<div class="search-box">
  <input
    class="search-box-input"
    id="sidebar-account-search"
    name="sidebar-account-search"
    type="text"
    placeholder="Search accounts"
    (focus)="showListOnMobile()"
    [formControl]="query"
  />
  <span class="icon-magnifier-1 search-box-icon"></span>
</div>

<ul
  class="account-sidebar-account-list"
  [ngClass]="{ 'account-sidebar-account-list__mobile-hidden': mobileHidden }"
>
  <li *ngFor="let account of filteredList | async">
    <a
      (click)="hideListOnMobile()"
      class="account-sidebar-account-link {{ sidebarLinkActive(account.id) }}"
      [routerLink]="['/accounts', account.id]"
      title="{{ account.companyName }}"
    >
      <span
        *ngIf="account.sampleAccount"
        class="pill-tag pill-tag__main-inverted pill-tag__small pill-tag__sample"
        >Sample</span
      >
      {{ account.companyName }}
    </a>
  </li>
  <div *ngIf="searching" class="account-sidebar-search-indicator">
    <p>
      <svg class="circle-spinner">
        <circle
          cx="11"
          cy="11"
          r="9"
          stroke-width="2"
          stroke-dasharray="0,1000"
          d="M5 40 l215 0"
          fill="transparent"
        />
      </svg>
      <span>Searching accounts...</span>
    </p>
  </div>
  <div *ngIf="tooManySearchResults" class="account-sidebar-search-indicator">
    <p>
      <span>Max 100 results shown. Please enter a more specific query.</span>
    </p>
  </div>
</ul>
