import { isEmpty, toInteger } from 'lodash';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { controlToFormStep } from 'app/features/liberty-mutual/models/lm-bop-form-steps';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';
import { getControl } from 'app/shared/helpers/form-helpers';
import {
  FormValue,
  ComplexEvaluatorFunc,
  DependencyCallback,
} from 'app/features/liberty-mutual/models/common-typings';
import {
  LmBopComplexEvaluator,
  LmBopQuestion,
  LmBopSubmissionField,
} from 'app/features/liberty-mutual/models/lm-bop-typings';

/**
 * A helper to find the path to a control as a string, in the format 'stepName.controlName'
 */
export const bopControlPath = (controlName: LmBopQuestion) => {
  return `${controlToFormStep[controlName]}.${controlName}`;
};

export const BOP_COMPLEX_DEPENDENCIES: {
  [key in LmBopComplexEvaluator]: ComplexEvaluatorFunc;
} = {
  // AND-type complex dependencies. ALL of the controls must be present in the form
  // to perform an evaluation.
  [LmBopComplexEvaluator.AREA_OCC_BY_INS_AND_BPP_OVER_0]: (form) => {
    const areaOccupiedByInsControl = getControl(
      form,
      bopControlPath(LmBopQuestion.AREA_OCCUPIED_BY_INSURED)
    );
    const bppLimitControl = getControl(
      form,
      bopControlPath(LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT)
    );

    if (!areaOccupiedByInsControl || !bppLimitControl) {
      return;
    }

    const areaOccupiedByIns$ = areaOccupiedByInsControl.valueChanges.pipe(
      startWith(areaOccupiedByInsControl.value)
    );
    const bppLimit$ = bppLimitControl.valueChanges.pipe(startWith(bppLimitControl.value));

    return {
      dependsOn: combineLatest([areaOccupiedByIns$, bppLimit$]),
      callback: ([areaOccupiedByIns, bppLimit]: string[]) => {
        const areaIsOccupiedByInsured =
          areaOccupiedByInsControl.enabled &&
          !isEmpty(areaOccupiedByInsControl) &&
          toInteger(areaOccupiedByIns) > 0;
        const bppIsOverZero =
          bppLimitControl.enabled && !isEmpty(bppLimit) && parseMoney(bppLimit) > 0;

        return areaIsOccupiedByInsured && bppIsOverZero;
      },
    };
  },
  [LmBopComplexEvaluator.AREA_OCC_BY_INS_AND_BPP_OVER_500K]: (form) => {
    const areaOccupiedByInsControl = getControl(
      form,
      bopControlPath(LmBopQuestion.AREA_OCCUPIED_BY_INSURED)
    );
    const bppLimitControl = getControl(
      form,
      bopControlPath(LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT)
    );
    const FIVE_HUNDRED_K = 500 * 1000;

    if (!areaOccupiedByInsControl || !bppLimitControl) {
      return;
    }

    const areaOccupiedByIns$ = areaOccupiedByInsControl.valueChanges.pipe(
      startWith(areaOccupiedByInsControl.value)
    );
    const bppLimit$ = bppLimitControl.valueChanges.pipe(startWith(bppLimitControl.value));

    return {
      dependsOn: combineLatest([areaOccupiedByIns$, bppLimit$]),
      callback: ([areaOccupiedByIns, bppLimit]: string[]) => {
        const areaIsOccupiedByInsured =
          areaOccupiedByInsControl.enabled &&
          !isEmpty(areaOccupiedByIns) &&
          toInteger(areaOccupiedByIns) > 0;
        const bppIsOver500K =
          bppLimitControl.enabled && !isEmpty(bppLimit) && parseMoney(bppLimit) > FIVE_HUNDRED_K;

        return areaIsOccupiedByInsured && bppIsOver500K;
      },
    };
  },
  [LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0]: (form) => {
    return BOP_COMPLEX_DEPENDENCIES[LmBopComplexEvaluator.AREA_OCC_BY_INS_AND_BPP_OVER_0](form);
  },
  [LmBopComplexEvaluator.HAS_BLDG_CVG_AND_HAS_TENANTS]: (form) => {
    const buildingLimitControl = getControl(
      form,
      bopControlPath(LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE)
    );
    const areaOccByTenantsControl = getControl(
      form,
      bopControlPath(LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS)
    );

    if (!buildingLimitControl || !areaOccByTenantsControl) {
      return;
    }

    const buildingLimit$ = buildingLimitControl.valueChanges.pipe(
      startWith(buildingLimitControl.value)
    );
    const areaOccByTenants$ = areaOccByTenantsControl.valueChanges.pipe(
      startWith(areaOccByTenantsControl.value)
    );

    return {
      dependsOn: combineLatest([buildingLimit$, areaOccByTenants$]),
      callback: ([buildingLimit, areaOccByTenants]: string[]) => {
        const buildingLimitIsOver0 =
          buildingLimitControl.enabled && !isEmpty(buildingLimit) && parseMoney(buildingLimit) > 0;
        const hasTenants =
          areaOccByTenantsControl.enabled &&
          !isEmpty(buildingLimit) &&
          toInteger(areaOccByTenants) > 0;

        return buildingLimitIsOver0 && hasTenants;
      },
    };
  },
  [LmBopComplexEvaluator.INS_OWNED_BLDG_CVG_AND_HAS_TENANTS]: (form) => {
    const buildingLimitControl = getControl(
      form,
      bopControlPath(LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE)
    );
    const areaOccByTenantsControl = getControl(
      form,
      bopControlPath(LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS)
    );
    const interestControl = getControl(form, bopControlPath(LmBopQuestion.INTEREST));

    if (!buildingLimitControl || !areaOccByTenantsControl || !interestControl) {
      return;
    }

    const buildingLimit$ = buildingLimitControl.valueChanges.pipe(
      startWith(buildingLimitControl.value)
    );
    const areaOccByTenants$ = areaOccByTenantsControl.valueChanges.pipe(
      startWith(areaOccByTenantsControl.value)
    );
    const interest$ = interestControl.valueChanges.pipe(startWith(interestControl.value));

    return {
      dependsOn: combineLatest([buildingLimit$, areaOccByTenants$, interest$]),
      callback: ([buildingLimit, areaOccByTenants, interest]: string[]) => {
        const buildingLimitIsOver0 =
          buildingLimitControl.enabled && !isEmpty(buildingLimit) && parseMoney(buildingLimit) > 0;
        const hasTenants = areaOccByTenantsControl.enabled && toInteger(areaOccByTenants) > 0;
        const buildingOwnedByInsured = interestControl.enabled && interest === 'OWNER';

        return buildingLimitIsOver0 && hasTenants && buildingOwnedByInsured;
      },
    };
  },
  [LmBopComplexEvaluator.UNDER_1M_SALES_AND_UNDER_10_EMP]: (form) => {
    const annualSalesControl = getControl(
      form,
      bopControlPath(LmBopQuestion.ANNUAL_SALES_RECEIPTS)
    );
    const totalEmployeesControl = getControl(
      form,
      bopControlPath(LmBopQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS)
    );

    if (!annualSalesControl || !totalEmployeesControl) {
      return;
    }

    const annualSales$ = annualSalesControl.valueChanges.pipe(startWith(annualSalesControl.value));
    const totalEmployees$ = totalEmployeesControl.valueChanges.pipe(
      startWith(totalEmployeesControl.value)
    );

    return {
      dependsOn: combineLatest([annualSales$, totalEmployees$]),
      callback: ([annualSales, totalEmployees]: string[]) => {
        const annualSalesAreUnder1M =
          annualSalesControl.enabled && !isEmpty(annualSales) && parseMoney(annualSales) < 1000000;
        const hasUnder10Employees =
          totalEmployeesControl.enabled &&
          !isEmpty(totalEmployees) &&
          toInteger(totalEmployees) < 10;

        return annualSalesAreUnder1M && hasUnder10Employees;
      },
    };
  },
  // OR-type complex dependencies. At least one of the controls must be present in the form
  // to perform an evaluation.
  [LmBopComplexEvaluator.FRYING_ON_PREMISES]: (form) => {
    const fryingOnPremises1Control = getControl(
      form,
      bopControlPath(LmBopQuestion.FRYING_ON_PREMISES_1)
    );
    const fryingOnPremises2Control = getControl(
      form,
      bopControlPath(LmBopQuestion.FRYING_ON_PREMISES_2)
    );

    const fryingOnPremisesControls = [fryingOnPremises1Control, fryingOnPremises2Control].filter(
      (maybeControl) => maybeControl
    );
    // Neither control found in form -- no subscription
    if (fryingOnPremisesControls.length === 0) {
      return;
    }
    // One control found in form -- subscribe to its changes
    if (fryingOnPremisesControls.length === 1) {
      const control = fryingOnPremisesControls[0];
      return {
        dependsOn: control.valueChanges.pipe(startWith(control.value)),
        callback: (hasFryingOnPremises) => {
          return control.enabled && hasFryingOnPremises === 'YES';
        },
      };
    }
    // Otherwise, both controls found -- subscribe to both changes
    const fryingOnPremisesValues$ = fryingOnPremisesControls.map((control) => {
      return control.valueChanges.pipe(startWith(control.value));
    });

    return {
      dependsOn: combineLatest(fryingOnPremisesValues$),
      callback: (fryingOnPremisesValues: (string | null)[]) => {
        return fryingOnPremisesValues.some((fryingVal, idx) => {
          return fryingOnPremisesControls[idx].enabled && fryingVal === 'YES';
        });
      },
    };
  },
  [LmBopComplexEvaluator.HIRED_OR_NON_OWNED_AUTO_SELECTED]: (form) => {
    const hiredAutoDamageControl = getControl(
      form,
      bopControlPath(LmBopQuestion.HIRED_AUTO_PHYSICAL_DAMAGE)
    );
    const hiredNonOwnedAutoControl = getControl(
      form,
      bopControlPath(LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY)
    );

    const hiredAutoControls = [hiredAutoDamageControl, hiredNonOwnedAutoControl].filter(
      (maybeControl) => maybeControl
    );
    // Neither control found in form -- no subscription
    if (hiredAutoControls.length === 0) {
      return;
    }
    // One control found in form -- subscribe to its changes
    if (hiredAutoControls.length === 1) {
      const control = hiredAutoControls[0];
      return {
        dependsOn: control.valueChanges.pipe(startWith(control.value)),
        callback: (hasHiredAutoCoverage) => {
          return control.enabled && hasHiredAutoCoverage === true;
        },
      };
    }
    // Otherwise, both controls found -- subscribe to both changes
    const hiredAutoValues$ = hiredAutoControls.map((control) => {
      return control.valueChanges.pipe(startWith(control.value));
    });

    return {
      dependsOn: combineLatest(hiredAutoValues$),
      callback: (hiredAutoValues: (boolean | null)[]) => {
        return hiredAutoValues.some((hiredAutoVal, idx) => {
          return hiredAutoControls[idx].enabled && hiredAutoVal === true;
        });
      },
    };
  },
  [LmBopComplexEvaluator.LIQ_LIABILITY_OR_BYOB]: (form) => {
    const liquorLiabilityTypeControl = getControl(
      form,
      bopControlPath(LmBopQuestion.LIQUOR_LIABILITY_TYPE)
    );

    // Control not found in form -- no subscription
    if (!liquorLiabilityTypeControl) {
      return;
    }

    return {
      dependsOn: liquorLiabilityTypeControl.valueChanges,
      callback: (liquorLiabilityTypeValue) => {
        return (
          liquorLiabilityTypeControl.enabled &&
          (liquorLiabilityTypeValue === LmBopSubmissionField.LIQUOR_LIABILITY ||
            liquorLiabilityTypeValue === LmBopSubmissionField.BYOB_LIQUOR_LIABILITY_COVERAGE)
        );
      },
    };
  },
  [LmBopComplexEvaluator.SERVICE_WORK_SALES_OVER_0]: (form) => {
    const serviceWorkSales1Control = getControl(
      form,
      bopControlPath(LmBopQuestion.SERVICE_WORK_SALES_1)
    );
    const serviceWorkSales3Control = getControl(
      form,
      bopControlPath(LmBopQuestion.SERVICE_WORK_SALES_2)
    );
    const serviceWorkSales4Control = getControl(
      form,
      bopControlPath(LmBopQuestion.SERVICE_WORK_SALES_3)
    );
    const serviceWorkSales2Control = getControl(
      form,
      bopControlPath(LmBopQuestion.SERVICE_WORK_SALES_4)
    );

    const serviceWorkSalesControls = [
      serviceWorkSales1Control,
      serviceWorkSales2Control,
      serviceWorkSales3Control,
      serviceWorkSales4Control,
    ].filter((maybeControl) => maybeControl);
    // No control found in form -- no subscription
    if (serviceWorkSalesControls.length === 0) {
      return;
    }
    // One control found in form -- subscribe to its changes
    if (serviceWorkSalesControls.length === 1) {
      const control = serviceWorkSalesControls[0];
      return {
        dependsOn: control.valueChanges.pipe(startWith(control.value)),
        callback: (serviceWorkSales: string | null) => {
          return control.enabled && !isEmpty(serviceWorkSales) && parseMoney(serviceWorkSales) > 0;
        },
      };
    }
    // Otherwise, multiple controls found -- subscribe to all changes
    const serviceWorkSalesValues$ = serviceWorkSalesControls.map((control) => {
      return control.valueChanges.pipe(startWith(control.value));
    });

    return {
      dependsOn: combineLatest(serviceWorkSalesValues$),
      callback: (salesValues: (string | null)[]) => {
        return salesValues.some((salesVal, idx) => {
          return (
            serviceWorkSalesControls[idx].enabled && !isEmpty(salesVal) && parseMoney(salesVal) > 0
          );
        });
      },
    };
  },
  // Combination AND/OR complex dependency. To perform an evaluation for a
  // dependency `CONTROL_A && (CONTROL_B || CONTROL_C)`:
  //  -> CONTROL_A must be present
  //  -> At least one of [CONTROL_B, CONTROL_C] must be present
  [LmBopComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K]: (form) => {
    const yearBuiltControl = getControl(form, bopControlPath(LmBopQuestion.YEAR_BUILT));
    const buildingLimitControl = getControl(
      form,
      bopControlPath(LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE)
    );
    const bppLimitControl = getControl(
      form,
      bopControlPath(LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT)
    );

    const limitDependencyValues$: Observable<FormValue>[] = [];
    const limitDependencyCallbacks: DependencyCallback[] = [];

    if (bppLimitControl) {
      const FIVE_HUNDRED_K = 500 * 1000;
      const dependsOn = bppLimitControl.valueChanges.pipe(startWith(bppLimitControl.value));
      const callback = (bppLimit: string | null) => {
        const bppLimitOver500K = !isEmpty(bppLimit) && parseMoney(bppLimit) > FIVE_HUNDRED_K;

        return bppLimitControl.enabled && bppLimitOver500K;
      };

      limitDependencyValues$.push(dependsOn);
      limitDependencyCallbacks.push(callback);
    }

    if (buildingLimitControl) {
      const dependsOn = buildingLimitControl.valueChanges.pipe(
        startWith(buildingLimitControl.value)
      );
      const callback = (buildingLimit: string | null) => {
        const buildingLimitOver0 = !isEmpty(buildingLimit) && parseMoney(buildingLimit) > 0;

        return buildingLimitControl.enabled && buildingLimitOver0;
      };

      limitDependencyValues$.push(dependsOn);
      limitDependencyCallbacks.push(callback);
    }

    if (!yearBuiltControl || !limitDependencyValues$.length) {
      return;
    }

    const yearBuiltValue$ = yearBuiltControl.valueChanges.pipe(startWith(yearBuiltControl.value));
    const yearBuiltCallback = (yearBuilt: string) => {
      const TWENTY_FOUR_YEARS_AGO = moment.utc().subtract(24, 'years').format('YYYY');

      if (isEmpty(yearBuilt)) {
        return false;
      }
      const yearBuiltMoment = moment.utc(yearBuilt, 'YYYY');
      const isOver24YearsOld = yearBuiltMoment.isBefore(TWENTY_FOUR_YEARS_AGO);

      return yearBuiltControl.enabled && isOver24YearsOld;
    };

    return {
      dependsOn: combineLatest([yearBuiltValue$, ...limitDependencyValues$]),
      callback: (dependencyValues: FormValue[]) => {
        const yearBuiltValue = dependencyValues[0] as string;
        const limitValues = dependencyValues.slice(1);

        const isOver24YearsOld = yearBuiltCallback(yearBuiltValue);
        const hasHighLimit = limitValues.some((limitValue, idx) => {
          const limitCallback = limitDependencyCallbacks[idx];

          return limitCallback(limitValue);
        });

        return isOver24YearsOld && hasHighLimit;
      },
    };
  },
  [LmBopComplexEvaluator.PRIOR_COVERAGE_REASON_IS_NEW_ACQUISITION_FOR_THE_INSURED_OR_NONE_OF_THE_ABOVE]:
    (form) => {
      const priorCoverageReasonControl = getControl(
        form,
        bopControlPath(LmBopQuestion.PRIOR_COVERAGE_REASON)
      );
      return {
        dependsOn: priorCoverageReasonControl.valueChanges.pipe(
          startWith(priorCoverageReasonControl.value)
        ),
        callback: (priorCoverageReason: string | null) => {
          return (
            priorCoverageReasonControl.enabled &&
            (priorCoverageReason === 'New acquisition for the insured' ||
              priorCoverageReason === 'None of the above apply')
          );
        },
      };
    },
};

const COB_EXCEPTIONS: { [exception: string]: string } = {
  MINI_WAREHOUSE: '88663',
  HEARING_AID_RETAIL: '81351',
};

export const COB_CODE_EXCEPTIONS_BY_STATE: {
  [state: string]: string[];
} = {
  AL: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  AK: [],
  AZ: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  AR: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  CA: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  CO: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  CT: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  DE: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  DC: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  FL: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  GA: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  HI: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  ID: [],
  IL: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  IN: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  IA: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  KS: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  KY: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  LA: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  ME: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  MD: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  MA: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  MI: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  MN: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  MS: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  MO: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  MT: [],
  NE: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  NV: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  NH: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  NJ: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  NM: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  NY: [COB_EXCEPTIONS.MINI_WAREHOUSE, COB_EXCEPTIONS.HEARING_AID_RETAIL],
  NC: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  ND: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  OH: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  OK: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  OR: [],
  PA: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  RI: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  SC: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  SD: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  TN: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  TX: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  UT: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  VT: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  VA: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  WA: [],
  WV: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  WI: [COB_EXCEPTIONS.MINI_WAREHOUSE],
  WY: [COB_EXCEPTIONS.MINI_WAREHOUSE],
};
