import { OnChanges, Input, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { SentryService } from 'app/core/services/sentry.service';
import { InformService } from 'app/core/services/inform.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { InvoicesBannerService } from '../../services/invoices-banner.service';
import {
  COALITION_CYBER_INVOICE_SURPLUS_GUIDEWIRE_NAME,
  COALITION_CYBER_INVOICE_SURPLUS_DISPLAY_NAME,
} from 'app/features/coalition/models/cyber-constants.model';
import { DocumentService } from '../../../documents/services/document.service';
import { getAttuneBopScheduleOfInvoicesUrl } from '../../../documents/models/document-urls.model';
import { getScheduleOfInvoicesFileName } from '../../../documents/models/document-file-names.model';

interface PolicyTermDetails {
  lineOfBusiness: string;
  totalValue: number;
  paymentPlan: string;
}

@Component({
  selector: 'app-invoices-metadata',
  templateUrl: './invoices-metadata.component.html',
})
export class InvoicesMetadataComponent implements OnChanges {
  @Input() isLoading = false;
  @Input() accountSummary: AccountSummary;
  @Input() invoice: Invoice;
  @Input() autopayStatus = false;

  showScheduleOfInvoices = environment.showScheduleOfInvoices;

  policyTermDetails: PolicyTermDetails[];
  isLoadingScheduleOfInvoices = false;

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private sentryService: SentryService,
    private informService: InformService,
    private amplitudeService: AmplitudeService,
    private invoiceBannerService: InvoicesBannerService
  ) {}

  ngOnChanges() {
    this.policyTermDetails = this.getPolicyTermDetails();
  }

  getPolicyTermDetails(): PolicyTermDetails[] {
    return this.invoiceBannerService
      .getPolicyTermDetails(this.accountSummary, this.invoice)
      .map((policyTerm) => {
        // Change LOB to display name since LOB used for display is different than the one sent by guidewire for cyber surplus
        policyTerm.lineOfBusiness =
          policyTerm.lineOfBusiness === COALITION_CYBER_INVOICE_SURPLUS_GUIDEWIRE_NAME
            ? COALITION_CYBER_INVOICE_SURPLUS_DISPLAY_NAME
            : policyTerm.lineOfBusiness;
        return policyTerm;
      });
  }

  getPolicyPeriodsOnInvoice(): { [lineOfBusiness: string]: string[] } {
    const lobToPolicyNumbersMap: { [lineOfBusiness: string]: string[] } = {};

    for (const charge of this.invoice.charges) {
      const lob = charge.lineOfBusiness;
      if (!lobToPolicyNumbersMap[lob]) {
        lobToPolicyNumbersMap[lob] = [];
      }
      lobToPolicyNumbersMap[lob].push(charge.policyNumber);
    }

    return lobToPolicyNumbersMap;
  }

  downloadScheduleOfInvoices() {
    this.isLoadingScheduleOfInvoices = true;

    this.amplitudeService.track({
      eventName: 'schedule_download_attempt',
      detail: `/invoice/${this.route.snapshot.params.id}/schedule?${this.route.snapshot.queryParams.token}`,
      useLegacyEventName: true,
    });

    const id = this.route.snapshot.params.id;
    const token = this.route.snapshot.queryParams.token;
    const url = getAttuneBopScheduleOfInvoicesUrl(id, token, false);
    const fileName = getScheduleOfInvoicesFileName(id);
    this.documentService.getDocument(url, fileName, 'pdf').subscribe({
      next: () => {
        this.amplitudeService.track({
          eventName: 'schedule_download_success',
          detail: `/invoice/${this.route.snapshot.params.id}/schedule/${this.route.snapshot.queryParams.token}`,
          useLegacyEventName: true,
        });
      },
      error: () => {
        this.amplitudeService.track({
          eventName: 'schedule_download_error',
          detail: `/invoice/${id}/schedule/${token}`,
          useLegacyEventName: true,
        });
        this.informService.infoToast(
          'Could not download the PDF of this invoice schedule. Please contact our Customer Care Team.'
        );
        this.isLoadingScheduleOfInvoices = false;
      },
      complete: () => {
        this.isLoadingScheduleOfInvoices = false;
      },
    });
  }
}
