import { ValidatorFn, Validators } from '@angular/forms';
import { values } from 'lodash';

import { FormDslNode } from '../../../shared/form-dsl/constants/form-dsl-typings';
import { validateCurrencyGreaterThanZero } from '../../../shared/helpers/form-helpers';
import { CoalitionCyberQuestion } from '../../coalition/models/cyber-typings.model';

// Specifies how to configure form-dsl
// overrides based on class of business
export interface CobOverrideGroups {
  [inputId: string]: string[];
}

export enum HISCOX_API_VERSION {
  v4 = 'v4',
}

export enum HISCOX_PRODUCTS {
  gl = 'gl',
  pl = 'pl',
}

// TODO: determine what, if any of these status apply to GL
export enum HiscoxQuoteStatus {
  QUOTED = 'QUOTED',
  PENDING_REFER = 'PENDING_REFER', // TODO: need to add case
  REFERRED = 'REFERRED',
  DECLINED = 'DECLINED',
  BOUND = 'BOUND',
  UNKNOWN = 'UNKNOWN', // TODO: need to add case
  REFERRED_DECLINED = 'REFERRED_DECLINED', // TODO: need to add case
  REFERRED_APPROVED = 'REFERRED_APPROVED', // TODO: need to add case
  PENDING_BIND = 'PENDING_BIND', // TODO: need to add case
}

export const yesNoOptions = {
  yes: 'Yes',
  no: 'No',
};

export const yesNoOptionalOptions = {
  yes: 'Yes',
  no: 'No',
  'prefer not to answer': 'Prefer not to answer',
};

export const agreeDisagreeOptions = {
  agree: 'agree',
  disagree: 'disagree',
};

export enum HiscoxFormStepPath {
  QUOTE_BASIC = 'quoteBasic',
  QUOTE_RATING = 'quoteRating',
}

export const agreeDisagreeOptionsV4 = {
  Agree: 'Agree',
  Disagree: 'Disagree',
};

// START: DYNAMIC QUESTIONS
interface BaseQuestion {
  Name?: string;
  Description?: string;
  XPath?: string;
}

export interface Level2SubQuestion extends BaseQuestion {
  Value?: string | number;
  Level2SubQuestions?: BaseQuestion | BaseQuestion[];
}
export interface Level1SubQuestion extends BaseQuestion {
  Responses?: Level2SubQuestion | Level2SubQuestion[];
}

export interface QuestionResponse {
  Value: string | number;
  Level1SubQuestions?: Level1SubQuestion | Level1SubQuestion[];
}

export interface QuestionYesNoResponse {
  Value: 'Yes' | 'No';
}

export interface BusinessActivity {
  BusinessActivityName: string;
  BusinessActivityDescription: string;
  Responses: QuestionYesNoResponse[];
}

export interface Question extends BaseQuestion {
  ContainsBusinessActivity: string | Record<string, any>;
  Responses?: QuestionResponse[];
  BusinessActivity?: BusinessActivity[];
}

export interface QuestionsList {
  State: string;
  ClassOfBusinessCd: string;
  Product: string;
  Questions: Question[];
}

export interface DynamicQuestionsResponse {
  QuestionsResponse: {
    QuestionsList: QuestionsList;
  };
}

// END: DYNAMIC QUESTIONS

export type GlV4Overrides = Partial<FormDslNode> & {
  cobOverrideSets?: {
    cobSet: string | string[];
    overrides: Partial<FormDslNode>;
    states?: string[];
  }[];
};

// CYBER UPSELL TYPES
export enum CyberUpsellFormLogicControlName {
  INCLUDE_COALITION_CYBER_STANDALONE_POLICY = 'IncludeCoalitionCyberStandalonePolicy',
  NAICS_CODE_IS_INELIGIBLE = 'naicsCodeIsIneligible',
  INDUSTRY_REQUIRES_TECH_EO = 'industryRequiresTechEO',
  INDUSTRY_REQUIRES_NET_PROFIT = 'industryRequiresNetProfit',
}

export const CYBER_UPSELL_QUESTIONS = [
  ...values(CyberUpsellFormLogicControlName),
  CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
  CoalitionCyberQuestion.COMPANY_REVENUE,
  CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT,
  CoalitionCyberQuestion.AGGREGATE_LIMIT,
  CoalitionCyberQuestion.HAS_TECH_EO,
  CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
] as const;

export type CyberUpsellQuestion = typeof CYBER_UPSELL_QUESTIONS[number];

// Explicitly set validators for each Cyber Upsell field, since setting the `required: true` property
// on the individual node configs does not apply consistently for each control.
export const cyberUpsellValidators: Record<string, ValidatorFn[]> = {
  [CoalitionCyberQuestion.COMPANY_INDUSTRY_ID]: [Validators.required],
  [CoalitionCyberQuestion.COMPANY_REVENUE]: [Validators.required, validateCurrencyGreaterThanZero],
  [CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT]: [Validators.required],
  [CoalitionCyberQuestion.AGGREGATE_LIMIT]: [Validators.required],
  [CoalitionCyberQuestion.HAS_TECH_EO]: [Validators.required],
  [CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE]: [
    Validators.required,
    validateCurrencyGreaterThanZero,
  ],
};
// END CYBER UPSELL TYPES
