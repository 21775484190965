<a
  [ngClass]="{
    'document-download': true,
    'document-download__disabled': state !== 'ready',
  }"
  href="javascript:void(0)"
  (click)="download()"
  (keyup.enter)="download()"
>
  <ng-container [ngSwitch]="type">
    <img
      *ngSwitchCase="'pdf'"
      src="/assets/img/pdf_icon.svg"
      alt=""
      class="document-download-pdf-icon"
    />
    <img
      *ngSwitchCase="'signed'"
      src="/assets/img/check_cutout.svg"
      alt=""
      class="document-download-pdf-icon"
    />
    <div *ngSwitchCase="'unsigned'" class="gray-circle"></div>
  </ng-container>
  <div
    [ngSwitch]="state"
    [ngClass]="{ 'tool-tip tool-tip__large': tooltip }"
    [attr.data-tooltip]="tooltip"
  >
    <span *ngSwitchCase="'ready'" class="document-download-title">
      {{ displayName }}
    </span>
    <span *ngSwitchCase="'downloading'" class="document-download-title">
      Downloading {{ displayName }}...
    </span>
    <span *ngSwitchCase="'preparing'" class="document-download-title">
      Preparing {{ displayName }}...
    </span>
    <span *ngSwitchCase="'disabled'" class="document-download-title">
      {{ displayName }} Unavailable
    </span>
  </div>
  <ng-container [ngSwitch]="state">
    <span *ngSwitchCase="'ready'" class="document-download-icon icon-download"></span>
    <span *ngSwitchCase="'disabled'"></span>
    <app-loading-spinner *ngSwitchDefault class="document-download-icon"></app-loading-spinner>
  </ng-container>
</a>
