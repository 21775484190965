import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UPDATE_PAYMENT_PLAN_URL } from '../../../constants';
import { SentryService } from '../../../core/services/sentry.service';

type NewPaymentPlanStatus = 'Success' | 'Failure';

type NewPaymentPlan = 'TenPay' | 'FourPay' | 'TwoPay' | 'FullPay';

export interface PaymentPlanInformation {
  billImmediately: boolean;
  policyNumber: string;
  newPaymentPlan: NewPaymentPlan;
}

@Injectable()
export class InvoicesPaymentPlanService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  public updatePaymentPlan(
    invoice: string,
    token: string,
    billImmediately: boolean,
    policyNumber: string,
    newPaymentPlan: NewPaymentPlan
  ): Observable<NewPaymentPlanStatus | HttpErrorResponse> {
    const payload: PaymentPlanInformation = {
      billImmediately,
      policyNumber,
      newPaymentPlan,
    };

    const options = { params: new HttpParams().set('token', token) };

    const send$: Observable<any> = this.http
      .post<NewPaymentPlanStatus>(`${UPDATE_PAYMENT_PLAN_URL}/${invoice}`, payload, options)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to change payment plan.', {
            severity: 'error',
            metaData: {
              policyNumber,
              newPaymentPlan,
            },
          });
          throw error;
        })
      );
    return send$;
  }
}
