/// <reference path="../../../../../../node_modules/@types/googlemaps/index.d.ts" />
// Libraries
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
} from '@angular/core';

import { FormFieldTextComponent } from '../form-field-text/form-field-text.component';
import { environment } from 'environments/environment';
import { timer } from 'rxjs';

@Component({
  selector: 'app-form-field-address-autocomplete',
  templateUrl: './form-field-address-autocomplete.component.html',
})
export class FormFieldAddressAutocompleteComponent
  extends FormFieldTextComponent
  implements AfterViewInit, OnInit
{
  @Input() readonly: boolean;
  @Output()
  newAutoCompleteAddress: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter<google.maps.places.PlaceResult>();

  @ViewChild('inputElement')
  public addressInputElementRef: ElementRef;

  constructor(private ngZone: NgZone) {
    super();
  }

  ngAfterViewInit() {
    // Create autocomplete outside angular context. When run inside, e2e tests
    // fail probably because this doesn't ever complete
    timer(500).subscribe(() =>
      this.ngZone.runOutsideAngular(() => {
        // If google api was unable to load, then just disable autocomplete functionality
        if (
          typeof google === 'undefined' ||
          !google.maps.places ||
          !environment.useGooglePlacesAutoComplete
        ) {
          return;
        }

        const autocomplete = new google.maps.places.Autocomplete(
          this.addressInputElementRef.nativeElement,
          {
            componentRestrictions: {
              country: 'US',
            },
          }
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            this.newAutoCompleteAddress.emit(autocomplete.getPlace());
          });
        });
      })
    );
  }

  handleFocusIn() {
    // The Google Autocomplete widget automatically adds autocomplete="off" to the element
    // But autocomplete="off" is not respected by the browsers :(
    // So instead we have to force it over to "new-password" to disable autocomplete
    const el = this.addressInputElementRef.nativeElement;
    el.autocomplete = 'new-password';
  }

  handleKeyPress(e: KeyboardEvent) {
    // Prevents the form being submitted when a user selects a place from the
    // Google autocomplete dropdown
    if (e && e.keyCode === 13) {
      e.preventDefault();
    }
  }
}
