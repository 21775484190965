import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { V3_REFER_API_URI } from 'app/constants';
import { SentryService } from 'app/core/services/sentry.service';

const STATUSES = ['not-invited', 'invited', 'complete'] as const;
export type Status = typeof STATUSES[number];
export interface ReferralUser {
  email: string;
  firstName: string;
  lastName: string;
  status: Status;
}

@Injectable()
export class ReferralsService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getReferralUsers(start = 0, limit = 20): Observable<ReferralUser[]> {
    return this.http.get<ReferralUser[]>(`${V3_REFER_API_URI}?start=${start}&limit=${limit}`).pipe(
      catchError((error: any) => {
        this.sentryService.notify('Error getting referral users', {
          severity: 'error',
          metaData: {
            underlyingError: error,
            underlyingErrorMessage: error && error.message,
          },
        });
        throw new Error('Error getting referral users');
      })
    );
  }

  sendReferral(referee: ReferralUser): Observable<void> {
    return this.http
      .post<void>(
        V3_REFER_API_URI,
        JSON.stringify({
          email: referee.email,
        }),
        {
          headers: { 'Content-type': 'application/json' },
        }
      )
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Error sending referral', {
            severity: 'error',
            metaData: {
              referee,
              underlyingError: error,
              underlyingErrorMessage: error && error.message,
            },
          });
          throw new Error('Error sending referral');
        })
      );
  }

  trackClick(uuid: string): Observable<void> {
    return this.http
      .post<void>(
        `${V3_REFER_API_URI}/${uuid}/click`,
        {},
        {
          headers: { 'Content-type': 'application/json' },
        }
      )
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Error tracking referral click', {
            severity: 'error',
            metaData: {
              uuid,
              underlyingError: error,
              underlyingErrorMessage: error && error.message,
            },
          });
          throw new Error('Error tracking referral click');
        })
      );
  }
}
