<section [ngClass]="{ 'form-animation': isChangingRoutes }">
  <h1 class="form-header-below-link">Location {{ locationId }}</h1>

  <div class="helper-card-container">
    <app-form-field-address
      labelText="Location business address"
      prefix="location-{{ locationId }}"
      [form]="form.get('address')"
      [submitted]="submitted()"
    ></app-form-field-address>
    <div class="helper-card">
      <div class="pill-tag pill-tag__main">Note</div>
      <p>Location 1 will be used as the primary location on the policy.</p>
    </div>
  </div>

  <app-dialog-box
    *ngIf="showDeductibleWarning()"
    type="warning"
    (dismissDialog)="dismissDeductibleWarning()"
    [dismissable]="true"
  >
    {{ deductibleWarningText() }}
  </app-dialog-box>

  <h3 class="question-subsection-header">Employee classifications</h3>

  <div class="multi-form-item" *ngIf="loadingClassCodes">
    <div class="placeholder-form-label"></div>
    <div class="placeholder-form-input"></div>
  </div>

  <ng-container *ngIf="!loadingClassCodes">
    <section class="validation">
      <div class="validation-message" *ngIf="showClassCodeFetchError">
        There was an error fetching employee classifications for this state. Please contact the
        Customer Care team if this persists.
      </div>

      <div class="validation-message__warning" *ngIf="showEmptyClassCodesWarning">
        There are no class codes available for this state or locality. We do not offer WC coverage
        in
        {{ form.value.address.state }}.
      </div>
    </section>

    <section *ngIf="showClassCodesControls" class="js-class-code-controls">
      <div
        id="location-{{ locationId }}-classCode-{{ i }}"
        class="multi-form-item"
        *ngFor="let classCode of classCodesFormArray().controls; let i = index"
      >
        <div class="helper-card-container">
          <app-wc-class-code
            prefix="location-{{ locationId }}-classCode-{{ i }}"
            [fuse]="stateClassCodes?.fuse"
            [classCodeOptions]="stateClassCodes?.classCodes"
            [classCode]="classCode"
            [submitted]="submitted()"
          >
          </app-wc-class-code>
          <div class="helper-card helper-card__wc-employee-classification" *ngIf="i === 0">
            <div class="pill-tag pill-tag__main">Note</div>
            <p>Payroll should exclude officers if an officers' exclusion will been added.</p>
          </div>
        </div>

        <div class="nav-button-group">
          <a
            class="button button__small button__secondary js-remove-classification-code-button"
            (click)="removeClassCode(i)"
            (keyup.enter)="removeClassCode(i)"
            *ngIf="moreThanOneClassCode()"
          >
            Remove
          </a>
        </div>
      </div>
    </section>

    <button
      *ngIf="showClassCodesControls"
      class="
        button button__secondary button__full-width button__no-margin
        js-add-classification-code-button
      "
      type="button"
      (click)="addClassCode()"
    >
      Add employee classification
    </button>
  </ng-container>
</section>
