import { Component, Input, OnDestroy } from '@angular/core';

import { EmailService } from '../services/email.service';
import { InsuredAccount } from '../../features/insured-account/models/insured-account.model';
import { User } from 'app/shared/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-insured-loss-runs-upload',
  templateUrl: './dashboard-loss-runs-upload.component.html',
  styleUrls: ['./dashboard-loss-runs-upload.component.scss'],
  providers: [EmailService],
})
export class DashboardLossRunsUploadComponent implements OnDestroy {
  @Input() model: InsuredAccount;
  @Input() user: User;
  fileUploading: Boolean;

  private sub = new Subscription();

  constructor(private emailService: EmailService) {}

  onFilesSelected(event: Event) {
    const target = <HTMLInputElement>event.target;
    if (target.files && target.files.length > 0) {
      const selectedFile = target.files[0];

      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      formData.append('model', JSON.stringify(this.model));
      formData.append('user', JSON.stringify(this.user));

      const fileUploading$ = this.emailService.setupFileStatusObserver(selectedFile.name);
      this.sub.add(this.emailService.sendEmail(formData, selectedFile.name).subscribe());
      this.sub.add(fileUploading$.subscribe((loading) => (this.fileUploading = loading)));
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
