<section class="form-animation">
  <h1>Loss History</h1>

  <app-form-field-radio
    inputId="hab-lossHistory-hasRiskHadPriorLosses"
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    questionText='Has this risk had any claims during the current period or over the prior three (3) years? If there have been claims but occurred/closed at $0, select "Yes"'
    [form]="form.get('lossHistory')"
    [submitted]="hasSubmitted()"
    nameOfFormControl="hasRiskHadPriorLosses"
  >
  </app-form-field-radio>

  <ng-container *ngIf="hasPriorRisk()">
    <section class="js-hab-loss-history" *ngFor="let loss of losses().controls; index as lossIndex">
      <div class="form-group multi-form-item">
        <app-form-field-select
          inputId="hab-lossHistory-{{ lossIndex }}-locationNum"
          labelText="Location"
          [form]="loss"
          [submitted]="hasSubmitted()"
          [availableOptions]="lossLocationNumOptions()"
          nameOfFormControl="locationNum"
        >
        </app-form-field-select>

        <div class="form-split">
          <app-form-field-text
            inputId="hab-lossHistory-{{ lossIndex }}-lossDate"
            labelText="Date of loss"
            placeholder="MM/DD/YYYY"
            [form]="loss"
            [submitted]="hasSubmitted()"
            nameOfFormControl="lossDate"
            appDateMask
          >
          </app-form-field-text>

          <app-form-field-select
            inputId="hab-lossHistory-{{ lossIndex }}-lossType"
            labelText="Type of loss"
            placeholder="Choose one"
            [form]="loss"
            [submitted]="hasSubmitted()"
            [availableOptions]="habLossTypeOptions"
            nameOfFormControl="lossType"
          >
          </app-form-field-select>
        </div>

        <div class="form-field form-field__autocomplete">
          <app-form-field-dropdown-search
            inputId="hab-lossHistory-{{ lossIndex }}-carrier"
            labelText="Carrier"
            placeholderText="Select a carrier"
            typeaheadPlaceholderText="Search"
            [submitted]="hasSubmitted()"
            [queryMethod]="queryMethod"
            [formatter]="codeDescription"
            [targetFormControl]="loss.get('carrier')"
          >
          </app-form-field-dropdown-search>
        </div>

        <app-form-field-text
          inputId="hab-lossHistory-{{ lossIndex }}-otherCarrier"
          labelText="Other carrier name"
          [form]="loss"
          [submitted]="hasSubmitted()"
          nameOfFormControl="otherCarrier"
        >
        </app-form-field-text>

        <div class="form-split">
          <app-form-field-radio
            inputId="hab-lossHistory-{{ lossIndex }}-claimStatus"
            questionCssClass="radio-question__small"
            optionsCssClass="radio-question-option__small"
            questionText="Claim status"
            [form]="loss"
            [submitted]="hasSubmitted()"
            [options]="habLossClaimStatusOptions"
            nameOfFormControl="claimStatus"
          >
          </app-form-field-radio>
          <app-form-field-text
            inputId="hab-lossHistory-{{ lossIndex }}-totalLossIncurred"
            labelText="Total loss incurred"
            placeholder="$"
            [form]="loss"
            [submitted]="hasSubmitted()"
            nameOfFormControl="totalLossIncurred"
            appMoneyMask="WITHOUT_DECIMAL"
          >
          </app-form-field-text>
        </div>

        <div class="nav-button-group">
          <a
            class="button button__small button__transparent"
            (click)="removeLoss(lossIndex)"
            (keyup.enter)="removeLoss(lossIndex)"
            *ngIf="moreThanOneLoss()"
          >
            Remove
          </a>
        </div>
      </div>
    </section>
  </ng-container>

  <button
    class="button button__secondary button__full-width js-hab-add-loss button__no-margin"
    type="button"
    (click)="addLoss()"
    *ngIf="hasPriorRisk()"
  >
    Add additional loss
  </button>

  <hr />
</section>
