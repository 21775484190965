export interface BopAdditionalInsuredBusiness {
  additionalInformation?: string;
  address?: Address;
  addressType?: string;
  buildingDescription?: string;
  businessName?: string;
  contractNumber?: string;
  guidewireId?: string;
  limitOfInsurance?: string;
  paragraph?: string;
  relatedEntityId?: string;
  relatedLocation?: string;
  type: AdditionalInsuredType;
}

export enum AdditionalInsuredType {
  CONTRACT_AGREEMENT_PERMIT = 'CONTRACT_AGREEMENT_PERMIT',
  CONTROLLING_INTEREST = 'CONTROLLING_INTEREST',
  DESIGNATED_PERSON = 'DESIGNATED_PERSON',
  ENGINEERS = 'ENGINEERS',
  ENGINEERS_NOT_ENGAGED = 'ENGINEERS_NOT_ENGAGED',
  STATE_SUBDIVISIONS = 'STATE_SUBDIVISIONS',
  GRANTOR_OF_FRANCHISE = 'GRANTOR_OF_FRANCHISE',
  OWNERS = 'OWNERS',
  STATE_SUBDIVISIONS_AUTHORIZATIONS = 'STATE_SUBDIVISIONS_AUTHORIZATIONS',
  VENDORS = 'VENDORS',
  CO_OWNER = 'CO_OWNER',
  LESSOR_OF_EQUIPMENT = 'LESSOR_OF_EQUIPMENT',
  MANAGER_OF_PREMISES = 'MANAGER_OF_PREMISES',
  OWNERS_OF_LAND = 'OWNERS_OF_LAND',
  OWNERS_SCHEDULED_PERSON = 'OWNERS_SCHEDULED_PERSON',
  OWNERS_COMPLETED_OPS = 'OWNERS_COMPLETED_OPS',
  LOSS_OF_RENTAL_VALUE = 'LOSS_OF_RENTAL_VALUE',
  MORTGAGEE = 'MORTGAGEE',
  BUILDING_OWNER = 'BUILDING_OWNER',
  LOSS_PAYABLE = 'LOSS_PAYABLE',
  MORTGAGE_HOLDER = 'MORTGAGE_HOLDER',
}

export enum AdditionalInsuredPlacement {
  LOCATION,
  BUILDING,
}

export const ADDITIONAL_INSURED_MAPPING: { [key: string]: AdditionalInsuredPlacement } = {
  [AdditionalInsuredType.CO_OWNER]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.LESSOR_OF_EQUIPMENT]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.MANAGER_OF_PREMISES]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.OWNERS_OF_LAND]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.OWNERS_SCHEDULED_PERSON]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.OWNERS_COMPLETED_OPS]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.LOSS_OF_RENTAL_VALUE]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.MORTGAGEE]: AdditionalInsuredPlacement.LOCATION,
  [AdditionalInsuredType.BUILDING_OWNER]: AdditionalInsuredPlacement.BUILDING,
  [AdditionalInsuredType.LOSS_PAYABLE]: AdditionalInsuredPlacement.BUILDING,
  [AdditionalInsuredType.MORTGAGE_HOLDER]: AdditionalInsuredPlacement.BUILDING,
};

export enum DependentFields {
  LIMIT_OF_INSURANCE = 'limitOfInsurance',
  ADDITIONAL_INFORMATION = 'additionalInformation',
  CONTRACT_NUMBER = 'contractNumber',
  BUILDING_DESCRIPTION = 'buildingDescription',
  PARAGRAPH = 'paragraph',
}

// If you add or remove here, make sure to update DEPENDENT_FIELDS_MAP for ADDITIONAL_INFORMATION
export const ADDITIONAL_INFORMATION_LABEL: { [key: string]: string } = {
  [AdditionalInsuredType.VENDORS]: 'Your Products',
  [AdditionalInsuredType.LESSOR_OF_EQUIPMENT]: 'Description of Leased Equipment',
  [AdditionalInsuredType.MANAGER_OF_PREMISES]: 'Designation of Premises (Part Leased to You)',
  [AdditionalInsuredType.OWNERS_COMPLETED_OPS]: 'Description of Completed Operations',
  [AdditionalInsuredType.LOSS_OF_RENTAL_VALUE]: 'Designation of Premises (Part Leased to You)',
  [AdditionalInsuredType.MORTGAGEE]: 'Designation of Premises',
};

export const OPTIONAL_INFORMATION_TYPES: AdditionalInsuredType[] = [
  AdditionalInsuredType.LOSS_OF_RENTAL_VALUE,
  AdditionalInsuredType.MANAGER_OF_PREMISES,
  AdditionalInsuredType.MORTGAGEE,
];

export const DEPENDENT_FIELDS_MAP: { [key: string]: DependentFields[] } = {
  [AdditionalInsuredType.LOSS_OF_RENTAL_VALUE]: [
    DependentFields.ADDITIONAL_INFORMATION,
    DependentFields.LIMIT_OF_INSURANCE,
  ],
  [AdditionalInsuredType.BUILDING_OWNER]: [
    DependentFields.CONTRACT_NUMBER,
    DependentFields.BUILDING_DESCRIPTION,
  ],
  [AdditionalInsuredType.LOSS_PAYABLE]: [
    DependentFields.CONTRACT_NUMBER,
    DependentFields.BUILDING_DESCRIPTION,
    DependentFields.PARAGRAPH,
  ],
  [AdditionalInsuredType.MORTGAGE_HOLDER]: [DependentFields.CONTRACT_NUMBER],
  [AdditionalInsuredType.LESSOR_OF_EQUIPMENT]: [DependentFields.ADDITIONAL_INFORMATION],
  [AdditionalInsuredType.MANAGER_OF_PREMISES]: [DependentFields.ADDITIONAL_INFORMATION],
  [AdditionalInsuredType.OWNERS_COMPLETED_OPS]: [DependentFields.ADDITIONAL_INFORMATION],
  [AdditionalInsuredType.MORTGAGEE]: [DependentFields.ADDITIONAL_INFORMATION],
  [AdditionalInsuredType.VENDORS]: [DependentFields.ADDITIONAL_INFORMATION],
};
