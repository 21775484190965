import { HappyPathFormData } from '../../../shared/form-dsl/constants/form-dsl-typings';
import {
  CoalitionCyberFormStepPath,
  CoalitionCyberQuestion,
  BundleOption,
  FirstPartyCoverageNestedQuestion,
  ThirdPartyCoverageNestedQuestion,
} from './cyber-typings.model';
import * as moment from 'moment';

export const CYBER_ADMITTED_HAPPY_PATH_FORM_DATA: HappyPathFormData<
  CoalitionCyberFormStepPath,
  CoalitionCyberQuestion
> = {
  [CoalitionCyberFormStepPath.QUICK_QUOTE]: {
    [CoalitionCyberQuestion.EFFECTIVE_DATE]: moment.utc().format('MM/DD/YYYY'),
    [CoalitionCyberQuestion.COMPANY_INDUSTRY_ID]: {
      displayName: 'Auto Components - Auto Parts, Equipment, and Accessories',
      id: 225,
    },
    [CoalitionCyberQuestion.COMPANY_REVENUE]: '$500,000',
    [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS]: 'No',
    [CoalitionCyberQuestion.MFA_EMAIL]: 'Yes',
    [CoalitionCyberQuestion.MFA_REMOTE_ACCESS]: 'Yes',
    [CoalitionCyberQuestion.MFA_VPN]: 'Yes',
    [CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS]: 'Yes',
    [CoalitionCyberQuestion.DUAL_CONTROL_5K]: 'Yes',
    [CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL]: 'Yes',
  },
  [CoalitionCyberFormStepPath.POLICY_INFO]: {
    [CoalitionCyberQuestion.EFFECTIVE_DATE]: moment.utc().format('MM/DD/YYYY'),
    [CoalitionCyberQuestion.ADDRESS]: {
      addressLine1: '321 Main Street',
      addressLine2: '',
      city: 'San Francisco',
      state: 'CA',
      zip: '94105',
    },
    [CoalitionCyberQuestion.COMPANY_INDUSTRY_ID]: {
      displayName: 'Auto Components - Auto Parts, Equipment, and Accessories',
      id: 225,
    },
    [CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY]: 'No',
    // This field is intentionally left blank to reduce "broker of record" errors
    // that appear when the same domain is used for the same zip code by different
    // brokers.
    [CoalitionCyberQuestion.DOMAIN_NAMES]: '',
    [CoalitionCyberQuestion.COMPANY_REVENUE]: '$500,000',
    [CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT]: '26-50',
  },
  [CoalitionCyberFormStepPath.UNDERWRITING]: {
    [CoalitionCyberQuestion.PRIOR_CLAIMS]: 'No',
    [CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION]: '',
    [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS]: 'No',
    [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS_EXPLANATION]: '',
    [CoalitionCyberQuestion.ENCRYPTS_DATA]: 'Yes',
    [CoalitionCyberQuestion.PII_PHI]: 'No',
    [CoalitionCyberQuestion.PII_PHI_COUNT]: 'No records',
    [CoalitionCyberQuestion.CC_CUSTOMER_COUNT]: '<100,000',
    [CoalitionCyberQuestion.CONTENT_COMPLAINTS]: 'No',
    [CoalitionCyberQuestion.REVIEWS_CONTENT]: 'No',
    [CoalitionCyberQuestion.BACKUP_AND_RESTORE]: 'Yes',
    [CoalitionCyberQuestion.DUAL_CONTROL]: 'Yes',
    [CoalitionCyberQuestion.MFA_EMAIL]: 'Yes',
    [CoalitionCyberQuestion.MFA_REMOTE_ACCESS]: 'Yes',
    [CoalitionCyberQuestion.MFA_VPN]: 'Yes',
    [CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS]: 'Yes',
    // Tech E&O questions
    [CoalitionCyberQuestion.HAS_TECH_EO]: 'No',
  },
  [CoalitionCyberFormStepPath.COVERAGE]: {
    [CoalitionCyberQuestion.BUNDLE]: BundleOption.MOST_POPULAR,
    [CoalitionCyberQuestion.FIRST_PARTY_COVERAGES]: {
      [FirstPartyCoverageNestedQuestion.ASSET_RESTORATION]: true,
      [FirstPartyCoverageNestedQuestion.BIPD_FIRST_PARTY]: false,
      [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE]: true,
      [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_COSTS]: true,
      [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_SERVICES]: true,
      [FirstPartyCoverageNestedQuestion.BUSINESS_INTERRUPTION]: true,
      [FirstPartyCoverageNestedQuestion.COMPUTER_REPLACEMENT]: true,
      [FirstPartyCoverageNestedQuestion.CRISIS_MANAGEMENT]: true,
      [FirstPartyCoverageNestedQuestion.EXTORTION]: true,
      [FirstPartyCoverageNestedQuestion.FUNDS_TRANSFER]: true,
      [FirstPartyCoverageNestedQuestion.REPUTATION]: false,
      [FirstPartyCoverageNestedQuestion.REPUTATIONAL_HARM]: true,
      [FirstPartyCoverageNestedQuestion.SERVICE_FRAUD]: true,
      // [FirstPartyCoverageNestedQuestion.TECH_EO]: false,
    },
    [CoalitionCyberQuestion.TECH_EO_DISPUTE]: 'No',
    [CoalitionCyberQuestion.TECH_EO_DISPUTE_EXPLANATION]: '',
    [CoalitionCyberQuestion.IS_MSP_OR_BAD_INDUSTRY]: 'No',
    [CoalitionCyberQuestion.PROFESSIONAL_SERVICES]: '',
    [CoalitionCyberQuestion.SERVICES_BY_CONTRACT]: '',
    [CoalitionCyberQuestion.MITIGATING_CLAUSES]: '',
    [CoalitionCyberQuestion.THIRD_PARTY_COVERAGES]: {
      [ThirdPartyCoverageNestedQuestion.BIPD_THIRD_PARTY]: false,
      [ThirdPartyCoverageNestedQuestion.MEDIA_LIABILITY]: true,
      [ThirdPartyCoverageNestedQuestion.NETWORK]: true,
      [ThirdPartyCoverageNestedQuestion.PCI]: true,
      [ThirdPartyCoverageNestedQuestion.POLLUTION]: false,
      [ThirdPartyCoverageNestedQuestion.REGULATORY_DEFENSE]: true,
    },
  },
};
