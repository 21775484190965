import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { LibertyMutualClassCode, ComplexValueEvaluatorFunc } from './common-typings';
import { getControl } from 'app/shared/helpers/form-helpers';
import { lmCpspControlToFormStep } from './lm-cpsp-form-steps';
import { evaluateSetCondition } from './helpers';
import { LM_CPSP_PROPERTY_CLASS_CODES } from './property-class-codes';
import { FormDslNode } from 'app/shared/form-dsl/constants/form-dsl-typings';
import {
  ExposureType,
  LmCpspComplexValueEvaluator,
  LmCpspQuestion,
  LmCpspLossHistoryQuestion,
} from './lm-cpsp-typings';

export const cpspControlPath = (controlName: LmCpspQuestion) => {
  return `${lmCpspControlToFormStep[controlName]}.${controlName}`;
};

export const cpspLossHistoryControlPath = (
  controlName: LmCpspLossHistoryQuestion,
  formArrayIndex: number
) => {
  return `${lmCpspControlToFormStep[LmCpspQuestion.LOSSES]}.${
    LmCpspQuestion.LOSSES
  }.${formArrayIndex}.${controlName}`;
};

const occupancyTypeOptions: Record<string, Record<string, string>> = {
  Lodging: {
    'Apartment, 2-4 Units': 'Apartment, 2-4 Units',
    'Apartment, High-Rise': 'Apartment, High-Rise',
    'Apartment, High-Rise, Shell': 'Apartment, High-Rise, Shell',
    'Apartment, Low-Rise': 'Apartment, Low-Rise',
    'Apartment, Low-Rise, Older': 'Apartment, Low-Rise, Older',
    'Bed & Breakfast': 'Bed & Breakfast',
    Condominium: 'Condominium',
    'Condominium, Shell': 'Condominium, Shell',
    'Convent or Rectory': 'Convent or Rectory',
    'Deluxe Condominium': 'Deluxe Condominium',
    Dormitory: 'Dormitory',
    'Fraternity House': 'Fraternity House',
    'Hotel, Full Service': 'Hotel, Full Service',
    'Hotel, Limited Service': 'Hotel, Limited Service',
    'Hotel, Older': 'Hotel, Older',
    Lodge: 'Lodge',
    'Luxury Apartment, High-Rise': 'Luxury Apartment, High-Rise',
    Mansion: 'Mansion',
    Motel: 'Motel',
    'Motel, Double Row': 'Motel, Double Row',
    'Motel, Extended Stay': 'Motel, Extended Stay',
    'Motel, Single Row': 'Motel, Single Row',
    'Office-Apartment (Motel)': 'Office-Apartment (Motel)',
    'Rooming House': 'Rooming House',
    'Row House': 'Row House',
  },
  Offices: {
    'Bank or Savings and Loan': 'Bank or Savings and Loan',
    'Bank or Savings and Loan (Mini or Branch Bank)':
      'Bank or Savings and Loan (Mini or Branch Bank)',
    'City Hall or Courthouse': 'City Hall or Courthouse',
    'Computer Data Center': 'Computer Data Center',
    'Government Community Service Building': 'Government Community Service Building',
    'Mechanical Penthouse': 'Mechanical Penthouse',
    'Office High-Rise': 'Office High-Rise',
    'Office, Low-Rise': 'Office, Low-Rise',
    'Office, Low-Rise, Older': 'Office, Low-Rise, Older',
    'Office, Low-Rise, Shell': 'Office, Low-Rise, Shell',
    'Office, Mid-Rise': 'Office, Mid-Rise',
    'Radio or TV Broadcast Center': 'Radio or TV Broadcast Center',
  },
  Mercantile: {
    'Bar or Lounge': 'Bar or Lounge',
    'Barber Shop': 'Barber Shop',
    Bookstore: 'Bookstore',
    'Bowling Center': 'Bowling Center',
    Cafeteria: 'Cafeteria',
    Cinema: 'Cinema',
    'City Club': 'City Club',
    'Clubhouse / Recreation Building': 'Clubhouse / Recreation Building',
    'Community Center': 'Community Center',
    'Concession Stand': 'Concession Stand',
    'Concession Stand with Press Box': 'Concession Stand with Press Box',
    'Convenience Food Store': 'Convenience Food Store',
    'Country Club': 'Country Club',
    'Department Store': 'Department Store',
    'Department Store, Discount': 'Department Store, Discount',
    Dining: 'Dining',
    'Dressing and Shower facility': 'Dressing and Shower facility',
    Drugstore: 'Drugstore',
    'Enclosed Park Pavilion': 'Enclosed Park Pavilion',
    'Fast Food with Seating': 'Fast Food with Seating',
    'Fast Food without Seating': 'Fast Food without Seating',
    'Fitness Center': 'Fitness Center',
    'Florist Shop': 'Florist Shop',
    'Furniture Warehouse / Showroom': 'Furniture Warehouse / Showroom',
    'Health and Racquetball Club': 'Health and Racquetball Club',
    'Health Club': 'Health Club',
    'Home Improvement Center': 'Home Improvement Center',
    'Indoor Ice or Roller Rink': 'Indoor Ice or Roller Rink',
    'Indoor Tennis Club': 'Indoor Tennis Club',
    'Open Park Pavilion': 'Open Park Pavilion',
    'Park Restroom Building': 'Park Restroom Building',
    'Senior Clubhouse': 'Senior Clubhouse',
    'Shopping Center, Mall Type': 'Shopping Center, Mall Type',
    'Shopping Center, Mall Type, Shell': 'Shopping Center, Mall Type, Shell',
    'Shopping Center, Strip Type': 'Shopping Center, Strip Type',
    'Shopping Center, Strip Type, Shell': 'Shopping Center, Strip Type, Shell',
    'Store or Shop, General': 'Store or Shop, General',
    'Store or Shop, Older': 'Store or Shop, Older',
    'Store with Apartment Above': 'Store with Apartment Above',
    'Store with Offices Above': 'Store with Offices Above',
    Supermarket: 'Supermarket',
    'Theater, Live Stage': 'Theater, Live Stage',
    'Warehouse Food Store': 'Warehouse Food Store',
  },
  'Professional Services': {
    'Dental Office / Clinic': 'Dental Office / Clinic',
    Dispensary: 'Dispensary',
    'Dog Kennel': 'Dog Kennel',
    'Funeral Home': 'Funeral Home',
    'Group Care Home': 'Group Care Home',
    'Home for the Elderly': 'Home for the Elderly',
    Hospital: 'Hospital',
    'Medical Clinic': 'Medical Clinic',
    'Multiple Residence, Assisted Living': 'Multiple Residence, Assisted Living',
    'Multiple Residence, Senior Citizen': 'Multiple Residence, Senior Citizen',
    'Nursing Home / Convalescent Center': 'Nursing Home / Convalescent Center',
    'Outpatient Surgical Center': 'Outpatient Surgical Center',
    'Veterinary Clinic': 'Veterinary Clinic',
  },
  'Public Services': {
    'Administration Building  (University or Trade School)':
      'Administration Building  (University or Trade School)',
    'Air Terminal (Large Commercial)': 'Air Terminal (Large Commercial)',
    'Air Terminal (Small Regional)': 'Air Terminal (Small Regional)',
    Armory: 'Armory',
    Atrium: 'Atrium',
    Auditorium: 'Auditorium',
    Canopy: 'Canopy',
    'Church Camp Cabins': 'Church Camp Cabins',
    'Church Camp Dorms': 'Church Camp Dorms',
    'Church Tower': 'Church Tower',
    'Church with Sunday School': 'Church with Sunday School',
    'Church, Auditorium Type': 'Church, Auditorium Type',
    'Church, Average': 'Church, Average',
    'Church, Basic': 'Church, Basic',
    'Church, Contemporary': 'Church, Contemporary',
    'Church, Contemporary, High': 'Church, Contemporary, High',
    'Church, Contemporary, Mansard': 'Church, Contemporary, Mansard',
    'Church, Elaborate': 'Church, Elaborate',
    'Church, Modern A-Frame': 'Church, Modern A-Frame',
    'Church, Narthex': 'Church, Narthex',
    'Church, Traditional': 'Church, Traditional',
    'Classroom (Elementary - High School)': 'Classroom (Elementary - High School)',
    'Classroom (University)': 'Classroom (University)',
    'Commons (University)': 'Commons (University)',
    'Day Care Center': 'Day Care Center',
    'Dining Atrium': 'Dining Atrium',
    'Educational Wing': 'Educational Wing',
    'Elementary School': 'Elementary School',
    'Fellowship Hall': 'Fellowship Hall',
    'Field House': 'Field House',
    'Fine Arts / Crafts Building (Elementary - High School)':
      'Fine Arts / Crafts Building (Elementary - High School)',
    'Fine Arts / Crafts Building (University)': 'Fine Arts / Crafts Building (University)',
    'Fire Station': 'Fire Station',
    'Fire Station, Volunteer': 'Fire Station, Volunteer',
    'Fraternal Building': 'Fraternal Building',
    'Gymnasium (Elementary - High School)': 'Gymnasium (Elementary - High School)',
    'High School': 'High School',
    'Jail, Correctional Facility': 'Jail, Correctional Facility',
    'Junior High School': 'Junior High School',
    'Laboratory (University)': 'Laboratory (University)',
    'Lecture Classroom (Elementary - High School)': 'Lecture Classroom (Elementary - High School)',
    'Lecture Hall (University)': 'Lecture Hall (University)',
    'Library (Public)': 'Library (Public)',
    'Library (University)': 'Library (University)',
    'Library / Media Center (Elementary - High School)':
      'Library / Media Center (Elementary - High School)',
    'Manual Arts Building (Elementary - High School)':
      'Manual Arts Building (Elementary - High School)',
    'Multipurpose Buildings (Elementary - High School)':
      'Multipurpose Buildings (Elementary - High School)',
    Museum: 'Museum',
    Natatorium: 'Natatorium',
    'Physical Education Building (University)': 'Physical Education Building (University)',
    'Police Station or Jail': 'Police Station or Jail',
    'Post Office': 'Post Office',
    'Post Office Main, Processing Facility': 'Post Office Main, Processing Facility',
    'Post Office, Branch': 'Post Office, Branch',
    'School, Older': 'School, Older',
    'Science Classrooms': 'Science Classrooms',
    'Technical Trades Building': 'Technical Trades Building',
    University: 'University',
    'Visitor Center': 'Visitor Center',
    'Vocational School': 'Vocational School',
  },
  Services: {
    'Aircraft Hangar': 'Aircraft Hangar',
    'Auto Repair / Service Center': 'Auto Repair / Service Center',
    'Automobile Showroom': 'Automobile Showroom',
    'Boat Storage': 'Boat Storage',
    'Bus Terminal': 'Bus Terminal',
    'Car Wash': 'Car Wash',
    'Car Wash, Automatic': 'Car Wash, Automatic',
    'Car Wash, Self-Serve': 'Car Wash, Self-Serve',
    Laundromat: 'Laundromat',
    'Lumber Storage Facility': 'Lumber Storage Facility',
    Marina: 'Marina',
    'Mini-Warehouse': 'Mini-Warehouse',
    'Mini-Warehouse, High-Rise': 'Mini-Warehouse, High-Rise',
    'Municipal Service Garage': 'Municipal Service Garage',
    'Parking Structure': 'Parking Structure',
    'Quick Oil Change Facility': 'Quick Oil Change Facility',
    'Self-Storage Facility': 'Self-Storage Facility',
    'Service Occupancy': 'Service Occupancy',
    'Service Station': 'Service Station',
    'Showroom with Service Area': 'Showroom with Service Area',
    'Storage Hangar': 'Storage Hangar',
    'T-Hangar': 'T-Hangar',
    'Truck Stop': 'Truck Stop',
    'Truck Terminal': 'Truck Terminal',
  },
  'Industrial / Processes': {
    Bakery: 'Bakery',
    'Boiler House': 'Boiler House',
    'Bottling Plant': 'Bottling Plant',
    Cannery: 'Cannery',
    'Cold Storage': 'Cold Storage',
    'Commercial Greenhouse': 'Commercial Greenhouse',
    Dairy: 'Dairy',
    'High-Tech Production Facility': 'High-Tech Production Facility',
    'Industrial Flex Building': 'Industrial Flex Building',
    'Industrial Mall Building': 'Industrial Mall Building',
    'Industrial Mall Building, Interior Space': 'Industrial Mall Building, Interior Space',
    'Industrial Mall Building, Shell': 'Industrial Mall Building, Shell',
    'Industrial Park Building': 'Industrial Park Building',
    'Laundry or Dry Cleaning Plant': 'Laundry or Dry Cleaning Plant',
    'Manufacturing, Heavy': 'Manufacturing, Heavy',
    'Manufacturing, Heavy Industrial': 'Manufacturing, Heavy Industrial',
    'Manufacturing, Light': 'Manufacturing, Light',
    'Manufacturing, Pole Frame': 'Manufacturing, Pole Frame',
    'Meat Packing Plant': 'Meat Packing Plant',
    'Office Service Center Building': 'Office Service Center Building',
    'Production Laboratory': 'Production Laboratory',
    'Utility Building': 'Utility Building',
    'Utility Building, Light Commercial': 'Utility Building, Light Commercial',
    'Warehouse, Cold Storage': 'Warehouse, Cold Storage',
    'Warehouse, Heavy': 'Warehouse, Heavy',
    'Warehouse, Mega': 'Warehouse, Mega',
    'Warehouse, Pole Frame': 'Warehouse, Pole Frame',
    'Wholesale Occupancy': 'Wholesale Occupancy',
  },
};

const exposureTypeClassCodeMap: Record<ExposureType, string[]> = {
  [ExposureType.ACRES]: ['41603', '41604'],
  [ExposureType.DOLLARS_OF_GROSS_SALES]: [
    '10010',
    '10026',
    '10040',
    '10042',
    '10065',
    '10066',
    '10070',
    '10111',
    '10113',
    '10115',
    '10140',
    '10141',
    '10145',
    '10146',
    '10150',
    '10204',
    '10255',
    '10257',
    '10309',
    '10352',
    '11007',
    '11020',
    '11126',
    '11127',
    '11128',
    '11160',
    '11167',
    '11168',
    '11222',
    '11234',
    '11258',
    '11259',
    '12014',
    '12356',
    '12361',
    '12362',
    '12374',
    '12375',
    '12391',
    '12393',
    '12467',
    '12510',
    '12651',
    '12797',
    '12805',
    '12841',
    '13049',
    '13111',
    '13112',
    '13351',
    '13506',
    '13507',
    '13590',
    '13670',
    '13673',
    '13715',
    '13716',
    '13720',
    '13759',
    '13930',
    '14101',
    '14279',
    '14401',
    '14405',
    '14527',
    '14655',
    '14731',
    '14732',
    '14733',
    '14734',
    '14913',
    '15070',
    '15223',
    '15224',
    '15404',
    '15538',
    '15600',
    '15607',
    '15608',
    '15699',
    '15839',
    '15991',
    '15993',
    '16005',
    '16403',
    '16404',
    '16501',
    '16527',
    '16588',
    '16676',
    '16705',
    '16819',
    '16820',
    '18109',
    '18110',
    '18200',
    '18205',
    '18206',
    '18335',
    '18435',
    '18436',
    '18437',
    '18438',
    '18501',
    '18507',
    '18575',
    '18616',
    '18833',
    '18834',
    '18911',
    '18912',
    '18920',
    '18991',
    '19007',
    '19051',
    '41665',
    '41696',
    '43889',
    '44069',
    '45190',
    '45191',
    '45192',
    '45193',
    '45678',
    '45901',
    '45937',
    '45993',
    '46202',
    '49005',
    '49617',
    '49619',
    '49840',
    '51116',
    '51221',
    '51224',
    '51315',
    '51355',
    '51356',
    '51357',
    '51358',
    '51359',
    '51500',
    '51516',
    '51553',
    '51554',
    '51752',
    '51869',
    '51877',
    '51896',
    '51919',
    '51926',
    '51927',
    '51957',
    '52002',
    '52134',
    '52432',
    '52438',
    '52467',
    '52469',
    '52505',
    '52660',
    '52967',
    '53374',
    '53375',
    '53376',
    '53377',
    '53565',
    '53732',
    '54077',
    '55371',
    '55802',
    '56041',
    '56042',
    '56202',
    '56390',
    '56391',
    '56758',
    '56759',
    '56760',
    '57257',
    '57410',
    '57572',
    '57600',
    '57716',
    '57808',
    '57871',
    '57913',
    '57997',
    '57998',
    '58009',
    '58057',
    '58095',
    '58096',
    '58408',
    '58409',
    '58456',
    '58457',
    '58458',
    '58459',
    '58627',
    '58663',
    '58759',
    '58903',
    '58922',
    '59005',
    '59057',
    '59058',
    '59223',
    '59482',
    '59781',
    '59782',
    '59889',
    '59905',
    '59914',
    '59915',
    '59917',
    '59923',
    '59963',
    '59964',
    '59975',
    '10071',
    '10072',
    '10073',
    '10100',
    '10367',
    '10368',
    '11039',
    '11288',
    '16402',
    '16471',
    '16900',
    '16901',
    '16902',
    '16910',
    '16911',
    '16916',
  ],
  [ExposureType.DOLLARS_OF_PAYROLL]: [
    '41675',
    '41677',
    '44280',
    '47050',
    '47367',
    '90089',
    '91111',
    '91150',
    '91155',
    '91302',
    '91405',
    '91581',
    '91585',
    '95124',
    '95487',
    '95647',
    '96053',
    '97650',
    '97653',
    '98090',
    '98091',
    '98111',
    '98405',
    '98502',
    '98597',
    '98598',
    '98636',
    '98710',
    '98805',
    '98813',
    '99003',
    '99004',
    '99505',
    '99600',
    '99620',
    '99650',
    '99826',
    '99827',
    '99851',
    '99946',
    '99948',
    '91177',
    '91179',
    '91340',
    '91341',
    '91342',
    '91436',
    '91551',
    '91555',
    '91560',
    '91577',
    '91746',
    '91805',
    '92102',
    '92215',
    '92338',
    '92451',
    '92478',
    '92663',
    '94007',
    '94276',
    '94569',
    '95410',
    '95625',
    '95648',
    '96317',
    '96611',
    '96816',
    '97047',
    '97050',
    '97220',
    '97447',
    '98304',
    '98305',
    '98344',
    '98482',
    '98483',
    '98806',
    '98884',
    '98967',
    '98993',
    '99471',
    '99746',
  ],
  [ExposureType.GALLONS]: ['13453', '13454', '13455'],
  [ExposureType.KENNELS]: ['45450'],
  [ExposureType.MEMBERS]: ['41669', '41670', '46881', '46882'],
  [ExposureType.SHOWS]: ['43215'],
  [ExposureType.SQUARE_FEET_OF_AREA]: [
    '41650',
    '41667',
    '41668',
    '41680',
    '45334',
    '46426',
    '46427',
    '47103',
    '48600',
    '61212',
    '61216',
    '61217',
    '61218',
    '61223',
    '61224',
    '61225',
    '61226',
    '61227',
    '62000',
    '62001',
    '62002',
    '64074',
    '66122',
    '66123',
    '66309',
    '66561',
    '67634',
    '67635',
    '68439',
  ],
  [ExposureType.UNITS]: ['60010', '60011', '62003', '68500'],
};

const exposureTypeOptionsMap: Record<ExposureType, Partial<FormDslNode>> = {
  [ExposureType.ACRES]: {
    primitive: 'NUMBER',
    labelText: 'Acre(s)',
  },
  [ExposureType.DOLLARS_OF_GROSS_SALES]: {
    primitive: 'MONEY_WITH_DECIMAL',
    labelText: 'Dollars of gross sales',
  },
  [ExposureType.DOLLARS_OF_PAYROLL]: {
    primitive: 'MONEY_WITH_DECIMAL',
    labelText: 'Dollars of payroll',
  },
  [ExposureType.GALLONS]: {
    primitive: 'NUMBER',
    labelText: 'Number of gallons',
  },
  [ExposureType.KENNELS]: {
    primitive: 'NUMBER',
    labelText: 'Number of kennels',
  },
  [ExposureType.MEMBERS]: {
    primitive: 'NUMBER',
    labelText: 'Number of members',
  },
  [ExposureType.SHOWS]: {
    primitive: 'NUMBER',
    labelText: 'Number of shows',
  },
  [ExposureType.SQUARE_FEET_OF_AREA]: {
    primitive: 'NUMBER',
    labelText: 'Square feet of area',
  },
  [ExposureType.UNITS]: {
    primitive: 'NUMBER',
    labelText: 'Unit(s)',
  },
};

const liquorLiabilityExposureMap: Record<string, { primary: string; exclusionStates: string[] }> = {
  '58166': {
    primary: 'Total annual liquor receipts per location',
    exclusionStates: [],
  },
  '58168': {
    primary: 'Total annual liquor receipts per liquor permit',
    exclusionStates: [],
  },
  '05998': {
    primary: 'Annual gross liquor sales',
    exclusionStates: ['DE', 'KS', 'MD', 'SD', 'VA'],
  },
  '50911': {
    primary: 'Annual gross liquor sales',
    exclusionStates: ['DE', 'IA', 'KS', 'MD', 'MO', 'SD', 'VA'],
  },
  '58161': {
    primary: 'Annual gross liquor sales',
    exclusionStates: ['DE', 'KS', 'MD', 'SD', 'VA'],
  },
  '58165': {
    primary: 'Annual gross liquor sales',
    exclusionStates: ['DE', 'KS', 'MD', 'SD', 'VA'],
  },
  '59211': {
    primary: 'Annual gross liquor sales',
    exclusionStates: ['DE', 'IA', 'KS', 'MD', 'MO', 'SD', 'VA'],
  },
  '70412': {
    primary: 'Annual gross liquor sales',
    exclusionStates: ['DE', 'KS', 'MD', 'SD', 'VA'],
  },
};

export const DEFUALT_LIQUOR_LIABILITY_EXPOSURE_LABEL_TEXT =
  'Total annual liquor receipts per location';

export const LM_CPSP_COMPLEX_VALUE_EVALUATORS: Record<
  LmCpspComplexValueEvaluator,
  ComplexValueEvaluatorFunc
> = {
  [LmCpspComplexValueEvaluator.EXPOSURE_LIQUOR_LIABILITY_LABEL_TEXT]: (form) => {
    const classCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.CLASS_LIQUOR_LIABILITY)
    );

    const baseStateControl = getControl(form, cpspControlPath(LmCpspQuestion.PRIMARY_RISK_STATE));

    const classCode$ = classCodeControl.valueChanges.pipe(startWith(classCodeControl.value));

    const baseState$ = baseStateControl.valueChanges.pipe(startWith(baseStateControl.value));

    return {
      dependsOn: combineLatest([classCode$, baseState$]),
      callback: ([classCode, baseState]: [string, string]) => {
        if (!classCode || !baseState || !liquorLiabilityExposureMap[classCode]) {
          return { labelText: DEFUALT_LIQUOR_LIABILITY_EXPOSURE_LABEL_TEXT };
        }

        if (!liquorLiabilityExposureMap[classCode].exclusionStates.includes(baseState)) {
          return { labelText: liquorLiabilityExposureMap[classCode].primary };
        } else {
          return { labelText: DEFUALT_LIQUOR_LIABILITY_EXPOSURE_LABEL_TEXT };
        }
      },
    };
  },
  [LmCpspComplexValueEvaluator.EXPOSURE_PRIMITIVE_AND_LABEL_TEXT]: (form) => {
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );

    const glClassCodeControl$ = glClassCodeControl.valueChanges.pipe(
      startWith(glClassCodeControl.value)
    );

    return {
      dependsOn: glClassCodeControl$,
      callback: (glClassCode: LibertyMutualClassCode) => {
        if (!glClassCode) {
          return { primitive: 'NUMBER', labelText: 'Exposure' };
        }

        const exposureType = _.findKey(exposureTypeClassCodeMap, (classes) =>
          classes.includes(glClassCode.code)
        );
        if (exposureType) {
          return exposureTypeOptionsMap[exposureType as ExposureType];
        }
        return { primitive: 'NUMBER', labelText: 'Exposure' };
      },
    };
  },
  [LmCpspComplexValueEvaluator.PROPERTY_CLASS_CODE_OPTIONS]: (form) => {
    const baseStateControl = getControl(form, cpspControlPath(LmCpspQuestion.PRIMARY_RISK_STATE));
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );

    const baseState$ = baseStateControl.valueChanges.pipe(startWith(baseStateControl.value));
    const glClassCodeControl$ = glClassCodeControl.valueChanges.pipe(
      startWith(glClassCodeControl.value)
    );

    return {
      dependsOn: combineLatest([baseState$, glClassCodeControl$]),
      callback: ([baseState, glClassCode]: [string, LibertyMutualClassCode]) => {
        if (!baseState || !glClassCode) {
          return {
            options: {},
          };
        }
        const propertyClassCodes = LM_CPSP_PROPERTY_CLASS_CODES[glClassCode.code];
        const options = propertyClassCodes.reduce(
          (availableClasses: Record<string, string>, classCodeConfig) => {
            const display = classCodeConfig.state.display;
            const values: string[] = _.get(classCodeConfig, ['state', 'values'], []);
            const isAvailable = evaluateSetCondition(baseState, display, values);

            if (isAvailable) {
              const { internalCode, name } = classCodeConfig.classCode;
              availableClasses[internalCode] = name;
            }
            return availableClasses;
          },
          {}
        );

        return {
          options,
        };
      },
    };
  },
  [LmCpspComplexValueEvaluator.COINSURANCE_BUILDING_OPTIONS]: (form) => {
    const baseStateControl = getControl(form, cpspControlPath(LmCpspQuestion.PRIMARY_RISK_STATE));
    const sprinklerPercentControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );

    const baseState$ = baseStateControl.valueChanges.pipe(startWith(baseStateControl.value));
    const sprinklerPercentControl$ = sprinklerPercentControl.valueChanges.pipe(
      startWith(sprinklerPercentControl.value)
    );

    return {
      dependsOn: combineLatest([baseState$, sprinklerPercentControl$]),
      callback: ([baseState, sprinklerPercent]: [string, number]) => {
        let options: Record<string, string> = { '80': '80%', '90': '90%', '100': '100%' }; // Default options
        if (baseState === 'LA' && sprinklerPercent > 74) {
          options = {
            '90': '90%',
            '100': '100%',
          };
        }

        return {
          options,
        };
      },
    };
  },
  [LmCpspComplexValueEvaluator.OCCUPANCY_TYPE_OPTIONS]: (form) => {
    const occupancyTypeCategoryControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.OCCUPANCY_TYPE_CATEGORY_PROPERTY)
    );
    const occupancyTypeCategory$ = occupancyTypeCategoryControl.valueChanges.pipe(
      startWith(occupancyTypeCategoryControl.value)
    );

    return {
      dependsOn: occupancyTypeCategory$,
      callback: (occupancyType: string) => {
        return {
          options: occupancyTypeOptions[occupancyType],
        };
      },
    };
  },
};

const GL_EXCEPTIONS: {
  [exception: string]: string;
} = {
  BUILDING_MATERIAL_DISTRIBUTORS: '10257',
  CATALOG_OR_PREMIUM_COUPON_REDEM_STORES: '11020',
  RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL: '16916',
  SPAS_OR_PERSONAL_ENHANCEMENT_FACILITIES: '18200',
  SPORTING_GOODS_OR_ATHL_EQUIP_DISTRIBUTORS: '18205',
  TOY_DISTRIBUTORS: '18833',
  COMPUTER_CONSULTING_OR_PROGRAMMING: '41675',
  CONVENTS_OR_MONASTERIES: '41680',
  ENTERTAINMENT_PERF_ON_OTHERS_PREMISES: '43215',
  GOLF_COURSES_MINI: '44069',
  EVENT_PARTY_OR_WEDDING_PLANNERS: '44280',
  MOBILE_HOME_PARKS_OR_COURTS: '46202',
  SOCIAL_SERVICES_CONSULTING_ONLY_PRIVATE: '48600',
  AIR_CONDITIONING_EQUIP_MFG: '51116',
  BOTTLE_AND_JAR_MFG_PLASTIC_NONRETURN: '51553',
  BOTTLE_AND_JAR_MFG_PLASTIC_RETURNABLE: '51554',
  COLOR_OR_PIGMENT_PREPARATION: '51919',
  HONEY_EXTRACTING: '55371',
  LAMPS_OR_LANTERNS_MFG_NON_ELECTRIC: '56042',
  LIGHT_BULB_OR_TUBES_MFG: '56390',
  PAPER_COATING_OR_FINISHING: '57716',
  PET_FOOD_MFG: '57913',
  PIPES_OR_TUBES_MFG_METAL: '58009',
  VEGETABLE_OIL_MFG_NOC: '59905',
  WATCH_OR_WATCH_CASE_MFG: '59923',
  WINE_MFG_SPARKLING: '59963',
  WINE_MFG_STILL: '59964',
  APARTMENT_BUILDINGS: '60010',
  APARTMENT_BUILDINGS_GARDEN: '60011',
  AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES: '91177',
  AUCTION_SALES_ON_OWNED_RENT_INS_PREMISES: '91179',
  CONDUIT_CONSTRUCTION_CABLES_OR_WIRES: '91577',
  DRILLING_WATER: '92102',
  HANDYPERSON: '95625',
  MACHINE_SHOP_NOC: '97220',
  PREFAB_BUILDING_ERECTION: '98502',
  SAND_OR_GRAVEL_DIGGING: '98710',
  SHEET_METAL_WORK_OUTSIDE: '98884',
  SIDING_INSTALLATION: '98967',
  WATER_MAINS_OR_CONNECT_CONSTRUCTION: '99946',
};

export const CPSP_COB_CODE_EXCEPTIONS_BY_STATE: {
  [state: string]: string[];
} = {
  AK: [
    GL_EXCEPTIONS.CATALOG_OR_PREMIUM_COUPON_REDEM_STORES,
    GL_EXCEPTIONS.SPORTING_GOODS_OR_ATHL_EQUIP_DISTRIBUTORS,
    GL_EXCEPTIONS.TOY_DISTRIBUTORS,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.SOCIAL_SERVICES_CONSULTING_ONLY_PRIVATE,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_NONRETURN,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_RETURNABLE,
    GL_EXCEPTIONS.COLOR_OR_PIGMENT_PREPARATION,
    GL_EXCEPTIONS.HONEY_EXTRACTING,
    GL_EXCEPTIONS.LAMPS_OR_LANTERNS_MFG_NON_ELECTRIC,
    GL_EXCEPTIONS.LIGHT_BULB_OR_TUBES_MFG,
    GL_EXCEPTIONS.PAPER_COATING_OR_FINISHING,
    GL_EXCEPTIONS.PET_FOOD_MFG,
    GL_EXCEPTIONS.PIPES_OR_TUBES_MFG_METAL,
    GL_EXCEPTIONS.VEGETABLE_OIL_MFG_NOC,
    GL_EXCEPTIONS.WATCH_OR_WATCH_CASE_MFG,
    GL_EXCEPTIONS.WINE_MFG_SPARKLING,
    GL_EXCEPTIONS.WINE_MFG_STILL,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.AUCTION_SALES_ON_OWNED_RENT_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.MACHINE_SHOP_NOC,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.SHEET_METAL_WORK_OUTSIDE,
    GL_EXCEPTIONS.SIDING_INSTALLATION,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  AL: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  AR: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.SPAS_OR_PERSONAL_ENHANCEMENT_FACILITIES,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.HANDYPERSON,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  AZ: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
  ],
  CA: [
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.SOCIAL_SERVICES_CONSULTING_ONLY_PRIVATE,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_NONRETURN,
    GL_EXCEPTIONS.COLOR_OR_PIGMENT_PREPARATION,
    GL_EXCEPTIONS.WINE_MFG_SPARKLING,
    GL_EXCEPTIONS.WINE_MFG_STILL,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.MACHINE_SHOP_NOC,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.SIDING_INSTALLATION,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  CO: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
  ],
  CT: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AIR_CONDITIONING_EQUIP_MFG,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  DC: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  DE: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  FL: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.CONVENTS_OR_MONASTERIES,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  GA: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  HI: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  IA: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  ID: [
    GL_EXCEPTIONS.CATALOG_OR_PREMIUM_COUPON_REDEM_STORES,
    GL_EXCEPTIONS.SPORTING_GOODS_OR_ATHL_EQUIP_DISTRIBUTORS,
    GL_EXCEPTIONS.TOY_DISTRIBUTORS,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.SOCIAL_SERVICES_CONSULTING_ONLY_PRIVATE,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_NONRETURN,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_RETURNABLE,
    GL_EXCEPTIONS.COLOR_OR_PIGMENT_PREPARATION,
    GL_EXCEPTIONS.HONEY_EXTRACTING,
    GL_EXCEPTIONS.LAMPS_OR_LANTERNS_MFG_NON_ELECTRIC,
    GL_EXCEPTIONS.LIGHT_BULB_OR_TUBES_MFG,
    GL_EXCEPTIONS.PAPER_COATING_OR_FINISHING,
    GL_EXCEPTIONS.PET_FOOD_MFG,
    GL_EXCEPTIONS.PIPES_OR_TUBES_MFG_METAL,
    GL_EXCEPTIONS.VEGETABLE_OIL_MFG_NOC,
    GL_EXCEPTIONS.WATCH_OR_WATCH_CASE_MFG,
    GL_EXCEPTIONS.WINE_MFG_SPARKLING,
    GL_EXCEPTIONS.WINE_MFG_STILL,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.AUCTION_SALES_ON_OWNED_RENT_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.MACHINE_SHOP_NOC,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.SHEET_METAL_WORK_OUTSIDE,
    GL_EXCEPTIONS.SIDING_INSTALLATION,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  IL: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  IN: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  KS: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.SPAS_OR_PERSONAL_ENHANCEMENT_FACILITIES,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.HANDYPERSON,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  KY: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  LA: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.SPAS_OR_PERSONAL_ENHANCEMENT_FACILITIES,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.HANDYPERSON,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  MA: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  MD: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  ME: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AIR_CONDITIONING_EQUIP_MFG,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  MI: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  MN: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  MO: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.SPAS_OR_PERSONAL_ENHANCEMENT_FACILITIES,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.HANDYPERSON,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  MS: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  MT: [
    GL_EXCEPTIONS.CATALOG_OR_PREMIUM_COUPON_REDEM_STORES,
    GL_EXCEPTIONS.SPORTING_GOODS_OR_ATHL_EQUIP_DISTRIBUTORS,
    GL_EXCEPTIONS.TOY_DISTRIBUTORS,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.SOCIAL_SERVICES_CONSULTING_ONLY_PRIVATE,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_NONRETURN,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_RETURNABLE,
    GL_EXCEPTIONS.COLOR_OR_PIGMENT_PREPARATION,
    GL_EXCEPTIONS.HONEY_EXTRACTING,
    GL_EXCEPTIONS.LAMPS_OR_LANTERNS_MFG_NON_ELECTRIC,
    GL_EXCEPTIONS.LIGHT_BULB_OR_TUBES_MFG,
    GL_EXCEPTIONS.PAPER_COATING_OR_FINISHING,
    GL_EXCEPTIONS.PET_FOOD_MFG,
    GL_EXCEPTIONS.PIPES_OR_TUBES_MFG_METAL,
    GL_EXCEPTIONS.VEGETABLE_OIL_MFG_NOC,
    GL_EXCEPTIONS.WATCH_OR_WATCH_CASE_MFG,
    GL_EXCEPTIONS.WINE_MFG_SPARKLING,
    GL_EXCEPTIONS.WINE_MFG_STILL,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.AUCTION_SALES_ON_OWNED_RENT_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.MACHINE_SHOP_NOC,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.SHEET_METAL_WORK_OUTSIDE,
    GL_EXCEPTIONS.SIDING_INSTALLATION,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  NC: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  ND: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  NE: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  NH: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AIR_CONDITIONING_EQUIP_MFG,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  NJ: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AIR_CONDITIONING_EQUIP_MFG,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  NM: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
  ],
  NV: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
  ],
  NY: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  OH: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  OK: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.SPAS_OR_PERSONAL_ENHANCEMENT_FACILITIES,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.HANDYPERSON,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  OR: [
    GL_EXCEPTIONS.CATALOG_OR_PREMIUM_COUPON_REDEM_STORES,
    GL_EXCEPTIONS.SPORTING_GOODS_OR_ATHL_EQUIP_DISTRIBUTORS,
    GL_EXCEPTIONS.TOY_DISTRIBUTORS,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.SOCIAL_SERVICES_CONSULTING_ONLY_PRIVATE,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_NONRETURN,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_RETURNABLE,
    GL_EXCEPTIONS.COLOR_OR_PIGMENT_PREPARATION,
    GL_EXCEPTIONS.HONEY_EXTRACTING,
    GL_EXCEPTIONS.LAMPS_OR_LANTERNS_MFG_NON_ELECTRIC,
    GL_EXCEPTIONS.LIGHT_BULB_OR_TUBES_MFG,
    GL_EXCEPTIONS.PAPER_COATING_OR_FINISHING,
    GL_EXCEPTIONS.PET_FOOD_MFG,
    GL_EXCEPTIONS.PIPES_OR_TUBES_MFG_METAL,
    GL_EXCEPTIONS.VEGETABLE_OIL_MFG_NOC,
    GL_EXCEPTIONS.WATCH_OR_WATCH_CASE_MFG,
    GL_EXCEPTIONS.WINE_MFG_SPARKLING,
    GL_EXCEPTIONS.WINE_MFG_STILL,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.AUCTION_SALES_ON_OWNED_RENT_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.MACHINE_SHOP_NOC,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.SHEET_METAL_WORK_OUTSIDE,
    GL_EXCEPTIONS.SIDING_INSTALLATION,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  PA: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  RI: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AIR_CONDITIONING_EQUIP_MFG,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  SC: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  SD: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  TN: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  TX: [
    GL_EXCEPTIONS.BUILDING_MATERIAL_DISTRIBUTORS,
    GL_EXCEPTIONS.SPAS_OR_PERSONAL_ENHANCEMENT_FACILITIES,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.HANDYPERSON,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  UT: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
  ],
  VT: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AIR_CONDITIONING_EQUIP_MFG,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.DRILLING_WATER,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  VA: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  WA: [
    GL_EXCEPTIONS.CATALOG_OR_PREMIUM_COUPON_REDEM_STORES,
    GL_EXCEPTIONS.SPORTING_GOODS_OR_ATHL_EQUIP_DISTRIBUTORS,
    GL_EXCEPTIONS.TOY_DISTRIBUTORS,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.SOCIAL_SERVICES_CONSULTING_ONLY_PRIVATE,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_NONRETURN,
    GL_EXCEPTIONS.BOTTLE_AND_JAR_MFG_PLASTIC_RETURNABLE,
    GL_EXCEPTIONS.COLOR_OR_PIGMENT_PREPARATION,
    GL_EXCEPTIONS.HONEY_EXTRACTING,
    GL_EXCEPTIONS.LAMPS_OR_LANTERNS_MFG_NON_ELECTRIC,
    GL_EXCEPTIONS.LIGHT_BULB_OR_TUBES_MFG,
    GL_EXCEPTIONS.PAPER_COATING_OR_FINISHING,
    GL_EXCEPTIONS.PET_FOOD_MFG,
    GL_EXCEPTIONS.PIPES_OR_TUBES_MFG_METAL,
    GL_EXCEPTIONS.VEGETABLE_OIL_MFG_NOC,
    GL_EXCEPTIONS.WATCH_OR_WATCH_CASE_MFG,
    GL_EXCEPTIONS.WINE_MFG_SPARKLING,
    GL_EXCEPTIONS.WINE_MFG_STILL,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.AUCTION_SALES_ON_OWNED_RENT_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.MACHINE_SHOP_NOC,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.SHEET_METAL_WORK_OUTSIDE,
    GL_EXCEPTIONS.SIDING_INSTALLATION,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  WV: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  WI: [
    GL_EXCEPTIONS.RESTAURANT_ALCH_SALE_30_GT_LT_75_TOTAL_ANNUAL,
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.MOBILE_HOME_PARKS_OR_COURTS,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
    GL_EXCEPTIONS.CONDUIT_CONSTRUCTION_CABLES_OR_WIRES,
    GL_EXCEPTIONS.PREFAB_BUILDING_ERECTION,
    GL_EXCEPTIONS.SAND_OR_GRAVEL_DIGGING,
    GL_EXCEPTIONS.WATER_MAINS_OR_CONNECT_CONSTRUCTION,
  ],
  WY: [
    GL_EXCEPTIONS.COMPUTER_CONSULTING_OR_PROGRAMMING,
    GL_EXCEPTIONS.ENTERTAINMENT_PERF_ON_OTHERS_PREMISES,
    GL_EXCEPTIONS.GOLF_COURSES_MINI,
    GL_EXCEPTIONS.EVENT_PARTY_OR_WEDDING_PLANNERS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS,
    GL_EXCEPTIONS.APARTMENT_BUILDINGS_GARDEN,
    GL_EXCEPTIONS.AUCTIONEERS_SALES_AWAY_FROM_INS_PREMISES,
  ],
};

export const CPSP_STATE_EXCEPTIONS: string[] = ['FL'];
