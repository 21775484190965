import { Observable, of as observableOf } from 'rxjs';

import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AttuneBopExcessQuotePayloadService } from './attune-bop-excess-quote-payload.service';
import { GWService } from 'app/bop/services/gw.service';
import { InsuredAccountService } from '../../insured-account/services/insured-account.service';
import { CurrentUserService } from '../../../core/services/current-user.service';
import { BopPricedQuote } from '../models/bop-priced-quote';
import { InsuredAccount } from '../../insured-account/models/insured-account.model';
import { BopPolicy, BopQuotePayload } from '../models/bop-policy';
import { RetrieveQuoteResponse, CovTerm } from 'app/bop/guidewire/typings';
import { translateGWExcessQuote } from 'app/bop/guidewire/retrieve-quote-translator';
import { V3_EXCESS_VIA_ZENDESK } from 'app/constants';
import { SentryService } from 'app/core/services/sentry.service';

export interface TranslatedQuoteResponse {
  retrievedQuote: RetrieveQuoteResponse;
  translatedQuote: BopQuotePayload['excessLiability'] | null;
}

@Injectable()
export class AttuneBopExcessQuoteService {
  constructor(
    private gwService: GWService,
    private currentUserService: CurrentUserService,
    private insuredAccountService: InsuredAccountService,
    private excessQuotePayloadService: AttuneBopExcessQuotePayloadService,
    private http: HttpClient,
    private sentryService: SentryService
  ) {}

  requestQuote(
    policy: BopPolicy,
    bopPricedQuote: BopPricedQuote
  ): Observable<BackendQuoteResponsePayload> {
    if (this.insuredAccountService.insuredSubject.getValue().id === '') {
      throw new Error('No insured account loaded, cannot generate Excess Quote payload.');
    }

    const currentUser = this.currentUserService.getCurrentUser();
    if (!currentUser) {
      throw new Error('Login Expired');
    }
    const insuredAccount: InsuredAccount = this.insuredAccountService.insuredSubject.getValue();

    const payload = this.excessQuotePayloadService.getExcessQuotePayload(
      insuredAccount,
      policy,
      currentUser,
      bopPricedQuote
    );

    return this.gwService.requestQuote(payload, 'Excess');
  }

  requestQuoteViaZendesk(
    bopPolicyWithExcess: BopPolicy,
    bopPricedQuote: BopPricedQuote
  ): Observable<any> {
    if (this.insuredAccountService.insuredSubject.getValue().id === '') {
      throw new Error('No insured account loaded, cannot generate Excess Quote payload.');
    }

    const insuredAccount: InsuredAccount = this.insuredAccountService.insuredSubject.getValue();
    const payload = this.excessQuotePayloadService.translateQuoteToManualSubmission(
      bopPolicyWithExcess,
      bopPricedQuote,
      insuredAccount
    );

    // Returned value is unused.
    return this.http.post<any>(V3_EXCESS_VIA_ZENDESK, payload).pipe(
      catchError((error) => {
        this.sentryService.notify(`Unable to create zendesk ticket to quote Excess Liability.`, {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getManualExcessZendeskId(
    bopQuoteNumber: string
  ): Observable<{ hasManualExcessRequest: boolean } | null> {
    return this.http
      .get<{
        hasManualExcessRequest: boolean;
      }>(`${V3_EXCESS_VIA_ZENDESK}/${bopQuoteNumber}`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify(`Unable to fetch manual excess quote zendesk ticket id.`, {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          // This shouldn't break the policy pane and prevent a user from binding.
          return observableOf(null);
        })
      );
  }

  patchUMUIM(jobId: string, covTerms: CovTerm[]) {
    return this.gwService.retrieveQuote(jobId).pipe(
      switchMap((quote) => {
        const policy = quote.return.QuoteSubmissionRequest.Periods.Entry[0];
        if (!policy.CommercialUmbrellaLine_CUE) {
          throw new Error('Attempting to put UMUIM on a non-excess policy!');
        }
        const clause = policy.CommercialUmbrellaLine_CUE.AllClauses.Entry.find(
          (c) => c.Pattern.Code === 'CUP_UM_UIM_CUE'
        );
        if (!clause) {
          throw new Error('Attempting to put UMUIM on an excess policy without UMUIM!');
        }
        // Patch
        clause.CovTerms = { Entry: covTerms };
        return this.gwService.requestQuote(
          {
            quoteRequestModel: quote.return,
            reRatewithRatingEngine: true,
          },
          'Excess - PatchUMUIM'
        );
      })
    );
  }

  public getTranslatedQuote(jobId: string): Observable<TranslatedQuoteResponse | null> {
    return this.gwService.retrieveQuote(jobId).pipe(
      map((response) => {
        return {
          retrievedQuote: response,
          translatedQuote: translateGWExcessQuote(response),
        };
      })
    );
  }
}
