/**
 * This Class is extended by each activity summary list component.
 * It contains shared helper methods.
 */
import { Component } from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';
import { CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  INSURED_CANCELLATION_REASONS,
  NOT_TAKEN_CANCELLATION_REASONS,
  PAYMENT_CANCELLATION_REASONS,
  UNDERWRITING_CANCELLATION_REASONS,
} from 'app/features/activity/models/constants';
import { PolicyCancellation } from 'app/bop/guidewire/typings';
@Component({
  template: '',
})
export class ActivityListBaseComponent {
  constructor(protected sentryService: SentryService, protected currencyPipe: CurrencyPipe) {}

  hasLoggedPolicyAmountError = false;

  buildArray(length: number): number[] {
    // Utility method to create an array, for use with ngFor in template
    return [...Array(length).keys()];
  }

  getFirstItemNumber(index: number) {
    return index + 1;
  }

  getLastItemNumber(index: number, policyCount: number, policyPageLength: number) {
    return Math.min(index + policyPageLength, policyCount || 0);
  }

  formatDate(date: string) {
    if (date) {
      return moment.utc(date).format('MM/DD/Y');
    }
    return 'Date not found';
  }

  formatCost(
    cost: string,
    accountNumber: string,
    policyNumber: string,
    policyType: 'renewal' | 'cancelled' | 'pendingCancellation' | 'recentQuote' | 'recentBind'
  ) {
    if (cost) {
      const parsedCost = parseInt(cost, 10);
      return this.currencyPipe.transform(parsedCost, 'USD', 'symbol', '1.0-0');
    }

    // The total cost being unavailable is an edge case that should almost never occur.
    // We log here so that if it begins happening frequently, we'll know.

    if (!this.hasLoggedPolicyAmountError) {
      this.sentryService.notify('Received policy summary without total cost information.', {
        severity: 'error',
        metaData: {
          affectedAccount: accountNumber,
          affectedPolicy: policyNumber,
          affectedPolicyType: policyType,
        },
      });
      this.hasLoggedPolicyAmountError = true;
    }

    return 'Unavailable';
  }

  getCancellationReason(policy: PolicyCancellation) {
    const reason = policy.Cancellation.CancelReasonDescription;

    if (PAYMENT_CANCELLATION_REASONS.includes(reason)) {
      return 'Non Payment';
    } else if (NOT_TAKEN_CANCELLATION_REASONS.includes(reason)) {
      return 'Not Taken';
    } else if (UNDERWRITING_CANCELLATION_REASONS.includes(reason)) {
      return 'Underwriting';
    } else if (INSURED_CANCELLATION_REASONS.includes(reason)) {
      return 'Insured Request';
    } else {
      return 'Unavailable';
    }
  }

  getCssClassForCancellationPillTag(policy: PolicyCancellation) {
    switch (this.getCancellationReason(policy)) {
      case 'Non Payment':
      case 'Not Taken':
        return 'pill-tag__bad';
      case 'Underwriting':
      case 'Unavailable':
        return 'pill-tag__warning';
      case 'Insured Request':
        return 'pill-tag__main';
    }
  }

  clearSummarySubscriptions(subscriptions: Subscription[]) {
    while (subscriptions.length) {
      const summarySubscription = subscriptions.pop();
      if (summarySubscription) {
        summarySubscription.unsubscribe();
      }
    }
  }
}
