<app-modal
  *ngIf="open"
  [open]="open"
  modalType="flyout"
  modalClass="app-modal-content__left-align"
  (handleModalClose)="closeModal.emit(false)"
>
  <div>
    <div>
      <main class="account-page" *ngIf="isFormReadyToMount">
        <h1>Edit account</h1>

        <form (ngSubmit)="editAccount()" novalidate>
          <div>
            <app-form-field-text
              inputId="companyName"
              [form]="form"
              [submitted]="submitted"
              labelText="Company name"
              nameOfFormControl="companyName"
              maxLength="60"
              (focusOut)="checkForBogusQuote()"
            >
            </app-form-field-text>

            <app-form-field-text
              inputId="doingBusinessAs"
              [form]="form"
              [submitted]="submitted"
              labelText="DBA or Operating Name"
              nameOfFormControl="doingBusinessAs"
              maxLength="60"
            >
            </app-form-field-text>
          </div>

          <app-dialog-box
            *ngIf="isBogusQuote"
            header="Is this a real insured account?"
            type="warning"
          >
            We only allow quoting for real businesses so please, no testing. Contact us if you'd
            like to schedule a demo or have questions.
          </app-dialog-box>

          <app-form-field-address
            labelText="Business mailing address"
            prefix="business"
            [form]="form"
            [submitted]="submitted"
          >
          </app-form-field-address>

          <app-form-field-textarea
            inputId="description"
            [form]="form"
            [submitted]="submitted"
            labelText="Detailed description of operations"
            specifierText="(optional)"
            nameOfFormControl="description"
            maxLength="170"
            rows="2"
          >
          </app-form-field-textarea>

          <ng-container>
            <div class="helper-card-container">
              <app-form-field-dropdown-search
                inputId="naics-code"
                labelText="Description of business"
                typeaheadPlaceholderText="Search classifications"
                [submitted]="submitted"
                [queryMethod]="getNaicsCodes"
                [formatter]="naicsFormatter"
                [showToolTip]="false"
                [targetFormControl]="form.get('naicsCode')"
              >
              </app-form-field-dropdown-search>
              <div class="helper-card" *ngIf="getCurrentState() !== 'CA'">
                <div class="pill-tag pill-tag__main">Note</div>
                <p>
                  <strong>Looking for Lessor's Risk? </strong>
                  <a (click)="addLROClass()" href="javascript:void(0);">Click here</a> to
                  auto-select the LRO classcode.
                </p>
              </div>
            </div>
          </ng-container>

          <div class="helper-card-container">
            <div class="form-split">
              <app-form-field-text
                inputId="phoneNumber"
                [form]="form"
                [submitted]="submitted"
                labelText="Insured phone number"
                nameOfFormControl="phoneNumber"
                appPhoneMask
              >
              </app-form-field-text>

              <app-form-field-text
                inputId="emailAddress"
                [form]="form"
                [submitted]="submitted"
                labelText="Insured email address"
                nameOfFormControl="emailAddress"
                appRemoveWhitespaceMask
              >
              </app-form-field-text>
            </div>

            <div class="helper-card">
              <div class="pill-tag pill-tag__main">Note</div>
              <p>Invoices will also be emailed to the binding broker.</p>
            </div>

            <app-form-field-text
              inputId="website"
              [form]="form"
              [submitted]="submitted"
              labelText="Website"
              nameOfFormControl="website"
              inputType="url"
            >
            </app-form-field-text>
          </div>
          <div
            *ngIf="
              form.controls.emailAddress.errors &&
              form.controls.emailAddress.errors.invalidEmailAddress
            "
            class="validation"
          >
            <span class="validation-message">Email Address must be a valid email address.</span>
          </div>
          <div class="form-footer">
            <div class="nav-button-group">
              <button type="submit" class="button button__primary" [disabled]="loading">
                <div class="button-loader" *ngIf="loading">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
                <span *ngIf="!loading">Save changes</span>
                <span *ngIf="loading">Saving changes...</span>
              </button>
              <span *ngIf="isFakeLoadingEligibility" class="nav-quote-button-loading">
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
            </div>
          </div>
          <div *ngIf="isDevMode" class="adminz-only">
            <pre>
              {{ form.value | json }}
            </pre>
          </div>
        </form>
      </main>
    </div>
  </div>
</app-modal>
