<section>
  <div
    class="policies-overview-pane policies-overview-pending-cancellation-pane"
    *ngIf="!policyCount"
  >
    <h3>Pending cancellation</h3>

    <table>
      <tbody>
        <tr>
          <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    class="policies-overview-pane policies-overview-pending-cancellation-pane"
    *ngIf="policyCount"
  >
    <div class="policies-overview-pane-header">
      <h3 [ngClass]="{ 'gray-text': getPendingCancellationCount() === 0 }">
        Pending cancellation
        <span *ngIf="getPendingCancellationCount() && hidePendingCancellations"
          >({{ getPendingCancellationCount() }})</span
        >
        <span class="gray-text" *ngIf="getPendingCancellationCount() === 0">(0)</span>
        <span
          class="more-info-tip tool-tip tool-tip__large"
          data-tooltip="Policies that have a scheduled cancellation effective date"
        >
          <span class="icon icon-question"></span>
        </span>
      </h3>

      <a
        class="policy-pane-toggle"
        *ngIf="getPendingCancellationCount() && !hidePendingCancellations"
        (click)="hidePendingCancellations = true"
        (keyup.enter)="hidePendingCancellations = true"
      >
        Hide
      </a>

      <a
        class="policy-pane-toggle"
        *ngIf="getPendingCancellationCount() && hidePendingCancellations"
        (click)="hidePendingCancellations = false"
        (keyup.enter)="hidePendingCancellations = false"
      >
        Show
      </a>
    </div>

    <table
      *ngIf="!hidePendingCancellations && getPendingCancellationCount()"
      class="js-pending-cancellation-list-table"
    >
      <thead>
        <th>Account</th>
        <th>Cancel date</th>
        <th>Reason</th>
        <th>Premium</th>
      </thead>
      <tbody>
        <ng-container *ngIf="loadingPendingCancellations">
          <tr
            *ngFor="let i of buildArray(estimatedPendingCancellationLength)"
            class="policies-overview-row js-pending-cancellation-loading-row"
          >
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell td__right">
              <div class="table-loading-cell"></div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!loadingPendingCancellations">
          <tr
            class="policies-overview-row"
            *ngFor="let pendingCancellation of pendingCancellations"
          >
            <td class="policies-overview-table-cell">
              {{ pendingCancellation.Cancellation.AccountName }}
            </td>
            <td class="policies-overview-table-cell">
              {{ formatDate(pendingCancellation.Cancellation.CancellationEffectiveDate) }}
            </td>
            <td class="policies-overview-table-cell js-pending-cancellation-reason-cell">
              <span class="pill-tag {{ getCssClassForCancellationPillTag(pendingCancellation) }}">{{
                getCancellationReason(pendingCancellation)
              }}</span>
            </td>
            <td class="policies-overview-table-cell">
              {{
                formatCost(
                  pendingCancellation.Cancellation.TotalPremium,
                  pendingCancellation.Cancellation.AccountNumber,
                  pendingCancellation.Cancellation.PolicyNumber,
                  'pendingCancellation'
                )
              }}
            </td>
            <td class="policies-overview-table-cell td__right">
              <a
                class="button button__small button__transparent button__no-margin"
                [routerLink]="getPendingCancellationRouterLink(pendingCancellation)"
                ><strong>DETAILS</strong></a
              >
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div
      class="policies-overview-page-selector js-policies-pending-cancellation-page-selector"
      *ngIf="!hidePendingCancellations && this.getPendingCancellationCount() > policyPageLength"
    >
      <a
        *ngIf="pendingCancellationSelectedIndex > 0"
        (click)="loadPreviousPendingCancellations()"
        (keyup.enter)="loadPreviousPendingCancellations()"
        class="js-policies-pending-cancellation-previous"
        ><span class="icon-arrow-left button-icon"></span
      ></a>
      <span class="gray-text" *ngIf="pendingCancellationSelectedIndex <= 0"
        ><span class="icon-arrow-left button-icon"></span
      ></span>

      <span>
        {{ getFirstItemNumber(pendingCancellationSelectedIndex) }}-{{
          getLastItemNumber(
            pendingCancellationSelectedIndex,
            getPendingCancellationCount(),
            policyPageLength
          )
        }}
      </span>
      <span class="gray-text"> of </span>
      <span> {{ getPendingCancellationCount() }} </span>

      <a
        *ngIf="pendingCancellationSelectedIndex + policyPageLength < getPendingCancellationCount()"
        (click)="loadNextPendingCancellations()"
        (keyup.enter)="loadNextPendingCancellations()"
        class="js-policies-pending-cancellation-next"
        ><span class="icon-arrow-right button-icon"></span
      ></a>
      <span
        class="gray-text"
        *ngIf="pendingCancellationSelectedIndex + policyPageLength >= getPendingCancellationCount()"
        ><span class="icon-arrow-right button-icon"></span
      ></span>
    </div>
  </div>
</section>
