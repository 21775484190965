export type ServicingInfoCarrier =
  | 'attune-gw-wc'
  | 'attune-gw-bop'
  | 'hiscox'
  | 'coalition'
  | 'employers';

export type ServicingInfoProduct =
  | 'Cyber Liability'
  | 'General Liability'
  | 'Professional Liability'
  | "Workers' Compensation";

interface ServicingItem {
  name: string;
  detail: string;
}

export interface ServicingInfo {
  displayName: string;
  servicingItems: ServicingItem[];
  quoteServicingItems: ServicingItem[];
}

export const SERVICING_INFO_BY_CARRIER: Record<ServicingInfoCarrier, ServicingInfo> = {
  hiscox: {
    displayName: 'Hiscox',
    servicingItems: [
      {
        name: 'General inquiries',
        detail:
          'Questions about your client’s policy? The Hiscox team is happy to answer your questions at <a href="tel:+18776555177">1-877-655-5177</a>.',
      },
      {
        name: 'Policy documents',
        detail:
          'Hiscox requires up to 24 hours to generate policy documents. Hiscox will send documents to you and your insured via email once they are generated.',
      },
      {
        name: 'Billing',
        detail:
          'Hiscox will send invoices directly to your client. Billing inquiries can be made by calling <a href="tel:+18776555177">1-877-655-5177</a>.',
      },
      {
        name: 'Post-bind servicing',
        detail:
          'Requests to endorse and cancel your client\'s policy can be made through the Hiscox partner portal <a href="https://www.hiscox.com/partner-agent/attuneinsurance/manage-policy" target="_blank">here</a>  or by calling <a href="tel:+18776555177">1-877-655-5177</a>.',
      },
      {
        name: 'Claims',
        detail:
          'Claims should be reported by calling <a href="tel:+18664248508">1-866-424-8508</a> or by filling out a form <a href="https://www.hiscox.com/manage-your-policy/claims-center" target="_blank">here</a>. More information about filing a claim can be found on your client\'s policy documents.',
      },
    ],
    quoteServicingItems: [
      {
        name: 'Pre-Bind',
        detail:
          'Now that you have a quote, if you have any questions, the Hiscox team is available at <a href="tel:+18776555177">1-877-655-5177</a>. To edit this Hiscox quote, please contact Hiscox directly at the previous number - this includes adding another location, adjusting limits, adding coverages, etc.',
      },
    ],
  },
  employers: {
    displayName: 'EMPLOYERS',
    servicingItems: [
      {
        name: 'General inquiries',
        detail:
          'Questions about your client\'s policy? Attune\'s Customer Care Team would be happy to answer your questions at <a href="tel:+18885304650">1-888-530-4650</a>.',
      },
      {
        name: 'Policy documents',
        detail:
          "EMPLOYERS requires 24-48 hours to generate policy documents. We will send documents to you, the binding broker, via email once they've generated.",
      },
      {
        name: 'Billing',
        detail:
          'EMPLOYERS will send invoices directly to your client via email and physical mail. Billing inquiries can be made by calling EMPLOYERS\' Customer Support team at <a href="tel:+18885304650">1-888-530-4650</a>.',
      },
      {
        name: 'Post-bind servicing',
        detail:
          'Requests to endorse or cancel your client\'s policy can be made via Attune\'s Help Center <a href="https://app.attuneinsurance.com/help-center" target="_blank">Form</a>.',
      },
      {
        name: 'Claims',
        detail:
          'Claims should be reported to EMPLOYERS directly by the insured. For more information, visit the EMPLOYERS Claims Services site <a href="https://www.employers.com/claims-services" target="_blank">here</a>.',
      },
    ],
    quoteServicingItems: [
      {
        name: 'Pre-Bind',
        detail:
          'Now that you have a quote, if you have any questions, the EMPLOYERS team is available at <a href="tel:+18885304650">1-888-530-4650</a>. To edit this EMPLOYERS quote, please complete the Attune\'s Help Center <a href="https://app.attuneinsurance.com/help-center" target="_blank">Form</a>.',
      },
    ],
  },
  coalition: {
    displayName: 'Coalition',
    servicingItems: [
      {
        name: 'General inquiries',
        detail:
          'Questions about your client\'s policy? The Coalition team is happy to answer your questions at <a href="tel:18338661337">1-833-866-1337</a>.',
      },
      {
        name: 'Policy documents',
        detail:
          "You'll be able to download your client's policy binder from the Attune Portal immediately after bind. You'll be able to download your client's policy document from the Attune Portal after the policyholder submits the signature bundle.",
      },
      {
        name: 'Billing',
        detail:
          'Attune will send your client an invoice immediately after bind. Billing inquiries can be made via Attune\'s Help Center Form or by calling Attune\'s Customer Support team at <a href="tel:+18885304650">1-888-530-4650</a>.',
      },
      {
        name: 'Post-bind servicing',
        detail:
          'Requests to endorse and cancel your client\'s policy can be made by calling Coalition at <a href="tel:18338661337">1-833-866-1337</a>',
      },
      {
        name: 'Claims',
        detail:
          'Claims should be reported by calling <a href="tel:18338661337">1-833-866-1337</a>. More information about filing a claim can be found in your client\'s policy documents.',
      },
    ],
    quoteServicingItems: [
      {
        name: 'Pre-Bind',
        detail:
          'Now that you have a quote, if you have additional questions, contact Attune Customer Care directly by submitting a request <a href="https://app.attuneinsurance.com/help-center" target="_blank">here</a> or calling <a href="tel:18885304650">1-888-530-4650</a>.',
      },
    ],
  },
  'attune-gw-wc': {
    displayName: 'Attune',
    servicingItems: [
      {
        name: 'General inquiries',
        detail:
          'Contact Attune Customer Care directly for all questions about this policy by submitting a request <a href="https://app.attuneinsurance.com/help-center" target="_blank">here</a> or calling <a href="tel:18885304650">1-888-530-4650</a>.',
      },
      {
        name: 'Policy documents',
        detail:
          "You'll be able to download your client's policy document from the Attune Portal immediately after bind.",
      },
      {
        name: 'Billing',
        detail:
          "Our invoices are billed upon bind and due 5 days after the effective date. Attune will send an invoice directly to the insured's email address.",
      },
      {
        name: 'Post-bind servicing',
        detail:
          'Requests to endorse and cancel your client\'s policy can be made by submitting a request <a href="https://app.attuneinsurance.com/help-center" target="_blank">here</a>.',
      },
      {
        name: 'Claims',
        detail:
          'Claims are handled by Builders and should be reported by calling <a href="tel:+18008839305">1-800-883-9305</a> or by filling out this <a href="https://ce.bldrs.com/Claims/ClaimEntry/GetClaims/W" target="_blank">form</a>. More information about filing a claim can be found on your client\'s policy documents.',
      },
    ],
    quoteServicingItems: [
      {
        name: 'Pre-Bind',
        detail:
          'Now that you have a quote, if you have additional questions, contact Attune Customer Care directly by submitting a request <a href="https://app.attuneinsurance.com/help-center" target="_blank">here</a> or calling <a href="tel:18885304650">1-888-530-4650</a>.',
      },
    ],
  },
  'attune-gw-bop': {
    displayName: 'Attune',
    servicingItems: [
      {
        name: 'General inquiries',
        detail:
          'Contact Attune Customer Care directly for all questions about this policy by submitting a request <a href="https://app.attuneinsurance.com/help-center" target="_blank">here</a> or calling <a href="tel:18885304650">1-888-530-4650</a>.',
      },
      {
        name: 'Policy documents',
        detail:
          "You'll be able to download your client's policy document from the Attune Portal immediately after bind.",
      },
      {
        name: 'Billing',
        detail:
          "Our invoices are billed upon bind and due 5 days after the effective date. Attune will send an invoice directly to the insured's email address.",
      },
      {
        name: 'Post-bind servicing',
        detail:
          'Requests to endorse and cancel your client\'s policy can be made by submitting a request <a href="https://app.attuneinsurance.com/help-center" target="_blank">here</a>.',
      },
      {
        name: 'Claims',
        detail:
          'Claims are handled by NARS and should be reported by calling <a href="tel:18003156090">1-800-315-6090</a> or by filling out this <a href="https://www.narisk.com/report-a-claim" target="_blank">form</a>. More information about filing a claim can be found on your client\'s policy documents.',
      },
    ],
    quoteServicingItems: [
      {
        name: 'Pre-Bind',
        detail:
          'Now that you have a quote, if you have additional questions, contact Attune Customer Care directly by submitting a request <a href="https://app.attuneinsurance.com/help-center" target="_blank">here</a> or calling <a href="tel:18885304650">1-888-530-4650</a>.',
      },
    ],
  },
};

const DO_NOT_SHOW_SERVICING_INFO_STORAGE_KEY = 'DO_NOT_SHOW_SERVICING_INFO';
const DO_NOT_SHOW_SERVICING_INFO_STORAGE_VALUE = 'true';

const storageKeyForCarrier = (carrier: ServicingInfoCarrier) =>
  `${DO_NOT_SHOW_SERVICING_INFO_STORAGE_KEY}_${carrier}`;

export const updateServicingDoNotShowAgainPreference = (
  carrier: ServicingInfoCarrier,
  shouldHideServicingInfoStep: boolean
) => {
  const localStorageKey = storageKeyForCarrier(carrier);
  if (shouldHideServicingInfoStep) {
    localStorage.setItem(localStorageKey, DO_NOT_SHOW_SERVICING_INFO_STORAGE_VALUE);
  } else {
    localStorage.removeItem(localStorageKey);
  }
};

export const getServicingInfoDoNotShowAgainPreference = (carrier: ServicingInfoCarrier) => {
  const localStorageKey = storageKeyForCarrier(carrier);

  return localStorage.getItem(localStorageKey) === DO_NOT_SHOW_SERVICING_INFO_STORAGE_VALUE;
};
