import { Component, Input } from '@angular/core';
import {
  InsuranceProductBadge,
  InsuranceProductIcon,
  PasSourceName,
  ProductName,
} from '../../features/digital-carrier/models/types';

type TooltipPosition = 'right' | 'bottom' | 'left';

@Component({
  selector: 'app-product-badge',
  templateUrl: './product-badge.component.html',
})
export class ProductBadgeComponent {
  @Input() product: InsuranceProductBadge;
  @Input() pasSource: InsuranceProductIcon;
  @Input() tooltipPosition: TooltipPosition = 'bottom';

  TOOL_TIP_POSITION_TO_CLASS: Record<TooltipPosition, string> = {
    right: '',
    bottom: 'tool-tip__bottom',
    left: 'tool-tip__left',
  };

  getToolTipText() {
    const product = this.getProductName() || '';
    const carrier = product === 'Flood Insurance' ? 'Neptune' : this.getCarrierName() || '';

    return `${carrier} ${product}`.trim();
  }

  getProductName(): ProductName | null {
    switch (this.product) {
      case 'bop':
      case 'bop-plus':
        return "Businessowners' Policy";
      case 'cpsp':
        return 'Custom Protector';
      case 'cyber_admitted':
      case 'cyber_surplus':
        return 'Cyber Liability';
      case 'flood':
        return 'Flood Insurance';
      case 'gl':
        return 'General Liability';
      case 'pl':
        return 'Professional Liability';
      case 'wc':
        return "Workers' Compensation";
      case 'xs':
        return 'Commercial Excess Liability Policy';
      default:
        return null;
    }
  }

  getCarrierName(): PasSourceName | null {
    switch (this.pasSource) {
      case 'attune_gw':
      case 'attune':
        return 'Attune';
      case 'coalition':
        return 'Coalition';
      case 'employers':
        return 'Employers';
      case 'hiscox':
        return 'Hiscox';
      case 'liberty_mutual':
        return 'Liberty Mutual';
      case 'neptune':
        return 'Neptune';
      default:
        return null;
    }
  }
}
