<div class="bar-chart" (window:resize)="this.renderBarChart()">
  <div class="bar-chart-left-labels">
    <p *ngFor="let datum of chartData.items" class="bar-chart-label">{{ datum.label }}</p>
  </div>
  <div class="bar-chart-canvas-container">
    <canvas
      #chartCanvas
      [attr.aria-label]="'Data visualization. ' + alternativeText"
      role="img"
    ></canvas>
    <div class="bar-chart-bottom-labels">
      <p *ngFor="let valueLabel of valueLabels" class="bar-chart-below-label">
        {{ valueLabel }}
      </p>
    </div>
  </div>
</div>
