import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_V4_BASE } from '../../../constants';
import { catchError, switchMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../core/services/feature-flag.service';
import { FrontendQuoteBundle } from '../../digital-carrier/models/types';
import { SentryService } from '../../../core/services/sentry.service';

const BUNDLE_URL_BASE = `${API_V4_BASE}/quotes/bundles`;

@Injectable({
  providedIn: 'root',
})
export class BundleService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private featureFlagService: FeatureFlagService
  ) {}

  getBundle(bundleUuid: string) {
    return this.http.get<FrontendQuoteBundle>(`${BUNDLE_URL_BASE}/${bundleUuid}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Error getting bundle', {
          severity: 'warning',
          metaData: {
            bundleId: bundleUuid,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  createBundle(accountId: string, bopUuid: string, cyberUuid: string) {
    return this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.BUNDLE_QUOTE_CREATION).pipe(
      switchMap((enabled) => {
        if (enabled) {
          return this.http
            .post<{ bundleUuid: string }>(
              BUNDLE_URL_BASE,
              {
                accountId: accountId,
                bopQuoteUuid: bopUuid,
                cyberQuoteUuid: cyberUuid,
              },
              {
                headers: { 'Content-type': 'application/json' },
              }
            )
            .pipe(
              catchError((error) => {
                this.sentryService.notify('Error creating bundle', {
                  severity: 'warning',
                  metaData: {
                    accountId: accountId,
                    underlyingErrorMessage: error && error.message,
                    underlyingError: error,
                  },
                });
                return observableOf(null);
              })
            );
        } else {
          return observableOf(null);
        }
      })
    );
  }

  deleteBundle(bundleUuid: string) {
    return this.http.delete(`${BUNDLE_URL_BASE}/${bundleUuid}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Error deleting bundle', {
          severity: 'warning',
          metaData: {
            bundleId: bundleUuid,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }
}
