import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { combineLatest, interval, Subscription } from 'rxjs';

import { scrollToTop } from 'app/shared/helpers/scroll-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AlertService } from 'app/core/services/alert.service';
import { VersionCheckService } from 'app/shared/services/version-check.service';
import { ZendeskService, ZendeskKey } from './shared/services/zendesk.service';
import { environment } from 'environments/environment';
import { CURRENT_GIT_SHA } from 'app/constants';
import { UserService } from './core/services/user.service';
import { CurrentUserService } from './core/services/current-user.service';
import { OktaAuthService } from './core/services/oktaAuth.service';
import { SentryService } from './core/services/sentry.service';
import { SegmentService } from './core/services/segment.service';
import { datadogRum } from '@datadog/browser-rum';
import { BOOLEAN_FLAG_NAMES, FeatureFlagService } from './core/services/feature-flag.service';
import { User } from 'app/shared/models/user';

// Pages which we immediately reload on reaching
// The main reason for this is to disable Sprig (which cannot be removed otherwise)
// This list should be kept in sync with the one in sprig.js
const reloadPages = ['/settings/commission'];

const FORCE_RELOAD_TRACKING_KEY = '_attune_portal__force_refresh';
const MAINTENANCE_REFRESH_RATE = 2 * 60 * 1000;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;
  constructor(
    public oktaAuth: OktaAuthService,
    public alertService: AlertService,
    private amplitudeService: AmplitudeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private versionCheckService: VersionCheckService,
    private zendeskService: ZendeskService,
    private userService: UserService,
    private currentUserService: CurrentUserService,
    private sentryService: SentryService,
    private segmentService: SegmentService,
    private featureFlagService: FeatureFlagService
  ) {}

  isAmplitudeInitalized = false;
  isPendoInitialized = false;
  isZendeskInitalized = false;
  isMaintenanceMode = false;
  isSegmentInitialized = false;
  isDdRumInitialized = false;

  private sub = new Subscription();

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sendPageSegmentEvent(event);
        this.navigationEnd(event);
      }
    });
    // Initialize polling of health endpoint to check for code version changes.
    if (
      environment.stage === 'production' ||
      environment.stage === 'staging' ||
      environment.stage === 'dev'
    ) {
      this.versionCheckService.initializeVersionCheck('/health').subscribe();
      this.pollForAlerts();
    }

    if ((<any>window).FS) {
      (<any>window).FS.setUserVars({
        currentGitSha: String(CURRENT_GIT_SHA).toLowerCase(),
      });
    }

    this.subscribeToMaintenance();
    this.oktaAuth.$isAuthenticated.subscribe((val) => (this.isAuthenticated = val));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private pollForAlerts() {
    this.sub.add(
      interval(MAINTENANCE_REFRESH_RATE).subscribe(() => {
        this.alertService.fetchAlert();
      })
    );
  }

  private subscribeToMaintenance() {
    this.alertService.isMaintenanceMode.subscribe((maintenanceEnabled) => {
      if (maintenanceEnabled && !this.isMaintenanceMode) {
        this.userService.redirectToLoginPage(this.activatedRoute?.snapshot?.fragment || '');
      }
      this.isMaintenanceMode = maintenanceEnabled;
    });
  }

  private navigationEnd(event: NavigationEnd) {
    this.alertService.fetchAlert();

    // Force-reload the page, using session storage to make sure this only happens once
    if (reloadPages.includes(event.urlAfterRedirects)) {
      if (!sessionStorage.getItem(FORCE_RELOAD_TRACKING_KEY)) {
        sessionStorage.setItem(FORCE_RELOAD_TRACKING_KEY, 'true');
        window.location.reload();
      }
    } else {
      sessionStorage.removeItem(FORCE_RELOAD_TRACKING_KEY);
    }

    if (!this.isAmplitudeInitalized) {
      const ampKey = environment.amplitudeKeys.agentPortal;

      this.amplitudeService.init(ampKey, { skipAskForUser: false });
      this.isAmplitudeInitalized = true;
    }

    if (!this.isZendeskInitalized) {
      const zendeskKey =
        environment.stage === 'production'
          ? ZendeskKey.AGENT_PORTAL_PRODUCTION
          : ZendeskKey.AGENT_PORTAL_SANDBOX;
      this.zendeskService.init(zendeskKey);
      this.isZendeskInitalized = true;
    }

    if (!this.isPendoInitialized) {
      if ((<any>window).pendo) {
        this.sub.add(
          combineLatest([
            this.featureFlagService.guaranteeIsEnabled(BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP),
            this.userService.getUser(),
          ]).subscribe(([attuneWcAvailable, user]: [boolean, User]) => {
            try {
              const visitorId =
                environment.stage === 'production'
                  ? user.userName
                  : environment.stage + '-' + user.userName;

              const accountId =
                environment.stage === 'production'
                  ? user.producer
                  : environment.stage + '-' + user.producer;

              (<any>window).pendo.initialize({
                visitor: {
                  id: visitorId,
                  email: user.userName,
                  full_name: user.firstName + ' ' + user.lastName,
                  producer_code: user.producer,
                  attune_wc_available: attuneWcAvailable || false,
                },
                account: {
                  id: accountId,
                },
              });
              this.isPendoInitialized = true;
            } catch (e) {
              this.sentryService.notify('Pendo: unable to initialize pendo', {
                severity: 'error',
                metaData: {
                  user,
                  underlyingErrorMessage: e && e.message,
                  underlyingError: e,
                },
              });
            }
          })
        );
      }
    }

    if (!this.isDdRumInitialized) {
      if ((<any>window).DD_RUM) {
        this.userService.getUser().subscribe((user) => {
          try {
            datadogRum.setUserProperty('producerCode', user.producer);
            this.isDdRumInitialized = true;
          } catch (e) {
            this.sentryService.notify('DD RUM: unable to initialize DD RUM', {
              severity: 'error',
              metaData: {
                user,
                underlyingErrorMessage: e && e.message,
                underlyingError: e,
              },
            });
          }
        });
      }
    }

    scrollToTop();
  }

  sendPageSegmentEvent(event: NavigationEnd) {
    if ((<any>window).analytics) {
      this.segmentService.page({
        currentUrl: event.url,
      });
    }
  }
}
