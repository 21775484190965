import { HappyPathFormData } from '../../../shared/form-dsl/constants/form-dsl-typings';
import { LmBopQuestion, LmBopFormStepPath } from './lm-bop-typings';
import * as moment from 'moment';

/* NOTE: Many fields below are set to an empty string. These are meant as placeholders to make it
   easier to add "happy path" values in the future.
  The values that *are* set below represent a complete, valid form.
*/
export const LM_BOP_HAPPY_PATH_FORM_DATA: HappyPathFormData<LmBopFormStepPath, LmBopQuestion> = {
  [LmBopFormStepPath.POLICY_INFO]: {
    [LmBopQuestion.EFFECTIVE_DATE]: moment().add(1, 'day').format('MM/DD/YYYY'),
    [LmBopQuestion.PRIMARY_RISK_STATE]: 'SC',
    [LmBopQuestion.INSURED_PHONE_NUMBER]: '8888888888',
    [LmBopQuestion.FORM_OF_BUSINESS]: 'AS',
    [LmBopQuestion.BOP_CLASS_CODE]: {
      name: 'Day Spas',
      code: '88573',
    },
    [LmBopQuestion.DESCRIPTION_OF_OPERATIONS]: 'Day spa services',
    [LmBopQuestion.YEAR_BUSINESS_STARTED]: '2010',
    [LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE]: '',
    [LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS]: '',
    [LmBopQuestion.ANNUAL_SALES_RECEIPTS]: '$500,000',
    [LmBopQuestion.LPG_SALES_RECEIPTS]: '',
    [LmBopQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS]: '5',
    [LmBopQuestion.PRIOR_COVERAGE]: 'YES',
    [LmBopQuestion.PRIOR_COVERAGE_REASON]: '',
    [LmBopQuestion.PRIOR_COVERAGE_DETAILS]: '',
    [LmBopQuestion.APPLICANT_SUBSIDIARIES]: 'NO',
    [LmBopQuestion.APPLICANT_SUBSIDIARIES_DETAILS]: '',
    [LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES]: 'NO',
    [LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS]: '',
    [LmBopQuestion.OTHER_BUSINESS_INSD_ELSEWHERE]: '',
    [LmBopQuestion.ARSON_CONVICTION]: 'NO',
    [LmBopQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS]: 'NO',
    [LmBopQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED]: 'NO',
    [LmBopQuestion.HOURS_OF_OPERATION]: '',
    [LmBopQuestion.SEASONAL_OPERATION]: '',
    [LmBopQuestion.OWN_PRODUCTS]: '',
    [LmBopQuestion.RARE_ANIMAL_TREATMENT]: '',
    [LmBopQuestion.PET_RESCUE]: '',
    [LmBopQuestion.HOME_CARE_SERVICES]: '',
    [LmBopQuestion.LEASED_EQUIPMENT]: '',
    [LmBopQuestion.LEASED_DETAILS]: '',
    [LmBopQuestion.LEASED_EQUIPMENT_DETAILS]: '',
    [LmBopQuestion.LEASED_OPERATOR_DETAILS]: '',
    [LmBopQuestion.REFURBISHED_EQUIPMENT]: '',
    [LmBopQuestion.REFURBISHED_EQUIPMENT_DETAILS]: '',
    [LmBopQuestion.COMPOUNDING_OR_MIXING]: '',
    [LmBopQuestion.COMPUTER_PROGRAMMING]: '',
    [LmBopQuestion.WEB_HOSTING]: '',
    [LmBopQuestion.RESTAURANT_EQUIP_INSTALL]: '',
    [LmBopQuestion.FRYING_ON_PREMISES_1]: '',
    [LmBopQuestion.FRYING_ON_PREMISES_2]: '',
    [LmBopQuestion.DONUT_FRYER]: '',
    [LmBopQuestion.UL_300_EXTINGUISHING_SYSTEM]: '',
    [LmBopQuestion.EXTINGUISHING_SYSTEM_MAINTENANCE]: '',
    [LmBopQuestion.DONUT_FRYER_UL_PROTECTED]: '',
    [LmBopQuestion.INCIDENTAL_COMMERCIAL_COOKING_OPERATIONS]: '',
    [LmBopQuestion.DEEP_FAT_FRYER_NUMBER]: '',
    [LmBopQuestion.CPUL_300_EXTINGUISHING_SYSTEM]: '',
    [LmBopQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL]: '',
    [LmBopQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE]: '',
    [LmBopQuestion.LRO_OFFICES]: '',
    [LmBopQuestion.BEAUTY_SHOP]: '',
    [LmBopQuestion.LEARNING_CENTERS_EXTRA_CURRICULAR]: '',
    [LmBopQuestion.LEARNING_CENTER_BACKGROUND_CHECK]: '',
    [LmBopQuestion.INSURED_OR_EMPLOYEE_SERVE_ALCOHOL]: '',
    [LmBopQuestion.INSURED_THIRD_PARTY_SERVE_ALCOHOL]: '',
    [LmBopQuestion.INSURED_ON_THIRD_PARTY_INSURANCE]: '',
    [LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_2]: '',
    [LmBopQuestion.THREE_D_PRINTING]: '',
    [LmBopQuestion.GREENHOUSE_PRESENT]: '',
    [LmBopQuestion.PERCENTAGE_OF_IMPORTED_MERCHANDISE]: '',
    [LmBopQuestion.MOTORIZED_CONVEYANCE]: '',
    [LmBopQuestion.PERFORMING_REPAIRS]: '',
    [LmBopQuestion.GROSS_RECEIPTS_FROM_ASSEMBLING_BICYCLES]: '',
    [LmBopQuestion.ATYPICAL_DAY_SPA_SERVICES_PROVIDED]: 'NO',
    [LmBopQuestion.INVOLVE_JOBSITE_MANAGEMENT]: '',
    [LmBopQuestion.HAS_WATERCRAFT_SALE_LIABILITY]: '',
    [LmBopQuestion.PROFESSIONAL_SERVICES_MEDICAL_LIABILITY]: '',
    [LmBopQuestion.PROFESSIONAL_SERVICES_LIABILITY]: '',
    [LmBopQuestion.DRUGGIST_BACKGROUND_CHECKS]: '',
    [LmBopQuestion.MEDICINAL_MARIJUANA_OPERATIONS_TYPE]: '',
  },
  [LmBopFormStepPath.LOSS_HISTORY]: {
    [LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED]: 'NO',
    [LmBopQuestion.LOSSES]: [],
    [LmBopQuestion.HAS_SINKHOLE_CLAIMS]: 'NO',
    [LmBopQuestion.HAS_SINKHOLE_DAMAGE]: 'NO',
  },
  [LmBopFormStepPath.LOCATION_DETAILS]: {
    [LmBopQuestion.BUSINESS_ADDRESS]: {
      addressLine1: '3630 Festival Drive',
      addressLine2: '',
      city: 'Fort Mill',
      state: 'SC',
      zip: '29708',
    },
    [LmBopQuestion.INTEREST]: 'TENANT',
    [LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE]: '$0',
    [LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT]: '$50,000',
    [LmBopQuestion.NUMBER_STORIES]: '2',
    [LmBopQuestion.AREA_OCCUPIED_BY_INSURED]: '1500',
    [LmBopQuestion.AREA_UNOCCUPIED]: '0',
    [LmBopQuestion.AREA_UNOCCUPIED_DETAILS]: '',
    [LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS]: '0',
    [LmBopQuestion.NAMED_INSURED_ON_GL_POLICY]: '',
    [LmBopQuestion.TENANT_OPERATIONS_TYPE]: '',
    [LmBopQuestion.TOTAL_RECEIPTS_FOR_LOCATION]: '$500,000',
    [LmBopQuestion.TOTAL_SALES_FROM_DIRECTLY_IMPORTED]: '',
    [LmBopQuestion.LOCATION_WEB_SALES]: '',
    [LmBopQuestion.MAJORITY_INSURED_SALES]: '',
    [LmBopQuestion.LOCATION_FIREARME_SALES]: '',
    [LmBopQuestion.CONSTRUCTION_TYPE]: 'F',
    [LmBopQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION]: 'NO',
    [LmBopQuestion.ROOF_CONSTRUCTION]: 'ASPHS',
    [LmBopQuestion.ROOF_TYPE]: 'WR',
    [LmBopQuestion.YEAR_ROOF_REPLACED]: '2010',
    [LmBopQuestion.YEAR_BUILT]: '2010',
    [LmBopQuestion.HEATING_YEAR]: '',
    [LmBopQuestion.PLUMBING_YEAR]: '',
    [LmBopQuestion.WIRING_YEAR]: '',
    [LmBopQuestion.ALARM_TYPE]: 'CEN',
    [LmBopQuestion.ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING]: '',
    [LmBopQuestion.PERCENT_HABITATIONAL_OCCUPANTS]: '',
    [LmBopQuestion.HAS_LEAD_ABATEMENT]: '',
    [LmBopQuestion.FULL_TIME_BEAUTICIANS_AT_THIS_LOCATION]: '1',
    [LmBopQuestion.PART_TIME_BEAUTICIANS_AT_THIS_LOCATION]: '1',
    [LmBopQuestion.FULL_TIME_BARBERS_AT_THIS_LOCATION]: '1',
    [LmBopQuestion.PART_TIME_BARBERS_AT_THIS_LOCATION]: '1',
    [LmBopQuestion.MANICURISTS_AT_THIS_LOCATION]: '1',
    [LmBopQuestion.ANNUAL_DRY_CLEANING_RECEIPTS_AT_THIS_LOCATION]: '',
    [LmBopQuestion.PROOF_READ]: '',
    [LmBopQuestion.SERVICE_WORK_SALES_1]: '',
    [LmBopQuestion.SERVICE_WORK_SALES_2]: '',
    [LmBopQuestion.SERVICE_WORK_SALES_3]: '',
    [LmBopQuestion.SERVICE_WORK_SALES_4]: '',
    [LmBopQuestion.SERVICE_WORK_DETAILS]: '',
    [LmBopQuestion.FURNITURE_INSTALLATION_PERCENT]: '',
    [LmBopQuestion.SECONDHAND_MATERIALS]: '',
    [LmBopQuestion.EMPLOYEE_ON_DUTY]: '',
    [LmBopQuestion.LOCATION_ADULT_MATERIAL_SALES]: '',
    [LmBopQuestion.CLEAN_EQUIPMENT_ACCORDING_TO_MANUFACTURER]: 'YES',
    [LmBopQuestion.REPACKAGING_PRODUCTS]: 'NO',
    [LmBopQuestion.RECREATIONAL_EQUIPMENT]: '',
    [LmBopQuestion.RESTAURANT_BOH_ACTIVITIES]: '',
    [LmBopQuestion.OPERATIONS_INVOLVE_FLAMMABLES]: '',
    [LmBopQuestion.USE_OF_FLAMMABLES]: '',
    [LmBopQuestion.BPP_INCLUDES_EQUIP_OVER_250]: '',
  },
  [LmBopFormStepPath.LIABILITY_COVERAGE]: {
    [LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES]: false,
    [LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT]: '',
    [LmBopQuestion.CYBER_COVERAGE]: '',
    [LmBopQuestion.EPLI]: false,
    [LmBopQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES]: '',
    [LmBopQuestion.EPLI_CLAIMS]: '',
    [LmBopQuestion.LIQUOR_COVERAGE]: false,
    [LmBopQuestion.LIQUOR_LIABILITY_TYPE]: '',
    [LmBopQuestion.TOTAL_ANNUAL_LIQUOR_RECEIPTS_AT_THIS_LOCATION]: '',
    [LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_1]: '',
    [LmBopQuestion.LIQUOR_WRITTEN_GUIDELINES]: '',
    [LmBopQuestion.HEARING_AID_PROFESSIONAL_LIABILITY]: '',
    [LmBopQuestion.NUMBER_OF_PROFESSIONALS_HEARING_AID_PROFESSIONAL_LIABILITY]: '',
    [LmBopQuestion.OPTICAL_PROFESSIONAL_LIABILITY]: '',
    [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTICAL_PROFESSIONAL_LIABILITY]: '',
    [LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY]: '',
    [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTOMETRISTS_PROFESSIONAL_LIABILITY]: '',
    [LmBopQuestion.OPTOMETRISTS_SURGERY]: '',
    [LmBopQuestion.OPTOMETRIST_LICENSED]: '',
    [LmBopQuestion.OPTOMETRIST_PRESCRIPTION]: '',
    [LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY]: false,
    [LmBopQuestion.HIRED_AUTO_PHYSICAL_DAMAGE]: '',
    [LmBopQuestion.HAS_DELIVERY_SERVICE]: '',
    [LmBopQuestion.OWNED_AUTO]: '',
    [LmBopQuestion.PERSONAL_AUTO_EMPLOYEE_USE]: '',
    [LmBopQuestion.EMP_COV_MIN_REQUIRED]: '',
    [LmBopQuestion.APPLIANCE_DELIVERY_SERVICE]: '',
    [LmBopQuestion.UNINSURED_UNDERINSURED_MOTORIST_LIMIT]: '',
    [LmBopQuestion.UM_UIM_LOSS_TYPE]: '',
    [LmBopQuestion.PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY]: '',
    [LmBopQuestion.PRINTERS_ANNUAL_RECEIPTS_AT_THIS_LOCATION_PRINTERS_E_O]: '',
    [LmBopQuestion.PRINTERS_WORK_CORRECTION_PRINTERS_E_O]: '',
    [LmBopQuestion.LIMIT_OF_INSURANCE_PRINTERS_WORK_CORRECTION]: '',
    [LmBopQuestion.VETERINARIAN_PROFESSIONAL_LIABILITY]: '',
    [LmBopQuestion.NUMBER_OF_EMPLOYEES_VETERINARIANS_ASSISTANTS_VETERINARIANS_STAFF]: '',
    [LmBopQuestion.NUMBER_OF_VETERINARIANS_OWNERS_PARTNERS_OFFICERS]: '',
  },
};
