<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div
    class="{{ cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(nameOfFormControl) }"
  >
    <label [for]="inputId">
      {{ labelText }}
      <div
        *ngIf="tooltipText"
        class="more-info-tip tool-tip tool-tip__large"
        attr.data-tooltip="{{ tooltipText }}"
      >
        <span class="icon icon-question"></span>
      </div>
      <ng-container *ngIf="additionalQuestionText">
        <br />
        {{ additionalQuestionText }}
      </ng-container>
      <small class="gray-text" *ngIf="subLabelText">{{ subLabelText }}</small>
    </label>
    <div class="trad-radio {{ questionCssClass }}" [id]="inputId" *ngIf="!displayOnlyKey">
      <div
        *ngFor="let optionKey of getOptions() | keys"
        class="trad-radio-option {{ optionsCssClass }}"
      >
        <input
          [formControl]="form.get(nameOfFormControl)"
          [id]="inputId + '-' + optionKey"
          [name]="inputId"
          type="radio"
          [value]="getOptions()[optionKey]"
          [ngClass]="{ 'trad-radio__readonly': readonly }"
          [readonly]="readonly || null"
          [attr.disabled]="optionKey === defaultDisabledKey || readonly ? true : null"
        />
        <label
          [for]="inputId + '-' + optionKey"
          class="trad-radio-label traditional-radio-question-label {{ optionsLabelCssClass }}"
          [attr.data-amplitude-input-name]="inputId"
        >
          <ng-container *ngIf="!showLabelWithoutValue">
            <span *ngIf="!optionDescriptions" class="trad-radio-label">{{ optionKey }}</span>
            <div *ngIf="optionDescriptions" class="trad-radio-label">
              <strong>{{ optionKey }}</strong>
              <p>{{ optionDescriptions[optionKey] }}</p>
            </div>
            <span *ngIf="optionPillTags[optionKey]" class="pill-tag pill-tag__success ml-2">{{
              optionPillTags[optionKey]
            }}</span>
          </ng-container>
          <ng-container *ngIf="showLabelWithoutValue">
            <span class="trad-radio-label" [innerHTML]="optionDescriptions[optionKey]"> </span>
            <ng-container *ngIf="optionAdditionalColumns">
              <span
                *ngFor="let column of optionAdditionalColumns[optionKey]"
                [innerHTML]="column.innerHTML"
                class="trad-radio-label {{ column.cssClass }}"
              ></span>
            </ng-container>
          </ng-container>
        </label>
      </div>
    </div>

    <div class="trad-radio {{ questionCssClass }}" [id]="inputId" *ngIf="displayOnlyKey">
      <div
        class="trad-radio-option {{ optionsCssClass }} {{
          isDisplayOnlyKeySelected() ? displayOnlyKeySelectedCssClass : ''
        }}"
      >
        <input
          [formControl]="form.get(nameOfFormControl)"
          [id]="inputId + '-' + displayOnlyKey"
          [name]="inputId"
          type="radio"
          [value]="getOptions()[displayOnlyKey]"
          [ngClass]="{ 'trad-radio__readonly': readonly }"
          [readonly]="readonly"
          [attr.disabled]="displayOnlyKey === defaultDisabledKey ? true : null"
        />
        <label
          [for]="inputId + '-' + displayOnlyKey"
          class="trad-radio-label traditional-radio-question-label {{ optionsLabelCssClass }}"
          [attr.data-amplitude-input-name]="inputId"
        >
          <ng-container *ngIf="!showLabelWithoutValue">
            <span *ngIf="!optionDescriptions" class="trad-radio-label">{{ displayOnlyKey }}</span>
            <div *ngIf="optionDescriptions" class="trad-radio-label">
              <strong>{{ displayOnlyKey }}</strong>
              <p>{{ optionDescriptions[displayOnlyKey] }}</p>
            </div>
          </ng-container>
          <span
            *ngIf="showLabelWithoutValue"
            class="trad-radio-label"
            [innerHTML]="optionDescriptions[displayOnlyKey]"
          >
            {{ showLabelWithoutValue }}
          </span>
          <ng-content select="[note]"> </ng-content>
        </label>
        <ng-content select="[side]"> </ng-content>
      </div>
    </div>

    <div *ngIf="fieldInvalid(nameOfFormControl) && showErrorText" class="form-field-error-text">
      {{ errorText }}
    </div>
  </div>
</ng-container>
