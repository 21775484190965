import { Component, OnInit, Input } from '@angular/core';
import {
  bonusBarBackground,
  TIER_INDICATOR_GRAY_COLOR,
  TIER_INDICATOR_GREEN_COLOR,
} from '../../../../shared/helpers/style-helpers';

export interface Milestone {
  value: string;
  label: string;
  icon?: string;
  tooltip?: string;
}

export interface BindGoalsConfig {
  indicatorTooltip?: string;
  text?: string;
  percentage: number;
  milestones: Milestone[];
}

@Component({
  selector: 'app-activity-incentive-cyber-binds',
  templateUrl: 'activity-incentive-cyber-binds.component.html',
})
export class ActivityIncentiveCyberBindsComponent implements OnInit {
  constructor() {}

  @Input()
  config: BindGoalsConfig;
  progressBarStyle = '';

  ngOnInit() {
    this.progressBarStyle = bonusBarBackground(this.config.percentage);
  }

  isMilestoneFulfilled(index: number) {
    const percentage = this.config.percentage / 100;
    const indexPercentage = index / (this.config.milestones.length - 1);
    return percentage > indexPercentage;
  }

  getMilestoneIndicatorColor(index: number) {
    return this.isMilestoneFulfilled(index)
      ? TIER_INDICATOR_GREEN_COLOR
      : TIER_INDICATOR_GRAY_COLOR;
  }
  getIcon(icon: string) {
    return `/assets/img/${icon}.svg`;
  }
}
