import { AbstractPolicyPayment } from 'app/shared/models/abstract-policy-payment';
import { DEFAULT_INSTALLMENT_FEE_AFTER_DOWN_PAYMENT } from 'app/workers-comp/attune/constants';

export interface AttuneWcGwPolicyPaymentParams {
  numberOfPayments: number;
  downPaymentPercentage: number;
  totalPremium: number;
  totalTaxes: number;
}

export class AttuneWcGWPolicyPayment extends AbstractPolicyPayment {
  installments: number;
  downPaymentPercentage: number;

  // installment fee does not apply to the initial payment aka down payment/deposit.
  installmentFeeAfterDownPayment: number = DEFAULT_INSTALLMENT_FEE_AFTER_DOWN_PAYMENT;
  downPaymentPremium: number;
  totalTaxes: number;
  downPaymentSubtotal: number;
  installmentPremium: number;
  // installment total includes the installment fee.
  installmentTotal: number;

  totalDollars: number;
  totalCents: number;

  constructor(paymentOptions: AttuneWcGwPolicyPaymentParams) {
    super(paymentOptions.totalPremium);
    this.initialize(paymentOptions);
  }

  private initialize(paymentOptions: AttuneWcGwPolicyPaymentParams) {
    const { numberOfPayments, downPaymentPercentage, totalTaxes } = paymentOptions;
    this.installments = numberOfPayments - 1;
    this.downPaymentPercentage = downPaymentPercentage;
    this.totalTaxes = totalTaxes;

    // NOTE: The order of these calculations matters.
    this.downPaymentPremium = this.getDownPaymentPremium();
    this.downPaymentSubtotal = this.getDownPaymentSubtotal();
    this.installmentPremium = this.getInstallment(numberOfPayments);
    this.installmentTotal = this.installmentPremium + this.installmentFeeAfterDownPayment;
    this.totalDollars = this.getTotalDollars();
    this.totalCents = this.getTotalCents();
  }

  updatePaymentPlan(numberOfPayments: number, downPaymentPercentage: number) {
    this.initialize({
      downPaymentPercentage: downPaymentPercentage,
      numberOfPayments: numberOfPayments,
      // These values remain the same.
      totalPremium: this.totalPremium,
      totalTaxes: this.totalTaxes,
    });
  }

  public getDownPaymentPremium() {
    return this.downPaymentPercentage * this.totalPremium;
  }

  public getInstallment(numberOfPayments: number) {
    if (numberOfPayments === 1) {
      return 0;
    }

    const numberOfInstallments = numberOfPayments - 1;
    const premiumAfterDownPayment = this.totalPremium - this.getDownPaymentPremium();
    return premiumAfterDownPayment / numberOfInstallments;
  }

  private getDownPaymentSubtotal() {
    return this.downPaymentPremium + this.totalTaxes;
  }

  private getTotalDollars() {
    return Math.trunc(this.getEstimatedTotal());
  }

  private getTotalCents() {
    return (this.getEstimatedTotal() - this.totalDollars) * 100;
  }

  private getEstimatedTotal() {
    // If we ever add fees they will need to be added to this total as well.
    return this.downPaymentPremium + this.installments * this.installmentTotal + this.totalTaxes;
  }
}
