import { OnInit, Component, OnDestroy } from '@angular/core';
import { AmplitudeService } from '../../../core/services/amplitude.service';
import { AmplitudePayloadType, INPUT_PAGE_ENTER } from '../../../core/constants/amplitude-helpers';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SentryService } from '../../../core/services/sentry.service';
import { Subscription, of as observableOf } from 'rxjs';
import {
  CancellationResponseInfo,
  EndorsementResponseInfo,
  PolicyAutomationLandingService,
  ResponseInfo,
  VerifyErrorResponse,
  VerifyResponse,
} from '../../services/policy-automation-landing.service';
import { catchError } from 'rxjs/operators';
import { CUSTOMER_CARE_HOURS } from 'app/constants';

export enum HelpCenterInquiryType {
  ENDORSEMENT = 'Endorsement',
  CANCEL = 'Cancellation',
  UNKNOWN = 'Unknown',
}

export enum LinkStatus {
  REVIEW = 'Review',
  CONFIRM = 'Confirm',
  WITHDRAW = 'Withdraw',
  EXPIRED = 'Expired',
  ERROR = 'Error',
}

@Component({
  selector: 'app-policy-automation-landing.app-page',
  templateUrl: './policy-automation-landing.component.html',
})
export class PolicyAutomationLandingComponent implements OnInit, OnDestroy {
  isLoadingResponseInfo = false;
  loadedResponseInfo: ResponseInfo;
  linkStatus: LinkStatus;
  inquiryType: HelpCenterInquiryType;
  requesterEmail: string;
  linkId: string;
  customerCareHours = CUSTOMER_CARE_HOURS;
  private sub = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService,
    private landingService: PolicyAutomationLandingService,
    private http: HttpClient,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.amplitudeService.submitEvent({
      input: INPUT_PAGE_ENTER,
      type: AmplitudePayloadType.Page,
      value: `/bop/automation`,
    });

    this.loadLinkResponseInfo(this.route.snapshot.params.linkId);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadLinkResponseInfo(linkId: string) {
    if (linkId) {
      this.isLoadingResponseInfo = true;
      this.linkId = linkId;

      this.sub.add(
        this.landingService
          .verifyLink(linkId)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              return observableOf({ status: error.status });
            })
          )
          .subscribe((response) => {
            this.isLoadingResponseInfo = false;

            if (this.isErrorResponse(response)) {
              this.inquiryType = HelpCenterInquiryType.UNKNOWN;

              if (response.status === 400) {
                this.linkStatus = LinkStatus.EXPIRED;
              } else {
                this.linkStatus = LinkStatus.ERROR;
              }
            } else if (this.isVerifyResponse(response)) {
              this.requesterEmail = response.requesterEmail;

              if (response.rejected) {
                this.linkStatus = LinkStatus.WITHDRAW;
              } else if (response.confirmed) {
                this.linkStatus = LinkStatus.CONFIRM;
              } else {
                this.linkStatus = LinkStatus.REVIEW;
              }

              if (this.isCancellationResponse(response.linkInfo)) {
                this.inquiryType = HelpCenterInquiryType.CANCEL;
                this.loadedResponseInfo = response.linkInfo;
              } else if (this.isEndorsementResponse(response.linkInfo)) {
                this.inquiryType = HelpCenterInquiryType.ENDORSEMENT;
                this.loadedResponseInfo = response.linkInfo;
              } else {
                this.sentryService.notify(
                  'HCF Automation: Verify HC link response is in invalid format',
                  {
                    severity: 'error',
                    metaData: {
                      linkInfo: response.linkInfo,
                    },
                  }
                );
                this.inquiryType = HelpCenterInquiryType.UNKNOWN;
                this.linkStatus = LinkStatus.ERROR;
              }
            }
          })
      );
    } else {
      this.isLoadingResponseInfo = false;
      this.linkStatus = LinkStatus.ERROR;
      this.inquiryType = HelpCenterInquiryType.UNKNOWN;
    }
  }

  isResponseInfo(responseInfo: any): responseInfo is ResponseInfo {
    return responseInfo;
  }

  isErrorResponse(response: any): response is VerifyErrorResponse {
    return response && (response as VerifyErrorResponse).status !== undefined;
  }

  isVerifyResponse(response: any): response is VerifyResponse {
    return response && (response as VerifyResponse).linkInfo !== undefined;
  }

  isCancellationResponse(responseInfo: any): responseInfo is CancellationResponseInfo {
    return responseInfo && (responseInfo as CancellationResponseInfo).refundMethod !== undefined;
  }

  isEndorsementResponse(responseInfo: any): responseInfo is EndorsementResponseInfo {
    return responseInfo && (responseInfo as EndorsementResponseInfo).costChange !== undefined;
  }

  isEndorsement() {
    return this.inquiryType === HelpCenterInquiryType.ENDORSEMENT;
  }

  isCancellation() {
    return this.inquiryType === HelpCenterInquiryType.CANCEL;
  }

  isError() {
    return this.linkStatus === LinkStatus.ERROR || this.linkStatus === LinkStatus.EXPIRED;
  }

  isWithdrawn() {
    return this.linkStatus === LinkStatus.WITHDRAW;
  }

  handleStatusChange(value: LinkStatus) {
    this.linkStatus = value;
  }

  getErrorPageHeaderText() {
    if (this.linkStatus === LinkStatus.EXPIRED) {
      return 'Link Expired';
    }

    if (this.inquiryType === HelpCenterInquiryType.ENDORSEMENT) {
      return 'Endorsement Failed';
    } else if (this.inquiryType === HelpCenterInquiryType.CANCEL) {
      return 'Cancellation Failed';
    } else {
      return 'Invalid Link';
    }
  }

  getErrorDialogHeaderText() {
    if (this.linkStatus === LinkStatus.EXPIRED) {
      return 'It looks like this link is no longer valid';
    } else if (
      this.linkStatus === LinkStatus.ERROR &&
      this.inquiryType === HelpCenterInquiryType.UNKNOWN
    ) {
      return "It looks like that link isn't quite right";
    } else {
      return 'Oops! Something went wrong';
    }
  }

  getErrorMessageText() {
    if (this.linkStatus === LinkStatus.EXPIRED) {
      return 'To contact our Customer Support team with questions about this request, reply to the email that brought you to this link or give us a call at the number below.';
    }

    if (this.inquiryType === HelpCenterInquiryType.ENDORSEMENT) {
      return 'To bind and receive your endorsement document, please reach out to our Customer Care Team using the details below.';
    } else if (this.inquiryType === HelpCenterInquiryType.CANCEL) {
      return 'To cancel and receive your cancellation document, please reach out to our Customer Care Team using the details below.';
    } else {
      return 'For help processing your request, please reach out to our Customer Care Team using the details below.';
    }
  }
}
