<section>
  <div class="policies-overview-pane policies-overview-cancelled-pane" *ngIf="!policyCount">
    <h3>Recently cancelled</h3>

    <table>
      <tbody>
        <tr>
          <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="policies-overview-pane policies-overview-cancelled-pane" *ngIf="policyCount">
    <div class="policies-overview-pane-header">
      <h3 [ngClass]="{ 'gray-text': getCancellationCount() === 0 }">
        Recently cancelled
        <span *ngIf="getCancellationCount() && hideCancellations"
          >({{ getCancellationCount() }})</span
        >
        <span class="gray-text" *ngIf="getCancellationCount() === 0">(0)</span>
        <span
          class="more-info-tip tool-tip tool-tip__large"
          data-tooltip="Policies that have cancellation effective dates in the previous 30 days"
        >
          <span class="icon icon-question"></span>
        </span>
      </h3>

      <a
        class="policy-pane-toggle"
        *ngIf="getCancellationCount() && !hideCancellations"
        (click)="hideCancellations = true"
        (keyup.enter)="hideCancellations = true"
      >
        Hide
      </a>

      <a
        class="policy-pane-toggle"
        *ngIf="getCancellationCount() && hideCancellations"
        (click)="hideCancellations = false"
        (keyup.enter)="hideCancellations = false"
      >
        Show
      </a>
    </div>

    <table *ngIf="!hideCancellations && getCancellationCount()" class="js-cancelled-list-table">
      <thead>
        <th>Account</th>
        <th>Cancel date</th>
        <th>Reason</th>
        <th>Premium</th>
      </thead>
      <tbody>
        <ng-container *ngIf="loadingCancellations">
          <tr
            *ngFor="let i of buildArray(estimatedCancellationLength)"
            class="policies-overview-row js-cancelled-loading-row"
          >
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell td__right">
              <div class="table-loading-cell"></div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!loadingCancellations">
          <tr class="policies-overview-row" *ngFor="let cancellation of cancellations">
            <td class="policies-overview-table-cell">
              {{ cancellation.Cancellation.AccountName }}
            </td>
            <td class="policies-overview-table-cell">
              {{ formatDate(cancellation.Cancellation.CancellationEffectiveDate) }}
            </td>
            <td class="policies-overview-table-cell js-cancelled-reason-cell">
              <span class="pill-tag {{ getCssClassForCancellationPillTag(cancellation) }}">{{
                getCancellationReason(cancellation)
              }}</span>
            </td>
            <td class="policies-overview-table-cell">
              {{
                formatCost(
                  cancellation.Cancellation.TotalPremium,
                  cancellation.Cancellation.AccountNumber,
                  cancellation.Cancellation.PolicyNumber,
                  'cancelled'
                )
              }}
            </td>
            <td class="policies-overview-table-cell td__right">
              <a
                class="button button__small button__transparent button__no-margin"
                [routerLink]="getCancellationRouterLink(cancellation)"
                ><strong>DETAILS</strong></a
              >
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="policies-overview-page-selector page-selector-with-link">
      <span class="policies-overview-help-center-link">
        <a
          routerLink="/support/form/request-reinstatement"
          data-amplitude-input-name="cancelled_list_request_reinstatement"
          >Request a reinstatement</a
        >
      </span>
      <div
        class="js-policies-cancelled-page-selector"
        *ngIf="!hideCancellations && getCancellationCount() > policyPageLength"
      >
        <a
          *ngIf="cancellationSelectedIndex > 0"
          (click)="loadPreviousCancellations()"
          (keyup.enter)="loadPreviousCancellations()"
          class="js-policies-cancelled-previous"
          ><span class="icon-arrow-left button-icon"></span
        ></a>
        <span class="gray-text" *ngIf="cancellationSelectedIndex <= 0"
          ><span class="icon-arrow-left button-icon"></span
        ></span>

        <span>
          {{ getFirstItemNumber(cancellationSelectedIndex) }}-{{
            getLastItemNumber(cancellationSelectedIndex, getCancellationCount(), policyPageLength)
          }}
        </span>
        <span class="gray-text"> of </span>
        <span> {{ getCancellationCount() }} </span>

        <a
          *ngIf="cancellationSelectedIndex + policyPageLength < getCancellationCount()"
          (click)="loadNextCancellations()"
          (keyup.enter)="loadNextCancellations()"
          class="js-policies-cancelled-next"
          ><span class="icon-arrow-right button-icon"></span
        ></a>
        <span
          class="gray-text"
          *ngIf="cancellationSelectedIndex + policyPageLength >= getCancellationCount()"
          ><span class="icon-arrow-right button-icon"></span
        ></span>
      </div>
    </div>
  </div>
</section>
