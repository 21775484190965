import * as _ from 'lodash';
import * as moment from 'moment';
import { HiscoxQuoteStatus, HISCOX_API_VERSION, HISCOX_PRODUCTS } from './hiscox-types';

interface ErrorObjectKeys {
  [key: string]: string;
}

export const HISCOX_ERROR_CODES: { [key: string]: any } = {
  '000': 'Successful request.',
  '100': 'Merchant Identifier left blank or not valid. The transaction was not processed.',
  '200': 'Name not present',
  '310': 'Credit card number left blank or is incorrect', // failed Mod10 check
  '315': 'Credit card number is incorrect', // failed Mod10 check
  '320': 'Credit card type left blank or invalid',
  '330': 'Expiration month left blank',
  '340': 'Expiration year left blank',
  '350': 'CVV2 field submitted but does not match card',
  '355': 'CVV2 required but not present',
  '357': 'An invalid character was entered, such as a letter in a numeric field',
  '360': 'Payment declined by financial institution, or some other error has occurred',
  '370': 'Expiration date invalid',
};

export const HISCOX_POLICY_PAYMENT_PLAN_OPTIONS = {
  'One-time payment': 1,
  Monthly: 10,
};

export interface HiscoxPaymentRes {
  QuoteId: string;
  TransactionId: string;
  PaymentReference: string;
  IFrameURL: string;
  SessionId: string;
}

export interface HiscoxQuoteBaseResponse {
  uuid: string;
  accountId: string;
  version: HISCOX_API_VERSION.v4;
  hx_response_payload: unknown; // Note: debugging usage
  hx_request_payload: unknown; // Note: debugging usage
  product: HISCOX_PRODUCTS;
  createdAt: string;
  origFrontendRequest: {
    quoteId: string;
    classOfBusinessCd: string;
    [key: string]: unknown;
  };
}
export interface HiscoxQuoteQuotedResponse extends HiscoxQuoteBaseResponse {
  status: HiscoxQuoteStatus.QUOTED;
  effectiveDate: string;
  quoteId: string | number;
  premium: number;
  annual: number;
  downpayment: number;
  monthly: number;
  surcharge: number;
  classCode: {
    class_of_business: string;
    naics_code: string;
    description: string;
  };
}

// Note: might need annual, monthly, downpayment data returned
export interface HiscoxQuoteBoundResponse extends HiscoxQuoteBaseResponse {
  status: HiscoxQuoteStatus.BOUND;
  effectiveDate: string;
  quoteId: string | number;
  policyNumber: number | string;
  premium?: number;
  classCode: {
    class_of_business: string;
    naics_code: string;
    description: string;
  };
}

export interface HiscoxQuoteDeclinedResponse extends HiscoxQuoteBaseResponse {
  status: HiscoxQuoteStatus.DECLINED;
  quoteId: string;
  declineReasons: string[]; // TODO: do not see this being defined
  classCode?: {
    class_of_business: string;
    naics_code: string;
    description: string;
  };
}

export interface HiscoxQuoteReferredResponse extends HiscoxQuoteBaseResponse {
  status: HiscoxQuoteStatus.REFERRED;
  quoteId: string;
  referredReasons: string[]; // TODO: do not see this being defined
  classCode?: {
    class_of_business: string;
    naics_code: string;
    description: string;
  };
}

export interface HiscoxQuoteUnknownResponse extends HiscoxQuoteBaseResponse {
  status: HiscoxQuoteStatus.UNKNOWN;
  errors: string[];
  classCode?: {
    class_of_business: string;
    naics_code: string;
    description: string;
  };
}

export type HiscoxBackendPricedQuote =
  | HiscoxQuoteQuotedResponse
  | HiscoxQuoteBoundResponse
  | HiscoxQuoteDeclinedResponse
  | HiscoxQuoteReferredResponse
  | HiscoxQuoteUnknownResponse;

/**
 * code: "000"
 * message: "Success"
 * profileId: "cpt500994742102SB"
 * rurl: "https://www.chasepaymentechhostedpay-var.com/hpf/1_1/"
 * uID: "9DCCC1F8DEE4E71D55019D7B90756A11"
 *
 * uID = payment reference
 */

export interface HiscoxTransactionRes {
  code: string;
  message: string;
  profileId: string;
  rurl: string;
  uID: string;
}

export class HiscoxPricedQuote {
  uuid: string;
  accountId: string;
  quoteId?: string; // QUOTED/BOUND cases only
  product: HISCOX_PRODUCTS;
  premium?: number; // QUOTED/BOUND cases only
  downpayment?: number; // QUOTED/BOUND cases only
  monthly?: number; // QUOTED/BOUND cases only
  surcharge?: number; // QUOTED/BOUND cases only
  createdAt: moment.Moment;
  effectiveDate?: moment.Moment; // QUOTED/BOUND cases only
  status: HiscoxQuoteStatus;
  declineReasons?: string[]; // DECLINED case only
  referredReasons?: string[]; // REFERRED case only
  errors?: string[]; // UNKNOWN case only, but not currently surfaced
  hasQuoteLetter = false;
  state: string;
  cob: string;
  version: HISCOX_API_VERSION;
  policyNumber?: string; // BOUND case only
  hx_response_payload?: unknown; // Note: debugging usage
  hx_request_payload?: unknown; // Note: debugging usage
  origFrontendRequest: {
    quoteId: string;
    classOfBusinessCd: string;
    [key: string]: unknown;
  };
  classCode: {
    class_of_business: string;
    naics_code: string;
    description: string;
  };

  constructor(backendPricedQuote: HiscoxBackendPricedQuote) {
    Object.assign(
      this,
      _.pick(backendPricedQuote, [
        'uuid',
        'policyNumber',
        'effectiveDate',
        'quoteId',
        'premium',
        'downpayment',
        'monthly',
        'surcharge',
        'version',
        'origFrontendRequest',
        'classCode',
        // TODO: eventually plumb in state and cob here once SQ is sending it
      ])
    );

    this.status = backendPricedQuote.status;

    this.state = _.get(
      backendPricedQuote,
      'hx_request_payload.InsuranceSvcRq.QuoteRq.StateOrProvCd',
      null
    );
    this.cob = _.get(
      backendPricedQuote,
      'hx_request_payload.InsuranceSvcRq.QuoteRq.ClassOfBusinessCd',
      null
    );

    if (_.get(backendPricedQuote, 'hx_response_payload.status', null) === 'DECLINED') {
      this.status = HiscoxQuoteStatus.DECLINED;
    }

    if (_.get(backendPricedQuote, 'hx_response_payload.status', null) === 'PENDING_REFER') {
      this.status = HiscoxQuoteStatus.REFERRED;
    }

    switch (this.status) {
      case HiscoxQuoteStatus.QUOTED:
        this.effectiveDate = moment(
          (backendPricedQuote as HiscoxQuoteQuotedResponse).effectiveDate
        );
        break;
      case HiscoxQuoteStatus.BOUND:
        this.effectiveDate = moment((backendPricedQuote as HiscoxQuoteBoundResponse).effectiveDate);
        break;
      case HiscoxQuoteStatus.DECLINED:
        this.declineReasons = (backendPricedQuote as HiscoxQuoteDeclinedResponse).declineReasons;
        break;
      case HiscoxQuoteStatus.REFERRED:
        this.referredReasons = (backendPricedQuote as HiscoxQuoteReferredResponse).referredReasons;
        break;
      case HiscoxQuoteStatus.UNKNOWN:
        this.errors = (backendPricedQuote as HiscoxQuoteUnknownResponse).errors;
        break;
    }
    this.createdAt = moment.utc(backendPricedQuote.createdAt);
  }

  isBound() {
    return this.status === HiscoxQuoteStatus.BOUND;
  }

  isQuoted() {
    return this.status === HiscoxQuoteStatus.QUOTED;
  }

  isDeclined() {
    return this.status === HiscoxQuoteStatus.DECLINED;
  }

  isReferred() {
    return this.status === HiscoxQuoteStatus.REFERRED;
  }

  isUnknown() {
    return this.status === HiscoxQuoteStatus.UNKNOWN;
  }
}
