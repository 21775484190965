import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

import { FormDslSteppedFormComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form.component';
import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';
import { FORM_CONFIG } from 'app/shared/form-dsl-test/form-dsl-multi-step-test-data';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-dsl-multi-step-test',
  templateUrl: './form-dsl-multi-step-test-form.component.html',
  providers: [FormDslSteppedFormService],
})
export class FormDslMultiStepTestFormComponent
  extends FormDslSteppedFormComponent
  implements OnInit, OnDestroy
{
  constructor(protected route: ActivatedRoute, public formService: FormDslSteppedFormService) {
    super(formService);
  }

  ngOnInit() {
    const config: string = this.route.snapshot.params['config'] as string;
    // cloneDeep is necessary for tests
    // which otherwise share state
    this.isEditing = false;
    this.formDefaults = {
      'form-dsl-multi-step-text-field-1': 'default value 1',
      'form-dsl-multi-step-text-field-2-1': 'No',
      'form-dsl-multi-step-text-field-3': 'default value 3',
    };
    this.complexFormValidators = {
      stepOne: [
        () => {
          return (form: UntypedFormGroup) => {
            const validationTestField = form.get('validationField');
            if (validationTestField && validationTestField.value) {
              return { testValidationError: 'test-validation-error' };
            }
            return null;
          };
        },
      ],
    };
    super.ngOnInit();
    this.setFormConfig(_.cloneDeep(FORM_CONFIG[config]));
  }
}
