import {
  combineLatest as observableCombineLatest,
  Subscription,
  Observable,
  BehaviorSubject,
  Subject,
  timer,
} from 'rxjs';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { map, filter } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  WcBackendPricedQuote,
  WcPricedQuote,
  WcQuoteWithDocuments,
} from '../models/wc-priced-quote';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { WcQuoteService } from '../services/workers-comp-quote.service';
import { environment } from 'environments/environment';
import { WcPolicyPaymentPresenter } from '../models/wc-policy-payment-presenter';
import { WcPolicyPayment } from '../models/wc-policy-payment';
import { WcBindFormValue, WcBindPolicy } from '../models/wc-bind-policy';
import { US_DATE_MASK } from 'app/constants';
import {
  API_DATE_FORMAT,
  WC_POLICY_PAYMENT_PLAN_OPTIONS,
  WcPolicyPaymentPlanOption,
} from 'app/workers-comp/employers/constants';
import { FEINPlaceholderValues } from 'app/workers-comp/shared/constants';
import { getControl } from 'app/shared/helpers/form-helpers';
import { UserService } from 'app/core/services/user.service';
import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import * as _ from 'lodash';
import { WcQuotePayload } from '../models/wc-policy';
import { User } from 'app/shared/models/user';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { scrollToTop } from 'app/shared/helpers/scroll-helpers';
import { WcBindFormService } from 'app/workers-comp/employers/services/workers-comp-bind-form.service';
import {
  checkGWTempEmailAddress,
  checkGWTempPhoneNumber,
} from 'app/features/attune-bop/models/form-validators';
import { InformService } from 'app/core/services/inform.service';
import { OnboardingService } from 'app/shared/services/onboarding.service';

@Component({
  selector: 'app-page.app-page.app-page__form',
  templateUrl: './wc-bind-quote.component.html',
  providers: [WcBindFormService],
})
export class WcBindQuoteComponent implements OnInit, OnDestroy {
  accountId: string;
  wcQuote: WcPricedQuote;
  tsRequestId: string;
  executiveExclusionTerms: string[];

  accountDetails: InsuredAccount;
  paymentPlanOptions: { [key: string]: number };
  insuredAccount: Observable<InsuredAccount | null>;
  policyPaymentPresenter: Observable<WcPolicyPaymentPresenter>;

  currentStep: RouteFormStep;
  isBinding = false;
  errorModalOpen = false;
  displayPriceDiffModal = false;
  newPremium: number;
  oldPremium: number;
  user: User;
  wcBindPolicy: WcBindPolicy;
  _numberOfPayments = new BehaviorSubject<string>(this.formService.DEFAULT_NUMBER_OF_PAYMENTS);
  _wcPolicyPayment = new BehaviorSubject<WcPolicyPayment | null>(null);
  isFEINPlaceholderValue = false;
  isUnedited = false;

  bindSuccess$ = new Subject();

  private wcQuoteSubmission: WcQuotePayload;

  private sub: Subscription = new Subscription();
  private insuredAccountSubject: BehaviorSubject<InsuredAccount | null> = new BehaviorSubject(null);

  constructor(
    private route: ActivatedRoute,
    private informService: InformService,
    private insuredAccountService: InsuredAccountService,
    private wcQuoteService: WcQuoteService,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private userService: UserService,
    private onboardingService: OnboardingService,
    public formService: WcBindFormService
  ) {}

  get effectiveDate() {
    return getControl(this.formService.form, 'bindQuote.effectiveDate');
  }

  isFinalStep() {
    return this.formService.isFinalStep();
  }

  isFirstStep() {
    return this.formService.isFirstStep();
  }

  // TODO? These methods are the same as in the ConsumerBopFormComponent
  clickBackward() {
    if (this.formService.stepBackward()) {
      this.navigateToCurrentStep();
    }
  }

  handleSubmit(submitEvent?: Event): boolean {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    if (this.isCurrentStep('working-with-employers')) {
      this.formService.handleDoNotShowAgainSelection();
    }

    return this.formService.stepForward();
  }

  generateRequestId() {
    this.tsRequestId = uuidv4();
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
  }
  // END Copy/Paste from ConsumerBopFormComponent

  handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep, step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  submitted() {
    return this.formService.submitted;
  }

  // TODO: Break out the sections into components and use routes with args like in ConsumerBopFormComponent
  protected navigateToCurrentStep(): void {
    this.currentStep = this.formService.getCurrentStep();
    scrollToTop();
  }

  isCurrentStep(slug: string) {
    if (!this.currentStep) {
      return false;
    }
    const currentSlug = this.currentStep.slug;
    const slugRegex = new RegExp('^' + slug + '$');
    return currentSlug ? currentSlug.match(slugRegex) : false;
  }

  isCurrentStepValid() {
    return this.formService.isCurrentStepValid();
  }

  ngOnInit() {
    this.displayPriceDiffModal = false;
    this.accountId = this.route.snapshot.params['accountId'];

    this.sub.add(
      this.insuredAccountService.insuredError.subscribe(() => {
        this.informService.minorErrorToast(
          'We encountered an error while loading information about this account. Please try refreshing the page.',
          null,
          'Failed to retrieve account.',
          'Retry',
          () => {
            // In certain error cases, caching will prevent a proper retry
            this.insuredAccountService.cachebust();
            this.loadAccountDetails();
          },
          0
        );
      })
    );

    this.paymentPlanOptions = {};
    this.getExecutiveExclusionTerms();
    this.insuredAccount = this.insuredAccountSubject.asObservable(); // for async pipe
    this.policyPaymentPresenter = this.observePaymentsOnPaymentSelection();
    this.loadAccountDetails();
    this.subscribeToQuoteDetails();
    this.subscribeToQuoteSubmission();
    zendeskLeftSnap();
    this.sub.add(this.userService.getUser().subscribe((user) => (this.user = user)));

    this.formService.syncAllSteps();
    this.navigateToCurrentStep();

    this.sub.add(
      this.wcQuoteService.isEdited$.subscribe((unedited) => {
        this.isUnedited = unedited;
      })
    );

    this.sub.add(
      this.formService.incrementedStep$.subscribe(() => {
        this.navigateToCurrentStep();
      })
    );

    this.sub.add(
      this.formService.submittedForm$.subscribe(() => {
        this.bind();
      })
    );
  }

  ngOnDestroy() {
    removeZendeskLeftSnap();
    this.sub.unsubscribe();
  }

  showPaymentOptions() {
    return _.keys(this.paymentPlanOptions).length > 0;
  }

  showBindQuoteButton() {
    return this.mailingAddressesMatch() && !this.uneditedPreviouslyReferred();
  }

  mailingAddressesMatch() {
    return this.formService.get<UntypedFormControl>('bindQuote.mailingBillingAddressesIdentical')
      .value;
  }

  uneditedPreviouslyReferred() {
    if (environment.stage === 'staging') {
      return false;
    }
    return this.wcQuote && this.wcQuote.previouslyReferred && this.isUnedited;
  }

  getEmailAndPhone() {
    const emailAddress = this.formService.get<UntypedFormControl>('bindQuote.emailAddress').value;
    const phoneNumber = this.formService.get<UntypedFormControl>('bindQuote.phoneNumber').value;
    const normalizedPhone = phoneNumber.trim().replace(/[^0-9+]/g, '');

    return {
      emailAddress,
      phoneNumber: normalizedPhone,
    };
  }

  bind() {
    this.isBinding = true;

    const { emailAddress, phoneNumber } = this.getEmailAndPhone();

    const bindQuoteFormValue: WcBindFormValue =
      this.formService.get<UntypedFormGroup>('bindQuote').value;

    // Boolean value for agreed to terms is only added to the payload if the value is true.
    const agreedToAdditionalRequirementTerms = this.formService.get<UntypedFormGroup>(
      'additionalRequirements.agreedToAdditionalRequirementTerms'
    ).value;

    if (agreedToAdditionalRequirementTerms) {
      bindQuoteFormValue.agreedToAdditionalRequirementTerms = agreedToAdditionalRequirementTerms;
    }

    this.wcBindPolicy = new WcBindPolicy(bindQuoteFormValue);

    if (
      emailAddress !== this.accountDetails.emailAddress ||
      phoneNumber !== this.accountDetails.phoneNumber
    ) {
      this.accountDetails.emailAddress = emailAddress;
      this.accountDetails.phoneNumber = phoneNumber;
      this.insuredAccountService
        .edit(this.accountDetails)
        .subscribe(() => this.checkPriceBeforeSubmittingBind());
    } else {
      this.checkPriceBeforeSubmittingBind();
    }
  }

  navigateToAccountsPage() {
    this.insuredAccountService.cachebust();
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  navigateToBoundPage(policyIdentifier: string) {
    this.insuredAccountService.cachebust();
    this.router.navigate([
      '/accounts',
      this.route.snapshot.params.accountId,
      'workers-comp',
      'policies',
      policyIdentifier,
    ]);
  }

  callBindService() {
    this.displayPriceDiffModal = false;
    this.isBinding = true;
    this.submitBind(this.wcBindPolicy);
  }

  checkFEINValue() {
    const FEINValue = (<UntypedFormControl>(
      this.formService.form.get('bindQuote.employerIdentificationNumber')
    )).value;
    if (FEINValue) {
      this.isFEINPlaceholderValue = FEINPlaceholderValues.includes(FEINValue);
    }
  }

  checkPriceBeforeSubmittingBind() {
    const currentEffectiveDate =
      this.formService.get<UntypedFormControl>('bindQuote.effectiveDate').value;
    const initialEffectiveDate = this.wcQuote.effectiveDate.format(US_DATE_MASK);
    const currentFEIN = this.formService
      .get<UntypedFormControl>('bindQuote.employerIdentificationNumber')
      .value.replace(/\D/g, '');
    const initialFEIN = this.wcQuoteSubmission.account.employerIdentificationNumber;

    if (currentEffectiveDate === initialEffectiveDate && currentFEIN === initialFEIN) {
      this.submitBind(this.wcBindPolicy);
      return;
    }

    this.wcQuoteService
      .getQuoteSubmission(this.accountId)
      .subscribe((wcQuotePayload: WcQuotePayload) => {
        if (!wcQuotePayload || Object.prototype.hasOwnProperty.call(wcQuotePayload, 'error')) {
          this.errorModalOpen = true;
          return;
        }

        wcQuotePayload.policy.effectiveDate = moment
          .utc(currentEffectiveDate, US_DATE_MASK)
          .format(API_DATE_FORMAT);

        wcQuotePayload.account.employerIdentificationNumber = currentFEIN;

        this.wcQuoteService.requestQuote(wcQuotePayload, true).subscribe((response) => {
          if (!response) {
            this.errorModalOpen = true;
            return;
          }

          this.policyPaymentPresenter.subscribe((policyPayment) => {
            const newPremium = parseInt(response.premium, 10);
            const oldPremium = policyPayment.estimatedTotalIntegral;

            if (Math.abs(oldPremium - newPremium) > 0) {
              this.newPremium = newPremium;
              this.oldPremium = oldPremium;
              this.isBinding = false;
              this.displayPriceDiffModal = true;

              this.amplitudeService.track({
                eventName: 'rate_change_on_bind',
                detail: this.wcQuote.uuid,
                useLegacyEventName: true,
              });
            } else {
              this.submitBind(this.wcBindPolicy);
            }
          });
        });
      });
  }

  submitBind(wcBindPolicy: WcBindPolicy) {
    this.amplitudeService.track({
      eventName: 'wc_bind_attempt',
      detail: this.wcQuote.uuid,
      useLegacyEventName: true,
    });
    this.wcQuoteService.bindQuote(this.wcQuote.uuid, wcBindPolicy).subscribe((resp: any) => {
      if (resp.error) {
        this.isBinding = false;
        this.amplitudeService.track({
          eventName: 'wc_bind_error',
          detail: resp.error,
          useLegacyEventName: true,
        });
        throw resp.error;
      } else {
        this.bindSuccess$.next(true);
        timer(3000).subscribe(() => {
          this.isBinding = false;
          // TODO (analytics): Remove / resolve these events
          this.amplitudeService.track({
            eventName: 'bind_attempt',
            detail: 'workers_comp',
            useLegacyEventName: true,
          });
          this.amplitudeService.track({
            eventName: 'bind',
            detail: 'workers_comp',
            useLegacyEventName: true,
          });
          this.wcQuoteService.cachebust();
          this.insuredAccountService.cachebust();
          this.navigateToBoundPage(resp.policyNumber);
        });
      }
    });
  }

  private loadAccountDetails() {
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => {
            this.accountDetails = insuredAccount;
            this.setEmailAndPhoneNumberInForm(insuredAccount);
            return this.accountId === insuredAccount.id.toString();
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccountSubject.next(insuredAccount);
          this.checkForAdditionalRequirements();
        })
    );
  }

  private subscribeToQuoteDetails() {
    this.sub.add(
      this.wcQuoteService.getQuote(this.accountId).subscribe(
        (wcQuoteResponse: WcQuoteWithDocuments) => {
          if (!wcQuoteResponse.quote) {
            return;
          }
          this.wcQuote = new WcPricedQuote(<WcBackendPricedQuote>wcQuoteResponse.quote);
          this._wcPolicyPayment.next(new WcPolicyPayment(this.wcQuote.premium));
          this.patchEffectiveDateControl();
          this.updatePaymentPlanOptions();
          this.checkForAdditionalRequirements();
        },
        (err) => {
          this.showPageRetryGrowl(this.subscribeToQuoteDetails.bind(this));
        }
      )
    );
  }

  private subscribeToQuoteSubmission() {
    this.sub.add(
      this.wcQuoteService.getQuoteSubmission(this.accountId).subscribe(
        (currentWCQuoteSubmissions: WcQuotePayload) => {
          if (Object.prototype.hasOwnProperty.call(currentWCQuoteSubmissions, 'error')) {
            this.showPageRetryGrowl(this.subscribeToQuoteSubmission.bind(this));
            return;
          }
          this.wcQuoteSubmission = currentWCQuoteSubmissions;

          this.formService.form.patchValue({
            bindQuote: {
              employerIdentificationNumber:
                this.wcQuoteSubmission.account.employerIdentificationNumber,
            },
          });

          this.checkForAdditionalRequirements();
        },
        (err) => {
          this.showPageRetryGrowl(this.subscribeToQuoteSubmission.bind(this));
        }
      )
    );
  }

  showPageRetryGrowl(retryMethod: Function) {
    this.informService.minorErrorToast(
      `We encountered an error while loading information about this quote. Please try refreshing the page.`,
      null,
      'Failed to retrieve quote.',
      'Retry',
      retryMethod,
      0
    );
  }

  private updatePaymentPlanOptions() {
    this.paymentPlanOptions = Object.entries(WC_POLICY_PAYMENT_PLAN_OPTIONS).reduce(
      (accumulator: { [key: string]: any }, value: [string, WcPolicyPaymentPlanOption]): {} => {
        if (this.wcQuote.premium >= value[1].premiumThreshold) {
          accumulator[value[1].name] = value[0];
        }

        return accumulator;
      },
      {}
    );
    this.formService.form.patchValue({
      bindQuote: {
        numberOfPayments: this.getDefaultNumberOfPayments(this.wcQuote.premium),
      },
    });
  }

  hasPrecisePayPaymentOption() {
    return Object.prototype.hasOwnProperty.call(this.paymentPlanOptions, 'Precisepay');
  }

  private patchEffectiveDateControl() {
    const currentEffectiveDate = this.effectiveDate.value;
    const originalEffectiveDate = this.wcQuote.effectiveDate;
    // If the original effective date is after today, patch the date otherwise keep it as today's date.
    if (originalEffectiveDate.isAfter(moment.utc(currentEffectiveDate, US_DATE_MASK))) {
      this.formService.form.patchValue({
        bindQuote: {
          effectiveDate: this.wcQuote.effectiveDate.format(US_DATE_MASK),
        },
      });
    }
  }

  private observePaymentsOnPaymentSelection() {
    this.sub.add(
      this.formService
        .get<UntypedFormControl>('bindQuote.numberOfPayments')
        .valueChanges.subscribe(this._numberOfPayments)
    );

    return observableCombineLatest(
      this._wcPolicyPayment.pipe(filter(this.isNotNull)),
      this._numberOfPayments
    ).pipe(
      map(([wcPolicyPayment, numberOfPayments]) => {
        return new WcPolicyPaymentPresenter(wcPolicyPayment, parseInt(numberOfPayments, 10));
      })
    );
  }

  private isNotNull(x: any) {
    return x !== null;
  }

  private setEmailAndPhoneNumberInForm(account: InsuredAccount) {
    this.formService.form.patchValue({
      bindQuote: {
        emailAddress: checkGWTempEmailAddress(account),
        phoneNumber: checkGWTempPhoneNumber(account),
      },
    });
  }

  private getDefaultNumberOfPayments(premium: number) {
    return premium >= 1000 ? '11' : '1';
  }

  getExecutiveExclusionTerms() {
    this.executiveExclusionTerms = [
      "According to the laws applicable to the insured's jurisdiction, this policy may exclude an officer from coverage.",
      'The insured and excluded officer(s) meet all of the requirements under all applicable laws necessary to exclude the officer(s) from this policy.',
      'The insured has correctly and completely filled out the relevant Officers Exclusion certification form.',
      'I will retain a complete and compliant copy of the Officers Exclusion certification form, and at the request of Attune or the carrier, I will provide a copy of it to them.',
    ];
  }

  checkForAdditionalRequirements() {
    const baseState = this.accountDetails && this.accountDetails.state;
    const hasExecutiveExclusion = this.wcQuote && this.wcQuote.hasExecutiveExclusion;
    if (baseState === 'FL' || hasExecutiveExclusion) {
      this.formService.showAdditionalRequirementsStep();
      this.navigateToCurrentStep();
    }
  }
}
