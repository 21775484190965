import { OnInit, ViewChild, Component, Input, EventEmitter, Output } from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormGroupDirective,
} from '@angular/forms';

import { CreateTokenBankAccountData, StripeCardNumberElement } from '@stripe/stripe-js';

import { getString, getControl } from '../../../../shared/helpers/form-helpers';

import { InvoicesPaymentInputComponent } from '../invoices-payment-input/invoices-payment-input.component';

type AccountType = 'individual' | 'company';

@Component({
  selector: 'app-invoices-payment-form',
  templateUrl: './invoices-payment-form.component.html',
})
export class InvoicesPaymentFormComponent implements OnInit {
  // Component which encapsulates the payment form logic we use for credit card and ACH (bank) payments.
  // Complicated by our credit card integration with Stripe: our Stripe credit card inputs cannot be interacted
  // with like normal form components, and are not kept within an Angular form.

  @Output() formSubmit = new EventEmitter<void>();
  @Output() closePaymentModal = new EventEmitter<void>();

  @Input() includeCreditCardFee = false;
  @Input() serverError: string;
  @Input() amountToPay: number;
  @Input() stripeRecurring = false;
  @Input() paymentUpdateSuccessful = false;
  @Input() isProcessing = false;
  // TODO(WJC): We should remove this unused field
  @Input() isUpdateOnly = false;
  @Input() isUpdatingPayment = false;
  // This form displays different language and allows unenroll when shown to brokers on the account page
  @Input() isAccountPage = false;

  @Input() onlyShowAchOption = false;
  @Input() paymentSuccessMessage: string | undefined;
  @Input() hasPaymentDue = false;
  @Input() hasPastDueAndOutstanding = false;
  @Input() isInitialAutopay = false;
  @Input() creditCardFee: number | undefined;
  @Input() creditCardFeePercentage: number | undefined;

  // Optional fields, used to display current autopay information when updating payment method
  @Input() currentAutopayText = '';
  @Input() currentAutopayIsCard = false;
  @Output() unenroll = new EventEmitter<void>();

  @ViewChild(InvoicesPaymentInputComponent)
  private stripePaymentInputComponent: InvoicesPaymentInputComponent;

  private readonly paymentCountry = 'US';
  private readonly paymentCurrency = 'usd';
  private readonly creditCardTechnologyFeeRate = '3.2%';

  hasCardComplete: boolean;

  paymentForm: UntypedFormGroup;

  paymentMethod = 'ach';

  stripeError: string | undefined;

  achFormSubmitted = false;
  paymentSubmitted = false;

  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildPaymentForm();
    this.paymentFormListener();
  }

  getErrorMessage() {
    if (this.paymentSubmitted && this.isCardPayment() && !this.hasCardComplete) {
      return this.serverError || this.stripeError || 'Please complete your payment information.';
    } else if (this.achFormSubmitted && !this.isCardPayment() && !this.getBankForm().valid) {
      return this.serverError || this.stripeError || 'Please complete your payment information.';
    }
    return this.serverError || this.stripeError || '';
  }

  getAmplitudeSubmitId() {
    if (this.isInitialAutopay) {
      return 'enroll_autopay';
    } else if (this.isUpdatingPayment) {
      return 'update_payment_method';
    }
    return 'pay_invoice';
  }

  getCardNumber(): StripeCardNumberElement {
    return this.stripePaymentInputComponent.cardNumber;
  }

  getPaymentMethod() {
    return getString(this.paymentForm, 'paymentOption');
  }

  isCardPayment(): boolean {
    return this.getPaymentMethod() === 'creditCard';
  }

  isACHPayment(): boolean {
    return this.getPaymentMethod() === 'ach';
  }

  getAutopayStatus(): boolean {
    return getControl(this.paymentForm, 'autopay').value;
  }

  setStripeError(error: string) {
    // Replace idempotency errors with user-friendly messaging
    const idempotencyErrorCheck = /idempot/i;
    const genericError =
      'Unable to process your payment at this time. Please reach out to our Customer Care Team if you continue to experience issues.';
    this.stripeError = idempotencyErrorCheck.test(error) ? genericError : error;
  }

  setCardComplete(complete: boolean) {
    this.hasCardComplete = complete;
  }

  buildPaymentForm() {
    const bankForm = this.formBuilder.group({
      accountNumber: ['', Validators.required],
      accountType: ['Individual', [Validators.required]],
      fullname: ['', [Validators.required]],
      routingNumber: ['', Validators.required],
    });

    this.paymentForm = this.formBuilder.group({
      achPayment: bankForm,
      autopay: [true],
      paymentOption: ['ach'],
    });
  }

  paymentFormListener(): void {
    const paymentFormListener = this.paymentForm.get('paymentOption');
    if (paymentFormListener) {
      paymentFormListener.valueChanges.subscribe((val) => {
        if (val !== this.paymentMethod) {
          this.paymentMethod = val;
          this.stripeError = '';
        }
      });
    }
  }

  clearForm() {
    this.stripePaymentInputComponent.clearForm();
    this.paymentSubmitted = false;
    this.achFormSubmitted = false;

    this.formRef.resetForm({
      autopay: true,
      paymentOption: 'ach',
      achPayment: {
        accountNumber: '',
        accountType: 'Individual',
        fullname: '',
        routingNumber: '',
      },
    });
  }

  getBankForm() {
    return this.paymentForm.get('achPayment') as UntypedFormGroup;
  }

  generateBankData(): CreateTokenBankAccountData {
    const bf = this.getBankForm();
    const bankData: CreateTokenBankAccountData = {
      account_holder_name: getString(bf, 'fullname'),
      account_holder_type: getString(bf, 'accountType') as AccountType,
      account_number: getString(bf, 'accountNumber'),
      country: this.paymentCountry,
      currency: this.paymentCurrency,
      routing_number: getString(bf, 'routingNumber'),
    };
    return bankData;
  }

  handlePaymentFlow() {
    this.paymentSubmitted = true;

    if (this.isCardPayment()) {
      if (!this.stripeError && this.hasCardComplete) {
        this.formSubmit.emit();
      }
    } else {
      this.achFormSubmitted = true;
      if (this.getBankForm().valid) {
        this.formSubmit.emit();
      }
    }
  }

  getCreditCardOptionText(): string {
    const text = 'Debit / Credit card';
    if (this.onlyShowAchOption) {
      return text + ' (This option is not available for our apartments program)';
    }
    return text;
  }

  getAmountToPay(): number {
    return this.amountToPay + (this.isCardPayment() && this.creditCardFee ? this.creditCardFee : 0);
  }

  displayCreditCardDollarAmountMessage() {
    return this.includeCreditCardFee && this.creditCardFee;
  }

  // When it's available, we want to display the exact dollar amount of the credit card fee to the user
  // because this will give them the most information.
  // Otherwise we display the percentage
  displayCreditCardPercentageMessage() {
    return this.includeCreditCardFee && !this.creditCardFee && this.creditCardFeePercentage;
  }
}
