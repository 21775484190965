import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AmplitudePayloadType } from 'app/core/constants/amplitude-helpers';
import { of } from 'rxjs';

// Note: the casing of ids mirrors our patterns for forms (camelcase) and HTML elements (kebobcase)
export enum AmplitudeTestId {
  Button = 'button-test',
  Checkbox = 'checkboxTest',
  DropdownSearch = 'searchTest',
  Input = 'input-test',
  Link = 'link-test',
  RadioQuestion = 'radioQuestionTest',
  Select = 'select-test',
  SelectField = 'selectFieldTest',
  SliderField = 'sliderFieldTest',
  TextField = 'textFieldTest',
  Tooltip = 'tooltip-test',
  TooltipLabel = 'tooltip-text',
}

interface AmplitudeTestConfig {
  input: string | AmplitudeTestId;
  type: AmplitudePayloadType;
  value: string;
}

// Potential improvement here: consider adding a `getter` helper method
// on each config to return the html element that will be tested
export const AMPLITUDE_TEST_VALUES: {
  [key in AmplitudeTestId]?: AmplitudeTestConfig;
} = {
  [AmplitudeTestId.Button]: {
    input: 'button-input-name', // Derived from data-amplitude-input-name
    type: AmplitudePayloadType.Button,
    value: 'click',
  },
  [AmplitudeTestId.Checkbox]: {
    input: AmplitudeTestId.Checkbox,
    type: AmplitudePayloadType.Checkbox,
    value: 'true', // Manually clicked in test specs
  },
  [AmplitudeTestId.DropdownSearch]: {
    input: `${AmplitudeTestId.DropdownSearch}-search-typeahead`,
    type: AmplitudePayloadType.Typeahead,
    value: 'Result 1', // Manually interacted with in test specs and hardcoded in test component in `dropdownQueryMethod`
  },
  [AmplitudeTestId.Input]: {
    input: AmplitudeTestId.Input,
    type: AmplitudePayloadType.Input,
    value: 'The best business', // Manually input'd in test specs
  },
  [AmplitudeTestId.Link]: {
    input: 'mb-1', // Derived from classList[0]
    type: AmplitudePayloadType.Link,
    value: 'javascript:void(0);',
  },
  [AmplitudeTestId.RadioQuestion]: {
    input: 'Do you want to do a sample quote?', // Derived from label class name
    type: AmplitudePayloadType.RadioButton,
    value: 'yes', // Manually clicked in test specs
  },
  [AmplitudeTestId.Select]: {
    input: AmplitudeTestId.Select,
    type: AmplitudePayloadType.Select,
    value: 'Option 1', // Manually select'd in test specs
  },
  [AmplitudeTestId.SelectField]: {
    input: AmplitudeTestId.SelectField,
    type: AmplitudePayloadType.Select,
    value: 'AL', // Manually select'd in test specs
  },
  [AmplitudeTestId.SliderField]: {
    input: AmplitudeTestId.SliderField,
    type: AmplitudePayloadType.Slider,
    value: '60', // Manually input'd in test specs
  },
  [AmplitudeTestId.TextField]: {
    input: AmplitudeTestId.TextField,
    type: AmplitudePayloadType.Input,
    value: '01/01/2000', // Manually input'd in test specs
  },
  [AmplitudeTestId.Tooltip]: {
    input: 'Payment plan', // Derived from the nearest parent `label`
    type: AmplitudePayloadType.Tooltip,
    value:
      'This is the current payment method on file. To unenroll, please contact your broker, so they can unenroll you in our system.', // Derrived from the tooltip's text content
  },
};

/**
 * This component is used exclusively in unit tests and is only accessible
 * from dev mode at `/amplitude-test`.
 */
@Component({
  selector: 'app-amplitude-test',
  templateUrl: './amplitude-test.component.html',
  styles: [],
})
export class AmplitudeTestComponent {
  public dropdownFormControl = new UntypedFormControl('');
  public textFieldForm = new UntypedFormGroup({ effectiveDate: new UntypedFormControl('') });
  public sliderFieldForm = new UntypedFormGroup({ damageToPremises: new UntypedFormControl(0) });
  public selectFieldForm = new UntypedFormGroup({ baseState: new UntypedFormControl('') });
  public selectFieldOptions = ['AL', 'AK', 'AZ', 'AR'];
  public radioQuestionForm = new UntypedFormGroup({ optIn: new UntypedFormControl('') });
  public radioQuestionOptions = { yes: true, no: false };
  public checkboxForm = new UntypedFormGroup({ optIn: new UntypedFormControl('') });

  public dropdownFormatter = (string: string) => (typeof string === 'string' ? string : '');
  public dropdownQueryMethod = (_string: string) => of(['Result 1', 'Result 2', 'Result 3']);

  constructor() {}
}
