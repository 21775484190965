import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UNKNOWN_ERROR } from 'app/shared/quote-error-modal/errors';

export interface ErrorModalEmittedEvent {
  close: boolean;
  retry: boolean;
}

@Component({
  selector: 'app-quote-error-modal',
  templateUrl: './quote-error-modal.component.html',
})
export class QuoteErrorModalComponent implements OnChanges {
  @Input() public open = false;
  @Input() public errorType = UNKNOWN_ERROR;
  @Input() public errors: string[] = [];
  @Input() public modalClass: string;
  @Output() closeQuoteErrorModal = new EventEmitter<ErrorModalEmittedEvent>();
  public modalName: string;

  ngOnChanges(changes: SimpleChanges) {
    const headerHasChanged =
      changes.errorType?.currentValue?.header !== changes.errorType?.previousValue?.header;
    const modalNameHasChanged =
      changes.errorType?.currentValue?.modalNameForAmplitudeEvent !==
      changes.errorType?.previousValue?.modalNameForAmplitudeEvent;

    if (headerHasChanged || modalNameHasChanged) {
      if (this.errorType.modalNameForAmplitudeEvent) {
        this.modalName = `-${this.errorType.modalNameForAmplitudeEvent}`;
      } else {
        this.modalName = `-${this.errorType.header.toLowerCase().replace(/ +/g, '-')}` || '';
      }
    }
  }

  closeModal() {
    this.closeQuoteErrorModal.emit({
      close: true,
      retry: false,
    });
  }

  closeFlow() {
    this.closeQuoteErrorModal.emit({
      close: false,
      retry: false,
    });
  }

  closeAndRetry() {
    this.closeQuoteErrorModal.emit({
      close: true,
      retry: true,
    });
  }
}
