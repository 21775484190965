import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormDslNode } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { FrontendQuote } from 'app/features/digital-carrier/models/types';
import { LmCpspComplexValueEvaluator } from './lm-cpsp-typings';

export type LibertyMutualProductType = 'bop' | 'cpsp';

export interface DynamicFunctionFormDslConfiguration {
  formDslNode: FormDslNode;
  valueType: 'DYNAMIC_FUNC';
  valueDependencyFunc: ComplexValueEvaluator;
}

export interface DynamicFormDslConfiguration {
  formDslNode: FormDslNode;
  valueType: 'DYNAMIC_CONFIG';
  valueData: {
    state: ClassAndStateDependency;
    nodeValue: Partial<FormDslNode>;
  }[];
}

interface StaticFormDslConfiguration {
  formDslNode: FormDslNode;
  valueType: 'STATIC';
}

export type FormDslConfiguration =
  | DynamicFormDslConfiguration
  | StaticFormDslConfiguration
  | DynamicFunctionFormDslConfiguration;

export enum DependencySource {
  ID = 'ID',
  CODE = 'CODE',
  VALUE = 'VALUE',
}

export enum DependencyOperator {
  LESS_THAN = 'LESS_THAN',
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
}

export enum EvaluatedValueName {
  CURRENT_YEAR = 'CURRENT_YEAR',
  CURRENT_YEAR_MINUS_THREE_YEARS = 'CURRENT_YEAR_MINUS_THREE_YEARS',
}

type ComplexValueEvaluator = LmCpspComplexValueEvaluator;
export type ComplexValueEvaluatorFunc = (form: UntypedFormGroup) => ValueDependencySubscription;

interface Operand {
  source: DependencySource;
  value: any;
}

interface CodeOperand extends Operand {
  source: DependencySource.CODE;
  value: EvaluatedValueName;
}

interface ControlOperand<TLmQuestion> extends Operand {
  source: DependencySource.ID;
  value: TLmQuestion;
}

interface ValueOperand extends Operand {
  source: DependencySource.VALUE;
  value: string | number | boolean;
}

export type DependencyOperand<TLmQuestion> =
  | CodeOperand
  | ControlOperand<TLmQuestion>
  | ValueOperand;

export interface SimpleDependency<TLmQuestion> {
  type: 'SIMPLE';
  left: ControlOperand<TLmQuestion>;
  operator: DependencyOperator;
  right: DependencyOperand<TLmQuestion>;
}

export type ComplexEvaluatorFunc = (
  form: UntypedFormGroup,
  formArrayIndex?: number
) => DependencySubscription | void;

interface ComplexDependency<TComplexEvaluator> {
  type: 'COMPLEX';
  functionName: TComplexEvaluator;
}

export type ControlDependency<TLmQuestion, TComplexEvaluator> =
  | SimpleDependency<TLmQuestion>
  | ComplexDependency<TComplexEvaluator>;

interface AllClassAndStateDependency {
  display: 'ALL';
}

export interface SomeClassAndStateDependency {
  display: 'IN' | 'NOT_IN';
  values: string[];
}

export type ClassAndStateDependency = AllClassAndStateDependency | SomeClassAndStateDependency;

export interface DependencyConfig<TLmQuestion, TComplexEvaluator> {
  classCode: ClassAndStateDependency;
  state: ClassAndStateDependency;
  dependency?: ControlDependency<TLmQuestion, TComplexEvaluator>;
}

export type FormValue = string | number | boolean | null;

export interface ValueDependencySubscription {
  dependsOn: Observable<FormValue[]>;
  callback: (
    values: FormValue | LibertyMutualClassCode | (LibertyMutualClassCode | FormValue)[]
  ) => Partial<FormDslNode>;
}

export type DependencyCallback = (
  values: FormValue | LibertyMutualClassCode | (LibertyMutualClassCode | FormValue)[]
) => boolean;

export interface DependencySubscription {
  dependsOn: Observable<FormValue | FormValue[]>;
  callback: DependencyCallback;
}

export interface LibertyMutualClassCode {
  name: string;
  code: string;
}

// NOTE: only the following payment plans allow for AUTOMATIC payments, excluded '10'/'9' payment plans do not
export const LIBERTY_MUTUAL_PAYMENT_OPTIONS: { [key: string]: LibertyMutualPaymentPlan } = {
  Monthly: 'MO',
  Quarterly: 'QT',
  'Full payment': 'FL',
};

// NOTE: only the following payment plans allow for AUTOMATIC payments, excluded '10'/'9' payment plans do not
export type LibertyMutualPaymentPlan = 'MO' | 'QT' | 'FL';

type HydraPaymentStatus = 'Approved' | 'Declined' | 'Error';

export interface HydraPaymentValidationRes {
  instrumentId: string;
  instrumentStatus: string;
  instrumentType: 'credit_card';
  maskedInstrument: string;
  cardBrand: string;
  cardExpirationDate: string;
  status: HydraPaymentStatus;
  statusCode: string;
}

export interface HydraPaymentIFrameRes {
  instrumentId: string;
  instrumentHostedPage: string;
  instrumentHostedPageUrl: string;
}

export interface PaymentPlan {
  $: {
    'com.libertymutual.ci_PaymentRuleInfoRefs': string | string[];
  };
  PaymentPlanCd: LibertyMutualPaymentPlan;
  DepositAmt: {
    Amt: string;
  };
}

export type LibertyMutualQuote = FrontendQuote & {
  details: {
    paymentOption: PaymentPlan[];
    insuredFullToBePaidAmt: {
      Amt: string;
    };
    taxAmt: {
      Amt: string;
    };
  };
};

export enum LmButtonFunctionName {
  ADD_LOSS_GROUP = 'ADD_LOSS_GROUP',
  REMOVE_LOSS_GROUP = 'REMOVE_LOSS_GROUP',
}

export enum EARLY_DECLINE_REASONS {
  FLORIDA_STATE = 'This risk is ineligible due to exposure in the state of Florida',
  COB_STATE_COMBO = 'Business classification is not eligible in this state',
}

export interface LmQuoteFlowStep<TFormStepPath extends string, TQuestion extends string> {
  title: string;
  slug: string;
  parent: string;
  formPath: TFormStepPath;
  questions: (TQuestion | TQuestion[])[];
}
