import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { InformService } from '../../../../core/services/inform.service';

@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html',
})
export class DocumentDownloadComponent implements OnDestroy, OnChanges {
  @Input()
  type: 'pdf' | 'unsigned' | 'signed' = 'pdf';
  // Display name of the document. Should be capitalized IE 'Policy Document'
  @Input({ required: true })
  displayName: string;
  // Observable that completes when download completes and errors on failure.
  @Input()
  documentDownload$: Observable<any>;
  // Observable that completes when the document is ready to be downloaded or errors if document is unavailable.
  @Input()
  documentReady$: Observable<any> | null = null;
  @Input()
  tooltip: string;
  @Input()
  documentDownloadErrorMessage =
    'There was an error downloading this document. Please contact our Customer Care Team.';

  /*
   * 'ready' = document is ready to begin download
   * 'downloading' = document download has begun
   * 'loading' = document is not yet ready to begin download
   * 'disabled' = document cannot be downloaded
   */
  state: 'ready' | 'downloading' | 'preparing' | 'disabled' = 'disabled';
  sub: Subscription = new Subscription();

  constructor(private informService: InformService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.documentDownload$ && !this.documentReady$) {
      this.state = 'disabled';
    } else if (!this.documentReady$) {
      this.state = 'ready';
    } else if (
      this.documentReady$ &&
      changes['documentReady$']?.previousValue !== this.documentReady$
    ) {
      this.state = 'preparing';
      this.sub.add(
        this.documentReady$.subscribe({
          error: () => {
            this.state = 'disabled';
          },
          complete: () => {
            if (this.documentDownload$) {
              this.state = 'ready';
            } else {
              this.state = 'disabled';
            }
          },
        })
      );
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  download() {
    if (this.state !== 'ready') {
      return;
    }
    this.state = 'downloading';
    this.sub.add(
      this.documentDownload$.subscribe({
        complete: () => {
          this.state = 'ready';
        },
        error: () => {
          // Currently not a 'failed' state for document downloader
          // Possible to rework this to show a red '!' or something
          // But for now just show the error toast
          this.state = 'ready';
          this.informService.errorToast(
            this.documentDownloadErrorMessage,
            null,
            'Document Download Error',
            'Okay',
            null,
            0
          );
        },
      })
    );
  }
}
