import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import * as moment from 'moment';

import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { of as observableOf, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  RewardActionProcessingState,
  RewardsService,
} from '../../../../shared/services/rewards.service';
import {
  ActionName,
  RedemptionType,
  RewardRequest,
} from '../../../../shared/rewards/rewards-types';
import {
  INFORMATIONAL_NAMES,
  NewInformationalService,
} from '../../../../shared/services/new-informational.service';
import { validateEmailAddress } from 'app/features/attune-bop/models/form-validators';
import {
  BIND_REWARDS_TIERS_CONFIG,
  BindGoalsData,
  BindGoalsService,
  BindRewardTiers,
} from 'app/shared/services/bind-goals.service';
import { UserService } from 'app/core/services/user.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import { User } from '../../../../shared/models/user';
import { UserApiService } from '../../../../core/services/user-api-service';
import { UserInfo } from '../../../../shared/consumer/typings';
import { BindGoalsConfig } from '../../../activity/components/activity-incentive-cyber-binds/activity-incentive-cyber-binds.component';
import { numberToMoneyString } from '../../../../shared/helpers/number-format-helpers';
import { InformService } from '../../../../core/services/inform.service';
import { ADP_AGENT_PRODUCER_CODES } from '../../../../shared/rewards/rewards-constants';

type GiftCardOptions =
  | 'GIFT_CARD_5'
  | 'GIFT_CARD_10'
  | 'GIFT_CARD_25'
  | 'GIFT_CARD_50'
  | 'GIFT_CARD_MAX';

export const NUM_POINTS_IN_A_DOLLAR = 10;
const DOLLAR_AMOUNT_INCREMENT_FOR_MAX_VALUE = 5;
const MAX_ALLOWED_POINTS_PER_YEAR = 6000;

const PROGRESS_BAR_BIND_MILESTONES = Object.values(BindRewardTiers).map((tier) => {
  const capitalizedTier = tier.charAt(0).toUpperCase() + tier.slice(1);
  const coins = BIND_REWARDS_TIERS_CONFIG[tier].coins;
  const premiumTarget = numberToMoneyString(BIND_REWARDS_TIERS_CONFIG[tier].premiumTarget);
  let tooltip;
  if (tier !== BindRewardTiers.BRONZE) {
    tooltip = `You will get ${coins} coins per bind after ${premiumTarget} in premium`;
  } else {
    tooltip = `You will get ${coins} coins per bind`;
  }
  return {
    value: premiumTarget,
    label: `${capitalizedTier}
          <br/> ${coins}
          <img style="width: 3.2rem" src="/assets/img/coin.svg" alt="attune coin" />/ BIND`,
    tooltip,
  };
});

const BIND_MILESTONES_THRESHOLDS = Object.values(BIND_REWARDS_TIERS_CONFIG)
  .map((tier) => tier.premiumTarget)
  .slice(1);

@Component({
  selector: 'app-rewards-page.app-page.app-page__rewards',
  templateUrl: './rewards-page.component.html',
})
export class RewardsPageComponent implements OnInit, OnDestroy {
  private balance = 0;

  optinRewardsForm: UntypedFormGroup;
  redeemRewardsForm: UntypedFormGroup;
  editRewardsEmailForm: UntypedFormGroup;
  public optinRewardsSubmitted = false;
  public requestingOptIn = false;
  public optInError = false;
  public rewardsEmail: string | null = null;
  public totalRedeemedThisYear = 0;
  public formattedBalance = '';
  /*
   The rewardsTier represents the tier at which the user is currently earning rewards.
   Eg a user at the Bronze tier will earn 20 coins per bind
  */
  public rewardsTier: BindRewardTiers = BindRewardTiers.BRONZE;
  /*
   The userTier represents the 'tier' of the user meaning what level of loyalty this user is at.
   We would say a user is a 'Gold tier user' because they have achieved the gold tier and has not expired.
   A user will stay at their tier for a whole year and if they do not maintain that level then they will
   be demoted to whatever tier they did make it to.

   TL;DR userTier is the max tier of this year and previous year.
  */
  public userTier: BindRewardTiers = BindRewardTiers.BRONZE;
  public tierCoins: number = 0;

  isRewardsActivityEnabled: boolean;
  showUserOptInModal = false;
  showRewardOptions = false;
  showEditEmailModal = false;
  showRewardsInfoModal = false;
  giftCardDescription = '';
  numberOfPoints = 0;
  maxValue = 0;
  redeemRewardsSubmitted = false;
  editEmailFormSubmitted = false;
  showNotEnoughRewardsMessage = false;
  showRewardsCapMessage = false;
  showSomeRewardsUnavailableMessage = false;
  redemptionProcessingState: RewardActionProcessingState = 'notProcessing';
  editEmailProcessingState: RewardActionProcessingState = 'notProcessing';
  tiersConfig = BIND_REWARDS_TIERS_CONFIG;
  tiers = [...Object.values(BindRewardTiers)];
  statusEndDate = '';
  coinsPerQuote = 10;

  user: User;
  activatedDate: moment.Moment;

  protected sub = new Subscription();
  giftCardOptions: { [key in GiftCardOptions]: any } = {
    GIFT_CARD_5: {
      imageUrl: '/assets/img/gift_card_5.png',
      description: '$5 e-gift card',
      points: 50,
    },
    GIFT_CARD_10: {
      imageUrl: '/assets/img/gift_card_10.png',
      description: '$10 e-gift card',
      points: 100,
    },
    GIFT_CARD_25: {
      imageUrl: '/assets/img/gift_card_25.png',
      description: '$25 e-gift card',
      points: 250,
    },
    GIFT_CARD_50: {
      imageUrl: '/assets/img/gift_card_50.png',
      description: '$50 e-gift card',
      points: 500,
    },
    GIFT_CARD_MAX: {
      imageUrl: '/assets/img/gift_card_max.png',
    },
  };
  disabledOptions = [] as string[];
  isRewardsTierEnabled: boolean;

  progressBarConfig: BindGoalsConfig;

  constructor(
    private amplitudeService: AmplitudeService,
    private rewardsService: RewardsService,
    private formBuilder: UntypedFormBuilder,
    private newInformationalService: NewInformationalService,
    private userService: UserService,
    private userApiService: UserApiService,
    private bindGoalsService: BindGoalsService,
    private featureFlagService: FeatureFlagService,
    private informService: InformService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.featureFlagService
      .guaranteeIsEnabled(BOOLEAN_FLAG_NAMES.REWARDS_ACTIVITY_PANE)
      .subscribe((value) => {
        this.isRewardsActivityEnabled = value || false;
      });

    this.featureFlagService
      .guaranteeIsEnabled(BOOLEAN_FLAG_NAMES.REWARDS_TIER_PANEL)
      .subscribe((value) => {
        this.isRewardsTierEnabled = value || false;
      });

    this.redeemRewardsForm = this.formBuilder.group({
      chooseGiftCard: [null, Validators.required],
    });
    this.editRewardsEmailForm = this.formBuilder.group({
      fullName: [null, Validators.required],
      emailAddress: [null, [Validators.required, validateEmailAddress]],
    });

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.user = user;
        // We want to encourage ADP brokers to quote with us so we're giving them a little extra incentive
        if (ADP_AGENT_PRODUCER_CODES.includes(user.producer)) {
          this.coinsPerQuote = 100;
        }
        this.bindGoalsService
          .getBindGoalsData(user.producer)
          .subscribe((bindGoalsData: BindGoalsData) => {
            this.progressBarConfig = {
              indicatorTooltip: numberToMoneyString(bindGoalsData.userCurrentYearPremium),
              percentage: this.calculateProgressBarPercentage(bindGoalsData.userCurrentYearPremium),
              milestones: PROGRESS_BAR_BIND_MILESTONES,
            };
            this.determineTier(bindGoalsData);

            this.ref.detectChanges();
          });
      })
    );

    (<UntypedFormControl>this.redeemRewardsForm.get('chooseGiftCard')).valueChanges.subscribe(
      (giftCardValue: GiftCardOptions) => {
        if (giftCardValue === null) {
          return;
        }
        if (giftCardValue === 'GIFT_CARD_MAX') {
          this.giftCardDescription = `$${this.maxValue} e-gift card`;
          this.numberOfPoints = this.maxValue * NUM_POINTS_IN_A_DOLLAR;
        } else {
          this.giftCardDescription = this.giftCardOptions[giftCardValue].description;
          this.numberOfPoints = this.giftCardOptions[giftCardValue].points;
        }
      }
    );
    this.rewardsService.getUserInfoForRewards().subscribe();
    if (!this.newInformationalService.getValue(INFORMATIONAL_NAMES.REWARDS_NAV_CTA)) {
      this.newInformationalService.incrementValue(INFORMATIONAL_NAMES.REWARDS_NAV_CTA);
    }

    this.sub.add(
      this.rewardsService
        .getRewardsEmail()
        .pipe(
          tap(() => {
            if (this.requestingOptIn) {
              this.requestingOptIn = false;
              this.optinRewardsSubmitted = false;
            }
          }),
          catchError(() => {
            this.requestingOptIn = false;
            this.optInError = true;
            return observableOf(null);
          })
        )
        .subscribe((rewardsEmail) => {
          this.rewardsEmail = rewardsEmail;
          if (!this.rewardsEmail) {
            this.amplitudeService.track({
              eventName: 'rewards_checklist_optIn_form_seen',
              detail: 'rewards_checklist',
            });
          }
        })
    );

    this.sub.add(
      this.rewardsService
        .getRedemptionActionProcessingState()
        .subscribe((redemptionProcessingState) => {
          this.redemptionProcessingState = redemptionProcessingState;
          if (this.redemptionProcessingState === 'notProcessing') {
            this.redeemRewardsSubmitted = false;
          }
          // NOTE: For some reason, the angular template wasn't updating automatically after the balance was changed. After we upgrade to angular 9, we can check to see if we can avoid this manual change detection
          this.ref.detectChanges();
        })
    );

    this.sub.add(
      this.rewardsService.getRewardsBalance().subscribe(({ balance, totalRedeemedThisYear }) => {
        this.balance = balance;
        this.formattedBalance =
          this.balance > 0
            ? this.balance.toString().replace(/\B(?=(\d{3})+\b)/g, ',')
            : this.balance.toString();
        this.totalRedeemedThisYear = totalRedeemedThisYear;
        // NOTE: For some reason, the angular template wasn't updating automatically after the balance was changed. After we upgrade to angular 9, we can check to see if we can avoid this manual change detection
        this.ref.detectChanges();
        this.setValueForMaxGiftCard();
        this.disabledOptions = Object.keys(this.giftCardOptions).filter(
          (giftCardOption: GiftCardOptions) => {
            if (giftCardOption === 'GIFT_CARD_MAX') {
              return this.maxValue <= 0;
            }
            return (
              this.giftCardOptions[giftCardOption].points > this.balance ||
              this.giftCardOptions[giftCardOption].points + this.totalRedeemedThisYear >
                MAX_ALLOWED_POINTS_PER_YEAR
            );
          }
        );
        if (this.showNotEnoughRewardsMessage || this.showRewardsCapMessage) {
          // we only want to show this message after the user clicks on the "View Rewards" button so the user
          // will at least know where the button is even if they don't have enough rewards. We want to get the user
          // in the habit of knowing where the button is
          this.showRewardsCapMessage = this.totalRedeemedThisYear >= MAX_ALLOWED_POINTS_PER_YEAR;
          this.showNotEnoughRewardsMessage = this.maxValue <= 0 && !this.showRewardsCapMessage;
        }
      })
    );

    this.sub.add(
      this.userApiService.getCurrentUser().subscribe((userInfo: UserInfo) => {
        this.activatedDate = moment.utc(userInfo.activated);
      })
    );

    this.buildOptInForm();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setValueForMaxGiftCard() {
    // We round down to the nearest $5 increment because we don't want to give away all the rewards when the broker redeems MAX amount. This
    // way, we can continue sending biweekly emails to brokers about their rewards balance, and keep them coming back to quote more and redeem more rewards
    // (Emails aren't sent to brokers whose rewards balance is 0)
    const dollarBalance = Math.floor(this.balance / NUM_POINTS_IN_A_DOLLAR);
    const unusableAmt = dollarBalance % DOLLAR_AMOUNT_INCREMENT_FOR_MAX_VALUE;

    const dollarRemainingRedeemableAmount = Math.floor(
      (MAX_ALLOWED_POINTS_PER_YEAR - this.totalRedeemedThisYear) / NUM_POINTS_IN_A_DOLLAR
    );
    const unusableRedeemableAmount =
      dollarRemainingRedeemableAmount % DOLLAR_AMOUNT_INCREMENT_FOR_MAX_VALUE;

    if (dollarBalance - unusableAmt > dollarRemainingRedeemableAmount - unusableRedeemableAmount) {
      this.maxValue = dollarRemainingRedeemableAmount - unusableRedeemableAmount;
      this.showSomeRewardsUnavailableMessage = true;
    } else {
      this.maxValue = dollarBalance - unusableAmt;
      this.showSomeRewardsUnavailableMessage = false;
    }
  }

  get rewardsBalance() {
    return this.balance;
  }

  buildOptInForm() {
    this.optinRewardsForm = this.formBuilder.group({
      name: ['', Validators.required],
      rewardsEmail: ['', [Validators.required, Validators.email]],
      termsAndConditions: [false, Validators.requiredTrue],
    });
  }

  submitOptIn(event: Event) {
    event.preventDefault();
    this.optinRewardsSubmitted = true;

    if (!this.optinRewardsForm.valid) {
      return;
    }

    const { name, rewardsEmail } = this.optinRewardsForm.value;
    this.rewardsService.submitOptIn({ name, rewardsEmail });

    this.requestingOptIn = true;
    this.showUserOptInModal = false;
    this.showRewardOptions = true;
    this.amplitudeService.track({
      eventName: 'rewards_checklist_optIn_form_submitted',
      detail: 'rewards_checklist',
      useLegacyEventName: true,
    });
  }

  viewRewards() {
    if (this.maxValue <= 0) {
      // we only want to show this message after the user clicks on the "View Rewards" button so the user
      // will at least know where the button is even if they don't have enough rewards. We want to get the user
      // in the habit of knowing where the button is
      if (this.totalRedeemedThisYear >= MAX_ALLOWED_POINTS_PER_YEAR) {
        this.informService.infoToast(
          'You have maxed out the rewards you can redeem this year ($600 maximum). Congrats!'
        );
        this.showRewardsCapMessage = true;
      } else {
        this.informService.minorErrorToast(
          'You need at least 50 insurecoins in order to redeem rewards. Quote more to earn rewards!'
        );
        this.showNotEnoughRewardsMessage = true;
      }
      return;
    }
    if (!this.rewardsEmail) {
      this.showUserOptInModal = true;
    } else {
      this.showRewardOptions = true;
    }
  }

  closeUserOptInModal() {
    this.showUserOptInModal = false;
  }

  closeRewardsOptionsModal() {
    this.redeemRewardsForm.reset();
    this.giftCardDescription = '';
    this.numberOfPoints = 0;
    this.rewardsService.doneProcessingRedemption();
    this.showRewardOptions = false;
  }

  redeemRewards() {
    this.redeemRewardsSubmitted = true;
    if (!this.redeemRewardsForm.valid) {
      return;
    }
    this.rewardsService.submitRewardAction({
      actionName: ActionName.GIFT_CARD_REDEMPTION,
      data: {
        redemptionType: this.redeemRewardsForm.value.chooseGiftCard as RedemptionType,
      },
    });
  }

  editRewardsEmail() {
    this.editEmailFormSubmitted = true;
    this.editEmailProcessingState = 'processing';
    if (!this.editRewardsEmailForm.valid) {
      return;
    }
    this.rewardsService
      .editEmailAddress({
        newRewardsEmail: this.editRewardsEmailForm.value.emailAddress,
        oldRewardsEmail: this.rewardsEmail || '',
        newName: this.editRewardsEmailForm.value.fullName,
      })
      .pipe(
        catchError((error) => {
          this.editEmailProcessingState = 'error';
          throw error;
        })
      )
      .subscribe(() => {
        this.editEmailProcessingState = 'notProcessing';
        this.rewardsEmail = this.editRewardsEmailForm.value.emailAddress;
        this.closeEditEmailModal();
      });
  }

  displayEditEmailModal() {
    this.showEditEmailModal = true;
  }

  closeEditEmailModal() {
    this.showEditEmailModal = false;
  }

  // for testing
  triggerRewardAction(actionName: ActionName, insuredAccountId?: string) {
    const action: RewardRequest = { actionName };
    if (insuredAccountId) {
      action.data = { insuredAccountId };
    }
    this.rewardsService.submitRewardAction(action);
  }

  determineTier({ userCurrentYearPremium, userPreviousYearPremium }: BindGoalsData) {
    const maxYearPremium = Math.max(userCurrentYearPremium, userPreviousYearPremium);
    if (maxYearPremium < BIND_REWARDS_TIERS_CONFIG.silver.premiumTarget) {
      this.userTier = BindRewardTiers.BRONZE;
    } else if (
      maxYearPremium >= BIND_REWARDS_TIERS_CONFIG.silver.premiumTarget &&
      maxYearPremium < BIND_REWARDS_TIERS_CONFIG.gold.premiumTarget
    ) {
      this.userTier = BindRewardTiers.SILVER;
    } else if (
      maxYearPremium >= BIND_REWARDS_TIERS_CONFIG.gold.premiumTarget &&
      maxYearPremium < BIND_REWARDS_TIERS_CONFIG.platinum.premiumTarget
    ) {
      this.userTier = BindRewardTiers.GOLD;
    } else {
      this.userTier = BindRewardTiers.PLATINUM;
    }

    if (userCurrentYearPremium < BIND_REWARDS_TIERS_CONFIG.silver.premiumTarget) {
      this.rewardsTier = BindRewardTiers.BRONZE;
      this.tierCoins = BIND_REWARDS_TIERS_CONFIG.bronze.coins;
    } else if (
      userCurrentYearPremium >= BIND_REWARDS_TIERS_CONFIG.silver.premiumTarget &&
      userCurrentYearPremium < BIND_REWARDS_TIERS_CONFIG.gold.premiumTarget
    ) {
      this.rewardsTier = BindRewardTiers.SILVER;
      this.tierCoins = BIND_REWARDS_TIERS_CONFIG.silver.coins;
    } else if (
      userCurrentYearPremium >= BIND_REWARDS_TIERS_CONFIG.gold.premiumTarget &&
      userCurrentYearPremium < BIND_REWARDS_TIERS_CONFIG.platinum.premiumTarget
    ) {
      this.rewardsTier = BindRewardTiers.GOLD;
      this.tierCoins = BIND_REWARDS_TIERS_CONFIG.gold.coins;
    } else {
      this.rewardsTier = BindRewardTiers.PLATINUM;
      this.tierCoins = BIND_REWARDS_TIERS_CONFIG.platinum.coins;
    }
  }

  /*
   * This function calculates the percentage of the bind rewards progress bar completed
   * considering the premium necessary to cross each milestone varies
   *
   *            A          B    ⌄     C
   * |----------|----------|----------|
   *
   * Consider a progress bar with expected progress indicated with milestones (A, B, and C).
   * Algorithm will check each milestone to see if premium surpasses it.
   * If yes
   *      increase or 'move' the progress to threshold determined by
   *      total length of progress bar (100) / milestones (3)
   * if no
   *      find progress between previous threshold and next threshold visualized here:
   *
   *      15_000        31_000           50_000
   *      B             ⌄                 C
   *      |------------------------------|
   *
   */
  calculateProgressBarPercentage(premium: number) {
    if (premium === 0) {
      return 0;
    } else if (premium > BIND_MILESTONES_THRESHOLDS[BIND_MILESTONES_THRESHOLDS.length - 1]) {
      return 100;
    }

    const totalThresholdPercentage = 100 / BIND_MILESTONES_THRESHOLDS.length;
    let percentage = 0;
    let previousThreshold = 0;
    for (const threshold of BIND_MILESTONES_THRESHOLDS) {
      if (premium > threshold) {
        percentage += totalThresholdPercentage;
      } else {
        const thresholdRange = threshold - previousThreshold;
        const premiumPastPreviousThreshold = premium - previousThreshold;
        const progressBetweenThresholds =
          (premiumPastPreviousThreshold / thresholdRange) * totalThresholdPercentage;
        return percentage + progressBetweenThresholds;
      }
      previousThreshold = threshold;
    }

    return 100;
  }
}
