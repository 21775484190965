<div class="replacement-coverage-documen">
  <div *ngIf="policyInfo">
    <div class="replacement-coverage-centered-header">
      Attune Insurance Services, LLC<br />
      60 Broad Street, 24th Floor #2502<br />
      New York, NY 10004<br />
      <h2>REPLACEMENT COVERAGE NOTICE</h2>
    </div>

    <div class="replacement-coverage-letter-body js-replacement-coverage-info">
      Named insured and mailing address:<br />
      <br />
      {{ policyInfo.insuredName }}<br />
      {{ policyInfo.addressLine1 }}<br />
      <ng-container *ngIf="policyInfo.addressLine2"
        >{{ policyInfo.addressLine2 }}<br
      /></ng-container>
      {{ policyInfo.city }}, {{ policyInfo.state }} {{ policyInfo.zip }}<br />
      <br />
      Thanks for choosing Attune as your business insurance policy administrator. You’re receiving
      this replacement coverage notice because Blackboard is non-renewing your policy and we are
      transitioning your Businessowners' Policy (BOP) to our new carrier partner, Accredited.<br />
      <br />
      The replacement coverage premium is
      {{ policyInfo.newPremium | currency: 'USD':'symbol':'1.0-0' }}.<br />
      <br />
      Please note that the attached replacement coverage quote is based on the exposure information
      and coverage needs you or your broker shared for your prior policy period. If coverage needs
      or exposures have changed, please contact your insurance broker or agent and they will be able
      to assist you with updating your replacement coverage quote.<br />
      <br />
      Unless otherwise communicated, this replacement coverage will be automatically bound one month
      prior to the quote effective date ({{ policyInfo.newEffectiveDate }}). If you do not want to
      bind this coverage, please contact your insurance broker or agent.<br />
      <br />
      You can expect the same invoicing experience you had with Attune to date. Thirty days prior to
      your policy’s effective date, Attune will send you an invoice. If you were previously enrolled
      in auto-pay, you’ll automatically be re-enrolled with Accredited.<br />
      <br />
    </div>
  </div>
</div>
