export enum ConditionalAchievement {
  ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT = 'ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT',
  ONE_HISCOX_GL_QUOTES_ACHIEVEMENT = 'ONE_HISCOX_GL_QUOTES_ACHIEVEMENT',
  ONE_HISCOX_PL_QUOTES_ACHIEVEMENT = 'ONE_HISCOX_PL_QUOTES_ACHIEVEMENT',
  ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT = 'ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT',
  BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT = 'BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT',
  BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT = 'BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT',
  BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT = 'BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT',
}

export enum UniversalAchievement {
  ONE_ATTUNE_BOP_QUOTES_ACHIEVEMENT = 'ONE_ATTUNE_BOP_QUOTES_ACHIEVEMENT',
  ONE_ATTUNE_WC_QUOTES_ACHIEVEMENT = 'ONE_ATTUNE_WC_QUOTES_ACHIEVEMENT',
  BOP_QUOTE_ARTISAN_ACHIEVEMENT = 'BOP_QUOTE_ARTISAN_ACHIEVEMENT',
  BOP_QUOTE_RETAIL_ACHIEVEMENT = 'BOP_QUOTE_RETAIL_ACHIEVEMENT',
  BOP_QUOTE_SERVICE_ACHIEVEMENT = 'BOP_QUOTE_SERVICE_ACHIEVEMENT',
  BOP_QUOTE_OFFICE_ACHIEVEMENT = 'BOP_QUOTE_OFFICE_ACHIEVEMENT',
  BOP_QUOTE_WHOLESALER_ACHIEVEMENT = 'BOP_QUOTE_WHOLESALER_ACHIEVEMENT',
}
// The optional keys indicate which achievements are conditional as deleted in 'RewardsChecklistComponent'
export type AchievementNames = Record<UniversalAchievement, AchievementItem> &
  Partial<Record<ConditionalAchievement, AchievementItem>>;

export type CompleteAchievementNames = Record<UniversalAchievement, AchievementItem> &
  Record<ConditionalAchievement, AchievementItem>;

export enum AchievementCategory {
  LOB = 'Line of Business Achievements',
  BOP_INDUSTRY = 'Attune BOP Industry Achievements',
  BUNDLES = 'Bundle Achievements',
}

export interface AchievementItem {
  text: string;
  category: AchievementCategory;
  points: string;
}

export const UniversalAchievementItems: Record<UniversalAchievement, AchievementItem> = {
  ONE_ATTUNE_BOP_QUOTES_ACHIEVEMENT: {
    text: 'Create a quote for Attune BOP',
    category: AchievementCategory.LOB,
    points: '25',
  },
  ONE_ATTUNE_WC_QUOTES_ACHIEVEMENT: {
    text: 'Create a quote for Attune WC',
    category: AchievementCategory.LOB,
    points: '25',
  },
  BOP_QUOTE_ARTISAN_ACHIEVEMENT: {
    text: 'Quote an artisan business',
    category: AchievementCategory.BOP_INDUSTRY,
    points: '25',
  },
  BOP_QUOTE_RETAIL_ACHIEVEMENT: {
    text: 'Quote a retail business',
    category: AchievementCategory.BOP_INDUSTRY,
    points: '25',
  },
  BOP_QUOTE_SERVICE_ACHIEVEMENT: {
    text: 'Quote a service business',
    category: AchievementCategory.BOP_INDUSTRY,
    points: '25',
  },
  BOP_QUOTE_OFFICE_ACHIEVEMENT: {
    text: 'Quote an office business',
    category: AchievementCategory.BOP_INDUSTRY,
    points: '25',
  },
  BOP_QUOTE_WHOLESALER_ACHIEVEMENT: {
    text: 'Quote a wholesaler',
    category: AchievementCategory.BOP_INDUSTRY,
    points: '25',
  },
};

export const AchievementItems: CompleteAchievementNames = {
  ...UniversalAchievementItems,
  ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT: {
    text: 'Create a quote for Employers WC',
    category: AchievementCategory.LOB,
    points: '25',
  },
  ONE_HISCOX_GL_QUOTES_ACHIEVEMENT: {
    text: 'Create a quote for Hiscox GL',
    category: AchievementCategory.LOB,
    points: '25',
  },
  ONE_HISCOX_PL_QUOTES_ACHIEVEMENT: {
    text: 'Create a quote for Hiscox PL',
    category: AchievementCategory.LOB,
    points: '25',
  },
  ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT: {
    text: 'Create a quote for Coalition Cyber',
    category: AchievementCategory.LOB,
    points: '25',
  },
  BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT: {
    text: 'BOP + WC',
    category: AchievementCategory.BUNDLES,
    points: '250',
  },
  BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT: {
    text: 'BOP + PL',
    category: AchievementCategory.BUNDLES,
    points: '250',
  },
  BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT: {
    text: 'BOP + Cyber',
    category: AchievementCategory.BUNDLES,
    points: '250',
  },
};
