import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { getFormGroup } from 'app/shared/helpers/form-helpers';

import * as _ from 'lodash';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { scrollToTop } from 'app/shared/helpers/scroll-helpers';

import { formTreeToFormGroup } from 'app/shared/form-dsl/services/form-dsl-service-base';
import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';
import {
  FormDslNode,
  FormDslStep,
  FormDslData,
  ValidatorDictionary,
  ComplexValidatorDictionary,
  FormArrayValidators,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { first, catchError, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-form-dsl-stepped-form',
  templateUrl: './form-dsl-stepped-form.component.html',
  providers: [FormDslSteppedFormService],
})
export class FormDslSteppedFormComponent implements OnInit, OnDestroy {
  currentStep: RouteFormStep;
  isDevMode = isDevMode();
  isEditing: boolean;
  // TODO: Find a way to combine formConfig and formConfigSubject.
  // See TODO comments below for explanation of historical approaches.
  formConfig: FormDslStep[];
  private formConfigSubject = new Subject<FormDslStep[]>();
  // TODO: Find a way to combine formData and formDataSubject.
  // I experimented with BehaviorSubject but realized that I have to
  // initialize it, which is undeseriable since we do not want to
  // invoke populateForm until we have a value available and this value
  // is emitted by subclasses (e.g., HiscoxQuoteFormComponent).
  // I also experimented with ReplaySubject but I am not able to
  // synchronously retrieve emitted values, which is undesirable for
  // the logic that extracts the values from formData in populateForm.
  formData: FormDslData = {};
  private formDataSubject = new Subject<FormDslData>();
  formDefaults: { [inputId: string]: any } = {};
  formValidators: ValidatorDictionary<string> = {};
  formArrayValidators: FormArrayValidators<string, string> = {};
  complexFormValidators: ComplexValidatorDictionary<string> = {};
  stepTree: FormDslNode[];
  isFormInitialized = new BehaviorSubject<Boolean>(false);
  isLoadingNextStep = false;

  protected sub: Subscription = new Subscription();

  constructor(public formService: FormDslSteppedFormService) {}

  ngOnInit() {
    // NOTE: generally, we expect for the first step config
    // to be available instantly without any dependencies
    this.sub.add(
      this.formConfigSubject
        .pipe(
          first(),
          switchMap((config) => {
            this.formConfig = config;
            const firstStep = config[0];
            return firstStep.getFormTree();
          }),
          first(),
          tap((tree) => {
            const steps = this.formConfig;
            this.stepTree = tree;
            this.formService.setFormTree(tree);
            this.formService.initializeSteps(steps);
            this.formService.setFormDefaults(this.formDefaults);
            this.formService.setFormValidators(
              this.formValidators,
              this.formArrayValidators,
              this.complexFormValidators
            );
            this.formService.setIsEditing(this.isEditing);
            this.formService.initializeForm();
            this.formService.syncAllSteps();
            this.navigateToCurrentStep();
            this.isFormInitialized.next(true);
          }),
          switchMap(() => {
            return this.formDataSubject;
          }),
          catchError((error) => {
            throw new Error(`Encountered the following error while retrieving form tree: ${error}`);
          })
        )
        .subscribe((data) => {
          // populate first step of form with data
          this.formData = data;
          this.loadFirstStep();
          this.formService.setFormData(this.formData);
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // Set form config
  setFormConfig(formConfig: FormDslStep[]) {
    this.formConfigSubject.next(formConfig);
  }

  // Set form data
  setFormData(formData: FormDslData) {
    this.formDataSubject.next(formData);
  }

  // Report whether slug identifies current step
  isCurrentStep(slug: string) {
    if (!this.currentStep) {
      return false;
    }
    const currentSlug = this.currentStep.slug;
    const slugRegex = new RegExp(`^${slug}$`);
    const result = currentSlug ? currentSlug.match(slugRegex) : false;
    return result;
  }

  // Report whether current step is valid
  isCurrentStepValid() {
    return this.formService.isCurrentStepValid();
  }

  // Report whether form is in first step
  isFirstStep() {
    return this.formService.isFirstStep();
  }

  // Report whether form is in final step
  isFinalStep() {
    return this.formService.isFinalStep();
  }

  // Advance forward to next step
  clickForward() {
    if (this.formService.stepForward()) {
      this.navigateToCurrentStep();
    }
  }

  // Revert to previous step
  clickBackward() {
    if (this.formService.stepBackward()) {
      this.navigateToCurrentStep();
    }
  }

  // Retrieve fields of step
  getFieldsOfStep(path: string) {
    return this.formService.getFieldsOfStep(path);
  }

  // Navigate to current step
  protected navigateToCurrentStep(): void {
    this.currentStep = this.formService.getCurrentStep();
    scrollToTop();
  }

  // Report whether form is submittable
  isSubmittable() {
    return this.formService.isSubmittable();
  }

  // Report whether form has been submitted
  submitted() {
    return this.formService.submitted;
  }

  // Navigate to a step
  handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep, step);
    // we only allow going forward once, so even if somehow
    // we get mixed up, still only go one step forward
    if (difference > 0) {
      this.formService.stepForward();
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  // Common steps for loading a form tree
  loadFormTree(formTree: FormDslNode[]) {
    this.stepTree = formTree;
    this.formService.setFormTree(formTree);
  }

  // Load previous step of form
  loadPreviousStep(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    // metadata for previous step
    const previousPath = this.formService.getPreviousStep().formPath as string;
    const previousFormDslStep = this.formService.formPathToFormDslStep[previousPath];

    // check for cached tree of previous step
    const previousFormDslTreeCached = previousFormDslStep.formTreeCached;
    if (previousFormDslTreeCached) {
      // load previously cached tree
      this.loadFormTree(previousFormDslTreeCached);

      // retreat to previous step
      this.clickBackward();
    } else {
      // retrieve tree of previous step
      previousFormDslStep
        .getFormTree()
        .pipe(
          first(),
          catchError((error) => {
            throw new Error(`Encountered the following error while retrieving form tree: ${error}`);
          })
        )
        .subscribe((tree) => {
          // cache tree for previous step
          previousFormDslStep.formTreeCached = tree;

          // load tree for previous step
          this.loadFormTree(tree);

          // retreat to previous step
          this.clickBackward();
        });
    }
  }

  // Load next step of form
  loadNextStep(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    // prepare to load next step
    this.isLoadingNextStep = true;

    // metadata for current step
    const currentPath = this.currentStep.formPath as string;
    const currentIndex = this.formService.formPathToIndex[currentPath];

    // attempt to step forward in order to
    // trigger validation error detection
    if (!this.isCurrentStepValid()) {
      this.isLoadingNextStep = false;
      this.clickForward();
      return;
    }

    // metadata for next step
    const nextPath = this.formService.getNextStep().formPath as string;
    const nextFormDslStep = this.formService.formPathToFormDslStep[nextPath];

    // check for cached tree of next step
    const nextFormDslTreeCached = nextFormDslStep.formTreeCached;
    const isCached = nextFormDslTreeCached ? true : false;

    // retrieve tree of next step
    nextFormDslStep
      .getFormTree()
      .pipe(
        first(),
        catchError((error) => {
          throw new Error(`Encountered the following error while retrieving form tree: ${error}`);
        })
      )
      .subscribe((tree) => {
        // cache tree for next step
        nextFormDslStep.formTreeCached = tree;

        // setup form with config and data for next step
        const nextGroup = formTreeToFormGroup(tree, this.formService.formBuilder);
        this.loadFormTree(tree);
        this.formService.addStep(currentIndex + 1, nextGroup, this.isEditing, isCached);

        // proceed to next step
        this.isLoadingNextStep = false;
        this.clickForward();
      });
  }

  // Populate default or preexisting data and apply validators
  loadFirstStep() {
    const firstStepIndex = 0;
    const steps = this.formConfig;
    const formData = this.formData;

    const firstStep = steps[firstStepIndex];
    const path = firstStep.formPath as string;

    // add step
    const stepGroup = formTreeToFormGroup(this.stepTree, this.formService.formBuilder);
    this.formService.addStep(firstStepIndex, stepGroup, this.isEditing);

    // retrieve fields of step
    const stepKeys = _.keys(this.getFieldsOfStep(path));
    const stepConfig: FormDslData = {};

    // iterate through fields of step and populate
    // those that are available in formData
    stepKeys.forEach((key: string) => {
      if (key in formData) {
        stepConfig[key] = formData[key];
      }
    });
    getFormGroup(this.formService.form, path).patchValue(stepConfig);
  }

  /**
   * Handles output of FormDslInterpreterComponent.
   * NOTE: OVERRIDE FOR SUBCLASSES
   */
  handleInterpreterOutput({ methodName, args }: { methodName: string; args: any[] }) {}

  // Submit form data
  // NOTE: OVERRIDE FOR SUBCLASSES
  submitForm(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    // attempt to step forward in order to
    // trigger validation error detection
    if (!this.isSubmittable()) {
      this.clickForward();
      return;
    }
  }
}
