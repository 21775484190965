export const parseMaskedInt = (maskedInt?: string): number => {
  if (maskedInt === null || maskedInt === undefined) {
    return NaN;
  }
  if (typeof maskedInt === 'string') {
    return parseInt(maskedInt.replace(/\D+/g, ''), 10); // Remove everything besides digits.
  }
  return maskedInt;
};

// This is different from parseMaskedInt because it doesn't remove decimal points
export const parseMoney = (currencyString: string | number | null): number => {
  if (currencyString === null) {
    return NaN;
  }

  if (typeof currencyString === 'string') {
    return Number(currencyString.replace(/[^0-9+.]/g, '')); // Remove everything besides digits.
  }
  return currencyString;
};

export const random = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const randomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export const formatMoneyNoCents = (rawValue: string) => {
  const numberValue = parseFloat(rawValue);
  const numberWithoutCents = numberValue.toFixed(0);
  const numberWithCommas = numberWithoutCents.replace(/\d(?=(\d{3})+$)/g, '$&,');
  return '$' + numberWithCommas;
};

export const numberToMoneyString = (rawNum: number | undefined) => {
  if (typeof rawNum === 'undefined') {
    return '';
  }

  return '$' + rawNum.toLocaleString('en-US');
};

/**
 *
 * @param phoneNumber
 * @returns phone number string with all non-digits removed.
 */
export const normalizePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  return phoneNumber.trim().replace(/[^0-9+]/g, '');
};
