import { Observable, fromEvent, forkJoin, map, first, Subscription } from 'rxjs';
import {
  AfterViewInit,
  OnDestroy,
  Component,
  ElementRef,
  ViewChild,
  HostListener,
  Input,
} from '@angular/core';
import { AmplitudeService } from 'app/core/services/amplitude.service';

interface FeedbackMessage {
  message: string;
  position: { x: number; y: number };
  type: 'gain' | 'loss';
}
interface GameObject {
  image: HTMLImageElement;
  x: number; // x-coordinate position
  y: number; // y-coordinate position
  width: number; // width of the object
  height: number; // height of the object
  collisionOffsetX: number;
}

interface FallingObject extends GameObject {
  speed: number; // speed at which the object falls
  type: 'wrench' | 'helmet'; // type of the object
  points: number;
  isPowerup: boolean;
}

type PreInitializedFallingObject = Omit<FallingObject, 'x' | 'y' | 'image'>;

const WRENCH_SMALL: PreInitializedFallingObject = {
  width: 60,
  height: 56,
  collisionOffsetX: 5,
  speed: 4,
  type: 'wrench',
  points: -100,
  isPowerup: false,
};

const WRENCH_MEDIUM: PreInitializedFallingObject = {
  width: 90,
  height: 84,
  collisionOffsetX: 5,
  speed: 5,
  type: 'wrench',
  points: -150,
  isPowerup: false,
};

const WRENCH_LARGE: PreInitializedFallingObject = {
  width: 120,
  height: 112,
  collisionOffsetX: 10,
  speed: 6,
  type: 'wrench',
  points: -200,
  isPowerup: false,
};

const HELMET: PreInitializedFallingObject = {
  width: 60,
  height: 40,
  collisionOffsetX: 5,
  speed: 6,
  type: 'helmet',
  points: 100,
  isPowerup: false,
};

@Component({
  selector: 'app-catch-game',
  templateUrl: './catching-game.component.html',
})
export class CatchingGameComponent implements AfterViewInit, OnDestroy {
  @Input() showProgressBar = true;
  @Input() finish$: Observable<boolean>;
  @Input() title = 'Processing quote';
  @Input() successComment = 'Preparing quote...';

  @ViewChild('canvas', { static: true })
  canvasRef: ElementRef;
  context: CanvasRenderingContext2D;
  canvasWidth: number;
  canvasHeight: number;
  animationFrameId: null | number = null;

  // images
  wrenchImage: HTMLImageElement;
  helmetImage: HTMLImageElement;
  playerImage: HTMLImageElement;

  // Flag to indicate if the user has interacted with the game at all.
  hasInteracted = false;
  // Flag to indicate the game is in progress.
  gameRunning = false;

  character: GameObject;

  fallingObjects: FallingObject[] = [];
  feedbackMessages: FeedbackMessage[] = [];
  score: number = 0;
  difficultyMultiplier = 1;
  lastScoreBeforeDifficultyAdjustment: number = 0;

  // Keyboard control properties
  leftArrowPressed: boolean = false;
  rightArrowPressed: boolean = false;
  speed: number = 0;
  acceleration: number = 1.5;
  maxSpeed: number = 14;

  // Game clock + time progression
  public readonly timeStep: number = 1000 / 60;
  public startTimestamp: number | null = null;
  private lastTimestamp: number = 0;
  private accumulatedTime: number = 0;

  // Debug flag
  private debugModeOn = false;

  subscriptions: Subscription = new Subscription();

  constructor(private amplitudeService: AmplitudeService) {}

  ngAfterViewInit() {
    this.initializeGame();
  }

  ngOnDestroy() {
    this.cancelGameLoop();
    this.subscriptions.unsubscribe();

    this.amplitudeService.track({
      eventName: 'catching_game_score',
      detail: String(this.score),
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.scaleCanvas();
    // Reset characters height so they are always at the bottom of the canvas.
    this.setCharacterYPosition();
  }

  focusCanvas() {
    this.canvasRef.nativeElement.focus();
  }

  initializeGame() {
    this.context = this.canvasRef.nativeElement.getContext('2d');
    this.scaleCanvas();
    // This is needed to allow for controls with left/right arrows.
    this.focusCanvas();

    // loadImages also calls initializeCharacter() and startGameLoop() after images are loaded.
    this.loadImages();
  }

  initializeCharacter() {
    const characterWidth = 80;
    const characterHeight = 102;

    const middleX = this.canvasWidth / 2 - characterWidth / 2;

    this.character = {
      x: middleX,
      // overriden in setCharacterYPosition
      y: 0,
      collisionOffsetX: 5,
      width: characterWidth,
      height: characterHeight,
      image: this.playerImage,
    };
    this.setCharacterYPosition();
  }

  startGameLoop(timestamp?: number) {
    if (!timestamp) {
      // Store animationFrameId for teardown.
      this.animationFrameId = requestAnimationFrame((newTimestamp) => {
        this.startGameLoop(newTimestamp);
      });
      return;
    }

    // Offset the starting timestamp since timestamp returned from requestAnimationFrame will keep growing
    // Even after the component is destroyed.
    if (this.startTimestamp === null) {
      this.startTimestamp = timestamp;
    }

    this.gameRunning = true;

    const deltaTime = timestamp - this.lastTimestamp - this.startTimestamp;
    this.lastTimestamp = timestamp - this.startTimestamp;

    this.accumulatedTime += deltaTime;

    // Call update() once per timeStep interval.
    while (this.accumulatedTime >= this.timeStep) {
      this.update();
      this.accumulatedTime -= this.timeStep;
    }

    this.draw();
    if (this.gameRunning) {
      // Store animationFrameId for teardown.
      this.animationFrameId = requestAnimationFrame((newTimestamp) =>
        this.startGameLoop(newTimestamp)
      );
    }
  }

  cancelGameLoop() {
    this.gameRunning = false;
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  update() {
    this.checkCollisionsAndUpdateScore();
    this.adjustDifficulty();
    this.updateFallingObjects();
    this.moveCharacterByKeyboardIfApplicable();
  }

  checkCollisionsAndUpdateScore() {
    const objectsToRemove: GameObject[] = [];
    for (let i = 0; i < this.fallingObjects.length; i++) {
      const obj = this.fallingObjects[i];

      if (this.isColliding(this.character, obj)) {
        const pointsScored = obj.points;
        this.score += pointsScored;
        this.createFeedbackMessage(obj, pointsScored);
        objectsToRemove.push(obj);
      }
    }
    // Remove objects already collided to prevent multiple hits.
    this.fallingObjects = this.fallingObjects.filter((obj) => !objectsToRemove.includes(obj));
  }

  adjustDifficulty() {
    const threshold = 100;
    const difficultyIncrease = 0.05;

    if (this.score >= this.lastScoreBeforeDifficultyAdjustment + threshold) {
      this.difficultyMultiplier += difficultyIncrease;
      this.lastScoreBeforeDifficultyAdjustment += threshold;
    }
  }

  updateFallingObjects() {
    // Move the falling objects downwards
    for (const obj of this.fallingObjects) {
      obj.y += obj.speed;
    }

    // Spawn new wrenches and helmets occasionally
    this.createWrenchByChance();
    this.createHelmetByChance();
  }

  createWrenchByChance() {
    const wrenchChance = 0.01 * this.difficultyMultiplier;
    const randomNum = Math.random();
    let selectedWrench = WRENCH_SMALL;

    // Adjust wrench type based on difficultyMultiplier
    if (this.difficultyMultiplier >= 1.2) {
      if (randomNum < 0.7) {
        selectedWrench = WRENCH_SMALL;
      } else if (randomNum < 0.9) {
        selectedWrench = WRENCH_MEDIUM;
      } else {
        selectedWrench = WRENCH_LARGE;
      }
    } else {
      selectedWrench = WRENCH_SMALL;
    }

    if (Math.random() < wrenchChance) {
      this.fallingObjects.push({
        ...selectedWrench,
        image: this.wrenchImage,
        x: this.getRandomXCanvasPosition(selectedWrench.width),
        y: 0,
        speed: this.getVariedWrenchSpeed(selectedWrench),
      });
    }
  }

  private getSpeedMultiplier() {
    const slowChance = 0.25;

    if (this.difficultyMultiplier > 3 && Math.random() < slowChance) {
      // Every once in a while return a multiplier between 0.25 and 0.5 to add more variety.
      return 0.25 + Math.random() * 0.25;
    }
    // Return a random number between 1 and 1.25
    return 1 + Math.random() * 0.25;
  }

  private getVariedWrenchSpeed(selectedWrench: PreInitializedFallingObject) {
    return selectedWrench.speed * this.difficultyMultiplier * this.getSpeedMultiplier();
  }

  private getVariedHelmetSpeed() {
    // Increase helmet speed at roughly half the speed of wrenches.
    return HELMET.speed * (1 + (this.difficultyMultiplier - 1) * 0.5) * this.getSpeedMultiplier();
  }

  private getPowerUpPoints() {
    const POWER_UP_POINT_OPTIONS = [500, 600, 700, 800, 900, 1000, 2000];
    return POWER_UP_POINT_OPTIONS[Math.floor(Math.random() * POWER_UP_POINT_OPTIONS.length)];
  }

  private getRandomXCanvasPosition(width: number) {
    return Math.random() * (this.canvasWidth - width);
  }

  createHelmetByChance() {
    if (Math.random() < 0.02) {
      // 2% chance to spawn a regular helmet
      this.fallingObjects.push({
        ...HELMET,
        image: this.helmetImage,
        x: this.getRandomXCanvasPosition(HELMET.width),
        y: 0,
        speed: this.getVariedHelmetSpeed(),
      });
    }

    if (this.difficultyMultiplier >= 1.2 && Math.random() < 0.005) {
      const powerUpPoints = this.getPowerUpPoints();
      const powerUpScale = powerUpPoints / 500;
      const scaledWidth = HELMET.width * powerUpScale;
      this.fallingObjects.push({
        ...HELMET,
        width: scaledWidth,
        height: HELMET.height * powerUpScale,
        image: this.helmetImage,
        x: this.getRandomXCanvasPosition(scaledWidth),
        y: 0,
        speed: this.getVariedHelmetSpeed(),
        points: powerUpPoints,
        isPowerup: true,
      });
    }
  }

  createFeedbackMessage(fallingObject: FallingObject, points: number) {
    const collisionPosition = {
      x: fallingObject.x,
      y: fallingObject.y,
    };
    const newFeedback: FeedbackMessage = {
      message: `${points > 0 ? '+' : ''}${points} points`,
      position: collisionPosition,
      type: points > 0 ? 'gain' : 'loss',
    };
    this.feedbackMessages.push(newFeedback);

    // Remove the message after animation duration which is 2.5s.
    // TODO - maybe refactor to use observables instead of setTimeout.
    setTimeout(() => {
      this.feedbackMessages = this.feedbackMessages.filter(
        (feedbackMessage) => feedbackMessage !== newFeedback
      );
    }, 2500);
  }

  moveCharacterByKeyboardIfApplicable() {
    if (this.leftArrowPressed) {
      // decrease speed by acceleration val
      this.speed -= this.acceleration;
      if (this.speed < -this.maxSpeed) {
        // prevent going too fast in one direction or the other.
        this.speed = -this.maxSpeed;
      }
    } else if (this.rightArrowPressed) {
      this.speed += this.acceleration;
      if (this.speed > this.maxSpeed) {
        this.speed = this.maxSpeed;
      }
    }

    this.character.x += this.speed;

    // keep char within left/right bounds
    if (this.character.x < 0) {
      this.character.x = 0;
      this.speed = 0;
    } else if (this.character.x + this.character.width > this.canvasWidth) {
      this.character.x = this.canvasWidth - this.character.width;
      this.speed = 0;
    }
  }

  draw() {
    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.drawCharacter();
    this.drawFallingObjects();
    // Draw the falling objects, score, etc.
  }

  drawCharacter() {
    const char = this.character;
    this.context.drawImage(char.image, char.x, char.y, char.width, char.height);

    if (this.debugModeOn) {
      this.drawDebugBoundingBox(this.context, char);
    }
  }

  drawDebugBoundingBox(ctx: CanvasRenderingContext2D, obj: GameObject) {
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    // Draw a red line for debugging purposes around the bounding box used for collision detection.
    const widthAfterOffset = obj.width - 2 * obj.collisionOffsetX;
    ctx.strokeRect(obj.x + obj.collisionOffsetX, obj.y, widthAfterOffset, obj.height);
  }

  drawFallingObjects() {
    for (const obj of this.fallingObjects) {
      if (obj.isPowerup) {
        // Add a halo-like effect
        this.context.shadowColor = '#006666';
        this.context.shadowBlur = 30;
      }
      this.context.drawImage(obj.image, obj.x, obj.y, obj.width, obj.height);

      if (obj.isPowerup) {
        // reset after the image has been drawn.
        this.context.shadowColor = 'transparent';
        this.context.shadowBlur = 0;
      }

      if (this.debugModeOn) {
        this.drawDebugBoundingBox(this.context, obj);
      }
    }
  }

  handleMouseMove(event: MouseEvent) {
    if (!this.gameRunning) {
      return;
    }

    this.hasInteracted = true;

    // Calculate the relative x position of the mouse on the canvas
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;

    // Update the character's x position
    this.character.x = mouseX - this.character.width / 2;

    // Boundary checks
    if (this.character.x < 0) {
      this.character.x = 0;
    } else if (this.character.x > this.canvasWidth - this.character.width) {
      this.character.x = this.canvasWidth - this.character.width;
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (!this.gameRunning) {
      return;
    }
    if (event.key === 'ArrowLeft') {
      this.leftArrowPressed = true;
      this.hasInteracted = true;
    } else if (event.key === 'ArrowRight') {
      this.rightArrowPressed = true;
      this.hasInteracted = true;
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if (!this.gameRunning) {
      return;
    }
    if (event.key === 'ArrowLeft') {
      this.leftArrowPressed = false;
    } else if (event.key === 'ArrowRight') {
      this.rightArrowPressed = false;
    }
    this.speed = 0; // reset speed when key is released
  }

  loadImages() {
    const wrenchImgPath = '/assets/img/catching_game_wrench.png';
    const helmetImgPath = '/assets/img/catching_game_helmet.png';
    const playerImgPath = '/assets/img/catching_game_player.png';
    const wrenchImage$ = this.loadImage(wrenchImgPath);
    const helmetImage$ = this.loadImage(helmetImgPath);
    const playerImage$ = this.loadImage(playerImgPath);

    this.subscriptions.add(
      forkJoin([wrenchImage$, helmetImage$, playerImage$]).subscribe({
        next: ([wrenchImg, helmetImg, playerImage]) => {
          this.wrenchImage = wrenchImg;
          this.helmetImage = helmetImg;
          this.playerImage = playerImage;
          // Start game loop after images are loaded.

          this.initializeCharacter();
          this.startGameLoop();
        },
      })
    );
  }

  private loadImage(src: string): Observable<HTMLImageElement> {
    const img = new Image();
    img.src = src;
    return fromEvent(img, 'load').pipe(
      map(() => img),
      first()
    );
  }

  private isColliding(obj1: GameObject, obj2: GameObject) {
    // check for horizontal and vertical overlap;
    return (
      obj1.x + obj1.collisionOffsetX < obj2.x + obj2.width - obj2.collisionOffsetX &&
      obj1.x + obj1.width - obj1.collisionOffsetX > obj2.x + obj2.collisionOffsetX &&
      obj1.y < obj2.y + obj2.height &&
      obj1.y + obj1.height > obj2.y
    );
  }

  private scaleCanvas() {
    const canvas = this.canvasRef.nativeElement;
    const { width, height } = this.canvasDimensions();

    // Make sure the canvas appears the same on retina and non-retina screens.
    canvas.width = width * this.devicePixelRatio();
    canvas.height = height * this.devicePixelRatio();

    // Set the display size of the canvas using CSS
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';

    // Scale the context so all drawings are scaled as per above.
    this.context.scale(this.devicePixelRatio(), this.devicePixelRatio());
    this.canvasWidth = width;
    this.canvasHeight = height;

    canvas.imageSmoothingEnabled = false;
  }

  private canvasDimensions() {
    return {
      height: Math.max(
        (<HTMLElement>document.documentElement).clientHeight,
        window.innerHeight || 0
      ),
      width: Math.max((<HTMLElement>document.documentElement).clientWidth, window.innerWidth || 0),
    };
  }

  private devicePixelRatio() {
    return window.devicePixelRatio;
  }

  private setCharacterYPosition() {
    const elevation = this.character.height / 2;
    this.character.y = this.canvasHeight - this.character.height - elevation;
  }
}
