import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import { AttuneBopExcessQuoteService } from '../../services/attune-bop-excess-quote.service';

import { AttuneBopBindFormPageComponent } from '../attune-bop-bind-form-page/attune-bop-bind-form-page.component';
import { GWBindService } from '../../../../shared/services/gw-bind.service';
import { AttuneBopQuoteService } from '../../services/attune-bop-quote.service';
import { InformService } from '../../../../core/services/inform.service';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { SentryService } from '../../../../core/services/sentry.service';
import { NewInformationalService } from '../../../../shared/services/new-informational.service';
import { InsuredAccountService } from '../../../insured-account/services/insured-account.service';
import { OnboardingService } from '../../../../shared/services/onboarding.service';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { UserService } from '../../../../core/services/user.service';
import {
  BundleBindPolicyIdParams,
  BundleBindAdditionalLineItem,
  FrontendQuote,
  CoalitionEffectiveDateUpdatedResponse,
  DigitalBindRequest,
  AttuneInsuredAccount,
  QuoteResponse,
} from '../../../digital-carrier/models/types';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import { of as observableOf, forkJoin, Observable, EMPTY } from 'rxjs';
import { ISO_DATE_MASK } from '../../../../constants';
import {
  convertDateToUsFormat,
  convertUsDateIsoFormat,
} from '../../../../shared/helpers/date-time-helpers';
import { take, switchMap, map } from 'rxjs/operators';
import {
  minDateExceededValidator,
  maxDateExceededValidator,
  getControl,
} from '../../../../shared/helpers/form-helpers';
import { CYBER_MAX_ALLOWED_BIND_DATE_OFFSET_FUTURE } from '../../../coalition/models/cyber-constants.model';
import { CoalitionCyberUpdateEffectiveDateService } from '../../../coalition/services/coalition-cyber-update-effective-date.service';
import { CyberProduct } from '../../../coalition/models/cyber-typings.model';
import {
  COALITION_BUNDLE_BIND_ERROR,
  TIMEOUT_ERROR,
} from '../../../../shared/quote-error-modal/errors';
import { ErrorModalEmittedEvent } from '../../../../shared/quote-error-modal/quote-error-modal.component';
import { v4 as uuidv4 } from 'uuid';
import { SegmentService } from 'app/core/services/segment.service';

@Component({
  templateUrl: '../attune-bop-bind-form-page/attune-bop-bind-form-page.component.html',
})
export class AttuneBopBundleBindFormPageComponent
  extends AttuneBopBindFormPageComponent
  implements OnInit
{
  private readonly policyIds: BundleBindPolicyIdParams;

  private cyberPolicyId: string;
  private initialCyberEffectiveDate: string;
  private cyberEffectiveDateWasUpdated = false;

  private additionalLineItemDollars: number;
  private additionalLineItemCents: number;

  private bundleBindId: string;

  constructor(
    public bindService: GWBindService,
    public quoteService: AttuneBopQuoteService,
    protected excessQuoteService: AttuneBopExcessQuoteService,
    protected informService: InformService,
    protected insuredAccountService: InsuredAccountService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected formBuilder: UntypedFormBuilder,
    protected amplitudeService: AmplitudeService,
    protected sentryService: SentryService,
    protected newInformationalService: NewInformationalService,
    protected userService: UserService,
    protected onboardingService: OnboardingService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private cyberUpdateEffectiveDateService: CoalitionCyberUpdateEffectiveDateService,
    protected segmentService: SegmentService
  ) {
    super(
      bindService,
      quoteService,
      excessQuoteService,
      informService,
      insuredAccountService,
      route,
      router,
      formBuilder,
      amplitudeService,
      sentryService,
      newInformationalService,
      userService,
      onboardingService,
      segmentService
    );

    const { bopPolicyId, cyberPolicyId } = this.route.snapshot.queryParams;
    this.policyIds = { bopPolicyId, cyberPolicyId };
  }

  ngOnInit() {
    super.ngOnInit();
    this.bundleBindId = uuidv4();
    if (this.cyberPolicyId) {
      this.sub.add(
        forkJoin(
          this.digitalCarrierService.getQuoteDetails(this.cyberPolicyId),
          this.bopQuoteDetails.pipe(take(1))
        ).subscribe(([cyberQuote, [bopQuote]]: [FrontendQuote, [QuoteDetails]]) => {
          if (!cyberQuote) {
            this.showPageRetryGrowl('Cyber');
            this.sentryService.notify('BundleBindQuoteComponent: Unable to fetch quote details', {
              severity: 'error',
              metaData: {
                quoteId: this.cyberPolicyId,
                pasSource: 'coalition',
                product: 'cyber_admitted',
              },
            });
            return;
          }
          if (this.isValidCyberQuoteForBind(cyberQuote)) {
            // Only use the bundle flow if there is a second policy to bind.
            this.isBundleBindFlow = true;
            this.productsToBind.push({ pasSource: 'coalition', product: 'cyber_admitted' });

            this.additionalLineItems.push({
              label: 'Cyber Premium',
              amount: cyberQuote.premium as number,
              type: 'premium',
            });

            const surcharges = cyberQuote.surcharges || [];
            surcharges.forEach(({ label, amount, type }) => {
              this.additionalLineItems.push({
                label,
                amount,
                type: type === 'STATE_TAX' ? 'tax' : 'other',
              });
            });

            this.sumAdditionalLineItemTotals();

            this.initialCyberEffectiveDate = convertDateToUsFormat(cyberQuote.effectiveDate);
            this.handleEffectiveDateDiscrepancy(cyberQuote.effectiveDate, bopQuote.policyStart);
          }
          // Only toggle off loading state after both quotes are fetched and handled.
          this.toggleOffLoadingState({ isBundleBindFlow: true });
        })
      );
    }
  }

  setPolicyIds() {
    this.bopPolicyId = this.policyIds.bopPolicyId;
    if (this.policyIds.cyberPolicyId) {
      this.cyberPolicyId = this.policyIds.cyberPolicyId;
    }
  }

  sumAdditionalLineItemTotals() {
    const dollars = _.sumBy(this.additionalLineItems, ({ amount }) => {
      return Math.floor(amount);
    });

    const totalCents = _.sumBy(this.additionalLineItems, ({ amount }) => {
      return Math.round((amount % 1) * 100);
    });

    const dollarsFromSummingCents = Math.floor(totalCents / 100);

    this.additionalLineItemDollars = dollars + dollarsFromSummingCents;
    this.additionalLineItemCents = totalCents % 100;
  }

  totalDollars(): number {
    if (
      this.additionalLineItemDollars === undefined ||
      this.additionalLineItemCents === undefined
    ) {
      return this.policyPaymentPresenter.estimatedTotalIntegral;
    }
    const dollarsFromSummingCents = Math.floor(
      (this.additionalLineItemCents + this.policyPaymentPresenter.estimatedTotalFractional) / 100
    );
    return (
      this.policyPaymentPresenter.estimatedTotalIntegral +
      this.additionalLineItemDollars +
      dollarsFromSummingCents
    );
  }

  totalCents(): number {
    if (this.additionalLineItemCents === undefined) {
      return this.policyPaymentPresenter.estimatedTotalFractional;
    }

    return (
      (this.policyPaymentPresenter.estimatedTotalFractional + this.additionalLineItemCents) % 100
    );
  }

  totalTax() {
    const bopTax = this.policyPaymentPresenter.downPaymentTax;
    const taxLineItems = this.additionalLineItemsByType('tax');
    const otherTax = _.sumBy(taxLineItems, ({ amount }) => Number(amount));

    return Number((bopTax + otherTax).toFixed(2));
  }

  downPaymentSubtotal() {
    const bopDownPaymentSubtotal = this.policyPaymentPresenter.downPaymentSubtotal;
    // For bundle bind, payment for products other than BOP are included in the down payment
    const otherProductsTotal = _.sumBy(this.additionalLineItems, ({ amount }) => Number(amount));

    const downPaymentSubtotal =
      bopDownPaymentSubtotal + otherProductsTotal + this.installmentFeeDownPayment;

    return Number(downPaymentSubtotal.toFixed(2));
  }

  additionalLineItemsByType(lineItemType: BundleBindAdditionalLineItem['type']) {
    return this.additionalLineItems.filter(({ type }) => type === lineItemType);
  }

  getAdditionalLineItems() {
    return _.concat(
      this.additionalLineItemsByType('premium'),
      this.additionalLineItemsByType('other')
    );
  }

  navigateToPolicyPage(policyId: string, policyTermNumber: string) {
    this.insuredAccountService.cachebust();
    // Note: If policy Id is not passed in, then just navigate to the account page.
    if (!policyId || !policyTermNumber) {
      this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
      return;
    }
    // Note: Default to first term (1)
    this.router.navigate(
      ['/accounts', this.route.snapshot.params.accountId, 'terms', policyId, policyTermNumber],
      {
        queryParams: {
          waitForInvoice: 'true',
          secondaryPolicyId: this.cyberPolicyId,
        },
      }
    );
  }

  isValidCyberQuoteForBind(cyberQuoteDetails: FrontendQuote): cyberQuoteDetails is FrontendQuote {
    if (
      cyberQuoteDetails.pasSource !== 'coalition' ||
      cyberQuoteDetails.product !== 'cyber_admitted' ||
      cyberQuoteDetails.status !== 'quoted'
    ) {
      this.sentryService.notify('BundleBindQuoteComponent: Fetched quote is ineligible for bind', {
        severity: 'error',
        metaData: {
          quoteId: this.cyberPolicyId,
          quoteStatus: cyberQuoteDetails.status,
          pasSource: cyberQuoteDetails.pasSource,
          product: cyberQuoteDetails.product,
        },
      });
      return false;
    }
    const accountId = this.route.snapshot.params.accountId;
    if (cyberQuoteDetails.accountId !== accountId) {
      this.sentryService.notify(
        'BundleBindQuoteComponent: Fetched quote belongs to a different insured account',
        {
          severity: 'error',
          metaData: {
            quoteAccountId: cyberQuoteDetails.accountId,
            bindFlowAccoundId: accountId,
            quoteId: this.cyberPolicyId,
            quoteStatus: cyberQuoteDetails.status,
            pasSource: cyberQuoteDetails.pasSource,
            product: cyberQuoteDetails.product,
          },
        }
      );
      return false;
    }

    return true;
  }

  handleEffectiveDateDiscrepancy(cyberEffectiveDate: string, bopEffectiveDate: number) {
    const today = moment.utc().startOf('day');
    const cyberDateMoment = moment.utc(cyberEffectiveDate, ISO_DATE_MASK).startOf('day');
    const bopDateMoment = moment.utc(bopEffectiveDate).startOf('day');

    const latestEffectiveDate = [today, cyberDateMoment, bopDateMoment].reduce((latest, date) => {
      if (date.isAfter(latest)) {
        return date;
      }

      return latest;
    });

    const requireDateConfirmation = [cyberDateMoment, bopDateMoment].some(
      (date) => !latestEffectiveDate.isSame(date)
    );

    this.toggleConfirmEffectiveDateField(requireDateConfirmation);

    this.addEffectiveDateToForm(latestEffectiveDate, { isBundleBindFlow: true });
  }

  /**
   * This method overrides the one on the BOP bind component. Because the method is called from both components,
   * we use the `isBundleBindFlow` flag so we can update the effective date *only* if the method is being called
   * from the BundleBindQuoteComponent.
   */
  addEffectiveDateToForm(
    policyStart: moment.Moment,
    { isBundleBindFlow }: { isBundleBindFlow: boolean } = { isBundleBindFlow: false }
  ) {
    if (isBundleBindFlow) {
      super.addEffectiveDateToForm(policyStart);
    }
  }

  /**
   * This method overrides the one on the BOP bind component. Because the method is called from both components,
   * we use the `isBundleBindFlow` flag to avoid prematurely toggling off the loading state if the BOP quote
   * loads before the cyber quote.
   */
  toggleOffLoadingState(
    { isBundleBindFlow }: { isBundleBindFlow: boolean } = { isBundleBindFlow: false }
  ) {
    if (isBundleBindFlow) {
      super.toggleOffLoadingState();
    }
  }

  validateEffectiveDate = (formControl: UntypedFormControl) => {
    let maxFutureDate: moment.Moment;
    if (this.isBundleBindFlow) {
      this.validationMessageDateAfter =
        'Please enter an effective between today and 30 days from today.';
      maxFutureDate = moment().add(CYBER_MAX_ALLOWED_BIND_DATE_OFFSET_FUTURE, 'days');
    } else {
      // Max future date for BOP
      maxFutureDate = moment().add(3, 'months');
    }
    const today: moment.Moment = moment().startOf('day');

    const minDateValidation = minDateExceededValidator(today)(formControl);
    const maxDateValidation = maxDateExceededValidator(maxFutureDate)(formControl);
    // Validate to ensure that effective date is between today and 3 months in the future.
    if (minDateValidation) {
      return minDateValidation;
    } else if (maxDateValidation) {
      return maxDateValidation;
    } else {
      return null;
    }
  };

  /**
   * This method is called from the BindBopQuoteComponent when the BOP bind call completes
   * successfully. To avoid a race condition in GuideWire, we must wait for the BOP quote to
   * bind before binding the cyber policy.
   *
   * Once the cyber bind call resolves, we call super.handleBindSuccess() to redirect the user
   * to the policy details page.
   * */
  handleBindSuccess() {
    if (!this.isBundleBindFlow) {
      super.handleBindSuccess();
      return;
    }

    this.sub.add(this.bindCyberQuote());
  }

  bindCyberQuote() {
    return this.updateCyberEffectiveDate()
      .pipe(
        switchMap(({ bindable }) => {
          if (!bindable) {
            this.handleCyberBindError();
            return EMPTY;
          }

          const bindRequest: DigitalBindRequest = {
            email: getControl(this.form, 'policyDetails.emailAddress').value,
          };
          const account = { id: this.route.snapshot.params.accountId } as AttuneInsuredAccount;
          return this.digitalCarrierService.bindQuote(
            bindRequest,
            // We are currently only supporting Cyber Admitted for bundle bind.
            { pasSource: 'coalition', product: 'cyber_admitted' },
            account,
            this.cyberPolicyId,
            undefined,
            this.bundleBindId
          );
        })
      )
      .subscribe((bindResponse: HttpResponse<QuoteResponse>) => {
        if (bindResponse.body?.success) {
          super.handleBindSuccess();
          return;
        }

        if (bindResponse.status === 202) {
          // A 202 is returned from Service Quote if the request to Coalition's API times out.
          this.amplitudeService.track({
            eventName: 'coalition_cyber_bind_timeout_modal',
            detail: 'bundle',
            useLegacyEventName: true,
          });

          this.showProgressBar = false;
          this.errorModalOpen = true;
          this.bindError = TIMEOUT_ERROR;
          return;
        } else {
          this.handleCyberBindError();
        }
      });
  }

  updateCyberEffectiveDate(): Observable<CoalitionEffectiveDateUpdatedResponse> {
    const effectiveDateOnForm = this.effectiveDate.value;
    if (
      this.cyberEffectiveDateWasUpdated ||
      this.initialCyberEffectiveDate === effectiveDateOnForm
    ) {
      return observableOf({ bindable: true });
    }

    const isoEffectiveDate = convertUsDateIsoFormat(effectiveDateOnForm);
    // Currently, we will only support the admitted product for the bundle bind flow.
    const product: CyberProduct = 'cyber_admitted';
    return this.cyberUpdateEffectiveDateService
      .updateEffectiveDate(this.cyberPolicyId, isoEffectiveDate, product)
      .pipe(
        map((updateEffectiveDateResponse) => {
          if (this.cyberUpdateEffectiveDateService.isSuccessResponse(updateEffectiveDateResponse)) {
            this.cyberEffectiveDateWasUpdated = true;
            return updateEffectiveDateResponse;
          } else {
            return { bindable: false };
          }
        })
      );
  }

  callBindService() {
    super.callBindService(this.bundleBindId);
  }

  handleCyberBindError() {
    this.showProgressBar = false;
    this.bindError = COALITION_BUNDLE_BIND_ERROR;
    this.errorModalOpen = true;
  }

  onCloseErrorModal({ retry }: ErrorModalEmittedEvent) {
    if (retry) {
      this.errorModalOpen = false;
      this.showProgressBar = true;
      this.sub.add(this.bindCyberQuote());
      return;
    }

    this.goBackToAccount();
  }
}
