import { ProductCombinationForAvailability } from '../../digital-carrier/models/types';

export interface AppetiteProductEligibility {
  isEligible: boolean;
  productCombination: ProductCombinationForAvailability;
}

export interface TopClassCode {
  category: Exclude<PopularClassCodeIndustry, 'All Industries'>;
  code: string;
  // Used for exceptions to the Industry value, e.g. landscapers, per UW Guidance
  // https://docs.google.com/document/d/1K_I1Ula5RWN7OJTYEVUan5dsLPrhR8v1vjeo6nll5G0/edit?usp=sharing
  colorOverride: Color;
  description: string;
  naicsCode: string;
  naicsDescription: string;
  naicsHash: string;
  products: AppetiteProductEligibility[];
}

export enum PopularClassCodeIndustry {
  ALL = 'All Industries',
  ARTISAN_MAKERS = 'Artisan Makers',
  CONTRACTOR = 'Contractor',
  MERCANTILE = 'Mercantile',
  OFFICE = 'Office',
  PROCESSING_AND_SERVICE = 'Processing and Service',
  RESTAURANT = 'Restaurant',
  WHOLESALER = 'Wholesaler',
}

export interface RecommendationAttributes {
  color: Color;
  hasNonIndustryExclusions: boolean; // e.g. Tier 1, specific counties
  excludedIndustries: typeof PopularClassCodeIndustry[keyof typeof PopularClassCodeIndustry][];
}

export type Color = 'red' | 'yellow' | 'green' | 'n/a'; // green = include non-red, yellow = include green, red = include none
export type State =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CZ'
  | 'CO'
  | 'CT'
  | 'DE'
  | 'DC'
  | 'FL'
  | 'GA'
  | 'GU'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'PR'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VI'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY';
