import { Directive, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<boolean>();

  constructor(private elRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(element: any) {
    const clickedOutside = !this.elRef.nativeElement.contains(element);
    if (clickedOutside) {
      this.clickOutside.emit(true);
    }
  }
}
