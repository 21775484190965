import {
  DraftQuoteResponse,
  CoalitionCyberAdmittedProduct,
  CoalitionCyberSurplusProduct,
} from 'app/features/digital-carrier/models/types';
import { Observable } from 'rxjs';
import { ValidatorDictionary } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { Industry } from 'app/shared/services/naics.service';

export enum CoalitionCyberFormStepPath {
  POLICY_INFO = 'policyInfo',
  UNDERWRITING = 'underwriting',
  COVERAGE = 'coverage',
  QUICK_QUOTE = 'quickQuote',
}

export enum ThirdPartyCoverageNestedQuestion {
  BIPD_THIRD_PARTY = 'bipd_third_party',
  MEDIA_LIABILITY = 'media_liability',
  NETWORK = 'network',
  PCI = 'pci',
  POLLUTION = 'pollution',
  REGULATORY_DEFENSE = 'regulatory_defense',
}

export enum FirstPartyCoverageNestedQuestion {
  ASSET_RESTORATION = 'asset_restoration',
  BIPD_FIRST_PARTY = 'bipd_first_party',
  BREACH_RESPONSE = 'breach_response',
  BREACH_RESPONSE_COSTS = 'breach_response_costs',
  BREACH_RESPONSE_SERVICES = 'breach_response_services',
  BUSINESS_INTERRUPTION = 'business_interruption',
  COMPUTER_REPLACEMENT = 'computer_replacement',
  CRISIS_MANAGEMENT = 'crisis_management',
  EXTORTION = 'extortion',
  FUNDS_TRANSFER = 'funds_transfer',
  REPUTATION = 'reputation',
  REPUTATIONAL_HARM = 'reputational_harm',
  SERVICE_FRAUD = 'service_fraud',
  TECH_EO = 'tech_eo',
}

export enum MitigatingClauseNestedQuestion {
  BINDING_ARBITRATION = 'BINDING_ARBITRATION',
  CUSTOMER_SIGN_OFF = 'CUSTOMER_SIGN_OFF',
  DISCLAIMER_OF_WARRANTIES = 'DISCLAIMER_OF_WARRANTIES',
  EXCLUSION_OF_DAMAGES = 'EXCLUSION_OF_DAMAGES',
  HOLD_HARMLESS_AGREEMENTS = 'HOLD_HARMLESS_AGREEMENTS',
  INDEMNIFICATION = 'INDEMNIFICATION',
  LIMITATION_OF_LIABILITY = 'LIMITATION_OF_LIABILITY',
  MILESTONES = 'MILESTONES',
}

export enum EngagedIndustriesNestedQuestion {
  ADULT = 'ADULT',
  CRYPTO_BLOCKCHAIN = 'CRYPTO_BLOCKCHAIN',
  GAMBLING = 'GAMBLING',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  MSP_MSSP_RNASP = 'MSP_MSSP_RNASP',
}

export enum CoalitionCyberQuestion {
  ADDRESS = 'address',
  AGGREGATE_LIMIT = 'aggregate_limit',
  AWARE_OF_NEW_CLAIMS = 'aware_of_new_claims',
  AWARE_OF_NEW_CLAIMS_EXPLANATION = 'aware_of_new_claims_explanation',
  BACKUP_AND_RESTORE = 'backup_and_restore',
  BUNDLE = 'bundle',
  CC_CUSTOMER_COUNT = 'cc_customer_count',
  CC_CUSTOMER_COUNT_MORE_THAN_MILLION = 'cc_customer_count_more_than_million',
  COMPANY_EMPLOYEE_COUNT = 'company_employee_count',
  COMPANY_GROSS_PROFIT_NET_REVENUE = 'company_gross_profit_net_revenue',
  COMPANY_INDUSTRY_ID = 'company_industry_id',
  COMPANY_REVENUE = 'company_revenue',
  CONTENT_COMPLAINTS = 'content_complaints',
  DEFAULT_RETENTION = 'default_retention',
  DOMAIN_NAMES = 'domain_names',
  DUAL_CONTROL = 'dual_control',
  EFFECTIVE_DATE = 'effective_date',
  ENCRYPTS_DATA = 'encrypts_data',
  HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY = 'has_eo_mpl',
  HAS_TECH_EO = 'has_tech_eo',
  IS_MSP_OR_BAD_INDUSTRY = 'is_msp_or_bad_industry',
  MFA_EMAIL = 'mfa_email',
  MFA_REMOTE_ACCESS = 'mfa_remote_access',
  MFA_OTHER_PRIVILEGED_ACCOUNTS = 'mfa_other_privileged_accounts',
  MITIGATING_CLAUSES = 'mitigating_clauses',
  PII_PHI = 'pii_phi',
  PII_PHI_COUNT = 'pii_phi_count',
  PII_PHI_COUNT_MORE_THAN_MILLION = 'pii_phi_count_more_than_million',
  PRIOR_CLAIMS = 'prior_claims',
  PRIOR_CLAIMS_EXPLANATION = 'prior_claims_explanation',
  PROFESSIONAL_SERVICES = 'professional_services',
  REVIEWS_CONTENT = 'reviews_content',
  SERVICES_BY_CONTRACT = 'services_by_contract',
  TECH_EO_DISPUTE = 'tech_eo_dispute',
  TECH_EO_DISPUTE_EXPLANATION = 'tech_eo_dispute_explanation',
  THIRD_PARTY_COVERAGES = 'third_party_coverages',
  FIRST_PARTY_COVERAGES = 'first_party_coverages',
  AWARE_OF_PRIOR_CLAIMS = 'aware_of_prior_claims',
  AWARE_OF_PRIOR_CLAIMS_COUNT = 'aware_of_prior_claims_count',
  AWARE_OF_PRIOR_CLAIMS_AMOUNT = 'aware_of_prior_claims_amount',
  AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K = 'aware_of_prior_claims_amount_over_500k',
  MFA_VPN = 'mfa_vpn',
  DUAL_CONTROL_5K = 'dual_control_5k',
  ADMINISTRATIVE_DUAL_CONTROL = 'administrative_dual_control',
  ENGAGED_INDUSTRIES = 'engaged_industries',
}

// Properties present on submission payload but not in form.
export enum CoalitionCyberSubmissionField {
  END_DATE = 'end_date',
  CITY = 'city',
  POSTCODE = 'postcode',
  STATE = 'state',
  STREET_LINE_1 = 'street_line_1',
  STREET_LINE_2 = 'street_line_2',
}

export enum AttestationFormFields {
  AUTHORIZED_CARRIER = 'authorizedCarrier',
  NAME_OF_DECLINER = 'nameDecliner',
  CONTACT = 'contact',
  DATE = 'date',
  REASON = 'reason',
  RESULT_CODE = 'resultCode',
  SURPLUS_LINES_CARRIER = 'surplusLinesCarrier',
  QUOTE_NUMBER = 'quoteNumber',
  DECLINATION_REASON = 'declinationReason',
}

export interface AttestationForm {
  attestationText: string;
  requiredCarriers: number;
  simpleTextQuestions: {
    id: string;
    labelText: string;
  }[];
  carrierQuestions: {
    date?: true;
    reason?: true;
    resultCode?: {
      display: Record<string, string>;
      value: Record<string, string>;
      labelText: string;
    };
    declinationReason?: true;
  };
}

export interface AttestationFormData {
  attestation: true;
  carriers?: Record<string, string>[];
}

// This type includes all controls: both top-level and nested
export type CoalitionCyberControlName =
  | ThirdPartyCoverageNestedQuestion
  | FirstPartyCoverageNestedQuestion
  | MitigatingClauseNestedQuestion
  | CoalitionCyberQuestion;

export type NestedGroupValue =
  | Record<ThirdPartyCoverageNestedQuestion, boolean>
  | Record<FirstPartyCoverageNestedQuestion, boolean>
  | Record<MitigatingClauseNestedQuestion, boolean>
  | Record<EngagedIndustriesNestedQuestion, boolean>;

export type FormValue = string | boolean | number;

// NAICS_CODE is only used in abbreviated quote flows where it is possible
// to omit the company industry ID. It does not appear in the form visually,
// but rather is added programmatically to raw form data.
export const NAICS_CODE = 'naics_code';
export type CoalitionNaicsCode = typeof NAICS_CODE;

export const INSURANCE_MARKET_CONTROL = 'market';
export type InsuranceMarketControl = typeof INSURANCE_MARKET_CONTROL;

export type CoalitionCyberFormDataRaw = Partial<
  Record<
    CoalitionCyberQuestion | InsuranceMarketControl | CoalitionNaicsCode,
    FormValue | NestedGroupValue | Address | Industry
  >
>;

export type CoalitionCyberFormDataFormatted = Record<
  CoalitionCyberControlName | CoalitionCyberSubmissionField | CoalitionNaicsCode,
  FormValue
>;

export enum CyberQuestionEnablementFlag {
  REJECT_BUSINESS_INTERRUPTION = 'reject_business_interruption',
  REJECT_FTF = 'reject_ftf',
  REJECT_MEDIA_LIABILITY = 'reject_media_liability',
  REJECT_TECH_EO = 'reject_tech_eo',
  REQUIRE_EO_MPL = 'require_eo_mpl',
  REQUIRE_TECH_EO = 'require_tech_eo',
  USE_GROSS_PROFIT_NET_REVENUE = 'use_gross_profit_net_revenue',
}

export type CyberQuestionEnablementFlags = Record<CyberQuestionEnablementFlag, boolean>;

export enum CyberMarketingGroup {
  CONTRACTOR_RISKS = 'contractors',
  MEDICAL_OFFICES = 'medical-offices',
  RETAIL_SHOPS = 'retail-shops-and-professional-services',
  REAL_STATE_PROP_MGT = 'real-state-prop-mgt', // aka lessors-risks
  INSURANCE_AGENCIES = 'insurance',
  LAW_OFFICES = 'law-offices',
  ACCOUNTANTS_BOOKKEEPERS = 'accountants-bookkeepers',
  WHOLESALE_DISTRIBUTORS = 'wholesale-distributors',
  REAL_STATE_AGENTS = 'real-state-agents',
}

export interface CyberMarketingConfig {
  header: string;
  body: string;
  case: string;
}

export type CyberIndustryData = {
  display_name: string;
  gics_code: string;
  id: number;
} & CyberQuestionEnablementFlags;

export interface LossEstimate {
  median_loss: number;
  '1_10_loss': number;
  '1_100_loss': number;
}

export interface CommonIncident {
  name: string;
  value: string;
}

export interface BreachEstimate {
  overall: LossEstimate & { common_incidents: CommonIncident[] };
  ransomware: LossEstimate;
  ftf: LossEstimate;
  breach: LossEstimate;
}

export type CyberDraftQuoteResponse = DraftQuoteResponse & {
  details: {
    companyIndustry: CyberIndustryData;
    insuranceMarket: CyberProduct;
  };
};

export type CyberProduct =
  | CoalitionCyberAdmittedProduct['product']
  | CoalitionCyberSurplusProduct['product'];

export type PathCyberProduct = 'cyber-admitted' | 'cyber-surplus';

export type CoalitionCyberMarket = 'ADMITTED' | 'SURPLUS';

export type CoalitionCyberMarketDisplay = 'Admitted' | 'Surplus';

interface ProductDependency {
  shouldEnableForValue: CyberProduct;
}

interface FlagDependency {
  flagName: CyberQuestionEnablementFlag;
  shouldEnableForValue: boolean;
}

interface ControlDependency {
  controlName: CoalitionCyberControlName;
  shouldEnableForValue: boolean | string | Array<Industry>;
}

interface CustomControlDependency {
  controlName: CoalitionCyberControlName;
  callback: (val: FormValue) => boolean;
}

export interface CyberDependency {
  productDependency?: ProductDependency;
  flagDependency?: FlagDependency;
  controlDependency?: ControlDependency;
  customControlDependency?: CustomControlDependency;
}

export type DependencyValue =
  | FormValue
  | CyberProduct
  | CyberQuestionEnablementFlags
  | null
  | Industry;

export interface DependencySubscription {
  dependsOn: Observable<DependencyValue>;
  callback: (val: DependencyValue) => boolean;
}

export type CoalitionCyberValidators = ValidatorDictionary<
  CoalitionCyberControlName | CoalitionCyberFormStepPath
>;

export enum BundleOption {
  ESSENTIAL = 'Essential',
  MOST_POPULAR = 'Most popular',
  CUSTOM = 'Custom',
}

export interface CoverageGroupPatchValues {
  [CoalitionCyberQuestion.AGGREGATE_LIMIT]?: number;
  [CoalitionCyberQuestion.DEFAULT_RETENTION]?: number;
  [CoalitionCyberQuestion.THIRD_PARTY_COVERAGES]: Partial<
    Record<ThirdPartyCoverageNestedQuestion, boolean>
  >;
  [CoalitionCyberQuestion.FIRST_PARTY_COVERAGES]: Partial<
    Record<FirstPartyCoverageNestedQuestion, boolean>
  >;
}

export enum IneligibleForAdmittedReason {
  HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY_REQUIRED = 'Will the applicant have an active errors and omissions or management professional liability policy concurrent with this insurance policy?',
  EXCEEDS_MAXIMUM_REVENUE = 'Risks with greater than $250M in revenue are not eligible for the Coalition Cyber admitted product.',
  INVALID_STATE = 'The Coalition Cyber admitted product is not available in this state.',
  TECH_EO_REQUIRED = 'For this industry, Coalition requires that a risk has an active technology errors and omissions or miscellaneous professional liability policy concurrent with this admitted cyber insurance policy.',
}

export const INELIGIBLE_FOR_ADMITTED =
  'To complete a quote in the surplus market, please start a new quote from the accounts page.';

export type EarlyDeclineReason = IneligibleForAdmittedReason | typeof INELIGIBLE_FOR_ADMITTED;

export type UpsellEarlyDeclineKey = 'exceedsMaxRevenue' | 'techEORequired';

export const UpsellEarlyDeclineReasons: Record<UpsellEarlyDeclineKey, IneligibleForAdmittedReason> =
  {
    exceedsMaxRevenue: IneligibleForAdmittedReason.EXCEEDS_MAXIMUM_REVENUE,
    techEORequired: IneligibleForAdmittedReason.TECH_EO_REQUIRED,
  };

export type CoalitionDocumentType =
  | 'BINDER'
  | 'CYBER_RISK_ASSESSMENT'
  | 'POLICY_BUNDLE'
  | 'QUOTATION'
  | 'SIGNATURE_BUNDLE'
  | 'SIGNED_SIGNATURE_BUNDLE'
  | 'RENEWAL_SIGNATURE_BUNDLE'
  | 'SPECIMEN_POLICY';

export interface CoalitionDocument {
  label: string;
  name: string;
  documentType: CoalitionDocumentType;
}

const QUOTE_STATUSES_WITH_CARRIER_DOCUMENTS = [
  'bound',
  'bound_with_subjectivity',
  'quoted',
] as const;

export type QuoteStatusWithCarrierDocuments = typeof QUOTE_STATUSES_WITH_CARRIER_DOCUMENTS[number];

export const quoteStatusHasAssociatedCarrierDocuments = (
  status: string
): status is QuoteStatusWithCarrierDocuments => {
  return QUOTE_STATUSES_WITH_CARRIER_DOCUMENTS.includes(status as QuoteStatusWithCarrierDocuments);
};

const QUOTE_STATUSES_WITH_COMPLIANCE_DOCUMENTS = [
  'bound',
  'bound_with_subjectivity',
  'quoted',
] as const;

export type QuoteStatusWithComplianceDocuments =
  typeof QUOTE_STATUSES_WITH_COMPLIANCE_DOCUMENTS[number];

export const quoteStatusHasAssociatedComplianceDocuments = (
  status: string
): status is QuoteStatusWithComplianceDocuments => {
  return QUOTE_STATUSES_WITH_COMPLIANCE_DOCUMENTS.includes(
    status as QuoteStatusWithComplianceDocuments
  );
};
