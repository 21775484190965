import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, interval, timer as observableTimer, Observable, NEVER } from 'rxjs';
import { first, tap, switchMap, catchError } from 'rxjs/operators';
import { SentryService } from 'app/core/services/sentry.service';

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

interface HealthEndPointResponse {
  status: 'OK';
  current_git_sha: string;
  build: string;
}

@Injectable()
export class VersionCheckService {
  // This will be replaced by actual hash post-build.js
  public currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  public hasCodeBeenUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private sentryService: SentryService) {}

  // Polls health endpoint of portal-agent every 5 minutes to check if it has the latest version.
  public initializeVersionCheck(url: string, frequency = FIVE_MINUTES_IN_MS) {
    return interval(frequency).pipe(switchMap(() => this.checkVersion(url)));
  }

  public requestHealthEndpoint(url: string, tries: number = 2): Observable<HealthEndPointResponse> {
    tries--;

    // Disable browser caching for this request.
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
    });

    return this.http.get<HealthEndPointResponse>(url, { headers: headers }).pipe(
      catchError((error) => {
        if (tries > 0) {
          return this.requestHealthEndpoint(url, tries);
        }

        this.sentryService.notify('Could not get application build version.', {
          severity: 'warning',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        // Note: Stifle errors so that the health check interval continues to check
        return NEVER;
      })
    );
  }

  public checkVersion(url: string) {
    return this.requestHealthEndpoint(url).pipe(
      first(),
      tap((response: any) => {
        const hash = response && response.build;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        if (hashChanged) {
          // store the new hash so we wouldn't trigger versionChange again
          // Not necessary if user is forced to refresh
          this.currentHash = hash;

          // We wait 5 minutes to allow for all instances of the Nginx servers to switch over the new code post-deployment.
          // If hash has changed, emit new value to behavior subject.
          // We can subscribe to this behavior subject on routes we can safely trigger a refresh, ie accounts list.
          observableTimer(FIVE_MINUTES_IN_MS).subscribe(() => {
            this.hasCodeBeenUpdated.next(true);
          });
        }
      })
    );
  }

  /**
   * Checks if hash has changed.
   * This file has the main js hash, if it is a different one than in the version file
   * then we are dealing with version change
   */
  private hasHashChanged(currentHash: string, newHash: string) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
