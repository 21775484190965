import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
  WcBackendPricedQuote,
  WcPolicyDocument,
  WcPricedQuote,
  WcQuoteWithDocuments,
} from '../models/wc-priced-quote';
import { DigitalCarrierPolicyService } from '../../../features/digital-carrier/services/digital-carrier-policy.service';
import { InformService } from '../../../core/services/inform.service';
import { SentryService } from '../../../core/services/sentry.service';
import {
  DigitalCarrierPolicyDetails,
  DigitalCarrierProduct,
} from '../../../features/digital-carrier/models/types';
import { DIGITAL_PRODUCTS, US_DATE_ALL_DIGIT_MASK, US_DATE_MASK } from '../../../constants';
import { ProductEligibility } from '../../../shared/services/naics.service';
import { WcQuoteService } from '../services/workers-comp-quote.service';
import { ProducerDetailsResponse } from '../../../bop/guidewire/typings';
import { InsuredAccountService } from '../../../features/insured-account/services/insured-account.service';
import {
  NON_BINDING_ROLES,
  UNABLE_TO_BIND_MESSAGE,
  UserService,
} from '../../../core/services/user.service';
import { DocumentService } from '../../../features/documents/services/document.service';
import { getEmployersWCQuoteLetterFileName } from '../../../features/documents/models/document-file-names.model';
import { retryWithBackoff } from '../../../shared/helpers/api-helpers';

// Note: all first term policies will have `00` as their term
const WC_NEW_BUSINESS_TERM = '00';
const DOCUMENT_POLL_INTERVAL = 5000;
const DOCUMENT_POLL_MAX_MS = 60 * 1000;

@Component({
  selector: 'app-wc-policy-pane.policy-pane.policy-pane__wc',
  templateUrl: './wc-quote-pane.component.html',
  providers: [CurrencyPipe],
})
export class WcQuotePaneComponent implements OnInit, OnDestroy {
  loading = false;
  accountId: string;
  quote: WcPricedQuote | null;
  nonBindingRole: boolean;

  wcPasQuoteUuid: string;
  wcPolicyTerm: string;
  wcPolicy: Observable<DigitalCarrierPolicyDetails | null | undefined>;
  policyApiDocsLoading: 'loading' | 'success' | 'error' | 'not-ready' = 'loading';
  policyDocument: WcPolicyDocument | null = null;
  policyApiDocuments: WcPolicyDocument[] | null = null;
  employersDigitalProduct = _.find(DIGITAL_PRODUCTS, (product) => {
    return product.pasSource === 'employers';
  }) as DigitalCarrierProduct;

  // `eligibilities` is determined by AskKodiak for all insurance products
  // whereas `productAvailabilities` is determined in SQ for insurance products
  // on DCP, e.g. LM BOP. An insurance product may be eligible for a given state
  // and classcode, i.e. within appetite, but might not be available to a
  // broker.
  eligibilities: ProductEligibility;

  quoteLetterDownload$: Observable<any>;
  quoteLetterDownloadReady$: Observable<any>;

  private sub: Subscription = new Subscription();

  US_DATE_MASK = US_DATE_MASK;

  constructor(
    private route: ActivatedRoute,
    private informService: InformService,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService,
    private digitalCarrierPolicyService: DigitalCarrierPolicyService,
    private wcQuoteService: WcQuoteService,
    private userService: UserService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    this.getWorkersCompQuoteOnRouteChanges();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getWorkersCompQuoteOnRouteChanges() {
    this.loading = true;
    this.route.params
      .pipe(
        switchMap((params) => {
          this.accountId = params['accountId'];
          return this.wcQuoteService.getQuote(this.accountId);
        })
      )
      .subscribe(({ quote, documents }) => {
        this.loading = false;
        this.quote = new WcPricedQuote(quote as WcBackendPricedQuote, documents);
        if (this.quote.quoteLetterDownloadLink) {
          const filename = getEmployersWCQuoteLetterFileName(this.quote.policyNumber);
          this.quoteLetterDownload$ = this.documentService.getDocument(
            this.quote.quoteLetterDownloadLink,
            filename,
            'pdf'
          );
        } else if (this.showWcDocumentLoading()) {
          this.wcDocumentPdfPoll();
        }
      });

    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );
  }

  showWcDocumentLoading() {
    return (
      !!this.quote &&
      this.quote.isQuoted() &&
      this.quote.hasQuoteLetter &&
      !this.quote.quoteLetterDownloadLink
    );
  }

  wcDocumentPdfPoll() {
    this.quoteLetterDownloadReady$ = this.wcQuoteService.getQuote(this.accountId).pipe(
      tap((wcQuoteResponse: WcQuoteWithDocuments) => {
        this.wcQuoteService.cachebust();
        this.quote = new WcPricedQuote(
          wcQuoteResponse.quote as WcBackendPricedQuote,
          wcQuoteResponse.documents
        );

        if (this.quote.quoteLetterDownloadLink) {
          const filename = getEmployersWCQuoteLetterFileName(this.quote.policyNumber);
          this.quoteLetterDownload$ = this.documentService.getDocument(
            this.quote.quoteLetterDownloadLink,
            filename,
            'pdf'
          );
        } else {
          throw new Error(`Error loading WC quote document for ${this.accountId}`);
        }
      }),
      retryWithBackoff(50, 2000)
    );
  }

  getUtcDate(dateString: string) {
    return moment.utc(dateString).format(US_DATE_ALL_DIGIT_MASK) || '---';
  }

  handleErrorToast() {
    if (this.nonBindingRole) {
      this.informService.infoToast(UNABLE_TO_BIND_MESSAGE);
      return;
    }
  }
}
