import * as _ from 'lodash';
import { Component, Input, OnInit, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import * as moment from 'moment';

import {
  AccountSummaryLoadedView,
  DigitalCarrierPolicyDetailsWithLinks,
  FrontendQuoteWithLinks,
} from '../../services/insured-account-summary.service';
import { SentryService } from 'app/core/services/sentry.service';
import {
  DigitalCarrierProduct,
  DigitalQuoteStatus,
  ProductAvailability,
  ProductCombination,
  FrontendQuote,
} from 'app/features/digital-carrier/models/types';
import { ProductEligibility } from 'app/shared/services/naics.service';
import {
  carrierAllowsMultipleQuotes,
  carrierAllowsEdit,
  isCpspNyRisk,
} from 'app/features/digital-carrier/models/carrier-helpers';
import { DIGITAL_PRODUCTS, US_DATE_ALL_DIGIT_MASK } from 'app/constants';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import { Router } from '@angular/router';
import { productToMarketDisplay } from '../../../coalition/models/cyber-constants.model';

@Component({
  selector: 'app-insured-account-product-card',
  templateUrl: './insured-account-product-card.component.html',
})
export class InsuredAccountProductCardComponent implements OnInit, OnChanges {
  @Input() accountId: string;
  @Input() productConfig: DigitalCarrierProduct;
  @Input() accountSummary: AccountSummaryLoadedView;
  // `eligibilities` is determined by AskKodiak for all insurance products
  // whereas `productAvailabilities` is determined in SQ for insurance products
  // on DCP, e.g. LM BOP. An insurance product may be eligible for a given state
  // and classcode, i.e. within appetite, but might not be available to a
  // broker.
  @Input() eligibilities: ProductEligibility;
  status: 'empty' | 'has-quotes' | 'has-active-policy' = 'empty';
  @Input() productAvailabilities: ProductAvailability[];
  @Input() naicsCode: NaicsCode | null;
  @Input() compact = false;
  filteredQuotes: FrontendQuoteWithLinks[] = [];
  policyTerms: DigitalCarrierPolicyDetailsWithLinks[] = [];
  renewalQuotes: FrontendQuoteWithLinks[] = [];
  quotesToPremiumPlusSurplus: Record<string, number> = {};
  productUrl: string;
  isEligible = false;
  boundWithSubjectivity = false;

  latestQuotedQuote: FrontendQuoteWithLinks;
  // For `guest` products to use the correct bind link
  urlPathForBind: string;
  guestProduct: DigitalCarrierProduct | null;

  productToMarketDisplay = productToMarketDisplay;

  @HostBinding('class.app-product-card')
  get alwaysTrue() {
    return true;
  }

  constructor(
    private sentryService: SentryService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private router: Router
  ) {}

  ngOnInit() {
    this.computeState(this.productConfig, this.accountSummary);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productConfig) {
      this.computeState(
        changes.productConfig.currentValue || this.productConfig,
        changes.accountSummary.currentValue || this.accountSummary
      );
    }
  }

  displayProductCard({ pasSource, product }: ProductCombination) {
    const productAvailability = _.find(this.productAvailabilities, {
      pasSource,
      product,
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    // The entire Product Card is intentionally not displayed if a broker (via
    // their producercode) doesn't have access to a certain insurance product.
    const availableForBroker = productAvailability.classCodeSelection !== 'NONE';

    return availableForBroker;
  }

  isProductAvailable({ pasSource, product }: ProductCombination) {
    const productAvailability = _.find(this.productAvailabilities, {
      pasSource,
      product,
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    if (productAvailability.classCodeSelection === 'ALL') {
      return true;
    }

    return (
      productAvailability.classCodeSelection === 'PARTIAL' &&
      this.naicsCode &&
      _.includes(productAvailability.classCodes.naics, this.naicsCode.code)
    );
  }

  computeState(productConfig: DigitalCarrierProduct, accountSummary: AccountSummaryLoadedView) {
    // 'guest' product is a product whose quotes are shown on this product card because it does not
    // have a product card of its own.
    this.guestProduct =
      DIGITAL_PRODUCTS.find(
        ({ showOnDifferentProductCard }) =>
          showOnDifferentProductCard &&
          showOnDifferentProductCard.product === productConfig.product &&
          showOnDifferentProductCard.pasSource === productConfig.pasSource
      ) || null;

    this.boundWithSubjectivity = false;
    if (!this.eligibilities) {
      // In theory, we should never hit this block of code because
      // `this.eligibilities`, an object, is provided by the parent component
      // (`policies-pane`), and this component itself isn't rendered until
      // `eligibilities` is loaded, i.e. `loading` is false. Past error stacktraces
      // show `ngOnChanges` invokes this method, so the app might be in a weird
      // state.
      this.sentryService.notify(
        'DCP: product-card component rendered with undefined eligibilities',
        {
          severity: 'info',
          metaData: {
            eligibilities: this.eligibilities,
            productConfig,
            accountSummary,
          },
        }
      );

      return;
    }

    this.isEligible = this.eligibilities[productConfig.eligibilityField];
    if (!this.isEligible) {
      return;
    }

    const expiredQuotes: FrontendQuoteWithLinks[] = [];
    const drafts: FrontendQuoteWithLinks[] = [];
    const quotes: FrontendQuoteWithLinks[] = [];
    const renewalQuotes: FrontendQuoteWithLinks[] = [];
    let draftSeen = false;
    const matchesProduct = (
      quoteOrPolicy: FrontendQuoteWithLinks | DigitalCarrierPolicyDetailsWithLinks
    ): boolean => {
      const quoteCarrierAndProduct = _.pick(quoteOrPolicy, ['product', 'pasSource']);
      return (
        _.isMatch(productConfig, quoteCarrierAndProduct) ||
        (!!this.guestProduct && _.isMatch(this.guestProduct, quoteCarrierAndProduct))
      );
    };

    accountSummary.quotes.forEach((quote) => {
      if (!matchesProduct(quote)) {
        return false;
      }

      // Discard all drafts after the first
      if (quote.status === 'draft') {
        if (draftSeen) {
          return false;
        } else {
          draftSeen = true;
          drafts.push(quote);
        }
      }

      if (quote.status === 'expired') {
        expiredQuotes.push(quote);
      }

      if (
        ['quoted', 'declined', 'referred', 'under_review', 'unavailable'].includes(quote.status)
      ) {
        quotes.push(quote);
      }

      if (
        quote.renewalDetails?.previousQuoteUuid &&
        !['bound', 'bound_with_subjectivity'].includes(quote.status)
      ) {
        renewalQuotes.push(quote);
      }
    });

    const policyTerms = accountSummary.policyTerms.filter(matchesProduct);
    const activePolicyTerms = policyTerms.filter(
      (policyTerm) => policyTerm.status !== 'cancelled' && policyTerm.status !== 'expired'
    );

    // TODO if all policyTerms are *expired*, that should be probably be another / 5th view
    // e.g. has-expired-policy, which shows policies, but allows quoting
    if (activePolicyTerms.length > 0) {
      this.status = 'has-active-policy';
      this.policyTerms = policyTerms;
      this.filteredQuotes = quotes;
      this.renewalQuotes = this.getRenewalQuotesForActivePolicy(renewalQuotes);

      // For Cyber policies, we perform a check to see whether they are
      // bound with subjectivity, so we can surface a warning to the user
      if (this.productConfig.pasSource === 'coalition') {
        policyTerms.forEach((policyTerm) => {
          if (!policyTerm.quoteId) {
            this.sentryService.notify(
              'Attempting to fetch quote for policy term with null quoteId',
              {
                severity: 'error',
                metaData: {
                  policyTerm,
                  quoteId: policyTerm.quoteId,
                  product: this.productConfig.product,
                  pasSource: this.productConfig.pasSource,
                },
              }
            );
            return;
          }
          this.digitalCarrierService.getQuoteDetails(policyTerm.quoteId).subscribe((quote) => {
            this.boundWithSubjectivity =
              this.boundWithSubjectivity || !!(quote && quote.status === 'bound_with_subjectivity');
            // This is to correct a mismatch between a policy and quote that can occur
            // due to the asynchronous nature of webhooks
            if (policyTerm.status === 'in_force' && quote && quote.status === 'not_taken') {
              policyTerm.status = 'cancelled';
            }
          });
        });
      }
    } else if (quotes.length > 0) {
      this.status = 'has-quotes';
      this.filteredQuotes = quotes.concat(expiredQuotes);
      this.filteredQuotes.forEach((quote) => {
        if (quote.premium && quote.surcharges) {
          const surcharges = _.sumBy(quote.surcharges, 'amount');
          this.quotesToPremiumPlusSurplus[quote.uuid] = Number(quote.premium) + surcharges;
        }
      });
      const quotedQuotes = quotes.filter((quote) => quote.status === 'quoted');

      if (quotedQuotes.length > 0) {
        // sort quotes by their created sort newest to oldest, then use the first element as the latestQuoteQuote
        const sortedByCreatedQuotedQuotes = quotedQuotes.sort((quoteA, quoteB) => {
          if (moment(quoteA.createdAt).isAfter(quoteB.createdAt)) {
            return -1;
          }
          if (moment(quoteA.createdAt).isBefore(quoteB.createdAt)) {
            return 1;
          }
          return 0;
        });
        this.latestQuotedQuote = sortedByCreatedQuotedQuotes[0];
        this.urlPathForBind =
          this.guestProduct && this.latestQuotedQuote.product === this.guestProduct.product
            ? this.guestProduct.pathForUrl
            : this.productConfig.pathForUrl;
      }
    } else if (drafts.length > 0) {
      this.status = 'has-quotes';
      this.filteredQuotes = drafts.concat(expiredQuotes);
    } else if (expiredQuotes.length > 0) {
      this.status = 'has-quotes';
      this.filteredQuotes = expiredQuotes;
    } else {
      this.status = 'empty';
    }
  }

  getRenewalQuotesForActivePolicy(renewalQuotes: FrontendQuoteWithLinks[]) {
    const latestActivePolicy = this.policyTerms
      .sort((p1, p2) => {
        return moment(p2.expirationDate).diff(moment(p1.expirationDate));
      })
      .find(({ expirationDate, status }) => {
        const oneMonthAgo = moment().subtract(1, 'month');
        return (
          // don't consider policies that expired more than one month ago
          moment(expirationDate).isSameOrAfter(oneMonthAgo) &&
          ['in_force', 'scheduled'].includes(status)
        );
      });

    if (!latestActivePolicy) {
      return [];
    }

    return renewalQuotes.filter(({ renewalDetails }) => {
      if (renewalDetails?.previousQuoteUuid) {
        return latestActivePolicy.quoteId === renewalDetails.previousQuoteUuid;
      }
    });
  }

  mapQuoteStatus(status: DigitalQuoteStatus, isRenewal = false): string {
    if (status === 'declined') {
      return 'danger';
    } else if (
      status === 'referred' ||
      status === 'under_review' ||
      (isRenewal && status === 'draft')
    ) {
      return 'warning';
    } else if (status === 'quoted') {
      return 'success';
    } else if (status === 'expired') {
      return 'expired';
    }

    return 'neutral';
  }

  formatDate(timestamp: string) {
    return moment.utc(timestamp).format(US_DATE_ALL_DIGIT_MASK) || '---';
  }

  getQuoteAmplitudeInputName() {
    return `account-get-quote-${this.productConfig.pathForUrl}`;
  }

  editQuoteAmplitudeInputName() {
    return `account-edit-quote-${this.productConfig.pathForUrl}`;
  }

  isCpspNyRisk(quote: FrontendQuote) {
    return isCpspNyRisk(quote);
  }

  // Used to check if a policy should be displayed as `in force`.
  // Temporary while service quote does not update policy status to in_force.
  isInForce(policyTerm: DigitalCarrierPolicyDetailsWithLinks) {
    const effectiveDate = moment(policyTerm.effectiveDate);
    if (effectiveDate.isBefore(moment())) {
      return true;
    }
  }

  carrierAllowsMultipleQuotes() {
    return carrierAllowsMultipleQuotes(this.productConfig.pasSource);
  }

  carrierAllowsEdit() {
    if (
      !this.filteredQuotes ||
      !this.filteredQuotes.length ||
      !carrierAllowsEdit(this.productConfig.pasSource)
    ) {
      return false;
    }

    return ['draft', 'quoted'].includes(this.filteredQuotes[0].status);
  }

  editFlowIsAvailable() {
    return this.carrierAllowsMultipleQuotes() || this.carrierAllowsEdit();
  }

  getEditQuoteText() {
    return this.carrierAllowsMultipleQuotes() ? 'New quote' : 'Edit';
  }
}
