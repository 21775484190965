import {
  of as observableOf,
  Subscription,
  Observable,
  timer,
  combineLatest,
  NEVER as NEVER_OBSERVABLE,
  forkJoin,
  EMPTY,
  BehaviorSubject,
} from 'rxjs';
import { switchMap, map, shareReplay, take, first } from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterContentChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { enableDisableControl, getControl, rangeValidator } from 'app/shared/helpers/form-helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { environment } from 'environments/environment';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { AttuneBopQuoteService } from 'app/features/attune-bop/services/attune-bop-quote.service';
import { BopPolicy, BopQuotePayload } from 'app/features/attune-bop/models/bop-policy';
import { US_DATE_MASK } from 'app/constants';
import {
  BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD,
  BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD,
  isBopV1,
  isBopV2,
  NON_PREFERRED_PREFERENCE_LEVELS,
  PREFERRED_PREFERENCE_LEVELS,
  STRONGLY_PREFERRED_PREFERENCE_LEVELS,
} from 'app/features/attune-bop/models/constants';
import {
  AccountManager,
  AccountManagerFlagResponse,
  getAccountManager,
  shouldGetBopV2,
  shouldGetMeToo,
} from 'app/shared/helpers/account-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { PrefillService } from 'app/shared/services/prefill.service';
import { AttuneBopQuoteRequestService } from 'app/features/attune-bop/services/attune-bop-quote-request.service';
import { AttuneBopQuoteUpdateComponent } from 'app/features/attune-bop/components/attune-bop-quote-update/attune-bop-quote-update.component';
import { RewardsService } from 'app/shared/services/rewards.service';
import { InformService } from 'app/core/services/inform.service';
import { SentryService } from 'app/core/services/sentry.service';
import { UWAlertService } from 'app/shared/services/uw-alert.service';
import { NaicsService } from 'app/shared/services/naics.service';
import { OnboardingService } from 'app/shared/services/onboarding.service';
import { FullstoryService } from 'app/core/services/fullstory.service';
import { AttuneBopExcessQuoteService } from 'app/features/attune-bop/services/attune-bop-excess-quote.service';
import { UsStateService } from 'app/shared/services/us-state.service';
import {
  BOP_CYBER_CROSS_SELL_CONFIGURATION,
  BOP_CYBER_UPSELL_ERROR_CONFIGURATION,
  CrossSellConfiguration,
  BOP_CYBER_UPSELL_SUCCESS_CONFIGURATION,
  BOP_CYBER_UPSELL_DECLINE_CONFIGURATION,
  QuoteWithEligibleStatusForUsellCard,
  DismissalRecords,
  CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY,
  BOP_CYBER_UPSELL_DRAFT_SUCCESS_CONFIGURATION,
  BOP_CYBER_UPSELL_EARLY_DECLINE_CONFIGURATION,
  BOP_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION,
  BOP_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION,
} from 'app/shared/product-cross-sell/configs';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
  JSON_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import {
  ADMITTED_KNOCK_OUT_STATES,
  BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL,
} from '../../../coalition/models/cyber-constants.model';
import {
  InsuredAccountSummaryService,
  FrontendQuoteWithLinks,
} from '../../../insured-account/services/insured-account-summary.service';
import { effectiveDateToPeriodDate } from 'app/features/attune-bop/models/date-helpers';
import {
  UpsellEarlyDeclineKey,
  UpsellEarlyDeclineReasons,
} from '../../../coalition/models/cyber-typings.model';
import { BundleQuotePrimaryProductName } from '../../../../shared/bundle-quote-review-modal/bundle-quote-review-modal.component';
import { CoalitionCyberDuplicateBrokerZendeskTicketService } from '../../../coalition/services/coalition-cyber-duplicate-broker-zendesk-ticket.service';
import { BundleService } from '../../../bundle/services/bundle.service';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import { ProducerDetailsResponse } from '../../../../bop/guidewire/typings';
import {
  NON_BINDING_ROLES,
  UNABLE_TO_BIND_MESSAGE,
  UserService,
} from '../../../../core/services/user.service';
import { DocumentService } from '../../../documents/services/document.service';
import {
  getQuoteLetterFileName,
  getQuoteSubmissionFileName,
} from '../../../documents/models/document-file-names.model';
import {
  getAttuneQuoteLetterUrl,
  getAttuneQuoteSubmissionUrl,
  getDcpQuoteLetterUrl,
} from '../../../documents/models/document-urls.model';
import { InsuranceProductCode } from '../../../digital-carrier/models/types';
import { PreferenceLevel, PreferenceService } from 'app/shared/services/preference.service';

export const QUOTE_CONFIRM_REMOVE_DELAY = 10000;
const BLOCK_EDIT_CODES = ['09541', '50901', '52114', '54516', '63741', '63751'];

@Component({
  selector:
    'app-attune-bop-quote-details-page.policy-pane.policy-pane__bop, app-attune-bop-quote-details-page',
  templateUrl: './attune-bop-quote-details-page.component.html',
  providers: [CurrencyPipe],
})
export class AttuneBopQuoteDetailsPageComponent
  extends AttuneBopQuoteUpdateComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  // These inputs are used on bop policy pane from route params and
  // also as inputs when used as a component of bundle quote detail
  @Input()
  isBundle = false;
  @Input()
  policyId: string;
  @Input()
  cyberId: string;

  isDevMode = false;
  model: Observable<QuoteDetails>;
  accountId: string;
  sub: Subscription = new Subscription();
  excessDetails$: Observable<QuoteDetails | null>;
  excessQuoteId: string;
  isExcessPolicyDeclined = false;
  locationVal: { [key: string]: string }[];
  showSubmissionDownload = environment.showSubmissionDownload;
  shouldDisplayRequoteButton: boolean;
  requoting = false;
  uwQuestionsHaveValidAnswers = false;
  accountDetails: InsuredAccount;
  nonBindingRole: boolean;
  tsRequestId: string;
  translatedQuote: BopQuotePayload;
  locationHasMultipleBuildingsOrClassifications: boolean;
  editNotAvailableMessageIsShowing: boolean;
  archivingConfirmationPanelShowing: boolean;
  excessTotalPremiumNotAvailable: boolean;
  isSampleAccount = false;
  hasQuoteNameError = false;
  public now = moment().startOf('day').add(12, 'hours');
  archiving = false;
  quoteNames: Record<string, string> = {};
  disableBindDueToLag = false;
  earliestQuoteUpdatedAt: moment.Moment;
  uwAlerts$: Observable<UWAlert[]>;
  isUWAlertModalShowing = false;
  guidelinesData: string[];
  isWaterSupplyVerified = true;
  isEmployeeCountVerified = true;
  showRolloverRequoteModal = false;
  locationsMissingInfo: DeepPartial<BopLocation[]> = [];
  locationInfoRequiredToBindFormArr: UntypedFormArray = this.formBuilder.array([]);
  disableRolloverRequote = false;
  rolloverRequoteSubmitted = false;
  hasManualExcessQuoteRequest = false;
  aigExcessBindCutoffDate = 'March 20, 2022';
  isAccountEligibleForCyber = false;
  isBrokerEnabledForCyber = false;

  // Bundle bind properties
  isBundleBindEnabled = false;
  showBundleBind = false;
  accountQuotes: FrontendQuoteWithLinks[] = [];
  doesAccountHaveCyberPolicy = false;
  // Cross-sell/Upsell properties
  isCrossSellWindowEnabled = false;
  showUpsellStatusCard = false;
  crossSellConfiguration = BOP_CYBER_CROSS_SELL_CONFIGURATION;
  doesAccountHaveCyberQuotes = false;
  upsellConfiguration: CrossSellConfiguration;
  secondaryQuoteStatus: 'success' | 'declined' | 'error' | null = null;
  secondaryQuoteId: string | null = null;
  // Bundle Quote properties
  showBundleQuoteReview = false;
  productForBundleQuoteReview: BundleQuotePrimaryProductName = 'BOP';
  productPremiumForBundleQuoteReview: number;
  secondaryQuote$ = new BehaviorSubject<FrontendQuoteWithLinks | null>(null);

  quoteLetterDownloadInProgress = false;
  quoteLetterDownload$: Observable<any>;
  quoteLetterDownloadReady$: Observable<any>;
  quoteSubmissionDownload$: Observable<any>;
  secondaryQuoteLetterDownload$: Observable<any>;

  quoteType = '';
  quoteTypeTip = '';
  isStronglyPreferredQuote = false;
  isPreferredQuote = false;
  isNonPreferredQuote = false;
  isBindBlockedProducer = false;
  isBindBlockedQuote = false;
  accountManager: AccountManager | undefined = undefined;

  constructor(
    private bindService: GWBindService,
    private cyberDuplicateBrokerZendeskTicketService: CoalitionCyberDuplicateBrokerZendeskTicketService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private informService: InformService,
    protected router: Router,
    protected amplitudeService: AmplitudeService,
    private featureFlagService: FeatureFlagService,
    protected fullstoryService: FullstoryService,
    protected bopQuoteService: AttuneBopQuoteService,
    protected insuredAccountService: InsuredAccountService,
    protected accountSummaryService: InsuredAccountSummaryService,
    private prefillService: PrefillService,
    protected requestQuoteService: AttuneBopQuoteRequestService,
    protected rewardsService: RewardsService,
    protected sentryService: SentryService,
    protected bundleService: BundleService,
    protected quoteService: DigitalCarrierQuoteService,
    protected onboardingService: OnboardingService,
    private currencyPipe: CurrencyPipe,
    private uwAlertService: UWAlertService,
    private naicsService: NaicsService,
    private formBuilder: UntypedFormBuilder,
    private excessQuoteService: AttuneBopExcessQuoteService,
    private usStateService: UsStateService,
    private userService: UserService,
    private preferenceService: PreferenceService
  ) {
    super(
      insuredAccountService,
      requestQuoteService,
      amplitudeService,
      fullstoryService,
      router,
      rewardsService,
      sentryService,
      bundleService,
      quoteService,
      onboardingService
    );
  }

  ngOnInit() {
    this.generateRequestId();
    this.model = this.route.params.pipe(
      switchMap((params) => {
        this.policyId = params['policyId'];
        this.requoting = false;
        this.disableBindDueToLag = false;
        this.loadTranslatedQuote();

        return combineLatest([
          this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.BOP_CLASS_PREFERENCE),
          this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ATTUNE_BOP_BIND_BLOCK),
          this.featureFlagService.getJsonFlagValue(JSON_FLAG_NAMES.BIND_BLOCK_AM_MAPPING),
        ]);
      }),
      switchMap(([showBopPreference, bopBindBlock, accountManagers]) => {
        this.setUserInfo(accountManagers as AccountManagerFlagResponse);
        this.isBindBlockedProducer = bopBindBlock || false;
        return showBopPreference
          ? this.preferenceService.getPreferenceByJobNumber('bop', this.policyId)
          : observableOf(null);
      }),
      switchMap((preferenceLevel) => {
        if (preferenceLevel?.preferenceLevel) {
          this.setQuoteType(preferenceLevel?.preferenceLevel);
        }
        return this.bindService.getQuoteDetails(this.policyId);
      }),
      shareReplay()
    );

    this.loadPreferenceInfo();

    this.excessDetails$ = this.model.pipe(
      switchMap((quoteDetails: QuoteDetails) => {
        this.shouldDisplayRequoteButton = quoteDetails.needsRequote;
        this.locationHasMultipleBuildingsOrClassifications =
          quoteDetails.locationHasMultipleBuildingsOrClassifications;

        if (quoteDetails.hasExcessPolicy && quoteDetails.linkedJobId) {
          return this.bindService.getQuoteDetails(quoteDetails.linkedJobId);
        } else {
          return observableOf(null);
        }
      }),
      map((excessDetails: QuoteDetails | null) => {
        if (excessDetails) {
          this.excessTotalPremiumNotAvailable = isNaN(excessDetails.totalPremium);
          this.excessQuoteId = excessDetails.id;
        }
        this.isExcessPolicyDeclined =
          (!!excessDetails && excessDetails.hasExcessPolicyDeclined) ||
          this.excessTotalPremiumNotAvailable;
        return this.isExcessPolicyDeclined ? null : excessDetails;
      }),
      shareReplay()
    );

    this.accountId = this.route.snapshot.params.accountId;

    this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.BOP_CYBER_CROSS_SELL_WINDOW)
      .subscribe((value) => {
        this.isCrossSellWindowEnabled = value || false;
      });
    this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.BOP_CYBER_BUNDLE_BIND)
      .subscribe((value) => {
        this.isBundleBindEnabled = value || false;
      });

    this.naicsService.getProductAvailability().subscribe((resp) => {
      this.isBrokerEnabledForCyber = resp.some((eligibility) => {
        return eligibility.pasSource === 'coalition' && eligibility.classCodeSelection === 'ALL';
      });
    });

    // Depends on excessDetails because of excessQuoteId;
    this.excessDetails$.subscribe((quote) => {
      const id = this.excessQuoteId ? this.policyId + '-' + this.excessQuoteId : this.policyId;
      const product = this.excessQuoteId ? 'bop-with-excess' : 'bop';
      const url = getAttuneQuoteLetterUrl(id);
      const filename = getQuoteLetterFileName(id, product as InsuranceProductCode);
      this.quoteLetterDownload$ = this.documentService.getDocument(url, filename, 'pdf');
      this.quoteLetterDownloadReady$ = this.documentService.pollDocument(url);
    });

    this.model.subscribe((quote) => {
      const url = getAttuneQuoteSubmissionUrl(quote.id, this.accountId, 'bop');
      const fileName = getQuoteSubmissionFileName(quote.id, quote.product as InsuranceProductCode);
      this.quoteSubmissionDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
    });

    this.model.subscribe((loc) => {
      this.locationVal = _.sortBy(loc.locations, 'locationNum');
    });

    this.sub.add(
      this.bopQuoteService
        .getNames(this.accountId)
        .subscribe((response: QSQuoteNameListResponse) => {
          this.quoteNames = response.quoteNames;
        })
    );

    this.uwAlerts$ = this.route.params.pipe(
      switchMap((params) => {
        return this.uwAlertService.fetchAlerts(params.accountId, this.policyId || params.policyId);
      }),
      shareReplay(1)
    ) as Observable<UWAlert[]>;

    this.sub.add(
      this.route.queryParams
        .pipe(
          // Note: Show uw alerts if we just submitted the quote
          switchMap((params) => {
            if (params.isNewQuote) {
              this.model.subscribe((quote) => {
                if ((<any>window).Sprig) {
                  (<any>window).Sprig('setAttributes', {
                    id: quote.id,
                    totalPremium: quote.totalPremium,
                    baseState: quote.baseState,
                  });
                  (<any>window).Sprig('track', 'gets-new-BOP-quote');
                }
              });
              return this.uwAlerts$;
            }
            return NEVER_OBSERVABLE;
          })
        )
        .subscribe((uwAlerts) => {
          if (uwAlerts && uwAlerts.length) {
            this.setUWAlertModalOpen(true);
          }
        })
    );

    const insuredAccount$ = this.insuredAccountService.get(this.accountId);
    insuredAccount$.subscribe((account) => {
      if (account.naicsCode) {
        this.naicsService
          .getProductEligibility(account.naicsCode.hash, account.state)
          .subscribe((eligibility) => {
            const isAccountInEligibleState = !ADMITTED_KNOCK_OUT_STATES.includes(
              this.accountDetails.state
            );
            this.isAccountEligibleForCyber =
              eligibility.isCyberEligible && isAccountInEligibleState;
          });
      }
      this.accountDetails = account;
      this.isSampleAccount = account.sampleAccount || false;
      this.fetchGuidelinesForUWAlert(account);
    });

    // Check if this BOP quote has an associated Manual Excess (core specialty) quote request
    this.sub.add(
      this.excessQuoteService.getManualExcessZendeskId(this.policyId).subscribe((res) => {
        if (res && res.hasManualExcessRequest) {
          this.hasManualExcessQuoteRequest = true;
        }
      })
    );

    this.setSecondaryQuoteDependencies();
  }

  setSecondaryQuoteDependencies() {
    // Set secondary quote used for upsell status card & bundle quote review
    this.sub.add(
      combineLatest(
        this.accountSummaryService.getSummary(this.accountId),
        this.route.queryParams
      ).subscribe(([accountSummary, queryParams]) => {
        if (accountSummary?.status !== 'success') {
          return;
        }
        this.doesAccountHaveCyberQuotes = accountSummary.quotes.some((quote) => {
          return (
            quote.pasSource === 'coalition' &&
            quote.product === 'cyber_admitted' &&
            quote.status === 'quoted'
          );
        });
        this.doesAccountHaveCyberPolicy = accountSummary.policyTerms.some((policyTerm) => {
          return policyTerm.pasSource === 'coalition';
        });

        const secondaryQuote =
          accountSummary.quotes.find(({ uuid }) => uuid === queryParams.secondaryQuoteId) || null;
        this.secondaryQuoteId = secondaryQuote?.uuid || null;
        const url = getDcpQuoteLetterUrl(this.secondaryQuoteId || '');
        const fileName = getQuoteLetterFileName(this.secondaryQuoteId || '', 'cyber_admitted');
        this.secondaryQuoteLetterDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
        this.secondaryQuote$.next(secondaryQuote);
      })
    );

    const bundleQuoteReviewIsEnabled$ = this.featureFlagService.isEnabled(
      BOOLEAN_FLAG_NAMES.BOP_CYBER_BUNDLE_QUOTE_REVIEW
    );
    // Set properties needed for upsell status card
    this.sub.add(
      combineLatest(
        this.secondaryQuote$,
        bundleQuoteReviewIsEnabled$,
        this.route.queryParams
      ).subscribe(([secondaryQuote, bundleQuoteReviewIsEnabled, queryParams]) => {
        if (bundleQuoteReviewIsEnabled && secondaryQuote?.status === 'quoted') {
          // We will show the bundle quote review modal instead of the status card
          this.showUpsellStatusCard = false;
        } else if (queryParams.secondaryQuoteEarlyDeclineReasons) {
          // Show status card for early declines
          this.setEarlyDeclineUpsellConfig(queryParams.secondaryQuoteEarlyDeclineReasons);
          this.showUpsellStatusCard = true;
        } else if (queryParams.secondaryQuoteBrokerOfRecordError) {
          this.secondaryQuoteStatus = 'error';
          this.upsellConfiguration = BOP_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION;
          this.showUpsellStatusCard = true;
        } else if (queryParams.secondaryQuoteDuplicateBrokerError) {
          this.secondaryQuoteStatus = 'error';
          this.upsellConfiguration = BOP_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION;
          this.showUpsellStatusCard = true;
        } else if (
          secondaryQuote &&
          this.secondaryQuoteHasEligibleStatusForUpsell(secondaryQuote)
        ) {
          // Show status card for issued or errored quotes
          this.setUpsellConfig(secondaryQuote);
          this.showUpsellStatusCard = true;
        } else {
          // Do not show status card
          this.showUpsellStatusCard = false;
        }
      })
    );

    // Set properties needed for bundle quote review
    this.sub.add(
      combineLatest(
        this.model,
        this.excessDetails$,
        bundleQuoteReviewIsEnabled$,
        this.secondaryQuote$
      ).subscribe(
        ([bopQuoteDetails, excessDetails, bundleQuoteReviewIsEnabled, secondaryQuote]) => {
          if (
            bopQuoteDetails?.status !== 'Quoted' ||
            (excessDetails && excessDetails.status !== 'Quoted')
          ) {
            this.showBundleQuoteReview = false;
            return EMPTY;
          }
          if (excessDetails) {
            this.productForBundleQuoteReview = 'BOP and XS';
            this.productPremiumForBundleQuoteReview =
              excessDetails.totalCost + bopQuoteDetails.totalCost;
          } else {
            this.productForBundleQuoteReview = 'BOP';
            this.productPremiumForBundleQuoteReview = bopQuoteDetails.totalCost;
          }
          if (secondaryQuote?.status === 'quoted' && bundleQuoteReviewIsEnabled) {
            this.showBundleQuoteReview = true;
          } else {
            this.showBundleQuoteReview = false;
          }
        }
      )
    );
  }

  // Checks for policies on class codes that are not eligible for new
  // BOP policies but can be rolled over to BOP+ policies ("grandfathered" in)
  isGrandfathered() {
    if (this.locationVal) {
      return this.locationVal.some((loc) => BLOCK_EDIT_CODES.includes(loc.classCode));
    }
    return true;
  }

  showBopPlusAutobindWarning(quoteInfo: QuoteDetails) {
    if (!this.accountDetails) {
      return false;
    }

    const oldBopQuotes = this.accountDetails.bopQuotes.filter((quote) =>
      isBopV1(quote.uwCompanyCode)
    );
    return (
      !!oldBopQuotes.length &&
      quoteInfo.status === 'Quoted' &&
      this.isBopV2Quote(quoteInfo) &&
      !this.accountDetails.bopPlusPolicies.length &&
      !this.accountDetails.bopQuotes.some((quote) => quote.AutoBindRollOver_ATTN)
    );
  }

  requiresAdditionalInfo(quote: QuoteDetails) {
    return this.locationsMissingInfo.length > 0;
  }

  fetchGuidelinesForUWAlert(insuredAccount: InsuredAccount) {
    this.sub.add(
      this.uwAlerts$
        .pipe(
          switchMap((uwAlerts) => {
            if (uwAlerts && uwAlerts.length && insuredAccount.naicsCode) {
              return this.naicsService.getGuidelines(
                insuredAccount.naicsCode.hash,
                insuredAccount.state
              );
            }
            return observableOf(null);
          })
        )
        .subscribe(
          (guidelinesData: AskKodiakProductData | null) => {
            if (guidelinesData) {
              this.guidelinesData = guidelinesData.guidelines;
            }
          },
          (err) => {
            this.sentryService.notify('Error fetching NAICs guidelines for UW Alert', {
              severity: 'error',
              metaData: {
                underlyingError: err,
                underlyingErrorMessage: err && err.message,
                affectedAccount: insuredAccount.id,
                naicsCode: insuredAccount.naicsCode,
              },
            });
          }
        )
    );
  }

  setUWAlertModalOpen(isOpen: boolean) {
    this.isUWAlertModalShowing = isOpen;
  }

  setQuoteNameError(hasError: boolean) {
    this.hasQuoteNameError = hasError;
  }

  updateQuoteName(quoteNumber: string, newName: string) {
    // Preemptively set the new name, so that there's immediate visual feedback
    this.quoteNames[quoteNumber] = newName;
  }

  showAchivingConfirmationPanel() {
    this.archivingConfirmationPanelShowing = true;
    this.sub.add(
      timer(QUOTE_CONFIRM_REMOVE_DELAY)
        .pipe(first())
        .subscribe(() => {
          this.archivingConfirmationPanelShowing = false;
        })
    );
  }

  showEditUnavailableMessage() {
    this.editNotAvailableMessageIsShowing = true;
  }

  get locationValues() {
    return this.locationVal;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  requote() {
    if (this.requoting) {
      return;
    }

    this.requoting = true;
    this.sub.add(
      this.model
        .pipe(
          take(1),
          switchMap((quoteDetails) => {
            return forkJoin([
              this.bopQuoteService.getTranslatedQuoteV2(this.policyId),
              quoteDetails.linkedJobId
                ? this.excessQuoteService.getTranslatedQuote(quoteDetails.linkedJobId)
                : observableOf(null),
            ]);
          })
        )
        .subscribe(
          ([bopResponse, excessResponse]: [BopQuotePayload, any]) => {
            if (!bopResponse) {
              this.requoting = false;
              return;
            }
            this.translatedQuote = bopResponse;
            if (excessResponse && excessResponse.translatedQuote) {
              this.translatedQuote.excessLiability = excessResponse.translatedQuote;
              this.translatedQuote.excessLiabilityOptIn = !!excessResponse.translatedQuote;
            }
            const bopVersion = bopResponse.policyInfo.bopVersion;
            const naicsHash = this.accountDetails.naicsCode
              ? this.accountDetails.naicsCode.hash
              : '';
            combineLatest(
              bopResponse.locations.map((location: BopLocation) => {
                return this.prefillService.fetch(
                  location.locationDetails,
                  this.accountDetails.companyName,
                  this.tsRequestId,
                  this.accountId,
                  'AGENT_PORTAL',
                  bopVersion,
                  this.accountDetails.doingBusinessAs,
                  this.accountDetails.website,
                  this.accountDetails.phoneNumber,
                  naicsHash
                );
              })
            ).subscribe(([...resp]) => {
              resp.forEach((prefillData: PrefillData, i: number) => {
                this.translatedQuote.locations[i].locationPrefill =
                  this.prefillService.getPrefill(prefillData).locationPrefill;
              });
              this.submitRequotingDetails(this.translatedQuote, this.policyId);
            });
          },
          (err) => {
            // This err is already logged to Sentry in bopQuoteService.getTranslatedQuotev2().
            this.requoting = false;
          }
        )
    );
  }

  handleQuoteResultModalClose(result: { close: boolean; retry: boolean }) {
    super.handleQuoteResultModalClose(result, this.accountId);
  }

  submitRequotingDetails(
    translatedQuote: BopQuotePayload,
    parentQuoteId: string,
    isRolloverQuote?: boolean,
    isPriceEstimateQuote?: boolean
  ) {
    let effectiveDate: Date;
    this.model.pipe(take(1)).subscribe((fetchedQuote) => {
      if (fetchedQuote.policyStart < moment()) {
        effectiveDate = effectiveDateToPeriodDate(moment().add(1, 'days').format(US_DATE_MASK));
      } else {
        effectiveDate = effectiveDateToPeriodDate(
          moment.utc(fetchedQuote.policyStart).format(US_DATE_MASK)
        );
      }
      const policy = new BopPolicy(translatedQuote, effectiveDate, this.tsRequestId);

      if (isRolloverQuote) {
        this.amplitudeService.track({
          eventName: 'requote_rollover_attempt',
          detail: 'bop',
        });
      } else if (isPriceEstimateQuote) {
        this.amplitudeService.trackWithOverride({
          eventName: 'requote_estimate_attempt',
          detail: 'bop',
          payloadOverride: {
            requoteTsRequestId: this.tsRequestId,
          },
        });
        // refresh tsRequestId for subsequent requotes
        this.generateRequestId();
      } else {
        this.amplitudeService.track({
          eventName: 'requote_attempt',
          detail: 'bop',
          useLegacyEventName: true,
        });
      }

      combineLatest(
        this.requestQuoteService.requestV3Quote({
          accountId: this.accountId,
          parentQuoteId,
          policy,
          excessUwQuestionsHaveValidAnswers: true,
          quoteGameEnabled: true,
        }),
        this.cyberId
          ? this.quoteService.cloneCoalitionCyber(this.cyberId, this.accountDetails)
          : observableOf(null)
      ).subscribe(([bopResp, cyberResponse]: [any, any]) => {
        const config: Record<string, any> = { isRolloverQuote, parentQuoteId };
        if (cyberResponse) {
          if (Array.isArray(cyberResponse)) {
            config.secondaryQuoteEarlyDeclineReasons = cyberResponse;
          } else {
            config.secondaryQuoteResponse = cyberResponse;
          }
        }
        super.updateQuote(bopResp, this.accountId, config);
      });
    });
  }

  handlePriceEstimateSubmit(patchedQuote: BopQuotePayload) {
    this.submitRequotingDetails(patchedQuote, this.policyId, false, true);
  }

  generateRequestId() {
    this.tsRequestId = uuidv4();
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
  }

  archiveQuote() {
    this.archiving = true;
    this.insuredAccountService.archive(this.policyId).subscribe(() => {
      this.informService.infoToast('Quote archived');
      this.insuredAccountService.cachebust();
      this.amplitudeService.track({
        eventName: 'bop_archive_quote',
        detail: this.policyId,
        useLegacyEventName: true,
      });
      this.router.navigate(['/accounts', this.accountId]);
    });
  }

  continueToBind() {
    if (this.nonBindingRole) {
      this.informService.infoToast(UNABLE_TO_BIND_MESSAGE);
      return;
    }
    if (this.isSampleAccount) {
      this.informService.infoToast("We'll show you binding once you have made a real quote.");
      return;
    }

    if (
      this.isBundleBindEnabled &&
      this.doesAccountHaveCyberQuotes &&
      !this.doesAccountHaveCyberPolicy
    ) {
      this.showBundleBind = true;
      return;
    }
    this.router.navigate(['bind'], { relativeTo: this.route });
  }

  closeBundleBind() {
    this.showBundleBind = false;
  }

  formatCost(...loadedModels: QuoteDetails[]) {
    const isExpired = loadedModels.some((quote) => {
      // There would be multiple loadedModels if the quote includes both BOP and XS. If the BOP quote is expired, the XS quote is also expired
      return quote.status === 'Expired';
    });
    if (isExpired) {
      return 'N/A';
    }
    const totalCost = loadedModels.reduce((accumulatedCost, model) => {
      return accumulatedCost + model.totalCost;
    }, 0);
    return this.currencyPipe.transform(totalCost, 'USD', 'symbol', '1.2-2');
  }

  isBopV2Quote(quote: QuoteDetails) {
    return isBopV2(quote.uwCompanyCode);
  }

  canEditBOPQuote(quote: QuoteDetails) {
    if (this.isSampleAccount) {
      return false;
    }
    // Disable edits of bopv1 quotes once a state has transitioned to Accredited.
    if (this.isBopV1QuoteInBopV2State(quote)) {
      return false;
    }
    return true;
  }

  isBopV1QuoteInBopV2State(quote: QuoteDetails) {
    // Returns true for a Blackboard BOP quote after a state has transitioned to Accredited.
    return (
      isBopV1(quote.uwCompanyCode) &&
      (shouldGetBopV2(quote.baseState) || shouldGetMeToo(quote.baseState))
    );
  }

  isTechMoratorium(quote: QuoteDetails) {
    return (
      quote.uwIssues.length > 0 &&
      quote.uwIssues.find((n) => n.includes('Unable to quote. There is a CAT moratorium in place'))
    );
  }

  openRolloverRequoteModal() {
    this.showRolloverRequoteModal = true;
  }

  closeRolloverRequoteModal() {
    this.showRolloverRequoteModal = false;
  }

  requoteRollover() {
    this.rolloverRequoteSubmitted = true;

    if (this.locationInfoRequiredToBindFormArr.invalid) {
      return;
    }

    this.model
      .pipe(
        take(1),
        switchMap((quoteDetails) => {
          return quoteDetails.linkedJobId
            ? this.excessQuoteService.getTranslatedQuote(quoteDetails.linkedJobId)
            : observableOf(null);
        })
      )
      .subscribe(
        (excessResponse) => {
          if (excessResponse && excessResponse.translatedQuote) {
            this.translatedQuote.excessLiability = excessResponse.translatedQuote;
            this.translatedQuote.excessLiabilityOptIn = !!excessResponse.translatedQuote;
          }
          this.locationsMissingInfo.forEach(
            (loc: BopLocation & { originalLocationIndex: number }, index: number) => {
              const missingInfoFormGroup = this.locationInfoRequiredToBindFormArr.at(
                index
              ) as UntypedFormGroup;
              const numberOfEmployees = getControl(missingInfoFormGroup, 'numberOfEmployees');
              const isWithinCreditableWaterSupplyForPPC = getControl(
                missingInfoFormGroup,
                'isWithinCreditableWaterSupplyForPPC'
              );

              let translatedLocation = this.translatedQuote.locations[loc.originalLocationIndex];

              translatedLocation = {
                ...translatedLocation,
                locationDetails: {
                  ...translatedLocation.locationDetails,
                  employeeCount: numberOfEmployees.enabled
                    ? numberOfEmployees.value
                    : translatedLocation.locationDetails.employeeCount,
                  isEmployeeCountVerified: true,
                  isWithinCreditableWaterSupplyForPPC: isWithinCreditableWaterSupplyForPPC.enabled
                    ? isWithinCreditableWaterSupplyForPPC.value
                    : translatedLocation.locationDetails.isWithinCreditableWaterSupplyForPPC,
                  isWaterSupplyVerified: true,
                },
              };
              this.translatedQuote.locations[loc.originalLocationIndex] = translatedLocation;
            }
          );

          this.closeRolloverRequoteModal();
          this.submitRequotingDetails(this.translatedQuote, this.policyId, true);
        },
        (err) => {
          // error already logged to Sentry
          this.informService.errorToast(
            'An error occurred when requoting. Please retry or contact Please contact our Customer Care Team.'
          );
        }
      );
  }

  getFullStateName(stateAbbreviation: string) {
    return this.usStateService.getUsFullStateNameByAbbreviation(stateAbbreviation);
  }

  getBindFormQueryParams(quote: QuoteDetails) {
    return {
      effectiveDate: quote.policyStart.format(US_DATE_MASK),
      accountNumber: this.accountId,
      quoteNumber: quote.id,
    };
  }

  // Set to true in the case where we are overriding eligibility to always be true
  // (for the sake of gathering data even about illegible class codes)
  isEligibilityOverride() {
    return !!localStorage.getItem(`${BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`);
  }

  isEligibilityClassOverride() {
    return !!localStorage.getItem(`${BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`);
  }

  shouldRenderCrossSellWindow(quote: QuoteDetails) {
    // Only render the window for quoted and scheduled quotes & if the cross sell was not already dismissed on that day for the current account
    if (!['Quoted', 'Scheduled'].includes(quote.status) || this.isSampleAccount) {
      return false;
    }

    const accountIds: DismissalRecords | string[] = JSON.parse(
      localStorage.getItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY) || '{}'
    );

    // Ignore the defunct localstorage record format (array) when checking whether to render
    if (
      !_.isArray(accountIds) &&
      Object.prototype.hasOwnProperty.call(accountIds, this.accountId)
    ) {
      const { lastDismissedAt, doNotShow } = accountIds[this.accountId];

      const now = moment();

      if (doNotShow || now.isSameOrBefore(lastDismissedAt, 'date')) {
        return false;
      }
    }

    return (
      !this.secondaryQuoteId &&
      this.isCrossSellWindowEnabled &&
      this.isAccountEligibleForCyber &&
      this.isBrokerEnabledForCyber &&
      !this.doesAccountHaveCyberQuotes
    );
  }

  secondaryQuoteHasEligibleStatusForUpsell(
    quote: FrontendQuoteWithLinks
  ): quote is QuoteWithEligibleStatusForUsellCard {
    return ['draft', 'quoted', 'under_review', 'unavailable', 'referred', 'declined'].includes(
      quote.status
    );
  }

  handleProductCrossSellWindowEvent($event: { close: boolean; submit: boolean }) {
    if ($event.close) {
      const rawStoredAccountIds = localStorage.getItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY);
      const storedAccountIds: DismissalRecords | string[] | null = rawStoredAccountIds
        ? JSON.parse(rawStoredAccountIds)
        : null;

      let accountIds: DismissalRecords;
      if (!storedAccountIds) {
        accountIds = {};
      } else if (_.isArray(storedAccountIds)) {
        // This localstorage record was previously an array; handle the case where the user has the old format stored.
        const yesterday = moment().subtract(1, 'day').format(US_DATE_MASK);
        accountIds = _.reduce(
          storedAccountIds,
          (prev: DismissalRecords, accountId) => {
            prev[accountId] = {
              lastDismissedAt: yesterday,
              doNotShow: false,
            };
            return prev;
          },
          {}
        );
      } else {
        accountIds = storedAccountIds;
      }

      const today = moment().format(US_DATE_MASK);

      if (Object.prototype.hasOwnProperty.call(accountIds, this.accountId)) {
        // if it already existed then this is the secind time it is being dismissed
        accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: true };
      } else {
        accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: false };
      }

      localStorage.setItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY, JSON.stringify(accountIds));

      this.amplitudeService.track({
        eventName: 'bop_close_cyber_cross_sell_window',
        detail: this.accountId,
      });
    }
    if ($event.submit) {
      this.amplitudeService.track({
        eventName: 'bop_new_quote_via_cyber_cross_sell_window',
        detail: this.accountId,
      });
      this.router.navigate([`/accounts/${this.accountId}/cyber-admitted/quick`], {
        queryParams: { bopId: this.policyId },
        replaceUrl: true,
      });
    }
  }

  handleProductUpsellWindowEvent($event: { close: boolean; submit: boolean }) {
    if ($event.close) {
      this.amplitudeService.track({
        eventName: 'coalition_cyber_upsell_card_closed',
        detail: `quote_status_${this.secondaryQuoteStatus}`,
      });
      this.showUpsellStatusCard = false;
    }
    if ($event.submit) {
      if (this.upsellConfiguration === BOP_CYBER_UPSELL_DRAFT_SUCCESS_CONFIGURATION) {
        this.router.navigate([
          'accounts',
          this.accountId,
          'cyber-admitted',
          this.secondaryQuoteId,
          'edit',
        ]);
        return;
      }
      if (this.upsellConfiguration === BOP_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION) {
        this.router.navigate([BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL]);
        return;
      } else if (
        this.upsellConfiguration === BOP_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION
      ) {
        this.cyberDuplicateBrokerZendeskTicketService
          .createTicket('cyber_admitted', this.accountId)
          .subscribe((_createTicketResponse) => {
            this.showUpsellStatusCard = false;
          });
        return;
      }
      this.amplitudeService.track({
        eventName: 'coalition_cyber_upsell_card_navigated_to_quote',
        detail: `quote_status_${this.secondaryQuoteStatus}`,
      });
      this.secondaryQuote$.subscribe((secondaryQuote) => {
        if (!secondaryQuote) {
          // This case should be impossible. As a fallback, navigate to the accounts page, where
          // the quote should be visible on the corresponding product card.
          this.router.navigate(['accounts', this.accountId]);
        } else {
          this.router.navigate([secondaryQuote.routerLink]);
        }
      });
    }
  }

  handleBundleQuoteReviewModalClose() {
    this.showBundleQuoteReview = false;
  }

  setEarlyDeclineUpsellConfig(
    secondaryQuoteEarlyDeclineReasons: UpsellEarlyDeclineKey | UpsellEarlyDeclineKey[]
  ) {
    this.secondaryQuoteStatus = 'declined';
    let declineReasons: UpsellEarlyDeclineKey[];
    if (Array.isArray(secondaryQuoteEarlyDeclineReasons)) {
      declineReasons = secondaryQuoteEarlyDeclineReasons;
    } else {
      declineReasons = [secondaryQuoteEarlyDeclineReasons];
    }
    const declineReasonListItems = declineReasons
      .filter((declineReason) => UpsellEarlyDeclineReasons[declineReason])
      .map((declineReason) => {
        return `<li>${UpsellEarlyDeclineReasons[declineReason]}</li>`;
      });
    const dialogBody = declineReasonListItems.length
      ? `<ul class="bulleted-list">${declineReasonListItems.join('')}</ul>`
      : 'Decline reasons unknown';
    this.upsellConfiguration = {
      ...BOP_CYBER_UPSELL_EARLY_DECLINE_CONFIGURATION,
      dialogBody,
    };
    return;
  }

  setUpsellConfig(secondaryQuote: QuoteWithEligibleStatusForUsellCard) {
    switch (secondaryQuote.status) {
      case 'quoted':
      case 'under_review':
      case 'referred':
        this.secondaryQuoteStatus = 'success';
        this.upsellConfiguration = BOP_CYBER_UPSELL_SUCCESS_CONFIGURATION;
        break;
      case 'draft':
        this.secondaryQuoteStatus = 'success';
        this.upsellConfiguration = BOP_CYBER_UPSELL_DRAFT_SUCCESS_CONFIGURATION;
        break;
      case 'declined':
        this.secondaryQuoteStatus = 'declined';
        const declineReasons = secondaryQuote.declineReasons || [];
        const declineReasonListItems = declineReasons.map((declineReason) => {
          return `<li>${declineReason}</li>`;
        });
        const dialogBody = declineReasonListItems.length
          ? `<ul class="bulleted-list">${declineReasonListItems.join('')}</ul>`
          : 'Decline reasons unknown';
        this.upsellConfiguration = {
          ...BOP_CYBER_UPSELL_DECLINE_CONFIGURATION,
          dialogBody,
        };
        break;
      case 'unavailable':
        this.secondaryQuoteStatus = 'error';
        this.upsellConfiguration = BOP_CYBER_UPSELL_ERROR_CONFIGURATION;
        break;
      default:
        // Other quote statuses (bound, bound with subjectivity, not taken, expired) are not results of a quote
        break;
    }
  }

  private setQuoteType(quotePreferenceLevel: PreferenceLevel) {
    if (STRONGLY_PREFERRED_PREFERENCE_LEVELS.includes(quotePreferenceLevel)) {
      this.quoteType = `Strongly Preferred Quote`;
      this.quoteTypeTip = 'This business is accepted and/or targeted.';
      this.isStronglyPreferredQuote = true;
    } else if (PREFERRED_PREFERENCE_LEVELS.includes(quotePreferenceLevel)) {
      this.quoteType = 'Preferred Quote';
      this.quoteTypeTip = 'This business is accepted and/or targeted.';
      this.isPreferredQuote = true;
    } else if (NON_PREFERRED_PREFERENCE_LEVELS.includes(quotePreferenceLevel)) {
      this.quoteType = 'Non-preferred Quote';
      this.quoteTypeTip = 'This business is accepted on a limited basis.';
      this.isNonPreferredQuote = true;
    }
  }

  private setUserInfo(accountManagersResponse: AccountManagerFlagResponse) {
    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          this.accountManager = getAccountManager(response, accountManagersResponse);
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );
  }

  private loadPreferenceInfo() {
    this.sub.add(
      // We pre-load preference information based on the route policyId, but we can't determine bind block until we have the quote details
      this.model.subscribe((quoteDetails) => {
        if (this.isNonPreferredQuote && this.isBindBlockedProducer && quoteDetails.bindable) {
          this.isBindBlockedQuote = true;
        }
      })
    );
  }

  private loadTranslatedQuote() {
    this.sub.add(
      this.bopQuoteService.getTranslatedQuoteV2(this.policyId).subscribe((res: BopQuotePayload) => {
        if (res && res.locations) {
          this.translatedQuote = res;
          // AIs that are missing an address/contact are not supported, quotes with this type of AI will stilll require CCC intervention.
          this.disableRolloverRequote = res.additionalCoverages.additionalInsuredBusinesses.some(
            (ai) => {
              return !ai.address?.addressLine1;
            }
          );
          this.locationsMissingInfo = res.locations
            .map((loc: BopLocation, index: number) => {
              return { ...loc, originalLocationIndex: index };
            })
            .filter((loc: BopLocation) => {
              return (
                loc.locationDetails.isEmployeeCountVerified === false ||
                loc.locationDetails.isWaterSupplyVerified === false
              );
            });
          this.locationsMissingInfo.forEach((loc: BopLocation) => {
            const missingInfoFormGroup = this.formBuilder.group({
              numberOfEmployees: [
                { value: null, disabled: true },
                [Validators.required, rangeValidator(1)],
              ],
              isWithinCreditableWaterSupplyForPPC: [
                { value: null, disabled: true },
                Validators.required,
              ],
            });

            if (loc.locationDetails.isEmployeeCountVerified === false) {
              this.isEmployeeCountVerified = false;
              enableDisableControl(getControl(missingInfoFormGroup, 'numberOfEmployees'), true);
            }
            if (loc.locationDetails.isWaterSupplyVerified === false) {
              this.isWaterSupplyVerified = false;
              enableDisableControl(
                getControl(missingInfoFormGroup, 'isWithinCreditableWaterSupplyForPPC'),
                true
              );
            }
            this.locationInfoRequiredToBindFormArr.push(missingInfoFormGroup);
          });
        }
      })
    );
  }
}
