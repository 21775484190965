<section>
  <h1 class="form-header-below-link">Loss runs</h1>

  <p>Please upload at least three years of loss runs, from the current day.</p>
  <ng-container *ngFor="let lossRun of form.controls; index as lossRunIndex">
    <div class="form-group multi-form-item">
      <app-loss-runs-upload
        inputId="hab-loss-runs-upload-{{ lossRunIndex }}"
        cssClass="form-field__no-margin"
        [quoteId]="quoteId"
        [account]="account"
        [user]="user"
        [form]="lossRun"
        [submitted]="hasSubmitted()"
      >
      </app-loss-runs-upload>

      <div class="nav-button-group">
        <a
          class="button button__small button__transparent"
          (click)="removeLossRun(lossRunIndex)"
          (keyup.enter)="removeLossRun(lossRunIndex)"
        >
          Remove
        </a>
      </div>

      <!-- Used by Cypress tests.
        Updating the file name text field is normally done by selecting a file from the browser
        prompt, but that functinality isn't testable in Cypress currently. As a result, we need
        Cypress to manually fill in these inputs to proceed.

        Context: https://github.com/cypress-io/cypress/issues/170 -->
      <app-form-field-text
        aria-hidden="true"
        hidden="true"
        inputId="hab-loss-runs-upload-{{ lossRunIndex }}-CYPRESS"
        [form]="lossRun"
        nameOfFormControl="fileName"
      >
      </app-form-field-text>
    </div>
  </ng-container>

  <button
    class="button button__secondary button__full-width js-hab-add-location button__no-margin"
    type="button"
    (click)="addLossRun()"
  >
    Add loss run
  </button>

  <hr />
</section>
