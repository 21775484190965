import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  AfterViewChecked,
} from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';

@Component({
  selector: 'app-activity-rollover-states-chart',
  templateUrl: 'activity-rollover-states-chart.component.html',
})
export class ActivityRolloverStatesChartComponent implements AfterViewInit, AfterViewChecked {
  @Input() stateColors: Record<string, string>;
  @Input() stateTotalCounts: Record<string, number>;
  @Input() stateRolloverCounts: Record<string, number>;
  @Input() alternativeText: string;

  tooltipText = '';
  tooltipLeft = 0;
  tooltipTop = 0;

  @ViewChild('statesChart')
  statesChart: ElementRef<HTMLCanvasElement>;

  private hasLoggedRenderError = false;

  constructor(private sentryService: SentryService) {}

  ngAfterViewInit() {
    this.renderStatesChart();
  }

  ngAfterViewChecked() {
    this.renderStatesChart();
  }

  // Bad "any" typing is needed, because TypeScript does not have a specific mouseover event type
  updateTooltip(event: any) {
    if (!this.stateTotalCounts || !this.stateRolloverCounts) {
      return;
    }

    // mouseover events have different shapes across browsers, so we need to check both
    const targetElement = (event.toElement || event.relatedTarget) as Element;
    if (targetElement && targetElement.tagName.toLowerCase() === 'path') {
      this.tooltipLeft = event.x;
      this.tooltipTop = event.y;

      // Extract state name from state-chart-[state] form
      const state = targetElement.id.split('-')[2];
      const total = this.stateTotalCounts[state] || 0;
      const rolledOver = this.stateRolloverCounts[state] || 0;
      this.tooltipText = `${state} policies: ${total}. Rolled Over: ${rolledOver}`;
    } else {
      this.hideTooltip();
    }
  }

  hideTooltip() {
    this.tooltipText = '';
  }

  renderStatesChart() {
    Object.entries(this.stateColors).forEach(([state, color]) => {
      const stateElement = this.statesChart.nativeElement.querySelector(`#state-chart-${state}`);
      if (!stateElement) {
        if (!this.hasLoggedRenderError) {
          this.hasLoggedRenderError = true;
          this.sentryService.notify(
            `Found problem rendering state chart visualization: could not find element for state.`,
            {
              metaData: {
                state,
              },
            }
          );
        }
        return;
      }
      stateElement.setAttribute('style', `fill: ${color}`);
    });
  }
}
