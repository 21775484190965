import { fromPairs } from 'lodash';

/**
 * This helper function can be used to a dictionary by its values,
 *
 * Example: sorting PL_BUSINESS_CLASSES_V4 or GL_BUSINESS_CLASSES_V4 for Hiscox
 *
 * @param dictionaryToSort dictionsary data structure
 */
export const sortDictionaryByValues = (dictionaryToSort: { [key: string]: string }) => {
  const sorted = Object.entries(dictionaryToSort).sort((currentElement, nextElement) => {
    // Sort based on the value of each element, e.g. currentElement = [key, value]
    return currentElement[1] < nextElement[1] ? -1 : 1;
  });

  return fromPairs(sorted);
};
