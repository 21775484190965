import { Injectable } from '@angular/core';
import { SentryService } from './sentry.service';

type BooleanString = 'true' | 'false';

@Injectable()
export class LocalStorageService {
  constructor(private sentryService: SentryService) {}

  // Unfortunately, we cannot get more specific than `any` with return types from JSON parse
  // any | null is somewhat redundant but included for readability
  getJson(key: string): any | null {
    const value = this.getItem(key);
    if (value !== null) {
      try {
        return JSON.parse(value);
      } catch (e) {
        this.sentryService.notify('Error reading JSON from local storage', {
          severity: 'error',
          metaData: {
            fieldName: key,
            errorMessage: e.message,
          },
        });
        return null;
      }
    }
    return null;
  }

  setJson(key: string, value: object): void {
    return this.setItem(key, JSON.stringify(value));
  }

  getInteger(key: string): number | null {
    const value = this.getItem(key);
    if (value !== null) {
      try {
        const integerValue = parseInt(value, 10);
        if (Number.isNaN(integerValue)) {
          throw new Error('Value parsed as NaN');
        }
        return integerValue;
      } catch (e) {
        this.sentryService.notify('Error reading integer from local storage', {
          severity: 'error',
          metaData: {
            fieldName: key,
            errorMessage: e.message,
          },
        });
        return null;
      }
    }
    return null;
  }

  remove(key: string): void {
    return this.removeItem(key);
  }

  setNumber(key: string, value: number): void {
    return this.setItem(key, value.toString());
  }

  getString(key: string): string | null {
    return this.getItem(key);
  }

  setString(key: string, value: string): void {
    return this.setItem(key, value);
  }

  getBoolean(key: string): boolean | null {
    const storedValue = this.getItem(key) as BooleanString | null;

    switch (storedValue) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  }

  setBoolean(key: string, value: boolean): void {
    const valueToStore: BooleanString = value ? 'true' : 'false';
    return this.setItem(key, valueToStore);
  }

  private getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  private removeItem(key: string): void {
    return localStorage.removeItem(key);
  }

  private setItem(key: string, value: string): void {
    return localStorage.setItem(key, value);
  }
}
