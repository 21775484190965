<ng-container>
  <header class="bind-quote-header">
    <h1>Policy details</h1>
  </header>

  <app-form-field-text
    inputId="bind-effectiveDate"
    [form]="form"
    [submitted]="submitted()"
    labelText="Effective date"
    nameOfFormControl="effectiveDate"
    appDateMask
  >
    <span
      role="label"
      class="more-info-tip tool-tip tool-tip__large"
      data-tooltip="Please use the appropriate effective date, as changing it may impact the rating plan."
    >
      <span class="icon icon-question"> </span>
    </span>

    <div class="validation" role="alert" *ngIf="fieldInvalid('effectiveDate')">
      <p class="validation-message">
        Please enter a date that is today or up to three months from today.
      </p>
    </div>
  </app-form-field-text>

  <div class="helper-card-container">
    <app-form-field-text
      inputId="employerIdentificationNumber"
      [form]="form"
      [submitted]="submitted()"
      labelText="FEIN"
      nameOfFormControl="employerIdentificationNumber"
      placeholder="00-0000000"
      appFormatMask
      maskFormat="XX-XXXXXXX"
      allowedCharacters="NUMBERS_ONLY"
    >
      <div
        *ngIf="form.get('employerIdentificationNumber')?.hasError('placeholderFein')"
        class="dialog-text dialog-text__error"
      >
        <strong>Is this a real FEIN number?</strong> FEINs are used to calculate premium, so please,
        no placeholder values. If you proceed with a placeholder FEIN, your account will be flagged
        for internal review.
      </div>
    </app-form-field-text>

    <div class="helper-card helper-card__no-margin">
      <div class="pill-tag pill-tag__main">Note</div>
      <p>Endorsing FEIN after bind may result in account audit and fines.</p>
    </div>
  </div>

  <section class="js-policy-details-content">
    <section class="bind-flow-subsection">
      <app-insured-contacts
        (addContact)="addInsuredContact()"
        (deleteContact)="removeInsuredContact($event)"
        [contactFormArray]="insuredContactsFormArr"
        [submitted]="submitted()"
        [insuredContactOptions]="insuredContactOptions"
      >
      </app-insured-contacts>
    </section>

    <section class="bind-flow-subsection">
      <app-broker-contacts
        (delete)="removeBrokerContact($event)"
        (add)="addBrokerContact()"
        [contacts]="getAllPossibleBrokerContacts()"
        [form]="brokerContactsFormArr"
        [submitted]="submitted()"
      >
      </app-broker-contacts>
    </section>
  </section>
</ng-container>
