<main id="set-password" class="login-box">
  <app-logo-with-text></app-logo-with-text>

  <h1 class="login-title-margin">{{ message }}</h1>
  <p class="{{ messageType }}">{{ messageSubtitle }}</p>

  <form class="login-box-form" (ngSubmit)="handleSubmit($event)">
    <button
      *ngIf="showSubmitButton"
      type="submit"
      class="button button__primary button__full-width button__no-margin"
      [disabled]="loading"
    >
      <div class="button-loader" *ngIf="loading">
        <svg class="circle-spinner">
          <circle
            cx="11"
            cy="11"
            r="9"
            stroke-width="2"
            stroke-dasharray="0,1000"
            d="M5 40 l215 0"
            fill="transparent"
          />
        </svg>
      </div>
      {{ message }}
    </button>
  </form>
</main>

<p class="terms-of-use-text">
  By accessing Attune, you agree to the following
  <a href="https://www.attuneinsurance.com/legal/terms-of-use/">Terms of Use</a>.
</p>
<app-footer showPhoneNumber="true"> </app-footer>
