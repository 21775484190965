import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { INVOICE_DETAILS_API_URI, API_V3_BASE, INVOICE_AUTOPAY_URL } from 'app/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { SentryService } from 'app/core/services/sentry.service';
@Injectable()
export class InvoicesService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getInvoice(invoiceId: string, eTransID: string): Observable<Invoice | null> {
    if (eTransID === undefined) {
      this.sentryService.notify('getInvoice called with undefined token.', {
        severity: 'error',
        metaData: {
          invoiceId,
        },
      });
    }
    const options = { params: new HttpParams().set('token', eTransID) };
    return this.http
      .get<BackendInvoiceDetails>(`${INVOICE_DETAILS_API_URI}${invoiceId}`, options)
      .pipe(
        map((response) => this.mapFromPayload(response)),
        catchError((error: Error) => {
          this.sentryService.notify('Unable to get invoice details.', {
            severity: 'error',
            metaData: {
              invoiceId,
              eTransID,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  getAssociatedAccountSummary(invoiceId: string, eTransID: string) {
    if (eTransID === undefined) {
      this.sentryService.notify('getAssociatedAccountSummary called with undefined token.', {
        severity: 'error',
        metaData: {
          invoiceId,
        },
      });
    }
    const options = { params: new HttpParams().set('token', eTransID) };
    return this.http
      .get<AccountSummary>(`${INVOICE_DETAILS_API_URI}${invoiceId}/account-summary`, options)
      .pipe(
        catchError((error: Error) => {
          this.sentryService.notify('Unable to get account summary for invoice.', {
            severity: 'error',
            metaData: {
              invoiceId,
              eTransID,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  getAssociatedInvoices(
    invoiceId: string,
    eTransID: string
  ): Observable<BackendAssociatedInvoices> {
    if (eTransID === undefined) {
      this.sentryService.notify('getAssociatedInvoices called with undefined token.', {
        severity: 'error',
        metaData: {
          invoiceId,
        },
      });
    }
    const options = { params: new HttpParams().set('token', eTransID) };
    return this.http
      .get<BackendAssociatedInvoices>(
        `${INVOICE_DETAILS_API_URI}${invoiceId}/associated-invoices`,
        options
      )
      .pipe(
        catchError((error: Error) => {
          this.sentryService.notify('Unable to get associated invoices.', {
            severity: 'error',
            metaData: {
              invoiceId,
              eTransID,
            },
          });
          throw error;
        })
      );
  }

  getAssociatedPolicies(
    invoiceId: string,
    eTransID: string
  ): Observable<BackendInvoiceAssociatedPolicyResponse> {
    if (eTransID === undefined) {
      this.sentryService.notify('getAssociatedPolicies called with undefined token.', {
        severity: 'error',
        metaData: {
          invoiceId,
        },
      });
    }
    const options = { params: new HttpParams().set('token', eTransID) };
    return this.http
      .get<BackendInvoiceAssociatedPolicyResponse>(
        `${INVOICE_DETAILS_API_URI}${invoiceId}/policies`,
        options
      )
      .pipe(
        catchError((error: Error) => {
          this.sentryService.notify('Unable to get policies associated with an invoice.', {
            severity: 'error',
            metaData: {
              invoiceId,
              eTransID,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  getAutopayStatus(
    accountId: string,
    invoiceId: string,
    token: string
  ): Observable<AutopayInfoResponse> {
    if (token === undefined) {
      this.sentryService.notify('getAutopayStatus called with undefined token.', {
        severity: 'error',
        metaData: {
          accountId,
          invoiceId,
        },
      });
    }
    return this.http
      .get<AutopayInfoResponse>(
        `${INVOICE_AUTOPAY_URL}${accountId}/payment-method/${invoiceId}?token=${token}`
      )
      .pipe(
        catchError((error: Error) => {
          this.sentryService.notify('Unable to get invoice details.', {
            severity: 'error',
            metaData: {
              accountId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  // Generated a standardized text representation of the current payment method
  getAutopayPaymentText(autopayInfo: AutopayInfoResponse) {
    if (autopayInfo.paymentMethod) {
      if (autopayInfo.paymentMethod.description === 'Credit Card') {
        const last4Digits = autopayInfo.displayName ? autopayInfo.displayName.slice(-4) : '****';
        if (last4Digits.length > 0) {
          return `**** **** **** ${last4Digits}`;
        }
        return '';
      } else {
        // ACH numbers come back from the API in the format "ACH/EFT (******6789/110000000)"
        // This regex selects the section "******6789", which is what we want to display.
        const bankNumberRegex = /(\*+\d*)/;
        const regexResults = bankNumberRegex.exec(autopayInfo.displayName || '');
        const bankNumber = regexResults && regexResults[0] ? regexResults[0] : '';
        if (bankNumber && bankNumber.length > 0) {
          return `ACH Transfer (${bankNumber})`;
        }
        return '';
      }
    }
    return 'Not available.';
  }

  private mapFromPayload(invoiceDetails: BackendInvoiceDetails): Invoice {
    const chargesTotal = invoiceDetails.lineItems.reduce((acc, invoiceItem) => {
      return acc + invoiceItem.amount;
    }, 0);

    return {
      accountName: invoiceDetails.accountName,
      accountNumber: invoiceDetails.accountNumber,
      agentName: invoiceDetails.agentName,
      charges: invoiceDetails.lineItems.map((invoiceItem) => {
        return {
          amount: invoiceItem.amount,
          chargeType: invoiceItem.chargeType,
          lineOfBusiness: invoiceItem.lineOfBusiness,
          policyNumber: invoiceItem.policyNumber,
          primaryProducerCode: invoiceItem.producerCode,
        };
      }),
      allChargesVoided: chargesTotal < 0.01,
      dueDate: invoiceDetails.dueDate,
      dueDateForFormPayload: this.dueDateForFormPayload(invoiceDetails),
      billDate: invoiceDetails.billDate,
      eTransID: invoiceDetails.token,
      invoiceNumber: invoiceDetails.invoiceNumber,
      isChaseRecurring: invoiceDetails.isChaseRecurring,
      isDeferred: invoiceDetails.isDeferred || false,
      isOverdue: this.getOverDueStatus(invoiceDetails),
      isStripeRecurring: invoiceDetails.isStripeRecurring,
      payoffAmount: invoiceDetails.payoffAmount,
      stripeCustomerId: invoiceDetails.stripeCustomerId,
      totalChargesOutstandingAmount: invoiceDetails.outstandingAmount,
      isCardProcessingFeeEligible: invoiceDetails.isCardProcessingFeeEligible,
      originalInvoiceAmountBilled: invoiceDetails.originalInvoiceAmount,
      totalInvoiceAmount: invoiceDetails.invoiceAmount,
      unbilledAmount: invoiceDetails.unbilledAmount,
      priorInvoiceDetails: {
        eTransID: invoiceDetails.previousToken,
        invoiceId: invoiceDetails.previousInvoice,
      },
      status: invoiceDetails.status,
      creditCardFee: invoiceDetails.creditCardFee,
      creditCardFeePayoffAmount: invoiceDetails.creditCardFeePayoffAmount,
      creditCardFeeOutstandingAmount: invoiceDetails.creditCardFeeOutstandingAmount,
      creditCardPercent: invoiceDetails.creditCardPercent,
    };
  }

  private dueDateForFormPayload(invoiceDetails: BackendInvoiceDetails) {
    if (
      invoiceDetails.invoiceAmount === 0 &&
      moment(invoiceDetails.dueDate).isBefore(moment(), 'day')
    ) {
      return 'TBD';
    } else {
      return moment(invoiceDetails.dueDate).format('Y-MM-DD');
    }
  }

  private getOverDueStatus(invoiceDetails: BackendInvoiceDetails) {
    return (
      invoiceDetails.invoiceAmount > 0 && moment(invoiceDetails.dueDate).isBefore(moment(), 'day')
    );
  }

  getPaymentInvoiceDetails(
    invoiceId: string,
    eTransID: string
  ): Observable<PaymentInvoiceDetailsResponse | null> {
    if (eTransID === undefined) {
      this.sentryService.notify('getPaymentInvoiceDetails called with undefined token.', {
        severity: 'error',
        metaData: {
          invoiceId,
        },
      });
    }
    const options = { params: new HttpParams().set('token', eTransID) };
    return this.http
      .get<PaymentInvoiceDetailsResponse>(
        `${API_V3_BASE}/billing/invoices/${invoiceId}/payment-details`,
        options
      )
      .pipe(
        catchError((error: Error) => {
          this.sentryService.notify('Unable to get payment invoice details.', {
            severity: 'error',
            metaData: {
              invoiceId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  getPlannedInvoices(invoiceId: string, eTransID: string): Observable<BackendAssociatedInvoices> {
    // TODO(WJC): rename this from the inaccurate "getPlannedInvoices" to more accurate "getAssociatedInvoices"
    if (eTransID === undefined) {
      this.sentryService.notify('getPlannedInvoices called with undefined token.', {
        severity: 'error',
        metaData: {
          invoiceId,
        },
      });
    }
    const options = {
      params: new HttpParams()
        .set('invoiceStatuses', 'Planned')
        .append('invoiceStatuses', 'Invoiced')
        .append('invoiceStatuses', 'Outstanding')
        .append('invoiceStatuses', 'Paid')
        .set('token', eTransID),
    };

    return this.http
      .get<BackendAssociatedInvoices>(
        `${INVOICE_DETAILS_API_URI}${invoiceId}/associated-invoices`,
        options
      )
      .pipe(
        catchError((error: Error) => {
          this.sentryService.notify('Unable to get planned invoices.', {
            severity: 'error',
            metaData: {
              invoiceId,
              eTransID,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }
}
