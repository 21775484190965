import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
  OnboardingFormService,
  UserRoleOption,
} from 'app/features/onboarding/services/onboarding-form.service';

@Component({
  selector: 'app-onboarding-introduction-page',
  templateUrl: './onboarding-introduction-page.component.html',
})
export class OnboardingIntroductionPageComponent implements OnInit {
  private formPath = 'userRole';
  public form: UntypedFormGroup;
  public userRoleOptions = {
    'Quoting new business': UserRoleOption.QUOTE,
    'Servicing policies': UserRoleOption.SERVICE,
    Both: UserRoleOption.BOTH,
  };
  public sampleQuoteOptInOptions = {
    "No, let's do a sample quote": true,
    'Yes, start quoting': false,
  };

  constructor(private formService: OnboardingFormService) {}

  ngOnInit() {
    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
  }

  hasSubmitted() {
    return this.formService.submitted;
  }
}
