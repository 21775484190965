import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { AmplitudePayloadType } from '../../core/constants/amplitude-helpers';

/**
 * Component that renders its children / content in a modal when open is truthy.
 *
 * @param open: whether the modal is visible right now
 * @param disableClose: turn on to disable usual methods of closing (X, curtain click)
 * @param handleModalClose: function handler for usual methods of closing (X, curtain click)
 *    typical usage is handleModalClose() { this.modalOpen = false };
 */

@Component({
  selector: 'app-modal',
  styleUrls: ['./modal.component.scss'],
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnChanges {
  @Input() open = false;
  @Input() disableClose = false;
  @Input() modalType: 'flyout' | 'drawer' | 'video' | null = null;
  @Input() modalClass: string;
  @Input() modalContainerClass: string;
  @Input() public ariaRole = 'dialog';
  @Input() public ariaLabelledby: string;
  @Input() public ariaDescribedby: string;
  @Input() modalNameForAmplitudeEvent: string;
  @Output() handleModalClose: EventEmitter<null> = new EventEmitter();

  isAnimatingClose = false;

  constructor(private amplitudeService: AmplitudeService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.open?.currentValue && !changes.open?.previousValue) {
      const detail = this.modalNameForAmplitudeEvent
        ? `modal-open-${this.modalNameForAmplitudeEvent}`
        : 'modal-open';
      this.amplitudeService.track({
        eventName: AmplitudePayloadType.Modal,
        detail,
      });
    }
  }

  getModalCloseAmplitudeEventName() {
    if (this.modalNameForAmplitudeEvent) {
      return `close-x-${this.modalNameForAmplitudeEvent}`;
    }
    return 'modal-close-button';
  }

  handleClickClose() {
    if (this.disableClose) {
      return;
    }

    if (this.modalType !== null) {
      this.isAnimatingClose = true;
      setTimeout(() => {
        this.handleModalClose.emit();
        this.isAnimatingClose = false;
      }, 300);
      return;
    }

    this.handleModalClose.emit();
  }

  isPlainModal() {
    return this.modalType === null;
  }

  isFlyout() {
    return this.modalType === 'flyout';
  }

  isDrawer() {
    return this.modalType === 'drawer';
  }

  hasCurtain() {
    return this.modalType !== 'drawer';
  }

  isVideo() {
    return this.modalType === 'video';
  }
}
