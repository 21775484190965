import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EditRequestParams } from '../models/types';

@Injectable({
  providedIn: 'root',
})
export class DigitalCarrierDuplicateRequestCheckService {
  private request: EditRequestParams | null;
  private wasLastRequestSuccessful: boolean | null;

  constructor() {
    this.request = null;
    this.wasLastRequestSuccessful = null;
  }

  /**
   * A call should only be made if the request made right before this one was either a different request or if it was the same
   * as the one being made now but had a failed response.
   * @param newRequest : EditRequest Params
   * @returns boolean which tells you wthere or not to make the call.
   */
  shouldTriggerRequest(newRequest: EditRequestParams) {
    const requestsAreSame = _.isEqual(this.request, newRequest);
    const sameRequestButLastOneFailed = requestsAreSame && this.wasLastRequestSuccessful === false;
    return !requestsAreSame || sameRequestButLastOneFailed;
  }

  newRequestMade(request: EditRequestParams) {
    this.request = request;
  }

  setRequestSuccess(success: boolean) {
    this.wasLastRequestSuccessful = success;
  }
}
