import * as moment from 'moment';

import { EvaluatedValueName } from 'app/features/liberty-mutual/models/common-typings';

import { CUSTOMER_CARE_HOURS } from 'app/constants';

export const EVALUATED_VALUES: { [k in EvaluatedValueName]: string | number } = {
  CURRENT_YEAR: moment.utc().format('YYYY'),
  CURRENT_YEAR_MINUS_THREE_YEARS: moment.utc().subtract(3, 'years').format('YYYY'),
};
// This constant must have the same value as DUPLICATE_INSURED_ACCOUNT_REFERRAL_MESSAGE
// in service-quote/src/main/ts/carriers/liberty-mutual/LibertyMutualConstants.ts
export const DUPLICATE_INSURED_ACCOUNT_REFERRAL_MESSAGE_BACKEND =
  'This account was reserved for another producer.';

export const LIBERTY_MUTUAL_BROKER_OF_RECORD_ERROR_MESSAGE = `This account was reserved for another producer. You may <a href="https://app.attuneinsurance.com/help-center/form/request-bor">request a broker of record change</a> through our Help Center. Please note this will require an <a href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Agent+or+Broker+of+Record+Change+(Acord+36).pdf">Agent/Broker of Record Change (ACORD 36)</a> form.<br><br> If you have any questions, please contact our Customer Care Team at <a href="tel:18885304650">1-888-530-4650</a> (Mon-Fri, ${CUSTOMER_CARE_HOURS.start}-${CUSTOMER_CARE_HOURS.start} ${CUSTOMER_CARE_HOURS.timezone}) or <a href="https://app.attuneinsurance.com/support/form/request-review">submit a request through our Help Center</a>.`;

export const PHONE_ERROR_MESSAGE = 'Please enter a valid phone number.';
export const YEAR_ERROR_MESSAGE = 'Please enter a valid date in the past or the current year.';
export const TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE = (value: number) =>
  `Please limit your entry to ${value} characters.`;
export const ZERO_ERROR_MESSAGE = 'Please enter a value greater than 0.';
export const FEIN_ERROR_MESSAGE = 'Please enter a valid FEIN in format 00-0000000.';
export const LOCATION_RISK_STATE_ERROR_MESSAGE =
  'The state for this location must match the base state for this policy. Please update the business location address or update the base state on the Policy details page.';
