import { Component, Input } from '@angular/core';

import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { WcQuoteFormService } from 'app/workers-comp/employers/services/workers-comp-form.service';

@Component({
  selector: 'app-wc-underwriting-form',
  templateUrl: './wc-underwriting-form.component.html',
})
export class WcUnderwritingFormComponent {
  @Input() underwritingFormGroup: UntypedFormGroup;
  @Input() locationsFormArray: UntypedFormArray;
  @Input() submitted: boolean;

  constructor(public formService: WcQuoteFormService) {}

  hasClassSpecificQuestions() {
    return this.formService.hasClassSpecificQuestions();
  }

  getExecutiveExclusionQuestion() {
    return this.formService.getExecutiveExclusionQuestion();
  }

  getClassSpecificQuestions() {
    return this.formService.getVisibleClassSpecificQuestions();
  }

  hasStateOrgTypeSpecificQuestions() {
    return this.formService.hasPartnersQuestions();
  }

  getPartnersControls() {
    return this.formService.getPartnersControls();
  }
}
