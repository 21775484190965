import { Component, ElementRef, ViewChild, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { Observable, fromEvent, combineLatest, Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { RewardsService } from '../services/rewards.service';
import { ActionName } from '../rewards/rewards-types';

interface Vector2D {
  x: number;
  y: number;
}

type Position = Vector2D;

interface QuotePosition {
  position: Position;
  // 2D Vector, pixels per second
  velocity: Vector2D;
  // 2D Vector, pixels per second
  acceleration: Vector2D;
  scale: number;
  points: number;
  image: HTMLImageElement;
}

// Quote image & size in px
const QUOTE_IMG = '/assets/img/quote_1.png';
const BLUE_QUOTE_IMG = '/assets/img/quote_2.png';
const GOLD_QUOTE_IMG = '/assets/img/quote_3.png';
export const QUOTE_WIDTH = 54 * window.devicePixelRatio;
export const QUOTE_HEIGHT = 73 * window.devicePixelRatio;
// Quote point values (multiplied by 100 in HTML template)
export const QUOTE_POINTS = 1;
export const BLUE_QUOTE_POINTS = 2.5;
export const GOLD_QUOTE_POINTS = 50;

const FULL_CIRCLE = 2 * Math.PI;
const ONE_SECOND_IN_MS = 1000;

// How fast does the quote image disappear when clicked
const DISAPPEAR_SPEED_SCALE = 1.1;
// How much faster do things move as score increases?
const SPEED_MULTIPLIER = 5;
// How fast should we start? (in pixels per second)
export const SPEED_BASE = 100 * window.devicePixelRatio;

// How many points do we award when more than quote is clicked on?
export const QUOTE_CLICK_BONUS = 1;

// Acceleration due to gravity in pixels per second per second
const GRAVITY_ACCELERATION = 300;

// Turn on/off bats and ghosts for halloween
// When `true`, blue quotes are substituted with bats
//              gold quotes are substituted with ghosts
const today = moment.utc();
const ITS_HALLOWEEN =
  today.isAfter(moment().month('Oct').date(27)) && today.isBefore(moment().month('Nov').date(4));
const BAT_IMG = '/assets/img/quote_bat.png';
const GHOST_IMG = '/assets/img/quote_ghost.png';

// Turn on/off gifts and snowpersons for winter
// When `true`, blue quotes are substituted with snowpersons
//              gold quotes are substituted with gift boxes
const ITS_WINTER_HOLIDAYS =
  today.isAfter(moment().month('Dec').date(20)) || today.isBefore(moment().month('Jan').date(4));
const SNOWPERSON_IMG = '/assets/img/quote_snowperson.png';
const GIFTBOX_IMG = '/assets/img/quote_giftbox.png';

const ITS_APRIL_FOOLS =
  today.isAfter(moment().month('April').date(1).startOf('day')) &&
  today.isBefore(moment().month('April').date(2).startOf('day'));

/**
 * Component that renders the quoting game
 */

@Component({
  selector: 'app-quoting-game',
  templateUrl: './quoting-game.component.html',
})
export class QuotingGameComponent implements OnDestroy, OnInit {
  @ViewChild('canvas', { static: true })
  canvasRef: ElementRef;
  @Input() finish$: Observable<boolean>;
  @Input() showProgressBar = true;
  @Input() title = 'Processing quote';
  @Input() successComment = 'Preparing policy...';
  @Input() showTip = false;
  public score = 0; // multiplied by 100 to display
  public quotes: QuotePosition[] = [];
  public bonusPoints: { points: number; positionX: number; positionY: number }[] = [];
  private running = false;
  private hits = 0;
  private quote = new Image();
  private blueQuote = new Image();
  private goldQuote = new Image();
  private context2d: CanvasRenderingContext2D;
  private sub = new Subscription();
  public speed = SPEED_BASE;
  private isGravityOn = false;
  private mousePosition = {
    x: 0,
    y: 0,
  };

  constructor(
    private ngZone: NgZone,
    private amplitudeService: AmplitudeService,
    private rewardsService: RewardsService
  ) {}

  static canvasDimensions() {
    return {
      height:
        Math.max((<HTMLElement>document.documentElement).clientHeight, window.innerHeight || 0) *
        this.devicePixelRatio(),
      width:
        Math.max((<HTMLElement>document.documentElement).clientWidth, window.innerWidth || 0) *
        this.devicePixelRatio(),
    };
  }

  static devicePixelRatio() {
    return window.devicePixelRatio;
  }

  static quoteCount() {
    const max = 600 * this.devicePixelRatio();
    const { width, height } = this.canvasDimensions();
    return width > max && height > max ? 15 : 10;
  }

  static tick(
    timeDeltaMs: number,
    quotePosition: QuotePosition,
    canvasWidth: number,
    canvasHeight: number,
    mousePosition: Position,
    targetSpeed: number,
    isGravityOn: boolean
  ): QuotePosition {
    // Note: Use Math.min here to "pause" the game while the user looks at another window
    timeDeltaMs = Math.min(100, timeDeltaMs);
    const newQuotePosition = _.cloneDeep(quotePosition);

    newQuotePosition.position.x =
      quotePosition.position.x + (quotePosition.velocity.x * timeDeltaMs) / ONE_SECOND_IN_MS;
    newQuotePosition.position.y =
      quotePosition.position.y + (quotePosition.velocity.y * timeDeltaMs) / ONE_SECOND_IN_MS;
    newQuotePosition.velocity.x =
      quotePosition.velocity.x + (quotePosition.acceleration.x * timeDeltaMs) / ONE_SECOND_IN_MS;
    newQuotePosition.velocity.y =
      quotePosition.velocity.y + (quotePosition.acceleration.y * timeDeltaMs) / ONE_SECOND_IN_MS;

    if (isGravityOn) {
      newQuotePosition.acceleration.x = 0;
      newQuotePosition.acceleration.y = GRAVITY_ACCELERATION;
    } else {
      const currSpeed = QuotingGameComponent.distance({ x: 0, y: 0 }, newQuotePosition.velocity);
      // Note: Determines "How quickly do quotes go back to normal speed after moving away from mouse?" During april fool's
      //       A higher number here means that the quote return to "normal" speed sooner
      const SPEED_CONST_ACCEL = 10;
      newQuotePosition.acceleration.x =
        ((targetSpeed - currSpeed) / currSpeed) * quotePosition.velocity.x * SPEED_CONST_ACCEL;
      newQuotePosition.acceleration.y =
        ((targetSpeed - currSpeed) / currSpeed) * quotePosition.velocity.y * SPEED_CONST_ACCEL;
    }

    // BOUNCE OFF SIDE WALLS
    if (newQuotePosition.position.x < 0) {
      newQuotePosition.position.x = Math.abs(newQuotePosition.position.x);
      newQuotePosition.velocity.x = -newQuotePosition.velocity.x;
    } else if (newQuotePosition.position.x + QUOTE_WIDTH > canvasWidth) {
      newQuotePosition.position.x =
        newQuotePosition.position.x - (newQuotePosition.position.x + QUOTE_WIDTH - canvasWidth);
      newQuotePosition.velocity.x = -newQuotePosition.velocity.x;
    }

    // BOUNCE OFF TOP AND BOTTOM WALLS
    if (newQuotePosition.position.y < 0) {
      newQuotePosition.position.y = Math.abs(newQuotePosition.position.y);
      newQuotePosition.velocity.y = -newQuotePosition.velocity.y;
    } else if (newQuotePosition.position.y + QUOTE_HEIGHT > canvasHeight) {
      newQuotePosition.position.y =
        newQuotePosition.position.y - (newQuotePosition.position.y + QUOTE_HEIGHT - canvasHeight);
      newQuotePosition.velocity.y = -newQuotePosition.velocity.y;
    }

    if (newQuotePosition.scale < 1) {
      newQuotePosition.scale = newQuotePosition.scale / DISAPPEAR_SPEED_SCALE;
    }

    const quoteCenter = {
      x: newQuotePosition.position.x + QUOTE_WIDTH / 2,
      y: newQuotePosition.position.y + QUOTE_HEIGHT / 2,
    };

    // Note: This variables determines "How many pixels away from the quote should
    //       our mouse start repelling it?"
    const ACCEL_INFLUENCE_DISTANCE_PX = 200 * QuotingGameComponent.devicePixelRatio();
    if (
      ITS_APRIL_FOOLS &&
      !isGravityOn &&
      QuotingGameComponent.distance(quoteCenter, mousePosition) < ACCEL_INFLUENCE_DISTANCE_PX
    ) {
      const distanceToMouse = QuotingGameComponent.distance(quoteCenter, mousePosition);

      let accelStrength =
        (ACCEL_INFLUENCE_DISTANCE_PX - distanceToMouse) / ACCEL_INFLUENCE_DISTANCE_PX;

      // Note: Using a power function here provides a
      //       nice smooth decay of influcence as the distance from the mouse increases.
      //       A smaller expoent here means a more gradual slope to "full repell force"
      //       as the mouse approachs the quote
      accelStrength = Math.pow(accelStrength, 10);

      // Note: Multiplying here allows us to control the "strength of influence" of the acceleration
      //       Higher number here means quotes move away from mouse faster
      accelStrength *= 2000;

      newQuotePosition.acceleration.x =
        newQuotePosition.acceleration.x + (quoteCenter.x - mousePosition.x) * accelStrength;
      newQuotePosition.acceleration.y =
        newQuotePosition.acceleration.y + (quoteCenter.y - mousePosition.y) * accelStrength;
    }

    return newQuotePosition;
  }

  static distance(position1: Position, position2: Position) {
    const distanceX = position1.x - position2.x;
    const distanceY = position1.y - position2.y;
    return Math.sqrt(distanceX * distanceX + distanceY * distanceY);
  }

  ngOnInit() {
    this.scaleCanvas();
    this.context2d = this.canvasRef.nativeElement.getContext('2d');

    const { width, height } = QuotingGameComponent.canvasDimensions();
    this.quotes = this.generateQuotes(
      QuotingGameComponent.quoteCount(),
      QuotingGameComponent.canvasDimensions()
    );

    // Create observables from each image's `load` event
    const quote$ = fromEvent(this.quote, 'load');
    const blueQuote$ = fromEvent(this.blueQuote, 'load');
    const goldQuote$ = fromEvent(this.goldQuote, 'load');
    // Start the quote game after all `load` events have emitted
    combineLatest(quote$, blueQuote$, goldQuote$).subscribe(() => {
      this.running = true;
      this.ngZone.runOutsideAngular(() => this.animate());
    });

    // Add an image load callback and set the source for all quote images
    this.blueQuote.onload = () => this.context2d.drawImage(this.blueQuote, -3000, -3000);
    this.goldQuote.onload = () => this.context2d.drawImage(this.goldQuote, -3000, -3000);
    this.quote.onload = () => this.context2d.drawImage(this.quote, -3000, -3000);

    if (ITS_HALLOWEEN) {
      // October 28 - November 3 images
      this.blueQuote.src = BAT_IMG;
      this.goldQuote.src = GHOST_IMG;
      this.quote.src = QUOTE_IMG;
    } else if (ITS_WINTER_HOLIDAYS) {
      // December 21 - January 3 images
      this.blueQuote.src = SNOWPERSON_IMG;
      this.goldQuote.src = GIFTBOX_IMG;
      this.quote.src = QUOTE_IMG;
    } else {
      // Default images for quote game
      this.blueQuote.src = BLUE_QUOTE_IMG;
      this.goldQuote.src = GOLD_QUOTE_IMG;
      this.quote.src = QUOTE_IMG;
    }

    // Submit a rewards request with the final quote game score
    this.sub.add(
      this.finish$.subscribe((quoteComplete) => {
        if (quoteComplete && this.score > 0) {
          this.rewardsService.submitRewardAction({
            actionName: ActionName.QUOTE_GAME_SCORE,
            data: { quoteGameScore: this.score * 100 },
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.amplitudeService.track({
      eventName: 'quote_score',
      detail: String(this.score * 100),
      useLegacyEventName: true,
    });
    this.running = false;
    this.sub.unsubscribe();
  }

  handleMove(event: MouseEvent) {
    const { clientX, clientY } = event;
    this.mousePosition = {
      x: clientX * QuotingGameComponent.devicePixelRatio(),
      y: clientY * QuotingGameComponent.devicePixelRatio(),
    };
  }

  generateQuotes(
    n: number,
    {
      width,
      height,
    }: {
      width: number;
      height: number;
    }
  ) {
    const quotes: QuotePosition[] = [];
    for (let i = 0; i < n; i++) {
      const radians = FULL_CIRCLE * Math.random();

      // Determine type of quote and point value randomly
      let image: HTMLImageElement;
      let points: number;
      const randomChance = Math.random() * 10;
      if (randomChance < 0.025) {
        image = this.goldQuote;
        points = GOLD_QUOTE_POINTS;
      } else if (randomChance < 1) {
        image = this.blueQuote;
        points = BLUE_QUOTE_POINTS;
      } else {
        image = this.quote;
        points = QUOTE_POINTS;
      }

      quotes.push({
        position: {
          x: Math.random() * width,
          y: Math.random() * height,
        },
        velocity: {
          x: Math.cos(radians) * SPEED_BASE,
          y: Math.sin(radians) * SPEED_BASE,
        },
        acceleration: {
          x: 0,
          y: 0,
        },
        scale: 1,
        image,
        points,
      });
    }
    return quotes;
  }

  handleClick(event: MouseEvent) {
    const { clientX, clientY } = event;
    const clickX = clientX * QuotingGameComponent.devicePixelRatio();
    const clickY = clientY * QuotingGameComponent.devicePixelRatio();
    const { width, height } = QuotingGameComponent.canvasDimensions();
    const wasClicked = ({ position, scale }: QuotePosition) => {
      return (
        scale === 1 &&
        clickX > position.x &&
        clickX < position.x + QUOTE_WIDTH &&
        clickY > position.y &&
        clickY < position.y + QUOTE_HEIGHT
      );
    };
    const startingQuoteCount = this.quotes.length;
    let quotesClicked = 0;
    this.quotes.forEach((q) => {
      if (wasClicked(q)) {
        q.scale = 0.8;
        this.score += q.points;
        // Keep track of how many quotes are clicked per click
        quotesClicked++;

        // Speed up on each success
        // // Add in a new quote to replace it
        this.quotes.push(...this.generateQuotes(1, QuotingGameComponent.canvasDimensions()));
      }
    });

    // Speed up when any amount of quotes are hit
    if (quotesClicked > 0) {
      this.hits++;
      this.speed = this.score * SPEED_MULTIPLIER + SPEED_BASE;
      this.isGravityOn = ITS_APRIL_FOOLS && !this.isGravityOn;
    }

    // Award bonus points if more than one quote is clicked on
    // with one click and display a note to user
    if (quotesClicked > 1) {
      this.score += quotesClicked - 1;
      this.bonusPoints.unshift({
        points: (quotesClicked - 1) * QUOTE_CLICK_BONUS,
        positionX: clientX,
        positionY: clientY,
      });
    }
  }

  private scaleCanvas() {
    const canvas = this.canvasRef.nativeElement;
    const { width, height } = QuotingGameComponent.canvasDimensions();
    if (canvas.width !== width) {
      canvas.width = width;
      canvas.style.width = width / QuotingGameComponent.devicePixelRatio() + 'px';
    }
    if (canvas.height !== height) {
      canvas.height = height;
      canvas.style.height = height / QuotingGameComponent.devicePixelRatio() + 'px';
    }
  }

  private animate(timeDeltaMs = 0) {
    if (!this.running) {
      return;
    }
    // XXX is perf now safe?
    const tickStartMs = window.performance.now();
    const { width, height } = QuotingGameComponent.canvasDimensions();
    this.scaleCanvas();

    this.quotes = this.quotes
      .map((q) =>
        QuotingGameComponent.tick(
          timeDeltaMs,
          q,
          width,
          height,
          this.mousePosition,
          this.speed,
          this.isGravityOn
        )
      )
      .filter(({ scale }) => scale > 0.01); // Remove them after they are tiny;

    // Remove what was on the canvas before.
    this.context2d.clearRect(0, 0, width, height);
    for (let i = this.quotes.length - 1; i >= 0; i--) {
      const {
        position: { x, y },
        scale,
        image,
      } = this.quotes[i];
      this.context2d.globalAlpha = scale;
      // Draw with the specific image on the quote
      this.context2d.drawImage(image, x, y, QUOTE_WIDTH * scale, QUOTE_HEIGHT * scale);
    }

    requestAnimationFrame(() => this.animate(window.performance.now() - tickStartMs));
  }

  public showQuoteGameTip() {
    return this.showTip;
  }
}
