<ng-container *ngIf="isReviewStatus()">
  <h1 class="landing-h1 landing-h1__info">Review Cancellation</h1>
  <div class="landing-container js-cancel-review">
    <h3>You must confirm below for this cancellation to take effect.</h3>
    <ul class="bulleted-list bulleted-list__tight">
      <li>
        Once this cancellation is confirmed, coverage for policy #
        {{ cancelResponseInfo.policyNumber }} will cease effective
        <strong>{{ getEffectiveDate() }}</strong>
      </li>
      <li>
        The reason for cancellation is: <strong>{{ getCancellationReason() }}</strong>
      </li>
      <li>
        The refund method for this cancellation will be <strong>{{ getRefundMethod() }}</strong>
      </li>
    </ul>

    <div class="landing-buttons">
      <button
        class="button button__primary button__no-margin js-confirm-button"
        type="submit"
        (click)="confirmCancellation()"
        [disabled]="isConfirmingCancellation || isWithdrawingCancellation"
      >
        <ng-container *ngIf="isConfirmingCancellation">
          <div class="button-loader">
            <svg class="circle-spinner">
              <circle
                cx="11"
                cy="11"
                r="9"
                stroke-width="2"
                stroke-dasharray="0,1000"
                d="M5 40 l215 0"
                fill="transparent"
              />
            </svg>
          </div>
          Cancelling...
        </ng-container>
        <ng-container *ngIf="!isConfirmingCancellation">Confirm cancellation</ng-container>
      </button>
      <button
        class="button button__transparent button__no-margin landing-withdraw-button"
        (click)="withdrawCancellation()"
        [disabled]="isConfirmingCancellation || isWithdrawingCancellation"
      >
        <ng-container *ngIf="isWithdrawingCancellation">
          <div class="button-loader">
            <svg class="circle-spinner">
              <circle
                cx="11"
                cy="11"
                r="9"
                stroke-width="2"
                stroke-dasharray="0,1000"
                d="M5 40 l215 0"
                fill="transparent"
              />
            </svg>
          </div>
          Withdrawing...
        </ng-container>
        <ng-container *ngIf="!isWithdrawingCancellation">Withdraw request</ng-container>
      </button>
    </div>

    <p class="gray-text">
      <strong>Please review the terms of the request before confirming.</strong>
      If we think a typo impacting policy number or effective date was made during request
      submission, we will have updated it here to reflect what we think you meant.
    </p>
  </div>
</ng-container>
<ng-container *ngIf="isConfirmStatus()">
  <h1 class="landing-h1 landing-h1__dialog">Confirm Cancellation</h1>
  <div class="landing-container js-cancel-confirm">
    <app-dialog-box
      type="success"
      class="dialog-text__margin-bottom dialog-text__full-width"
      header="Cancellation completed"
      [dismissable]="false"
    >
      Success! Please find confirmation details below. We will also send a confirmation email to
      {{ emailAddress }} with the endorsement document within the next hour.
    </app-dialog-box>
    <app-document-download
      displayName="Cancellation Document"
      [documentDownload$]="policyDocumentDownload$"
      [documentReady$]="policyDocumentDownloadReady$"
    >
    </app-document-download>
    <div class="landing-card">
      <h2 class="mt-0">Summary of cancellation</h2>
      <ul class="bulleted-list bulleted-list__tight">
        <li>
          Coverage for policy # {{ cancelResponseInfo.policyNumber }} will cease effective
          <strong>{{ getEffectiveDate() }}</strong>
        </li>
        <li>
          The reason for cancellation is: <strong>{{ getCancellationReason() }}</strong>
        </li>
        <li>
          The refund method for this cancellation will be <strong>{{ getRefundMethod() }}</strong>
        </li>
      </ul>

      <p class="gray-text">
        <strong>Please review the terms of the request before confirming.</strong>
        If we think a typo impacting policy number or effective date was made during request
        submission, we will have updated it here to reflect what we think you meant.
      </p>
    </div>
  </div>
</ng-container>
