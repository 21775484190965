import {
  InvoicesPaymentService,
  InvoicePaymentResponse,
} from '../../services/invoices-payment.service';
import { OnInit, ViewChild, ElementRef, OnDestroy, Component } from '@angular/core';
import { InvoicesService } from 'app/features/invoices/services/invoices.service';
import { SentryService } from 'app/core/services/sentry.service';
import { FullstoryService } from 'app/core/services/fullstory.service';
import { datadogRum } from '@datadog/browser-rum';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AmplitudePayloadType, INPUT_PAGE_ENTER } from 'app/core/constants/amplitude-helpers';
import { HAB_PRODUCT_NAME } from 'app/features/invoices/models/invoices-constants';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { flatMap, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BILLER_GROUP_ID } from 'app/features/attune-bop/models/constants';
import { CreateTokenBankAccountData } from '@stripe/stripe-js';
import { InvoicesPaymentFormComponent } from '../../components/invoices-payment-form/invoices-payment-form.component';
import { environment } from 'environments/environment';
import { InformService } from 'app/core/services/inform.service';
import { InvoicesBannerService } from '../../services/invoices-banner.service';
import {
  COALITION_CYBER_INVOICE_SURPLUS_DISPLAY_NAME,
  COALITION_CYBER_INVOICE_SURPLUS_GUIDEWIRE_NAME,
} from 'app/features/coalition/models/cyber-constants.model';
import { getAttuneBopInvoiceReceiptUrl } from '../../../documents/models/document-urls.model';
import {
  getInsuredBillingGuideFileName,
  getInvoiceReceiptFileName,
} from '../../../documents/models/document-file-names.model';
import { DocumentService } from '../../../documents/services/document.service';
import { INSURED_BILLING_GUIDE } from 'app/features/support/models/support-constants';

@Component({
  selector: 'app-invoices-policy-page.app-page.app-page__invoice',
  templateUrl: './invoices-policy-page.component.html',
})
export class InvoicesPolicyPageComponent implements OnInit, OnDestroy {
  @ViewChild('nativeForm')
  formElement: ElementRef;

  @ViewChild(InvoicesPaymentFormComponent)
  paymentFormComponent: InvoicesPaymentFormComponent;

  isProcessing = false;

  isUpdatingPayment = false;
  isUpdateOnly = false;
  isPayoffPayment = false;
  isInitialAutopay = false;

  isLoadingInvoice = false;
  paymentUpdateSuccessful = false;

  showStripePaymentModal = false;

  showDeferredModal = false;

  showUnenrollAutopayModal = false;
  isProcessingUnenroll = false;
  unenrollErrorMessage = '';

  autopayDetails: AutopayInfoResponse | null;

  isReceiptAvailable = false;
  isReceiptDownloadRequestInProgress = false;
  dateOfPayment: string;
  showSecondPayoffPaymentMessage = false;

  isPendingReinstatement = false;

  amountPaid = 0;

  serverError = '';
  paymentSuccessMessage: string | undefined;
  isPaymentVerified = false;
  insuredBillingGuide = INSURED_BILLING_GUIDE;

  banners: Record<string, boolean> = {
    overDueInvoice: false,
    nonPayCanceledOutstandingInvoice: false,
    uwPendingCancelInvoice: false,
    uwOutstandingCanceledInvoice: false,
    nonPayCancelNoOutstandingInvoice: false,
    noOutstandingCanceledUWInvoice: false,
    outstandingButNotDeliquentActiveInvoice: false,
    nonPayOutstandingPendingCancellation: false,
    otherCancelInvoice: false,
  };

  loadedInvoice: Invoice;
  previousInvoice: Invoice;
  invoiceObservable: Observable<Invoice | null>;
  associatedInvoices: BackendAssociatedInvoices;
  invoiceStates: string[];
  billerGroupId = BILLER_GROUP_ID;
  showInvoiceBanners = environment.showInvoiceBanners;
  cancellationDate: Date;

  private sub: Subscription = new Subscription();
  isLoadingMetadata = false;
  accountSummary: AccountSummary;
  billDate: string;
  isLoadingBillDate = false;
  plannedInvoice: BackendListInvoice[];
  invoiceStatus: string;
  isInvoiceEarlyPayOff: boolean;
  invoiceDraftDay: string;
  invoiceId: string;
  gracePeriodAfterEarliestDueDate: moment.Moment;
  earliestInvoiceDueDate: moment.Moment;

  constructor(
    private invoiceService: InvoicesService,
    private route: ActivatedRoute,
    private invoicePaymentService: InvoicesPaymentService,
    private amplitudeService: AmplitudeService,
    private documentService: DocumentService,
    private sentryService: SentryService,
    private fullstoryService: FullstoryService,
    private informService: InformService,
    private invoiceBannerService: InvoicesBannerService
  ) {}

  ngOnInit() {
    // Because invoices load on their own page, they do not trigger a NavigationEnd / 'url change'
    // NOTE: Edits event type to look like normal / automatic page_events
    this.amplitudeService.submitEvent({
      input: INPUT_PAGE_ENTER,
      type: AmplitudePayloadType.Page,
      value: `/bop/invoice/${this.route.snapshot.params.id}`,
    });

    this.invoiceId = this.route.snapshot.params.id;

    const source = this.route.snapshot.queryParams.src;
    const smsMessageId = this.route.snapshot.queryParams.msg_id || '';
    if (
      [
        'sms_welcome',
        'sms_delinquency',
        'sms_delinquency_second_reminder',
        'sms_failed_payment',
      ].includes(source)
    ) {
      this.amplitudeService.trackWithOverride({
        eventName: `billing_${source}`,
        detail: `/bop/invoice/${this.route.snapshot.params.id}`,
        payloadOverride: { smsMessageId },
        useLegacyEventName: true,
      });
    }

    this.getInvoice();
    this.getPlannedInvoices();
    this.hideZendeskWidget();
  }

  hasAutopay() {
    return this.autopayDetails && this.autopayDetails.autopayEnrolled;
  }

  openAutopaySignupModal() {
    this.isInitialAutopay = true;
    this.openStripePaymentModal();
  }

  openUnenrollAutopayModal() {
    this.closeStripePaymentModal();
    this.showUnenrollAutopayModal = true;
  }

  closeUnenrollAutopayModal() {
    this.showUnenrollAutopayModal = false;
  }

  hasCompletedPaymentSubmission() {
    return this.paymentSuccessMessage === 'Payment successful.';
  }

  hasCreditCardPayment() {
    return (
      this.autopayDetails &&
      this.autopayDetails.autopayEnrolled &&
      this.autopayDetails.paymentMethod &&
      this.autopayDetails.paymentMethod.description === 'Credit Card'
    );
  }

  isDeferred() {
    return this.loadedInvoice && this.loadedInvoice.isDeferred;
  }

  getPaymentMethodText() {
    if (this.autopayDetails) {
      return this.invoiceService.getAutopayPaymentText(this.autopayDetails);
    }
    return '';
  }

  getAmountDue() {
    if (this.associatedInvoices && this.associatedInvoices.associatedInvoices.length === 1) {
      return this.getDefaultAmountDue();
    } else if (this.previousInvoice && this.previousInvoice.totalInvoiceAmount > 0) {
      return this.getOutstandingAmount();
    } else {
      return this.getInvoiceAmount();
    }
  }

  lastInvoicePaid() {
    return !this.previousInvoice || this.previousInvoice.totalInvoiceAmount <= 0;
  }

  getInvoice() {
    this.isLoadingInvoice = true;
    if (this.route.snapshot.params.id && this.route.snapshot.queryParams.token) {
      this.invoiceObservable = this.invoiceService.getInvoice(
        this.route.snapshot.params.id,
        this.route.snapshot.queryParams.token
      );
      this.invoiceObservable.subscribe((res) => this.handleInvoice(res));
      this.getMetadataForInvoice();
      this.isLoadingBillDate = true;
      this.invoiceBannerService
        .getAssociatedInvoicesAndPolicies(
          this.route.snapshot.params.id,
          this.route.snapshot.queryParams.token
        )
        .subscribe(
          (
            associatedInvoicesAndPolicies:
              | [BackendAssociatedInvoices, BackendInvoiceAssociatedPolicyResponse]
              | undefined
          ) => {
            if (!associatedInvoicesAndPolicies) {
              return null;
            }
            const [associatedInvoices, associatedPolicies] = associatedInvoicesAndPolicies;
            this.associatedInvoices = associatedInvoices;

            this.checkForInvalidInvoiceState();

            const currentInvoiceFromList = associatedInvoices.associatedInvoices.find(
              (invoice) => invoice.id === this.route.snapshot.params.id
            );
            this.isPendingReinstatement =
              !!currentInvoiceFromList &&
              !!currentInvoiceFromList.isReinstatementInvoice &&
              currentInvoiceFromList.lineItems.some((item) => item.isPendingReinstatement);

            this.amplitudeService.trackWithOverride({
              eventName: 'invoice_page_loaded',
              detail: `/bop/invoice/${this.route.snapshot.params.id}`,
              payloadOverride: {
                accountId: this.loadedInvoice ? this.loadedInvoice.accountNumber : '',
              },
              useLegacyEventName: true,
            });

            this.invoiceStates = [
              ...new Set(
                associatedPolicies.associatedPolicies.map((policy) => {
                  return policy.BaseState;
                })
              ),
            ];
            return this.handleShowingTopBanner(
              ...this.invoiceBannerService.filterInvoicesAndPoliciesForTopBanner(
                associatedInvoices.associatedInvoices,
                associatedPolicies.associatedPolicies
              )
            );
          }
        );
    } else {
      this.isLoadingInvoice = false;
    }
  }

  checkForInvalidInvoiceState() {
    if (this.showOverdueBox() && !this.getOverdueInvoice()) {
      this.sentryService.notify(
        `Invoice account was in an invalid state: outstanding charges were overdue, but no overdue invoice could be found.`,
        {
          metaData: {
            accountNumber: this.loadedInvoice.accountNumber,
          },
        }
      );
    }
  }

  getInvoiceDueDate(mask: string) {
    return moment.utc(this.loadedInvoice.dueDate).format(mask);
  }

  getPlannedInvoices() {
    this.invoiceService
      .getPlannedInvoices(this.route.snapshot.params.id, this.route.snapshot.queryParams.token)
      .subscribe((res) => {
        this.plannedInvoice = res.associatedInvoices;
        this.updateInvoiceBillingState();
      });
  }

  updateInvoiceBillingState() {
    if (!this.plannedInvoice || !this.loadedInvoice) {
      return;
    }

    const currentInvoice =
      this.plannedInvoice &&
      this.plannedInvoice.filter((invoice) => {
        return invoice.transactionId === this.loadedInvoice.eTransID;
      })[0];

    if (currentInvoice) {
      this.invoiceDraftDay = moment
        .utc(currentInvoice.dueDate)
        .subtract('days', 3)
        .format('dddd, MMMM D, YYYY');
      this.invoiceStatus = currentInvoice.status.toLowerCase();
      this.isInvoiceEarlyPayOff =
        moment.utc(this.dateOfPayment).isBefore(moment.utc(currentInvoice.billDate)) &&
        currentInvoice.amountDue === 0;

      const currentDate = moment.utc();
      const billingDate = moment.utc(currentInvoice.billDate);

      if (billingDate.isAfter(currentDate)) {
        this.billDate = currentDate.format('dddd, MMMM D, YYYY');
      } else {
        this.billDate = billingDate.format('dddd, MMMM D, YYYY');
      }
      this.isLoadingBillDate = false;
    }
  }

  handleShowingTopBanner(
    unpaidInvoices: BackendListInvoice[],
    associatedPolicies: BackendInvoiceAssociatedPolicy[]
  ) {
    Object.keys(this.banners).forEach((key) => {
      this.banners[key] = false;
    });
    const filteredPolicies = this.invoiceBannerService.calculatefilterPolicies(
      associatedPolicies,
      this.accountSummary
    );

    const policyStatus = this.invoiceBannerService.getPolicyStatus(filteredPolicies);

    const numOutstandingInvoices = unpaidInvoices.length;

    this.earliestInvoiceDueDate = moment.min(
      unpaidInvoices.map((invoice) => moment.utc(invoice.dueDate))
    );

    const gracePeriodDays = this.isInvoiceInState('NY') ? 10 : 5;
    this.gracePeriodAfterEarliestDueDate = this.earliestInvoiceDueDate.add(gracePeriodDays, 'days');

    const numPastDueInvoices = this.invoiceBannerService.getNumOfPastDueInvoices(unpaidInvoices);
    if (policyStatus === 'Pending Cancel') {
      this.cancellationDate = this.invoiceBannerService.getCancellationDate(filteredPolicies);

      if (this.invoiceBannerService.isNonPayCancellation(filteredPolicies)) {
        this.banners.nonPayOutstandingPendingCancellation = true;
        return;
      }
      if (this.invoiceBannerService.isUWCancellation(filteredPolicies)) {
        this.banners.uwPendingCancelInvoice = true;
        return;
      }
      this.banners.otherCancelInvoice = true;
      return;
    } else if (policyStatus === 'Canceled') {
      this.cancellationDate = this.invoiceBannerService.getCancellationDate(filteredPolicies);

      if (this.invoiceBannerService.isNonPayCancellation(filteredPolicies)) {
        if (numOutstandingInvoices === 0) {
          this.banners.nonPayCancelNoOutstandingInvoice = true;
          return;
        }
        this.banners.nonPayCanceledOutstandingInvoice = true;
        return;
      }
      if (this.invoiceBannerService.isUWCancellation(filteredPolicies)) {
        // Note: these are underwriting cancellation reasons
        if (numOutstandingInvoices === 0) {
          this.banners.noOutstandingCanceledUWInvoice = true;
          return;
        }

        this.banners.uwOutstandingCanceledInvoice = true;
        return;
      }
      this.banners.otherCancelInvoice = true;
      return;
    } else if (policyStatus === 'Active') {
      if (numPastDueInvoices > 0) {
        this.banners.overDueInvoice = true;
        return;
      }
      if (numOutstandingInvoices > 1) {
        this.banners.outstandingButNotDeliquentActiveInvoice = true;
        return;
      }
      // NOTE: This is the only place in this if block where we don't show a banner
      return;
    } else {
      throw new Error('Unrecognized policy status');
    }
  }

  isBannerShowing() {
    if (this.isPendingReinstatement) {
      return false;
    }

    return Object.keys(this.banners).some((key: keyof InvoicesPolicyPageComponent['banners']) => {
      return !!this.banners[key];
    });
  }

  handleInvoice(res: Invoice | null) {
    if (res) {
      this.loadedInvoice = res as Invoice;
      if (
        this.loadedInvoice.totalInvoiceAmount <= 0 &&
        this.loadedInvoice.status === 'Paid' &&
        !this.allChargesVoided()
      ) {
        this.getPaymentInformation();
      }

      // Create a DD event, so that we can find invoice sessions associated with an account
      if (this.loadedInvoice.accountNumber) {
        datadogRum.addAction('View Invoice', {
          accountID: this.loadedInvoice.accountNumber,
          accountName: this.loadedInvoice.accountName,
        });
      }

      // For deferred invoices, open the modal by default
      if (this.isDeferred()) {
        this.showDeferredModal = true;
      }

      const amount = this.getDefaultAmountDue();
      this.updateInvoiceBillingState();
      this.handlePriorInvoice();

      this.getInvoiceAutopayDetails(res);
      this.loadedInvoice.charges = this.transformLoadedInvoiceCharges();
    } else {
      this.isLoadingInvoice = false;
    }
  }

  getPaymentInformation() {
    this.invoiceService
      .getPaymentInvoiceDetails(
        this.route.snapshot.params.id,
        this.route.snapshot.queryParams.token
      )
      .subscribe((paymentInvoiceDetailsResponse) => {
        if (paymentInvoiceDetailsResponse) {
          this.isPaymentVerified = paymentInvoiceDetailsResponse.stripePaymentVerified;
          this.isReceiptAvailable = true;
          this.dateOfPayment = paymentInvoiceDetailsResponse.paymentReceivedDate;
        }
      });
  }

  getFormattedPaymentDate(dateIsoString: string) {
    // Formats the date as such: Tuesday, February 4, 2020, at 4:18 PM UTC
    return `${moment.utc(dateIsoString).format('dddd, MMMM D, YYYY, [at] h:mm A [UTC]')}`;
  }

  getInvoiceAutopayDetails(invoice: Invoice) {
    this.sub.add(
      this.invoiceService
        .getAutopayStatus(
          invoice.accountNumber,
          this.route.snapshot.params.id,
          this.route.snapshot.queryParams.token
        )
        .subscribe((autopayDetails) => {
          this.autopayDetails = autopayDetails;
        })
    );
  }

  reloadAutopayDetails() {
    this.autopayDetails = null;
    // Give the service time to update, and then reload autopay details
    setTimeout(() => this.getInvoiceAutopayDetails(this.loadedInvoice), 2000);
  }

  unenrollThroughModal() {
    this.unenrollErrorMessage = '';
    this.isProcessingUnenroll = true;
    this.sub.add(
      this.invoicePaymentService.unenrollAutopay(this.loadedInvoice.accountNumber).subscribe(
        (result) => {
          this.amplitudeService.track({
            eventName: 'autopay_unenroll_success',
            detail: '',
            useLegacyEventName: true,
          });

          this.showUnenrollAutopayModal = false;
          this.isProcessingUnenroll = false;

          this.reloadAutopayDetails();
        },
        (err) => {
          this.amplitudeService.track({
            eventName: 'autopay_unenroll_error',
            detail: '',
            useLegacyEventName: true,
          });

          this.isProcessingUnenroll = false;
          this.unenrollErrorMessage = 'We’re sorry, an error has occurred. Please try again.';
        }
      )
    );
  }

  handlePriorInvoice() {
    if (this.getOutstandingAmount() > this.getInvoiceAmount()) {
      const { invoiceId, eTransID } = this.loadedInvoice.priorInvoiceDetails;

      if (invoiceId && eTransID) {
        this.invoiceService.getInvoice(invoiceId, eTransID).subscribe((response: Invoice) => {
          this.previousInvoice = response;
          this.isLoadingInvoice = false;
        });
      } else {
        // If we're on the first invoice in the account then there's no prior invoice.
        this.isLoadingInvoice = false;
      }
    } else {
      this.isLoadingInvoice = false;
    }
  }

  ngOnDestroy() {
    this.showZendeskWidget();
    this.sub.unsubscribe();
  }

  getDefaultAmountDue() {
    if (this.getInvoiceAmount() <= 0 && this.getOutstandingAmount() > 0) {
      return this.getOutstandingAmount();
    }

    return this.getInvoiceAmount();
  }

  private hideZendeskWidget() {
    (<any>document).querySelector('body').classList.add('body__no-zendesk');
  }

  private showZendeskWidget() {
    (<any>document).querySelector('body').classList.remove('body__no-zendesk');
  }

  openStripePaymentModal() {
    this.isProcessing = false;
    this.paymentFormComponent.clearForm();
    this.showStripePaymentModal = true;
  }

  openStripeUpdatePaymentModal() {
    this.isUpdatingPayment = true;
    this.openStripePaymentModal();
  }

  openStripePayoffPaymentModal() {
    this.isPayoffPayment = true;
    this.openStripePaymentModal();
  }

  getOverdueInvoice() {
    if (this.associatedInvoices) {
      return this.associatedInvoices.associatedInvoices.find((invoice) => {
        const dueDate = moment.utc(invoice.dueDate);
        const isPastDue = moment.utc().startOf('day').isAfter(dueDate);
        return invoice.amountDue && isPastDue;
      });
    }
    return null;
  }

  closeStripePaymentModal() {
    this.isInitialAutopay = false;
    this.isPayoffPayment = false;
    this.showStripePaymentModal = false;
    this.paymentUpdateSuccessful = false;
    this.isUpdatingPayment = false;
    this.isUpdateOnly = false;
  }

  getPaymentInvoiceId() {
    if (!this.loadedInvoice) {
      return '';
    }

    // Logic is required because billing-center glitches if an invoice ID is passed that does not have an amount due.
    if (this.loadedInvoice.totalInvoiceAmount) {
      return this.loadedInvoice.invoiceNumber;
    }

    let invoiceWithAmountDue: BackendListInvoice | undefined;
    if (this.associatedInvoices) {
      invoiceWithAmountDue = this.associatedInvoices.associatedInvoices.find(
        (invoice) => !!invoice.amountDue
      );
    }
    if (this.plannedInvoice && !invoiceWithAmountDue) {
      invoiceWithAmountDue = this.plannedInvoice[0];
    }

    return invoiceWithAmountDue ? invoiceWithAmountDue.invoiceNumber : '';
  }

  makeCardPayment() {
    this.amountPaid = this.getAmountToPay();
    const invoiceNumber = this.getPaymentInvoiceId();
    if (!invoiceNumber) {
      this.sentryService.notify(
        'Tried to get invoice ID when making a payment, but no invoice was found',
        {
          severity: 'error',
          metaData: {
            invoiceNumber: this.route.snapshot.params.id,
          },
        }
      );
      this.handleServerErrors(new Error('Could not determine invoice ID'));
      return;
    }

    const ob$: Observable<InvoicePaymentResponse> =
      this.invoicePaymentService.processCreditCardPayment(
        this.paymentFormComponent.getCardNumber(),
        {},
        this.paymentFormComponent.getAutopayStatus(),
        this.loadedInvoice.accountNumber,
        invoiceNumber,
        this.getAmountToPay(),
        this.route.snapshot.queryParams.token,
        this.route.snapshot.params.id
      );
    ob$.subscribe(
      () => this.handlePaymentSuccess(),
      (err) => this.handleServerErrors(err)
    );
  }

  makeBankPayment() {
    this.amountPaid = this.getAmountToPay();
    const invoiceNumber = this.getPaymentInvoiceId();
    if (!invoiceNumber) {
      this.sentryService.notify(
        'Tried to get invoice ID when making a payment, but no invoice was found',
        {
          severity: 'error',
          metaData: {
            invoiceNumber: this.route.snapshot.params.id,
          },
        }
      );
      this.handleServerErrors(new Error('Could not determine invoice ID'));
      return;
    }

    const bankData: CreateTokenBankAccountData = this.paymentFormComponent.generateBankData();
    const ob$: Observable<InvoicePaymentResponse> = this.invoicePaymentService.processBankPayment(
      bankData,
      this.paymentFormComponent.getAutopayStatus(),
      this.loadedInvoice.accountNumber,
      invoiceNumber,
      this.getAmountToPay(),
      this.route.snapshot.queryParams.token,
      this.route.snapshot.params.id
    );
    ob$.subscribe(
      () => this.handlePaymentSuccess(),
      (err) => this.handleServerErrors(err)
    );
  }

  updateCardPayment() {
    const stripeToken$: Observable<string> = this.invoicePaymentService.createStripeCreditCardToken(
      this.paymentFormComponent.getCardNumber(),
      {},
      !!this.autopayDetails && this.autopayDetails.autopayEnrolled,
      this.getAmountToPay()
    );

    this.sendPaymentUpdate(stripeToken$, 'card').subscribe(
      () => {
        return this.handlePaymentSuccess(true);
      },
      (err) => this.handleServerErrors(err)
    );
  }

  updateBankPayment() {
    const bankData: CreateTokenBankAccountData = this.paymentFormComponent.generateBankData();

    const stripeToken$: Observable<string> =
      this.invoicePaymentService.createStripeBankToken(bankData);

    this.sendPaymentUpdate(stripeToken$, 'ach').subscribe(
      () => {
        return this.handlePaymentSuccess(true);
      },
      (err) => this.handleServerErrors(err)
    );
  }

  sendPaymentUpdate(
    stripeToken$: Observable<string>,
    paymentType: PaymentType
  ): Observable<InvoicePaymentResponse> {
    const result$: Observable<InvoicePaymentResponse> = stripeToken$.pipe(
      flatMap((token: string) => {
        return this.invoicePaymentService.updatePaymentInformation({
          accountNumber: this.loadedInvoice.accountNumber,
          paymentId: token,
          paymentType: paymentType,
        });
      })
    );
    return result$;
  }

  handlePaymentFormSubmit() {
    // TODO(merge note): can be a lot simpler...
    if (this.isInitialAutopay && !this.hasAutopayPaymentDue()) {
      // This makes a separate API call which does *not* create a payment
      this.enrollAutopayWithoutPayment();
    } else if (this.isInitialAutopay && this.hasAutopayPaymentDue()) {
      // Enroll the user into autopay
      this.paymentFormComponent.paymentForm.patchValue({ autopay: true });
      this.makePaymentOrUpdate();
    } else {
      this.makePaymentOrUpdate();
    }
  }

  makePaymentOrUpdate() {
    this.clearMessages();
    this.isProcessing = true;

    const isOnlyUpdate = this.isUpdatingPayment && !this.hasAutopayPaymentDue();

    if (this.paymentFormComponent.isCardPayment()) {
      isOnlyUpdate ? this.updateCardPayment() : this.makeCardPayment();
    } else {
      isOnlyUpdate ? this.updateBankPayment() : this.makeBankPayment();
    }
  }

  enrollAutopayWithoutPayment() {
    this.isProcessing = true;

    let stripeToken$: Observable<string>;
    let paymentType: string;
    if (this.paymentFormComponent.isCardPayment()) {
      paymentType = 'card';
      stripeToken$ = this.invoicePaymentService.createStripeCreditCardToken(
        this.paymentFormComponent.getCardNumber(),
        {},
        true,
        0
      );
    } else {
      paymentType = 'ach';
      const bankData: CreateTokenBankAccountData = this.paymentFormComponent.generateBankData();

      stripeToken$ = this.invoicePaymentService.createStripeBankToken(bankData);
    }

    this.sub.add(
      stripeToken$
        .pipe(
          switchMap((token: string) =>
            this.invoicePaymentService.enrollAutopay(
              this.loadedInvoice.accountNumber,
              paymentType,
              token
            )
          )
        )
        .subscribe(
          () => {
            this.isProcessing = false;
            this.closeStripePaymentModal();
            this.reloadAutopayDetails();
          },
          (err) => this.handleServerErrors(err)
        )
    );
  }

  clearMessages() {
    this.serverError = '';
    this.paymentSuccessMessage = '';
  }

  handlePaymentSuccess(isUpdate: boolean = false) {
    this.serverError = '';
    this.reloadAutopayDetails();
    this.isProcessing = false;
    if (isUpdate) {
      this.paymentUpdateSuccessful = true;
      return;
    }

    this.trackSuccessfulPayment(
      this.paymentFormComponent.isCardPayment() ? 'creditCard' : 'ach',
      this.paymentFormComponent.getAutopayStatus()
    );

    // Edge case where user makes a second payment on an already paid invoice (payoff payment).
    // In this case, the second payment will always be a full payoff, and it will never include
    // the current invoice (since the earlier payment paid off the invoice).
    // Therefore we should show a warning about the receipt (which will not include that second payment).
    if (this.isReceiptAvailable) {
      this.showSecondPayoffPaymentMessage = true;
    }

    this.paymentSuccessMessage = 'Payment successful.';
    this.banners.nonPayOutstandingPendingCancellation = false;

    // Delay reloading invoice information to give API a moment to update
    setTimeout(() => {
      this.getInvoice();
    }, 2000);
    this.updateInvoiceBillingState();
    this.getPaymentInformation();
    this.closeStripePaymentModal();
  }

  trackSuccessfulPayment(paymentType: string, isAuto: boolean) {
    if (isAuto) {
      this.amplitudeService.track({
        eventName: 'pay_invoice_auto',
        detail: paymentType,
        useLegacyEventName: true,
      });
      this.informService.infoToast(
        'You have enrolled in automatic payments. All future payments will now be drafted three days before the due date.',
        null,
        'Autopay enabled'
        /*
        'Learn more',
        () => (buttonClicked: boolean) => {
          if (buttonClicked) {
            window.open(
              'https://attunehelp.zendesk.com/hc/en-us/articles/360032231151-How-does-EMPLOYERS-bill-',
              '_blank'
            );
          }
        },
        */
      );
    } else {
      this.amplitudeService.track({
        eventName: 'pay_invoice_noauto',
        detail: paymentType,
        useLegacyEventName: true,
      });
    }
  }

  handleServerErrors(serverError: any) {
    this.isProcessing = false;
    // set error messages and then reopen first modal
    if (serverError.code && serverError.message) {
      this.serverError = serverError.message;
    } else if (serverError.error && serverError.error.errors) {
      this.serverError = serverError.error.errors[0];
    } else if (
      ['card_error', 'validation_error', 'invalid_request_error'].includes(serverError.type)
    ) {
      // Stripe errors with messages that are friendly enough to show to users
      // See https://stripe.com/docs/api/errors
      this.serverError = serverError.message;
    } else {
      this.sentryService.notify('Invoice payment received an error in an unexpected format', {
        severity: 'error',
        metaData: {
          errorData: JSON.stringify(serverError),
          invoiceNumber: this.route.snapshot.params.id,
        },
      });
      this.serverError =
        'Oops, seems like there is an issue. If this problem persists, please try again later or call 888-530-4650';
    }
    // TODO(WJC): remove this call, which is now unnecessary since autopay is not a separate modal
    this.openStripePaymentModal();
  }

  hasImminentPaymentDue() {
    // Return true if there is payment due and the due date is within the next three days.
    // This method is expected to also return true if the payment is *overdue*.

    if (!this.loadedInvoice) {
      return false;
    }

    const dueDate = moment.utc(this.loadedInvoice.dueDate);
    const isAlmostDue = moment.utc().isAfter(dueDate.subtract('day', 3));
    return isAlmostDue && this.getAmountDue() > 0;
  }

  hasAutopayPaymentDue() {
    return this.hasImminentPaymentDue() || this.getOutstandingAmount() > 0;
  }

  getAmountToPay(): number {
    if (this.isPendingReinstatement) {
      return this.loadedInvoice.totalInvoiceAmount;
    }

    if (this.isPayoffPayment) {
      return this.getPayoffAmount();
    } else if (this.isInitialAutopay) {
      return this.getOutstandingAmount();
    } else {
      return this.getAmountDue();
    }
  }

  hasDeferredInvoice(): boolean {
    return (
      this.associatedInvoices &&
      this.associatedInvoices.associatedInvoices.some((invoice) => {
        return invoice.isDeferred;
      })
    );
  }

  getCreditCardFeePercentage(): number {
    return this.loadedInvoice.creditCardPercent;
  }

  // When it's available, we want to display the exact dollar amount of the credit card fee to the user
  // because this will give them the most information. However, when the user is setting up auto pay without
  // making a payment, or when there are deferred invoices, we wouldn't know the exact dollar amount of the credit card fee, so in that case we display
  // the percentage
  getCreditCardFee(): number | null {
    if (this.hasDeferredInvoice()) {
      return null;
    }
    if (this.isPayoffPayment) {
      return this.loadedInvoice.creditCardFeePayoffAmount;
    }

    const isPayingSingleInvoice =
      !this.isInitialAutopay &&
      this.associatedInvoices &&
      this.associatedInvoices.associatedInvoices.length > 1 &&
      this.previousInvoice &&
      this.previousInvoice.totalInvoiceAmount === 0;
    // Invoices pending reinstatement *only* allow paying a single invoice
    if (isPayingSingleInvoice || this.isPendingReinstatement) {
      return this.loadedInvoice.creditCardFee;
    }

    return this.loadedInvoice.creditCardFeeOutstandingAmount;
  }

  getAmountPaid() {
    if (this.loadedInvoice.status === 'Paid') {
      return this.loadedInvoice.originalInvoiceAmountBilled;
    } else {
      return this.loadedInvoice.totalInvoiceAmount;
    }
  }

  getInvoiceAmount(): number {
    return this.loadedInvoice.totalInvoiceAmount;
  }

  getOutstandingAmount(): number {
    // Subtract the value of any unpaid, deferred invoices from the outstanding amount
    let outstandingAmount = this.loadedInvoice.totalChargesOutstandingAmount;
    if (this.associatedInvoices) {
      this.associatedInvoices.associatedInvoices.forEach((invoice) => {
        if (invoice.amountDue && invoice.isDeferred) {
          outstandingAmount -= invoice.amountDue;
        }
      });
      if (outstandingAmount < 0) {
        outstandingAmount = 0;
      }
    }

    return outstandingAmount;
  }

  getPayoffAmount() {
    return this.loadedInvoice.payoffAmount;
  }

  allChargesVoided() {
    return this.loadedInvoice.allChargesVoided;
  }

  showOverdueBox() {
    return this.loadedInvoice.isOverdue && this.lastInvoicePaid();
  }

  showOutstandingBox() {
    return (
      this.loadedInvoice.totalChargesOutstandingAmount > this.loadedInvoice.totalInvoiceAmount &&
      !this.isPendingReinstatement &&
      !this.lastInvoicePaid()
    );
  }

  showOverdueSection() {
    return this.showOverdueBox() || this.showOutstandingBox();
  }

  showPayoffSection() {
    return (
      this.loadedInvoice.payoffAmount > 0 &&
      !this.isPendingReinstatement &&
      !this.showOverdueBox() &&
      !this.showOutstandingBox() &&
      !this.isPayoffPaid()
    );
  }

  showNoAccountOpenChargesMessage() {
    return this.getPayoffAmount() <= 0;
  }

  isPayoffPaid() {
    return this.isPayoffPayment && this.paymentSuccessMessage;
  }

  showUpdatePaymentLink() {
    return (
      this.getInvoiceAmount() <= 0 &&
      this.autopayDetails &&
      this.autopayDetails.autopayEnrolled &&
      this.getPayoffAmount() > 0
    );
  }

  onlyShowACHOption() {
    if (!this.loadedInvoice.charges) {
      return false;
    }
    return this.loadedInvoice.charges.some((charge) => charge.lineOfBusiness === HAB_PRODUCT_NAME);
  }

  isInvoiceInState(state: string) {
    return this.invoiceStates && this.invoiceStates.includes(state.toUpperCase());
  }

  toggleDeferredModal(): void {
    this.showDeferredModal = !this.showDeferredModal;
  }

  downloadReceipt() {
    const id = this.route.snapshot.params.id;
    const token = this.route.snapshot.queryParams.token;
    this.isReceiptDownloadRequestInProgress = true;

    this.amplitudeService.track({
      eventName: 'receipt_download_attempt',
      detail: `/bop/invoice/${id}`,
      useLegacyEventName: true,
    });

    const url = getAttuneBopInvoiceReceiptUrl(id, token);
    const fileName = getInvoiceReceiptFileName(id);
    this.documentService.getDocument(url, fileName, 'pdf').subscribe({
      next: () => {
        this.amplitudeService.track({
          eventName: 'static_invoice_receipt_download_success',
          detail: `/bop/invoice/${id}`,
          useLegacyEventName: true,
        });
      },
      error: () => {
        this.amplitudeService.track({
          eventName: 'static_invoice_receipt_download_error',
          detail: `/bop/invoice/${id}`,
          useLegacyEventName: true,
        });
        this.informService.infoToast(
          'Could not download the PDF of this receipt. Please contact our Customer Care Team.'
        );
        this.isReceiptDownloadRequestInProgress = false;
      },
      complete: () => {
        this.isReceiptDownloadRequestInProgress = false;
      },
    });
  }

  getMetadataForInvoice() {
    // TODO: remove feature flag once we flag on in prod.
    if (!environment.showScheduleOfInvoices) {
      return;
    }
    this.isLoadingMetadata = true;

    this.sub.add(
      this.invoiceService
        .getAssociatedAccountSummary(
          this.route.snapshot.params.id,
          this.route.snapshot.queryParams.token
        )
        .subscribe(
          (accountSummary) => {
            this.accountSummary = accountSummary;
            this.isLoadingMetadata = false;
          },
          (error) => {
            // Error is logged to Sentry in service.
            this.isLoadingMetadata = false;
          }
        )
    );
  }

  downloadInsuredBillingGuide() {
    const id = this.route.snapshot.params.id;
    const url = this.insuredBillingGuide;
    const fileName = getInsuredBillingGuideFileName();
    this.documentService.getDocument(url, fileName, 'pdf').subscribe({
      next: () => {
        this.amplitudeService.track({
          eventName: 'invoice_page_insured_guide_download_success',
          detail: `/bop/invoice/${id}`,
          useLegacyEventName: true,
        });
      },
      error: () => {
        this.amplitudeService.track({
          eventName: 'invoice_page_insured_guide_download_failure',
          detail: `/bop/invoice/${id}`,
          useLegacyEventName: true,
        });
        this.informService.infoToast(
          'Could not download billing guide, please contact our Customer Care Team.'
        );
      },
    });
  }

  printPage(): void {
    window.print();
  }

  formatDateLong(date: Date) {
    return moment.utc(date).format('dddd, MMMM D, YYYY');
  }

  isCardProcessingFeeEligible() {
    return this.loadedInvoice && this.loadedInvoice.isCardProcessingFeeEligible;
  }

  private transformLoadedInvoiceCharges(): DisplayInvoiceCharges[] {
    return this.loadedInvoice.charges.map((charge) => {
      // Add a displayLob field that we can use to display label for cyber surplus (different from what is sent from guidewire)
      const displayLob =
        charge.lineOfBusiness === COALITION_CYBER_INVOICE_SURPLUS_GUIDEWIRE_NAME
          ? COALITION_CYBER_INVOICE_SURPLUS_DISPLAY_NAME
          : charge.lineOfBusiness;
      return {
        displayLob,
        ...charge,
      };
    });
  }
}
