import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import { API_V4_BASE } from 'app/constants';
import { catchError } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { CyberProduct } from '../models/cyber-typings.model';

@Injectable({
  providedIn: 'root',
})
export class CoalitionCyberDuplicateBrokerZendeskTicketService {
  constructor(
    private http: HttpClient,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService
  ) {}

  createTicket(product: CyberProduct, accountNumber: string) {
    return this.http
      .post(
        this.cyberZendeskTicketRoute(product, accountNumber),
        {},
        {
          headers: { 'Content-type': 'application/json' },
        }
      )
      .pipe(
        catchError((error) => {
          this.amplitudeService.track({
            eventName: 'create_cyber_cyber_duplicate_broker_zendesk_ticket_error',
            detail: `coalition-${product}`,
          });
          this.sentryService.notify(
            `Coalition Cyber: Error creating zendesk ticket for duplicate broker error`,
            {
              severity: 'error',
              metaData: {
                product: product,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            }
          );
          return observableOf(error);
        })
      );
  }

  private cyberZendeskTicketRoute(product: CyberProduct, accountNumber: string) {
    return `${API_V4_BASE}/coalition/${product}/zendesk/duplicate-broker-ticket/${accountNumber}`;
  }
}
