export const ZENDESK_NEW_REQUEST_LINK = 'https://attunehelp.zendesk.com/hc/en-us/requests/new';
export const EXCESS_PRODUCT_NAME = 'Commercial Excess Liability Policy';
export const BOP_PRODUCT_NAME = 'Business Owners';
export const WC_PRODUCT_NAME = "Workers' Compensation";
export const HAB_PRODUCT_NAME = 'Habitational';
export const CYBER_ADMITTED_PRODUCT_NAME = 'Cyber Liability (Admitted)';
export const CYBER_SURPLUS_PRODUCT_NAME = 'Cyber Liability (Surplus)';
export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];
export const FILTER_STATUS_OPTIONS = [
  { name: 'all', value: 'All' },
  { name: 'billed', value: 'Invoiced' },
  { name: 'billed & past due', value: 'OutstandingAndInvoiced' },
  { name: 'paid', value: 'Paid' },
  { name: 'past due', value: 'Outstanding' },
];
export const MAP_TO_BACKEND_STATUSES: { [key: string]: string[] } = {
  All: [], // For all invoice statuses, we do not provide this filter option on the request to Service-billing. Note: This will not include planned invoices.
  Invoiced: ['Invoiced'],
  OutstandingAndInvoiced: ['Invoiced', 'Outstanding'],
  Paid: ['Paid'],
  Outstanding: ['Outstanding'],
};

export const prettyPaymentPlan = (backendPaymentPlan: BackendPaymentPlan): PaymentPlan => {
  switch (backendPaymentPlan) {
    case 'Monthly 20% Down, 9 Max installments': {
      return '10 Payments';
    }
    case 'Monthly 10% Down, 9 Max installments': {
      return 'Ten Payments, 10% Down';
    }
    case 'Monthly 25% Down, 9 Max installments': {
      return 'Ten Payments, 25% Down';
    }
    case 'Quarterly': {
      return '4 Payments';
    }
    case 'Semi-Annual': {
      return '2 Payments';
    }
    case 'Full Pay': {
      return '1 Payment';
    }
  }
};

export const prettyLineOfBusiness = (lineOfBusiness: string): string => {
  if (lineOfBusiness === 'Business Owners') {
    return 'BOP';
  } else if (lineOfBusiness === 'Commercial Excess Liability Policy') {
    return 'XS';
  } else if (lineOfBusiness === 'Habitational') {
    return 'HAB';
  }

  return lineOfBusiness;
};

export const BIND_GUIDE_FAQS: Faq[] = [
  {
    question: 'How do you bill?',
    answer:
      'Attune is a 100% paperless direct bill general agency, meaning that we deliver invoices to your insured via email using the contact information you provide on the next page. Invoices are sent via email immediately after bind. Brokers can access these invoices (and their real-time payment status) via the “Invoices” tab.',
  },
  {
    question: 'What payment plans do you offer?',
    answer:
      "We offer single payment, quarterly payment, and ten payment plans for businessowners' policies with premiums greater than $530. Policies with premiums lower than $530 must bind on a single payment plan.",
  },
  {
    question: 'What payment methods do you accept?',
    answer:
      'We accept all major credit cards and ACH payments. Both types of payments are accepted for enrollment in autopay. We strongly discourage your insured from making payment by check, as it takes 2-3 weeks for processing.',
  },
  {
    question: 'How do I make changes to the policy after it is bound?',
    answer: `You can process an endorsement online! Navigate to the account that requires the endorsement, click on the “Endorse Policy” button, and select the appropriate policy. This will bring you to a list of endorsements. You can also contact our Customer Care Team <a href="${ZENDESK_NEW_REQUEST_LINK}" target="_blank">here</a>.`,
  },
];
