import { of as observableOf, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { V3QuoteService } from 'app/shared/services/v3-quote-service';
import { BopPolicy } from 'app/features/attune-bop/models/bop-policy';
import { BopPricedQuote } from '../models/bop-priced-quote';
import { AttuneBopExcessQuoteService } from './attune-bop-excess-quote.service';

@Injectable()
export class AttuneBopQuoteRequestService {
  protected quotedSuccess$ = new Subject();
  quoteInProgress = false;

  constructor(
    private excessQuoteService: AttuneBopExcessQuoteService,
    private v3quoteService: V3QuoteService
  ) {}

  requestV3Quote(payload: {
    accountId: string;
    excessUwQuestionsHaveValidAnswers: boolean;
    parentQuoteId: string | undefined | null;
    policy: BopPolicy;
    quoteGameEnabled: boolean;
  }) {
    const {
      accountId,
      parentQuoteId,
      policy,
      excessUwQuestionsHaveValidAnswers,
      quoteGameEnabled,
    } = payload;
    if (quoteGameEnabled) {
      this.quoteInProgress = true;
    }

    return this.v3quoteService.requestQuote(policy, parentQuoteId).pipe(
      mergeMap((resp: BopPricedQuote | ErrorResponse) => {
        if ((<BopPricedQuote>resp)?.id) {
          // Checks if there are any lessors risk documents to be updated and updates accordingly
          return this.v3quoteService
            .updateLessorsRiskDocuments({
              accountId: accountId,
              bopQuote: policy,
              tsRequestId: policy.tsRequestId,
              quoteNumber: (<BopPricedQuote>resp).id,
            })
            .pipe(map(() => resp));
        }
        return observableOf(resp);
      }),
      mergeMap((resp: BopPricedQuote | ErrorResponse) => {
        if (
          !this.isErrorResponse(resp) &&
          !!policy.excessLiabilityOptIn &&
          excessUwQuestionsHaveValidAnswers
        ) {
          const pricedQuote = <BopPricedQuote>resp;
          if (!!pricedQuote && pricedQuote.valid) {
            // If BOP quote succeeds, request an excess quote via Zendesk. NOTE: returns the BOP priced quote.
            return this.excessQuoteService
              .requestQuoteViaZendesk(policy, pricedQuote)
              .pipe(map(() => resp));
          }
        }

        return observableOf(resp);
      })
    );
  }

  getQuotedSuccess() {
    return this.quotedSuccess$.asObservable();
  }

  showQuoteGameSuccess() {
    this.quotedSuccess$.next(true);
  }

  hideQuoteGame() {
    this.quoteInProgress = false;
  }

  isQuoteInProgress() {
    return this.quoteInProgress;
  }

  private isErrorResponse(maybeError: object): maybeError is ErrorResponse {
    return Object.prototype.hasOwnProperty.call(maybeError, 'error');
  }
}
