import { find, flatten, forEach, keys, values } from 'lodash';
import { LM_CPSP_QUOTE_FLOW } from './lm-cpsp-form-steps';
import { LmCpspQuestion, LmCpspComplexEvaluator, LmCpspFormStepPath } from './lm-cpsp-typings';
import { LM_BOP_QUOTE_FLOW } from './lm-bop-form-steps';
import { LmBopQuestion } from './lm-bop-typings';
import { DependencyConfig, LmQuoteFlowStep } from './common-typings';
import { LM_CPSP_DEPENDENCIES } from './lm-cpsp-dependencies';
import { LM_CPSP_HAPPY_PATH_FORM_DATA } from './lm-cpsp-happy-path-form-data';

export const verifyBopQuestionsAreInFlow = () => {
  const questionsInFormFlow = LM_BOP_QUOTE_FLOW.reduce((questions, formStep) => {
    const stepQuestions = flatten(formStep.questions);
    stepQuestions.forEach((question) => {
      questions.add(question);
    });
    return questions;
  }, new Set<LmBopQuestion>());

  values(LmBopQuestion).forEach((questionName: LmBopQuestion) => {
    if (!questionsInFormFlow.has(questionName)) {
      console.warn(`Control '${questionName}' is missing from form flow.`);
    }
  });
};

export const verifyCpspQuestionsAreInFlow = () => {
  const questionsInFormFlow = LM_CPSP_QUOTE_FLOW.reduce((questions, formStep) => {
    const stepQuestions = flatten(formStep.questions);
    stepQuestions.forEach((question) => {
      questions.add(question);
    });
    return questions;
  }, new Set<LmCpspQuestion>());

  values(LmCpspQuestion).forEach((questionName: LmCpspQuestion) => {
    if (!questionsInFormFlow.has(questionName)) {
      console.warn(`Control '${questionName}' is missing from form flow.`);
    }
  });
};

const getDependency = (
  dependenciesDict: Record<string, DependencyConfig<string, string>>,
  dependent: string
) => {
  const depBlock = dependenciesDict[dependent];

  if (!depBlock) {
    console.warn(`No dep block for ${dependent}!`);
    return null;
  }

  if (depBlock.dependency && depBlock.dependency.type === 'SIMPLE') {
    return depBlock.dependency.left.value as string;
  }
};

const getComplexFuncName = (
  dependenciesDict: Record<string, DependencyConfig<string, string>>,
  dependent: string
) => {
  const depBlock = dependenciesDict[dependent];

  if (!depBlock) {
    console.warn(`No dep block for ${dependent}!`);
    return null;
  }

  if (depBlock.dependency && depBlock.dependency.type === 'COMPLEX') {
    return depBlock.dependency.functionName;
  }
};

// Key is the name of the evaluator; value is an array of control names that that evaluator depends on
const cpspComplexEvaluatorDependencies: { [key in LmCpspComplexEvaluator]: LmCpspQuestion[] } = {
  [LmCpspComplexEvaluator.BACKGROUND_CHECKS_DEPENDENCY]: [
    LmCpspQuestion.ENTER_RESIDENCES,
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
  ],
  [LmCpspComplexEvaluator.CITATION_ALCOHOLIC_BEVERAGES_DEPENDENCY]: [
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
    LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
  ],
  [LmCpspComplexEvaluator.CSP_CLASS_IS_0542]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.CSP_CLASS_IS_0570]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.CP_EXTING_SYS_SERV_SEMI_ANNUAL_DEPENDENCY]: [
    LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM,
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.EXCAVATION_OR_TRENCHING_EXCAVATING]: [
    LmCpspQuestion.TRENCHING_EXCAVATING,
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_LIMITED_COOKING]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
    LmCpspQuestion.LIMITED_COOKING_QUESTION,
  ],
  [LmCpspComplexEvaluator.SERVES_ALCOHOL_OR_HAS_LIQUOR_RELATED_CLASS_CODE]: [
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
    LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
  ],
  [LmCpspComplexEvaluator.UNOCC_AREA_GREATER_THAN_50_PERCENT_AND_EITHER_BUILDING_OR_BPP_SELECTED]: [
    LmCpspQuestion.AREA_OCCUPIED_BY_INSURED_PROPERTY,
    LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS,
  ],
  [LmCpspComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K]: [
    LmCpspQuestion.YEAR_BUILT_PROPERTY,
    LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING,
    LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY,
  ],
  [LmCpspComplexEvaluator.GREATER_THAN_25_PERCENT_REVENUE_GENERATED_OFF_PREMISES]: [
    LmCpspQuestion.EXTERNAL_INSURED_REVENUES,
    LmCpspQuestion.ANNUAL_SALES_RECEIPTS,
  ],
  [LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_MA]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_WA]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_MS]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_MS]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K]: [
    LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT,
    LmCpspQuestion.ATTACK_EXTORTION_LIMIT,
    LmCpspQuestion.NETWORK_SECURITY_LIMIT,
  ],
  [LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K]: [
    LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT,
    LmCpspQuestion.ATTACK_EXTORTION_LIMIT,
    LmCpspQuestion.NETWORK_SECURITY_LIMIT,
  ],
  [LmCpspComplexEvaluator.LIMITED_COOKING_CSP_CLASS]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_COMMERCIAL_COOKING_AND_INTEREST_IS_NOT_TENANT]: [
    LmCpspQuestion.INTEREST_PROPERTY,
    LmCpspQuestion.COMMERCIAL_COOKING_QUESTION,
  ],
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_JM_CONSTRUCTION]: [
    LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY,
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_FRAME_NC_CONSTRUCTION]: [
    LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY,
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_WA]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_BUILDING_CVG_AND_CONSTRUCTION_IS_FR_JM_NC_AND_INCIDENTAL_APT_EXISTS]:
    [
      LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY,
      LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
    ],
  [LmCpspComplexEvaluator.PRIOR_COVERAGE_REASON_NEW_ACQUISITION_OR_NONE_OF_THE_ABOVE]: [
    LmCpspQuestion.PRIOR_COVERAGE_REASON,
  ],
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_MNC_MFR_FR_CONSTRUCTION]: [
    LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY,
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.HAS_LOSS_TYPE_PROPERTY]: [],
  [LmCpspComplexEvaluator.HAS_LOSS_TYPE_GENERAL_LIABILITY]: [],
  [LmCpspComplexEvaluator.LIQUOR_LIABILITY_IS_NOT_SELECTED]: [
    LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
  ],
  [LmCpspComplexEvaluator.IS_COMPUTER_CONSULTANT_DOING_E_COMMERCE_OR_IS_MEDICAL_OFFICE]: [
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
    LmCpspQuestion.E_COMMERCE_DESIGN,
  ],
  [LmCpspComplexEvaluator.IN_AL_OR_IS_HOTEL_OR_CMML_CONDO]: [
    LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
    LmCpspQuestion.PRIMARY_RISK_STATE,
  ],
  [LmCpspComplexEvaluator.CSP_CLASS_IS_BUILDING_ONLY]: [
    LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
  ],
  [LmCpspComplexEvaluator.ARMED_SECURITY_GUARDS_IS_NO_OR_RENOVATION_BY_EMP_IS_NO]: [
    LmCpspQuestion.ARMED_SECURITY_GUARDS,
    LmCpspQuestion.RENOVATION_BY_EMP,
  ],
  [LmCpspComplexEvaluator.HAS_SECURITY_PERSONNEL_OR_HAS_SECURITY_SERVICE]: [
    LmCpspQuestion.SECURITY_PERSONNEL,
    LmCpspQuestion.SECURITY_SERVICE,
  ],
};

/**
 * This function logs questions that are out of order, i.e. questions that appear
 * before questions they are dependent upon
 */
const checkQuestionOrderAgainstDependencies = (
  lmQuoteFlow: LmQuoteFlowStep<string, string>[],
  questionNames: string[],
  dependenciesDict: Record<string, DependencyConfig<string, string>>,
  complexEvaluatorDependencies: { [k: string]: string[] | undefined } = {}
) => {
  const missingFromQuestions: string[] = [];
  const missingFromSteps: string[] = [];

  const formStepQuestions = lmQuoteFlow.reduce((questionSet: Set<string>, step) => {
    step.questions.forEach((questionOrList) => {
      if (Array.isArray(questionOrList)) {
        questionOrList.forEach((question) => {
          if (!questionNames.includes(question)) {
            missingFromQuestions.push(question);
          }
          questionSet.add(question);
          const dependency = getDependency(dependenciesDict, question);
          if (dependency && !questionSet.has(dependency)) {
            console.warn(`Out of order: ${question} appears before ${dependency}`);
          }
          const depFuncName = getComplexFuncName(dependenciesDict, question);
          if (depFuncName) {
            const cDeps = complexEvaluatorDependencies[depFuncName];
            if (
              cDeps &&
              !cDeps.every((dep) => {
                return questionSet.has(dep);
              })
            ) {
              console.warn(
                `Out of order: ${question} appears before ${cDeps.filter(
                  (dep) => !questionSet.has(dep)
                )}`
              );
            }
          }
        });
      } else {
        if (!questionNames.includes(questionOrList)) {
          missingFromQuestions.push(questionOrList);
        }
        questionSet.add(questionOrList);
        const dependency = getDependency(dependenciesDict, questionOrList);
        if (dependency && !questionSet.has(dependency)) {
          console.warn(`Out of order: ${questionOrList} appears before ${dependency}`);
        }
        const depFuncName = getComplexFuncName(dependenciesDict, questionOrList);
        if (depFuncName) {
          const cDeps = complexEvaluatorDependencies[depFuncName];
          if (
            cDeps &&
            !cDeps.every((dep) => {
              return questionSet.has(dep);
            })
          ) {
            console.warn(
              `Out of order: ${questionOrList} appears before ${cDeps.filter(
                (dep) => !questionSet.has(dep)
              )}`
            );
          }
        }
      }
    });
    return questionSet;
  }, new Set());

  questionNames.forEach((question) => {
    if (!formStepQuestions.has(question)) {
      missingFromSteps.push(question);
    }
  });

  console.log({ missingFromQuestions, missingFromSteps });
};

export const checkCpspQuestionOrder = () =>
  checkQuestionOrderAgainstDependencies(
    LM_CPSP_QUOTE_FLOW,
    values(LmCpspQuestion),
    LM_CPSP_DEPENDENCIES,
    cpspComplexEvaluatorDependencies
  );

export const validateCpspHappyPathData = () => {
  const inWrongStep: { controlName: LmCpspQuestion; inStep: LmCpspFormStepPath }[] = [];
  const missingFromStep: { controlName: LmCpspQuestion; missingFromStep: LmCpspFormStepPath }[] =
    [];

  // Check for controls that are in the wrong step in the happy path data
  forEach(LM_CPSP_HAPPY_PATH_FORM_DATA, (controls, stepName: LmCpspFormStepPath) => {
    const formStepConfig = find(LM_CPSP_QUOTE_FLOW, { formPath: stepName });
    if (!formStepConfig) {
      console.warn(`CPSP happy path: step ${stepName} not found in form steps`);
      return;
    }
    const formStepQuestions = flatten(formStepConfig.questions);
    const seenControls = new Set<LmCpspQuestion>();
    keys(controls).forEach((controlName: LmCpspQuestion) => {
      if (seenControls.has(controlName)) {
        console.warn(`CPSP happy path: duplicate ${controlName} key in step ${stepName}`);
      }
      seenControls.add(controlName);
      if (!formStepQuestions.includes(controlName)) {
        inWrongStep.push({ controlName, inStep: stepName });
      }
    });
  });

  forEach(LM_CPSP_QUOTE_FLOW, (formStepPath) => {
    const stepName = formStepPath.formPath;
    const happyPathStep = LM_CPSP_HAPPY_PATH_FORM_DATA[stepName];
    const happyPathQuestions = keys(happyPathStep);
    const formStepQuestions = flatten(formStepPath.questions);
    formStepQuestions.forEach((controlName: LmCpspQuestion) => {
      if (!happyPathQuestions.includes(controlName)) {
        missingFromStep.push({ controlName, missingFromStep: stepName });
      }
    });
  });

  console.log('validateCpspHappyPathData output:', { missingFromStep, inWrongStep });
};
