import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';
import { AdditionalCoverages, CoverageOptions } from 'app/hab/constants';

@Component({
  selector: 'app-hab-additional-coverages-page',
  templateUrl: './hab-additional-coverages-page.component.html',
})
export class HabAdditionalCoveragesPageComponent {
  form: UntypedFormGroup;
  formPath = 'additionalCoverages';
  public AdditionalCoverages = AdditionalCoverages;
  public CoverageOptions = CoverageOptions;

  constructor(protected formService: HabQuoteFormService) {
    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
    if (!this.form) {
      throw new Error(`formPath (${this.formPath}) not found`);
    }
  }

  hasSubmitted() {
    return this.formService.submitted;
  }
}
