import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { brokerEmailTypes } from 'app/constants';
import { shouldShowInvalid, getFirstError } from 'app/shared/helpers/form-helpers';
import * as _ from 'lodash';

@Component({
  selector: 'app-broker-contacts',
  templateUrl: './form-field-broker-contacts.component.html',
})
export class FormFieldBrokerContactsComponent {
  @Input() form: UntypedFormArray;
  @Input() submitted: boolean;
  @Input() describedBy: string;
  @Input() isUpdating: boolean;
  @Input() contacts: Record<string, { email: string }>;

  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  @Output() add: EventEmitter<void> = new EventEmitter<void>();

  NEW_EMAIL_OPTION = '<Add new contact>';
  emailTypes = _.map(brokerEmailTypes, (value, key) => ({ name: value, id: key }));

  contactsList() {
    const simpleContacts = _.mapValues(this.contacts, (value: { email: string }) => value.email);
    return {
      ...simpleContacts,
      '': this.NEW_EMAIL_OPTION,
    };
  }

  removeText() {
    return this.isUpdating ? 'Unsubscribe from all' : 'Remove';
  }

  isNewContact(index: number) {
    return !(<UntypedFormControl>this.form.get(`${index}.contactId`)).value;
  }

  hasGeneralError() {
    return this.submitted && this.form.errors;
  }

  getGeneralError() {
    const errors = this.form.errors;
    return errors && Object.values(errors)[0];
  }

  hasEmailTypesError(index: number) {
    return shouldShowInvalid(`${index.toString()}.emailTypes`, this.form, this.submitted);
  }

  hasContactError(index: number) {
    return shouldShowInvalid(index.toString(), this.form, this.submitted);
  }

  getContactError(index: number) {
    return getFirstError(<UntypedFormGroup>this.form.get(index.toString()));
  }
}
