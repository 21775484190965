import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { map as observableMap, shareReplay, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { WcPolicyDocument } from '../models/wc-priced-quote';
import { DigitalCarrierPolicyService } from '../../../features/digital-carrier/services/digital-carrier-policy.service';
import { InformService } from '../../../core/services/inform.service';
import { SentryService } from '../../../core/services/sentry.service';
import {
  DigitalCarrierPolicyDetails,
  ProductCombination,
} from '../../../features/digital-carrier/models/types';
import { US_DATE_ALL_DIGIT_MASK } from '../../../constants';
import { ProductEligibility } from '../../../shared/services/naics.service';
import { getEmployersWCPolicyDocumentFileName } from '../../../features/documents/models/document-file-names.model';
import { DocumentService } from '../../../features/documents/services/document.service';
import {
  getEmployersWCPolicyDocumentUrl,
  getEmployersWCRenewalPolicyDocumentUrl,
} from '../../../features/documents/models/document-urls.model';

// Note: all first term policies with have `00` as their term
const WC_NEW_BUSINESS_TERM = '00';

@Component({
  selector: 'app-wc-policy-pane.policy-pane.policy-pane__wc',
  templateUrl: './wc-policy-pane.component.html',
  providers: [CurrencyPipe],
})
export class WcPolicyPaneComponent implements OnInit, OnDestroy {
  wcPolicyUuid: string;
  wcPasQuoteUuid: string;
  wcPolicyTerm: string;
  wcPolicy$: Observable<DigitalCarrierPolicyDetails | null | undefined>;
  policyApiDocsLoading: 'loading' | 'success' | 'error' | 'not-ready' = 'loading';
  policyDocument: WcPolicyDocument | null = null;
  policyApiDocuments: WcPolicyDocument[] | null = null;
  employersDigitalProduct: ProductCombination = {
    pasSource: 'employers',
    product: 'wc',
  };

  // `eligibilities` is determined by AskKodiak for all insurance products
  // whereas `productAvailabilities` is determined in SQ for insurance products
  // on DCP, e.g. LM BOP. An insurance product may be eligible for a given state
  // and classcode, i.e. within appetite, but might not be available to a
  // broker.
  eligibilities: ProductEligibility;

  policyDocumentDownload$: Observable<any>;

  private sub: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private informService: InformService,
    private sentryService: SentryService,
    private digitalCarrierPolicyService: DigitalCarrierPolicyService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    this.getWorkersCompQuoteOnRouteChanges();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getWorkersCompQuoteOnRouteChanges() {
    this.wcPolicy$ = this.route.params.pipe(
      switchMap((params) => {
        // policyIdentifier will be either the policy_number or the policy uuid
        // policy_number should not include term, to find policy in policy_terms
        let policyIdentifier = params['policyIdentifier'];
        // WC policy numbers are stored in policy_terms prepended with EIG
        if (policyIdentifier.length <= 9) {
          policyIdentifier = `EIG${params['policyIdentifier']}`;
        }

        return this.digitalCarrierPolicyService.getPolicyDetails(
          policyIdentifier,
          this.employersDigitalProduct
        );
      }),
      observableMap((wcPolicy: DigitalCarrierPolicyDetails | null) => {
        if (wcPolicy === null) {
          console.error('Error loading EMPLOYERS Policy');
          return;
        }
        this.wcPolicyUuid = wcPolicy.uuid;
        this.wcPasQuoteUuid = wcPolicy.pasQuoteId;
        this.wcPolicyTerm = wcPolicy.term;

        if (wcPolicy.docsStatus === 'error') {
          console.error('Error loading EMPLOYERS Policy API docs');
        } else {
          // The policy documents
          const docsResponse = wcPolicy.documents;

          // Note: if policy is first term, document will be a new business document
          if (wcPolicy.term === WC_NEW_BUSINESS_TERM) {
            this.policyDocument =
              _.find(docsResponse, (doc: WcPolicyDocument) => {
                return doc.documentType === 'New Business';
              }) || null;
          } else {
            // Note: if the policy is a renewal, use the term to find its document.
            // The documents returned by the backend will be those fetched from
            // Employers, using the policy number + term, so these are specific
            // to the policy term
            const policyTermNumber = wcPolicy.policyNumber + wcPolicy.term;

            this.policyDocument =
              _.find(docsResponse, (doc: WcPolicyDocument) => {
                return doc.policyNumber === policyTermNumber && doc.documentType === 'Renewal';
              }) || null;
          }
        }
        return wcPolicy;
      }),
      shareReplay()
    );
    this.sub.add(
      this.wcPolicy$.subscribe(
        () => {},
        (e: any) => {
          console.error('Error loading Employers Policy:', e);
        }
      )
    );
    this.sub.add(
      this.wcPolicy$.subscribe((wcPolicy) => {
        if (!this.policyDocument || !wcPolicy) {
          return;
        }
        let url;
        const fileName = getEmployersWCPolicyDocumentFileName(
          wcPolicy.policyNumber,
          wcPolicy.term,
          this.policyDocument.documentType
        );
        if (this.wcPolicyTerm === WC_NEW_BUSINESS_TERM) {
          url = getEmployersWCPolicyDocumentUrl(
            this.wcPasQuoteUuid,
            this.policyDocument.documentId
          );
        } else {
          url = getEmployersWCRenewalPolicyDocumentUrl(
            this.wcPolicyUuid,
            this.policyDocument.documentId
          );
        }
        this.policyDocumentDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
      })
    );
  }

  getUtcDate(dateString: string) {
    return moment.utc(dateString).format(US_DATE_ALL_DIGIT_MASK) || '---';
  }
}
