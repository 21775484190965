<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote"
  [product]="productType"
  pasSource="coalition"
  [steppedFormService]="formService"
  [disableNavigationToStep]="true"
  [accountId]="accountId"
  [insAccount]="insAccount"
  [sidebarTitle]="formService.currentStep.displayName"
></app-quote-stepped-sidebar>

<div class="app-page-form-scrollable-wrapper app-page-form-with-sidebar-wrapper">
  <div class="app-page-form">
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="loadPreviousStep($event)"
      (keyup.enter)="loadPreviousStep($event)"
    >
      <span class="icon-full-arrow-left button-icon" role="presentation"></span>Previous step
    </a>
    <h1
      [ngClass]="{ 'form-header-below-link': !isFirstStep() && formService.getSteps().length > 1 }"
      class="h1"
    >
      {{ formService.currentStep.displayName }}
    </h1>

    <main *ngIf="loadingQuoteDetails" class="placeholder-form-container">
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-submit"></div>
    </main>

    <main *ngIf="!loadingQuoteDetails">
      <form [formGroup]="formService.form" (ngSubmit)="submit()">
        <app-carrier-partner-servicing-info
          *ngIf="formService.currentStep.slug === 'working-with-coalition'"
          carrier="coalition"
          product="Cyber Liability"
        ></app-carrier-partner-servicing-info>
        <section
          class="js-attestation-section"
          *ngIf="formService.currentStep.slug === 'declinationAttestation'"
        >
          <h2 class="h2">Please agree to the following:</h2>
          <app-form-field-checkbox
            [form]="formService.form.get('declinationAttestation')"
            [inputId]="'attestation'"
            [nameOfFormControl]="'attestation'"
            [innerHtmlLabelText]="formService.attestationForm.attestationText"
            [submitted]="submitted"
          >
          </app-form-field-checkbox>
          <ng-container
            *ngIf="getCarriersFormArray() && getCarriersFormArray().controls.length > 0"
          >
            <h2 class="h2">Summary of due diligence effort</h2>
            <p>
              Please enter the
              {{ arabicNumeralToEnglish(getCarriersFormArray().controls.length) }} declination(s)
              secured as part of your due diligent effort
            </p>
            <ng-container *ngFor="let group of getCarriersFormArray().controls; let index = index">
              <div class="attestation-form-group">
                <p class="p__gray p__no-margin p__capitalize">
                  Carrier {{ arabicNumeralToEnglish(index + 1) }}
                </p>
                <div class="attestation-form-row">
                  <app-form-field-text
                    *ngFor="let question of formService.attestationForm.simpleTextQuestions"
                    class="attestation-form-child"
                    [form]="group"
                    [inputId]="question.id"
                    [nameOfFormControl]="question.id"
                    [labelText]="question.labelText"
                    [submitted]="submitted"
                  ></app-form-field-text>
                  <app-form-field-text
                    *ngIf="group.get('date')"
                    class="attestation-form-child"
                    [form]="group"
                    inputId="date"
                    nameOfFormControl="date"
                    labelText="Date of Contact"
                    [submitted]="submitted"
                    appDateMask
                  ></app-form-field-text>
                </div>

                <div class="attestation-form-row">
                  <app-form-field-textarea
                    *ngIf="group.get('reason')"
                    class="attestation-form-child"
                    [form]="group"
                    inputId="reason"
                    nameOfFormControl="reason"
                    labelText="The reason for declination by the Insurer was as follows:"
                    [submitted]="submitted"
                  ></app-form-field-textarea>
                  <app-form-field-radio-traditional
                    *ngIf="group.get('resultCode')"
                    class="attestation-form-child"
                    [form]="group"
                    [inputId]="'resultCode-' + index"
                    nameOfFormControl="resultCode"
                    [labelText]="formService.attestationForm.carrierQuestions.resultCode.labelText"
                    [submitted]="submitted"
                    [options]="formService.attestationForm.carrierQuestions.resultCode.value"
                    [optionDescriptions]="
                      formService.attestationForm.carrierQuestions.resultCode.display
                    "
                  ></app-form-field-radio-traditional>
                  <app-form-field-radio-traditional
                    *ngIf="group.get('declinationReason')"
                    class="attestation-form-child"
                    [form]="group"
                    [inputId]="'declinationReason-' + index"
                    nameOfFormControl="declinationReason"
                    labelText="Declination Reason"
                    [submitted]="submitted"
                    [options]="DECLINATION_REASONS"
                    [optionDescriptions]="DECLINATION_REASONS_DISPLAY"
                  ></app-form-field-radio-traditional>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isInTexasState() && isSurplusQuote()">
            <h2>Broker Texas License Number</h2>
            <app-form-field-text
              inputId="licenseNumber"
              [submitted]="submitted"
              [form]="formService.form.get('bind')"
              labelText="Please enter license number below"
              nameOfFormControl="licenseNumber"
              errorText="Please enter your license number."
              showErrorText="true"
            >
            </app-form-field-text>
          </ng-container>
        </section>
        <ng-container *ngIf="formService.currentStep.slug === 'bind'">
          <app-form-field-text
            inputId="effectiveDate"
            [submitted]="submitted"
            [form]="formService.form.get('bind')"
            labelText="Effective date"
            nameOfFormControl="effectiveDate"
            [errorText]="effectiveDateErrorMessage()"
            [readonly]="isRenewal"
            [tooltipText]="isRenewal ? 'The effective date of renewals cannot be changed.' : null"
            showErrorText="true"
            appDateMask
          >
          </app-form-field-text>

          <ng-container *ngIf="isSurplusQuote()">
            <h3>Insured contact information</h3>
            <p>
              Insured contact information will be used for billing purposes and completion of
              required surplus filing processes only.
            </p>
            <div class="attestation-form-row">
              <app-form-field-text
                inputId="insuredFirstName"
                class="attestation-form-child"
                [submitted]="submitted"
                [form]="formService.form.get('bind')"
                labelText="Insured first name"
                nameOfFormControl="insuredFirstName"
                placeholder=""
                showErrorText="true"
              >
              </app-form-field-text>
              <app-form-field-text
                inputId="insuredLastName"
                class="attestation-form-child"
                [submitted]="submitted"
                [form]="formService.form.get('bind')"
                labelText="Insured last name"
                nameOfFormControl="insuredLastName"
                placeholder=""
                showErrorText="true"
              >
              </app-form-field-text>
            </div>
          </ng-container>
          <app-form-field-text
            inputId="insuredEmail"
            [submitted]="submitted"
            [form]="formService.form.get('bind')"
            labelText="Insured email address"
            questionNote="Please ensure that you enter an accurate insured email address to avoid delinquency."
            nameOfFormControl="insuredEmail"
            placeholder=""
            errorText="Please type a valid email address"
            showErrorText="true"
            appRemoveWhitespaceMask
          >
          </app-form-field-text>

          <div>
            <div class="bind-quote-total-lineitem js-quotePremium">
              <span>Premium</span> <span> $ {{ quote.premium }}</span>
            </div>

            <div *ngFor="let surcharge of surcharges" class="bind-quote-total-lineitem js-quoteTax">
              <span>{{ surcharge.label }}</span> <span> $ {{ surcharge.amount.toFixed(2) }}</span>
            </div>

            <div class="bind-quote-total bind-quote-total-lineitem">
              Total
              <div class="big-total">
                <span>$</span>
                <span class="js-total-dollars">{{ totalDollars | number: '2.0-0' }}</span>
                <span class="big-total-cents js-total-cents">.{{ totalCents }}</span>
              </div>
            </div>
            <p class="p__field-description-text">
              * Please refer to the quote letter for calculated taxes, fees, surcharges and payment
              plan installment fees which may be applicable to this quotation.
            </p>
          </div>
        </ng-container>

        <div class="validation" role="alert" *ngIf="submitted && !formService.isCurrentStepValid()">
          <p
            class="validation-message"
            [innerHTML]="
              formService.getValidationMessage() || 'Please fill out all required fields.'
            "
          ></p>
        </div>
        <div class="nav-button-group nav-button-group__left nav-button-group__centered">
          <button
            class="button button__primary js-submit-bind-form-button"
            type="submit"
            [ngClass]="{ button__discouraged: !formService.isCurrentStepValid() }"
          >
            {{ formService.currentStep.nextButtonText || 'Next' }}
          </button>
          <ng-container *ngIf="formService.currentStep.slug === 'working-with-coalition'">
            <app-form-field-checkbox
              inputId="doNotShowAgain"
              labelText="Don't show me this again"
              [form]="formService.form.get('workingWithCoalition')"
              nameOfFormControl="doNotShowAgain"
              cssClass="form-field__no-margin ml-3"
            ></app-form-field-checkbox>
          </ng-container>
        </div>
      </form>
    </main>
  </div>

  <!-- TODO: Currently stretches to the left -->
  <section class="app-page-form-side-rail">
    <h5>Frequently asked</h5>
    <app-faqs-list [faqs]="faqs"></app-faqs-list>
  </section>
</div>

<ng-container *ngIf="showProgressBar">
  <app-binding-animation [showProgressBar]="true" [finish$]="bindSuccess$"> </app-binding-animation>
</ng-container>

<app-quote-error-modal
  [open]="errorModalOpen"
  (closeQuoteErrorModal)="closeOrRetry($event)"
  errorHeader="Error"
  [errorType]="typeOfError"
>
</app-quote-error-modal>
