<app-quote-stepped-sidebar
  *ngIf="isFormInitialized | async"
  class="app-page-sidebar app-page-sidebar__quote"
  [product]="digitalCarrierProduct.product"
  pasSource="coalition"
  [steppedFormService]="formService"
  [disableNavigationToStep]="true"
  [accountId]="accountId"
  [insAccount]="insuredAccount"
  [sidebarTitle]="isEditing ? 'Edit quote' : 'New quote'"
  [fillInHappyPath]="fillInHappyPath.bind(this)"
>
</app-quote-stepped-sidebar>
<div *ngIf="isFormInitialized | async" class="app-page-form-scrollable-wrapper">
  <div class="app-page-form-with-sidebar-wrapper">
    <ng-container *ngFor="let step of formService.formDslSteps">
      <ng-container *ngIf="isCurrentStep(step.slug)">
        <div class="app-page-form app-page-form__small-padding" content>
          <a
            *ngIf="!isFirstStep()"
            class="emphasized-link form-previous-link"
            (click)="loadPreviousStep($event)"
            (keyup.enter)="loadPreviousStep($event)"
          >
            <span class="icon-full-arrow-left button-icon" role="presentation"></span>Previous step
          </a>
          <form [formGroup]="formService.form" (ngSubmit)="submitForm($event)" novalidate>
            <main *ngFor="let step of formService.formDslSteps">
              <div>
                <section *ngIf="isCurrentStep(step.slug)">
                  <div>
                    <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">
                      {{ formService.getCurrentStep().displayName }}
                    </h1>
                    <h3 *ngIf="!isFirstStep()" class="h3">{{ market }} Market</h3>
                  </div>
                  <ng-container
                    *ngIf="
                      isCurrentStep('underwriting') && showDefaultedUnderwritingQuestionsTooltip
                    "
                  >
                    <app-dialog-box class="dialog-text__margin-bottom" type="primary">
                      These underwriting questions were initially defaulted based on the typical
                      responses for an applicant with less than $1M in revenue. Please ensure that
                      you review and certify each to be accurate.
                    </app-dialog-box>
                    <br />
                  </ng-container>
                  <app-form-dsl-form
                    [formTree]="stepTree"
                    [form]="formService.form.get(step.formPath)"
                    [readonly]="isSubmittingForm || isLoadingNextStep"
                    [submitted]="submitted()"
                  >
                  </app-form-dsl-form>
                </section>
              </div>
            </main>
          </form>
        </div>

        <!-- Rail cards -->
        <div
          *ngIf="!isQuickQuote && isQuoteHelperVideoEnabled && isCurrentStep('policy-info')"
          class="form-flow-rail"
        >
          <div class="multi-form-item form-flow-rail-card form-flow-rail-card__padded">
            <div>
              <a
                (click)="setShowQuoteHelperVideo(true)"
                (keyup.enter)="setShowQuoteHelperVideo(true)"
              >
                <img src="/assets/img/cyber_explainer_thumbnail.png" alt="" />
              </a>
              <h3>Learn how everyday tools create cyber risk, such as:</h3>
            </div>
            <ul class="help-video-list">
              <li class="help-video-list-item">
                <div class="help-video-list-item-content">Email</div>
              </li>
              <li class="help-video-list-item">
                <div class="help-video-list-item-content">Wire transfers</div>
              </li>
              <li class="help-video-list-item">
                <div class="help-video-list-item-content">Credit card payments</div>
              </li>
            </ul>
            <p>
              Be sure to see our guide to overcoming client objections and why your customers need
              enhanced cyber protection
              <a
                href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/addressing+client+objections.pdf"
                target="_blank"
              >
                here
              </a>
            </p>
          </div>
          <div
            *ngIf="cyberMarketingConfig"
            class="multi-form-item form-flow-rail-card form-flow-rail-card__padded"
          >
            <p class="p__underlined p__bold">{{ cyberMarketingConfig.header }}</p>
            <p [innerHTML]="cyberMarketingConfig.body"></p>
            <a [href]="cyberMarketingConfig.case" target="_blank"> Download case study </a>
          </div>
        </div>
        <div
          *ngIf="!isQuickQuote && isQuoteHelperVideoEnabled && isCurrentStep('underwriting')"
          class="form-flow-rail"
        >
          <div class="multi-form-item form-flow-rail-card form-flow-rail-card__padded">
            <div>
              <a
                (click)="setShowQuoteHelperVideo(true)"
                (keyup.enter)="setShowQuoteHelperVideo(true)"
                ><img src="/assets/img/cyber_explainer_thumbnail.png" alt=""
              /></a>
              <h3>
                Watch our tips and tricks video for answering underwriting questions related to:
              </h3>
            </div>
            <ul class="help-video-list">
              <li class="help-video-list-item">
                <div class="help-video-list-item-content">PII/PHI</div>
              </li>
              <li class="help-video-list-item">
                <div class="help-video-list-item-content">
                  Content infringement and intellectual property violation
                </div>
              </li>
              <li class="help-video-list-item">
                <div class="help-video-list-item-content">
                  Data and critical business system backup
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          *ngIf="!isQuickQuote && isFinalStep()"
          class="
            multi-form-item
            form-flow-rail-card form-flow-rail-card__padded
            app-page-form-side-rail
          "
        >
          <p class="p p__small-text">Starting at:</p>
          <h2 *ngIf="!isLoadingPremium" class="h1 mt-0">{{ getPremium() }}</h2>
          <h2 *ngIf="isLoadingPremium" class="h1 mt-0">
            $
            <app-loading-spinner></app-loading-spinner>
          </h2>
          <div
            *ngIf="getPremium() === '--' && !isLoadingPremium"
            class="dialog-text dialog-text__full-width dialog-text__warning dialog-text__margin-top"
            type="warning"
          >
            <div class="dialog-icon">
              <img alt="dialog-warning" src="assets/img/dialog-warning.svg" />
            </div>
            <div class="dialog-content">
              <div>Pricing subject to manual review.</div>
            </div>
          </div>
          <app-form-field-slider
            *ngIf="coverageFormGroup.get(limitPath).value !== null"
            [inputId]="limitPath"
            [labelText]="'Limit'"
            [form]="coverageFormGroup"
            [nameOfFormControl]="limitPath"
            [step]="1"
            [min]="0"
            [max]="LIMIT_OPTIONS.length - 1"
            [displayType]="'DISPLAY_CALCULATED_VALUE'"
            [displayValueCalculator]="this.limitDisplayValueCalculator"
            [isCurrencyValue]="true"
            [readonly]="isSubmittingForm"
            [submitted]="submitted()"
          ></app-form-field-slider>
          <app-form-field-slider
            *ngIf="coverageFormGroup.get(retentionPath).value !== null"
            [inputId]="retentionPath"
            [labelText]="'Retention (deductible)'"
            [form]="coverageFormGroup"
            [nameOfFormControl]="retentionPath"
            [step]="1"
            [min]="retentionMin"
            [max]="retentionMax"
            [displayType]="'DISPLAY_CALCULATED_VALUE'"
            [displayValueCalculator]="this.retentionDisplayValueCalculator"
            [isCurrencyValue]="true"
            [readonly]="isSubmittingForm"
            [submitted]="submitted()"
          ></app-form-field-slider>
          <section>
            <p class="p__bold">Make sure you submit the quote to:</p>
            <ul *ngFor="let message of cyberCallToActionMessages">
              <li>
                <img class="badge-list-img no-top-margin" src="{{ message.icon }}" alt="" />
                <p class="badge-list-text">
                  {{ message.text }}
                </p>
              </li>
            </ul>
          </section>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="form-footer form-footer__disconnected">
    <div class="validation" role="alert" *ngIf="submitted() && !isCurrentStepValid()">
      <p class="validation-message" [innerHTML]="getValidationMessage()"></p>
    </div>
    <ng-container *ngIf="isFirstStep()">
      <div
        [ngClass]="{
          'more-info-tip': surplusWasSelected() || !isQuoteEligibleForAdmitted(),
          'tool-tip': surplusWasSelected() || !isQuoteEligibleForAdmitted(),
          'tool-tip__large': surplusWasSelected() || !isQuoteEligibleForAdmitted()
        }"
        [attr.data-tooltip]="getNextButtonToolTipText('cyber_admitted')"
      >
        <button
          class="button js-load-next-step-button"
          (click)="selectProductAndContinue($event, 'cyber_admitted')"
          [ngClass]="{
            button__discouraged: !isCurrentStepValid() && isQuoteEligibleForAdmitted(),
            button__ineligible: !isQuoteEligibleForAdmitted(),
            button__primary: isQuoteEligibleForAdmitted()
          }"
          [disabled]="
            isSubmittingForm ||
            isLoadingNextStep ||
            !isQuoteEligibleForAdmitted() ||
            surplusWasSelected()
          "
        >
          <div
            class="button-loader"
            role="presentation"
            *ngIf="isSubmittingForm || isLoadingNextStep"
          >
            <app-loading-spinner></app-loading-spinner>
          </div>
          <span>{{ getNextButtonText('cyber_admitted') }}</span>
        </button>
      </div>

      <div
        [ngClass]="{
          'more-info-tip': admittedWasSelected() || surplusButtonIsDisabled(),
          'tool-tip': admittedWasSelected() || surplusButtonIsDisabled(),
          'tool-tip__large': admittedWasSelected() || surplusButtonIsDisabled()
        }"
        [attr.data-tooltip]="getNextButtonToolTipText('cyber_surplus')"
        *ngIf="!isQuickQuote"
      >
        <button
          class="button js-load-next-step-button"
          (click)="selectProductAndContinue($event, 'cyber_surplus')"
          [disabled]="
            isSubmittingForm ||
            isLoadingNextStep ||
            admittedWasSelected() ||
            surplusButtonIsDisabled()
          "
          [ngClass]="{
            button__primary: !isQuoteEligibleForAdmitted(),
            button__secondary: isSurplusEnabled() && isQuoteEligibleForAdmitted(),
            button__ineligible: !isSurplusEnabled() && isQuoteEligibleForAdmitted()
          }"
          style="margin-top: 1.5rem"
        >
          <div
            class="button-loader"
            role="presentation"
            *ngIf="isSubmittingForm || isLoadingNextStep"
          >
            <app-loading-spinner></app-loading-spinner>
          </div>
          <span>{{ getNextButtonText('cyber_surplus') }}</span>
        </button>
      </div>
    </ng-container>
    <button
      *ngIf="!isFirstStep() && !isFinalStep()"
      class="button button__primary js-load-next-step-button"
      (click)="loadNextStep($event)"
      [ngClass]="{
        button__discouraged: !isCurrentStepValid()
      }"
      [disabled]="isLoadingNextStep"
    >
      <div class="button-loader" role="presentation" *ngIf="isLoadingNextStep">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <span>Next</span>
    </button>
    <button
      *ngIf="isFinalStep() && !isQuickQuote"
      class="button button__primary js-step-forward-button"
      [ngClass]="{ button__discouraged: !this.isCurrentStepValid() }"
      [disabled]="isLoadingPremium || isSubmittingForm"
      (click)="submitForm($event)"
    >
      <div class="button-loader" role="presentation" *ngIf="isLoadingPremium || isSubmittingForm">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <span *ngIf="!isSubmittingForm">Submit</span>
      <span *ngIf="isSubmittingForm">Submitting...</span>
    </button>
  </div>
</div>

<app-quote-error-modal
  *ngIf="quoteErrorModalOpen"
  [open]="quoteErrorModalOpen"
  (closeQuoteErrorModal)="onCloseErrorModal($event)"
  [errorType]="quoteError"
  [errors]="earlyDeclineErrors"
>
</app-quote-error-modal>

<app-modal
  *ngIf="showQuoteHelperVideo"
  [open]="showQuoteHelperVideo"
  [modalNameForAmplitudeEvent]="
    isCurrentStep('policy-info') ? 'cyber_video1_open' : 'cyber_video2_open'
  "
  modalType="video"
  (handleModalClose)="setShowQuoteHelperVideo(false)"
>
  <!--
   The reason we don't make src conditional on current step is because of an angular security feature
   context: https://angular.io/guide/security#xss
  -->
  <ng-container *ngIf="isCurrentStep('policy-info')">
    <iframe
      [ngStyle]="{ width: videoWidth, height: videoHeight }"
      src="https://www.youtube.com/embed/P-acDPqeP7M"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </ng-container>
  <ng-container *ngIf="!isCurrentStep('policy-info')">
    <iframe
      [ngStyle]="{ width: videoWidth, height: videoHeight }"
      src="https://www.youtube.com/embed/2DAt1xCDkH8"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </ng-container>
</app-modal>
