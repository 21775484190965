import { Directive } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

@Directive({
  selector: '[appAlphanumericMask]',
})
export class AlphanumericDirective extends BaseMaskDirective {
  getMask(value: string) {
    // Captures non-alphanumeric characters
    const regex = /[^a-zA-Z0-9]/g;

    return value.replace(regex, '');
  }
}
