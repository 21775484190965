<p
  class="education-pane"
  [id]="elementId"
  role="tooltip"
  ngClass="{{ caretClass() }} {{ alignmentClass() }}"
  [ngStyle]="{ left: leftAmount, top: topAmount }"
  (window:resize)="this.setPosition()"
  #container
>
  <ng-content></ng-content>
</p>
