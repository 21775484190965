import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * This component serves as an intermediary between the a stepped form component, which is handled by the
 * form service, and the new account form, which is not handled by the form service. When we
 * fake an "Account" breadcrumb in the stepped form flow after creating a new account, we can't
 * directly serve the new account form since it's not encoded in the form service -- so we instead
 * need to navigate to the new account route.
 */
@Component({
  selector: 'app-insured-account-redirect-page',
  template: '<ng-container></ng-container>',
})
export class SteppedFormRedirectPageComponent implements OnInit {
  constructor(protected route: ActivatedRoute, protected router: Router) {}

  ngOnInit() {
    const accountId = (this.route.parent as ActivatedRoute).snapshot.params['accountId'];

    this.router.navigate(['/accounts/new'], {
      queryParams: { 'existing-account': accountId },
    });
  }
}
