import {
  CoalitionCyberQuestion,
  CoalitionCyberControlName,
  CyberDependency,
  CyberQuestionEnablementFlag,
  ThirdPartyCoverageNestedQuestion,
  FirstPartyCoverageNestedQuestion,
  FormValue,
} from './cyber-typings.model';
import * as _ from 'lodash';
import { parseMoney } from '../../../shared/helpers/number-format-helpers';
import { PRIOR_CLAIMS_AMOUNT_OPTIONS } from './cyber-constants.model';

export const CYBER_DEPENDENCIES: { [key in CoalitionCyberControlName]?: CyberDependency } = {
  [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS_EXPLANATION]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS,
      shouldEnableForValue: 'Yes',
    },
  },
  [FirstPartyCoverageNestedQuestion.BUSINESS_INTERRUPTION]: {
    flagDependency: {
      flagName: CyberQuestionEnablementFlag.REJECT_BUSINESS_INTERRUPTION,
      shouldEnableForValue: false,
    },
  },
  [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE]: {
    productDependency: {
      shouldEnableForValue: 'cyber_surplus',
    },
  },
  [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_COSTS]: {
    productDependency: {
      shouldEnableForValue: 'cyber_admitted',
    },
  },
  [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_SERVICES]: {
    productDependency: {
      shouldEnableForValue: 'cyber_admitted',
    },
  },
  [CoalitionCyberQuestion.CC_CUSTOMER_COUNT]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.PII_PHI,
      shouldEnableForValue: 'Yes',
    },
  },
  [CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.CC_CUSTOMER_COUNT,
      shouldEnableForValue: '>1,000,000',
    },
  },
  [CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE]: {
    flagDependency: {
      flagName: CyberQuestionEnablementFlag.USE_GROSS_PROFIT_NET_REVENUE,
      shouldEnableForValue: true,
    },
  },
  [FirstPartyCoverageNestedQuestion.FUNDS_TRANSFER]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.DUAL_CONTROL_5K,
      shouldEnableForValue: 'Yes',
    },
    flagDependency: {
      flagName: CyberQuestionEnablementFlag.REJECT_FTF,
      shouldEnableForValue: false,
    },
  },
  [CoalitionCyberQuestion.DUAL_CONTROL]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.DUAL_CONTROL_5K,
      shouldEnableForValue: 'No',
    },
  },
  [CoalitionCyberQuestion.HAS_TECH_EO]: {
    flagDependency: {
      flagName: CyberQuestionEnablementFlag.REQUIRE_TECH_EO,
      shouldEnableForValue: true,
    },
  },
  [CoalitionCyberQuestion.IS_MSP_OR_BAD_INDUSTRY]: {
    controlDependency: {
      controlName: FirstPartyCoverageNestedQuestion.TECH_EO,
      shouldEnableForValue: true,
    },
  },
  [ThirdPartyCoverageNestedQuestion.MEDIA_LIABILITY]: {
    flagDependency: {
      flagName: CyberQuestionEnablementFlag.REJECT_MEDIA_LIABILITY,
      shouldEnableForValue: false,
    },
  },
  [CoalitionCyberQuestion.MITIGATING_CLAUSES]: {
    controlDependency: {
      controlName: FirstPartyCoverageNestedQuestion.TECH_EO,
      shouldEnableForValue: true,
    },
  },
  [CoalitionCyberQuestion.PII_PHI_COUNT]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.PII_PHI,
      shouldEnableForValue: 'Yes',
    },
  },
  [CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.PII_PHI_COUNT,
      shouldEnableForValue: '>1,000,000',
    },
  },
  [CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      shouldEnableForValue: 'Yes',
    },
  },
  [CoalitionCyberQuestion.PROFESSIONAL_SERVICES]: {
    controlDependency: {
      controlName: FirstPartyCoverageNestedQuestion.TECH_EO,
      shouldEnableForValue: true,
    },
  },
  [CoalitionCyberQuestion.SERVICES_BY_CONTRACT]: {
    controlDependency: {
      controlName: FirstPartyCoverageNestedQuestion.TECH_EO,
      shouldEnableForValue: true,
    },
  },
  [FirstPartyCoverageNestedQuestion.TECH_EO]: {
    flagDependency: {
      flagName: CyberQuestionEnablementFlag.REJECT_TECH_EO,
      shouldEnableForValue: false,
    },
    productDependency: {
      shouldEnableForValue: 'cyber_surplus',
    },
  },
  [CoalitionCyberQuestion.TECH_EO_DISPUTE]: {
    controlDependency: {
      controlName: FirstPartyCoverageNestedQuestion.TECH_EO,
      shouldEnableForValue: true,
    },
  },
  [CoalitionCyberQuestion.TECH_EO_DISPUTE_EXPLANATION]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.TECH_EO_DISPUTE,
      shouldEnableForValue: 'Yes',
    },
  },
  [CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
      shouldEnableForValue: [
        {
          displayName:
            'Professional Services - Research and Consulting Services (Architect / Architecture)',
          id: 185,
        },
        {
          displayName:
            'Professional Services - Research and Consulting Services (Engineers / Engineering)',
          id: 186,
        },
      ],
    },
  },
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      shouldEnableForValue: 'Yes',
    },
  },
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      shouldEnableForValue: 'Yes',
    },
  },
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
      shouldEnableForValue: PRIOR_CLAIMS_AMOUNT_OPTIONS['> $500,000'],
    },
  },
  [CoalitionCyberQuestion.PRIOR_CLAIMS]: {
    controlDependency: {
      controlName: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      shouldEnableForValue: 'Yes',
    },
  },
};

// Currently only used to filter the form data for quick quote.
export const SURPLUS_DEPENDENCIES = Object.keys(
  _.pickBy(
    CYBER_DEPENDENCIES,
    (dependency) =>
      dependency &&
      dependency.productDependency &&
      dependency.productDependency.shouldEnableForValue === 'cyber_surplus'
  )
);
export const ADMITTED_DEPENDENCIES = Object.keys(
  _.pickBy(
    CYBER_DEPENDENCIES,
    (dependency) =>
      dependency &&
      dependency.productDependency &&
      dependency.productDependency.shouldEnableForValue === 'cyber_admitted'
  )
);

const isCurrencyOverOneMillion = (val: FormValue) => {
  if (typeof val !== 'string') {
    return false;
  }

  return parseMoney(val) >= 1_000_000;
};

export const CYBER_QUICK_QUOTE_DEPENDENCIES: {
  [key in CoalitionCyberControlName]?: CyberDependency;
} = {
  [CoalitionCyberQuestion.PRIOR_CLAIMS]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
  [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
  [CoalitionCyberQuestion.ENCRYPTS_DATA]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
  [CoalitionCyberQuestion.PII_PHI]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
  [CoalitionCyberQuestion.CONTENT_COMPLAINTS]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
  [CoalitionCyberQuestion.REVIEWS_CONTENT]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
  [CoalitionCyberQuestion.BACKUP_AND_RESTORE]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
  [CoalitionCyberQuestion.DUAL_CONTROL]: {
    customControlDependency: {
      controlName: CoalitionCyberQuestion.COMPANY_REVENUE,
      callback: isCurrencyOverOneMillion,
    },
  },
};
