<section class="live-chat-section">
  <div class="live-chat-header-content">
    <app-logo-with-text></app-logo-with-text>
    <a
      class="button button__small button__primary button__no-margin"
      routerLink="/accounts/new"
      data-amplitude-input-name="live_chat_get_quote"
      >Get a quote</a
    >
  </div>

  <!-- todo consider margin-top for spinner -->
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <div *ngIf="!loading" class="live-chat-content">
    <app-status-indicator [active]="online"></app-status-indicator>
    <h1 class="h1 h1__live-chat">Live eligibility chat</h1>
    <p class="p p__gray">
      We’re available to answer your questions Monday through Friday from
      {{ customerCareHours.start }} to {{ customerCareHours.end }} {{ customerCareHours.timezone }}.
    </p>
  </div>
</section>
