<div [formGroup]="additionalInsureds">
  <div
    id="{{ prefix }}-additionalInsured-{{ i }}"
    class="multi-form-item js-additional-insured-form-control"
    *ngFor="let insuredBusiness of additionalInsureds.controls; let i = index"
  >
    <div [formGroup]="insuredBusiness">
      <div
        class="validation validation__align-left"
        *ngIf="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
      >
        <div class="validation-message validation-message__warning">
          Please submit a request through
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">helpdesk</a> to modify this
          additional insured.
        </div>
      </div>
      <app-form-field-text
        inputId="{{ prefix }}-additionalInsured-{{ i }}-businessName"
        [form]="insuredBusiness"
        [submitted]="submitted"
        labelText="Legal Business Name"
        specifierText="(max 60 characters)"
        maxLength="60"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        nameOfFormControl="businessName"
      >
      </app-form-field-text>

      <div
        [ngClass]="{
          'form-field': true,
          'form-field__error': shouldShowInvalid('type', insuredBusiness, submitted)
        }"
      >
        <label for="additionalInsured-{{ i }}-insuredType">Type</label>
        <section *ngIf="!isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)">
          <div class="select-container">
            <select id="additionalInsured-{{ i }}-insuredType" formControlName="type">
              <option value="" disabled>Select Type</option>
              <option
                *ngFor="let insuredType of additionalInsuredTypes | keys"
                [value]="additionalInsuredTypes[insuredType]"
              >
                {{ insuredType }}
              </option>
            </select>
          </div>
          <small class="form-field-note"
            >Not sure which type to use?
            <a
              href="https://attunehelp.zendesk.com/hc/en-us/articles/360044224431"
              target="_blank"
              data-amplitude-input-name="link-to-additional-insured-guide"
              >See our guide</a
            ></small
          >
        </section>
        <section *ngIf="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)">
          <input
            type="text"
            readonly
            [value]="getFormattedDisabledAdditionalInsuredType(insuredBusiness.value.type)"
          />
        </section>
      </div>

      <div
        class="additional-insured-related-locations js-additional-insureds-related-locations"
        *ngIf="locationSpecificControlsVisible(insuredBusiness.value.type)"
      >
        <p>Applicable Location</p>

        <ng-container *ngIf="isUsingRelatedLocation">
          <ng-container *ngFor="let location of relatedLocations">
            <label for="additionalInsured-{{ i }}-{{ location.locationId }}">
              <input
                id="additionalInsured-{{ i }}-{{ location.locationId }}"
                name="additionalInsured-{{ i }}-relatedLocation"
                type="radio"
                [value]="location.locationId"
                [attr.readonly]="
                  isAdditionalInsuredTypeDisabled(insuredBusiness.value.type) || null
                "
                [attr.disabled]="
                  isAdditionalInsuredTypeDisabled(insuredBusiness.value.type) || null
                "
                [formControl]="insuredBusiness.get('relatedLocation')"
              />
              <span>{{ location.address }}</span>
            </label>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isUsingRelatedLocation">
          <ng-container
            *ngFor="let relatedEntity of getRelatedEntities(insuredBusiness.value.type)"
          >
            <label for="additionalInsured-{{ i }}-{{ formatForCss(relatedEntity.guidewireId) }}">
              <input
                id="additionalInsured-{{ i }}-{{ formatForCss(relatedEntity.guidewireId) }}"
                name="additionalInsured-{{ i }}-relatedLocation"
                type="radio"
                [value]="relatedEntity.guidewireId"
                [attr.readonly]="
                  isAdditionalInsuredTypeDisabled(insuredBusiness.value.type) || null
                "
                [attr.disabled]="
                  isAdditionalInsuredTypeDisabled(insuredBusiness.value.type) || null
                "
                [formControl]="insuredBusiness.get('relatedEntityId')"
              />
              <span>{{ relatedEntity.address }}</span>
            </label>
          </ng-container>
        </ng-container>
      </div>

      <app-form-field-text
        [form]="insuredBusiness"
        inputId="{{ prefix }}-additionalInsured-{{ i }}-additionalInformation"
        [submitted]="submitted"
        labelText="{{ getDependentQuestionLabel(insuredBusiness.value.type) }}"
        specifierText="{{ getOptionalLabel(insuredBusiness) }}"
        maxLength="60"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        nameOfFormControl="additionalInformation"
      >
      </app-form-field-text>

      <app-form-field-text
        [form]="insuredBusiness"
        inputId="{{ prefix }}-additionalInsured-{{ i }}-limitOfInsurance"
        [submitted]="submitted"
        labelText="Limit of Insurance"
        maxLength="60"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        nameOfFormControl="limitOfInsurance"
        appMoneyMask="WITHOUT_DECIMAL"
        placeholder="$"
      >
      </app-form-field-text>

      <app-form-field-text
        [form]="insuredBusiness"
        inputId="{{ prefix }}-additionalInsured-{{ i }}-contractNumber"
        [submitted]="submitted"
        labelText="Contract Number"
        specifierText="(optional)"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        nameOfFormControl="contractNumber"
      >
      </app-form-field-text>

      <app-form-field-text
        [form]="insuredBusiness"
        inputId="{{ prefix }}-additionalInsured-{{ i }}-buildingDescription"
        [submitted]="submitted"
        labelText="Building Description"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        nameOfFormControl="buildingDescription"
      >
      </app-form-field-text>

      <app-form-field-select
        [form]="insuredBusiness"
        inputId="{{ prefix }}-additionalInsured-{{ i }}-paragraph"
        [submitted]="submitted"
        labelText="Paragraph A, B, or C"
        [availableOptions]="paragraphOptions"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        nameOfFormControl="paragraph"
      >
      </app-form-field-select>
      <p *ngIf="insuredBusiness.controls.paragraph.enabled" class="p__field-description-text">
        What are Paragraph A, B, and C?
        <a
          href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/BOP+Loss+Payable+Clauses+(BP12031T).pdf"
          target="_blank"
          >Learn More</a
        >
      </p>

      <app-form-field-address
        labelText="Additional Insured Business Address"
        prefix="{{ prefix }}-additionalInsured-{{ i }}"
        [form]="insuredBusiness.get('address')"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        [submitted]="submitted"
      >
      </app-form-field-address>

      <app-form-field-select
        [form]="insuredBusiness"
        inputId="{{ prefix }}-additionalInsured-{{ i }}-addressType"
        [submitted]="submitted"
        labelText="Address Type"
        [availableOptions]="additionalInsuredAddressTypes"
        [readonly]="isAdditionalInsuredTypeDisabled(insuredBusiness.value.type)"
        nameOfFormControl="addressType"
        cssClass="{ 'has-error': shouldShowInvalid('addressType', insuredBusiness, submitted) }"
      >
      </app-form-field-select>

      <div class="nav-button-group">
        <a
          class="button button__small button__secondary"
          (click)="removeInsuredBusinessHandler(i)"
          (keyup.enter)="removeInsuredBusinessHandler(i)"
          *ngIf="moreThanOneAdditionalInsured() || allowRemovingAllAdditionalInsureds"
        >
          Remove
        </a>
      </div>
    </div>
  </div>
  <button
    class="
      button button__secondary button__full-width button__no-margin
      js-add-additional-insureds-button
    "
    type="button"
    (click)="addInsuredBusinessHandler()"
  >
    Add additional insured
  </button>
</div>
