<ng-container *ngIf="spoilageEnabled">
  <div class="helper-card-container js-bop-spoilage">
    <app-form-field-select
      inputId="{{ prefix }}-spoilage"
      [form]="coverage"
      [submitted]="submitted"
      labelText="Spoilage"
      nameOfFormControl="spoilage"
      placeholder="$0"
      [availableOptions]="availableSpoilageLimits"
      tooltipText="Coverage pays for loss of “perishable stock” when a change in temperature
      or humidity from a mechanical breakdown or failure of refrigeration, cooling or
      humidity control apparatus or equipment, as well as when loss happens from contamination
      by a refrigerant."
    >
    </app-form-field-select>
  </div>
</ng-container>
