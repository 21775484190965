import { cloneDeep, keys, mapValues, pickBy } from 'lodash';

import { addressNode, FormDslNode } from '../../../shared/form-dsl/constants/form-dsl-typings';
import { FormDslConfiguration, LmButtonFunctionName } from './common-typings';
import {
  LmBopQuestion,
  LmBopSubmissionField,
  LmBopLossHistoryQuestion,
  LmBopNestedControls,
} from './lm-bop-typings';
import { SearchQueryMethod, SearchFormatter } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE } from './constants';
import { setupTextLengthExceededErrorMessage } from './lm-shared-validators';

const INT_RADIX = 10; // to pass linting in Number.parseInt method calls
const TEXT_CHAR_LIMIT = 250;

const ARRAY_CONTROLS: Record<LmBopNestedControls, FormDslConfiguration> = {
  [LmBopLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE,
      nameOfFormControl: LmBopLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE,
      labelText: 'Allocated adjuster expense',
      value: '$0',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopLossHistoryQuestion.AMOUNT_PAID]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopLossHistoryQuestion.AMOUNT_PAID,
      nameOfFormControl: LmBopLossHistoryQuestion.AMOUNT_PAID,
      labelText: 'Amount paid',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopLossHistoryQuestion.AMOUNT_RESERVED]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopLossHistoryQuestion.AMOUNT_RESERVED,
      nameOfFormControl: LmBopLossHistoryQuestion.AMOUNT_RESERVED,
      labelText: 'Amount reserved',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopLossHistoryQuestion.CLAIM_STATUS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopLossHistoryQuestion.CLAIM_STATUS,
      nameOfFormControl: LmBopLossHistoryQuestion.CLAIM_STATUS,
      labelText: 'Claim status',
      options: {
        Open: 'Open',
        Closed: 'Closed',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopLossHistoryQuestion.DATE_OF_LOSS]: {
    formDslNode: {
      primitive: 'DATE',
      inputId: LmBopLossHistoryQuestion.DATE_OF_LOSS,
      nameOfFormControl: LmBopLossHistoryQuestion.DATE_OF_LOSS,
      labelText: 'Date of loss',
      showErrorText: true,
      errorText:
        'Please enter a date that is within the current term or three most recent completed policy periods.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopLossHistoryQuestion.LOSS_DESCRIPTION]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopLossHistoryQuestion.LOSS_DESCRIPTION,
      nameOfFormControl: LmBopLossHistoryQuestion.LOSS_DESCRIPTION,
      labelText: 'Loss description',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopLossHistoryQuestion.LOSS_TYPE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopLossHistoryQuestion.LOSS_TYPE,
      nameOfFormControl: LmBopLossHistoryQuestion.LOSS_TYPE,
      labelText: 'Loss type',
      required: true,
      options: {
        BFIRE: 'Fire',
        BWATR: 'Water',
        BHAIL: 'Hail',
        BVAND: 'Vandalism',
        BCOLL: 'Collapse',
        BWIND: 'Windstorm',
        BTHFT: 'Theft/Burglary',
        SPOIL: 'Food Spoilage',
        BIM: 'Inland Marine',
        BSLPI: 'Slip/Fall - Inside',
        BSLPO: 'Slip/Fall - Outside',
        BPROD: 'Products',
        BPINJ: 'Personal Injury',
        BPRDM: 'Property Damage',
        BPROF: 'Professional Liability / Errors and Omissions',
        BEMPR: 'Employee Practices',
        BOTHR: 'Other',
      },
    },
    valueType: 'STATIC',
  },
};

mapValues(ARRAY_CONTROLS, (config: FormDslConfiguration) => {
  if (config.formDslNode.primitive === 'TEXT_AREA') {
    const maxLength = Number.parseInt(config.formDslNode.maxLength as string, INT_RADIX);
    if (maxLength < TEXT_CHAR_LIMIT) {
      config.formDslNode.questionNote = TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(maxLength);
    } else {
      config.formDslNode.questionNote = TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(TEXT_CHAR_LIMIT);
      config.formDslNode.maxLength = `${TEXT_CHAR_LIMIT}`;
    }
  }
  return config;
});

export const lmBopLossGroupNodes = () =>
  cloneDeep<FormDslNode[]>([
    ARRAY_CONTROLS[LmBopLossHistoryQuestion.DATE_OF_LOSS].formDslNode,
    ARRAY_CONTROLS[LmBopLossHistoryQuestion.LOSS_DESCRIPTION].formDslNode,
    ARRAY_CONTROLS[LmBopLossHistoryQuestion.LOSS_TYPE].formDslNode,
    ARRAY_CONTROLS[LmBopLossHistoryQuestion.CLAIM_STATUS].formDslNode,
    ARRAY_CONTROLS[LmBopLossHistoryQuestion.AMOUNT_PAID].formDslNode,
    ARRAY_CONTROLS[LmBopLossHistoryQuestion.AMOUNT_RESERVED].formDslNode,
    ARRAY_CONTROLS[LmBopLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE].formDslNode,
  ]);

export const BOP_FORM_QUESTIONS: Record<LmBopQuestion, FormDslConfiguration> = {
  [LmBopQuestion.PRIMARY_RISK_STATE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.PRIMARY_RISK_STATE,
      nameOfFormControl: LmBopQuestion.PRIMARY_RISK_STATE,
      labelText: 'Base state',
      questionNote: 'Business location address must be in the base state',
      options: {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District Of Columbia',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.BOP_CLASS_CODE]: {
    formDslNode: {
      primitive: 'DROPDOWN_SEARCH',
      inputId: LmBopQuestion.BOP_CLASS_CODE,
      nameOfFormControl: LmBopQuestion.BOP_CLASS_CODE,
      labelText: 'Business classification',
      queryMethodName: SearchQueryMethod.LM_BOP_CLASS_CODE,
      formatterName: SearchFormatter.LM_BOP_CLASS_CODE,
      typeaheadPlaceholderText: 'Search class codes',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.BUSINESS_ADDRESS]: {
    formDslNode: addressNode({
      nameOfFormControl: LmBopQuestion.BUSINESS_ADDRESS,
      labelText: 'Business address',
      prefix: 'lm-bop',
      useAddressLine2: true,
      questionNote:
        'Need to add another location or building? Use <a href="https://app.attuneinsurance.com/support/form/add-building" target="_blank">this form</a> after completing the quote.',
    }),
    valueType: 'STATIC',
  },
  [LmBopQuestion.NUMBER_STORIES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.NUMBER_STORIES,
      nameOfFormControl: LmBopQuestion.NUMBER_STORIES,
      labelText: 'Number of stories',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ALARM_TYPE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.ALARM_TYPE,
      nameOfFormControl: LmBopQuestion.ALARM_TYPE,
      labelText: 'Alarm type',
      required: true,
      options: {
        CEN: 'Central Station Without Keys',
        LO: 'Local',
        PCFC: 'Police/Fire Connected',
        NO: 'None',
      },
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ANNUAL_DRY_CLEANING_RECEIPTS_AT_THIS_LOCATION]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.ANNUAL_DRY_CLEANING_RECEIPTS_AT_THIS_LOCATION,
      nameOfFormControl: LmBopQuestion.ANNUAL_DRY_CLEANING_RECEIPTS_AT_THIS_LOCATION,
      labelText: 'Annual dry cleaning receipts at this location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ANNUAL_SALES_RECEIPTS]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.ANNUAL_SALES_RECEIPTS,
      nameOfFormControl: LmBopQuestion.ANNUAL_SALES_RECEIPTS,
      labelText: 'Annual sales/receipts',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING,
      nameOfFormControl: LmBopQuestion.ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING,
      labelText: 'Are there any habitational exposures in this building?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.AREA_OCCUPIED_BY_INSURED]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.AREA_OCCUPIED_BY_INSURED,
      nameOfFormControl: LmBopQuestion.AREA_OCCUPIED_BY_INSURED,
      labelText: 'Square footage your business occupies',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS,
      nameOfFormControl: LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS,
      labelText: 'Square footage occupied by other building tenants',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.AREA_UNOCCUPIED]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.AREA_UNOCCUPIED,
      nameOfFormControl: LmBopQuestion.AREA_UNOCCUPIED,
      labelText: 'Square footage unoccupied',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE,
      nameOfFormControl: LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE,
      labelText: 'Building limit',
      questionNote: `Building limit may be defaulted to Liberty Mutual's ITV calculation upon submission. If this default needs to be changed, please submit a request to <a href="mailto:help@attuneinsurance.com">help@attuneinsurance.com</a> and include documentation showing the new valuation.`,
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT,
      nameOfFormControl: LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT,
      labelText: 'Business personal property limit',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LIQUOR_COVERAGE]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.LIQUOR_COVERAGE,
      nameOfFormControl: LmBopQuestion.LIQUOR_COVERAGE,
      labelText: 'Liquor coverage',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LIQUOR_LIABILITY_TYPE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LIQUOR_LIABILITY_TYPE,
      nameOfFormControl: LmBopQuestion.LIQUOR_LIABILITY_TYPE,
      labelText: 'Liquor coverage',
      options: {
        'Liquor Liability': LmBopSubmissionField.LIQUOR_LIABILITY,
        'BYOB Liability coverage': LmBopSubmissionField.BYOB_LIQUOR_LIABILITY_COVERAGE,
      },
      optionsCssClass: 'radio-question-option__no-transform',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.CONSTRUCTION_TYPE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.CONSTRUCTION_TYPE,
      nameOfFormControl: LmBopQuestion.CONSTRUCTION_TYPE,
      labelText: 'Construction type',
      options: {
        F: 'Frame',
        JM: 'Joisted Masonry',
        NC: 'Non Combustible',
        MNC: 'Masonry Non Combustible',
        R: 'Fire Resistive',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      nameOfFormControl: LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      labelText: 'Data Compromise Response Expenses',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT]: {
    formDslNode: {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT,
      nameOfFormControl: LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT,
      labelText: 'Limit',
      value: '$50,000',
      readonly: true,
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.CYBER_COVERAGE]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.CYBER_COVERAGE,
      nameOfFormControl: LmBopQuestion.CYBER_COVERAGE,
      labelText: 'Cyber',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.DESCRIPTION_OF_OPERATIONS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.DESCRIPTION_OF_OPERATIONS,
      nameOfFormControl: LmBopQuestion.DESCRIPTION_OF_OPERATIONS,
      labelText: 'Description of operations',
      minLength: '1',
      maxLength: '170',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES,
      nameOfFormControl: LmBopQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES,
      labelText: 'Each claim / Aggregate limit – Employment Practices',
      options: {},
      required: true,
    },
    valueType: 'DYNAMIC_CONFIG',
    valueData: [
      {
        state: {
          display: 'IN',
          values: ['AL'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['AK'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['AR'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['AZ'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['CA'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['CO'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['CT'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['DC'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['DE'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['FL'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['GA'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['ID'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['IL'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['IN'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['IA'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['KS'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['KY'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['LA'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['ME'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['MD'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['MA'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['MI'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['MN'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['MS'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['MT'],
        },
        nodeValue: {
          options: {
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['MO'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['NC'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['ND'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['NE'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['NH'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['NJ'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['NM'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['NV'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      // NOTE: This question isn't displayed in NY, so although this value is
      // here, it's not used.
      {
        state: {
          display: 'IN',
          values: ['NY'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['OH'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['OK'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['OR'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['PA'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['RI'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['SC'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['SD'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['TN'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['TX'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['UT'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['VT'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['VA'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['WA'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['WV'],
        },
        nodeValue: {
          options: {
            '10000': '$10,000',
            '25000': '$25,000',
            '50000': '$50,000',
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['WI'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['WY'],
        },
        nodeValue: {
          options: {
            '5000/5000': '$5,000/$5,000',
            '12500/12500': '$12,500/$12,500',
            '25000/25000': '$25,000/$25,000',
            '50000/50000': '$50,000/$50,000',
            '125000/125000': '$125,000/$125,000',
            '250000/250000': '$250,000/$250,000',
            '500000/500000': '$500,000/$500,000',
          },
        },
      },
    ],
  },
  [LmBopQuestion.EFFECTIVE_DATE]: {
    formDslNode: {
      primitive: 'DATE',
      inputId: LmBopQuestion.EFFECTIVE_DATE,
      nameOfFormControl: LmBopQuestion.EFFECTIVE_DATE,
      labelText: 'Effective date',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.EPLI]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.EPLI,
      nameOfFormControl: LmBopQuestion.EPLI,
      labelText: 'Employment Practices Liability',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.FORM_OF_BUSINESS]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.FORM_OF_BUSINESS,
      nameOfFormControl: LmBopQuestion.FORM_OF_BUSINESS,
      labelText: 'Form of business',
      options: {
        AS: 'Association',
        CP: 'Corporation',
        IN: 'Individual',
        JV: 'Joint Venture',
        PT: 'Partnership',
        LL: 'Limited Liability Company',
        LY: 'Limited Liability Partnership',
        MU: 'Municipality',
        TR: 'Trust',
        OT: 'Other (Describe)',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.FULL_TIME_BARBERS_AT_THIS_LOCATION]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.FULL_TIME_BARBERS_AT_THIS_LOCATION,
      nameOfFormControl: LmBopQuestion.FULL_TIME_BARBERS_AT_THIS_LOCATION,
      labelText: 'Full time barbers at this location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.FULL_TIME_BEAUTICIANS_AT_THIS_LOCATION]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.FULL_TIME_BEAUTICIANS_AT_THIS_LOCATION,
      nameOfFormControl: LmBopQuestion.FULL_TIME_BEAUTICIANS_AT_THIS_LOCATION,
      labelText: 'Full time beauticians at this location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      nameOfFormControl: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      labelText: 'Have there been losses for the lines of business submitted?',
      subLabelText:
        'Include losses from the current term and three most recent completed policy periods.',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HEARING_AID_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.HEARING_AID_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.HEARING_AID_PROFESSIONAL_LIABILITY,
      labelText: 'Hearing Aid Professional Liability',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY,
      nameOfFormControl: LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY,
      labelText: 'Hired and Non-owned Auto Liability',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HIRED_AUTO_PHYSICAL_DAMAGE]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.HIRED_AUTO_PHYSICAL_DAMAGE,
      nameOfFormControl: LmBopQuestion.HIRED_AUTO_PHYSICAL_DAMAGE,
      labelText: 'Hired Auto Physical Damage',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.INSURED_PHONE_NUMBER]: {
    formDslNode: {
      primitive: 'PHONE',
      inputId: LmBopQuestion.INSURED_PHONE_NUMBER,
      nameOfFormControl: LmBopQuestion.INSURED_PHONE_NUMBER,
      labelText: 'Insured phone number',
      minLength: '12',
      maxLength: '12',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.INTEREST]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.INTEREST,
      nameOfFormControl: LmBopQuestion.INTEREST,
      labelText: 'Interest',
      options: {
        Owner: 'OWNER',
        Tenant: 'TENANT',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LIMIT_OF_INSURANCE_PRINTERS_WORK_CORRECTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LIMIT_OF_INSURANCE_PRINTERS_WORK_CORRECTION,
      nameOfFormControl: LmBopQuestion.LIMIT_OF_INSURANCE_PRINTERS_WORK_CORRECTION,
      labelText: 'Limit of insurance (printers work correction)',
      options: {
        '$50,000': '50000',
        '$100,000': '100000',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LOSSES]: {
    formDslNode: {
      primitive: 'NODE_ARRAY',
      inputId: LmBopQuestion.LOSSES,
      prefix: 'loss',
      nameOfFormControl: LmBopQuestion.LOSSES,
      required: true,
      children: [lmBopLossGroupNodes()],
      childDivCssClass: 'multi-form-item js-lm-bop-loss-group',
      childFooterButtons: [
        {
          primitive: 'BUTTON',
          buttonText: 'Remove',
          methodName: LmButtonFunctionName.REMOVE_LOSS_GROUP,
          cssClass: 'button button__small button__secondary js-remove-loss-group-button',
        },
      ],
      minChildrenToShowChildButtons: 1,
      footerButtons: [
        {
          primitive: 'BUTTON',
          buttonText: 'Add another loss',
          methodName: LmButtonFunctionName.ADD_LOSS_GROUP,
          cssClass:
            'button button__secondary button__full-width button__no-margin js-add-loss-group-button',
        },
      ],
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.MANICURISTS_AT_THIS_LOCATION]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.MANICURISTS_AT_THIS_LOCATION,
      nameOfFormControl: LmBopQuestion.MANICURISTS_AT_THIS_LOCATION,
      labelText: 'Manicurists at this location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NUMBER_OF_EMPLOYEES_VETERINARIANS_ASSISTANTS_VETERINARIANS_STAFF]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.NUMBER_OF_EMPLOYEES_VETERINARIANS_ASSISTANTS_VETERINARIANS_STAFF,
      nameOfFormControl:
        LmBopQuestion.NUMBER_OF_EMPLOYEES_VETERINARIANS_ASSISTANTS_VETERINARIANS_STAFF,
      labelText: 'Number of employees (veterinarians assistants/veterinarians staff)',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_HEARING_AID_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.NUMBER_OF_PROFESSIONALS_HEARING_AID_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.NUMBER_OF_PROFESSIONALS_HEARING_AID_PROFESSIONAL_LIABILITY,
      labelText: 'Number of professionals (Hearing Aid Professional Liability)',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTICAL_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTICAL_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTICAL_PROFESSIONAL_LIABILITY,
      labelText: 'Number of professionals (Optical Professional Liability)',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTOMETRISTS_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      labelText: 'Number of professionals (Optometrists Professional Liability)',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NUMBER_OF_VETERINARIANS_OWNERS_PARTNERS_OFFICERS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.NUMBER_OF_VETERINARIANS_OWNERS_PARTNERS_OFFICERS,
      nameOfFormControl: LmBopQuestion.NUMBER_OF_VETERINARIANS_OWNERS_PARTNERS_OFFICERS,
      labelText: 'Number of veterinarians (owners/partners/officers)',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OPTICAL_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.OPTICAL_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.OPTICAL_PROFESSIONAL_LIABILITY,
      labelText: 'Optical professional liability',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      labelText: 'Optometrists Professional Liability',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PART_TIME_BARBERS_AT_THIS_LOCATION]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.PART_TIME_BARBERS_AT_THIS_LOCATION,
      nameOfFormControl: LmBopQuestion.PART_TIME_BARBERS_AT_THIS_LOCATION,
      labelText: 'Part time barbers at this location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PART_TIME_BEAUTICIANS_AT_THIS_LOCATION]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.PART_TIME_BEAUTICIANS_AT_THIS_LOCATION,
      nameOfFormControl: LmBopQuestion.PART_TIME_BEAUTICIANS_AT_THIS_LOCATION,
      labelText: 'Part time beauticians at this location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PRINTERS_ANNUAL_RECEIPTS_AT_THIS_LOCATION_PRINTERS_E_O]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.PRINTERS_ANNUAL_RECEIPTS_AT_THIS_LOCATION_PRINTERS_E_O,
      nameOfFormControl: LmBopQuestion.PRINTERS_ANNUAL_RECEIPTS_AT_THIS_LOCATION_PRINTERS_E_O,
      labelText: 'Printers annual receipts at this location (printers E&O)',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY,
      nameOfFormControl: LmBopQuestion.PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY,
      labelText: 'Printers Errors and Omissions Liability',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PRINTERS_WORK_CORRECTION_PRINTERS_E_O]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.PRINTERS_WORK_CORRECTION_PRINTERS_E_O,
      nameOfFormControl: LmBopQuestion.PRINTERS_WORK_CORRECTION_PRINTERS_E_O,
      labelText: 'Printers Work Correction (printers E&O)',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.TOTAL_ANNUAL_LIQUOR_RECEIPTS_AT_THIS_LOCATION]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.TOTAL_ANNUAL_LIQUOR_RECEIPTS_AT_THIS_LOCATION,
      nameOfFormControl: LmBopQuestion.TOTAL_ANNUAL_LIQUOR_RECEIPTS_AT_THIS_LOCATION,
      labelText: 'Total annual liquor receipts at this location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS,
      nameOfFormControl: LmBopQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS,
      labelText: 'Total number of employees at all locations',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.TOTAL_RECEIPTS_FOR_LOCATION]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.TOTAL_RECEIPTS_FOR_LOCATION,
      nameOfFormControl: LmBopQuestion.TOTAL_RECEIPTS_FOR_LOCATION,
      labelText: 'Total receipts for location',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.UM_UIM_LOSS_TYPE]: {
    formDslNode: {
      primitive: 'TEXT',
      inputId: LmBopQuestion.UM_UIM_LOSS_TYPE,
      nameOfFormControl: LmBopQuestion.UM_UIM_LOSS_TYPE,
      labelText: 'UM/UIM loss type',
      readonly: true,
      questionNote: 'Required for policies in LA with these coverages',
      value: 'Economic and Non-Economic Loss',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.UNINSURED_UNDERINSURED_MOTORIST_LIMIT]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.UNINSURED_UNDERINSURED_MOTORIST_LIMIT,
      nameOfFormControl: LmBopQuestion.UNINSURED_UNDERINSURED_MOTORIST_LIMIT,
      labelText: 'Uninsured/underinsured motorist limit',
      required: true,
      options: {}, // There are no default options; each relevant state has its own set.
    },
    valueType: 'DYNAMIC_CONFIG',
    valueData: [
      {
        state: {
          display: 'IN',
          values: ['IL'],
        },
        nodeValue: {
          options: {
            '60000': '$60,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['LA'],
        },
        nodeValue: {
          options: {
            '55000': '$55,000',
          },
        },
      },
      {
        state: {
          display: 'IN',
          values: ['WV'],
        },
        nodeValue: {
          options: {
            '75000': '$75,000',
            '350000': '$350,000',
          },
        },
      },
    ],
  },
  [LmBopQuestion.VETERINARIAN_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmBopQuestion.VETERINARIAN_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.VETERINARIAN_PROFESSIONAL_LIABILITY,
      labelText: 'Veterinarian Professional Liability',
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.YEAR_BUILT]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.YEAR_BUILT,
      nameOfFormControl: LmBopQuestion.YEAR_BUILT,
      labelText: 'Year built',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HAS_DELIVERY_SERVICE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HAS_DELIVERY_SERVICE,
      nameOfFormControl: LmBopQuestion.HAS_DELIVERY_SERVICE,
      labelText: 'Does insured provide delivery service?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.YEAR_BUSINESS_STARTED]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.YEAR_BUSINESS_STARTED,
      nameOfFormControl: LmBopQuestion.YEAR_BUSINESS_STARTED,
      labelText: 'Year business started',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.RARE_ANIMAL_TREATMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.RARE_ANIMAL_TREATMENT,
      nameOfFormControl: LmBopQuestion.RARE_ANIMAL_TREATMENT,
      labelText:
        'Are any professional services provided to animals used or bred for: racing, show, circus, rodeos or any other entertainment purposes?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.USE_OF_FLAMMABLES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.USE_OF_FLAMMABLES,
      nameOfFormControl: LmBopQuestion.USE_OF_FLAMMABLES,
      labelText: 'Are flammables stored in UL approved containers?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OWN_PRODUCTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.OWN_PRODUCTS,
      nameOfFormControl: LmBopQuestion.OWN_PRODUCTS,
      labelText: 'Does the insured sell any products under their own name?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.AREA_UNOCCUPIED_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.AREA_UNOCCUPIED_DETAILS,
      nameOfFormControl: LmBopQuestion.AREA_UNOCCUPIED_DETAILS,
      labelText: 'Please provide details regarding unoccupied areas of the building.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HEATING_YEAR]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.HEATING_YEAR,
      nameOfFormControl: LmBopQuestion.HEATING_YEAR,
      labelText: 'Heating year',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PLUMBING_YEAR]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.PLUMBING_YEAR,
      nameOfFormControl: LmBopQuestion.PLUMBING_YEAR,
      labelText: 'Plumbing year',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS,
      nameOfFormControl: LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS,
      labelText: "Please provide details regarding insured's management experience.",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE,
      nameOfFormControl: LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE,
      labelText:
        'How many years of management experience does the management team have in this industry?',
      errorText: 'Please enter a numeric value less than 1000.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OWNED_AUTO]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.OWNED_AUTO,
      nameOfFormControl: LmBopQuestion.OWNED_AUTO,
      labelText: 'Does insured own any autos that are titled in the name of the business?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PERSONAL_AUTO_EMPLOYEE_USE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.PERSONAL_AUTO_EMPLOYEE_USE,
      nameOfFormControl: LmBopQuestion.PERSONAL_AUTO_EMPLOYEE_USE,
      labelText:
        "Are non-owned autos (i.e., employees' personal autos) used either daily or weekly in the course of the insured's business?",
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.EMP_COV_MIN_REQUIRED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.EMP_COV_MIN_REQUIRED,
      nameOfFormControl: LmBopQuestion.EMP_COV_MIN_REQUIRED,
      labelText:
        'Are employees required to carry their own personal auto coverage with minimum limits requirements of $300,000 CSL or $100,000/$300,000 split limits?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LEASED_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.LEASED_DETAILS,
      nameOfFormControl: LmBopQuestion.LEASED_DETAILS,
      labelText: 'What type of equipment is leased or rented?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LEASED_OPERATOR_DETAILS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LEASED_OPERATOR_DETAILS,
      nameOfFormControl: LmBopQuestion.LEASED_OPERATOR_DETAILS,
      labelText: 'Is the equipment leased or rented with or without operators?',
      options: {
        With: 'W',
        Without: 'WO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.UL_300_EXTINGUISHING_SYSTEM]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.UL_300_EXTINGUISHING_SYSTEM,
      nameOfFormControl: LmBopQuestion.UL_300_EXTINGUISHING_SYSTEM,
      labelText:
        'Is there a UL 300 chemical extinguishing system covering hoods, ducts and all surfaces of grills, ranges, deep fat fryers and broilers?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.EXTINGUISHING_SYSTEM_MAINTENANCE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.EXTINGUISHING_SYSTEM_MAINTENANCE,
      nameOfFormControl: LmBopQuestion.EXTINGUISHING_SYSTEM_MAINTENANCE,
      labelText:
        'Is there a contract for semi-annual inspection and maintenance of the extinguishing system, hood, filters, and ducts?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.BPP_INCLUDES_EQUIP_OVER_250]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.BPP_INCLUDES_EQUIP_OVER_250,
      nameOfFormControl: LmBopQuestion.BPP_INCLUDES_EQUIP_OVER_250,
      labelText:
        'Does your business personal property limit include any equipment valued over $500,000 per item (i.e. - magnetic resonance imaging - MRI, X-ray equipment, ultra sound technology, etc.)?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NAMED_INSURED_ON_GL_POLICY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.NAMED_INSURED_ON_GL_POLICY,
      nameOfFormControl: LmBopQuestion.NAMED_INSURED_ON_GL_POLICY,
      labelText:
        'Is the named insured included as an additional insured on the tenants general liability policy?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PROOF_READ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.PROOF_READ,
      nameOfFormControl: LmBopQuestion.PROOF_READ,
      labelText: 'Are customers required to proof-read prior to printing?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.SERVICE_WORK_SALES_1]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.SERVICE_WORK_SALES_1,
      nameOfFormControl: LmBopQuestion.SERVICE_WORK_SALES_1,
      labelText:
        "What are the insured's sales from any installation, service, or repair work generated from this location?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.SERVICE_WORK_SALES_2]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.SERVICE_WORK_SALES_2,
      nameOfFormControl: LmBopQuestion.SERVICE_WORK_SALES_2,
      labelText:
        "What are the insured's sales from any installation, service, or repair work generated from this location?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.SERVICE_WORK_SALES_3]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.SERVICE_WORK_SALES_3,
      nameOfFormControl: LmBopQuestion.SERVICE_WORK_SALES_3,
      labelText:
        "What are the insured's sales from any installation, service, or repair work generated from this location?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.SERVICE_WORK_SALES_4]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.SERVICE_WORK_SALES_4,
      nameOfFormControl: LmBopQuestion.SERVICE_WORK_SALES_4,
      labelText:
        "What are the insured's sales from any installation, service, or repair work generated from this location?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.SERVICE_WORK_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.SERVICE_WORK_DETAILS,
      nameOfFormControl: LmBopQuestion.SERVICE_WORK_DETAILS,
      labelText:
        "Please provide details regarding the insured's sales from installation, service or repair work.",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.FURNITURE_INSTALLATION_PERCENT]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.FURNITURE_INSTALLATION_PERCENT,
      nameOfFormControl: LmBopQuestion.FURNITURE_INSTALLATION_PERCENT,
      labelText:
        "What are the insured's sales from any installation, excluding delivery, generated from this location?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HOURS_OF_OPERATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HOURS_OF_OPERATION,
      nameOfFormControl: LmBopQuestion.HOURS_OF_OPERATION,
      labelText: 'Are any operations open 24 hours a day?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.COMPOUNDING_OR_MIXING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.COMPOUNDING_OR_MIXING,
      nameOfFormControl: LmBopQuestion.COMPOUNDING_OR_MIXING,
      labelText:
        'Does the applicant do any compounding or mixing of drugs? (Note: The practice of adding syrups or other flavoring agents that do not add any medicinal value to medications is not considered "compounding or mixing of drugs")',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.SECONDHAND_MATERIALS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.SECONDHAND_MATERIALS,
      nameOfFormControl: LmBopQuestion.SECONDHAND_MATERIALS,
      labelText: 'Does the insured sell second hand or used materials or merchandise?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.COMPUTER_PROGRAMMING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.COMPUTER_PROGRAMMING,
      nameOfFormControl: LmBopQuestion.COMPUTER_PROGRAMMING,
      labelText: 'Does the insured do computer programming for a fee?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.WEB_HOSTING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.WEB_HOSTING,
      nameOfFormControl: LmBopQuestion.WEB_HOSTING,
      labelText: 'Does the insured do development or hosting of websites?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.WIRING_YEAR]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.WIRING_YEAR,
      nameOfFormControl: LmBopQuestion.WIRING_YEAR,
      labelText: 'Wiring year',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.REFURBISHED_EQUIPMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.REFURBISHED_EQUIPMENT,
      nameOfFormControl: LmBopQuestion.REFURBISHED_EQUIPMENT,
      labelText: 'Does the insured sell used and/or refurbished goods or equipment?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE,
      nameOfFormControl: LmBopQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE,
      labelText:
        'Are the hoods, ductwork and flues cleaned by an outside service at least quarterly?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.CPUL_300_EXTINGUISHING_SYSTEM]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.CPUL_300_EXTINGUISHING_SYSTEM,
      nameOfFormControl: LmBopQuestion.CPUL_300_EXTINGUISHING_SYSTEM,
      labelText:
        'Is there a UL 300 fixed extinguishing system with automatic fuel cutoff protecting all cooking surfaces?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PERCENT_HABITATIONAL_OCCUPANTS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.PERCENT_HABITATIONAL_OCCUPANTS,
      nameOfFormControl: LmBopQuestion.PERCENT_HABITATIONAL_OCCUPANTS,
      labelText: 'What percentage of the premises contains habitational occupants?',
      maxLength: '3',
      inputType: 'text',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.SEASONAL_OPERATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.SEASONAL_OPERATION,
      nameOfFormControl: LmBopQuestion.SEASONAL_OPERATION,
      labelText: 'Are any of the operations seasonal?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.APPLICANT_SUBSIDIARIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.APPLICANT_SUBSIDIARIES,
      nameOfFormControl: LmBopQuestion.APPLICANT_SUBSIDIARIES,
      labelText:
        'Does the applicant have any subsidiaries or is the applicant a subsidiary of another entity?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PRIOR_COVERAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.PRIOR_COVERAGE,
      nameOfFormControl: LmBopQuestion.PRIOR_COVERAGE,
      labelText:
        'Was insurance coverage in force for the same exposures for the prior policy period (excluding new ventures)?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PRIOR_COVERAGE_REASON]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.PRIOR_COVERAGE_REASON,
      nameOfFormControl: LmBopQuestion.PRIOR_COVERAGE_REASON,
      labelText: 'Please choose a specific reason why',
      options: {
        'New acquisition for the insured': 'New acquisition for the insured',
        'No prior operations (business entity formed but no work performed or products made)':
          'No prior operations (business entity formed but no work performed or products made)',
        'No prior employees (WC)': 'No prior employees (WC)',
        'No prior owned autos': 'No prior owned autos',
        'New venture within last 3 months': 'New venture within last 3 months',
        'None of the above apply': 'None of the above apply',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LEASED_EQUIPMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LEASED_EQUIPMENT,
      nameOfFormControl: LmBopQuestion.LEASED_EQUIPMENT,
      labelText: 'Does the insured rent or lease equipment (other than carpet cleaners) to others?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS,
      nameOfFormControl: LmBopQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS,
      labelText:
        'Has the insured had 2 or more non-pay cancellations or 2 or more non-compliant audits in the last 3 years?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LRO_OFFICES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LRO_OFFICES,
      nameOfFormControl: LmBopQuestion.LRO_OFFICES,
      labelText:
        'Is more than 15% of this building (maximum of 7,500 square feet) occupied by other tenants that are not offices, but have property exposures similar to those contemplated for the BOP, such as eligible retail, wholesale or service classifications?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.BEAUTY_SHOP]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.BEAUTY_SHOP,
      nameOfFormControl: LmBopQuestion.BEAUTY_SHOP,
      labelText:
        'Are any services provided other than those typical to a beauty salon or barber shop? Typical services include hair cutting, styling, coloring, permanents, eyebrow waxing, and manicures/pedicures.',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OTHER_BUSINESS_INSD_ELSEWHERE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.OTHER_BUSINESS_INSD_ELSEWHERE,
      nameOfFormControl: LmBopQuestion.OTHER_BUSINESS_INSD_ELSEWHERE,
      labelText: 'Is other business a separate legal entity insured elsewhere?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.INCIDENTAL_COMMERCIAL_COOKING_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.INCIDENTAL_COMMERCIAL_COOKING_OPERATIONS,
      nameOfFormControl: LmBopQuestion.INCIDENTAL_COMMERCIAL_COOKING_OPERATIONS,
      labelText:
        'The majority of menu items (at least 75%) are baked (in oven and/or microwave) or heated on the stove top.',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LEARNING_CENTERS_EXTRA_CURRICULAR]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LEARNING_CENTERS_EXTRA_CURRICULAR,
      nameOfFormControl: LmBopQuestion.LEARNING_CENTERS_EXTRA_CURRICULAR,
      labelText:
        'Does the applicant offer any extra curricular activities (i.e. fields trips, special events, etc.)?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LEARNING_CENTER_BACKGROUND_CHECK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LEARNING_CENTER_BACKGROUND_CHECK,
      nameOfFormControl: LmBopQuestion.LEARNING_CENTER_BACKGROUND_CHECK,
      labelText:
        'Are background checks obtained (including all state sex offender registries) on all staff and/or volunteers who interact with students?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.INSURED_OR_EMPLOYEE_SERVE_ALCOHOL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.INSURED_OR_EMPLOYEE_SERVE_ALCOHOL,
      nameOfFormControl: LmBopQuestion.INSURED_OR_EMPLOYEE_SERVE_ALCOHOL,
      labelText: 'Does the insured and/or employees serve alcohol?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.INSURED_THIRD_PARTY_SERVE_ALCOHOL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.INSURED_THIRD_PARTY_SERVE_ALCOHOL,
      nameOfFormControl: LmBopQuestion.INSURED_THIRD_PARTY_SERVE_ALCOHOL,
      labelText: 'Does the insured utilize a third party to provide and/or serve alcohol?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.INSURED_ON_THIRD_PARTY_INSURANCE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.INSURED_ON_THIRD_PARTY_INSURANCE,
      nameOfFormControl: LmBopQuestion.INSURED_ON_THIRD_PARTY_INSURANCE,
      labelText:
        'Are proper risk transfer in place (insured is listed as AI, hold harmless language in favor of the insured, limits carried are at or able what the insured carries)?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.THREE_D_PRINTING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.THREE_D_PRINTING,
      nameOfFormControl: LmBopQuestion.THREE_D_PRINTING,
      labelText: 'Any 3D printing operations?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.DEEP_FAT_FRYER_NUMBER]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.DEEP_FAT_FRYER_NUMBER,
      nameOfFormControl: LmBopQuestion.DEEP_FAT_FRYER_NUMBER,
      labelText:
        'Number of deep fat fryers (if more than one location, indicate the maximum number of deep fat fryers at any one location).',
      options: {
        None: 'NONE',
        One: 'ONE',
        'Two or more': 'TWORMORE',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PET_RESCUE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.PET_RESCUE,
      nameOfFormControl: LmBopQuestion.PET_RESCUE,
      labelText:
        'Any pet-related operations involving pet rescue, adoptions or shelters performed by the insured, or for which the insured does not have a risk transfer to a separate organization?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.GREENHOUSE_PRESENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.GREENHOUSE_PRESENT,
      nameOfFormControl: LmBopQuestion.GREENHOUSE_PRESENT,
      labelText: 'Is there a greenhouse present at this location?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.RESTAURANT_EQUIP_INSTALL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.RESTAURANT_EQUIP_INSTALL,
      nameOfFormControl: LmBopQuestion.RESTAURANT_EQUIP_INSTALL,
      labelText:
        "Does applicant's business include installation or repair of restaurant furniture/equipment?",
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.EMPLOYEE_ON_DUTY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.EMPLOYEE_ON_DUTY,
      nameOfFormControl: LmBopQuestion.EMPLOYEE_ON_DUTY,
      labelText: 'Is there an employee on site at this location during all hours of operations?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.APPLIANCE_DELIVERY_SERVICE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.APPLIANCE_DELIVERY_SERVICE,
      nameOfFormControl: LmBopQuestion.APPLIANCE_DELIVERY_SERVICE,
      labelText: 'Does applicant haul goods for others?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED,
      nameOfFormControl: LmBopQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED,
      labelText:
        'Is the business operation closed, temporarily suspended or not expected to open within the next 30 days?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES,
      nameOfFormControl: LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES,
      labelText:
        'Are there any other business interests or activities of the named insured that are not identified or scheduled on this policy?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PERCENTAGE_OF_IMPORTED_MERCHANDISE]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.PERCENTAGE_OF_IMPORTED_MERCHANDISE,
      nameOfFormControl: LmBopQuestion.PERCENTAGE_OF_IMPORTED_MERCHANDISE,
      labelText:
        'What percentage of total sales are derived from merchandise that is directly imported, manufactured or distributed outside of the United States?',
      maxLength: '3',
      inputType: 'text',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.MOTORIZED_CONVEYANCE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.MOTORIZED_CONVEYANCE,
      nameOfFormControl: LmBopQuestion.MOTORIZED_CONVEYANCE,
      labelText:
        'Any sale of any type of motorized conveyance including ATVs, motorbikes, or scooters?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PERFORMING_REPAIRS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.PERFORMING_REPAIRS,
      nameOfFormControl: LmBopQuestion.PERFORMING_REPAIRS,
      labelText: 'Does applicant perform any repairing or renting of ladders or scaffolding?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OPERATIONS_INVOLVE_FLAMMABLES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.OPERATIONS_INVOLVE_FLAMMABLES,
      nameOfFormControl: LmBopQuestion.OPERATIONS_INVOLVE_FLAMMABLES,
      labelText: 'Are flammables used in the operations at this location?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION,
      nameOfFormControl: LmBopQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION,
      labelText: 'Does glass make up more than 50% of the exterior construction?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HOME_CARE_SERVICES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HOME_CARE_SERVICES,
      nameOfFormControl: LmBopQuestion.HOME_CARE_SERVICES,
      labelText: 'Do the operations of the risk include home care services of any kind?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.GROSS_RECEIPTS_FROM_ASSEMBLING_BICYCLES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.GROSS_RECEIPTS_FROM_ASSEMBLING_BICYCLES,
      nameOfFormControl: LmBopQuestion.GROSS_RECEIPTS_FROM_ASSEMBLING_BICYCLES,
      labelText:
        'Are more than 10% of the gross annual receipts generated from the sale, assembly, or repair of bicycles?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ATYPICAL_DAY_SPA_SERVICES_PROVIDED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.ATYPICAL_DAY_SPA_SERVICES_PROVIDED,
      nameOfFormControl: LmBopQuestion.ATYPICAL_DAY_SPA_SERVICES_PROVIDED,
      labelText:
        'Are any services provided other than those that are typical to a day spa? (Typical services include all services usual to a beauty salon, as well as facials and facial massages, body massages (professional liability must be in place for exposure), spray-on tanning and other similar services.) chemical peels, permanent make-up applications, tattooing, body piercing, tanning operations (incidental may be acceptable, but coverage is excluded), hair implantation, wart or mole removal, chiropody, cryotherapy and other more hazardous services are ineligible. See medical spa class if medical spa treatments are performed (note that medical spas offering cryotherapy are not eligible).',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.DONUT_FRYER_UL_PROTECTED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.DONUT_FRYER_UL_PROTECTED,
      nameOfFormControl: LmBopQuestion.DONUT_FRYER_UL_PROTECTED,
      labelText:
        'Is the donut fryer a UL-approved self-contained unit or fully protected by a UL 300 chemical extinguishing system?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LOCATION_ADULT_MATERIAL_SALES]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.LOCATION_ADULT_MATERIAL_SALES,
      nameOfFormControl: LmBopQuestion.LOCATION_ADULT_MATERIAL_SALES,
      labelText: "What are the insured's sales from adult materials at this location?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.CLEAN_EQUIPMENT_ACCORDING_TO_MANUFACTURER]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.CLEAN_EQUIPMENT_ACCORDING_TO_MANUFACTURER,
      nameOfFormControl: LmBopQuestion.CLEAN_EQUIPMENT_ACCORDING_TO_MANUFACTURER,
      labelText:
        'Does the applicant clean and disinfect all instruments and equipment according to the manufacturers instructions?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.INVOLVE_JOBSITE_MANAGEMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.INVOLVE_JOBSITE_MANAGEMENT,
      nameOfFormControl: LmBopQuestion.INVOLVE_JOBSITE_MANAGEMENT,
      labelText: 'Do the operations of the applicant involve any jobsite or project management?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.REPACKAGING_PRODUCTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.REPACKAGING_PRODUCTS,
      nameOfFormControl: LmBopQuestion.REPACKAGING_PRODUCTS,
      labelText:
        'Is the insured involved in any manufacturing, mixing, relabeling or repackaging of products?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.RECREATIONAL_EQUIPMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.RECREATIONAL_EQUIPMENT,
      nameOfFormControl: LmBopQuestion.RECREATIONAL_EQUIPMENT,
      labelText:
        'Does insured rent or lease any equipment such as skis, snowboards, bicycles or similar equipment?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HAS_WATERCRAFT_SALE_LIABILITY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HAS_WATERCRAFT_SALE_LIABILITY,
      nameOfFormControl: LmBopQuestion.HAS_WATERCRAFT_SALE_LIABILITY,
      labelText: 'Does insured sell any motorized watercraft at this location?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.DONUT_FRYER]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.DONUT_FRYER,
      nameOfFormControl: LmBopQuestion.DONUT_FRYER,
      labelText: 'Is there a donut fryer at this location?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.TOTAL_SALES_FROM_DIRECTLY_IMPORTED]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.TOTAL_SALES_FROM_DIRECTLY_IMPORTED,
      nameOfFormControl: LmBopQuestion.TOTAL_SALES_FROM_DIRECTLY_IMPORTED,
      labelText:
        "What are the insured's sales at this location from directly imported merchandise from manufacturers or distributors not domiciled in the United States?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PROFESSIONAL_SERVICES_MEDICAL_LIABILITY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.PROFESSIONAL_SERVICES_MEDICAL_LIABILITY,
      nameOfFormControl: LmBopQuestion.PROFESSIONAL_SERVICES_MEDICAL_LIABILITY,
      labelText:
        'Does the insured have professional liability coverage in place for all medical and non-medical professional services they provide with limits that are equal to or greater than those requested on this application? Note: Operations involving cryotherapy are not eligible.',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PROFESSIONAL_SERVICES_LIABILITY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.PROFESSIONAL_SERVICES_LIABILITY,
      nameOfFormControl: LmBopQuestion.PROFESSIONAL_SERVICES_LIABILITY,
      labelText:
        'Does the insured have professional liability coverage currently in force with another carrier?',
      options: {
        Yes: 'YES',
        No: 'NO',
        'N/A': 'NA',
      },
      optionsCssClass: 'radio-question-option__no-transform',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.FRYING_ON_PREMISES_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.FRYING_ON_PREMISES_1,
      nameOfFormControl: LmBopQuestion.FRYING_ON_PREMISES_1,
      labelText:
        'Does the restaurant tenant(s) in the building engage in any of the following types of cooking: frying, grilling, broiling, barbequeing or other cooking that produces grease laden vapors?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.FRYING_ON_PREMISES_2]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.FRYING_ON_PREMISES_2,
      nameOfFormControl: LmBopQuestion.FRYING_ON_PREMISES_2,
      labelText:
        'Is there any of the following types of cooking operations at this location: frying, grilling, broiling, barbequing or other cooking that produces grease laden vapors?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LOCATION_FIREARME_SALES]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.LOCATION_FIREARME_SALES,
      nameOfFormControl: LmBopQuestion.LOCATION_FIREARME_SALES,
      labelText:
        "What are the insured's total sales from firearms and ammunition at this location?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LOCATION_WEB_SALES]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmBopQuestion.LOCATION_WEB_SALES,
      nameOfFormControl: LmBopQuestion.LOCATION_WEB_SALES,
      labelText:
        "What are the total sales transacted from this location on the insured's web site?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.DRUGGIST_BACKGROUND_CHECKS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.DRUGGIST_BACKGROUND_CHECKS,
      nameOfFormControl: LmBopQuestion.DRUGGIST_BACKGROUND_CHECKS,
      labelText:
        "Do the applicant's business practices include all of the following: background checks for all employees, implementation of appropriate drug distribution tracking procedures, storage and handling of prescription narcotics that meet the operating state's requirements?",
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LIQUOR_WRITTEN_GUIDELINES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LIQUOR_WRITTEN_GUIDELINES,
      nameOfFormControl: LmBopQuestion.LIQUOR_WRITTEN_GUIDELINES,
      labelText: 'Are all employees serving alcohol properly trained?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OPTOMETRISTS_SURGERY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.OPTOMETRISTS_SURGERY,
      nameOfFormControl: LmBopQuestion.OPTOMETRISTS_SURGERY,
      labelText:
        "Do the applicant's operations involve surgical procedures of any kind, including, but not limited to laser surgery?",
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OPTOMETRIST_LICENSED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.OPTOMETRIST_LICENSED,
      nameOfFormControl: LmBopQuestion.OPTOMETRIST_LICENSED,
      labelText: 'Is the applicant a licensed optometrist?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.OPTOMETRIST_PRESCRIPTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.OPTOMETRIST_PRESCRIPTION,
      nameOfFormControl: LmBopQuestion.OPTOMETRIST_PRESCRIPTION,
      labelText: 'Does the applicant prescribe pain medication that has high abuse potential?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LEASED_EQUIPMENT_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.LEASED_EQUIPMENT_DETAILS,
      nameOfFormControl: LmBopQuestion.LEASED_EQUIPMENT_DETAILS,
      labelText:
        'Please provide details regarding the insured renting or leasing equipment to others.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.APPLICANT_SUBSIDIARIES_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.APPLICANT_SUBSIDIARIES_DETAILS,
      nameOfFormControl: LmBopQuestion.APPLICANT_SUBSIDIARIES_DETAILS,
      labelText: 'Please provide details regarding the subsidiary relationship.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.MAJORITY_INSURED_SALES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.MAJORITY_INSURED_SALES,
      nameOfFormControl: LmBopQuestion.MAJORITY_INSURED_SALES,
      labelText:
        "Are the majority of internet sales from this location (over 50%) generated from inventory stored on the insured's premises?",
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.MEDICINAL_MARIJUANA_OPERATIONS_TYPE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.MEDICINAL_MARIJUANA_OPERATIONS_TYPE,
      nameOfFormControl: LmBopQuestion.MEDICINAL_MARIJUANA_OPERATIONS_TYPE,
      labelText:
        'Does the insureds operations involve growing, storing, selling, dispensing, or otherwise providing access to medically-prescribed marijuana?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL,
      nameOfFormControl: LmBopQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL,
      labelText:
        'Is the extinguishing system serviced at least on a semi-annual basis by a licensed contractor?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_1,
      nameOfFormControl: LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_1,
      labelText:
        'Has there ever been a citation issued by an alcoholic beverage commission or other government regulator for any location that sells and/or serves alcoholic beverages?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_2]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_2,
      nameOfFormControl: LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_2,
      labelText:
        'Has there ever been a citation issued by an alcoholic beverage commission or other government regulator for any location that sells and/or serves alcoholic beverages?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HAS_LEAD_ABATEMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HAS_LEAD_ABATEMENT,
      nameOfFormControl: LmBopQuestion.HAS_LEAD_ABATEMENT,
      labelText: 'Has the operations undergone lead abatement?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.PRIOR_COVERAGE_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.PRIOR_COVERAGE_DETAILS,
      nameOfFormControl: LmBopQuestion.PRIOR_COVERAGE_DETAILS,
      labelText: 'Please Describe',
      minLength: '1',
      maxLength: '250',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS,
      nameOfFormControl: LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS,
      labelText:
        'Please provide details regarding other business interests or activities of the named insured that are not insured',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.REFURBISHED_EQUIPMENT_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmBopQuestion.REFURBISHED_EQUIPMENT_DETAILS,
      nameOfFormControl: LmBopQuestion.REFURBISHED_EQUIPMENT_DETAILS,
      labelText: 'Please provide details regarding the sale of used and/or refurbished equipment.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.EPLI_CLAIMS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.EPLI_CLAIMS,
      nameOfFormControl: LmBopQuestion.EPLI_CLAIMS,
      labelText:
        'Has the insured been involved in any EPLI claims regardless of whether any payment or not, or does the insured have knowledge of any situation(s) that could produce an EPLI claim?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.TENANT_OPERATIONS_TYPE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.TENANT_OPERATIONS_TYPE,
      nameOfFormControl: LmBopQuestion.TENANT_OPERATIONS_TYPE,
      labelText:
        'Is any of the space leased to tenants used for business operations that do not qualify for coverage on the commercial protector (BOP)? Examples include:',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      ul: {
        li: [
          'Manufacturing',
          'Metal working or welding',
          'Restaurants or other operations with commercial cooking exposures',
          'Spray painting',
          'Autobody work',
          'Woodworking',
          'Storage/manufacturing of flammables',
          'Growing, processing, storing or dispensing marijuana',
        ],
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.LPG_SALES_RECEIPTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.LPG_SALES_RECEIPTS,
      nameOfFormControl: LmBopQuestion.LPG_SALES_RECEIPTS,
      labelText:
        'Are any receipts derived from the sale of LPG (do not include receipts generated from the sale of small pre-filled propane containers or the sale or exchange of empty propane receptacles)?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ARSON_CONVICTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.ARSON_CONVICTION,
      nameOfFormControl: LmBopQuestion.ARSON_CONVICTION,
      labelText:
        'During the last five years (ten in RI), has any applicant been indicted for or convicted of any degree of the crime of fraud, bribery, arson or any other arson-related crime?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.RESTAURANT_BOH_ACTIVITIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.RESTAURANT_BOH_ACTIVITIES,
      nameOfFormControl: LmBopQuestion.RESTAURANT_BOH_ACTIVITIES,
      labelText: 'Has applicant been closed by the board of health in the last three years?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ROOF_CONSTRUCTION]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmBopQuestion.ROOF_CONSTRUCTION,
      nameOfFormControl: LmBopQuestion.ROOF_CONSTRUCTION,
      labelText: 'Roof construction',
      options: {
        ASPHS: 'Asphalt Shingles',
        TARGRB: 'Built Up (with gravel)',
        BLTXGRVL: 'Built Up Without Gravel (Smooth Surface)',
        CLAYCONC: 'Clay or Concrete Tile',
        FOAM: 'Foam (sprayed on)',
        METL: 'Metal',
        MODBITUMEN: 'Modified Bitumen',
        MEMSINGLEBALL: 'Single Ply Membrane (ballasted with smooth stone or paving blocks)',
        SPMSEPDMRUBB: 'Single Ply Membrane (EPDM, Rubber)',
        SPMSPVCTPO: 'Single Ply Membrane (PVC, TPO)',
        WOODSS: 'Wood Shingles/Shakes',
        UNKNOWN: 'Unknown',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.ROOF_TYPE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.ROOF_TYPE,
      nameOfFormControl: LmBopQuestion.ROOF_TYPE,
      labelText: 'Roof type',
      options: {
        'Other than Wind Tolerant': 'OT',
        'Wind Resistant': 'WR',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.YEAR_ROOF_REPLACED]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmBopQuestion.YEAR_ROOF_REPLACED,
      nameOfFormControl: LmBopQuestion.YEAR_ROOF_REPLACED,
      labelText: 'Year roof replaced',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HAS_SINKHOLE_CLAIMS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HAS_SINKHOLE_CLAIMS,
      nameOfFormControl: LmBopQuestion.HAS_SINKHOLE_CLAIMS,
      labelText: 'Have there been any sinkhole claims on this location?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmBopQuestion.HAS_SINKHOLE_DAMAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmBopQuestion.HAS_SINKHOLE_DAMAGE,
      nameOfFormControl: LmBopQuestion.HAS_SINKHOLE_DAMAGE,
      labelText: 'Are there any visible signs of sinkhole activity or damage at this location?',
      options: {
        Yes: 'YES',
        No: 'NO',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
};

// Setup text lengh validation for BOP quuestions
setupTextLengthExceededErrorMessage(BOP_FORM_QUESTIONS, TEXT_CHAR_LIMIT);

export const PERCENT_FORM_FIELDS = [
  LmBopQuestion.PERCENT_HABITATIONAL_OCCUPANTS,
  LmBopQuestion.PERCENTAGE_OF_IMPORTED_MERCHANDISE,
];

export const PHONE_FORM_FIELDS = keys(
  pickBy(BOP_FORM_QUESTIONS, (value: FormDslConfiguration) => {
    return value.formDslNode.primitive === 'PHONE';
  })
);

export const PHONE_FORM_ARRAY_FIELDS = keys(
  pickBy(ARRAY_CONTROLS, (value: FormDslConfiguration) => {
    return value.formDslNode.primitive === 'PHONE';
  })
);

export const YEAR_FORM_FIELDS = [
  LmBopQuestion.YEAR_BUILT,
  LmBopQuestion.YEAR_ROOF_REPLACED,
  LmBopQuestion.YEAR_BUSINESS_STARTED,
  LmBopQuestion.HEATING_YEAR,
  LmBopQuestion.PLUMBING_YEAR,
  LmBopQuestion.WIRING_YEAR,
];
