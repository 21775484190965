import { Observable, pipe, retry, timer } from 'rxjs';

/**
 * Helper function for retrying an observable (typically an api call).
 *
 * Reference:
 * https://angular.io/guide/practical-observable-usage#exponential-backoff
 *
 * When the source observable throws an error it will be retried exponentially
 * longer based off how many times it has been previously retried.
 * If the source observable has been retried more than maxTries times it will
 * instead throw the error.
 */
export const retryWithBackoff = <T>(maxTries: number, interval: number) =>
  pipe<Observable<T>, Observable<T>>(
    retry({
      count: maxTries,
      delay: (_error, retryIndex) => {
        const delay = Math.pow(2, retryIndex - 1) * interval;
        return timer(delay);
      },
    })
  );
