import {
  ValidatorDictionary,
  ComplexValidatorDictionary,
  FormArrayValidators,
} from '../../../shared/form-dsl/constants/form-dsl-typings';

export enum LmBopFormStepPath {
  POLICY_INFO = 'policyInfo',
  LOSS_HISTORY = 'lossHistory',
  LOCATION_DETAILS = 'locationDetails',
  LIABILITY_COVERAGE = 'liabilityCoverage',
}

// If any IDs are renamed in `LmBopQuestion`, then they will also need to be
// updated in `service-quote`. If a new ID is added and it corresponds to a node
// in the XML request to LM's quote API, then it will need to be added in
// `service-quote` as well.
export enum LmBopQuestion {
  ALARM_TYPE = 'alarm-type',
  ANNUAL_DRY_CLEANING_RECEIPTS_AT_THIS_LOCATION = 'annual-dry-cleaning-receipts-at-this-location',
  ANNUAL_SALES_RECEIPTS = 'annual-sales-receipts',
  APPLIANCE_DELIVERY_SERVICE = 'appliance-delivery-service',
  APPLICANT_SUBSIDIARIES = 'applicant-subsidiaries',
  APPLICANT_SUBSIDIARIES_DETAILS = 'applicant-subsidiaries-details',
  ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING = 'are-there-any-habitational-exposures-in-this-building',
  AREA_OCCUPIED_BY_INSURED = 'area-occupied-by-insured',
  AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS = 'area-occupied-by-other-building-tenants',
  AREA_UNOCCUPIED = 'area-unoccupied',
  AREA_UNOCCUPIED_DETAILS = 'area-unoccupied-details',
  ARSON_CONVICTION = 'arson-conviction',
  ATYPICAL_DAY_SPA_SERVICES_PROVIDED = 'atypical-day-spa-services-provided',
  BEAUTY_SHOP = 'beauty-shop',
  BIZ_OPS_CLOSEDOR_SUSPENDED = 'biz-ops-closedor-suspended',
  BOP_CLASS_CODE = 'bop-class-code',
  BPP_INCLUDES_EQUIP_OVER_250 = 'bpp-includes-equip-over-250',
  BUILDING_LIMIT_OF_INSURANCE = 'building-limit-of-insurance',
  BUSINESS_ADDRESS = 'business-address',
  BUSINESS_PERSONAL_PROPERTY_LIMIT = 'business-personal-property-limit',
  CLEAN_EQUIPMENT_ACCORDING_TO_MANUFACTURER = 'clean-equipment-according-to-manufacturer',
  COMPOUNDING_OR_MIXING = 'compounding-or-mixing',
  COMPUTER_PROGRAMMING = 'computer-programming',
  CONSTRUCTION_TYPE = 'construction-type',
  CP_EXTING_SYS_SERV_SEMI_ANNUAL = 'cp-exting-sys-serv-semi-annual',
  CPUL_300_EXTINGUISHING_SYSTEM = 'cpul-300-extinguishing-system',
  CYBER_COVERAGE = 'cyber-coverage',
  DATA_COMPROMISE_RESPONSE_EXPENSES = 'data-compromise-response-expenses',
  DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT = 'data-compromise-response-expenses-limit',
  DEEP_FAT_FRYER_NUMBER = 'deep-fat-fryer-number',
  DESCRIPTION_OF_OPERATIONS = 'description-of-operations',
  DONUT_FRYER = 'donut-fryer',
  DONUT_FRYER_UL_PROTECTED = 'donut-fryer-ul-protected',
  DRUGGIST_BACKGROUND_CHECKS = 'druggist-background-checks',
  EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES = 'each-claim-aggregate-limit-employment-practices',
  EFFECTIVE_DATE = 'effective-date',
  EMP_COV_MIN_REQUIRED = 'emp-cov-min-required',
  EMPLOYEE_ON_DUTY = 'employee-on-duty',
  EPLI = 'epli',
  EPLI_CLAIMS = 'epli-claims',
  EXTINGUISHING_SYSTEM_MAINTENANCE = 'extinguishing-system-maintenance',
  FORM_OF_BUSINESS = 'form-of-business',
  FRYING_ON_PREMISES_1 = 'frying-on-premises-1',
  FRYING_ON_PREMISES_2 = 'frying-on-premises-2',
  FULL_TIME_BARBERS_AT_THIS_LOCATION = 'full-time-barbers-at-this-location',
  FULL_TIME_BEAUTICIANS_AT_THIS_LOCATION = 'full-time-beauticians-at-this-location',
  FURNITURE_INSTALLATION_PERCENT = 'furniture-installation-percent',
  GLASS_ON_EXTERIOR_CONSTRUCTION = 'glass-on-exterior-construction',
  GREENHOUSE_PRESENT = 'greenhouse-present',
  GROSS_RECEIPTS_FROM_ASSEMBLING_BICYCLES = 'gross-receipts-from-assembling-bicycles',
  HAS_DELIVERY_SERVICE = 'has-delivery-service',
  HAS_LEAD_ABATEMENT = 'has-lead-abatement',
  HAS_SINKHOLE_CLAIMS = 'has-sinkhole-claims',
  HAS_SINKHOLE_DAMAGE = 'has-sinkhole-damage',
  HAS_WATERCRAFT_SALE_LIABILITY = 'has-watercraft-sale-liability',
  HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED = 'have-there-been-losses-for-the-lines-of-business-submitted',
  HEARING_AID_PROFESSIONAL_LIABILITY = 'hearing-aid-professional-liability',
  HEATING_YEAR = 'heating-year',
  HIRED_AND_NON_OWNED_AUTO_LIABILITY = 'hired-and-non-owned-auto-liability',
  HIRED_AUTO_PHYSICAL_DAMAGE = 'hired-auto-physical-damage',
  HOME_CARE_SERVICES = 'home-care-services',
  HOURS_OF_OPERATION = 'hours-of-operation',
  INCIDENTAL_COMMERCIAL_COOKING_OPERATIONS = 'incidental-commercial-cooking-operations',
  INSURED_ON_THIRD_PARTY_INSURANCE = 'insured-on-third-party-insurance',
  INSURED_OR_EMPLOYEE_SERVE_ALCOHOL = 'insured-or-employee-serve-alcohol',
  INSURED_PHONE_NUMBER = 'insured-phone-number',
  INSURED_THIRD_PARTY_SERVE_ALCOHOL = 'insured-third-party-serve-alcohol',
  INTEREST = 'interest',
  INVOLVE_JOBSITE_MANAGEMENT = 'involve-jobsite-management',
  LEARNING_CENTER_BACKGROUND_CHECK = 'learning-center-background-check',
  LEARNING_CENTERS_EXTRA_CURRICULAR = 'learning-centers-extra-curricular',
  LEASED_DETAILS = 'leased-details',
  LEASED_EQUIPMENT = 'leased-equipment',
  LEASED_EQUIPMENT_DETAILS = 'leased-equipment-details',
  LEASED_OPERATOR_DETAILS = 'leased-operator-details',
  LIMIT_OF_INSURANCE_PRINTERS_WORK_CORRECTION = 'limit-of-insurance-printers-work-correction',
  LIQUOR_CONTROL_VIOLATIONS_1 = 'liquor-control-violations-1',
  LIQUOR_CONTROL_VIOLATIONS_2 = 'liquor-control-violations-2',
  LIQUOR_COVERAGE = 'liquor-coverage',
  LIQUOR_LIABILITY_TYPE = 'liquor-liability-type',
  LIQUOR_WRITTEN_GUIDELINES = 'liquor-written-guidelines',
  LOCATION_ADULT_MATERIAL_SALES = 'location-adult-material-sales',
  LOCATION_FIREARME_SALES = 'location-firearme-sales',
  LOCATION_WEB_SALES = 'location-web-sales',
  LOSSES = 'losses',
  LPG_SALES_RECEIPTS = 'lpg-sales-receipts',
  LRO_OFFICES = 'lro-offices',
  MAJORITY_INSURED_SALES = 'majority-insured-sales',
  MANICURISTS_AT_THIS_LOCATION = 'manicurists-at-this-location',
  MEDICINAL_MARIJUANA_OPERATIONS_TYPE = 'medicinal-marijuana-operations-type',
  MOTORIZED_CONVEYANCE = 'motorized-conveyance',
  NAMED_INSD_OTHER_ACTIVITIES = 'named-insd-other-activities',
  NAMED_INSD_OTHER_ACTIVITIES_DETAILS = 'named-insd-other-activities-details',
  NAMED_INSURED_ON_GL_POLICY = 'named-insured-on-gl-policy',
  NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS = 'non-pay-cancellations-or-non-compliant-audits',
  NUMBER_OF_EMPLOYEES_VETERINARIANS_ASSISTANTS_VETERINARIANS_STAFF = 'number-of-employees-veterinarians-assistants-veterinarians-staff',
  NUMBER_OF_PROFESSIONALS_HEARING_AID_PROFESSIONAL_LIABILITY = 'number-of-professionals-hearing-aid-professional-liability',
  NUMBER_OF_PROFESSIONALS_OPTICAL_PROFESSIONAL_LIABILITY = 'number-of-professionals-optical-professional-liability',
  NUMBER_OF_PROFESSIONALS_OPTOMETRISTS_PROFESSIONAL_LIABILITY = 'number-of-professionals-optometrists-professional-liability',
  NUMBER_OF_VETERINARIANS_OWNERS_PARTNERS_OFFICERS = 'number-of-veterinarians-owners-partners-officers',
  NUMBER_STORIES = 'number-stories',
  OPERATIONS_INVOLVE_FLAMMABLES = 'operations-involve-flammables',
  OPTICAL_PROFESSIONAL_LIABILITY = 'optical-professional-liability',
  OPTOMETRIST_LICENSED = 'optometrist-licensed',
  OPTOMETRIST_PRESCRIPTION = 'optometrist-prescription',
  OPTOMETRISTS_PROFESSIONAL_LIABILITY = 'optometrists-professional-liability',
  OPTOMETRISTS_SURGERY = 'optometrists-surgery',
  OTHER_BUSINESS_INSD_ELSEWHERE = 'other-business-insd-elsewhere',
  OWN_PRODUCTS = 'own-products',
  OWNED_AUTO = 'owned-auto',
  PART_TIME_BARBERS_AT_THIS_LOCATION = 'part-time-barbers-at-this-location',
  PART_TIME_BEAUTICIANS_AT_THIS_LOCATION = 'part-time-beauticians-at-this-location',
  PERCENT_HABITATIONAL_OCCUPANTS = 'percent-habitational-occupants',
  PERCENTAGE_OF_IMPORTED_MERCHANDISE = 'percentage-of-imported-merchandise',
  PERFORMING_REPAIRS = 'performing-repairs',
  PERSONAL_AUTO_EMPLOYEE_USE = 'personal-auto-employee-use',
  PET_RESCUE = 'pet-rescue',
  PLUMBING_YEAR = 'plumbing-year',
  PRIMARY_RISK_STATE = 'primary-risk-state',
  PRINTERS_ANNUAL_RECEIPTS_AT_THIS_LOCATION_PRINTERS_E_O = 'printers-annual-receipts-at-this-location-printers-e-o',
  PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY = 'printers-errors-and-omissions-liability',
  PRINTERS_WORK_CORRECTION_PRINTERS_E_O = 'printers-work-correction-printers-e-o',
  PRIOR_COVERAGE = 'prior-coverage',
  PRIOR_COVERAGE_DETAILS = 'prior-coverage-details',
  PRIOR_COVERAGE_REASON = 'prior-coverage-reason',
  PROFESSIONAL_SERVICES_LIABILITY = 'professional-services-liability',
  PROFESSIONAL_SERVICES_MEDICAL_LIABILITY = 'professional-services-medical-liability',
  PROOF_READ = 'proof-read',
  RARE_ANIMAL_TREATMENT = 'rare-animal-treatment',
  RECREATIONAL_EQUIPMENT = 'recreational-equipment',
  REFURBISHED_EQUIPMENT = 'refurbished-equipment',
  REFURBISHED_EQUIPMENT_DETAILS = 'refurbished-equipment-details',
  REPACKAGING_PRODUCTS = 'repackaging-products',
  RESTAURANT_BOH_ACTIVITIES = 'restaurant-boh-activities',
  RESTAURANT_EQUIP_INSTALL = 'restaurant-equip-install',
  RESTAURANTS_OUTSIDE_CLEANING_SERVICE = 'restaurants-outside-cleaning-service',
  ROOF_CONSTRUCTION = 'roof-construction',
  ROOF_TYPE = 'roof-type',
  SEASONAL_OPERATION = 'seasonal-operation',
  SECONDHAND_MATERIALS = 'secondhand-materials',
  SERVICE_WORK_DETAILS = 'service-work-details',
  SERVICE_WORK_SALES_1 = 'service-work-sales-1',
  SERVICE_WORK_SALES_2 = 'service-work-sales-2',
  SERVICE_WORK_SALES_3 = 'service-work-sales-3',
  SERVICE_WORK_SALES_4 = 'service-work-sales-4',
  TENANT_OPERATIONS_TYPE = 'tenant-operations-type',
  THREE_D_PRINTING = '3-d-printing',
  TOTAL_ANNUAL_LIQUOR_RECEIPTS_AT_THIS_LOCATION = 'total-annual-liquor-receipts-at-this-location',
  TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS = 'total-number-of-employees-at-all-locations',
  TOTAL_RECEIPTS_FOR_LOCATION = 'total-receipts-for-location',
  TOTAL_SALES_FROM_DIRECTLY_IMPORTED = 'total-sales-from-directly-imported',
  UL_300_EXTINGUISHING_SYSTEM = 'ul-300-extinguishing-system',
  UM_UIM_LOSS_TYPE = 'um-uim-loss-type',
  UNINSURED_UNDERINSURED_MOTORIST_LIMIT = 'uninsured-underinsured-motorist-limit',
  USE_OF_FLAMMABLES = 'use-of-flammables',
  VETERINARIAN_PROFESSIONAL_LIABILITY = 'veterinarian-professional-liability',
  WEB_HOSTING = 'web-hosting',
  WIRING_YEAR = 'wiring-year',
  YEAR_BUILT = 'year-built',
  YEAR_BUSINESS_STARTED = 'year-business-started',
  YEAR_ROOF_REPLACED = 'year-roof-replaced',
  YEARS_MANAGEMENT_EXPERIENCE = 'years-management-experience',
  YEARS_MANAGEMENT_EXPERIENCE_DETAILS = 'years-management-experience-details',
}

export enum LmBopLossHistoryQuestion {
  ALLOCATED_ADJUSTER_EXPENSE = 'allocated-adjuster-expense',
  AMOUNT_PAID = 'amount-paid',
  AMOUNT_RESERVED = 'amount-reserved',
  CLAIM_STATUS = 'claim-status',
  DATE_OF_LOSS = 'date-of-loss',
  LOSS_DESCRIPTION = 'loss-description',
  LOSS_TYPE = 'loss-type',
}

export type LmBopFormValue = string | number | boolean | null;

export type LmBopLossGroupValue = { [key in LmBopLossHistoryQuestion]: LmBopFormValue };

// Properties present on submission payload but not in form. If any additional
// IDs are added here or any are renamed, then the equivalent change also needs
// to be made in `service-quote`.
export enum LmBopSubmissionField {
  ADDR_STREET_1 = 'street-address-1',
  ADDR_STREET_2 = 'street-address-2',
  ADDR_CITY = 'city',
  ADDR_STATE = 'state',
  ADDR_ZIP = 'zipcode',
  CLASS_CODE_DESC = 'business-class-description',
  LINE_OF_BUSINESS = 'line-of-business',
  RETROACTIVE_DATE_EMPLOYMENT_PRACTICES = 'retroactive-date-employment-practices',
  LIQUOR_LIABILITY = 'liquor-liability',
  BYOB_LIQUOR_LIABILITY_COVERAGE = 'byob-liquor-liability-coverage',
}

export type LmBopFormData = {
  [key in LmBopQuestion | LmBopSubmissionField]?: string | number | true | LmBopLossGroupValue[];
};

// FormArray controls are handled differently for dependencies and
// validators. If additional FormArrays are added, their enum should
// be included in this type.
export type LmBopNestedControls = LmBopLossHistoryQuestion;

export enum LmBopComplexEvaluator {
  AREA_OCC_BY_INS_AND_BPP_OVER_0 = 'area-occupied-by-insured-and-personal-property-limit-greater-than-zero',
  AREA_OCC_BY_INS_AND_BPP_OVER_500K = 'area-occupied-by-insured-and-personal-property-limit-greater-than-five-hundred-thousand',
  BLDG_OCC_BY_INS_AND_BPP_OVER_0 = 'building-occupied-by-insured-and-personal-property-limit-greater-than-zero',
  FRYING_ON_PREMISES = 'frying-on-premises',
  HAS_BLDG_CVG_AND_HAS_TENANTS = 'building-coverage-provided-for-building-occupied-by-tenants',
  HIRED_OR_NON_OWNED_AUTO_SELECTED = 'hired-and-or-non-owned-auto-coverage-selected',
  INS_OWNED_BLDG_CVG_AND_HAS_TENANTS = 'building-coverage-provided-for-building-owned-by-named-insured-and-building-occupied-by-tenants',
  LIQ_LIABILITY_OR_BYOB = 'liquor-liability-or-byob-liquor-liability-coverage-selected',
  UNDER_1M_SALES_AND_UNDER_10_EMP = 'total-annual-sales-must-be-less-than-1-m-and-total-employess-less-than-10',
  OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K = 'built-more-than-24-years-in-past-and-building-limit-greater-than-zero-or-bpp-greater-than-five-hundred-thousand',
  PRIOR_COVERAGE_REASON_IS_NEW_ACQUISITION_FOR_THE_INSURED_OR_NONE_OF_THE_ABOVE = 'prior-coverage-reason-is-new-acquisition-for-the-insured-or-none-of-the-above',
  SERVICE_WORK_SALES_OVER_0 = 'service-works-sales-greater-than-zero',
}

export type LmBopValidators = ValidatorDictionary<LmBopQuestion | LmBopFormStepPath>;
export type LmBopComplexValidators = ComplexValidatorDictionary<LmBopQuestion | LmBopFormStepPath>;
export type LmBopArrayValidators = FormArrayValidators<LmBopQuestion, LmBopNestedControls>;
