<div
  class="dialog-text dialog-text__margin-bottom dialog-text__full-width"
  *ngIf="showDialogBox()"
  [class]="getClassName()"
>
  <div class="dialog-text-icon-wrap">
    <div class="dialog-text-content">
      <p class="p p__bold dialog-text-header" *ngIf="header">{{ header }}</p>
      <ng-content></ng-content>
      <div *ngIf="dismissable" class="dialog-text-links">
        <a
          class="dialog-text-link"
          (click)="dismiss()"
          (keyup.enter)="dismiss()"
          data-amplitude-input-name="Dismiss"
          >Dismiss</a
        >
      </div>
    </div>
  </div>
</div>
