// TODO: use Launch Darkly to determine producer codes so we're not taking up 500 lines in a constants file
export const eligibilityLiveChatProducers = [
  'AANAKA00001',
  'ABA00001',
  'ABCINSGRPS00001',
  'ABCIS00001',
  'ABMCONPRS00001',
  'ABOUTU00001',
  'ABSOLU00001',
  'ACAPITAL00001',
  'ACCING00001',
  'ACENCGA00001',
  'ACGBIA00001',
  'ACGBLIA00001',
  'ACGBUN00001',
  'ACGCEIF00001',
  'ACGCIG00001',
  'ACGDCA00001',
  'ACGDEER00001',
  'ACGDIA00001',
  'ACGGAM00001',
  'ACGHERI00001',
  'ACGHIA00001',
  'ACGHRA00001',
  'ACGIIFS00001',
  'ACGJBA00001',
  'ACGJCG00001',
  'ACGJKI00001',
  'ACGJOIG00001',
  'ACGLIA00001',
  'ACGLKIF00001',
  'ACGMANS00001',
  'ACGMEAR00001',
  'ACGMGIA00001',
  'ACGMIF00001',
  'ACGMIFS00001',
  'ACGNEWM00001',
  'ACGOST00001',
  'ACGREG00001',
  'ACGREIA00001',
  'ACGSINGH00001',
  'ACGSSPA00001',
  'ACGSTAN00001',
  'ACGTGA00001',
  'ACRIRIMP00001',
  'ACTINS00001',
  'ACTINSUN00001',
  'ACURISKG00001',
  'ADFI00001',
  'ADIA00001',
  'ADONIRAM00001',
  'ADPARG00001',
  'ADPCASA00001',
  'ADPCOCOV00001',
  'ADPDSR00001',
  'ADPHERNA00001',
  'ADPHOP00001',
  'ADPISSAR00001',
  'ADPJAS00001',
  'ADPMAP00001',
  'ADPMCG00001',
  'ADPMEYER00001',
  'ADPNYPR00001',
  'ADPONEON00001',
  'ADPPBI00001',
  'ADPRICAND00001',
  'ADPROB00001',
  'ADPRPA00001',
  'ADPSELA00001',
  'ADPSILV00001',
  'ADPSRS00001',
  'ADPTITAN00001',
  'ADPWHIT00001',
  'ADV5BOROUGH00001',
  'ADVAINPL00001',
  'ADVGOODW00001',
  'ADVINSGROUP00001',
  'ADVL00001',
  'ADVLATININS00001',
  'ADVLGPG00001',
  'ADVMAIELLA00001',
  'ADVOCA00001',
  'ADVPAR00001',
  'ADVRESOUR00001',
  'AFFIA00001',
  'AFI00001',
  'AGBINS00001',
  'AGE1BON00001',
  'AGE1STLINE00001',
  'AGEBIA00001',
  'AGEBIG00001',
  'AGECAMA00001',
  'AGEHAI00001',
  'AGEHAIG00001',
  'AGEHANBY00001',
  'AGEHIEN00001',
  'AGEIRI00001',
  'AGEKF00001',
  'AGEKIIA00001',
  'AGEMA00001',
  'AGEMCIC00001',
  'AGEMIS00001',
  'AGEMSBG00001',
  'AGEMVIS00001',
  'AGEOCIN00001',
  'AGETA00001',
  'AGONZINS00001',
  'AIMADVGR00001',
  'AINPRO00001',
  'AISPEC00001',
  'AJBROKGE00001',
  'AKIN00001',
  'AKOYAI00001',
  'ALACOAST00001',
  'ALIANZA0001',
  'ALICTX00001',
  'ALLAM00001',
  'ALLINSAG00001',
  'ALLINSGR00001',
  'ALLMDJ00001',
  'ALLPRO00001',
  'ALLRISKINS00001',
  'ALMEIDA00001',
  'ALOINAG00001',
  'ALPHA00001',
  'ALSMAINFIS1',
  'ALSMAJMRA1',
  'ALTSHIE00001',
  'ALWEX00001',
  'AMAD00001',
  'AMAZING00001',
  'AMCOI00001',
  'AMDTIS00001',
  'AMEAIN00001',
  'AMGREGLY00001',
  'AMIINS00001',
  'AMRELIA00001',
  'AMRO00001',
  'AMSBSIS00001',
  'AMSIII00001',
  'AMSKEND00001',
  'AMSMHC00001',
  'AMSWGI00001',
  'AMWNCLT00001',
  'AMWORL00001',
  'AMWSBS00001',
  'ANDER00001',
  'ANEJGSIN00001',
  'ANEMSIA00001',
  'ANEPMCA00001',
  'ANGJOHN00001',
  'ANWINTL00001',
  'APBROKSE00001',
  'APPAAIG00001',
  'APPABSI00001',
  'APPACOS00001',
  'APPADV00001',
  'APPAFBIC00001',
  'APPAIAF00001',
  'APPAIF00001',
  'APPAIM00001',
  'APPAINA00001',
  'APPAISE00001',
  'APPALP00001',
  'APPAMIG00001',
  'APPANDY00001',
  'APPAPCI00001',
  'APPAPIA00001',
  'APPARM00001',
  'APPAZIS00001',
  'APPBBCO00001',
  'APPBCYI00001',
  'APPBEL00001',
  'APPBGI00001',
  'APPBIA00001',
  'APPBIS00001',
  'APPBWI00001',
  'APPC3RI00001',
  'APPCAH00001',
  'APPCG00001',
  'APPCGI00001',
  'APPCLSA00001',
  'APPCOIS00001',
  'APPCR00001',
  'APPCSM00001',
  'APPCVM00001',
  'APPCWA00001',
  'APPDBMS00001',
  'APPDELIA00001',
  'APPDEN00001',
  'APPDFIF00001',
  'APPDGI00001',
  'APPDIS00001',
  'APPDNA00001',
  'APPEIL00001',
  'APPEOCI00001',
  'APPEOIA00001',
  'APPETI00001',
  'APPEXE00001',
  'APPFCIA00001',
  'APPFETT00001',
  'APPFIAI00001',
  'APPFIC00001',
  'APPFLB00001',
  'APPFLPC00001',
  'APPFLYIN00001',
  'APPFOIA00001',
  'APPFRA00001',
  'APPFSI00001',
  'APPFUSIA00001',
  'APPGB00001',
  'APPGEI00001',
  'APPGLI00001',
  'APPGSWS00001',
  'APPGWI00001',
  'APPHBF00001',
  'APPHHIS00001',
  'APPHII00001',
  'APPHLIA00001',
  'APPHPI00001',
  'APPHUFL00001',
  'APPHURD00001',
  'APPHYS00001',
  'APPIBAR00001',
  'APPIGEE00001',
  'APPIIA00001',
  'APPIIS00001',
  'APPINAC00001',
  'APPINMG00001',
  'APPIRA00001',
  'APPISD00001',
  'APPISU00001',
  'APPJCLAR00001',
  'APPJEFF00001',
  'APPJKST00001',
  'APPJRR00001',
  'APPKATZ00001',
  'APPKRN00001',
  'APPLAI00001',
  'APPLATI00001',
  'APPLEI00001',
  'APPLIBL00001',
  'APPLMWS00001',
  'APPLWSI00001',
  'APPMAA00001',
  'APPMARYA00001',
  'APPMCD00001',
  'APPMLIB00001',
  'APPMOIA00001',
  'APPMOMOC00001',
  'APPMON00001',
  'APPMURV00001',
  'APPMYI00001',
  'APPNIS00001',
  'APPNTI00001',
  'APPOB00001',
  'APPOF00001',
  'APPOXIA00001',
  'APPPIS00001',
  'APPPMG00001',
  'APPPRL00001',
  'APPRB00001',
  'APPRFH00001',
  'APPRIA00001',
  'APPRMAG00001',
  'APPROIA00001',
  'APPROS00001',
  'APPROW00001',
  'APPRPI00001',
  'APPRSC00001',
  'APPSAIA00001',
  'APPSAN00001',
  'APPSARDY00001',
  'APPSCINC00001',
  'APPSCO00001',
  'APPSEI00001',
  'APPSIA00001',
  'APPSKIG00001',
  'APPSPG00001',
  'APPSUP00001',
  'APPSWI00001',
  'APPTEMAX00001',
  'APPTIG00001',
  'APPTIP00001',
  'APPTJA00001',
  'APPTWG00001',
  'APPUPPIA00001',
  'APPUTS00001',
  'APPVOLB00001',
  'APPWEIG00001',
  'APPWILL00001',
  'APPWKI00001',
  'APPWWI00001',
  'APPWYN00001',
  'APPWYNN00001',
  'APPZA00001',
  'APPZEE00001',
  'ARCLLC00001',
  'ARMALS00001',
  'ARMDAVA00001',
  'ARMFIA00001',
  'ARMGSI00001',
  'ARMHIA00001',
  'ARMKI00001',
  'ARMMID00001',
  'ARROYOINS00001',
  'ARSLIA00001',
  'ARSMAAGMI1',
  'ARSMAINIS1',
  'ARSMARCFI1',
  'ARTEK00001',
  'ASINS00001',
  'ASINSM00001',
  'ASSURED00001',
  'ASTRAI00001',
  'ATHINC00001',
  'ATLANI00001',
  'ATLGRAG00001',
  'ATLGYIA00001',
  'ATLJEF00001',
  'AXIOM00001',
  'AZINS00001',
  'AZSMACISL1',
  'AZSMAWISH1',
  'BAKAIAG00001',
  'BAKDSR00001',
  'BAKLOIA00001',
  'BAKLRIA00001',
  'BAKPGI00001',
  'BAKPRIS00001',
  'BAKTEJ00001',
  'BAKVEN00001',
  'BANDBFL00001',
  'BAPENTA00001',
  'BARBIA00001',
  'BASIINS00001',
  'BASINS00001',
  'BBCOM00001',
  'BBIMIFL00001',
  'BBINSU00001',
  'BBKYSI00001',
  'BDVGWL00001',
  'BEACHI00001',
  'BEACON00001',
  'BENSOUINS00001',
  'BERUMI00001',
  'BEYINSGR00001',
  'BGG00001',
  'BIGFOOT00001',
  'BIGINC00001',
  'BILYINSE00001',
  'BINGWA00001',
  'BINSTRT00001',
  'BIRCHYARD00001',
  'BISHDU00001',
  'BLACKMON00001',
  'BLINDSPOT00001',
  'BLKFCI00001',
  'BLUEMNI00001',
  'BLUENA00001',
  'BMCGHEEINS00001',
  'BNA00001',
  'BNBGARDEN0001',
  'BOBADAGEN00001',
  'BOBBYBROWNINS00001',
  'BPDEFY00001',
  'BPECRI00001',
  'BPEGBG00001',
  'BPEHIA00001',
  'BPEIMI00001',
  'BPENHML00001',
  'BPERIV00001',
  'BPESOL00001',
  'BPKAHAN00001',
  'BPMRZA00001',
  'BPNYCM00001',
  'BRAAI372235',
  'BRAAI659885',
  'BRABI164969',
  'BRACA465',
  'BRACAI00001',
  'BRACI60042',
  'BRAGI192421',
  'BRAGIA25685',
  'BRAGU665046',
  'BRAII18681',
  'BRAISH00001',
  'BRAIWSA00001',
  'BRAJA09367',
  'BRAJJA00001',
  'BRALAWA00001',
  'BRAMDSA00001',
  'BRAMIGC00001',
  'BRANOLA701',
  'BRAPBA86196',
  'BRARBIA00001',
  'BRARDI8441',
  'BRASBI00001',
  'BRASI325452',
  'BRASI558529',
  'BRASOU00001',
  'BRATH18294',
  'BRATIB2106',
  'BRATIN00001',
  'BRATK60008',
  'BRATPA00001',
  'BRAVIA0A6020',
  'BRAZOS00001',
  'BRBFDI00001',
  'BRBMETRO00001',
  'BRBSA00001',
  'BRDG00001',
  'BRENTA00001',
  'BREVCIN00001',
  'BRIBOC00001',
  'BRIBRG00001',
  'BRIHIT00001',
  'BRITKA00001',
  'BRITNF00001',
  'BRITTANY00001',
  'BRIVILL00001',
  'BROAIL00001',
  'BRODZAG00001',
  'BROHCC00001',
  'BROHHI00001',
  'BROWC00001',
  'BRUGND0001',
  'BRWE00001',
  'BRWNLLC00001',
  'BUESINSAG00001',
  'BUHL00001',
  'BULLDOG00001',
  'BULLER00001',
  'BURNWIL00001',
  'BUTLERINS00001',
  'BXSBAT00001',
  'BXSCIS00001',
  'BXSGUL00001',
  'BXSHOU00001',
  'BYRD00001',
  'CAAFIRST00001',
  'CAAGBS00001',
  'CAARNC00001',
  'CAASCI00001',
  'CAAWIA00001',
  'CAINSAG00001',
  'CALEAG00001',
  'CALINSAG00001',
  'CAMGHA00001',
  'CANGUIG00001',
  'CAPDIRECT00001',
  'CAPIINSU00001',
  'CARINS0001',
  'CASMAARIS1',
  'CASMAEDAY1',
  'CASMAGPIS1',
  'CASMAINSSOLUTION1',
  'CASMAKOIFS1',
  'CASMALONGENINA1',
  'CASMATSI1',
  'CASMAVIS1',
  'CASMAWOOJIN1',
  'CASURA00011',
  'CAVENDINSA00001',
  'CCFAGENC00001',
  'CCINS00001',
  'CCRINC00001',
  'CDCOM00001',
  'CDEVILIN00001',
  'CESSERVICES00001',
  'CFIC00001',
  'CFVENTISG00001',
  'CGBINS00001',
  'CHAGEN00001',
  'CHARSA00001',
  'CHERIN00001',
  'CHILDR00001',
  'CHRSTGRP00001',
  'CIGI00001',
  'CINSADV00001',
  'CISNERIA00001',
  'CJCINT00001',
  'CJINSA00001',
  'CKSIA00001',
  'CLAUDIA00001',
  'CLAUSA00001',
  'CMAIG00001',
  'CMSRISK00001',
  'CNAINS00001',
  'COASELIN00001',
  'COASTTOCOAST00001',
  'COCHINS00001',
  'COCOINSE00001',
  'COLLINS00001',
  'COLUNW00001',
  'COMINSAG00001',
  'COMMERMAN00001',
  'COMMUN00001',
  'CONCOV00001',
  'COOKE00001',
  'COREINS00001',
  'CORROUT00001',
  'COSMARIG1',
  'COSMASEID1',
  'COSMEAGLE1',
  'COSTELL00001',
  'COVERU00001',
  'COWESTINS00001',
  'COXIGI00001',
  'CPICO00001',
  'CPTLIN00001',
  'CRAILEIC00001',
  'CRAWSC00001',
  'CRCINS00001',
  'CRCINSBR00001',
  'CREMULS00001',
  'CROSSTIM00001',
  'CRYSTL00001',
  'CSINS00001',
  'CSINSTRAT00001',
  'CUMMINS0001',
  'CUTLER00001',
  'CYPRESSINS00001',
  'CYPRINSU00001',
  'DADRSKSOL00001',
  'DANEGRP00001',
  'DAVASL00001',
  'DAVIS00001',
  'DCAPWOOOD00001',
  'DEMBWI00001',
  'DEMCOSMO00001',
  'DEMGAP00001',
  'DEMOCI00001',
  'DEMROND00001',
  'DEMSBL00001',
  'DEVAS00001',
  'DEVITO00001',
  'DFIG00001',
  'DGAACC00001',
  'DGAEWAY00001',
  'DIRECTI00001',
  'DISCOUNRIA00001',
  'DIVINBRS00001',
  'DJMINC00001',
  'DLGI00001',
  'DOWINSAG00001',
  'DUNAII00001',
  'DURAME00001',
  'DUSTINAG00001',
  'DWIGHT00001',
  'DYNAAGEN00001',
  'EAGLES00001',
  'EAVES00001',
  'EBCOHEN00001',
  'ECRUM00001',
  'EEBINS00001',
  'EFGINC00001',
  'EITZENAGENCY00001',
  'ELA00001',
  'ELENT00001',
  'ELITE00001',
  'ELITECOV00001',
  'ELLIS000001',
  'EMPBPR00002',
  'EMPBPR00004',
  'EMPBPR00012',
  'EMPBPR00014',
  'EMPBPR00020',
  'EMPBPR00022',
  'EMPBPR00024',
  'EMPBPR00025',
  'EMPGROU00001',
  'ENKINS00001',
  'EPCIA00001',
  'EQUINOKS00001',
  'EST16500001',
  'EST20700001',
  'EST21500001',
  'EST33600001',
  'ESTEDER00001',
  'ESTINSAG00001',
  'ESTMYI00001',
  'ESTTUCS00001',
  'EVERIA00001',
  'EVOAALLG00001',
  'EVOACIS00001',
  'EVOAMCO00001',
  'EVOBZ00001',
  'EVOCIAG00001',
  'EVOEGINS00001',
  'EVOESI00001',
  'EVOHHAS00001',
  'EVOJCGI00001',
  'EVOMAIB00001',
  'EVOMARA00001',
  'EVORFIA00001',
  'EVOSWF00001',
  'EVOTLON00001',
  'EVOTVIA00001',
  'EVOUNIA00001',
  'EVOUNIT00001',
  'EVRISK00001',
  'EXECUTIVEIA00001',
  'FAGP00001',
  'FAIOTBAY00001',
  'FALA00001',
  'FAMFIRIN00001',
  'FAMINS00001',
  'FCADION00001',
  'FCAIA00001',
  'FCCIG00001',
  'FCDEFI00001',
  'FCEAST00001',
  'FCGRANG0001',
  'FCHARN00001',
  'FCIS00001',
  'FCJCA00001',
  'FCJLOV00001',
  'FCLLC00001',
  'FCOAIB00001',
  'FCOAISO00001',
  'FCOALA00001',
  'FCOALAI00001',
  'FCOAMIA00001',
  'FCOANM00001',
  'FCOARIN00001',
  'FCOATIA00001',
  'FCOBBILV00001',
  'FCOBIFA00001',
  'FCOBKFG00001',
  'FCOBMCD00001',
  'FCOCCIA00001',
  'FCOCHCI00001',
  'FCOCIA00001',
  'FCOCOIG00001',
  'FCOCSIN00001',
  'FCOCTIA00001',
  'FCODEIN00001',
  'FCODINC00001',
  'FCOELITEINS00001',
  'FCOELMINS00001',
  'FCOFAITH00001',
  'FCOGAIA00001',
  'FCOHAAT00001',
  'FCOHER00001',
  'FCOHIA00001',
  'FCOINIG00001',
  'FCOINSP00001',
  'FCOJCIA00001',
  'FCOJDAU00001',
  'FCOKG00001',
  'FCOKWI00001',
  'FCOLEIS00001',
  'FCOLGIA00001',
  'FCOLSIS00001',
  'FCOMETR00001',
  'FCOMIIA00001',
  'FCOMIIN00001',
  'FCOMJIA00001',
  'FCOMOIN00001',
  'FCOMWTI00001',
  'FCOMXAI00001',
  'FCONFCIN00001',
  'FCONFOX00001',
  'FCONTIF00001',
  'FCOPAP00001',
  'FCOPEFI00001',
  'FCOPKIG00001',
  'FCOPRIS00001',
  'FCOPVIA00001',
  'FCOQLIN00001',
  'FCOSAF00001',
  'FCOSAFE00001',
  'FCOSAIS00001',
  'FCOSEIA00001',
  'FCOSITS00001',
  'FCOSRSI00001',
  'FCOSWIN00001',
  'FCOSYND00001',
  'FCOTOPGUN0001',
  'FCOTSIQ00001',
  'FCOTXIS00001',
  'FCOUCIS00001',
  'FCOVICA00001',
  'FCOWANA00001',
  'FCOWEAT00001',
  'FCOWWIB00001',
  'FCOZAKINS00001',
  'FCPGT00001',
  'FCPYR00001',
  'FCRPD00001',
  'FCSCA00001',
  'FCSHELINSAG00001',
  'FCSRA00001',
  'FCTPA00001',
  'FCTTT00001',
  'FCWIL00001',
  'FDASERV00001',
  'FENOW00001',
  'FERGA00001',
  'FGRCK00001',
  'FIG00001',
  'FILI00001',
  'FINITYR00001',
  'FINPIRINS00001',
  'FIRBSI00001',
  'FIRGINS00001',
  'FIRGLE00001',
  'FIRGROD00001',
  'FIRJDIG00001',
  'FIRMURPHY00001',
  'FIRNPIA00001',
  'FIRPSA00001',
  'FIRSTBWIN00001',
  'FIRTBA00001',
  'FIRTDAE00001',
  'FIRTWIN00001',
  'FIVSTRBR00001',
  'FLCASU00001',
  'FLEJO00001',
  'FLGLR00001',
  'FLIMSACOM1',
  'FLIMSAIS1',
  'FLIMSAMER1',
  'FLIMSASHU1',
  'FLIMSATR1',
  'FLIMSAWIS1',
  'FLIMSAZUR1',
  'FLIMSBEIA1',
  'FLIMSBII1',
  'FLIMSBLUE1',
  'FLIMSBSG1',
  'FLIMSCCIA1',
  'FLIMSCFIG1',
  'FLIMSCIA1',
  'FLIMSCIG1',
  'FLIMSCREA1',
  'FLIMSDAIA1',
  'FLIMSDNI1',
  'FLIMSEFI1',
  'FLIMSFCIB1',
  'FLIMSFLDI1',
  'FLIMSFOCU1',
  'FLIMSHERB1',
  'FLIMSHMTA1',
  'FLIMSJSIG1',
  'FLIMSJUP1',
  'FLIMSJWED1',
  'FLIMSLIAC1',
  'FLIMSLOV1',
  'FLIMSMTIA1',
  'FLIMSNATU1',
  'FLIMSPF1',
  'FLIMSPIIN1',
  'FLIMSPLA1',
  'FLIMSROFG1',
  'FLIMSSTR1',
  'FLIMSTIC01',
  'FLJIG00001',
  'FLSMAACCURSOL1',
  'FLSMABRTI1',
  'FLSMACIGI1',
  'FLSMADIG1',
  'FLSMAFR1',
  'FLSMAGRACE1',
  'FLSMAIPDADVISORS1',
  'FLSMAMAGINS1',
  'FLSMAMWIS1',
  'FLSMANMSL1',
  'FLSMARESPON00001',
  'FLSMAYPIL1',
  'FNDSLD00001',
  'FOLKS00001',
  'FOOAINGR00001',
  'FORINSSE00001',
  'FORMOSA00001',
  'FOROFF00001',
  'FORTRE00001',
  'FOURR00001',
  'FRASER00001',
  'FRCC00001',
  'FREPPFR00001',
  'FRIENDSINS00001',
  'FSGJPIA00001',
  'FSGOAK00001',
  'FTEZIN00001',
  'GAIMANIAI1',
  'GAIMSPIAD1',
  'GAKNIGHT00001',
  'GALVES00001',
  'GARZON00001',
  'GASMAEIA1',
  'GASMALTAM1',
  'GASMAPLANET1',
  'GASMASAPELO1',
  'GGFRANTZ00001',
  'GGIACEDAR00001',
  'GGIADFS00001',
  'GGIAGREEN00001',
  'GGIAPIG00001',
  'GGIBIG00001',
  'GGIETL00001',
  'GGIGCI00001',
  'GGIMID00001',
  'GGIMINA00001',
  'GGIPEOPLE00001',
  'GGIPRIS00001',
  'GGISTAS00001',
  'GGIWDINS00001',
  'GGIYARA0001',
  'GGMONTANA00001',
  'GGRIZO00001',
  'GHULAM00001',
  'GIBC00001',
  'GILBRT00001',
  'GKGRPEMRGINS00001',
  'GLBLVSN00001',
  'GLDMNT00001',
  'GLENDEAN00001',
  'GLFRGNINS00001',
  'GLOBALUW00001',
  'GLOBIS00001',
  'GLOCAL00001',
  'GLSLLC00001',
  'GLWHANIN00001',
  'GOBR00001',
  'GORIINSU00001',
  'GOTTLIEB00001',
  'GRABINC00001',
  'GRACEINS00001',
  'GREAT00001',
  'GREATINS00001',
  'GREENGEN00001',
  'GREENWD00001',
  'GRISWOLD00001',
  'GRLA00001',
  'GROBER00001',
  'GROUP00001',
  'GSTVNS00001',
  'GUARDIN00001',
  'GUCOINAG00001',
  'GUMAFIS00001',
  'GUMJSA00001',
  'GUMMOIG00001',
  'GUYINSUR00001',
  'GYSINSRC00001',
  'HACOINCE00001',
  'HAILEYINS00001',
  'HAMILTON00001',
  'HAMPTN00001',
  'HANBWHISEM00001',
  'HANGBKA00001',
  'HANGRIEGO00001',
  'HANINSSE00001',
  'HANL00001',
  'HANSON00001',
  'HANSPEC00001',
  'HARBORAI00001',
  'HARFINPA00001',
  'HARINSSE00001',
  'HARRINSE00001',
  'HARTTINS00001',
  'HATCHER00001',
  'HCPI00001',
  'HECDIBIA00001',
  'HELRISAD00001',
  'HENISER00001',
  'HENRYB00001',
  'HERINAG00001',
  'HIBINSAG00001',
  'HIGGINS00001',
  'HIGHTE00001',
  'HILLCOINS00001',
  'HILLCOUNT00001',
  'HMIA00001',
  'HOINS00001',
  'HOMEWELL00001',
  'HORINSAG00001',
  'HORIZ00001',
  'HORIZN00001',
  'HORIZON00001',
  'HOSPINS00001',
  'HROB00001',
  'HRTIG00001',
  'HUBHHBLTN00001',
  'HUBIMOB00001',
  'HUBSC00001',
  'HULLBIGS00001',
  'HULLHORSH00001',
  'HULLSTPET00001',
  'HUNTINGTON00001',
  'HUTCHINS00001',
  'HWC00001',
  'IBXIAG00001',
  'IDACSONS00001',
  'IL235INSUR00001',
  'ILSMAGRAC1',
  'IMSBRG001',
  'IMSCRIA00001',
  'INDBROKERAG00001',
  'INDNEVER00001',
  'INGRFL00001',
  'INHOUSE00001',
  'INSAMOSE00001',
  'INSBEN00001',
  'INSDEE00001',
  'INSFINSE00001',
  'INSFLIA00001',
  'INSIDMM00001',
  'INSIFLH00001',
  'INSILWK00001',
  'INSIPAA00001',
  'INSIPBH00001',
  'INSIPCK00001',
  'INSIPDB00001',
  'INSIPJF00001',
  'INSIPKL00001',
  'INSIPTA00001',
  'INSJMA00001',
  'INSJUN00001',
  'INSMAIA00001',
  'INSMAT00001',
  'INSNTWRKLA00001',
  'INSPM00001',
  'INSUAMER00001',
  'INSUEXPR00001',
  'INSUNLMTDLA00001',
  'INSUZONE00001',
  'INSWORLDAGENCY0001',
  'INSYAS00001',
  'INTANT00001',
  'INTASIB00001',
  'INTBIN00001',
  'INTBSC00001',
  'INTFG00001',
  'INTFGLONSTAR00001',
  'INTFGPHILD00001',
  'INTFTAG00001',
  'INTGFIS00001',
  'INTGIRA00001',
  'INUS00001',
  'IPABEJ00001',
  'IPABSV00001',
  'IPADOJJ00001',
  'IPAFAB00001',
  'IPAJHU00001',
  'IPAJMUL00001',
  'IPAKMS00001',
  'IPAKVLZ00001',
  'IPAMORRIS00001',
  'IPANGR00001',
  'IPANIKE00001',
  'IPATAMY00001',
  'IPATIMH00001',
  'IPMI00001',
  'IROAAI00001',
  'IROADLER00001',
  'IROAIP00001',
  'IROATZ00001',
  'IROBARTOW00001',
  'IROCOOKE00001',
  'IROCTGI00001',
  'IROCWIS00001',
  'IRODIFS00001',
  'IROEAST00001',
  'IROEYIA00001',
  'IROGINA00001',
  'IROLAIS00001',
  'IROLAN00001',
  'IROLEG00001',
  'IROMFSA00001',
  'IROMIT00001',
  'IROMSU00001',
  'IROPRIVATE00001',
  'IRORIFS00001',
  'IROSEIN00001',
  'IROSRP00001',
  'IROSTR00001',
  'IRQADGUYINS00001',
  'IRQAIGRO00001',
  'IRQBRAZ00001',
  'IRQCAMTED00001',
  'IRQCANINSBRO00001',
  'IRQCARL00001',
  'IRQCHAN00001',
  'IRQCHIA00001',
  'IRQCHY00001',
  'IRQCIS00001',
  'IRQCLW00001',
  'IRQEOI00001',
  'IRQFRSAFF00001',
  'IRQGARYTAX1',
  'IRQHAI00001',
  'IRQHGL00001',
  'IRQIAU00001',
  'IRQKARAB00001',
  'IRQKIA00001',
  'IRQLIS00001',
  'IRQMCB00001',
  'IRQNANTZ00001',
  'IRQNORSI00001',
  'IRQPAA00001',
  'IRQPRESID00001',
  'IRQPRNC00001',
  'IRQPRO00001',
  'IRQRCI00001',
  'IRQRLPH00001',
  'IRQRW00001',
  'IRQSALZ00001',
  'IRQSBHK00001',
  'IRQSHAV00001',
  'IRQSTARK00001',
  'IRQTI00001',
  'IRQTMI00001',
  'IRQTSB00001',
  'IRQWTXINS00001',
  'IRQWUADV00001',
  'IRQYLI00001',
  'ISAHIPENA00001',
  'ISFT00001',
  'ISLINSAG00001',
  'ISUGRE00001',
  'IUL00001',
  'JACKSN00001',
  'JAFACCA00001',
  'JAGINS00001',
  'JAP00001',
  'JARAG00001',
  'JASPER00001',
  'JBMART000001',
  'JBYINA00001',
  'JCOINS00001',
  'JDLLC00001',
  'JDVAVAS00001',
  'JEFFWOLF00001',
  'JEROME00001',
  'JESLIUIS00001',
  'JGIA00001',
  'JGINS00001',
  'JHCINS00001',
  'JHTYSONCO00001',
  'JJIMEN00001',
  'JKMEAD00001',
  'JMGLOVER00001',
  'JMIINIS00001',
  'JNBROK0001',
  'JOHLCOMP00001',
  'JOHNIS00001',
  'JOHSC00001',
  'JONESINS00001',
  'JOPAT00001',
  'JSED00001',
  'JSNEDINS00001',
  'JUPITER00001',
  'JUSTINW00001',
  'KALOAK00001',
  'KALRA00001',
  'KARNSINS00001',
  'KBBROKE00001',
  'KBCINSURANCESERVICE00001',
  'KEHEINAG00001',
  'KELINSGR00001',
  'KELLEYCO00001',
  'KHALSA00001',
  'KICKER00001',
  'KIMIA00001',
  'KIMINSAG00001',
  'KOKKORIS00001',
  'KOLL00001',
  'KOREINS00001',
  'KOUVEL00001',
  'KPRO00001',
  'KSBILLING00001',
  'KSINSSER00001',
  'KYSMAADIS1',
  'KYSMAWAY00001',
  'LACASMAMORRINSGRP1',
  'LACYWEST00001',
  'LACZKO00001',
  'LAIMSCRT1',
  'LAIMSCT1',
  'LAIMSDI1',
  'LAIMSDIA1',
  'LAIMSJACK1',
  'LAIMSKDP1',
  'LAIMSKELL1',
  'LANDREA00001',
  'LANDRYINS00001',
  'LANG00001',
  'LARIS00001',
  'LASMAAEIN1',
  'LASMAAMER1',
  'LASMAAMFED1',
  'LASMAAP1',
  'LASMAARMORINS1',
  'LASMACLE1',
  'LASMACOVER1',
  'LASMAEINA1',
  'LASMAHRDW1',
  'LASMALGCY1',
  'LASMAMFIA1',
  'LASMAMWES1',
  'LASMANOBLES1',
  'LASMANOLA1',
  'LASMAPRTI1',
  'LASMASQIS1',
  'LASMASREI1',
  'LASMAUDB1',
  'LASMAWORKMAN00001',
  'LATBIA00001',
  'LATIFAFFORDFISERV00001',
  'LAUNERAGENCY00001',
  'LAYRHOLD00001',
  'LAZARE00001',
  'LAZZA00001',
  'LEBLANCOKAGE00001',
  'LEELEEIN00001',
  'LEGACY00001',
  'LEGINSI00001',
  'LEWLEW00001',
  'LEXINS00001',
  'LIBCHOIN00001',
  'LIBRO00001',
  'LIC00001',
  'LIOTPB00001',
  'LOGUDW00001',
  'LOHMANX200001',
  'LONACC00001',
  'LONAIA00001',
  'LONAWS00001',
  'LONBRPF00001',
  'LONBURKHOLD00001',
  'LONCOAST00001',
  'LONCRO00001',
  'LONDCIS00001',
  'LONDGFIP00001',
  'LONDMA0001',
  'LONDMU00001',
  'LONEGLLC00001',
  'LONESCAP00001',
  'LONGFORCE00001',
  'LONHIS00001',
  'LONKATZ00001',
  'LONLAT00001',
  'LONMARB00001',
  'LONMIG00001',
  'LONROBBA00001',
  'LONSEB00001',
  'LONSPS00001',
  'LONSQZ00001',
  'LONSTRSOVIS00001',
  'LONTANYA00001',
  'LONTEXPLA00001',
  'LONVAL00001',
  'LSUT00001',
  'LTNINSSER00001',
  'LUNWESFL00001',
  'LYNBENHR00001',
  'MADR00001',
  'MAGILLAGCY00001',
  'MAINLNDINS00001',
  'MAISLLC00001',
  'MARCEN00001',
  'MARCUS00001',
  'MARINE00001',
  'MASMAVIAG1',
  'MATRINAG00001',
  'MATTALBANI00001',
  'MATTDI00001',
  'MAYFLOWER00001',
  'MCAFFJHN00001',
  'MCCART00001',
  'MCCORK00001',
  'MCGHOS00001',
  'MCGRFM00001',
  'MCGRLOU00001',
  'MCIM00001',
  'MDIMSLIME1',
  'MDINC00001',
  'MDSMAAZT1',
  'MDSMAERA1',
  'MDSMASTRA1',
  'MDSMAWMIA1',
  'MEDJAMES00001',
  'MEMBEN00001',
  'METRO00001',
  'METTS00001',
  'MIAREIS00001',
  'MIARUS00001',
  'MICHAELCHO00001',
  'MIG00001',
  'MIGINSL00001',
  'MILLASSO00001',
  'MILLEINS00001',
  'MILLENNIUMINS0001',
  'MILTBRAN00001',
  'MINNICK00001',
  'MINTINS00001',
  'MISMAKATZ1',
  'MISMALBFS1',
  'MISMAUIS1',
  'MJKELLY00001',
  'MLCU00001',
  'MLTLNFS00001',
  'MMAEUSTIS00001',
  'MMIA00001',
  'MNSMAUAG1',
  'MOCITYIN00001',
  'MOGRP00001',
  'MOONBAKERAG00001',
  'MOOREJ00001',
  'MOREBI00001',
  'MORGAN00001',
  'MORMATO00001',
  'MOROAK00001',
  'MORWORLD00001',
  'MOSCOINS00001',
  'MOSMACAIB1',
  'MOSMAMZRK1',
  'MOSMATWOGIL1',
  'MRKAA00001',
  'MWINSSER00001',
  'NAINBRNY00001',
  'NAVION00001',
  'NAVISU00001',
  'NBTINS00001',
  'NCIMSBBIG1',
  'NCIMSCRAI1',
  'NCIMSCRIW1',
  'NCIMSDPIN1',
  'NCIMSEM1',
  'NCIMSFIAG1',
  'NCIMSISIA1',
  'NCIMSJET1',
  'NCIMSLWIA1',
  'NCIMSMCCA1',
  'NCIMSPI1',
  'NCIMSPP1',
  'NCIMSSTA1',
  'NCSMAAHRI1',
  'NCSMACRCLINS1',
  'NCSMALAMB1',
  'NCSMAPIEDMONTINS1',
  'NCSMATHEPENGR1',
  'NCSMAWHIT1',
  'NDUPEYINS00001',
  'NESMASINS1',
  'NET101A00001',
  'NETACT00001',
  'NETASIA00001',
  'NETAWIN00001',
  'NETAZI00001',
  'NETBBIS00001',
  'NETBRBR00001',
  'NETCCI00001',
  'NETCCIA00001',
  'NETCENTRAL0001',
  'NETCGIB00001',
  'NETCIA00001',
  'NETCORI00001',
  'NETCRWN00001',
  'NETCSIS00001',
  'NETDOCO00001',
  'NETDTIS00001',
  'NETECIA00001',
  'NETEIS00001',
  'NETEJBA00001',
  'NETELLTEI00001',
  'NETESIA00001',
  'NETETIA00001',
  'NETFACO00001',
  'NETFISI00001',
  'NETFUTURA00001',
  'NETGIB00001',
  'NETGOLDR00001',
  'NETHDA00001',
  'NETHERA00001',
  'NETHFS00001',
  'NETHIIN00001',
  'NETHRCI00001',
  'NETIISC00001',
  'NETINCE00001',
  'NETIND00001',
  'NETKHINS00001',
  'NETLIEBE00001',
  'NETMC00001',
  'NETMILLINGTON00001',
  'NETNAIS00001',
  'NETNFCC00001',
  'NETOMIA00001',
  'NETPFI00001',
  'NETRAGA00001',
  'NETRILEY00001',
  'NETROHI00001',
  'NETRU00001',
  'NETSARA00001',
  'NETSHIELD00001',
  'NETSKYLINE00001',
  'NETSONORA00001',
  'NETTAIS00001',
  'NETTSM00001',
  'NETUNI00001',
  'NETWDST00001',
  'NETYMI00001',
  'NEWADVAN00001',
  'NEWAGEIS00001',
  'NEWANTPHAM00001',
  'NEWASIA00001',
  'NEWBGI00001',
  'NEWBLA00001',
  'NEWBVI00001',
  'NEWCASTLER00001',
  'NEWCEI00001',
  'NEWCIG00001',
  'NEWCOVER00001',
  'NEWDIB00001',
  'NEWDWMAN00001',
  'NEWELIG00001',
  'NEWFRIA00001',
  'NEWGABMAN00001',
  'NEWGGS00001',
  'NEWGLEN00001',
  'NEWGSF00001',
  'NEWHIA00001',
  'NEWHIFS00001',
  'NEWHYA00001',
  'NEWJSC00001',
  'NEWMBGB00001',
  'NEWMIA00001',
  'NEWMICWHI00001',
  'NEWMOIA00001',
  'NEWNBPG00001',
  'NEWOIA00001',
  'NEWOS00001',
  'NEWPL00001',
  'NEWPRIME00001',
  'NEWPRIMP00001',
  'NEWPZ00001',
  'NEWREYE00001',
  'NEWRON00001',
  'NEWRPC00001',
  'NEWSALVD00001',
  'NEWSERHEY00001',
  'NEWSHAMSHUM00001',
  'NEWSIAL00001',
  'NEWSINSE00001',
  'NEWSLI00001',
  'NEWTAB00001',
  'NEWTPG00001',
  'NEWTRANW00001',
  'NEWUNIALIN00001',
  'NEWWIA00001',
  'NGHAGE00001',
  'NICRIS00001',
  'NIULLC00001',
  'NJIMSHARY1',
  'NJIMSINIA1',
  'NJIMSKEYI1',
  'NJSMAABAINS1',
  'NJSMACCIA1',
  'NJSMACQ1',
  'NJSMADACCA0001',
  'NJSMAEISGI1',
  'NJSMAEKA00001',
  'NJSMAEMIG1',
  'NJSMAGPIS1',
  'NJSMAISIS1',
  'NJSMAJBLTRIGR1',
  'NJSMAJCLEAPINS1',
  'NJSMAJNC00001',
  'NJSMAJS1',
  'NJSMAKIAG1',
  'NJSMAMJ00001',
  'NJSMAORIA1',
  'NJSMAPK1',
  'NJSMAROIA1',
  'NJSMARONGHGUO',
  'NJSMARR00001',
  'NJSMASECIOATLS1',
  'NJSMASEJONGINS1',
  'NJSMATSHA1',
  'NJSMAUNIVERSAL1',
  'NLRECORD00001',
  'NMSMACME1',
  'NMSMATLI1',
  'NOLASK00001',
  'NORAIA00001',
  'NORHLIG00001',
  'NORJDI00001',
  'NORLAIG00001',
  'NORTHSTAR00001',
  'NOWELL00001',
  'NRM00001',
  'NVSMAALLU1',
  'NVSMAHIA1',
  'NWPRTBAY0001',
  'NXTHERRAINS0001',
  'NYIMSAR1',
  'NYIMSBLSN1',
  'NYIMSDAIG1',
  'NYIMSHARB1',
  'NYIMSHS1',
  'NYIMSMLAS1',
  'NYIMSNFIA1',
  'NYIMSROYB1',
  'NYSMAALAN00001',
  'NYSMAALLBRO1',
  'NYSMACSANI1',
  'NYSMACWIA1',
  'NYSMAFOURB00001',
  'NYSMAHERINSBRO1',
  'NYSMAINCO1',
  'NYSMAINSURES00001',
  'NYSMAJEM1',
  'NYSMAJNRINS1',
  'NYSMALANC1',
  'NYSMALIPS1',
  'NYSMAMCLBROK1',
  'NYSMAMQUEST00001',
  'NYSMANYCSHLD1',
  'NYSMAPARK1',
  'NYSMARSAU1',
  'NYSMAYOLIMBROK1',
  'NYTPAG1',
  'OAAHOPE00001',
  'OAATRI00001',
  'OHSMACIA00001',
  'OHSMAINM1',
  'OIS00001',
  'OKIE00001',
  'OKSMAMVP1',
  'OLIKRI00001',
  'OLIMAYRI00001',
  'OLITOTELI00001',
  'OLSCIG00001',
  'ONEINGR00001',
  'ORLINSCE00001',
  'ORRCAPTSRVS00001',
  'OVERLEE00001',
  'PACGT00001',
  'PACHELIX00001',
  'PACHOW00001',
  'PACIFI00001',
  'PACIFICMSP00001',
  'PACJAMIEJINS00001',
  'PACKERAN00001',
  'PACKNIGHT00001',
  'PACWILE00001',
  'PAGRINAG00001',
  'PAIMSA11',
  'PAIMSGRM1',
  'PAIMSHD1',
  'PAIMSLEVY1',
  'PAIMSMCS1',
  'PAIMSSTAP1',
  'PALMERAG00001',
  'PALMYRA00001',
  'PALOM00001',
  'PANAMAA00001',
  'PARAGN00001',
  'PARBRALY00001',
  'PARCIM00001',
  'PARMBIA00001',
  'PARRISH00001',
  'PASMAHENRY00001',
  'PASMAINXC1',
  'PASMAMAPLE00001',
  'PASMAMIA1',
  'PASMANAG1',
  'PASMAPAIA1',
  'PASMASTRO1',
  'PASMATAILORED00001',
  'PASMATRIS1',
  'PEEP00001',
  'PENN00001',
  'PERSIS00001',
  'PFSI00001',
  'PGINS00001',
  'PGLIINS00001',
  'PHAGAN00001',
  'PHINCOAG00001',
  'PHP00001',
  'PIADDI00001',
  'PIAFAIFS00001',
  'PIAFIC00001',
  'PIAGALL00001',
  'PIAGWIB00001',
  'PIAHRA00001',
  'PIAI00001',
  'PIAMIDG00001',
  'PIAPAPP00001',
  'PIAPIA00001',
  'PIAPLBI00001',
  'PIATFIA00001',
  'PICINAG00001',
  'POMIBLLC00001',
  'PREACIA00001',
  'PREAUI00001',
  'PRECAPS00001',
  'PRECRIA00001',
  'PREGCI00001',
  'PREHINS00001',
  'PREHRG00001',
  'PREINS00001',
  'PREINSF00001',
  'PREINSNOW00001',
  'PREMLTS00001',
  'PREMSBX00001',
  'PREPANZER00001',
  'PREPART00001',
  'PREROIG00001',
  'PRESTI00001',
  'PRETYNER00001',
  'PREZAP00001',
  'PRI91800001',
  'PRIMESER00001',
  'PRITIS00001',
  'PRIVEAST00001',
  'PRKINSUR00001',
  'PROFIN00001',
  'PROSAL00001',
  'PROTPLUS00001',
  'PSAREINS00001',
  'PTNMIN00001',
  'PWNIG00001',
  'QDINSAGE00001',
  'QUAFSTK00001',
  'QUALBROK00001',
  'QUAPEIG00001',
  'QUIBDIN00001',
  'RAIBNY00001',
  'RAJINS00001',
  'RAMPH00001',
  'RANTRIEST00001',
  'RAYARN00001',
  'RCIBROK00001',
  'RCKYMINS00001',
  'REGDELT00001',
  'REGRIG00001',
  'REGRTX00001',
  'REGUNIV00001',
  'REGWIA00001',
  'RELIBROK00001',
  'RELIINSU00001',
  'REMER00001',
  'RENAIA00001',
  'RENBRU00001',
  'RENIFS00001',
  'RENINSDIR00001',
  'RENONE00001',
  'RIGAQI00001',
  'RII00001',
  'RIPW00001',
  'RISKP00001',
  'RISOHOIA00001',
  'RIVERLAND00001',
  'RMALOWC00001',
  'ROBINSAG00001',
  'ROCDOM00001',
  'ROCGIA00001',
  'ROCJWA00001',
  'ROCKPROG00001',
  'ROCKWA00001',
  'ROCPAWANS00001',
  'ROCSAND00001',
  'RODINSA00001',
  'ROVNER00001',
  'ROYREEVE00001',
  'RPSHOU00001',
  'RPSR00001',
  'RSI00001',
  'RSKLA00001',
  'RSTALDEN00001',
  'RTSALLM00001',
  'RTSASLCLEA00001',
  'RTSASLHAM00001',
  'RTSASLLEAN00001',
  'RTSASLRIC00001',
  'RTSDAL00001',
  'RTSMEL00001',
  'RTSNAS00001',
  'RTSNYCH00001',
  'RTSORL00001',
  'RTSPHI00001',
  'RTSPIT00001',
  'RTSTAM00001',
  'RUDSMITH00001',
  'RVRUN00001',
  'RVS00001',
  'SAFAUIN00001',
  'SAFEST00001',
  'SALOAK00001',
  'SAMJIME00001',
  'SANDSTONEINS00001',
  'SARPOOLAKIINS00001',
  'SCHEN00001',
  'SCHNE00001',
  'SCOTTLLC00001',
  'SCSMACCI1',
  'SCSMACLON1',
  'SCSMACYP1',
  'SCSMADRIA1',
  'SCSMAEDSI1',
  'SCSMAEMIA1',
  'SCSMAHFCI1',
  'SCSMAHIGR1',
  'SCSMAHIIG1',
  'SCSMAMAPPIA1',
  'SCSMAMSIN1',
  'SCSMAPERRY00001',
  'SCSMASTHSF1',
  'SCSMATIMB00001',
  'SEASCAPE00001',
  'SEFINS00001',
  'SELACE00001',
  'SELALLENJ00001',
  'SELAPP00001',
  'SELCAPLE00001',
  'SELCELEB00001',
  'SELCLAZ00001',
  'SELDIAM00001',
  'SELELA00001',
  'SELFEIC00001',
  'SELFMPI00001',
  'SELGIB00001',
  'SELGINS00001',
  'SELHNINSU00001',
  'SELICG00001',
  'SELITIS00001',
  'SELJOE00001',
  'SELJWA00001',
  'SELKTIA00001',
  'SELLOU00001',
  'SELLXR00001',
  'SELMBIA00001',
  'SELNET00001',
  'SELRDA00001',
  'SELSCIG00001',
  'SELSHIPM00001',
  'SELTXGUARD0001',
  'SELZELEN00001',
  'SEMON00001',
  'SEWELL00001',
  'SGATEIN00001',
  'SGFINAN00001',
  'SGIM00001',
  'SHAPIROG00001',
  'SHEILA00001',
  'SHEWA00001',
  'SHOLINSG00001',
  'SHREEJIINS00001',
  'SHUNAC00001',
  'SIAAADCS00001',
  'SIAAADI00001',
  'SIAAAHL00001',
  'SIAABZ00001',
  'SIAACFIS00001',
  'SIAACHI00001',
  'SIAAEVIA00001',
  'SIAAIACHOI00001',
  'SIAAIAIN00001',
  'SIAAIAWAL00001',
  'SIAALAP00001',
  'SIAALAPAOA00001',
  'SIAALAV00001',
  'SIAALBK00001',
  'SIAALIC00001',
  'SIAALSS00001',
  'SIAALWI00001',
  'SIAAMAC00001',
  'SIAAMCBC00001',
  'SIAANCUPO00001',
  'SIAAOI00001',
  'SIAAPRIA00001',
  'SIAAREAS00001',
  'SIAASANSI00001',
  'SIAASC00001',
  'SIAATHIN00001',
  'SIAATSIG00001',
  'SIAAWALT00001',
  'SIABAR00001',
  'SIABBI00001',
  'SIABELI00001',
  'SIABIG00001',
  'SIACHIG00001',
  'SIACIG00001',
  'SIACL00001',
  'SIACLYL00001',
  'SIACPC00001',
  'SIACRWN00001',
  'SIACVAP00001',
  'SIACVFG00001',
  'SIACVZO00001',
  'SIADFIA00001',
  'SIADIP00001',
  'SIADMBL00001',
  'SIAELE00001',
  'SIAEPPE00001',
  'SIAFFG00001',
  'SIAFIA00001',
  'SIAFLOR00001',
  'SIAFULLP00001',
  'SIAFULRSK00001',
  'SIAGLAPALLA00001',
  'SIAHIA00001',
  'SIAHIFC00001',
  'SIAHISP00001',
  'SIAHTIA00001',
  'SIAHVAPSEC00001',
  'SIAHVOR00001',
  'SIAHVTG00001',
  'SIAI00001',
  'SIAIADN00001',
  'SIAICAAICO00001',
  'SIAICAICA00001',
  'SIAICAPNEL00001',
  'SIAICARC00001',
  'SIAICBL00001',
  'SIAIGUSA00001',
  'SIAINAP00001',
  'SIAINURE00001',
  'SIAJAGI00001',
  'SIAJNAG00001',
  'SIAJSAI00001',
  'SIAJSIA00001',
  'SIAKIG00001',
  'SIALAPAGD00001',
  'SIALIG00001',
  'SIALPC00001',
  'SIALYNERNINS00001',
  'SIAMAP00001',
  'SIAMDB00001',
  'SIAMZ00001',
  'SIAMZI00001',
  'SIANACA00001',
  'SIANCAS00001',
  'SIANCPG00001',
  'SIANCSG00001',
  'SIANCWM00001',
  'SIANFAARCH00001',
  'SIANFAPHEN00001',
  'SIANFBI00001',
  'SIANFDI0001',
  'SIANJAE00001',
  'SIANJAI00002',
  'SIAOCPC00001',
  'SIAORCINS00001',
  'SIAORH00001',
  'SIAPFI00001',
  'SIAPHIA00001',
  'SIAPHNX00001',
  'SIAPIG00001',
  'SIAPIKC00001',
  'SIAPRIG00001',
  'SIAROS00001',
  'SIASANCDT00001',
  'SIASANIR00001',
  'SIASCIA00001',
  'SIASFAP00001',
  'SIASFGO00001',
  'SIASGIS00001',
  'SIASIG0001',
  'SIASNACOL00001',
  'SIASNEC00001',
  'SIASRSA00001',
  'SIASTAP00001',
  'SIASTATRU0001',
  'SIASTAZ00001',
  'SIASTBY00001',
  'SIASTDL00001',
  'SIASTDS00001',
  'SIASTDZ00001',
  'SIASTFI00001',
  'SIASTHD00001',
  'SIASTII00001',
  'SIASTPW00001',
  'SIATAIA00001',
  'SIATIB00001',
  'SIATIPA00001',
  'SIATODD00001',
  'SIATWIS00001',
  'SIAVAN00001',
  'SIAVIACKI00001',
  'SIAVIAL00001',
  'SIAVIAPBIA00001',
  'SIAWFIG00001',
  'SIAWISECI00001',
  'SIAYIA00001',
  'SIAYOU00001',
  'SIAYWI00001',
  'SIBINS00001',
  'SIG00001',
  'SIGFM00001',
  'SIGINSS00001',
  'SIGRP00001',
  'SIGSMITH00001',
  'SIMAGAPE00001',
  'SIMBAW00001',
  'SIMGRULLON00001',
  'SIMMORLEY00001',
  'SISOL00001',
  'SITA00001',
  'SIUFLB00001',
  'SIUSA00001',
  'SIYA00001',
  'SKYCA00001',
  'SKYPT00001',
  'SKYSAL00001',
  'SMAAIA00001',
  'SMAATZ00001',
  'SMACCI00001',
  'SMACHA00001',
  'SMAKENANT1',
  'SMAMCG00001',
  'SMARGE00001',
  'SMASERJN00001',
  'SMASOI00001',
  'SMITHSON00001',
  'SMSVINS00001',
  'SNP00001',
  'SOCALINS00001',
  'SOUBAL00001',
  'SOUINS00001',
  'SOUINSE00001',
  'SOUTHE00001',
  'SPASTON00001',
  'SPCOAST00001',
  'SPEEINSU00001',
  'SPICIS00001',
  'SPIPAGE00001',
  'SPRTINS00001',
  'SRBT00001',
  'SRIAIS00001',
  'SRINSRM00001',
  'SRJAR00001',
  'SSGRP00001',
  'STAINAG00001',
  'STAR00001',
  'STARINS00001',
  'STARTINSLLC00001',
  'STATET00001',
  'STDFSTCOM00001',
  'STEADFA00001',
  'STEW00001',
  'STHNPRO00001',
  'STOINSSE00001',
  'STOPSHOP00001',
  'STOUTINC00001',
  'STRTM00001',
  'STWGRP00001',
  'SUPINS00001',
  'SUTINSAG00001',
  'SYLVIAAA00001',
  'SZNFSC00001',
  'TAMINS00001',
  'TANS00001',
  'TATUM00001',
  'TATUMINS00001',
  'TAUINS00001',
  'TAVARESIN00001',
  'TBHI00001',
  'TBWIA00001',
  'TCDSINS00001',
  'TELLINS00001',
  'TEXCAS00001',
  'TEXSTINS00001',
  'THAICHANG00001',
  'THANKSER00001',
  'THEOMNI00001',
  'THEROSS00001',
  'THFG00001',
  'THINK00001',
  'TINALE00001',
  'TINSSHOP00001',
  'TIPI00001',
  'TKALLC00001',
  'TNSMAAS1',
  'TNSMADKCI1',
  'TNSMALX901',
  'TNSMAP3SOL1',
  'TNSMARISK1',
  'TNTINSURANCEGRP00001',
  'TOMACINS00001',
  'TONNU00001',
  'TORR00001',
  'TRALIA00001',
  'TRAYNER00001',
  'TRAZA00001',
  'TREROM00001',
  'TRPCL00001',
  'TRUITT00001',
  'TRUSTINSA00001',
  'TRUTANCE00001',
  'TTWNA00001',
  'TWER00001',
  'TWFBABU00001',
  'TWFBATR00001',
  'TWFCLC00001',
  'TWFGAVT00001',
  'TWFGBJ00001',
  'TWFGBRH00001',
  'TWFGCANT00001',
  'TWFGDI00001',
  'TWFGDT00001',
  'TWFGF00001',
  'TWFGHONG00001',
  'TWFGJAM00001',
  'TWFGJULW00001',
  'TWFGKRJ00001',
  'TWFGLARSON00001',
  'TWFGLEOP00001',
  'TWFGSJ00001',
  'TWFGSP00001',
  'TWFGSR00001',
  'TWFGTAM00001',
  'TWFGTILG00001',
  'TWFGTN00001',
  'TWFGVPAZ00001',
  'TWFGYORK00001',
  'TWFJHSR00001',
  'TWFMASI00001',
  'TWFMATW00001',
  'TWFNAGU00001',
  'TWFSAMRO00001',
  'TWFUDTW00001',
  'TWFYGTW00001',
  'TWFYJX00001',
  'TXIMSCIAG1',
  'TXIMSGAUL1',
  'TXIMSJPIS1',
  'TXIMSSKIA1',
  'TXIMSWIAI1',
  'TXISC00001',
  'TXSMA2BIS1',
  'TXSMAALBE1',
  'TXSMACIIT1',
  'TXSMACYIS1',
  'TXSMADDAX1',
  'TXSMADWINS1',
  'TXSMAFCI1',
  'TXSMAHNIA1',
  'TXSMAKNBS00001',
  'TXSMALALA1',
  'TXSMALEIF1',
  'TXSMALIFS1',
  'TXSMAMPIA1',
  'TXSMANECHINSAG1',
  'TXSMARAG1',
  'TXSMATI1',
  'TXSMATRDI1',
  'TXSMATRUEBLUE1',
  'UCORN00001',
  'UIG00001',
  'UNICOM00001',
  'UNINSAGTX00001',
  'UNITED00001',
  'UNITEDCIA00001',
  'UNITEDINSAG00001',
  'UPIS00001',
  'URBABS00001',
  'URBBUE00001',
  'URBCATA00001',
  'URBEXPR00001',
  'URBFIS00001',
  'URBGIFS00001',
  'URBMJIA00001',
  'URBOCPA00001',
  'URBPRUN00001',
  'URBSOIS00001',
  'USSELECT00001',
  'UTECHT00001',
  'VAGENCY00001',
  'VAILASINC00001',
  'VALEGROU00001',
  'VALLEY00001',
  'VASMAMADCHA1',
  'VEROML00001',
  'VICGLOBAL00001',
  'VIRGSMITH00001',
  'VORBACH00001',
  'WAFDINC00001',
  'WALWIL00001',
  'WASICZ00001',
  'WATTDA00001',
  'WBIS00001',
  'WEIALE00001',
  'WEIANDU00001',
  'WEICG00001',
  'WEICHR00001',
  'WEICHU00001',
  'WEIDELR00001',
  'WEIDORAL00001',
  'WEIFOX00001',
  'WEIJORO00001',
  'WEIKEV00001',
  'WEIMAHE00001',
  'WEIMAR00001',
  'WEIMON00001',
  'WEINMLW00001',
  'WEIRRU00001',
  'WEIS00001',
  'WEISBWI00001',
  'WEISHAJ00001',
  'WEISNA00001',
  'WEISTJ00001',
  'WEITEBR00001',
  'WEIWEKI00001',
  'WEIWIDK00001',
  'WEIWIMT00001',
  'WEIYAMO00001',
  'WESTSOUTH00001',
  'WEXFINS00001',
  'WIA00001',
  'WICHITAOKINS00001',
  'WILHELM00001',
  'WILLIAMSIA00001',
  'WININSGR00001',
  'WIRI00001',
  'WISMAHHIA1',
  'WLGS00001',
  'WNDR00001',
  'WOOAII00001',
  'WOOBOWI00001',
  'WOOCAS00001',
  'WOOCYLA00001',
  'WOODDY00001',
  'WOODMB00001',
  'WOODMF00001',
  'WOODMILL00001',
  'WOODPRS00001',
  'WOOHANMI00001',
  'WOOINSUR00001',
  'WOOKEKE00001',
  'WOORCIS00001',
  'WOOSIA00001',
  'WOOTNMI00001',
  'WORTHINS00001',
  'WSIA00001',
  'WVSMAWINA1',
  'WWINC00001',
  'YECMIL00001',
  'YOUNGSOK00001',
  'ZBGCOI00001',
  'ZEIN00001',
  'ZERON00001',
  'ZISM00001',
  'ZOLAIN00001',
];
