import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { AmplitudePayloadType, INPUT_PAGE_ENTER } from '../../core/constants/amplitude-helpers';
import { Subscription, timer } from 'rxjs';
import { environment } from 'environments/environment';
import OktaSignIn, { RenderOptions, WidgetOptions } from '@okta/okta-signin-widget';
import { SentryService } from '../../core/services/sentry.service';
import { OKTA_BASE, OKTA_ISSUER_URI } from 'app/constants';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { PasswordLinkService } from 'app/core/services/password-link.service';

const WARNING_MSG_TYPE = 'warning-message';

@Component({
  selector: 'app-reset-password.app-page.app-page__login',
  templateUrl: './password.component.html',
})
export class PasswordComponent implements OnDestroy, OnInit {
  CLIENT_ID = environment.oktaClientId;
  LOGIN_REDIRECT_URI = environment.oktaLoginRedirectURI;
  LOGOUT_REDIRECT_URI = environment.oktaLogoutRedirectURI;

  passwordRequirements = `
  <small class="gray-text">Passwords must:</small>
    <ul class="okta-req-list">
      <li><small>Be at least 16 characters long</small></li>
      <li><small>Not contain parts of your username</small></li>
      <li>
        <small>Include at least:</small>
        <ul class="okta-req-sublist">
          <li><small>1 lowercase letter</small></li>
          <li><small>1 uppercase letter</small></li>
          <li><small>1 number</small></li>
          <li><small>1 special character (eg. #, $, %, &)</small></li>
        </ul>
      </li>
    </ul>
  `;

  loading = false;
  error = '';
  submitted = false;
  recoveryToken: string;
  uuid: string;
  email: string;
  path: string;
  private sub: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private localStorage: LocalStorageService,
    private passwordLinkService: PasswordLinkService
  ) {}

  ngOnInit() {
    if (this.router.url.indexOf('reset-password') >= 0) {
      this.path = 'reset-password';
    } else if (this.router.url.indexOf('invite') >= 0) {
      this.path = 'invite';
    } else {
      this.path = 'set-password';
    }

    this.amplitudeService.submitEvent({
      input: INPUT_PAGE_ENTER,
      type: AmplitudePayloadType.Page,
      value: `/${this.path}`,
    });

    const { uuid, email } = this.route.snapshot.queryParams;
    this.uuid = uuid;
    this.email = email;

    if (['set-password', 'reset-password', 'invite'].includes(this.path)) {
      this.recoveryToken = uuid;
    }

    if (['invite', 'set-password', 'reset-password'].includes(this.path)) {
      let destRouteData = {
        email: this.email,
        recoveryToken: this.recoveryToken,
        messageType: '',
        messageSubtitle: '',
      };
      if (!this.email || !this.recoveryToken) {
        destRouteData = {
          ...destRouteData,
          messageType: WARNING_MSG_TYPE,
          messageSubtitle: 'You cannot access this page without a valid email and token.',
        };
        this.router.navigate(['password-reset-redirect'], { state: { data: destRouteData } });
      } else {
        this.sub.add(
          this.passwordLinkService
            .validatePasswordLink(this.email, this.recoveryToken)
            .subscribe((response) => {
              this.loading = false;
              if (!response) {
                destRouteData = {
                  ...destRouteData,
                  messageType: WARNING_MSG_TYPE,
                  messageSubtitle: 'This link is invalid/expired. You can create a new one below.',
                };
                this.router.navigate(['password-reset-redirect'], {
                  state: { data: destRouteData },
                });
              }
            })
        );
        this.initOkta();
      }
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async initOkta() {
    const widgetOptions: WidgetOptions = {
      authParams: {
        pkce: false,
      },
      recoveryToken: this.uuid,
      baseUrl: OKTA_BASE,
      redirectUri: this.LOGIN_REDIRECT_URI,
      i18n: {
        en: {
          'primaryauth.username.title': 'title',
          'primaryauth.username.explanation': 'explanation',
          'primaryauth.username.caption': 'caption',
          'primaryauth.username.header': 'header',
          'primaryauth.username': 'username itself',
        },
      },
    };
    const widget = new OktaSignIn(widgetOptions);

    widget.on('afterError', (context: any, err: any) => {
      this.amplitudeService.track({
        eventName: 'password_reset_failure',
        detail: this.email,
      });
    });

    widget.on('afterRender', () => {
      const submitButton = document.querySelector('.button-primary') as HTMLInputElement;
      if (submitButton) {
        submitButton.value = this.displayMessage();
      } else {
        this.sentryService.notify('Could not locate Okta reset password button');
      }

      const passwordRequirements = document.querySelector('.o-form-explain');

      if (passwordRequirements) {
        passwordRequirements.innerHTML = this.passwordRequirements;
      }
    });

    try {
      const renderOptions: RenderOptions = {
        el: '#okta-widget-container',
        clientId: this.CLIENT_ID,
        issuer: OKTA_ISSUER_URI,
        recoveryToken: this.uuid,
        baseUrl: OKTA_BASE,
        flow: 'resetPassword',
        scopes: ['openid', 'profile'], // optional
      };

      // Adding a timer, as the element will take a little bit of time to prepare
      this.sub.add(
        timer(2000).subscribe(() => {
          // Necessary to manually add a listener here, as Okta does not offer hooks into submissions
          const submitButton = document.querySelector('.button-primary');
          if (submitButton) {
            submitButton.addEventListener('click', () => {
              // User is logged in after password reset, so we need to reset Segment id/anonymousId/traits
              if (this.email) {
                if (
                  (<any>window).analytics &&
                  this.localStorage.getString('LAST_LOGGED_IN_USERNAME') &&
                  this.localStorage.getString('LAST_LOGGED_IN_USERNAME') !== this.email
                ) {
                  (<any>window).analytics.reset();
                }
                this.localStorage.setString('LAST_LOGGED_IN_USERNAME', this.email);
              }
              if (this.path === 'invite') {
                sessionStorage.setItem('okta-accept-invite', this.email);
              }
              this.amplitudeService.track({
                eventName: 'password_reset_attempt',
                detail: this.email,
              });
            });
          }
        })
      );

      await widget.showSignInAndRedirect(renderOptions);
    } catch (error) {
      this.sentryService.notify('Error rendering password reset flow');
    }
  }

  displayMessage() {
    if (this.path === 'reset-password') {
      return 'Reset password';
    } else if (this.path === 'invite') {
      return 'Create password';
    } else {
      return 'Set password';
    }
  }

  messageSubtitle() {
    if (this.path === 'invite') {
      return 'Welcome to Attune!';
    }
    return 'Enter a new password';
  }
}
