<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__bind"
  product="hab"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  sidebarTitle="Bind quote"
></app-quote-stepped-sidebar>
<div class="app-page-form-scrollable-wrapper">
  <div class="app-page-form">
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>
    <main *ngIf="quoteDetailsLoading" class="placeholder-form-container">
      <div class="placeholder-h1"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-submit"></div>
    </main>
    <ng-container *ngIf="!quoteDetailsLoading">
      <form [formGroup]="form" (ngSubmit)="handleSubmit()" novalidate>
        <router-outlet></router-outlet>

        <div class="nav-button-group nav-button-group__left">
          <button
            *ngIf="!isFinalStep()"
            id="hab-quote-form-next-button"
            [ngClass]="{
              button: true,
              'js-step-forward-button': true,
              button__discouraged: !this.isCurrentStepValid()
            }"
            type="submit"
            class="button__primary"
          >
            <span>Next</span>
          </button>
          <button
            *ngIf="isFinalStep()"
            id="hab-quote-form-submit-button"
            [ngClass]="{
              button: true,
              'js-step-forward-button': true,
              button__discouraged: !this.isCurrentStepValid()
            }"
            type="submit"
            class="button__confirm"
            [disabled]="isQuoteInProgress"
          >
            <span *ngIf="!isQuoteInProgress">Bind quote</span>
            <span *ngIf="isQuoteInProgress">Binding...</span>
          </button>
        </div>

        <ng-container *ngIf="isDevMode">
          <div class="adminz-only">
            <pre>{{ form.value | json }}</pre>
          </div>
        </ng-container>
      </form>
    </ng-container>
    <div class="app-page-form-side-rail"></div>
  </div>
</div>

<app-quote-error-modal
  [open]="errorModalOpen"
  (closeQuoteErrorModal)="closeQuoteErrorModal($event)"
  [errorType]="errorType"
></app-quote-error-modal>

<app-price-difference-modal
  [displayPriceDiffModal]="displayPriceDiffModal"
  [originalTotalCost]="oldTotalCost"
  [newTotalCost]="newTotalCost"
  (triggerNavigationToAccount)="goBackToAccount()"
  (triggerBindServiceCall)="handleSubmit()"
></app-price-difference-modal>

<ng-container *ngIf="showProgressBar">
  <app-binding-animation [showProgressBar]="true" [finish$]="bindSuccess$"></app-binding-animation>
</ng-container>
