import { InsuredAccount } from '../../features/insured-account/models/insured-account.model';

export enum SampleAccountType {
  RESTAURANT = 'restaurant',
  CONTRACTOR = 'contractor',
  SALON = 'beautySalon',
}

export const DEFAULT_SAMPLE = SampleAccountType.RESTAURANT;

export const SAMPLE_ACCOUNTS: {
  [key in SampleAccountType]: {
    accountFormPayload: Partial<InsuredAccount>;
    bopQuoteFormPayload: any;
  };
} = {
  [SampleAccountType.RESTAURANT]: {
    accountFormPayload: {
      companyName: "Luke's Bistro",
      naicsCode: {
        code: '722511',
        description: 'Restaurants, full service',
        hash: 'babb175c507e13296c1435dd4d77599b',
      },
      addressLine1: '123 Morris Street',
      city: 'Morristown',
      state: 'NJ',
      zip: '07960',
    },
    bopQuoteFormPayload: {
      account: true,
      guidelines: false,
      policyInfo: {
        numberOfLocations: 1,
        yearsInBusiness: '5',
        baseState: 'NJ',
        organizationType: 'llc',
        locationAddresses: [
          {
            addressLine1: '123 Morris Street',
            city: 'Morristown',
            state: 'NJ',
            zip: '07960',
            employeeCount: '12',
            propertyDeductible: '1000',
          },
        ],
      },
      locations: [
        {
          locationDetails: {
            addressLine1: '123 Morris Street',
            city: 'Morristown',
            state: 'NJ',
            zip: '07960',
            employeeCount: '12',
            propertyDeductible: '1000',
          },
          buildings: [
            {
              exposure: {
                businessType: 'RestaurantCasualDining',
                classification: {
                  code: {
                    code: '09611',
                    descriptionCode: 'BistrosBrasseriesandCafesWithnosalesalcoholicbever',
                  },
                },
                limitForBusinessPersonalProperty: '$152,141',
                constructionType: CONSTRUCTION_TYPE.MASONRY_NON_COMBUSTIBLE,
                storyCount: '1',
                squareFootage: '9200',
                hasAutomaticSprinklerSystem: true,
                buildingLimit: '$547,387',
                totalSales: '$430,000',
                yearBuilt: 1990,
                roofUpdated: true,
                electricPlumbingHVACUpdated: true,
              },
            },
          ],
        },
      ],
      additionalCoverages: {
        cyberLiabilityCoverage: {
          optedIn: false,
          selectedCyberCoverage: 'none',
        },
      },
      liabilityCoverages: {
        employeeHandbook: true,
        janitorialServices: true,
      },
    },
  },
  [SampleAccountType.CONTRACTOR]: {
    accountFormPayload: {
      companyName: 'Sanders Drywall',
      naicsCode: {
        code: '238310',
        description: 'Drywall contractors',
        hash: '950eb18a1b00ed337b45a97746473166',
      },
      addressLine1: '5174 Richmond Avenue',
      city: 'Houston',
      state: 'TX',
      zip: '77056',
    },
    bopQuoteFormPayload: {
      account: true,
      guidelines: false,
      policyInfo: {
        numberOfLocations: 1,
        yearsInBusiness: '10',
        baseState: 'TX',
        organizationType: 'llc',
        locationAddresses: [
          {
            addressLine1: '5174 Richmond Avenue',
            city: 'Houston',
            state: 'TX',
            zip: '77056',
            employeeCount: '12',
            propertyDeductible: '1000',
          },
        ],
      },
      locations: [
        {
          locationDetails: {
            addressLine1: '5174 Richmond Avenue',
            city: 'Houston',
            state: 'TX',
            zip: '77056',
            employeeCount: '8',
            propertyDeductible: '1000',
          },
          buildings: [
            {
              exposure: {
                businessType: 'Contractor',
                classification: {
                  code: {
                    code: '74511',
                    descriptionCode: 'DryWallWallboardInstallationShop',
                  },
                },
                limitForBusinessPersonalProperty: '$7,470',
                constructionType: CONSTRUCTION_TYPE.MASONRY_NON_COMBUSTIBLE,
                storyCount: '1',
                squareFootage: '2006',
                hasAutomaticSprinklerSystem: true,
                buildingLimit: '$69,129',
                totalSales: '$0',
                payroll: '$62,555',
                yearBuilt: 2000,
                roofUpdated: true,
              },
            },
          ],
        },
      ],
      additionalCoverages: {
        cyberLiabilityCoverage: {
          optedIn: false,
          selectedCyberCoverage: 'none',
        },
      },
      liabilityCoverages: {
        employeeHandbook: true,
        janitorialServices: true,
      },
    },
  },
  [SampleAccountType.SALON]: {
    accountFormPayload: {
      companyName: 'Pretty Beauty Salon',
      naicsCode: {
        code: '812112',
        description: 'Beauty salons',
        hash: 'b50d64f01419e62603cccb1e17249c5e',
      },
      addressLine1: '49 South Hamilton Street',
      city: 'High Point',
      state: 'NC',
      zip: '27260',
    },
    bopQuoteFormPayload: {
      account: true,
      guidelines: false,
      policyInfo: {
        numberOfLocations: 1,
        yearsInBusiness: '7',
        baseState: 'NC',
        organizationType: 'llc',
        locationAddresses: [
          {
            addressLine1: '49 South Hamilton Street',
            city: 'High Point',
            state: 'NC',
            zip: '27260',
            employeeCount: '5',
            propertyDeductible: '1000',
          },
        ],
      },
      locations: [
        {
          locationDetails: {
            addressLine1: '49 South Hamilton Street',
            city: 'High Point',
            state: 'NC',
            zip: '27260',
            employeeCount: '12',
            propertyDeductible: '1000',
          },
          buildings: [
            {
              exposure: {
                businessType: 'ProcessingandService',
                classification: {
                  code: {
                    code: '71952',
                    descriptionCode: 'BeautyParlorsHairStylingSalons',
                  },
                },
                limitForBusinessPersonalProperty: '$45,424',
                constructionType: CONSTRUCTION_TYPE.FRAME_CONSTRUCTION,
                storyCount: '1',
                squareFootage: '3987',
                hasAutomaticSprinklerSystem: true,
                buildingLimit: '$163,855',
                totalSales: '$430,000',
                yearBuilt: 2000,
                roofUpdated: true,
              },
            },
          ],
        },
      ],
      additionalCoverages: {
        cyberLiabilityCoverage: {
          optedIn: false,
          selectedCyberCoverage: 'none',
        },
      },
      liabilityCoverages: {
        employeeHandbook: true,
        janitorialServices: true,
      },
    },
  },
};
