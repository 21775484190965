import { catchError } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { getEligibilityDeclineReasonsUrl } from 'app/constants';
import { HttpClient } from '@angular/common/http';
import { SentryService } from 'app/core/services/sentry.service';

/**
 * Shared eligibility service methods for various products.
 */
@Injectable()
export class EligibilityService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getEligibilityDeclineReasons(accountId: string, product: 'bop' | 'wc') {
    return this.http
      .get<{ declineReasons: string[] }>(`${getEligibilityDeclineReasonsUrl(product)}/${accountId}`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify(`Unable to fetch decline reasons for ${product}`, {
            severity: 'error',
            metaData: {
              accountId,
              product,
              underlyingErrorMessage: error && error.message,
            },
          });
          // We log error above and return an empty list so that Attune BOP/WC can still be quoted.
          return observableOf({
            declineReasons: [],
          });
        })
      );
  }
}
