import { of as observableOf, Observable } from 'rxjs';
import { map, catchError, switchMap, concatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NaicsCode } from 'app/features/insured-account/models/insured-account.model';
import { NaicsService } from 'app/shared/services/naics.service';
import { BopPolicy, BopQuotePayload } from 'app/features/attune-bop/models/bop-policy';
import { GWService } from 'app/bop/services/gw.service';
import * as moment from 'moment';
import { RetrieveQuoteResponse } from 'app/bop/guidewire/typings';
import { SentryService } from 'app/core/services/sentry.service';
import { US_DATE_MASK } from 'app/constants';
import {
  API_V3_BOP_QUOTES_URI,
  QUOTE_NAME_URI,
  UPCOMING_POLICIES_URI,
  BUSINESS_ROLLOVER_INFO_URI,
  STATE_ROLLOVER_INFO_URI,
  UPDATE_AUTOBIND_STATUS_URI,
  ESTIMATE_PRICE_REDUCTION_URI,
  GET_LESSORS_RISK_DOCUMENTS,
} from 'app/features/attune-bop/models/constants';
import { HttpClient } from '@angular/common/http';
import {
  addLROdocumentsToBaseQuote,
  translateFromV3BopForm,
} from '../models/bop-v3-retrieve-translator';
import {
  PriceReductionLambdaPayload,
  EstimateResponse,
} from '../components/attune-bop-price-reduction-estimate/attune-bop-price-reduction-estimate.component';
import { AttuneBopQuoteRequestService } from 'app/features/attune-bop/services/attune-bop-quote-request.service';
import { effectiveDateToPeriodDate } from 'app/features/attune-bop/models/date-helpers';
import { locationsHaveLroBuildings } from 'app/features/attune-bop/models/classification-helpers';
import { environment } from 'environments/environment';

export interface TranslatedQuoteResponse {
  retrievedQuote: RetrieveQuoteResponse;
  translatedQuote: BopQuotePayload | null;
}

@Injectable()
export class AttuneBopQuoteService {
  constructor(
    private http: HttpClient,
    private gwService: GWService,
    private naicsService: NaicsService,
    private sentryService: SentryService,
    private requestQuoteService: AttuneBopQuoteRequestService
  ) {}

  updateEffectiveDateByIdV3(id: string, effectiveDate: string, accountId: string) {
    return this.getTranslatedQuoteV2(id).pipe(
      switchMap((response) => {
        const updatedEffectiveDate = effectiveDateToPeriodDate(effectiveDate);
        const payload = new BopPolicy(response, updatedEffectiveDate);
        // We need to update coverage retro effective dates whenever we update the start/effective date of the policy.
        this.updateRetroEffectiveDatesToStartDate(payload);
        return this.requestQuoteService.requestV3Quote({
          accountId: accountId,
          parentQuoteId: id,
          policy: payload,
          excessUwQuestionsHaveValidAnswers: true,
          quoteGameEnabled: false,
        });
      })
    );
  }

  private updateRetroEffectiveDatesToStartDate(bopPolicy: BopPolicy) {
    const effectiveDateString = moment.utc(bopPolicy.startDate).format(US_DATE_MASK);

    // When the backend payload is created, we use bopPolicy.startDate to set the effective date on the submission.
    // We are updating the effective date on the bop policy here just in case it ends up getting used elsewhere.

    if (bopPolicy.policyInfo && bopPolicy.policyInfo.effectiveDate) {
      bopPolicy.policyInfo.effectiveDate = effectiveDateString;
    }

    if (
      bopPolicy.additionalCoverages &&
      bopPolicy.additionalCoverages.cyberLiabilityCoverage &&
      bopPolicy.additionalCoverages.cyberLiabilityCoverage.retroactiveDate
    ) {
      bopPolicy.additionalCoverages.cyberLiabilityCoverage.retroactiveDate = effectiveDateString;
    }

    if (
      bopPolicy.additionalCoverages &&
      bopPolicy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage &&
      bopPolicy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage.retroactiveDate
    ) {
      bopPolicy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage.retroactiveDate =
        effectiveDateString;
    }

    if (
      bopPolicy.liabilityCoverages &&
      bopPolicy.liabilityCoverages.employeeBenefitsLiabilityCoverage &&
      bopPolicy.liabilityCoverages.employeeBenefitsLiabilityCoverage.retroactiveDate
    ) {
      bopPolicy.liabilityCoverages.employeeBenefitsLiabilityCoverage.retroactiveDate =
        effectiveDateString;
    }
  }

  updateHabEffectiveDateById(
    id: string,
    effectiveDate: string
  ): Observable<QSQuoteSubmissionResponse> {
    return this.gwService.retrieveHabQuote(id).pipe(
      switchMap((response) => {
        if (!response.policy) {
          const error =
            'Error while updating effective date for hab bind: received retrieve quote response with unexpected structure';
          this.sentryService.notify(error, {
            severity: 'error',
            metaData: {
              id,
              response,
            },
          });
          throw Error(error);
        }
        response.policy.effectiveDate = effectiveDate;
        return this.gwService.requestFinishedHabQuote(response);
      })
    );
  }

  renameQuote(
    accountId: string,
    quoteId: string,
    newName: string
  ): Observable<QSQuoteNameResponse> {
    const payload = { name: newName };
    return this.http
      .post<QSQuoteNameResponse>(`${QUOTE_NAME_URI}/${accountId}/${quoteId}`, payload)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to rename quote.', {
            severity: 'error',
            metaData: {
              payload,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  getUpcomingPolicies(
    start: number,
    limit: number,
    numberOfDays: number,
    rollOver: boolean,
    filter?: string
  ): Observable<UpcomingPoliciesResponse> {
    const payload: any = { start, limit, numberOfDays, rollOver };
    if (filter) {
      payload.propertyTypeFilterBy = filter;
    }

    return this.http.post<UpcomingPoliciesResponse>(UPCOMING_POLICIES_URI, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to get upcoming policy list.', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  updateAutobindStatus(jobNumber: string, enableAutobind: boolean): Observable<string> {
    const payload = { jobNumber, enabled: enableAutobind };
    return this.http.post<string>(UPDATE_AUTOBIND_STATUS_URI, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to get upcoming policy list.', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getStateRolloverInfo(): Observable<RolloverStateInfo> {
    return this.http.get<RolloverStateInfo>(STATE_ROLLOVER_INFO_URI).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to get state-by-state rollover information.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getBusinessRolloverInfo(): Observable<RolloverCategoryInfo> {
    return this.http.get<RolloverCategoryInfo>(BUSINESS_ROLLOVER_INFO_URI).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to get rollover business type information.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getNames(accountId: string): Observable<QSQuoteNameListResponse> {
    return this.http.get<QSQuoteNameListResponse>(`${QUOTE_NAME_URI}/${accountId}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve list of quote names.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        // Throwing here was silently showing empty accounts
        return observableOf({ quoteNames: {} });
      })
    );
  }

  getNaicsToIsoMappings(
    naicsCode: NaicsCode | null,
    state: string
  ): Observable<AskKodiakISOSuggestions> {
    return state && naicsCode
      ? this.naicsService.getNaicsToIsoMappings(naicsCode.hash, state)
      : observableOf({
          categories: [],
          classifications: [],
        });
  }

  public getTranslatedQuoteV2(jobId: string): Observable<DeepPartial<BopQuotePayload>> {
    return this.getV3BopQuote(jobId).pipe(
      concatMap((resp) => {
        // TODO (NY) - remove feature flag once this is in prod for 2 weeks.
        if (!environment.lroFlowEnabled) {
          return observableOf(resp);
        }
        // Check for lessors risk buildings and attach document references to base quote.
        // This makes it easier to patch quotes on edit/requote for LRO buildings.
        const hasLessorsRiskBuildings = locationsHaveLroBuildings(resp?.locations);

        if (hasLessorsRiskBuildings) {
          return this.getLessorsRiskDocuments(jobId).pipe(
            map((lroDocuments) => {
              return addLROdocumentsToBaseQuote(resp, lroDocuments);
            })
          );
        }
        return observableOf(resp);
      }),
      map(translateFromV3BopForm)
    );
  }

  private getV3BopQuote(jobId: string) {
    return this.http
      .get<BaseQuote>(`${API_V3_BOP_QUOTES_URI}/${jobId}`, {
        params: {
          format: 'v3',
        },
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to retrieve BOP quote.', {
            severity: 'error',
            metaData: {
              jobId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  private getLessorsRiskDocuments(quoteNumber: string): Observable<QSLessorsRiskBuilding[] | null> {
    return this.http
      .get<{
        documentsByBuilding: QSLessorsRiskBuilding[];
      }>(`${GET_LESSORS_RISK_DOCUMENTS}/${quoteNumber}`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to retrieve BOP lessors risk documents.', {
            severity: 'error',
            metaData: {
              quoteNumber: quoteNumber,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        }),
        map((resp) => {
          if (resp?.documentsByBuilding) {
            return resp.documentsByBuilding;
          }
          return null;
        })
      );
  }

  public getPriceReductionEstimate(
    policyId: string,
    body: PriceReductionLambdaPayload
  ): Observable<EstimateResponse> {
    return this.http
      .post<EstimateResponse>(
        `${ESTIMATE_PRICE_REDUCTION_URI}/${policyId}/price-reduction-estimate`,
        { body: body }
      )
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to price reduction estimate.', {
            severity: 'error',
            metaData: {
              body: body,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }
}
