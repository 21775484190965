import { of as observableOf, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VALIDATE_PASSWORD_LINK_API_URI } from 'app/constants';

import { SentryService } from 'app/core/services/sentry.service';

@Injectable()
export class PasswordLinkService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  validatePasswordLink(email: string, recoveryToken: string): Observable<boolean> {
    return this.http
      .post<any>(
        VALIDATE_PASSWORD_LINK_API_URI,
        JSON.stringify({
          email: email,
          recoveryToken: recoveryToken,
        }),
        {
          headers: { 'Content-type': 'application/json' },
        }
      )
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Recovery token is invalid', {
            severity: 'warning',
            metaData: {
              email,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(false);
        })
      );
  }
}
