// WARNING
// Use caution when adding imports to environment files to avoid creating circular dependencies
// Use the same pattern across production and all lower environments when setting up environment variables
//   ex. if you import a constant to set an environment variable in production, import a constant in lower environment files as well
// This allows us to verify production-like behavior when testing changes in lower environments

export const environment = {
  adpBearerToken: 'eaa35c67-f5a9-4536-a7fe-431cff0ce08a',
  amplitudeKeys: {
    agentPortal: '698f6ad9144b963173a5d24792679fa4',
    intuit: '698f6ad9144b963173a5d24792679fa4',
    brokerLandingPage: '698f6ad9144b963173a5d24792679fa4',
  },
  alertStatusUri:
    'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/alerts/alert_staging.txt',
  apiHost: 'https://api.attunestaging.com/v3/auth',
  apiV3Host: 'https://api.attunestaging.com/v3',
  apiV4Host: 'https://api.attunestaging.com/v4',
  billerGroupId: 'XM7',
  bopV2LaunchDates: {
    AZ: '2020-10-23',
    IL: '2020-10-23',
    MT: '2020-09-01',
    RI: '2020-10-23',
    MI: '2020-11-30',
    NM: '2020-12-03',
    NV: '2020-12-08',
    UT: '2020-12-08',
    TN: '2020-12-17',
    WI: '2020-12-17',
    IA: '2020-12-17',
    AL: '2021-01-13',
    NH: '2021-01-13',
    SC: '2021-01-19',
    OR: '2021-02-08',
    MS: '2021-02-22',
    OK: '2021-02-22',
    DE: '2021-02-22',
    AR: '2021-02-22',
    IN: '2021-02-26',
    SD: '2021-03-10',
    MO: '2021-03-18',
    PA: '2021-03-25',
    WY: '2021-03-25',
    VA: '2021-03-25',
    VT: '2021-03-25',
    ND: '2021-03-25',
    OH: '2021-03-31',
    ME: '2021-03-31',
    NC: '2021-03-31',
    KS: '2021-03-31',
    WV: '2021-03-31',
    KY: '2021-03-31',
    TX: '2021-04-01',
    GA: '2021-04-06',
    MA: '2021-04-06',
    CO: '2021-04-06',
    NE: '2021-04-06',
    MD: '2021-04-06',
    DC: '2021-04-19',
    WA: '2021-05-04',
    LA: '2021-09-16',
    CT: '2021-09-23',
    FL: '2021-10-20',
    NJ: '2022-01-09',
    ID: '2022-01-09',
    CA: '2022-04-19',
    NY: '2024-07-15',
  },
  nyMeTooLaunchDate: '2023-03-14',
  digitalProductsRolledOver: ['liberty_mutual', 'coalition'],
  domainAuthAllowlist: ['api.attunestaging.com'],
  eligibilityLiveChatProducers: ['SIATEST00011', 'CASURA00011'],
  enabledBopEndorsementSteps: [
    'account',
    'additionalInsureds',
    'coverages',
    'locations',
    'namedInsureds',
    'otherEndorsements',
    'waiverOfSubrogation',
  ],
  googleMapsKey: 'AIzaSyBh-PWHWNOsBVVN3H2erSgUIBYlKtFe26s',
  hasBackdatedEndorsements: true,
  hasEndorsements: true,
  hasHab: true,
  invoiceVendorPaymentUri: 'https://www.payconnexion.com/pconWeb/epay.jhtml',
  launchDarklyKey: '603d376e68a2a111cc28e802',
  useMockFeatureFlags: false,
  production: true,
  quoteIndicationEndpoint: 'staging-bop-quote-indications',
  requireAdpAuth: true,
  showBopBindEducational: true,
  showNewHelpCenter: true,
  showInvoiceBanners: true,
  showSampleQuoteOnboarding: true,
  stage: 'staging',
  statesEnabledForCyberSurplus: [
    'AL',
    'AZ',
    'CO',
    'FL',
    'IL',
    'LA',
    'MA',
    'MI',
    'MO',
    'MS',
    'NC',
    'NJ',
    'NV',
    'NY',
    'OK',
    'RI',
    'SC',
    'TN',
    'TX',
    'VA',
  ],
  stripePublishableKey: 'pk_test_ATD2S5eu5maCp3TASp5x9i1r',
  showScheduleOfInvoices: true,
  showSubmissionDownload: true,
  isSandboxEnv: false,
  useGooglePlacesAutoComplete: true,
  showUserManagement: true,
  userManagementEnableList: [],
  showNewRenewalsUI: true,
  showWelcomeBack: true,
  showCreditableWaterSupplyField: true,
  storePrefillDeclineDraftQuotes: true,
  usePlanckEligibility: true,
  wcCrossSellPromptProducers: [] as string[],
  bindGoalsProducers: [] as string[],
  eligibilityOverrideProducers: ['CASURA00011'],
  intraReferralProducers: [] as string[],
  lroFlowEnabled: true,
  bopCatCutoffDate: '2022-08-31',
  allowedCSXSproducerCodes: ['MORSTAN00001', 'GRACEINS00001', 'GLOBAL00001'],
  oktaClientId: '0oai0k48gVefHrWDU356',
  oktaLoginRedirectURI: 'https://app.attunestaging.com/callback',
  oktaLogoutRedirectURI: 'https://app.attunestaging.com/',
};
