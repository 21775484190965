import { BopQuotePayload } from 'app/features/attune-bop/models/bop-policy';
import {
  Clause,
  Period,
  RetrieveQuoteResponse,
  ULPolicy,
  PeriodAnswer,
} from 'app/bop/guidewire/typings';
import {
  existsCoverageClause,
  findCoverageTermLimitFromClauses,
  getEmployersLiabilityStateInfixFromState,
} from 'app/bop/guidewire/translation-helpers';
import { KNOWN_CODES } from 'app/bop/guidewire/known-pattern-codes';
import * as _ from 'lodash';
import { formatMoneyNoCents } from 'app/shared/helpers/number-format-helpers';

export const translateGWExcessQuote = (
  gwPolicy: RetrieveQuoteResponse
): BopQuotePayload['excessLiability'] | null => {
  if (!gwPolicy) {
    return null;
  }

  const period: Period = gwPolicy.return.QuoteSubmissionRequest.Periods.Entry[0];
  const line = period.CommercialUmbrellaLine_CUE;
  if (!line) {
    return null;
  }
  const coverageClauses: Clause[] = line.AllClauses.Entry;

  const stateInfix = getEmployersLiabilityStateInfixFromState(period.BaseState);

  const excessLiabilityPerAccidentCoverageValue = findCoverageTermLimitFromClauses(
    coverageClauses,
    'CUP_EmployersLiability_CUE',
    `CUP_ELBodilyInjuryByAccident${stateInfix}_CUE`
  );

  const excessLiabilityPerDiseaseCoverageValue = findCoverageTermLimitFromClauses(
    coverageClauses,
    'CUP_EmployersLiability_CUE',
    `CUP_ELBodilyInjuryByDisease${stateInfix}_CUE`
  );

  const excessLiabilityPerPolicyCoverageValue = findCoverageTermLimitFromClauses(
    coverageClauses,
    'CUP_EmployersLiability_CUE',
    `CUP_ELBodilyInjuryByDiseasePL${stateInfix}_CUE`
  );
  const excessLiability: BopQuotePayload['excessLiability'] = {
    excessLiabilityCoverageIsScheduled: existsCoverageClause(
      coverageClauses,
      'CUP_EmployersLiability_CUE'
    ),
    excessLiabilityPerAccidentCoverage:
      !excessLiabilityPerAccidentCoverageValue ||
      excessLiabilityPerAccidentCoverageValue === 'Unlimited'
        ? String(excessLiabilityPerAccidentCoverageValue)
        : formatMoneyNoCents(String(excessLiabilityPerAccidentCoverageValue)),

    excessLiabilityPerDiseaseCoverage:
      !excessLiabilityPerDiseaseCoverageValue ||
      excessLiabilityPerDiseaseCoverageValue === 'Unlimited'
        ? String(excessLiabilityPerDiseaseCoverageValue)
        : formatMoneyNoCents(String(excessLiabilityPerDiseaseCoverageValue)),

    excessLiabilityPerPolicyCoverage:
      !excessLiabilityPerPolicyCoverageValue ||
      excessLiabilityPerPolicyCoverageValue === 'Unlimited'
        ? String(excessLiabilityPerPolicyCoverageValue)
        : formatMoneyNoCents(String(excessLiabilityPerPolicyCoverageValue)),

    excessLiabilityLimit: Number(
      findCoverageTermLimitFromClauses(
        coverageClauses,
        'CUP_Primary_Liability_CUE',
        'CUP_GeneralAggregateLimit_CUE'
      )
    ),
    excessCommercialAutoCoverageIsScheduled: existsCoverageClause(
      coverageClauses,
      'CUP_AutoMobileLiability_CUE'
    ),
    excessCommercialAutoCombinedLimit: Number(
      findCoverageTermLimitFromClauses(
        coverageClauses,
        'CUP_AutoMobileLiability_CUE',
        'CUP_AMLCombSingleLimit_CUE'
      )
    ),
    excessAnnualRevenue: '$0', // This field only exists for manual submissions for Core Specialty Excess.
    excessEmployersUnderlyingPremium: '$0', // This field only exists for manual submissions for Core Specialty Excess.
    excessCommercialAutoUnderlyingPremium: '$0', // This field only exists for manual submissions for Core Specialty Excess.
  };

  const commercialAutoULPolicy = _.find(line.ULPolicies.Entry, (policy: ULPolicy) => {
    // Note: CA === commercial auto
    return policy.PolicyType === 'CA';
  });

  const excessCommercialAutoCoverageVehicleCounts = {
    BusesOver20PassengersCount: 0,
    CementMixersCount: 0,
    ExHvyTruckAndTractorLHCount: 0,
    ExHvyTruckAndTractorSHCount: 0,
    HeavyTrucksCount: 0,
    LightTrucksCount: 0,
    MediumTrucksCount: 0,
    PrivatePassengerVehicleCount: 0,
  };

  const excessCommercialAutoCoverageVehicleExposure = {
    CommodityIIIORIVHauling: false,
    GasHaulHzdWasteRedLblMat: false,
    MoreThan10HtlMtlParkVan: false,
    MoreThan15PassengerCourtseyVan: false,
    PoliceVehFireTrkAmbulance: false,
    RapidDevliveryOperations: false,
    SchoolBusOrVan: false,
  };

  if (commercialAutoULPolicy && commercialAutoULPolicy.VehicleFleetExposure) {
    // Note: Populate counts
    excessCommercialAutoCoverageVehicleCounts.BusesOver20PassengersCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.BusesOver20PassengersCount
    );
    excessCommercialAutoCoverageVehicleCounts.CementMixersCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.CementMixersCount
    );
    excessCommercialAutoCoverageVehicleCounts.ExHvyTruckAndTractorLHCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.ExHvyTruckAndTractorLHCount
    );
    excessCommercialAutoCoverageVehicleCounts.ExHvyTruckAndTractorSHCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.ExHvyTruckAndTractorSHCount
    );
    excessCommercialAutoCoverageVehicleCounts.HeavyTrucksCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.HeavyTrucksCount
    );
    excessCommercialAutoCoverageVehicleCounts.LightTrucksCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.LightTrucksCount
    );
    excessCommercialAutoCoverageVehicleCounts.MediumTrucksCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.MediumTrucksCount
    );
    excessCommercialAutoCoverageVehicleCounts.PrivatePassengerVehicleCount = Number(
      commercialAutoULPolicy.VehicleFleetExposure.PrivatePassengerVehicleCount
    );

    // Note: Populate exposure
    excessCommercialAutoCoverageVehicleExposure.CommodityIIIORIVHauling =
      commercialAutoULPolicy.VehicleFleetExposure.CommodityIIIORIVHauling;
    excessCommercialAutoCoverageVehicleExposure.GasHaulHzdWasteRedLblMat =
      commercialAutoULPolicy.VehicleFleetExposure.GasHaulHzdWasteRedLblMat;
    excessCommercialAutoCoverageVehicleExposure.MoreThan10HtlMtlParkVan =
      commercialAutoULPolicy.VehicleFleetExposure.MoreThan10HtlMtlParkVan;
    excessCommercialAutoCoverageVehicleExposure.MoreThan15PassengerCourtseyVan =
      commercialAutoULPolicy.VehicleFleetExposure.MoreThan15PassengerCourtseyVan;
    excessCommercialAutoCoverageVehicleExposure.PoliceVehFireTrkAmbulance =
      commercialAutoULPolicy.VehicleFleetExposure.PoliceVehFireTrkAmbulance;
    excessCommercialAutoCoverageVehicleExposure.RapidDevliveryOperations =
      commercialAutoULPolicy.VehicleFleetExposure.RapidDevliveryOperations;
    excessCommercialAutoCoverageVehicleExposure.SchoolBusOrVan =
      commercialAutoULPolicy.VehicleFleetExposure.SchoolBusOrVan;
  }

  excessLiability.excessCommercialAutoCoverageVehicleCounts =
    excessCommercialAutoCoverageVehicleCounts;
  excessLiability.excessCommercialAutoCoverageVehicleExposure =
    excessCommercialAutoCoverageVehicleExposure;

  let UIUIMWVExposureQuestion;
  if (commercialAutoULPolicy && commercialAutoULPolicy.ULPolicyAnswers_HUSA) {
    UIUIMWVExposureQuestion = commercialAutoULPolicy.ULPolicyAnswers_HUSA.Entry.find((entry) => {
      return entry.QuestionCode === 'UIUIMWVExposure';
    });
  }
  let UIUIMWVExposureExists = false;
  if (UIUIMWVExposureQuestion) {
    // Note: AnswerValueAsString is actually a boolean
    UIUIMWVExposureExists = UIUIMWVExposureQuestion.AnswerValueAsString;
  }

  const excessCommercialAutoCoverageStates = {
    hasCommercialAutoInFL:
      findCoverageTermLimitFromClauses(
        coverageClauses,
        'CUP_UM_UIM_CUE',
        'CUP_UMUIMFL_SELECTED'
      ) === 'true',
    hasCommercialAutoInLA:
      findCoverageTermLimitFromClauses(
        coverageClauses,
        'CUP_UM_UIM_CUE',
        'CUP_UMUIMLA_SELECTED'
      ) === 'true',
    hasCommercialAutoInNH:
      findCoverageTermLimitFromClauses(
        coverageClauses,
        'CUP_UM_UIM_CUE',
        'CUP_UMUIMNH_SELECTED'
      ) === 'true',
    hasCommercialAutoInVT:
      findCoverageTermLimitFromClauses(
        coverageClauses,
        'CUP_UM_UIM_CUE',
        'CUP_UMUIMVT_SELECTED'
      ) === 'true',
    hasCommercialAutoInWV:
      findCoverageTermLimitFromClauses(
        coverageClauses,
        'CUP_UM_UIM_CUE',
        'CUP_UMUIMWV_SELECTED'
      ) === 'true',
  };

  excessLiability.excessCommercialAutoCoverageStates = excessCommercialAutoCoverageStates;

  const periodAnswersList = _.get(period, 'PeriodAnswers.Entry');
  let uwQuestions = {};

  if (periodAnswersList) {
    periodAnswersList.forEach((entry: PeriodAnswer) => {
      if (entry.QuestionCode.match('^CUP')) {
        uwQuestions = {
          ...uwQuestions,
          [entry.QuestionCode]: entry.AnswerValueAsString,
        };
      }
    });
  }

  excessLiability.uwQuestions = uwQuestions;
  return excessLiability;
};

export const extractUnknownClauses = (gwPolicy: RetrieveQuoteResponse): Clause[] => {
  const bopPeriod: Period = gwPolicy.return.QuoteSubmissionRequest.Periods.Entry[0];
  if (!bopPeriod.BP7Line) {
    return [];
  }
  const covClauses: Clause[] = bopPeriod.BP7Line.AllClauses.Entry;

  return _.reject(covClauses, (covClause: Clause) => {
    return _.includes(KNOWN_CODES, covClause.Pattern.Code);
  });
};
