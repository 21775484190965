<div class="policy-pane-content">
  <div class="policy-pane-nav">
    <a class="button button__nav button__no-margin" [routerLink]="['../../..']">
      <span class="icon-arrow-left button-icon"></span>
      Back
    </a>
  </div>
  <ng-container *ngIf="!(wcPolicy$ | async)">
    <div class="placeholder-h1"></div>
    <ul>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
    </ul>
  </ng-container>

  <ng-container *ngIf="wcPolicy$ | async as loadedBoundPolicy">
    <div class="policy-pane-header" *ngIf="!loadedBoundPolicy">
      <h2>Error loading Workers' Comp policy...</h2>
      <small>Is this URL correct?</small>
    </div>

    <div class="policy-pane-header" *ngIf="loadedBoundPolicy">
      <div class="policy-pane-header-left">
        <div class="product-badge product-badge__wc">
          <div class="product-badge-carrier-icon product-badge-carrier-icon__employers"></div>
        </div>
        <div class="policy-pane-quote-info">
          <h2 class="policy-pane-header-h2">Policy details</h2>
          <small class="gray-text"
            >{{ loadedBoundPolicy.policyNumber }}{{ loadedBoundPolicy.term }}</small
          >
        </div>
      </div>
      <div class="policy-pane-header-right">
        <h2 class="policy-pane-header-h2 js-premium-number">
          {{ loadedBoundPolicy.netPremium | currency }}
        </h2>
        <small class="gray-text">Premium</small>
      </div>
    </div>

    <div class="policy-detail-subsection">
      <ng-container *ngIf="loadedBoundPolicy">
        <dl>
          <dt>Status</dt>
          <dd><app-policy-status [policyStatus]="loadedBoundPolicy.status"></app-policy-status></dd>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{{ getUtcDate(loadedBoundPolicy.effectiveDate) }}</dd>
        </dl>
        <dl *ngIf="loadedBoundPolicy.status === 'cancelled'">
          <dt>Cancellation Date</dt>
          <dd>{{ getUtcDate(loadedBoundPolicy.cancellationDate) }}</dd>
        </dl>
        <dl *ngIf="loadedBoundPolicy.status !== 'cancelled'">
          <dt>Expiration Date</dt>
          <dd>{{ getUtcDate(loadedBoundPolicy.expirationDate) }}</dd>
        </dl>
      </ng-container>
    </div>

    <div class="policy-detail-subsection">
      <ng-container *ngIf="loadedBoundPolicy.docsStatus === 'error'">
        <app-dialog-box
          type="warning"
          header="Error loading policy documents"
          class="dialog-text__full-width"
        >
          If this error persists, our Customer Care team would be happy to assist you.
          <div class="dialog-text-links">
            <a class="dialog-text-link" href="/support">Get in touch</a>
          </div>
        </app-dialog-box>
      </ng-container>

      <ng-container *ngIf="loadedBoundPolicy.docsStatus === 'not-ready'">
        <h3>Policy Documents</h3>
        <app-dialog-box
          type="primary"
          header="Policy documents generating"
          class="dialog-text__full-width"
        >
          EMPLOYERS requires 24-48 hours to generate official policy documents. Our team will reach
          out to the binding broker with these documents when they are ready. If proof of bind is
          required immediately, our team can provide an ACORD 75 upon request.
          <div class="dialog-text-links">
            <a class="dialog-text-link" href="/support">Request ACORD 75</a>
          </div>
        </app-dialog-box>
      </ng-container>

      <ng-container *ngIf="loadedBoundPolicy.docsStatus === 'success'">
        <h3>Policy Documents</h3>
        <app-document-download
          displayName="Policy Document"
          [documentDownload$]="policyDocumentDownload$"
        ></app-document-download>
      </ng-container>
    </div>

    <app-post-bind-servicing-information carrier="employers"> </app-post-bind-servicing-information>
  </ng-container>
</div>
