import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicingInfoProduct } from '../../../../shared/carrier-partner-servicing-info/helpers';
import { HISCOX_PRODUCTS } from '../../models/hiscox-types';

const productTypeToProductName: Record<HISCOX_PRODUCTS, ServicingInfoProduct> = {
  [HISCOX_PRODUCTS.gl]: 'General Liability',
  [HISCOX_PRODUCTS.pl]: 'Professional Liability',
};

@Component({
  selector: 'app-hiscox-bind-servicing-info',
  templateUrl: './hiscox-bind-servicing-info-page.component.html',
})
export class HiscoxBindServicingInfoPageComponent implements OnInit {
  product: ServicingInfoProduct;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const productType: HISCOX_PRODUCTS = this.route.parent?.snapshot.params.productType;
    this.product = productTypeToProductName[productType];
  }
}
