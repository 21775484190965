<main id="forgot-password" class="login-box">
  <app-logo-with-text></app-logo-with-text>

  <h1 class="login-title-margin">Reset password</h1>
  <p class="gray-text" *ngIf="!completedForEmail">
    Enter the email address associated with your accounts, and we'll email you a link to reset your
    password.
  </p>

  <app-dialog-box *ngIf="completedForEmail" type="success">
    A password reset link has been sent to <strong>{{ completedForEmail }}</strong
    >.
  </app-dialog-box>
  <form class="login-box-form" (ngSubmit)="handleSubmit($event)" *ngIf="!completedForEmail">
    <div class="form-field">
      <input
        id="login-form-email"
        placeholder="Email"
        type="text"
        name="username"
        [formControl]="email"
        required
      />
    </div>

    <p class="login-box-failure-message" *ngIf="error">
      <span>{{ error }}</span>
    </p>

    <button
      id="forgot-password-submit-button"
      type="submit"
      class="button button__primary button__full-width button__no-margin"
      [disabled]="loading"
    >
      <div class="button-loader" *ngIf="loading">
        <svg class="circle-spinner">
          <circle
            cx="11"
            cy="11"
            r="9"
            stroke-width="2"
            stroke-dasharray="0,1000"
            d="M5 40 l215 0"
            fill="transparent"
          />
        </svg>
      </div>
      <ng-container *ngIf="loading">Sending... </ng-container>
      <ng-container *ngIf="!loading">Send reset link</ng-container>
    </button>
  </form>
  <a class="login-tertiary-link" [routerLink]="['/login']"> Back to sign in </a>
</main>

<p class="terms-of-use-text">
  By accessing Attune, you agree to the following
  <a href="https://www.attuneinsurance.com/legal/terms-of-use/">Terms of Use</a>.
</p>
<app-footer showPhoneNumber="true"> </app-footer>
