import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { SentryService } from '../../core/services/sentry.service';
import {
  Theme,
  ZendeskWidgetService,
  ZendeskSettings,
  ZendeskWindow,
  defaultTheme,
} from './zendesk-widget.service';

export enum ZendeskKey {
  AGENT_PORTAL_PRODUCTION = '7afad69b-2c14-45c9-9e42-8213a9bbad54',
  AGENT_PORTAL_SANDBOX = '2bc2ed72-345b-4286-8f8f-44599531c532',
}

const getWindow = () => window as ZendeskWindow;
const defaultSettings: ZendeskSettings = {
  webWidget: {
    color: defaultTheme,
    offset: {
      vertical: '10px',
    },
  },
};

@Injectable()
export class ZendeskService {
  private automaticMessage: string;
  private automaticMessageStaged: boolean;
  private initialStyle: Partial<Pick<CSSStyleDeclaration, 'cssText'>>;
  private window: ZendeskWindow;

  constructor(
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    public widget: ZendeskWidgetService
  ) {
    this.automaticMessage = '';
    this.automaticMessageStaged = false;
    this.window = getWindow();
  }

  public init(zendeskKey: ZendeskKey) {
    this.initSettings(defaultSettings);

    const e = document.createElement('script');
    e.id = 'ze-snippet';
    e.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    document.body.appendChild(e);
    this.onLoad();
  }

  public ready(): boolean {
    return Object.keys(this.window).includes('zE');
  }

  public getSettings(): ZendeskSettings {
    return this.window.zESettings || {};
  }

  public updateSettings(settings: ZendeskSettings) {
    this.widget.updateSettings(settings);
  }

  /**
   *
   * @param targetWidth desired width of centered widget in rem
   */
  public centerWidget(targetWidth: number) {
    // widget iframe contains a nested div with 16px padding
    // increase target width by padding to get actual width
    const internalSidePadding = 2 * 1.6; // rem
    const width = targetWidth + internalSidePadding; // rem
    this.restyleWidget({
      position: 'absolute',
      right: `calc(50vw - ${width / 2}rem)`,
      top: '30.3rem',
      width: `${width}rem`,
    });
  }

  public uncenterWidget() {
    this.restyleWidget(this.initialStyle);
  }

  public restyleWidget(style: Partial<CSSStyleDeclaration>, attempts = 0) {
    const retryLimit = 7;
    const retryDelay = 300; // ms
    if (attempts > retryLimit) {
      this.sentryService.notify('Unable to restyle widget, retry limit exceeded', {
        severity: 'error',
        metaData: {
          retryDelay,
          retryLimit,
        },
      });
      return;
    }
    const webWidget = document.getElementById('webWidget');
    // webWidget iframe may not be loaded yet; keep retrying
    if (!webWidget) {
      setTimeout(() => {
        this.restyleWidget(style, attempts + 1);
      }, retryDelay);
      return;
    }
    if (!this.initialStyle) {
      this.initialStyle = {
        cssText: webWidget.style.cssText,
      };
    }
    for (const k in style) {
      if (k !== 'length' && k !== 'parentRule') {
        webWidget.style[k] = style[k] as string;
      }
    }
  }

  public setTheme(theme: Theme | undefined) {
    const additionalSettings = {
      webWidget: { color: theme },
    };
    const newSettings = this.mergeSettings(additionalSettings);
    this.updateSettings(newSettings);
    const webWidget = document.getElementById('launcher');
  }

  public skipHelpCenter() {
    const additionalSettings = {
      webWidget: {
        helpCenter: {
          suppress: true,
        },
      },
    };
    const newSettings = this.mergeSettings(additionalSettings);
    this.updateSettings(newSettings);
  }

  public unskipHelpCenter() {
    const additionalSettings = {
      webWidget: {
        helpCenter: {
          suppress: false,
        },
      },
    };
    const newSettings = this.mergeSettings(additionalSettings);
    this.updateSettings(newSettings);
  }

  // checks if any chat departments are not 'offline' ('online' or 'away')
  public isChatLive(): boolean {
    const departments = this.widget.getChatDepartments();
    for (const department of departments) {
      if (department.status !== 'offline') {
        return true;
      }
    }
    return false;
  }

  /**
   * prepares an automatic message to be sent after the user's initial message
   *
   * we are unable to prefill the user's message when they enter the pre-chat
   * form, so to provide additional context to the CC team, we append the user's
   * message with an automatic follow-up chat message
   *
   * when the chat ends, we disable the automatic follow-up so that additional
   * chats started by the user do not re-trigger the follow-up message
   */
  public stageAutomaticMessage(message: string) {
    // only define event listeners on first call to stage automatic message
    // automatic message will update on subsequent calls
    if (!this.automaticMessage) {
      this.widget.onChatStart(this.sendAutomaticMessage.bind(this));
      // disable 'chat:start' callback logic on 'chat:end', unable to remove directly
      this.widget.onChatEnd(() => {
        this.automaticMessageStaged = false;
      });
    }
    this.automaticMessage = message;
    this.automaticMessageStaged = true;
  }

  private initSettings(settings: ZendeskSettings) {
    this.window.zESettings = settings;
  }

  // applied once, after global instance is ready
  private onLoad() {
    if (!this.ready()) {
      setTimeout(this.onLoad.bind(this), 500);
      return;
    }
    this.widget.onChatStart(this.addSessionIdTag.bind(this));
  }

  public addSessionIdTag() {
    const sessionId = this.amplitudeService.getSessionId();
    if (sessionId) {
      this.widget.addChatTags([sessionId]);
    }
  }

  private mergeSettings(newSettings: ZendeskSettings): ZendeskSettings {
    const currentSettings = this.getSettings();
    return merge({} as typeof currentSettings, currentSettings, newSettings);
  }

  private sendAutomaticMessage() {
    // unable to remove event listeners within widget, use automaticMessageStaged instead
    if (this.automaticMessageStaged) {
      this.widget.sendChat(this.automaticMessage);
    }
  }
}
