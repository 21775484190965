import { values } from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  EXCESS_LIABILITY_LIMITS,
  DEFAULT_EXCESS_LIABILITY_COVERAGE_OPTIONS,
} from 'app/features/attune-bop/models/constants';

import { checkCommercialAutoSumOfVehicles } from 'app/features/attune-bop/models/form-validators';
import { rangeValidator } from 'app/shared/helpers/form-helpers';

export const ExcessLiabilityMixin = <T extends Constructor>(BaseClass: T) => {
  return class extends BaseClass {
    excessLiabilityPerAccidentCoverageOptions: {
      [key: string]: string;
    } = DEFAULT_EXCESS_LIABILITY_COVERAGE_OPTIONS;
    excessLiabilityPerDiseaseCoverageOptions: {
      [key: string]: string;
    } = DEFAULT_EXCESS_LIABILITY_COVERAGE_OPTIONS;
    excessLiabilityPerPolicyCoverageOptions: {
      [key: string]: string;
    } = DEFAULT_EXCESS_LIABILITY_COVERAGE_OPTIONS;

    createExcessLiabilityFormGroup(): UntypedFormGroup {
      const formBuilder = new UntypedFormBuilder();

      return formBuilder.group({
        uwQuestions: formBuilder.group({}),
        excessLiabilityLimit: [
          { value: EXCESS_LIABILITY_LIMITS[0], disabled: true },
          Validators.required,
        ],
        excessLiabilityCoverageIsScheduled: [{ value: null, disabled: true }, Validators.required],
        excessLiabilityPerAccidentCoverage: [{ value: null, disabled: true }, Validators.required],
        excessLiabilityPerDiseaseCoverage: [{ value: null, disabled: true }, Validators.required],
        excessLiabilityPerPolicyCoverage: [{ value: null, disabled: true }, Validators.required],
        excessCommercialAutoCoverageIsScheduled: [
          { value: null, disabled: true },
          Validators.required,
        ],
        excessCommercialAutoCombinedLimit: [{ value: null, disabled: true }, Validators.required],
        excessCommercialAutoCoverageVehicleCounts: formBuilder.group(
          {
            PrivatePassengerVehicleCount: [{ value: 0, disabled: true }, Validators.required],
            LightTrucksCount: [{ value: 0, disabled: true }, Validators.required],
            MediumTrucksCount: [{ value: 0, disabled: true }, Validators.required],
            HeavyTrucksCount: [{ value: 0, disabled: true }, Validators.required],
            ExHvyTruckAndTractorSHCount: [{ value: 0, disabled: true }, Validators.required],
            ExHvyTruckAndTractorLHCount: [{ value: 0, disabled: true }, Validators.required],
            CementMixersCount: [{ value: 0, disabled: true }, Validators.required],
            BusesOver20PassengersCount: [{ value: 0, disabled: true }, Validators.required],
          },
          {
            validators: checkCommercialAutoSumOfVehicles,
          }
        ),
        excessCommercialAutoCoverageVehicleExposure: formBuilder.group({
          SchoolBusOrVan: [{ value: false, disabled: true }, Validators.required],
          PoliceVehFireTrkAmbulance: [{ value: false, disabled: true }, Validators.required],
          MoreThan10HtlMtlParkVan: [{ value: false, disabled: true }, Validators.required],
          MoreThan15PassengerCourtseyVan: [{ value: false, disabled: true }, Validators.required],
          RapidDevliveryOperations: [{ value: false, disabled: true }, Validators.required],
          GasHaulHzdWasteRedLblMat: [{ value: false, disabled: true }, Validators.required],
          CommodityIIIORIVHauling: [{ value: false, disabled: true }, Validators.required],
        }),
        excessCommercialAutoCoverageStates: formBuilder.group({
          hasCommercialAutoInFL: [{ value: false, disabled: true }, Validators.required],
          hasCommercialAutoInLA: [{ value: false, disabled: true }, Validators.required],
          hasCommercialAutoInNH: [{ value: false, disabled: true }, Validators.required],
          hasCommercialAutoInVT: [{ value: false, disabled: true }, Validators.required],
          hasCommercialAutoInWV: [{ value: false, disabled: true }, Validators.required],
        }),
        excessAnnualRevenue: [
          { value: '$0', disabled: true },
          [Validators.required, rangeValidator(1)],
        ],
        excessCommercialAutoUnderlyingPremium: [{ value: '$0', disabled: true }],
        excessEmployersUnderlyingPremium: [{ value: '$0', disabled: true }],
      });
    }

    patchEmployersLiabilityCoverage(excessLiability: UntypedFormGroup) {
      excessLiability.patchValue({
        excessLiabilityPerAccidentCoverage: values(
          this.excessLiabilityPerAccidentCoverageOptions
        )[0],
        excessLiabilityPerDiseaseCoverage: values(this.excessLiabilityPerDiseaseCoverageOptions)[0],
        excessLiabilityPerPolicyCoverage: values(this.excessLiabilityPerPolicyCoverageOptions)[0],
      });
    }

    updateCoverageOptionsByBaseState(
      baseState: string,
      excessLiabilityFormGroup: UntypedFormGroup
    ) {
      if (baseState === 'NY' || baseState === 'MA') {
        this.excessLiabilityPerAccidentCoverageOptions =
          this.excessLiabilityPerDiseaseCoverageOptions =
          this.excessLiabilityPerPolicyCoverageOptions =
            {
              Unlimited: 'Unlimited',
            };
      } else if (baseState === 'OH' || baseState === 'TX') {
        this.excessLiabilityPerAccidentCoverageOptions =
          this.excessLiabilityPerDiseaseCoverageOptions =
          this.excessLiabilityPerPolicyCoverageOptions =
            {
              '$1,000,000': '$1,000,000',
              '$10,000,000': '$10,000,000',
              '$2,000,000': '$2,000,000',
              '$3,000,000': '$3,000,000',
              '$4,000,000': '$4,000,000',
              '$5,000,000': '$5,000,000',
              '$6,000,000': '$6,000,000',
              '$7,000,000': '$7,000,000',
              '$8,000,000': '$8,000,000',
              '$9,000,000': '$9,000,000',
            };
      } else {
        this.excessLiabilityPerAccidentCoverageOptions =
          this.excessLiabilityPerDiseaseCoverageOptions =
          this.excessLiabilityPerPolicyCoverageOptions =
            DEFAULT_EXCESS_LIABILITY_COVERAGE_OPTIONS;
      }
      this.patchEmployersLiabilityCoverage(excessLiabilityFormGroup);
    }
  };
};
