import { Injectable } from '@angular/core';

import { BOOLEAN_FLAG_NAMES, FeatureFlagService } from './feature-flag.service';
import { SentryService } from './sentry.service';
import { environment } from 'environments/environment';
import { Observable, from, map, switchMap, tap, of } from 'rxjs';
import { UserService } from './user.service';
import { User } from 'app/shared/models/user';

export enum SegmentEventName {
  PAGE = 'page',
  IDENTIFY = 'identify',
  TRACK = 'track',
  GROUP = 'group',
}

export type AttuneEventName =
  | 'Logged In'
  | 'Account Create Started'
  | 'Quote Started'
  | 'Quote Attempted'
  | 'Bind Attempted';

export enum AccountCreateStartedProperties {
  USER = 'user',
  PRODUCER_CODE = 'producer_code',
}

// For Started, Attempted events
export enum QuoteEventProperties {
  PRODUCT = 'product',
  CARRIER = 'carrier',
  CLASS_CODE = 'class_code',
  NAICS_CODE = 'naics_code',
  INDUSTRY = 'industry',
  PRIMARY_STATE = 'primary_state',
  USER = 'user',
  PRODUCER_CODE = 'producer_code',
  INSURED_ADDRESS = 'insured_address',
  INSURED_EMAIL = 'insured_email',
  BUSINESS_NAME = 'business_name',
}

export enum IdentifyEventTraits {
  FIRST_NAME = 'firstName', // reserved key name, non snake-case
  LAST_NAME = 'lastName', // reserved key name, non snake-case
  EMAIL = 'email',
  USER = 'user',
  PRODUCER_CODE = 'producer_code',
}

export enum LogInEventProperties {
  FIRST_NAME = 'firstName', // reserved key name, non snake-case
  LAST_NAME = 'lastName', // reserved key name, non snake-case
  EMAIL = 'email',
  USER = 'user',
  PRODUCER_CODE = 'producer_code',
}

type SegmentTrackEventProperties =
  | Partial<Record<AccountCreateStartedProperties, string | object | null>>
  | Partial<Record<QuoteEventProperties, string | object | null>>
  | Partial<Record<LogInEventProperties, string | object | null>>;

type SegmentIdentifyEventTraits = Partial<Record<IdentifyEventTraits, any>>;

export interface SegmentPageEventPayload {
  currentUrl: string;
  previousUrl?: string;
  category?: string;
  name?: string;
  properties?: Record<string, string>;
  options?: Record<string, string>;
  callback?: Function;
}

export interface SegmentIdentifyEventPayload {
  traits: SegmentIdentifyEventTraits;
  options?: Record<string, string>;
  callback?: Function;
}

export interface SegmentTrackEventPayload {
  event: AttuneEventName;
  properties?: SegmentTrackEventProperties | null;
  options?: Record<string, string>;
  callback?: Function;
}

export interface SegmentGroupEventPayload {
  groupId: string;
  traits?: Record<string, string>;
  options?: Record<string, string>;
  callback?: Function;
}

/**
 * Documentations for segment analytics, website javascript option at:
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript
 */
@Injectable()
export class SegmentService {
  private previousUrl: string;
  private currentUrl: string;
  private segmentFlagEnabled = false;
  private hashedEmail: string;
  private user: User;

  constructor(
    private featureFlagService: FeatureFlagService,
    private sentryService: SentryService,
    private userService: UserService
  ) {
    this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.SEGMENT_ANALYTICS).subscribe((value) => {
      this.segmentFlagEnabled = value || false;
    });

    this.userService
      .getUser()
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
        switchMap((currentUser: User) => {
          if (currentUser && currentUser.userName) {
            return this.getHashedEmail(currentUser.userName);
          }
          return of('');
        })
      )
      .subscribe((emailHash) => {
        this.hashedEmail = emailHash;
        // Moved this event to the service constructor, from the app.component
        // since this is when the user is determined for the first time
        this.identify({
          traits: {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.userName.toLowerCase(),
            user: this.user,
            producer_code: this.user.producer,
          },
        });

        this.track({
          event: 'Logged In',
          properties: {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.userName.toLowerCase(),
            user: this.user,
            producer_code: this.user.producer,
          },
        });
      });
  }

  getHashedEmail(email: string): Observable<string> {
    const encoder = new TextEncoder();
    const lowerEmail = email.toLowerCase();
    const data = encoder.encode(lowerEmail);
    return from(crypto.subtle.digest('SHA-256', data)).pipe(
      map((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
      })
    );
  }

  getCommonOptions(options: Record<string, string>) {
    return {
      ...options,
      environment: environment.stage,
      source: this.previousUrl,
    };
  }

  getCommonTrackPropeties(properties: SegmentTrackEventProperties) {
    return {
      ...properties,
      user: this.user,
      producer_code: this.user?.producer,
    };
  }

  /**
   *
   * @param payload As defined in SegmentPageEventPayload, all parameters are optional, so defaulting to {} if no params passed in
   */
  page(payload: SegmentPageEventPayload) {
    this.previousUrl = this.currentUrl;
    this.currentUrl = payload.currentUrl;

    if (this.segmentFlagEnabled) {
      try {
        payload.options = this.getCommonOptions(payload.options || {});
        // analytics.page([category], [name], [properties], [options], [callback]);
        (<any>window).analytics.page(
          payload.category,
          payload.name,
          payload.properties || {},
          payload.options,
          payload.callback
        );
      } catch (error) {
        this.sentryService.notify('Segment: Error sending event', {
          severity: 'warning',
          metaData: {
            event: SegmentEventName.PAGE,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
      }
    }
  }

  /**
   * Identify is a special case where we cannot use the user subjest
   * because the event is sent when the app component is created,
   * so the user information has to be passed in the
   * event, otherwise, the user subject is null at this time and will
   * prevent the event from being sent.
   *
   * The service user attribute is set here as well because the
   * user$ subject is of type `CurrentUser` and does not contain the
   * properties found in type `User`
   *
   * @param payload includes email and traits
   */
  identify(payload: SegmentIdentifyEventPayload) {
    if (this.segmentFlagEnabled) {
      this.user = payload.traits.user;
      payload.options = this.getCommonOptions(payload.options || {});
      try {
        // identify([userId], [traits], [options], [callback]);
        (<any>window).analytics.identify(
          this.hashedEmail,
          payload.traits || {},
          payload.options,
          payload.callback
        );
      } catch (error) {
        this.sentryService.notify('Segment: Error sending event', {
          severity: 'warning',
          metaData: {
            event: SegmentEventName.IDENTIFY,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
            payload,
          },
        });
      }
    }
  }

  track(payload: SegmentTrackEventPayload) {
    if (this.segmentFlagEnabled) {
      const currentUser = this.user;
      try {
        payload.options = this.getCommonOptions(payload.options || {});
        payload.properties = this.getCommonTrackPropeties(payload.properties || {});
        // analytics.track(event, [properties], [options], [callback]);
        (<any>window).analytics.track(
          payload.event,
          payload.properties || {},
          payload.options,
          payload.callback
        );
      } catch (error) {
        this.sentryService.notify('Segment: Error sending event', {
          severity: 'warning',
          metaData: {
            event: SegmentEventName.TRACK,
            email: currentUser?.userName,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
            payload,
          },
        });
      }
    }
  }

  group(payload: SegmentGroupEventPayload) {
    if (this.segmentFlagEnabled) {
      try {
        payload.options = this.getCommonOptions(payload.options || {});
        // analytics.group(groupId, [traits], [options], [callback]);
        (<any>window).analytics.group(
          payload.groupId,
          payload.traits || {},
          payload.options,
          payload.callback
        );
      } catch (error) {
        this.sentryService.notify('Segment: Error sending event', {
          severity: 'warning',
          metaData: {
            event: SegmentEventName.GROUP,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
            payload,
          },
        });
      }
    }
  }
}
