import { first } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { interval, Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-quoting-progress',
  templateUrl: './quoting-progress.component.html',
})
export class QuotingProgressComponent implements OnDestroy, OnInit {
  @Input() title = 'Processing quote';
  @Input() successComment = 'Preparing policy...';
  @Input() comments: string[] = [];
  @Input() secondsToMax = 60;
  @Input() maxPercent = 90;
  @Input() updateIntervalMs = 3000;
  @Input() successImage: string;
  @Input() finish$: Observable<boolean>;
  @Input() random = true;
  @Input() startPercent = 3;
  @Input() allowClicks = false;
  @Input() infoText = 'This can take up to two minutes';
  @Input() displayInfoText = false;
  public ithComment = 0;
  public percent = 3;

  private sub: Subscription = new Subscription();

  ngOnInit() {
    this.percent = this.startPercent;
    this.sub.add(
      interval(this.updateIntervalMs).subscribe((nth: number) => {
        if (this.comments.length) {
          this.ithComment = this.random
            ? Math.floor(Math.random() * this.comments.length)
            : (this.ithComment + 1) % this.comments.length;
        }
        // Slowly go to max percent
        const secondsElapsed = (nth + 1) * (this.updateIntervalMs / 1000);
        this.percent = Math.round(
          Math.min(this.maxPercent, (secondsElapsed / this.secondsToMax) * 100)
        );
      })
    );

    if (this.finish$) {
      this.finish$.pipe(first()).subscribe(() => {
        this.sub.unsubscribe();
        this.percent = 100;
        this.comments = [];
        this.title = 'Success!';
        this.displayInfoText = false;
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
