/// <reference path="../../../../../../node_modules/@types/googlemaps/index.d.ts" />
// Libraries
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';

// Helpers
import { GOOGLE_MAPS_API_KEY } from 'app/constants';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import { UsStateService } from 'app/shared/services/us-state.service';
import { shouldShowInvalid } from 'app/shared/helpers/form-helpers';
import {
  addressComponentIsCity,
  addressComponentIsState,
  addressComponentIsZipCode,
} from 'app/shared/helpers/google-maps-helpers';

@Component({
  selector: 'app-form-field-address',
  templateUrl: './form-field-address.component.html',
})
export class FormFieldAddressComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() labelText: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() prefix: string;
  @Input() disableStateChange: boolean;
  @Input() displayMap = false;
  @Input() useAddressLine2 = true;
  @Input() questionNote: string;
  @Input() specifierText: string;
  @Output() focusOut: EventEmitter<null> = new EventEmitter();

  public addressMapUrl = '';
  public isMapLoading = false;
  public isStreetNumberMissing = false;

  constructor(
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    public usStateService: UsStateService
  ) {
    this.labelText = this.labelText || 'Address';
  }

  ngOnInit() {
    if (this.form && this.form.value.addressLine1) {
      this.loadAddressMap(this.form.value);
    }
  }

  shouldShowInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.form, this.submitted);
  }

  handleNewAutoCompleteAddress(addressInfo: google.maps.places.PlaceResult) {
    if (!addressInfo || !addressInfo.address_components) {
      return;
    }

    let addressLine1 = '';
    let hasStreetNumber = false;
    addressInfo.address_components.forEach((component: google.maps.GeocoderAddressComponent) => {
      if (component.types.includes('street_number')) {
        addressLine1 += component.long_name + ' ';
        hasStreetNumber = true;
      } else if (component.types.includes('route')) {
        addressLine1 += component.long_name;
      } else if (addressComponentIsCity(component)) {
        this.form.controls.city.setValue(component.long_name);
      } else if (addressComponentIsState(component) && !this.disableStateChange) {
        this.form.controls.state.setValue(component.short_name);
      } else if (addressComponentIsZipCode(component)) {
        this.form.controls.zip.setValue(component.long_name);
      }
    });

    this.form.controls.addressLine1.setValue(addressLine1);
    this.isStreetNumberMissing = !hasStreetNumber;

    if (this.isStreetNumberMissing) {
      this.amplitudeService.track({
        eventName: 'street_number_missing',
        detail: JSON.stringify(addressInfo),
      });
      this.sentryService.notify('Selected address autocomplete is missing street number', {
        severity: 'warning',
        metaData: {
          addressInfo,
        },
      });
    }

    // Finally, load the Google map.
    this.loadAddressMap(this.form.value);
  }

  handleFocusOut() {
    this.focusOut.emit();
  }

  private formatAddress(address: Address): string {
    return `${address.addressLine1}, ${address.city}, ${address.state} ${address.zip}`;
  }

  private loadAddressMap(address: Address): void {
    if (!this.displayMap) {
      return;
    }

    const addressWithZip = this.formatAddress(address);
    this.isMapLoading = true;

    observableOf(
      `https://maps.googleapis.com/maps/api/staticmap?center=${addressWithZip}&zoom=19&size=600x300&markers=color:red%7C${addressWithZip}&maptype=roadmap&key=${GOOGLE_MAPS_API_KEY}`
    )
      .pipe(
        catchError((err) => {
          return '';
        }),
        tap((url: string) => {
          this.addressMapUrl = url;
        }),
        delay(1000) // Add a small delay so the image has more time to load
      )
      .subscribe(() => {
        this.isMapLoading = false;
      });
  }
}
