import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { shouldShowInvalid } from 'app/shared/helpers/form-helpers';
import { WcQuoteFormService } from 'app/workers-comp/employers/services/workers-comp-form.service';

import { EMP_TAX_ID_KEY_LENGTH_BY_STATE } from 'app/workers-comp/employers/constants';
import { FEINPlaceholderValues } from 'app/workers-comp/shared/constants';

@Component({
  selector: 'app-wc-policy-info-form',
  templateUrl: './wc-policy-info-form.component.html',
})
export class WcPolicyInfoFormComponent {
  @Input() policyFormGroup: UntypedFormGroup;
  @Input() submitted: boolean;
  taxOrUnemploymentIdLength = EMP_TAX_ID_KEY_LENGTH_BY_STATE;
  isFEINPlaceholderValue = false;

  constructor(private formService: WcQuoteFormService) {}

  fieldInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.policyFormGroup, this.submitted);
  }

  getStateBureauInfoFormArray() {
    return this.formService.getStateBureauInfoFormArray();
  }

  empLiabilityOptions() {
    return this.formService.empLiabilityOptions;
  }

  checkFEINValue() {
    const FEINValue = (<UntypedFormControl>(
      this.formService.form.get('policyInfo.employerIdentificationNumberCrossSellExperiment')
    )).value;
    if (FEINValue) {
      this.isFEINPlaceholderValue = FEINPlaceholderValues.includes(FEINValue);
    }
  }
}
