<div class="adminz-only">
  <div>
    <strong>Current Form Status:</strong>
    <span class="bop-quote-form-status">{{ form.status }}</span>
    <br />
    <strong>Current Step:</strong>
    <pre>{{ currentStep | json }}</pre>
    <br />
    <strong>Submitted:</strong>
    <span>{{ submitted }}</span>
    <br />
    <strong>Errors:</strong>
    <pre>{{ allErrors() | json }}</pre>
    <br />
    <strong>TS Request ID</strong>
    <span class="js-bop-quote-request-id">{{ tsRequestId }}</span>
    <button class="js-debugger-show-payload button button__secondary" (click)="toggleShowStuff()">
      Show/Hide GW Payload
    </button>
  </div>

  <strong>BOP Policy Data (ng form)</strong>
  <pre class="js-bop-quote-form-data">
    {{ bopPolicyData() | json }}
  </pre>
  <div *ngIf="showStuff">
    <hr />
    <strong>Current Payload Data</strong>
    <pre class="js-bop-quote-payload-data">
      {{ bopPayload() | json }}
    </pre>
  </div>
</div>
