<ng-container *ngFor="let statementGroup of parsedCommissionDataByYear; last as isLast">
  <h2>{{ statementGroup[0].date.year() }}</h2>
  <table class="statement-list-table">
    <thead>
      <tr>
        <th>Commission type</th>
        <th>Statement date</th>
        <th>
          Commission Earned
          <span
            class="more-info-tip tool-tip tool-tip__large tool-tip__no-transform"
            data-tooltip="Gross commission earned in the transaction period. Does not factor in clawbacks incurred via cancellations or premium bearing endorsements."
          >
            <span class="icon icon-question"></span>
          </span>
        </th>
        <th>
          Commission Paid
          <span
            class="more-info-tip tool-tip tool-tip__large tool-tip__no-transform"
            data-tooltip="Net commission earned in the transaction period. Factors in clawbacks incurred via cancellations or premium bearing endorsements."
          >
            <span class="icon icon-question"></span>
          </span>
        </th>
        <th class="td__right"></th>
      </tr>
    </thead>
    <tbody class="tbody__striped">
      <tr *ngFor="let statement of statementGroup">
        <td>{{ statement.commissionType }}</td>
        <td>{{ statement.statementCreated | date: 'MM/dd/yyyy':'UTC' }}</td>
        <td>
          <span *ngIf="statement.statementAmount > 0">
            {{ statement.statementAmount | currency: 'USD':'symbol':'1.2-2' }}
          </span>
          <span *ngIf="statement.statementAmount === 0" class="gray-text">
            $0 (No commission earned)
          </span>
          <span *ngIf="statement.statementAmount < 0">
            {{ statement.statementAmount | currency: 'USD':'symbol':'1.2-2' }}
            <a
              class="statement-balance-explanation"
              (click)="openNegativeExplanationModal()"
              (keyup.enter)="openNegativeExplanationModal()"
              >Why is this negative?</a
            >
          </span>
        </td>
        <td>
          {{ statement.payoutAmount | currency: 'USD':'symbol':'1.2-2' }}
          <span
            *ngIf="statement.payoutAmount !== statement.statementAmount"
            class="warning-tool-tip tool-tip tool-tip__large"
            data-tooltip="The discrepancy between commission earned and commission paid is due to a clawback incurred by a cancellation or premium bearing endorsement."
          >
            <img alt="dialog-warning" src="/assets/img/dialog-warning.svg" />
          </span>
        </td>
        <td class="td__right">
          <a
            class="h4"
            (click)="openCommissionDetail(statement)"
            (keyup.enter)="openCommissionDetail(statement)"
            >Details</a
          >
          |
          <a
            (click)="downloadCommissionStatement(statement)"
            (keyup.enter)="downloadCommissionStatement(statement)"
            [ngClass]="{
              h4: true,
              'link-disabled': this.downloadingStatements.includes(statement)
            }"
          >
            Download
          </a>
          <app-loading-spinner
            *ngIf="this.downloadingStatements.includes(statement)"
            class="invoice-list-loading-spinner"
          >
          </app-loading-spinner>
        </td>
      </tr>
      <ng-container *ngIf="statementsLoading && isLast">
        <tr *ngFor="let i of [1, 2]">
          <td class="statement-list-month-cell">
            <div class="table-loading-cell"></div>
          </td>
          <td>
            <div class="table-loading-cell"></div>
          </td>
          <td>
            <div class="table-loading-cell"></div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>

<app-modal
  *ngIf="selectedStatement"
  [open]="!!selectedStatement"
  modalType="flyout"
  modalClass="app-modal-content__left-align"
  (handleModalClose)="closeCommissionDetail()"
>
  <h1>{{ selectedStatement.statementAmount | currency: 'USD':'symbol':'1.2-2' }}</h1>
  <dl>
    <dt>Lines of business included</dt>
    <dd>{{ TYPE_TO_SHORT_LOB[selectedStatement.commissionType] }}</dd>
  </dl>
  <dl>
    <dt>Statement date</dt>
    <dd>{{ selectedStatement.statementCreated | date: 'MM/dd/yyyy':'UTC' }}</dd>
  </dl>
  <hr />
  <table>
    <thead>
      <tr>
        <th>Line of business</th>
        <th class="td__right">Transaction period</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="selectedStatement.commissionType === 'Attune'">
        <tr *ngFor="let productName of ATTUNE_PRODUCTS">
          <td>{{ productName }}</td>
          <td class="td__right">{{ transactionPeriod(selectedStatement) }}</td>
        </tr>
      </ng-container>
      <ng-container *ngIf="selectedStatement.commissionType === 'Partner'">
        <tr *ngFor="let productName of PARTNER_PRODUCTS">
          <td>{{ productName }}</td>
          <td class="td__right">{{ transactionPeriod(selectedStatement) }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <hr />
  <dl class="dl__no-dotted-line">
    <dt><strong>Payout total</strong></dt>
    <dd>
      <strong>{{ selectedStatement.payoutAmount | currency: 'USD':'symbol':'1.2-2' }}</strong>
    </dd>
  </dl>

  <p class="mt-3">
    <strong>Note: </strong>
    <span *ngIf="selectedStatement.commissionType === 'Partner'">
      All commission owed for GL, PL, and Flood policies is paid out after bind. Should there be a
      cancellation or premium bearing endorsement, adjustments to commission owed will be reflected
      in subsequent commission statements. Commission owed for WC is paid once premium is paid by
      insured.
    </span>
    <span *ngIf="selectedStatement.commissionType === 'Attune'">
      All commission owed for BOP and Cyber policies is paid out after bind. Should there be a
      cancellation or premium bearing endorsement, adjustments to commission owed will be reflected
      in subsequent commission statements.
    </span>
  </p>
  <button
    class="button button__secondary button__full-width"
    (click)="downloadCommissionStatement(selectedStatement, 'xlsx')"
    [disabled]="this.downloadingStatements.includes(selectedStatement)"
  >
    Download Statement (.xlsx)
    <app-loading-spinner
      *ngIf="this.downloadingStatements.includes(selectedStatement)"
      class="invoice-list-loading-spinner"
    >
    </app-loading-spinner>
  </button>
  <button
    *ngIf="selectedStatement.commissionType === 'Partner'"
    class="button button__secondary button__full-width"
    (click)="downloadCommissionStatement(selectedStatement, 'pdf')"
    [disabled]="this.downloadingStatements.includes(selectedStatement)"
  >
    Download Statement (.pdf)
    <app-loading-spinner
      *ngIf="this.downloadingStatements.includes(selectedStatement)"
      class="invoice-list-loading-spinner"
    >
    </app-loading-spinner>
  </button>
</app-modal>
