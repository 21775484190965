import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, timer, of } from 'rxjs';
import { filter, first, flatMap } from 'rxjs/operators';
import { MOBILE_WIDTH_THRESHOLD, CUSTOMER_CARE_HOURS } from 'app/constants';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AmplitudePayloadType, INPUT_PAGE_ENTER } from 'app/core/constants/amplitude-helpers';
import { ZendeskService } from 'app/shared/services/zendesk.service';
import { attuneTheme, defaultTheme } from 'app/shared/services/zendesk-widget.service';
/**
 * default status indicator to true since:
 * (1) we drive traffic to this page during business hours
 * (2) there is a slight lag between loading done and online status updated
 */
const DEFAULT_STATUS = true;

@Component({
  selector: 'app-support-live-chat-page',
  templateUrl: './support-live-chat-page.component.html',
  styles: [],
})
export class SupportLiveChatPageComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();

  public tags = [] as string[];
  public contactInfo: { email: string; name: string } | null = null;
  public loading = true; // loading ZenDesk widget data
  public online = DEFAULT_STATUS; // chat agent is online for live chat
  customerCareHours = CUSTOMER_CARE_HOURS;

  constructor(
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService,
    private zendeskService: ZendeskService
  ) {}

  ngOnInit() {
    const { email, name, tag } = this.route.snapshot.queryParams as { [k: string]: unknown };
    if (typeof email === 'string' && typeof name === 'string') {
      this.contactInfo = { email, name };
    }
    if (typeof tag === 'string') {
      this.tags = [tag];
    }
    if (Array.isArray(tag)) {
      this.tags = tag.filter((x) => typeof x === 'string');
    }
    // The Live Chat page, i.e. /live-chat, is a standalone
    // page that isn't navigable from other AP pages.
    // Users will land on this page from a direct link and
    // the typical `page_enter` Amplitude event won't trigger.
    // Therefore, we'll manually fire the event on that page.
    this.amplitudeService.submitEvent({
      input: INPUT_PAGE_ENTER,
      type: AmplitudePayloadType.Page,
      value: `/live-chat`,
    });
    this.initChat();
    this.liveChatPoll();
  }

  ngOnDestroy() {
    if (window.innerWidth > MOBILE_WIDTH_THRESHOLD) {
      this.zendeskService.uncenterWidget();
    }
    this.zendeskService.setTheme(defaultTheme);
    this.zendeskService.widget.close();
    this.sub.unsubscribe();
  }

  // uses 'filter' and 'first' to execute subscription once when ready
  initChat() {
    this.sub.add(
      timer(0, 300)
        .pipe(
          flatMap(() => this.isZenDeskReady()),
          filter((ready) => ready),
          first()
        )
        .subscribe(() => {
          this.loading = false;
          this.zendeskService.skipHelpCenter();
          if (this.contactInfo) {
            this.zendeskService.widget.identify(this.contactInfo);
          }
          if (this.tags) {
            this.zendeskService.widget.addChatTags(this.tags);
          }
          if (window.innerWidth > MOBILE_WIDTH_THRESHOLD) {
            const supportWidth = 77; // rem
            this.zendeskService.centerWidget(supportWidth);
          }
          this.zendeskService.widget.open();
          this.zendeskService.setTheme(attuneTheme);
        })
    );
  }

  isZenDeskReady() {
    return of(this.zendeskService.ready());
  }

  liveChatPoll() {
    this.sub.add(
      timer(1500, 3000).subscribe(() => {
        this.online = this.checkChatAvailable();
      })
    );
  }

  checkChatAvailable() {
    if (this.loading) {
      return DEFAULT_STATUS;
    }
    return this.zendeskService.isChatLive();
  }
}
