import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { RewardsService } from '../../../shared/services/rewards.service';
import { BOOLEAN_FLAG_NAMES, FeatureFlagService } from '../../services/feature-flag.service';
import { OktaAuthService } from '../../services/oktaAuth.service';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.html',
})
export class LeftNavigationComponent implements OnInit, OnDestroy {
  public showWelcomeBack = environment.showWelcomeBack;
  public isNewUser = false;
  public isAiCheckerEnabled = false;
  leftNavCompletedRewardsTip = true;

  private sub = new Subscription();
  private balance = 0;

  constructor(
    public oktaAuth: OktaAuthService,
    private rewardsService: RewardsService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.rewardsService.getRewardsBalance().subscribe(({ balance }) => {
        this.balance = balance;
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.AI_CHECKER_FEATURE)
        .subscribe((aiCheckedFeatureFlag) => {
          this.isAiCheckerEnabled = !!aiCheckedFeatureFlag;
        })
    );
  }

  logout() {
    // reset Segment id/anonymousId/traits
    if ((<any>window).analytics) {
      (<any>window).analytics.reset();
    }
    this.oktaAuth.logout();
  }

  get rewardsBalance() {
    return this.balance;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
