import {
  CovTerm,
  Clause,
  ScheduledItemsHUSA,
  BuildingAnswersHUSA,
} from 'app/bop/guidewire/typings';
import * as _ from 'lodash';
import * as moment from 'moment';
import { US_DATE_MASK } from 'app/constants';

export function idsEqual(typeIdString1: string, typeIdString2: string): boolean {
  const typeId1: string = typeIdString1.split(':')[1];
  const typeId2: string = typeIdString2.split(':')[1];
  if (typeId1 && typeId2) {
    return parseInt(typeId1, 10) === parseInt(typeId2, 10);
  }

  return false;
}

export function existsCoverageClause(covClauses: Clause[], clauseCode: string): boolean {
  return (
    _.find(covClauses, (covClause: Clause) => covClause.Pattern.Code === clauseCode) !== undefined
  );
}

export function findCoverageTermsByClause(
  covClauses: Clause[],
  clauseCode: string
): CovTerm[] | null {
  if (covClauses) {
    const covClause: Clause | undefined = _.find(
      covClauses,
      (clause: Clause) => clause.Pattern.Code === clauseCode
    );

    if (covClause && covClause.CovTerms) {
      return covClause.CovTerms.Entry;
    }
  }

  return null;
}

export function findScheduledClausesByIdAndClause(
  covClauses: Clause[],
  clauseCode: string,
  covId: string
): Clause[] | null {
  if (covClauses) {
    const covClause: Clause | undefined = _.find(covClauses, (clause: Clause) => {
      return (
        clause.Pattern.Code === clauseCode && idsEqual(clause.OwningCoverable.TypeIDString, covId)
      );
    });

    if (covClause && covClause.ScheduledItems_HUSA) {
      const scheduledItems: ScheduledItemsHUSA | null =
        covClause.ScheduledItems_HUSA.Entry[0] || null;

      if (scheduledItems && scheduledItems.ScheduleClauses_HUSA) {
        return scheduledItems.ScheduleClauses_HUSA.Entry;
      }
    }
  }

  return null;
}

export function findScheduledItemsByClause(
  covClauses: Clause[],
  clauseCode: string
): ScheduledItemsHUSA[] | null {
  if (!covClauses) {
    return null;
  }
  const covClause: Clause | undefined = _.find(covClauses, (clause: Clause) => {
    return clause.Pattern.Code === clauseCode;
  });

  if (covClause && covClause.ScheduledItems_HUSA) {
    return covClause.ScheduledItems_HUSA.Entry;
  }
  return null;
}

export function findCoverageTermsByIdAndClause(
  covClauses: Clause[],
  clauseCode: string,
  covId: string
): CovTerm[] | null {
  if (covClauses) {
    const covClause: Clause | undefined = _.find(covClauses, (clause: Clause) => {
      return (
        clause.Pattern.Code === clauseCode && idsEqual(clause.OwningCoverable.TypeIDString, covId)
      );
    });

    if (covClause && covClause.CovTerms) {
      return covClause.CovTerms.Entry;
    }
  }

  return null;
}

export function findBuildingLimitFromTerms(
  covClauses: Clause[],
  clauseCode: string,
  termCode: string,
  covId: string
): string {
  const covTerms: CovTerm[] | null = findCoverageTermsByIdAndClause(covClauses, clauseCode, covId);

  if (covTerms) {
    const covTerm: CovTerm | undefined = _.find(
      covTerms,
      (term: CovTerm) => term.PatternCode === termCode
    );

    if (covTerm) {
      const limit = String(covTerm.CovTermValueForRating_HUSA || '0');
      return String(parseInt(limit, 10));
    }
  }

  return '0';
}

export function findCoverageTermLimitFromTerms(
  covTerms: CovTerm[],
  termCode: string
): number | string | null {
  if (covTerms) {
    const covTermLimit: CovTerm | undefined = _.find(
      covTerms,
      (term: CovTerm) => term.PatternCode === termCode
    );

    if (covTermLimit) {
      const termLimitString: string = String(covTermLimit.CovTermValueForRating_HUSA);
      const termLimitNumber: number = Number(termLimitString);

      if (Number.isNaN(termLimitNumber)) {
        if (termLimitString.includes('%')) {
          return termLimitString;
        }

        const termLimitDate: moment.Moment = moment(termLimitString, 'YYYY-MM-DD');
        return termLimitDate.isValid() ? termLimitDate.format('MM/DD/YYYY') : termLimitString;
      } else {
        return termLimitNumber;
      }
    }
  }

  return null;
}

export function findCoverageTermLimitFromClauses(
  covClauses: Clause[],
  clauseCode: string,
  termCode: string
): number | string | null {
  const covTerms: CovTerm[] | null = findCoverageTermsByClause(covClauses, clauseCode);

  if (covTerms) {
    return findCoverageTermLimitFromTerms(covTerms, termCode);
  }
  return null;
}

export function parsedFutureMomentOrNow(date: number | string): string {
  // We create a newDate with the user's timezone to allow them to set an effective date
  // of today's date in their timezone, not necessarily forcing them into today's date in UTC.
  let newDate: moment.Moment = moment();
  const parsedMoment = moment.utc(date);

  if (parsedMoment.isValid()) {
    if (parsedMoment.isAfter(newDate)) {
      newDate = parsedMoment;
    }
  } else {
    console.warn(`Invalid date: ${date}`);
  }
  const format: string = newDate.format(US_DATE_MASK);
  return format;
}

export function findBuildingAnswerByTerm(
  buildingAnswers: BuildingAnswersHUSA[],
  term: string
): boolean | null {
  const foundBuildingAnswer: BuildingAnswersHUSA | undefined = _.find(
    buildingAnswers,
    (buildingAnswer: BuildingAnswersHUSA) => buildingAnswer.QuestionCode === term
  );

  if (foundBuildingAnswer) {
    return (
      foundBuildingAnswer.AnswerValueAsString === true ||
      foundBuildingAnswer.AnswerValueAsString === 'true'
    );
  }

  return null;
}

export function getEmployersLiabilityStateInfixFromState(state: string) {
  if (state === 'NY' || state === 'MA') {
    return 'MA_NY';
  }
  if (state === 'OH' || state === 'TX') {
    return 'OH_TX';
  }
  return '';
}
