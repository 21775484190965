<app-modal [open]="showHelperModal" *ngIf="showHelperModal" (handleModalClose)="close()">
  <div class="neptune-modal">
    <img class="neptune-modal-flooded-town" src="/assets/img/neptune_flooded_town.png" alt="" />
    <div class="neptune-modal-product-logo-container">
      <img
        class="neptune-modal-product-logo"
        src="/assets/img/neptune_logo_horizontal_blue.svg"
        alt="Neptune Logo"
      />
    </div>
    <h2 class="h2">Neptune Flood + Attune</h2>
    <ul class="bulleted-list">
      <li>Available in every state but Alaska</li>
      <li>10% new / 8% renewal commission</li>
      <li>Quote & bind in minutes with no elevation certificate (EC) required</li>
      <li>Almost always priced lower than NFIP</li>
      <li>
        Better coverage - Up to $4m in building coverage and $25,000 in business interruption
        coverage
      </li>
      <li>
        <a href="{{ NEPTUNE_FLOOD_GUIDE }}" target="_blank" class="small-text"> Learn more </a>
      </li>
    </ul>
    <div class="modal-action-group">
      <div></div>
      <div class="nav-button-group">
        <a
          *ngIf="neptuneFloodQuoteResponse"
          class="button button__secondary"
          [ngClass]="{
            button__primary: !neptuneFloodQuoteResponse,
            button__secondary: neptuneFloodQuoteResponse
          }"
          href="{{ agentHubLinkFromQuoteLink(neptuneFloodQuoteResponse.details.link) }}"
          target="_blank"
          (click)="continueToNeptuneAgentHub(); close()"
        >
          See all quotes / policies
        </a>
        <a
          *ngIf="
            isQuotedQuoteResponse(neptuneFloodQuoteResponse) ||
            neptuneFloodQuoteResponse.details.link
          "
          href="{{ neptuneFloodQuoteResponse.details.link }}"
          target="_blank"
          (click)="continueToNeptuneQuote(); close()"
          class="button button__primary"
        >
          Go to quote
        </a>
      </div>
    </div>
  </div>
</app-modal>

<div class="account-policies-header" [ngClass]="{ 'account-policies-header__small': compact }">
  <div class="product-badge-group">
    <div
      class="product-badge product-badge__flood"
      [ngClass]="{ 'product-badge__small': compact }"
    ></div>
    <div class="neptune-quote-details-js">
      <h3 class="account-policies-header-h3">Flood Insurance</h3>

      <div class="flood-risk-container" *ngIf="neptuneFloodQuoteResponse?.details?.riskColorCode">
        <div
          [ngSwitch]="neptuneFloodQuoteResponse.details.riskColorCode"
          class="pill-tag pill-tag__flood-risk-{{
            neptuneFloodQuoteResponse.details.riskColorCode | lowercase
          }}"
        >
          <img
            *ngSwitchCase="'Yellow'"
            class="badge-list-img"
            src="/assets/img/flood_risk_yellow.svg"
            alt=""
          />
          <span
            *ngSwitchCase="'Yellow'"
            class="ml-1 flood-risk-text"
            style="text-transform: capitalize"
          >
            Flood Risk - <strong> low </strong>
          </span>

          <img
            *ngSwitchCase="'Orange'"
            class="badge-list-img"
            src="/assets/img/flood_risk_orange.svg"
            alt=""
          />
          <span
            *ngSwitchCase="'Orange'"
            class="ml-1 flood-risk-text"
            style="text-transform: capitalize"
          >
            Flood Risk - <strong> medium </strong>
          </span>

          <img
            *ngSwitchCase="'Red'"
            class="badge-list-img"
            src="/assets/img/flood_risk_red.svg"
            alt=""
          />
          <span
            *ngSwitchCase="'Red'"
            class="ml-1 flood-risk-text"
            style="text-transform: capitalize"
          >
            Flood Risk - <strong> high </strong>
          </span>
        </div>
      </div>

      <p class="p p__no-margin">
        <ng-container *ngIf="neptuneFloodQuoteResponse && neptuneFloodQuoteResponse.premium">
          Premium: {{ formatMoneyNoCents(neptuneFloodQuoteResponse.premium) }}
          <span class="gray-text">(beats NFIP 90% of the time)</span>
        </ng-container>
        <ng-container *ngIf="neptuneFloodQuoteResponse && !neptuneFloodQuoteResponse.premium">
          <span class="gray-text">Beats NFIP 90% of the time</span>
        </ng-container>
      </p>
    </div>
  </div>
  <a
    (click)="this.showHelperModal = true"
    (keyup.enter)="this.showHelperModal = true"
    class="small-text"
  >
    Visit Neptune
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style="margin-bottom: -0.5rem"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none"></rect>
      <line
        x1="64"
        y1="192"
        x2="192"
        y2="64"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
      ></line>
      <polyline
        points="88 64 192 64 192 168"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
      ></polyline>
    </svg>
  </a>
</div>
