<div class="team-settings-header-container">
  <h1 class="team-settings-header">Team Settings</h1>
  <div *ngIf="canInvite()" class="policy-pane-nav-buttons">
    <a
      [routerLink]="['/support/form/remove-access']"
      class="button button__secondary button__small button__no-margin"
    >
      Remove User
    </a>
    <button
      class="button button__small button__primary button__no-margin js-invite-user-button"
      (click)="inviteUser()"
    >
      Invite User
    </button>
  </div>
</div>

<p class="team-settings-informational gray-text" *ngIf="producerDetails">
  <span *ngIf="isCurrentUserAdmin()">
    Here you can manage your agency's users ({{ getAgencyName() }}). All commission they generate
    will go to your agency's producer code, {{ getProducerCode() }}. To onboard a subproducer under
    a different producer code, please submit a request
    <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new" target="_blank">here</a>.
  </span>
  <span *ngIf="!isCurrentUserAdmin()">
    Here is where admin users can manage users at your agency, with the producer code
    {{ getProducerCode() }}. To request changes to your own or another user's portal permissions,
    please reach out to any of your agency’s admin users listed below.
  </span>
</p>

<app-dialog-box
  *ngIf="disableManagement"
  type="primary"
  class="dialog-text__full-width dialog-text__no-margin js-user-management-disabled-banner"
>
  Due to the large number of accounts associated with this producer, certain user management
  features are disabled. For help managing user access levels, please reach out to our Customer Care
  team.
  <div class="dialog-text-links">
    <a class="dialog-text-link" href="https://attunehelp.zendesk.com/hc/en-us/requests/new"
      >Get in touch</a
    >
  </div>
</app-dialog-box>

<table class="user-list-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Email Address</th>
      <th>Role</th>
    </tr>
  </thead>
  <tbody class="tbody__striped">
    <ng-container *ngIf="loading">
      <tr *ngFor="let i of [1, 2, 3]" class="support-table-row">
        <td><div class="table-loading-cell"></div></td>
        <td><div class="table-loading-cell"></div></td>
        <td><div class="table-loading-cell"></div></td>
        <td class="td__small td__right"><div class="table-loading-cell"></div></td>
      </tr>
    </ng-container>
    <tr *ngFor="let userData of getUsers()">
      <td data-column-name="Name">
        {{ userData.user.firstName }} {{ userData.user.lastName }}
        <div class="pill-tag pill-tag__you" *ngIf="isCurrentUser(userData)">You</div>
      </td>
      <td data-column-name="Email Address">{{ userData.user.login }}</td>
      <td data-column-name="Role" *ngIf="!disableManagement">
        {{ getRole(userData) | titlecase }}
      </td>
      <td data-column-name="Role" *ngIf="disableManagement"></td>
      <td class="td__small td__right">
        <a
          (click)="editUser(userData)"
          (keyup.enter)="editUser(userData)"
          *ngIf="canEdit()"
          class="button button__no-margin button__transparent button__small js-user-edit"
          >Edit</a
        >
      </td>
    </tr>
    <ng-container *ngIf="showDeactivatedUsers">
      <tr *ngFor="let deactivatedUser of getDeactivatedUsers()">
        <td data-column-name="Name" class="gray-text">
          {{ deactivatedUser.profile.firstName }} {{ deactivatedUser.profile.lastName }}
        </td>
        <td data-column-name="Email Address" class="gray-text">
          {{ deactivatedUser.profile.login }}
        </td>
        <td data-column-name="Role"></td>
        <td class="td__small td__right"></td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div class="user-list-deactivated-toggle" *ngIf="!loading">
  <a
    (click)="toggleDeactivatedUsers()"
    (keyup.enter)="toggleDeactivatedUsers()"
    *ngIf="!showDeactivatedUsers"
    class="js-user-deactivated-toggle"
  >
    View all deactivated accounts
    <span class="icon-arrow-down dropdown-icon"></span>
  </a>
  <a
    (click)="toggleDeactivatedUsers()"
    (keyup.enter)="toggleDeactivatedUsers()"
    *ngIf="showDeactivatedUsers"
    class="js-user-deactivated-toggle"
  >
    Hide all deactivated accounts
    <span class="icon-arrow-up dropdown-icon"></span>
  </a>
</div>

<h3 class="pending-invitations-header">Pending Invitations</h3>

<table class="pending-invitations-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Email Address</th>
    </tr>
  </thead>
  <tbody class="tbody__striped">
    <ng-container *ngIf="loading">
      <tr *ngFor="let i of [1, 2]" class="support-table-row">
        <td><div class="table-loading-cell"></div></td>
        <td><div class="table-loading-cell"></div></td>
        <td class="td__right"><div class="table-loading-cell"></div></td>
      </tr>
    </ng-container>
    <tr *ngFor="let user of getInvitations(); let i = index">
      <td data-column-name="Name">{{ user.profile.firstName }} {{ user.profile.lastName }}</td>
      <td data-column-name="Email">{{ user.profile.login }}</td>
    </tr>
  </tbody>
</table>

<app-modal
  [open]="showEditUserModal"
  (handleModalClose)="closeEditUserModal()"
  modalClass="app-modal-content__edit-user"
>
  <div class="edit-user-modal">
    <h2>Edit user role</h2>
    <span class="edit-user-name-header">
      {{ editingUser && editingUser.user.firstName }}
      {{ editingUser && editingUser.user.lastName }}
    </span>
    <p>{{ editingUser && editingUser.user.email }}</p>

    <form (ngSubmit)="updateUser()" [formGroup]="editUserForm">
      <app-form-field-radio-traditional
        [form]="editUserForm"
        [submitted]="editUserSubmitted"
        inputId="edit-role"
        labelText="Role"
        nameOfFormControl="role"
        [options]="roleOptions"
        showErrorText="true"
        [optionDescriptions]="roleLabels"
      >
      </app-form-field-radio-traditional>

      <div class="nav-button-group">
        <button (click)="closeEditUserModal()" class="button button__secondary">Cancel</button>
        <button type="submit" class="button button__primary">
          <div class="button-loader" *ngIf="updatingUser">
            <svg class="circle-spinner">
              <circle
                cx="11"
                cy="11"
                r="9"
                stroke-width="2"
                stroke-dasharray="0,1000"
                d="M5 40 l215 0"
                fill="transparent"
              />
            </svg>
          </div>
          Save Changes
        </button>
      </div>
    </form>
  </div>
</app-modal>

<app-modal
  [open]="showForbiddenChangeModal"
  (handleModalClose)="closeForbiddenChangeModal()"
  modalClass="app-modal-content__edit-user"
>
  <div class="forbidden-edit-user-modal">
    <h2>Edit user role</h2>
    <span class="edit-user-name-header">
      {{ editingUser && editingUser.user.firstName }}
      {{ editingUser && editingUser.user.lastName }}
      <div class="pill-tag pill-tag__you">You</div>
    </span>
    <p>{{ editingUser && editingUser.user.email }}</p>

    <form (ngSubmit)="closeForbiddenChangeModal()" [formGroup]="editUserForm">
      <app-dialog-box header="Admin role cannot be changed" type="danger">
        To edit this user's role, please assign someone else as an Admin before proceeding.
      </app-dialog-box>

      <div class="nav-button-group">
        <button (click)="closeEditUserModal()" class="button button__secondary">Close</button>
      </div>
    </form>
  </div>
</app-modal>

<app-modal
  [open]="showInviteUserModal"
  (handleModalClose)="closeInviteUserModal()"
  modalClass="app-modal-content__invite-user"
>
  <div class="invite-user-modal">
    <h2>Invite user</h2>

    <form (ngSubmit)="submitInvite()" [formGroup]="inviteUserForm">
      <app-form-field-checkbox
        [form]="inviteUserForm"
        [submitted]="inviteUserSubmitted"
        inputId="invite-agency-confirmation"
        nameOfFormControl="agencyConfirmation"
        [labelText]="getInviteConfirmationText()"
      >
      </app-form-field-checkbox>
      <div class="form-split">
        <app-form-field-text
          inputId="firstName"
          [form]="inviteUserForm"
          [submitted]="inviteUserSubmitted"
          labelText="First Name"
          nameOfFormControl="firstName"
        >
        </app-form-field-text>
        <app-form-field-text
          inputId="lastName"
          [form]="inviteUserForm"
          [submitted]="inviteUserSubmitted"
          labelText="Last Name"
          nameOfFormControl="lastName"
        >
        </app-form-field-text>
      </div>
      <app-form-field-text
        [form]="inviteUserForm"
        [submitted]="inviteUserSubmitted"
        showErrorText="true"
        inputId="emailAddress"
        labelText="Email address"
        nameOfFormControl="emailAddress"
        appRemoveWhitespaceMask
      >
      </app-form-field-text>
      <app-form-field-radio-traditional
        [form]="inviteUserForm"
        [submitted]="inviteUserSubmitted"
        showErrorText="true"
        inputId="invite-role"
        nameOfFormControl="role"
        [options]="roleOptions"
        [optionDescriptions]="roleLabels"
      >
      </app-form-field-radio-traditional>

      <app-dialog-box *ngIf="hasInviteNameCollision" header="User exists" type="danger">
        There is already a user or pending invite associated with this email address.
      </app-dialog-box>

      <div class="nav-button-group">
        <button (click)="closeInviteUserModal()" class="button button__secondary">Cancel</button>
        <button type="submit" class="button button__primary js-submit-invite">
          <div class="button-loader" *ngIf="inviteUserSending">
            <svg class="circle-spinner">
              <circle
                cx="11"
                cy="11"
                r="9"
                stroke-width="2"
                stroke-dasharray="0,1000"
                d="M5 40 l215 0"
                fill="transparent"
              />
            </svg>
          </div>
          Send
        </button>
      </div>

      <small class="gray-text under-nav-button-disclaimer">
        By clicking “Send,” I agree that I am granting MyAttune access only to an employee who is
        either properly licensed as an individual insurance producer or under the direct supervision
        of such licensed employees and who have proper authority to enter into insurance
        transactions on my behalf. Any new user agrees to abide by the MyAttune Terms of Use, which
        are available
        <a href="https://www.attuneinsurance.com/legal/terms-of-use/" target="_blank">here</a>.
      </small>
    </form>
  </div>
</app-modal>
