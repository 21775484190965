<section>
  <h1>Welcome to Attune 👋</h1>
  <p class="p__large-text">
    We’re excited to have you join us. We’d love to get to know you a little better so we can tailor
    your experience with Attune.
  </p>

  <app-form-field-radio
    inputId="onboarding-user-role"
    questionText="Will you be quoting new business or servicing policies?"
    nameOfFormControl="role"
    cssClass="form-field__large"
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    [form]="form"
    [options]="userRoleOptions"
    [submitted]="hasSubmitted()"
  >
  </app-form-field-radio>

  <app-form-field-radio
    inputId="onboarding-sample-quote-optin"
    questionText="Do you have anything to quote now?"
    nameOfFormControl="sampleQuoteOptIn"
    cssClass="form-field__large"
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    [form]="form"
    [options]="sampleQuoteOptInOptions"
    [submitted]="hasSubmitted()"
  >
  </app-form-field-radio>
</section>
