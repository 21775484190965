import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AmplitudePayloadType, INPUT_PAGE_ENTER } from 'app/core/constants/amplitude-helpers';
import { SubmitStatus } from 'app/features/support/components/support-help-center-form/support-help-center-form.component';

import { UNAUTHENTICATED_ROUTE } from '../../models/support-help-center-constants';

@Component({
  selector: 'app-support-help-center-page',
  templateUrl: './support-help-center-page.component.html',
  styles: [],
})
export class SupportHelpCenterPageComponent implements OnInit {
  @Input() authenticated = false;
  ticketResponses: { status: SubmitStatus; email: string }[] = [];

  constructor(private amplitudeService: AmplitudeService, private router: Router) {}

  ngOnInit() {
    const currentPath = this.router.routerState.snapshot.url;
    // The unauthenticated Help Center page, i.e. /help-center, is a standalone
    // page that isn't navigable from other AP pages except for footer & invoice
    // links. That is, most users will land on this page from a direct link and
    // the typical `page_enter` Amplitude event won't trigger. Therefore, we'll
    // manually fire the event on that page.
    const isStandAlonePage = RegExp(`\/${UNAUTHENTICATED_ROUTE}`).test(currentPath);
    if (isStandAlonePage) {
      this.amplitudeService.submitEvent({
        input: INPUT_PAGE_ENTER,
        type: AmplitudePayloadType.Page,
        value: `/${UNAUTHENTICATED_ROUTE}`,
      });
    }
  }

  handleTicketResponse(ticketResponse: { status: SubmitStatus; email: string }) {
    this.ticketResponses.unshift(ticketResponse);
  }
}
