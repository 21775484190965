import { Injectable, OnDestroy } from '@angular/core';
import { assign, isEmpty, isEqual, keys } from 'lodash';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { getFormGroup, getControl } from 'app/shared/helpers/form-helpers';
import {
  LmCpspComplexValueEvaluator,
  LmCpspQuestion,
  LmCpspFormStepPath,
  LmCpspNestedControls,
  LmCpspComplexEvaluator,
} from '../models/lm-cpsp-typings';
import { LM_CPSP_COMPLEX_VALUE_EVALUATORS, cpspControlPath } from '../models/lm-cpsp-constants';
import { LibertyMutualBaseQuoteFormService } from './liberty-mutual-base-quote-form-service';
import { LM_CPSP_QUOTE_FLOW, getCpspNode, getCpspNodeConfig } from '../models/lm-cpsp-form-steps';
import {
  LM_CPSP_DEPENDENCIES,
  LM_CPSP_NESTED_CONTROL_DEPENDENCIES,
  LM_CPSP_COMPLEX_DEPENDENCIES,
} from '../models/lm-cpsp-dependencies';
import { lmCpspLossGroupNodes } from '../models/lm-cpsp-questions';
import { LM_CPSP_HAPPY_PATH_FORM_DATA } from '../models/lm-cpsp-happy-path-form-data';

@Injectable({
  providedIn: 'root',
})
export class LibertyMutualCpspQuoteFormService
  extends LibertyMutualBaseQuoteFormService<
    LmCpspFormStepPath,
    LmCpspQuestion,
    LmCpspNestedControls,
    LmCpspComplexEvaluator
  >
  implements OnDestroy
{
  happyPathFormData = LM_CPSP_HAPPY_PATH_FORM_DATA;

  // Form steps
  formSteps = LM_CPSP_QUOTE_FLOW;

  // Dependencies dictionaries
  simpleDependencies = LM_CPSP_DEPENDENCIES;
  complexDependencies = LM_CPSP_COMPLEX_DEPENDENCIES;
  nestedControlDependencies = LM_CPSP_NESTED_CONTROL_DEPENDENCIES;

  // Names of key controls
  CLASS_CODE_CONTROL_NAME = LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE;
  PRIMARY_RISK_STATE_CONTROL_NAME = LmCpspQuestion.PRIMARY_RISK_STATE;
  LOSSES_CONTROL_NAME = LmCpspQuestion.LOSSES;

  // Functions for retrieving from data structures
  getControlPathFunc = cpspControlPath;
  getNodeFunc = getCpspNode;
  getNodeConfigFunc = getCpspNodeConfig;
  lossGroupNodesFunc = lmCpspLossGroupNodes;

  formDependenciesInit(currentFormPath: LmCpspFormStepPath) {
    const formGroup = getFormGroup(this.form, currentFormPath);
    const stepHasControls = formGroup && !isEmpty(formGroup.controls);
    const stepDependenciesAreSet = this.setDependencies.has(currentFormPath);

    if (stepHasControls && !stepDependenciesAreSet) {
      this.setDependenciesForFormGroup(formGroup, this.simpleDependencies);
      this.setValueDependenciesForFormGroup(formGroup);
      this.setDependencies.add(currentFormPath);
    }
  }

  private setValueDependenciesForFormGroup(formGroup: UntypedFormGroup) {
    keys(formGroup.controls).forEach((controlName: LmCpspQuestion) => {
      const control: UntypedFormControl = getControl(formGroup, controlName);

      const nodeConfig = this.getNodeConfigFunc(controlName);
      if (nodeConfig.valueType === 'DYNAMIC_FUNC') {
        const valueDependencyEvaluator = nodeConfig.valueDependencyFunc;
        this.addValueDependencies({ control, controlName, valueDependencyEvaluator });
      }
    });
  }

  private addValueDependencies({
    control,
    controlName,
    valueDependencyEvaluator,
  }: {
    control: UntypedFormControl;
    controlName: LmCpspQuestion;
    valueDependencyEvaluator: LmCpspComplexValueEvaluator;
  }) {
    const evalFunc = LM_CPSP_COMPLEX_VALUE_EVALUATORS[valueDependencyEvaluator];
    const depSub = evalFunc(this.form);
    const nodeInTree = this.formTree.find((node) => {
      return node.nameOfFormControl === controlName;
    });
    if (depSub) {
      const { dependsOn, callback } = depSub;
      this.sub.add(
        dependsOn
          .pipe(
            map((value) => {
              return callback(value);
            }),
            distinctUntilChanged((prev, cur) => {
              return isEqual(prev, cur);
            }),
            tap((dynamicVals) => {
              // If the options change, erase the user's previous answer.
              control.patchValue(null);
              assign(nodeInTree, dynamicVals);
            })
          )
          .subscribe()
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
