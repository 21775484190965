import { Directive } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

@Directive({
  selector: '[appRemoveWhitespaceMask]',
})
export class RemoveWhitespaceMaskDirective extends BaseMaskDirective {
  getMask(value: string) {
    if (value == null) {
      return '';
    }

    return value.replace(/\s+/g, '');
  }
}
