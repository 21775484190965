import { Injectable, isDevMode } from '@angular/core';

@Injectable()
export class DevModeOnly {
  constructor() {}

  canActivate() {
    return isDevMode();
  }
}
