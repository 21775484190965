import { CoalitionCyberFormStepPath, CoalitionCyberQuestion } from './cyber-typings.model';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

export const QUICK_CYBER_QUOTE_FLOW: (RouteFormStep & {
  questions: (CoalitionCyberQuestion | CoalitionCyberQuestion[])[];
})[] = [
  {
    args: {},
    displayName: 'Policy details',
    slug: 'quick-quote',
    parent: 'quick-quote',
    formPath: CoalitionCyberFormStepPath.QUICK_QUOTE,
    questions: [
      CoalitionCyberQuestion.EFFECTIVE_DATE,
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
      CoalitionCyberQuestion.COMPANY_REVENUE,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      [
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
        CoalitionCyberQuestion.PRIOR_CLAIMS,
        CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
      ],
      CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS,
      [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS_EXPLANATION],
      CoalitionCyberQuestion.ENCRYPTS_DATA,
      CoalitionCyberQuestion.PII_PHI,
      [
        CoalitionCyberQuestion.PII_PHI_COUNT,
        CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION,
        CoalitionCyberQuestion.CC_CUSTOMER_COUNT,
        CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION,
      ],
      CoalitionCyberQuestion.CONTENT_COMPLAINTS,
      CoalitionCyberQuestion.REVIEWS_CONTENT,
      CoalitionCyberQuestion.BACKUP_AND_RESTORE,
      CoalitionCyberQuestion.DUAL_CONTROL_5K,
      [CoalitionCyberQuestion.DUAL_CONTROL],
      CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
      CoalitionCyberQuestion.MFA_EMAIL,
      CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
      CoalitionCyberQuestion.MFA_VPN,
      CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
      CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
      CoalitionCyberQuestion.HAS_TECH_EO,
      //
    ],
  },
];
export const CYBER_QUOTE_FLOW: (RouteFormStep & {
  questions: (CoalitionCyberQuestion | CoalitionCyberQuestion[])[];
})[] = [
  {
    args: {},
    displayName: 'Policy info',
    slug: 'policy-info',
    parent: 'policy-info',
    formPath: CoalitionCyberFormStepPath.POLICY_INFO,
    questions: [
      CoalitionCyberQuestion.EFFECTIVE_DATE,
      CoalitionCyberQuestion.ADDRESS,
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
      CoalitionCyberQuestion.HAS_TECH_EO,
      CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY,
      CoalitionCyberQuestion.DOMAIN_NAMES,
      CoalitionCyberQuestion.COMPANY_REVENUE,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
      CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT,
    ],
  },
  {
    args: {},
    displayName: 'Underwriting',
    slug: 'underwriting',
    parent: 'underwriting',
    formPath: CoalitionCyberFormStepPath.UNDERWRITING,
    questions: [
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      [
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
        CoalitionCyberQuestion.PRIOR_CLAIMS,
        CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
      ],
      CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS,
      [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS_EXPLANATION],
      CoalitionCyberQuestion.ENCRYPTS_DATA,
      CoalitionCyberQuestion.PII_PHI,
      [
        CoalitionCyberQuestion.PII_PHI_COUNT,
        CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION,
        CoalitionCyberQuestion.CC_CUSTOMER_COUNT,
        CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION,
      ],
      CoalitionCyberQuestion.CONTENT_COMPLAINTS,
      CoalitionCyberQuestion.REVIEWS_CONTENT,
      CoalitionCyberQuestion.BACKUP_AND_RESTORE,
      CoalitionCyberQuestion.DUAL_CONTROL_5K,
      [CoalitionCyberQuestion.DUAL_CONTROL],
      CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
      CoalitionCyberQuestion.MFA_EMAIL,
      CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
      CoalitionCyberQuestion.MFA_VPN,
      CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
      CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
    ],
  },
  {
    args: {},
    displayName: 'Coverage',
    slug: 'coverage',
    parent: 'coverage',
    formPath: CoalitionCyberFormStepPath.COVERAGE,
    questions: [
      CoalitionCyberQuestion.BUNDLE,
      CoalitionCyberQuestion.THIRD_PARTY_COVERAGES,
      CoalitionCyberQuestion.FIRST_PARTY_COVERAGES,
      [
        CoalitionCyberQuestion.TECH_EO_DISPUTE,
        CoalitionCyberQuestion.TECH_EO_DISPUTE_EXPLANATION,
        CoalitionCyberQuestion.IS_MSP_OR_BAD_INDUSTRY,
        CoalitionCyberQuestion.PROFESSIONAL_SERVICES,
        CoalitionCyberQuestion.SERVICES_BY_CONTRACT,
        CoalitionCyberQuestion.MITIGATING_CLAUSES,
      ],
    ],
  },
];
