<h1>Amplitude Test Component</h1>
<h2>Button</h2>
<button
  id="button-test"
  class="button button__primary"
  data-amplitude-input-name="button-input-name"
>
  Submit
</button>

<h2>Link</h2>
<a id="link-test" class="mb-1" href="javascript:void(0);">Download our guidelines</a>

<h2>Input</h2>
<input id="input-test" type="text" class="form-field" placeholder="Business name" />

<h2>Select</h2>
<select id="select-test">
  <option disabled>Display per page</option>
  <option>Option 1</option>
  <option>Option 2</option>
  <option>Option 3</option>
</select>

<h2>Tool tip</h2>
<div id="tooltip-text">
  Payment plan
  <div
    id="tooltip-test"
    class="tool-tip tool-tip__large"
    data-tooltip="This is the current payment method on file. To unenroll, please contact your broker, so they can unenroll you in our system."
  >
    <span class="icon icon-question"></span>
  </div>
</div>

<h2>TextFieldComponent</h2>
<app-form-field-text
  inputId="textFieldTest"
  labelText="Effective date"
  nameOfFormControl="effectiveDate"
  [form]="textFieldForm"
  [submitted]="false"
  appDateMask
></app-form-field-text>

<h2>SliderFieldComponent</h2>
<app-form-field-slider
  inputId="sliderFieldTest"
  min="0"
  max="100"
  step="20"
  labelText="Damage to premises rented to you"
  nameOfFormControl="damageToPremises"
  [form]="sliderFieldForm"
></app-form-field-slider>

<h2>SelectFieldComponent</h2>
<app-form-field-select
  inputId="selectFieldTest"
  nameOfFormControl="baseState"
  labelText="Base state"
  [form]="selectFieldForm"
  [availableOptions]="selectFieldOptions"
  [submitted]="false"
></app-form-field-select>

<h2>RadioQuestionComponent</h2>
<app-form-field-radio
  inputId="radioQuestionTest"
  questionText="Do you want to do a sample quote?"
  nameOfFormControl="optIn"
  [form]="radioQuestionForm"
  [options]="radioQuestionOptions"
></app-form-field-radio>

<h2>CheckboxComponent</h2>
<app-form-field-checkbox
  inputId="checkboxTest"
  nameOfFormControl="optIn"
  labelText="Receive text messages about your policy"
  [form]="checkboxForm"
  [submitted]="false"
></app-form-field-checkbox>

<h2>DropdownSearchComponent</h2>
<app-form-field-dropdown-search
  inputId="searchTest"
  labelText="Description of business"
  [submitted]="false"
  [queryMethod]="dropdownQueryMethod"
  [formatter]="dropdownFormatter"
  [targetFormControl]="dropdownFormControl"
  [showToolTip]="false"
></app-form-field-dropdown-search>
