<div class="app-page" [ngClass]="{ 'app-page__form': isNewAccountComponent }">
  <app-sample-quote-modal
    *ngIf="displaySampleQuoteModal"
    (closeModal)="handleSampleQuoteModalClose($event)"
  ></app-sample-quote-modal>
  <app-neptune-flood-helper-modal
    *ngIf="neptuneFloodQuote || showNeptuneFloodError"
    [neptuneFloodQuote]="neptuneFloodQuote"
    [showHideNeptuneFloodPrompt]="false"
    (handleModalClose)="navigateToAccounts()"
  ></app-neptune-flood-helper-modal>

  <!-- Dummy breadcrumbs container to make the Create Account to Create Quote flow look seamless -->
  <div *ngIf="isNewAccountComponent" class="app-page-sidebar app-page-sidebar__account">
    <a
      *ngIf="!isSampleAccount"
      class="
        button button__nav button__nav-sidebar-quote button__no-margin button__nav-white-bg
        js-account-cancel
      "
      (click)="goBack()"
      (keyup.enter)="goBack()"
    >
      <span class="icon-arrow-left button-icon"></span>
      Cancel
    </a>
    <header class="quote-sidebar">
      <h3 class="h3">New account</h3>
      <ul class="sidebar-breadcrumbs">
        <li class="sidebar-breadcrumb sidebar-breadcrumb__active">Account</li>
      </ul>
    </header>
  </div>

  <div [ngClass]="{ 'app-page-form-scrollable-wrapper': isNewAccountComponent }">
    <main
      class="account-page"
      [ngClass]="{
        'app-page-form': isNewAccountComponent,
        'account-page-no-sidebar': !isNewAccountComponent
      }"
      *ngIf="isFormReadyToMount"
    >
      <button
        class="button button__nav js-account-new-close-modal"
        (click)="goBack()"
        *ngIf="!isNewAccountComponent"
      >
        <span class="icon-arrow-left button-icon" role="presentation"></span>
        Back
      </button>

      <h1>{{ isNewAccountComponent ? 'New insured account' : 'Edit account' }}</h1>

      <form (ngSubmit)="isNewAccountComponent ? submitForm() : editAccount()" novalidate>
        <div>
          <app-form-field-text
            inputId="companyName"
            [form]="form"
            [submitted]="submitted"
            labelText="Company name"
            nameOfFormControl="companyName"
            maxLength="60"
            (focusOut)="checkForBogusQuote()"
          >
          </app-form-field-text>

          <app-form-field-text
            inputId="doingBusinessAs"
            [form]="form"
            [submitted]="submitted"
            labelText="DBA or Operating Name"
            nameOfFormControl="doingBusinessAs"
            maxLength="60"
            (focusOut)="checkForBogusDbaName()"
            attr.aria-describedby="{{ isBogusDbaName ? 'add-dba-name-tip' : '' }}"
          >
            <app-education-pane
              position="right-middle"
              *ngIf="isBogusDbaName"
              elementId="dba-name-tip"
              class="js-dba-name-tip"
            >
              We require DBA if the business operates under any other names.
            </app-education-pane>
          </app-form-field-text>
        </div>

        <div *ngIf="isBogusQuote" class="dialog-text dialog-text__error dialog-text__inline">
          <strong>Is this a real insured account?</strong> We only allow quoting for real businesses
          so please, no testing. Contact us if you'd like to schedule a demo or have questions.
        </div>

        <div
          *ngIf="matchedDuplicate"
          class="
            dialog-text dialog-text__warning dialog-text__full-width dialog-text__inline
            js-dup-dialog
          "
        >
          <strong>Is this a duplicate?</strong> We found an account with a similar name:
          <a [routerLink]="['/accounts', matchedDuplicate.id]">{{ matchedDuplicate.companyName }}</a
          >.
        </div>

        <app-form-field-address
          labelText="Business mailing address"
          prefix="business"
          [form]="form"
          [submitted]="submitted"
          (focusOut)="checkForBogusDbaName()"
        >
        </app-form-field-address>

        <app-form-field-textarea
          inputId="description"
          [form]="form"
          [submitted]="submitted"
          labelText="Detailed description of operations"
          specifierText="(optional)"
          nameOfFormControl="description"
          maxLength="170"
          rows="2"
        >
        </app-form-field-textarea>

        <app-dialog-box
          *ngIf="showLiveChatNudge"
          class="dialog-text__margin-bottom"
          fullWidth="true"
          id="live-chat-container"
          type="primary"
        >
          <span>
            Live chat!
            <a
              id="live-chat"
              (click)="openChat()"
              href="javascript:void(0);"
              data-amplitude-input-name="new-account-live-chat"
              >Click here</a
            >
            to get help with eligibility and classification.
          </span>
        </app-dialog-box>

        <ng-container>
          <div class="helper-card-container">
            <div class="helper-card" *ngIf="!isSampleAccount && getCurrentState() !== 'CA'">
              <div class="pill-tag pill-tag__main">Note</div>
              <p>
                <strong>Looking for Lessor's Risk? </strong>
                <a (click)="addLROClass()" href="javascript:void(0);">Click here</a>
                to auto-select the <strong>LRO</strong> classcode.
              </p>
            </div>
            <div class="helper-card" *ngIf="!isSampleAccount && getCurrentState() === 'CA'">
              <div class="pill-tag pill-tag__main">Note</div>
              <p><strong>Lessor's Risk</strong> is not available in California.</p>
            </div>
            <app-form-field-dropdown-search
              inputId="naics-code"
              labelText="Description of business"
              typeaheadPlaceholderText="Search descriptions"
              [searchExpanded]="naicsSearchExpanded"
              [submitted]="submitted"
              [queryMethod]="getNaicsCodes"
              [formatter]="naicsFormatter"
              [targetFormControl]="form.get('naicsCode')"
              [readonly]="isSampleAccount"
            >
            </app-form-field-dropdown-search>
          </div>
        </ng-container>

        <div class="helper-card-container" *ngIf="!isNewAccountComponent">
          <div class="form-split">
            <app-form-field-text
              inputId="phoneNumber"
              [form]="form"
              [submitted]="submitted"
              labelText="Insured phone number"
              nameOfFormControl="phoneNumber"
              appPhoneMask
            >
            </app-form-field-text>

            <app-form-field-text
              inputId="emailAddress"
              [form]="form"
              [submitted]="submitted"
              labelText="Insured email address"
              nameOfFormControl="emailAddress"
              appRemoveWhitespaceMask
            >
            </app-form-field-text>
          </div>

          <div class="helper-card">
            <div class="pill-tag pill-tag__main">Note</div>
            <p>Invoices will also be emailed to the binding broker.</p>
          </div>

          <app-form-field-text
            inputId="website"
            [form]="form"
            [submitted]="submitted"
            labelText="Website"
            nameOfFormControl="website"
            inputType="url"
          >
          </app-form-field-text>
        </div>
        <div class="form-footer">
          <div
            class="dialog-text dialog-text__neutral dialog-text__block"
            *ngIf="!form.get('naicsCode').value"
          >
            Please select a description from the list to continue.
          </div>
          <div
            class="nav-quote-buttons-container"
            *ngIf="isNewAccountComponent && form.get('naicsCode').value"
          >
            <div
              class="nav-quote-button"
              *ngIf="
                showAdpOption() &&
                !isSampleAccount &&
                !isLoadingEligibility &&
                !productAvailabilitiesLoading
              "
            >
              <button
                type="submit"
                class="button button__small button__no-margin button__primary new-account-button"
                [disabled]="loading || loadingNeptuneFlood"
                data-amplitude-input-name="new-account-no-quote"
              >
                Create Account
              </button>
            </div>

            <!-- Attune WC product -->
            <div *ngIf="showAttuneWcProduct()" class="nav-quote-button js-attune-wc-quote-button">
              <div class="nav-quote-info">
                <div class="product-badge product-badge__wc">
                  <div
                    class="product-badge-carrier-icon"
                    [ngClass]="
                      isAdpWcUser
                        ? 'product-badge-carrier-icon__everpeak'
                        : 'product-badge-carrier-icon__attune'
                    "
                  ></div>
                </div>
                <div>
                  <span class="nav-quote-button-label">Workers' Compensation</span>
                  <div *ngIf="isWcPreferred()" class="preference-tag-container">
                    <div class="pill-tag pill-tag__preferred">
                      <ng-container *ngIf="showWcStronglyPreferred && wcPreferenceLevel === '1'">
                        <img
                          class="badge-list-img"
                          src="/assets/img/attune_strongly_preferred_heart.svg"
                          alt=""
                        />
                        <span class="ml-1 preferred-text js-wc-preferred-text">
                          <strong>strongly preferred</strong>
                        </span>
                      </ng-container>
                      <ng-container *ngIf="!showWcStronglyPreferred || wcPreferenceLevel === '2'">
                        <img
                          class="badge-list-img"
                          src="/assets/img/attune_preferred_green.svg"
                          alt=""
                        />
                        <span class="ml-1 preferred-text js-wc-preferred-text">
                          <strong>preferred</strong>
                        </span>
                      </ng-container>
                    </div>
                  </div>
                  <p>
                    {{ getAttuneWCBrandName(isAdpWcUser).brand }}&nbsp;
                    <span class="gray-text">{{ getAttuneWCBrandName(isAdpWcUser).details }}</span
                    >&nbsp;&nbsp;<a href="{{ attuneWcGuidelinesLink }}" target="_blank"
                      >Learn more</a
                    >
                  </p>
                </div>
              </div>
              <span *ngIf="isLoadingEligibility" class="nav-quote-button-loading">
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
              <div class="new-tag-container" *ngIf="!isLoadingEligibility">
                <button
                  type="submit"
                  *ngIf="!isSampleAccount && isAttuneWcEligible"
                  [disabled]="loading || loadingNeptuneFlood"
                  (click)="createProduct('attune', 'wc')"
                  class="button button__small button__no-margin button__primary"
                  data-amplitude-input-name="new-account-get-quote-attune-wc"
                >
                  Get a Quote
                </button>
                <span *ngIf="!isAttuneWcEligible" class="nav-quote-button-disabled"
                  >Ineligible</span
                >
                <button
                  type="button"
                  *ngIf="isSampleAccount && isAttuneWcEligible"
                  (click)="displayProductRestrictionNote()"
                  class="button button__small button__no-margin button__primary"
                >
                  Get a Quote
                </button>
              </div>
            </div>

            <!-- BOP product -->
            <div
              class="nav-quote-button js-bop-quote-button"
              *ngIf="
                displayProductCard({ product: 'bop', pasSource: 'attune_gw' }) && !isFortegra()
              "
            >
              <div class="nav-quote-info">
                <div
                  ngClass="product-badge {{
                    isBopV2() || isMeToo() ? 'product-badge__bop-plus' : 'product-badge__bop'
                  }}"
                >
                  <div class="product-badge-carrier-icon product-badge-carrier-icon__attune"></div>
                </div>
                <div>
                  <h3 class="account-policies-header-h3">Businessowners' Policy</h3>
                  <div *ngIf="isBopPreferred()" class="preference-tag-container">
                    <div class="pill-tag pill-tag__preferred">
                      <ng-container *ngIf="bopPreferenceLevel === '1'">
                        <img
                          class="badge-list-img"
                          src="/assets/img/attune_strongly_preferred_heart.svg"
                          alt=""
                        />
                        <span class="ml-1 preferred-text js-bop-preferred-text">
                          <strong>strongly preferred</strong>
                        </span>
                      </ng-container>
                      <ng-container *ngIf="bopPreferenceLevel === '2'">
                        <img
                          class="badge-list-img"
                          src="/assets/img/attune_preferred_green.svg"
                          alt=""
                        />
                        <span class="ml-1 preferred-text js-bop-preferred-text">
                          <strong>preferred</strong>
                        </span>
                      </ng-container>
                    </div>
                  </div>
                  <p>
                    Attune
                    <span class="gray-text">{{
                      isBopV2() || isMeToo() ? '(Accredited)' : '(Blackboard)'
                    }}</span
                    >&nbsp;&nbsp;<a [href]="getBopGuidelinesLink()" target="_blank">Learn more</a>
                  </p>
                </div>
              </div>
              <span *ngIf="isLoadingEligibility" class="nav-quote-button-loading">
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
              <button
                type="submit"
                *ngIf="isBopEligible && isBopStateEligible && !isLoadingEligibility"
                [disabled]="loading || loadingNeptuneFlood"
                (click)="createProduct('attune', 'bop')"
                class="button button__small button__no-margin button__primary"
                attr.aria-describedby="{{ showSampleBopTip() ? 'create-sample-bop-tip' : '' }}"
                data-amplitude-input-name="new-account-get-quote-bop"
              >
                Get a Quote
                <app-education-pane
                  position="right-top"
                  *ngIf="showSampleBopTip()"
                  elementId="create-sample-bop-tip"
                >
                  Let's quote BOP
                </app-education-pane>
              </button>
              <span
                *ngIf="(!isBopEligible || !isBopStateEligible) && !isLoadingEligibility"
                class="nav-quote-button-disabled"
                >Ineligible</span
              >
            </div>

            <!-- BOP Fortegra product-->
            <div
              class="nav-quote-button js-bop-fortegra-quote-button"
              *ngIf="isFortegra() && !isBopV2() && !isMeToo()"
            >
              <div class="nav-quote-info">
                <div ngClass="product-badge product-badge__bop-fortegra">
                  <div class="product-badge-carrier-icon product-badge-carrier-icon__attune"></div>
                </div>
                <div>
                  <h3 class="account-policies-header-h3">Businessowners' Policy</h3>
                  <div *ngIf="isBopPreferred()" class="preference-tag-container">
                    <div class="pill-tag pill-tag__preferred">
                      <ng-container *ngIf="bopPreferenceLevel === '1'">
                        <img
                          class="badge-list-img"
                          src="/assets/img/attune_strongly_preferred_heart.svg"
                          alt=""
                        />
                        <span class="ml-1 preferred-text js-bop-preferred-text">
                          <strong>strongly preferred</strong>
                        </span>
                      </ng-container>
                      <ng-container *ngIf="bopPreferenceLevel === '2'">
                        <img
                          class="badge-list-img"
                          src="/assets/img/attune_preferred_green.svg"
                          alt=""
                        />
                        <span class="ml-1 preferred-text js-bop-preferred-text">
                          <strong>preferred</strong>
                        </span>
                      </ng-container>
                    </div>
                  </div>
                  <p>
                    Attune
                    <span class="gray-text">(Fortegra)</span>&nbsp;&nbsp;<a
                      [href]="getBopGuidelinesLink()"
                      target="_blank"
                      >Learn more</a
                    >
                  </p>
                </div>
              </div>
              <span *ngIf="isLoadingEligibility" class="nav-quote-button-loading">
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
              <button
                type="submit"
                *ngIf="isBopEligible && isBopStateEligible && !isLoadingEligibility"
                [disabled]="loading || loadingNeptuneFlood"
                (click)="createProduct('attune', 'bop')"
                class="button button__small button__no-margin button__primary"
                attr.aria-describedby="{{ showSampleBopTip() ? 'create-sample-bop-tip' : '' }}"
                data-amplitude-input-name="new-account-get-quote-bop-fortegra"
              >
                Get a Quote
                <app-education-pane
                  position="right-top"
                  *ngIf="showSampleBopTip()"
                  elementId="create-sample-bop-tip"
                >
                  Let's quote BOP
                </app-education-pane>
                <div class="pill-tag pill-tag__new pill-tag__new-top-corner">New</div>
              </button>
              <span
                *ngIf="(!isBopEligible || !isBopStateEligible) && !isLoadingEligibility"
                class="nav-quote-button-disabled"
                >Ineligible</span
              >
            </div>

            <!-- Neptune Flood -->
            <div
              *ngIf="
                getCurrentState() !== 'AK' &&
                getCurrentState() !== 'KY' &&
                isProductAvailable({
                  product: 'flood',
                  pasSource: 'neptune'
                })
              "
              class="nav-quote-button"
            >
              <div class="nav-quote-info">
                <div class="product-badge-group">
                  <div class="product-badge product-badge__flood"></div>
                  <div>
                    <div style="display: flex; align-items: center">
                      <span class="nav-quote-button-label">Flood Insurance &nbsp;</span>
                      <span
                        style="margin-top: -0.2rem"
                        class="pill-tag__small pill-tag pill-tag__warning-inverted"
                        >Partner product</span
                      >
                    </div>
                    <p>
                      <span class="gray-text">Neptune (beats NFIP 90% of the time)</span>
                    </p>
                  </div>
                </div>
              </div>
              <span
                *ngIf="isLoadingEligibility || loadingNeptuneFlood"
                class="nav-quote-button-loading"
              >
                <div class="button-loader">
                  <app-loading-spinner></app-loading-spinner>
                </div>
              </span>
              <a
                *ngIf="!isLoadingEligibility && !loadingNeptuneFlood"
                (click)="createNeptuneFlood()"
                (keyup.enter)="createNeptuneFlood()"
                class="small-text"
              >
                Get a quote
                <img
                  class="help-search-link-arrow-icon"
                  src="../../../../../assets/img/diagonal_arrow.svg"
                  alt=""
                />
              </a>
            </div>

            <!-- Employers WC product -->
            <div class="nav-quote-button js-wc-quote-button" *ngIf="showEmployersProduct()">
              <div class="nav-quote-info">
                <div class="product-badge product-badge__wc">
                  <div
                    class="product-badge-carrier-icon product-badge-carrier-icon__employers"
                  ></div>
                </div>
                <div>
                  <span class="nav-quote-button-label">Workers' Compensation</span>
                  <p>
                    EMPLOYERS&nbsp;&nbsp;<a href="{{ employersWcGuidelinesLink }}" target="_blank"
                      >Learn more</a
                    >
                  </p>
                </div>
              </div>
              <span
                *ngIf="isLoadingEligibility || productAvailabilitiesLoading"
                class="nav-quote-button-loading"
              >
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
              <button
                type="submit"
                *ngIf="
                  !isSampleAccount &&
                  isEmployersWcNaicsEligible &&
                  isEmployersWcStateEligible &&
                  !isLoadingEligibility &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'wc',
                    pasSource: 'employers'
                  })
                "
                [disabled]="loading || loadingNeptuneFlood"
                (click)="createProduct('employers', 'wc')"
                class="button button__small button__no-margin button__primary"
                data-amplitude-input-name="new-account-get-quote-wc"
              >
                Get a Quote
              </button>
              <span
                *ngIf="
                  !(isEmployersWcNaicsEligible && isEmployersWcStateEligible) &&
                  !isLoadingEligibility &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'wc',
                    pasSource: 'employers'
                  })
                "
                class="nav-quote-button-disabled"
                >Ineligible</span
              >
              <span
                *ngIf="
                  !productAvailabilitiesLoading &&
                  !isProductAvailable({
                    product: 'wc',
                    pasSource: 'employers'
                  })
                "
                class="nav-quote-button-disabled"
                >Unavailable</span
              >
              <button
                type="button"
                *ngIf="
                  isSampleAccount &&
                  isEmployersWcNaicsEligible &&
                  isEmployersWcStateEligible &&
                  !isLoadingEligibility &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'wc',
                    pasSource: 'employers'
                  })
                "
                (click)="displayProductRestrictionNote()"
                class="button button__small button__no-margin button__primary"
              >
                Get a Quote
              </button>
            </div>

            <!-- Cyber Liability product -->
            <div
              class="nav-quote-button js-coalition-cyber-quote-button"
              *ngIf="
                displayProductCard({
                  product: 'cyber_admitted',
                  pasSource: 'coalition'
                })
              "
            >
              <div class="nav-quote-info">
                <div class="product-badge product-badge__cyber_admitted">
                  <div
                    class="product-badge-carrier-icon product-badge-carrier-icon__coalition"
                  ></div>
                </div>
                <div>
                  <span class="nav-quote-button-label">Cyber Liability</span>
                  <p>
                    Coalition&nbsp;&nbsp;<a
                      href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/Coalition%20Cyber%20Insurance%20Guidelines%2003.18.2022.pdf"
                      target="_blank"
                      >Learn more</a
                    >
                  </p>
                </div>
              </div>
              <span *ngIf="isLoadingEligibility" class="nav-quote-button-loading">
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
              <div *ngIf="!isLoadingEligibility">
                <button
                  type="submit"
                  *ngIf="!isSampleAccount && isCyberNaicsEligible && !isLoadingEligibility"
                  [disabled]="loading || loadingNeptuneFlood"
                  (click)="createProduct('coalition', 'cyber_admitted')"
                  class="button button__small button__no-margin button__primary"
                  data-amplitude-input-name="new-account-get-quote-cyber_admitted"
                >
                  Get a Quote
                </button>
                <span
                  *ngIf="!isCyberNaicsEligible && !isLoadingEligibility"
                  class="nav-quote-button-disabled"
                  >Ineligible</span
                >
                <button
                  type="button"
                  *ngIf="isSampleAccount && isCyberNaicsEligible && !isLoadingEligibility"
                  (click)="displayProductRestrictionNote()"
                  class="button button__small button__no-margin button__primary"
                >
                  Get a Quote
                </button>
              </div>
            </div>

            <!-- GL V4 product -->
            <div
              class="nav-quote-button js-gl-v4-quote-button"
              *ngIf="
                displayProductCard({
                  product: 'gl',
                  pasSource: 'hiscox'
                })
              "
            >
              <div class="nav-quote-info">
                <div class="product-badge product-badge__gl">
                  <div class="product-badge-carrier-icon product-badge-carrier-icon__hiscox"></div>
                </div>
                <div>
                  <span class="nav-quote-button-label">General Liability</span>
                  <p>
                    Hiscox&nbsp;&nbsp;<a href="{{ hiscoxGlPlGuidelinesLink }}" target="_blank"
                      >Learn more</a
                    >
                  </p>
                </div>
              </div>
              <span
                *ngIf="isLoadingEligibility || productAvailabilitiesLoading"
                class="nav-quote-button-loading"
              >
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
              <button
                type="submit"
                *ngIf="
                  isGlNaicsEligible &&
                  !isLoadingEligibility &&
                  !isSampleAccount &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'gl',
                    pasSource: 'hiscox'
                  })
                "
                [disabled]="loading || loadingNeptuneFlood"
                (click)="createProduct('hiscox', 'gl')"
                class="button button__small button__no-margin button__primary"
                attr.aria-describedby="{{ showSampleBopTip() ? 'create-sample-gl-tip' : '' }}"
                data-amplitude-input-name="new-account-get-quote-gl-v4"
              >
                Get a Quote
              </button>
              <span
                *ngIf="
                  !isGlNaicsEligible &&
                  !isLoadingEligibility &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'gl',
                    pasSource: 'hiscox'
                  })
                "
                class="nav-quote-button-disabled"
              >
                Ineligible
              </span>
              <span
                *ngIf="
                  !isLoadingEligibility &&
                  !productAvailabilitiesLoading &&
                  !isProductAvailable({
                    product: 'gl',
                    pasSource: 'hiscox'
                  })
                "
                class="nav-quote-button-disabled"
              >
                Unavailable
              </span>
              <button
                type="button"
                *ngIf="
                  isGlNaicsEligible &&
                  !isLoadingEligibility &&
                  isSampleAccount &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'gl',
                    pasSource: 'hiscox'
                  })
                "
                (click)="displayProductRestrictionNote()"
                class="button button__small button__no-margin button__primary"
              >
                Get a Quote
              </button>
            </div>

            <!-- PL product V4 -->
            <div
              class="nav-quote-button js-pl-v4-quote-button"
              *ngIf="
                displayProductCard({
                  product: 'pl',
                  pasSource: 'hiscox'
                })
              "
            >
              <div class="nav-quote-info">
                <div class="product-badge product-badge__pl">
                  <div class="product-badge-carrier-icon product-badge-carrier-icon__hiscox"></div>
                </div>
                <div>
                  <span class="nav-quote-button-label">Professional Liability</span>
                  <p>
                    Hiscox&nbsp;&nbsp;<a href="{{ hiscoxGlPlGuidelinesLink }}" target="_blank"
                      >Learn more</a
                    >
                  </p>
                </div>
              </div>
              <span
                *ngIf="isLoadingEligibility || productAvailabilitiesLoading"
                class="nav-quote-button-loading"
              >
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </span>
              <button
                type="submit"
                *ngIf="
                  isPlNaicsEligible &&
                  !isLoadingEligibility &&
                  !isSampleAccount &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'pl',
                    pasSource: 'hiscox'
                  })
                "
                [disabled]="loading || loadingNeptuneFlood"
                (click)="createProduct('hiscox', 'pl')"
                class="button button__small button__no-margin button__primary"
                attr.aria-describedby="{{ showSampleBopTip() ? 'create-sample-pl-tip' : '' }}"
                data-amplitude-input-name="new-account-get-quote-pl-v4"
              >
                Get a Quote
              </button>
              <span
                *ngIf="
                  !isPlNaicsEligible &&
                  !isLoadingEligibility &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'pl',
                    pasSource: 'hiscox'
                  })
                "
                class="nav-quote-button-disabled"
              >
                Ineligible
              </span>
              <span
                *ngIf="
                  !isLoadingEligibility &&
                  !productAvailabilitiesLoading &&
                  !isProductAvailable({
                    product: 'pl',
                    pasSource: 'hiscox'
                  })
                "
                class="nav-quote-button-disabled"
              >
                Unavailable
              </span>
              <button
                type="button"
                *ngIf="
                  isPlNaicsEligible &&
                  !isLoadingEligibility &&
                  isSampleAccount &&
                  !productAvailabilitiesLoading &&
                  isProductAvailable({
                    product: 'pl',
                    pasSource: 'hiscox'
                  })
                "
                (click)="displayProductRestrictionNote()"
                class="button button__small button__no-margin button__primary"
              >
                Get a Quote
              </button>
            </div>
          </div>

          <div class="nav-button-group" *ngIf="!isNewAccountComponent">
            <button type="submit" class="button button__primary" [disabled]="loading">
              <div class="button-loader" *ngIf="loading">
                <app-loading-spinner></app-loading-spinner>
              </div>
              <span *ngIf="!loading">Save changes</span>
              <span *ngIf="loading">Saving changes...</span>
            </button>
            <span *ngIf="isLoadingEligibility" class="nav-quote-button-loading">
              <div class="button-loader">
                <svg class="circle-spinner">
                  <circle
                    cx="11"
                    cy="11"
                    r="9"
                    stroke-width="2"
                    stroke-dasharray="0,1000"
                    d="M5 40 l215 0"
                    fill="transparent"
                  />
                </svg>
              </div>
            </span>
          </div>
        </div>

        <div *ngIf="isDevMode" class="adminz-only">
          <pre>
            {{ form.value | json }}
          </pre>
        </div>
      </form>
    </main>
  </div>
</div>
