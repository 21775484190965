import { Injectable } from '@angular/core';
import { SelectData } from 'app/shared/models/select-data-type';
import { PLACEHOLDER_ORG_TYPE } from 'app/constants';
import { EXECUTIVE_TYPES } from 'app/workers-comp/shared/constants';
import { WorkersCompExec } from 'app/workers-comp/shared/models/executives.model';
import * as _ from 'lodash';

// TODO - can this be replaced by the enum structure in create-insured-account model?
@Injectable()
export class OrganizationTypeService {
  organizationTypes = [
    {
      acordCode: 'CO',
      name: 'Common Ownership',
      value: 'commonownership',
    },
    {
      acordCode: 'CP',
      name: 'Corporation',
      value: 'corporation',
    },
    {
      acordCode: 'ET',
      name: 'Executor Trustee',
      value: 'executortrustee',
    },
    {
      acordCode: 'GE',
      name: 'Government',
      value: 'government',
    },
    {
      acordCode: 'IN',
      name: 'Individual',
      value: 'individual',
    },
    {
      acordCode: 'JV',
      name: 'Joint Venture',
      value: 'jointventure',
    },
    {
      acordCode: 'LP',
      name: 'Limited Partnership',
      value: 'limitedpartnership',
    },
    {
      acordCode: 'LL',
      name: 'LLC',
      value: 'llc',
    },
    {
      acordCode: 'LT',
      name: 'LLP',
      value: 'llp',
    },
    {
      acordCode: 'OT',
      name: 'Non-Profit',
      value: 'nonprofit',
    },
    {
      acordCode: 'OT',
      name: 'Other',
      value: 'other',
    },
    {
      acordCode: 'GP',
      name: 'Partnership',
      value: 'partnership',
    },
    {
      acordCode: 'RO',
      name: 'Religious',
      value: 'religious',
    },
    {
      acordCode: 'IN',
      name: 'Sole Proprietorship',
      value: 'solepropship',
    },
    {
      acordCode: 'TE',
      name: 'Trust Estate',
      value: 'trustestate',
    },
  ];

  public getOrganizationTypes(): SelectData[] {
    // Filter out the placeholder org type, which should only ever be used for account creation
    return this.organizationTypes.filter((orgType) => orgType.value !== PLACEHOLDER_ORG_TYPE);
  }

  public getOrgTypeName(orgTypeValue: string): string {
    const orgType = this.getOrganizationTypes().find((org) => org.value === orgTypeValue);

    return orgType ? orgType.name : orgTypeValue;
  }

  public getEmployersOrgCode(orgType: string): string {
    return _.result(
      _.find(this.organizationTypes, ({ value, name, acordCode }) => {
        return value === orgType;
      }),
      'acordCode'
    );
  }

  // Workers Compensation Executive types
  public getExecTypes(stateCode: string, orgType: string): WorkersCompExec[] {
    const empOrgCode = this.getEmployersOrgCode(orgType);
    const allOptions = EXECUTIVE_TYPES[empOrgCode] || [];

    return allOptions.filter((execType: WorkersCompExec) => {
      return execType.states.includes(stateCode);
    });
  }
}
