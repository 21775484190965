import { Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { SentryService } from 'app/core/services/sentry.service';
import {
  AccountPolicyTerm,
  InsuredAccount,
  WITHDRAWN_RESCINDED_STATUSES,
} from 'app/features/insured-account/models/insured-account.model';
import { AccountPolicyPeriod, PolicyPeriod, PreRenewalDirection } from 'app/bop/guidewire/typings';
import {
  isExcess,
  isBop,
  isHab,
  displayProductName,
  isAttuneWc,
} from 'app/shared/helpers/quote-details';
import { AmplitudeService } from '../../core/services/amplitude.service';
import {
  BOP_RENEWAL_CYBER_CROSS_SELL_CONFIGURATION,
  CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY,
  DismissalRecords,
} from '../product-cross-sell/configs';
import { Router } from '@angular/router';
import { US_DATE_MASK } from '../../constants';
import { NaicsService } from '../services/naics.service';
import { ADMITTED_KNOCK_OUT_STATES } from '../../features/coalition/models/cyber-constants.model';
import { InsuredAccountService } from '../../features/insured-account/services/insured-account.service';
import { InsuredAccountSummaryService } from '../../features/insured-account/services/insured-account-summary.service';
import { FeatureFlagService, BOOLEAN_FLAG_NAMES } from '../../core/services/feature-flag.service';
import * as _ from 'lodash';
import { MAIN_JOB_TYPES } from './bound-policy-details-pane.component';
import { DocumentService } from '../../features/documents/services/document.service';
import {
  getAttuneBopNonRenewNoticeUrl,
  getAttuneBopPolicyUrl,
  getAttuneQuoteLetterUrl,
} from '../../features/documents/models/document-urls.model';
import {
  getAttuneBopNonRenewNoticeFileName,
  getAttuneBopPolicyFileName,
  getQuoteLetterFileName,
} from '../../features/documents/models/document-file-names.model';
import { InsuranceProductCode } from '../../features/digital-carrier/models/types';

// Note: Hardcode term number because ODEN docs are stored on the basis of policy number, not term number
const ODEN_TERM_NUMBER = 1;

@Component({
  selector: 'app-renewal-policy-details',
  templateUrl: './renewal-policy-details-pane.component.html',
})
export class RenewalPolicyDetailsPaneComponent implements OnChanges, OnInit, OnDestroy {
  @Input() accountId: string;
  @Input() policyTermTransaction: PolicyPeriod;
  @Input() insuredAccount: InsuredAccount;
  @Input() paymentStatus: string;
  private sub: Subscription = new Subscription();

  isCrossSellWindowEnabled = false;
  isBrokerEnabledForCyber = false;
  isAccountEligibleForCyber = false;
  doesAccountHaveCyberQuotes = false;
  doesAccountHaveCyberPolicy = false;
  crossSellDismissed = false;
  crossSellConfiguration = BOP_RENEWAL_CYBER_CROSS_SELL_CONFIGURATION;
  preRenewalDirection: PreRenewalDirection;

  quoteLetterDownload$: Observable<any>;
  policyDocumentDownload$: Observable<any>;
  nonRenewDocumentDownload$: Observable<any>;
  nonRenewDocumentDownloadReady$: Observable<any>;
  changeHistory: { download$: Observable<any>; displayName: string }[];
  changeHistoryDownloadsReady$: Observable<any>;

  constructor(
    private sentryService: SentryService,
    private amplitudeService: AmplitudeService,
    private naicsService: NaicsService,
    private featureFlagService: FeatureFlagService,
    private accountSummaryService: InsuredAccountSummaryService,
    private insuredAccountService: InsuredAccountService,
    private router: Router,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.BOP_RENEWAL_CYBER_CROSS_SELL_WINDOW)
      .subscribe((value) => {
        this.isCrossSellWindowEnabled = value || false;
      });
    this.sub.add(
      this.naicsService.getProductAvailability().subscribe((resp) => {
        this.isBrokerEnabledForCyber = resp.some((eligibility) => {
          return eligibility.pasSource === 'coalition' && eligibility.classCodeSelection === 'ALL';
        });
      })
    );

    this.insuredAccountService.get(this.accountId).subscribe((insuredAccount) => {
      if (insuredAccount.naicsCode) {
        this.sub.add(
          this.naicsService
            .getProductEligibility(insuredAccount.naicsCode.hash, insuredAccount.state)
            .subscribe((eligibility) => {
              const isAccountInEligibleState = !ADMITTED_KNOCK_OUT_STATES.includes(
                this.insuredAccount.state
              );
              this.isAccountEligibleForCyber =
                eligibility.isCyberEligible && isAccountInEligibleState;
            })
        );
      }
      if (this.isNonRenewing()) {
        const allTerms = _.flatten(insuredAccount.policiesWithTerms.map((policy) => policy.terms));
        const nonRenewTerm = allTerms.find((term) => {
          return term.status === 'Non-renewing' || term.status === 'Non-renewed';
        });
        if (nonRenewTerm) {
          this.sub.add(
            this.insuredAccountService
              .getPreRenewalDirection(nonRenewTerm.id)
              .subscribe((direction) => {
                this.preRenewalDirection = direction;
              })
          );
        }
      }
    });
    this.sub.add(
      this.accountSummaryService.getSummary(this.accountId).subscribe((accountSummary) => {
        if (accountSummary?.status !== 'success') {
          return;
        }
        this.doesAccountHaveCyberQuotes = accountSummary.quotes.some((quote) => {
          return (
            quote.pasSource === 'coalition' &&
            quote.product === 'cyber_admitted' &&
            quote.status === 'quoted'
          );
        });
        this.doesAccountHaveCyberPolicy = accountSummary.policyTerms.some((policyTerm) => {
          return policyTerm.pasSource === 'coalition';
        });
      })
    );

    const accountIds: DismissalRecords = JSON.parse(
      localStorage.getItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY) || '{}'
    );
    if (Object.prototype.hasOwnProperty.call(accountIds, this.accountId)) {
      const { lastDismissedAt, doNotShow } = accountIds[this.accountId];

      const now = moment();

      this.crossSellDismissed = doNotShow || now.isSameOrBefore(lastDismissedAt, 'date');
    } else {
      this.crossSellDismissed = false;
    }

    if (this.isScheduled()) {
      this.amplitudeService.track({
        eventName: 'scheduled_renewal_policy_details_pane_rendered',
        detail: this.policyTermTransaction.PolicyNumber,
      });
    } else {
      this.amplitudeService.track({
        eventName: 'renewal_policy_details_pane_rendered',
        detail: this.policyTermTransaction.PolicyNumber,
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // Get previous policy number
  ngOnChanges(changes: SimpleChanges) {
    if (!this.policyTermTransaction || !this.insuredAccount) {
      return;
    }
    const policies = this.insuredAccount.policiesWithTerms;
    // Note: find the policy that corresponds to this term
    const policy = policies.find((policyInner) => {
      return policyInner.terms.some((term) => {
        return term.policyNumber === this.policyTermTransaction.PolicyNumber;
      });
    });
    if (!policy) {
      return;
    }
    const quoteLetterUrl = getAttuneQuoteLetterUrl(this.policyTermTransaction.Job.JobNumber);
    const quoteLetterFileName = getQuoteLetterFileName(
      this.policyTermTransaction.Job.JobNumber,
      'renewal' as InsuranceProductCode
    );
    this.quoteLetterDownload$ = this.documentService.getDocument(
      quoteLetterUrl,
      quoteLetterFileName,
      'pdf'
    );

    const policyDocumentUrl = getAttuneBopPolicyUrl(this.policyTermTransaction.Job.JobNumber);
    const policyDocumentFileName = getAttuneBopPolicyFileName(
      this.policyTermTransaction.Job.JobNumber
    );
    this.policyDocumentDownload$ = this.documentService.getDocument(
      policyDocumentUrl,
      policyDocumentFileName,
      'pdf'
    );

    // Note: ODEN Notices are generated for the previous policy number
    //       so we have to look for the previous policy
    const previousTerm = this.getPreviousPolicyTerm();
    if (previousTerm) {
      const nonRenewDocumentUrl = getAttuneBopNonRenewNoticeUrl(
        previousTerm.policyNumber,
        previousTerm.termNumber.toString()
      );
      const nonRenewDocumentFileName = getAttuneBopNonRenewNoticeFileName(
        previousTerm.policyNumber
      );
      this.nonRenewDocumentDownload$ = this.documentService.getDocument(
        nonRenewDocumentUrl,
        nonRenewDocumentFileName,
        'pdf'
      );
      this.nonRenewDocumentDownloadReady$ = this.documentService.pollDocument(nonRenewDocumentUrl);
    }

    const changeHistory = this.getChangeHistory();
    if (changeHistory.length) {
      this.changeHistory = changeHistory.map((policyChange) => {
        const url = getAttuneBopPolicyUrl(policyChange.Job.JobNumber);
        const fileName = `${policyChange.Job.DisplayType.toLowerCase().replace(' ', '-')}-${
          this.policyTermTransaction.PolicyNumber
        }.pdf`;
        return {
          download$: this.documentService.getDocument(url, fileName, 'pdf'),
          displayName: `${policyChange.Job.DisplayType} (${moment
            .utc(policyChange.EditEffectiveDate)
            .format(US_DATE_MASK)})`,
        };
      });
      const changeHistoryUrl = getAttuneBopPolicyUrl(
        changeHistory[changeHistory.length - 1].Job.JobNumber
      );
      this.changeHistoryDownloadsReady$ = this.documentService
        .pollDocument(changeHistoryUrl)
        .pipe(shareReplay());
    }
  }

  getPreviousPolicyTerm(): AccountPolicyTerm | null {
    if (!this.policyTermTransaction || !this.insuredAccount) {
      return null;
    }
    const policies = this.insuredAccount.policiesWithTerms;
    // Note: find the policy that corresponds to this term
    const policy = policies.find((policyInner) => {
      return policyInner.terms.some((term) => {
        return term.policyNumber === this.policyTermTransaction.PolicyNumber;
      });
    });
    if (!policy) {
      return null;
    }
    // Note: ODEN Notices are generated for the previous policy number
    //       so we have to look for the previous policy number
    // Note: policy.terms are in chronological order
    let previousTerm;
    for (let i = policy.terms.length - 2; i >= 0; i--) {
      if (policy.terms[i].policyNumber === this.policyTermTransaction.PolicyNumber) {
        previousTerm = policy.terms[i + 1];
      }
    }
    return previousTerm || null;
  }

  isExcess() {
    return isExcess(this.policyTermTransaction);
  }

  isBop() {
    return isBop(this.policyTermTransaction);
  }

  isAttuneWc() {
    return isAttuneWc(this.policyTermTransaction);
  }

  isHab() {
    return isHab(this.policyTermTransaction);
  }

  displayProductName() {
    return displayProductName(this.policyTermTransaction);
  }

  isNotTaken(): boolean {
    return (
      this.policyTermTransaction?.TermDisplayStatus_ATN === 'Not-taking' ||
      this.policyTermTransaction?.TermDisplayStatus_ATN === 'Not-taken'
    );
  }

  getAutoIssueDate(): Date | undefined {
    if (!this.policyTermTransaction) {
      return undefined;
    }
    return moment.utc(this.policyTermTransaction.EditEffectiveDate).subtract(30, 'days').toDate();
  }

  isScheduled(): boolean {
    return this.policyTermTransaction?.TermDisplayStatus_ATN === 'Scheduled';
  }

  isQuoted(): boolean {
    return this.policyTermTransaction?.TermDisplayStatus_ATN === 'Quoted';
  }

  isNonRenewing(): boolean {
    return (
      this.policyTermTransaction?.TermDisplayStatus_ATN === 'Non-renewing' ||
      this.policyTermTransaction?.TermDisplayStatus_ATN === 'Non-renewed'
    );
  }

  getChangeHistory(): AccountPolicyPeriod[] {
    return _.get(
      this,
      'policyTermTransaction.PolicyTerm.PortalViewableTermPeriods.Entry',
      []
    ).filter((displayTerm: AccountPolicyPeriod) => {
      const isMidtermTransaction = !MAIN_JOB_TYPES.includes(displayTerm.Job.Subtype as string);
      const isRescinded = displayTerm.Status
        ? WITHDRAWN_RESCINDED_STATUSES.includes(displayTerm.Status)
        : false;
      return isMidtermTransaction && !isRescinded;
    });
  }

  getTransactionCost() {
    if (this.policyTermTransaction.TermTotalCost_ATN == null || !this.insuredAccount) {
      return NaN;
    }

    return parseInt(this.policyTermTransaction.TermTotalCost_ATN.split(' ')[0], 10);
  }

  hasValidNonRenewReasons() {
    return this?.preRenewalDirection?.PreRenewalDirectionReasons?.length === 1;
  }

  getOdenNoticeDisplayName() {
    if (this.isNonRenewing()) {
      return 'Non-renewal notice';
    }
    return 'Notice';
  }

  transactionClass() {
    if (this.isNotTaken()) {
      return 'declined';
    } else if (this.isQuoted()) {
      return 'good';
    } else if (this.isScheduled()) {
      return 'main';
    } else if (this.isNonRenewing()) {
      return 'draft';
    }
  }

  closeCrossSell($event: { close: boolean; submit: boolean }) {
    if ($event.close) {
      const accountIds: DismissalRecords = JSON.parse(
        localStorage.getItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY) || '{}'
      );

      const today = moment().format(US_DATE_MASK);

      if (Object.prototype.hasOwnProperty.call(accountIds, this.accountId)) {
        // if it already existed then this is the secind time it is being dismissed
        accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: true };
      } else {
        accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: false };
      }

      localStorage.setItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY, JSON.stringify(accountIds));

      this.crossSellDismissed = true;

      this.amplitudeService.track({
        eventName: 'bop_renewal_close_cyber_cross_sell_window',
        detail: this.accountId,
      });
    }
    if ($event.submit) {
      this.amplitudeService.track({
        eventName: 'bop_renewal_new_quote_via_cyber_cross_sell_window',
        detail: this.accountId,
      });
      this.router.navigate([`/accounts/${this.accountId}/cyber-admitted/quick`]);
    }
  }
}
