import { BopPolicy } from 'app/features/attune-bop/models/bop-policy';
import {
  AVAILABLE_BUSINESS_TYPES,
  DOCTORS_AND_DENTISTS_CLASS_CODES,
} from 'app/features/attune-bop/models/constants';
import * as _ from 'lodash';

// NOTE: Spoke with Andrew, he said this is fine for now.
export function businessType(policy: BopPolicy) {
  return policy.locations[0].buildings[0].exposure.businessType || '';
}

export function hasContractorBusinessType(policy: BopPolicy) {
  return _.flatMap(policy.locations, (location) => location.buildings).some(
    (building) => building.exposure.businessType === AVAILABLE_BUSINESS_TYPES.Contractor
  );
}

export function hasAbuseRiskClassifications(policy: BopPolicy) {
  return _.flatMap(policy.locations, (location) => location.buildings).some((building) => {
    const code = building.exposure.classification.code;
    const stringCode = code && typeof code === 'object' && code.code;
    return _.includes(DOCTORS_AND_DENTISTS_CLASS_CODES, stringCode);
  });
}
