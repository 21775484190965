import { of as observableOf } from 'rxjs';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { ClassCodeFormGroup } from 'app/workers-comp/attune/models/quote-form.model';
import { AttuneWcClassCode } from 'app/workers-comp/attune/typings';
import { AmplitudeService } from 'app/core/services/amplitude.service';

const INITIAL_CODES_LIMIT = 250;

@Component({
  selector: 'app-wc-class-code',
  templateUrl: './attune-wc-class-code.component.html',
})
export class AttuneWcClassCodeComponent {
  @Input() classCode: ClassCodeFormGroup;
  @Input() submitted: boolean;
  @Input() prefix: string;
  @Input() classCodeOptions: AttuneWcClassCode[] = [];
  @Input() fuse: any;
  @ViewChild('typeahead')
  typeahead: ElementRef;

  constructor(private amplitudeService: AmplitudeService) {}

  triggerAmplitudeOnClassCodeSelect(classCode: AttuneWcClassCode) {
    this.amplitudeService.track({
      eventName: 'attune_wc_classification_code',
      detail: JSON.stringify(classCode),
    });
  }

  mapCodeToLabel(classCode: AttuneWcClassCode): string {
    if (!classCode || !classCode?.classCode || !classCode?.description) {
      return '';
    }
    // This is used for analytics, for rendering, see the resultTemplate
    return `${classCode.classCode}- ${classCode.description}`;
  }

  searchCodes = (query: string) => {
    if (!this.fuse) {
      return observableOf([]);
    }

    if (!query) {
      return observableOf(this.classCodeOptions.slice(0, INITIAL_CODES_LIMIT));
    }

    const results = this.fuse.search(query);
    return observableOf(results);
  };
}
