import {
  interval as observableInterval,
  Observable,
  Subscription,
  of,
  BehaviorSubject,
} from 'rxjs';

import { onErrorResumeNext, mergeMap, map, catchError, filter, switchMap } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { User } from 'app/shared/models/user';

import {
  REFRESH_USER_API_URI,
  USER_LOGGED_IN_CHECK_INTERVAL_IN_SECONDS,
  USER_TOKEN_REFRESH_IN_SECONDS,
  INVITE_USER_API_URI,
  ACCEPT_INVITE_API_URI,
  CANCEL_INVITE_API_URI,
} from 'app/constants';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { CurrentUser, USER_CONTEXT } from 'app/shared/models/current-user';
import { HttpResponse, HttpClient } from '@angular/common/http';

import { SentryService } from 'app/core/services/sentry.service';
import { InviteUserRequest } from '../../shared/consumer/typings';
import { OktaAuthService } from './oktaAuth.service';

// Would prefer an enum but the role is technically just a string and these technically aren't
// all the roles but these are all the non binding roles in use
export const NON_BINDING_ROLES = [
  'Producer - AttuneQuoteOnly',
  'Producer View Only',
  'Service Only',
];

export const UNABLE_TO_BIND_MESSAGE =
  'Your account is not setup to bind. For more information, please contact our Customer Support Team!';

@Injectable()
export class UserService {
  loggedInCheckSub = new Subscription();
  destinationUrl = '';
  refreshTimerSub = new Subscription();
  userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private http: HttpClient,
    private currentUserService: CurrentUserService,
    private authenticationService: AuthenticationService,
    private oktaAuth: OktaAuthService,
    private router: Router,
    private ngZone: NgZone,
    private sentryService: SentryService
  ) {
    this.loggedInCheckSub.closed = true;
    this.refreshTimerSub.closed = true;
    authenticationService.successfulAuthentications$.subscribe(
      this.setCurrentUserInSentryService.bind(this)
    );
  }

  private setCurrentUserInSentryService() {
    this.currentUserService.getCurrentUserFormatted().subscribe((currentUser) => {
      if (currentUser) {
        this.sentryService.setCurrentUser(currentUser);
      }
    });
  }

  getUser(): Observable<User> {
    if (!this.userSubject.getValue()) {
      const localSubject = this.userSubject;
      this.currentUserService
        .getCurrentUserFormatted()
        .pipe(filter((state: User) => !!state))
        .subscribe((currentUser) => {
          localSubject.next(currentUser);
        });
    }
    return this.userSubject.pipe(filter((user: User) => !!user));
  }

  inviteUser(payload: InviteUserRequest): Observable<InviteUserResponse> {
    return this.http.post<any>(INVITE_USER_API_URI, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to invite user.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  acceptInvite(email: string): Observable<any> {
    return this.http
      .post<any>(ACCEPT_INVITE_API_URI, {
        email: email,
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to accept invite.', {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  cancelInvite(login: string): Observable<any> {
    return this.http.post<any>(`${CANCEL_INVITE_API_URI}/${encodeURI(login)}`, {}).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to cancel invite.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  startRefreshTimer(time: number) {
    return observableInterval(time)
      .pipe(
        mergeMap((): Observable<CurrentUser | null> => this.refreshUser().pipe(onErrorResumeNext()))
      )
      .subscribe((user: CurrentUser | null) => {
        if (user !== null) {
          this.authenticationService.updateTokenAndCurrentUserFromPayload(user);
        }
      });
  }

  stopRefreshTimer() {
    this.refreshTimerSub.unsubscribe();
  }

  /**
   *
   * @param destinationUrl URL path the user is trying to access
   * @param loginRedirectOverride optional URL path to redirect users instead of login page
   */
  validateSessionAndRepeat(destinationUrl: string, loginRedirectOverride?: string): boolean {
    this.destinationUrl = destinationUrl; // update so we redirect to latest page on auto logout
    const result = this.currentUserService.isCurrentUserPresent(USER_CONTEXT.AGENT_PORTAL);

    if (!result) {
      loginRedirectOverride
        ? this.router.navigateByUrl(loginRedirectOverride)
        : this.redirectToLoginPage(this.destinationUrl);
      return false;
    }

    this.ngZone.runOutsideAngular(() => {
      const loggedInCheckInterval = USER_LOGGED_IN_CHECK_INTERVAL_IN_SECONDS * 1000;
      const tokenRefreshInterval = USER_TOKEN_REFRESH_IN_SECONDS * 1000;
      if (this.refreshTimerSub.closed) {
        this.refreshTimerSub = this.startRefreshTimer(tokenRefreshInterval);
      }
      if (this.loggedInCheckSub.closed) {
        this.loggedInCheckSub = observableInterval(loggedInCheckInterval)
          .pipe(
            switchMap((): Promise<boolean> => this.oktaAuth.isAuthenticated()),
            map((isOktaAuthorized): boolean => {
              const currentUserPresent = this.currentUserService.isCurrentUserPresent(
                USER_CONTEXT.AGENT_PORTAL
              );

              return currentUserPresent;
            }),
            catchError((error) => {
              this.sentryService.notify("Error while validating user's session", {
                severity: 'error',
                metaData: {
                  underlyingErrorMessage: error && error.message,
                  underlyingError: error,
                  username: this.currentUserService.getCurrentUser()?.username,
                },
              });

              throw error;
            })
          )
          .subscribe((isLoggedIn) => {
            if (!isLoggedIn) {
              this.ngZone.run(() => {
                this.redirectToLoginPage(this.destinationUrl);
              });
            }
          });
      }
    });

    return result;
  }

  redirectToLoginPage(relativeRedirectUrl: string) {
    this.currentUserService.destroyCurrentUser();
    this.userSubject.next(null);
    relativeRedirectUrl === '/' || relativeRedirectUrl === ''
      ? this.router.navigateByUrl('/login')
      : this.router.navigateByUrl('/login?redirect=' + encodeURIComponent(relativeRedirectUrl));
  }

  stopValidatingSession() {
    this.loggedInCheckSub.unsubscribe();
  }

  private refreshUser(): Observable<CurrentUser | null> {
    const currentUser = this.currentUserService.getCurrentUser();
    if (!currentUser) {
      return of(null);
    }
    return this.http
      .get(REFRESH_USER_API_URI, {
        observe: 'response',
      })
      .pipe(
        map((payload: HttpResponse<any>): CurrentUser | null => {
          return this.authenticationService.parseCurrentUser(payload, currentUser.username);
        }),
        catchError((error) => {
          this.sentryService.notify("Unable to refresh user's session", {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }
}
