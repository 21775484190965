<div class="sectioned-guidelines">
  <ng-content></ng-content>
  <details
    data-amplitude-input-name="hab-account-history-guidelines-toggle"
    class="sectioned-details sectioned-details__padding"
  >
    <summary>Account History</summary>
    <ul>
      <li>
        Any risk non-renewed by a prior carrier due to non-compliance with loss control
        recommendations
      </li>
      <li>Two or more similar losses at an individual location in the last three years</li>
      <li>Three or more losses of any type at an individual location in the last three years</li>
      <li>Two or more losses over $25,000 at an individual location in the last three years</li>
      <li>Any risk with a current open liability claim</li>
      <li>Two or more evictions in the last 12 months</li>
      <li>Properties transitioning out of Previously Foreclosed / Bank Owned</li>
      <li>Insureds under bankruptcy protection</li>
    </ul>
  </details>

  <details
    data-amplitude-input-name="hab-classifications-guidelines-toggle"
    class="sectioned-details sectioned-details__padding"
  >
    <summary>Classifications</summary>
    <ul>
      <li>1-4 unit buildings</li>
      <li>Boarding or rooming houses</li>
      <li>Condominium, Homeowners or Townhouse associations</li>
      <li>Corporate or Company housing - permanent or temporary</li>
      <li>Correctional Facilities</li>
      <li>Designated Historical buildings</li>
      <li>
        Dormitories, college housing, fraternities/sororities, student housing or similar
        occupancies
      </li>
      <li>Group homes, Halfway houses or Rehabilitation facilities</li>
      <li>Hotels or Motels</li>
      <li>
        Mixed use of any kind. Defined as any business not operated by the insured located on/in the
        insured premises/building
      </li>
      <li>
        Nursing homes, Age-in-place units or facilities, hospice facilities or residential care
        facilities offering living assistance and/or medical services
      </li>
      <li>Age-restricted senior housing</li>
      <li>Subsidized, government funded or public housing complexes</li>
      <li>Timeshares or Resort communities</li>
      <li>Vacant Land</li>
    </ul>
  </details>

  <details
    data-amplitude-input-name="hab-premises-guidelines-toggle"
    class="sectioned-details sectioned-details__padding"
  >
    <summary>Premises / Operations Characteristics</summary>
    <ul>
      <li>Amusement/ Playgrounds or similar structures</li>
      <li>Any risk with recreational facilities open to the general public</li>
      <li>Daycare or Childcare provided</li>
      <li>Open man made water exposures (e.g. ponds, creeks)</li>
      <li>
        Properties within 1000 ft of an unrestricted non-man made body of water (Oceans, Rivers,
        Lakes etc)
      </li>
      <li>Security guards</li>
    </ul>
  </details>

  <details
    data-amplitude-input-name="hab-property-guidelines-toggle"
    class="sectioned-details sectioned-details__padding"
  >
    <summary>Property Characteristics</summary>
    <ul>
      <li>Any barred windows or similar window security fixtures</li>
      <li>Any exterior insulation finishing system (EIFS) construction</li>
      <li>Buildings with vacancies over 20% (based on total of all available units)</li>
      <li>Buildings with wood shake/shingle siding or fascia</li>
      <li>Buildings under construction or renovations involving more than one unit</li>
      <li>Manufactured or modular buildings</li>
      <li>Non-permitted or non-conforming units</li>
    </ul>
  </details>
</div>
