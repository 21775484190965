<div class="support-wrapper">
  <h1>News</h1>
  <ul class="newsfeed">
    <li *ngFor="let news of newsfeed" class="newsfeed-item">
      <h2 class="h2">
        <a href="{{ news.link }}" target="_blank">{{ news.title }}</a>
      </h2>
      <div class="newsfeed-date">{{ formatDate(news.isoDate) }}</div>
      <div class="newsfeed-content">
        {{ news.content }}
        <a href="{{ news.link }}" target="_blank"> Read More</a>
      </div>
    </li>
  </ul>
</div>
