import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { shouldShowInvalid } from 'app/shared/helpers/form-helpers';
import { User } from 'app/shared/models/user';
import { Subscription } from 'rxjs';

import { InsuredAccount } from '../../features/insured-account/models/insured-account.model';
import { EmailService } from '../services/email.service';

/**
 * NOTE: (2020-4-29) This component is exclusively used in the Hab flow.
 * // TODO confirm above and remove w/ HAB
 */
@Component({
  selector: 'app-loss-runs-upload',
  templateUrl: './loss-runs-upload.component.html',
})
export class LossRunsUploadComponent implements OnDestroy {
  @Input() inputId: string;
  @Input() cssClass: string;
  @Input() quoteId: string;
  @Input() account: InsuredAccount;
  @Input() user: User;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  fileUploading: boolean;

  public nameOfFormControl = 'fileName';
  private sub = new Subscription();

  constructor(private emailService: EmailService) {}

  onFilesSelected(event: Event) {
    const target = <HTMLInputElement>event.target;
    if (target.files && target.files.length > 0) {
      const selectedFile = target.files[0];

      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      formData.append('model', JSON.stringify(this.account));
      formData.append('user', JSON.stringify(this.user));

      const fileUploading$ = this.emailService.setupFileStatusObserver(selectedFile.name);
      this.sub.add(
        this.emailService
          .sendHabLossRun(this.quoteId, formData, selectedFile.name)
          .subscribe((success: boolean) => {
            this.form.patchValue({
              [this.nameOfFormControl]: success ? selectedFile.name : '',
            });
          })
      );
      this.sub.add(
        fileUploading$.subscribe((loading: boolean) => {
          this.fileUploading = loading;
        })
      );
    }
  }

  fieldInvalid(field: string): boolean | undefined {
    return this.submitted || shouldShowInvalid(field, this.form, this.submitted);
  }

  ngOnDestroy() {
    const fileName = this.form.value[this.nameOfFormControl];
    if (fileName !== undefined) {
      this.emailService.teardownFileStatusObserver(fileName);
    }

    this.sub.unsubscribe();
  }
}
