<section class="form-animation">
  <h1>Guidelines</h1>
  <p>
    I have read and confirm that the business I am representing for Workers' Compensation coverage
    adheres to the following guidelines:
  </p>

  <h2>Prohibited Classes/Operations:</h2>
  <p>
    The following classes of business, operations, or exposures are prohibited and cannot be
    considered for coverage under any circumstances:
  </p>

  <h3>Ineligible Industries:</h3>
  <ul class="bulleted-list bulleted-list__tight js-ineligible-industries">
    <li *ngFor="let industry of ineligibleIndustries">{{ industry }}</li>
  </ul>

  <h3>Coverage Restrictions:</h3>
  <ul class="bulleted-list bulleted-list__tight js-coverage-restrictions">
    <li *ngFor="let restriction of coverageRestrictions">{{ restriction }}</li>
  </ul>

  <app-form-field-checkbox
    inputId="accept-guidelines"
    labelText="I attest that the business meets the above guidelines and is accurate to my knowledge."
    nameOfFormControl="attestation"
    [submitted]="submitted()"
    [form]="form"
  >
  </app-form-field-checkbox>
</section>
