import * as moment from 'moment';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BopPolicy } from 'app/features/attune-bop/models/bop-policy';
import { AttuneBopQuotePayloadService } from 'app/features/attune-bop/services/attune-bop-quote-payload.service';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { allErrorsRecursively } from 'app/shared/helpers/form-helpers';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

@Component({
  selector: 'app-quote-form-debugger',
  templateUrl: './attune-bop-quote-form-debugger.component.html',
})
export class AttuneBopQuoteFormDebuggerComponent {
  @Input() form: UntypedFormGroup;
  @Input() insuredAccount: InsuredAccount;
  @Input() tsRequestId: string;
  @Input() now: moment.Moment = moment.utc(); // Hardcoded placeholder
  @Input() currentStep: RouteFormStep;
  @Input() submitted: boolean;
  showStuff = false;

  constructor(
    private quotePayloadService: AttuneBopQuotePayloadService,
    private currentUserService: CurrentUserService
  ) {}

  bopPolicyData() {
    return new BopPolicy(this.form.value, this.now.toDate(), this.tsRequestId);
  }

  bopPayload() {
    try {
      const currentUser = this.currentUserService.getCurrentUser();
      if (!currentUser) {
        throw new Error('Login Expired');
      }
      return this.quotePayloadService.translatePolicy(
        this.insuredAccount,
        new BopPolicy(this.form.value, this.now.toDate(), this.tsRequestId),
        currentUser
      );
    } catch (e) {
      return {};
    }
  }

  toggleShowStuff() {
    this.showStuff = !this.showStuff;
  }

  allErrors(): any {
    // https://stackoverflow.com/questions/40680321/get-all-validation-errors-from-angular-2-formgroup
    return allErrorsRecursively(this.form);
  }
}
