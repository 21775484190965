import { reduce } from 'lodash';

import { CpsClassConfig } from './lm-cpsp-typings';
import { ClassAndStateDependency } from './common-typings';

/**
 * Dictionary that gives the possible CSP/Property class codes corresponding
 * to each GL class code, and the state(s) those CSP/Property class codes are
 * available in.
 */
export const LM_CPSP_PROPERTY_CLASS_CODES: Record<
  string,
  { classCode: CpsClassConfig; state: ClassAndStateDependency }[]
> = {
  '10010': [
    {
      classCode: {
        name: 'Air Conditioning Equipment - Dealers Or Distributors Only',
        cspCode: '0563',
        internalCode: '000240',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252337',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10026': [
    {
      classCode: {
        name: 'Antique Stores',
        cspCode: '0564',
        internalCode: '001164',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252340',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10040': [
    {
      classCode: {
        name: "Other Than Radios, TV's Compact Disc and/or DVD Players Only (With or Without Repair)",
        cspCode: '0563',
        internalCode: '001792',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Radios, TV's Compact Disc and/or DVD Players Only (With or Without Repair)",
        cspCode: '0567',
        internalCode: '001784',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Including Home Furnishings, With or Without Repair',
        cspCode: '0563',
        internalCode: '070037',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252342',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair Only',
        cspCode: '0922',
        internalCode: '001800',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10042': [
    {
      classCode: {
        name: "Other Than Radios, TV's Compact Disc and/or DVD Players Only (With or Without Repair)",
        cspCode: '0563',
        internalCode: '001826',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Radios, TV's Compact Disc and/or DVD Players Only (With or Without Repair)",
        cspCode: '0567',
        internalCode: '001818',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252343',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair Only',
        cspCode: '0922',
        internalCode: '001834',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10065': [
    {
      classCode: {
        name: 'Art Studios',
        cspCode: '0921',
        internalCode: '350413',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Commercial',
        cspCode: '0567',
        internalCode: '350421',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252346',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10066': [
    {
      classCode: {
        name: 'Art Studios',
        cspCode: '0921',
        internalCode: '002014',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Commercial',
        cspCode: '0567',
        internalCode: '002030',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252345',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Non-Profit',
        cspCode: '1051',
        internalCode: '002022',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10070': [
    {
      classCode: {
        name: 'Automobile Parts And Supplies Distributors',
        cspCode: '0512',
        internalCode: '002584',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252351',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10071': [
    {
      classCode: {
        name: 'Automobile Parts And Supplies Stores',
        cspCode: '0512',
        internalCode: '002592',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252352',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10072': [
    {
      classCode: {
        name: 'Automobile Quick Lubrication Services',
        cspCode: '0932',
        internalCode: '070052',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10073': [
    {
      classCode: {
        name: 'Auto Glass Replacement, Battery Replacement, Brake Adjustment, Tire Changing, Tune Ups, Oil Changing, Lubrications, Speedometer Adjustments and Similar Services',
        cspCode: '0932',
        internalCode: '002675',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Major Engine Repair or Body Repair',
        cspCode: '0933',
        internalCode: '002683',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252353',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Seat Cover Padding and Trimwork or Top Repair Exclusively',
        cspCode: '0512',
        internalCode: '002667',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10100': [
    {
      classCode: {
        name: 'Baking, With Delivery to Other Outlets',
        cspCode: '2200',
        internalCode: '002881',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Baking, With No Delivery to Other Outlets',
        cspCode: '0533',
        internalCode: '002873',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales Only, No Baking',
        cspCode: '0532',
        internalCode: '002865',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10111': [
    {
      classCode: {
        name: 'Barber Or Beauty Shop Supplies Distributors',
        cspCode: '0567',
        internalCode: '002832',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252355',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10113': [
    {
      classCode: {
        name: 'Barber Shops',
        cspCode: '0921',
        internalCode: '002840',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10115': [
    {
      classCode: {
        name: 'Beauty Parlors And Hair Styling Salons',
        cspCode: '0921',
        internalCode: '003079',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10140': [
    {
      classCode: {
        name: 'Beverage Distributors - Alcoholic Other Than Beer',
        cspCode: '0531',
        internalCode: '003368',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10141': [
    {
      classCode: {
        name: 'Beverage Distributors - Nonalcoholic And Beer',
        cspCode: '0532',
        internalCode: '003376',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Beverage Distributors - Nonalcoholic And Beer - Primarily Beer',
        cspCode: '0531',
        internalCode: '003434',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10145': [
    {
      classCode: {
        name: 'Beverage Stores - Liquor And Wine',
        cspCode: '0531',
        internalCode: '003384',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10146': [
    {
      classCode: {
        name: 'Beverage Stores - Soft Drinks and Beer',
        cspCode: '0532',
        internalCode: '003392',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Beverage Stores - Soft Drinks and Beer - Principal Stock is Beer',
        cspCode: '0531',
        internalCode: '048348',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10150': [
    {
      classCode: {
        name: 'Repair And/Or Service And/Or Storage',
        cspCode: '0922',
        internalCode: '003467',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Sales Only',
        cspCode: '0567',
        internalCode: '003475',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252356',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10204': [
    {
      classCode: {
        name: 'For Profit',
        cspCode: '0567',
        internalCode: '041921',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252493',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10255': [
    {
      classCode: {
        name: 'Cement, Hydrated Or Slaked Lime',
        cspCode: '1650',
        internalCode: '004705',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Lumber, Plywood Or Timbers, Lumber Products Other Than Furniture, Box Shook, Sash, Doors And Similar Products',
        cspCode: '1650',
        internalCode: '004721',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252359',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mixed Building Material Not Noted Above',
        cspCode: '0567',
        internalCode: '004762',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Non-Combustible Materials Only (Bricks, Concrete, Sand, Tile, Etc.)',
        cspCode: '1650',
        internalCode: '004689',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10257': [
    {
      classCode: {
        name: 'Cement, Hydrated Or Slaked Lime',
        cspCode: '1650',
        internalCode: '041392',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Lumber, Plywood Or Timbers, Lumber Products Other Than Furniture, Box Shook, Sash, Doors And Similar Products',
        cspCode: '1650',
        internalCode: '041418',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252367',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mixed Building Material Not Noted Above',
        cspCode: '0567',
        internalCode: '041434',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Non-Combustible Materials Only (Bricks, Concrete, Sand, Tile, Etc.)',
        cspCode: '1650',
        internalCode: '041376',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10309': [
    {
      classCode: {
        name: 'Camera And Photographic Equipment Stores',
        cspCode: '0567',
        internalCode: '004994',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mercantile - >15,000 sq ft-retail/wholesale other than food - sole occupancy - bldg only - not class rated',
        cspCode: '0431',
        internalCode: '252368',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10352': [
    {
      classCode: {
        name: 'Candy Or Confectionery Stores',
        cspCode: '0532',
        internalCode: '005504',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Candy Or Confectionery Stores - Sole Occupancy And Over 15,000 Square Feet',
        cspCode: '0432',
        internalCode: '005512',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10367': [
    {
      classCode: {
        name: 'Car Washes - Other Than Self Service',
        cspCode: '0931',
        internalCode: '005447',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '10368': [
    {
      classCode: {
        name: 'Car Washes - Self-Service',
        cspCode: '0931',
        internalCode: '005454',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11007': [
    {
      classCode: {
        name: 'Carpet, Rug Or Upholstery Cleaning - Shop Only',
        cspCode: '0912',
        internalCode: '005686',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11020': [
    {
      classCode: {
        name: 'Catalog Or Premium Coupon Redemption Stores',
        cspCode: '0567',
        internalCode: '005694',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252373',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11039': [
    {
      classCode: {
        name: 'Caterers',
        cspCode: '0542',
        internalCode: '005702',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11126': [
    {
      classCode: {
        name: '5 Or Less Employees Engaged In Sewing',
        cspCode: '0567',
        internalCode: '006551',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 5 Employees Engaged In Sewing',
        cspCode: '3009',
        internalCode: '006585',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252375',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11127': [
    {
      classCode: {
        name: '5 or Less Employees Engaged in Sewing',
        cspCode: '0520',
        internalCode: '006569',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252376',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 5 Employees Engaged in Sewing',
        cspCode: '3009',
        internalCode: '006577',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11128': [
    {
      classCode: {
        name: '5 or Less Employees Engaged in Sewing',
        cspCode: '0520',
        internalCode: '351742',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252495',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 5 Employees Engaged in Sewing',
        cspCode: '3009',
        internalCode: '351759',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11160': [
    {
      classCode: {
        name: 'Computer Stores',
        cspCode: '0570',
        internalCode: '070326',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252516',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11167': [
    {
      classCode: {
        name: 'Mercantile',
        cspCode: '0567',
        internalCode: '354316',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252378',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Service',
        cspCode: '0921',
        internalCode: '354324',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11168': [
    {
      classCode: {
        name: 'Concessionaires - Food or Drink',
        cspCode: '0542',
        internalCode: '352005',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Concessionaires - NOC',
        cspCode: '0921',
        internalCode: '352021',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Concessionaires - No Food or Drink',
        cspCode: '0567',
        internalCode: '352013',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252377',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11222': [
    {
      classCode: {
        name: 'Copying And Duplicating Services',
        cspCode: '0921',
        internalCode: '008417',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair Of Copying And Duplicating Equipment With No Sales Or Storage',
        cspCode: '0922',
        internalCode: '008433',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sale Of Copying And Duplicating Equipment With Or Without Repair',
        cspCode: '0567',
        internalCode: '008425',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11234': [
    {
      classCode: {
        name: 'Cosmetic, Hair Or Skin Preparation Stores',
        cspCode: '0567',
        internalCode: '008342',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252383',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11258': [
    {
      classCode: {
        name: 'Dairy Products Or Butter And Egg Stores',
        cspCode: '0532',
        internalCode: '008896',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Dairy Products Or Butter And Egg Stores - Over 15,000 Square Feet',
        cspCode: '0432',
        internalCode: '008904',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11259': [
    {
      classCode: {
        name: 'Dairy Products Or Butter And Egg Stores',
        cspCode: '0532',
        internalCode: '354111',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Dairy Products Or Butter And Egg Stores - Over 15,000 Square Feet',
        cspCode: '0432',
        internalCode: '354126',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '11288': [
    {
      classCode: {
        name: 'Delicatessens - With Cooking',
        cspCode: '0542',
        internalCode: '009134',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Delicatessens - Without Cooking',
        cspCode: '0532',
        internalCode: '009142',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12014': [
    {
      classCode: {
        name: 'Dental Laboratories',
        cspCode: '0921',
        internalCode: '009076',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12356': [
    {
      classCode: {
        name: 'Department Or Discount Stores',
        cspCode: '0567',
        internalCode: '009084',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252385',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12361': [
    {
      classCode: {
        name: 'Wholesale or Warehouse Stock When the Principal Stock is Not Alcoholic Beverages or Farm Products',
        cspCode: '0532',
        internalCode: '353037',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Wholesale or Warehouse Stock of Alcoholic Beverage',
        cspCode: '0531',
        internalCode: '353029',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Wholesale or Warehouse Stock or Farm Products Other than Grain, Seeds, Beans, Cotton or Tobacco',
        cspCode: '1251',
        internalCode: '009373',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12362': [
    {
      classCode: {
        name: 'Book Distributors',
        cspCode: '0567',
        internalCode: '354167',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Gardening and Light Farm Supply',
        cspCode: '0567',
        internalCode: '354175',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Hearing Aid Distributors',
        cspCode: '0567',
        internalCode: '354159',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Hobby, Craft or Artist' Supply",
        cspCode: '0567',
        internalCode: '354183',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office Machines or Appliances - No Repair',
        cspCode: '0563',
        internalCode: '354142',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other - High Susceptibility Personal Property',
        cspCode: '0570',
        internalCode: '354209',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Moderate Susceptibility Personal Property',
        cspCode: '0567',
        internalCode: '354217',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Stationary or Paper Products',
        cspCode: '0567',
        internalCode: '354191',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Electrical Goods, Hardware and Machinery Including Air Conditioners, Appliances, Plumbing, Heating and Farm Machinery',
        cspCode: '0563',
        internalCode: '354134',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252386',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12374': [
    {
      classCode: {
        name: 'Drugstores - No Table Or Counter Service For Beverage Or Food',
        cspCode: '0562',
        internalCode: '009969',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252389',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12375': [
    {
      classCode: {
        name: 'Drugstores NOC',
        cspCode: '0562',
        internalCode: '009951',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252388',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12391': [
    {
      classCode: {
        name: 'Electrical Equipment Distributors - Repair Or Service With No Sales Or Storage',
        cspCode: '0922',
        internalCode: '010603',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Electrical Equipment Distributors - Repair Or Service With Sales Or Storage',
        cspCode: '0563',
        internalCode: '010611',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252390',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12393': [
    {
      classCode: {
        name: 'Electronics Store',
        cspCode: '0570',
        internalCode: '070508',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252517',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12467': [
    {
      classCode: {
        name: 'With Furniture',
        cspCode: '0564',
        internalCode: '011205',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Furniture',
        cspCode: '0567',
        internalCode: '011213',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252391',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12510': [
    {
      classCode: {
        name: 'Fabric Stores',
        cspCode: '0520',
        internalCode: '011585',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252392',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12651': [
    {
      classCode: {
        name: 'Fence Dealers',
        cspCode: '0567',
        internalCode: '011916',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252396',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12797': [
    {
      classCode: {
        name: 'Floor Covering Distributors - Including Linoleum, Vinyl And Plastic Carpets',
        cspCode: '0520',
        internalCode: '012583',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Floor Covering Distributors NOC',
        cspCode: '0567',
        internalCode: '012575',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252400',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12805': [
    {
      classCode: {
        name: 'Floor Covering Stores - Carpets',
        cspCode: '0520',
        internalCode: '012724',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Floor Covering Stores - Tiles Or Linoleum or Mixed Merchandise',
        cspCode: '0567',
        internalCode: '012732',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252401',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '12841': [
    {
      classCode: {
        name: 'Florists - Greenhouses',
        cspCode: '0580',
        internalCode: '012765',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Florists - Sales Outlets',
        cspCode: '0570',
        internalCode: '012757',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mercantile - >15,000 sq ft-retail/wholesale other than food - sole occupancy - bldg only - not class rated',
        cspCode: '0431',
        internalCode: '252402',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13049': [
    {
      classCode: {
        name: 'Frozen Food Distributors',
        cspCode: '0532',
        internalCode: '013342',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Frozen Food Distributors - Sole Occupant Over 15,000 Square Feet',
        cspCode: '0432',
        internalCode: '013359',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13111': [
    {
      classCode: {
        name: 'Fruit, Nut or Vegetable Dealers',
        cspCode: '0532',
        internalCode: '013367',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sole Occupancy and Over 15,000 Square Feet Total Floor Area',
        cspCode: '0432',
        internalCode: '013375',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13112': [
    {
      classCode: {
        name: 'Fruit, Nut or Vegetable Distributors',
        cspCode: '0532',
        internalCode: '353623',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sole Occupancy and Over 15,000 Square Feet Total Floor Area',
        cspCode: '0432',
        internalCode: '353631',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13351': [
    {
      classCode: {
        name: 'Furniture Stores - For Profit',
        cspCode: '0564',
        internalCode: '042697',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252500',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13453': [
    {
      classCode: {
        name: 'Auto Glass Replacement, Battery Replacement, Brake Adjustment, Tire Changing, Tune Ups, Oil Changing, Lubrications, Speedometer Adjustments and Similar Services',
        cspCode: '0932',
        internalCode: '014530',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Major Engine Repair or Body Repair',
        cspCode: '0933',
        internalCode: '014548',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'No Repair',
        cspCode: '0931',
        internalCode: '014522',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13454': [
    {
      classCode: {
        name: 'Gasoline Stations - Self-Service',
        cspCode: '0931',
        internalCode: '014571',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13455': [
    {
      classCode: {
        name: 'Auto Glass Replacement, Battery Replacement, Brake Adjustment, Tire Changing, Tune Ups, Oil Changing, Lubrications, Speedometer Adjustments and Similar Services',
        cspCode: '0932',
        internalCode: '354290',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Major Engine Repair or Body Repair',
        cspCode: '0933',
        internalCode: '354308',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'No Repair',
        cspCode: '0931',
        internalCode: '354287',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13506': [
    {
      classCode: {
        name: 'Gift Shops - For Profit',
        cspCode: '0567',
        internalCode: '014787',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252410',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13507': [
    {
      classCode: {
        name: 'Gift Shops - Not For Profit',
        cspCode: '0567',
        internalCode: '014795',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252411',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13590': [
    {
      classCode: {
        name: 'With No Grinding, Polishing Or Glazing - Or Limited To A Maximum Of 5 Employees Engaged In Such Activity',
        cspCode: '0567',
        internalCode: '014746',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With More Than 5 Employees Engaged In Grinding, Polishing Or Glazing',
        cspCode: '6009',
        internalCode: '014753',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252365',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13670': [
    {
      classCode: {
        name: 'Grocery Distributors - Sole Occupancy And Over 15,000 Square Feet',
        cspCode: '0432',
        internalCode: '015453',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Grocery Distributors NOC',
        cspCode: '0532',
        internalCode: '015446',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13673': [
    {
      classCode: {
        name: 'Grocery Stores - 4,000 Square Feet Or Over, Not Exceeding 15,000 Feet',
        cspCode: '0532',
        internalCode: '015479',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Grocery Stores - Less Than 4,000 Square Feet',
        cspCode: '0532',
        internalCode: '015461',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Grocery Stores - Over 15,000 Square Feet',
        cspCode: '0432',
        internalCode: '015487',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13715': [
    {
      classCode: {
        name: 'Hardware And Tool Distributors',
        cspCode: '0563',
        internalCode: '015651',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252412',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13716': [
    {
      classCode: {
        name: 'Hardware Stores',
        cspCode: '0563',
        internalCode: '015669',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252413',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13720': [
    {
      classCode: {
        name: 'Not Sole Occupancy And Less Than 15,000 Square Feet In Total Floor Area',
        cspCode: '0532',
        internalCode: '070615',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sole Occupancy And Over 15,000 In Total Floor Area',
        cspCode: '0432',
        internalCode: '070623',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13759': [
    {
      classCode: {
        name: 'Hearing Aid Stores',
        cspCode: '0567',
        internalCode: '016030',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252414',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '13930': [
    {
      classCode: {
        name: 'Heating Or Combined Heating And Air Conditioning Equipment - Dealers Or Distributors Only',
        cspCode: '0563',
        internalCode: '016048',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252415',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14101': [
    {
      classCode: {
        name: "Hobby, Craft Or Artists' Supply Stores",
        cspCode: '0567',
        internalCode: '070656',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252518',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14279': [
    {
      classCode: {
        name: 'Home Improvement Stores',
        cspCode: '0567',
        internalCode: '016352',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252417',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14401': [
    {
      classCode: {
        name: 'Ice Cream Stores - No Cooking',
        cspCode: '0532',
        internalCode: '017111',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Ice Cream Stores - With Cooking',
        cspCode: '0542',
        internalCode: '017129',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14405': [
    {
      classCode: {
        name: 'Ice Dealers And Distributors - Ice Houses Storage Only, No Mfg.',
        cspCode: '1230',
        internalCode: '017137',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Ice Dealers And Distributors - Ice Houses, Ice Mfg.',
        cspCode: '2000',
        internalCode: '017145',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252418',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14527': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '359257',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252419',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '354894',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '354886',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14655': [
    {
      classCode: {
        name: 'Jewelry Stores Or Distributors',
        cspCode: '0565',
        internalCode: '042879',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252504',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14731': [
    {
      classCode: {
        name: 'Laundries And Dry Cleaners - Self-Service',
        cspCode: '0913',
        internalCode: '018192',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14732': [
    {
      classCode: {
        name: 'Laundry And Dry Cleaning Or Dyeing Receiving Stations - No Pressing',
        cspCode: '0520',
        internalCode: '018325',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Laundry And Dry Cleaning Or Dyeing Receiving Stations - With Pressing',
        cspCode: '0911',
        internalCode: '018333',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14733': [
    {
      classCode: {
        name: 'Laundry And Dry Cleaning Stores',
        cspCode: '0913',
        internalCode: '018226',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14734': [
    {
      classCode: {
        name: 'Dry Cleaning',
        cspCode: '0911',
        internalCode: '018358',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Laundries',
        cspCode: '0912',
        internalCode: '018341',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Rental Service With No Laundry Or Dry Cleaning On Premises',
        cspCode: '0912',
        internalCode: '018366',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '14913': [
    {
      classCode: {
        name: 'Locksmiths',
        cspCode: '0922',
        internalCode: '018796',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252424',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15070': [
    {
      classCode: {
        name: 'Mail Box Or Packaging Stores',
        cspCode: '0921',
        internalCode: '070847',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15223': [
    {
      classCode: {
        name: 'Sole Occupancy And Over 15,000 Square Feet In Total Floor Area',
        cspCode: '0432',
        internalCode: '019836',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Meat, Fish, Poultry Or Seafood - Distributors NOC',
        cspCode: '0532',
        internalCode: '019844',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15224': [
    {
      classCode: {
        name: 'Meat, Fish, Poultry Or Seafood Stores',
        cspCode: '0532',
        internalCode: '019828',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15404': [
    {
      classCode: {
        name: 'Ingots, Pigs, Pipe, Bars, Rail, Rods, Plates, Sheets and Uninsulated Wire',
        cspCode: '0511',
        internalCode: '020313',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252434',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Metal Products',
        cspCode: '0567',
        internalCode: '020321',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15538': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252438',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Music Studios',
        cspCode: '0921',
        internalCode: '021428',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Musical Instrument Stores',
        cspCode: '0567',
        internalCode: '021410',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15600': [
    {
      classCode: {
        name: 'Nail Salons',
        cspCode: '0921',
        internalCode: '070896',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15607': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252439',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Newspaper Or Magazine Distributors',
        cspCode: '0567',
        internalCode: '021576',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15608': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252440',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Newsstands',
        cspCode: '0567',
        internalCode: '021584',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15699': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252441',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Nursery - Garden - Greenhouses',
        cspCode: '0580',
        internalCode: '021626',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Nursery - Garden - Sales Outlets',
        cspCode: '0570',
        internalCode: '021618',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15839': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252444',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Optical Goods Stores - Over 5 Employees Engaged In Grinding Or Polishing',
        cspCode: '6009',
        internalCode: '022525',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Optical Goods Stores NOC',
        cspCode: '0567',
        internalCode: '022517',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15991': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252445',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Paint, Wallpaper Or Wallcovering Stores',
        cspCode: '0570',
        internalCode: '022681',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '15993': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252446',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Painting, Picture Or Frame Stores',
        cspCode: '0570',
        internalCode: '022897',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16005': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252447',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Products NOC',
        cspCode: '0567',
        internalCode: '022988',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Paper Decorations, Paper-Mache, Sensitized Paper, Wall Paper Or Waxed Paper',
        cspCode: '0570',
        internalCode: '022970',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16402': [
    {
      classCode: {
        name: 'Pet Grooming',
        cspCode: '0921',
        internalCode: '023366',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16403': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252449',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Pet Stores',
        cspCode: '0570',
        internalCode: '023499',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16404': [
    {
      classCode: {
        name: 'Pet Training',
        cspCode: '0921',
        internalCode: '023507',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16471': [
    {
      classCode: {
        name: 'Photo, Photographer, Motion Picture Development',
        cspCode: '0921',
        internalCode: '054908',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Photographers',
        cspCode: '0921',
        internalCode: '042887',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16501': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252450',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic Or Rubber Supply Goods Distributors',
        cspCode: '0567',
        internalCode: '024125',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16527': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252451',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plumbing Supplies And Fixtures Dealers And Distributors',
        cspCode: '0563',
        internalCode: '024398',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16588': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252452',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Printers Or Electrotypers Supplies - Distributors',
        cspCode: '0567',
        internalCode: '024604',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16676': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252520',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Music Stores - Compact Discs, DVDs, Records & Tapes',
        cspCode: '0570',
        internalCode: '070862',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Music Stores - Pre-Recorded - Sheet Music',
        cspCode: '0567',
        internalCode: '070870',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16705': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0563',
        internalCode: '025924',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252454',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '025981',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Refrigeration Equipment - Dealers And Distributors Only - Commercial',
        cspCode: '0563',
        internalCode: '025916',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '357335',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357327',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16819': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '357400',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '357418',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '357426',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16820': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '041459',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '049601',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '041467',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16900': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '049619',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '049627',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '049635',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16901': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '049643',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '049650',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '049668',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16902': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '049676',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '049684',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '049692',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16910': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '049700',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '049718',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '049726',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16911': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '049734',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '049742',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '049759',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '16916': [
    {
      classCode: {
        name: 'With Cooking',
        cspCode: '0542',
        internalCode: '049791',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Limited Cooking',
        cspCode: '0545',
        internalCode: '049809',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Cooking',
        cspCode: '0532',
        internalCode: '049817',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18109': [
    {
      classCode: {
        name: 'Shoe Repair Shops',
        cspCode: '0922',
        internalCode: '028076',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Shoe Repair Shops',
        cspCode: '0921',
        internalCode: '028084',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18110': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252463',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'No Repair',
        cspCode: '0520',
        internalCode: '028092',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Repair',
        cspCode: '0922',
        internalCode: '028100',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18200': [
    {
      classCode: {
        name: 'Spas Or Personal Enhancement Facilities',
        cspCode: '0844',
        internalCode: '071241',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18205': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252464',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sporting Goods Or Athletic Equipment Distributors',
        cspCode: '0566',
        internalCode: '028753',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA'],
      },
    },
  ],
  '18206': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252465',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sporting Goods Or Athletic Equipment Stores',
        cspCode: '0566',
        internalCode: '028779',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18335': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252466',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Stationery Or Paper Products Stores',
        cspCode: '0567',
        internalCode: '028944',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18435': [
    {
      classCode: {
        name: 'If Not Sole Occupancy and Not Over 15,000 Sq. Ft. in Total Floor Area',
        cspCode: '0532',
        internalCode: '358549',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'If Sole Occupancy and Over 15,000 Sq. Ft. in Total Floor Area',
        cspCode: '0432',
        internalCode: '358515',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18436': [
    {
      classCode: {
        name: 'If Not Sole Occupancy and Not Over 15,000 Sq. Ft. in Total Floor Area',
        cspCode: '0532',
        internalCode: '029462',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'If Sole Occupancy and Over 15,000 Sq. Ft. in Total Floor Area',
        cspCode: '0432',
        internalCode: '029470',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18437': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252502',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Stores - No Food Or Drink NOC - For Profit',
        cspCode: '0567',
        internalCode: '042838',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18438': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252503',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Stores - No Food Or Drink NOC - Not For Profit',
        cspCode: '0567',
        internalCode: '042846',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18501': [
    {
      classCode: {
        name: 'Less Than 15,000 Square Feet In Total Floor Area',
        cspCode: '0532',
        internalCode: '047753',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sole Occupancy And More Than 15,000 Square Feet In Total Floor Area',
        cspCode: '0432',
        internalCode: '047761',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18507': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252470',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 10 Employees Engaged In Sewing Or Tailoring',
        cspCode: '3009',
        internalCode: '030189',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With 10 Employees Or Less Engaged In Custom Alterations Or Custom Tailoring Of Wearing Apparel After Sale To Customer',
        cspCode: '0520',
        internalCode: '030171',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18575': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252521',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '071415',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '358937',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '358929',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18616': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252472',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mercantile - Sole Occupancy Only - Tire, Battery and Accessory Dealers without Tire Recapping and Vulcanizing',
        cspCode: '0512',
        internalCode: '031104',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tire Changing Or Wheel Alignment',
        cspCode: '0932',
        internalCode: '031112',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18833': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252475',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Toy Distributors',
        cspCode: '0567',
        internalCode: '031476',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18834': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252476',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Toy Stores',
        cspCode: '0567',
        internalCode: '031484',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18911': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252507',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Variety Stores - Profit',
        cspCode: '0567',
        internalCode: '043281',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18912': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252508',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Variety Stores - Non-Profit',
        cspCode: '0567',
        internalCode: '043299',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18920': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252522',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Video Stores',
        cspCode: '0570',
        internalCode: '071621',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '18991': [
    {
      classCode: {
        name: 'Cold Storage',
        cspCode: '1230',
        internalCode: '360412',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Freight Terminals, Freight Depots, Including Airport, Railroad, Truck, Transfer Stations, etc.',
        cspCode: '1211',
        internalCode: '360453',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252484',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mini-Warehousing of Personal Property',
        cspCode: '1213',
        internalCode: '360461',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Public Warehouses NOC',
        cspCode: '1212',
        internalCode: '360487',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Whiskey and Liquor Warehouses and Whiskey and Liquor Warehouses in Connection with Distilleries',
        cspCode: '1450',
        internalCode: '360404',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Whiskey and Liquor Warehouses in Connection With Distilleries',
        cspCode: '1220',
        internalCode: '360438',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '19007': [
    {
      classCode: {
        name: 'Washing Machines, Dryers Or Ironers - Coin Meter Type',
        cspCode: '0913',
        internalCode: '032672',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '19051': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '032920',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '033001',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '359349',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '359331',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41603': [
    {
      classCode: {
        name: 'Cemeteries - All Other Buildings',
        cspCode: '1070',
        internalCode: '351726',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41604': [
    {
      classCode: {
        name: 'Cemeteries - All Other Buildings',
        cspCode: '1070',
        internalCode: '006056',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41650': [
    {
      classCode: {
        name: 'Churches and Synagogues',
        cspCode: '0900',
        internalCode: '006452',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Convents, Monasteries, Rectories and Sisters' Homes - 11 To 30 Units",
        cspCode: '0078',
        internalCode: '383943',
      },
      state: {
        display: 'IN',
        values: ['LA'],
      },
    },
    {
      classCode: {
        name: "Convents, Monasteries, Rectories and Sisters' Homes - Over 30 Units",
        cspCode: '0079',
        internalCode: '383950',
      },
      state: {
        display: 'IN',
        values: ['LA'],
      },
    },
    {
      classCode: {
        name: "Convents, Monasteries, Rectories and Sisters' Homes - Up To 10 Units",
        cspCode: '0077',
        internalCode: '383935',
      },
      state: {
        display: 'IN',
        values: ['LA'],
      },
    },
    {
      classCode: {
        name: 'Rectories - 11 - 30 Units',
        cspCode: '0078',
        internalCode: '383349',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Rectories - Over 30 Units',
        cspCode: '0079',
        internalCode: '383356',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Rectories - Up to 10 Units',
        cspCode: '0077',
        internalCode: '383331',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
  ],
  '41665': [
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: With Cooking',
        cspCode: '0755',
        internalCode: '361220',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: Without Cooking',
        cspCode: '0756',
        internalCode: '361238',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Clubs, NOC, Including Fraternal and Union Halls',
        cspCode: '0757',
        internalCode: '361246',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41667': [
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: With Cooking',
        cspCode: '0755',
        internalCode: '351809',
      },
      state: {
        display: 'NOT_IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: Without Cooking',
        cspCode: '0756',
        internalCode: '351817',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Clubs, NOC, Including Fraternal and Union Halls',
        cspCode: '0757',
        internalCode: '351825',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Fraternity and Sorority Houses - 11 - 30 Units',
        cspCode: '0075',
        internalCode: '383257',
      },
      state: {
        display: 'IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Fraternity and Sorority Houses - Over 30 Units',
        cspCode: '0076',
        internalCode: '383281',
      },
      state: {
        display: 'IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Fraternity and Sorority Houses - Up to 10 Units',
        cspCode: '0074',
        internalCode: '383224',
      },
      state: {
        display: 'IN',
        values: ['LA', 'MS'],
      },
    },
  ],
  '41668': [
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: With Cooking',
        cspCode: '0755',
        internalCode: '351775',
      },
      state: {
        display: 'NOT_IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: Without Cooking',
        cspCode: '0756',
        internalCode: '351783',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Clubs, NOC, Including Fraternal and Union Halls',
        cspCode: '0757',
        internalCode: '351791',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Fraternity and Sorority Houses - 11 - 30 Units',
        cspCode: '0075',
        internalCode: '383265',
      },
      state: {
        display: 'IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Fraternity and Sorority Houses - Over 30 Units',
        cspCode: '0076',
        internalCode: '383299',
      },
      state: {
        display: 'IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Fraternity and Sorority Houses - Up to 10 Units',
        cspCode: '0074',
        internalCode: '383232',
      },
      state: {
        display: 'IN',
        values: ['LA', 'MS'],
      },
    },
  ],
  '41669': [
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: With Cooking',
        cspCode: '0755',
        internalCode: '351866',
      },
      state: {
        display: 'NOT_IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: Without Cooking',
        cspCode: '0756',
        internalCode: '351874',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Clubs, NOC, Including Fraternal and Union Halls',
        cspCode: '0757',
        internalCode: '351882',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41670': [
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: With Cooking',
        cspCode: '0755',
        internalCode: '351833',
      },
      state: {
        display: 'NOT_IN',
        values: ['LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Clubs such as Golf Clubs, Tennis Clubs and Similar Sports Facilities: Without Cooking',
        cspCode: '0756',
        internalCode: '351841',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Clubs, NOC, Including Fraternal and Union Halls',
        cspCode: '0757',
        internalCode: '351858',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41675': [
    {
      classCode: {
        name: 'Computer Consulting Or Programming - Office',
        cspCode: '0702',
        internalCode: '071662',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41677': [
    {
      classCode: {
        name: 'Consultants - NOC - Office',
        cspCode: '0702',
        internalCode: '071720',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '41680': [
    {
      classCode: {
        name: 'Convents Or Monasteries - 11 To 30 Units',
        cspCode: '0078',
        internalCode: '383315',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Convents Or Monasteries - 11 To 30 Units',
        cspCode: '0078',
        internalCode: '008391',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Convents Or Monasteries - Over 30 Units',
        cspCode: '0079',
        internalCode: '383323',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Convents Or Monasteries - Over 30 Units',
        cspCode: '0079',
        internalCode: '008409',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Convents Or Monasteries - Up To 10 Units',
        cspCode: '0077',
        internalCode: '383307',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Convents Or Monasteries - Up To 10 Units',
        cspCode: '0077',
        internalCode: '008383',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: "Convents, Monasteries, Rectories and Sisters' Homes - 11 To 30 Units",
        cspCode: '0078',
        internalCode: '383919',
      },
      state: {
        display: 'IN',
        values: ['LA'],
      },
    },
    {
      classCode: {
        name: "Convents, Monasteries, Rectories and Sisters' Homes - Over 30 Units",
        cspCode: '0079',
        internalCode: '383927',
      },
      state: {
        display: 'IN',
        values: ['LA'],
      },
    },
    {
      classCode: {
        name: "Convents, Monasteries, Rectories and Sisters' Homes - Up To 10 Units",
        cspCode: '0077',
        internalCode: '383901',
      },
      state: {
        display: 'IN',
        values: ['LA'],
      },
    },
    {
      classCode: {
        name: 'Sisters Homes - 11 - 30 Units',
        cspCode: '0078',
        internalCode: '383794',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Sisters Homes - Over 30 Units',
        cspCode: '0079',
        internalCode: '383802',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Sisters Homes - Up to 10 Units',
        cspCode: '0077',
        internalCode: '383786',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
  ],
  '41696': [
    {
      classCode: {
        name: 'Crematories - Profit',
        cspCode: '0921',
        internalCode: '043208',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '43215': [
    {
      classCode: {
        name: "Entertainment Performed On Others' Premises",
        cspCode: '0832',
        internalCode: '070516',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '43889': [
    {
      classCode: {
        name: 'Funeral Homes Or Chapels',
        cspCode: '0923',
        internalCode: '013482',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '44069': [
    {
      classCode: {
        name: 'Golf Courses - Miniature - Enclosed Buildings Only',
        cspCode: '0844',
        internalCode: '014761',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '44280': [
    {
      classCode: {
        name: 'Event, Party, or Wedding Planners',
        cspCode: '0702',
        internalCode: '361659',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '45190': [
    // 'Hotels And Motels - With Pools Or Beaches - Less Than Four Stories
    {
      classCode: {
        name: 'With Restaurants: 11 to 30 Units',
        cspCode: '0743',
        internalCode: '016626',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Over 30 Units',
        cspCode: '0744',
        internalCode: '016634',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Up to 10 Units',
        cspCode: '0742',
        internalCode: '016618',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: 11 to 30 Units',
        cspCode: '0746',
        internalCode: '016659',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Over 30 Units',
        cspCode: '0747',
        internalCode: '016667',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Up to 10 Units',
        cspCode: '0745',
        internalCode: '016642',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
  ],
  '45191': [
    {
      classCode: {
        name: 'With Restaurants: 11 to 30 Units',
        cspCode: '0743',
        internalCode: '016683',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Over 30 Units',
        cspCode: '0744',
        internalCode: '016691',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Up to 10 Units',
        cspCode: '0742',
        internalCode: '016675',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: 11 to 30 Units',
        cspCode: '0746',
        internalCode: '016717',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Over 30 Units',
        cspCode: '0747',
        internalCode: '016725',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Up to 10 Units',
        cspCode: '0745',
        internalCode: '016709',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
  ],
  '45192': [
    {
      classCode: {
        name: 'With Restaurants: 11 to 30 Units',
        cspCode: '0743',
        internalCode: '354480',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Over 30 Units',
        cspCode: '0744',
        internalCode: '354498',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Up to 10 Units',
        cspCode: '0742',
        internalCode: '354472',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: 11 to 30 Units',
        cspCode: '0746',
        internalCode: '354514',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Over 30 Units',
        cspCode: '0747',
        internalCode: '354522',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Up to 10 Units',
        cspCode: '0745',
        internalCode: '354506',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
  ],
  '45193': [
    {
      classCode: {
        name: 'With Restaurants: 11 to 30 Units',
        cspCode: '0743',
        internalCode: '354548',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Over 30 Units',
        cspCode: '0744',
        internalCode: '354555',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Up to 10 Units',
        cspCode: '0742',
        internalCode: '354530',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: 11 to 30 Units',
        cspCode: '0746',
        internalCode: '354571',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Over 30 Units',
        cspCode: '0747',
        internalCode: '354589',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Up to 10 Units',
        cspCode: '0745',
        internalCode: '354563',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
  ],
  '45334': [
    {
      classCode: {
        name: 'Insurance Agents - Offices',
        cspCode: '0702',
        internalCode: '017533',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '45450': [
    {
      classCode: {
        name: 'Kennels - Boarding Or Breeding',
        cspCode: '0922',
        internalCode: '017848',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Kennels - Sales',
        cspCode: '0570',
        internalCode: '017830',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '45678': [
    {
      classCode: {
        name: 'Laundries And Dry Cleaning Plants - Commercial Laundries',
        cspCode: '0912',
        internalCode: '018309',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Laundries And Dry Cleaning Plants - Dry Cleaning Including Dyeing',
        cspCode: '0911',
        internalCode: '018317',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '45901': [
    {
      classCode: {
        name: 'Mail Order Houses',
        cspCode: '0567',
        internalCode: '019349',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252430',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '45937': [
    {
      classCode: {
        name: 'Mailing Or Addressing Companies',
        cspCode: '0921',
        internalCode: '019356',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '45993': [
    {
      classCode: {
        name: 'Commission Merchants - Produce',
        cspCode: '0532',
        internalCode: '355776',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252431',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '355768',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '46202': [
    {
      classCode: {
        name: 'Community Centers, Recreational Buildings, Shelter Houses, Locker Houses And Other Auxiliary Or Subsidiary Buildings',
        cspCode: '0844',
        internalCode: '020966',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Laundries For Tenants Only',
        cspCode: '0922',
        internalCode: '020941',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '46426': [
    {
      classCode: {
        name: 'Commercial',
        cspCode: '0567',
        internalCode: '021402',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Than Commercial',
        cspCode: '1051',
        internalCode: '021394',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '46427': [
    {
      classCode: {
        name: 'Commercial',
        cspCode: '0567',
        internalCode: '356410',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Than Commercial',
        cspCode: '1051',
        internalCode: '356402',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '46881': [
    {
      classCode: {
        name: 'Professional And Trade Associations - No Building Or Premises Owned Or Leased Except As Offices - For Profit',
        cspCode: '0702',
        internalCode: '042317',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '46882': [
    {
      classCode: {
        name: 'Professional And Trade Associations - No Building Or Premises Owned Or Leased Except As Offices - Not For Profit',
        cspCode: '0702',
        internalCode: '042325',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '47050': [
    {
      classCode: {
        name: 'Real Estate Agents - Offices',
        cspCode: '0702',
        internalCode: '025510',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '47103': [
    {
      classCode: {
        name: 'Recording Studios',
        cspCode: '0832',
        internalCode: '025494',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '47367': [
    {
      classCode: {
        name: 'Sales Or Service Organization - Office',
        cspCode: '0702',
        internalCode: '071167',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '48600': [
    {
      classCode: {
        name: 'Social Services - Consulting Services Only - Operated By The Private Sector - Office',
        cspCode: '0702',
        internalCode: '071217',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '49005': [
    {
      classCode: {
        name: 'Taxidermists',
        cspCode: '0922',
        internalCode: '030437',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '49617': [
    {
      classCode: {
        name: 'Repair, With or Without Sales or Storage',
        cspCode: '0922',
        internalCode: '032409',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales With or Without Storage, No Repair',
        cspCode: '0563',
        internalCode: '032391',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '49619': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252477',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair, With or Without Sales or Storage',
        cspCode: '0922',
        internalCode: '032300',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales With or Without Storage, No Repair',
        cspCode: '0563',
        internalCode: '032292',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '49840': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252492',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Offices',
        cspCode: '0702',
        internalCode: '033514',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales And/Or Storage',
        cspCode: '0567',
        internalCode: '033449',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51116': [
    {
      classCode: {
        name: 'Heavy Metal Parts',
        cspCode: '6810',
        internalCode: '000273',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Metal Parts NOC',
        cspCode: '6850',
        internalCode: '000299',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic Parts',
        cspCode: '5500',
        internalCode: '000307',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Precision Parts',
        cspCode: '6900',
        internalCode: '000281',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51221': [
    {
      classCode: {
        name: 'Commercial - Not Gas',
        cspCode: '6850',
        internalCode: '001677',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51224': [
    {
      classCode: {
        name: 'Household - Not Gas',
        cspCode: '6850',
        internalCode: '001693',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51315': [
    {
      classCode: {
        name: 'Bakery Plants',
        cspCode: '2200',
        internalCode: '002824',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51355': [
    {
      classCode: {
        name: 'Beverage Bottler - Soft Drinks - Carbonated - In Cans Or Plastic Bottles',
        cspCode: '2350',
        internalCode: '003210',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51356': [
    {
      classCode: {
        name: 'Beverage Bottler - Soft Drinks - In Glass Bottles',
        cspCode: '2350',
        internalCode: '003228',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51357': [
    {
      classCode: {
        name: 'Beverage Bottler - Soft Drinks - In Metal Cylinders',
        cspCode: '2350',
        internalCode: '003236',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51358': [
    {
      classCode: {
        name: 'Beverage Bottler - Soft Drinks - In Paper Containers',
        cspCode: '2350',
        internalCode: '003244',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51359': [
    {
      classCode: {
        name: 'Beverage Bottler - Soft Drinks - Not Carbonated - In Bottles Or Cans',
        cspCode: '2350',
        internalCode: '003251',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51500': [
    {
      classCode: {
        name: 'Bolt, Nut, Rivet, Screw Or Washer Mfg.',
        cspCode: '6850',
        internalCode: '004101',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51516': [
    {
      classCode: {
        name: 'Bookbinding - For Profit',
        cspCode: '4809',
        internalCode: '041905',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51553': [
    {
      classCode: {
        name: 'Bottle And Jar Mfg. - Plastic - Nonreturnable',
        cspCode: '5500',
        internalCode: '004275',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51554': [
    {
      classCode: {
        name: 'Bottle And Jar Mfg. - Plastic - Returnable',
        cspCode: '5500',
        internalCode: '004283',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51752': [
    {
      classCode: {
        name: 'Candy Or Confectionery Products Mfg.',
        cspCode: '2200',
        internalCode: '005421',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51869': [
    {
      classCode: {
        name: 'China, Porcelain Or Earthenware Mfg.',
        cspCode: '6009',
        internalCode: '006445',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51877': [
    {
      classCode: {
        name: 'Clay Products Mfg.',
        cspCode: '6009',
        internalCode: '006478',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51896': [
    {
      classCode: {
        name: "Children's and Infants' Wear",
        cspCode: '3009',
        internalCode: '006619',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Clothing - Ladies and Girls (Coats, Suits and Dresses)',
        cspCode: '3009',
        internalCode: '006627',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Furs (Including Pelts)',
        cspCode: '3009',
        internalCode: '006635',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Haberdashery and Men's Furnishings",
        cspCode: '3009',
        internalCode: '006643',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Hosiery',
        cspCode: '3009',
        internalCode: '006650',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Ladies' Specialty Stores",
        cspCode: '3009',
        internalCode: '351734',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Ladies' Undergarments and Lingerie",
        cspCode: '3009',
        internalCode: '006676',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Leather',
        cspCode: '3009',
        internalCode: '006734',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Men's and Boy's Clothing (Coats & Suits)",
        cspCode: '3009',
        internalCode: '006692',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Men's and Boy's Hats and Caps",
        cspCode: '3009',
        internalCode: '006684',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Millinery and Trimmings',
        cspCode: '3009',
        internalCode: '006700',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: "Shoes - Ladies', Men's and Children's",
        cspCode: '3009',
        internalCode: '006718',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Wigs',
        cspCode: '3009',
        internalCode: '006726',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51919': [
    {
      classCode: {
        name: 'High Hazard (Rapid Burning)',
        cspCode: '5100',
        internalCode: '007047',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Low Hazard (Non-Combustible)',
        cspCode: '5000',
        internalCode: '007021',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Moderate Hazard (Combustible or Free Burning)',
        cspCode: '5050',
        internalCode: '007039',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51926': [
    {
      classCode: {
        name: 'Electronic Components',
        cspCode: '6900',
        internalCode: '007070',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Heavy Metal',
        cspCode: '6810',
        internalCode: '007088',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Metal',
        cspCode: '6850',
        internalCode: '007096',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic Parts',
        cspCode: '5500',
        internalCode: '007104',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51927': [
    {
      classCode: {
        name: 'Electronic Components',
        cspCode: '6900',
        internalCode: '352070',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Heavy Metal',
        cspCode: '6810',
        internalCode: '352088',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Metal',
        cspCode: '6850',
        internalCode: '352096',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic Parts',
        cspCode: '5500',
        internalCode: '352104',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '51957': [
    {
      classCode: {
        name: 'Concrete Or Plaster Products Mfg. - Not Structural',
        cspCode: '6009',
        internalCode: '352054',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52002': [
    {
      classCode: {
        name: 'Dairy Products Mfg.',
        cspCode: '2000',
        internalCode: '008813',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52134': [
    {
      classCode: {
        name: 'Metal',
        cspCode: '6850',
        internalCode: '353052',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vinyl',
        cspCode: '5500',
        internalCode: '353045',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52432': [
    {
      classCode: {
        name: 'Electrical Equipment Mfg. - Other Than For Direct & Indirect Application To The Body',
        cspCode: '6900',
        internalCode: '010439',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52438': [
    {
      classCode: {
        name: 'Electrical Parts',
        cspCode: '6850',
        internalCode: '010561',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Electronic Components',
        cspCode: '6900',
        internalCode: '010579',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52467': [
    {
      classCode: {
        name: 'Electrical Wire Or Cable Mfg.',
        cspCode: '6850',
        internalCode: '010488',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52469': [
    {
      classCode: {
        name: 'Electronic Components Mfg.',
        cspCode: '6900',
        internalCode: '010736',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52505': [
    {
      classCode: {
        name: 'Electronic Games Mfg. - Electronic Components',
        cspCode: '6900',
        internalCode: '010868',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Electronic Games Mfg. - Metal Parts, Heavy',
        cspCode: '6810',
        internalCode: '010876',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Electronic Games Mfg. - Metal Parts, Light',
        cspCode: '6850',
        internalCode: '010884',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Electronic Games Mfg. - Plastic Parts',
        cspCode: '5500',
        internalCode: '010892',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52660': [
    {
      classCode: {
        name: 'Engraving - Other Than Photo Engraving',
        cspCode: '0922',
        internalCode: '011197',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Engraving - Photo Engraving',
        cspCode: '4809',
        internalCode: '011189',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '52967': [
    {
      classCode: {
        name: 'Glass',
        cspCode: '6009',
        internalCode: '353458',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic',
        cspCode: '5500',
        internalCode: '353441',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '53374': [
    {
      classCode: {
        name: 'Fish Oils Mfg',
        cspCode: '2250',
        internalCode: '012781',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With Flammables',
        cspCode: '5050',
        internalCode: '012856',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With No Flammables',
        cspCode: '2300',
        internalCode: '012849',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Food Products (Including Retail Bakeries) - No Baking or Cooking',
        cspCode: '0532',
        internalCode: '012922',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Food Products (Other Than Bakeries) - Limited Cooking.',
        cspCode: '0534',
        internalCode: '048843',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Food Products NOC',
        cspCode: '2600',
        internalCode: '012906',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Fruits Processing',
        cspCode: '2250',
        internalCode: '012799',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Malt Mills Other Than Breweries',
        cspCode: '2400',
        internalCode: '012898',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Meat Processing',
        cspCode: '2059',
        internalCode: '012831',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mercantile - Sole occupant - Food Products - Large area (over 15,000 sq. ft.) - Not class rated',
        cspCode: '0432',
        internalCode: '012914',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Molasses, Sugar, Honey and Syrup Mfg.',
        cspCode: '2300',
        internalCode: '012864',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Nut Processing',
        cspCode: '2250',
        internalCode: '012815',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Seafood Products',
        cspCode: '2059',
        internalCode: '012773',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Spices Processing',
        cspCode: '2250',
        internalCode: '012823',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tobacco Products',
        cspCode: '2550',
        internalCode: '012872',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vegetables Processing',
        cspCode: '2250',
        internalCode: '012807',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Yeast Factories',
        cspCode: '2400',
        internalCode: '012880',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '53375': [
    {
      classCode: {
        name: 'Fish Oils Mfg',
        cspCode: '2250',
        internalCode: '359687',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With Flammables',
        cspCode: '5050',
        internalCode: '359752',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With No Flammables',
        cspCode: '2300',
        internalCode: '359745',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Food Products (Including Retail Bakeries) - No Baking or Cooking',
        cspCode: '0532',
        internalCode: '359828',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Food Products (Other Than Bakeries) - Limited Cooking.',
        cspCode: '0534',
        internalCode: '359836',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Food Products NOC',
        cspCode: '2600',
        internalCode: '359802',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Food Products Sales, Storage and Distributors: Sole Occupant and Over 15,000 Sq. Ft.',
        cspCode: '0432',
        internalCode: '359810',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Fruits Processing',
        cspCode: '2250',
        internalCode: '359695',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Malt Mills Other Than Breweries',
        cspCode: '2400',
        internalCode: '359794',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Meat Processing',
        cspCode: '2059',
        internalCode: '359737',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Molasses, Sugar, Honey and Syrup Mfg.',
        cspCode: '2300',
        internalCode: '359760',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Nut Processing',
        cspCode: '2250',
        internalCode: '359711',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Seafood Products',
        cspCode: '2059',
        internalCode: '359679',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Spices Processing',
        cspCode: '2250',
        internalCode: '359729',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tobacco Products',
        cspCode: '2550',
        internalCode: '359778',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vegetables Processing',
        cspCode: '2250',
        internalCode: '359703',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Yeast Factories',
        cspCode: '2400',
        internalCode: '359786',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '53376': [
    {
      classCode: {
        name: 'Fish Oils Mfg',
        cspCode: '2250',
        internalCode: '359851',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With Flammables',
        cspCode: '5050',
        internalCode: '359927',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With No Flammables',
        cspCode: '2300',
        internalCode: '359919',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Food Products (Including Retail Bakeries) - No Baking or Cooking',
        cspCode: '0532',
        internalCode: '359992',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Food Products (Other Than Bakeries) - Limited Cooking.',
        cspCode: '0534',
        internalCode: '360008',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Food Products NOC',
        cspCode: '2600',
        internalCode: '359976',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Food Products Sales, Storage and Distributors: Sole Occupant and Over 15,000 Sq. Ft.',
        cspCode: '0432',
        internalCode: '359984',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Fruits Processing',
        cspCode: '2250',
        internalCode: '359869',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Malt Mills Other Than Breweries',
        cspCode: '2400',
        internalCode: '359968',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Meat Processing',
        cspCode: '2059',
        internalCode: '359901',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Molasses, Sugar, Honey and Syrup Mfg.',
        cspCode: '2300',
        internalCode: '359935',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Nut Processing',
        cspCode: '2250',
        internalCode: '359885',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Seafood Products',
        cspCode: '2059',
        internalCode: '359844',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Spices Processing',
        cspCode: '2250',
        internalCode: '359893',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tobacco Products',
        cspCode: '2550',
        internalCode: '359943',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vegetables Processing',
        cspCode: '2250',
        internalCode: '359877',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Yeast Factories',
        cspCode: '2400',
        internalCode: '359950',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '53377': [
    {
      classCode: {
        name: 'Fish Oils Mfg',
        cspCode: '2250',
        internalCode: '360024',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With Flammables',
        cspCode: '5050',
        internalCode: '360099',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Flavor Extracts Mfg. - With No Flammables',
        cspCode: '2300',
        internalCode: '360081',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Food Products (Including Retail Bakeries) - No Baking or Cooking',
        cspCode: '0532',
        internalCode: '360164',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Food Products (Other Than Bakeries) - Limited Cooking.',
        cspCode: '0534',
        internalCode: '360172',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Food Products NOC',
        cspCode: '2600',
        internalCode: '360149',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Food Products Sales, Storage and Distributors: Sole Occupant and Over 15,000 Sq. Ft.',
        cspCode: '0432',
        internalCode: '360156',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Fruits Processing',
        cspCode: '2250',
        internalCode: '360032',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Malt Mills Other Than Breweries',
        cspCode: '2400',
        internalCode: '360131',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Meat Processing',
        cspCode: '2059',
        internalCode: '360073',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Molasses, Sugar, Honey and Syrup Mfg.',
        cspCode: '2300',
        internalCode: '360107',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
    {
      classCode: {
        name: 'Nut Processing',
        cspCode: '2250',
        internalCode: '360057',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Seafood Products',
        cspCode: '2059',
        internalCode: '360016',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Spices Processing',
        cspCode: '2250',
        internalCode: '360065',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tobacco Products',
        cspCode: '2550',
        internalCode: '360115',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vegetables Processing',
        cspCode: '2250',
        internalCode: '360040',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Yeast Factories',
        cspCode: '2400',
        internalCode: '360123',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '53565': [
    {
      classCode: {
        name: 'Fruit Or Vegetable Juice Mfg. - No Bottling Of Carbonated Beverages',
        cspCode: '2250',
        internalCode: '013276',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '53732': [
    {
      classCode: {
        name: 'Metal',
        cspCode: '6850',
        internalCode: '353672',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic',
        cspCode: '5500',
        internalCode: '353664',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '54077': [
    {
      classCode: {
        name: 'Glass Or Glassware Manufacturing',
        cspCode: '6009',
        internalCode: '014696',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '55371': [
    {
      classCode: {
        name: 'Honey Extracting',
        cspCode: '2300',
        internalCode: '016543',
      },
      state: {
        display: 'NOT_IN',
        values: ['FL'],
      },
    },
  ],
  '55802': [
    {
      classCode: {
        name: 'Jewelry Mfg.',
        cspCode: '6900',
        internalCode: '017749',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56041': [
    {
      classCode: {
        name: 'Lamps Or Lanterns Mfg. - Electric',
        cspCode: '6850',
        internalCode: '018028',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56042': [
    {
      classCode: {
        name: 'Lamps Or Lanterns Mfg. - Other Than Electric',
        cspCode: '6850',
        internalCode: '018010',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56202': [
    {
      classCode: {
        name: 'Finished Leather Products Storage Or Sales',
        cspCode: '0567',
        internalCode: '018572',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Leather Goods - Raw Stock Or Unfinished Skins Not On Premises Of Manufacturer',
        cspCode: '3409',
        internalCode: '018598',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Leather Goods Mfg.',
        cspCode: '3409',
        internalCode: '018556',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Leather Products Or Hide Stores',
        cspCode: '0567',
        internalCode: '018614',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252421',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56390': [
    {
      classCode: {
        name: 'Light Bulb Or Tubes Mfg.',
        cspCode: '6009',
        internalCode: '018515',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56391': [
    {
      classCode: {
        name: 'Lighting Fixtures Mfg. NOC',
        cspCode: '6850',
        internalCode: '018523',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56758': [
    {
      classCode: {
        name: 'Meat, Fish, Poultry Or Seafood - Curing',
        cspCode: '2059',
        internalCode: '019786',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56759': [
    {
      classCode: {
        name: 'Meat, Fish, Poultry Or Seafood Processing - In Airtight Containers',
        cspCode: '2059',
        internalCode: '019802',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '56760': [
    {
      classCode: {
        name: 'Meat, Fish, Poultry Or Seafood Processing - Not In Airtight Containers',
        cspCode: '2059',
        internalCode: '019810',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57257': [
    {
      classCode: {
        name: 'Musical Instrument Mfg.',
        cspCode: '6900',
        internalCode: '021378',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57410': [
    {
      classCode: {
        name: 'Net Mfg - Other Than Safety Nets',
        cspCode: '2800',
        internalCode: '021550',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57572': [
    {
      classCode: {
        name: 'Office Machines Mfg.',
        cspCode: '6900',
        internalCode: '021733',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57600': [
    {
      classCode: {
        name: 'Optical Goods Manufacturing',
        cspCode: '6009',
        internalCode: '022343',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57716': [
    {
      classCode: {
        name: 'Paper Coating Or Finishing',
        cspCode: '4450',
        internalCode: '022905',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57808': [
    {
      classCode: {
        name: 'Pattern Mfg - Metal',
        cspCode: '6850',
        internalCode: '023309',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57871': [
    {
      classCode: {
        name: 'Other than Wood or Plastic',
        cspCode: '6009',
        internalCode: '356766',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic',
        cspCode: '5500',
        internalCode: '356758',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Wood',
        cspCode: '3959',
        internalCode: '356741',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57913': [
    {
      classCode: {
        name: 'Pet Food Mfg.',
        cspCode: '2059',
        internalCode: '023358',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57997': [
    {
      classCode: {
        name: 'Photo Finishing Laboratories',
        cspCode: '0921',
        internalCode: '023515',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '57998': [
    {
      classCode: {
        name: 'Metal Parts NOC',
        cspCode: '6850',
        internalCode: '023564',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic Parts',
        cspCode: '5500',
        internalCode: '023572',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Precision Metal Parts',
        cspCode: '6900',
        internalCode: '023556',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58009': [
    {
      classCode: {
        name: 'Pipes Or Tubes Mfg. - Metal',
        cspCode: '6850',
        internalCode: '024067',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58057': [
    {
      classCode: {
        name: 'Plastic Or Rubber Goods Mfg. - Household NOC',
        cspCode: '5500',
        internalCode: '024109',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58095': [
    {
      classCode: {
        name: 'Plumbing Fixtures Mfg.',
        cspCode: '6850',
        internalCode: '024380',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58096': [
    {
      classCode: {
        name: 'Plumbing Supplies Mfg. NOC',
        cspCode: '6850',
        internalCode: '024406',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58408': [
    {
      classCode: {
        name: 'Addressing, Blueprinting and Duplicating Services',
        cspCode: '0921',
        internalCode: '357251',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Printing Services',
        cspCode: '4809',
        internalCode: '357269',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58409': [
    {
      classCode: {
        name: 'Addressing, Blueprinting and Duplicating Services',
        cspCode: '0921',
        internalCode: '024661',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Other Printing Services',
        cspCode: '4809',
        internalCode: '024679',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58456': [
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - For Profit',
        cspCode: '4809',
        internalCode: '042333',
      },
      state: {
        display: 'IN',
        values: ['CA'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - For Profit - IP',
        cspCode: '4809',
        internalCode: '255654',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - For Profit - IP',
        cspCode: '4809',
        internalCode: '253542',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines-For Profit-Service',
        cspCode: '4809',
        internalCode: '255650',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines-For Profit-Service',
        cspCode: '4809',
        internalCode: '253541',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
  ],
  '58457': [
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - Not For Profit',
        cspCode: '4809',
        internalCode: '042341',
      },
      state: {
        display: 'IN',
        values: ['CA'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - Not For Profit - IP',
        cspCode: '4809',
        internalCode: '255655',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - Not For Profit - IP',
        cspCode: '4809',
        internalCode: '253544',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - Not For Profit - Service',
        cspCode: '4809',
        internalCode: '255651',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Books Or Magazines - Not For Profit - Service',
        cspCode: '4809',
        internalCode: '253543',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
  ],
  '58458': [
    {
      classCode: {
        name: 'Publishers - Newspapers - For Profit',
        cspCode: '4809',
        internalCode: '042358',
      },
      state: {
        display: 'IN',
        values: ['CA'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - For Profit - IP',
        cspCode: '4809',
        internalCode: '255656',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - For Profit - IP',
        cspCode: '4809',
        internalCode: '253546',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - For Profit - Service',
        cspCode: '4809',
        internalCode: '255652',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - For Profit - Service',
        cspCode: '4809',
        internalCode: '253545',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
  ],
  '58459': [
    {
      classCode: {
        name: 'Publishers - Newspapers - Not For Profit',
        cspCode: '4809',
        internalCode: '042366',
      },
      state: {
        display: 'IN',
        values: ['CA'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - Not For Profit- IP',
        cspCode: '4809',
        internalCode: '255657',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - Not For Profit- IP',
        cspCode: '4809',
        internalCode: '253548',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - Not For Profit- Service',
        cspCode: '4809',
        internalCode: '255653',
      },
      state: {
        display: 'IN',
        values: ['MS'],
      },
    },
    {
      classCode: {
        name: 'Publishers - Newspapers - Not For Profit- Service',
        cspCode: '4809',
        internalCode: '253547',
      },
      state: {
        display: 'NOT_IN',
        values: ['CA', 'MS'],
      },
    },
  ],
  '58627': [
    {
      classCode: {
        name: 'Media Mfg. - prerecorded',
        cspCode: '5500',
        internalCode: '361675',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58663': [
    {
      classCode: {
        name: 'Refrigeration Equipment Mfg',
        cspCode: '6850',
        internalCode: '025858',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58759': [
    {
      classCode: {
        name: 'Rubber Stamp Mfg.',
        cspCode: '5759',
        internalCode: '026617',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Rubber Stamp Preparation',
        cspCode: '0922',
        internalCode: '026625',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58903': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252460',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Metal Parts',
        cspCode: '6850',
        internalCode: '027805',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic Parts',
        cspCode: '5500',
        internalCode: '027821',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Precision Parts',
        cspCode: '6900',
        internalCode: '027813',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales Outlets and Storage Buildings Not On Premises of Manufacturer',
        cspCode: '0567',
        internalCode: '027839',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '58922': [
    {
      classCode: {
        name: 'Sheet Metal Work - Shop Only',
        cspCode: '6850',
        internalCode: '027961',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59005': [
    {
      classCode: {
        name: 'Shoe, Boot Or Slipper Mfg.',
        cspCode: '3409',
        internalCode: '028001',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59057': [
    {
      classCode: {
        name: 'Metal',
        cspCode: '6850',
        internalCode: '358085',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic',
        cspCode: '5500',
        internalCode: '358077',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Wood',
        cspCode: '3959',
        internalCode: '358069',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59058': [
    {
      classCode: {
        name: 'Metal',
        cspCode: '6850',
        internalCode: '358119',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic',
        cspCode: '5500',
        internalCode: '358101',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Wood',
        cspCode: '3959',
        internalCode: '358093',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59223': [
    {
      classCode: {
        name: 'Soap Mfg.',
        cspCode: '5000',
        internalCode: '028712',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59482': [
    {
      classCode: {
        name: 'Stone Crushing, Cutting or Polishing',
        cspCode: '6009',
        internalCode: '358366',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Stone Cutting Or Polishing - Quarrying',
        cspCode: '6210',
        internalCode: '358374',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59781': [
    {
      classCode: {
        name: 'Tool Mfg. - Plastic Parts',
        cspCode: '5500',
        internalCode: '031526',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tool Mfg. - Precision Parts',
        cspCode: '6900',
        internalCode: '031518',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tool Mfg. NOC',
        cspCode: '6850',
        internalCode: '031500',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59782': [
    {
      classCode: {
        name: 'Tool Mfg. - Hand Type - Not Powered - NOC',
        cspCode: '6850',
        internalCode: '041756',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tool Mfg. - Hand Type - Not Powered - Plastic Parts Manufacturing',
        cspCode: '5500',
        internalCode: '041772',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Tool Mfg. - Hand Type - Not Powered - Precision Parts Manufacturing',
        cspCode: '6900',
        internalCode: '041764',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59889': [
    {
      classCode: {
        name: 'Umbrella Or Cane Mfg.',
        cspCode: '3009',
        internalCode: '031849',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59905': [
    {
      classCode: {
        name: 'Vegetable Oil Mfg. NOC',
        cspCode: '2250',
        internalCode: '032060',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59914': [
    {
      classCode: {
        name: 'All Other Metalworking',
        cspCode: '6850',
        internalCode: '020578',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Heavy Metalworking',
        cspCode: '6810',
        internalCode: '020552',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Precision Products',
        cspCode: '6900',
        internalCode: '020560',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59915': [
    {
      classCode: {
        name: 'Vending Machines Mfg.',
        cspCode: '6900',
        internalCode: '032268',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59917': [
    {
      classCode: {
        name: 'Metal',
        cspCode: '6850',
        internalCode: '359281',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Plastic',
        cspCode: '5500',
        internalCode: '359273',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Wood',
        cspCode: '3959',
        internalCode: '359265',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59923': [
    {
      classCode: {
        name: 'Watch Or Watch Case Mfg. - Watches Only',
        cspCode: '6900',
        internalCode: '032789',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59963': [
    {
      classCode: {
        name: 'Wine Mfg. - Sparkling',
        cspCode: '2459',
        internalCode: '033365',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59964': [
    {
      classCode: {
        name: 'Wine Mfg. - Still',
        cspCode: '2459',
        internalCode: '033373',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '59975': [
    {
      classCode: {
        name: 'Wire Goods Mfg. NOC',
        cspCode: '6850',
        internalCode: '033571',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '60010': [
    {
      classCode: {
        name: 'Apartments With Mercantile - 11 - 30 Units',
        cspCode: '0322',
        internalCode: '040154',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments With Mercantile - Over 30 Units',
        cspCode: '0323',
        internalCode: '040121',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments With Mercantile - Up to 10 Units',
        cspCode: '0321',
        internalCode: '040188',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments Without Mercantile - 11 - 30 Units',
        cspCode: '0312',
        internalCode: '001313',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments Without Mercantile - Over 30 Units',
        cspCode: '0313',
        internalCode: '001321',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments Without Mercantile - Up to 10 Units',
        cspCode: '0311',
        internalCode: '001305',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '60011': [
    {
      classCode: {
        name: 'Apartments With Mercantile - 11 - 30 Units',
        cspCode: '0322',
        internalCode: '350157',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments With Mercantile - Over 30 Units',
        cspCode: '0323',
        internalCode: '350165',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments With Mercantile - Up to 10 Units',
        cspCode: '0321',
        internalCode: '350140',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments Without Mercantile - 11 - 30 Units',
        cspCode: '0312',
        internalCode: '350124',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments Without Mercantile - Over 30 Units',
        cspCode: '0313',
        internalCode: '350132',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Apartments Without Mercantile - Up to 10 Units',
        cspCode: '0311',
        internalCode: '350116',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61212': [
    {
      classCode: {
        name: '15,000 Sq Ft or Less: Buildings or Premises - Mercantile - NOC multiple occupancy - building only - not class rated',
        cspCode: '0434',
        internalCode: '253614',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '253612',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 15,000 Sq Ft: Buildings or Premises - Mercantile - retail or wholesale NOC - multiple occupancy - building only - not class rated',
        cspCode: '0433',
        internalCode: '253613',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61216': [
    {
      classCode: {
        name: '15,000 Sq Ft or Less: Buildings or Premises - Mercantile - NOC multiple occupancy - building only - not class rated',
        cspCode: '0434',
        internalCode: '253617',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '253615',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 15,000 Sq Ft: Buildings or Premises - Mercantile - retail or wholesale NOC - multiple occupancy - building only - not class rated',
        cspCode: '0433',
        internalCode: '253616',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61217': [
    {
      classCode: {
        name: '15,000 Sq Ft or Less: Buildings or Premises - Mercantile - NOC multiple occupancy - building only - not class rated',
        cspCode: '0434',
        internalCode: '253620',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '253618',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 15,000 Sq Ft: Buildings or Premises - Mercantile - retail or wholesale NOC - multiple occupancy - building only - not class rated',
        cspCode: '0433',
        internalCode: '253619',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61218': [
    {
      classCode: {
        name: '15,000 Sq Ft or Less: Buildings or Premises - Mercantile - NOC multiple occupancy - building only - not class rated',
        cspCode: '0434',
        internalCode: '253623',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '253621',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 15,000 Sq Ft: Buildings or Premises - Mercantile - retail or wholesale NOC - multiple occupancy - building only - not class rated',
        cspCode: '0433',
        internalCode: '253622',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61223': [
    {
      classCode: {
        name: 'Offices NOC',
        cspCode: '0702',
        internalCode: '047712',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61224': [
    {
      classCode: {
        name: 'Building Or Premises - Office - Premises Primarily Occupied By Employees Of The Insured - For Profit',
        cspCode: '0702',
        internalCode: '042614',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61225': [
    {
      classCode: {
        name: 'Building Or Premises - Office - Premises Primarily Occupied By Employees Of The Insured - Not For Profit',
        cspCode: '0702',
        internalCode: '042622',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61226': [
    {
      classCode: {
        name: 'Buildings Or Premises - Office NOC - For Profit',
        cspCode: '0702',
        internalCode: '042630',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '61227': [
    {
      classCode: {
        name: 'Buildings Or Premises - Office NOC - Not For Profit',
        cspCode: '0702',
        internalCode: '042648',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '62000': [
    {
      classCode: {
        name: 'Manufacturing',
        cspCode: '6850',
        internalCode: '352187',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252379',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mercantile Exposure',
        cspCode: '0567',
        internalCode: '352179',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office or Banks',
        cspCode: '0702',
        internalCode: '352161',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '62001': [
    {
      classCode: {
        name: 'Condominiums - Commercial - Shopping Centers (Association Risk Only)',
        cspCode: '0567',
        internalCode: '007427',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252380',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '62002': [
    {
      classCode: {
        name: 'Condominiums - Commercial Warehouses - Manufacturing Or Private (Association Risk Only)',
        cspCode: '1213',
        internalCode: '007435',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '62003': [
    {
      classCode: {
        name: 'With Mercantile Occupancies - 10 to 30 Units',
        cspCode: '0342',
        internalCode: '254012',
      },
      state: {
        display: 'IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'With Mercantile Occupancies - 10 to 30 Units',
        cspCode: '0342',
        internalCode: '048538',
      },
      state: {
        display: 'NOT_IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'With Mercantile Occupancies - Over 30 Units',
        cspCode: '0343',
        internalCode: '254013',
      },
      state: {
        display: 'IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'With Mercantile Occupancies - Over 30 Units',
        cspCode: '0343',
        internalCode: '048546',
      },
      state: {
        display: 'NOT_IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'With Mercantile Occupancies - Up to 10 Units',
        cspCode: '0341',
        internalCode: '254011',
      },
      state: {
        display: 'IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'With Mercantile Occupancies - Up to 10 Units',
        cspCode: '0341',
        internalCode: '048520',
      },
      state: {
        display: 'NOT_IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'Without Mercantile Occupancies - 10 to 30 Units',
        cspCode: '0332',
        internalCode: '254009',
      },
      state: {
        display: 'IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'Without Mercantile Occupancies - 10 to 30 Units',
        cspCode: '0332',
        internalCode: '048504',
      },
      state: {
        display: 'NOT_IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'Without Mercantile Occupancies - Over 30 Units',
        cspCode: '0333',
        internalCode: '254010',
      },
      state: {
        display: 'IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'Without Mercantile Occupancies - Over 30 Units',
        cspCode: '0333',
        internalCode: '048512',
      },
      state: {
        display: 'NOT_IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'Without Mercantile Occupancies - Up To 10 Units',
        cspCode: '0331',
        internalCode: '254008',
      },
      state: {
        display: 'IN',
        values: ['DC'],
      },
    },
    {
      classCode: {
        name: 'Without Mercantile Occupancies - Up To 10 Units',
        cspCode: '0331',
        internalCode: '048496',
      },
      state: {
        display: 'NOT_IN',
        values: ['DC'],
      },
    },
  ],
  '64074': [
    {
      classCode: {
        name: 'With Restaurants: 11 to 30 Units',
        cspCode: '0743',
        internalCode: '354605',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Over 30 Units',
        cspCode: '0744',
        internalCode: '354613',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'With Restaurants: Up to 10 Units',
        cspCode: '0742',
        internalCode: '354597',
      },
      state: {
        display: 'NOT_IN',
        values: ['ID', 'LA', 'MS'],
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: 11 to 30 Units',
        cspCode: '0746',
        internalCode: '354639',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Over 30 Units',
        cspCode: '0747',
        internalCode: '354647',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Restaurants: Up to 10 Units',
        cspCode: '0745',
        internalCode: '354621',
      },
      state: {
        display: 'NOT_IN',
        values: ['MS'],
      },
    },
  ],
  '66122': [
    {
      classCode: {
        name: 'Lawyers Offices - For Profit',
        cspCode: '0702',
        internalCode: '042655',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '66123': [
    {
      classCode: {
        name: 'Lawyers Offices - Not For Profit',
        cspCode: '0702',
        internalCode: '042663',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '66309': [
    {
      classCode: {
        name: 'Libraries',
        cspCode: '1051',
        internalCode: '018507',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '66561': [
    {
      classCode: {
        name: 'Medical Offices',
        cspCode: '0702',
        internalCode: '020099',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '67634': [
    {
      classCode: {
        name: '15,000 Sq. Ft. or Less',
        cspCode: '0434',
        internalCode: '357871',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252506',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 15,000 Sq. Ft.',
        cspCode: '0433',
        internalCode: '357889',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '67635': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252505',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mercantile - Multiple occupancy - Large area (over 15,000 sq. ft.) - Not class rated',
        cspCode: '0433',
        internalCode: '357863',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Mercantile - Multiple occupancy - Not large area - (15,000 sq. ft. or less) - Not class rated',
        cspCode: '0434',
        internalCode: '357855',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '68439': [
    {
      classCode: {
        name: 'Ticket Agencies - Offices',
        cspCode: '0702',
        internalCode: '030916',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '68500': [
    {
      classCode: {
        name: '11 to 30 Units',
        cspCode: '0312',
        internalCode: '359125',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Excluding Restaurant or Drugstore Operations, Wines or Liquors - 11 - 30 Units',
        cspCode: '0312',
        internalCode: '359158',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Excluding Restaurant or Drugstore Operations, Wines or Liquors - Over 30 Units',
        cspCode: '0313',
        internalCode: '359166',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Excluding Restaurant or Drugstore Operations, Wines or Liquors - Up to 10 Units',
        cspCode: '0311',
        internalCode: '359141',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Over 30 Units',
        cspCode: '0313',
        internalCode: '359133',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Up to 10 Units',
        cspCode: '0311',
        internalCode: '359117',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '90089': [
    {
      classCode: {
        name: 'Advertising Sign Companies - Outdoor',
        cspCode: '4809',
        internalCode: '000067',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91111': [
    {
      classCode: {
        name: 'Air Conditioning Systems Or Equipment - Dealers Or Distributors And Installation, Servicing Or Repair',
        cspCode: '0922',
        internalCode: '000257',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91150': [
    {
      classCode: {
        name: 'Repair and/or Service Only',
        cspCode: '0922',
        internalCode: '001743',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales and/or Storage With or Without Repair',
        cspCode: '0563',
        internalCode: '001750',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91155': [
    {
      classCode: {
        name: 'Repair and/or Service Only',
        cspCode: '0922',
        internalCode: '001768',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales and/or Storage With or Without Repair',
        cspCode: '0563',
        internalCode: '001776',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91177': [
    {
      classCode: {
        name: "Auctioneers - Sales Conducted Away From The Insured's Premises",
        cspCode: '0567',
        internalCode: '002212',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252348',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91179': [
    {
      classCode: {
        name: 'Auctions - On Premises Owned Or Rented By The Insured',
        cspCode: '0567',
        internalCode: '002220',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252349',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91302': [
    {
      classCode: {
        name: 'Contractors Storage or Sales Outlet',
        cspCode: '0567',
        internalCode: '350983',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '351007',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '351023',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '351015',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91340': [
    {
      classCode: {
        name: 'Contractors Equipment Storage',
        cspCode: '0567',
        internalCode: '005728',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '005801',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '351288',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '351270',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91341': [
    {
      classCode: {
        name: 'Contractors Equipment Storage',
        cspCode: '0567',
        internalCode: '351346',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '351361',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '351387',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '351379',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91342': [
    {
      classCode: {
        name: 'Carpentry - Shop Only',
        cspCode: '3959',
        internalCode: '351676',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Contractors Equipment Storage',
        cspCode: '0567',
        internalCode: '351296',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '351312',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '351338',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '351320',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91405': [
    {
      classCode: {
        name: 'Storage Of Cleaning Equipment And Supplies',
        cspCode: '0567',
        internalCode: '005793',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91436': [
    {
      classCode: {
        name: 'Contractors Equipment Storage',
        cspCode: '0567',
        internalCode: '005991',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '006114',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '351403',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '351395',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91551': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252362',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair Only with No Sales or Storage',
        cspCode: '0922',
        internalCode: '007062',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales and/or Storage With No Repair',
        cspCode: '0567',
        internalCode: '007054',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91555': [
    {
      classCode: {
        name: 'Computer Service Or Repair',
        cspCode: '0922',
        internalCode: '070300',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91560': [
    {
      classCode: {
        name: 'Electrical, Plumbing, Heating, Air Conditioning or Sheet Metal Contractors Storage Facilities',
        cspCode: '0563',
        internalCode: '007278',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Contractors Equipment Storage',
        cspCode: '0567',
        internalCode: '007286',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '007336',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '352047',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '352039',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91577': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '352112',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '352138',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '352153',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '352146',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91746': [
    {
      classCode: {
        name: 'Door, Window or Assembled Millwork - Installation, Service or Repair - Metal',
        cspCode: '0567',
        internalCode: '009522',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '91805': [
    {
      classCode: {
        name: 'Drafting, Engineering And Architectural Offices',
        cspCode: '0702',
        internalCode: '009654',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '92102': [
    {
      classCode: {
        name: 'Drilling - Water',
        cspCode: '6210',
        internalCode: '009761',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '92215': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '353060',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '353086',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '353102',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '353094',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '92338': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '353144',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '010074',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '353169',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '353151',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '92451': [
    {
      classCode: {
        name: 'Repair Or Service With No Sales Or Storage',
        cspCode: '0922',
        internalCode: '010496',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair Or Service With Sales Or Storage',
        cspCode: '0563',
        internalCode: '010504',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252363',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '92478': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '353292',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '353318',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '353334',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '353326',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '92663': [
    {
      classCode: {
        name: 'Engineers Or Architects - Consulting - Governmental',
        cspCode: '0701',
        internalCode: '011221',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Engineers Or Architects - Consulting - Other Than Governmental',
        cspCode: '0702',
        internalCode: '011346',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '94007': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '353342',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '353367',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0931',
        internalCode: '353383',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '353375',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '94276': [
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '012088',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Storage',
        cspCode: '0567',
        internalCode: '011981',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '353524',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '353516',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '94569': [
    // Floor Covering Installation
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '012682',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '012930',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '353615',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '353607',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '95124': [
    // Furniture Or Fixtures
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '353698',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Storage',
        cspCode: '0567',
        internalCode: '353680',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '95410': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '353953',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '353979',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '353995',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '353987',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '95487': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '354001',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '354027',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '354043',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '354035',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '95625': [
    {
      classCode: {
        name: 'Carpentry Shop',
        cspCode: '3959',
        internalCode: '070581',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '354332',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '354340',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '95647': [
    {
      classCode: {
        name: 'Heating and Air Conditioning Contractors: Contractors Equipment',
        cspCode: '0563',
        internalCode: '350645',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252361',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '350520',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '350744',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '350694',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Sales and/or Storage',
        cspCode: '0563',
        internalCode: '354100',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Sales and/or Storage',
        cspCode: '0922',
        internalCode: '354274',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '95648': [
    {
      classCode: {
        name: 'Heating and Air Conditioning Contractors: Contractors Equipment',
        cspCode: '0563',
        internalCode: '016113',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252360',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '016154',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '354449',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '354431',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'With Sales and/or Storage',
        cspCode: '0563',
        internalCode: '016097',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Sales and/or Storage',
        cspCode: '0922',
        internalCode: '016071',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '96053': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '353300',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '353359',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '96317': [
    {
      classCode: {
        name: 'Inspection And Appraisal Companies - Office',
        cspCode: '0702',
        internalCode: '017152',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '96611': [
    {
      classCode: {
        name: 'Interior Decorators - Offices',
        cspCode: '0702',
        internalCode: '017582',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '96816': [
    {
      classCode: {
        name: 'Equipment Storage',
        cspCode: '0567',
        internalCode: '354928',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '354936',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '97047': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '018259',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '018374',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '355610',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '355602',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '97050': [
    // Lawn Care Services
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '070763',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '070789',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '355636',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '355628',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '97220': [
    {
      classCode: {
        name: 'Machine Shops NOC',
        cspCode: '6850',
        internalCode: '018945',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '97447': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '019604',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '019679',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '355990',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '355982',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '97650': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '356105',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '356113',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '356139',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '356121',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '97653': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '356220',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '356238',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '356253',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '356246',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98090': [
    {
      classCode: {
        name: 'Motion Pictures - Development Of Negatives, Printing And All Subsequent Operations',
        cspCode: '0921',
        internalCode: '021147',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98091': [
    {
      classCode: {
        name: 'Motion Pictures - Film Distribution Or Exchanges - Not Located At Motion Picture Studios',
        cspCode: '0570',
        internalCode: '021154',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98111': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252442',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales Or Storage - With No Repair',
        cspCode: '0563',
        internalCode: '021824',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales Or Storage - With Repair',
        cspCode: '0922',
        internalCode: '021832',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98304': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0570',
        internalCode: '356527',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '356535',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '356550',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '356543',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98305': [
    // Painting - Interior - Buildings Or Structures
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0570',
        internalCode: '356568',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '356576',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '356592',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '356584',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98344': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '022996',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '023036',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '356733',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '356725',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98405': [
    {
      classCode: {
        name: 'Piano Tuning',
        cspCode: '0567',
        internalCode: '023689',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98482': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0563',
        internalCode: '024448',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '024323',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '357202',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357194',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98483': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0563',
        internalCode: '024489',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '024513',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '357228',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357210',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98502': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '024620',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '024703',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '357244',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357236',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98597': [
    {
      classCode: {
        name: 'With Stage or Scenery Storage',
        cspCode: '0832',
        internalCode: '357285',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Stage and Without Scenery Storage: Office',
        cspCode: '0702',
        internalCode: '357277',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98598': [
    {
      classCode: {
        name: 'With Stage or Scenery Storage',
        cspCode: '0832',
        internalCode: '025114',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Without Stage and Without Scenery Storage: Office',
        cspCode: '0702',
        internalCode: '025163',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98636': [
    // Refrigeration Systems Or Equipment - Dealers And Distributors And Installation, Servicing Or Repair - Commercial
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0563',
        internalCode: '025965',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252455',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '357459',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair and Service Only',
        cspCode: '0922',
        internalCode: '025940',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales and Storage With or Without Repair',
        cspCode: '0563',
        internalCode: '025957',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '357350',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357343',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98710': [
    {
      classCode: {
        name: 'Sand Or Gravel Digging',
        cspCode: '6210',
        internalCode: '357491',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sand Or Gravel Digging',
        cspCode: '0567',
        internalCode: '357483',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98805': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '027490',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '027318',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0932',
        internalCode: '357616',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357608',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98806': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '027516',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '027375',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0567',
        internalCode: '357632',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0567',
        internalCode: '357624',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98813': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '027763',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '027623',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '657657',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357640',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98884': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '028050',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '358143',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '357756',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357749',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98967': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '028290',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '028399',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '357905',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '357897',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '98993': [
    // Sign Erection, Installation Or Repair
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '028316',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '358218',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sign Painting',
        cspCode: '0922',
        internalCode: '028332',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '358051',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '358044',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Woodworking Repair',
        cspCode: '3959',
        internalCode: '028340',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99003': [
    // Sign Painting Or Lettering - Inside Of Buildings
    {
      classCode: {
        name: 'Contractors Storage: Other Equipment',
        cspCode: '0567',
        internalCode: '028365',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Contractors Storage: Paint',
        cspCode: '0570',
        internalCode: '028357',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '358242',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Shopwork',
        cspCode: '0922',
        internalCode: '028381',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '358135',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '358127',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99004': [
    // Sign Painting Or Lettering On Buildings Or Structures
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '358150',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '358168',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '358184',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '358176',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99471': [
    // Surveyors - Land - Not Engaged In Actual Construction
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '029728',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '029744',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '358416',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '358408',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99505': [
    // Swimming Pool Servicing
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '071316',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '071332',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '358770',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '358762',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99600': [
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '071480',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '358952',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '358945',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99620': [
    {
      classCode: {
        name: 'Teleproduction Studios',
        cspCode: '0921',
        internalCode: '071613',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99650': [
    {
      classCode: {
        name: 'Television Or Radio Receiving Set Installation Or Repair',
        cspCode: '0922',
        internalCode: '030460',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99746': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '031070',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252364',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '031146',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales Outlets',
        cspCode: '0567',
        internalCode: '031096',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '359067',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '359059',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99826': [
    {
      classCode: {
        name: 'Upholstering NOC',
        cspCode: '3009',
        internalCode: '031864',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99827': [
    {
      classCode: {
        name: 'Upholstering - Shop Only',
        cspCode: '3009',
        internalCode: '031872',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99851': [
    {
      classCode: {
        name: 'Hospitals Or Offices With No Stabling Or Commercial Boarding Kennels In The Building - Government',
        cspCode: '0701',
        internalCode: '032326',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Hospitals Or Offices With No Stabling Or Commercial Boarding Kennels In The Building - Other Than Government',
        cspCode: '0702',
        internalCode: '032177',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Stabling Or Commercial Boarding Kennels In The Building With Or Without Hospital Or Office',
        cspCode: '0921',
        internalCode: '032383',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99946': [
    {
      classCode: {
        name: 'Contractors Storage',
        cspCode: '0567',
        internalCode: '032888',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Office',
        cspCode: '0702',
        internalCode: '032649',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '359307',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '359299',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
  '99948': [
    {
      classCode: {
        name: 'Merc - >15K sq ft-retail/wholesale other than food - sole occ - bldg only - spec rated',
        cspCode: '0431',
        internalCode: '252485',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Repair, With or Without Sales or Storage',
        cspCode: '0922',
        internalCode: '032912',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Sales With or Without Storage, No Repair',
        cspCode: '0567',
        internalCode: '032904',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Repair',
        cspCode: '0933',
        internalCode: '359323',
      },
      state: {
        display: 'ALL',
      },
    },
    {
      classCode: {
        name: 'Vehicle Storage Without Repair',
        cspCode: '0931',
        internalCode: '359315',
      },
      state: {
        display: 'ALL',
      },
    },
  ],
};

// Merc CSP codes in MS for which HAS_APT_WITH_MERC_CSP_CLASS_MS needs to be enabled
export const LM_CPSP_MS_MERC_CSP_CODES: string[] = ['0321', '0322', '0323'];

// Residential CSP codes in WA for which HAS_RESIDENTIAL_CSP_CLASS_WA needs to be enabled
export const LM_CPSP_WA_RESIDENTIAL_CSP_CODES: string[] = [
  '0074',
  '0075',
  '0076',
  '0077',
  '0078',
  '0079',
  '0745',
  '0746',
  '0747',
  '0321',
  '0322',
  '0323',
  '0341',
  '0342',
  '0343',
];

// Residential CSP codes in MS for which HAS_RESIDENTIAL_CSP_CLASS_WA needs to be enabled
export const LM_CPSP_MS_RESIDENTIAL_CSP_CODES: string[] = [
  '0074',
  '0075',
  '0076',
  '0077',
  '0078',
  '0079',
  '0745',
  '0746',
  '0747',
  '0757',
  '0311',
  '0312',
  '0313',
  '0319',
  '0321',
  '0322',
  '0323',
  '0331',
  '0332',
  '0333',
  '0341',
  '0342',
  '0343',
];

// Residential CSP classes in MA that enable HAS_RESIDENTIAL_CSP_CLASS_MA
export const LM_CPSP_MA_CSP_RESIDENTIAL_CLASSES = [
  '0074',
  '0075',
  '0076',
  '0311',
  '0312',
  '0313',
  '0321',
  '0322',
  '0323',
  '0331',
  '0332',
  '0333',
  '0341',
  '0342',
  '0343',
];

// Incidental apartments CSP class codes that enable HAS_BUILDING_CVG_AND_CONSTRUCTION_IS_FR_JM_NC_AND_INCIDENTAL_APT_EXISTS
export const LM_CPSP_INCIDENTAL_APT_CSP_CLASS_CODES = [
  '0511',
  '0512',
  '0520',
  '0531',
  '0532',
  '0541',
  '0561',
  '0562',
  '0563',
  '0564',
  '0565',
  '0566',
  '0567',
  '0570',
  '0581',
  '0582',
  '0841',
  '0845',
  '0911',
  '0912',
  '0913',
  '0921',
  '0922',
  '0923',
  '1211',
  '1212',
  '1213',
  '1220',
  '1230',
];

export const ALCOHOL_RELATED_CLASS_CODES = [
  '10140',
  '10141',
  '10145',
  '10146',
  '16910',
  '16911',
  '16916',
];

// Limited cooking CSP class codes that enable LIMITED_COOKING_CSP_CLASS
export const LM_CPSP_LIMITED_COOKING_CSP_CLASS_CODES = ['0545', '0534'];

// Limited cooking-related CSP class codes that enable CPUL_300_EXTINGUISHING_SYSTEM and RESTAURANTS_OUTSIDE_CLEANING_SERVICE
export const LM_CPSP_LIMITED_COOKING_RELATED_CSP_CLASS_CODES = ['0542', '0431'];

// Residential condominium CSP class codes
export const LM_CPSP_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES = ['0341', '0342', '0343'];

// Combination of LM_CPSP_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES and LM_CPSP_MS_MERC_CSP_CODES enable a few complex dependencies
export const LM_CPSP_MERC_AND_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES =
  LM_CPSP_MS_MERC_CSP_CODES.concat(LM_CPSP_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES);

const CSP_CODES_BY_INTERNAL_CODE = reduce(
  LM_CPSP_PROPERTY_CLASS_CODES,
  (dict: Record<string, string>, propertyClasses) => {
    propertyClasses.forEach((propertyClass) => {
      const { cspCode, internalCode } = propertyClass.classCode;
      dict[internalCode] = cspCode;
    });

    return dict;
  },
  {} as Record<string, string>
);

/**
 * Liberty Mutual expects a 6-digit internal classification code in the quote request payload. This is the value
 * set on the "Property Class Code (Internal Classification Code)" control.
 * However, for question dependencies we instead need to check the 4-digit CSP code. This helper allows us to look up the
 * 4-digit CSP code using the 6-digit internal classification code.
 */
export const getCspCode = (internalCode: string) => {
  return CSP_CODES_BY_INTERNAL_CODE[internalCode] || '';
};
