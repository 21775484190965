<div [ngClass]="{ 'policy-pane-content': !isBundle }">
  <ng-container *ngIf="!isLoadingQuoteDetails && quoteDetails | async as loadedQuoteDetails">
    <nav *ngIf="!isBundle" class="nav-button-group nav-button-group__space-between mb-4">
      <a class="button button__nav button__no-margin" [routerLink]="['../../']">
        <span class="button-icon icon-arrow-left"></span>
        Back
      </a>
      <div
        *ngIf="
          renewalStatus !== 'automatic' &&
          (renewalStatus !== 'manual' || loadedQuoteDetails.status !== 'draft')
        "
      >
        <ng-container *ngIf="isEditable">
          <a
            [routerLink]="
              '/accounts/' +
              accountId +
              '/' +
              productConfig.computePathForEditUrl(loadedQuoteDetails.uuid)
            "
            [queryParams]="
              carrierAllowsMultipleQuotes() ? { fromQuote: loadedQuoteDetails.uuid } : {}
            "
            class="button button__small button__secondary button__no-margin js-edit-quote-button"
          >
            Edit
          </a>
        </ng-container>
        <!-- Note: disabling Edit/Bind buttons for LM CPSP NY quotes -->
        <a
          *ngIf="loadedQuoteDetails.status === 'quoted'"
          (click)="continueToBind(loadedQuoteDetails.uuid)"
          (keyup.enter)="continueToBind(loadedQuoteDetails.uuid)"
          class="button button__small button__primary button__no-margin js-new-quote-button"
          [ngClass]="{
            button__disabled: isCpspNyRisk(loadedQuoteDetails),
            button__discouraged: nonBindingRole
          }"
        >
          Bind
        </a>
      </div>
    </nav>

    <app-dialog-box
      *ngIf="displayedStatus === 'bound_with_subjectivity' && !isRenewalQuote"
      fullWidth="true"
      type="warning"
    >
      <strong>Quote bound with subjectivity</strong><br />
      <p>
        A policyholder signature is required to issue this policy. An email has been sent to the
        policyholder requesting their electronic signature on the application. This binder and any
        coverage will expire if this signature is not completed by the requisite date.
      </p>
    </app-dialog-box>

    <app-dialog-box *ngIf="loadedQuoteDetails.status === 'declined'" fullWidth="true" type="danger">
      <strong>Quote declined</strong><br />
      This quote was declined for the following reasons:
      <ul *ngIf="loadedQuoteDetails.details.declineReasons" class="bulleted-list mb-0">
        <li *ngFor="let reason of loadedQuoteDetails.details.declineReasons">{{ reason }}</li>
      </ul>
    </app-dialog-box>

    <app-dialog-box
      *ngIf="
        loadedQuoteDetails.status === 'expired' && this.productConfig.pasSource === 'coalition'
      "
      fullWidth="true"
      type="warning"
    >
      <strong>Quote Expired</strong><br />
      Cyber liability quotes expire after 60 days. Please create a new quote to bind cyber liability
      policy.
    </app-dialog-box>

    <!-- Note: For LM CPSP NY quotes, a link to request a bind will be provided -->
    <app-dialog-box
      *ngIf="loadedQuoteDetails.status === 'quoted' && isCpspNyRisk(loadedQuoteDetails)"
      fullWidth="true"
      type="warning"
    >
      <strong>Manual bind required</strong><br />
      <p>
        Liberty Mutual requires that New York quotes including property be issued by our Customer
        Care Team. The policy will be issued within 24 hours of the request.
      </p>
      <a
        class="dialog-text-link"
        href="{{
          productConfig.computePathForRequestBindUrl(accountId, loadedQuoteDetails.displayId)
        }}"
        target="_blank"
      >
        Request bind
      </a>
    </app-dialog-box>

    <app-dialog-box *ngIf="warningHeader" fullWidth="true" type="warning">
      <strong>{{ warningHeader }}</strong
      ><br />
      <span [innerHtml]="getWarningMessage()"></span>
    </app-dialog-box>

    <div *ngIf="!isBundle" class="policy-pane-header">
      <div class="policy-pane-header-left">
        <app-product-badge
          [product]="quotePolicyTerm?.product || loadedQuoteDetails.product"
          [pasSource]="quotePolicyTerm?.pasSource || loadedQuoteDetails.pasSource"
        >
        </app-product-badge>
        <div class="policy-pane-quote-info">
          <h2 class="policy-pane-header-h2">
            {{ getHeaderText() }}
          </h2>
          <small class="gray-text">{{
            quotePolicyTerm?.policyNumber ||
              loadedQuoteDetails.displayId ||
              loadedQuoteDetails.pasId
          }}</small>
        </div>
      </div>
      <div class="policy-pane-header-right">
        <h2 class="policy-pane-header-h2 js-premium-number">
          {{ fetchCost(loadedQuoteDetails) }}
        </h2>
        <small class="gray-text">Premium</small>
      </div>
    </div>
    <div *ngIf="!isBundle" class="document-download-container">
      <app-document-download
        *ngIf="quoteStatus === 'quoted'"
        displayName="Quote Letter"
        [documentDownload$]="quoteLetterDownload$"
        [documentReady$]="quoteLetterDownloadReady$"
      >
      </app-document-download>
      <app-document-download
        *ngIf="showCyberRiskProfile()"
        displayName="Cyber Risk Profile"
        [documentDownload$]="coalitionRiskAssessmentDownload$"
        [documentReady$]="carrierDocumentsReady$"
      >
      </app-document-download>

      <app-document-download
        *ngIf="isLoadingCarrierDocuments"
        displayName="Carrier Documents"
        [documentReady$]="carrierDocumentsReady$"
      >
      </app-document-download>
      <ng-container *ngIf="!isLoadingCarrierDocuments">
        <app-document-download
          *ngFor="let doc of carrierDocuments"
          [displayName]="doc.label"
          [documentDownload$]="coalitionDocumentDownload$(doc.documentType, doc.name)"
        >
        </app-document-download>
      </ng-container>
    </div>
    <div class="policy-detail-subsection">
      <ng-container *ngIf="isRenewalQuote && !policyId">
        <h4
          *ngIf="['manual', 'automatic'].includes(renewalStatus)"
          class="policy-pane-subheader js-important-dates-subheader"
        >
          Important dates
        </h4>
        <dl *ngIf="renewalStatus === 'automatic'">
          <dt>Date policy is auto-issued</dt>
          <dd class="js-policy-auto-issue-date">{{ renewalPolicyAutoIssueDate() }}</dd>
        </dl>
        <dl *ngIf="renewalStatus === 'manual'">
          <dt>Existing policy expiration*</dt>
          <dd class="js-policy-policy-expiration-date">{{ previousPolicyExpiration | date }}</dd>
        </dl>
        <h4 class="policy-pane-subheader js-renewal-info-subheader">Renewal information</h4>
      </ng-container>
      <dl>
        <dt>Status</dt>
        <dd>
          <span class="pill-tag pill-tag__{{ pillTagTypeForStatus() }}">
            {{ formattedStatus() }}
          </span>
        </dd>
      </dl>
      <dl>
        <dt>Effective date</dt>
        <dd>
          {{ getEffectiveDate(quotePolicyTerm || loadedQuoteDetails) }}
        </dd>
      </dl>
      <dl *ngIf="loadedQuoteDetails.pasId">
        <dt>Quote number</dt>
        <dd>
          {{
            quotePolicyTerm?.policyNumber ||
              loadedQuoteDetails.displayId ||
              loadedQuoteDetails.pasId
          }}
        </dd>
      </dl>
      <dl *ngIf="loadedQuoteDetails.pasSource === 'coalition'">
        <dt>Market</dt>
        <dd>{{ productToMarketDisplay[loadedQuoteDetails.product] }}</dd>
      </dl>
      <small *ngIf="renewalStatus === 'manual' && !policyId" class="gray-text"
        >*If a signed renewal application is not returned for this account by this date, coverage
        will cease on the date listed above.</small
      >
    </div>
    <ng-container *ngIf="isCoalitionCyberSurplus()">
      <ng-container *ngIf="hasComplianceDocuments() && errorLoadingComplianceDocuments">
        <app-dialog-box [fullWidth]="true" header="Error Fetching Compliance Docs" type="error">
          There was an error fetching the compliance documents. Please contact our Customer Care
          Team at <a href="tel:18885304650">1-888-530-4650</a> (Mon-Fri,
          {{ customerCareHours.start }}-{{ customerCareHours.end }}
          {{ customerCareHours.timezone }}) or visit our
          <a href="https://app.attuneinsurance.com/help-center/form/request-review" target="_blank"
            >Help Center</a
          >
          if you have any questions.
          <br />
          <br />
          Please note that you will be required to complete a diligent search attestation during the
          bind process.
        </app-dialog-box>
      </ng-container>

      <ng-container *ngIf="hasComplianceDocuments() && !errorLoadingComplianceDocuments">
        <ng-container *ngIf="isQuoted() && hasAttestation()">
          <app-dialog-box header="Requirements for Surplus Policies" type="primary">
            You will be required to complete a diligent search attestation during the bind process.
            Once the policy is bound, you and the insured will be sent the following documents for
            e-signature via HelloSign.
          </app-dialog-box>
          <h4 class="policy-pane-subheader">Preview of documents for e-sign</h4>
        </ng-container>
        <ng-container *ngIf="isQuoted() && !hasAttestation()">
          <app-dialog-box header="Requirements for Surplus Policies" type="primary">
            Once the policy is bound, you and the insured will be sent the following documents for
            e-signature via HelloSign.
          </app-dialog-box>
          <h4 class="policy-pane-subheader">Preview of documents for e-sign</h4>
        </ng-container>
        <ng-container *ngIf="isBound()">
          <app-dialog-box header="E-Signature Required" type="primary">
            Please complete e-signatures for the following documents. If the signatures are not
            completed within <strong>15 days of the effective date</strong>, the policy may be
            subject to cancellation.
          </app-dialog-box>
          <h4 class="policy-pane-subheader">Status for e-sign from HelloSign</h4>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isBound() && isRenewalQuote">
        <app-dialog-box header="E-Signature Not Required" type="primary">
          An e-signature is not required for cyber surplus renewal policies.
        </app-dialog-box>
      </ng-container>

      <div
        *ngIf="!isRenewalQuote && address && !hasComplianceDocuments()"
        class="policy-pane-subsection"
      >
        <h4 class="gray-text">Preparing Compliance Documents</h4>
        <!-- show the expected number of documents as loading so they don't 'pop' in when they are ready -->
        <app-document-download
          *ngFor="let _ of [].constructor(expectedComplianceDocumentCount)"
          displayName="Compliance Document"
          [documentReady$]="complianceDocumentReady$"
        >
        </app-document-download>
      </div>
      <div *ngIf="complianceDocumentsForBroker.length" class="policy-pane-subsection">
        <h4 class="gray-text">For broker</h4>
        <app-document-download
          *ngFor="let doc of complianceDocumentsForBroker"
          [type]="!isBound() ? 'pdf' : doc.status === 'signed' ? 'signed' : 'unsigned'"
          [displayName]="doc.document_name"
          [documentDownload$]="complianceDocumentToDownload$[doc.s3_url]"
        >
        </app-document-download>
      </div>
      <div *ngIf="complianceDocumentsForInsured.length" class="policy-pane-subsection">
        <h4 class="gray-text">For insured</h4>
        <app-document-download
          *ngFor="let doc of complianceDocumentsForInsured"
          [type]="'unsigned'"
          [displayName]="doc.document_name"
          [documentDownload$]="complianceDocumentToDownload$[doc.s3_url]"
        >
        </app-document-download>
      </div>
      <ng-container *ngIf="complianceDocumentsWithoutEsign.length">
        <h4 class="policy-pane-subheader">Other documents without e-sign</h4>
        <app-document-download
          *ngFor="let doc of complianceDocumentsWithoutEsign"
          [displayName]="doc.document_name"
          [documentDownload$]="complianceDocumentToDownload$[doc.s3_url]"
        >
        </app-document-download>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="hasCoalitionRiskAssessment()">
      <div>
        <h4 class="policy-pane-subheader">What is a Coalition Risk Assessment (CRA)?</h4>
        <ul>
          <li>
            <img class="badge-list-img" src="../../../../../assets/img/life_presever.svg" alt="" />
            <p class="badge-list-text">
              Comprehensive security posture report offered to all insureds, free of charge
            </p>
          </li>
          <li>
            <img class="badge-list-img" src="../../../../../assets/img/security.svg" alt="" />
            <p class="badge-list-text">
              Includes information on vulnerabilities and overall recommendations to help the
              insured understand their risk exposure
            </p>
          </li>
        </ul>
      </div>

      <div>
        <h4 class="policy-pane-subheader">
          Active Insurance: $10k worth of monitoring tools included
        </h4>
        <ul>
          <li>
            <img class="badge-list-img" src="../../../../../assets/img/small_business.svg" alt="" />
            <p class="badge-list-text">
              Small businesses are increasingly easy targets for bad actors due to limited security
              measures.
            </p>
          </li>
          <li>
            <img class="badge-list-img" src="../../../../../assets/img/sad_computer.svg" alt="" />
            <p class="badge-list-text">
              3rd-party data breaches can impact a small business's liability exposure like Equifax
              and Experian.
            </p>
          </li>
        </ul>
      </div>
    </ng-container>

    <div *ngIf="productConfig.pasSource === 'coalition' && isBound()">
      <app-post-bind-servicing-information carrier="coalition">
      </app-post-bind-servicing-information>
    </div>
    <div *ngIf="productConfig.pasSource === 'coalition' && !isBound()">
      <app-quote-servicing-information carrier="coalition"></app-quote-servicing-information>
    </div>
  </ng-container>
</div>

<app-modal
  [open]="showBundleBind"
  (handleModalClose)="closeBundleBind()"
  modalClass="app-modal-content__left-align"
  modalNameForAmplitudeEvent="bundle-bind-quote-selection"
>
  <app-bundle-bind-selection-form
    *ngIf="showBundleBind && model$ | async"
    [accountId]="accountId"
    [selectedCyberId]="quoteId"
    (closeModal)="closeBundleBind()"
  ></app-bundle-bind-selection-form>
</app-modal>
