<div
  *ngIf="
    shouldShowAopPriceReduction ||
    shouldShowPerOccurrencePriceReduction ||
    shouldShowBIPeriodReduction ||
    shouldShowEquipmentBreakdownPriceReduction
  "
  class="price-reduction-estimate"
>
  <hr class="hr__small" />
  <div class="estimate-header">
    <span>
      <h2 class="h2">Quick Edit</h2>
    </span>
    <a
      (click)="useBestOptions()"
      (keyup.enter)="useBestOptions()"
      *ngIf="estimatedHighestSavings"
      class="estimate-highest-savings"
    >
      Reduce premium by up to {{ formatEstimate(estimatedHighestSavings) }}!*
    </a>
  </div>
  <table class="estimate-table">
    <thead>
      <tr>
        <th>Coverage</th>
        <th>Limit</th>
        <th class="td__right">Change</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngIf="shouldShowAopPriceReduction">
        <td data-column-name="Coverage">AOP Deductible</td>
        <td data-column-name="Limit">
          <app-form-field-select
            label="AOP Deductible"
            inputId="newAopDeductible"
            [form]="priceReductionEstimateFormGroup"
            nameOfFormControl="newAopDeductible"
            [availableOptions]="aopQuoteOptions"
            cssClass="estimate-select-field form-field__no-margin js-aopDeductible-select-input"
          >
          </app-form-field-select>
        </td>
        <td data-column-name="Change" class="js-aopDeductible-change">
          <p
            *ngIf="estimatedAopSavings"
            class="p__no-margin estimate-row-amount"
            [ngClass]="estimatedAopSavings >= 0 ? 'p__primary-text' : 'p__red'"
          >
            {{ formatChangeEstimate(estimatedAopSavings) }}
          </p>
          <p class="p__no-margin" *ngIf="!estimatedAopSavings">--</p>
        </td>
      </tr>
      <tr *ngIf="shouldShowPerOccurrencePriceReduction">
        <td data-column-name="Coverage">Per Occurrence Limit</td>
        <td data-column-name="Limit">
          <app-form-field-select
            label="Per Occurrence Limit"
            inputId="newPerOccurrenceLimit"
            [form]="priceReductionEstimateFormGroup"
            nameOfFormControl="newPerOccurrenceLimit"
            [availableOptions]="perOccurrenceQuoteOptions"
            cssClass="estimate-select-field form-field__no-margin js-perOccurrenceLimit-select-input"
          >
          </app-form-field-select>
        </td>
        <td data-column-name="Change" class="js-perOccurrenceLimit-change">
          <p
            *ngIf="estimatedPerOccurrenceSavings"
            class="p__no-margin estimate-row-amount"
            [ngClass]="estimatedPerOccurrenceSavings >= 0 ? 'p__primary-text' : 'p__red'"
          >
            {{ formatChangeEstimate(estimatedPerOccurrenceSavings) }}
          </p>
          <p class="p__no-margin" *ngIf="!estimatedPerOccurrenceSavings">--</p>
        </td>
      </tr>
      <tr *ngIf="shouldShowBIPeriodReduction">
        <td data-column-name="Coverage">BI Period of Indemnity</td>
        <td data-column-name="Limit">
          <app-form-field-select
            label="Period of Indemnity"
            inputId="newPeriodOfIndemnity"
            [form]="priceReductionEstimateFormGroup"
            nameOfFormControl="newPeriodOfIndemnity"
            [availableOptions]="biPeriodQuoteOptions"
            cssClass="estimate-select-field form-field__no-margin js-biPeriodOfIndemnity-select-input"
          >
          </app-form-field-select>
        </td>
        <td data-column-name="Change" class="js-biPeriodOfIndemnity-change">
          <p
            *ngIf="estimatedBIPeriodSavings"
            class="p__no-margin estimate-row-amount"
            [ngClass]="estimatedBIPeriodSavings >= 0 ? 'p__primary-text' : 'p__red'"
          >
            {{ formatChangeEstimate(estimatedBIPeriodSavings) }}
          </p>
          <p class="p__no-margin" *ngIf="!estimatedBIPeriodSavings">--</p>
        </td>
      </tr>
      <tr *ngIf="shouldShowEquipmentBreakdownPriceReduction">
        <td data-column-name="Coverage">Equipment Breakdown</td>
        <td data-column-name="Limit">
          <app-form-field-select
            label="Equipment Breakdown"
            inputId="newEquipmentBreakdownCoverage"
            [form]="priceReductionEstimateFormGroup"
            nameOfFormControl="newEquipmentBreakdownCoverage"
            [availableOptions]="equipmentBreakdownCoverageOptions"
            cssClass="estimate-select-field form-field__no-margin js-equipmentBreakdownCoverage-select-input"
          >
          </app-form-field-select>
        </td>
        <td data-column-name="Change" class="js-equipmentBreakdownCoverage-change">
          <p
            *ngIf="estimatedEquipmentBreakdownSavings"
            class="p__no-margin estimate-row-amount"
            [ngClass]="estimatedEquipmentBreakdownSavings >= 0 ? 'p__primary-text' : 'p__red'"
          >
            {{ formatChangeEstimate(estimatedEquipmentBreakdownSavings) }}
          </p>
          <p class="p__no-margin" *ngIf="!estimatedEquipmentBreakdownSavings">--</p>
        </td>
      </tr>
    </tbody>
  </table>
  <hr class="hr__small" />
  <div class="estimate-card">
    <div class="estimate-card-header">
      <span class="estimate-card-title-group">
        <h3 class="h3">Estimated new premium</h3>
        <p *ngIf="estimatedHighestSavings" class="estimate-info-text gray-text">
          *Savings are estimated and results may vary.
        </p>
      </span>
      <p
        class="estimate-card-premium"
        [ngClass]="estimatedNewPremium <= quote.totalCost ? 'p__primary-text' : 'p__red'"
      >
        <strong [ngClass]="!estimatedNewPremium ? 'gray-text' : ''">
          <span>{{ estimatedNewPremium ? formatEstimate(estimatedNewPremium) : '--' }}</span>
        </strong>
      </p>
    </div>
    <div class="nav-button-group nav-button-group__right estimate-card-button-group">
      <button
        class="button button__small button__no-margin button__secondary js-clear-changes"
        (click)="clearUserChanges()"
      >
        Clear Changes
      </button>
      <button
        class="button button__small button__no-margin button__primary js-requote-with-changes"
        [disabled]="!estimatedNewPremium"
        (click)="requoteAsNewQuote()"
      >
        Requote
      </button>
    </div>
  </div>
  <hr class="hr__small" />
</div>
