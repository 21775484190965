import { Component, OnChanges, OnDestroy, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { SentryService } from 'app/core/services/sentry.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { PolicyCountResponse, PolicyRenewal } from 'app/bop/guidewire/typings';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { EXCESS_BUSINESS_TYPE } from 'app/features/activity/models/constants';
import { ActivityListBaseComponent } from 'app/features/activity/components/activity-list-base/activity-list-base.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-activity-list-renewals',
  templateUrl: 'activity-list-renewals.component.html',
  providers: [CurrencyPipe],
})
export class ActivityListRenewalsComponent
  extends ActivityListBaseComponent
  implements OnChanges, OnDestroy
{
  @Input() policyCount: PolicyCountResponse;
  @Input() policyPageLength = 5;

  constructor(
    protected sentryService: SentryService,
    private insuredAccountService: InsuredAccountService,
    protected currencyPipe: CurrencyPipe
  ) {
    super(sentryService, currencyPipe);
  }

  private sub: Subscription = new Subscription();
  private accountDetails: Record<string, InsuredAccount> = {};

  showNewRenewalsUI = environment.showNewRenewalsUI;

  renewalsSelectedIndex = 0;
  renewalsLoadedIndex = 0;

  // Number of fake rows to display while loading renewals
  estimatedRenewalLength = 1;

  renewals: PolicyRenewal[] = [];
  renewalsSubscriptions: Subscription[] = [];

  loadingRenewals = true;

  ngOnChanges() {
    this.loadRenewals(0);
  }

  getRenewalCount() {
    return this.policyCount ? parseInt(this.policyCount.renewalPolicyCount, 10) : null;
  }

  loadRenewals(index: number) {
    const renewalCount = this.getRenewalCount();
    if (!renewalCount) {
      return;
    }

    this.renewalsSelectedIndex = index;
    this.clearSummarySubscriptions(this.renewalsSubscriptions);

    this.loadingRenewals = true;
    this.estimatedRenewalLength = Math.min(this.policyPageLength, renewalCount - index);
    this.renewalsSubscriptions.push(
      this.insuredAccountService
        .getRenewalSummary(index, this.policyPageLength)
        .subscribe((renewals) => {
          this.loadingRenewals = false;
          this.renewalsLoadedIndex = index;
          this.renewals = renewals;
          this.loadRenewalAccountDetails();
        })
    );
  }

  loadRenewalAccountDetails() {
    // Making all account details calls at once is reasonable for now, because there will only be 5 per page.
    // If we change to a large page size, we will want to revisit this strategy.
    this.renewals.forEach((renewal: PolicyRenewal) => {
      const accountNumber = renewal.Renewal.AccountNumber;
      if (!this.accountDetails[accountNumber]) {
        this.renewalsSubscriptions.push(
          this.insuredAccountService.get(accountNumber).subscribe((accountResponse) => {
            this.accountDetails[accountNumber] = accountResponse;
          })
        );
      }
    });
  }

  loadNextRenewals() {
    this.loadRenewals(this.renewalsSelectedIndex + this.policyPageLength);
  }

  loadPreviousRenewals() {
    this.loadRenewals(this.renewalsSelectedIndex - this.policyPageLength);
  }

  trimLastIdSegment(id: string) {
    const lastDashIndex = id.lastIndexOf('-');
    if (lastDashIndex !== -1) {
      return id.slice(0, lastDashIndex);
    }
    return id;
  }

  getFormattedRenewalStatus(renewal: PolicyRenewal) {
    const accountNumber = _.get(renewal, 'Renewal.AccountNumber');
    const renewalPolicyNumber = _.get(renewal, 'Renewal.PolicyNumber', '');
    const renewalStatus = _.get(renewal, 'Renewal.Status');

    const formattedStatusMapping: { [k: string]: string } = {
      Quoted: 'Quoted',
      Renewing: 'Scheduled',
      Scheduled: 'Scheduled',
      Bound: 'Scheduled',
      Draft: 'Draft',
      NotTaken: 'Not Taken',
      NotTaking: 'Not Taken',
      NonRenewing: 'Non-Renewing',
      NonRenewed: 'Non-Renewed',
    };

    if (this.accountDetails[accountNumber]) {
      const accountInfo = this.accountDetails[accountNumber];
      const policies = accountInfo.policiesWithTerms || [];
      const matchingPolicy = policies.find(
        (policy) =>
          this.trimLastIdSegment(policy.policyNumber || '') ===
          this.trimLastIdSegment(renewalPolicyNumber)
      );
      const matchingPolicyPeriod =
        matchingPolicy &&
        matchingPolicy.terms.find((period) => period.policyNumber === renewalPolicyNumber);

      if (
        matchingPolicyPeriod &&
        ['NotTaken', 'NonRenewing'].includes(matchingPolicyPeriod.status)
      ) {
        return formattedStatusMapping[matchingPolicyPeriod.status];
      }
    }

    return formattedStatusMapping[renewalStatus] || 'Unavailable';
  }

  getCssClassForPillTag(renewal: PolicyRenewal) {
    switch (this.getFormattedRenewalStatus(renewal)) {
      case 'Quoted':
        return 'pill-tag__good';
      case 'Scheduled':
        return 'pill-tag__main';
      case 'Unavailable':
      case 'Draft':
      case 'Not Taken':
        return 'pill-tag__neutral';
      case 'Non-Renewing':
      case 'Non-Renewed':
        return 'pill-tag__bad';
    }
  }

  getRenewalRouterLink(renewal: PolicyRenewal) {
    const accountNumber = _.get(renewal, 'Renewal.AccountNumber');
    const policyNumber = _.get(renewal, 'Renewal.PolicyNumber');
    const policyType = _.get(renewal, 'Renewal.LineBusinessType');
    const status = _.get(renewal, 'Renewal.Status');

    // For these statuses the renewal policy cannot be linked, because it is not available
    const statusesThatCannotDeepLink = ['Draft', 'NonRenewing', 'NonRenewed'];
    if (
      policyType === EXCESS_BUSINESS_TYPE ||
      statusesThatCannotDeepLink.includes(status) ||
      !this.showNewRenewalsUI
    ) {
      return [`/accounts/${accountNumber}`];
    } else {
      return [`/accounts/${accountNumber}/policy/${policyNumber}`];
    }
  }

  getRenewalQuoteLinkParams() {
    return { path: 'Policy,Endorsements' };
  }

  ngOnDestroy() {
    this.clearSummarySubscriptions(this.renewalsSubscriptions);
    this.sub.unsubscribe();
  }
}
