import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-price-difference-modal',
  templateUrl: './price-difference-modal.component.html',
})
export class PriceDifferenceModalComponent {
  @Input() displayPriceDiffModal: boolean;
  @Input() isWcQuote: boolean;
  @Input() originalTotalCost: number;
  @Input() newTotalCost: number;
  @Output() triggerNavigationToAccount: EventEmitter<null> = new EventEmitter();
  @Output() triggerBindServiceCall: EventEmitter<null> = new EventEmitter();

  goBackToAccount() {
    this.triggerNavigationToAccount.emit();
  }

  callBindService() {
    this.triggerBindServiceCall.emit();
  }
}
