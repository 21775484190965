import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GWService } from 'app/bop/services/gw.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { of as observableOf, Observable } from 'rxjs';
import { mapPolicyPeriodToQuoteDetails } from 'app/shared/helpers/quote-details';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_V3_BOP_QUOTES_URI } from '../../features/attune-bop/models/constants';
import { ActionName } from '../rewards/rewards-types';
import { RewardsService } from './rewards.service';
import { InsuredAccount } from '../../features/insured-account/models/insured-account.model';

export type MappedBindResponse =
  | { success: true; bindResponse: QSQuoteSubmission[] }
  | { success: false; isMoratoriumError: boolean; isFeinBlocked: boolean };

@Injectable()
export class GWBindService {
  constructor(
    private http: HttpClient,
    private gwService: GWService,
    private amplitudeService: AmplitudeService,
    private rewardsService: RewardsService
  ) {}

  getQuoteDetails(quoteId: string): Observable<QuoteDetails> {
    return this.gwService.details(quoteId).pipe(
      map(mapPolicyPeriodToQuoteDetails),
      map((payload) => {
        if (payload.product === 'BOP' || payload.product === 'WC') {
          this.amplitudeService.setPolicyDetails(payload);
        } else if (payload.product === 'Excess') {
          this.amplitudeService.setMSExcessDetails(payload);
        }
        return payload;
      })
    );
  }

  public bind(
    paymentPlanId: QSBindPaymentPlan,
    quoteId: string,
    productType: InsuranceProduct,
    excessCarriers?: QSCarrierConfig,
    agreedToBackdatedEffectiveDateTerms?: boolean,
    bundleId?: string,
    insuredAccount?: InsuredAccount
  ): Observable<MappedBindResponse> {
    const payload: QSBindRequest = {
      paymentPlan: paymentPlanId,
    };
    if (excessCarriers !== undefined) {
      payload.carriers = excessCarriers;
    }
    if (insuredAccount?.id !== undefined) {
      payload.accountNumber = insuredAccount?.id;
    }

    if (agreedToBackdatedEffectiveDateTerms) {
      /*
      Note: Payload for GW expects a field called `effDateWithinPast5Days`. This field is a boolean that
      represents whether or not the effective date is within the past 5 days and if the user has agreed to the terms.
      */
      payload.effDateWithinPast5Days = agreedToBackdatedEffectiveDateTerms;
    }

    return this.gwService.bind(quoteId, payload, productType, bundleId).pipe(
      map((bindResponse: QSQuoteSubmissionResponse) => {
        return { success: true as true, bindResponse: bindResponse.results };
      }),
      tap(() => {
        this.rewardsService.submitRewardAction({
          actionName: ActionName.BIND_POLICY_FOR_ACCOUNT,
          data: {
            insuredAccountId: insuredAccount?.id,
            accountName: insuredAccount?.companyName,
            carrierName: 'attune_gw',
            product: 'bop',
          },
        });
      }),
      catchError((error: HttpErrorResponse) => {
        return observableOf({
          success: false as false,
          isMoratoriumError: error?.error?.isMoratoriumError,
          isFeinBlocked: error?.error?.isFeinBlocked,
        });
      })
    );
  }

  public createMoratoriumZendeskTicket(
    paymentPlanId: QSBindPaymentPlan,
    quoteId: string
  ): Observable<any> {
    const payload: QSBindRequest = {
      paymentPlan: paymentPlanId,
    };
    return this.http.post(`${API_V3_BOP_QUOTES_URI}/${quoteId}/zendesk/moratorium`, payload);
  }
}
