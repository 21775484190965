<!--TODO: Consider adding loading state tracker to avoid displaying empty page-->
<ng-container *ngIf="isFormInitialized | async">
  <app-quote-stepped-sidebar
    class="app-page-sidebar app-page-sidebar__quote"
    product="bop"
    pasSource="liberty_mutual"
    [steppedFormService]="formService"
    (navigateToStep)="handleNavigateToSlug($event)"
    [accountId]="accountId"
    [insAccount]="insuredAccount$ | async"
    [disableNavigationToStep]="true"
    [sidebarTitle]="isEditing ? 'Edit quote' : 'New quote'"
    [fillInHappyPath]="fillInHappyPath.bind(this)"
  >
  </app-quote-stepped-sidebar>
  <!-- TODO: Implement happy path and other functions for the quote sidebar -->

  <div class="app-page-form-scrollable-wrapper">
    <div class="app-page-form" content>
      <a
        *ngIf="!isFirstStep()"
        class="emphasized-link form-previous-link"
        (click)="loadPreviousStep($event)"
        (keyup.enter)="loadPreviousStep($event)"
      >
        <span class="icon-full-arrow-left button-icon" role="presentation"></span>Previous step
      </a>
      <app-dialog-box
        *ngIf="libertyMutualOutages | async"
        type="warning"
        [fullWidth]="true"
        [dismissable]="true"
        class="mt-4"
      >
        Liberty Mutual is currently experiencing some degradation of services which might cause
        errors to occur.
      </app-dialog-box>

      <form [formGroup]="formService.form" (ngSubmit)="submitForm($event)" novalidate>
        <main *ngFor="let step of formService.formDslSteps">
          <section *ngIf="isCurrentStep(step.slug)">
            <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">{{ step.displayName }}</h1>
            <app-form-dsl-form
              [formTree]="stepTree"
              [form]="formService.form.get(step.formPath)"
              [submitted]="submitted()"
              (buttonClick)="handleInterpreterOutput($event)"
            >
            </app-form-dsl-form>
          </section>
        </main>

        <div class="form-footer">
          <div class="validation" role="alert" *ngIf="submitted() && !isCurrentStepValid()">
            <p class="validation-message">
              {{ getValidationMessage() }}
            </p>
          </div>
          <div class="nav-button-group nav-button-group__left">
            <button
              *ngIf="!isFinalStep()"
              class="button button__primary js-load-next-step-button"
              (click)="loadNextStep($event)"
              [ngClass]="{ button__discouraged: !isCurrentStepValid() }"
              [disabled]="isLoadingNextStep"
            >
              <div class="button-loader" role="presentation" *ngIf="isLoadingNextStep">
                <svg class="circle-spinner">
                  <circle
                    cx="11"
                    cy="11"
                    r="9"
                    stroke-width="2"
                    stroke-dasharray="0,1000"
                    d="M5 40 l215 0"
                    fill="transparent"
                  />
                </svg>
              </div>
              <span *ngIf="isLoadingNextStep">Loading...</span>
              <span *ngIf="!isLoadingNextStep">Next</span>
            </button>
            <button
              *ngIf="isFinalStep()"
              type="submit"
              class="button button__primary js-step-forward-button"
              [ngClass]="{ button__discouraged: !this.isCurrentStepValid() }"
              [disabled]="isSubmittingForm"
            >
              <div class="button-loader" *ngIf="isSubmittingForm">
                <svg class="circle-spinner">
                  <circle
                    cx="11"
                    cy="11"
                    r="9"
                    stroke-width="2"
                    stroke-dasharray="0,1000"
                    d="M5 40 l215 0"
                    fill="transparent"
                  />
                </svg>
              </div>
              <span *ngIf="isSubmittingForm">Submitting...</span>
              <span *ngIf="!isSubmittingForm">Submit</span>
            </button>
          </div>
        </div>
      </form>

      <!-- TODO/FYI: There is a lot of BOP-specific code in this debugger component, so beware if things break; the component can definitely be refactored to handle multiple products -->
      <app-quote-form-debugger
        *ngIf="isDevMode"
        [submitted]="submitted()"
        [insuredAccount]="insuredAccount$ | async"
        [form]="formService.form"
        [currentStep]="currentStep"
        tsRequestId="bleep-bloop"
      ></app-quote-form-debugger>
    </div>
  </div>
</ng-container>

<app-quote-error-modal
  [open]="quoteErrorModalOpen"
  (closeQuoteErrorModal)="onCloseErrorModal($event)"
  [errorType]="UNKNOWN_ERROR_WITHOUT_RETRY"
  [errors]="quoteError ? quoteError.errors : []"
>
  <p class="js-liberty-mutual-contact-team">
    We're sorry, there was an issue processing this quote.
  </p>
</app-quote-error-modal>

<app-quote-error-modal
  [open]="quoteEarlyDeclinedModalOpen"
  (closeQuoteErrorModal)="onCloseErrorModal($event)"
  [errorType]="LIBERTY_MUTUAL_DECLINED_ERROR"
  [errors]="earlyDeclineErrors"
>
</app-quote-error-modal>

<app-quote-error-modal
  [open]="quoteClassCodesErrorModalOpen"
  (closeQuoteErrorModal)="onClassCodeCloseErrorModal($event)"
  [errorType]="LIBERTY_MUTUAL_CLASS_CODES_ERROR"
></app-quote-error-modal>
