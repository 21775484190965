<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__bop"
  product="bop"
  pasSource="attune"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insAccount"
  [disableNavigationToStep]="isCurrentStep('review') || eligibilityCheckLoading"
  sidebarTitle="Endorsement"
>
</app-quote-stepped-sidebar>

<div class="app-page-form-scrollable-wrapper app-page-form-with-sidebar-wrapper">
  <div class="app-page-form" content>
    <main *ngIf="loading" class="placeholder-form-container">
      <div class="placeholder-h1"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-submit"></div>
    </main>

    <ng-container *ngIf="!loading">
      <a
        *ngIf="!formService.isFirstStep() && !formService.isFinalStep()"
        class="emphasized-link form-previous-link"
        (click)="formService.stepBackward()"
        (keyup.enter)="formService.stepBackward()"
      >
        <span class="icon-full-arrow-left button-icon"></span>Previous step
      </a>
      <form (ngSubmit)="handleSubmit($event)" novalidate>
        <section *ngIf="isCurrentStep('endorsement-requests')">
          <h1>Endorse policy</h1>
          <div
            class="validation validation__align-left"
            *ngIf="submitted() && (effectiveDate.invalid || endorsementRequests.errors)"
          >
            <div class="validation-message validation-message__warning" *ngIf="!isFutureDatedQuote">
              Please select a later date or
              <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new"
                >contact our Customer Care Team here</a
              >.
            </div>
            <div class="validation-message validation-message__warning" *ngIf="isFutureDatedQuote">
              Invalid date: this date is before the policy is scheduled to take effect.
            </div>
          </div>
          <app-form-field-text
            inputId="effectiveDate"
            [submitted]="submitted()"
            [form]="form.get('endorsementRequests')"
            labelText="Endorsement effective date"
            nameOfFormControl="effectiveDate"
            appDateMask
          >
          </app-form-field-text>

          <section class="mb-4">
            <h3>Change requests</h3>
            <p class="p__big-margin">Add, remove, or edit any of the following:</p>

            <app-form-field-checkbox
              inputId="accountChange"
              labelText="Basic business information"
              sublabelText="DBA, insured mailing address"
              [form]="form.get('endorsementRequests.typeOfEndorsementOptIn')"
              [submitted]="submitted()"
              nameOfFormControl="account"
            ></app-form-field-checkbox>

            <app-form-field-checkbox
              inputId="additionalInsureds"
              labelText="Additional insureds"
              sublabelText="Including Mortgagee, Loss Payable, Lessors of Premises, etc."
              [form]="form.get('endorsementRequests.typeOfEndorsementOptIn')"
              [submitted]="submitted()"
              nameOfFormControl="additionalInsureds"
            ></app-form-field-checkbox>

            <app-form-field-checkbox
              inputId="waiversOfSubrogation"
              labelText="Waivers of subrogation"
              [form]="form.get('endorsementRequests.typeOfEndorsementOptIn')"
              [submitted]="submitted()"
              nameOfFormControl="waiverOfSubrogation"
            >
            </app-form-field-checkbox>

            <app-form-field-checkbox
              inputId="coverages"
              labelText="Coverages"
              sublabelText="Coverages, limits, and excess"
              [form]="form.get('endorsementRequests.typeOfEndorsementOptIn')"
              [submitted]="submitted()"
              nameOfFormControl="coverages"
            >
            </app-form-field-checkbox>

            <app-form-field-checkbox
              inputId="locations"
              labelText="Locations"
              sublabelText="Edit existing location address, Add new location"
              [form]="form.get('endorsementRequests.typeOfEndorsementOptIn')"
              [submitted]="submitted()"
              nameOfFormControl="locations"
            >
            </app-form-field-checkbox>

            <app-form-field-checkbox
              inputId="namedInsureds"
              labelText="Named Insured"
              sublabelText="Edit or add a named insured"
              [form]="form.get('endorsementRequests.typeOfEndorsementOptIn')"
              [submitted]="submitted()"
              nameOfFormControl="namedInsureds"
            >
            </app-form-field-checkbox>

            <app-form-field-checkbox
              inputId="otherEndorsement"
              labelText="Other"
              [form]="form.get('endorsementRequests.typeOfEndorsementOptIn')"
              [submitted]="submitted()"
              nameOfFormControl="otherEndorsements"
            >
            </app-form-field-checkbox>
          </section>

          <app-form-field-file
            inputId="noKnownLossLetterFiles"
            labelText="Upload No Known Loss Letter (NKLL)"
            [fileMetadata]="{
              accountId: accountId,
              policyNumber: policyNumber,
              jobNumber: endorsementJobNumber
            }"
            [form]="form.get('endorsementRequests')"
            nameOfFormControl="noKnownLossLetterFiles"
            [submitted]="submitted()"
          ></app-form-field-file>

          <app-form-field-textarea
            [submitted]="submitted()"
            [form]="form.get('endorsementRequests')"
            labelText="Please provide any context as to why this endorsement is backdated."
            nameOfFormControl="reasonForBackdating"
          >
          </app-form-field-textarea>
        </section>

        <section *ngIf="isCurrentStep('account')">
          <h1 class="form-header-below-link">Basic business info</h1>

          <app-form-field-text
            inputId="doingBusinessAs"
            [submitted]="submitted()"
            [form]="form.get('account')"
            labelText="DBA or Operating Name"
            nameOfFormControl="doingBusinessAs"
          >
          </app-form-field-text>

          <app-form-field-address
            labelText="Business mailing address"
            prefix="account"
            [form]="form.get('account.address')"
            [submitted]="submitted()"
          >
          </app-form-field-address>
        </section>

        <section *ngIf="isCurrentStep('additional-insureds')">
          <h1 class="form-header-below-link">Additional insureds</h1>

          <app-additional-insureds
            [additionalInsureds]="form.get('additionalInsureds.additionalInsuredBusinesses')"
            [numberOfAdditionalInsureds]="
              form.get('additionalInsureds.additionalInsuredBusinesses').length
            "
            [locationAddresses]="locationAddresses"
            [locations]="locations"
            [submitted]="submitted()"
            prefix="endorse"
            [isUsingRelatedLocation]="false"
            [allowRemovingAllAdditionalInsureds]="true"
            (addInsuredBusiness)="
              formService.addAdditionalInsuredBusiness(
                form.get('additionalInsureds.additionalInsuredBusinesses')
              )
            "
            (removeInsuredBusiness)="
              formService.removeAdditionalInsuredBusinessAt(
                $event,
                form.get('additionalInsureds.additionalInsuredBusinesses')
              )
            "
          >
          </app-additional-insureds>

          <app-form-field-file
            inputId="noKnownLossLetterFiles"
            labelText="Upload No Known Loss Letter (NKLL)"
            [fileMetadata]="{
              accountId: accountId,
              policyNumber: policyNumber,
              jobNumber: endorsementJobNumber
            }"
            [form]="form.get('endorsementRequests')"
            nameOfFormControl="noKnownLossLetterFiles"
            [submitted]="submitted()"
          ></app-form-field-file>
        </section>

        <section *ngIf="isCurrentStep('waiver-of-subrogation')">
          <h1 class="form-header-below-link">Waiver of subrogation</h1>

          <app-form-field-radio
            [form]="form.get('waiverOfSubrogation')"
            inputId="endorsement-waiversOptIn"
            [submitted]="submitted()"
            questionText="Would you like to add a waiver of subrogation?"
            nameOfFormControl="waiversOptIn"
          >
          </app-form-field-radio>

          <app-waiver-of-subrogation
            prefix="policy"
            [allowRemovingAllWaivers]="true"
            [waiversOfSubrogation]="form.get('waiverOfSubrogation.waiversOfSubrogation')"
            [submitted]="submitted()"
            (addWaiver)="
              formService.addWaiverOfSubrogation(
                form.get('waiverOfSubrogation.waiversOfSubrogation')
              )
            "
            (removeWaiver)="
              formService.removeWaiverOfSubrogationAt(
                $event,
                form.get('waiverOfSubrogation.waiversOfSubrogation')
              )
            "
          >
          </app-waiver-of-subrogation>
        </section>

        <section *ngIf="isCurrentStep('coverages')">
          <h1 class="form-header-below-link">Coverages</h1>

          <ng-container
            *ngFor="
              let building of form.get('coverages.exposures.coveredBuildings').controls;
              index as buildingIdx
            "
          >
            <div class="location-form-address">
              <span aria-label="Address" class="icon-location contact-bullet-icon"></span>
              <span
                >{{ makeFormattedAddress(building.value.address) }} (Building
                {{ building.value.buildingIndex }})</span
              >
            </div>
            <app-form-field-text
              inputId="building-{{ buildingIdx }}-buildingLimit"
              [form]="building"
              [submitted]="submitted()"
              labelText="Building limit"
              nameOfFormControl="buildingLimit"
              placeholder="$"
              appMoneyMask="WITHOUT_DECIMAL"
            >
            </app-form-field-text>
            <section
              *ngIf="formService.buildingLimitHasChanged(buildingIdx)"
              class="form-subsection"
            >
              <app-form-field-textarea
                inputId="building-{{ buildingIdx }}-buildingLimitChangeReason"
                [form]="building"
                [submitted]="submitted()"
                labelText="Please explain the reason for change in coverage"
                nameOfFormControl="buildingLimitChangeReason"
              >
              </app-form-field-textarea>
              <app-form-field-file
                inputId="building-{{ buildingIdx }}-buildingLimitChangeFiles"
                labelText="Supplemental documentation (e.g. receipt of purchase, invoice)"
                [fileMetadata]="{
                  accountId: accountId,
                  policyNumber: policyNumber,
                  jobNumber: endorsementJobNumber
                }"
                [form]="building"
                nameOfFormControl="buildingLimitChangeFiles"
                [submitted]="submitted()"
              ></app-form-field-file>
              <app-form-field-radio-traditional
                inputId="building-{{ buildingIdx }}-buildingLimitChangeInOwnershipStructure"
                labelText="Has there been a change in organizational structure, operation, or insured's scope of work?"
                [form]="building"
                [submitted]="submitted()"
                nameOfFormControl="buildingLimitChangeInOwnershipStructure"
                showErrorText="true"
                cssClass="endorse-policy-info-radio"
                optionsLabelCssClass="label__tight"
              ></app-form-field-radio-traditional>
            </section>
            <app-form-field-text
              inputId="building-{{ buildingIdx }}-limitForBusinessPersonalProperty"
              [form]="building"
              [submitted]="submitted()"
              labelText="Business Personal Property limit"
              nameOfFormControl="limitForBusinessPersonalProperty"
              placeholder="$"
              appMoneyMask="WITHOUT_DECIMAL"
              [errorText]="getBppLimitError(buildingIdx)"
              showErrorText="true"
              tooltipText="(BPP) refers to personal property used in the business but not
              a part of the building or real property. Examples include tablets,
              cell phones, furniture, equipment not permanently installed, and inventory.
              If the insured is a tenant, it should include improvements and betterments"
            >
            </app-form-field-text>
            <section *ngIf="formService.bppLimitHasChanged(buildingIdx)" class="form-subsection">
              <app-form-field-textarea
                inputId="building-{{ buildingIdx }}-bppLimitChangeReason"
                [form]="building"
                [submitted]="submitted()"
                labelText="Please provide details regarding the need for the increased/decreased coverage requested."
                nameOfFormControl="bppLimitChangeReason"
              >
              </app-form-field-textarea>
              <app-form-field-file
                inputId="building-{{ buildingIdx }}-bppLimitChangeFiles"
                labelText="Provide documentation regarding this requested change (e.g. receipt of purchase, invoice)."
                [fileMetadata]="{
                  accountId: accountId,
                  policyNumber: policyNumber,
                  jobNumber: endorsementJobNumber
                }"
                [form]="building"
                nameOfFormControl="bppLimitChangeFiles"
                [submitted]="submitted()"
              ></app-form-field-file>
              <app-form-field-radio-traditional
                inputId="building-{{ buildingIdx }}-bppLimitChangeInOwnershipStructure"
                labelText="Has there been a change in organizational structure, operation, or insured's scope of work?"
                [form]="building"
                [submitted]="submitted()"
                nameOfFormControl="bppLimitChangeInOwnershipStructure"
                showErrorText="true"
                cssClass="endorse-policy-info-radio"
                optionsLabelCssClass="label__tight"
              ></app-form-field-radio-traditional>
            </section>
            <hr />
          </ng-container>

          <app-form-field-textarea
            inputId="miscellaneous-coverage-endorsements"
            [form]="form.get('coverages')"
            [submitted]="submitted()"
            specifierText="(Optional)"
            labelText="Are there any additional coverage changes you would like to make?"
            nameOfFormControl="miscellaneousCoverageChangeRequests"
          >
          </app-form-field-textarea>
        </section>

        <section *ngIf="isCurrentStep('policy-info')">
          <h1 class="form-header-below-link">Policy info</h1>

          <app-form-field-select
            inputId="organizationType"
            labelText="Organization type"
            [form]="form.get('policyInfo')"
            [submitted]="submitted()"
            [readonly]="uwDecisionLoading"
            [availableOptions]="organizationTypes"
            nameOfFormControl="organizationType"
          ></app-form-field-select>

          <section
            *ngFor="let location of locationAddressesFormArray().controls; index as locationIndex"
            [ngClass]="{
              'multi-form-item': true,
              'multi-form-item__gray': !getPrefillDeclineList(locationIndex),
              'multi-form-item__error': !!getPrefillDeclineList(locationIndex)
            }"
          >
            <app-form-field-address
              labelText="Location business address"
              prefix="location-{{ locationIndex + 1 }}"
              [form]="location"
              [submitted]="submitted()"
              [readonly]="uwDecisionLoading"
            ></app-form-field-address>

            <div class="nav-button-group">
              <a
                *ngIf="moreThanOneLocation()"
                class="button button__small button__secondary remove-location"
                (click)="removeLocation(locationIndex)"
                (keyup.enter)="removeLocation(locationIndex)"
                [ngClass]="{ button__disabled: uwDecisionLoading }"
                [attr.aria-disabled]="uwDecisionLoading"
              >
                Remove
              </a>
            </div>

            <div *ngIf="getPrefillDeclineList(locationIndex)" class="validation">
              <div class="validation-message validation-message__left" role="alert">
                We’re sorry, this location is ineligible. Please remove it to continue your
                endorsement.
                <ul class="bulleted-list">
                  <li *ngFor="let reason of getPrefillDeclineList(locationIndex)">{{ reason }}</li>
                </ul>
              </div>
            </div>

            <div *ngIf="getUniqueAddressError(locationIndex)" class="validation">
              <p class="validation-message" role="alert">
                Each location must have a unique address.
              </p>
            </div>
          </section>

          <button
            class="button button__secondary button__full-width js-add-location button__no-margin"
            type="button"
            (click)="addLocation()"
            [disabled]="uwDecisionLoading"
          >
            Add location
          </button>

          <app-form-field-textarea
            inputId="reasonForChange"
            labelText="Please provide an explanation for why the insured is making this change:"
            [form]="form.get('policyInfo')"
            [submitted]="submitted()"
            nameOfFormControl="reasonForChange"
            cssClass="endorse-policy-info-textarea"
            showErrorText="true"
            [readonly]="uwDecisionLoading"
          >
          </app-form-field-textarea>

          <app-form-field-radio-traditional
            inputId="changeInOwnershipStructure"
            labelText="Has there been a change in organizational structure, operation, or insured's scope of work?"
            [form]="form.get('policyInfo')"
            [submitted]="submitted()"
            nameOfFormControl="changeInOwnershipStructure"
            showErrorText="true"
            [readonly]="uwDecisionLoading"
            cssClass="endorse-policy-info-radio"
            optionsLabelCssClass="label__tight"
          ></app-form-field-radio-traditional>
        </section>

        <section *ngIf="isCurrentStep('location-\\d+')" class="form-animation">
          <app-location-form
            [locationIndex]="endorsementLocationIndex()"
            [locationFormGroup]="currentLocationDetails()"
            [availablePropertyDeductibles]="
              availablePropertyDeductiblesForLocation[currentLocationIndex() - 1]
            "
            (navigateToStep)="handleNavigateToSlug($event)"
            [submitted]="submitted()"
            [bopVersion]="bopVersion()"
          ></app-location-form>
        </section>

        <section *ngIf="isCurrentStep('building-\\d+-\\d+')" class="form-animation">
          <app-building-exposure-form
            [locationIndex]="endorsementLocationIndex()"
            [buildingIndex]="buildingIndex()"
            [buildingExposureFormGroup]="currentBuildingExposure()"
            [buildingLessorsRiskFormGroup]="currentBuildingLessorsRisk()"
            [currentLocationDetails]="currentLocationDetails()"
            [displayDeductibleChangedWarning]="displayDeductibleChangedWarning"
            [bopVersion]="bopVersion()"
            [submitted]="submitted()"
          ></app-building-exposure-form>
        </section>

        <section *ngIf="isCurrentStep('building-\\d+-\\d+-lessors-risk')" class="form-animation">
          <app-building-lessors-risk-form
            [accountId]="accountId"
            [tsRequestId]="tsrequestid"
            [bopVersion]="bopVersion()"
            [locationIndex]="endorsementLocationIndex()"
            [buildingIndex]="buildingIndex()"
            [buildingExposureFormGroup]="currentBuildingExposure()"
            [buildingLessorsRiskFormGroup]="currentBuildingLessorsRisk()"
            [formService]="formService"
            [submitted]="submitted()"
          >
          </app-building-lessors-risk-form>
        </section>

        <section *ngIf="isCurrentStep('building-\\d+-\\d+-coverages')" class="form-animation">
          <h1 class="form-header-below-link">
            Location {{ endorsementLocationIndex() }} – Building {{ buildingIndex() }} – Coverages
          </h1>
          <div id="building-{{ endorsementLocationIndex() }}-{{ buildingIndex() }}">
            <app-utility-services-coverage
              [building]="currentBuildingCoverage()"
              [submitted]="submitted()"
              [state]="currentLocationDetails().get('state').value"
              [milesToOcean]="currentLocation().value.locationPrefill.distToEffectiveCoastRangeCode"
              prefix="building-{{ endorsementLocationIndex() }}-{{ buildingIndex() }}"
            >
            </app-utility-services-coverage>

            <app-form-field-radio
              inputId="building-{{ endorsementLocationIndex() }}-{{
                buildingIndex()
              }}-businessIncomeAndExtraExpensesIndemnityInMonths"
              cssClass="js-biee-radio"
              [form]="currentBuildingCoverage()"
              [submitted]="submitted()"
              questionText="BI/EE Period of Indemnity (Months)"
              [options]="businessIncomeAndExtraExpensesIndemnityInMonthsOptions"
              nameOfFormControl="businessIncomeAndExtraExpensesIndemnityInMonths"
              tooltipText="Business Interruption & Extra Expense (BI/EE) responds on an
              Actual Loss Sustained (ALS) basis so there is no scheduled limit. The Period of
              Indemnity is the period of time the benefits are payable under the policy.
              BI/EE contemplates the net income that would have been earned by the applicant
              including normal operating expenses such as payroll."
            >
            </app-form-field-radio>

            <app-catastrophe-coverages
              (openWindDeclineModal)="openWindDeclineModal()"
              [building]="currentBuildingCoverage()"
              [exposure]="currentBuildingExposure()"
              [submitted]="submitted()"
              [state]="currentLocationDetails().get('state').value"
              [constructionType]="currentBuildingExposure().get('constructionType').value"
              [propertyDeductible]="currentLocationDetails().get('propertyDeductible').value"
              [businessType]="currentBuildingExposure().get('businessType').value"
              [lessorsRisk]="currentBuildingExposure().get('lessorsRisk').value"
              [endorsementJobNumber]="endorsementJobNumber"
              [milesToOcean]="currentLocation().value.locationPrefill.distToEffectiveCoastRangeCode"
              [bopVersion]="bopVersion()"
              prefix="building-{{ buildingIndex() }}"
            >
            </app-catastrophe-coverages>

            <h3 class="question-subsection-header">Additional Building Coverage</h3>
            <app-form-field-radio
              inputId="{{ prefix }}-equipmentBreakdownCoverageOptedIn"
              [form]="currentBuildingCoverage()"
              [submitted]="submitted()"
              questionText="Would you like Equipment Breakdown Coverage?"
              nameOfFormControl="equipmentBreakdownCoverageOptedIn"
              tooltipText="This pays for direct physical damage to Covered Property that is the direct
              result of an “accident” or “electronic circuitry impairment”. Examples can include
              mechanical breakdown, electric arcing, explosion, or damage to steam boilers. A $50,000
              limit is included for Data Restoration, Expediting Expenses, Hazardous Substances, and
              Off Premises Equipment Breakdown."
            >
            </app-form-field-radio>

            <app-ordinance-law-coverage
              [coverage]="currentBuildingCoverage()"
              [submitted]="submitted()"
              prefix="building-{{ endorsementLocationIndex() }}-{{ buildingIndex() }}"
            >
            </app-ordinance-law-coverage>

            <app-spoilage-coverage
              [coverage]="currentBuildingCoverage()"
              [exposure]="currentBuildingExposure()"
              [submitted]="submitted()"
              prefix="building-{{ endorsementLocationIndex() }}-{{ buildingIndex() }}"
            >
            </app-spoilage-coverage>

            <app-debris-removal-coverage
              [coverage]="currentBuildingCoverage()"
              [state]="currentLocationDetails().get('state').value"
              [submitted]="submitted()"
              prefix="building-{{ endorsementLocationIndex() }}-{{ buildingIndex() }}"
            >
            </app-debris-removal-coverage>
          </div>
        </section>

        <section *ngIf="isCurrentStep('named-insureds')">
          <h1 class="form-header-below-link">Named Insureds</h1>

          <app-form-field-select
            inputId="typeOfChange"
            labelText="What would you like to do?"
            [form]="form.get('namedInsureds')"
            [submitted]="submitted()"
            [availableOptions]="namedInsuredChangeTypes"
            nameOfFormControl="typeOfChange"
          ></app-form-field-select>
          <app-form-field-text
            inputId="primaryNamedInsured"
            [form]="form.get('namedInsureds')"
            [submitted]="submitted()"
            labelText="Primary Named Insured"
            nameOfFormControl="primaryName"
          >
          </app-form-field-text>
          <app-form-field-address
            labelText="Primary named insured mailing address"
            prefix="namedInsureds.primaryAddress"
            [form]="form.get('namedInsureds.primaryAddress')"
            [submitted]="submitted()"
          >
          </app-form-field-address>
          <app-form-field-text
            inputId="additionalNamedInsuredName"
            [form]="form.get('namedInsureds')"
            [submitted]="submitted()"
            labelText="Name of insured"
            nameOfFormControl="additionalName"
          >
          </app-form-field-text>
          <app-form-field-address
            labelText="Named insured mailing address"
            prefix="namedInsureds.additionalAddress"
            [form]="form.get('namedInsureds.additionalAddress')"
            [submitted]="submitted()"
          >
          </app-form-field-address>
          <app-form-field-textarea
            inputId="namedInsuredExplanation"
            [form]="form.get('namedInsureds')"
            [submitted]="submitted()"
            labelText="Please explain why the insured has requested this endorsement."
            nameOfFormControl="explanation"
          ></app-form-field-textarea>
          <app-form-field-textarea
            inputId="namedInsuredAdditionalOperations"
            [form]="form.get('namedInsureds')"
            [submitted]="submitted()"
            labelText="What are the named insured's operations?"
            nameOfFormControl="additionalOperations"
          ></app-form-field-textarea>
          <app-form-field-radio
            [form]="form.get('namedInsureds')"
            inputId="additionalNamedInsuredCommonOwnership"
            [submitted]="submitted()"
            questionText="Does the named insured entity share at least 51% common ownership with the current named insured?"
            nameOfFormControl="additionalCommonOwnership"
          ></app-form-field-radio>
          <app-form-field-radio
            [form]="form.get('namedInsureds')"
            inputId="namedInsuredChangedOwnership"
            [submitted]="submitted()"
            questionText="Has there been a change in ownership, structure, or operations?"
            nameOfFormControl="hasChangedOwnership"
          >
          </app-form-field-radio>
          <section class="form-subsection">
            <app-form-field-textarea
              inputId="namedInsuredChangeDescription"
              [form]="form.get('namedInsureds')"
              [submitted]="submitted()"
              labelText="Please describe the change in operations and/or ownership structure."
              nameOfFormControl="descriptionOfChange"
            ></app-form-field-textarea>
          </section>
        </section>

        <section *ngIf="isCurrentStep('other-endorsement')">
          <h1 class="form-header-below-link">Other change requests</h1>
          <app-form-field-textarea
            inputId="otherEndorsement-zendeskEndorsement"
            [form]="form.get('otherEndorsements')"
            [submitted]="submitted()"
            labelText="Please provide description of change or coverage
          requirements."
            nameOfFormControl="zendeskEndorsement"
          ></app-form-field-textarea>
        </section>

        <section
          *ngIf="isCurrentStep('review') && quoteReturnedSuccessfully && isEndorsementAutomated()"
        >
          <h1>Review</h1>
          <div class="js-policy-change-description">
            <p>
              {{ policyChangeDescription }}
            </p>
          </div>
          <hr />
          <div>
            <dl class="dl__no-dotted-line">
              <dt>Original policy premium</dt>
              <dd class="js-payment-details-original-premium">
                {{ getOriginalPolicyPremium() | currency: 'USD':'symbol':'1.2-2' }}
              </dd>
            </dl>
            <dl class="dl__no-dotted-line">
              <dt>Total premium change</dt>
              <dd class="js-payment-details-premium-change">
                <span *ngIf="priceDifference < 0">- </span>
                <span *ngIf="priceDifference > 0">+ </span>
                {{ getAbsoluteValue(priceDifference) | currency: 'USD':'symbol':'1.2-2' }}
              </dd>
            </dl>
          </div>

          <div class="bind-quote-total">
            <div class="bind-quote-total-lineitem">
              <strong>New total premium</strong>
              <div class="big-total js-payment-details-total">
                <span>$</span>{{ getTotalInteger(totalCost) | number: '2.0-0' }}.<span
                  class="big-total-cents"
                  >{{ getEstimatedFraction(totalCost) | number: '2.0-0' }}</span
                >
              </div>
            </div>
          </div>
        </section>

        <section *ngIf="isCurrentStep('review') && !isEndorsementAutomated()">
          <ng-container *ngIf="!policyChangeFailed && !policyChangeTimedOut">
            <h1>Review Required</h1>
            <app-dialog-box type="primary" class="dialog-text__margin-bottom">
              Your request requires underwriter review. Our team will review your request and reach
              out to the email below within 24-48 hours.
            </app-dialog-box>
          </ng-container>

          <ng-container *ngIf="policyChangeFailed || policyChangeTimedOut">
            <h1>Oops...something went wrong</h1>
            <app-dialog-box type="warning" class="dialog-text__margin-bottom">
              We had some trouble processing your request. Our team will manually review your
              request and reach out to the email below within 24-48 hours.
            </app-dialog-box>
          </ng-container>

          <app-form-field-text
            inputId="brokerEmailAddress"
            [form]="form.get('review')"
            [submitted]="submitted()"
            labelText="Broker email"
            nameOfFormControl="brokerEmailAddress"
            appRemoveWhitespaceMask
          >
          </app-form-field-text>
        </section>

        <section class="form-footer">
          <div
            class="validation"
            role="alert"
            *ngIf="submitted() && !formService.isCurrentStepValid()"
          >
            <p class="validation-message" [innerHTML]="getValidationMessage()"></p>
          </div>

          <div class="nav-button-group nav-button-group__left">
            <button
              id="endorse-form-submit-button"
              [ngClass]="{
                button: true,
                'js-step-forward-button': true,
                button__discouraged: !formService.isCurrentStepValid(),
                button__primary:
                  formService.getNextButtonText() === 'Endorse' ||
                  formService.getNextButtonText() === 'Next'
              }"
              type="submit"
              [disabled]="uwDecisionLoading || eligibilityCheckLoading"
            >
              <ng-container *ngIf="uwDecisionLoading">
                <div class="button-loader">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
                Pulling location info...
              </ng-container>
              <ng-container *ngIf="!uwDecisionLoading">
                <div class="button-loader" *ngIf="isCreatingZendeskEndorsement">
                  <svg class="circle-spinner">
                    <circle
                      cx="11"
                      cy="11"
                      r="9"
                      stroke-width="2"
                      stroke-dasharray="0,1000"
                      d="M5 40 l215 0"
                      fill="transparent"
                    />
                  </svg>
                </div>
                {{ formService.getNextButtonText() }}
              </ng-container>
            </button>
            <a
              [routerLink]="['/accounts/', accountId]"
              class="button button__secondary"
              *ngIf="!formService.isFirstStep() && formService.isFinalStep()"
              >Cancel</a
            >
          </div>

          <ng-container *ngIf="isDevMode">
            <hr />
            <div class="adminz-only">
              <h4>Errors</h4>
              <pre>{{ allErrors() | json }}</pre>
              <h4>Form value</h4>
              <pre>{{ formService.form.value | json }}</pre>
            </div>
          </ng-container>
        </section>
      </form>
    </ng-container>
  </div>
  <div *ngIf="displayFaqs()" class="app-page-form-side-rail js-endorsement-faqs">
    <app-attune-bop-endorse-faqs-panel></app-attune-bop-endorse-faqs-panel>
    <div>
      <a
        class="mt-3"
        data-amplitude-input-name="document-download-endorsements-guide"
        href="{{ brokerEndorsementsGuide }}"
        target="_blank"
      >
        Broker Endorsements Guide
      </a>
    </div>
  </div>
</div>

<div class="quoting-game-container" *ngIf="isQuoteInProgress()">
  <app-quoting-game
    [finish$]="getQuoteSuccessSubject()"
    title="Processing endorsement quote"
    successComment="Preparing quote..."
  >
  </app-quoting-game>
</div>

<ng-container *ngIf="isBindInProgress()">
  <app-binding-animation
    [showProgressBar]="true"
    [finish$]="getBindSuccess()"
  ></app-binding-animation>
</ng-container>
