import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { HiscoxQuoteService } from '../../services/hiscox-quote.service';
import { HISCOX_API_VERSION, HISCOX_PRODUCTS } from '../../models/hiscox-types';
import { HiscoxBackendPricedQuote, HiscoxPricedQuote } from '../../models/hiscox-priced-quote';
import { InformService } from '../../../../core/services/inform.service';
import { US_DATE_MASK } from '../../../../constants';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import {
  CrossSellConfiguration,
  DismissalRecords,
  CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY,
  GL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION,
  GL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION,
  HISCOX_GL_CYBER_CROSS_SELL_CONFIGURATION,
  HISCOX_PL_CYBER_CROSS_SELL_CONFIGURATION,
  PL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION,
  PL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION,
} from '../../../../shared/product-cross-sell/configs';
import { InsuredAccountService } from '../../../insured-account/services/insured-account.service';
import { NaicsService, ProductEligibility } from '../../../../shared/services/naics.service';
import {
  InsuredAccountSummaryService,
  FrontendQuoteWithLinks,
} from '../../../insured-account/services/insured-account-summary.service';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { SentryService } from '../../../../core/services/sentry.service';
import { BundleQuotePrimaryProductName } from '../../../../shared/bundle-quote-review-modal/bundle-quote-review-modal.component';
import { ProducerDetailsResponse } from '../../../../bop/guidewire/typings';
import {
  NON_BINDING_ROLES,
  UNABLE_TO_BIND_MESSAGE,
  UserService,
} from '../../../../core/services/user.service';
import { CoalitionCyberDuplicateBrokerZendeskTicketService } from '../../../coalition/services/coalition-cyber-duplicate-broker-zendesk-ticket.service';
import {
  ADMITTED_KNOCK_OUT_STATES,
  BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL,
} from '../../../coalition/models/cyber-constants.model';
import { DocumentService } from '../../../documents/services/document.service';
import { getDcpQuoteLetterUrl } from '../../../documents/models/document-urls.model';
import { getQuoteLetterFileName } from '../../../documents/models/document-file-names.model';

@Component({
  selector: 'app-hiscox-quote-page.policy-pane',
  templateUrl: './hiscox-quote-details-page.component.html',
  providers: [AsyncPipe],
})
export class HiscoxQuoteDetailsPageComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();
  quote$: Observable<HiscoxPricedQuote | null>;
  quote: HiscoxPricedQuote;
  product: HISCOX_PRODUCTS;
  accountId: string;
  nonBindingRole: boolean;
  version: HISCOX_API_VERSION;
  quoteLetterLoading = false;
  quoteLetterError = false;

  isCrossSellWindowEnabled = false;
  isCrossSellWindowDismissed = true;
  isAccountEligibleForCyber = false;
  isBrokerEnabledForCyber = false;
  doesAccountHaveCyberQuotes = false;
  showUpsellStatusCard = false;
  hiscoxGlPlCyberCrossSellConfiguration: CrossSellConfiguration | null = null;

  // Bundle quote review properties
  showBundleQuoteReview = false;
  secondaryQuote$ = new BehaviorSubject<FrontendQuoteWithLinks | null>(null);
  productForBundleQuoteReview: BundleQuotePrimaryProductName;

  US_DATE_MASK = US_DATE_MASK;

  // Product availability
  isHiscoxV4Available = false;
  quoteLetterDownload$: Observable<any>;
  quoteLetterDownloadReady$: Observable<any>;
  quoteLetterErrorMessage: string;
  secondaryQuoteLetterDownload$: Observable<any>;

  constructor(
    private cyberDuplicateBrokerZendeskTicketService: CoalitionCyberDuplicateBrokerZendeskTicketService,
    private hiscoxQuoteService: HiscoxQuoteService,
    private documentService: DocumentService,
    private informService: InformService,
    private route: ActivatedRoute,
    private router: Router,
    private accountSummaryService: InsuredAccountSummaryService,
    private naicsService: NaicsService,
    private insuredAccountService: InsuredAccountService,
    private amplitudeService: AmplitudeService,
    private featureFlagService: FeatureFlagService,
    private sentryService: SentryService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.accountId = this.route.snapshot.params.accountId;
    const productIndex = this.route.snapshot.url.length - 2;
    const frontendProductType = this.route.snapshot.url[productIndex].path;
    this.product = frontendProductType.slice(0, 2) as HISCOX_PRODUCTS;
    this.version = HISCOX_API_VERSION.v4;
    this.setProductForBundleQuoteReviewModal();
    this.setUpCrossSellConfig();

    this.quote$ = this.hiscoxQuoteService.fetchQuote(this.product, this.accountId).pipe(
      map((quote) => new HiscoxPricedQuote(<HiscoxBackendPricedQuote>quote)),
      tap((quote) => {
        if (quote) {
          this.quote = quote;
          this.initializeQuoteLetterDownload();
        } else {
          this.sentryService.notify(`Hiscox ${this.product}: Error loading quote`, {
            severity: 'error',
            metaData: {
              url: this.route.snapshot.url,
            },
          });

          this.handleRedirectError();
        }
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.HISCOX_CYBER_CROSS_SELL_WINDOW)
        .subscribe((value) => {
          this.isCrossSellWindowEnabled = value || false;
        })
    );

    const accountSummary$ = this.accountSummaryService.getSummary(this.accountId);

    this.sub.add(
      combineLatest(
        this.insuredAccountService.get(this.accountId),
        accountSummary$,
        this.naicsService.getProductAvailability()
      ).subscribe(([model, accountSummary, availabilities]) => {
        this.isBrokerEnabledForCyber =
          availabilities &&
          availabilities.some((eligibility) => {
            return (
              eligibility.pasSource === 'coalition' && eligibility.classCodeSelection === 'ALL'
            );
          });

        this.isHiscoxV4Available =
          availabilities &&
          availabilities.some((eligibility) => {
            return (
              (eligibility.product === 'gl' && eligibility.classCodeSelection !== 'NONE') ||
              (eligibility.product === 'pl' && eligibility.classCodeSelection !== 'NONE')
            );
          });

        this.doesAccountHaveCyberQuotes =
          accountSummary &&
          accountSummary.status === 'success' &&
          accountSummary.quotes.some((quote) => {
            return quote.pasSource === 'coalition';
          });
        if (!model || !model.naicsCode) {
          return;
        }
        this.naicsService
          .getProductEligibility(model.naicsCode.hash, model.state)
          .subscribe((resp: ProductEligibility) => {
            const isAccountInEligibleState = !ADMITTED_KNOCK_OUT_STATES.includes(model.state);
            this.isAccountEligibleForCyber =
              resp && resp.isCyberEligible && isAccountInEligibleState;
          });
      })
    );

    this.sub.add(
      this.route.queryParams.subscribe((queryParams) => {
        if (!queryParams.secondaryQuoteId) {
          if (queryParams.secondaryQuoteBrokerOfRecordError) {
            this.hiscoxGlPlCyberCrossSellConfiguration =
              this.product === HISCOX_PRODUCTS.gl
                ? GL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION
                : PL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION;
            this.showUpsellStatusCard = true;
          } else if (queryParams.secondaryQuoteDuplicateBrokerError) {
            this.hiscoxGlPlCyberCrossSellConfiguration =
              this.product === HISCOX_PRODUCTS.gl
                ? GL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION
                : PL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION;
            this.showUpsellStatusCard = true;
          }
          return;
        }
      })
    );

    // Check if we should show the bundle quote review modal
    this.sub.add(
      combineLatest(this.route.queryParams, accountSummary$).subscribe(
        ([queryParams, accountSummary]) => {
          if (!queryParams.secondaryQuoteId || accountSummary.status !== 'success') {
            return;
          }
          const secondaryQuote = accountSummary.quotes.find(({ uuid, pasSource }) => {
            return uuid === queryParams.secondaryQuoteId && pasSource === 'coalition';
          });
          const secondaryQuoteId = secondaryQuote?.uuid || null;
          const url = getDcpQuoteLetterUrl(secondaryQuoteId || '');
          const fileName = getQuoteLetterFileName(secondaryQuoteId || '', 'cyber_admitted');
          this.secondaryQuoteLetterDownload$ = this.documentService.getDocument(
            url,
            fileName,
            'pdf'
          );
          this.secondaryQuote$.next(secondaryQuote || null);
          this.showBundleQuoteReview = !!secondaryQuote;
        }
      )
    );

    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );
  }

  setUpCrossSellConfig() {
    if (this.product === HISCOX_PRODUCTS.gl) {
      this.hiscoxGlPlCyberCrossSellConfiguration = HISCOX_GL_CYBER_CROSS_SELL_CONFIGURATION;
    } else if (this.product === HISCOX_PRODUCTS.pl) {
      this.hiscoxGlPlCyberCrossSellConfiguration = HISCOX_PL_CYBER_CROSS_SELL_CONFIGURATION;
    }

    const accountIds: DismissalRecords | string[] = JSON.parse(
      localStorage.getItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY) || '{}'
    );

    // Ignore the defunct localstorage record format (array) when checking whether to render
    if (
      !_.isArray(accountIds) &&
      Object.prototype.hasOwnProperty.call(accountIds, this.accountId)
    ) {
      const { lastDismissedAt, doNotShow } = accountIds[this.accountId];

      const now = moment();
      this.isCrossSellWindowDismissed = doNotShow || now.isSameOrBefore(lastDismissedAt, 'date');
    } else {
      this.isCrossSellWindowDismissed = false;
    }
  }

  isExpired() {
    if (!this.quote || !this.quote.effectiveDate) {
      return;
    }

    // EOD needed, otherwise formatting this in current timezone puts date on previous day
    const effectiveDateUTC = this.quote.effectiveDate.endOf('day').format();
    const tz = moment.tz.guess();
    // Quote effective date is valid through end of day
    const effectiveDateLocal = moment.tz(effectiveDateUTC, tz).endOf('day');
    const currentDateTime = moment.tz(tz);

    return effectiveDateLocal.isBefore(currentDateTime);
  }

  continueToBind() {
    if (this.nonBindingRole) {
      this.informService.infoToast(UNABLE_TO_BIND_MESSAGE);
      return;
    }
    if (this.isExpired()) {
      this.informService.infoToast(
        'The effective date for this quote has expired. Please edit this quote and update the effective date in order to bind.'
      );
      return;
    }

    this.router.navigate([
      '/accounts',
      this.accountId,
      'hiscox',
      this.product,
      'policies',
      this.quote.quoteId,
      'bind',
      this.quote.version,
    ]);
  }

  handleProductCrossSellWindowEvent($event: { close: boolean; submit: boolean }) {
    if ($event.close) {
      const rawStoredAccountIds = localStorage.getItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY);
      const storedAccountIds: DismissalRecords | string[] | null = rawStoredAccountIds
        ? JSON.parse(rawStoredAccountIds)
        : null;

      let accountIds: DismissalRecords;
      if (!storedAccountIds) {
        accountIds = {};
      } else if (_.isArray(storedAccountIds)) {
        // This localstorage record was previously an array; handle the case where the user has the old format stored.
        const yesterday = moment().subtract(1, 'day').format(US_DATE_MASK);
        accountIds = _.reduce(
          storedAccountIds,
          (prev: DismissalRecords, accountId) => {
            prev[accountId] = {
              lastDismissedAt: yesterday,
              doNotShow: false,
            };
            return prev;
          },
          {}
        );
      } else {
        accountIds = storedAccountIds;
      }

      const today = moment().format(US_DATE_MASK);

      if (Object.prototype.hasOwnProperty.call(accountIds, this.accountId)) {
        // if it already existed then this is the second time it is being dismissed
        accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: true };
      } else {
        accountIds[this.accountId] = { lastDismissedAt: today, doNotShow: false };
      }

      localStorage.setItem(CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY, JSON.stringify(accountIds));

      this.amplitudeService.track({
        eventName: 'hiscox_close_cyber_cross_sell_window',
        detail: this.hiscoxGlPlCyberCrossSellConfiguration?.baseProduct ?? '',
      });

      this.isCrossSellWindowDismissed = true;
    }

    if ($event.submit) {
      if (
        this.hiscoxGlPlCyberCrossSellConfiguration ===
          GL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION ||
        this.hiscoxGlPlCyberCrossSellConfiguration ===
          GL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION
      ) {
        this.router.navigate([BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL]);
        return;
      } else if (
        this.hiscoxGlPlCyberCrossSellConfiguration ===
          GL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION ||
        this.hiscoxGlPlCyberCrossSellConfiguration ===
          PL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION
      ) {
        this.cyberDuplicateBrokerZendeskTicketService
          .createTicket('cyber_admitted', this.accountId)
          .subscribe((_createTicketResponse) => {
            this.showUpsellStatusCard = false;
          });
        return;
      }
      this.amplitudeService.track({
        eventName: 'hiscox_new_quote_via_cyber_cross_sell_window',
        detail: this.hiscoxGlPlCyberCrossSellConfiguration?.baseProduct ?? '',
      });
      this.router.navigate([`/accounts/${this.accountId}/cyber-admitted/new`], {
        replaceUrl: true,
      });
    }
  }

  handleRedirectError() {
    this.router.navigate(['accounts'], { replaceUrl: true });
    this.informService.errorToast(
      `We encountered an error loading the Hiscox quote details.`,
      null,
      null,
      'Okay',
      null,
      0
    );
  }

  setProductForBundleQuoteReviewModal() {
    switch (this.product) {
      case 'gl':
        this.productForBundleQuoteReview = 'GL';
        break;
      case 'pl':
        this.productForBundleQuoteReview = 'PL';
        break;
    }
  }

  handleBundleQuoteReviewModalClose() {
    this.showBundleQuoteReview = false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private initializeQuoteLetterDownload() {
    if (this.isExpired()) {
      this.quoteLetterErrorMessage = `${this.product.toUpperCase()} quote effective date is in the past, please edit and requote to access quote letter.`;
      this.quoteLetterDownload$ = throwError(() => new Error());
    } else {
      this.quoteLetterErrorMessage = `We encountered an error loading the ${this.product.toUpperCase()} quote letter for this account.  Note: If you have this quote open in the Hiscox NOW Small Business Program portal in another browser tab, please close that tab before retrying.`;
      this.quoteLetterDownload$ = this.hiscoxQuoteService
        .getQuotePdfUrl(this.product, this.accountId)
        .pipe(
          tap((hiscoxQuoteLetterPdf: string) => {
            this.quote.hasQuoteLetter = true;
            this.quoteLetterLoading = false;
            // NOTE: using the `download` attribute to rename the file does not work
            // because this only works on the same-origin and the `hiscoxQuoteLetterPdf` url
            // is a link to an external server location
            const link = document.createElement('a');
            link.href = hiscoxQuoteLetterPdf;
            link.click();
            link.remove();
          })
        );
    }
  }
}
