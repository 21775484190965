import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductCombinationForAvailability } from 'app/features/digital-carrier/models/types';

@Component({
  selector: 'app-insured-account-product-eligibility-card',
  templateUrl: './insured-account-product-eligibility-card.component.html',
})
export class InsuredAccountProductEligibilityCardComponent {
  @Input() loading: boolean;
  @Input() small: boolean;

  @Input() lob: ProductCombinationForAvailability['product'];
  @Input() carrier: ProductCombinationForAvailability['pasSource'];
  @Input() guidelinesLink: string;
  @Input() isAvailable: boolean;
  @Input() isEligible: boolean;
  @Input() hasQuotes?: boolean;
  @Input() hasEditableQuotes?: boolean;

  @Output() navigate: EventEmitter<{ type: 'new' | 'edit' }> = new EventEmitter();
}
