export abstract class AbstractPolicyPayment {
  totalPremium: number;

  constructor(totalPremium: number) {
    this.totalPremium = totalPremium;
  }

  abstract getDownPaymentPremium(numberOfPayments: number): number;

  getInstallment(numberOfPayments: number) {
    if (numberOfPayments === 1) {
      return 0;
    }

    const numberOfInstallments = numberOfPayments - 1;
    return (
      (this.totalPremium - this.getDownPaymentPremium(numberOfPayments)) / numberOfInstallments
    );
  }
}
