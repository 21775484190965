<div
  *ngIf="!clear && isEligible"
  class="
    multi-form-item
    form-flow-rail-card form-flow-rail-card__padded form-flow-rail-card__rewards
  "
>
  <img src="/assets/img/rewards-chest-with-stars.svg" alt="" />
  <p>Start binding to earn rewards!</p>
  <p>
    Earn an extra $100 for each policy bound (on up to three binds) before {{ incentiveEndDate }}.
  </p>
  <a
    class="rewards-terms"
    href="https://www.attuneinsurance.com/legal/new-user-rewards-2023"
    target="_blank"
    >Terms & conditions</a
  >
</div>

<div
  *ngIf="clear && isEligible"
  class="form-flow-rail-card form-flow-rail-card__centered form-flow-rail-card__padded"
>
  <img src="/assets/img/rewards-chest.svg" alt="" />
  <p class="gray-text">Start binding to earn rewards!</p>
  <p class="gray-text">
    Earn an extra $100 for each policy bound (on up to three binds) before {{ incentiveEndDate }}.
  </p>
  <a
    class="gray-text rewards-terms"
    href="https://www.attuneinsurance.com/legal/new-user-rewards-2023"
    target="_blank"
    >Terms & conditions</a
  >

  <a
    [routerLink]="'/accounts/new'"
    class="button button__small button__primary"
    [attr.data-amplitude-input-name]="'new-account-incentive-get-quote'"
  >
    Start a new quote
  </a>
</div>
