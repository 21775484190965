import { Injectable } from '@angular/core';
import { UsState } from 'app/shared/models/us-state';
import { chain } from 'lodash';

export const US_STATES: UsState[] = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District Of Columbia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' },
];

export const US_STATES_DICT = chain(US_STATES).keyBy('value').mapValues('name').value();

export type UsStateAbbreviation = keyof typeof US_STATES_DICT;

@Injectable()
export class UsStateService {
  getUsStates(): UsState[] {
    return US_STATES;
  }

  // { value: 'AL', name: 'AL' }
  getUsStateAbbreviations() {
    const abbreviations: { [key: string]: string } = this.getUsStates().reduce(
      (usStates: { [key: string]: string }, usState: UsState) => {
        usStates[usState.value] = usState.value;
        return usStates;
      },
      {}
    );
    return abbreviations;
  }

  // { value: 'AL', name: 'Alabama' }
  getUsStatesByAbbreviation() {
    const statesByAbbreviation: { [key: string]: string } = this.getUsStates().reduce(
      (usStates: { [key: string]: string }, usState: UsState) => {
        usStates[usState.value] = usState.name;
        return usStates;
      },
      {}
    );
    return statesByAbbreviation;
  }

  getUsFullStateNameByAbbreviation(stateAbbrevation: string) {
    const statesByAbbreviation = this.getUsStatesByAbbreviation();
    return statesByAbbreviation[stateAbbrevation];
  }

  // { value: 'Alabama', name: 'AL' }
  getUsStatesByFullName() {
    const statesByFullName: { [key: string]: string } = this.getUsStates().reduce(
      (usStates: { [key: string]: string }, usState: UsState) => {
        usStates[usState.name] = usState.value;
        return usStates;
      },
      {}
    );
    return statesByFullName;
  }
}
