import * as moment from 'moment';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { of as observableOf, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { InvoicesListService } from 'app/features/invoices/services/invoices-list.service';
import { AttuneBopEndorseQuoteService } from 'app/features/attune-bop/services/attune-bop-endorse-quote.service';
import { environment } from 'environments/environment';
import { HAS_ENDORSEMENTS } from 'app/constants';
import { HURRICANE_IAN_MORATORIUM_STATES } from 'app/features/attune-bop/models/constants';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from '../../features/insured-account/services/insured-account.service';
import { AccountPolicyPeriod, PolicyPeriod } from 'app/bop/guidewire/typings';
import { UserService } from 'app/core/services/user.service';
import {
  ENDORSABLE_STATUSES,
  WITHDRAWN_RESCINDED_STATUSES,
} from '../../features/insured-account/models/insured-account.model';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  NewInformationalService,
  INFORMATIONAL_NAMES,
  BOP_BIND_INFORMATIONAL,
} from '../services/new-informational.service';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { HelpFormFieldNames } from 'app/features/support/models/support-help-center-constants';

interface NoticeInfo {
  classModifer: string;
  billingDueDate: Date;
}

@Component({
  selector: 'app-policy-details-pane.policy-pane.policy-pane__bop',
  templateUrl: './policy-details-pane.component.html',
})
export class PolicyDetailsPaneComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();
  policyTermTransaction: PolicyPeriod;
  cancellationDate: moment.Moment | null = null;
  insuredAccount: InsuredAccount;
  accountId: string;
  termNumber: string;
  shouldSeeEndorsementButton = HAS_ENDORSEMENTS;
  displayEndorsementError = false;
  isCreatingEndorsement = false;
  paymentNotice?: NoticeInfo;
  showNewRenewalsUI = environment.showNewRenewalsUI;
  policyNumber: string;
  isEndorsementPending: boolean;
  paymentStatus: string;

  loggedEndorseTip = false;

  constructor(
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService,
    private insuredAccountService: InsuredAccountService,
    private billingListService: InvoicesListService,
    private userService: UserService,
    private informService: InformService,
    private endorseQuoteService: AttuneBopEndorseQuoteService,
    private newInformationalService: NewInformationalService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            return this.insuredAccountService.get(params['accountId']);
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccount = insuredAccount;
        })
    );
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.policyNumber = params['policyId'];
            this.termNumber = params['termNumber'];
            this.accountId = params['accountId'];

            return this.insuredAccountService.retrievePolicyTransactionTerms(
              this.policyNumber,
              this.termNumber
            );
          }),
          switchMap((transactionResponse) => {
            if (!transactionResponse) {
              this.informService.errorToast(
                'Could not load information about policy: please try again.'
              );
              return observableOf([]);
            }
            this.policyTermTransaction = transactionResponse;
            this.cancellationDate = this.getCancellationDate(this.policyTermTransaction);

            if (this.policyTermTransaction.Job.Subtype !== 'Renewal') {
              return observableOf([]);
            }
            return this.userService.getUser().pipe(
              switchMap((user) => {
                return this.billingListService.getInvoiceList(
                  user.producer,
                  0,
                  25,
                  undefined,
                  this.accountId,
                  undefined
                );
              })
            );
          })
        )
        .subscribe((invoiceListOrError) => {
          // TODO(WJC): We should find a way to get rid of the casting here, which leaves us vulnerable to errors
          if (invoiceListOrError && (invoiceListOrError as HttpErrorResponse).error) {
            // Note: Ignore error response -- already logged to Sentry in service.
            return;
          }
          const invoiceList = (invoiceListOrError as BackendInvoiceSummary).invoices || [];
          const invoice = invoiceList.find((innerInvoice) => {
            return innerInvoice.lineItems.some((lineItem) => {
              return lineItem.policyNumber === this.policyTermTransaction.PolicyNumber;
            });
          });
          if (!invoice) {
            // This means that the invoice just hasn't been set to billed yet by the batch process. Simply wait for it to be billed
            return;
          }
          this.paymentStatus = invoice.status;
          if (this.paymentStatus === 'Paid') {
            // If the invoice has already been paid, do not show the notice
            return;
          }

          const effectiveDate = moment.utc(this.policyTermTransaction.EditEffectiveDate);
          const isAfterEffectiveDate = moment.utc().isAfter(effectiveDate);
          this.paymentNotice = {
            classModifer: isAfterEffectiveDate ? 'error' : 'warning',
            billingDueDate: moment.utc(invoice.dueDate).toDate(),
          };
        })
    );

    this.sub.add(
      this.endorseQuoteService.getZendeskEndorsement(this.policyNumber).subscribe((response) => {
        if (response && response.openZendeskTickets.length > 0) {
          this.isEndorsementPending = true;
        }
      })
    );
  }

  getCancellationDate(policyTermTransaction: PolicyPeriod) {
    const policies = _.get(
      policyTermTransaction,
      'PolicyTerm.PortalViewableTermPeriods.Entry',
      []
    ) as AccountPolicyPeriod[];

    if (policies.length) {
      const cancelDates = policies.reduce((dates: moment.Moment[], policy) => {
        if (policy.Job.Subtype === 'Cancellation' && policy.Status !== 'Rescinded') {
          const cancelDate = moment.utc(policy.EditEffectiveDate);
          dates.push(cancelDate);
        }

        return dates;
      }, []);
      return moment.min(cancelDates);
    }

    return null;
  }

  createPolicyChangeEndorsement() {
    // If bop policy is pending cancellation or there is a CAT moratorium, show the tooltip error and do not create an endorsement.
    if (
      this.isBopPolicyPendingCancellation() ||
      this.isEndorsementPending ||
      this.isUnderCatMoratorium()
    ) {
      this.displayEndorsementError = true;
      return;
    }

    this.isCreatingEndorsement = true;

    const binds = this.policyTermTransaction.PolicyTerm.PortalViewableTermPeriods.Entry;
    let latestMoment = moment();
    for (const termPeriod of binds) {
      if (moment(termPeriod.EditEffectiveDate).isAfter(latestMoment)) {
        latestMoment = moment(termPeriod.EditEffectiveDate);
      }
    }

    this.sub.add(
      this.endorseQuoteService
        .createPolicyChange(this.policyTermTransaction.PolicyNumber, this.termNumber, latestMoment)
        .subscribe((response) => {
          this.isCreatingEndorsement = false;
          const queryParams = { policyNumber: this.policyNumber, term: this.termNumber };
          if (response && response.jobNumber) {
            if (!this.newInformationalService.getValue(INFORMATIONAL_NAMES.NEW_ENDORSE_BUTTON)) {
              this.newInformationalService.incrementValue(INFORMATIONAL_NAMES.NEW_ENDORSE_BUTTON);
            }
            this.completeEndorseTip();
            this.router.navigate(
              ['/accounts', this.accountId, 'bop', 'endorsements', response.jobNumber],
              { queryParams }
            );
          }
        })
    );
  }

  showEndorseTip() {
    const visible = this.newInformationalService.isUserAtBopBindState(
      BOP_BIND_INFORMATIONAL.ENDORSE_BUTTON_TIP
    );
    if (visible && !this.loggedEndorseTip) {
      this.amplitudeService.track({
        eventName: 'bop_bind_education_endorsement_tip',
        detail: 'bop',
        useLegacyEventName: true,
      });
      this.loggedEndorseTip = true;
    }
    return visible;
  }

  completeEndorseTip() {
    this.newInformationalService.completeBopBindState(BOP_BIND_INFORMATIONAL.ENDORSE_BUTTON_TIP);
  }

  isRenewal() {
    if (!this.policyTermTransaction) {
      return false;
    }
    if (this.policyTermTransaction.TermDisplayStatus_ATN === 'In Force') {
      return false;
    }
    if (this.termNumber === '1') {
      return false;
    }
    return true;
  }

  helpCenterQueryParams() {
    return {
      [HelpFormFieldNames.Account]: this.accountId ? encodeURIComponent(this.accountId) : '',
      [HelpFormFieldNames.Policy]: this.policyNumber ? encodeURIComponent(this.policyNumber) : '',
      [HelpFormFieldNames.CancellationDate]: this.cancellationDate
        ? encodeURIComponent(this.cancellationDate.format('MM-DD-YYYY'))
        : '',
    };
  }

  showReinstatementButton() {
    if (
      !this.isBop() ||
      this.policyTermTransaction.TermDisplayStatus_ATN !== 'Canceled' ||
      !this.cancellationDate
    ) {
      return false;
    }

    const thirtyDaysAgo = moment.utc().subtract(30, 'days');

    return this.cancellationDate.isSameOrAfter(thirtyDaysAgo);
  }

  isBopEndorsable() {
    const terms = _.get(
      this.policyTermTransaction,
      'PolicyTerm.PortalViewableTermPeriods.Entry',
      []
    );

    const hasEndorsableStatus =
      terms.some((term: PolicyPeriod) =>
        ENDORSABLE_STATUSES.includes(term.TermDisplayStatus_ATN)
      ) || _.get(this.policyTermTransaction, 'TermDisplayStatus_ATN') === 'In Force';
    return hasEndorsableStatus && this.isBop();
  }

  isBop() {
    return (_.get(this.policyTermTransaction, 'LineBusinessType') || '').includes('Businessowners');
  }

  isUnderCatMoratorium() {
    // TODO(NY) - Remove after Hurricane Ian moratorium is lifted.
    const baseState = this.policyTermTransaction.BaseState;
    return HURRICANE_IAN_MORATORIUM_STATES.includes(baseState);
  }

  isBopPolicyPendingCancellation() {
    const portalTermTransactions: AccountPolicyPeriod[] = _.get(
      this.policyTermTransaction,
      'PolicyTerm.PortalViewableTermPeriods.Entry',
      []
    );
    const latestPortalTermTransaction = portalTermTransactions[portalTermTransactions.length - 1];

    if (latestPortalTermTransaction) {
      return (
        latestPortalTermTransaction.Job.Subtype === 'Cancellation' &&
        !WITHDRAWN_RESCINDED_STATUSES.includes(latestPortalTermTransaction.Status || '')
      );
    }
  }

  ngOnDestroy() {
    // When the user dismisses this pane, mark them as having seen the informational it contains
    // If the user is not already at the appropriate point in the bind education flow, this call is a no-op
    this.newInformationalService.completeBopBindState(BOP_BIND_INFORMATIONAL.ENDORSE_BUTTON_TIP);
  }
}
