import * as moment from 'moment';
import { startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import {
  DependencyConfig,
  DependencySource,
  DependencyOperator,
  EvaluatedValueName,
  ComplexEvaluatorFunc,
  LibertyMutualClassCode,
} from './common-typings';
import {
  LmCpspQuestion,
  LmCpspLossHistoryQuestion,
  LmCpspNestedControls,
  LmCpspComplexEvaluator,
} from './lm-cpsp-typings';

import { getControl } from 'app/shared/helpers/form-helpers';
import { cpspControlPath, cpspLossHistoryControlPath } from './lm-cpsp-constants';
import { disabledOrEmpty, isEmptyValue } from './helpers';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';
import {
  getCspCode,
  LM_CPSP_INCIDENTAL_APT_CSP_CLASS_CODES,
  LM_CPSP_LIMITED_COOKING_CSP_CLASS_CODES,
  LM_CPSP_MERC_AND_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES,
  LM_CPSP_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES,
  LM_CPSP_WA_RESIDENTIAL_CSP_CODES,
  LM_CPSP_MS_MERC_CSP_CODES,
  LM_CPSP_MS_RESIDENTIAL_CSP_CODES,
  LM_CPSP_MA_CSP_RESIDENTIAL_CLASSES,
  ALCOHOL_RELATED_CLASS_CODES,
  LM_CPSP_LIMITED_COOKING_RELATED_CSP_CLASS_CODES,
} from './property-class-codes';

const VALID_PRIOR_COVERAGE_REASONS_NEW_ACQUISITION_OR_NONE_OF_THE_ABOVE = [
  'New acquisition for the insured',
  'None of the above apply',
];

export const LM_CPSP_NESTED_CONTROL_DEPENDENCIES: Record<
  LmCpspNestedControls,
  DependencyConfig<LmCpspQuestion, LmCpspComplexEvaluator>
> = {
  [LmCpspLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspLossHistoryQuestion.AMOUNT_PAID]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspLossHistoryQuestion.AMOUNT_RESERVED]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspLossHistoryQuestion.CLAIM_STATUS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspLossHistoryQuestion.LINE_OF_BUSINESS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspLossHistoryQuestion.DATE_OF_LOSS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspLossHistoryQuestion.LOSS_DESCRIPTION]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspLossHistoryQuestion.LOSS_TYPE_GENERAL_LIABILITY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_LOSS_TYPE_GENERAL_LIABILITY,
    },
  },
  [LmCpspLossHistoryQuestion.LOSS_TYPE_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_LOSS_TYPE_PROPERTY,
    },
  },
};

/**
 * Determines enablement logic of each control based on base state, class code, and other controls'
 * values.
 */
export const LM_CPSP_DEPENDENCIES: Record<
  LmCpspQuestion,
  DependencyConfig<LmCpspQuestion, LmCpspComplexEvaluator>
> = {
  [LmCpspQuestion._10_MONTHLY_RENTAL]: {
    classCode: { display: 'IN', values: ['60010', '60011'] },
    state: {
      display: 'IN',
      values: [
        'AL',
        'AK',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'GA',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MT',
        'MO',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
    },
  },
  [LmCpspQuestion._3_D_PRINTING]: {
    classCode: { display: 'IN', values: ['58408', '58409'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ADDITIONAL_INSURED_CLAUSE]: {
    classCode: {
      display: 'IN',
      values: [
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91560',
        '91577',
        '91581',
        '91585',
        '92215',
        '92338',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98806',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99746',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ADDRESS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ADULT_MATERIALS_SALES]: {
    classCode: {
      display: 'IN',
      values: ['10204', '15607', '16471', '16676', '18920', '58456', '98090', '98091'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AIRPORT_CONSTRUCTION_DETAILS]: {
    classCode: { display: 'IN', values: ['91560', '92215', '92451', '92478'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT,
      },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.ALARM_OR_SPRINKLER_INSTALLATION]: {
    classCode: {
      display: 'IN',
      values: [
        '12391',
        '91150',
        '91155',
        '91551',
        '91555',
        '92451',
        '92478',
        '92663',
        '98111',
        '98482',
        '98483',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ANNUAL_SALES_RECEIPTS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.APARTMENT_FORECLOSURE]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003'] },
    state: {
      display: 'IN',
      values: [
        'AL',
        'AK',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'GA',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MT',
        'MO',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
    },
  },
  [LmCpspQuestion.APPLICANT_IS_ADDITIONAL_INSURED]: {
    classCode: {
      display: 'IN',
      values: ['61212', '61216', '61217', '61218', '62000', '62002', '67634', '67635'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS,
      },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.APPLICANT_SUBSIDIARIES]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.APPLICANT_SUBSIDIARIES_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.APPLICANT_SUBSIDIARIES },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.APPLICANTS_MAIN_CUSTOMERS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ARE_THERE_APARTMENT_OCCUPANCIES_THAT_IN_TOTAL_COMPRISE_LESS_THAN_25_OF_THE_BUILDING_FLOOR_AREA]:
    {
      classCode: { display: 'ALL' },
      state: { display: 'IN', values: ['NY'] },
      dependency: {
        type: 'COMPLEX',
        functionName:
          LmCpspComplexEvaluator.HAS_BUILDING_CVG_AND_CONSTRUCTION_IS_FR_JM_NC_AND_INCIDENTAL_APT_EXISTS,
      },
    },
  [LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_1]: {
    classCode: { display: 'IN', values: ['60010', '60011'] },
    state: { display: 'IN', values: ['MS'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_MNC_MFR_FR_CONSTRUCTION,
    },
  },
  [LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_2]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003'] },
    state: { display: 'IN', values: ['WA'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_MNC_MFR_FR_CONSTRUCTION,
    },
  },
  [LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_1]: {
    classCode: { display: 'IN', values: ['60010', '60011'] },
    state: { display: 'IN', values: ['MS'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_FRAME_NC_CONSTRUCTION,
    },
  },
  [LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_2]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003'] },
    state: { display: 'IN', values: ['WA'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_FRAME_NC_CONSTRUCTION,
    },
  },
  [LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_1]: {
    classCode: { display: 'IN', values: ['60010', '60011'] },
    state: { display: 'IN', values: ['MS'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_JM_CONSTRUCTION,
    },
  },
  [LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_2]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003'] },
    state: { display: 'IN', values: ['WA'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_JM_CONSTRUCTION,
    },
  },
  [LmCpspQuestion.AREA_OCCUPIED_BY_INSURED_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AREA_UNOCCUPIED]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.AREA_UNOCCUPIED_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      functionName:
        LmCpspComplexEvaluator.UNOCC_AREA_GREATER_THAN_50_PERCENT_AND_EITHER_BUILDING_OR_BPP_SELECTED,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.ARMED_SECURITY_GUARDS]: {
    classCode: {
      display: 'IN',
      values: ['41650', '61212', '61216', '61217', '61218', '67634', '67635'],
    },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.HAS_SECURITY_PERSONNEL_OR_HAS_SECURITY_SERVICE,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.ARSON_CONVICTION]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.ASBESTOS_FIBERS]: {
    classCode: { display: 'IN', values: ['94569', '99746'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ATTACK_EXTORTION]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ATTACK_EXTORTION_LIMIT]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.ATTACK_EXTORTION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.ATTENDANT_ON_DUTY]: {
    classCode: { display: 'IN', values: ['14731'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AUTO_BODY_1]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AUTO_BODY_2]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AUTO_BODY_DETAILS]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.AUTO_BODY_1,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.AUTO_LENDING]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AUTO_RENTAL_OR_LEASING]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.AUTO_SALVAGE]: {
    classCode: { display: 'IN', values: ['10073', '18616'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BACKGROUND_CHECKS]: {
    classCode: {
      display: 'IN',
      values: [
        '10042',
        '11160',
        '12356',
        '12393',
        '12805',
        '13351',
        '13716',
        '14279',
        '15538',
        '15991',
        '16402',
        '16403',
        '16404',
        '16471',
        '45190',
        '45191',
        '45192',
        '45193',
        '60010',
        '60011',
        '91111',
        '91155',
        '91341',
        '91342',
        '91405',
        '91555',
        '91746',
        '92338',
        '92478',
        '95625',
        '95647',
        '95648',
        '96053',
        '96611',
        '96816',
        '97650',
        '97653',
        '98305',
        '98344',
        '98405',
        '98483',
        '99505',
        '99826',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.BACKGROUND_CHECKS_DEPENDENCY,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.BALCONIES]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003', '68500'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BANQUET_ADEQUATE_HARDWARE]: {
    classCode: { display: 'IN', values: ['16900', '16901', '16902', '16910', '16911', '16916'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.INSURED_HAVE_BANQUET },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.BEAUTY_SHOP]: {
    classCode: { display: 'IN', values: ['10113', '10115'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BICYCLE_RENTAL]: {
    classCode: { display: 'IN', values: ['10150'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BLANKET_ADDITIONAL_INSURED_CONTRACTORS_PRODUCTS_COMPLETED_OPERATIONS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BOILER_WORK]: {
    classCode: { display: 'IN', values: ['92451', '95647', '95648', '98482', '98483', '98636'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BOILER_WORK_DETAILS]: {
    classCode: { display: 'IN', values: ['92451', '95647', '95648', '98482', '98483', '98636'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.BOILER_WORK },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.BPP_INCLUDES_EQUIP_OVER_500]: {
    classCode: { display: 'IN', values: ['66561'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '500000' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.BUILDING_LOCATIONS_DISTANCE]: {
    classCode: {
      display: 'IN',
      values: [
        '45190',
        '45191',
        '45192',
        '45193',
        '61216',
        '62000',
        '62001',
        '62002',
        '61212',
        '61217',
        '61218',
        '64074',
        '67634',
        '67635',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AL',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'IA',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MN',
        'MO',
        'MS',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VA',
        'VT',
        'WI',
        'WV',
        'WY',
      ],
    },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.IN_AL_OR_IS_HOTEL_OR_CMML_CONDO,
    },
  },
  [LmCpspQuestion.BUILDING_OCCUPANTS]: {
    classCode: { display: 'IN', values: ['61212', '61216', '61217', '61218', '67634', '67635'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_100_K]: {
    classCode: {
      display: 'IN',
      values: [
        '41675',
        '44280',
        '45334',
        '45993',
        '46881',
        '46882',
        '47050',
        '47367',
        '48600',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '66122',
        '66123',
        '68439',
        '91805',
        '92663',
        '96317',
        '96611',
        '98597',
        '98598',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'IA',
        'IL',
        'IN',
        'KS',
        'LA',
        'MA',
        'ME',
        'MI',
        'MN',
        'MO',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OK',
        'RI',
        'SD',
        'TX',
        'UT',
        'VT',
        'WI',
        'WY',
      ],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.INTEREST_PROPERTY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'OWNER',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_150_K]: {
    classCode: {
      display: 'IN',
      values: [
        '41675',
        '44280',
        '45334',
        '45993',
        '46881',
        '46882',
        '47050',
        '47367',
        '48600',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '66122',
        '66123',
        '68439',
        '91805',
        '92663',
        '96317',
        '96611',
        '98597',
        '98598',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AK',
        'AL',
        'DC',
        'DE',
        'FL',
        'GA',
        'ID',
        'KY',
        'MD',
        'MS',
        'MT',
        'NC',
        'OH',
        'OR',
        'PA',
        'SC',
        'TN',
        'VA',
        'WA',
        'WV',
      ],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.INTEREST_PROPERTY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'OWNER',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.BUILDING_SQUARE_FOOTAGE]: {
    classCode: { display: 'IN', values: ['41650'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.BURGLARY_ALARM]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: 0 },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.CASINO]: {
    classCode: {
      display: 'IN',
      values: [
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '45190',
        '45191',
        '45192',
        '45193',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.GAMBLING_OPERATIONS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.CERAMIC_FLOOR_PERCENT_REFER]: {
    classCode: { display: 'IN', values: ['94569'] },
    state: { display: 'IN', values: ['CA'] },
  },
  [LmCpspQuestion.CERTIFICATES_OF_INSURANCE]: {
    classCode: {
      display: 'IN',
      values: [
        '10113',
        '10115',
        '10367',
        '10368',
        '11007',
        '11039',
        '11222',
        '12014',
        '14731',
        '14732',
        '14733',
        '14734',
        '14913',
        '15070',
        '15600',
        '16402',
        '16404',
        '16471',
        '18109',
        '18200',
        '18991',
        '19007',
        '19051',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41696',
        '43215',
        '43889',
        '44069',
        '45450',
        '45678',
        '45937',
        '46202',
        '47103',
        '49005',
        '51516',
        '52660',
        '57997',
        '58408',
        '58409',
        '90089',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91555',
        '91560',
        '91577',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98090',
        '98091',
        '98111',
        '98304',
        '98305',
        '98344',
        '98405',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99600',
        '99620',
        '99650',
        '99746',
        '99851',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SUB_CONTRACTORS_PERCENT },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: 0 },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.CERTIFICATES_PROVIDED]: {
    classCode: { display: 'IN', values: ['61212', '61216', '61217', '61218', '67634', '67635'] },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.ARMED_SECURITY_GUARDS_IS_NO_OR_RENOVATION_BY_EMP_IS_NO,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.CHILD_CARE_SERVICE]: {
    classCode: { display: 'IN', values: ['41665', '41667', '41668', '41669', '41670'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CHURCH_SCHOOL]: {
    classCode: { display: 'IN', values: ['41650'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CITATION_ALCOHOLIC_BEVERAGES]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.CITATION_ALCOHOLIC_BEVERAGES_DEPENDENCY,
    },
  },
  [LmCpspQuestion.CLASS_LIQUOR_LIABILITY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.CLEANING_KITCHEN_FLUES_HOODS_PERCENT_REFER]: {
    classCode: { display: 'IN', values: ['95647', '95648', '96816'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CLOSED_BY_BOH]: {
    classCode: {
      display: 'IN',
      values: [
        '11288',
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51752',
        '52002',
        '53374',
        '53375',
        '53376',
        '53377',
        '53565',
        '55371',
        '56759',
        '56760',
        '57913',
        '59905',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.COINSURANCE_BI_AND_EE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.LIMIT_BI_AND_EE },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: 0 },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.COINSURANCE_BPP]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: 0 },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.COINSURANCE_BUILDING]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: 0 },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.COMBUSTIBLE_WASTE_CONTAINERS]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.COMMERCIAL_COOKING_QUESTION]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '0' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.COMPLEMENTARY_SERVICES]: {
    classCode: {
      display: 'IN',
      values: [
        '10115',
        '10073',
        '10113',
        '10367',
        '13670',
        '13673',
        '15600',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '18200',
        '41667',
        '41668',
        '41670',
        '45190',
        '45191',
        '45192',
        '45193',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.COMPUTER_PROGRAMMING]: {
    classCode: { display: 'IN', values: ['11160', '41677', '91555', '98111'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.COMPUTER_PROGRAMMING_DETAILS]: {
    classCode: { display: 'IN', values: ['11160', '41677', '91555', '98111'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.COMPUTER_PROGRAMMING },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.CONSTRUCTION_PROJECTS_GENERAL_AGGREGATE_LIMITS_PER_PROJECT]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CONSULTANT_OPERATIONS]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CONTACT_UTILITIES]: {
    classCode: { display: 'IN', values: ['94007', '94276', '95410', '98806'] },
    state: {
      display: 'IN',
      values: [
        'AK',
        'AL',
        'AR',
        'AZ',
        'CA',
        'CO',
        'DC',
        'DE',
        'FL',
        'GA',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MD',
        'MI',
        'MN',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NM',
        'NV',
        'OH',
        'OK',
        'OR',
        'PA',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VA',
        'WA',
        'WI',
        'WV',
        'WY',
      ],
    },
  },
  [LmCpspQuestion.CONTRACT_LANGUAGE]: {
    classCode: { display: 'IN', values: ['61212', '61216', '61217', '61218', '67634', '67635'] },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.ARMED_SECURITY_GUARDS_IS_NO_OR_RENOVATION_BY_EMP_IS_NO,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.CONTRACTORS_LICENSES_LIMIT]: {
    classCode: {
      display: 'IN',
      values: [
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91560',
        '91581',
        '91581',
        '91577',
        '92215',
        '92338',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98806',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99746',
        '99946',
      ],
    },
    state: { display: 'IN', values: ['CA'] },
  },
  [LmCpspQuestion.CONVERTED_HOME_DWELLING]: {
    classCode: {
      display: 'IN',
      values: ['16900', '16901', '16902', '16910', '16911', '16916', '11288'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CP_1045_SALE_OR_STORAGE]: {
    classCode: { display: 'IN', values: ['14733', '45678'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL]: {
    classCode: {
      display: 'IN',
      values: [
        '10065',
        '10066',
        '10100',
        '11039',
        '11168',
        '11288',
        '12374',
        '12375',
        '13454',
        '13673',
        '14401',
        '15224',
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41680',
        '45190',
        '45191',
        '45192',
        '45193',
        '51315',
        '64074',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.CP_EXTING_SYS_SERV_SEMI_ANNUAL_DEPENDENCY,
    },
  },
  [LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM]: {
    classCode: {
      display: 'IN',
      values: [
        '10065',
        '10066',
        '10100',
        '11039',
        '11168',
        '11288',
        '12374',
        '12375',
        '13454',
        '13673',
        '14401',
        '15224',
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41680',
        '45190',
        '45191',
        '45192',
        '45193',
        '51315',
        '64074',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_LIMITED_COOKING,
    },
  },
  [LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM_MGT_POLICY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_COMMERCIAL_COOKING_AND_INTEREST_IS_NOT_TENANT,
    },
  },
  [LmCpspQuestion.CRANE_OR_BUCKET_TRUCK]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '90089',
        '91111',
        '91150',
        '91302',
        '91340',
        '91341',
        '91342',
        '91436',
        '91551',
        '91560',
        '91577',
        '91581',
        '91585',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98806',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99600',
        '99746',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.CRANE_OR_BUCKET_TRUCK_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.CRANE_OR_BUCKET_TRUCK },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.CUSTOMIZING_SERVICES]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DAMS_OR_BRIDGES]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '91111',
        '91150',
        '91302',
        '91340',
        '91341',
        '91342',
        '91436',
        '91551',
        '91560',
        '91577',
        '91581',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98502',
        '98636',
        '98710',
        '98483',
        '98806',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99746',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DATA_COMPROMISE_DEFENSE_AND_LIABILITY]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: true },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_1]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_10]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_11]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_12]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_2]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_3]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_4]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_5]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_6]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_7]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_8]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K,
    },
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_9]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K,
    },
  },
  [LmCpspQuestion.DAY_CARE]: {
    classCode: { display: 'IN', values: ['41650'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DEMOLITION_DETAILS]: {
    classCode: {
      display: 'IN',
      values: ['91340', '91342', '91746', '94007', '95410', '95625', '97047', '98502', '98813'],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.DEMOLITION_PERCENT,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: 0,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.DEMOLITION_PERCENT]: {
    classCode: {
      display: 'IN',
      values: ['91340', '91342', '91746', '94007', '95410', '95625', '97047', '98502', '98813'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DESCRIPTION_OF_OPERATIONS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES]: {
    classCode: {
      display: 'IN',
      values: [
        '51500',
        '52134',
        '52432',
        '52438',
        '52467',
        '52469',
        '57572',
        '57808',
        '57998',
        '58057',
        '58095',
        '58922',
        '59781',
        '59782',
        '59914',
        '91340',
        '91341',
        '91342',
        '97220',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '51500',
        '52134',
        '52432',
        '52438',
        '52467',
        '52469',
        '57572',
        '57808',
        '57998',
        '58057',
        '58095',
        '58922',
        '59781',
        '59782',
        '59914',
        '91340',
        '91341',
        '91342',
        '97220',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91340',
        '91342',
        '91551',
        '91555',
        '91746',
        '91805',
        '92102',
        '92451',
        '92478',
        '95647',
        '96053',
        '96317',
        '96611',
        '97047',
        '98482',
        '98483',
        '98805',
        '98813',
        '99471',
        '99650',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91340',
        '91342',
        '91551',
        '91555',
        '91746',
        '91805',
        '92102',
        '92451',
        '92478',
        '95647',
        '96053',
        '96317',
        '96611',
        '97047',
        '98482',
        '98483',
        '98805',
        '98813',
        '99471',
        '99650',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.DISPOSAL_AUTO_RELATED]: {
    classCode: { display: 'IN', values: ['10072', '10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.DIVING_BOARD_HEIGHT]: {
    classCode: {
      display: 'IN',
      values: [
        '18200',
        '41665',
        '41667',
        '41668',
        '41670',
        '45190',
        '45191',
        '45192',
        '45193',
        '46202',
        '60010',
        '60011',
        '62003',
        '64074',
        '68500',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SWIMMING_POOL_DIVING_BOARD },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.DWELLING_OCCUPANCY]: {
    classCode: { display: 'IN', values: ['60010', '60011', '60016', '62003'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.E_COMMERCE_DESIGN]: {
    classCode: { display: 'IN', values: ['41675'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: true },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.EACH_OCCURRENCE_AGGREGATE_LIMIT]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.OFF_PREMISES_PROPERTY_DAMAGE_INCLUDING_CARE_CUSTODY_OR_CONTROL,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.EFFECTIVE_DATE]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.EIFS]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91340',
        '91341',
        '91342',
        '91436',
        '91560',
        '91746',
        '92338',
        '92451',
        '95625',
        '97447',
        '97650',
        '97653',
        '98304',
        '98482',
        '98483',
        '98502',
        '98884',
        '98967',
        '98993',
        '99505',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ELECTROPLATING_OPERATIONS]: {
    classCode: {
      display: 'IN',
      values: [
        '51221',
        '51224',
        '51500',
        '52432',
        '52438',
        '52467',
        '52469',
        '52505',
        '53732',
        '55802',
        '56041',
        '56042',
        '56391',
        '57257',
        '57572',
        '57808',
        '57998',
        '58903',
        '58922',
        '59057',
        '59058',
        '59781',
        '58782',
        '59782',
        '59914',
        '97220',
        '99004',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.EMERGENCY_ESCAPE_ROUTES]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003', '68500'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.EMP_CARRY_FIREARMS]: {
    classCode: {
      display: 'IN',
      values: [
        '10145',
        '10146',
        '12356',
        '12374',
        '12375',
        '13453',
        '13454',
        '13455',
        '13673',
        '18435',
        '18436',
        '18501',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.EMPLOYEE_LEASING]: {
    classCode: {
      display: 'IN',
      values: [
        '41675',
        '41677',
        '45993',
        '46881',
        '46882',
        '47367',
        '61224',
        '61225',
        '61226',
        '61227',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY]: {
    classCode: { display: 'ALL' },
    state: { display: 'NOT_IN', values: ['NY'] },
  },
  [LmCpspQuestion.ENTER_RESIDENCES]: {
    classCode: {
      display: 'IN',
      values: [
        '10042',
        '11160',
        '12356',
        '12393',
        '12805',
        '13351',
        '13716',
        '14279',
        '15538',
        '15991',
        '16402',
        '16403',
        '16404',
        '16471',
        '91111',
        '91155',
        '91341',
        '91342',
        '91405',
        '91555',
        '91746',
        '92338',
        '92478',
        '95625',
        '95647',
        '95648',
        '96053',
        '96611',
        '97650',
        '97653',
        '98305',
        '98344',
        '98405',
        '98483',
        '99505',
        '99826',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR]: {
    classCode: { display: 'IN', values: ['16900', '16910', '16911', '16916', '41667', '41668'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR_DETAILS]: {
    classCode: { display: 'IN', values: ['16900', '16910', '16911', '16916', '41667', '41668'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.ENVIRONMENTAL_HAZARD]: {
    classCode: { display: 'IN', values: ['10010', '13930', '91111', '92663'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ENVIRONMENTAL_HAZARD_DETAILS]: {
    classCode: { display: 'IN', values: ['10010', '13930', '91111', '92663'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.ENVIRONMENTAL_HAZARD },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.EPLI_CLAIMS]: {
    classCode: { display: 'ALL' },
    state: { display: 'NOT_IN', values: ['NY'] },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '0' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.EXCEED_10_MILLION]: {
    // NOTE: There is ambiguity on when this is required (quote API requirements differ from CSV/Question service API rules)
    // We may need to restrict enablement in the future once we confirm with LM.
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmCpspQuestion.EXCEED_15_MILLION]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10026',
        '10040',
        '10042',
        '10065',
        '10066',
        '10070',
        '10071',
        '10072',
        '10073',
        '10100',
        '10111',
        '10140',
        '10141',
        '10145',
        '10146',
        '10150',
        '10204',
        '10255',
        '10257',
        '10309',
        '10352',
        '11020',
        '11126',
        '11127',
        '11128',
        '11160',
        '11167',
        '11168',
        '11258',
        '11259',
        '11288',
        '12356',
        '12361',
        '12362',
        '12374',
        '12375',
        '12391',
        '12393',
        '12467',
        '12510',
        '12651',
        '12797',
        '12805',
        '13049',
        '13111',
        '13112',
        '13351',
        '13453',
        '13454',
        '13455',
        '13506',
        '13507',
        '13590',
        '13670',
        '13673',
        '13715',
        '13716',
        '13720',
        '13759',
        '13930',
        '14101',
        '14279',
        '14401',
        '14405',
        '14527',
        '14655',
        '15223',
        '15224',
        '15404',
        '15538',
        '15607',
        '15608',
        '15699',
        '15839',
        '15991',
        '15993',
        '16005',
        '16403',
        '16501',
        '16527',
        '16588',
        '16676',
        '16705',
        '18110',
        '18616',
        '18205',
        '18206',
        '18335',
        '18435',
        '18436',
        '18437',
        '18438',
        '18501',
        '18507',
        '18575',
        '18833',
        '18834',
        '18911',
        '18912',
        '18920',
        '41603',
        '41604',
        '41680',
        '45190',
        '45191',
        '45192',
        '45193',
        '45901',
        '46426',
        '46427',
        '49617',
        '49619',
        '49840',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51752',
        '52002',
        '53374',
        '53375',
        '53376',
        '53377',
        '53565',
        '55371',
        '56758',
        '56759',
        '56760',
        '59963',
        '59964',
        '60010',
        '60011',
        '62000',
        '62001',
        '62002',
        '62003',
        '64074',
        '66309',
        '68500',
        '91177',
        '91179',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AK',
        'AL',
        'AR',
        'AZ',
        'CO',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'ID',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MO',
        'MS',
        'MT',
        'NC',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'TN',
        'TX',
        'UT',
        'VA',
        'VT',
        'WA',
        'WV',
        'WY',
      ],
    },
  },
  [LmCpspQuestion.EXCEED_15_MILLION_IP]: {
    classCode: {
      display: 'IN',
      values: [
        '51116',
        '51221',
        '51224',
        '51500',
        '51553',
        '51554',
        '51877',
        '51869',
        '51896',
        '51927',
        '51926',
        '51957',
        '51919',
        '52134',
        '52432',
        '52438',
        '52467',
        '52469',
        '52505',
        '52967',
        '53732',
        '54077',
        '55802',
        '56041',
        '56042',
        '56202',
        '56390',
        '56391',
        '57257',
        '57410',
        '57572',
        '57600',
        '57716',
        '57808',
        '57871',
        '57913',
        '57998',
        '58009',
        '58057',
        '58095',
        '58096',
        '58456',
        '58457',
        '58458',
        '58459',
        '58663',
        '58627',
        '58759',
        '58903',
        '58922',
        '59005',
        '59057',
        '59058',
        '59223',
        '59482',
        '59781',
        '59782',
        '59889',
        '59905',
        '59914',
        '59915',
        '59917',
        '59923',
        '59975',
        '97220',
        '97220',
        '99827',
        '99826',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AR',
        'AZ',
        'CO',
        'CT',
        'DC',
        'DE',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MO',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'PA',
        'RI',
        'TX',
        'UT',
        'VA',
        'VT',
        'WV',
        'WY',
      ],
    },
  },
  [LmCpspQuestion.EXCEED_7_5_MILLION_IP]: {
    classCode: {
      display: 'IN',
      values: [
        '51116',
        '51221',
        '51224',
        '51500',
        '51553',
        '51554',
        '51869',
        '51877',
        '51896',
        '51919',
        '51926',
        '51927',
        '51957',
        '52134',
        '52432',
        '52438',
        '52467',
        '52469',
        '52505',
        '52967',
        '53732',
        '54077',
        '55802',
        '56041',
        '56042',
        '56202',
        '56390',
        '56391',
        '57257',
        '57410',
        '57572',
        '57600',
        '57716',
        '57808',
        '57871',
        '57913',
        '57998',
        '58009',
        '58057',
        '58095',
        '58096',
        '58456',
        '58457',
        '58458',
        '58459',
        '58627',
        '58663',
        '58759',
        '58903',
        '58922',
        '59005',
        '59057',
        '59058',
        '59223',
        '59482',
        '59781',
        '59782',
        '59889',
        '59905',
        '59914',
        '59915',
        '59917',
        '59923',
        '59975',
        '97220',
        '99826',
        '99827',
      ],
    },
    state: { display: 'IN', values: ['CA', 'IA', 'IL', 'IN', 'MI', 'MN', 'NE', 'ND', 'SD', 'WI'] },
  },
  [LmCpspQuestion.EXECUTIVE_SEARCH_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'ExecutiveSearchOutplacementConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.EXPERT_WITNESS_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'ExpertWitnessConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.EXPOSURE]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.EXPOSURE_LIQUOR_LIABILITY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.EXTERIOR_DOORS_1]: {
    classCode: { display: 'IN', values: ['45190', '45191', '45192', '45193', '64074'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.EXTERNAL_INSURED_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10042',
        '10071',
        '12393',
        '12651',
        '12797',
        '12805',
        '13351',
        '13590',
        '13716',
        '13930',
        '14279',
        '15991',
        '16402',
        '16404',
        '16471',
        '99851',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.GREATER_THAN_25_PERCENT_REVENUE_GENERATED_OFF_PREMISES,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.EXTERNAL_INSURED_REVENUES]: {
    classCode: {
      display: 'IN',
      values: [
        '10042',
        '10071',
        '12393',
        '12651',
        '12797',
        '12805',
        '13351',
        '13590',
        '13716',
        '13930',
        '14279',
        '15991',
        '16402',
        '16404',
        '16471',
        '99851',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.FEDERAL_ID]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.FILL_PROPANE_GAS_TANKS]: {
    classCode: { display: 'IN', values: ['13453', '13454', '13455', '13716'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.FINANCIAL_ACCOUNTING_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'FinancialAccountingConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.FIRE_ALARM_TYPE]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.FIRE_DAMAGE_REMEDIATION]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91560',
        '91577',
        '91581',
        '91585',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99746',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.FIRE_DAMAGE_REMEDIATION_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91560',
        '91577',
        '91581',
        '91585',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99746',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.FIRE_DAMAGE_REMEDIATION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.FIREARMS_SALES]: {
    classCode: {
      display: 'IN',
      values: ['10026', '13715', '13716', '18205', '18206', '18437', '18438', '18833', '91179'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.FIREPLACES_OR_WOODSTOVES]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003', '68500'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.FIREWALL_AND_SELF_CLOSING_FIRE_DOORS]: {
    classCode: {
      display: 'IN',
      values: [
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51896',
        '51919',
        '51926',
        '51927',
        '51957',
        '52002',
        '52134',
        '52432',
        '52438',
        '52467',
        '52469',
        '52505',
        '52967',
        '53375',
        '53376',
        '53377',
        '53565',
        '53732',
        '54077',
        '55371',
        '55802',
        '56041',
        '56042',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57410',
        '57572',
        '57600',
        '57716',
        '57808',
        '57871',
        '57913',
        '57998',
        '58009',
        '58057',
        '58095',
        '58096',
        '58456',
        '58458',
        '58459',
        '58627',
        '58663',
        '58759',
        '58903',
        '58922',
        '59005',
        '59057',
        '59058',
        '59223',
        '59482',
        '59781',
        '59782',
        '59889',
        '59905',
        '59914',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '97220',
        '99826',
        '99827',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AL',
        'AR',
        'CO',
        'DC',
        'DE',
        'FL',
        'GA',
        'IA',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MD',
        'MI',
        'MN',
        'MO',
        'MS',
        'NC',
        'ND',
        'NE',
        'OH',
        'OK',
        'PA',
        'SC',
        'SD',
        'TN',
        'TX',
        'VA',
        'WI',
        'WV',
      ],
    },
  },
  [LmCpspQuestion.FLAMMABLE_FLUID_STORAGE]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.FOOD_PREP_QUESTION]: {
    classCode: { display: 'IN', values: ['10100', '51315', '51572'] },
    state: { display: 'IN', values: ['LA'] },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: 0,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.FOOD_TRUCK]: {
    classCode: {
      display: 'IN',
      values: ['11039', '11168', '16819', '16900', '16901', '16902', '16910', '16911', '16916'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.FOOD_TRUCK_PERCENTAGE]: {
    classCode: {
      display: 'IN',
      values: ['11039', '11168', '16819', '16900', '16901', '16902', '16910', '16911', '16916'],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.FOOD_TRUCK },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.FORM_OF_BUSINESS]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.GAMBLING_OPERATIONS]: {
    classCode: {
      display: 'IN',
      values: [
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '45190',
        '45191',
        '45192',
        '45193',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.GASOLINE_SALES]: {
    classCode: { display: 'IN', values: ['13673', '18435', '18436'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.GENERAL_CONTRACTING]: {
    classCode: { display: 'IN', values: ['61226'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.GENERAL_CONTRACTING_DETAILS]: {
    classCode: { display: 'IN', values: ['61226'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.GENERAL_CONTRACTING },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.GENERAL_MANAGER_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'GeneralManagementConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION]: {
    classCode: { display: 'ALL' },
    state: {
      display: 'IN',
      values: [
        'AL',
        'CT',
        'DE',
        'FL',
        'GA',
        'LA',
        'MA',
        'MD',
        'ME',
        'MS',
        'NC',
        'NH',
        'NJ',
        'NY',
        'RI',
        'SC',
        'TX',
        'VA',
      ],
    },
  },
  [LmCpspQuestion.GREENHOUSE_PRESENT]: {
    classCode: { display: 'IN', values: ['12841', '15699'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HABITATION_RISKS]: {
    classCode: { display: 'IN', values: ['60010', '60011'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HABITATIONAL_EXPOSURES]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003', '68500'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HABITATIONAL_UNITS]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10026',
        '10040',
        '10042',
        '10065',
        '10066',
        '10070',
        '10071',
        '10072',
        '10073',
        '10100',
        '10111',
        '10113',
        '10115',
        '10140',
        '10141',
        '10145',
        '10146',
        '10150',
        '10204',
        '10255',
        '10257',
        '10309',
        '10352',
        '10367',
        '10368',
        '11007',
        '11020',
        '11039',
        '11126',
        '11127',
        '11128',
        '11160',
        '11167',
        '11168',
        '11222',
        '11234',
        '11258',
        '11259',
        '11288',
        '12014',
        '12356',
        '12361',
        '12362',
        '12374',
        '12375',
        '12391',
        '12393',
        '12467',
        '12510',
        '12651',
        '12797',
        '12805',
        '12841',
        '13049',
        '13111',
        '13112',
        '13351',
        '13453',
        '13454',
        '13455',
        '13506',
        '13507',
        '13590',
        '13670',
        '13673',
        '13715',
        '13716',
        '13720',
        '13759',
        '13930',
        '14101',
        '14279',
        '14401',
        '14405',
        '14527',
        '14655',
        '14731',
        '14732',
        '14733',
        '14734',
        '14913',
        '15070',
        '15223',
        '15224',
        '15404',
        '15538',
        '15600',
        '15607',
        '15608',
        '15699',
        '15839',
        '15991',
        '15993',
        '16005',
        '16402',
        '16403',
        '16404',
        '16471',
        '16501',
        '16527',
        '16588',
        '16676',
        '16705',
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '18109',
        '18110',
        '18200',
        '18205',
        '18206',
        '18335',
        '18435',
        '18436',
        '18437',
        '18438',
        '18501',
        '18507',
        '18575',
        '18616',
        '18833',
        '18834',
        '18911',
        '18912',
        '18920',
        '18991',
        '19007',
        '19051',
        '41603',
        '41604',
        '41650',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41675',
        '41677',
        '41680',
        '41696',
        '43215',
        '43889',
        '44069',
        '44280',
        '45190',
        '45191',
        '45192',
        '45193',
        '45334',
        '45450',
        '45678',
        '45901',
        '45937',
        '45993',
        '46202',
        '46426',
        '46427',
        '46881',
        '46882',
        '47050',
        '47103',
        '47367',
        '48600',
        '49005',
        '49617',
        '49619',
        '49840',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51516',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51896',
        '51919',
        '51926',
        '51927',
        '51957',
        '52002',
        '52134',
        '52432',
        '52438',
        '52467',
        '52469',
        '52505',
        '52660',
        '52967',
        '53374',
        '53375',
        '53376',
        '53377',
        '53565',
        '53732',
        '54077',
        '55371',
        '55802',
        '56041',
        '56042',
        '56202',
        '56390',
        '56391',
        '56758',
        '56759',
        '56760',
        '57257',
        '57410',
        '57572',
        '57600',
        '57716',
        '57808',
        '57871',
        '57913',
        '57997',
        '57998',
        '58009',
        '58057',
        '58095',
        '58096',
        '58408',
        '58409',
        '58456',
        '58457',
        '58458',
        '58459',
        '58627',
        '58663',
        '58759',
        '58903',
        '58922',
        '59005',
        '59057',
        '59058',
        '59223',
        '59482',
        '59781',
        '59782',
        '59889',
        '59905',
        '59914',
        '59915',
        '59917',
        '59923',
        '59963',
        '59964',
        '59975',
        '61212',
        '61216',
        '61217',
        '61218',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '62000',
        '62001',
        '62002',
        '64074',
        '66122',
        '66123',
        '66309',
        '66561',
        '67634',
        '67635',
        '68439',
        '68500',
        '90089',
        '91111',
        '91150',
        '91155',
        '91177',
        '91179',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91555',
        '91560',
        '91577',
        '91746',
        '91805',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '92663',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96317',
        '96611',
        '96816',
        '97047',
        '97050',
        '97220',
        '97447',
        '97650',
        '97653',
        '98090',
        '98091',
        '98111',
        '98304',
        '98305',
        '98344',
        '98405',
        '98482',
        '98483',
        '98502',
        '98597',
        '98598',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99600',
        '99620',
        '99650',
        '99746',
        '99826',
        '99827',
        '99851',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HAS_FIREPLACE_OR_WOOD_STOVE_WORK]: {
    classCode: {
      display: 'IN',
      values: [
        '10255',
        '13716',
        '14279',
        '91111',
        '91155',
        '91340',
        '91341',
        '91342',
        '91560',
        '95625',
        '95647',
        '95648',
        '97447',
        '99003',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HAZARDOUS_MATERIALS_ABATEMENT]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91111',
        '91342',
        '91436',
        '91746',
        '92338',
        '92451',
        '94569',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97447',
        '98304',
        '98305',
        '98482',
        '98483',
        '98636',
        '98805',
        '98813',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HAZARDOUS_PRODUCTS]: {
    classCode: {
      display: 'IN',
      values: ['52438', '52469', '52505', '57600', '58057', '58095', '58922', '59781'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HEATING_YEAR]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.HIGH_HAZ_OCCUPANCIES]: {
    classCode: { display: 'IN', values: ['61212', '61216', '61217', '61218', '67634', '67635'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HOLD_HARMLESS_CONTRACT]: {
    classCode: {
      display: 'IN',
      values: [
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91560',
        '91577',
        '91581',
        '91585',
        '92215',
        '92338',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98806',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99746',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HOSPITAL_WORK_PERCENT]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91111',
        '91150',
        '91302',
        '91341',
        '91342',
        '91436',
        '91551',
        '91555',
        '91560',
        '91746',
        '91805',
        '92215',
        '92338',
        '92451',
        '92478',
        '92663',
        '94569',
        '95124',
        '95647',
        '95648',
        '96053',
        '96611',
        '96816',
        '97447',
        '98111',
        '98482',
        '98483',
        '98636',
        '98813',
        '99471',
        '99650',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HOT_WORK_OPERATIONS]: {
    classCode: { display: 'IN', values: ['94276', '97650', '97653'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.ANNUAL_SALES_RECEIPTS },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '999999' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.HOT_WORK_PROGRAM]: {
    classCode: { display: 'IN', values: ['94276', '97650', '97653'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.HOT_WORK_OPERATIONS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.HOTEL_NUMBER_OF_STORIES]: {
    classCode: { display: 'IN', values: ['45191', '45193'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.HOURS_OF_OPERATION]: {
    classCode: {
      display: 'IN',
      values: [
        '12356',
        '12374',
        '12375',
        '13453',
        '13454',
        '13455',
        '13673',
        '14731',
        '18435',
        '18436',
        '18501',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.INLAND_MARINE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.INS_ADDITIONAL_INS_ON_MFGR_INS_POLICY]: {
    classCode: { display: 'IN', values: ['45993'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.INSURED_HAVE_BANQUET]: {
    classCode: { display: 'IN', values: ['16900', '16901', '16902', '16910', '16911', '16916'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.INSURED_PHONE_NUMBER]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.INSURED_SNOWPLOW_ON_HIGHWAY]: {
    classCode: {
      display: 'IN',
      values: [
        '91342',
        '92215',
        '94007',
        '95410',
        '95625',
        '97047',
        '97050',
        '97447',
        '98710',
        '98806',
        '99946',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.SNOW_PLOW_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.INSURED_STORE_PRODUCTS]: {
    classCode: { display: 'IN', values: ['45993'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE]: {
    classCode: {
      display: 'IN',
      values: [
        '16705',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91436',
        '91551',
        '91560',
        '91577',
        '91581',
        '91585',
        '92215',
        '92338',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98806',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99746',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE_DETAIL]: {
    classCode: {
      display: 'ALL',
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.INTEREST_PROPERTY]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.INTERNAL_COMPLAINT_PROCEDURE]: {
    classCode: { display: 'ALL' },
    state: { display: 'NOT_IN', values: ['NY'] },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: true },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.JANITORIAL_DEBRIS_CLEAN_UP_PERCENT_REFER]: {
    classCode: { display: 'IN', values: ['94007', '95410', '96816', '97047', '97050'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LARGE_SPECIAL_EVENT]: {
    classCode: { display: 'IN', values: ['41665', '41667', '41668', '41669', '41670'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LARGEST_NUMBER_OF_NEW_HOME_STARTS]: {
    classCode: {
      display: 'IN',
      values: [
        '91111',
        '91302',
        '91436',
        '91340',
        '91341',
        '91342',
        '91551',
        '91577',
        '91560',
        '91580',
        '91585',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98806',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99746',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LAWN_LANDSCAPE_UNDERGROUND]: {
    classCode: { display: 'IN', values: ['97050', '97047'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LEASED_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10040',
        '10070',
        '10255',
        '10257',
        '11160',
        '12391',
        '13715',
        '13716',
        '13930',
        '14279',
        '15699',
        '18205',
        '18206',
        '91111',
        '95647',
        '95648',
        '98111',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.LEASED_EQUIPMENT,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.LEASED_EQUIPMENT]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10040',
        '10070',
        '10255',
        '10257',
        '11160',
        '12391',
        '13715',
        '13716',
        '13930',
        '14279',
        '15699',
        '18205',
        '18206',
        '91111',
        '95647',
        '95648',
        '98111',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LEASED_OPERATOR_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10040',
        '10070',
        '10255',
        '10257',
        '11160',
        '12391',
        '13715',
        '13716',
        '13930',
        '14279',
        '15699',
        '18205',
        '18206',
        '91111',
        '95647',
        '95648',
        '98111',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.LEASED_EQUIPMENT,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_100_K]: {
    classCode: {
      display: 'IN',
      values: [
        '41675',
        '41677',
        '44280',
        '45334',
        '45993',
        '46881',
        '46882',
        '47050',
        '47367',
        '48600',
        '61212',
        '61216',
        '61217',
        '61218',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '66122',
        '66123',
        '66561',
        '67634',
        '67635',
        '68439',
        '91805',
        '92663',
        '96317',
        '96611',
        '98597',
        '98598',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'IA',
        'IL',
        'IN',
        'KS',
        'LA',
        'MA',
        'ME',
        'MI',
        'MN',
        'MO',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OK',
        'RI',
        'SD',
        'TX',
        'UT',
        'VT',
        'WI',
        'WY',
      ],
    },
  },
  [LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_150_K]: {
    classCode: {
      display: 'IN',
      values: [
        '41675',
        '41677',
        '44280',
        '45334',
        '45993',
        '46881',
        '46882',
        '47050',
        '47367',
        '48600',
        '61212',
        '61216',
        '61217',
        '61218',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '66122',
        '66123',
        '66561',
        '67634',
        '67635',
        '68439',
        '91805',
        '92663',
        '96317',
        '96611',
        '98597',
        '98598',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AK',
        'AL',
        'DC',
        'DE',
        'FL',
        'GA',
        'ID',
        'KY',
        'MD',
        'MS',
        'MT',
        'NC',
        'OH',
        'OR',
        'PA',
        'SC',
        'TN',
        'VA',
        'WA',
        'WV',
      ],
    },
  },
  [LmCpspQuestion.LICENSED_FOR_TRADE]: {
    classCode: { display: 'IN', values: ['95625'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LIMIT_BI_AND_EE]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.CSP_CLASS_IS_BUILDING_ONLY,
    },
  },
  [LmCpspQuestion.LIMITED_COOKING_QUESTION]: {
    classCode: {
      display: 'IN',
      values: [
        '10065',
        '10100',
        '11288',
        '12374',
        '12375',
        '13454',
        '13673',
        '15224',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41680',
        '45190',
        '45191',
        '45192',
        '45193',
        '51315',
        '64074',
      ],
    },
    state: { display: 'ALL' },
    // TODO: confirm this should always be enabled for the above classes. API responses conflict
    // with info previously given by LM. Email was sent 11/05/21 to confirm correct setting.
  },
  [LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LIQUOR_PERCENT_OF_SALES]: {
    classCode: { display: 'IN', values: ['16910', '16911', '16916'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LIT_COMMON_AREAS]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003', '68500'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LITIGATION_INVOLVEMENT]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91111',
        '91155',
        '91340',
        '91341',
        '91342',
        '91551',
        '91560',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '98304',
        '98482',
        '98483',
        '98502',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '99471',
        '99650',
        '99746',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LOCKS_CHANGED]: {
    classCode: {
      display: 'IN',
      values: ['60010', '60011', '62003', '68500'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LOSSES]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.LP_GAS_WORK_PERCENT]: {
    classCode: {
      display: 'IN',
      values: ['58922', '92451', '95647', '95648', '98482', '98483', '98636', '99948'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.LP_GAS_WORK_PERCENT_DETAILS]: {
    classCode: {
      display: 'IN',
      values: ['58922', '92451', '95647', '95648', '98482', '98483', '98636', '99948'],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.LP_GAS_WORK_PERCENT },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '0' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.MA_TENANT_RELOCATION_EXPENSE_COVERAGE_NUMBER_OF_RENTED_LIVING_QUARTERS]: {
    classCode: {
      display: 'IN',
      values: ['60010', '60011', '41667', '41668', '62003', '68500'],
    },
    state: {
      display: 'IN',
      values: ['MA'],
    },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_MA,
    },
  },
  [LmCpspQuestion.MANUFACTURING_ACTIVITY]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10040',
        '10042',
        '10070',
        '10071',
        '10111',
        '10113',
        '10115',
        '10140',
        '10141',
        '10145',
        '10146',
        '10150',
        '10255',
        '10257',
        '10352',
        '11234',
        '12356',
        '12361',
        '12362',
        '12374',
        '12375',
        '12391',
        '12393',
        '12467',
        '13049',
        '13111',
        '13112',
        '13670',
        '13673',
        '13715',
        '13716',
        '13720',
        '13930',
        '14279',
        '15223',
        '15224',
        '16403',
        '16501',
        '16705',
        '18205',
        '18206',
        '18435',
        '18436',
        '18437',
        '18438',
        '18616',
        '18833',
        '18834',
        '18911',
        '18912',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MANUFACTURING_ACTIVITY_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10040',
        '10042',
        '10070',
        '10071',
        '10111',
        '10113',
        '10115',
        '10140',
        '10141',
        '10145',
        '10146',
        '10150',
        '10255',
        '10257',
        '10352',
        '11234',
        '12356',
        '12361',
        '12362',
        '12374',
        '12375',
        '12391',
        '12393',
        '12467',
        '13049',
        '13111',
        '13112',
        '13670',
        '13673',
        '13715',
        '13716',
        '13720',
        '13930',
        '14279',
        '15223',
        '15224',
        '16403',
        '16501',
        '16705',
        '18205',
        '18206',
        '18435',
        '18436',
        '18437',
        '18438',
        '18616',
        '18833',
        '18834',
        '18911',
        '18912',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.MANUFACTURING_ACTIVITY },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.MARIJUANA_OPERATIONS]: {
    classCode: { display: 'IN', values: ['61212', '61217'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MARKETING_ADVERTISING_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'MarketingAdvertisingConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.MEDICAL_EQUIP_OVER_500_DESC]: {
    classCode: { display: 'IN', values: ['66561'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.BPP_INCLUDES_EQUIP_OVER_500 },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.MEDICAL_GASES_WORK]: {
    classCode: { display: 'IN', values: ['98482', '98483', '98636'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MEDICAL_MARIJUANA_SALES]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '12374',
        '12375',
        '13670',
        '13720',
        '15699',
        '18435',
        '18436',
        '18437',
        '41677',
        '51315',
        '53374',
        '53376',
        '53377',
        '66561',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MEDICAL_OFFICE_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'MedicalOffice',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.MERCHANDISE_IMPORTED_DIRECTLY]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10040',
        '10070',
        '10111',
        '10140',
        '10141',
        '10257',
        '11126',
        '11127',
        '12361',
        '12362',
        '12391',
        '12467',
        '12797',
        '13049',
        '13112',
        '13351',
        '13506',
        '13507',
        '13670',
        '13715',
        '13930',
        '14405',
        '14527',
        '14655',
        '15223',
        '15404',
        '15607',
        '16005',
        '16501',
        '16527',
        '16588',
        '16705',
        '18205',
        '18435',
        '18436',
        '18437',
        '18438',
        '18833',
        '18834',
        '18911',
        '18912',
        '51221',
        '51224',
        '51957',
        '53376',
        '53377',
        '56760',
        '59782',
        '91111',
        '95647',
        '95648',
        '98091',
        '98636',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MINORS_LIQUOR_SALE]: {
    classCode: {
      display: 'IN',
      values: [
        '10140',
        '10141',
        '10145',
        '10146',
        '11168',
        '11288',
        '12361',
        '12374',
        '12375',
        '13670',
        '13673',
        '16819',
        '16820',
        '16910',
        '16911',
        '16916',
        '18435',
        '18436',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.SERVES_ALCOHOL_OR_HAS_LIQUOR_RELATED_CLASS_CODE,
    },
  },
  [LmCpspQuestion.MIXING_OR_COMPOUNDING_DRUGS]: {
    classCode: { display: 'IN', values: ['12374', '12375', '13673', '18501'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MOLESTATION]: {
    classCode: { display: 'IN', values: ['41650'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MOLESTATION_DETAILS]: {
    classCode: { display: 'IN', values: ['41650'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.MOLESTATION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.MOTORCYCLE_REPAIR]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.MOTORIZED_CONVEYANCE]: {
    classCode: { display: 'IN', values: ['18205', '18206'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.NETWORK_SECURITY]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.NETWORK_SECURITY_LIMIT]: {
    classCode: { display: 'NOT_IN', values: ['43151', '62000', '67512', '67513'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.NETWORK_SECURITY },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: true },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS,
      },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION]: {
    classCode: {
      display: 'IN',
      values: [
        '91111',
        '91155',
        '91340',
        '91341',
        '91342',
        '91436',
        '91551',
        '91560',
        '91577',
        '91746',
        '92215',
        '92338',
        '92478',
        '94007',
        '95410',
        '95625',
        '95647',
        '95648',
        '97047',
        '97447',
        '97650',
        '97653',
        '98304',
        '98482',
        '98483',
        '98502',
        '98710',
        '98806',
        '98884',
        '98967',
        '99471',
        '99746',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AZ',
        'CA',
        'CO',
        'FL',
        'IL',
        'IN',
        'LA',
        'MO',
        'MT',
        'ND',
        'NV',
        'NY',
        'OR',
        'PA',
        'SC',
        'TX',
        'WA',
        'WV',
      ],
    },
  },
  [LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.NONGARAGE_OPERATIONS]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.NONGARAGE_OPERATIONS_DETAILS]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.NONGARAGE_OPERATIONS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.NP_FUND_RAISING_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'NonProfitFundRaisingConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.NUMBER_OF_GAMING_MACHINES]: {
    classCode: {
      display: 'IN',
      values: [
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '45190',
        '45191',
        '45192',
        '45193',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.GAMBLING_OPERATIONS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.NUMBER_OF_STORIES]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.OCCUPANCY_QUESTION_MS]: {
    classCode: { display: 'IN', values: ['60010', '60011'] },
    state: {
      display: 'IN',
      values: ['MS'],
    },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_MS,
    },
  },
  [LmCpspQuestion.OCCUPANCY_QUESTION_WA]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003'] },
    state: { display: 'IN', values: ['WA'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_WA,
    },
  },
  [LmCpspQuestion.OCCUPANCY_RATE_1]: {
    classCode: { display: 'IN', values: ['45190', '45191', '45192', '45193'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OCCUPANCY_TYPE_CATEGORY_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '0' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.OCCUPANCY_TYPE_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '0' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.OFF_PREMISES_PROPERTY_DAMAGE_INCLUDING_CARE_CUSTODY_OR_CONTROL]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OFFICE_ADMIN_IQ]: {
    classCode: { display: 'IN', values: ['47367'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SALES_SERVICE_OPERATIONS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'Office Administrative Service' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.OP_CHANGE]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.OPEN_AFTER_MIDNIGHT]: {
    classCode: {
      display: 'IN',
      values: ['11288', '16819', '16820', '16900', '16901', '16902', '16910', '16911', '16916'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT]: {
    classCode: { display: 'IN', values: ['91560', '92215', '92451', '92478'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING]: {
    classCode: {
      display: 'IN',
      values: [
        '91302',
        '91551',
        '91560',
        '91577',
        '92102',
        '92215',
        '94007',
        '95410',
        '95625',
        '97047',
        '98482',
        '98483',
        '98710',
        '98806',
        '98993',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '91302',
        '91551',
        '91560',
        '91577',
        '92102',
        '92215',
        '94007',
        '95410',
        '95625',
        '97047',
        '98482',
        '98483',
        '98710',
        '98806',
        '98993',
        '99946',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING,
      },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.ORGANIZATIONAL_DEVELOPMENT_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'OrganizationalDevelopmentConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.OSHA_APPROVAL]: {
    classCode: {
      display: 'IN',
      values: [
        '91302',
        '91560',
        '92102',
        '94007',
        '94276',
        '95410',
        '98482',
        '98483',
        '98805',
        '98806',
        '98813',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.EXCAVATION_OR_TRENCHING_EXCAVATING,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.OTHER_BUSINESS_INSD_ELSEWHERE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.OTHER_OFFICE_TYPES]: {
    classCode: { display: 'IN', values: ['61226'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OTHER_OFFICE_TYPES_DETAILS]: {
    classCode: { display: 'IN', values: ['61226'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.OTHER_OFFICE_TYPES },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'NO' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.OTHER_PROD_SOLD_UNDER_APPLICANTS_LABEL]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OTHER_THAN_MINOR_AUTO_REPAIR]: {
    classCode: { display: 'IN', values: ['13453', '13454', '13455'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.OWN_LEASE_DOCKS]: {
    classCode: { display: 'IN', values: ['62003'] },
    state: {
      display: 'IN',
      values: [
        'AK',
        'AL',
        'AR',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MN',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'VA',
        'VT',
        'WA',
        'WI',
        'WV',
      ],
    },
  },
  [LmCpspQuestion.PAINTING_OPERATIONS]: {
    classCode: { display: 'IN', values: ['98304'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PARTY_PLANNER_IQ]: {
    classCode: { display: 'IN', values: ['47367'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SALES_SERVICE_OPERATIONS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'Party Planners/Coordinators' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PERFORMING_REPAIRS]: {
    classCode: { display: 'IN', values: ['10255', '13716', '14279'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PERFORMS_RADON_MEASUREMENTS]: {
    classCode: { display: 'IN', values: ['91155', '95647', '98305'] },
    state: { display: 'IN', values: ['KY'] },
  },
  [LmCpspQuestion.PERSONAL_SHOPPER_IQ]: {
    classCode: { display: 'IN', values: ['47367'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SALES_SERVICE_OPERATIONS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'Personal Shopper' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PEST_RODENT_CONTROL_PGM]: {
    classCode: { display: 'IN', values: ['51315'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PESTICIDE_CERTIFICATE_OF_INSURANCE]: {
    classCode: { display: 'IN', values: ['97047', '97050'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PESTICIDES_LICENSED_CONTRACTOR },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PESTICIDES_LICENSED_CONTRACTOR]: {
    classCode: { display: 'IN', values: ['97047', '97050'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PHF_FERTILIZER },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PESTICIDES_SUPERVISION]: {
    classCode: { display: 'IN', values: ['97047', '97050'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PHF_FERTILIZER },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PESTICIDES_USAGE]: {
    classCode: { display: 'IN', values: ['97047', '97050'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PHF_FERTILIZER },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PET_RESCUE]: {
    classCode: { display: 'IN', values: ['16402', '16403', '16404', '45450', '99851'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PHF_FERTILIZER]: {
    classCode: { display: 'IN', values: ['97050', '97047'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PIPING_REPAIRS]: {
    classCode: { display: 'IN', values: ['92451', '95647', '95648', '98482', '98483', '98884'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PIPING_REPAIRS_DETAILS]: {
    classCode: { display: 'IN', values: ['92451', '95647', '95648', '98482', '98483', '98884'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PIPING_REPAIRS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PLUMBING_YEAR]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.PNEUMATIC_PLUMBING_SYSTEM]: {
    classCode: { display: 'IN', values: ['98482', '98483'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PRIMARY_RISK_STATE]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.PRIOR_COVERAGE]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10026',
        '10040',
        '10042',
        '10065',
        '10066',
        '10070',
        '10071',
        '10072',
        '10073',
        '10100',
        '10111',
        '10113',
        '10115',
        '10140',
        '10141',
        '10145',
        '10146',
        '10150',
        '10204',
        '10255',
        '10257',
        '10309',
        '10352',
        '10367',
        '10368',
        '11007',
        '11020',
        '11039',
        '11126',
        '11127',
        '11128',
        '11160',
        '11167',
        '11168',
        '11222',
        '11234',
        '11258',
        '11259',
        '11288',
        '12014',
        '12356',
        '12361',
        '12362',
        '12374',
        '12375',
        '12391',
        '12393',
        '12467',
        '12510',
        '12651',
        '12797',
        '12805',
        '12841',
        '13049',
        '13111',
        '13112',
        '13351',
        '13453',
        '13454',
        '13455',
        '13506',
        '13507',
        '13590',
        '13670',
        '13673',
        '13715',
        '13716',
        '13720',
        '13759',
        '13930',
        '14101',
        '14279',
        '14401',
        '14405',
        '14527',
        '14655',
        '14731',
        '14732',
        '14733',
        '14734',
        '14913',
        '15070',
        '15223',
        '15224',
        '15404',
        '15538',
        '15600',
        '15607',
        '15608',
        '15699',
        '15839',
        '15991',
        '15993',
        '16005',
        '16402',
        '16403',
        '16404',
        '16471',
        '16501',
        '16527',
        '16588',
        '16676',
        '16705',
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '18109',
        '18110',
        '18200',
        '18205',
        '18206',
        '18335',
        '18435',
        '18436',
        '18437',
        '18438',
        '18501',
        '18507',
        '18575',
        '18616',
        '18833',
        '18834',
        '18911',
        '18912',
        '18920',
        '18991',
        '19007',
        '19051',
        '41603',
        '41604',
        '41650',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41675',
        '41677',
        '41680',
        '41696',
        '43215',
        '43889',
        '44069',
        '44280',
        '45190',
        '45191',
        '45192',
        '45193',
        '45334',
        '45450',
        '45678',
        '45901',
        '45937',
        '45993',
        '46202',
        '46426',
        '46427',
        '46881',
        '46882',
        '47050',
        '47103',
        '47367',
        '48600',
        '49005',
        '49617',
        '49619',
        '49840',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51516',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51896',
        '51919',
        '51926',
        '51927',
        '51957',
        '52002',
        '52134',
        '52432',
        '52438',
        '52467',
        '52469',
        '52505',
        '52660',
        '52967',
        '53374',
        '53375',
        '53376',
        '53377',
        '53565',
        '53732',
        '54077',
        '55371',
        '55802',
        '56041',
        '56042',
        '56202',
        '56390',
        '56391',
        '56758',
        '56759',
        '56760',
        '57257',
        '57410',
        '57572',
        '57600',
        '57716',
        '57808',
        '57871',
        '57913',
        '57997',
        '57998',
        '58009',
        '58057',
        '58095',
        '58096',
        '58408',
        '58409',
        '58456',
        '58457',
        '58458',
        '58459',
        '58627',
        '58663',
        '58759',
        '58903',
        '58922',
        '59005',
        '59057',
        '59058',
        '59223',
        '59482',
        '59781',
        '59782',
        '59889',
        '59905',
        '59914',
        '59915',
        '59917',
        '59923',
        '59963',
        '59964',
        '59975',
        '60010',
        '60011',
        '61212',
        '61216',
        '61217',
        '61218',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '62000',
        '62001',
        '62002',
        '62003',
        '64074',
        '66122',
        '66123',
        '66309',
        '66561',
        '67634',
        '67635',
        '68439',
        '68500',
        '90089',
        '91111',
        '91150',
        '91155',
        '91177',
        '91179',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91555',
        '91560',
        '91577',
        '91581',
        '91585',
        '91746',
        '91805',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '92663',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96317',
        '96611',
        '96816',
        '97047',
        '97050',
        '97220',
        '97447',
        '97650',
        '97653',
        '98090',
        '98091',
        '98111',
        '98304',
        '98305',
        '98344',
        '98405',
        '98482',
        '98483',
        '98502',
        '98597',
        '98598',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99600',
        '99620',
        '99650',
        '99746',
        '99826',
        '99827',
        '99851',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.YEAR_BUSINESS_STARTED },
      operator: DependencyOperator.LESS_THAN,
      right: { source: DependencySource.CODE, value: EvaluatedValueName.CURRENT_YEAR },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PRIOR_COVERAGE_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      type: 'COMPLEX',
      functionName:
        LmCpspComplexEvaluator.PRIOR_COVERAGE_REASON_NEW_ACQUISITION_OR_NONE_OF_THE_ABOVE,
    },
  },
  [LmCpspQuestion.PRIOR_COVERAGE_REASON]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.PRIOR_COVERAGE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'NO',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PRODUCT_DESCRIPTION]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PRODUCT_DISCONTINUED_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PRODUCTS_DISCONTINUED },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PRODUCT_LAWSUIT]: {
    classCode: {
      display: 'IN',
      values: [
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51752',
        '52002',
        '53374',
        '53375',
        '53376',
        '53377',
        '53565',
        '55371',
        '56759',
        '56760',
        '57913',
        '59905',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PRODUCTS_CUSTOMER_SPECIFICATIONS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS,
      },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PRODUCTS_DISCONTINUED]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL,
      },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PRODUCTS_STORED]: {
    classCode: { display: 'IN', values: ['45993'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.INSURED_STORE_PRODUCTS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PROFESSIONAL_LIABILITY_COVERAGE]: {
    classCode: { display: 'ALL' }, // class code is checked in complex evaluator
    state: { display: 'ALL' },
    dependency: {
      functionName:
        LmCpspComplexEvaluator.IS_COMPUTER_CONSULTANT_DOING_E_COMMERCE_OR_IS_MEDICAL_OFFICE,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.PROHIBITED_SMOKING]: {
    classCode: { display: 'IN', values: ['13453', '13454', '13455'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PROPERTIES_LEASED_TO_OTHERS]: {
    classCode: { display: 'IN', values: ['47050', '62000', '62002'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION]: {
    classCode: { display: 'IN', values: ['61226'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION_DETAILS]: {
    classCode: { display: 'IN', values: ['61226'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PROPERTY_DEDUCTIBLE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.PROVIDING_TRAINING]: {
    classCode: { display: 'IN', values: ['18206'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.QUALITY_CONTROL_PROCEDURES]: {
    classCode: {
      display: 'IN',
      values: [
        '10100',
        '11126',
        '11127',
        '13590',
        '51896',
        '52002',
        '52134',
        '52438',
        '52469',
        '53374',
        '53375',
        '53376',
        '53377',
        '53732',
        '55371',
        '55802',
        '57600',
        '57913',
        '58057',
        '58663',
        '59058',
        '59223',
        '59914',
        '91746',
        '92102',
        '97220',
        '11128',
        '14405',
        '15839',
        '18507',
        '51116',
        '51221',
        '51224',
        '51315',
        '51355',
        '51356',
        '51357',
        '51358',
        '51359',
        '51500',
        '51553',
        '51554',
        '51752',
        '51869',
        '51877',
        '51919',
        '51926',
        '51927',
        '51957',
        '52432',
        '52505',
        '53565',
        '54077',
        '56041',
        '56202',
        '56390',
        '56391',
        '56759',
        '56760',
        '57257',
        '57572',
        '57716',
        '57998',
        '58009',
        '58095',
        '58096',
        '58456',
        '58458',
        '58627',
        '58922',
        '59005',
        '59057',
        '59482',
        '59781',
        '59782',
        '59905',
        '59915',
        '59917',
        '59963',
        '59964',
        '59975',
        '99826',
        '99827',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.RARE_ANIMAL_TREATMENT]: {
    classCode: { display: 'IN', values: ['99851'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.RECORDS_MANAGEMENT_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'RecordsManagement',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.REFURBISHED_EQUIPMENT]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10042',
        '10070',
        '10071',
        '10072',
        '10073',
        '10257',
        '11160',
        '12362',
        '12391',
        '12467',
        '13715',
        '13716',
        '13930',
        '14279',
        '16705',
        '18206',
        '51116',
        '51221',
        '51224',
        '58903',
        '59781',
        '59915',
        '91111',
        '97220',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.REFURBISHED_EQUIPMENT_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10010',
        '10042',
        '10070',
        '10071',
        '10073',
        '10257',
        '11160',
        '12362',
        '12391',
        '12467',
        '13715',
        '13716',
        '13930',
        '14279',
        '16705',
        '18206',
        '51116',
        '51221',
        '51224',
        '58903',
        '59781',
        '59915',
        '91111',
        '97220',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.REFURBISHED_EQUIPMENT },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.RELIGIOUS_LIMITATIONS]: {
    classCode: { display: 'IN', values: ['41650'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.RENOVATION]: {
    classCode: { display: 'IN', values: ['61212', '61216', '61217', '61218', '67634', '67635'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.RENOVATION_BY_EMP]: {
    classCode: { display: 'IN', values: ['61212', '61216', '61217', '61218', '67634', '67635'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.RENT_MEDICAL_EQUIPMENT]: {
    classCode: { display: 'IN', values: ['12374', '12375'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.REPAIR_HEAVY_TRUCKS_TRACTORS_R_VS_MOBILE_HOMES]: {
    classCode: { display: 'IN', values: ['10073', '18616'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.REPAIR_INSTALL_PASSENGER_VEHICLES]: {
    classCode: { display: 'IN', values: ['10073', '13453', '13454', '13455', '18616'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.REQUIRE_WRITTEN_CONTRACTS]: {
    classCode: {
      display: 'IN',
      values: [
        '18991',
        '47050',
        '60010',
        '60011',
        '61212',
        '61216',
        '61217',
        '61218',
        '62000',
        '62002',
        '64074',
        '67634',
        '67635',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.REQUIRED_APPLICATORS_LICENSE]: {
    classCode: { display: 'IN', values: ['97047', '97050'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.PESTICIDES_LICENSED_CONTRACTOR },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE]: {
    classCode: {
      display: 'IN',
      values: [
        '10065',
        '10066',
        '10100',
        '11039',
        '11168',
        '11288',
        '12374',
        '12375',
        '13454',
        '13673',
        '14401',
        '15224',
        '16819',
        '16820',
        '16900',
        '16901',
        '16902',
        '16910',
        '16911',
        '16916',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41680',
        '45190',
        '45191',
        '45192',
        '45193',
        '51315',
        '64074',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.HAS_LIMITED_COOKING,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.RESTORATION_WORK]: {
    classCode: {
      display: 'IN',
      values: [
        '91340',
        '91341',
        '91342',
        '91436',
        '91560',
        '92338',
        '92478',
        '94007',
        '94569',
        '95410',
        '95625',
        '95647',
        '95648',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98482',
        '98483',
        '98502',
        '98884',
        '98967',
        '99471',
        '99746',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.RESTORATION_WORK_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '91340',
        '91341',
        '91342',
        '91436',
        '91560',
        '92338',
        '92478',
        '94007',
        '94569',
        '95410',
        '95625',
        '95647',
        '95648',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98482',
        '98483',
        '98502',
        '98884',
        '98967',
        '99471',
        '99746',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.RESTORATION_WORK,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.RISK_CURRENTLY_OR_PAST_10_YEARS]: {
    classCode: {
      display: 'IN',
      values: [
        '91341',
        '97050',
        '98305',
        '97047',
        '92478',
        '94569',
        '99746',
        '92338',
        '94276',
        '91551',
        '98884',
        '95124',
      ],
    },
    state: { display: 'IN', values: ['CA'] },
  },
  [LmCpspQuestion.RISK_MANAGEMENT_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'RiskManagementConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.ROAD_WORK]: {
    classCode: { display: 'IN', values: ['91560', '92215'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ROAD_WORK_DETAILS]: {
    classCode: { display: 'IN', values: ['91560', '92215'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.ROAD_WORK },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.ROOF_CONSTRUCTION_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ROOF_INSTALLATION]: {
    classCode: {
      display: 'IN',
      values: ['13590', '58922', '91340', '91342', '91746', '95625', '98884', '98967'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ROOFING_YEAR]: {
    classCode: { display: 'ALL' },
    state: { display: 'IN', values: ['CA'] },
    dependency: {
      functionName: LmCpspComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.ROOM_OFFERED_RATE_1]: {
    classCode: { display: 'IN', values: ['45190', '45191', '45192', '45193'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.ROOM_RATE_1]: {
    classCode: { display: 'IN', values: ['45190', '45191', '45192', '45193'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SAFETY_PRECAUTIONS]: {
    classCode: {
      display: 'IN',
      values: [
        '10073',
        '51116',
        '51224',
        '51500',
        '52134',
        '52432',
        '52438',
        '52505',
        '53732',
        '56391',
        '57572',
        '57808',
        '57998',
        '58009',
        '58663',
        '58903',
        '58922',
        '59057',
        '59058',
        '59781',
        '59782',
        '59914',
        '59915',
        '59917',
        '59975',
        '97220',
        '97650',
        '97653',
        '98304',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.SPRAY_PAINTING_QUESTION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SALES_OR_STORAGE_QUESTION]: {
    classCode: {
      display: 'IN',
      values: [
        '11160',
        '12362',
        '12393',
        '12841',
        '15699',
        '15991',
        '15993',
        '16005',
        '16403',
        '16676',
        '18920',
        '45450',
        '98091',
        '98304',
        '98305',
        '99003',
      ],
    },
    state: { display: 'IN', values: ['MS', 'WA'] },
    dependency: {
      functionName: LmCpspComplexEvaluator.CSP_CLASS_IS_0570,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.SALES_SERVICE_OPERATIONS]: {
    classCode: { display: 'IN', values: ['47367'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SAND_BLASTING]: {
    classCode: { display: 'IN', values: ['98304', '98305'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SECURITY_PERSONNEL]: {
    classCode: {
      display: 'IN',
      values: ['41650', '61212', '61216', '61217', '61218', '67634', '67635'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SECURITY_SERVICE]: {
    classCode: {
      display: 'IN',
      values: ['41650', '61212', '61216', '61217', '61218', '67634', '67635'],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SECURITY_PERSONNEL },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'NO' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SEMINAR_EDUCATOR_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'SeminarEducatorProfessionalSpeakerConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SERVE_ALCOHOL]: {
    classCode: {
      display: 'IN',
      values: [
        '11168',
        '11288',
        '12361',
        '12374',
        '12375',
        '13670',
        '13673',
        '16819',
        '16820',
        '18435',
        '18436',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.LIQUOR_LIABILITY_IS_NOT_SELECTED,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.SERVE_ALCOHOL_TOTAL_SALES]: {
    classCode: {
      display: 'IN',
      values: [
        '11168',
        '11288',
        '12361',
        '12374',
        '12375',
        '13670',
        '13673',
        '16819',
        '16820',
        '18435',
        '18436',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SERVE_ALCOHOL },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SERVICE_STATION_CAPTURE_CONTROLS]: {
    classCode: { display: 'IN', values: ['13454', '13455'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SERVICE_STATIONS_MISC_OPTS]: {
    classCode: { display: 'IN', values: ['13454', '13455'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SILK_SCREENING]: {
    classCode: { display: 'IN', values: ['58408', '58409'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SMOKE_ALARMS]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003', '68500'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SMOKING_IN_REPAIR_AREAS]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SNOW_PLOW_OPERATIONS]: {
    classCode: {
      display: 'IN',
      values: [
        '91342',
        '92215',
        '94007',
        '95410',
        '95625',
        '97047',
        '97050',
        '97447',
        '98710',
        '98806',
        '99946',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SPA_SALON_SERVICES]: {
    classCode: {
      display: 'IN',
      values: ['10113', '10115', '18200', '45190', '45191', '45192', '45193'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SPRAY_MAN_IND_OPS_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.SPRAY_PAINT_PROCESSING_MFG_OCCUPANCIES_QUESTION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SPRAY_PAINT_PROCESSING_MFG_OCCUPANCIES_QUESTION]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '0' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SPRAY_PAINTING_OR_DIP_TANK_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '10073',
        '51116',
        '51224',
        '51500',
        '52134',
        '52432',
        '52438',
        '52505',
        '53732',
        '56391',
        '57572',
        '57808',
        '57998',
        '58009',
        '58663',
        '58903',
        '58922',
        '59057',
        '59058',
        '59781',
        '59782',
        '59914',
        '59915',
        '59917',
        '59975',
        '97220',
        '97650',
        '97653',
        '98304',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.SPRAY_PAINTING_QUESTION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SPRAY_PAINTING_QUESTION]: {
    classCode: {
      display: 'IN',
      values: [
        '10073',
        '51116',
        '51224',
        '51500',
        '52134',
        '52432',
        '52438',
        '52505',
        '53732',
        '56391',
        '57572',
        '57808',
        '57998',
        '58009',
        '58663',
        '58903',
        '58922',
        '59057',
        '59058',
        '59781',
        '59782',
        '59914',
        '59915',
        '59917',
        '59975',
        '97220',
        '97650',
        '97653',
        '98304',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SPRINKLER]: { classCode: { display: 'ALL' }, state: { display: 'ALL' } },
  [LmCpspQuestion.SUB_CONTRACTORS_PERCENT]: {
    classCode: {
      display: 'IN',
      values: [
        '10113',
        '10115',
        '10367',
        '10368',
        '11007',
        '11039',
        '11222',
        '12014',
        '14731',
        '14732',
        '14733',
        '14734',
        '14913',
        '15070',
        '15600',
        '16402',
        '16404',
        '16471',
        '18109',
        '18200',
        '18991',
        '19007',
        '19051',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41696',
        '43215',
        '43889',
        '44069',
        '45450',
        '45678',
        '45937',
        '46202',
        '47103',
        '49005',
        '51516',
        '52660',
        '57997',
        '58408',
        '58409',
        '90089',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91555',
        '91560',
        '91577',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98090',
        '98091',
        '98111',
        '98304',
        '98305',
        '98344',
        '98405',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99600',
        '99620',
        '99650',
        '99746',
        '99851',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SUBCONTRACTOR_LIMITS]: {
    classCode: {
      display: 'IN',
      values: [
        '10113',
        '10115',
        '10367',
        '10368',
        '11007',
        '11039',
        '11222',
        '12014',
        '14731',
        '14732',
        '14733',
        '14734',
        '14913',
        '15070',
        '15600',
        '16402',
        '16404',
        '16471',
        '18109',
        '18200',
        '18991',
        '19007',
        '19051',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41696',
        '43215',
        '43889',
        '44069',
        '45450',
        '45678',
        '45937',
        '46202',
        '47103',
        '49005',
        '51516',
        '52660',
        '57997',
        '58408',
        '58409',
        '90089',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91555',
        '91560',
        '91577',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98090',
        '98091',
        '98111',
        '98304',
        '98305',
        '98344',
        '98405',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99600',
        '99620',
        '99650',
        '99746',
        '99851',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SUB_CONTRACTORS_PERCENT },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: 0 },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SURVEYORS_PROFESSIONAL_LIABILITY]: {
    classCode: { display: 'IN', values: ['99471'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SWIMMING_POOL]: {
    classCode: {
      display: 'IN',
      values: [
        '18200',
        '41665',
        '41667',
        '41668',
        '41670',
        '45190',
        '45191',
        '45192',
        '45193',
        '46202',
        '60010',
        '60011',
        '62003',
        '64074',
        '68500',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.SWIMMING_POOL_DIVING_BOARD]: {
    classCode: {
      display: 'IN',
      values: [
        '18200',
        '41665',
        '41667',
        '41668',
        '41670',
        '45190',
        '45191',
        '45192',
        '45193',
        '46202',
        '60010',
        '60011',
        '62003',
        '64074',
        '68500',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SWIMMING_POOL },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.SWIMMING_POOL_LOCKED_AND_FENCED]: {
    classCode: {
      display: 'IN',
      values: [
        '18200',
        '41665',
        '41667',
        '41668',
        '41670',
        '45190',
        '45191',
        '45192',
        '45193',
        '46202',
        '60010',
        '60011',
        '62003',
        '64074',
        '68500',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SWIMMING_POOL },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.TABLESIDE_COOKING]: {
    classCode: {
      display: 'IN',
      values: ['16900', '16901', '16910', '16911', '16916'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TENANT_CREDIT_QUESTION]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.INTEREST_PROPERTY },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'TENANT' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_100_K]: {
    classCode: {
      display: 'IN',
      values: [
        '41675',
        '44280',
        '45334',
        '45993',
        '46881',
        '46882',
        '47050',
        '47367',
        '48600',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '66122',
        '66123',
        '68439',
        '91805',
        '92663',
        '96317',
        '96611',
        '98597',
        '98598',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'IA',
        'IL',
        'IN',
        'KS',
        'LA',
        'MA',
        'ME',
        'MI',
        'MN',
        'MO',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OK',
        'RI',
        'SD',
        'TX',
        'UT',
        'VT',
        'WI',
        'WY',
      ],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.INTEREST_PROPERTY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'TENANT',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_150_K]: {
    classCode: {
      display: 'IN',
      values: [
        '41675',
        '44280',
        '45334',
        '45993',
        '46881',
        '46882',
        '47050',
        '47367',
        '48600',
        '61223',
        '61224',
        '61225',
        '61226',
        '61227',
        '66122',
        '66123',
        '68439',
        '91805',
        '92663',
        '96317',
        '96611',
        '98597',
        '98598',
      ],
    },
    state: {
      display: 'IN',
      values: [
        'AK',
        'AL',
        'DC',
        'DE',
        'FL',
        'GA',
        'ID',
        'KY',
        'MD',
        'MS',
        'MT',
        'NC',
        'OH',
        'OR',
        'PA',
        'SC',
        'TN',
        'VA',
        'WA',
        'WV',
      ],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.INTEREST_PROPERTY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'TENANT',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.TIRE_RECAPPING]: {
    classCode: { display: 'IN', values: ['10073', '18616'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TIRE_SALES]: {
    classCode: { display: 'IN', values: ['10073', '13453', '13454', '13455', '18616'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TIRE_STOCK]: {
    classCode: { display: 'IN', values: ['18616'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TOTAL_LIMIT_CUSTOM_PROTECTOR_PROPERTY_FLOATER]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.INLAND_MARINE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_MS]: {
    classCode: {
      display: 'IN',
      values: [
        '41650',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
        '41680',
        '45190',
        '45191',
        '45192',
        '45193',
        '60010',
        '60011',
        '62003',
        '64074',
        '68500',
      ],
    },
    state: { display: 'IN', values: ['MS'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_MS,
    },
  },
  [LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_WA]: {
    classCode: {
      display: 'IN',
      values: [
        '41650',
        '41667',
        '41668',
        '41680',
        '45190',
        '45191',
        '45192',
        '45193',
        '60010',
        '60011',
        '62003',
        '64074',
      ],
    },
    state: { display: 'IN', values: ['WA'] },
    dependency: {
      type: 'COMPLEX',
      functionName: LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_WA,
    },
  },
  [LmCpspQuestion.TOWING]: {
    classCode: {
      display: 'IN',
      values: ['10071', '10072', '10073', '13453', '13454', '13455', '18616'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TRAFFIC_SIGNAL_WORK]: {
    classCode: { display: 'IN', values: ['92215', '92451', '97653'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TRAINED_EMPLOYEE_SERVING_ALCOHOL]: {
    classCode: {
      display: 'IN',
      values: [
        '10140',
        '10141',
        '10145',
        '10146',
        '11168',
        '11288',
        '12361',
        '12374',
        '12375',
        '13670',
        '13673',
        '16819',
        '16820',
        '16910',
        '16911',
        '16916',
        '18435',
        '18436',
        '18501',
        '41665',
        '41667',
        '41668',
        '41669',
        '41670',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.SERVES_ALCOHOL_OR_HAS_LIQUOR_RELATED_CLASS_CODE,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.TRAINING_LEADERSHIP_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'TrainingLeadershipConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.TRAINING_SOFTWARE_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'TrainingSoftwareConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.TRENCHING_EXCAVATING]: {
    classCode: {
      display: 'IN',
      values: [
        '91302',
        '91560',
        '92102',
        '94276',
        '95410',
        '98482',
        '98483',
        '98805',
        '98806',
        '98813',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.TYPE_OF_LICENSES]: {
    classCode: { display: 'IN', values: ['95625'] },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.LICENSED_FOR_TRADE },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.UL_APPROVED_PAINT_BOOTH]: {
    classCode: {
      display: 'IN',
      values: [
        '10073',
        '51116',
        '51224',
        '51500',
        '52134',
        '52432',
        '52438',
        '52505',
        '53732',
        '56391',
        '57572',
        '57808',
        '57998',
        '58009',
        '58663',
        '58903',
        '58922',
        '59057',
        '59058',
        '59781',
        '59782',
        '59914',
        '59915',
        '59917',
        '59975',
        '97220',
        '97650',
        '97653',
        '98304',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.SPRAY_PAINTING_QUESTION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.UL_APPROVED_PAINT_CABINET]: {
    classCode: {
      display: 'IN',
      values: [
        '10073',
        '51116',
        '51224',
        '51500',
        '52134',
        '52432',
        '52438',
        '52505',
        '53732',
        '56391',
        '57572',
        '57808',
        '57998',
        '58009',
        '58663',
        '58903',
        '58922',
        '59057',
        '59058',
        '59781',
        '59782',
        '59914',
        '59915',
        '59917',
        '59975',
        '97220',
        '97650',
        '97653',
        '98304',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.SPRAY_PAINTING_QUESTION,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.UNDERGROUND_OR_HEIGHTS]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '59057',
        '59058',
        '90089',
        '91111',
        '91302',
        '91340',
        '91342',
        '91436',
        '91551',
        '91555',
        '91577',
        '91746',
        '92338',
        '92451',
        '92478',
        '94007',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99600',
        '99650',
      ],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.UNDERGROUND_OR_HEIGHTS_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '59057',
        '59058',
        '90089',
        '91111',
        '91302',
        '91340',
        '91342',
        '91436',
        '91551',
        '91555',
        '91577',
        '91746',
        '92338',
        '92451',
        '92478',
        '94007',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99600',
        '99650',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.UNDERGROUND_OR_HEIGHTS },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: 'YES' },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.USED_MATERIAL_MERCHANDISE]: {
    classCode: {
      display: 'IN',
      values: ['10071', '10255', '10257', '14279', '18437', '18438', '18911', '18912'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.VEHICLES_SOLD]: {
    classCode: { display: 'IN', values: ['10073'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.VOLUNTARY_PROPERTY_DAMAGE_EXTENSION]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.WATER_CONNECTIONS]: {
    classCode: { display: 'IN', values: ['91155'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.WATER_HAZARDS]: {
    classCode: { display: 'IN', values: ['60010', '60011', '62003', '68500'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.WATER_OR_SEWER_MAIN_INSTALLATION]: {
    classCode: {
      display: 'IN',
      values: ['92102', '94007', '95410', '98482', '98483', '98805', '98806', '98813'],
    },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.WATERPROOFING_SURFACES]: {
    classCode: { display: 'IN', values: ['91560', '97447', '98304'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.WEB_HOSTING]: {
    classCode: { display: 'IN', values: ['11160', '41675', '91555'] },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.WEDDING_IQ]: {
    classCode: { display: 'IN', values: ['41677'] },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.CONSULTANT_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'WeddingConsultant',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.WIRING_YEAR]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      functionName: LmCpspComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmCpspQuestion.WRITTEN_ANTI_SEXUAL_HARASSMENT]: {
    classCode: { display: 'ALL' },
    state: { display: 'NOT_IN', values: ['NY'] },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY },
      operator: DependencyOperator.EQUAL,
      right: { source: DependencySource.VALUE, value: true },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.WRITTEN_CONTRACT]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91560',
        '91577',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99650',
        '99746',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.SUB_CONTRACTORS_PERCENT },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: 0 },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.WRITTEN_CONTRACT_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '13590',
        '58922',
        '91111',
        '91150',
        '91155',
        '91302',
        '91340',
        '91341',
        '91342',
        '91405',
        '91436',
        '91551',
        '91560',
        '91577',
        '91746',
        '92102',
        '92215',
        '92338',
        '92451',
        '92478',
        '94007',
        '94276',
        '94569',
        '95124',
        '95410',
        '95487',
        '95625',
        '95647',
        '95648',
        '96053',
        '96816',
        '97047',
        '97050',
        '97447',
        '97650',
        '97653',
        '98304',
        '98305',
        '98344',
        '98482',
        '98483',
        '98502',
        '98636',
        '98710',
        '98805',
        '98806',
        '98813',
        '98884',
        '98967',
        '98993',
        '99003',
        '99004',
        '99471',
        '99505',
        '99650',
        '99746',
        '99946',
        '99948',
      ],
    },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.WRITTEN_CONTRACT,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'NO',
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.YEAR_BUILT_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.YEAR_BUSINESS_STARTED]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.YEAR_ROOF_REPLACED_PROPERTY]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
  },
  [LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmCpspQuestion.YEAR_BUSINESS_STARTED,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.CODE,
        value: EvaluatedValueName.CURRENT_YEAR_MINUS_THREE_YEARS,
      },
      type: 'SIMPLE',
    },
  },
  [LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS]: {
    classCode: { display: 'ALL' },
    state: { display: 'ALL' },
    dependency: {
      left: { source: DependencySource.ID, value: LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE },
      operator: DependencyOperator.GREATER_THAN,
      right: { source: DependencySource.VALUE, value: '0' },
      type: 'SIMPLE',
    },
  },
};

export const LM_CPSP_COMPLEX_DEPENDENCIES: Record<LmCpspComplexEvaluator, ComplexEvaluatorFunc> = {
  [LmCpspComplexEvaluator.ARMED_SECURITY_GUARDS_IS_NO_OR_RENOVATION_BY_EMP_IS_NO]: (form) => {
    // Controls
    const renovationByEmpControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.RENOVATION_BY_EMP)
    );
    const armedSecurityGuardsControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.ARMED_SECURITY_GUARDS)
    );
    // Value change listeners
    const renovationByEmp$ = renovationByEmpControl.valueChanges.pipe(
      startWith(renovationByEmpControl.value)
    );
    const armedSecurityGuards$ = armedSecurityGuardsControl.valueChanges.pipe(
      startWith(armedSecurityGuardsControl.value)
    );

    return {
      dependsOn: combineLatest([renovationByEmp$, armedSecurityGuards$]),
      callback: ([renovationByEmp, armedSecurityGuards]: string[]) => {
        if (
          disabledOrEmpty(renovationByEmpControl, renovationByEmp) &&
          disabledOrEmpty(armedSecurityGuardsControl, armedSecurityGuards)
        ) {
          return false;
        }

        return renovationByEmp === 'NO' || armedSecurityGuards === 'NO';
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_SECURITY_PERSONNEL_OR_HAS_SECURITY_SERVICE]: (form) => {
    const securityPersonnelControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.SECURITY_PERSONNEL)
    );
    const securityServiceControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.SECURITY_SERVICE)
    );

    const securityPersonnel$ = securityPersonnelControl.valueChanges.pipe(
      startWith(securityPersonnelControl.value)
    );
    const securityService$ = securityServiceControl.valueChanges.pipe(
      startWith(securityServiceControl.value)
    );

    return {
      dependsOn: combineLatest([securityPersonnel$, securityService$]),
      callback: ([securityPersonnel, securityService]: string[]) => {
        if (
          disabledOrEmpty(securityPersonnelControl, securityPersonnel) &&
          disabledOrEmpty(securityServiceControl, securityService)
        ) {
          return false;
        }

        return securityPersonnel === 'YES' || securityService === 'YES';
      },
    };
  },
  [LmCpspComplexEvaluator.SERVES_ALCOHOL_OR_HAS_LIQUOR_RELATED_CLASS_CODE]: (form) => {
    // Controls
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );
    const serveAlcoholControl = getControl(form, cpspControlPath(LmCpspQuestion.SERVE_ALCOHOL));

    // Value change listeners
    const glClassCode$ = glClassCodeControl.valueChanges.pipe(startWith(glClassCodeControl.value));
    const serveAlcohol$ = serveAlcoholControl.valueChanges.pipe(
      startWith(serveAlcoholControl.value)
    );

    return {
      dependsOn: combineLatest([glClassCode$, serveAlcohol$]),
      callback: ([glClassCode, serveAlcohol]: [LibertyMutualClassCode, string, boolean]) => {
        if (
          disabledOrEmpty(glClassCodeControl, glClassCode) &&
          disabledOrEmpty(serveAlcoholControl, serveAlcohol)
        ) {
          return false;
        }

        return serveAlcohol === 'YES' || ALCOHOL_RELATED_CLASS_CODES.includes(glClassCode.code);
      },
    };
  },
  [LmCpspComplexEvaluator.CITATION_ALCOHOLIC_BEVERAGES_DEPENDENCY]: (form) => {
    // Controls
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );
    const liquorLiabilityIncludedControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE)
    );
    const serveAlcoholControl = getControl(form, cpspControlPath(LmCpspQuestion.SERVE_ALCOHOL));
    // Value change listeners
    const glClassCode$ = glClassCodeControl.valueChanges.pipe(startWith(glClassCodeControl.value));
    const liquorLiabilityIncluded$ = liquorLiabilityIncludedControl.valueChanges.pipe(
      startWith(liquorLiabilityIncludedControl.value)
    );
    const serveAlcohol$ = serveAlcoholControl.valueChanges.pipe(
      startWith(serveAlcoholControl.value)
    );

    return {
      dependsOn: combineLatest([glClassCode$, serveAlcohol$, liquorLiabilityIncluded$]),
      callback: ([glClassCode, serveAlcohol, liquorLiabilityIncluded]: [
        LibertyMutualClassCode,
        string,
        boolean
      ]) => {
        if (
          disabledOrEmpty(glClassCodeControl, glClassCode) &&
          disabledOrEmpty(liquorLiabilityIncludedControl, liquorLiabilityIncluded) &&
          disabledOrEmpty(serveAlcoholControl, serveAlcohol)
        ) {
          return false;
        }

        return (
          serveAlcohol === 'YES' ||
          liquorLiabilityIncluded ||
          ALCOHOL_RELATED_CLASS_CODES.includes(glClassCode.code)
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_LIMITED_COOKING]: (form) => {
    // Controls
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    const limitedCookingQuestionControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIMITED_COOKING_QUESTION)
    );
    // Value change listeners
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );
    const limitedCookingQuestion$ = limitedCookingQuestionControl.valueChanges.pipe(
      startWith(limitedCookingQuestionControl.value)
    );

    return {
      dependsOn: combineLatest([propertyClassCode$, limitedCookingQuestion$]),
      callback: ([propertyClassCode, limitedCookingQuestion]: string[]) => {
        if (
          disabledOrEmpty(propertyClassCodeControl, propertyClassCode) &&
          disabledOrEmpty(limitedCookingQuestionControl, limitedCookingQuestion)
        ) {
          return false;
        }

        const cspCode = getCspCode(propertyClassCode);
        return (
          limitedCookingQuestion === 'YES' ||
          LM_CPSP_LIMITED_COOKING_RELATED_CSP_CLASS_CODES.includes(cspCode)
        );
      },
    };
  },
  [LmCpspComplexEvaluator.CSP_CLASS_IS_0542]: (form) => {
    // Controls
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    // Value change listeners
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        return (
          !disabledOrEmpty(propertyClassCodeControl, propertyClassCode) &&
          getCspCode(propertyClassCode) === '0542'
        );
      },
    };
  },
  [LmCpspComplexEvaluator.CSP_CLASS_IS_0570]: (form) => {
    // Controls
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    // Value change listeners
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        return (
          !disabledOrEmpty(propertyClassCodeControl, propertyClassCode) &&
          getCspCode(propertyClassCode) === '0570'
        );
      },
    };
  },
  [LmCpspComplexEvaluator.CSP_CLASS_IS_BUILDING_ONLY]: (form) => {
    // Control
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    // Value change listener
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        const BUILDING_COVERAGE_ONLY_CLASS_CODE = '0431';

        return (
          !disabledOrEmpty(propertyClassCodeControl, propertyClassCode) &&
          getCspCode(propertyClassCode) !== BUILDING_COVERAGE_ONLY_CLASS_CODE
        );
      },
    };
  },
  [LmCpspComplexEvaluator.LIQUOR_LIABILITY_IS_NOT_SELECTED]: (form) => {
    // Controls
    const liquorLiabilityControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE)
    );
    // Value change listeners
    const liquorLiability$ = liquorLiabilityControl.valueChanges.pipe(
      startWith(liquorLiabilityControl.value)
    );

    return {
      dependsOn: liquorLiability$,
      callback: (liquorLiability: boolean | null) => {
        return liquorLiability !== true;
      },
    };
  },
  [LmCpspComplexEvaluator.UNOCC_AREA_GREATER_THAN_50_PERCENT_AND_EITHER_BUILDING_OR_BPP_SELECTED]: (
    form
  ) => {
    // Controls
    const areaOccupiedByInsuredControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.AREA_OCCUPIED_BY_INSURED_PROPERTY)
    );
    const areaUnoccupiedControl = getControl(form, cpspControlPath(LmCpspQuestion.AREA_UNOCCUPIED));
    const buildingLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING)
    );
    const bppLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY)
    );
    // Value change listeners
    const areaOccupiedByInsured$ = areaOccupiedByInsuredControl.valueChanges.pipe(
      startWith(areaOccupiedByInsuredControl.value)
    );
    const areaUnoccupied$ = areaUnoccupiedControl.valueChanges.pipe(
      startWith(areaUnoccupiedControl.value)
    );
    const buildingLimit$ = buildingLimitControl.valueChanges.pipe(
      startWith(buildingLimitControl.value)
    );
    const bppLimit$ = bppLimitControl.valueChanges.pipe(startWith(bppLimitControl.value));

    return {
      dependsOn: combineLatest([
        areaOccupiedByInsured$,
        areaUnoccupied$,
        buildingLimit$,
        bppLimit$,
      ]),
      callback: ([areaOccupiedByInsured, areaUnoccupied, buildingLimit, bppLimit]: string[]) => {
        const areaControlsAreDisabled = [areaOccupiedByInsuredControl, areaUnoccupiedControl].some(
          (control) => !control.enabled
        );

        const areaControlsAreEmpty = [areaOccupiedByInsured, areaUnoccupied].some(isEmptyValue);

        const areaControlsAreIncomplete = areaControlsAreDisabled || areaControlsAreEmpty;

        const bothLimitControlsAreIncomplete =
          disabledOrEmpty(buildingLimitControl, buildingLimit) &&
          disabledOrEmpty(bppLimitControl, bppLimit);

        if (areaControlsAreIncomplete || bothLimitControlsAreIncomplete) {
          return false;
        }
        const over50PercentIsUnoccupied =
          Number(areaUnoccupied) / Number(areaOccupiedByInsured) > 0.5;
        const buildingLimitGreaterThanZero =
          !isEmptyValue(buildingLimit) && parseMoney(buildingLimit) > 0;
        const bppLimitGreaterThanZero = !isEmptyValue(bppLimit) && parseMoney(bppLimit) > 0;

        return (
          over50PercentIsUnoccupied && (buildingLimitGreaterThanZero || bppLimitGreaterThanZero)
        );
      },
    };
  },
  [LmCpspComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K]: (form) => {
    // Controls
    const yearBuiltControl = getControl(form, cpspControlPath(LmCpspQuestion.YEAR_BUILT_PROPERTY));
    const buildingLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING)
    );
    const bppLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY)
    );
    // Value change listeners
    const yearBuilt$ = yearBuiltControl.valueChanges.pipe(startWith(yearBuiltControl.value));
    const buildingLimit$ = buildingLimitControl.valueChanges.pipe(
      startWith(buildingLimitControl.value)
    );
    const bppLimit$ = bppLimitControl.valueChanges.pipe(startWith(bppLimitControl.value));

    return {
      dependsOn: combineLatest([yearBuilt$, buildingLimit$, bppLimit$]),
      callback: ([yearBuilt, buildingLimit, bppLimit]: string[]) => {
        const limitValuesAreIncomplete =
          disabledOrEmpty(buildingLimitControl, buildingLimit) &&
          disabledOrEmpty(bppLimitControl, bppLimit);
        if (limitValuesAreIncomplete || disabledOrEmpty(yearBuiltControl, yearBuilt)) {
          return false;
        }

        const TWENTY_FOUR_YEARS_AGO = moment.utc().subtract(24, 'years').format('YYYY');

        const yearBuiltMoment = moment.utc(yearBuilt, 'YYYY');
        const isOver24YearsOld = yearBuiltMoment.isBefore(TWENTY_FOUR_YEARS_AGO);
        const bppLimitOver500K = !isEmptyValue(bppLimit) && parseMoney(bppLimit) > 500 * 1000;
        const buildingLimitOver0 = !isEmptyValue(buildingLimit) && parseMoney(buildingLimit) > 0;

        return isOver24YearsOld && (bppLimitOver500K || buildingLimitOver0);
      },
    };
  },
  [LmCpspComplexEvaluator.GREATER_THAN_25_PERCENT_REVENUE_GENERATED_OFF_PREMISES]: (form) => {
    // Controls
    const externalRevenuesControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.EXTERNAL_INSURED_REVENUES)
    );
    const annualSalesControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.ANNUAL_SALES_RECEIPTS)
    );
    // Value change listeners
    const externalRevenues$ = externalRevenuesControl.valueChanges.pipe(
      startWith(externalRevenuesControl.value)
    );
    const annualSales$ = annualSalesControl.valueChanges.pipe(startWith(annualSalesControl.value));

    return {
      dependsOn: combineLatest([externalRevenues$, annualSales$]),
      callback: ([externalRevenues, annualSales]: string[]) => {
        if (
          disabledOrEmpty(externalRevenuesControl, externalRevenues) ||
          disabledOrEmpty(annualSalesControl, annualSales)
        ) {
          return false;
        }

        return parseMoney(externalRevenues) / parseMoney(annualSales) >= 0.25;
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_MA]: (form) => {
    // Control
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );

    // Change listener
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    // Required for requests with buliding property exposure in MA for the following CSP classes
    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        return (
          propertyClassCodeControl.enabled &&
          LM_CPSP_MA_CSP_RESIDENTIAL_CLASSES.includes(getCspCode(propertyClassCode))
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_WA]: (form) => {
    // Control
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );

    // Change listener
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    // has residential CSP class in WA if it matches any in LM_WA_CSP_CODES
    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        return (
          propertyClassCodeControl.enabled &&
          LM_CPSP_WA_RESIDENTIAL_CSP_CODES.includes(getCspCode(propertyClassCode))
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_MS]: (form) => {
    // Control
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );

    // Change listener
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        return (
          propertyClassCodeControl.enabled &&
          LM_CPSP_MS_MERC_CSP_CODES.includes(getCspCode(propertyClassCode))
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_RESIDENTIAL_CSP_CLASS_MS]: (form) => {
    // Control
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );

    // Change listener
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        return (
          propertyClassCodeControl.enabled &&
          LM_CPSP_MS_RESIDENTIAL_CSP_CODES.includes(getCspCode(propertyClassCode))
        );
      },
    };
  },
  [LmCpspComplexEvaluator.IN_AL_OR_IS_HOTEL_OR_CMML_CONDO]: (form) => {
    // Controls
    const stateControl = getControl(form, cpspControlPath(LmCpspQuestion.PRIMARY_RISK_STATE));
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );
    // Change listeners
    const state$ = stateControl.valueChanges.pipe(startWith(stateControl.value));
    const glClassCode$ = glClassCodeControl.valueChanges.pipe(startWith(glClassCodeControl.value));

    // Enable if state is 'AL' or class code is a hotel/motel or commercial condo
    return {
      dependsOn: combineLatest([state$, glClassCode$]),
      callback: ([state, glClassCode]: [string, LibertyMutualClassCode]) => {
        const isAlabama = state === 'AL';
        const isHotelOrCmmlCondo =
          glClassCode &&
          ['45190', '45191', '45192', '45193', '61216', '62000', '62001', '62002'].includes(
            glClassCode.code
          );
        return isAlabama || isHotelOrCmmlCondo;
      },
    };
  },
  [LmCpspComplexEvaluator.IS_COMPUTER_CONSULTANT_DOING_E_COMMERCE_OR_IS_MEDICAL_OFFICE]: (form) => {
    // Controls
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );
    const eCommerceDesignControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.E_COMMERCE_DESIGN)
    );
    // Change listeners
    const glClassCode$ = glClassCodeControl.valueChanges.pipe(startWith(glClassCodeControl.value));
    const eCommerceDesign$ = eCommerceDesignControl.valueChanges.pipe(
      startWith(eCommerceDesignControl.value)
    );

    // if class is 66561, or is 41675 doing E-commerce design
    return {
      dependsOn: combineLatest([glClassCode$, eCommerceDesign$]),
      callback: ([glClassCode, eCommerceDesign]: [LibertyMutualClassCode, string]) => {
        if (disabledOrEmpty(glClassCodeControl, glClassCode)) {
          return false;
        }
        const isMedicalOffice = glClassCode.code === '66561';
        const isComputerConsultant = glClassCode.code === '41675';
        const doesECommerceDesign = eCommerceDesign === 'YES';

        return isMedicalOffice || (isComputerConsultant && doesECommerceDesign);
      },
    };
  },
  [LmCpspComplexEvaluator.CP_EXTING_SYS_SERV_SEMI_ANNUAL_DEPENDENCY]: (form) => {
    // Controls
    const extinguishingSystemControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM)
    );
    const limitedCookingQuestionControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.LIMITED_COOKING_QUESTION)
    );
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );

    // Change listeners
    const extinguishingSystemChange$ = extinguishingSystemControl.valueChanges.pipe(
      startWith(extinguishingSystemControl.value)
    );
    const limitedCookingQuestion$ = limitedCookingQuestionControl.valueChanges.pipe(
      startWith(limitedCookingQuestionControl.value)
    );
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    // if class code is '0542' AND either extinguishing system 1 or 2 is YES (radio options)
    return {
      dependsOn: combineLatest([
        extinguishingSystemChange$,
        limitedCookingQuestion$,
        propertyClassCode$,
      ]),
      callback: ([extinguishingSystem, limitedCookingQuestion, classCode]: string[]) => {
        const is0542csp = getCspCode(classCode) === '0542';
        const hasExtinguishingSystem =
          extinguishingSystemControl.enabled && extinguishingSystem === 'YES';
        // return hasExtinguishingSystem && (is0542csp || limitedCookingQuestion === 'YES');
        // TODO: remove this line and use the above once LM has fixed their API's behavior. See https://linear.app/attuneinsurance/issue/CARP-578/finalize-limited-looking-question-enablement
        return hasExtinguishingSystem;
      },
    };
  },
  [LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K]: (form) => {
    // Controls
    const dataCompromiseLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT)
    );
    const attackExtortionLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.ATTACK_EXTORTION_LIMIT)
    );
    const networkSecurityLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.NETWORK_SECURITY_LIMIT)
    );

    // Change listeners
    const dataCompromiseLimit$ = dataCompromiseLimitControl.valueChanges.pipe(
      startWith(dataCompromiseLimitControl.value)
    );
    const attackExtortionLimit$ = attackExtortionLimitControl.valueChanges.pipe(
      startWith(attackExtortionLimitControl.value)
    );
    const networkSecurityLimit$ = networkSecurityLimitControl.valueChanges.pipe(
      startWith(networkSecurityLimitControl.value)
    );

    // if data, attack, or network limit is >= $250,000
    return {
      dependsOn: combineLatest([
        dataCompromiseLimit$,
        attackExtortionLimit$,
        networkSecurityLimit$,
      ]),
      callback: ([dataCompromiseLimit, attackExtortionLimit, networkSecurityLimit]: string[]) => {
        const baseValue = 250000;
        const dataLimitIsGte250k =
          dataCompromiseLimitControl.enabled && parseMoney(dataCompromiseLimit) >= baseValue;
        const attackLimitIsGte250k =
          attackExtortionLimitControl.enabled && parseMoney(attackExtortionLimit) >= baseValue;
        const networkLimitIsGte250k =
          networkSecurityLimitControl.enabled && parseMoney(networkSecurityLimit) >= baseValue;

        return dataLimitIsGte250k || attackLimitIsGte250k || networkLimitIsGte250k;
      },
    };
  },
  [LmCpspComplexEvaluator.DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K]: (form) => {
    // Controls
    const dataCompromiseLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT)
    );
    const attackExtortionLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.ATTACK_EXTORTION_LIMIT)
    );
    const networkSecurityLimitControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.NETWORK_SECURITY_LIMIT)
    );

    // Change listeners
    const dataCompromiseLimit$ = dataCompromiseLimitControl.valueChanges.pipe(
      startWith(dataCompromiseLimitControl.value)
    );
    const attackExtortionLimit$ = attackExtortionLimitControl.valueChanges.pipe(
      startWith(attackExtortionLimitControl.value)
    );
    const networkSecurityLimit$ = networkSecurityLimitControl.valueChanges.pipe(
      startWith(networkSecurityLimitControl.value)
    );

    // if data, attack, or network limit is >= $500,000
    return {
      dependsOn: combineLatest([
        dataCompromiseLimit$,
        attackExtortionLimit$,
        networkSecurityLimit$,
      ]),
      callback: ([dataCompromiseLimit, attackExtortionLimit, networkSecurityLimit]: string[]) => {
        const baseValue = 500000;
        const dataLimitIsGte500k =
          dataCompromiseLimitControl.enabled && parseMoney(dataCompromiseLimit) >= baseValue;
        const attackLimitIsGte500k =
          attackExtortionLimitControl.enabled && parseMoney(attackExtortionLimit) >= baseValue;
        const networkLimitIsGte500k =
          networkSecurityLimitControl.enabled && parseMoney(networkSecurityLimit) >= baseValue;

        return dataLimitIsGte500k || attackLimitIsGte500k || networkLimitIsGte500k;
      },
    };
  },
  [LmCpspComplexEvaluator.LIMITED_COOKING_CSP_CLASS]: (form) => {
    // Controls
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    // Value change listeners
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: propertyClassCode$,
      callback: (propertyClassCode: string) => {
        return (
          !disabledOrEmpty(propertyClassCodeControl, propertyClassCode) &&
          LM_CPSP_LIMITED_COOKING_CSP_CLASS_CODES.includes(getCspCode(propertyClassCode))
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_COMMERCIAL_COOKING_AND_INTEREST_IS_NOT_TENANT]: (form) => {
    // Controls
    const interestPropertyControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.INTEREST_PROPERTY)
    );
    const commercialCookingQuestionControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.COMMERCIAL_COOKING_QUESTION)
    );
    // Value change listeners
    const interestProperty$ = interestPropertyControl.valueChanges.pipe(
      startWith(interestPropertyControl.value)
    );
    const commercialCooking$ = commercialCookingQuestionControl.valueChanges.pipe(
      startWith(commercialCookingQuestionControl.value)
    );

    return {
      dependsOn: combineLatest([interestProperty$, commercialCooking$]),
      callback: ([interestProperty, commercialCooking]: [string, string]) => {
        if (
          disabledOrEmpty(interestPropertyControl, interestProperty) ||
          disabledOrEmpty(commercialCookingQuestionControl, commercialCooking)
        ) {
          return false;
        }
        return interestProperty !== 'TENANT' && commercialCooking === 'YES';
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_JM_CONSTRUCTION]: (form) => {
    // Controls
    const constructionTypeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY)
    );
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    // Value change listeners
    const constructionType$ = constructionTypeControl.valueChanges.pipe(
      startWith(constructionTypeControl.value)
    );
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: combineLatest([constructionType$, propertyClassCode$]),
      callback: ([constructionType, propertyClassCode]: [string, string]) => {
        if (
          disabledOrEmpty(constructionTypeControl, constructionType) ||
          disabledOrEmpty(propertyClassCodeControl, propertyClassCode)
        ) {
          return false;
        }
        return (
          LM_CPSP_MERC_AND_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES.includes(
            getCspCode(propertyClassCode)
          ) && constructionType === 'JM'
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_FRAME_NC_CONSTRUCTION]: (form) => {
    // Controls
    const constructionTypeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY)
    );
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    // Value change listeners
    const constructionType$ = constructionTypeControl.valueChanges.pipe(
      startWith(constructionTypeControl.value)
    );
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: combineLatest([constructionType$, propertyClassCode$]),
      callback: ([constructionType, propertyClassCode]: [string, string]) => {
        if (
          disabledOrEmpty(constructionTypeControl, constructionType) ||
          disabledOrEmpty(propertyClassCodeControl, propertyClassCode)
        ) {
          return false;
        }
        return (
          LM_CPSP_MERC_AND_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES.includes(
            getCspCode(propertyClassCode)
          ) && ['F', 'NC'].includes(constructionType)
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_WA]: (form) => {
    // Controls
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );

    // Change listeners
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: propertyClassCode$,
      callback: (classCode: string) => {
        return (
          !disabledOrEmpty(propertyClassCodeControl, classCode) &&
          LM_CPSP_MERC_AND_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES.includes(getCspCode(classCode))
        );
      },
    };
  },

  [LmCpspComplexEvaluator.HAS_BUILDING_CVG_AND_CONSTRUCTION_IS_FR_JM_NC_AND_INCIDENTAL_APT_EXISTS]:
    (form) => {
      // Controls
      const constructionTypeControl = getControl(
        form,
        cpspControlPath(LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY)
      );
      const propertyClassCodeControl = getControl(
        form,
        cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
      );
      // Value change listeners
      const constructionType$ = constructionTypeControl.valueChanges.pipe(
        startWith(constructionTypeControl.value)
      );
      const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
        startWith(propertyClassCodeControl.value)
      );

      return {
        dependsOn: combineLatest([constructionType$, propertyClassCode$]),
        callback: ([constructionType, propertyClassCode]: [string, string]) => {
          if (
            disabledOrEmpty(constructionTypeControl, constructionType) ||
            disabledOrEmpty(propertyClassCodeControl, propertyClassCode)
          ) {
            return false;
          }
          return (
            LM_CPSP_INCIDENTAL_APT_CSP_CLASS_CODES.includes(getCspCode(propertyClassCode)) &&
            ['F', 'JM', 'NC'].includes(constructionType)
          );
        },
      };
    },
  [LmCpspComplexEvaluator.PRIOR_COVERAGE_REASON_NEW_ACQUISITION_OR_NONE_OF_THE_ABOVE]: (form) => {
    // Controls
    const priorCoverageReasonControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PRIOR_COVERAGE_REASON)
    );
    // Value change listeners
    const priorCoverageReason$ = priorCoverageReasonControl.valueChanges.pipe(
      startWith(priorCoverageReasonControl.value)
    );

    return {
      dependsOn: priorCoverageReason$,
      callback: (priorCoverageReason: string) => {
        return (
          !disabledOrEmpty(priorCoverageReasonControl, priorCoverageReason) &&
          VALID_PRIOR_COVERAGE_REASONS_NEW_ACQUISITION_OR_NONE_OF_THE_ABOVE.includes(
            priorCoverageReason
          )
        );
      },
    };
  },
  [LmCpspComplexEvaluator.HAS_APT_WITH_MERC_CSP_CLASS_AND_MNC_MFR_FR_CONSTRUCTION]: (form) => {
    // Controls
    const constructionTypeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY)
    );
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );
    const propertyClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE)
    );
    // Value change listeners
    const constructionType$ = constructionTypeControl.valueChanges.pipe(
      startWith(constructionTypeControl.value)
    );
    const glClassCode$ = glClassCodeControl.valueChanges.pipe(startWith(glClassCodeControl.value));
    const propertyClassCode$ = propertyClassCodeControl.valueChanges.pipe(
      startWith(propertyClassCodeControl.value)
    );

    return {
      dependsOn: combineLatest([constructionType$, glClassCode$, propertyClassCode$]),
      callback: ([constructionType, glClassCode, propertyClassCode]: [
        string,
        LibertyMutualClassCode,
        string
      ]) => {
        if (
          disabledOrEmpty(constructionTypeControl, constructionType) ||
          disabledOrEmpty(glClassCodeControl, glClassCode) ||
          disabledOrEmpty(propertyClassCodeControl, propertyClassCode)
        ) {
          return false;
        }
        return (
          ((['60010', '60011'].includes(glClassCode.code) &&
            LM_CPSP_MS_MERC_CSP_CODES.includes(getCspCode(propertyClassCode))) ||
            (glClassCode.code === '62003' &&
              LM_CPSP_RESIDENTIAL_CONDOMINIUM_CSP_CLASS_CODES.includes(
                getCspCode(propertyClassCode)
              ))) &&
          ['MNC', 'MFR', 'R'].includes(constructionType)
        );
      },
    };
  },
  [LmCpspComplexEvaluator.BACKGROUND_CHECKS_DEPENDENCY]: (form) => {
    // Controls
    const enterResidencesControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.ENTER_RESIDENCES)
    );
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );
    // Value change listeners
    const enterResidences$ = enterResidencesControl.valueChanges.pipe(
      startWith(enterResidencesControl.value)
    );
    const glClassCode$ = glClassCodeControl.valueChanges.pipe(startWith(glClassCodeControl.value));

    return {
      dependsOn: combineLatest([enterResidences$, glClassCode$]),
      callback: ([enterResidences, glClassCode]: [string, LibertyMutualClassCode]) => {
        if (
          disabledOrEmpty(enterResidencesControl, enterResidences) &&
          disabledOrEmpty(glClassCodeControl, glClassCode)
        ) {
          return false;
        }
        if (!glClassCode) {
          return false;
        }
        return (
          ['45190', '45191', '45192', '45193', '60010', '60011', '96816'].includes(
            glClassCode.code
          ) || enterResidences === 'YES'
        );
      },
    };
  },
  [LmCpspComplexEvaluator.EXCAVATION_OR_TRENCHING_EXCAVATING]: (form) => {
    // Controls
    const trenchingExcavatingControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.TRENCHING_EXCAVATING)
    );
    const glClassCodeControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE)
    );
    // Value change listeners
    const trenchingExcavating$ = trenchingExcavatingControl.valueChanges.pipe(
      startWith(trenchingExcavatingControl.value)
    );
    const glClassCode$ = glClassCodeControl.valueChanges.pipe(startWith(glClassCodeControl.value));

    return {
      dependsOn: combineLatest([trenchingExcavating$, glClassCode$]),
      callback: ([trenchingExcavating, glClassCode]: [string, LibertyMutualClassCode]) => {
        if (
          disabledOrEmpty(trenchingExcavatingControl, trenchingExcavating) &&
          disabledOrEmpty(glClassCodeControl, glClassCode)
        ) {
          return false;
        }
        return glClassCode.code === '94007' || trenchingExcavating === 'YES';
      },
    };
  },
  // Note: this complex dependency is to enable the loss property control if the user
  // has selected "Property" (PROPC) in the line of business drop down
  [LmCpspComplexEvaluator.HAS_LOSS_TYPE_PROPERTY]: (form, formArrayIndex) => {
    // Control
    const hasLossesControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED)
    );

    const lineOfBusinessControl = getControl(
      form,
      cpspLossHistoryControlPath(
        LmCpspLossHistoryQuestion.LINE_OF_BUSINESS,
        formArrayIndex as number
      )
    );

    // Control observable
    const hasLossesControl$ = hasLossesControl.valueChanges.pipe(startWith(hasLossesControl.value));

    const lineOfBusinessControl$ = lineOfBusinessControl.valueChanges.pipe(
      startWith(lineOfBusinessControl.value)
    );

    return {
      dependsOn: combineLatest([hasLossesControl$, lineOfBusinessControl$]),
      callback: ([lossesSelection, lineOfBusiness]: [string, string]) => {
        return (
          hasLossesControl.enabled &&
          lossesSelection === '1' &&
          lineOfBusinessControl.enabled &&
          lineOfBusiness === 'PROPC'
        );
      },
    };
  },
  // Note: this complex dependency is to enable the loss general liability control if the user
  // has selected "General Liability-Occurrence" (CGL) or "Employment Practices" (EPLI)
  // in the line of business drop down
  [LmCpspComplexEvaluator.HAS_LOSS_TYPE_GENERAL_LIABILITY]: (form, formArrayIndex) => {
    // Controls
    const hasLossesControl = getControl(
      form,
      cpspControlPath(LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED)
    );

    const lineOfBusinessControl = getControl(
      form,
      cpspLossHistoryControlPath(
        LmCpspLossHistoryQuestion.LINE_OF_BUSINESS,
        formArrayIndex as number
      )
    );

    // Control observables
    const hasLossesControl$ = hasLossesControl.valueChanges.pipe(startWith(hasLossesControl.value));

    const lineOfBusinessControl$ = lineOfBusinessControl.valueChanges.pipe(
      startWith(lineOfBusinessControl.value)
    );

    return {
      dependsOn: combineLatest([hasLossesControl$, lineOfBusinessControl$]),
      callback: ([lossesSelection, lineOfBusiness]: [string, string]) => {
        const businessLineOptions = ['CGL'];

        return (
          hasLossesControl.enabled &&
          lossesSelection === '1' &&
          lineOfBusinessControl.enabled &&
          businessLineOptions.includes(lineOfBusiness)
        );
      },
    };
  },
};
