import * as _ from 'lodash';
import { ComplianceDocument, InsuranceProductCode } from '../../digital-carrier/models/types';
import { DOCUMENT_TYPES } from '../services/document.service';

export const getQuoteLetterFileName = (id: string, product: InsuranceProductCode) =>
  `${product}-quote-letter-${id}.pdf`;
export const getAttuneBopPolicyFileName = (id: string) => `policy-${id}.pdf`;
export const getAttuneBopNonRenewNoticeFileName = (policyNumber: string) =>
  `${policyNumber}-nonrenew.pdf`;
export const getCoalitionCyberRiskAssessmentFileName = () => `cyber-risk-profile.pdf`;
export const getQuoteSubmissionFileName = (id: string, product: InsuranceProductCode) =>
  `${product}-quote-application-${id}.pdf`;
export const getComplianceDocumentFileName = (doc: ComplianceDocument) =>
  `${doc.state}-${_.snakeCase(doc.document_name)}.pdf`;
export const getAttuneBopCancellationFileName = (id: string) => `cancel-${id}.pdf`;
export const getAttuneBopPolicyChangeFileName = (id: string) => `policy-change-${id}.pdf`;
export const getEmployersWCQuoteLetterFileName = (id: string) => `wc-quote-EIG${id}.pdf`;
export const getEmployersWCPolicyDocumentFileName = (
  id: string,
  term: string,
  documentType: string
) => `wc-${id}-${term}-${documentType.toLowerCase().replace(' ', '-')}.pdf`;
export const getScheduleOfInvoicesFileName = (id: string) => `schedule-of-invoices-${id}.pdf`;
export const getInsuredBillingGuideFileName = () => 'Insured Billing Guide.pdf';
export const getInvoiceReceiptFileName = (id: string) => `invoice-${id}-receipt.pdf`;
export const getAttuneCommissionStatementFileName = (id: string) =>
  `attune-commission-statement-${id}.xls`;
export const getPartnerCommissionStatementFileName = (id: string, documentType: DOCUMENT_TYPES) =>
  `partner-commission-statement-${id}.${documentType}`;
export const getFormFileName = (id: string) => `form-template-${id}.pdf`;
export const getLossRunsDocumentsFileName = (policyNumber: string, latestDate: string) =>
  `${policyNumber}/${latestDate}.zip`;
