import { OnInit, Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AmplitudeService } from '../../../core/services/amplitude.service';
import { Observable, Subscription } from 'rxjs';
import { LinkStatus } from './policy-automation-landing.component';
import * as moment from 'moment';
import {
  EndorsementResponseInfo,
  PolicyAutomationLandingService,
} from '../../services/policy-automation-landing.service';
import { getAttuneBopPolicyChangeFileName } from '../../../features/documents/models/document-file-names.model';
import { DocumentService } from '../../../features/documents/services/document.service';

@Component({
  selector: 'app-endorse-landing',
  templateUrl: './endorse-landing.component.html',
})
export class EndorseLandingComponent implements OnInit, OnDestroy {
  @Input() endorsementResponseInfo: EndorsementResponseInfo;
  @Input() linkStatus: LinkStatus;
  @Input() linkId: string;
  @Input() emailAddress: string;
  @Output() statusChange = new EventEmitter<LinkStatus>();
  isConfirmingEndorsement = false;
  isWithdrawingEndorsement = false;
  quotedCost: number;
  costChange: number;

  policyDocumentDownload$: Observable<any>;
  policyDocumentDownloadReady$: Observable<any>;
  private sub = new Subscription();

  constructor(
    private amplitudeService: AmplitudeService,
    private landingService: PolicyAutomationLandingService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    if (this.endorsementResponseInfo) {
      this.quotedCost = Number(this.endorsementResponseInfo.quotedCost.replace(/[$]/, ''));
      this.costChange = Number(this.endorsementResponseInfo.costChange.replace(/[$]/, ''));
    }

    if (this.linkStatus === LinkStatus.CONFIRM) {
      this.loadPolicyDocLink();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  confirmEndorsement() {
    this.isConfirmingEndorsement = true;
    this.sub.add(
      this.landingService.confirmLink(this.linkId).subscribe((result) => {
        this.isConfirmingEndorsement = false;
        if (result) {
          this.loadPolicyDocLink();
          this.statusChange.emit(LinkStatus.CONFIRM);
        } else {
          this.statusChange.emit(LinkStatus.ERROR);
        }
      })
    );
  }

  withdrawEndorsement() {
    this.isWithdrawingEndorsement = true;
    this.sub.add(
      this.landingService.withdrawLink(this.linkId).subscribe((result) => {
        this.isWithdrawingEndorsement = false;
        if (result) {
          this.statusChange.emit(LinkStatus.WITHDRAW);
        } else {
          this.statusChange.emit(LinkStatus.ERROR);
        }
      })
    );
  }

  loadPolicyDocLink() {
    this.sub.add(
      this.landingService.getPolicyDocLink(this.linkId).subscribe((result: any) => {
        if (result) {
          const fileName = getAttuneBopPolicyChangeFileName(
            this.endorsementResponseInfo.policyNumber
          );
          this.policyDocumentDownload$ = this.documentService.getDocument(
            result.link,
            fileName,
            'pdf'
          );
          this.policyDocumentDownloadReady$ = this.documentService.pollDocument(result.link);
        }
      })
    );
  }

  getEndorsementDescription() {
    return this.endorsementResponseInfo.description;
  }

  getPremiumChangeSign() {
    return this.costChange < 0 ? '-' : '+';
  }

  getPremiumChanges() {
    return Math.abs(this.costChange);
  }

  getOriginalPremium() {
    return this.quotedCost - this.costChange;
  }

  getEffectiveDate() {
    return moment(this.endorsementResponseInfo.effectiveDate).utc().format('MM/DD/YYYY');
  }

  getPremiumInteger() {
    return Math.trunc(this.quotedCost);
  }

  getPremiumDecimal() {
    return (this.quotedCost - this.getPremiumInteger()) * 100;
  }

  isReviewStatus() {
    return this.linkStatus === LinkStatus.REVIEW;
  }

  isConfirmStatus() {
    return this.linkStatus === LinkStatus.CONFIRM;
  }
}
