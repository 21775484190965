<section class="form-animation">
  <h1 class="form-header-below-link">Additional Coverages</h1>

  <app-form-field-select
    inputId="hab-additionalCoverages-{{ [AdditionalCoverages.ORDINANCE_OR_LAW_DEMOLITION_COST] }}"
    [form]="form"
    [submitted]="hasSubmitted()"
    labelText="Ordinance or Law - Demolition Cost"
    [nameOfFormControl]="AdditionalCoverages.ORDINANCE_OR_LAW_DEMOLITION_COST"
    [availableOptions]="CoverageOptions[AdditionalCoverages.ORDINANCE_OR_LAW_DEMOLITION_COST]"
  >
  </app-form-field-select>

  <app-form-field-select
    inputId="hab-additionalCoverages-{{
      [AdditionalCoverages.ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION]
    }}"
    [form]="form"
    [submitted]="hasSubmitted()"
    labelText="Ordinance or Law - Increased Cost of Construction"
    [nameOfFormControl]="AdditionalCoverages.ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION"
    [availableOptions]="
      CoverageOptions[AdditionalCoverages.ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION]
    "
  >
  </app-form-field-select>

  <app-form-field-select
    inputId="hab-additionalCoverages-{{
      [AdditionalCoverages.BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT]
    }}"
    [form]="form"
    [submitted]="hasSubmitted()"
    labelText="Below Ground Water and Backup of Sewer and Drain Limit"
    [nameOfFormControl]="AdditionalCoverages.BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT"
    [availableOptions]="
      CoverageOptions[AdditionalCoverages.BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT]
    "
  >
  </app-form-field-select>

  <app-form-field-select
    inputId="hab-additionalCoverages-{{ [AdditionalCoverages.EMPLOYEE_THEFT_LIMIT] }}"
    [form]="form"
    [submitted]="hasSubmitted()"
    labelText="Employee Theft Limit"
    [nameOfFormControl]="AdditionalCoverages.EMPLOYEE_THEFT_LIMIT"
    [availableOptions]="CoverageOptions[AdditionalCoverages.EMPLOYEE_THEFT_LIMIT]"
  >
  </app-form-field-select>

  <app-form-field-select
    inputId="hab-additionalCoverages-{{ [AdditionalCoverages.FORGERY_OR_ALTERATION_LIMIT] }}"
    [form]="form"
    [submitted]="hasSubmitted()"
    labelText="Forgery or Alteration Limit"
    [nameOfFormControl]="AdditionalCoverages.FORGERY_OR_ALTERATION_LIMIT"
    [availableOptions]="CoverageOptions[AdditionalCoverages.FORGERY_OR_ALTERATION_LIMIT]"
  >
  </app-form-field-select>

  <hr />
</section>
