import { Component, OnInit } from '@angular/core';
import { RewardsService } from '../../../../shared/services/rewards.service';
import { UserService } from '../../../../core/services/user.service';
import { switchMap } from 'rxjs/operators';
import { User } from '../../../../shared/models/user';
import { NUM_POINTS_IN_A_DOLLAR } from '../../pages/rewards-page/rewards-page.component';
import {
  RewardsBalanceInfo,
  RewardsTransaction,
  RewardsTransactions,
} from '../../../../shared/rewards/rewards-types';

@Component({
  selector: 'app-rewards-activity',
  templateUrl: './rewards-activity.component.html',
})
export class RewardsActivityComponent implements OnInit {
  transactions: RewardsTransaction[];
  totalRedeemedThisYear: number;

  constructor(private rewardsService: RewardsService, private userService: UserService) {}

  ngOnInit() {
    this.userService
      .getUser()
      .pipe(
        switchMap((user: User) => {
          return this.rewardsService.fetchRewardsActivity(user.userName);
        })
      )
      .subscribe((transactionResponse: RewardsTransactions) => {
        this.transactions = transactionResponse.transactions;
      });

    this.rewardsService.getRewardsBalance().subscribe((rewardsBalanceInfo: RewardsBalanceInfo) => {
      this.totalRedeemedThisYear = rewardsBalanceInfo.totalRedeemedThisYear;
    });
  }

  pointsToDollarValue(points: number) {
    return Math.floor(points / NUM_POINTS_IN_A_DOLLAR);
  }
}
