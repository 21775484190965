import { Injectable } from '@angular/core';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

import {
  enableDisableControl,
  minDateExceededValidator,
  requireCheckboxesToBeCheckedValidator,
  zipCodeValidator,
  getControl,
  getFormGroup,
  getFormArray,
  patchControl,
} from 'app/shared/helpers/form-helpers';
import {
  validateNoSpecialCharactersInAddress,
  validateEmailAddress,
  uniqueAddressValidator,
  addressesEqual,
  checkForMinimumBppLimit,
  checkForFailedUpload,
} from 'app/features/attune-bop/models/form-validators';
import { get, isEqual, isMatch, clone, omit, cloneDeep, range } from 'lodash';
import * as moment from 'moment';
import { US_DATE_MASK } from 'app/constants';
import {
  UWQuestion,
  BOP_V2_UW_COMPANY,
  MAX_BACKDATE_AGE_IN_DAYS,
  BPP_LIMIT_CHANGE_THRESHOLDS,
  BPP_LIMIT_REL_CHANGE_THRESHOLDS,
  BUILDING_LIMIT_REL_CHANGE_THRESHOLDS,
} from 'app/features/attune-bop/models/constants';
import {
  BopEndorsementForm,
  CoveredBuilding,
} from 'app/features/attune-bop/models/bop-endorsement';

import { AdditionalInsuredsMixin } from 'app/features/attune-bop/mixins/additional-insureds.mixin';
import { LocationFormMixin } from 'app/features/attune-bop/mixins/location-form.mixin';
import { WaiverOfSubrogationMixin } from 'app/features/attune-bop/mixins/waiver-of-subrogation.mixin';
import { SubgrogationWaiverName } from 'app/features/attune-bop/models/bop-policy';
import { ReplaySubject, combineLatest, of as observableOf, merge } from 'rxjs';
import { CurrentUserService } from '../../../core/services/current-user.service';
import { environment } from 'environments/environment';
import { ZENDESK_NEW_REQUEST_LINK } from 'app/features/invoices/models/invoices-constants';
import { BuildingClassificationCategory } from '../models/building-classifications';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';

type EndorsementSteps =
  | 'account'
  | 'additionalInsureds'
  | 'coverages'
  | 'waiverOfSubrogation'
  | 'locations'
  | 'namedInsureds'
  | 'otherEndorsements';

// form steps
const ACCOUNT_DETAILS_STEP: RouteFormStep = {
  args: {},
  displayName: 'Basic Business Information',
  slug: 'account',
  formPath: 'account',
  parent: 'account',
};

const AI_STEP: RouteFormStep = {
  args: {},
  displayName: 'Additional Insureds',
  slug: 'additional-insureds',
  formPath: 'additionalInsureds',
  parent: 'additionalInsureds',
};

const WAIVER_STEP: RouteFormStep = {
  args: {},
  displayName: 'Waiver of Subrogation',
  slug: 'waiver-of-subrogation',
  formPath: 'waiverOfSubrogation',
  parent: 'waiverOfSubrogation',
};

const COVERAGE_STEP: RouteFormStep = {
  args: {},
  displayName: 'Coverages',
  slug: 'coverages',
  formPath: 'coverages',
  parent: 'coverages',
};

const POLICY_INFO_STEP: RouteFormStep = {
  args: {},
  displayName: 'Policy Info',
  slug: 'policy-info',
  formPath: 'policyInfo',
  parent: 'policyInfo',
};

const NAMED_INSUREDS_STEP: RouteFormStep = {
  args: {},
  displayName: 'Named Insureds',
  slug: 'named-insureds',
  formPath: 'namedInsureds',
  parent: 'namedInsureds',
};

const OTHER_STEP: RouteFormStep = {
  args: {},
  displayName: 'Other Change Requests',
  slug: 'other-endorsement',
  formPath: 'otherEndorsements',
  parent: 'otherEndorsements',
};

const REVIEW_STEP: RouteFormStep = {
  args: {},
  displayName: 'Review',
  slug: 'review',
  formPath: 'review',
  parent: 'review',
  nextButtonText: 'Endorse',
};

// Set of endorsement types which may be backdated beyond the normal permitted range
// In this case, the endorsement request will go to Zendesk for approval via the "partially automated" flow
const allowedTypesForBackdating = ['additionalInsureds', 'coverages'];

const hasDisallowedBackdateTypesSelected = (currentTypesState: Record<string, boolean>) => {
  const currentTypesSelected = Object.keys(currentTypesState).filter(
    (field) => currentTypesState[field]
  );

  return currentTypesSelected.some((type) => !allowedTypesForBackdating.includes(type));
};

const disableBackdatingForDisallowedTypesValidator =
  (earliestAllowedDate: moment.MomentInput): ValidatorFn =>
  (formGroup: UntypedFormGroup) => {
    const currentTypesState = formGroup.controls.typeOfEndorsementOptIn.value;
    const hasDisallowedTypes = hasDisallowedBackdateTypesSelected(currentTypesState);

    if (!hasDisallowedTypes) {
      return null;
    } else {
      return minDateExceededValidator(earliestAllowedDate, 'day')(formGroup.controls.effectiveDate);
    }
  };

export interface EndorsementOptInTypes {
  account: boolean;
  additionalInsureds: boolean;
  waiverOfSubrogation: boolean;
  coverages: boolean;
  locations: boolean;
  namedInsureds: boolean;
  otherEndorsements: boolean;
}

class FormService extends FormDslSteppedFormBaseService {
  lessorsRiskPageFeatureFlaggedOn = environment.lroFlowEnabled;

  generateSteps(): RouteFormStep[] {
    const newLocationSteps: RouteFormStep[] = this.form ? this.generateLocationSteps() : [];

    const additionalSteps = !this.form
      ? []
      : [
          this.stepOptedIn('account') ? clone(ACCOUNT_DETAILS_STEP) : null,
          this.stepOptedIn('additionalInsureds') ? clone(AI_STEP) : null,
          this.stepOptedIn('coverages') ? clone(COVERAGE_STEP) : null,
          this.stepOptedIn('waiverOfSubrogation') ? clone(WAIVER_STEP) : null,
          this.stepOptedIn('locations') ? clone(POLICY_INFO_STEP) : null,
          ...(this.stepOptedIn('locations') ? cloneDeep(newLocationSteps) : [null]),
          this.stepOptedIn('namedInsureds') ? clone(NAMED_INSUREDS_STEP) : null,
          this.stepOptedIn('otherEndorsements') ? clone(OTHER_STEP) : null,
        ];

    const filteredAdditionalSteps = additionalSteps.filter(
      (step) => step !== null
    ) as RouteFormStep[];

    if (filteredAdditionalSteps.length > 0) {
      const reviewStep = clone(REVIEW_STEP);

      if (!this.isEndorsementAutomated()) {
        reviewStep.nextButtonText = 'Submit for Review';
      }
      filteredAdditionalSteps.push(reviewStep);

      // If a review step is added, change 2nd to last step's next button to `Submit`.
      filteredAdditionalSteps[filteredAdditionalSteps.length - 2].nextButtonText = 'Submit';
    }

    return [
      {
        args: {},
        displayName: 'Endorse Policy',
        slug: 'endorsement-requests',
        formPath: 'endorsementRequests',
        parent: 'endorsementRequests',
      },
      ...filteredAdditionalSteps,
    ];
  }
  // This method only adds Location form steps for newly added locations, since existing location changes
  // are handled in the 'Coverages' step.
  generateLocationSteps() {
    const locationAddresses: any[] = getFormArray(this.form, 'policyInfo.locationAddresses').value;
    const newLocationAddresses = locationAddresses.filter((locationAddress) => {
      return !Object.prototype.hasOwnProperty.call(locationAddress, 'originalLocationNumber');
    });

    const existingLocationCount = locationAddresses.length - newLocationAddresses.length;
    const locationSteps: RouteFormStep[] = [];

    for (let locationIndex = 0; locationIndex < newLocationAddresses.length; locationIndex++) {
      const locationNumber = locationIndex + existingLocationCount + 1;

      const stepsToAdd: RouteFormStep[] = [
        {
          args: {},
          displayName: `Location ${locationNumber}`,
          slug: `location-${locationNumber}`,
          parent: `location-${locationNumber}`,
          formPath: `locations.${locationIndex}.locationDetails`,
        },
        {
          args: {},
          displayName: `Building Details`,
          slug: `building-${locationNumber}-1`,
          parent: `location-${locationNumber}`,
          substep: true,
          formPath: `locations.${locationIndex}.buildings.0.exposure`,
        },
        // Will return null if the building is not LRO, we filter out null steps when assembling the steps.
        this.buildingLessorsRiskStep(locationIndex, locationNumber),
        {
          args: {},
          displayName: `Building Coverage`,
          slug: `building-${locationNumber}-1-coverages`,
          parent: `location-${locationNumber}`,
          substep: true,
          formPath: `locations.${locationIndex}.buildings.0.coverage`,
        },
      ].filter((step): step is RouteFormStep => step !== null);

      locationSteps.push(...stepsToAdd);
    }
    return locationSteps;
  }

  private buildingLessorsRiskStep(
    locationIndex: number,
    locationNumber: number
  ): RouteFormStep | null {
    // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
    if (!this.lessorsRiskPageFeatureFlaggedOn) {
      return null;
    }

    // Steps are generated once before the form is initialized
    if (!this.form) {
      return null;
    }

    const lessorsRiskFormPath = `locations.${locationIndex}.buildings.0.exposure.lessorsRisk`;
    const lessorsRiskFormControl = this.get<UntypedFormControl>(lessorsRiskFormPath);

    if (!lessorsRiskFormControl) {
      return null;
    }

    const showLessorsRiskStep = lessorsRiskFormControl.enabled && lessorsRiskFormControl.value;
    if (showLessorsRiskStep) {
      return {
        args: {},
        displayName: "Lessors' Risk Details",
        slug: `building-${locationNumber}-1-lessors-risk`,
        parent: `location-${locationNumber}`,
        substep: true,
        formPath: `locations.${locationIndex}.buildings.0.lessorsRisk`,
      };
    }
    return null;
  }

  fillInHappyPath() {}

  stepOptedIn(stepName: EndorsementSteps): boolean {
    if (!this.form) {
      return false;
    }

    const endorsementOptInFormGroup = getFormGroup(
      this.form,
      'endorsementRequests.typeOfEndorsementOptIn'
    );

    if (endorsementOptInFormGroup && endorsementOptInFormGroup.value[stepName]) {
      return true;
    }

    return false;
  }

  endorsementHasValidBackdate(): boolean {
    const effectiveDateControl = getControl(this.form, 'endorsementRequests.effectiveDate');
    const effectiveDate = moment(effectiveDateControl.value).startOf('day');
    const today = moment().startOf('day');

    const isNotBackdated = effectiveDate.isSameOrAfter(today);
    if (isNotBackdated) {
      return false;
    }

    const endorsementOptInFormGroup = getFormGroup(
      this.form,
      'endorsementRequests.typeOfEndorsementOptIn'
    );
    const currentTypesState = endorsementOptInFormGroup.value;
    const hasDisallowedTypes = hasDisallowedBackdateTypesSelected(currentTypesState);

    if (!hasDisallowedTypes) {
      return true;
    }

    const earliestAllowedBackdate = moment()
      .subtract(MAX_BACKDATE_AGE_IN_DAYS, 'days')
      .startOf('day');

    return effectiveDate.isSameOrAfter(earliestAllowedBackdate) && effectiveDate.isBefore(today);
  }

  checkIfNkllIsRequired(): boolean {
    // Feature flag for backdating endorsements.
    if (!environment.hasBackdatedEndorsements) {
      return false;
    }

    if (!this.form) {
      return false;
    }

    if (!this.endorsementHasValidBackdate()) {
      return false;
    }

    /**
     * Note: This method will primarily be used to toggle on/off the NKLL upload widget form array.
     * For AI endorsements where NKLL is only required for certain types, that validation will be handled in the
     * NKLL Form array validator.
     */
    const NKLL_ENDORSEMENT_TYPES: EndorsementSteps[] = [
      'additionalInsureds',
      'coverages',
      'locations',
      'namedInsureds',
      'otherEndorsements',
    ];

    const typeOfEndorsementFormGroup = this.form.get(
      'endorsementRequests.typeOfEndorsementOptIn'
    ) as UntypedFormGroup;

    const hasNKLLEndorsement = Object.entries(typeOfEndorsementFormGroup.value).some(
      ([endorsementType, enabled]: [EndorsementSteps, boolean]) => {
        if (enabled) {
          return NKLL_ENDORSEMENT_TYPES.includes(endorsementType);
        }
      }
    );

    return hasNKLLEndorsement;
  }

  updateNkllControl() {
    const noKnownLossLetterFiles = getFormArray(
      this.form,
      'endorsementRequests.noKnownLossLetterFiles'
    );

    enableDisableControl(noKnownLossLetterFiles, this.checkIfNkllIsRequired());
  }

  updateReasonForBackdatingControl() {
    const reasonForBackdatingControl = getControl(
      this.form,
      'endorsementRequests.reasonForBackdating'
    );
    enableDisableControl(reasonForBackdatingControl, this.endorsementHasValidBackdate());
  }

  isEndorsementAutomated() {
    if (!this.form) {
      return false;
    }

    const hasBopPlusPolicy = getControl(this.form, 'review.hasBopPlusPolicy').value;
    const hasBopPlusQuote = getControl(this.form, 'review.hasBopPlusQuote').value;

    if (hasBopPlusPolicy || hasBopPlusQuote) {
      return false;
    }

    const endorsementOptInFormGroup = this.form.get(
      'endorsementRequests.typeOfEndorsementOptIn'
    ) as UntypedFormGroup;

    const endorsementOptInValue: { [key in EndorsementSteps]: boolean } =
      endorsementOptInFormGroup.value;

    const additionalInsuredsFormArray = getFormArray(
      this.form,
      'additionalInsureds.additionalInsuredBusinesses'
    );
    const quoteReturnedSuccessfullyControl = getControl(
      this.form,
      'review.quoteReturnedSuccessfully'
    );
    const quoteWasDeclinedFormControl = getControl(this.form, 'review.quoteWasDeclined');
    const policyChangeFailedControl = getControl(this.form, 'review.policyChangeFailed');
    const policyChangeTimedOutControl = getControl(this.form, 'review.policyChangeTimedOut');
    const premiumIncreaseIsSmall = getControl(this.form, 'review.premiumIncreaseIsSmall');

    const AUTOMATED_AI_TYPES = [
      'MORTGAGEE',
      'OWNERS_OF_LAND',
      'LOSS_PAYABLE',
      'BUILDING_OWNER',
      'MORTGAGE_HOLDER',
      'CONTROLLING_INTEREST',
      'CO_OWNER',
    ];

    // If an endorsement is quoted and the quote is declined, we submit the entire endorsement for review.
    if (quoteWasDeclinedFormControl.value) {
      return false;
    }

    // If an endorsement attempt fails, submit for manual review.
    if (policyChangeFailedControl.value) {
      return false;
    }

    // If an endorsement attempt fails, submit for manual review.
    if (policyChangeTimedOutControl.value) {
      return false;
    }

    // Named insured endorsements always go to either a manual or partially automated flow.
    if (endorsementOptInValue.namedInsureds) {
      return false;
    }

    // If the premium increase is below an UW threshold and the transaction is not waived, we do not allow in-portal bind.
    if (premiumIncreaseIsSmall.value) {
      return false;
    }

    // These type of endorsements are never automated.
    if (endorsementOptInValue.otherEndorsements) {
      return false;
    }
    if (endorsementOptInValue.coverages && (<any>this).coverageChangesRequireReview()) {
      return false;
    }
    // If any existing location has been modified or removed, endorsement is not automated.
    if ((<any>this).originalPolicyInfoHasChanged()) {
      return false;
    }

    // If the request is backdated beyond the point that can be automated, it goes to a partial automation review
    const today = moment().startOf('day');
    const effectiveDateControl = getControl(this.form, 'endorsementRequests.effectiveDate');
    const effectiveDate = moment(effectiveDateControl.value).startOf('day');
    const earliestAllowedBackdate = moment()
      .subtract(MAX_BACKDATE_AGE_IN_DAYS, 'days')
      .startOf('day');

    const isBackdatedPastAutomation = effectiveDate.isBefore(earliestAllowedBackdate);
    if (isBackdatedPastAutomation) {
      return false;
    }

    // If the quote has already returned, then the endorsement was automated at the
    // time of submission. (Do this before the NKLL check in case the request was made at
    // midnight, and the date has changed by the time we get to the review page.)
    if (quoteReturnedSuccessfullyControl.value) {
      return true;
    }

    const nkllIsRequired = this.checkIfNkllIsRequired();

    if (nkllIsRequired && endorsementOptInValue.locations) {
      return false;
    }

    if (nkllIsRequired && endorsementOptInValue.additionalInsureds) {
      return additionalInsuredsFormArray.controls.every((aiFormGroup: UntypedFormGroup) => {
        // Pre-existing AIs not added/edited during this endorsement do not affect if this endorsement is automated.
        if (!aiFormGroup.dirty) {
          return true;
        }
        return AUTOMATED_AI_TYPES.includes(aiFormGroup.value.type);
      });
    }
    return true;
  }

  updateReviewStep() {
    const isAutomated = this.isEndorsementAutomated();
    const {
      brokerEmailAddress,
      policyChangeFailed,
      premiumIncreaseIsSmall,
      quoteReturnedSuccessfully,
      quoteWasDeclined,
      hasBopPlusQuote,
      hasBopPlusPolicy,
    } = getFormGroup(this.form, 'review').controls;

    // Control for automated review step:
    enableDisableControl(quoteReturnedSuccessfully, isAutomated);
    // Controls for non-automated review step:
    enableDisableControl(brokerEmailAddress, !isAutomated);
    enableDisableControl(quoteWasDeclined, !isAutomated);
    enableDisableControl(policyChangeFailed, !isAutomated);
    enableDisableControl(hasBopPlusQuote, !isAutomated);
    enableDisableControl(hasBopPlusPolicy, !isAutomated);
    enableDisableControl(premiumIncreaseIsSmall, !isAutomated);

    this.syncAllSteps();
  }
}

// Adds methods to add/remove waivers of subrogation and Additional insureds to the form.
const FormServiceWithMixins = LocationFormMixin(
  WaiverOfSubrogationMixin(AdditionalInsuredsMixin(FormService)),
  'policyInfo.locationAddresses',
  'locations',
  'policyInfo.baseState',
  'policyInfo.bopVersion'
);

@Injectable()
export class AttuneBopEndorseQuoteFormService extends FormServiceWithMixins {
  form: UntypedFormGroup;
  originalFormValue: BopEndorsementForm;
  uwQuestions: UWQuestion[];
  nonAutomatedFieldsHaveChanged = false;

  retrievedBopQuote$ = new ReplaySubject<BaseQuote>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private currentUserService: CurrentUserService
  ) {
    super();
    this.initializeForm();
    this.setupFormValueListeners();
    this.syncAllSteps();
  }

  formChangeValidation(originalFormPath: string): ValidatorFn {
    return (control: UntypedFormGroup): ValidationErrors | null => {
      if (!this.formValueHasChanged(control.value, originalFormPath, true)) {
        return {
          noChangeMadeToForm: {
            validationMessage:
              'A change is required to continue or select cancel to return to the accounts page.',
          },
        };
      }
      return null;
    };
  }

  initializeForm() {
    // We default the form to today's date in the user's timezone.
    const today = moment();

    const effectiveDate = this.formBuilder.control(today.format(US_DATE_MASK), [
      Validators.required,
      minDateExceededValidator(today.startOf('day'), 'day'),
    ]);

    const noKnownLossLetterFiles = this.formBuilder.array([], [this.nkllValidation]);
    // Disabled by default
    noKnownLossLetterFiles.disable({ onlySelf: true });

    const typeOfEndorsementOptIn = this.formBuilder.group(
      {
        account: [false, Validators.required],
        additionalInsureds: [false, Validators.required],
        coverages: [false, Validators.required],
        waiverOfSubrogation: [false, Validators.required],
        locations: [false, Validators.required],
        namedInsureds: [false, Validators.required],
        otherEndorsements: [false, Validators.required],
      },
      {
        validators: requireCheckboxesToBeCheckedValidator(
          1,
          'Please select at least one endorsement.'
        ),
      }
    );

    const reasonForBackdating = this.formBuilder.control('', Validators.required);
    // Disabled by default
    reasonForBackdating.disable({ onlySelf: true });

    const endorsementRequests = this.formBuilder.group({
      effectiveDate,
      typeOfEndorsementOptIn,
      noKnownLossLetterFiles,
      reasonForBackdating,
    });

    const account = this.formBuilder.group(
      {
        id: null,
        doingBusinessAs: '',
        address: this.formBuilder.group({
          addressLine1: [
            null,
            [Validators.required, validateNoSpecialCharactersInAddress, Validators.maxLength(60)],
          ],
          addressLine2: [null, [validateNoSpecialCharactersInAddress, Validators.maxLength(60)]],
          city: [null, Validators.required],
          state: [null, [Validators.required]],
          zip: [null, [Validators.required, zipCodeValidator]],
        }),
      },
      { validators: this.formChangeValidation('account') }
    );

    /*
      NOTE(nabil): The the additionalInsuredBusinesses form array has to be nested in a formGroup.
        Async validation on FormArrays creates a bug where the form's fty is stuck in a PENDING state.
        See: https://github.com/angular/angular/issues/10064
    */
    const additionalInsureds = this.formBuilder.group(
      {
        additionalInsuredBusinesses: this.formBuilder.array([]),
        aiCount: [0, Validators.required],
      },
      {
        validators: [
          this.formChangeValidation('additionalInsureds'),
          this.nkllValidationWrapper(noKnownLossLetterFiles),
        ],
      }
    );

    const waiverOfSubrogation = this.formBuilder.group(
      {
        waiversOptIn: [false, Validators.required],
        waiversOfSubrogation: this.formBuilder.array([]),
      },
      { validators: this.formChangeValidation('waiverOfSubrogation') }
    );

    const namedInsureds = this.formBuilder.group({
      typeOfChange: [null, Validators.required],
      primaryName: ['', Validators.required],
      primaryAddress: this.formBuilder.group({
        addressLine1: [
          null,
          [Validators.required, validateNoSpecialCharactersInAddress, Validators.maxLength(60)],
        ],
        addressLine2: [null, [validateNoSpecialCharactersInAddress, Validators.maxLength(60)]],
        city: [null, Validators.required],
        state: [null, [Validators.required]],
        zip: [null, [Validators.required, zipCodeValidator]],
      }),
      additionalName: ['', Validators.required],
      additionalAddress: this.formBuilder.group({
        addressLine1: [
          null,
          [Validators.required, validateNoSpecialCharactersInAddress, Validators.maxLength(60)],
        ],
        addressLine2: [null, [validateNoSpecialCharactersInAddress, Validators.maxLength(60)]],
        city: [null, Validators.required],
        state: [null, [Validators.required]],
        zip: [null, [Validators.required, zipCodeValidator]],
      }),
      additionalCommonOwnership: [false, Validators.required],
      additionalOperations: ['', Validators.required],
      explanation: ['', Validators.required],
      hasChangedOwnership: [false, Validators.required],
      descriptionOfChange: ['', Validators.required],
    });

    const otherEndorsements = this.formBuilder.group({
      zendeskEndorsement: [null, Validators.required],
    });

    const currentUser = this.currentUserService.getCurrentUser();
    const emailAddress = currentUser ? currentUser.username : '';
    // Coverage endorsements - building limits, bpp

    const coverages = this.formBuilder.group(
      {
        exposures: this.formBuilder.group({
          coveredBuildings: this.formBuilder.array([], Validators.required),
        }),
        miscellaneousCoverageChangeRequests: [''],
      },
      { validators: this.formChangeValidation('coverages') }
    );

    const locationAddresses = new UntypedFormArray([]);
    const removedLocationAddresses = new UntypedFormArray([]);

    const policyInfo = this.formBuilder.group(
      {
        baseState: [null],
        bopVersion: [1],
        changeInOwnershipStructure: [null, Validators.required],
        reasonForChange: ['', Validators.required],
        locationAddresses,
        organizationType: [null, Validators.required],
        removedLocationAddresses,
      },
      {
        validators: [this.formChangeValidation('policyInfo'), uniqueAddressValidator],
      }
    );
    policyInfo.disable();

    const locations = this.formBuilder.array([]);

    const review = this.formBuilder.group({
      quoteReturnedSuccessfully: [null, Validators.requiredTrue],
      policyChangeFailed: [false, Validators.required],
      policyChangeTimedOut: [false, Validators.required],
      premiumIncreaseIsSmall: [false, Validators.required],
      quoteWasDeclined: [false, Validators.required],
      hasBopPlusQuote: [false],
      hasBopPlusPolicy: [false],
      bppLimitChangeOutsideThreshold: [false],
      namedInsuredChange: [false],
      buildingLimitChangeOutsideThreshold: [false],
      brokerEmailAddress: [emailAddress, [Validators.required, validateEmailAddress]],
    });

    const endorseForm = this.formBuilder.group({
      endorsementRequests,
      account,
      additionalInsureds,
      waiverOfSubrogation,
      policyInfo,
      locations,
      namedInsureds,
      otherEndorsements,
      coverages,
      review,
    });
    // Disable all steps and setup valueChanges subscription to enable them for activated steps
    this.setupEndorsementListeners(endorseForm);

    // Setup effective date listeners for backdated endorsements.
    this.setupEffectiveDateListeners(endorseForm);

    this.retrievedBopQuote$.subscribe((retrievedBopQuote) => {
      this.updateWaiverOfSubrogationStep(retrievedBopQuote);
    });

    const coverageOptInFormControl = typeOfEndorsementOptIn.get('coverages') as UntypedFormControl;

    const miscellaneousCoverageChangeRequestsControl = getControl(
      coverages,
      'miscellaneousCoverageChangeRequests'
    );

    const coverageOptIn$ = coverageOptInFormControl.valueChanges;

    coverageOptIn$.subscribe((coverageOptIn) => {
      enableDisableControl(miscellaneousCoverageChangeRequestsControl, coverageOptIn);
      this.toggleBuildingControls(coverageOptIn);
    });

    const locationsOptInFormControl = getControl(typeOfEndorsementOptIn, 'locations');
    const organizationTypeControl = getControl(policyInfo, 'organizationType');
    const changeInOwnershipStructureControl = getControl(policyInfo, 'changeInOwnershipStructure');
    const reasonForChangeControl = getControl(policyInfo, 'reasonForChange');

    locationsOptInFormControl.valueChanges.subscribe((locationsOptInValue) => {
      enableDisableControl(changeInOwnershipStructureControl, locationsOptInValue);
      enableDisableControl(locationAddresses, locationsOptInValue);
      enableDisableControl(organizationTypeControl, locationsOptInValue);
      enableDisableControl(reasonForChangeControl, locationsOptInValue);
    });

    this.form = endorseForm;

    // This needs to happen within initializeForm so that it occurs before we patch the form.
    this.setupExposureObservables();

    this.getBopExposure$().subscribe((bopExposure: BopExposureInfo) => {
      // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
      if (!this.lessorsRiskPageFeatureFlaggedOn) {
        return;
      }

      bopExposure.locations.forEach((location: BopLocation, locationIndex: number) => {
        location.buildings.forEach((building: BopBuilding, buildingIndex: number) => {
          const lroFormGroup = this.get<UntypedFormGroup>([
            'locations',
            locationIndex,
            'buildings',
            buildingIndex,
            'lessorsRisk',
          ]);
          const nullIfNoChange = enableDisableControl(lroFormGroup, building.exposure.lessorsRisk);

          if (nullIfNoChange !== null) {
            // The LRO step is an optional step and if the form is toggled we want to re-sync.
            this.syncAllSteps();
          }
        });
      });
    });
  }

  toggleBuildingControls(enable: boolean) {
    this.coveredBuildingsFormArray().controls.forEach((coveredBuilding: UntypedFormGroup) => {
      const address = getFormGroup(coveredBuilding, 'address');
      const bppLimit = getControl(coveredBuilding, 'limitForBusinessPersonalProperty');
      const buildingIndex = getControl(coveredBuilding, 'buildingIndex');
      const buildingLimit = getControl(coveredBuilding, 'buildingLimit');

      enableDisableControl(address, enable);
      enableDisableControl(bppLimit, enable);
      enableDisableControl(buildingIndex, enable);
      enableDisableControl(buildingLimit, enable);
    });
  }

  addBuildingLocationCoverageFormGroup(validators: { [key: string]: ValidatorFn }) {
    const noClaimsValidator = (noClaimsControl: UntypedFormControl): ValidationErrors | null => {
      if (noClaimsControl.value === true || noClaimsControl.disabled) {
        return null;
      }
      return {
        noClaimsNotConfirmed: {
          validationMessage: `We are unable to process coverage changes without confirmation. Please follow up with the Customer Care team <a href="${ZENDESK_NEW_REQUEST_LINK}" target="_blank">here</a> if you are unable to do so.`,
        },
      };
    };

    const bppLimitChangeFiles = this.formBuilder.array([], checkForFailedUpload);
    bppLimitChangeFiles.disable();

    const buildingLimitChangeFiles = this.formBuilder.array([], checkForFailedUpload);
    buildingLimitChangeFiles.disable();

    const buildingLocationCoverageLimitGroup = this.formBuilder.group({
      buildingIndex: [null],
      guidewireId: '',
      address: this.formBuilder.group({
        addressLine1: [null],
        addressLine2: [null],
        city: [null],
        state: [null],
        zip: [null],
      }),
      lessorsRisk: [null],
      limitForBusinessPersonalProperty: ['$0', [Validators.required, validators.bppLimitValidator]],
      bppLimitChangeFiles,
      bppLimitChangeReason: [{ value: null, disabled: true }, Validators.required],
      bppLimitChangeInOwnershipStructure: [{ value: null, disabled: true }, Validators.required],
      buildingLimit: ['$0', Validators.required],
      buildingLimitChangeFiles,
      buildingLimitChangeReason: [{ value: null, disabled: true }, Validators.required],
      buildingLimitChangeInOwnershipStructure: [
        { value: null, disabled: true },
        [Validators.required],
      ],
    });

    // Whether the file upload is required depends on the BPP limit, so we check validity whenever BPP limit changes.
    getControl(
      buildingLocationCoverageLimitGroup,
      'limitForBusinessPersonalProperty'
    ).valueChanges.subscribe(() => {
      bppLimitChangeFiles.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    });

    // Whether the file upload is required depends on the Building limit, so we check validity whenever Building limit changes.
    getControl(buildingLocationCoverageLimitGroup, 'buildingLimit').valueChanges.subscribe(() => {
      buildingLimitChangeFiles.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    });

    const coveredBuildingsFormArray = this.coveredBuildingsFormArray();
    coveredBuildingsFormArray.push(buildingLocationCoverageLimitGroup);
  }

  setupEffectiveDateListeners(endorseForm: UntypedFormGroup) {
    const effDateFormControl = endorseForm.get(
      'endorsementRequests.effectiveDate'
    ) as UntypedFormControl;

    effDateFormControl.valueChanges.subscribe((effDateValue) => {
      this.updateNkllControl();
      this.updateReasonForBackdatingControl();
    });
  }

  setupEndorsementListeners(endorseForm: UntypedFormGroup) {
    const optInControlGroup = endorseForm.get(
      'endorsementRequests.typeOfEndorsementOptIn'
    ) as UntypedFormGroup;

    // Disable all form steps (same as when they are set to false)
    Object.keys(optInControlGroup.value).forEach((endorsementPage) => {
      (<UntypedFormGroup>endorseForm.get(endorsementPage)).disable();
    });

    // Disable checkbox controls for flagged-out steps
    if (environment.enabledBopEndorsementSteps) {
      Object.keys(optInControlGroup.getRawValue()).forEach((endorsementPage) => {
        const pageCheckbox = optInControlGroup.get(endorsementPage) as null | UntypedFormControl;
        if (pageCheckbox === null) {
          console.log(`DEV Warning: Step missing on checkbox form - ${endorsementPage}`);
          return;
        }

        if (
          !environment.enabledBopEndorsementSteps.includes(endorsementPage) &&
          pageCheckbox.enabled
        ) {
          pageCheckbox.disable();
        }
      });
    }

    optInControlGroup.valueChanges.subscribe((optedInValue: { [key: string]: boolean }) => {
      // Enable/Disable additional insureds formGroup if the user selects this type of endorsement.
      Object.keys(optedInValue).forEach((endorsementPage) => {
        const stepGroup = <UntypedFormGroup>endorseForm.get(endorsementPage);
        const enabled = optedInValue[endorsementPage];

        if (endorsementPage === 'coverages' || endorsementPage === 'locations') {
          // coverages endorsement type is handled differently due to nested excess liability
          // locations steps are enabled/disabled by user input on the PolicyInfo step
          return;
        }

        if (stepGroup.disabled && enabled) {
          stepGroup.enable();

          const additionalInsuredsFormArray = getFormArray(
            stepGroup,
            'additionalInsuredBusinesses'
          );
          // Start with a new blank Additional Insured if none exist on the policy and the user selects this endorsement type.
          if (additionalInsuredsFormArray && additionalInsuredsFormArray.length === 0) {
            this.addAdditionalInsuredBusiness(additionalInsuredsFormArray);
          }

          if (this.bopVersion() === 1) {
            const waiversOfSubrogationFormArray = getFormArray(stepGroup, 'waiversOfSubrogation');
            // Start with a new blank Waiver of subrogation if none exist on the policy and the user selects this endorsement type.
            if (waiversOfSubrogationFormArray && waiversOfSubrogationFormArray.length === 0) {
              this.addWaiverOfSubrogation(waiversOfSubrogationFormArray); // NOTE: enables the array!
            }
          }
          this.updateWaiverOfSubrogationControls();
        } else if (stepGroup.enabled && !enabled) {
          stepGroup.disable();
        }
      });

      // Update NKLL Control, which has different logic if AI page is enabled/disabled
      this.updateNkllControl();
    });
  }

  setupFormValueListeners() {
    const endorsementOptInFormGroup = this.get(
      'endorsementRequests.typeOfEndorsementOptIn'
    ) as UntypedFormGroup;

    const additionalInsuredsFormArray = getFormArray(
      this.form,
      'additionalInsureds.additionalInsuredBusinesses'
    );
    const aiCountControl = getControl(this.form, 'additionalInsureds.aiCount');
    const coveragesFormGroup = getFormGroup(this.form, 'coverages');
    const policyInfoFormGroup = getFormGroup(this.form, 'policyInfo');
    const namedInsuredsFormGroup = getFormGroup(this.form, 'namedInsureds');
    const nkllFilesFormArr = getFormArray(this.form, 'endorsementRequests.noKnownLossLetterFiles');

    endorsementOptInFormGroup.valueChanges.subscribe((formGroupValue) => {
      this.updateReviewStep();
      // Recheck whether NKLL is required whenever optin group changes.
      nkllFilesFormArr.updateValueAndValidity({ emitEvent: false });
    });

    additionalInsuredsFormArray.valueChanges.subscribe(() => {
      aiCountControl.patchValue(additionalInsuredsFormArray.length);
      this.updateNkllControl();
      this.updateReviewStep();
    });

    coveragesFormGroup.valueChanges.subscribe(() => {
      this.updateReviewStep();
    });

    policyInfoFormGroup.valueChanges.subscribe(() => {
      this.updateReviewStep();
    });

    // Any time this Form Array changes we should also re-run validation on the AI form group.
    nkllFilesFormArr.valueChanges.subscribe(() => {
      additionalInsuredsFormArray.updateValueAndValidity({ emitEvent: false });
    });

    const namedInsuredPrimaryNameControl = getControl(namedInsuredsFormGroup, 'primaryName');
    const namedInsuredPrimaryAddressControl = getControl(namedInsuredsFormGroup, 'primaryAddress');
    const namedInsuredAdditionalNameControl = getControl(namedInsuredsFormGroup, 'additionalName');
    const namedInsuredAdditionalCommonOwnership = getControl(
      namedInsuredsFormGroup,
      'additionalCommonOwnership'
    );
    const namedInsuredAdditionalOperations = getControl(
      namedInsuredsFormGroup,
      'additionalOperations'
    );
    const namedInsuredAdditionalAddressControl = getControl(
      namedInsuredsFormGroup,
      'additionalAddress'
    );
    const namedInsuredOwnershipDescriptionControl = getControl(
      namedInsuredsFormGroup,
      'descriptionOfChange'
    );
    const namedInsuredExplanationControl = getControl(namedInsuredsFormGroup, 'explanation');
    const namedInsuredOwnershipChangeControl = getControl(
      namedInsuredsFormGroup,
      'hasChangedOwnership'
    );

    namedInsuredsFormGroup.valueChanges.subscribe((formGroupValue) => {
      const isAddingAdditional = formGroupValue.typeOfChange === 'add_additional';
      const isUpdatingPrimary = formGroupValue.typeOfChange === 'update_primary';
      const hasChosenType = !!formGroupValue.typeOfChange;

      enableDisableControl(namedInsuredPrimaryNameControl, isUpdatingPrimary);
      enableDisableControl(namedInsuredPrimaryAddressControl, isUpdatingPrimary);
      enableDisableControl(namedInsuredAdditionalNameControl, isAddingAdditional);
      enableDisableControl(namedInsuredAdditionalAddressControl, isAddingAdditional);
      enableDisableControl(namedInsuredAdditionalCommonOwnership, isAddingAdditional);
      enableDisableControl(namedInsuredAdditionalOperations, isAddingAdditional);
      enableDisableControl(namedInsuredExplanationControl, hasChosenType);
      enableDisableControl(namedInsuredOwnershipChangeControl, hasChosenType);
      enableDisableControl(
        namedInsuredOwnershipDescriptionControl,
        formGroupValue.hasChangedOwnership
      );
    });
  }

  // If 'checkOnlyEnabledControls' is true, ignore any disabled controls when checking for changes.
  formValueHasChanged(
    currentValue: any,
    controlPath: string,
    checkOnlyEnabledControls = false
  ): boolean {
    const originalValue = get(this.originalFormValue, controlPath);
    if (checkOnlyEnabledControls) {
      return !isMatch(originalValue, currentValue);
    }
    return !isEqual(originalValue, currentValue);
  }

  setupCoverageValueListeners() {
    const coverageChangesOptIn$ = getControl(
      this.form,
      'endorsementRequests.typeOfEndorsementOptIn.coverages'
    ).valueChanges;
    this.coveredBuildingsFormArray().controls.forEach(
      (coveredBuildingForm: UntypedFormGroup, index) => {
        const {
          limitForBusinessPersonalProperty,
          bppLimitChangeFiles,
          bppLimitChangeReason,
          bppLimitChangeInOwnershipStructure,
          buildingLimit,
          buildingLimitChangeFiles,
          buildingLimitChangeReason,
          buildingLimitChangeInOwnershipStructure,
          guidewireId,
        } = coveredBuildingForm.controls;

        const bppLimitHasChanged$ = limitForBusinessPersonalProperty.valueChanges.pipe(
          map(() => this.bppLimitHasChanged(index)),
          startWith(false)
        );
        const buildingLimitHasChanged$ = buildingLimit.valueChanges.pipe(
          map(() => this.buildingLimitHasChanged(index)),
          startWith(false)
        );

        const bppThresholdReview = getControl(this.form, 'review.bppLimitChangeOutsideThreshold');
        const buildingThresholdReview = getControl(
          this.form,
          'review.buildingLimitChangeOutsideThreshold'
        );

        combineLatest(
          bppLimitHasChanged$,
          buildingLimitHasChanged$,
          coverageChangesOptIn$
        ).subscribe(([bppLimitHasChanged, buildingLimitHasChanged, coverageChangesOptIn]) => {
          // BPP Limit controls
          const enableBppControls = bppLimitHasChanged && coverageChangesOptIn;
          enableDisableControl(bppLimitChangeFiles, enableBppControls);
          enableDisableControl(bppLimitChangeReason, enableBppControls);
          enableDisableControl(bppLimitChangeInOwnershipStructure, enableBppControls);
          // Building Limit controls
          const enableBuildingControls = buildingLimitHasChanged && coverageChangesOptIn;
          enableDisableControl(buildingLimitChangeFiles, enableBuildingControls);
          enableDisableControl(buildingLimitChangeReason, enableBuildingControls);
          enableDisableControl(buildingLimitChangeInOwnershipStructure, enableBuildingControls);

          // Enable guidewireId if either value has changed
          enableDisableControl(guidewireId, enableBppControls || enableBuildingControls);

          // Update review for BPP/Building limit thresholds
          if (!bppThresholdReview.value && this.bppLimitRequiresReview()) {
            bppThresholdReview.patchValue(true);
          } else if (bppThresholdReview.value && !this.bppLimitRequiresReview()) {
            bppThresholdReview.patchValue(false);
          }

          if (!buildingThresholdReview.value && this.buildingLimitRequiresReview()) {
            buildingThresholdReview.patchValue(true);
          } else if (buildingThresholdReview.value && !this.buildingLimitRequiresReview()) {
            buildingThresholdReview.patchValue(false);
          }
        });

        bppLimitChangeFiles.disable();
        bppLimitChangeReason.disable();
        bppLimitChangeInOwnershipStructure.disable();
        buildingLimitChangeFiles.disable();
        buildingLimitChangeReason.disable();
        buildingLimitChangeInOwnershipStructure.disable();
      }
    );

    const miscellaneousCoverageChangePath = 'coverages.miscellaneousCoverageChangeRequests';

    const miscellaneousCoverageRequest = getControl(this.form, miscellaneousCoverageChangePath);

    const miscellaneousCoverageHasChanged$ = miscellaneousCoverageRequest.valueChanges.pipe(
      map((currentValue): boolean => {
        return this.formValueHasChanged(currentValue, miscellaneousCoverageChangePath);
      }),
      startWith(false)
    );

    miscellaneousCoverageHasChanged$.subscribe((miscellaneousCoverageHasChanged) => {
      this.nonAutomatedFieldsHaveChanged = miscellaneousCoverageHasChanged;
    });
  }

  endorsementOptInValue() {
    const endorsementOptInFormGroup = this.get(
      'endorsementRequests.typeOfEndorsementOptIn'
    ) as UntypedFormGroup;
    return endorsementOptInFormGroup.value;
  }

  patchFormControls(bopQuote: BaseQuote) {
    const additionalInsuredsFormArray = getFormArray(
      this.form,
      'additionalInsureds.additionalInsuredBusinesses'
    );
    const waiversOfSubrogationFormArray = getFormArray(
      this.form,
      'waiverOfSubrogation.waiversOfSubrogation'
    );
    const waiversOptIn = getControl(this.form, 'waiverOfSubrogation.waiversOptIn');

    const { account, attachments, policy, locations, productUwCompany } = bopQuote;

    // TODO: this check will be revisited as part of BOP-2454
    const bopVersion: BopVersion = productUwCompany === BOP_V2_UW_COMPANY ? 2 : 1;
    const bopVersionControl = getControl(this.form, 'policyInfo.bopVersion');
    patchControl(bopVersionControl, bopVersion);

    if (attachments) {
      additionalInsuredsFormArray.enable();
      additionalInsuredsFormArray.reset();

      const { additionalInsuredBusinesses } = attachments;

      additionalInsuredBusinesses.forEach((additionalInsuredBusiness) => {
        this.addAdditionalInsuredBusiness(additionalInsuredsFormArray);
      });
      additionalInsuredsFormArray.patchValue(additionalInsuredBusinesses);
      additionalInsuredsFormArray.disable();

      getControl(this.form, 'additionalInsureds.aiCount').patchValue(
        additionalInsuredBusinesses.length
      );
    }

    if (policy) {
      const endorsementGroup = <UntypedFormGroup>this.form.get('endorsementRequests');
      const effectiveDateControl = <UntypedFormControl>(
        this.form.get('endorsementRequests.effectiveDate')
      );

      const todayInUsersTimezone = moment();
      const parsedEffectiveDateMoment = moment.utc(policy.effectiveDate);
      if (parsedEffectiveDateMoment > todayInUsersTimezone) {
        effectiveDateControl.patchValue(parsedEffectiveDateMoment.format(US_DATE_MASK));

        effectiveDateControl.setValidators([
          Validators.required,
          minDateExceededValidator(parsedEffectiveDateMoment.startOf('day'), 'day'),
        ]);
        endorsementGroup.setValidators([]);
      }

      // The following if statement is a feature flag for backdating endorsements.
      const earliestAllowedBackdate = todayInUsersTimezone
        .clone()
        .subtract(MAX_BACKDATE_AGE_IN_DAYS, 'days');

      if (earliestAllowedBackdate > parsedEffectiveDateMoment) {
        effectiveDateControl.setValidators([Validators.required]);
        endorsementGroup.setValidators([
          disableBackdatingForDisallowedTypesValidator(earliestAllowedBackdate.startOf('day')),
        ]);
      } else {
        effectiveDateControl.setValidators([
          Validators.required,
          minDateExceededValidator(parsedEffectiveDateMoment.startOf('day'), 'day'),
        ]);
        endorsementGroup.setValidators([]);
      }

      const { coverages } = policy;
      const waiverOfSubrogationCoverage = coverages.find(
        (coverage) => coverage.name === 'WaiverOfSubrogation'
      );
      waiversOptIn.setValue(!!waiverOfSubrogationCoverage);

      // Enable FormGroups/FormArrays before patching
      waiversOfSubrogationFormArray.reset();
      waiversOfSubrogationFormArray.enable();

      const subrogationWaiverNames: SubgrogationWaiverName[] = get(
        waiverOfSubrogationCoverage,
        'value.waiversOfSubrogation',
        []
      );

      subrogationWaiverNames.forEach((waiver) => {
        this.addWaiverOfSubrogation(waiversOfSubrogationFormArray);
      });

      waiversOfSubrogationFormArray.patchValue(subrogationWaiverNames);
      waiversOfSubrogationFormArray.disable();
    }

    if (account) {
      const accountForm = getFormGroup(this.form, 'account');
      patchControl(accountForm, account);

      const organizationTypeControl = getControl(this.form, 'policyInfo.organizationType');
      patchControl(organizationTypeControl, account.organizationType);
    }

    if (locations) {
      const buildingLocationsToPatch: CoveredBuilding[] = [];
      const locationAddressesToPatch: Address[] = [];

      locations.forEach((location, locationIdx) => {
        const address = location.address;
        address.addressLine2 = address.addressLine2 || '';

        // We identify a control by its original location number so we can later check
        // for any changes a user has made. (Checking index is not sufficient since
        // removing a location will change subsequent indices.)
        const originalLocationNumber = locationIdx + 1;
        const locationId = location.guidewireId || '';

        const locationAddressPatch = {
          ...address,
          guidewireId: locationId,
          originalLocationNumber,
        };

        locationAddressesToPatch.push(locationAddressPatch);

        location.buildings.forEach((building, index) => {
          const buildingLimit = `$${building.exposure.buildingLimit.toLocaleString('en-US')}`;
          const limitForBusinessPersonalProperty = `$${building.exposure.limitForBusinessPersonalProperty.toLocaleString(
            'en-US'
          )}`;
          const buildingId = get(building, 'guidewireId', '');
          const lessorsRisk = building.exposure.lessorsRisk;

          buildingLocationsToPatch.push({
            buildingIndex: index + 1,
            guidewireId: buildingId,
            address,
            lessorsRisk,
            limitForBusinessPersonalProperty,
            bppLimitChangeReason: '',
            bppLimitChangeFiles: [],
            buildingLimit,
            buildingLimitChangeReason: '',
            buildingLimitChangeFiles: [],
          });

          const bppLimitValidator = (
            bppLimitControl: UntypedFormControl
          ): ValidationErrors | null => {
            const bppLimit = bppLimitControl.value;
            const businessType = building.exposure.businessType as BuildingClassificationCategory;

            return checkForMinimumBppLimit(bppLimit, businessType, lessorsRisk);
          };

          // Add a new location coverages form group for each building location before patching.
          this.addBuildingLocationCoverageFormGroup({ bppLimitValidator });
        });
        // Add a new policy location form group for each location before patching.
        this.addPolicyLocation();
      });
      const locationAddressesFormArray = this.locationAddressesFormArray();
      const coveredBuildingsFormArray = this.coveredBuildingsFormArray();

      patchControl(locationAddressesFormArray, locationAddressesToPatch);
      patchControl(coveredBuildingsFormArray, buildingLocationsToPatch);

      // The building controls do not exist until after the formControls are patched, we set value
      // listeners now, rather than including them in the `setupFormValueListeners` method.
      this.setupCoverageValueListeners();
      this.setupLocationValueListeners();
    }

    // Ensure all opt-in form groups are disabled. Do not emit an event,
    // so as to not trigger any of the valueChanges listeners.
    getFormGroup(this.form, 'account').disable({ emitEvent: false });
    getFormGroup(this.form, 'additionalInsureds').disable({ emitEvent: false });
    getFormGroup(this.form, 'waiverOfSubrogation').disable({ emitEvent: false });
    getFormGroup(this.form, 'coverages').disable({ emitEvent: false });
    getFormGroup(this.form, 'policyInfo').disable({ emitEvent: false });
    getFormGroup(this.form, 'locations').disable({ emitEvent: false });
    getFormGroup(this.form, 'namedInsureds').disable({ emitEvent: false });
    getFormGroup(this.form, 'otherEndorsements').disable({ emitEvent: false });

    // Emit the "original" state of the policy
    // This is used for diffing / validating that a change has occured
    this.originalFormValue = this.form.getRawValue();
  }

  setupLocationValueListeners() {
    const locationAddresses = this.locationAddressesFormArray();
    const changeInOwnershipStructureControl = getControl(
      this.form,
      'policyInfo.changeInOwnershipStructure'
    );
    const reasonForChangeControl = getControl(this.form, 'policyInfo.reasonForChange');

    const addresses$ = locationAddresses.controls.map((control) => control.valueChanges);
    const addressObservables = addresses$.map((address, idx) =>
      merge(address, observableOf(locationAddresses.controls[idx].value))
    );

    const addressControlUpdates$ = combineLatest(addressObservables);
    const locationAddressUpdates$ = merge(
      locationAddresses.valueChanges,
      observableOf(locationAddresses.value)
    );

    combineLatest([addressControlUpdates$, locationAddressUpdates$]).subscribe((values) => {
      const newLocationWasAdded = this.newLocationAddresses().length > 0;
      const existingLocationWasRemoved = this.removedLocations().length > 0;
      let addressUpdated = false;
      const addresses = values[0];

      addresses.forEach((address, idx) => {
        if (this.originalFormValue) {
          const originalAddress = get(
            this.originalFormValue,
            `policyInfo.locationAddresses[${idx}]`
          );
          if (originalAddress && !addressesEqual(originalAddress, address)) {
            addressUpdated = true;
          }
        }
      });

      const addressesChanged = addressUpdated || newLocationWasAdded || existingLocationWasRemoved;
      enableDisableControl(changeInOwnershipStructureControl, addressesChanged);
      enableDisableControl(reasonForChangeControl, addressesChanged);
    });
  }

  updateWaiverOfSubrogationStep(retrievedBopQuote: BaseQuote) {
    // hide WoS field if contractor enhancement is true and bop version is 2
    // contractor enhancement is added in MPC during quoting when:
    // - business type is contractor, and
    // - lessorsRisk is false for first location
    // We want to hide the field because in BOP+, WoS is included by default when
    // the contractor enhancement is present, so the broker shouldn't be able to add/remove it

    // TODO: this check will be revisited as part of BOP-2454
    if (retrievedBopQuote.productUwCompany !== BOP_V2_UW_COMPANY) {
      return;
    }
    const locations = retrievedBopQuote.locations;
    if (locations[0].buildings[0].exposure.lessorsRisk) {
      return;
    }
    const isContractorEnhancement = locations.some((location) => {
      return location.buildings.some((building) => {
        return building.exposure.businessType === 'Contractor';
      });
    });
    if (isContractorEnhancement) {
      const waiversStep = getControl(
        this.form,
        'endorsementRequests.typeOfEndorsementOptIn.waiverOfSubrogation'
      );
      if (waiversStep) {
        waiversStep.disable();
      }
    }
  }

  // Updates v1 vs v2 Waivers of Subrogation controls
  updateWaiverOfSubrogationControls() {
    const waiversOfSubrogationFormArray = getFormArray(
      this.form,
      'waiverOfSubrogation.waiversOfSubrogation'
    );
    const waiversOptIn = getControl(this.form, 'waiverOfSubrogation.waiversOptIn');
    if (this.bopVersion() === 2) {
      enableDisableControl(waiversOptIn, true);
      enableDisableControl(waiversOfSubrogationFormArray, false);
    } else {
      enableDisableControl(waiversOptIn, false);
      enableDisableControl(waiversOfSubrogationFormArray, true);
    }
  }

  // Coverages
  coveredBuildingsFormArray() {
    return getFormArray(this.form, 'coverages.exposures.coveredBuildings');
  }

  // Coverage/BPP Limit
  originalBppLimitValue(buildingIndex: number) {
    const originalValue = get(
      this.originalFormValue,
      `coverages.exposures.coveredBuildings[${buildingIndex}].limitForBusinessPersonalProperty`
    );
    return parseMaskedInt(originalValue);
  }

  currentBppLimitValue(buildingIndex: number) {
    const buildingFormGroup = this.coveredBuildingsFormArray().at(
      buildingIndex
    ) as UntypedFormGroup;
    if (!buildingFormGroup) {
      return null;
    }
    const bppLimitControl = getControl(buildingFormGroup, 'limitForBusinessPersonalProperty');

    return parseMaskedInt(bppLimitControl.value);
  }

  bppLimitHasChanged(index: number): boolean {
    const originalBppLimit = this.originalBppLimitValue(index);
    const currentBppLimit = this.currentBppLimitValue(index);
    if (currentBppLimit === null) {
      return false;
    }
    return originalBppLimit !== currentBppLimit;
  }

  bppLimitChangeIsLarge(buildingIndex: number): boolean {
    const originalLimit = this.originalBppLimitValue(buildingIndex);
    const currentLimit = this.currentBppLimitValue(buildingIndex);
    if (currentLimit === null) {
      return false;
    }
    const absoluteDiff = Math.abs(originalLimit - currentLimit);
    if (!absoluteDiff) {
      return false;
    }
    // An increase from $0 always requires review.
    if (originalLimit === 0) {
      return true;
    }

    const relativeDiff = absoluteDiff / originalLimit;

    if (currentLimit > originalLimit) {
      return (
        absoluteDiff >= BPP_LIMIT_CHANGE_THRESHOLDS.INCREASE ||
        relativeDiff >= BPP_LIMIT_REL_CHANGE_THRESHOLDS.INCREASE
      );
    } else if (currentLimit < originalLimit) {
      return (
        absoluteDiff >= BPP_LIMIT_CHANGE_THRESHOLDS.DECREASE ||
        relativeDiff >= BPP_LIMIT_REL_CHANGE_THRESHOLDS.DECREASE
      );
    } else {
      return false;
    }
  }

  bppLimitRequiresReview() {
    const coveredBuildings = this.coveredBuildingsFormArray();
    if (!coveredBuildings) {
      return false;
    }
    return range(coveredBuildings.length).some((buildingIndex) => {
      return this.bppLimitChangeIsLarge(buildingIndex);
    });
  }
  // End Coverages/BPP Limit

  // Coverages/Building Limit
  originalBuildingLimitValue(buildingIndex: number) {
    const originalValue = get(
      this.originalFormValue,
      `coverages.exposures.coveredBuildings[${buildingIndex}].buildingLimit`
    );
    return parseMaskedInt(originalValue);
  }

  currentBuildingLimitValue(buildingIndex: number) {
    const buildingFormGroup = this.coveredBuildingsFormArray().at(
      buildingIndex
    ) as UntypedFormGroup;
    if (!buildingFormGroup) {
      return null;
    }
    const buildingLimitControl = getControl(buildingFormGroup, 'buildingLimit');

    return parseMaskedInt(buildingLimitControl.value);
  }

  buildingLimitHasChanged(index: number): boolean {
    const originalBuildingLimit = this.originalBuildingLimitValue(index);
    const currentBuildingLimit = this.currentBuildingLimitValue(index);
    if (currentBuildingLimit === null) {
      return false;
    }
    return originalBuildingLimit !== currentBuildingLimit;
  }

  buildingLimitChangeIsLarge(buildingIndex: number): boolean {
    const originalLimit = this.originalBuildingLimitValue(buildingIndex);
    const currentLimit = this.currentBuildingLimitValue(buildingIndex);
    if (currentLimit === null) {
      return false;
    }
    const absoluteDiff = Math.abs(originalLimit - currentLimit);
    if (!absoluteDiff) {
      return false;
    }
    // An increase from $0 always requires review.
    if (originalLimit === 0) {
      return true;
    }

    const relativeDiff = absoluteDiff / originalLimit;
    if (currentLimit > originalLimit) {
      return relativeDiff >= BUILDING_LIMIT_REL_CHANGE_THRESHOLDS.INCREASE;
    } else if (currentLimit < originalLimit) {
      const buildingFormGroup = this.coveredBuildingsFormArray().at(
        buildingIndex
      ) as UntypedFormGroup;
      const isLessorsRisk = getControl(buildingFormGroup, 'lessorsRisk').value;
      // For lessor's risk, any decrease should be flagged for review
      return isLessorsRisk || relativeDiff >= BUILDING_LIMIT_REL_CHANGE_THRESHOLDS.DECREASE;
    } else {
      return false;
    }
  }

  buildingLimitRequiresReview() {
    const coveredBuildings = this.coveredBuildingsFormArray();
    if (!coveredBuildings) {
      return false;
    }
    return range(coveredBuildings.length).some((buildingIndex) => {
      return this.buildingLimitChangeIsLarge(buildingIndex);
    });
  }
  // End Coverages/Building Limit

  coverageChangesRequireReview() {
    return (
      this.nonAutomatedFieldsHaveChanged ||
      this.bppLimitRequiresReview() ||
      this.buildingLimitRequiresReview()
    );
  }
  // End Coverages

  getZendeskTags() {
    const zendeskTags = [];

    const hasBopPlusPolicy = getControl(this.form, 'review.hasBopPlusPolicy').value;
    const hasBopPlusQuote = getControl(this.form, 'review.hasBopPlusQuote').value;
    if (hasBopPlusPolicy || hasBopPlusQuote) {
      zendeskTags.push('escalation');
    }

    if (this.bppLimitRequiresReview()) {
      zendeskTags.push('bpp_limit');
    }
    if (this.buildingLimitRequiresReview()) {
      zendeskTags.push('building_limit');
    }

    const today = moment.utc();
    const effectiveDateControl = getControl(this.form, 'endorsementRequests.effectiveDate');
    const effectiveDate = moment.utc(effectiveDateControl.value);
    if (today.isSameOrBefore(effectiveDate, 'day')) {
      zendeskTags.push('current_future_date');
    } else if (today.diff(effectiveDate, 'day') < 30) {
      zendeskTags.push('backdated_lessthan_30');
    } else {
      zendeskTags.push('backdated_morethan_30');
    }

    return zendeskTags;
  }

  // NKLL
  nkllValidationWrapper = (control: AbstractControl): ValidatorFn => {
    return () => this.nkllValidation(control);
  };

  nkllValidation = (control: AbstractControl): ValidationErrors | null => {
    if (!this.form || control.disabled) {
      return null;
    }

    const endorsementOptInFormGroup = getFormGroup(
      this.form,
      'endorsementRequests.typeOfEndorsementOptIn'
    );
    const additionalInsuredsFormArray = getFormArray(
      this.form,
      'additionalInsureds.additionalInsuredBusinesses'
    );

    const ENDORSEMENT_TYPES_ALWAYS_REQUIRING_NKLL = ['otherEndorsements', 'coverages', 'locations'];
    const ENDORSEMENT_TYPES_SOMETIMES_REQUIRING_NKLL = ['additionalInsureds'];

    const AI_TYPES_REQUIRING_NKLL = [
      'CO_OWNER',
      'CONTROLLING_INTEREST',
      'DESIGNATED_PERSON',
      'ENGINEERS',
      'ENGINEERS_NOT_ENGAGED',
      'OWNERS',
      'STATE_SUBDIVISIONS',
      'STATE_SUBDIVISIONS_AUTHORIZATIONS',
      'VENDORS',
      'LESSOR_OF_EQUIPMENT',
      'MANAGER_OF_PREMISES',
      'OWNERS_COMPLETED_OPS',
      'OWNERS_SCHEDULED_PERSON',
      'LOSS_OF_RENTAL_VALUE',
    ];

    const isNKLLRequired = Object.entries(endorsementOptInFormGroup.value).some(
      ([endorsmentType, enabled]) => {
        if (!enabled) {
          return false;
        }

        if (ENDORSEMENT_TYPES_ALWAYS_REQUIRING_NKLL.includes(endorsmentType)) {
          return true;
        } else if (ENDORSEMENT_TYPES_SOMETIMES_REQUIRING_NKLL.includes(endorsmentType)) {
          return additionalInsuredsFormArray.controls.some((aiFormGroup: UntypedFormGroup) => {
            // NKLL is required for certain AI types - we do not care about AIs that already exist on the policy unless the user changes them.
            if (!aiFormGroup.dirty) {
              return false;
            }

            return AI_TYPES_REQUIRING_NKLL.includes(aiFormGroup.value.type);
          });
        } else {
          return false;
        }
      }
    );

    if (!isNKLLRequired) {
      return null;
    }

    if (control && control.value.length > 0) {
      const hasFile = (control.value as FileUpload[]).some((file) => {
        return file.status === 'done';
      });

      if (hasFile) {
        return null;
      }
    }

    return {
      noFileUploaded: {
        validationMessage:
          'Your endorsement type requires a No Known Loss Letter. Please upload one to continue.',
      },
    };
  };
  // End of NKLL

  // Location endorsement helpers

  modifiedExistingLocations() {
    const existingLocationAddresses = this.existingLocationAddresses();
    const originalPolicyLocationAddresses: Address[] = get(
      this.originalFormValue,
      'policyInfo.locationAddresses',
      []
    );

    return existingLocationAddresses.filter((currentAddress) => {
      const originalLocationIndex = currentAddress.originalLocationNumber - 1;
      const originalAddress = originalPolicyLocationAddresses[originalLocationIndex];
      if (!currentAddress || !originalAddress) {
        return false;
      }
      return !addressesEqual(originalAddress, currentAddress);
    });
  }

  existingLocationAddresses(): ExistingLocationAddress[] {
    const locationAddresses: any[] = this.locationAddressesFormArray().value;
    return locationAddresses.filter((locationAddress) => {
      return Object.prototype.hasOwnProperty.call(locationAddress, 'originalLocationNumber');
    });
  }

  originalPolicyInfoHasChanged(): boolean {
    const organizationTypeControl = getControl(this.form, 'policyInfo.organizationType');
    const organizationTypeHasChanged =
      this.originalFormValue &&
      this.formValueHasChanged(organizationTypeControl.value, 'policyInfo.organizationType');
    const existingLocationsHaveChanged =
      this.modifiedExistingLocations().length > 0 || this.removedLocations().length > 0;

    return organizationTypeHasChanged || existingLocationsHaveChanged;
  }

  newLocationAddresses(): Address[] {
    const locationAddresses = this.locationAddressesFormArray().value;
    return locationAddresses.filter((locationAddress: Address) => {
      return !Object.prototype.hasOwnProperty.call(locationAddress, 'originalLocationNumber');
    });
  }

  locationAddressesFormArray(): UntypedFormArray {
    return this.getLocationAddresses();
  }

  locationsFormArray(): UntypedFormArray {
    return getFormArray(this.form, 'locations');
  }

  removedLocations() {
    return getFormArray(this.form, 'policyInfo.removedLocationAddresses');
  }

  removeLocation(index: number) {
    const existingLocationCount = this.existingLocationAddresses().length;
    const locationsFormArray = this.locationsFormArray();
    const policyLocationsFormArray = this.locationAddressesFormArray();
    if (index >= existingLocationCount) {
      // If the removed location *is not* one of the original policy locations,
      // remove the corresponding Location form group.
      const locationFormIndex = index - existingLocationCount;
      locationsFormArray.removeAt(locationFormIndex);

      if (locationsFormArray.length === 0) {
        locationsFormArray.disable();
      }
    } else {
      // If the removed location *is* one of the original policy locations,
      // add its original value to the removedLocationAddresses form array.
      const originalLocationNumber = get(
        policyLocationsFormArray.at(index),
        'value.originalLocationNumber'
      );
      const originalLocationValue = get(
        this.originalFormValue,
        `policyInfo.locationAddresses[${originalLocationNumber - 1}]`
      );
      this.addRemovedPolicyLocation(originalLocationValue);
    }

    // Remove control from the Policy Info page
    policyLocationsFormArray.removeAt(index);

    this.syncAllSteps();
    if (locationsFormArray.length === 0) {
      this.refreshCurrentStep();
    }
  }

  addPolicyLocation() {
    const policyLocation = this.createAddressLocation(true);

    policyLocation.addControl('originalLocationNumber', new UntypedFormControl(null));
    policyLocation.addControl('guidewireId', new UntypedFormControl(null));
    policyLocation.addControl('prefillDeclineReasons', new UntypedFormControl([]));

    this.locationAddressesFormArray().push(policyLocation);
  }

  addRemovedPolicyLocation(rawAddressValue: ExistingLocationAddress) {
    // Remove properties that are irrelevant for removed locations.
    const addressValue = omit(rawAddressValue, 'prefillDeclineReasons');
    const removedLocation = this.formBuilder.control({ ...addressValue });
    this.removedLocations().push(removedLocation);
  }

  addLocation() {
    const locationsFormArray = this.locationsFormArray();
    enableDisableControl(locationsFormArray, true);
    this.addLocationForm(true);
    this.addPrefillDeclineControl();
    this.syncAllSteps();

    if (locationsFormArray.length === 1) {
      this.refreshCurrentStep();
    }
  }

  addPrefillDeclineControl() {
    const prefillValidator = (
      prefillDeclineReasonsControl: UntypedFormControl
    ): ValidationErrors | null => {
      if (
        prefillDeclineReasonsControl?.value?.length === 0 ||
        prefillDeclineReasonsControl.disabled
      ) {
        return null;
      }

      return {
        isPrefillDeclined: {
          validationMessage: 'Please remove any ineligible locations before proceeding.',
        },
      };
    };

    const locationAddresses = this.locationAddressesFormArray();
    const newAddressFormGroup = locationAddresses.at(
      locationAddresses.length - 1
    ) as UntypedFormGroup;

    newAddressFormGroup.addControl(
      'prefillDeclineReasons',
      new UntypedFormControl([], prefillValidator)
    );
  }
  // End of Location endorsement helpers

  bopVersion(): BopVersion {
    const bopVersionControl: UntypedFormControl | null = getControl(
      this.form,
      'policyInfo.bopVersion'
    );
    return bopVersionControl ? bopVersionControl.value : 1;
  }
}
