import * as Fuse from 'fuse.js';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap, of as observableOf, map, catchError, switchMap } from 'rxjs';

import { ATTUNE_WC_CLASSCODES_API } from 'app/workers-comp/attune/constants';
import { SentryService } from 'app/core/services/sentry.service';
import { FeatureFlagService, JSON_FLAG_NAMES } from 'app/core/services/feature-flag.service';
import { AttuneWcClassCode } from 'app/workers-comp/attune/typings';

const FUSE_SEARCH_OPTIONS: Fuse.FuseOptions<AttuneWcClassCode> = {
  distance: 1000,
  keys: ['description', 'classCode'],
  location: 0,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  shouldSort: true,
  threshold: 0.45,
};

export interface SuccessfulClassCodeFetch {
  classCodes: AttuneWcClassCode[];
  fuse: Fuse<unknown, any>;
}

export interface ErrorClassCodeFetch {
  error: true;
}

export type ClassCodeResult = SuccessfulClassCodeFetch | ErrorClassCodeFetch;
export interface ClassCodesByState {
  [state: string]: ClassCodeResult;
}

@Injectable()
export class AttuneWcClassCodesService {
  classCodesByState: ClassCodesByState = {};

  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private featureFlagService: FeatureFlagService
  ) {}

  getHazardGroup(classCode: string, description: string, state: string): string | null {
    const cachedClassCodes = this.classCodesByState[state];
    if (!cachedClassCodes || this.isFetchError(cachedClassCodes)) {
      return null;
    }

    const relevantClassCode = cachedClassCodes.classCodes.find((classification) => {
      return classification.classCode === classCode && classification.description === description;
    });
    if (relevantClassCode?.hazardGroup) {
      return relevantClassCode.hazardGroup;
    }
    return null;
  }

  getClassCodes(state: string): Observable<ClassCodeResult> {
    const attuneEnabledStates$ = this.featureFlagService.getJsonFlagValue<string[]>(
      JSON_FLAG_NAMES.ATTUNE_WC_ENABLED_STATES
    );

    return attuneEnabledStates$.pipe(
      switchMap((flagValue) => {
        if (!flagValue) {
          return this.classCodesErrorResponse();
        }
        // If we have not enabled the product in this state, return an empty list of classcodes. A warning will be displayed to the user.
        // We do this because the GW api might enable classcodes for certain states before we are ready to display them in the portal.
        if (!flagValue?.includes(state)) {
          return this.classCodesUnavailableResponse();
        }

        const cachedData = this.classCodesByState[state];

        if (cachedData && !this.isFetchError(cachedData)) {
          return observableOf(cachedData);
        }

        return this.fetchClassCodes(state).pipe(
          tap((fetchedData) => (this.classCodesByState[state] = fetchedData))
        );
      })
    );
  }

  private fetchClassCodes(state: string): Observable<ClassCodeResult> {
    return this.http
      .get<{
        classCodes: AttuneWcClassCode[];
      }>(ATTUNE_WC_CLASSCODES_API, {
        params: {
          jurisdiction: state,
        },
      })
      .pipe(
        map((response) => {
          const classCodes = response.classCodes;
          const fuse = new Fuse(classCodes, FUSE_SEARCH_OPTIONS);
          return { classCodes, fuse };
        }),
        catchError((resp: any): Observable<ErrorClassCodeFetch> => {
          this.sentryService.notify('Failed to fetch Attune WC Class Codes via GWPC', {
            severity: 'error',
            metaData: {
              state: state,
              responseStatusCode: resp ? resp.status : null,
              responseError: resp ? resp.error : null,
              underlyingErrorMessage: resp.error && resp.error.message,
            },
          });
          return this.classCodesErrorResponse();
        })
      );
  }

  private isFetchError(
    data: SuccessfulClassCodeFetch | ErrorClassCodeFetch
  ): data is ErrorClassCodeFetch {
    return 'error' in data;
  }

  private classCodesUnavailableResponse(): Observable<SuccessfulClassCodeFetch> {
    return observableOf({
      classCodes: [],
      fuse: new Fuse([], FUSE_SEARCH_OPTIONS),
    });
  }

  private classCodesErrorResponse(): Observable<ErrorClassCodeFetch> {
    return observableOf({
      error: true,
    });
  }
}
