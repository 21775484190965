import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import { CoalitionEffectiveDateUpdatedResponse } from 'app/features/digital-carrier/models/types';
import { API_V4_BASE } from 'app/constants';
import { catchError } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { CyberProduct } from '../models/cyber-typings.model';

@Injectable({
  providedIn: 'root',
})
export class CoalitionCyberUpdateEffectiveDateService {
  constructor(
    private http: HttpClient,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService
  ) {}

  updateEffectiveDate(quoteId: string, effectiveDate: string, product: CyberProduct) {
    const effectiveDateUpdateRequest = {
      effectiveDate,
    };
    return this.http
      .post<CoalitionEffectiveDateUpdatedResponse>(
        this.updateEffectiveDateRoute(product, quoteId),
        effectiveDateUpdateRequest,
        {
          headers: { 'Content-type': 'application/json' },
        }
      )
      .pipe(
        catchError((error) => {
          this.amplitudeService.track({
            eventName: 'update_cyber_bind_effective_date_update_error',
            detail: `coalition-${product}`,
          });
          this.sentryService.notify(
            `Coalition Cyber: Error updating cyber effective date during bind for ${product} quote`,
            {
              severity: 'warning',
              metaData: {
                product: product,
                quoteId,
                effectiveDate,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            }
          );
          return observableOf(error);
        })
      );
  }

  isSuccessResponse(
    updateEffectiveDateResponse: any
  ): updateEffectiveDateResponse is CoalitionEffectiveDateUpdatedResponse {
    return (
      updateEffectiveDateResponse &&
      Object.prototype.hasOwnProperty.call(updateEffectiveDateResponse, 'bindable')
    );
  }

  private updateEffectiveDateRoute(product: CyberProduct, quoteId: string) {
    return `${API_V4_BASE}/quotes/coalition/${product}/${quoteId}/effective-date`;
  }
}
