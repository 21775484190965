import { Injectable } from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';
import { HttpResponse } from '@angular/common/http';

const STORAGE_KEY = 'DEVICE_TOKEN';

@Injectable()
export class DeviceTokenService {
  constructor(private sentryService: SentryService) {}

  setDeviceToken(response: HttpResponse<any>) {
    const deviceToken = response.headers && response.headers.get('X-DEVICE-TOKEN');

    if (!deviceToken) {
      return;
    }
    this.set(deviceToken);
  }

  private set(deviceToken: string) {
    try {
      localStorage.setItem(STORAGE_KEY, deviceToken);
    } catch (error) {
      this.sentryService.notify('Unable to set device token in local storage.', {
        severity: 'error',
        metaData: {
          underlyingErrorMessage: error && error.message,
          underlyingError: error,
        },
      });
      throw error;
    }
  }

  getDeviceToken(): string | null {
    return this.get();
  }

  private get(): string | null {
    const deviceToken = localStorage.getItem(STORAGE_KEY);
    if (!deviceToken) {
      return null;
    }
    return deviceToken;
  }
}
