import { Injectable } from '@angular/core';
import { CurrentUser, USER_CONTEXT } from 'app/shared/models/current-user';
import {
  USER_TOKEN_EXPIRATION_IN_MINUTES,
  ADP_USER_TOKEN_EXPIRATION_IN_MINUTES,
} from 'app/constants';
import { SentryService } from 'app/core/services/sentry.service';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { User } from '../../shared/models/user';
import { Observable, of } from 'rxjs';

const TOKEN_EXPIRATION_MS = USER_TOKEN_EXPIRATION_IN_MINUTES * 60 * 1000;
const ADP_TOKEN_EXPIRATION_MS = ADP_USER_TOKEN_EXPIRATION_IN_MINUTES * 60 * 1000;
const STORAGE_KEY = 'CURRENT_USER';

@Injectable()
export class CurrentUserService {
  constructor(
    private sentryService: SentryService,
    private localStorageService: LocalStorageService
  ) {}

  setCurrentUser(incomingUser: CurrentUser, context = USER_CONTEXT.AGENT_PORTAL) {
    this.set(incomingUser, context);
  }

  getCurrentUser(includeExpired = false): CurrentUser | null {
    if (includeExpired) {
      return this.getIncludeExpired();
    }
    return this.get();
  }

  getCurrentUserFormatted(): Observable<User | null> {
    const currentUser = this.get();
    if (!currentUser) {
      return of(null);
    }

    try {
      // Note: this decoding algorithm assumes no unicode characters are present in the token contents.
      // If this changes, we will need to revisit this algorithm.
      const base64UrlEncodedToken = currentUser.token.split('.')[1];
      const base64Token = base64UrlEncodedToken.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = window.atob(base64Token);
      const parsedToken = JSON.parse(jsonPayload);

      return of(parsedToken?.user || null);
    } catch (err) {
      this.sentryService.notify('Could not parse user token in local storage.', {
        severity: 'error',
        metaData: {
          underlyingErrorMessage: err && err.message,
          underlyingError: err,
          username: currentUser.username,
        },
      });
      return of(null);
    }
  }

  isCurrentUserPresent(contextCheck: USER_CONTEXT): boolean {
    const user = this.getCurrentUser();
    return !!user && !!user.token && user.context === contextCheck;
  }

  destroyCurrentUser(): void {
    this.remove();
  }

  private set(user: CurrentUser, context = USER_CONTEXT.AGENT_PORTAL) {
    try {
      const expiration =
        context === USER_CONTEXT.ADP_FORM ? ADP_TOKEN_EXPIRATION_MS : TOKEN_EXPIRATION_MS;
      this.localStorageService.setJson(STORAGE_KEY, { user, expires: expiration + +new Date() });
    } catch (error) {
      this.sentryService.notify('Unable to set user token in local storage.', {
        severity: 'error',
        metaData: {
          underlyingErrorMessage: error && error.message,
          underlyingError: error,
          username: user.username,
        },
      });
      throw error;
    }
  }

  private remove() {
    this.localStorageService.remove(STORAGE_KEY);
  }

  private get(): CurrentUser | null {
    const data = this.localStorageService.getJson(STORAGE_KEY);
    if (!data || !data.user || !data.expires || data.expires < +new Date() || !data.user.token) {
      return null;
    }
    return <CurrentUser>data.user;
  }

  private getIncludeExpired(): CurrentUser | null {
    const data = this.localStorageService.getJson(STORAGE_KEY);
    if (!data || !data.user || !data.user.token) {
      return null;
    }
    return <CurrentUser>data.user;
  }
}
