<section class="form-animation">
  <h1>Policy details</h1>

  <app-form-field-text
    inputId="hab-policyDetails-effectiveDate"
    labelText="Effective Date"
    placeholder="MM/DD/YYYY"
    [form]="form"
    [submitted]="hasSubmitted()"
    nameOfFormControl="effectiveDate"
    appDateMask
  >
  </app-form-field-text>

  <app-form-field-select
    inputId="hab-policyDetails-organizationType"
    labelText="Organization type"
    [form]="form"
    [submitted]="hasSubmitted()"
    [availableOptions]="organizationTypes"
    nameOfFormControl="organizationType"
    labelText="Organization type"
  >
  </app-form-field-select>

  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-policyDetails-doesInsuredUsePropertyManagementFirm"
    questionText="Does the insured employ a property management firm?"
    nameOfFormControl="doesInsuredUsePropertyManagementFirm"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-radio>

  <h2>Schedule of locations</h2>
  <section
    class="js-hab-policy-locations"
    *ngFor="let location of locationSummaries().controls; index as locationIndex"
  >
    <div class="form-group multi-form-item">
      <app-form-field-address
        prefix="hab-policyDetails-location-{{ locationIndex }}"
        cssClass="form-field__no-margin"
        labelText="Location address"
        [form]="location.get('address')"
        [submitted]="hasSubmitted()"
        [useAddressLine2]="false"
        [displayMap]="true"
      >
      </app-form-field-address>
      <div class="validation" *ngIf="hasSubmitted() && getAddressErrors(locationIndex) !== ''">
        <div class="validation-message">
          {{ getAddressErrors(locationIndex) }}
        </div>
      </div>

      <app-form-field-text
        inputId="hab-policyDetails-location-{{ locationIndex }}-numberOfBuildings"
        labelText="Number of buildings at location"
        [form]="location"
        [submitted]="hasSubmitted()"
        nameOfFormControl="numBuildings"
        appNumberMask
      >
      </app-form-field-text>

      <div class="form-group skip">
        <p>
          Does this location have any of the following?
          <strong>Please check all that apply:</strong>
        </p>

        <div class="app-checkbox-field-group">
          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasCommercialOccupancies"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasCommercialOccupancies"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasCommercialOccupancies"
              >Commercial occupancies</label
            >
          </app-form-field-checkbox>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasPaidParking"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasPaidParking"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasPaidParking"
              >Paid parking</label
            >
          </app-form-field-checkbox>

          <app-form-field-text
            inputId="hab-policyDetails-location-{{ locationIndex }}-paidParkingSquareFootage"
            cssClass="form-field__checkbox-indent form-field__no-margin form-field__half-width"
            labelText="Square Footage"
            [form]="location.get('features')"
            [submitted]="hasSubmitted()"
            nameOfFormControl="paidParkingSquareFootage"
            appDelimitedNumberMask
          >
          </app-form-field-text>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasPool"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasPool"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasPool">Pool</label>
          </app-form-field-checkbox>

          <app-form-field-text
            inputId="hab-policyDetails-location-{{ locationIndex }}-numberOfPools"
            cssClass="form-field__checkbox-indent form-field__no-margin form-field__half-width"
            labelText="Number of Pools"
            [form]="location.get('features')"
            [submitted]="hasSubmitted()"
            nameOfFormControl="numberOfPools"
            appDelimitedNumberMask
          >
          </app-form-field-text>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasPondOrLake"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasPondOrLake"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasPondOrLake"
              >Pond / lake</label
            >
          </app-form-field-checkbox>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasDivingBoard"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasDivingBoard"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasDivingBoard"
              >Diving board</label
            >
          </app-form-field-checkbox>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasClubhouseOrPartyRoom"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasClubhouseOrPartyRoom"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasClubhouseOrPartyRoom"
              >Clubhouse / party room</label
            >
          </app-form-field-checkbox>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasPlayground"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasPlayground"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasPlayground"
              >Playground</label
            >
          </app-form-field-checkbox>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasGymOrExerciseRoom"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasGymOrExerciseRoom"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasGymOrExerciseRoom"
              >Gym / exercise room</label
            >
          </app-form-field-checkbox>

          <app-form-field-checkbox
            inputId="hab-policyDetails-location-{{ locationIndex }}-hasSportsFacilitiesOrCourts"
            cssClass="form-field__no-margin"
            nameOfFormControl="hasSportsFacilitiesOrCourts"
            [submitted]="hasSubmitted()"
            [form]="location.get('features')"
          >
            <label for="hab-policyDetails-location-{{ locationIndex }}-hasSportsFacilitiesOrCourts"
              >Sports facilities / courts</label
            >
          </app-form-field-checkbox>
        </div>
      </div>
      <div class="nav-button-group">
        <a
          class="button button__small button__transparent"
          (click)="removeLocation(locationIndex)"
          (keyup.enter)="removeLocation(locationIndex)"
          *ngIf="moreThanOneLocation()"
        >
          Remove
        </a>
      </div>
    </div>
  </section>

  <button
    class="button button__secondary button__full-width js-hab-add-location button__no-margin"
    type="button"
    (click)="addLocation()"
  >
    Add additional location
  </button>

  <hr />

  <!-- hiding blankets until the functionality is available in the backend
  <h2>Blankets</h2>
  <app-checkbox
    inputId="hab-policyDetails-applyBuildingCoverageBlanket"
    labelText="Apply blanket Building/Contents coverage"
    [cssClass]="indentedFieldMarginCss('buildingCoverageBlanket')"
    nameOfFormControl="applyBuildingCoverageBlanket"
    [submitted]="hasSubmitted()"
    [form]="form">
  </app-checkbox>

  <app-radio-question
    cssClass="form-field__indent form-field__no-margin"
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-policyDetails-buildingCoverageBlanketDeductible"
    questionText="Deductible"
    nameOfFormControl="buildingCoverageDeductible"
    [options]="habBuildingCoverageBlanketDeductibleOptions"
    [submitted]="hasSubmitted()"
    [form]="form.get('buildingCoverageBlanket')">
  </app-radio-question>

  <app-radio-question
    cssClass="form-field__indent form-field__indent-sibling"
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-policyDetails-buildingCoverageBlanketCoinsurance"
    questionText="Coinsurance"
    nameOfFormControl="buildingCoverageCoinsurance"
    [options]="habBuildingCoverageBlanketCoinsuranceOptions"
    [submitted]="hasSubmitted()"
    [form]="form.get('buildingCoverageBlanket')">
  </app-radio-question>

  <app-checkbox
    inputId="hab-policyDetails-applyBusinessIncomeBlanket"
    labelText="Apply blanket Business Income coverage"
    [cssClass]="indentedFieldMarginCss('businessIncomeBlanket')"
    nameOfFormControl="applyBusinessIncomeBlanket"
    [submitted]="hasSubmitted()"
    [form]="form">
  </app-checkbox>

  <app-radio-question
    cssClass="form-field__indent"
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-policyDetails-businessIncomeBlanketCoinsurance"
    questionText="Coinsurance"
    nameOfFormControl="coinsurancePercent"
    [options]="habBusinessIncomeBlanketCoinsuranceOptions"
    [submitted]="hasSubmitted()"
    [form]="form.get('businessIncomeBlanket')">
  </app-radio-question>
  -->
</section>
