import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of as observableOf, race, timer, interval, BehaviorSubject } from 'rxjs';
import { BILLING_PAGE_URL } from 'app/constants';
import { catchError, mapTo, filter, first, mergeMap, tap } from 'rxjs/operators';
import { SentryService } from 'app/core/services/sentry.service';

const INVOICE_CREATION_TIMEOUT = 3 * 60 * 1000;

@Injectable()
export class InvoicesListService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  public invoicesSubject: BehaviorSubject<BackendListInvoice[] | null> = new BehaviorSubject(null);

  // Note: Returns an observable that emits `false` if we timeout out waiting for an invoice, or `true` if an invoice is available
  waitUntilInvoiceIsAvailable(accountId: string, producerCode: string): Observable<boolean> {
    return race(
      timer(INVOICE_CREATION_TIMEOUT).pipe(mapTo(false)),
      this.pollForInvoices(accountId, producerCode)
    );
  }

  private pollForInvoices(accountId: string, producerCode: string) {
    return interval(2000).pipe(
      mergeMap(() => {
        return this.getInvoiceList(
          producerCode,
          undefined,
          undefined,
          undefined,
          accountId,
          undefined
        );
      }),
      filter((invoiceList: BackendInvoiceSummary) => {
        // only emit when invoice is available
        return invoiceList && invoiceList.invoices && invoiceList.invoices.length > 0;
      }),
      mapTo(true),
      first()
    );
  }

  getInvoiceList(
    producerCode: string,
    position?: number,
    size?: number,
    businessName?: string,
    accountNumber?: string | number,
    invoiceStatuses?: string[]
  ): Observable<BackendInvoiceSummary | HttpErrorResponse> {
    let params = { producer_code: producerCode };

    if (typeof position !== 'undefined' && typeof size !== 'undefined') {
      params = Object.assign(params, { position: position, size: size });
    }

    if (businessName) {
      params = Object.assign(params, { business_name: String(businessName) });
    }

    if (accountNumber) {
      params = Object.assign(params, { account_number: String(accountNumber) });
    }

    if (invoiceStatuses && invoiceStatuses.length) {
      params = Object.assign(params, {
        invoice_statuses: invoiceStatuses.map((status) => String(status)),
      });
    }

    return this.http.get<BackendInvoiceSummary>(BILLING_PAGE_URL, { params: params }).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to fetch invoice list.', {
          severity: 'error',
          metaData: {
            producerCode,
            params,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(error);
      }),
      tap((result: BackendInvoiceSummary) => {
        if (result) {
          this.invoicesSubject.next(result.invoices);
        }
      })
    );
  }
}
