import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SentryService } from '../../../core/services/sentry.service';
import { Observable } from 'rxjs';
import { retryWithBackoff } from '../../../shared/helpers/api-helpers';

import * as FileSaver from 'file-saver';

export type DOCUMENT_TYPES = 'pdf' | 'xls' | 'xlsx' | 'zip';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getDocument(
    url: string,
    fileName: string,
    documentType: DOCUMENT_TYPES,
    description?: string
  ): Observable<Blob> {
    return this.http
      .get(url, {
        headers: { Accept: `application/${documentType}` },
        responseType: 'blob',
      })
      .pipe(
        tap((data) => {
          if (data === null || data?.size === 0) {
            throw new Error('Document data empty');
          }
          FileSaver.saveAs(data, fileName);
        }),
        catchError((error) => {
          this.sentryService.notify(`Unable to get document ${description || fileName}`, {
            severity: 'error',
            metaData: {
              downloadLink: url,
              fileName,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  headDocument(url: string): Observable<HttpResponse<any>> {
    return this.http
      .head(url, {
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify(`Unable to get document headers`, {
            severity: 'error',
            metaData: {
              downloadLink: url,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  pollDocument(url: string, maxTries: number = Infinity): Observable<HttpResponse<any>> {
    return this.headDocument(url).pipe(
      tap((res) => {
        if (!(res && res.status === 200)) {
          throw new Error();
        }
      }),
      retryWithBackoff(maxTries, 2000)
    );
  }
}
