<div *ngFor="let question of uwQuestions">
  <app-form-field-radio
    inputId="{{ question.questionID }}"
    [form]="uwQuestionsFormGroup"
    nameOfFormControl="{{ question.questionID }}"
    questionText="{{ question.questionText }}"
  >
  </app-form-field-radio>
</div>

<div class="uw-question-warning" id="uw-question-error" *ngIf="showErrorMessage()">
  <small>This quote is not eligible for Excess Liability. Submitting will quote just BOP.</small>
</div>
