import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BillingToast, BillingToastLevel } from '../services/billing-toast.service';

@Component({
  selector: 'app-billing-toast',
  templateUrl: './billing-toast.component.html',
})
export class BillingToastComponent implements OnDestroy {
  @Input() toast: BillingToast;
  @Output() destroy = new EventEmitter<BillingToast>();
  private closeClicked = false;
  BillingToastLevel = BillingToastLevel;

  constructor() {}

  ngOnDestroy() {
    if (this.toast && this.toast.callback) {
      this.toast.callback(this.closeClicked);
    }
  }

  close() {
    this.closeClicked = true;
    this.destroy.emit(this.toast);
  }
}
