import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

import { FormDslSteppedFormComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form.component';
import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';
import { FORM_CONFIG, FORM_DATA } from 'app/shared/form-dsl-test/form-dsl-multi-step-test-data';

@Component({
  selector: 'app-form-dsl-multi-step-edit-test',
  templateUrl: './form-dsl-multi-step-test-form.component.html',
  providers: [FormDslSteppedFormService],
})
export class FormDslMultiStepEditTestFormComponent
  extends FormDslSteppedFormComponent
  implements OnInit
{
  constructor(protected route: ActivatedRoute, public formService: FormDslSteppedFormService) {
    super(formService);
  }

  ngOnInit() {
    const config: string = this.route.snapshot.params['config'] as string;
    const data: number = this.route.snapshot.queryParams['data'] as number;
    // cloneDeep is necessary for tests
    // which otherwise share state
    this.isEditing = true;
    // Note: we set these default values only to prove
    // that they are disregarded in edit workflows
    this.formDefaults = {
      'form-dsl-multi-step-text-field-1': 'default value 1',
      'form-dsl-multi-step-text-field-2': 'default value 2',
      'form-dsl-multi-step-text-field-3': 'default value 3',
    };
    super.ngOnInit();
    this.setFormConfig(_.cloneDeep(FORM_CONFIG[config]));
    this.setFormData(_.cloneDeep(FORM_DATA[data]));
  }
}
