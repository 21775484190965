import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResetPasswordService } from 'app/core/services/reset-password.service';

const WARNING_MSG_TYPE = 'warning-message';
const SUCCESS_MSG_TYPE = 'success-message';
const ERROR_MSG_TYPE = 'error-message';

@Component({
  selector: 'app-reset-password.app-page.app-page__login',
  templateUrl: './password-reset-redirect.component.html',
})
export class PasswordResetRedirectComponent implements OnDestroy, OnInit {
  loading = false;
  submitted = false;
  showSubmitButton = false;
  messageType = ERROR_MSG_TYPE;
  message = 'Request password link';
  messageSubtitle = 'This link is expired. You can request a new one below.';
  email: string;
  recoveryToken: string;
  private sub: Subscription = new Subscription();

  constructor(private resetPasswordService: ResetPasswordService) {}

  ngOnInit() {
    if (!history.state.data) {
      this.message = 'Whoops!';
      this.messageSubtitle = 'You cannot access this page without a proper redirect.';
    } else {
      this.showSubmitButton = true;
      const { email, recoveryToken, messageType, messageSubtitle } = history.state.data;
      this.email = email;
      this.recoveryToken = recoveryToken;
      this.messageType = messageType;
      this.messageSubtitle = messageSubtitle;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  handleSubmit(event: any) {
    this.submitted = true;
    this.loading = true;
    this.sub.add(
      this.resetPasswordService.forgotPassword(this.email).subscribe((response) => {
        this.showSubmitButton = false;
        this.loading = false;
        if (response) {
          this.messageType = SUCCESS_MSG_TYPE;
          this.message = 'Success!';
          this.messageSubtitle = 'A new password link was sent to your email.';
        } else {
          this.messageType = ERROR_MSG_TYPE;
          this.message = 'Sorry!';
          this.messageSubtitle =
            'We were not able to generate a new password link for you. Please contact customer support.';
        }
      })
    );

    event.preventDefault();
  }
}
