import { RedemptionType } from './rewards-types';

export const ADP_AGENT_PRODUCER_CODES = ['ADPDIRECT1'];

interface RewardDetails {
  rewardTitle: string;
  rewardDescription: string;
  rewardRedemptionType: RedemptionType;
  rewardIcon: string;
  rewardClass: string;
}

export const REWARD_TYPES: RewardDetails[] = [
  {
    rewardTitle: '$5 Starbucks gift card',
    rewardDescription: 'Redeemable by email',
    rewardRedemptionType: RedemptionType.GIFT_CARD_50,
    rewardIcon: 'assets/img/rewards-starbucks.png',
    rewardClass: 'js-button__rewards_50',
  },
  {
    rewardTitle: '$25 Amazon gift card',
    rewardDescription: 'Redeemable by email',
    rewardRedemptionType: RedemptionType.GIFT_CARD_250,
    rewardIcon: 'assets/img/rewards-amazon-card.png',
    rewardClass: 'js-button__rewards_250',
  },
];
