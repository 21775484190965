import { catchError, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { ALERT_STATUS_S3_URI } from 'app/constants';

import { SentryService } from 'app/core/services/sentry.service';
@Injectable()
export class AlertService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  public response: AlertResponse | null = null;
  public shouldDisplayAlert = false;
  public clickedClose = false;
  public isMaintenanceMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  previousMessage: string;
  public getMessage(): string {
    return this.response !== null ? this.response.message : '';
  }

  public fetchAlert(): Observable<AlertResponse | null> {
    const responseObs: Observable<AlertResponse | null> = this.http
      .get<AlertResponse | null>(ALERT_STATUS_S3_URI, {
        headers: new HttpHeaders({
          'Cache-Control': 'no-store',
        }),
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to fetch alert.', {
            severity: 'info',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );

    responseObs.pipe(first()).subscribe((response: AlertResponse | null) => {
      this.response = response;
      if (response) {
        if (response.maintenance && response.maintenance === 'enabled') {
          this.isMaintenanceMode.next(true);
        } else {
          this.isMaintenanceMode.next(false);
        }

        if (
          response.message &&
          response.message.includes('update') &&
          response.message.includes('refresh')
        ) {
          // If the user has already refreshed and has the versionCheck code, do not show them the banner again.
          return;
        }

        if (response.message !== this.previousMessage) {
          this.previousMessage = response.message;
          this.clickedClose = false;
          this.showAlert();
        } else if (this.clickedClose) {
          this.hideAlert();
        }
      } else {
        this.hideAlert();
      }
    });

    return responseObs;
  }

  public showAlert(): void {
    if (this.response) {
      this.shouldDisplayAlert = true;
      const bodyEl: Element | null = document.querySelector('body');
      if (bodyEl) {
        bodyEl.classList.add('body__alert-enabled');
      }
    }
  }

  public hideAlert(): void {
    this.shouldDisplayAlert = false;
    const bodyEl: Element | null = document.querySelector('body');
    if (bodyEl) {
      bodyEl.classList.remove('body__alert-enabled');
    }
  }

  public handleClickedClose(): void {
    this.clickedClose = true;
    this.hideAlert();
  }

  public shouldShowBanner() {
    return this.shouldDisplayAlert;
  }
}
