import {
  Component,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterContentChecked,
} from '@angular/core';

export type PANE_POSITION =
  | 'below-center'
  | 'below-left'
  | 'below-right'
  | 'left-top'
  | 'left-middle'
  | 'left-bottom'
  | 'right-top'
  | 'right-middle'
  | 'right-bottom';

const CARET_POINT_DEPTH = 6;
const CARET_WIDTH = 16;
const CARET_SIDE_OFFSET = 18;
const CARET_HEIGHT = 12;
const CARET_POINT_OFFSET = CARET_SIDE_OFFSET + CARET_HEIGHT / 2;
const CARET_BOTTOM_OFFSET = CARET_SIDE_OFFSET + CARET_HEIGHT;

type EDUCATION_CARET_CLASS =
  | 'education-caret-left'
  | 'education-caret-right'
  | 'education-caret-top';

@Component({
  selector: 'app-education-pane',
  templateUrl: './education-pane.component.html',
})
export class EducationPaneComponent implements AfterContentChecked, AfterViewInit, OnDestroy {
  @Input() position: PANE_POSITION = 'below-center';
  @Input() targetSelector = '';
  @Input() elementId = '';

  @Input() withinScrollContainer = false;

  @ViewChild('container')
  container: ElementRef;

  repositioned = false;

  leftAmount = '0px';
  topAmount = '0px';

  handler: EventListener;
  targets: Element[];

  ngAfterViewInit() {
    this.targets = [];
    if (this.withinScrollContainer) {
      let parent = this.container.nativeElement;
      while (parent && parent !== document.body) {
        const { overflow } = window.getComputedStyle(parent);
        if (
          overflow &&
          overflow.split(' ').every((token) => token === 'auto' || token === 'scroll')
        ) {
          this.targets.push(parent);
        }
        parent = parent.parentElement;
      }
      this.handler = this.setPosition.bind(this);
      this.targets.forEach((target) => {
        target.addEventListener('scroll', this.handler);
      });
    }
  }

  caretClass(): EDUCATION_CARET_CLASS {
    if (this.repositioned) {
      // If the tooltip was repositioned because it's out of the viewport, render the caret above
      return 'education-caret-top';
    }
    if (this.position.startsWith('right')) {
      return 'education-caret-left';
    } else if (this.position.startsWith('left')) {
      return 'education-caret-right';
    } else {
      return 'education-caret-top';
    }
  }

  alignmentClass() {
    if (
      (this.position.startsWith('left') || this.position.startsWith('right')) &&
      !this.repositioned
    ) {
      return 'education-pane-left';
    }
    return '';
  }

  ngAfterContentChecked() {
    this.setPosition();
  }

  ngOnDestroy() {
    if (this.targets) {
      this.targets.forEach((target) => target.removeEventListener('scroll', this.handler));
    }
  }

  setPosition() {
    let targetElement;

    // Do not try to reposition before the pane has been added to the DOM
    if (
      !this.container ||
      !this.container.nativeElement ||
      !this.container.nativeElement.offsetParent
    ) {
      return;
    }

    if (this.targetSelector && document.querySelector(this.targetSelector)) {
      targetElement = document.querySelector(this.targetSelector);
    } else {
      // Call .parentNode twice, since the first parent is the template element
      targetElement = this.container.nativeElement.parentNode.parentNode;
    }

    let verticalScrollOffset = 0;
    if (this.withinScrollContainer) {
      let nextParent = targetElement;
      while (nextParent) {
        if (nextParent === document.body) {
          nextParent = null;
        } else if (nextParent.scrollTop) {
          verticalScrollOffset += nextParent.scrollTop;
          nextParent = nextParent.parentNode;
        } else {
          nextParent = nextParent.parentNode;
        }
      }
    }

    const elementWidth = this.container.nativeElement.offsetWidth;
    const targetWidth = targetElement.offsetWidth;
    const targetHeight = targetElement.offsetHeight;
    const targetLeft = targetElement.offsetLeft;
    const targetTop = targetElement.offsetTop - verticalScrollOffset;
    // If the target element has a small height, move the tooltip upward so the caret is not below the target.
    const verticalAdjust =
      targetHeight <= CARET_BOTTOM_OFFSET ? CARET_POINT_OFFSET - targetHeight / 2 : 0;

    let position = this.position;
    this.repositioned = false;
    const targetBoundingRectangle = targetElement.getBoundingClientRect();

    // Change the pane's rendering position if it wouldn't fit on the screen
    if (position.startsWith('left') && targetBoundingRectangle.left - elementWidth < 0) {
      this.repositioned = true;
      position = 'below-center';
    } else if (
      position.startsWith('right') &&
      targetBoundingRectangle.left + targetWidth + elementWidth > window.innerWidth
    ) {
      this.repositioned = true;
      position = 'below-center';
    }

    switch (position) {
      case 'below-center':
        this.leftAmount = targetLeft + targetWidth / 2 - elementWidth / 2 + 'px';
        this.topAmount = targetTop + targetHeight + CARET_POINT_DEPTH + 'px';
        break;
      case 'below-left':
        this.leftAmount = targetLeft - elementWidth / 2 + CARET_WIDTH / 2 + 'px';
        this.topAmount = targetTop + targetHeight + CARET_POINT_DEPTH + 'px';
        break;
      case 'below-right':
        this.leftAmount = targetLeft + targetWidth - elementWidth / 2 - CARET_WIDTH / 2 + 'px';
        this.topAmount = targetTop + targetHeight + CARET_POINT_DEPTH + 'px';
        break;
      case 'left-top':
        this.leftAmount = targetLeft - elementWidth - CARET_POINT_DEPTH + 'px';
        this.topAmount = targetTop - verticalAdjust + 'px';
        break;
      case 'left-middle':
        this.leftAmount = targetLeft - elementWidth - CARET_POINT_DEPTH + 'px';
        this.topAmount = targetTop - verticalAdjust + targetHeight / 2 - CARET_WIDTH / 2 + 'px';
        break;
      case 'left-bottom':
        this.leftAmount = targetLeft - elementWidth - CARET_POINT_DEPTH + 'px';
        this.topAmount =
          targetTop - verticalAdjust + targetHeight - CARET_SIDE_OFFSET - CARET_WIDTH / 2 + 'px';
        break;
      case 'right-top':
        this.leftAmount = targetLeft + targetWidth + CARET_POINT_DEPTH + 'px';
        this.topAmount = targetTop - verticalAdjust + 'px';
        break;
      case 'right-middle':
        this.leftAmount = targetLeft + targetWidth + CARET_POINT_DEPTH + 'px';
        this.topAmount = targetTop - verticalAdjust + targetHeight / 2 - CARET_WIDTH / 2 + 'px';
        break;
      case 'right-bottom':
        this.leftAmount = targetLeft + targetWidth + CARET_POINT_DEPTH + 'px';
        this.topAmount =
          targetTop - verticalAdjust + targetHeight - CARET_SIDE_OFFSET - CARET_WIDTH / 2 + 'px';
    }
  }
}
