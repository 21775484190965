import {
  InsuredAccount,
  InsuredAccountAttributes,
} from 'app/features/insured-account/models/insured-account.model';

import { PLACEHOLDER_ORG_TYPE } from 'app/constants';

export class BackendCreateInsuredAccountRequestPayloadFactory {
  static build(
    insuredAccount: InsuredAccountAttributes | InsuredAccount
  ): BackendCreateInsuredAccountRequestPayload {
    const subject: InsuredAccount = new InsuredAccount(insuredAccount);

    const backendInsuredAccountRequestPayload: BackendCreateInsuredAccountRequestPayload = {
      accountModel: {
        Account: {
          AccountHolderContact: {
            EmailAddress1: subject.emailAddress,
            EmailAddress2: subject.additionalEmailAddress,
            Name: subject.companyName,
            PrimaryAddress: {
              AddressLine1: subject.addressLine1,
              AddressLine2: subject.addressLine2 || null,
              AddressType: 'Business',
              City: subject.city,
              County: null,
              PostalCode: subject.zip,
              State: subject.state,
            },
            Subtype: 'Company',
            WorkPhone: subject.phoneNumber,
            FEINOfficialID: subject.fein,
          },
          AccountNumber: subject.id || '',
          AccountOrgType: subject.organizationType,
          DoesBusinessAs_HUSA: subject.doingBusinessAs,
          NAICSCodes_ATTN: null,
          ProducerCodes: subject.producerCodesStruct,
          WebsiteURL_ATTN: subject.website,
        },
      },
    };

    if (subject.naicsCode) {
      backendInsuredAccountRequestPayload.accountModel.Account.NAICSCodes_ATTN = {
        Entry: [
          {
            Code: subject.naicsCode.code,
            Description: subject.naicsCode.description,
            Hash: subject.naicsCode.hash,
          },
        ],
      };
    }

    if (subject.sampleAccount === true) {
      backendInsuredAccountRequestPayload.accountModel.Account.SampleAccount_ATTN =
        subject.sampleAccount;
    }

    return backendInsuredAccountRequestPayload;
  }

  static buildV3Payload(insuredAccount: InsuredAccount): QSAccount {
    const payload: QSAccount = {
      additionalEmail: insuredAccount.additionalEmailAddress || undefined,
      address: {
        addressLine1: insuredAccount.addressLine1,
        addressLine2: insuredAccount.addressLine2 || undefined,
        city: insuredAccount.city,
        state: insuredAccount.state,
        zip: insuredAccount.zip,
      },
      baseState: insuredAccount.state, // not used in create or update account
      description: insuredAccount.description || undefined,
      doingBusinessAs: insuredAccount.doingBusinessAs || undefined,
      fein: insuredAccount.fein || undefined,
      id: insuredAccount.id || undefined,
      insuredEmail: insuredAccount.emailAddress,
      insuredPhone: insuredAccount.phoneNumber,
      insuredContacts: insuredAccount.insuredContacts,
      naicsCodes: insuredAccount.naicsCode !== null ? [insuredAccount.naicsCode] : [],
      name: insuredAccount.companyName,
      // default organizationType to Trust Estate, which we treat as a placeholder value
      organizationType: insuredAccount.organizationType
        ? insuredAccount.organizationType
        : PLACEHOLDER_ORG_TYPE,
      website: insuredAccount.website || undefined,
    };

    if (insuredAccount.inspectionContacts) {
      payload.inspectionContacts = insuredAccount.inspectionContacts;
    }

    if (insuredAccount.sampleAccount === true) {
      payload.sampleAccount = insuredAccount.sampleAccount;
    }

    return payload;
  }
}
