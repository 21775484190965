<div *ngIf="showClassCodeModule" class="policies-overview-sidebar-content">
  <h3>
    Recent binds near {{ classCodeModuleData.zipCode }}
    <span *ngIf="bindsSinceEarliestDate && bindsSinceLatestDate" class="gray-text date-header">
      from {{ bindsSinceEarliestDate | date: 'shortDate' }} -
      {{ bindsSinceLatestDate | date: 'shortDate' }}
    </span>
  </h3>
  <div class="binds-in-your-area-container">
    <div class="binds-in-your-area-header binds-in-your-area-row">
      <div class="product-column">Product</div>
      <div *ngIf="shouldShowNumericCode" class="classcode-column">Classcode</div>
      <div class="description-column">Description</div>
      <div class="premium-column">Premium</div>
    </div>

    <div
      *ngFor="let bindRow of classCodeModuleData.bindsNearYou | slice: 0:displayCount"
      class="binds-in-your-area-row"
    >
      <div class="product-column">
        <div
          [className]="
            'product-badge product-badge__table-cell ' +
            getBadgeName(bindRow.productType.toLowerCase())
          "
        >
          <div
            [className]="
              'product-badge-carrier-icon
              product-badge-carrier-icon__table-cell
              product-badge-carrier-icon__' +
              getCarrierName(bindRow)
            "
          ></div>
        </div>
      </div>
      <div *ngIf="shouldShowNumericCode" class="classcode-column">{{ bindRow.classCode }}</div>
      <div
        class="description-column tool-tip tool-tip__large + {{
          isSmallLaptopOrLess ? null : 'tool-tip__left'
        }}"
        (window:resize)="this.setToolTipShape()"
        [attr.data-tooltip]="setToolTipText(bindRow.classCode, bindRow.classCodeDescription)"
      >
        <p class="truncated-description">
          {{ bindRow.classCodeDescription.split('-').join(' - ') }}
        </p>
      </div>
      <div class="premium-column">{{ bindRow.premium | currency: 'USD':'symbol':'1.0-0' }}</div>
    </div>
  </div>
  <p *ngIf="displayShowMoreButton" class="binds-in-your-area-show-more">
    <a (click)="showMore()" (keyup.enter)="showMore()">Show more</a>
  </p>
</div>
