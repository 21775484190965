<div [ngClass]="{ 'quoting-progress': true, 'quoting-progress--allow-clicks': allowClicks }">
  <div class="quoting-progress-inside" [ngClass]="{ 'quote-progress-complete': percent === 100 }">
    <img src="{{ successImage }}" alt="" />
    <p class="quoting-progress-info-text" *ngIf="displayInfoText">{{ infoText }}</p>
    <h2>{{ title }}</h2>
    <div *ngIf="comments.length" class="quoting-progress--comment">
      {{ comments[ithComment] }}
    </div>
    <div *ngIf="percent === 100" class="quoting-progress--comment js-quoting-progress-success">
      {{ successComment }}
    </div>
    <div class="quoting-progress--bar">
      <div class="quoting-progress--bar-amount" [ngStyle]="{ 'width.%': percent }"></div>
    </div>
  </div>
</div>
