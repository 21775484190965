<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div
    class="{{ cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(nameOfFormControl) }"
  >
    <label [for]="inputId" [attr.data-specifier]="specifierText">
      {{ labelText }}
      <ng-content></ng-content>
      <div *ngIf="tooltipText" class="{{ tooltipCssClass }}" attr.data-tooltip="{{ tooltipText }}">
        <span class="icon icon-question"></span>
      </div>
      <ng-container *ngIf="subLabelText">
        <small class="form-field-sub-label">{{ subLabelText }}</small>
      </ng-container>
    </label>
    <textarea
      [id]="inputId"
      [name]="inputId"
      [value]="derivedValue()"
      [formControl]="form.get(nameOfFormControl)"
      [maxlength]="maxLength"
      [rows]="rows"
      [placeholder]="placeholder"
      (focusout)="handleFocusOut()"
      [readonly]="readonly"
      [class]="textareaClass"
    >
    </textarea>
    <div *ngIf="fieldInvalid(nameOfFormControl) && showErrorText" class="form-field-error-text">
      {{ errorText }}
    </div>
    <p class="form-field-note" *ngIf="questionNote">{{ questionNote }}</p>
  </div>
</ng-container>
