<section class="insured-contact-form">
  <h3>Insured contact information</h3>
  <p>
    Attune is a direct bill company. Insured contact information will be used for billing, audit, or
    loss control purposes only.
  </p>

  <div
    *ngIf="hasContactListError()"
    class="validation insured-contact-validation js-insured-general-error"
  >
    <p
      class="validation-message"
      [ngClass]="{
        'validation-message__warning': !submitted
      }"
    >
      {{ getContactListError() }}
    </p>
  </div>

  <section class="multi-form-item" *ngFor="let contact of contactFormArray.controls; let i = index">
    <section>
      <section class="form-split">
        <app-form-field-text
          inputId="insured-contact-{{ i }}-first-name"
          [form]="contact"
          [submitted]="submitted"
          labelText="First name"
          nameOfFormControl="firstName"
        >
        </app-form-field-text>
        <app-form-field-text
          inputId="insured-contact-{{ i }}-last-name"
          [form]="contact"
          [submitted]="submitted"
          labelText="Last name"
          nameOfFormControl="lastName"
        >
        </app-form-field-text>
      </section>
      <section class="form-split">
        <app-form-field-text
          inputId="insured-contact-{{ i }}-email"
          [form]="contact"
          [submitted]="submitted"
          labelText="Email address"
          nameOfFormControl="emailAddress"
          appRemoveWhitespaceMask
        >
        </app-form-field-text>

        <app-form-field-text
          inputId="insured-contact-{{ i }}-phone"
          [form]="contact"
          [submitted]="submitted"
          labelText="Mobile number"
          nameOfFormControl="phoneNumber"
          appPhoneMask
          maxLength="12"
        >
        </app-form-field-text>
      </section>
      <div *ngIf="contact.get('emailAddress')?.hasError('invalidEmailAddress')" class="validation">
        <span
          class="validation-message"
          [ngClass]="{
            'validation-message__warning': !submitted
          }"
          >Email Address must be a valid email address.</span
        >
      </div>
    </section>

    <p>Contact purpose:</p>
    <ng-container *ngFor="let contactType of insuredContactOptions | keys">
      <app-form-field-checkbox
        cssClass="insured-contact-email-type"
        [form]="contact.get('contactPurpose')"
        [submitted]="submitted"
        inputId="insured-contact-{{ i }}-purpose-{{ contactType }}"
        [nameOfFormControl]="contactType"
        [innerHtmlLabelText]="insuredContactOptions[contactType]"
      >
      </app-form-field-checkbox>
    </ng-container>

    <div
      *ngIf="hasContactPurposeError(contact)"
      class="validation insured-contact-validation js-insured-contact-error"
    >
      <p
        class="validation-message"
        [ngClass]="{
          'validation-message__warning': !submitted
        }"
      >
        {{ getContactPurposeError(contact) }}
      </p>
    </div>

    <div class="insured-contacts-button-container" *ngIf="contactFormArray.length > 1">
      <a
        class="
          button button__small button__no-margin button__transparent
          js-insured-contact-remove-button
        "
        (click)="removeInsuredContact(i)"
        (keyup.enter)="removeInsuredContact(i)"
      >
        Remove
      </a>
    </div>
  </section>

  <button
    class="
      button button__secondary button__full-width button__no-margin
      js-insured-contact-add-button
    "
    type="button"
    (click)="addInsuredContact()"
    *ngIf="contactFormArray.length < maxNumContacts"
  >
    Add insured contact
  </button>
</section>
