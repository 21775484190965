import { ValidatorFn, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { LibertyMutualClassCode } from './common-typings';
import * as _ from 'lodash';

import {
  LmBopNestedControls,
  LmBopQuestion,
  LmBopFormStepPath,
  LmBopLossHistoryQuestion,
  LmBopValidators,
  LmBopArrayValidators,
  LmBopComplexValidators,
} from './lm-bop-typings';
import {
  getControl,
  percentValidator,
  phoneValidatorWithMessage,
  validateCurrencyIsNotEmpty,
} from '../../../shared/helpers/form-helpers';
import {
  PERCENT_FORM_FIELDS,
  PHONE_FORM_FIELDS,
  PHONE_FORM_ARRAY_FIELDS,
  YEAR_FORM_FIELDS,
} from './lm-bop-questions';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';
import { validateDate } from 'app/features/attune-bop/models/form-validators';
import { bopControlPath } from './lm-bop-constants';
import { BOP_LRO_CLASSES } from './class-codes';
import { PHONE_ERROR_MESSAGE, LOCATION_RISK_STATE_ERROR_MESSAGE } from './constants';
import {
  validateLmEffectiveDate,
  setPhoneValidators,
  setYearValidators,
  valueIsGreaterThanZero,
  validateLmLossDate,
} from './lm-shared-validators';

const NUM_EXCEEDED_ERROR_MESSAGE = (value: number) =>
  `Please enter a numeric value less than ${value + 1}.`;
const YEARS_MANAGEMENT_EXPERIENCE_LIMIT = 999;

const validateBppOrBuildingLimitIsNonZero: ValidatorFn = (locationDetailsGroup) => {
  const bppControl = getControl(
    locationDetailsGroup as UntypedFormGroup,
    LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT
  );
  const buildingLimitControl = getControl(
    locationDetailsGroup as UntypedFormGroup,
    LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE
  );

  if (parseMoney(bppControl.value) > 0 || parseMoney(buildingLimitControl.value) > 0) {
    return null;
  }

  return {
    bppAndBuildingLimitAreZero: {
      validationMessage: 'Business personal property limit and/or Building limit is required.',
    },
  };
};

const validateBeautyEmployeesIsNonzero: ValidatorFn = (locationDetailsGroup) => {
  const beautyEmployeeControls = [
    LmBopQuestion.FULL_TIME_BEAUTICIANS_AT_THIS_LOCATION,
    LmBopQuestion.PART_TIME_BEAUTICIANS_AT_THIS_LOCATION,
    LmBopQuestion.FULL_TIME_BARBERS_AT_THIS_LOCATION,
    LmBopQuestion.PART_TIME_BARBERS_AT_THIS_LOCATION,
    LmBopQuestion.MANICURISTS_AT_THIS_LOCATION,
  ];

  const atLeastOneControlEnabled = beautyEmployeeControls.some((controlName) => {
    const employeeControl = getControl(locationDetailsGroup as UntypedFormGroup, controlName);
    return _.get(employeeControl, 'enabled');
  });

  // If no beauty employee controls are enabled, then this validator isn't
  // relevant. We can skip any total number of employees checks.
  if (!atLeastOneControlEnabled) {
    return null;
  }

  const totalNumOfEmployees = beautyEmployeeControls.reduce((total, controlName) => {
    const employeeControl = getControl(locationDetailsGroup as UntypedFormGroup, controlName);
    const employeeControlVal = employeeControl.value;
    // The conversion to a `Number` coerces any `null` values to `0`.
    return Number(employeeControlVal) + total;
  }, 0);

  if (totalNumOfEmployees === 0) {
    return {
      beautyEmployeesIsZero: {
        validationMessage: 'Please enter at least one employee.',
      },
    };
  }

  return null;
};

const valueIsLessThanOrEqualToNumber = (threshold: number) => {
  const valueIsLessThanOrEqualToNumberHelper: ValidatorFn = (control) => {
    // The field value is either money (as a `string` type) or a number (as a
    // `number` type). For money, it's parsed as a number before any comparisons
    // occur.
    let fieldValue = control.value;
    if (_.isNull(fieldValue)) {
      return null;
    }

    if (_.isString(fieldValue)) {
      const currencyInvalid = validateCurrencyIsNotEmpty(control);

      if (currencyInvalid) {
        return currencyInvalid;
      }

      fieldValue = parseMoney(fieldValue);
    }

    if (fieldValue > threshold) {
      return {
        valueGreaterThanNumber: {
          validationMessage: NUM_EXCEEDED_ERROR_MESSAGE(threshold),
        },
      };
    }

    return null;
  };

  return valueIsLessThanOrEqualToNumberHelper;
};

// Set phone validators for BOP phone form fields
const PHONE_FORM_VALIDATORS = setPhoneValidators(PHONE_FORM_FIELDS);

// PHONE form array validators (NODE_ARRAY LM questions - mostly loss questions)
const PHONE_FORM_ARRAY_VALIDATORS: {
  [key in LmBopQuestion | LmBopNestedControls | LmBopFormStepPath]?: ValidatorFn[];
} = _.zipObject(
  PHONE_FORM_ARRAY_FIELDS,
  _.map(PHONE_FORM_ARRAY_FIELDS, () => [phoneValidatorWithMessage(PHONE_ERROR_MESSAGE)])
);

// Percent validators
const PERCENT_AREA_FORM_VALIDATORS: {
  [key in LmBopQuestion | LmBopFormStepPath]?: ValidatorFn[];
} = _.zipObject(
  PERCENT_FORM_FIELDS,
  _.map(PERCENT_FORM_FIELDS, () => [percentValidator])
);

// Set year validators for BOP year form fields
const YEAR_VALIDATORS = setYearValidators(YEAR_FORM_FIELDS);

/**
 * A dictionary of validators. If the key is ...
 *  - the name of a control => the validation relies only on the control's value
 *  - the name of a step => the validator is set on the step's form group; validation
 *      relies on more than one control.
 */
export const LM_BOP_VALIDATORS: Partial<LmBopValidators> = {
  // Inter-control validators
  [LmBopFormStepPath.LOCATION_DETAILS]: [
    validateBppOrBuildingLimitIsNonZero,
    validateBeautyEmployeesIsNonzero,
  ],
  // Single-control validators
  [LmBopQuestion.EFFECTIVE_DATE]: [validateDate, validateLmEffectiveDate],
  [LmBopQuestion.NUMBER_STORIES]: [valueIsGreaterThanZero],
  [LmBopQuestion.ANNUAL_SALES_RECEIPTS]: [valueIsGreaterThanZero],
  [LmBopQuestion.TOTAL_RECEIPTS_FOR_LOCATION]: [valueIsGreaterThanZero],
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_HEARING_AID_PROFESSIONAL_LIABILITY]: [
    valueIsGreaterThanZero,
  ],
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTOMETRISTS_PROFESSIONAL_LIABILITY]: [
    valueIsGreaterThanZero,
  ],
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTICAL_PROFESSIONAL_LIABILITY]: [valueIsGreaterThanZero],
  [LmBopQuestion.NUMBER_OF_VETERINARIANS_OWNERS_PARTNERS_OFFICERS]: [valueIsGreaterThanZero],
  [LmBopQuestion.ANNUAL_DRY_CLEANING_RECEIPTS_AT_THIS_LOCATION]: [valueIsGreaterThanZero],
  [LmBopQuestion.TOTAL_ANNUAL_LIQUOR_RECEIPTS_AT_THIS_LOCATION]: [valueIsGreaterThanZero],
  [LmBopQuestion.PRINTERS_ANNUAL_RECEIPTS_AT_THIS_LOCATION_PRINTERS_E_O]: [valueIsGreaterThanZero],
  [LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT]: [validateCurrencyIsNotEmpty],
  [LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE]: [validateCurrencyIsNotEmpty],
  [LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE]: [
    valueIsLessThanOrEqualToNumber(YEARS_MANAGEMENT_EXPERIENCE_LIMIT),
  ],
  ...PERCENT_AREA_FORM_VALIDATORS,
  ...PHONE_FORM_VALIDATORS,
  ...YEAR_VALIDATORS,
};

/**
 * A dictionary of validators for fields implemented as FormArrays. The keys are the names
 * of these fields, and each value is a dictionary of validators for each element (form group) of
 * the FormArray.
 *
 * When a new FormGroup is pushed into the FormArray, this dictionary can be used to set all of the
 * relevant validators on that FormGroup
 */
export const LM_BOP_FORM_ARRAY_VALIDATORS: DeepPartial<LmBopArrayValidators> = {
  [LmBopQuestion.LOSSES]: {
    [LmBopLossHistoryQuestion.DATE_OF_LOSS]: [validateDate, validateLmLossDate],
    ...PHONE_FORM_ARRAY_VALIDATORS,
  },
};

// Inter-step validators
const otherTenantAreaOverFiftyPercentForLRO = (form: UntypedFormGroup): ValidatorFn => {
  const classCodeControl = getControl(form, bopControlPath(LmBopQuestion.BOP_CLASS_CODE));
  const areaOccupiedByInsuredControl = getControl(
    form,
    bopControlPath(LmBopQuestion.AREA_OCCUPIED_BY_INSURED)
  );
  const areaUnoccupiedControl = getControl(form, bopControlPath(LmBopQuestion.AREA_UNOCCUPIED));

  return (areaOccupiedByOthersControl: UntypedFormControl) => {
    const classCode: LibertyMutualClassCode = classCodeControl.value;
    if (!BOP_LRO_CLASSES.has(classCode.name)) {
      return null;
    }

    const areaOccupiedByOthers = areaOccupiedByOthersControl.value;
    const areaOccupiedByInsured = areaOccupiedByInsuredControl.value;
    const areaUnoccupied = areaUnoccupiedControl.value;

    const areaControlsAreIncomplete = [
      areaOccupiedByInsured,
      areaUnoccupied,
      areaOccupiedByOthers,
    ].some((areaVal) => {
      return _.isNull(areaVal) || areaVal === '';
    });

    if (areaControlsAreIncomplete) {
      return null;
    }

    const totalArea =
      Number(areaOccupiedByOthers) + Number(areaUnoccupied) + Number(areaOccupiedByInsured);
    const areaOccupiedByOthersIsTooSmall = areaOccupiedByOthers < totalArea / 2;

    if (areaOccupiedByOthersIsTooSmall) {
      return {
        unoccupiedAreaIsTooSmall: {
          validationMessage:
            'For all LRO class codes, the area occupied by others must be 50% or more of the total square footage',
        },
      };
    }

    return null;
  };
};

const locationStateMatchesRiskState = (form: UntypedFormGroup): ValidatorFn => {
  const primaryRiskStateControl = getControl(
    form,
    bopControlPath(LmBopQuestion.PRIMARY_RISK_STATE)
  );

  return (addressFormGroup: UntypedFormGroup) => {
    const addressValue = addressFormGroup.value as Address;
    const stateFieldIsIncomplete = _.isNull(addressValue.state) || addressValue.state === '';
    if (stateFieldIsIncomplete || addressValue.state === primaryRiskStateControl.value) {
      return null;
    }

    return {
      locationStateAndRiskStateMismatch: {
        validationMessage: LOCATION_RISK_STATE_ERROR_MESSAGE,
      },
    };
  };
};

// TODO: Refactor the structure of these dictionaries so that they can be contained in a single data structure;
//       This will require making changes elsewhere in FormDSL, especially in FormDslMultiStepFormService.setStepValidators()
//       and in the Hiscox validator configs.
/**
 * A dictionary of validators used for interstep validation. If the key is ...
 *  - the name of a control => the value of the control is validated
 *  - the name of a step => the value of different elements in the step are validated
 *
 * The values in this dictionary are functions that take in the ENTIRE form and return
 * a validator function. This validator is set on the control/step named in the key.
 */
export const LM_BOP_COMPLEX_VALIDATORS: Partial<LmBopComplexValidators> = {
  [LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS]: [otherTenantAreaOverFiftyPercentForLRO],
  [LmBopQuestion.BUSINESS_ADDRESS]: [locationStateMatchesRiskState],
};
