<div class="commission-info-main-container">
  <h1>Commission</h1>
  <ng-container *ngIf="!this.pageLoading">
    <ng-container *ngIf="isDirectlyPayed()">
      <p class="gray-text">
        To ensure that we pay you as quickly as possible, Attune pays commission twice per month.
        <span
          *ngIf="
            (commissionPlanLinkEnabled === true &&
              producerDetails.GroupType === 'subagency' &&
              ![
                'SMARTCHOICE00001',
                'IMS00001',
                'IROQUOIS00001',
                'ADPDIRECT1',
                'ADPDIGITAL1'
              ].includes(producerDetails.Organization)) ||
            producerDetails.ProducerCode === 'CASURA00011'
          "
        >
          Click
          <a
            class="commission-plan-information"
            (click)="openCommissionPlanInformationModal()"
            (keyup.enter)="openCommissionPlanInformationModal()"
            >here</a
          >
          to learn more about commission details.
        </span>
        See below for more information about when to expect commission payouts for each line of
        business.
      </p>
      <div class="commission-info-card-container">
        <article class="commission-info-card">
          <h2 class="p p__bold">Attune statements</h2>
          <div role="img" [attr.aria-label]="'Included products'" class="product-badge-group">
            <app-product-badge product="bop" pasSource="attune"></app-product-badge>
            <app-product-badge product="cyber_admitted" pasSource="coalition"></app-product-badge>
          </div>
          <ul class="badge-list gray-text">
            <li>
              <img class="badge-list-img" src="/assets/img/calendar.svg" alt="" />
              <p class="badge-list-text">Payout initiated: 15th of each month</p>
            </li>
            <li>
              <img class="badge-list-img" src="/assets/img/dollar_cycle.svg" alt="" />
              <p class="badge-list-text">
                Transactions included in statement: Commission payable for policies bound between
                the 15th of the previous month and the 14th of the current month
              </p>
            </li>
          </ul>
        </article>

        <article class="commission-info-card">
          <h2 class="p p__bold">Partner statements</h2>
          <div role="img" [attr.aria-label]="'Included products'" class="product-badge-group">
            <app-product-badge product="gl_v4" pasSource="hiscox"></app-product-badge>
            <app-product-badge product="pl_v4" pasSource="hiscox"></app-product-badge>
            <app-product-badge product="wc" pasSource="employers"></app-product-badge>
            <app-product-badge product="flood"></app-product-badge>
          </div>
          <ul class="badge-list gray-text">
            <li>
              <img class="badge-list-img" src="/assets/img/calendar.svg" alt="" />
              <p class="badge-list-text">Payout initiated: First week of each month</p>
            </li>
            <li>
              <img class="badge-list-img" src="/assets/img/dollar_cycle.svg" alt="" />
              <p class="badge-list-text">
                Transactions included: Commission payable for non-WC policies bound in the month
                before last and commission payable for WC premium paid in the month before last
              </p>
            </li>
          </ul>
        </article>
      </div>
    </ng-container>

    <ng-container *ngIf="dialogHeader && dialogBody">
      <div
        [ngClass]="{
          'js-commission-data-hidden-dialog': !canManageFinances() && isDirectlyPayed(),
          'js-commission-data-not-available-dialog': !isDirectlyPayed()
        }"
      >
        <app-dialog-box type="primary" [header]="dialogHeader">
          <p [innerHtml]="dialogBody"></p>
        </app-dialog-box>
      </div>
    </ng-container>

    <ng-container *ngIf="isDirectlyPayed() && canManageFinances()">
      <div *ngIf="statementsError">
        <p class="dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin">
          There was an error loading commission information.
        </p>
      </div>
      <ng-container *ngIf="!statementsError">
        <app-combined-commission-statements-table
          [commissionData]="attuneAndPartnerCommissionData"
          [statementsLoading]="statementsLoading"
        ></app-combined-commission-statements-table>
      </ng-container>
    </ng-container>

    <div *ngIf="!payableStatus">
      <p class="dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin">
        There was an error loading commission information.
      </p>
    </div>
  </ng-container>

  <app-modal
    [open]="showNegativeExplanationModal"
    (handleModalClose)="closeNegativeExplanationModal()"
    modalClass="app-modal-content__left-align"
  >
    <h2>Negative commission values</h2>
    <p>
      Commission statements can be negative if Attune has already paid commission for a policy which
      was then canceled or endorsed to a lower premium. The negative amount reflects how much an
      agency owes back to Attune. Note that Attune will deduct this amount from the agency's
      subsequent commission payout.
    </p>
    <div class="nav-button-group">
      <button (click)="closeNegativeExplanationModal()" class="button button__secondary">
        Got it
      </button>
    </div>
  </app-modal>

  <app-commission-plan-information-modal
    [open]="showCommissionPlanInformationModal"
    (closeModal)="closeCommissionPlanInformationModal()"
    [producerDetails]="producerDetails"
  ></app-commission-plan-information-modal>
</div>
