import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import * as moment from 'moment';
import { interval } from 'rxjs';
import { debounce, startWith } from 'rxjs/operators';
import {
  SimpleValidator,
  validateNoSpecialCharactersInAddress,
  validateAddressIsNotPO,
} from 'app/features/attune-bop/models/form-validators';
import { US_DATE_MASK } from 'app/constants';
import {
  getControl,
  minDateExceededValidator,
  rangeValidator,
} from 'app/shared/helpers/form-helpers';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import {
  AdditionalCoverages,
  AssaultAndBatteryCoverageOptionValues,
  CoverageOptions,
} from 'app/hab/constants';
import { HabFormLocationSummary, HabFormLossHistoryCarrier } from 'app/hab/typings';

const MIN_RETROACTIVE_DATE = moment('2000-01-01');
const MIN_RETROACTIVE_DATE_STR = MIN_RETROACTIVE_DATE.format(US_DATE_MASK);
const ELIGIBILE_STATES = ['AZ'];

function retroactiveDateIsBeforeEffectiveDate(
  retroactiveDate: AbstractControl,
  effectiveDate: string
): boolean {
  if (!retroactiveDate.value) {
    return true;
  }
  return moment(retroactiveDate.value, US_DATE_MASK).isBefore(moment(effectiveDate, US_DATE_MASK));
}

const retroactiveDateValidator = (effectiveDate: UntypedFormControl): SimpleValidator => {
  return function (retroactiveDate: AbstractControl) {
    if (!retroactiveDateIsBeforeEffectiveDate(retroactiveDate, effectiveDate.value)) {
      return { retroactiveDateError: 'Retroactive date must be before effective date' };
    }
    if (
      retroactiveDate.value &&
      moment(retroactiveDate.value, US_DATE_MASK).isBefore(MIN_RETROACTIVE_DATE)
    ) {
      return { retroactiveDateError: `Retroactive date must be after ${MIN_RETROACTIVE_DATE_STR}` };
    }
    return null;
  };
};

const stateValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!ELIGIBILE_STATES.includes(control.value)) {
      return {
        stateValidationError: `This product is only available in ${ELIGIBILE_STATES.join(', ')}`,
      };
    }
    return null;
  };
};

@Injectable()
export class HabQuoteFormService extends FormDslSteppedFormBaseService {
  public form: UntypedFormGroup;
  public submitted = false;
  public now: moment.Moment = moment();

  // Flags
  public shouldAddAccountStep = false;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.initializeForm();
    this.syncAllSteps();
  }

  /**
   * Setup Methods
   */
  public initializeForm() {
    const guidelines = this.formBuilder.group({
      areBuildingsBuiltAfter1980: [null, Validators.required],
      isRiskApartmentBuildingsOnly: [null, Validators.required],
      is4StoriesOrUnder: [null, Validators.required],
      isBetween5And75Units: [null, Validators.required],
      isAtLeast80PercentOccupied: [null, Validators.required],
      isCompliantWithTivLimits: [null, Validators.required],
      hasReadGuidelines: [false, Validators.requiredTrue],
    });

    const initialLocationSummary: UntypedFormGroup = this.newLocationSummary();
    const initialLocation: UntypedFormGroup = this.newLocation();
    this.syncSummaryWithActualLocation(initialLocationSummary, initialLocation);

    const policyDetails = this.formBuilder.group({
      effectiveDate: [
        this.now.format(US_DATE_MASK),
        [Validators.required, minDateExceededValidator(this.now, 'day')],
      ],
      organizationType: ['', Validators.required],
      doesInsuredUsePropertyManagementFirm: [null, Validators.required],
      locationSummaries: this.formBuilder.array([initialLocationSummary]),
      applyBuildingCoverageBlanket: [false, Validators.required],
      buildingCoverageBlanket: this.formBuilder.group({
        buildingCoverageDeductible: [null, Validators.required],
        buildingCoverageCoinsurance: [null, Validators.required],
      }),
      applyBusinessIncomeBlanket: [false, Validators.required],
      businessIncomeBlanket: this.formBuilder.group({
        coinsurancePercent: [null, Validators.required],
      }),
    });

    const lossHistory = this.formBuilder.group({
      hasRiskHadPriorLosses: [null, Validators.required],
      losses: this.formBuilder.array([this.newLoss()]),
    });

    const lossesGroup = lossHistory.get('losses') as UntypedFormArray;
    lossesGroup.disable();
    getControl(lossHistory, 'hasRiskHadPriorLosses').valueChanges.subscribe(
      (hasHadPriorRisk: boolean) => {
        this.toggleEnabled(lossesGroup, hasHadPriorRisk);
        // when we enable lossesGroup, we enable its children, but we want the otherCarrier field to be disabled by default
        for (const lossGroup of lossesGroup.controls) {
          const otherCarrier = getControl(lossGroup as UntypedFormGroup, 'otherCarrier');
          otherCarrier.disable({ onlySelf: true });
        }
      }
    );

    const locations = this.formBuilder.array([initialLocation]);

    /**
     * Building Coverage Blanket Behavior
     *
     * Toggle the visibility of the building page form controls when we opt in/out.
     */
    const buildingCoverageBlanketCheckbox = getControl(
      policyDetails,
      'applyBuildingCoverageBlanket'
    );
    const buildingCoverageBlanketGroup = policyDetails.get(
      'buildingCoverageBlanket'
    ) as UntypedFormGroup;
    buildingCoverageBlanketGroup.disable();
    buildingCoverageBlanketCheckbox.valueChanges.subscribe((optedIn: boolean) => {
      this.toggleEnabled(buildingCoverageBlanketGroup, optedIn);

      locations.controls.forEach((control) => {
        (control.get('buildings') as UntypedFormArray).controls.forEach(
          (bldgControl: UntypedFormGroup) => {
            this.toggleEnabled(getControl(bldgControl, 'buildingCoverageCoinsurance'), !optedIn);
            this.toggleEnabled(getControl(bldgControl, 'buildingCoverageDeductible'), !optedIn);
          }
        );
      });
    });

    /**
     * Business Income Blanket Behavior
     *
     * Toggle the visibility of the building page form controls when we opt in/out.
     */
    const businessIncomeBlanketCheckbox = getControl(policyDetails, 'applyBusinessIncomeBlanket');
    const businessIncomeBlanketGroup = policyDetails.get(
      'businessIncomeBlanket'
    ) as UntypedFormGroup;
    businessIncomeBlanketCheckbox.disable();
    businessIncomeBlanketGroup.disable();
    businessIncomeBlanketCheckbox.valueChanges.subscribe((optedIn: boolean) => {
      this.toggleEnabled(businessIncomeBlanketGroup, optedIn);

      locations.controls.forEach((control) => {
        (control.get('buildings') as UntypedFormArray).controls.forEach(
          (bldgControl: UntypedFormGroup) => {
            if (optedIn) {
              getControl(bldgControl, 'businessIncomeAndExtraExpenseSelected').patchValue(true);
              getControl(bldgControl, 'businessIncomeAndExtraExpense.coinsurancePercent').disable();
            } else {
              getControl(bldgControl, 'businessIncomeAndExtraExpenseSelected').patchValue(false);
            }
          }
        );
      });
    });

    /**
     * The business income blanket isn't available for 1 location / 1 building; disable it.
     */
    const locationSummaries = (
      policyDetails.get('locationSummaries') as UntypedFormGroup
    ).valueChanges.subscribe((formLocSummaries: HabFormLocationSummary[]) => {
      const numLocations = formLocSummaries.length;
      if (numLocations === 0 || numLocations === 1) {
        const locSummary: HabFormLocationSummary = formLocSummaries[0];
        const numBuildings = parseInt(locSummary.numBuildings, 10);
        if (numBuildings === 0 || numBuildings === 1) {
          businessIncomeBlanketCheckbox.patchValue(false);
          businessIncomeBlanketCheckbox.disable();
        }
      } else {
        businessIncomeBlanketCheckbox.enable();
        businessIncomeBlanketCheckbox.patchValue(businessIncomeBlanketCheckbox.value);
      }
    });

    const additionalCoverages = this.formBuilder.group({
      [AdditionalCoverages.ORDINANCE_OR_LAW_DEMOLITION_COST]: [
        CoverageOptions[AdditionalCoverages.ORDINANCE_OR_LAW_DEMOLITION_COST][0],
        Validators.required,
      ],
      [AdditionalCoverages.ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION]: [
        CoverageOptions[AdditionalCoverages.ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION][0],
        Validators.required,
      ],
      [AdditionalCoverages.BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT]: [
        CoverageOptions[
          AdditionalCoverages.BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT
        ][0],
        Validators.required,
      ],
      [AdditionalCoverages.EMPLOYEE_THEFT_LIMIT]: [
        CoverageOptions[AdditionalCoverages.EMPLOYEE_THEFT_LIMIT][0],
        Validators.required,
      ],
      [AdditionalCoverages.FORGERY_OR_ALTERATION_LIMIT]: [
        CoverageOptions[AdditionalCoverages.FORGERY_OR_ALTERATION_LIMIT][0],
        Validators.required,
      ],
    });

    const generalLiability = this.formBuilder.group({
      perOccurenceAndAggregateLimitDisplayedValue: ['$1M / $2M', Validators.required],
      perOccurrenceLimit: ['1000000', Validators.required],
      aggregateLimit: ['2000000', Validators.required],
      assaultAndBatterySelected: [false, Validators.required],
      assaultAndBattery: this.formBuilder.group({
        displayedValue: [null, Validators.required],
        aggregateLimit: [null, Validators.required],
        occurrenceLimit: [null, Validators.required],
      }),
      hiredNonOwnedAutoLiabilitySelected: [false, Validators.required],
      hiredNonOwnedAutoLiability: this.formBuilder.group({
        numDrivers: [null, Validators.required],
      }),
      employeeBenefitsLiabilitySelected: [false, Validators.required],
      employeeBenefitsLiability: this.formBuilder.group({
        retroactiveDate: [
          null,
          [
            Validators.required,
            retroactiveDateValidator(getControl(policyDetails, 'effectiveDate')),
          ],
        ],
        aggregateLimit: [null, Validators.required],
      }),
    });

    /**
     * Assault and Battery Behavior
     */
    const assaultAndBatteryFormGroup = generalLiability.get(
      'assaultAndBattery'
    ) as UntypedFormGroup;
    getControl(assaultAndBatteryFormGroup, 'displayedValue').valueChanges.subscribe(
      (selection: string) => {
        let newOccurrenceLimit: string | null = null;
        let newAggregateLimit: string | null = null;

        if (selection === AssaultAndBatteryCoverageOptionValues.OPTION_10K_20K) {
          newOccurrenceLimit = '10000';
          newAggregateLimit = '20000';
        } else if (selection === AssaultAndBatteryCoverageOptionValues.OPTION_25K_50K) {
          newOccurrenceLimit = '25000';
          newAggregateLimit = '50000';
        }

        assaultAndBatteryFormGroup.patchValue({
          occurrenceLimit: newOccurrenceLimit,
          aggregateLimit: newAggregateLimit,
        });
      }
    );

    assaultAndBatteryFormGroup.disable();
    getControl(generalLiability, 'assaultAndBatterySelected').valueChanges.subscribe(
      (optedIn: boolean) => {
        this.toggleEnabled(assaultAndBatteryFormGroup, optedIn);

        if (!optedIn) {
          /**
           * TODO: Figure out why this is necessary.
           *
           * Without this, the form value becomes {}, instead of undefined.
           */
          Object.keys(assaultAndBatteryFormGroup.controls).forEach((key: string) => {
            assaultAndBatteryFormGroup.controls[key].disable();
          });
        }
      }
    );

    /**
     * Employee Benefits Behavior
     */
    const employeeBenefitsLiabilityFormGroup = getControl(
      generalLiability,
      'employeeBenefitsLiability'
    );
    getControl(generalLiability, 'employeeBenefitsLiabilitySelected')
      .valueChanges.pipe(startWith(false))
      .subscribe((optedIn: boolean) => {
        this.toggleEnabled(employeeBenefitsLiabilityFormGroup, optedIn);
      });

    getControl(policyDetails, 'effectiveDate').valueChanges.subscribe((effectiveDate) => {
      const retroactiveDateControl = getControl(
        generalLiability,
        'employeeBenefitsLiability.retroactiveDate'
      );
      if (!retroactiveDateIsBeforeEffectiveDate(retroactiveDateControl, effectiveDate)) {
        retroactiveDateControl.patchValue('');
      }
    });

    /**
     * Hired Non Owned Auto Behavior
     */
    const hnoaFormGroup = getControl(generalLiability, 'hiredNonOwnedAutoLiability');
    getControl(generalLiability, 'hiredNonOwnedAutoLiabilitySelected')
      .valueChanges.pipe(startWith(false))
      .subscribe((optedIn: boolean) => {
        this.toggleEnabled(hnoaFormGroup, optedIn);
      });

    this.form = this.formBuilder.group({
      guidelines,
      policyDetails,
      lossHistory,
      locations,
      additionalCoverages,
      generalLiability,
    });
  }

  public setAccountStepVisibility(shouldDisplay: boolean): void {
    this.shouldAddAccountStep = shouldDisplay;
    this.syncAllSteps();
  }

  public generateSteps(): RouteFormStep[] {
    const accountStep: RouteFormStep | null = this.shouldAddAccountStep
      ? {
          args: {},
          displayName: 'Account',
          slug: 'account',
          parent: 'account',
          formPath: 'account',
        }
      : null;

    let locationSteps: RouteFormStep[] = [];
    if (this.form) {
      locationSteps = this.locationSummariesFormArray().controls.reduce(
        (acc: RouteFormStep[], control: AbstractControl, locationIndex: number) => {
          const formLocation: HabFormLocationSummary = control.value;
          const numBuildings = parseInt(formLocation.numBuildings, 10);

          return acc.concat(this.generateBuildingSteps(locationIndex, numBuildings));
        },
        []
      );
    }

    return [
      accountStep,
      {
        args: {},
        displayName: 'Guidelines',
        slug: 'guidelines',
        parent: 'guidelines',
        formPath: 'guidelines',
      },
      {
        args: {},
        displayName: 'Policy details',
        slug: 'policy-details',
        parent: 'policy-details',
        formPath: 'policyDetails',
      },
      {
        args: {},
        displayName: 'Loss history',
        slug: 'loss-history',
        parent: 'loss-history',
        formPath: 'lossHistory',
      },
      ...locationSteps,
      {
        args: {},
        displayName: 'Additional Coverages',
        slug: 'additional-coverages',
        parent: 'additional-coverages',
        formPath: 'additionalCoverages',
      },
      {
        args: {},
        displayName: `General liability`,
        slug: 'general-liability',
        parent: 'general-liability',
        formPath: 'generalLiability',
      },
    ].filter(this.isStep);
  }

  private generateBuildingSteps(
    zeroIndexedLocationNum: number,
    numBuildings: number
  ): RouteFormStep[] {
    const oneIndexedLocationNum: number = zeroIndexedLocationNum + 1;

    const buildingSteps: RouteFormStep[] = [];
    for (let buildingIndex = 0; buildingIndex < numBuildings; buildingIndex++) {
      const oneIndexedBuildingNum: number = buildingIndex + 1;

      buildingSteps.push({
        args: {},
        displayName: `Loc ${oneIndexedLocationNum} / Bldg ${oneIndexedBuildingNum}`,
        slug: `location/${oneIndexedLocationNum}/building/${oneIndexedBuildingNum}`,
        parent: `location/${oneIndexedLocationNum}/building/${oneIndexedBuildingNum}`,
        formPath: `locations.${zeroIndexedLocationNum}.buildings.${buildingIndex}`,
      });
    }

    return buildingSteps;
  }

  /**
   * Location Methods
   */
  public locationSummariesFormArray(): UntypedFormArray {
    return this.form.get('policyDetails.locationSummaries') as UntypedFormArray;
  }

  public locationsFormArray(): UntypedFormArray {
    return this.form.get('locations') as UntypedFormArray;
  }

  public addLocation(): void {
    const locationSummary = this.newLocationSummary();
    const location = this.newLocation();
    this.syncSummaryWithActualLocation(locationSummary, location);

    this.locationSummariesFormArray().push(locationSummary);
    this.locationsFormArray().push(location);

    this.syncAllSteps();
  }

  public removeLocation(index: number): void {
    this.locationSummariesFormArray().removeAt(index);
    this.locationsFormArray().removeAt(index);

    this.syncAllSteps();
  }

  public newLocationSummary(): UntypedFormGroup {
    const addressFormGroup: UntypedFormGroup = this.formBuilder.group({
      addressLine1: [
        null,
        [
          Validators.required,
          validateAddressIsNotPO,
          validateNoSpecialCharactersInAddress,
          Validators.maxLength(60),
        ],
      ],
      addressLine2: ['', Validators.maxLength(60)],
      city: [null, Validators.required],
      state: [null, [Validators.required, stateValidator()]],
      zip: [null, Validators.required],
    });

    const numBuildingsFormControl: UntypedFormControl = this.formBuilder.control(1, [
      rangeValidator(1),
      Validators.required,
    ]);
    numBuildingsFormControl.valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(() => this.syncAllSteps());

    const locationFeatures: UntypedFormGroup = this.newLocationFeatures();

    return this.formBuilder.group({
      address: addressFormGroup,
      features: locationFeatures,
      numBuildings: numBuildingsFormControl,
    });
  }

  public newLocation(): UntypedFormGroup {
    const addressFormGroup: UntypedFormGroup = this.formBuilder.group({
      addressLine1: [null, Validators.required],
      addressLine2: [null],
      city: [null, Validators.required],
      state: [null, Validators.required],
      zip: [null, Validators.required],
    });

    const buildingsFormArray: UntypedFormArray = this.formBuilder.array([this.newBuilding()]);

    const locationFeatures: UntypedFormGroup = this.newLocationFeatures();

    return this.formBuilder.group({
      address: addressFormGroup,
      buildings: buildingsFormArray,
      features: locationFeatures,
    });
  }

  private newLocationFeatures(): UntypedFormGroup {
    const hasPaidParkingControl = this.formBuilder.control(false, Validators.required);
    const paidParkingSqFootageControl = this.formBuilder.control(null, Validators.required);

    const hasPoolsControl = this.formBuilder.control(false, Validators.required);
    const numberOfPoolsControl = this.formBuilder.control(null, Validators.required);

    const features: UntypedFormGroup = this.formBuilder.group({
      hasCommercialOccupancies: [false, Validators.required],
      hasPaidParking: hasPaidParkingControl,
      paidParkingSquareFootage: paidParkingSqFootageControl,
      hasPool: hasPoolsControl,
      numberOfPools: numberOfPoolsControl,
      hasPondOrLake: [false, Validators.required],
      hasDivingBoard: [false, Validators.required],
      hasClubhouseOrPartyRoom: [false, Validators.required],
      hasPlayground: [false, Validators.required],
      hasGymOrExerciseRoom: [false, Validators.required],
      hasSportsFacilitiesOrCourts: [false, Validators.required],
    });

    paidParkingSqFootageControl.disable();
    hasPaidParkingControl.valueChanges.subscribe((hasPaidParking: boolean) => {
      this.toggleEnabled(paidParkingSqFootageControl, hasPaidParking);
    });

    numberOfPoolsControl.disable();
    hasPoolsControl.valueChanges.subscribe((hasPools: boolean) => {
      this.toggleEnabled(numberOfPoolsControl, hasPools);
    });

    return features;
  }

  private syncSummaryWithActualLocation(
    summaryFormGroup: UntypedFormGroup,
    locationFormGroup: UntypedFormGroup
  ): void {
    summaryFormGroup.valueChanges.subscribe((locationSummary: HabFormLocationSummary) => {
      const newNumBuildings = parseInt(locationSummary.numBuildings, 10);

      locationFormGroup.patchValue({
        address: locationSummary.address,
        features: locationSummary.features,
      });

      /**
       * If the new amount of buildings is less than the current number of buildings, then remove
       * extra buildings from the end of the array.
       *
       * If the new amount of buildings is more than the current number of buildings, then add the
       * corresponding number of buildings to the array.
       */
      const locationBuildingsFormArray = locationFormGroup.get('buildings') as UntypedFormArray;
      const currentNumBuildings = locationBuildingsFormArray.length;

      if (newNumBuildings === 0) {
        return;
      }

      while (newNumBuildings < locationBuildingsFormArray.length) {
        locationBuildingsFormArray.removeAt(locationBuildingsFormArray.length - 1);
      }
      while (newNumBuildings > locationBuildingsFormArray.length) {
        locationBuildingsFormArray.push(this.newBuilding());
      }
    });
  }

  public newBuilding(): UntypedFormGroup {
    const businessIncomeAndExtraExpensesGroup = this.formBuilder.group({
      coinsurancePercent: [null, Validators.required],
      incomeLimit: [null, Validators.required],
    });
    const contentsReplacementCostGroup = this.formBuilder.group({
      coinsurancePercent: [null, Validators.required],
      deductible: [null, Validators.required],
      replacementCostLimit: [null, Validators.required],
    });
    const electricPlumbingHVACUpdatedControl = this.formBuilder.control(null, [
      Validators.required,
    ]);
    const yearBuiltControl = this.formBuilder.control(null, [
      Validators.required,
      rangeValidator(1800, this.now.year()),
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
    ]);
    const yearRoofUpdatedControl = this.formBuilder.control(null, [
      Validators.required,
      rangeValidator(1800, this.now.year()),
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
    ]);

    const buildingFormGroup = this.formBuilder.group({
      buildingCoverageCoinsurance: [null, Validators.required],
      buildingCoverageDeductible: [null, Validators.required],
      businessIncomeAndExtraExpenseSelected: this.formBuilder.control(false),
      businessIncomeAndExtraExpense: businessIncomeAndExtraExpensesGroup,
      constructionType: [null, Validators.required],
      contentsReplacementCost: contentsReplacementCostGroup,
      contentsReplacementCostSelected: this.formBuilder.control(false),
      electricPlumbingHVACUpdated: electricPlumbingHVACUpdatedControl,
      hasRoofBeenUpdated: [null, Validators.required],
      isFullySprinklered: [null, Validators.required],
      numStories: [null, Validators.required],
      numUnits: [null, Validators.required],
      squareFeet: [null, Validators.required],
      replacementCost: [null, Validators.required],
      roofShape: [null, Validators.required],
      roofType: [null, Validators.required],
      smokeDetectorType: [null, Validators.required],
      windDeductible: [null],
      yearBuilt: yearBuiltControl,
      yearRoofUpdated: yearRoofUpdatedControl,
    });

    electricPlumbingHVACUpdatedControl.disable();
    yearBuiltControl.valueChanges.subscribe((yearBuilt: number) => {
      this.toggleEnabled(electricPlumbingHVACUpdatedControl, this.now.year() - yearBuilt > 20);
    });

    businessIncomeAndExtraExpensesGroup.disable();
    getControl(buildingFormGroup, 'businessIncomeAndExtraExpenseSelected').valueChanges.subscribe(
      (optedIn: boolean) => {
        this.toggleEnabled(businessIncomeAndExtraExpensesGroup, optedIn);
      }
    );

    /**
     * Add defaults from any blankets that the user's selected on the policy details page.
     */
    if (this.form) {
      const buildingCoverageBlanketOptedIn: boolean = getControl(
        this.form,
        'policyDetails.applyBuildingCoverageBlanket'
      ).value;
      if (buildingCoverageBlanketOptedIn) {
        getControl(buildingFormGroup, 'buildingCoverageCoinsurance').disable();
        getControl(buildingFormGroup, 'buildingCoverageDeductible').disable();
      }

      const businessIncomeBlanketOptedIn: boolean = getControl(
        this.form,
        'policyDetails.applyBusinessIncomeBlanket'
      ).value;
      if (businessIncomeBlanketOptedIn) {
        buildingFormGroup.patchValue({
          businessIncomeAndExtraExpenseSelected: true,
        });
        getControl(businessIncomeAndExtraExpensesGroup, 'coinsurancePercent').disable();
      }
    }

    contentsReplacementCostGroup.disable();
    getControl(buildingFormGroup, 'contentsReplacementCostSelected').valueChanges.subscribe(
      (optedIn: boolean) => {
        this.toggleEnabled(contentsReplacementCostGroup, optedIn);
      }
    );

    yearRoofUpdatedControl.disable();
    getControl(buildingFormGroup, 'hasRoofBeenUpdated').valueChanges.subscribe(
      (hasBeenUpdated: boolean) => {
        if (hasBeenUpdated) {
          yearRoofUpdatedControl.enable();
        } else {
          yearRoofUpdatedControl.disable();
        }
      }
    );

    return buildingFormGroup;
  }

  /**
   * Loss History Methods
   */
  public lossHistoryFormArray(): UntypedFormArray {
    return this.form.get('lossHistory.losses') as UntypedFormArray;
  }

  public newLoss(): UntypedFormGroup {
    const lossGroup = this.formBuilder.group({
      locationNum: ['1', Validators.required],
      lossDate: [null, Validators.required],
      lossType: [null, Validators.required],
      carrier: [null, Validators.required],
      otherCarrier: [null, Validators.required],
      claimStatus: [null, Validators.required],
      totalLossIncurred: [null, Validators.required],
    });

    const otherCarrier = getControl(lossGroup, 'otherCarrier');

    getControl(lossGroup, 'carrier').valueChanges.subscribe(
      (carrier: HabFormLossHistoryCarrier) => {
        if (carrier && carrier.name === 'Other') {
          otherCarrier.enable({ onlySelf: true });
        } else {
          otherCarrier.disable({ onlySelf: true });
        }
      }
    );

    return lossGroup;
  }

  public addLoss(): void {
    return this.lossHistoryFormArray().push(this.newLoss());
  }

  public removeLoss(index: number): void {
    return this.lossHistoryFormArray().removeAt(index);
  }

  /**
   * Utility Methods
   */
  private toggleEnabled(control: AbstractControl, shouldEnable: boolean) {
    if (shouldEnable) {
      control.enable();
    } else {
      control.disable();
    }
  }

  /**
   * Local Development Methods
   */
  public fillInHappyPath(): void {
    this.form.patchValue({
      guidelines: {
        areBuildingsBuiltAfter1980: true,
        isRiskApartmentBuildingsOnly: true,
        is4StoriesOrUnder: true,
        isBetween5And75Units: true,
        isAtLeast80PercentOccupied: true,
        isCompliantWithTivLimits: true,
        hasReadGuidelines: true,
      },
      policyDetails: {
        doesInsuredUsePropertyManagementFirm: true,
        effectiveDate: this.now.format(US_DATE_MASK),
        organizationType: 'corporation',
        locationSummaries: [
          {
            address: {
              addressLine1: '3600 West Ray Road',
              city: 'Chandler',
              state: 'AZ',
              zip: '85226',
            },
            features: {
              hasCommercialOccupancies: false,
              hasPaidParking: false,
              hasPool: false,
              hasPondOrLake: false,
              hasDivingBoard: false,
              hasClubhouseOrPartyRoom: false,
              hasPlayground: false,
              hasGymOrExerciseRoom: false,
              hasSportsFacilitiesOrCourts: false,
            },
            numBuildings: 1,
          },
        ],
      },
      lossHistory: {
        hasRiskHadPriorLosses: true,
        losses: [
          {
            locationNum: '1',
            lossDate: this.now.clone().day(-15).format(US_DATE_MASK),
            lossType: 'FIRE',
            carrier: {
              publicId: '5430',
              name: '21st Century Casualty Company',
            },
            claimStatus: 'Closed',
            totalLossIncurred: '$123',
          },
        ],
      },
      locations: [
        {
          address: {
            addressLine1: '3600 West Ray Road',
            city: 'Chandler',
            state: 'AZ',
            zip: '85226',
          },
          buildings: [
            {
              buildingCoverageCoinsurance: '90',
              buildingCoverageDeductible: '5000',
              businessIncomeAndExtraExpenseSelected: false,
              constructionType: 'FireResistive',
              contentsReplacementCostSelected: true,
              contentsReplacementCost: {
                coinsurancePercent: '100',
                deductible: '10000',
                replacementCostLimit: '$123,456',
              },
              electricPlumbingHVACUpdated: true,
              isFullySprinklered: true,
              hasRoofBeenUpdated: true,
              numStories: '2',
              numUnits: '15',
              squareFeet: '15000',
              replacementCost: '$1,000,000',
              roofShape: 'Hip',
              roofType: 'Metal',
              smokeDetectorType: 'HARDWIREDOR10YRLITHIUM',
              yearBuilt: '1990',
              yearRoofUpdated: '2010',
            },
          ],
          features: {
            hasCommercialOccupancies: false,
            hasPaidParking: false,
            hasPool: false,
            hasPondOrLake: false,
            hasDivingBoard: false,
            hasClubhouseOrPartyRoom: false,
            hasPlayground: false,
            hasGymOrExerciseRoom: false,
            hasSportsFacilitiesOrCourts: false,
          },
        },
      ],
      additionalCoverages: {
        [AdditionalCoverages.ORDINANCE_OR_LAW_DEMOLITION_COST]: '100000',
        [AdditionalCoverages.ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION]: '100000',
        [AdditionalCoverages.BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT]: '50000',
        [AdditionalCoverages.EMPLOYEE_THEFT_LIMIT]: '25000',
        [AdditionalCoverages.FORGERY_OR_ALTERATION_LIMIT]: '25000',
      },
      generalLiability: {
        perOccurenceAndAggregateLimitDisplayedValue: '$1M / $2M',
        perOccurrenceLimit: '1000000',
        aggregateLimit: '2000000',
        assaultAndBatterySelected: true,
        assaultAndBattery: {
          displayedValue: '$25,000 / $50,000',
          aggregateLimit: '50000',
          occurrenceLimit: '25000',
        },
        hiredNonOwnedAutoLiabilitySelected: true,
        hiredNonOwnedAutoLiability: {
          numDrivers: '12',
        },
        employeeBenefitsLiabilitySelected: true,
        employeeBenefitsLiability: {
          retroactiveDate: this.now.clone().day(-30).format(US_DATE_MASK),
          aggregateLimit: '2000000',
        },
      },
    });
  }
}
