import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import {
  DigitalBindRequest,
  FrontendQuote,
  QuoteResponse,
  Surcharge,
} from 'app/features/digital-carrier/models/types';
import { CoalitionCyberBindFormService } from 'app/features/coalition/services/coalition-cyber-bind-form.service';
import { ISO_DATE_MASK, US_DATE_MASK } from 'app/constants';
import { combineLatest, Subject, Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import * as _ from 'lodash';
import { SentryService } from 'app/core/services/sentry.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  AppError,
  COALITION_BIND_ERROR,
  COALITION_NON_BIND_ERROR,
  TIMEOUT_ERROR,
} from 'app/shared/quote-error-modal/errors';
import { ErrorModalEmittedEvent } from 'app/shared/quote-error-modal/quote-error-modal.component';
import {
  CoalitionCyberMarket,
  CyberProduct,
  PathCyberProduct,
} from '../../models/cyber-typings.model';
import {
  DECLINATION_REASONS,
  DECLINATION_REASONS_DISPLAY,
  pathToMarket,
  pathToProduct,
} from '../../models/cyber-constants.model';
import { UntypedFormArray } from '@angular/forms';
import { CoalitionCyberUpdateEffectiveDateService } from 'app/features/coalition/services/coalition-cyber-update-effective-date.service';
import {
  convertDateToUsFormat,
  convertUsDateIsoFormat,
} from 'app/shared/helpers/date-time-helpers';
import {
  getControl,
  getValidationMessageFromControl,
} from '../../../../shared/helpers/form-helpers';
import { AttuneEventName, SegmentService } from 'app/core/services/segment.service';

const numberToEnglish: Record<number, string> = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
};

@Component({
  selector: 'app-cyber-bind-form',
  templateUrl: './coalition-cyber-bind-form.component.html',
  providers: [CoalitionCyberBindFormService],
})
export class CoalitionCyberBindFormComponent implements OnInit, OnDestroy {
  @Input() quoteId: string;
  @Input() accountId: string;
  @Input() insAccount: InsuredAccount;
  @Input() faqs: (Faq & { isToggled?: boolean })[];

  quote: FrontendQuote;
  market: CoalitionCyberMarket;
  productType: CyberProduct;
  totalDollars: string;
  totalCents: string;
  surcharges: Surcharge[];
  loadingQuoteDetails = true;
  isRenewal: boolean;
  error: string;
  showProgressBar = false;
  submitted = false;
  errorModalOpen = false;
  bindSuccess$ = new Subject();
  address: Address;

  private originalEffectiveDate: string;

  typeOfError: AppError = COALITION_NON_BIND_ERROR;

  DECLINATION_REASONS = DECLINATION_REASONS;
  DECLINATION_REASONS_DISPLAY = DECLINATION_REASONS_DISPLAY;

  private sub: Subscription = new Subscription();

  constructor(
    private sentryService: SentryService,
    public formService: CoalitionCyberBindFormService,
    private amplitudeService: AmplitudeService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private cyberUpdateEffectiveDateService: CoalitionCyberUpdateEffectiveDateService,
    private router: Router,
    private route: ActivatedRoute,
    private segmentService: SegmentService
  ) {}

  ngOnInit() {
    const urlProduct = this.route.snapshot.url[2].path as PathCyberProduct;
    this.market = pathToMarket[urlProduct];
    this.productType = pathToProduct[urlProduct];

    this.updateValuesFromQuote();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateValuesFromQuote() {
    this.loadingQuoteDetails = true;
    combineLatest([
      this.digitalCarrierService.getQuoteDetails(this.quoteId),
      this.digitalCarrierService.getCoalitionCyberAddress(this.quoteId),
    ]).subscribe(([quoteDetails, address]) => {
      this.isRenewal = !!quoteDetails?.renewalDetails?.previousQuoteUuid;
      if (address !== null) {
        this.address = address;
      }

      if (quoteDetails?.product === 'cyber_surplus') {
        this.formService.insertContactInformation();

        // Do not inject the attestation forms for renewals
        if (!this.isRenewal) {
          if (address?.state) {
            this.formService.insertAttestation(address.state as string);

            if (this.isInTexasState()) {
              this.formService.insertTexasLicenseNumberQuestion();
            }
          }
        }
      }

      if (quoteDetails !== null) {
        if (quoteDetails.status !== 'quoted') {
          this.notifyNonQuotedQuoteStatus(quoteDetails);
          return;
        }

        const quoteCreationDateRaw = quoteDetails.createdAt;
        const quoteExpirationDate = moment(quoteCreationDateRaw).startOf('day').add(60, 'days');
        this.formService.setEffectiveDateValidators(quoteExpirationDate, this.isRenewal);

        const formattedDate = convertDateToUsFormat(quoteDetails.effectiveDate);
        this.originalEffectiveDate = formattedDate;
        this.formService.setEffectiveDate(formattedDate);
        this.quote = quoteDetails;

        this.surcharges = _.get(quoteDetails, 'surcharges', []) as Surcharge[];

        let totalSurcharges = 0;
        this.surcharges.forEach((surcharge) => {
          totalSurcharges += surcharge.amount;
        });

        const premiumPlusSurcharge = Number(this.quote.premium) + totalSurcharges;
        const totalRoundedToHundredth = Math.round(100 * premiumPlusSurcharge) / 100;

        const [dollars, cents] = this.splitDollarsAndCents(totalRoundedToHundredth);

        this.totalDollars = dollars;
        this.totalCents = (cents || '00').padEnd(2, '0');
        this.loadingQuoteDetails = false;
      } else {
        this.notifyGetQuoteDetailsFailure();
      }
    });
  }

  getCarriersFormArray(): UntypedFormArray | undefined {
    const declinationAttestation = this.formService.form.get('declinationAttestation');
    if (declinationAttestation) {
      return declinationAttestation.get('carriers') as UntypedFormArray;
    }
  }

  loadPreviousStep(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.formService.stepBackward();
  }

  sendSegmentEvent(eventName: AttuneEventName) {
    const address = {
      addressLine1: this.insAccount?.addressLine1,
      addressLine2: this.insAccount?.addressLine2,
      city: this.insAccount?.city,
      state: this.insAccount?.state,
      zip: this.insAccount?.zip,
    };

    this.segmentService.track({
      event: eventName,
      properties: {
        product: this.productType,
        carrier: 'coalition',
        class_code: this.quote.details?.classCode,
        naics_code: this.insAccount?.naicsCode,
        primary_state: this.insAccount?.state,
        insured_address: address,
        insured_email: this.insAccount?.emailAddress,
        business_name: this.insAccount?.companyName,
      },
    });
  }

  submit() {
    this.submitted = true;
    if (!this.formService.isCurrentStepValid()) {
      return;
    }

    if (this.formService.currentStep.slug === 'working-with-coalition') {
      this.formService.handleDoNotShowAgainSelection();
    }

    if (!this.formService.isFinalStep()) {
      this.formService.stepForward();
      this.submitted = false;
      return;
    }

    this.error = '';
    this.showProgressBar = true;
    this.sendSegmentEvent('Bind Attempted');

    const bindReq: DigitalBindRequest = {
      email: this.formService.getInsuredEmail(),
      attestationFormData: this.formService.form.value.declinationAttestation
        ? this.formService.form.value.declinationAttestation
        : null,
      firstName:
        this.quote.product === 'cyber_surplus' ? this.formService.getInsuredFirstName() : null,
      lastName:
        this.quote.product === 'cyber_surplus' ? this.formService.getInsuredLastName() : null,
      licenseNumber:
        this.address.state === 'TX' && this.quote.product === 'cyber_surplus'
          ? this.formService.getLicenseNumber()
          : null,
    };

    const effectiveDateOnForm = this.formService.getEffectiveDate();
    const shouldUpdateEffectiveDate = effectiveDateOnForm !== this.originalEffectiveDate;

    if (!shouldUpdateEffectiveDate) {
      this.bindRequest(bindReq);
      return;
    }

    const reformattedFormDate = convertUsDateIsoFormat(effectiveDateOnForm);
    this.cyberUpdateEffectiveDateService
      .updateEffectiveDate(this.quoteId, reformattedFormDate, this.productType as CyberProduct)
      .subscribe(
        (updateEffectiveDateResponse) => {
          if (updateEffectiveDateResponse.bindable) {
            this.bindRequest(bindReq);
          } else {
            this.notifyBindFailureDueToEffectiveDate(
              new Error(
                `Quote is not bindable by Coalition after effective date update to ${reformattedFormDate} from ${this.originalEffectiveDate} for ${this.market} policy`
              ),
              this.originalEffectiveDate,
              reformattedFormDate
            );
          }
        },
        (err) => {
          this.notifyBindFailureDueToEffectiveDate(
            err,
            this.originalEffectiveDate,
            reformattedFormDate
          );
        }
      );
  }

  private bindRequest(bindReq: DigitalBindRequest) {
    this.digitalCarrierService
      .bindQuote(
        bindReq,
        {
          product: this.productType,
          pasSource: 'coalition',
        },
        this.insAccount,
        this.quoteId
      )
      .subscribe(
        (quoteResp: HttpResponse<QuoteResponse>) => {
          if (quoteResp.status === 202) {
            // A 202 is returned from Service Quote if the request to Coalition's API times out.
            this.notifyBindTimeout();
            return;
          }
          const responseBody = quoteResp.body;
          if (responseBody?.success) {
            this.bindSuccess$.next(true);

            this.amplitudeService.track({
              eventName:
                this.productType === 'cyber_admitted'
                  ? `coalition_cyber_admitted_bind_success`
                  : `coalition_cyber_surplus_bind_success`,
              detail: JSON.stringify(responseBody),
            });

            timer(3000).subscribe(() => {
              this.showProgressBar = false;
              this.goBackToAccount();
            });
          } else {
            this.notifyBindFailure(
              new Error(`Failed to bind coalition cyber ${this.market} policy`)
            );
          }
        },
        (err) => {
          this.notifyBindFailure(err);
        }
      );
  }

  private notifyBindFailureDueToEffectiveDate(
    error: Error,
    originalEffectiveDate: string,
    newEffectiveDate: string
  ) {
    this.showProgressBar = false;
    this.amplitudeService.track({
      eventName:
        this.productType === 'cyber_admitted'
          ? `coalition_cyber_admitted_bind_error_modal`
          : `coalition_cyber_surplus_bind_error_modal`,
      detail: JSON.stringify({
        error,
        quoteId: this.quoteId,
        originalEffectiveDate,
        newEffectiveDate,
      }),
    });

    this.errorModalOpen = true;
    this.typeOfError = COALITION_BIND_ERROR;

    this.sentryService.notify(
      `Coalition Cyber: Error while updating effective date during bind for ${this.market} quote`,
      {
        severity: 'error',
        metaData: {
          error: error,
          accountId: this.accountId,
          product: this.productType,
          quoteId: this.quoteId,
          originalEffectiveDate,
          newEffectiveDate,
        },
      }
    );
  }

  private notifyBindFailure(error: Error) {
    this.showProgressBar = false;
    this.amplitudeService.track({
      eventName:
        this.productType === 'cyber_admitted'
          ? `coalition_cyber_admitted_bind_error_modal`
          : `coalition_cyber_surplus_bind_error_modal`,
      detail: JSON.stringify(error),
    });

    this.errorModalOpen = true;
    this.typeOfError = COALITION_BIND_ERROR;

    this.sentryService.notify(`Coalition Cyber: Error while trying to bind ${this.market} quote`, {
      severity: 'error',
      metaData: {
        error: error,
        accountId: this.accountId,
        product: this.productType,
      },
    });
  }

  private notifyBindTimeout() {
    this.showProgressBar = false;
    this.amplitudeService.track({
      eventName: 'coalition_cyber_bind_timeout_modal',
      detail: this.productType,
      useLegacyEventName: true,
    });

    this.errorModalOpen = true;
    this.typeOfError = TIMEOUT_ERROR;
  }

  private notifyGetQuoteDetailsFailure() {
    this.amplitudeService.track({
      eventName:
        this.productType === 'cyber_admitted'
          ? `coalition_cyber_admitted_bind_error_modal`
          : `coalition_cyber_surplus_bind_error_modal`,
      detail: 'Coalition Cyber: failed to get quote details',
    });

    this.errorModalOpen = true;
    this.typeOfError = COALITION_NON_BIND_ERROR;
  }

  private notifyNonQuotedQuoteStatus(quoteDetails: FrontendQuote) {
    this.amplitudeService.track({
      eventName:
        this.productType === 'cyber_admitted'
          ? `coalition_cyber_admitted_bind_error_modal`
          : `coalition_cyber_surplus_bind_error_modal`,
      detail: 'Coalition Cyber: retrieved quote for bind did not have quoted status',
      useLegacyEventName: true,
    });

    this.errorModalOpen = true;
    this.typeOfError = COALITION_NON_BIND_ERROR;

    this.sentryService.notify(
      'Coalition Cyber: retrieved quote for bind did not have quoted status',
      {
        severity: 'error',
        metaData: { quoteDetails, product: this.productType },
      }
    );
  }

  public closeOrRetry(flow: ErrorModalEmittedEvent) {
    if (flow.close && flow.retry) {
      this.retryBind();
    }
    if (!flow.close && !flow.retry) {
      this.goBackToAccount();
    }
  }

  private goBackToAccount() {
    this.router.navigate(['accounts', this.accountId], { replaceUrl: true });
  }

  private retryBind() {
    this.errorModalOpen = false;
  }

  splitDollarsAndCents(price: number): string[] {
    return price.toString().split('.', 2);
  }

  formatEffectiveDate(date: string): string {
    return moment(date, ISO_DATE_MASK).format(US_DATE_MASK);
  }

  arabicNumeralToEnglish(n: number): string {
    return numberToEnglish[n];
  }

  isFirstStep() {
    return this.formService.isFirstStep();
  }

  isInTexasState() {
    return this.address?.state === 'TX';
  }

  isSurplusQuote() {
    return this.quote?.product === 'cyber_surplus';
  }

  effectiveDateErrorMessage(): string | null {
    const effectiveDateControl = getControl(this.formService.form, 'bind.effectiveDate');
    if (effectiveDateControl) {
      return getValidationMessageFromControl(effectiveDateControl);
    }

    return null;
  }
}
