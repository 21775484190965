<section class="appetite-search-results">
  <h2>Search Result</h2>
  <table class="appetite-search-results-table">
    <thead>
      <tr>
        <th class="class-column">Class</th>
        <th>Eligible products</th>
        <th class="select-column"></th>
      </tr>
    </thead>
    <tbody class="tbody__striped">
      <tr>
        <td class="td-align-top">
          <p class="mb-0 ml-1">{{ naicsCode.description }}</p>
          <p class="p__gray mb-0 ml-1">
            {{ naicsCode.code }}
          </p>
        </td>

        <td *ngIf="productAvailabities.length > 0" class="eligible-products-td">
          <app-product-badge
            *ngFor="let product of productAvailabities"
            [product]="product.productCombination.product"
            [pasSource]="product.productCombination.pasSource"
          ></app-product-badge>
        </td>

        <td *ngIf="productAvailabities.length > 0" class="td-align-top">
          <div class="get-quote-cell">
            <a
              class="get-quote-link"
              (click)="selectClassCode(naicsCode.code, naicsCode.description, naicsCode.hash)"
              (keyup.enter)="selectClassCode(naicsCode.code, naicsCode.description, naicsCode.hash)"
            >
              Get a Quote
            </a>
          </div>
        </td>

        <td>
          <div *ngIf="productAvailabities.length === 0" class="eligible-products-td">
            <p style="width: 16em">No eligible LOBs available</p>
          </div>
        </td>

        <td *ngIf="productAvailabities.length === 0" class="td-align-top mr-4">
          <div class="get-quote-cell">
            <a
              class="get-quote-link-disabled gray-text"
              (click)="selectClassCode(naicsCode.code, naicsCode.description, naicsCode.hash)"
              (keyup.enter)="selectClassCode(naicsCode.code, naicsCode.description, naicsCode.hash)"
            >
              Get a Quote
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</section>
