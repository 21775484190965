<h3 class="h3 h3__support h3__no-margin">Search Topics</h3>
<form [formGroup]="form" (ngSubmit)="handleSubmit($event)" novalidate>
  <app-form-field-text
    [form]="form"
    [inputId]="inputId"
    [nameOfFormControl]="nameOfFormControl"
    placeholder="Find answers"
    questionNote="Find answers to frequently asked questions <a href='https://attunehelp.zendesk.com' target='_blank'>here</a>."
    cssClass="form-field__no-margin search-field"
  >
    <span
      class="search-field-send-icon"
      (click)="handleSubmit($event)"
      (keypress)="handleSubmit($event)"
    >
      <img src="/assets/img/send_icon.svg" alt="send-request" />
    </span>
  </app-form-field-text>
</form>
