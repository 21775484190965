import { API_V3_BASE } from 'app/constants';

export enum AdditionalCoverages {
  ORDINANCE_OR_LAW_DEMOLITION_COST = 'ordinanceOrLawDemolitionCost',
  ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION = 'ordinanceOrLawIncreasedCostofConstruction',
  BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT = 'belowGroundWaterAndBackupOfSewerAndDrainLimit',
  EMPLOYEE_THEFT_LIMIT = 'employeeTheftLimit',
  FORGERY_OR_ALTERATION_LIMIT = 'forgeryOrAlterationLimit',
}

export const CoverageOptions = {
  [AdditionalCoverages.ORDINANCE_OR_LAW_DEMOLITION_COST]: [1e5, 1.5e5, 2e5, 2.5e5],
  [AdditionalCoverages.ORDINANCE_OR_LAW_INCREASED_COST_OF_CONSTRUCTION]: [1e5, 1.5e5, 2e5, 2.5e5],
  [AdditionalCoverages.BELOW_GROUND_WATER_AND_BACKUP_OF_SEWER_AND_DRAIN_LIMIT]: [
    2.5e4, 5e4, 1e5, 1.5e5, 2e5, 2.5e5,
  ],
  [AdditionalCoverages.EMPLOYEE_THEFT_LIMIT]: [2.5e4, 5e4, 1e5, 1.5e5, 2.5e5],
  [AdditionalCoverages.FORGERY_OR_ALTERATION_LIMIT]: [2.5e4, 5e4, 1e5, 1.5e5, 2.5e5],
};

export enum AssaultAndBatteryCoverageOptionValues {
  OPTION_10K_20K = '$10,000 / $20,000',
  OPTION_25K_50K = '$25,000 / $50,000',
}

export const assaultAndBatteryCoverageOptions: { [key: string]: string } = {
  '': 'Choose one',
  [AssaultAndBatteryCoverageOptionValues.OPTION_10K_20K]:
    AssaultAndBatteryCoverageOptionValues.OPTION_10K_20K,
  [AssaultAndBatteryCoverageOptionValues.OPTION_25K_50K]:
    AssaultAndBatteryCoverageOptionValues.OPTION_25K_50K,
};

export const API_V3_HAB_QUOTES_URI = `${API_V3_BASE}/quotes/hab`;

export const DISABLED_PAYMENT_OPTION = 'Ten payments (Coming soon)';

export const HAB_POLICY_PAYMENT_PLAN_OPTIONS = {
  'One-time payment': 1,
  'Ten payments (Coming soon)': 10,
};

export const DEBOUNCE_TIME = 200;
