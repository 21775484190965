import { Directive } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

@Directive({
  selector: '[appFeinMask]',
})
export class FeinMaskDirective extends BaseMaskDirective {
  getMask(value: string) {
    const regex = /^\d{2}-\d{7}$/;

    // Not sure why this cast is nessecary but it's not recognizing value as a string without it - Chris
    value = String(value);

    if (regex.test(value)) {
      return value;
    }

    const numberString = value.replace(/\D+/g, '');

    if (numberString.length > 2) {
      return numberString.slice(0, 2) + '-' + numberString.slice(2, 9);
    }

    return numberString;
  }
}
