<section [ngClass]="{ 'form-animation': isChangingRoutes }">
  <h1 class="form-header-below-link">Loc {{ locationId }} / Bldg {{ buildingId }}</h1>

  <h2 class="form-section-header">Building exposure</h2>
  <section class="js-hab-location-{{ locationId }}-building-{{ buildingId }}-section">
    <div class="form-split">
      <app-form-field-text
        inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-numUnits"
        labelText="Total number of units"
        [form]="form"
        [submitted]="hasSubmitted()"
        nameOfFormControl="numUnits"
        appNumberMask
      >
      </app-form-field-text>

      <app-form-field-text
        inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-yearBuilt"
        labelText="Year built"
        [form]="form"
        [submitted]="hasSubmitted()"
        nameOfFormControl="yearBuilt"
        appNumberMask
      >
      </app-form-field-text>
    </div>

    <div class="form-split">
      <app-form-field-text
        inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-squareFeet"
        labelText="Occupied area (square feet)"
        [form]="form"
        [submitted]="hasSubmitted()"
        nameOfFormControl="squareFeet"
        appDelimitedNumberMask
      >
      </app-form-field-text>

      <app-form-field-select
        inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-constructionType"
        labelText="Construction type"
        placeholder="Choose one"
        [form]="form"
        [submitted]="hasSubmitted()"
        [availableOptions]="habConstructionTypeOptions"
        nameOfFormControl="constructionType"
      >
      </app-form-field-select>
    </div>

    <div class="form-split">
      <app-form-field-select
        inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-numStories"
        labelText="Number of stories"
        placeholder="Choose one"
        [form]="form"
        [submitted]="hasSubmitted()"
        [availableOptions]="habNumStoriesOptions"
        nameOfFormControl="numStories"
      >
      </app-form-field-select>

      <app-form-field-select
        inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-roofType"
        labelText="Roof type"
        placeholder="Choose one"
        [form]="form"
        [submitted]="hasSubmitted()"
        [availableOptions]="habRoofTypeOptions"
        nameOfFormControl="roofType"
      >
      </app-form-field-select>
    </div>

    <app-form-field-radio-image
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-roofShape"
      questionText="Roof shape"
      nameOfFormControl="roofShape"
      [submitted]="hasSubmitted()"
      [options]="habRoofShapeRadioOptions"
      [form]="form"
    >
    </app-form-field-radio-image>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      [cssClass]="indentedFieldMarginCss('yearRoofUpdated')"
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-hasRoofBeenUpdated"
      questionText="Has the roof been updated?"
      nameOfFormControl="hasRoofBeenUpdated"
      [submitted]="hasSubmitted()"
      [form]="form"
    >
    </app-form-field-radio>

    <app-form-field-text
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-yearRoofUpdated"
      cssClass="form-field__indent"
      labelText="What year was it updated?"
      [form]="form"
      [submitted]="hasSubmitted()"
      nameOfFormControl="yearRoofUpdated"
      appNumberMask
    >
    </app-form-field-text>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-electricPlumbingHVACUpdated"
      questionText="Have HVAC equipment/appliances been updated?"
      nameOfFormControl="electricPlumbingHVACUpdated"
      [submitted]="hasSubmitted()"
      [form]="form"
    >
    </app-form-field-radio>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-isFullySprinklered"
      questionText="Is the building 100% sprinklered?"
      nameOfFormControl="isFullySprinklered"
      [submitted]="hasSubmitted()"
      [form]="form"
    >
    </app-form-field-radio>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-smokeDetectorType"
      questionText="Smoke detector type"
      nameOfFormControl="smokeDetectorType"
      [submitted]="hasSubmitted()"
      [form]="form"
      [options]="habSmokeDetectorOptions"
    >
    </app-form-field-radio>

    <h2 class="form-section-header">Building coverage</h2>
    <app-form-field-text
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-replacementCost"
      labelText="Building Replacement Cost"
      placeholder="$"
      [form]="form"
      [submitted]="hasSubmitted()"
      nameOfFormControl="replacementCost"
      appMoneyMask="WITHOUT_DECIMAL"
    >
    </app-form-field-text>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-buildingCoverageDeductible"
      questionText="Building Coverage (AOP) Deductible"
      nameOfFormControl="buildingCoverageDeductible"
      [submitted]="hasSubmitted()"
      [form]="form"
      [options]="habBuildingCoverageDeductibleOptions"
    >
    </app-form-field-radio>

    <app-form-field-select
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-windDeductible"
      labelText="Wind Deductible"
      placeholder="No separate wind deductible"
      [form]="form"
      [submitted]="hasSubmitted()"
      [availableOptions]="habWindDeductibleOptions"
      nameOfFormControl="windDeductible"
      [readonly]="true"
    >
    </app-form-field-select>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-buildingCoverageCoinsurance"
      questionText="Building Coverage Coinsurance"
      nameOfFormControl="buildingCoverageCoinsurance"
      [submitted]="hasSubmitted()"
      [form]="form"
      [options]="habBuildingCoverageCoinsuranceOptions"
    >
    </app-form-field-radio>

    <!-- Start: Contents Replacement Cost Group -->
    <app-form-field-checkbox
      inputId="hab-location-{{ locationId }}-building-{{
        buildingId
      }}-contentsReplacementCostSelected"
      [cssClass]="indentedFieldMarginCss('contentsReplacementCost')"
      labelText="Contents Replacement Cost"
      nameOfFormControl="contentsReplacementCostSelected"
      [submitted]="hasSubmitted()"
      [form]="form"
    >
    </app-form-field-checkbox>

    <app-form-field-text
      inputId="hab-location-{{ locationId }}-building-{{ buildingId }}-contentsReplacementCostLimit"
      cssClass="form-field__no-margin form-field__indent"
      labelText="Contents Replacement Cost Limit"
      placeholder="$"
      [form]="form.get('contentsReplacementCost')"
      [submitted]="hasSubmitted()"
      nameOfFormControl="replacementCostLimit"
      appMoneyMask="WITHOUT_DECIMAL"
    >
    </app-form-field-text>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      cssClass="form-field__no-margin form-field__indent form-field__indent-sibling"
      inputId="hab-location-{{ locationId }}-building-{{
        buildingId
      }}-contentsReplacementCostDeductible"
      questionText="Contents Coverage Deductible"
      nameOfFormControl="deductible"
      [submitted]="hasSubmitted()"
      [form]="form.get('contentsReplacementCost')"
      [options]="habContentsCoverageDeductibleOptions"
    >
    </app-form-field-radio>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      cssClass="form-field__indent form-field__indent-sibling"
      inputId="hab-location-{{ locationId }}-building-{{
        buildingId
      }}-contentsReplacementCostCoinsurancePercent"
      questionText="Contents Coverage Coinsurance"
      nameOfFormControl="coinsurancePercent"
      [submitted]="hasSubmitted()"
      [form]="form.get('contentsReplacementCost')"
      [options]="habContentsCoverageCoinsuranceOptions"
    >
    </app-form-field-radio>
    <!-- End: Contents Replacement Cost Group -->

    <!-- Start: Business Income and Extra Expense Including Rental Value Group -->
    <app-form-field-checkbox
      inputId="hab-location-{{ locationId }}-building-{{
        buildingId
      }}-businessIncomeAndExtraExpenseSelected"
      [cssClass]="indentedFieldMarginCss('businessIncomeAndExtraExpense')"
      labelText="Business Income and Extra Expense including Rental Value"
      nameOfFormControl="businessIncomeAndExtraExpenseSelected"
      [submitted]="hasSubmitted()"
      [form]="form"
    >
    </app-form-field-checkbox>

    <app-form-field-text
      inputId="hab-location-{{ locationId }}-building-{{
        buildingId
      }}-businessIncomeAndExtraExpenseLimit"
      cssClass="form-field__no-margin form-field__indent"
      labelText="Business Income Limit"
      placeholder="$"
      [form]="form.get('businessIncomeAndExtraExpense')"
      [submitted]="hasSubmitted()"
      nameOfFormControl="incomeLimit"
      appMoneyMask="WITHOUT_DECIMAL"
    >
    </app-form-field-text>

    <app-form-field-radio
      questionCssClass="radio-question__small"
      optionsCssClass="radio-question-option__small"
      cssClass="form-field__indent form-field__indent-sibling"
      inputId="hab-location-{{ locationId }}-building-{{
        buildingId
      }}-businessIncomeAndExtraExpenseCoinsurancePercent"
      questionText="Business Income Coinsurance"
      nameOfFormControl="coinsurancePercent"
      [submitted]="hasSubmitted()"
      [form]="form.get('businessIncomeAndExtraExpense')"
      [options]="habBusinessIncomeCoinsuranceOptions"
    >
    </app-form-field-radio>
    <!-- End: Business Income and Extra Expense Including Rental Value Group -->
  </section>
</section>
