import { OnInit, Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LinkStatus } from './policy-automation-landing.component';
import * as moment from 'moment';
import {
  cancellationReason,
  CancellationResponseInfo,
  PolicyAutomationLandingService,
  RefundMethod,
} from '../../services/policy-automation-landing.service';
import { AmplitudeService } from '../../../core/services/amplitude.service';
import { DocumentService } from '../../../features/documents/services/document.service';
import { getAttuneBopCancellationFileName } from '../../../features/documents/models/document-file-names.model';

export const REFUND_METHOD_DESCRIPTION: { [key in RefundMethod]?: string } = {
  flat: 'flat',
  prorata: 'pro-rata',
  shortrate: 'short-rate',
};

// The only cancellation reasons valid for automation (and would show up in this link) are Business Closed, Business Sold, and Replaced Coverage
export const CANCEL_REASON_DESCRIPTION: { [key in cancellationReason]?: string } = {
  businessClosed: 'business closed',
  businessSold: 'business sold',
  noc: "Insured's request",
  nofincononpay_HUSA: "Insured's request",
  nottaken: 'Renewal not taken',
};

@Component({
  selector: 'app-cancel-landing',
  templateUrl: './cancel-landing.component.html',
})
export class CancelLandingComponent implements OnInit, OnDestroy {
  @Input() cancelResponseInfo: CancellationResponseInfo;
  @Input() linkStatus: LinkStatus;
  @Input() linkId: string;
  @Input() emailAddress: string;
  @Output() statusChange = new EventEmitter<LinkStatus>();
  isConfirmingCancellation = false;
  isWithdrawingCancellation = false;

  policyDocumentDownload$: Observable<any>;
  policyDocumentDownloadReady$: Observable<any>;

  private sub = new Subscription();

  constructor(
    private amplitudeService: AmplitudeService,
    private landingService: PolicyAutomationLandingService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    if (this.linkStatus === LinkStatus.CONFIRM) {
      this.preparePolicyDoc();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  confirmCancellation() {
    this.isConfirmingCancellation = true;
    this.sub.add(
      this.landingService.confirmLink(this.linkId).subscribe((result) => {
        this.isConfirmingCancellation = false;
        if (result) {
          this.preparePolicyDoc();
          this.statusChange.emit(LinkStatus.CONFIRM);
        } else {
          this.statusChange.emit(LinkStatus.ERROR);
        }
      })
    );
  }

  withdrawCancellation() {
    this.isWithdrawingCancellation = true;
    this.sub.add(
      this.landingService.withdrawLink(this.linkId).subscribe((result) => {
        this.isWithdrawingCancellation = false;
        if (result) {
          this.statusChange.emit(LinkStatus.WITHDRAW);
        } else {
          this.statusChange.emit(LinkStatus.ERROR);
        }
      })
    );
  }

  preparePolicyDoc() {
    this.sub.add(
      this.landingService.getPolicyDocLink(this.linkId).subscribe((result: any) => {
        if (result) {
          const fileName = getAttuneBopCancellationFileName(this.cancelResponseInfo.policyNumber);
          this.policyDocumentDownload$ = this.documentService.getDocument(
            result.link,
            fileName,
            'pdf'
          );
          this.policyDocumentDownloadReady$ = this.documentService.pollDocument(result.link);
        }
      })
    );
  }

  getEffectiveDate() {
    return moment(this.cancelResponseInfo.effectiveDate).utc().format('DD MMM YYYY');
  }

  getCancellationReason() {
    return CANCEL_REASON_DESCRIPTION[this.cancelResponseInfo.cancellationReason];
  }

  getRefundMethod() {
    return REFUND_METHOD_DESCRIPTION[this.cancelResponseInfo.refundMethod];
  }

  isReviewStatus() {
    return this.linkStatus === LinkStatus.REVIEW;
  }

  isConfirmStatus() {
    return this.linkStatus === LinkStatus.CONFIRM;
  }
}
