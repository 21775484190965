<table class="price-comparison-table">
  <thead>
    <tr>
      <th class="price-comparison-table-header"></th>
      <th class="price-comparison-table-header">
        <p class="p__bold price-comparison-table-header-text">Active Cyber Policy</p>
        <p class="h4 p__primary-text" *ngIf="!!activeCyberPrice">
          Starting at ${{ activeCyberPrice }}/Month*
        </p>
      </th>
      <th class="price-comparison-table-header">
        <p class="price-comparison-table-header-text">Standard Endorsement</p>
        <p class="price-comparison-table-header-subtext p__primary-text" *ngIf="!!endorsementPrice">
          Starting at ${{ endorsementPrice }}/Month*
        </p>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="price-comparison-table-header-row">
      <td class="price-comparison-table-cell">
        <h4 class="mt-0 mb-0">Coverages</h4>
      </td>
      <td></td>
      <td></td>
    </tr>
    <tr class="price-comparison-table-data-row">
      <td class="price-comparison-table-cell">Breach response services</td>
      <td class="price-comparison-table-cell">
        Coverage continues even if no personal data compromised
      </td>
      <td class="price-comparison-table-cell">
        Coverage ceases when no personal data is compromised
      </td>
    </tr>
    <tr class="price-comparison-table-data-row">
      <td class="price-comparison-table-cell">Crisis management + PR</td>
      <td class="price-comparison-table-cell">Coverage for any claim</td>
      <td class="price-comparison-table-cell">Covers only data compromise</td>
    </tr>
    <tr class="price-comparison-table-data-row">
      <td class="price-comparison-table-cell">Funds transfer fraud</td>
      <td class="price-comparison-table-cell">Via endorsement</td>
      <td class="price-comparison-table-cell">
        <img class="price-comparison-table-image-cell" src="/assets/img/x.svg" alt="" />
      </td>
    </tr>
    <tr class="price-comparison-table-data-row">
      <td class="price-comparison-table-cell">Ransomware & cyber extortion</td>
      <td class="price-comparison-table-cell">No sublimit</td>
      <td class="price-comparison-table-cell">Sublimited</td>
    </tr>
    <tr class="price-comparison-table-header-row">
      <td class="price-comparison-table-cell">
        <h4 class="mt-0 mb-0">Services</h4>
      </td>
      <td></td>
      <td></td>
    </tr>
    <tr class="price-comparison-table-data-row">
      <td class="price-comparison-table-cell">24/7 access to breach response team</td>
      <td class="price-comparison-table-cell">
        <img class="price-comparison-table-image-cell" src="/assets/img/check.svg" alt="" />
      </td>
      <td class="price-comparison-table-cell">
        <img class="price-comparison-table-image-cell" src="/assets/img/x.svg" alt="" />
      </td>
    </tr>
    <tr class="price-comparison-table-data-row">
      <td class="price-comparison-table-cell">Free domain scanning and alerting</td>
      <td class="price-comparison-table-cell">
        <img class="price-comparison-table-image-cell" src="/assets/img/check.svg" alt="" />
      </td>
      <td class="price-comparison-table-cell">
        <img class="price-comparison-table-image-cell" src="/assets/img/x.svg" alt="" />
      </td>
    </tr>
    <tr class="price-comparison-table-data-row">
      <td class="price-comparison-table-cell">Free employee cyber security training</td>
      <td class="price-comparison-table-cell">
        <img class="price-comparison-table-image-cell" src="/assets/img/check.svg" alt="" />
      </td>
      <td class="price-comparison-table-cell">
        <img class="price-comparison-table-image-cell" src="/assets/img/x.svg" alt="" />
      </td>
    </tr>
  </tbody>
</table>
