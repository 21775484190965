import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from 'app/app.routes';
import { SharedModule } from 'app/shared/shared.module';
import { UnsubscribeComponent } from 'app/shared/unsubscribe/unsubscribe.component';
import { UnsubscribeService } from 'app/shared/services/unsubscribe.service';

@NgModule({
  declarations: [UnsubscribeComponent],
  exports: [UnsubscribeComponent],
  imports: [CommonModule, routing, SharedModule],
  providers: [UnsubscribeService],
})
export class UnsubscribeModule {}
