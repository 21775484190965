import { ValidatorFn, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { getControl } from '../../../shared/helpers/form-helpers';
import { parseMoney } from '../../../shared/helpers/number-format-helpers';
import {
  LmCpspFormStepPath,
  LmCpspValidators,
  LmCpspQuestion,
  LmCpspComplexValidators,
  LmCpspLossHistoryQuestion,
  LmCpspArrayValidators,
} from './lm-cpsp-typings';
import { PHONE_FORM_FIELDS, YEAR_FORM_FIELDS } from './lm-cpsp-questions';
import { validateDate } from 'app/features/attune-bop/models/form-validators';
import {
  validateLmEffectiveDate,
  setPhoneValidators,
  setYearValidators,
  valueIsGreaterThanZero,
  valueIsValidFederalInsuranceNumber,
  validateMinMaxNumber,
  validateLmLossDate,
} from './lm-shared-validators';
import { cpspControlPath } from './lm-cpsp-constants';
import { LOCATION_RISK_STATE_ERROR_MESSAGE } from './constants';

const validateBppOrBuildingLimitIsNonZero: ValidatorFn = (locationDetailsGroup) => {
  const bppControl = getControl(
    locationDetailsGroup as UntypedFormGroup,
    LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY
  );
  const buildingLimitControl = getControl(
    locationDetailsGroup as UntypedFormGroup,
    LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING
  );

  if (parseMoney(bppControl.value) > 0 || parseMoney(buildingLimitControl.value) > 0) {
    return null;
  }

  return {
    bppAndBuildingLimitAreZero: {
      validationMessage: 'Business personal property limit and/or Building limit is required.',
    },
  };
};

const validateLocationStateMatchesRiskState = (form: UntypedFormGroup): ValidatorFn => {
  const primaryRiskStateControl = getControl(
    form,
    cpspControlPath(LmCpspQuestion.PRIMARY_RISK_STATE)
  );

  return (addressFormGroup: UntypedFormGroup) => {
    const addressValue = addressFormGroup.value as Address;
    const stateFieldIsIncomplete = _.isNull(addressValue.state) || addressValue.state === '';
    if (stateFieldIsIncomplete || addressValue.state === primaryRiskStateControl.value) {
      return null;
    }

    return {
      locationStateAndRiskStateMismatch: {
        validationMessage: LOCATION_RISK_STATE_ERROR_MESSAGE,
      },
    };
  };
};

// Set phone validators for CPSP phone form fields
const PHONE_FORM_VALIDATORS = setPhoneValidators(PHONE_FORM_FIELDS);

// Set year validators for CPSP year form fields
const YEAR_VALIDATORS = setYearValidators(YEAR_FORM_FIELDS);

/**
 * A dictionary of validators for fields implemented as FormArrays. The keys are the names
 * of these fields, and each value is a dictionary of validators for each element (form group) of
 * the FormArray.
 *
 * When a new FormGroup is pushed into the FormArray, this dictionary can be used to set all of the
 * relevant validators on that FormGroup
 */
export const LM_CPSP_FORM_ARRAY_VALIDATORS: DeepPartial<LmCpspArrayValidators> = {
  [LmCpspQuestion.LOSSES]: {
    [LmCpspLossHistoryQuestion.DATE_OF_LOSS]: [validateDate, validateLmLossDate],
  },
};

/**
 * A dictionary of validators. If the key is ...
 *  - the name of a control => the validation relies only on the control's value
 *  - the name of a step => the validator is set on the step's form group; validation
 *      relies on more than one control.
 */
export const LM_CPSP_VALIDATORS: Partial<LmCpspValidators> = {
  // Step/inter-control validators
  [LmCpspFormStepPath.LOCATION_DETAILS]: [validateBppOrBuildingLimitIsNonZero],
  // Single-control validators
  // TODO: add some!
  [LmCpspQuestion.EFFECTIVE_DATE]: [validateDate, validateLmEffectiveDate],
  [LmCpspQuestion.ANNUAL_SALES_RECEIPTS]: [valueIsGreaterThanZero],
  [LmCpspQuestion.NUMBER_OF_STORIES]: [valueIsGreaterThanZero],
  [LmCpspQuestion.EXPOSURE_LIQUOR_LIABILITY]: [valueIsGreaterThanZero],
  [LmCpspQuestion.FEDERAL_ID]: [valueIsValidFederalInsuranceNumber],
  [LmCpspQuestion.CERAMIC_FLOOR_PERCENT_REFER]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.CLEANING_KITCHEN_FLUES_HOODS_PERCENT_REFER]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.DEMOLITION_PERCENT]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.FOOD_TRUCK_PERCENTAGE]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.HOSPITAL_WORK_PERCENT]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.JANITORIAL_DEBRIS_CLEAN_UP_PERCENT_REFER]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.LIQUOR_PERCENT_OF_SALES]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.LP_GAS_WORK_PERCENT]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.SPRINKLER]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.SUB_CONTRACTORS_PERCENT]: [validateMinMaxNumber(0, 100)],
  [LmCpspQuestion.WATER_CONNECTIONS]: [validateMinMaxNumber(0, 100)],
  ...PHONE_FORM_VALIDATORS,
  ...YEAR_VALIDATORS,
};

export const LM_CPSP_COMPLEX_VALIDATORS: Partial<LmCpspComplexValidators> = {
  [LmCpspQuestion.ADDRESS]: [validateLocationStateMatchesRiskState],
};
