import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SentryService } from '../../core/services/sentry.service';
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ZENDESK_GET_ACCOUNT_API } from '../../constants';
import { InsuredAccount } from '../../features/insured-account/models/insured-account.model';

@Injectable()
export class ZendeskApiService {
  // Service for connecting to Service-Quote's Zendesk-facing API endpoints.
  // These services can only be accessed via a token generated by the Zendesk Support app.
  // Methods here should not be used in normal pages--only on pages specifically built
  // for the sake of compatibility with the Zendesk app.

  constructor(private http: HttpClient, private sentryService: SentryService) {}

  fetchAccountInformation(accountNumber: string, token: string) {
    return this.http
      .get<any>(ZENDESK_GET_ACCOUNT_API + accountNumber, { headers: { 'X-AUTH-TOKEN': token } })
      .pipe(
        map((response) => {
          return InsuredAccount.fromV3ApiPayload(response);
        }),
        catchError((error) => {
          this.sentryService.notify('Error fetching insured account.', {
            severity: 'error',
            metaData: {
              accountNumber,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableThrowError(error);
        })
      );
  }
}
