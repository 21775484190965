<div [ngClass]="{ 'policy-pane-content': !isBundle }">
  <div class="policy-pane-nav">
    <a *ngIf="!isBundle" class="button button__nav button__no-margin" [routerLink]="['../../..']">
      <span class="icon-arrow-left button-icon"></span>
      Back
    </a>

    <ng-container *ngIf="!isBindBlockedQuote && model | async as loadedModel">
      <div class="policy-pane-nav-buttons" *ngIf="!isBundle && !shouldDisplayRequoteButton">
        <ng-container>
          <a
            class="button button__small button__secondary button__no-margin js-quote-archive-button"
            (click)="showAchivingConfirmationPanel()"
            (keyup.enter)="showAchivingConfirmationPanel()"
          >
            Archive
          </a>

          <ng-container>
            <button
              *ngIf="locationHasMultipleBuildingsOrClassifications || isGrandfathered()"
              (click)="showEditUnavailableMessage()"
              class="button button__small button__secondary button__no-margin js-go-to-edit"
              [disabled]="!canEditBOPQuote(loadedModel)"
            >
              Edit
            </button>
            <button
              *ngIf="!locationHasMultipleBuildingsOrClassifications && !isGrandfathered()"
              class="button button__small button__secondary button__no-margin js-go-to-edit"
              [disabled]="locationsMissingInfo.length > 0 || !canEditBOPQuote(loadedModel)"
              routerLink="edit"
            >
              Edit
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="loadedModel.bindable">
          <button
            class="button button__small button__primary button__no-margin js-go-to-bind"
            (click)="continueToBind()"
            [disabled]="
              requiresAdditionalInfo(loadedModel) ||
              isBopV1QuoteInBopV2State(loadedModel) ||
              isEligibilityOverride()
            "
            [ngClass]="{ button__discouraged: nonBindingRole }"
          >
            Continue to bind
          </button>
        </ng-container>
      </div>

      <app-dialog-box
        *ngIf="(!isWaterSupplyVerified || !isEmployeeCountVerified) && disableRolloverRequote"
        type="warning"
        header="Changes required to bind"
        class="js-dialog-text dialog-text__margin-top dialog-text__full-width policy-pane-dialog"
      >
        Binding is unavailable for this quote until you resolve the following:
        <ul class="bulleted-list">
          <li *ngIf="!isEmployeeCountVerified" class="policy-pane-bind-block-list">
            Designate 1 or more employees or remove EPLI/EBL coverage.
          </li>
          <li *ngIf="!isWaterSupplyVerified" class="policy-pane-bind-block-list">
            Provide an answer to “Is this location within 1000 feet of a fire hydrant?” on the
            Location step.
          </li>
        </ul>

        To edit and bind this quote, call us at
        <a href="tel:8885304650" class="js-call-ccc dialog-text-link">888-530-4650</a>
      </app-dialog-box>

      <app-dialog-box
        *ngIf="locationsMissingInfo.length > 0 && !disableRolloverRequote"
        type="primary"
        header="Information required to bind"
        class="
          js-missing-rollover-info-dialog-text
          dialog-text__margin-top dialog-text__full-width
          policy-pane-dialog
        "
      >
        Please fill out some missing information to complete your quote using the link below:
        <div class="dialog-text-links">
          <a
            data-amplitude-input-name="requote_rollover_modal_open"
            (click)="openRolloverRequoteModal()"
            (keyup.enter)="openRolloverRequoteModal()"
            class="js-rollover-complete-quote-link dialog-text-link"
          >
            Complete quote
          </a>
        </div>
      </app-dialog-box>

      <app-dialog-box
        *ngIf="archivingConfirmationPanelShowing"
        type="primary"
        header="Are you sure?"
        class="
          js-dialog-text-with-button
          dialog-text__margin-top dialog-text__full-width
          policy-pane-dialog
        "
      >
        This action cannot be undone.
        <div class="dialog-text-links">
          <a
            (click)="archiveQuote()"
            (keyup.enter)="archiveQuote()"
            [ngClass]="{ 'link-disabled': archiving }"
            class="js-quote-archive-confirm-button dialog-text-link"
          >
            {{ archiving ? 'Archiving' : 'Yes, archive' }}
          </a>
        </div>
      </app-dialog-box>
    </ng-container>

    <ng-container *ngIf="isBindBlockedQuote && model | async as loadedModel">
      <app-dialog-box
        class="
          js-bind-block-dialog
          dialog-text__margin-top dialog-text__full-width
          policy-pane-dialog
        "
        fullWidth="true"
        type="warning"
      >
        <strong>Non-Preferred quote not bindable at this time</strong><br />
        Your agency is no longer able to bind non-preferred quotes in our portal.
        <br />
        <br />
        To discuss reinstating non-preferred access, please contact your account manager.
        <br />
        <br />
        <a
          *ngIf="accountManager?.bopBookBalanceLink"
          class="js-account-manager-link"
          href="{{ accountManager.bopBookBalanceLink }}"
          target="_blank"
          data-amplitude-input-name="bop-quote-not-bindable-book-balance"
        >
          Schedule time with {{ accountManager.name }} by clicking here.
        </a>
      </app-dialog-box>
    </ng-container>
  </div>

  <app-dialog-box
    *ngIf="editNotAvailableMessageIsShowing"
    type="warning"
    header="Edit unavailable"
    class="js-dialog-text dialog-text__full-width"
  >
    Please contact our Customer Care Team to makes changes to this quote.
    <div class="dialog-text-links">
      <a class="dialog-text-link" href="https://attunehelp.zendesk.com/hc/en-us/requests/new"
        >Get in touch</a
      >
    </div>
  </app-dialog-box>

  <app-dialog-box
    *ngIf="(uwAlerts$ | async) && (uwAlerts$ | async).length > 0"
    type="warning"
    header="Underwriting alert"
    class="js-uw-alert-banner dialog-text__full-width"
  >
    This quote will be reviewed after bind.
    <div class="dialog-text-links">
      <a
        (click)="setUWAlertModalOpen(true)"
        (keyup.enter)="setUWAlertModalOpen(true)"
        class="dialog-text-link"
        >View details</a
      >
    </div>
  </app-dialog-box>

  <app-dialog-box
    *ngIf="shouldDisplayRequoteButton"
    type="primary"
    header="Requote required"
    class="dialog-text__full-width"
  >
    The account details have been changed. Click "Requote" to get a bindable quote.
    <div class="dialog-text-links">
      <a
        (click)="requote()"
        (keyup.enter)="requote()"
        [ngClass]="{ 'link-disabled': requoting }"
        class="js-go-to-requote dialog-text-link"
        >{{ requoting ? 'Requoting...' : 'Requote' }}</a
      >
    </div>
  </app-dialog-box>

  <app-dialog-box
    *ngIf="isExcessPolicyDeclined"
    type="danger"
    class="js-no-excess-message dialog-text__full-width"
  >
    Excess Liability is not available for this quote.
  </app-dialog-box>

  <ng-container *ngIf="model | async as loadedModel">
    <app-dialog-box
      *ngIf="showBopPlusAutobindWarning(loadedModel)"
      header="Manual bind required"
      type="warning"
      class="js-autobind-manual-warning dialog-text__full-width"
    >
      This renewal will not bind automatically.
      <div class="dialog-text-links">
        <a
          class="dialog-text-link"
          (click)="continueToBind()"
          (keyup.enter)="continueToBind()"
          *ngIf="loadedModel.bindable && !requiresAdditionalInfo(loadedModel)"
          >Bind now</a
        >
      </div>
    </app-dialog-box>
    <ng-container *ngIf="loadedModel.status !== 'Draft'">
      <app-dialog-box
        *ngIf="loadedModel.hasCyberCoverageBug || loadedModel.isQuoteOverpriced"
        type="warning"
        class="js-requote-message dialog-text__full-width"
      >
        There was an issue pricing this quote. Try requoting it via the edit button. If that doesn't
        work, please contact our Customer Care Team.
        <div class="dialog-text-links">
          <a class="dialog-text-link" href="https://attunehelp.zendesk.com/hc/en-us/requests/new"
            >Get in touch</a
          >
        </div>
      </app-dialog-box>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!(model | async)">
    <div class="placeholder-h1"></div>
    <ul>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
    </ul>
  </ng-container>

  <ng-container *ngIf="model | async as loadedModel">
    <ng-container *ngIf="loadedModel.status !== 'Draft'">
      <app-dialog-box
        *ngIf="isBopV1QuoteInBopV2State(loadedModel)"
        header="Looking for an existing Blackboard quote?"
        type="warning"
        class="
          js-open-blackboard-quote-manual-warning
          dialog-text__margin-top dialog-text__full-width
        "
      >
        {{ getFullStateName(loadedModel.baseState) }} is now on Accredited. Please<a
          routerLink="/support/form/bind-quote"
          [queryParams]="getBindFormQueryParams(loadedModel)"
          data-amplitude-input-name="support_bind_quote_existing_blackboard"
        >
          contact our Customer Care Team </a
        >to bind any existing Blackboard quotes on this account.
      </app-dialog-box>

      <app-dialog-box
        *ngIf="
          !isBopV1QuoteInBopV2State(loadedModel) &&
          loadedModel.hasExcessPolicy &&
          !loadedModel.isExcessPolicyDeclined &&
          !hasManualExcessQuoteRequest
        "
        type="warning"
        class="js-expiring-aig-excess-quote-warning dialog-text__full-width"
      >
        <strong>AIG Excess liability quotes must be bound by {{ aigExcessBindCutoffDate }}</strong
        >, regardless of quote date or effective date. BOP policies may be bound without excess
        liability at any time, subject to the terms outlined in the quote letter. More info is
        available
        <a
          href="https://blog.attuneinsurance.com/were-migrating-aig-xs-policies-to-core-specialty"
          target="_blank"
          data-amplitude-input-name="support_bind_aig_excess_quote_warning"
          >here</a
        >.
      </app-dialog-box>

      <app-dialog-box
        *ngIf="hasManualExcessQuoteRequest"
        type="primary"
        class="js-has-manual-excess-quote-request dialog-text__full-width"
      >
        A Core Specialty Excess Liability quote has been requested for this account. Premium below
        is for BOP only. Please contact us for details on Excess.
      </app-dialog-box>

      <div *ngIf="!isBundle" class="policy-pane-header">
        <div class="policy-pane-header-left">
          <div
            class="product-badge"
            [ngClass]="{
              'product-badge__with-excess': loadedModel.hasExcessPolicy,
              'product-badge__bop': !isBopV2Quote(loadedModel),
              'product-badge__bop-plus': isBopV2Quote(loadedModel)
            }"
          ></div>
          <div class="policy-pane-quote-info">
            <h2 class="policy-pane-header-h2">Quote details</h2>
            <small class="gray-text">No. {{ loadedModel.id }}</small>
            <div class="quote-type-info" *ngIf="quoteType">
              <div
                *ngIf="isStronglyPreferredQuote"
                class="quote_preference quote_preference__strongly_preferred"
              ></div>
              <div
                *ngIf="isPreferredQuote"
                class="quote_preference quote_preference__preferred"
              ></div>
              <div
                *ngIf="isNonPreferredQuote"
                class="quote_preference quote_preference__non_preferred"
              ></div>
              {{ quoteType }}
              <span
                *ngIf="quoteTypeTip"
                class="more-info-tip tool-tip tool-tip__large"
                attr.data-tooltip="{{ quoteTypeTip }}"
              >
                <span class="icon icon-question"></span>
              </span>
            </div>
          </div>
        </div>
        <div
          *ngIf="!loadedModel.hasExcessPolicy || isExcessPolicyDeclined"
          class="policy-pane-header-right"
        >
          <h2 class="policy-pane-header-h2 js-premium-number">{{ formatCost(loadedModel) }}</h2>
          <div class="premium-explainer">
            <small>Policy amount</small>
          </div>
        </div>
        <div
          *ngIf="loadedModel.hasExcessPolicy && !isExcessPolicyDeclined"
          class="policy-pane-header-right"
        >
          <div *ngIf="excessLoading" class="placeholder-premium"></div>
          <h2
            *ngIf="excessDetails$ | async as loadedExcess"
            class="policy-pane-header-h2 js-premium-number-with-excess"
          >
            {{ formatCost(loadedModel, loadedExcess) }}
          </h2>
          <small class="gray-text">Policy Amount</small>
        </div>
      </div>

      <div *ngIf="!isBundle" class="document-download-container">
        <app-document-download
          *ngIf="loadedModel.status !== 'Expired'"
          displayName="Quote Letter"
          [documentDownload$]="quoteLetterDownload$"
          [documentReady$]="quoteLetterDownloadReady$"
        ></app-document-download>
        <app-document-download
          *ngIf="showSubmissionDownload"
          displayName="Application Input"
          [documentDownload$]="quoteSubmissionDownload$"
        ></app-document-download>
      </div>

      <ng-container
        *ngIf="!(loadedModel.status === 'Expired') && excessDetails$ | async as loadedExcess"
      >
        <div class="helper-card helper-card__static">
          <p>
            <strong>Binding without Excess?</strong> You can choose to exclude it from the policy on
            the bind step.
          </p>
        </div>

        <table>
          <thead>
            <tr>
              <th>Line of Business</th>
              <th>Quote number</th>
              <th>Effective</th>
              <th class="td__right">Amount</th>
              <th class="td__right">PDF</th>
            </tr>
          </thead>

          <tbody>
            <a
              class="quote-table-row quote-table-row__linked"
              (click)="downloadQuoteLetter(loadedModel)"
              (keyup.enter)="downloadQuoteLetter(loadedModel)"
            >
              <td data-column-name="Line of Business">{{ loadedModel.product }}</td>
              <td data-column-name="Quote number">{{ loadedModel.id }}</td>
              <td data-column-name="Effective">
                {{ loadedModel.policyStart | date: 'mediumDate':'UTC' }}
              </td>
              <td class="td__bold td__right" data-column-name="Premium">
                {{ formatCost(loadedModel) }}
              </td>
              <td class="td__right" data-column-name="PDF">
                <span class="icon-download"></span>
              </td>
            </a>

            <tr
              class="quote-table-row quote-table-row__linked"
              (click)="downloadQuoteLetter(loadedExcess)"
            >
              <td data-column-name="Line of Business">{{ loadedExcess.product }}</td>
              <td data-column-name="Quote number">{{ loadedExcess.id }}</td>
              <td data-column-name="Effective">
                {{ loadedExcess.policyStart | date: 'mediumDate':'UTC' }}
              </td>
              <td class="td__bold td__right" data-column-name="Premium">
                {{ formatCost(loadedExcess) }}
              </td>
              <td class="td__right" data-column-name="PDF">
                <span class="icon-download"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>

      <dl
        *ngIf="
          !isBundle &&
          (!loadedModel.hasExcessPolicy || isExcessPolicyDeclined) &&
          !(loadedModel.status === 'Expired')
        "
      >
        <dt>Effective date</dt>
        <dd>{{ loadedModel.policyStart | date: 'mediumDate':'UTC' }}</dd>
      </dl>

      <app-additional-documents [isAttuneWc]="false" paneType="quote"></app-additional-documents>

      <ng-container *ngIf="loadedModel.status === 'Quoted' && loadedModel.locations.length <= 3">
        <app-bop-price-reduction-estimate
          [quote]="loadedModel"
          [policyId]="policyId"
          [translatedQuote]="translatedQuote"
          (submitRequote)="handlePriceEstimateSubmit($event)"
        >
        </app-bop-price-reduction-estimate>
      </ng-container>

      <div *ngIf="!(loadedModel.status === 'Expired')" class="policy-pane-details">
        <div class="policy-detail-subsection">
          <h3>Liability</h3>
          <dl>
            <dt>Per Occurrence Limit</dt>
            <dd>
              {{ loadedModel.patternCodes.perOccurenceLimit | currency: '$':'symbol':'1.0-0' }}
            </dd>
          </dl>
          <dl>
            <dt>Aggregate Limit</dt>
            <dd>{{ loadedModel.patternCodes.aggregateLimit | currency: '$':'symbol':'1.0-0' }}</dd>
          </dl>
          <dl *ngIf="loadedModel.patternCodes.medicalLimitPerPerson">
            <dt>Medical Expenses</dt>
            <dd>
              {{ loadedModel.patternCodes.medicalLimitPerPerson | currency: '$':'symbol':'1.0-0' }}
            </dd>
          </dl>
        </div>
        <ng-container *ngIf="!loadedModel.hasExcessPolicy || isExcessPolicyDeclined">
          <div class="policy-detail-subsection" *ngFor="let loc of locationValues">
            <h3>Loc {{ loc.locationNum }}/ Bldg {{ loc.buildingNum }}</h3>
            <dl *ngIf="loc.aopDeductible">
              <dt>AOP Deductible</dt>
              <dd>{{ loc.aopDeductible | currency: '$':'symbol':'1.0-0' }}</dd>
            </dl>
            <dl *ngIf="loc.windDeductible">
              <dt>Wind Deductible</dt>
              <dd>{{ loc.windDeductible }}</dd>
            </dl>
            <dl *ngIf="loc.buildingLimit">
              <dt>Building Limit</dt>
              <dd>{{ loc.buildingLimit | currency: '$':'symbol':'1.0-0' }}</dd>
            </dl>
            <dl *ngIf="loc.businessPersonalProperty">
              <dt>BPP Limit</dt>
              <dd>{{ loc.businessPersonalProperty | currency: '$':'symbol':'1.0-0' }}</dd>
            </dl>
          </div>
        </ng-container>
        <div
          class="policy-detail-subsection"
          *ngIf="loadedModel.hasExcessPolicy && !isExcessPolicyDeclined"
        >
          <h3>Excess Liability</h3>
          <ng-container *ngIf="!(excessDetails$ | async)">
            <ul>
              <li class="placeholder-contact-item"></li>
              <li class="placeholder-contact-item"></li>
            </ul>
          </ng-container>
          <ng-container *ngIf="excessDetails$ | async as loadedExcess">
            <dl>
              <dt>Aggregate Limit</dt>
              <dd>
                {{ loadedExcess.patternCodes.aggregateLimit | currency: '$':'symbol':'1.0-0' }}
              </dd>
            </dl>
          </ng-container>
        </div>

        <div class="mt-4 pb-2">
          <app-quote-servicing-information
            carrier="attune-gw-bop"
          ></app-quote-servicing-information>
        </div>
      </div>

      <div *ngIf="isDevMode">
        <div class="adminz-only">
          <pre>{{ loadedModel | json }}</pre>
        </div>
      </div>

      <app-product-cross-sell
        [open]="shouldRenderCrossSellWindow(loadedModel)"
        [config]="crossSellConfiguration"
        (handleClose)="handleProductCrossSellWindowEvent($event)"
      >
      </app-product-cross-sell>
      <app-product-cross-sell
        [open]="showUpsellStatusCard"
        [config]="upsellConfiguration"
        (handleClose)="handleProductUpsellWindowEvent($event)"
      >
      </app-product-cross-sell>
      <app-bundle-quote-review-modal
        [open]="showBundleQuoteReview"
        primaryProductName="BOP"
        [primaryProductPremium]="productPremiumForBundleQuoteReview"
        [secondaryQuote]="secondaryQuote$ | async"
        [primaryDocumentDownload$]="quoteLetterDownload$"
        [primaryDocumentDownloadReady$]="quoteLetterDownloadReady$"
        [secondaryDocumentDownload$]="secondaryQuoteLetterDownload$"
        (closeModal)="handleBundleQuoteReviewModalClose()"
      ></app-bundle-quote-review-modal>
    </ng-container>
    <ng-container *ngIf="loadedModel.status === 'Draft'">
      <app-dialog-box
        *ngIf="isTechMoratorium(loadedModel)"
        type="warning"
        header="Moratorium"
        class="dialog-text__full-width js-tech-moratorium"
      >
        There is a moratorium in place which is affecting this location. This quote can not be bound
        until the moratorium is lifted.
      </app-dialog-box>
      <app-dialog-box
        *ngIf="!isTechMoratorium(loadedModel) && loadedModel.uwIssues.length > 0"
        type="danger"
        header="Quote declined"
        class="dialog-text__full-width js-policy-issue"
      >
        This quote was declined for the following reasons:
        <ul class="bulleted-list">
          <li *ngFor="let issue of loadedModel.uwIssues">{{ issue }}</li>
        </ul>
        <div class="dialog-text-links">
          <a class="dialog-text-link" href="/support/form/request-review">Request a review</a>
        </div>
      </app-dialog-box>
      <div class="policy-pane-header">
        <div class="policy-pane-header-left">
          <div class="product-badge product-badge__bop">
            <div class="product-badge-carrier-icon product-badge-carrier-icon__attune"></div>
          </div>
          <div class="policy-pane-quote-info">
            <h2 class="policy-pane-header-h2 js-quote-declined-header">Quote details</h2>
            <small class="gray-text">No. {{ loadedModel.id }}</small>
          </div>
        </div>
        <div class="policy-pane-header-right">
          <h2 class="policy-pane-header-h2 js-policy-premium">N/A</h2>
          <small class="gray-text">Premium</small>
        </div>
      </div>

      <app-document-download
        *ngIf="showSubmissionDownload && loadedModel.uwIssues.length !== 0"
        displayName="Application Input"
        [documentDownload$]="quoteSubmissionDownload$"
      ></app-document-download>
      <div class="policy-pane-date">
        <div class="policy-pane-date__left">Effective Date</div>
        <div class="policy-pane-date__right">
          {{ loadedModel.policyStart | date: 'mediumDate':'UTC' }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<app-quote-error-modal
  [open]="quoteResultModalOpen"
  (closeQuoteErrorModal)="handleQuoteResultModalClose($event)"
  [errors]="getErrorList()"
  [errorType]="getErrorType()"
></app-quote-error-modal>
<app-uw-alert-modal
  [open]="isUWAlertModalShowing"
  [naicsCode]="accountDetails && accountDetails.naicsCode"
  [guidelinesData]="guidelinesData"
  [uwAlerts]="uwAlerts$ | async"
  (closeUWModal)="setUWAlertModalOpen(false)"
></app-uw-alert-modal>

<app-modal
  [open]="showRolloverRequoteModal"
  (handleModalClose)="closeRolloverRequoteModal()"
  modalClass="app-modal-content__left-align"
>
  <h2>Information required to bind</h2>
  <section
    *ngFor="let location of locationsMissingInfo; let i = index"
    class="js-location-missing-info"
  >
    <h3 class="mb-2">Location {{ location.originalLocationIndex + 1 }}</h3>
    <div class="location-form-address">
      <span _ngcontent-c2="" aria-label="Address" class="icon-location contact-bullet-icon"></span>
      <span>{{ location.locationDetails.addressLine1 }},</span>
      <span *ngIf="location.locationDetails.addressLine2">
        {{ location.locationDetails.addressLine2 }},</span
      >
      <span class="js-address-city-zip">
        {{ location.locationDetails.city }}, {{ location.locationDetails.state }}
        {{ location.locationDetails.zip }}</span
      >
    </div>

    <app-form-field-text
      inputId="location-{{ location.originalLocationIndex + 1 }}-numberOfEmployees"
      [form]="locationInfoRequiredToBindFormArr.controls[i]"
      [submitted]="rolloverRequoteSubmitted"
      labelText="Number of employees"
      nameOfFormControl="numberOfEmployees"
    >
    </app-form-field-text>
    <app-form-field-radio
      inputId="location-{{
        location.originalLocationIndex + 1
      }}-isWithinCreditableWaterSupplyForPPC"
      [form]="locationInfoRequiredToBindFormArr.controls[i]"
      [submitted]="rolloverRequoteSubmitted"
      questionText="Is this building within 1,000 feet of a creditable water supply, such as a fire hydrant, suction point, or dry hydrant?"
      nameOfFormControl="isWithinCreditableWaterSupplyForPPC"
    >
    </app-form-field-radio>
  </section>

  <div
    class="validation"
    role="alert"
    *ngIf="rolloverRequoteSubmitted && locationInfoRequiredToBindFormArr.invalid"
  >
    <p class="validation-message">Please fill out all required fields.</p>
  </div>

  <div class="nav-button-group">
    <button (click)="closeRolloverRequoteModal()" class="button button__secondary">Cancel</button>
    <button
      (click)="requoteRollover()"
      class="button button__primary"
      [ngClass]="{ button__discouraged: locationInfoRequiredToBindFormArr.invalid }"
    >
      Requote
    </button>
  </div>
</app-modal>
<app-modal
  [open]="showBundleBind"
  (handleModalClose)="closeBundleBind()"
  modalClass="app-modal-content__left-align"
  modalNameForAmplitudeEvent="bundle-bind-quote-selection"
>
  <app-bundle-bind-selection-form
    *ngIf="showBundleBind && model | async"
    [accountId]="accountId"
    [selectedBopId]="(model | async)?.id"
    (closeModal)="closeBundleBind()"
  ></app-bundle-bind-selection-form>
</app-modal>
