import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { LibertyMutualPaymentPlan } from 'app/features/liberty-mutual/models/common-typings';
import { getControl } from 'app/shared/helpers/form-helpers';

@Injectable()
export class LibertyMutualBindFormService extends FormDslSteppedFormBaseService {
  form: UntypedFormGroup;
  submitted = false;
  DEFAULT_PAYMENT_OPTION: LibertyMutualPaymentPlan = 'FL';

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.initializeForm();
  }

  generateSteps(): RouteFormStep[] {
    return [
      {
        args: {},
        displayName: `Payment Plan`,
        slug: 'payment-plan',
        parent: 'payment-plan',
        formPath: 'paymentPlan',
      },
      {
        args: {},
        displayName: `Payment Details`,
        slug: 'payment-details',
        parent: 'payment-details',
        formPath: 'paymentDetails',
      },
    ];
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      paymentPlan: this.formBuilder.group({
        numberOfPayments: [this.DEFAULT_PAYMENT_OPTION, Validators.required],
        policyHolderEmail: ['tbd', Validators.required],
      }),
      paymentDetails: this.formBuilder.group({}),
    });
  }

  fillInHappyPath() {}

  getValue() {
    return this.form.value;
  }

  getSelectedPayment() {
    return getControl(this.form, 'paymentPlan.numberOfPayments').value;
  }

  setPreviouslySelectedPaymentOption(paymentPlan: number) {
    getControl(this.form, 'paymentPlan.numberOfPayments').patchValue(paymentPlan);
  }
}
