<main class="login-box-container">
  <div id="login" class="login-box">
    <app-logo-with-text></app-logo-with-text>
    <h1 class="login-title-margin">Welcome</h1>
    <p class="gray-text">Sign in to Attune</p>
    <div class="login-box-form">
      <div id="okta-widget-container"></div>
    </div>
    <a
      class="login-tertiary-link"
      [routerLink]="['/forgot-password']"
      [queryParams]="{ email: getUsername() }"
      >Forgot password?</a
    >
  </div>
  <div id="promo-widget"></div>
</main>
<p class="terms-of-use-text terms-of-use-text__black">
  Interested in working with Attune?
  <a href="https://www.attuneinsurance.com/register">Learn more</a>
</p>
<p class="terms-of-use-text">
  By accessing Attune, you agree to the following
  <a href="https://www.attuneinsurance.com/legal/terms-of-use/">Terms of Use</a>.
</p>
<app-footer showPhoneNumber="true"> </app-footer>
