import { Injectable } from '@angular/core';

import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

import { FormDslServiceBase } from 'app/shared/form-dsl/services/form-dsl-service-base';

@Injectable()
export class FormDslTestFormService extends FormDslServiceBase {
  // form: FormGroup;
  submitted = false;

  defaultFormStep = 'quoteBasic';
  FORM_STEPS = [
    {
      args: {},
      displayName: 'Basic',
      slug: 'quote-basic',
      parent: 'quote-basic',
      formPath: 'quoteBasic',
    },
  ];

  private _showAdditionalRequirements = false;

  fillInHappyPath() {}

  getValue() {
    return this.form.value;
  }

  generateSteps(): RouteFormStep[] {
    return [
      {
        args: {},
        displayName: `Basic`,
        slug: 'quote-basic',
        parent: 'quote-basic',
        formPath: 'quoteBasic',
      },
    ];
  }
}
