<section>
  <h1 class="h1__onboarding-form">Let's start with a sample quote</h1>
  <p id="onboarding-classification" class="p__large-text form-field">
    You're going to love quoting with us. Choose a class code below to see how easy it is:
  </p>

  <div aria-labelledby="onboarding-classification" class="radio-question-list" role="radiogroup">
    <div class="radio-question-list-option">
      <input
        id="onboarding-classcode-restaurant"
        name="classCodeSelection"
        type="radio"
        value="{{ sampleType.RESTAURANT }}"
        [formControl]="form.get('sampleClassification.selection')"
      />
      <label
        class="radio-question-list-label"
        for="onboarding-classcode-restaurant"
        data-amplitude-input-name="onboarding-classcode-restaurant"
      >
        🍔 Restaurant
      </label>
    </div>
    <div class="radio-question-list-option">
      <input
        id="onboarding-classcode-contractor"
        name="classCodeSelection"
        type="radio"
        value="{{ sampleType.CONTRACTOR }}"
        [formControl]="form.get('sampleClassification.selection')"
      />
      <label
        class="radio-question-list-label"
        for="onboarding-classcode-contractor"
        data-amplitude-input-name="onboarding-classcode-contractor"
      >
        🔨 Contractor
      </label>
    </div>
    <div class="radio-question-list-option">
      <input
        id="onboarding-classcode-beautySalon"
        name="classCodeSelection"
        type="radio"
        value="{{ sampleType.SALON }}"
        [formControl]="form.get('sampleClassification.selection')"
      />
      <label
        class="radio-question-list-label"
        for="onboarding-classcode-beautySalon"
        data-amplitude-input-name="onboarding-classcode-beautySalon"
      >
        💅 Beauty salon
      </label>
    </div>
  </div>
</section>
