import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  assign,
  cloneDeep,
  find,
  forEach,
  isEmpty,
  isEqual,
  isMatch,
  omit,
  pick,
  some,
} from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skipWhile, startWith, tap } from 'rxjs/operators';

import {
  CheckBoxGroupNode,
  DropdownSearchNode,
  FormDslNode,
  FormDslStep,
  TextNode,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';
import { enableDisableControl, getControl, getFormGroup } from 'app/shared/helpers/form-helpers';
import { Industry } from '../../../shared/services/naics.service';
import { CYBER_QUOTE_FLOW, QUICK_CYBER_QUOTE_FLOW } from '../models/cyber-form-steps.model';
import {
  CYBER_DEPENDENCIES,
  CYBER_QUICK_QUOTE_DEPENDENCIES,
} from '../models/cyber-dependencies.model';
import { CYBER_FORM_QUESTIONS } from '../models/cyber-questions.model';
import {
  COVERAGE_VALUES_BY_BUNDLE_OPTION,
  ESSENTIAL_COVERAGES,
  INITIAL_AGGREGATE_LIMIT_INDEX,
  INITIAL_DEFAULT_RETENTION_INDEX,
  MOST_POPULAR_COVERAGES,
  USER_INPUT_DEBOUNCE_TIME_IN_MS,
} from '../models/cyber-constants.model';
import { CYBER_ADMITTED_HAPPY_PATH_FORM_DATA } from '../models/cyber-happy-path-form-data.model';
import {
  BundleOption,
  CoalitionCyberControlName,
  CoalitionCyberFormStepPath,
  CoalitionCyberQuestion,
  CyberDependency,
  CyberProduct,
  CyberQuestionEnablementFlag,
  CyberQuestionEnablementFlags,
  DependencySubscription,
  DependencyValue,
  FirstPartyCoverageNestedQuestion,
  FormValue,
  INSURANCE_MARKET_CONTROL,
} from '../models/cyber-typings.model';

@Injectable({
  providedIn: 'root',
})
export class CoalitionCyberQuoteFormService extends FormDslSteppedFormService implements OnDestroy {
  happyPathFormData = CYBER_ADMITTED_HAPPY_PATH_FORM_DATA;

  // Dependencies-related properties
  setDependencies = new Set<CoalitionCyberFormStepPath>();
  controlNameToFormPath: { [key in CoalitionCyberControlName]?: string } = {};
  dependencies = CYBER_DEPENDENCIES;

  private cyberProductSubject: BehaviorSubject<CyberProduct | null> = new BehaviorSubject(null);
  private questionEnablementFlagsSubject: BehaviorSubject<CyberQuestionEnablementFlags | null> =
    new BehaviorSubject(null);
  // END Dependencies-related properties

  private sub = new Subscription();

  coverageStepWasUpdated$: Observable<true>;
  private coverageStepWasUpdatedSubject = new Subject<true>();

  userIsEditingForm$: Observable<boolean>;
  userIsEditingFormSubject = new BehaviorSubject<boolean>(false);

  industryId$: Observable<number | null>;
  private industryIdSubject: BehaviorSubject<number | null> = new BehaviorSubject(null);

  techEOEvaluatorSet = false;
  techEORequired = false;

  constructor(public formBuilder: UntypedFormBuilder) {
    super(formBuilder);
    this.coverageStepWasUpdated$ = this.coverageStepWasUpdatedSubject.asObservable();
    this.userIsEditingForm$ = this.userIsEditingFormSubject.asObservable();
    this.industryId$ = this.industryIdSubject.asObservable();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  generateFormSteps(quick = false): FormDslStep[] {
    const quoteFlow = quick ? QUICK_CYBER_QUOTE_FLOW : CYBER_QUOTE_FLOW;

    return quoteFlow.map((step) => {
      const { args, displayName, slug, parent, formPath, nextButtonText, questions } = step;

      const nodes: FormDslNode[] = [];
      questions.forEach((question) => {
        if (Array.isArray(question)) {
          const children = question.map((nestedQuestion) => {
            return cloneDeep(CYBER_FORM_QUESTIONS[nestedQuestion]);
          });

          nodes.push({
            primitive: 'DIV',
            cssClass: 'form-subsection',
            children,
          });
        } else {
          nodes.push(cloneDeep(CYBER_FORM_QUESTIONS[question]));
        }
      });
      const getFormTree = () => of(nodes);
      return { args, displayName, slug, parent, formPath, nextButtonText, getFormTree };
    });
  }

  formDependenciesInit(currentFormPath: CoalitionCyberFormStepPath) {
    const formGroup = getFormGroup(this.form, currentFormPath);
    const stepHasControls = formGroup && !isEmpty(formGroup.controls);
    const stepDependenciesAreSet = this.setDependencies.has(currentFormPath);

    if (stepHasControls && !stepDependenciesAreSet) {
      this.setDependenciesForFormGroup(currentFormPath, formGroup);
      this.setDependencies.add(currentFormPath);

      if (
        currentFormPath === CoalitionCyberFormStepPath.POLICY_INFO ||
        currentFormPath === CoalitionCyberFormStepPath.QUICK_QUOTE
      ) {
        this.setIndustryIdControlListener(formGroup);
      }

      if (currentFormPath === CoalitionCyberFormStepPath.COVERAGE) {
        this.setBundleControlListener(formGroup);
        this.setCoverageFormStepListener(formGroup);
        this.handleTechEOEvaluator();

        // These initial values are chosen for business reasons, namely to target a particular premium range.
        formGroup.addControl(
          CoalitionCyberQuestion.AGGREGATE_LIMIT,
          this.formBuilder.control(INITIAL_AGGREGATE_LIMIT_INDEX)
        );
        formGroup.addControl(
          CoalitionCyberQuestion.DEFAULT_RETENTION,
          this.formBuilder.control(INITIAL_DEFAULT_RETENTION_INDEX)
        );

        // This control is not present in UI or in quote submission; it is
        // only used for our validator logic. If needed to validate a control
        // on a step other than Coverages, we will need to create a
        // complexFormValidators dictionary and add it to the form component.
        formGroup.addControl(
          INSURANCE_MARKET_CONTROL,
          this.formBuilder.control(this.cyberProductSubject.getValue())
        );
      }
    }
  }

  setDependenciesForFormGroup(prefix: string, formGroup: UntypedFormGroup) {
    forEach(formGroup.controls, (control, controlName: CoalitionCyberControlName) => {
      // Register the control's formpath so it is available for lookup later.
      this.controlNameToFormPath[controlName] = `${prefix}.${controlName}`;
      // Set the dependency
      const dependency = this.dependencies[controlName] || null;
      if (dependency) {
        this.addDependencies({ control, dependency, dependentControlName: controlName });
      }
      if (control instanceof UntypedFormGroup) {
        // NOTE: This implementation assumes there are no form arrays in the cyber flow.
        // If any were added, we should handle that case as well.
        this.setDependenciesForFormGroup(`${prefix}.${controlName}`, control);
      }

      if (prefix === CoalitionCyberFormStepPath.QUICK_QUOTE) {
        const quickQuoteDependency = CYBER_QUICK_QUOTE_DEPENDENCIES[controlName];

        if (quickQuoteDependency) {
          this.addDependencies({
            control,
            dependency: quickQuoteDependency,
            dependentControlName: controlName,
          });
        }
      }
    });
  }

  addDependencies({
    control,
    dependency,
    dependentControlName,
  }: {
    control: AbstractControl;
    dependency: CyberDependency;
    dependentControlName: CoalitionCyberControlName;
  }) {
    const dependencySubs: DependencySubscription[] = [];
    const { controlDependency, flagDependency, productDependency, customControlDependency } =
      dependency;
    if (controlDependency) {
      const { controlName, shouldEnableForValue } = controlDependency;
      const dependencyControlPath = this.controlNameToFormPath[controlName] as string;
      const dependsOnControl = getControl(this.form, dependencyControlPath);
      const dependsOn = dependsOnControl.valueChanges.pipe(startWith(dependsOnControl.value));
      const callback = this.getControlOrProductDependencyCallback(shouldEnableForValue);
      dependencySubs.push({ dependsOn, callback });
    }

    if (flagDependency) {
      const { flagName, shouldEnableForValue } = flagDependency;
      const dependsOn = this.questionEnablementFlagsSubject;
      const callback = this.getFlagDependencyCallback(flagName, shouldEnableForValue);
      dependencySubs.push({ dependsOn, callback });
    }

    if (productDependency) {
      const { shouldEnableForValue } = productDependency;
      const dependsOn = this.cyberProductSubject;
      const callback = this.getControlOrProductDependencyCallback(shouldEnableForValue);
      dependencySubs.push({ dependsOn, callback });
    }

    if (customControlDependency) {
      const { controlName, callback } = customControlDependency;
      const dependencyControlPath = this.controlNameToFormPath[controlName] as string;
      const dependsOnControl = getControl(this.form, dependencyControlPath);
      const dependsOn = dependsOnControl.valueChanges.pipe(startWith(dependsOnControl.value));
      dependencySubs.push({ dependsOn, callback });
    }

    if (dependencySubs.length === 0) {
      return;
    }

    // Disable control by default
    control.disable();
    // Handle a single dependency as a regular subscription
    if (dependencySubs.length === 1) {
      const { dependsOn, callback } = dependencySubs[0];
      this.sub.add(
        dependsOn.subscribe((value) => {
          const shouldEnable = callback(value);
          enableDisableControl(control, shouldEnable);
        })
      );

      return;
    }
    // Handle multiple dependencies using a combineLatest subscription
    const dependsOnList$ = dependencySubs.map(({ dependsOn }) => dependsOn);

    this.sub.add(
      combineLatest(dependsOnList$).subscribe((dependsOnValues: DependencyValue[]) => {
        // Enable only if all callbacks return true
        const shouldEnable = dependsOnValues.every((val, idx) => {
          const { callback } = dependencySubs[idx];
          return callback(val);
        });

        enableDisableControl(control, shouldEnable);
      })
    );
  }

  getControlOrProductDependencyCallback(
    shouldEnableForValue: FormValue | CyberProduct | Array<Industry>
  ) {
    if (Array.isArray(shouldEnableForValue)) {
      return (val: FormValue | CyberProduct | Industry) =>
        val !== null && some(shouldEnableForValue, val);
    }

    return (val: FormValue | CyberProduct) => isEqual(val, shouldEnableForValue);
  }

  getFlagDependencyCallback(flagName: CyberQuestionEnablementFlag, shouldEnableForValue: boolean) {
    return (flagValues: CyberQuestionEnablementFlags) => {
      if (flagValues === null) {
        return false;
      }
      return flagValues[flagName] === shouldEnableForValue;
    };
  }

  updateQuestionEnablementFlags(flags: CyberQuestionEnablementFlags) {
    this.questionEnablementFlagsSubject.next(flags);
  }

  updateProduct(product: CyberProduct) {
    this.cyberProductSubject.next(product);
  }

  updateIndustryOptions(cyberMappings: Industry[]) {
    const industryIdNode = find(
      this.formTree,
      (node) => node.nameOfFormControl === CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    ) as DropdownSearchNode;
    industryIdNode.queryableResults = cyberMappings.map((industry) => industry.id.toString(10));
  }

  setBundleControlListener(coverageFormGroup: UntypedFormGroup) {
    const bundleFormControl = getControl(coverageFormGroup, CoalitionCyberQuestion.BUNDLE);
    this.sub.add(
      bundleFormControl.valueChanges.subscribe((selectedBundle: BundleOption) => {
        if (selectedBundle === null) {
          return;
        }
        const coveragePatchValues = COVERAGE_VALUES_BY_BUNDLE_OPTION[selectedBundle];
        // TECH_EO is handled specially so we don't uncheck techEO when it is required
        coveragePatchValues[CoalitionCyberQuestion.FIRST_PARTY_COVERAGES][
          FirstPartyCoverageNestedQuestion.TECH_EO
        ] = this.techEORequired;
        coverageFormGroup.patchValue(coveragePatchValues);
      })
    );
  }

  setIndustryIdControlListener(policyInfoGroup: UntypedFormGroup) {
    const businessDescriptionControl = getControl(
      policyInfoGroup,
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    );
    this.sub.add(
      businessDescriptionControl.valueChanges.subscribe((industry: Industry) => {
        if (industry === null) {
          return;
        }
        this.industryIdSubject.next(industry.id);
      })
    );
  }

  setCoverageFormStepListener(coverageFormGroup: UntypedFormGroup) {
    this.sub.add(
      coverageFormGroup.valueChanges
        .pipe(
          // Ignore any initial patches to the form before the user
          // actually interacts with it.
          skipWhile(() => coverageFormGroup.pristine),
          tap(() => {
            const bundleFormControl = getControl(coverageFormGroup, CoalitionCyberQuestion.BUNDLE);
            const coverages = pick(coverageFormGroup.value, [
              CoalitionCyberQuestion.FIRST_PARTY_COVERAGES,
              CoalitionCyberQuestion.THIRD_PARTY_COVERAGES,
            ]);

            if (
              bundleFormControl.value !== BundleOption.ESSENTIAL &&
              isMatch(ESSENTIAL_COVERAGES, coverages)
            ) {
              bundleFormControl.setValue(BundleOption.ESSENTIAL, { emitEvent: false });
            } else if (
              bundleFormControl.value !== BundleOption.MOST_POPULAR &&
              isMatch(MOST_POPULAR_COVERAGES, coverages)
            ) {
              bundleFormControl.setValue(BundleOption.MOST_POPULAR, { emitEvent: false });
            } else {
              bundleFormControl.setValue(BundleOption.CUSTOM, { emitEvent: false });
            }

            // While the user is editing the form, we are preparing to send
            // an edit request. We set this flag here to prevent the user from
            // submitting the form.
            // It will be flipped back to `false` either in the `distinctUntilChanged` check
            // below, or else by the component when the edit response comes back.
            this.userIsEditingFormSubject.next(true);
          }),
          debounceTime(USER_INPUT_DEBOUNCE_TIME_IN_MS),
          distinctUntilChanged((prev, cur) => {
            const formHasNotChanged = isEqual(
              omit(prev, [CoalitionCyberQuestion.BUNDLE]),
              omit(cur, [CoalitionCyberQuestion.BUNDLE])
            );
            if (formHasNotChanged) {
              // In this case, the user has changed the form but then changed
              // it back to its original state. We will not send an edit
              // request, so there is no need to block submission.
              this.userIsEditingFormSubject.next(false);
            }
            return formHasNotChanged;
          })
        )
        .subscribe(() => {
          this.coverageStepWasUpdatedSubject.next(true);
        })
    );
  }

  handleTechEOEvaluator() {
    if (this.techEOEvaluatorSet) {
      return;
    }
    this.techEOEvaluatorSet = true;
    const hasPolicyControl = getControl(
      getFormGroup(this.form, CoalitionCyberFormStepPath.POLICY_INFO),
      CoalitionCyberQuestion.HAS_TECH_EO
    );
    // Industry control is so we can check the `require_tech_eo` flag is still enabled for a new Industry
    const industryControl = getControl(
      getFormGroup(this.form, CoalitionCyberFormStepPath.POLICY_INFO),
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    );
    const techEOControl = getControl(
      this.form,
      this.controlNameToFormPath[FirstPartyCoverageNestedQuestion.TECH_EO] as string
    );
    const firstPartyCoverages = find(
      this.formTree,
      (node) => node.nameOfFormControl === CoalitionCyberQuestion.FIRST_PARTY_COVERAGES
    ) as CheckBoxGroupNode;
    const techEONodeInFormTree = firstPartyCoverages.checkboxConfigs.find(
      (node) => node.nameOfFormControl === FirstPartyCoverageNestedQuestion.TECH_EO
    );
    const hasPolicy$ = hasPolicyControl.valueChanges.pipe(startWith(hasPolicyControl.value));
    const industryId$ = industryControl.valueChanges.pipe(startWith(industryControl.value));

    this.sub.add(
      combineLatest(hasPolicy$, industryId$)
        .pipe(
          map(([hasPolicy, _]) => {
            const flags = this.questionEnablementFlagsSubject.value;
            const requireTechEOFlag = !!flags && flags.require_tech_eo;
            return !!hasPolicy && hasPolicy === 'No' && requireTechEOFlag;
          }),
          distinctUntilChanged((prev, cur) => {
            return isEqual(prev, cur);
          }),
          tap((required) => {
            this.techEORequired = required;
            techEOControl.patchValue(required);
            assign(techEONodeInFormTree, {
              labelText: required
                ? 'Technical Errors and Omissions (required)'
                : 'Technical Errors and Omissions',
              initialValue: required,
              requiredTrue: required,
              readonly: required,
            });
          })
        )
        .subscribe()
    );
  }

  setEffectiveDateAsReadonly() {
    const effectiveDateNode = find(
      this.formTree,
      (node) => node.nameOfFormControl === CoalitionCyberQuestion.EFFECTIVE_DATE
    ) as TextNode;

    effectiveDateNode.readonly = true;
  }
}
