import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FormTemplateResponse,
  SupportEnhancementService,
} from '../../services/support-enhancement.service';
import { InformService } from 'app/core/services/inform.service';

import { DocumentService } from '../../../documents/services/document.service';
import { API_V3_BASE } from 'app/constants';

@Component({
  providers: [SupportEnhancementService],
  selector: 'app-support-enhancement-page',
  templateUrl: './support-enhancement-page.component.html',
})
export class SupportEnhancementPageComponent implements OnInit, OnDestroy {
  formTemplates: FormTemplateResponse;
  sub: Subscription = new Subscription();
  isLoading = true;
  constructor(
    private supportEnhancementService: SupportEnhancementService,
    private documentService: DocumentService,
    private informService: InformService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.supportEnhancementService.getFormTemplates().subscribe((forms) => {
        this.formTemplates = forms;
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  downloadForm(formFullName: string) {
    const formUrl = `${API_V3_BASE}/documents/enhancement-library-documents/download/${encodeURIComponent(
      formFullName
    )}`;

    this.sub.add(
      this.documentService.getDocument(formUrl, formFullName, 'pdf').subscribe({
        error: () => {
          this.informService.infoToast(
            'There was an error downloading this file. Please contact our Customer Care Team.'
          );
        },
      })
    );
  }
}
