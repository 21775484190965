import { BopPolicy } from 'app/features/attune-bop/models/bop-policy';
import {
  Period,
  Clause,
  QuoteRequestWrapper,
  VehicleFleetExposure,
} from 'app/bop/guidewire/typings';
import {
  EXCESS_LIABILITY_QUESTION_TEXT,
  UW_QUESTIONS,
} from 'app/features/attune-bop/models/constants';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { CurrentUser } from 'app/shared/models/current-user';
import { BackendCreateInsuredAccountRequestPayloadFactory } from 'app/shared/models/backend/backend-create-insured-account-payload-factory';
import { AttuneBopQuotePayloadService } from 'app/features/attune-bop/services/attune-bop-quote-payload.service';
import { BopPricedQuote } from '../models/bop-priced-quote';
import { getEmployersLiabilityStateInfixFromState } from 'app/bop/guidewire/translation-helpers';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { findFixtureByDescriptionCode } from './attune-bop-building-classification.service';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import {
  ManualExcessAccountDetails,
  ManualExcessBopDetails,
  ManualExcessFormDetails,
  ManualExcessQuoteSubmissionPayload,
} from 'app/features/attune-bop/models/excess-liability-typings';

const TYPE_IDS: Record<string, string> = {
  // NOTE:  the numbers after colons here are arbitrary, used to link ULPolicies to Coverages, but the form entity.COVERAGETYPE:123 is required by guidewire
  bop: 'entity.ULPolicy_CUE:6306',
  excess: 'entity.CUPLine_CUE:54004',
  excessCommercialAuto: 'entity.ULPolicy_CUE:6307',
  excessEmployersLiability: 'entity.ULPolicy_CUE:6308',
};

@Injectable()
export class AttuneBopExcessQuotePayloadService {
  getExcessQuotePayload(
    insuredAccount: InsuredAccount,
    policy: BopPolicy,
    user: CurrentUser,
    bopPricedQuote: BopPricedQuote
  ): QuoteRequestWrapper {
    const contact: any =
      BackendCreateInsuredAccountRequestPayloadFactory.build(insuredAccount).accountModel.Account
        .AccountHolderContact;
    contact.AddressBookUID = null;
    contact['entity-Person'] = null;

    const effective = policy.periodStart().toISOString();
    const expires = policy.periodEnd().toISOString();

    const policyPeriod: Period = {
      AddlNamedInsureds_HUSA: null,
      BP7LineExists: false,
      BaseState: policy.policyInfo.baseState,
      CP7LineExists: false,
      CommercialUmbrellaLine_CUE: {
        AllClauses: {
          Entry: [
            {
              CovTerms: null,
              OwningCoverable: {
                TypeIDString: TYPE_IDS.excess,
              },
              Pattern: {
                Code: 'CUP_Tria_CUE',
              },
              ScheduledItems_HUSA: null,
            },
            {
              CovTerms: {
                Entry: [
                  {
                    CovTermValueForRating_HUSA: String(policy.excessLiability.excessLiabilityLimit),
                    PatternCode: 'CUP_OccurenceLimit_CUE',
                  },
                  {
                    CovTermValueForRating_HUSA: String(policy.excessLiability.excessLiabilityLimit),
                    PatternCode: 'CUP_GeneralAggregateLimit_CUE',
                  },
                  {
                    CovTermValueForRating_HUSA: String(policy.excessLiability.excessLiabilityLimit),
                    PatternCode: 'CUP_ProductCompOpsAggregateLimit_CUE',
                  },
                ],
              },
              OwningCoverable: {
                TypeIDString: TYPE_IDS.excess,
              },
              Pattern: {
                Code: 'CUP_Primary_Liability_CUE',
              },
              ScheduledItems_HUSA: null,
            },
          ],
        },
        TypeIDString: TYPE_IDS.excess,
        ULAutoPolicyExists_CUE: policy.excessLiability.excessCommercialAutoCoverageIsScheduled,
        ULBOPPolicyExists_CUE: true,
        ULPolicies: {
          Entry: [
            {
              Carrier: null,
              EffDate: effective,
              ExpDate: expires,
              IsExternal: false,
              JobNumber: bopPricedQuote.id,
              PolicyNumber: null,
              PolicyType: 'BOP',
              TypeIDString: TYPE_IDS.bop,
              ULPolicyAnswers_HUSA: null,
              VehicleFleetExposure: null,
            },
          ],
        },
      },
      CommercialUmbrellaLine_CUEExists: true,
      GeneralLiabilityLine_GLE: null,
      GeneralLiabilityLine_GLEExists: false,
      MonoLinePolicyLine_HUSA: {
        HamiltonCodes_HUSA: null,
        IndustryGroup_HUSA: null,
        Subtype: 'CUPLine_CUE',
      },
      PeriodAnswers: { Entry: [] },
      PeriodEnd: policy.periodEnd().toISOString(),
      PeriodStart: policy.periodStart().toISOString(),
      PolicyContactRoles: { Entry: [] },
      PolicyLocations: { Entry: [] },
      UWCompany: {
        Code: 'HI',
      },
      WrittenDate: AttuneBopQuotePayloadService.getTime(),
    };

    if (policy.excessLiability.excessLiabilityCoverageIsScheduled) {
      this.scheduleEmployersLiabilityCoverage(
        policyPeriod,
        policy,
        bopPricedQuote.id,
        effective,
        expires
      );
    }

    if (policy.excessLiability.excessCommercialAutoCoverageIsScheduled) {
      this.scheduleAutoCoverage(policyPeriod, policy, bopPricedQuote.id, effective, expires);
    }

    this.addUWQuestionsAnswersExclusions(policyPeriod, policy);

    return {
      quoteRequestModel: {
        QuoteSubmissionRequest: {
          Account: {
            AccountHolderContact: contact,
            AccountNumber: insuredAccount.id,
            AccountOrgType: insuredAccount.organizationType,
            IndustryCode: null,
            MathedBusinessName_HUSA: null,
            OFACStatus_HUSA: 'NO',
            ProducerCodes: insuredAccount.producerCodesStruct,
          },
          Periods: {
            Entry: [policyPeriod],
          },
          TSRequestID_HUSA: policy.tsRequestId,
        },
      },
      userName: user.username,
    };
  }

  private addUWQuestionsAnswersExclusions(policyPeriod: Period, policy: BopPolicy) {
    const clauseDictionary: { [k: string]: Clause } = {
      CUP_CRANE_SCAFFOLD_CUE: {
        CovTerms: null,
        OwningCoverable: {
          TypeIDString: 'entity.CUPLine_CUE:54004',
        },
        Pattern: {
          Code: 'CUP_CRANE_SCAFFOLD_CUE',
        },
        ScheduledItems_HUSA: null,
      },
    };

    policyPeriod.PeriodAnswers = {
      Entry: policy.uwQuestions
        ? Object.keys(policy.uwQuestions).map((questionId) => ({
            AnswerValueAsString: String(policy.uwQuestions[questionId]),
            QuestionCode: questionId,
          }))
        : [],
    };

    policy.exclusionsToAdd.forEach((exclusionCode: string) => {
      if (clauseDictionary[exclusionCode] && policyPeriod.CommercialUmbrellaLine_CUE) {
        policyPeriod.CommercialUmbrellaLine_CUE.AllClauses.Entry.push(
          clauseDictionary[exclusionCode]
        );
      }
    });
  }

  private scheduleEmployersLiabilityCoverage(
    policyPeriod: Period,
    policy: BopPolicy,
    bopId: string,
    effective: string,
    expires: string
  ) {
    const stateInfix = getEmployersLiabilityStateInfixFromState(
      policy.locations[0].locationDetails.state
    );

    const setCoverage = (cov: string): string => {
      return cov === 'Unlimited' ? cov : String(policy.parseInt(cov));
    };

    if (!policyPeriod.CommercialUmbrellaLine_CUE) {
      return;
    }

    policyPeriod.CommercialUmbrellaLine_CUE.AllClauses.Entry.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: setCoverage(
              <string>policy.excessLiability.excessLiabilityPerAccidentCoverage
            ),
            PatternCode: `CUP_ELBodilyInjuryByAccident${stateInfix}_CUE`,
          },
          {
            CovTermValueForRating_HUSA: setCoverage(
              <string>policy.excessLiability.excessLiabilityPerDiseaseCoverage
            ),
            PatternCode: `CUP_ELBodilyInjuryByDisease${stateInfix}_CUE`,
          },
          {
            CovTermValueForRating_HUSA: setCoverage(
              <string>policy.excessLiability.excessLiabilityPerPolicyCoverage
            ),
            PatternCode: `CUP_ELBodilyInjuryByDiseasePL${stateInfix}_CUE`,
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: TYPE_IDS.excessEmployersLiability,
      },
      Pattern: {
        Code: 'CUP_EmployersLiability_CUE',
      },
      ScheduledItems_HUSA: null,
    });

    policyPeriod.CommercialUmbrellaLine_CUE.ULPolicies.Entry.push({
      Carrier: null,
      EffDate: effective,
      ExpDate: expires,
      IsExternal: true,
      JobNumber: bopId,
      PolicyNumber: null,
      PolicyType: 'EL',
      TypeIDString: TYPE_IDS.excessEmployersLiability,
      ULPolicyAnswers_HUSA: {
        Entry: [],
      },
      VehicleFleetExposure: null,
    });
  }

  private scheduleAutoCoverage(
    policyPeriod: Period,
    policy: BopPolicy,
    bopId: string,
    effective: string,
    expires: string
  ) {
    const additionalExposure = _.some(
      Object.values(policy.excessLiability.excessCommercialAutoCoverageVehicleExposure || {})
    );
    const uiuimStates = policy.excessLiability.excessCommercialAutoCoverageStates;
    const fleetExposure = Object.assign(
      {},
      {
        AdditionalExposure: additionalExposure,
        Subtype: 'CUPVehicleFleet_CUE',
        TypeIDString: 'entity.CUPVehicleFleet_CUE:5803',
      },
      _.pick(policy.excessLiability.excessCommercialAutoCoverageVehicleCounts, [
        'PrivatePassengerVehicleCount',
        'LightTrucksCount',
        'MediumTrucksCount',
        'HeavyTrucksCount',
        'ExHvyTruckAndTractorLHCount',
        'ExHvyTruckAndTractorSHCount',
        'CementMixersCount',
        'BusesOver20PassengersCount',
      ]),
      _.pick(policy.excessLiability.excessCommercialAutoCoverageVehicleExposure, [
        'SchoolBusOrVan',
        'PoliceVehFireTrkAmbulance',
        'MoreThan10HtlMtlParkVan',
        'MoreThan15PassengerCourtseyVan',
        'RapidDevliveryOperations',
        'GasHaulHzdWasteRedLblMat',
        'CommodityIIIORIVHauling',
      ])
    ) as VehicleFleetExposure;

    const uiuimExposure = uiuimStates && _.some(Object.values(uiuimStates));

    if (!policyPeriod.CommercialUmbrellaLine_CUE) {
      return;
    }

    // Add underlying policy w/ vehicle / fleet exposure data
    policyPeriod.CommercialUmbrellaLine_CUE.ULPolicies.Entry.push({
      Carrier: null,
      EffDate: effective,
      ExpDate: expires,
      IsExternal: true,
      JobNumber: bopId,
      PolicyNumber: null,
      PolicyType: 'CA',
      TypeIDString: TYPE_IDS.excessCommercialAuto,
      ULPolicyAnswers_HUSA: {
        Entry: [
          {
            AnswerValueAsString: String(uiuimExposure),
            QuestionCode: 'CUPUIUIMEligibility',
          },
          {
            AnswerValueAsString: uiuimStates
              ? String(uiuimStates.hasCommercialAutoInWV || false)
              : 'false',
            QuestionCode: 'UIUIMWVExposure',
          },
        ],
      },
      VehicleFleetExposure: fleetExposure,
    });

    // Add Coverage Term w/ Combined Auto Limit
    policyPeriod.CommercialUmbrellaLine_CUE.AllClauses.Entry.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: String(
              policy.excessLiability.excessCommercialAutoCombinedLimit
            ),
            PatternCode: 'CUP_AMLCombSingleLimit_CUE',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: TYPE_IDS.excessCommercialAuto,
      },
      Pattern: {
        Code: 'CUP_AutoMobileLiability_CUE',
      },
      ScheduledItems_HUSA: null,
    });

    // Add UIUIM Exposure Clause if present
    if (uiuimExposure && uiuimStates) {
      policyPeriod.CommercialUmbrellaLine_CUE.AllClauses.Entry.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: String(uiuimStates.hasCommercialAutoInWV),
              PatternCode: 'CUP_UMUIMWV_SELECTED',
            },
            {
              CovTermValueForRating_HUSA: String(uiuimStates.hasCommercialAutoInFL),
              PatternCode: 'CUP_UMUIMFL_SELECTED',
            },
            {
              CovTermValueForRating_HUSA: String(uiuimStates.hasCommercialAutoInLA),
              PatternCode: 'CUP_UMUIMLA_SELECTED',
            },
            {
              CovTermValueForRating_HUSA: String(uiuimStates.hasCommercialAutoInNH),
              PatternCode: 'CUP_UMUIMNH_SELECTED',
            },
            {
              CovTermValueForRating_HUSA: String(uiuimStates.hasCommercialAutoInVT),
              PatternCode: 'CUP_UMUIMVT_SELECTED',
            },
            {
              CovTermValueForRating_HUSA: String(
                policy.excessLiability.excessCommercialAutoCombinedLimit
              ),
              PatternCode: 'CUP_UMUIMFL_CUE',
            },
            {
              CovTermValueForRating_HUSA: String(
                policy.excessLiability.excessCommercialAutoCombinedLimit
              ),
              PatternCode: 'CUP_UMUIMWV_CUE',
            },
            {
              CovTermValueForRating_HUSA: String(
                policy.excessLiability.excessCommercialAutoCombinedLimit
              ),
              PatternCode: 'CUP_UMUIMLA_CUE',
            },
            {
              CovTermValueForRating_HUSA: String(
                policy.excessLiability.excessCommercialAutoCombinedLimit
              ),
              PatternCode: 'CUP_UMUIMNH_CUE',
            },
            {
              CovTermValueForRating_HUSA: String(
                policy.excessLiability.excessCommercialAutoCombinedLimit
              ),
              PatternCode: 'CUP_UMUIMVT_CUE',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: TYPE_IDS.excess,
        },
        Pattern: {
          Code: 'CUP_UM_UIM_CUE',
        },
        ScheduledItems_HUSA: null,
      });
    }
  }

  public translateQuoteToManualSubmission(
    bopPolicyWithExcess: BopPolicy,
    bopPricedQuote: BopPricedQuote,
    insuredAccount: InsuredAccount
  ): ManualExcessQuoteSubmissionPayload {
    const translatedExcessDetails = this.translateExcessFormDataToQuestions(
      bopPolicyWithExcess.excessLiability
    );
    const translatedBopDetails = this.translateBopDetails(bopPolicyWithExcess, bopPricedQuote);
    const translatedAccountDetails = this.translateAccountDetails(insuredAccount);
    const accountNumber = insuredAccount.id;
    const bopQuoteNumber = bopPricedQuote.id;

    return {
      accountNumber: accountNumber,
      accountDetails: translatedAccountDetails,
      bopQuoteNumber: bopQuoteNumber,
      bopQuoteDetails: translatedBopDetails,
      excessQuote: translatedExcessDetails,
    };
  }

  public translateBopDetails(
    bopPolicy: BopPolicy,
    bopPricedQuote: BopPricedQuote
  ): ManualExcessBopDetails {
    const locationAddresses = this.getLocationsFromBopPolicy(bopPolicy);
    const bopClassCodes = this.getClassificationsFromBopPolicy(bopPolicy);
    const { squareFootage, payroll } = this.getTotalSqftPayrollFromBopPolicy(bopPolicy);
    const hasLiquorLiability =
      bopPolicy.liabilityCoverages.liquorLiability &&
      bopPolicy.liabilityCoverages.liquorLiability.optedIn;
    const liquorLiabAggregateLimit = hasLiquorLiability
      ? bopPolicy.liabilityCoverages.liquorLiability.aggregateLimit
      : undefined;
    const liquorLiabCommonCauseLimit = hasLiquorLiability
      ? bopPolicy.liabilityCoverages.liquorLiability.eachCommonCauseLimit
      : undefined;

    const hasHNOACoverage = bopPolicy.liabilityCoverages.acceptHiredNonOwnedAutoCoverage;
    const hasEBLCoverage =
      bopPolicy.liabilityCoverages.employeeBenefitsLiabilityCoverage &&
      bopPolicy.liabilityCoverages.employeeBenefitsLiabilityCoverage.optedIn;

    const glLimitPerOccurrence =
      bopPolicy.liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses;
    const glPremium =
      bopPricedQuote.allCosts &&
      bopPricedQuote.allCosts.find(
        (cost) =>
          cost.displayName.toLowerCase() ===
          'Business Liability coverage on Businessowners Line'.toLowerCase()
      );

    // NOTE: Keys are in a readable format for a manual submission for zendesk.
    return {
      'BOP Locations': locationAddresses.map((location, index) => {
        return {
          'Location Number': index + 1,
          'BOP Class Code(s)': bopClassCodes[index],
          ...location,
        };
      }),
      'Total Square Footage': squareFootage,
      'Total Payroll (if applicable)': payroll > 0 ? payroll : 'N/A',
      'Liquor Liability': hasLiquorLiability ? 'Yes' : 'No',
      'Liquor Liability Aggregate Limit': liquorLiabAggregateLimit,
      'Liquor Liability Each Common Cause Limit': liquorLiabCommonCauseLimit,
      'Hired + Non-owned Auto': hasHNOACoverage ? 'Yes' : 'No',
      'Employee Benefits Liability': hasEBLCoverage ? 'Yes' : 'No',
      'GL Per Occurrence Limit': glLimitPerOccurrence,
      'GL Premium': glPremium ? glPremium.actualAmount : undefined,
    };
  }

  private getTotalSqftPayrollFromBopPolicy(bopPolicy: BopPolicy) {
    let squareFootage = 0;
    let payroll = 0;
    bopPolicy.locations.forEach((location) => {
      location.buildings.forEach((building) => {
        squareFootage += parseMaskedInt(building.exposure.squareFootage);
        payroll += parseMaskedInt(building.exposure.payroll);
      });
    });
    return {
      payroll,
      squareFootage,
    };
  }

  private getClassificationsFromBopPolicy(bopPolicy: BopPolicy) {
    return bopPolicy.locations.map((location) => {
      return location.buildings.map((building) => {
        const descriptionCode = building.exposure.classification.code
          ? building.exposure.classification.code.descriptionCode
          : '';
        const classificationDetails = findFixtureByDescriptionCode(descriptionCode);
        // NOTE: Keys are in a readable format for a manual submission for zendesk.
        return {
          'BOP Class Code': classificationDetails ? classificationDetails.code : '',
          'Class Code Description': classificationDetails ? classificationDetails.description : '',
        };
      });
    });
  }

  private getLocationsFromBopPolicy(bopPolicy: BopPolicy) {
    return bopPolicy.locations.map((location) => {
      // NOTE: Keys are in a readable format for a manual submission for zendesk.
      return {
        'Address Line 1': location.locationDetails.addressLine1,
        'Address Line 2': location.locationDetails.addressLine2 || undefined,
        City: location.locationDetails.city,
        State: location.locationDetails.state,
        Zipcode: location.locationDetails.zip,
      };
    });
  }

  public translateAccountDetails(insuredAccount: InsuredAccount): ManualExcessAccountDetails {
    // NOTE: Keys are in a readable format for a manual submission for zendesk.
    return {
      'Company Name': insuredAccount.companyName,
      'Doing Business As': insuredAccount.doingBusinessAs,
      'Mailing Address': {
        'Address Line 1': insuredAccount.addressLine1,
        'Address Line 2': insuredAccount.addressLine2 || undefined,
        City: insuredAccount.city,
        State: insuredAccount.state,
        Zipcode: insuredAccount.zip,
      },
    };
  }

  public translateExcessFormDataToQuestions(
    excessLiabilityQuote: any,
    uwQuestions = false
  ): ManualExcessFormDetails {
    const translatedQuote: ManualExcessFormDetails = {};
    let key: keyof typeof excessLiabilityQuote;
    for (key in excessLiabilityQuote) {
      if (Object.prototype.hasOwnProperty.call(excessLiabilityQuote, key)) {
        const keyIsUwQuestions = key === 'uwQuestions';
        let translatedKey;

        if (uwQuestions) {
          translatedKey = UW_QUESTIONS[key].questionText || key;
        } else {
          translatedKey = EXCESS_LIABILITY_QUESTION_TEXT[key] || key;
        }

        let value = excessLiabilityQuote[key];

        if (typeof value === 'boolean') {
          value = value ? 'Yes' : 'No';
        }

        if (typeof value === 'object') {
          value = this.translateExcessFormDataToQuestions(value, keyIsUwQuestions);
        }
        translatedQuote[translatedKey] = value;
      }
    }
    return translatedQuote;
  }
}
