<app-left-navigation></app-left-navigation>

<div class="ai-checker-main">
  <h1 class="mb-1">Meet our Guideline AI ChatBot 👋</h1>
  <ng-container *ngIf="!hasSubmittedAiSearch">
    <p>
      Want to easily explore our BOP Underwriting Guidelines? Type a question below to understand
      class code eligibility or ask about specific coverages.
    </p>
    <p>We know your time is valuable, so let us do the work for you!</p>
    <p>
      <strong>Please note:</strong> This is only related to our BOP Guidelines, you can check out
      our
      <a [routerLink]="['/appetite']">Appetite Checker</a>
      for all lines of business or download our Guidelines at any time
      <a [routerLink]="['/support']">here</a>.
    </p>
  </ng-container>
  <app-dialog-box
    *ngIf="hasSearchError"
    [fullWidth]="true"
    header="Error confirming eligibility"
    type="error"
    >There was an error getting a response from our AI ChatBot. Please try again. If the problem
    persists, you can review our BOP guidelines
    <a [href]="BOP_GUIDELINE_LINK_ACCREDITED" target="_blank">here</a> or start a live chat for
    assistance.
  </app-dialog-box>
  <div>
    <ng-container *ngIf="hasSubmittedAiSearch">
      <strong>You asked:</strong>
      <p class="ai-search-text" *ngIf="hasSubmittedAiSearch">
        {{ aiSearchText }}
      </p>
    </ng-container>
    <ng-container *ngIf="!hasSubmittedAiSearch">
      <app-form-field-textarea
        cssClass="form-field__no-margin search-box"
        inputId="queryText"
        nameOfFormControl="queryText"
        placeholder="Example: Can we write for a risk in NY that installs cabinet tops and builds decks and privacy fences?"
        textareaClass="ai-checker-search-box"
        [form]="form"
      ></app-form-field-textarea>
      <button
        (click)="submitAiSearch()"
        (keypress)="submitAiSearch()"
        class="button button__primary"
      >
        Submit
      </button>
    </ng-container>
    <p class="p p__small-text p__gray">
      Disclaimer: This information is generated by an artificial intelligence language model, and
      while our goal is to strive for accuracy, please note that the information provided may not be
      entirely free from error and is dependent on several uncontrollable factors. As a result, we
      recommend that you independently verify the content and consult with our Customer Care Team
      for specific advice or information.
    </p>
  </div>
  <div class="ai-checker-progress-results" *ngIf="hasSubmittedAiSearch">
    <ul class="ai-checker-progress-message">
      <li>
        <img class="badge-list-img" src="/assets/img/ai_bot.svg" alt="ai chatbot" />
        <p class="ai-checker-progress-text badge-list-text">
          <span *ngIf="fetchingSearchResults">Working on it...</span>
          <span *ngIf="!fetchingSearchResults">AI ChatBot says...</span>
        </p>
      </li>
    </ul>
    <div class="ai-checker-results-container">
      <ng-container *ngIf="searchResults$ | async as searchResults">
        <p *ngIf="!searchResults.hasStructuredResponse">{{ searchResults.rawResult }}</p>
        <div *ngIf="searchResults.hasStructuredResponse">
          <p class="p__bold p__no-margin">Relevant Facts:</p>
          <ul
            [ngClass]="{
              'bulleted-list bulleted-list__tight': searchResults.relevantFacts?.length > 1
            }"
          >
            <li *ngFor="let fact of searchResults.relevantFacts">
              {{ fact }}
            </li>
          </ul>
          <div *ngIf="searchResults.reasons">
            <p class="p__bold p__no-margin">Reasoning:</p>
            <ul class="bulleted-list bulleted-list__tight">
              <li *ngFor="let reason of searchResults.reasons">
                {{ reason }}
              </li>
            </ul>
          </div>
          <div *ngIf="searchResults.conditions">
            <p class="p__bold p__no-margin">Conditions:</p>
            <ul class="bulleted-list bulleted-list__tight">
              <li *ngFor="let condition of searchResults.conditions">
                {{ condition }}
              </li>
            </ul>
          </div>
          <div *ngIf="searchResults.answer">
            <p class="p__bold">Answer:</p>
            <ul
              [ngClass]="{
                'bulleted-list bulleted-list__tight': searchResults.answer?.length > 1
              }"
            >
              <li *ngFor="let answer of searchResults.answer">
                {{ answer }}
              </li>
            </ul>
            <div
              class="ai-checker-quote-button-container"
              *ngIf="!streamingSearchResults && searchResults.mayBeEligible"
            >
              <p>
                <strong>Note: </strong>Full eligibility depends on a complete application and is
                subject to underwriting review. Please complete a quote using the form below to
                confirm eligibility.
              </p>
              <div class="nav-quote-button js-bop-quote-button">
                <div class="product-badge-group">
                  <app-product-badge product="bop-plus" pasSource="attune"></app-product-badge>
                  <div>
                    <h3 class="account-policies-header-h3">Businessowners' Policy</h3>
                    <p class="p p__no-margin">
                      Attune <span class="gray-text">(Accredited)</span>&nbsp;&nbsp;<a
                        href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+Plus+UW+Guidelines.pdf"
                        target="_blank"
                        >Learn more</a
                      >
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  routerLink="/accounts/new"
                  class="button button__small button__no-margin button__primary"
                >
                  Get a quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <button
    *ngIf="hasSubmittedAiSearch"
    (click)="editAiSearch()"
    (keypress)="editAiSearch()"
    class="button button__secondary"
  >
    Ask another question
  </button>
</div>
