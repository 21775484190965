import { switchMap, take, shareReplay } from 'rxjs/operators';
// Libraries
import { Component, OnInit } from '@angular/core';
// Models
import { BopPolicy, BopQuotePayload } from 'app/features/attune-bop/models/bop-policy';
// Services
import { AttuneBopQuoteFormService } from 'app/features/attune-bop/services/attune-bop-quote-form.service';
// Parent Component
import { AttuneBopQuoteEditFormComponent } from '../../components/attune-bop-quote-edit-form/attune-bop-quote-edit-form.component';
import { forkJoin, of as observableOf, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { QuoteResponse } from '../../../digital-carrier/models/types';
import { effectiveDateToPeriodDate } from 'app/features/attune-bop/models/date-helpers';
import {
  BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD,
  BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD,
} from '../../models/constants';
import { UpsellEarlyDeclineKey } from '../../../coalition/models/cyber-typings.model';

@Component({
  selector: 'app-attune-bop-quote-edit-form-page.app-page.app-page__form',
  templateUrl: '../attune-bop-quote-new-form-page/attune-bop-quote-new-form-page.component.html',
  providers: [AttuneBopQuoteFormService],
})
export class AttuneBopQuoteEditFormPageComponent
  extends AttuneBopQuoteEditFormComponent
  implements OnInit
{
  hasExcess: string;
  model: Observable<QuoteDetails>;
  policyId: string;
  parentQuoteId: string;
  shouldArchiveQuote = false;

  ngOnInit() {
    this.isEditing = true;
    super.ngOnInit();

    this.model = this.route.params.pipe(
      switchMap((params) => {
        this.parentQuoteId = params['jobNumber'];
        return this.bindService.getQuoteDetails(this.parentQuoteId);
      }),
      shareReplay()
    );

    if (this.route.queryParams) {
      this.sub.add(
        this.route.queryParams.pipe(take(1)).subscribe((query) => {
          if (query.new) {
            this.createQuoteFromMostRecentQuote = true;
          }
        })
      );
    }

    this.sub.add(
      this.model
        .pipe(
          switchMap((policyDetails) => {
            this.loading = true;
            return forkJoin([
              this.bopQuoteService.getTranslatedQuoteV2(this.parentQuoteId),
              policyDetails.linkedJobId
                ? this.excessQuoteService.getTranslatedQuote(policyDetails.linkedJobId)
                : observableOf(null),
            ]);
          })
        )
        .subscribe(
          ([bopResponse, excessResponse]) => {
            if (!bopResponse) {
              this.handleTranslationError(new Error('Could not retrieve quote'));
              return;
            }

            this.originalTranslatedQuote = bopResponse as BopQuotePayload;
            return this.patchForm(
              bopResponse as BopQuotePayload,
              true,
              excessResponse ? excessResponse.translatedQuote : null
            );
          },
          (err) => this.handleTranslationError(err)
        )
    );
  }

  // Set to true in the case where we are overriding eligibility to always be true
  // (for the sake of gathering data even about illegible class codes)
  // Further documentation here: https://www.notion.so/attune/ADP-eligibility-override-d16d9aa11d2e4f1abb8e17ed7c6ace81
  isEligibilityOverride() {
    return !!localStorage.getItem(`${BOP_GENERAL_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`);
  }
  isClassEligibilityOverride() {
    return !!localStorage.getItem(`${BOP_CLASS_ELIGIBILITY_OVERRIDE_FIELD}_${this.accountId}`);
  }

  createNewQuoteWhileEditing() {
    this.resetQuoteResults();
    this.displayNewOrOverwriteQuoteModal = false;
    this.doFinalSetupBeforeSubmitting();
  }

  overwriteQuoteWhileEditing() {
    this.shouldArchiveQuote = true;
    this.displayNewOrOverwriteQuoteModal = false;
    this.resetQuoteResults();
    this.doFinalSetupBeforeSubmitting();
  }

  doFinalSetupBeforeSubmitting() {
    // Begin quote game while eligibility runs
    this.requestQuoteService.quoteInProgress = true;

    // Clear out IRPM and rating model version if moving from one version to another
    if (this.originalTranslatedQuote?.policyInfo.bopVersion !== this.formService.bopVersion()) {
      this.formService.get('policyInfo.irpmVersion')?.reset();
      this.formService.get('policyInfo.ratingModelVersion')?.reset();
    }

    // Check eligibility one final time before quoting
    this.sub.add(
      this.checkEligibility().subscribe(() => {
        if (this.underWritingDeclineReasons.length === 0) {
          this.submitQuote();
        }
      })
    );
  }

  // TODO: Refactor to share logic with parent classes -- the only difference is the archive functionality
  submitQuote() {
    this.resetQuoteResults();
    const effectiveDate = effectiveDateToPeriodDate(
      this.formService.form.value.policyInfo.effectiveDate
    );

    // Omit standalone Cyber fields from the payload used for the BOP quote
    const bopFormValue = this.formService.getValueWithoutStandaloneCyberFields();
    const policy = new BopPolicy(bopFormValue, effectiveDate, this.tsRequestId);

    // If there is an eligibility override, alter the submission to guarantee a rejection
    if (this.isEligibilityOverride()) {
      policy.liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses = '';
    }
    this.trackSubmitAttempt();
    this.sendSegmentEvent('Quote Attempted');
    const insuredAccount = this.insuredAccountService.insuredSubject.getValue();
    this.uwAlertService
      .runUWAlert(insuredAccount.id, {
        insuredAccount: insuredAccount,
        newBopQuote: policy,
      })
      .subscribe();

    if (!this.parentQuoteId) {
      this.sentryService.notify('Could not determine job number to use for edit.', {
        severity: 'error',
        metaData: {
          retrievedQuote: this.parentQuoteId,
          accountId: insuredAccount.id,
        },
      });
    }

    this.sub.add(
      forkJoin(
        this.requestQuoteService.requestV3Quote({
          accountId: this.accountId,
          parentQuoteId: this.parentQuoteId,
          policy: policy,
          excessUwQuestionsHaveValidAnswers: this.uwQuestionsHaveValidAnswers,
          quoteGameEnabled: true,
        }),
        this.submitCoalitionCyberQuote()
      ).subscribe(
        ([bopResponse, cyberResponse]: [any, QuoteResponse | UpsellEarlyDeclineKey[] | null]) => {
          if (
            this.shouldArchiveQuote &&
            (!bopResponse.status ||
              (bopResponse.status < 300 && !(bopResponse instanceof HttpErrorResponse)))
          ) {
            this.insuredAccountService.archive(this.parentQuoteId).subscribe(
              () => {
                this.insuredAccountService.cachebust();
                const config: Record<string, any> = { isSampleQuote: this.isSampleQuote };
                if (cyberResponse) {
                  if (Array.isArray(cyberResponse)) {
                    config.secondaryQuoteEarlyDeclineReasons = cyberResponse;
                  } else {
                    config.secondaryQuoteResponse = cyberResponse;
                  }
                }

                this.updateQuote(bopResponse, this.accountId, config);
              },
              () => {
                this.quoteResultModalOpen = true;
                this.quoteIssue = true;
                this.quotingFailReasons = [
                  'There was an issue overwriting your quote but a new one was successfully created',
                ];
                this.requestQuoteService.hideQuoteGame();
              }
            );
          } else {
            const config: Record<string, any> = { isSampleQuote: this.isSampleQuote };
            if (cyberResponse) {
              if (Array.isArray(cyberResponse)) {
                config.secondaryQuoteEarlyDeclineReasons = cyberResponse;
              } else {
                config.secondaryQuoteResponse = cyberResponse;
              }
            }

            this.updateQuote(bopResponse, this.accountId, config);
          }
        }
      )
    );
  }

  handleTranslationError(err: Error) {
    console.error(err);
  }
}
