import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { US_DATE_MASK } from 'app/constants';
import { BopGWPolicyPayment } from 'app/features/attune-bop/models/bop-gw-policy-payment';
import { BopGWPolicyPaymentPresenter } from 'app/features/attune-bop/models/bop-gw-policy-payment-presenter';
import {
  minDateExceededValidator,
  maxDateExceededValidator,
  getControl,
  phoneValidator,
} from 'app/shared/helpers/form-helpers';
import { of as observableOf, Observable, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { POLICY_PAYMENT_PLAN_IDS } from 'app/features/attune-bop/models/constants';

import { GWBindService } from 'app/shared/services/gw-bind.service';
import { SentryService } from 'app/core/services/sentry.service';
import { DEBOUNCE_TIME } from 'app/hab/constants';
import { validateEmailAddress } from 'app/features/attune-bop/models/form-validators';

const DEFAULT_NUMBER_OF_PAYMENTS = 1;

@Injectable()
export class HabBindQuoteService extends FormDslSteppedFormBaseService {
  public form: UntypedFormGroup;

  originalEffectiveDate: string | null = null;
  isPastEffDateValid = false;
  habPolicyPayment: BopGWPolicyPayment | null = null;
  private policyDetailsSource = new ReplaySubject<QuoteDetails>();
  private policyPaymentPresenterSource = new ReplaySubject<BopGWPolicyPaymentPresenter>();

  policyDetails$ = this.policyDetailsSource.asObservable();
  policyPaymentPresenter$ = this.policyPaymentPresenterSource.asObservable();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public bindService: GWBindService,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService
  ) {
    super();
    this.initializeForm();
    this.syncAllSteps();
  }

  public initializeForm() {
    // Because of the way `moment` interprets dates, a month, or a month and day are technically valid, so the `minLength` validation is a quick fix to ensure an explicit year is included.

    // The default value is today's date and is replaced when policy is fetched
    this.form = this.formBuilder.group({
      lossRuns: this.formBuilder.array([this.newLossRun()], Validators.required),
      policyDetails: this.formBuilder.group({
        effectiveDate: [
          moment().format(US_DATE_MASK),
          [Validators.required, Validators.minLength(6), this.validateWithOriginalEffectiveDate],
        ],
        backdatedTermsAgreement: [null, [Validators.required, Validators.requiredTrue]],
        emailAddress: ['', [Validators.required, validateEmailAddress]],
        phoneNumber: ['', [Validators.required, phoneValidator]],
        inspectionContacts: this.formBuilder.array([]),
      }),
      paymentDetails: this.formBuilder.group({
        numberOfPayments: [DEFAULT_NUMBER_OF_PAYMENTS, Validators.required],
      }),
    });

    this.addBackdatedTermsControl();
  }

  public addBackdatedTermsControl() {
    const backdatedTermsFormControl = getControl(
      this.form,
      'policyDetails.backdatedTermsAgreement'
    );
    // Disable backdated terms checkbox by default.
    backdatedTermsFormControl.disable();

    // Display and require backdated effective date terms checkbox if effective date is before today.
    getControl(this.form, 'policyDetails.effectiveDate')
      .valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((effectiveDate) => {
        const today = moment.utc().startOf('day');
        const effectiveDateMomentObj = moment.utc(effectiveDate, US_DATE_MASK).startOf('day');

        if (effectiveDateMomentObj.isBefore(today) && this.isPastEffDateValid) {
          backdatedTermsFormControl.enable();
        } else {
          // Reset checkbox value and disable the form control.
          backdatedTermsFormControl.reset();
          backdatedTermsFormControl.disable();
        }
      });
  }

  setDetailsFromPolicy(quoteDetails: QuoteDetails) {
    this.addEffectiveDateToForm(quoteDetails.policyStart);
    if (quoteDetails.accountLocations) {
      for (let i = 0; i < quoteDetails.accountLocations.length; i++) {
        this.getInspectionContactFormArray().push(this.newInspectionContact());
      }
    }
    this.habPolicyPayment = new BopGWPolicyPayment(quoteDetails);
    getControl(this.form, 'paymentDetails.numberOfPayments').valueChanges.subscribe(
      (numberOfPayments) => {
        this.policyPaymentPresenterSource.next(
          new BopGWPolicyPaymentPresenter(
            this.habPolicyPayment as BopGWPolicyPayment,
            null,
            numberOfPayments
          )
        );
      }
    );
  }

  getDetailsFromPolicy(policyId: string) {
    return this.bindService.getQuoteDetails(policyId);
  }

  emitQuoteDetails(policyDetails: QuoteDetails) {
    this.policyDetailsSource.next(policyDetails);
    if (this.habPolicyPayment !== null) {
      this.policyPaymentPresenterSource.next(
        new BopGWPolicyPaymentPresenter(
          this.habPolicyPayment,
          null,
          getControl(this.form, 'paymentDetails.numberOfPayments').value
        )
      );
    }
  }

  // If a policy's effective date is after five days ago, update effective date field to that value
  // Otherwise effective date field will default to today
  addEffectiveDateToForm(policyStart: number) {
    this.originalEffectiveDate = moment.utc(policyStart).format(US_DATE_MASK);
    const fiveDaysAgo: moment.Moment = moment.utc().subtract(5, 'days').startOf('day');
    const isBeforeFiveDaysAgo: boolean = moment.utc(policyStart).isBefore(fiveDaysAgo);

    if (!isBeforeFiveDaysAgo) {
      const effectiveDate = moment.utc(policyStart).format(US_DATE_MASK);
      getControl(this.form, 'policyDetails.effectiveDate').patchValue(effectiveDate);
    } else {
      getControl(this.form, 'policyDetails.effectiveDate').patchValue(this.originalEffectiveDate);
    }
    const today: moment.Moment = moment.utc().startOf('day');
    const isBeforeToday: boolean = moment.utc(policyStart).isBefore(today);
    this.isPastEffDateValid = !isBeforeFiveDaysAgo && isBeforeToday;
  }

  public validateWithOriginalEffectiveDate = (formControl: UntypedFormControl) => {
    const fiveDaysAgo: moment.Moment = moment.utc().subtract(5, 'days').startOf('day');

    const threeMonthsFromToday: moment.Moment = moment.utc().add(3, 'months');

    const minDateValidation = minDateExceededValidator(fiveDaysAgo)(formControl);
    const maxDateValidation = maxDateExceededValidator(threeMonthsFromToday)(formControl);
    const originalDateValidation =
      this.originalEffectiveDate === formControl.value
        ? null
        : { notOriginalEffectiveDate: { value: formControl.value } };
    // Validate to ensure that effective date is either the original effective date (within 5 days in the past from today) or today -> 3 months in the future.
    if (minDateValidation) {
      return minDateValidation;
    } else if (maxDateValidation) {
      return maxDateValidation;
    } else if (originalDateValidation) {
      if (!minDateExceededValidator(moment(0, 'HH'))(formControl)) {
        return null;
      }
      return originalDateValidation;
    }
  };

  public fillInHappyPath(): void {}
  public generateSteps(): RouteFormStep[] {
    return [
      {
        args: {},
        displayName: 'Loss runs',
        slug: 'loss-runs',
        parent: 'loss-runs',
        formPath: 'lossRuns',
      },
      {
        args: {},
        displayName: 'Policy details',
        slug: 'policy-details',
        parent: 'policy-details',
        formPath: 'policyDetails',
      },
      {
        args: {},
        displayName: 'Payment details',
        slug: 'payment-details',
        parent: 'payment-details',
        formPath: 'paymentDetails',
      },
    ].filter(this.isStep);
  }

  public getEffectiveDateValidationMessage() {
    const effectiveDateField = getControl(this.form, 'policyDetails.effectiveDate');
    if (effectiveDateField.valid) {
      return null;
    }

    if (this.isPastEffDateValid) {
      return `<strong>Hold on!</strong> Looks like your originally quoted effective date was ${this.originalEffectiveDate}.
        You may bind this policy with an effective date of ${this.originalEffectiveDate} or a date between today and
        three months from today.`;
    }
    return 'Please enter a date between today and three months from today.';
  }

  public getLossRunFormArray(): UntypedFormArray {
    return this.form.get('lossRuns') as UntypedFormArray;
  }

  public addLossRun(): void {
    this.getLossRunFormArray().push(this.newLossRun());
  }

  public removeLossRun(index: number): void {
    this.getLossRunFormArray().removeAt(index);
  }

  private newLossRun(): UntypedFormGroup {
    return this.formBuilder.group({
      fileName: [null, Validators.required],
    });
  }

  private newInspectionContact(): UntypedFormGroup {
    return this.formBuilder.group({
      inspectorEmailAddress: ['', [Validators.required, validateEmailAddress]],
      inspectorPhoneNumber: ['', [Validators.required, phoneValidator]],
      inspectorName: ['', [Validators.required]],
    });
  }

  private getInspectionContactFormArray(): UntypedFormArray {
    return this.form.get('policyDetails.inspectionContacts') as UntypedFormArray;
  }

  public sendForm(
    accountId: string,
    policyId: string,
    policyDetails: QuoteDetails
  ): Observable<QSQuoteSubmission[] | null> {
    return this.insuredAccountService.get(accountId).pipe(
      switchMap((account: InsuredAccount) => {
        account.inspectionContacts = this.getInspectionContactFormArray().controls.map(
          (inspectionContactField, index) => {
            return {
              // We're asserting that accountLocations is non-null because we wouldn't have inspection contacts if account locations was non-null
              /* eslint-disable-next-line  @typescript-eslint/no-non-null-assertion */
              addressId: policyDetails.accountLocations![index].PublicID,
              email: (inspectionContactField as UntypedFormGroup).controls.inspectorEmailAddress
                .value,
              phone: (inspectionContactField as UntypedFormGroup).controls.inspectorPhoneNumber
                .value,
              name: (inspectionContactField as UntypedFormGroup).controls.inspectorName.value,
            };
          }
        );
        const emailAddress = getControl(this.form, 'policyDetails.emailAddress').value;
        const phoneNumber = getControl(this.form, 'policyDetails.phoneNumber').value;
        const { EmailAddress1, WorkPhone } = policyDetails.contact;

        // If insured contact details have changed, edit those values at the same time we add inspection contact info to account
        if (emailAddress !== EmailAddress1 || phoneNumber.replace(/[^0-9 ]/g, '') !== WorkPhone) {
          account.emailAddress = emailAddress;
          account.phoneNumber = phoneNumber;
        }
        return observableOf(account);
      }),
      switchMap((account: InsuredAccount) => {
        return this.insuredAccountService.edit(account);
      }),
      switchMap(() => this.submitBindRequest(policyId)),
      catchError((error) => {
        this.sentryService.notify('Unable to bind quote', {
          severity: 'error',
          metaData: {
            accountId,
            policyId,
            policyDetails,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  private submitBindRequest(policyId: string): Observable<QSQuoteSubmission[] | null> {
    // This is only required by GW if the effective date is in the past.
    let agreedToBackdatedEffectiveDateTerms: boolean | undefined;
    const backdatedTermsAgreementControl = getControl(
      this.form,
      'policyDetails.backdatedTermsAgreement'
    );
    if (backdatedTermsAgreementControl.enabled) {
      agreedToBackdatedEffectiveDateTerms = backdatedTermsAgreementControl.value;
    }

    return this.bindService
      .bind(
        POLICY_PAYMENT_PLAN_IDS[getControl(this.form, 'paymentDetails.numberOfPayments').value],
        policyId,
        'Habitational',
        undefined,
        agreedToBackdatedEffectiveDateTerms
      )
      .pipe(map(() => null));
  }
}
