<div id="bind-excess-umuim-{{ stateCode }}">
  <app-form-field-radio
    inputId="bind-excess-umuim-{{ stateCode }}-input-accept"
    [form]="formGroup"
    nameOfFormControl="accepted"
    questionText="{{ stateName }}"
    [submitted]="submitted"
    [options]="options"
  >
  </app-form-field-radio>
  <app-form-field-select
    inputId="bind-excess-umuim-{{ stateCode }}-input-limit"
    [form]="formGroup"
    [submitted]="submitted"
    cssClass="form-subsection"
    labelText="Limit"
    nameOfFormControl="limit"
    [readonly]="!accepted"
    [availableOptions]="getUMUIMLimitOptions(stateCode, excessCLLimit)"
  >
  </app-form-field-select>
</div>
