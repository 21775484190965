<section *ngIf="showReferrals" class="policies-overview-sidebar-content referrals-container">
  <div class="referrals-header-container">
    <div class="referrals-header">
      <h3>Earn $5 per invitee that quotes</h3>
      <p class="p__gray">You and your invitee earn $5 when they do their first quote</p>
    </div>
    <div class="referrals-icon">
      <img src="/assets/img/gift-cards-icon.png" alt="gift cards" />
    </div>
  </div>
  <table>
    <thead>
      <th>Name, Email</th>
      <th class="td__right">Invite Status</th>
    </thead>
    <tbody>
      <tr *ngFor="let referralUser of getReferralUsers()">
        <td>{{ referralUser.firstName }} {{ referralUser.lastName }}, {{ referralUser.email }}</td>
        <td [ngSwitch]="referralUser.status" class="td__right">
          <a
            *ngSwitchCase="'not-invited'"
            (click)="openModal(referralUser)"
            (keyup.enter)="openModal(referralUser)"
            data-amplitude-input-name="referrals-invite-open-modal"
            >{{ referralUser.statusLabel }}</a
          >
          <span *ngSwitchCase="'invited'" class="referrals-invited">{{
            referralUser.statusLabel
          }}</span>
          <span *ngSwitchCase="'complete'">{{ referralUser.statusLabel }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <a (click)="showMore()" (keyup.enter)="showMore()" *ngIf="!hideShowMore">Show more</a>
</section>
<ng-container *ngIf="selectedUser">
  <app-modal [open]="selectedUser" (handleModalClose)="closeModal()" modalClass="app-modal-content">
    <h2>Earn $5 when {{ selectedUser.firstName }} {{ selectedUser.lastName }} quotes</h2>
    <p>
      {{ selectedUser.firstName }} {{ selectedUser.lastName }} ({{ selectedUser.email }}) must click
      the link in the email we’ll send them and then quote for each of you to receive the reward.
    </p>
    <p>We’ll notify you when they have completed their quote.</p>
    <button
      class="button button__primary"
      data-amplitude-input-name="referrals-invite-send"
      (click)="sendReferral(selectedUser)"
      [ngClass]="{ button__disabled: sendingReferral }"
      [disabled]="sendingReferral"
    >
      Send Invite
    </button>
  </app-modal>
</ng-container>
