import { isDevMode, OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of as observableOf, Subject, Observable, Subscription, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

@Component({
  template: '',
})
export abstract class FormDslSteppedFormBaseComponent<
  FormServiceType extends FormDslSteppedFormBaseService
> implements OnInit, OnDestroy
{
  public form: UntypedFormGroup;
  public formStepSub: Subscription;
  public formSubmitSub: Subscription;
  public isDevMode = isDevMode();
  public tsRequestId: string;
  public loading = true;
  public loadingSuccess$ = new Subject<boolean>();
  public quoting = false;
  public quotingSuccess$ = new Subject<boolean>();

  constructor(
    @Inject(FormDslSteppedFormBaseService) public formService: FormServiceType,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  ngOnInit() {
    this.generateRequestId();

    this.form = this.formService.form;

    this.formService.resetToFirstStep();

    this.navigateToCurrentStep();

    this.loadInitialData();

    this.formStepSub = this.formService.incrementedStep$.subscribe((nextStep: RouteFormStep) => {
      this.onIncrementedStep(nextStep);
    });

    this.createFormSubmitSubscription();
  }

  ngOnDestroy() {
    if (this.formStepSub !== undefined) {
      this.formStepSub.unsubscribe();
    }

    if (this.formSubmitSub !== undefined) {
      this.formSubmitSub.unsubscribe();
    }
  }

  public doneLoading(delay: number) {
    return timer(delay).pipe(
      tap(() => {
        this.loading = false;
        this.quoting = false;
      })
    );
  }

  public generateRequestId() {
    this.tsRequestId = uuidv4();
  }

  public clickBackward() {
    if (this.formService.stepBackward()) {
      this.navigateToCurrentStep();
    }
  }

  public handleSubmit(submitEvent?: Event): boolean {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    return this.formService.stepForward();
  }

  public submitted(): boolean {
    return this.formService.submitted;
  }

  public currentStep(): RouteFormStep {
    return this.formService.currentStep;
  }

  public isFirstStep(): boolean {
    return this.formService.isFirstStep();
  }

  public isFinalStep(): boolean {
    return this.formService.isFinalStep();
  }

  public isCurrentStepValid(): boolean {
    return this.formService.isCurrentStepValid();
  }

  public isCurrentStep(slug: string): boolean {
    const currentSlug = this.formService.currentStep.slug;
    const slugRegex = new RegExp('^' + slug + '$');
    return currentSlug ? currentSlug.match(slugRegex) !== null : false;
  }

  protected navigateToCurrentStep(): void {
    const currStep = this.formService.currentStep;
    const currentUrlTree = this.router.createUrlTree([currStep.slug, currStep.args || {}], {
      queryParamsHandling: '',
      relativeTo: this.route,
    });

    this.router.navigateByUrl(currentUrlTree);
  }

  public handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep(), step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  public getValidationMessage() {
    return this.formService.getValidationMessage() || 'Please fill out all required fields';
  }

  public checkQuotingFailure(error: any): Observable<boolean> {
    // If an error is encountered, we assume it is a failure.
    // This method can be overridden in extended classes to add additional error-checking logic.
    return observableOf(false);
  }

  public abstract onIncrementedStep(nextStep: RouteFormStep): void;

  public abstract checkQuotingSuccess(response: any): Observable<boolean>;

  public abstract loadInitialData(): void;

  public abstract sendForm(): Observable<any>;

  public abstract handleSuccessfulQuote(): void;

  public abstract handleFailedQuote(): void;

  private createFormSubmitSubscription(): void {
    if (!this.formSubmitSub) {
      this.formSubmitSub = new Subscription();
    }
    this.formSubmitSub.add(
      this.formService.submittedForm$
        .pipe(
          tap(() => {
            this.quoting = true;
          }),
          switchMap(() => this.sendForm()),
          switchMap((success: any) => this.checkQuotingSuccess(success)),
          catchError((error: any) => {
            // We need to re-subscribe to the form submit subject since the stream switches after emitting an error. The previous subscribe will no longer be listenining on form submits (e.g. retry).
            // https://rxjs.dev/api/operators/catchError
            this.createFormSubmitSubscription();
            return this.checkQuotingFailure(error);
          })
        )
        .subscribe((success: boolean) => {
          if (!success) {
            this.doneLoading(2000).subscribe(() => {
              this.handleFailedQuote();
            });
          } else {
            // Show success screen and go to summary
            this.quotingSuccess$.next(true);
            return timer(2000).subscribe(() => {
              this.handleSuccessfulQuote();
            });
          }
        })
    );
  }
}
