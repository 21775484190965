<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div class="{{ cssClass }} form-field" [ngClass]="{ 'form-field__error': fieldInvalid() }">
    <label [for]="inputId" [attr.data-specifier]="specifierText">
      {{ labelText }}
      <div
        *ngIf="tooltipText"
        class="more-info-tip tool-tip tool-tip__large"
        attr.data-tooltip="{{ tooltipText }}"
      >
        <span class="icon icon-question"></span>
      </div>
    </label>
    <ul *ngIf="ul" class="bulleted-list">
      <li *ngFor="let listItem of ul.li">
        {{ listItem }}
      </li>
    </ul>
    <div class="select-container" [ngClass]="{ 'select-container__readonly': readonly }">
      <ng-container *ngIf="!showOptionGroups">
        <select
          #selectRef
          [id]="inputId"
          [name]="inputId"
          [formControl]="form.get(nameOfFormControl)"
          attr.aria-describedby="{{ describedBy || '' }}"
        >
          <option value="" disabled hidden [selected]="!form.get(nameOfFormControl).value">
            {{ placeholder }}
          </option>
          <option
            *ngFor="let availableValue of mappedOptions | keys"
            value="{{ availableValue }}"
            [attr.selected]="
              availableValue === form.get(nameOfFormControl).value ? 'selected' : null
            "
          >
            <ng-container [ngSwitch]="getTypeOf(mappedOptions[availableValue])">
              <ng-container *ngSwitchCase="'number'">
                {{ mappedOptions[availableValue] | currency: 'USD':'symbol':'1.0-0' }}
              </ng-container>
              <ng-container *ngSwitchCase="'string'">
                {{ mappedOptions[availableValue] }}
              </ng-container>
            </ng-container>
          </option>
        </select>
      </ng-container>
      <select
        [id]="inputId"
        [name]="inputId"
        [formControl]="form.get(nameOfFormControl)"
        *ngIf="showOptionGroups"
      >
        <option value="" disabled hidden [selected]="!form.get(nameOfFormControl).value">
          {{ placeholder }}
        </option>
        <ng-container *ngFor="let optionGroupValue of mappedOptionsWithOptionGroups | keys">
          <optgroup label="{{ optionGroupValue }}">
            <option
              *ngFor="let optionValue of mappedOptionsWithOptionGroups[optionGroupValue]"
              value="{{ optionValue }}"
              [attr.selected]="
                optionValue === form.get(nameOfFormControl).value ? 'selected' : null
              "
            >
              <ng-container [ngSwitch]="getTypeOf(optionValue)">
                <ng-container *ngSwitchCase="'number'">
                  {{ optionValue | currency: 'USD':'symbol':'1.0-0' }}
                </ng-container>
                <ng-container *ngSwitchCase="'string'">
                  {{ optionValue }}
                </ng-container>
              </ng-container>
            </option>
          </optgroup>
        </ng-container>
      </select>
    </div>
    <p class="form-field-note" *ngIf="questionNote" [innerHTML]="questionNote"></p>
  </div>
</ng-container>
