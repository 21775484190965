<div id="building-{{ locationIndex }}-{{ buildingIndex }}">
  <h1>Lessors' Risk Details</h1>
  <h3 class="question-subsection-header">Tenant Information</h3>
  <p>Please provide information for all tenants below, including vacancies:</p>

  <!-- LRO Tenants table -->
  <section
    class="multi-form-item lro-tenant-row"
    *ngFor="let tenant of tenantsFormArray.controls; index as i; first as largestTenant"
  >
    <div class="form-split">
      <app-form-field-select
        [submitted]="submitted"
        [form]="tenant"
        nameOfFormControl="occupancyStatus"
        labelText="Occupancy Status"
        cssClass="form-field__no-margin"
        [availableOptions]="['Occupied', 'Vacant']"
        [readonly]="largestTenant"
        inputId="building-{{ locationIndex }}-{{ buildingIndex }}-tenant-{{ i + 1 }}-occupancy"
      >
      </app-form-field-select>
      <app-form-field-text
        [form]="tenant"
        nameOfFormControl="squareFootage"
        labelText="Square Footage"
        [submitted]="submitted"
        [cssClass]="getCssForSquareFootageField(largestTenant)"
        [readonly]="largestTenant"
        inputId="building-{{ locationIndex }}-{{ buildingIndex }}-tenant-{{ i + 1 }}-squareFootage"
        appDelimitedNumberMask
      >
      </app-form-field-text>
    </div>
    <ng-container *ngIf="tenant.controls.occupancyStatus.value === 'Occupied'">
      <div class="form-split">
        <app-form-field-text
          [form]="tenant"
          nameOfFormControl="tenantName"
          labelText="Tenant Name"
          [submitted]="submitted"
          cssClass="form-field__no-margin"
          [readonly]="largestTenant"
          inputId="building-{{ locationIndex }}-{{ buildingIndex }}-tenant-{{ i + 1 }}-name"
        >
        </app-form-field-text>
        <app-form-field-text
          [form]="tenant"
          nameOfFormControl="rent"
          labelText="Monthly Rent"
          [submitted]="submitted"
          cssClass="form-field__no-margin"
          [readonly]="largestTenant"
          inputId="building-{{ locationIndex }}-{{ buildingIndex }}-tenant-{{ i + 1 }}-rent"
          placeholder="$"
          appMoneyMask="WITHOUT_DECIMAL"
        >
        </app-form-field-text>
      </div>
      <app-form-field-text
        [form]="tenant"
        nameOfFormControl="descriptionOfBusiness"
        labelText="Description of Business"
        [submitted]="submitted"
        cssClass="form-field__no-margin"
        [readonly]="largestTenant"
        inputId="building-{{ locationIndex }}-{{ buildingIndex }}-tenant-{{ i + 1 }}-description"
      ></app-form-field-text>
    </ng-container>

    <div class="nav-button-group">
      <a
        class="button button__small button__secondary js-delete-tenant-{{ i + 1 }}-button"
        (click)="removeTenant(i)"
        (keyup.enter)="removeTenant(i)"
        *ngIf="!largestTenant"
      >
        Remove
      </a>
    </div>
  </section>

  <button type="button" class="button button__secondary button__small" (click)="addTenant()">
    Add Additional tenant
  </button>

  <div class="lro-totals">
    <h3>
      Entered Square Footage:
      <span
        id="building-{{ locationIndex }}-{{ buildingIndex }}-entered-square-footage"
        [ngClass]="{ 'invalid-total-square-footage': !(tenantRentIsValid$ | async) }"
        >{{ tenantTotalSquareFootage$ | async | number }}</span
      >
    </h3>
    <h3>
      Building Square Footage:
      <span id="building-{{ locationIndex }}-{{ buildingIndex }}-building-sqft">{{
        buildingSquareFootage
      }}</span>
    </h3>
    <h3>
      Total Rent:
      <span id="building-{{ locationIndex }}-{{ buildingIndex }}-total-rent"
        >${{ tenantTotalRent$ | async | number }}</span
      >
    </h3>
  </div>

  <!-- Rent roll uploads -->

  <app-form-field-file
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-rent-roll-upload"
    labelText="Attach rent roll"
    [form]="buildingLessorsRiskFormGroup"
    cssClass="lro-file-uploader"
    nameOfFormControl="rentRollFiles"
    [submitted]="submitted"
    accept=".pdf"
    [fileMetadata]="getFileUploadMetadata()"
  ></app-form-field-file>

  <!-- Risk transfer information section -->
  <h3 class="question-subsection-header">Risk Transfer Information</h3>

  <section class="lro-checkbox-group">
    <h3 class="lro-checkbox-title">
      Which of the following items does the landlord require of all tenants:
    </h3>
    <app-form-field-checkbox
      [form]="landlordRequirementsFormGroup"
      nameOfFormControl="writtenLease"
      labelText="Written lease between landlord and tenant"
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-written-lease"
      [submitted]="submitted"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      [form]="landlordRequirementsFormGroup"
      nameOfFormControl="tenantInsuranceValidated"
      labelText="Tenant's insurance policy is validated at time of lease signing and renewal"
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-insurance-validated"
      [submitted]="submitted"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      [form]="landlordRequirementsFormGroup"
      nameOfFormControl="landlordAdditionalInsured"
      labelText="Landlord named as Additional Insured"
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-landlord-ai"
      [submitted]="submitted"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      [form]="landlordRequirementsFormGroup"
      nameOfFormControl="landlordLimits"
      labelText="The tenant's GL limits are required to be greater than or equal to the landlord's limits"
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-landlord-limits"
      [submitted]="submitted"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      [form]="landlordRequirementsFormGroup"
      nameOfFormControl="primaryNonContributory"
      labelText="The tenant's policy is required to be primary and non-contributory."
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-primary-noncontributory"
      [submitted]="submitted"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      [form]="landlordRequirementsFormGroup"
      nameOfFormControl="waiverOfSubrogation"
      labelText="The tenant's policy includes a waiver of subrogation in favor of the insured."
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-waiver-of-subrogation"
      [submitted]="submitted"
    ></app-form-field-checkbox>
  </section>

  <section class="lro-checkbox-group">
    <h3 class="lro-checkbox-title">
      Which of the following expenses are tenants responsible for:
      <span
        class="more-info-tip tool-tip tool-tip__large"
        data-tooltip="AKA “net” leases. When a tenant is responsible for property taxes only, it's a “single net lease”. When a tenant is responsible for property taxes, insurance, and maintenance, it's a “triple net lease”."
      >
        <span class="icon icon-question"></span>
      </span>
    </h3>
    <app-form-field-checkbox
      [form]="tenantResponsibilitiesFormGroup"
      nameOfFormControl="propertyTaxes"
      labelText="Property taxes"
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-property-taxes"
      [submitted]="submitted"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      [form]="tenantResponsibilitiesFormGroup"
      nameOfFormControl="propertyInsurance"
      labelText="Property insurance"
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-property-insurance"
      [submitted]="submitted"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      [form]="tenantResponsibilitiesFormGroup"
      nameOfFormControl="propertyMaintenance"
      labelText="Property maintenance"
      cssClass="form-field__no-margin"
      inputId="building-{{ locationIndex }}-{{ buildingIndex }}-property-maintenance"
      [submitted]="submitted"
    ></app-form-field-checkbox>
  </section>

  <app-form-field-radio
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-property-management"
    [form]="buildingLessorsRiskFormGroup"
    nameOfFormControl="propertyManagementCompany"
    [submitted]="submitted"
    questionText="Does the applicant use a property management company?"
  ></app-form-field-radio>
  <app-form-field-radio
    inputId="building-{{ locationIndex }}-{{ buildingIndex }}-common-ownership"
    [form]="buildingLessorsRiskFormGroup"
    nameOfFormControl="commonOwnershipWithTenants"
    [submitted]="submitted"
    questionText="Does the applicant share common ownership with any tenants?"
  ></app-form-field-radio>
</div>
