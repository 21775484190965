import { Component, isDevMode, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  RouteFormStep,
  FormDslSteppedFormBaseService,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { HISCOX_API_VERSION, HISCOX_PRODUCTS } from 'app/features/hiscox/models/hiscox-types';

@Component({
  selector: 'app-hiscox-quote-form-sidebar-inspection',
  templateUrl: './hiscox-quote-form-sidebar-inspection.component.html',
})
export class HiscoxQuoteFormSidebarInspectionComponent {
  @Input() fillInHappyPath: Function;
  @Input() fillInIneligibleLocation: Function;
  @Input() accountId: string;
  @Input() insAccount: InsuredAccount;
  @Input() steppedFormService: FormDslSteppedFormBaseService;
  @Input() product: string;
  @Input() version: HISCOX_API_VERSION;
  @Input() pasSource = '';
  @Input() form: UntypedFormGroup;
  @Input() sidebarTitle: string;
  @Input() disableNavigationToStep: boolean;
  @Input() showCancelButton = true;
  @Output() navigateToStep: EventEmitter<string> = new EventEmitter();

  isDevMode = isDevMode();

  happyPathHandler() {
    this.fillInHappyPath();
  }

  isCurrentStep(step: RouteFormStep) {
    return step.slug === this.steppedFormService.currentStep.slug;
  }

  isNavigable(step: RouteFormStep) {
    return this.steppedFormService.stepDifference(this.steppedFormService.currentStep, step) <= 1;
  }

  handleStepClicked(step: RouteFormStep) {
    if (this.isNavigable(step)) {
      this.navigateToStep.emit(step.slug);
    }
  }

  getSteps() {
    return this.steppedFormService.getSteps();
  }

  // The happy path button is only shown for PL at the moment. In the future, we may want to show it
  // for GL as well.
  showHappyPathButton() {
    return this.product === HISCOX_PRODUCTS.pl;
  }
}
