export enum ToastTemplate {
  REWARD,
  INFORM,
  BILLING,
}

export interface Toast {
  id: string;
  type: ToastTemplate;
  removing: boolean;
}
