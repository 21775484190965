<div class="quoting-game-score" id="quote-game-score">
  <p
    *ngIf="showQuoteGameTip()"
    class="education-pane education-pane__inline education-caret-bottom js-quote-game-tip"
    id="quote-game-score"
    role="tooltip"
  >
    Quotes take about 30 seconds to process. In the meantime, click the flying quotes to play our
    famous "quote game." Keep an eye out for the golden quote.
  </p>
  <span class="sr-only">Score:</span>
  {{ score * 100 }}
  <p class="quoting-game-instruction" *ngIf="!score">Click the quotes to play.</p>
</div>

<ng-container *ngFor="let bonus of bonusPoints">
  <p
    role="alert"
    [ngStyle]="{ 'left.px': bonus.positionX, 'top.px': bonus.positionY }"
    class="quoting-game-bonus"
    [ngClass]="{
      'quoting-game-bonus__three': bonus.points === 2,
      'quoting-game-bonus__four': bonus.points === 3 || bonus.points > 3
    }"
  >
    {{ bonus.points * 100 }} combo bonus!
  </p>
</ng-container>

<ng-container *ngIf="showProgressBar">
  <app-quoting-progress
    [title]="title"
    [successComment]="successComment"
    [comments]="[
      'Dotting the i\'s...',
      'Crossing the t\'s...',
      'Spell checking...',
      'Warming up the printer...',
      'Looking for the stapler...'
    ]"
    [finish$]="finish$"
    successImage="assets/img/approved.png"
    allowClicks="true"
  ></app-quoting-progress>
</ng-container>
<canvas
  #canvas
  id="quoting-game"
  (mousedown)="handleClick($event)"
  (mousemove)="handleMove($event)"
></canvas>
