import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-center-marketing-modal',
  templateUrl: './help-center-marketing-modal.component.html',
})
export class HelpCenterMarketingModalComponent {
  @Input() open = false;
  @Output() closeHelpModal: EventEmitter<null> = new EventEmitter();

  constructor(protected router: Router) {}

  closeModal() {
    this.closeHelpModal.emit();
  }

  closeModalAndNavigate() {
    this.closeModal();
    this.router.navigate(['/support']);
  }
}
