import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormGroupDirective,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { Observable, concat, of as observableOf } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import * as moment from 'moment';
import { mapValues } from 'lodash';

import {
  InvoicesPaymentPlanService,
  PaymentPlanInformation,
} from '../../services/invoices-payment-plan.service';
import { InvoicesService } from '../../services/invoices.service';
import { InsuredCurrentPolicyInfo } from '../../pages/invoices-insured-page/invoices-insured-page.component';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { SentryService } from '../../../../core/services/sentry.service';

import { UNAUTHENTICATED_ROUTE } from 'app/features/support/models/support-help-center-constants';
import { US_DATE_SINGLE_DIGIT_MASK } from 'app/constants';

@Component({
  selector: 'app-invoices-payment-plan-form',
  templateUrl: './invoices-payment-plan-form.component.html',
})
export class InvoicesPaymentPlanFormComponent implements OnInit {
  @Output() closePaymentPlanModal = new EventEmitter<void>();
  @Output() openPayoffModal = new EventEmitter<void>();

  @Input() isCancelled = false;
  @Input() billingStatus: 'next' | 'current' | 'overdue';
  @Input() relevantPolicy: InsuredCurrentPolicyInfo;
  @Input() futurePolicy?: InsuredCurrentPolicyInfo;
  @Input() remainingAnnualPremium: number | null;
  @Input() hasPlanConflict: boolean;

  @Input() invoicePid: string;
  @Input() invoiceToken: string;

  @Input() billedInvoices: BackendListInvoice[];

  policyRecord: Record<string, string[]> = { '': [''] };
  paymentPlanForm: UntypedFormGroup;
  isProcessing = false;
  hasPolicyNumbers = false;
  loggedChangePreventedDelinquent = false;
  loggedChangePreventedPaidInv = false;
  helpCenterPath = UNAUTHENTICATED_ROUTE;
  errorMessage: string | null;
  isTargetingRenewalOrRollover = false;
  currentPaymentPlan = '';

  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;

  private changeRequests: Observable<'Success' | 'Failure' | HttpErrorResponse>[] = [];

  private readonly planMap: Record<string, string> = {
    '1-Payment': 'FullPay',
    '4-Payments': 'FourPay',
    '10-Payments': 'TenPay',
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private paymentPlanService: InvoicesPaymentPlanService,
    private invoiceService: InvoicesService,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.buildPaymentPlanForm();
    this.invoiceService
      .getAssociatedPolicies(this.invoicePid, this.invoiceToken)
      .subscribe((response: BackendInvoiceAssociatedPolicyResponse) => {
        this.policyRecord = this.getPolicyRecord(response.associatedPolicies, this.relevantPolicy);
        this.hasPolicyNumbers = this.policyRecord
          ? !!this.policyRecord[Object.keys(this.policyRecord)[0]]
          : false;
      });
  }

  buildPaymentPlanForm() {
    this.paymentPlanForm = this.formBuilder.group({
      policyNumber: ['', [Validators.required]],
      newPaymentPlan: [
        this.planMap[this.normalizePaymentPlan(this.relevantPolicy.paymentPlans)],
        [Validators.required],
      ],
    });
  }

  getPolicyRecord(
    allPolicies: BackendInvoiceAssociatedPolicy[],
    relevantPolicy: InsuredCurrentPolicyInfo
  ): Record<string, string[]> {
    const boundScheduledPolicyNumbers = this.getBoundScheduledPolicyNumbers(allPolicies);
    const policyRecord: Record<string, string[]> = {};
    let startDate, endDate;
    let policyNumbers: string[] = [];

    if (this.hasUnpaidInvoicesOnRelevantPolicy() || boundScheduledPolicyNumbers.length === 0) {
      startDate = moment(relevantPolicy.periodStart).format(US_DATE_SINGLE_DIGIT_MASK);
      endDate = moment(relevantPolicy.periodEnd).format(US_DATE_SINGLE_DIGIT_MASK);
      policyNumbers = Object.values(relevantPolicy.policyNumbers).map((policy) =>
        this.normalizePolicyNumber(policy)
      );
    } else {
      boundScheduledPolicyNumbers.forEach((policy) => {
        startDate = moment(policy.PeriodStart).format(US_DATE_SINGLE_DIGIT_MASK);
        endDate = moment(policy.PeriodEnd).format(US_DATE_SINGLE_DIGIT_MASK);
        policyNumbers.push(policy.PolicyNumber);
        this.isTargetingRenewalOrRollover = true;
      });
    }

    const policyString = `[${startDate} - ${endDate}] ${policyNumbers.join(' & ')}`;
    policyRecord[policyString] = policyNumbers;
    return policyRecord;
  }

  getBoundScheduledPolicyNumbers(
    associatedPolicies: BackendInvoiceAssociatedPolicy[]
  ): BackendInvoiceAssociatedPolicy[] {
    const renewalPolicies = associatedPolicies.filter((policy) => {
      // to get renewals and rollovers - include policy if the display status is scheduled & the term periods are bound
      return (
        policy.TermDisplayStatus_ATN === 'Scheduled' &&
        policy.PolicyTerm.PortalViewableTermPeriods.every((termPeriod) => {
          return termPeriod.Status === 'Bound';
        }) &&
        policy.TermDisplayStatus_ATN === 'Scheduled'
      );
    });
    return renewalPolicies ? renewalPolicies : [];
  }

  hasUnpaidInvoicesOnRelevantPolicy(): boolean {
    return this.billedInvoices.some((invoice: BackendListInvoice) => {
      if (invoice.amountDue > 0) {
        return invoice.lineItems.some((lineItem) => {
          const relPolNumAtLOB = this.relevantPolicy.policyNumbers[lineItem.lineOfBusiness];
          // if 'relevantPolicy' includes the lineOfBusiness for this line item
          // check to see if the unpaid billed invoice has the same policy number
          if (relPolNumAtLOB) {
            return lineItem.policyNumber === this.normalizePolicyNumber(relPolNumAtLOB);
          }
          return false;
        });
      }
      return false;
    });
  }

  // because guidewire isn't correctly recalculating invoices when one or more payments has been made
  // prevent users who have made any payments on the targeted (either relevant or future) policy period
  hasPaidInvoicesOnTargetedPolicy(isTargetingRenewalOrRollover: boolean): boolean {
    const paidOnTargeted = this.billedInvoices.some((invoice: BackendListInvoice) => {
      if (invoice.amountDue === 0) {
        return invoice.lineItems.some((lineItem) => {
          // double check policy number of paid invoice is in relevant or future policy
          if (isTargetingRenewalOrRollover && this.futurePolicy) {
            if (!this.futurePolicy.policyNumbers) {
              this.sentryService.notify(
                'Future policy policy numbers are not set, defaulting to relevant policy numbers'
              );
              return (
                lineItem.policyNumber ===
                this.normalizePolicyNumber(
                  this.relevantPolicy.policyNumbers[lineItem.lineOfBusiness]
                )
              );
            }
            this.paymentPlanForm.patchValue({
              newPaymentPlan:
                this.planMap[this.normalizePaymentPlan(this.futurePolicy.paymentPlans)],
            });
            return (
              lineItem.policyNumber ===
              this.normalizePolicyNumber(this.futurePolicy.policyNumbers[lineItem.lineOfBusiness])
            );
          }
          return (
            lineItem.policyNumber ===
            this.normalizePolicyNumber(this.relevantPolicy.policyNumbers[lineItem.lineOfBusiness])
          );
        });
      }
      return false;
    });

    if (paidOnTargeted) {
      if (!this.loggedChangePreventedPaidInv) {
        this.amplitudeService.track({
          eventName: 'change_plan_prevented_paid_invoice',
          detail: 'insured_invoice_list',
          useLegacyEventName: true,
        });
        this.loggedChangePreventedPaidInv = true;
      }
    }
    return paidOnTargeted;
  }

  // some of our endpoints return policy numbers with an extra term designation
  // e.g. CAUSRA0001-01-1 for the first policy period and CAUSRA0001-02-2
  // payment plan endpoint expects policy numbers without the final -1
  // because accredited policies shouldn't have the same issue this method:
  // checks to see if the policy number passed to it has that extra term & returns it trimmed
  normalizePolicyNumber(policyNo: string): string {
    if (policyNo && policyNo.length) {
      const penultimateChar: number = Number(policyNo.length - 2);
      if (policyNo[penultimateChar] === '-') {
        return policyNo.slice(0, -2);
      }
      return policyNo;
    }
    // this.sentryService.notify('Tried to normalize policy number, but policy number was not set');
    return '';
  }

  normalizePaymentPlan(paymentPlan: { [key: string]: string }): string {
    return Object.values(paymentPlan)[0].replace(' ', '-');
  }

  handlePaymentPlanFlow() {
    const form = <UntypedFormGroup>this.paymentPlanForm.get('newPaymentPlan');

    if (form.pristine) {
      this.errorMessage = 'Please choose a different plan';
    } else {
      this.amplitudeService.track({
        eventName: 'change_plan_attempt',
        detail: 'insured_invoice_list',
        useLegacyEventName: true,
      });
      this.errorMessage = null;
      this.isProcessing = true;
      const policyNumbers: string[] = this.policyRecord[Object.keys(this.policyRecord)[0]];
      let first = policyNumbers && policyNumbers.length > 1 ? true : false;

      for (const policyNumber of policyNumbers) {
        const newPaymentPlan = {
          billImmediately: first ? false : true,
          policyNumber,
          newPaymentPlan: this.paymentPlanForm.controls.newPaymentPlan.value,
        };
        this.changeRequests.push(this.submitSingleChangeRequest(newPaymentPlan));
        // only request billImmediately for second call to change payment plan
        if (first) {
          first = false;
        }
      }

      const currentPlanVals: string = this.normalizePaymentPlan(this.relevantPolicy.paymentPlans);
      const currentPlanFormatted = this.planMap[currentPlanVals];
      const newPlanFormatted = this.paymentPlanForm.controls.newPaymentPlan.value;

      // make post requests sequentially, break out if one of them fails, close modal if all are successful
      concat(...this.changeRequests).subscribe(
        () => {},
        (error) => {
          console.error(error);
          this.amplitudeService.track({
            eventName: `change_plan_from_${currentPlanFormatted}_to_${newPlanFormatted}_error`,
            detail: 'insured_invoice_list',
            useLegacyEventName: true,
          });
          this.errorMessage = error;
          this.isProcessing = false;
        },
        () => {
          this.amplitudeService.track({
            eventName: `change_plan_from_${currentPlanFormatted}_to_${newPlanFormatted}_success`,
            detail: 'insured_invoice_list',
            useLegacyEventName: true,
          });
          this.isProcessing = false;
          this.closePaymentPlanModal.emit();
          this.resetForm({ newPaymentPlan: currentPlanFormatted });
        }
      );
    }
  }

  submitSingleChangeRequest(
    paymentPlan: PaymentPlanInformation
  ): Observable<'Success' | 'Failure' | HttpErrorResponse> {
    return this.paymentPlanService
      .updatePaymentPlan(
        this.invoicePid,
        this.invoiceToken,
        paymentPlan.billImmediately,
        paymentPlan.policyNumber,
        paymentPlan.newPaymentPlan
      )
      .pipe(
        catchError((err) => {
          return observableOf(err);
        }),
        tap((res) => {
          if (res.error) {
            // logged to Sentry at the service level, can pass back more information for future iterations
            throw res.error;
          }
        })
      );
  }

  openStripePaymentModal() {
    this.closePaymentPlanModal.emit();
    this.resetForm();
    this.openPayoffModal.emit();
  }

  resetForm(formValues: { policyNumber?: string; newPaymentPlan?: string } = {}) {
    this.formRef.resetForm({
      policyNumber: formValues.policyNumber || '',
      newPaymentPlan: formValues.newPaymentPlan || '',
    });
    this.changeRequests = [];
  }

  isCancelledOrOverdue() {
    if (this.isCancelled || this.billingStatus === 'overdue') {
      if (!this.loggedChangePreventedDelinquent) {
        this.amplitudeService.track({
          eventName: 'change_plan_prevented_overdue',
          detail: 'insured_invoice_list',
          useLegacyEventName: true,
        });
        this.loggedChangePreventedDelinquent = true;
      }
      return true;
    }
    return false;
  }

  isSelfServiceAvailable(): boolean {
    return (
      !this.hasPlanConflict &&
      this.hasPolicyNumbers &&
      !this.hasPaidInvoicesOnTargetedPolicy(this.isTargetingRenewalOrRollover)
    );
  }

  generateLabelText() {
    const defaultLabels = {
      '1-Payment': 'One payment',
      '4-Payments': 'Four payments',
      '10-Payments': 'Ten payments',
    };

    // replace whitespace with `-` so radio input IDs are properly formatted
    this.currentPaymentPlan =
      this.isTargetingRenewalOrRollover && this.futurePolicy
        ? this.normalizePaymentPlan(this.futurePolicy.paymentPlans)
        : this.normalizePaymentPlan(this.relevantPolicy.paymentPlans);

    return mapValues(defaultLabels, (val, key) => {
      if (key === this.currentPaymentPlan) {
        return `${val} <div class="pill-tag pill-tag__neutral">CURRENT PAYMENT PLAN</div>`;
      } else if (this.currentPaymentPlan !== '1-Payment' && key === '1-Payment') {
        return 'Pay off remaining balance';
      }
      return val;
    });
  }

  generateHelpCenterUrl() {
    return `/${this.helpCenterPath}/form/change-payment-plan`;
  }
}
