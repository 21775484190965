// Libraries
import {
  Input,
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { compact, map } from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
// Constants
import {
  ADDITIONAL_INSURED_TYPES,
  ADDITIONAL_INSURED_TYPES_IN_FL,
  ADDITIONAL_INSURED_ADDRESS_TYPES,
  ADDITIONAL_INSURED_TYPES_DISABLED_IN_PORTAL,
} from 'app/features/attune-bop/models/constants';
// Models
import {
  AdditionalInsuredType,
  AdditionalInsuredPlacement,
  ADDITIONAL_INSURED_MAPPING,
  ADDITIONAL_INFORMATION_LABEL,
  DependentFields,
} from 'app/features/attune-bop/models/bop-additional-insured-business';
import { shouldShowInvalid } from 'app/shared/helpers/form-helpers';

interface RelatedLocation {
  locationId: string;
  address: string;
}

interface RelatedEntity {
  guidewireId?: string;
  address: string;
}

@Component({
  selector: 'app-additional-insureds',
  templateUrl: './additional-insureds.component.html',
})
export class AdditionalInsuredsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prefix: string;
  @Input() submitted: boolean;
  @Input() locationAddresses: Address[] = [];
  @Input() locations: QSLocation[] = [];
  @Input() additionalInsureds: UntypedFormArray;
  @Input() allowRemovingAllAdditionalInsureds: boolean;
  /*
    numberOfAdditionalInsureds being passed in here because Angular does not trigger an ngOnChanges for FormArrays.
    This refers to the length of the additionalInsureds form array.
  */
  @Input() numberOfAdditionalInsureds: number;

  /*
    By default the bop quote/edit flow will continue to use a concept of a related-location based on location array index
    however the endorsement flow will use guidewireIds to associate an additional insureds with a particular building/location.
  */
  @Input() isUsingRelatedLocation = true;

  @Output() addInsuredBusiness: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeInsuredBusiness: EventEmitter<number> = new EventEmitter<number>();
  shouldShowInvalid = shouldShowInvalid;

  additionalInsuredTypes: { [key: string]: AdditionalInsuredType };
  additionalInsuredAddressTypes = ADDITIONAL_INSURED_ADDRESS_TYPES;
  paragraphOptions = ['A', 'B', 'C'];

  relatedLocations: RelatedLocation[] = [];

  relatedBuildingEntities: RelatedEntity[] = [];
  relatedLocationEntities: RelatedEntity[] = [];

  sub: Subscription = new Subscription();

  additionalInsuredsWithTypeSubscription: UntypedFormGroup[] = [];

  constructor() {}

  ngOnInit() {
    if (this.checkIfAllLocationsAreNotInFL()) {
      this.additionalInsuredTypes = ADDITIONAL_INSURED_TYPES;
    } else {
      this.additionalInsuredTypes = ADDITIONAL_INSURED_TYPES_IN_FL;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Note directly binding the locationAddresses to the radio options interrupts value changes
    if (changes.locationAddresses) {
      this.relatedLocations = this.generateRelatedLocations();
      this.fixDeletedLocations();
    }

    if (changes.numberOfAdditionalInsureds && !this.isUsingRelatedLocation) {
      // If additional insureds are added, check to see if new AI's have a subscription on the type of AI.
      this.additionalInsureds.controls.forEach((additionalInsured: UntypedFormGroup) => {
        if (!this.additionalInsuredsWithTypeSubscription.includes(additionalInsured)) {
          this.additionalInsuredsWithTypeSubscription.push(additionalInsured);
          this.listenOnTypeAndPatchLocation(additionalInsured);
        }
      });
    }

    if (changes.locations) {
      this.generateRelatedEntityIds();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  listenOnTypeAndPatchLocation(additionalInsured: UntypedFormGroup) {
    this.sub.add(
      (<UntypedFormControl>additionalInsured.get('type')).valueChanges
        .pipe(
          distinctUntilChanged((prevType, currType) => {
            return ADDITIONAL_INSURED_MAPPING[prevType] === ADDITIONAL_INSURED_MAPPING[currType];
          })
        )
        .subscribe((additionalInsuredType: AdditionalInsuredType) => {
          // Reset the guidewireId if the type of AI changes. This allows MPC to treat this as a new AI and remove the original.
          (<UntypedFormControl>additionalInsured.get('guidewireId')).reset();

          // Get applicable related entity ids for the AI type.
          const relatedEntities = this.getRelatedEntities(additionalInsuredType);
          if (relatedEntities.length > 0) {
            // Patch related entity id to first building or location when type is changed.
            (<UntypedFormControl>additionalInsured.get('relatedEntityId')).patchValue(
              relatedEntities[0].guidewireId
            );
          } else {
            // If the endorsement type applies to the entire policy, reset the relatedEntityId.
            (<UntypedFormControl>additionalInsured.get('relatedEntityId')).reset();
          }
        })
    );
  }

  checkIfAllLocationsAreNotInFL() {
    return this.locationAddresses.some((location) => location.state !== 'FL');
  }

  getDependentQuestionLabel(additionalInsuredType: AdditionalInsuredType) {
    return ADDITIONAL_INFORMATION_LABEL[additionalInsuredType];
  }

  getOptionalLabel(additionalInsured: UntypedFormGroup) {
    const additionalInformationControl = additionalInsured.get(
      DependentFields.ADDITIONAL_INFORMATION
    ) as UntypedFormControl;
    if (additionalInformationControl.valid) {
      return '(optional)';
    } else {
      return '';
    }
  }

  locationSpecificControlsVisible(additionalInsuredType: AdditionalInsuredType) {
    const placement = ADDITIONAL_INSURED_MAPPING[additionalInsuredType];

    return (
      (this.locations.length > 0 || this.locationAddresses.length) &&
      (placement === AdditionalInsuredPlacement.LOCATION ||
        placement === AdditionalInsuredPlacement.BUILDING)
    );
  }

  moreThanOneAdditionalInsured() {
    return this.additionalInsureds.controls.length > 1;
  }

  makeFormattedAddress(loc: Address) {
    const address = compact([loc.addressLine1, loc.addressLine2, loc.city, loc.state, loc.zip])
      .filter((x) => !!x)
      .map((str) => str.trim())
      .join(' ');

    return address;
  }

  formatForCss(relatedEntityId: string) {
    // Replace periods & colons in a relatedEntityId with a hyphen for use as a valid css selector.
    return relatedEntityId.replace(/\W/g, '-');
  }

  private generateRelatedLocations() {
    const locations = this.locationAddresses;
    if (!locations || locations.length === 0) {
      return [];
    }
    return map(locations, (loc: Address, locationIndex: number) => {
      return <RelatedLocation>{
        address: this.makeFormattedAddress(loc),
        locationId: `location-${locationIndex + 1}-1`,
      };
    });
  }

  generateRelatedEntityIds() {
    const locationEntities: RelatedEntity[] = [];
    const buildingEntities: RelatedEntity[] = [];

    this.locations.forEach((location) => {
      const formattedLocationAddress = this.makeFormattedAddress(location.address);
      locationEntities.push({
        address: formattedLocationAddress,
        guidewireId: location.guidewireId,
      });

      location.buildings.forEach((building: QSBuilding, index) => {
        // Note: locations and buildings are indexed at 1;
        buildingEntities.push({
          address: `${formattedLocationAddress}-building-${index + 1}`,
          guidewireId: building.guidewireId,
        });
      });
    });

    this.relatedBuildingEntities = buildingEntities;
    this.relatedLocationEntities = locationEntities;
  }

  getRelatedEntities(additionalInsuredType: AdditionalInsuredType) {
    const placement = ADDITIONAL_INSURED_MAPPING[additionalInsuredType];

    if (placement === AdditionalInsuredPlacement.LOCATION) {
      return this.relatedLocationEntities;
    } else if (placement === AdditionalInsuredPlacement.BUILDING) {
      return this.relatedBuildingEntities;
    }
    return [];
  }

  isAdditionalInsuredTypeDisabled(additionalInsuredType: AdditionalInsuredType) {
    // Additional insured types that are disabled in the portal.
    return Object.values(ADDITIONAL_INSURED_TYPES_DISABLED_IN_PORTAL).includes(
      additionalInsuredType
    );
  }

  getFormattedDisabledAdditionalInsuredType(additionalInsuredType: AdditionalInsuredType) {
    const additionalInsuredTypeKeys = Object.keys(ADDITIONAL_INSURED_TYPES_DISABLED_IN_PORTAL);

    for (const key of additionalInsuredTypeKeys) {
      if (ADDITIONAL_INSURED_TYPES_DISABLED_IN_PORTAL[key] === additionalInsuredType) {
        return key;
      }
    }
  }

  private fixDeletedLocations() {
    if (!this.additionalInsureds || this.additionalInsureds.controls.length === 0) {
      return;
    }
    const allowedKeys = this.relatedLocations.map((l) => l.locationId);
    this.additionalInsureds.controls.forEach((additionalInsured) => {
      const relatedControl = additionalInsured.get('relatedLocation');
      if (relatedControl && !allowedKeys.includes(relatedControl.value)) {
        // reset to default
        relatedControl.setValue('location-1-1');
      }
    });
  }

  addInsuredBusinessHandler() {
    this.addInsuredBusiness.emit();
  }

  removeInsuredBusinessHandler(index: number) {
    this.removeInsuredBusiness.emit(index);
  }
}
