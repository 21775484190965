<section class="form-animation">
  <h1 class="form-header-below-link">General Liability</h1>

  <app-form-field-select
    inputId="hab-generalLiability-occurenceAndAggregateLimit"
    [form]="form"
    [submitted]="hasSubmitted()"
    labelText="Per Occurrence / Aggregate Limit"
    nameOfFormControl="perOccurenceAndAggregateLimitDisplayedValue"
    [availableOptions]="['$1M / $2M']"
    readonly="true"
  >
  </app-form-field-select>

  <app-form-field-checkbox
    inputId="hab-generalLiability-assaultAndBatterySelected"
    [cssClass]="indentedFieldMarginCss('assaultAndBattery.displayedValue')"
    labelText="Assault and Battery"
    nameOfFormControl="assaultAndBatterySelected"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-checkbox>

  <app-form-field-select
    inputId="hab-generalLiability-assaultAndBatteryLimit"
    placeholder="Choose one"
    [form]="form.get('assaultAndBattery')"
    [submitted]="hasSubmitted()"
    [availableOptions]="habAssaultAndBatteryOptions"
    nameOfFormControl="displayedValue"
  >
  </app-form-field-select>

  <app-form-field-checkbox
    inputId="hab-generalLiability-hiredNonOwnedAutoLiabilitySelected"
    [cssClass]="indentedFieldMarginCss('hiredNonOwnedAutoLiability')"
    labelText="Hired and Non-owned Auto Liability with Physical Damage Coverage"
    [form]="form"
    [submitted]="hasSubmitted()"
    nameOfFormControl="hiredNonOwnedAutoLiabilitySelected"
  >
  </app-form-field-checkbox>

  <app-form-field-text
    inputId="hab-generalLiability-hiredNonOwnedAutoLiabilityNumDrivers"
    cssClass="form-field__indent"
    labelText="Total number of drivers"
    [form]="form.get('hiredNonOwnedAutoLiability')"
    [submitted]="hasSubmitted()"
    nameOfFormControl="numDrivers"
    appDelimitedNumberMask
  >
  </app-form-field-text>

  <app-form-field-checkbox
    inputId="hab-generalLiability-employeeBenefitsLiabilitySelected"
    [cssClass]="indentedFieldMarginCss('employeeBenefitsLiability')"
    labelText="Employee Benefits Liability"
    [form]="form"
    [submitted]="hasSubmitted()"
    nameOfFormControl="employeeBenefitsLiabilitySelected"
  >
  </app-form-field-checkbox>

  <app-form-field-text
    inputId="hab-generalLiability-employeeBenefitsLiabilityRetroactiveDate"
    cssClass="form-field__no-margin form-field__indent"
    labelText="Retroactive Date"
    [form]="form.get('employeeBenefitsLiability')"
    [submitted]="hasSubmitted()"
    nameOfFormControl="retroactiveDate"
    appDateMask
  >
  </app-form-field-text>
  <div class="validation" *ngIf="hasSubmitted() && getRetroactiveDateErrors() !== ''">
    <div class="validation-message">
      {{ getRetroactiveDateErrors() }}
    </div>
  </div>

  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    cssClass="form-field__no-margin form-field__indent form-field__indent-sibling"
    inputId="hab-generalLiability-employeeBenefitsLiabilityAggregateLimit"
    questionText="Aggregate Limit"
    nameOfFormControl="aggregateLimit"
    [submitted]="hasSubmitted()"
    [form]="form.get('employeeBenefitsLiability')"
    [options]="habEmployeeBenefitsAggregateLimitOptions"
    questionNote="Each Employee Limit is $1,000,000"
  >
  </app-form-field-radio>
</section>
