<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div
    class="{{ cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(nameOfFormControl) }"
  >
    <label [for]="inputId">
      {{ questionText }}
      <div
        *ngIf="tooltipText"
        class="more-info-tip tool-tip tool-tip__large"
        attr.data-tooltip="{{ tooltipText }}"
      >
        <span class="icon icon-question"></span>
      </div>
      <ng-container *ngIf="additionalQuestionText">
        <br />
        {{ additionalQuestionText }}
      </ng-container>
    </label>
    <div class="radio-question {{ questionCssClass }}" [id]="inputId">
      <div
        *ngFor="let optionKey of options | keys"
        class="radio-question-option radio-question-image-option {{ optionsCssClass }}"
      >
        <input
          [formControl]="form.get(nameOfFormControl)"
          [id]="inputId + '-' + optionKey"
          [name]="inputId"
          type="radio"
          [value]="optionKey"
          [attr.disabled]="shouldDisableOption(optionKey) ? true : null"
        />
        <label [for]="inputId + '-' + optionKey" class="radio-question-label">
          <img
            [src]="options[optionKey].imageUrl"
            alt="Image of {{ options[optionKey].displayName }}"
          />
          {{ options[optionKey].displayName }}
        </label>
      </div>
    </div>
  </div>
</ng-container>
