import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';

export const WaiverOfSubrogationMixin = <T extends Constructor>(BaseClass: T) => {
  return class extends BaseClass {
    addWaiverOfSubrogation(waiversOfSubrogationFormArray: UntypedFormArray): UntypedFormGroup {
      const formBuilder = new UntypedFormBuilder();
      const waiver = formBuilder.group({ orgOrPersonName: [null, Validators.required] });
      waiversOfSubrogationFormArray.push(waiver);
      return waiver;
    }

    removeWaiverOfSubrogationAt(index: number, waiverOfSubrogationFormArray: UntypedFormArray) {
      waiverOfSubrogationFormArray.removeAt(index);
    }
  };
};
