import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-attune-bop-cyber-price-comparison-table',
  templateUrl: './attune-bop-cyber-price-comparison-table.component.html',
})
export class AttuneBopCyberPriceComparisonTableComponent {
  @Input() activeCyberPrice: number;
  @Input() endorsementPrice: number;
}
