import { Pipe, PipeTransform } from '@angular/core';
import { ProductCombinationForAvailability } from '../../features/digital-carrier/models/types';

@Pipe({ name: 'lobDisplayName' })
export class LobDisplayNamePipe implements PipeTransform {
  transform(value: ProductCombinationForAvailability['product']): string {
    switch (value) {
      case 'cyber_admitted':
      case 'cyber_surplus':
        return 'Cyber Liability';
      case 'wc':
        return "Workers' Compensation";
      case 'gl':
        return 'General Liability';
      case 'pl':
        return 'Professional Liability';
      case 'bop':
        return "Businessowners' Policy";
      case 'cpsp':
        return 'Custom Protector';
      case 'flood':
        return 'Flood Insurance';
      default:
        return value;
    }
  }
}
