<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__wc"
  product="wc"
  pasSource="attune"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insAccount"
  sidebarTitle="New quote"
  [fillInIneligibleLocation]="populateIneligibleLocation.bind(this)"
  [fillInHappyPath]="populateKnownGoodHappyPathValues.bind(this)"
></app-quote-stepped-sidebar>

<div class="app-page-form-scrollable-wrapper app-page-form-with-sidebar-wrapper">
  <div class="app-page-form" content>
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>
    <main *ngIf="isLoading" class="placeholder-form-container">
      <div class="placeholder-h1"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-submit"></div>
    </main>
    <ng-container *ngIf="!isLoading">
      <form autocomplete="off" [formGroup]="form" (ngSubmit)="handleSubmit($event)" novalidate>
        <router-outlet></router-outlet>

        <section class="form-footer">
          <div class="validation" *ngIf="submitted() && !isCurrentStepValid()">
            <div class="validation-message">
              {{ getValidationMessage() }}
            </div>
          </div>

          <div class="nav-button-group nav-button-group__left">
            <button
              *ngIf="!isFinalStep()"
              id="wc-quote-form-next-button"
              [ngClass]="{
                button: true,
                'js-step-forward-button': true,
                button__discouraged: !this.isCurrentStepValid()
              }"
              [disabled]="waitingForEligibilityCheck"
              type="submit"
              class="button__primary"
            >
              <span>Next</span>
            </button>
            <button
              *ngIf="isFinalStep()"
              id="wc-quote-form-submit-button"
              [ngClass]="{
                button: true,
                'js-step-forward-button': true,
                button__discouraged: !this.isCurrentStepValid()
              }"
              type="submit"
              class="button__primary"
              [disabled]="isQuoteInProgress"
            >
              <span *ngIf="!isQuoteInProgress">Submit</span>
              <span *ngIf="isQuoteInProgress">Submitting...</span>
            </button>
          </div>
        </section>

        <ng-container *ngIf="isDevMode">
          <div class="adminz-only">
            <pre>{{ form.value | json }}</pre>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </div>

  <div class="app-page-form-side-rail"></div>
</div>

<div class="quoting-game-container" *ngIf="quoting">
  <app-catch-game [finish$]="quotingSuccess$"></app-catch-game>
</div>

<app-quote-error-modal
  [open]="errorModalOpen"
  (closeQuoteErrorModal)="handleErrorModalClose($event)"
  [errors]="getErrorList()"
  [errorType]="getErrorType()"
></app-quote-error-modal>
