export interface UtilityServiceLimits {
  directDamageSubLimit: number;
  timeElementSubLimit: number;
}

export interface WindOption {
  DollarAmountDeductible: string | null;
  PercentDeductible: string;
}

export enum APIDataStatus {
  UNREQUESTED,
  LOADING,
  LOADED,
  ERROR,
}

// TODO: Rename this interface to give an indication that it is related to wind or utility damage -HH
export interface APIData {
  data: UtilityServiceLimits | Array<WindOption>;
  status: APIDataStatus;
}
