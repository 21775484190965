<div class="support-enhancement">
  <h1>Enhancement Library - Accredited BOP</h1>
  <table>
    <thead>
      <tr>
        <th class="support-enhancement-col-name">NAME</th>
        <th class="support-enhancement-col">NUMBER</th>
        <th class="support-enhancement-col">EDITION</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let form of formTemplates.contents" class="support-table-row">
        <td data-column-name="Name">
          <img src="/assets/img/pdf_icon.svg" alt="" class="support-document-pdf-icon-td" />{{
            form.name
          }}
        </td>
        <td data-column-name="Number" class="support-form-name-edition-download">
          {{ form.formNumber }}
        </td>

        <td data-column-name="Edition" class="support-form-name-edition-download">
          {{ form.formEdition }}
        </td>
        <td data-column-name="">
          <a
            class="button button__small button__transparent button__no-margin"
            (click)="downloadForm(form.fullName)"
            href="javascript:void(0)"
          >
            Download
          </a>
        </td>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let i of [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]" class="support-table-row">
          <td>
            <div class="table-loading-pdf-icon"></div>
            <div class="table-loading-cell"></div>
          </td>
          <td><div class="table-loading-cell"></div></td>
          <td><div class="table-loading-cell"></div></td>
          <td><div class="table-loading-cell"></div></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
