import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { numberToMoneyString, parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';

/**
 * Component that shows an estimated replacement cost.
 *
 * @param open: whether the modal is visible right now
 * @param estimatedReplacementCost: Estimated replacement cost for building
 * @param buildingLimit: Building limit
 * @param update: function handler for when user updates building limit
 */

enum CORRECTION_OPTIONS {
  USE_SUGGESTED = 'USE_SUGGESTED',
  USE_DIFFERENT_AMOUNT = 'USE_DIFFERENT_AMOUNT',
}

export interface UWInterventionAlert {
  templateMessage: string;
  eventName: string;
  estimate: number;
  userEntered: number;
  userCorrection?: string;
  field: UntypedFormControl | null;
  percentage?: number;
}

@Component({
  selector: 'app-attune-bop-estimated-replacement-cost-modal',
  templateUrl: './attune-bop-estimated-replacement-cost-modal.component.html',
})
export class AttuneBopEstimatedReplacementCostModalComponent implements OnChanges {
  @Input() public open = false;
  @Input() public interventionAlerts: UWInterventionAlert[] = [];

  @Output() update = new EventEmitter<UWInterventionAlert[]>();

  public formArray: UntypedFormArray;

  public percentageCorrected = 0;
  public percentageCorrectLabel = '';

  public correctionOptions = CORRECTION_OPTIONS;

  constructor(private amplitudeService: AmplitudeService, private formBuilder: UntypedFormBuilder) {
    this.formArray = formBuilder.array([]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.interventionAlerts) {
      const interventionAlerts: UWInterventionAlert[] = changes.interventionAlerts.currentValue;

      const formArray = this.formBuilder.array(
        interventionAlerts.map((intervention) => {
          const group = this.formBuilder.group({
            correctionOption: [CORRECTION_OPTIONS.USE_SUGGESTED],
            correctedValue: [intervention.estimate],
          });

          intervention.userCorrection = numberToMoneyString(intervention.estimate);

          const radio = group.get('correctionOption');
          if (radio) {
            radio.valueChanges.subscribe((newValue: string) => {
              if (newValue === CORRECTION_OPTIONS.USE_DIFFERENT_AMOUNT) {
                group.patchValue({
                  correctedValue: intervention.estimate,
                });
              }
            });

            radio.setValue(CORRECTION_OPTIONS.USE_SUGGESTED);
          }

          const correctedValueField = group.get('correctedValue');

          if (correctedValueField) {
            correctedValueField.valueChanges.subscribe((newValue: string) => {
              intervention.userCorrection = newValue;
              const valueNum = parseMaskedInt(newValue);
              intervention.percentage = Math.round((valueNum / intervention.estimate) * 100);
            });
          }

          this.amplitudeService.trackWithOverride({
            eventName: `uw_intervention.${intervention.eventName}.display`,
            detail: `${intervention.estimate}`,
            payloadOverride: {
              userEntered: `${intervention.userEntered}`,
            },
          });

          return group;
        })
      );

      this.formArray = formArray;
    }
  }

  getCorrectionOption(i: number) {
    const radio = this.formArray.at(i).get('correctionOption');
    if (radio) {
      return radio.value;
    }
    return '';
  }

  /* Percentage the entered building limit is of the estimated replacement cost */
  getPercentageOf(a: number, b: number) {
    return Math.round((1 - a / b) * 100);
  }

  getOptionLabels(intervention: UWInterventionAlert) {
    return {
      USE_SUGGESTED: `Use the estimate (${numberToMoneyString(intervention.estimate)})`,
      USE_DIFFERENT_AMOUNT: 'Use a different amount',
    };
  }

  renderTemplateMessage(intervention: UWInterventionAlert) {
    const upperEstimate =
      Math.round((intervention.estimate + intervention.estimate * 0.15) / 1000) * 1000;
    const lowerEstimate =
      Math.round((intervention.estimate - intervention.estimate * 0.15) / 1000) * 1000;

    return intervention.templateMessage
      .replace('%ESTIMATE%', numberToMoneyString(intervention.estimate))
      .replace('%ESTIMATE_UPPER%', numberToMoneyString(upperEstimate))
      .replace('%ESTIMATE_LOWER%', numberToMoneyString(lowerEstimate))
      .replace('%ENTERED%', numberToMoneyString(intervention.userEntered))
      .replace(
        '%PERCENT_OF%',
        `${this.getPercentageOf(intervention.userEntered, intervention.estimate)}%`
      )
      .replace(
        '%PERCENT_OF_UPPER%',
        `${this.getPercentageOf(intervention.userEntered, upperEstimate)}%`
      )
      .replace(
        '%PERCENT_OF_LOWER%',
        `${this.getPercentageOf(intervention.userEntered, lowerEstimate)}%`
      );
  }

  goBack() {
    this.amplitudeService.track({
      eventName: 'uw_intervention.go_back',
      detail: '',
    });

    /* Emit empty array to signal to event handler to go back from modal */
    this.update.emit([]);
  }

  continue() {
    this.interventionAlerts.forEach((intervention: UWInterventionAlert, i: number) => {
      if (this.getCorrectionOption(i) === CORRECTION_OPTIONS.USE_SUGGESTED) {
        this.amplitudeService.trackWithOverride({
          eventName: `uw_intervention.${intervention.eventName}.use_suggested`,
          detail: `${intervention.estimate}`,
          payloadOverride: {
            userEntered: `${intervention.userEntered}`,
          },
        });
      } else if (this.getCorrectionOption(i) === CORRECTION_OPTIONS.USE_DIFFERENT_AMOUNT) {
        this.amplitudeService.trackWithOverride({
          eventName: `uw_intervention.${intervention.eventName}.use_different_amount`,
          detail: `${intervention.userCorrection}`,
          payloadOverride: {
            userEntered: `${intervention.userEntered}`,
          },
        });
      }
    });

    this.update.emit(this.interventionAlerts);
  }
}
