import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FrontendQuoteWithLinks } from 'app/features/insured-account/services/insured-account-summary.service';
import { COALITION_TECHNOLOGY_AND_SERVICES } from 'app/features/support/models/support-constants';
import { CurrencyPipe } from '@angular/common';
import { Observable } from 'rxjs';

export type BundleQuotePrimaryProductName = 'BOP' | 'BOP and XS' | 'GL' | 'PL' | 'Primary'; // Can update type as we add other products

@Component({
  selector: 'app-bundle-quote-review-modal',
  templateUrl: './bundle-quote-review-modal.component.html',
  providers: [CurrencyPipe],
})
export class BundleQuoteReviewModalComponent implements OnChanges {
  @Input() open = false;
  @Input() primaryProductName: BundleQuotePrimaryProductName = 'Primary';
  @Input() primaryProductPremium: string | number;
  @Input() secondaryQuote: FrontendQuoteWithLinks;
  @Input() primaryDocumentDownload$: Observable<any>;
  @Input() primaryDocumentDownloadReady$: Observable<any>;
  @Input() secondaryDocumentDownload$: Observable<any>;
  @Input() secondaryDocumentDownloadReady$: Observable<any>;
  @Output() closeModal: EventEmitter<null> = new EventEmitter();

  secondaryProductName: string;

  includesCyberQuote = false;
  secondaryProductPremium: number;
  totalQuotedPremium: number;

  COALITION_TECHNOLOGY_AND_SERVICES = COALITION_TECHNOLOGY_AND_SERVICES;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.secondaryQuote?.currentValue) {
      const secondaryQuote: FrontendQuoteWithLinks = changes.secondaryQuote.currentValue;
      switch (secondaryQuote.product) {
        case 'cyber_admitted':
        case 'cyber_surplus':
          this.secondaryProductName = 'Cyber';
          break;
        default:
          this.secondaryProductName = 'Secondary';
          break;
      }
      this.includesCyberQuote = ['cyber_admitted', 'cyber_surplus'].includes(
        secondaryQuote.product
      );

      if (secondaryQuote.premium !== null) {
        this.secondaryProductPremium = Number(secondaryQuote.premium);
      }
    }

    if (this.secondaryProductPremium && this.primaryProductPremium) {
      this.totalQuotedPremium = Number(this.primaryProductPremium) + this.secondaryProductPremium;
    } else if (this.primaryProductPremium) {
      this.totalQuotedPremium = Number(this.primaryProductPremium);
    }
  }

  modalNameForAmplitudeEvent() {
    switch (this.primaryProductName) {
      case 'BOP':
      case 'BOP and XS':
        return 'bop-bundle-quote-review';
      case 'GL':
        return 'gl-bundle-quote-review';
      case 'PL':
        return 'pl-bundle-quote-review';
      default:
        return 'bundle-quote-review';
    }
  }
}
