import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { InvoicesService } from 'app/features/invoices/services/invoices.service';
import { InvoicesBannerService } from 'app/features/invoices/services/invoices-banner.service';

import { InvoicesPaymentFormComponent } from '../../../invoices/components/invoices-payment-form/invoices-payment-form.component';
import { CreateTokenBankAccountData } from '@stripe/stripe-js';
import { Observable, Subscription } from 'rxjs';
import { switchMap, flatMap } from 'rxjs/operators';
import {
  InvoicesPaymentService,
  InvoicePaymentError,
} from 'app/features/invoices/services/invoices-payment.service';

import { Router } from '@angular/router';
import { HAB_PRODUCT_NAME } from 'app/features/invoices/models/invoices-constants';
import { SentryService } from '../../../../core/services/sentry.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  NewInformationalService,
  BOP_BIND_INFORMATIONAL,
} from '../../../../shared/services/new-informational.service';

interface PolicyTermDetails {
  lineOfBusiness: string;
  totalValue: number;
  paymentPlan: string;
}

@Component({
  selector: 'app-insured-account-billing-card',
  templateUrl: './insured-account-billing-card.component.html',
  providers: [],
})
export class InsuredAccountBillingCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() invoices: BackendListInvoice[];
  @Input() accountId: string;

  @ViewChild(InvoicesPaymentFormComponent)
  paymentFormComponent: InvoicesPaymentFormComponent;

  banners: Record<string, boolean> = {
    noOpenInvoice: false,
    openEnrolledInvoices: false,
    overDueInvoices: false,
    activePastDueInvoices: false,
    openInvoices: false,
    openUnenrolledInvoice: false,
    autopayPastDueInvoices: false,
  };

  autopayInfo: AutopayInfoResponse | null;

  latestInvoiceDetails: Invoice | null;

  policies: BackendInvoiceAssociatedPolicy[] | null;

  paymentUpdateSuccessful = false;
  isProcessing = false;
  isUpdateOnly = false;
  paymentSuccessMessage = '';
  isUpdatingPayment = false;
  serverError = '';
  isInitialAutopay = false;
  showPaymentModal = false;

  unenrollErrorMessage = '';
  isProcessingUnenroll = false;
  showUnenrollAutopayModal = false;

  showAutopayWarningModal = false;

  // Track the number of invoices last seen, so as to re-render if it changes
  invoiceCount = -1;

  loggedBillingTip = false;

  private sub: Subscription = new Subscription();
  accountSummary: AccountSummary;
  policyTermDetails: PolicyTermDetails[];
  cancellationDate: Date;
  isActivePolicy: boolean;
  isPendingCancelPolicy: boolean;
  gracePeriodAfterEarliestDueDate: moment.Moment;

  constructor(
    private amplitudeService: AmplitudeService,
    private invoiceService: InvoicesService,
    private invoicePaymentService: InvoicesPaymentService,
    private invoiceBannerService: InvoicesBannerService,
    private sentryService: SentryService,
    private newInformationalService: NewInformationalService,
    private router: Router
  ) {}

  earliestInvoiceDueDate: moment.Moment;
  threeDaysBeforeEarliestDueDate: moment.Moment;
  earliestInvoiceAmountDue: any;

  pendingReinstatementInvoice: BackendListInvoice | undefined;

  ngOnInit() {
    this.getAccountSummary();
  }

  ngOnChanges(props: SimpleChanges) {
    if (props.accountId) {
      this.reloadData();
    }
    if (
      props.invoices &&
      props.invoices.currentValue &&
      props.invoices.currentValue.length !== this.invoiceCount
    ) {
      this.invoiceCount = props.invoices.currentValue.length;
      this.reloadData();
    }
  }

  getAccountSummary() {
    if (this.invoices && this.invoices.length) {
      const { id, transactionId } = this.invoices[0];
      this.sub.add(
        this.invoiceService
          .getAssociatedAccountSummary(id, transactionId)
          .subscribe((summary: AccountSummary) => {
            this.accountSummary = summary;
          })
      );
    }
  }

  reloadData() {
    this.sub.unsubscribe();
    this.sub = new Subscription();

    this.latestInvoiceDetails = null;
    this.policies = null;
    this.autopayInfo = null;
    this.getAccountBillingDetails();
  }

  onInvoiceNav() {
    this.newInformationalService.completeBopBindState(BOP_BIND_INFORMATIONAL.BILLING_CARD_TIP);
  }

  loading() {
    // If there are no invoices yet, do not display a loading state
    return (
      this.invoices &&
      !this.includesCyberPolicy() &&
      this.invoices.length &&
      (!this.policies || !this.autopayInfo)
    );
  }

  getAccountBillingDetails() {
    if (!this.invoices) {
      return;
    }

    if (this.invoices.length) {
      this.pendingReinstatementInvoice = this.invoices.find((invoice) => {
        return (
          invoice.isReinstatementInvoice &&
          invoice.lineItems.some((lineItem) => lineItem.isPendingReinstatement)
        );
      });
      const { id, transactionId, accountNumber } = this.invoices[this.invoices.length - 1];
      if (this.accountId === accountNumber) {
        this.sub.add(
          this.invoiceService
            .getAssociatedPolicies(id, transactionId)
            .subscribe((associatedPolicies: BackendInvoiceAssociatedPolicyResponse) => {
              this.loadLatestInvoiceDetails();
              this.policies = associatedPolicies.associatedPolicies;
              this.handleShowingBanner(
                ...this.invoiceBannerService.filterInvoicesAndPoliciesForTopBanner(
                  this.invoices,
                  this.policies
                )
              );
            })
        );
      }
      this.reloadAutopayDetails();
    } else {
      // No invoices have been generated for this account
      this.banners.noOpenInvoice = true;
    }
  }

  showBillingBannerTip() {
    if (
      !this.newInformationalService.isUserAtBopBindState(BOP_BIND_INFORMATIONAL.BILLING_CARD_TIP)
    ) {
      return false;
    }

    const isShowingBanner = Object.keys(this.banners).some((bannerKey) => this.banners[bannerKey]);

    if (isShowingBanner && !this.banners.noOpenInvoice) {
      if (!this.loggedBillingTip) {
        this.amplitudeService.track({
          eventName: 'bop_bind_billing_tip',
          detail: 'bop',
          useLegacyEventName: true,
        });
        this.loggedBillingTip = true;
      }
      return true;
    }
    return false;
  }

  loadLatestInvoiceDetails() {
    // Load additional data for the most recent invoice, since we need fields that are not
    // available on the invoice list (such as current outstanding charges)
    if (this.invoices && this.invoices.length) {
      const { id, transactionId } = this.invoices[0];
      this.sub.add(
        this.invoiceService.getInvoice(id, transactionId).subscribe((invoiceDetails) => {
          this.latestInvoiceDetails = invoiceDetails;
          this.policyTermDetails = this.getPolicyTermDetails(
            this.accountSummary,
            this.latestInvoiceDetails
          );
        })
      );
    }
  }

  isBannerShowing() {
    return Object.keys(this.banners).some(
      (key: keyof InsuredAccountBillingCardComponent['banners']) => {
        return !!this.banners[key];
      }
    );
  }

  includesCyberPolicy() {
    return (
      this.invoices &&
      this.invoices.length > 0 &&
      this.invoices.some((invoice) =>
        invoice.lineItems.some((i) => i.lineOfBusiness.includes('Cyber Liability'))
      )
    );
  }

  handleShowingBanner(
    unpaidInvoices: BackendListInvoice[],
    associatedPolicies: BackendInvoiceAssociatedPolicy[]
  ) {
    if (this.pendingReinstatementInvoice) {
      unpaidInvoices = [this.pendingReinstatementInvoice];
      if (this.pendingReinstatementInvoice.amountDue === 0) {
        unpaidInvoices = [];
      }
    }

    // Reset all banners, so that they can be recalculated
    Object.keys(this.banners).forEach((key) => {
      this.banners[key] = false;
    });

    const filteredPolicies = this.invoiceBannerService.calculatefilterPolicies(
      associatedPolicies,
      this.accountSummary
    );

    this.getDueInvoiceDateAndAmount(unpaidInvoices);

    const policyStatus = this.invoiceBannerService.getPolicyStatus(filteredPolicies);

    const numPastDueInvoices = this.invoiceBannerService.getNumOfPastDueInvoices(unpaidInvoices);

    const numOpenInvoices = this.invoiceBannerService.getNumOfOpenInvoices(unpaidInvoices);

    this.isActivePolicy = policyStatus === 'Active' ? true : false;

    this.isPendingCancelPolicy = policyStatus === 'Pending Cancel' ? true : false;

    if (this.hasAutopayEnabled()) {
      if (policyStatus === 'Active') {
        if (numPastDueInvoices > 0) {
          this.banners.autopayPastDueInvoices = true;
          return;
        } else if (numOpenInvoices > 0) {
          this.banners.openEnrolledInvoices = true;
          return;
        }
        this.banners.noOpenInvoice = true;
        return;
      } else if (policyStatus === 'Pending Cancel') {
        if (this.invoiceBannerService.isNonPayCancellation(filteredPolicies)) {
          if (numPastDueInvoices > 0) {
            this.banners.autopayPastDueInvoices = true;
            return;
          }
          return;
        }
        if (numOpenInvoices > 0) {
          this.banners.openEnrolledInvoices = true;
          return;
        } else if (numPastDueInvoices > 0) {
          this.banners.autopayPastDueInvoices = true;
          return;
        }
        this.banners.noOpenInvoice = true;
        return;
      } else if (policyStatus === 'Canceled') {
        if (numOpenInvoices > 0) {
          this.banners.openEnrolledInvoices = true;
          return;
        } else if (numPastDueInvoices > 0) {
          this.banners.overDueInvoices = true;
          return;
        }
        this.banners.noOpenInvoice = true;
        return;
      }
      return;
    } else {
      if (policyStatus === 'Active') {
        if (numPastDueInvoices > 0) {
          this.banners.activePastDueInvoices = true;
          return;
        } else if (numOpenInvoices > 0) {
          this.banners.openUnenrolledInvoice = true;
          return;
        }
        this.banners.noOpenInvoice = true;
        return;
      } else if (policyStatus === 'Pending Cancel') {
        this.cancellationDate = this.invoiceBannerService.getCancellationDate(filteredPolicies);

        if (this.invoiceBannerService.isUWCancellation(associatedPolicies)) {
          if (numOpenInvoices > 0) {
            this.banners.openInvoices = true;
            return;
          }
          if (numPastDueInvoices > 0) {
            this.banners.overDueInvoices = true;
            return;
          }
          this.banners.noOpenInvoice = true;
          return;
        }
        if (numPastDueInvoices > 0) {
          this.banners.activePastDueInvoices = true;
          return;
        }
        return;
      } else if (policyStatus === 'Canceled') {
        if (numPastDueInvoices > 0) {
          this.banners.overDueInvoices = true;
          return;
        } else if (numOpenInvoices > 0) {
          this.banners.openInvoices = true;
          return;
        }
        this.banners.noOpenInvoice = true;
        return;
      }
    }
  }

  getDueInvoiceDateAndAmount(unpaidInvoices: BackendListInvoice[]) {
    this.earliestInvoiceDueDate = moment.min(
      unpaidInvoices.map((invoice: BackendListInvoice) => moment.utc(invoice.dueDate))
    );
    this.threeDaysBeforeEarliestDueDate = this.earliestInvoiceDueDate
      .clone()
      .subtract(3, 'days')
      .startOf('day');

    this.gracePeriodAfterEarliestDueDate = this.earliestInvoiceDueDate.clone().add(10, 'days');

    unpaidInvoices.map((invoice: BackendListInvoice) => {
      this.earliestInvoiceAmountDue = invoice.amountDue;
    });
  }

  closeUnenrollAutopayModal() {
    this.showUnenrollAutopayModal = false;
  }

  closeAutopayWarningModal() {
    this.showAutopayWarningModal = false;
  }

  unenrollThroughModal() {
    this.unenrollErrorMessage = '';
    this.isProcessingUnenroll = true;
    this.sub.add(
      this.invoicePaymentService.unenrollAutopay(this.accountId).subscribe(
        (result) => {
          this.showUnenrollAutopayModal = false;
          this.isProcessingUnenroll = false;
          this.amplitudeService.track({
            eventName: 'autopay_unenroll_success',
            detail: '',
            useLegacyEventName: true,
          });

          this.reloadData();
        },
        (err) => {
          this.amplitudeService.track({
            eventName: 'autopay_unenroll_error',
            detail: '',
            useLegacyEventName: true,
          });
          this.isProcessingUnenroll = false;
          this.unenrollErrorMessage = 'We’re sorry, an error has occurred. Please try again.';
        }
      )
    );
  }

  reloadAutopayDetails() {
    this.autopayInfo = null;
    const { id, transactionId } = this.invoices[0];
    this.sub.add(
      this.invoiceService
        .getAutopayStatus(this.accountId, id, transactionId)
        .subscribe((autopayStatus: AutopayInfoResponse) => {
          this.autopayInfo = autopayStatus;
        })
    );
  }

  hasAutopayEnabled() {
    return this.autopayInfo && this.autopayInfo.autopayEnrolled;
  }

  hasCreditCardPayment() {
    return (
      this.autopayInfo &&
      this.autopayInfo.paymentMethod &&
      this.autopayInfo.paymentMethod.description === 'Credit Card'
    );
  }

  openUpdatePaymentModal() {
    this.paymentFormComponent.clearForm();
    this.serverError = '';
    this.isProcessing = false;
    this.isInitialAutopay = false;
    this.isUpdatingPayment = true;

    this.showPaymentModal = true;
  }

  getPaymentMethodText() {
    if (this.autopayInfo) {
      return this.invoiceService.getAutopayPaymentText(this.autopayInfo);
    }
    return '';
  }

  closePaymentModal() {
    this.showPaymentModal = false;
  }

  handlePaymentFormSubmit() {
    this.isProcessing = true;
    if (this.isInitialAutopay) {
      this.enrollAutopay();
    } else {
      this.updatePaymentMethod();
    }
  }

  openUnenrollAutopayModal() {
    this.closePaymentModal();

    this.unenrollErrorMessage = '';
    this.isProcessingUnenroll = false;
    this.showUnenrollAutopayModal = true;
  }

  enrollAutopay() {
    let stripeToken$: Observable<string>;
    let paymentType: string;
    if (this.paymentFormComponent.isCardPayment()) {
      paymentType = 'card';
      stripeToken$ = this.invoicePaymentService.createStripeCreditCardToken(
        this.paymentFormComponent.getCardNumber(),
        {},
        true,
        0
      );
    } else {
      paymentType = 'ach';
      const bankData: CreateTokenBankAccountData = this.paymentFormComponent.generateBankData();

      stripeToken$ = this.invoicePaymentService.createStripeBankToken(bankData);
    }

    this.sub.add(
      stripeToken$
        .pipe(
          switchMap((token: string) =>
            this.invoicePaymentService.enrollAutopay(this.accountId, paymentType, token)
          )
        )
        .subscribe(
          () => {
            this.amplitudeService.track({
              eventName: 'pay_invoice_auto',
              detail: paymentType,
              useLegacyEventName: true,
            });
            this.isProcessing = false;
            this.closePaymentModal();
            this.reloadData();
          },
          (err) => this.handleServerErrors(err)
        )
    );
  }

  updatePaymentMethod() {
    let stripeToken$: Observable<string>;
    let paymentType: PaymentType;

    if (this.paymentFormComponent.isCardPayment()) {
      paymentType = 'card';
      stripeToken$ = this.invoicePaymentService.createStripeCreditCardToken(
        this.paymentFormComponent.getCardNumber(),
        {},
        true,
        0
      );
    } else {
      paymentType = 'ach';
      const bankData: CreateTokenBankAccountData = this.paymentFormComponent.generateBankData();

      stripeToken$ = this.invoicePaymentService.createStripeBankToken(bankData);
    }

    stripeToken$
      .pipe(
        flatMap((token: string) => {
          return this.invoicePaymentService.updatePaymentInformation({
            accountNumber: this.accountId,
            paymentId: token,
            paymentType: paymentType,
          });
        })
      )
      .subscribe(
        () => {
          this.isProcessing = false;
          this.closePaymentModal();
          this.reloadAutopayDetails();
        },
        (err) => this.handleServerErrors(err)
      );
  }

  handleServerErrors(serverError: InvoicePaymentError) {
    this.isProcessing = false;

    if (serverError.code && serverError.message) {
      this.serverError = serverError.message;
    } else if (serverError.error && serverError.error.errors) {
      this.serverError = serverError.error.errors[0];
    } else {
      this.serverError =
        'Oops, seems like there is an issue. If this problem persists, please try again later or call 888-530-4650';
    }
  }

  navToInvoicePage() {
    if (!this.invoices) {
      this.sentryService.notify(
        'Attempted to nav to latest invoice, but invoice details were absent',
        {
          severity: 'error',
          metaData: {
            accountId: this.accountId,
          },
        }
      );
      return;
    }

    const { id, transactionId } = this.invoices[0];

    this.router.navigate([`/bop/invoice/${id}`], {
      queryParams: { token: transactionId },
    });
  }

  getEarliestDueInvoice() {
    if (this.invoices) {
      if (this.pendingReinstatementInvoice) {
        return this.pendingReinstatementInvoice;
      }
      let firstDueInvoice = this.invoices[0];
      this.invoices.forEach((invoice) => {
        if (invoice.amountDue > 0) {
          firstDueInvoice = invoice;
        }
      });
      return firstDueInvoice;
    }
    return null;
  }

  getEarliestDueInvoiceRoute() {
    const earliestDueInvoice = this.getEarliestDueInvoice();
    if (earliestDueInvoice) {
      const { id } = earliestDueInvoice;
      return `/bop/invoice/${id}`;
    }
    return null;
  }

  getEarliestDueInvoiceQueryParams() {
    const earliestDueInvoice = this.getEarliestDueInvoice();
    if (earliestDueInvoice) {
      const { transactionId } = earliestDueInvoice;
      return { token: transactionId };
    }
    return null;
  }

  onlyShowACHOption() {
    if (!this.latestInvoiceDetails || !this.latestInvoiceDetails.charges) {
      return false;
    }
    return this.latestInvoiceDetails.charges.some(
      (charge) => charge.lineOfBusiness === HAB_PRODUCT_NAME
    );
  }

  completeBillingInformationalState() {
    if (this.loggedBillingTip) {
      this.newInformationalService.completeBopBindState(BOP_BIND_INFORMATIONAL.BILLING_CARD_TIP);
    }
  }

  ngOnDestroy() {
    this.completeBillingInformationalState();
    this.sub.unsubscribe();
  }

  getPolicyTermDetails(
    accountSummary: AccountSummary,
    invoice: Invoice | null
  ): PolicyTermDetails[] {
    return this.invoiceBannerService.getPolicyTermDetails(accountSummary, invoice);
  }

  hasSeparator(index: number) {
    if (this.policyTermDetails.length <= 1) {
      return false;
    }

    if (this.policyTermDetails.length - 1 === index) {
      return false;
    }
    return true;
  }

  formatDate(date: Date) {
    return moment.utc(date).format('MM/DD/YYYY');
  }

  isCardProcessingFeeEligible() {
    return _.get(this.invoices, '0.isCardProcessingFeeEligible');
  }

  getBillingLink() {
    return ['/accounts/accounts/invoice-list', this.accountId];
  }

  getSupportBillingParams() {
    return { path: 'Billing' };
  }
}
