<ng-container *ngIf="showDebrisRemovalCoverage">
  <div class="js-bop-debris-removal">
    <app-form-field-radio
      inputId="{{ prefix }}-debris-removal"
      [form]="coverage"
      [submitted]="submitted"
      questionText="Additional Debris Removal"
      nameOfFormControl="debrisRemoval"
      [options]="availableDebrisRemovalLimits"
    >
    </app-form-field-radio>
  </div>
</ng-container>
