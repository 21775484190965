import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faqs-list',
  templateUrl: './faqs-list.component.html',
})
export class FaqsListComponent {
  @Input()
  faqs: (Faq & { isToggled: boolean })[] = [];

  toggleFaq(index: number) {
    const faq = this.faqs[index];
    faq.isToggled = !faq.isToggled;
  }

  isToggled(index: number) {
    return this.faqs[index].isToggled;
  }
}
