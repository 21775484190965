import { of as observableOf, Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import {
  FormDslNode,
  FormDslStep,
  FormDslData,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { EvaluatorName } from '../form-dsl/constants/form-dsl-constants';

export const FORM_CONFIG: { [formName: string]: FormDslStep[] } = {
  simple: [
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 1',
      slug: 'step-one',
      parent: 'step-one',
      formPath: 'stepOne',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-1',
            nameOfFormControl: 'form-dsl-multi-step-text-field-1',
            labelText: 'Text label 1',
            placeholder: 'e.g., hello',
          },
        ]);
      },
    },
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 2',
      slug: 'step-two',
      parent: 'step-two',
      formPath: 'stepTwo',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-2',
            nameOfFormControl: 'form-dsl-multi-step-text-field-2',
            labelText: 'Text label 2',
            placeholder: 'e.g., hello',
            required: true,
          },
        ]);
      },
    },
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 3',
      slug: 'step-three',
      parent: 'step-three',
      formPath: 'stepThree',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-3',
            nameOfFormControl: 'form-dsl-multi-step-text-field-3',
            labelText: 'Text label 3',
            placeholder: 'e.g., hello',
          },
        ]);
      },
    },
  ],
  async: [
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 1',
      slug: 'step-one',
      parent: 'step-one',
      formPath: 'stepOne',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-1',
            nameOfFormControl: 'form-dsl-multi-step-text-field-1',
            labelText: 'Text label 1',
            placeholder: 'e.g., hello',
          },
        ]);
      },
    },
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 2',
      slug: 'step-two',
      parent: 'step-two',
      formPath: 'stepTwo',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf(null).pipe(
          delay(3000),
          map(() => {
            return [
              {
                primitive: 'TEXT',
                inputId: 'form-dsl-multi-step-text-field-2',
                nameOfFormControl: 'form-dsl-multi-step-text-field-2',
                labelText: 'Text label 2',
                placeholder: 'e.g., hello',
                required: true,
              },
            ];
          })
        );
      },
    },
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 3',
      slug: 'step-three',
      parent: 'step-three',
      formPath: 'stepThree',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-3',
            nameOfFormControl: 'form-dsl-multi-step-text-field-3',
            labelText: 'Text label 3',
            placeholder: 'e.g., hello',
          },
        ]);
      },
    },
  ],
  multipleConditional: [
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 1',
      slug: 'step-one',
      parent: 'step-one',
      formPath: 'stepOne',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'NUMBER',
            nameOfFormControl: 'firstField',
            inputId: 'form-dsl-multiple-conditional-input1',
            labelText: 'First form input',
          },
          {
            primitive: 'NUMBER',
            nameOfFormControl: 'secondField',
            inputId: 'form-dsl-multiple-conditional-input2',
            labelText: 'Second form input',
          },
          {
            primitive: 'TEXT',
            nameOfFormControl: 'validationField',
            inputId: 'validation-test-field',
            labelText: 'Validation input',
          },
          {
            children: [],
            primitive: 'VALIDATION_MESSAGE',
            errorType: 'testValidationError',
            nameOfFormControl: 'validationMessage',
            inputId: 'form-dsl-validation-message',
          },
          {
            primitive: 'EVAL-CONDITIONAL',
            dependsOn: ['firstField', 'secondField'],
            enableEvaluator: EvaluatorName.TWO_POSITIVE_VALUES,
            conditionalChildren: [
              {
                primitive: 'NUMBER',
                inputId: 'form-dsl-multiple-conditional-condtioned-input',
                nameOfFormControl: 'sumInput',
                labelText: 'What is the sum of the two numbers?',
              },
            ],
          },
        ]);
      },
    },
  ],
  conditional: [
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 1',
      slug: 'step-one',
      parent: 'step-one',
      formPath: 'stepOne',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-1',
            nameOfFormControl: 'form-dsl-multi-step-text-field-1',
            labelText: 'Text label 1',
            placeholder: 'e.g., hello',
          },
        ]);
      },
    },
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 2',
      slug: 'step-two',
      parent: 'step-two',
      formPath: 'stepTwo',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-2',
            nameOfFormControl: 'form-dsl-multi-step-text-field-2',
            labelText: 'Text label 2',
            placeholder: 'e.g., hello',
            required: true,
          },
          {
            primitive: 'RADIO',
            nameOfFormControl: 'form-dsl-multi-step-text-field-2-1',
            inputId: 'form-dsl-multi-step-text-field-2-1',
            cobOnly: 'ALL',
            conditional: 'None',
            options: {
              Yes: 'Yes',
              No: 'No',
            },
            labelText: 'Do you have a dog?',
          },
          {
            primitive: 'VALUE-CONDITIONAL',
            dependsOn: 'form-dsl-multi-step-text-field-2-1',
            enableValue: 'Yes',
            cobOnly: 'ALL',
            conditionalChildren: [
              {
                primitive: 'TEXT',
                cobOnly: 'ALL',
                cssClass: 'sub-question',
                nameOfFormControl: 'form-dsl-multi-step-text-field-2-2',
                inputId: 'form-dsl-multi-step-text-field-2-2',
                labelText: 'Name of dog',
                required: true,
              },
            ],
          },
          {
            primitive: 'VALUE-CONDITIONAL',
            dependsOn: 'form-dsl-multi-step-text-field-2-1',
            enableValue: 'Yes',
            cobOnly: 'ALL',
            conditionalChildren: [
              {
                primitive: 'TEXT',
                cobOnly: 'ALL',
                cssClass: 'sub-question',
                nameOfFormControl: 'form-dsl-multi-step-text-field-2-3',
                inputId: 'form-dsl-multi-step-text-field-2-3',
                labelText: 'Age of dog',
                required: true,
              },
            ],
          },
          {
            primitive: 'VALUE-CONDITIONAL',
            dependsOn: 'form-dsl-multi-step-text-field-2-1',
            enableValue: 'Yes',
            cobOnly: 'ALL',
            conditionalChildren: [
              {
                primitive: 'TEXT',
                cobOnly: 'ALL',
                cssClass: 'sub-question',
                nameOfFormControl: 'form-dsl-multi-step-text-field-2-4',
                inputId: 'form-dsl-multi-step-text-field-2-4',
                labelText: 'Breed of dog',
                required: true,
              },
            ],
          },
        ]);
      },
    },
    {
      /* === BEGIN RouteFormStep PROPERTIES === */
      args: {},
      displayName: 'Step 3',
      slug: 'step-three',
      parent: 'step-three',
      formPath: 'stepThree',
      /* === END RouteFormStep PROPERTIES === */
      getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
        return observableOf([
          {
            primitive: 'TEXT',
            inputId: 'form-dsl-multi-step-text-field-3',
            nameOfFormControl: 'form-dsl-multi-step-text-field-3',
            labelText: 'Text label 3',
            placeholder: 'e.g., hello',
          },
        ]);
      },
    },
  ],
};

export const FORM_DATA: { [key: string]: any } = [
  {},
  {
    'form-dsl-multi-step-text-field-1': 'text value 1',
  },
  {
    'form-dsl-multi-step-text-field-1': 'text value 1',
    'form-dsl-multi-step-text-field-2': 'text value 2',
    'form-dsl-multi-step-text-field-2-1': 'Yes',
    'form-dsl-multi-step-text-field-2-2': 'Dewey',
    'form-dsl-multi-step-text-field-2-3': '25',
    'form-dsl-multi-step-text-field-2-4': 'Yorkie',
  },
  {
    'form-dsl-multi-step-text-field-1': 'text value 1',
    'form-dsl-multi-step-text-field-2': 'text value 2',
    'form-dsl-multi-step-text-field-2-1': 'Yes',
    'form-dsl-multi-step-text-field-2-2': 'Dewey',
    'form-dsl-multi-step-text-field-2-3': '25',
    'form-dsl-multi-step-text-field-2-4': 'Yorkie',
    'form-dsl-multi-step-text-field-3': 'text value 3',
  },
  {
    'form-dsl-multi-step-text-field-1': 'text value 1',
    'form-dsl-multi-step-text-field-2': 'text value 2',
    'form-dsl-multi-step-text-field-2-1': 'No',
  },
  {
    'form-dsl-multi-step-text-field-1': 'text value 1',
    'form-dsl-multi-step-text-field-2': 'text value 2',
    'form-dsl-multi-step-text-field-2-1': 'Yes',
  },
];
