import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable, of as observableOf, Subscription } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { last } from 'lodash';

// Components
import { FormDslSteppedFormBaseComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form-base.component';

// Services
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import {
  OnboardingFormService,
  ROLES_TO_SKIP_SAMPLE_QUOTE,
  UserRoleOption,
} from 'app/features/onboarding/services/onboarding-form.service';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import {
  OnboardingAmplitudeEvents,
  OnboardingQueryParams,
  OnboardingService,
  OnboardingUserPath,
  SampleAccountTriggers,
} from 'app/shared/services/onboarding.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';

// Helpers
import { enableDisableControl, getControl, getFormGroup } from 'app/shared/helpers/form-helpers';
import { SampleAccountType } from 'app/shared/sample-accounts/sample-accounts';
import { UserAttributesService } from '../../../../core/services/user-attributes.service';
import { UserAttributes } from '../../../../shared/models/user-attributes';
import { FeatureFlagService } from '../../../../core/services/feature-flag.service';

@Component({
  selector: 'app-onboarding-page',
  templateUrl: './onboarding-page.component.html',
})
export class OnboardingPageComponent
  extends FormDslSteppedFormBaseComponent<OnboardingFormService>
  implements OnInit, OnDestroy
{
  loading = false;
  skipLoading = false;
  selectedClassDisplayName: string;
  showExploreAppetite = false;
  onboardingFormExitPath = OnboardingUserPath.SAMPLE;
  private sub = new Subscription();

  constructor(
    protected amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private featureFlagService: FeatureFlagService,
    public formService: OnboardingFormService,
    private insuredAccountService: InsuredAccountService,
    private onboardingService: OnboardingService,
    private userAttributesService: UserAttributesService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(formService, route, router);
  }

  ngOnInit() {
    super.ngOnInit();

    this.sub.add(this.handleUserRoleChanges().subscribe());
    this.sub.add(this.syncRouteChangesWithFormSteps().subscribe());
    this.sub.add(this.handleRedirectFromQuoteFlow().subscribe());

    const selectedClassification = getControl(this.form, 'sampleClassification.selection');
    if (selectedClassification) {
      this.sub.add(
        selectedClassification.valueChanges.subscribe((selection: SampleAccountType) => {
          if (selection === SampleAccountType.SALON) {
            this.selectedClassDisplayName = 'beauty salon';
          } else {
            this.selectedClassDisplayName = selection;
          }
        })
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sub.unsubscribe();
  }

  public loadInitialData(): void {}

  public onIncrementedStep(_nextStep: RouteFormStep): void {
    this.navigateToCurrentStep();
  }

  public checkQuotingSuccess(response: string | boolean | null): Observable<boolean> {
    // A null response means that there was an error creating a sample insured account
    if (response === null) {
      this.sentryService.notify(
        'Error creating a sample account for user skipping the sample class selection'
      );
    }

    // TODO(LT): Consider displaying an error state to the user or trying to create an insured account again
    return observableOf(true);
  }

  public handleSuccessfulQuote(): void {
    this.loading = false;
    this.skipLoading = false;

    this.setOnboardingUserState(this.onboardingFormExitPath);

    switch (this.onboardingFormExitPath) {
      case OnboardingUserPath.SKIP:
        /**
         * Note: Because the accounts dashboard uses a cached account list,
         * users for whom the sample account is their first insured account
         * can erroneously be redirected to create their first account.
         * Adding the sample account id to the route will prevent
         * this miscalculation.
         */
        this.router.navigate(['/accounts', this.onboardingService.sampleAccountId]);

        break;
      case OnboardingUserPath.QUOTE:
        this.router.navigate(['/accounts', 'new'], {
          queryParams: {
            [OnboardingQueryParams.SKIPPED_SAMPLE_QUOTE]: true,
          },
        });

        break;
      case OnboardingUserPath.SAMPLE:
        const selectedClassification = getControl(
          this.form,
          'sampleClassification.selection'
        ).value;
        this.router.navigate(['/sample', 'accounts', 'new'], {
          queryParams: {
            [OnboardingQueryParams.CLASS]: selectedClassification,
          },
        });
    }
  }

  public handleFailedQuote(): void {}

  public sendForm(): Observable<string | boolean | null> {
    this.loading = true;

    // Record the final form answers in Amplitude
    this.submitFormDataToAmplitude();

    // Create a sample insured account for a user who selected a role that skips sample quoting,
    // so that they have an account created under their username and don't see this flow again
    if (this.onboardingFormExitPath === OnboardingUserPath.SKIP) {
      return this.onboardingService.createSampleAccountForUser({
        sampleAccountTrigger: SampleAccountTriggers.SERVICING_ROLE,
      });
    }

    return observableOf(true);
  }

  public stepIsInvalid() {
    return this.formService.submitted && !this.formService.isCurrentStepValid();
  }

  public handleSubmit(submitEvent?: Event) {
    if (this.formService.isFinalStep()) {
      this.userAttributesService
        .updateUserAttribute(UserAttributes.ONBOARDING_ACCOUNTS_COMPLETE, 'created')
        .subscribe();
    }
    return super.handleSubmit(submitEvent);
  }

  public exploreAppetite() {
    this.userAttributesService
      .updateUserAttribute(UserAttributes.ONBOARDING_ACCOUNTS_DISMISSED, 'skipped')
      .subscribe();
    this.router.navigate(['/appetite']);
  }

  private setOnboardingUserState(userState: OnboardingUserPath) {
    this.onboardingService.onboardingUserPath = userState;
  }

  /**
   * Based on user role selection, determine if they should skip the sample quote flow.
   * If yes:
   * - Disable the sample classification question
   * - Track that the user will be directed to accounts page
   *
   * If no:
   * - Display the sample quote opt-in question
   * - Enable or disable the sample classification question based on opt-in answer
   * - Track whether they should be directed to the sample or real quote flow
   */
  handleUserRoleChanges() {
    const userRoles = getFormGroup(this.formService.form, 'userRole.role');
    const sampleQuoteOptIn = getFormGroup(this.formService.form, 'userRole.sampleQuoteOptIn');

    return combineLatest(
      userRoles.valueChanges.pipe(startWith(null)),
      sampleQuoteOptIn.valueChanges.pipe(startWith(null))
    ).pipe(
      tap(([changedRoleValue, changedOptedInValue]: [UserRoleOption, boolean]) => {
        // Check to see if the form control has an existing value if the observable emits `null`
        const role = changedRoleValue !== null ? changedRoleValue : userRoles.value;

        if (role) {
          const roleShouldSkipSampleQuote = ROLES_TO_SKIP_SAMPLE_QUOTE.includes(role);

          if (!roleShouldSkipSampleQuote) {
            // Check to see if the form control has an existing value if the observable emits `null`
            const optedIntoSampleQuote =
              changedOptedInValue !== null ? changedOptedInValue : sampleQuoteOptIn.value;
            enableDisableControl(sampleQuoteOptIn, true);

            if (optedIntoSampleQuote) {
              this.onboardingFormExitPath = OnboardingUserPath.SAMPLE;
              this.formService.toggleClassificationStep(true);
            } else {
              this.onboardingFormExitPath = OnboardingUserPath.QUOTE;
              this.formService.toggleClassificationStep(false);
            }

            this.showExploreAppetite = true;
          } else {
            this.showExploreAppetite = false;
            this.onboardingFormExitPath = OnboardingUserPath.SKIP;
            this.formService.toggleClassificationStep(false);
            enableDisableControl(sampleQuoteOptIn, false);
          }
        }
      })
    );
  }

  submitFormDataToAmplitude() {
    const userRole = getControl(this.form, 'userRole.role');
    if (userRole) {
      this.amplitudeService.track({
        eventName: OnboardingAmplitudeEvents.ROLE,
        detail: userRole.value,
        useLegacyEventName: true,
      });
    }

    const sampleQuoteOptIn = getControl(this.form, 'userRole.sampleQuoteOptIn');
    if (sampleQuoteOptIn && sampleQuoteOptIn.enabled) {
      const eventName = sampleQuoteOptIn.value
        ? OnboardingAmplitudeEvents.SAMPLE_OPT_IN
        : OnboardingAmplitudeEvents.SAMPLE_OPT_OUT;

      this.amplitudeService.track({
        eventName,
        detail: '',
        useLegacyEventName: true,
      });
    }

    const selectedClassification = getControl(this.form, 'sampleClassification.selection');
    if (selectedClassification && selectedClassification.enabled) {
      this.amplitudeService.track({
        eventName: OnboardingAmplitudeEvents.CLASSIFICATION,
        detail: selectedClassification.value,
        useLegacyEventName: true,
      });
    }
  }

  handleRedirectFromQuoteFlow() {
    return this.route.queryParams.pipe(
      tap((params) => {
        if (params[OnboardingQueryParams.FROM_MODAL]) {
          const sampleQuoteOptInControl = getControl(
            this.formService.form,
            'userRole.sampleQuoteOptIn'
          );
          if (!sampleQuoteOptInControl.value) {
            sampleQuoteOptInControl.patchValue(true);
            this.formService.stepForward();
          }
        }
      })
    );
  }

  // Note: This bug is present in the base stepped form service logic and
  // occurs when a user uses the browser back/forward buttons to navigate
  // between form pages
  syncRouteChangesWithFormSteps() {
    return this.router.events.pipe(
      tap((event) => {
        if (event instanceof NavigationEnd) {
          const url = event.url.split('?')[0];
          const urlSegments = url.split('/');
          const urlSlug = last(urlSegments);

          if (urlSlug && urlSlug !== this.currentStep().slug) {
            const urlStep = this.formService.findStep({ args: {}, slug: urlSlug });

            if (urlStep) {
              const stepDifference = this.formService.stepDifference(this.currentStep(), urlStep);

              // Because there are only two steps in the onboarding form,
              // it's safe to assume that we'll only need to step once in any direction
              if (stepDifference < 1) {
                this.formService.stepBackward();
              } else {
                this.formService.stepForward();
              }
            }

            this.sentryService.notify("Url slug does not match form service's current step slug", {
              metaData: { urlSlug, urlStep },
            });
          }
        }
      })
    );
  }

  public handleSkip(event: Event) {
    event.preventDefault();
    this.skipLoading = true;
    this.onboardingFormExitPath = OnboardingUserPath.SKIP;

    // Note: Amplitude events for the form selections are NOT
    // submitted if a user chooses to explicitly skip the flow

    this.onboardingService
      .createSampleAccountForUser({
        sampleAccountTrigger: SampleAccountTriggers.EXPLICIT_SKIP,
      })
      .pipe(
        tap(() => {
          this.handleSuccessfulQuote();
        })
      )
      .subscribe();

    this.userAttributesService
      .updateUserAttribute(UserAttributes.ONBOARDING_ACCOUNTS_DISMISSED, 'skipped')
      .subscribe();
  }

  public showSkipButton() {
    return this.currentStep().formPath === 'userRole';
  }

  public showSampleQuoteTip() {
    return (
      this.currentStep().formPath === 'sampleClassification' && !!this.selectedClassDisplayName
    );
  }

  public showAccountsNote() {
    const roleSelection = getControl(this.form, 'userRole.role');
    return (
      roleSelection.value === UserRoleOption.SERVICE &&
      this.onboardingFormExitPath === OnboardingUserPath.SKIP
    );
  }
}
