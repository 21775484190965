import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { Industry } from '../../services/naics.service';

import { SearchFormatter, SearchQueryMethod } from '../constants/form-dsl-typings';
import { COALITION_CYBER_INDUSTRIES } from 'app/features/coalition/models/cyber-industries.model';
import { LM_CPSP_GL_CLASS_CODES } from 'app/features/liberty-mutual/models/gl-class-codes';
import { LibertyMutualClassCode } from 'app/features/liberty-mutual/models/common-typings';
import { LM_BOP_CLASSES } from 'app/features/liberty-mutual/models/class-codes';

type FormDslSearchMethods = {
  [key in SearchFormatter]: (result: any) => string;
} & {
  [key in SearchQueryMethod]: (query: string, resultsAllowList?: string[]) => Observable<any[]>;
};

export const coalitionCyberIndustryFormatter = (result: Industry) => {
  if (!result) {
    return '';
  }
  return result.displayName;
};

export const coalitionCyberIndustryQueryMethod = (
  query: string,
  mappings?: string[]
): Observable<Industry[]> => {
  const industries =
    mappings && mappings.length
      ? COALITION_CYBER_INDUSTRIES.filter((industry) => mappings.includes(industry.id.toString(10)))
      : COALITION_CYBER_INDUSTRIES;

  if (query.length < 1) {
    return observableOf(industries);
  }

  const found = industries.filter(({ displayName }) => {
    return displayName.toLowerCase().includes(query.toLowerCase());
  });

  return observableOf(found);
};

/**
 * This service defines query and formatter methods to be used with the DROPDOWN_SEARCH
 * primitive, which is an abstraction of the DropdownSearchComponent.
 *
 * The query method defines how and from where data is retrieved, and how to present
 * the results (quantity, order, etc.)
 *
 * The formatter is used to convert each result to a user-readable string.
 */
@Injectable()
export class FormDslSearchService implements FormDslSearchMethods {
  coalitionCyberIndustryFormatter = coalitionCyberIndustryFormatter;

  coalitionCyberIndustryQueryMethod = coalitionCyberIndustryQueryMethod;

  lmBopClassCodeFormatter(result: LibertyMutualClassCode) {
    if (!result) {
      return '';
    }
    return result.name;
  }

  lmBopClassCodeQueryMethod(query: string, classCodeAllowList?: string[]) {
    let queryableClassCodes: LibertyMutualClassCode[];

    if (Array.isArray(classCodeAllowList)) {
      queryableClassCodes = LM_BOP_CLASSES.filter(({ code }) => {
        return classCodeAllowList.includes(code);
      });
    } else {
      queryableClassCodes = LM_BOP_CLASSES;
    }

    if (query.length < 1) {
      return observableOf(queryableClassCodes);
    }

    const found = queryableClassCodes.filter(({ name }) => {
      return name.toLowerCase().includes(query.toLowerCase());
    });

    return observableOf(found);
  }

  lmGlClassCodeFormatter(result: LibertyMutualClassCode) {
    if (!result) {
      return '';
    }
    return result.name;
  }

  lmGlClassCodeQueryMethod(query: string, classCodeAllowList?: string[]) {
    let queryableClassCodes: LibertyMutualClassCode[];

    if (Array.isArray(classCodeAllowList)) {
      queryableClassCodes = LM_CPSP_GL_CLASS_CODES.filter(({ code }) => {
        return classCodeAllowList.includes(code);
      });
    } else {
      queryableClassCodes = LM_CPSP_GL_CLASS_CODES;
    }

    if (query.length < 1) {
      return observableOf(queryableClassCodes);
    }

    const found = queryableClassCodes.filter(({ name }) => {
      return name.toLowerCase().includes(query.toLowerCase());
    });

    return observableOf(found);
  }
}
