import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getControl } from '../../../../shared/helpers/form-helpers';
import { Router } from '@angular/router';
import { SUPPORT_ZENDESK_URL } from '../../models/support-constants';

@Component({
  selector: 'app-faq-search',
  templateUrl: './faq-search.component.html',
})
export class FaqSearchComponent implements OnInit {
  form: FormGroup;
  inputId = 'faq';
  nameOfFormControl = 'faq';
  constructor(private formBuilder: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      [this.inputId]: [null, Validators.required],
    });
  }

  handleSubmit(submitEvent?: Event) {
    const searchText = getControl(this.form, this.nameOfFormControl).value;
    let url = SUPPORT_ZENDESK_URL;
    if (searchText) {
      url = `${SUPPORT_ZENDESK_URL}/search?utf8=%E2%9C%93&query=${searchText}&commit=Search`;
    }
    window.open(url, '_blank');
  }
}
