import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoalitionCyberBindFormService } from 'app/features/coalition/services/coalition-cyber-bind-form.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { filter } from 'rxjs/operators';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { CYBER_ADMITTED_BIND_FAQS } from 'app/features/coalition/models/cyber-constants.model';

@Component({
  selector: 'app-cyber-bind-quote',
  templateUrl: './coalition-cyber-bind-form-container.component.html',
})
export class CoalitionCyberBindFormContainerComponent implements OnInit {
  accountId: string;
  quoteId: string;

  sub: Subscription = new Subscription();
  insuredAccount$: Observable<InsuredAccount | null>;
  insuredAccountSubject: BehaviorSubject<InsuredAccount | null> = new BehaviorSubject(null);

  cyberAdmittedFAQs: (Faq & { isToggled?: boolean })[] = CYBER_ADMITTED_BIND_FAQS;

  constructor(
    public formService: CoalitionCyberBindFormService,
    private route: ActivatedRoute,
    private insuredAccountService: InsuredAccountService
  ) {}

  ngOnInit() {
    this.accountId = this.route.snapshot.params['accountId'];
    this.quoteId = this.route.snapshot.params['quoteId'];
    this.insuredAccount$ = this.insuredAccountSubject.asObservable();
    this.loadAccountDetails();
  }

  private loadAccountDetails() {
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => {
            return this.accountId === insuredAccount.id.toString();
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccountSubject.next(insuredAccount);
        })
    );
  }
}
