import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formatMoneyNoCents } from '../../../../shared/helpers/number-format-helpers';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { NeptuneFloodQuotedQuoteResponse } from 'app/features/digital-carrier/models/types';
import { NEPTUNE_FLOOD_GUIDE } from '../../../support/models/support-constants';

@Component({
  selector: 'app-neptune-flood-helper-modal',
  templateUrl: './neptune-flood-helper-modal.component.html',
})
export class NeptuneFloodHelperModalComponent implements OnInit {
  @Input() neptuneFloodQuote: NeptuneFloodQuotedQuoteResponse;

  @Input() showHideNeptuneFloodPrompt = true;

  @Output() handleModalClose: EventEmitter<null> = new EventEmitter();

  hideNeptuneFloodPrompt: boolean;

  neptuneFloodPrompt: UntypedFormGroup;

  formatMoneyNoCents = formatMoneyNoCents;

  NEPTUNE_FLOOD_GUIDE = NEPTUNE_FLOOD_GUIDE;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    if (this.neptuneFloodQuote && 'pasId' in this.neptuneFloodQuote) {
      this.amplitudeService.track({
        eventName: 'neptune_flood_quote_rendered',
        detail: this.neptuneFloodQuote.pasId,
      });
    }
    this.neptuneFloodPrompt = this.formBuilder.group({
      dontShowAgain: [null],
    });
    this.hideNeptuneFloodPrompt = !!localStorage.getItem('hideNeptuneFloodPrompt');
  }

  continueToNeptuneAndClose() {
    this.continueToNeptune();
    this.close();
  }

  continueToNeptune() {
    this.amplitudeService.track({
      eventName: 'neptune_flood_quote_opened',
      detail: this.neptuneFloodQuote.pasId,
    });
  }

  close() {
    if (this.neptuneFloodPrompt.value.dontShowAgain === true) {
      localStorage.setItem('hideNeptuneFloodPrompt', JSON.stringify({ hidePrompt: true }));
      this.hideNeptuneFloodPrompt = true;
    }
    this.handleModalClose.emit();
  }
}
