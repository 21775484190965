import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ExcessBindLimitOptions } from 'app/features/attune-bop/models/constants';
import { filter } from 'lodash';
import { getControl } from 'app/shared/helpers/form-helpers';

// UMUIM is for uninsured / under insured motorists.
@Component({
  selector: 'app-attune-bop-bind-excess-umuim',
  templateUrl: './attune-bop-bind-excess-umuim.component.html',
})
export class AttuneBopBindExcessQuoteUMUIMComponent implements OnInit {
  @Input() stateCode: string;
  @Input() limit: number | null;
  @Output() stateFormGroup: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Input() submitted: boolean;
  @Input() excessCLLimit: number;
  options = { ACCEPT: true, REJECT: false };
  stateName: string;
  formGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      accepted: [true, Validators.required],
      limit: [this.allowedOption(this.limit), Validators.required],
    });
    this.stateFormGroup.emit(this.formGroup);

    switch (this.stateCode) {
      case 'FL':
        this.stateName = 'Florida';
        break;
      case 'NH':
        this.stateName = 'New Hampshire';
        break;
      case 'VT':
        this.stateName = 'Vermont';
        break;
      case 'LA':
        this.stateName = 'Louisiana';
        break;
    }

    const getStateControl = (ctrlName: string) => getControl(this.formGroup, `${ctrlName}`);

    getStateControl('accepted').valueChanges.subscribe((val) => {
      if (!val) {
        getStateControl('limit').disable();
      } else {
        getStateControl('limit').enable();
      }
    });
  }

  getUMUIMLimitOptions(stateCode: string, excessCLLimit: number) {
    let options: number[] = [];
    switch (stateCode) {
      case 'FL':
        options = [1e6];
        break;
      case 'VT':
      case 'LA':
        options = filter(ExcessBindLimitOptions, (n: number) => n <= excessCLLimit);
        break;
      case 'NH':
        options = [excessCLLimit];
        break;
    }
    return options;
  }

  private allowedOption(option: number | null) {
    if (option === null) {
      return null;
    }
    const options = this.getUMUIMLimitOptions(this.stateCode, this.excessCLLimit);
    return options.includes(option) ? option : null;
  }

  get accepted() {
    return getControl(this.formGroup, 'accepted').value;
  }
}
