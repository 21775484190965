<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div
    class="{{ cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(nameOfFormControl) }"
  >
    <label [for]="inputId">{{ labelText }}</label>
    <input
      type="text"
      [id]="inputId"
      [name]="inputId"
      [value]="derivedValue()"
      [formControl]="form.get(nameOfFormControl)"
      [placeholder]="placeholder"
      #inputElement
      (focusin)="handleFocusIn()"
      (focusout)="handleFocusOut()"
      (keypress)="handleKeyPress($event)"
      autocomplete="new-password"
      [readonly]="readonly"
    />
  </div>
</ng-container>
