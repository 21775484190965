<div class="invoice-wrapper">
  <div class="invoice-container invoice-container__single-column">
    <div class="terms-and-conditions-header">
      <a class="button button__nav button__no-margin" (click)="goBack()" (keyup.enter)="goBack()"
        ><span class="icon-arrow-left button-icon"></span> Back
      </a>
    </div>
    <div class="nav-button-group nav-button-group__centered">
      <div>
        <h1>Terms of Use</h1>

        Attune uses a third party vendor, Stripe, Inc. (Stripe) to manage the on-line facility that
        allows you to pay your premium with your credit card or through an Automated Clearing House
        (ACH). ACH is a computer-based clearing and settlement facility that processes the exchange
        of electronic transactions. Stripe is not under Attune’s direct control, and they may
        collect information that Attune does not. Attune’s agreement with Stripe requires the
        parties to maintain physical, electronic, and procedural safeguards to protect your
        information and to access customer information only for legitimate business purposes.

        <br /><br />

        Attune sells commercial insurance to businesses. Accordingly, data collected by Attune or
        Stripe will primarily include information about a customer’s business, rather than personal
        information (information which may be used to identify, contact, or locate a single person,
        or to identify an individual). In a limited number of cases, such as businesses that are
        sole proprietorships or pay insurance premiums with a personal credit card, some personal
        information may be collected.

        <br /><br />

        We encourage you to learn about how data is collected in the payment process by reviewing
        Stripe’s Privacy Policy, which can be found at
        <a href="http://www.stripe.com/us/privacy">www.stripe.com/us/privacy</a>. Stripe maintains a
        frequently updated information page about data privacy issues at
        <a href="http://www.stripe.com/privacy-center/legal">www.stripe.com/privacy-center/legal</a
        >.

        <br /><br />

        Attune’s Privacy Policy, as well as information about how Attune is compensated and other
        important consumer information, can be found at our website’s Corporate, Licensing and Legal
        Information page at
        <a href="http://www.attuneinsurance.com/legal">www.attuneinsurance.com/legal</a>.

        <br /><br />

        At your option, you may elect to pay your insurance premium in such a way that information
        is not collected electronically, such as by mail.

        <br /><br />

        If you elect to pay your premium in installments, additional fees will be added to reflect
        increased processing costs. Failure to pay an installment fee may qualify as a non-payment
        of premium resulting in policy cancellation.
      </div>
    </div>
  </div>
  <app-footer> </app-footer>
</div>
