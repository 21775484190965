import * as _ from 'lodash';
import * as moment from 'moment';
import { BopPolicy } from 'app/features/attune-bop/models/bop-policy';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { CurrentUser } from 'app/shared/models/current-user';
import { BackendCreateInsuredAccountRequestPayloadFactory } from 'app/shared/models/backend/backend-create-insured-account-payload-factory';
import { coverageTerms } from '../models/coverages';
import {
  getAdditionalInterestsForBuilding,
  getAdditionalInsuredsForLocation,
  getAdditionalInsuredsForPolicy,
} from '../models/additional-insureds';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import {
  getAllTypeIds,
  typeIdsMap,
  getBuildingTypeId,
  getClassificationTypeId,
  getLocationTypeId,
} from '../models/type-ids';
import { businessType, hasContractorBusinessType } from '../models/business-type';
import {
  RequestQuoteBody,
  BuildingOverview,
  PolicyLocation,
  Classification,
  Clause,
  RetrieveQuoteResponse,
  BlanketsList,
} from 'app/bop/guidewire/typings';
import { extractUnknownClauses } from 'app/bop/guidewire/retrieve-quote-translator';
import { Injectable } from '@angular/core';

@Injectable()
export class AttuneBopQuotePayloadService {
  static getTime(): number {
    return moment().startOf('day').add(12, 'hours').valueOf();
  }

  translatePolicy(
    insuredAccount: InsuredAccount,
    policy: BopPolicy,
    user?: CurrentUser,
    retrievedQuote?: RetrieveQuoteResponse
  ): RequestQuoteBody {
    const contact: any =
      BackendCreateInsuredAccountRequestPayloadFactory.build(insuredAccount).accountModel.Account
        .AccountHolderContact;
    contact.AddressBookUID = null;
    contact['entity-Person'] = null;

    const bop7locations = this.bop7Locations(policy);

    let allClauses = coverageTerms(policy, insuredAccount);
    let blankets: BlanketsList = { Entry: [] };

    if (retrievedQuote && retrievedQuote.return.QuoteSubmissionRequest.Periods.Entry[0].BP7Line) {
      const retrievedBopLine =
        retrievedQuote.return.QuoteSubmissionRequest.Periods.Entry[0].BP7Line;
      const unknownClauses = extractUnknownClauses(retrievedQuote);
      const idMap: { [key: string]: string } = typeIdsMap(retrievedBopLine);

      // Newly generated typeIDs
      const allTypeIds = getAllTypeIds(policy);

      // NOTE: This blocks removes unknown coverages that refer to buildings or locations
      //       that have since been deleted from the policy.
      const modifiedClauses: Clause[] = _.reduce(
        unknownClauses,
        (acc, clause) => {
          const oldTypeId: string = clause.OwningCoverable.TypeIDString;
          const newTypeId: string = idMap[oldTypeId];
          if (_.includes(allTypeIds, newTypeId)) {
            acc.push({ ...clause, OwningCoverable: { TypeIDString: newTypeId } });
          }
          return acc;
        },
        [] as Clause[]
      );
      allClauses = allClauses.concat(modifiedClauses);

      blankets = <BlanketsList>retrievedBopLine.Blankets;
    }

    const yearBusinessStarted = moment().year() - Number(policy.policyInfo.yearsInBusiness);
    const uwCompany = policy.policyInfo.bopVersion === 2 ? '26379' : 'HI';

    return {
      bopVersion: policy.policyInfo.bopVersion,
      quoteRequestModel: {
        QuoteSubmissionRequest: {
          Account: {
            AccountHolderContact: contact,
            AccountNumber: insuredAccount.id,
            AccountOrgType: policy.policyInfo.organizationType || '',
            IndustryCode: null,
            MathedBusinessName_HUSA: null,
            OFACStatus_HUSA: 'NO',
            ProducerCodes: insuredAccount.producerCodesStruct,
          },
          Periods: {
            Entry: [
              {
                AddlNamedInsureds_HUSA: null,
                BP7Line: {
                  AdditionalInsureds: {
                    Entry: policy.additionalCoverages.hasAdditionalInsuredBusinesses
                      ? getAdditionalInsuredsForPolicy(
                          policy.additionalCoverages.additionalInsuredBusinesses
                        )
                      : [],
                  },
                  AllClauses: {
                    Entry: allClauses,
                  },
                  BP7ContractorsCovEligible_HUSA: hasContractorBusinessType(policy),
                  BP7LineBusinessType: businessType(policy),
                  BP7Locations: {
                    Entry: bop7locations,
                  },
                  Blankets: blankets,
                  TypeIDString: 'entity.BP7BusinessOwnersLine',
                },
                BP7LineExists: true,
                BaseState: policy.policyInfo.baseState,
                CP7Line: null,
                CP7LineExists: false,
                CommercialUmbrellaLine_CUE: null,
                CommercialUmbrellaLine_CUEExists: false,
                GeneralLiabilityLine_GLE: null,
                GeneralLiabilityLine_GLEExists: false,
                Job: {
                  JobNumber: null,
                  QuoteName_HUSA: policy.quoteName || 'Quote details',
                },
                MonoLinePolicyLine_HUSA: {
                  HamiltonCodes_HUSA: null,
                  IndustryGroup_HUSA: null,
                  Subtype: 'BP7BusinessOwnersLine',
                },
                Offering: null,
                PeriodAnswers: {
                  Entry: [
                    {
                      QuestionCode: 'BP7_Business_Started_Year',
                      AnswerValueAsString: String(yearBusinessStarted),
                    },
                  ],
                },
                // NB for PeriodEnd and PeriodStart:  Someday Sandeep would like to have these as date strings without time to avoid TZ confusion.
                // For now, note that any ISOstring < UTC 5AM will be converted in guidewire to previous UTC day 5:01AM.
                // e.g. '2019-01-03T05:00:00.000Z' will be stored as as an epoch time for '2019-01-03T05:01:00.000Z'
                // and  '2019-01-03T04:00:00.000Z' will be stored as as an epoch time for '2019-01-02T05:01:00.000Z'
                // frontend representations should use UTC date and not browser timezone.
                PeriodEnd: policy.periodEnd().toISOString(),
                PeriodStart: policy.periodStart().toISOString(),
                PolicyContactRoles: {
                  Entry: [], // TODO: Collect this?
                },
                PolicyLocations: {
                  Entry: this.locations(policy),
                },
                PrimaryNamedInsured: {
                  ContactDenorm: contact,
                  Subtype: 'PolicyPriNamedInsured',
                },
                UWCompany: {
                  Code: uwCompany,
                },
                WrittenDate: AttuneBopQuotePayloadService.getTime(),
              },
            ],
          },
          TSRequestID_HUSA: policy.tsRequestId,
        },
      },
      userName: user && user.username,
    };
  }

  locations(policy: BopPolicy): PolicyLocation[] {
    return policy.locations.map((location, index) => {
      return {
        AccountLocation: {
          AddressLine1: location.locationDetails.addressLine1,
          AddressLine2: location.locationDetails.addressLine2 || null,
          AddressLine3: null,
          AddressType: 'business',
          City: location.locationDetails.city,
          Country: null,
          County: null,
          PostalCode: location.locationDetails.zip,
          State: location.locationDetails.state,
          Subtype: 'AccountLocation',
        },
        // Distance to Ocean Code. Caution: This is NOT a distance
        // 0 => "0~500 feet", 16 => "30 miles or more", etc etc
        DistanceToOcean_HUSA:
          _.get(location.locationPrefill, 'distToEffectiveCoastRangeCode') || '',
        EmployeeCount: parseMaskedInt(location.locationDetails.employeeCount),
        PartTimeEmployeeCount: parseMaskedInt(location.locationDetails.partTimeEmployeeCount),
        LocationNum: index + 1,
        PrimaryLoc: index === 0,
      };
    });
  }

  bop7Locations(policy: BopPolicy) {
    return policy.locations.map((location, index) => {
      return {
        AdditionalInsureds_HUSA: {
          Entry: policy.additionalCoverages.hasAdditionalInsuredBusinesses
            ? getAdditionalInsuredsForLocation(
                policy.additionalCoverages.additionalInsuredBusinesses,
                location,
                index
              )
            : [],
        },
        Buildings: {
          Entry: this.bop7Buildings(policy, location, index),
        },
        Location: {
          EarthquakeMMI100Year_HUSA: _.get(location.locationPrefill, 'earthquakeMMI100Year', null),
          EarthquakeSoilType_HUSA: {
            Name: _.get(location.locationPrefill, 'earthquakeSoilType', null),
          },
          EarthquakeTerritoryCode_HUSA: _.get(
            location.locationPrefill,
            'commEarthquakeTerritoryCode',
            null
          ),
          LocationNum: index + 1,
        },
        TypeIDString: getLocationTypeId(index),
      };
    });
  }

  bop7Buildings(
    policy: BopPolicy,
    location: BopLocation,
    locationIndex: number
  ): BuildingOverview[] {
    return location.buildings.map((building, buildingIndex) => {
      return {
        AdditionalInterests_HUSA: {
          Entry: policy.additionalCoverages.hasAdditionalInsuredBusinesses
            ? getAdditionalInterestsForBuilding(
                policy.additionalCoverages.additionalInsuredBusinesses,
                building,
                locationIndex,
                buildingIndex
              )
            : [],
        },
        Building: {
          BuildingAnswers_HUSA: {
            Entry: [
              {
                AnswerValueAsString: String(building.exposure.yearBuilt),
                QuestionCode: 'BP7_Building_Built_Year',
              },
              {
                AnswerValueAsString: String(!!building.coverage.windLossMitigationPresent),
                QuestionCode: 'BP7_South_Carolina_Windstorm_Loss',
              },
              {
                AnswerValueAsString: String(building.exposure.electricPlumbingHVACUpdated),
                QuestionCode: 'BP7_Has_Roof_HVAC_Updated',
              },
              {
                AnswerValueAsString: String(building.exposure.roofUpdated),
                QuestionCode: 'BP7_Has_Roof_Updated_In20yrs',
              },
            ],
          },
          BuildingNum: buildingIndex + 1,
          PolicyLocation: {
            LocationNum: locationIndex + 1,
          },
          RoofYear_HUSA: null, // TODO: collect this.
          YearBuilt: Number(building.exposure.yearBuilt),
        },
        Classifications: {
          // TODO: wrapping the classification in an array so it can be mapped in scope is a temporary workaround.
          //       This should be properly addressed in the translation layer when it's built.
          Entry: [building.exposure.classification]
            .filter(
              (classificationCode) =>
                typeof classificationCode.code === 'object' && classificationCode.code
            )
            .map((classificationCode) => {
              return this.classificationCodeToClassification(
                building,
                locationIndex,
                buildingIndex,
                <BuildingClassification>classificationCode.code
              );
            }),
        },
        ConstructionType: building.exposure.constructionType,
        NumOfStories: building.exposure.storyCount,
        PctOwnerOccupied:
          parseMaskedInt(building.exposure.buildingLimit) > 0
            ? building.exposure.lessorsRisk
              ? '10orless'
              : 'Over10'
            : 'NotApplicable',
        PropertyType: building.exposure.businessType,
        Sprinklered: building.exposure.hasAutomaticSprinklerSystem,
        TypeIDString: getBuildingTypeId(locationIndex, buildingIndex),
      };
    });
  }

  private classificationCodeToClassification(
    building: BopBuilding,
    locationIndex: number,
    buildingIndex: number,
    classification: BuildingClassification
  ): Classification {
    const { exposure, exposureBasis } = this.exposureAndExposureBasis(building);
    return {
      AmusementArea: false, // TODO: ?
      Area: parseMaskedInt(building.exposure.squareFootage),
      ClassCode: classification.code,
      ClassDescription: classification.descriptionCode,
      ClassPropertyType: building.exposure.businessType,
      ClassificationNumber: 1,
      Exposure: exposure,
      ExposureBasis: exposureBasis,
      NumClubHouses_HUSA: '0', // TODO: Collect this
      NumExcerciseRooms_HUSA: '0', // TODO: Collect this
      NumOfAmuseAreas_HUSA: '0', // TODO: Collect this
      NumOfPlayGrounds_HUSA: '0', // TODO: Collect this
      NumOfSpas_HUSA: '0', // TODO: Collect this
      NumSwimmingPools: '0', // TODO: Collect this
      Playground: 'None', // TODO: Collect this
      TypeIDString: getClassificationTypeId(locationIndex, buildingIndex),
    };
  }

  private exposureAndExposureBasis(building: BopBuilding) {
    if (building.exposure.lessorsRisk) {
      return {
        exposure: null,
        exposureBasis: 'LimitofInsurance',
      };
    }
    switch (building.exposure.businessType) {
      case 'Contractor':
        return {
          exposure: parseMaskedInt(building.exposure.payroll),
          exposureBasis: 'AnnualPayroll',
        };
      case 'RestaurantCasualDining':
      case 'RestaurantFineDining':
      case 'RestaurantFastFood':
      case 'RestaurantLimitedCooking':
        return {
          exposure: parseMaskedInt(building.exposure.totalSales),
          exposureBasis: 'AnnualGrossSales',
        };
      default:
        return {
          exposure: null,
          exposureBasis: 'LimitofInsurance',
        };
    }
  }
}
