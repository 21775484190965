import * as moment from 'moment';
import { US_DATE_MASK } from 'app/constants';
import { API_DATE_FORMAT } from '../constants';

export interface WcBindFormValue {
  emailAddress: string;
  phoneNumber: string;
  effectiveDate: string;
  numberOfPayments: string;
  agreedToAdditionalRequirementTerms?: boolean;
}

export interface WcBindPayload {
  insuredEmail: string;
  insuredPhone: string;
  effectiveDate: string;
  numberOfPayments: number;
  agreedToAdditionalRequirementTerms?: boolean;
}

export class WcBindPolicy implements WcBindPayload {
  effectiveDate: string;
  insuredEmail: string;
  insuredPhone: string;
  numberOfPayments: number;
  agreedToAdditionalRequirementTerms?: boolean;

  constructor(wcForm: WcBindFormValue) {
    this.effectiveDate = moment(wcForm.effectiveDate, US_DATE_MASK).format(API_DATE_FORMAT);
    this.insuredEmail = wcForm.emailAddress;
    this.insuredPhone = wcForm.phoneNumber.trim().replace(/[^0-9+]/g, '');
    this.numberOfPayments = parseInt(wcForm.numberOfPayments, 10);

    if (wcForm.agreedToAdditionalRequirementTerms) {
      this.agreedToAdditionalRequirementTerms = wcForm.agreedToAdditionalRequirementTerms;
    }
  }
}
