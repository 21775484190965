<div role="alert" class="toast toast__success">
  <div class="inform-message">
    <p class="inform-submessage">
      <small>{{ toast.message }}</small>
    </p>
    <ul class="bulleted-list">
      <li>Schedule of invoices</li>
      <li>First invoice</li>
    </ul>
    <ng-container *ngIf="toast.hasLoader">
      <app-loading-bar startPercent="1" secondsToMax="100" maxPercent="100"></app-loading-bar>
    </ng-container>
  </div>
  <button
    *ngIf="toast.button"
    class="button button__small button__secondary button__no-margin inform-button"
    (click)="close()"
  >
    {{ toast.button }}
  </button>
</div>
