import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
// Models
import { APIDataStatus } from 'app/shared/models/api-data';

@Component({
  selector: 'app-form-field-select-api',
  styleUrls: ['./form-field-select-api.component.scss'],
  templateUrl: './form-field-select-api.component.html',
})
export class FormFieldSelectApiComponent {
  @Input() inputId: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() labelText: string;
  @Input() cssClass: string;
  @Input() nameOfFormControl: string;
  @Input() options: Array<number>;
  @Input() currency = true;
  @Input() tooltipText: string;

  private _status: APIDataStatus;

  APIDataStatus: typeof APIDataStatus = APIDataStatus;

  get status(): APIDataStatus {
    return this._status;
  }

  get formControl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get(this.nameOfFormControl);
  }

  @Input()
  set status(s: APIDataStatus) {
    this._status = s;
  }
}
