<ng-container *ngIf="form.get('wholesalerAndMercantileOperations')?.enabled">
  <p>
    Does the applicant engage in any of the following operations?
    <span class="p p__field-description-text">Check all that apply</span>
  </p>
  <div
    class="sub-question form-field"
    [ngClass]="{
      'form-field__error':
        fieldInvalid('wholesalerAndMercantileOperations') &&
        !fieldInvalid('wholesalerAndMercantileOperations.otherOperationsText')
    }"
  >
    <app-form-field-checkbox
      inputId="quote-distributingProducts"
      labelText="Distributing products manufactured by others to retail businesses"
      nameOfFormControl="distributingProducts"
      [form]="form.get('wholesalerAndMercantileOperations')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-importingGoods"
      labelText="Importing goods"
      nameOfFormControl="importingGoods"
      [form]="form.get('wholesalerAndMercantileOperations')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-manufacturing"
      labelText="Manufacturing"
      nameOfFormControl="manufacturing1"
      [form]="form.get('wholesalerAndMercantileOperations')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-repackaging"
      labelText="Repackaging and/or relabeling of products"
      nameOfFormControl="repackaging"
      [form]="form.get('wholesalerAndMercantileOperations')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-sellingProductsManufacturedByOthers"
      labelText="Selling products manufactured by others directly to consumers"
      nameOfFormControl="sellingProductsManufacturedByOthers"
      [form]="form.get('wholesalerAndMercantileOperations')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-sellingWithInsuredsBranding"
      labelText="Selling products with the insured's branding or under their own name"
      nameOfFormControl="sellingWithInsuredsBranding"
      [form]="form.get('wholesalerAndMercantileOperations')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-otherOperationsNotMentioned"
      labelText="Other operations not mentioned above"
      nameOfFormControl="otherOperationsNotMentioned"
      [form]="form.get('wholesalerAndMercantileOperations')"
    ></app-form-field-checkbox>
    <app-form-field-text
      cssClass="sub-question"
      inputId="quote-otherOperationsText"
      placeholder="Other operation"
      nameOfFormControl="otherOperationsText"
      [form]="form.get('wholesalerAndMercantileOperations')"
      [submitted]="submitted"
    ></app-form-field-text>
  </div>
</ng-container>

<ng-container *ngIf="form.get('liveEntertainmentActivities')?.enabled">
  <p>
    Select the live entertainment occurring at the business.
    <span class="p p__field-description-text">Check all that apply</span>
  </p>
  <div
    class="sub-question form-field"
    [ngClass]="{
      'form-field__error':
        fieldInvalid('liveEntertainmentActivities') &&
        !fieldInvalid('liveEntertainmentActivities.otherActivitiesText')
    }"
  >
    <app-form-field-checkbox
      inputId="quote-barGames"
      labelText="Bar Games"
      nameOfFormControl="barGames"
      [form]="form.get('liveEntertainmentActivities')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-dancing"
      labelText="Dancing"
      nameOfFormControl="dancing"
      [form]="form.get('liveEntertainmentActivities')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-djs"
      labelText="DJ's"
      nameOfFormControl="DJs"
      [form]="form.get('liveEntertainmentActivities')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-incidentalMusic"
      labelText="Incidental music (such as one person playing piano or acoustic guitar)"
      nameOfFormControl="incidentalMusic"
      [form]="form.get('liveEntertainmentActivities')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-karaoke"
      labelText="Karaoke"
      nameOfFormControl="karaoke"
      [form]="form.get('liveEntertainmentActivities')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-otherActivitiesNotMentioned"
      labelText="Other activities not mentioned above"
      nameOfFormControl="otherActivitiesNotMentioned"
      [form]="form.get('liveEntertainmentActivities')"
    ></app-form-field-checkbox>
    <app-form-field-text
      cssClass="sub-question"
      inputId="quote-otherActivitiesText"
      placeholder="Other activity"
      nameOfFormControl="otherActivitiesText"
      [form]="form.get('liveEntertainmentActivities')"
      [submitted]="submitted"
    ></app-form-field-text>
    <app-form-field-checkbox
      inputId="quote-noneOfTheAbove"
      labelText="None of the above"
      nameOfFormControl="noneOfTheAbove"
      [form]="form.get('liveEntertainmentActivities')"
    ></app-form-field-checkbox>
  </div>
</ng-container>

<ng-container *ngIf="form.get('nailSalonBeautyParlorBarberShopServices')?.enabled">
  <p>
    Does the business provide any of the following services?
    <span class="p p__field-description-text">Check all that apply</span>
  </p>
  <div
    class="sub-question form-field"
    [ngClass]="{
      'form-field__error':
        fieldInvalid('nailSalonBeautyParlorBarberShopServices') &&
        !fieldInvalid('nailSalonBeautyParlorBarberShopServices.otherServicesText')
    }"
  >
    <app-form-field-checkbox
      inputId="quote-beardShave"
      labelText="Beard shave, trim, or shape-up"
      nameOfFormControl="beardShave"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-botox"
      labelText="Botox and filler injections"
      nameOfFormControl="botox"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-chemicalPeels"
      labelText="Chemical peels"
      nameOfFormControl="chemicalPeels"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-coolsculpting"
      labelText="Coolsculpting"
      nameOfFormControl="coolsculpting"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-dermabrasion"
      labelText="Dermabrasion"
      nameOfFormControl="dermabrasion"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-eyelashExtensions"
      labelText="Eyelash extensions"
      nameOfFormControl="eyelashExtensions"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-hairTransplant"
      labelText="Hair transplant"
      nameOfFormControl="hairTransplant"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-hairStyling"
      labelText="Haircutting/Hairstyling/Hair dyeing"
      nameOfFormControl="hairStyling"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-laserHairRemoval"
      labelText="Laser hair removal"
      nameOfFormControl="laserHairRemoval"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-laserSkinResurfacing"
      labelText="Laser skin resurfacing"
      nameOfFormControl="laserSkinResurfacing"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-makeupArtistry"
      labelText="Makeup artistry"
      nameOfFormControl="makeupArtistry"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-massages"
      labelText="Massages"
      nameOfFormControl="massages"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-pedicuresManicures"
      labelText="Pedicures/Manicures"
      nameOfFormControl="pedicuresManicures"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-skinTanning"
      labelText="Skin tanning"
      nameOfFormControl="skinTanning"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-surgicalCosmeticRemedies"
      labelText="Surgical cosmetic remedies (e.g. facelifts, liposuction, rhinoplasty)"
      nameOfFormControl="surgicalCosmeticRemedies"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-tattooOrMicroblading"
      labelText="Tattoo application/removal or microblading"
      nameOfFormControl="tattooOrMicroblading"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices.medispaOrCosmeticServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-waxing"
      labelText="Waxing"
      nameOfFormControl="waxing"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
    ></app-form-field-checkbox>
    <app-form-field-checkbox
      inputId="quote-otherServicesNotMentioned"
      labelText="Other service not mentioned above"
      nameOfFormControl="otherServicesNotMentioned"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
    ></app-form-field-checkbox>
    <app-form-field-text
      cssClass="sub-question"
      inputId="quote-otherServicesText"
      placeholder="Other service"
      nameOfFormControl="otherServicesText"
      [form]="form.get('nailSalonBeautyParlorBarberShopServices')"
      [submitted]="submitted"
    ></app-form-field-text>
  </div>
</ng-container>

<ng-container *ngIf="form.get('totalSubcontractorCostsPercentage')?.enabled">
  <app-form-field-text
    inputId="quote-totalSubcontractorCostsPercentage"
    [form]="form"
    [submitted]="submitted"
    labelText="Please enter the applicant’s subcontracted costs as a percentage of their total gross sales"
    nameOfFormControl="totalSubcontractorCostsPercentage"
    appNumberMask
    maxLength="3"
  >
  </app-form-field-text>
</ng-container>
