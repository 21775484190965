import { Component, OnInit } from '@angular/core';

// Constants
import {
  COVERAGE_RESTRICTIONS_FOR_GUIDELINES,
  INELIGIBLE_INDUSTRIES_FOR_GUIDELINES,
} from 'app/workers-comp/attune/constants';

// Models
import { BasicsInfoFormGroup } from 'app/workers-comp/attune/models/quote-form.model';

// Components
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';

// Services
import { AttuneWcQuoteFormService } from 'app/workers-comp/attune/services/attune-wc-quote-form.service';

@Component({
  selector: 'app-wc-guidelines-page',
  templateUrl: './attune-wc-guidelines-page.component.html',
})
export class AttuneWcGuidelinesPageComponent
  extends SteppedFormPageComponent<AttuneWcQuoteFormService, BasicsInfoFormGroup>
  implements OnInit
{
  formPath = 'guidelines';
  coverageRestrictions = COVERAGE_RESTRICTIONS_FOR_GUIDELINES;
  ineligibleIndustries = INELIGIBLE_INDUSTRIES_FOR_GUIDELINES;

  constructor(protected formService: AttuneWcQuoteFormService) {
    super(formService);
  }
}
