<ng-container [ngSwitch]="status">
  <ng-container *ngSwitchCase="'loading'">
    <svg
      alt="uploading..."
      class="inline-loading-spinner js-status-loading"
      *ngIf="!isIE"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="6.5" stroke="#DBDBDB" />
      <circle cx="7.5" cy="7.5" r="7" stroke="#667D87" stroke-dasharray="22 22" />
    </svg>
    <img
      alt="uploading..."
      style="width: 15px; height: 15px"
      src="assets/img/loading-spinner-white.gif"
      *ngIf="isIE"
    />
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <svg
      alt="upload failed"
      *ngIf="!isIE"
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="js-status-error"
    >
      <path
        d="M1.7478 12.5C1.24783 13.5 0.655303 13.635 1.24773 14.5L1.61333 14.7719C1.86013 15.1316 2.35097 15.015 2.55086 14.7713C6.09892 10.4423 9.27213 6.76611 13.2478 3C12.669 3.17321 11.8859 3.50463 11.3357 3.76333C8.74468 5.49818 6.19386 7.33789 2.74284 11.3005L1.7478 12.5Z"
        fill="#DD728E"
      />
      <path
        d="M1.7478 5.5C1.24783 4.5 0.655303 4.36496 1.24773 3.5L1.61333 3.2281C1.86013 2.86837 2.35097 2.98496 2.55086 3.22868C6.09892 7.55774 9.27213 11.2339 13.2478 15C12.669 14.8268 11.8859 14.4954 11.3357 14.2367C8.74468 12.5018 6.19386 10.6621 2.74284 6.69946L1.7478 5.5Z"
        fill="#DD728E"
      />
    </svg>
    <img alt="upload failed" src="assets/img/x.svg" *ngIf="isIE" />
  </ng-container>

  <ng-container *ngSwitchCase="'done'">
    <svg
      alt="upload successful"
      *ngIf="!isIE"
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="js-status-done"
    >
      <path
        d="M0.0987198 9.07615C-0.493712 8.07812 1.7517 7.00847 2.2835 8.09606L3.49502 10.575C3.76128 11.1201 4.03408 11.1887 4.49502 10.5776C7.94604 6.00526 10.4969 3.88251 13.0879 1.88076C13.6381 1.58226 14.4212 1.19986 15 1C11.0243 5.34551 7.8511 9.58722 4.30305 14.5823C4.10315 14.8635 3.61231 14.998 3.36551 14.583L0.0981393 9.07609L0.0987198 9.07615Z"
        fill="#00D2D0"
      />
    </svg>
    <img alt="upload successful" src="assets/img/check.svg" *ngIf="isIE" />
  </ng-container>

  <ng-container *ngSwitchCase="'incomplete'">
    <svg
      alt="incomplete check mark"
      *ngIf="!isIE"
      width="18"
      height="18"
      fill="#E6E9EB"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      class="js-status-incomplete"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 9A9 9 0 110 9a9 9 0 0118 0zM5.982 8.7c-.395-.808-2.063-.013-1.623.728l2.427 4.09c.183.31.548.21.697 0 2.635-3.71 4.992-6.861 7.946-10.09-.43.149-1.012.433-1.42.655-1.925 1.487-3.82 3.064-6.384 6.46-.342.454-.545.403-.743-.002l-.9-1.841z"
      ></path>
    </svg>
    <img alt="incomplete check mark" src="assets/img/circle-check-gray.svg" *ngIf="isIE" />
  </ng-container>

  <ng-container *ngSwitchCase="'complete'">
    <svg
      alt="complete check mark"
      *ngIf="!isIE"
      width="18"
      height="18"
      fill="#6D57FF"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      class="js-status-complete"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 9A9 9 0 110 9a9 9 0 0118 0zM5.982 8.7c-.395-.808-2.063-.013-1.623.728l2.427 4.09c.183.31.548.21.697 0 2.635-3.71 4.992-6.861 7.946-10.09-.43.149-1.012.433-1.42.655-1.925 1.487-3.82 3.064-6.384 6.46-.342.454-.545.403-.743-.002l-.9-1.841z"
      ></path>
    </svg>
    <img alt="complete check mark" src="assets/img/circle-check-purple.svg" *ngIf="isIE" />
  </ng-container>
</ng-container>
