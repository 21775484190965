import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';

// TODO: Create a Hab Carrier Service?
import { Carrier } from 'app/features/attune-bop/models/constants-carriers';
import { CarrierService } from 'app/bop/services/carrier.service';

import { HabFormLocationSummary, HabFormLossHistoryCarrier } from 'app/hab/typings';
import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';
import { getControl } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-hab-loss-history-page',
  templateUrl: './hab-loss-history-page.component.html',
})
export class HabLossHistoryPageComponent {
  form: UntypedFormGroup;

  public habLossTypeOptions: { [key: string]: string } = {
    '': 'Choose one',
    WATERLEAK: 'Water leak',
    FIRE: 'Fire',
    WINDHAILDAMAGE: 'Wind/hail damage',
    ASSAULTANDBATTERY: 'Assault and battery',
    PERSONALINJURY: 'Personal injury',
    DOGBITEATTACK: 'Dog bite/attack',
    SLIPANDFALL: 'Slip and fall',
    HABITABILITY: 'Habitability',
    OTHERLIABILITY: 'Other Liability',
    OTHERPROPERTY: 'Other Property',
  };

  public habLossClaimStatusOptions: { [key: string]: string } = {
    Open: 'Open',
    Closed: 'Closed',
  };

  constructor(
    protected carrierService: CarrierService,
    protected formService: HabQuoteFormService
  ) {
    this.form = <UntypedFormGroup>this.formService.form;
  }

  hasSubmitted() {
    return this.formService.submitted;
  }

  /**
   * Managing Loss History methods
   */
  public lossLocationNumOptions(): { [key: string]: string } {
    const locations: HabFormLocationSummary[] = (
      this.form.get('policyDetails.locationSummaries') as UntypedFormArray
    ).value;

    const options: { [key: string]: string } = {};
    locations.forEach((summary: HabFormLocationSummary, index: number) => {
      const address = summary.address;
      const oneIndexedLocationNum: string = String(index + 1);
      options[
        oneIndexedLocationNum
      ] = `${address.addressLine1}, ${address.city}, ${address.state} ${address.zip}`;
    });

    return options;
  }

  public losses(): UntypedFormArray {
    return this.formService.lossHistoryFormArray();
  }

  public moreThanOneLoss(): boolean {
    return this.losses().length > 1;
  }

  public addLoss(): void {
    return this.formService.addLoss();
  }

  public removeLoss(index: number): void {
    return this.formService.removeLoss(index);
  }

  public hasPriorRisk(): boolean {
    return getControl(this.formService.form, 'lossHistory.hasRiskHadPriorLosses').value;
  }

  /**
   * Loss Carrier Search Dropdown Methods
   */
  public codeDescription(carrier: HabFormLossHistoryCarrier | null): string {
    return carrier !== null ? carrier.name : '';
  }

  queryMethod = (query: string): Observable<HabFormLossHistoryCarrier[]> => {
    const carriers = this.carrierService.lookup(query).map(
      (carrier: Carrier): HabFormLossHistoryCarrier => ({
        name: carrier.name,
        publicId: String(carrier.publicId),
      })
    );

    carriers.push({
      name: 'Other',
    });
    return observableOf(carriers);
  };
}
