import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import {
  minDateExceededValidator,
  phoneValidator,
  feinValidator,
  getControl,
} from 'app/shared/helpers/form-helpers';
import { US_DATE_MASK } from 'app/constants';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { validateEmailAddress } from 'app/features/attune-bop/models/form-validators';
import {
  getServicingInfoDoNotShowAgainPreference,
  updateServicingDoNotShowAgainPreference,
} from '../../../shared/carrier-partner-servicing-info/helpers';
import { BindFormGroup } from 'app/workers-comp/employers/models/bind-form-model';

@Injectable()
export class WcBindFormService extends FormDslSteppedFormBaseService {
  form: BindFormGroup;
  submitted = false;

  DEFAULT_NUMBER_OF_PAYMENTS = '1';
  private _showAdditionalRequirements = false;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.initializeForm();
    this.syncAllSteps();
  }

  fillInHappyPath() {}

  initializeForm() {
    const today = moment().utc();

    this.form = this.formBuilder.group({
      workingWithEmployers: this.formBuilder.group({
        doNotShowAgain: this.formBuilder.control<boolean | null>(null),
      }),
      additionalRequirements: this.formBuilder.group({
        agreedToAdditionalRequirementTerms: this.formBuilder.control<boolean | null>(null, {
          validators: [Validators.required, Validators.requiredTrue],
        }),
      }),
      bindQuote: this.formBuilder.group({
        effectiveDate: [
          today.format(US_DATE_MASK),
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            minDateExceededValidator(today, 'day'),
          ]),
        ],
        employerIdentificationNumber: ['', [Validators.required, feinValidator]],
        emailAddress: ['', [Validators.required, validateEmailAddress]],
        mailingBillingAddressesIdentical: [true, Validators.required],
        numberOfPayments: [this.DEFAULT_NUMBER_OF_PAYMENTS, Validators.required],
        phoneNumber: ['', [Validators.required, phoneValidator]],
      }),
    });
    // Disable additional requirements by default.
    this.form.get('additionalRequirements')?.disable();
  }

  getValue() {
    return this.form.value;
  }

  showAdditionalRequirementsStep() {
    this._showAdditionalRequirements = true;
    this.syncAllSteps();
    // Go to the beginning
    this.stepWithoutValidation(this.steps[0]);
  }

  generateSteps(): RouteFormStep[] {
    const isStep = (step: RouteFormStep | null): step is RouteFormStep => {
      return step !== null;
    };

    return [
      this.workingWithEmployersStep(),
      this.additionalRequirementsStep(),
      {
        args: {},
        displayName: `Bind quote`,
        slug: 'bind-quote',
        parent: 'bind-quote',
        formPath: 'bindQuote',
      },
    ].filter(isStep);
  }

  private additionalRequirementsStep(): RouteFormStep | null {
    if (this._showAdditionalRequirements) {
      this.form.get('additionalRequirements')?.enable();
      return {
        args: {},
        displayName: `Additional requirements`,
        slug: 'additional-requirements',
        parent: 'additional-requirements',
        formPath: 'additionalRequirements',
      };
    }
    return null;
  }

  private workingWithEmployersStep(): RouteFormStep | null {
    if (getServicingInfoDoNotShowAgainPreference('employers')) {
      return null;
    }
    return {
      args: {},
      displayName: 'Working with EMPLOYERS',
      slug: 'working-with-employers',
      parent: 'working-with-employers',
      formPath: 'workingWithEmployers',
    };
  }

  handleDoNotShowAgainSelection() {
    const doNotShowAgainControl = getControl(this.form, 'workingWithEmployers.doNotShowAgain');
    if (doNotShowAgainControl.dirty) {
      updateServicingDoNotShowAgainPreference('employers', doNotShowAgainControl.value);
    }
  }
}
