import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SentryService } from 'app/core/services/sentry.service';

import { API_V4_BASE } from 'app/constants';
import {
  BindGoalsConfig,
  Milestone,
} from '../../features/activity/components/activity-incentive-cyber-binds/activity-incentive-cyber-binds.component';

const milestones: Milestone[] = [
  {
    icon: 'silver_tier',
    value: '+ $0',
    label: 'No binds',
    tooltip: 'Your agency will start earning after its second bind this quarter',
  },
  {
    icon: 'silver_tier',
    value: '+ $0',
    label: 'Bind 1',
    tooltip: 'Your agency will start earning after its second bind this quarter',
  },
  {
    icon: 'gold_tier',
    value: '+ $100',
    label: 'Bind 2',
    tooltip: 'Earn a $100 cash bonus for each cyber bind (up to $500)',
  },
  {
    icon: 'gold_tier',
    value: '+ $100',
    label: 'Bind 3',
    tooltip: 'Earn a $100 cash bonus for each cyber bind (up to $500)',
  },
  {
    icon: 'gold_tier',
    value: '+ $100',
    label: 'Bind 4',
    tooltip: 'Earn a $100 cash bonus for each cyber bind (up to $500)',
  },
  {
    icon: 'gold_tier',
    value: '+ $100',
    label: 'Bind 5',
    tooltip: 'Earn a $100 cash bonus for each cyber bind (up to $500)',
  },
  {
    icon: 'gold_tier',
    value: '+ $100',
    label: 'Bind 6',
    tooltip: 'Earn a $100 cash bonus for each cyber bind (up to $500)',
  },
];

interface CyberBindsResponse {
  binds: number;
}

const bonusCalculator = (binds: number) => Math.min(Math.max(binds - 1, 0), 5) * 100;

const bonusRemainingCalculator = (binds: number) => 500 - bonusCalculator(binds);

const percentageCalculator = (binds: number) => Math.min((binds / 6) * 100, 100);

const LEARN_MORE_LINK = 'https://blog.attuneinsurance.com/attune-cyber-advantage-incentive/';

@Injectable()
export class CyberBindsRewardsService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getCyberBindsRewardsConfig(producerCode: string): Observable<BindGoalsConfig | null> {
    return this.http.get<CyberBindsResponse>(`${API_V4_BASE}/policies/coalition/count`).pipe(
      map((data) => {
        const config: BindGoalsConfig = {
          text:
            data.binds >= 6
              ? `Congratulations! Your agency has earned the maximum commission bonus this quarter. <a target="_blank" href="${LEARN_MORE_LINK}">Learn more.</a>`
              : `Earn cash bonuses for your agency by <span class="p p__large-text p__bold">binding more cyber policies this quarter. </span>Your agency still has $${bonusRemainingCalculator(
                  data.binds
                )} to earn! <a target="_blank" href="${LEARN_MORE_LINK}">Learn more.</a>`,
          percentage: percentageCalculator(data.binds),
          milestones: milestones,
        };
        return config;
      }),
      catchError((error: any) => {
        this.sentryService.notify('Cyber Binds Rewards: Error getting cyber bind goals data', {
          severity: 'error',
          metaData: {
            producerCode,
            underlyingError: error,
            underlyingErrorMessage: error && error.message,
          },
        });
        return observableOf(null);
      })
    );
  }
}
