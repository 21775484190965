import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sample-quote-modal',
  templateUrl: './sample-quote-modal.component.html',
})
export class SampleQuoteModalComponent {
  @Output() closeModal = new EventEmitter<{ goToSampleQuote: boolean }>();
  open = true;

  constructor() {}

  goToSampleQuote(goToSampleQuote: boolean) {
    this.closeModal.emit({ goToSampleQuote });
  }
}
