<!-- TODO: refactor
<app-radio-question
  [foo]="initConfig.foo">
  -->
<ng-container *ngIf="getControl(form, initConfig.nameOfFormControl).enabled">
  <div
    class="{{ initConfig.cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(initConfig.nameOfFormControl) }"
  >
    <label [for]="initConfig.inputId">
      {{ initConfig.labelText }}
      <div
        *ngIf="initConfig.tooltipText"
        class="more-info-tip tool-tip tool-tip__large"
        attr.data-tooltip="{{ initConfig.tooltipText }}"
      >
        <span class="icon icon-question"></span>
      </div>
      <ng-container *ngIf="initConfig.additionalQuestionText">
        <br />
        {{ initConfig.additionalQuestionText }}
      </ng-container>
      <small
        class="gray-text"
        *ngIf="initConfig.subLabelText"
        [innerHTML]="initConfig.subLabelText"
      ></small>
    </label>
    <ul *ngIf="initConfig.ul" class="bulleted-list">
      <li *ngFor="let listItem of initConfig.ul.li">
        {{ listItem }}
      </li>
    </ul>
    <div
      class="radio-question {{ initConfig.questionCssClass }}"
      [id]="initConfig.inputId"
      role="radiogroup"
    >
      <div
        *ngFor="let optionKey of getOptions() | keys"
        [ngClass]="{ 'radio-question-option__disabled': readonly }"
        class="radio-question-option {{ initConfig.optionsCssClass }}"
      >
        <input
          type="radio"
          [formControl]="form.get(initConfig.nameOfFormControl)"
          [id]="getSanitizedOptionId(optionKey)"
          [name]="initConfig.inputId"
          [value]="getOptions()[optionKey]"
          [readOnly]="readonly"
          [attr.aria-checked]="isSelected(optionKey)"
          [attr.disabled]="shouldDisableOption(optionKey) ? true : null"
        />
        <label
          [for]="getSanitizedOptionId(optionKey)"
          [ngClass]="{ 'radio-question-option__readonly': readonly }"
          class="radio-question-label {{
            shouldDisableOption(optionKey) ? initConfig.optionsLabelCssClass : null
          }}"
          (click)="clickOption(getOptions()[optionKey])"
          (keyup.enter)="clickOption(getOptions()[optionKey])"
        >
          {{ optionKey }}
        </label>
      </div>
    </div>
    <p class="form-field-note" *ngIf="initConfig.questionNote">{{ initConfig.questionNote }}</p>
  </div>
</ng-container>
