<app-modal
  [open]="open"
  (handleModalClose)="closeModal.emit()"
  modalNameForAmplitudeEvent="commission-plan-information-view"
  modalClass="app-modal-content__commission-plan-information"
>
  <div class="commission-plan-information-container">
    <img src="assets/img/commissions_plan.svg" alt="info-chart" />
    <h2>Commission Details</h2>
  </div>

  <table class="commission-plan-information-table">
    <thead>
      <th>Product</th>
      <th class="th__right">New Business Commission</th>
      <th class="th__right">Renewal Business Commission</th>
    </thead>
    <tbody>
      <tr *ngFor="let plan of availableCommissionPlans | keyvalue: asIsOrder">
        <td class="product-name td__left">
          {{ plan.value.label }}
          <ng-container *ngIf="plan.value.states">
            <small class="form-field-sub-label"> States: {{ plan.value.states }}</small>
          </ng-container>
        </td>
        <td class="td__right">{{ plan.value.newBusinessPercentage }}%</td>
        <td class="td__right">{{ plan.value.renewalBusinessPercentage }}%</td>
      </tr>
    </tbody>
  </table>
</app-modal>
