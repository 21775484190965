import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { getFormGroup } from 'app/shared/helpers/form-helpers';
import {
  bopControlPath,
  BOP_COMPLEX_DEPENDENCIES as LM_BOP_COMPLEX_DEPENDENCIES,
} from 'app/features/liberty-mutual/models/lm-bop-constants';
import {
  LmBopQuestion,
  LmBopLossHistoryQuestion,
  LmBopComplexEvaluator,
  LmBopFormStepPath,
} from 'app/features/liberty-mutual/models/lm-bop-typings';
import {
  LM_BOP_QUOTE_FLOW,
  getBopNode,
  getBopNodeConfig,
} from 'app/features/liberty-mutual/models/lm-bop-form-steps';
import {
  LM_BOP_DEPENDENCIES,
  LM_BOP_NESTED_CONTROL_DEPENDENCIES,
} from '../models/lm-bop-dependencies';
import { LibertyMutualBaseQuoteFormService } from './liberty-mutual-base-quote-form-service';
import { lmBopLossGroupNodes } from '../models/lm-bop-questions';
import { LM_BOP_HAPPY_PATH_FORM_DATA } from '../models/lm-bop-happy-path-form-data';

@Injectable({
  providedIn: 'root',
})
export class LibertyMutualBopQuoteFormService extends LibertyMutualBaseQuoteFormService<
  LmBopFormStepPath,
  LmBopQuestion,
  LmBopLossHistoryQuestion,
  LmBopComplexEvaluator
> {
  happyPathFormData = LM_BOP_HAPPY_PATH_FORM_DATA;

  // Form steps
  formSteps = LM_BOP_QUOTE_FLOW;

  // Dependencies dictionaries
  simpleDependencies = LM_BOP_DEPENDENCIES;
  complexDependencies = LM_BOP_COMPLEX_DEPENDENCIES;
  nestedControlDependencies = LM_BOP_NESTED_CONTROL_DEPENDENCIES;

  // Names of key controls
  CLASS_CODE_CONTROL_NAME = LmBopQuestion.BOP_CLASS_CODE;
  PRIMARY_RISK_STATE_CONTROL_NAME = LmBopQuestion.PRIMARY_RISK_STATE;
  LOSSES_CONTROL_NAME = LmBopQuestion.LOSSES;

  // Functions for retrieving from data structures
  getControlPathFunc = bopControlPath;
  getNodeFunc = getBopNode;
  getNodeConfigFunc = getBopNodeConfig;
  lossGroupNodesFunc = lmBopLossGroupNodes;

  formDependenciesInit(currentFormPath: LmBopFormStepPath) {
    const formGroup = getFormGroup(this.form, currentFormPath);
    const stepHasControls = formGroup && !isEmpty(formGroup.controls);
    const stepDependenciesAreSet = this.setDependencies.has(currentFormPath);

    if (stepHasControls && !stepDependenciesAreSet) {
      this.setDependenciesForFormGroup(formGroup, this.simpleDependencies);
      this.setDependencies.add(currentFormPath);
    }
  }
}
