// Libraries
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
// Constants
import {
  AVAILABLE_DEBRIS_REMOVAL_LIMITS,
  DEBRIS_REMOVAL_LIMIT_DEFAULT,
} from 'app/features/attune-bop/models/constants';
// Helpers

@Component({
  selector: 'app-debris-removal-coverage',
  templateUrl: './debris-removal-coverage.component.html',
})
export class DebrisRemovalCoverageComponent implements OnInit, OnDestroy {
  @Input() coverage: UntypedFormGroup;
  @Input() state: string;
  @Input() submitted: boolean;
  @Input() prefix: string;

  public availableDebrisRemovalLimits = AVAILABLE_DEBRIS_REMOVAL_LIMITS;
  public showDebrisRemovalCoverage = false;
  public showBaseLimitTooltip = true;

  private debrisRemovalSub: Subscription;

  constructor() {}

  ngOnInit() {
    this.showDebrisRemovalCoverage = this.state !== 'FL';

    if (this.showDebrisRemovalCoverage) {
      const debrisFormGroup: UntypedFormGroup = <UntypedFormGroup>(
        this.coverage.get('debrisRemoval')
      );
      this.showBaseLimitTooltip = this.shouldShowTooltip(debrisFormGroup.value);
      this.debrisRemovalSub = debrisFormGroup.valueChanges.subscribe((newLimit: string) => {
        this.showBaseLimitTooltip = this.shouldShowTooltip(newLimit);
      });
    }
  }

  ngOnDestroy() {
    if (this.debrisRemovalSub) {
      this.debrisRemovalSub.unsubscribe();
    }
  }

  private shouldShowTooltip(limit: string): boolean {
    return Number(limit) === DEBRIS_REMOVAL_LIMIT_DEFAULT;
  }
}
