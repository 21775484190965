import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UnsubscribeService } from 'app/shared/services/unsubscribe.service';
import * as parseUrl from 'url-parse';

const TYPE_TO_DESCRIPTION: Record<string, string> = {
  rewards: 'Rewards',
  open_quotes: 'Open Quote Roundup',
  bop_plus_summary: 'BOP+ Upcoming Renewal Summary',
};

@Component({
  templateUrl: 'unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
  email: string;
  token: string;
  type: string;
  isUnsubscribedCompleted: boolean;
  isUnsuccessful: boolean;
  private sub: Subscription = new Subscription();

  constructor(private unsubscribeService: UnsubscribeService) {}

  getLocationHref() {
    return window.location.href;
  }

  ngOnInit() {
    const currentUrl = parseUrl(this.getLocationHref(), '', true);
    this.email = currentUrl.query.email || '';
    this.type = currentUrl.query.type || '';
    this.token = currentUrl.query.token || '';
  }

  emailDescription() {
    if (this.type) {
      return TYPE_TO_DESCRIPTION[this.type];
    }
    return TYPE_TO_DESCRIPTION.bop_plus_summary;
  }

  unsubscribe(e: Event) {
    e.preventDefault();
    this.sub.add(
      this.unsubscribeService.unsubscribe(this.email, this.token, this.type).subscribe((result) => {
        if (result.status >= 400) {
          this.isUnsuccessful = true;
        } else {
          this.isUnsubscribedCompleted = true;
        }
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
