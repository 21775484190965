import { UntypedFormArray } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-waiver-of-subrogation',
  templateUrl: './waiver-of-subrogation.component.html',
})
export class WaiverOfSubrogationComponent {
  @Input() submitted: boolean;
  @Input() prefix: string;
  @Input() waiversOfSubrogation: UntypedFormArray;
  @Input() allowRemovingAllWaivers: boolean;
  @Output() addWaiver: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeWaiver: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  multipleWaivers() {
    return this.waiversOfSubrogation.controls.length > 1;
  }

  addWaiverOfSubrogation() {
    this.addWaiver.emit();
  }

  removeWaiverOfSubrogationAt(index: number) {
    this.removeWaiver.emit(index);
  }
}
