import {
  FormDslNode,
  checkboxGroupNode,
  addressNode,
  SearchQueryMethod,
  SearchFormatter,
} from '../../../shared/form-dsl/constants/form-dsl-typings';
import {
  CoalitionCyberQuestion,
  FirstPartyCoverageNestedQuestion,
  ThirdPartyCoverageNestedQuestion,
  MitigatingClauseNestedQuestion,
  BundleOption,
  EngagedIndustriesNestedQuestion,
} from './cyber-typings.model';
import {
  COMPANY_EMPLOYEE_COUNT_OPTIONS,
  PRIOR_CLAIMS_AMOUNT_OPTIONS,
} from './cyber-constants.model';

export const CYBER_FORM_QUESTIONS: Readonly<Record<CoalitionCyberQuestion, FormDslNode>> = {
  [CoalitionCyberQuestion.ADDRESS]: addressNode({
    nameOfFormControl: CoalitionCyberQuestion.ADDRESS,
    labelText: 'Business headquarters address',
    prefix: 'coalition-cyber',
    useAddressLine2: true,
  }),
  [CoalitionCyberQuestion.AGGREGATE_LIMIT]: {
    primitive: 'MONEY_WITHOUT_DECIMAL',
    inputId: CoalitionCyberQuestion.AGGREGATE_LIMIT,
    nameOfFormControl: CoalitionCyberQuestion.AGGREGATE_LIMIT,
    labelText: 'Limit',
    required: true,
  },
  [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS,
    nameOfFormControl: CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS,
    labelText:
      'Does the company have knowledge or information regarding any fact, circumstance, situation, or event that could reasonably give rise to a claim or loss under the proposed insurance?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS_EXPLANATION]: {
    primitive: 'TEXT_AREA',
    inputId: CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS_EXPLANATION,
    nameOfFormControl: CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS_EXPLANATION,
    labelText: 'Please explain the circumstances and/or potential claims',
    required: true,
  },
  [CoalitionCyberQuestion.BACKUP_AND_RESTORE]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.BACKUP_AND_RESTORE,
    nameOfFormControl: CoalitionCyberQuestion.BACKUP_AND_RESTORE,
    labelText:
      'Does the company maintain at least weekly backups of all sensitive or otherwise critical data and all critical business systems offline or on a separate network?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.BUNDLE]: {
    primitive: 'TRADITIONAL_RADIO',
    inputId: CoalitionCyberQuestion.BUNDLE,
    nameOfFormControl: CoalitionCyberQuestion.BUNDLE,
    labelText: '',
    options: {
      [BundleOption.ESSENTIAL]: BundleOption.ESSENTIAL,
      [BundleOption.MOST_POPULAR]: BundleOption.MOST_POPULAR,
      [BundleOption.CUSTOM]: BundleOption.CUSTOM,
    },
    optionDescriptions: {
      [BundleOption.ESSENTIAL]: 'Basic coverage typical in a Cyber Liability endorsement',
      [BundleOption.MOST_POPULAR]: 'Coverage typical for businesses with elevated cyber risk',
      [BundleOption.CUSTOM]: 'Configure coverage to the unique needs of the insured',
    },
    optionsCssClass: 'trad-radio-option__outline',
    required: true,
  },
  [CoalitionCyberQuestion.CC_CUSTOMER_COUNT]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.CC_CUSTOMER_COUNT,
    nameOfFormControl: CoalitionCyberQuestion.CC_CUSTOMER_COUNT,
    labelText:
      'What is the estimated annual volume of payment card transactions (credit cards, debit cards, etc.)?',
    options: {
      'No records': 'No records',
      'Under 100,000': '<100,000',
      '100,000 - 500,000': '100,000 - 500,000',
      '500,000 - 1,000,000': '500,000 - 1,000,000',
      'More than 1,000,000': '>1,000,000',
    },
    required: true,
  },
  [CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION]: {
    primitive: 'NUMBER',
    inputId: CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION,
    nameOfFormControl: CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION,
    labelText: 'Please enter the value below',
    required: true,
  },
  [CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT,
    nameOfFormControl: CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT,
    labelText: 'Number of employees',
    options: COMPANY_EMPLOYEE_COUNT_OPTIONS,
    required: true,
  },
  [CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE]: {
    primitive: 'MONEY_WITHOUT_DECIMAL',
    inputId: CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
    nameOfFormControl: CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
    labelText: 'Projected gross profit/net revenue over the next 12 months',
    required: true,
  },
  [CoalitionCyberQuestion.COMPANY_INDUSTRY_ID]: {
    primitive: 'DROPDOWN_SEARCH',
    inputId: CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
    nameOfFormControl: CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
    labelText: 'Description of business',
    queryMethodName: SearchQueryMethod.COALITION_CYBER_INDUSTRY,
    formatterName: SearchFormatter.COALITION_CYBER_INDUSTRY,
    required: true,
  },
  [CoalitionCyberQuestion.COMPANY_REVENUE]: {
    primitive: 'MONEY_WITHOUT_DECIMAL',
    inputId: CoalitionCyberQuestion.COMPANY_REVENUE,
    nameOfFormControl: CoalitionCyberQuestion.COMPANY_REVENUE,
    labelText: 'Annual revenue',
    required: true,
  },
  [CoalitionCyberQuestion.CONTENT_COMPLAINTS]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.CONTENT_COMPLAINTS,
    nameOfFormControl: CoalitionCyberQuestion.CONTENT_COMPLAINTS,
    labelText:
      'Within the last 3 years has the company been subject to any complaints concerning the content of its website, advertising materials, social media, or other publications?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.DEFAULT_RETENTION]: {
    primitive: 'MONEY_WITHOUT_DECIMAL',
    inputId: CoalitionCyberQuestion.DEFAULT_RETENTION,
    nameOfFormControl: CoalitionCyberQuestion.DEFAULT_RETENTION,
    labelText: 'Retention (deductible)',
    required: true,
  },
  [CoalitionCyberQuestion.DOMAIN_NAMES]: {
    primitive: 'TEXT_NO_WHITESPACE',
    inputId: CoalitionCyberQuestion.DOMAIN_NAMES,
    nameOfFormControl: CoalitionCyberQuestion.DOMAIN_NAMES,
    labelText: 'Website or email domain',
    specifierText: '(optional)',
    questionNote:
      "Please provide the domain for the primary website or email owned and operated by the insured. This information is used to accurately price this risk and enables Coalition to offer tailored recommendations to improve the insured's cybersecurity. The policyholder will not be contacted or notified as part of this scan.",
    placeholder: 'example.com',
  },
  [CoalitionCyberQuestion.DUAL_CONTROL]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.DUAL_CONTROL,
    nameOfFormControl: CoalitionCyberQuestion.DUAL_CONTROL,
    labelText:
      'Does the company require a secondary means of communication to validate the authenticity of funds transfers (ACH, wire, etc.) requests before processing a request in excess of $25,000?',
    tooltipText:
      'An example of this best practice would be the business calling the requester back at a previously established phone number (ie, not using contact information taken directly from the email) to confirm that the request is legitimate before processing.',
    options: { Yes: 'Yes', No: 'No', 'N/A': 'N/A' },
    required: true,
  },
  [CoalitionCyberQuestion.EFFECTIVE_DATE]: {
    primitive: 'DATE',
    inputId: CoalitionCyberQuestion.EFFECTIVE_DATE,
    nameOfFormControl: CoalitionCyberQuestion.EFFECTIVE_DATE,
    labelText: 'Effective date',
    required: true,
  },
  [CoalitionCyberQuestion.ENCRYPTS_DATA]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.ENCRYPTS_DATA,
    nameOfFormControl: CoalitionCyberQuestion.ENCRYPTS_DATA,
    labelText:
      'Does the company implement encryption on laptop computers, desktop computers, and other portable media devices?',
    options: { Yes: 'Yes', No: 'No', Sometimes: 'Sometimes' },
    required: true,
  },
  [CoalitionCyberQuestion.FIRST_PARTY_COVERAGES]: checkboxGroupNode({
    nameOfFormControl: CoalitionCyberQuestion.FIRST_PARTY_COVERAGES,
    labelText: '1st party coverages',
    questionCssClass: 'h3',
    checkboxConfigs: [
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.BIPD_FIRST_PARTY,
        labelText: 'Bodily Injury and Property Damage - 1st Party',
        tooltipText:
          'A hacker gains access to an industrial control system or Internet-connected device. The attack may come from cyberspace, but the physical impact is real. Coalition helps your client recover, by covering their business interruption losses and extra expenses (including tangible property) to restore their business operations to normal. Sub-limit may apply; see the policy for details.',
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.BREACH_RESPONSE,
        labelText: 'Breach Response',
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_COSTS,
        labelText: 'Breach Response Costs',
        tooltipText:
          'Incidents happen. When they do, Coalition is ready to help your client recover and maintain the confidence of their customers. Coalition will provide expert counsel, and cover the costs of forensics, notification, and incident response efforts to help make your client whole.',
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_SERVICES,
        labelText: 'Breach Response Services (required)',
        initialValue: true,
        requiredTrue: true,
        readonly: true,
        tooltipText:
          'Incidents happen. When they do, Coalition is ready to help them recover and maintain the confidence of their customers. Coalition will provide expert counsel, and cover the costs of forensics, notification, and incident response efforts to help make them whole.',
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.COMPUTER_REPLACEMENT,
        labelText: 'Computer Replacement and Bricking',
        tooltipText:
          "A malware infection alters the firmware of your client's computers. The computers' integrity may be comprised, but their business won’t be. Coalition covers the costs to repair or replace any affected systems. Sub-limit may apply; see the policy for details.",
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.CRISIS_MANAGEMENT,
        labelText: 'Crisis Management and Public Relations',
        tooltipText:
          "When an incident occurs, it’s often more than just your client's data that is damaged. In their time of crisis, Coalition will provide and cover the costs of consultants, public relations, and notification services so that your client can control the narrative.",
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.ASSET_RESTORATION,
        labelText: 'Digital Asset Restoration',
        tooltipText:
          "Your client's network is hit by a ransomware attack. Their data may be held hostage, but their business doesn’t have to be. Coalition will cover the costs to fix the problem and restore data that is corrupted or lost.",
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.BUSINESS_INTERRUPTION,
        labelText:
          'Direct and Contingent Business Interruption, and Extra Expenses from Security Failure and Systems Failure',
        tooltipText:
          "A hacker uses a denial of service attack to take down your client's website. Their cloud provider goes down as the result of a network outage. Coalition not only cover their lost income, but any extra expenses to get your client's business back online.",
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.FUNDS_TRANSFER,
        labelText: 'Funds Transfer Fraud, Personal Funds Fraud, and Social Engineering',
        tooltipText:
          "A funds transfer request is sent to your client's controller by someone pretending to be the CEO. The funds may be out the door, but at least they're not out of pocket—Coalition's got them covered. Sub-limit and separate retention may apply; see the policy for details.",
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.EXTORTION,
        labelText: 'Ransomware and Cyber Extortion',
        tooltipText:
          "A hacker has encrypted all of your client's files and won’t unlock them until they pay a ransom. Not to worry, Coalition will cover the costs to get them up and running again.",
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.REPUTATIONAL_HARM,
        labelText: 'Reputational Harm Loss',
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.REPUTATION,
        labelText: 'Reputation Repair',
        tooltipText:
          "When an incident occurs, it’s often more than just your client's data that is damaged. In their time of crisis, Coalition will provide and cover the costs of consultants, public relations, and notification services so that they can control the narrative.\n" +
          '\n' +
          'Reputation Repair coverage enhances Crisis Management coverage, with expanded coverage for advertising and media purchases and consulting costs to help your client preserve customer confidence and protect their brand.',
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.SERVICE_FRAUD,
        labelText: 'Service Fraud including Cryptojacking',
        tooltipText:
          "A hacker gains access to your client's cloud infrastructure (network, servers, telephony, etc.) and drives up their bill mining for virtual currencies. Coalition will reimburse them for the loss incurred as a result of these malicious acts. Sub-limit may apply; see the policy for details.",
      },
      {
        nameOfFormControl: FirstPartyCoverageNestedQuestion.TECH_EO,
        labelText: 'Technical Errors and Omissions',
      },
    ],
  }),
  [CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY,
    nameOfFormControl: CoalitionCyberQuestion.HAS_EO_OR_MGMT_PROFESSIONAL_LIABILITY_POLICY,
    labelText:
      'Will the applicant have an active errors and omissions or management professional liability policy concurrent with this insurance policy?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.HAS_TECH_EO]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.HAS_TECH_EO,
    nameOfFormControl: CoalitionCyberQuestion.HAS_TECH_EO,
    labelText:
      'Will the company have an active technology errors and omissions policy concurrent with this insurance policy?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.IS_MSP_OR_BAD_INDUSTRY]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.IS_MSP_OR_BAD_INDUSTRY,
    nameOfFormControl: CoalitionCyberQuestion.IS_MSP_OR_BAD_INDUSTRY,
    labelText:
      'Is the company operating as a managed service provider (MSP), or does the company participate directly in or sell technology products/services designed for any of the following industries?',
    ul: {
      li: [
        'Cryptocurrency',
        'Cannabis',
        'Internet of Things',
        'Financial Services',
        'Healthcare',
        'Blockchain',
        'Automotive',
        'Aviation',
        'Military/Defense',
        'Professional Services (Legal, Medical, A&E, or other licensed professional services)',
        'Point of Sale (POS) Software/​Hardware/​Reseller',
        'Gambling',
        'Payment Processing',
        'Adult Entertainment',
      ],
    },
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.MFA_EMAIL]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.MFA_EMAIL,
    nameOfFormControl: CoalitionCyberQuestion.MFA_EMAIL,
    labelText: 'Does the company enforce Multi-Factor Authentication (MFA) for email?',
    options: {
      Yes: 'Yes',
      No: 'No',
      'N/A - No remote access allowed': 'N/A - No remote access allowed',
    },
    required: true,
  },
  [CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
    nameOfFormControl: CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
    labelText:
      'Does the company enforce Multi-Factor Authentication (MFA) for network / cloud administration or other privileged user accounts?',
    options: {
      Yes: 'Yes',
      No: 'No',
      'On administrative accounts and all cloud services where supported':
        'On administrative accounts and all cloud services where supported',
    },
    required: true,
  },
  [CoalitionCyberQuestion.MFA_REMOTE_ACCESS]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
    nameOfFormControl: CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
    labelText:
      'Does the company enforce Multi-Factor Authentication (MFA) for Remote Desktop Protocol (RDP), RDWeb, RD Gateway, or other remote access?',
    options: {
      Yes: 'Yes',
      No: 'No',
      'N/A - No remote access allowed': 'N/A - No remote access allowed',
    },
    required: true,
  },
  [CoalitionCyberQuestion.MITIGATING_CLAUSES]: checkboxGroupNode({
    nameOfFormControl: CoalitionCyberQuestion.MITIGATING_CLAUSES,
    labelText:
      'Identify the standard risk mitigating clauses or methods contained within the company agreements or contracts.',
    questionCssClass: 'h3',
    checkboxConfigs: [
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.BINDING_ARBITRATION,
        labelText: 'Binding mandatory arbitration',
      },
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.CUSTOMER_SIGN_OFF,
        labelText: 'Customer acceptance / final sign off',
      },
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.DISCLAIMER_OF_WARRANTIES,
        labelText: 'Disclaimer of warranties',
      },
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.EXCLUSION_OF_DAMAGES,
        labelText: 'Exclusion of consequential damages',
      },
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.HOLD_HARMLESS_AGREEMENTS,
        labelText: 'Hold harmless agreements that benefit the company',
      },
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.INDEMNIFICATION,
        labelText: 'Indemnification clause',
      },
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.LIMITATION_OF_LIABILITY,
        labelText: 'Limitation of Liability',
      },
      {
        nameOfFormControl: MitigatingClauseNestedQuestion.MILESTONES,
        labelText: 'Project phases / milestones',
      },
    ],
  }),
  [CoalitionCyberQuestion.PII_PHI]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.PII_PHI,
    nameOfFormControl: CoalitionCyberQuestion.PII_PHI,
    labelText:
      'Does the company collect, process, store, transmit, or have access to any Payment Card Information (PCI), Personally Identifiable Information (PII), or Protected Health Information (PHI) other than employees of the company?',
    tooltipText:
      "PII (personally identifiable information) and PHI (protected health information) includes data such as health information, social security numbers, credit or debit card numbers, driver's license numbers, and similar info. This does not include employees.",
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.PII_PHI_COUNT]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.PII_PHI_COUNT,
    nameOfFormControl: CoalitionCyberQuestion.PII_PHI_COUNT,
    labelText:
      'How many PII or PHI records does the company collect, process, store, transmit, or have access to?',
    options: {
      'No records': 'No records',
      'Under 100,000': '<100,000',
      '100,000 - 500,000': '100,000 - 500,000',
      '500,000 - 1,000,000': '500,000 - 1,000,000',
      'More than 1,000,000': '>1,000,000',
    },
    required: true,
  },
  [CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION]: {
    primitive: 'NUMBER',
    inputId: CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION,
    nameOfFormControl: CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION,
    labelText: 'Please enter the value below',
    required: true,
  },
  [CoalitionCyberQuestion.PRIOR_CLAIMS]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.PRIOR_CLAIMS,
    nameOfFormControl: CoalitionCyberQuestion.PRIOR_CLAIMS,
    labelText:
      'Within the last 3 years, has the company suffered any cyber incidents resulting in any single claim in excess of $25,000?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION]: {
    primitive: 'TEXT_AREA',
    inputId: CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
    nameOfFormControl: CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
    labelText: 'Please provide details of all the cyber incidents and claims.',
    required: true,
  },
  [CoalitionCyberQuestion.PROFESSIONAL_SERVICES]: {
    primitive: 'TEXT_AREA',
    inputId: CoalitionCyberQuestion.PROFESSIONAL_SERVICES,
    nameOfFormControl: CoalitionCyberQuestion.PROFESSIONAL_SERVICES,
    labelText:
      'Please describe the company use of technology in delivering its product and/or services.',
    required: true,
  },
  [CoalitionCyberQuestion.REVIEWS_CONTENT]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.REVIEWS_CONTENT,
    nameOfFormControl: CoalitionCyberQuestion.REVIEWS_CONTENT,
    labelText:
      'Does the company enforce procedures to remove content (including third party content) that may infringe or violate any intellectual property or privacy right?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.SERVICES_BY_CONTRACT]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.SERVICES_BY_CONTRACT,
    nameOfFormControl: CoalitionCyberQuestion.SERVICES_BY_CONTRACT,
    labelText: 'How often are the company services provided by written agreement or contract?',
    options: {
      '0% of agreements or contracts': '0% of agreements or contracts',
      'Under 50% of agreements or contracts': '<50% of agreements or contracts',
      '50% or more of agreements or contracts': '>=50% of agreements or contracts',
      '100% of agreements or contracts': '100% of agreements or contracts',
    },
    required: true,
  },
  [CoalitionCyberQuestion.TECH_EO_DISPUTE]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.TECH_EO_DISPUTE,
    nameOfFormControl: CoalitionCyberQuestion.TECH_EO_DISPUTE,
    labelText:
      'Within the last 3 years has the company been subject to a dispute or claim arising out of a technology error or omission in excess of $25,000?',
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.TECH_EO_DISPUTE_EXPLANATION]: {
    primitive: 'TEXT_AREA',
    inputId: CoalitionCyberQuestion.TECH_EO_DISPUTE_EXPLANATION,
    nameOfFormControl: CoalitionCyberQuestion.TECH_EO_DISPUTE_EXPLANATION,
    labelText: 'Please explain the disputes and/or claims.',
    required: true,
  },
  [CoalitionCyberQuestion.THIRD_PARTY_COVERAGES]: checkboxGroupNode({
    nameOfFormControl: CoalitionCyberQuestion.THIRD_PARTY_COVERAGES,
    labelText: '3rd party coverages',
    questionCssClass: 'h3',
    checkboxConfigs: [
      {
        nameOfFormControl: ThirdPartyCoverageNestedQuestion.BIPD_THIRD_PARTY,
        labelText: 'Bodily Injury and Property Damage - 3rd Party',
        tooltipText:
          'A hacker gains access to an industrial control system or Internet-connected device. The attack may come from cyberspace, but the physical impact is real. Coalition will cover the costs of defense and any liability your client incurs when a failure in their security results in physical damage or injury. Sub-limit may apply; see the policy for details.',
      },
      {
        nameOfFormControl: ThirdPartyCoverageNestedQuestion.MEDIA_LIABILITY,
        labelText: 'Multimedia Content Liability',
        tooltipText:
          'Thanks to the Internet, any company with a public website is subject to the same exposures and litigation of a traditional media company. Coalition will indemnify your client against claims for defamation, invasion of privacy, trademark and copyright infringement, and other multimedia wrongful acts.',
      },
      {
        nameOfFormControl: ThirdPartyCoverageNestedQuestion.NETWORK,
        labelText: 'Network and Information Security Liability (required)',
        initialValue: true,
        requiredTrue: true,
        readonly: true,
        tooltipText:
          "An employee loses a laptop with the private data of your client's customers or a hacker breaches your computer’s defenses and steals your data. These events can trigger mandatory reporting obligations, or worse, resulting in your client's company being sued. Coalition helps your client resolve these incidents, including hiring legal counsel and covering legal judgments.",
      },
      {
        nameOfFormControl: ThirdPartyCoverageNestedQuestion.PCI,
        labelText: 'PCI Fines and Assessments',
        tooltipText:
          "Your client's customers’ credit card information was stolen. Don’t worry. Coalition will cover any PCI fines or assessments made against your client. If you weren’t aware, all businesses that accept or store credit card information are subject to the Payment Card Industry’s Data Security Standards (PCI DSS).",
      },
      {
        nameOfFormControl: ThirdPartyCoverageNestedQuestion.POLLUTION,
        labelText: 'Pollution',
        tooltipText:
          'A hacker gains access to an industrial control system or Internet-connected device. A resulting system failure triggers the release of pollutants. Coalition will cover the costs to defend your client and any liability they might have to a 3rd party resulting in the release of pollutants. Sub-limit may apply; see the policy for details.',
      },
      {
        nameOfFormControl: ThirdPartyCoverageNestedQuestion.REGULATORY_DEFENSE,
        labelText: 'Regulatory Defense and Penalties',
        tooltipText:
          "Data breaches are damaging enough, but they can also result in state and federal investigations and penalties to your client's business. Coalition will cover the costs to manage any investigation as well as any penalties levied against your client (subject to applicable law).",
      },
    ],
  }),
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
    nameOfFormControl: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
    labelText:
      'During the past three years, did the company experience a cyber incident, claim or loss, whether insured or not, which could have been covered under a policy similar to the proposed insurance? This includes but is not limited to any:',
    ul: {
      li: [
        'Actual or reasonably suspected data breach or security failure, including notifying consumers or third parties of data breach or security failure',
        'Claims or complaints with respect to privacy injury, breach of information or network security, unauthorized disclosure of information, defamation, or content infringement',
        'Government action, investigation, or subpoena regarding any alleged violation of a privacy law or regulation',
        'Actual or attempted extortion demand with respect to its data or computer systems',
      ],
    },
    options: { Yes: 'Yes', No: 'No' },
    required: true,
  },
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT]: {
    primitive: 'NUMBER',
    inputId: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
    nameOfFormControl: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
    labelText: 'How many claims in total?',
    required: true,
    errorText: 'Please enter a value greater than 0.',
  },
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
    nameOfFormControl: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
    labelText: 'What is the total claims payout amount?',
    options: PRIOR_CLAIMS_AMOUNT_OPTIONS,
    required: true,
  },
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K]: {
    primitive: 'MONEY_WITHOUT_DECIMAL',
    inputId: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
    nameOfFormControl: CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
    labelText: 'Please enter the value below',
    required: true,
  },
  [CoalitionCyberQuestion.MFA_VPN]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.MFA_VPN,
    nameOfFormControl: CoalitionCyberQuestion.MFA_VPN,
    labelText:
      'Does the company enforce Multi-Factor Authentication (MFA) for Virtual Private Network (VPN) access?',
    options: { Yes: 'Yes', No: 'No', 'N/A - No VPN allowed': 'N/A - No VPN allowed' },
    required: true,
  },
  [CoalitionCyberQuestion.DUAL_CONTROL_5K]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.DUAL_CONTROL_5K,
    nameOfFormControl: CoalitionCyberQuestion.DUAL_CONTROL_5K,
    labelText:
      'Does the company require a secondary means of communication to validate the authenticity of funds transfers (ACH, wire, etc.) requests before processing a request in excess of $5,000?',
    tooltipText:
      'An example of this best practice would be the business calling the requester back at a previously established phone number (ie, not using contact information taken directly from the email) to confirm that the request is legitimate before processing.',
    options: { Yes: 'Yes', No: 'No', 'N/A': 'N/A' },
    required: true,
  },
  [CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL]: {
    primitive: 'RADIO',
    inputId: CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
    nameOfFormControl: CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
    labelText:
      'Does the company require a secondary means of communication to validate the authenticity of any request to change banking details (ACH, wire, payroll distribution, etc.)?',
    options: { Yes: 'Yes', No: 'No', 'N/A': 'N/A' },
    required: true,
  },
  [CoalitionCyberQuestion.ENGAGED_INDUSTRIES]: checkboxGroupNode({
    nameOfFormControl: CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
    labelText: 'Is the company engaged in any of the following businesses?',
    subLabelText: 'Check all that apply.',
    checkboxConfigs: [
      {
        nameOfFormControl: EngagedIndustriesNestedQuestion.ADULT,
        labelText: 'Adult Content',
      },
      {
        nameOfFormControl: EngagedIndustriesNestedQuestion.CRYPTO_BLOCKCHAIN,
        labelText: 'Cryptocurrency or Blockchain',
      },
      {
        nameOfFormControl: EngagedIndustriesNestedQuestion.GAMBLING,
        labelText: 'Gambling',
      },
      {
        nameOfFormControl: EngagedIndustriesNestedQuestion.MSP_MSSP_RNASP,
        labelText: 'Payment Processing',
      },
      {
        nameOfFormControl: EngagedIndustriesNestedQuestion.PAYMENT_PROCESSING,
        labelText:
          'Managed Service Provider (MSP), Managed Security Service Provider (MSSP), or remote network administration services provider',
      },
    ],
  }),
};
