import { Injectable } from '@angular/core';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';
import { WcFormValue, WcPolicy } from 'app/workers-comp/employers/models/wc-policy';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { User } from 'app/shared/models/user';

@Injectable()
export class WcPolicyFactory {
  constructor(private orgTypeService: OrganizationTypeService) {}

  public build(wcForm: WcFormValue, insuredAccount: InsuredAccount, user: User): WcPolicy {
    const wcPolicy = new WcPolicy(wcForm, insuredAccount, user);
    wcPolicy.account.organizationType = this.orgTypeService.getEmployersOrgCode(
      wcForm.basicInfo.organizationType || ''
    );
    return wcPolicy;
  }
}
