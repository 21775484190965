import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  UntypedFormArray,
} from '@angular/forms';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { evaluatorFuncs, validatorFuncs } from '../constants/form-dsl-constants';
import {
  FormDslNode,
  FormDslStep,
  LinkModalNode,
  ControlConfig,
  GroupConfig,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { getFormGroup, getControl, enableDisableControl } from 'app/shared/helpers/form-helpers';
import { combineLatest, merge, of as observableOf } from 'rxjs';

export const flattenTree = (nodeList: FormDslNode[]): FormDslNode[] => {
  return nodeList.reduce((nodeAccum: FormDslNode[], node) => {
    if (node.primitive === 'DIV') {
      nodeAccum = nodeAccum.concat(flattenTree(node.children));
    } else if (node.primitive === 'LINK_MODAL') {
      const lmNode = node as LinkModalNode;
      nodeAccum.push({ ...lmNode.child, ...lmNode });
    } else if (
      node.primitive === 'TRUE-CONDITIONAL' ||
      node.primitive === 'VALUE-CONDITIONAL' ||
      node.primitive === 'EVAL-CONDITIONAL'
    ) {
      nodeAccum.push(node);
      nodeAccum = nodeAccum.concat(flattenTree(node.conditionalChildren));
    } else {
      nodeAccum.push(node);
    }
    return nodeAccum;
  }, []);
};

const validatorsForNode = (node: FormDslNode) => {
  const validators: ValidatorFn[] = [];
  if (node.required) {
    validators.push(Validators.required);
  }
  if ('validators' in node && node.validators && node.validators.length) {
    node.validators.forEach((validator) => {
      validators.push(validatorFuncs[validator]);
    });
  }
  return validators;
};

export const setNodeArrayInputIds = (childNodes: FormDslNode[], prefix: string, index: number) => {
  return childNodes.map((formDslNode) => {
    if ('inputId' in formDslNode) {
      return { ...formDslNode, inputId: `${prefix}-${index}-${formDslNode.inputId}` };
    } else {
      return formDslNode;
    }
  });
};

// I don't know how to enforce it, but the FormDslNodes passed to this
// function will already be flattened, no nesting
export const formTreeToFormGroup = (
  primitiveList: FormDslNode[],
  formBuilder: UntypedFormBuilder
): GroupConfig => {
  const flatPrimitiveList = flattenTree(primitiveList);
  const formGroupConfig: { [key: string]: ControlConfig | UntypedFormGroup | UntypedFormArray } =
    {};
  flatPrimitiveList.forEach((node: FormDslNode) => {
    if ('isFormGroup' in node && 'controls' in node && node.isFormGroup) {
      // If the node represents a form group, we don't want to add the node itself,
      // just its controls as a form group.
      formGroupConfig[node.nameOfFormControl] = formBuilder.group(node.controls, {
        validators: validatorsForNode(node),
      });
    } else if (node.primitive === 'NODE_ARRAY') {
      const childFormGroups = node.children.map((childNodes, childIdx) => {
        const nodesWithIds = setNodeArrayInputIds(childNodes, node.prefix, childIdx);
        const childFormGroupConfig = formTreeToFormGroup(nodesWithIds, formBuilder);
        return formBuilder.group(childFormGroupConfig);
      });
      formGroupConfig[node.nameOfFormControl] = formBuilder.array(
        childFormGroups,
        validatorsForNode(node)
      );
    } else if ('isArray' in node && node.isArray) {
      // Create child controls and apply validators to the array itself
      formGroupConfig[node.nameOfFormControl] = formBuilder.array(
        _.keys(node.values),
        validatorsForNode(node)
      );
      const childValidator = node.required ? [Validators.required] : [];

      // TODO: Rework this. Currently we are creating any child controls twice:
      //       once as members of the FormArray, and again as siblings of the FormArray

      // Add array members to the form config, and set validator
      _.keys(node.values).forEach((k: string) => {
        formGroupConfig[k] = [null, childValidator];
      });
    } else if ('inputId' in node && node.nameOfFormControl) {
      formGroupConfig[node.nameOfFormControl] = [null, validatorsForNode(node)];
    }
  });

  return formGroupConfig;
};

const setFormDependenciesForNode = (form: UntypedFormGroup, node: FormDslNode) => {
  // for now assume that TRUE-CONDITIONAL primitves aren't nested in
  // divs and aren't recursively nested, those are later pieces of work
  if (node.primitive === 'TRUE-CONDITIONAL') {
    node.conditionalChildren.forEach((subNode) => {
      setFormDependenciesForNode(form, subNode);
      const subChildControl = getControl(form, subNode.nameOfFormControl as string);
      if (subChildControl) {
        subChildControl.disable();
      }
    });

    const control = getControl(form, node.nameOfFormControl);
    if (!control) {
      return;
    }
    control.valueChanges.subscribe((newBoolValue) => {
      node.conditionalChildren.forEach((subNode) => {
        const subChildControl = getControl(form, subNode.nameOfFormControl as string);
        enableDisableControl(subChildControl, newBoolValue === true);
      });
    });
  } else if (node.primitive === 'VALUE-CONDITIONAL' || node.primitive === 'EVAL-CONDITIONAL') {
    node.conditionalChildren.forEach((subNode) => {
      setFormDependenciesForNode(form, subNode);
    });
    let controlsToEvaluate: UntypedFormControl[] = [];
    if (_.isArray(node.dependsOn)) {
      controlsToEvaluate = node.dependsOn.map((dependency) => getControl(form, dependency));
      if (!controlsToEvaluate.every((item) => !!item)) {
        return;
      }
    } else {
      controlsToEvaluate = [getControl(form, node.dependsOn)];
      if (!controlsToEvaluate[0]) {
        return;
      }
    }

    const controlsToToggle = node.conditionalChildren.reduce(
      (controls: UntypedFormControl[], childNode) => {
        const childNodeControl = getControl(form, childNode.nameOfFormControl as string);
        if (childNodeControl) {
          childNodeControl.disable();
          controls.push(childNodeControl);
        }
        return controls;
      },
      []
    );

    const enableEvaluator =
      node.primitive === 'EVAL-CONDITIONAL'
        ? evaluatorFuncs[node.enableEvaluator]
        : (val: any) => val === node.enableValue;
    const values$ = controlsToEvaluate.map((ctrl) => ctrl.valueChanges);

    const valuesObservables = values$.map((valueObservable, i) =>
      merge(valueObservable, observableOf(controlsToEvaluate[i].value))
    );

    combineLatest(valuesObservables).subscribe((values) => {
      controlsToToggle.forEach((toggledControl) => {
        if (controlsToEvaluate.length === 1) {
          const evaluatedControlEnabled = controlsToEvaluate[0].enabled;
          enableDisableControl(
            toggledControl,
            enableEvaluator(values[0]) && evaluatedControlEnabled
          );
        } else {
          // Note: if there are multiple input controls to evaluate, we cannot deterministically infer
          // from this service how an evaluator function will use those controls to determine whether
          // its children will be enabled based on the values of these controls. In this case, it is the
          // developer's responsibility to make sure that they explicitly cover all cases for which the
          // control should be visible in the evaluator function.
          enableDisableControl(toggledControl, enableEvaluator(values));
        }
      });
    });
  }
};

export const setFormDependencies = (form: UntypedFormGroup, formTree: FormDslNode[]) => {
  formTree.forEach((node) => {
    setFormDependenciesForNode(form, node);
  });
};

@Injectable()
export abstract class FormDslServiceBase extends FormDslSteppedFormBaseService {
  form: UntypedFormGroup;
  formTree: FormDslNode[];
  defaultFormStep: string;
  FORM_STEPS: RouteFormStep[];
  formDslSteps: FormDslStep[];
  formPathToIndex: { [key: string]: number };
  formPathToFormDslStep: { [key: string]: FormDslStep };

  constructor(public formBuilder: UntypedFormBuilder) {
    super();
  }

  fillInHappyPath() {}

  initializeSteps(fdslSteps: FormDslStep[]) {
    this.formDslSteps = fdslSteps;
    this.currentStep = this.formDslSteps[0];
    this.formPathToIndex = _.reduce(
      this.formDslSteps,
      (obj: { [key: string]: number }, s: FormDslStep, i: number) => {
        const sPath = s.formPath || this.defaultFormStep;
        obj[sPath] = i;
        return obj;
      },
      {}
    );

    this.formPathToFormDslStep = _.reduce(
      this.formDslSteps,
      (obj: { [key: string]: FormDslStep }, s: FormDslStep) => {
        const sPath = s.formPath || this.defaultFormStep;
        obj[sPath] = s;
        return obj;
      },
      {}
    );
  }

  initializeForm() {
    const flatTree = flattenTree(this.formTree);

    const formGroupFinal: any = {};

    // TODO: remove, just a not lest we run into a merge error - the
    // following two lines are from old main
    // this.FORM_STEPS.forEach((stepId) => {
    //   const stepNodes = _.filter(flatTree, (node: FormDslNode) => {
    this.FORM_STEPS.map((step) => step.formPath).forEach((stepId: string) => {
      const stepNodes = _.filter(flatTree, (node: any) => {
        if (typeof node.formStep === 'undefined') {
          if (stepId === this.defaultFormStep) {
            return true;
          }
          return false;
        } else {
          return node.formStep === stepId;
        }
      });

      formGroupFinal[stepId] = this.formBuilder.group(
        formTreeToFormGroup(stepNodes, this.formBuilder)
      );
    });

    this.form = this.formBuilder.group(formGroupFinal);
    this.formDependenciesInit(this.defaultFormStep);
  }

  formDependenciesInit(formStep: string) {
    const servForm = getFormGroup(this.form, formStep);
    if (!servForm) {
      return;
    }
    setFormDependencies(servForm, this.formTree);
  }

  getValue() {
    return this.form.value;
  }

  getRawValue() {
    return this.form.getRawValue();
  }

  // Retrieve fields of step
  getFieldsOfStep(path: string) {
    return this.getRawValue()[path];
  }

  setFormTree(formTree: FormDslNode[]) {
    this.formTree = flattenTree(formTree);
  }
}
