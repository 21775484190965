<section class="form-animation">
  <h1>Quote feedback</h1>

  <h3 class="mb-2">Why are you seeking an additional quote?</h3>

  <ng-container *ngFor="let entry of feedbackLabelMap | keyvalue: keepMapOrder">
    <app-form-field-checkbox
      inputId="{{ entry.key }}-checkbox"
      labelText="{{ entry.value.checkboxLabel }}"
      [form]="form.get(entry.key)"
      [submitted]="submitted()"
      nameOfFormControl="checkbox"
    >
    </app-form-field-checkbox>

    <!-- For the price comment we want the input to be more structured so we add the appMoneyMask -->
    <ng-container *ngIf="entry.key === 'price'; else standardComment">
      <app-form-field-text
        inputId="{{ entry.key }}-comment"
        labelText="{{ entry.value.commentLabel }}"
        [form]="form.get(entry.key)"
        [submitted]="submitted()"
        nameOfFormControl="comment"
        cssClass="sub-question"
        appMoneyMask="WITHOUT_DECIMAL"
        placeholder="$"
      >
      </app-form-field-text>
    </ng-container>

    <!-- This is rendered when entry.key is not 'price' -->
    <ng-template #standardComment>
      <app-form-field-textarea
        inputId="{{ entry.key }}-comment"
        labelText="{{ entry.value.commentLabel }}"
        [form]="form.get(entry.key)"
        [submitted]="submitted()"
        nameOfFormControl="comment"
        cssClass="sub-question"
      >
      </app-form-field-textarea>
    </ng-template>
  </ng-container>
</section>
