import { Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';

@Component({
  selector: 'app-hab-policy-details-page',
  templateUrl: './hab-policy-details-page.component.html',
})
export class HabPolicyDetailsPageComponent {
  form: UntypedFormGroup;
  formPath = 'policyDetails';
  organizationTypes: { [key: string]: string } = {};

  public habBuildingCoverageBlanketDeductibleOptions: { [key: string]: string } = {
    '$2,500': '2500',
    '$5,000': '5000',
    '$10,000': '10000',
  };

  public habBuildingCoverageBlanketCoinsuranceOptions: { [key: string]: string } = {
    '90%': '90',
    '100%': '100',
  };

  public habBusinessIncomeBlanketCoinsuranceOptions: { [key: string]: string } =
    this.habBuildingCoverageBlanketCoinsuranceOptions;

  constructor(
    protected formService: HabQuoteFormService,
    protected organizationTypeService: OrganizationTypeService
  ) {
    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
    if (!this.form) {
      throw new Error(`formPath (${this.formPath}) not found`);
    }

    this.organizationTypeService.getOrganizationTypes().forEach((orgType) => {
      this.organizationTypes[orgType.value] = orgType.name;
    });
  }

  hasSubmitted() {
    return this.formService.submitted;
  }

  public locationSummaries(): UntypedFormArray {
    return this.formService.locationSummariesFormArray();
  }

  public moreThanOneLocation(): boolean {
    return this.locationSummaries().length > 1;
  }

  public addLocation(): void {
    return this.formService.addLocation();
  }

  public removeLocation(index: number): void {
    return this.formService.removeLocation(index);
  }

  public indentedFieldMarginCss(formControlName: string): string {
    const formControl: AbstractControl | null = this.form.get(formControlName);

    if (formControl === null) {
      return '';
    }

    return formControl.disabled ? '' : 'form-field__no-margin';
  }

  public getAddressErrors(locationIndex: number) {
    const addressStateControl = this.locationSummaries().at(locationIndex).get('address.state');
    if (
      !addressStateControl ||
      !addressStateControl.errors ||
      !addressStateControl.errors.stateValidationError
    ) {
      return '';
    }
    return addressStateControl.errors.stateValidationError;
  }
}
