<header class="bind-quote-header">
  <h1 class="mb-4 mt-0">Payment Details</h1>
</header>

<main *ngIf="paymentIframeLoading" class="placeholder-form-container">
  <div class="placeholder-form-input"></div>
</main>

<ng-container *ngIf="!paymentIframeLoading">
  <iframe
    id="contentFrame"
    [ngStyle]="{ width: '800px', height: '520px' }"
    [src]="libertyMutualHydraUrl"
    class="e2e-iframe-trusted-src"
    frameborder="0"
    width="600"
    height="600"
    allowfullscreen
  >
  </iframe>
</ng-container>
