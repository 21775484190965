import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string) {
    if (phoneNumber) {
      const simplePhoneNumber = phoneNumber.replace(/\D/g, '');

      if (simplePhoneNumber.length === 10) {
        const areaCode = simplePhoneNumber.slice(0, 3);
        const prefix = simplePhoneNumber.slice(3, 6);
        const suffix = simplePhoneNumber.slice(6, 10);
        return `(${areaCode}) ${prefix}-${suffix}`;
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  }
}
