<div class="policy-detail-dropdown-subsection">
  <div class="policy-pane-header policy-pane-header__no-margin">
    <h3 class="policy-pane-header-left policy-pane-header-h2">{{ header }}</h3>
    <a
      (click)="isExpanded = !isExpanded"
      (keyup.enter)="isExpanded = !isExpanded"
      class="policy-pane-header-right policy-pane-header-right__end"
    >
      View details
      <span
        class="icon-arrow-down button-icon"
        [ngClass]="{
          'icon-arrow-down': !isExpanded,
          'icon-arrow-up': isExpanded
        }"
      ></span>
    </a>
  </div>
  <ng-content *ngIf="isExpanded"></ng-content>
</div>
