import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';
import { getControl } from 'app/shared/helpers/form-helpers';
import { ImageRadioQuestionOption } from 'app/shared/form-dsl/components/form-field-radio-image/form-field-radio-image.component';

@Component({
  selector: 'app-hab-building-page',
  templateUrl: './hab-building-page.component.html',
})
export class HabBuildingPageComponent implements OnDestroy {
  public buildingId = '';
  public form: UntypedFormGroup;
  public locationId = '';

  public habConstructionTypeOptions: { [key: string]: string } = {
    '': 'Choose one',
    FireResistive: 'Fire-Resistive',
    FrameConstruction: 'Frame Construction',
    JoistedMasonry: 'Joisted Masonry',
    MasonryNonCombustible: 'Masonry Non-Combustible',
    ModifiedFireResistive: 'Modified Fire-Resistive',
    NonCombustible: 'Non-Combustible',
  };

  public habNumStoriesOptions: { [key: string]: string } = {
    '': 'Choose one',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
  };

  public habRoofTypeOptions: { [key: string]: string } = {
    '': 'Choose one',
    AsphaltShingle: 'Asphalt Shingle',
    Metal: 'Metal',
    TarAndGravel: 'Tar and Gravel',
    Tile: 'Tile',
    Slate: 'Slate',
    WoodShingle: 'Wood Shingle',
  };

  public habRoofShapeRadioOptions: { [key: string]: ImageRadioQuestionOption } = {
    Flat: { displayName: 'Flat', imageUrl: '/assets/img/hab/roof_flat.png' },
    Hip: { displayName: 'Hip', imageUrl: '/assets/img/hab/roof_hip.png' },
    Gable: { displayName: 'Gable', imageUrl: '/assets/img/hab/roof_gable.png' },
    MixedShape: { displayName: 'Mixed Shape', imageUrl: '/assets/img/hab/roof_mixed_shape.png' },
  };

  public habSmokeDetectorOptions: { [key: string]: string } = {
    'Hardwired or enclosed 10yr lithium': 'HARDWIREDOR10YRLITHIUM',
    'Battery or other': 'BATTERYOROTHER',
    None: 'NONE',
  };

  public habBuildingCoverageDeductibleOptions: { [key: string]: string } = {
    '$2,500': '2500',
    '$5,000': '5000',
    '$10,000': '10000',
  };

  public habBuildingCoverageCoinsuranceOptions: { [key: string]: string } = {
    '80%': '80',
    '90%': '90',
    '100%': '100',
  };

  public habContentsCoverageDeductibleOptions: { [key: string]: string } =
    this.habBuildingCoverageDeductibleOptions;

  public habContentsCoverageCoinsuranceOptions: { [key: string]: string } =
    this.habBuildingCoverageCoinsuranceOptions;

  public habBusinessIncomeCoinsuranceOptions: { [key: string]: string } =
    this.habBuildingCoverageCoinsuranceOptions;

  public habWindDeductibleOptions: { [key: string]: string } = {};

  public isChangingRoutes = false;
  public routeParams$: Subscription;

  constructor(protected formService: HabQuoteFormService, protected route: ActivatedRoute) {
    this.routeParams$ = this.route.params
      .pipe(
        tap((params: Params) => {
          this.isChangingRoutes = true; // Trigger the form page transition animation.
        }),
        tap((params: Params) => {
          this.locationId = params['locationId'];
          this.buildingId = params['buildingId'];

          const zeroIndexedLocationId = parseInt(this.locationId, 10) - 1;
          const zeroIndexedBuildingId = parseInt(this.buildingId, 10) - 1;
          const formPath = `locations.${zeroIndexedLocationId}.buildings.${zeroIndexedBuildingId}`;

          this.form = <UntypedFormGroup>this.formService.form.get(formPath);
          if (!this.form) {
            throw new Error(`formPath (${formPath}) not found`);
          }
        }),
        delay(300) // Allow the form transition animation to finish.
      )
      .subscribe(() => {
        this.isChangingRoutes = false; // Reset, allowing navigations to the next building to be animated.
      });
  }

  ngOnDestroy() {
    this.routeParams$.unsubscribe();
  }

  hasSubmitted() {
    return this.formService.submitted;
  }

  public indentedFieldMarginCss(formControlName: string): string {
    return getControl(this.form, formControlName).disabled ? '' : 'form-field__no-margin';
  }
}
