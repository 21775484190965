import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AmplitudeService } from 'app/core/services/amplitude.service';

@Component({
  selector: 'app-uw-alert-modal',
  templateUrl: './uw-alert-modal.component.html',
})
export class UWAlertModalComponent {
  @Input() public open = false;
  @Input() public uwAlerts: UWAlert[] = [];
  @Input() public naicsCode: NaicsCode;
  @Input() public guidelinesData: string[];
  @Output() closeUWModal = new EventEmitter<void>();

  constructor(private amplitudeService: AmplitudeService) {}

  closeModal() {
    this.closeUWModal.emit();
  }

  handleGuidelinesClick(event: any) {
    const areGuidelinesExpanded = event.target.open;
    if (areGuidelinesExpanded) {
      this.amplitudeService.track({
        eventName: 'UW_alert_guidelines',
        detail: 'opened',
        useLegacyEventName: true,
      });
    } else {
      this.amplitudeService.track({
        eventName: 'UW_alert_guidelines',
        detail: 'closed',
        useLegacyEventName: true,
      });
    }
  }
}
