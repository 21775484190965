<ng-container>
  <header class="bind-quote-header">
    <h1 class="form-header-below-link">Payment details</h1>
  </header>
  <div class="helper-card">
    <p class="p__bold">Wondering how we bill?</p>
    <p>
      We've got answers. Check out our
      <a
        href="https://attunehelp.zendesk.com/hc/en-us/sections/360001532851-Billing"
        target="_blank"
        >billing FAQ page</a
      >
      to learn more.
    </p>
  </div>
  <div class="bind-flow-subsection">
    <ng-container *ngIf="policyPaymentPresenter as policyPayment">
      <app-form-field-radio
        inputId="numberOfPayments"
        [form]="form"
        [submitted]="hasSubmitted()"
        questionText="Payment plan"
        [options]="paymentPlanOptions"
        cssClass="js-bind-payment-details"
        nameOfFormControl="numberOfPayments"
        [disabledOptions]="disabledPaymentPlanOptions"
      >
      </app-form-field-radio>
      <div *ngIf="policyPayment.installments > 0">
        <h5 class="payment-subtitle">Down Payment</h5>
        <dl class="dl__no-dotted-line">
          <dt>Premium</dt>
          <dd class="js-payment-details-downPayment">
            {{ policyPayment.downPaymentPremium | currency }}
          </dd>
        </dl>
        <dl class="dl__no-dotted-line">
          <dt>Tax</dt>
          <dd class="js-payment-details-downPayment-tax">
            {{ policyPayment.downPaymentTax | currency }}
          </dd>
        </dl>
        <dl class="dl__subtotal dl__no-dotted-line">
          <dt>Subtotal</dt>
          <dd>
            <strong class="js-payment-details-downPayment-subtotal">{{
              policyPayment.downPaymentSubtotal | currency
            }}</strong>
          </dd>
        </dl>
        <h5 class="payment-subtitle">Installments x {{ policyPayment.installments }}</h5>
        <dl class="dl__no-dotted-line">
          <dt>Installment Premium</dt>
          <dd class="js-payment-details-installment">
            {{ policyPayment.installmentPremium | currency }}
          </dd>
        </dl>
        <dl class="dl__subtotal dl__no-dotted-line">
          <dt>Subtotal</dt>
          <dd>
            <strong class="js-wc-payment-details-downPayment-subtotal">{{
              policyPayment.installmentPremium * policyPayment.installments | currency
            }}</strong>
          </dd>
        </dl>
      </div>
      <div *ngIf="policyPayment.installments === 0">
        <dl class="dl__no-dotted-line">
          <dt>Premium</dt>
          <dd class="js-payment-details-premium">
            {{ policyPayment.downPaymentPremium | currency }}
          </dd>
        </dl>
        <dl class="dl__no-dotted-line">
          <dt>Tax</dt>
          <dd class="js-payment-details-tax">{{ policyPayment.downPaymentTax | currency }}</dd>
        </dl>
      </div>
      <div class="bind-quote-total">
        <div class="bind-quote-total-lineitem">
          <strong>Estimated total cost*</strong>
          <div class="big-total js-payment-details-total">
            <span>$</span>{{ policyPayment.estimatedTotalIntegral | number: '2.0-0' }}.<span
              class="big-total-cents"
              >{{ policyPayment.estimatedTotalFractional | number: '2.0-0' }}</span
            >
          </div>
        </div>
        <small class="gray-text"
          >* Please refer to the quote letter for calculated taxes, fees, surcharges and payment
          plan installment fees which may be applicable to this quotation.</small
        >
      </div>
    </ng-container>
  </div>
</ng-container>
