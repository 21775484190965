import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

// TODO investigate usage in Attune WC and if it can be implemented differently / more dynamically
@Component({
  selector: 'app-number-of-locations',
  template: `
    <app-form-field-radio
      inputId="numberOfLocations"
      [form]="form"
      [submitted]="submitted"
      questionText="How many locations does this business have?"
      [options]="{ '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7+': 7 }"
      nameOfFormControl="numberOfLocations"
    ></app-form-field-radio>
  `,
})
export class NumberOfLocationsComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() currentNumberOfLocations: number;
  @Output() addLocations = new EventEmitter<number>();
  @Output() removeLocations = new EventEmitter<number>();

  private sub: Subscription = new Subscription();

  constructor() {}

  ngOnInit() {
    const formCtrl = <UntypedFormControl>this.form.get('numberOfLocations');
    this.sub.add(
      formCtrl.valueChanges.subscribe((numberOfLocations: number) => {
        this.handleChange(numberOfLocations);
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private handleChange(numberOfLocations: number) {
    if (numberOfLocations > this.currentNumberOfLocations) {
      this.addLocations.emit(numberOfLocations - this.currentNumberOfLocations);
    } else if (numberOfLocations < this.currentNumberOfLocations) {
      this.removeLocations.emit(this.currentNumberOfLocations - numberOfLocations);
    }
  }
}
