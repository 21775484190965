<div
  class="api-select {{ cssClass }} form-field"
  [formGroup]="form"
  [ngClass]="{
    'form-field__error': submitted && formControl.invalid
  }"
>
  <label [for]="inputId">
    {{ labelText }}
    <div
      *ngIf="tooltipText"
      class="more-info-tip tool-tip tool-tip__large"
      attr.data-tooltip="{{ tooltipText }}"
    >
      <span class="icon icon-question"></span>
    </div>
  </label>
  <div class="select-container">
    <select
      *ngIf="status === APIDataStatus.LOADED"
      [id]="inputId"
      [name]="nameOfFormControl"
      [formControlName]="nameOfFormControl"
    >
      <option value="" disabled>Please Select</option>
      <ng-container *ngIf="currency">
        <option *ngFor="let option of options" [value]="option">
          {{ option | currency: 'USD':'symbol-narrow':'1.0-0' }}
        </option>
      </ng-container>
      <ng-container *ngIf="!currency">
        <option *ngFor="let option of options" [value]="option.value">
          {{ option.description }}
        </option>
      </ng-container>
    </select>
    <select disabled="true" *ngIf="status !== APIDataStatus.LOADED"></select>
    <p *ngIf="status === APIDataStatus.UNREQUESTED" class="api-preload-status">
      Unavailable while editing a draft
    </p>
    <p *ngIf="status === APIDataStatus.LOADING" class="api-load-status">
      Loading options
      <app-loading-spinner></app-loading-spinner>
    </p>
    <p *ngIf="status === APIDataStatus.ERROR" class="api-error-status">Error loading options</p>
  </div>
</div>
