// Libraries
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormDslSearchService } from '../../services/form-dsl-search.service';
import {
  SearchFormatter,
  SearchQueryMethod,
  NodeArray,
  FormDslNode,
  ValidationMessageNode,
} from '../../constants/form-dsl-typings';
import { setNodeArrayInputIds } from '../../services/form-dsl-service-base';
import {
  getFormArray,
  getValidationMessageFromControl,
  getControlValidationMessageFromForm,
} from '../../../helpers/form-helpers';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

export const EXTRA_DATA_FIELD_NAME = 'extraData';

@Component({
  selector: 'app-form-dsl-form',
  templateUrl: './form-dsl-form.component.html',
})
export class FormDslFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() form: UntypedFormGroup;
  @Input() formTree: FormDslNode[];
  @Input() submitted: boolean;
  @Input() readonly = false;
  @Input() inputIdFormat: { prefix: string; index: number };
  @Input() extraData: Record<string, Partial<FormDslNode>> = {};
  @Output() buttonClick: EventEmitter<{ methodName: string; args: any[] }> = new EventEmitter();
  @Output()
  newAutoCompleteAddress: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter<google.maps.places.PlaceResult>();

  protected sub: Subscription = new Subscription();

  constructor(
    private formDslSearchService: FormDslSearchService,
    private sanitizer: DomSanitizer
  ) {}

  getFormArray = getFormArray;

  modifiedFormTree: FormDslNode[] | null = null;

  ngOnInit() {
    this.modifiedFormTree = this.formTree;
    if (this.inputIdFormat) {
      const { prefix, index } = this.inputIdFormat;
      this.modifiedFormTree = setNodeArrayInputIds(this.formTree, prefix, index);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formTree || changes.extraData || changes.inputIdFormat) {
      let indexedFormTree = this.formTree;
      if (this.inputIdFormat) {
        const { prefix, index } = this.inputIdFormat;
        indexedFormTree = setNodeArrayInputIds(this.formTree, prefix, index);
      }

      this.modifiedFormTree = indexedFormTree.map((node) => {
        if (node.dynamicSource && this.extraData && this.extraData[node.dynamicSource]) {
          return {
            ...node,
            ...this.extraData[node.dynamicSource],
          };
        } else {
          return node;
        }
      }) as FormDslNode[];
    }
  }

  getFormTree() {
    return this.modifiedFormTree;
  }

  ngAfterViewInit(): void {
    if (this.form) {
      this.formTree.forEach((node: FormDslNode) => {
        if (node.nameOfFormControl) {
          const control = this.form.get(node.nameOfFormControl);
          if (
            control &&
            !(node as any).errorText &&
            [
              'TEXT',
              'NUMBER',
              'PHONE',
              'MONEY_WITHOUT_DECIMAL',
              'MONEY_WITH_DECIMAL',
              'TEXT_AREA',
              'DATE',
            ].includes(node.primitive) // TODO: update type here when FormDsl primitive types are better defined
          ) {
            this.sub.add(
              control.statusChanges.subscribe((status: string) => {
                if (status === 'INVALID') {
                  const validationMessage =
                    getValidationMessageFromControl(control) ||
                    getControlValidationMessageFromForm(
                      this.form,
                      node.nameOfFormControl as string
                    );
                  if (validationMessage) {
                    (node as any).errorText = validationMessage;
                  }
                }
              })
            );
          }
        }
      });
    }
  }

  validationMessageErrors(validationNode: ValidationMessageNode) {
    const errorNames = _.isArray(validationNode.errorType)
      ? validationNode.errorType
      : [validationNode.errorType];
    const matchingErrors = _.pick(this.form.errors, errorNames);

    return Object.values(matchingErrors);
  }

  getQueryMethod(queryMethod: SearchQueryMethod) {
    return this.formDslSearchService[queryMethod];
  }

  getFormatterMethod(formatter: SearchFormatter) {
    return this.formDslSearchService[formatter];
  }

  handleButtonClick(methodName: string, ...args: any[]) {
    this.buttonClick.emit({ methodName, args });
  }

  shouldShowChildButtons(node: NodeArray) {
    return (
      !node.minChildrenToShowChildButtons ||
      node.children.length > node.minChildrenToShowChildButtons
    );
  }

  trustHtml(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
