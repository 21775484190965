import { Component, Input } from '@angular/core';
import { PolicyStatus } from '../../features/digital-carrier/models/types';

@Component({
  selector: 'app-policy-status',
  templateUrl: './policy-status.component.html',
})
export class PolicyStatusComponent {
  @Input() policyStatus: PolicyStatus = 'expired';

  mapStatusToStyle(status: string): string {
    status = status.toLowerCase();
    switch (status) {
      case 'declined':
      case 'canceled':
      case 'cancelled':
        return 'danger';
      case 'referred':
      case 'under_review':
      case 'expiring soon':
        return 'warning';
      case 'scheduled':
      case 'quoted':
      case 'bound':
      case 'bound_with_subjectivity':
        return 'success';
      case 'binding':
      case 'in_force':
      case 'in force':
        return 'main';
      case 'expired':
        return 'expired';
      case 'non-renewing':
      case 'non-renewed':
        return 'non-renewal';
      case 'not-taking':
      case 'not-taken':
        return 'not-taken';
      default:
        return 'neutral';
    }
  }

  mapStatus(status: string): string {
    return status.replace(/_/g, ' ');
  }
}
