import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LibertyMutualBindQuoteService } from 'app/features/liberty-mutual/services/liberty-mutual-bind-quote.service';

@Component({
  selector: 'app-liberty-mutual-bind-payment-details',
  templateUrl: './liberty-mutual-bind-payment-details.component.html',
})
export class LibertyMutualBindPaymentDetailsComponent implements OnInit {
  paymentIframeLoading = true;
  libertyMutualHydraUrl: SafeResourceUrl;

  constructor(
    private route: ActivatedRoute,
    private bindQuoteService: LibertyMutualBindQuoteService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.route.snapshot.parent) {
      this.makePaymentSetup();
    }
  }

  makePaymentSetup() {
    if (this.bindQuoteService.paymentSubject.value) {
      const libertyMutualHydraIframeRes = this.bindQuoteService.paymentSubject.value;
      const lmIframeUrl = libertyMutualHydraIframeRes.instrumentHostedPageUrl;
      this.libertyMutualHydraUrl = this.sanitizer.bypassSecurityTrustResourceUrl(lmIframeUrl);
      this.paymentIframeLoading = false;
    }
  }
}
