<div [hidden]="!targetFormControl.enabled">
  <label for="{{ inputId }}-input">
    {{ labelText }}
    <div
      *ngIf="tooltipText"
      class="more-info-tip tool-tip tool-tip__large"
      [attr.data-tooltip]="tooltipText"
    >
      <span class="icon icon-question"></span>
    </div>
  </label>
  <div class="form-field">
    <div
      id="{{ inputId }}"
      class="dropdown-search-container {{
        searchExpanded
          ? 'dropdown-search-container__expanded'
          : 'dropdown-search-container__collapsed'
      }}"
      [ngClass]="{ 'dropdown-search-container__readonly': readonly }"
    >
      <button
        class="dropdown-search-button {{
          searchExpanded ? 'dropdown-search-button__expanded' : 'dropdown-search-button__collapsed'
        }}"
        type="button"
        [ngClass]="{
          'dropdown-search__error': submitted && !targetFormControl.valid,
          button__disabled: readonly
        }"
        id="{{ inputId }}-toggle"
        attr.aria-expanded="{{ searchExpanded }}"
        attr.aria-controls="{{ inputId }}-search"
        (click)="expandSearch($event)"
        [disabled]="readonly"
      >
        <input
          id="{{ inputId }}-input"
          class="dropdown-search-disabled-input"
          [value]="currentInputValue"
          placeholder="{{ placeholderText }}"
          disabled
        />
      </button>
      <div
        [ngClass]="{ hidden: !searchExpanded }"
        id="{{ inputId }}-search"
        class="dropdown-search"
      >
        <div class="tool-tip tool-tip__static js-account-flag" *ngIf="showToolTip">
          Start typing to search
        </div>
        <span class="icon-magnifier-1 classification-search-icon"></span>
        <input
          #typeahead
          id="{{ inputId }}-search-typeahead"
          (selectItem)="handleSelect($event.item)"
          [ngbTypeahead]="search"
          (focus)="focus$.next($event.target.value)"
          (click)="click$.next($event.target.value)"
          popupClass="js-typeahead-popup-window"
          [resultTemplate]="resultTemplate"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [placeholder]="typeaheadPlaceholderText"
          [formControl]="typeaheadFormControl"
        />
        <div *ngIf="displayError" class="dropdown-menu">
          <div class="dropdown-item dropdown-item__no-result">Sorry! No matches found.</div>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <p class="form-field-note" *ngIf="questionNote" [innerHTML]="questionNote"></p>
  </div>
</div>
