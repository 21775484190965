<ng-template #classCodeResult let-code="result" let-term="term">
  <ngb-highlight
    class="wc-classification-code"
    [result]="code.classCode"
    [term]="term"
  ></ngb-highlight>
  <ngb-highlight
    class="wc-classification-description"
    [result]="code.description"
    [term]="term"
  ></ngb-highlight>
</ng-template>

<app-form-field-dropdown-search
  inputId="{{ prefix }}"
  labelText="Employee classification code"
  placeholderText="Choose a class"
  typeaheadPlaceholderText="Search classifications"
  [submitted]="submitted"
  [queryMethod]="searchCodes"
  [resultTemplate]="classCodeResult"
  [formatter]="mapCodeToLabel"
  (triggerAmplitudeOnClassCodeSelect)="triggerAmplitudeOnClassCodeSelect($event)"
  [targetFormControl]="classCode"
>
</app-form-field-dropdown-search>

<app-form-field-text
  inputId="{{ prefix }}-number-of-employees"
  [form]="classCode"
  [submitted]="submitted"
  labelText="Number of employees"
  nameOfFormControl="numberOfEmployees"
  appDelimitedNumberMask
></app-form-field-text>

<app-form-field-text
  inputId="{{ prefix }}-payroll"
  placeholder="$"
  [form]="classCode"
  [submitted]="submitted"
  labelText="Payroll"
  nameOfFormControl="payroll"
  appMoneyMask="WITHOUT_DECIMAL"
></app-form-field-text>

<div class="validation" role="alert" *ngIf="classCode.get('payroll')?.hasError('outOfRange')">
  <p class="validation-message">Payroll cannot be $0 when number of employees is greater than 0.</p>
</div>
