<div class="payment-receipt-container">
  <app-logo-with-text></app-logo-with-text>

  <h1>Payment Confirmation</h1>
  <p>
    Thank you for making your payment! We appreciate your business. Please consider this your
    official receipt. Below is a summary of your transaction.
  </p>

  <ng-container *ngIf="invoiceDetails && paymentInvoiceDetails">
    <!-- IMPORTANT: Do not remove the js-account-name-value class: service-document uses it to ensure that the data has loaded before rendering the pdf -->
    <p class="js-account-name-value js-account-details-section">
      <strong>Account Name:</strong> {{ invoiceDetails.accountName }}
      <br />
      <strong>Account Number:</strong> {{ invoiceDetails.accountNumber }}
      <br />
      <strong>Broker:</strong> {{ invoiceDetails.agentName }}
      <br />
      <strong>Invoice Number(s):</strong> {{ getInvoiceNumbers(paymentInvoiceDetails) }}
      <br />
      <strong>Autopay Status:</strong> {{ getAutopayStatus() }}
    </p>

    <section *ngFor="let invoiceDetail of paymentInvoiceDetails.invoiceDetails">
      <h3 class="invoice-table-header">
        <strong>Invoice No. </strong>{{ invoiceDetail.invoiceNumber }}
      </h3>
      <table>
        <tr>
          <th>Line of Business</th>
          <th>Policy Number</th>
          <th>Charge Type</th>
          <th class="align-right">Amount Applied</th>
        </tr>
        <tr *ngFor="let charge of invoiceDetail.invoiceCharges">
          <td class="js-line-of-business-cell">{{ charge.lineOfBusiness }}</td>
          <td class="js-policy-number-cell">{{ charge.policyNumber }}</td>
          <td class="js-charge-type-cell">{{ charge.invoiceChargeType }}</td>
          <td class="align-right js-invoice-amount-cell">
            {{ charge.invoiceItemAmountPaid | currency: '$':'symbol':'1.2-2' }}
          </td>
        </tr>
        <tr class="invoice-total-row">
          <td><strong>Invoice Total</strong></td>
          <td></td>
          <td></td>
          <td class="align-right js-invoice-total-cell">
            <strong>{{
              getInvoiceTotal(invoiceDetail.invoiceCharges) | currency: '$':'symbol':'1.2-2'
            }}</strong>
          </td>
        </tr>
      </table>
    </section>

    <section class="payment-stub-container">
      <div class="payment-total">
        <h4>Payment Total</h4>
        <h4>
          {{
            formatPaymentAmount(paymentInvoiceDetails.paymentAmount)
              | currency: '$':'symbol':'1.2-2'
          }}
        </h4>
      </div>

      <h2 class="payment-stub-header">Payment Stub</h2>
      <p>
        Your payment was successfully completed! Please retain this copy for your records.<br />
      </p>
      <p class="js-payment-stub-details">
        <strong>Payment Date:</strong>
        {{ getPaymentDate(paymentInvoiceDetails.paymentReceivedDate) }}
        <br />
        <strong>Payment Time:</strong>
        {{ getPaymentTime(paymentInvoiceDetails.paymentReceivedDate) }}
        <br />
        <strong>Amount:</strong>
        {{
          formatPaymentAmount(paymentInvoiceDetails.paymentAmount) | currency: '$':'symbol':'1.2-2'
        }}
        <br />
        <strong>Payment Method:</strong> {{ paymentInvoiceDetails.paymentMethod }} ending in
        {{ paymentInvoiceDetails.paymentInstrument }}
        <br />
        <strong>Invoice Number(s):</strong> {{ getInvoiceNumbers(paymentInvoiceDetails) }}
      </p>
    </section>
  </ng-container>
</div>
