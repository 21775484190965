import { Component, isDevMode, Input, EventEmitter, Output } from '@angular/core';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  RouteFormStep,
  FormDslSteppedFormBaseService,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { environment } from 'environments/environment';

const bopV2LaunchDates: { [key: string]: string } = environment.bopV2LaunchDates || {};

// TODO investigate usage, remove if unused
@Component({
  selector: 'app-quote-stepped-sidebar',
  templateUrl: './quote-stepped-sidebar.component.html',
})
export class QuoteSteppedSidebarComponent {
  @Input() fillInHappyPath: Function;
  @Input() fillInIneligibleLocation: Function;
  @Input() accountId: string;
  @Input() insAccount: InsuredAccount;
  @Input() steppedFormService: FormDslSteppedFormBaseService;
  @Input() product: string;
  @Input() pasSource = '';
  @Input() sidebarTitle: string;
  @Input() disableNavigationToStep: boolean;
  @Input() showCancelButton = true;
  @Output() navigateToStep: EventEmitter<string> = new EventEmitter();

  isDevMode = isDevMode();

  isSandbox = environment.isSandboxEnv || false;

  constructor(private amplitudeService: AmplitudeService) {}

  debugGCI() {
    return this.amplitudeService.debugUserId();
  }

  debugGP() {
    return JSON.stringify(this.amplitudeService.debugUser());
  }

  bopVersion(): number | null {
    const control = this.steppedFormService
      ? this.steppedFormService.get('policyInfo.bopVersion')
      : null;
    return control ? control.value : null;
  }

  meToo(): boolean | null {
    const control = this.steppedFormService
      ? this.steppedFormService.get('policyInfo.meToo')
      : null;
    return control ? control.value : null;
  }

  bopState(): { state: string; date: string | null } | null {
    const control = this.steppedFormService
      ? this.steppedFormService.get('policyInfo.baseState')
      : null;
    const state = control ? control.value : null;
    return { state: state, date: bopV2LaunchDates[state] };
  }

  debugSaveForm() {
    this.steppedFormService.debugSaveFormData();
  }

  debugLoadForm() {
    this.steppedFormService.debugLoadFormData();
    // After loading the form state - trigger a navigation to the current step.
    this.navigateToStep.emit(this.steppedFormService.currentStep.slug);
  }

  ineligibleLocationHandler() {
    this.fillInIneligibleLocation();
    this.steppedFormService.stepForward();
  }

  happyPathHandler() {
    this.fillInHappyPath();
  }

  southCarolinaPathHandler() {
    this.fillInHappyPath('SC');
  }

  isCurrentStep(step: RouteFormStep) {
    return step.slug === this.steppedFormService.currentStep.slug;
  }

  // isNavigable(step: RouteFormStep) {
  //   return true;
  // }

  handleStepClicked(step: RouteFormStep) {
    this.navigateToStep.emit(step.slug);
  }

  getSteps() {
    return this.steppedFormService.getSteps();
  }

  async shortcutHandler() {
    console.log('now in shortcutHandler');
    const stateInfo = this.bopState();
    this.fillInHappyPath(stateInfo ? stateInfo.state : 'KY', false);
    this.steppedFormService.stepForward();
    await new Promise((r) => setTimeout(r, 500));
    this.steppedFormService.stepForward();
    await new Promise((r) => setTimeout(r, 500));
    this.steppedFormService.stepForward();
    // longer timeout for location lookup. Note that this may not always be long enough.
    await new Promise((r) => setTimeout(r, 5000));
    // redundantly fill in building info, which is sometimes replaced by autofill stuff.
    this.fillInHappyPath(stateInfo ? stateInfo.state : 'KY', false);
    this.steppedFormService.stepForward();
    await new Promise((r) => setTimeout(r, 500));
    this.steppedFormService.stepForward();
    await new Promise((r) => setTimeout(r, 500));
    this.steppedFormService.stepForward();
    await new Promise((r) => setTimeout(r, 500));
    this.steppedFormService.stepForward();
    await new Promise((r) => setTimeout(r, 500));
    this.steppedFormService.stepForward();
    await new Promise((r) => setTimeout(r, 500));
    this.steppedFormService.stepForward();
  }
}
