import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { shouldShowInvalid } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-eligibility-uw-questions',
  templateUrl: './attune-bop-questions-uw-eligibility.component.html',
})
export class AttuneBopQuestionsUwEligibilityComponent {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;

  fieldInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.form, this.submitted);
  }
}
