import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'environments/environment';
import { CURRENT_GIT_SHA } from 'app/constants';

export const initDatadogRum = () => {
  datadogRum.init({
    applicationId: '67a8d499-17cf-41f3-a25e-4d1beb7970bf',
    clientToken: 'pub3f4dc84b71d83989e614549b67c0fa33',
    site: 'datadoghq.com',
    service: 'agent-portal',
    env: environment.stage,
    version: CURRENT_GIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    workerUrl: '/assets/scripts/dd-worker.js',
    defaultPrivacyLevel: 'allow',
    beforeSend: (event, context) => {
      // Remove query parameters from the URL, as they may include private information (esp. for billing)
      const originalUrl = event.view.url;
      if (originalUrl.includes('?')) {
        event.view.url = originalUrl.split('?')[0];
      }

      // Add extra tracking for requests that are to our APIs, excluding authentication APIs
      if (
        event?.resource &&
        ((event.resource as any).url.startsWith(environment.apiV3Host) ||
          (event.resource as any).url.startsWith(environment.apiV4Host)) &&
        !(event.resource as any).url.includes('auth')
      ) {
        if ((context as any)?.xhr?.response) {
          event.response = (context as any).xhr.response;
        }
      }

      if (event.view.url_query) {
        event.view.url_query = '';
      }

      return true;
    },
    allowedTracingUrls: [
      (url) => url.startsWith(environment.apiV3Host) || url.startsWith(environment.apiV4Host),
    ],
  });
  datadogRum.startSessionReplayRecording();
};
