<app-modal [open]="open" disableClose="true">
  <header class="js-sample-quote-modal app-modal-header">
    <div class="modal-image">
      <img src="assets/img/sample-quote-icon.svg" alt="" role="presentation" />
    </div>
    <h1 class="h2">Why not try a sample quote?</h1>
  </header>
  <p class="p p__big-margin">
    It will just take a few clicks and will let you get a sense of how easy it is to fill out our
    application. You can get right back to quoting after that.
  </p>
  <div class="nav-button-group nav-button-group__centered-horizontal">
    <button class="button button__secondary button__no-margin" (click)="goToSampleQuote(false)">
      No thanks
    </button>
    <button class="button button__primary button__no-margin" (click)="goToSampleQuote(true)">
      Try a sample quote
    </button>
  </div>
</app-modal>
