import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { isNil } from 'lodash';
import { UWQuestion } from 'app/features/attune-bop/models/constants';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-uw-questions',
  templateUrl: './uw-questions.component.html',
})
export class UWQuestionsComponent implements OnInit {
  @Input() uwQuestionsFormGroup: UntypedFormGroup;
  @Input() uwQuestions: UWQuestion[] = [];
  @Output() answersAreValid = new EventEmitter<boolean>();

  ngOnInit() {
    const desiredQuestionIds = this.uwQuestions.map((q) => q.questionID);
    Object.keys(this.uwQuestionsFormGroup.controls)
      .filter((ctrlName) => !desiredQuestionIds.includes(ctrlName))
      .forEach((ctrlName) => this.uwQuestionsFormGroup.removeControl(ctrlName));

    this.answersAreValid.emit(this.areTheAnswersValid());

    this.uwQuestionsFormGroup.valueChanges.subscribe(() => {
      this.answersAreValid.emit(this.areTheAnswersValid());
    });
  }

  questionResult(q: UWQuestion): boolean | null {
    let correctAnswer: boolean;
    const questionControl = this.uwQuestionsFormGroup.controls[q.questionID];

    if (isNil(questionControl) || questionControl.value === null) {
      return null;
    }

    if (q.answerToDecline !== undefined) {
      // Answer to decline is what fails the test. This converts to what the "correct" answer is for this question
      correctAnswer = q.answerToDecline === 'Yes' ? false : true;
      return questionControl.value === correctAnswer;
    }
    return true;
  }

  areTheAnswersValid() {
    return this.uwQuestions.every((q) => this.questionResult(q) === true);
  }

  showErrorMessage() {
    return this.uwQuestions.map((q) => this.questionResult(q)).includes(false);
  }
}
