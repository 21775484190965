import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray,
} from '@angular/forms';
import { get, keys, pullAt } from 'lodash';
import { startWith, map } from 'rxjs/operators';
import { of, combineLatest, Subscription } from 'rxjs';
import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';
import { getControl, enableDisableControl, getFormArray } from 'app/shared/helpers/form-helpers';
import { SentryService } from 'app/core/services/sentry.service';
import { EVALUATED_VALUES } from 'app/features/liberty-mutual/models/constants';
import { evaluateSetCondition } from 'app/features/liberty-mutual/models/helpers';
import {
  DependencyOperand,
  SimpleDependency,
  ClassAndStateDependency,
  DependencySource,
  ControlDependency,
  DependencyCallback,
  FormValue,
  DependencySubscription,
  FormDslConfiguration,
  LibertyMutualClassCode,
  DependencyOperator,
  SomeClassAndStateDependency,
  DynamicFormDslConfiguration,
  DependencyConfig,
  ComplexEvaluatorFunc,
  LmQuoteFlowStep,
} from 'app/features/liberty-mutual/models/common-typings';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';
import {
  FormDslNode,
  FormDslStep,
  NodeArray,
  DropdownSearchNode,
  HappyPathFormData,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { formTreeToFormGroup } from 'app/shared/form-dsl/services/form-dsl-service-base';

@Injectable({
  providedIn: 'root',
})
export class LibertyMutualBaseQuoteFormService<
    TFormStepPath extends string, // enum with each form step's form path
    TQuestion extends string, // enum with the names of each top-level form control
    TNestedQuestion extends string, // enum with the names of each nested form control (e.g. loss group controls)
    TComplexEvaluator extends string // enum with the names of the complex dependency evaluator functions
  >
  extends FormDslSteppedFormService
  implements OnDestroy
{
  protected sub = new Subscription();
  defaultFormStep = 'guidelines';
  happyPathFormData: HappyPathFormData<TFormStepPath, TQuestion>;

  // Form steps
  formSteps: LmQuoteFlowStep<TFormStepPath, TQuestion>[];

  // Dependencies dictionaries
  simpleDependencies: Record<TQuestion, DependencyConfig<TQuestion, TComplexEvaluator>>;
  complexDependencies: Record<TComplexEvaluator, ComplexEvaluatorFunc>;
  nestedControlDependencies: Record<
    TNestedQuestion,
    DependencyConfig<TQuestion, TComplexEvaluator>
  >;
  setDependencies = new Set<TFormStepPath>();

  // Names of key controls
  CLASS_CODE_CONTROL_NAME: TQuestion;
  PRIMARY_RISK_STATE_CONTROL_NAME: TQuestion;
  LOSSES_CONTROL_NAME: TQuestion;

  // Functions for retrieving from data structures
  getControlPathFunc: (controlName: TQuestion) => string;
  getNodeFunc: (controlName: TQuestion) => FormDslNode;
  getNodeConfigFunc: (controlName: TQuestion) => FormDslConfiguration;
  lossGroupNodesFunc: () => FormDslNode[];

  constructor(public formBuilder: UntypedFormBuilder, private sentryService: SentryService) {
    super(formBuilder);
  }

  public destroy() {
    this.sub.unsubscribe();
  }

  /**
   *  For the LM quote flow, all dependent controls are "opt-in", meaning they are disabled
   *  by default, and only become enabled if the dependency control A) is present in the form, and
   *  B) has an appropriate value.
   *
   *  Note: This method overrides the `formDependenciesInit` from the form-dsl-service-base
   */
  formDependenciesInit(currentFormPath: TFormStepPath): void {}

  protected setDependenciesForFormGroup(
    formGroup: UntypedFormGroup,
    dependenciesDict: Record<string, DependencyConfig<TQuestion, TComplexEvaluator>>,
    formArrayIndex?: number
  ) {
    const classCode = getControl(this.form, this.getControlPathFunc(this.CLASS_CODE_CONTROL_NAME));
    const state = getControl(
      this.form,
      this.getControlPathFunc(this.PRIMARY_RISK_STATE_CONTROL_NAME)
    );

    keys(formGroup.controls).forEach((controlName: TQuestion) => {
      const control: UntypedFormControl | UntypedFormArray = getControl(formGroup, controlName);
      if (control instanceof UntypedFormArray) {
        control.controls.forEach((childFormGroup: UntypedFormGroup, formArrayIdx: number) => {
          this.setDependenciesForFormGroup(
            childFormGroup,
            this.nestedControlDependencies,
            formArrayIdx
          );
        });
      }
      const fieldDisplay = dependenciesDict[controlName];

      this.addDependencies({
        controlName,
        control,
        fieldDisplay,
        classCode,
        state,
        formArrayIndex,
      });
    });
  }

  public generateFormSteps(
    productAvailabilityLoaded: boolean,
    classCodeAllowList?: string[]
  ): FormDslStep[] {
    return this.formSteps.map((step, idx): FormDslStep => {
      const nodes: FormDslNode[] = [];
      step.questions.forEach((question) => {
        if (Array.isArray(question)) {
          const children: FormDslNode[] = [];

          question.forEach((q) => {
            const node = this.getNodeFunc(q);
            if (node) {
              children.push(node);
            }
          });

          nodes.push({
            primitive: 'DIV',
            cssClass: 'form-subsection',
            children,
          });
        } else {
          let node = this.getNodeFunc(question);

          if (node !== null && node.nameOfFormControl === this.CLASS_CODE_CONTROL_NAME) {
            node = node as DropdownSearchNode;
            node.queryableResults = classCodeAllowList;

            // TODO: Add more UI affordances to loading state
            if (productAvailabilityLoaded === false) {
              node.readonly = true;
              node.searchExpanded = false;
            } else {
              node.readonly = false;
              node.searchExpanded = true;
            }
          }

          if (node) {
            nodes.push(node);
          }
        }
      });

      return {
        args: {},
        displayName: step.title,
        slug: step.slug,
        parent: step.parent,
        formPath: step.formPath,
        getFormTree: () => {
          // State-dependent questions can't appear on the first step
          if (idx === 0) {
            return of(nodes);
          }

          return of(this.generateQuestionsByBaseState(nodes, step.title));
        },
      };
    });
  }

  protected addDependencies({
    controlName,
    control,
    fieldDisplay,
    classCode,
    state,
    formArrayIndex,
  }: {
    controlName: TQuestion | TNestedQuestion; // Used just for better logging
    control: UntypedFormControl;
    fieldDisplay: {
      classCode: ClassAndStateDependency;
      state: ClassAndStateDependency;
      dependency?: ControlDependency<TQuestion, TComplexEvaluator>;
    };
    classCode: UntypedFormControl;
    state: UntypedFormControl;
    formArrayIndex?: number;
  }) {
    const fieldDependencies: DependencySubscription[] = [];
    if (fieldDisplay.classCode.display !== 'ALL') {
      const callback = this.getClassOrStateCallback(fieldDisplay.classCode);
      const dependsOn = classCode.valueChanges.pipe(
        startWith(classCode.value),
        map((classCodeObj: LibertyMutualClassCode) => (classCodeObj ? classCodeObj.code : null))
      );

      fieldDependencies.push({ dependsOn, callback });
    }

    if (fieldDisplay.state.display !== 'ALL') {
      const callback = this.getClassOrStateCallback(fieldDisplay.state);
      const dependsOn = state.valueChanges.pipe(startWith(state.value));
      fieldDependencies.push({ dependsOn, callback });
    }

    if (fieldDisplay.dependency) {
      const dependencySetup = this.getControlDependencyCallback(
        controlName,
        fieldDisplay.dependency,
        formArrayIndex
      );

      if (dependencySetup) {
        fieldDependencies.push({
          dependsOn: dependencySetup.dependsOn,
          callback: dependencySetup.callback,
        });
      }
    }

    // Don't proceed if there are no dependencies that affect the control's display
    if (fieldDependencies.length === 0) {
      return;
    }

    // Disable control by default
    control.disable();

    // If there's only one dependency, handle it as single subscription
    if (fieldDependencies.length === 1) {
      const { dependsOn, callback } = fieldDependencies[0];
      this.sub.add(
        dependsOn.subscribe((value) => {
          const shouldEnable = callback(value);
          enableDisableControl(control, shouldEnable);
        })
      );

      return;
    }

    // If there are multiple dependencies, handle them in a combined observable stream

    // 1. Produce a list of valueChange observables for all dependent fields
    const dependsOnList$ = fieldDependencies.map(({ dependsOn }) => dependsOn);

    this.sub.add(
      combineLatest(dependsOnList$).subscribe((dependsOnValues: (string | number | null)[]) => {
        // 2. Evaluate each callback calculation to determine if a field should be enabled
        const shouldEnableList: boolean[] = dependsOnValues.map((value, idx) => {
          // Look up the corresponding callback in the dependencies list
          const { callback } = fieldDependencies[idx];
          return callback(value);
        });

        // Calculate final "enable" status based on whether all evaluations are true
        // ie, if any calculations say that a field should not be enabled, it will not be enabled
        const shouldEnable = shouldEnableList.every((value) => value === true);

        // 3. Enable or disable the field
        enableDisableControl(control, shouldEnable);
      })
    );
  }

  private getClassOrStateCallback({
    display,
    values,
  }: SomeClassAndStateDependency): DependencyCallback {
    return (value: FormValue) => {
      const controlShouldEnable = evaluateSetCondition(value, display, values);
      return controlShouldEnable;
    };
  }

  private getControlDependencyCallback(
    controlName: string,
    dependency: ControlDependency<TQuestion, TComplexEvaluator>,
    formArrayIndex?: number
  ): DependencySubscription | undefined {
    // For controls that are dependent on multiple other controls, we use
    // manually configured functions to set up value change subscriptions.
    if (dependency.type === 'COMPLEX') {
      const evalFunc = this.complexDependencies[dependency.functionName];

      let depSub;
      try {
        depSub = evalFunc(this.form, formArrayIndex);
      } catch (error) {
        if (isDevMode()) {
          console.error(
            `Error thrown setting complex dependency subscription for function ${dependency.functionName}: ${error}`
          );
        }
      }
      if (!depSub) {
        this.sentryService.notify(
          'Liberty Mutual: One or more controls not found when setting complex dependency',
          {
            severity: 'error',
            metaData: {
              dependentControl: controlName,
              dependencyFunction: dependency.functionName,
            },
          }
        );
        return;
      }
      return depSub;
    }

    const dependencySource = getControl(this.form, this.getControlPathFunc(dependency.left.value));

    const callback = () => {
      // Only enable the field if dependency is enabled and evaluation is true
      const shouldEnable = dependencySource.enabled && this.evaluate(dependency);
      return shouldEnable;
    };

    return {
      dependsOn: dependencySource.valueChanges.pipe(startWith(dependencySource.value)),
      callback,
    };
  }

  private getOperandValue(op: DependencyOperand<TQuestion>): string | number | boolean | null {
    switch (op.source) {
      case DependencySource.ID:
        return getControl(this.form, this.getControlPathFunc(op.value)).value;
      case DependencySource.VALUE:
        return op.value;
      case DependencySource.CODE:
        return EVALUATED_VALUES[op.value];
    }
  }

  private evaluate(dep: SimpleDependency<TQuestion>): boolean {
    const leftVal = this.getOperandValue(dep.left);
    const rightVal = this.getOperandValue(dep.right);

    switch (dep.operator) {
      case DependencyOperator.EQUAL:
        return leftVal === rightVal;
      case DependencyOperator.LESS_THAN:
        return parseMoney(leftVal as string | number) < parseMoney(rightVal as string | number);
      case DependencyOperator.GREATER_THAN:
        return parseMoney(leftVal as string | number) > parseMoney(rightVal as string | number);
    }
  }

  private generateQuestionsByBaseState(nodes: FormDslNode[], stepTitle: string): FormDslNode[] {
    // Fetch the state control
    const baseState = getControl(
      this.form,
      this.getControlPathFunc(this.PRIMARY_RISK_STATE_CONTROL_NAME)
    );

    // If no base state is available, return the original nodes
    if (!baseState || baseState.value === null) {
      this.sentryService.notify(
        `Liberty Mutual: No base state information available for step ${stepTitle}`,
        {
          severity: 'error',
        }
      );

      return nodes;
    }

    const nodesWithStateDependentValues = nodes.map((node) => {
      const inputId: TQuestion = get(node, 'inputId', undefined);
      if (node.primitive === 'DIV') {
        return { ...node, children: this.generateQuestionsByBaseState(node.children, stepTitle) };
      }
      if (!inputId) {
        return node;
      }

      // TODO: Consider resetting the current value of the control if a new set of values is calculated
      const nodeConfigWithValues = this.getNodeConfigFunc(inputId);
      return this.getNodeWithValues(nodeConfigWithValues, { baseState: String(baseState.value) });
    });

    return nodesWithStateDependentValues;
  }

  private getNodeWithValues(
    fieldData: FormDslConfiguration,
    dataSource: { baseState: string }
  ): FormDslNode {
    if (fieldData.valueType === 'DYNAMIC_CONFIG') {
      const dynamicValues = this.selectDynamicValues(fieldData, dataSource);
      // Note/TODO: Caution about this casting! Typescript is not happy with the combination of a full type + partial type.
      // It feels safe to cast this value as it is now, but if logic changes upstream, a casting could introduce bugs.
      const formDslNode = {
        ...fieldData.formDslNode,
        ...dynamicValues,
      } as FormDslNode;

      return formDslNode;
    } else {
      // STATIC case
      return fieldData.formDslNode;
    }
  }

  private selectDynamicValues(
    fieldData: DynamicFormDslConfiguration,
    dataSource: { baseState: string }
  ): Partial<FormDslNode> {
    // For now, all value calculations rely on a `state` property only
    // Support can be added in the future for other property calculations!
    const stateValueData = fieldData.valueData.find((valueDataItem) => {
      if (valueDataItem.state) {
        if (valueDataItem.state.display === 'ALL') {
          return true;
        }
        return evaluateSetCondition(
          dataSource.baseState,
          valueDataItem.state.display,
          valueDataItem.state.values
        );
      }
    });

    return stateValueData ? stateValueData.nodeValue : {};
  }

  addLossGroup() {
    // Add to form
    const lossesArray = getFormArray(this.form, this.getControlPathFunc(this.LOSSES_CONTROL_NAME));
    const lossGroupConfig = formTreeToFormGroup(this.lossGroupNodesFunc(), this.formBuilder);
    const newLossGroup = this.formBuilder.group(lossGroupConfig);
    lossesArray.push(newLossGroup);
    const currentArrayIndex = lossesArray.length - 1;
    // Set dependencies and validators
    this.setDependenciesForFormGroup(
      newLossGroup,
      this.nestedControlDependencies,
      currentArrayIndex
    );
    this.setFormArrayValidators(this.LOSSES_CONTROL_NAME, newLossGroup);
    // Add to DOM
    const lossesNode = this.formTree.find(
      (node) => node.nameOfFormControl === this.LOSSES_CONTROL_NAME
    ) as NodeArray;
    lossesNode.children.push(this.lossGroupNodesFunc());
  }

  removeLossGroup(idx: number) {
    // Remove from DOM
    const lossesNode = this.formTree.find(
      (node) => node.nameOfFormControl === this.LOSSES_CONTROL_NAME
    ) as NodeArray;
    pullAt(lossesNode.children, idx);
    // Remove from form
    const lossesArray = getFormArray(this.form, this.getControlPathFunc(this.LOSSES_CONTROL_NAME));
    lossesArray.removeAt(idx);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
