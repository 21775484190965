<ng-container *ngIf="!loading">
  <h1>Financial Info</h1>

  <div class="gray-text" *ngIf="producerDetails">
    <p>Here you can manage the financial information for {{ getAgencyName() }}.</p>
    <p>
      This includes banking credentials, tax information, and any additional data required for
      receiving commission payments.
    </p>
    <p>
      Note: All commission generated by your agency will go to your agency's producer code,
      {{ getProducerCode() }}.
    </p>
  </div>

  <iframe
    [ngStyle]="{ width: '800px', height: '1200px' }"
    [src]="tipaltiUrlMain"
    class="e2e-iframe-trusted-src"
    frameborder="0"
    width="600"
    height="600"
    allowfullscreen
  >
  </iframe>
</ng-container>
