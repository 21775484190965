<header class="bind-quote-header">
  <h1>Payment Plan</h1>
</header>

<form [formGroup]="formService.form" (ngSubmit)="handleSubmit($event)" novalidate>
  <main *ngIf="loadingAccountDetails" class="placeholder-form-container">
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-submit"></div>
  </main>

  <ng-container *ngIf="loadingAccountDetails">
    <app-loading-spinner></app-loading-spinner>
    <span class="gray-text pl-2">Loading account details...</span>
    <br />
  </ng-container>
  <div *ngIf="!loadingAccountDetails" class="bind-quote-main">
    <div
      class="bind-flow-subsection js-hiscox-bind-insured-details"
      *ngIf="insuredAccount | async as insuredAccount"
    >
      <h3 class="question-subsection-header">Insured details</h3>
      <dl>
        <dt>Legal business name</dt>
        <dd>{{ insuredAccount.companyName }}</dd>
      </dl>
      <dl>
        <dt>Mailing address</dt>
        <dd>
          {{ insuredAccount.addressLine1 }},{{ insuredAccount.addressLine2 }}
          {{ insuredAccount.city }},
          {{ insuredAccount.state }}
          {{ insuredAccount.zip }}
        </dd>
      </dl>
    </div>

    <!-- Payment options -->
    <div class="bind-flow-subsection helper-card-container">
      <div class="mb-2" *ngIf="loadingQuoteDetails">
        <app-loading-spinner></app-loading-spinner>
        <span class="gray-text pl-2">Loading quote details... This may take a moment.</span>
        <br />
      </div>
      <ng-container *ngIf="!loadingQuoteDetails">
        <ng-container>
          <app-form-field-radio
            inputId="hiscox-bind-numberOfPayments"
            [form]="formService.form.get('paymentPlan')"
            [submitted]="submitted()"
            questionText="Payment plan"
            [options]="paymentPlanOptions"
            nameOfFormControl="numberOfPayments"
          >
          </app-form-field-radio>
        </ng-container>

        <ng-container *ngIf="hiscoxQuote">
          <!-- Monthly -->
          <div *ngIf="_numberOfPayments > 1">
            <h5 class="payment-subtitle">Downpayment</h5>
            <dl class="dl__no-dotted-line">
              <dt>Premium</dt>
              <dd class="js-hiscox-payment-details-downPayment">
                {{ hiscoxQuote.downpayment | currency }}
              </dd>
            </dl>
            <dl class="dl__subtotal dl__no-dotted-line">
              <dt>Subtotal</dt>
              <dd>
                <strong class="js-hiscox-payment-details-downPayment-subtotal">{{
                  hiscoxQuote.downpayment | currency
                }}</strong>
              </dd>
            </dl>
            <h5 class="payment-subtitle">Installments x {{ installments }}</h5>
            <dl class="dl__no-dotted-line">
              <dt>Installment Premium</dt>
              <dd class="js-hiscox-payment-details-installment">
                {{ hiscoxQuote.monthly | currency }}
              </dd>
            </dl>
            <dl class="dl__subtotal dl__no-dotted-line">
              <dt>Subtotal</dt>
              <dd>
                <strong class="js-hiscox-payment-details-downPayment-subtotal">{{
                  hiscoxQuote.monthly * installments | currency
                }}</strong>
              </dd>
            </dl>
          </div>

          <!-- Annually -->
          <div *ngIf="_numberOfPayments === 1">
            <dl class="dl__no-dotted-line">
              <dt>Premium</dt>
              <dd class="js-hiscox-payment-details-premium">
                {{ hiscoxQuote.premium | currency }}
              </dd>
            </dl>
          </div>

          <!-- Total -->
          <div class="bind-quote-total">
            <div class="bind-quote-total-lineitem">
              <strong>Estimated total cost*</strong>
              <div class="big-total js-hiscox-payment-details-total">
                <span>$</span>
                {{ estimatedTotalIntegral | number: '2.0-0' }}.
                <span class="big-total-cents">{{
                  estimatedTotalFractional | number: '2.0-0'
                }}</span>
              </div>
            </div>
            <small *ngIf="hasStateSurcharges()" class="gray-text"
              >* Includes {{ hiscoxQuote.surcharge | currency }}/year of state required
              surcharges.</small
            >
          </div>

          <!-- Questions -->
          <!-- InformationConfirmAgreement -->
          <h3 class="h3">Please agree to the following to bind:</h3>
          <app-form-field-radio
            inputId="hx-bind-accepted"
            [form]="formService.form.get('paymentPlan')"
            nameOfFormControl="hx-bind-accepted"
            questionText=" I have read the information above and confirm it is correct. By accepting this statement and providing the premium payment I agree that I am entering into a binding agreement with Hiscox Insurance Company Inc."
            [options]="agreeDisagree"
          >
          </app-form-field-radio>
          <app-form-field-radio
            inputId="hx-electronic-info-accepted"
            [form]="formService.form.get('paymentPlan')"
            nameOfFormControl="hx-electronic-info-accepted"
            questionText="I agree to accept delivery of the insurance policy and related documents via email to the address provided to Hiscox and agree to consent in electronic transactions."
            [options]="agreeDisagree"
          >
          </app-form-field-radio>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
