import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { validateDate, validateEmailAddress } from 'app/features/attune-bop/models/form-validators';
import {
  getControl,
  maxDateExceededValidator,
  minDateExceededValidator,
  getFormGroup,
  validatorWithMessage,
} from '../../../shared/helpers/form-helpers';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import * as moment from 'moment';
import {
  ATTESTATION_FORM_BY_STATE,
  CYBER_MAX_ALLOWED_BIND_DATE_OFFSET_FUTURE,
} from '../models/cyber-constants.model';
import { range } from 'lodash';
import { AttestationForm } from '../models/cyber-typings.model';
import { US_DATE_MASK } from '../../../constants';
import {
  getServicingInfoDoNotShowAgainPreference,
  updateServicingDoNotShowAgainPreference,
} from '../../../shared/carrier-partner-servicing-info/helpers';

@Injectable({
  providedIn: 'root',
})
export class CoalitionCyberBindFormService extends FormDslSteppedFormBaseService {
  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.initializeForm();
  }
  // MAX_ALLOWED_DATE_OFFSET_FUTURE is the maximum number of days the effective date can be changed to be in the future
  private static MAX_ALLOWED_DATE_OFFSET_FUTURE = CYBER_MAX_ALLOWED_BIND_DATE_OFFSET_FUTURE;
  // MAX_ALLOWED_DATE_OFFSET_PAST is the maximum number of days the effective date can be changed to be in the past
  private static MAX_ALLOWED_DATE_OFFSET_PAST = 14;

  attestationForm: AttestationForm;
  form: UntypedFormGroup;

  fillInHappyPath() {}

  initializeForm() {
    this.form = this.formBuilder.group({
      workingWithCoalition: this.formBuilder.group({
        doNotShowAgain: [null],
      }),
      bind: this.formBuilder.group({
        // Validators are set after quote is fetched, in `setEffectiveDateValidators`
        effectiveDate: [null],
        insuredEmail: [null, [Validators.required, validateEmailAddress]],
      }),
    });
  }

  insertContactInformation() {
    const bindGroup = getFormGroup(this.form, 'bind');
    bindGroup.addControl('insuredFirstName', this.formBuilder.control('', [Validators.required]));
    bindGroup.addControl('insuredLastName', this.formBuilder.control('', [Validators.required]));
  }

  insertAttestation(state: string) {
    this.attestationForm = ATTESTATION_FORM_BY_STATE[state];
    if (!this.attestationForm) {
      return;
    }
    this.form.addControl(
      'declinationAttestation',
      this.formBuilder.group({
        attestation: this.formBuilder.control(false, Validators.requiredTrue),
        carriers: this.formBuilder.array(
          range(this.attestationForm.requiredCarriers).map((a) => {
            const config: Record<string, UntypedFormControl> = {};
            this.attestationForm.simpleTextQuestions.forEach((question) => {
              config[question.id] = this.formBuilder.control('', Validators.required);
            });
            if (this.attestationForm.carrierQuestions.date) {
              config['date'] = this.formBuilder.control('', [Validators.required, validateDate]);
            }
            if (this.attestationForm.carrierQuestions.reason) {
              config['reason'] = this.formBuilder.control('', Validators.required);
            }
            if (this.attestationForm.carrierQuestions.resultCode) {
              config['resultCode'] = this.formBuilder.control('', Validators.required);
            }
            if (this.attestationForm.carrierQuestions.declinationReason) {
              config['declinationReason'] = this.formBuilder.control('', Validators.required);
            }
            return this.formBuilder.group(config);
          })
        ),
      })
    );
    this.resetToFirstStep();
  }

  insertTexasLicenseNumberQuestion() {
    const bindGroup = getFormGroup(this.form, 'bind');
    bindGroup.addControl('licenseNumber', this.formBuilder.control([null], [Validators.required]));
  }

  generateSteps() {
    const steps: RouteFormStep[] = [];

    const workingWithCoalitionStep: RouteFormStep = {
      args: {},
      displayName: 'Working with Coalition',
      formPath: 'workingWithCoalition',
      parent: 'working-with-coalition',
      slug: 'working-with-coalition',
    };

    const attestationStep: RouteFormStep = {
      args: {},
      displayName: 'Attestation',
      formPath: 'declinationAttestation',
      parent: '',
      slug: 'declinationAttestation',
    };

    const bindStep: RouteFormStep = {
      args: {},
      displayName: 'Bind',
      formPath: 'bind',
      parent: '',
      slug: 'bind',
      nextButtonText: 'Submit',
    };

    if (!getServicingInfoDoNotShowAgainPreference('coalition')) {
      steps.push(workingWithCoalitionStep);
    }
    if (this.attestationForm) {
      steps.push(attestationStep);
    }
    steps.push(bindStep);

    return steps;
  }

  isValid() {
    return !this.form.invalid;
  }

  getInsuredEmail() {
    return getControl(this.form, 'bind.insuredEmail').value;
  }

  getInsuredFirstName() {
    return getControl(this.form, 'bind.insuredFirstName').value;
  }

  getInsuredLastName() {
    return getControl(this.form, 'bind.insuredLastName').value;
  }

  getEffectiveDate() {
    return getControl(this.form, 'bind.effectiveDate').value;
  }

  setEffectiveDate(date: string) {
    this.form.patchValue({ bind: { effectiveDate: date } });
  }

  setEffectiveDateValidators(expirationDate: moment.Moment, isRenewal: boolean) {
    const minimumAcceptedDate = moment()
      .subtract(CoalitionCyberBindFormService.MAX_ALLOWED_DATE_OFFSET_PAST, 'days')
      .startOf('day');
    const maximumAcceptedDate = moment()
      .add(CoalitionCyberBindFormService.MAX_ALLOWED_DATE_OFFSET_FUTURE, 'days')
      .startOf('day');
    const dateOutOfRangeMessage =
      'Please enter an effective date within the past 15 or upcoming 30 days.';
    const minDateValidator = validatorWithMessage(
      minDateExceededValidator(minimumAcceptedDate, 'day'),
      dateOutOfRangeMessage
    );

    const maxDateValidator = validatorWithMessage(
      maxDateExceededValidator(maximumAcceptedDate),
      dateOutOfRangeMessage
    );

    const expirationDateValidator = (control: UntypedFormControl): ValidationErrors | null => {
      const expirationDateExeeded = moment(control.value, US_DATE_MASK).isSameOrAfter(
        expirationDate
      );
      if (!expirationDateExeeded) {
        return null;
      }
      return {
        expirationDateExeeded: {
          value: control.value,
          validationMessage:
            'Quotes cannot have an effective date more than 60 days after the quote was created. \
            To bind this risk, please create a new quote from the account details page.',
        },
      };
    };
    const effectiveDateControl = getControl(this.form, 'bind.effectiveDate');
    effectiveDateControl.setValidators([minDateValidator, maxDateValidator]);
    if (!isRenewal) {
      effectiveDateControl.addValidators([expirationDateValidator]);
    }
  }

  getLicenseNumber() {
    return getControl(this.form, 'bind.licenseNumber').value;
  }

  handleDoNotShowAgainSelection() {
    const doNotShowAgainControl = getControl(this.form, 'workingWithCoalition.doNotShowAgain');
    if (doNotShowAgainControl.dirty) {
      updateServicingDoNotShowAgainPreference('coalition', doNotShowAgainControl.value);
    }
  }
}
