import { Component, OnChanges, OnDestroy, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { SentryService } from 'app/core/services/sentry.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { PolicyCountResponse, PolicyCancellation } from 'app/bop/guidewire/typings';
import { EXCESS_BUSINESS_TYPE } from 'app/features/activity/models/constants';
import { ActivityListBaseComponent } from 'app/features/activity/components/activity-list-base/activity-list-base.component';

@Component({
  selector: 'app-activity-list-cancellation',
  templateUrl: 'activity-list-cancellation.component.html',
  providers: [CurrencyPipe],
})
export class ActivityListCancellationComponent
  extends ActivityListBaseComponent
  implements OnChanges, OnDestroy
{
  @Input() policyCount: PolicyCountResponse;
  @Input() policyPageLength = 5;

  constructor(
    protected sentryService: SentryService,
    private insuredAccountService: InsuredAccountService,
    protected currencyPipe: CurrencyPipe
  ) {
    super(sentryService, currencyPipe);
  }

  private sub: Subscription = new Subscription();

  // Number of fake rows to display while loading pending cancellations
  estimatedCancellationLength = 0;

  cancellationSelectedIndex = 0;
  cancellationLoadedIndex = 0;

  cancellations: PolicyCancellation[];
  cancellationSubscriptions: Subscription[] = [];

  loadingCancellations = true;
  hideCancellations = false;

  ngOnChanges() {
    this.loadCancellations(0);
  }

  getCancellationCount() {
    return this.policyCount ? parseInt(this.policyCount.canceledPolicyCount, 10) : null;
  }

  loadCancellations(index: number) {
    const cancellationCount = this.getCancellationCount();

    if (!cancellationCount) {
      return;
    }

    this.cancellationSelectedIndex = index;
    this.clearSummarySubscriptions(this.cancellationSubscriptions);

    this.loadingCancellations = true;
    this.estimatedCancellationLength = Math.min(this.policyPageLength, cancellationCount - index);

    this.cancellationSubscriptions.push(
      this.insuredAccountService
        .getCancelledSummary(index, this.policyPageLength)
        .subscribe((cancellations) => {
          this.loadingCancellations = false;
          this.cancellationLoadedIndex = index;
          this.cancellations = cancellations;
        })
    );
  }

  loadNextCancellations() {
    this.loadCancellations(this.cancellationSelectedIndex + this.policyPageLength);
  }

  loadPreviousCancellations() {
    this.loadCancellations(this.cancellationSelectedIndex - this.policyPageLength);
  }

  getCancellationRouterLink(policy: PolicyCancellation) {
    const accountNumber = _.get(policy, 'Cancellation.AccountNumber');
    const policyNumber = _.get(policy, 'Cancellation.PolicyNumber');
    const policyType = _.get(policy, 'Cancellation.LineBusinessType');

    if (policyType === EXCESS_BUSINESS_TYPE) {
      return [`/accounts/${accountNumber}`];
    } else {
      return [`/accounts/${accountNumber}/policy/${policyNumber}`];
    }
  }

  ngOnDestroy() {
    this.clearSummarySubscriptions(this.cancellationSubscriptions);
    this.sub.unsubscribe();
  }
}
