import { Directive } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

@Directive({
  selector: '[appDelimitedNumberMask]',
})
export class DelimitedNumberMaskDirective extends BaseMaskDirective {
  getMask(value: string) {
    const numberVal = this.getNumberValueFormattedWithoutDecimals(value);

    if (numberVal !== null) {
      return numberVal;
    }

    return '';
  }
}
