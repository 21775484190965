<div *ngIf="waiversOfSubrogation.enabled" [formGroup]="waiversOfSubrogation">
  <div
    class="multi-form-item js-waiver-form-control"
    *ngFor="let waiver of waiversOfSubrogation.controls; let i = index"
  >
    <app-form-field-text
      inputId="{{ prefix }}-waiver-{{ i }}-orgOrPersonName"
      [form]="waiver"
      [submitted]="submitted"
      labelText="Name of person or organization"
      nameOfFormControl="orgOrPersonName"
    >
    </app-form-field-text>
    <div class="nav-button-group">
      <a
        class="button button__small button__secondary"
        (click)="removeWaiverOfSubrogationAt(i)"
        (keyup.enter)="removeWaiverOfSubrogationAt(i)"
        *ngIf="multipleWaivers() || allowRemovingAllWaivers"
      >
        Remove
      </a>
    </div>
  </div>
  <button
    *ngIf="waiversOfSubrogation.enabled"
    class="button button__secondary button__full-width button__no-margin"
    type="button"
    (click)="addWaiverOfSubrogation()"
  >
    Add waiver of subrogation
  </button>
</div>
