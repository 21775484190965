<ng-container *ngIf="modalLinkText && modalPreLinkText && modalPostLinkText">
  <span
    >{{ modalPreLinkText
    }}<a (click)="openModal()" (keyup.enter)="openModal()">{{ modalLinkText }}</a
    >{{ modalPostLinkText }}</span
  >
</ng-container>

<ng-container *ngIf="modalLinkText && !(modalPreLinkText && modalPostLinkText)">
  <span
    ><a (click)="openModal()" (keyup.enter)="openModal()">{{ modalLinkText }}</a></span
  >
</ng-container>

<ng-container *ngIf="!modalLinkText">
  <span>Click <a (click)="openModal()" (keyup.enter)="openModal()">here</a>!</span>
</ng-container>

<app-form-dsl-form [form]="form" [formTree]="formTree" [submitted]="submitted"> </app-form-dsl-form>

<app-modal [open]="open" [disableClose]="true" modalClass="app-modal-content__left-align">
  <div *ngIf="modalImage" class="modal-image" role="presentation">
    <img src="{{ modalImage }}" alt="" />
  </div>

  <h2 class="app-modal-header">{{ modalTitle }}</h2>

  <ng-container *ngIf="modalSubTitle">
    <div class="app-link-modal-msg-container">
      <div class="dialog-text dialog-text__warning dialog-text__modal">
        {{ modalSubTitle }}
      </div>
    </div>
  </ng-container>

  <p [innerHTML]="modalBody" role="alert"></p>

  <div class="nav-button-group nav-button-group__centered-horizontal">
    <button type="button" class="button button__secondary button__no-margin" (click)="closeModal()">
      Close
    </button>
  </div>
</app-modal>
