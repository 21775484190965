<div *ngIf="isStreaksEnabled && streakDeadline" class="streaks-section">
  <div>
    <h3 *ngIf="streakLength === 1">Bind streak: {{ streakLength }} month</h3>
    <h3 *ngIf="streakLength !== 1">Bind streak: {{ streakLength }} months</h3>
    <p>
      Your agency is on a {{ streakLength }} month bind streak.
      <span *ngIf="streakLength > 0"
        >Bind again by <strong>{{ streakDeadline | date: 'MMM d' }}</strong> to keep your streak
        going.</span
      >
      <span *ngIf="streakLength === 0"
        >Bind a policy by <strong>{{ streakDeadline | date: 'MMM d' }}</strong> to start your
        streak.</span
      >
    </p>
  </div>
  <div class="streaks-calendar">
    <ul>
      <span
        *ngFor="let month of calendarStyles"
        class="more-info-tip tool-tip tool-tip__bottom"
        [attr.data-tooltip]="getToolTipTextForMonth(month.count, month.date)"
      >
        <li [ngClass]="month.count && month.count > 0 ? 'success-streak' : month.style">
          <span
            class="month-label"
            [ngClass]="month.count ? 'success-streak-label' : month.style + '-label'"
            >{{ month.date | date: 'MMM' | uppercase }}</span
          >
        </li>
      </span>
    </ul>
  </div>
</div>
