import { FormValue } from './common-typings';
import { UntypedFormControl } from '@angular/forms';

export const evaluateSetCondition = (
  property: FormValue,
  display: 'ALL' | 'IN' | 'NOT_IN',
  values: string[]
) => {
  if (display === 'ALL') {
    return true;
  }

  const valueInSet = values.some(
    (value) => String(value).toLowerCase() === String(property).toLowerCase()
  );

  if (display === 'IN') {
    return valueInSet;
  }

  if (display === 'NOT_IN') {
    return !valueInSet;
  }

  return false;
};

export const isEmptyValue = (formValue: any) => {
  return formValue === null || formValue === '';
};

export const disabledOrEmpty = (control: UntypedFormControl, latestValue: any) => {
  return !control.enabled || isEmptyValue(latestValue);
};
