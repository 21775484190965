import * as moment from 'moment';
import { US_DATE_MASK, ISO_DATE_MASK } from 'app/constants';

export const convertDateToUsFormat = (date: string): string => {
  return moment(date, ISO_DATE_MASK).format(US_DATE_MASK);
};

export const convertUsDateIsoFormat = (date: string): string => {
  const parsedDate = moment(date, US_DATE_MASK).format(ISO_DATE_MASK);
  return parsedDate;
};

export const getToday = () => {
  return moment.utc().local().startOf('day');
};

export const getYesterday = () => {
  return moment.utc().local().startOf('day').subtract(1, 'day');
};

export const getTomorrow = () => {
  return moment.utc().local().startOf('day').add(1, 'day');
};

export const getTodayUs = () => {
  const today = getToday();
  return today.format(US_DATE_MASK);
};

export const getYesterdayUs = () => {
  const yesterday = getYesterday();
  return yesterday.format(US_DATE_MASK);
};

export const getTomorrowUs = () => {
  const tomorrow = getTomorrow();
  return tomorrow.format(US_DATE_MASK);
};

export const getTodayIso = () => {
  const today = getToday();
  return today.format(ISO_DATE_MASK);
};

export const getYesterdayIso = () => {
  const yesterday = getYesterday();
  return yesterday.format(ISO_DATE_MASK);
};

export const getTomorrowIso = () => {
  const tomorrow = getTomorrow();
  return tomorrow.format(ISO_DATE_MASK);
};
