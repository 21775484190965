import { Component, ChangeDetectorRef, OnChanges, OnDestroy, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { SentryService } from 'app/core/services/sentry.service';
import {
  RecentQuotesData,
  RecentBindsData,
  RecentQuotesResponse,
  RecentBindsResponse,
  RecentActivitySummaryService,
} from 'app/shared/services/recent-activity-summary.service';
import { Subscription } from 'rxjs';
import { ActivityListBaseComponent } from 'app/features/activity/components/activity-list-base/activity-list-base.component';
import { UserService } from 'app/core/services/user.service';

type DataType = 'QUOTE' | 'BIND';
// NOTE: GW account calls need to be made separately for each row that displayed in this module, so
// let's not increase this number too much until a GW API endpoint is made that can get account details for multiple account id's
// at once
const POLICY_PAGE_LENGTH = 5;

@Component({
  selector: 'app-activity-list-quotes',
  templateUrl: 'activity-list-quotes.component.html',
  providers: [CurrencyPipe],
})
export class ActivityListQuotesComponent
  extends ActivityListBaseComponent
  implements OnChanges, OnDestroy
{
  constructor(
    protected sentryService: SentryService,
    private recentActivitySummaryService: RecentActivitySummaryService,
    private userService: UserService,
    protected currencyPipe: CurrencyPipe,
    private ref: ChangeDetectorRef
  ) {
    super(sentryService, currencyPipe);
  }
  @Input() dataType: DataType;

  dataSelectedIndex = 0;
  dataLoadedIndex = 0;
  dataCount = 0;
  private sub: Subscription = new Subscription();
  producerCode = '';
  policyPageLength = POLICY_PAGE_LENGTH;
  // Number of fake rows to display while loading next page
  estimatedDataLength: number | null = null;

  data: RecentQuotesData[] | RecentBindsData[] = [];
  dataSubscription: Subscription[] = [];

  loadingData = true;

  ngOnChanges() {
    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.producerCode = user.producer;
        this.loadData(0);
      })
    );
  }

  loadData(index: number) {
    this.dataSelectedIndex = index;
    this.clearSummarySubscriptions(this.dataSubscription);

    this.loadingData = true;
    if (this.dataCount > 0) {
      this.estimatedDataLength = Math.min(this.policyPageLength, this.dataCount - index);
    }
    this.ref.detectChanges();

    const recentActivityMethod =
      this.dataType === 'QUOTE'
        ? this.recentActivitySummaryService.getRecentQuotes
        : this.recentActivitySummaryService.getRecentBinds;
    this.dataSubscription.push(
      recentActivityMethod
        .call(this.recentActivitySummaryService, this.producerCode, index, this.policyPageLength)
        .subscribe((response: RecentQuotesResponse | RecentBindsResponse) => {
          this.loadingData = false;
          this.dataLoadedIndex = index;
          this.data =
            this.dataType === 'QUOTE'
              ? (response as RecentQuotesResponse).recentQuotes
              : (response as RecentBindsResponse).recentBinds;
          this.dataCount = response.totalNumber;
          this.ref.detectChanges();
        })
    );
  }

  loadNextData() {
    this.loadData(this.dataSelectedIndex + this.policyPageLength);
  }

  loadPreviousData() {
    this.loadData(this.dataSelectedIndex - this.policyPageLength);
  }

  ngOnDestroy() {
    this.clearSummarySubscriptions(this.dataSubscription);
  }

  getRouterLink(dataRow: RecentQuotesData | RecentBindsData) {
    if (dataRow.product === 'bop' && dataRow.pasSource === 'attune_gw') {
      if (this.dataType === 'QUOTE') {
        const quoteData = dataRow as RecentQuotesData;
        return `/accounts/${quoteData.accountId}/bop/policies/${quoteData.pasId}`;
      } else {
        const bindData = dataRow as RecentBindsData;
        return `/accounts/${bindData.accountId}/terms/${bindData.policyNumber}/${bindData.term}`;
      }
    } else if (dataRow.product === 'wc' && dataRow.pasSource === 'attune_gw') {
      if (this.dataType === 'QUOTE') {
        const quoteData = dataRow as RecentQuotesData;
        return `/accounts/${quoteData.accountId}/attune/workers-comp/quotes/${quoteData.pasId}`;
      } else {
        const bindData = dataRow as RecentBindsData;
        return `/accounts/${bindData.accountId}/terms/${bindData.policyNumber}/${bindData.term}`;
      }
    } else {
      return `/accounts/${dataRow.accountId}`;
    }
  }

  getFormattedStatus(status: string) {
    if (status === 'in_force') {
      return 'In force';
    }
    if (status === 'employers_validation_error') {
      return 'Quote incomplete';
    }

    return status;
  }

  getCssClassForPillTag(dataRow: RecentQuotesData | RecentBindsData) {
    if (dataRow.status === 'quoted') {
      return 'pill-tag__good';
    }
    if (dataRow.status === 'declined') {
      return 'pill-tag__bad';
    }

    if (dataRow.status === 'pending_refer' || dataRow.status === 'employers_validation_error') {
      return 'pill-tag__draft';
    }

    return 'pill-tag__main';
  }

  getPremium(dataRow: RecentQuotesData | RecentBindsData) {
    if (this.dataType === 'QUOTE') {
      const quoteData = dataRow as RecentQuotesData;
      return this.formatCost(
        quoteData.premium,
        quoteData.accountId,
        quoteData.pasId,
        'recentQuote'
      );
    }
    const bindData = dataRow as RecentBindsData;
    return this.formatCost(
      bindData.netPremium,
      bindData.accountId,
      bindData.policyNumber,
      'recentBind'
    );
  }

  getSectionHeading() {
    return this.dataType === 'QUOTE' ? 'Recent quotes' : 'Recent binds';
  }

  getCarrierName(dataRow: RecentQuotesData | RecentBindsData) {
    if (dataRow.pasSource === 'attune_gw') {
      return 'attune';
    }
    return dataRow.pasSource;
  }

  getAccountName(dataRow: RecentQuotesData | RecentBindsData) {
    return dataRow.accountName ? dataRow.accountName : 'Account Name Unavailable';
  }
}
