import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, mergeMap, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
  ComplianceDocument,
  DigitalCarrierPolicyDetails,
  DigitalCarrierProduct,
  DigitalQuoteStatus,
  FormDSLQuoteRequest,
  FrontendQuote,
  PolicyRenewalStatus,
  PolicyStatus,
} from '../../models/types';
import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import {
  DEFAULT_WARNING_MESSAGE_FOR_QUOTE_DETAILS_PANE,
  DIGITAL_PRODUCTS,
  CUSTOMER_CARE_HOURS,
} from 'app/constants';
import {
  DUPLICATE_INSURED_ACCOUNT_REFERRAL_MESSAGE_BACKEND,
  LIBERTY_MUTUAL_BROKER_OF_RECORD_ERROR_MESSAGE,
} from 'app/features/liberty-mutual/models/constants';
import { carrierAllowsMultipleQuotes, isCpspNyRisk } from '../../models/carrier-helpers';
import {
  ATTESTATION_FORM_BY_STATE,
  COALITION_BROKER_OF_RECORD_REFERRAL_ERROR_MESSAGE,
  COALITION_BROKER_OF_RECORD_REFERRAL_REASON,
  COALITION_RENEWAL_CALL_TO_ACTION_MESSAGE,
  productToMarketDisplay,
  visibleCarrierDocumentsByQuoteStatus,
  visibleCarrierDocumentsByRenewalStatus,
} from '../../../coalition/models/cyber-constants.model';
import {
  CoalitionDocument,
  CoalitionDocumentType,
  quoteStatusHasAssociatedCarrierDocuments,
  quoteStatusHasAssociatedComplianceDocuments,
} from '../../../coalition/models/cyber-typings.model';
import { retryWithBackoff } from '../../../../shared/helpers/api-helpers';
import {
  InsuredAccountSummaryService,
  AccountSummaryView,
  DigitalCarrierPolicyDetailsWithLinks,
} from '../../../insured-account/services/insured-account-summary.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import { InsuredAccountService } from '../../../insured-account/services/insured-account.service';
import { InsuredAccount } from '../../../insured-account/models/insured-account.model';
import { SentryService } from '../../../../core/services/sentry.service';
import { ProducerDetailsResponse } from '../../../../bop/guidewire/typings';
import { InformService } from '../../../../core/services/inform.service';
import {
  NON_BINDING_ROLES,
  UNABLE_TO_BIND_MESSAGE,
  UserService,
} from '../../../../core/services/user.service';
import { DocumentService } from '../../../documents/services/document.service';
import {
  getCoalitionCyberDocumentUrl,
  getCoalitionCyberRiskAssessmentUrl,
  getDcpQuoteLetterUrl,
} from '../../../documents/models/document-urls.model';
import {
  getCoalitionCyberRiskAssessmentFileName,
  getComplianceDocumentFileName,
  getQuoteLetterFileName,
} from '../../../documents/models/document-file-names.model';
import { isBopV2orV3 } from '../../../attune-bop/models/constants';

export const POLLING_STOP_TIME_MS = 120000;
const DOCUMENT_RETRYS = 6;
const DOCUMENT_RETRY_TIME_MS = 2000;

@Component({
  selector: 'app-carrier-quote-details-pane.policy-pane, app-carrier-quote-details',
  templateUrl: './digital-carrier-quote-details.component.html',
  providers: [CurrencyPipe, DigitalCarrierQuoteService],
})
export class DigitalCarrierQuoteDetailsComponent implements OnInit, OnDestroy {
  @Input()
  isBundle = false;
  @Input()
  quoteId: string;
  @Input()
  policyId: string;

  quoteIdSubject = new BehaviorSubject<string | null>(null);

  model$: Observable<InsuredAccount>;
  nonBindingRole: boolean;
  address: Address;
  quoteDetails: Observable<FrontendQuote | null>;
  previousPolicyDetails: Observable<DigitalCarrierPolicyDetails | null>;
  isLoadingQuoteDetails = true;
  isRenewalQuote = false;
  previousQuoteId: string | null = null;
  previousPolicyId: string | null = null;
  renewalStatus: PolicyRenewalStatus | null = null;
  previousPolicyExpiration: string | null = null;

  quotePolicyTerm: DigitalCarrierPolicyDetailsWithLinks;
  quoteStatus: DigitalQuoteStatus;
  policyStatus: PolicyStatus;
  displayedStatus: string;
  accountId: string;
  sub: Subscription = new Subscription();
  productConfig: DigitalCarrierProduct | null = null;

  warningHeader:
    | 'Quote referred'
    | 'Quote under review'
    | 'Quote unavailable'
    | 'Quote not taken'
    | 'Action required'
    | null = null;
  warningMessage: string | undefined;

  // Note: Type can be generalized/standardized if other products have similar document fetch interfaces.
  carrierDocuments: CoalitionDocument[] = [];
  isLoadingCarrierDocuments = false;
  complianceDocumentsForBroker: ComplianceDocument[] = [];
  complianceDocumentsForInsured: ComplianceDocument[] = [];
  complianceDocumentsWithoutEsign: ComplianceDocument[] = [];
  errorLoadingComplianceDocuments = false;
  // Default to zero until we get the first response from getComplianceDocuments with an expected number
  expectedComplianceDocumentCount = 0;
  quoteLetterDownload$: Observable<any>;
  quoteLetterDownloadReady$: Observable<any>;
  carrierDocumentsReady$: BehaviorSubject<any>;
  coalitionRiskAssessmentDownload$: Observable<any>;
  complianceDocumentReady$: BehaviorSubject<any>;
  complianceDocumentToDownload$: Record<string, Observable<any>> = {};

  productToMarketDisplay = productToMarketDisplay;

  hasDomains = false;
  tooltipText = '';
  tooltipTop = 0;

  isEditable = false;
  isBundleBindEnabled = false;
  doesAccountHaveBopV2orV3Quotes = false;
  doesAccountHaveBopPolicy = false;
  showBundleBind = false;

  quoteSubmission: FormDSLQuoteRequest | null;

  isCyberRiskProfileEnabled = false;

  quoteLetterDownloading = false;

  customerCareHours = CUSTOMER_CARE_HOURS;

  constructor(
    private digitalCarrierService: DigitalCarrierQuoteService,
    private route: ActivatedRoute,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private featureFlagService: FeatureFlagService,
    private insuredAccountService: InsuredAccountService,
    private accountSummaryService: InsuredAccountSummaryService,
    private sentryService: SentryService,
    private informService: InformService,
    private userService: UserService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    this.accountId = routeParams['accountId'];

    this.model$ = this.insuredAccountService.get(this.accountId);

    this.sub.add(
      this.model$.subscribe((insuredAccount) => {
        this.doesAccountHaveBopV2orV3Quotes = insuredAccount.bopQuotes.some(
          (quote) => isBopV2orV3(quote.uwCompanyCode) && quote.status === 'Quoted'
        );
        this.doesAccountHaveBopPolicy =
          insuredAccount.bopPlusPolicies.length !== 0 || insuredAccount.bopV1Policies.length !== 0;
      })
    );

    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.insuredAccountService.getProducerDetails(user.producer);
          })
        )
        .subscribe((response: ProducerDetailsResponse) => {
          this.nonBindingRole = response.Roles.Entry.every((role) =>
            NON_BINDING_ROLES.includes(role)
          );
        })
    );

    this.sub.add(
      this.quoteIdSubject
        .pipe(
          filter((quoteId) => !!quoteId),
          distinctUntilChanged()
        )
        .subscribe((quoteId: string) => {
          this.quoteId = quoteId;
          this.isLoadingQuoteDetails = true;
          this.setQuoteDetailsObservables();
        })
    );

    this.sub.add(
      this.route.params.subscribe((params) => {
        const quoteId = this.quoteId || params['quoteUuid'];
        this.policyId = this.policyId || params['policyUuid'];
        if (this.policyId) {
          return this.setQuoteIdFromAccountSummary(this.accountId, this.policyId);
        } else {
          this.quoteIdSubject.next(quoteId);
        }
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.BOP_CYBER_BUNDLE_BIND)
        .subscribe((value) => {
          this.isBundleBindEnabled = value || false;
        })
    );
  }

  setQuoteDetailsObservables() {
    this.quoteDetails = this.digitalCarrierService.getQuoteDetails(this.quoteId).pipe(
      tap((quoteDetails: FrontendQuote) => {
        if (!quoteDetails) {
          return;
        }
        this.productConfig =
          _.find(DIGITAL_PRODUCTS, (config) => {
            return (
              config.product === quoteDetails.product && config.pasSource === quoteDetails.pasSource
            );
          }) || null;
        this.quoteStatus = quoteDetails.status;
        if (quoteDetails.details && quoteDetails.details.domains) {
          this.hasDomains = quoteDetails.details.domains.length > 0;
        }
        if (quoteDetails.renewalDetails?.previousQuoteUuid) {
          this.previousQuoteId = quoteDetails.renewalDetails.previousQuoteUuid;
        }
        if (this.quoteStatus === 'quoted') {
          this.initializeQuoteLetterObservables(quoteDetails);
        }

        if (this.isCoalitionCyberSurplus()) {
          this.digitalCarrierService.getCoalitionCyberAddress(this.quoteId).subscribe((res) => {
            this.address = res;
          });
        }
        if (quoteDetails.pasSource === 'liberty_mutual') {
          const today = moment();
          const sixtyDaysAgo = moment().subtract(60, 'days');
          const createdAtMoment = moment(quoteDetails.createdAt);
          const effectiveDateMoment = moment(quoteDetails.effectiveDate);
          const quoteIsExpired =
            effectiveDateMoment.isBefore(today, 'day') ||
            createdAtMoment.isSameOrBefore(sixtyDaysAgo, 'day');
          this.isEditable = !quoteIsExpired && ['draft', 'quoted'].includes(quoteDetails.status);
        } else {
          this.isEditable = ['draft', 'quoted'].includes(quoteDetails.status);
        }

        this.loadAdditionalDocuments();
        this.fetchQuoteSubmission();
      })
    );

    this.previousPolicyDetails = this.digitalCarrierService.getPreviousPolicyDetails(this.quoteId);
    this.sub.add(
      combineLatest([this.quoteDetails, this.previousPolicyDetails]).subscribe(
        ([quoteDetails, policyDetails]) => {
          if (policyDetails) {
            this.renewalStatus = policyDetails.policyAttributes?.renewal_status || null;
            this.isRenewalQuote = true;
            this.previousPolicyExpiration = policyDetails.expirationDate;
            this.previousPolicyId = policyDetails.uuid;
          } else {
            this.renewalStatus = null;
            this.isRenewalQuote = false;
            this.previousPolicyExpiration = null;
            this.previousPolicyId = null;
          }

          this.setStatus();

          // If we have a policy ID, then the quote has been bound; thus we
          // have no need to ever set a warning based on the quote status
          if (!this.policyId && quoteDetails) {
            this.setQuoteWarningMessage(quoteDetails);
          }

          this.isLoadingQuoteDetails = false;
        }
      )
    );

    this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.BOP_CYBER_BUNDLE_BIND)
      .subscribe((value) => {
        this.isBundleBindEnabled = value || false;
      });

    this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.CYBER_RISK_PROFILE).subscribe((value) => {
      this.isCyberRiskProfileEnabled = value || false;
    });
  }

  fetchQuoteSubmission() {
    if (this.productConfig) {
      this.sub.add(
        this.digitalCarrierService
          .getQuoteSubmission(this.productConfig, this.quoteId)
          .subscribe((submission) => {
            this.quoteSubmission = submission;
          })
      );
    }
  }

  setQuoteWarningMessage(quoteDetails: FrontendQuote) {
    // Note: this branch can be used to add more cases
    // where we need to alter the referral message
    if (this.isLibertyMutualBrokerOfRecordError(quoteDetails)) {
      this.warningHeader = 'Quote referred';
      this.warningMessage = LIBERTY_MUTUAL_BROKER_OF_RECORD_ERROR_MESSAGE;
    } else if (this.isCoalitionBrokerOfRecordError(quoteDetails)) {
      this.warningHeader = 'Quote unavailable';
      this.warningMessage = COALITION_BROKER_OF_RECORD_REFERRAL_ERROR_MESSAGE;
    } else if (quoteDetails.status === 'referred' && this.productConfig) {
      this.warningHeader = 'Quote referred';
      this.warningMessage = this.productConfig.referralMessage;
    } else if (quoteDetails.status === 'under_review' && this.productConfig) {
      this.warningHeader = 'Quote under review';
      this.warningMessage = this.productConfig.underReviewMessage;
    } else if (quoteDetails.status === 'unavailable' && this.productConfig) {
      this.warningHeader = 'Quote unavailable';
      this.warningMessage = this.productConfig.unavailableMessage;
    } else if (quoteDetails.status === 'not_taken' && this.productConfig) {
      this.warningHeader = 'Quote not taken';
      this.warningMessage = this.productConfig.notTakenMessage;
    } else if (
      quoteDetails.status === 'draft' &&
      this.isRenewalQuote &&
      this.productConfig &&
      this.previousQuoteId &&
      this.previousPolicyId
    ) {
      this.warningHeader = 'Action required';
      const pathToQuoteFlow = `/accounts/${
        this.accountId
      }/${this.productConfig.computePathForEditUrl()}?renewalOf=${
        this.previousQuoteId
      }&previousPolicy=${this.previousPolicyId}`;

      this.warningMessage = COALITION_RENEWAL_CALL_TO_ACTION_MESSAGE(pathToQuoteFlow);
    }
  }

  getWarningMessage() {
    return this.warningMessage || DEFAULT_WARNING_MESSAGE_FOR_QUOTE_DETAILS_PANE;
  }

  isLibertyMutualBrokerOfRecordError(quoteDetails: FrontendQuote) {
    if (quoteDetails.pasSource !== 'liberty_mutual') {
      return false;
    }
    const referralReasons = _.get(quoteDetails, 'details.referralReasons');

    return (
      _.isArray(referralReasons) &&
      referralReasons.includes(DUPLICATE_INSURED_ACCOUNT_REFERRAL_MESSAGE_BACKEND)
    );
  }

  isCoalitionBrokerOfRecordError(quoteDetails: FrontendQuote) {
    if (quoteDetails.pasSource !== 'coalition') {
      return false;
    }

    const unavailableReasons = _.get(quoteDetails, 'details.unavailableReasons');
    return (
      _.isArray(unavailableReasons) &&
      unavailableReasons.includes(COALITION_BROKER_OF_RECORD_REFERRAL_REASON)
    );
  }

  formatCost(cost: number | null) {
    if (cost !== null) {
      return this.currencyPipe.transform(cost, 'USD');
    } else {
      return 'N/A';
    }
  }

  fetchCost(loadedQuoteDetails: FrontendQuote) {
    if (this.policyId || this.isBound()) {
      if (this.quotePolicyTerm) {
        return this.formatCost(this.quotePolicyTerm.netPremium);
      }

      // If the quotePolicyTerm is null, that means it's still loading.
      // Don't render anything until it's loaded in this case.
    } else {
      let premium;
      if (loadedQuoteDetails.premium && loadedQuoteDetails.surcharges) {
        const surcharges = _.sumBy(loadedQuoteDetails.surcharges, 'amount');
        premium = Number(loadedQuoteDetails.premium) + surcharges;
      } else {
        premium = loadedQuoteDetails.premium;
      }
      return this.formatCost(premium);
    }
  }

  initializeQuoteLetterObservables(quoteDetails: FrontendQuote) {
    const url = getDcpQuoteLetterUrl(quoteDetails.uuid);
    const fileName = getQuoteLetterFileName(quoteDetails.uuid, quoteDetails.product);
    this.quoteLetterDownloadReady$ = this.documentService.pollDocument(url);
    this.quoteLetterDownload$ = this.documentService.getDocument(url, fileName, 'pdf');
  }

  setQuoteIdFromAccountSummary(accountId: string, policyUuid: string) {
    this.sub.add(
      this.accountSummaryService
        .getSummary(accountId)
        .pipe(
          map((accountSummary: AccountSummaryView) => {
            if (accountSummary.status === 'success') {
              const foundPolicyTerm = accountSummary.policyTerms.find((policyTerm) => {
                return policyTerm.uuid === policyUuid;
              });

              if (foundPolicyTerm) {
                this.quotePolicyTerm = foundPolicyTerm;
              }
              return foundPolicyTerm || null;
            }
            return null;
          })
        )
        .subscribe((policyTerm: DigitalCarrierPolicyDetailsWithLinks | null) => {
          if (policyTerm) {
            this.policyStatus = policyTerm.status;
            this.quoteIdSubject.next(policyTerm.quoteId);
          }
        })
    );
  }

  loadAdditionalDocuments() {
    if (!this.productConfig) {
      return;
    }
    this.loadCarrierDocuments(this.productConfig);
    this.loadComplianceDocuments(this.productConfig);
  }

  loadCarrierDocuments(productConfig: DigitalCarrierProduct) {
    if (productConfig.pasSource === 'coalition') {
      const status = this.quoteStatus;
      const renewalStatus = this.renewalStatus;
      if (!quoteStatusHasAssociatedCarrierDocuments(status)) {
        return;
      }
      this.isLoadingCarrierDocuments = true;
      this.carrierDocumentsReady$ = new BehaviorSubject(null);

      const { resourceType, resourceUuid } = this.getResourceTypeAndId();
      this.digitalCarrierService
        .getCoalitionCyberDocuments(resourceType, resourceUuid)
        .pipe(
          tap((documents: CoalitionDocument[]) => {
            const documentsToShow = visibleCarrierDocumentsByQuoteStatus[status];
            const renewalDocumentsToShow =
              renewalStatus && visibleCarrierDocumentsByRenewalStatus[renewalStatus];
            if (renewalDocumentsToShow) {
              documentsToShow.push(...renewalDocumentsToShow);
            }
            this.carrierDocuments = documents.filter(({ documentType }) => {
              return documentsToShow.includes(documentType);
            });
            this.isLoadingCarrierDocuments = false;
          })
        )
        .subscribe(this.carrierDocumentsReady$);
      this.coalitionRiskAssessmentDownload$ = this.model$.pipe(
        mergeMap((insuredAccount) => {
          const cyberRiskAssessmentUrl = getCoalitionCyberRiskAssessmentUrl(
            this.quoteId,
            insuredAccount.companyName
          );
          const cyberRiskAssessmentFileName = getCoalitionCyberRiskAssessmentFileName();
          return this.documentService.getDocument(
            cyberRiskAssessmentUrl,
            cyberRiskAssessmentFileName,
            'pdf'
          );
        })
      );
    }
  }

  loadComplianceDocuments(productConfig: DigitalCarrierProduct) {
    const status = this.quoteStatus;
    if (!quoteStatusHasAssociatedComplianceDocuments(status)) {
      return;
    }
    const { pasSource, product } = productConfig;
    if (pasSource === 'coalition' && product === 'cyber_surplus') {
      this.complianceDocumentReady$ = new BehaviorSubject(null);
      this.digitalCarrierService
        .getComplianceDocuments({ pasSource, product }, this.quoteId)
        .pipe(
          tap(({ success, documents, expectedDocumentCount }) => {
            // If expectedDocumentCount is not present we do not know how many documents to expect so we show one loading
            this.expectedComplianceDocumentCount = expectedDocumentCount || 1;
            if (documents.length === 0 && success && expectedDocumentCount !== 0) {
              this.sentryService.notify(
                'Cyber surplus documents not ready: expected documents does not match actual',
                {
                  severity: 'warning',
                  metaData: {
                    success,
                    documents,
                    expectedDocumentCount,
                  },
                }
              );
              throw new Error('Cyber surplus documents not ready');
            }
          }),
          retryWithBackoff(DOCUMENT_RETRYS, DOCUMENT_RETRY_TIME_MS),
          tap(
            ({ success, documents }) => {
              const documentsForBroker: ComplianceDocument[] = [];
              const documentsForInsured: ComplianceDocument[] = [];
              const documentsWithoutSignature: ComplianceDocument[] = [];

              documents.forEach((doc: ComplianceDocument) => {
                this.complianceDocumentToDownload$[doc.s3_url] =
                  this.complianceDocumentDownload$(doc);
                switch (doc.signing_party) {
                  case 'broker':
                  case 'attune_and_broker':
                    documentsForBroker.push(doc);
                    break;
                  case 'insured':
                  case 'attune_and_insured':
                    documentsForInsured.push(doc);
                    break;
                  case 'broker_and_insured':
                    documentsForBroker.push(doc);
                    documentsForInsured.push(doc);
                    break;
                  case 'no_signing_party_required':
                    documentsWithoutSignature.push(doc);
                    break;
                  default:
                    break;
                }
              });

              this.complianceDocumentsForBroker = documentsForBroker;
              this.complianceDocumentsForInsured = documentsForInsured;
              this.complianceDocumentsWithoutEsign = documentsWithoutSignature;
              this.errorLoadingComplianceDocuments = !success;
            },
            (_error) => {
              this.errorLoadingComplianceDocuments = true;
            }
          )
        )
        .subscribe(this.complianceDocumentReady$);
    }
  }

  hasComplianceDocuments() {
    return (
      !this.isRenewalQuote &&
      (this.complianceDocumentsForBroker.length ||
        this.complianceDocumentsForInsured.length ||
        this.complianceDocumentsWithoutEsign.length)
    );
  }

  coalitionDocumentDownload$(documentType: CoalitionDocumentType, fileName: string) {
    const { resourceType, resourceUuid } = this.getResourceTypeAndId();
    const url = getCoalitionCyberDocumentUrl(resourceType, resourceUuid, documentType);
    return this.documentService.getDocument(url, fileName, 'pdf');
  }

  getResourceTypeAndId(): { resourceType: 'quote' | 'policy'; resourceUuid: string } {
    if (this.policyId) {
      return {
        resourceType: 'policy',
        resourceUuid: this.policyId,
      };
    } else {
      return {
        resourceType: 'quote',
        resourceUuid: this.quoteId,
      };
    }
  }

  complianceDocumentDownload$(doc: ComplianceDocument) {
    const fileName = getComplianceDocumentFileName(doc);
    return this.documentService.getDocument(doc.s3_url, fileName, 'pdf');
  }

  pillTagTypeForStatus(): string {
    switch (this.displayedStatus) {
      case 'declined':
        return 'danger';
      case 'referred':
      case 'under_review':
      case 'cancelled':
      case 'unavailable':
      case 'action required': // Not a backend status; used only for renewal drafts
        return 'warning';
      case 'quoted':
      case 'bound_with_subjectivity':
        return 'success';
      case 'bound':
      case 'in_force':
      case 'scheduled':
        return 'main';
      case 'draft':
      default:
        return 'neutral';
    }
  }

  setStatus() {
    const isPolicyBoundWithSubjectivity =
      ['in_force', 'scheduled'].includes(this.policyStatus) &&
      this.quoteStatus === 'bound_with_subjectivity';

    if (this.isRenewalQuote && this.quoteStatus === 'draft') {
      // This is a portal-only status used as a call-to-action for users who
      // only have a quote in the draft state.
      this.displayedStatus = 'action required';
    } else if (isPolicyBoundWithSubjectivity || !this.policyStatus) {
      // Quotes that are bound with subjectivity have an "in force" policy status, but
      // we intentionally show the quote status instead to make it clear that the policy
      // is not issued.
      this.displayedStatus = this.quoteStatus;
    } else if (
      this.quotePolicyTerm &&
      moment(this.quotePolicyTerm.effectiveDate).isSameOrBefore(moment(), 'day') &&
      !moment(this.quotePolicyTerm.expirationDate).isBefore(moment(), 'day') &&
      this.policyStatus === 'scheduled'
    ) {
      // Quotes having a `scheduled` status and an effective date now or before
      // have not had their status updated
      this.displayedStatus = 'in_force';
    } else if (
      this.quotePolicyTerm &&
      moment(this.quotePolicyTerm.expirationDate).isBefore(moment(), 'day') &&
      this.policyStatus !== 'expired'
    ) {
      // Final check to ensure we don't somehow have an expired policy that
      // still has a non-expired status. Log a Sentry error as this means bad
      // data that should be investigated.
      this.sentryService.notify(
        'Encountered expired policy term that does not have expired status',
        {
          severity: 'error',
          metaData: {
            accountId: this.accountId,
            policyTerm: this.quotePolicyTerm,
            quoteDetails: this.quoteDetails,
            renewalStatus: this.renewalStatus,
          },
        }
      );

      this.displayedStatus = 'expired';
    } else {
      this.displayedStatus = this.policyStatus;
    }
  }

  formattedStatus() {
    return this.displayedStatus?.replace(/_/g, ' ') || '';
  }

  getHeaderText() {
    if (this.isRenewalQuote) {
      return 'Renewal details';
    } else if (this.policyId) {
      return 'Policy details';
    } else {
      return 'Quote details';
    }
  }

  getEffectiveDate(loadedQuoteDetails: FrontendQuote | DigitalCarrierPolicyDetailsWithLinks) {
    return moment.utc(loadedQuoteDetails.effectiveDate).format('ll');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  carrierAllowsMultipleQuotes() {
    if (!this.productConfig) {
      return false;
    }
    return carrierAllowsMultipleQuotes(this.productConfig.pasSource);
  }

  isCpspNyRisk(quote: FrontendQuote) {
    return isCpspNyRisk(quote);
  }

  isCoalitionCyberSurplus() {
    return (
      this.productConfig &&
      this.productConfig.product === 'cyber_surplus' &&
      this.productConfig.pasSource === 'coalition'
    );
  }

  hasComplianceDocsToSign() {
    return (
      !this.isRenewalQuote &&
      (this.complianceDocumentsForBroker.length ||
        this.complianceDocumentsForInsured.length ||
        this.expectedComplianceDocumentCount)
    );
  }

  hasAttestation() {
    return this.address && this.address.state in ATTESTATION_FORM_BY_STATE;
  }

  isQuoted() {
    return this.quoteStatus === 'quoted';
  }

  isBound() {
    return this.quoteStatus === 'bound' || this.quoteStatus === 'bound_with_subjectivity';
  }

  hasCoalitionRiskAssessment() {
    if (!this.isLoadingCarrierDocuments && this.carrierDocuments) {
      return this.carrierDocuments.some((doc) => {
        return doc.documentType === 'CYBER_RISK_ASSESSMENT';
      });
    }
  }

  shouldReceiveCoalitionRiskAssessment() {
    return !!this.quoteSubmission?.formData?.domain_names;
  }

  showCyberRiskProfile() {
    return (
      this.isCyberRiskProfileEnabled &&
      this.productConfig?.pasSource === 'coalition' &&
      !this.isRenewalQuote &&
      quoteStatusHasAssociatedCarrierDocuments(this.quoteStatus) &&
      !this.shouldReceiveCoalitionRiskAssessment() &&
      !this.hasCoalitionRiskAssessment()
    );
  }

  updateTooltip(event: any) {
    const targetElement = (event.toElement || event.relatedTarget) as Element;

    if (targetElement && this.hasDomains) {
      this.tooltipTop = event.y;
      this.tooltipText =
        'The domain scan is currently in progress to generate the Coalition Risk Assessment. This could take up to three to five minutes.';
    } else {
      this.hideTooltip();
    }
  }

  hideTooltip() {
    this.tooltipText = '';
  }

  continueToBind(uuid: string) {
    if (this.nonBindingRole) {
      this.informService.infoToast(UNABLE_TO_BIND_MESSAGE);
      return;
    }
    if (
      this.productConfig?.product === 'cyber_admitted' &&
      this.isBundleBindEnabled &&
      this.doesAccountHaveBopV2orV3Quotes &&
      !this.doesAccountHaveBopPolicy
    ) {
      this.showBundleBind = true;
      return;
    }

    this.router.navigate([
      '/accounts',
      this.accountId,
      this.productConfig?.pathForUrl,
      'quotes',
      uuid,
      'bind',
    ]);
  }

  closeBundleBind() {
    this.showBundleBind = false;
  }

  isAutomaticRenewal() {
    return this.renewalStatus === 'automatic';
  }

  renewalPolicyAutoIssueDate() {
    if (this.previousPolicyExpiration) {
      const previousPolicyExpirationMoment = moment(this.previousPolicyExpiration, 'YYYY-MM-DD');
      return previousPolicyExpirationMoment.subtract(21, 'days').format('MMM D, YYYY');
    }
  }
}
