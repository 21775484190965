<div class="catching-game-score" id="catching-game-score">
  <span class="sr-only">Score:</span>
  {{ score }}
  <p class="catching-game-instruction">
    Be safe and avoid risks! Move your mouse or left/right arrows to play.
  </p>
</div>

<ng-container *ngFor="let feedback of feedbackMessages">
  <p
    role="alert"
    [ngStyle]="{ 'left.px': feedback.position.x, 'top.px': feedback.position.y }"
    class="catching-game-points"
    [ngClass]="{
      'points-gained': feedback.type === 'gain',
      'points-lost': feedback.type === 'loss'
    }"
  >
    {{ feedback.message }}
  </p>
</ng-container>

<ng-container *ngIf="showProgressBar">
  <app-quoting-progress
    [title]="title"
    [successComment]="successComment"
    [comments]="[
      'Dotting the i\'s...',
      'Crossing the t\'s...',
      'Spell checking...',
      'Warming up the printer...',
      'Looking for the stapler...'
    ]"
    [finish$]="finish$"
    successImage="assets/img/approved.png"
    allowClicks="true"
  ></app-quoting-progress>
</ng-container>
<canvas
  #canvas
  id="catching-game"
  (mousemove)="handleMouseMove($event)"
  (keydown)="handleKeyDown($event)"
  (keyup)="handleKeyUp($event)"
  tabindex="0"
></canvas>
