<section class="broker-contact-form">
  <h3>Broker contact information</h3>
  <p>
    Learn more about the emails we send you
    <a href="https://attunehelp.zendesk.com/hc/en-us/articles/360042722492" target="_blank">here</a>
  </p>

  <div
    *ngIf="hasGeneralError()"
    class="validation broker-contact-validation js-broker-general-error"
  >
    <p class="validation-message">{{ getGeneralError() }}</p>
  </div>

  <section
    class="multi-form-item broker-contact-panel"
    *ngFor="let contact of form.controls; let i = index"
  >
    <app-form-field-select
      inputId="contact-{{ i }}-contactId"
      labelText="Email Address"
      [form]="form.at(i)"
      nameOfFormControl="contactId"
      cssClass="js-broker-contact-select"
      [describedBy]="describedBy"
      [placeholder]="NEW_EMAIL_OPTION"
      [submitted]="submitted"
      [availableOptions]="contactsList()"
    >
    </app-form-field-select>

    <ng-container *ngIf="isNewContact(i)">
      <div class="offset-form-section">
        <div class="split-line-input-container">
          <app-form-field-text
            inputId="contact-{{ i }}-firstName"
            [form]="form.at(i)"
            [submitted]="submitted"
            labelText="First name"
            nameOfFormControl="firstName"
          >
          </app-form-field-text>
          <app-form-field-text
            inputId="contact-{{ i }}.lastName"
            [form]="form.at(i)"
            [submitted]="submitted"
            labelText="Last name"
            nameOfFormControl="lastName"
          >
          </app-form-field-text>
        </div>
        <app-form-field-text
          inputId="contact-{{ i }}-email"
          [form]="form.at(i)"
          [submitted]="submitted"
          labelText="Broker email address"
          nameOfFormControl="email"
          appRemoveWhitespaceMask
        >
        </app-form-field-text>
      </div>
    </ng-container>

    <p>Subscribe to emails about the following:</p>
    <ng-container *ngFor="let type of emailTypes">
      <app-form-field-checkbox
        cssClass="broker-contact-email-type"
        [form]="form.at(i)"
        [submitted]="submitted"
        [hasError]="hasEmailTypesError(i)"
        inputId="contact-{{ i }}-emailTypes-{{ type.id }}"
        nameOfFormControl="emailTypes.{{ type.id }}"
        labelText="{{ type.name }}"
      >
      </app-form-field-checkbox>
    </ng-container>

    <div
      *ngIf="hasContactError(i)"
      class="validation broker-contact-validation js-broker-contact-error"
    >
      <p class="validation-message">{{ getContactError(i) }}</p>
    </div>

    <div class="broker-contacts-button-container" *ngIf="form.controls.length > 1">
      <a
        class="button button__small button__no-margin button__transparent js-contact-remove-button"
        (click)="delete.emit(i)"
        (keyup.enter)="delete.emit(i)"
      >
        {{ removeText() }}
      </a>
    </div>
  </section>

  <button
    class="button button__secondary button__full-width button__no-margin js-contact-add-button"
    type="button"
    (click)="add.emit()"
  >
    Add broker contact
  </button>
</section>
