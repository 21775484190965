<app-modal
  [open]="displayPriceDiffModal"
  (handleModalClose)="goBackToAccount()"
  modalClass="app-modal-content__price-diff"
>
  <header>
    <div class="modal-image">
      <img src="assets/img/billing_icon.png" alt="" role="presentation" />
    </div>
    <h2>Price change</h2>
  </header>
  <section>
    <div class="bind-price-difference">
      <div class="bind-price-orignal">
        <p class="gray-text">Original Premium</p>
        <h2>{{ originalTotalCost | currency: 'USD':'symbol':'1.0-0' }}</h2>
      </div>
      <img src="assets/img/long_arrow.svg" alt="" class="bind-price-difference-arrow" />
      <div>
        <p class="gray-text">New Premium</p>
        <h2>{{ newTotalCost | currency: 'USD':'symbol':'1.0-0' }}</h2>
      </div>
    </div>
    <!--
    <p class="dialog-text dialog-text__main">The last price re-file in NY was July 12, 2020.</p>
    -->
    <p *ngIf="!isWcQuote">
      This likely happened due to a new effective date or a scheduled change in rates. Please
      contact our Customer Care Team if you have any questions or concerns.
    </p>
    <p *ngIf="isWcQuote">
      This likely happened due to a new effective date, a change in FEIN, or a scheduled change in
      rates. Please contact our Customer Care Team if you have any questions or concerns.
    </p>
    <button (click)="goBackToAccount()" class="button button__secondary button__price-diff">
      Cancel
    </button>
    <button
      (click)="callBindService()"
      class="button button__primary js-bind-quote-button button__price-diff"
    >
      Confirm bind
    </button>
  </section>
</app-modal>
