// These CovClause pattern codes are parsed by retrieve-quote-translator
export const PARSED_CODES = [
  'BP7BarbersBeauticiansProflLiab',
  'BP7BeautySalonsProflLiab',
  'BP7BuildWind_HUSA',
  'BP7BusinessLiability',
  'BP7BusinessOwnersEnhacement',
  'BP7BusnIncomeAndExtraExpenseCovRevisedPerOfIndemni',
  'BP7ClassificationBusinessPersonalProperty',
  'BP7ContractorsEnhacement',
  'BP7ContrctrsInstalltnTypes',
  'BP7DataResponseCyberLiability',
  'BP7DebrisRmvlAddlIns',
  'BP7EmpBenefitsLiabCov',
  'BP7EmployeeDishty',
  'BP7EmploymentRelatedPracticesLiabilityCov',
  'BP7EquipmentBreakdownCoverage2',
  'BP7FuneralDirectorsProflLiab',
  'BP7HiredNonOwnedAuto',
  'BP7LessorRiskEnhacement',
  'BP7LiquorExposure_HUSA',
  'BP7LiquorLiabCov',
  'BP7LiquorLiabCovExposure_HUSA',
  'BP7LocationPropertyDeductibles',
  'BP7LocWind_HUSA',
  'BP7OpticalHearingAid',
  'BP7OptionalDeductible',
  'BP7OrdinanceOrLawCov',
  'BP7PrintersErrorsOmissions',
  'BP7RestaurantsEnhacement',
  'BP7SnowPlowProdsCompldOpsHzdCov',
  'BP7SpoilgCov',
  'BP7Structure',
  'BP7Terrorism_HUSA',
  'BP7UtilitySrvcsDirectDamageForBuilding',
  'BP7UtilitySrvcsTimeElement1',
  'BP7VeterinariansProflLiab',
  'BP7WaiverTransferRightsAgainstOthersToUs',
  'BP7WaiverTransferRightsOfRecoveryAgainstOthersToUs',
  'BP7WindLossMitig_HUSA',
];

// These CovClause pattern codes are overwritten by the quote generator
// in order to create a valid quote. Some values are "parsed" without
// appearing in the translator e.g. BP7_ClassAdditionalCov_HUSA uses the
// classification code parsed from buildings -> classifications
//
// Any coverage amounts, etc, that appear in these codes will NOT be
// maintained through an edit.
export const OVERWRITTEN_CODES = [
  'BP7AbuseMolestationExcl',
  'BP7AccsDisclConfidPersonInfoDataRelatedLiabNoBIExcepIncl_HUSA',
  'BP7AsbestosExclusion_HUSA',
  'BP7BPPSeasonalIncrease_HUSA',
  'BP7BusinessIncome',
  'BP7CapLossesFromCertfdActsTerrsm',
  'BP7ClassificationAccountsReceivable',
  'BP7ClassificationBPPTemporarilyInPortableStorageUn',
  'BP7ClassificationBusinessIncomeFromDependentProps',
  'BP7ClassificationOutdoorProperty',
  'BP7ClassificationValuablePapers',
  'BP7ComputerFraudFundsTransferFraud',
  'BP7DisclosurePursuantToTRIA2002',
  'BP7ElectronicData',
  'BP7EmploymentRelatedPracticesExcl',
  'BP7ExclCertfdActsTerrsmCovFireLosses',
  'BP7ExclSilicaSilicaRelatedDust',
  'BP7ForgeryAlteration',
  'BP7FungiBacteriaExclLiability',
  'BP7InterruptionComputerOps',
  'BP7LocationAutomaticIncreasePct',
  'BP7LocationEmployeeDishty',
  'BP7LocationFireDeptService',
  'BP7MinimumPremiumAdjustment_HUSA',
  'BP7TheftLimitations',
  'BP7TotPolltnExclHeatEquipAndHostileFireExcptn',
  'BP7UTLiquorLiabCov_HUSA',
  'BP7WindstormOrHailExcl',
  'BP7_BuildAddCov_HUSA',
  'BP7_ClassAdditionalCov_HUSA',
  'BP7_LocAdditionalCov_HUSA',
  'BP7_LineAdditionalCov_HUSA',
];

// These are codes added by GW automatically that we don't have to pass back to it.
//
// Any coverage amounts, etc, that appear in these codes will NOT be
// maintained through an edit.
const IGNORED_CODES = [
  'BP7DesignatedPremisesProject',
  'BP7LmtdExclPersonalAndAdvertisingInjuryLawyers',
  'BP7NYFungus_HUSA',
  'BP7WindstormOrHailLossesRoofACV',
  'PolHolDisNotOfTerrInsCovInc_ATN',
];

export const KNOWN_CODES = [...PARSED_CODES, ...OVERWRITTEN_CODES, ...IGNORED_CODES];
