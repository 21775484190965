<ng-container *ngIf="form">
  <header class="bind-quote-header">
    <h1 class="form-header-below-link">Policy details</h1>
  </header>
  <div class="bind-flow-subsection">
    <app-form-field-text
      inputId="bind-effectiveDate"
      [form]="form"
      [submitted]="hasSubmitted()"
      labelText="Effective date"
      nameOfFormControl="effectiveDate"
      appDateMask
    >
    </app-form-field-text>
    <div class="validation validation__align-left" role="alert" *ngIf="effectiveDate.invalid">
      <p class="validation-message js-effective-date-validation-message">
        {{ getEffectiveDateValidationMessage() }}
      </p>
    </div>
    <app-form-field-checkbox
      *ngIf="form.get('backdatedTermsAgreement').enabled"
      [form]="form"
      [submitted]="hasSubmitted()"
      inputId="bind-backdated-terms"
      nameOfFormControl="backdatedTermsAgreement"
      labelText="By checking this box, I certify that all terms and conditions were requested by the Insured and quoted by Attune prior to the effective date of the policy and remain unchanged in the binder and policy issued. No back-dated coverage for any loss has been requested or received."
    >
    </app-form-field-checkbox>
  </div>
  <div class="bind-flow-subsection">
    <h3>Insured contact information</h3>
    <dl>
      <dt>Legal business name</dt>
      <dd>{{ contact.Name }}</dd>
    </dl>
    <dl>
      <dt>Mailing address</dt>
      <dd>
        {{ contact.PrimaryAddress.AddressLine1 }} {{ contact.PrimaryAddress.AddressLine2 }}
        {{ contact.PrimaryAddress.City }},
        {{ contact.PrimaryAddress.State }}
        {{ contact.PrimaryAddress.PostalCode }}
      </dd>
    </dl>
  </div>
  <div class="bind-flow-subsection">
    <div class="form-split">
      <app-form-field-text
        inputId="bind-emailAddress"
        [form]="form"
        [submitted]="hasSubmitted()"
        labelText="Insured email address"
        nameOfFormControl="emailAddress"
        appRemoveWhitespaceMask
      >
      </app-form-field-text>
      <app-form-field-text
        inputId="bind-phoneNumber"
        [form]="form"
        [submitted]="hasSubmitted()"
        labelText="Phone number"
        nameOfFormControl="phoneNumber"
        appPhoneMask
      >
      </app-form-field-text>
    </div>
  </div>

  <div class="bind-flow-subsection">
    <ng-container *ngFor="let contact of form.get('inspectionContacts').controls; index as i">
      <ng-container *ngIf="i === 0">
        <div class="helper-card-container">
          <ng-container
            [ngTemplateOutlet]="inspectionContact"
            [ngTemplateOutletContext]="{ contact: contact, i: i }"
          >
          </ng-container>
          <div class="helper-card">
            <div class="pill-tag pill-tag__main">Reminder</div>
            <p>
              Inspections on all habitational risks must be completed within seven (7) calendar days
              after the policy is bound regardless of its effective date.
              <strong>Failure to do so may result in cancellation</strong>.
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="i > 0"
        [ngTemplateOutlet]="inspectionContact"
        [ngTemplateOutletContext]="{ contact: contact, i: i }"
      >
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #inspectionContact let-i="i" let-contact="contact">
  <div class="bind-flow-subsection__location">
    <h3>Location {{ i + 1 }} inspection contact</h3>
    <div class="form-split">
      <app-form-field-text
        inputId="inspector-emailAddress-{{ i }}"
        [form]="contact"
        [submitted]="hasSubmitted()"
        labelText="Email address"
        nameOfFormControl="inspectorEmailAddress"
        appRemoveWhitespaceMask
      >
      </app-form-field-text>
      <app-form-field-text
        inputId="inspector-phoneNumber-{{ i }}"
        [form]="contact"
        [submitted]="hasSubmitted()"
        labelText="Phone number"
        nameOfFormControl="inspectorPhoneNumber"
        appPhoneMask
      >
      </app-form-field-text>
    </div>
    <app-form-field-text
      inputId="inspector-name-{{ i }}"
      [form]="contact"
      [submitted]="hasSubmitted()"
      labelText="Name"
      nameOfFormControl="inspectorName"
    >
    </app-form-field-text>
  </div>
</ng-template>
