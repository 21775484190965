import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
})
export class LoadingBarComponent implements OnDestroy, OnInit {
  @Input() secondsToMax = 60;
  @Input() maxPercent = 90;
  @Input() updateIntervalMs = 3000;
  @Input() startPercent = 3;
  public percent = 3;

  private sub: Subscription = new Subscription();

  ngOnInit() {
    this.percent = this.startPercent;
    this.sub.add(
      interval(this.updateIntervalMs).subscribe((nth: number) => {
        // Slowly go to max percent
        const secondsElapsed = (nth + 1) * (this.updateIntervalMs / 1000);
        this.percent = Math.round(
          Math.min(this.maxPercent, (secondsElapsed / this.secondsToMax) * 100)
        );
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
