import { Directive, HostListener } from '@angular/core';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  AMPLITUDE_INPUT_ATTRIBUTE_NAME,
  AmplitudeHtmlEvent,
  AmplitudePayloadType,
  AmplitudeCssSelectorButton,
  INPUT_FALLBACK_VALUE,
} from 'app/core/constants/amplitude-helpers';

@Directive({
  selector: AmplitudeCssSelectorButton,
})
export class AmplitudeButtonDirective {
  constructor(private amplitudeService: AmplitudeService) {}

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    if (e && e.currentTarget) {
      const button = e.currentTarget as HTMLElement;
      const attr: Attr | null = button.attributes.getNamedItem(AMPLITUDE_INPUT_ATTRIBUTE_NAME);

      const input: string =
        (attr && attr.value) ||
        button.textContent ||
        button.getAttribute('id') ||
        INPUT_FALLBACK_VALUE;

      this.amplitudeService.submitOrganicEvent(
        {
          input,
          type: AmplitudePayloadType.Button,
          value: AmplitudeHtmlEvent.Click,
        },
        button
      );
    }
  }
}
