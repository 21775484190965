// Libraries
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers

@Component({
  selector: 'app-form-field-link-modal',
  templateUrl: './form-field-link-modal.component.html',
})
export class FormFieldLinkModalComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  // This is the FormDslNode associated with this modal
  @Input() child: any;
  // This is the title of the modal content
  @Input() modalTitle: string;
  // This is the subtitle of the modal content
  @Input() modalSubTitle: string;
  // This is the text preceding the hyperlink text
  @Input() modalPreLinkText: string;
  // This is the hyperlink text
  @Input() modalLinkText: string;
  // This is the text following the hyperlink text
  @Input() modalPostLinkText: string;
  // This is the body of the modal content
  @Input() modalBody: string;
  // This is the location of an image displayed in the modal
  @Input() modalImage: string;
  // These are CSS classes to remove when the modal is presented
  @Input() removeClasses: string[];
  // This flag is used as a signal to show/hide the modal
  @Input() public open = false;
  formTree: any;
  // Map from a member of removeClasses to elements with this class that were deactivated during modal presentation
  deactivatedElements: { [key: string]: any[] } = {};

  ngOnInit() {
    this.formTree = [{ ...this.child, labelText: '' }];
  }

  closeModal() {
    Object.entries(this.deactivatedElements).forEach((pair) => {
      const removedClass = pair[0];
      const deactivatedElements = pair[1];
      for (let i = 0; i < deactivatedElements.length; i++) {
        const deactivatedElement = deactivatedElements[i];
        deactivatedElement.classList.add(removedClass);
      }
    });
    this.open = false;
  }

  openModal() {
    if (this.removeClasses) {
      this.removeClasses.forEach((remClass) => {
        const remElements = document.getElementsByClassName(remClass);
        for (let i = 0; i < remElements.length; i++) {
          const remElement = remElements[i];
          if (!this.deactivatedElements[remClass]) {
            this.deactivatedElements[remClass] = [];
          }
          this.deactivatedElements[remClass].push(remElement);
          remElement.classList.remove(remClass);
        }
      });
    }
    this.open = true;
  }
}
