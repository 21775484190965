import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CrossSellConfiguration } from './configs';
import { AmplitudeService } from '../../core/services/amplitude.service';

@Component({
  selector: 'app-product-cross-sell',
  styleUrls: ['./product-cross-sell.component.scss'],
  templateUrl: './product-cross-sell.component.html',
})
export class ProductCrossSellComponent implements OnChanges {
  @Output() handleClose = new EventEmitter<{
    close: boolean;
    submit: boolean;
  }>();
  @Input() open: boolean;
  @Input() config: CrossSellConfiguration;

  constructor(protected amplitudeService: AmplitudeService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.config && !!changes.open?.currentValue && !changes.open?.previousValue) {
      this.amplitudeService.track({
        eventName: this.config.renderEventName,
        detail: this.config.baseProduct,
      });
    }
  }

  close() {
    this.handleClose.emit({ close: true, submit: false });
  }

  submit() {
    this.handleClose.emit({ close: false, submit: true });
  }
}
