<!-- main app container -->
<div class="banner-alert" *ngIf="alertService.shouldShowBanner()">
  <div class="pill-tag pill-tag__success-inverted pill-tag__margin">Notice</div>
  <span
    class="alert-text alert-text__main js-alert-message"
    [innerHTML]="alertService.getMessage()"
  ></span>
  <span
    class="icon-close banner-button"
    (click)="alertService.handleClickedClose()"
    (keyup.enter)="alertService.handleClickedClose()"
  ></span>
</div>
<router-outlet *ngIf="!isMaintenanceMode"></router-outlet>
<div *ngIf="isMaintenanceMode">
  <main class="maintenance-frame">
    <h1>🚧 Maintenance 🚧</h1>
    <p>
      We’re sorry for the inconvenience. Our portal is currently down for maintenance. For
      assistance, please visit our
      <a href="https://attunehelp.zendesk.com/hc/en-us/">Attune Help Center</a>.
    </p>
    <p>Thanks!</p>
  </main>
</div>
<app-toasts></app-toasts>
