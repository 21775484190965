import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { routing } from 'app/app.routes';
import { SharedModule } from 'app/shared/shared.module';

import { ForgotPasswordComponent } from 'app/shared/login/forgot-password.component';
import { LoginComponent } from 'app/shared/login/login.component';
import { PasswordComponent } from 'app/shared/login/password.component';

@NgModule({
  declarations: [ForgotPasswordComponent, LoginComponent, PasswordComponent],
  exports: [ForgotPasswordComponent, LoginComponent, PasswordComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, routing, SharedModule],
})
export class LoginModule {}
