import { Routes, RouterModule } from '@angular/router';

import { Always } from 'app/core/guards/always.guard';
import { DevModeOnly } from 'app/core/guards/dev-mode-only.guard';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { FeatureFlagEnabledGuard } from 'app/core/guards/feature-flag-enabled.guard';
import { SupportAuthGuard } from 'app/core/guards/support-auth.guard';

import {
  ATTUNE_WC_NEW_QUOTE_ROUTE,
  ATTUNE_WC_EDIT_QUOTE_ROUTE,
  ATTUNE_WC_DRAFT_QUOTE_ROUTE,
} from 'app/workers-comp/attune/helpers/route-utilities';

import { AttuneWcSubmissionSummaryComponent } from 'app/workers-comp/attune/components/submission-summary/attune-wc-submission-summary.component';

import { AmplitudeTestComponent } from 'app/shared/spec_helpers/amplitude-test/amplitude-test.component';
import { AttuneWcBindPolicyDetailsComponent } from 'app/workers-comp/attune/components/form-pages/bind/policy-details/attune-wc-bind-policy-details.component';
import { AttuneWcBindPaymentDetailsComponent } from 'app/workers-comp/attune/components/form-pages/bind/payment-details/attune-wc-bind-payment-details.component';
import { AttuneWcBindQuoteComponent } from 'app/workers-comp/attune/components/form/bind/attune-wc-bind-quote.component';
import { InvoicesPageComponent } from 'app/features/invoices/pages/invoices-page/invoices-page.component';
import { AttuneBopBindFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-bind-form-page/attune-bop-bind-form-page.component';
import { AttuneBopQuoteDraftFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-draft-form-page/attune-bop-quote-draft-form-page.component';
import { AttuneBopQuoteEditFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-edit-form-page/attune-bop-quote-edit-form-page.component';
import { AttuneBopQuoteDetailsPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-details-page/attune-bop-quote-details-page.component';
import { AttuneBopQuoteNewFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-new-form-page/attune-bop-quote-new-form-page.component';
import { AttuneBopSubmissionDocumentPageComponent } from 'app/features/attune-bop/pages/attune-bop-submission-document-page/attune-bop-submission-document-page.component';
import { CoalitionCyberQuoteFormComponent } from 'app/features/coalition/components/coalition-cyber-quote-form/coalition-cyber-quote-form.component';
import { CoalitionCyberRiskProfileComponent } from './features/coalition/components/coalition-cyber-risk-profile/coalition-cyber-risk-profile.component';
import { AttuneBopEndorseFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-endorse-form-page/attune-bop-endorse-form-page.component';
import { ForgotPasswordComponent } from 'app/shared/login/forgot-password.component';
import { HiscoxGlQuotePageComponent } from 'app/features/hiscox/pages/hiscox-gl-quote-page/hiscox-gl-quote-page.component';
import { SteppedFormRedirectPageComponent } from 'app/shared/stepped-form/stepped-form-account-redirect-page.component';
import { HabAdditionalCoveragesPageComponent } from 'app/hab/components/form-pages/hab-additional-coverages-page.component';
import { HabBindLossRunsPageComponent } from 'app/hab/components/form-pages/hab-bind-loss-runs-page.component';
import { HabBindPaymentDetailsComponent } from 'app/hab/components/form-pages/hab-bind-payment-details.component';
import { HabBindPolicyDetailsComponent } from 'app/hab/components/form-pages/hab-bind-policy-details.component';
import { HabBindQuoteComponent } from 'app/hab/components/form/hab-bind-quote.component';
import { HabBuildingPageComponent } from 'app/hab/components/form-pages/hab-building-page.component';
import { HabGeneralLiabilityPageComponent } from 'app/hab/components/form-pages/hab-general-liability-page.component';
import { HabGuidelinesPageComponent } from 'app/hab/components/form-pages/hab-guidelines-page.component';
import { HabLossHistoryPageComponent } from 'app/hab/components/form-pages/hab-loss-history-page.component';
import { HabPolicyDetailsPageComponent } from 'app/hab/components/form-pages/hab-policy-details-page.component';
import { HabPolicyPaneComponent } from 'app/hab/components/policy-pane/hab-policy-pane.component';
import { HabQuoteFormComponent } from 'app/hab/components/form/hab-quote-form.component';
import { SupportPageComponent } from 'app/features/support/pages/support-page/support-page.component';
import { SupportHelpCenterPageComponent } from 'app/features/support/pages/support-help-center-page/support-help-center-page.component';
import { InsuredAccountComponent } from 'app/features/insured-account/pages/insured-account/insured-account.component';
import { InsuredAccountNewFormComponent } from 'app/features/insured-account/pages/insured-account-form/insured-account-new-form.component';
import { InvoicesInsuredPageComponent } from 'app/features/invoices/pages/invoices-insured-page/invoices-insured-page.component';
import { InvoicesPolicyPageComponent } from 'app/features/invoices/pages/invoices-policy-page/invoices-policy-page.component';
import { InvoiceRedirectPageComponent } from 'app/features/invoices/pages/invoices-redirect-page/invoice-redirect-page.component';
import { LibertyMutualBopFormComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bop-form/liberty-mutual-bop-form.component';
import { LibertyMutualBindQuoteFormComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bind-quote-form/liberty-mutual-bind-quote-form.component';
import { LibertyMutualBindPaymentPlanComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bind-payment-plan/liberty-mutual-bind-payment-plan.component';
import { LibertyMutualBindPaymentDetailsComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bind-payment-details/liberty-mutual-bind-payment-details.component';
import { LibertyMutualCpspQuoteFormComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-cpsp-quote-form/liberty-mutual-cpsp-quote-form.component';
import { SupportLiveChatPageComponent } from './features/support/pages/support-live-chat-page/support-live-chat-page.component';
import { LoginComponent } from 'app/shared/login/login.component';
import { ManageSettingsComponent } from 'app/shared/settings/settings.component';
import { OnboardingClassificationPageComponent } from 'app/features/onboarding/pages/onboarding-classification-page/onboarding-classification-page.component';
import { OnboardingPageComponent } from 'app/features/onboarding/pages/onboarding-page/onboarding-page.component';
import { OnboardingIntroductionPageComponent } from 'app/features/onboarding/pages/onboarding-introduction-page/onboarding-introduction-page.component';
import { PasswordComponent } from './shared/login/password.component';
import { InvoicesPaymentReceiptPageComponent } from 'app/features/invoices/pages/invoices-payment-receipt-page/invoices-payment-receipt-page.component';
import { PolicyDetailsPaneComponent } from 'app/shared/insured-account/policy-details-pane.component';
import { PasswordResetRedirectComponent } from 'app/shared/login/password-reset-redirect.component';
import { HiscoxPlQuotePageComponent } from 'app/features/hiscox/pages/hiscox-pl-quote-page/hiscox-pl-quote-page.component';
import { HiscoxQuoteDetailsPageComponent } from 'app/features/hiscox/pages/hiscox-quote-details-page/hiscox-quote-details-page.component';

import { PolicyDetailsRedirectComponent } from 'app/shared/insured-account/policy-details-redirect.component';
import { QuotingGameComponent } from 'app/shared/games/quoting-game.component';
import { RewardsPageComponent } from 'app/features/rewards/pages/rewards-page/rewards-page.component';
import { InvoicesSchedulePageComponent } from 'app/features/invoices/pages/invoices-schedule-page/invoices-schedule-page.component';
import { SupportEnhancementPageComponent } from 'app/features/support/pages/support-enhancement-page/support-enhancement-page.component';
import { SupportNewsfeedPageComponent } from 'app/features/support/pages/support-newsfeed-page/support-newsfeed-page.component';
import { TeamSettingsComponent } from 'app/shared/settings/team-settings.component';
import { FinancialInfoComponent } from 'app/shared/settings/financial-info.component';
import { CommissionInfoComponent } from 'app/shared/settings/commission/commission-info.component';
import { InvoicesTermsAndConditionsPageComponent } from 'app/features/invoices/pages/invoices-terms-and-conditions-page/invoices-terms-and-conditions-page.component';
import { UnsubscribeComponent } from 'app/shared/unsubscribe/unsubscribe.component';
import { WcBindQuoteComponent } from 'app/workers-comp/employers/components/wc-bind-quote.component';
import { WcPolicyPaneComponent } from 'app/workers-comp/employers/components/wc-policy-pane.component';
import { WcQuoteFormComponent } from 'app/workers-comp/employers/components/wc-quote-form.component';
import { HiscoxBindPageComponent } from 'app/features/hiscox/pages/hiscox-bind-page/hiscox-bind-page.component';
import { HiscoxBindPaymentPlanPageComponent } from 'app/features/hiscox/pages/hiscox-bind-payment-plan-page/hiscox-bind-payment-plan-page.component';
import { HiscoxBindPaymentDetailsPageComponent } from 'app/features/hiscox/pages/hiscox-bind-payment-details-page/hiscox-bind-payment-details-page.component';
import { DigitalCarrierQuoteDetailsComponent } from 'app/features/digital-carrier/pages/digital-carrier-quote-details/digital-carrier-quote-details.component';
import { FormDslTestComponent } from 'app/shared/form-dsl-test/form-dsl-test.component';
import { FormDslMultiStepTestFormComponent } from 'app/shared/form-dsl-test/form-dsl-multi-step-test-form.component';
import { FormDslMultiStepEditTestFormComponent } from 'app/shared/form-dsl-test/form-dsl-multi-step-edit-test-form.component';

import { ActivityPageComponent } from './features/activity/pages/activity-page/activity-page.component';
import { ActivityOverviewPageComponent } from './features/activity/pages/activity-overview-page/activity-overview-page.component';
import { ActivityRolloverPageComponent } from './features/activity/pages/activity-rollover-page/activity-rollover-page.component';
import { AttuneBopReplacementCoverageDocumentPageComponent } from './features/attune-bop/pages/attune-bop-replacement-coverage-document-page/attune-bop-replacement-coverage-document-page.component';
import { CoalitionCyberBindFormContainerComponent } from './features/coalition/components/coalition-cyber-bind-form-container/coalition-cyber-bind-form-container.component';
import { PolicyAutomationLandingComponent } from './bop/components/policy-automation-landing/policy-automation-landing.component';
import { AttuneBopBundleBindFormPageComponent } from './features/attune-bop/pages/attune-bop-bundle-bind-form-page/attune-bop-bundle-bind-form-page.component';
import { BOOLEAN_FLAG_NAMES } from './core/services/feature-flag.service';
import { InsuredAccountNewQuoteComponent } from './features/insured-account/pages/insured-account-new-quote/insured-account-new-quote.component';
import { BundleQuoteDetailsComponent } from './features/bundle/pages/bundle-quote-details/bundle-quote-details.component';
import { WcQuotePaneComponent } from './workers-comp/employers/components/wc-quote-pane.component';
import { AppetiteCheckerPageComponent } from './features/appetite-checker/pages/appetite-checker-page/appetite-checker-page.component';
import { CommissionStatementComponent } from './shared/settings/commission/commission-statement.component';
import { HiscoxBindServicingInfoPageComponent } from './features/hiscox/pages/hiscox-bind-servicing-info-page/hiscox-bind-servicing-info-page.component';
import { AiCheckerPageComponent } from './features/ai-checker/pages/ai-checker-page/ai-checker-page.component';
import { AttuneWcQuotePaneComponent } from './workers-comp/attune/components/quote-details-pane/attune-wc-quote-pane.component';
import { InsuredAccountQuotesComponent } from './features/insured-account/pages/insured-account-quotes/insured-account-quotes.component';
import { CallbackComponent } from './callback.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [Always] },
  { path: 'callback', component: CallbackComponent, canActivate: [Always] },
  {
    path: 'form-dsl-demo/single-step/:config/new',
    component: FormDslTestComponent,
    canActivate: [DevModeOnly],
  },
  {
    path: 'form-dsl-demo/multi-step/:config/new',
    component: FormDslMultiStepTestFormComponent,
    canActivate: [DevModeOnly],
  },
  {
    path: 'form-dsl-demo/multi-step/:config/edit',
    component: FormDslMultiStepEditTestFormComponent,
    canActivate: [DevModeOnly],
  },
  {
    path: 'amplitude-test',
    component: AmplitudeTestComponent,
    canActivate: [DevModeOnly],
  },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [Always] },
  { path: 'reset-password', component: PasswordComponent, canActivate: [Always] },
  { path: 'invite', component: PasswordComponent, canActivate: [Always] },
  { path: 'set-password', component: PasswordComponent, canActivate: [Always] },
  { path: 'game', component: QuotingGameComponent, canActivate: [AuthGuard] },
  { path: 'accounts/new', component: InsuredAccountNewFormComponent, canActivate: [AuthGuard] },
  { path: 'unsubscribe', component: UnsubscribeComponent, canActivate: [Always] },
  {
    path: 'password-reset-redirect',
    component: PasswordResetRedirectComponent,
    canActivate: [Always],
  },
  {
    path: 'onboarding',
    component: OnboardingPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        canActivate: [AuthGuard],
        component: OnboardingIntroductionPageComponent,
        path: 'introduction',
      },
      {
        canActivate: [AuthGuard],
        component: OnboardingClassificationPageComponent,
        path: 'classification',
      },
    ],
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        canActivate: [AuthGuard],
        component: InsuredAccountQuotesComponent,
        path: ':accountId',
      },
      {
        canActivate: [AuthGuard],
        component: InsuredAccountNewQuoteComponent,
        path: ':accountId/quotes/new',
      },
      {
        canActivate: [AuthGuard],
        component: AttuneBopQuoteDetailsPageComponent,
        path: ':accountId/bop/policies/:policyId',
      },
      {
        canActivate: [AuthGuard],
        component: HabPolicyPaneComponent,
        path: ':accountId/hab/policies/:policyId',
      },
      {
        canActivate: [AuthGuard],
        component: PolicyDetailsRedirectComponent,
        path: ':accountId/policy/:policyId',
      },
      {
        canActivate: [AuthGuard],
        component: InvoiceRedirectPageComponent,
        path: 'accounts/invoice-list/:accountId',
      },
      {
        canActivate: [AuthGuard],
        component: PolicyDetailsPaneComponent,
        path: ':accountId/terms/:policyId/:termNumber',
      },
      {
        canActivate: [AuthGuard],
        component: WcPolicyPaneComponent,
        path: ':accountId/workers-comp/policies/:policyIdentifier',
      },
      {
        canActivate: [AuthGuard],
        component: WcQuotePaneComponent,
        path: ':accountId/workers-comp/quotes/:quoteUuid',
      },
      {
        canActivate: [AuthGuard, FeatureFlagEnabledGuard],
        component: AttuneWcQuotePaneComponent,
        path: ':accountId/attune/workers-comp/quotes/:quoteNumber',
        data: {
          flagName: BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP,
        },
      },
      {
        canActivate: [AuthGuard],
        component: DigitalCarrierQuoteDetailsComponent,
        path: ':accountId/quotes/:quoteUuid',
      },
      {
        canActivate: [AuthGuard],
        component: DigitalCarrierQuoteDetailsComponent,
        path: ':accountId/policies/:policyUuid',
      },
      {
        canActivate: [AuthGuard],
        component: HiscoxQuoteDetailsPageComponent,
        path: ':accountId/gl/details',
      },
      {
        canActivate: [AuthGuard],
        component: HiscoxQuoteDetailsPageComponent,
        path: ':accountId/pl/details',
      },
      {
        canActivate: [AuthGuard],
        component: BundleQuoteDetailsComponent,
        path: ':accountId/bundle/:bundleUuid',
      },
    ],
    component: InsuredAccountComponent,
    path: 'accounts',
  },
  {
    canActivate: [AuthGuard],
    component: AttuneBopQuoteNewFormPageComponent,
    path: 'accounts/:accountId/bop/new',
  },
  {
    canActivate: [AuthGuard],
    component: AttuneBopBindFormPageComponent,
    path: 'accounts/:accountId/bop/policies/:policyId/bind',
  },
  {
    canActivate: [AuthGuard, FeatureFlagEnabledGuard],
    component: AttuneBopBundleBindFormPageComponent,
    path: 'accounts/:accountId/bundle-bind',
    data: {
      flagName: BOOLEAN_FLAG_NAMES.BOP_CYBER_BUNDLE_BIND,
    },
  },
  {
    canActivate: [AuthGuard],
    component: HabBindQuoteComponent,
    path: 'accounts/:accountId/hab/policies/:quoteId/bind',
    children: [
      {
        canActivate: [AuthGuard],
        component: HabBindLossRunsPageComponent,
        path: 'loss-runs',
      },
      {
        canActivate: [AuthGuard],
        component: HabBindPolicyDetailsComponent,
        path: 'policy-details',
      },
      {
        canActivate: [AuthGuard],
        component: HabBindPaymentDetailsComponent,
        path: 'payment-details',
      },
    ],
  },
  {
    canActivate: [AuthGuard],
    component: AttuneBopQuoteEditFormPageComponent,
    path: 'accounts/:accountId/bop/policies/:jobNumber/edit',
  },
  {
    canActivate: [AuthGuard],
    component: AttuneBopEndorseFormPageComponent,
    path: 'accounts/:accountId/bop/endorsements/:jobNumber',
  },
  {
    canActivate: [AuthGuard],
    component: AttuneBopQuoteDraftFormPageComponent,
    path: 'accounts/:accountId/bop/drafts/:draftId',
  },
  {
    canActivate: [AuthGuard],
    component: AttuneBopSubmissionDocumentPageComponent,
    path: 'accounts/:accountId/bop/policies/:policyId/submission-summary',
  },
  {
    canActivate: [AuthGuard],
    component: WcQuoteFormComponent,
    path: 'accounts/:accountId/workers-comp/:action',
  },
  {
    canActivate: [AuthGuard],
    component: WcBindQuoteComponent,
    path: 'accounts/:accountId/workers-comp/policies/:quoteUuid/bind',
  },
  {
    canActivate: [AuthGuard],
    component: HiscoxBindPageComponent,
    path: 'accounts/:accountId/hiscox/:productType/policies/:quoteUuid/bind/:version',
    children: [
      {
        canActivate: [AuthGuard],
        component: HiscoxBindServicingInfoPageComponent,
        path: 'working-with-hiscox',
      },
      {
        canActivate: [AuthGuard],
        component: HiscoxBindPaymentPlanPageComponent,
        path: 'payment-plan',
      },
      {
        canActivate: [AuthGuard],
        component: HiscoxBindPaymentDetailsPageComponent,
        path: 'payment-details',
      },
    ],
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        canActivate: [AuthGuard],
        component: SteppedFormRedirectPageComponent,
        path: 'account',
      },
      {
        canActivate: [AuthGuard],
        component: HabGuidelinesPageComponent,
        path: 'guidelines',
      },
      {
        canActivate: [AuthGuard],
        component: HabPolicyDetailsPageComponent,
        path: 'policy-details',
      },
      {
        canActivate: [AuthGuard],
        component: HabLossHistoryPageComponent,
        path: 'loss-history',
      },
      {
        canActivate: [AuthGuard],
        component: HabBuildingPageComponent,
        path: 'location/:locationId/building/:buildingId',
      },
      {
        canActivate: [AuthGuard],
        component: HabAdditionalCoveragesPageComponent,
        path: 'additional-coverages',
      },
      {
        canActivate: [AuthGuard],
        component: HabGeneralLiabilityPageComponent,
        path: 'general-liability',
      },
    ],
    component: HabQuoteFormComponent,
    path: 'accounts/:accountId/hab/new',
  },
  ATTUNE_WC_NEW_QUOTE_ROUTE,
  ATTUNE_WC_EDIT_QUOTE_ROUTE,
  ATTUNE_WC_DRAFT_QUOTE_ROUTE,
  {
    // Cannot be feature flag guarded otherwise direct link will not work.
    canActivate: [AuthGuard],
    component: AttuneWcSubmissionSummaryComponent,
    path: 'accounts/:accountId/attune/workers-comp/quotes/:quoteId/submission-summary',
  },
  {
    canActivate: [AuthGuard, FeatureFlagEnabledGuard],
    component: AttuneWcBindQuoteComponent,
    path: 'accounts/:accountId/attune/workers-comp/quotes/:quoteId/bind',
    children: [
      {
        component: AttuneWcBindPolicyDetailsComponent,
        path: 'policy-details',
      },
      {
        component: AttuneWcBindPaymentDetailsComponent,
        path: 'payment-details',
      },
    ],
    data: {
      flagName: BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP,
    },
  },
  {
    canActivate: [AuthGuard],
    component: HiscoxGlQuotePageComponent,
    path: 'accounts/:accountId/gl/edit',
  },
  {
    canActivate: [AuthGuard],
    component: HiscoxGlQuotePageComponent,
    path: 'accounts/:accountId/gl/new',
  },
  {
    canActivate: [AuthGuard],
    component: HiscoxPlQuotePageComponent,
    path: 'accounts/:accountId/pl/edit',
  },
  {
    canActivate: [AuthGuard],
    component: HiscoxPlQuotePageComponent,
    path: 'accounts/:accountId/pl/new',
  },
  {
    canActivate: [AuthGuard],
    component: LibertyMutualBopFormComponent,
    path: 'accounts/:accountId/lm-bop/:quoteId/edit',
  },
  {
    canActivate: [AuthGuard],
    component: LibertyMutualBindQuoteFormComponent,
    path: 'accounts/:accountId/lm-bop/quotes/:quoteIdentifier/bind',
    children: [
      {
        canActivate: [AuthGuard],
        component: LibertyMutualBindPaymentPlanComponent,
        path: 'payment-plan',
      },
      {
        canActivate: [AuthGuard],
        component: LibertyMutualBindPaymentDetailsComponent,
        path: 'payment-details',
      },
    ],
  },
  {
    canActivate: [AuthGuard],
    component: LibertyMutualCpspQuoteFormComponent,
    path: 'accounts/:accountId/lm-cpsp/:quoteId/edit',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberQuoteFormComponent,
    path: 'accounts/:accountId/cyber-admitted/new',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberQuoteFormComponent,
    path: 'accounts/:accountId/cyber-admitted/quick',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberQuoteFormComponent,
    path: 'accounts/:accountId/cyber-admitted/:quoteId/edit',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberBindFormContainerComponent,
    path: 'accounts/:accountId/cyber-admitted/quotes/:quoteId/bind',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberQuoteFormComponent,
    path: 'accounts/:accountId/cyber-surplus/new',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberQuoteFormComponent,
    path: 'accounts/:accountId/cyber-surplus/quick',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberQuoteFormComponent,
    path: 'accounts/:accountId/cyber-surplus/:quoteId/edit',
  },
  {
    canActivate: [Always],
    component: CoalitionCyberRiskProfileComponent,
    path: 'cyber/quotes/:quoteId/cyber-risk-profile',
  },
  {
    canActivate: [AuthGuard],
    component: CoalitionCyberBindFormContainerComponent,
    path: 'accounts/:accountId/cyber-surplus/quotes/:quoteId/bind',
  },
  {
    canActivate: [AuthGuard],
    component: LibertyMutualBindQuoteFormComponent,
    path: 'accounts/:accountId/lm-cpsp/quotes/:quoteIdentifier/bind',
    children: [
      {
        canActivate: [AuthGuard],
        component: LibertyMutualBindPaymentPlanComponent,
        path: 'payment-plan',
      },
      {
        canActivate: [AuthGuard],
        component: LibertyMutualBindPaymentDetailsComponent,
        path: 'payment-details',
      },
    ],
  },
  {
    children: [
      {
        canActivate: [AuthGuard],
        component: TeamSettingsComponent,
        path: '',
      },
      {
        canActivate: [AuthGuard],
        component: FinancialInfoComponent,
        path: 'finances',
      },
      {
        canActivate: [AuthGuard],
        component: CommissionInfoComponent,
        path: 'commission',
      },
    ],
    canActivate: [AuthGuard],
    component: ManageSettingsComponent,
    path: 'settings',
  },
  {
    canActivate: [Always],
    component: CommissionStatementComponent,
    path: 'commission-statement/:producerCode/:statementDate',
  },
  {
    children: [
      {
        canActivate: [AuthGuard],
        component: ActivityOverviewPageComponent,
        path: '',
      },
      {
        canActivate: [AuthGuard],
        component: ActivityRolloverPageComponent,
        path: 'rollover',
      },
    ],
    canActivate: [AuthGuard],
    component: ActivityPageComponent,
    path: 'activity',
  },
  {
    canActivate: [AuthGuard],
    component: AttuneBopQuoteNewFormPageComponent,
    path: 'sample/accounts/:accountId/bop/new',
  },
  {
    canActivate: [AuthGuard],
    path: 'sample/accounts/new',
    component: InsuredAccountNewFormComponent,
  },
  {
    canActivate: [SupportAuthGuard],
    children: [
      {
        canActivate: [SupportAuthGuard],
        component: SupportPageComponent,
        path: 'form/:formId',
      },
      {
        // NOTE: this is not being routed to ATM because it is not maintained. Not slated to be deleted because it might be used in the future.
        canActivate: [AuthGuard],
        component: SupportEnhancementPageComponent,
        path: 'enhancement-library',
      },
      {
        component: SupportNewsfeedPageComponent,
        path: 'news',
      },
    ],
    component: SupportPageComponent,
    path: 'support',
  },
  { path: 'legacy-support', redirectTo: '/support' },
  {
    canActivate: [SupportAuthGuard],
    children: [
      {
        canActivate: [SupportAuthGuard],
        component: SupportHelpCenterPageComponent,
        path: 'form/:formId',
      },
    ],
    component: SupportHelpCenterPageComponent,
    path: 'help-center',
  },
  {
    canActivate: [Always],
    component: PolicyAutomationLandingComponent,
    path: 'bop/automation/:linkId',
  },
  {
    canActivate: [Always],
    component: SupportLiveChatPageComponent,
    path: 'live-chat',
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        canActivate: [AuthGuard],
        component: InvoicesPageComponent,
        path: ':id',
      },
    ],
    component: InvoicesPageComponent,
    path: 'billing', // This should probably be named 'invoices'
    runGuardsAndResolvers: 'always',
  },
  {
    component: AiCheckerPageComponent,
    path: 'ai-checker',
    canActivate: [AuthGuard, FeatureFlagEnabledGuard],
    data: {
      flagName: BOOLEAN_FLAG_NAMES.AI_CHECKER_FEATURE,
    },
  },
  {
    path: 'bop/replacement-coverage/:accountNumber',
    component: AttuneBopReplacementCoverageDocumentPageComponent,
    canActivate: [Always],
  },
  { path: 'bop/invoice/:id', component: InvoicesPolicyPageComponent, canActivate: [Always] },
  { path: 'hab/invoice/:id', component: InvoicesPolicyPageComponent, canActivate: [Always] },
  {
    path: 'bop/invoice/:id/receipt',
    component: InvoicesPaymentReceiptPageComponent,
    canActivate: [Always],
  },
  { path: 'invoice/:id/schedule', component: InvoicesSchedulePageComponent, canActivate: [Always] },
  {
    canActivate: [Always],
    component: InvoicesTermsAndConditionsPageComponent,
    path: 'invoice/terms-and-conditions',
  },
  {
    path: 'appetite',
    component: AppetiteCheckerPageComponent,
    canActivate: [AuthGuard],
  },
  { path: 'rewards', component: RewardsPageComponent, canActivate: [AuthGuard] },

  // invoice list page
  { path: 'insured/:accountId', component: InvoicesInsuredPageComponent, canActivate: [Always] },

  // otherwise redirect to home
  { path: '', redirectTo: '/activity', pathMatch: 'full' },

  // TODO(olex): Should be a 404 page
  { path: '**', redirectTo: '/accounts' },
];

export const routing = RouterModule.forRoot(appRoutes, {
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
});
