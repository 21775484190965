import { Component, Input, OnInit } from '@angular/core';
import { formatMoneyNoCents } from '../../../../shared/helpers/number-format-helpers';
import { UntypedFormBuilder } from '@angular/forms';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { NEPTUNE_FLOOD_GUIDE } from '../../../support/models/support-constants';
import {
  isQuotedQuoteResponse,
  NeptuneFloodRiskColorValue,
  QuoteResponse,
} from '../../../digital-carrier/models/types';
import { environment } from '../../../../../environments/environment';

const NEPTUNE_AGENT_HUB_URL = environment.production
  ? 'https://neptuneflood.com/agent-hub/#/auth/'
  : 'https://uat.neptuneflood.com/agent-hub/#/auth/';

@Component({
  selector: 'app-neptune-flood-details',
  templateUrl: './neptune-flood-details.component.html',
})
export class NeptuneFloodDetailsComponent implements OnInit {
  @Input() neptuneFloodQuoteResponse:
    | (QuoteResponse & {
        premium: string;
        pasId: string;
        details: {
          link: string;
          riskColorCode?: NeptuneFloodRiskColorValue;
        };
      })
    | null;
  @Input() compact = false;

  showHelperModal = false;

  formatMoneyNoCents = formatMoneyNoCents;
  isQuotedQuoteResponse = isQuotedQuoteResponse;
  NEPTUNE_FLOOD_GUIDE = NEPTUNE_FLOOD_GUIDE;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    this.amplitudeService.track({
      eventName: 'neptune_flood_quote_rendered',
      detail: this.neptuneFloodQuoteResponse ? this.neptuneFloodQuoteResponse.pasId : '',
    });
  }

  continueToNeptuneQuote() {
    this.amplitudeService.track({
      eventName: 'neptune_flood_quote_opened',
      detail: this.neptuneFloodQuoteResponse ? this.neptuneFloodQuoteResponse.pasId : '',
    });
  }

  continueToNeptuneAgentHub() {
    this.amplitudeService.track({
      eventName: 'neptune_flood_agent_hub_opened',
      detail: this.neptuneFloodQuoteResponse ? this.neptuneFloodQuoteResponse.pasId : '',
    });
  }

  close() {
    this.showHelperModal = false;
  }

  agentHubLinkFromQuoteLink(quoteLink: string): string {
    const splitLink = quoteLink.split('/');
    const auth = splitLink[splitLink.length - 1];
    return `${NEPTUNE_AGENT_HUB_URL}${auth}`;
  }
}
