// Libraries
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-form-field-text',
  templateUrl: './form-field-text.component.html',
})
export class FormFieldTextComponent implements OnInit {
  @Input() inputId: string;
  @Input() labelText: string;
  @Input() subLabelText: string | null = null;
  @Input() questionNote: string;
  @Input() specifierText: string;
  @Input() nameOfFormControl: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() maxLength: string;
  @Input() minLength: string;
  @Input() cssClass: string;
  @Input() describedBy: string;
  @Input() tooltipCssClass = 'more-info-tip tool-tip tool-tip__large';
  @Input() isSensitiveInfo = false;
  @Input() tooltipText: string;
  @Input() value: string | number;
  @Input() placeholder = '';
  @Input() inputType: 'text' | 'number' | 'url' | 'email' | 'tel' = 'text';
  @Input() readonly: boolean;
  @Input() mask = false;
  @Input() enableAutocomplete = true;
  @Input() autocomplete = 'on';
  @Output() focusOut: EventEmitter<null> = new EventEmitter();
  @Input() errorText = '';
  @Input() showErrorText = false;

  getControl = getControl;

  fieldInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.form, this.submitted);
  }

  derivedValue(): string | number {
    if (this.value === 0 || this.value === '') {
      return this.value;
    }

    return this.value || getControl(this.form, this.nameOfFormControl).value;
  }

  ngOnInit() {
    if (this.form) {
      getControl(this.form, this.nameOfFormControl).setValue(this.derivedValue());
    }
  }

  handleFocusOut() {
    this.focusOut.emit();
  }

  isNumberType() {
    return this.inputType === 'number';
  }

  autocompleteValue() {
    return this.enableAutocomplete ? this.autocomplete : 'new-password';
  }

  shouldShowErrorText() {
    if (this.showErrorText) {
      // Note: Show error text if showErrorText is explicitly set
      return true;
    }
    // Note: Also show error text if the component consumer passed in error text to show
    return !!this.errorText;
  }

  getErrorText() {
    if (this.errorText) {
      return this.errorText;
    }
    return 'Field is required';
  }
}
