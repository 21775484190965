import { Injectable } from '@angular/core';

@Injectable()
export class FullstoryService {
  trackEvent(eventName: string, payload: Record<string, string>) {
    // Uncomment the following line to debug Fullstory custom events
    // console.log(`Tracked Fullstory event: ${eventName}`, payload);
    if ((<any>window).FS && (<any>window).FS.event) {
      (<any>window).FS.event(eventName, payload);
    }
  }

  getSessionUrl(): string {
    if ((<any>window).FS && (<any>window).FS.getCurrentSessionURL) {
      return (<any>window).FS.getCurrentSessionURL(true);
    }
    return '';
  }
}
