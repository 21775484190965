<ng-template #classCodeResult let-code="result" let-term="term">
  <ngb-highlight
    class="wc-classification-code"
    [ngClass]="{
      'wc-classification-code__easy-rate': code.easyRate,
      'wc-classification-code__not-quoteable': !code.quoteable
    }"
    [result]="code.classCode"
    [term]="term"
  ></ngb-highlight>
  <sup class="wc-classification-seq">{{ code.classSeq }}</sup>
  <ngb-highlight
    class="wc-classification-description"
    [result]="code.description"
    [term]="term"
  ></ngb-highlight>
</ng-template>

<app-form-field-dropdown-search
  inputId="{{ prefix }}"
  labelText="Employee classification code"
  placeholderText="Choose a class"
  typeaheadPlaceholderText="Search classifications"
  [submitted]="submitted"
  [queryMethod]="searchCodes"
  [resultTemplate]="classCodeResult"
  [formatter]="mapCodeToLabel"
  (triggerAmplitudeOnClassCodeSelect)="triggerAmplitudeOnClassCodeSelect($event)"
  [targetFormControl]="employeeClassification.get('code')"
>
</app-form-field-dropdown-search>

<app-form-field-text
  inputId="{{ prefix }}-payroll"
  placeholder="$"
  [form]="employeeClassification"
  [submitted]="submitted"
  labelText="Payroll"
  nameOfFormControl="remuneration"
  appMoneyMask="WITHOUT_DECIMAL"
>
</app-form-field-text>
