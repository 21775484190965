import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { assaultAndBatteryCoverageOptions } from 'app/hab/constants';
import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';
import { getControl } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-hab-general-liability-page',
  templateUrl: './hab-general-liability-page.component.html',
})
export class HabGeneralLiabilityPageComponent {
  form: UntypedFormGroup;
  formPath = 'generalLiability';

  public habPerOccurenceAndAggregateLimitOptions: { [key: string]: string } = {
    '$1M / $2M': '10000',
  };

  public habAssaultAndBatteryOptions: { [key: string]: string } = assaultAndBatteryCoverageOptions;

  public habEmployeeBenefitsAggregateLimitOptions: { [key: string]: string } = {
    '$1,000,000': '1000000',
    '$2,000,000': '2000000',
  };

  constructor(protected formService: HabQuoteFormService) {
    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
    if (!this.form) {
      throw new Error(`formPath (${this.formPath}) not found`);
    }
  }

  public hasSubmitted() {
    return this.formService.submitted;
  }

  public getRetroactiveDateErrors() {
    const retroactiveDateControl = this.form.get('employeeBenefitsLiability.retroactiveDate');
    if (!retroactiveDateControl || !retroactiveDateControl.errors) {
      return '';
    }
    return retroactiveDateControl.errors.retroactiveDateError;
  }

  public indentedFieldMarginCss(formControlName: string): string {
    return getControl(this.form, formControlName).disabled ? '' : 'form-field__no-margin';
  }
}
