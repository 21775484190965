import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export interface PaginationData {
  totalItems: number;
  startPage: number;
  startIndex: number;
  pages: number[];
  endIndex: number;
  totalPages: number;
  pageSize: number;
  endPage: number;
  currentPage: number;
}

@Injectable()
export class InvoicesPaginationService {
  getPage(totalItems: number, currentPage: number = 1, pageSize: number = 5) {
    const totalPages = Math.ceil(totalItems / pageSize);
    const startPage = 1;
    const endPage = totalPages;
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    const pages = _.range(startPage, endPage + 1);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
