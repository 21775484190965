<app-left-navigation></app-left-navigation>

<div class="billing-page">
  <div class="billing-page-content">
    <div class="billing-page-header">
      <h3 class="billing-page-title">Invoices</h3>

      <div class="search-box search-box__billing">
        <input
          id="billing-page-search"
          name="billing-page-search"
          type="text"
          placeholder="Search account name or account #"
          [formControl]="billingQuery"
          (keyup.enter)="search()"
        />
        <span
          *ngIf="!billingQuery.value"
          class="icon-magnifier-1 search-box-icon"
          (click)="search()"
          (keyup.enter)="search()"
        ></span>
        <span
          *ngIf="billingQuery.value"
          class="icon-close search-box-icon search-box-icon__close"
          (click)="clear()"
          (keyup.enter)="clear()"
        ></span>
      </div>

      <div class="billing-filters-container">
        <div class="select-container" [ngClass]="{ 'select-container__readonly': loading }">
          <select
            [(ngModel)]="filterStatus"
            (ngModelChange)="updateFilterStatus($event)"
            id="billing-filter-status"
          >
            <option *ngFor="let status of filterStatusOptions" value="{{ status.value }}">
              Filter status - {{ status.name }}
            </option>
          </select>
        </div>

        <div class="select-container" [ngClass]="{ 'select-container__readonly': loading }">
          <select
            [(ngModel)]="numberOfInvoicesToFetch"
            (ngModelChange)="updateRowsPerPage($event)"
            id="billing-filter-invoices-per-page"
          >
            <option *ngFor="let perPage of rowsPerPageOptions" value="{{ perPage }}">
              Display per page - {{ perPage }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="billing-page-content">
      <div class="billing-page-sub-header">
        <div *ngIf="invoices" class="billing-pagination">
          <button
            (click)="previousPage()"
            [disabled]="!hasPreviousPage || loading"
            class="button button__secondary button__small button__no-margin"
          >
            <span class="icon-arrow-left button-icon button-icon__solo"></span>
          </button>
          <button
            (click)="nextPage()"
            [disabled]="!hasNextPage || loading"
            class="button button__secondary button__small button__no-margin"
          >
            <span class="icon-arrow-right button-icon button-icon__solo"></span>
          </button>
          <span class="billing-pagination-copy" *ngIf="invoices.length > 0">
            Showing page {{ getPageNumber() }} of {{ getPageTotal() }}
          </span>
          <span class="billing-pagination-copy" *ngIf="invoices.length === 0"
            >No invoices to display</span
          >
        </div>
      </div>
    </div>

    <div class="billing-table-container">
      <table>
        <thead>
          <tr>
            <th class="td__lob">LOB</th>
            <th>Business name</th>
            <th>Due date</th>
            <th>Status</th>
            <th class="td__right">Amount</th>
            <th class="td__right">Due</th>
            <th class="td__center"></th>
          </tr>
        </thead>

        <tbody class="tbody__striped">
          <ng-container *ngIf="loading">
            <tr *ngFor="let i of placeholderRange()" class="support-table-row">
              <td>
                <div class="table-loading-pdf-icon"></div>
              </td>
              <td><div class="table-loading-cell"></div></td>
              <td><div class="table-loading-cell"></div></td>
              <td><div class="table-loading-cell"></div></td>
              <td class="td__right"><div class="table-loading-cell"></div></td>
              <td class="td__right"><div class="table-loading-cell"></div></td>
              <td class="td__center"><div class="table-loading-cell"></div></td>
            </tr>
          </ng-container>

          <ng-container *ngIf="!loading">
            <tr *ngFor="let invoice of invoices" class="js-invoice-row">
              <td>
                <div class="product-badge product-badge__small" [ngClass]="getBadge(invoice)"></div>
              </td>
              <td
                data-column-name="Business Name"
                data-amplitude-input-name="billing_list_account_link"
              >
                {{ invoice.businessName }}
              </td>
              <td data-column-name="Due Date">
                {{ getDueDate(invoice, 'MMM D, YYYY') }}
              </td>
              <td data-column-name="Status">
                <span class="pill-tag billing-status__{{ invoiceStatus(invoice) }}">
                  {{ invoiceStatus(invoice) }}</span
                >
              </td>
              <td data-column-name="Amount" class="td__right">
                ${{ invoice.amountOriginallyBilled }}
              </td>
              <td data-column-name="Due" class="td__right">${{ invoice.amountDue }}</td>
              <td class="td__right">
                <a
                  class="button button__small button__transparent button__no-margin"
                  target="_blank"
                  [routerLink]="getInvoiceUrlSegments(invoice)"
                  [queryParams]="{ token: invoice.transactionId }"
                >
                  View invoice
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div
        *ngIf="invoices && invoices.length"
        class="billing-pagination billing-pagination__bottom"
      >
        <button
          (click)="previousPage()"
          [disabled]="!hasPreviousPage || loading"
          class="button button__secondary button__small button__no-margin"
        >
          <span class="icon-arrow-left button-icon button-icon__solo"></span>
        </button>
        <button
          (click)="nextPage()"
          [disabled]="!hasNextPage || loading"
          class="button button__secondary button__small button__no-margin"
        >
          <span class="icon-arrow-right button-icon button-icon__solo"></span>
        </button>
        <span class="billing-pagination-copy" *ngIf="invoices.length > 0">
          Showing invoices {{ indexOfFirstInvoiceToFetch + 1 }} -
          {{ indexOfFirstInvoiceToFetch + min(numberOfInvoicesToFetch, invoices.length) }}
        </span>
        <span class="billing-pagination-copy" *ngIf="invoices.length === 0"
          >No invoices to display</span
        >
      </div>
    </div>
  </div>
</div>
