import {
  Color,
  PopularClassCodeIndustry,
  RecommendationAttributes,
  State,
  TopClassCode,
} from './types';

export const INDUSTRY_OPTIONS = {
  'All Industries': 'All Industries',
  'Artisan Makers': 'Artisan Makers',
  Contractor: 'Contractor',
  Mercantile: 'Mercantile',
  Office: 'Office',
  'Processing and Service': 'Processing and Service',
  Restaurant: 'Restaurant',
  Wholesaler: 'Wholesaler',
} as const;

// Default colors per Industry, as per UW Guidance
// https://docs.google.com/document/d/1K_I1Ula5RWN7OJTYEVUan5dsLPrhR8v1vjeo6nll5G0/edit?usp=sharing
// Note: colorOverride used on a per-class code basis to handle exceptions to the default
export const industryColorMap: Record<
  Exclude<typeof PopularClassCodeIndustry[keyof typeof PopularClassCodeIndustry], 'All Industries'>,
  Color
> = {
  [INDUSTRY_OPTIONS['Artisan Makers']]: 'green',
  // Plumbers and Landscapers are red
  [INDUSTRY_OPTIONS.Contractor]: 'yellow',
  // Appliance Stores, Electronic Stores, Variety/5&10¢ Stores are red
  // Auto Parts Stores, Beverage Stores and Drugstores are green
  [INDUSTRY_OPTIONS.Mercantile]: 'yellow',
  [INDUSTRY_OPTIONS.Office]: 'green',
  // Funeral Homes and Nail Salons are yellow
  [INDUSTRY_OPTIONS['Processing and Service']]: 'green',
  // Limited Cooking and Fine Dining are yellow
  [INDUSTRY_OPTIONS.Restaurant]: 'red',
  [INDUSTRY_OPTIONS.Wholesaler]: 'green',
} as const;

export const stateRecommendationMap: Record<State, RecommendationAttributes> = {
  AL: { color: 'green', hasNonIndustryExclusions: true, excludedIndustries: [] },
  AK: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  AZ: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  AR: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  CA: { color: 'yellow', hasNonIndustryExclusions: true, excludedIndustries: [] },
  CZ: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  CO: { color: 'yellow', hasNonIndustryExclusions: false, excludedIndustries: [] },
  CT: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  DE: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  DC: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  FL: { color: 'red', hasNonIndustryExclusions: false, excludedIndustries: [] },
  GA: {
    color: 'green',
    hasNonIndustryExclusions: true,
    excludedIndustries: [
      INDUSTRY_OPTIONS['Artisan Makers'] as PopularClassCodeIndustry,
      INDUSTRY_OPTIONS.Contractor as PopularClassCodeIndustry,
      INDUSTRY_OPTIONS['Processing and Service'] as PopularClassCodeIndustry,
      INDUSTRY_OPTIONS.Restaurant as PopularClassCodeIndustry,
    ],
  },
  GU: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  HI: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  ID: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  IL: { color: 'green', hasNonIndustryExclusions: true, excludedIndustries: [] },
  IN: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  IA: { color: 'yellow', hasNonIndustryExclusions: false, excludedIndustries: [] },
  KS: { color: 'yellow', hasNonIndustryExclusions: false, excludedIndustries: [] },
  KY: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  LA: { color: 'red', hasNonIndustryExclusions: false, excludedIndustries: [] },
  ME: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  MD: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  MA: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  MI: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  MN: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  MS: { color: 'green', hasNonIndustryExclusions: true, excludedIndustries: [] },
  MO: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  MT: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  NE: { color: 'yellow', hasNonIndustryExclusions: false, excludedIndustries: [] },
  NV: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  NH: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  NJ: {
    color: 'yellow',
    hasNonIndustryExclusions: false,
    excludedIndustries: [INDUSTRY_OPTIONS.Mercantile as PopularClassCodeIndustry],
  },
  NM: { color: 'yellow', hasNonIndustryExclusions: false, excludedIndustries: [] },
  NY: { color: 'red', hasNonIndustryExclusions: false, excludedIndustries: [] },
  NC: { color: 'green', hasNonIndustryExclusions: true, excludedIndustries: [] },
  ND: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  OH: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  OK: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  OR: { color: 'yellow', hasNonIndustryExclusions: false, excludedIndustries: [] },
  PA: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  PR: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  RI: {
    color: 'green',
    hasNonIndustryExclusions: false,
    excludedIndustries: [INDUSTRY_OPTIONS.Restaurant as PopularClassCodeIndustry],
  },
  SC: {
    color: 'green',
    hasNonIndustryExclusions: true,
    excludedIndustries: [
      INDUSTRY_OPTIONS.Mercantile as PopularClassCodeIndustry,
      INDUSTRY_OPTIONS.Restaurant as PopularClassCodeIndustry,
    ],
  },
  SD: { color: 'yellow', hasNonIndustryExclusions: false, excludedIndustries: [] },
  TN: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  TX: {
    color: 'green',
    hasNonIndustryExclusions: true,
    excludedIndustries: [
      INDUSTRY_OPTIONS['Artisan Makers'] as PopularClassCodeIndustry,
      INDUSTRY_OPTIONS.Contractor as PopularClassCodeIndustry,
      INDUSTRY_OPTIONS['Processing and Service'] as PopularClassCodeIndustry,
      INDUSTRY_OPTIONS.Restaurant as PopularClassCodeIndustry,
    ],
  },
  UT: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  VT: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  VI: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  VA: { color: 'green', hasNonIndustryExclusions: true, excludedIndustries: [] },
  WA: { color: 'n/a', hasNonIndustryExclusions: false, excludedIndustries: [] },
  WV: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  WI: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
  WY: { color: 'green', hasNonIndustryExclusions: false, excludedIndustries: [] },
};

export const topClassCodes: TopClassCode[] = [
  {
    category: PopularClassCodeIndustry.RESTAURANT,
    code: '445110',
    colorOverride: 'red', // Could be Fast Food or Limited Cooking, treating as red
    description: 'Delicatessens & Sandwich Shops',
    naicsCode: '445110',
    naicsDescription: 'Delicatessens primarily retailing a range of grocery items and meats',
    naicsHash: 'd28b40d04e6797bd031ba6299775506a',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'wc',
          pasSource: 'employers',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.RESTAURANT,
    code: '722515',
    colorOverride: 'yellow', // Limited Cooking
    description: 'Coffee Bars/Shops',
    naicsCode: '722515',
    naicsDescription:
      'Beverage (e.g., coffee, juice, soft drink) bars, nonalcoholic, fixed location',
    naicsHash: '64afef6cb78f4facbb9e58945ade67a9',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.CONTRACTOR,
    code: '238320',
    colorOverride: 'n/a',
    description: 'Painting (interior only)',
    naicsCode: '238320',
    naicsDescription: 'Painting and wallpapering',
    naicsHash: '28f415845930f77b6eb95f15c921f784',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'gl',
          pasSource: 'hiscox',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.WHOLESALER,
    code: '424410',
    colorOverride: 'n/a',
    description: 'Grocery distributors',
    naicsCode: '424410',
    naicsDescription: 'General-line groceries merchant wholesalers',
    naicsHash: '5acd771b4c48d3faefe6f813fbb7716a',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.OFFICE,
    code: '531312',
    colorOverride: 'n/a',
    description: 'Property manager',
    naicsCode: '531312',
    naicsDescription: "Property managers' offices, nonresidential real estate",
    naicsHash: '90cee4a6bb7014b934535e33c5454861',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'gl',
          pasSource: 'hiscox',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.RESTAURANT,
    code: '9082',
    colorOverride: 'n/a',
    description: 'Restaurants',
    naicsCode: '',
    naicsDescription: '',
    naicsHash: '',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'wc',
          pasSource: 'employers',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.PROCESSING_AND_SERVICE,
    code: '311811',
    colorOverride: 'n/a',
    description: 'Bakeries-Retail-With Baking on Premises',
    naicsCode: '311811',
    naicsDescription:
      'Bakeries with baking from flour on the premises, retailing not for immediate consumption',
    naicsHash: 'cd3de2c97620c79439480d4c80f15ff9',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.PROCESSING_AND_SERVICE,
    code: 'DCD',
    colorOverride: 'n/a',
    description: 'Business Consulting',
    naicsCode: '',
    naicsDescription: '',
    naicsHash: '',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'gl',
          pasSource: 'hiscox',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.CONTRACTOR,
    code: 'DSD',
    colorOverride: 'n/a',
    description: 'Electrical work (interior only)',
    naicsCode: '238210',
    naicsDescription: 'Electrical work',
    naicsHash: '6d2f5f5032b632768541ff8be8dcde72',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'gl',
          pasSource: 'hiscox',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.OFFICE,
    code: '64061',
    colorOverride: 'n/a',
    description: 'Real Estate Agents-Lessor Risk Only',
    naicsCode: '531120',
    naicsDescription: 'Lessors of nonresidential buildings (except miniwarehouses)',
    naicsHash: '487de5d0db526b5d95d58506adfb2f80',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.CONTRACTOR,
    code: '74111',
    colorOverride: 'n/a',
    description: 'Appliances & Accessories-Installation or Service or Repair-Household-Shop',
    naicsCode: '811412',
    naicsDescription:
      'Appliance, household-type, repair and maintenance services without retailing new appliances',
    naicsHash: 'b9194789b2e6aea42c6deb149dadf16b',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.OFFICE,
    code: '63611',
    colorOverride: 'n/a',
    description: 'Accounting Services-Except CPAs',
    naicsCode: '541211',
    naicsDescription: "Accountants' (i.e., CPAs) offices, certified public",
    naicsHash: '4b852ac4e94fadc894b23cbb02b01cdc',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.OFFICE,
    code: '63921',
    colorOverride: 'n/a',
    description: 'Lawyers-Lessor Risk Only',
    naicsCode: '531120',
    naicsDescription: 'Lessors of nonresidential buildings (except miniwarehouses)',
    naicsHash: '487de5d0db526b5d95d58506adfb2f80',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.OFFICE,
    code: '63671',
    colorOverride: 'n/a',
    description: 'Bookkeeping services',
    naicsCode: '541219',
    naicsDescription: 'Bookkeeping services',
    naicsHash: 'e7a1b1c4100791db00e4c3bc8dd4f652',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.PROCESSING_AND_SERVICE,
    code: '71899',
    colorOverride: 'n/a',
    description: 'Portrait photography studios',
    naicsCode: '541921',
    naicsDescription: 'Portrait photography studios',
    naicsHash: '3bce28295a7bfc5d10b4504c16b212c6',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.PROCESSING_AND_SERVICE,
    code: '9586',
    colorOverride: 'n/a',
    description: 'Beauty parlors',
    naicsCode: '812112',
    naicsDescription: 'Beauty parlors',
    naicsHash: 'bcf2a9bb2ceb0784eaa611305467cf62',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.MERCANTILE,
    code: '53127',
    colorOverride: 'n/a',
    description: 'Department stores',
    naicsCode: '452210',
    naicsDescription: 'Department stores',
    naicsHash: 'c3fb1221af20f63fa368ee60e5d72568',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.WHOLESALER,
    code: '50171',
    colorOverride: 'n/a',
    description: 'Barber/Beauty Shop Supplies Distbtrs',
    naicsCode: '423850',
    naicsDescription: 'Beauty parlor equipment and supplies merchant wholesalers',
    naicsHash: '3d6c8fcfe2ddc7bb6a74110d6dee529d',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'bop',
          pasSource: 'attune_gw',
        },
      },
    ],
  },
  {
    category: PopularClassCodeIndustry.PROCESSING_AND_SERVICE,
    code: 'DDJ',
    colorOverride: 'n/a',
    description: 'Engineering',
    naicsCode: '',
    naicsDescription: '',
    naicsHash: '',
    products: [
      {
        isEligible: true,
        productCombination: {
          product: 'pl',
          pasSource: 'hiscox',
        },
      },
    ],
  },
];
