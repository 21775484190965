export enum UserAttributes {
  ONBOARDING_ACCOUNTS_COMPLETE = 'onboarding_accounts_complete',
  ONBOARDING_ACCOUNTS_DISMISSED = 'onboarding_accounts_dismissed',
}

export interface UserAttributesResponse {
  userAttributes: {
    attribute_type: UserAttributes;
    attribute_value: string;
  }[];
}
