<div *ngIf="open && config" class="app-product-cross-sell">
  <div>
    <button type="button" class="app-product-cross-sell-close-button" (click)="close()">
      <span class="icon-close"></span><span class="sr-only">Close</span>
    </button>
  </div>
  <div class="app-product-cross-sell-content">
    <div class="app-product-cross-sell-content-header">
      <div *ngIf="config.pillTagText">
        <span class="pill-tag pill-tag__success">{{ config.pillTagText }}</span>
      </div>
      <div *ngIf="!config.header" class="app-product-cross-sell-content-body-icon">
        <div class="product-badge product-badge__{{ config.product }}">
          <div
            class="product-badge-carrier-icon product-badge-carrier-icon__{{ config.pasSource }}"
          ></div>
        </div>
      </div>
    </div>
    <div class="app-product-cross-sell-content-body">
      <div>
        <h3>{{ config.header }}</h3>
        <p class="p__big-margin" *ngIf="config.subText">{{ config.subText }}</p>
        <app-dialog-box
          *ngIf="config.dialogType"
          [type]="config.dialogType"
          [header]="config.dialogHeader"
          fullWidth="true"
        >
          <span [innerHTML]="config.dialogBody"></span>
        </app-dialog-box>
        <ul>
          <li *ngFor="let text of config.bodyTexts">
            <div class="app-product-cross-sell-body-text-icon">
              <img src="/assets/img/shield.svg" alt="" />
            </div>
            <p class="p__small-text">
              {{ text }}
            </p>
          </li>
        </ul>
      </div>
      <div *ngIf="config.header">
        <div
          class="product-badge product-badge__{{
            config.product
          }} app-product-cross-sell-content-body-icon"
        >
          <div
            class="product-badge-carrier-icon product-badge-carrier-icon__{{ config.pasSource }}"
          ></div>
        </div>
      </div>
    </div>
    <div class="app-product-cross-sell-content-footer">
      <strong *ngIf="config.footerText">{{ config.footerText }}</strong>
      <div class="app-product-cross-sell-content-footer-content">
        <ng-container *ngIf="config.percentComplete">
          <div>
            <strong class="app-product-cross-sell-footer-percent-complete-text">
              {{ config.percentComplete }}%
            </strong>
          </div>
          <div
            class="app-product-cross-sell-completed-slider app-product-cross-sell-completed-slider__{{
              config.percentComplete
            }}"
          ></div>
        </ng-container>
        <div
          *ngIf="config.submitButtonText"
          class="app-product-cross-sell-content-footer-button-container"
        >
          <button
            class="button button__primary button__small js-product-cross-sell-submit"
            (click)="submit()"
          >
            {{ config.submitButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
