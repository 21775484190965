import { Injectable } from '@angular/core';
import { get } from 'lodash';

import { CURRENT_GIT_SHA } from 'app/constants';
import { User } from 'app/shared/models/user';

import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class SentryService {
  // Set by the user service upon successful authentication
  public currentUser?: User;

  setCurrentUser(user: User) {
    this.currentUser = user;
    // Note: Set email on the global client so we get the user even for unhandle errors
    // (instead of just handled errors submitted via the notify method below)
    Sentry.setContext('user', {
      email: user.userName,
    });
    const replayUrl = (<any>window)?.DD_RUM?.getSessionReplayLink();
    if (replayUrl) {
      Sentry.setContext('replay', {
        url: replayUrl,
      });
    }
  }

  private shouldIgnore(options: any = {}) {
    const maybeUnderlyingError = get(options.metaData, 'underlyingError');
    if (!maybeUnderlyingError) {
      // If the underlyingError field is not populated, then always log the error
      return false;
    }
    // Note: Only ignore HttpErrorResponse with status of 0 and status text of "Unknown Error",
    // because these errors are often caused by ad blockers or network errors like dropped packets.
    // In any case, nobody is actively investigating them, and they clutter up logs.
    return (
      get(maybeUnderlyingError, 'name') === 'HttpErrorResponse' &&
      get(maybeUnderlyingError, 'status') === 0 &&
      get(maybeUnderlyingError, 'statusText') === 'Unknown Error'
    );
  }

  /*
  Important: This groups errors by the errorMessage in Sentry. It is very important that the errorMessage be a static string, and that metadata not be included in it

  GOOD example:
  sentryService.notify('Error fetching account', {
    metaData: {
      accountId: accountId
    }
  })

  BAD example:
  sentryService.notify(`Error fetching account: ${accountId}`)
  */
  notify(errorMessage: string, options: any = {}) {
    if (this.shouldIgnore(options)) {
      return;
    }

    const config = {
      contexts: {
        metaData: {
          ...options.metaData,
          'current-git-sha': CURRENT_GIT_SHA,
        },
      },
    };

    if (options.severity === 'info') {
      this.captureMessage(errorMessage, config);
    } else {
      this.captureException(errorMessage, config);
    }
  }

  captureException(errorMessage: string, context: any) {
    Sentry.captureException(new Error(errorMessage), context);
  }

  captureMessage(message: string, context: any) {
    Sentry.captureMessage(message, context);
  }
}
