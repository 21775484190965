<div
  role="alert"
  class="toast inform"
  [ngClass]="{
    toast__info: toast.level === InformLevel.info,
    toast__success: toast.level === InformLevel.success,
    toast__warn: toast.level === InformLevel.warning,
    toast__error: toast.level === InformLevel.error || toast.level === InformLevel.minorError
  }"
>
  <img
    class="inform-icon"
    *ngIf="toast.level === InformLevel.error || toast.level === InformLevel.minorError"
    src="assets/img/warning.svg"
    alt=""
    role="presentation"
  />
  <img
    class="inform-icon"
    *ngIf="toast.level === InformLevel.customSuccess"
    src="assets/img/{{ toast.iconName }}.svg"
    alt=""
    role="presentation"
  />
  <div class="inform-message">
    <ng-container *ngIf="toast.title">
      <h4 class="inform-title">
        {{ toast.title }}
      </h4>
      <p class="inform-submessage">
        <small>{{ toast.message }}</small>
      </p>
    </ng-container>
    <ng-container *ngIf="!toast.title">
      {{ toast.message }}
    </ng-container>
  </div>

  <button
    *ngIf="toast.button"
    class="button button__small button__secondary button__no-margin inform-button"
    (click)="close()"
  >
    {{ toast.button }}
  </button>
</div>
