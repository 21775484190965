import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';

import { getValidationMessageFromControl } from 'app/shared/helpers/form-helpers';
import {
  ContactPurposeConstants,
  ContactPurposeFormGroup,
  InsuredContactFormGroup,
} from 'app/shared/helpers/insured-contact-helpers';

@Component({
  selector: 'app-insured-contacts',
  templateUrl: './form-field-insured-contacts.component.html',
})
export class FormFieldInsuredContactsComponent {
  @Input() insuredContactOptions: ContactPurposeConstants;
  @Input() contactFormArray: FormArray<InsuredContactFormGroup<ContactPurposeConstants>>;
  @Input() submitted: boolean;
  @Input() maxNumContacts: number = 3;

  @Output() addContact: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteContact: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  addInsuredContact() {
    this.addContact.emit();
  }

  removeInsuredContact(index: number) {
    if (this.contactFormArray.length > 1) {
      return this.deleteContact.emit(index);
    }
  }

  hasContactListError() {
    return this.contactFormArray.errors;
  }

  getContactListError() {
    return getValidationMessageFromControl(this.contactFormArray);
  }

  hasContactPurposeError(contact: InsuredContactFormGroup<ContactPurposeConstants>) {
    return contact.get('contactPurpose')?.errors;
  }

  getContactPurposeError(contact: InsuredContactFormGroup<ContactPurposeConstants>) {
    const contactPurposeForm = contact.get(
      'contactPurpose'
    ) as ContactPurposeFormGroup<ContactPurposeConstants>;

    return getValidationMessageFromControl(contactPurposeForm);
  }
}
