import * as _ from 'lodash';
import { UNAUTHENTICATED_PAGE_URLS } from 'app/constants';

export const searchByPrefix = (
  prefix: string,
  searchIndex: string[],
  collection: string[][]
): string[] => {
  if (!prefix || prefix.length < 2) {
    return [];
  }

  const transformedPrefix = prefix.toLocaleLowerCase();

  const start: number = _.sortedIndex(searchIndex, transformedPrefix);
  const lastIndex: number = transformedPrefix.length - 1;
  const prefixPlusOne: string =
    transformedPrefix.substring(0, lastIndex) +
    String.fromCharCode(transformedPrefix.charCodeAt(lastIndex) + 1);
  const end: number = _.sortedIndex(searchIndex, prefixPlusOne);
  return collection.slice(start, end).map((code) => code[0]);
};

export const longestCommonSubsequence = (a: string, b: string): string => {
  const aSub = a.substr(0, a.length - 1);
  const bSub = b.substr(0, b.length - 1);

  if (a.length === 0 || b.length === 0) {
    return '';
  } else if (a.charAt(a.length - 1) === b.charAt(b.length - 1)) {
    return longestCommonSubsequence(aSub, bSub) + a.charAt(a.length - 1);
  } else {
    const x = longestCommonSubsequence(a, bSub);
    const y = longestCommonSubsequence(aSub, b);
    return x.length > y.length ? x : y;
  }
};

export const isUnauthenticatedUrl = (url: string) => {
  return UNAUTHENTICATED_PAGE_URLS.map((urlString) => new RegExp(urlString)).some(
    (unauthenticatedUrl) => unauthenticatedUrl.test(url)
  );
};
