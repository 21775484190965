import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AttuneWcBindFormService } from 'app/workers-comp/attune/services/attune-wc-bind-form.service';
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';
import {
  ContactListGroup,
  TransformedContacts,
  getAllPossibleContacts,
  insertNewBrokerContact,
} from 'app/shared/helpers/broker-contact-helpers';
import { PolicyDetailsFormGroup } from 'app/workers-comp/attune/models/bind-form.model';
import { ATTUNE_WC_INSURED_CONTACT_OPTIONS } from 'app/workers-comp/attune/constants';
import {
  addInsuredContact,
  removeInsuredContact,
  InsuredContactFormGroup,
} from 'app/shared/helpers/insured-contact-helpers';

@Component({
  selector: 'app-wc-bind-details-page',
  templateUrl: './attune-wc-bind-policy-details.component.html',
})
export class AttuneWcBindPolicyDetailsComponent extends SteppedFormPageComponent<
  AttuneWcBindFormService,
  PolicyDetailsFormGroup
> {
  form: PolicyDetailsFormGroup;
  formPath = 'policyDetails';

  constructor(protected formService: AttuneWcBindFormService) {
    super(formService);
  }

  get insuredContactsFormArr() {
    return this.form.get('insuredContacts') as FormArray<
      InsuredContactFormGroup<typeof ATTUNE_WC_INSURED_CONTACT_OPTIONS>
    >;
  }

  get insuredContactOptions() {
    return ATTUNE_WC_INSURED_CONTACT_OPTIONS;
  }

  addInsuredContact() {
    addInsuredContact(this.insuredContactsFormArr, ATTUNE_WC_INSURED_CONTACT_OPTIONS);
  }

  removeInsuredContact(index: number) {
    if (this.insuredContactsFormArr.length > 1) {
      removeInsuredContact(this.insuredContactsFormArr, index);
    }
  }

  // End of insured contact methods

  // Start of broker contact methods

  get brokerContactsFormArr() {
    return this.form.get('brokerContacts') as ContactListGroup;
  }

  getAllPossibleBrokerContacts(): TransformedContacts {
    return getAllPossibleContacts(
      this.formService.currentContact,
      this.formService.subscribedContacts,
      this.formService.availableContacts
    );
  }

  removeBrokerContact(index: number) {
    if (this.brokerContactsFormArr?.length > 1) {
      this.brokerContactsFormArr.removeAt(index);
    }
  }

  addBrokerContact() {
    const defaultContactId = Object.keys(this.getAllPossibleBrokerContacts())[0];
    insertNewBrokerContact(this.brokerContactsFormArr, defaultContactId);
  }

  // End of broker contact methods
}
