import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
  AfterViewChecked,
  OnChanges,
} from '@angular/core';

import * as _ from 'lodash';

// TODO(WJC): make these configurable based on attributes
const VERTICAL_AXIS_COUNT = 6;
const VERTICAL_AXIS_COLOR = '#80939b'; // gray55, in CSS
// Height of each bar, in pixels
const BAR_HEIGHT = 12;

export interface ChartDatum {
  label: string;
  values: number[];
}

export interface ChartData {
  items: ChartDatum[];
}

@Component({
  selector: 'app-activity-rollover-bar-chart',
  templateUrl: 'activity-rollover-bar-chart.component.html',
})
export class ActivityRolloverBarChartComponent
  implements AfterViewInit, OnChanges, AfterViewChecked
{
  @Input() chartData: ChartData;
  // Text to show if the bar chart cannot be rendered (e.g. for screen readers)
  @Input() alternativeText: string;
  @Input() barColors: string[];

  valueLabels: string[] = [];

  @ViewChild('chartCanvas')
  canvas: ElementRef<HTMLCanvasElement>;

  context: CanvasRenderingContext2D;

  // Hardcoded values related to rendering
  // TODO(WJC): consider making some of these configurable (maybe indirectly?)
  xMargin = 4;
  yMargin = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      this.calculateValueLabels();
    }
  }

  ngAfterViewInit() {
    this.context = this.canvas.nativeElement.getContext('2d') as CanvasRenderingContext2D;
    this.renderBarChart();
  }

  ngAfterViewChecked() {
    this.renderBarChart();
  }

  calculateValueLabels() {
    const maxValue = this.getMaxValue(this.chartData);
    const newLabels = new Array(VERTICAL_AXIS_COUNT);
    for (let ii = 0; ii < VERTICAL_AXIS_COUNT; ii++) {
      const labelValue = ii * (maxValue / VERTICAL_AXIS_COUNT);

      // Limit to one decimal of precision, for cleaner display.
      newLabels[ii] = Math.floor(labelValue).toString();
    }
    this.valueLabels = newLabels;
  }

  hasValidData() {
    if (
      !this.barColors ||
      !this.chartData ||
      !this.chartData.items ||
      !this.chartData.items.length
    ) {
      return false;
    }

    const maxValueLength = _.max(this.chartData.items.map((datum) => datum.values.length)) || 0;
    if (this.barColors.length < maxValueLength) {
      // There aren't enough colors to label the data
      return false;
    }

    return true;
  }

  getMaxValue(data: ChartData): number {
    return (
      _.max(
        data.items.map((datum) => {
          return _.max(datum.values);
        })
      ) || 0
    );
  }

  renderBarChart() {
    const canvas = this.canvas.nativeElement;

    if (!this.hasValidData()) {
      return;
    }

    // Double-scaling, so that the canvas looks good on retina displays
    this.context.scale(2, 2);
    // Necessary to do this manually to avoid stretching
    canvas.width = canvas.offsetWidth * 2;
    canvas.height = canvas.offsetHeight * 2;

    const { width, height } = canvas;
    this.context.clearRect(0, 0, width, height);

    const maxValue = this.getMaxValue(this.chartData);

    // Calculate general dimensions of chart
    const availableSpace = (height - this.yMargin * 2) / this.chartData.items.length;
    const numberOfTopBars = this.chartData.items[0].values.length;
    const topOfTopBar = (availableSpace - numberOfTopBars * BAR_HEIGHT) / 2 + this.yMargin;

    const lastBarValues = _.last(this.chartData.items);
    const numberOfBottomBars = lastBarValues ? lastBarValues.values.length : 0;
    const bottomOfBottomBar =
      height - (availableSpace - numberOfBottomBars * BAR_HEIGHT) / 2 - this.yMargin;

    // Draw the vertical axes
    for (let ii = 0; ii < VERTICAL_AXIS_COUNT; ii++) {
      this.context.strokeStyle = VERTICAL_AXIS_COLOR;
      this.context.beginPath();
      this.context.lineWidth = 0.5;
      this.context.moveTo(
        this.xMargin + ii * ((width - this.xMargin * 2) / (VERTICAL_AXIS_COUNT - 1)),
        topOfTopBar
      );
      this.context.lineTo(
        this.xMargin + ii * ((width - this.xMargin * 2) / (VERTICAL_AXIS_COUNT - 1)),
        bottomOfBottomBar
      );
      this.context.stroke();
    }

    // Draw the individual bars
    for (let ii = 0; ii < this.chartData.items.length; ii++) {
      const barData = this.chartData.items[ii];

      const numberOfBars = barData.values.length;
      const barTopGap = (availableSpace - numberOfBars * BAR_HEIGHT) / 2;
      const barTop = this.yMargin + ii * availableSpace + barTopGap;

      barData.values.forEach((barDatum, barIndex) => {
        if (barDatum === 0) {
          return;
        }

        const barWidth = (width - this.xMargin * 2) * (barDatum / maxValue);
        this.context.fillStyle = this.barColors[barIndex];
        this.context.fillRect(this.xMargin, barTop + BAR_HEIGHT * barIndex, barWidth, BAR_HEIGHT);
      });
    }
  }
}
