import { from, Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { OktaAuth, IDToken, AccessToken } from '@okta/okta-auth-js';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { OKTA_ISSUER_URI } from 'app/constants';

@Injectable({ providedIn: 'root' })
export class OktaAuthService {
  CLIENT_ID = environment.oktaClientId;
  ISSUER = OKTA_ISSUER_URI;
  LOGIN_REDIRECT_URI = environment.oktaLoginRedirectURI;
  LOGOUT_REDIRECT_URI = environment.oktaLogoutRedirectURI;

  oktaAuth = new OktaAuth({
    clientId: this.CLIENT_ID,
    issuer: this.ISSUER,
    redirectUri: this.LOGIN_REDIRECT_URI,
    pkce: false,
    // Okta's frontend lifetime check is buggy and creates a bad user experience
    // Instead, we should rely on our backend token expiry checks
    ignoreLifetime: true,
  });

  $isAuthenticated: Observable<boolean>;
  private observer?: Observer<boolean>;
  private username: string;
  private token: string;

  constructor(private authenticationService: AuthenticationService) {
    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then((val) => {
        observer.next(val);
      });
    });
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return !!(await this.oktaAuth.tokenManager.get('accessToken'));
  }

  async updateAuthenticationObservable() {
    if (await this.isAuthenticated()) {
      this.observer?.next(true);
    }
  }

  handleAuthentication(): Observable<string> {
    return from(this.oktaAuth.token.parseFromUrl()).pipe(
      switchMap((tokenContainer) => {
        const accessToken = tokenContainer.tokens.accessToken as AccessToken;
        this.oktaAuth.tokenManager.add('idToken', tokenContainer.tokens.idToken as IDToken);
        this.oktaAuth.tokenManager.add('accessToken', accessToken);

        this.username = accessToken.claims.sub;
        this.token = accessToken.accessToken;

        datadogRum.setUser({
          id: String(this.username).toLowerCase(),
          name: String(this.username).toLowerCase(),
          email: String(this.username).toLowerCase(),
        });

        return this.authenticationService.loginOkta(this.username, this.token);
      }),
      map((result) => {
        if (result === true) {
          // Retrieve the saved URL and navigate back
          const storedUrl = sessionStorage.getItem('okta-app-url') as string;

          // Set user identity for our various 3rd-party services
          if ((<any>window).Sprig) {
            (<any>window).Sprig('setEmail', String(this.username).toLowerCase());
          }
          if ((<any>window).amplitude) {
            (<any>window).amplitude.getInstance().setUserId(String(this.username).toLowerCase());
          }
          if ((<any>window).FS) {
            (<any>window).FS.identify(String(this.username).toLowerCase(), {
              email: String(this.username).toLowerCase(),
            });
          }

          return storedUrl;
        } else {
          throw Error(
            `There was an error authenticating the tokens passed in the URL for user ${this.username}`
          );
        }
      })
    );
  }

  async logout() {
    await this.oktaAuth.signOut({
      // Because the token we use for auth is our own JWT, not the Okta token, revoking the Okta token is unnecessary
      revokeAccessToken: false,
      clearTokensBeforeRedirect: true,
    });
    this.authenticationService.logout();
  }
}
