import * as _ from 'lodash';
import { CobOverrideGroups, HISCOX_PRODUCTS } from './hiscox-types';

// Determine whether to apply override node for a given class of business
/**
 * @param businessClasses are the key from PL_BUSINESS_CLASSES_V4, which are the cob codes
 * @param classOfBusinessOverrideGroups is PL_COB_OVERRIDE_GROUPS, e.g. {... INS_AGENT: ['DDL', PL_BUSINESS_CLASS_CODES_V4.INSURANCE_AGENT_AGENCIES_INSURANCE], // Insurance agent ...}
 * @param classOfBusinessGroupName is the COB group name, e.g. INS_AGENT
 * @param classOfBusiness is the Hiscox COB code, e.g. for PL 72233000_11101100_02000000.722330 (with NAICS code), for GL 72233000_11101100_02000000
 * @param version is the quote version v3 or v4
 * @param productType is the product type, e.g. gl or pl
 */
export const shouldApplyOverrideNode = (
  businessClasses: string[],
  classOfBusinessOverrideGroups: CobOverrideGroups,
  classOfBusinessGroupName: string,
  classOfBusiness: string,
  productType: HISCOX_PRODUCTS
) => {
  // if cobSet is a legitimate class of business,
  // check equality with cob
  if (_.hasIn(businessClasses, classOfBusinessGroupName)) {
    return classOfBusiness === classOfBusinessGroupName;
  }

  if (productType === HISCOX_PRODUCTS.gl) {
    if (_.hasIn(classOfBusinessOverrideGroups, classOfBusinessGroupName)) {
      // v4 cob codes in AP are a union of Hiscox code + NAICS code, but we only need the Hiscox codes for GL, since
      // the dynamic questions API only uses the Hiscox code
      const overrideCobGroupCodes = classOfBusinessOverrideGroups[classOfBusinessGroupName];
      const hiscoxCodes = overrideCobGroupCodes.map((cobCode) => {
        // For each code, e.g. "72233000_11101100_02000000.722330", split and return only the first element
        return cobCode.split('.')[0];
      });

      return _.includes(hiscoxCodes, classOfBusiness);
    }
  }
  if (productType === HISCOX_PRODUCTS.pl) {
    if (_.hasIn(classOfBusinessOverrideGroups, classOfBusinessGroupName)) {
      // v4 cob codes in AP are a union of Hiscox code + NAICS code
      const hiscoxCodes = classOfBusinessOverrideGroups[classOfBusinessGroupName];

      return _.includes(hiscoxCodes, classOfBusiness);
    }
  }

  return false;
};
