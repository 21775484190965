import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

import { LibertyMutualBindFormService } from 'app/features/liberty-mutual/services/liberty-mutual-bind-form.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import { FrontendQuote } from 'app/features/digital-carrier/models/types';
import {
  LIBERTY_MUTUAL_PAYMENT_OPTIONS,
  LibertyMutualPaymentPlan,
  LibertyMutualQuote,
  PaymentPlan,
} from 'app/features/liberty-mutual/models/common-typings';
import { getControl } from 'app/shared/helpers/form-helpers';

// NOTE: only the following payment plans allow for AUTOMATIC payments, excluded '10'/'9' payment plans do not
const INSTALLMENTS: { [key in LibertyMutualPaymentPlan]: number } = {
  FL: 1,
  QT: 4,
  MO: 12,
};

@Component({
  selector: 'app-liberty-mutual-bind-payment-plan',
  templateUrl: './liberty-mutual-bind-payment-plan.component.html',
})
export class LibertyMutualBindPaymentPlanComponent implements OnInit, OnDestroy {
  accountId: string;
  productType: string;
  quoteIdentifier: string;
  quoteId: string;
  loadingAccountDetails = true;

  insuredAccount: Observable<InsuredAccount | null>;
  insuredAccountSubject: BehaviorSubject<InsuredAccount | null> = new BehaviorSubject(null);
  quote: Observable<FrontendQuote | null>;

  paymentPlanOptions = LIBERTY_MUTUAL_PAYMENT_OPTIONS;
  _numberOfPayments: LibertyMutualPaymentPlan = 'FL'; // setting default payment to start at Full (FL)

  private sub: Subscription = new Subscription();

  constructor(
    public formService: LibertyMutualBindFormService,
    protected route: ActivatedRoute,
    protected router: Router,
    private insuredAccountService: InsuredAccountService,
    private digitalCarrierQuoteService: DigitalCarrierQuoteService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.parent) {
      this.productType = this.route.snapshot.parent.params.productType;
      this.accountId = this.route.snapshot.parent.params.accountId;
      this.quoteIdentifier = this.route.snapshot.parent.params.quoteIdentifier;
      this.insuredAccount = this.insuredAccountSubject.asObservable();

      this.loadAccountDetails();
      this.getQuoteDetails();
      this.subscribeToPaymentPlanChange();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  submitted() {
    return this.formService.submitted;
  }

  handleSubmit(submitEvent?: Event): boolean {
    return this.formService.stepForward();
  }

  loadAccountDetails() {
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => {
            return this.accountId === insuredAccount.id.toString();
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccountSubject.next(insuredAccount);
          this.loadingAccountDetails = false;
        })
    );
  }

  getQuoteDetails() {
    if (this.route.snapshot.parent) {
      this.quote = this.digitalCarrierQuoteService.getQuoteDetails(this.quoteIdentifier);
    }
  }

  subscribeToPaymentPlanChange() {
    const paymentPlanControl = getControl(this.formService.form, 'paymentPlan');
    paymentPlanControl.valueChanges.subscribe((paymentPlan) => {
      const newPayment = paymentPlan.numberOfPayments;
      if (newPayment !== this._numberOfPayments) {
        // only trigger payment setup to be called again when the
        // number of payments changes
        this._numberOfPayments = paymentPlan.numberOfPayments;
      }
    });
  }

  getPaymentPlanDetails(
    loadedQuoteDetails: LibertyMutualQuote,
    paymentPlanSelection: LibertyMutualPaymentPlan
  ) {
    const paymentPlans: PaymentPlan[] = loadedQuoteDetails.details.paymentOption;
    const plan = paymentPlans.find((paymentPlan: PaymentPlan) => {
      return paymentPlan.PaymentPlanCd === paymentPlanSelection;
    });
    return plan;
  }

  calculateInstallments(paymentPlanSelection: LibertyMutualPaymentPlan) {
    return INSTALLMENTS[paymentPlanSelection];
  }

  calculateInstallmentAmount(
    loadedQuoteDetails: LibertyMutualQuote,
    paymentPlanSelection: LibertyMutualPaymentPlan
  ) {
    const paymentPlans: PaymentPlan[] = loadedQuoteDetails.details.paymentOption;
    const plan = paymentPlans.find((paymentPlan: PaymentPlan) => {
      return paymentPlan.PaymentPlanCd === paymentPlanSelection;
    });

    if (plan) {
      const remainder =
        Number(loadedQuoteDetails.details.insuredFullToBePaidAmt.Amt) - Number(plan.DepositAmt.Amt);
      return remainder / INSTALLMENTS[paymentPlanSelection];
    } else {
      return null;
    }
  }

  calculateSubtotal(
    loadedQuoteDetails: LibertyMutualQuote,
    paymentPlanSelection: LibertyMutualPaymentPlan
  ) {
    const paymentPlans: PaymentPlan[] = loadedQuoteDetails.details.paymentOption;
    const plan = paymentPlans.find((paymentPlan: PaymentPlan) => {
      return paymentPlan.PaymentPlanCd === paymentPlanSelection;
    });

    if (plan) {
      return (
        Number(loadedQuoteDetails.details.insuredFullToBePaidAmt.Amt) - Number(plan.DepositAmt.Amt)
      );
    } else {
      return false;
    }
  }

  calculateEstimatedTotalIntegralAmount(loadedQuoteDetails: LibertyMutualQuote) {
    if (loadedQuoteDetails.details && loadedQuoteDetails.details.insuredFullToBePaidAmt) {
      const totalPremiumAmount = Number(loadedQuoteDetails.details.insuredFullToBePaidAmt.Amt);

      return Math.trunc(totalPremiumAmount);
    } else {
      return 0;
    }
  }

  calculatEstimatedTotalFractionalAmount(loadedQuoteDetails: LibertyMutualQuote) {
    if (loadedQuoteDetails.details && loadedQuoteDetails.details.insuredFullToBePaidAmt) {
      const totalPremiumAmount = Number(loadedQuoteDetails.details.insuredFullToBePaidAmt.Amt);

      if (Math.floor(totalPremiumAmount) !== totalPremiumAmount) {
        return (totalPremiumAmount - Math.trunc(totalPremiumAmount)) * 100;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  hasStateSurcharges(loadedQuoteDetails: LibertyMutualQuote) {
    return _.has(loadedQuoteDetails, 'details.taxAmt.Amt');
  }
}
