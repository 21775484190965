import * as moment from 'moment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SentryService } from 'app/core/services/sentry.service';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { QuoteFormValue } from 'app/workers-comp/attune/models/quote-form.model';
import { ATTUNE_WC_QUOTE_API } from 'app/workers-comp/attune/constants';
import {
  CreateQuoteRequest,
  GetQuoteResponse,
  GuidewireWCQuoteResponse,
} from 'app/workers-comp/attune/models/quote.model';
import { cloneDeep, omit } from 'lodash';
import { US_DATE_MASK } from 'app/constants';
import { ActionName } from '../../../shared/rewards/rewards-types';
import { tap } from 'rxjs/operators';
import { RewardsService } from '../../../shared/services/rewards.service';
import { InsuredAccount } from '../../../features/insured-account/models/insured-account.model';

interface RequoteUpdateFields {
  effectiveDate?: string;
  fein?: string;
}

@Injectable()
export class AttuneWCQuoteService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private rewardsService: RewardsService
  ) {}

  requestQuote(data: {
    quoteForm: QuoteFormValue;
    insuredAccount: InsuredAccount;
    tsRequestId: string;
  }): Observable<GuidewireWCQuoteResponse> {
    // We need to tack on accountNumber and tsRequestId to the form data.
    const payload: CreateQuoteRequest = {
      ...data.quoteForm,
      accountNumber: data.insuredAccount.id,
      tsRequestId: data.tsRequestId,
    };
    return this.http.post<GuidewireWCQuoteResponse>(ATTUNE_WC_QUOTE_API, payload).pipe(
      tap((response) => {
        this.rewardsService.submitRewardAction({
          actionName: ActionName.QUOTE_FOR_ACCOUNT,
          data: {
            insuredAccountId: data.insuredAccount.id,
            accountName: data.insuredAccount.companyName,
            carrierName: 'attune_gw',
            product: 'wc',
          },
        });
      }),
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Unable to create Attune WC quote', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return throwError(() => error);
      })
    );
  }

  getQuote(quoteNumber: string): Observable<GetQuoteResponse> {
    return this.http.get<GetQuoteResponse>(`${ATTUNE_WC_QUOTE_API}/${quoteNumber}`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Unable to get Attune WC quote', {
          severity: 'error',
          metaData: {
            quoteNumber,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return throwError(() => error);
      })
    );
  }

  requote(data: {
    quoteNumber: string;
    insuredAccount: InsuredAccount;
    tsRequestId: string;
    dataToUpdate: RequoteUpdateFields;
  }): Observable<GuidewireWCQuoteResponse> {
    const { insuredAccount, quoteNumber, tsRequestId, dataToUpdate } = data;

    return this.getQuote(quoteNumber).pipe(
      switchMap((quoteResponse) => {
        const updatedQuoteForm = this.prepareUpdatedQuoteForm(quoteResponse, dataToUpdate);

        return this.requestQuote({
          insuredAccount,
          tsRequestId,
          quoteForm: updatedQuoteForm,
        });
      })
    );
  }

  private prepareUpdatedQuoteForm(
    quoteResponse: GetQuoteResponse,
    dataToUpdate: RequoteUpdateFields
  ): QuoteFormValue {
    const clonedQuoteResponse = cloneDeep(quoteResponse);
    // We remove these identifiers from the parent quote to ensure the requote has a unique requestId and quoteNumber.
    const quoteFormWithoutParentIds = omit(clonedQuoteResponse, 'tsRequestId', 'quoteNumber');

    // Update to today or effective date passed, whichever is later.
    const udpatedQuoteForm = this.setEffectiveDateForRequote(
      quoteFormWithoutParentIds,
      dataToUpdate.effectiveDate
    );

    // Update FEIN if required
    if (dataToUpdate.fein) {
      udpatedQuoteForm.basicInfo.employerIdentificationNumber = dataToUpdate.fein;
    }

    return udpatedQuoteForm;
  }

  private setEffectiveDateForRequote(
    getQuoteResp: QuoteFormValue,
    updatedEffectiveDate?: string
  ): QuoteFormValue {
    // First make sure effective date is either today or after today.
    const today = moment().startOf('day');
    const currentEffDate = moment(getQuoteResp.basicInfo.effectiveDate, US_DATE_MASK).startOf(
      'day'
    );

    if (currentEffDate.isBefore(today)) {
      getQuoteResp.basicInfo.effectiveDate = today.format(US_DATE_MASK);
    }

    // If an effective date is passed in, we update effective date on the form.
    if (
      updatedEffectiveDate &&
      moment(updatedEffectiveDate, US_DATE_MASK).startOf('day').isSameOrAfter(today)
    ) {
      getQuoteResp.basicInfo.effectiveDate = updatedEffectiveDate;
    }

    return getQuoteResp;
  }

  private setFeinForRequoteIfRequired(getQuoteResp: QuoteFormValue, updatedFein?: string) {
    const clonedQuote = cloneDeep(getQuoteResp);

    if (updatedFein) {
      clonedQuote.basicInfo.employerIdentificationNumber = updatedFein;
    }
    return clonedQuote;
  }
}
