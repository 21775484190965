import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, AbstractControl, ValidatorFn } from '@angular/forms';
import { getControl, getFormGroup } from 'app/shared/helpers/form-helpers';

import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

import {
  FormDslData,
  FormDslStep,
  ValidatorDictionary,
  GroupConfig,
  ComplexValidatorDictionary,
  FormArrayValidators,
  HappyPathFormData,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import {
  FormDslServiceBase,
  formTreeToFormGroup,
} from 'app/shared/form-dsl/services/form-dsl-service-base';

import * as _ from 'lodash';
import { first, catchError } from 'rxjs/operators';

@Injectable()
export class FormDslSteppedFormService extends FormDslServiceBase {
  submitted = false;
  defaultFormStep = 'stepOne';
  formDefaults: { [inputId: string]: any };
  // TODO: consider combining these two dictionaries into a single data structure
  // (will likely require refactor throughout FormDSL validator configs)
  formValidators: ValidatorDictionary<string>;
  formArrayValidators: Record<string, ValidatorDictionary<string>>;
  complexFormValidators: ComplexValidatorDictionary<string>;
  formData: FormDslData = {};
  isEditing: boolean;

  // Happy path properties
  isHappyPath = false;
  patchedFormSteps: Record<string, boolean> = {};
  happyPathFormData: HappyPathFormData<string, string> = {};
  // END Happy path properties

  fillInHappyPath() {
    this.isHappyPath = true;
    const currentFormPath = this.currentStep.formPath;
    if (currentFormPath && !this.patchedFormSteps[currentFormPath]) {
      this.patchStepForHappyPath(currentFormPath);
    }
  }

  patchStepForHappyPath(currentFormPath: string) {
    this.patchedFormSteps[currentFormPath] = true;
    const formData = this.happyPathFormData[currentFormPath];
    if (formData) {
      const currentFormGroup = getFormGroup(this.form, currentFormPath);
      currentFormGroup.patchValue(formData);
    }
  }

  resetToFirstStep() {}

  // Initialize form with step configs
  initializeForm() {
    // save first step form group
    let firstFormGroup: GroupConfig = {};

    // retrieve config step configs
    const groupConfig: { [key: string]: any } = _.reduce(
      this.formDslSteps,
      (config: { [key: string]: UntypedFormGroup }, step: FormDslStep, index: number) => {
        const path = step.formPath || this.defaultFormStep;
        if (index === 0) {
          // initialize first step
          step
            .getFormTree()
            .pipe(
              first(),
              catchError((error) => {
                throw new Error(
                  `Encountered the following error while retrieving form tree: ${error}`
                );
              })
            )
            .subscribe((tree) => {
              firstFormGroup = formTreeToFormGroup(tree, this.formBuilder);
              config[path] = this.formBuilder.group(firstFormGroup);
              step.formTreeCached = tree;
            });
        } else {
          // initialize other steps
          // with placeholder config
          config[path] = this.formBuilder.group({});
        }
        return config;
      },
      {}
    );
    // setup form with step configs
    this.form = this.formBuilder.group(groupConfig);

    this.formDslSteps.forEach((step) => {
      // process special fields appropriately
      const path = step.formPath || this.defaultFormStep;
      this.formDependenciesInit(path);
    });

    // add first step
    this.addStep(0, firstFormGroup, this.isEditing);
  }

  // Set form defaults
  setFormDefaults(defaults: { [inputId: string]: any }) {
    this.formDefaults = defaults;
  }

  // Set form validators
  setFormValidators(
    validators: ValidatorDictionary<string>,
    formArrayValidators: FormArrayValidators<string, string>,
    complexFormValidators: ComplexValidatorDictionary<string>
  ) {
    this.formValidators = validators;
    this.formArrayValidators = formArrayValidators;
    this.complexFormValidators = complexFormValidators;
  }

  // Set form validators
  setIsEditing(isEditing: boolean) {
    this.isEditing = isEditing;
  }

  // Set form data
  setFormData(formData: FormDslData) {
    this.formData = formData;
  }

  // Set step defaults
  private setStepDefaults(step: FormDslStep, defaults: { [inputId: string]: any }) {
    const path = step.formPath as string;
    const form = getFormGroup(this.form, path);
    const stepKeys = _.keys(this.getRawValue()[path]);
    stepKeys.forEach((key) => {
      const control = getControl(form, key);
      if (control && key in defaults) {
        const val = defaults[key];
        control.patchValue(val);
      }
    });
  }

  setFormArrayValidators(controlName: string, formGroup: UntypedFormGroup) {
    const validators = this.formArrayValidators[controlName];

    _.forEach(validators, (validatorFns, childControlName) => {
      const childControl = getControl(formGroup, childControlName);
      if (childControl) {
        childControl.setValidators(validatorFns);
        childControl.updateValueAndValidity();
      }
    });
  }

  getComplexFormValidators(controlNameOrStep: string): ValidatorFn[] {
    const validatorGenerators = this.complexFormValidators[controlNameOrStep] || [];

    return validatorGenerators.map((generatorFunc) => {
      return generatorFunc(this.form);
    });
  }

  // Set step validators
  setStepValidators(step: FormDslStep) {
    const stepPath = step.formPath as string;
    const stepGroup = getFormGroup(this.form, stepPath);
    const controlNames = _.keys(this.getRawValue()[stepPath]);
    // set per-field validators
    controlNames.forEach((controlName) => {
      const control = stepGroup.get(controlName);
      if (!control) {
        return;
      }
      if (control instanceof UntypedFormArray) {
        // set validators for nested form arrays
        control.controls.forEach((childFormGroup: UntypedFormGroup) => {
          this.setFormArrayValidators(controlName, childFormGroup);
        });
      } else {
        const simpleValidators = this.formValidators[controlName] || [];
        const complexValidators = this.getComplexFormValidators(controlName);
        control.setValidators(_.concat(simpleValidators, complexValidators));
      }

      control.updateValueAndValidity();
    });
    // set multi-field validators
    const simpleStepValidators = this.formValidators[stepPath] || [];
    const complexStepValidators = this.getComplexFormValidators(stepPath);
    // This is where we set interstep validators
    stepGroup.setValidators(_.concat(simpleStepValidators, complexStepValidators));
    stepGroup.updateValueAndValidity();
  }

  /**
   * formData has the following format (for GL):
   *
   * ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_OperatedFromHome: "Yes"
   * ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd-DS4: "DS4"
   * ProductQuoteRqs_GeneralLiabilityQuoteRq_TRIACoverQuoteRq_CoverId: "TRIA"
   *
   *
   * populateStepData creates stepConfig by finding each key from stepGroupConfig in the formData
   *
   *
   * stepGroupConfig has keys such as:
   *
   * ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd
   * ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd-DS1
   * ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd-DS2
   * ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd-DS3
   *
   *
   * For the secondary COBs, there is no match for `ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd`
   * in formData, because related formData fields will always have an appended v3 COB code, i.e. `DS1`.
   * Therefore, if the key is `ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd`,
   * we can skip this key (prior implementation would only add matches, but an else cause was added for v4 checkbox questions).
   *
   */
  populateStepData(
    step: FormDslStep,
    stepGroupConfig: { [key: string]: any },
    formData: FormDslData
  ) {
    const path = step.formPath as string;
    const stepKeys = _.keys(stepGroupConfig);
    const stepConfig: FormDslData = {};
    // iterate through fields of step and populate
    // those that are available in formData
    stepKeys.forEach((key: string) => {
      if (key in formData) {
        // if key, e.g.
        // `ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_OperatedFromHome` or
        // `ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd-DS1`
        // is in formData, then add to stepConfig, so it can be used to populate the form
        stepConfig[key] = formData[key];
      } else {
        // check if key is a parent key for a checkbox like question,
        // Example:
        // parent key: ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ConsultantEligibilityPL
        // children of parent key:
        // "ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ConsultantEligibilityPL_AerospaceConsultingOrAdvice": "Yes",
        // "ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ConsultantEligibilityPL_ActuarialAdvice": "Yes",

        const relatedFormFieldPairs = _.entries(formData).filter(
          (formDataField: [string, string]) => {
            return (formDataField[0] as string).startsWith(key);
          }
        );
        // take previous array of pairs and join back into key pairs of an object
        const relatedFormFields = _.fromPairs(relatedFormFieldPairs);

        // For each field that is related to the parent 'key'
        for (const formField in relatedFormFields) {
          if (Object.prototype.hasOwnProperty.call(relatedFormFields, formField)) {
            // calculate the child key, everything after the parent 'key' including '_'
            const childKey = formField.split(`${key}_`)[1];
            // set up a child object to contain the key/value pairs
            const stepChildren: { [key: string]: boolean } = {};
            // if the formField in in the formData object, then there is a value for one or more children
            if (formField in formData) {
              const value = formData[formField];
              // set value to boolean 'true', since anything that is checked will have a value of 'Yes'
              stepChildren[childKey] = value === 'Yes' ? true : false;
            }

            stepConfig[key] = stepChildren;
          }
        }
      }
    });

    getFormGroup(this.form, path).patchValue(stepConfig);
  }

  // Setup form with config and data for step
  addStep(stepIndex: number, stepGroupConfig: GroupConfig, isEditing: boolean, isCached = false) {
    // metadata for step
    const step = this.formDslSteps[stepIndex];
    const path = step.formPath as string;
    const form = getFormGroup(this.form, path);
    const stepKeys = _.keys(stepGroupConfig);
    const formData = this.formData;

    // add control for each field of step
    stepKeys.forEach((key: string) => {
      // config for this key
      const keyConfig = stepGroupConfig[key];

      let control: AbstractControl;
      if (keyConfig instanceof UntypedFormArray) {
        // TODO: this is the case for arrays but would like to refactor formTreeToFormGroup
        // so that keyConfig can only be a FormControl, FormGroup, or FormArray

        // Only look at one child, so we don't set duplicate validator functions on `this.formArrayValidators`
        // This implementation assumes that the array's form group elements are uniform in structure. (This is currently the norm in our app.)
        const firstChild = keyConfig.at(0);

        // Add validators from form-dsl node config to formArrayValidators
        if (firstChild && firstChild instanceof UntypedFormGroup) {
          _.forEach(firstChild.controls, (childControl, childControlName) => {
            if (childControl.validator) {
              const validatorsToSet = _.get(this.formArrayValidators, [key, childControlName], []);
              _.set(
                this.formArrayValidators,
                [key, childControlName],
                [...validatorsToSet, childControl.validator]
              );
            }
          });
        }
        control = keyConfig;
      } else if (keyConfig instanceof UntypedFormGroup) {
        control = keyConfig;
      } else {
        // retrieve default value of group, which is located at index 0
        const fieldDefault = keyConfig[0];
        // retrieve validators of group, which is located at index 1
        const fieldValidators = keyConfig[1];

        // add validators from form-dsl node config to form validators
        if (fieldValidators && fieldValidators.length > 0) {
          if (this.formValidators[key]) {
            this.formValidators[key].push(...fieldValidators);
          } else {
            this.formValidators[key] = fieldValidators;
          }
        }
        // TODO: Consider setting all validators here, and using setStepValidators()
        // to handle complex cases (form groups, form arrays, and step validation)
        control = this.formBuilder.control(fieldDefault, fieldValidators);
      }

      // hide specified controls
      if (step.hiddenNodeIds && step.hiddenNodeIds.has(key)) {
        control.disable();
      }

      // add control to form
      form.addControl(key, control);
    });

    if (!isCached) {
      // process special fields appropriately
      this.formDependenciesInit(path);

      // set validators for step
      this.setStepValidators(step);

      if (!isEditing) {
        // set defaults for step
        this.setStepDefaults(step, this.formDefaults);
      } else {
        // populate data for step
        this.populateStepData(step, stepGroupConfig, formData);
      }
    }

    if (this.isHappyPath && !this.patchedFormSteps[path]) {
      this.patchStepForHappyPath(path);
    }
  }

  // Setup steps for form
  generateSteps(): RouteFormStep[] {
    this.FORM_STEPS = this.formDslSteps;
    return this.FORM_STEPS;
  }
}
