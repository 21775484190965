import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ordinance-law-coverage',
  templateUrl: './ordinance-law-coverage.component.html',
})
export class OrdinanceLawCoverageComponent {
  @Input() exposure: UntypedFormGroup;
  @Input() coverage: UntypedFormGroup;
  @Input() prefix: string;
  @Input() submitted: boolean;

  constructor() {}

  coverageAvailable() {
    const optedInControl = this.coverage.get('ordinanceLawCoverageOptedIn');
    return optedInControl && optedInControl.enabled;
  }
}
