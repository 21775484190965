import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { HiscoxBindFormService } from 'app/features/hiscox/services/hiscox-bind-form.service';
import {
  HISCOX_POLICY_PAYMENT_PLAN_OPTIONS,
  HiscoxPricedQuote,
  HiscoxQuoteQuotedResponse,
} from 'app/features/hiscox/models/hiscox-priced-quote';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';
import { SC_POLICY_COBS } from 'app/features/hiscox/models/gl-constants';
import { SentryService } from 'app/core/services/sentry.service';
import { AbstractControl } from '@angular/forms';
import {
  HISCOX_PRODUCTS,
  HISCOX_API_VERSION,
  agreeDisagreeOptions,
} from '../../models/hiscox-types';
import { InformService } from 'app/core/services/inform.service';
import { hasQuoteExpired } from '../../models/hiscox-customization';

@Component({
  selector: 'app-hiscox-bind-payment-plan',
  templateUrl: './hiscox-bind-payment-plan-page.component.html',
})
export class HiscoxBindPaymentPlanPageComponent implements OnInit, OnDestroy {
  accountId: string;
  productType: HISCOX_PRODUCTS;
  loadingAccountDetails: boolean;
  insuredAccount: Observable<InsuredAccount | null>;
  insuredAccountSubject: BehaviorSubject<InsuredAccount | null> = new BehaviorSubject(null);

  loadingQuoteDetails: boolean;
  paymentPlanOptions = HISCOX_POLICY_PAYMENT_PLAN_OPTIONS;
  hiscoxQuote: HiscoxPricedQuote;

  state: string;
  showSCDisclaimer: boolean;
  estimatedTotalFractional: number;
  estimatedTotalIntegral: number;

  agreeDisagree = agreeDisagreeOptions;

  // Statup values
  _numberOfPayments = 1;
  DEFAULT_PAYMENT_PLAN = HISCOX_POLICY_PAYMENT_PLAN_OPTIONS['One-time payment'];

  private sub: Subscription = new Subscription();

  constructor(
    public formService: HiscoxBindFormService,
    protected route: ActivatedRoute,
    protected router: Router,
    private insuredAccountService: InsuredAccountService,
    private hiscoxQuoteService: HiscoxQuoteService,
    private sentryService: SentryService,
    private informService: InformService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.parent) {
      const paymentPlanForm: AbstractControl | null = this.formService.form.get(
        'paymentPlan.numberOfPayments'
      );
      if (paymentPlanForm !== null && paymentPlanForm.value !== null) {
        this._numberOfPayments = paymentPlanForm.value;
      } else {
        this._numberOfPayments = 1;
      }

      this.productType = this.route.snapshot.parent.params.productType;
      this.accountId = this.route.snapshot.parent.params.accountId;
      this.insuredAccount = this.insuredAccountSubject.asObservable();

      this.loadAccountDetails();
      this.getQuoteDetails();
      this.subscribeToPaymentPlanChange();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  submitted() {
    return this.formService.submitted;
  }

  loadAccountDetails() {
    this.loadingAccountDetails = true;
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => {
            return this.accountId === insuredAccount.id.toString();
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccountSubject.next(insuredAccount);
          this.loadingAccountDetails = false;
        })
    );
  }

  redirectWithToastMessage(reason: 'INVALID_EFF_DATE' | 'INVALID_STATUS' | 'FETCH_ERROR') {
    let productTypeForEdit: 'gl' | 'pl' | null = null;
    switch (this.productType) {
      case 'gl':
        productTypeForEdit = 'gl';
        break;
      case 'pl':
        productTypeForEdit = 'pl';
        break;
    }

    const accountPagePath = ['accounts', this.accountId];
    // Fall back to account details page if there is a problem determining an edit path.
    let editQuotePath = accountPagePath;
    if (productTypeForEdit) {
      editQuotePath = ['accounts', this.accountId, productTypeForEdit, 'edit'];
    }

    let toastMessage: string;
    let pathForRedirect: string[] = ['accounts', this.accountId];
    switch (reason) {
      case 'FETCH_ERROR':
        toastMessage =
          'There was an error retrieving this quote. Please try again or reach out to our Customer Care team if the problem persists.';
        pathForRedirect = accountPagePath;
        break;
      case 'INVALID_EFF_DATE':
        toastMessage =
          'The effective date for this Hiscox quote has expired. Please edit this quote and update the effective date in order to bind.';
        pathForRedirect = editQuotePath;
        break;
      case 'INVALID_STATUS':
        toastMessage =
          'This risk must be requoted. Please correct any errors before proceeding to bind.';
        pathForRedirect = editQuotePath;
        break;
    }

    this.router.navigate(pathForRedirect, { replaceUrl: true });

    this.informService.infoToast(toastMessage);
  }

  getQuoteDetails() {
    this.loadingQuoteDetails = true;
    if (this.route.snapshot.parent) {
      const productType: HISCOX_PRODUCTS = this.route.snapshot.parent.params.productType;
      const version: HISCOX_API_VERSION = this.route.snapshot.parent.params.version;

      this.sub.add(
        this.hiscoxQuoteService.getQuote(productType, this.accountId, true).subscribe({
          next: (hiscoxQuoteResponse: HiscoxQuoteQuotedResponse) => {
            if (!hiscoxQuoteResponse) {
              this.sentryService.notify('Unable to fetch Hiscox quote during bind flow', {
                severity: 'error',
                metaData: {
                  accountId: this.accountId,
                  productType,
                  version,
                },
              });
              return this.redirectWithToastMessage('FETCH_ERROR');
            }
            this.hiscoxQuote = new HiscoxPricedQuote(hiscoxQuoteResponse);
            // Note: if the quote has expired, the broker should edit and re-quote
            // so the coverage start date is set to a valid date
            const quoteHasExpired = hasQuoteExpired(this.hiscoxQuote);
            if (quoteHasExpired) {
              return this.redirectWithToastMessage('INVALID_EFF_DATE');
            }
            if (this.hiscoxQuote.status !== 'QUOTED') {
              this.sentryService.notify(
                'Unexpected quote status when fetching Hiscox quote details',
                {
                  severity: 'info',
                  metaData: {
                    quoteId: this.hiscoxQuote.quoteId,
                    quoteStatus: this.hiscoxQuote.status,
                  },
                }
              );
              return this.redirectWithToastMessage('INVALID_STATUS');
            }

            this.state = this.hiscoxQuote.state;
            this.showSCDisclaimer = SC_POLICY_COBS.indexOf(this.hiscoxQuote.cob) !== -1;
            if (
              this.hiscoxQuote.premium &&
              Math.floor(this.hiscoxQuote.premium) !== this.hiscoxQuote.premium
            ) {
              this.estimatedTotalFractional =
                (this.hiscoxQuote.premium - Math.trunc(this.hiscoxQuote.premium)) * 100;
            } else {
              this.estimatedTotalFractional = 0;
            }
            this.estimatedTotalIntegral = Math.trunc(this.hiscoxQuote.premium || 0);
            this.loadingQuoteDetails = false;
          },
          error: (err) => {
            this.sentryService.notify('Error while trying to get Hiscox quote details', {
              severity: 'error',
              metaData: {
                error: err,
                accountId: this.accountId,
                productType,
              },
            });
            return this.redirectWithToastMessage('FETCH_ERROR');
          },
        })
      );
    }
  }

  subscribeToPaymentPlanChange() {
    // TODO: when clicking previous step, show previously selected payment plan
    this.sub.add(
      this.formService.form.valueChanges.subscribe((data) => {
        const newPayments = data.paymentPlan.numberOfPayments;
        if (newPayments !== this._numberOfPayments) {
          // only trigger payment setup to be called again when the
          // number of payments changes
          this._numberOfPayments = data.paymentPlan.numberOfPayments;
        }
      })
    );
  }

  hasStateSurcharges() {
    return this.hiscoxQuote.surcharge && typeof this.hiscoxQuote.surcharge === 'number';
  }

  handleSubmit(submitEvent?: Event): boolean {
    return this.formService.stepForward();
  }
}
