import {
  UntypedFormControl,
  Validators,
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { GwCommContact } from 'app/bop/guidewire/typings';
import { brokerEmailTypes } from 'app/constants';
import * as _ from 'lodash';

/**
 * Helper functions for working with broker contacts.
 * Broadly, these methods all assume the same form shape for this data as is assumed
 * by the BrokerContactsComponent form component (a FormArray of FormGroups).
 */

export interface AccountContact {
  roles: string[];
  email: string;
  isDefaultContact?: boolean;
}

export type TransformedContacts = Record<string, AccountContact>;

export type ContactFormGroup = FormGroup<{
  isExistingContact: FormControl<boolean | null>;
  contactId: FormControl<string | null>;
  email: FormControl<string | null>;
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  emailTypes: FormGroup<{
    Invoice: FormControl<boolean | null>;
    Renewal: FormControl<boolean | null>;
    Cancellation: FormControl<boolean | null>;
  }>;
}>;

export type ContactListGroup = FormArray<ContactFormGroup>;

export const brokerContactsListValidator = (brokerContacts: ContactListGroup) => {
  if (!brokerContacts.length) {
    return {
      emptyBrokerContactsList:
        'There must be at least one broker contact associated with the account.',
    };
  }

  let hasDuplicate = false;
  const selectedIds: Record<string, boolean> = {};
  brokerContacts.controls.forEach((contactControls) => {
    const contactIdField = contactControls.get('contactId');
    if (contactIdField && contactIdField.value) {
      if (selectedIds[contactIdField.value]) {
        hasDuplicate = true;
      } else {
        selectedIds[contactIdField.value] = true;
      }
    }
  });
  if (hasDuplicate) {
    return {
      duplicateContactId:
        'You have set up subscriptions for the same contact several times. Please set up a single set of subscriptions per contact.',
    };
  }

  const contactTypes: Record<string, boolean> = {};
  brokerContacts.controls.forEach((contactControls) => {
    Object.keys((contactControls.get('emailTypes') as FormGroup).controls).forEach(
      (controlName) => {
        if ((contactControls?.get('emailTypes')?.get(controlName) as FormControl).value) {
          contactTypes[controlName] = true;
        }
      }
    );
  });
  if (Object.keys(contactTypes).length !== Object.keys(brokerEmailTypes).length) {
    return {
      notAllEmailTypesSelected:
        'Please ensure that at least one broker is subscribed to each type of email correspondence.',
    };
  }

  return null;
};

export const brokerContactInfoValidator = (group: ContactFormGroup) => {
  if (group && !group.getRawValue().contactId) {
    const brokerEmail = <UntypedFormControl>group.get('email');

    // If the new contact is completely blank, just accept it as valid
    const rawValue = group.getRawValue();
    const hasNoTypesSelected = !_.compact(Object.values(rawValue.emailTypes)).length;
    const isCompletelyBlank =
      !rawValue.contactId &&
      !rawValue.firstName &&
      !rawValue.lastName &&
      !rawValue.email &&
      hasNoTypesSelected;
    if (isCompletelyBlank) {
      return null;
    }

    const hasEmailError = Validators.required(brokerEmail) || Validators.email(brokerEmail);
    if (hasEmailError) {
      return { hasInvalidEmail: 'Please enter a valid email address.' };
    }
    if (Validators.required(<UntypedFormControl>group.get('firstName'))) {
      return { hasNoFirstName: 'First name is required.' };
    }
    if (Validators.required(<UntypedFormControl>group.get('lastName'))) {
      return { hasNoLastName: 'Last name is required.' };
    }
  }
  return null;
};

export const getNewBrokerContactFormSection = () => {
  const formBuilder = new FormBuilder();
  return formBuilder.array<ContactFormGroup>([], brokerContactsListValidator) as ContactListGroup;
};

export const patchBrokerContactForm = (
  brokerContactForm: ContactListGroup,
  selectedContacts: TransformedContacts
) => {
  const actualContacts = Object.entries(selectedContacts).map(([contactId, contactValues]) => {
    const roleValues = _.mapValues(
      brokerEmailTypes,
      (value, key) => contactValues.roles.includes(key) || false
    );

    return {
      contactId,
      emailAddress: contactValues.email,
      emailTypes: roleValues,
    };
  });

  // Clear the existing for array, so that the new contacts can be added
  while (brokerContactForm.length) {
    brokerContactForm.removeAt(0);
  }
  const formBuilder = new FormBuilder();
  actualContacts.forEach((actualContact) => {
    brokerContactForm.push(
      formBuilder.group(
        {
          isExistingContact: formBuilder.control<boolean | null>(true, {
            validators: [Validators.required],
          }),
          contactId: formBuilder.control<string | null>(actualContact.contactId),
          email: formBuilder.control<string | null>(null),
          firstName: formBuilder.control<string | null>(null),
          lastName: formBuilder.control<string | null>(null),
          emailTypes: formBuilder.group(actualContact.emailTypes),
        },
        { validators: brokerContactInfoValidator }
      )
    );
  });
};

interface BrokerContactChange {
  contactId?: string;
  action: 'follow' | 'unfollow' | 'create';
  roles: string[];
  contactInfo?: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export const getBrokerContactChanges = (
  brokerContactForm: ContactListGroup,
  currentContacts: TransformedContacts
): BrokerContactChange[] => {
  const changes: BrokerContactChange[] = [];
  const removedContacts = _.cloneDeep(currentContacts);
  brokerContactForm.getRawValue().forEach((formItemValues) => {
    const id = formItemValues.contactId as string;
    const newRoles = _.keys(_.pickBy(formItemValues.emailTypes, (value) => value));
    delete removedContacts[id];

    if (!id) {
      if (formItemValues.email) {
        changes.push({
          action: 'create',
          roles: newRoles,
          contactInfo: {
            firstName: formItemValues.firstName || '',
            lastName: formItemValues.lastName || '',
            email: formItemValues.email,
          },
        });
      }
    } else if (!currentContacts[id]) {
      if (newRoles.length) {
        changes.push({ action: 'follow', roles: newRoles, contactId: id });
      }
    } else {
      const currentRoles = currentContacts[id].roles;

      const addedRoles = _.difference(newRoles, currentRoles);
      const removedRoles = _.difference(currentRoles, newRoles);
      if (addedRoles.length) {
        changes.push({ action: 'follow', roles: addedRoles, contactId: id });
      }
      if (removedRoles.length) {
        changes.push({ action: 'unfollow', roles: removedRoles, contactId: id });
      }
    }
  });

  Object.entries(removedContacts).forEach(([removedId, removedContact]) => {
    changes.push({ action: 'unfollow', roles: removedContact.roles, contactId: removedId });
  });

  return changes;
};

export const insertNewBrokerContact = (brokerContactForm: ContactListGroup, contactId: string) => {
  const formBuilder = new FormBuilder();

  const newContact: ContactFormGroup = formBuilder.group(
    {
      isExistingContact: formBuilder.control<boolean | null>(true, {
        validators: [Validators.required],
      }),
      contactId: formBuilder.control<string | null>(contactId),
      email: formBuilder.control<string | null>(null),
      firstName: formBuilder.control<string | null>(null),
      lastName: formBuilder.control<string | null>(null),
      emailTypes: formBuilder.group({
        Renewal: formBuilder.control<boolean | null>(null),
        Cancellation: formBuilder.control<boolean | null>(null),
        Invoice: formBuilder.control<boolean | null>(null),
      }),
    },
    { validators: brokerContactInfoValidator }
  );
  brokerContactForm.push(newContact);
};

// Transform data from an arbitrary format (one of Guidewire's various forms) into a simplified, useable format
export const transformContacts = (
  contacts: any,
  contactPath: string,
  rolesPath: string,
  communicationTypeKey: string
): TransformedContacts => {
  return contacts.reduce((acc: TransformedContacts, rawContact: any) => {
    const contactId = _.get(rawContact, contactPath + '.PublicID');
    const roles = _.get(rawContact, rolesPath, []).map((item: any) => item[communicationTypeKey]);
    const email = _.get(rawContact, contactPath + '.EmailAddress1');
    const isDefaultContact = _.get(rawContact, 'IsDefaultContact');

    if (acc[contactId]) {
      const combinedRoles = acc[contactId].roles.concat(roles);
      acc[contactId].roles = combinedRoles;
    } else {
      acc[contactId] = { roles, email, isDefaultContact };
    }

    return acc;
  }, {});
};

export const selectivelyPatchBrokerContactForm = (
  subscribedContacts: TransformedContacts,
  currentContact: GwCommContact,
  contactFormGroup: ContactListGroup
) => {
  // If the account was somehow created without any contact subscriptions, add the contact of the logged-in broker
  if (subscribedContacts) {
    if (Object.keys(subscribedContacts).length) {
      patchBrokerContactForm(contactFormGroup, subscribedContacts);
    } else if (currentContact) {
      patchBrokerContactForm(contactFormGroup, {
        [currentContact.PublicID]: {
          email: currentContact.EmailAddress1,
          roles: Object.keys(brokerEmailTypes),
        },
      });
    }
  }
};

export const getAllPossibleContacts = (
  currentContact: GwCommContact,
  subscribedContacts: TransformedContacts,
  availableContacts: TransformedContacts
): TransformedContacts => {
  const contacts = {};
  if (currentContact) {
    Object.assign(contacts, {
      [currentContact.PublicID]: { email: currentContact.EmailAddress1 },
    });
  }
  Object.assign(contacts, subscribedContacts || {});
  Object.assign(contacts, availableContacts || {});
  return contacts;
};
