import { Directive, Input } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

@Directive({
  selector: '[appFormatMask]',
})
export class FormatMaskDirective extends BaseMaskDirective {
  // NOTE: This directive expects the format string to have numbers/letters as X's
  // Example: 555-333-111 would be provided as "XXX-XXX-XXX"
  @Input() maskFormat: string = 'XXX-XXX-XXX';
  @Input() allowedCharacters: 'NUMBERS_ONLY' | 'ALPHANUMERIC' = 'NUMBERS_ONLY';

  getMask(value: string | null): string {
    if (!value) {
      return '';
    }

    let formattedString = value;

    if (this.allowedCharacters === 'ALPHANUMERIC') {
      formattedString = this.removeNonAlphaNumeric(formattedString);
    } else if (this.allowedCharacters === 'NUMBERS_ONLY') {
      formattedString = this.removeNonNumbers(formattedString);
    }

    let finalValue = '';
    let numberIndex = 0;
    for (let i = 0; i < this.maskFormat.length && numberIndex < formattedString.length; i++) {
      if (this.maskFormat[i] === 'X') {
        finalValue += formattedString[numberIndex];
        numberIndex++;
      } else {
        finalValue += this.maskFormat[i];
      }
    }

    return finalValue;
  }

  private removeNonAlphaNumeric(value: string) {
    // Captures non-alphanumeric characters
    const regex = /[^a-zA-Z0-9]/g;

    return value.replace(regex, '');
  }

  private removeNonNumbers(value: string) {
    return this.getNumericString(value) || '';
  }
}
