<h1 class="form-header-below-link">Location {{ locationIndex }}</h1>
<div class="helper-card-container">
  <app-form-field-address
    labelText="Location business address"
    prefix="location-{{ locationIndex }}"
    [form]="locationFormGroup"
    [submitted]="submitted"
  ></app-form-field-address>
  <div class="helper-card">
    <div class="pill-tag pill-tag__main">Note</div>
    <p>Location 1 will be used as the primary location on the policy.</p>
  </div>
</div>

<app-form-field-text
  inputId="location-{{ locationIndex }}-employeeCount"
  [form]="locationFormGroup"
  [submitted]="submitted"
  labelText="Number of employees"
  nameOfFormControl="employeeCount"
  appDelimitedNumberMask
></app-form-field-text>

<div class="form-subsection" *ngIf="getControl(locationFormGroup, 'manyEmployees').enabled">
  <app-form-field-text
    inputId="location-{{ locationIndex }}-employeesInShift1"
    [form]="getControl(locationFormGroup, 'manyEmployees')"
    [submitted]="submitted"
    labelText="Employees in shift 1"
    nameOfFormControl="employeesInShift1"
    appDelimitedNumberMask
  ></app-form-field-text>

  <app-form-field-text
    inputId="location-{{ locationIndex }}-employeesInShift2"
    [form]="getControl(locationFormGroup, 'manyEmployees')"
    [submitted]="submitted"
    labelText="Employees in shift 2"
    nameOfFormControl="employeesInShift2"
    appDelimitedNumberMask
  ></app-form-field-text>

  <app-form-field-text
    inputId="location-{{ locationIndex }}-employeesInShift3"
    [form]="getControl(locationFormGroup, 'manyEmployees')"
    [submitted]="submitted"
    labelText="Employees in shift 3"
    nameOfFormControl="employeesInShift3"
    appDelimitedNumberMask
  ></app-form-field-text>

  <app-form-field-select
    inputId="location-{{ locationIndex }}-constructionType"
    [form]="getControl(locationFormGroup, 'manyEmployees')"
    [submitted]="submitted"
    labelText="Construction type"
    [availableOptions]="constructionTypeOptions"
    nameOfFormControl="constructionType"
  ></app-form-field-select>

  <div class="form-split">
    <app-form-field-text
      inputId="location-{{ locationIndex }}-yearBuilt"
      [form]="getControl(locationFormGroup, 'manyEmployees')"
      [submitted]="submitted"
      labelText="Year built"
      nameOfFormControl="yearBuilt"
    >
    </app-form-field-text>

    <div class="helper-card-container">
      <app-form-field-text
        inputId="location-{{ locationIndex }}-storiesCount"
        [form]="getControl(locationFormGroup, 'manyEmployees')"
        [submitted]="submitted"
        labelText="Number of stories"
        nameOfFormControl="storiesCount"
        appDelimitedNumberMask
      >
      </app-form-field-text>
      <div class="validation" role="alert" *ngIf="fieldInvalid('storiesCount')">
        <p class="validation-message">Number of stories must be greater than 0.</p>
      </div>
    </div>
  </div>
</div>

<h3 class="question-subsection-header">Employee classifications</h3>

<div class="multi-form-item" *ngIf="formService.loadingClassCodes">
  <div class="placeholder-form-label"></div>
  <div class="placeholder-form-input"></div>
</div>

<ng-container *ngIf="!formService.loadingClassCodes && formService.classCodesError">
  <div class="validation">
    <div class="validation-message">
      There was an error fetching employee classifications for this state. Please contact the
      Customer Care team if this persists.
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="
    !formService.loadingClassCodes && !formService.classCodesError && formService.classCodesEmpty
  "
>
  <p>
    There are no class codes available for this state or locality. We do not offer WC coverage in
    {{ locationFormGroup.value.state }}.
  </p>
</ng-container>

<ng-container
  *ngIf="
    !formService.loadingClassCodes && !formService.classCodesError && !formService.classCodesEmpty
  "
>
  <div
    id="employeeClassification-{{ i }}"
    class="multi-form-item"
    *ngFor="
      let employeeClassification of formService.employeeClassifications(locationFormGroup).controls;
      let i = index
    "
  >
    <div class="helper-card-container">
      <app-wc-classification
        prefix="employeeClassification-{{ i }}"
        [fuse]="formService.fuse"
        [classCodeOptions]="formService.classCodeOptions"
        [employeeClassification]="employeeClassification"
        [submitted]="submitted"
      >
      </app-wc-classification>
      <div class="helper-card helper-card__wc-employee-classification" *ngIf="i === 0">
        <div class="pill-tag pill-tag__main">Note</div>
        <p>Payroll should exclude officers if officers' exclusion has been added.</p>
      </div>
    </div>

    <div class="nav-button-group">
      <a
        class="button button__small button__secondary js-remove-classification-code-button"
        (click)="formService.removeEmployeeClassification(locationFormGroup, i)"
        (keyup.enter)="formService.removeEmployeeClassification(locationFormGroup, i)"
        *ngIf="formService.moreThanOneEmployeeClassification(locationFormGroup)"
      >
        Remove
      </a>
    </div>

    <div
      class="validation"
      *ngIf="
        submitted &&
        formService.employeeClassifications(locationFormGroup).at(i).controls.code.errors
      "
    >
      <div
        class="validation-message"
        *ngIf="
          formService.employeeClassifications(locationFormGroup).at(i).controls.code.errors
            .notQuoteable
        "
      >
        {{
          formService.employeeClassifications(locationFormGroup).at(i).controls.code.errors
            .notQuoteable
        }}
      </div>

      <div
        class="validation-message"
        *ngIf="
          formService.employeeClassifications(locationFormGroup).at(i).controls.code.errors
            .notEasyRate
        "
      >
        {{
          formService.employeeClassifications(locationFormGroup).at(i).controls.code.errors
            .notEasyRate
        }}
      </div>

      <div
        class="validation-message"
        *ngIf="
          formService.employeeClassifications(locationFormGroup).at(i).controls.code.errors
            .AddressSpecialCharsError
        "
      >
        {{
          formService.employeeClassifications(locationFormGroup).at(i).controls.code.errors
            .AddressSpecialCharsError
        }}
      </div>
    </div>
  </div>
</ng-container>

<div
  class="validation"
  *ngIf="submitted && formService.employeeClassifications(locationFormGroup).errors"
>
  <div class="validation-message">
    <span
      *ngFor="
        let message of formService.employeeClassifications(locationFormGroup).errors
          .invalidClassCodeList
      "
    >
      {{ message }}<br />
    </span>
  </div>
</div>

<button
  class="
    button button__secondary button__full-width button__no-margin
    js-add-classification-code-button
  "
  type="button"
  (click)="formService.addEmployeeClassification(locationFormGroup)"
>
  Add employee classification
</button>

<ng-container *ngIf="(formService.hasExecutiveTitles | async) && locationIndex === 1">
  <hr />
  <h3 class="question-subsection-header">Executive elections</h3>

  <div
    id="executives-{{ i }}"
    class="multi-form-item"
    *ngFor="let executive of formService.executives(locationFormGroup).controls; let i = index"
  >
    <div class="form-split">
      <app-form-field-text
        inputId="executives-{{ i }}-firstName"
        [form]="executive"
        [submitted]="submitted"
        labelText="First name"
        nameOfFormControl="firstName"
      >
      </app-form-field-text>

      <app-form-field-text
        inputId="executives-{{ i }}-lastName"
        [form]="executive"
        [submitted]="submitted"
        labelText="Last name"
        nameOfFormControl="lastName"
      >
      </app-form-field-text>
    </div>

    <div class="form-split">
      <ng-container>
        <app-form-field-select
          inputId="executives-{{ i }}-title"
          [form]="executive"
          [submitted]="submitted"
          labelText="Title"
          [availableOptions]="formService.executiveTitleOptions"
          nameOfFormControl="title"
        >
        </app-form-field-select>
      </ng-container>

      <div
        class="form-field"
        [ngClass]="{ 'form-field__error': fieldInvalid(['executives', i, 'ownership']) }"
      >
        <label for="executives-{{ i }}-ownership">Ownership %</label>
        <input
          id="executives-{{ i }}-ownership"
          name="executives-{{ i }}-ownership"
          [formControl]="executive.get('ownership')"
          min="0"
          max="100"
          type="number"
        />
      </div>
    </div>

    <app-form-field-radio
      inputId="executives-{{ i }}-isIncluded"
      [invalid]="locationFormGroup.errors && locationFormGroup.errors['execExclusion']"
      [form]="executive"
      [submitted]="submitted"
      [options]="executivesIncludedOptions"
      questionText="Executive is..."
      nameOfFormControl="isIncluded"
    >
    </app-form-field-radio>

    <!-- state specific executive questions -->
    <div *ngIf="formService.hasExecutiveQuestions(executive)">
      <div *ngFor="let question of formService.executiveQuestions(executive).controls | keyvalue">
        <app-form-field-radio
          inputId="executives-{{ i }}-question-{{ question.key }}"
          [invalid]="locationFormGroup.errors && locationFormGroup.errors['execQuestions']"
          [form]="executive.get('questions')"
          [submitted]="submitted"
          [options]="executiveQuestionOptions"
          questionText="{{ formService.getExecutiveQuestionText(question.key) }}"
          nameOfFormControl="{{ question.key }}"
        >
        </app-form-field-radio>
      </div>
    </div>

    <div class="nav-button-group">
      <a
        class="button button__small button__secondary js-remove-executive-button"
        (click)="formService.removeExecutive(locationFormGroup, i)"
        (keyup.enter)="formService.removeExecutive(locationFormGroup, i)"
      >
        Remove
      </a>
    </div>
  </div>

  <ng-container
    *ngIf="submitted && locationFormGroup.errors && locationFormGroup.errors['execExclusion']"
  >
    <div class="validation">
      <div class="validation-message">
        {{ locationFormGroup.errors['execExclusion'] }}
      </div>
    </div>
  </ng-container>

  <button
    id="add-executive"
    class="button button__secondary button__full-width button__form-margin js-add-executive-button"
    type="button"
    [ngClass]="{
      button__error:
        submitted &&
        formService.executives(locationFormGroup) &&
        formService.executives(locationFormGroup).errors
    }"
    (click)="formService.addExecutive(locationFormGroup)"
  >
    Add executive
  </button>

  <ng-container
    *ngIf="
      submitted &&
      formService.executives(locationFormGroup) &&
      formService.executives(locationFormGroup).errors
    "
  >
    <div class="validation">
      <div class="validation-message">
        At least one executive is required to be scheduled in this state.
      </div>
    </div>
  </ng-container>
</ng-container>
