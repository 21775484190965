import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserApiService } from '../../core/services/user-api-service';
import { UserService } from '../../core/services/user.service';
import { User } from '../models/user';
import { OktaGroup } from 'app/constants';

const isFinanceAdmin = (groups: string[]) => groups && groups.includes(OktaGroup.FINANCE_ADMIN);

@Component({
  selector: 'app-settings-page.app-page.app-page__settings',
  templateUrl: 'settings.component.html',
})
export class ManageSettingsComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();

  userGroups: string[];

  currentUser: User;

  constructor(private userApiService: UserApiService, private userService: UserService) {}

  ngOnInit() {
    this.sub.add(this.userService.getUser().subscribe((user) => (this.currentUser = user)));

    this.sub.add(
      this.userApiService
        .getCurrentGroups()
        .subscribe((response) => this.setUserGroups(response.result))
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setUserGroups(groups: string[]) {
    this.userGroups = groups;
  }

  canManageFinances() {
    if (!this.currentUser || !this.userGroups) {
      return false;
    }

    return isFinanceAdmin(this.userGroups);
  }
}
