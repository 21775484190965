import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SentryService } from 'app/core/services/sentry.service';
import { LIST_ENHANCEMENT_LIBRARY_URL } from 'app/constants';

export interface EnhancementItem {
  formEdition: string;
  formNumber: string;
  fullName: string;
  name: string;
}

export interface FormTemplateResponse {
  contents: EnhancementItem[];
}

@Injectable()
export class SupportEnhancementService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}
  getFormTemplates() {
    return this.http.get<FormTemplateResponse>(LIST_ENHANCEMENT_LIBRARY_URL).pipe(
      map((payload) => payload),
      catchError((error) => {
        this.sentryService.notify('Unable to get form templates for support enhancement.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }
}
