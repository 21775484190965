import { cloneDeep } from 'lodash';
import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { RewardsService } from 'app/shared/services/rewards.service';
import { NaicsService } from 'app/shared/services/naics.service';
import { Subscription, combineLatest, skipWhile } from 'rxjs';
import { RewardListItems, RewardName } from '../../../../shared/rewards/rewards-types';
import {
  AchievementCategory,
  AchievementItems,
  AchievementNames,
  ConditionalAchievement,
  UniversalAchievementItems,
} from '../../../../shared/rewards/rewards-achievement-helpers';
import { UserService } from '../../../../core/services/user.service';
import { ADP_AGENT_PRODUCER_CODES } from '../../../../shared/rewards/rewards-constants';

@Component({
  selector: 'app-rewards-checklist',
  templateUrl: './rewards-checklist.component.html',
})
export class RewardsChecklistComponent implements OnInit {
  categories: AchievementCategory[] = [];
  achievements: AchievementNames = cloneDeep(UniversalAchievementItems);
  public rewardsAchievementsList: RewardListItems[] = [];

  protected sub = new Subscription();

  constructor(
    private rewardsService: RewardsService,
    private userService: UserService,
    private naicsService: NaicsService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.sub.add(
      this.rewardsService.getRewardsListForChecklist().subscribe((list) => {
        // NOTE: For some reason, the angular template wasn't updating automatically after the rewards list was changed. After we upgrade to angular 9, we can check to see if we can avoid this manual change detection
        this.rewardsAchievementsList = list;
        this.ref.detectChanges();
      })
    );
    this.sub.add(
      combineLatest([this.naicsService.getProductAvailability(), this.userService.getUser()])
        .pipe(skipWhile(([productAvailabilities, user]) => !productAvailabilities || !user))
        .subscribe(([productAvailabilities, user]) => {
          const coalitionAvail = productAvailabilities.find((productAvailability) => {
            return (
              productAvailability.pasSource === 'coalition' &&
              productAvailability.classCodeSelection !== 'NONE'
            );
          });
          if (coalitionAvail) {
            this.achievements[ConditionalAchievement.ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT] =
              AchievementItems[ConditionalAchievement.ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT];
          }
          if (coalitionAvail && !ADP_AGENT_PRODUCER_CODES.includes(user.producer)) {
            this.achievements[ConditionalAchievement.BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT] =
              AchievementItems[ConditionalAchievement.BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT];
          }
          const employersAvail = productAvailabilities.find((productAvailability) => {
            return (
              productAvailability.pasSource === 'employers' &&
              productAvailability.classCodeSelection !== 'NONE'
            );
          });
          if (employersAvail) {
            this.achievements[ConditionalAchievement.ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT] =
              AchievementItems[ConditionalAchievement.ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT];
          }
          if (employersAvail && !ADP_AGENT_PRODUCER_CODES.includes(user.producer)) {
            this.achievements[ConditionalAchievement.BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT] =
              AchievementItems[ConditionalAchievement.BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT];
          }
          const hiscoxPlAvail = productAvailabilities.find((productAvailability) => {
            return (
              productAvailability.pasSource === 'hiscox' &&
              productAvailability.product === 'pl' &&
              productAvailability.classCodeSelection !== 'NONE'
            );
          });
          if (hiscoxPlAvail) {
            this.achievements[ConditionalAchievement.ONE_HISCOX_PL_QUOTES_ACHIEVEMENT] =
              AchievementItems[ConditionalAchievement.ONE_HISCOX_PL_QUOTES_ACHIEVEMENT];
          }
          if (hiscoxPlAvail && !ADP_AGENT_PRODUCER_CODES.includes(user.producer)) {
            this.achievements[ConditionalAchievement.BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT] =
              AchievementItems[ConditionalAchievement.BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT];
          }
          const hiscoxGlAvail = productAvailabilities.find((productAvailability) => {
            return (
              productAvailability.pasSource === 'hiscox' &&
              productAvailability.product === 'gl' &&
              productAvailability.classCodeSelection !== 'NONE'
            );
          });
          if (hiscoxGlAvail) {
            this.achievements[ConditionalAchievement.ONE_HISCOX_GL_QUOTES_ACHIEVEMENT] =
              AchievementItems[ConditionalAchievement.ONE_HISCOX_GL_QUOTES_ACHIEVEMENT];
          }

          Object.values(this.achievements).forEach((achievement) => {
            if (!this.categories.includes(achievement.category)) {
              this.categories.push(achievement.category);
            }
          });
        })
    );
  }

  isRewardedChecklist(rewardName: RewardName) {
    if (this.rewardsAchievementsList.length > 0) {
      return this.rewardsAchievementsList.some((reward) => reward.name === rewardName);
    }
    return false;
  }
}
