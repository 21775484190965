import { of as observableOf, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { EMAIL_PREFERENCES_API_URI } from 'app/constants';

import { SentryService } from 'app/core/services/sentry.service';

@Injectable()
export class UnsubscribeService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  unsubscribe(email: string, token: string, optOutType: string = ''): Observable<any> {
    return this.http
      .post<any>(EMAIL_PREFERENCES_API_URI, { email, token, optOutType, isOptedOut: true })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.sentryService.notify('Unable to unsubscribe user from emails.', {
            severity: 'error',
            metaData: {
              email,
              token,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(error);
        })
      );
  }
}
