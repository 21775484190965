import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { endsWith } from 'lodash';

import { UserService } from '../../core/services/user.service';
import { User } from '../models/user';
import { InsuredAccount } from '../../features/insured-account/models/insured-account.model';
import {
  SAMPLE_ACCOUNTS,
  SampleAccountType,
  DEFAULT_SAMPLE,
} from 'app/shared/sample-accounts/sample-accounts';
import { BopQuoteFormValue } from 'app/features/attune-bop/models/bop-policy';
import { SentryService } from '../../core/services/sentry.service';
import { CurrentUserService } from '../../core/services/current-user.service';
import { AmplitudeService } from '../../core/services/amplitude.service';
import { InsuredAccountService } from '../../features/insured-account/services/insured-account.service';
import {
  GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER,
  GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS,
} from 'app/features/attune-bop/models/constants';

export enum OnboardingAmplitudeEvents {
  REDIRECT = 'onboarding_redirect',
  ROLE = 'role_selection',
  CLASSIFICATION = 'sample_classification_selection',
  EXPLICIT_SKIP = 'explicit_sample_account_skip',
  SAMPLE_SKIP = 'sample_account_skip',
  SAMPLE_OPT_IN = 'sample_quote_opt_in',
  SAMPLE_OPT_OUT = 'sample_quote_opt_out',
  CANCEL_REAL_QUOTE = 'cancel_real_quote',
}

export enum SampleAccountTriggers {
  EXPLICIT_SKIP = 'explicit_skip',
  SERVICING_ROLE = 'servicing_role',
  CANCEL_QUOTE = 'cancel_quote',
}

const ACCOUNT_TRIGGER_TO_AMPLITUDE_EVENT = {
  [SampleAccountTriggers.EXPLICIT_SKIP]: OnboardingAmplitudeEvents.EXPLICIT_SKIP,
  [SampleAccountTriggers.SERVICING_ROLE]: OnboardingAmplitudeEvents.SAMPLE_SKIP,
  [SampleAccountTriggers.CANCEL_QUOTE]: OnboardingAmplitudeEvents.CANCEL_REAL_QUOTE,
};

export enum OnboardingQueryParams {
  CLASS = 'class',
  FROM_QUOTE = 'from-sample-quote',
  SKIPPED_SAMPLE_QUOTE = 'skipped-sample-quote',
  FROM_MODAL = 'from-modal',
}

export enum OnboardingUserPath {
  SKIP = 'skip-quote',
  QUOTE = 'real-quote',
  SAMPLE = 'sample-quote',
  NONE = '',
}

export const SAMPLE_NOT_AVAILABLE_MESSAGE = '🚧 Not available in the sample quote 🚧';
export const ACCOUNT_LIST_LENGTH_CUTOFF = 30;

@Injectable()
export class OnboardingService {
  private userState: OnboardingUserPath = OnboardingUserPath.NONE;
  private accountListLength = 0;
  userDetails: User;
  sampleAccountId = '';

  constructor(
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private currentUserService: CurrentUserService,
    private insuredAccountService: InsuredAccountService,
    private userService: UserService
  ) {}

  static getSampleAccountInfo(sampleType: SampleAccountType): Partial<InsuredAccount> {
    const sampleAccount = SAMPLE_ACCOUNTS[sampleType];
    if (!sampleAccount) {
      return SAMPLE_ACCOUNTS[DEFAULT_SAMPLE].accountFormPayload;
    }

    return sampleAccount.accountFormPayload;
  }

  static getSampleQuoteInfo(sampleType: SampleAccountType): Partial<BopQuoteFormValue> {
    const sampleAccount = SAMPLE_ACCOUNTS[sampleType];
    if (!sampleAccount) {
      return SAMPLE_ACCOUNTS[DEFAULT_SAMPLE].bopQuoteFormPayload;
    }

    return sampleAccount.bopQuoteFormPayload;
  }

  public createSampleAccountForUser({
    sampleAccountTrigger,
  }: {
    sampleAccountTrigger: SampleAccountTriggers;
  }) {
    const sampleAccountInfo = OnboardingService.getSampleAccountInfo(DEFAULT_SAMPLE);
    const sampleAccount = new InsuredAccount({
      ...sampleAccountInfo,
      phoneNumber: GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER,
      emailAddress: GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS,
      sampleAccount: true,
    });

    const skipEventName = ACCOUNT_TRIGGER_TO_AMPLITUDE_EVENT[sampleAccountTrigger];

    this.amplitudeService.track({ eventName: skipEventName, detail: '', useLegacyEventName: true });

    return (
      this.insuredAccountService
        .create(sampleAccount)
        // Save the created accountId so it's available for routing later.
        .pipe(tap((accountId: string) => (this.sampleAccountId = accountId)))
    );
  }

  get onboardingUserPath() {
    return this.userState;
  }

  set onboardingUserPath(newState: OnboardingUserPath) {
    this.userState = newState;
  }

  get userAccountListLength() {
    return this.accountListLength;
  }

  // Enable Onboarding feature for all non-Attune users.
  public featureEnabled() {
    const userEmail = this.getUserEmail();

    return of(!endsWith(userEmail, 'attuneinsurance.com'));
  }

  private getUserEmail() {
    if (this.userDetails) {
      return this.userDetails.userName.toLowerCase();
    } else {
      const userDetails = this.currentUserService.getCurrentUser();
      return (userDetails && userDetails.username.toLowerCase()) || '';
    }
  }

  userHasCreatedAccount(accountList: InsuredAccount[], gwUserName: string) {
    // If no Guidewire username is present, do not proceed with account creation check
    // And assume that a user has created an account
    if (!gwUserName) {
      this.sentryService.notify('No Guidewire username available for user in onboarding flow');

      return true;
    }

    if (accountList.length > ACCOUNT_LIST_LENGTH_CUTOFF) {
      return true;
    }

    this.trackUserAccountListLength(accountList);

    const lowercaseUserName = gwUserName.toLowerCase();

    // For debugging info, record the number of accounts without gwUserNames attached
    const accountsWithNoUsername: string[] = [];

    const userHasCreatedAccounts = accountList.some((account) => {
      if (!account.createdByUser) {
        accountsWithNoUsername.push(account.id);
        return false;
      }

      return account.createdByUser.toLowerCase() === lowercaseUserName;
    });

    if (accountsWithNoUsername.length > 0) {
      this.sentryService.notify('Insured accounts found with missing Guidewire usernames', {
        metaData: {
          accountsWithNoUsername,
          totalAccounts: accountList.length,
        },
      });
    }

    return userHasCreatedAccounts;
  }

  trackUserAccountListLength(accountList: InsuredAccount[]) {
    // Store the account list length for future calculations,
    // like displaying the `Skip` button in the onboarding form flow
    const nonSampleAccounts = accountList.filter((account) => !account.sampleAccount);
    this.accountListLength = nonSampleAccounts.length;
  }

  // TODO: Consider refactoring to produce a non-observable result using local storage parsing for user details
  public hasUserCreatedInsuredAccount(accountList: InsuredAccount[]) {
    if (this.userDetails) {
      return of(this.userHasCreatedAccount(accountList, this.userDetails.husaUserName));
    }

    return this.userService.getUser().pipe(
      tap((userDetails) => (this.userDetails = userDetails)),
      map((userDetails) => this.userHasCreatedAccount(accountList, userDetails.husaUserName))
    );
  }
}
