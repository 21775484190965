import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-wc-loss-form',
  templateUrl: 'wc-loss-form.component.html',
})
export class WcLossFormComponent {
  @Input() lossInfoFormGroup: UntypedFormGroup;
  @Input() submitted: boolean;

  constructor() {}

  get lossHistory() {
    return (<UntypedFormGroup>this.lossInfoFormGroup.get('lossHistory')).controls;
  }
}
