<div class="rollover-main-section">
  <nav class="rollover-top-links">
    <a [routerLink]="['..']">Activity</a> <span class="gray-text"> / Rollover</span>
  </nav>

  <h1 class="rollover-top-header">BOP+ Transition</h1>

  <section class="rollover-summary-section" *ngIf="hasChartError">
    <div class="rollover-chart-failure">
      <span class="rollover-chart-loading-failure"></span>
      <h2>Couldn't load your data</h2>
      <p>
        <a
          (click)="reloadChartInfo()"
          (keyup.enter)="reloadChartInfo()"
          class="js-rollover-chart-refresh-button"
          >Refresh</a
        >
        to try again
      </p>
    </div>
  </section>
  <section class="rollover-summary-section" *ngIf="!hasChartError && !loadingChartInformation()">
    <div>
      <div class="summary-number-value">
        {{ policiesRolledOver }}<span class="gray-text">/{{ policiesTotal }}</span>
        <span class="summary-number-superscript">({{ policyRollOverPercent }}%)</span>
      </div>
      <div class="summary-number-label">Policies transitioned</div>
    </div>

    <div>
      <div class="summary-number-value">
        {{ statesLive }}<span class="gray-text">/{{ statesTotal }}</span>
      </div>
      <div class="summary-number-label">States live</div>
    </div>
  </section>

  <hr class="hr__small" *ngIf="hasChartError" />

  <section
    class="rollover-visualization-section"
    *ngIf="!hasChartError && !loadingChartInformation()"
  >
    <figure class="bar-chart-section">
      <figcaption class="chart-key-section">
        <div *ngFor="let keyItem of BAR_CHART_KEY">
          <span class="chart-key-color-pane" [style.background-color]="keyItem.color"></span>
          <span class="chart-key-label">{{ keyItem.label }}</span>
        </div>
      </figcaption>
      <app-activity-rollover-bar-chart
        [chartData]="barChartData"
        [barColors]="[TOTAL_BAR_COLOR, ROLLOVER_BAR_COLOR]"
        [alternativeText]="barChartAlternativeText"
      >
      </app-activity-rollover-bar-chart>
    </figure>
    <figure class="states-chart-section">
      <figcaption class="chart-key-section">
        <div *ngFor="let keyItem of STATE_CHART_KEY">
          <span class="chart-key-color-pane" [style.background-color]="keyItem.color"></span>
          <span class="chart-key-label">{{ keyItem.label }}</span>
        </div>
      </figcaption>
      <app-activity-rollover-states-chart
        [stateColors]="stateStatusData"
        [stateTotalCounts]="stateTotalCounts"
        [stateRolloverCounts]="stateRolloverCounts"
        [alternativeText]="stateChartAlternativeText"
      >
      </app-activity-rollover-states-chart>
    </figure>
  </section>
  <section class="rollover-table-section" *ngIf="scheduledRolloverPolicies.length">
    <h3 class="h3">Scheduled Rollovers</h3>
    <table *ngIf="scheduledRolloverPolicies && scheduledRolloverPolicies.length">
      <thead>
        <th>Account Name</th>
        <th>Status</th>
        <th>Effective</th>
        <th>Premium</th>
        <th></th>
      </thead>
      <tbody>
        <tr class="policies-overview-row" *ngFor="let policy of scheduledPoliciesPage()">
          <td class="scheduled-rollover-table-cell">{{ policy.AccountName }}</td>
          <td class="scheduled-rollover-table-cell">
            <div class="pill-tag pill-tag__main">Scheduled</div>
          </td>
          <td class="scheduled-rollover-table-cell">{{ formatDate(policy.PeriodStart) }}</td>
          <td class="scheduled-rollover-table-cell">
            {{ policy.TotalCost | currency: 'USD':'symbol':'1.0-0' }}
          </td>
          <td class="scheduled-rollover-table-cell">
            <a [routerLink]="getQuoteLink(policy)">Review</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="policies-overview-page-selector js-policies-rollover-page-selector"
      *ngIf="scheduledRolloverPolicies.length > POLICY_TABLE_PAGE_SIZE"
    >
      <a
        *ngIf="scheduledPolicyTableIndex > 0"
        (click)="showPreviousScheduledPolicies()"
        (keyup.enter)="showPreviousScheduledPolicies()"
        class="js-policies-rollover-previous"
        ><span class="icon-arrow-left button-icon"></span
      ></a>
      <span class="gray-text" *ngIf="scheduledPolicyTableIndex <= 0"
        ><span class="icon-arrow-left button-icon"></span
      ></span>

      <span>
        {{ scheduledPolicyTableIndex + 1 }}-{{
          getPageEnd(scheduledPolicyTableIndex, scheduledRolloverPolicies.length)
        }}
      </span>
      <span class="gray-text"> of </span>
      <span> {{ scheduledRolloverPolicies.length }} </span>

      <a
        *ngIf="
          scheduledPolicyTableIndex + POLICY_TABLE_PAGE_SIZE < scheduledRolloverPolicies.length
        "
        (click)="showNextScheduledPolicies()"
        (keyup.enter)="showNextScheduledPolicies()"
        class="js-policies-rollover-next"
        ><span class="icon-arrow-right button-icon"></span
      ></a>
      <span
        class="gray-text"
        *ngIf="
          scheduledPolicyTableIndex + POLICY_TABLE_PAGE_SIZE >= scheduledRolloverPolicies.length
        "
        ><span class="icon-arrow-right button-icon"></span
      ></span>
    </div>
  </section>
  <section class="rollover-table-section">
    <h3 class="h3">Upcoming Rollovers</h3>
    <div class="rollover-quote-filters">
      <a
        *ngFor="let filter of getFilterOptions()"
        class="upcoming-policies-table-filter"
        (click)="setFilter(filter)"
        (keyup.enter)="setFilter(filter)"
        [ngClass]="{ 'gray-text': filter !== selectedFilter }"
      >
        {{ FILTER_OPTIONS[filter] }}
      </a>
    </div>
    <app-dialog-box
      *ngIf="!loadingRolloverList && !totalPolicyCount()"
      class="dialog-text__full-width dialog-text__no-margin"
      type="primary"
    >
      You have no {{ FILTER_SINGULAR_LABEL[selectedFilter] }} quotes at this time.
    </app-dialog-box>
    <app-dialog-box *ngIf="!loadingRolloverList && totalPolicyCount() && showAutobindExplanation()">
      <strong>What is auto bind?</strong>
      <p>
        BOP+ policies with auto bind enabled will bind automatically 32 days before their effective
        date as if they were renewals. These policies share the same coverage as the old BOP and are
        similarly priced.
      </p>
      <a class="dialog-text-link" [routerLink]="['/support']">Questions? Get in touch</a
      ><a
        class="dialog-text-link"
        (click)="dismissAutobindExplanation()"
        (keyup.enter)="dismissAutobindExplanation()"
        >Dismiss</a
      >
    </app-dialog-box>
    <table *ngIf="loadingRolloverList || totalPolicyCount()">
      <thead>
        <th>Auto Bind</th>
        <th>Account Name</th>
        <th>Status</th>
        <th>NRN Mail Date</th>
        <th>Effective</th>
        <th>Premium</th>
        <th></th>
      </thead>
      <tbody>
        <ng-container *ngIf="loadingRolloverList">
          <tr *ngFor="let i of buildArray(POLICY_TABLE_PAGE_SIZE)" class="upcoming-rollover-row">
            <td class="upcoming-rollover-table-cell"><div class="table-loading-cell"></div></td>
            <td class="upcoming-rollover-table-cell"><div class="table-loading-cell"></div></td>
            <td class="upcoming-rollover-table-cell"><div class="table-loading-cell"></div></td>
            <td class="upcoming-rollover-table-cell"><div class="table-loading-cell"></div></td>
            <td class="upcoming-rollover-table-cell"><div class="table-loading-cell"></div></td>
            <td class="upcoming-rollover-table-cell"><div class="table-loading-cell"></div></td>
            <td class="upcoming-rollover-table-cell td__right">
              <div class="table-loading-cell"></div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!loadingRolloverList">
          <tr class="policies-overview-row" *ngFor="let policy of filteredPoliciesPage()">
            <td class="upcoming-rollover-table-cell">
              <app-form-field-toggle-switch
                inputId="policy-auto-bind-{{ policy.AccountNumber }}"
                [tooltipText]="getTooltipText(policy)"
                [toggleChecked]="policy.AutoBind"
                (toggled)="updateAutobind(policy, $event)"
              >
              </app-form-field-toggle-switch>
            </td>
            <td class="upcoming-rollover-table-cell">{{ policy.AccountName }}</td>
            <td class="upcoming-rollover-table-cell">
              <div
                class="pill-tag pill-tag__good"
                *ngIf="!policy.VerifyEmployeeCount && !policy.VerifyFeetToHydrant"
              >
                Quoted
              </div>
              <div
                class="pill-tag pill-tag__warning"
                *ngIf="policy.VerifyEmployeeCount || policy.VerifyFeetToHydrant"
              >
                Issues
              </div>
            </td>
            <td class="upcoming-rollover-table-cell">
              {{ policy.NRNMailDate ? formatDate(policy.NRNMailDate) : '' }}
            </td>
            <td class="upcoming-rollover-table-cell">{{ formatDate(policy.PeriodStart) }}</td>
            <td class="upcoming-rollover-table-cell">
              {{ policy.TotalCost | currency: 'USD':'symbol':'1.0-0' }}
            </td>
            <td class="upcoming-rollover-table-cell">
              <a [routerLink]="getQuoteLink(policy)">Details</a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div
      class="policies-overview-page-selector js-policies-rollover-page-selector"
      *ngIf="totalPolicyCount() > POLICY_TABLE_PAGE_SIZE"
    >
      <a
        *ngIf="policyTableIndex > 0"
        (click)="showPreviousRollovers()"
        (keyup.enter)="showPreviousRollovers()"
        class="js-policies-rollover-previous"
        ><span class="icon-arrow-left button-icon"></span
      ></a>
      <span class="gray-text" *ngIf="policyTableIndex <= 0"
        ><span class="icon-arrow-left button-icon"></span
      ></span>

      <span>
        {{ policyTableIndex + 1 }}-{{ getPageEnd(policyTableIndex, totalPolicyCount()) }}
      </span>
      <span class="gray-text"> of </span>
      <span> {{ this.totalPolicyCount() }} </span>

      <a
        *ngIf="policyTableIndex + POLICY_TABLE_PAGE_SIZE < totalPolicyCount()"
        (click)="showNextRollovers()"
        (keyup.enter)="showNextRollovers()"
        class="js-policies-rollover-next"
        ><span class="icon-arrow-right button-icon"></span
      ></a>
      <span
        class="gray-text"
        *ngIf="policyTableIndex + POLICY_TABLE_PAGE_SIZE >= totalPolicyCount()"
        ><span class="icon-arrow-right button-icon"></span
      ></span>
    </div>
  </section>
</div>
<div class="rollover-info-sidebar">
  <div class="rollover-info-sidebar-content">
    <h5>Frequently asked questions</h5>
    <app-faqs-list [faqs]="rolloverFAQs"> </app-faqs-list>
  </div>
</div>
<ng-container *ngIf="showAutobindModal">
  <app-modal
    [open]="showAutobindModal"
    (handleModalClose)="closeAutobindModal()"
    modalClass="app-modal-content__left-align app-modal-content__disable-autobind"
  >
    <h2>Are you sure you want to disable auto bind?</h2>
    <p>
      The policy for {{ modalPolicy.AccountName }} will not bind automatically as a renewal would.
      You will have to revisit this policy and bind manually before the effective date.
    </p>

    <app-dialog-box type="error" [fullWidth]="true" *ngIf="hasAutobindDisableError">
      <p>There was an error disabling auto-bind.</p>
      <a class="dialog-error-link" (click)="disableAutobind()" (keyup.enter)="disableAutobind()"
        >Try again</a
      >
    </app-dialog-box>

    <div class="autobind-modal-buttons-container">
      <button
        class="button button__secondary"
        (click)="closeAutobindModal()"
        [ngClass]="{ button__disabled: processingAutobindDisable }"
        [disabled]="processingAutobindDisable"
      >
        No, cancel
      </button>
      <button
        class="button button__primary js-modal-disable-autobind"
        (click)="disableAutobind()"
        [ngClass]="{ button__disabled: processingAutobindDisable }"
        [disabled]="processingAutobindDisable"
      >
        <ng-container *ngIf="processingAutobindDisable">Disabling...</ng-container>
        <ng-container *ngIf="!processingAutobindDisable">Yes, disable it</ng-container>
      </button>
    </div>
  </app-modal>
</ng-container>
