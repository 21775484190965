import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
})
export class LoadingSpinnerComponent {
  isIE: boolean;

  constructor() {
    // Adapted from: https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie/21712356#21712356
    const ua = window.navigator.userAgent;
    const msie = ua.includes('MSIE ');
    const trident = ua.includes('Trident/');
    this.isIE = msie || trident;
  }
}
