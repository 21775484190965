import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Toast, ToastTemplate } from '../toasts/toast';

export enum BillingToastLevel {
  'success',
}

export interface BillingToast extends Toast {
  type: ToastTemplate;
  level: BillingToastLevel;
  timeout: number;
  message: string;
  button: string | null;
  hasLoader: boolean;
  callback: Function | null;
  forceTimeout: boolean;
}

Injectable({
  providedIn: 'root',
});
@Injectable()
export class BillingToastService {
  constructor() {}

  private billingToasts = new Subject<BillingToast>();

  public billingToast(
    message: string,
    button: string | null = null,
    hasLoader: boolean,
    callback: Function | null = null,
    timeout: number,
    forceTimeout?: boolean
  ) {
    return this.toast(
      BillingToastLevel.success,
      message,
      button,
      hasLoader,
      callback,
      timeout,
      forceTimeout
    );
  }

  public toast(
    level: BillingToastLevel,
    message: string,
    button: string | null,
    hasLoader: boolean,
    callback: Function | null,
    timeout: number,
    forceTimeout: boolean = false
  ) {
    const toast = {
      id: uuidv4(),
      type: ToastTemplate.BILLING,
      level,
      message,
      button,
      hasLoader,
      callback,
      timeout,
      removing: false,
      forceTimeout,
    };
    this.billingToasts.next(toast);
    return toast;
  }

  public getBillingToasts() {
    return this.billingToasts.asObservable();
  }
}
