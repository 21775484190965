<div *ngIf="!config">
  <table>
    <tbody>
      <tr>
        <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
      </tr>
    </tbody>
  </table>
</div>

<ng-container *ngIf="config">
  <div *ngIf="config.text" class="bind-goals-text">
    <p class="p p__large-text" [innerHTML]="config.text"></p>
  </div>
  <div class="bind-goals-container">
    <div class="medals-container">
      <div class="medal-container" *ngFor="let milestone of config.milestones; let i = index">
        <h5 class="medal-threshold h5">
          {{ milestone.value }}
        </h5>
        <div
          class="medal-threshold-indicator"
          [ngStyle]="{ 'background-color': getMilestoneIndicatorColor(i) }"
        ></div>
        <div
          class="tool-tip tool-tip__large bind-goals-tooltip"
          [attr.data-tooltip]="milestone.tooltip"
        >
          <div *ngIf="milestone.icon" class="medal-icon">
            <img src="{{ getIcon(milestone.icon) }}" alt="{{ milestone.icon.replace('_', ' ') }}" />
          </div>
          <div
            [innerHTML]="milestone.label"
            class="medal-label small-text"
            [ngClass]="{
              'gray-text': !isMilestoneFulfilled(i)
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="bind-goals-progress-bar-container">
      <div class="bind-goals-progress-bar" [ngStyle]="{ background: progressBarStyle }"></div>
      <div class="white-circle" [ngStyle]="{ left: this.config.percentage + '%' }">
        <div *ngIf="config.indicatorTooltip" class="tooltip-indicator h5">
          {{ config.indicatorTooltip }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
