import { InjectionToken } from '@angular/core';
import { Observable, of as observableOf, ReplaySubject, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';

import { scrollToTop } from 'app/shared/helpers/scroll-helpers';
import { getValidationMessageFromControl } from '../../helpers/form-helpers';

/**
 * Interfaces for a step in a SteppedForm.
 *
 * RouteFormStepPathInfo should be unique for each step.
 * This info is inferred from the URL.
 *
 */
export interface RouteFormStepPathInfo {
  slug: string; // URL slug
  args: { [key: string]: number }; // "Matrix" URL args
}

/**
 * RouteFormStep has additional keys for validation and display concerns.
 * This info is hardcoded in a stepped form subclass in generateSteps()
 */
export interface RouteFormStep extends RouteFormStepPathInfo {
  displayName: string; // Sidebar title
  formPath: string | null; // what path of the form needs to be valid to advance
  substep?: boolean; // optional dimished sidebar look (if true)
  parent: string; // the route's parent; this is the route itself if it is not a substep.
  nextButtonText?: string;
}

// Warning: currStep is 1 index: If you are on the first step, it will be 1!
export interface StepProgress {
  currStep: number;
  numSteps: number;
}

/**
 * Base stepped form service injection token.
 * Useful for shared components that might have a different
 * formservice implementations provided via dependency injection at runtime.
 */
export const FORM_SERVICE_TOKEN = new InjectionToken<FormDslSteppedFormBaseService>('FormService');

/**
 * Used by form mixins to interact with the .form member of Form Services
 */
export class BaseFormService {
  public form: UntypedFormGroup;
  public submitted = false;

  public get<T extends UntypedFormGroup | UntypedFormControl | UntypedFormArray | null>(
    path: string | Array<string | number>
  ): T {
    return this.form.get(path) as T;
  }
}

/**
 * Base Class for the service that backs a stepped form.
 */
export abstract class FormDslSteppedFormBaseService extends BaseFormService {
  protected steps: RouteFormStep[];
  public currentStep: RouteFormStep;

  public currentStep$: ReplaySubject<RouteFormStep> = new ReplaySubject<RouteFormStep>(1);
  public decrementedStep$: Subject<RouteFormStep> = new Subject<RouteFormStep>();
  public incrementedStep$: Subject<RouteFormStep> = new Subject<RouteFormStep>();
  public submittedForm$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    super();
    this.resetToFirstStep();
    this.didChangeStep();
  }

  public getSteps() {
    // TODO(olex): Does TS have a clean "public .steps" that disallows overwriting?
    return this.steps;
  }

  public getStepProgress(
    step: RouteFormStep,
    stepProgressMap: Map<string, StepProgress>
  ): StepProgress | undefined {
    return stepProgressMap && stepProgressMap.get(step.parent);
  }

  public isFirstStep(step = this.currentStep) {
    return this.isSameStep(step, this.steps[0]);
  }

  public isCurrentStep(step: RouteFormStep) {
    return this.isSameStep(this.currentStep, step);
  }

  public isCurrentStepValid() {
    return this.isStepValid(this.currentStep);
  }

  public isCurrentStepDirty() {
    return this.isStepDirty(this.currentStep);
  }

  public getValidationMessage(): string | null {
    if (!this.currentStep.formPath) {
      return null;
    }

    const stepControl = this.get(this.currentStep.formPath);
    if (!stepControl) {
      console.warn(
        `Caution: Step ${this.currentStep.slug} has a formPath, but that control/group does not exist: ${this.currentStep.formPath}`
      );
      return null;
    }

    return getValidationMessageFromControl(stepControl);
  }

  public isSubmittable(): boolean {
    return this.isCurrentStepValid() && this.isFinalStep();
  }

  public isSubstepOf(currStep: RouteFormStep, parentStepSlug: string): boolean {
    const parentStep: RouteFormStep | undefined = this.findStep({
      args: {},
      slug: parentStepSlug,
    });

    if (!parentStep) {
      return false;
    }

    return currStep.parent === parentStep.parent;
  }

  public isAfterStep(stepSlug: string) {
    const step = _.find(this.steps, {
      slug: stepSlug,
    });

    if (!step) {
      console.warn(`Caution: Calling isAfterStep on slug that is not part of steps: ${stepSlug}`);
      return false;
    }

    if (this.stepDifference(step, this.currentStep) > 0) {
      return true;
    }

    return false;
  }

  public isNavigable(step: RouteFormStep) {
    // TODO (stepped quote): remove this?
    return this.isTriviallyNavigable(step) || this.isNextStep(step);
  }

  public stepDifference(stepA: RouteFormStep, stepB: RouteFormStep) {
    const indexA = this.findStepIndex(stepA);
    const indexB = this.findStepIndex(stepB);
    if (indexA < 0 || indexB < 0) {
      throw new Error('Unknown step');
    }
    return indexB - indexA;
  }

  public isTriviallyNavigable(step: RouteFormStep) {
    const targetIndex = this.findStepIndex(step);
    const currentIndex = this.findStepIndex(this.currentStep);

    if (!this.form && !this.isFirstStep(step)) {
      // You can only go to the first step if form hasn't been init'd
      return false;
    }

    if (targetIndex <= currentIndex) {
      // You can always navigate back any number of steps
      return true;
    } else if (
      _.every(this.steps.slice(currentIndex, targetIndex), (intermediateStep) =>
        this.isStepValid(intermediateStep)
      )
    ) {
      // You can attempt to navigate to a far-forward step
      // iff all steps in between are valid.
      return true;
    }

    return false;
  }

  public nearestNavigableStep(targetStep: RouteFormStep): RouteFormStep {
    let step = targetStep;
    while (!this.isTriviallyNavigable(step)) {
      step = this.steps[this.findStepIndex(step) - 1];
    }
    return step;
  }

  // Hook for stepping forward from step
  // Override this to implement Prefill or other synchronous calls
  // with an Observable that then maps to (true = advance / false = do not advance)
  willStepForward(): Observable<boolean> {
    return observableOf(true);
  }

  didChangeStep(): void {
    // Hook to update locations and other subclass concerns
    this.currentStep$.next(this.currentStep);
  }

  stepBackward(): boolean {
    if (!this.isFirstStep(this.currentStep)) {
      this.decrementStep();
      return true;
    }

    return false;
  }

  // Method that submits a current step, and attempts to step forward.
  stepForward() {
    this.submitted = true;

    if (!this.isCurrentStepValid()) {
      return false;
    }

    if (this.isFinalStep()) {
      this.submitForm();
    } else {
      this.incrementStep();
    }
    return true;
  }

  public stepWithoutValidation(step: RouteFormStep) {
    // TODO (stepped quote);
    // Going Forward? Call willStepForward on each intermediate step
    this.submitted = false;
    this.currentStep = step;
    this.didChangeStep();
  }

  public stepExists(step: RouteFormStep): boolean {
    return !!this.findStep(step);
  }

  public isNextStep(step: RouteFormStepPathInfo): boolean {
    return this.isSameStep(step, this.getNextStep());
  }

  public getNextStep(): RouteFormStep {
    if (this.isFinalStep()) {
      return this.currentStep;
    }
    return this.getNeighboringStep(1);
  }

  public getPreviousStep(): RouteFormStep {
    if (this.isFirstStep()) {
      return this.currentStep;
    }
    return this.getNeighboringStep(-1);
  }

  public getCurrentStep(): RouteFormStep {
    return this.currentStep;
  }

  public getNextButtonText(): string {
    return this.getCurrentStep().nextButtonText || 'Next';
  }

  public findStep(step: RouteFormStepPathInfo): RouteFormStep | undefined {
    return _.find(this.steps, {
      args: step.args,
      slug: step.slug,
    });
  }

  // Useful if you need to update the current step based changes that occur
  // during the step (e.g. if user input causes nextButtonText to change).
  public refreshCurrentStep() {
    const currentStepSlug = this.currentStep.slug;
    const updatedCurrentStep = this.steps.find((step) => {
      return step.slug === currentStepSlug;
    });
    if (updatedCurrentStep) {
      this.currentStep = updatedCurrentStep;
      this.didChangeStep();
    }
  }

  public getStepsUptoGivenStep(step: RouteFormStep): RouteFormStep[] {
    const curInd = this.findStepIndex(step);
    return this.steps.slice(0, curInd);
  }

  public generateStepProgressMap(): Map<string, StepProgress> {
    const stepProgressMap: Map<string, StepProgress> = new Map();

    let hasPassedCurrentStep = false;

    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];

      // If the progress map doesn't include the current
      // step's parent, add it
      if (!stepProgressMap.has(step.parent)) {
        const newParentProgress: StepProgress = { currStep: 0, numSteps: 0 };
        stepProgressMap.set(step.parent, newParentProgress);
      }

      const parentProgress = stepProgressMap.get(step.parent);

      // Increment the number of steps in this parent
      if (parentProgress) {
        parentProgress.numSteps++;
      }

      // If the current step hasn't been reached in the step iteration
      // => Check to see if this step is current step
      // => Increment the parent step's progress
      if (!hasPassedCurrentStep && parentProgress) {
        hasPassedCurrentStep = step.slug === this.currentStep.slug;
        parentProgress.currStep++;
      }
    }

    return stepProgressMap;
  }

  // private methods
  private isSameStep(step: RouteFormStepPathInfo, stepTwo: RouteFormStepPathInfo) {
    return step.slug === stepTwo.slug && _.isEqual(step.args, stepTwo.args);
  }

  private findStepIndex(step: RouteFormStep) {
    return _.findIndex(this.steps, {
      args: step.args,
      slug: step.slug,
    });
  }

  private decrementStep() {
    this.syncAllSteps();
    this.submitted = false;

    const prevStep: RouteFormStep = this.getPreviousStep();
    this.currentStep = prevStep;

    this.decrementedStep$.next(prevStep);
    this.didChangeStep();
  }

  private incrementStep() {
    this.syncAllSteps();
    this.submitted = false;

    const nextStep: RouteFormStep = this.getNeighboringStep(1);
    this.currentStep = nextStep;

    this.incrementedStep$.next(nextStep);
    this.didChangeStep();
  }

  // Override this to implement advancing from the final step of the form
  // Only gets called if last step is valid.
  public submitForm(): void {
    this.submittedForm$.next(true);
  }

  protected defaultDidChangeStep() {
    scrollToTop();
  }

  protected isStep(maybeStep: RouteFormStep | null): maybeStep is RouteFormStep {
    return maybeStep !== null;
  }

  private getNeighboringStep(index: number) {
    return this.steps[this.findStepIndex(this.currentStep) + index];
  }

  isFinalStep() {
    const lastStep = _.last(this.steps);
    return (
      lastStep !== null && lastStep !== undefined && this.isSameStep(lastStep, this.currentStep)
    );
  }

  isStepValid(step: RouteFormStep): boolean {
    if (!step.formPath) {
      return true;
    }

    const stepControl = this.get(step.formPath);
    if (!stepControl) {
      console.warn(
        `Caution: Step ${step.slug} has a formPath, but that control/group does not exist: ${step.formPath}`
      );
      return false;
    }

    if (!stepControl.valid) {
      return false;
    }

    return true;
  }

  isStepDirty(step: RouteFormStep) {
    if (!step.formPath) {
      return true;
    }

    const stepControl = this.get(step.formPath);
    if (!stepControl) {
      console.warn(
        `Caution: Step ${step.slug} has a formPath, but that control/group does not exist: ${step.formPath}`
      );
      return false;
    }

    if (!stepControl.dirty) {
      return false;
    }

    return true;
  }

  syncAllSteps() {
    this.steps = this.generateSteps();
  }

  public resetToFirstStep() {
    this.syncAllSteps();
    this.stepWithoutValidation(this.steps[0]);
  }

  // Override this to fill in data for whichever form you're using.
  abstract fillInHappyPath(): void;

  // Override this to implement generating all the steps / routes
  // Location-aware forms re-generate steps when location length changes.
  abstract generateSteps(): RouteFormStep[];

  debugSaveFormData() {}

  debugLoadFormData() {}
}
