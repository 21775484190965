import {
  Component,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { RewardsService } from 'app/shared/services/rewards.service';
import { RewardToast } from './rewards-toast-helpers';
import { Subject } from 'rxjs';

import { RewardName } from '../rewards/rewards-types';

@Component({
  selector: 'app-rewards-toast',
  templateUrl: './rewards-toast.component.html',
})
export class RewardsToastComponent implements OnDestroy, AfterViewInit {
  @Input() toast: RewardToast;
  @Output() destroy = new EventEmitter<RewardToast>();
  @ViewChild('container', { static: true })
  container: ElementRef;
  animationContainer$: Subject<ElementRef> = new Subject();

  constructor(private rewardsService: RewardsService) {}

  ngAfterViewInit() {
    if (this.container && this.toast.display.type === 'ACHIEVEMENT') {
      this.animationContainer$.next(this.container);
      this.animationContainer$.complete();
    }

    if (this.toast) {
      setTimeout(() => {
        this.destroy.emit(this.toast);
      }, 5000);
    }
  }

  ngOnDestroy() {
    if (this.toast) {
      setTimeout(() => {
        this.rewardsService.updateBalance(this.toast.value);
      });
    }
  }

  getRewardIcon(rewardName: string) {
    switch (rewardName) {
      case RewardName.GIFT_CARD_250:
        return 'assets/img/rewards_25card_new.svg';
      case RewardName.GIFT_CARD_50:
        return 'assets/img/rewards_5card_new.svg';
    }
  }

  get animationContainer() {
    return this.animationContainer$.asObservable();
  }
}
