<div *ngIf="epliEnabled()">
  <app-form-field-radio
    inputId="quote-employmentRelatedPracticesLiabilityCoverage"
    [form]="form"
    [submitted]="submitted"
    questionText="Include Employment-Related Practices Liability coverage?"
    nameOfFormControl="optedIn"
    tooltipText="Employee Practices Liability (EPL) Insurance responds to claims of wrongful conduct at
            work and includes defense. Some typical examples include discrimination, wrongful demotion or termination,
            failure to promote, reassignment or discipline, breach of employment contract of the insured’s employees,
            among others."
  >
  </app-form-field-radio>

  <div *ngIf="form.value.optedIn" class="form-subsection emp-rel-practices-fields">
    <ng-container *ngIf="bopVersion === 1">
      <app-form-field-text
        inputId="quote-employmentRelatedPracticesLiabilityCoverage-retroactiveDate"
        [form]="form"
        [submitted]="submitted"
        labelText="Retroactive Date"
        nameOfFormControl="retroactiveDate"
        appDateMask
      >
      </app-form-field-text>
      <app-form-field-select
        inputId="quote-employmentRelatedPracticesLiabilityCoverage-eachEmploymentWrongfulActLimit"
        [form]="form"
        [submitted]="submitted"
        labelText="Each Employment Wrongful Act Limit"
        nameOfFormControl="eachEmploymentWrongfulActLimit"
        [availableOptions]="availableLimitsEachAct"
      >
      </app-form-field-select>
      <div class="form-field">
        <label for="quote-employmentRelatedPracticesLiabilityCoverage-aggregateLimit"
          >Aggregate Limit</label
        >
        <input
          id="quote-employmentRelatedPracticesLiabilityCoverage-aggregateLimit"
          disabled="true"
          [value]="form.get('aggregateLimit').value | currency: 'USD':'symbol':'1.0-0'"
        />
      </div>
      <app-form-field-radio
        inputId="quote-employmentRelatedPracticesLiabilityCoverage-deductible"
        [form]="form"
        [submitted]="submitted"
        questionText="Deductible"
        nameOfFormControl="deductible"
        cssClass="form-field__no-margin"
        [options]="availableDeductibles"
      >
      </app-form-field-radio>
    </ng-container>

    <ng-container *ngIf="bopVersion === 2">
      <app-form-field-radio
        inputId="quote-employmentRelatedPracticesLiabilityCoverage-aggregateLimitV2"
        [form]="form"
        [submitted]="submitted"
        questionText="Aggregate Limit"
        nameOfFormControl="aggregateLimitV2"
        [options]="epliV2Options.aggregateLimitV2"
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-employmentRelatedPracticesLiabilityCoverage-defenseLimitV2"
        [form]="form"
        [submitted]="submitted"
        questionText="Defense Limit, Indemnity Limit"
        nameOfFormControl="defenseLimitV2"
        [options]="epliV2Options.defenseLimitV2"
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-employmentRelatedPracticesLiabilityCoverage-deductibleV2"
        [form]="form"
        [submitted]="submitted"
        [disabled]="onlyOneDeductibleOption()"
        questionText="Deductible"
        nameOfFormControl="deductibleV2"
        cssClass="form-field__no-margin"
        [options]="epliV2Options.deductibleV2"
      >
      </app-form-field-radio>
      <div *ngIf="getDeductibleError()" class="validation">
        <div class="validation-message">
          {{ getDeductibleError() }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
