import * as moment from 'moment';
import { ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

import { US_DATE_MASK, YEAR_MASK, FEIN_MASK, US_DATE_SINGLE_DIGIT_MASK } from 'app/constants';
import { FormDslConfiguration } from './common-typings';
import {
  TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE,
  PHONE_ERROR_MESSAGE,
  YEAR_ERROR_MESSAGE,
  ZERO_ERROR_MESSAGE,
  FEIN_ERROR_MESSAGE,
} from './constants';
import { LmCpspQuestion, LmCpspNestedControls, LmCpspFormStepPath } from './lm-cpsp-typings';
import { LmBopQuestion, LmBopNestedControls, LmBopFormStepPath } from './lm-bop-typings';
import {
  dateIsInPastValidator,
  phoneValidatorWithMessage,
  validateCurrencyIsNotEmpty,
} from 'app/shared/helpers/form-helpers';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';

const YEAR_FAR_PAST = '1700';
const INT_RADIX = 10; // to pass linting in Number.parseInt method calls
const LOSS_DATE_ERROR_MESSAGE =
  'Please enter a date that is within the current term or three most recent completed policy periods.';

const yearValidator: ValidatorFn = (control) => {
  const year = control.value;
  if (!year) {
    return null;
  }

  const today = moment.utc();
  const yearMoment = moment.utc(year, YEAR_MASK);
  const yearFarPast = moment.utc(YEAR_FAR_PAST, YEAR_MASK);
  const yearIsInTheFuture = yearMoment.isAfter(today, 'year');
  const yearIsInTheFarPast = yearMoment.isBefore(yearFarPast, 'year');
  if (yearIsInTheFuture || yearIsInTheFarPast) {
    return {
      yearIsInvalid: {
        validationMessage: YEAR_ERROR_MESSAGE,
      },
    };
  }

  return null;
};

export const valueIsValidFederalInsuranceNumber: ValidatorFn = (control) => {
  const federalInsuranceNumber = control.value;
  if (_.isNull(federalInsuranceNumber)) {
    return null;
  }

  // optional federal id was not entered and does not need to be validated
  if (!federalInsuranceNumber) {
    return null;
  }

  // federal id entered, needs to be validated
  if (!FEIN_MASK.test(federalInsuranceNumber)) {
    return {
      federalInsuranceNumberIsInvalid: {
        validationMessage: FEIN_ERROR_MESSAGE,
      },
    };
  }

  return null;
};

export const valueIsGreaterThanZero: ValidatorFn = (control) => {
  // The field value is either money (as a `string` type) or a number (as a
  // `number` type). For money, it's parsed as a number before any comparisons
  // occur.
  let fieldValue = control.value;
  if (_.isNull(fieldValue)) {
    return null;
  }

  if (_.isString(fieldValue)) {
    const currencyInvalid = validateCurrencyIsNotEmpty(control);

    if (currencyInvalid) {
      return currencyInvalid;
    }

    fieldValue = parseMoney(fieldValue);
  }

  if (fieldValue <= 0) {
    return {
      valueNotGreaterThanZero: {
        validationMessage: ZERO_ERROR_MESSAGE,
      },
    };
  }

  return null;
};

export const validateLmEffectiveDate: ValidatorFn = (effectiveDateControl) => {
  const effectiveDate = effectiveDateControl.value;
  if (!effectiveDate) {
    return null;
  }

  const dateMoment = moment.utc(effectiveDate, US_DATE_MASK);
  const today = moment.utc();
  const maxFutureDate = moment.utc().add(120, 'days');
  if (dateMoment.isBefore(today, 'date') || dateMoment.isAfter(maxFutureDate, 'date')) {
    return {
      effectiveDateOutOfRange: {
        value: effectiveDate,
        validationMessage:
          'Please select a current or future date within the next 120 calendar days.',
      },
    };
  }

  return null;
};

export const validateLmLossDate: ValidatorFn = (dateControl) => {
  const lossDate = dateControl.value;
  if (!lossDate) {
    return null;
  }

  const dateInFuture = dateIsInPastValidator(dateControl);
  if (dateInFuture) {
    return dateInFuture;
  }

  const dateMoment = moment.utc(lossDate, [US_DATE_MASK, US_DATE_SINGLE_DIGIT_MASK]);
  const fourYearsAgo = moment.utc().subtract(4, 'year');
  if (dateMoment.isBefore(fourYearsAgo, 'date')) {
    return {
      dateTooFarInPast: {
        value: lossDate,
        validationMessage: LOSS_DATE_ERROR_MESSAGE,
      },
    };
  }

  return null;
};

export const validateMinMaxNumber = (
  min: number = -Infinity,
  max: number = Infinity
): ValidatorFn => {
  return (numberControl) => {
    const numberValue = +numberControl.value;
    if ((!numberValue && numberValue !== 0) || numberValue < min || numberValue > max) {
      return {
        numberOutOfRange: {
          value: numberValue,
          validationMessage: `Please enter a number between ${min} and ${max}.`,
        },
      };
    }

    return null;
  };
};

// This function adds max length and questionNote to dsl questions TEXT_AREA questions
// that do not already have a max length or questionNote
export const setupTextLengthExceededErrorMessage = (
  lmFormQuestions:
    | Record<LmCpspQuestion, FormDslConfiguration>
    | Record<LmBopQuestion, FormDslConfiguration>,
  textLengthLimit: number
) => {
  return _.mapValues(lmFormQuestions, (config: FormDslConfiguration) => {
    if (
      config.formDslNode.primitive === 'TEXT_AREA' &&
      !config.formDslNode.questionNote &&
      !config.formDslNode.maxLength
    ) {
      const maxLength = Number.parseInt(config.formDslNode.maxLength as string, INT_RADIX);
      if (maxLength < textLengthLimit) {
        config.formDslNode.questionNote = TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(maxLength);
      } else {
        config.formDslNode.questionNote = TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(textLengthLimit);
        config.formDslNode.maxLength = `${textLengthLimit}`;
      }
    }
    return config;
  });
};

export const setPhoneValidators = (
  phoneFormFields: string[]
): {
  [key in
    | LmBopQuestion
    | LmBopNestedControls
    | LmBopFormStepPath
    | LmCpspQuestion
    | LmCpspNestedControls
    | LmCpspFormStepPath]?: ValidatorFn[];
} => {
  return _.zipObject(
    phoneFormFields,
    _.map(phoneFormFields, () => [phoneValidatorWithMessage(PHONE_ERROR_MESSAGE)])
  );
};

export const setYearValidators = (
  yearFormFields: LmBopQuestion[] | LmCpspQuestion[]
): {
  [key in LmBopQuestion | LmBopFormStepPath | LmCpspQuestion | LmCpspFormStepPath]?: ValidatorFn[];
} => {
  return _.zipObject(
    yearFormFields,
    _.map(yearFormFields, () => [yearValidator])
  );
};
