<div
  class="js-product-card js-product-card__{{ productConfig.pasSource }}-{{ productConfig.product }}"
  [ngClass]="{
    'product-eligibility-box': !compact,
  }"
  *ngIf="displayProductCard({ product: productConfig.product, pasSource: productConfig.pasSource })"
>
  <div class="account-policies-header" [ngClass]="{ 'account-policies-header__small': compact }">
    <div class="product-badge-group">
      <div
        class="product-badge product-badge__{{ productConfig.product }}"
        [ngClass]="{ 'product-badge__small': compact }"
      >
        <div
          class="product-badge-carrier-icon product-badge-carrier-icon__{{
            productConfig.pasSource
          }}"
          [ngClass]="{ 'product-badge-carrier-icon__small': compact }"
        ></div>
      </div>
      <div>
        <h3 class="account-policies-header-h3">{{ productConfig.productName }}</h3>
        <p class="p p__no-margin">
          {{ productConfig.carrierDisplayName }}
          <!-- CPSP guidelines temporarily disabled -->
          <ng-container *ngIf="!!productConfig.guidelinesLink && productConfig.product !== 'cpsp'">
            &nbsp;&nbsp;<a [href]="productConfig.guidelinesLink" target="_blank">Learn more</a>
          </ng-container>
        </p>
      </div>
    </div>
    <div
      *ngIf="
        status === 'empty' &&
        isEligible &&
        isProductAvailable({ product: productConfig.product, pasSource: productConfig.pasSource })
      "
    >
      <a
        [routerLink]="'/accounts/' + accountId + '/' + productConfig.pathForUrl + '/new'"
        class="button button__small button__primary button__no-margin js-new-quote-button"
        [attr.data-amplitude-input-name]="getQuoteAmplitudeInputName()"
      >
        Get a Quote
      </a>
    </div>
    <span
      *ngIf="
        isEligible &&
        !isProductAvailable({ product: productConfig.product, pasSource: productConfig.pasSource })
      "
      class="nav-quote-button-disabled js-{{ productConfig.pasSource }}-{{
        productConfig.product
      }}-not-in-appetite-warning"
    >
      Unavailable
    </span>
    <span
      *ngIf="!isEligible"
      class="nav-quote-button-disabled js-{{ productConfig.pasSource }}-{{
        productConfig.product
      }}-not-in-appetite-warning"
    >
      Ineligible
    </span>
    <div *ngIf="status === 'has-quotes'" class="nav-button-group">
      <a
        *ngIf="editFlowIsAvailable()"
        [routerLink]="
          '/accounts/' +
          accountId +
          '/' +
          productConfig.computePathForEditUrl(filteredQuotes[0].uuid)
        "
        [queryParams]="carrierAllowsMultipleQuotes() ? { fromQuote: filteredQuotes[0].uuid } : {}"
        [attr.data-amplitude-input-name]="editQuoteAmplitudeInputName()"
        class="button button__small button__secondary button__no-margin"
      >
        {{ getEditQuoteText() }}
      </a>
    </div>
    <span
      *ngIf="status === 'has-quotes' && !editFlowIsAvailable()"
      class="nav-quote-button-disabled"
    >
      Edit unavailable
    </span>
    <div
      *ngIf="boundWithSubjectivity"
      class="more-info-tip tool-tip tool-tip__large tool-tip__left"
      [attr.data-tooltip]="
        'This policy is currently bound with a subjectivity. The policyholder must electronically sign the application to remove this subjectivity.'
      "
    >
      <span class="icon icon-question"></span>
    </div>
  </div>
</div>
