<section class="account-billing-card">
  <div class="account-policies-header">
    <div class="product-badge-group">
      <div class="product-badge product-badge__billing"></div>
      <div>
        <h3 class="account-policies-header-h3">Billing summary</h3>
      </div>
    </div>
    <a
      [routerLink]="getBillingLink()"
      data-amplitude-input-name="accounts_view_billing_information"
      target="_blank"
    >
      View full billing information
    </a>
  </div>
  <div class="account-policies-content">
    <section class="placeholder-section" *ngIf="loading()"></section>

    <section *ngIf="isBannerShowing() && !includesCyberPolicy()">
      <app-dialog-box
        *ngIf="banners.noOpenInvoice"
        type="primary"
        class="dialog-text__full-width dialog-text__no-margin js-no-open-invoice"
        >There are no outstanding invoices.
      </app-dialog-box>

      <app-dialog-box
        *ngIf="banners.openEnrolledInvoices"
        type="primary"
        class="dialog-text__full-width dialog-text__no-margin js-open-invoice"
        attr.aria-describedBy="{{ showBillingBannerTip() ? 'billing-banner-educational-tip' : '' }}"
      >
        A payment of {{ earliestInvoiceAmountDue | currency }} will automatically be drafted on
        {{ formatDate(threeDaysBeforeEarliestDueDate) }}.
      </app-dialog-box>

      <app-dialog-box
        *ngIf="banners.overDueInvoices"
        type="warning"
        class="dialog-text__full-width dialog-text__no-margin js-cancelled-pastdue"
        attr.aria-describedBy="{{ showBillingBannerTip() ? 'billing-banner-educational-tip' : '' }}"
      >
        This policy has an overdue invoice due on {{ formatDate(earliestInvoiceDueDate) }}.
        <div class="dialog-text-links">
          <a
            class="dialog-text-link"
            target="_blank"
            *ngIf="getEarliestDueInvoiceRoute()"
            [routerLink]="getEarliestDueInvoiceRoute()"
            [queryParams]="getEarliestDueInvoiceQueryParams()"
            (click)="onInvoiceNav()"
            >View invoice</a
          >
        </div>
      </app-dialog-box>

      <app-dialog-box
        *ngIf="banners.openInvoices"
        type="primary"
        class="dialog-text__full-width dialog-text__no-margin js-cancelled-pastdue"
        attr.aria-describedBy="{{ showBillingBannerTip() ? 'billing-banner-educational-tip' : '' }}"
      >
        This policy has an invoice due on {{ formatDate(earliestInvoiceDueDate) }}.
        <div class="dialog-text-links">
          <a
            class="dialog-text-link"
            target="_blank"
            *ngIf="getEarliestDueInvoiceRoute()"
            [routerLink]="getEarliestDueInvoiceRoute()"
            [queryParams]="getEarliestDueInvoiceQueryParams()"
            (click)="onInvoiceNav()"
            >View invoice</a
          >
        </div>
      </app-dialog-box>

      <app-dialog-box
        *ngIf="banners.autopayPastDueInvoices"
        type="danger"
        class="dialog-text__full-width dialog-text__no-margin js-active-pastdue"
        attr.aria-describedBy="{{ showBillingBannerTip() ? 'billing-banner-educational-tip' : '' }}"
      >
        Autopayment for this account failed. Please make payment using an alternative card or ACH
        account, or contact our Customer Care team.
        <div class="dialog-text-links">
          <a
            class="dialog-text-link"
            target="_blank"
            *ngIf="getEarliestDueInvoiceRoute()"
            [routerLink]="getEarliestDueInvoiceRoute()"
            [queryParams]="getEarliestDueInvoiceQueryParams()"
            (click)="onInvoiceNav()"
          >
            View invoice
          </a>
          <a
            style="margin-left: 1.5rem"
            class="dialog-text-link"
            href="https://attunehelp.zendesk.com/hc/en-us/requests/new"
            >Get in touch</a
          >
        </div>
      </app-dialog-box>

      <app-dialog-box
        *ngIf="banners.activePastDueInvoices"
        [type]="isPendingCancelPolicy ? 'danger' : 'warning'"
        class="dialog-text__full-width dialog-text__no-margin js-active-pastdue"
        attr.aria-describedBy="{{ showBillingBannerTip() ? 'billing-banner-educational-tip' : '' }}"
      >
        This policy has an overdue invoice. If not paid by
        <span *ngIf="isPendingCancelPolicy"> {{ formatDate(cancellationDate) }} </span>
        <span *ngIf="isActivePolicy"> {{ formatDate(gracePeriodAfterEarliestDueDate) }}</span> , a
        notice of cancellation will be sent to the insured.
        <div class="dialog-text-links">
          <a
            class="dialog-text-link"
            target="_blank"
            *ngIf="getEarliestDueInvoiceRoute()"
            [routerLink]="getEarliestDueInvoiceRoute()"
            [queryParams]="getEarliestDueInvoiceQueryParams()"
            (click)="onInvoiceNav()"
          >
            View invoice
          </a>
        </div>
      </app-dialog-box>

      <app-dialog-box
        *ngIf="banners.openUnenrolledInvoice"
        type="warning"
        class="dialog-text__full-width dialog-text__no-margin js-active-pastdue"
        attr.aria-describedBy="{{ showBillingBannerTip() ? 'billing-banner-educational-tip' : '' }}"
      >
        There is an invoice for {{ earliestInvoiceAmountDue | currency }} due on
        {{ formatDate(earliestInvoiceDueDate) }}.
        <div class="dialog-text-links">
          <a
            class="dialog-text-link"
            target="_blank"
            *ngIf="getEarliestDueInvoiceRoute()"
            [routerLink]="getEarliestDueInvoiceRoute()"
            [queryParams]="getEarliestDueInvoiceQueryParams()"
            (click)="onInvoiceNav()"
          >
            View invoice
          </a>
        </div>
      </app-dialog-box>

      <app-education-pane
        position="left-top"
        *ngIf="showBillingBannerTip()"
        [withinScrollContainer]="true"
        elementId="billing-banner-educational-tip"
      >
        Check here to view the billing status of your account.
      </app-education-pane>
    </section>

    <section *ngIf="includesCyberPolicy()">
      <div class="dialog-text-links" id="cyberViewInvoice">
        <a
          class="dialog-text-link"
          target="_blank"
          *ngIf="getEarliestDueInvoiceRoute()"
          [routerLink]="getEarliestDueInvoiceRoute()"
          [queryParams]="getEarliestDueInvoiceQueryParams()"
          (click)="onInvoiceNav()"
        >
          View invoice
        </a>
      </div>
    </section>

    <section *ngIf="hasAutopayEnabled()" class="drop-shadow-section">
      <div class="account-autopay-status">
        <img
          class="account-autopay-icon"
          src="/assets/img/credit_card_icon.svg"
          alt="Credit card"
          *ngIf="hasCreditCardPayment()"
        />
        <img
          class="account-autopay-icon"
          src="/assets/img/ach_icon.svg"
          alt="ACH transfer"
          *ngIf="!hasCreditCardPayment()"
        />
        <span class="account-autopay-status-item js-autopay-method-text">{{
          getPaymentMethodText()
        }}</span>
        <a
          class="
            account-autopay-status-item account-autopay-invoice-link
            js-account-card-edit-payment
          "
          target="_blank"
          (click)="openUpdatePaymentModal()"
          (keyup.enter)="openUpdatePaymentModal()"
          >Update</a
        >
      </div>
    </section>

    <div class="account-billing-help-center">
      <p>
        Need to make a billing related request? Submit via the
        <a
          routerLink="/support"
          [queryParams]="getSupportBillingParams()"
          data-amplitude-input-name="accounts_billing_help_center"
          >help center</a
        >
      </p>
    </div>
  </div>
</section>

<app-modal
  [open]="showPaymentModal"
  (handleModalClose)="closePaymentModal()"
  modalClass="app-modal-content__payment"
>
  <div class="invoice-payment-modal">
    <app-invoices-payment-form
      (formSubmit)="handlePaymentFormSubmit()"
      (closePaymentModal)="closePaymentModal()"
      [amountToPay]="0"
      [onlyShowAchOption]="onlyShowACHOption()"
      [paymentUpdateSuccessful]="paymentUpdateSuccessful"
      [isProcessing]="isProcessing"
      [isUpdateOnly]="false"
      [paymentSuccessMessage]="paymentSuccessMessage"
      [isUpdatingPayment]="isUpdatingPayment"
      [serverError]="serverError"
      [isInitialAutopay]="isInitialAutopay"
      [isAccountPage]="true"
      [currentAutopayIsCard]="hasCreditCardPayment()"
      [currentAutopayText]="getPaymentMethodText()"
      (unenroll)="openUnenrollAutopayModal()"
      [includeCreditCardFee]="isCardProcessingFeeEligible()"
      [hasPaymentDue]="false"
      [creditCardFee]="0"
      [creditCardFeePercentage]="latestInvoiceDetails && latestInvoiceDetails.creditCardPercent"
    >
    </app-invoices-payment-form>
  </div>
</app-modal>

<app-modal
  [open]="showUnenrollAutopayModal"
  (handleModalClose)="closeUnenrollAutopayModal()"
  modalClass="app-modal-content__payment app-modal-content__autopay"
>
  <div class="unenroll-autopay-modal">
    <h2>Unenroll in Autopay?</h2>
    <p>
      Payments will no longer be drafted automatically. Please notify your client that they will be
      responsible for manual payments for the remaining invoices of this policy.
    </p>

    <p class="dialog-text dialog-text__error dialog-text__full-width" *ngIf="unenrollErrorMessage">
      {{ unenrollErrorMessage }}
    </p>
    <button
      (click)="unenrollThroughModal()"
      data-amplitude-input-name="unenroll_autopay"
      class="button button__primary button__full-width js-confirm-unenroll"
      [disabled]="isProcessingUnenroll"
    >
      <ng-container *ngIf="!isProcessingUnenroll"> Yes, unenroll me </ng-container>

      <ng-container *ngIf="isProcessingUnenroll">
        <div class="button-loader">
          <svg class="circle-spinner">
            <circle
              cx="11"
              cy="11"
              r="9"
              stroke-width="2"
              stroke-dasharray="0,1000"
              d="M5 40 l215 0"
              fill="transparent"
            />
          </svg>
        </div>
        Processing unenrollment...
      </ng-container>
    </button>

    <button
      (click)="closeUnenrollAutopayModal()"
      data-amplitude-input-name="cancel_unenroll_autopay"
      class="button button__secondary button__full-width"
      [disabled]="isProcessingUnenroll"
    >
      No, remain enrolled
    </button>
  </div>
</app-modal>
