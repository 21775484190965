import { AbstractPolicyPayment } from 'app/shared/models/abstract-policy-payment';

// This class has been renamed BopGWPolicyPayment since it is tightly coupled w/ BOP payment logic.
export class BopGWPolicyPayment extends AbstractPolicyPayment {
  DOWNPAYMENT_PORTION_FOR_TEN_PAYMENTS = 0.2;

  totalTaxes: number;
  uwCompanyCode: BopUwCompany;
  technologyFee: number;
  processingFee: number;
  policyBaseState: string;

  constructor(bindQuoteDetails: QuoteDetails) {
    super(bindQuoteDetails.totalPremium);

    this.totalTaxes = bindQuoteDetails.totalTaxes;
    this.uwCompanyCode = bindQuoteDetails.uwCompanyCode as BopUwCompany;
    this.technologyFee = bindQuoteDetails.technologyFee || 0;
    this.processingFee = bindQuoteDetails.processingFee || 0;
    this.policyBaseState = bindQuoteDetails.baseState;
  }

  getDownPaymentPremium(numberOfPayments: number) {
    if (numberOfPayments === 10) {
      return this.totalPremium * this.downPaymentFractionForTenPayments();
    }
    return this.totalPremium / numberOfPayments;
  }

  getDownPaymentTax() {
    return this.totalTaxes;
  }

  getTechnologyFee() {
    return this.technologyFee;
  }

  getProcessingFee() {
    return this.processingFee;
  }

  downPaymentFractionForTenPayments() {
    return this.DOWNPAYMENT_PORTION_FOR_TEN_PAYMENTS;
  }
}
