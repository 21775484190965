import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { OnboardingFormService } from 'app/features/onboarding/services/onboarding-form.service';
import { SampleAccountType } from 'app/shared/sample-accounts/sample-accounts';

@Component({
  selector: 'app-onboarding-class-code-next-steps-page',
  templateUrl: './onboarding-classification-page.component.html',
})
export class OnboardingClassificationPageComponent implements OnInit {
  public form: UntypedFormGroup;
  public sampleType = SampleAccountType;

  constructor(private formService: OnboardingFormService) {}

  ngOnInit() {
    this.form = <UntypedFormGroup>this.formService.form;
  }

  hasSubmitted() {
    return this.formService.submitted;
  }
}
