import {
  of as observableOf,
  merge as observableMerge,
  combineLatest as observableCombineLatest,
  Subscription,
  Observable,
  BehaviorSubject,
} from 'rxjs';
import { debounceTime, switchMap, filter, map, tap } from 'rxjs/operators';
import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import {
  InsuredAccount,
  InsuredAccountSummary,
} from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { SentryService } from 'app/core/services/sentry.service';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-insured-account-list',
  templateUrl: './insured-account-list.component.html',
})
export class InsuredAccountListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accountList: Array<InsuredAccount>;
  @Input() activeAccountId: string;
  staticAccountList: BehaviorSubject<Array<InsuredAccount>> = new BehaviorSubject([]);
  query: UntypedFormControl;
  mergedList: BehaviorSubject<Array<InsuredAccountSummary>> = new BehaviorSubject([]);
  filteredList: Observable<Array<InsuredAccountSummary>>;
  searching = false;
  loading = false;
  mobileHidden = true;
  tooManySearchResults = false;

  private sub = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.query = this.formBuilder.control('');

    this.staticAccountList.next(this.accountList);
    this.updateMergedList(this.accountList, []);

    this.loading = true;
    const searchResults = this.query.valueChanges.pipe(
      debounceTime(200),
      tap(() => {
        this.tooManySearchResults = false;
      }),
      filter((potentialQuery) => {
        return potentialQuery.length > 1;
      }),
      switchMap((query) => {
        this.mobileHidden = false;
        this.searching = true;
        return this.insuredAccountService.search(query);
      })
    );

    this.sub.add(
      observableCombineLatest([this.staticAccountList, searchResults]).subscribe(
        ([staticAccounts, response]) => {
          this.updateMergedList(staticAccounts, response);
          this.searching = false;
          if (response.length >= 100) {
            this.tooManySearchResults = true;
          } else {
            this.tooManySearchResults = false;
          }
        }
      )
    );

    const queryChangesWithDefault = observableMerge(
      this.query.valueChanges,
      observableOf(this.query.value)
    );

    // See BOP-307 PR for description of these observables, logic flow
    this.filteredList = observableCombineLatest([queryChangesWithDefault, this.mergedList]).pipe(
      map(([query, mergedList]) => {
        if (query.length === 0) {
          return mergedList;
        }

        try {
          return _.filter(mergedList, (accountSummary) => {
            return (
              _.includes(accountSummary.companyName?.toLowerCase(), query.toLowerCase()) ||
              _.includes(accountSummary.id?.toLowerCase(), query.toLowerCase())
            );
          });
        } catch (error) {
          this.sentryService.notify('Error filtering account list', {
            severity: 'error',
            metaData: {
              accountList: mergedList,
              accountListFilterQuery: query,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return mergedList;
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // issue new static account list if received
    const changedAccountList = changes['accountList'];

    if (changedAccountList) {
      this.staticAccountList.next(changedAccountList.currentValue);
      this.updateMergedList(changedAccountList.currentValue, []);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateMergedList(
    staticAccounts: Array<InsuredAccount>,
    searchResultAccounts: Array<InsuredAccount>
  ) {
    const comboList = this.convertAccounts(staticAccounts).concat(
      this.convertAccounts(searchResultAccounts)
    );
    this.mergedList.next(_.uniqBy(comboList, 'id'));
    this.loading = false;
  }

  convertAccounts(acctList: Array<InsuredAccount>) {
    if (acctList === undefined) {
      // Initial account list is undefined, when the component is first created
      return [];
    }

    return acctList.map((account) => {
      return {
        address: account.address(),
        companyName: account.companyName,
        id: account.id,
        sampleAccount: account.sampleAccount || false,
      };
    });
  }

  showListOnMobile() {
    this.mobileHidden = false;
  }

  hideListOnMobile() {
    this.mobileHidden = true;
  }

  public sidebarLinkActive(accountId: string) {
    return this.activeAccountId === accountId.toString()
      ? 'account-sidebar-account-link__selected'
      : '';
  }
}
