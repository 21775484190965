import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SentryService } from '../../../core/services/sentry.service';
import { Observable, of, of as observableOf, throwError } from 'rxjs';
import { UserService } from '../../../core/services/user.service';
import { InsuredAccountService } from '../../../features/insured-account/services/insured-account.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../core/services/feature-flag.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { User } from '../../models/user';
import { API_V4_BASE } from '../../../constants';
import { BillingApiService } from '../../insured-account/billing-api.service';

@Injectable()
export class IncentivesNewUsersService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private userService: UserService,
    private insuredAccountService: InsuredAccountService,
    private featureFlagService: FeatureFlagService,
    private billingApiService: BillingApiService
  ) {}

  getNewUserBinds(): Observable<{ binds: number }> {
    return this.http
      .get<{ binds: string }>(`${API_V4_BASE}/policies/count`, {
        params: {
          sinceDate: moment().subtract(30, 'days').toString(),
        },
      })
      .pipe(
        map((policesCountResponse) => {
          return { binds: Number(policesCountResponse.binds) };
        }),
        catchError((error: any) => {
          this.sentryService.notify('New User Binds Rewards: Error getting bind data', {
            severity: 'error',
            metaData: {
              underlyingError: error,
              underlyingErrorMessage: error && error.message,
            },
          });
          return observableOf({ binds: 0 });
        })
      );
  }

  getUserCreationTime(): Observable<moment.Moment | null> {
    return this.featureFlagService
      .guaranteeIsEnabled(BOOLEAN_FLAG_NAMES.NEW_USER_BIND_REWARDS_IN_PORTAL)
      .pipe(
        switchMap((enabled: boolean) => {
          if (!enabled) {
            return throwError(null);
          }
          return this.billingApiService.getPayableStatus();
        }),
        switchMap((result) => {
          if (!result?.hasDirectCommission) {
            return throwError(null);
          }
          return this.userService.getUser();
        }),
        switchMap((user: User) =>
          this.insuredAccountService.getProducerCodeCreationTime(user.producer)
        ),
        catchError(() => of(null))
      );
  }
}
