import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { BopQuotePayload } from '../../models/bop-policy';
import { AttuneBopQuoteService } from '../../services/attune-bop-quote.service';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import {
  NewInformationalService,
  INFORMATIONAL_NAMES,
} from 'app/shared/services/new-informational.service';

import {
  AVAILABLE_PROPERTY_DEDUCTIBLES,
  AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES,
  AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY,
  BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS,
  AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES,
  AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES_UP_TO_1000000,
  isBopV2orV3,
} from '../../models/constants';

export enum LambdaModelToExecute {
  AOP_DEDUCTIBLE = 'aop-deductible',
  PER_OCCURRENCE_LIMIT = 'per-occurrence-limit',
  BI_PERIOD_OF_INDEMNITY = 'bi-period-of-indemnity',
  EQUIPMENT_BREAKDOWN_COVERAGE = 'equipment-breakdown-coverage',
}

export type EstimateResponse = {
  [key in LambdaModelToExecute]: {
    prediction: number[];
  };
};

export interface PriceReductionLambdaPayload {
  modelsToExecute: LambdaModelToExecute[];
  oldPremium: number;
  oldAopDeductible: number;
  newAopDeductible: number;
  oldPerOccurrenceLimit: number;
  newPerOccurrenceLimit: number;
  oldPeriodOfIndemnity: number;
  newPeriodOfIndemnity: number;
  oldEquipmentBreakdownCoverage: number;
  newEquipmentBreakdownCoverage: number;
}

@Component({
  selector: 'app-bop-price-reduction-estimate',
  templateUrl: './attune-bop-price-reduction-estimate.component.html',
})
export class AttuneBopPriceReductionEstimateComponent implements OnInit, OnChanges {
  @Input() quote: QuoteDetails;
  @Input() policyId: string;
  @Input() translatedQuote: BopQuotePayload;
  @Output() submitRequote: EventEmitter<BopQuotePayload> = new EventEmitter();

  constructor(
    protected bopQuoteService: AttuneBopQuoteService,
    private formBuilder: UntypedFormBuilder,
    private amplitudeService: AmplitudeService,
    private newInformationalService: NewInformationalService
  ) {}

  shouldShowAopPriceReduction = false;
  shouldShowPerOccurrencePriceReduction = false;
  shouldShowBIPeriodReduction = false;
  shouldShowEquipmentBreakdownPriceReduction = false;
  priceReductionEstimateFormGroup: UntypedFormGroup;
  aopQuoteOptions: { [key: string]: number } = {};
  perOccurrenceQuoteOptions: { [key: string]: number } = {};
  biPeriodQuoteOptions: { [key: string]: string } = {};
  bestCaseAopOption: number;
  bestCasePerOccurrOption: number;
  bestCaseBIPeriodOption = 6;
  equipmentBreakdownCoverageOptions: { [key: number]: string } = { 1: 'Yes', 0: 'No' };
  bestCaseEquipmentBreakdownOption: 1 | 0 = 0;
  bestCaseOptions: PriceReductionLambdaPayload[] = [];
  useBestOptionsChanges = false;
  estimatedHighestSavings: number | null = null;
  estimatedNewPremium: number | null = null;
  estimatedAopSavings: number | null = null;
  estimatedPerOccurrenceSavings: number | null = null;
  estimatedBIPeriodSavings: number | null = null;
  estimatedEquipmentBreakdownSavings: number | null = null;
  estimatesObservable$: Observable<any>;

  ngOnInit() {
    this.initializePriceReductionEstimateForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quote || changes.translatedQuote) {
      this.initializePriceReductionEstimateForm();
    }
  }

  // Show all users new badge but only log first time it's seen
  logPriceEstimateModuleFirstUserChange() {
    const userHasSeenPriceReductionEstimate = this.newInformationalService.getValue(
      INFORMATIONAL_NAMES.PRICE_REDUCTION_ESTIMATE
    );

    if (!userHasSeenPriceReductionEstimate && this.priceReductionEstimateFormGroup.dirty) {
      this.newInformationalService.incrementValue(INFORMATIONAL_NAMES.PRICE_REDUCTION_ESTIMATE);
      this.amplitudeService.track({
        eventName: 'price_reduction_estimate_first_estimate_interaction',
        detail: this.policyId,
      });
    }
  }

  initializePriceReductionEstimateForm() {
    this.setAvailablePropertyDeductibles();
    this.setAvailablePerOccurrenceOptions();
    this.setupEstimateForm();
  }

  setAvailablePropertyDeductibles(): void {
    let aopOptions: number[] = [];
    if (isBopV2orV3(this.quote.uwCompanyCode)) {
      aopOptions =
        this.quote.baseState === 'NY'
          ? AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES_FOR_NY
          : AVAILABLE_BOPV2_PROPERTY_DEDUCTIBLES;
    } else {
      aopOptions =
        this.quote.baseState === 'NY'
          ? AVAILABLE_PROPERTY_DEDUCTIBLES_FOR_NY
          : AVAILABLE_PROPERTY_DEDUCTIBLES;
    }

    aopOptions.forEach((option) => (this.aopQuoteOptions[option] = option));

    this.bestCaseAopOption = Object.values(this.aopQuoteOptions)[
      Object.values(this.aopQuoteOptions).length - 1
    ];
    // If the _highest_ deductible is already selected for all locations, do not show that row in estimate form
    this.shouldShowAopPriceReduction = !this.quote.locations.every(
      (location) => Number(location.aopDeductible) === this.bestCaseAopOption
    );
  }

  setAvailablePerOccurrenceOptions(): void {
    const perOccurrenceOptions: number[] =
      this.quote.coverages.liquorLiabilityCoverage &&
      this.quote.coverages.liquorLiabilityCoverage.optedIn === 'true'
        ? Object.values(
            AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES_UP_TO_1000000
          )
        : Object.values(AVAILABLE_LIMITS_PER_OCCURRENCE_OF_LIABILITY_AND_MEDICAL_EXPENSES);

    perOccurrenceOptions.map((option) => (this.perOccurrenceQuoteOptions[option] = option));

    this.bestCasePerOccurrOption = Object.values(this.perOccurrenceQuoteOptions)[0];
    // If the _lowest_ per occurrence option is already selected, do not show that row in estimate form
    this.shouldShowPerOccurrencePriceReduction =
      this.bestCasePerOccurrOption !== Number(this.quote.patternCodes.perOccurenceLimit);
  }

  setStartingFormValues() {
    let currentBIPeriod;
    if (this.translatedQuote && this.translatedQuote.locations[0]) {
      currentBIPeriod =
        this.translatedQuote.locations[0].buildings[0].coverage
          .businessIncomeAndExtraExpensesIndemnityInMonths;
    }
    Object.keys(BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS).map(
      (key: string) =>
        (this.biPeriodQuoteOptions[key] =
          BUSINESS_INCOME_AND_EXTRA_EXPENSE_INDEMNITY_PERIOD_OPTIONS[key].toString())
    );
    this.shouldShowBIPeriodReduction =
      currentBIPeriod && this.bestCaseBIPeriodOption !== currentBIPeriod ? true : false;

    let currentEquipmentBreakdownCov = 0;
    let noSpoilage = false;
    if (this.translatedQuote && this.translatedQuote.locations[0].buildings[0].coverage) {
      noSpoilage = this.translatedQuote.locations.every((location) =>
        location.buildings.every((building) =>
          building.coverage && building.coverage.spoilage ? building.coverage.spoilage === 0 : true
        )
      );
      currentEquipmentBreakdownCov = this.translatedQuote.locations[0].buildings[0].coverage
        .equipmentBreakdownCoverageOptedIn
        ? 1
        : 0;
    }
    this.shouldShowEquipmentBreakdownPriceReduction =
      currentEquipmentBreakdownCov === 1 && noSpoilage ? true : false;

    this.priceReductionEstimateFormGroup = this.formBuilder.group({
      newAopDeductible: [Number(this.quote.locations[0].aopDeductible), Validators.required],
      newPerOccurrenceLimit: [
        Number(this.quote.patternCodes.perOccurenceLimit),
        Validators.required,
      ],
      newPeriodOfIndemnity: [currentBIPeriod, Validators.required],
      // pass equipment breakdown as string so select-input doesn't format it as currency
      newEquipmentBreakdownCoverage: [currentEquipmentBreakdownCov.toString(), Validators.required],
    });

    this.addEstimateFormListeners();
  }

  setupEstimateForm() {
    this.setStartingFormValues();

    // Set best case options & use for 'Reduce premium by up to' call
    const reducePremiumUpTo = this.priceReductionEstimateFormGroup.value;
    reducePremiumUpTo.newAopDeductible = this.bestCaseAopOption;
    reducePremiumUpTo.newPerOccurrenceLimit = this.bestCasePerOccurrOption;
    reducePremiumUpTo.newPeriodOfIndemnity = this.bestCaseBIPeriodOption;
    reducePremiumUpTo.newEquipmentBreakdownCoverage = this.bestCaseEquipmentBreakdownOption;

    // Sets estimated highest savings based on lowest per occurr + highest deductible
    // Also wakes up price reduction estimate endpoint - first call can be slow if lambda has gone to sleep
    this.getEstimate(reducePremiumUpTo, 'reducePremiumUpTo');
  }

  getEstimate(newValues: PriceReductionLambdaPayload, estimateTypeDetail: string) {
    this.bestCaseOptions = this.createLocationRequestPayloads(newValues);
    const requests = this.bestCaseOptions.map((option) =>
      this.bopQuoteService
        .getPriceReductionEstimate(this.policyId, option)
        .pipe(startWith(undefined))
    );
    this.estimatesObservable$ = forkJoin(requests).pipe(
      map((estimates: EstimateResponse[], index: number) => {
        let accAop = 0;
        let accPerOccurrence = 0;
        let accBIPeriod = 0;
        let accEquipment = 0;

        estimates.forEach((estimate: EstimateResponse) => {
          const reqBody = {
            requestPart: index,
            ...this.bestCaseOptions[index],
          };
          this.amplitudeService.trackWithOverride({
            eventName: 'get_price_reduction_estimate',
            detail: `${estimateTypeDetail}`,
            payloadOverride: {
              getPriceReductionReq: JSON.stringify(reqBody),
              getPriceReductionRes: JSON.stringify(estimate),
            },
          });
          accAop += estimate[LambdaModelToExecute.AOP_DEDUCTIBLE].prediction[1];
          accPerOccurrence += estimate[LambdaModelToExecute.PER_OCCURRENCE_LIMIT].prediction[1];
          accBIPeriod += estimate[LambdaModelToExecute.BI_PERIOD_OF_INDEMNITY].prediction[1];
          accEquipment += estimate[LambdaModelToExecute.EQUIPMENT_BREAKDOWN_COVERAGE].prediction[1];
        });
        return {
          'aop-deductible': accAop,
          'per-occurrence-limit': accPerOccurrence,
          'bi-period-of-indemnity': accBIPeriod,
          'equipment-breakdown-coverage': accEquipment,
        };
      })
    );
    this.estimatesObservable$.subscribe((res) =>
      this.calculateChangesAndPremium(res, estimateTypeDetail)
    );
  }

  calculateChangesAndPremium(newValues: { [key: string]: number }, estimateTypeDetail: string) {
    if (estimateTypeDetail === 'reducePremiumUpTo') {
      let upTo = 0;
      Object.keys(newValues).forEach((newValKey) => (upTo += newValues[newValKey]));
      this.estimatedHighestSavings = upTo;
    } else {
      this.estimatedAopSavings = newValues[LambdaModelToExecute.AOP_DEDUCTIBLE];
      this.estimatedPerOccurrenceSavings = newValues[LambdaModelToExecute.PER_OCCURRENCE_LIMIT];
      this.estimatedBIPeriodSavings = newValues[LambdaModelToExecute.BI_PERIOD_OF_INDEMNITY];
      this.estimatedEquipmentBreakdownSavings =
        newValues[LambdaModelToExecute.EQUIPMENT_BREAKDOWN_COVERAGE];

      this.estimatedNewPremium =
        Number(this.quote.totalCost) -
        (this.estimatedAopSavings +
          this.estimatedPerOccurrenceSavings +
          this.estimatedBIPeriodSavings +
          this.estimatedEquipmentBreakdownSavings);
      this.useBestOptionsChanges = false;
    }
  }
  addEstimateFormListeners(): void {
    this.priceReductionEstimateFormGroup.valueChanges.subscribe(
      (changes: PriceReductionLambdaPayload) => {
        this.logPriceEstimateModuleFirstUserChange();
        if (!this.useBestOptionsChanges) {
          this.getEstimate(changes, 'inputChangeEstimate');
        }
      }
    );
  }

  useBestOptions() {
    // Set reduce premium changes to true to prevent form listeners from firing again after formValues are patched
    this.useBestOptionsChanges = true;

    // because equipment breakdown is hidden for quotes with spoilage, don't patch those values
    if (this.shouldShowEquipmentBreakdownPriceReduction) {
      this.priceReductionEstimateFormGroup.patchValue({
        newAopDeductible: this.bestCaseAopOption,
        newPerOccurrenceLimit: this.bestCasePerOccurrOption,
        newPeriodOfIndemnity: this.bestCaseBIPeriodOption,
        newEquipmentBreakdownCoverage: this.bestCaseEquipmentBreakdownOption.toString(),
      });
    } else {
      this.priceReductionEstimateFormGroup.patchValue({
        newAopDeductible: this.bestCaseAopOption,
        newPerOccurrenceLimit: this.bestCasePerOccurrOption,
        newPeriodOfIndemnity: this.bestCaseBIPeriodOption,
      });
    }

    this.getEstimate(this.priceReductionEstimateFormGroup.value, 'useBestOptions');
  }

  createLocationRequestPayloads(
    changes: PriceReductionLambdaPayload
  ): PriceReductionLambdaPayload[] {
    const priceReductionEstimates: PriceReductionLambdaPayload[] = [];
    const currentPerOccurrence: number = Number(this.quote.patternCodes.perOccurenceLimit);
    const policyLineItems: { [key: string]: number }[] = this.quote.allCosts.filter(
      (cost) => !cost.DisplayName.endsWith(`Location`)
    );
    const totalPolicyPortion: number = policyLineItems.reduce((accumulatedCost, locationItem) => {
      return accumulatedCost + Number(locationItem.ActualAmount_amt);
    }, 0);

    this.quote.locations.forEach((location, i) => {
      let totalPremiumPerLocation = 0;
      const currentAopDeductible: number = Number(location.aopDeductible);
      const locationLineItems = this.quote.allCosts.filter((cost) =>
        cost.DisplayName.endsWith(`${i + 1}: Location`)
      );
      const totalLocationPortion: number = locationLineItems.reduce(
        (accumulatedCost, locationItem) => {
          return accumulatedCost + Number(locationItem.ActualAmount_amt);
        },
        0
      );

      let currentBIPeriod = 6;
      if (this.translatedQuote && this.translatedQuote.locations[i]) {
        currentBIPeriod =
          this.translatedQuote.locations[i].buildings[0].coverage
            .businessIncomeAndExtraExpensesIndemnityInMonths;
      }

      let currentEquipmentBreakdownCov: 0 | 1 = 0;
      if (this.translatedQuote) {
        currentEquipmentBreakdownCov = this.translatedQuote.locations[i].buildings[0].coverage
          .equipmentBreakdownCoverageOptedIn
          ? 1
          : 0;
      }

      totalPremiumPerLocation +=
        totalPolicyPortion / this.quote.locations.length + totalLocationPortion;

      const priceReductionEstimatePiece: PriceReductionLambdaPayload = {
        modelsToExecute: [
          LambdaModelToExecute.AOP_DEDUCTIBLE,
          LambdaModelToExecute.PER_OCCURRENCE_LIMIT,
          LambdaModelToExecute.BI_PERIOD_OF_INDEMNITY,
          LambdaModelToExecute.EQUIPMENT_BREAKDOWN_COVERAGE,
        ],
        oldPremium: Number(totalPremiumPerLocation),
        oldAopDeductible: Number(currentAopDeductible),
        newAopDeductible: Number(changes.newAopDeductible),
        oldPerOccurrenceLimit: Number(currentPerOccurrence),
        newPerOccurrenceLimit: Number(changes.newPerOccurrenceLimit),
        oldPeriodOfIndemnity: Number(currentBIPeriod),
        newPeriodOfIndemnity: Number(changes.newPeriodOfIndemnity),
        oldEquipmentBreakdownCoverage: Number(currentEquipmentBreakdownCov),
        newEquipmentBreakdownCoverage: Number(changes.newEquipmentBreakdownCoverage),
      };

      priceReductionEstimates.push(priceReductionEstimatePiece);
    });

    return priceReductionEstimates;
  }
  clearUIEstimates() {
    this.estimatedAopSavings = null;
    this.estimatedPerOccurrenceSavings = null;
    this.estimatedBIPeriodSavings = null;
    this.estimatedEquipmentBreakdownSavings = null;
    this.estimatedNewPremium = null;
    this.useBestOptionsChanges = false;
  }

  clearUserChanges() {
    this.clearUIEstimates();
    this.setStartingFormValues();
  }

  requoteAsNewQuote() {
    const patchedQuote = this.translatedQuote;
    patchedQuote.locations.forEach((location) => {
      location.locationDetails.propertyDeductible =
        this.priceReductionEstimateFormGroup.value.newAopDeductible.toString();
      location.buildings.forEach((building) => {
        building.coverage.businessIncomeAndExtraExpensesIndemnityInMonths =
          this.priceReductionEstimateFormGroup.value.newPeriodOfIndemnity;

        // lambda takes 1 | 0 for opt-in/opt-out coverage,
        // translates back to boolean for patched quote
        building.coverage.equipmentBreakdownCoverageOptedIn =
          Number(this.priceReductionEstimateFormGroup.value.newEquipmentBreakdownCoverage) === 1
            ? true
            : false;
        return;
      });
    });
    patchedQuote.liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses =
      this.priceReductionEstimateFormGroup.value.newPerOccurrenceLimit.toString();

    this.estimatedHighestSavings = null;
    this.clearUIEstimates();
    this.logPriceEstimateModuleFirstUserChange();

    this.submitRequote.emit(patchedQuote);
  }

  formatChangeEstimate(estimateAmount: number) {
    // Negative estimate predictions from the lambda = an increase in premium
    // So we strip the negative from these values and return the formatted currency string
    // Otherwise positive change column values = reduction in premium so we add a `-`
    return estimateAmount < 0
      ? `$${Math.abs(estimateAmount).toFixed(2)}`
      : `-$${estimateAmount.toFixed(2)}`;
  }

  formatEstimate(estimateAmount: number) {
    return estimateAmount < 0
      ? `-$${Math.abs(estimateAmount).toFixed(2)}`
      : `$${estimateAmount.toFixed(2)}`;
  }
}
