<a
  *ngIf="showCancelButton"
  class="button button__nav button__nav-sidebar-quote button__no-margin button__nav-white-bg"
  [routerLink]="['/accounts/', accountId]"
>
  <span class="icon-arrow-left button-icon"></span>
  Cancel
</a>

<header class="quote-sidebar">
  <div class="product-badge product-badge__{{ product }} product-badge__sidebar">
    <div
      *ngIf="pasSource"
      class="product-badge-carrier-icon product-badge-carrier-icon__{{ pasSource }}"
    ></div>
  </div>
  <h3 class="h3">{{ sidebarTitle }}</h3>
  <small>{{ insAccount?.companyName }}</small>

  <ul class="sidebar-breadcrumbs">
    <ng-container *ngFor="let step of getSteps()">
      <li
        [ngClass]="{
          'sidebar-breadcrumb': true,
          'sidebar-breadcrumb__active': this.isCurrentStep(step),
          'sidebar-breadcrumb__substep': step.substep,
          'sidebar-breadcrumb__disabled': disableNavigationToStep
        }"
        (click)="handleStepClicked(step)"
        (keyup.enter)="handleStepClicked(step)"
      >
        {{ step.displayName }}
      </li>
    </ng-container>
  </ul>

  <div *ngIf="isDevMode">
    <div class="adminz-only">
      <h3>Debug</h3>
      <pre><strong>Quote Form Data: </strong>{{ this.form.value | json }}</pre>
      <br />
      <hr />

      <div *ngIf="showHappyPathButton()">
        <button
          (click)="happyPathHandler()"
          class="button button__primary js-hiscox-quote-form-happy-path-button"
        >
          Happy Path Button
        </button>
      </div>
    </div>
  </div>
</header>
