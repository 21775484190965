import { Injectable } from '@angular/core';

import { UserService } from 'app/core/services/user.service';

@Injectable()
export class Always {
  constructor(private userService: UserService) {}

  canActivate() {
    this.userService.stopValidatingSession();

    return true;
  }
}
