import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { enableDisableControl, getFormGroup } from 'app/shared/helpers/form-helpers';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

export enum UserRoleOption {
  QUOTE = 'quoteBusinesses',
  SERVICE = 'servicePolicies',
  BOTH = 'both',
}

export const ROLES_TO_SKIP_SAMPLE_QUOTE = [UserRoleOption.SERVICE];

@Injectable({
  providedIn: 'root',
})
export class OnboardingFormService extends FormDslSteppedFormBaseService {
  public form: UntypedFormGroup;
  public submitted = false;
  public now: moment.Moment = moment();
  public showClassificationStep = true;

  // Flags
  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.initializeForm();
    this.syncAllSteps();
  }

  /**
   * Setup Methods
   */
  public initializeForm() {
    const userRole = this.formBuilder.group({
      role: [null, Validators.required],
      sampleQuoteOptIn: [{ value: null, disabled: true }, Validators.required],
    });

    const sampleClassification = this.formBuilder.group({
      selection: [null, Validators.required],
    });

    this.form = this.formBuilder.group({
      userRole,
      sampleClassification,
    });
  }

  public generateSteps(): RouteFormStep[] {
    const steps = [
      {
        args: {},
        displayName: 'Introduction',
        slug: 'introduction',
        parent: 'introduction',
        formPath: 'userRole',
        nextButtonText: 'Get started',
      },
    ];

    if (this.showClassificationStep) {
      steps.push({
        args: {},
        displayName: 'Classification',
        slug: 'classification',
        parent: 'classification',
        formPath: 'sampleClassification',
        nextButtonText: 'Start quote',
      });
    }
    return steps.filter(this.isStep);
  }

  public toggleClassificationStep(displayStep: boolean) {
    this.showClassificationStep = displayStep;

    const classification = getFormGroup(this.form, 'sampleClassification.selection');
    if (classification) {
      enableDisableControl(classification, displayStep);
    }

    this.syncAllSteps();
  }

  public fillInHappyPath(): void {}
}
