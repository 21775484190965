<div
  *ngIf="!loading; else loadingState"
  class="account-policies-header js-product-card__{{ carrier }}-{{ lob }}"
  [ngClass]="{ 'account-policies-header__small': small, 'product-eligibility-box': !small }"
>
  <div class="product-badge-group">
    <div
      class="product-badge product-badge__{{ lob | lowercase }}"
      [ngClass]="{ 'product-badge__small': small }"
    >
      <div
        class="product-badge-carrier-icon product-badge-carrier-icon__{{ carrier | lowercase }}"
        [ngClass]="{ 'product-badge-carrier-icon__small': small }"
      ></div>
    </div>
    <div>
      <h3 class="account-policies-header-h3">{{ lob | lobDisplayName }}</h3>
      <p class="p p__no-margin js-p-carrier">
        {{ carrier | carrierDisplayName }}
        <!-- CPSP guidelines temporarily disabled -->
        &nbsp;&nbsp;<a
          class="js-guidelines-link"
          *ngIf="guidelinesLink && lob !== 'cpsp' && lob !== 'flood'"
          [href]="guidelinesLink"
          target="_blank"
          >Learn more</a
        >
      </p>
    </div>
  </div>
  <div class="nav-button-group nav-button-group__centered">
    <span
      *ngIf="!isEligible; else availability"
      class="nav-quote-button-disabled js-{{ carrier }}-{{ lob }}-not-in-appetite-warning"
    >
      Ineligible
    </span>
    <ng-template #availability>
      <span
        *ngIf="!isAvailable; else editAvailability"
        class="nav-quote-button-disabled js-{{ carrier }}-{{ lob }}-not-in-appetite-warning"
      >
        Unavailable
      </span>
    </ng-template>
    <ng-template #editAvailability>
      <span
        *ngIf="hasQuotes && !hasEditableQuotes; else existingQuotes"
        class="nav-quote-button-disabled"
      >
        Edit unavailable
      </span>
    </ng-template>
    <ng-template #existingQuotes>
      <button
        *ngIf="hasQuotes; else newQuote"
        class="button button__small button__secondary button__no-margin js-edit-quote-button js-edit-quote-button-{{
          carrier
        }}-{{ lob }}"
        attr.data-amplitude-input-name="account-edit-quote-{{ carrier }}-{{ lob }}"
        (click)="navigate.emit({ type: 'edit' })"
        (keyup.enter)="navigate.emit({ type: 'edit' })"
      >
        Edit
      </button>
    </ng-template>
    <ng-template #newQuote>
      <button
        class="button button__small button__primary button__no-margin js-new-quote-button js-new-quote-button-{{
          carrier
        }}-{{ lob }}"
        attr.data-amplitude-input-name="account-new-quote-{{ carrier }}-{{ lob }}"
        (click)="navigate.emit({ type: 'new' })"
        (keyup.enter)="navigate.emit({ type: 'new' })"
      >
        Get a Quote
      </button>
    </ng-template>
  </div>
</div>
<ng-template #loadingState>
  <div
    class="account-policies-header"
    [ngClass]="{ 'account-policies-header__small': small, 'product-eligibility-box': !small }"
  >
    <div class="product-badge-group">
      <div
        class="product-badge product-badge__loading-placeholder"
        [ngClass]="{ 'product-badge__small': small }"
      ></div>
      <h3 class="account-policies-header-h3__loading-placeholder"></h3>
    </div>
    <div class="loading-placeholder-button"></div>
  </div>
</ng-template>
