import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import {
  enableDisableControl,
  getControl,
  zipCodeValidator,
} from 'app/shared/helpers/form-helpers';
import {
  DependentFields,
  DEPENDENT_FIELDS_MAP,
  OPTIONAL_INFORMATION_TYPES,
} from '../models/bop-additional-insured-business';
import { ADDITIONAL_INSURED_TYPES_WITH_NO_ENTITY } from 'app/features/attune-bop/models/constants';
import { BaseFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

export const AdditionalInsuredsMixin = <T extends Constructor<BaseFormService>>(BaseClass: T) => {
  return class extends BaseClass {
    addAdditionalInsuredBusiness(additionalInsuredsFormArray: UntypedFormArray): UntypedFormGroup {
      const formBuilder = new UntypedFormBuilder();

      const additionalInsured = formBuilder.group({
        [DependentFields.ADDITIONAL_INFORMATION]: [
          { value: '', disabled: true },
          Validators.required,
        ],
        address: formBuilder.group({
          addressLine1: [null, [Validators.required, Validators.maxLength(60)]],
          addressLine2: [null, Validators.maxLength(60)],
          city: [null, Validators.required],
          state: [null, Validators.required],
          zip: [null, [Validators.required, zipCodeValidator]],
        }),
        addressType: [null, Validators.required],
        [DependentFields.BUILDING_DESCRIPTION]: [
          { value: null, disabled: true },
          Validators.required,
        ],
        businessName: [null, Validators.required],
        [DependentFields.CONTRACT_NUMBER]: [{ value: '', disabled: true }],
        [DependentFields.LIMIT_OF_INSURANCE]: [
          { value: null, disabled: true },
          Validators.required,
        ],
        [DependentFields.PARAGRAPH]: [{ value: null, disabled: true }, Validators.required],
        guidewireId: [null],
        relatedLocation: ['location-1-1', null],
        relatedEntityId: [null],
        type: [null, Validators.required],
      });

      additionalInsuredsFormArray.push(additionalInsured);

      (<UntypedFormControl>additionalInsured.get('type')).valueChanges.subscribe(
        (additionalInsuredType) => {
          const toEnable = DEPENDENT_FIELDS_MAP[additionalInsuredType] || [];
          Object.values(DependentFields).forEach((k: DependentFields) => {
            if (toEnable.includes(k)) {
              if (additionalInsured.disabled) {
                return;
              }
              (<UntypedFormControl>additionalInsured.get(k)).enable();
            } else {
              (<UntypedFormControl>additionalInsured.get(k)).disable();
            }
          });
          const isOptional = OPTIONAL_INFORMATION_TYPES.includes(additionalInsuredType);
          const additionalInformationControl = additionalInsured.get(
            DependentFields.ADDITIONAL_INFORMATION
          ) as UntypedFormControl;
          if (isOptional) {
            additionalInformationControl.clearValidators();
            additionalInformationControl.updateValueAndValidity();
          } else {
            additionalInformationControl.setValidators([Validators.required]);
            additionalInformationControl.updateValueAndValidity();
          }
          // Additional insured types corresponding to forms that don't apply to specific entity (they provide coverage broadly as opposed to a specific business name and address)
          const hasEntity = !Object.values(ADDITIONAL_INSURED_TYPES_WITH_NO_ENTITY).includes(
            additionalInsuredType
          );
          const enable = additionalInsured.enabled && hasEntity;
          enableDisableControl(getControl(additionalInsured, 'address'), enable);
          enableDisableControl(getControl(additionalInsured, 'addressType'), enable);
          enableDisableControl(getControl(additionalInsured, 'businessName'), enable);
        }
      );
      return additionalInsured;
    }

    removeAdditionalInsuredBusinessAt(
      index: number,
      additionalInsuredsFormArray: UntypedFormArray
    ) {
      additionalInsuredsFormArray.removeAt(index);
    }
  };
};
