import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { InvoicesListService } from 'app/features/invoices/services/invoices-list.service';
import {
  BOP_PRODUCT_NAME,
  WC_PRODUCT_NAME,
  EXCESS_PRODUCT_NAME,
  HAB_PRODUCT_NAME,
  ROWS_PER_PAGE_OPTIONS,
  FILTER_STATUS_OPTIONS,
  MAP_TO_BACKEND_STATUSES,
} from 'app/features/invoices/models/invoices-constants';
import { UserService } from 'app/core/services/user.service';
import { debounceTime, switchMap, tap, filter, map } from 'rxjs/operators';
import { User } from 'app/shared/models/user';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { VersionCheckService } from 'app/shared/services/version-check.service';
import * as moment from 'moment';

@Component({
  selector: 'app-invoices-page.app-page.app-page__billing',
  templateUrl: './invoices-page.component.html',
})
export class InvoicesPageComponent implements OnInit, OnDestroy {
  user: User;
  invoices: BackendListInvoice[];
  producerCode: string;
  billingQuery: UntypedFormControl;
  indexOfFirstInvoiceToFetch = 0;
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS;
  filterStatusOptions = FILTER_STATUS_OPTIONS;
  mapToBackendStatuses = MAP_TO_BACKEND_STATUSES;
  numberOfInvoicesToFetch = 25;
  totalInvoices = 0;
  filterStatus = 'All';
  loading = true;
  hasNextPage = true;
  hasPreviousPage = true;
  min = Math.min;
  private businessId$: Observable<string>;
  private sub = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceListService: InvoicesListService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private versionCheckService: VersionCheckService,
    private ref: ChangeDetectorRef,
    @Inject('Window') private window: Window
  ) {}

  ngOnInit() {
    this.billingQuery = this.formBuilder.control('');

    this.businessId$ = this.route.params.pipe(
      map(() => {
        if (!this.route.firstChild || !this.route.firstChild.snapshot.params) {
          return this.route.snapshot.params;
        }

        return this.route.firstChild.snapshot.params;
      }),
      filter((params) => Object.prototype.hasOwnProperty.call(params, 'id') && params.id),
      map((params) => params.id),
      tap(() => (this.loading = true))
    );

    this.sub.add(
      this.businessId$.subscribe((businessId) => {
        this.billingQuery.setValue(businessId);
      })
    );

    this.sub.add(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          const params = this.route.firstChild
            ? this.route.firstChild.snapshot.params
            : this.route.snapshot.params;

          if (!params.id) {
            this.billingQuery.setValue('');
            this.resetRowsPerPage();
            this.resetFilters();
          }
        }
      })
    );

    // Check if JS code that is served is different than code running in the browser.
    this.sub.add(
      this.versionCheckService.hasCodeBeenUpdated.subscribe((isCodeUpdated: boolean) => {
        // If code/build has changed, reload the page on the billing list page (this should be a safe place to do a refresh).
        if (isCodeUpdated) {
          this.window.location.reload();
        }
      })
    );

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.user = user;
        this.goToPage(this.indexOfFirstInvoiceToFetch, this.numberOfInvoicesToFetch);

        this.billingQuery.valueChanges
          .pipe(
            debounceTime(750),
            switchMap((query) => {
              this.resetRowsPerPage();
              this.resetFilters();

              this.goToPage(this.indexOfFirstInvoiceToFetch, this.numberOfInvoicesToFetch);

              return EMPTY;
            })
          )
          .subscribe();
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  goToPage(pageNumber: number, pageSize: number) {
    let businessName;
    let accountNumber;
    let invoiceStatuses;

    this.loading = true;

    // Search by business name or account number or status filter
    if (this.billingQuery.value.length > 0 || this.filterStatus) {
      [businessName, accountNumber] = this.getBillingQueries();
      invoiceStatuses = this.mapToBackendStatuses[this.filterStatus];
    }

    this.invoiceListService
      .getInvoiceList(
        this.user.producer,
        pageNumber,
        pageSize,
        businessName,
        accountNumber,
        invoiceStatuses
      )
      .subscribe((data: BackendInvoiceSummary) => {
        this.totalInvoices = data.invoiceCount;
        if (_.isArray(data.invoices) && data.invoices.length > 0) {
          this.invoices = this.filteredInvoices(data.invoices);
        } else {
          this.invoices = [];
          this.hasNextPage = false;
        }

        this.loading = false;

        if (pageNumber <= 0) {
          this.hasPreviousPage = false;
        } else {
          this.hasPreviousPage = true;
        }

        if ((pageNumber + 1) * pageSize > data.invoiceCount) {
          this.hasNextPage = false;
        } else {
          this.hasNextPage = true;
        }

        this.ref.detectChanges();
      });
  }

  nextPage() {
    this.indexOfFirstInvoiceToFetch =
      this.numberOfInvoicesToFetch + this.indexOfFirstInvoiceToFetch;

    this.goToPage(this.indexOfFirstInvoiceToFetch, this.numberOfInvoicesToFetch);
  }

  previousPage() {
    if (this.indexOfFirstInvoiceToFetch <= 0) {
      this.indexOfFirstInvoiceToFetch = 0;
    } else {
      this.indexOfFirstInvoiceToFetch =
        this.indexOfFirstInvoiceToFetch - this.numberOfInvoicesToFetch;
    }

    this.goToPage(this.indexOfFirstInvoiceToFetch, this.numberOfInvoicesToFetch);
  }

  isOnlyExcess(invoice: BackendListInvoice): boolean {
    return _.every(invoice.lineItems, (item) => item.lineOfBusiness === EXCESS_PRODUCT_NAME);
  }

  hasBop(invoice: BackendListInvoice): boolean {
    return _.some(invoice.lineItems, (item) => item.lineOfBusiness === BOP_PRODUCT_NAME);
  }

  hasWorkersComp(invoice: BackendListInvoice): boolean {
    return _.some(invoice.lineItems, (item) => item.lineOfBusiness === WC_PRODUCT_NAME);
  }

  hasExcess(invoice: BackendListInvoice): boolean {
    return _.some(invoice.lineItems, (item) => item.lineOfBusiness === EXCESS_PRODUCT_NAME);
  }

  hasHab(invoice: BackendListInvoice): boolean {
    return _.some(invoice.lineItems, (item) => item.lineOfBusiness === HAB_PRODUCT_NAME);
  }

  getBadge(invoice: BackendListInvoice): string {
    let badgeClass = '';

    if (this.hasWorkersComp(invoice)) {
      badgeClass += 'product-badge__wc';
    }
    if (this.hasBop(invoice)) {
      badgeClass += 'product-badge__bop';
    }
    if (this.isOnlyExcess(invoice)) {
      badgeClass += ' product-badge__excess';
    }
    if (this.hasExcess(invoice)) {
      badgeClass += ' product-badge__with-excess';
    }
    if (this.hasHab(invoice)) {
      badgeClass += ' product-badge__hab';
    }

    return badgeClass;
  }

  getBillingQueries() {
    const businessNameQuery = isNaN(this.billingQuery.value) ? this.billingQuery.value : undefined;

    const accountNumberQuery =
      isNaN(this.billingQuery.value) || this.billingQuery.value.length === 0
        ? undefined
        : this.billingQuery.value;

    return [businessNameQuery, accountNumberQuery];
  }

  clear() {
    this.billingQuery.setValue('');
  }

  search() {
    if (this.billingQuery.value.length > 0) {
      this.indexOfFirstInvoiceToFetch = 0;
      this.resetFilters();
      this.goToPage(this.indexOfFirstInvoiceToFetch, this.numberOfInvoicesToFetch);
    }
  }

  placeholderRange() {
    return _.range(0, 25);
  }

  getPageNumber() {
    return this.indexOfFirstInvoiceToFetch / this.numberOfInvoicesToFetch + 1;
  }

  getPageTotal() {
    return Math.ceil(this.totalInvoices / this.numberOfInvoicesToFetch);
  }

  updateRowsPerPage(size: string) {
    this.numberOfInvoicesToFetch = parseInt(size, 10);
    this.indexOfFirstInvoiceToFetch = 0;
    this.goToPage(this.indexOfFirstInvoiceToFetch, this.numberOfInvoicesToFetch);
  }

  getDueDate(invoice: BackendListInvoice, format: string) {
    return moment.utc(invoice.dueDate).format(format);
  }

  resetRowsPerPage() {
    this.indexOfFirstInvoiceToFetch = 0;
    this.numberOfInvoicesToFetch = 25;
  }

  updateFilterStatus(status: string) {
    this.filterStatus = status;
    this.indexOfFirstInvoiceToFetch = 0;

    this.goToPage(this.indexOfFirstInvoiceToFetch, this.numberOfInvoicesToFetch);
  }

  resetFilters() {
    this.filterStatus = 'All';
  }

  filteredInvoices(invoices: BackendListInvoice[]): BackendListInvoice[] {
    const query = this.billingQuery.value;
    if (query.length === 0) {
      return invoices;
    }

    return _.filter(invoices, (invoiceSummary) => {
      if (!isNaN(query)) {
        return invoiceSummary.accountNumber ? invoiceSummary.accountNumber === query : false;
      } else {
        return invoiceSummary.businessName
          ? _.includes(invoiceSummary.businessName.toLowerCase(), query.toLowerCase())
          : false;
      }
    });
  }

  getInvoiceUrlSegments(invoice: BackendListInvoice): string[] {
    const urlSegments: string[] = [];
    if (this.hasHab(invoice)) {
      urlSegments.push('/hab');
    } else {
      urlSegments.push('/bop');
    }
    return urlSegments.concat(['invoice', invoice.id]);
  }

  // Deprecated
  // Use billing/helpers/invoice-list-helpers.ts
  invoiceStatus(invoice: BackendListInvoice): string {
    switch (invoice.status) {
      case 'Billed':
        return 'billed';
      case 'Due':
        return 'past-due';
      case 'Planned':
        return 'future';
      case 'Paid':
        return 'paid';
      case 'Void':
        return 'void';
      default:
        return 'unknown';
    }
  }
}
