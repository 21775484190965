// Global constants go here
import { environment } from 'environments/environment';
import { DigitalCarrierProduct } from './features/digital-carrier/models/types';

export const CUSTOMER_CARE_HOURS = {
  start: '9am',
  end: '7pm',
  timezone: 'EST',
};

export const OKTA_BASE = 'https://attune.okta.com';
export const OKTA_ISSUER_URI = `${OKTA_BASE}/oauth2/default`;
export const MOBILE_WIDTH_THRESHOLD = 639;
export const SMALL_LAPTOP_THRESHOLD = 1280;
export const USER_TOKEN_REFRESH_IN_SECONDS = 10 * 60;
export const USER_LOGGED_IN_CHECK_INTERVAL_IN_SECONDS = 5;
export const USER_TOKEN_EXPIRATION_IN_MINUTES = 12;
export const ADP_USER_TOKEN_EXPIRATION_IN_MINUTES = 20;
export const USER_IDLE_LOGOUT_IN_MINUTES = 30;
export const USER_IDLE_CHECK_INTERVAL_IN_SECONDS = 1;
export const FIVE_SECONDS_IN_MS = 5 * 1000;
export const APP_ENV = environment.stage;
export const API_URI_BASE = environment.apiHost;
export const API_V3_BASE = environment.apiV3Host;
export const API_V4_BASE = environment.apiV4Host;
export const INVOICE_VENDOR_PAYMENT_URI = environment.invoiceVendorPaymentUri;
export const GOOGLE_MAPS_API_KEY = environment.googleMapsKey;
export const LOGIN_OKTA_API_URI = `${API_URI_BASE}/login/translate`;
export const GET_FINANCE_ADMIN_LINK_MAIN_API_URI = `${API_V3_BASE}/user/finance-admin-link-main`;
export const VALIDATE_PASSWORD_LINK_API_URI = `${API_V3_BASE}/user/validate-password-link`;
export const LIST_ENHANCEMENT_LIBRARY_URL = `${API_V3_BASE}/documents/enhancement-library-documents`;
export const FORGOT_PASSWORD_API_URI = `${API_V3_BASE}/user/forgot-password`;
export const RESET_PASSWORD_API_URI = `${API_V3_BASE}/user/reset-password`;
export const SET_PASSWORD_API_URI = `${API_V3_BASE}/user/set-password`;
export const GET_ACCOUNT_API_URI = `${API_V3_BASE}/accounts/details`;
export const V3_ACCOUNT_API_URI = `${API_V3_BASE}/accounts`;
export const V4_ACCOUNT_API_URI = `${API_V4_BASE}/accounts`;
export const V4_PRODUCT_AVAILABILITY_API_URI = `${API_V4_BASE}/product-availability`;
export const V3_SMS_SUBSCRIPTION_API_URI = `${API_V3_BASE}/notifications/subscription`;
export const V3_SMS_SUBSCRIBE_API_URI = `${API_V3_BASE}/notifications/subscribe`;
export const V3_SMS_UNSUBSCRIBE_API_URI = `${API_V3_BASE}/notifications/unsubscribe`;
export const USER_API_URL = `${API_V3_BASE}/user`;
export const V4_USER_API_URL = `${API_V4_BASE}/user`;
export const GET_USER_API_URI = `${API_URI_BASE}/v1/user/user`;
export const V4_USER_ATTRIBUTES_API_URL = `${API_V4_BASE}/user/attributes`;
export const ACCEPT_INVITE_API_URI = `${API_V3_BASE}/user/accept-invite`;
export const CANCEL_INVITE_API_URI = `${API_V3_BASE}/user/cancel-invite`;
export const INVITE_USER_API_URI = `${API_V3_BASE}/user/invite`;
export const REFRESH_USER_API_URI = `${API_URI_BASE}/refresh`;
export const INVOICE_AUTOPAY_URL = `${API_V3_BASE}/billing/autopay/`;
export const UPDATE_AUTOPAY_URL = `${API_V3_BASE}/billing/autopay/enrollment`;
export const INVOICE_DETAILS_API_URI = `${API_V3_BASE}/billing/invoices/`;
export const UPDATE_PAYMENT_PLAN_URL = `${API_V3_BASE}/billing/payments/payment-plan`;
export const GET_COMMISSIONS_API_URL = `${API_V3_BASE}/commissions/statements/`;
export const GET_PAYABLE_STATUS_API_URL = `${API_V3_BASE}/commissions/payable-status/`;
export const GET_QUOTE_LETTER_PDF = `${API_V3_BASE}/documents/get-quote-letter/`;
export const GET_POLICY_DOC_PDF = `${API_V3_BASE}/documents/get-policy-document/`;
export const GET_FORM_PDF = `${API_URI_BASE}/v1/doc/viewFormTemplate?`;
export const GET_ODEN_DOC_PDF = `${API_V3_BASE}/documents/policy/`;
export const GET_COMMISSION_DOC = `${API_V3_BASE}/documents/get-commission-statement/`;
export const GET_LOSS_RUNS_BY_POLICY = `${API_V4_BASE}/accounts/policy/get-loss-runs`;
export const GET_LOSS_RUNS_PDF = `${API_V4_BASE}/accounts/policy/loss-runs-download`;
export const V3_REFER_API_URI = `${API_V3_BASE}/refer/intra`;
export const V3_ADP_API_URI = `${API_V3_BASE}/adp`;
export const GET_ACCOUNT_BILLING_STATUS_API_URI = `${API_V3_BASE}/billing/account/billing-status`;
export const GET_DOCUMENT_UPLOAD_LINK = `${API_V3_BASE}/managed-documents/upload-link`;
export const MOMENT_TIMEZONE_PACIFIC = 'America/Los_Angeles';
export const GHOST_API_URI = 'https://attune.ghost.io/ghost/api/v2/content/posts/';
export const GHOST_CONTENT_KEY = '31c8848adf3d34fb572e69e435';
export const BILLING_PAGE_URL = `${API_V3_BASE}/billing/invoices-info`;
export const FEIN_MASK = /\d{2}-\d{7}/;
export const US_DATE_MASK = 'MM/DD/Y';
export const US_DATE_SINGLE_DIGIT_MASK = 'M/D/YYYY';
export const US_DATE_ALL_DIGIT_MASK = 'MM/DD/YYYY';
export const ISO_DATE_MASK = 'YYYY-MM-DD';
export const YEAR_MASK = 'YYYY';
export const US_DATE_MASK_PATTERN = /\d{2}\/\d{2}\/\d{4}/;
export const ISO_DATE_MASK_PATTERN = /\d{4}-\d{2}-\d{2}/;
export const USD_MONEY_PATTERN = /^\$(0|[1-9][0-9]{0,2})(,\d{3})*(\.\d{1,2})?$/;
export const USD_PHONE_PATTERN = /^(\+1-)?\d{3}-\d{3}-?\d{4}$/;
export const URL_PATTTERN_VALIDATION =
  '^(https?:\\/\\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\\.[a-z]{2,}(\\/.*)?.?$';
export const ALERT_STATUS_S3_URI = environment.alertStatusUri;
export const PROMO_S3_URI =
  'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/attune_promo_info.txt';
export const DOMAIN_AUTH_ALLOWLIST: string[] = environment.domainAuthAllowlist;

export const FULLSTORY_HEADER_ALLOWLIST = [
  `${API_V3_BASE}/quotes/`,
  `${API_V3_BASE}/policy/`,
  `${API_V3_BASE}/drafts/`,
  `${API_V3_BASE}/growth/`,
  `${API_V3_BASE}/naics/`,
  `${API_V3_BASE}/user/`,
  `${API_V3_BASE}/accounts`,
  `${API_V4_BASE}`,
];

export const EMAIL_PREFERENCES_API_URI = `${API_V3_BASE}/email-preferences`;
export const PREFILL_WITH_UW_DECISION_API_URI = `${API_V3_BASE}/prefillDecision`;
export const ARCHIVE_ACCOUNT_API_URI = `${API_V3_BASE}/quotes/bop`;
export const SEND_LOSS_RUNS_EMAIL_URI = `${API_V3_BASE}/quotes/loss-runs-email`;
export const AMPLITUDE_ANALOGUE_URL = `${API_V3_BASE}/growth/AmplitudeAnalogue`;
export const GET_POLICY_PERIOD_URI = `${API_V3_BASE}/policy/`;
export const GET_PRERENEWAL_DIRECTION_URL = `${API_V3_BASE}/policy/prerenew-direction`;
export const V3_EXCESS_VIA_ZENDESK = `${API_V3_BASE}/quotes/excess-liability/manual-quote`;
export const PRODUCER_DETAILS_URL = `${API_V3_BASE}/quotes/producer-details/`;
export const PRODUCT_FEEDBACK_URL = `${API_V3_BASE}/quotes/product-feedback`;
export const ACCOUNT_CONTACTS_URL = `${API_V3_BASE}/quotes/account-contacts/`;
export const CURRENT_CONTACT_URL = `${API_V3_BASE}/user/current-contact`;
export const PRODUCER_CODE_DETAILS_URL = `${API_V3_BASE}/quotes/producer-code/`;
export const FOLLOW_ACCOUNT_URL = `${API_V3_BASE}/quotes/follow-account`;
export const FOLLOW_NEW_ACCOUNT_URL = `${API_V3_BASE}/quotes/follow-new-account`;
export const UNFOLLOW_ACCOUNT_URL = `${API_V3_BASE}/quotes/unfollow-account`;
export const V3_CANCELLED_SUMMARY_API = `${API_V3_BASE}/quotes/cancelled-summary`;
export const V3_RENEWALS_SUMMARY_API = `${API_V3_BASE}/quotes/renewals-summary`;
export const V3_PENDING_CANCELLATION_SUMMARY_API = `${API_V3_BASE}/quotes/pending-cancellations-summary`;
export const V3_POLICY_COUNT_API = `${API_V3_BASE}/quotes/policy-count-summary`;
export const V3_ACCOUNTS_BY_POLICY_NO = `${API_V3_BASE}/accounts/policy/`;
export const getUWAlertUrl = (accountNumber: string) =>
  `${API_V3_BASE}/accounts/${accountNumber}/uw-alerts`;
export const V3_ELIGIBILITY_CHECK_API = `${API_V3_BASE}/quotes/bop/eligibility-checkpoint`;
export const getEligibilityDeclineReasonsUrl = (product: 'bop' | 'wc') =>
  `${API_V3_BASE}/quotes/${product}/decline-reasons`;
export const ZENDESK_GET_ACCOUNT_API = `${API_V3_BASE}/external/zd/account/`;

// Value used as a placeholder for the Organization Type account field, allowing that field to be omitted on account creation
export const PLACEHOLDER_ORG_TYPE = 'trustestate';

// Temporary Feature Flags
export const HAS_HAB = environment.hasHab;
export const HAB_PRODUCER_CODES = ['SIATEST00011'];

export const HAS_ENDORSEMENTS = environment.hasEndorsements;

// This will be replaced by the git sha passed during the docker build.
export const CURRENT_GIT_SHA = '{{CURRENT_GIT_SHA}}';

// Possible user permission levels
export enum UserRole {
  Standard = 'Standard',
  Admin = 'Admin',
}
// Okta Groups
export enum OktaGroup {
  EVERYONE = 'Everyone', // This group contains everyone
  USER_ADMIN = 'user-admin', // This group controls agency membership
  FINANCE_ADMIN = 'finance-admin', // This group manages financial information
}

export const brokerEmailTypes = {
  Invoice: 'Invoices',
  Renewal: 'Renewals',
  Cancellation: 'Cancellations',
};

export const UNACCEPTABLE_DBA_NAMES = [
  'tbd',
  't.b.d',
  'dba',
  'd.b.a',
  'n/a',
  'none',
  'No DBA',
  'Not Applicable',
  '',
];

export const UNACCEPTABLE_TENANT_NAMES = [
  'tbd',
  't.b.d',
  'n/a',
  'none',
  'na',
  'other',
  'tenant',
  'unknown',
  'lro',
  'inc',
  'Not Applicable',
  '',
];

export const UNAUTHENTICATED_PAGE_URLS = [
  'login',
  'password-reset-redirect',
  'billing',
  'invoice',
  'insured',
  'help-center',
  'live-chat',
];

export const WC_DIGITAL_PRODUCT: DigitalCarrierProduct = {
  carrierDisplayName: 'Employers',
  product: 'wc',
  productName: "Workers' Compensation",
  pasSource: 'employers',
  pathForUrl: 'wc',
  eligibilityField: 'isEmployersWcEligible',
  computePathForEditUrl: () => 'wc/edit',
};

export const LM_CPSP_PRODUCT: DigitalCarrierProduct = {
  carrierDisplayName: 'Liberty Mutual',
  product: 'cpsp',
  productName: 'Custom Protector',
  pasSource: 'liberty_mutual',
  pathForUrl: 'lm-cpsp',
  eligibilityField: 'isLibertyMutualCpspEligible',
  guidelinesLink:
    'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Liberty+Mutual+CP+Product+Overview+and+Appetite+Guide+November+2021.pdf',
  referralMessage:
    'Our Underwriting Team needs to review this quote. Please allow up to 2 business days for a response.',
  computePathForEditUrl: (quoteId) => `lm-cpsp/${quoteId}/edit`,
  computePathForRequestBindUrl: (accountNumber, quoteNumber) =>
    `help-center/form/bind-cpsp-quote?accountNumber=${accountNumber}&quoteNumber=${quoteNumber}`,
};

const COALITION_CYBER_ADMITTED: DigitalCarrierProduct = {
  carrierDisplayName: 'Coalition',
  product: 'cyber_admitted',
  productName: 'Cyber Liability',
  pasSource: 'coalition',
  pathForUrl: 'cyber-admitted',
  eligibilityField: 'isCyberEligible',
  guidelinesLink:
    'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/Coalition%20Cyber%20Insurance%20Guidelines%2003.18.2022.pdf',
  underReviewMessage: `This quote is being reviewed by a Coalition underwriter. \
    You will receive an email in 1-2 business days either releasing the quote or requesting additional information. \
    If you have any questions, please contact our Customer Care Team at <a href="tel:18885304650">1-888-530-4650</a> (Mon-Fri, ${CUSTOMER_CARE_HOURS.start}-${CUSTOMER_CARE_HOURS.start} ${CUSTOMER_CARE_HOURS.timezone}) \
    or submit a request through our \
    <a href="https://app.attuneinsurance.com/help-center/form/request-review" target="_blank">Help Center</a>. `,
  referralMessage: `This quote was referred by a Coalition underwriter. \
    You received an email from Coalition requesting additional information to further review this quote. \
    If you did not receive this email or have any questions, please contact our Customer Care Team at <a href="tel:+18885304650" target="_blank">1-888-530-4650</a> (Mon-Fri, ${CUSTOMER_CARE_HOURS.start}-${CUSTOMER_CARE_HOURS.start} ${CUSTOMER_CARE_HOURS.timezone}) or submit a request through our \
    <a href="https://app.attuneinsurance.com/help-center/form/request-review" target="_blank">Help Center</a>.`,
  unavailableMessage: `This quote is unavailable because of a system error. \
    You may attempt another quote by selecting "New quote" from the accounts page.\
    Please contact our Customer Care Team at <a href="tel:+18885304650" target="_blank">1-888-530-4650</a> (Mon-Fri, ${CUSTOMER_CARE_HOURS.start}-${CUSTOMER_CARE_HOURS.start} ${CUSTOMER_CARE_HOURS.timezone}) or \
    visit our <a href="https://app.attuneinsurance.com/help-center" target="_blank">Help Center</a> if you have any questions.`,
  notTakenMessage: `The policy bundle was not signed by the policyholder within ten days of the effective date (30 days in CA). \
   Coalition Cyber Liability policies are not deemed inforce until signed. <br><br> \
   Please contact our Customer Care Team at <a href="tel:+18885304650" target="_blank">1-888-530-4650</a> (Mon-Fri, ${CUSTOMER_CARE_HOURS.start}-${CUSTOMER_CARE_HOURS.start} ${CUSTOMER_CARE_HOURS.timezone}) or \
   visit our <a href="https://app.attuneinsurance.com/help-center" target="_blank">Help Center</a> if you have any questions \
   or wish to reissue the policy.`,
  computePathForEditUrl: () => 'cyber-admitted/new',
};

const COALITION_CYBER_SURPLUS: DigitalCarrierProduct = {
  ...COALITION_CYBER_ADMITTED,
  product: 'cyber_surplus',
  pathForUrl: 'cyber-surplus',
  computePathForEditUrl: () => 'cyber-surplus/new',
  // Even though Admitted and Surplus are separate products, we will only show one product card.
  showOnDifferentProductCard: COALITION_CYBER_ADMITTED,
};

// This will likely come back from an API (e.g. products *you have access to*) - DCP-
export const DIGITAL_PRODUCTS: DigitalCarrierProduct[] = [
  COALITION_CYBER_ADMITTED,
  COALITION_CYBER_SURPLUS,
  {
    carrierDisplayName: 'Hiscox',
    product: 'gl',
    productName: 'General Liability',
    pasSource: 'hiscox',
    pathForUrl: 'gl',
    eligibilityField: 'isGlEligible',
    computePathForEditUrl: () => `gl/edit`,
  },
  {
    carrierDisplayName: 'Hiscox',
    product: 'pl',
    productName: 'Professional Liability',
    pasSource: 'hiscox',
    pathForUrl: 'pl',
    eligibilityField: 'isPlEligible',
    computePathForEditUrl: () => `pl/edit`,
  },
  {
    carrierDisplayName: 'Liberty Mutual',
    product: 'bop',
    productName: "Businessowners' Policy",
    pasSource: 'liberty_mutual',
    pathForUrl: 'lm-bop',
    eligibilityField: 'isLibertyMutualBopEligible',
    guidelinesLink:
      'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Liberty+Mutual+BOP+Product+Overview+and+Appetite+Guide+August+2021.pdf',
    referralMessage:
      'Our Underwriting Team needs to review this quote. Please allow up to 2 business days for a response.',
    computePathForEditUrl: (quoteId) => `lm-bop/${quoteId}/edit`,
  },
  WC_DIGITAL_PRODUCT,
  LM_CPSP_PRODUCT,
];

export const DEFAULT_WARNING_MESSAGE_FOR_QUOTE_DETAILS_PANE =
  'Please contact our Customer Care Team at <a href="tel:18885304650">1-888-530-4650</a> (Mon-Fri, 9am-6pm EST) \
  or <a href="https://app.attuneinsurance.com/help-center">submit a request through our Help Center</a> to complete your quote.';
