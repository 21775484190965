<app-modal
  [open]="open"
  [disableClose]="true"
  [modalClass]="modalClass"
  ariaRole="alertdialog"
  ariaLabelledby="error-header"
  ariaDescribedby="error-body"
  [modalNameForAmplitudeEvent]="'quote-error-modal' + modalName"
>
  <div class="modal-image" role="presentation">
    <img src="{{ errorType.image }}" alt="" />
  </div>

  <h2 id="error-header" class="app-modal-header">{{ errorType.header }}</h2>

  <ng-container *ngIf="errors && errors.length > 0 && !errorType.hideDialogBox">
    <div class="app-modal-error-msg-container js-validation-errors js-validation-error">
      <app-dialog-box
        class="js-validation-error dialog-text__full-width"
        style="text-align: left"
        [type]="errorType.hideDialogBox ? errorType.type : 'danger'"
      >
        <span *ngIf="errorType.dialogMessage">{{ errorType.dialogMessage }}</span>
        <span *ngIf="!errorType.dialogMessage"
          >This quote is ineligible for the following reasons:</span
        >
        <ul class="bulleted-list">
          <li *ngFor="let error of errors">{{ error }}</li>
        </ul>
      </app-dialog-box>
    </div>
  </ng-container>

  <ng-container *ngIf="errorType.message">
    <div class="app-modal-error-msg-container js-validation-message">
      <app-dialog-box class="dialog-text__full-width" style="text-align: left" type="warning">
        {{ errorType.message }}
      </app-dialog-box>
    </div>
  </ng-container>

  <p id="modal-body" class="js-error-body" [innerHTML]="errorType.body" role="alert"></p>

  <div class="nav-button-group nav-button-group__centered-horizontal">
    <button
      *ngIf="errorType.displayClose"
      type="button"
      class="button button__secondary button__no-margin js-close-modal"
      (click)="closeModal()"
    >
      {{ errorType.closeButtonText ? errorType.closeButtonText : 'Close' }}
    </button>
    <button
      *ngIf="errorType.displayRetry"
      type="button"
      class="button button__primary button__no-margin js-resubmit-quote"
      (click)="closeAndRetry()"
    >
      {{ errorType.retryButtonText ? errorType.retryButtonText : 'Retry' }}
    </button>
    <button
      *ngIf="errorType.displayBack"
      type="button"
      class="button button__primary button__no-margin js-back-to-accounts"
      (click)="closeFlow()"
    >
      {{ errorType.backButtonText ? errorType.backButtonText : 'Back to accounts' }}
    </button>
  </div>
</app-modal>
