// WARNING
// Use caution when adding imports to environment files to avoid creating circular dependencies
// Use the same pattern across production and all lower environments when setting up environment variables
//   ex. if you import a constant to set an environment variable in production, import a constant in lower environment files as well
// This allows us to verify production-like behavior when testing changes in lower environments
import { wcCrossSellPromptProducers } from 'app/workers-comp/employers/wcCrossSellPromptProducers';
import { eligibilityLiveChatProducers } from 'app/shared/insured-account/eligibilityLiveChatProducers';

export const environment = {
  adpBearerToken: 'e2c5579f-3bb8-42b5-b5f9-d0f8f85cbda9',
  amplitudeKeys: {
    agentPortal: '15c6c542ed8f53006b9959b1f65c6287',
    intuit: 'b6a94b375d33ebd6ec3a69353ea5dc8e',
    brokerLandingPage: 'e68761cda013109eb0a3a5846664517f',
  },
  alertStatusUri: 'https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/alerts/alert.txt',
  apiHost: 'https://api.attuneinsurance.com/v3/auth',
  apiV3Host: 'https://api.attuneinsurance.com/v3',
  apiV4Host: 'https://api.attuneinsurance.com/v4',
  billerGroupId: 'AIS',
  bopV2LaunchDates: {
    AZ: '2020-11-07',
    MT: '2020-11-17',
    UT: '2020-12-16',
    NM: '2020-12-16',
    NV: '2020-12-16',
    MI: '2020-12-16',
    IA: '2020-12-21',
    WI: '2020-12-21',
    TN: '2020-12-22',
    NH: '2021-01-21',
    AL: '2021-01-25',
    OR: '2021-02-22',
    SC: '2021-02-22',
    AR: '2021-03-01',
    IN: '2021-03-01',
    MS: '2021-03-01',
    OK: '2021-03-01',
    RI: '2021-03-15',
    SD: '2021-03-22',
    MO: '2021-03-22',
    DE: '2021-03-22',
    ND: '2021-03-29',
    PA: '2021-03-29',
    VT: '2021-03-29',
    VA: '2021-03-29',
    WY: '2021-03-29',
    NC: '2021-04-05',
    TX: '2021-04-05',
    ME: '2021-04-08',
    OH: '2021-04-08',
    IL: '2021-04-12',
    CO: '2021-04-12',
    NE: '2021-04-12',
    GA: '2021-04-12',
    KS: '2021-04-19',
    KY: '2021-04-19',
    MD: '2021-04-19',
    WV: '2021-04-19',
    DC: '2021-04-22',
    MA: '2021-07-26',
    LA: '2021-09-18',
    CT: '2021-10-09',
    FL: '2021-11-28',
    ID: '2022-02-18',
    CA: '2022-05-01',
    NJ: '2022-08-06',
    NY: '2024-07-15',
  },
  nyMeTooLaunchDate: '2023-03-14',
  digitalProductsRolledOver: ['liberty_mutual', 'coalition'],
  domainAuthAllowlist: ['api.attuneinsurance.com', 'bill.attuneinsurance.com'],
  eligibilityLiveChatProducers: eligibilityLiveChatProducers,
  enabledBopEndorsementSteps: [
    'account',
    'additionalInsureds',
    'coverages',
    'locations',
    'namedInsureds',
    'otherEndorsements',
    'waiverOfSubrogation',
  ],
  googleMapsKey: 'AIzaSyAJaLfXzmF4hpnzmRofzkuRmKfZJRoDuHg',
  hasBackdatedEndorsements: true,
  hasEndorsements: true,
  hasHab: false,
  invoiceVendorPaymentUri: 'https://www.payconnexion.com/pconWeb/epay.jhtml',
  isSandboxEnv: false,
  launchDarklyKey: '6035731e6faa990b6eab8a13',
  useMockFeatureFlags: false,
  production: true,
  quoteIndicationEndpoint: 'production-bop-quote-indications',
  requireAdpAuth: true,
  showBopBindEducational: true,
  showInvoiceBanners: true,
  showNewHelpCenter: true,
  showNewRenewalsUI: true,
  showSampleQuoteOnboarding: false,
  showScheduleOfInvoices: true,
  showSubmissionDownload: true,
  showUserManagement: true,
  userManagementEnableList: [],
  showWelcomeBack: true,
  stage: 'production',
  statesEnabledForCyberSurplus: [
    'AL',
    'AZ',
    'CO',
    'FL',
    'IL',
    'LA',
    'MA',
    'MI',
    'MO',
    'MS',
    'NC',
    'NJ',
    'NV',
    'NY',
    'OK',
    'RI',
    'SC',
    'TN',
    'TX',
    'VA',
  ],
  stripePublishableKey: 'pk_live_u9QZfkFamzv2SkzgMDceaSwI',
  useGooglePlacesAutoComplete: true,
  showCreditableWaterSupplyField: true,
  storePrefillDeclineDraftQuotes: true,
  usePlanckEligibility: true,
  wcCrossSellPromptProducers: wcCrossSellPromptProducers,
  bindGoalsProducers: [] as string[],
  intraReferralProducers: [],
  lroFlowEnabled: true,
  bopCatCutoffDate: '2022-08-31',
  eligibilityOverrideProducers: ['ADPDIRECT1'],
  allowedCSXSproducerCodes: ['MORSTAN00001', 'GRACEINS00001', 'GLOBAL00001', 'SCHEN00001'],
  oktaClientId: '0oaw9yylm1xp5InO4357',
  oktaLoginRedirectURI: 'https://app.attuneinsurance.com/callback',
  oktaLogoutRedirectURI: 'https://app.attuneinsurance.com/',
};
