import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SentryService } from 'app/core/services/sentry.service';

import { USER_API_URL } from 'app/constants';

export enum BindRewardTiers {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
  PLATINUM = 'platinum',
}

export const BIND_REWARDS_TIERS_CONFIG: {
  [key in BindRewardTiers]: {
    tier: BindRewardTiers;
    coins: number;
    premiumTarget: number;
  };
} = {
  [BindRewardTiers.BRONZE]: {
    tier: BindRewardTiers.BRONZE,
    coins: 20,
    premiumTarget: 0,
  },
  [BindRewardTiers.SILVER]: {
    tier: BindRewardTiers.SILVER,
    coins: 30,
    premiumTarget: 3_500,
  },
  [BindRewardTiers.GOLD]: {
    tier: BindRewardTiers.GOLD,
    coins: 50,
    premiumTarget: 15_000,
  },
  [BindRewardTiers.PLATINUM]: {
    tier: BindRewardTiers.PLATINUM,
    coins: 80,
    premiumTarget: 50_000,
  },
};

export interface BindGoalsData {
  userPreviousYearPremium: number;
  userCurrentYearPremium: number;
  premiumToGoalTier: number;
  currentEarningTier: BindRewardTiers;
  tierPremiumMapping: {
    [key in BindRewardTiers]: { inclusiveStart: number; exclusiveEnd: number | undefined };
  };
  tierBonusMapping: { [key in BindRewardTiers]: number };
}

@Injectable()
export class BindGoalsService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getBindGoalsData(producerCode: string): Observable<BindGoalsData> {
    return this.http
      .get<BindGoalsData>(`${USER_API_URL}/${producerCode}/current-quarter-premium`)
      .pipe(
        map((bindGoalsResponse: BindGoalsData) => {
          return {
            ...bindGoalsResponse,
            premiumToGoalTier: Math.ceil(bindGoalsResponse.premiumToGoalTier),
            userCurrentYearPremium: Math.ceil(bindGoalsResponse.userCurrentYearPremium),
          };
        }),
        catchError((error: any) => {
          this.sentryService.notify('Error getting bind goals data', {
            severity: 'error',
            metaData: {
              producerCode,
              underlyingError: error,
              underlyingErrorMessage: error && error.message,
            },
          });
          throw new Error('Error getting bind goals data');
        })
      );
  }
}
