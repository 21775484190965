import { combineLatest as observableCombineLatest, BehaviorSubject } from 'rxjs';

import { filter, mergeMap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LimitsService } from 'app/shared/services/limits.service';
import { APIData, APIDataStatus } from 'app/shared/models/api-data';
import { UntypedFormGroup } from '@angular/forms';
import { UTILITY_SERVICES_INELIGIBLE_STATES } from 'app/features/attune-bop/models/constants';

const notNull = (x: string | null): x is string => x !== null;
const notUndefined = (x: string | undefined): x is string => x !== undefined;

@Component({
  selector: 'app-utility-services-coverage',
  templateUrl: './utility-services-coverage.component.html',
})
export class UtilityServicesCoverageComponent implements OnInit {
  @Input() building: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() prefix: string;

  _state = new BehaviorSubject<string | null>(null);
  _milesToOcean = new BehaviorSubject<string | null>(null);
  hide = false;

  constructor(private limitsService: LimitsService, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this._state.pipe(filter(notNull)).subscribe((state) => {
      this.hide = UTILITY_SERVICES_INELIGIBLE_STATES.includes(state);
    });
    observableCombineLatest(
      this._state.pipe(filter(notNull)),
      this._milesToOcean.pipe(filter(notNull), filter(notUndefined))
    )
      .pipe(
        mergeMap(([state, milesToOcean]) => {
          return this.limitsService.requestUtilityServiceLimits(state, milesToOcean);
        })
      )
      .subscribe((response: APIData) => {
        this.ref.detectChanges();
      });
  }

  @Input()
  get state() {
    return this._state.getValue();
  }
  set state(value: string | null) {
    this._state.next(value);
  }

  @Input()
  get milesToOcean() {
    return this._milesToOcean.getValue();
  }
  set milesToOcean(value: string | null) {
    this._milesToOcean.next(value);
  }

  utilityServicesDirectDamage(): Array<number> {
    return this.limitsService.utilityServicesDirectDamage(
      this._state.getValue(),
      this._milesToOcean.getValue()
    );
  }

  utilityServicesDirectDamageStatus(): APIDataStatus {
    return this.limitsService.utilityServicesDirectDamageStatus(
      this._state.getValue(),
      this._milesToOcean.getValue()
    );
  }

  utilityServicesTimeElement(): Array<number> {
    return this.limitsService.utilityServicesTimeElement(
      this._state.getValue(),
      this._milesToOcean.getValue()
    );
  }

  utilityServicesTimeElementStatus(): APIDataStatus {
    return this.limitsService.utilityServicesTimeElementStatus(
      this._state.getValue(),
      this._milesToOcean.getValue()
    );
  }
}
