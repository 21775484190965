<section class="loss-runs-upload">
  <a
    class="js-upload-file-button button button__secondary button__no-margin button__full-width"
    (click)="fileInput.click()"
    (keyup.enter)="fileInput.click()"
  >
    <div class="button-loader" *ngIf="fileUploading">
      <svg class="circle-spinner">
        <circle
          cx="11"
          cy="11"
          r="9"
          stroke-width="2"
          stroke-dasharray="0,1000"
          d="M5 40 l215 0"
          fill="transparent"
        />
      </svg>
    </div>
    Upload loss runs
    <span class="sr-only"> loss run documents</span>
    <input
      type="file"
      id="selectFile"
      accept=".pdf"
      name="lossRunFiles"
      (change)="onFilesSelected($event)"
      #fileInput
    />
  </a>
</section>
