// Libraries
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Constants
import {
  getAvailableEachEmployeeLimitsForEmployeeLiability,
  getAvailableAggregateLimitsForEmployeeLiability,
  getAvailableDeductiblesForEmployeeLiability,
  getBaseEmployeeLiabilityLimit,
} from 'app/features/attune-bop/models/constants';
// Helpers
import { shouldShowInvalid } from 'app/shared/helpers/form-helpers';

const FORM_GROUP_NAME = 'employeeBenefitsLiabilityCoverage';

@Component({
  selector: 'app-employee-benefits-liability-coverage',
  templateUrl: './employee-benefits-liability-coverage.component.html',
})
export class EmployeeBenefitsLiabilityCoverageComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() baseState: string;

  availableEachEmployeeLimitsForEmployeeLiability =
    getAvailableEachEmployeeLimitsForEmployeeLiability();
  availableAggregateLimitsForEmployeeLiability = getAvailableAggregateLimitsForEmployeeLiability();
  availableDeductiblesForEmployeeLiability = getAvailableDeductiblesForEmployeeLiability();
  baseEmployeeLimit = getBaseEmployeeLiabilityLimit();

  formSubject: UntypedFormGroup;

  constructor() {}

  ngOnInit() {
    this.updateLimits();

    this.formSubject = <UntypedFormGroup>this.form.get(FORM_GROUP_NAME);
    (<UntypedFormGroup>this.formSubject.get('eachEmployeeLimit')).valueChanges.subscribe(
      (value: string) => {
        this.formSubject.patchValue({ aggregateLimit: parseInt(value, 10) });
      }
    );
  }

  eblcEnabled() {
    const optinControl = this.formSubject.get('optedIn');
    return optinControl && optinControl.enabled;
  }

  updateLimits() {
    this.availableEachEmployeeLimitsForEmployeeLiability =
      getAvailableEachEmployeeLimitsForEmployeeLiability(this.baseState);
    this.availableAggregateLimitsForEmployeeLiability =
      getAvailableAggregateLimitsForEmployeeLiability(this.baseState);
    this.availableDeductiblesForEmployeeLiability = getAvailableDeductiblesForEmployeeLiability(
      this.baseState
    );
    this.baseEmployeeLimit = getBaseEmployeeLiabilityLimit(this.baseState);
  }

  shouldShowInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.formSubject, this.submitted);
  }

  parseEmployeeLimitToInt(figure: string) {
    return parseInt(figure, 10);
  }
}
