import { Subscription, Observable } from 'rxjs';
import { switchMap, shareReplay } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import * as moment from 'moment';

import { GWBindService } from 'app/shared/services/gw-bind.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { InformService } from 'app/core/services/inform.service';
import { SentryService } from 'app/core/services/sentry.service';

@Component({
  selector: 'app-hab-policy-pane.policy-pane.policy-pane__hab',
  templateUrl: './hab-policy-pane.component.html',
  providers: [CurrencyPipe],
})
export class HabPolicyPaneComponent implements OnInit, OnDestroy {
  isDevMode = false;
  model: Observable<QuoteDetails>;
  accountId: string;
  sub: Subscription = new Subscription();
  displayDownloadButton: boolean;
  locationVal: any = [];
  tsRequestId: string;
  public now = moment().startOf('day').add(12, 'hours');
  policyId: string;

  constructor(
    private bindService: GWBindService,
    private route: ActivatedRoute,
    private informService: InformService,
    protected router: Router,
    protected amplitudeService: AmplitudeService,
    protected insuredAccountService: InsuredAccountService,
    private sentryService: SentryService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    // TODO: Get a policy period through quote service
    this.generateRequestId();
    this.model = this.route.params.pipe(
      switchMap((params) => {
        this.policyId = params['policyId'];
        return this.bindService.getQuoteDetails(this.policyId);
      }),
      shareReplay()
    );

    this.accountId = this.route.snapshot.params.accountId;

    this.model.subscribe((loc) => {
      this.locationVal = _.sortBy(loc.locations, 'locationNum');
    });
  }

  get locationValues() {
    return this.locationVal;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  generateRequestId() {
    this.tsRequestId = uuidv4();
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
  }

  formatCost(cost: number) {
    return this.currencyPipe.transform(Math.floor(cost), 'USD', 'symbol', '1.0-0');
  }
}
