import { FormBuilder, FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { isEqual, sortBy, groupBy, some } from 'lodash';

import { validateEmailAddress } from 'app/features/attune-bop/models/form-validators';
import {
  phoneValidator,
  requireCheckboxesToBeCheckedValidator,
} from 'app/shared/helpers/form-helpers';

import { normalizePhoneNumber } from './number-format-helpers';

/**
 * key/value pairings with key representing GW contact role and value being UI-friendly label.
 * Requires constants to be declared as "const" to make the properties readonly.
 */
export type ContactPurposeConstants = Readonly<Partial<Record<InsuredContactRole, string>>>;
export type ContactPurposeKeys<T extends ContactPurposeConstants> = keyof T;

export type ContactPurposeFormGroup<T extends ContactPurposeConstants> = FormGroup<{
  [key in ContactPurposeKeys<T>]: FormControl<boolean | null>;
}>;

export type InsuredContactFormGroup<T extends ContactPurposeConstants> = FormGroup<{
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  emailAddress: FormControl<string | null>;
  phoneNumber: FormControl<string | null>;
  contactPurpose: ContactPurposeFormGroup<T>;
}>;

// Insured contact comparison functions

export const isEqualContact = (contact1: InsuredContact, contact2: InsuredContact) => {
  return (
    contact1.email?.toLowerCase() === contact2.email?.toLowerCase() &&
    contact1.firstName?.toLowerCase() === contact2.firstName?.toLowerCase() &&
    contact1.lastName?.toLowerCase() === contact2.lastName?.toLowerCase() &&
    normalizePhoneNumber(contact1?.phone) === normalizePhoneNumber(contact2?.phone) &&
    isEqual(sortBy(contact1?.roles), sortBy(contact2?.roles))
  );
};

/**
 *
 * @param insuredContacts
 * @param existingInsuredContacts
 * @returns true if am insured contact is being added or modified, false otherwise.
 */
export const hasNewOrUpdatedInsuredContacts = (
  insuredContacts: InsuredContact[],
  existingInsuredContacts: InsuredContact[] | undefined
): boolean => {
  return insuredContacts?.some((contact1) => {
    return !existingInsuredContacts?.some((contact2) => isEqualContact(contact1, contact2));
  });
};

/**
 * Translates an insured contact form value to the insured contact model on the insuredAcount.
 * @param insuredContactFormGroup - form group for the insured contact
 * @returns InsuredContact or undefined
 */

export const translateInsuredContact = <T extends ContactPurposeConstants>(
  insuredContactFormGroup: InsuredContactFormGroup<T>['value']
): InsuredContact | undefined => {
  if (
    !insuredContactFormGroup ||
    !insuredContactFormGroup.emailAddress ||
    !insuredContactFormGroup.firstName ||
    !insuredContactFormGroup.lastName ||
    !insuredContactFormGroup.phoneNumber ||
    !insuredContactFormGroup.contactPurpose
  ) {
    return;
  }

  return {
    email: insuredContactFormGroup.emailAddress,
    firstName: insuredContactFormGroup.firstName,
    lastName: insuredContactFormGroup.lastName,
    phone: normalizePhoneNumber(insuredContactFormGroup.phoneNumber),
    roles: Object.entries(insuredContactFormGroup.contactPurpose)
      .filter(([_role, optedIn]) => !!optedIn)
      .map(([role, _optedIn]) => role as InsuredContactRole),
  };
};

// Form functions

export function createInsuredContactFormArray<T extends ContactPurposeConstants>(
  contactPurposeRoles: T
) {
  const formBuilder = new FormBuilder();
  const insuredContactFormArray = formBuilder.array<InsuredContactFormGroup<T>>([], {
    validators: [createInsuredContactFormArrValidator(contactPurposeRoles)],
  });

  // The insured contact array should start with one contact.
  addInsuredContact(insuredContactFormArray, contactPurposeRoles);
  return insuredContactFormArray;
}

export function addInsuredContact<T extends ContactPurposeConstants>(
  insuredContactFormArr: FormArray<InsuredContactFormGroup<T>>,
  contactPurposeRoles: T
) {
  const formBuilder = new FormBuilder();
  // Opt in for all purposes for the initial contact.
  const defaultOptedIn = insuredContactFormArr.length === 0 ? true : false;

  // Create the contactPurpose form controls based on the contactPurposeRoles object.
  const contactPurposeFormGroup = Object.keys(contactPurposeRoles).reduce(
    (formGroup, role) => {
      const control = formBuilder.control<boolean | null>(defaultOptedIn, [Validators.required]);
      formGroup.addControl(role, control);
      return formGroup;
    },
    // Need to cast here due to limitations of building up a typed dynamic form group.
    formBuilder.group<any>(
      {},
      {
        validators: [requireCheckboxesToBeCheckedValidator(1)],
      }
    )
  ) as ContactPurposeFormGroup<T>;

  const newContact: InsuredContactFormGroup<T> = formBuilder.group({
    firstName: formBuilder.control('', [Validators.required]),
    lastName: formBuilder.control('', [Validators.required]),
    emailAddress: formBuilder.control('', [Validators.required, validateEmailAddress]),
    phoneNumber: formBuilder.control('', [Validators.required, phoneValidator]),
    contactPurpose: contactPurposeFormGroup,
  });
  insuredContactFormArr.push(newContact);
}

export function removeInsuredContact<T extends ContactPurposeConstants>(
  insuredContactFormArr: FormArray<InsuredContactFormGroup<T>>,
  index: number
) {
  insuredContactFormArr.removeAt(index);
}

function createInsuredContactFormArrValidator<T extends ContactPurposeConstants>(
  contactPurposeRoles: T
) {
  return (formArray: FormArray<InsuredContactFormGroup<T>>) => {
    const contacts = formArray.value;
    if (!contacts) {
      return null;
    }

    const availableContactTypes = new Set(Object.keys(contactPurposeRoles));
    const contactTypesSelected: Set<string> = new Set();

    // Check if each contact purpose has at least one insured contact opted in.
    contacts.forEach((contact) => {
      if (contact.contactPurpose) {
        Object.entries(contact.contactPurpose).forEach(([purpose, optedIn]) => {
          if (optedIn) {
            contactTypesSelected.add(purpose);
          }
        });
      }
    });

    const unselectedOptions = [...availableContactTypes].filter(
      (contactType) => !contactTypesSelected.has(contactType)
    );

    if (unselectedOptions.length > 0) {
      const prettifiedOptionNames = unselectedOptions.map(
        (option) => contactPurposeRoles[option as ContactPurposeKeys<T>]
      );

      return {
        notAllInsuredContactOptionsSelected: {
          validationMessage: `Please provide an insured contact for ${prettifiedOptionNames.join(
            ', '
          )} purposes.`,
        },
      };
    }

    const contactsWithEmailAndPhone = contacts.filter(
      (contact) => !!contact.emailAddress && !!contact.phoneNumber
    );

    const groupedContacts = groupBy(
      contactsWithEmailAndPhone,
      (contact) => `${contact.emailAddress}-${contact.phoneNumber}`
    );
    const hasDuplicateContacts = some(groupedContacts, (group) => group.length > 1);
    if (hasDuplicateContacts) {
      return {
        duplicateContacts: {
          validationMessage:
            'Insured contacts cannot have the same email address and phone number.',
        },
      };
    }

    return null;
  };
}
