import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { Subscription, interval, merge, timer } from 'rxjs';
import { delay, delayWhen, tap, filter, first } from 'rxjs/operators';
import { InformService, InformLevel } from 'app/core/services/inform.service';
import { RewardsService } from 'app/shared/services/rewards.service';
import { STANDARD_DELAY } from 'app/shared/rewards/rewards-toast-helpers';
import { ActionName, RewardRequest } from 'app/shared/rewards/rewards-types';
import { Toast, ToastTemplate } from './toast';
import { BillingToastService, BillingToast } from 'app/shared/services/billing-toast.service';
import { Router, NavigationEnd } from '@angular/router';

// If you change, also change the css animation for toast-container__removing
export const TOAST_REMOVE_DELAY = 600;

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
})
export class ToastsComponent implements OnInit, OnDestroy {
  isDevMode = isDevMode();
  toasts: Toast[] = [];
  sub = new Subscription();
  ToastTemplate = ToastTemplate;
  private minorToasts: Record<string, boolean> = {};

  constructor(
    private rewardsService: RewardsService,
    private router: Router,
    private informService: InformService,
    private billingToastService: BillingToastService
  ) {}

  ngOnInit() {
    const rewardToasts$ = this.rewardsService.getRewards().pipe(
      delayWhen((rewardToast) => interval(rewardToast.display.delay || 1)),
      tap((rewardToast) => this.toasts.push(rewardToast)),
      // Don't auto-hide REDEMPTION toasts
      filter((rewardToast) => rewardToast.display.type !== 'REDEMPTION'),
      delay(STANDARD_DELAY)
    );

    const informToasts$ = this.informService.getInformToasts().pipe(
      tap((informToast) => this.toasts.push(informToast)),
      tap((informToast) => {
        if (informToast.level === InformLevel.minorError) {
          this.minorToasts[informToast.id] = true;
        }
      }),
      // Don't auto-hide button toasts
      filter(
        (informToast) => informToast.forceTimeout || !informToast.button || !!informToast.timeout
      ),
      delayWhen((informToast) => interval(informToast.timeout))
    );
    const billingToasts$ = this.billingToastService.getBillingToasts().pipe(
      tap((billingToast) => this.toasts.push(billingToast)),
      filter((billingToast) => billingToast.forceTimeout || !!billingToast.timeout),
      delayWhen((billingToast) => interval(billingToast.timeout))
    );

    this.sub.add(
      merge(rewardToasts$, informToasts$, billingToasts$)
        .pipe(
          tap((toast) => this.removingToast(toast.id)),
          delay(TOAST_REMOVE_DELAY)
        )
        .subscribe((toast) => this.removeToast(toast.id))
    );

    this.sub.add(
      this.informService
        .getDeleteToasts()
        .subscribe((deleteToast) => this.removeToast(deleteToast.toastId))
    );

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // filter out all minor toasts
        this.toasts = this.toasts.filter(({ id }) => !this.minorToasts[id]);
        this.minorToasts = {};
      }
    });
  }

  isBillingToast(toast: Toast): toast is BillingToast {
    return toast.type === ToastTemplate.BILLING;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  removeToast(toastId: string) {
    this.toasts = this.toasts.filter(({ id }) => id !== toastId);
  }

  removingToast(toastId: string) {
    const index = this.toasts.findIndex(({ id }) => id === toastId);
    if (index > -1) {
      this.toasts[index].removing = true;
    }
  }

  triggerRemoveToast(toast: Toast) {
    this.removingToast(toast.id);
    timer(TOAST_REMOVE_DELAY)
      .pipe(first())
      .subscribe(() => this.removeToast(toast.id));
  }

  // For testing buttons only, toasts will not need to submit actions
  triggerRewardAction(actionName: ActionName, insuredAccountId?: string) {
    const action: RewardRequest = { actionName };
    if (insuredAccountId) {
      action.data = { insuredAccountId };
    }

    this.rewardsService.submitRewardActionForTesting(action);
  }

  triggerInformToast(button: boolean) {
    if (button) {
      this.informService.successToast(
        'General message',
        'This is the title for the toast',
        'Got it'
      );
    } else {
      this.informService.successToast('Standard message from a toast without a button or title');
    }
  }
  // End Testing Buttons
}
