import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap, switchMap, shareReplay } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserApiService } from '../../core/services/user-api-service';
import { UserService } from '../../core/services/user.service';

import { User } from '../models/user';

import * as _ from 'lodash';
import { UserGroupInfo, UserGroupsResponse } from '../consumer/typings';
import { InsuredAccountService } from '../../features/insured-account/services/insured-account.service';
import { ProducerDetailsResponse, GwProducerCodeDetails } from 'app/bop/guidewire/typings';

const DEACTIVATED_USER_STATUSES = ['DEPROVISIONED', 'DEACTIVATED'];

@Component({
  selector: 'app-financial-info',
  templateUrl: 'financial-info.component.html',
})
export class FinancialInfoComponent implements OnInit, OnDestroy {
  constructor(
    private userApiService: UserApiService,
    private userService: UserService,
    private insuredAccountService: InsuredAccountService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}
  private sub: Subscription = new Subscription();

  userGroups: Record<string, UserGroupInfo>;
  producerDetails: ProducerDetailsResponse;
  producerCodeDetails: GwProducerCodeDetails;

  loading = true;

  currentUser: User;

  userData: UserGroupInfo[] = [];

  tipaltiUrlMain: SafeResourceUrl;

  ngOnInit() {
    const user$ = this.userService.getUser().pipe(shareReplay());

    this.sub.add(
      user$
        .pipe(
          switchMap((user) => this.insuredAccountService.getProducerDetails(user.producer)),
          tap((producerDetails) => (this.producerDetails = producerDetails))
        )
        .subscribe()
    );

    this.sub.add(
      user$
        .pipe(
          switchMap((user) => this.insuredAccountService.getProducerCodeDetails(user.producer)),
          tap((producerCodeDetails) => (this.producerCodeDetails = producerCodeDetails))
        )
        .subscribe()
    );

    this.sub.add(this.userService.getUser().subscribe((user) => (this.currentUser = user)));

    this.sub.add(
      this.userApiService.getUserGroups().subscribe((groups) => this.setUserGroups(groups))
    );

    this.userApiService.getFinanceAdminLinkMain().subscribe((response) => {
      if (response) {
        this.loading = false;
        this.tipaltiUrlMain = this.sanitizer.bypassSecurityTrustResourceUrl(response.tipaltiUrl);
      } else {
        this.router.navigate(['/settings']);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getAgencyName() {
    return _.get(this.producerCodeDetails, 'AgencyEntity_HUSA.Name', '');
  }

  getProducerCode() {
    return this.currentUser.producer;
  }

  setUserGroups(groups: UserGroupsResponse) {
    this.userGroups = groups.result.reduce((acc, item) => {
      const login = _.get(item, 'user.login', '') as string;
      if (login) {
        acc[login] = item;
      }
      return acc;
    }, {} as Record<string, UserGroupInfo>);
  }
}
