<div class="{{ cssClass }} form-field">
  <label [for]="inputId">
    {{ labelText }}
    <div
      *ngIf="tooltipText"
      class="more-info-tip tool-tip tool-tip__large"
      attr.data-tooltip="{{ tooltipText }}"
    >
      <span class="icon icon-question"></span>
    </div>
  </label>
  <div class="slider-container">
    <input
      [attr.disabled]="readonly || null"
      class="slider"
      type="range"
      [id]="inputId"
      [min]="min"
      [max]="max"
      [step]="step"
      [formControl]="form.get(nameOfFormControl)"
      [value]="derivedValue()"
      (input)="onValueChange($event.target.value)"
      [readonly]="readonly || null"
      [ngStyle]="{ backgroundImage: inlineStyle }"
    />
    <ng-container *ngIf="isCurrencyValue; else genericValue">
      <div class="slider-output">{{ displayValue() | currency: 'USD':'symbol':'1.0-0' }}</div>
    </ng-container>
    <ng-template #genericValue>
      <div class="slider-output">{{ displayValue() }}</div>
    </ng-template>
  </div>
</div>
