<ng-container>
  <h3 class="question-subsection-header">Catastrophe Coverages</h3>
  <ng-container *ngIf="showWindCoverageOptIn()">
    <div class="helper-card-container">
      <app-form-field-radio
        inputId="{{ prefix }}-windCoverageOptedIn"
        [form]="building"
        [submitted]="submitted"
        questionText="Would you like Wind Coverage?"
        nameOfFormControl="windCoverageOptedIn"
        tooltipText="Wind coverage is an included peril in the BOP policy. Wind coverage can have
        its own deductible, separate from the property (AOP) deductible based on the program guidelines"
      >
      </app-form-field-radio>
      <div class="helper-card" *ngIf="!building.value.windCoverageOptedIn">
        <div class="pill-tag pill-tag__main">Note</div>
        <p>By saying no to Wind Coverage, Wind Exclusion will be added to the policy.</p>
      </div>
    </div>
  </ng-container>
  <div class="helper-card-container" *ngIf="building.value.windCoverageOptedIn">
    <div [ngClass]="{ 'form-subsection': showWindCoverageOptIn() }">
      <app-form-field-select-api
        inputId="{{ prefix }}-windOptions"
        [form]="building"
        [submitted]="submitted"
        labelText="Wind Deductible"
        nameOfFormControl="windDeductiblePercent"
        cssClass="form-field__no-margin"
        [status]="this.windOptionsStatus"
        [options]="this.windOptions"
        [currency]="false"
      >
      </app-form-field-select-api>
    </div>
    <div class="helper-card helper-card__no-margin" *ngIf="building.value.windCoverageOptedIn">
      <div class="pill-tag pill-tag__main">Note</div>
      <p>By selecting Not Applicable, the AOP deductible will apply to the wind peril.</p>
      <p>
        For Accredited BOP, Wind Coverage is required for
        {{ requiredV2WindCoverageStates.join(', ') }}
      </p>
      <p>
        For Blackboard BOP, Wind Coverage is required for
        {{ requiredV1WindCoverageStates.join(', ') }}
      </p>
    </div>
  </div>

  <ng-container *ngIf="defaultWindLossMitigationAnswers()">
    <app-form-field-radio
      inputId="{{ prefix }}-windLossMitigationPresent"
      [form]="building"
      [submitted]="submitted"
      nameOfFormControl="windLossMitigationPresent"
      [questionText]="windLossMitigationPresentText"
    >
    </app-form-field-radio>

    <div class="form-subsection" *ngIf="building.value.windLossMitigationPresent">
      <app-form-field-radio
        inputId="{{ prefix }}-SCBCCompliant"
        [form]="windLossMitigation"
        [submitted]="submitted"
        nameOfFormControl="SCBCCompliant"
        questionText="Is the Building Code SCBC Compliant?"
      >
      </app-form-field-radio>

      <app-form-field-select
        inputId="{{ prefix }}-windLossMitigationLevel"
        [form]="windLossMitigation"
        [submitted]="submitted"
        labelText="Level"
        nameOfFormControl="level"
        [availableOptions]="wlmQuestions.level"
      >
      </app-form-field-select>

      <app-form-field-select
        inputId="{{ prefix }}-windLossMitigationRoofToWallConnection"
        [form]="windLossMitigation"
        [submitted]="submitted"
        labelText="Roof To Wall Connection"
        nameOfFormControl="roofToWallConnection"
        [availableOptions]="wlmQuestions.roofToWallConnection"
      >
      </app-form-field-select>

      <app-form-field-select
        inputId="{{ prefix }}-windLossMitigationOpeningProtection"
        [form]="windLossMitigation"
        [submitted]="submitted"
        labelText="Opening Protection"
        nameOfFormControl="openingProtection"
        [availableOptions]="wlmQuestions.openingProtection"
      >
      </app-form-field-select>

      <app-form-field-select
        inputId="{{ prefix }}-windLossMitigationDoorStrength"
        [form]="windLossMitigation"
        [submitted]="submitted"
        labelText="Door Strength"
        nameOfFormControl="doorStrength"
        [availableOptions]="wlmQuestions.doorStrength"
      >
      </app-form-field-select>

      <app-form-field-radio
        inputId="{{ prefix }}-windLossMitigationSecondaryWaterResistance"
        [form]="windLossMitigation"
        [submitted]="submitted"
        nameOfFormControl="secondaryWaterResistance"
        questionText="Does the building include Secondary Water Resistance?"
      >
      </app-form-field-radio>

      <app-form-field-select
        inputId="{{ prefix }}-windLossMitigationRoofShape"
        [form]="windLossMitigation"
        [submitted]="submitted"
        labelText="Roof Shape"
        nameOfFormControl="roofShape"
        [availableOptions]="wlmQuestions.roofShape"
      >
      </app-form-field-select>

      <ng-container *ngIf="showWindLossMitigationType2()">
        <app-form-field-select
          inputId="{{ prefix }}-windLossMitigationRoofAndDeckAttachment"
          [form]="windLossMitigation"
          [submitted]="submitted"
          labelText="Roof Deck and Roof Deck Attachment"
          nameOfFormControl="roofAndDeckAttachment"
          [availableOptions]="wlmQuestions.roofAndDeckAttachment"
        >
        </app-form-field-select>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
