<header class="bind-quote-header">
  <h1>Payment Plan</h1>
</header>

<form [formGroup]="formService.form" (ngSubmit)="handleSubmit($event)" novalidate>
  <main *ngIf="loadingAccountDetails" class="placeholder-form-container">
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-submit"></div>
  </main>

  <div *ngIf="!loadingAccountDetails" class="bind-quote-main">
    <div
      class="bind-flow-subsection js-lm-bind-insured-details"
      *ngIf="insuredAccount | async as insuredAccount"
    >
      <h3 class="question-subsection-header">Insured details</h3>
      <dl>
        <dt>Legal business name</dt>
        <dd>{{ insuredAccount.companyName }}</dd>
      </dl>
      <dl>
        <dt>Mailing address</dt>
        <dd>
          {{ insuredAccount.addressLine1 }},{{ insuredAccount.addressLine2 }}
          {{ insuredAccount.city }},
          {{ insuredAccount.state }}
          {{ insuredAccount.zip }}
        </dd>
      </dl>

      <app-form-field-text
        inputId="bind-emailAddress"
        [form]="formService.form.get('paymentPlan')"
        [submitted]="submitted()"
        labelText="Insured email"
        subLabelText="Electronic notifications will be sent to this policy holder."
        [value]="insuredAccount.emailAddress"
        nameOfFormControl="policyHolderEmail"
        appRemoveWhitespaceMask
      >
      </app-form-field-text>
    </div>

    <!-- Payment Options-->
    <ng-container *ngIf="quote | async as loadedQuoteDetails">
      <ng-container>
        <app-form-field-radio
          inputId="lm-bind-numberOfPayments"
          [form]="formService.form.get('paymentPlan')"
          [submitted]="submitted()"
          questionText="Payment plan"
          [options]="paymentPlanOptions"
          nameOfFormControl="numberOfPayments"
        >
        </app-form-field-radio>
      </ng-container>

      <!-- Full Payment -->
      <div *ngIf="_numberOfPayments === 'FL'">
        <h5 class="payment-subtitle">Total</h5>
        <dl class="dl__no-dotted-line">
          <dt>Premium</dt>
          <dd class="js-lm-payment-details-premium">
            {{ getPaymentPlanDetails(loadedQuoteDetails, 'FL').DepositAmt.Amt | currency }}
          </dd>
        </dl>
      </div>

      <!-- Quarterly, Monthly, and 10 Payments -->
      <div *ngIf="_numberOfPayments !== 'FL'">
        <h5 class="payment-subtitle">Down payment</h5>
        <dl class="dl__no-dotted-line">
          <dt>Premium</dt>
          <dd class="js-lm-payment-details-downPayment">
            {{
              getPaymentPlanDetails(loadedQuoteDetails, _numberOfPayments).DepositAmt.Amt | currency
            }}
          </dd>
        </dl>

        <dl class="dl__subtotal dl__no-dotted-line">
          <dt>Subtotal</dt>
          <dd>
            <strong class="js-lm-payment-details-downPayment-subtotal">{{
              getPaymentPlanDetails(loadedQuoteDetails, _numberOfPayments).DepositAmt.Amt | currency
            }}</strong>
          </dd>
        </dl>

        <h5 class="payment-subtitle">
          Installments x {{ calculateInstallments(_numberOfPayments) }}
        </h5>

        <dl class="dl__no-dotted-line">
          <dt>Installment Premium</dt>
          <dd class="js-lm-payment-details-installment">
            {{ calculateInstallmentAmount(loadedQuoteDetails, _numberOfPayments) | currency }}
          </dd>
        </dl>

        <dl class="dl__subtotal dl__no-dotted-line">
          <dt>Subtotal</dt>
          <dd>
            <strong class="js-lm-payment-details-installment-subtotal">{{
              calculateSubtotal(loadedQuoteDetails, _numberOfPayments) | currency
            }}</strong>
          </dd>
        </dl>
      </div>

      <!-- Total -->
      <div class="bind-quote-total">
        <div class="bind-quote-total-lineitem">
          <strong>Estimated total cost*</strong>
          <div class="big-total js-liberty-mutual-payment-details-total">
            <span>$</span>
            {{ calculateEstimatedTotalIntegralAmount(loadedQuoteDetails) | number: '2.0-0' }}.
            <span class="big-total-cents">{{
              calculatEstimatedTotalFractionalAmount(loadedQuoteDetails) | number: '2.0-0'
            }}</span>
          </div>
        </div>
        <!-- Maybe need to change this wrt taxes/surcharges -->
        <small *ngIf="hasStateSurcharges(loadedQuoteDetails)" class="gray-text"
          >* Includes {{ loadedQuoteDetails.details.taxAmt.Amt | currency }}/year of state required
          surcharges.</small
        >
      </div>
    </ng-container>
  </div>
</form>
