import { Injectable } from '@angular/core';

const ID = 'webWidget';

const getWindow = () => window as ZendeskWindow;

@Injectable()
export class ZendeskWidgetService {
  private window: ZendeskWindow;

  constructor() {
    this.window = getWindow();
  }

  public activate() {
    this.window.zE(() => {
      this.window.zE.activate();
    });
  }

  public addChatTags(tags: string[], persist = false) {
    this.window.zE(ID, 'chat:addTags', tags);
    if (!persist) {
      this.window.zE(`${ID}:on`, 'chat:end', this.removeChatTags.bind(this, tags));
    }
  }

  public close() {
    this.window.zE(ID, 'close');
  }

  public getChatDepartments(): ZendeskChatDepartment[] {
    return this.window.zE(`${ID}:get`, 'chat:departments') || [];
  }

  public hide() {
    this.window.zE(() => {
      this.window.zE.hide();
    });
  }

  public identify(userObj: ZendeskIdentity) {
    this.window.zE(() => {
      this.window.zE.identify(userObj);
    });
  }

  public logout() {
    this.window.zE(() => {
      this.window.zE.logout();
    });
  }

  public onChatEnd(callback: Function) {
    this.window.zE(`${ID}:on`, 'chat:end', callback);
  }

  public onChatStart(callback: Function) {
    this.window.zE(`${ID}:on`, 'chat:start', callback);
  }

  public open() {
    this.window.zE(ID, 'open');
  }

  public prefill(prefill: ZendeskPrefill) {
    this.window.zE(ID, 'prefill', prefill);
  }

  public removeChatTags(tags: string[]) {
    this.window.zE(ID, 'chat:removeTags', tags);
  }

  public sendChat(message: string) {
    this.window.zE(ID, 'chat:send', message);
  }

  public setLocale(locale: string) {
    this.window.zE(() => {
      this.window.zE.setLocale(locale);
    });
  }

  public show() {
    this.window.zE(() => {
      this.window.zE.show();
    });
  }

  public toggle() {
    this.window.zE(ID, 'toggle');
  }

  public updateSettings(settings: ZendeskSettings) {
    this.window.zE(ID, 'updateSettings', settings);
  }
}

export const attuneTheme: Theme = {
  theme: '#00baba',
};
export const defaultTheme: Theme = {
  theme: '#d86783',
};

export interface ZendeskWindow {
  zE?: any;
  zEmbed?: Function;
  zESettings?: ZendeskSettings;
}

export interface ZendeskSettings {
  webWidget?: {
    authenticate?: any;
    chat?: {
      authenticate?: any;
      badge?: {
        label?: ZendeskLocalString;
        image?: ImageUrl;
        layout?: 'image_right' | 'image_left' | 'image_only' | 'text_only';
      };
      concierge?: {
        avatarPath?: ImageUrl;
        name?: string;
        title?: ZendeskLocalString;
      };
      departments?: {
        select?: string;
        enabled?: string[];
      };
      hideWhenOffline?: Boolean;
      navigation?: {
        popoutButton?: Boolean;
      };
      notifications?: {
        mobile: {
          disable: Boolean;
        };
      };
      offlineForm?: {
        greeting?: ZendeskLocalString;
      };
      prechatForm?: {
        greeting?: ZendeskLocalString;
        departmentLabel?: ZendeskLocalString;
      };
      suppress?: Boolean;
      tags?: string[];
      title?: ZendeskLocalString;
    };
    color?: Theme;
    contactForm?: {
      attachments?: Boolean;
      fields?: { [key: string]: ZendeskLocalString };
      selectTicketForm?: ZendeskLocalString;
      subject?: Boolean;
      suppress?: Boolean;
      ticketForms?: any;
      title?: ZendeskLocalString;
    };
    contactOptions?: {
      enabled?: Boolean;
      contactButton?: ZendeskLocalString;
      contactFormLabel?: ZendeskLocalString;
      chatLabelOnline?: ZendeskLocalString;
      chatLabelOffline?: ZendeskLocalString;
    };
    helpCenter?: {
      chatButton?: ZendeskLocalString;
      filter?: any;
      messageButton?: ZendeskLocalString;
      originalArticleButton?: Boolean;
      searchPlaceholder?: ZendeskLocalString;
      suppress?: Boolean;
      title?: ZendeskLocalString;
    };
    offset?: {
      horizontal?: string;
      vertical?: string;
      mobile?: {
        horizontal?: string;
        vertical?: string;
      };
    };
    position?: {
      horizontal?: 'right' | 'left';
      vertical?: 'bottom' | 'top';
    };
    talk?: any;
    zIndex?: number;
  };
}

export interface Theme {
  theme?: HexColor;
  launcher?: HexColor;
  launcherText?: HexColor;
  button?: HexColor;
  resultLists?: HexColor;
  header?: HexColor;
  articleLinks?: HexColor;
}

interface ZendeskLocalString {
  '*': string;
}

type HexColor = string;
type ImageUrl = string;

interface ZendeskIdentity {
  name: string;
  email: string;
  organization?: string;
}

interface ZendeskPrefill {
  name?: PrefillValue;
  email?: PrefillValue;
  phone?: PrefillValue;
}

interface PrefillValue {
  value: string;
  readOnly?: Boolean;
}

interface ZendeskChatDepartment {
  id: number;
  name: string;
  status: 'online' | 'away' | 'offline';
}
