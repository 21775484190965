<section class="testing-buttons" *ngIf="isDevMode">
  <details>
    <summary>Test Toasts & Rewards</summary>
    <button class="button button__small" (click)="triggerRewardAction('QUOTE_FOR_ACCOUNT')">
      quote + achievement
    </button>
    <button class="button button__small" (click)="triggerRewardAction('BOP_BIND_FOR_ACCOUNT')">
      bind only
    </button>
    <button class="button button__small" (click)="triggerRewardAction('GIFT_CARD_REDEMPTION')">
      reward redemption
    </button>
    <button class="button button__small" (click)="triggerInformToast(false)">
      Informational Toast
    </button>
    <button class="button button__small" (click)="triggerInformToast(true)">
      Info Toast with Button
    </button>
  </details>
</section>
<section *ngIf="toasts.length" class="toasts-container" aria-label="rewards notifications">
  <ng-container *ngFor="let toast of toasts">
    <ng-container [ngSwitch]="toast.type">
      <app-rewards-toast
        *ngSwitchCase="ToastTemplate.REWARD"
        class="toast-container"
        [ngClass]="{ 'toast-container__removing': toast.removing }"
        [toast]="toast"
        (destroy)="triggerRemoveToast($event)"
      ></app-rewards-toast>
      <app-inform-toast
        *ngSwitchCase="ToastTemplate.INFORM"
        class="toast-container"
        [ngClass]="{ 'toast-container__removing': toast.removing }"
        [toast]="toast"
        (destroy)="triggerRemoveToast($event)"
      ></app-inform-toast>
      <app-billing-toast
        *ngSwitchCase="ToastTemplate.BILLING"
        class="toast-container"
        [ngClass]="{ 'toast-container__removing': toast.removing }"
        [toast]="toast"
        (destroy)="triggerRemoveToast($event)"
      ></app-billing-toast>
    </ng-container>
  </ng-container>
</section>
