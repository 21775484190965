import { OnInit, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDslSteppedFormBaseService } from '../form-dsl/services/form-dsl-stepped-form-base.service';

@Component({
  template: '',
})
export abstract class SteppedFormPageComponent<
  FormServiceType extends FormDslSteppedFormBaseService,
  FormType extends FormGroup
> implements OnInit
{
  form: FormType;
  formPath: string;

  constructor(@Inject(FormDslSteppedFormBaseService) protected formService: FormServiceType) {}

  ngOnInit() {
    this.form = this.formService.form.get(this.formPath) as FormType;
    if (!this.form) {
      throw new Error(`formPath (${this.formPath}) not found`);
    }
  }

  submitted() {
    return this.formService.submitted;
  }

  fieldInvalid(path: string) {
    const formField = this.form.get(path);
    if (formField) {
      return this.submitted() && formField.invalid;
    }
  }
}
