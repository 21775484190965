import { industryColorMap, stateRecommendationMap } from '../models/constants';
import { AppetiteProductEligibility, Color, State, TopClassCode } from '../models/types';

export const productFilter = (
  product: AppetiteProductEligibility,
  state: State,
  topClassCode: TopClassCode,
  attuneWcEnabledStates: string[] = []
) => {
  const isBop =
    product.productCombination.pasSource === 'attune_gw' &&
    product.productCombination.product === 'bop';
  const isWc =
    product.productCombination.pasSource === 'employers' &&
    product.productCombination.product === 'wc';
  if (isBop) {
    if (!state) {
      return true;
    }
    const stateColor = effectiveStateColor(state);
    if (stateColor === 'red') {
      return false;
    }
    const { excludedIndustries } = stateRecommendationMap[state];
    if (excludedIndustries.includes(topClassCode.category)) {
      return false;
    }
    const { colorOverride } = topClassCode;
    const industryColor =
      colorOverride !== 'n/a' ? colorOverride : industryColorMap[topClassCode.category];
    if (stateColor === 'yellow') {
      return industryColor === 'green';
    }
    if (stateColor === 'green') {
      return industryColor !== 'red';
    }
    return true;
  }
  // Hide Employers WC products in Top Class Codes list of Appetite Checker for states with Attune WC enabled.
  if (isWc) {
    return !state || !attuneWcEnabledStates.includes(state);
  }
  return true;
};

// States with non-industry exclusions (e.g. Tier 1) are downgraded by one color position
const effectiveStateColor = (state: State): Color => {
  const { color, hasNonIndustryExclusions } = stateRecommendationMap[state];
  if (!hasNonIndustryExclusions) {
    return color;
  }
  if (color === 'green') {
    return 'yellow';
  }
  return 'red';
};
