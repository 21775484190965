<div *ngIf="!loading && !error" class="cyber-risk-profile-container">
  <div class="header-logo">
    <div>
      <img
        class="logo header-url-logo"
        alt="Attune Logo"
        src="assets/img/attune-logo-a-coalition-company.png"
      />
    </div>
    <div class="header-url">
      <p class="header-url-text">attuneinsurance.com</p>
    </div>
  </div>
  <table class="risk-profile-formatting-table">
    <tr class="hidden-row">
      <td class="first-column"></td>
      <td class="second-column"></td>
      <td class="third-column"></td>
    </tr>
    <tr>
      <td class="align-top pr-0" rowspan="2">
        <div class="table-header-cell">
          <div>
            <h1 class="risk-profile-header">
              Cyber <span class="highlight-orange">Risk</span> Profile
            </h1>
          </div>
          <div class="mt-0-25">
            <h2 class="prepared-for-header">Prepared for:</h2>
          </div>
          <div class="mt-0-25">
            <h2 class="account-name-header">{{ accountName }}</h2>
          </div>
          <div class="mt-0-5">
            <h3 class="risk-description-header">
              No business is too small for a cyber attack -
              <br />
              not even yours
            </h3>
          </div>
          <div class="mt-0-25">
            <p class="risk-description-body">
              It’s easy to assume that your small business isn’t a target for a cyber attack.
              Unfortunately, small businesses are more likely to experience a cyber incident than
              many realize - and the impact of an incident can be catastrophic. To give you a sense
              of how a cyber incident could impact your organization, we’ve calculated the expected
              severity of a cyber loss your business could face:
            </p>
          </div>
        </div>
      </td>
      <td class="align-right" colspan="2">
        <img height="215" width="261" src="assets/img/risk-profile.png" alt="" />
      </td>
    </tr>
    <tr class="middle-row">
      <td></td>
      <td class="align-bottom" rowspan="2">
        <div class="bg-light-grey common-cyber-incidents">
          <h3 class="table-sub-headers">
            Most Common
            <br />
            Cyber Incidents
          </h3>
          <div class="common-cyber-incident-row">
            <div class="common-cyber-incident-bar" style="width: {{ ransomwareIncident }}%"></div>
            <span class="common-cyber-incident-percentage">{{ ransomwareIncident }}%</span>
          </div>
          <div>
            <span class="common-cyber-incident-type">Ransomware</span>
          </div>
          <div class="common-cyber-incident-row">
            <div class="common-cyber-incident-bar" style="width: {{ ftfIncident }}%"></div>
            <span class="common-cyber-incident-percentage">{{ ftfIncident }}%</span>
          </div>
          <div>
            <span class="common-cyber-incident-type">Funds Transfer Fraud</span>
          </div>
          <div class="common-cyber-incident-row">
            <div class="common-cyber-incident-bar" style="width: {{ breachIncident }}%"></div>
            <span class="common-cyber-incident-percentage">{{ breachIncident }}%</span>
          </div>
          <div>
            <span class="common-cyber-incident-type">Data Breach</span>
          </div>
          <div class="common-cyber-incident-row">
            <div class="common-cyber-incident-bar" style="width: {{ otherIncident }}%"></div>
            <span class="common-cyber-incident-percentage">{{ otherIncident }}%</span>
          </div>
          <div>
            <span class="common-cyber-incident-type">Other</span>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <div class="bg-light-grey estimated-loss-table-cell">
          <h3 class="table-sub-headers">
            Estimated loss based on your organization's risk profile
          </h3>
          <table class="estimated-loss-table">
            <thead>
              <tr>
                <th>Type of loss</th>
                <th>Median</th>
                <th>1 in 10 years</th>
                <th>1 in 100 years</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Overall</td>
                <td>${{ overallLoss.median_loss | number }}</td>
                <td>${{ overallLoss['1_10_loss'] | number }}</td>
                <td>${{ overallLoss['1_100_loss'] | number }}</td>
              </tr>
              <tr>
                <td>Ransomware</td>
                <td>${{ ransomwareLoss.median_loss | number }}</td>
                <td>${{ ransomwareLoss['1_10_loss'] | number }}</td>
                <td>${{ ransomwareLoss['1_100_loss'] | number }}</td>
              </tr>
              <tr>
                <td>Funds Transfer Fraud</td>
                <td>${{ ftfLoss.median_loss | number }}</td>
                <td>${{ ftfLoss['1_10_loss'] | number }}</td>
                <td>${{ ftfLoss['1_100_loss'] | number }}</td>
              </tr>
              <tr>
                <td>Data Breach</td>
                <td>${{ breachLoss.median_loss | number }}</td>
                <td>${{ breachLoss['1_10_loss'] | number }}</td>
                <td>${{ breachLoss['1_100_loss'] | number }}</td>
              </tr>
            </tbody>
          </table>
          <p class="footnote-text">
            These estimates are calculated using your organization’s unique demographic data,
            Coalition claims data, and a statistical model derived from 10,000 simulated years of
            cyber incidents.
            <sup>1</sup>
          </p>
        </div>
      </td>
    </tr>
  </table>
  <br />
  <div class="bg-light-blue small-business-cyber-threats">
    <h2>Small businesses are uniquely vulnerable to cyber threats</h2>
    <ul>
      <li>
        Small businesses are especially vulnerable to threat actors as they often lack the expertise
        and resources to quickly respond to an attack.
      </li>
      <li>
        Small businesses receive the highest rate of targeted malicious emails of all business
        segments.
        <br />
        <span class="small-business-cyber-threats-bold">1 in 323 emails contains a bad link</span>
        that could expose your organization to an incident.
        <sup>2</sup>
      </li>
      <li>
        <span class="small-business-cyber-threats-bold">
          87% of small businesses have customer data that could be compromised in an attack.
        </span>
        Surprised? Don’t be - sensitive data is gathered when you take credit card payments or
        gather client addresses, telephone numbers, and names.
        <sup>3</sup>
      </li>
    </ul>
  </div>
  <p class="footnote-text">
    (1) Data is from multiple sources, including Coalition’s own data. Actual numbers may vary
    significantly from calculator estimates based on additional factors for a given business. The
    data provided is for informational and educational purposes only. Use of the Coalition Coverage
    Calculator should not be used as a replacement for a company’s own due diligence in regards to
    their cyber risk. Access and use of the Coalition Coverage Calculator is predicated upon the
    acceptance of Coalition, Inc. Terms of Service.
    <br />
    (2) Symantec Security Center
    <br />
    (3) Digital.com
  </p>
  <div class="cyber-risk-profile-footer">
    <p class="p__bold cyber-risk-profile-footer-title">
      Attune Insurance Services, LLC • 60 Broad Street, 24th Floor #2502, New York, NY 10004
    </p>
    <p class="cyber-risk-profile-footer-body">
      Is an insurance producer licensed in all states and the District of Columbia. Visit
      www.attuneinsurance.com for full corporate, licensing, and legal information.
      <br />
      Copyright © {{ getCurrentYear() }} Attune Insurance Services, LLC. All rights reserved.
    </p>
  </div>
</div>
