import { LibertyMutualClassCode } from './common-typings';

export const LM_BOP_CLASSES: LibertyMutualClassCode[] = [
  {
    name: 'Antique Stores',
    code: '88546',
  },
  {
    name: 'Appliance Store – Household',
    code: '80011',
  },
  {
    name: 'Appliance and Accessories Stores – Sales, Installation, Service or Repair – Commercial or Household',
    code: '83041',
  },
  {
    name: 'Appliances - Commercial or Household - Excluding TV, Stereo, Video, Computer or Data Processing Equipment - Wholesale',
    code: '85011',
  },
  {
    name: 'Appliances - Commercial or Household - Wholesale',
    code: '85601',
  },
  {
    name: 'Aquariums and Fish - Retail',
    code: '86521',
  },
  {
    name: 'Aquariums and Fish - Wholesale',
    code: '86531',
  },
  {
    name: 'Art Galleries - Retail',
    code: '88548',
  },
  {
    name: 'Artificial Flowers/Plants - Retail',
    code: '86031',
  },
  {
    name: 'Artificial Flowers/Plants - Wholesale',
    code: '88549',
  },
  {
    name: 'Arts and Craft Supplies - Retail',
    code: '86021',
  },
  {
    name: 'Arts and Crafts Supplies - Wholesale',
    code: '86541',
  },
  {
    name: 'Athletic Footwear Stores',
    code: '89131',
  },
  {
    name: 'Auto Parts and Accessories - Franchise - Retail',
    code: '85911',
  },
  {
    name: 'Automobile Accessories - No Repairs, Services or Recapping - Retail',
    code: '80021',
  },
  {
    name: 'Automobile Accessories - No Repairs, Services or Recapping - Wholesale',
    code: '85021',
  },
  {
    name: 'Bagel Shops',
    code: '88701',
  },
  {
    name: 'Baked Goods - Baking on Premises - Wholesale',
    code: '85521',
  },
  {
    name: 'Baked Goods - No Baking on Premises - Wholesale',
    code: '85621',
  },
  {
    name: 'Bakeries - Baking on Premises - Retail',
    code: '88551',
  },
  {
    name: 'Bakeries - No Baking on Premises - Retail',
    code: '88552',
  },
  {
    name: 'Barber Shop',
    code: '81011',
  },
  {
    name: 'Barber or Beauty Supplies - No Mixing, Blending or Manufacturing - Retail',
    code: '80701',
  },
  {
    name: 'Barber or Beauty Supplies - No Mixing, Blending or Manufacturing - Wholesale',
    code: '85701',
  },
  {
    name: 'Battery Stores',
    code: '88553',
  },
  {
    name: 'Beauty Parlor and Hair Styling Salons',
    code: '81021',
  },
  {
    name: 'Bed or Bath Accessories - No Mixing, Blending or Manufacturing - Retail',
    code: '83161',
  },
  {
    name: 'Bed or Bath Accessories - No Mixing, Blending or Manufacturing - Wholesale',
    code: '85861',
  },
  {
    name: 'Beer or Wine Making Supply Stores',
    code: '88554',
  },
  {
    name: 'Beverage Distributor (Including Beer and Wine) - No other alcoholic beverages',
    code: '88555',
  },
  {
    name: 'Beverage Distributor - No Alcoholic Beverages',
    code: '88556',
  },
  {
    name: 'Beverage Stores - No Alcoholic Beverages',
    code: '88557',
  },
  {
    name: 'Birdfeeders - Retail',
    code: '86561',
  },
  {
    name: 'Birdfeeders - Wholesale',
    code: '86571',
  },
  {
    name: 'Blankets and Bedspreads - Retail',
    code: '83171',
  },
  {
    name: 'Blankets and Bedspreads - Wholesale',
    code: '86581',
  },
  {
    name: 'Bldg Materials Equip/Supplies (Less 15% Lumber Sales) Retail',
    code: '86041',
  },
  {
    name: 'Boat Supply Stores - Pleasure Craft',
    code: '88558',
  },
  {
    name: 'Bookbinding',
    code: '89132',
  },
  {
    name: 'Bookbinding and Printing Supplies',
    code: '88559',
  },
  {
    name: 'Books, Magazines, Stationary, Cards, or Paper Products - Retail',
    code: '80031',
  },
  {
    name: 'Books/Magazines/Stationary/Cards/Paper Products -Wholesale',
    code: '85031',
  },
  {
    name: 'Bottled Water Dealers and Distributors',
    code: '89133',
  },
  {
    name: 'Bridal Shop',
    code: '86071',
  },
  {
    name: 'Building Materials Equipment and Supplies (Less Than 15% Lumber Sales) - Wholesale',
    code: '85871',
  },
  {
    name: 'Camera and Photographic Equipment - Wholesale',
    code: '85191',
  },
  {
    name: 'Camera and Photographic Equipment Stores - Retail',
    code: '80191',
  },
  {
    name: 'Camera and Video Equipment Repair',
    code: '88561',
  },
  {
    name: 'Candle Stores - Retail',
    code: '86051',
  },
  {
    name: 'Candy, Nut or Confectionery Stores',
    code: '80201',
  },
  {
    name: 'Carpet or Rug Stores - Excluding Rare or High Valued Rugs',
    code: '88563',
  },
  {
    name: 'Carpet, Rug and Upholstery Cleaning - Shop Only',
    code: '89134',
  },
  {
    name: 'Casket/Coffin Sales',
    code: '88564',
  },
  {
    name: 'Catalog or Premium Coupon Redemption Stores - Retail',
    code: '80421',
  },
  {
    name: 'Cheese Distributor',
    code: '88565',
  },
  {
    name: 'China Shop - Retail',
    code: '86061',
  },
  {
    name: 'Clocks - Retail',
    code: '83181',
  },
  {
    name: 'Clocks - Wholesale',
    code: '86621',
  },
  {
    name: 'Clothing: All Other, Excluding Furs – Retail',
    code: '86111',
  },
  {
    name: 'Clothing: All Other, Excluding Furs – Wholesale',
    code: '85641',
  },
  {
    name: "Clothing: Children's and Infants' Apparel - Retail",
    code: '80211',
  },
  {
    name: 'Clothing: Children’s Sports Apparel – Retail',
    code: '86591',
  },
  {
    name: 'Clothing: Children’s Sports Apparel – Wholesale',
    code: '86611',
  },
  {
    name: 'Clothing: Dance, Gymnastic, and Figure Skating Apparel Stores',
    code: '89135',
  },
  {
    name: 'Clothing: Family Apparel',
    code: '88584',
  },
  {
    name: "Clothing: Ladies' Sports Apparel - Retail",
    code: '86941',
  },
  {
    name: "Clothing: Ladies' Sports Apparel - Wholesale",
    code: '86951',
  },
  {
    name: "Clothing: Ladies' and Girls', Excluding Furs - Retail",
    code: '83211',
  },
  {
    name: "Clothing: Ladies' and Girls', Excluding Furs - Wholesale",
    code: '85251',
  },
  {
    name: "Clothing: Ladies' and Girls', Including Furs - Retail",
    code: '83201',
  },
  {
    name: 'Clothing: Lingerie Stores',
    code: '88606',
  },
  {
    name: 'Clothing: Maternity Shops',
    code: '89136',
  },
  {
    name: "Clothing: Men's and Boys' - Retail",
    code: '83191',
  },
  {
    name: 'Clothing: Men’s Sports Apparel - Retail',
    code: '86791',
  },
  {
    name: 'Clothing: Men’s Sports Apparel – Wholesale',
    code: '86811',
  },
  {
    name: 'Clothing: Sportswear – Retail',
    code: '86081',
  },
  {
    name: 'Clothing: Sportswear – Wholesale',
    code: '86631',
  },
  {
    name: 'Clothing: T-Shirts – Retail',
    code: '86891',
  },
  {
    name: 'Clothing: T-Shirts – Wholesale',
    code: '86911',
  },
  {
    name: 'Clothing: Uniforms - Wholesale',
    code: '86931',
  },
  {
    name: 'Clothing: Uniforms – Retail',
    code: '86921',
  },
  {
    name: 'Clothing: Western Riding Apparel – Retail',
    code: '88644',
  },
  {
    name: 'Clothing: Western Riding Apparel – Wholesale',
    code: '88643',
  },
  {
    name: 'Coffee or Espresso House',
    code: '88567',
  },
  {
    name: 'Coffee, Tea and Spice Distributors',
    code: '88568',
  },
  {
    name: 'Coffee, Tea and Spice Specialty Store',
    code: '83751',
  },
  {
    name: 'Computer Service and Repair - On Premises',
    code: '89137',
  },
  {
    name: 'Computer Stores - Including Software and Accessories',
    code: '80571',
  },
  {
    name: 'Computer Stores - Including Software and Accessories - Wholesale',
    code: '85571',
  },
  {
    name: 'Condominium – Commercial Mercantile',
    code: '80891',
  },
  {
    name: 'Condominium – Commercial Office',
    code: '82121',
  },
  {
    name: 'Cookies - Wholesale',
    code: '86651',
  },
  {
    name: 'Cookies – Retail',
    code: '86641',
  },
  {
    name: 'Cosmetic, Hair or Skin Preparation Distributors',
    code: '88572',
  },
  {
    name: 'Cosmetics, Toiletries and Perfumes – No Mixing, Blending or Manufacturing – Retail',
    code: '83221',
  },
  {
    name: 'Dairy Products - Retail',
    code: '80881',
  },
  {
    name: 'Day Spas',
    code: '88573',
  },
  {
    name: 'Delicatessens - With Incidental Commercial Cooking (e.g., frying, grilling, etc.)',
    code: '89138',
  },
  {
    name: 'Delicatessens – No Commercial Cooking (e.g., frying, grilling, etc.)',
    code: '88574',
  },
  {
    name: 'Dental Laboratories',
    code: '81111',
  },
  {
    name: 'Department Stores',
    code: '80331',
  },
  {
    name: 'Dinette Store - Household - No Antiques - Retail',
    code: '86211',
  },
  {
    name: 'Dollar Store - Retail',
    code: '86311',
  },
  {
    name: 'Donut Shops',
    code: '88577',
  },
  {
    name: 'Draftsmen',
    code: '81231',
  },
  {
    name: 'Dressmaking Shops',
    code: '81121',
  },
  {
    name: 'Drug Stores',
    code: '81331',
  },
  {
    name: 'Dry Cleaners - Pick-up Station - Retail',
    code: '88662',
  },
  {
    name: 'Dry Cleaners - with Cleaning Plant - Retail',
    code: '88661',
  },
  {
    name: 'Dry Goods - Linens, Curtains or Draperies - Retail',
    code: '80561',
  },
  {
    name: 'Dry Goods - Linens, Curtains or Draperies - Wholesale',
    code: '85661',
  },
  {
    name: 'Dry Goods Dealers - Including Fabrics/Yarn/Piece Goods - Retail',
    code: '80051',
  },
  {
    name: 'Dry Goods Dealers - Including Fabrics/Yarn/Piece Goods - Wholesale',
    code: '85071',
  },
  {
    name: 'Edible Bouquet Stores (Baked Goods, Fresh Fruits or Vegetables)',
    code: '88593',
  },
  {
    name: 'Electrical Fixtures - Retail',
    code: '83671',
  },
  {
    name: 'Electrical Fixtures - Wholesale',
    code: '85671',
  },
  {
    name: 'Electrical Lighting Fixtures and Fans - Retail',
    code: '86331',
  },
  {
    name: 'Electrical Lighting Fixtures and Fans - Wholesale',
    code: '86341',
  },
  {
    name: 'Electrical Supplies - Retail',
    code: '86661',
  },
  {
    name: 'Electrical Supplies - Wholesale',
    code: '86671',
  },
  {
    name: 'Engraving - Retail',
    code: '81131',
  },
  {
    name: 'Equipment, Fixtures and Supplies For Bars, Hotels, Offices, Restaurants or Stores - No Restaurant Installation - Retail',
    code: '80061',
  },
  {
    name: 'Equipment, Fixtures and Supplies For Bars, Hotels, Offices, Restaurants or Stores - No Restaurant Installation - Wholesale',
    code: '85061',
  },
  {
    name: 'Fabric Stores',
    code: '80071',
  },
  {
    name: 'Fence Dealers - Retail',
    code: '88585',
  },
  {
    name: 'Fence Dealers - Wholesale',
    code: '88586',
  },
  {
    name: 'Film Developing or Photofinishing Stores',
    code: '83151',
  },
  {
    name: 'Fishing Equipment and Supplies - Retail',
    code: '86681',
  },
  {
    name: 'Fishing Equipment and Supplies - Wholesale',
    code: '86691',
  },
  {
    name: 'Five and Ten Cent Stores',
    code: '80831',
  },
  {
    name: 'Flags and Banners - Retail',
    code: '86711',
  },
  {
    name: 'Flags and Banners - Wholesale',
    code: '86721',
  },
  {
    name: 'Floor Covering Distributors',
    code: '88587',
  },
  {
    name: 'Floor Covering Stores',
    code: '88588',
  },
  {
    name: 'Florists - No Greenhouses or Roadside Stands - Retail',
    code: '80441',
  },
  {
    name: 'Florists - No Greenhouses or Roadside Stands - Wholesale',
    code: '83231',
  },
  {
    name: 'Formal Wear or Costume Rental',
    code: '88591',
  },
  {
    name: 'Fresh Ethnic Food Stands (e.g., Sushi Stand within other retail establishments)',
    code: '88592',
  },
  {
    name: 'Fruit or Vegetable Dealers',
    code: '88594',
  },
  {
    name: 'Funeral Home or Chapels: Buildings Constructed for Funeral Home Occupancy',
    code: '81311',
  },
  {
    name: 'Furniture - Upholstered - Retail',
    code: '88595',
  },
  {
    name: 'Furniture - Wood or Metal - Retail',
    code: '88596',
  },
  {
    name: 'Garden Stores and Light Farming Supplies - No Landscaping or Equipment Rental - Retail',
    code: '80841',
  },
  {
    name: 'Garden Stores and Light Farming Supplies - No Landscaping or Equipment Rental - Wholesale',
    code: '85841',
  },
  {
    name: 'Gift Shops - Retail',
    code: '80341',
  },
  {
    name: 'Gift Shops - Wholesale',
    code: '85341',
  },
  {
    name: 'Glass Dealers - Not Glaziers - Wholesale',
    code: '85351',
  },
  {
    name: 'Glass Novelty  - Wholesale',
    code: '86741',
  },
  {
    name: 'Glass Novelty - Retail',
    code: '86731',
  },
  {
    name: 'Glassware, Ceramics, Pottery and China - Retail',
    code: '86751',
  },
  {
    name: 'Glassware, Ceramics, Pottery and China – Wholesale',
    code: '86761',
  },
  {
    name: 'Golfing Supplies and Accessories',
    code: '88658',
  },
  {
    name: 'Gourmet Food Stores',
    code: '88589',
  },
  {
    name: 'Greeting Cards',
    code: '86361',
  },
  {
    name: "Hardware - Nuts, Bolts, Hand Tools and Artisans' Tools - Excluding Guns and Bulk Solvents - Wholesale",
    code: '85091',
  },
  {
    name: 'Hardware Stores',
    code: '80091',
  },
  {
    name: 'Hat Stores',
    code: '88597',
  },
  {
    name: 'Health Food Stores',
    code: '88598',
  },
  {
    name: 'Hearing Aid Establishments - Retail',
    code: '81351',
  },
  {
    name: 'Hearing Aid Establishments - Wholesale',
    code: '85811',
  },
  {
    name: 'Heating or Combined Heating and Air Conditioning Equipment Distributors - Wholesale',
    code: '85821',
  },
  {
    name: "Hobby, Model Maker or Artists' Supply - Retail",
    code: '80361',
  },
  {
    name: "Hobby, Model Maker or Artists' Supply - Wholesale",
    code: '85361',
  },
  {
    name: 'Holiday Boutiques (Christmas, etc.)',
    code: '89139',
  },
  {
    name: 'Home Furnishing and Accessory Distributors',
    code: '88601',
  },
  {
    name: 'Home Furnishing and Accessory Stores',
    code: '88599',
  },
  {
    name: 'Home Improvement Stores',
    code: '81361',
  },
  {
    name: 'Home Theatre - Including Audio/Visual Equipment - Wholesale',
    code: '86781',
  },
  {
    name: 'Household Accessories/Small Appliances - No Furniture - Retail',
    code: '81371',
  },
  {
    name: 'Household Furnishings - With Furniture - Not Appliances - Retail',
    code: '80101',
  },
  {
    name: 'Household Furnishings - With Furniture - Not Appliances - Wholesale',
    code: '85101',
  },
  {
    name: 'Household Paper Product Distributor - Wholesale',
    code: '86371',
  },
  {
    name: 'Household Paper Product Distributor No Mixing, Blending or Manufacturing - Wholesale',
    code: '86381',
  },
  {
    name: 'Ice Cream or Yogurt Stores',
    code: '88602',
  },
  {
    name: 'Interior Decorators',
    code: '81191',
  },
  {
    name: 'Internet: Books and CD’s',
    code: '88712',
  },
  {
    name: 'Internet: Clothing',
    code: '88713',
  },
  {
    name: 'Internet: Crafts and Gifts',
    code: '88714',
  },
  {
    name: 'Internet: Home Furnishings',
    code: '88715',
  },
  {
    name: 'Internet: Paper or Party Goods',
    code: '88716',
  },
  {
    name: 'Janitorial Supplies - Retail',
    code: '88603',
  },
  {
    name: 'Janitorial Supplies - Wholesale',
    code: '86391',
  },
  {
    name: 'Jewelers - Imitation and Novelty - Retail',
    code: '81381',
  },
  {
    name: 'Juice Bar',
    code: '88604',
  },
  {
    name: 'Kennels',
    code: '88605',
  },
  {
    name: 'Kitchen Accessories - Including Cutlery - Retail',
    code: '83241',
  },
  {
    name: 'Kitchen Accessories - Including Cutlery - Wholesale',
    code: '85241',
  },
  {
    name: 'LRO: Mercantile - With < 25% Restaurant',
    code: '89112',
  },
  {
    name: 'LRO: Mercantile - Without Restaurant',
    code: '89111',
  },
  {
    name: 'LRO: Office - With Restaurant',
    code: '82150',
  },
  {
    name: 'LRO: Office - Without Restaurant',
    code: '82140',
  },
  {
    name: 'LRO: Services',
    code: '89113',
  },
  {
    name: 'Learning Centers (College ACT/SAT prep, Computers, Math, etc.)',
    code: '89142',
  },
  {
    name: 'Leather Products and Luggage - Wholesale',
    code: '85111',
  },
  {
    name: 'Leather Products and Luggage – Retail',
    code: '80111',
  },
  {
    name: 'Liquor Stores',
    code: '88607',
  },
  {
    name: 'Lithographing - Retail',
    code: '81141',
  },
  {
    name: 'Locksmiths – No Alarm Installation',
    code: '80121',
  },
  {
    name: 'Mailbox and Packaging Stores',
    code: '89146',
  },
  {
    name: 'Mailing or Addressing Companies',
    code: '81041',
  },
  {
    name: 'Meal Preparation Stores',
    code: '88608',
  },
  {
    name: 'Meat, Poultry, or Seafood Stores',
    code: '88659',
  },
  {
    name: 'Medical Spas',
    code: '88611',
  },
  {
    name: 'Monuments and Tombstones – Retail',
    code: '88613',
  },
  {
    name: 'Monuments and Tombstones – Wholesale',
    code: '88612',
  },
  {
    name: 'Musical Instrument Stores - Not Secondhand - Retail',
    code: '80371',
  },
  {
    name: 'Musical Instruments -Wholesale',
    code: '85371',
  },
  {
    name: 'Musical Instruments Sales, Service or Repair - Retail',
    code: '83031',
  },
  {
    name: 'Nail Salons',
    code: '88614',
  },
  {
    name: 'Newsstand',
    code: '86411',
  },
  {
    name: 'Nursery Store',
    code: '86421',
  },
  {
    name: 'Office Furniture - Retail',
    code: '83831',
  },
  {
    name: 'Office Furniture - Wholesale',
    code: '85831',
  },
  {
    name: 'Office Machines - Equipment and Supplies Sales, Service or Repair - Retail',
    code: '83011',
  },
  {
    name: 'Office Machines - Retail',
    code: '80131',
  },
  {
    name: 'Office Machines, Equipment or Supplies - Wholesale',
    code: '85131',
  },
  {
    name: 'Office: Accounting/Bookkeeping',
    code: '82031',
  },
  {
    name: 'Office: Acupuncture',
    code: '88543',
  },
  {
    name: 'Office: Adjusters',
    code: '82161',
  },
  {
    name: 'Office: Advertising',
    code: '82091',
  },
  {
    name: 'Office: Allergists',
    code: '88544',
  },
  {
    name: 'Office: Answering Services',
    code: '88545',
  },
  {
    name: 'Office: Architect',
    code: '88547',
  },
  {
    name: 'Office: Auditors',
    code: '82171',
  },
  {
    name: 'Office: Billing Services',
    code: '88609',
  },
  {
    name: 'Office: Business Management Consulting',
    code: '82271',
  },
  {
    name: 'Office: Chiropractors',
    code: '88566',
  },
  {
    name: 'Office: Commercial Artist and Graphic Designers',
    code: '82181',
  },
  {
    name: 'Office: Court Reporting Services',
    code: '82191',
  },
  {
    name: 'Office: Dentists',
    code: '82391',
  },
  {
    name: 'Office: Dermatologists',
    code: '88575',
  },
  {
    name: 'Office: Direct Mail Advertising Services',
    code: '88576',
  },
  {
    name: 'Office: Engineers',
    code: '88582',
  },
  {
    name: 'Office: Escrow Agents',
    code: '88583',
  },
  {
    name: 'Office: Financial Planners',
    code: '82201',
  },
  {
    name: 'Office: Inspection and Appraisal Services',
    code: '82211',
  },
  {
    name: 'Office: Insurance Agencies',
    code: '82081',
  },
  {
    name: 'Office: Lawyers',
    code: '82021',
  },
  {
    name: 'Office: Market Research Firms',
    code: '82221',
  },
  {
    name: 'Office: Medical',
    code: '82011',
  },
  {
    name: 'Office: Mortgage Brokers',
    code: '82231',
  },
  {
    name: 'Office: Naturopathic Specialists',
    code: '88615',
  },
  {
    name: 'Office: Nutritionists',
    code: '88616',
  },
  {
    name: 'Office: Ophthalmologists',
    code: '88618',
  },
  {
    name: 'Office: Optometrists',
    code: '88619',
  },
  {
    name: 'Office: Oral Surgeons',
    code: '88621',
  },
  {
    name: 'Office: Osteopaths',
    code: '88622',
  },
  {
    name: 'Office: Physical Therapists',
    code: '88627',
  },
  {
    name: 'Office: Physicians',
    code: '88628',
  },
  {
    name: 'Office: Plastic Surgeons',
    code: '88632',
  },
  {
    name: 'Office: Podiatrists',
    code: '88634',
  },
  {
    name: 'Office: Psychologists',
    code: '88639',
  },
  {
    name: 'Office: Real Estate Agents, no Property Management',
    code: '82051',
  },
  {
    name: 'Office: Religious Reading Rooms',
    code: '82241',
  },
  {
    name: 'Office: Secretarial/Stenographic Services',
    code: '82041',
  },
  {
    name: 'Office: Speech Therapists',
    code: '88646',
  },
  {
    name: 'Office: Stockbrokers',
    code: '82331',
  },
  {
    name: 'Office: Surgeons',
    code: '88648',
  },
  {
    name: 'Office: Tax Preparation',
    code: '82251',
  },
  {
    name: 'Office: Ticket Agencies – Sports or Entertainment Only',
    code: '82261',
  },
  {
    name: 'Office: Title Insurance Services',
    code: '88651',
  },
  {
    name: 'Office: Travel Agencies – No Tours',
    code: '82061',
  },
  {
    name: 'Office: Veterinarians',
    code: '82281',
  },
  {
    name: 'Office: X-Ray, MRI, etc.',
    code: '88657',
  },
  {
    name: 'Optical Goods - Retail',
    code: '80141',
  },
  {
    name: 'Optical Goods - Wholesale',
    code: '85141',
  },
  {
    name: 'Paint and Sip Studios',
    code: '89147',
  },
  {
    name: 'Paint, Wallpaper or Wallcovering - Retail',
    code: '80381',
  },
  {
    name: 'Paint, Wallpaper or Wallcovering - Wholesale',
    code: '85381',
  },
  {
    name: 'Painting, Picture or Frame Stores - No Bulk Solvents',
    code: '80151',
  },
  {
    name: 'Painting, Picture or Frame Stores-No Bulk Solvents-Wholesale',
    code: '86821',
  },
  {
    name: 'Party Supply Stores',
    code: '88623',
  },
  {
    name: 'Pet Breeders',
    code: '88624',
  },
  {
    name: 'Pet Daycare',
    code: '86321',
  },
  {
    name: 'Pet Grooming',
    code: '86011',
  },
  {
    name: 'Pet Stores',
    code: '80501',
  },
  {
    name: 'Pet Supplies - Retail',
    code: '86831',
  },
  {
    name: 'Pet Supplies - Wholesale',
    code: '85501',
  },
  {
    name: 'Photo Finishing Labs',
    code: '88625',
  },
  {
    name: 'Photocopy Services - Retail',
    code: '81071',
  },
  {
    name: 'Photoengraving',
    code: '88626',
  },
  {
    name: 'Photographers',
    code: '81151',
  },
  {
    name: 'Pizza Carryout',
    code: '88629',
  },
  {
    name: 'Pizzeria - With Incidental Commercial Cooking (e.g., frying, grilling, etc.)',
    code: '89148',
  },
  {
    name: 'Pizzeria – No Commercial Cooking (e.g., frying, grilling, etc.)',
    code: '88631',
  },
  {
    name: 'Plastic or Rubber Supply Goods Distributor',
    code: '89149',
  },
  {
    name: 'Plumbing and Heating Supplies – Retail',
    code: '88633',
  },
  {
    name: 'Plumbing and Heating Supplies – Wholesale',
    code: '85691',
  },
  {
    name: 'Pool Tables and Billiards Supplies – Retail',
    code: '86841',
  },
  {
    name: 'Pool Tables and Billiards Supplies – Wholesale',
    code: '86851',
  },
  {
    name: 'Popcorn Shops',
    code: '89151',
  },
  {
    name: 'Pottery Store - No Kilns',
    code: '86431',
  },
  {
    name: 'Precision and Scientific Tools and Instruments – Retail',
    code: '88636',
  },
  {
    name: 'Pretzel Shop',
    code: '88637',
  },
  {
    name: 'Print Shops - Other than Quick Print',
    code: '81081',
  },
  {
    name: 'Printer Cartridge Sales and Service',
    code: '88638',
  },
  {
    name: 'Printing and Bookbinding Supplies - Wholesale',
    code: '85741',
  },
  {
    name: 'Quick Print Shops',
    code: '81221',
  },
  {
    name: 'Radio, TV and Audio Equipment – Wholesale',
    code: '88641',
  },
  {
    name: 'Records, Audio and/or Video Tapes, Compact Discs and Sheet Music Stores - Sales or Rental - Retail',
    code: '80801',
  },
  {
    name: 'Records, Audio and/or Video Tapes, Compact Discs and Sheet Music Stores - Sales or Rental - Wholesale',
    code: '85801',
  },
  {
    name: 'Refrigeration Equipment Distributor - Commercial - Wholesale',
    code: '85851',
  },
  {
    name: 'Religious Articles - No Precious Metals or Jewelry - Wholesale',
    code: '85161',
  },
  {
    name: 'Religious Articles - Retail',
    code: '80161',
  },
  {
    name: 'Sandwich Shops - No Commercial Cooking (e.g., frying, grilling, etc.)',
    code: '89152',
  },
  {
    name: 'Sandwich Shops - With Incidental Commercial Cooking (e.g., frying, grilling, etc.)',
    code: '89153',
  },
  {
    name: 'School and Educational Supplies - Retail',
    code: '86461',
  },
  {
    name: 'School and Educational Supplies - Wholesale',
    code: '88578',
  },
  {
    name: 'Self Storage Facilities – Mini Warehouses (NOTE: This class is only available in AK, ID, MT, OR, WA)',
    code: '88663',
  },
  {
    name: 'Sewing Machines - Retail',
    code: '80171',
  },
  {
    name: 'Sewing Machines - Sales, Service or Repair - Retail',
    code: '83021',
  },
  {
    name: 'Sewing Machines - Wholesale',
    code: '85171',
  },
  {
    name: 'Ship Chandler Stores',
    code: '88645',
  },
  {
    name: 'Shoe Repair Shops',
    code: '81161',
  },
  {
    name: "Shoes - Ladies', Men's and Children's - Retail",
    code: '80311',
  },
  {
    name: "Shoes - Ladies', Men's and Children's - Wholesale",
    code: '85311',
  },
  {
    name: 'Sporting Goods or Athletic Equipment - No Guns, Ammunition or Vehicles - Wholesale',
    code: '85391',
  },
  {
    name: 'Sporting Goods or Athletic Equipment Stores - No Guns - Retail',
    code: '80391',
  },
  {
    name: 'Stationery or Paper Products Stores',
    code: '80181',
  },
  {
    name: 'Sunglasses - Wholesale',
    code: '88647',
  },
  {
    name: 'Sunglasses – Retail',
    code: '86471',
  },
  {
    name: 'Tack Shops - Saddlery Stores - Retail',
    code: '86481',
  },
  {
    name: 'Tailoring Establishments',
    code: '81171',
  },
  {
    name: 'Taxidermists',
    code: '81181',
  },
  {
    name: 'Television or Radio Receiving Set Installation or Repair',
    code: '81201',
  },
  {
    name: 'Tile - Ceramic - No Installation - Retail',
    code: '83901',
  },
  {
    name: 'Tile - Ceramic - No Installation - Wholesale',
    code: '85901',
  },
  {
    name: 'Toy Stores - Retail',
    code: '80401',
  },
  {
    name: 'Toys - Wholesale',
    code: '85401',
  },
  {
    name: 'Trophy Stores - Retail',
    code: '80411',
  },
  {
    name: 'Trophy Stores - Wholesale',
    code: '86881',
  },
  {
    name: 'Vacuum Stores',
    code: '86491',
  },
  {
    name: 'Variety Stores',
    code: '86511',
  },
  {
    name: 'Watch, Clock or Jewelry Repair',
    code: '81051',
  },
  {
    name: 'Wedding Consultants',
    code: '88652',
  },
  {
    name: 'Weight Counseling Facilities - Franchise',
    code: '88653',
  },
  {
    name: 'Wigs - Retail',
    code: '80321',
  },
  {
    name: 'Wigs – Wholesale',
    code: '88655',
  },
  {
    name: 'Wine Shops',
    code: '88656',
  },
];

export const BOP_LRO_CLASSES = LM_BOP_CLASSES.reduce((lroClasses, { name }) => {
  if (name.startsWith('LRO:')) {
    lroClasses.add(name);
  }
  return lroClasses;
}, new Set<string>());
