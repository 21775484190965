<ng-container>
  <h2 class="stripe-header" *ngIf="!isInitialAutopay && !isUpdatingPayment">
    Select payment method
  </h2>
  <h2 class="stripe-header" *ngIf="!isInitialAutopay && isUpdatingPayment">
    Update payment method
  </h2>
  <h2 class="stripe-header" *ngIf="isInitialAutopay">Set up Autopay</h2>

  <ng-container *ngIf="isUpdatingPayment">
    <p>
      Current autopay method
      <span
        *ngIf="!isAccountPage"
        class="more-info-tip tool-tip tool-tip__large"
        data-tooltip="This is the current payment method on file. To unenroll, please contact your broker, so they can unenroll you in our system."
      >
        <span class="icon icon-question"></span>
      </span>
    </p>
    <div class="form-subsection form-subsection__gray payment-unenroll-subsection">
      <span>
        <img
          class="invoice-autopay-icon"
          src="/assets/img/credit_card_icon.svg"
          *ngIf="currentAutopayIsCard"
          alt="Credit card"
        />
        <img
          class="invoice-autopay-icon"
          src="/assets/img/ach_icon.svg"
          *ngIf="!currentAutopayIsCard"
          alt="ACH transfer"
        />
        <span>{{ currentAutopayText }}</span>
      </span>
      <a
        *ngIf="isAccountPage"
        (click)="unenroll.emit()"
        (keyup.enter)="unenroll.emit()"
        class="js-payment-form-unenroll"
        data-amplitude-input-name="payment-form-unenroll-button"
        >Unenroll</a
      >
      <span *ngIf="!isAccountPage" class="gray-text">Please contact your broker to unenroll</span>
    </div>
  </ng-container>

  <p *ngIf="!isAccountPage && isInitialAutopay">
    By enrolling in Autopay, you authorize Attune to draft money three days before the due date.
    This applies to all invoices that have not already been billed (ie. fixed installments,
    endorsements, renewals, or miscellaneous charges from manually generated invoices).
  </p>
  <p *ngIf="isAccountPage && isInitialAutopay">
    Please notify your client that payments will be drafted <strong>3 days</strong> before the
    invoice due date. This applies to all invoices that have not already been billed (i.e. fixed
    installments, endorsements, renewals, or miscellaneous charges from manually generated
    invoices).
  </p>
  <p
    *ngIf="
      (isUpdatingPayment || isInitialAutopay) &&
      !isProcessing &&
      !paymentUpdateSuccessful &&
      !getErrorMessage()
    "
  >
    New autopay method
  </p>

  <div *ngIf="!paymentUpdateSuccessful">
    <form [formGroup]="paymentForm" novalidate>
      <div
        class="form-subsection form-subsection__static"
        [ngClass]="{ 'form-subsection__gray': isACHPayment() }"
      >
        <div class="invoice-payment-option invoice-payment-option__modal">
          <input
            id="ach-option"
            type="radio"
            formControlName="paymentOption"
            name="paymentOption"
            value="ach"
            checked
          />
          <label for="ach-option" class="invoice-payment-label">
            Bank account / E-check
            <span *ngIf="!onlyShowAchOption" class="preferred-option-text">
              <span class="preferred-option-icon-check">&#10003;</span>
              Most businesses choose this
            </span>
          </label>
          <div *ngIf="!onlyShowAchOption" class="preferred-option-badge">
            <img src="/assets/img/preferred_badge.svg" alt="" />
          </div>
        </div>

        <ng-container *ngIf="isACHPayment()">
          <div class="form-split">
            <app-form-field-text
              inputId="fullname"
              [submitted]="achFormSubmitted"
              [form]="paymentForm.get('achPayment')"
              labelText="Account holder name"
              nameOfFormControl="fullname"
              placeholder="Jane Doe"
            >
            </app-form-field-text>
            <app-form-field-select
              inputId="accountType"
              [submitted]="achFormSubmitted"
              [form]="paymentForm.get('achPayment')"
              labelText="Account type"
              nameOfFormControl="accountType"
              [availableOptions]="['Individual', 'Company']"
            >
            </app-form-field-select>
          </div>
          <div class="form-split">
            <app-form-field-text
              inputId="routingNumber"
              [submitted]="achFormSubmitted"
              [form]="paymentForm.get('achPayment')"
              labelText="Routing number"
              nameOfFormControl="routingNumber"
              placeholder="110000000"
            >
            </app-form-field-text>
            <app-form-field-text
              [mask]="true"
              inputId="accountNumber"
              isSensitiveInfo="true"
              [form]="paymentForm.get('achPayment')"
              [submitted]="achFormSubmitted"
              labelText="Account number"
              nameOfFormControl="accountNumber"
              placeholder="000123456789"
            >
            </app-form-field-text>
          </div>
        </ng-container>
      </div>

      <div
        class="form-subsection form-subsection__static"
        [ngClass]="{ 'form-subsection__gray': !isACHPayment() }"
      >
        <div
          class="invoice-payment-option invoice-payment-option__modal"
          [ngClass]="{ disabled: onlyShowAchOption }"
        >
          <!-- using a ternary operator to set attr.disabled below because "false" is considered truthy -->
          <input
            id="credit-option"
            type="radio"
            name="paymentOption"
            formControlName="paymentOption"
            value="creditCard"
            [attr.disabled]="onlyShowAchOption ? true : null"
          />
          <label for="credit-option" class="invoice-payment-label">{{
            getCreditCardOptionText()
          }}</label>
          <img *ngIf="!isACHPayment()" class="card-stack" src="/assets/img/cardstack.png" alt="" />
        </div>

        <div
          class="credit-card-form-section"
          [ngClass]="{
            'credit-card-form-section__hide': isACHPayment(),
            'credit-card-form-section__with-card-fee-notice':
              displayCreditCardDollarAmountMessage() || displayCreditCardPercentageMessage()
          }"
        >
          <app-invoices-payment-input
            (cardComplete)="setCardComplete($event)"
            (cardErrors)="setStripeError($event)"
          >
          </app-invoices-payment-input>
          <p *ngIf="displayCreditCardDollarAmountMessage()" class="credit-card-fee-notice">
            Debit / credit card payments will be charged a fee of
            {{ creditCardFee | currency: 'USD':'symbol' }}
          </p>
          <p *ngIf="displayCreditCardPercentageMessage()" class="credit-card-fee-notice">
            Debit / credit card payments will be charged a fee of up to
            {{ creditCardFeePercentage }}%
          </p>
        </div>
      </div>

      <app-dialog-box *ngIf="isInitialAutopay && hasPaymentDue" type="warning">
        You have outstanding charges. (This may include invoices that are not yet due.) Please pay
        your balance to confirm enrollment.
      </app-dialog-box>

      <app-dialog-box *ngIf="hasPastDueAndOutstanding" type="warning">
        You are about to pay the outstanding balance on this account, which includes all past due
        and billed invoices.
      </app-dialog-box>

      <app-dialog-box
        *ngIf="getErrorMessage()"
        type="danger"
        class="js-payment-failure dialog-text__full-width"
      >
        {{ getErrorMessage() }}
      </app-dialog-box>

      <div class="invoice-autopay-section" *ngIf="!isUpdatingPayment && !isInitialAutopay">
        <app-form-field-checkbox
          inputId="autopay"
          labelText="Enroll in automatic payments"
          nameOfFormControl="autopay"
          [form]="paymentForm"
        ></app-form-field-checkbox>
      </div>

      <div class="invoice-pay-buttons">
        <p class="invoice-confirm-text">
          By clicking pay, you are confirming that you understand and agree to pay all premium,
          fees, and taxes associated with your policy.
        </p>
        <button
          (click)="handlePaymentFlow()"
          [attr.data-amplitude-input-name]="getAmplitudeSubmitId()"
          type="submit"
          class="
            button button__primary button__full-width
            js-stripe-pay-button js-stripe-submit-button
          "
          [disabled]="isProcessing"
        >
          <ng-container *ngIf="!isProcessing">
            <ng-container *ngIf="isInitialAutopay && hasPaymentDue"
              >Enroll and Pay {{ getAmountToPay() | currency
              }}<span *ngIf="displayCreditCardPercentageMessage()">
                (fee of up to {{ creditCardFeePercentage }}% will apply)</span
              ></ng-container
            >
            <ng-container *ngIf="isInitialAutopay && !hasPaymentDue"
              >Enroll in Autopay</ng-container
            >
            <ng-container *ngIf="!isInitialAutopay && !isUpdatingPayment"
              >Pay {{ getAmountToPay() | currency
              }}<span *ngIf="displayCreditCardPercentageMessage()">
                (fee of up to {{ creditCardFeePercentage }}% will apply)</span
              ></ng-container
            >
            <ng-container *ngIf="!isInitialAutopay && isUpdatingPayment && hasPaymentDue"
              >Update Payment and Pay {{ getAmountToPay() | currency
              }}<span *ngIf="displayCreditCardPercentageMessage()">
                (fee of up to {{ creditCardFeePercentage }}% will apply)</span
              ></ng-container
            >
            <ng-container *ngIf="!isInitialAutopay && isUpdatingPayment && !hasPaymentDue"
              >Update Payment</ng-container
            >
          </ng-container>
          <ng-container *ngIf="isProcessing">
            <div class="button-loader">
              <svg class="circle-spinner">
                <circle
                  cx="11"
                  cy="11"
                  r="9"
                  stroke-width="2"
                  stroke-dasharray="0,1000"
                  d="M5 40 l215 0"
                  fill="transparent"
                />
              </svg>
            </div>
            Processing
            <span *ngIf="!isUpdatingPayment && !isInitialAutopay">payment</span>
            <span *ngIf="isInitialAutopay">enrollment</span>
            <span *ngIf="isUpdatingPayment">update</span>...
          </ng-container>
        </button>

        <button
          *ngIf="!isInitialAutopay && !paymentUpdateSuccessful"
          [disabled]="isProcessing"
          class="button button__secondary button__full-width"
          (click)="closePaymentModal.emit()"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="paymentUpdateSuccessful">
    <app-dialog-box
      type="success"
      dismissable="true"
      class="dialog-text__full-width dialog-text__margin-top js-invoice-success-message"
    >
      Update successful!
    </app-dialog-box>
    <button (click)="closePaymentModal.emit()" class="button button__secondary button__full-width">
      Close
    </button>
  </div>

  <div *ngIf="amountToPay > 0" class="stripe-terms-text">
    See our <a target="_blank" href="/invoice/terms-and-conditions">terms and conditions</a> for
    payments using Stripe.
  </div>
</ng-container>
