import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NaicsService } from '../../../../shared/services/naics.service';
import { AppetiteProductEligibility } from '../../models/types';

@Component({
  selector: 'app-appetite-search-results',
  templateUrl: 'appetite-search-results.component.html',
})
export class AppetiteSearchResultsComponent {
  @Input() naicsCode: NaicsCode;
  @Input() productAvailabities: AppetiteProductEligibility[];
  @Input() state: NaicsCode;

  constructor(private naicsService: NaicsService, private router: Router) {}

  selectClassCode(code: string, description: string, hash: string) {
    this.router.navigate(['/accounts', 'new'], {
      queryParams: {
        'naicsCode.code': code,
        'naicsCode.description': description,
        'naicsCode.hash': hash,
        state: this.state,
      },
    });
  }
}
