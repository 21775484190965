import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {
  AUTHENTICATED_ROUTE,
  UNAUTHENTICATED_ROUTE,
} from 'app/features/support/models/support-help-center-constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class SupportAuthGuard {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!environment.showNewHelpCenter) {
      return this.router.parseUrl('/login');
    }

    // If user is trying to access authenticated route, check the user context
    // --> yes, allow (and refresh auth token in background)
    // --> no, redirect to unauth'd route
    if (state.url.includes(AUTHENTICATED_ROUTE)) {
      const formUrlRegex = new RegExp(`^\/${AUTHENTICATED_ROUTE}\/form\/.*`);
      let redirectUrl = `/${UNAUTHENTICATED_ROUTE}`;

      // If user is trying to access a specific form via authenticated route, redirect to the same form using the unauthenticated route
      if (formUrlRegex.test(state.url)) {
        redirectUrl = state.url.replace(
          new RegExp(`^\/${AUTHENTICATED_ROUTE}`),
          `/${UNAUTHENTICATED_ROUTE}`
        );
      }

      return this.userService.validateSessionAndRepeat(state.url, redirectUrl);
    }

    // If user is trying to access unauthenticated route, always allow
    if (state.url.includes(UNAUTHENTICATED_ROUTE)) {
      // TODO: Redirect users with a user context who access the unauth'd flow to the auth'd flow
      return true;
    }

    return this.userService.validateSessionAndRepeat(state.url);
  }
}
