import { Observable } from 'rxjs';
import { flatMap, map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StripeService } from 'ngx-stripe';
import {
  StripeElement,
  StripeCardNumberElement,
  CreateTokenBankAccountData,
  StripeError,
  CreateSourceData,
  Token,
  Source,
  CreateTokenCardData,
} from '@stripe/stripe-js';
import { API_V3_BASE, UPDATE_AUTOPAY_URL } from '../../../constants';
import { SentryService } from 'app/core/services/sentry.service';

declare type InvoicePaymentStatus = 'success' | 'failure';

const COMMON_CARD_ERRORS = [
  'Your card was declined',
  'Your card has expired',
  'Your card has insufficient funds',
  "Your card's security code is incorrect",
];

export interface SourceResult {
  source?: Source;
  error?: StripeError;
}
export interface TokenResult {
  token?: Token;
  error?: StripeError;
}
export interface PaymentInformation {
  paymentType: 'ach' | 'card';
  stripeToken: string;
}

export interface InvoicePaymentError {
  error: {
    errors: string[];
  };
  code: string;
  message: string;
  status: string;
}

export class InvoicePaymentResponse {
  statusFlag: InvoicePaymentStatus = 'failure';
  status: number | string = 1;
  statusText = 'nothing';
  url = '';
  ok = false;
  name = 'HttpErrorResponse';
  message = '';
  error = {
    errors: [''],
    message: '',
    status: '',
  };
}

@Injectable()
export class InvoicesPaymentService {
  private readonly BILLING_SERVICE_URL = `${API_V3_BASE}/billing/payments/stripe/instrument`;

  constructor(
    private http: HttpClient,
    private stripeService: StripeService,
    private sentryService: SentryService
  ) {}

  createStripeCreditCardToken(
    cardElement: StripeCardNumberElement,
    cardDataOptions: CreateTokenCardData,
    isRecurring: boolean,
    amount: number
  ): Observable<string> {
    amount = Number(amount); // TODO how is sometimes not a number?

    const tokenResult$: Observable<TokenResult> = this.stripeService.createToken(
      cardElement,
      cardDataOptions
    );
    let token$: Observable<string> = tokenResult$.pipe(
      map((result) => this.extractTokenId(result))
    );

    if (isRecurring) {
      const source$: Observable<SourceResult> = token$.pipe(
        flatMap((token) => this.createSourceData(token, cardElement))
      );
      token$ = source$.pipe(map((res) => this.extractSourceId(res)));
    }

    return token$;
  }

  createStripeBankToken(bankData: CreateTokenBankAccountData): Observable<string> {
    const tokenResult$: Observable<TokenResult> = this.stripeService.createToken(
      'bank_account',
      bankData
    );
    const token$: Observable<string> = tokenResult$.pipe(
      map((tokenResult) => this.extractTokenId(tokenResult))
    );
    return token$;
  }

  processCreditCardPayment(
    cardElement: StripeCardNumberElement,
    cardDataOptions: CreateTokenCardData,
    isRecurring: boolean,
    accountNumber: string,
    invoiceNumber: string,
    amount: number,
    invoiceToken: string,
    invoiceId: string
  ): Observable<InvoicePaymentResponse> {
    const token: Observable<string> = this.createStripeCreditCardToken(
      cardElement,
      cardDataOptions,
      isRecurring,
      amount
    );
    return this.process(
      token,
      isRecurring,
      accountNumber,
      invoiceNumber,
      amount,
      'card',
      invoiceToken,
      invoiceId
    );
  }

  processBankPayment(
    bankData: CreateTokenBankAccountData,
    isRecurring: boolean,
    accountNumber: string,
    invoiceNumber: string,
    amount: number,
    invoiceToken: string,
    invoiceId: string
  ): Observable<InvoicePaymentResponse> {
    const token: Observable<string> = this.createStripeBankToken(bankData);
    return this.process(
      token,
      isRecurring,
      accountNumber,
      invoiceNumber,
      amount,
      'ach',
      invoiceToken,
      invoiceId
    );
  }

  process(
    token$: Observable<string>,
    isRecurring: boolean,
    accountNumber: string,
    invoiceNumber: string,
    amount: number,
    type: PaymentType,
    invoiceToken: string,
    invoiceId: string
  ): Observable<InvoicePaymentResponse> {
    const payload$: Observable<PaymentRequestPayLoad> = token$.pipe(
      map((token) =>
        this.createBillingServicePayload(
          token,
          isRecurring,
          accountNumber,
          invoiceNumber,
          amount,
          type,
          invoiceToken,
          invoiceId
        )
      )
    );

    const send$: Observable<InvoicePaymentResponse> = payload$.pipe(
      flatMap((payload) => this.sendCustomerToken(payload))
    );

    const result$: Observable<InvoicePaymentResponse> = send$.pipe(
      map((res: InvoicePaymentResponse) => this.parsePayloadResponse(res))
    );

    return result$;
  }

  parsePayloadResponse(obj: InvoicePaymentResponse): InvoicePaymentResponse {
    return obj; // new InvoicePaymentResponse('success');
  }

  sendCustomerToken(payload: PaymentRequestPayLoad): Observable<InvoicePaymentResponse> {
    const send$: Observable<InvoicePaymentResponse> = this.http
      .post<InvoicePaymentResponse>(this.BILLING_SERVICE_URL, payload)
      .pipe(
        catchError((error) => {
          // Do not log to Sentry if there is a single error, and it's a common card problem
          // (For example, card decline, incorrect CVC, and so on)
          if (
            !error.errors ||
            error.errors.length !== 1 ||
            !COMMON_CARD_ERRORS.some((commonError) => error.errors[0].includes(commonError))
          ) {
            this.sentryService.notify('Unable to process payment.', {
              severity: 'error',
              metaData: {
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
          }
          throw error;
        })
      );
    return send$;
  }

  extractTokenId(result: TokenResult): string {
    if (result.error) {
      /* eslint-disable-next-line */
      throw result.error;
    }
    const token: Token = result.token as Token;
    return token.id;
  }

  extractSourceId(result: SourceResult): string {
    if (result.error) {
      /* eslint-disable-next-line */
      throw result.error;
    }
    const source: Source = result.source as Source;
    return source.id;
  }

  createBillingServicePayload(
    id: string,
    isRecurring: boolean,
    accountNumber: string,
    invoiceNumber: string,
    amount: number,
    type: PaymentType,
    invoiceToken: string,
    invoiceId: string
  ): PaymentRequestPayLoad {
    const payload: PaymentRequestPayLoad = {
      accountNumber: accountNumber,
      amount: amount,
      invoiceNumber: invoiceNumber,
      isRecurringPayment: isRecurring,
      paymentType: type,
      tokenOrSourceId: id,
      invoiceToken: invoiceToken,
      invoiceId: invoiceId,
    };
    return payload;
  }

  createSourceData(token: string, cardElement: StripeElement): Observable<SourceResult> {
    // we can add additional sourceData
    // not sure if this is needed.
    const sourceData: CreateSourceData = {
      token: token,
    };
    const ob: Observable<SourceResult> = this.stripeService.createSource(cardElement, sourceData);
    return ob;
  }

  enrollAutopayWithAch(
    bankData: CreateTokenBankAccountData,
    accountNumber: string
  ): Observable<InvoicePaymentResponse> {
    return this.createStripeBankToken(bankData).pipe(
      switchMap((stripeToken) => {
        return this.enrollAutopay(accountNumber, 'ach', stripeToken);
      })
    );
  }

  enrollAutopayWithCard(
    cardElement: StripeCardNumberElement,
    accountNumber: string
  ): Observable<InvoicePaymentResponse> {
    return this.createStripeCreditCardToken(cardElement, {}, true, 0).pipe(
      switchMap((stripeToken) => {
        return this.enrollAutopay(accountNumber, 'card', stripeToken);
      })
    );
  }

  sendCardUpdate(cardElement: StripeCardNumberElement, accountNumber: string) {
    const stripeToken$: Observable<string> = this.createStripeCreditCardToken(
      cardElement,
      {},
      true,
      0
    );

    return stripeToken$.pipe(
      switchMap((stripeToken) => {
        return this.updatePaymentInformation({
          accountNumber: accountNumber,
          paymentId: stripeToken,
          paymentType: 'ach',
        });
      })
    );
  }

  sendBankUpdate(bankData: CreateTokenBankAccountData, accountNumber: string) {
    const stripeToken$: Observable<string> = this.createStripeBankToken(bankData);

    return stripeToken$.pipe(
      switchMap((stripeToken) => {
        return this.updatePaymentInformation({
          accountNumber: accountNumber,
          paymentId: stripeToken,
          paymentType: 'ach',
        });
      })
    );
  }

  enrollAutopay(
    accountNumber: string,
    paymentType: string,
    token: string
  ): Observable<InvoicePaymentResponse> {
    const payload = {
      accountNumber,
      enroll: true,
      paymentType,
      tokenOrSourceId: token,
    };
    return this.http.post<InvoicePaymentResponse>(UPDATE_AUTOPAY_URL, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to enroll autopay.', {
          severity: 'error',
          metaData: {
            accountNumber,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  unenrollAutopay(accountNumber: string): Observable<any> {
    const payload = {
      accountNumber,
      enroll: false,
      paymentType: '',
      tokenOrSourceId: '',
    };
    const send$: Observable<InvoicePaymentResponse> = this.http
      .post<InvoicePaymentResponse>(UPDATE_AUTOPAY_URL, payload)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to unenroll autopay.', {
            severity: 'error',
            metaData: {
              accountNumber,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
    return send$;
  }

  updatePaymentInformation(
    payload: PaymentUpdateRequestPayLoad
  ): Observable<InvoicePaymentResponse> {
    const send$: Observable<InvoicePaymentResponse> = this.http
      .put<InvoicePaymentResponse>(this.BILLING_SERVICE_URL, payload)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to update payment information.', {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
    return send$;
  }
}
