// Libraries
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-form-field-textarea',
  templateUrl: './form-field-textarea.component.html',
})
export class FormFieldTextareaComponent implements OnInit {
  @Input() inputId: string;
  @Input() labelText: string;
  @Input() subLabelText: string | null = null;
  @Input() questionNote: string;
  @Input() specifierText: string;
  @Input() nameOfFormControl: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() maxLength: string;
  @Input() rows: number;
  @Input() cssClass: string;
  @Input() tooltipCssClass = 'more-info-tip tool-tip tool-tip__large';
  @Input() textareaClass: string;
  @Input() tooltipText: string;
  @Input() value: string | number;
  @Input() placeholder = '';
  @Input() readonly: boolean;
  @Output() focusOut: EventEmitter<null> = new EventEmitter();
  @Input() errorText = 'Field is required';
  @Input() showErrorText = false;

  getControl = getControl;

  fieldInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.form, this.submitted);
  }

  derivedValue(): string | number {
    if (this.value === 0 || this.value === '') {
      return this.value;
    }
    return this.value || getControl(this.form, this.nameOfFormControl).value;
  }

  ngOnInit() {
    if (this.form) {
      getControl(this.form, this.nameOfFormControl).setValue(this.derivedValue());
    }
  }

  handleFocusOut() {
    this.focusOut.emit();
  }
}
