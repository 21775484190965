import { switchMap } from 'rxjs/operators';
// Libraries
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// Services
import { AttuneBopQuoteFormService } from 'app/features/attune-bop/services/attune-bop-quote-form.service';
import { BopPricedQuote } from 'app/features/attune-bop/models/bop-priced-quote';
// Parent Component
import { AttuneBopQuoteEditFormComponent } from '../../components/attune-bop-quote-edit-form/attune-bop-quote-edit-form.component';
import { BopQuoteFormValue } from 'app/features/attune-bop/models/bop-policy';
import { SegmentService } from 'app/core/services/segment.service';

@Component({
  selector: 'app-attune-bop-quote-draft-form-page.app-page.app-page__form',
  templateUrl: '../attune-bop-quote-new-form-page/attune-bop-quote-new-form-page.component.html',
  providers: [AttuneBopQuoteFormService, SegmentService],
})
export class AttuneBopQuoteDraftFormPageComponent
  extends AttuneBopQuoteEditFormComponent
  implements OnInit
{
  draftId: string;

  ngOnInit() {
    this.isEditing = true;
    this.isEditingDraft = true;
    super.ngOnInit();

    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.draftId = params['draftId'];
            this.loading = true;
            return this.draftQuoteService.getQuote(this.accountId, this.draftId);
          })
        )
        .subscribe(
          (res) => {
            if (res === null) {
              this.goBackToAccount();
              return;
            }
            return this.patchForm(res.formData as BopQuoteFormValue, true);
          },
          (err) => this.goBackToAccount()
        )
    );
  }

  saveDraftQuote() {
    this.draftQuoteService
      .update(this.accountId, this.draftId, this.formService.getValue())
      .subscribe(() => {
        this.goBackToAccount();
      });
  }

  updateQuote(resp: BopPricedQuote | HttpErrorResponse, accountId?: string) {
    // Quote is successfully sent to GW. Delete draft
    this.draftQuoteService.delete(this.accountId, this.draftId).subscribe();
    super.updateQuote(resp, accountId);
  }
}
