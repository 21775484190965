<h2>Binding as easy as it should be</h2>
<p>
  Please confirm which products you’d like to bind today. To keep things simple, we’ll create one
  seamless invoice for both products once they’re bound.
</p>

<form (ngSubmit)="handleContinue()">
  <div class="bundle-select__header">
    <app-form-field-checkbox
      inputId="bop_included"
      cssClass="form-field__no-margin form-field__header"
      nameOfFormControl="bop_included"
      [submitted]="submitted"
      [errorText]="getValidationMessageFromControl(bopIncludedControl)"
      [form]="form"
    >
      <div class="label-text__header">
        <div class="product-badge product-badge__bop">
          <div class="product-badge-carrier-icon product-badge-carrier-icon__attune"></div>
        </div>
        <h3>BUSINESS OWNER POLICY</h3>
      </div>
    </app-form-field-checkbox>
    <small class="gray-text">Premium</small>
  </div>
  <div class="bundle-bind-radio-offset">
    <app-form-field-radio-traditional
      *ngIf="bopOptions && bopOptionAdditionalColumns"
      inputId="bop_quotes"
      nameOfFormControl="bop_quotes"
      [options]="bopOptions"
      [optionDescriptions]="bopOptions"
      [optionAdditionalColumns]="bopOptionAdditionalColumns"
      [showLabelWithoutValue]="true"
      optionsLabelCssClass="trad-radio-label-row"
      [readonly]="!bopSelected"
      [submitted]="submitted"
      [form]="form"
    >
    </app-form-field-radio-traditional>
  </div>

  <div class="bundle-select__header">
    <app-form-field-checkbox
      inputId="cyber_included"
      cssClass="form-field__no-margin form-field__header"
      nameOfFormControl="cyber_included"
      [submitted]="submitted"
      [errorText]="getValidationMessageFromControl(cyberIncludedControl)"
      [form]="form"
    >
      <div class="label-text__header">
        <div class="product-badge product-badge__cyber_admitted"></div>
        <h3>CYBER LIABILITY</h3>
      </div>
    </app-form-field-checkbox>
    <small class="gray-text">Premium</small>
  </div>
  <div class="bundle-bind-radio-offset">
    <app-form-field-radio-traditional
      *ngIf="cyberOptions && cyberOptionAdditionalColumns"
      inputId="cyber_quotes"
      nameOfFormControl="cyber_quotes"
      [options]="cyberOptions"
      [optionDescriptions]="cyberOptionDescriptions"
      [optionAdditionalColumns]="cyberOptionAdditionalColumns"
      [showLabelWithoutValue]="true"
      optionsLabelCssClass="trad-radio-label-row"
      [readonly]="!cyberSelected"
      [submitted]="submitted"
      [form]="form"
    >
    </app-form-field-radio-traditional>
  </div>

  <div
    *ngIf="!this.bopIncludedControl.value && !this.cyberIncludedControl.value && submitted"
    class="form-field-error-text"
  >
    Please select a product
  </div>

  <nav class="nav-button-group">
    <button (click)="closeModal.emit()" type="button" class="button button__secondary">
      Cancel
    </button>
    <button type="submit" class="button button__primary">Continue</button>
  </nav>
</form>
