<app-modal
  [open]="open"
  ariaRole="alertdialog"
  ariaLabelledby="estimated-replacement-cost-header"
  ariaDescribedby="estimated-replacement-cost-body"
  modalClass="app-modal-content__uw-estimate"
  (handleModalClose)="goBack()"
>
  <h2 id="estimated-replacement-cost-header" class="app-modal-header uw-estimate-header">
    Insufficient Exposure Values
  </h2>

  <ng-container *ngFor="let intervention of interventionAlerts; let i = index">
    <p id="estimated-replacement-cost-body" class="uw-estimate-desc">
      {{ renderTemplateMessage(intervention) }}
    </p>

    <app-form-field-radio-traditional
      [inputId]="'correction-option-' + i"
      cssClass="uw-estimate-option-radio"
      [form]="formArray.at(i)"
      [options]="correctionOptions"
      [optionDescriptions]="getOptionLabels(intervention)"
      nameOfFormControl="correctionOption"
      [showLabelWithoutValue]="true"
      optionsCssClass="uw-estimate-option"
      displayOnlyKey="USE_SUGGESTED"
      displayOnlyKeySelectedCssClass="uw-estimate-option-selected"
    >
      <span class="pill-tag pill-tag__success" side>Recommended</span>
    </app-form-field-radio-traditional>

    <app-form-field-radio-traditional
      [inputId]="'correction-option-' + i"
      cssClass="uw-estimate-option-radio"
      [form]="formArray.at(i)"
      [options]="correctionOptions"
      [optionDescriptions]="getOptionLabels(intervention)"
      nameOfFormControl="correctionOption"
      [showLabelWithoutValue]="true"
      optionsCssClass="uw-estimate-option"
      displayOnlyKey="USE_DIFFERENT_AMOUNT"
      displayOnlyKeySelectedCssClass="uw-estimate-option-selected"
    >
      <ng-container *ngIf="getCorrectionOption(i) === 'USE_DIFFERENT_AMOUNT'" side>
        <app-form-field-text
          [inputId]="'corrected-value-' + i"
          [form]="formArray.at(i)"
          nameOfFormControl="correctedValue"
          cssClass="uw-estimate-replacement"
          placeholder="$"
          appMoneyMask="WITHOUT_DECIMAL"
        ></app-form-field-text>
      </ng-container>
      <ng-container *ngIf="getCorrectionOption(i) === 'USE_DIFFERENT_AMOUNT'" note>
        <p class="form-field-note">Entered value is {{ intervention.percentage }}% of estimate.</p>
      </ng-container>
    </app-form-field-radio-traditional>
  </ng-container>

  <div class="nav-button-group">
    <button type="button" class="button button__secondary button__no-margin" (click)="goBack()">
      Go back
    </button>
    <button type="button" class="button button__primary button__no-margin" (click)="continue()">
      Continue
    </button>
  </div>
</app-modal>
