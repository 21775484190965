<app-hiscox-quote-form-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__hiscox"
  [product]="productType"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insuredAccount | async"
  sidebarTitle="Bind quote"
>
</app-hiscox-quote-form-sidebar>

<div class="app-page-form-scrollable-wrapper">
  <div class="app-page-form">
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>

    <router-outlet></router-outlet>

    <div class="nav-button-group nav-button-group__left nav-button-group__centered">
      <form [formGroup]="form" (ngSubmit)="handleSubmit()" novalidate>
        <button
          *ngIf="!isFinalStep()"
          id="hiscox-quote-form-next-button"
          [ngClass]="{
            button: true,
            'js-step-forward-button': true,
            button__discouraged: !this.isCurrentStepValid()
          }"
          type="submit"
          class="button__primary"
          [disabled]="
            formService.currentStep.slug !== 'working-with-hiscox' &&
            (!acceptedAgreementQuestions() || paymentIframeLoading)
          "
        >
          <span *ngIf="!paymentIframeLoading">Next</span>
          <span *ngIf="paymentIframeLoading">Loading...</span>
        </button>
      </form>
      <ng-container *ngIf="formService.currentStep.slug === 'working-with-hiscox'">
        <app-form-field-checkbox
          inputId="doNotShowAgain"
          labelText="Don't show me this again"
          [form]="form.get('workingWithHiscox')"
          nameOfFormControl="doNotShowAgain"
          cssClass="form-field__no-margin ml-3"
        ></app-form-field-checkbox>
      </ng-container>
    </div>
  </div>
</div>

<app-quote-error-modal
  [open]="openPaymentErrorModal"
  (closeQuoteErrorModal)="handleQuotePaymentResultModalClose($event)"
  [errors]="quotePaymentErrors"
  [errorType]="getPaymentErrorType()"
>
  <ng-container *ngIf="!hasDisplayableQuotePaymentErrors()">
    <p class="js-hiscox-contact-team">
      We're sorry, there was an issue with this quote. Please contact our Customer Care team so that
      we can help finish your quote.
    </p>
  </ng-container>
  <ng-container *ngIf="hasDisplayableQuotePaymentErrors()">
    <p class="js-hiscox-retry-quote-payment">
      We're sorry, there was an issue processing your request. Please retry your quote or contact
      our Customer Care team.
    </p>
  </ng-container>
</app-quote-error-modal>

<app-quote-error-modal
  [open]="openCreditCardErrorModal"
  (closeQuoteErrorModal)="handleCreditCardResultModalClose($event)"
  [errors]="quoteCreditCardErrors"
  [errorType]="getCreditCardErrorType()"
>
  <ng-container *ngIf="!hasDisplayableCrediCardErrors()">
    <p class="js-hiscox-contact-team">
      We're sorry, there was an issue with this quote. Please contact our Customer Care team so that
      we can help finish your quote.
    </p>
  </ng-container>
  <ng-container *ngIf="hasDisplayableCrediCardErrors()">
    <p class="js-hiscox-retry-credit-card">
      We're sorry, there was an issue processing your request. Please retry your quote or contact
      our Customer Care team.
    </p>
  </ng-container>
</app-quote-error-modal>

<app-quote-error-modal
  [open]="openBindErrorModal"
  (closeQuoteErrorModal)="handleQuoteBindResultModalClose($event)"
  [errors]="quoteBindErrors"
  [errorType]="getBindErrorType()"
>
  <ng-container *ngIf="!hasDisplayableQuoteBindErrors()">
    <p class="js-hiscox-contact-team">
      We're sorry, there was an issue with this quote. Please contact our Customer Care team so that
      we can help finish your quote.
    </p>
  </ng-container>
  <ng-container *ngIf="hasDisplayableQuoteBindErrors()">
    <p class="js-hiscox-retry-quote-bind">
      We're sorry, there was an issue processing your request. Please retry your quote or contact
      our Customer Care team.
    </p>
  </ng-container>
</app-quote-error-modal>

<ng-container *ngIf="showProgressBar">
  <app-binding-animation [showProgressBar]="true" [finish$]="bindSuccess$"> </app-binding-animation>
</ng-container>
