import { of as observableOf, Observable, Subject } from 'rxjs';
import { map, catchError, tap, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  POLICY_DOCS_API_URI,
  QUOTE_API_URI,
  QUOTE_SUBMISSION_API_URI,
} from 'app/workers-comp/employers/constants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { WcQuotePayload } from 'app/workers-comp/employers/models/wc-policy';
import {
  WcPricedResponse,
  WcQuoteWithDocuments,
  WcPolicyDocument,
  WcPolicyDocumentsResponse,
} from 'app/workers-comp/employers/models/wc-priced-quote';
import { WcBindPayload } from 'app/workers-comp/employers/models/wc-bind-policy';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';

@Injectable()
export class WcQuoteService {
  constructor(
    private http: HttpClient,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService
  ) {}

  cachePayload: Observable<any>;
  cachedAccountId: string | null;
  quotedThisSession: string[] = [];
  isEdited$: Subject<boolean> = new Subject<boolean>();

  requestQuote(wcPolicy: WcQuotePayload, isEdit: boolean): Observable<any> {
    this.cachebust();
    let method = 'post';
    let url = QUOTE_API_URI;

    if (isEdit) {
      method = 'put';
      url = QUOTE_API_URI + `/${wcPolicy.uuid}`;
    }

    return this.http
      .request(method, url, {
        body: wcPolicy,
      })
      .pipe(
        tap((_result: any) => {
          this.quotedThisSession.push(wcPolicy.account.guidewireAccountId);
        }),
        catchError((error: HttpErrorResponse) => {
          this.sentryService.notify('Unable to request/edit WC quote.', {
            severity: 'error',
            metaData: {
              wcPolicy,
              isEdit,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf({
            error: error,
            status: error.status,
          });
        })
      );
  }

  fetchQuote(accountId: string): Observable<WcQuoteWithDocuments | null> {
    return this.http
      .get<WcPricedResponse>(QUOTE_API_URI, {
        params: {
          legacy_insured_account_id: accountId,
        },
      })
      .pipe(
        map((payload: WcPricedResponse) => {
          const quote = payload.quotes[0];
          if (
            quote &&
            quote.status === 'PENDING_REFER' &&
            this.quotedThisSession.includes(quote.legacyInsuredAccountId)
          ) {
            quote.justReferred = true;
            this.quotedThisSession = this.quotedThisSession.filter(
              (el) => el !== quote.legacyInsuredAccountId
            );
          }

          return { quote: quote || null, documents: payload.documents };
        }),
        catchError((error) => {
          if (error) {
            this.sentryService.notify('Unable to fetch WC quote by accountId.', {
              severity: 'error',
              metaData: {
                legacy_insured_account_id: accountId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
          }
          throw error;
        })
      );
  }

  getPolicyDocuments(quoteUuid: string): Observable<WcPolicyDocument[] | null> {
    return this.http.get<WcPolicyDocumentsResponse>(POLICY_DOCS_API_URI(quoteUuid)).pipe(
      map((payload: WcPolicyDocumentsResponse) => {
        if (payload.success) {
          return payload.documents;
        }
        return null;
      }),
      catchError((error) => {
        if (error) {
          this.sentryService.notify('Unable to fetch WC policy documents by uuid.', {
            severity: 'error',
            metaData: {
              quoteUuid: quoteUuid,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
        }
        throw error;
      })
    );
  }

  getQuote(accountId: string): Observable<WcQuoteWithDocuments> {
    if (this.cachedAccountId === accountId) {
      return this.cachePayload;
    }
    this.cachedAccountId = accountId;
    return (this.cachePayload = this.fetchQuote(accountId).pipe(
      tap((quote: WcQuoteWithDocuments) => {
        this.amplitudeService.setWcPolicyDetails(quote);
      }),
      shareReplay(1)
    ));
  }

  getQuoteSubmission(accountId: string): Observable<any> {
    return this.http
      .get(QUOTE_SUBMISSION_API_URI, {
        params: {
          legacy_insured_account_id: accountId,
        },
      })
      .pipe(
        map((payload) => {
          return payload;
        }),
        catchError((error) => {
          this.sentryService.notify('Unable to get the WC quote submission by accountId.', {
            severity: 'error',
            metaData: {
              legacy_insured_account_id: accountId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf({
            error: error,
            status: error.status,
          });
        })
      );
  }

  bindQuote(quoteUuid: string, wcBindPolicy: WcBindPayload): Observable<any> {
    this.cachebust();
    const bindQuoteUri = QUOTE_API_URI + `/${quoteUuid}/bind`;

    return this.http.post(bindQuoteUri, wcBindPolicy).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to bind the WC quote.', {
          severity: 'error',
          metaData: {
            quoteUuid,
            wcBindPolicy,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf({
          error: error,
          status: error.status,
        });
      })
    );
  }

  referQuote(quoteUuid: string): Observable<any> {
    this.cachebust();
    const referQuoteUri = QUOTE_API_URI + `/${quoteUuid}/refer`;

    return this.http
      .post(referQuoteUri, '', {
        headers: { 'Content-type': 'application/json' },
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to refer the WC quote.', {
            severity: 'error',
            metaData: {
              quoteUuid,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf({
            error: error,
            status: error.status,
          });
        })
      );
  }

  cachebust() {
    this.cachedAccountId = null;
  }
}
