import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EMP_CONSTRUCTION_TYPE_OPTIONS } from 'app/workers-comp/employers/constants';
import { getControl, shouldShowInvalid } from 'app/shared/helpers/form-helpers';
import { WcQuoteFormService } from 'app/workers-comp/employers/services/workers-comp-form.service';

@Component({
  selector: 'app-wc-location-form',
  templateUrl: './wc-location-form.component.html',
})
export class WcLocationFormComponent {
  @Input() locationIndex: number;
  @Input() locationFormGroup: UntypedFormGroup;
  @Input() submitted: boolean;

  executivesIncludedOptions = {
    Excluded: false,
    Included: true,
  };
  executiveQuestionOptions = {
    Yes: true,
    No: false,
  };
  constructionTypeOptions = EMP_CONSTRUCTION_TYPE_OPTIONS;
  getControl = getControl;

  constructor(public formService: WcQuoteFormService) {}

  fieldInvalid(field: string): boolean | undefined {
    // Check that the control exists in the locationFormGroup
    if (this.locationFormGroup.get(field)) {
      return shouldShowInvalid(field, this.locationFormGroup, this.submitted);
    } else {
      return shouldShowInvalid(
        field,
        <UntypedFormGroup>this.locationFormGroup.get('manyEmployees'),
        this.submitted
      );
    }
  }
}
