<section class="invoice-top-metadata-section" *ngIf="showScheduleOfInvoices">
  <section class="invoice-metadata-card">
    <h4 class="invoice-metadata-title">Annual amount</h4>

    <ng-container *ngIf="isLoading">
      <div class="invoice-loading-placeholder invoice-metadata-body"></div>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="!accountSummary">
        <p class="invoice-metadata-body">Unavailable</p>
      </ng-container>

      <ng-container *ngIf="accountSummary && policyTermDetails.length === 0">
        <p class="invoice-metadata-body">N/A</p>
      </ng-container>

      <ng-container *ngFor="let policyTerm of policyTermDetails">
        <p class="invoice-metadata-body">
          <strong>{{ policyTerm.lineOfBusiness }}:</strong> {{ policyTerm.totalValue | currency }}
        </p>
      </ng-container>
    </ng-container>
  </section>

  <section class="invoice-metadata-card">
    <h4 class="invoice-metadata-title">
      Payment Plan{{ policyTermDetails && policyTermDetails.length > 1 ? 's' : '' }}
    </h4>

    <ng-container *ngIf="isLoading">
      <div class="invoice-loading-placeholder invoice-metadata-body"></div>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="!accountSummary">
        <p class="invoice-metadata-body">Unavailable</p>
      </ng-container>

      <ng-container *ngIf="accountSummary && policyTermDetails.length === 0">
        <p class="invoice-metadata-body">N/A</p>
      </ng-container>

      <ng-container *ngFor="let policyTerm of policyTermDetails">
        <p class="invoice-metadata-body">
          <strong>{{ policyTerm.lineOfBusiness }}:</strong> {{ policyTerm.paymentPlan }}
        </p>
      </ng-container>
    </ng-container>
  </section>

  <section class="invoice-metadata-card print-hidden">
    <h4 class="invoice-metadata-title">Schedule of invoices</h4>

    <ng-container *ngIf="isLoading">
      <div class="invoice-loading-placeholder invoice-metadata-body"></div>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <a
        *ngIf="!isLoadingScheduleOfInvoices"
        (click)="downloadScheduleOfInvoices()"
        (keyup.enter)="downloadScheduleOfInvoices()"
        class="invoice-metadata-body js-download-schedule-link"
      >
        Click here to download
      </a>
      <p *ngIf="isLoadingScheduleOfInvoices" class="invoice-metadata-body js-schedule-load-text">
        <span class="invoice-metadata-text">Please wait...</span>
        <app-loading-spinner class="invoice-loading-spinner"></app-loading-spinner>
      </p>
    </ng-container>
  </section>

  <section class="invoice-metadata-card print-only">
    <h4 class="invoice-metadata-title">Autopay Status</h4>
    <ng-container *ngIf="autopayStatus">
      <p class="invoice-metadata-body">Enrolled</p>
    </ng-container>
    <ng-container *ngIf="!autopayStatus">
      <p class="invoice-metadata-body">Not Enrolled</p>
    </ng-container>
  </section>
</section>
