import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { RecentActivitySummaryService } from '../../../../shared/services/recent-activity-summary.service';
import {
  BOOLEAN_FLAG_NAMES,
  FeatureFlagService,
} from '../../../../core/services/feature-flag.service';

import * as moment from 'moment';

@Component({
  selector: 'app-activity-incentive-streaks',
  templateUrl: 'activity-incentive-streaks.component.html',
})
export class ActivityIncentiveStreaksComponent implements OnInit, OnDestroy {
  constructor(
    private ref: ChangeDetectorRef,
    private recentActivitySummaryService: RecentActivitySummaryService,
    private featureFlagService: FeatureFlagService
  ) {}

  private sub: Subscription = new Subscription();
  isStreaksEnabled = false;
  streakLength: number;
  streakDeadline: string;
  currentYear = moment().format('YYYY').toString();
  currentMonth = moment().format('MM').toString();
  calendarStyles: { date: string; count: string; style: string }[] = Array.from(
    { length: 12 },
    (_, i) => {
      const monthString = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
      return {
        date: `${this.currentYear}-${monthString}`,
        count: '',
        style: this.getCssClassForCalendar(monthString),
      };
    }
  );

  ngOnInit() {
    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.STREAKS_UI_ENABLED)
        .subscribe((value) => {
          this.isStreaksEnabled = value || false;

          if (this.isStreaksEnabled) {
            this.sub.add(
              this.recentActivitySummaryService
                .getRecentBindsForStreaks()
                .subscribe((recentBindsData) => {
                  this.streakLength = recentBindsData.stats.streakLength;
                  this.streakDeadline = recentBindsData.stats.streakDeadline;
                  recentBindsData.bindsPerMonth.forEach((monthlyBinds) => {
                    const monthDateArray = monthlyBinds.month.split('-');
                    const monthIndex = Number(monthDateArray[1]) - 1;
                    if (monthDateArray[0] === this.currentYear) {
                      this.calendarStyles[monthIndex] = {
                        ...this.calendarStyles[monthIndex],
                        count: monthlyBinds.count,
                      };
                    }
                  });
                  this.ref.detectChanges();
                })
            );
          }
        })
    );
  }

  getCssClassForCalendar(month: string) {
    if (Number(month) > Number(this.currentMonth)) {
      return 'future-streak-month';
    } else if (Number(month) === Number(this.currentMonth)) {
      return 'current-streak-month';
    } else {
      return 'past-streak-month';
    }
  }

  getToolTipTextForMonth(count: string, month: string) {
    const longMonth = moment(month).format('MMMM').toString();
    if (Number(count) === 1) {
      return `${count} bind in ${longMonth} ${this.currentYear}`;
    } else if (Number(count) > 1) {
      return `${count} binds in ${longMonth} ${this.currentYear}`;
    } else {
      return `No binds in ${longMonth} ${this.currentYear}`;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
