import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  FORGOT_PASSWORD_API_URI,
  RESET_PASSWORD_API_URI,
  SET_PASSWORD_API_URI,
} from 'app/constants';

import { SentryService } from 'app/core/services/sentry.service';

@Injectable()
export class ResetPasswordService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  forgotPassword(email: string): Observable<boolean> {
    return this.http
      .post<any>(FORGOT_PASSWORD_API_URI, JSON.stringify({ email: email }), {
        headers: { 'Content-type': 'application/json' },
      })
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Error initiating password reset.', {
            severity: 'warning',
            metaData: {
              email,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  resetPassword(email: string, password: string, recoveryToken: string): Observable<boolean> {
    return this.http
      .post<any>(
        RESET_PASSWORD_API_URI,
        JSON.stringify({
          email: email,
          password: password,
          recoveryToken: recoveryToken,
        }),
        {
          headers: { 'Content-type': 'application/json' },
        }
      )
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify("Error resetting user's password.", {
            severity: 'warning',
            metaData: {
              email,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  setPassword(email: string, password: string, recoveryToken: string): Observable<boolean> {
    return this.http
      .post<any>(
        SET_PASSWORD_API_URI,
        JSON.stringify({
          email: email,
          password: password,
          recoveryToken: recoveryToken,
        }),
        {
          headers: { 'Content-type': 'application/json' },
        }
      )
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify("Error setting user's password", {
            severity: 'warning',
            metaData: {
              email,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }
}
