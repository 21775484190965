<div class="policy-pane-content">
  <div class="policy-pane-nav">
    <a class="button button__nav button__no-margin" [routerLink]="['../../..']">
      <span class="icon-arrow-left button-icon"></span>
      Back
    </a>

    <ng-container *ngIf="model | async as loadedModel">
      <div class="policy-pane-nav-buttons">
        <ng-container *ngIf="loadedModel.bindable">
          <a
            class="button button__small button__primary button__no-margin js-go-to-bind"
            [routerLink]="['bind']"
            >Continue to bind</a
          >
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!(model | async)">
    <div class="placeholder-h1"></div>
    <ul>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
    </ul>
  </ng-container>

  <ng-container *ngIf="model | async as loadedModel">
    <ng-container *ngIf="loadedModel.status !== 'Draft'">
      <div class="policy-pane-header">
        <div class="policy-pane-header-left">
          <div class="product-badge product-badge__hab"></div>
          <div class="policy-pane-quote-info">
            <h2 class="policy-pane-header-h2">Quote details</h2>
            <small class="gray-text">No. {{ loadedModel.id }}</small>
          </div>
        </div>
        <div class="policy-pane-header-right">
          <h2 class="policy-pane-header-h2">{{ formatCost(loadedModel.totalCost) }}</h2>
          <small class="gray-text">Premium</small>
        </div>
      </div>

      <dl>
        <dt>Effective date</dt>
        <dd>{{ loadedModel.policyStart | date: 'mediumDate':'UTC' }}</dd>
      </dl>
      <dl>
        <dt>Expiration date</dt>
        <dd>{{ loadedModel.policyEnd | date: 'mediumDate':'UTC' }}</dd>
      </dl>

      <div *ngIf="isDevMode">
        <div class="adminz-only">
          <pre>{{ loadedModel | json }}</pre>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="loadedModel.status === 'Draft'">
      <div class="policy-pane-header">
        <div class="policy-pane-header-left">
          <div class="product-badge product-badge__hab"></div>
          <div class="policy-pane-quote-info">
            <h2 class="policy-pane-header-h2">Declined quote details</h2>
            <small class="gray-text">No. {{ loadedModel.id }}</small>
          </div>
        </div>
        <div class="policy-pane-header-right">
          <h2 class="policy-pane-header-h2 js-policy-premium">N/A</h2>
          <small class="gray-text">Premium</small>
        </div>
      </div>
      <div
        class="dialog-text dialog-text__error dialog-text__full-width policy-issues"
        *ngFor="let issue of loadedModel.uwIssues"
      >
        {{ issue }}
      </div>
      <div class="policy-pane-date">
        <div class="policy-pane-date__left">Effective Date</div>
        <div class="policy-pane-date__right">
          {{ loadedModel.policyStart | date: 'mediumDate':'UTC' }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
