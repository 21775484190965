<div class="landing-header" role="presentation"></div>
<section class="landing-section">
  <!-- Placeholder -->
  <ng-container *ngIf="isLoadingResponseInfo" class="landing-wrapper">
    <div class="landing-header-content">
      <app-logo-with-text [darkText]="false"></app-logo-with-text>
      <div class="landing-account-info">
        <p class="p p__landing-header landing-loading-placeholder"></p>
        <p class="p p__landing-header landing-loading-placeholder"></p>
      </div>
    </div>
    <h1 class="landing-h1 landing-h1__info"></h1>
    <div class="landing-description-container">
      <p class="landing-description-placeholder"></p>
      <p class="landing-description-placeholder"></p>
      <p class="landing-description-placeholder"></p>
      <p class="landing-description-placeholder"></p>
    </div>
  </ng-container>

  <!-- Loaded content -->
  <ng-container *ngIf="!isLoadingResponseInfo">
    <div class="landing-header-content">
      <app-logo-with-text [darkText]="false"></app-logo-with-text>
      <div class="landing-account-info">
        <p *ngIf="loadedResponseInfo" class="p p__landing-header mb-0">
          Acct # {{ loadedResponseInfo.accountNumber }}
        </p>
        <p *ngIf="loadedResponseInfo" class="p p__landing-header">
          Policy # {{ loadedResponseInfo.policyNumber }}
        </p>
      </div>
    </div>
    <ng-container *ngIf="isError()">
      <h1 class="landing-h1 landing-h1__dialog">{{ getErrorPageHeaderText() }}</h1>
      <app-dialog-box
        type="danger"
        class="dialog-text__margin-bottom dialog-text__full-width js-request-error"
        [header]="getErrorDialogHeaderText()"
        [dismissable]="false"
      >
        {{ getErrorMessageText() }}
      </app-dialog-box>
      <div class="landing-card">
        <h2 class="mt-0">Customer Care Team</h2>
        <p>Our Customer Care Team is here for you</p>
        <p>
          Monday through Friday from {{ customerCareHours.start }} to {{ customerCareHours.end }}
          {{ customerCareHours.timezone }}.
        </p>
        <p class="mb-0">Phone <a href="tel:18885304650">1-888-530-4650</a></p>
      </div>
    </ng-container>
    <ng-container *ngIf="isWithdrawn()">
      <h1 class="landing-h1 landing-h1__dialog">Request Withdrawn</h1>
      <app-dialog-box
        type="success"
        class="dialog-text__margin-bottom dialog-text__full-width js-request-withdraw"
        header="We've withdrawn your request"
        [dismissable]="false"
      >
        To get back in touch, reply to the email that led you to this page or get in touch using the
        contact information below.
      </app-dialog-box>
      <div class="landing-card">
        <h2 class="mt-0">Customer Care Team</h2>
        <p>Our Customer Care Team is here for you</p>
        <p>
          Monday through Friday from {{ customerCareHours.start }} to {{ customerCareHours.end }}
          {{ customerCareHours.timezone }}.
        </p>
        <p class="mb-0">Phone <a href="tel:18885304650">1-888-530-4650</a></p>
      </div>
    </ng-container>
    <app-endorse-landing
      class="js-endorse-component"
      *ngIf="isEndorsement() && !isError() && !isWithdrawn()"
      [linkStatus]="linkStatus"
      [linkId]="linkId"
      [endorsementResponseInfo]="loadedResponseInfo"
      [emailAddress]="requesterEmail"
      (statusChange)="handleStatusChange($event)"
    ></app-endorse-landing>
    <app-cancel-landing
      class="js-cancel-component"
      *ngIf="isCancellation() && !isError() && !isWithdrawn()"
      [linkStatus]="linkStatus"
      [linkId]="linkId"
      [cancelResponseInfo]="loadedResponseInfo"
      [emailAddress]="requesterEmail"
      (statusChange)="handleStatusChange($event)"
    >
    </app-cancel-landing>
  </ng-container>
</section>

<app-footer showPhoneNumber="true" isWhiteBackground="true"></app-footer>
