// Libraries
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-field-toggle-switch',
  templateUrl: './form-field-toggle-switch.component.html',
})
export class FormFieldToggleSwitchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputId: string;
  @Input() tooltipText: string;
  @Input() toggleChecked: boolean;
  @Output() toggled = new EventEmitter<boolean>();

  toggleForm = new UntypedFormControl();
  private sub = new Subscription();

  ngOnInit() {
    this.toggleForm.setValue(this.toggleChecked);
    this.sub.add(
      this.toggleForm.valueChanges.subscribe((value) => {
        this.toggleForm.setValue(!value, { emitEvent: false });
        this.toggled.emit(value);
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.toggleChecked) {
      this.toggleForm.setValue(changes.toggleChecked.currentValue, { emitEvent: false });
    }
  }
}
