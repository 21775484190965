<ng-container *ngFor="let category of categories">
  <h3 id="reward-type" class="h3">
    {{ category }}
  </h3>
  <ul class="reward-checklist-container" aria-labelledby="reward-type">
    <ng-container *ngFor="let achievement of achievements | keyvalue">
      <ng-container *ngIf="achievement.value.category === category">
        <ng-container *ngIf="isRewardedChecklist(achievement.key)">
          <li>
            <span class="pill-tag pill-tag__success"
              >{{ achievement.value.points }} <span class="sr-only"> points</span
              ><img
                class="rewards-page-check"
                src="/assets/img/circle_check.svg"
                alt="check" /></span
            >&nbsp;&nbsp;<span class="rewards-item-completed gray-text">
              {{ achievement.value.text }}</span
            >
          </li>
        </ng-container>
        <ng-container *ngIf="!isRewardedChecklist(achievement.key)">
          <li>
            <span class="pill-tag pill-tag__warning"
              >{{ achievement.value.points }} <span class="sr-only"> points</span
              ><img class="rewards-page-coin" src="/assets/img/coin.svg" alt="coin"
            /></span>
            {{ achievement.value.text }}
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</ng-container>
