import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { V4_USER_ATTRIBUTES_API_URL } from '../../constants';
import { catchError } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { SentryService } from './sentry.service';
import { UserAttributes, UserAttributesResponse } from '../../shared/models/user-attributes';

@Injectable()
export class UserAttributesService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  public updateUserAttribute(
    attributeType: UserAttributes,
    attributeValue: string
  ): Observable<ErrorResponse | null> {
    const payload = { attributeType, attributeValue };
    return this.http.post<ErrorResponse | null>(V4_USER_ATTRIBUTES_API_URL, payload).pipe(
      catchError((error: any) => {
        this.sentryService.notify('User Attributes: Error updating user attribute', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  public getUserAttribute(): Observable<UserAttributesResponse> {
    return this.http.get<UserAttributesResponse>(V4_USER_ATTRIBUTES_API_URL).pipe(
      catchError((error: any) => {
        this.sentryService.notify('User Attributes: Error getting user attribute', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf({ userAttributes: [] });
      })
    );
  }
}
