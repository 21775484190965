import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { DOMAIN_AUTH_ALLOWLIST, FULLSTORY_HEADER_ALLOWLIST } from 'app/constants';
import { Observable } from 'rxjs';
import * as URLParse from 'url-parse';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { FullstoryService } from 'app/core/services/fullstory.service';
@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private currentUserService: CurrentUserService,
    private fullstoryService: FullstoryService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const parsedURL: URLParse<string> = new URLParse(req.url);

    let ddUrl;
    if ((<any>window).DD_RUM) {
      ddUrl = (<any>window).DD_RUM.getSessionReplayLink();
    }

    if (
      ddUrl &&
      req.url &&
      FULLSTORY_HEADER_ALLOWLIST.some((allowedPath) => req.url.startsWith(allowedPath))
    ) {
      // TODO(WJC): change this to a more appropriate header name
      req = req.clone({
        setHeaders: { 'x-fullstory-sessionurl': ddUrl },
      });
    }

    if (DOMAIN_AUTH_ALLOWLIST.includes(parsedURL.hostname)) {
      const currentUser = this.currentUserService.getCurrentUser();
      if (currentUser && currentUser.token) {
        req = req.clone({
          setHeaders: { 'X-AUTH-TOKEN': currentUser.token },
        });
      }
    }

    return next.handle(req);
  }
}
