import { Injectable } from '@angular/core';
import { SentryService } from './sentry.service';
import { HttpClient } from '@angular/common/http';
import { USER_API_URL, GET_FINANCE_ADMIN_LINK_MAIN_API_URI } from 'app/constants';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserListResponse, UserGroupsResponse, UserInfo } from '../../shared/consumer/typings';

@Injectable()
export class UserApiService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getUserGroups(): Observable<UserGroupsResponse> {
    return this.http.get<UserGroupsResponse>(`${USER_API_URL}/groups/list-users`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve user groups.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getCurrentGroups(): Observable<{ result: string[] }> {
    return this.http.get<{ result: string[] }>(`${USER_API_URL}/groups/current`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve current user groups.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getCurrentUser(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${USER_API_URL}/current`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve current user.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getUsers(): Observable<UserListResponse> {
    return this.http.get<UserListResponse>(`${USER_API_URL}/list`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve user list.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  addAdminStatus(userName: string) {
    return this.http.put<any>(`${USER_API_URL}/${userName}/groups/user-admin`, {}).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to add admin role.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  removeAdminStatus(userName: string) {
    return this.http.delete<any>(`${USER_API_URL}/${userName}/groups/user-admin`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to remove admin role.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getFinanceAdminLinkMain(): Observable<any> {
    return this.http
      .get<any>(GET_FINANCE_ADMIN_LINK_MAIN_API_URI, {
        headers: { 'Content-Type': 'application/json' },
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Issue retrieving main finance admin link', {
            severity: 'warning',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }
}
