import { catchError, first } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { interval, Observable, of as observableOf, Subscription, timer } from 'rxjs';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SentryService } from 'app/core/services/sentry.service';
import { OKTA_BASE, OKTA_ISSUER_URI, PROMO_S3_URI } from 'app/constants';
import { DomSanitizer } from '@angular/platform-browser';
import OktaSignIn from '@okta/okta-signin-widget';
import { environment } from 'environments/environment';
import { AlertService } from '../../core/services/alert.service';
import { LocalStorageService } from '../../core/services/local-storage.service';

@Component({
  selector: 'app-login.app-page.app-page__login',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  loading = false;
  error = '';
  signIn: OktaSignIn;
  CLIENT_ID = environment.oktaClientId;
  ISSUER = OKTA_ISSUER_URI;
  LOGIN_REDIRECT_URI = environment.oktaLoginRedirectURI;
  LOGOUT_REDIRECT_URI = environment.oktaLogoutRedirectURI;

  private sub: Subscription = new Subscription();
  private startTime: Date | null = null;
  private hasLoggedSpinnerTimeout = false;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private amplitudeService: AmplitudeService,
    private location: Location,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private sentryService: SentryService,
    private alertService: AlertService,
    private localStorage: LocalStorageService
  ) {}

  public response: string | null = null;

  private fetchGist(): Observable<string | null> {
    function createDestinationNode(content: string) {
      const destinationNode = document.createElement('div');
      destinationNode.id = 'promo-wrapper';
      destinationNode.innerHTML = content;
      return destinationNode;
    }

    const responseObs: Observable<string | null> = this.http
      .get<string | null>(PROMO_S3_URI, {
        headers: new HttpHeaders({}),
        responseType: 'text' as 'json',
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to fetch promo.', {
            severity: 'info',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );

    responseObs.pipe(first()).subscribe((response: string | null) => {
      const sanitizedText = this.sanitizer.sanitize(SecurityContext.HTML, response);
      // make sure resp is successful
      if (sanitizedText && sanitizedText.includes('login-promo-container')) {
        const teleport = createDestinationNode(sanitizedText.toString());
        const widget = document.getElementById('promo-widget');
        if (widget && widget.parentNode) {
          widget.parentNode.insertBefore(teleport, widget);
        }
      }
    });

    return responseObs;
  }

  ngOnInit() {
    // Note: this page gets hit when a user logs out & in... probably bad event...
    this.amplitudeService.track({ eventName: 'logout', detail: '', useLegacyEventName: true });

    // reset login status
    this.authenticationService.logout();

    // In some cases, we have seen Okta login errors causing the spinner to hang around indefinitely
    // If it has been spinning for more than 30 seconds, it is safe to say something is going wrong
    this.sub.add(
      interval(5000).subscribe(() => {
        const hasLoadingSpinner = !!document.querySelector(
          '#okta-widget-container .beacon-loading'
        );

        if (hasLoadingSpinner) {
          if (this.startTime) {
            if (
              !this.hasLoggedSpinnerTimeout &&
              new Date().valueOf() - this.startTime.valueOf() > 30_000
            ) {
              this.sentryService.notify('Waited on Okta login spinner for more than 30 seconds', {
                metaData: {
                  userName: this.getUsername(),
                },
              });
              this.hasLoggedSpinnerTimeout = true;
            }
          } else {
            this.startTime = new Date();
          }
        } else if (this.startTime) {
          // Set startTime to null if we've previously set startTime but no longer see the spinner
          this.startTime = null;
        }
      })
    );

    // Load promo widget
    this.fetchGist();
    this.initOkta();
  }

  getUsername() {
    const input = document.getElementById('okta-signin-username') as HTMLInputElement;
    return input ? input.value : '';
  }

  async initOkta() {
    this.signIn = new OktaSignIn({
      authParams: {
        pkce: false,
      },
      baseUrl: OKTA_BASE,
      i18n: {
        en: {
          'primaryauth.username.title': 'title',
          'primaryauth.username.explanation': 'explanation',
          'primaryauth.username.caption': 'caption',
          'primaryauth.username.header': 'header',
          'primaryauth.username': 'username itself',
        },
      },
    });
    this.signIn.on('afterError', (context: any, err: any) => {
      this.amplitudeService.track({
        eventName: 'login_failure',
        detail: this.getUsername(),
        useLegacyEventName: true,
      });
    });

    this.signIn.on('afterRender', () => {
      const usernameInputLabel = document.querySelector(
        '#okta-widget-container .o-form-fieldset > .o-form-label > label'
      );
      if (usernameInputLabel && usernameInputLabel.textContent) {
        if (usernameInputLabel.textContent.trim() === 'Username') {
          usernameInputLabel.textContent = 'Email';
        } else if (usernameInputLabel.textContent.trim() !== 'Email') {
          this.sentryService.notify('Okta login username input label had unexpected text content', {
            severity: 'error',
            metaData: {
              actualContent: usernameInputLabel.textContent,
            },
          });
        }
      } else {
        this.sentryService.notify('Could not locate Okta login username input label');
      }
    });

    this.prepareOktaRedirect();
  }

  async prepareOktaRedirect() {
    const redirectParam = this.route.snapshot.queryParamMap.get('redirect');
    const redirectState = redirectParam ? redirectParam.toString() : '';

    sessionStorage.setItem('okta-app-url', redirectState);

    // Adding a timer, as the element will take a little bit of time to prepare
    this.sub.add(
      timer(2000).subscribe(() => {
        // Necessary to manually add a listener here, as Okta does not offer hooks into submissions
        const submitButton = document.getElementById('okta-signin-submit');
        if (submitButton) {
          submitButton.addEventListener('click', () => {
            // reset Segment id/anonymousId/traits when new user logs in
            if (this.getUsername()) {
              if (
                (<any>window).analytics &&
                this.localStorage.getString('LAST_LOGGED_IN_USERNAME') &&
                this.localStorage.getString('LAST_LOGGED_IN_USERNAME') !== this.getUsername()
              ) {
                (<any>window).analytics.reset();
              }
              this.localStorage.setString('LAST_LOGGED_IN_USERNAME', this.getUsername());
            }
            this.amplitudeService.track({
              eventName: 'login_attempt',
              detail: this.getUsername(),
              useLegacyEventName: true,
            });
          });
        }
      })
    );

    await this.signIn.showSignInAndRedirect({
      el: '#okta-widget-container',
      clientId: this.CLIENT_ID,
      issuer: this.ISSUER,
      redirectUri: this.LOGIN_REDIRECT_URI,
      scopes: ['openid', 'profile'], // optional
    });
    // DO NOT place code after this await--it will not be executed
  }

  ngOnDestroy() {
    this.signIn.remove();
    this.sub.unsubscribe();
  }

  getLocationHref() {
    return this.location.path();
  }
}
