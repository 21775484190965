import { CARRIERS_ALLOWING_MULTIPLE_QUOTES, CARRIERS_ALLOWING_EDIT } from './constants';
import { FrontendQuote, InsurancePolicyAdminSource } from './types';
import { isArray } from 'lodash';

/**
 * As of 12/13/2021, LM CPSP quotes where the risk state is NY cannot be bound in AP.
 * In these cases, we will direct the user to request a bind (via ZD form) instead of the AP bind flow.
 */
export const isCpspNyRisk = (quote: FrontendQuote) => {
  if (
    quote.details &&
    Object.prototype.hasOwnProperty.call(quote.details, 'riskStates') &&
    isArray(quote.details.riskStates)
  ) {
    return (
      quote.status === 'quoted' &&
      quote.product === 'cpsp' &&
      quote.details.riskStates.includes('NY')
    );
  } else {
    return false;
  }
};

export const carrierAllowsMultipleQuotes = (carrier: InsurancePolicyAdminSource) => {
  return CARRIERS_ALLOWING_MULTIPLE_QUOTES.includes(carrier);
};

export const carrierAllowsEdit = (carrier: InsurancePolicyAdminSource) => {
  return CARRIERS_ALLOWING_EDIT.includes(carrier);
};
