<ng-container *ngIf="product">
  <div
    [attr.data-tooltip]="getToolTipText()"
    class="tool-tip"
    [ngClass]="TOOL_TIP_POSITION_TO_CLASS[tooltipPosition]"
  >
    <div
      role="img"
      [attr.aria-label]="getToolTipText() + ' product'"
      class="product-badge product-badge__{{ product }}"
    >
      <div
        *ngIf="pasSource"
        class="product-badge-carrier-icon product-badge-carrier-icon__{{ pasSource }}"
      ></div>
    </div>
  </div>
</ng-container>
