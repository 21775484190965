import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsStateService } from '../../../../shared/services/us-state.service';
import { User } from '../../../../shared/models/user';
import { getNaicsCodes } from 'app/shared/insured-account/insured-account-helpers';
import { combineLatest, Subscription } from 'rxjs';
import { UserService } from '../../../../core/services/user.service';
import { environment } from '../../../../../environments/environment';
import { ZendeskService } from '../../../../shared/services/zendesk.service';
import { NaicsService } from '../../../../shared/services/naics.service';
import { Router } from '@angular/router';
import { AppetiteProductEligibility } from '../../models/types';
import { getControl } from '../../../../shared/helpers/form-helpers';
import * as _ from 'lodash';
import {
  ProductAvailability,
  ProductCombinationForAvailability,
} from '../../../digital-carrier/models/types';
import {
  BOOLEAN_FLAG_NAMES,
  FeatureFlagService,
  JSON_FLAG_NAMES,
} from 'app/core/services/feature-flag.service';

@Component({
  selector: 'app-appetite-checker-page.app-page.app-page__appetite',
  templateUrl: 'appetite-checker-page.component.html',
})
export class AppetiteCheckerPageComponent implements OnInit {
  displayResults = false;
  eligibilityLiveChatProducers = environment.eligibilityLiveChatProducers;
  form: FormGroup;
  loading = false;
  showLiveChatWhenOnline = false;
  user: User;

  isEverpeakEnabled = false;
  attuneWcEnabledStates: string[];

  productAvailabities: AppetiteProductEligibility[] = [];
  private sub: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private naicsService: NaicsService,
    private router: Router,
    private userService: UserService,
    public usStateService: UsStateService,
    private zendeskService: ZendeskService,
    private featureFlagService: FeatureFlagService
  ) {
    this.form = this.formBuilder.group({
      naicsCode: [null, Validators.required],
      state: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.user = user;
        if (this.eligibilityLiveChatProducers.includes(this.user.producer)) {
          this.showLiveChatWhenOnline = true;
        }
      })
    );

    const naicsControl = getControl(this.form, 'naicsCode');
    const stateControl = getControl(this.form, 'state');
    const naicsControl$ = naicsControl.valueChanges;
    const stateControl$ = stateControl.valueChanges;

    this.sub.add(
      combineLatest([naicsControl$, stateControl$]).subscribe(([naics, state]) => {
        this.displayResults = false;
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP)
        .subscribe((enabled) => (this.isEverpeakEnabled = enabled || false))
    );

    this.sub.add(
      this.featureFlagService
        .getJsonFlagValue<string[]>(JSON_FLAG_NAMES.ATTUNE_WC_ENABLED_STATES)
        .subscribe((attuneWcEnabledStates) => {
          this.attuneWcEnabledStates = attuneWcEnabledStates || [];
        })
    );
  }

  checkChatAvailable() {
    if (!this.showLiveChatWhenOnline) {
      return false;
    }
    if (!this.zendeskService.ready()) {
      return false;
    }
    return this.zendeskService.isChatLive();
  }

  getNaicsCodes = (searchTerm: string) => {
    return getNaicsCodes(this.naicsService, searchTerm);
  };

  naicsFormatter(code: NaicsCode) {
    return code.description;
  }

  isProductAvailable(
    productAvailabilities: ProductAvailability[],
    productCombination: ProductCombinationForAvailability,
    naicsCode: string
  ) {
    const productAvailability = _.find(
      productAvailabilities,
      productCombination
    ) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    if (productAvailability.classCodeSelection === 'ALL') {
      return true;
    }

    return (
      productAvailability.classCodeSelection === 'PARTIAL' &&
      _.includes(productAvailability.classCodes.naics, naicsCode)
    );
  }

  getState() {
    return this.form.get('state')?.value;
  }

  submitForm() {
    const naicsCode = this.form.get('naicsCode')?.value;
    const state = this.form.get('state')?.value;

    this.productAvailabities = [];

    if (naicsCode && state) {
      this.loading = true;

      this.sub.add(
        combineLatest(
          this.naicsService.getProductEligibility(naicsCode.hash, state),
          this.naicsService.getProductAvailability()
        ).subscribe(([productEligibilities, productAvailabilities]) => {
          if (
            productEligibilities.isBopEligible &&
            this.isProductAvailable(
              productAvailabilities,
              {
                product: 'bop',
                pasSource: 'attune_gw',
              },
              naicsCode.code
            )
          ) {
            this.productAvailabities.push({
              isEligible: true,
              productCombination: {
                product: 'bop',
                pasSource: 'attune_gw',
              },
            });
          }

          if (
            productEligibilities.isCyberEligible &&
            this.isProductAvailable(
              productAvailabilities,
              {
                product: 'cyber_admitted',
                pasSource: 'coalition',
              },
              naicsCode.code
            )
          ) {
            this.productAvailabities.push({
              isEligible: true,
              productCombination: {
                product: 'cyber_admitted',
                pasSource: 'coalition',
              },
            });
          }

          if (
            productEligibilities.isGlEligible &&
            this.isProductAvailable(
              productAvailabilities,
              {
                product: 'gl',
                pasSource: 'hiscox',
              },
              naicsCode.code
            )
          ) {
            this.productAvailabities.push({
              isEligible: true,
              productCombination: {
                product: 'gl',
                pasSource: 'hiscox',
              },
            });
          }

          if (
            productEligibilities.isPlEligible &&
            this.isProductAvailable(
              productAvailabilities,
              {
                product: 'pl',
                pasSource: 'hiscox',
              },
              naicsCode.code
            )
          ) {
            this.productAvailabities.push({
              isEligible: true,
              productCombination: {
                product: 'pl',
                pasSource: 'hiscox',
              },
            });
          }

          let inAllowedAttuneWcState = true;
          if (state) {
            inAllowedAttuneWcState = this.attuneWcEnabledStates.includes(state);
          }

          const attuneWcAvailable =
            productEligibilities.isAttuneWcEligible &&
            this.isEverpeakEnabled &&
            inAllowedAttuneWcState;
          if (attuneWcAvailable) {
            this.productAvailabities.push({
              isEligible: true,
              productCombination: {
                product: 'wc',
                pasSource: 'attune_gw',
              },
            });
          }

          // Do not show Employers WC if Attune WC is eligibile and enabled.
          if (
            productEligibilities.isEmployersWcEligible &&
            this.isProductAvailable(
              productAvailabilities,
              {
                product: 'wc',
                pasSource: 'employers',
              },
              naicsCode.code
            ) &&
            !attuneWcAvailable
          ) {
            this.productAvailabities.push({
              isEligible: true,
              productCombination: {
                product: 'wc',
                pasSource: 'employers',
              },
            });
          }

          this.loading = false;
          this.displayResults = true;
        })
      );
    }
  }

  openChat() {
    const { firstName, lastName, userName } = this.user;
    const identity = {
      name: `${firstName} ${lastName}`,
      email: userName,
    };
    this.zendeskService.skipHelpCenter();
    this.zendeskService.widget.identify(identity);
    this.zendeskService.widget.addChatTags(['appetite-checker']);
    this.zendeskService.widget.open();
    const state = this.form.get('description')?.value;
    const automatedMessageContent = [] as string[];

    if (state) {
      automatedMessageContent.push(`State: ${state}`);
    }

    if (automatedMessageContent.length) {
      this.zendeskService.stageAutomaticMessage(automatedMessageContent.join('\n'));
    }

    this.zendeskService.unskipHelpCenter();
  }
}
