<div style="display: flex">
  <div class="app-page-form-side-rail">
    <b>Current Step FormDSL</b>
    <pre>{{ formTree | json }}</pre>
    <b>Angular Form</b>
    <pre>{{ formService.form.value | json }}</pre>
  </div>

  <div class="app-page-form" style="width: 49%; float: right; overflow: scroll">
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>

    <form [formGroup]="formService.form" (ngSubmit)="submitForm()" novalidate>
      <main>
        <div style="padding: 20px">
          <app-form-dsl-form
            [formTree]="formTree"
            [form]="formService.form.get('quoteBasic')"
            [submitted]="submitted()"
          >
          </app-form-dsl-form>
        </div>
      </main>

      <div class="form-footer">
        <div class="validation" role="alert" *ngIf="submitted() && !isCurrentStepValid()">
          <p class="validation-message">Please fill out all required fields</p>
        </div>
        <div class="nav-button-group nav-button-group__left">
          <button
            *ngIf="isFinalStep()"
            type="submit"
            class="button button__primary js-step-forward-button"
            [ngClass]="{ button__discouraged: !this.isCurrentStepValid() }"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="app-page-form-side-rail"></div>
</div>
