import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';
import { catchError, of as observableOf } from 'rxjs';
import { QuoteFeedbackForm } from '../helpers/quote-feedback-helpers';
import { PRODUCT_FEEDBACK_URL } from '../../constants';
import { ProductCombinationForAvailability } from '../../features/digital-carrier/models/types';
import { omit } from 'lodash';

export interface FrontendProductFeedbackPayload {
  product: ProductCombinationForAvailability['product'];
  pasSource: ProductCombinationForAvailability['pasSource'];
  feedbackForm: QuoteFeedbackForm;
  accountId: string;
  quoteUuid: string;
}

export interface QSProductFeedbackPayload
  extends Omit<FrontendProductFeedbackPayload, 'feedbackForm'> {
  productFeedback: {
    category: string;
    comment: string;
  }[];
}

@Injectable()
export class ProductFeedbackService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  submitProductFeedback(feedbackPayload: FrontendProductFeedbackPayload) {
    const backendPayload = this.createBackendPayload(feedbackPayload);
    return this.http.post(PRODUCT_FEEDBACK_URL, backendPayload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to submit product feedback', {
          severity: 'error',
          metaData: {
            feedbackPayload,
            accountId: feedbackPayload.accountId,
            underlyingErrorMessage: error && error.message,
          },
        });

        // Unused
        return observableOf(null);
      })
    );
  }

  private createBackendPayload(
    feedbackPayload: FrontendProductFeedbackPayload
  ): QSProductFeedbackPayload {
    const feedbackFormVal = feedbackPayload.feedbackForm.value;
    // We only want to store feedback for checked categories w/ a comment.
    const filteredProductFeedback = Object.entries(feedbackFormVal)
      .filter(([_key, value]) => {
        if (value.checkbox && value.comment) {
          return true;
        }
        return false;
      })
      .map(([key, value]) => {
        return {
          category: key,
          comment: value.comment as string,
        };
      });

    return {
      // replace quoteFeedback form group w/ backend-friendly array of feedback entries.
      ...omit(feedbackPayload, 'feedbackForm'),
      productFeedback: filteredProductFeedback,
    };
  }
}
