<ng-container *ngIf="isReviewStatus()">
  <h1 class="landing-h1 landing-h1__info">Review endorsement</h1>
  <div class="landing-container js-endorse-review">
    <h3>You must issue this endorsement below for the following change(s) to take effect:</h3>
    <p>Effective: {{ getEffectiveDate() }}</p>
    <p>{{ getEndorsementDescription() }}</p>
    <hr />
    <ng-container>
      <div>
        <dl class="dl__no-dotted-line">
          <dt>Original policy premium</dt>
          <dd>
            {{ getOriginalPremium() | currency: 'USD':'symbol':'1.2-2' }}
          </dd>
        </dl>
        <dl class="dl__no-dotted-line">
          <dt>Total premium change</dt>
          <dd>
            <span>{{ getPremiumChangeSign() }}</span>
            {{ getPremiumChanges() | currency: 'USD':'symbol':'1.2-2' }}
          </dd>
        </dl>
      </div>

      <div class="endorse-quote-total">
        <div class="endorse-quote-total-lineitem">
          <strong>New total premium</strong>
          <div class="endorse-total">
            <span>$</span>{{ getPremiumInteger() | number: '2.0-0' }}.<span
              class="endorse-total-cents"
              >{{ getPremiumDecimal() | number: '2.0-0' }}</span
            >
          </div>
        </div>
      </div>
    </ng-container>

    <div class="landing-buttons">
      <button
        class="button button__primary button__no-margin js-confirm-button"
        type="submit"
        (click)="confirmEndorsement()"
        [disabled]="isConfirmingEndorsement || isWithdrawingEndorsement"
      >
        <ng-container *ngIf="isConfirmingEndorsement">
          <div class="button-loader">
            <svg class="circle-spinner">
              <circle
                cx="11"
                cy="11"
                r="9"
                stroke-width="2"
                stroke-dasharray="0,1000"
                d="M5 40 l215 0"
                fill="transparent"
              />
            </svg>
          </div>
          Endorsing...
        </ng-container>
        <ng-container *ngIf="!isConfirmingEndorsement">Issue endorsement</ng-container>
      </button>
      <button
        class="button button__transparent button__no-margin landing-withdraw-button"
        (click)="withdrawEndorsement()"
        [disabled]="isConfirmingEndorsement || isWithdrawingEndorsement"
      >
        <ng-container *ngIf="isWithdrawingEndorsement">
          <div class="button-loader">
            <svg class="circle-spinner">
              <circle
                cx="11"
                cy="11"
                r="9"
                stroke-width="2"
                stroke-dasharray="0,1000"
                d="M5 40 l215 0"
                fill="transparent"
              />
            </svg>
          </div>
          Withdrawing...
        </ng-container>
        <ng-container *ngIf="!isWithdrawingEndorsement">Withdraw request</ng-container>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isConfirmStatus()">
  <h1 class="landing-h1 landing-h1__dialog">Confirm Endorsement</h1>
  <div class="landing-container js-endorse-confirm">
    <app-dialog-box
      type="success"
      class="dialog-text__margin-bottom dialog-text__full-width"
      header="Endorsement issued"
      [dismissable]="false"
    >
      Success! Please find confirmation details below. We will also send a confirmation email to
      {{ emailAddress }} with the endorsement document within the next hour.
    </app-dialog-box>
    <app-document-download
      displayName="Endorsement Document"
      [documentDownload$]="policyDocumentDownload$"
      [documentReady$]="policyDocumentDownloadReady$"
    >
    </app-document-download>

    <div class="landing-card">
      <h2 class="mt-0">Summary of change</h2>
      <p>Effective: {{ getEffectiveDate() }}</p>
      <p>{{ getEndorsementDescription() }}</p>
      <hr />
      <ng-container>
        <div>
          <dl class="dl__no-dotted-line">
            <dt class="desc__no-background">Original policy premium</dt>
            <dd class="desc__no-background">
              {{ getOriginalPremium() | currency: 'USD':'symbol':'1.2-2' }}
            </dd>
          </dl>
          <dl class="dl__no-dotted-line">
            <dt class="desc__no-background">Total premium change</dt>
            <dd class="desc__no-background">
              <span>{{ getPremiumChangeSign() }}</span>
              {{ getPremiumChanges() | currency: 'USD':'symbol':'1.2-2' }}
            </dd>
          </dl>
        </div>

        <div class="endorse-quote-total">
          <div class="endorse-quote-total-lineitem">
            <strong>New total premium</strong>
            <div class="endorse-total">
              <span>$</span>{{ getPremiumInteger() | number: '2.0-0' }}.<span
                class="endorse-total-cents"
                >{{ getPremiumDecimal() | number: '2.0-0' }}</span
              >
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
