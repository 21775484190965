<div class="form-split" *ngIf="!hide">
  <app-form-field-select-api
    inputId="{{ prefix }}-utilityServicesTimeElement"
    [form]="building"
    [submitted]="submitted"
    labelText="Utility Services - Time Element"
    nameOfFormControl="utilityServicesTimeElement"
    [status]="this.utilityServicesTimeElementStatus()"
    [options]="this.utilityServicesTimeElement()"
    tooltipText="Responds to loss of business income of Water Supply Property (pumping stations, water mains, and wastewater removal property) to the described premises."
  >
  </app-form-field-select-api>

  <app-form-field-select-api
    inputId="{{ prefix }}-utilityServicesDirectDamage"
    [form]="building"
    [submitted]="submitted"
    labelText="Utility Services - Direct Damage"
    nameOfFormControl="utilityServicesDirectDamage"
    [status]="this.utilityServicesDirectDamageStatus()"
    [options]="this.utilityServicesDirectDamage()"
    tooltipText="Responds to direct damage of Water Supply Property (pumping stations and water mains) to the described premises."
  >
  </app-form-field-select-api>
</div>
