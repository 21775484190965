import { WcPolicyPayment } from './wc-policy-payment';
import { WC_POLICY_PAYMENT_PLAN_OPTIONS } from 'app/workers-comp/employers/constants';

export class WcPolicyPaymentPresenter {
  installments: number;
  downPaymentPremium: number;
  downPaymentSubtotal: number;
  installmentPremium: number;
  estimatedTotalIntegral: number;
  estimatedTotalFractional: number;

  constructor(wcPolicyPayment: WcPolicyPayment, numberOfPayments: number) {
    const paymentOption = WC_POLICY_PAYMENT_PLAN_OPTIONS[numberOfPayments];

    if (numberOfPayments === 9 || !paymentOption.downPayment) {
      this.installments = numberOfPayments;
    } else {
      this.installments = numberOfPayments - 1;
    }

    this.downPaymentPremium = wcPolicyPayment.getDownPaymentPremium(numberOfPayments);
    this.downPaymentSubtotal = this.downPaymentPremium;
    this.installmentPremium = wcPolicyPayment.getInstallment(numberOfPayments);

    const estimatedTotal = this.downPaymentPremium + this.installments * this.installmentPremium;
    this.estimatedTotalIntegral = Math.trunc(estimatedTotal);
    this.estimatedTotalFractional = (estimatedTotal - this.estimatedTotalIntegral) * 100;
  }
}
