<footer class="app-footer print-hidden" [ngClass]="{ 'app-footer__white-bg': isWhiteBackground }">
  <div class="footer-container">
    <span>© Attune Insurance {{ currentYear }}</span>
    <div class="footer-links">
      <a id="footer-content-contact" class="footer-link" href="/{{ helpCenterPath }}"
        >Get in touch with us</a
      >
      <p *ngIf="showPhoneNumber" class="footer-link">|</p>
      <a
        *ngIf="showPhoneNumber"
        id="footer-content-phone"
        href="tel+18885304650"
        class="footer-link"
        >1-888-530-4650</a
      >
    </div>
  </div>
</footer>
