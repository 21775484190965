import * as moment from 'moment';
import { ValidatorFn, Validators } from '@angular/forms';
import {
  checkForFailedUpload,
  validateDate,
  validateEmailAddress,
  validateEMod,
  validatePhoneNumber,
  validateVersionNumber,
} from 'app/features/attune-bop/models/form-validators';
import {
  ADDITIONAL_INSURED_MAPPING,
  AdditionalInsuredPlacement,
} from 'app/features/attune-bop/models/bop-additional-insured-business';
import { ADDITIONAL_INSURED_TYPES_MAPPING } from 'app/features/support/models/support-constants';

export enum EvaluatorName {
  IS_BEFORE_TODAY,
  IS_NOT_BEFORE_TODAY,
  IS_ADD_OR_UPDATE_LOCATION,
  IS_ADD_OR_UPDATE_LOCATION_NON_WC,
  IS_ADD_LOCATION_NON_WC,
  IS_UPDATE_LOCATION_NON_WC,
  IS_REMOVE_LOCATION_NON_WC,
  TWO_POSITIVE_VALUES,
  IS_ADD_OR_REMOVE_WITHOUT_AI_LIST,
  NEEDS_AI_INSURABLE_INTEREST,
  NEEDS_LOCATION_OF_COVERED_OPS,
  IS_AI_LESSOR,
  IS_AI_LOSS_PAYABLE,
  IS_AI_COMPLETED_OPERATIONS,
  IS_AI_PLACEMENT_BUILDING,
  IS_AI_PLACEMENT_LOCATION,
  IS_AI_VENDORS,
  IS_UPDATE_OR_REMOVE_AI_AND_BACKDATED,
  IS_ADD_AI_AND_BACKDATED_AND_NEEDS_NKLL,
  IS_AI_DOES_NOT_NEED_NKLL,
  IS_BACKDATED_MORE_THAN_5_DAYS,
  IS_BACKDATED_WITHIN_30_DAYS,
  IS_BACKDATED_MORE_THAN_30_DAYS,
  IS_BACKDATED_30_DAYS_AND_SOLD,
  IS_BACKDATED_30_DAYS_AND_CLOSED,
  IS_BACKDATED_30_DAYS_AND_COVERAGE_REPLACED,
  IS_BACKDATED_OTHER_OR_WITHIN_30_DAYS_AND_REASON,
  IS_MIDTERM_OR_RENEWAL_BACKDATED_5_DAYS,
  REQUIRES_CANCELLATION_REASON,
  IS_ADD_OR_UPDATE_AND_20_YEARS_OLD,
  IS_ADD_OR_UPDATE_AND_TENANT_INTEREST,
  IS_ADD_OR_UPDATE_AND_OWNER_INTEREST,
  IS_ADD_UPDATE_AND_TENANT_AND_4_STORIES,
  IS_NOT_BACKDATED_MORE_THAN_30_DAYS,
  IS_CHANGE_COVERAGE_UPDATE,
  HAS_CHANGE_COVERAGE_REASON,
  HAS_CHANGE_COVERAGE_INSURED_UPDATES,
  IS_UPDATE_DEDUCTIBLE_AND_BACKDATED,
  HAS_NKLL_DOCS_UPLOAD,
  HAS_SUPPLEMENTARY_DOCS_UPLOAD,
  IS_GL_OR_PL,
  IS_NOT_ATTUNE_WC,
  IS_ATTUNE_WC_ADD_NI,
  IS_ATTUNE_WC_UPDATE_NI,
  IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES,
  IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES_EMOD,
  IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES_FEIN,
  CHECKBOX,
  INCLUDE_ERM_14_UPLOAD,
  INCLUDE_ERM_14_UPLOAD_MI,
  INCLUDE_ERM_14_UPLOAD_PA,
  UPDATE_OR_REMOVE_HAS_AI_OPTIONS,
  UPDATE_OR_REMOVE_HAS_NO_AI_OPTIONS,
  ADD_AI_OR_REMOVE_WITH_NO_AI_OPTIONS,
  UPDATE_HAS_NO_AI_OPTIONS,
}

export type EvaluatorFunc = (val: any) => boolean;

export const evaluatorFuncs: { [key in EvaluatorName]: EvaluatorFunc } = {
  [EvaluatorName.IS_BEFORE_TODAY]: (date: string) => {
    // Using local time instead of UTC to prevent evaluator from incorrectly marking dates as backdated
    const today = moment();
    return moment(date).isBefore(today, 'date');
  },
  [EvaluatorName.IS_NOT_BEFORE_TODAY]: (date: string) => {
    // Using local time instead of UTC to prevent evaluator from incorrectly marking dates as backdated
    const today = moment();
    return !moment(date).isBefore(today, 'date');
  },
  [EvaluatorName.TWO_POSITIVE_VALUES]: (values: [number, number]) => {
    return values[0] > 0 && values[1] > 0;
  },
  // Note: This evaluator is very specific and not reusable; if we have more such evaluators,
  // consider refactoring FormDSL's 'ValueConditional' node to accept an array of enableValues
  // to handle such cases.
  [EvaluatorName.IS_ADD_OR_UPDATE_LOCATION]: (operation: string) => {
    return ['add_a_new_location', 'update_an_existing_location'].includes(operation);
  },
  [EvaluatorName.IS_ADD_OR_UPDATE_LOCATION_NON_WC]: (values: [string, string]) => {
    const [locationChange, lineOfBusiness] = values;
    return (
      evaluatorFuncs[EvaluatorName.IS_ADD_OR_UPDATE_LOCATION](locationChange) &&
      evaluatorFuncs[EvaluatorName.IS_NOT_ATTUNE_WC](lineOfBusiness)
    );
  },
  [EvaluatorName.IS_ADD_LOCATION_NON_WC]: (values: [string, string]) => {
    const [locationChange, lineOfBusiness] = values;
    return (
      locationChange === 'add_a_new_location' &&
      evaluatorFuncs[EvaluatorName.IS_NOT_ATTUNE_WC](lineOfBusiness)
    );
  },
  [EvaluatorName.IS_UPDATE_LOCATION_NON_WC]: (values: [string, string]) => {
    const [locationChange, lineOfBusiness] = values;
    return (
      locationChange === 'update_an_existing_location' &&
      evaluatorFuncs[EvaluatorName.IS_NOT_ATTUNE_WC](lineOfBusiness)
    );
  },
  [EvaluatorName.IS_REMOVE_LOCATION_NON_WC]: (values: [string, string]) => {
    const [locationChange, lineOfBusiness] = values;
    return (
      locationChange === 'remove_an_existing_location' &&
      evaluatorFuncs[EvaluatorName.IS_NOT_ATTUNE_WC](lineOfBusiness)
    );
  },
  [EvaluatorName.IS_ADD_OR_REMOVE_WITHOUT_AI_LIST]: (values: [string, Record<string, any>]) => {
    const [operation, extraData] = values;
    if (operation === 'add_an_additional_insured') {
      return true;
    } else if (operation === 'remove_an_additional_insured' && !extraData?.additionalInsuredList) {
      return true;
    }
    return false;
  },
  [EvaluatorName.IS_CHANGE_COVERAGE_UPDATE]: (operation: string) => {
    return ['update_limit', 'update_deductible'].includes(operation);
  },
  [EvaluatorName.HAS_CHANGE_COVERAGE_REASON]: (values: [string, string]) => {
    const [changeCoverageType, changeCoverageAddRemove] = values;
    return (
      ['change_bpp_building_limit', 'schedule_equipment', 'update_limit'].includes(
        changeCoverageType
      ) ||
      (changeCoverageType === 'add_remove_coverage' && changeCoverageAddRemove === 'add_coverage')
    );
  },
  [EvaluatorName.HAS_CHANGE_COVERAGE_INSURED_UPDATES]: (operation: string) => {
    return [
      'change_bpp_building_limit',
      'add_remove_coverage',
      'schedule_equipment',
      'update_limit',
    ].includes(operation);
  },
  [EvaluatorName.IS_UPDATE_DEDUCTIBLE_AND_BACKDATED]: (values: [string, string]) => {
    const [changeCoverageType, effectiveDate] = values;
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](effectiveDate);
    const isUpdateDeductible = changeCoverageType === 'update_deductible';

    return isBackdated && isUpdateDeductible;
  },
  [EvaluatorName.HAS_NKLL_DOCS_UPLOAD]: (values: [string, string, string]) => {
    const [changeCoverageType, changeCoverageAddRemove, effectiveDate] = values;
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](effectiveDate);
    const isUpdateDeductible = changeCoverageType === 'update_deductible';
    const isRemoveCoverage =
      changeCoverageType === 'add_remove_coverage' && changeCoverageAddRemove === 'remove_coverage';

    return isBackdated && !isUpdateDeductible && !isRemoveCoverage;
  },
  [EvaluatorName.HAS_SUPPLEMENTARY_DOCS_UPLOAD]: (values: [string, string, string]) => {
    const [changeCoverageType, changeCoverageAddRemove, effectiveDate] = values;
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](effectiveDate);
    const isFuturedated = evaluatorFuncs[EvaluatorName.IS_NOT_BEFORE_TODAY](effectiveDate);
    const isRemoveCoverage =
      changeCoverageType === 'add_remove_coverage' && changeCoverageAddRemove === 'remove_coverage';

    return isFuturedated || (isBackdated && isRemoveCoverage);
  },
  [EvaluatorName.NEEDS_AI_INSURABLE_INTEREST]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, aiType, aiUpdateType] = values;
    const isUpdate = aiAddOrUpdate === 'update_an_additional_insured';
    const isAdd = aiAddOrUpdate === 'add_an_additional_insured';
    const aiValues = [
      'designated_person_or_organization___bp_04_48_',
      'engineers__architects_or_surveyors_not_engaged_by_the_named_insured__bp_04_49_',
      'engineers__architects_or_surveyors__bp_04_49_',
      'owners__lessees_or_contractors-_with_additional_insured_requirement_in_construction_contract__bp_04_51_',
      'state_or_governmental_agency_or_subdivision_or_political_subdivision-_permits_or_authorizations___bp_04_52_',
      'vendors__bp_04_47_',
      'owners__lessees_or_contractors_-_completed_operations__not_available_in_fl___bp_14_02_',
      'controlling_interest__bp_04_06_',
    ];
    let type = '';

    if (isUpdate) {
      type = aiUpdateType;
    } else if (isAdd) {
      type = aiType;
    }

    return aiValues.includes(type);
  },
  [EvaluatorName.NEEDS_LOCATION_OF_COVERED_OPS]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, aiType, aiUpdateType] = values;
    const isUpdate = aiAddOrUpdate === 'update_an_additional_insured';
    const isAdd = aiAddOrUpdate === 'add_an_additional_insured';

    let type = '';

    if (isUpdate) {
      type = aiUpdateType;
    } else if (isAdd) {
      type = aiType;
    }

    return type === 'owners__lessees_or_contractors_-_scheduled_person_or_organization__bp_04_50_';
  },
  [EvaluatorName.IS_AI_LESSOR]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, aiType, aiUpdateType] = values;
    const isUpdate = aiAddOrUpdate === 'update_an_additional_insured';
    const isAdd = aiAddOrUpdate === 'add_an_additional_insured';
    let type = '';

    if (isUpdate) {
      type = aiUpdateType;
    } else if (isAdd) {
      type = aiType;
    }

    return type === 'less_or_leased_equipment__flat_rate___bp_04_16_';
  },
  [EvaluatorName.IS_AI_LOSS_PAYABLE]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, aiType, aiUpdateType] = values;
    const isUpdate = aiAddOrUpdate === 'update_an_additional_insured';
    const isAdd = aiAddOrUpdate === 'add_an_additional_insured';
    let type = '';

    if (isUpdate) {
      type = aiUpdateType;
    } else if (isAdd) {
      type = aiType;
    }

    return type === 'loss_payable__bp_12_03_';
  },
  [EvaluatorName.IS_AI_COMPLETED_OPERATIONS]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, aiType, aiUpdateType] = values;
    const isUpdate = aiAddOrUpdate === 'update_an_additional_insured';
    const isAdd = aiAddOrUpdate === 'add_an_additional_insured';
    let type = '';

    if (isUpdate) {
      type = aiUpdateType;
    } else if (isAdd) {
      type = aiType;
    }

    return (
      type ===
      'owners__lessees_or_contractors_-_completed_operations__not_available_in_fl___bp_14_02_'
    );
  },
  [EvaluatorName.IS_AI_PLACEMENT_BUILDING]: (values: [string, Record<string, any>]) => {
    const [type, extraData] = values;
    // Return false when dynamicSource data is missing
    if (!extraData?.additionalInsuredBuildings) {
      return false;
    }
    const aiType =
      ADDITIONAL_INSURED_TYPES_MAPPING[type as keyof typeof ADDITIONAL_INSURED_TYPES_MAPPING];
    return aiType && ADDITIONAL_INSURED_MAPPING[aiType] === AdditionalInsuredPlacement.BUILDING;
  },
  [EvaluatorName.IS_AI_PLACEMENT_LOCATION]: (values: [string, Record<string, any>]) => {
    const [type, extraData] = values;
    // Return false when dynamicSource data is missing
    if (!extraData?.additionalInsuredLocations) {
      return false;
    }
    const aiType =
      ADDITIONAL_INSURED_TYPES_MAPPING[type as keyof typeof ADDITIONAL_INSURED_TYPES_MAPPING];
    return aiType && ADDITIONAL_INSURED_MAPPING[aiType] === AdditionalInsuredPlacement.LOCATION;
  },
  [EvaluatorName.IS_AI_VENDORS]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, aiType, aiUpdateType] = values;
    const isUpdate = aiAddOrUpdate === 'update_an_additional_insured';
    const isAdd = aiAddOrUpdate === 'add_an_additional_insured';
    let type = '';

    if (isUpdate) {
      type = aiUpdateType;
    } else if (isAdd) {
      type = aiType;
    }

    return type === 'vendors__bp_04_47_';
  },
  [EvaluatorName.IS_UPDATE_OR_REMOVE_AI_AND_BACKDATED]: (values: [string, string]) => {
    const [aiAddOrUpdate, effectiveDate] = values;
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](effectiveDate);
    const isUpdateOrRemoveAi = [
      'update_an_additional_insured',
      'remove_an_additional_insured',
    ].includes(aiAddOrUpdate);

    return isBackdated && isUpdateOrRemoveAi;
  },
  [EvaluatorName.IS_ADD_AI_AND_BACKDATED_AND_NEEDS_NKLL]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, effectiveDate, aiType] = values;
    const isAddAi = aiAddOrUpdate === 'add_an_additional_insured';
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](effectiveDate);
    const aiNkllExemptTypes = [
      'mortgagee__assignee_or_receiver__bp_04_09_',
      'owners_or_other_interests_from_whom_land_has_been_leased__bp_04_10_',
      'loss_payable__bp_12_03_',
      'building_owner__not_available_in_fl___bp_12_31_',
    ];

    return isAddAi && isBackdated && !aiNkllExemptTypes.includes(aiType);
  },
  [EvaluatorName.IS_AI_DOES_NOT_NEED_NKLL]: (values: [string, string, string]) => {
    const [aiAddOrUpdate, effectiveDate, aiType] = values;
    const isAddAi = aiAddOrUpdate === 'add_an_additional_insured';
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](effectiveDate);
    const aiNkllExemptTypes = [
      'mortgagee__assignee_or_receiver__bp_04_09_',
      'owners_or_other_interests_from_whom_land_has_been_leased__bp_04_10_',
      'loss_payable__bp_12_03_',
      'building_owner__not_available_in_fl___bp_12_31_',
    ];

    return !isBackdated || (isAddAi && aiNkllExemptTypes.includes(aiType));
  },
  [EvaluatorName.IS_BACKDATED_MORE_THAN_5_DAYS]: (date: string) => {
    const fiveDaysAgo = moment().subtract(5, 'days');
    return moment(date).isBefore(fiveDaysAgo, 'date');
  },
  [EvaluatorName.IS_BACKDATED_WITHIN_30_DAYS]: (date: string) => {
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](date);
    const thirtyDaysAgo = moment().subtract(30, 'days');
    return isBackdated && !moment(date).isBefore(thirtyDaysAgo, 'date');
  },
  [EvaluatorName.IS_BACKDATED_MORE_THAN_30_DAYS]: (date: string) => {
    const thirtyDaysAgo = moment().subtract(30, 'days');
    return moment(date).isBefore(thirtyDaysAgo, 'date');
  },
  [EvaluatorName.IS_BACKDATED_30_DAYS_AND_SOLD]: (values: [string, string]) => {
    const [effectiveDate, cancellationReason] = values;
    const backdatedMoreThan30Days =
      evaluatorFuncs[EvaluatorName.IS_BACKDATED_MORE_THAN_30_DAYS](effectiveDate);
    return backdatedMoreThan30Days && cancellationReason === 'business_sold';
  },
  [EvaluatorName.IS_BACKDATED_30_DAYS_AND_CLOSED]: (values: [string, string]) => {
    const [effectiveDate, cancellationReason] = values;
    const backdatedMoreThan30Days =
      evaluatorFuncs[EvaluatorName.IS_BACKDATED_MORE_THAN_30_DAYS](effectiveDate);
    return backdatedMoreThan30Days && cancellationReason === 'business_closed';
  },
  [EvaluatorName.IS_BACKDATED_30_DAYS_AND_COVERAGE_REPLACED]: (values: [string, string]) => {
    const [effectiveDate, cancellationReason] = values;
    const backdatedMoreThan30Days =
      evaluatorFuncs[EvaluatorName.IS_BACKDATED_MORE_THAN_30_DAYS](effectiveDate);
    return backdatedMoreThan30Days && cancellationReason === 'replaced_coverage';
  },
  [EvaluatorName.IS_BACKDATED_OTHER_OR_WITHIN_30_DAYS_AND_REASON]: (values: [string, string]) => {
    const [effectiveDate, cancellationReason] = values;
    const backdatedWithin30Days =
      evaluatorFuncs[EvaluatorName.IS_BACKDATED_WITHIN_30_DAYS](effectiveDate);
    const isBackdated = evaluatorFuncs[EvaluatorName.IS_BEFORE_TODAY](effectiveDate);
    return (
      (backdatedWithin30Days &&
        ['business_sold', 'business_closed', 'replaced_coverage'].includes(cancellationReason)) ||
      (isBackdated && cancellationReason === 'cancel_reason_other')
    );
  },
  [EvaluatorName.IS_MIDTERM_OR_RENEWAL_BACKDATED_5_DAYS]: (values: [string, string]) => {
    const [effectiveDate, cancellationPolicyType] = values;
    const backdatedMoreThan5Days =
      evaluatorFuncs[EvaluatorName.IS_BACKDATED_MORE_THAN_5_DAYS](effectiveDate);
    return (
      cancellationPolicyType === 'active_cancellation' ||
      (cancellationPolicyType === 'renewal_cancellation' && backdatedMoreThan5Days)
    );
  },
  [EvaluatorName.REQUIRES_CANCELLATION_REASON]: (values: [string, string, string]) => {
    const [effectiveDate, cancellationPolicyType, cancellationReason] = values;
    const isMidtermOrRenewalBackdated = evaluatorFuncs[
      EvaluatorName.IS_MIDTERM_OR_RENEWAL_BACKDATED_5_DAYS
    ]([effectiveDate, cancellationPolicyType]);
    return isMidtermOrRenewalBackdated && cancellationReason === 'cancel_reason_other';
  },
  [EvaluatorName.IS_NOT_BACKDATED_MORE_THAN_30_DAYS]: (date: string) => {
    return !evaluatorFuncs[EvaluatorName.IS_BACKDATED_MORE_THAN_30_DAYS](date);
  },
  [EvaluatorName.IS_ADD_OR_UPDATE_AND_20_YEARS_OLD]: (values: [string, number]) => {
    const [locationAddOrUpdate, yearBuilt] = values;
    const isAddOrUpdate =
      evaluatorFuncs[EvaluatorName.IS_ADD_OR_UPDATE_LOCATION](locationAddOrUpdate);
    const year = yearBuilt;

    // This logic for calculating building age is similar to the logic in the quote flow
    if (year && year > 1000) {
      const buildingAge = moment().year() - year;
      return buildingAge >= 20 && isAddOrUpdate;
    }

    return false;
  },
  [EvaluatorName.IS_ADD_UPDATE_AND_TENANT_AND_4_STORIES]: (values: [string, string, string]) => {
    const [locationAddOrUpdate, interest, stories] = values;
    const isAddOrUpdateAndTenant = evaluatorFuncs[
      EvaluatorName.IS_ADD_OR_UPDATE_AND_TENANT_INTEREST
    ]([locationAddOrUpdate, interest]);
    // Stories is a selection with values of either more_than_6 or of a string of the number
    const numberOfStories: number = stories === 'more_than_6' ? 6 : Number(stories);

    if (numberOfStories) {
      return numberOfStories > 3 && isAddOrUpdateAndTenant;
    }

    return false;
  },
  [EvaluatorName.IS_ADD_OR_UPDATE_AND_OWNER_INTEREST]: (values: [string, string]) => {
    const [locationAddOrUpdate, interest] = values;
    const isAddOrUpdate =
      evaluatorFuncs[EvaluatorName.IS_ADD_OR_UPDATE_LOCATION](locationAddOrUpdate);
    const isOwner = interest === 'Owner';

    return isAddOrUpdate && isOwner;
  },
  [EvaluatorName.IS_ADD_OR_UPDATE_AND_TENANT_INTEREST]: (values: [string, string]) => {
    const [locationAddOrUpdate, interest] = values;
    const isAddOrUpdate =
      evaluatorFuncs[EvaluatorName.IS_ADD_OR_UPDATE_LOCATION](locationAddOrUpdate);
    const isTenant = interest === 'Tenant';

    return isAddOrUpdate && isTenant;
  },
  [EvaluatorName.IS_GL_OR_PL]: (value: string) => {
    return value === 'general_liability' || value === 'professional_liability';
  },
  [EvaluatorName.IS_NOT_ATTUNE_WC]: (value: string | undefined) => {
    return value !== 'attune_work_comp';
  },
  [EvaluatorName.IS_ATTUNE_WC_ADD_NI]: (values: [string, string]) => {
    const [line, niChangeType] = values;
    return line === 'attune_work_comp' && niChangeType === 'add_an_additional_named_insured';
  },
  [EvaluatorName.IS_ATTUNE_WC_UPDATE_NI]: (values: [string, string]) => {
    const [line, niChangeType] = values;
    return line === 'attune_work_comp' && niChangeType === 'update_primary_named_insured';
  },
  [EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES]: (values: [string, string, string]) => {
    const [line, niChangeType, niChangeOwner] = values;
    return (
      evaluatorFuncs[EvaluatorName.IS_ATTUNE_WC_UPDATE_NI]([line, niChangeType]) &&
      niChangeOwner === 'yes_ni_wc_owner_changes'
    );
  },
  [EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES_EMOD]: (
    values: [string, string, string, boolean]
  ) => {
    const [line, niChangeType, niChangeOwner, checked] = values;
    return (
      evaluatorFuncs[EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES]([
        line,
        niChangeType,
        niChangeOwner,
      ]) && checked
    );
  },
  [EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES_FEIN]: (
    values: [string, string, string, boolean]
  ) => {
    const [line, niChangeType, niChangeOwner, checked] = values;
    return (
      evaluatorFuncs[EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES]([
        line,
        niChangeType,
        niChangeOwner,
      ]) && checked
    );
  },
  [EvaluatorName.CHECKBOX]: (value: boolean) => {
    return value;
  },
  [EvaluatorName.INCLUDE_ERM_14_UPLOAD]: (
    values: [string, string, string, string, boolean, boolean]
  ) => {
    const [line, niChangeType, niChangeOwner, state, ownerChecked, feinChecked] = values;
    const states = ['AZ', 'GA', 'SC', 'MD', 'TN', 'VA', 'AL', 'MS', 'OK', 'FL', 'IN', 'NC'];
    if (
      evaluatorFuncs[EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES]([
        line,
        niChangeType,
        niChangeOwner,
      ]) &&
      states.includes(state)
    ) {
      return ownerChecked || feinChecked;
    }
    return false;
  },
  [EvaluatorName.INCLUDE_ERM_14_UPLOAD_MI]: (
    values: [string, string, string, string, boolean, boolean]
  ) => {
    const [line, niChangeType, niChangeOwner, state, ownerChecked, feinChecked] = values;
    if (
      evaluatorFuncs[EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES]([
        line,
        niChangeType,
        niChangeOwner,
      ]) &&
      state === 'MI'
    ) {
      return ownerChecked || feinChecked;
    }
    return false;
  },
  [EvaluatorName.INCLUDE_ERM_14_UPLOAD_PA]: (
    values: [string, string, string, string, boolean, boolean]
  ) => {
    const [line, niChangeType, niChangeOwner, state, ownerChecked, feinChecked] = values;
    if (
      evaluatorFuncs[EvaluatorName.IS_ATTUNE_WC_UPDATE_NI_OWNER_CHANGES]([
        line,
        niChangeType,
        niChangeOwner,
      ]) &&
      state === 'PA'
    ) {
      return ownerChecked || feinChecked;
    }
    return false;
  },
  [EvaluatorName.UPDATE_OR_REMOVE_HAS_AI_OPTIONS]: (values: [string, Record<string, any>]) => {
    const [aiType, extraData] = values;
    const isUpdateOrRemoveAi = [
      'update_an_additional_insured',
      'remove_an_additional_insured',
    ].includes(aiType);
    if (!isUpdateOrRemoveAi) {
      return false;
    }
    return !!extraData?.additionalInsuredList;
  },
  [EvaluatorName.UPDATE_HAS_NO_AI_OPTIONS]: (values: [string, Record<string, any>]) => {
    const [aiType, extraData] = values;
    const isUpdateAi = aiType === 'update_an_additional_insured';
    if (!isUpdateAi) {
      return false;
    }
    return !extraData?.additionalInsuredList;
  },
  [EvaluatorName.ADD_AI_OR_REMOVE_WITH_NO_AI_OPTIONS]: (values: [string, Record<string, any>]) => {
    const [aiType, extraData] = values;

    if (aiType === 'add_an_additional_insured') {
      return true;
    } else if (aiType === 'remove_an_additional_insured' && !extraData?.additionalInsuredList) {
      return true;
    }

    return false;
  },
  [EvaluatorName.UPDATE_OR_REMOVE_HAS_NO_AI_OPTIONS]: (values: [string, Record<string, any>]) => {
    const [aiType, extraData] = values;
    const isUpdateOrRemoveAi = [
      'update_an_additional_insured',
      'remove_an_additional_insured',
    ].includes(aiType);
    if (!isUpdateOrRemoveAi) {
      return false;
    }
    return !!extraData?.additionalInsuredList;
  },
};

export enum ValidatorName {
  UPLOADS_SUCCESSFUL,
  VALID_DATE,
  VALID_E_MOD,
  VALID_EMAIL,
  VALID_PHONE_NUMBER,
  VALID_VERSION_NUMBER,
  ALPHANUMERIC,
}

export const validatorFuncs: { [key in ValidatorName]: ValidatorFn } = {
  [ValidatorName.UPLOADS_SUCCESSFUL]: checkForFailedUpload,
  [ValidatorName.VALID_DATE]: validateDate,
  [ValidatorName.VALID_E_MOD]: validateEMod,
  [ValidatorName.VALID_EMAIL]: validateEmailAddress,
  [ValidatorName.VALID_PHONE_NUMBER]: validatePhoneNumber,
  [ValidatorName.VALID_VERSION_NUMBER]: validateVersionNumber,
  [ValidatorName.ALPHANUMERIC]: Validators.pattern(/\w+/),
};
