import { catchError } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { V3_ELIGIBILITY_CHECK_API } from 'app/constants';
import { HttpClient } from '@angular/common/http';
import { SentryService } from 'app/core/services/sentry.service';
import { EligibilityService } from 'app/shared/services/eligibility.service';

export const DEFAULT_ACCEPT_BOP_RISK = {
  locationEligibilities: [],
  policyEligibility: {
    acceptRisk: true,
    riskDeclineReason: [],
  },
};

@Injectable()
export class AttuneBopEligibilityService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private eligibilityService: EligibilityService
  ) {}

  getEligibilityDeclineReasons(accountId: string) {
    return this.eligibilityService.getEligibilityDeclineReasons(accountId, 'bop');
  }

  fetchEligibilityCheck(
    payload: BopEligibilityCheckRequest
  ): Observable<BopEligibilityCheckResponse> {
    // If there are no locations to check, we do not have to request eligibility.
    if (payload.bopLocations.length === 0) {
      return observableOf(DEFAULT_ACCEPT_BOP_RISK);
    }

    return this.http.post<BopEligibilityCheckResponse>(V3_ELIGIBILITY_CHECK_API, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to fetch BOP eligibility check data', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
          },
        });
        // We log error above and allow the quote to proceed.
        return observableOf(DEFAULT_ACCEPT_BOP_RISK);
      })
    );
  }
}
