import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';

@Component({
  selector: 'app-activity-incentive-wc-hot-classes',
  templateUrl: 'activity-incentive-wc-hot-classes.component.html',
})
export class ActivityIncentiveWcHotClassesComponent implements OnInit, OnDestroy {
  constructor(
    private amplitudeService: AmplitudeService,
    private featureFlagService: FeatureFlagService
  ) {}
  private sub: Subscription = new Subscription();

  promoImg: string | undefined = undefined;
  promoLink: string | undefined = undefined;

  ngOnInit() {
    this.sub.add(
      this.featureFlagService
        .guaranteeIsEnabled(BOOLEAN_FLAG_NAMES.ATTUNE_WC_SUMMER_INCENTIVE)
        .subscribe((hasSummerIncentive) => {
          // Producers in the Summer Incentive see a promo for it; the rest see the Hot Classes promo #2 (#1 is over)
          this.promoImg = hasSummerIncentive
            ? 'assets/img/attune_wc_summer_incentive.svg'
            : 'assets/img/attune_wc_hot_classes_2.svg';
          this.promoLink = hasSummerIncentive
            ? 'https://cdn.brandfolder.io/8ATZIINR/as/nzsgqq4xqvrbjs8nst464w/WC_Summer_Incentive.pdf'
            : 'https://cdn.brandfolder.io/8ATZIINR/at/qtt6rzsgv8s67gxh3h93gnc/Attune_WC_Promo_2_Overview.pdf';
          const eventName = hasSummerIncentive
            ? 'displayed_wc_summer_incentive_2024'
            : 'displayed_wc_hot_classes_promo_2';
          this.amplitudeService.track({
            eventName,
            detail: '',
          });
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
