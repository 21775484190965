import { FormDslNode } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { LmCpspFormStepPath, LmCpspQuestion } from './lm-cpsp-typings';
import { CPSP_FORM_QUESTIONS } from './lm-cpsp-questions';
import { FormDslConfiguration } from './common-typings';

/**
 * Determines the step and question order for the quote flow.
 *
 * Any nested arrays within each `questions` array will be rendered as subsections (i.e. with a left
 * indentation and a vertical bar on the left.)
 */
export const LM_CPSP_QUOTE_FLOW: {
  title: string;
  slug: string;
  parent: string;
  formPath: LmCpspFormStepPath;
  questions: (LmCpspQuestion | LmCpspQuestion[])[];
}[] = [
  {
    title: 'Policy details',
    slug: 'policy-details',
    parent: 'policy-details',
    formPath: LmCpspFormStepPath.POLICY_INFO,
    questions: [
      LmCpspQuestion.EFFECTIVE_DATE,
      LmCpspQuestion.PRIMARY_RISK_STATE,
      LmCpspQuestion.INSURED_PHONE_NUMBER,
      LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
      LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
      LmCpspQuestion.DESCRIPTION_OF_OPERATIONS,
      LmCpspQuestion.FORM_OF_BUSINESS,
      LmCpspQuestion.FEDERAL_ID,
      LmCpspQuestion.ANNUAL_SALES_RECEIPTS,
      LmCpspQuestion.EXPOSURE,
      LmCpspQuestion.LIQUOR_PERCENT_OF_SALES,
      LmCpspQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS,
      LmCpspQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED,
      LmCpspQuestion.OP_CHANGE,
      LmCpspQuestion.YEAR_BUSINESS_STARTED,
      LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE,
      LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS,
      LmCpspQuestion.PRIOR_COVERAGE,
      LmCpspQuestion.PRIOR_COVERAGE_REASON,
      LmCpspQuestion.PRIOR_COVERAGE_DETAILS,
      LmCpspQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS,
      LmCpspQuestion.WATER_CONNECTIONS,
      LmCpspQuestion.HOURS_OF_OPERATION,
      LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES,
      LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS,
      LmCpspQuestion.OTHER_BUSINESS_INSD_ELSEWHERE,
      LmCpspQuestion.APPLICANT_SUBSIDIARIES,
      LmCpspQuestion.APPLICANT_SUBSIDIARIES_DETAILS,
      LmCpspQuestion.ARSON_CONVICTION,
      LmCpspQuestion.CHURCH_SCHOOL,
      LmCpspQuestion.MOLESTATION,
      LmCpspQuestion.MOLESTATION_DETAILS,
      LmCpspQuestion.RARE_ANIMAL_TREATMENT,
      LmCpspQuestion.SILK_SCREENING,
      LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION,
      LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION_DETAILS,
      LmCpspQuestion.GENERAL_CONTRACTING,
      LmCpspQuestion.GENERAL_CONTRACTING_DETAILS,
      LmCpspQuestion.LEASED_EQUIPMENT,
      LmCpspQuestion.LEASED_DETAILS,
      LmCpspQuestion.LEASED_OPERATOR_DETAILS,
      LmCpspQuestion.REFURBISHED_EQUIPMENT,
      LmCpspQuestion.REFURBISHED_EQUIPMENT_DETAILS,
      LmCpspQuestion.OTHER_OFFICE_TYPES,
      LmCpspQuestion.OTHER_OFFICE_TYPES_DETAILS,
      LmCpspQuestion.ENVIRONMENTAL_HAZARD,
      LmCpspQuestion.ENVIRONMENTAL_HAZARD_DETAILS,
      LmCpspQuestion.EXTERNAL_INSURED_REVENUES,
      LmCpspQuestion.EXTERNAL_INSURED_DETAILS,
      LmCpspQuestion.MANUFACTURING_ACTIVITY,
      LmCpspQuestion.MANUFACTURING_ACTIVITY_DETAILS,
      LmCpspQuestion.BICYCLE_RENTAL,
      LmCpspQuestion.FIREARMS_SALES,
      LmCpspQuestion.ADULT_MATERIALS_SALES,
      LmCpspQuestion.GASOLINE_SALES,
      LmCpspQuestion.COMPUTER_PROGRAMMING,
      LmCpspQuestion.COMPUTER_PROGRAMMING_DETAILS,
      LmCpspQuestion.WEB_HOSTING,
      LmCpspQuestion.SURVEYORS_PROFESSIONAL_LIABILITY,
      LmCpspQuestion.DEMOLITION_PERCENT,
      LmCpspQuestion.DEMOLITION_DETAILS,
      LmCpspQuestion.HAZARDOUS_MATERIALS_ABATEMENT,
      LmCpspQuestion.CRANE_OR_BUCKET_TRUCK,
      LmCpspQuestion.CRANE_OR_BUCKET_TRUCK_DETAILS,
      LmCpspQuestion.DAMS_OR_BRIDGES,
      LmCpspQuestion.OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT,
      LmCpspQuestion.AIRPORT_CONSTRUCTION_DETAILS,
      LmCpspQuestion.ALARM_OR_SPRINKLER_INSTALLATION,
      LmCpspQuestion.HOSPITAL_WORK_PERCENT,
      LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS,
      LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS_DETAILS,
      LmCpspQuestion.LP_GAS_WORK_PERCENT,
      LmCpspQuestion.LP_GAS_WORK_PERCENT_DETAILS,
      LmCpspQuestion.BOILER_WORK,
      LmCpspQuestion.BOILER_WORK_DETAILS,
      LmCpspQuestion.PIPING_REPAIRS,
      LmCpspQuestion.PIPING_REPAIRS_DETAILS,
      LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES,
      LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES_DETAILS,
      LmCpspQuestion.HAZARDOUS_PRODUCTS,
      LmCpspQuestion.ELECTROPLATING_OPERATIONS,
      LmCpspQuestion.TIRE_RECAPPING,
      LmCpspQuestion.TIRE_STOCK,
      LmCpspQuestion.TIRE_SALES,
      LmCpspQuestion.AUTO_RENTAL_OR_LEASING,
      LmCpspQuestion.NONGARAGE_OPERATIONS,
      LmCpspQuestion.NONGARAGE_OPERATIONS_DETAILS,
      LmCpspQuestion.AUTO_BODY_1,
      LmCpspQuestion.AUTO_BODY_2,
      LmCpspQuestion.AUTO_BODY_DETAILS,
      LmCpspQuestion.AUTO_SALVAGE,
      LmCpspQuestion.MOTORCYCLE_REPAIR,
      LmCpspQuestion.TOWING,
      LmCpspQuestion.AUTO_LENDING,
      LmCpspQuestion.SUB_CONTRACTORS_PERCENT,
      LmCpspQuestion.WRITTEN_CONTRACT,
      LmCpspQuestion.WRITTEN_CONTRACT_DETAILS,
      LmCpspQuestion.CERTIFICATES_OF_INSURANCE,
      LmCpspQuestion.SUBCONTRACTOR_LIMITS,
      LmCpspQuestion.HOLD_HARMLESS_CONTRACT,
      LmCpspQuestion.ADDITIONAL_INSURED_CLAUSE,
      LmCpspQuestion.TRENCHING_EXCAVATING,
      LmCpspQuestion.ROAD_WORK,
      LmCpspQuestion.ROAD_WORK_DETAILS,
      LmCpspQuestion.EIFS,
      LmCpspQuestion.PEST_RODENT_CONTROL_PGM,
      LmCpspQuestion.BUILDING_SQUARE_FOOTAGE,
      LmCpspQuestion.CP_1045_SALE_OR_STORAGE,
      LmCpspQuestion.FIREWALL_AND_SELF_CLOSING_FIRE_DOORS,
      LmCpspQuestion.SERVICE_STATIONS_MISC_OPTS,
      LmCpspQuestion.SERVICE_STATION_CAPTURE_CONTROLS,
      LmCpspQuestion.BUILDING_OCCUPANTS,
      LmCpspQuestion.BUILDING_LOCATIONS_DISTANCE,
      LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR,
      LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR_DETAILS,
      LmCpspQuestion.CONTACT_UTILITIES,
      LmCpspQuestion.EMP_CARRY_FIREARMS,
      LmCpspQuestion.ENTER_RESIDENCES,
      LmCpspQuestion.BACKGROUND_CHECKS,
      LmCpspQuestion.PROPERTIES_LEASED_TO_OTHERS,
      LmCpspQuestion.REQUIRE_WRITTEN_CONTRACTS,
      LmCpspQuestion.RENT_MEDICAL_EQUIPMENT,
      LmCpspQuestion.INS_ADDITIONAL_INS_ON_MFGR_INS_POLICY,
      LmCpspQuestion.PHF_FERTILIZER,
      LmCpspQuestion.EXCEED_15_MILLION,
      LmCpspQuestion.EXCEED_10_MILLION,
      LmCpspQuestion.INSURED_STORE_PRODUCTS,
      LmCpspQuestion.PRODUCTS_STORED,
      LmCpspQuestion.SWIMMING_POOL,
      LmCpspQuestion.SWIMMING_POOL_LOCKED_AND_FENCED,
      LmCpspQuestion.SWIMMING_POOL_DIVING_BOARD,
      LmCpspQuestion.DIVING_BOARD_HEIGHT,
      // LmCpspQuestion.PHF_FERTILIZER, <-- Duplicate, which position do we want?
      LmCpspQuestion.PESTICIDES_USAGE,
      LmCpspQuestion.PESTICIDES_SUPERVISION,
      LmCpspQuestion.PESTICIDES_LICENSED_CONTRACTOR,
      LmCpspQuestion.REQUIRED_APPLICATORS_LICENSE,
      LmCpspQuestion.PESTICIDE_CERTIFICATE_OF_INSURANCE,
      LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL,
      LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL_DETAILS,
      LmCpspQuestion.OTHER_PROD_SOLD_UNDER_APPLICANTS_LABEL,
      LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS,
      LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS_DETAILS,
      LmCpspQuestion.PRODUCTS_CUSTOMER_SPECIFICATIONS,
      LmCpspQuestion.PRODUCT_DESCRIPTION,
      LmCpspQuestion.APPLICANTS_MAIN_CUSTOMERS,
      LmCpspQuestion.QUALITY_CONTROL_PROCEDURES,
      LmCpspQuestion.PRODUCTS_DISCONTINUED,
      LmCpspQuestion.PRODUCT_DISCONTINUED_DETAILS,
      LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS,
      LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS_DETAILS,
      LmCpspQuestion.APPLICANT_IS_ADDITIONAL_INSURED,
      LmCpspQuestion.LICENSED_FOR_TRADE,
      [LmCpspQuestion.TYPE_OF_LICENSES],
      LmCpspQuestion._10_MONTHLY_RENTAL,
      LmCpspQuestion.BEAUTY_SHOP,
      LmCpspQuestion.WATER_HAZARDS,
      LmCpspQuestion.PNEUMATIC_PLUMBING_SYSTEM,
      LmCpspQuestion.HOT_WORK_OPERATIONS,
      LmCpspQuestion.HOT_WORK_PROGRAM,
      LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING,
      LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING_DETAILS,
      LmCpspQuestion.REPAIR_HEAVY_TRUCKS_TRACTORS_R_VS_MOBILE_HOMES,
      // LmCpspQuestion.OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT,  <-- Duplicate, which position do we want?
      LmCpspQuestion.APARTMENT_FORECLOSURE,
      LmCpspQuestion.REPAIR_INSTALL_PASSENGER_VEHICLES,
      LmCpspQuestion.OPEN_AFTER_MIDNIGHT,
      LmCpspQuestion.MARIJUANA_OPERATIONS,
      LmCpspQuestion.MEDICAL_MARIJUANA_SALES,
      LmCpspQuestion.ROOM_OFFERED_RATE_1,
      LmCpspQuestion.ROOM_RATE_1,
      LmCpspQuestion.OCCUPANCY_RATE_1,
      LmCpspQuestion.HOTEL_NUMBER_OF_STORIES,
      LmCpspQuestion.EXTERIOR_DOORS_1,
      LmCpspQuestion.FOOD_TRUCK,
      LmCpspQuestion.FOOD_TRUCK_PERCENTAGE,
      LmCpspQuestion.E_COMMERCE_DESIGN,
      LmCpspQuestion.PROFESSIONAL_LIABILITY_COVERAGE,
      LmCpspQuestion._3_D_PRINTING,
      LmCpspQuestion.LARGEST_NUMBER_OF_NEW_HOME_STARTS,
      LmCpspQuestion.PET_RESCUE,
      LmCpspQuestion.GREENHOUSE_PRESENT,
      LmCpspQuestion.VEHICLES_SOLD,
      LmCpspQuestion.EMPLOYEE_LEASING,
      LmCpspQuestion.LAWN_LANDSCAPE_UNDERGROUND,
      LmCpspQuestion.TABLESIDE_COOKING,
      LmCpspQuestion.DAY_CARE,
      LmCpspQuestion.CHILD_CARE_SERVICE,
      LmCpspQuestion.DWELLING_OCCUPANCY,
      LmCpspQuestion.ASBESTOS_FIBERS,
      LmCpspQuestion.PAINTING_OPERATIONS,
      LmCpspQuestion.ROOF_INSTALLATION,
      LmCpspQuestion.SAND_BLASTING,
      // LmCpspQuestion.TRENCHING_EXCAVATING,  <-- Duplicate, which position do we want?
      LmCpspQuestion.OSHA_APPROVAL,
      LmCpspQuestion.WATERPROOFING_SURFACES,
      LmCpspQuestion.LITIGATION_INVOLVEMENT,
      LmCpspQuestion.TRAFFIC_SIGNAL_WORK,
      LmCpspQuestion.HAS_FIREPLACE_OR_WOOD_STOVE_WORK,
      LmCpspQuestion.MOTORIZED_CONVEYANCE,
      LmCpspQuestion.CUSTOMIZING_SERVICES,
      LmCpspQuestion.RELIGIOUS_LIMITATIONS,
      LmCpspQuestion.PERFORMING_REPAIRS,
      LmCpspQuestion.PROVIDING_TRAINING,
      LmCpspQuestion.CLOSED_BY_BOH,
      LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION,
      LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION_DETAILS,
      LmCpspQuestion.EXCEED_7_5_MILLION_IP,
      LmCpspQuestion.EXCEED_15_MILLION_IP,
      LmCpspQuestion.FIRE_DAMAGE_REMEDIATION,
      LmCpspQuestion.FIRE_DAMAGE_REMEDIATION_DETAILS,
      LmCpspQuestion.JANITORIAL_DEBRIS_CLEAN_UP_PERCENT_REFER,
      LmCpspQuestion.CLEANING_KITCHEN_FLUES_HOODS_PERCENT_REFER,
      LmCpspQuestion.CERAMIC_FLOOR_PERCENT_REFER,
      LmCpspQuestion.CONTRACTORS_LICENSES_LIMIT,
      LmCpspQuestion.RESTORATION_WORK,
      LmCpspQuestion.RESTORATION_WORK_DETAILS,
      LmCpspQuestion.INSURED_HAVE_BANQUET,
      LmCpspQuestion.BANQUET_ADEQUATE_HARDWARE,
      LmCpspQuestion.RISK_CURRENTLY_OR_PAST_10_YEARS,
      LmCpspQuestion.FLAMMABLE_FLUID_STORAGE,
      LmCpspQuestion.COMBUSTIBLE_WASTE_CONTAINERS,
      LmCpspQuestion.SNOW_PLOW_OPERATIONS,
      LmCpspQuestion.INSURED_SNOWPLOW_ON_HIGHWAY,
      LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE,
      LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE_DETAIL,
      LmCpspQuestion.GAMBLING_OPERATIONS,
      LmCpspQuestion.CASINO,
      LmCpspQuestion.NUMBER_OF_GAMING_MACHINES,
      LmCpspQuestion.RENOVATION,
      LmCpspQuestion.RENOVATION_BY_EMP,
      LmCpspQuestion.CONTRACT_LANGUAGE,
      LmCpspQuestion.SECURITY_PERSONNEL,
      LmCpspQuestion.SECURITY_SERVICE,
      LmCpspQuestion.ARMED_SECURITY_GUARDS,
      LmCpspQuestion.CERTIFICATES_PROVIDED,
      LmCpspQuestion.DISPOSAL_AUTO_RELATED,
      LmCpspQuestion.SMOKING_IN_REPAIR_AREAS,
      LmCpspQuestion.UNDERGROUND_OR_HEIGHTS,
      LmCpspQuestion.UNDERGROUND_OR_HEIGHTS_DETAILS,
      LmCpspQuestion.PRODUCT_LAWSUIT,
      LmCpspQuestion.HIGH_HAZ_OCCUPANCIES,
      LmCpspQuestion.LARGE_SPECIAL_EVENT,
      LmCpspQuestion.OTHER_THAN_MINOR_AUTO_REPAIR,
      LmCpspQuestion.FILL_PROPANE_GAS_TANKS,
      LmCpspQuestion.PROHIBITED_SMOKING,
      LmCpspQuestion.MEDICAL_GASES_WORK,
      LmCpspQuestion.SPA_SALON_SERVICES,
      LmCpspQuestion.MERCHANDISE_IMPORTED_DIRECTLY,
      LmCpspQuestion.USED_MATERIAL_MERCHANDISE,
      LmCpspQuestion.PERFORMS_RADON_MEASUREMENTS,
      LmCpspQuestion.CONSULTANT_OPERATIONS,
      LmCpspQuestion.SALES_SERVICE_OPERATIONS,
      LmCpspQuestion.FINANCIAL_ACCOUNTING_IQ,
      LmCpspQuestion.EXECUTIVE_SEARCH_IQ,
      LmCpspQuestion.EXPERT_WITNESS_IQ,
      LmCpspQuestion.NP_FUND_RAISING_IQ,
      LmCpspQuestion.RISK_MANAGEMENT_IQ,
      LmCpspQuestion.MARKETING_ADVERTISING_IQ,
      LmCpspQuestion.TRAINING_LEADERSHIP_IQ,
      LmCpspQuestion.TRAINING_SOFTWARE_IQ,
      LmCpspQuestion.ORGANIZATIONAL_DEVELOPMENT_IQ,
      LmCpspQuestion.GENERAL_MANAGER_IQ,
      LmCpspQuestion.PERSONAL_SHOPPER_IQ,
      LmCpspQuestion.SEMINAR_EDUCATOR_IQ,
      LmCpspQuestion.MEDICAL_OFFICE_IQ,
      LmCpspQuestion.RECORDS_MANAGEMENT_IQ,
      LmCpspQuestion.WEDDING_IQ,
      LmCpspQuestion.PARTY_PLANNER_IQ,
      LmCpspQuestion.OFFICE_ADMIN_IQ,
      LmCpspQuestion.WATER_OR_SEWER_MAIN_INSTALLATION,
      LmCpspQuestion.OWN_LEASE_DOCKS,
      LmCpspQuestion.MIXING_OR_COMPOUNDING_DRUGS,
      LmCpspQuestion.COMPLEMENTARY_SERVICES,
      LmCpspQuestion.CONVERTED_HOME_DWELLING,
    ],
  },
  {
    title: 'Loss history',
    slug: 'loss-history',
    parent: 'loss-history',
    formPath: LmCpspFormStepPath.LOSS_HISTORY,
    questions: [
      LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      LmCpspQuestion.LOSSES,
    ],
  },
  {
    title: 'Location details',
    slug: 'location-details',
    parent: 'location-details',
    formPath: LmCpspFormStepPath.LOCATION_DETAILS,
    questions: [
      LmCpspQuestion.ADDRESS,
      LmCpspQuestion.INTEREST_PROPERTY,
      LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_100_K,
      LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_150_K,
      LmCpspQuestion.PROPERTY_DEDUCTIBLE,
      LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING,
      LmCpspQuestion.COINSURANCE_BUILDING,
      LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY,
      LmCpspQuestion.COINSURANCE_BPP,
      LmCpspQuestion.BPP_INCLUDES_EQUIP_OVER_500,
      LmCpspQuestion.MEDICAL_EQUIP_OVER_500_DESC,
      LmCpspQuestion.LIMIT_BI_AND_EE,
      LmCpspQuestion.COINSURANCE_BI_AND_EE,
      LmCpspQuestion.SALES_OR_STORAGE_QUESTION,
      LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY,
      LmCpspQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION,
      LmCpspQuestion.YEAR_BUILT_PROPERTY,
      LmCpspQuestion.ROOF_CONSTRUCTION_PROPERTY,
      LmCpspQuestion.ROOFING_YEAR,
      LmCpspQuestion.YEAR_ROOF_REPLACED_PROPERTY,
      LmCpspQuestion.HEATING_YEAR,
      LmCpspQuestion.PLUMBING_YEAR,
      LmCpspQuestion.WIRING_YEAR,
      LmCpspQuestion.FIRE_ALARM_TYPE,
      LmCpspQuestion.BURGLARY_ALARM,
      LmCpspQuestion.SPRINKLER,
      LmCpspQuestion.NUMBER_OF_STORIES,
      LmCpspQuestion.AREA_OCCUPIED_BY_INSURED_PROPERTY,
      LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS,
      LmCpspQuestion.AREA_UNOCCUPIED,
      LmCpspQuestion.AREA_UNOCCUPIED_DETAILS,
      LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_100_K,
      LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_150_K,
      LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_1,
      LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_2,
      LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_1,
      LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_2,
      LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_1,
      LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_2,
      LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_100_K,
      LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_150_K,
      LmCpspQuestion.TENANT_CREDIT_QUESTION,
      LmCpspQuestion.OCCUPANCY_TYPE_CATEGORY_PROPERTY,
      LmCpspQuestion.OCCUPANCY_TYPE_PROPERTY,
      LmCpspQuestion.OCCUPANCY_QUESTION_MS,
      LmCpspQuestion.OCCUPANCY_QUESTION_WA,
      LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_MS,
      LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_WA,
      LmCpspQuestion.MA_TENANT_RELOCATION_EXPENSE_COVERAGE_NUMBER_OF_RENTED_LIVING_QUARTERS,
      LmCpspQuestion.ARE_THERE_APARTMENT_OCCUPANCIES_THAT_IN_TOTAL_COMPRISE_LESS_THAN_25_OF_THE_BUILDING_FLOOR_AREA,
      LmCpspQuestion.SPRAY_PAINT_PROCESSING_MFG_OCCUPANCIES_QUESTION,
      LmCpspQuestion.SPRAY_PAINTING_QUESTION,
      LmCpspQuestion.SPRAY_MAN_IND_OPS_DETAILS,
      LmCpspQuestion.SPRAY_PAINTING_OR_DIP_TANK_DETAILS,
      LmCpspQuestion.UL_APPROVED_PAINT_BOOTH,
      LmCpspQuestion.UL_APPROVED_PAINT_CABINET,
      LmCpspQuestion.SAFETY_PRECAUTIONS,
      LmCpspQuestion.HABITATIONAL_EXPOSURES,
      LmCpspQuestion.HABITATIONAL_UNITS,
      LmCpspQuestion.HABITATION_RISKS,
      LmCpspQuestion.EMERGENCY_ESCAPE_ROUTES,
      LmCpspQuestion.SMOKE_ALARMS,
      LmCpspQuestion.LIT_COMMON_AREAS,
      LmCpspQuestion.LOCKS_CHANGED,
      LmCpspQuestion.FIREPLACES_OR_WOODSTOVES,
      LmCpspQuestion.BALCONIES,
      // LmCpspQuestion.REQUIRE_WRITTEN_CONTRACTS,  <-- Duplicate, which position do we want?
      LmCpspQuestion.ATTENDANT_ON_DUTY,
      LmCpspQuestion.FOOD_PREP_QUESTION,
      LmCpspQuestion.LIMITED_COOKING_QUESTION,
      LmCpspQuestion.COMMERCIAL_COOKING_QUESTION,
      LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM_MGT_POLICY,
      LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM,
      LmCpspQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL,
      LmCpspQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE,
      LmCpspQuestion.OFF_PREMISES_PROPERTY_DAMAGE_INCLUDING_CARE_CUSTODY_OR_CONTROL,
      [LmCpspQuestion.EACH_OCCURRENCE_AGGREGATE_LIMIT],
      LmCpspQuestion.VOLUNTARY_PROPERTY_DAMAGE_EXTENSION,
    ],
  },
  {
    title: 'Additional coverages',
    slug: 'additional-coverages',
    parent: 'additional-coverages',
    formPath: LmCpspFormStepPath.ADDITIONAL_COVERAGES,
    questions: [
      LmCpspQuestion.ATTACK_EXTORTION,
      [LmCpspQuestion.ATTACK_EXTORTION_LIMIT],
      LmCpspQuestion.BLANKET_ADDITIONAL_INSURED_CONTRACTORS_PRODUCTS_COMPLETED_OPERATIONS,
      LmCpspQuestion.CONSTRUCTION_PROJECTS_GENERAL_AGGREGATE_LIMITS_PER_PROJECT,
      LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      [
        LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT,
        LmCpspQuestion.DATA_COMPROMISE_DEFENSE_AND_LIABILITY,
      ],
      LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY,
      [
        LmCpspQuestion.INTERNAL_COMPLAINT_PROCEDURE,
        LmCpspQuestion.WRITTEN_ANTI_SEXUAL_HARASSMENT,
        LmCpspQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES,
        LmCpspQuestion.EPLI_CLAIMS,
      ],
      LmCpspQuestion.INLAND_MARINE,
      [LmCpspQuestion.TOTAL_LIMIT_CUSTOM_PROTECTOR_PROPERTY_FLOATER],
      LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
      [
        LmCpspQuestion.CLASS_LIQUOR_LIABILITY,
        LmCpspQuestion.EXPOSURE_LIQUOR_LIABILITY,
        LmCpspQuestion.SERVE_ALCOHOL,
        LmCpspQuestion.SERVE_ALCOHOL_TOTAL_SALES,
        LmCpspQuestion.MINORS_LIQUOR_SALE,
        LmCpspQuestion.CITATION_ALCOHOLIC_BEVERAGES,
        LmCpspQuestion.TRAINED_EMPLOYEE_SERVING_ALCOHOL,
      ],
      LmCpspQuestion.NETWORK_SECURITY,
      [LmCpspQuestion.NETWORK_SECURITY_LIMIT],
      LmCpspQuestion.DATA_SECURITY_QUESTION_1,
      LmCpspQuestion.DATA_SECURITY_QUESTION_2,
      LmCpspQuestion.DATA_SECURITY_QUESTION_3,
      LmCpspQuestion.DATA_SECURITY_QUESTION_4,
      LmCpspQuestion.DATA_SECURITY_QUESTION_5,
      LmCpspQuestion.DATA_SECURITY_QUESTION_6,
      LmCpspQuestion.DATA_SECURITY_QUESTION_7,
      LmCpspQuestion.DATA_SECURITY_QUESTION_8,
      LmCpspQuestion.DATA_SECURITY_QUESTION_9,
      LmCpspQuestion.DATA_SECURITY_QUESTION_10,
      LmCpspQuestion.DATA_SECURITY_QUESTION_11,
      LmCpspQuestion.DATA_SECURITY_QUESTION_12,
    ],
  },
];

export const getCpspNode = (name: LmCpspQuestion): FormDslNode => {
  return CPSP_FORM_QUESTIONS[name].formDslNode;
};

export const getCpspNodeConfig = (name: LmCpspQuestion): FormDslConfiguration => {
  return CPSP_FORM_QUESTIONS[name];
};

/**
 * A dictionary for looking up a control's formPath by its step name.
 */
export const lmCpspControlToFormStep = LM_CPSP_QUOTE_FLOW.reduce((dict, step) => {
  const stepPath = step.formPath;
  step.questions.forEach((questionOrList) => {
    if (Array.isArray(questionOrList)) {
      questionOrList.forEach((question) => {
        if (dict[question]) {
          console.warn('controlToFormStep: duplicate question', question);
        }
        dict[question] = stepPath;
      });
    } else {
      if (dict[questionOrList]) {
        console.warn('controlToFormStep: duplicate question', questionOrList);
      }
      dict[questionOrList] = stepPath;
    }
  });

  return dict;
}, {} as { [key in LmCpspQuestion]: string });
