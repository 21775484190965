import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from './core/services/oktaAuth.service';
import { BOOLEAN_FLAG_NAMES, FeatureFlagService } from './core/services/feature-flag.service';
import { UserAttributesService } from './core/services/user-attributes.service';
import { SentryService } from './core/services/sentry.service';

import { Observable, of as observableOf, zip } from 'rxjs';
import { UserAttributes, UserAttributesResponse } from './shared/models/user-attributes';
import { catchError, switchMap } from 'rxjs/operators';
import { some } from 'lodash';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { AmplitudeService } from './core/services/amplitude.service';
import { UserService } from './core/services/user.service';

@Component({
  templateUrl: 'callback.component.html',
})
export class CallbackComponent implements OnInit {
  constructor(
    private okta: OktaAuthService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private sentryService: SentryService,
    private userAttributesService: UserAttributesService,
    private currentUserService: CurrentUserService,
    private amplitudeService: AmplitudeService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    await this.okta.updateAuthenticationObservable();
    // Handles the response from Okta and parses tokens
    this.okta.handleAuthentication().subscribe((storedUrl) => {
      this.performRedirect(storedUrl);
    });
  }

  performRedirect(defaultUrl: string) {
    // Technically because the user has not been updated in the feature flag service this flag will trigger twice
    // if the user being logged in has a different value than the default. In other words this flag should only
    // be enabled/ disabled for all and not for some users.
    const userAttributes = this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.USER_ATTRIBUTES_ONBOARDING_REDIRECT)
      .pipe(
        switchMap((enabled: boolean | null): Observable<UserAttributesResponse | null> => {
          if (!enabled) {
            return observableOf(null);
          }
          return this.userAttributesService.getUserAttribute();
        }),
        catchError((err) => {
          this.sentryService.notify('Failed to load user attributes or feature flag status', {
            severity: 'error',
            metaData: {
              errorMessage: err.message,
            },
          });
          return observableOf(null);
        })
      );

    const userRedirectedFromInvite = sessionStorage.getItem('okta-accept-invite') as string;
    sessionStorage.removeItem('okta-accept-invite');
    let acceptInvite;
    if (userRedirectedFromInvite) {
      acceptInvite = this.userService.acceptInvite(userRedirectedFromInvite).pipe(
        catchError(() => {
          // Accepting an invite adds the user to the admin Okta group if applicable.
          // user.service.ts logs the error in Sentry, we should continue to redirect in case of error.
          return observableOf(null);
        })
      );
    } else {
      acceptInvite = observableOf(null);
    }

    zip(userAttributes, acceptInvite).subscribe(
      ([userAttributesResponse, acceptInviteResponse]) => {
        if (acceptInviteResponse) {
          this.amplitudeService.track({
            eventName: 'accept_invite',
            detail: userRedirectedFromInvite,
          });
        }
        this.amplitudeService.track({
          eventName: 'login',
          detail: this.currentUserService.getCurrentUser()?.username || '',
          useLegacyEventName: true,
        });

        if (!userAttributesResponse) {
          this.router.navigateByUrl(defaultUrl);
        } else if (
          !some(userAttributesResponse.userAttributes, (attribute) =>
            [
              UserAttributes.ONBOARDING_ACCOUNTS_COMPLETE,
              UserAttributes.ONBOARDING_ACCOUNTS_DISMISSED,
            ].includes(attribute.attribute_type)
          )
        ) {
          this.router.navigateByUrl('/onboarding/introduction');
        } else {
          this.router.navigateByUrl(defaultUrl);
        }
      }
    );
  }
}
