<app-form-field-select
  inputId="excess-liability-limit"
  [form]="excessLiabilityFormGroup"
  [submitted]="submitted"
  labelText="Excess Liability Limit"
  nameOfFormControl="excessLiabilityLimit"
  [availableOptions]="availableExcessLiabilityLimits"
>
</app-form-field-select>

<app-form-field-radio
  inputId="schedule-excess-liability-coverage"
  [form]="excessLiabilityFormGroup"
  [submitted]="submitted"
  questionText="Would you like to schedule Employers' Liability Coverage?"
  nameOfFormControl="excessLiabilityCoverageIsScheduled"
>
</app-form-field-radio>

<div class="form-subsection" *ngIf="showCoverageOptions('excessLiabilityCoverageIsScheduled')">
  <app-form-field-text
    inputId="excess-liability-employers-underlying-premium"
    [form]="excessLiabilityFormGroup"
    [submitted]="submitted"
    specifierText="(optional)"
    labelText="Estimated underlying premium"
    nameOfFormControl="excessEmployersUnderlyingPremium"
    appMoneyMask="WITHOUT_DECIMAL"
    placeholder="$"
  >
  </app-form-field-text>

  <app-form-field-select
    inputId="excess-liability-per-accident-coverage"
    [form]="excessLiabilityFormGroup"
    [submitted]="submitted"
    labelText="Per accident"
    nameOfFormControl="excessLiabilityPerAccidentCoverage"
    [readonly]="excessLiabilityPerAccidentCoverageReadOnly()"
    [availableOptions]="formService.excessLiabilityPerAccidentCoverageOptions"
  >
  </app-form-field-select>

  <app-form-field-select
    inputId="excess-liability-per-disease-coverage"
    [form]="excessLiabilityFormGroup"
    [submitted]="submitted"
    labelText="Per disease"
    nameOfFormControl="excessLiabilityPerDiseaseCoverage"
    [readonly]="excessLiabilityPerDiseaseCoverageReadOnly()"
    [availableOptions]="formService.excessLiabilityPerDiseaseCoverageOptions"
  >
  </app-form-field-select>

  <app-form-field-select
    inputId="excess-liability-per-policy-coverage"
    [form]="excessLiabilityFormGroup"
    [submitted]="submitted"
    labelText="Per policy"
    nameOfFormControl="excessLiabilityPerPolicyCoverage"
    [readonly]="excessLiabilityPerPolicyCoverageReadOnly()"
    [availableOptions]="formService.excessLiabilityPerPolicyCoverageOptions"
  >
  </app-form-field-select>
</div>

<app-form-field-radio
  cssClass="js-excess-commercial-auto-coverage"
  inputId="excess-commercialAutoCoverageIsScheduled"
  [form]="excessLiabilityFormGroup"
  [submitted]="submitted"
  questionText="Would you like to schedule Commercial Auto Coverage?"
  nameOfFormControl="excessCommercialAutoCoverageIsScheduled"
>
</app-form-field-radio>

<div
  class="form-subsection js-excess-commercial-auto-coverage-fields"
  *ngIf="showCoverageOptions('excessCommercialAutoCoverageIsScheduled')"
>
  <app-form-field-text
    inputId="excess-liability-commercial-auto-underlying-premium"
    [form]="excessLiabilityFormGroup"
    [submitted]="submitted"
    specifierText="(optional)"
    labelText="Estimated underlying premium"
    nameOfFormControl="excessCommercialAutoUnderlyingPremium"
    appMoneyMask="WITHOUT_DECIMAL"
    placeholder="$"
  >
  </app-form-field-text>
  <app-form-field-select
    inputId="excess-commercialAuto-combinedLimit"
    [form]="excessLiabilityFormGroup"
    [submitted]="submitted"
    labelText="Commercial Auto Coverage Combined Single Limit"
    [availableOptions]="availableCommercialAutoCombinedLimits"
    nameOfFormControl="excessCommercialAutoCombinedLimit"
  >
  </app-form-field-select>

  <app-form-field-text
    inputId="excess-commercialAuto-privatePassengerVehicleCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Private Passenger Vehicles"
    subLabelText="Including hired and non-owned"
    nameOfFormControl="PrivatePassengerVehicleCount"
    inputType="number"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="excess-commercialAuto-lightTrucksCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Light Trucks (0 to 10,000 lbs.)"
    subLabelText="Including 1-8 Passenger Vans"
    nameOfFormControl="LightTrucksCount"
    inputType="number"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="excess-commercialAuto-mediumTrucksCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Medium Trucks (10,001 to 20,000 lbs.)"
    subLabelText="Including 9-20 Passenger Vans"
    nameOfFormControl="MediumTrucksCount"
    inputType="number"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="excess-commercialAuto-heavyTrucksCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Heavy Trucks (20,001 to 45,000 lbs.)"
    subLabelText="Units not for hire"
    nameOfFormControl="HeavyTrucksCount"
    inputType="number"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="excess-commercialAuto-exHvyTruckAndTractorSHCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Short Haul Extra Heavy Trucks and Tractors (over 45,000 lbs.)"
    subLabelText="Units not for hire"
    nameOfFormControl="ExHvyTruckAndTractorSHCount"
    inputType="number"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="excess-commercialAuto-exHvyTruckAndTractorLHCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Long Haul Extra Heavy Trucks and Tractors (over 45,000 lbs.)"
    subLabelText="Units not for hire"
    nameOfFormControl="ExHvyTruckAndTractorLHCount"
    inputType="number"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="excess-commercialAuto-cementMixersCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Cement Mixers"
    nameOfFormControl="CementMixersCount"
    inputType="number"
  >
  </app-form-field-text>
  <app-form-field-text
    inputId="excess-commercialAuto-busesOver20PassengersCount"
    cssClass="form-field__one-liner"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleCounts')"
    [submitted]="submitted"
    labelText="Buses Over 20 Passengers Only"
    nameOfFormControl="BusesOver20PassengersCount"
    inputType="number"
  >
  </app-form-field-text>
  <div *ngIf="submitted && sumOfVehiclesInvalid()" class="validation">
    <div class="validation-message vehicle-sum-validation">
      A scheduled commercial auto policy must cover at least one vehicle.
    </div>
  </div>
  <hr />

  <p class="question-subsection-header">
    Please select applicable exposures for the Insured’s automobile fleet
  </p>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-schoolBusOrVan"
    labelText="School Buses or Vans"
    nameOfFormControl="SchoolBusOrVan"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleExposure')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-policeVehFireTrkAmbulance"
    labelText="Police Vehicles, Fire Trucks, or Ambulances"
    nameOfFormControl="PoliceVehFireTrkAmbulance"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleExposure')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-moreThan10HtlMtlParkVan"
    labelText="Hotel/Motel/Parking Lot Courtesy Vans"
    nameOfFormControl="MoreThan10HtlMtlParkVan"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleExposure')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-moreThan15PassengerCourtseyVan"
    labelText="Courtesy Vans More than 15 Passengers"
    nameOfFormControl="MoreThan15PassengerCourtseyVan"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleExposure')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-rapidDevliveryOperations"
    labelText="Rapid Delivery Operations e.g. Pizza, Magazine, Newspaper"
    nameOfFormControl="RapidDevliveryOperations"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleExposure')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-gasHaulHzdWasteRedLblMat"
    labelText="Gasoline Hauling or Hazardous Waste/Red Label Materials"
    nameOfFormControl="GasHaulHzdWasteRedLblMat"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleExposure')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-commodityIIIORIVHauling"
    labelText="Commodity III or IV Hauling"
    nameOfFormControl="CommodityIIIORIVHauling"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageVehicleExposure')"
  >
  </app-form-field-checkbox>

  <p class="question-subsection-header">
    At any point during the policy period, will you have vehicles registered in, garaged in, or
    traveling within any of the following states? (check all that apply)
  </p>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-hasCommercialAutoInFL"
    labelText="Florida"
    nameOfFormControl="hasCommercialAutoInFL"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageStates')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-hasCommercialAutoInLA"
    labelText="Louisiana"
    nameOfFormControl="hasCommercialAutoInLA"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageStates')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-hasCommercialAutoInNH"
    labelText="New Hampshire"
    nameOfFormControl="hasCommercialAutoInNH"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageStates')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-hasCommercialAutoInVT"
    labelText="Vermont"
    nameOfFormControl="hasCommercialAutoInVT"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageStates')"
  >
  </app-form-field-checkbox>

  <app-form-field-checkbox
    inputId="excess-commercialAuto-hasCommercialAutoInWV"
    labelText="West Virginia"
    nameOfFormControl="hasCommercialAutoInWV"
    [form]="excessLiabilityFormGroup.get('excessCommercialAutoCoverageStates')"
  >
  </app-form-field-checkbox>
</div>

<div>
  <app-form-field-text
    labelText="Annual Revenue"
    [form]="excessLiabilityFormGroup"
    nameOfFormControl="excessAnnualRevenue"
    [submitted]="submitted"
    inputId="excess-annual-revenue"
    appMoneyMask="WITHOUT_DECIMAL"
    placeholder="$"
  >
  </app-form-field-text>
</div>
