import * as _ from 'lodash';
import * as moment from 'moment';
import {
  AGG_LOI_COB_EXCEPTIONS,
  HiscoxGlFormDataFieldV4,
} from 'app/features/hiscox/models/gl-constants';
import { REMOVE_FROM_PAYLOAD } from 'app/features/hiscox/models/hiscox-constants';
import { HISCOX_PRODUCTS, CYBER_UPSELL_QUESTIONS } from './hiscox-types';
import { HiscoxPlFormDataFieldV4 } from './pl-constants';

const PROPER_FIELD_PATTERN = /\w(_\w)+/;

export const decodeArrayData = (formDataPrior: { [key: string]: any }): { [key: string]: any } => {
  let excludedFields: string[] = [];
  const formDataEntriesMapped = _.entries(formDataPrior)
    .map((pair: [string, any]) => {
      const key = pair[0];
      let val = pair[1];
      if (val && Array.isArray(val)) {
        // remove the array values, which are
        // undesirably repeated in the form data
        excludedFields = _.concat(excludedFields, val);
        val = val
          .filter((v: any) => {
            return formDataPrior[v];
          })
          .map((v: any) => {
            // this represents the values that
            // were selected by the user
            return formDataPrior[v];
          });
        if (_.isEmpty(val)) {
          // This is necessary for downstream
          // cleanup by the removeNullData function
          val = null;
        }
      }
      return [key, val];
    })
    .filter((pair: [string, any]) => {
      // remove the array values, which are
      // undesirably repeated in the form data
      return !excludedFields.includes(pair[0]);
    });
  return _.fromPairs(formDataEntriesMapped);
};

/**
 * due to the addition of the checkbox primitive, formData will contain embedded objects,
 * where each key in the object will correspond to a differen xPath. In order for SQ to properly
 * generate a request, the formData that is sent needs to be flat. Therefore, this helper function
 * takes formData, as formDataPrior, before it is modied, and tranverses it to find the objects.
 *
 * Because these objects are checkbox elements, their values are true or false, so this helper
 * function is not only create an individual xPath for each option, but setting the value to 'Yes`,
 * when it is a checked opption.
 *
 * @param formDataPrior formData, before it is reformatted to have flattened objects
 */
export const flattenObjectData = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  // Keep track of those flattend answers
  const flattenedAnwers: { [key: string]: any } = {};
  // Keep track of fields that arre objects, as these will be excluded in favor of the flattend fields
  const excludedFields: string[] = [];

  // this section of the function takes the objects and flattens them and sets their values to Yes, instead of True
  const formDataFieldsToFlatten = _.entries(formDataPrior).filter((formDataPair) => {
    const formDataPairVal = formDataPair[1];
    // if the formDataPair has a value that is an object, return it
    if (formDataPairVal && _.isObject(formDataPairVal)) {
      excludedFields.push(formDataPair[0]);
      return formDataPair;
    }
  });

  // this removes the objects from formData, so they can be replaced with the flattened version
  const formDataFieldsWithoutObjects = _.entries(formDataPrior).filter((pair) => {
    return !excludedFields.includes(pair[0]);
  });

  // this function takes the top level xPath and fixes each flattened field and adds it to flattenedAnwers
  formDataFieldsToFlatten.forEach((formDataField) => {
    const prefixKey = formDataField[0];
    const options = formDataField[1];
    _.entries(options).map((option: [string, any]) => {
      if (option[1]) {
        const newKey: string = prefixKey.concat('_', option[0]);
        flattenedAnwers[newKey] = 'Yes';
      }
    });
  });

  // finally, both the flattenedAnwers and formData without objects are merged together to return new version of formData
  const newFormData = _.fromPairs(formDataFieldsWithoutObjects);
  _.assign(newFormData, flattenedAnwers);
  return newFormData;
};

export const removeMalformedFields = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  const formDataEntriesFiltered = _.entries(formDataPrior).filter((pair: [string, any]) => {
    return PROPER_FIELD_PATTERN.test(pair[0]);
  });
  return _.fromPairs(formDataEntriesFiltered);
};

/**
 * In V4 some paths are set to 'primary-or-secondary', which is an invalid XPath, per the schema,
 * so this helper method sets those fields to the key for the primary location, since we do not
 * support multiple location
 * @param formDataPrior form data being sent to backend
 */
export const renameLocationFields = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  return _.mapKeys(formDataPrior, (_value, key) => {
    if (
      key ===
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS
    ) {
      return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS;
    } else if (
      key ===
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE
    ) {
      return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE;
    } else if (
      key ===
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE
    ) {
      return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE;
    } else if (
      key ===
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU
    ) {
      return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU;
    } else {
      return key;
    }
  });
};

// TODO GL-RELEASE this function and the related functions are
// involved with converting long state name to short state name from
// the first form step to the second.  Adding typing and comments here
// will help make the transforms cleaner.
export const correctStateFormat = (
  formDataPrior: { [key: string]: any },
  state: string
): { [key: string]: any } => {
  const formDataResult: { [key: string]: any } = {};
  const stateKey = 'BusinessInfo_Locations_Primary_AddrInfo_StateOrProvCd';
  _.assign(formDataResult, formDataPrior);
  formDataResult[stateKey] = state;
  return formDataResult;
};

// LOI is an abbreviation for Limit of Incidence - the maximum amount
// covered for any single claim
// Agg LOI is an abbreviation for aggregate LOI, the total that the
// insurance will pay out for all claims
// Hiscox has a particular formular for how AGG_LOI is computed and
// this function implements that formula

// TODO Kayode's note on the following function: I still believe that
// this should be happening via glFieldDefaults in what is now
// src/app/hiscox/components/gl-config.ts, but that can be handled in
// another MR and I am happy to do it myself.

export const coerceAggLoiValue = (
  formDataPrior: { [key: string]: any },
  cob: string
): { [key: string]: any } => {
  const formDataResult: { [key: string]: any } = {};
  const aggLoiKey =
    'ProductQuoteRqs_GeneralLiabilityQuoteRq_GeneralLiabilityCoverQuoteRq_RatingInfo_AggLOI';
  const loiKey =
    'ProductQuoteRqs_GeneralLiabilityQuoteRq_GeneralLiabilityCoverQuoteRq_RatingInfo_LOI';
  _.assign(formDataResult, formDataPrior);
  formDataResult[aggLoiKey] = (parseInt(formDataResult[loiKey], 10) * 2).toString(10);
  if (formDataResult[loiKey] === '2000000') {
    formDataResult[aggLoiKey] = '2000000';
  } else if (_.indexOf(AGG_LOI_COB_EXCEPTIONS, cob) > -1) {
    if (formDataResult[loiKey] === '300000') {
      formDataResult[aggLoiKey] = '300000';
    } else if (formDataResult[loiKey] === '500000') {
      formDataResult[aggLoiKey] = '500000';
    } else if (formDataResult[loiKey] === '1000000') {
      formDataResult[aggLoiKey] = '2000000';
    }
  }
  return formDataResult;
};

export const correctRegularLOI = (formDataPrior: { [key: string]: any }) => {
  const formDataResult: { [key: string]: any } = {};
  _.assign(formDataResult, formDataPrior);
  const loiKey =
    'ProductQuoteRqs_GeneralLiabilityQuoteRq_GeneralLiabilityCoverQuoteRq_RatingInfo_LOI';
  formDataResult[loiKey] = _.get(formDataPrior, loiKey, 1000000);
  return formDataResult;
};

export const coerceAggLoiValuePl = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  const formDataResult: { [key: string]: any } = {};

  const aggLoiKey =
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_AGGLOI;
  const loiKey =
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI;

  _.assign(formDataResult, formDataPrior);
  formDataResult[aggLoiKey] = parseInt(formDataResult[loiKey], 10).toString(10);
  return formDataResult;
};

export const setRequestDate = (formDataPrior: { [key: string]: any }): { [key: string]: any } => {
  const reqDate: string = moment().utc().format('YYYY-MM-DD');

  formDataPrior[HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_QUOTERQDT] =
    reqDate;

  return formDataPrior;
};

export const expandLiabilityQuoteInputIdV4 = (
  productType: HISCOX_PRODUCTS.pl | HISCOX_PRODUCTS.gl,
  abbreviatedId: string
): string => {
  switch (productType) {
    case HISCOX_PRODUCTS.gl:
      return `ProductQuoteRqs_GeneralLiabilityQuoteRq_${abbreviatedId}`;
    case HISCOX_PRODUCTS.pl:
      return `ProductQuoteRqs_ProfessionalLiabilityQuoteRq_${abbreviatedId}`;
  }
};

export const expandV4RatingInfoInputId = (
  productType: HISCOX_PRODUCTS,
  abbreviatedId: string
): string => {
  switch (productType) {
    case HISCOX_PRODUCTS.gl:
      return `ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_${abbreviatedId}`;
    case HISCOX_PRODUCTS.pl:
      return `ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_${abbreviatedId}`;
    default:
      throw new Error(`expandV4RatingInfoInputId not available for ${productType}.`);
  }
};

/**
 * Undoes what renameLocationFields does, so that during edit, the question answers can be displayed in the UI
 * @param formDataPrior
 */
export const expandPrimarySecondaryLocationFields = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  return _.mapKeys(formDataPrior, (_value, key) => {
    switch (key) {
      case HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS:
        return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS;
      case HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE:
        return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE;
      case HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE:
        return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_ESTMTDPAYROLLEXPENSE;
      case HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU:
        return HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_OR_SECONDARY_ADDRINFO_RATINGINFO_SQUAREFEETOCCUPIEDBYYOU;
      default:
        return key;
    }
  });
};

/**
 * When sending SQ the formData for a Hiscox GL V4 request, there are questions that are removed if the
 * selected answer is no. In order to populate these questions' anwers, we need to set them to their default value.
 * This helper function does this.
 * @param formDataPrior
 */
export const defaultAnswersToGlQuestions = (formDataPrior: { [key: string]: any }) => {
  const hasPrimaryNonContribCoverage = _.get(
    formDataPrior,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID,
    false
  );
  const hasWaiverOfSubConverage = _.get(
    formDataPrior,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID,
    false
  );
  const hasBusinessPropertyEquipCoverage = _.get(
    formDataPrior,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID,
    false
  );
  const hasHireNonOwnedAutonCoverage = _.get(
    formDataPrior,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ,
    false
  );
  const hasTerrorismCoverage = _.get(
    formDataPrior,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID,
    false
  );

  if (!hasPrimaryNonContribCoverage) {
    formDataPrior[
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID
    ] = REMOVE_FROM_PAYLOAD;
  }
  if (!hasWaiverOfSubConverage) {
    formDataPrior[
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID
    ] = REMOVE_FROM_PAYLOAD;
  }
  if (!hasBusinessPropertyEquipCoverage) {
    formDataPrior[
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID
    ] = REMOVE_FROM_PAYLOAD;
  }
  if (!hasHireNonOwnedAutonCoverage) {
    formDataPrior[
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ
    ] = REMOVE_FROM_PAYLOAD;
  }
  if (!hasTerrorismCoverage) {
    formDataPrior[
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID
    ] = REMOVE_FROM_PAYLOAD;
  }
};

export const defaultAnswersToPlQuestions = (formDataPrior: { [key: string]: any }) => {
  const hasContingentBodilyInjuryCoverage = _.get(
    formDataPrior,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ_RATINGINFO_LOI,
    true
  );

  if (hasContingentBodilyInjuryCoverage) {
    formDataPrior[
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ
    ] = 'Include';
  }

  if (!hasContingentBodilyInjuryCoverage) {
    formDataPrior[
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ
    ] = REMOVE_FROM_PAYLOAD;
  }

  const hasWaiverOfSubConverage = _.get(
    formDataPrior,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID,
    false
  );

  if (!hasWaiverOfSubConverage) {
    formDataPrior[
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID
    ] = REMOVE_FROM_PAYLOAD;
  }
};

// This helper function uses the productType defined in the component that
// invokes it, e.g. hiscox-pl-quote-page.component.ts, so the productType
// will always be 'pl' or 'gl', not the v4 version used for routing purposes
export const expandApplicationRatingInfoInputId = (
  productType: HISCOX_PRODUCTS.pl | HISCOX_PRODUCTS.gl,
  abbreviatedId: string
): string => {
  switch (productType) {
    case HISCOX_PRODUCTS.pl:
    case HISCOX_PRODUCTS.gl:
      return `ProductQuoteRqs_ApplicationRatingInfo_${abbreviatedId}`;
  }
};

// This helper function uses the productType defined in the component that
// invokes it, e.g. hiscox-pl-quote-page.component.ts, so the productType
// will always be 'pl' or 'gl', not the v4 version used for routing purposes
export const expandProfessionalLiabilityQuoteRqInputId = (
  productType: HISCOX_PRODUCTS.pl | HISCOX_PRODUCTS.gl,
  abbreviatedId: string
): string => {
  switch (productType) {
    case HISCOX_PRODUCTS.pl:
      return `ProductQuoteRqs_ProfessionalLiabilityQuoteRq_${abbreviatedId}`;
    case HISCOX_PRODUCTS.gl:
      return `ProductQuoteRqs_GeneralLiabilityQuoteRq_${abbreviatedId}`;
  }
};

export const retrieveSecondaryCobFieldsSelected = (
  productType: HISCOX_PRODUCTS,
  secondaryCobs: string[]
) => {
  const secondaryCobFieldEntries = secondaryCobs.map((key) => {
    let newKey;
    switch (productType) {
      case HISCOX_PRODUCTS.gl:
        newKey = `ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd-${key}`;
        break;
      case HISCOX_PRODUCTS.pl:
        newKey = `ProductQuoteRqs_GErrorsAndOmissionsQuoteRq_RatingInfo_SecondaryCOBSmallContractors_ClassOfBusinessCd-${key}`;
        break;
      default:
    }
    return [newKey, key];
  });
  return _.fromPairs(secondaryCobFieldEntries);
};

/**
 * If a PL/GL data form field needs to be removed based on the selection the user makes,
 * it can be added to this dictionary.
 *
 * Example: the WaiverOfSubrogationCoverQuoteRq question should be excluded if the user select 'Do not include'
 */
const PL_FIELDS_TO_REMOVE_BY_VALUE: { [key: string]: string } = {
  // PL fields
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ]:
    'Include',
};

/**
 * In Hiscox V4, some questions have to be asked, e.g. include or not include, but if the user selects to not include
 * we need to exclude it from the formData. This data structure keeps track of these questions, so they can be removed,
 * when their value is as noted.
 */
const GL_FIELDS_TO_REMOVE_BY_VALUE: { [key: string]: string | string[] } = {
  // GL fields
  // Note: removing because this data field is not in the schema, but is used to conditionally show LOI question
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ]: [
    'Include',
    'Do not include',
  ],
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PROPERTYANDEQUIPMENTCOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_PRIMARYNONCONTRIBCOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
  [HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_TRIACOVERQUOTERQ_COVERID]:
    REMOVE_FROM_PAYLOAD,
};

/**
 * For each field defined in FIELDS_TO_REMOVE_BY_VALUE, if the formData contains this key and the value
 * matches the one specified in FIELDS_TO_REMOVE_BY_VALUE, then the field is removed from formData,
 * and it is not send to SQ.
 *
 * @param formDataPrior formData datastructure
 * @param version quote version, since this method will only be executed on a v4 version of the quote
 */

export const removeFieldsBasedOnValue = (
  formDataPrior: { [key: string]: any },
  productType: HISCOX_PRODUCTS
) => {
  if (productType === HISCOX_PRODUCTS.pl) {
    for (const formDataField in PL_FIELDS_TO_REMOVE_BY_VALUE) {
      if (Object.prototype.hasOwnProperty.call(formDataPrior, formDataField)) {
        const currentFieldValue: string = PL_FIELDS_TO_REMOVE_BY_VALUE[formDataField];
        if (formDataPrior[formDataField] === currentFieldValue) {
          delete formDataPrior[formDataField];
        }
      }
    }
  }
  if (productType === HISCOX_PRODUCTS.gl) {
    for (const formDataField in GL_FIELDS_TO_REMOVE_BY_VALUE) {
      if (Object.prototype.hasOwnProperty.call(formDataPrior, formDataField)) {
        const valuesToRemove: string | string[] = GL_FIELDS_TO_REMOVE_BY_VALUE[formDataField];
        if (_.isArray(valuesToRemove)) {
          if (valuesToRemove.includes(formDataPrior[formDataField])) {
            delete formDataPrior[formDataField];
          }
        }

        if (!_.isArray(valuesToRemove) && formDataPrior[formDataField] === valuesToRemove) {
          delete formDataPrior[formDataField];
        }
      }
    }
  }
  return formDataPrior;
};

/**
 * If a PL data form field needs to be removed because it is not required for the quote request,
 * it can be added to this data structure.
 *
 * Example: the ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ContingentBodilyInjuryCoverQuoteRq
 * is a field used to help conditionally show the LOI question that is required in the request
 */
const FIELDS_TO_REMOVE = {
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ]:
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ,
};

/**
 * For each field defined in FIELDS_TO_REMOVE, if the formData contains this form data field,
 * then remove it before sending to SQ.
 *
 * @param formDataPrior formData datastructure
 * @param version quote version, since this method will only be executed on a v4 version of the quote
 */

export const removeFieldsNotRequiredInRequest = (formDataPrior: { [key: string]: any }) => {
  for (const formDataField in FIELDS_TO_REMOVE) {
    if (Object.prototype.hasOwnProperty.call(formDataPrior, formDataField)) {
      delete formDataPrior[formDataField];
    }
  }

  return formDataPrior;
};

/**
 * Removes fields needed only for cyber quote, so that they are not to SQ
 * as part  of the Hiscox quote.
 *
 * @param formDataPrior formData data structure
 */
export const removeCyberUpsellFields = (formDataPrior: { [key: string]: any }) => {
  return _.omit(formDataPrior, CYBER_UPSELL_QUESTIONS);
};
