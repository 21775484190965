export const invoiceStatus = (invoice: BackendListInvoice): string => {
  switch (invoice.status) {
    case 'Billed':
      return 'billed';
    case 'Due':
      return 'past-due';
    case 'Planned':
      return 'scheduled';
    case 'Paid':
      return 'paid';
    case 'Void':
      return 'void';
    default:
      return 'unknown';
  }
};

export const invoiceStatusClass = (invoice: BackendListInvoice): string => {
  switch (invoice.status) {
    case 'Billed':
      return 'main';
    case 'Due':
      return 'danger';
    case 'Planned':
      return 'main';
    case 'Paid':
      return 'success';
    case 'Void':
      return 'neutral';
    default:
      return 'unknown';
  }
};
