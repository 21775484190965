import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo-with-text',
  templateUrl: './logo-with-text.component.html',
})
export class LogoWithTextComponent {
  @Input() rainbow = false;
  @Input() class = '';
  @Input() darkText = true;

  constructor() {}
}
