<a
  *ngIf="showCancelButton"
  class="button button__nav button__nav-sidebar-quote button__no-margin button__nav-white-bg"
  [routerLink]="['/accounts/', accountId]"
>
  <span class="icon-arrow-left button-icon"></span>
  Cancel
</a>

<header class="quote-sidebar">
  <div class="product-badge product-badge__{{ product }} product-badge__sidebar">
    <div
      *ngIf="pasSource"
      class="product-badge-carrier-icon product-badge-carrier-icon__{{ pasSource }}"
    ></div>
  </div>
  <h3 class="h3">{{ sidebarTitle }}</h3>
  <small>{{ insAccount?.companyName }}</small>

  <ul class="sidebar-breadcrumbs">
    <ng-container *ngFor="let step of getSteps()">
      <li
        [ngClass]="{
          'sidebar-breadcrumb': true,
          'sidebar-breadcrumb__active': this.isCurrentStep(step),
          'sidebar-breadcrumb__substep': step.substep,
          'sidebar-breadcrumb__disabled': disableNavigationToStep
        }"
        (click)="handleStepClicked(step)"
        (keyup.enter)="handleStepClicked(step)"
      >
        {{ step.displayName }}
      </li>
    </ng-container>
  </ul>

  <div *ngIf="isDevMode || isSandbox">
    <div *ngIf="bopVersion()" class="adminz-only">
      <p>
        BOP Version: <strong>{{ bopVersion() }}</strong> <br />Base State:
        <strong>{{ bopState().state }}</strong> <br />V2 Go-Live for {{ bopState().state }}:
        <strong>{{ bopState().date || 'None' }}</strong>
      </p>
    </div>
  </div>

  <div *ngIf="isDevMode || isSandbox">
    <div class="adminz-only">
      <div>
        <button (click)="debugSaveForm()" class="button button__primary">Save Form State</button>

        <button (click)="debugLoadForm()" class="button button__primary">Load Form State</button>
      </div>

      <button
        (click)="happyPathHandler()"
        class="button button__primary js-bop-quote-form-happy-path-button"
      >
        Happy Path Button
      </button>

      <button (click)="southCarolinaPathHandler()" class="button button__small button__secondary">
        SC Path Button
      </button>

      <button (click)="shortcutHandler()" class="button button__small button__secondary">
        Super Shortcut
      </button>

      <button (click)="ineligibleLocationHandler()" class="button button__small button__secondary">
        Ineligible Location
      </button>
    </div>
  </div>
</header>
