<section class="form-animation">
  <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">Guidelines</h1>

  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-guidelines-areBuildingsBuiltAfter1980"
    questionText="Were all buildings built 1980 or later?"
    nameOfFormControl="areBuildingsBuiltAfter1980"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-radio>
  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-guidelines-isRiskApartmentBuildingsOnly"
    questionText="Is the risk apartment buildings only (no condos or co-ops)?"
    nameOfFormControl="isRiskApartmentBuildingsOnly"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-radio>
  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-guidelines-is4StoriesOrUnder"
    questionText="Are all buildings 4 stories or under?"
    nameOfFormControl="is4StoriesOrUnder"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-radio>
  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-guidelines-isBetween5And75Units"
    questionText="Do all buildings contain between 5 and 75 units?"
    nameOfFormControl="isBetween5And75Units"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-radio>
  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-guidelines-isAtLeast80PercentOccupied"
    questionText="Are at least 80% of units in all buildings occupied?"
    nameOfFormControl="isAtLeast80PercentOccupied"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-radio>
  <app-form-field-radio
    questionCssClass="radio-question__small"
    optionsCssClass="radio-question-option__small"
    inputId="hab-guidelines-isCompliantWithTivLimits"
    questionText="Does the risk comply with the following TIV limits?"
    additionalQuestionText="[Per building: $5M, per location: $20M ($12.5M if frame), per policy: $40M]"
    nameOfFormControl="isCompliantWithTivLimits"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-radio>

  <h2 class="form-section-header">Ineligibility qualifications</h2>
  <p>The presence of the following disqualifiers will be inspected.</p>

  <app-hab-sectioned-guidelines></app-hab-sectioned-guidelines>

  <h2 class="form-section-header">Inspection details</h2>
  <div class="inspection-guidelines">
    <ul>
      <li>Inspections are ordered upon binding on all accounts</li>
      <li>
        Inspections on all habitational risks are performed within seven (7) calendar days after the
        policy is bound regardless of its effective date
      </li>
      <li>Inspections not completed may result in cancellation of coverage</li>
      <li>
        Inspections reflective of characteristics outside of program eligibility may result in
        cancellation of coverage
      </li>
    </ul>
  </div>

  <p class="hab-guidelines-download">
    <a
      href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/Attune+Apartments+User+Guide.pdf"
      target="_blank"
      >Click here to download</a
    >
    these guidelines as a PDF.
  </p>

  <app-form-field-checkbox
    inputId="hab-guidelines-hasReadGuidelines"
    labelText="I have read and understand these guidelines"
    nameOfFormControl="hasReadGuidelines"
    [submitted]="hasSubmitted()"
    [form]="form"
  >
  </app-form-field-checkbox>
</section>
