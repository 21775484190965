<main id="set-password" class="login-box">
  <app-logo-with-text></app-logo-with-text>

  <h1 class="login-title-margin">{{ displayMessage() }}</h1>
  <p class="gray-text">{{ messageSubtitle() }}</p>

  <div id="okta-widget-container"></div>
</main>

<p class="terms-of-use-text">
  By accessing Attune, you agree to the following
  <a href="https://www.attuneinsurance.com/legal/terms-of-use/">Terms of Use</a>.
</p>
<app-footer showPhoneNumber="true"> </app-footer>
