import * as _ from 'lodash';

import {
  fieldOverridesV4,
  FIELDS_TO_REMOVE,
  NODES_TO_ALWAYS_INCLUDE,
  HNOA_FIELDS_TO_REMOVE,
} from 'app/features/hiscox/models/gl-config';
import {
  GL_ORDERED_FIELDS_V4,
  GL_BUSINESS_CLASSES_V4,
  BUSINESS_CLASS_OVERRIDES,
  GL_HNOA_COVERAGE_EXCLUDED_COBS,
} from 'app/features/hiscox/models/gl-constants';
import {
  FormDslNode,
  checkboxGroupNode,
  CheckboxNodeConfig,
  MultiInputNode,
  TextNode,
  CheckBoxGroupNode,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { shouldApplyOverrideNode } from './cob-overrides';
import { HISCOX_PRODUCTS, DynamicQuestionsResponse, Question, GlV4Overrides } from './hiscox-types';

const GL_HNOA_EXCLUDED_STATES = ['IL', 'LA', 'VT', 'WA'];

const FIELD_ID_PREFIX = '_InsuranceSvcRq_QuoteRq_';

// create form input ID for a given field
export const createInputId = (xpath: string): string => {
  // Note: some xPath values coming from Hiscox have double \\, e.g. \\MergerAcquision, so replaces \ or \\ with _
  return xpath.replace(/[\/]+/g, '_').split(FIELD_ID_PREFIX)[1];
};

// display the question label text for a given question
const getLabelText = (dynamicForm: any) => {
  return dynamicForm.Description;
};

// create a radio node in form-dsl for v4 question
const convertV4QuestionToRadio = (dynamicForm: Question): MultiInputNode => {
  const form: MultiInputNode = {
    primitive: 'RADIO',
    inputId: createInputId(dynamicForm.XPath || ''),
    nameOfFormControl: createInputId(dynamicForm.XPath || ''),
    required: true,
    labelText: getLabelText(dynamicForm),
    options: _.zipObject(
      _.map(dynamicForm.Responses, 'Value'),
      _.map(dynamicForm.Responses, 'Value')
    ),
  };

  return form;
};

// create a text node in form-dsl for v4 question
const convertV4QuestionToText = (dynamicForm: Question): TextNode => {
  const form: TextNode = {
    primitive: 'TEXT',
    inputId: createInputId(dynamicForm.XPath || ''),
    nameOfFormControl: createInputId(dynamicForm.XPath || ''),
    required: true,
    labelText: getLabelText(dynamicForm),
    inputType: 'text',
  };

  // Note: this section will replace the text primitive with a MONEY primitive for question that requires a MONEY value
  const moneyKeywords = /revenue|payroll/;
  if (dynamicForm.Description && moneyKeywords.test(dynamicForm.Description)) {
    return {
      ...form,
      primitive: 'MONEY_WITHOUT_DECIMAL',
    };
  }

  // Note: this section will replace the text primitive with a number primitive for question that requires a number value
  const numericKeywords = /num/;
  if (dynamicForm.Name && numericKeywords.test(dynamicForm.Name.toLowerCase())) {
    return {
      ...form,
      primitive: 'NUMBER',
    };
  }

  // Note: this section will replace the text primitive with a DATE primitive for question that requires a DATE value
  const dateKeywords = /date/;
  if (dynamicForm.Description && dateKeywords.test(dynamicForm.Description.toLowerCase())) {
    return {
      ...form,
      primitive: 'DATE',
    };
  }
  return form;
};

// create a dropdown node in form-dsl for v4
const convertV4QuestionToDropdown = (dynamicForm: Question): MultiInputNode => {
  const form: MultiInputNode = {
    primitive: 'SELECT',
    inputId: createInputId(dynamicForm.XPath || ''),
    nameOfFormControl: createInputId(dynamicForm.XPath || ''),
    required: true,
    labelText: getLabelText(dynamicForm),
    options: {},
  };

  const responseValues = dynamicForm.Responses;
  const options = _.zipObject(_.map(responseValues, 'Value'), _.map(responseValues, 'Value'));
  _.assign(form, { options: options });

  return form;
};

/**
 * This helper method removes special characters from a checkbox value, e.g. { Value: "Carpentry (interior only)" },
 * so this can be used to build the value for 'nameOfFormControl'
 * @param value the checkbox option text
 */
export const cleanUpValueToConvertIntoControlName = (value: string) => {
  return value.replace(/[^\w\s]/gi, '');
};

/**
 * Some option values have html tags, e.g. '<b>None of the above<\b>' option, and these need to be removed
 * so the option values can be used to create the nameOfFormControl
 * @param value the checkbox option text
 */
export const removeHtmlTags = (value: string) => {
  return value.replace(/<[^>]*>/gi, '');
};

// create a checkbox node in form-dsl for v4 question
const convertV4QuestionToCheckbox = (dynamicForm: Question): CheckBoxGroupNode => {
  const inputId = createInputId(dynamicForm.XPath || '');
  let checkboxConfigs: CheckboxNodeConfig[] = [];
  if (dynamicForm.Responses) {
    checkboxConfigs = dynamicForm.Responses.map((option) => {
      let value = option.Value as string;
      // Note: for option values that have html tags, e.g. '<b>None of the above<\b>' option, remove tags
      value = removeHtmlTags(value);
      // Note: if there have double backslashes, remove them
      const cleanedUpKey = cleanUpValueToConvertIntoControlName(value);
      const optionArray = cleanedUpKey.split(' ');
      // Capitalize all the works in the option's value
      const capitalizedArray = optionArray.map((word) => {
        return word[0].toUpperCase() + word.slice(1);
      });
      // Join the capitalized words into one to create the 'nameOfFormControl'
      const optionString = capitalizedArray.join('');

      return {
        labelText: value,
        nameOfFormControl: optionString,
      };
    });
  }

  const baseCheckboxGroupNode = checkboxGroupNode({
    nameOfFormControl: inputId,
    labelText: getLabelText(dynamicForm),
    checkboxConfigs: checkboxConfigs,
    required: true,
  });
  return baseCheckboxGroupNode;
};

const convertV4QuestionWithBusinessActivityToCheckbox = (
  dynamicForm: Question
): CheckBoxGroupNode => {
  // create a checkbox node in form-dsl for v4 question
  const inputId = createInputId(dynamicForm.XPath || '');
  let checkboxConfigs: CheckboxNodeConfig[] = [];
  if (dynamicForm.BusinessActivity) {
    checkboxConfigs = dynamicForm.BusinessActivity.map((option) => {
      return {
        labelText: option.BusinessActivityDescription,
        nameOfFormControl: option.BusinessActivityName,
      };
    });
  }

  const baseCheckboxGroupNode = checkboxGroupNode({
    nameOfFormControl: inputId,
    labelText: getLabelText(dynamicForm),
    checkboxConfigs: checkboxConfigs,
    required: true,
  });

  return baseCheckboxGroupNode;
};

const convertV4QuestionToDate = (dynamicForm: Question): TextNode => {
  return {
    primitive: 'DATE',
    inputId: createInputId(dynamicForm.XPath || ''),
    nameOfFormControl: createInputId(dynamicForm.XPath || ''),
    required: true,
    labelText: getLabelText(dynamicForm),
  };
};

const questionTypeMatchToPrimitive: any = {
  Radio: convertV4QuestionToRadio,
  Text: convertV4QuestionToText,
  Dropdown: convertV4QuestionToDropdown,
  Checkbox: convertV4QuestionToCheckbox,
  Date: convertV4QuestionToDate,
  ComplexCheckbox: convertV4QuestionWithBusinessActivityToCheckbox,
};

class PeekIterator {
  private list: any[];
  private counter: number;
  constructor(startList: any[]) {
    this.list = startList;
    this.counter = 0;
  }

  take() {
    const retVal = this.list[this.counter];
    this.counter++;
    return retVal;
  }
  peek() {
    return this.list[this.counter + 1];
  }

  canTake() {
    return this.counter < this.list.length;
  }

  canPeek() {
    return this.counter < this.list.length - 1;
  }
}

// Override automated form-dsl generation for a field
// based on class of business and additional factors
// For v4, we additionally need the state for some fieilds
const overrideField = (node: FormDslNode, cob: string, state: string | null): FormDslNode => {
  const fieldOverrides: Record<string, GlV4Overrides> = fieldOverridesV4;
  const businessClasses: { [key: string]: string } = GL_BUSINESS_CLASSES_V4;

  // In the old implementation of form DSL, every node has an inputId, but with the newer version of
  // form DSL, nodes such as CHECKBOX_GROUP, only have a nameOfFormControl value, which is
  // used for the inputId, but is not accessible here, so we need to check for nameOfFormControl
  const override =
    fieldOverrides[(node as any).inputId] || fieldOverrides[(node as any).nameOfFormControl];
  if (override) {
    const baseOverride = { ...node, ...override };

    // factor in class of business overrides
    if (baseOverride.cobOverrideSets) {
      const cobOverrideSets = baseOverride.cobOverrideSets;
      const cobOverride = cobOverrideSets.find((cos) => {
        if (state && cos.states && !cos.states.includes(state)) {
          return false;
        }
        const cobGroupName = cos.cobSet;
        // if cobGroupName is an array, check if the logical group includes cob
        // either in the array or in the associated BUSINESS_CLASS_OVERRIDES member
        if (Array.isArray(cobGroupName)) {
          return cobGroupName.some((cgn) => {
            return shouldApplyOverrideNode(
              _.keys(businessClasses),
              BUSINESS_CLASS_OVERRIDES,
              cgn,
              cob,
              HISCOX_PRODUCTS.gl
            );
          });
        }
        // check if there is a BUSINESS_CLASS_OVERRIDES member that includes cob
        return shouldApplyOverrideNode(
          _.keys(businessClasses),
          BUSINESS_CLASS_OVERRIDES,
          cobGroupName,
          cob,
          HISCOX_PRODUCTS.gl
        );
      });
      if (cobOverride) {
        return { ...baseOverride, ...cobOverride.overrides } as FormDslNode;
      }
    }

    return baseOverride as FormDslNode;
  }
  return node;
};

export const nestTree = (treeList: any[]) => {
  const lookForPage = 'About Your Customer';
  const accum: any[] = [];
  const iter = new PeekIterator(treeList);
  while (iter.canTake()) {
    const tempNode = iter.take();
    if (tempNode.page === lookForPage) {
      const childAccum: any[] = [tempNode];
      while (iter.canTake()) {
        const testNode = iter.peek();
        if (testNode.page === lookForPage) {
          childAccum.push(iter.take());
        } else {
          break;
        }
      }
      accum.push({
        primitive: 'DIV',
        cssClass: 'dsl-example-border',
        children: childAccum,
      });
    }
    accum.push(tempNode);
  }
  return accum;
};

export const nestTreeGeneric = (
  treeList: any[],
  predicate: (testNode: any) => boolean,
  grouper: (groupList: any[]) => any
) => {
  const accum: any[] = [];
  const iter = new PeekIterator(treeList);
  while (iter.canTake()) {
    const tempNode = iter.take();
    if (predicate(tempNode)) {
      const childAccum: any[] = [tempNode];
      while (iter.canTake()) {
        const testNode = iter.peek();
        if (predicate(testNode)) {
          childAccum.push(iter.take());
        } else {
          break;
        }
      }
      accum.push(grouper(childAccum));
    }
    accum.push(tempNode);
  }
  return accum;
};

// remove fields that should not be shown on the second step of the form, e.g. fields that will be populated in the backend
const removeFieldsFilter = (elem: any): boolean => {
  return !_.includes(FIELDS_TO_REMOVE, elem.inputId);
};

const removeHnoaFieldsFilter = (elem: any): boolean => {
  return !_.includes(HNOA_FIELDS_TO_REMOVE, elem.inputId);
};

// order the fields as specified in 'src/app/hiscox/components/gl-config.ts'
const orderTree = (treeUnordered: any[]): any[] => {
  const ORDERED_FIELDS: string[] = GL_ORDERED_FIELDS_V4;

  const tree = treeUnordered.slice();
  tree.sort((n1, n2) => {
    const n1Id = n1.inputId;
    const n2Id = n2.inputId;
    return ORDERED_FIELDS.indexOf(n1Id) - ORDERED_FIELDS.indexOf(n2Id);
  });
  return tree;
};

const isDropdownTypeQuestion = (question: Question) => {
  return (
    _.isArray(question.Responses) &&
    question.Responses.length > 2 &&
    question.Description &&
    !question.Description.startsWith('Select all') &&
    !question.Description.includes('check all that apply')
  );
};

const isCheckboxTypeQuestion = (question: Question) => {
  return (
    _.isArray(question.Responses) &&
    question.Responses.length > 2 &&
    question.Description &&
    (question.Description.startsWith('Select all') ||
      question.Description.includes('check all that apply'))
  );
};

const isDateTypeQuestion = (question: Question) => {
  return _.isUndefined(question.Responses) && question.XPath && question.XPath.endsWith('Date');
};

/**
 * This helper function can be used to translate each of the question from
 * the Dynamic Questions (DQs) API endpoint response into FormDsl Nodes
 *
 * @param hiscoxQuestions Hiscox's DQs response
 * @param version Hiscox quote version, v4 (new)
 *
 */
const convertV4DynamicQsToFormTree = (hiscoxQuestions: any, state: string): FormDslNode[] => {
  const questionsResponse = hiscoxQuestions as DynamicQuestionsResponse;
  const classOfBusiness = questionsResponse.QuestionsResponse.QuestionsList.ClassOfBusinessCd;
  const questions = questionsResponse.QuestionsResponse.QuestionsList.Questions;

  let tree = _.map(questions, (question: Question) => {
    let convertFunction;
    if (isDropdownTypeQuestion(question)) {
      // Note: When Responses is an array and has more than 2 options, and the description DOES NOT start with 'Select all', it is a SELECT
      convertFunction = questionTypeMatchToPrimitive.Dropdown;
    } else if (isCheckboxTypeQuestion(question)) {
      // Note: When Responses is an array, has more than 2 options, and the description starts with 'Select all', it is a CHECKBOX
      convertFunction = questionTypeMatchToPrimitive.Checkbox;
    } else if (question.ContainsBusinessActivity === 'T' && question.BusinessActivity) {
      // Note: some questions have the checkbox options in the BusinessActivity property, rather than the Responses property
      convertFunction = questionTypeMatchToPrimitive.ComplexCheckbox;
    } else if (_.isArray(question.Responses) && question.Responses.length === 2) {
      convertFunction = questionTypeMatchToPrimitive.Radio;
    } else if (isDateTypeQuestion(question)) {
      convertFunction = questionTypeMatchToPrimitive.Date;
    } else {
      // Note: treating all other questions as text nodes
      convertFunction = questionTypeMatchToPrimitive.Text;
    }
    return overrideField(convertFunction(question), classOfBusiness, state);
  });
  // Note: some fields are being remove, e.g. those that do not have enough details to determine the PRIMITIVE
  // or those that we are showing in the first step or the acknowledgements modal
  tree = _.filter(tree, removeFieldsFilter);

  // TODO: Remove this section when Hiscox stops sending `GLHireNonOwnVehicleCoverage` for all requests
  // GLHireNonOwnVehicleCoverage question should be shown to all COBs except the following
  const cobs = _.map(GL_HNOA_COVERAGE_EXCLUDED_COBS, (cobString: string) => {
    return cobString.split('.')[0];
  });

  // GLHireNonOwnVehicleCoverage should not be shown if it is returned in DQs response for the following states
  if (cobs.includes(classOfBusiness) || GL_HNOA_EXCLUDED_STATES.includes(state)) {
    tree = _.filter(tree, removeHnoaFieldsFilter);
  }
  // END: Remove section above when Hiscox stops sending `GLHireNonOwnVehicleCoverage` for all requests

  // Note: some nodes, such as Terrorism coverage as text fields that should be radio buttons,
  // so those nodes are defined in a separate data structure, so they can always be added
  tree = tree.concat(NODES_TO_ALWAYS_INCLUDE);

  // Note: the GL_ORDERED_FIELDS_V4 data structure defines in which order the question should be shown
  // so we can use this to order the fields
  tree = orderTree(tree);

  return tree;
};

// convert hiscox questions to form-dsl representation
export const convertToFormTree = (hiscoxQuestions: any, state: string): FormDslNode[] => {
  return convertV4DynamicQsToFormTree(hiscoxQuestions, state);
};
