<a
  *ngIf="showCancelButton"
  class="button button__nav button__nav-sidebar-quote button__no-margin button__nav-white-bg"
  [routerLink]="['/accounts/', accountId]"
>
  <span class="icon-arrow-left button-icon"></span>
  Cancel
</a>

<header class="quote-sidebar">
  <div class="product-badge product-badge__{{ product }} product-badge__sidebar">
    <div class="product-badge-carrier-icon product-badge-carrier-icon__liberty_mutual"></div>
  </div>
  <h3 class="h3">{{ sidebarTitle }}</h3>
  <small>{{ insAccount?.companyName }}</small>

  <ul class="sidebar-breadcrumbs">
    <ng-container *ngFor="let step of getSteps()">
      <li
        [ngClass]="{
          'sidebar-breadcrumb': true,
          'sidebar-breadcrumb__active': this.isCurrentStep(step),
          'sidebar-breadcrumb__substep': step.substep,
          'sidebar-breadcrumb__disabled': disableNavigationToStep
        }"
        (click)="handleStepClicked(step)"
        (keyup.enter)="handleStepClicked(step)"
      >
        {{ step.displayName }}
      </li>
    </ng-container>
  </ul>
</header>
