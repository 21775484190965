import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { initSentry } from './app/sentry';
import { initDatadogRum } from './app/dd-rum';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

initSentry();
initDatadogRum();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.warn(err));
