<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div
    class="{{ cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(nameOfFormControl) }"
  >
    <!-- TODO: Update the CSS so that labels don't have margins. -->
    <label [for]="inputId" [attr.data-specifier]="specifierText">
      {{ labelText }}
      <ng-content select="*[role=label]"></ng-content>
      <div *ngIf="tooltipText" class="{{ tooltipCssClass }}" attr.data-tooltip="{{ tooltipText }}">
        <span class="icon icon-question"></span>
      </div>
      <ng-container *ngIf="subLabelText">
        <small class="form-field-sub-label">{{ subLabelText }}</small>
      </ng-container>
    </label>
    <input
      [ngClass]="{ 'disable-spin-button': isNumberType(), 'fs-block': isSensitiveInfo }"
      [attr.aria-describedby]="describedBy || ''"
      [attr.data-amplitude-redact]="isSensitiveInfo"
      [type]="inputType"
      [id]="inputId"
      [name]="inputId"
      [value]="derivedValue()"
      [formControl]="form.get(nameOfFormControl)"
      [maxlength]="maxLength"
      [minlength]="minLength"
      [placeholder]="placeholder"
      (focusout)="handleFocusOut()"
      [readonly]="readonly"
      [autocomplete]="autocompleteValue()"
      [attr.data-dd-privacy]="mask ? 'mask' : null"
    />
    <div
      *ngIf="fieldInvalid(nameOfFormControl) && shouldShowErrorText()"
      class="form-field-error-text"
    >
      {{ getErrorText() }}
    </div>
    <ng-content></ng-content>
    <p class="form-field-note" *ngIf="questionNote" [innerHTML]="questionNote"></p>
  </div>
</ng-container>
