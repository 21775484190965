<ng-container *ngFor="let node of getFormTree()">
  <app-form-field-text
    *ngIf="node.primitive === 'TEXT'"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [inputType]="node.inputType"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [questionNote]="node.questionNote"
    [specifierText]="node.specifierText"
    [cssClass]="node.cssClass"
    [maxLength]="node.maxLength"
    [minLength]="node.minLength"
    [placeholder]="node.placeholder || ''"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [nameOfFormControl]="node.nameOfFormControl"
    [submitted]="submitted"
  >
  </app-form-field-text>

  <app-form-field-address-autocomplete
    *ngIf="node.primitive === 'ADDRESS_AUTO_COMPLETE'"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [inputType]="node.inputType"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [questionNote]="node.questionNote"
    [specifierText]="node.specifierText"
    [cssClass]="node.cssClass"
    [maxLength]="node.maxLength"
    [minLength]="node.minLength"
    [placeholder]="node.placeholder || ''"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [nameOfFormControl]="node.nameOfFormControl"
    [submitted]="submitted"
    (newAutoCompleteAddress)="newAutoCompleteAddress.emit($event)"
  >
  </app-form-field-address-autocomplete>

  <app-form-field-text
    *ngIf="node.primitive === 'TEXT_NO_WHITESPACE'"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [inputType]="node.inputType"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [questionNote]="node.questionNote"
    [specifierText]="node.specifierText"
    [cssClass]="node.cssClass"
    [maxLength]="node.maxLength"
    [minLength]="node.minLength"
    [placeholder]="node.placeholder || ''"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [nameOfFormControl]="node.nameOfFormControl"
    [submitted]="submitted"
    appRemoveWhitespaceMask
  >
  </app-form-field-text>

  <app-form-field-text
    *ngIf="node.primitive === 'FEIN'"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [inputType]="node.inputType"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [questionNote]="node.questionNote"
    [specifierText]="node.specifierText"
    [cssClass]="node.cssClass"
    [maxLength]="node.maxLength"
    [minLength]="node.minLength"
    [placeholder]="node.placeholder || ''"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [nameOfFormControl]="node.nameOfFormControl"
    [submitted]="submitted"
    appFeinMask
  >
  </app-form-field-text>

  <app-form-field-text
    *ngIf="node.primitive === 'NUMBER'"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [questionNote]="node.questionNote"
    [specifierText]="node.specifierText"
    [tooltipText]="node.tooltipText"
    [cssClass]="node.cssClass"
    [maxLength]="node.maxLength"
    [minLength]="node.minLength"
    [placeholder]="node.placeholder || ''"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [nameOfFormControl]="node.nameOfFormControl"
    [submitted]="submitted"
    appNumberMask
  >
  </app-form-field-text>

  <app-form-field-text
    *ngIf="node.primitive === 'PHONE'"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [nameOfFormControl]="node.nameOfFormControl"
    [maxLength]="node.maxLength"
    [placeholder]="node.placeholder || ''"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [submitted]="submitted"
    appPhoneMask
  >
  </app-form-field-text>

  <app-form-field-text
    *ngIf="node.primitive === 'MONEY_WITHOUT_DECIMAL'"
    placeholder="$"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [specifierText]="node.specifierText"
    [questionNote]="node.questionNote"
    [nameOfFormControl]="node.nameOfFormControl"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [submitted]="submitted"
    [cssClass]="node.cssClass"
    appMoneyMask="WITHOUT_DECIMAL"
  >
  </app-form-field-text>

  <app-form-field-text
    *ngIf="node.primitive === 'MONEY_WITH_DECIMAL'"
    placeholder="$"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [specifierText]="node.specifierText"
    [questionNote]="node.questionNote"
    [nameOfFormControl]="node.nameOfFormControl"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [submitted]="submitted"
    appMoneyMask="WITH_DECIMAL"
  >
  </app-form-field-text>

  <app-form-field-textarea
    *ngIf="node.primitive === 'TEXT_AREA'"
    [form]="form"
    [value]="node.value"
    [readonly]="node.readonly || readonly"
    [inputId]="node.inputId"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [questionNote]="node.questionNote"
    [cssClass]="node.cssClass"
    [maxLength]="node.maxLength"
    [placeholder]="node.placeholder || ''"
    [errorText]="node.errorText"
    [showErrorText]="node.showErrorText"
    [nameOfFormControl]="node.nameOfFormControl"
    [submitted]="submitted"
  >
  </app-form-field-textarea>

  <app-form-field-select
    *ngIf="node.primitive === 'SELECT'"
    [inputId]="node.inputId"
    [cssClass]="node.cssClass"
    [form]="form"
    [submitted]="submitted"
    [labelText]="node.labelText"
    [questionNote]="node.questionNote"
    [readonly]="node.readonly || readonly"
    [nameOfFormControl]="node.nameOfFormControl"
    [availableOptions]="node.options"
    [specifierText]="node.specifierText || ''"
    [ul]="node.ul"
  >
  </app-form-field-select>

  <app-form-field-radio-dsl
    *ngIf="node.primitive === 'RADIO'"
    [form]="form"
    [initConfig]="node"
    [readonly]="node.readonly || readonly"
    [submitted]="submitted"
  >
  </app-form-field-radio-dsl>

  <app-form-field-radio-traditional
    *ngIf="node.primitive === 'TRADITIONAL_RADIO'"
    [inputId]="node.inputId"
    [nameOfFormControl]="node.nameOfFormControl"
    [labelText]="node.labelText"
    [additionalQuestionText]="node.questionNote"
    [cssClass]="node.cssClass"
    [optionsCssClass]="node.optionsCssClass"
    [questionCssClass]="node.questionCssClass"
    [form]="form"
    [submitted]="submitted"
    [readonly]="node.readonly || readonly"
    [options]="node.options"
    [optionDescriptions]="node.optionDescriptions"
  ></app-form-field-radio-traditional>

  <p *ngIf="node.primitive === 'PARA'">
    {{ node.text }}
  </p>

  <h2 *ngIf="node.primitive === 'H2'">
    {{ node.text }}
  </h2>

  <app-dialog-box
    *ngIf="node.primitive === 'DIALOG'"
    [class]="node.cssClass || ''"
    [type]="node.type"
    [header]="node.header"
    [dismissable]="node.dismissable || false"
    [form]="form"
    [nameOfFormControl]="node.nameOfFormControl"
    [inputId]="node.inputId"
  >
    <p class="p__no-margin" [innerHTML]="trustHtml(node.content)"></p>
  </app-dialog-box>

  <app-form-field-checkbox
    *ngIf="node.primitive === 'CHECKBOX'"
    [form]="form"
    [inputId]="node.inputId"
    [labelText]="node.labelText"
    [tooltipText]="node.tooltipText"
    [readonly]="node.readonly || readonly"
    [nameOfFormControl]="node.nameOfFormControl"
    [submitted]="submitted"
  >
  </app-form-field-checkbox>

  <div
    *ngIf="node.primitive === 'CHECKBOX_GROUP'"
    [className]="node.cssClass"
    [id]="node.nameOfFormControl"
  >
    <ng-container *ngIf="form.get(node.nameOfFormControl).enabled">
      <p [className]="node.questionCssClass">{{ node.labelText }}</p>
      <ng-container *ngIf="node.subLabelText">
        <small class="form-field-sub-label">
          {{ node.subLabelText }}
        </small>
      </ng-container>
      <app-form-field-checkbox
        *ngFor="let config of node.checkboxConfigs"
        [form]="form.get(node.nameOfFormControl)"
        [labelText]="config.labelText"
        [inputId]="config.nameOfFormControl"
        [tooltipText]="config.tooltipText"
        [readonly]="config.readonly || readonly"
        [nameOfFormControl]="config.nameOfFormControl"
        [submitted]="submitted"
        cssClass="form-field__no-margin mt-1"
      ></app-form-field-checkbox>
    </ng-container>
  </div>

  <app-form-field-text
    *ngIf="node.primitive === 'DATE'"
    [form]="form"
    [inputId]="node.inputId"
    [labelText]="node.labelText"
    [subLabelText]="node.subLabelText"
    [questionNote]="node.questionNote"
    [cssClass]="node.cssClass"
    [submitted]="submitted"
    [placeholder]="node.placeholder || ''"
    [specifierText]="node.specifierText"
    [errorText]="node.errorText"
    [readonly]="node.readonly || readonly"
    [showErrorText]="node.showErrorText"
    [nameOfFormControl]="node.nameOfFormControl"
    appDateMask
  >
  </app-form-field-text>

  <app-form-field-link-modal
    *ngIf="node.primitive === 'LINK_MODAL'"
    [form]="form"
    [child]="node.child"
    [modalTitle]="node.modalTitle"
    [modalSubTitle]="node.modalSubTitle"
    [modalLinkText]="node.modalLinkText"
    [modalPreLinkText]="node.modalPreLinkText"
    [modalPostLinkText]="node.modalPostLinkText"
    [modalBody]="node.modalBody"
    [modalImage]="node.modalImage"
    [removeClasses]="node.removeClasses"
    [submitted]="submitted"
  >
  </app-form-field-link-modal>

  <div *ngIf="node.primitive === 'DIV'" [className]="node.cssClass">
    <app-form-dsl-form
      [form]="form"
      [formTree]="node.children"
      [submitted]="submitted"
      (buttonClick)="handleButtonClick($event)"
      [extraData]="this.extraData"
    >
    </app-form-dsl-form>
  </div>

  <div *ngIf="node.primitive === 'VALIDATION_MESSAGE'" [className]="node.cssClass">
    <ng-container *ngIf="validationMessageErrors(node).length > 0">
      <div
        [id]="node.inputId"
        class="validation"
        role="alert"
        *ngFor="let validationError of validationMessageErrors(node)"
      >
        <p class="validation-message" *ngIf="!node.validationMessage">
          {{ validationError }}
        </p>
        <div *ngIf="node.validationMessage" [innerHTML]="node.validationMessage"></div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="node.primitive === 'TRUE-CONDITIONAL'" [className]="node.cssClass">
    <app-form-field-checkbox
      [inputId]="node.inputId"
      [cssClass]="node.cssClass"
      [form]="form"
      [submitted]="submitted"
      [labelText]="node.labelText"
      [nameOfFormControl]="node.nameOfFormControl"
    >
    </app-form-field-checkbox>
    <app-form-dsl-form
      [form]="form"
      [formTree]="node.conditionalChildren"
      [submitted]="submitted"
      (buttonClick)="handleButtonClick($event)"
      [extraData]="this.extraData"
    >
    </app-form-dsl-form>
  </div>

  <div *ngIf="node.primitive === 'VALUE-CONDITIONAL'" [className]="node.cssClass">
    <app-form-dsl-form
      [form]="form"
      [formTree]="node.conditionalChildren"
      [submitted]="submitted"
      (buttonClick)="handleButtonClick($event)"
      [extraData]="this.extraData"
    >
    </app-form-dsl-form>
  </div>

  <div *ngIf="node.primitive === 'EVAL-CONDITIONAL'" [className]="node.cssClass">
    <app-form-dsl-form
      [form]="form"
      [formTree]="node.conditionalChildren"
      [submitted]="submitted"
      (buttonClick)="handleButtonClick($event)"
      [extraData]="this.extraData"
    >
    </app-form-dsl-form>
  </div>

  <div *ngIf="node.primitive === 'MULTI-CLAUSE-CHECKBOX'" [className]="node.cssClass">
    <h3>{{ node.labelText }}</h3>
    <ul>
      <li *ngFor="let clause of node.clauses">
        {{ clause }}
      </li>
    </ul>
    <app-form-field-checkbox
      [inputId]="node.inputId"
      [cssClass]="node.cssClass"
      [form]="form"
      [submitted]="submitted"
      [labelText]=""
      [nameOfFormControl]="node.nameOfFormControl"
    >
    </app-form-field-checkbox>
  </div>

  <div *ngIf="node.primitive === 'MULTI-CLAUSE-RADIO'" [className]="node.cssClass">
    <h3>{{ node.headerText }}</h3>
    <ul>
      <li *ngFor="let clause of node.clauses">
        {{ clause }}
      </li>
    </ul>
    <app-form-field-radio-dsl [form]="form" [initConfig]="node" [submitted]="submitted">
    </app-form-field-radio-dsl>
  </div>

  <div *ngIf="node.primitive === 'NODE_ARRAY'" [className]="node.cssClass">
    <ng-container *ngIf="getFormArray(form, node.nameOfFormControl).enabled">
      <div
        *ngFor="let childNodes of node.children; index as idx"
        [className]="node.childDivCssClass"
      >
        <app-form-dsl-form
          [form]="getFormArray(form, node.nameOfFormControl).at(idx)"
          [formTree]="childNodes"
          [inputIdFormat]="{ prefix: node.prefix, index: idx }"
          [submitted]="submitted"
          (buttonClick)="handleButtonClick($event)"
          [extraData]="this.extraData"
        >
        </app-form-dsl-form>
        <ng-container *ngIf="shouldShowChildButtons(node)">
          <button
            *ngFor="let btn of node.childFooterButtons"
            (click)="handleButtonClick(btn.methodName, idx)"
            [className]="btn.cssClass"
            type="button"
          >
            {{ btn.buttonText }}
          </button>
        </ng-container>
      </div>
      <button
        *ngFor="let btn of node.footerButtons"
        (click)="handleButtonClick(btn.methodName)"
        [className]="btn.cssClass"
        type="button"
      >
        {{ btn.buttonText }}
      </button>
    </ng-container>
  </div>

  <div *ngIf="node.primitive === 'BUTTON'">
    <button (click)="handleButtonClick(node.methodName)" [className]="node.cssClass" type="button">
      {{ node.buttonText }}
    </button>
  </div>

  <div *ngIf="node.primitive === 'ADDRESS'" [className]="node.cssClass">
    <app-form-field-address
      [form]="form.get(node.nameOfFormControl)"
      [submitted]="submitted"
      [labelText]="node.labelText"
      [readonly]="node.readonly || readonly"
      [prefix]="node.prefix"
      [disableStateChange]="node.disableStateChange"
      [displayMap]="node.displayMap"
      [useAddressLine2]="node.useAddressLine2"
      [questionNote]="node.questionNote"
      [specifierText]="node.specifierText"
    ></app-form-field-address>
  </div>

  <div *ngIf="node.primitive === 'FILE_UPLOAD'" [className]="node.cssClass">
    <app-form-field-file
      [form]="form"
      [submitted]="submitted"
      [nameOfFormControl]="node.nameOfFormControl"
      [labelText]="node.labelText"
      [specifierText]="node.specifierText"
      [tooltipText]="node.tooltipText"
      [inputId]="node.inputId"
      [accept]="node.accept"
      [fileMetadata]="node.fileMetadata"
    >
    </app-form-field-file>
  </div>

  <div *ngIf="node.primitive === 'DROPDOWN_SEARCH'" [className]="node.cssClass">
    <app-form-field-dropdown-search
      [submitted]="submitted"
      [inputId]="node.inputId"
      [labelText]="node.labelText"
      [questionNote]="node.questionNote"
      [tooltipText]="node.tooltipText"
      [queryMethod]="getQueryMethod(node.queryMethodName)"
      [queryableResults]="node.queryableResults"
      [formatter]="getQueryMethod(node.formatterName)"
      [typeaheadPlaceholderText]="node.typeaheadPlaceholderText || ''"
      [targetFormControl]="form.get(node.inputId)"
      [placeholderText]="node.placeholderText || 'Choose one'"
      [readonly]="node.readonly || readonly"
      [searchExpanded]="node.searchExpanded !== false"
    >
    </app-form-field-dropdown-search>
  </div>
</ng-container>
