import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Toast, ToastTemplate } from '../../shared/toasts/toast';

export enum InformLevel {
  'info',
  'success',
  'error',
  'minorError',
  'warning',
  'customSuccess',
}

export interface InformToast extends Toast {
  type: ToastTemplate;
  level: InformLevel;
  timeout: number;
  message: string;
  iconName: string | null;
  title: string | null;
  button: string | null;
  callback: Function | null;
  forceTimeout: boolean;
}

export interface DeleteToast {
  toastId: string;
}

@Injectable({
  providedIn: 'root',
})
export class InformService {
  constructor() {}

  private informToasts = new Subject<InformToast>();
  private deleteToasts = new Subject<DeleteToast>();

  public deleteToast(deleteObj: DeleteToast) {
    this.deleteToasts.next(deleteObj);
  }

  public infoToast(
    message: string,
    iconName: string | null = null,
    title: string | null = null,
    button: string | null = null,
    callback: Function | null = null,
    timeout: number = 5000
  ) {
    return this.toast(InformLevel.info, message, iconName, title, button, callback, timeout);
  }

  public warnToast(
    message: string,
    iconName: string | null = null,
    title: string | null = null,
    button: string | null = null,
    callback: Function | null = null,
    timeout: number = 5000
  ) {
    return this.toast(InformLevel.warning, message, iconName, title, button, callback, timeout);
  }

  public successToast(
    message: string,
    iconName: string | null = null,
    title: string | null = null,
    button: string | null = null,
    callback: Function | null = null,
    timeout: number = 5000
  ) {
    return this.toast(InformLevel.success, message, iconName, title, button, callback, timeout);
  }

  public successIconToast(
    message: string,
    iconName: string | null = null,
    title: string | null = null,
    button: string | null = null,
    callback: Function | null = null,
    timeout: number = 5000,
    forceTimeout?: boolean
  ) {
    return this.toast(
      InformLevel.customSuccess,
      message,
      iconName,
      title,
      button,
      callback,
      timeout,
      forceTimeout
    );
  }

  public errorToast(
    message: string,
    iconName: string | null = null,
    title: string | null = null,
    button: string | null = null,
    callback: Function | null = null,
    timeout: number = 5000
  ) {
    return this.toast(InformLevel.error, message, iconName, title, button, callback, timeout);
  }

  // a minor error toast is similar to an error toast, but it will be cleared
  // on page navigation
  public minorErrorToast(
    message: string,
    iconName: string | null = null,
    title: string | null = null,
    button: string | null = null,
    callback: Function | null = null,
    timeout: number = 5000
  ) {
    return this.toast(InformLevel.minorError, message, iconName, title, button, callback, timeout);
  }

  public toast(
    level: InformLevel,
    message: string,
    iconName: string | null,
    title: string | null,
    button: string | null,
    callback: Function | null,
    timeout: number,
    forceTimeout: boolean = false
  ) {
    const toast = {
      id: uuidv4(),
      type: ToastTemplate.INFORM,
      message,
      iconName,
      level,
      timeout,
      title,
      button,
      callback,
      removing: false,
      forceTimeout,
    };
    this.informToasts.next(toast);
    return toast;
  }

  public getInformToasts() {
    return this.informToasts.asObservable();
  }

  public getDeleteToasts() {
    return this.deleteToasts.asObservable();
  }
}
