import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  UTILITY_SERVICE_LIMITS_API_URI,
  WIND_OPTIONS_API_URI,
} from 'app/features/attune-bop/models/constants';
import {
  APIData,
  APIDataStatus,
  UtilityServiceLimits,
  WindOption,
} from 'app/shared/models/api-data';
import { HttpClient } from '@angular/common/http';

import { SentryService } from 'app/core/services/sentry.service';

export interface WindLimitRequest {
  state: string | null;
  constructionType: string | null;
  isLessor?: boolean | null;
  jobNumber?: string;
  propertyDeductible: string | null;
  propertyType?: string | null;
  milesToOcean: string | null;
}

@Injectable()
export class LimitsService {
  utilityServiceLimits: { [utilitiesLimitsKey: string]: APIData } = {};
  windOptionsCache: { [windOptionsKey: string]: BehaviorSubject<APIData> } = {};

  constructor(private http: HttpClient, private sentryService: SentryService) {}

  utilityServicesDirectDamage(state: string | null, milesToOcean: string | null): Array<number> {
    return this.optionsForUtilityServices(state, milesToOcean, (usl) => usl.directDamageSubLimit);
  }

  utilityServicesDirectDamageStatus(
    state: string | null,
    milesToOcean: string | null
  ): APIDataStatus {
    return this.statusForUtilityServices(state, milesToOcean);
  }

  utilityServicesTimeElement(state: string | null, milesToOcean: string | null): Array<number> {
    return this.optionsForUtilityServices(state, milesToOcean, (usl) => usl.timeElementSubLimit);
  }

  utilityServicesTimeElementStatus(
    state: string | null,
    milesToOcean: string | null
  ): APIDataStatus {
    return this.statusForUtilityServices(state, milesToOcean);
  }

  windOptions(request: WindLimitRequest): Array<WindOption> {
    const { state, constructionType, propertyDeductible, isLessor, propertyType, milesToOcean } =
      request;

    /* Not requiring isLessor, propertyType to be set so service is backwards compatible */
    if (!state || !constructionType || propertyDeductible == null || !milesToOcean) {
      return [];
    }

    const key = this.mungeWindOptionsKey(
      state,
      isLessor || false,
      constructionType,
      propertyDeductible,
      propertyType || '',
      milesToOcean
    );
    const limitApiData$ = this.windOptionsCache[key];
    if (!limitApiData$) {
      return [];
    }
    const limitApiData = limitApiData$.value;
    if (!limitApiData || limitApiData.status !== APIDataStatus.LOADED) {
      return [];
    }

    return limitApiData.data as WindOption[];
  }

  windOptionsStatus(request: WindLimitRequest): APIDataStatus {
    const { state, constructionType, propertyDeductible, isLessor, propertyType, milesToOcean } =
      request;
    if (!state || !constructionType || propertyDeductible == null || !milesToOcean) {
      return APIDataStatus.UNREQUESTED;
    }

    const key = this.mungeWindOptionsKey(
      state,
      isLessor || false,
      constructionType,
      propertyDeductible,
      propertyType || '',
      milesToOcean
    );
    const limitSubject$ = this.windOptionsCache[key];
    if (!limitSubject$) {
      return APIDataStatus.UNREQUESTED;
    }

    return limitSubject$.value.status;
  }

  requestUtilityServiceLimits(state: string, milesToOcean: string): Observable<APIData> {
    const utilitiesLimitsKey = this.getUtilitiesLimitsKey(state, milesToOcean);

    let limitSubject = this.utilityServiceLimits[utilitiesLimitsKey];
    if (limitSubject) {
      return observableOf(limitSubject);
    }

    limitSubject = {
      data: {
        directDamageSubLimit: 0,
        timeElementSubLimit: 0,
      },
      status: APIDataStatus.LOADING,
    };
    this.utilityServiceLimits[utilitiesLimitsKey] = limitSubject;

    return this.utilityServiceLimitsHttpRequest(state, milesToOcean).pipe(
      tap((result) => {
        if (result.status !== APIDataStatus.ERROR) {
          this.utilityServiceLimits[utilitiesLimitsKey] = result;
        }
      })
    );
  }

  requestWindOptions(request: WindLimitRequest): Observable<APIData> {
    const { state, constructionType, propertyDeductible, isLessor, propertyType, milesToOcean } =
      request;

    const key = this.mungeWindOptionsKey(
      state || '',
      isLessor || false,
      constructionType || '',
      propertyDeductible || '',
      propertyType || '',
      milesToOcean || ''
    );
    let limitSubject$ = this.windOptionsCache[key];
    if (limitSubject$) {
      return limitSubject$;
    }

    // Cache data with LOADING status
    this.windOptionsCache[key] = new BehaviorSubject({
      data: [],
      status: APIDataStatus.LOADING,
    });
    limitSubject$ = this.windOptionsCache[key];

    return this.windOptionsHttpRequest(request).pipe(
      tap((result) => {
        limitSubject$.next(result);
      })
    );
  }

  private windOptionsHttpRequest(request: WindLimitRequest): Observable<APIData> {
    return this.http
      .post<WindOptionsResponsePayload>(WIND_OPTIONS_API_URI, {
        windDeductibleRequestModel: {
          WindDeductibleRequest: {
            BuildingState: request.state,
            ConstructionType: request.constructionType,
            JobNumber: request.jobNumber,
            IsLessor: request.isLessor,
            MilesToOcean: request.milesToOcean,
            PropertyDeductible: request.propertyDeductible
              ? parseInt(request.propertyDeductible, 10)
              : null,
            PropertyType: request.propertyType,
          },
        },
      })
      .pipe(
        map((response) => {
          const windOptions = <Array<WindOption>>(
            response.return.WindDeductibleResponse.Options.Entry
          );
          return {
            data: windOptions.sort(this.compareByPercentDeductible),
            status: APIDataStatus.LOADED,
          };
        }),
        catchError((error: any) => {
          this.sentryService.notify('Error getting wind options.', {
            severity: 'error',
            metaData: {
              ...request,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf({
            data: [],
            status: APIDataStatus.ERROR,
          });
        })
      );
  }

  private utilityServiceLimitsHttpRequest(
    state: string,
    milesToOcean: string
  ): Observable<APIData> {
    return this.http
      .post<UtilityServiceLimitsResponsePayload>(UTILITY_SERVICE_LIMITS_API_URI, {
        state,
        milesToOcean: parseInt(milesToOcean, 10),
      })
      .pipe(
        map((response) => {
          return {
            data: response.return.UtilityLimitResponse,
            status: APIDataStatus.LOADED,
          };
        }),
        catchError((error: any) => {
          this.sentryService.notify('Error getting utility service limits.', {
            severity: 'error',
            metaData: {
              state,
              milesToOcean,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf({
            data: {
              directDamageSubLimit: 0,
              timeElementSubLimit: 0,
            },
            status: APIDataStatus.ERROR,
          });
        })
      );
  }

  private mungeWindOptionsKey(
    state: string,
    isLessor: boolean,
    constructionType: string,
    propertyDeductible: string,
    propertyType: string,
    milesToOcean: string | null
  ): string {
    return `${state}--${isLessor}--${constructionType}--${propertyDeductible}--${propertyType}--${milesToOcean}`;
  }

  private getUtilitiesLimitsKey(state: string, milesToOcean: string | null): string {
    return `${state}--${milesToOcean}`;
  }

  private statusForUtilityServices(
    state: string | null,
    milesToOcean: string | null
  ): APIDataStatus {
    if (!state || !milesToOcean) {
      return APIDataStatus.UNREQUESTED;
    }

    const utilitiesLimitsKey = this.getUtilitiesLimitsKey(state, milesToOcean);
    const limitSubject = this.utilityServiceLimits[utilitiesLimitsKey];
    if (!limitSubject) {
      return APIDataStatus.UNREQUESTED;
    }

    return limitSubject.status;
  }

  private optionsForUtilityServices(
    state: string | null,
    milesToOcean: string | null,
    mapper: (limits: UtilityServiceLimits) => number
  ): Array<number> {
    if (!state || !milesToOcean) {
      return [];
    }

    const utilitiesLimitsKey = this.getUtilitiesLimitsKey(state, milesToOcean);
    const limitSubject = this.utilityServiceLimits[utilitiesLimitsKey];

    if (!limitSubject || limitSubject.status !== APIDataStatus.LOADED) {
      return [];
    }

    const limit = mapper(<UtilityServiceLimits>limitSubject.data);
    const increment = limit <= 25000 ? 5000 : 10000;
    const options = [];
    for (let option = 0; option <= limit; option += increment) {
      options.push(option);
    }
    return options;
  }

  private compareByPercentDeductible(optionA: WindOption, optionB: WindOption): number {
    const percentDeductibleA = parseInt(optionA.PercentDeductible, 10);
    const percentDeductibleB = parseInt(optionB.PercentDeductible, 10);
    if (isNaN(percentDeductibleA)) {
      return 1;
    } else if (isNaN(percentDeductibleB)) {
      return -1;
    }
    return percentDeductibleA - percentDeductibleB;
  }
}
