import { BP7Line } from 'app/bop/guidewire/typings';
import { BopQuotePayload, BopQuoteFormValue } from 'app/features/attune-bop/models/bop-policy';

export const BOP_TYPE_ID = 'entity.BP7BusinessOwnersLine';

export const getLocationTypeId = (locationIndex: number) => `entity.BP7Location:${locationIndex}`;

export const getBuildingTypeId = (locationIndex: number, buildingIndex: number) => {
  return `entity.BP7Building:${locationIndex}.${buildingIndex}`;
};

export const getClassificationTypeId = (
  locationIndex: number,
  buildingIndex: number,
  classificationIndex: number = 0
) => {
  return `entity.BP7Classification:${locationIndex}.${buildingIndex}.${classificationIndex}`;
};

export const getAllTypeIds = (bopPolicy: BopQuoteFormValue | BopQuotePayload): string[] => {
  const locationTypeIds: string[] = [];
  const buildingTypeIds: string[] = [];
  const classificationTypeIds: string[] = [];

  bopPolicy.locations.forEach((location, locationIndex) => {
    locationTypeIds.push(getLocationTypeId(locationIndex));

    location.buildings.forEach((building, buildingIndex) => {
      buildingTypeIds.push(getBuildingTypeId(locationIndex, buildingIndex));
      classificationTypeIds.push(getClassificationTypeId(locationIndex, buildingIndex));
    });
  });

  return [BOP_TYPE_ID, ...locationTypeIds, ...buildingTypeIds, ...classificationTypeIds];
};

// Returns a map from actual typeId in payload to the would-be generated payload
// typeIDString by the payload service.
//
export const typeIdsMap = (bopLine: BP7Line): { [key: string]: string } => {
  const idMap: { [key: string]: string } = {};
  idMap[bopLine.TypeIDString] = 'entity.BP7BusinessOwnersLine';

  bopLine.BP7Locations.Entry.forEach((bopLoc, locationIndex) => {
    idMap[bopLoc.TypeIDString] = getLocationTypeId(locationIndex);

    bopLoc.Buildings.Entry.forEach((bopBuilding, buildingIndex) => {
      idMap[bopBuilding.TypeIDString] = getBuildingTypeId(locationIndex, buildingIndex);

      bopBuilding.Classifications.Entry.forEach((bopClassification, classificationIndex) => {
        idMap[bopClassification.TypeIDString] = getClassificationTypeId(
          locationIndex,
          buildingIndex,
          classificationIndex
        );
      });
    });
  });

  return idMap;
};
