import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { ProductName } from '../../../features/digital-carrier/models/types';
import { ParsedAttuneOrPartnerCommissionStatement } from '../../consumer/typings';
import { SentryService } from '../../../core/services/sentry.service';
import { InformService } from '../../../core/services/inform.service';
import { UserService } from '../../../core/services/user.service';
import {
  DOCUMENT_TYPES,
  DocumentService,
} from '../../../features/documents/services/document.service';
import {
  getAttuneCommissionStatementUrl,
  getPartnerCommissionStatementUrl,
} from '../../../features/documents/models/document-urls.model';
import {
  getAttuneCommissionStatementFileName,
  getPartnerCommissionStatementFileName,
} from '../../../features/documents/models/document-file-names.model';

@Component({
  selector: 'app-combined-commission-statements-table',
  templateUrl: './combined-commission-statements-table.component.html',
})
export class CombinedCommissionStatementsTableComponent implements OnInit, OnChanges {
  @Input() commissionData: AttuneOrPartnerCommissionStatement[];
  @Input() statementsLoading: boolean;

  parsedCommissionDataByYear: ParsedAttuneOrPartnerCommissionStatement[][] = [];
  selectedStatement: AttuneOrPartnerCommissionStatement | null = null;
  downloadingStatements: AttuneOrPartnerCommissionStatement[] = [];

  TYPE_TO_SHORT_LOB: Record<AttuneOrPartnerCommissionStatement['commissionType'], string> = {
    Attune: 'BOP + Cyber',
    Partner: 'GL + PL + Flood + WC',
  };

  ATTUNE_PRODUCTS: ProductName[] = ["Businessowners' Policy", 'Cyber Liability'];
  PARTNER_PRODUCTS: ProductName[] = [
    'General Liability',
    'Professional Liability',
    'Flood Insurance',
    "Workers' Compensation",
  ];

  constructor(
    private sentryService: SentryService,
    private informService: InformService,
    private userService: UserService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.getGroupsByYear(this.commissionData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.commissionData) {
      this.getGroupsByYear(changes.commissionData.currentValue);
    }
  }

  getGroupsByYear(commissionData: AttuneOrPartnerCommissionStatement[]) {
    if (!commissionData?.length) {
      return [];
    }
    const parsedStatements = commissionData.map((statement) => {
      // Partner statements at the end of the month should show in the following month
      // 2 instead of 1 to avoid fenceposting issues
      const date =
        statement.commissionType === 'Partner'
          ? moment(statement.statementCreated).add(2, 'days')
          : moment(statement.statementCreated);
      return {
        ...statement,
        date,
      };
    });

    const sortedStatements = parsedStatements.sort((a, b) => b.date.diff(a.date));

    let currentYear = sortedStatements[0].date.year();
    let currentArray: ParsedAttuneOrPartnerCommissionStatement[] = [];
    const groupsByYear: ParsedAttuneOrPartnerCommissionStatement[][] = [];
    for (const statement of sortedStatements) {
      if (statement.date.year() !== currentYear) {
        currentYear = statement.date.year();
        groupsByYear.push(currentArray);
        currentArray = [];
      }
      currentArray.push(statement);
    }
    groupsByYear.push(currentArray);

    this.parsedCommissionDataByYear = groupsByYear;
  }

  transactionPeriod(statement: AttuneOrPartnerCommissionStatement) {
    const statementDate = moment(statement.statementCreated).utc();

    if (statement.commissionType === 'Attune') {
      const periodStart = statementDate.clone().subtract(1, 'month').format('MM/DD/YYYY');
      const periodEnd = statementDate.clone().subtract(1, 'day').format('MM/DD/YYYY');

      return `${periodStart}-${periodEnd}`;
    } else {
      const periodStart = statementDate.clone().startOf('month').format('MM/DD/YYYY');
      const periodEnd = statementDate.clone().format('MM/DD/YYYY');

      return `${periodStart}-${periodEnd}`;
    }
  }

  openCommissionDetail(statement: AttuneOrPartnerCommissionStatement) {
    this.selectedStatement = statement;
  }

  closeCommissionDetail() {
    this.selectedStatement = null;
  }

  downloadCommissionStatement(
    statement: AttuneOrPartnerCommissionStatement,
    formatForPartnerStatement: 'xlsx' | 'pdf' = 'xlsx'
  ) {
    this.downloadingStatements.push(statement);
    let documentType: DOCUMENT_TYPES;
    let url;
    let fileName;
    if (statement.commissionType === 'Attune') {
      documentType = 'xls';
      url = getAttuneCommissionStatementUrl(statement.statementNumber);
      fileName = getAttuneCommissionStatementFileName(statement.statementNumber);
      this.getDocument(statement, url, fileName, documentType, 'attune commission statement pdf');
    } else if (statement.commissionType === 'Partner' && formatForPartnerStatement === 'pdf') {
      documentType = 'pdf';
      url = getPartnerCommissionStatementUrl(statement.statementCreated, documentType);
      fileName = getPartnerCommissionStatementFileName(statement.statementCreated, documentType);
      this.getDocument(statement, url, fileName, documentType, 'partner commission statement pdf');
    } else if (statement.commissionType === 'Partner' && formatForPartnerStatement === 'xlsx') {
      this.userService.getUser().subscribe(({ producer }) => {
        documentType = 'xlsx';
        url = getPartnerCommissionStatementUrl(statement.statementCreated, documentType, producer);
        fileName = getPartnerCommissionStatementFileName(statement.statementCreated, documentType);
        this.getDocument(
          statement,
          url,
          fileName,
          documentType,
          'partner commission statement xlsx'
        );
      });
    }
  }

  getDocument(
    statement: AttuneOrPartnerCommissionStatement,
    url: string,
    fileName: string,
    documentType: DOCUMENT_TYPES,
    description: string
  ) {
    this.documentService.getDocument(url, fileName, documentType, description).subscribe({
      next: () => {
        this.downloadingStatements = this.downloadingStatements.filter(
          (item) => item !== statement
        );
      },
      error: () => {
        this.downloadingStatements = this.downloadingStatements.filter(
          (item) => item !== statement
        );
        this.informService.infoToast(
          'Could not download this statement. Please contact our Customer Care Team.'
        );
      },
    });
  }
}
