import { environment } from 'environments/environment';
import { CURRENT_GIT_SHA } from 'app/constants';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';

const notifyReleaseStages = ['acceptance', 'staging', 'dev', 'production'];

const isHttpError = (error: any) => {
  if (error instanceof HttpErrorResponse) {
    return true;
  }

  if (typeof error === 'string') {
    return error.startsWith('Http failure response for');
  }

  return false;
};

export const initSentry = () => {
  Sentry.init({
    // maxBreadcrumbs: 50, // Default: 100
    debug: environment.stage !== 'production',
    dsn: 'https://308ada9fd5e446e590b26cbb5dd3ffbd@o1408929.ingest.sentry.io/4503899105722368',
    release: CURRENT_GIT_SHA,
    environment: environment.stage,
    enabled: notifyReleaseStages.includes(environment.stage),
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://app.attuneinsurance.com',
          'https://app.wcumb.attunesandbox.com',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // TODO: may need to adjust this to reduce tracing intelligently
    tracesSampleRate: 0.01,
    autoSessionTracking: true,
    beforeSend: (event, hint) => {
      // We are already logging custom errors on HTTP requests. Additionally,
      // HttpErrorResponse objects are not valid errors for Sentry, so throwing
      // them results in an opaque Non-Error Exception.
      // Some discussion of this issue here:
      // https://github.com/getsentry/sentry-javascript/issues/2292
      if (isHttpError(hint.originalException)) {
        return null;
      }
      return event;
    },
  });
};
