// Libraries
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { getControl, shouldShowInvalid } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-form-field-checkbox',
  templateUrl: './form-field-checkbox.component.html',
})
export class FormFieldCheckboxComponent {
  @Input() inputId: string;
  @Input() innerHtmlLabelText: string;
  @Input() labelText: string;
  @Input() sublabelText: string;
  @Input() cssClass: string;
  @Input() tooltipText: string;
  @Input() nameOfFormControl: string;
  @Input() form: UntypedFormGroup;
  @Input() readonly: boolean;
  @Input() submitted: boolean;
  @Input() invalid = false;
  @Input() errorText: string;
  @Input() hasError = false;

  public getControl = getControl;

  fieldInvalid(field: string): boolean | undefined {
    return (this.submitted && this.invalid) || shouldShowInvalid(field, this.form, this.submitted);
  }

  getErrorText() {
    if (this.submitted) {
      return this.errorText ? this.errorText : 'Field is required';
    }
  }
}
