import { Observable } from 'rxjs';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { ValidatorFn, Validators, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {
  validateAddressIsNotPO,
  validateNoSpecialCharactersInAddress,
} from 'app/features/attune-bop/models/form-validators';

import { EvaluatorName, ValidatorName } from './form-dsl-constants';

import { zipCodeValidator } from '../../helpers/form-helpers';
import { assign } from 'lodash';

// FormDSL search service enums
export enum SearchFormatter {
  COALITION_CYBER_INDUSTRY = 'coalitionCyberIndustryFormatter',
  LM_BOP_CLASS_CODE = 'lmBopClassCodeFormatter',
  LM_GL_CLASS_CODE = 'lmGlClassCodeFormatter',
}

export enum SearchQueryMethod {
  COALITION_CYBER_INDUSTRY = 'coalitionCyberIndustryQueryMethod',
  LM_BOP_CLASS_CODE = 'lmBopClassCodeQueryMethod',
  LM_GL_CLASS_CODE = 'lmGlClassCodeQueryMethod',
}
// END FormDSL search service enums

export type ValidatorDictionary<T extends string> = Record<T, ValidatorFn[]>;
export type ComplexValidatorDictionary<T extends string> = Record<
  T,
  ((form: UntypedFormGroup) => ValidatorFn)[]
>;
export type FormArrayValidators<
  TArrayControls extends string,
  TNestedControls extends string
> = Record<TArrayControls, ValidatorDictionary<TNestedControls>>;

export type HappyPathFormData<TStepNames extends string, TControlNames extends string> = Record<
  TStepNames,
  { [key in TControlNames]?: any }
>;

export type ControlConfig = [any, null | ValidatorFn[]];
export type GroupConfig = Record<string, ControlConfig | UntypedFormGroup | UntypedFormArray>;

type Primitive =
  | 'ADDRESS'
  | 'CHECKBOX_GROUP'
  | 'CHECKBOX'
  | 'DATE'
  | 'DIV'
  | 'FEIN'
  | 'FILE_UPLOAD'
  | 'H2'
  | 'LINK_MODAL'
  | 'MONEY_WITHOUT_DECIMAL'
  | 'MONEY_WITH_DECIMAL'
  | 'MULTI-CLAUSE-CHECKBOX'
  | 'MULTI-CLAUSE-RADIO'
  | 'NUMBER'
  | 'PARA'
  | 'PHONE'
  | 'RADIO'
  | 'SELECT'
  | 'TEXT'
  | 'TEXT_AREA'
  | 'VALIDATION_MESSAGE';

// TODO: Consider using base nodes like this, and giving them a type property (w/ value
// of e.g. control | group | array | layout) to clean up some unused properties and make
// property access less clunky.
export interface BaseFormNode {
  primitive: Primitive;
  cssClass: string;
  formStep?: string;
}

export interface AddressAutoCompleteNode {
  primitive: 'ADDRESS_AUTO_COMPLETE';
  cssClass?: string; // should default to blank, but all Primitives should accept it
  nameOfFormControl: string;
  inputId: string;
  labelText: string;
  subLabelText?: string;
  questionNote?: string;
  specifierText?: string;
  maxLength?: string;
  minLength?: string;
  placeholder?: string;
  isSensitiveInfo?: boolean;
  errorText?: string;
  showErrorText?: boolean;
  value?: any;
  readonly?: boolean;
  formStep?: string;
  required?: boolean;
  validators?: ValidatorName[];
  page?: string;
  dynamicSource?: string;
}
export interface TextNode {
  primitive: StrictPrimitive;
  cssClass?: string; // should default to blank, but all Primitives should accept it
  nameOfFormControl: string;
  inputId: string;
  inputType?: 'email' | 'tel' | 'number' | 'text';
  labelText: string;
  subLabelText?: string;
  questionNote?: string;
  specifierText?: string;
  tooltipText?: string;
  maxLength?: string;
  minLength?: string;
  placeholder?: string;
  isSensitiveInfo?: boolean;
  errorText?: string;
  showErrorText?: boolean;
  value?: any;
  readonly?: boolean;
  formStep?: string;
  required?: boolean;
  validators?: ValidatorName[];
  page?: string;
  dynamicSource?: string;
}
export interface TextAreaNode {
  primitive: 'TEXT_AREA';
  cssClass?: string; // should default to blank, but all Primitives should accept it
  nameOfFormControl: string;
  inputId: string;
  labelText: string;
  subLabelText?: string;
  questionNote?: string;
  maxLength?: string;
  minLength?: string;
  placeholder?: string;
  isSensitiveInfo?: boolean;
  errorText?: string;
  showErrorText?: boolean;
  value?: any;
  readonly?: boolean;
  formStep?: string;
  required?: boolean;
  validators?: ValidatorName[];
  dynamicSource?: string;
}

export interface CheckboxNode {
  primitive: 'CHECKBOX';
  cssClass?: string; // should default to blank, but all Primitives should accept it
  nameOfFormControl: string;
  inputId: string;
  labelText: string;
  formStep?: string;
  required?: boolean;
  validators?: ValidatorName[];
  dynamicSource?: string;
}

export interface CheckboxNodeConfig {
  labelText: string;
  nameOfFormControl: string;
  tooltipText?: string;
  requiredTrue?: boolean;
  initialValue?: boolean;
  readonly?: boolean;
}

export const checkboxGroupNode = ({
  nameOfFormControl,
  labelText,
  checkboxConfigs,
  cssClass = '',
  questionCssClass = '',
  subLabelText = '',
  required = false,
  validators = [],
}: {
  nameOfFormControl: string;
  labelText: string;
  checkboxConfigs: CheckboxNodeConfig[];
  cssClass?: string;
  questionCssClass?: string;
  subLabelText?: string;
  required?: boolean;
  validators?: ValidatorName[];
}): CheckBoxGroupNode => {
  const controls = checkboxConfigs.reduce((dict, config) => {
    const initialValue = !!config.initialValue;
    const checkboxValidators = config.requiredTrue ? [Validators.requiredTrue] : [];
    dict[config.nameOfFormControl] = [initialValue, checkboxValidators];
    return dict;
  }, {} as { [k: string]: ControlConfig });

  return {
    primitive: 'CHECKBOX_GROUP',
    nameOfFormControl,
    labelText,
    checkboxConfigs,
    controls,
    isFormGroup: true,
    cssClass,
    questionCssClass,
    subLabelText,
    validators,
    required,
  };
};

// Note: this primitive should be instantiated with the `checkboxGroupNode()` helper for ease
export interface CheckBoxGroupNode {
  primitive: 'CHECKBOX_GROUP';
  nameOfFormControl: string;
  labelText: string;
  checkboxConfigs: CheckboxNodeConfig[];
  controls: {
    [k: string]: ControlConfig;
  };
  isFormGroup: true;
  cssClass?: string;
  questionCssClass?: string;
  subLabelText?: string;
  required?: boolean;
  validators?: ValidatorName[];
  dynamicSource?: string;
}

export interface MultiInputNode {
  primitive: 'RADIO' | 'SELECT';
  cssClass?: string; // should default to blank, but all Primitives should accept it
  // stricter for key string value string only
  options: { [key: string]: any };
  optionsCssClass?: string;
  optionsLabelCssClass?: string;
  questionCssClass?: string;
  // the following are only optional for non input nodes like DIV, PARA, H2
  nameOfFormControl: string;
  inputId: string;
  labelText: string;
  questionText?: string;
  // TODO George-tool the blank string is hack. I would prefer the key not to exist
  inputType?: 'email' | 'tel' | 'number' | 'text' | '';
  placeholder?: string;
  additionalQuestionText?: string;
  subLabelText?: string;
  specifierText?: string; // Used for `SELECT` components instead of `subLabelText`
  questionNote?: string;
  tooltipText?: string;
  errorText?: string;
  showErrorText?: boolean;
  disabled?: boolean;
  disabledOptions?: string[];
  invalid?: boolean;
  emitClicks?: boolean;
  ul?: { li: string[] };
  formStep?: string;
  readonly?: boolean;
  required?: boolean;
  validators?: ValidatorName[];
  page?: string;
  isArray?: boolean;
  values?: Record<string, any>;
  dynamicSource?: string;
}

export interface TraditionalRadioNode {
  primitive: 'TRADITIONAL_RADIO';
  inputId: string;
  additionalQuestionText?: string;
  labelText: string;
  nameOfFormControl: string;
  options: { [key: string]: any };
  optionDescriptions?: { [key: string]: string };
  readonly?: boolean;
  cssClass?: string;
  questionCssClass?: string;
  optionsCssClass?: string;
  optionsLabelCssClass?: string;
  tooltipText?: string;
  errorText?: string;
  showErrorText?: boolean;
  showLabelWithoutValue?: boolean;
  required?: boolean;
  validators?: ValidatorName[];
  dynamicSource?: string;
}

export type StrictPrimitive =
  | 'TEXT'
  | 'TEXT_NO_WHITESPACE'
  | 'NUMBER'
  | 'DATE'
  | 'FEIN'
  | 'PHONE'
  | 'MONEY_WITHOUT_DECIMAL'
  | 'MONEY_WITH_DECIMAL';

export type StrictInputNode = TextNode | MultiInputNode | CheckboxNode;

export interface MultiClause {
  primitive: 'MULTI-CLAUSE-CHECKBOX' | 'MULTI-CLAUSE-RADIO';
  cssClass?: string; // should default to blank, but all Primitives should accept it
  options?: { [key: string]: any }; // only radio and select use options
  nameOfFormControl: string;
  inputId: string;
  labelText: string;
  // inputType necessary to make the typechecker happy, probably can
  // be removed once we have a dedicated validators field
  inputType?: string;
  placeholder?: string;
  clauses: string[];
  formStep?: string;
  required?: boolean;
  validators?: ValidatorName[];
  dynamicSource?: string;
}

export const addressNode = (
  {
    nameOfFormControl,
    labelText,
    prefix,
    readonly = false,
    disableStateChange = false,
    displayMap = false,
    useAddressLine2 = true,
    required = true,
    questionNote = '',
    specifierText = '',
  }: {
    nameOfFormControl: string;
    labelText: string;
    prefix: string;
    readonly?: boolean;
    disableStateChange?: boolean;
    displayMap?: boolean;
    useAddressLine2?: boolean;
    required?: boolean;
    questionNote?: string;
    specifierText?: string;
    dynamicSource?: string;
  },
  isPoBox = false,
  validators: ValidatorName[] = [],
  controlOverrides: {
    [k: string]: ControlConfig;
  } = {}
): AddressNode => {
  const requiredValidator = required ? [Validators.required] : [];
  const addressLine1Validators = [
    ...requiredValidator,
    validateNoSpecialCharactersInAddress,
    Validators.maxLength(60),
  ];
  if (!isPoBox) {
    addressLine1Validators.push(validateAddressIsNotPO);
  }
  const defaultControls = {
    addressLine1: ['', addressLine1Validators],
    addressLine2: ['', [validateNoSpecialCharactersInAddress, Validators.maxLength(60)]],
    city: ['', requiredValidator],
    state: ['', requiredValidator],
    zip: ['', [...requiredValidator, zipCodeValidator]],
  };

  const controls = assign({}, defaultControls, controlOverrides);
  return {
    primitive: 'ADDRESS',
    nameOfFormControl,
    labelText,
    controls,
    isFormGroup: true,
    prefix,
    readonly,
    disableStateChange,
    displayMap,
    useAddressLine2,
    required,
    questionNote,
    specifierText,
    validators,
  };
};
export interface AddressNode {
  primitive: 'ADDRESS';
  nameOfFormControl: string;
  labelText: string;
  controls: {
    [k: string]: ControlConfig;
  };
  isFormGroup: true;
  prefix: string;
  readonly?: boolean;
  disableStateChange?: boolean;
  displayMap?: boolean;
  useAddressLine2?: boolean;
  required?: boolean;
  questionNote?: string;
  specifierText?: string;
  validators?: ValidatorName[];
  dynamicSource?: string;
}

export interface FileUploadNode {
  primitive: 'FILE_UPLOAD';
  nameOfFormControl: string;
  inputId: string;
  labelText: string;
  isArray: true;
  values: [];
  fileMetadata: object;
  required?: boolean;
  tooltipText?: string;
  specifierText?: string;
  accept?: string;
  validators?: ValidatorName[];
  dynamicSource?: string;
}

export interface NodeArray {
  primitive: 'NODE_ARRAY';
  nameOfFormControl: string;
  inputId: string;
  childDivCssClass?: string;
  prefix: string;
  labelText?: string;
  children: FormDslNode[][];
  childFooterButtons: ButtonNode[];
  minChildrenToShowChildButtons?: number;
  footerButtons: ButtonNode[];
  cssClass?: string;
  required?: boolean;
  dynamicSource?: string;
}

export interface DropdownSearchNode {
  primitive: 'DROPDOWN_SEARCH';
  inputId: string;
  nameOfFormControl: string;
  labelText: string;
  queryMethodName: SearchQueryMethod; // Methods defined on the FormDslSearchService
  formatterName: SearchFormatter; // Methods defined on the FormDslSearchService
  queryableResults?: string[];
  tooltipText?: string;
  typeaheadPlaceholderText?: string;
  searchExpanded?: boolean;
  cssClass?: string;
  required?: boolean;
  questionNote?: string;
  placeholderText?: string;
  readonly?: boolean;
  dynamicSource?: string;
}

export interface HtmlElementNode {
  primitive: 'H2' | 'PARA';
  text: string; // used for H2 and PARA
  nameOfFormControl?: string;
  cssClass?: string;
  formStep?: string;
  required?: boolean; // not really needed, but necesarry to make the typechecker happy
  dynamicSource?: string;
}

export interface DivNode {
  primitive: 'DIV';
  children: FormDslNode[]; // only used for DIV
  nameOfFormControl?: string;
  cssClass?: string; // should default to blank, but all Primitives should accept it
  formStep?: string;
  required?: boolean; // not really needed, but necesarry to make the typechecker happy
  dynamicSource?: string;
}

export interface ValidationMessageNode {
  primitive: 'VALIDATION_MESSAGE';
  cssClass?: string;
  errorType: string | string[];
  inputId: string;
  nameOfFormControl: string;
  required?: false;
  validationMessage?: string;
  dynamicSource?: string;
}

// NOTE/TODO: This interface is purposely not included in the FormDslNode type because it
// does not include 'nameOfFormControl'. The types here should be cleaned up first so the
// compiler cand differentiate between control and non-control nodes. (See comment above
// BaseFormNode)
export interface ButtonNode {
  primitive: 'BUTTON';
  buttonText: string;
  methodName: string;
  cssClass?: string;
  dynamicSource?: string;
}

export interface DialogBoxNode {
  primitive: 'DIALOG';
  content: string;
  header?: string;
  type: 'primary' | 'warning' | 'danger' | 'success';
  dismissable?: boolean;
  nameOfFormControl?: string;
  inputId?: string;
  cssClass?: string;
  required?: boolean;
  dynamicSource?: string;
}

export interface LinkModalNode {
  primitive: 'LINK_MODAL';
  child: FormDslNode;
  nameOfFormControl?: string;
  modalTitle?: string;
  modalSubTitle?: string;
  modalLinkText?: string;
  modalPreLinkText?: string;
  modalPostLinkText?: string;
  modalBody?: string;
  modalImage?: string;
  removeClasses?: string[];
  cssClass?: string; // should default to blank, but all Primitives should accept it
  formStep?: string;
  required?: boolean; // not really needed, but necesarry to make the typechecker happy
  dynamicSource?: string;
}

export interface ValueConditional {
  primitive: 'VALUE-CONDITIONAL';
  conditionalChildren: FormDslNode[];
  dependsOn: string;
  enableValue: string | boolean;
  nameOfFormControl?: string;
  cssClass?: string;
  formStep?: string;
  required?: boolean; // not really needed, but necessary to make the typechecker happy
  dynamicSource?: string;
}

export interface EvalConditional {
  primitive: 'EVAL-CONDITIONAL';
  conditionalChildren: FormDslNode[];
  dependsOn: string | string[];
  enableEvaluator: EvaluatorName;
  nameOfFormControl?: string;
  cssClass?: string;
  formStep?: string;
  required?: boolean; // not really needed, but necessary to make the typechecker happy
  dynamicSource?: string;
}

export interface TrueConditional {
  primitive: 'TRUE-CONDITIONAL';
  inputId: string;
  nameOfFormControl: string;
  conditionalChildren: FormDslNode[];
  labelText: string;
  cssClass?: string;
  formStep?: string;
  required?: boolean; // not really needed, but necesarry to make the typechecker happy
  dynamicSource?: string;
}

export interface FormDslData {
  [key: string]:
    | string
    | number
    | boolean
    | string[]
    | number[]
    | boolean[]
    | null
    | { [key: string]: boolean };
}

export interface FormDslStep extends RouteFormStep {
  // TODO: consider implementing BehaviorSubject interface for form trees
  getFormTree: (formData?: FormDslData) => Observable<FormDslNode[]>;
  // NOTE: We write to this property whenever we retrieve a form tree from getFormTree.
  // However, we only read and use the value in this property when we are navigate backwards.
  // These design decisions are important for the following reasons:
  // - when navigation lags in long-running asynchronous calls to getFormTree,
  //   the user is tempted to click the navigation button a second time, which breaks the form
  // - applying form tree cache in forward navigation is worrisome because it is possible that
  //   a question on step x adds, removes, or modifies a question on step x + 1
  // - conversely, applying form tree cache in backward navigation is generally fine because we do not anticipate
  //   that many forms would have reverse dependencies in which the tree of step x affects the tree of step x - 1
  formTreeCached?: FormDslNode[];
  hiddenNodeIds?: Set<string>;
}

export type FormDslNode =
  | AddressNode
  | AddressAutoCompleteNode
  | CheckboxNode
  | CheckBoxGroupNode
  | DialogBoxNode
  | DivNode
  | DropdownSearchNode
  | EvalConditional
  | FileUploadNode
  | HtmlElementNode
  | LinkModalNode
  | MultiClause
  | MultiInputNode
  | NodeArray
  | TextAreaNode
  | TextNode
  | TraditionalRadioNode
  | TrueConditional
  | ValidationMessageNode
  | ValueConditional;
