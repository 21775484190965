import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { V4_ACCOUNT_API_URI } from 'app/constants';
import { SentryService } from 'app/core/services/sentry.service';
import {
  HydraPaymentIFrameRes,
  HydraPaymentValidationRes,
  LibertyMutualProductType,
} from '../models/common-typings';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LibertyMutualBindQuoteService {
  paymentSubject: BehaviorSubject<HydraPaymentIFrameRes | null> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private sentryService: SentryService) {}

  requestPaymentIFrame(
    accountId: string,
    quoteUuid: string,
    productType: LibertyMutualProductType
  ): Observable<HydraPaymentIFrameRes> {
    return this.http
      .get<HydraPaymentIFrameRes>(
        `${V4_ACCOUNT_API_URI}/${accountId}/liberty-mutual/${productType}/${quoteUuid}/iframe-request`
      )
      .pipe(
        map((payload: HydraPaymentIFrameRes) => {
          this.paymentSubject.next(payload);
          return payload;
        }),
        catchError((error) => {
          this.sentryService.notify(
            `Liberty Mutual ${productType.toUpperCase()}: error requesting iFrame from Hydra.`,
            {
              severity: 'error',
              metaData: {
                accountId,
                quoteUuid,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            }
          );
          throw error;
        })
      );
  }

  validatePaymentInstrument(
    accountId: string,
    quoteUuid: string,
    instrumentId: string,
    productType: LibertyMutualProductType
  ): Observable<HydraPaymentValidationRes> {
    return this.http
      .get<HydraPaymentValidationRes>(
        `${V4_ACCOUNT_API_URI}/${accountId}/liberty-mutual/${productType}/${quoteUuid}/payment-validation/${instrumentId}`
      )
      .pipe(
        catchError((error) => {
          this.sentryService.notify(
            `Liberty Mutual ${productType.toUpperCase()}: error with Hydra payment validation.`,
            {
              severity: 'error',
              metaData: {
                accountId,
                quoteUuid,
                instrumentId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            }
          );
          throw error;
        })
      );
  }
}
