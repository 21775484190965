import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  API_V3_BOP_QUOTES_URI,
  API_V3_RATED_QUOTE_URI,
  CREATE_QUOTE_API_URI,
} from 'app/features/attune-bop/models/constants';
import { API_V3_HAB_QUOTES_URI } from 'app/hab/constants';
import { ATTUNE_WC_QUOTE_API } from 'app/workers-comp/attune/constants';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RetrieveQuoteResponse, RequestQuoteBody, QuotedPeriod } from 'app/bop/guidewire/typings';
import { HabQuoteSubmission } from 'app/hab/typings';

import { SentryService } from 'app/core/services/sentry.service';

// TODO: Rename to something like "QuoteService" once we've finished the SP -> QS cutover, and
// rename the V3QuoteService to something like "RequestV3QuoteService".
@Injectable()
export class GWService {
  constructor(
    private http: HttpClient,
    private currentUserService: CurrentUserService,
    private sentryService: SentryService
  ) {}

  details(jobId: string): Observable<QuotedPeriod> {
    return this.http.get<QuotedPeriod>(`${API_V3_RATED_QUOTE_URI}/${jobId}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to get BOP quote details.', {
          severity: 'error',
          metaData: {
            jobId,
            currentUserName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  bind(
    quoteId: string,
    payload: QSBindRequest,
    productType: InsuranceProduct,
    bundleId?: string
  ): Observable<QSQuoteSubmissionResponse> {
    let quotes_url;
    if (productType === 'Habitational') {
      quotes_url = API_V3_HAB_QUOTES_URI;
    } else if (productType === 'WC') {
      quotes_url = ATTUNE_WC_QUOTE_API;
    } else {
      quotes_url = API_V3_BOP_QUOTES_URI;
    }
    const postRequest = bundleId
      ? this.http.post<QSQuoteSubmissionResponse>(`${quotes_url}/${quoteId}/bind`, payload, {
          params: { bundleId },
        })
      : this.http.post<QSQuoteSubmissionResponse>(`${quotes_url}/${quoteId}/bind`, payload);

    return postRequest.pipe(
      catchError((error) => {
        this.sentryService.notify(`Unable to bind ${productType} quote.`, {
          severity: 'error',
          metaData: {
            productType,
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  retrieveQuote(jobId: string): Observable<RetrieveQuoteResponse> {
    return this.http.get<RetrieveQuoteResponse>(`${API_V3_BOP_QUOTES_URI}/${jobId}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve BOP quote.', {
          severity: 'error',
          metaData: {
            jobId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  retrieveHabQuote(jobId: string): Observable<HabQuoteSubmission> {
    return this.http.get<HabQuoteSubmission>(`${API_V3_HAB_QUOTES_URI}/${jobId}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve HAB quote.', {
          severity: 'error',
          metaData: {
            jobId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  requestQuote(
    payload: RequestQuoteBody,
    productName: string
  ): Observable<BackendQuoteResponsePayload> {
    return this.http.post<BackendQuoteResponsePayload>(CREATE_QUOTE_API_URI, payload).pipe(
      catchError((error) => {
        this.sentryService.notify(`Unable to quote ${productName}.`, {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  // TODO(SP cutover): Replace `requestQuote` with this once we've finished the cutover.
  requestQuoteViaQuoteService(
    payload: BaseQuote,
    parentQuoteId?: string | null
  ): Observable<QSQuoteSubmissionResponse> {
    const params: { [param: string]: string | string[] } = {};
    if (parentQuoteId) {
      params.parentQuoteId = parentQuoteId;
    }
    return this.http
      .post<QSQuoteSubmissionResponse>(API_V3_BOP_QUOTES_URI, payload, { params })
      .pipe(
        catchError((error) => {
          // Note: 409s are BOP declines -- not errors
          if (!error || error.status !== 409) {
            this.sentryService.notify('Unable to create BOP quote through Quote Service.', {
              severity: 'error',
              metaData: {
                payload,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
          }
          throw error;
        })
      );
  }

  requestFinishedHabQuote(payload: HabQuoteSubmission): Observable<QSQuoteSubmissionResponse> {
    return this.http.post<QSQuoteSubmissionResponse>(API_V3_HAB_QUOTES_URI, payload).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!error || error.status !== 409) {
          this.sentryService.notify('Unable to submit hab quote through Quote Service.', {
            severity: 'error',
            metaData: {
              payload,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
        }
        /* eslint-disable-next-line */
        throw error;
      })
    );
  }

  requestPartialHabQuote(payload: HabQuoteSubmission): Observable<QSQuoteSubmissionResponse> {
    return this.http.patch<QSQuoteSubmissionResponse>(API_V3_HAB_QUOTES_URI, payload).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!error || error.status !== 409) {
          this.sentryService.notify('Unable to submit partial hab quote through Quote Service.', {
            severity: 'error',
            metaData: {
              payload,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
        }
        /* eslint-disable-next-line */
        throw error;
      })
    );
  }

  private currentUserName() {
    const user = this.currentUserService.getCurrentUser();
    if (user && user.username) {
      return user.username;
    }
    throw new Error('Login Expired');
  }
}
