// Libraries
import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';
import { MultiInputNode } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { kebabCase, toString } from 'lodash';

// TODO mend the schism between this component and form field radio
@Component({
  selector: 'app-form-field-radio-dsl',
  templateUrl: './form-field-radio-dsl.component.html',
})
export class FormFieldRadioDslComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() readonly = false;
  @Input() initConfig: MultiInputNode;

  @Output() optionClicked = new EventEmitter<string | boolean | number>();

  private sub: Subscription = new Subscription();
  public inputValue: string | boolean | number | null;

  getControl = getControl;

  ngOnInit() {
    const formControl = getControl(this.form, this.initConfig.nameOfFormControl);
    if (formControl) {
      this.sub.add(
        formControl.valueChanges
          .pipe(startWith(formControl.value))
          .subscribe((selectedValue) => (this.inputValue = selectedValue))
      );
    }
  }

  getOptions() {
    return this.initConfig.options || { Yes: true, No: false };
  }

  getSanitizedOptionId(optionKey: string) {
    return this.initConfig.inputId + '-' + kebabCase(toString(this.getOptions()[optionKey]));
  }

  shouldDisableOption(optionKey: string): boolean {
    if (this.initConfig.disabled) {
      return true;
    }
    if (!this.initConfig.disabledOptions) {
      return false;
    }
    for (let i = 0; i < this.initConfig.disabledOptions.length; i++) {
      if (this.initConfig.disabledOptions[i] === optionKey) {
        return true;
      }
    }
    return false;
  }

  fieldInvalid(field: string): boolean | undefined {
    return (
      (this.submitted && this.initConfig.invalid) ||
      shouldShowInvalid(field, this.form, this.submitted)
    );
  }

  clickOption(option: string | boolean | number) {
    if (this.initConfig.emitClicks) {
      this.optionClicked.emit(option);
    }
  }

  isSelected(optionKey: string): boolean {
    return this.getOptions()[optionKey] === this.inputValue;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
