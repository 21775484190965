import { has, mapValues, reduce } from 'lodash';
import * as moment from 'moment';

import {
  US_DATE_MASK,
  ISO_DATE_MASK,
  ISO_DATE_MASK_PATTERN,
  US_DATE_MASK_PATTERN,
  USD_MONEY_PATTERN,
  USD_PHONE_PATTERN,
} from '../../../constants';
import { parseMoney } from '../../../shared/helpers/number-format-helpers';
import { LibertyMutualClassCode, FormValue } from './common-typings';
import {
  LmBopQuestion,
  LmBopSubmissionField,
  LmBopFormData,
  LmBopLossGroupValue,
} from './lm-bop-typings';
import {
  LmCpspFormData,
  LmCpspLossGroupValue,
  LmCpspQuestion,
  LmCpspSubmissionField,
} from './lm-cpsp-typings';

export const formatPhoneNumber = (phoneNumber: string) => {
  // LM expects a phone format of +1-888-8888888
  // Prepend '+1-' and remove final '-'
  return '+1-' + phoneNumber.replace(/-([^-]*)$/, '$1');
};

export const formatPhoneNumberForDisplay = (phoneNumber: string) => {
  let phoneNumberFormatted = phoneNumber.replace(/-|\+/g, '');
  if (phoneNumberFormatted.startsWith('1')) {
    phoneNumberFormatted = phoneNumberFormatted.slice(1);
  }
  // Insert dash after area code
  const beginningPos = 0;
  const firstDashPos = 3;
  phoneNumberFormatted = `${phoneNumberFormatted.substring(
    beginningPos,
    firstDashPos
  )}-${phoneNumberFormatted.substring(firstDashPos)}`;
  // Insert dash before line number
  const lineNumberLen = 4;
  const phoneNumberFormattedLen = phoneNumberFormatted.length;
  const secondDashPos = phoneNumberFormattedLen - lineNumberLen;
  phoneNumberFormatted = `${phoneNumberFormatted.substring(
    beginningPos,
    secondDashPos
  )}-${phoneNumberFormatted.substring(secondDashPos)}`;
  return phoneNumberFormatted;
};

export const formatStringField = (fieldValue: string) => {
  if (USD_MONEY_PATTERN.test(fieldValue)) {
    return parseMoney(fieldValue);
  }
  if (US_DATE_MASK_PATTERN.test(fieldValue)) {
    return moment(fieldValue, US_DATE_MASK).format(ISO_DATE_MASK);
  }
  if (USD_PHONE_PATTERN.test(fieldValue)) {
    return formatPhoneNumber(fieldValue);
  }
  return fieldValue;
};

export const formatStringFieldForDisplay = (fieldValue: string) => {
  if (ISO_DATE_MASK_PATTERN.test(fieldValue)) {
    return moment(fieldValue, ISO_DATE_MASK).format(US_DATE_MASK);
  }
  if (USD_PHONE_PATTERN.test(fieldValue)) {
    return formatPhoneNumberForDisplay(fieldValue);
  }
  return fieldValue;
};

export const parseFieldValue = (fieldValue: FormValue) => {
  switch (typeof fieldValue) {
    case 'string':
      const formattedString = formatStringField(fieldValue);
      if (formattedString || formattedString === 0) {
        return formattedString;
      }
      return null;
    case 'boolean':
      return fieldValue || null;
    case 'number':
      return fieldValue;
    default:
      return null;
  }
};

export const formatFieldValueForDisplay = (fieldValue: FormValue) => {
  switch (typeof fieldValue) {
    case 'string':
      return formatStringFieldForDisplay(fieldValue);
    default:
      return fieldValue;
  }
};

/**
 * Format all field values appropriately for Liberty Mutual, and discard all 'null'
 * and 'false' values.
 */
export const formatFormFieldValuesCpsp = (formValues: {
  [key in LmCpspQuestion]: FormValue | LibertyMutualClassCode | LmCpspLossGroupValue[] | Address;
}): LmCpspFormData => {
  return reduce(
    formValues,
    (
      formatted: { [key: string]: string | number | true | LmCpspLossGroupValue[] },
      fieldValue,
      fieldName: LmCpspQuestion
    ) => {
      // First, check for fields that are handled specially
      switch (fieldName) {
        case LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE:
          const { code, name } = fieldValue as LibertyMutualClassCode;
          formatted[LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE] = code;
          formatted[LmCpspSubmissionField.BUSINESS_CLASS_DESCRIPTION] = name;
          return formatted;
        case LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE:
          const propertyClassCode = formatStringField(fieldValue as string);
          formatted[fieldName] = propertyClassCode;
          formatted[
            LmCpspSubmissionField.PROPERTY_MOST_HAZARDOUS_CLASS_CODE_MOST_HAZARDOUS_INTERNAL_CLASSIFICATION_CODE
          ] = propertyClassCode;
          return formatted;
        case LmCpspQuestion.ADDRESS:
          const { addressLine1, addressLine2, city, state, zip } = fieldValue as Address;
          formatted[LmCpspSubmissionField.ADDR_STREET_1] = addressLine1;
          formatted[LmCpspSubmissionField.ADDR_CITY] = city;
          formatted[LmCpspSubmissionField.ADDR_STATE] = state;
          formatted[LmCpspSubmissionField.ADDR_ZIP] = zip;
          if (addressLine2) {
            formatted[LmCpspSubmissionField.ADDR_STREET_2] = addressLine2;
          }
          return formatted;
        case LmCpspQuestion.EFFECTIVE_DATE:
          const parsedDate = formatStringField(fieldValue as string);
          formatted[fieldName] = parsedDate;
          formatted[LmCpspSubmissionField.RETROACTIVE_DATE_EMPLOYMENT_PRACTICES] = parsedDate;
          return formatted;
        case LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING:
          const buildingLimit = parseMoney(fieldValue as string);
          if (buildingLimit > 0) {
            formatted[LmCpspSubmissionField.SELECT_BUILDING_CHECKBOX] = true;
            formatted[fieldName] = buildingLimit;
          }
          return formatted;
        case LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY:
          const businessLimit = parseMoney(fieldValue as string);
          if (businessLimit > 0) {
            formatted[LmCpspSubmissionField.SELECT_BUSINESS_PERSONAL_PROPERTY] = true;
            formatted[fieldName] = businessLimit;
          }
          return formatted;
        case LmCpspQuestion.LIMIT_BI_AND_EE:
          const bieeLimit = parseMoney(fieldValue as string);
          if (bieeLimit > 0) {
            formatted[LmCpspSubmissionField.SELECT_BUSINESS_INCOME_WITH_EXTRA_EXPENSE] = true;
            formatted[fieldName] = bieeLimit;
          }
          return formatted;
        case LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE:
          if (fieldValue) {
            formatted[LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE] = 'LL';
          }
          return formatted;
        case LmCpspQuestion.LOSSES:
          formatted[fieldName] = (fieldValue as LmCpspLossGroupValue[]).map((lossGroup) => {
            return mapValues(lossGroup, (val) => {
              return parseFieldValue(val);
            });
          });
          return formatted;
        case LmCpspQuestion.ROOM_RATE_1:
          if (fieldValue) {
            const formattedValue = formatStringField(fieldValue as string);
            formatted[fieldName] = formattedValue;
            formatted[LmCpspSubmissionField.ROOM_RATE_2] = formattedValue;
          }
          return formatted;
        case LmCpspQuestion.OCCUPANCY_RATE_1:
          if (fieldValue) {
            const formattedValue = formatStringField(fieldValue as string);
            formatted[fieldName] = formattedValue;
            formatted[LmCpspSubmissionField.OCCUPANCY_RATE_2] = formattedValue;
          }
          return formatted;
        case LmCpspQuestion.ROOM_OFFERED_RATE_1:
          if (fieldValue) {
            const formattedValue = formatStringField(fieldValue as string);
            formatted[fieldName] = formattedValue;
            formatted[LmCpspSubmissionField.ROOM_OFFERED_RATE_2] = formattedValue;
          }
          return formatted;
        case LmCpspQuestion.EXTERIOR_DOORS_1:
          if (fieldValue) {
            const formattedValue = formatStringField(fieldValue as string);
            formatted[fieldName] = formattedValue;
            formatted[LmCpspSubmissionField.EXTERIOR_DOORS_2] = formattedValue;
          }
          return formatted;
        case LmCpspQuestion.FEDERAL_ID:
          if (fieldValue) {
            formatted[fieldName] = (fieldValue as string).replace('-', '');
          }
          return formatted;
        default:
          break;
      }

      // If not a special case, handle the field based on its value
      const parsedValue = parseFieldValue(fieldValue as FormValue);

      if (parsedValue !== null) {
        formatted[fieldName] = parsedValue;
      }

      return formatted;
    },
    {}
  );
};

/**
 * Format all field values appropriately for Liberty Mutual, and discard all 'null'
 * and 'false' values.
 */
export const formatFormFieldValuesBop = (formValues: {
  [key in LmBopQuestion]: FormValue | LibertyMutualClassCode | LmBopLossGroupValue[] | Address;
}): LmBopFormData => {
  return reduce(
    formValues,
    (
      formatted: { [key: string]: string | number | true | LmBopLossGroupValue[] },
      fieldValue,
      fieldName: LmBopQuestion
    ) => {
      // First, check for fields that are handled specially
      switch (fieldName) {
        case LmBopQuestion.BOP_CLASS_CODE:
          const { code, name } = fieldValue as LibertyMutualClassCode;
          formatted[LmBopQuestion.BOP_CLASS_CODE] = code;
          formatted[LmBopSubmissionField.CLASS_CODE_DESC] = name;
          return formatted;
        case LmBopQuestion.BUSINESS_ADDRESS:
          const { addressLine1, addressLine2, city, state, zip } = fieldValue as Address;
          formatted[LmBopSubmissionField.ADDR_STREET_1] = addressLine1;
          formatted[LmBopSubmissionField.ADDR_CITY] = city;
          formatted[LmBopSubmissionField.ADDR_STATE] = state;
          formatted[LmBopSubmissionField.ADDR_ZIP] = zip;
          if (addressLine2) {
            formatted[LmBopSubmissionField.ADDR_STREET_2] = addressLine2;
          }
          return formatted;
        case LmBopQuestion.UM_UIM_LOSS_TYPE:
          formatted[fieldName] = 'EN';
          return formatted;
        case LmBopQuestion.EFFECTIVE_DATE:
          const parsedDate = formatStringField(fieldValue as string);
          formatted[fieldName] = parsedDate;
          formatted[LmBopSubmissionField.RETROACTIVE_DATE_EMPLOYMENT_PRACTICES] = parsedDate;
          return formatted;
        case LmBopQuestion.LIQUOR_LIABILITY_TYPE:
          if (
            fieldValue === LmBopSubmissionField.BYOB_LIQUOR_LIABILITY_COVERAGE ||
            fieldValue === LmBopSubmissionField.LIQUOR_LIABILITY
          ) {
            formatted[fieldValue] = true;
          }
          return formatted;
        case LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT:
        case LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE:
          const parsedLimit = parseMoney(fieldValue as string);
          if (parsedLimit > 0) {
            formatted[fieldName] = parsedLimit;
          }
          return formatted;
        case LmBopQuestion.LOSSES:
          formatted[fieldName] = (fieldValue as LmBopLossGroupValue[]).map((lossGroup) => {
            return mapValues(lossGroup, (val) => {
              return parseFieldValue(val);
            });
          });
          return formatted;
        default:
          break;
      }

      // If not a special case, handle the field based on its value
      const parsedValue = parseFieldValue(fieldValue as FormValue);

      if (parsedValue !== null) {
        formatted[fieldName] = parsedValue;
      }

      return formatted;
    },
    {}
  );
};

/**
 * Format form data from Liberty Mutual format to Attune format
 */
export const formatFormDataForDisplayBop = (
  formData: LmBopFormData
): {
  [key in LmBopQuestion | LmBopSubmissionField]:
    | FormValue
    | LibertyMutualClassCode
    | Address
    | LmBopLossGroupValue[];
} => {
  const formDataFormatted = reduce(
    formData,
    (
      formatted: {
        [key in LmBopQuestion | LmBopSubmissionField]:
          | FormValue
          | LibertyMutualClassCode
          | Address
          | LmBopLossGroupValue[];
      },
      fieldValue,
      fieldName: LmBopQuestion | LmBopSubmissionField
    ) => {
      // First, check for fields that are handled specially
      switch (fieldName) {
        case LmBopQuestion.BOP_CLASS_CODE:
          const classDesc = formData[LmBopSubmissionField.CLASS_CODE_DESC];
          formatted[fieldName] = {
            code: fieldValue as string,
            name: classDesc as string,
          };
          return formatted;
        case LmBopSubmissionField.ADDR_STREET_1:
        case LmBopSubmissionField.ADDR_STREET_2:
        case LmBopSubmissionField.ADDR_CITY:
        case LmBopSubmissionField.ADDR_STATE:
        case LmBopSubmissionField.ADDR_ZIP:
          // These fields are handled below
          return formatted;
        case LmBopQuestion.LOSSES:
          formatted[fieldName] = (fieldValue as LmBopLossGroupValue[]).map((lossGroup) => {
            return mapValues(lossGroup, (val) => {
              return formatFieldValueForDisplay(val);
            });
          });
          return formatted;
        case LmBopQuestion.UM_UIM_LOSS_TYPE:
          formatted[fieldName] = 'Economic and Non-Economic Loss';
          return formatted;
        case LmBopSubmissionField.BYOB_LIQUOR_LIABILITY_COVERAGE:
          if (fieldValue) {
            formatted[LmBopQuestion.LIQUOR_LIABILITY_TYPE] =
              LmBopSubmissionField.BYOB_LIQUOR_LIABILITY_COVERAGE;
          }
          return formatted;
        case LmBopSubmissionField.LIQUOR_LIABILITY:
          if (fieldValue) {
            formatted[LmBopQuestion.LIQUOR_LIABILITY_TYPE] = LmBopSubmissionField.LIQUOR_LIABILITY;
          }
          return formatted;
        default:
          break;
      }

      // If not a special case, handle the field based on its value
      const formattedValue = formatFieldValueForDisplay(fieldValue as FormValue);

      // Then, handle the rest
      if (formattedValue !== undefined) {
        formatted[fieldName] = formattedValue;
      }

      return formatted;
    },
    {} as {
      [key in LmBopQuestion | LmBopSubmissionField]:
        | FormValue
        | LibertyMutualClassCode
        | Address
        | LmBopLossGroupValue[];
    }
  );

  // Handle address fields specially
  const addressLine1 = formData[LmBopSubmissionField.ADDR_STREET_1];
  const addressLine2 = formData[LmBopSubmissionField.ADDR_STREET_2];
  const city = formData[LmBopSubmissionField.ADDR_CITY];
  const state = formData[LmBopSubmissionField.ADDR_STATE];
  const zip = formData[LmBopSubmissionField.ADDR_ZIP];
  formDataFormatted[LmBopQuestion.BUSINESS_ADDRESS] = {
    addressLine1,
    addressLine2: addressLine2 || '',
    city,
    state,
    zip,
  } as Address;

  // Handle building limit field specially
  if (!has(formData, LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE)) {
    formDataFormatted[LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE] = '0';
  }

  return formDataFormatted;
};

/**
 * Format form data from Liberty Mutual format to Attune format
 */
export const formatFormDataForDisplayCpsp = (
  formData: LmCpspFormData
): {
  [key in LmCpspQuestion | LmCpspSubmissionField]:
    | FormValue
    | LibertyMutualClassCode
    | Address
    | LmCpspLossGroupValue[];
} => {
  const formDataFormatted = reduce(
    formData,
    (
      formatted: {
        [key in LmCpspQuestion | LmCpspSubmissionField]:
          | FormValue
          | LibertyMutualClassCode
          | Address
          | LmCpspLossGroupValue[];
      },
      fieldValue,
      fieldName: LmCpspQuestion | LmCpspSubmissionField
    ) => {
      // First, check for fields that are handled specially
      switch (fieldName) {
        case LmCpspSubmissionField.ADDR_STREET_1:
        case LmCpspSubmissionField.ADDR_STREET_2:
        case LmCpspSubmissionField.ADDR_CITY:
        case LmCpspSubmissionField.ADDR_STATE:
        case LmCpspSubmissionField.ADDR_ZIP:
          // These fields are handled below
          return formatted;
        case LmCpspQuestion.LOSSES:
          formatted[fieldName] = (fieldValue as LmCpspLossGroupValue[]).map((lossGroup) => {
            return mapValues(lossGroup, (val) => {
              return formatFieldValueForDisplay(val);
            });
          });
          return formatted;
        case LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE:
          formatted[LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE] = {
            code: fieldValue,
            name: formatted[LmCpspSubmissionField.BUSINESS_CLASS_DESCRIPTION],
          } as LibertyMutualClassCode;
          return formatted;
        case LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE:
          formatted[LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE] = true;
          return formatted;
        default:
          break;
      }

      // If not a special case, handle the field based on its value
      const formattedValue = formatFieldValueForDisplay(fieldValue as FormValue);

      // Then, handle the rest
      if (formattedValue !== undefined) {
        formatted[fieldName] = formattedValue;
      }

      return formatted;
    },
    {} as {
      [key in LmCpspQuestion | LmCpspSubmissionField]:
        | FormValue
        | LibertyMutualClassCode
        | Address
        | LmCpspLossGroupValue[];
    }
  );

  // Handle address fields specially
  const addressLine1 = formData[LmCpspSubmissionField.ADDR_STREET_1];
  const addressLine2 = formData[LmCpspSubmissionField.ADDR_STREET_2];
  const city = formData[LmCpspSubmissionField.ADDR_CITY];
  const state = formData[LmCpspSubmissionField.ADDR_STATE];
  const zip = formData[LmCpspSubmissionField.ADDR_ZIP];
  formDataFormatted[LmCpspQuestion.ADDRESS] = {
    addressLine1,
    addressLine2: addressLine2 || '',
    city,
    state,
    zip,
  } as Address;

  // Handle building limit field specially
  if (!has(formData, LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING)) {
    formDataFormatted[LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING] = '0';
  }
  if (!has(formData, LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY)) {
    formDataFormatted[LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY] = '0';
  }
  if (!has(formData, LmCpspQuestion.LIMIT_BI_AND_EE)) {
    formDataFormatted[LmCpspQuestion.LIMIT_BI_AND_EE] = '0';
  }

  return formDataFormatted;
};
