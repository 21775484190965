<section class="submission-summary">
  <header>
    <figure class="submission-summary-heading-logo">
      <img src="/assets/img/attune_logo_moire.svg" alt="Attune logo" />
    </figure>
    <h1 class="submission-summary-download-title h2">
      Attune Workers' Compensation Quote Application
    </h1>
  </header>
  <section class="submission-summary-general-details">
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">Quote number</div>
      <div class="submission-summary-field-value js-quote-number">
        {{ quoteNumber }}
      </div>
    </div>
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">Quote Date</div>
      <div class="submission-summary-field-value js-created-time">
        {{ createdAt }}
      </div>
    </div>
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">Exported</div>
      <div class="submission-summary-field-value js-exported-time">
        {{ downloadedAt }}
      </div>
    </div>
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">PRODUCERS PLEASE TAKE NOTE:</div>
      <div class="submission-summary-field-value">
        This document allows you to review the selections you made in Attune's portal for this
        quote. For a full list of coverages/forms associated with this quote, please see the quote
        letter.
      </div>
    </div>
  </section>

  <ng-template
    #fieldGroupTemplate
    let-configurations="configurations"
    let-data="data"
    let-cssLoadClass="cssLoadClass"
  >
    <ng-container *ngFor="let configuration of configurations">
      <!-- Check if the configuration is a group with a heading (DisplayConfigurationGroup) -->
      <div *ngIf="configuration.heading && !isHidden(configuration, data)">
        <div class="submission-summary-data-row">
          <div class="submission-summary-field-label">
            <h2 class="submission-summary-section-heading h3">{{ configuration.heading }}</h2>
          </div>
        </div>
        <!-- Recursively render children of the current group -->
        <ng-container
          *ngTemplateOutlet="
            fieldGroupTemplate;
            context: {
              configurations: configuration.children,
              data: data,
              cssLoadClass: cssLoadClass
            }
          "
        ></ng-container>
      </div>

      <!-- Check if the configuration is a field (DisplayConfiguration) -->
      <div
        *ngIf="configuration.keyName && !isHidden(configuration, data)"
        class="submission-summary-data-row"
      >
        <ng-container>
          <div class="submission-summary-field-label">
            {{ configuration.displayName }}
          </div>
          <!-- IMPORTANT: Do not remove the cssLoadClass class. service-document uses it to ensure that the data has loaded before rendering the pdf -->
          <div [ngClass]="cssLoadClass" class="submission-summary-field-value">
            {{ getFormField(data, configuration.keyName, configuration.formatter) }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <section *ngIf="insuredAccount && accountDisplayConfiguration">
    <ng-container
      *ngTemplateOutlet="
        fieldGroupTemplate;
        context: {
          configurations: accountDisplayConfiguration,
          data: insuredAccount,
          cssLoadClass: 'js-account-detail-value'
        }
      "
    ></ng-container>
  </section>

  <section *ngIf="getQuoteResponse && quoteDisplayConfiguration">
    <ng-container
      *ngTemplateOutlet="
        fieldGroupTemplate;
        context: {
          configurations: quoteDisplayConfiguration,
          data: getQuoteResponse,
          cssLoadClass: 'js-policy-detail-value'
        }
      "
    ></ng-container>
  </section>
</section>
