import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routing } from 'app/app.routes';

import { LeftNavigationComponent } from 'app/core/components/left-navigation/left-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LeftNavigationComponent],
  exports: [LeftNavigationComponent],
  imports: [CommonModule, routing, SharedModule, ReactiveFormsModule],
})
export class NavigationModule {}
