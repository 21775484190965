import { Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SentryService } from 'app/core/services/sentry.service';
import { V4_ACCOUNT_API_URI } from 'app/constants';
import {
  FrontendQuote,
  DigitalCarrierPolicyDetails,
  FrontendQuoteBundle,
  BackendQuoteBundle,
} from '../../digital-carrier/models/types';
import { find } from 'lodash';

export interface FrontendQuoteWithLinks extends FrontendQuote {
  routerLink: string;
}

export interface DigitalCarrierPolicyDetailsWithLinks extends DigitalCarrierPolicyDetails {
  routerLink: string;
}

interface AccountSummaryBackendResponse {
  quotes: FrontendQuote[];
  policyTerms: DigitalCarrierPolicyDetails[];
  quoteBundles: BackendQuoteBundle[];
}

interface AccountSummaryLoadingView {
  status: 'loading';
}

interface AccountSummaryErrorView {
  status: 'error';
  errorMessage: string;
}

export interface AccountSummaryLoadedView {
  status: 'success';
  quotes: FrontendQuoteWithLinks[];
  policyTerms: DigitalCarrierPolicyDetailsWithLinks[];
  quoteBundles: FrontendQuoteBundle[];
}

export type AccountSummaryView =
  | AccountSummaryLoadedView
  | AccountSummaryErrorView
  | AccountSummaryLoadingView;

@Injectable()
export class InsuredAccountSummaryService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getSummary(accountId: string): Observable<AccountSummaryView> {
    return this.http.get(V4_ACCOUNT_API_URI + '/' + accountId).pipe(
      catchError((error: any) => {
        this.sentryService.notify('unable to fetch v4 account summary', {
          severity: 'warning',
          metaData: {
            accountId,
            underlyingerrormessage: error && error.message,
            underlyingerror: error,
          },
        });
        throw new Error('Unable to fetch account summary');
      }),
      map((response: AccountSummaryBackendResponse) => {
        const quotes = this.processQuotes(response.quotes);
        return {
          status: 'success',
          quotes: quotes,
          policyTerms: this.processPolicies(response.policyTerms),
          quoteBundles: this.processQuoteBundles(response.quoteBundles || [], quotes),
        };
      })
    );
  }

  private processQuoteBundles(
    quoteBundles: BackendQuoteBundle[],
    quotes: FrontendQuoteWithLinks[]
  ): FrontendQuoteBundle[] {
    return quoteBundles.map((bundle) => {
      const bopQuote = find(
        quotes,
        (quote) => quote.uuid === bundle.bopQuoteUuid
      ) as FrontendQuoteWithLinks;
      const cyberQuote = find(
        quotes,
        (quote) => quote.uuid === bundle.cyberQuoteUuid
      ) as FrontendQuoteWithLinks;
      return {
        uuid: bundle.uuid,
        brokerEmail: bundle.brokerEmail,
        producerCode: bundle.producerCode,
        accountId: bundle.accountId,
        quotes: [bopQuote, cyberQuote],
      };
    });
  }

  private processQuotes(quotes: FrontendQuote[]): FrontendQuoteWithLinks[] {
    return quotes.map((quote: FrontendQuote) => {
      return {
        routerLink: `/accounts/${quote.accountId}/quotes/${quote.uuid}`,
        ...quote,
      };
    });
  }

  private processPolicies(
    policyTerms: DigitalCarrierPolicyDetails[]
  ): DigitalCarrierPolicyDetailsWithLinks[] {
    return policyTerms.map(
      (policyTerm: DigitalCarrierPolicyDetails): DigitalCarrierPolicyDetailsWithLinks => {
        // TODO: wc should use the same router link url and component for policy details
        let routerLink;
        if (policyTerm.product === 'wc') {
          routerLink = `/accounts/${policyTerm.accountId}/workers-comp/policies/${policyTerm.uuid}`;
        } else {
          // Only WC is using GET_POLICY_DETAILS, which requires a policy identifier, otherwise,
          // other products are using the account summary data, so the quote id, akay uuid is needed
          routerLink = `/accounts/${policyTerm.accountId}/policies/${policyTerm.uuid}`;
        }

        return {
          routerLink: routerLink,
          ...policyTerm,
        };
      }
    );
  }
}
