/**
 * Typings for product submission summary components.
 */
export interface DisplayConfigurationGroup {
  heading: string;
  hide?: boolean;
  children: (DisplayConfiguration | DisplayConfigurationGroup)[];
}

export interface DisplayConfiguration {
  keyName: string;
  displayName?: string;
  hide?: boolean;
  formatter?: (rawValue: number | string | boolean) => string;
}

export const isDisplayConfigGroup = (
  fieldGroup: DisplayConfiguration | DisplayConfigurationGroup
): fieldGroup is DisplayConfigurationGroup => {
  return 'heading' in fieldGroup;
};

export const isDisplayConfig = (
  field: DisplayConfiguration | DisplayConfigurationGroup
): field is DisplayConfiguration => {
  return 'keyName' in field;
};
