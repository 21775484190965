// Libraries
import {
  AfterViewChecked,
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-form-field-select',
  templateUrl: './form-field-select.component.html',
})
export class FormFieldSelectComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() inputId: string;
  @Input() labelText: string;
  @Input() questionNote: string;
  @Input() nameOfSection: string;
  @Input() nameOfFormControl: string;
  @Input() cssClass: string;
  @Input() describedBy: string;
  @Input() availableOptions: string[] | number[] | { [key: string]: string | number };
  @Input() availableOptionsWithOptionGroups: { [optionGroup: string]: (string | number)[] };
  // showOptionGroups toggles whether we use option sub-groups vs a standard list of options.
  @Input() showOptionGroups: boolean;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() placeholder: string;
  @Input() tooltipText: string;
  @Input() readonly: boolean;
  @Input() specifierText: string;
  @Input() ul: any;
  @ViewChild('selectRef') selectRef: ElementRef;

  getControl = getControl;
  mappedOptions: { [key: string]: string | number | (number | string)[] } = {};
  mappedOptionsWithOptionGroups: { [optionGroup: string]: (string | number)[] } = {};

  // While the component can take in a variety of types as input for
  // `availableOptions`, the HTML itself expects a straightforward object
  // mapping from the option values to the option descriptions.
  ngOnInit() {
    if (!this.form) {
      console.warn('Error: Cannot render form control ', this.nameOfFormControl);
      console.warn('> Form is', this.form);
    }

    this.readonly = this.readonly || false;
    this.placeholder = this.placeholder || 'Choose one';
    this.setMappedOptions(this.availableOptions);
    this.setMappedOptionsWithOptionGroups(this.availableOptionsWithOptionGroups);
  }

  // After rendering, if the value is null and the select element is reset to (-1),
  // reset the selection to the placeholder option.
  ngAfterViewChecked() {
    if (
      this.selectRef && // this only affects selects w/o option groups
      getControl(this.form, this.nameOfFormControl).value === null &&
      this.selectRef.nativeElement.selectedIndex === -1
    ) {
      this.selectRef.nativeElement.selectedIndex = 0;
    }
  }

  ngOnChanges(props: SimpleChanges) {
    if (props.availableOptions) {
      this.setMappedOptions(props.availableOptions.currentValue);
    }

    if (props.availableOptionsWithOptionGroups) {
      this.setMappedOptionsWithOptionGroups(props.availableOptionsWithOptionGroups.currentValue);
    }
  }

  isControlEnabled() {
    return getControl(this.form, this.nameOfFormControl).enabled;
  }

  getTypeOf(thing: any): string {
    return typeof thing;
  }

  fieldInvalid(): boolean | undefined {
    return shouldShowInvalid(this.nameOfFormControl, this.form, this.submitted);
  }

  parseInt(inputString: string, radix: number) {
    return parseInt(inputString, radix);
  }

  private setMappedOptions(
    availableOptions: string[] | number[] | { [key: string]: string | number }
  ) {
    this.mappedOptions = {};
    if (Array.isArray(availableOptions)) {
      (<Array<number | string>>availableOptions).forEach((item) => {
        this.mappedOptions[String(item)] = item;
      });
    } else {
      this.mappedOptions = availableOptions;
    }
  }

  private setMappedOptionsWithOptionGroups(availableOptions: {
    [optionGroup: string]: (string | number)[];
  }) {
    this.mappedOptionsWithOptionGroups = {};
    this.mappedOptionsWithOptionGroups = availableOptions;
  }
}
