import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { of as observableOf, Observable, forkJoin } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { SentryService } from 'app/core/services/sentry.service';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { UserService } from 'app/core/services/user.service';
import { UsStateService } from 'app/shared/services/us-state.service';
import { RewardsService } from 'app/shared/services/rewards.service';
import { ActionName } from 'app/shared/rewards/rewards-types';

import {
  fixCountyNode,
  getGlFieldDefaults,
  getGlFieldValidators,
  glBasicTreeV4,
} from 'app/features/hiscox/models/gl-config';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';
import { HiscoxQuoteFormService } from 'app/features/hiscox/services/hiscox-quote-form.service';
import { HiscoxQuoteFormComponent } from 'app/features/hiscox/components/hiscox-quote-form/hiscox-quote-form.component';

import { FormDslData, FormDslNode, TextNode } from 'app/shared/form-dsl/constants/form-dsl-typings';
import {
  findNode,
  correctDateFormat,
  correctMoneyFormat,
  correctPhoneFormat,
  removeNullData,
} from 'app/shared/form-dsl/utils/form-dsl-helpers';
import {
  decodeArrayData,
  removeMalformedFields,
  correctStateFormat,
  coerceAggLoiValue,
  correctRegularLOI,
  expandLiabilityQuoteInputIdV4,
  flattenObjectData,
  renameLocationFields,
  removeFieldsBasedOnValue,
  removeCyberUpsellFields,
  expandPrimarySecondaryLocationFields,
  defaultAnswersToGlQuestions,
} from 'app/features/hiscox/models/component-data';
import { convertDateToUsFormat } from 'app/shared/helpers/date-time-helpers';
import { convertToFormTree } from 'app/features/hiscox/models/gl-translate-dynamic-questions';
import { HiscoxQuoteStatus, HISCOX_API_VERSION, HISCOX_PRODUCTS } from '../../models/hiscox-types';
import { InsuredAccountSummaryService } from '../../../insured-account/services/insured-account-summary.service';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import {
  CoalitionCyberAdmittedProduct,
  QuoteResponse,
  isDraftQuoteResponse,
  isUnavailableResponse,
  isQuoteErrorResponse,
} from '../../../digital-carrier/models/types';
import { ISO_DATE_MASK } from '../../../../constants';
import { NaicsService } from '../../../../shared/services/naics.service';
import { HiscoxGlFormDataFieldV4 } from '../../models/gl-constants';
import {
  CoalitionCyberQuestion,
  CyberQuestionEnablementFlag,
  CyberIndustryData,
} from '../../../coalition/models/cyber-typings.model';
import {
  COALITION_BROKER_OF_RECORD_REFERRAL_REASON,
  DUPLICATE_BROKER_ERROR_MESSAGE,
  NAICS_CODES_TO_INDUSTRY_IDS,
} from '../../../coalition/models/cyber-constants.model';
import { SegmentService } from 'app/core/services/segment.service';

const cleanFormData = (formDataPrior: { [key: string]: any }, state: string, cob: string): any => {
  let formDataResult: { [key: string]: any };
  // remove fields used only for Cyber quote
  formDataResult = removeCyberUpsellFields(formDataPrior);
  formDataResult = decodeArrayData(formDataResult);
  formDataResult = removeFieldsBasedOnValue(formDataResult, HISCOX_PRODUCTS.gl);
  formDataResult = flattenObjectData(formDataResult);
  formDataResult = removeNullData(formDataResult);
  formDataResult = removeMalformedFields(formDataResult);
  formDataResult = renameLocationFields(formDataResult);
  formDataResult = correctDateFormat(formDataResult);
  formDataResult = correctMoneyFormat(formDataResult);
  formDataResult = correctPhoneFormat(formDataResult);
  formDataResult = correctStateFormat(formDataResult, state);
  formDataResult = correctRegularLOI(formDataResult);
  formDataResult = coerceAggLoiValue(formDataResult, cob);

  return formDataResult;
};

@Component({
  selector: 'app-hiscox-gl-quote-form.app-page.app-page__form',
  templateUrl: '../../components/hiscox-quote-form/hiscox-quote-form.component.html',
  providers: [HiscoxQuoteFormService],
})
export class HiscoxGlQuotePageComponent
  extends HiscoxQuoteFormComponent
  implements OnInit, OnDestroy
{
  productType: HISCOX_PRODUCTS.gl = HISCOX_PRODUCTS.gl;
  version: HISCOX_API_VERSION;
  glBasicTreeByVersion: FormDslNode[];

  cyberQuoteWasAttempted = false;

  constructor(
    protected route: ActivatedRoute,
    protected informService: InformService,
    protected insuredAccountService: InsuredAccountService,
    protected router: Router,
    protected amplitudeService: AmplitudeService,
    protected userService: UserService,
    protected usStateService: UsStateService,
    protected hiscoxQuoteService: HiscoxQuoteService,
    public formService: HiscoxQuoteFormService,
    protected sentryService: SentryService,
    protected rewardsService: RewardsService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private accountSummaryService: InsuredAccountSummaryService,
    private naicsService: NaicsService,
    protected segmentService: SegmentService
  ) {
    super(
      route,
      informService,
      insuredAccountService,
      router,
      amplitudeService,
      userService,
      usStateService,
      hiscoxQuoteService,
      formService,
      sentryService,
      segmentService
    );
    this.loadQuestions = this.loadQuestions.bind(this);
  }

  ngOnInit() {
    this.version = HISCOX_API_VERSION.v4;
    this.glBasicTreeByVersion = glBasicTreeV4;
    this.formService.apiVersionSubject.next(this.version);

    // setup hidden questions
    const basicTreeKeys = this.glBasicTreeByVersion.map((node) => {
      return node.nameOfFormControl as string;
    });
    const secondStepHiddenNodeIds = new Set<string>(basicTreeKeys);

    // setup form config
    this.formConfig = [
      {
        /* === BEGIN RouteFormStep PROPERTIES === */
        args: {},
        displayName: 'Basic Info',
        slug: 'quote-basic',
        parent: 'quote-basic',
        formPath: 'quoteBasic',
        /* === END RouteFormStep PROPERTIES === */
        getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
          return observableOf(this.glBasicTreeByVersion);
        },
      },
      {
        /* === BEGIN RouteFormStep PROPERTIES === */
        args: {},
        displayName: 'Rating Info',
        slug: 'quote-rating',
        parent: 'quote-rating',
        formPath: 'quoteRating',
        /* === END RouteFormStep PROPERTIES === */
        hiddenNodeIds: secondStepHiddenNodeIds,
        getFormTree: this.loadQuestions,
      },
    ];

    // set form defaults
    this.formDefaults = getGlFieldDefaults();

    // set form validators
    this.formValidators = getGlFieldValidators();

    // filter cyber industries according to class code
    this.insuredAccountSubject
      .pipe(
        tap((insuredAccount) => {
          const accountNaicsCode = insuredAccount.naicsCode?.code;
          if (accountNaicsCode && NAICS_CODES_TO_INDUSTRY_IDS[accountNaicsCode]) {
            this.formService.naicsCodeSubject.next(accountNaicsCode);
          }
        }),
        switchMap((insuredAccount) => {
          // It's not guaranteed that an insuredAccount will have a naicsCode on it
          if (insuredAccount && insuredAccount.naicsCode && insuredAccount.naicsCode.hash) {
            return this.naicsService.getCyberLiabilityNaicsMapping(insuredAccount.naicsCode.hash);
          }

          // return an empty array if the insuredAccount lacks a naicsCode
          return observableOf([]);
        })
      )
      .subscribe((cyberMappings) => {
        if (cyberMappings.length > 0) {
          this.formService.cyberDescriptionOfBusinessSubject.next(
            cyberMappings.map((industry) => industry.id.toString(10))
          );
        }
      });

    // start hiscox component
    super.ngOnInit();

    // Set listener for Cyber industry ID, so we can fetch the industry when it changes
    this.sub.add(
      this.formService.industryId$.subscribe((industryId: number | null) => {
        if (industryId) {
          this.handleCyberIndustryIdControlUpdates(industryId);
        }
      })
    );

    this.sub.add(
      this.accountSummaryService
        .getSummary(this.route.snapshot.params.accountId)
        .subscribe((accountSummary) => {
          this.formService.accountSummarySubject.next(accountSummary);
        })
    );

    this.sub.add(
      this.naicsService.getProductAvailability().subscribe((availabilities) => {
        this.formService.productAvailabilitiesSubject.next(availabilities);
      })
    );
  }

  protected configureNewForm(accountDetails: InsuredAccount) {
    // retrieve business name field name
    const businessNameInputId = 'BusinessInfo_CommercialName';
    // cast business name node to TextNode in order to access 'value' property
    const businessNameNode = findNode(this.glBasicTreeByVersion, businessNameInputId) as TextNode;
    // set business name
    businessNameNode['value'] = accountDetails.companyName;

    // retrieve state
    const state = accountDetails.state;

    // fix county node based on state
    fixCountyNode(this.glBasicTreeByVersion, state);

    // reset form config to reflect these updates
    const firstStepConfig = this.formConfig[0];
    firstStepConfig['getFormTree'] = (formData: FormDslData = {}): Observable<FormDslNode[]> => {
      return observableOf(this.glBasicTreeByVersion);
    };

    // configure form data and config
    this.formData = {
      [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1]:
        accountDetails.addressLine1,
      [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2]:
        accountDetails.addressLine2,
      [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY]: accountDetails.city,
      [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD]: state,
      [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE]: accountDetails.zip,
    };
  }

  private configureEditFormV4(
    oldFormData: FormDslData,
    classOfBusiness: string,
    state: string,
    insuredAccountDetails: InsuredAccount
  ) {
    // replace questions that should have both primary/secondary in their name, clean will be done before sending to SQ
    oldFormData = expandPrimarySecondaryLocationFields(oldFormData);

    // retrieve coverage start date field name for GL
    const coverageStartDateInputId = expandLiabilityQuoteInputIdV4(
      this.productType,
      'CoverageStartDate'
    );
    // retrieve coverage start date input value
    const coverageStartDate = convertDateToUsFormat(
      _.get(oldFormData, coverageStartDateInputId, '') as string
    );

    // retrieve professional experience field name for GL
    const professionalExperienceInputId =
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE;

    // retrieve professional experience input value
    const professionalExperience = convertDateToUsFormat(
      _.get(oldFormData, professionalExperienceInputId, '') as string
    );

    // retrieve business name field name
    const businessNameInputId = HiscoxGlFormDataFieldV4.BUSINESSINFO_COMMERCIALNAME;

    // use business name from insured account
    const businessName = insuredAccountDetails.companyName;

    // cast business name node to TextNode in order to access 'value' property
    const businessNameNode = findNode(this.glBasicTreeByVersion, businessNameInputId) as TextNode;
    // set business name
    businessNameNode['value'] = businessName;

    // fix county node based on state
    fixCountyNode(this.glBasicTreeByVersion, state);

    // reset form config to reflect these updates
    const firstStepConfig = this.formConfig[0];
    firstStepConfig['getFormTree'] = (formData: FormDslData = {}): Observable<FormDslNode[]> => {
      return observableOf(this.glBasicTreeByVersion);
    };

    if (this.productType === 'gl') {
      defaultAnswersToGlQuestions(oldFormData);
    }

    // update form data
    this.formData = {
      ...oldFormData,
      classOfBusinessCd: classOfBusiness,
      [coverageStartDateInputId]: coverageStartDate,
      [professionalExperienceInputId]: professionalExperience,
    };
  }

  protected configureEditForm(
    oldFormData: FormDslData,
    classOfBusiness: string,
    state: string,
    insuredAccountDetails: InsuredAccount
  ) {
    return this.configureEditFormV4(oldFormData, classOfBusiness, state, insuredAccountDetails);
  }

  handleRedirectError() {
    this.router.navigate(['accounts'], { replaceUrl: true });
    this.informService.errorToast(
      `We encountered an error loading the Hiscox quote flow.`,
      null,
      null,
      'Okay',
      null,
      0
    );
  }

  submitForm(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    // attempt to step forward in order to trigger validation error detection
    if (!this.isSubmittable()) {
      this.clickForward();
      return;
    }

    const requestUuid = 'FROM_HISCOX_DQ_API'; // TODO: this is subject to change
    const quoteId = ''; // TODO: this will change for existing draft quotes
    const formStepFields = this.getFieldsOfStep(this.formService.defaultFormStep);
    const state = formStepFields.BusinessInfo_Locations_Primary_AddrInfo_StateOrProvCd;
    const classOfBusiness = formStepFields.classOfBusinessCd;
    const formData = cleanFormData(this.getFormData(), state, classOfBusiness);

    this.isSubmittingForm = true;
    this.sendSegmentEvent('Quote Attempted');
    this.sub.add(
      forkJoin(
        this.hiscoxQuoteService.requestQuote(
          formData,
          this.productType, // this is set to 'gl' at the top of the component
          this.accountDetails,
          requestUuid,
          quoteId,
          classOfBusiness
        ),
        this.submitCoalitionCyberQuote()
      ).subscribe(([hiscoxResponse, cyberResponse]: [any, QuoteResponse]) => {
        const validQuoteStates = [HiscoxQuoteStatus.DECLINED, HiscoxQuoteStatus.REFERRED];
        this.isSubmittingForm = false;
        if (hiscoxResponse?.error && !validQuoteStates.includes(hiscoxResponse.status)) {
          this.requestQuoteErrorResponse = hiscoxResponse;
          this.requestQuoteErrors = this.getQuoteErrors();
          this.quoteResultModalOpen = true;
          this.amplitudeService.track({
            eventName: 'gl_error',
            detail: JSON.stringify(hiscoxResponse.error),
            useLegacyEventName: true,
          });
          return;
        }
        this.amplitudeService.track({
          eventName: 'quote',
          detail: 'hiscox-gl',
          useLegacyEventName: true,
        });
        if (hiscoxResponse.status !== HiscoxQuoteStatus.DECLINED) {
          this.rewardsService.submitRewardAction({
            actionName: ActionName.QUOTE_FOR_ACCOUNT,
            data: {
              insuredAccountId: this.accountId,
              accountName: formData.BusinessInfo_CommercialName,
              carrierName: 'hiscox',
              product: 'gl',
            },
          });
        }
        this.quotedSuccess$.next(true);

        const queryParams: Params = {};
        if (cyberResponse?.success && cyberResponse.status === 'quoted') {
          queryParams.secondaryQuoteId = cyberResponse.uuid;
        }

        if (
          cyberResponse &&
          isUnavailableResponse(cyberResponse) &&
          cyberResponse.unavailableReasons.includes(COALITION_BROKER_OF_RECORD_REFERRAL_REASON)
        ) {
          this.sentryService.notify(
            'Coalition Cyber: Submit quote response from pl up sell / cross sell contains broker of record error, redirecting user to Help Center form',
            {
              severity: 'info',
              metaData: { response: cyberResponse, product: 'cyber_admitted' },
            }
          );
          queryParams.secondaryQuoteBrokerOfRecordError = true;
        } else if (
          cyberResponse &&
          isQuoteErrorResponse(cyberResponse) &&
          cyberResponse.errors.includes(DUPLICATE_BROKER_ERROR_MESSAGE)
        ) {
          this.sentryService.notify(
            'Coalition Cyber: Submit quote response from pl up sell / cross sell did not have draft status due to Duplicate broker error',
            {
              severity: 'info',
              metaData: { response: cyberResponse, product: 'cyber_admitted' },
            }
          );
          queryParams.secondaryQuoteDuplicateBrokerError = true;
        }

        this.router.navigate(['/accounts', this.accountId, HISCOX_PRODUCTS.gl, 'details'], {
          queryParams,
        });
      })
    );
  }

  loadQuestions(): Observable<FormDslNode[]> {
    const formStepFields = this.getFieldsOfStep(this.formService.defaultFormStep);

    const state: string = formStepFields.BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd;
    const classOfBusiness: string = formStepFields.BusinessInfo_ClassOfBusinessCd;

    return this.hiscoxQuoteService.loadQuestions(this.productType, state, classOfBusiness).pipe(
      map((response: any) => {
        return convertToFormTree(response, state);
      }),
      catchError((error) => {
        // TODO: add toast
        this.sentryService.notify(
          `Hiscox ${this.productType}: Error while trying to load questions`,
          {
            severity: 'error',
            metaData: {
              productType: this.productType,
              cob: classOfBusiness,
              state: state,
              version: this.version,
              error,
            },
          }
        );
        throw error;
      })
    );
  }

  getEmailAndPhone() {
    const emailAddress = this.formService.get<UntypedFormControl>('bindQuote.emailAddress').value;
    const phoneNumber = this.formService.get<UntypedFormControl>('bindQuote.phoneNumber').value;
    const normalizedPhone = phoneNumber.trim().replace(/[^0-9+]/g, '');

    return {
      emailAddress,
      phoneNumber: normalizedPhone,
    };
  }

  fillInHappyPath() {
    this.formService.fillInHappyPath();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // Methods for Cyber upsell
  updateQuestionEnablementFlags(industryData: CyberIndustryData) {
    const flagNames = _.values(CyberQuestionEnablementFlag);
    const flags = _.pick(industryData, flagNames);
    this.formService.cyberIndustryFlagsSubject.next(flags);
  }

  handleCyberIndustryIdControlUpdates(industryId: number) {
    this.sub.add(
      this.digitalCarrierService
        .getCoalitionCyberIndustry(industryId, 'cyber_admitted')
        .subscribe((industryData) => {
          if (!industryData) {
            this.sentryService.notify(
              'Coalition Cyber Upsell: Failed to retrieve industry data; cannot determine questions to enable',
              {
                severity: 'error',
                metaData: {
                  industryId,
                  product: 'cyber_admitted',
                  quoteFlowProduct: HISCOX_PRODUCTS.gl,
                },
              }
            );
            return;
          }
          this.updateQuestionEnablementFlags(industryData);
        })
    );
  }

  submitCoalitionCyberQuote() {
    if (!this.formService.isStandaloneCyberSelected() || this.cyberQuoteWasAttempted) {
      return observableOf(null);
    }

    // All upsell cyber quotes will be in the Admitted market.
    const digitalCarrierProduct: CoalitionCyberAdmittedProduct = {
      product: 'cyber_admitted',
      pasSource: 'coalition',
    };

    const formData = this.formService.getStandaloneCyberFields(HISCOX_PRODUCTS.gl);

    const rawEffectiveDate = formData[CoalitionCyberQuestion.EFFECTIVE_DATE] as string;
    const effectiveDate = moment.utc(rawEffectiveDate).format(ISO_DATE_MASK);
    const account = this.digitalCarrierService.parseAccountForQuoteSubmit(this.accountDetails);

    const quoteResponse$ = this.digitalCarrierService.submitQuote(
      digitalCarrierProduct,
      formData,
      account,
      effectiveDate,
      false
    );

    // We set this flag so that we do not create a new Cyber quote if the Hiscox quote
    // is re-submitted for any reason.
    this.cyberQuoteWasAttempted = true;

    return quoteResponse$.pipe(
      switchMap((quoteResponse: QuoteResponse) => {
        if (isDraftQuoteResponse(quoteResponse) && quoteResponse.pasId) {
          const quoteUuid = quoteResponse.uuid;

          return this.digitalCarrierService.issueQuote(
            digitalCarrierProduct,
            formData,
            account,
            effectiveDate,
            quoteUuid
          );
        } else {
          return observableOf(quoteResponse);
        }
      })
    );
  }
  // END methods for cyber upsell
}
