<section>
  <div class="policies-overview-pane policies-overview-renewals-pane" *ngIf="!policyCount">
    <h3>Upcoming renewals</h3>

    <table>
      <tbody>
        <tr>
          <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="policies-overview-pane policies-overview-renewals-pane" *ngIf="policyCount">
    <div class="policies-overview-pane-header">
      <h3 [ngClass]="{ 'gray-text': getRenewalCount() === 0 }">
        Upcoming renewals
        <span *ngIf="getRenewalCount() && hideRenewals">({{ getRenewalCount() }})</span>
        <span class="gray-text" *ngIf="getRenewalCount() === 0">(0)</span>
        <span
          class="more-info-tip tool-tip tool-tip__large"
          data-tooltip="Policies with renewal effective dates in the next 60 days"
        >
          <span class="icon icon-question"></span>
        </span>
      </h3>

      <a
        class="policy-pane-toggle"
        *ngIf="getRenewalCount() && !hideRenewals"
        (click)="hideRenewals = true"
        (keyup.enter)="hideRenewals = true"
      >
        Hide
      </a>

      <a
        class="policy-pane-toggle"
        *ngIf="getRenewalCount() && hideRenewals"
        (click)="hideRenewals = false"
        (keyup.enter)="hideRenewals = false"
      >
        Show
      </a>
    </div>

    <table *ngIf="!hideRenewals && getRenewalCount()" class="js-renewals-list-table">
      <thead>
        <th>Account</th>
        <th>Effective</th>
        <th>Status</th>
        <th>Premium</th>
      </thead>
      <tbody>
        <ng-container *ngIf="loadingRenewals">
          <tr
            *ngFor="let i of buildArray(estimatedRenewalLength)"
            class="policies-overview-row js-renewals-loading-row"
          >
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell td__right">
              <div class="table-loading-cell"></div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!loadingRenewals">
          <tr class="policies-overview-row" *ngFor="let renewal of renewals">
            <td class="policies-overview-table-cell">{{ renewal.Renewal.AccountName }}</td>
            <td class="policies-overview-table-cell">
              {{ formatDate(renewal.Renewal.PeriodStart) }}
            </td>
            <td class="policies-overview-table-cell js-renewal-status-cell">
              <span ngClass="pill-tag {{ getCssClassForPillTag(renewal) }}">{{
                getFormattedRenewalStatus(renewal)
              }}</span>
            </td>
            <td class="policies-overview-table-cell">
              {{
                formatCost(
                  renewal.Renewal.TotalCost,
                  renewal.Renewal.AccountNumber,
                  renewal.Renewal.PolicyNumber,
                  'renewal'
                )
              }}
            </td>
            <td class="policies-overview-table-cell td__right">
              <a
                class="button button__small button__transparent button__no-margin"
                [routerLink]="getRenewalRouterLink(renewal)"
                ><strong>DETAILS</strong></a
              >
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="policies-overview-page-selector page-selector-with-link">
      <span class="policies-overview-help-center-link">
        <a
          routerLink="/support"
          [queryParams]="getRenewalQuoteLinkParams()"
          data-amplitude-input-name="renewal_list_edit_renewal_quote"
          >Edit a renewal quote</a
        >
      </span>
      <div
        class="js-policies-renewal-page-selector"
        *ngIf="!hideRenewals && this.getRenewalCount() > policyPageLength"
      >
        <a
          *ngIf="renewalsSelectedIndex > 0"
          (click)="loadPreviousRenewals()"
          (keyup.enter)="loadPreviousRenewals()"
          class="js-policies-renewal-previous"
          ><span class="icon-arrow-left button-icon"></span
        ></a>
        <span class="gray-text" *ngIf="renewalsSelectedIndex <= 0"
          ><span class="icon-arrow-left button-icon"></span
        ></span>

        <span>
          {{ getFirstItemNumber(renewalsSelectedIndex) }}-{{
            getLastItemNumber(renewalsSelectedIndex, getRenewalCount(), policyPageLength)
          }}
        </span>
        <span class="gray-text"> of </span>
        <span> {{ getRenewalCount() }} </span>

        <a
          *ngIf="renewalsSelectedIndex + policyPageLength < getRenewalCount()"
          (click)="loadNextRenewals()"
          (keyup.enter)="loadNextRenewals()"
          class="js-policies-renewal-next"
          ><span class="icon-arrow-right button-icon"></span
        ></a>
        <span
          class="gray-text"
          *ngIf="renewalsSelectedIndex + policyPageLength >= getRenewalCount()"
          ><span class="icon-arrow-right button-icon"></span
        ></span>
      </div>
    </div>
  </div>
</section>
