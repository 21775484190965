import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CurrencyPipe } from '@angular/common';
import { BillingApiService } from '../../insured-account/billing-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-commission-statement',
  templateUrl: 'commission-statement.component.html',
  providers: [CurrencyPipe],
})
export class CommissionStatementComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private billingApiService: BillingApiService) {}
  private sub: Subscription = new Subscription();
  commissionStatement: DcpCommissionStatement;

  ngOnInit() {
    const producerCode = this.route.snapshot.params['producerCode'];
    const statementDate = this.route.snapshot.params['statementDate'];
    this.sub.add(
      this.billingApiService
        .fetchDcpCommissionStatementJson(producerCode, statementDate)
        .subscribe((data) => {
          this.commissionStatement = data;
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  totalPremium() {
    return this.commissionStatement.policies
      .map((policy) => {
        return Number(policy.earnedCommission);
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
  }
}
