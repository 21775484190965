import { ValidatorFn, UntypedFormGroup, Validators } from '@angular/forms';

import {
  CoalitionCyberValidators,
  CoalitionCyberQuestion,
  FirstPartyCoverageNestedQuestion,
  ThirdPartyCoverageNestedQuestion,
  CoalitionCyberFormStepPath,
  INSURANCE_MARKET_CONTROL,
} from './cyber-typings.model';
import {
  getControl,
  getFormGroup,
  validateCurrencyGreaterThanValue,
  validateCurrencyGreaterThanZero,
} from 'app/shared/helpers/form-helpers';
import { validateDate } from '../../attune-bop/models/form-validators';
import * as moment from 'moment';
import { US_DATE_MASK } from '../../../constants';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';

const validateIsEligibleForFirstPartyBipd: ValidatorFn = (coverageGroup) => {
  const firstPartyBipdControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.FIRST_PARTY_COVERAGES}.${FirstPartyCoverageNestedQuestion.BIPD_FIRST_PARTY}`
  );

  const insuranceMarketControl = getControl(
    coverageGroup as UntypedFormGroup,
    INSURANCE_MARKET_CONTROL
  );

  if (!firstPartyBipdControl.value) {
    return null;
  }

  const firstPartyCoverages = getFormGroup(
    coverageGroup as UntypedFormGroup,
    CoalitionCyberQuestion.FIRST_PARTY_COVERAGES
  );

  const techEOControl = getControl(firstPartyCoverages, FirstPartyCoverageNestedQuestion.TECH_EO);

  if (techEOControl.enabled && techEOControl.value) {
    return {
      invalidCoverageComboForFirstPartyBIPD: {
        validationMessage:
          'First Party BIPD coverage cannot be added when Technical Errors and Omissions is included',
      },
    };
  }

  let validationMessage: string | null = null;

  const dependentCoveragesForBothMarkets = [
    FirstPartyCoverageNestedQuestion.BUSINESS_INTERRUPTION,
    FirstPartyCoverageNestedQuestion.CRISIS_MANAGEMENT,
    FirstPartyCoverageNestedQuestion.EXTORTION,
  ];

  if (insuranceMarketControl.value === 'cyber_admitted') {
    const dependentCoveragesAreSelected = [
      ...dependentCoveragesForBothMarkets,
      FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_COSTS,
      FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_SERVICES,
    ].every((controlName) => {
      return !!getControl(firstPartyCoverages, controlName).value;
    });

    if (!dependentCoveragesAreSelected) {
      validationMessage = `The following coverages must be selected in order to include 1st Party BIPD coverage:
        <ul class="bulleted-list">
          <li>Breach Response Costs</li>
          <li>Breach Response Services</li>
          <li>Crisis Management and Public Relations</li>
          <li>Direct and Contingent Business Interruption</li>
          <li>Ransomware and Cyber Extortion</li>
        </ul>`;
    }
  }

  if (insuranceMarketControl.value === 'cyber_surplus') {
    const dependentCoveragesAreSelected = [
      ...dependentCoveragesForBothMarkets,
      FirstPartyCoverageNestedQuestion.BREACH_RESPONSE,
    ].every((controlName) => {
      return !!getControl(firstPartyCoverages, controlName).value;
    });

    if (!dependentCoveragesAreSelected) {
      validationMessage = `The following coverages must be selected in order to include 1st Party BIPD coverage:
      <ul class="bulleted-list">
        <li>Breach Response</li>
        <li>Crisis Management and Public Relations</li>
        <li>Direct and Contingent Business Interruption</li>
        <li>Ransomware and Cyber Extortion</li>
      </ul>`;
    }
  }

  if (validationMessage) {
    return {
      missingCoveragesForFirstPartyBIPD: {
        validationMessage,
      },
    };
  }

  return null;
};

const validateIsEligibleForThirdPartyBipd: ValidatorFn = (coverageGroup) => {
  const thirdPartyBipdControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.THIRD_PARTY_COVERAGES}.${ThirdPartyCoverageNestedQuestion.BIPD_THIRD_PARTY}`
  );

  if (!thirdPartyBipdControl.value) {
    return null;
  }

  const techEOControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.FIRST_PARTY_COVERAGES}.${FirstPartyCoverageNestedQuestion.TECH_EO}`
  );

  if (techEOControl.enabled && techEOControl.value) {
    return {
      invalidCoverageComboForFirstPartyBIPD: {
        validationMessage:
          'Third Party BIPD coverage cannot be added when Technical Errors and Omissions is included',
      },
    };
  }

  const regulatoryDefenseControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.THIRD_PARTY_COVERAGES}.${ThirdPartyCoverageNestedQuestion.REGULATORY_DEFENSE}`
  );

  if (regulatoryDefenseControl.enabled && regulatoryDefenseControl.value) {
    return null;
  }

  return {
    missingCoverageForThirdPartyBIPD: {
      validationMessage:
        'The following coverage must be selected in order to include 3rd Party BIPD coverage:\
        <ul class="bulleted-list">\
          <li>Regulatory Defense and Penalties</li>\
        </ul>',
    },
  };
};

const validateIsEligibleForPollution: ValidatorFn = (coverageGroup) => {
  const techEOControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.FIRST_PARTY_COVERAGES}.${FirstPartyCoverageNestedQuestion.TECH_EO}`
  );

  const pollutionControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.THIRD_PARTY_COVERAGES}.${ThirdPartyCoverageNestedQuestion.POLLUTION}`
  );

  if (pollutionControl.value && techEOControl.enabled && techEOControl.value) {
    return {
      invalidCoverageComboForFirstPartyBIPD: {
        validationMessage:
          'Pollution coverage cannot be added when Technical Errors and Omissions is included',
      },
    };
  }

  return null;
};

const validateIsEligibleForReputation: ValidatorFn = (coverageGroup) => {
  const crisisManagementControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.FIRST_PARTY_COVERAGES}.${FirstPartyCoverageNestedQuestion.CRISIS_MANAGEMENT}`
  );

  if (crisisManagementControl.enabled && crisisManagementControl.value) {
    return null;
  }

  const reputationControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.FIRST_PARTY_COVERAGES}.${FirstPartyCoverageNestedQuestion.REPUTATION}`
  );

  if (reputationControl.enabled && reputationControl.value) {
    return {
      missingCoverageForReputation: {
        validationMessage:
          'The following coverage must be selected in order to include Reputational Repair coverage:\
          <ul class="bulleted-list">\
            <li>Crisis Management</li>\
          </ul>',
      },
    };
  }

  return null;
};

const validateIsEligibleForReputationalHarm: ValidatorFn = (coverageGroup) => {
  const crisisManagementControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.FIRST_PARTY_COVERAGES}.${FirstPartyCoverageNestedQuestion.CRISIS_MANAGEMENT}`
  );

  if (crisisManagementControl.enabled && crisisManagementControl.value) {
    return null;
  }

  const reputationalHarmControl = getControl(
    coverageGroup as UntypedFormGroup,
    `${CoalitionCyberQuestion.FIRST_PARTY_COVERAGES}.${FirstPartyCoverageNestedQuestion.REPUTATIONAL_HARM}`
  );

  if (reputationalHarmControl.enabled && reputationalHarmControl.value) {
    return {
      missingCoverageForReputationalHarm: {
        validationMessage:
          'The following coverage must be selected in order to include Reputational Harm Loss coverage:\
          <ul class="bulleted-list">\
            <li>Crisis Management</li>\
          </ul>',
      },
    };
  }

  return null;
};

const validateCoalitionEffectiveDate = (isRenewalQuote: boolean): ValidatorFn => {
  const maxDaysInFuture = isRenewalQuote ? 90 : 60;

  return (effectiveDateControl) => {
    const effectiveDate = effectiveDateControl.value;
    if (!effectiveDate) {
      return null;
    }

    const dateMoment = moment(effectiveDate, US_DATE_MASK);
    const today = moment();
    const maxFutureDateMoment = moment().add(maxDaysInFuture, 'days');
    if (
      dateMoment.isBefore(today, 'date') ||
      dateMoment.isSameOrAfter(maxFutureDateMoment, 'date')
    ) {
      return {
        effectiveDateOutOfRange: {
          value: effectiveDate,
          validationMessage: `Please select a current or future date within the next ${maxDaysInFuture} calendar days.`,
        },
      };
    }

    return null;
  };
};

export const validateCustomerCount: ValidatorFn = (form: UntypedFormGroup) => {
  const piiPhiPciControl = getControl(form, CoalitionCyberQuestion.PII_PHI);
  const numberOfRecordsControl = getControl(form, CoalitionCyberQuestion.PII_PHI_COUNT);
  const annualVolumeOfPaymentCards = getControl(form, CoalitionCyberQuestion.CC_CUSTOMER_COUNT);

  if (piiPhiPciControl.value === 'Yes') {
    if (
      numberOfRecordsControl.value === 'No records' &&
      annualVolumeOfPaymentCards.value === 'No records'
    ) {
      return {
        nonZeroPaymentCardVolume: {
          value: '123',
          validationMessage:
            'Please enter an amount greater than zero for PII/PHI volume or payment card transactions if the company interacts with this type of data.',
        },
      };
    }
  }

  return null;
};

export const validateNetProfitIsLessThanCompanyRevenue = (
  { revenueControlName }: { revenueControlName: string } = {
    revenueControlName: CoalitionCyberQuestion.COMPANY_REVENUE,
  }
): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const revenueControl = getControl(form, revenueControlName);

    const grossProfitNetRevenueControl = getControl(
      form,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE
    );
    if (
      !revenueControl ||
      !grossProfitNetRevenueControl ||
      !revenueControl.value ||
      !grossProfitNetRevenueControl.value ||
      parseMoney(revenueControl.value) > parseMoney(grossProfitNetRevenueControl.value)
    ) {
      return null;
    }

    return {
      grossProfitGreaterThanCompanyRevenue: {
        value: grossProfitNetRevenueControl.value,
        validationMessage:
          'Projected gross profit/net revenue cannot be greater than annual revenue.',
      },
    };
  };
};

export const validateCreditCardCountLessThanOneMillion: ValidatorFn = (formControl) => {
  const value = formControl.value;

  if (!value) {
    return null;
  }

  if (value <= 1_000_000) {
    return {
      ccCountMoreThanOneMillion: {
        value: value,
        validationMessage:
          'Please enter a value greater than 1,000,000 or select a different option above.',
      },
    };
  }

  return null;
};

export const validatePiiPhiCountLessThanOneMillion: ValidatorFn = (formControl) => {
  const value = formControl.value;

  if (!value) {
    return null;
  }

  if (value <= 1_000_000) {
    return {
      piiPhiCountMoreThanOneMillion: {
        value: value,
        validationMessage:
          'Please enter a value greater than 1,000,000 or select a different option above.',
      },
    };
  }

  return null;
};

/**
 * Generates a dictionary of validators. Validators can be configured differently
 * for renewal quotes by optionally passing the isRenewalQuote down to the
 * validator functions.
 *
 * @param isRenewalQuote - whether these validators are for a renewal quote
 * @returns a dictionary of validators. If the key is ...
 *  - the name of a control => the validation relies only on the control's value
 *  - the name of a step => the validator is set on the step's form group; validation
 *      relies on more than one control.
 */
export const COALITION_CYBER_VALIDATORS = (
  isRenewalQuote: boolean
): Partial<CoalitionCyberValidators> => ({
  // Validators for form steps
  [CoalitionCyberFormStepPath.COVERAGE]: [
    validateIsEligibleForFirstPartyBipd,
    validateIsEligibleForPollution,
    validateIsEligibleForReputation,
    validateIsEligibleForReputationalHarm,
    validateIsEligibleForThirdPartyBipd,
  ],
  [CoalitionCyberFormStepPath.UNDERWRITING]: [validateCustomerCount],
  [CoalitionCyberFormStepPath.POLICY_INFO]: [validateNetProfitIsLessThanCompanyRevenue()],
  [CoalitionCyberFormStepPath.QUICK_QUOTE]: [validateNetProfitIsLessThanCompanyRevenue()],
  // Validators for form controls
  [CoalitionCyberQuestion.EFFECTIVE_DATE]: [
    validateDate,
    validateCoalitionEffectiveDate(isRenewalQuote),
  ],
  [CoalitionCyberQuestion.COMPANY_REVENUE]: [validateCurrencyGreaterThanZero],
  [CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE]: [validateCurrencyGreaterThanZero],
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K]: [
    validateCurrencyGreaterThanValue(500_000),
  ],
  [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT]: [Validators.min(1)],
  [CoalitionCyberQuestion.CC_CUSTOMER_COUNT_MORE_THAN_MILLION]: [
    validateCreditCardCountLessThanOneMillion,
  ],
  [CoalitionCyberQuestion.PII_PHI_COUNT_MORE_THAN_MILLION]: [validatePiiPhiCountLessThanOneMillion],
});
