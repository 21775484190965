import {
  InsurancePolicyAdminSource,
  InsuranceProductCode,
} from '../../features/digital-carrier/models/types';
import { DialogBoxType } from '../ui-components/dialog-box.component';
import { FrontendQuoteWithLinks } from '../../features/insured-account/services/insured-account-summary.service';
import { CUSTOMER_CARE_HOURS } from 'app/constants';

export interface QuoteWithEligibleStatusForUsellCard extends FrontendQuoteWithLinks {
  status: 'draft' | 'quoted' | 'under_review' | 'unavailable' | 'referred' | 'declined';
}

export interface DismissalRecord {
  lastDismissedAt: string;
  doNotShow: boolean;
}

export type DismissalRecords = Record<string, DismissalRecord>;

export const CYBER_CROSS_SELL_DISMISSAL_RECORDS_KEY = 'accountIdsWithDismissedCyberProductWindows';
export const UNBUNDLE_TOOLTIP_DISMISSAL_RECORDS_KEY = 'accountIdsWithDismissedUnBundleTooltips';

export interface CrossSellConfiguration {
  baseProduct: string;
  bodyTexts?: string[];
  dialogType?: DialogBoxType;
  dialogHeader?: string;
  dialogBody?: string;
  header?: string;
  footerText?: string;
  pasSource: InsurancePolicyAdminSource;
  percentComplete?: number;
  product: InsuranceProductCode;
  renderEventName: string;
  submitButtonText: string;
  subText?: string;
  pillTagText?: string;
}

export const BOP_CYBER_CROSS_SELL_CONFIGURATION: CrossSellConfiguration = {
  baseProduct: 'bop',
  bodyTexts: [
    'Small businesses are increasingly easy targets for bad actors due to limited security measures.',
    '3rd party data breaches can impact a small business’s liability exposure like Equifax and Experian.',
  ],
  footerText: 'Only a few questions left!',
  header: 'New Cyber Opportunity',
  pasSource: 'coalition',
  percentComplete: 80,
  product: 'cyber_admitted',
  renderEventName: 'coalition_cyber_quote_rendered',
  submitButtonText: 'Finish quote',
  subText: 'You could get more coverage for the dollar with a Coalition Cyber policy.',
};

export const BOP_RENEWAL_CYBER_CROSS_SELL_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_CROSS_SELL_CONFIGURATION,
  header: 'Upgrade this Renewal to Active',
  subText:
    "Add Active Cyber coverage to your client's next term to provide them the protection they need.",
};

const BOP_CYBER_UPSELL_CONFIGURATION_BASE = {
  baseProduct: 'bop',
  pasSource: 'coalition',
  product: 'cyber_admitted',
  submitButtonText: 'View quote',
  pillTagText: 'Because you added Standalone Cyber',
} as const;

export const BOP_CYBER_UPSELL_DRAFT_SUCCESS_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_CONFIGURATION_BASE,
  bodyTexts: [
    'Please note that you will not be able to bind cyber coverage until you complete your quote',
  ],
  header: 'Your cyber quote is almost complete',
  renderEventName: 'coalition_cyber_upsell_draft_success_card_rendered',
  footerText: 'Only a few questions left!',
  submitButtonText: 'Complete quote',
  percentComplete: 80,
};

export const BOP_CYBER_UPSELL_SUCCESS_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_CONFIGURATION_BASE,
  bodyTexts: [
    'Verify that underwriting questions are correct',
    'Add a domain to get a Coaliton Risk Assesment (CRA)',
    'Configure coverages, limits, and retention (deductible)',
  ],
  header: 'Quote submitted! Here are some next steps:',
  renderEventName: 'coalition_cyber_upsell_success_card_rendered',
};

export const BOP_CYBER_UPSELL_DECLINE_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_CONFIGURATION_BASE,
  renderEventName: 'coalition_cyber_upsell_decline_card_rendered',
  dialogType: 'error',
  dialogHeader: 'This quote was declined for the admitted market',
  dialogBody: '',
};

export const BOP_CYBER_UPSELL_EARLY_DECLINE_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_CONFIGURATION_BASE,
  renderEventName: 'coalition_cyber_upsell_early_decline_card_rendered',
  dialogType: 'error',
  dialogHeader: 'This quote was declined for the admitted market',
  dialogBody: '',
  submitButtonText: '',
};

export const BOP_CYBER_UPSELL_ERROR_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_CONFIGURATION_BASE,
  renderEventName: 'coalition_cyber_upsell_error_card_rendered',
  dialogType: 'warning',
  dialogHeader: 'There was an issue processing your cyber quote',
  dialogBody: `<p>We were unable to process your standalone cyber quote. You can attempt another quote by visiting the quote details.</p>\
    <p>If the issue persists, please contact our Customer Care Team at <a href="tel:18885304650">1-888-530-4650</a> (Mon-Fri, ${CUSTOMER_CARE_HOURS.start}-${CUSTOMER_CARE_HOURS.start} ${CUSTOMER_CARE_HOURS.timezone}) \
    or visit our <a href="https://app.attuneinsurance.com/help-center" target="_blank">Help Center</a>.</p>`,
};

export const BOP_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_CONFIGURATION_BASE,
  renderEventName: 'coalition_cyber_upsell_broker_of_record_error_card_rendered',
  dialogType: 'warning',
  dialogHeader: 'This quote has already been created by a different agency',
  dialogBody:
    '<p>We were unable to process this quote because another agency has created a quote for this account.</p>\
    <p>To request a Broker of Record change and unlock quoting for this account, please submit a request to our Customer Care team. \
    Our team will respond to your request within 48 business hours.</p>',
  submitButtonText: 'Submit request',
};

export const BOP_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_CONFIGURATION_BASE,
  renderEventName: 'coalition_cyber_upsell_duplicate_broker_error_card_rendered',
  dialogType: 'warning',
  dialogHeader: 'Your email is registered with a different wholesale agency',
  dialogBody:
    "<p>We were unable to process this quote because your email is already registered in Coalition's system, either with a different wholesale agency or as a Coalition policy holder.\
     To access Coalition Cyber through Attune, you will be required to update your registration.</p>\
    <p>If you choose to update your registration, you will receive a confirmation email within 24 hours from our Customer Care Team.</p>",
  submitButtonText: 'Update my registration',
};

export const GL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION,
  baseProduct: 'gl',
};
export const PL_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_BROKER_OF_RECORD_ERROR_CONFIGURATION,
  baseProduct: 'pl',
};

export const GL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION,
  baseProduct: 'gl',
};
export const PL_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION: CrossSellConfiguration = {
  ...BOP_CYBER_UPSELL_DUPLICATE_BROKER_ERROR_CONFIGURATION,
  baseProduct: 'pl',
};

export const HISCOX_GL_CYBER_CROSS_SELL_CONFIGURATION: CrossSellConfiguration = {
  pillTagText: 'Beta',
  renderEventName: 'hiscox_gl_cross_sell_coalition_cyber_quote_rendered',
  baseProduct: 'gl',
  bodyTexts: [
    'Small businesses are increasingly easy targets for bad actors due to limited security measures.',
    '3rd party data breaches can impact a small business’s liability exposure like Equifax and Experian.',
  ],
  footerText: 'Only a few questions left!',
  header: 'New Cyber Opportunity',
  pasSource: 'coalition',
  percentComplete: 30,
  product: 'cyber_admitted',
  submitButtonText: 'Finish quote',
  subText: 'You could get more coverage for the dollar with a Coalition Cyber policy.',
};

export const HISCOX_PL_CYBER_CROSS_SELL_CONFIGURATION: CrossSellConfiguration = {
  pillTagText: 'Beta',
  renderEventName: 'hiscox_pl_cross_sell_coalition_cyber_quote_rendered',
  baseProduct: 'pl',
  bodyTexts: [
    'Small businesses are increasingly easy targets for bad actors due to limited security measures.',
    '3rd party data breaches can impact a small business’s liability exposure like Equifax and Experian.',
  ],
  footerText: 'Only a few questions left!',
  header: 'New Cyber Opportunity',
  pasSource: 'coalition',
  percentComplete: 30,
  product: 'cyber_admitted',
  submitButtonText: 'Finish quote',
  subText: 'You could get more coverage for the dollar with a Coalition Cyber policy.',
};
