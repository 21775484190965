<section>
  <h2 *ngIf="authenticated">Submit an email request</h2>

  <form
    [formGroup]="form"
    (ngSubmit)="handleSubmit($event)"
    aria-labelledby="form-title"
    novalidate
  >
    <app-form-field-select
      class="js-category-select"
      inputId="categorySelect"
      nameOfFormControl="categorySelect"
      [submitted]="submitted"
      [labelText]="categorySelectLabel"
      [availableOptionsWithOptionGroups]="availableCategoryTitles"
      showOptionGroups="true"
      [form]="form"
    ></app-form-field-select>

    <app-form-field-select
      inputId="subcategorySelect"
      nameOfFormControl="subcategorySelect"
      [submitted]="submitted"
      [labelText]="subcategorySelectLabel"
      [availableOptions]="availableSubcategoryTitles"
      [form]="form"
    ></app-form-field-select>

    <app-form-dsl-form
      *ngIf="hasInnerForm"
      [formTree]="formTree"
      [form]="getInnerForm()"
      [submitted]="submitted"
      [extraData]="extraData"
    ></app-form-dsl-form>

    <app-form-field-textarea
      inputId="ticketMessage"
      [form]="form"
      [submitted]="submitted"
      labelText="Message"
      specifierText="(optional)"
      nameOfFormControl="message"
    >
    </app-form-field-textarea>

    <app-form-field-text
      inputId="emailAddress"
      [form]="form"
      [submitted]="submitted"
      labelText="Your email address"
      nameOfFormControl="userEmail"
      showErrorText="true"
      errorText="Please enter a valid email address."
      appRemoveWhitespaceMask
    >
    </app-form-field-text>

    <div class="validation" role="alert" *ngIf="submitted && !isFormValid()">
      <p class="validation-message">Please fill out all required fields.</p>
    </div>

    <button type="submit" [disabled]="loadingResponse" class="button button__primary">
      Submit request
    </button>
  </form>
</section>
