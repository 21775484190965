import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { of as observableOf, Observable, combineLatest } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';

import {
  CyberIndustryData,
  CoalitionDocument,
  BreachEstimate,
} from 'app/features/coalition/models/cyber-typings.model';
import { API_V4_BASE, ISO_DATE_MASK } from 'app/constants';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { RewardsService } from 'app/shared/services/rewards.service';
import { ActionName } from 'app/shared/rewards/rewards-types';

import {
  FormDSLFormData,
  DigitalQuoteRequest,
  QuoteResponse,
  isReferralResponse,
  isQuoteErrorResponse,
  isDeclinedQuoteResponse,
  FrontendQuote,
  ProductCombination,
  AttuneInsuredAccount,
  DigitalBindRequest,
  FormDSLQuoteRequest,
  ComplianceDocumentFetchResponse,
  DigitalCarrierPolicyDetails,
  isDraftQuoteResponse,
} from '../models/types';
import * as moment from 'moment-timezone';

export const DCP_PASSIVE_QUOTE_API_URL = (dcpProduct: ProductCombination) =>
  `${API_V4_BASE}/passive-quote/${dcpProduct.pasSource}/${dcpProduct.product}`;

export const DCP_CREATE_QUOTE_API_URL = (dcpProduct: ProductCombination) =>
  `${API_V4_BASE}/quotes/${dcpProduct.pasSource}/${dcpProduct.product}`;

export const DCP_EDIT_QUOTE_API_URL = (dcpProduct: ProductCombination, quoteId: string) =>
  `${DCP_CREATE_QUOTE_API_URL(dcpProduct)}/${quoteId}/edit`;

export const DCP_ISSUE_QUOTE_API_URL = (dcpProduct: ProductCombination, quoteId: string) =>
  `${DCP_CREATE_QUOTE_API_URL(dcpProduct)}/${quoteId}/issue`;

export const DCP_BIND_QUOTE_API_URL = (dcpProduct: ProductCombination, quoteId: string) =>
  `${DCP_CREATE_QUOTE_API_URL(dcpProduct)}/${quoteId}/bind`;

const cleanObjToStrings = (obj: object): object => {
  return _.mapValues(_.pickBy(obj, _.identity), _.toString);
};

@Injectable({
  providedIn: 'root',
})
export class DigitalCarrierQuoteService {
  constructor(
    private http: HttpClient,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private currentUserService: CurrentUserService,
    private rewardsService: RewardsService
  ) {}

  trackQuoteResponse(dcpProduct: ProductCombination, resp: QuoteResponse) {
    const trackDetails: Record<string, string | number | undefined | object> = {
      ...dcpProduct,
      status: resp.status,
    };

    if (isReferralResponse(resp)) {
      trackDetails['referralReasons'] = JSON.stringify(resp.referralReasons, null, 2);
    }

    if (isDeclinedQuoteResponse(resp)) {
      trackDetails['declineReasons'] = JSON.stringify(resp.declineReasons, null, 2);
    }

    const dcpString = `${dcpProduct.pasSource}-${dcpProduct.product}`;

    // having an all encompasing quote_response event allows grouping by status in details
    this.amplitudeService.trackWithOverride({
      eventName: 'quote_response',
      detail: dcpString,
      payloadOverride: trackDetails,
    });
    this.amplitudeService.trackWithOverride({
      eventName: `quote_${resp.status}`,
      detail: dcpString,
      payloadOverride: trackDetails,
    });
  }

  trackIssueQuoteResponse(dcpProduct: ProductCombination, resp: QuoteResponse) {
    const trackDetails = {
      ...dcpProduct,
      status: resp.status,
    };
    const dcpString = `${dcpProduct.pasSource}-${dcpProduct.product}`;
    const eventNameString = resp.status === 'quoted' ? 'issue_success' : `issue_${resp.status}`;

    this.amplitudeService.trackWithOverride({
      eventName: 'issue_response',
      detail: dcpString,
      payloadOverride: trackDetails,
    });

    this.amplitudeService.trackWithOverride({
      eventName: eventNameString,
      detail: dcpString,
      payloadOverride: trackDetails,
    });
  }

  trackQuoteBindResponse(dcpProduct: ProductCombination, resp: QuoteResponse) {
    const trackDetails = {
      ...dcpProduct,
      status: resp.status,
    };

    const dcpString = `${dcpProduct.pasSource}-${dcpProduct.product}`;
    const eventNameString = resp.status === 'bound' ? 'bind_success' : `bind_${resp.status}`;

    this.amplitudeService.trackWithOverride({
      eventName: 'bind_response',
      detail: dcpString,
      payloadOverride: trackDetails,
    });
    this.amplitudeService.trackWithOverride({
      eventName: eventNameString,
      detail: dcpString,
      payloadOverride: trackDetails,
    });
  }

  trackQuoteAttempt(dcpProduct: ProductCombination, req: DigitalQuoteRequest) {
    const trackDetails = {
      ...dcpProduct,
      ...cleanObjToStrings(req),
    };

    this.amplitudeService.trackWithOverride({
      eventName: 'quote_attempt',
      detail: `${dcpProduct.pasSource}-${dcpProduct.product}`,
      payloadOverride: trackDetails,
    });
  }

  trackIssueQuoteAttempt(
    dcpProduct: ProductCombination,
    req: { account: AttuneInsuredAccount; effectiveDate: string; requestId: string }
  ) {
    const trackDetails = {
      ...dcpProduct,
      ...req,
    };

    this.amplitudeService.trackWithOverride({
      eventName: 'issue_attempt',
      detail: `${dcpProduct.pasSource}-${dcpProduct.product}`,
      payloadOverride: trackDetails,
    });
  }

  trackQuoteBindAttempt(dcpProduct: ProductCombination, req: DigitalBindRequest) {
    const trackDetails = {
      ...dcpProduct,
      ...req,
    };

    this.amplitudeService.trackWithOverride({
      eventName: 'bind_attempt',
      detail: `${dcpProduct.pasSource}-${dcpProduct.product}`,
      payloadOverride: trackDetails,
    });
  }

  public parseAccountForQuoteSubmit(insuredAccount: InsuredAccount): AttuneInsuredAccount {
    return _.pick(insuredAccount, [
      'id',
      'companyName',
      'organizationType',
      'phoneNumber',
      'emailAddress',
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'zip',
      'doingBusinessAs',
      'website',
      'naicsCode',
    ]);
  }

  submitQuote(
    dcpProduct: ProductCombination,
    formData: FormDSLFormData,
    account: AttuneInsuredAccount,
    effectiveDate: string,
    isEditing: boolean,
    isPartialSubmission: boolean = false,
    quoteId?: string,
    requestId?: string,
    renewalOfQuoteId?: string
  ): Observable<QuoteResponse> {
    if (quoteId === undefined) {
      if (isEditing) {
        return observableOf({
          success: false,
          status: 'error',
          errors: [],
          uuid: '12345',
          errorMessage: 'Edited quotes must include quote ID',
        });
      }
      quoteId = '';
    }
    if (requestId === undefined) {
      requestId = uuidv4();
    }

    const quoteRequest: DigitalQuoteRequest = {
      requestId,
      effectiveDate,
      formData,
      account,
      quoteId,
      renewalOfQuoteId,
    };

    this.trackQuoteAttempt(dcpProduct, quoteRequest);

    let quoteSubmissionResponse: Observable<QuoteResponse>;

    if (isEditing) {
      quoteSubmissionResponse = this.http.post<QuoteResponse>(
        DCP_EDIT_QUOTE_API_URL(dcpProduct, quoteId),
        quoteRequest,
        {
          headers: { 'Content-type': 'application/json' },
        }
      );
    } else {
      quoteSubmissionResponse = this.http.post<QuoteResponse>(
        DCP_CREATE_QUOTE_API_URL(dcpProduct),
        quoteRequest,
        {
          headers: { 'Content-type': 'application/json' },
        }
      );
    }

    return quoteSubmissionResponse.pipe(
      tap((response) => {
        this.trackQuoteResponse(dcpProduct, response);

        // prevent products that call `submitQuote` w/ partial data from rewarding coins until `issueQuote`
        if (
          !isDeclinedQuoteResponse(response) &&
          !isQuoteErrorResponse(response) &&
          !isPartialSubmission
        ) {
          this.rewardsService.submitRewardAction({
            actionName: ActionName.QUOTE_FOR_ACCOUNT,
            data: {
              insuredAccountId: account.id,
              accountName: account.companyName,
              carrierName: dcpProduct.pasSource,
              product: dcpProduct.product,
            },
          });
        }
      }),
      catchError((error: any) => {
        const amplitudeEvent = isEditing ? 'edit_quote_error' : 'create_quote_error';
        const sentryNotification = isEditing
          ? `Error editing ${dcpProduct} quote`
          : `Error creating ${dcpProduct} quote`;

        this.amplitudeService.track({
          eventName: amplitudeEvent,
          detail: `${dcpProduct.pasSource}-${dcpProduct.product}`,
        });
        this.sentryService.notify(sentryNotification, {
          severity: 'warning',
          metaData: {
            dcpProduct,
            accountId: account.id,
            quoteId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        if (error instanceof HttpErrorResponse) {
          return observableOf(error.error);
        }

        return observableOf(error);
      })
    );
  }

  // Currently only neptune flood
  passiveQuote(account: AttuneInsuredAccount, requestId?: string): Observable<QuoteResponse> {
    if (requestId === undefined) {
      requestId = uuidv4();
    }
    const quoteRequest = {
      requestId,
      account,
    };

    return this.http.post<QuoteResponse>(
      DCP_PASSIVE_QUOTE_API_URL({ pasSource: 'neptune', product: 'flood' }),
      quoteRequest,
      {
        headers: { 'Content-type': 'application/json' },
      }
    );
  }

  // Currently only Coalition Cyber
  // formData is included to be later retrieved from getQuoteSubmission but not strictly necessary for issue.
  issueQuote(
    dcpProduct: ProductCombination,
    formData: FormDSLFormData,
    account: AttuneInsuredAccount,
    effectiveDate: string,
    quoteId: string
  ): Observable<HttpResponse<QuoteResponse> | null> {
    const requestId = uuidv4();

    const requestBody = {
      account,
      formData,
      effectiveDate,
      requestId,
    };

    const issueQuoteResponse$ = this.http.post<QuoteResponse>(
      DCP_ISSUE_QUOTE_API_URL(dcpProduct, quoteId),
      requestBody,
      {
        observe: 'response',
      }
    );

    this.trackIssueQuoteAttempt(dcpProduct, requestBody);

    return issueQuoteResponse$.pipe(
      tap((response) => {
        if (response.body) {
          this.trackIssueQuoteResponse(dcpProduct, response.body);
        }

        // only submit cyber quotes for rewards at issue
        // if/when issueQuote is used for products other than cyber
        // make sure submitRewardAction is only being called once
        this.rewardsService.submitRewardAction({
          actionName: ActionName.QUOTE_FOR_ACCOUNT,
          data: {
            insuredAccountId: account.id,
            accountName: account.companyName,
            carrierName: dcpProduct.pasSource,
            product: dcpProduct.product,
          },
        });
      }),
      catchError((error: any) => {
        this.amplitudeService.track({
          eventName: 'issue_error',
          detail: `${dcpProduct.pasSource}-${dcpProduct.product}`,
        });
        // NOTE: This message should be generalized if used for other products in the future.
        this.sentryService.notify('Coalition Cyber: Error issuing quote', {
          severity: 'error',
          metaData: {
            dcpProduct,
            accountId: account.id,
            quoteId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }

  getQuoteDetails(quoteId: string, searchBy = 'uuid'): Observable<FrontendQuote | null> {
    return this.http
      .get<FrontendQuote>(`${API_V4_BASE}/quotes/${quoteId}`, { params: { searchBy } })
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Unable to get quote details.', {
            // Searching by pasId can often times cause a noisy error when it should handle the null response.
            severity: searchBy === 'uuid' ? 'error' : 'warning',
            metaData: {
              quoteId,
              searchBy,
              currentUserName: this.currentUserName(),
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  getPreviousPolicyDetails(quoteId: string): Observable<DigitalCarrierPolicyDetails | null> {
    return this.http
      .get<DigitalCarrierPolicyDetails>(`${API_V4_BASE}/quotes/${quoteId}/previous-policy-details`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.sentryService.notify('Unable to get previous policy details', {
            // 404s are expected, since most quotes will not have an associated previous policy.
            severity: error?.status === 404 ? 'info' : 'error',
            metaData: {
              quoteId,
              underlyingErrorMessage: error?.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  getQuoteSubmission(
    dcpProduct: ProductCombination,
    quoteId: string
  ): Observable<FormDSLQuoteRequest | null> {
    return this.http.get<FormDSLQuoteRequest>(`${API_V4_BASE}/quotes/${quoteId}/submission`).pipe(
      catchError((error: any) => {
        // Quote submissions are not returned when the quote isn't a draft or
        // quoted quote, leading to a 404 error. These 404's are expected, so
        // they are sent as `info` notifications to Sentry rather than `error`
        // notifications.
        const severity =
          error instanceof HttpResponseBase && error.status === 404 ? 'info' : 'error';

        this.sentryService.notify('Unable to get quote submission.', {
          severity,
          metaData: {
            dcpProduct,
            currentUserName: this.currentUserName(),
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  bindQuote(
    bindRequest: DigitalBindRequest,
    dcpProduct: ProductCombination,
    account: AttuneInsuredAccount,
    quoteUuid: string,
    requestId?: string,
    bundleId?: string
  ) {
    if (requestId === undefined) {
      requestId = uuidv4();
    }
    this.trackQuoteBindAttempt(dcpProduct, bindRequest);

    const options: Record<string, any> = {
      headers: { 'Content-type': 'application/json' },
      observe: 'response', // Allows us to get the whole response including HTTP status
    };

    if (bundleId) {
      options.params = { bundleId };
    }

    return this.http
      .post<HttpResponse<QuoteResponse>>(
        DCP_BIND_QUOTE_API_URL(dcpProduct, quoteUuid),
        bindRequest,
        options
      )
      .pipe(
        tap(({ body }) => {
          if (body?.status) {
            this.trackQuoteBindResponse(dcpProduct, body);
          }
          if (body?.status === 'bound' || body?.status === 'bound_with_subjectivity') {
            this.rewardsService.submitRewardAction({
              actionName: ActionName.BIND_POLICY_FOR_ACCOUNT,
              data: {
                insuredAccountId: account.id,
                accountName: account.companyName,
                carrierName: dcpProduct.pasSource,
                product: dcpProduct.product,
              },
            });
          }
        }),
        catchError((error: any) => {
          this.amplitudeService.track({
            eventName: 'bind_error',
            detail: `${dcpProduct.pasSource}-${dcpProduct.product}`,
          });
          this.sentryService.notify(`Error binding ${dcpProduct} quote`, {
            severity: 'warning',
            metaData: {
              dcpProduct,
              accountId: account.id,
              quoteUuid,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(error);
        })
      );
  }

  getComplianceDocuments(dcpProduct: ProductCombination, quoteId: string) {
    const { pasSource, product } = dcpProduct;
    return this.http
      .get<ComplianceDocumentFetchResponse>(
        `${API_V4_BASE}/quotes/${pasSource}/${product}/${quoteId}/compliance-documents`
      )
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Coalition Cyber: Unable to fetch compliance documents.', {
            severity: 'error',
            metaData: {
              product,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf({
            success: false,
            documents: [],
            expectedDocumentCount: 0,
          } as ComplianceDocumentFetchResponse);
        })
      );
  }

  getCoalitionCyberDocuments(
    resourceType: 'quote' | 'policy',
    resourceUuid: string
  ): Observable<CoalitionDocument[]> {
    return this.http
      .get<any>(`${API_V4_BASE}/coalition-cyber-documents/${resourceType}/${resourceUuid}`)
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Coalition Cyber: Unable to fetch documents.', {
            severity: 'error',
            metaData: {
              resourceType,
              resourceUuid,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf([]);
        })
      );
  }

  getCoalitionCyberIndustry(
    industryId: number,
    product: ProductCombination['product']
  ): Observable<CyberIndustryData | null> {
    return this.http
      .get<CyberIndustryData>(`${API_V4_BASE}/coalition-cyber-industries/${industryId}`)
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Coalition Cyber: Unable to fetch industry data.', {
            severity: 'error',
            metaData: {
              industryId,
              product,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf(null);
        })
      );
  }

  getCoalitionBreachEstimates(quoteId: string): Observable<BreachEstimate | null> {
    return this.http
      .get<BreachEstimate>(`${API_V4_BASE}/coalition-cyber/${quoteId}/breach-estimate-calculator`)
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Coalition Cyber: Unable to fetch breach estimates.', {
            severity: 'error',
            metaData: {
              quoteId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf(null);
        })
      );
  }

  getCoalitionCyberAddress(quoteId: string): Observable<Address> {
    return this.http.get<Address>(`${API_V4_BASE}/coalition-cyber/address/${quoteId}`).pipe(
      catchError((error: any) => {
        this.sentryService.notify('Coalition Cyber: Unable to fetch address.', {
          severity: 'error',
          metaData: {
            quoteId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf({
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zip: '',
        });
      })
    );
  }

  cloneCoalitionCyber(id: string, accountDetails: InsuredAccount, effectiveDate?: string) {
    const tomorrow = moment().add(1, 'days').format(ISO_DATE_MASK);
    return combineLatest(
      this.getQuoteSubmission(
        {
          product: 'cyber_admitted',
          pasSource: 'coalition',
        },
        id
      ),
      observableOf(accountDetails)
    ).pipe(
      switchMap(([submission, account]: [FormDSLQuoteRequest | null, InsuredAccount]) => {
        if (!submission) {
          return observableOf([null, null, account]);
        }
        this.amplitudeService.track({
          eventName: 'bop_policy_pane_submit_bundle_cyber_quote_clone',
          detail: account.id,
        });
        return combineLatest(
          // Does not require early decline reasons in edit flow for first iteration of bundle edit
          // because we are only handling bundles for successfully quoted cyber and bop quotes.
          this.submitQuote(
            {
              product: 'cyber_admitted',
              pasSource: 'coalition',
            },
            submission.formData,
            account,
            effectiveDate ? effectiveDate : tomorrow,
            false
          ),
          observableOf(submission),
          observableOf(account)
        );
      }),
      switchMap(
        ([quoteResponse, submission, account]: [
          QuoteResponse,
          FormDSLQuoteRequest,
          InsuredAccount
        ]) => {
          if (!quoteResponse || !isDraftQuoteResponse(quoteResponse) || !submission) {
            return observableOf(null);
          }
          return this.issueQuote(
            {
              product: 'cyber_admitted',
              pasSource: 'coalition',
            },
            submission.formData,
            account,
            effectiveDate ? effectiveDate : tomorrow,
            quoteResponse.uuid
          ).pipe(map((response) => response?.body));
        }
      ),
      first(),
      catchError((error) => {
        this.sentryService.notify('Error cloning cyber quote', {
          severity: 'warning',
          metaData: {
            accountId: accountDetails.id,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  private currentUserName() {
    const user = this.currentUserService.getCurrentUser();
    if (user && user.username) {
      return user.username;
    }
    throw new Error('Login Expired');
  }
}
