<h1 class="form-header-below-link">Loss info</h1>
<p class="p__big-margin">
  Please check all of the following policy periods in which a loss occurred.
</p>

<div *ngFor="let lossPolicyPeriod of lossHistory; let i = index">
  <app-form-field-checkbox
    inputId="losses-{{ i }}-hasClaims"
    labelText="Policy effective {{ lossPolicyPeriod.value['policyEffectiveDate'] }}"
    nameOfFormControl="hasClaims"
    [form]="lossPolicyPeriod"
  >
  </app-form-field-checkbox>

  <div *ngIf="lossPolicyPeriod.value['hasClaims']" class="multi-form-item">
    <div class="form-split">
      <app-form-field-text
        inputId="losses-{{ i }}-policyEffectiveDate"
        labelText="Policy effective date"
        [form]="lossPolicyPeriod"
        [submitted]="submitted"
        [readonly]="true"
        nameOfFormControl="policyEffectiveDate"
        appDateMask
      >
      </app-form-field-text>
      <app-form-field-text
        inputId="losses-{{ i }}-numClaims"
        [form]="lossPolicyPeriod"
        [submitted]="submitted"
        labelText="Number of claims"
        nameOfFormControl="numClaims"
        appDelimitedNumberMask
      >
      </app-form-field-text>
    </div>

    <div class="form-split">
      <app-form-field-text
        inputId="losses-{{ i }}-amountPaid"
        [form]="lossPolicyPeriod"
        [submitted]="submitted"
        labelText="Total amount paid"
        nameOfFormControl="amountPaid"
        appMoneyMask="WITHOUT_DECIMAL"
        placeholder="$"
      >
      </app-form-field-text>
      <app-form-field-text
        inputId="losses-{{ i }}-amountReserved"
        [form]="lossPolicyPeriod"
        [submitted]="submitted"
        labelText="Total amount reserved"
        nameOfFormControl="amountReserved"
        appMoneyMask="WITHOUT_DECIMAL"
        placeholder="$"
      >
      </app-form-field-text>
    </div>
  </div>
</div>
