import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { getControl } from 'app/shared/helpers/form-helpers';

export type DialogBoxType = 'primary' | 'warning' | 'danger' | 'success' | 'error' | 'info';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
})
export class DialogBoxComponent {
  @Input() header: string;
  @Input() type: DialogBoxType;
  @Input() fullWidth = false;
  @Input() dismissable = false;
  @Input() form: UntypedFormGroup;
  @Input() nameOfFormControl: string;
  @Input() inputId: string;

  @Output() dismissDialog: EventEmitter<void> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @HostBinding('class') @Input('class') classList = '';
  dismissed = false;

  getClassName() {
    let modifier = 'dialog-text__primary';
    if (this.type === 'warning') {
      modifier = 'dialog-text__warning';
    } else if (this.type === 'danger' || this.type === 'error') {
      modifier = 'dialog-text__danger';
    } else if (this.type === 'success') {
      modifier = 'dialog-text__success';
    } else if (this.type === 'info') {
      modifier = 'dialog-text__neutral';
    }
    const fullWidth = this.fullWidth ? 'dialog-text__full-width' : '';
    return `dialog-text ${fullWidth} ${modifier} ${this.classList}`;
  }

  dismiss() {
    this.dismissDialog.emit();
    this.dismissed = true;
  }

  showDialogBox() {
    // If the dialog box is given both a form and form control name, check that the control is enabled
    let controlEnabled = true;
    if (this.form && this.nameOfFormControl) {
      const control = getControl(this.form, this.nameOfFormControl);
      controlEnabled = !!control && control.enabled;
    }

    return !this.dismissed && controlEnabled;
  }
}
