import { Component, OnInit } from '@angular/core';

import { ReferralsService, ReferralUser, Status } from 'app/shared/services/referrals.service';
import { UserService } from 'app/core/services/user.service';
import { environment } from 'environments/environment';

const INITIAL_LIMIT = 3;
const PER_PAGE = 20;
const STATUS_MAP: Record<Status, string> = {
  'not-invited': 'Send invite',
  invited: 'Invite sent',
  complete: 'Complete',
};

@Component({
  selector: 'app-activity-incentive-referrals',
  templateUrl: 'activity-incentive-referrals.component.html',
})
export class ActivityIncentiveReferralsComponent implements OnInit {
  constructor(private referralsService: ReferralsService, private userService: UserService) {}
  curDisplayLimit = INITIAL_LIMIT;
  hideShowMore = false;
  page = 0;
  referralUsers: Array<ReferralUser & { statusLabel: string }> = [];
  selectedUser?: ReferralUser = undefined;
  sendingReferral = false;
  showReferrals = false;

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      const estimateProducers = environment.intraReferralProducers as string[];
      if (estimateProducers.includes(user.producer) && this.referralUsers.length === 0) {
        this.loadReferralUsers();
      }
    });
  }

  closeModal() {
    this.sendingReferral = false;
    this.selectedUser = undefined;
  }

  // used by html to get referral users to render
  getReferralUsers() {
    return this.referralUsers.slice(0, this.curDisplayLimit);
  }

  loadReferralUsers() {
    const start = this.page * PER_PAGE;
    this.referralsService
      .getReferralUsers(start, PER_PAGE)
      .subscribe((referralUsers: ReferralUser[]) => {
        const additionalReferralUsers = referralUsers.map((referralUser) => ({
          ...referralUser,
          statusLabel: STATUS_MAP[referralUser.status],
        }));
        this.referralUsers = [...this.referralUsers, ...additionalReferralUsers];
        this.showReferrals = this.referralUsers.length > 0;
        if (this.curDisplayLimit > this.referralUsers.length) {
          this.hideShowMore = true;
        }
      });
  }

  sendReferral(user: ReferralUser) {
    this.sendingReferral = true;
    this.referralsService.sendReferral(user).subscribe(() => {
      const index = this.referralUsers.findIndex(
        (referralUser) => referralUser.email === user.email
      );
      const invitedStatus = 'invited';
      this.referralUsers[index].status = invitedStatus;
      this.referralUsers[index].statusLabel = STATUS_MAP[invitedStatus];
      this.closeModal();
    });
  }

  showMore() {
    if (this.curDisplayLimit === INITIAL_LIMIT) {
      this.curDisplayLimit = PER_PAGE;
    } else {
      this.curDisplayLimit += PER_PAGE;
      this.page += 1;
      this.loadReferralUsers();
    }
  }

  openModal(user: ReferralUser) {
    this.selectedUser = user;
  }
}
