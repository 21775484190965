export class BopPricedQuote {
  id: string;
  allCosts: QSQuoteSubmissionCostLineItem[];
  totalCost: string;
  totalPremium: string;
  totalTaxesAndFees: string;
  valid: boolean;
  reasons: string[];

  constructor(payload: {
    allCosts: QSQuoteSubmissionCostLineItem[];
    id: string;
    totalCost: string;
    totalPremium: string;
    totalTaxesAndFees: string;
    valid: boolean;
    reasons: string[];
  }) {
    this.id = payload.id;
    this.allCosts = payload.allCosts;
    this.totalCost = payload.totalCost;
    this.totalPremium = payload.totalPremium;
    this.totalTaxesAndFees = payload.totalTaxesAndFees;
    this.valid = payload.valid;
    this.reasons = payload.reasons;
  }

  premiumAsNumber(): number {
    return Number(this.totalPremium.split(' ')[0]);
  }

  costAsNumber(): number {
    return Number(this.totalCost.split(' ')[0]);
  }
}
