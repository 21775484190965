import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { API_V3_BASE } from 'app/constants';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { UserService } from '../../core/services/user.service';
import { User } from '../models/user';
import {
  RewardRequest,
  RewardResponse,
  BalanceResponse,
  EditEmailAddressRequest,
  RewardResponseList,
  RewardName,
  ActionName,
  OptInResponse,
  OptInRequest,
  RewardListItems,
  RewardsBalanceInfo,
  RewardsTransactions,
} from '../rewards/rewards-types';
import { RewardToast, TOAST_DISPLAYS } from '../rewards/rewards-toast-helpers';

import { SentryService } from 'app/core/services/sentry.service';

const GET_REWARDS_ACHIEVEMENTS_URI = (userId: string) =>
  `${API_V3_BASE}/user/${encodeURI(userId)}/rewards-achievements`;
const GET_REWARDS_BALANCE_URI = (userId: string) =>
  `${API_V3_BASE}/user/${encodeURI(userId)}/rewards-balance`;
const POST_REWARDS_TRANSACTION_URI = (userId: string) =>
  `${API_V3_BASE}/user/${encodeURI(userId)}/rewards-actions`;
const GET_REWARDS_TRANSACTION_URI = (userId: string) =>
  `${API_V3_BASE}/user/${encodeURI(userId)}/rewards-transactions`;
const POST_OPTIN_TRANSACTION_URI = (userId: string) =>
  `${API_V3_BASE}/user/${encodeURI(userId)}/rewards-optin`;

export type RewardActionProcessingState = 'processing' | 'error' | 'notProcessing' | 'success';

@Injectable()
export class RewardsService {
  private balanceInfo$: BehaviorSubject<RewardsBalanceInfo> = new BehaviorSubject({
    balance: 0,
    totalRedeemedThisYear: 0,
  });
  private newPoints$: BehaviorSubject<number> = new BehaviorSubject(0);
  private rewardsEligible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private rewardsEmail$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private rewards$: Subject<RewardToast> = new Subject();
  private rewardsList$: BehaviorSubject<RewardListItems[]> = new BehaviorSubject([]);
  private redemptionProcessingState$: BehaviorSubject<RewardActionProcessingState> =
    new BehaviorSubject('notProcessing');
  private userHasBeenFetched = false;
  private userEmail: string;
  checklistHasBeenFetched = false;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService
  ) {}

  getRewardsEligibility(): Observable<boolean> {
    return this.rewardsEligible$.asObservable();
  }

  getRewardsBalance(): Observable<RewardsBalanceInfo> {
    return this.balanceInfo$.asObservable();
  }

  getRewardsEmail(): Observable<string | null> {
    return this.rewardsEmail$.asObservable();
  }

  getRedemptionActionProcessingState(): Observable<RewardActionProcessingState> {
    return this.redemptionProcessingState$.asObservable();
  }

  getRewards() {
    return this.rewards$.asObservable();
  }

  getRewardsList() {
    return this.rewardsList$.asObservable();
  }

  getNewPoints() {
    return this.newPoints$.asObservable();
  }

  get userDetailsHaveBeenFetched() {
    return this.userHasBeenFetched;
  }

  getUserInfoForRewards(): Observable<BalanceResponse> {
    return this.userService.getUser().pipe(
      switchMap((user: User) => {
        this.userEmail = user.userName;
        return this.fetchBalanceAndEligibility(this.userEmail);
      }),
      tap((balanceEligibility: BalanceResponse) => {
        const { balance, eligibleForRewards, optedIn, rewardsEmail, totalRedeemedThisYear } =
          balanceEligibility;
        this.balanceInfo$.next({ balance, totalRedeemedThisYear });
        this.rewardsEligible$.next(eligibleForRewards);
        this.rewardsEmail$.next(rewardsEmail);
        this.userHasBeenFetched = true;
      }),
      catchError((_error) => {
        this.userHasBeenFetched = false;
        return observableOf({
          balance: 0,
          eligibleForRewards: false,
          optedIn: false,
          rewardsEmail: null,
          totalRedeemedThisYear: 0,
        });
      })
    );
  }

  getRewardsListForChecklist(): Observable<RewardListItems[]> {
    return this.userService.getUser().pipe(
      switchMap((user: User) => {
        this.userEmail = user.userName;
        this.checklistHasBeenFetched = true;
        return this.fetchRewardsList(this.userEmail);
      }),
      tap((rewardsList: RewardListItems[]) => {
        this.rewardsList$.next(rewardsList);
      }),
      catchError((_error) => {
        this.checklistHasBeenFetched = false;
        return observableOf([]);
      })
    );
  }

  fetchBalanceAndEligibility(userId: string): Observable<BalanceResponse> {
    const requestUri = GET_REWARDS_BALANCE_URI(userId);
    return this.http.get<BalanceResponse>(requestUri).pipe(
      catchError((error) => {
        this.sentryService.notify('Error fetching rewards balance and eligibility.', {
          severity: 'error',
          metaData: {
            userId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  fetchRewardsList(userId: string): Observable<RewardListItems[]> {
    const requestUri = GET_REWARDS_ACHIEVEMENTS_URI(userId);
    return this.http.get<RewardListItems[]>(requestUri).pipe(
      catchError((error) => {
        this.sentryService.notify('Error fetching rewards achievements list.', {
          severity: 'error',
          metaData: {
            userId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  fetchRewardsActivity(userId: string): Observable<RewardsTransactions> {
    const requestUri = GET_REWARDS_TRANSACTION_URI(userId);
    return this.http.get<any>(requestUri).pipe(
      catchError((error) => {
        this.sentryService.notify('Error fetching rewards transactions list.', {
          severity: 'error',
          metaData: {
            userId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  submitOptIn(optInRequest: OptInRequest) {
    this.amplitudeService.track({ eventName: 'rewards_opt_in_attempt', detail: '' });

    this.postRewardOptIn(this.userEmail, optInRequest)
      .pipe(catchError(() => observableOf({ optedIn: false, rewardsEmail: null })))
      .subscribe((optInResponse) => {
        this.rewardsEmail$.next(optInResponse.rewardsEmail);
        this.amplitudeService.track({ eventName: 'rewards_opt_in', detail: '' });
      });
  }

  postRewardOptIn(userId: string, payload: OptInRequest): Observable<OptInResponse> {
    const requestURI = POST_OPTIN_TRANSACTION_URI(userId);
    return this.http.post<OptInResponse>(requestURI, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Error opting user into rewards.', {
          severity: 'error',
          metaData: {
            userId,
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  editEmailAddress(payload: EditEmailAddressRequest): Observable<{ message: string }> {
    const requestURI = POST_OPTIN_TRANSACTION_URI(this.userEmail);
    return this.http.put<{ message: string }>(requestURI, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Error updating user reward email address', {
          severity: 'error',
          metaData: {
            userId: this.userEmail,
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  submitRewardAction(rewardRequest: RewardRequest) {
    // If user details are not present, get user info before processing reward action
    if (!this.userHasBeenFetched) {
      this.getUserInfoForRewards().subscribe(() => {
        this.processRewardAction(rewardRequest);
      });
    } else {
      this.processRewardAction(rewardRequest);
    }
  }

  processRewardAction(rewardRequest: RewardRequest) {
    if (!this.rewardsEligible$.value) {
      return;
    }
    if (rewardRequest.actionName === ActionName.GIFT_CARD_REDEMPTION) {
      this.redemptionProcessingState$.next('processing');
    }

    // Log the reward action attempt
    this.amplitudeService.track({
      eventName: 'rewards_earn_attempt',
      detail: rewardRequest.actionName,
    });

    rewardRequest.username = this.userEmail;

    this.postRewardAction(this.userEmail, rewardRequest)
      .pipe(
        catchError((error) => {
          if (rewardRequest.actionName === ActionName.GIFT_CARD_REDEMPTION) {
            this.redemptionProcessingState$.next('error');
          }
          throw error;
        })
      )
      .subscribe((rewards: RewardResponseList) => {
        const { transactions } = rewards;
        // Only create notifications for eligible users
        if (this.rewardsEligible$.value) {
          transactions.forEach((reward: RewardResponse) => {
            const rewardToast = this.createToast(reward);

            if (rewardToast) {
              this.rewards$.next(rewardToast);
              this.amplitudeService.trackWithOverride({
                eventName: 'rewards_earn',
                detail: reward.name,
                payloadOverride: { rewardsPoints: String(reward.points) },
              });
            }
          });
          if (rewardRequest.actionName === ActionName.GIFT_CARD_REDEMPTION) {
            this.redemptionProcessingState$.next('success');
            this.updateBalance(0);
          }
        }
      });
  }

  doneProcessingRedemption() {
    this.redemptionProcessingState$.next('notProcessing');
  }

  isRewardName(name: ActionName | RewardName): name is RewardName {
    if (Object.keys(RewardName).includes(name)) {
      return true;
    }
    return false;
  }

  postRewardAction(userId: string, rewardRequest: RewardRequest): Observable<RewardResponseList> {
    const requestURI = POST_REWARDS_TRANSACTION_URI(userId);
    return this.http.post<RewardResponseList>(requestURI, rewardRequest).pipe(
      catchError((error) => {
        this.sentryService.notify('Error completing reward action/transaction.', {
          severity: 'error',
          metaData: {
            userId,
            rewardRequest,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  createToast(rewardResponse: RewardResponse): RewardToast | null {
    // If there isn't a corresponding display toast for the rewards
    // response, then do not create a toast
    if (!Object.prototype.hasOwnProperty.call(TOAST_DISPLAYS, rewardResponse.name)) {
      this.sentryService.notify('Received an unsupported reward name in reward response', {
        severity: 'info',
        metaData: {
          rewardResponseName: rewardResponse.name,
        },
      });

      return null;
    }

    if (!rewardResponse.points) {
      this.sentryService.notify('Received a reward with no points in reward response', {
        severity: 'info',
        metaData: {
          rewardResponseName: rewardResponse.name,
        },
      });

      return null;
    }

    return new RewardToast(rewardResponse.name, rewardResponse.points);
  }

  updateBalance(rewardPoints: number) {
    // Publish the value of the new points to trigger the coin animation
    this.newPoints$.next(rewardPoints);
    // Fetch and publish the new reward point balance
    this.fetchBalanceAndEligibility(this.userEmail).subscribe(
      ({ balance, totalRedeemedThisYear }) => {
        this.balanceInfo$.next({ balance, totalRedeemedThisYear });
      }
    );
  }

  // For testing buttons only, using hard coded values
  submitRewardActionForTesting({ actionName }: RewardRequest) {
    if (this.isRewardName(actionName)) {
      const rewardToast = this.createToast({ name: actionName, points: 10 });
      this.rewards$.next(<RewardToast>rewardToast);
      if (actionName === ActionName.QUOTE_FOR_ACCOUNT) {
        const achievementToast = this.createToast({
          name: RewardName.ONE_ATTUNE_BOP_QUOTES_ACHIEVEMENT,
          points: 50,
        });
        this.rewards$.next(<RewardToast>achievementToast);
        const categoryAchievementToast = this.createToast({
          name: RewardName.BOP_QUOTE_OFFICE_ACHIEVEMENT,
          points: 10,
        });
        this.rewards$.next(<RewardToast>categoryAchievementToast);
      }
    } else {
      const giftCardToast = this.createToast({ name: RewardName.GIFT_CARD_250, points: -250 });
      this.rewards$.next(<RewardToast>giftCardToast);
    }
  }
}
