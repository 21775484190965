<div class="schedule-invoices-container js-scheduled-invoices-value">
  <div class="schedule-invoices-logo"><app-logo-with-text></app-logo-with-text></div>
  <h1><strong>Schedule of Invoices</strong></h1>

  <p>
    Below is your schedule of invoices as of {{ currentDate }}. It includes all of your fixed
    installments, endorsements, renewals, and miscellaneous charges.
  </p>

  <p *ngIf="accountName && linesOfBiz" class="js-account-details-section">
    <strong>Account Name:</strong> {{ accountName }}
    <br />
    <strong>Account Number:</strong> {{ accountNumber }}
    <br />
    <strong>Broker:</strong> {{ agentName }}
    <br />
    <strong *ngIf="policyStart" class="js-scheduled-policy-period">Policy Period: </strong>
    {{ policyStart | date: 'MM/dd/yyyy' }} -- {{ policyEnd | date: 'MM/dd/yyyy' }}
    <br />
    <strong>Lines of Business: </strong> {{ linesOfBiz }}
    <br />
    <strong>Autopay Status:</strong> {{ autopayStatus }}
    <br />
    <strong>Current Date:</strong> {{ currentDate }}
    <br />
    <strong>Current Time:</strong> {{ currentTime }}
    <br />
  </p>

  <h3 class="schedule-table-header"><strong>Invoice List</strong></h3>
  <table>
    <tr>
      <th><strong>Invoice Number</strong></th>
      <th><strong>Bill Date</strong></th>
      <th><strong>Due Date</strong></th>
      <th><strong>Status</strong></th>
      <th class="align-right"><strong>Amount</strong></th>
    </tr>
    <tr *ngFor="let item of invoicesArray">
      <td class="js-invoice-number-cell">
        {{ item.number }}
      </td>
      <td class="js-invoice-bill-date-cell">
        {{ item.billDate | date: 'MM/dd/yyyy' }}
      </td>
      <td class="js-invoice-due-date-cell">
        {{ item.dueDate | date: 'MM/dd/yyyy' }}
      </td>
      <td class="js-invoice-status-cell">
        {{ item.status }}
      </td>
      <td class="align-right js-invoice-amount-cell">
        {{ item.amount | currency: '$':'symbol':'1.2-2' }}
      </td>
    </tr>
  </table>
</div>
