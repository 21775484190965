<app-modal
  [open]="open && !!secondaryQuote"
  (handleModalClose)="closeModal.emit()"
  [modalNameForAmplitudeEvent]="modalNameForAmplitudeEvent()"
>
  <div class="modal-image" role="presentation">
    <img src="/assets/img/documents.png" alt="" />
  </div>
  <h2>View your quote letters</h2>
  <p class="p__gray">Here are all the documents for the coverage you quoted:</p>
  <div class="document-download-container">
    <app-document-download
      [displayName]="primaryProductName + ' Quote Letter'"
      [documentDownload$]="primaryDocumentDownload$"
      [documentReady$]="primaryDocumentDownloadReady$"
    ></app-document-download>
    <app-document-download
      [displayName]="secondaryProductName + ' Quote Letter'"
      [documentDownload$]="secondaryDocumentDownload$"
      [documentReady$]="secondaryDocumentDownloadReady$"
    ></app-document-download>
  </div>
  <dl class="dl__no-space-between">
    <dt><strong>Total quoted premium</strong></dt>
    <dd>
      <strong *ngIf="totalQuotedPremium">{{ totalQuotedPremium | currency }}</strong>
    </dd>
  </dl>
  <dl class="dl__no-space-between">
    <dt>
      <small>{{ primaryProductName }} premium</small>
    </dt>
    <dd>
      <small>{{ primaryProductPremium | currency }}</small>
    </dd>
  </dl>
  <dl *ngIf="secondaryProductPremium" class="dl__no-space-between">
    <dt>
      <small>{{ (secondaryProductName || 'Secondary') + ' premium' }}</small>
    </dt>
    <dd>
      <small>{{ secondaryProductPremium | currency }}</small>
    </dd>
  </dl>
  <aside class="mt-2 js-cyber-marketing-footer" *ngIf="includesCyberQuote">
    <ul>
      <li>
        <img class="badge-list-img" src="/assets/img/shield.svg" alt="" />
        <p class="badge-list-text">
          Did you know that Coalition Cyber Insurance provides your client with free 24/7 security
          monitoring and alerts ($10,000 value)?
          <a
            [href]="COALITION_TECHNOLOGY_AND_SERVICES"
            target="_blank"
            data-amplitude-input-name="learn_more_about_monitoring"
            >Learn more</a
          >
        </p>
      </li>
    </ul>
  </aside>
  <div class="nav-button-group">
    <button
      class="button button__primary button__no-margin"
      type="button"
      (click)="closeModal.emit()"
    >
      Got it
    </button>
  </div>
</app-modal>
