import * as moment from 'moment';

import { HiscoxSharedDataField } from './hiscox-constants';
import { HiscoxPlFormDataFieldV4 } from './pl-constants';
import { HiscoxFormStepPath } from './hiscox-types';

export const HISCOX_PL_HAPPY_PATH_FORM_DATA_V4 = {
  [HiscoxFormStepPath.QUOTE_BASIC]: {
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_COMMERCIALNAME]: 'Business Consulting For You',
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERAGESTARTDATE]:
      moment().add(1, 'day').format('MM/DD/YYYY'),
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_CLASSOFBUSINESSCD]: '54161100_13111100_01000000.541611',
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_BUSINESSOWNERSHIPSTRUCTURE]: 'Limited Liability Company',
    [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1]: '101 Dalmeny Road',
    [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2]: '',
    [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY]: 'Briarcliff Manor',
    [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD]: 'NY',
    [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE]: '10510',
    [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY]: 'Westchester',
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_FIRSTNAME]: 'Jane',
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_PERSON_NAME_LASTNAME]: 'Doe',
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR]:
      'test@gmail.com',
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER]:
      '609-999-9999',
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_HAVEDBAORFKA]: '',
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_DBAORFKANAME]: '',
  },
  [HiscoxFormStepPath.QUOTE_RATING]: {
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYPL]:
      {
        AerospaceConsultingOrAdvice: false,
        ActuarialAdvice: false,
        ArchitectureOrEngineeringAdvice: false,
        ConstructionManagementOrAdvice: false,
        CreditCounseling: false,
        EnvironmentalConsultingOrAdvice: false,
        FinancingOrFinancialAuditing: false,
        GeneralContracting: false,
        InvestmentOrTaxAdvice: false,
        InsurancePlacementOrAdvice: false,
        LandAcquisition: false,
        LawEnforcementTraining: false,
        LegalAdvice: false,
        LobbyingAndOrPoliticalAdvice: false,
        MedicalAdvice: false,
        MergersAndAcquisitionsOrBusinessValuations: false,
        MiningConsultingOrAdvice: false,
        OilGasOrPetroleumConsultingOrAdvice: false,
        SafetyConsultingOrAdvice: false,
        SalesRepresentative: false,
        NoneOfTheAbove: true,
      },
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ]:
      'Do not include',
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE]: moment()
      .add(-1, 'year')
      .format('MM/DD/YYYY'),
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SIMILARINSURANCE]:
      'PreferNotToAnswer',
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE]: 123000,
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR1]: 23000,
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_FORMALCHANGEMGMT]:
      'Yes',
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI]: 2000000,
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE]: 2500,
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID]:
      'Do not include',
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EOSTATEMENT1]:
      'Agree',
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_PRODUCTACKNOWLEDGEMENTS_EOSTATEMENT3]:
      'Agree',
    [HiscoxPlFormDataFieldV4.ACKNOWLEDGEMENTS_AGREEDISAGREESTATEMENTS]: 'Agree',
    [HiscoxPlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT]: 'Agree',
    [HiscoxPlFormDataFieldV4.ACKNOWLEDGEMENTS_EMAILCONSENT2]: 'Agree',
  },
};
