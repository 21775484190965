import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-invoices-terms-and-conditions-page.app-page.app-page__invoice',
  templateUrl: './invoices-terms-and-conditions-page.component.html',
})
export class InvoicesTermsAndConditionsPageComponent {
  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
