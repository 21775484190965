import { ElementRef, NgZone } from '@angular/core';
import { random, randomInt } from '../helpers/number-format-helpers';

/**
 * A mixin to render confetti.
 *
 * This mixin assumes that for any derived class:
 * - You define a view child for the `canvasRef` property:
 * ```
 * @ViewChild('canvas') canvasRef: ElementRef;
 * ```
 *
 * - You have a <canvas> element in the template you're working with.
 */

interface Confetti {
  x: number;
  y: number;
  width: number;
  height: number;
  speed: number;
  rotation: number;
  color: string;
  seed: number; // For jiggling the confetti
  translateX: number;
  translateY: number;
  rotate: number;
  scale: number;
  opacity: number;
}

// Confetti properties
const COLORS = [
  '#4579FF',
  '#29EAFC',
  '#E68D61',
  '#C37DE1',
  '#50E3C2',
  '#FFFC9D',
  '#FF7D7D',
  '#FFFFFF',
];

export const ConfettiMixin = <T extends Constructor>(BaseClass: T) => {
  return class extends BaseClass {
    public canvasRef: ElementRef;
    public confetti: Confetti[] = [];
    public running = false;
    public context: any;
    public counter = 0;
    public recreateConfetti = true;
    public ngZone: NgZone;

    public canvasDimensions() {
      return {
        height:
          Math.max((<HTMLElement>document.documentElement).clientHeight, window.innerHeight || 0) *
          window.devicePixelRatio,
        width:
          Math.max((<HTMLElement>document.documentElement).clientWidth, window.innerWidth || 0) *
          window.devicePixelRatio,
      };
    }

    // Generate more confetti if device pixel ratio is higher
    public confettiCount() {
      return 100 * window.devicePixelRatio;
    }

    // Create the confetti pieces with optional initial placement above the window
    public generateConfetti(count: number, aboveWindow?: boolean) {
      const confetti: Confetti[] = [];
      const { width: canvasWidth, height: canvasHeight } = this.canvasDimensions();

      for (let i = 0; i < count; i++) {
        const x: number = randomInt(0, canvasWidth);
        const y: number = aboveWindow ? -100 : randomInt(0, canvasHeight);
        const speed = 2.8;
        const scale: number = random(0.9, 1.1);
        const height: number = Math.floor(((80 * scale) / speed) * scale);
        const width: number = Math.floor(((40 * scale) / speed) * scale);
        const rotation: number = randomInt(0, 360);

        confetti.push({
          color: COLORS[randomInt(0, COLORS.length)],
          height,
          opacity: 0,
          rotate: 0,
          rotation,
          scale: 0,
          seed: random(0, 10),
          speed,
          translateX: 0,
          translateY: 0,
          width,
          x,
          y,
        });
      }
      return confetti;
    }

    public tick(
      { x, y, width, height, speed, rotation, color, seed, opacity }: Confetti,
      canvasWidth: number,
      canvasHeight: number,
      recreateConfetti: boolean
    ): Confetti {
      const newX: number = x > canvasWidth ? 0 : x + Math.sin(y / 50 + seed) * 2;

      // If a confetti has fallen off the window, make a new confetti
      let newY: number = y + speed;
      if (y >= canvasHeight && recreateConfetti) {
        newY = -20;
      }

      return {
        color,
        height,
        opacity: opacity < 1 ? opacity + 0.03 : 1,
        rotate: (rotation * speed * 1.5 * Math.PI) / 180,
        rotation: rotation + 1,
        scale: Math.cos(newY / 10),
        seed,
        speed,
        translateX: newX + width / 2,
        translateY: newY + height / 2,
        width,
        x: newX,
        y: newY,
      };
    }

    public init() {
      this.ngZone = new NgZone({
        enableLongStackTrace: false,
      });
      this.scaleCanvas();
      this.confetti = this.generateConfetti(this.confettiCount());
      this.context = this.canvasRef.nativeElement.getContext('2d');
    }

    public destroy() {
      this.running = false;
    }

    public clearCanvas() {
      const { width, height } = this.canvasDimensions();

      if (this.context) {
        this.context.clearRect(0, 0, width, height);
      }
    }

    private scaleCanvas() {
      const canvas = this.canvasRef.nativeElement;
      const { width, height } = this.canvasDimensions();
      if (canvas.width !== width) {
        canvas.width = width;
        canvas.style.width = width / window.devicePixelRatio + 'px';
      }
      if (canvas.height !== height) {
        canvas.height = height;
        canvas.style.height = height / window.devicePixelRatio + 'px';
      }
    }

    public animate() {
      if (!this.running) {
        return;
      }

      const context = this.context;
      const { width, height } = this.canvasDimensions();

      const postTickConfetti: Confetti[] = [];
      for (let i = 0; i < this.confetti.length; i++) {
        postTickConfetti.push(this.tick(this.confetti[i], width, height, this.recreateConfetti));
      }
      this.confetti = postTickConfetti;

      if (this.counter % 15 === 0 && this.recreateConfetti) {
        this.confetti.push(...this.generateConfetti(10));
        // push new confetti
      }

      // Remove what was on the canvas before.
      this.clearCanvas();

      // Transform the confetti pieces
      for (let i = this.confetti.length - 1; i >= 0; i--) {
        const {
          width: confettiWidth,
          height: confettiHeight,
          color,
          translateX,
          translateY,
          rotate,
          scale,
          opacity,
        } = this.confetti[i];
        context.fillStyle = color;
        context.globalAlpha = opacity;
        context.translate(translateX, translateY);
        context.rotate(rotate);
        context.scale(scale, 1);
        context.fillRect(-confettiWidth / 2, -confettiHeight / 2, confettiWidth, confettiHeight);
        context.setTransform(1, 0, 0, 1, 0, 0);
      }

      this.counter++;

      this.ngZone.runOutsideAngular(() => {
        requestAnimationFrame(() => this.animate());
      });
    }
  };
};
