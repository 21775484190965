import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DCP_COMMISSION_PLANS } from './constants';
import { Subscription } from 'rxjs';

import { ProducerDetailsResponse } from 'app/bop/guidewire/typings';
import { ProductAvailability } from 'app/features/digital-carrier/models/types';
import { BillingApiService } from '../insured-account/billing-api.service';
import { CommissionPlanFrontendDetails, CommissionPlansResponse } from './types';
import { NaicsService } from 'app/shared/services/naics.service';
import { SentryService } from '../../core/services/sentry.service';

@Component({
  selector: 'app-commission-plan-information-modal',
  templateUrl: './commission-plan-information-modal.component.html',
})
export class CommissionPlanInformationModalComponent implements OnChanges {
  @Input() open = false;
  @Input() producerDetails: ProducerDetailsResponse;
  @Output() closeModal: EventEmitter<null> = new EventEmitter();

  dcpCommissionPlans = DCP_COMMISSION_PLANS;
  availableCommissionPlans: { [key: string]: CommissionPlanFrontendDetails } = {};

  private sub: Subscription = new Subscription();

  constructor(
    private billingApiService: BillingApiService,
    private naicsService: NaicsService,
    private sentryService: SentryService
  ) {}

  asIsOrder(a: CommissionPlanFrontendDetails, b: CommissionPlanFrontendDetails) {
    return 1;
  }

  sortPlanDataInOrder() {
    const sortOrder = [
      'coalition',
      'attune_bop_cat',
      'attune_bop_non_cat',
      'attune_xs',
      'employers',
      'hiscox',
      'neptune',
      'default',
    ];

    const sortedData: CommissionPlansResponse = {};
    Object.entries(this.availableCommissionPlans)
      .sort(([keyA], [keyB]) => {
        const indexA = sortOrder.indexOf(keyA);
        const indexB = sortOrder.indexOf(keyB);

        if (indexA === -1) {
          return 1;
        } // Move keys not in sortOrder to the end
        if (indexB === -1) {
          return -1;
        } // Move keys not in sortOrder to the end

        return indexA - indexB; // Sort based on the sortOrder
      })
      .forEach(([key, value]) => {
        sortedData[key] = value;
      });
    this.availableCommissionPlans = sortedData;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.producerDetails &&
      changes.producerDetails.currentValue &&
      changes.producerDetails.currentValue.ProducerCode
    ) {
      this.sub.add(
        this.billingApiService
          .getCommissionPlanRates(changes.producerDetails.currentValue.ProducerCode)
          .subscribe((response) => {
            this.availableCommissionPlans = {
              ...response,
              ...this.availableCommissionPlans,
            };
            this.sortPlanDataInOrder();
          })
      );

      this.sub.add(
        this.naicsService.getProductAvailability().subscribe({
          next: (productAvailabilities: ProductAvailability[]) => {
            const matchingObjects = productAvailabilities.filter(
              (availProduct: ProductAvailability) => {
                if (availProduct.classCodeSelection !== 'NONE') {
                  const planItem = this.dcpCommissionPlans[availProduct.pasSource];
                  if (planItem) {
                    return {
                      ...planItem,
                    };
                  }
                }
              }
            );

            matchingObjects.forEach((plan: ProductAvailability) => {
              if (!this.availableCommissionPlans[plan.pasSource]) {
                this.availableCommissionPlans[plan.pasSource] = {
                  ...this.dcpCommissionPlans[plan.pasSource],
                };
              }
            });

            this.availableCommissionPlans = {
              ...this.availableCommissionPlans,
            };
            this.sortPlanDataInOrder();
          },
          error: (err) => {
            this.sentryService.notify(
              'Commission Plan Info: product availability not found. Cannot determine which products broker has access to.',
              {
                severity: 'error',
                metaData: {
                  underlyingErrorMessage: err && err.message,
                  underlyingError: err,
                },
              }
            );
          },
        })
      );
    }
  }
}
