<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div class="{{ cssClass }} form-field" [ngClass]="{ 'form-field__error': fieldInvalid() }">
    <label [for]="inputId" [attr.data-specifier]="specifierText" [innerHTML]="labelText">
      <div
        *ngIf="tooltipText"
        class="more-info-tip tool-tip tool-tip__large"
        attr.data-tooltip="{{ tooltipText }}"
      >
        <span class="icon icon-question"></span>
      </div>
    </label>
    <a
      (click)="fileInput.click()"
      (keyup.enter)="fileInput.click()"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="dragHandler(false)"
      (dragenter)="dragHandler(true)"
      [ngClass]="{
        'file-field-drop-zone__dropping': droppingClass,
        'file-field-drop-zone__error': fieldInvalid()
      }"
      class="file-field-drop-zone"
      id="{{ inputId }}-drop-zone"
    >
      <svg
        class="file-field-cloud"
        width="54"
        height="39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.425 37.873h-5.5313c-1.8303-.0017-3.6393-.3928-5.30688-1.1472-1.66761-.7543-3.15569-1.8548-4.36548-3.2282a12.906758 12.906758 0 01-2.65148-4.7381 12.90606 12.90606 0 01-.46822-5.4092c.22924-1.8159.84209-3.5623 1.79788-5.1232.95578-1.5609 2.23264-2.9007 3.74581-3.9304 1.51318-1.0297 3.22806-1.7258 5.03087-2.042 1.8027-.3163 3.6521-.2455 5.4255.2077"
          stroke="#BFC9CD"
          stroke-width="1.84375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.7373 19.4357c.0003-2.9213.6948-5.8008 2.0262-8.4011 1.3314-2.60032 3.2616-4.84704 5.6316-6.55503 2.37-1.708 5.112-2.82837 7.9999-3.26882 2.888-.440436 5.8393-.18833 8.6107.73554 2.7714.92388 5.2836 2.49308 7.3296 4.5783 2.046 2.08522 3.5672 4.62681 4.4383 7.41521.8711 2.7885 1.0671 5.744.5719 8.623-.4952 2.8791-1.6675 5.5993-3.4201 7.9365"
          stroke="#BFC9CD"
          stroke-width="1.84375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.5088 27.2581l7.8223-7.8226 7.8224 7.8226M32.3311 37.873V19.4355"
          stroke="#BFC9CD"
          stroke-width="1.84375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <!-- <svg width="51" height="40" viewBox="0 0 51 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="file-field-cloud-path" fill-rule="evenodd" clip-rule="evenodd" d="M39.2105 35.7895H33.6842C32.52 35.7895 31.5768 34.8484 31.5768 33.6842C31.5768 32.5221 32.52 31.5789 33.6842 31.5789H39.2105C42.7411 31.5789 46.3158 28.8168 46.3158 23.5411C46.3158 17.9453 41.7179 13.3958 36.0695 13.3958C35.8842 13.3958 35.6989 13.4084 35.5158 13.4295C34.541 13.5432 33.6253 12.96 33.2989 12.0379C31.6442 7.35579 27.1726 4.21053 22.1747 4.21053C15.6758 4.21053 10.3874 9.44842 10.3874 15.8863C10.3874 16.4232 10.4337 16.9663 10.5263 17.5537C10.6947 18.5747 10.0905 19.5684 9.10526 19.8905C6.17895 20.8505 4.21053 23.5495 4.21053 26.6042C4.21053 31.2189 9.04421 31.5789 10.5263 31.5789H16.8421C18.0042 31.5789 18.9453 32.5221 18.9453 33.6842C18.9453 34.8484 18.0042 35.7895 16.8421 35.7895H10.5263C4.22947 35.7895 0 32.0968 0 26.6042C0 22.3053 2.42947 18.4547 6.19158 16.5516C6.18105 16.3305 6.17684 16.1074 6.17684 15.8863C6.17684 7.12632 13.3537 0 22.1747 0C28.4295 0 34.0779 3.63789 36.68 9.1979C44.3705 9.51579 50.5263 15.8295 50.5263 23.5411C50.5263 30.5242 45.6632 35.7895 39.2105 35.7895ZM17.4589 23.7747L23.7726 17.4611C23.9663 17.2653 24.2 17.1116 24.4589 17.0042C24.7158 16.8989 24.9874 16.8442 25.2589 16.8421C25.2611 16.8421 25.2611 16.8421 25.2632 16.8421C25.5368 16.8421 25.8105 16.8989 26.0674 17.0042C26.3263 17.1116 26.56 17.2653 26.7537 17.4611L33.0674 23.7747C33.8905 24.5979 33.8905 25.9284 33.0674 26.7516C32.6568 27.1621 32.1179 27.3684 31.5789 27.3684C31.04 27.3684 30.5011 27.1621 30.0905 26.7516L27.3684 24.0295V37.8947C27.3684 39.0589 26.4253 40 25.2632 40C24.1011 40 23.1579 39.0589 23.1579 37.8947V24.0295L20.4358 26.7516C19.6126 27.5747 18.2821 27.5747 17.4589 26.7516C16.6358 25.9284 16.6358 24.5979 17.4589 23.7747Z" fill="#BFC9CD"/>
      </svg> -->
      <span class="file-field-drop-zone-content"
        >Drag and drop files or <span class="file-field-fake-link">click</span> to browse</span
      >
    </a>
    <input
      #fileInput
      type="file"
      [id]="inputId"
      [name]="inputId"
      [accept]="accept"
      (change)="onFilesSelected($event)"
      (click)="fileInput.value = null"
    />
    <ul class="file-field-upload-list" *ngIf="getControl(form, nameOfFormControl).value">
      <li class="file-field-upload" *ngFor="let file of getFiles(); index as fileIndex">
        <svg
          class="file-field-paper-clip"
          width="28"
          height="30"
          viewBox="0 0 28 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="file-field-paper-clip-path"
            fill="#002737"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.70069 26.9995C3.44421 26.9995 2.28681 26.5249 1.41458 25.6448C0.432639 24.6559 -0.0548611 23.4791 0.00439812 22.242C0.067824 20.9291 0.724306 19.6346 1.95579 18.3934L16.2887 3.94714C17.535 2.69112 19.1919 1.99899 20.9544 1.99899C22.7169 1.99899 24.3738 2.69112 25.6201 3.94714C28.1928 6.5402 28.1928 10.7596 25.6201 13.3532L13.1225 25.9499C12.9428 26.1305 12.6521 26.1305 12.4725 25.9499C12.2933 25.7689 12.2933 25.4754 12.4725 25.2953L24.9706 12.6985C26.0433 11.617 26.6345 10.1791 26.6345 8.64992C26.6345 7.12075 26.0433 5.68371 24.9706 4.60223C22.7563 2.36983 19.153 2.36983 16.9382 4.60223L2.60532 19.0485C1.55532 20.1069 0.97338 21.2263 0.922454 22.287C0.875694 23.2555 1.2706 24.1907 2.06412 24.9902C2.81875 25.7513 3.84421 26.1314 4.95486 26.0652C6.10486 25.9944 7.2456 25.4439 8.16736 24.5152L19.6854 12.8981C20.0715 12.5092 20.284 11.9925 20.284 11.442C20.284 10.892 20.0715 10.3749 19.6859 9.98649C18.8896 9.18325 17.5938 9.18371 16.7975 9.98649L7.21273 19.6457C7.03356 19.8272 6.74282 19.8272 6.56319 19.6457C6.38356 19.4652 6.38356 19.1717 6.56319 18.9911L16.1479 9.3314C17.3021 8.16751 19.1808 8.16751 20.3354 9.3314C20.8947 9.89529 21.203 10.6444 21.203 11.442C21.203 12.2397 20.8947 12.9893 20.3354 13.5532L8.81736 25.1694C7.73634 26.2592 6.38449 26.905 5.01088 26.9893C4.90718 26.9957 4.80347 26.9995 4.70069 26.9995Z"
          />
        </svg>
        <div class="file-field-upload-meta">
          <p class="p__no-margin">
            {{ file.name }}
            <app-inline-loading-status [status]="file.status"></app-inline-loading-status>
          </p>
          <small class="gray-text">{{ file.message || file.size || '' }}</small>
        </div>
        <div class="file-field-upload-actions">
          <a
            (click)="onFileRemoved(fileIndex)"
            (keyup.enter)="onFileRemoved(fileIndex)"
            data_amplitude_input_name="file_field_remove_download"
            class="button button__tertiary button__small button__file-field-upload-action"
            >Remove</a
          >
        </div>
      </li>
    </ul>
  </div>
</ng-container>
