import { InsuranceProductCode } from 'app/features/digital-carrier/models/types';
import {
  API_V3_BASE,
  API_V4_BASE,
  GET_COMMISSION_DOC,
  GET_FORM_PDF,
  GET_POLICY_DOC_PDF,
  GET_QUOTE_LETTER_PDF,
  GET_LOSS_RUNS_PDF,
} from '../../../constants';
import { CoalitionDocumentType } from '../../coalition/models/cyber-typings.model';
import { DOCUMENT_TYPES } from '../services/document.service';

export const getDcpQuoteLetterUrl = (id: string) => `${API_V4_BASE}/quotes/${id}/quote-documents`;
export const getAttuneQuoteLetterUrl = (id: string) => `${GET_QUOTE_LETTER_PDF}${id}/pdf`;
export const getAttuneBopPolicyUrl = (id: string) => `${GET_POLICY_DOC_PDF}${id}/pdf`;
export const getAttuneBopNonRenewNoticeUrl = (policyNumber: string, termNumber: string) =>
  `${API_V3_BASE}/documents/nonrenew/${policyNumber}/${termNumber}`;
export const getCoalitionCyberDocumentUrl = (
  resourceType: 'quote' | 'policy',
  resourceUuid: string,
  documentType: CoalitionDocumentType
) => `${API_V4_BASE}/coalition-cyber-documents/${resourceType}/${resourceUuid}/${documentType}`;
export const getCoalitionCyberRiskAssessmentUrl = (id: string, companyName: string) =>
  `${API_V3_BASE}/documents/quotes/${id}/cyber-risk-profile?accountName=${companyName}`;
export const getAttuneQuoteSubmissionUrl = (
  quoteId: string,
  insuredAccountId: string,
  product: InsuranceProductCode
) =>
  `${API_V3_BASE}/documents/quote-submission/${insuredAccountId}/${quoteId}/pdf?product=${product}`;
export const getEmployersWCPolicyDocumentUrl = (quoteId: string, documentId: string) =>
  `${API_V3_BASE}/quotes/workers-comp/${quoteId}/policy-documents/${documentId}`;
export const getEmployersWCRenewalPolicyDocumentUrl = (policyId: string, documentId: string) =>
  `${API_V3_BASE}/quotes/workers-comp/${policyId}/renewal-policy-documents/${documentId}`;
export const getAttuneBopScheduleOfInvoicesUrl = (id: string, token: string, inForce: boolean) =>
  `${API_V3_BASE}/documents/invoices/${id}/schedule?token=${token}&inForce=${inForce}`;
export const getAttuneBopInvoiceReceiptUrl = (id: string, token: string) =>
  `${API_V3_BASE}/documents/invoices/${id}/pdf?token=${token}`;
export const getAttuneCommissionStatementUrl = (id: string) => `${GET_COMMISSION_DOC}${id}`;
export const getPartnerCommissionStatementUrl = (
  date: string,
  documentType: DOCUMENT_TYPES,
  producerCode?: string
) =>
  documentType === 'pdf'
    ? `${API_V3_BASE}/documents/commission-statement/${date}`
    : `${API_V4_BASE}/producer-code/${producerCode}/commissions/statements/${date}?format=xlsx`;
export const getFormUrl = (id: string, version: string) =>
  `${GET_FORM_PDF}formNumber=${id}&formEdition=${version}`;

export const getLossRunsDocumentsUrl = (policyNumber: string) =>
  `${GET_LOSS_RUNS_PDF}/${policyNumber}`;
