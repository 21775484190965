import { Component, Input } from '@angular/core';

// Adapted from: https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie/21712356#21712356
export const isInternetExplorer = (userAgent: string) =>
  userAgent.includes('MSIE ') || userAgent.includes('Trident/');

@Component({
  selector: 'app-inline-loading-status',
  templateUrl: './inline-loading-status.component.html',
})
export class InlineLoadingStatusComponent {
  @Input() status: 'done' | 'loading' | 'error' | 'incomplete' | 'complete';
  isIE: boolean;

  constructor() {
    const ua = window.navigator.userAgent;
    this.isIE = isInternetExplorer(ua);
  }
}
