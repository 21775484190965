import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HiscoxBindFormService } from 'app/features/hiscox/services/hiscox-bind-form.service';
import { HiscoxPaymentRes } from 'app/features/hiscox/models/hiscox-priced-quote';
import { HISCOX_PRODUCTS, HISCOX_API_VERSION } from '../../models/hiscox-types';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-hiscox-bind-payment-details',
  templateUrl: './hiscox-bind-payment-details-page.component.html',
})
export class HiscoxBindPaymentDetailsPageComponent implements OnInit {
  accountId: string;
  productType: HISCOX_PRODUCTS;
  apiVersion: HISCOX_API_VERSION;
  numberOfPayments: number;

  hiscoxPaymentDetails: Observable<HiscoxPaymentRes | null>;
  _hiscoxPaymentDetailsSubject: BehaviorSubject<HiscoxPaymentRes | null> = new BehaviorSubject(
    null
  );
  paymentReference: string;
  hiscoxChaseUrl: SafeResourceUrl;

  paymentIframeLoading: boolean;
  openPaymentErrorModal = false;

  private sub: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private formService: HiscoxBindFormService,
    private hiscoxQuoteSevice: HiscoxQuoteService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.hiscoxPaymentDetails = this._hiscoxPaymentDetailsSubject.asObservable();
    if (this.route.snapshot.parent) {
      this.accountId = this.route.snapshot.parent.params.accountId;
      this.productType = this.route.snapshot.parent.params.productType;
      this.apiVersion = this.route.snapshot.parent.params.version;

      this.numberOfPayments = this.formService.get<UntypedFormControl>(
        'paymentPlan.numberOfPayments'
      ).value;

      this.makePaymentSetup();
    }
  }

  makePaymentSetup() {
    this.paymentIframeLoading = true;

    if (this.hiscoxQuoteSevice.paymentSubject.value) {
      const hiscoxQuotePaymentResponse = this.hiscoxQuoteSevice.paymentSubject.value;
      this.paymentReference = hiscoxQuotePaymentResponse.PaymentReference;

      const hiscoxIframeUrl = `${hiscoxQuotePaymentResponse.IFrameURL}`;
      this.hiscoxChaseUrl = this.sanitizer.bypassSecurityTrustResourceUrl(hiscoxIframeUrl);
      this.paymentIframeLoading = false;
      this.openPaymentErrorModal = false;
    }
  }
}
