<div class="credit-card-field-split">
  <div
    id="js-card-number-element"
    data-amplitude-redact="true"
    class="card-field fs-block"
    [ngClass]="{ 'card-field__error': checkCreditCardFieldError('cardNumber') }"
    #cardNumber
  ></div>
  <div
    id="js-card-expiry-element"
    data-amplitude-redact="true"
    class="card-field fs-block"
    [ngClass]="{ 'card-field__error': checkCreditCardFieldError('cardExpiry') }"
    #cardExpiry
  ></div>
  <div
    id="js-card-cvc-element"
    data-amplitude-redact="true"
    class="card-field fs-block"
    [ngClass]="{ 'card-field__error': checkCreditCardFieldError('cardCvc') }"
    #cardCvc
  ></div>
  <span class="icon-credit-card"></span>
  <span class="icon-lock"></span>
</div>
