<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div
    class="{{ cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(nameOfFormControl) }"
  >
    <label [for]="inputId" class="checkbox" [ngClass]="{ checkbox__disabled: readonly }">
      <input
        *ngIf="readonly && form.get(nameOfFormControl).value"
        type="checkbox"
        style="background-color: #00baba"
        [attr.disabled]="true"
        [id]="inputId"
        [readOnly]="true"
        [formControl]="form.get(nameOfFormControl)"
      />
      <input
        *ngIf="readonly && !form.get(nameOfFormControl).value"
        type="checkbox"
        [attr.disabled]="true"
        [id]="inputId"
        [readOnly]="true"
        [formControl]="form.get(nameOfFormControl)"
      />
      <input
        *ngIf="!readonly"
        type="checkbox"
        [id]="inputId"
        [formControl]="form.get(nameOfFormControl)"
      />
      <span>
        <div [innerHTML]="innerHtmlLabelText" *ngIf="innerHtmlLabelText"></div>
        <ng-container *ngIf="labelText">
          {{ labelText }}
        </ng-container>
        <ng-content></ng-content>
        <div
          *ngIf="tooltipText"
          class="more-info-tip tool-tip tool-tip__large"
          attr.data-tooltip="{{ tooltipText }}"
        >
          <span class="icon icon-question"></span>
        </div>
        <div *ngIf="sublabelText" class="checkbox-sublabel">
          {{ sublabelText }}
        </div>
      </span>
    </label>
    <div
      *ngIf="fieldInvalid(nameOfFormControl)"
      class="form-field-error-text form-field-error-text__checkbox"
    >
      {{ getErrorText() }}
    </div>
  </div>
</ng-container>
