import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GOOGLE_MAPS_API_KEY } from 'app/constants';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
})
export class LocationFormComponent implements OnInit {
  @Input() locationIndex: number;
  @Input() locationFormGroup: UntypedFormGroup;
  @Input() submitted: boolean;
  @Output() navigateToStep = new EventEmitter<string>();
  address: Address;
  addressLoaded: Boolean = false;
  @Input() availablePropertyDeductibles: number[];
  @Input() bopVersion: number;

  constructor() {}
  ngOnInit() {
    const { state, addressLine1, addressLine2, city, zip } = this.locationFormGroup.value;
    this.address = {
      addressLine1,
      addressLine2,
      city,
      zip,
      state,
    };

    if (GOOGLE_MAPS_API_KEY) {
      this.addressLoaded = true;
    }
  }

  navigateToPolicyInfo() {
    this.navigateToStep.emit('policy-info');
  }

  partTimeEmployeeCountEnabled() {
    const partTimeEmployeeCountControl = this.locationFormGroup.get('partTimeEmployeeCount');
    if (!partTimeEmployeeCountControl) {
      return false;
    }
    return partTimeEmployeeCountControl.enabled;
  }

  public googleMapsAddress() {
    const address2Str = _.isEmpty(this.address.addressLine2) ? '' : ' ' + this.address.addressLine2;
    return encodeURIComponent(
      `${this.address.addressLine1} ${address2Str}, ${this.address.city}, ${this.address.state} ${this.address.zip}`
    );
  }

  public generateMapUrl() {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${this.googleMapsAddress()}&zoom=14&size=600x180&maptype=roadmap&markers=color:red%7C${this.googleMapsAddress()}&key=${GOOGLE_MAPS_API_KEY}`;
  }
}
