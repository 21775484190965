import { cloneDeep, keys, pickBy } from 'lodash';

import { FormDslConfiguration, LmButtonFunctionName } from './common-typings';
import {
  LmCpspQuestion,
  LmCpspComplexValueEvaluator,
  LmCpspLossHistoryQuestion,
} from './lm-cpsp-typings';
import {
  SearchQueryMethod,
  SearchFormatter,
  addressNode,
  FormDslNode,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { setupTextLengthExceededErrorMessage } from './lm-shared-validators';
import { TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE } from './constants';

const TEXT_CHAR_LIMIT = 170;

const ARRAY_CONTROLS: Record<LmCpspLossHistoryQuestion, FormDslConfiguration> = {
  [LmCpspLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE,
      nameOfFormControl: LmCpspLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE,
      labelText: 'Allocated adjuster expense',
      value: '$0',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.AMOUNT_PAID]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspLossHistoryQuestion.AMOUNT_PAID,
      nameOfFormControl: LmCpspLossHistoryQuestion.AMOUNT_PAID,
      labelText: 'Amount paid',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.AMOUNT_RESERVED]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspLossHistoryQuestion.AMOUNT_RESERVED,
      nameOfFormControl: LmCpspLossHistoryQuestion.AMOUNT_RESERVED,
      labelText: 'Amount reserved',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.CLAIM_STATUS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspLossHistoryQuestion.CLAIM_STATUS,
      nameOfFormControl: LmCpspLossHistoryQuestion.CLAIM_STATUS,
      labelText: 'Claim status',
      options: { Open: 'Open', Closed: 'Closed' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.LINE_OF_BUSINESS]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspLossHistoryQuestion.LINE_OF_BUSINESS,
      nameOfFormControl: LmCpspLossHistoryQuestion.LINE_OF_BUSINESS,
      labelText: 'Line of business',
      options: {
        CGL: 'General Liability-Occurrence',
        PROPC: 'Property',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.DATE_OF_LOSS]: {
    formDslNode: {
      primitive: 'DATE',
      inputId: LmCpspLossHistoryQuestion.DATE_OF_LOSS,
      nameOfFormControl: LmCpspLossHistoryQuestion.DATE_OF_LOSS,
      labelText: 'Date of loss',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.LOSS_DESCRIPTION]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspLossHistoryQuestion.LOSS_DESCRIPTION,
      nameOfFormControl: LmCpspLossHistoryQuestion.LOSS_DESCRIPTION,
      labelText: 'Loss Description',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.LOSS_TYPE_GENERAL_LIABILITY]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspLossHistoryQuestion.LOSS_TYPE_GENERAL_LIABILITY,
      nameOfFormControl: LmCpspLossHistoryQuestion.LOSS_TYPE_GENERAL_LIABILITY,
      labelText: 'Loss type - General Liability',
      options: {
        GBOD: 'Bodily Injury Other',
        GERR: 'Errors and Omissions',
        GLIAB: 'General Liability Other',
        GLMED: 'Liability-Medical Payments',
        GOTHR: 'Other',
        GPINJ: 'Personal Injury',
        GPROD: 'Products/Completed Operations',
        GPROF: 'Professional Liability',
        GPRDM: 'Property Damage',
        GSLPI: 'Slip/Fall - Inside',
        GSLPO: 'Slip/Fall - Outside',
      },
      // required: true, // TODO see CARP-475
    },
    valueType: 'STATIC',
  },
  [LmCpspLossHistoryQuestion.LOSS_TYPE_PROPERTY]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspLossHistoryQuestion.LOSS_TYPE_PROPERTY,
      nameOfFormControl: LmCpspLossHistoryQuestion.LOSS_TYPE_PROPERTY,
      labelText: 'Loss type - Property',
      options: {
        PCOLL: 'Collapse',
        PFIRE: 'Fire',
        PFOOD: 'Food Spoilage',
        PHAIL: 'Hail',
        PTHFT: 'Theft/Burglary',
        PVAND: 'Vandalism',
        PWATR: 'Water',
        PWIND: 'Wind',
        PPROT: 'Property Other',
        POTHR: 'Other',
      },
      // required: true, // TODO see CARP-475
    },
    valueType: 'STATIC',
  },
};

export const lmCpspLossGroupNodes = () =>
  cloneDeep<FormDslNode[]>([
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.LINE_OF_BUSINESS].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.DATE_OF_LOSS].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.LOSS_DESCRIPTION].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.LOSS_TYPE_GENERAL_LIABILITY].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.LOSS_TYPE_PROPERTY].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.CLAIM_STATUS].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.AMOUNT_PAID].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.AMOUNT_RESERVED].formDslNode,
    ARRAY_CONTROLS[LmCpspLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE].formDslNode,
  ]);

export const CPSP_FORM_QUESTIONS: Record<LmCpspQuestion, FormDslConfiguration> = {
  [LmCpspQuestion._10_MONTHLY_RENTAL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion._10_MONTHLY_RENTAL,
      nameOfFormControl: LmCpspQuestion._10_MONTHLY_RENTAL,
      labelText: 'Are more than 10% of the units rented on less than a monthly basis?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion._3_D_PRINTING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion._3_D_PRINTING,
      nameOfFormControl: LmCpspQuestion._3_D_PRINTING,
      labelText: 'Any 3D printing operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ADDITIONAL_INSURED_CLAUSE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ADDITIONAL_INSURED_CLAUSE,
      nameOfFormControl: LmCpspQuestion.ADDITIONAL_INSURED_CLAUSE,
      labelText:
        "Is the insured named as an additional insured for both premises operations and completed operations on all the insured's sub-contractors' liability policies?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ADULT_MATERIALS_SALES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.ADULT_MATERIALS_SALES,
      nameOfFormControl: LmCpspQuestion.ADULT_MATERIALS_SALES,
      labelText: "What are the insured's total sales from adult materials?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AIRPORT_CONSTRUCTION_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.AIRPORT_CONSTRUCTION_DETAILS,
      nameOfFormControl: LmCpspQuestion.AIRPORT_CONSTRUCTION_DETAILS,
      labelText: 'Give details of work around airports.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ALARM_OR_SPRINKLER_INSTALLATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ALARM_OR_SPRINKLER_INSTALLATION,
      nameOfFormControl: LmCpspQuestion.ALARM_OR_SPRINKLER_INSTALLATION,
      labelText:
        'Any work involving fire alarm, burglar alarm, or automatic sprinkler design, installation or repair?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ANNUAL_SALES_RECEIPTS]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspQuestion.ANNUAL_SALES_RECEIPTS,
      nameOfFormControl: LmCpspQuestion.ANNUAL_SALES_RECEIPTS,
      labelText: 'Annual sales/receipts',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.APARTMENT_FORECLOSURE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.APARTMENT_FORECLOSURE,
      nameOfFormControl: LmCpspQuestion.APARTMENT_FORECLOSURE,
      labelText:
        'Is the property currently in foreclosure or been in foreclosure during the past 12 months?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.APPLICANT_IS_ADDITIONAL_INSURED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.APPLICANT_IS_ADDITIONAL_INSURED,
      nameOfFormControl: LmCpspQuestion.APPLICANT_IS_ADDITIONAL_INSURED,
      labelText:
        "Is the applicant named as an additional insured on the tenant's insurance policies?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS,
      nameOfFormControl: LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS,
      labelText: 'Does the applicant manufacture component parts for others?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS_DETAILS,
      nameOfFormControl: LmCpspQuestion.APPLICANT_MFG_COMPENENTS_FOR_OTHERS_DETAILS,
      labelText: 'Please give details of the manufacture component parts for others.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.APPLICANT_SUBSIDIARIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.APPLICANT_SUBSIDIARIES,
      nameOfFormControl: LmCpspQuestion.APPLICANT_SUBSIDIARIES,
      labelText:
        'Does the applicant have any subsidiaries or is the applicant a subsidiary of another entity?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.APPLICANT_SUBSIDIARIES_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.APPLICANT_SUBSIDIARIES_DETAILS,
      nameOfFormControl: LmCpspQuestion.APPLICANT_SUBSIDIARIES_DETAILS,
      labelText: 'Please provide details regarding the subsidiary relationship.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.APPLICANTS_MAIN_CUSTOMERS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.APPLICANTS_MAIN_CUSTOMERS,
      nameOfFormControl: LmCpspQuestion.APPLICANTS_MAIN_CUSTOMERS,
      labelText: "Who are the applicant's main customers?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ARE_THERE_APARTMENT_OCCUPANCIES_THAT_IN_TOTAL_COMPRISE_LESS_THAN_25_OF_THE_BUILDING_FLOOR_AREA]:
    {
      formDslNode: {
        primitive: 'RADIO',
        inputId:
          LmCpspQuestion.ARE_THERE_APARTMENT_OCCUPANCIES_THAT_IN_TOTAL_COMPRISE_LESS_THAN_25_OF_THE_BUILDING_FLOOR_AREA,
        nameOfFormControl:
          LmCpspQuestion.ARE_THERE_APARTMENT_OCCUPANCIES_THAT_IN_TOTAL_COMPRISE_LESS_THAN_25_OF_THE_BUILDING_FLOOR_AREA,
        labelText:
          'Are there apartment occupancies that in total comprise less than 25% of the building floor area?',
        options: { Yes: 'YES', No: 'NO' },
        required: true,
      },
      valueType: 'STATIC',
    },
  [LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_1,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_1,
      labelText:
        'Does the area occupied by the mercantile or non-manufacturing area exceed 15% of the total floor area of the building or 10,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_2]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_2,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_2,
      labelText:
        'Does the area occupied by the mercantile or non-manufacturing area exceed 15% of the total floor area of the building or 10,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_1,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_1,
      labelText:
        'Does the area occupied by the mercantile or non-manufacturing area exceed 15% of the total floor area of the building or 2,500 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_2]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_2,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_2,
      labelText:
        'Does the area occupied by the mercantile or non-manufacturing area exceed 15% of the total floor area of the building or 2,500 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_1,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_1,
      labelText:
        'Does the area occupied by the mercantile or non-manufacturing area exceed 15% of the total floor area of the building or 5,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_2]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_2,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_2,
      labelText:
        'Does the area occupied by the mercantile or non-manufacturing area exceed 15% of the total floor area of the building or 5,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_OCCUPIED_BY_INSURED_PROPERTY]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.AREA_OCCUPIED_BY_INSURED_PROPERTY,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_BY_INSURED_PROPERTY,
      labelText: "Square footage the insured's business occupies",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS,
      nameOfFormControl: LmCpspQuestion.AREA_OCCUPIED_BY_TENANTS,
      labelText: 'Square footage occupied by other building tenants',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_UNOCCUPIED]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.AREA_UNOCCUPIED,
      nameOfFormControl: LmCpspQuestion.AREA_UNOCCUPIED,
      labelText: 'Square footage unoccupied',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AREA_UNOCCUPIED_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.AREA_UNOCCUPIED_DETAILS,
      nameOfFormControl: LmCpspQuestion.AREA_UNOCCUPIED_DETAILS,
      labelText: 'Please provide details regarding unoccupied areas of the building.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ARMED_SECURITY_GUARDS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ARMED_SECURITY_GUARDS,
      nameOfFormControl: LmCpspQuestion.ARMED_SECURITY_GUARDS,
      labelText: 'Are employees or hired security guards armed?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ARSON_CONVICTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ARSON_CONVICTION,
      nameOfFormControl: LmCpspQuestion.ARSON_CONVICTION,
      labelText:
        'During the last five years (ten in RI), has any applicant been indicted for or convicted of any degree of the crime of fraud, bribery, arson or any other arson-related crime?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ASBESTOS_FIBERS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ASBESTOS_FIBERS,
      nameOfFormControl: LmCpspQuestion.ASBESTOS_FIBERS,
      labelText: 'Does applicant install or remove tile with asbestos fibers?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ATTACK_EXTORTION]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.ATTACK_EXTORTION,
      nameOfFormControl: LmCpspQuestion.ATTACK_EXTORTION,
      labelText: 'Attack & Extortion',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ATTACK_EXTORTION_LIMIT]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.ATTACK_EXTORTION_LIMIT,
      nameOfFormControl: LmCpspQuestion.ATTACK_EXTORTION_LIMIT,
      labelText: 'Attack & Extortion Limit',
      options: {
        '50000': '$50,000',
        '100000': '$10,0000',
        '250000': '$25,0000',
        '500000': '$500,000',
        '1000000': '$1,000,000',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ATTENDANT_ON_DUTY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ATTENDANT_ON_DUTY,
      nameOfFormControl: LmCpspQuestion.ATTENDANT_ON_DUTY,
      labelText: 'Is there an employee on premises during all hours of operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AUTO_BODY_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AUTO_BODY_1,
      nameOfFormControl: LmCpspQuestion.AUTO_BODY_1,
      labelText: 'Does the insured have any autobody, welding or painting operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AUTO_BODY_2]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AUTO_BODY_2,
      nameOfFormControl: LmCpspQuestion.AUTO_BODY_2,
      labelText: 'Does the insured have any autobody or welding operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AUTO_BODY_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.AUTO_BODY_DETAILS,
      nameOfFormControl: LmCpspQuestion.AUTO_BODY_DETAILS,
      labelText: 'Please give details of any autobody, welding or painting operations.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AUTO_LENDING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AUTO_LENDING,
      nameOfFormControl: LmCpspQuestion.AUTO_LENDING,
      labelText: 'Does the insured provide auto lending to customers?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AUTO_RENTAL_OR_LEASING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AUTO_RENTAL_OR_LEASING,
      nameOfFormControl: LmCpspQuestion.AUTO_RENTAL_OR_LEASING,
      labelText: 'Does the insured have any vehicle rental or leasing operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.AUTO_SALVAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.AUTO_SALVAGE,
      nameOfFormControl: LmCpspQuestion.AUTO_SALVAGE,
      labelText: 'Are there any auto dismantling, salvage or used parts sales?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BACKGROUND_CHECKS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BACKGROUND_CHECKS,
      nameOfFormControl: LmCpspQuestion.BACKGROUND_CHECKS,
      labelText: 'Are background checks obtained on employees?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BALCONIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BALCONIES,
      nameOfFormControl: LmCpspQuestion.BALCONIES,
      labelText: 'Do any habitational units have balconies?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BANQUET_ADEQUATE_HARDWARE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BANQUET_ADEQUATE_HARDWARE,
      nameOfFormControl: LmCpspQuestion.BANQUET_ADEQUATE_HARDWARE,
      labelText:
        'Does the banquet area have adequate exits properly marked and equipped with panic hardware to permit easy egress?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BEAUTY_SHOP]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BEAUTY_SHOP,
      nameOfFormControl: LmCpspQuestion.BEAUTY_SHOP,
      labelText:
        'Are any services provided other than those typical to a beauty salon or barber shop? Typical services include hair cutting, styling, coloring, permanents, eyebrow waxing, and manicures/pedicures.',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },

  [LmCpspQuestion.BICYCLE_RENTAL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BICYCLE_RENTAL,
      nameOfFormControl: LmCpspQuestion.BICYCLE_RENTAL,
      labelText: 'Does the insured rent any bicycles?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED,
      nameOfFormControl: LmCpspQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED,
      labelText:
        'Is the business operation closed, temporarily suspended or not expected to open within the next 30 days?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BLANKET_ADDITIONAL_INSURED_CONTRACTORS_PRODUCTS_COMPLETED_OPERATIONS]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.BLANKET_ADDITIONAL_INSURED_CONTRACTORS_PRODUCTS_COMPLETED_OPERATIONS,
      nameOfFormControl:
        LmCpspQuestion.BLANKET_ADDITIONAL_INSURED_CONTRACTORS_PRODUCTS_COMPLETED_OPERATIONS,
      labelText: 'Blanket additional insured Contractors - Products Completed Operations',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BOILER_WORK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BOILER_WORK,
      nameOfFormControl: LmCpspQuestion.BOILER_WORK,
      labelText:
        'Does the insured do any boiler work other than to low pressure hot water heat systems?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BOILER_WORK_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.BOILER_WORK_DETAILS,
      nameOfFormControl: LmCpspQuestion.BOILER_WORK_DETAILS,
      labelText: 'Give details of boiler work.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BPP_INCLUDES_EQUIP_OVER_500]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BPP_INCLUDES_EQUIP_OVER_500,
      nameOfFormControl: LmCpspQuestion.BPP_INCLUDES_EQUIP_OVER_500,
      labelText:
        "Does the insured's business personal property limit include any equipment valued over $500,000 (i.e. - magnetic resonance imaging - MRI, X-Ray equipment, ultra sound technology, etc.)?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BUILDING_LOCATIONS_DISTANCE]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.BUILDING_LOCATIONS_DISTANCE,
      nameOfFormControl: LmCpspQuestion.BUILDING_LOCATIONS_DISTANCE,
      labelText:
        'If there is more than one building at a location, what is the distance between each building on the premises?',
      questionNote:
        'Distance between buildings should be entered in feet. If there is only one building, enter 0.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BUILDING_OCCUPANTS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.BUILDING_OCCUPANTS,
      nameOfFormControl: LmCpspQuestion.BUILDING_OCCUPANTS,
      labelText:
        'List each occupant, the nature of their business and the square footage they occupy.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_100_K]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_100_K,
      nameOfFormControl: LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_100_K,
      labelText: 'Does the area of any one building exceed 100,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_150_K]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_150_K,
      nameOfFormControl: LmCpspQuestion.BUILDING_SQUARE_FEET_EXCEED_150_K,
      labelText: 'Does the area of any one building exceed 150,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BUILDING_SQUARE_FOOTAGE]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.BUILDING_SQUARE_FOOTAGE,
      nameOfFormControl: LmCpspQuestion.BUILDING_SQUARE_FOOTAGE,
      labelText: 'What is the square footage of the the main nave/sanctuary building?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.BURGLARY_ALARM]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.BURGLARY_ALARM,
      nameOfFormControl: LmCpspQuestion.BURGLARY_ALARM,
      labelText:
        'Is the premises protected against burglary or robbery by a central station alarm?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CASINO]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CASINO,
      nameOfFormControl: LmCpspQuestion.CASINO,
      labelText: 'Is there a casino?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CERAMIC_FLOOR_PERCENT_REFER]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.CERAMIC_FLOOR_PERCENT_REFER,
      nameOfFormControl: LmCpspQuestion.CERAMIC_FLOOR_PERCENT_REFER,
      labelText:
        "What percent of the applicant's business is for installation of ceramic floor covering material?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CERTIFICATES_OF_INSURANCE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CERTIFICATES_OF_INSURANCE,
      nameOfFormControl: LmCpspQuestion.CERTIFICATES_OF_INSURANCE,
      labelText: 'Are certificates of insurance required from subcontractors?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CERTIFICATES_PROVIDED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CERTIFICATES_PROVIDED,
      nameOfFormControl: LmCpspQuestion.CERTIFICATES_PROVIDED,
      labelText:
        "Does the applicant allow contractors and/or service providers to work on their premises without providing certificate of insurance showing liability limits equal to or greater than the applicant's?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CHILD_CARE_SERVICE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CHILD_CARE_SERVICE,
      nameOfFormControl: LmCpspQuestion.CHILD_CARE_SERVICE,
      labelText: 'Any child care services offered?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CHURCH_SCHOOL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CHURCH_SCHOOL,
      nameOfFormControl: LmCpspQuestion.CHURCH_SCHOOL,
      labelText: 'Does the insured operate a school other than for religious education?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CITATION_ALCOHOLIC_BEVERAGES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CITATION_ALCOHOLIC_BEVERAGES,
      nameOfFormControl: LmCpspQuestion.CITATION_ALCOHOLIC_BEVERAGES,
      labelText:
        'Has there ever been a citation issued by an alcoholic beverage commission or other government regulator for any location that sells and/or serves alcoholic beverages?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ADDRESS]: {
    formDslNode: addressNode({
      nameOfFormControl: LmCpspQuestion.ADDRESS,
      labelText: 'Business address',
      prefix: 'lm-cpsp',
      useAddressLine2: true,
      questionNote:
        'Need to add another location or building? Use <a href="/support/form/add-building" target="_blank">this form</a> after completing the quote.',
    }),
    valueType: 'STATIC',
  },

  [LmCpspQuestion.CLASS_LIQUOR_LIABILITY]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.CLASS_LIQUOR_LIABILITY,
      nameOfFormControl: LmCpspQuestion.CLASS_LIQUOR_LIABILITY,
      labelText: 'Class',
      options: {
        '50911': 'Manufacturers',
        '58161': 'Restaurants, Hotels, Motels',
        '58165': 'Restaurants Bring Your Own',
        '58166': 'All Other Bring Your Own',
        '58168': 'Temporary Licenses',
        '59211': 'Package Stores',
        '70412': 'Clubs',
        '05998': 'Bars or Taverns Including Package Stores',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CLEANING_KITCHEN_FLUES_HOODS_PERCENT_REFER]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.CLEANING_KITCHEN_FLUES_HOODS_PERCENT_REFER,
      nameOfFormControl: LmCpspQuestion.CLEANING_KITCHEN_FLUES_HOODS_PERCENT_REFER,
      labelText: "What percent of the applicant's business is cleaning of kitchen flues or hoods?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CLOSED_BY_BOH]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CLOSED_BY_BOH,
      nameOfFormControl: LmCpspQuestion.CLOSED_BY_BOH,
      labelText:
        'Has the applicant been cited or closed by any regulatory agencies in the last five years?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.COINSURANCE_BI_AND_EE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.COINSURANCE_BI_AND_EE,
      nameOfFormControl: LmCpspQuestion.COINSURANCE_BI_AND_EE,
      labelText: 'Coinsurance (Business income with extra expense)',
      options: {
        '0': '0%',
        '50': '50%',
        '60': '60%',
        '70': '70%',
        '80': '80%',
        '90': '90%',
        '100': '100%',
        '125': '125%',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.COINSURANCE_BPP]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.COINSURANCE_BPP,
      nameOfFormControl: LmCpspQuestion.COINSURANCE_BPP,
      labelText: 'Coinsurance (Business personal property)',
      options: { '80': '80%', '90': '90%', '100': '100%' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.COINSURANCE_BUILDING]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.COINSURANCE_BUILDING,
      nameOfFormControl: LmCpspQuestion.COINSURANCE_BUILDING,
      labelText: 'Coinsurance (Building)',
      options: {
        '80': '80%',
        '90': '90%',
        '100': '100%',
      },
      required: true,
    },
    valueType: 'DYNAMIC_FUNC',
    valueDependencyFunc: LmCpspComplexValueEvaluator.COINSURANCE_BUILDING_OPTIONS,
  },
  [LmCpspQuestion.COMBUSTIBLE_WASTE_CONTAINERS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.COMBUSTIBLE_WASTE_CONTAINERS,
      nameOfFormControl: LmCpspQuestion.COMBUSTIBLE_WASTE_CONTAINERS,
      labelText:
        'Is combustible waste kept in metal containers with lids and emptied on a daily or weekly basis?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.COMMERCIAL_COOKING_QUESTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.COMMERCIAL_COOKING_QUESTION,
      nameOfFormControl: LmCpspQuestion.COMMERCIAL_COOKING_QUESTION,
      labelText:
        'Are there commercial cooking operations in the building that produce smoke or grease laden vapors?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.COMPLEMENTARY_SERVICES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.COMPLEMENTARY_SERVICES,
      nameOfFormControl: LmCpspQuestion.COMPLEMENTARY_SERVICES,
      labelText:
        'Does the applicant provide complementary services or operations not associated with the selected classification(s)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.COMPUTER_PROGRAMMING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.COMPUTER_PROGRAMMING,
      nameOfFormControl: LmCpspQuestion.COMPUTER_PROGRAMMING,
      labelText: 'Does the insured do computer programming for a fee?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.COMPUTER_PROGRAMMING_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.COMPUTER_PROGRAMMING_DETAILS,
      nameOfFormControl: LmCpspQuestion.COMPUTER_PROGRAMMING_DETAILS,
      labelText: 'Please provide details regarding the computer programming for a fee.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CONSTRUCTION_PROJECTS_GENERAL_AGGREGATE_LIMITS_PER_PROJECT]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.CONSTRUCTION_PROJECTS_GENERAL_AGGREGATE_LIMITS_PER_PROJECT,
      nameOfFormControl: LmCpspQuestion.CONSTRUCTION_PROJECTS_GENERAL_AGGREGATE_LIMITS_PER_PROJECT,
      labelText: 'Construction Project(s)',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY,
      nameOfFormControl: LmCpspQuestion.CONSTRUCTION_TYPE_PROPERTY,
      labelText: 'Construction Type',
      options: {
        F: 'Frame',
        JM: 'Joisted Masonry',
        NC: 'Non Combustible',
        MNC: 'Masonry Non Combustible',
        MFR: 'Modified Fire Resisitive',
        R: 'Fire Resistive',
      },
      required: true,
    },
    valueType: 'DYNAMIC_CONFIG',
    valueData: [
      {
        state: { display: 'IN', values: ['MS'] },
        nodeValue: {
          options: {
            F: 'Frame',
            JM: 'Joisted Masonry',
            NC: 'Non Combustible',
            MNC: 'Masonry Non Combustible',
            R: 'Fire Resistive',
          },
        },
      },
      {
        state: { display: 'IN', values: ['LA'] },
        nodeValue: {
          options: {
            F: 'Frame',
            JM: 'Joisted Masonry',
            NC: 'Non Combustible',
            MNC: 'Masonry Non Combustible',
            MFR: 'Modified Fire Resisitive',
            R: 'Fire Resistive',
            MILL: 'Heavy Timber Joisted Masonry',
            SNC: 'Superior Non-Combustible',
            SMNC: 'Superior Masonry Non-Combustible',
          },
        },
      },
    ],
  },
  [LmCpspQuestion.CONSULTANT_OPERATIONS]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.CONSULTANT_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.CONSULTANT_OPERATIONS,
      labelText: 'Type of Consultant',
      options: {
        ExecutiveSearchOutplacementConsultant: 'Executive Search/Outplacement Consultant',
        ExpertWitnessConsultant: 'Expert Witness Consultant',
        FinancialAccountingConsultant: 'Financial/Accounting Consultant',
        GeneralManagementConsultant: 'General Management Consultant',
        MarketingAdvertisingConsultant: 'Marketing/Advertising Consultant',
        MedicalOffice: 'Medical Office',
        NonProfitFundRaisingConsultant: 'Non-Profit Fund Raising Consultant',
        OrganizationalDevelopmentConsultant: 'Organizational Development Consultant',
        RecordsManagement: 'Records management',
        RiskManagementConsultant: 'Risk Management Consultant',
        SeminarEducatorProfessionalSpeakerConsultant:
          'Seminar Educator/Professional speaker Consultant',
        TrainingLeadershipConsultant: 'Training Leadership Consultant',
        TrainingSoftwareConsultant: 'Training - Software Consultant',
        WeddingConsultant: 'Wedding Consultant',
        Other: 'Other',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CONTACT_UTILITIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CONTACT_UTILITIES,
      nameOfFormControl: LmCpspQuestion.CONTACT_UTILITIES,
      labelText: 'Does the insured contact utilities prior to digging?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CONTRACT_LANGUAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CONTRACT_LANGUAGE,
      nameOfFormControl: LmCpspQuestion.CONTRACT_LANGUAGE,
      labelText:
        'Does the applicant allow contractors and/or service providers to work on their premises without contract language holding the applicant harmless for injuries or damage arising out of the actions of the contractors and/or service providers?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CONTRACTORS_LICENSES_LIMIT]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.CONTRACTORS_LICENSES_LIMIT,
      nameOfFormControl: LmCpspQuestion.CONTRACTORS_LICENSES_LIMIT,
      labelText: "List number of contractor's licenses held by the applicant.",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CONVERTED_HOME_DWELLING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CONVERTED_HOME_DWELLING,
      nameOfFormControl: LmCpspQuestion.CONVERTED_HOME_DWELLING,
      labelText: 'Is the building a converted home or dwelling structure?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CP_1045_SALE_OR_STORAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CP_1045_SALE_OR_STORAGE,
      nameOfFormControl: LmCpspQuestion.CP_1045_SALE_OR_STORAGE,
      labelText:
        'Are the sales or storage of materials by the insured, primarily, any of the following materials: ammunition; chemicals, acids, gases or oil, bulk fibers, fireworks, or waste or reclaimed materials?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL,
      nameOfFormControl: LmCpspQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL,
      labelText:
        'Is the extinguishing system serviced at least on a semi-annual basis by a licensed contractor?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM,
      nameOfFormControl: LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM,
      labelText:
        'Is there a UL 300 fixed extinguishing system with automatic fuel cutoff protecting all cooking surfaces?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM_MGT_POLICY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM_MGT_POLICY,
      nameOfFormControl: LmCpspQuestion.CPUL_300_EXTINGUISHING_SYSTEM_MGT_POLICY,
      labelText:
        'Is there a management policy requiring a UL 300 fixed extinguishing system of these tenant(s)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CRANE_OR_BUCKET_TRUCK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CRANE_OR_BUCKET_TRUCK,
      nameOfFormControl: LmCpspQuestion.CRANE_OR_BUCKET_TRUCK,
      labelText:
        'Does the insured use any type of crane, stand alone, truck mounted crane, or bucket truck?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CRANE_OR_BUCKET_TRUCK_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.CRANE_OR_BUCKET_TRUCK_DETAILS,
      nameOfFormControl: LmCpspQuestion.CRANE_OR_BUCKET_TRUCK_DETAILS,
      labelText: 'Give details of work with any type of cranes.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.CUSTOMIZING_SERVICES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.CUSTOMIZING_SERVICES,
      nameOfFormControl: LmCpspQuestion.CUSTOMIZING_SERVICES,
      labelText: 'Any speed shop or customizing services?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DAMS_OR_BRIDGES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DAMS_OR_BRIDGES,
      nameOfFormControl: LmCpspQuestion.DAMS_OR_BRIDGES,
      labelText: 'Does the insured perform construction work in/around dams, bridges, or rivers?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_COMPROMISE_DEFENSE_AND_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.DATA_COMPROMISE_DEFENSE_AND_LIABILITY,
      nameOfFormControl: LmCpspQuestion.DATA_COMPROMISE_DEFENSE_AND_LIABILITY,
      labelText: 'Data Compromise Defense and Liability',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      nameOfFormControl: LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      labelText: 'Data Compromise Response Expenses',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT,
      nameOfFormControl: LmCpspQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT,
      labelText: 'Data Compromise Response Expenses Limit',
      options: {
        '50000': '$50,000',
        '100000': '$100,000',
        '250000': '$250,000',
        '500000': '$500,000',
        '1000000': '$1,000,000',
      },
      required: true,
    },
    valueType: 'DYNAMIC_CONFIG',
    valueData: [
      {
        state: { display: 'IN', values: ['MT', 'NH', 'NY'] },
        nodeValue: {
          options: {
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
    ],
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_1,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_1,
      labelText:
        "Has the insured's organization suffered a breach of personal information in the last 12 months?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_10]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_10,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_10,
      labelText:
        'Are all users issued unique IDs and passwords when connecting to or accessing the internal network and do passwords require periodic changes, minimum length and mixed case, letters, numbers and special characters?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_11]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_11,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_11,
      labelText: 'Does the insured backup computer data and store it off site?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_12]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_12,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_12,
      labelText:
        'Does the insured use encryption techniques for secure communications and the transfer of confidential information?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_2]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_2,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_2,
      labelText: 'Does the insured conduct background checks on prospective employees?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_3]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_3,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_3,
      labelText: 'Is there a posted document retention/destrution policy in place?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_4]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_4,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_4,
      labelText:
        'Does the insured maintain regularly updated computer security measures, e.g. firewall configured to maximum security, secured wireless connectivity, virus protection configured to update automatically?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_5]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_5,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_5,
      labelText:
        "Are the insured's employee, customer, and other physical and electronic records maintained in a secure environment with limited access? In the case of electronic records, this includes using networks that cannot be accessed extern",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_6]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_6,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_6,
      labelText:
        'Is access to personal information and/or third party confidential information restricted by job position?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_7]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_7,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_7,
      labelText: 'Is there a Chief Information and/or Chief Security Officer (or equivalent)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_8]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_8,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_8,
      labelText:
        'Does the insured have a comprehensive Information Security and Privacy Policy addressing such items as use of email (including size limitations), etc.?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DATA_SECURITY_QUESTION_9]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DATA_SECURITY_QUESTION_9,
      nameOfFormControl: LmCpspQuestion.DATA_SECURITY_QUESTION_9,
      labelText:
        'Does the insured provide regular security training/information to all people who have access to personally identifying information, whether in paper or electronic format?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DAY_CARE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DAY_CARE,
      nameOfFormControl: LmCpspQuestion.DAY_CARE,
      labelText:
        'Does the insured offer day care (other than during church services and functions)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DEMOLITION_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.DEMOLITION_DETAILS,
      nameOfFormControl: LmCpspQuestion.DEMOLITION_DETAILS,
      labelText: 'Give details of demolition or wrecking.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DEMOLITION_PERCENT]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.DEMOLITION_PERCENT,
      nameOfFormControl: LmCpspQuestion.DEMOLITION_PERCENT,
      labelText: "What is the applicant's percent of work that involves demolition?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DESCRIPTION_OF_OPERATIONS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.DESCRIPTION_OF_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.DESCRIPTION_OF_OPERATIONS,
      labelText: 'Description of operations',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES,
      nameOfFormControl: LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES,
      labelText: 'Does the insured provide any design or engineering services?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES_DETAILS,
      nameOfFormControl: LmCpspQuestion.DESIGN_OR_ENGINEERING_SERVICES_DETAILS,
      labelText: 'Give details of any design or engineering services the insured provides.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS,
      nameOfFormControl: LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS,
      labelText: 'Does applicant draw plans, designs, or specifications for others?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS_DETAILS,
      nameOfFormControl: LmCpspQuestion.DESIGNS_OR_SPECS_FOR_OTHERS_DETAILS,
      labelText: 'Give details of plans, designs or specifications for others.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DISPOSAL_AUTO_RELATED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DISPOSAL_AUTO_RELATED,
      nameOfFormControl: LmCpspQuestion.DISPOSAL_AUTO_RELATED,
      labelText:
        'Does applicant dispose of used tires, automotive fluids, batteries, motor oil, soiled uniforms or rags in accordance with state and federal regulations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DIVING_BOARD_HEIGHT]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.DIVING_BOARD_HEIGHT,
      nameOfFormControl: LmCpspQuestion.DIVING_BOARD_HEIGHT,
      labelText: 'What is the height of the diving board?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.DWELLING_OCCUPANCY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.DWELLING_OCCUPANCY,
      nameOfFormControl: LmCpspQuestion.DWELLING_OCCUPANCY,
      labelText:
        'Does the risk include time share units, resorts or seasonal/short term rentals, boarding or rooming houses, student housing, transitional housing, rehabilitation facilities, or senior housing?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.E_COMMERCE_DESIGN]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.E_COMMERCE_DESIGN,
      nameOfFormControl: LmCpspQuestion.E_COMMERCE_DESIGN,
      labelText: 'Any design or development of e-commerce functionality?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES,
      nameOfFormControl: LmCpspQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES,
      labelText: 'Each claim / aggregate limit – Employment Practices',
      options: {
        '10000': '$10,000',
        '25000': '$25,000',
        '100000': '$100,000',
        '250000': '$250,000',
        '500000': '$500,000',
        '1000000': '$1,000,000',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EACH_OCCURRENCE_AGGREGATE_LIMIT]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.EACH_OCCURRENCE_AGGREGATE_LIMIT,
      nameOfFormControl: LmCpspQuestion.EACH_OCCURRENCE_AGGREGATE_LIMIT,
      labelText: 'Each occurrence / aggregate limit',
      required: true,
      options: {
        '5000/25000': '$5,000/$25,000',
        '25000/25000': '$25,000/$25,000',
      },
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EFFECTIVE_DATE]: {
    formDslNode: {
      primitive: 'DATE',
      inputId: LmCpspQuestion.EFFECTIVE_DATE,
      nameOfFormControl: LmCpspQuestion.EFFECTIVE_DATE,
      labelText: 'Effective date',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EIFS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EIFS,
      nameOfFormControl: LmCpspQuestion.EIFS,
      labelText: 'Has the insured worked with EIFS either past or present?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ELECTROPLATING_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ELECTROPLATING_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.ELECTROPLATING_OPERATIONS,
      labelText: 'Any electroplating or chemical milling operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EMERGENCY_ESCAPE_ROUTES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EMERGENCY_ESCAPE_ROUTES,
      nameOfFormControl: LmCpspQuestion.EMERGENCY_ESCAPE_ROUTES,
      labelText:
        'Do habitational units have two or more means of egress/emergency escape routes and are they unobstructed and kept in usable condition?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EMP_CARRY_FIREARMS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EMP_CARRY_FIREARMS,
      nameOfFormControl: LmCpspQuestion.EMP_CARRY_FIREARMS,
      labelText: 'Do employees carry firearms?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EMPLOYEE_LEASING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EMPLOYEE_LEASING,
      nameOfFormControl: LmCpspQuestion.EMPLOYEE_LEASING,
      labelText:
        'Is the business an employee leasing, labor leasing, labor contractor, PEO, temporary worker staffing or employment agency firm?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY,
      nameOfFormControl: LmCpspQuestion.EMPLOYEE_PRACTICES_LIABILITY,
      labelText: 'Employee Practices Liability',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ENTER_RESIDENCES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ENTER_RESIDENCES,
      nameOfFormControl: LmCpspQuestion.ENTER_RESIDENCES,
      labelText: 'Do employees enter occupied customer residences as part of operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR,
      nameOfFormControl: LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR,
      labelText:
        'Is there live entertainment (excluding solo performers), dance floors, or dangerous activities?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR_DETAILS,
      nameOfFormControl: LmCpspQuestion.ENTERTAINMENT_OR_DANCE_FLOOR_DETAILS,
      labelText: 'Please provide details regarding the live entertainment or dance floor.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ENVIRONMENTAL_HAZARD]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ENVIRONMENTAL_HAZARD,
      nameOfFormControl: LmCpspQuestion.ENVIRONMENTAL_HAZARD,
      labelText:
        'Does the insured have past, present, or discontinued operations that involve(d) storing, treating, discharging, applying, disposing, or transporting of hazardous material (e.g. landfills, wastes, fuel tanks, etc.)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ENVIRONMENTAL_HAZARD_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.ENVIRONMENTAL_HAZARD_DETAILS,
      nameOfFormControl: LmCpspQuestion.ENVIRONMENTAL_HAZARD_DETAILS,
      labelText:
        'Please give details of operations that involve(d) storing, treating, discharging, applying, disposing, or transporting of hazardous material',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EPLI_CLAIMS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EPLI_CLAIMS,
      nameOfFormControl: LmCpspQuestion.EPLI_CLAIMS,
      labelText:
        'Has the insured been involved in any EPLI claims regardless of whether any payment or not, or does the insured have knowledge of any situation(s) that could produce an EPLI claim?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXCEED_10_MILLION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EXCEED_10_MILLION,
      nameOfFormControl: LmCpspQuestion.EXCEED_10_MILLION,
      labelText: 'Do the annual sales at any one location exceed $10,000,000?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXCEED_15_MILLION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EXCEED_15_MILLION,
      nameOfFormControl: LmCpspQuestion.EXCEED_15_MILLION,
      labelText: 'Do the annual sales at any one location exceed $15,000,000?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXCEED_15_MILLION_IP]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EXCEED_15_MILLION_IP,
      nameOfFormControl: LmCpspQuestion.EXCEED_15_MILLION_IP,
      labelText: 'Do the annual sales at any one location exceed $15,000,000?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXCEED_7_5_MILLION_IP]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EXCEED_7_5_MILLION_IP,
      nameOfFormControl: LmCpspQuestion.EXCEED_7_5_MILLION_IP,
      labelText: 'Do the annual sales at any one location exceed $7,500,000?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXECUTIVE_SEARCH_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EXECUTIVE_SEARCH_IQ,
      nameOfFormControl: LmCpspQuestion.EXECUTIVE_SEARCH_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only on the identification, selection and compensation of business leaders and executives or providing advice only relative to outplacement services offered to terminated employees of customers' businesses. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXPERT_WITNESS_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EXPERT_WITNESS_IQ,
      nameOfFormControl: LmCpspQuestion.EXPERT_WITNESS_IQ,
      labelText:
        "The operations of the applicant consist of providing advice and testimony regarding specific areas of expertise. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXPOSURE]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.EXPOSURE,
      nameOfFormControl: LmCpspQuestion.EXPOSURE,
      labelText: 'Exposure',
      required: true,
    },
    valueType: 'DYNAMIC_FUNC',
    valueDependencyFunc: LmCpspComplexValueEvaluator.EXPOSURE_PRIMITIVE_AND_LABEL_TEXT,
  },
  [LmCpspQuestion.EXPOSURE_LIQUOR_LIABILITY]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspQuestion.EXPOSURE_LIQUOR_LIABILITY,
      nameOfFormControl: LmCpspQuestion.EXPOSURE_LIQUOR_LIABILITY,
      labelText: '', // labelText is set by EXPOSURE_LIQUOR_LIABILITY_LABEL_TEXT
      required: true,
    },
    valueType: 'DYNAMIC_FUNC',
    valueDependencyFunc: LmCpspComplexValueEvaluator.EXPOSURE_LIQUOR_LIABILITY_LABEL_TEXT,
  },
  [LmCpspQuestion.EXTERIOR_DOORS_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.EXTERIOR_DOORS_1,
      nameOfFormControl: LmCpspQuestion.EXTERIOR_DOORS_1,
      labelText: 'Do any guest rooms have primary entrance doors on the exterior of the building?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXTERNAL_INSURED_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.EXTERNAL_INSURED_DETAILS,
      nameOfFormControl: LmCpspQuestion.EXTERNAL_INSURED_DETAILS,
      labelText: "Describe the insured's operations conducted away from premises?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.EXTERNAL_INSURED_REVENUES]: {
    formDslNode: {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: LmCpspQuestion.EXTERNAL_INSURED_REVENUES,
      nameOfFormControl: LmCpspQuestion.EXTERNAL_INSURED_REVENUES,
      labelText:
        "What are the insured's revenues generated from all operations performed away from premises?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FEDERAL_ID]: {
    formDslNode: {
      primitive: 'FEIN',
      inputId: LmCpspQuestion.FEDERAL_ID,
      nameOfFormControl: LmCpspQuestion.FEDERAL_ID,
      labelText: 'FEIN',
      specifierText: '(optional)',
      required: false,
      placeholder: '00-0000000',
      maxLength: '10',
      inputType: 'text',
      value: null,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FILL_PROPANE_GAS_TANKS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FILL_PROPANE_GAS_TANKS,
      nameOfFormControl: LmCpspQuestion.FILL_PROPANE_GAS_TANKS,
      labelText: 'Does the insured fill propane gas tanks?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FINANCIAL_ACCOUNTING_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FINANCIAL_ACCOUNTING_IQ,
      nameOfFormControl: LmCpspQuestion.FINANCIAL_ACCOUNTING_IQ,
      labelText:
        "The operations of the applicant consist of providing advice on financial and/or accounting matters only. Investment, stock or commodities brokers or investments bankers are ineligible. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FIRE_ALARM_TYPE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.FIRE_ALARM_TYPE,
      nameOfFormControl: LmCpspQuestion.FIRE_ALARM_TYPE,
      labelText: 'Fire alarm type',
      options: { C: 'Central Station', LO: 'Local', PC: 'Police/Fire Connected', NO: 'None' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FIRE_DAMAGE_REMEDIATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FIRE_DAMAGE_REMEDIATION,
      nameOfFormControl: LmCpspQuestion.FIRE_DAMAGE_REMEDIATION,
      labelText:
        'Does the insured perform any remediation work including, but not limited to, repair of fire damage, water damage or mold damage?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FIRE_DAMAGE_REMEDIATION_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.FIRE_DAMAGE_REMEDIATION_DETAILS,
      nameOfFormControl: LmCpspQuestion.FIRE_DAMAGE_REMEDIATION_DETAILS,
      labelText: 'Please provide details on remediation work performed.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FIREARMS_SALES]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspQuestion.FIREARMS_SALES,
      nameOfFormControl: LmCpspQuestion.FIREARMS_SALES,
      labelText: "What are the insured's total sales from firearms and ammunition?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FIREPLACES_OR_WOODSTOVES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FIREPLACES_OR_WOODSTOVES,
      nameOfFormControl: LmCpspQuestion.FIREPLACES_OR_WOODSTOVES,
      labelText: 'Do any habitational units have fireplaces or woodstoves?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FIREWALL_AND_SELF_CLOSING_FIRE_DOORS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FIREWALL_AND_SELF_CLOSING_FIRE_DOORS,
      nameOfFormControl: LmCpspQuestion.FIREWALL_AND_SELF_CLOSING_FIRE_DOORS,
      labelText:
        'Are all process areas separated from storage areas by firewalls and self-closing doors?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FLAMMABLE_FLUID_STORAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FLAMMABLE_FLUID_STORAGE,
      nameOfFormControl: LmCpspQuestion.FLAMMABLE_FLUID_STORAGE,
      labelText: 'Does the insured store all flammable fluids in U.L. listed safety containers?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FOOD_PREP_QUESTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FOOD_PREP_QUESTION,
      nameOfFormControl: LmCpspQuestion.FOOD_PREP_QUESTION,
      labelText:
        'Do the operations of any of the building occupants involve food preparation using other than residential ovens or microwave ovens?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FOOD_TRUCK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.FOOD_TRUCK,
      nameOfFormControl: LmCpspQuestion.FOOD_TRUCK,
      labelText:
        'Does the insured have any mobile food operations (i.e. food trucks, trailers or carts)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FOOD_TRUCK_PERCENTAGE]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.FOOD_TRUCK_PERCENTAGE,
      nameOfFormControl: LmCpspQuestion.FOOD_TRUCK_PERCENTAGE,
      labelText: 'What percentage of total receipts are generated from the truck/cart?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.FORM_OF_BUSINESS]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.FORM_OF_BUSINESS,
      nameOfFormControl: LmCpspQuestion.FORM_OF_BUSINESS,
      labelText: 'Form of Business',
      options: {
        AS: 'Association',
        CP: 'Corporation',
        IN: 'Individual',
        JV: 'Joint Venture',
        PT: 'Partnership',
        LL: 'Limited Liability Company',
        LY: 'Limited Liability Partnership',
        MU: 'Municipality',
        TR: 'Trust',
        OT: 'Other (Describe)',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GAMBLING_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.GAMBLING_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.GAMBLING_OPERATIONS,
      labelText: 'Are there any gambling operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GASOLINE_SALES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.GASOLINE_SALES,
      nameOfFormControl: LmCpspQuestion.GASOLINE_SALES,
      labelText: "What are the insured's total sales from gasoline?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GENERAL_CONTRACTING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.GENERAL_CONTRACTING,
      nameOfFormControl: LmCpspQuestion.GENERAL_CONTRACTING,
      labelText:
        'Does the insured provide general contracting, project management, or repair service?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GENERAL_CONTRACTING_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.GENERAL_CONTRACTING_DETAILS,
      nameOfFormControl: LmCpspQuestion.GENERAL_CONTRACTING_DETAILS,
      labelText:
        'Please provide details regarding the General Contracting, Project Management, or repair service.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GENERAL_MANAGER_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.GENERAL_MANAGER_IQ,
      nameOfFormControl: LmCpspQuestion.GENERAL_MANAGER_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only regarding business and organizational management. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE]: {
    formDslNode: {
      primitive: 'DROPDOWN_SEARCH',
      inputId: LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
      nameOfFormControl: LmCpspQuestion.GL_CLASSIFICATION_LEVEL_CLASS_CODE,
      labelText: 'Business classification',
      queryMethodName: SearchQueryMethod.LM_GL_CLASS_CODE,
      formatterName: SearchFormatter.LM_GL_CLASS_CODE,
      questionNote:
        "For Lessor's Risk (LRO), please enter the business description of the largest tenant",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION,
      nameOfFormControl: LmCpspQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION,
      labelText: 'Does glass make up more than 50% of the exterior construction?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.GREENHOUSE_PRESENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.GREENHOUSE_PRESENT,
      nameOfFormControl: LmCpspQuestion.GREENHOUSE_PRESENT,
      labelText: 'Is there a greenhouse present at this location?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HABITATION_RISKS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HABITATION_RISKS,
      nameOfFormControl: LmCpspQuestion.HABITATION_RISKS,
      labelText:
        "Are habitation (i.e. apartments, dwellings, condominium or townhouse) exposures more than 10% of risks' operations?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HABITATIONAL_EXPOSURES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HABITATIONAL_EXPOSURES,
      nameOfFormControl: LmCpspQuestion.HABITATIONAL_EXPOSURES,
      labelText:
        'Are there any residential apartments, condo units or dwellings owned, leased or rented to others that were built prior to 1978?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HABITATIONAL_UNITS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HABITATIONAL_UNITS,
      nameOfFormControl: LmCpspQuestion.HABITATIONAL_UNITS,
      labelText: 'Are there any habitational units on premises?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HAS_FIREPLACE_OR_WOOD_STOVE_WORK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HAS_FIREPLACE_OR_WOOD_STOVE_WORK,
      nameOfFormControl: LmCpspQuestion.HAS_FIREPLACE_OR_WOOD_STOVE_WORK,
      labelText: 'Does the insured do any fireplace or wood stove installation?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      nameOfFormControl: LmCpspQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      labelText: 'Have there been losses for the lines of business submitted?',
      subLabelText:
        'Include losses from the current term and three most recent completed policy periods.',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HAZARDOUS_MATERIALS_ABATEMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HAZARDOUS_MATERIALS_ABATEMENT,
      nameOfFormControl: LmCpspQuestion.HAZARDOUS_MATERIALS_ABATEMENT,
      labelText:
        'Does the insured do work in/around hazardous materials abatement such as asbestos, lead-based paint, or PCBs?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HAZARDOUS_PRODUCTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HAZARDOUS_PRODUCTS,
      nameOfFormControl: LmCpspQuestion.HAZARDOUS_PRODUCTS,
      labelText:
        'Does insured manufacture any of the following: toys, aircraft parts, chemicals, pharmaceuticals, motorized vehicle parts, watercraft parts, medical equipment, invasive medical products, railroad or locomotive parts, or oil/gas related products?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HEATING_YEAR]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.HEATING_YEAR,
      nameOfFormControl: LmCpspQuestion.HEATING_YEAR,
      labelText: 'Heating year',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HIGH_HAZ_OCCUPANCIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HIGH_HAZ_OCCUPANCIES,
      nameOfFormControl: LmCpspQuestion.HIGH_HAZ_OCCUPANCIES,
      labelText:
        'Are there any high hazard occupancies (i.e. chemical manufacturing or storage, bars or nightclubs, etc.)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HOLD_HARMLESS_CONTRACT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HOLD_HARMLESS_CONTRACT,
      nameOfFormControl: LmCpspQuestion.HOLD_HARMLESS_CONTRACT,
      labelText:
        'Does the insured always obtain a written contract that includes a hold harmless clause from all subcontractors?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HOSPITAL_WORK_PERCENT]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.HOSPITAL_WORK_PERCENT,
      nameOfFormControl: LmCpspQuestion.HOSPITAL_WORK_PERCENT,
      labelText: "What is the insured's percent of work in or around hospitals?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HOT_WORK_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HOT_WORK_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.HOT_WORK_OPERATIONS,
      labelText:
        'Does the applicant have any work that involves burning, welding, using fire-or spark-producing tools, or that produces a source of ignition?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HOT_WORK_PROGRAM]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HOT_WORK_PROGRAM,
      nameOfFormControl: LmCpspQuestion.HOT_WORK_PROGRAM,
      labelText: 'Is a written Hot Work Safety Program in place?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HOTEL_NUMBER_OF_STORIES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.HOTEL_NUMBER_OF_STORIES,
      nameOfFormControl: LmCpspQuestion.HOTEL_NUMBER_OF_STORIES,
      labelText: 'What is the greatest number of stories for any hotel or motel locations?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.HOURS_OF_OPERATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.HOURS_OF_OPERATION,
      nameOfFormControl: LmCpspQuestion.HOURS_OF_OPERATION,
      labelText: 'Are any operations open 24 hours a day?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INLAND_MARINE]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.INLAND_MARINE,
      nameOfFormControl: LmCpspQuestion.INLAND_MARINE,
      labelText: 'Inland marine',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INS_ADDITIONAL_INS_ON_MFGR_INS_POLICY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.INS_ADDITIONAL_INS_ON_MFGR_INS_POLICY,
      nameOfFormControl: LmCpspQuestion.INS_ADDITIONAL_INS_ON_MFGR_INS_POLICY,
      labelText: "Is the insured an additional insured on the manufacturer's insurance policy?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INSURED_HAVE_BANQUET]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.INSURED_HAVE_BANQUET,
      nameOfFormControl: LmCpspQuestion.INSURED_HAVE_BANQUET,
      labelText: 'Does the insured have banquet facilities?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INSURED_PHONE_NUMBER]: {
    formDslNode: {
      primitive: 'PHONE',
      inputId: LmCpspQuestion.INSURED_PHONE_NUMBER,
      nameOfFormControl: LmCpspQuestion.INSURED_PHONE_NUMBER,
      labelText: 'Insured phone number',
      minLength: '12',
      maxLength: '12',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INSURED_SNOWPLOW_ON_HIGHWAY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.INSURED_SNOWPLOW_ON_HIGHWAY,
      nameOfFormControl: LmCpspQuestion.INSURED_SNOWPLOW_ON_HIGHWAY,
      labelText:
        'Does applicant perform any snow removal or plowing services on public highways, at airports, or from roofs?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INSURED_STORE_PRODUCTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.INSURED_STORE_PRODUCTS,
      nameOfFormControl: LmCpspQuestion.INSURED_STORE_PRODUCTS,
      labelText: 'Does the insured store any products?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE,
      nameOfFormControl: LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE,
      labelText: 'Does the insured work or operate outside their domiciled state?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE_DETAIL]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE_DETAIL,
      nameOfFormControl: LmCpspQuestion.INSURED_WORK_OUTSIDE_STATE_DETAIL,
      labelText:
        "Please provide details on the insured's work or operations outside their domiciled state.",
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INTEREST_PROPERTY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.INTEREST_PROPERTY,
      nameOfFormControl: LmCpspQuestion.INTEREST_PROPERTY,
      labelText: 'Interest',
      options: { Owner: 'OWNER', Tenant: 'TENANT' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.INTERNAL_COMPLAINT_PROCEDURE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.INTERNAL_COMPLAINT_PROCEDURE,
      nameOfFormControl: LmCpspQuestion.INTERNAL_COMPLAINT_PROCEDURE,
      labelText:
        'Does the insured have an internal complaint procedure managed by a designated person responsible for human resource activities?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.JANITORIAL_DEBRIS_CLEAN_UP_PERCENT_REFER]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.JANITORIAL_DEBRIS_CLEAN_UP_PERCENT_REFER,
      nameOfFormControl: LmCpspQuestion.JANITORIAL_DEBRIS_CLEAN_UP_PERCENT_REFER,
      labelText:
        "What percent of the applicant's business is for construction site clean up & debris removal?",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LARGE_SPECIAL_EVENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LARGE_SPECIAL_EVENT,
      nameOfFormControl: LmCpspQuestion.LARGE_SPECIAL_EVENT,
      labelText: 'Any large special events with attendance over 500?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LARGEST_NUMBER_OF_NEW_HOME_STARTS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.LARGEST_NUMBER_OF_NEW_HOME_STARTS,
      nameOfFormControl: LmCpspQuestion.LARGEST_NUMBER_OF_NEW_HOME_STARTS,
      labelText: 'What is the largest number of new home starts in any single subdivision?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LAWN_LANDSCAPE_UNDERGROUND]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LAWN_LANDSCAPE_UNDERGROUND,
      nameOfFormControl: LmCpspQuestion.LAWN_LANDSCAPE_UNDERGROUND,
      labelText:
        'Does the applicant perform any trenching or excavation not associated with the planting of trees, flowers, or shrubs?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LEASED_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.LEASED_DETAILS,
      nameOfFormControl: LmCpspQuestion.LEASED_DETAILS,
      labelText: 'What type of equipment is leased or rented?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LEASED_EQUIPMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LEASED_EQUIPMENT,
      nameOfFormControl: LmCpspQuestion.LEASED_EQUIPMENT,
      labelText: 'Does the insured lease equipment to others?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LEASED_OPERATOR_DETAILS]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.LEASED_OPERATOR_DETAILS,
      nameOfFormControl: LmCpspQuestion.LEASED_OPERATOR_DETAILS,
      labelText: 'Is the equipment leased or rented with or without operators?',
      options: { W: 'With', WO: 'Without' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_100_K]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_100_K,
      nameOfFormControl: LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_100_K,
      labelText:
        'If applicant is building owner, does area of any one building exceed 100,000 square feet or if applicant is tenant, does tenant occupied area exceed 100,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_150_K]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_150_K,
      nameOfFormControl: LmCpspQuestion.LIABILITY_SQUARE_FEET_EXCEED_150_K,
      labelText:
        'If applicant is building owner, does area of any one building exceed 150,000 square feet or if applicant is tenant, does tenant occupied area exceed 150,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LICENSED_FOR_TRADE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LICENSED_FOR_TRADE,
      nameOfFormControl: LmCpspQuestion.LICENSED_FOR_TRADE,
      labelText:
        'Is the applicant licensed by the state for any particular trades, other than handyperson?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIMIT_BI_AND_EE]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspQuestion.LIMIT_BI_AND_EE,
      nameOfFormControl: LmCpspQuestion.LIMIT_BI_AND_EE,
      labelText: 'Business income with extra expense limit',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING,
      nameOfFormControl: LmCpspQuestion.LIMIT_OF_INSURANCE_BUILDING,
      labelText: 'Building limit',
      questionNote:
        'Building limit may be defaulted to Liberty Mutual\'s ITV calculation upon submission. If this default needs to be changed, use <a href="/support/form/change-quote" target="_blank">this form</a> and include documentation showing the new valuation.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY]: {
    formDslNode: {
      primitive: 'MONEY_WITH_DECIMAL',
      inputId: LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY,
      nameOfFormControl: LmCpspQuestion.LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY,
      labelText: 'Business personal property limit',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIMITED_COOKING_QUESTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LIMITED_COOKING_QUESTION,
      nameOfFormControl: LmCpspQuestion.LIMITED_COOKING_QUESTION,
      labelText:
        'Do any of the cooking exposures in the building include grilling, open broiling, deep fat frying, roasting, barbecuing, solid fuel cooking or processes capable of producing grease laden vapors requiring an exhaust system?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
      nameOfFormControl: LmCpspQuestion.LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE,
      labelText: 'Liquor Liability',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIQUOR_PERCENT_OF_SALES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.LIQUOR_PERCENT_OF_SALES,
      nameOfFormControl: LmCpspQuestion.LIQUOR_PERCENT_OF_SALES,
      labelText:
        'Enter liquor receipts as percentage of gross sales (at majority of restaurant locations).',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LIT_COMMON_AREAS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LIT_COMMON_AREAS,
      nameOfFormControl: LmCpspQuestion.LIT_COMMON_AREAS,
      labelText: 'Are common areas adequately lit and maintained?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LITIGATION_INVOLVEMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LITIGATION_INVOLVEMENT,
      nameOfFormControl: LmCpspQuestion.LITIGATION_INVOLVEMENT,
      labelText:
        "Has the applicant been involved in any loss or litigation regarding poor workmanship, construction defect, water intrusion, mold or fungi? (Work performed by the insured or insured's subcontractors)",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LOCKS_CHANGED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.LOCKS_CHANGED,
      nameOfFormControl: LmCpspQuestion.LOCKS_CHANGED,
      labelText: 'Are locks changed with each new tenant?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LOSSES]: {
    formDslNode: {
      primitive: 'NODE_ARRAY',
      inputId: LmCpspQuestion.LOSSES,
      prefix: 'loss',
      nameOfFormControl: LmCpspQuestion.LOSSES,
      required: true,
      children: [lmCpspLossGroupNodes()],
      childDivCssClass: 'multi-form-item js-lm-bop-loss-group',
      childFooterButtons: [
        {
          primitive: 'BUTTON',
          buttonText: 'Remove',
          methodName: LmButtonFunctionName.REMOVE_LOSS_GROUP,
          cssClass: 'button button__small button__secondary js-remove-loss-group-button',
        },
      ],
      minChildrenToShowChildButtons: 1,
      footerButtons: [
        {
          primitive: 'BUTTON',
          buttonText: 'Add another loss',
          methodName: LmButtonFunctionName.ADD_LOSS_GROUP,
          cssClass:
            'button button__secondary button__full-width button__no-margin js-add-loss-group-button',
        },
      ],
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LP_GAS_WORK_PERCENT]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.LP_GAS_WORK_PERCENT,
      nameOfFormControl: LmCpspQuestion.LP_GAS_WORK_PERCENT,
      labelText: 'Indicate receipts for work related to LP and/or natural gas.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.LP_GAS_WORK_PERCENT_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.LP_GAS_WORK_PERCENT_DETAILS,
      nameOfFormControl: LmCpspQuestion.LP_GAS_WORK_PERCENT_DETAILS,
      labelText: 'Give details about LP gas related work.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MA_TENANT_RELOCATION_EXPENSE_COVERAGE_NUMBER_OF_RENTED_LIVING_QUARTERS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId:
        LmCpspQuestion.MA_TENANT_RELOCATION_EXPENSE_COVERAGE_NUMBER_OF_RENTED_LIVING_QUARTERS,
      nameOfFormControl:
        LmCpspQuestion.MA_TENANT_RELOCATION_EXPENSE_COVERAGE_NUMBER_OF_RENTED_LIVING_QUARTERS,
      labelText: 'MA tenant relocation expense coverage - number of rented living qduarters',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MANUFACTURING_ACTIVITY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MANUFACTURING_ACTIVITY,
      nameOfFormControl: LmCpspQuestion.MANUFACTURING_ACTIVITY,
      labelText:
        'Is the insured involved in the manufacturing, mixing, relabeling or repackaging of products?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MANUFACTURING_ACTIVITY_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.MANUFACTURING_ACTIVITY_DETAILS,
      nameOfFormControl: LmCpspQuestion.MANUFACTURING_ACTIVITY_DETAILS,
      labelText:
        'Please provide details regarding the manufacturing, mixing, relabeling or repackaging of products.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MARIJUANA_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MARIJUANA_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.MARIJUANA_OPERATIONS,
      labelText:
        'Are any of the insureds businesses or tenants involved in growing, storing, selling, dispensing medical or recreational marijuana?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MARKETING_ADVERTISING_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MARKETING_ADVERTISING_IQ,
      nameOfFormControl: LmCpspQuestion.MARKETING_ADVERTISING_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only for promoting customers' products and/or services, including advertising and marketing strategies. Advertising agencies and marketing firms are not eligible. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MEDICAL_EQUIP_OVER_500_DESC]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.MEDICAL_EQUIP_OVER_500_DESC,
      nameOfFormControl: LmCpspQuestion.MEDICAL_EQUIP_OVER_500_DESC,
      labelText:
        'Please give a description of all medical equipment (exceeding $500,000) and its value.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MEDICAL_GASES_WORK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MEDICAL_GASES_WORK,
      nameOfFormControl: LmCpspQuestion.MEDICAL_GASES_WORK,
      labelText: 'Any work done with medical gases for hospitals, labs, or medical facilities?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MEDICAL_MARIJUANA_SALES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MEDICAL_MARIJUANA_SALES,
      nameOfFormControl: LmCpspQuestion.MEDICAL_MARIJUANA_SALES,
      labelText:
        "Does the insured's operations involve growing, storing, selling, dispensing, manufacturing/processing or otherwise providing access to medically-prescribed or recreational marijuana?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MEDICAL_OFFICE_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MEDICAL_OFFICE_IQ,
      nameOfFormControl: LmCpspQuestion.MEDICAL_OFFICE_IQ,
      labelText:
        "The operations of the applicant consist of providing advice related to the development of effective medical office procedures and processes for effective documentation and coding, medical records management, employee management, insurance coordination, etc. to achieve medical office management best practices and meets compliance requirements. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MERCHANDISE_IMPORTED_DIRECTLY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MERCHANDISE_IMPORTED_DIRECTLY,
      nameOfFormControl: LmCpspQuestion.MERCHANDISE_IMPORTED_DIRECTLY,
      labelText: 'Does the insured import any merchandise directly from the manufacturer?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MINORS_LIQUOR_SALE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MINORS_LIQUOR_SALE,
      nameOfFormControl: LmCpspQuestion.MINORS_LIQUOR_SALE,
      labelText:
        'Is there a written policy regarding non-service to minors and obviously intoxicated persons?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MIXING_OR_COMPOUNDING_DRUGS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MIXING_OR_COMPOUNDING_DRUGS,
      nameOfFormControl: LmCpspQuestion.MIXING_OR_COMPOUNDING_DRUGS,
      labelText:
        'Does the applicant do any compounding or mixing of drugs? (Note: The practice of adding syrups or other flavoring agents that do not add any medicinal value to medications is not considered "compounding or mixing of drugs")',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MOLESTATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MOLESTATION,
      nameOfFormControl: LmCpspQuestion.MOLESTATION,
      labelText:
        'Has the insured had any molestation/abuse or counseling professional lawsuits or losses?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MOLESTATION_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.MOLESTATION_DETAILS,
      nameOfFormControl: LmCpspQuestion.MOLESTATION_DETAILS,
      labelText:
        'Please give details of any molestation/abuse or counseling professional lawsuits or losses.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MOTORCYCLE_REPAIR]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MOTORCYCLE_REPAIR,
      nameOfFormControl: LmCpspQuestion.MOTORCYCLE_REPAIR,
      labelText: 'Does the insured have any motorcycle repair?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.MOTORIZED_CONVEYANCE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.MOTORIZED_CONVEYANCE,
      nameOfFormControl: LmCpspQuestion.MOTORIZED_CONVEYANCE,
      labelText:
        'Any sale of any type of motorized conveyance including ATVs, motorbikes, or scooters?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES,
      nameOfFormControl: LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES,
      labelText:
        'Are there any other business interests or activities of the named insured that are not identified or scheduled on this policy?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS,
      nameOfFormControl: LmCpspQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS,
      labelText:
        'Please provide details regarding other business interests or activities of the named insured that are not Insured',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NETWORK_SECURITY]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.NETWORK_SECURITY,
      nameOfFormControl: LmCpspQuestion.NETWORK_SECURITY,
      labelText: 'Network Security',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NETWORK_SECURITY_LIMIT]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.NETWORK_SECURITY_LIMIT,
      nameOfFormControl: LmCpspQuestion.NETWORK_SECURITY_LIMIT,
      labelText: 'Network Security Limit',
      options: {
        '50000': '$50,000',
        '100000': '$100,000',
        '250000': '$250,000',
        '500000': '$500,000',
        '1000000': '$1,000,000',
      },
      required: true,
    },
    valueType: 'DYNAMIC_CONFIG',
    valueData: [
      {
        state: { display: 'IN', values: ['MT', 'NH', 'NY'] },
        nodeValue: {
          options: {
            '100000': '$100,000',
            '250000': '$250,000',
            '500000': '$500,000',
            '1000000': '$1,000,000',
          },
        },
      },
    ],
  },
  [LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS,
      nameOfFormControl: LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS,
      labelText: 'Are any new products planned or being researched?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS_DETAILS,
      nameOfFormControl: LmCpspQuestion.NEW_PLANNED_OR_RESEARCHED_PRODUCTS_DETAILS,
      labelText: 'Please provide details regarding the planned new products.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION,
      nameOfFormControl: LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION,
      labelText:
        'Are any of the following operations performed on any type of residential building: Siding, window or door installation, insulation, roofing, waterproofing, foundations, construction of additions to residences including sunrooms or decks? (Includes: single or multi-family dwellings, apartments, townhomes, condominiums, or any mixed-use structures with a residential component.)',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION_DETAILS,
      nameOfFormControl: LmCpspQuestion.NEW_RESIDENTAIL_CONSRUCTION_DETAILS,
      labelText: 'Please provide details on the new residential construction.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS,
      nameOfFormControl: LmCpspQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS,
      labelText:
        'Has the insured had 2 or more non-pay cancellations or 2 or more non-compliant audits in the last 3 years?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NONGARAGE_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.NONGARAGE_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.NONGARAGE_OPERATIONS,
      labelText: 'Are there any non-garage operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NONGARAGE_OPERATIONS_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.NONGARAGE_OPERATIONS_DETAILS,
      nameOfFormControl: LmCpspQuestion.NONGARAGE_OPERATIONS_DETAILS,
      labelText: 'Please provide details of the non-garage operations.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NP_FUND_RAISING_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.NP_FUND_RAISING_IQ,
      nameOfFormControl: LmCpspQuestion.NP_FUND_RAISING_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only regarding fund raising for non-profit organizations. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NUMBER_OF_GAMING_MACHINES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.NUMBER_OF_GAMING_MACHINES,
      nameOfFormControl: LmCpspQuestion.NUMBER_OF_GAMING_MACHINES,
      labelText: 'What is the maximum number of gaming machines at a single location?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.NUMBER_OF_STORIES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.NUMBER_OF_STORIES,
      nameOfFormControl: LmCpspQuestion.NUMBER_OF_STORIES,
      labelText: 'Number of stories',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OCCUPANCY_QUESTION_MS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OCCUPANCY_QUESTION_MS,
      nameOfFormControl: LmCpspQuestion.OCCUPANCY_QUESTION_MS,
      labelText: 'Does this building contain one or more of the following occupancies?',
      ul: {
        li: [
          'Auto body or collision shops with spray painting.',
          'Clubs or lodges with cooking, except snack warmers or other warming devices if not open to the public.',
          'Commercial cleaning or laundry occupancy (including launderettes).',
          'Occupancies with manufacturing or processing operations (such as upholstering or woodworking).',
          'Occupancies where the principal operation is spray painting.',
          'Occupancies where the principal materials stored or sold are: Ammunition; Chemicals, Acids, Gases or Oils (including Flammable Liquids); Fibers (Cotton, Hay, Hemp or Similar Bulk Fibers); Fireworks; Waste or Reclaimed Materials.',
          'Printing Shops, other than office services.',
          'Restaurants or other occupancies with commercial cooking (not including use of snack warmers or other warming devices) open to the public (Does not apply if building construction is Fire Resistive).',
        ],
      },
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OCCUPANCY_QUESTION_WA]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OCCUPANCY_QUESTION_WA,
      nameOfFormControl: LmCpspQuestion.OCCUPANCY_QUESTION_WA,
      labelText: 'Does this building contain one or more of the following occupancies?',
      ul: {
        li: [
          'Auto repair or service shops - major engine repair or body repair with spray painting.',
          'Clubs, Auditoriums, Halls with cooking or other occupancies operating for the purpose of serving meals or with cooking facilities operated in connection with regularly scheduled meal service - unless the definition of Limited Cooking is met.',
          'Occupancies with manufacturing (except Bakeries with Baking on Premises - CSP 2200, Beverage Bottlers excl. Alcoholic Beverages - CSP 2350, Distilleries and Wineries - CSP 2459, Textile Mill Products - CSP 2800, Leather and Leather Products - CSP 3409 or Printing - CSP 4809 subject to limitations), or processing operations such as upholstering or woodworking and auxiliary buildings on the same premises.',
          'Occupancies where the principal operation is spray painting.',
          'Restaurants, Clubs or other occupancies with commercial cooking (unless the definition of Limited Cooking is met, and not including use of snack warmers or other warming devices) open to the public."',
        ],
      },
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OCCUPANCY_RATE_1]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.OCCUPANCY_RATE_1,
      nameOfFormControl: LmCpspQuestion.OCCUPANCY_RATE_1,
      labelText:
        'What is the average guest room occupancy rate? [average occupancy rate = annual number of guest rooms sold/(number of guest rooms X 365 days)]',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OCCUPANCY_TYPE_CATEGORY_PROPERTY]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.OCCUPANCY_TYPE_CATEGORY_PROPERTY,
      nameOfFormControl: LmCpspQuestion.OCCUPANCY_TYPE_CATEGORY_PROPERTY,
      labelText: 'Occupancy type category',
      options: {
        Lodging: 'Lodging',
        Offices: 'Offices',
        Mercantile: 'Mercantile',
        'Professional Services': 'Professional Services',
        'Public Services': 'Public Services',
        Services: 'Services',
        'Industrial / Processes': 'Industrial / Processes',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OCCUPANCY_TYPE_PROPERTY]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.OCCUPANCY_TYPE_PROPERTY,
      nameOfFormControl: LmCpspQuestion.OCCUPANCY_TYPE_PROPERTY,
      labelText: 'Occupancy type',
      options: {},
      required: true,
    },
    valueType: 'DYNAMIC_FUNC',
    valueDependencyFunc: LmCpspComplexValueEvaluator.OCCUPANCY_TYPE_OPTIONS,
  },
  [LmCpspQuestion.OFF_PREMISES_PROPERTY_DAMAGE_INCLUDING_CARE_CUSTODY_OR_CONTROL]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.OFF_PREMISES_PROPERTY_DAMAGE_INCLUDING_CARE_CUSTODY_OR_CONTROL,
      nameOfFormControl:
        LmCpspQuestion.OFF_PREMISES_PROPERTY_DAMAGE_INCLUDING_CARE_CUSTODY_OR_CONTROL,
      labelText: 'Off Premises Property Damage Including Care, Custody Or Control',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OFFICE_ADMIN_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OFFICE_ADMIN_IQ,
      nameOfFormControl: LmCpspQuestion.OFFICE_ADMIN_IQ,
      labelText:
        "The operations of the applicant consist of providing advice for completing general office tasks. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OP_CHANGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OP_CHANGE,
      nameOfFormControl: LmCpspQuestion.OP_CHANGE,
      labelText: 'Have any operations been sold, acquired or discontinued in the last 5 years?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OPEN_AFTER_MIDNIGHT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OPEN_AFTER_MIDNIGHT,
      nameOfFormControl: LmCpspQuestion.OPEN_AFTER_MIDNIGHT,
      labelText: 'Do the hours of operation extend beyond midnight?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT,
      nameOfFormControl: LmCpspQuestion.OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT,
      labelText:
        'Does the insured perform any operations on airport facilities such as runway/tarmac work or anything to do with emergency/warning equipment?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING,
      nameOfFormControl: LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING,
      labelText: 'Do any operations include blasting or tunneling?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING_DETAILS,
      nameOfFormControl: LmCpspQuestion.OPTS_INCLUDE_BLASTING_OR_TUNNELING_DETAILS,
      labelText: 'Please give details of blasting or tunneling operations.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ORGANIZATIONAL_DEVELOPMENT_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ORGANIZATIONAL_DEVELOPMENT_IQ,
      nameOfFormControl: LmCpspQuestion.ORGANIZATIONAL_DEVELOPMENT_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only regarding organization development. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OSHA_APPROVAL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OSHA_APPROVAL,
      nameOfFormControl: LmCpspQuestion.OSHA_APPROVAL,
      labelText:
        'Does applicant use OSHA - approved trenching safeguards and contact local public utilities (eg. One Call) for existing underground hazards prior to excavation?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OTHER_BUSINESS_INSD_ELSEWHERE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OTHER_BUSINESS_INSD_ELSEWHERE,
      nameOfFormControl: LmCpspQuestion.OTHER_BUSINESS_INSD_ELSEWHERE,
      labelText: 'Is other business a separate legal entity insured elsewhere?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OTHER_OFFICE_TYPES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OTHER_OFFICE_TYPES,
      nameOfFormControl: LmCpspQuestion.OTHER_OFFICE_TYPES,
      labelText:
        'Do the office operations reflect that of an insurance appraisal or inspection company, cemetery, office condominium (unit owner), engineer or architect, insurance agent, interior decorator, lawyer, real estate agent, stockbroker or ticket agency?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OTHER_OFFICE_TYPES_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.OTHER_OFFICE_TYPES_DETAILS,
      nameOfFormControl: LmCpspQuestion.OTHER_OFFICE_TYPES_DETAILS,
      labelText: "Please describe the nature of the insured's business.",
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OTHER_PROD_SOLD_UNDER_APPLICANTS_LABEL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OTHER_PROD_SOLD_UNDER_APPLICANTS_LABEL,
      nameOfFormControl: LmCpspQuestion.OTHER_PROD_SOLD_UNDER_APPLICANTS_LABEL,
      labelText: "Are products of others sold relabeled or repackaged under the applicant's label?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OTHER_THAN_MINOR_AUTO_REPAIR]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OTHER_THAN_MINOR_AUTO_REPAIR,
      nameOfFormControl: LmCpspQuestion.OTHER_THAN_MINOR_AUTO_REPAIR,
      labelText: "Does the insured's operations include major engine repair or body repair work?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.OWN_LEASE_DOCKS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.OWN_LEASE_DOCKS,
      nameOfFormControl: LmCpspQuestion.OWN_LEASE_DOCKS,
      labelText: 'Does applicant own, operate or lease docks?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PAINTING_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PAINTING_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.PAINTING_OPERATIONS,
      labelText: 'Does applicant operations include any painting of bridges, towers or tanks?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PARTY_PLANNER_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PARTY_PLANNER_IQ,
      nameOfFormControl: LmCpspQuestion.PARTY_PLANNER_IQ,
      labelText:
        "The operations of the applicant consist of providing advice on overall party plan for social, family or neighborhood events. Plans include suggestions for food menus, catering services, banquet halls, decorations, musical entertainment, etc. These services are not hired by the applicant directly. Operations providing planning services for events that include animal rides, amusement games/rides or large events such as conventions are not eligible. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PERFORMING_REPAIRS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PERFORMING_REPAIRS,
      nameOfFormControl: LmCpspQuestion.PERFORMING_REPAIRS,
      labelText: 'Does applicant perform any repairing or renting of ladders or scaffolding?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PERFORMS_RADON_MEASUREMENTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PERFORMS_RADON_MEASUREMENTS,
      nameOfFormControl: LmCpspQuestion.PERFORMS_RADON_MEASUREMENTS,
      labelText: 'Does the insured perform radon measurement or radon mitigation?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PERSONAL_SHOPPER_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PERSONAL_SHOPPER_IQ,
      nameOfFormControl: LmCpspQuestion.PERSONAL_SHOPPER_IQ,
      labelText:
        "The operations of the applicant consist of providing advice for selecting and purchasing clothing and other merchandise. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PEST_RODENT_CONTROL_PGM]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PEST_RODENT_CONTROL_PGM,
      nameOfFormControl: LmCpspQuestion.PEST_RODENT_CONTROL_PGM,
      labelText: 'Is there a pest or rodent control program?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PESTICIDE_CERTIFICATE_OF_INSURANCE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PESTICIDE_CERTIFICATE_OF_INSURANCE,
      nameOfFormControl: LmCpspQuestion.PESTICIDE_CERTIFICATE_OF_INSURANCE,
      labelText:
        'Are certificates of insurance required to confirm that the contractor has liability coverage in force for pesticide and fertilizer applications?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PESTICIDES_LICENSED_CONTRACTOR]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PESTICIDES_LICENSED_CONTRACTOR,
      nameOfFormControl: LmCpspQuestion.PESTICIDES_LICENSED_CONTRACTOR,
      labelText:
        'Are the pesticides, herbicides, fungicides and fertilizers applied by a licensed independent contractor?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PESTICIDES_SUPERVISION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PESTICIDES_SUPERVISION,
      nameOfFormControl: LmCpspQuestion.PESTICIDES_SUPERVISION,
      labelText:
        'Are the pesticides, herbicides, fungicides or fertilizers applied by employees under direct supervision of a licensed/trained applicator?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PESTICIDES_USAGE]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PESTICIDES_USAGE,
      nameOfFormControl: LmCpspQuestion.PESTICIDES_USAGE,
      labelText:
        'Provide details of the use of any pesticides, herbicides, fungicides, and/or fertilizers including type used, amount kept on premises and record of application.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PET_RESCUE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PET_RESCUE,
      nameOfFormControl: LmCpspQuestion.PET_RESCUE,
      labelText:
        'Any operations involving pet rescue, adoptions or shelters for which the insured does not have a risk transfer to a separate organization?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PHF_FERTILIZER]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PHF_FERTILIZER,
      nameOfFormControl: LmCpspQuestion.PHF_FERTILIZER,
      labelText:
        'Does the applicant apply any pesticides, herbicides, fungicides and/or fertilizer?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PIPING_REPAIRS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PIPING_REPAIRS,
      nameOfFormControl: LmCpspQuestion.PIPING_REPAIRS,
      labelText: 'Does the insured install or repair process piping or dust collection systems?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PIPING_REPAIRS_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PIPING_REPAIRS_DETAILS,
      nameOfFormControl: LmCpspQuestion.PIPING_REPAIRS_DETAILS,
      labelText: 'Give details on work involving process piping or dust collection systems.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PLUMBING_YEAR]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.PLUMBING_YEAR,
      nameOfFormControl: LmCpspQuestion.PLUMBING_YEAR,
      labelText: 'Plumbing year',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PNEUMATIC_PLUMBING_SYSTEM]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PNEUMATIC_PLUMBING_SYSTEM,
      nameOfFormControl: LmCpspQuestion.PNEUMATIC_PLUMBING_SYSTEM,
      labelText:
        'Any work on buildings with more than 10 stories or with pneumatic plumbing systems?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRIMARY_RISK_STATE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.PRIMARY_RISK_STATE,
      nameOfFormControl: LmCpspQuestion.PRIMARY_RISK_STATE,
      labelText: 'Base state',
      questionNote: 'Business location address must be in the base state',
      options: {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District Of Columbia',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRIOR_COVERAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PRIOR_COVERAGE,
      nameOfFormControl: LmCpspQuestion.PRIOR_COVERAGE,
      labelText:
        'Was insurance coverage in force for the same exposures for the prior policy period (excluding new ventures)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRIOR_COVERAGE_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PRIOR_COVERAGE_DETAILS,
      nameOfFormControl: LmCpspQuestion.PRIOR_COVERAGE_DETAILS,
      labelText: 'Please Describe',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRIOR_COVERAGE_REASON]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.PRIOR_COVERAGE_REASON,
      nameOfFormControl: LmCpspQuestion.PRIOR_COVERAGE_REASON,
      labelText: 'Please choose a specific reason why',
      options: {
        'New acquisition for the insured': 'New acquisition for the insured',
        'No prior operations (business entity formed but no work performed or products made)':
          'No prior operations (business entity formed but no work performed or products made)',
        'No prior employees (WC)': 'No prior employees (WC)',
        'No prior owned autos': 'No prior owned autos',
        'New venture within last 3 months': 'New venture within last 3 months',
        'None of the above apply': 'None of the above apply',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCT_DESCRIPTION]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PRODUCT_DESCRIPTION,
      nameOfFormControl: LmCpspQuestion.PRODUCT_DESCRIPTION,
      labelText:
        "Provide a detailed description of the applicant's products and their intended use.",
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCT_DISCONTINUED_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PRODUCT_DISCONTINUED_DETAILS,
      nameOfFormControl: LmCpspQuestion.PRODUCT_DISCONTINUED_DETAILS,
      labelText: 'Please provide details and dates regarding the discontinued products.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCT_LAWSUIT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PRODUCT_LAWSUIT,
      nameOfFormControl: LmCpspQuestion.PRODUCT_LAWSUIT,
      labelText:
        'Has the applicant been named in a product liability lawsuit in the last ten years?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCTS_CUSTOMER_SPECIFICATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PRODUCTS_CUSTOMER_SPECIFICATIONS,
      nameOfFormControl: LmCpspQuestion.PRODUCTS_CUSTOMER_SPECIFICATIONS,
      labelText: "Are components made to the customers' specifications?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCTS_DISCONTINUED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PRODUCTS_DISCONTINUED,
      nameOfFormControl: LmCpspQuestion.PRODUCTS_DISCONTINUED,
      labelText: 'Have any products been discontinued?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL,
      nameOfFormControl: LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL,
      labelText: "Are products manufactured/sold under the applicant's label?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL_DETAILS,
      nameOfFormControl: LmCpspQuestion.PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL_DETAILS,
      labelText: 'Please give details of products manufactured/sold under the applicants label.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PRODUCTS_STORED]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PRODUCTS_STORED,
      nameOfFormControl: LmCpspQuestion.PRODUCTS_STORED,
      labelText: 'Please enter amount and type of product stored.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PROFESSIONAL_LIABILITY_COVERAGE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PROFESSIONAL_LIABILITY_COVERAGE,
      nameOfFormControl: LmCpspQuestion.PROFESSIONAL_LIABILITY_COVERAGE,
      labelText: 'Is professional liability coverage in force with another carrier?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PROHIBITED_SMOKING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PROHIBITED_SMOKING,
      nameOfFormControl: LmCpspQuestion.PROHIBITED_SMOKING,
      labelText:
        'Does the insured display "Smoking Prohibited" or "No Smoking" signs in all areas where fuels are stored or dispensed?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PROPERTIES_LEASED_TO_OTHERS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PROPERTIES_LEASED_TO_OTHERS,
      nameOfFormControl: LmCpspQuestion.PROPERTIES_LEASED_TO_OTHERS,
      labelText: 'Any properties leased to others?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION,
      nameOfFormControl: LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION,
      labelText: 'Does the insured have owned properties under construction?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION_DETAILS,
      nameOfFormControl: LmCpspQuestion.PROPERTIES_UNDER_CONSTRUCTION_DETAILS,
      labelText:
        'Please provide details regarding the General Contracting, Project Management, or repair service.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE]: {
    // CSP
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
      nameOfFormControl: LmCpspQuestion.PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE,
      labelText: 'Property classification',
      options: {},
      required: true,
    },
    valueType: 'DYNAMIC_FUNC',
    valueDependencyFunc: LmCpspComplexValueEvaluator.PROPERTY_CLASS_CODE_OPTIONS,
  },
  [LmCpspQuestion.PROPERTY_DEDUCTIBLE]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.PROPERTY_DEDUCTIBLE,
      nameOfFormControl: LmCpspQuestion.PROPERTY_DEDUCTIBLE,
      labelText: 'Property deductible',
      options: {
        '500': '$500',
        '1000': '$1,000',
        '2500': '$2,500',
        '5000': '$5,000',
        '10000': '$10,000',
        '25000': '$25,000',
        '50000': '$50,000',
        '75000': '$75,000',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.PROVIDING_TRAINING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.PROVIDING_TRAINING,
      nameOfFormControl: LmCpspQuestion.PROVIDING_TRAINING,
      labelText:
        'Does applicant provide any classes, training, lessons or professional instruction of any kind?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.QUALITY_CONTROL_PROCEDURES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.QUALITY_CONTROL_PROCEDURES,
      nameOfFormControl: LmCpspQuestion.QUALITY_CONTROL_PROCEDURES,
      labelText: 'Are there written quality control procedures in place?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RARE_ANIMAL_TREATMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RARE_ANIMAL_TREATMENT,
      nameOfFormControl: LmCpspQuestion.RARE_ANIMAL_TREATMENT,
      labelText:
        'Are any professional services provided to animals used or bred for: racing, show, circus, rodeos or any other entertainment purposes?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RECORDS_MANAGEMENT_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RECORDS_MANAGEMENT_IQ,
      nameOfFormControl: LmCpspQuestion.RECORDS_MANAGEMENT_IQ,
      labelText:
        "The operations of the applicant consist of providing advice related to the assessment and development of an effective records management strategy that achieves records management best practices and meets compliance requirements. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.REFURBISHED_EQUIPMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.REFURBISHED_EQUIPMENT,
      nameOfFormControl: LmCpspQuestion.REFURBISHED_EQUIPMENT,
      labelText: 'Does the insured sell used and/or refurbished goods or equipment?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.REFURBISHED_EQUIPMENT_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.REFURBISHED_EQUIPMENT_DETAILS,
      nameOfFormControl: LmCpspQuestion.REFURBISHED_EQUIPMENT_DETAILS,
      labelText: 'Please provide details regarding the sale of used and/or refurbished equipment.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RELIGIOUS_LIMITATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RELIGIOUS_LIMITATIONS,
      nameOfFormControl: LmCpspQuestion.RELIGIOUS_LIMITATIONS,
      labelText: 'Are all buildings limited to religious, church administration or parsonage use?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RENOVATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RENOVATION,
      nameOfFormControl: LmCpspQuestion.RENOVATION,
      labelText: 'Are any premise(s) under any renovation?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RENOVATION_BY_EMP]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RENOVATION_BY_EMP,
      nameOfFormControl: LmCpspQuestion.RENOVATION_BY_EMP,
      labelText:
        'Are those performing maintenance, repair or renovations employed by the applicant?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RENT_MEDICAL_EQUIPMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RENT_MEDICAL_EQUIPMENT,
      nameOfFormControl: LmCpspQuestion.RENT_MEDICAL_EQUIPMENT,
      labelText: 'Does insured rent medical equipment to customers?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.REPAIR_HEAVY_TRUCKS_TRACTORS_R_VS_MOBILE_HOMES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.REPAIR_HEAVY_TRUCKS_TRACTORS_R_VS_MOBILE_HOMES,
      nameOfFormControl: LmCpspQuestion.REPAIR_HEAVY_TRUCKS_TRACTORS_R_VS_MOBILE_HOMES,
      labelText:
        'Does the insured perform any repair work on heavy weight trucks greater than 20,001 pounds, race cars, farm equipment or recreational vehicles (including ATVs, campers, pop-up trailers, or mobile/motor homes)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.REPAIR_INSTALL_PASSENGER_VEHICLES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.REPAIR_INSTALL_PASSENGER_VEHICLES,
      nameOfFormControl: LmCpspQuestion.REPAIR_INSTALL_PASSENGER_VEHICLES,
      labelText:
        'Do operations involve installation and/or repair of tires for vehicles other than light trucks and private passenger autos?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.REQUIRE_WRITTEN_CONTRACTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.REQUIRE_WRITTEN_CONTRACTS,
      nameOfFormControl: LmCpspQuestion.REQUIRE_WRITTEN_CONTRACTS,
      labelText:
        'Does the insured require written contracts with tenants, to include hold harmless & indemnity agreements (where permitted by law)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.REQUIRED_APPLICATORS_LICENSE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.REQUIRED_APPLICATORS_LICENSE,
      nameOfFormControl: LmCpspQuestion.REQUIRED_APPLICATORS_LICENSE,
      labelText:
        'Does the contractor applying the pesticides, herbicides, fungicides and fertilizers hold the required applicators license?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE,
      nameOfFormControl: LmCpspQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE,
      labelText:
        'Are the hoods, ductwork and flues cleaned by an outside service at least quarterly?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RESTORATION_WORK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RESTORATION_WORK,
      nameOfFormControl: LmCpspQuestion.RESTORATION_WORK,
      labelText: 'Does the insured perform any restoration work?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RESTORATION_WORK_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.RESTORATION_WORK_DETAILS,
      nameOfFormControl: LmCpspQuestion.RESTORATION_WORK_DETAILS,
      labelText: 'Please provide details on restoration work performed.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RISK_CURRENTLY_OR_PAST_10_YEARS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RISK_CURRENTLY_OR_PAST_10_YEARS,
      nameOfFormControl: LmCpspQuestion.RISK_CURRENTLY_OR_PAST_10_YEARS,
      labelText:
        'Does the risk currently or in the past ten years done any new residential construction?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.RISK_MANAGEMENT_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.RISK_MANAGEMENT_IQ,
      nameOfFormControl: LmCpspQuestion.RISK_MANAGEMENT_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only regarding risk management. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ROAD_WORK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ROAD_WORK,
      nameOfFormControl: LmCpspQuestion.ROAD_WORK,
      labelText: 'Any street, road, or bridge work done?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ROAD_WORK_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.ROAD_WORK_DETAILS,
      nameOfFormControl: LmCpspQuestion.ROAD_WORK_DETAILS,
      labelText: 'Give details of street, road, or bridge work.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ROOF_CONSTRUCTION_PROPERTY]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.ROOF_CONSTRUCTION_PROPERTY,
      nameOfFormControl: LmCpspQuestion.ROOF_CONSTRUCTION_PROPERTY,
      labelText: 'Roof construction',
      required: true,
      options: {
        ASPHS: 'Asphalt Shingles',
        TARGRB: 'Built Up (with gravel)',
        BLTXGRVL: 'Built Up Without Gravel (Smooth Surface)',
        CLAYCONC: 'Clay or Concrete Tile',
        FOAM: 'Foam (sprayed on)',
        METL: 'Metal',
        MODBITUMEN: 'Modified Bitumen',
        MEMSINGLEBALL: 'Single Ply Membrane (ballasted with smooth stone or paving blocks)',
        SPMSEPDMRUBB: 'Single Ply Membrane (EPDM, Rubber)',
        SPMSPVCTPO: 'Single Ply Membrane (PVC, TPO)',
        WOODSS: 'Wood Shingles/Shakes',
        UNKNOWN: 'Unknown',
      },
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ROOF_INSTALLATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ROOF_INSTALLATION,
      nameOfFormControl: LmCpspQuestion.ROOF_INSTALLATION,
      labelText: 'Does applicant perform any major roof repair or installation of new roofs?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ROOFING_YEAR]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.ROOFING_YEAR,
      nameOfFormControl: LmCpspQuestion.ROOFING_YEAR,
      labelText: 'Roofing year',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ROOM_OFFERED_RATE_1]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.ROOM_OFFERED_RATE_1,
      nameOfFormControl: LmCpspQuestion.ROOM_OFFERED_RATE_1,
      labelText: 'Are any guest rooms offered at hourly, weekly, or monthly rates?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.ROOM_RATE_1]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.ROOM_RATE_1,
      nameOfFormControl: LmCpspQuestion.ROOM_RATE_1,
      labelText:
        'What is the average daily guest room rate? [Average daily room rate = Annual guest room revenue/Annual guest rooms sold]',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SAFETY_PRECAUTIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SAFETY_PRECAUTIONS,
      nameOfFormControl: LmCpspQuestion.SAFETY_PRECAUTIONS,
      labelText: 'Are appropriate safety precautions taken?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SALES_OR_STORAGE_QUESTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SALES_OR_STORAGE_QUESTION,
      nameOfFormControl: LmCpspQuestion.SALES_OR_STORAGE_QUESTION,
      labelText:
        'Is this location used primarily for the sales or storage of any of the following materials: ammunition; chemicals, acids, gases or oils (including flammable liquids); fibers (cotton, hay, hemp or similar bulk fibers); fireworks?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SALES_SERVICE_OPERATIONS]: {
    formDslNode: {
      primitive: 'SELECT',
      inputId: LmCpspQuestion.SALES_SERVICE_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.SALES_SERVICE_OPERATIONS,
      labelText: 'Type of sales/service organization',
      options: {
        'Party Planners/Coordinators': 'Party Planners/Coordinators',
        'Personal Shopper': 'Personal Shopper',
        'Office Administrative Service': 'Office Administrative Service',
        Other: 'Other',
      },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SAND_BLASTING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SAND_BLASTING,
      nameOfFormControl: LmCpspQuestion.SAND_BLASTING,
      labelText: 'Does applicant perform sandblasting?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SECURITY_PERSONNEL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SECURITY_PERSONNEL,
      nameOfFormControl: LmCpspQuestion.SECURITY_PERSONNEL,
      labelText: 'Does the applicant employ security personnel?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SECURITY_SERVICE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SECURITY_SERVICE,
      nameOfFormControl: LmCpspQuestion.SECURITY_SERVICE,
      labelText: 'Does the applicant hire a security service?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SEMINAR_EDUCATOR_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SEMINAR_EDUCATOR_IQ,
      nameOfFormControl: LmCpspQuestion.SEMINAR_EDUCATOR_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only on business administration and management. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SERVE_ALCOHOL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SERVE_ALCOHOL,
      nameOfFormControl: LmCpspQuestion.SERVE_ALCOHOL,
      labelText: 'Does the applicant serve alcohol?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SERVE_ALCOHOL_TOTAL_SALES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.SERVE_ALCOHOL_TOTAL_SALES,
      nameOfFormControl: LmCpspQuestion.SERVE_ALCOHOL_TOTAL_SALES,
      labelText: 'What are the total receipts from the sale of alcohol?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SERVICE_STATION_CAPTURE_CONTROLS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SERVICE_STATION_CAPTURE_CONTROLS,
      nameOfFormControl: LmCpspQuestion.SERVICE_STATION_CAPTURE_CONTROLS,
      labelText: 'Are there adequate controls for monitoring tank leakage?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SERVICE_STATIONS_MISC_OPTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SERVICE_STATIONS_MISC_OPTS,
      nameOfFormControl: LmCpspQuestion.SERVICE_STATIONS_MISC_OPTS,
      labelText:
        'Are there any miscellaneous operations such as car wash facilities, vehicle repairs, welding, tire storage or LPG?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SILK_SCREENING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SILK_SCREENING,
      nameOfFormControl: LmCpspQuestion.SILK_SCREENING,
      labelText: 'Does the insured perform any silkscreening operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SMOKE_ALARMS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SMOKE_ALARMS,
      nameOfFormControl: LmCpspQuestion.SMOKE_ALARMS,
      labelText:
        'Are all habitational units equipped with hardwired smoke detectors with battery backup?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SMOKING_IN_REPAIR_AREAS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SMOKING_IN_REPAIR_AREAS,
      nameOfFormControl: LmCpspQuestion.SMOKING_IN_REPAIR_AREAS,
      labelText: 'Does the insured prohibit smoking in the repair areas?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SNOW_PLOW_OPERATIONS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SNOW_PLOW_OPERATIONS,
      nameOfFormControl: LmCpspQuestion.SNOW_PLOW_OPERATIONS,
      labelText: 'Does the applicant perform any snow plowing operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SPA_SALON_SERVICES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SPA_SALON_SERVICES,
      nameOfFormControl: LmCpspQuestion.SPA_SALON_SERVICES,
      labelText:
        'Does the insured offer any of the following services: tanning, hair implantation, tattoos, wart or mole removal, face lifting, chemical peel, chiropody, training facilities, or weight reduction?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SPRAY_MAN_IND_OPS_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.SPRAY_MAN_IND_OPS_DETAILS,
      nameOfFormControl: LmCpspQuestion.SPRAY_MAN_IND_OPS_DETAILS,
      labelText:
        'Please provide details regarding operations of spray paint, welding, processing, and manufacturing occupants.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SPRAY_PAINT_PROCESSING_MFG_OCCUPANCIES_QUESTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SPRAY_PAINT_PROCESSING_MFG_OCCUPANCIES_QUESTION,
      nameOfFormControl: LmCpspQuestion.SPRAY_PAINT_PROCESSING_MFG_OCCUPANCIES_QUESTION,
      labelText:
        'Do the operations of any of the building occupants involve an auto body or collision shop with spray painting, an occupant in which the principal operation is spray painting or welding, or any industrial and processing/manufacturing operations (e.g. woodworking, metalworking, etc.)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SPRAY_PAINTING_OR_DIP_TANK_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.SPRAY_PAINTING_OR_DIP_TANK_DETAILS,
      nameOfFormControl: LmCpspQuestion.SPRAY_PAINTING_OR_DIP_TANK_DETAILS,
      labelText: 'Give details of spray painting operations.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SPRAY_PAINTING_QUESTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SPRAY_PAINTING_QUESTION,
      nameOfFormControl: LmCpspQuestion.SPRAY_PAINTING_QUESTION,
      labelText: 'Any spray painting operations?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SPRINKLER]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.SPRINKLER,
      nameOfFormControl: LmCpspQuestion.SPRINKLER,
      labelText: 'Percentage of property sprinkler accessible',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SUB_CONTRACTORS_PERCENT]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.SUB_CONTRACTORS_PERCENT,
      nameOfFormControl: LmCpspQuestion.SUB_CONTRACTORS_PERCENT,
      labelText: 'What percent of total receipts are for subcontracted work?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SUBCONTRACTOR_LIMITS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SUBCONTRACTOR_LIMITS,
      nameOfFormControl: LmCpspQuestion.SUBCONTRACTOR_LIMITS,
      labelText:
        "Are subcontractor's limits equal to or greater than insured's primary liability limits?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SURVEYORS_PROFESSIONAL_LIABILITY]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SURVEYORS_PROFESSIONAL_LIABILITY,
      nameOfFormControl: LmCpspQuestion.SURVEYORS_PROFESSIONAL_LIABILITY,
      labelText: 'Does the insured carry professional liability insurance?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SWIMMING_POOL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SWIMMING_POOL,
      nameOfFormControl: LmCpspQuestion.SWIMMING_POOL,
      labelText: 'Does the applicant have a swimming pool or hot tub?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SWIMMING_POOL_DIVING_BOARD]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SWIMMING_POOL_DIVING_BOARD,
      nameOfFormControl: LmCpspQuestion.SWIMMING_POOL_DIVING_BOARD,
      labelText: 'Is there a diving board?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.SWIMMING_POOL_LOCKED_AND_FENCED]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.SWIMMING_POOL_LOCKED_AND_FENCED,
      nameOfFormControl: LmCpspQuestion.SWIMMING_POOL_LOCKED_AND_FENCED,
      labelText: 'Is the pool or hot tub area fenced and locked when not in use?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TABLESIDE_COOKING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TABLESIDE_COOKING,
      nameOfFormControl: LmCpspQuestion.TABLESIDE_COOKING,
      labelText: 'Do insured operations include tableside cooking?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TENANT_CREDIT_QUESTION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TENANT_CREDIT_QUESTION,
      nameOfFormControl: LmCpspQuestion.TENANT_CREDIT_QUESTION,
      labelText:
        "Is the building occupied insured by one of the Liberty Mutual's underwriting companies?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_100_K]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_100_K,
      nameOfFormControl: LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_100_K,
      labelText: 'Does the tenant occupied area exceed 100,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_150_K]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_150_K,
      nameOfFormControl: LmCpspQuestion.TENANT_SQUARE_FEET_EXCEED_150_K,
      labelText: 'Does the tenant occupied area exceed 150,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TIRE_RECAPPING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TIRE_RECAPPING,
      nameOfFormControl: LmCpspQuestion.TIRE_RECAPPING,
      labelText: 'Do operations involve tire recapping or vulcanizing?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TIRE_SALES]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.TIRE_SALES,
      nameOfFormControl: LmCpspQuestion.TIRE_SALES,
      labelText: 'What are the total sales from used tires?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TIRE_STOCK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TIRE_STOCK,
      nameOfFormControl: LmCpspQuestion.TIRE_STOCK,
      labelText: 'Does tire warehouse area exceed 10,000 square feet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TOTAL_LIMIT_CUSTOM_PROTECTOR_PROPERTY_FLOATER]: {
    formDslNode: {
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId: LmCpspQuestion.TOTAL_LIMIT_CUSTOM_PROTECTOR_PROPERTY_FLOATER,
      nameOfFormControl: LmCpspQuestion.TOTAL_LIMIT_CUSTOM_PROTECTOR_PROPERTY_FLOATER,
      labelText: 'Total limit',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS,
      nameOfFormControl: LmCpspQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS,
      labelText: 'Total number of employees at all locations',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_MS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_MS,
      nameOfFormControl: LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_MS,
      labelText:
        'Does the total area occupied by any service occupancies exceed 15% of the total floor area of the building (e.g.: barber shops, beauty shops, circulating libraries, custom dressmakers or tailors, funeral homes, offices, telephone exchanges, private schools or studios)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_WA]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_WA,
      nameOfFormControl: LmCpspQuestion.TOTAL_SERVICE_OCCUPIED_QUESTION_WA,
      labelText:
        'Does the total area occupied by any service occupancies exceed 15% of the total floor area of the building (e.g.: Light Hazard Service occupancies using CSP Class Code 0921, libraries, Tailor Merchants: Tailoring or Dressmaking Establishments - custom With 10 employees or less engaged in custom alterations or custom tailoring of wearing apparel after sale to customer; Funeral Homes or Chapels, Offices - Governmental, or Offices - Non-Governmental)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TOWING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TOWING,
      nameOfFormControl: LmCpspQuestion.TOWING,
      labelText:
        'Do operations involve emergency roadside assistance, towing, or hauling of vehicles that will not be repaired or serviced by the insured?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TRAFFIC_SIGNAL_WORK]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TRAFFIC_SIGNAL_WORK,
      nameOfFormControl: LmCpspQuestion.TRAFFIC_SIGNAL_WORK,
      labelText: 'Is any work done on or around traffic signals (past or present)?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TRAINED_EMPLOYEE_SERVING_ALCOHOL]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TRAINED_EMPLOYEE_SERVING_ALCOHOL,
      nameOfFormControl: LmCpspQuestion.TRAINED_EMPLOYEE_SERVING_ALCOHOL,
      labelText: 'Are all employees serving alcohol properly trained?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TRAINING_LEADERSHIP_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TRAINING_LEADERSHIP_IQ,
      nameOfFormControl: LmCpspQuestion.TRAINING_LEADERSHIP_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only concerning the development of a leadership training curriculum for businesses looking to enhance the leadership qualities of their employees or applicant may also participate in the leadership training classes held in office or classroom settings. Risks that engage in owning or operating in large convention/hotel or meeting facilities are not eligible. No indoor/outdoor physical team building activities or vocational training. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TRAINING_SOFTWARE_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TRAINING_SOFTWARE_IQ,
      nameOfFormControl: LmCpspQuestion.TRAINING_SOFTWARE_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only concerning the type of computer software that best meets the personnel training needs of their customers. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TRENCHING_EXCAVATING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.TRENCHING_EXCAVATING,
      nameOfFormControl: LmCpspQuestion.TRENCHING_EXCAVATING,
      labelText: 'Does the applicant perform trenching or excavation?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.TYPE_OF_LICENSES]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.TYPE_OF_LICENSES,
      nameOfFormControl: LmCpspQuestion.TYPE_OF_LICENSES,
      labelText: 'List type of trade licenses:',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.UL_APPROVED_PAINT_BOOTH]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.UL_APPROVED_PAINT_BOOTH,
      nameOfFormControl: LmCpspQuestion.UL_APPROVED_PAINT_BOOTH,
      labelText: 'Does the insured have a UL approved paint booth?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.UL_APPROVED_PAINT_CABINET]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.UL_APPROVED_PAINT_CABINET,
      nameOfFormControl: LmCpspQuestion.UL_APPROVED_PAINT_CABINET,
      labelText: 'Does the insured store paints supplies in a UL approved cabinet?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.UNDERGROUND_OR_HEIGHTS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.UNDERGROUND_OR_HEIGHTS,
      nameOfFormControl: LmCpspQuestion.UNDERGROUND_OR_HEIGHTS,
      labelText: 'Any work performed underground, above 15 feet, or do they utilize scaffolding?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.UNDERGROUND_OR_HEIGHTS_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.UNDERGROUND_OR_HEIGHTS_DETAILS,
      nameOfFormControl: LmCpspQuestion.UNDERGROUND_OR_HEIGHTS_DETAILS,
      labelText: 'Give details on work done underground or above 15 feet and any scaffolding use.',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.USED_MATERIAL_MERCHANDISE]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.USED_MATERIAL_MERCHANDISE,
      nameOfFormControl: LmCpspQuestion.USED_MATERIAL_MERCHANDISE,
      labelText: 'Does the insured sell second hand or used materials or merchandise?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.VEHICLES_SOLD]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.VEHICLES_SOLD,
      nameOfFormControl: LmCpspQuestion.VEHICLES_SOLD,
      labelText: 'Number of vehicles sold per year?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.VOLUNTARY_PROPERTY_DAMAGE_EXTENSION]: {
    formDslNode: {
      primitive: 'CHECKBOX',
      inputId: LmCpspQuestion.VOLUNTARY_PROPERTY_DAMAGE_EXTENSION,
      nameOfFormControl: LmCpspQuestion.VOLUNTARY_PROPERTY_DAMAGE_EXTENSION,
      labelText: 'Voluntary Property Damage Extension',
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WATER_CONNECTIONS]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.WATER_CONNECTIONS,
      nameOfFormControl: LmCpspQuestion.WATER_CONNECTIONS,
      labelText:
        'What percent of installations are for washing machines, dishwashers or icemakers?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WATER_HAZARDS]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.WATER_HAZARDS,
      nameOfFormControl: LmCpspQuestion.WATER_HAZARDS,
      labelText: 'Are there any bodies of water (pond, lake, stream, etc.) on parcel of land?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WATER_OR_SEWER_MAIN_INSTALLATION]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.WATER_OR_SEWER_MAIN_INSTALLATION,
      nameOfFormControl: LmCpspQuestion.WATER_OR_SEWER_MAIN_INSTALLATION,
      labelText: 'Any water or sewer main installation or construction?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WATERPROOFING_SURFACES]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.WATERPROOFING_SURFACES,
      nameOfFormControl: LmCpspQuestion.WATERPROOFING_SURFACES,
      labelText:
        'Does applicants operations include waterproofing, tuck-pointing or restoration of outside surfaces of buildings or structures?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WEB_HOSTING]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.WEB_HOSTING,
      nameOfFormControl: LmCpspQuestion.WEB_HOSTING,
      labelText: 'Does the insured do development or hosting of websites?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WEDDING_IQ]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.WEDDING_IQ,
      nameOfFormControl: LmCpspQuestion.WEDDING_IQ,
      labelText:
        "The operations of the applicant consist of providing advice only to develop an overall wedding plan which would include suggestions for food menus, catering services, banquet halls, decorations, musical entertainment, etc. All services must be hired by the customers of the applicant. No services other than logistics may be contracted directly by the applicant. Do the applicant's operations meet these requirements?",
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WIRING_YEAR]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.WIRING_YEAR,
      nameOfFormControl: LmCpspQuestion.WIRING_YEAR,
      labelText: 'Wiring year',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WRITTEN_ANTI_SEXUAL_HARASSMENT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.WRITTEN_ANTI_SEXUAL_HARASSMENT,
      nameOfFormControl: LmCpspQuestion.WRITTEN_ANTI_SEXUAL_HARASSMENT,
      labelText:
        'Does the insured have a written anti-sexual harassment and discrimination policy?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WRITTEN_CONTRACT]: {
    formDslNode: {
      primitive: 'RADIO',
      inputId: LmCpspQuestion.WRITTEN_CONTRACT,
      nameOfFormControl: LmCpspQuestion.WRITTEN_CONTRACT,
      labelText:
        'Does the insured always obtain a written contract for their work prior to starting any work?',
      options: { Yes: 'YES', No: 'NO' },
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.WRITTEN_CONTRACT_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.WRITTEN_CONTRACT_DETAILS,
      nameOfFormControl: LmCpspQuestion.WRITTEN_CONTRACT_DETAILS,
      labelText: 'Please provide details on written contract requirements.',
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.YEAR_BUILT_PROPERTY]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.YEAR_BUILT_PROPERTY,
      nameOfFormControl: LmCpspQuestion.YEAR_BUILT_PROPERTY,
      labelText: 'Year built',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.YEAR_BUSINESS_STARTED]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.YEAR_BUSINESS_STARTED,
      nameOfFormControl: LmCpspQuestion.YEAR_BUSINESS_STARTED,
      labelText: 'Year business started',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.YEAR_ROOF_REPLACED_PROPERTY]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.YEAR_ROOF_REPLACED_PROPERTY,
      nameOfFormControl: LmCpspQuestion.YEAR_ROOF_REPLACED_PROPERTY,
      labelText: 'Year roof replaced',
      maxLength: '4',
      minLength: '4',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE]: {
    formDslNode: {
      primitive: 'NUMBER',
      inputId: LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE,
      nameOfFormControl: LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE,
      labelText:
        'How many years of management experience does the management team have in this industry?',
      required: true,
    },
    valueType: 'STATIC',
  },
  [LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS]: {
    formDslNode: {
      primitive: 'TEXT_AREA',
      inputId: LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS,
      nameOfFormControl: LmCpspQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS,
      labelText: "Please provide details regarding the insured's management experience.",
      maxLength: '250',
      questionNote: TEXT_LENGTH_EXCEEDED_ERROR_MESSAGE(250),
      required: true,
    },
    valueType: 'STATIC',
  },
};

// Setup text lengh validation for CPSP quuestions
setupTextLengthExceededErrorMessage(CPSP_FORM_QUESTIONS, TEXT_CHAR_LIMIT);

export const PHONE_FORM_FIELDS = keys(
  pickBy(CPSP_FORM_QUESTIONS, (value: FormDslConfiguration) => {
    return value.formDslNode.primitive === 'PHONE';
  })
);

export const YEAR_FORM_FIELDS = [
  LmCpspQuestion.YEAR_BUSINESS_STARTED,
  LmCpspQuestion.YEAR_ROOF_REPLACED_PROPERTY,
  LmCpspQuestion.YEAR_BUILT_PROPERTY,
];
