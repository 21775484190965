import { combineLatest as observableCombineLatest, Subscription, BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  EXCESS_LIABILITY_LIMITS,
  EXCESS_COMMERCIAL_AUTO_LIMIT_OPTIONS,
} from 'app/features/attune-bop/models/constants';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { getControl, enableDisableControl } from 'app/shared/helpers/form-helpers';

import { AttuneBopQuoteFormService } from 'app/features/attune-bop/services/attune-bop-quote-form.service';

@Component({
  selector: 'app-excess-liability',
  templateUrl: './excess-liability.component.html',
})
export class ExcessLiabilityComponent implements OnInit, OnDestroy {
  @Input() excessLiabilityFormGroup: UntypedFormGroup;
  @Input() excessLiabiltyQuotable$: BehaviorSubject<boolean>;
  @Input() baseState: string;
  @Input() submitted: boolean;
  @Input() isEditing: boolean;
  @Input() formService: AttuneBopQuoteFormService;

  availableExcessLiabilityLimits = EXCESS_LIABILITY_LIMITS;
  availableCommercialAutoCombinedLimits = EXCESS_COMMERCIAL_AUTO_LIMIT_OPTIONS;

  protected sub: Subscription = new Subscription();

  constructor() {}

  ngOnInit() {
    const autoCoverageOptInControl = <UntypedFormControl>(
      this.excessLiabilityFormGroup.get('excessCommercialAutoCoverageIsScheduled')
    );
    observableCombineLatest(
      autoCoverageOptInControl.statusChanges,
      autoCoverageOptInControl.valueChanges
    ).subscribe(([status, value]) => {
      const enabled = status !== 'DISABLED';
      this.enableCommercialAutoCoverageOptions(enabled && value);
    });

    this.excessLiabiltyQuotable$.subscribe((enabled) => {
      this.enableExcessLiabilityFormControls(enabled);
    });

    const isExcessEmployerCoverageScheduled = getControl(
      this.excessLiabilityFormGroup,
      'excessLiabilityCoverageIsScheduled'
    );
    if (isExcessEmployerCoverageScheduled.value) {
      this.enableExcessEmployerLiabilityCoverages(true);
    }
    observableCombineLatest(
      isExcessEmployerCoverageScheduled.statusChanges,
      isExcessEmployerCoverageScheduled.valueChanges
    ).subscribe(([status, value]) => {
      const enabled = status !== 'DISABLED';
      this.enableExcessEmployerLiabilityCoverages(enabled && value);
    });
  }

  showCoverageOptions(optInControlName: string) {
    const optInCtrl = getControl(this.excessLiabilityFormGroup, optInControlName);
    return optInCtrl.enabled && optInCtrl.value;
  }

  excessLiabilityPerAccidentCoverageReadOnly() {
    return Object.keys(this.formService.excessLiabilityPerAccidentCoverageOptions).length === 1;
  }

  excessLiabilityPerDiseaseCoverageReadOnly() {
    return Object.keys(this.formService.excessLiabilityPerDiseaseCoverageOptions).length === 1;
  }

  excessLiabilityPerPolicyCoverageReadOnly() {
    return Object.keys(this.formService.excessLiabilityPerPolicyCoverageOptions).length === 1;
  }

  private enableCommercialAutoCoverageOptions(shouldEnable: boolean) {
    const enableGroup = (groupName: string) => {
      const group = <UntypedFormGroup>this.excessLiabilityFormGroup.get(groupName);
      group[shouldEnable ? 'enable' : 'disable']();
    };

    const combinedLimit = <UntypedFormControl>(
      this.excessLiabilityFormGroup.get('excessCommercialAutoCombinedLimit')
    );
    combinedLimit[shouldEnable ? 'enable' : 'disable']();

    enableGroup('excessCommercialAutoCoverageVehicleCounts');
    enableGroup('excessCommercialAutoCoverageVehicleExposure');
    enableGroup('excessCommercialAutoCoverageStates');
    enableDisableControl(
      getControl(this.excessLiabilityFormGroup, 'excessCommercialAutoUnderlyingPremium'),
      shouldEnable
    );
  }

  private enableExcessLiabilityFormControls(shouldEnable: boolean) {
    [
      'excessLiabilityLimit',
      'excessLiabilityCoverageIsScheduled',
      'excessCommercialAutoCoverageIsScheduled',
      'excessAnnualRevenue',
    ].forEach((formCtrlName) => {
      const formCtrl = getControl(this.excessLiabilityFormGroup, formCtrlName);
      formCtrl[shouldEnable ? 'enable' : 'disable']();
    });
  }

  private enableExcessEmployerLiabilityCoverages(shouldEnable: boolean) {
    [
      'excessLiabilityPerAccidentCoverage',
      'excessLiabilityPerDiseaseCoverage',
      'excessLiabilityPerPolicyCoverage',
      'excessEmployersUnderlyingPremium',
    ].forEach((formCtrlName) => {
      const formCtrl = getControl(this.excessLiabilityFormGroup, formCtrlName);
      formCtrl[shouldEnable ? 'enable' : 'disable']();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sumOfVehiclesInvalid(): boolean {
    const vehicleCounts = this.excessLiabilityFormGroup.get(
      'excessCommercialAutoCoverageVehicleCounts'
    ) as UntypedFormGroup;
    return !!(vehicleCounts.errors && vehicleCounts.errors.commercialAutoSumOfVehicles);
  }
}
