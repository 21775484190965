import { Injectable } from '@angular/core';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, tap, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import {
  HISCOX_POLICY_PAYMENT_PLAN_OPTIONS,
  HiscoxBackendPricedQuote,
  HiscoxPaymentRes,
} from '../models/hiscox-priced-quote';
import { HISCOX_PRODUCTS } from '../models/hiscox-types';

import {
  API_URI,
  QUOTE_API_URI,
  HISCOX_QUOTE_API_URL,
  GL_QUESTION_API_URL,
} from '../models/constants';
import { InsuredAccount } from '../../insured-account/models/insured-account.model';

@Injectable({
  providedIn: 'root',
})
export class HiscoxQuoteService {
  paymentSubject: BehaviorSubject<HiscoxPaymentRes | null> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService
  ) {}

  // eventually implement cachebust, look at how the
  // workers-comp-quote service does it
  // src/app/workers-comp/services/workers-comp-quote.service caching
  // is currently removed because GL quote results don't show up
  // without a cachebust implemented and plumbed into the
  // insured-account.service / policies-pane.component

  getQuote(
    productType: HISCOX_PRODUCTS,
    accountId: string,
    ensureQuoteIsCurrent = false
  ): Observable<any> {
    return this.fetchQuote(productType, accountId, ensureQuoteIsCurrent).pipe(
      tap((payload) => {
        this.amplitudeService.setGlPolicyDetails(payload);
      }),
      shareReplay(1)
    );
  }

  fetchQuote(productType: HISCOX_PRODUCTS, accountId: string, ensureQuoteIsCurrent = false) {
    const options = ensureQuoteIsCurrent ? { params: { ensureQuoteIsCurrent: true } } : {};
    return this.http
      .get<HiscoxBackendPricedQuote>(`${QUOTE_API_URI}/${productType}/${accountId}`, options)
      .pipe(
        map((payload: HiscoxBackendPricedQuote) => {
          return payload;
        }),
        catchError((error) => {
          if (error) {
            this.sentryService.notify(`Unable to fetch ${productType} Hiscox quote by accountId.`, {
              severity: 'error',
              metaData: {
                accountId: accountId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
          }
          throw error;
        })
      );
  }

  getQuoteLetterInfo(productType: HISCOX_PRODUCTS, accountId: string) {
    return this.http
      .get<any>(`${QUOTE_API_URI}/quote-letter-info/${accountId}/${productType}`, {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .pipe(
        map((payload: any) => {
          return payload;
        }),
        catchError((error) => {
          if (error) {
            this.sentryService.notify('Unable to fetch Hiscox quote letter', {
              severity: 'error',
              metaData: {
                quoteId: accountId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
          }
          throw error;
        })
      );
  }

  getQuoteLetterBlob(productType: string, accountId: string) {
    return this.http
      .get(`${QUOTE_API_URI}/quote-letter-pdf-blob/${accountId}/${productType}`, {
        headers: {
          'Content-type': 'application/json',
        },
        responseType: 'blob', // had to add this one here
      })
      .pipe(
        map((payload: any) => {
          return payload;
        }),
        catchError((error) => {
          if (error) {
            this.sentryService.notify(`Unable to fetch Hiscox quote letter for ${accountId}`, {
              severity: 'error',
              metaData: {
                quoteId: accountId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
          }
          throw error;
        })
      );
  }

  getQuotePdfUrl(productType: string, accountId: string) {
    const url = `${QUOTE_API_URI}/quote-letter-pdf-blob/${accountId}/${productType}`;
    return this.http.get(url).pipe(
      map((payload: any) => {
        return payload.url;
      }),
      catchError((error) => {
        this.sentryService.notify('Unable to get quote pdf url.', {
          severity: 'error',
          metaData: {
            downloadLink: url,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  private translatePayment(paymentFreq: number) {
    if (paymentFreq === HISCOX_POLICY_PAYMENT_PLAN_OPTIONS['One-time payment']) {
      return 'Annual';
    }
    if (paymentFreq === HISCOX_POLICY_PAYMENT_PLAN_OPTIONS.Monthly) {
      return 'Monthly';
    }
  }

  makePaymentSetup(accountId: string, productType: string, paymentFreq: number) {
    const paymentFrequency = this.translatePayment(paymentFreq);
    const quotePaymentSetupUri =
      API_URI + `/accounts/${accountId}/hiscox/${productType}/payment-setup`;
    return this.http
      .post<any>(quotePaymentSetupUri, {
        paymentFreq: paymentFrequency,
      })
      .pipe(
        map((payload: any) => {
          this.paymentSubject.next(payload);
          return payload;
        }),
        catchError((error) => {
          if (error) {
            this.sentryService.notify(`Unable to make Hiscox payment setup`, {
              severity: 'error',
              metaData: {
                accountId: accountId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
                paymentFreq: paymentFrequency,
                productType: productType,
              },
            });
          }
          throw error;
        })
      );
  }

  bindQuote(accountId: string, productType: string, paymentFreq: string, paymentRef: string) {
    const quoteBindUri = API_URI + `/accounts/${accountId}/hiscox/${productType}/bind`;
    return this.http
      .post<any>(quoteBindUri, {
        paymentFreq: paymentFreq,
        paymentRef: paymentRef,
      })
      .pipe(
        map((payload: any) => {
          return payload;
        }),
        catchError((error) => {
          if (error) {
            this.sentryService.notify(`Unable to make Hiscox bind request`, {
              severity: 'error',
              metaData: {
                accountId: accountId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
                productType: productType,
                paymentFreq: paymentFreq,
                paymentRef: paymentRef,
              },
            });
          }
          throw error;
        })
      );
  }

  /*
  For Hiscox's v4 API, the GL questions are retrieved directly from Hiscox's Dynamic Questions (DQs) endpoint
  */
  loadQuestions(productType: string, state: string, classOfBusiness: string): Observable<any> {
    return this.http
      .get<any>(GL_QUESTION_API_URL(productType, state, classOfBusiness), {
        headers: { 'Content-type': 'application/json' },
      })
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Error retrieving gl questions', {
            severity: 'warning',
            metaData: {
              productType: productType,
              state: state,
              classOfBusiness: classOfBusiness,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(false);
        })
      );
  }

  // Request quote from Hiscox
  // TODO: add typing for quote
  requestQuote(
    formData: any,
    productType: HISCOX_PRODUCTS,
    insuredAccount: InsuredAccount,
    requestUuid: string,
    quoteId: string,
    classOfBusinessCd: string
  ): Observable<any> {
    const quoteRequest: any = {
      formData,
      accountId: insuredAccount.id,
      naicsCode: insuredAccount.naicsCode,
      requestUuid,
      quoteId,
      classOfBusinessCd,
    };

    return this.http
      .post<any>(HISCOX_QUOTE_API_URL(productType), quoteRequest, {
        headers: { 'Content-type': 'application/json' },
      })
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify(`Error submitting ${productType} quote`, {
            severity: 'warning',
            metaData: {
              productType,
              accountId: insuredAccount.id,
              naicsCode: insuredAccount.naicsCode,
              quoteId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(error);
        })
      );
  }
}
