// Libraries
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';

@Component({
  selector: 'app-form-field-radio-traditional',
  templateUrl: './form-field-radio-traditional.component.html',
})
export class FormFieldRadioTraditionalComponent {
  @Input() inputId: string;
  @Input() additionalQuestionText: string;
  @Input() labelText: string;
  @Input() subLabelText: string;
  @Input() nameOfFormControl: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() readonly = false;
  @Input() optionsLabelCssClass: string;
  @Input() cssClass: string;
  @Input() optionsCssClass: string;

  @Input() questionCssClass: string;
  @Input() tooltipText: string;
  @Input() options: { [key: string]: string | boolean | number };
  @Input() optionDescriptions: { [key: string]: string };
  @Input() optionAdditionalColumns: { [key: string]: { innerHTML: string; cssClass?: string }[] };
  @Input() optionPillTags: { [key: string]: string } = {};
  @Input() errorText = 'Field is required';
  @Input() showErrorText = false;
  @Input() showLabelWithoutValue = false;
  @Input() defaultDisabledKey = '';

  /* This field is used when we only want to display a component for a single key from the options.  This is
     required for using the content slot to encapsulate additional content within a radio control */
  @Input() displayOnlyKey = '';
  @Input() displayOnlyKeySelectedCssClass: string;

  getControl = getControl;

  getOptions() {
    return this.options || { Yes: true, No: false };
  }

  isDisplayOnlyKeySelected() {
    const val = this.form.get(this.nameOfFormControl);
    if (!val) {
      return false;
    }

    return val.value === this.displayOnlyKey;
  }

  fieldInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.form, this.submitted);
  }
}
