<section class="policy-pane-content">
  <!-- Note: Only show back button here in case the content hasn't loaded, otherwise allow child components to render back button -->
  <div class="policy-pane-nav">
    <a class="button button__nav button__no-margin" [routerLink]="['../../..']">
      <span class="icon-arrow-left button-icon"></span>
      Back
    </a>
    <div class="policy-pane-nav-buttons">
      <ng-container
        *ngIf="shouldSeeEndorsementButton && policyTermTransaction && isBopEndorsable()"
      >
        <a
          [routerLink]="['/support', 'form', 'request-cancellation']"
          [queryParams]="helpCenterQueryParams()"
          class="button button__secondary button__small button__no-margin"
        >
          Request cancellation
        </a>
        <button
          (click)="createPolicyChangeEndorsement()"
          [disabled]="isCreatingEndorsement"
          attr.aria-describedby="{{ showEndorseTip() ? 'bind-endorse-tip' : '' }}"
          class="button button__small button__no-margin button__secondary js-endorse-quote-button"
        >
          <div class="new-tag-container">
            <div class="button-loader" *ngIf="isCreatingEndorsement">
              <svg class="circle-spinner">
                <circle
                  cx="11"
                  cy="11"
                  r="9"
                  stroke-width="2"
                  stroke-dasharray="0,1000"
                  d="M5 40 l215 0"
                  fill="transparent"
                />
              </svg>
            </div>
            <ng-container *ngIf="!isCreatingEndorsement">Endorse Policy</ng-container>
            <ng-container *ngIf="isCreatingEndorsement">Creating new endorsement</ng-container>
          </div>
          <app-education-pane
            elementId="bind-endorse-tip"
            position="left-top"
            *ngIf="showEndorseTip()"
          >
            You can make mid-term changes to the policy with self-service endorsements here.
          </app-education-pane>
        </button>
      </ng-container>
      <ng-container *ngIf="showReinstatementButton()">
        <a
          [routerLink]="['/support', 'form', 'request-reinstatement']"
          [queryParams]="helpCenterQueryParams()"
          class="button button__secondary button__small button__no-margin"
          >Request reinstatement</a
        >
      </ng-container>
    </div>
    <div
      *ngIf="displayEndorsementError"
      class="
        dialog-text
        dialog-text__warning
        dialog-text__margin-top
        dialog-text__full-width
        dialog-text__warning-icon
        js-endorse-unavailable-text
      "
    >
      Endorse policy unavailable. Please contact our Customer Care Team to submit policy change
      requests.
    </div>
  </div>

  <ng-container *ngIf="!policyTermTransaction">
    <div class="placeholder-h1"></div>
    <ul>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
    </ul>
  </ng-container>

  <ng-container *ngIf="policyTermTransaction">
    <ng-container *ngIf="showNewRenewalsUI">
      <div
        *ngIf="!!paymentNotice"
        class="js-payment-warning-dialog dialog-text dialog-text__payment-reminder dialog-text__full-width dialog-text__{{
          paymentNotice.classModifer
        }} dialog-text__warning-icon"
      >
        <ng-container *ngIf="policyTermTransaction.BaseState === 'NY'">
          Please ensure payment is made before
          <strong>{{ paymentNotice.billingDueDate | date: 'longDate':'UTC' }}.</strong> If no
          payment is received, a notice of cancellation will be sent.
        </ng-container>
        <ng-container *ngIf="policyTermTransaction.BaseState !== 'NY'">
          Please ensure payment is made before
          <strong>{{ paymentNotice.billingDueDate | date: 'longDate':'UTC' }}.</strong> If no
          payment is received by this date, the current policy will expire and no further coverage
          will be provided.
        </ng-container>
      </div>
      <app-renewal-policy-details
        *ngIf="isRenewal()"
        [accountId]="accountId"
        [policyTermTransaction]="policyTermTransaction"
        [insuredAccount]="insuredAccount"
        [paymentStatus]="paymentStatus"
      >
      </app-renewal-policy-details>
      <app-bound-policy-details-pane
        *ngIf="!isRenewal()"
        [accountId]="accountId"
        [policyTermTransaction]="policyTermTransaction"
        [insuredAccount]="insuredAccount"
      >
      </app-bound-policy-details-pane>
    </ng-container>
    <ng-container *ngIf="!showNewRenewalsUI">
      <app-bound-policy-details-pane
        [accountId]="accountId"
        [policyTermTransaction]="policyTermTransaction"
      >
      </app-bound-policy-details-pane>
    </ng-container>

    <ng-container *ngIf="isEndorsementPending && isBopEndorsable()">
      <section class="endorsement-dialog dialog-text dialog-text__margin-top dialog-text__neutral">
        <h3>Endorsement status: pending</h3>
        Your change request is currently under review. There has been no change in coverage at this
        time. An agent will reach out when your request has been processed.
      </section>
    </ng-container>
  </ng-container>
</section>
