<div style="display: flex">
  <div class="app-page-form-side-rail">
    <b>Current Step FormDSL</b>
    <pre>{{ stepTree | json }}</pre>
    <b>Angular Form</b>
    <pre>{{ formService.form.value | json }}</pre>
  </div>

  <div class="app-page-form" content>
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="loadPreviousStep($event)"
      (keyup.enter)="loadPreviousStep($event)"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>

    <form [formGroup]="formService.form" (ngSubmit)="submitForm($event)" novalidate>
      <main *ngFor="let step of formService.formDslSteps">
        <section *ngIf="isCurrentStep(step.slug)">
          <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">{{ step.displayName }}</h1>
          <app-form-dsl-form
            [formTree]="stepTree"
            [form]="formService.form.get(step.formPath)"
            [submitted]="submitted()"
          >
          </app-form-dsl-form>
        </section>
      </main>

      <div class="form-footer">
        <div class="validation" role="alert" *ngIf="submitted() && !isCurrentStepValid()">
          <p class="validation-message">Please fill out all required fields</p>
        </div>
        <div class="nav-button-group nav-button-group__left">
          <div>
            <button
              *ngIf="!isFinalStep()"
              class="button button__primary js-load-next-step-button"
              (click)="loadNextStep($event)"
              [ngClass]="{ button__discouraged: !isCurrentStepValid() }"
              [disabled]="isLoadingNextStep"
            >
              <div class="button-loader" *ngIf="isLoadingNextStep">
                <svg class="circle-spinner">
                  <circle
                    cx="11"
                    cy="11"
                    r="9"
                    stroke-width="2"
                    stroke-dasharray="0,1000"
                    d="M5 40 l215 0"
                    fill="transparent"
                  />
                </svg>
              </div>
              <span *ngIf="isLoadingNextStep">Loading...</span>
              <span *ngIf="!isLoadingNextStep">Next</span>
            </button>
          </div>
          <div>
            <button
              *ngIf="isFinalStep()"
              type="submit"
              class="button button__primary js-step-forward-button"
              [ngClass]="{ button__discouraged: !this.isCurrentStepValid() }"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="app-page-form-side-rail"></div>
</div>
