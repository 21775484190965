import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { InsuredAccount } from '../../../insured-account/models/insured-account.model';
import { QuoteSummary } from '../../../../shared/models/quote-summary';
import { CurrencyPipe } from '@angular/common';
import { ZendeskApiService } from '../../../../shared/services/zendesk-api.service';
import { SentryService } from 'app/core/services/sentry.service';
import { isBopV2orV3 } from '../../models/constants';

interface CoveragePolicyInfo {
  insuredName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  newPremium: number | null;
  newEffectiveDate: string;
}

@Component({
  selector: 'app-attune-bop-replacement-coverage-document-page',
  templateUrl: './attune-bop-replacement-coverage-document-page.component.html',
  providers: [CurrencyPipe],
})
export class AttuneBopReplacementCoverageDocumentPageComponent implements OnInit, OnDestroy {
  // Component to expose a Replacement Coverage document, for use by the the ZD app via service-document.

  private sub = new Subscription();

  policyInfo: CoveragePolicyInfo;

  constructor(
    private route: ActivatedRoute,
    private zendeskApiService: ZendeskApiService,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    const accountId = this.route.snapshot.params.accountNumber as string;
    const token = this.route.snapshot.queryParams.token as string;

    this.zendeskApiService
      .fetchAccountInformation(accountId, token)
      .subscribe((account: InsuredAccount) => {
        try {
          const internallyGeneratedRolloverQuotes = ([] as QuoteSummary[])
            .concat(account.bopQuotes, account.inForceQuotes, account.withdrawnBopPlusQuotes)
            .filter((quote: QuoteSummary) => {
              return (
                isBopV2orV3(quote.uwCompanyCode) && quote.creator && !quote.creator.ExternalUser
              );
            })
            .sort((quote1: QuoteSummary, quote2: QuoteSummary) =>
              moment(quote2.timeLastUpdated).diff(quote1.timeLastUpdated)
            );

          // Find the *bound* quote, if possible--otherwise, use the most recent internally-generated quote
          const bopPlusQuote: QuoteSummary =
            internallyGeneratedRolloverQuotes.find((quote) => quote.status === 'Bound') ||
            internallyGeneratedRolloverQuotes[0];

          // If neither method returns a valid quote, refuse to generate the document
          if (!bopPlusQuote) {
            throw new Error(`Could not find BOP+ quote for account ${accountId}`);
          }

          this.policyInfo = {
            insuredName: account.companyName,
            addressLine1: account.addressLine1,
            addressLine2: account.addressLine2 || '',
            city: account.city,
            state: account.state,
            zip: account.zip,

            newPremium: bopPlusQuote.totalCost,
            newEffectiveDate: bopPlusQuote.policyEffectiveDate.format('M/D/YYYY'),
          };
        } catch (error) {
          this.sentryService.notify('Failed to generate replacement coverage document', {
            severity: 'error',
            metaData: {
              accountId,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
