import {
  addressNode,
  FormDslNode,
  checkboxGroupNode,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import { EvaluatorName } from '../form-dsl/constants/form-dsl-constants';

export const FORM_CONFIG: { [formName: string]: FormDslNode[] } = {
  intro: [
    { primitive: 'H2', text: 'Introduction to Form-DSL' },
    {
      primitive: 'PARA',
      text: 'this is form-dsl.  On the right is the rendered form, on the left is the input form-dsl, in JSON format that generated the output.  In this example we have 3 nodes, an H2, this Paragraph, and a TEXT node that allows user input.  In a more advaned test component, the H2 and PARA will be handled outside of Form-dsl, but this what I put together for now.',
    },
    {
      primitive: 'TEXT',
      inputType: 'text',
      inputId: 'quote-question-free-text-1',
      nameOfFormControl: 'quote-question-free-text-1',
      labelText: 'Free text label 1',
      placeholder: 'e.g., hello',
    },
  ],
  simple: [
    {
      primitive: 'TEXT',
      inputType: 'text',
      inputId: 'quote-question-free-text-1',
      nameOfFormControl: 'quote-question-free-text-1',
      labelText: 'Free text label 1',
      placeholder: 'e.g., hello',
    },
  ],
  'div-test': [
    {
      primitive: 'DIV',
      cssClass: 'dsl-example-border',
      children: [
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'quote-question-free-text-1',
          nameOfFormControl: 'quote-question-free-text-1',
          labelText: 'DIV free text label 1',
          placeholder: 'e.g., hello',
        },
        {
          primitive: 'DIV',
          cssClass: 'dsl-example-border',
          children: [
            {
              primitive: 'TEXT',
              inputType: 'text',
              inputId: 'quote-question-free-text-2',
              nameOfFormControl: 'quote-question-free-text-2',
              labelText: 'DIV free text label 2',
              placeholder: 'e.g., hello',
            },
          ],
        },
        {
          primitive: 'RADIO',
          inputId: 'quote-question-radio-1',
          nameOfFormControl: 'quote-question-radio-1',
          labelText: 'DIV free radio label 1',
          options: {
            a: 1,
            b: 2,
            c: 3,
          },
          ul: { li: [] },
        },
      ],
    },
  ],
  'checkbox-group': [
    checkboxGroupNode({
      nameOfFormControl: 'checkbox-group',
      labelText: 'What words describe your ideal snack? Select all that apply:',
      checkboxConfigs: [
        { nameOfFormControl: 'is-chewy', labelText: 'Chewy' },
        { nameOfFormControl: 'is-crunchy', labelText: 'Crunchy' },
        { nameOfFormControl: 'is-salty', labelText: 'Salty' },
        { nameOfFormControl: 'is-sweet', labelText: 'Sweet' },
      ],
    }),
  ],
  'multi-clause-checkbox': [
    {
      primitive: 'MULTI-CLAUSE-CHECKBOX',
      inputId: 'multi-clause-1',
      nameOfFormControl: 'multi-clause-1',
      labelText: 'Do you or any of your subcontractors do the following?',
      clauses: [
        'Airports',
        'Amusement rides, pools or playgrounds',
        'Asbestos, lead, or mold evaluation or abatement',
        'Automotive glass installation and repair',
        'Blasting or demolition',
        'Bridges, dams, harbors, mines, piers or tunnels',
        'Crane operation',
        'Emergency systems',
        'Exterior carpentry work',
        'Exterior electrical work',
        'Exterior painting',
      ],
    },
  ],
  'multi-clause-radio': [
    {
      primitive: 'MULTI-CLAUSE-RADIO',
      inputId: 'multi-clause-1',
      nameOfFormControl: 'multi-clause-1',
      labelText: 'Do you or any of your subcontractors do the following?',
      clauses: [
        'Airports',
        'Amusement rides, pools or playgrounds',
        'Asbestos, lead, or mold evaluation or abatement',
        'Automotive glass installation and repair',
        'Blasting or demolition',
        'Bridges, dams, harbors, mines, piers or tunnels',
        'Crane operation',
        'Emergency systems',
        'Exterior carpentry work',
        'Exterior electrical work',
        'Exterior painting',
      ],
    },
  ],
  address: [
    addressNode({
      nameOfFormControl: 'address',
      prefix: 'location',
      labelText: 'Address',
    }),
  ],
  'override-test': [
    {
      primitive: 'TEXT',
      inputType: 'text',
      inputId: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_NumOfEmployees',
      nameOfFormControl: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_NumOfEmployees',
      labelText: 'Number of employees',
      placeholder: 'e.g., hello',
    },
    {
      primitive: 'SELECT',
      inputId: 'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_BusinessOwnershipStructure',
      nameOfFormControl:
        'ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_BusinessOwnershipStructure',
      labelText: 'Organization type',
      options: {
        'Corporation or other organization (other than the above)':
          'Corporation or other Organization (other than the above)',
        'Individual/sole proprietor': 'Individual/Sole Proprietor',
        'Joint venture': 'Joint Venture',
        'Limited liability company': 'Limited Liability Company',
        Partnership: 'Partnership',
        Trust: 'Trust',
      },
      ul: { li: [] },
    },
  ],
  'true-c-test': [
    {
      primitive: 'TRUE-CONDITIONAL',
      cssClass: 'red-border',
      inputId: 'true-c-1',
      nameOfFormControl: 'true-c-1',
      labelText: 'parent conditional',
      conditionalChildren: [
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-2',
          nameOfFormControl: 'true-c-2',
          labelText: 'Free text label 1',
          placeholder: 'e.g., hello',
        },
      ],
    },
  ],
  'true-c-test2': [
    {
      primitive: 'TRUE-CONDITIONAL',
      cssClass: 'red-border',
      inputId: 'true-c-1',
      nameOfFormControl: 'true-c-1',
      labelText: 'parent conditional',
      conditionalChildren: [
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-2',
          nameOfFormControl: 'true-c-2',
          labelText: 'Free text label 1',
          placeholder: 'e.g., hello',
        },
      ],
    },
  ],
  'true-c-multiple-test': [
    {
      primitive: 'TRUE-CONDITIONAL',
      cssClass: 'red-border',
      inputId: 'true-c-1',
      nameOfFormControl: 'true-c-1',
      labelText: 'parent conditional',
      conditionalChildren: [
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-2',
          nameOfFormControl: 'true-c-2',
          labelText: 'Free text label 2',
          placeholder: 'e.g., hello',
        },
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-3',
          nameOfFormControl: 'true-c-3',
          labelText: 'Free text label 3',
          placeholder: 'e.g., hello',
        },
      ],
    },
  ],
  'value-c-test': [
    {
      primitive: 'TEXT',
      inputType: 'text',
      inputId: 'sibling-input',
      nameOfFormControl: 'sibling-input',
      labelText: 'Shave and a haircut for?',
      placeholder: 'e.g., hello',
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      cssClass: 'red-border',
      dependsOn: 'sibling-input',
      enableValue: 'two bits',
      conditionalChildren: [
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-2',
          nameOfFormControl: 'true-c-2',
          labelText: 'The door opens',
          placeholder: 'e.g., hello',
        },
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-3',
          nameOfFormControl: 'true-c-3',
          labelText: 'Free text label 3',
          placeholder: 'e.g., hello',
        },
      ],
    },
  ],
  'value-c-radio-test': [
    {
      primitive: 'RADIO',
      inputId: 'sibling-input',
      nameOfFormControl: 'sibling-input',
      labelText: 'Shave and a haircut for?',
      placeholder: 'e.g., hello',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      ul: { li: [] },
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      cssClass: 'red-border',
      dependsOn: 'sibling-input',
      enableValue: 'Yes',
      conditionalChildren: [
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-2',
          nameOfFormControl: 'true-c-2',
          labelText: 'The door opens',
          placeholder: 'e.g., hello',
        },
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'true-c-3',
          nameOfFormControl: 'true-c-3',
          labelText: 'Free text label 3',
          placeholder: 'e.g., hello',
        },
      ],
    },
  ],
  'value-c-radio-test-2': [
    {
      primitive: 'DIV',
      children: [
        {
          primitive: 'RADIO',

          inputId: 'sibling-input',
          nameOfFormControl: 'sibling-input',
          labelText: 'Shave and a haircut for?',
          placeholder: 'e.g., hello',
          ul: { li: [] },
          options: {
            Yes: 'Yes',
            No: 'No',
          },
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          cssClass: 'red-border',
          dependsOn: 'sibling-input',
          enableValue: 'Yes',
          conditionalChildren: [
            {
              primitive: 'TEXT',
              inputType: 'text',
              inputId: 'true-c-2',
              nameOfFormControl: 'true-c-2',
              labelText: 'The door opens',
              placeholder: 'e.g., hello',
            },
            {
              primitive: 'TEXT',
              inputType: 'text',
              inputId: 'true-c-3',
              nameOfFormControl: 'true-c-3',
              labelText: 'Free text label 3',
              placeholder: 'e.g., hello',
            },
          ],
        },
      ],
    },
  ],
  'eval-conditional': [
    {
      primitive: 'DATE',
      nameOfFormControl: 'effectiveDate',
      inputId: 'effectiveDate',
      labelText: 'Change effective date',
    },
    {
      primitive: 'EVAL-CONDITIONAL',
      dependsOn: 'effectiveDate',
      enableEvaluator: EvaluatorName.IS_BEFORE_TODAY,
      conditionalChildren: [
        {
          primitive: 'TEXT',
          inputType: 'text',
          inputId: 'backdateReason',
          nameOfFormControl: 'backdateReason',
          labelText: 'Why is this change backdated?',
        },
      ],
    },
  ],
  'eval-multi-conditional': [
    {
      primitive: 'NUMBER',
      nameOfFormControl: 'firstField',
      inputId: 'firstField',
      labelText: 'First form input',
    },
    {
      primitive: 'NUMBER',
      nameOfFormControl: 'secondField',
      inputId: 'secondField',
      labelText: 'Second form input',
    },
    {
      primitive: 'EVAL-CONDITIONAL',
      dependsOn: ['firstField', 'secondField'],
      enableEvaluator: EvaluatorName.TWO_POSITIVE_VALUES,
      conditionalChildren: [
        {
          primitive: 'NUMBER',
          inputId: 'sumInput',
          nameOfFormControl: 'sumInput',
          labelText: 'What is the sum of the two numbers?',
        },
      ],
    },
  ],
  'inner-conditional-children': [],
  'link-modal': [
    {
      primitive: 'LINK_MODAL',
      modalTitle: 'Link Modals',
      modalSubTitle: 'This is a sample modal triggered by a link',
      modalPreLinkText: 'Click ',
      modalLinkText: 'here ',
      modalPostLinkText: 'to learn about link modals!',
      modalBody: `<strong>Here is what you need to know about link modals:</strong><br>
                  - The child attribute specifies the associated form-dsl question.<br>
                  - The modalTitle attribute specifies the modal title.<br>
                  - The modalSubTitle attribute specifies the modal subtitle.<br>
                  - The modalImage attribute specifies the modal image.<br>
                  - The modalBody attribute specifies the modal body.<br>
                  - The modalPreLinkText attribute specifies the hyperlink text.<br>
                  - The modalPreLinkText attribute specifies the text before the hyperlink text.<br>
                  - The modalPostLinkText attribute specifies the text after the hyperlink text.<br>
                  - The removeClasses attribute is useful for the sake of removing classes that interfere with full-screen modal presentation.<br>`,
      removeClasses: ['app-page-form-scrollable-wrapper', 'form-animation'],
      child: {
        primitive: 'TEXT',
        inputType: 'text',
        inputId: 'link-modal-text',
        nameOfFormControl: 'link-modal-text',
        labelText: 'Read more about link modals below',
        placeholder: 'I understand how link modals work now!',
      },
    },
  ],
};
