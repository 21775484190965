import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { omit } from 'lodash';
import { INDUSTRY_OPTIONS, topClassCodes } from '../../models/constants';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, startWith, Subscription, switchMap } from 'rxjs';
import { InsuredAccountService } from '../../../../features/insured-account/services/insured-account.service';
import { getControl } from '../../../../shared/helpers/form-helpers';
import { UsStateService } from '../../../../shared/services/us-state.service';
import { UserService } from '../../../../core/services/user.service';
import { PopularClassCodeIndustry, State, TopClassCode } from '../../models/types';
import { productFilter } from '../../utils/helpers';
import { FeatureFlagService, JSON_FLAG_NAMES } from 'app/core/services/feature-flag.service';

@Component({
  selector: 'app-appetite-checker-top-class-codes',
  templateUrl: 'appetite-checker-top-class-codes.component.html',
})
export class AppetiteCheckerTopClassCodesComponent implements OnInit, OnChanges {
  @Input() searchState: State;
  form: FormGroup<{
    industry: FormControl<keyof typeof INDUSTRY_OPTIONS | null>;
    state: FormControl<State | '' | null>;
  }>;
  industries = omit(INDUSTRY_OPTIONS, 'Artisan Makers'); // No top class codes for this so omit from filter dropdown
  topClassCodes = topClassCodes;
  loading = true;

  private sub: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private insuredAccountService: InsuredAccountService,
    public usStateService: UsStateService,
    private userService: UserService,
    private featureFlagService: FeatureFlagService
  ) {
    this.form = this.formBuilder.group({
      industry: new FormControl<keyof typeof INDUSTRY_OPTIONS>(INDUSTRY_OPTIONS['All Industries']),
      state: new FormControl<State | '' | null>(''),
    });
  }

  ngOnInit() {
    const industryControl: AbstractControl<keyof typeof INDUSTRY_OPTIONS> = getControl(
      this.form,
      'industry'
    );
    const stateControl: AbstractControl<State> = getControl(this.form, 'state');
    const industryControl$ = industryControl.valueChanges;
    const stateControl$ = stateControl.valueChanges;
    const attuneWcEnabledStates$ = this.featureFlagService.getJsonFlagValue<string[]>(
      JSON_FLAG_NAMES.ATTUNE_WC_ENABLED_STATES
    );

    this.sub.add(
      this.userService
        .getUser()
        .pipe(switchMap((user) => this.insuredAccountService.getProducerDetails(user.producer)))
        .subscribe({
          next: (producerDetails) => {
            if (!stateControl.getRawValue()) {
              stateControl.setValue(producerDetails.State as State);
            }
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        })
    );
    this.sub.add(
      combineLatest([
        industryControl$.pipe(startWith(undefined)),
        stateControl$.pipe(startWith(undefined)),
        attuneWcEnabledStates$,
      ]).subscribe(([industry, state, attuneWcEnabledStates]) => {
        const filterByIndustry = (topClassCode: TopClassCode) => {
          const industryFilter = industry && industry !== PopularClassCodeIndustry.ALL;
          return industryFilter ? topClassCode.category === industry : true;
        };
        this.topClassCodes = topClassCodes.reduce((acc, topClassCode) => {
          if (!filterByIndustry(topClassCode)) {
            return acc;
          }
          if (!state) {
            return [...acc, topClassCode];
          }
          const filteredProducts = topClassCode.products.filter((product) =>
            productFilter(product, state, topClassCode, attuneWcEnabledStates || [])
          );
          if (filteredProducts.length === 0) {
            return acc;
          }
          return [...acc, { ...topClassCode, products: filteredProducts }];
        }, []);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const { searchState } = changes;
    const { currentValue, previousValue } = searchState;
    const stateControl: AbstractControl<State> = getControl(this.form, 'state');
    if (
      currentValue &&
      currentValue !== previousValue &&
      currentValue !== stateControl.getRawValue()
    ) {
      stateControl.setValue(currentValue);
      this.loading = false;
    }
  }

  getStates() {
    return {
      '': '--',
      ...this.usStateService.getUsStateAbbreviations(),
    };
  }

  selectClassCode(code: string, description: string, hash: string) {
    this.router.navigate(['/accounts', 'new'], {
      queryParams: {
        'naicsCode.code': code,
        'naicsCode.description': description,
        'naicsCode.hash': hash,
      },
    });
  }
}
