import { Pipe, PipeTransform } from '@angular/core';
import { ProductCombinationForAvailability } from '../../features/digital-carrier/models/types';

@Pipe({ name: 'carrierDisplayName' })
export class CarrierDisplayNamePipe implements PipeTransform {
  transform(value: ProductCombinationForAvailability['pasSource']): string {
    switch (value) {
      case 'coalition':
        return 'Coalition';
      case 'employers':
        return 'Employers';
      case 'hiscox':
        return 'Hiscox';
      case 'liberty_mutual':
        return 'Liberty Mutual';
      case 'neptune':
        return 'Neptune';
      case 'attune_gw':
        return 'Attune';
      default:
        return value;
    }
  }
}
