import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, Subscription, tap, switchMap, startWith, filter } from 'rxjs';

// Components
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';

// Models
import { LocationFormGroup } from 'app/workers-comp/attune/models/quote-form.model';

// Services
import {
  AttuneWcClassCodesService,
  ClassCodeResult,
  ErrorClassCodeFetch,
  SuccessfulClassCodeFetch,
} from 'app/workers-comp/attune/services/attune-wc-class-code.service';
import { AttuneWcQuoteFormService } from 'app/workers-comp/attune/services/attune-wc-quote-form.service';

@Component({
  selector: 'app-wc-location-page',
  templateUrl: './attune-wc-location-page.component.html',
})
export class AttuneWcLocationPageComponent
  extends SteppedFormPageComponent<AttuneWcQuoteFormService, LocationFormGroup>
  implements OnInit, OnDestroy
{
  formPath: string;
  locationId: string;
  stateClassCodes: ClassCodeResult;

  sub: Subscription = new Subscription();

  // Flags
  isChangingRoutes = false;
  loadingClassCodes = false;

  constructor(
    protected formService: AttuneWcQuoteFormService,
    protected route: ActivatedRoute,
    protected attuneWcClassCodesService: AttuneWcClassCodesService
  ) {
    super(formService);
  }

  ngOnInit(): void {
    // Setup subscription to get locationId from url params and set correct location form group.
    this.sub.add(
      this.route.paramMap
        .pipe(
          tap(() => {
            // This allows navigations to the next location to have a form animation although the component is re-used.
            this.isChangingRoutes = true;
          }),
          tap((params) => {
            const locationId = params.get('locationId');
            if (!locationId) {
              throw new Error('Unexpectedly missing locationId route param.');
            }
            this.locationId = locationId;
            const zeroIndexedLocationId = parseInt(this.locationId, 10) - 1;
            this.formPath = `locations.${zeroIndexedLocationId}`;

            super.ngOnInit();
          }),
          tap(() => {
            // Setup any form-dependent subscriptions.
            this.setupSubscriptions();
          }),
          // Allow the form transition animation to finish
          delay(300)
        )
        .subscribe(() => {
          this.isChangingRoutes = false;
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setupSubscriptions() {
    this.setupStateSubscription();
  }

  private setupStateSubscription() {
    const stateFormControl = this.form.get('address.state');
    if (!stateFormControl) {
      return;
    }

    let lastStateValue = stateFormControl.value;

    this.sub.add(
      stateFormControl.valueChanges
        .pipe(
          startWith(stateFormControl.value),
          filter((state) => state !== null),
          tap((_state: string) => {
            this.loadingClassCodes = true;
          }),
          switchMap((state: string) => {
            if (state !== lastStateValue) {
              lastStateValue = state;
              // If the state changes, we also want to clear the class code + descriptions.
              this.clearClassCodesForm();
            }

            return this.attuneWcClassCodesService.getClassCodes(state);
          })
        )
        .subscribe((result) => {
          this.handleClassCodeResult(result);
        })
    );
  }

  /**
   *  Class code methods
   */

  private clearClassCodesForm() {
    return this.formService.clearClassCodes(this.form);
  }

  classCodesFormArray() {
    return this.formService.classCodeFormArray(this.form);
  }

  moreThanOneClassCode() {
    return this.classCodesFormArray().length > 1;
  }

  addClassCode() {
    return this.formService.addClassCode(this.form);
  }

  removeClassCode(index: number) {
    return this.formService.removeClassCode(this.form, index);
  }

  get showClassCodeFetchError(): boolean {
    return !!(this.stateClassCodes as ErrorClassCodeFetch)?.error;
  }

  get showEmptyClassCodesWarning(): boolean {
    return (this.stateClassCodes as SuccessfulClassCodeFetch)?.classCodes?.length === 0;
  }

  get showClassCodesControls(): boolean {
    return (this.stateClassCodes as SuccessfulClassCodeFetch)?.classCodes?.length > 0;
  }

  private handleClassCodeResult(result: ClassCodeResult) {
    this.stateClassCodes = result;
    this.loadingClassCodes = false;
  }

  /**
   *  End of Class code methods
   */

  showDeductibleWarning() {
    return this.formService.showDeductibleChangedWarning;
  }

  dismissDeductibleWarning() {
    this.formService.showDeductibleChangedWarning = false;
  }

  deductibleWarningText() {
    return 'The selected deductible has been removed. It is not a valid option in this state.';
  }
}
