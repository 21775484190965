<app-modal
  [open]="open"
  ariaRole="alertdialog"
  ariaLabelledby="error-header"
  ariaDescribedby="error-body"
  modalClass="app-modal-content__left-align"
  (handleModalClose)="closeModal()"
>
  <h2 id="error-header" class="app-modal-header">Underwriting review triggered</h2>

  <ng-container *ngIf="uwAlerts && uwAlerts.length > 0">
    <div class="app-modal-error-msg-container js-validation-uw-alerts">
      <app-dialog-box type="warning" class="js-uw-alert-text dialog-text__full-width">
        This review was triggered by:
        <ul class="bulleted-list">
          <li *ngFor="let uwAlert of uwAlerts">{{ uwAlert.publicReason }}</li>
        </ul>
      </app-dialog-box>
    </div>
  </ng-container>

  <p>
    This quote will be reviewed after bind. <strong>If deemed ineligible</strong>, the policy will
    be <strong>cancelled</strong>. Please double check eligibility with our guidelines.
  </p>

  <div class="sectioned-guidelines">
    <details
      data-amplitude-input-name="UW-alert-guidelines-toggle"
      class="js-uw-alert-guideline-details"
      *ngIf="naicsCode && guidelinesData"
      (toggle)="handleGuidelinesClick($event)"
    >
      <summary class="js-uw-alert-naics-desc sectioned-details">
        View guidelines for {{ naicsCode.description }}
      </summary>
      <ul class="js-uw-alert-guidelines">
        <li *ngFor="let guideline of guidelinesData">{{ guideline }}</li>
      </ul>
    </details>
  </div>

  <div class="nav-button-group">
    <a
      class="button button__secondary button__no-margin"
      href="https://attunehelp.zendesk.com/hc/en-us/articles/360045229952"
      >Why am I seeing this?</a
    >
    <button
      type="button"
      class="button button__primary button__no-margin js-close-modal"
      (click)="closeModal()"
    >
      Close
    </button>
  </div>
</app-modal>
