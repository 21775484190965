import { Component, Input, OnInit } from '@angular/core';
import {
  SERVICING_INFO_BY_CARRIER,
  ServicingInfo,
  ServicingInfoCarrier,
} from '../carrier-partner-servicing-info/helpers';

@Component({
  selector: 'app-post-bind-servicing-information',
  templateUrl: './post-bind-servicing-information.component.html',
})
export class PostBindServicingInformationComponent implements OnInit {
  @Input() carrier: ServicingInfoCarrier;

  servicingInfo: ServicingInfo;

  constructor() {}

  ngOnInit(): void {
    this.servicingInfo = SERVICING_INFO_BY_CARRIER[this.carrier];
  }
}
