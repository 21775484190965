import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BopGWPolicyPaymentPresenter } from 'app/features/attune-bop/models/bop-gw-policy-payment-presenter';

import { HabBindQuoteService } from 'app/hab/services/hab-bind-quote.service';
import { DISABLED_PAYMENT_OPTION, HAB_POLICY_PAYMENT_PLAN_OPTIONS } from 'app/hab/constants';

@Component({
  selector: 'app-hab-bind-page',
  templateUrl: './hab-bind-payment-details.component.html',
})
export class HabBindPaymentDetailsComponent implements OnInit {
  form: UntypedFormGroup;
  formPath = 'paymentDetails';
  policyPaymentPresenter: BopGWPolicyPaymentPresenter | null = null;
  sub: Subscription;
  paymentPlanOptions = HAB_POLICY_PAYMENT_PLAN_OPTIONS;
  disabledPaymentPlanOptions = [DISABLED_PAYMENT_OPTION];

  constructor(protected formService: HabBindQuoteService) {}

  ngOnInit() {
    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
    if (!this.form) {
      throw new Error(`formPath (${this.formPath}) not found`);
    }

    this.sub = this.formService.policyPaymentPresenter$.subscribe(
      (policyPaymentPresenter: BopGWPolicyPaymentPresenter) => {
        this.policyPaymentPresenter = policyPaymentPresenter;
      }
    );
  }

  hasSubmitted() {
    return this.formService.submitted;
  }
}
