import { Industry } from '../../../shared/services/naics.service';

export const COALITION_CYBER_INDUSTRIES: Industry[] = [
  {
    displayName: 'Auto Components - Auto Parts, Equipment, and Accessories',
    id: 225,
  },
  {
    displayName:
      'Auto Components - Auto Parts, Equipment, and Accessories (Auto Metal Part Wholesaler)',
    id: 226,
  },
  {
    displayName: 'Auto Components - Tires and Rubber',
    id: 227,
  },
  {
    displayName: 'Automobiles - Automobile Manufacturers',
    id: 229,
  },
  {
    displayName: 'Automobiles - Motorcycle Manufacturers',
    id: 230,
  },
  {
    displayName: 'Automobiles - Motorcycle Manufacturers (Motorcycle Dealer)',
    id: 231,
  },
  {
    displayName: 'Banks - Diversified Banks',
    id: 722,
  },
  {
    displayName: 'Banks - Diversified Banks (Central Reserve Depository Institutions)',
    id: 723,
  },
  {
    displayName: 'Banks - Diversified Banks (Foreign Banking and Branches Of Foreign Banks)',
    id: 724,
  },
  {
    displayName: 'Banks - Diversified Banks (Private and Industrial Banking)',
    id: 725,
  },
  {
    displayName: 'Banks - Regional Banks (National Commercial Banks)',
    id: 727,
  },
  {
    displayName: 'Banks - Regional Banks (State and Regional Commercial Banks)',
    id: 728,
  },
  {
    displayName: 'Banks - Thrifts and Mortgage Finance',
    id: 730,
  },
  {
    displayName: 'Banks - Thrifts and Mortgage Finance (Mortgage Banking and Services)',
    id: 731,
  },
  {
    displayName: 'Banks - Thrifts and Mortgage Finance (Mortgage Broker)',
    id: 1034,
  },
  {
    displayName: 'Banks - Thrifts and Mortgage Finance (Savings Institutions)',
    id: 732,
  },
  {
    displayName: 'Beverages - Breweries',
    id: 628,
  },
  {
    displayName: 'Beverages - Breweries (Brewery Manufacturing)',
    id: 629,
  },
  {
    displayName: 'Beverages - Distillers and Vintners',
    id: 630,
  },
  {
    displayName: 'Beverages - Distillers and Vintners (Vineyard)',
    id: 1057,
  },
  {
    displayName: 'Beverages - Soft Drinks',
    id: 631,
  },
  {
    displayName: 'Capital Goods - Aerospace and Defense',
    id: 64,
  },
  {
    displayName: 'Capital Goods - Building Products',
    id: 66,
  },
  {
    displayName: 'Capital Goods - Building Products (Furniture or Fixture Manufacturing)',
    id: 67,
  },
  {
    displayName: 'Capital Goods - Industrial Conglomerates',
    id: 79,
  },
  {
    displayName: 'Capital Goods - Trading Companies and Distributors (Industrial Distribution)',
    id: 85,
  },
  {
    displayName:
      'Capital Goods - Trading Companies and Distributors (Industrial Distribution) (Aerospace and Airlines Parts Distribution)',
    id: 1041,
  },
  {
    displayName:
      'Capital Goods - Trading Companies and Distributors (Industrial Distribution) (Refrigeration System or Equipment Wholesaler)',
    id: 86,
  },
  {
    displayName:
      'Capital Goods - Trading Companies and Distributors (Industrial Distribution) (Steam and Air Conditioning Supply)',
    id: 87,
  },
  {
    displayName: 'Capital Markets - Asset Management and Custody Banks',
    id: 749,
  },
  {
    displayName: 'Capital Markets - Asset Management and Custody Banks (Family Office)',
    id: 997,
  },
  {
    displayName: 'Capital Markets - Asset Management and Custody Banks (Hedge Fund)',
    id: 750,
  },
  {
    displayName:
      'Capital Markets - Asset Management and Custody Banks (Institutional Investment Advice)',
    id: 753,
  },
  {
    displayName: 'Capital Markets - Asset Management and Custody Banks (Investment Funds)',
    id: 755,
  },
  {
    displayName: 'Capital Markets - Asset Management and Custody Banks (Pension Fund)',
    id: 1023,
  },
  {
    displayName:
      'Capital Markets - Asset Management and Custody Banks (Personal Investment Advice)',
    id: 754,
  },
  {
    displayName: 'Capital Markets - Asset Management and Custody Banks (Private Equity)',
    id: 752,
  },
  {
    displayName: 'Capital Markets - Asset Management and Custody Banks (Venture Capital)',
    id: 751,
  },
  {
    displayName: 'Capital Markets - Diversified Capital Markets',
    id: 764,
  },
  {
    displayName: 'Capital Markets - Financial Exchanges and Data',
    id: 765,
  },
  {
    displayName: 'Capital Markets - Financial Exchanges and Data (Security and Commodity Exchange)',
    id: 766,
  },
  {
    displayName: 'Capital Markets - Investment Banking and Brokerage',
    id: 756,
  },
  {
    displayName: 'Capital Markets - Investment Banking and Brokerage (Commodity Broker)',
    id: 758,
  },
  {
    displayName:
      'Capital Markets - Investment Banking and Brokerage (Internet Security Brokers and Dealers)',
    id: 763,
  },
  {
    displayName: 'Capital Markets - Investment Banking and Brokerage (Investment Banking)',
    id: 760,
  },
  {
    displayName:
      'Capital Markets - Investment Banking and Brokerage (Investment Brokerage Services)',
    id: 761,
  },
  {
    displayName:
      'Capital Markets - Investment Banking and Brokerage (Securities and Commodities Services (excl. online))',
    id: 762,
  },
  {
    displayName:
      'Capital Markets - Investment Banking and Brokerage (Security Broker / Dealer and Flotation Company)',
    id: 759,
  },
  {
    displayName: 'Capital Markets - Investment Banking and Brokerage (Stockbroker)',
    id: 757,
  },
  {
    displayName: 'Chemicals - Commodity Chemicals',
    id: 26,
  },
  {
    displayName: 'Chemicals - Commodity Chemicals (Chemical Petroleum Manufacturing)',
    id: 29,
  },
  {
    displayName: 'Chemicals - Commodity Chemicals (Plastic Manufacturing)',
    id: 28,
  },
  {
    displayName: 'Chemicals - Commodity Chemicals (Rubber Manufacturing)',
    id: 30,
  },
  {
    displayName: 'Chemicals - Diversified Chemicals',
    id: 31,
  },
  {
    displayName: 'Chemicals - Fertilizers and Agricultural Chemicals',
    id: 32,
  },
  {
    displayName: 'Chemicals - Industrial Gases',
    id: 33,
  },
  {
    displayName: 'Chemicals - Specialty Chemicals',
    id: 34,
  },
  {
    displayName: 'Commercial Services and Supplies - Commercial Printing',
    id: 90,
  },
  {
    displayName: 'Commercial Services and Supplies - Commercial Printing (Engraving)',
    id: 94,
  },
  {
    displayName: 'Commercial Services and Supplies - Commercial Printing (Fabric Screen Printing)',
    id: 91,
  },
  {
    displayName: 'Commercial Services and Supplies - Commercial Printing (Lithography Printing)',
    id: 92,
  },
  {
    displayName: 'Commercial Services and Supplies - Commercial Printing (Other Printing)',
    id: 93,
  },
  {
    displayName: 'Commercial Services and Supplies - Diversified Support Services',
    id: 115,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Background Screening Firm)',
    id: 120,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Building Care and Improvement)',
    id: 123,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Commercial Auction)',
    id: 124,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Commercial Design)',
    id: 125,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Commercial or Industrial Laundry)',
    id: 130,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Commercial Photography)',
    id: 126,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Commercial Rental and Leasing)',
    id: 128,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Commercial Repair Services)',
    id: 129,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Correctional Institution)',
    id: 118,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Customer Call Center or Answering Service)',
    id: 122,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Government Contracting / Consulting)',
    id: 1013,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Inspection Service)',
    id: 117,
  },
  {
    displayName: 'Commercial Services and Supplies - Diversified Support Services (Land Surveyor)',
    id: 116,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Printing Prepress Support Service)',
    id: 127,
  },
  {
    displayName: 'Commercial Services and Supplies - Diversified Support Services (Repo Services)',
    id: 121,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Title Escrow and Settlement Office)',
    id: 119,
  },
  {
    displayName:
      'Commercial Services and Supplies - Diversified Support Services (Warehousing and Storage)',
    id: 131,
  },
  {
    displayName:
      'Commercial Services and Supplies - Environmental and Facilities Services (Agricultural Services)',
    id: 101,
  },
  {
    displayName:
      'Commercial Services and Supplies - Environmental and Facilities Services (Air and Water Resource and Solid Waste Management)',
    id: 96,
  },
  {
    displayName:
      'Commercial Services and Supplies - Environmental and Facilities Services (Environmental Consulting)',
    id: 98,
  },
  {
    displayName:
      'Commercial Services and Supplies - Environmental and Facilities Services (Janitorial / Sanitary Services)',
    id: 100,
  },
  {
    displayName:
      'Commercial Services and Supplies - Environmental and Facilities Services (Meteorology and Weather Services)',
    id: 99,
  },
  {
    displayName:
      'Commercial Services and Supplies - Environmental and Facilities Services (Recycling)',
    id: 1033,
  },
  {
    displayName:
      'Commercial Services and Supplies - Environmental and Facilities Services (Sewerage System)',
    id: 97,
  },
  {
    displayName: 'Commercial Services and Supplies - Office Services and Supplies',
    id: 102,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Debt Collection and Debt Management Services)',
    id: 103,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Document Management and Storage)',
    id: 1053,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Ink Cartridge Refill Shop)',
    id: 112,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Media and Photoduplication Service)',
    id: 109,
  },
  {
    displayName: 'Commercial Services and Supplies - Office Services and Supplies (Notary Public)',
    id: 108,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Office Administrative Service)',
    id: 113,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Office Equipment Rental and Leasing)',
    id: 105,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Office Furnishings and Equipment)',
    id: 104,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Office Machine and Appliance Installation and Repair)',
    id: 111,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Office Products)',
    id: 106,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Outsourced Business Services)',
    id: 107,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (School and Art Supplies)',
    id: 114,
  },
  {
    displayName:
      'Commercial Services and Supplies - Office Services and Supplies (Secretarial Document Preparation Service)',
    id: 110,
  },
  {
    displayName: 'Commercial Services and Supplies - Security and Alarm Services',
    id: 132,
  },
  {
    displayName: 'Commercial Services and Supplies - Security and Alarm Services (Fire Protection)',
    id: 133,
  },
  {
    displayName: 'Construction and Engineering - Construction and Engineering Products',
    id: 69,
  },
  {
    displayName:
      'Construction and Engineering - Construction and Engineering Products (Irrigation System)',
    id: 70,
  },
  {
    displayName: 'Consumer Durables and Apparel - Leisure Products',
    id: 244,
  },
  {
    displayName: 'Consumer Durables and Apparel - Leisure Products (Instrument Manufacturing)',
    id: 245,
  },
  {
    displayName: 'Consumer Durables and Apparel - Leisure Products (Musical Instruments)',
    id: 246,
  },
  {
    displayName:
      'Consumer Durables and Apparel - Leisure Products (Other Vehicle Dealership (Recreation, Other))',
    id: 998,
  },
  {
    displayName:
      'Consumer Durables and Apparel - Leisure Products (Sporting and Recreational Goods)',
    id: 247,
  },
  {
    displayName: 'Consumer Finance - Consumer Finance / Consumer Lending',
    id: 746,
  },
  {
    displayName: 'Consumer Finance - Consumer Finance / Consumer Lending (Check Cashing)',
    id: 747,
  },
  {
    displayName: 'Consumer Finance - Consumer Finance / Consumer Lending (Peer-to-Peer Lending)',
    id: 991,
  },
  {
    displayName: 'Containers and Packaging - Metal and Glass Containers',
    id: 38,
  },
  {
    displayName:
      'Containers and Packaging - Metal and Glass Containers (Stone, Clay, and Glass Manufacturing)',
    id: 39,
  },
  {
    displayName: 'Containers and Packaging - Paper Packaging',
    id: 40,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services',
    id: 297,
  },
  {
    displayName:
      'Diversified Consumer Services - Education Services (Automobile Driving Instruction)',
    id: 306,
  },
  {
    displayName:
      'Diversified Consumer Services - Education Services (Business and Secretarial School)',
    id: 304,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (Child Care Commercial)',
    id: 308,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (Child Care Residential)',
    id: 309,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (Child Day Care Services)',
    id: 307,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (College)',
    id: 300,
  },
  {
    displayName:
      'Diversified Consumer Services - Education Services (Educational Curriculum Development)',
    id: 310,
  },
  {
    displayName:
      'Diversified Consumer Services - Education Services (Elementary and Secondary School)',
    id: 302,
  },
  {
    displayName:
      'Diversified Consumer Services - Education Services (Exam Preparation and Tutoring)',
    id: 311,
  },
  {
    displayName:
      'Diversified Consumer Services - Education Services (Junior College and Technical Institute)',
    id: 305,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (Libraries)',
    id: 298,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (Schools)',
    id: 299,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (University)',
    id: 301,
  },
  {
    displayName: 'Diversified Consumer Services - Education Services (Vocational School)',
    id: 303,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services',
    id: 312,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Art Conservator)',
    id: 323,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Automobile Conversion or Customization)',
    id: 341,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Automobile Service or Repair Center)',
    id: 342,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Automotive Top Body and Upholstery Repair and Paint)',
    id: 340,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Barber)',
    id: 327,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Beauty Care)',
    id: 326,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Boat Dealer)',
    id: 324,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Car Body Repair, Paint, and Glass Replacement)',
    id: 339,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Carpentry)',
    id: 358,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Carpet Rug or Upholstery Cleaning Service)',
    id: 367,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Car Washes)',
    id: 343,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Cemeteries, Crematories, and Funeral Homes)',
    id: 328,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Commercial and Household Appliance Repair)',
    id: 336,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Construction Contractor)',
    id: 350,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Construction General Contractor)',
    id: 995,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Construction Subcontractor)',
    id: 351,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Consumer Auctions)',
    id: 329,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Consumer Photography)',
    id: 330,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Consumer Rental and Leasing)',
    id: 331,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Consumer Repair)',
    id: 332,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Counseling Service)',
    id: 322,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Domestic Cleaning and Housekeeping)',
    id: 338,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Door Window or Assembled Millwork Installation)',
    id: 362,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Driveway Parking or Sidewalk Repair)',
    id: 349,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Dry Cleaner or Laundromat)',
    id: 319,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Dry Wall or Wallboard Installation)',
    id: 356,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Electrical Work)',
    id: 354,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Embroidery Services)',
    id: 368,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Fence Erection Contractor)',
    id: 365,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Floor Covering Installation)',
    id: 363,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Forestry Consulting)',
    id: 361,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Handyperson)',
    id: 359,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Home Furnishing Installation)',
    id: 366,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Kennel)',
    id: 314,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Landscape Architect)',
    id: 373,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Landscape Gardening)',
    id: 360,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Locksmith, Keys, and Cutting)',
    id: 380,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Masonry)',
    id: 355,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Moving / Storage Company)',
    id: 325,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Painting Service)',
    id: 353,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Parking)',
    id: 344,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Personal Detective Services)',
    id: 374,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Pet Day Care)',
    id: 315,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Pet Detective)',
    id: 375,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Pet Grooming)',
    id: 313,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Pet Motel Hotel)',
    id: 316,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Pet Sitter)',
    id: 317,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Pet Training)',
    id: 318,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Plumbing, and Heating, Air Conditioning, and Ventilation (HVAC) Service)',
    id: 352,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential and Personal Safety and Security Consulting)',
    id: 378,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential and Personal Security Services)',
    id: 376,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential Building Care and Improvement)',
    id: 348,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential Burglary Protection)',
    id: 377,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential Design)',
    id: 372,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential Janitorial and Window Cleaning Service)',
    id: 371,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential Locksmith Services)',
    id: 379,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Residential Security Systems)',
    id: 381,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Sewing Machine Repair)',
    id: 333,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Sheet Metal Work Siding and Installation)',
    id: 364,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Shoe Repair and Shoe Shine Stand)',
    id: 320,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Small Motor Engine Repair)',
    id: 334,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Taxidermist)',
    id: 321,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Television or Radio Installation and Repair)',
    id: 335,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Tile, Stone, and Marble Work)',
    id: 357,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Tire Retreading and Repair)',
    id: 346,
  },
  {
    displayName: 'Diversified Consumer Services - Specialized Consumer Services (Towing Services)',
    id: 345,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Transmission Repair)',
    id: 347,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Upholstery Service)',
    id: 370,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Watch or Clock Repair)',
    id: 337,
  },
  {
    displayName:
      'Diversified Consumer Services - Specialized Consumer Services (Window Treatment, Installation, and Decorating)',
    id: 369,
  },
  {
    displayName: 'Diversified Financial Services - Multi-Sector Holdings',
    id: 740,
  },
  {
    displayName:
      'Diversified Financial Services - Other Diversified Financial Services (Commercial Lending / Loans (non-bank))',
    id: 739,
  },
  {
    displayName:
      'Diversified Financial Services - Other Diversified Financial Services (Investment Services and Holding Companies)',
    id: 737,
  },
  {
    displayName:
      'Diversified Financial Services - Other Diversified Financial Services (Mortgage Servicing / Mortgage Services)',
    id: 992,
  },
  {
    displayName:
      'Diversified Financial Services - Other Diversified Financial Services (Other Financial Services (Lending))',
    id: 1055,
  },
  {
    displayName:
      'Diversified Financial Services - Other Diversified Financial Services (Other Financial Services (Non-Lending))',
    id: 1056,
  },
  {
    displayName:
      'Diversified Financial Services - Other Diversified Financial Services (Student Loan Lending)',
    id: 738,
  },
  {
    displayName:
      'Diversified Financial Services - Other Diversified Financial Services (Trust Company)',
    id: 736,
  },
  {
    displayName: 'Diversified Financial Services - Specialized Finance',
    id: 741,
  },
  {
    displayName:
      'Diversified Financial Services - Specialized Finance (Credit Monitoring / Credit Consulting)',
    id: 993,
  },
  {
    displayName: 'Diversified Financial Services - Specialized Finance (Credit Unions)',
    id: 744,
  },
  {
    displayName:
      'Diversified Financial Services - Specialized Finance (Short Term Business Credit Institution)',
    id: 742,
  },
  {
    displayName: 'Domestic Non-Profit - Advocacy Organizations',
    id: 961,
  },
  {
    displayName: 'Domestic Non-Profit - Advocacy Organizations (Social Services)',
    id: 986,
  },
  {
    displayName: 'Domestic Non-Profit - Charitable Organizations (Food Bank or Soup Kitchen)',
    id: 963,
  },
  {
    displayName: 'Domestic Non-Profit - Charitable Organizations (Other Charitable Organizations)',
    id: 966,
  },
  {
    displayName:
      'Domestic Non-Profit - Charitable Organizations (Transitional Housing and Shelter)',
    id: 964,
  },
  {
    displayName: 'Domestic Non-Profit - Charitable Organizations (Voluntary Health Organizations)',
    id: 965,
  },
  {
    displayName: 'Domestic Non-Profit - Churches and Religious Organizations',
    id: 967,
  },
  {
    displayName:
      'Domestic Non-Profit - Churches and Religious Organizations (Education Religious and Charitable Trust)',
    id: 968,
  },
  {
    displayName: 'Domestic Non-Profit - Political Organizations',
    id: 969,
  },
  {
    displayName: 'Domestic Non-Profit - Private Foundations',
    id: 970,
  },
  {
    displayName: 'Domestic Non-Profit - Professional Associations',
    id: 971,
  },
  {
    displayName: 'Domestic Non-Profit - Professional Associations (Business Association)',
    id: 972,
  },
  {
    displayName: 'Domestic Non-Profit - Social and Membership Organizations',
    id: 973,
  },
  {
    displayName: 'Domestic Non-Profit - Social and Membership Organizations (Alumni Association)',
    id: 974,
  },
  {
    displayName: 'Domestic Non-Profit - Social and Membership Organizations (Civic Association)',
    id: 975,
  },
  {
    displayName:
      'Domestic Non-Profit - Social and Membership Organizations (Civil and Social Club)',
    id: 976,
  },
  {
    displayName: 'Domestic Non-Profit - Social and Membership Organizations (Historical Society)',
    id: 977,
  },
  {
    displayName:
      'Domestic Non-Profit - Social and Membership Organizations (Horticultural Society)',
    id: 978,
  },
  {
    displayName:
      'Domestic Non-Profit - Social and Membership Organizations (Land, Mineral, Wildlife, and Forest Conservation)',
    id: 979,
  },
  {
    displayName:
      'Domestic Non-Profit - Social and Membership Organizations (Youth Community Program)',
    id: 980,
  },
  {
    displayName: 'Domestic Non-Profit - Trade Groups and Labor Unions',
    id: 981,
  },
  {
    displayName: 'Domestic Non-Profit - Trade Groups and Labor Unions (Trade Association)',
    id: 982,
  },
  {
    displayName: 'Electrical Equipment - Electrical Components and Equipment',
    id: 72,
  },
  {
    displayName:
      'Electrical Equipment - Electrical Components and Equipment (Electrical Machinery Equipment and Supply)',
    id: 73,
  },
  {
    displayName:
      'Electrical Equipment - Electrical Components and Equipment (Power Distribution and Specialty Transformers)',
    id: 74,
  },
  {
    displayName: 'Electrical Equipment - Heavy Electrical Equipment',
    id: 75,
  },
  {
    displayName:
      'Electrical Equipment - Heavy Electrical Equipment (Electrical Industrial Apparatus)',
    id: 76,
  },
  {
    displayName:
      'Electrical Equipment - Heavy Electrical Equipment (Industrial Electronics Including Production and Process Control)',
    id: 77,
  },
  {
    displayName: 'Electronic Equipment, Instruments and Components - Electronic Components',
    id: 904,
  },
  {
    displayName:
      'Electronic Equipment, Instruments and Components - Electronic Equipment and Instruments',
    id: 903,
  },
  {
    displayName:
      'Electronic Equipment, Instruments and Components - Electronic Manufacturing Services',
    id: 905,
  },
  {
    displayName: 'Electronic Equipment, Instruments and Components - Technology Distributors',
    id: 906,
  },
  {
    displayName: 'Energy Equipment and Services - Oil and Gas Drilling',
    id: 9,
  },
  {
    displayName: 'Energy Equipment and Services - Oil and Gas Equipment and Services',
    id: 10,
  },
  {
    displayName:
      'Energy Equipment and Services - Oil and Gas Equipment and Services (Gas, Electric, and Other Services)',
    id: 11,
  },
  {
    displayName: 'Energy - Oil, Gas and Consumable Fuels',
    id: 12,
  },
  {
    displayName: 'Energy - Other Energy',
    id: 1044,
  },
  {
    displayName: 'Energy - Other Energy (Renewable Energy - Geothermal)',
    id: 1046,
  },
  {
    displayName: 'Energy - Other Energy (Renewable Energy - Hydroelectric)',
    id: 1048,
  },
  {
    displayName: 'Energy - Other Energy (Renewable Energy - Solar)',
    id: 1045,
  },
  {
    displayName: 'Energy - Other Energy (Renewable Energy - Wind)',
    id: 1047,
  },
  {
    displayName: 'Federal Level Government - National Government',
    id: 939,
  },
  {
    displayName:
      'Federal Level Government - National Government (Administration of Educational Program - Local)',
    id: 943,
  },
  {
    displayName:
      'Federal Level Government - National Government (Administration of General Economic Program - Local)',
    id: 944,
  },
  {
    displayName:
      'Federal Level Government - National Government (Administration of Housing Program - Local)',
    id: 945,
  },
  {
    displayName:
      'Federal Level Government - National Government (Administration of Social Human Resource and Income Maintenance Program - Local)',
    id: 946,
  },
  {
    displayName:
      'Federal Level Government - National Government (Administration of Urban Planning and Community and Rural Development - Local)',
    id: 947,
  },
  {
    displayName:
      'Federal Level Government - National Government (Central Reserve Depository Institution)',
    id: 940,
  },
  {
    displayName: 'Federal Level Government - National Government (Legislative Body)',
    id: 941,
  },
  {
    displayName: 'Federal Level Government - National Government (Space Research and Technology)',
    id: 942,
  },
  {
    displayName: 'Food and Staples Retailing - Drug Retail',
    id: 604,
  },
  {
    displayName: 'Food and Staples Retailing - Drug Retail (Pharmacy)',
    id: 605,
  },
  {
    displayName: 'Food and Staples Retailing - Food Distributors',
    id: 606,
  },
  {
    displayName: 'Food and Staples Retailing - Food Distributors (Beverage Wholesaler)',
    id: 608,
  },
  {
    displayName: 'Food and Staples Retailing - Food Distributors (Coffee and Tea Wholesaler)',
    id: 609,
  },
  {
    displayName: 'Food and Staples Retailing - Food Distributors (Food Wholesaler)',
    id: 607,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail',
    id: 610,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Bakery)',
    id: 617,
  },
  {
    displayName:
      'Food and Staples Retailing - Food Retail (Beverage Retail (Coffee, Soft Drink, Water, Liquor, Wine, Coffee, Juice, etc.))',
    id: 624,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Butcher)',
    id: 612,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Cheese Shop)',
    id: 614,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Cookie Retail)',
    id: 618,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Cupcake Retail)',
    id: 619,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Dairy Product Retail)',
    id: 615,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Dessert Retail)',
    id: 620,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Frozen Yogurt Retail)',
    id: 616,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Grocery Retail)',
    id: 611,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Health Food Retail)',
    id: 621,
  },
  {
    displayName:
      'Food and Staples Retailing - Food Retail (Snack, Candy, Confectionary, and Nut Retail)',
    id: 613,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Specialty Food Retail)',
    id: 622,
  },
  {
    displayName: 'Food and Staples Retailing - Food Retail (Spice Retail)',
    id: 623,
  },
  {
    displayName: 'Food and Staples Retailing - Hypermarkets and Super Centers',
    id: 625,
  },
  {
    displayName: 'Food, Beverage and Tobacco - Tobacco',
    id: 641,
  },
  {
    displayName: 'Food Products - Agricultural Products',
    id: 633,
  },
  {
    displayName: 'Food Products - Agricultural Products (Agricultural Production Crops)',
    id: 634,
  },
  {
    displayName: 'Food Products - Agricultural Products (Agricultural Production Livestock)',
    id: 635,
  },
  {
    displayName: 'Food Products - Agricultural Products (Cannabis / Marijuana Horticulture Grower)',
    id: 989,
  },
  {
    displayName:
      'Food Products - Agricultural Products (Cannabis Non-Marijuana Horticulture Grower)',
    id: 1039,
  },
  {
    displayName: 'Food Products - Agricultural Products (Fishing, Hunting, and Trapping)',
    id: 636,
  },
  {
    displayName: 'Food Products - Packaged Foods and Meats',
    id: 637,
  },
  {
    displayName: 'Food Products - Packaged Foods and Meats (Food Product Manufacturing)',
    id: 638,
  },
  {
    displayName: 'Food Products - Packaged Foods and Meats (Meat Product Manufacturing)',
    id: 639,
  },
  {
    displayName: 'Global Government - International Bodies and Organizations',
    id: 937,
  },
  {
    displayName: 'Healthcare Equipment and Supplies - Healthcare Equipment',
    id: 650,
  },
  {
    displayName:
      'Healthcare Equipment and Supplies - Healthcare Equipment (Pharmaceutical or Surgical Good Manufacturing)',
    id: 651,
  },
  {
    displayName: 'Healthcare Equipment and Supplies - Healthcare Supplies',
    id: 652,
  },
  {
    displayName: 'Healthcare Equipment and Supplies - Healthcare Supplies (Dental Laboratory)',
    id: 653,
  },
  {
    displayName: 'Healthcare Providers and Services - Healthcare Distributors',
    id: 655,
  },
  {
    displayName: 'Healthcare Providers and Services - Healthcare Facilities',
    id: 704,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Facilities (Assisted Living Facilities and Services)',
    id: 705,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Facilities (Hospitals and Healthcare Centers)',
    id: 706,
  },
  {
    displayName: 'Healthcare Providers and Services - Healthcare Facilities (Retirement Home)',
    id: 1031,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Facilities (Veterinary Services and Animal Hospitals)',
    id: 707,
  },
  {
    displayName: 'Healthcare Providers and Services - Healthcare Services / Doctors',
    id: 656,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Dental Offices and Dentistry)',
    id: 657,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Healthcare Provider Specialists)',
    id: 661,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Healthcare Referral Services)',
    id: 662,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Home Healthcare)',
    id: 658,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Laboratory Services)',
    id: 699,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Acupuncturist)',
    id: 665,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Allergist)',
    id: 668,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Audiologist)',
    id: 669,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Cardiologist)',
    id: 670,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Chiropractor)',
    id: 666,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Dermatologist)',
    id: 671,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Diagnostic Center)',
    id: 672,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Dialysis Center)',
    id: 673,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Dietician)',
    id: 674,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Ear Nose Throat)',
    id: 675,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Endocrinologist)',
    id: 676,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Gastroenterologist)',
    id: 677,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office General)',
    id: 667,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office General Health Practitioner or Primary Care Physician)',
    id: 678,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Geriatric Specialist)',
    id: 679,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Gynecologist)',
    id: 680,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Hematologist)',
    id: 681,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Internist)',
    id: 682,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Laser Eye Surgery Center)',
    id: 683,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Neurologist)',
    id: 684,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Obstetrician)',
    id: 685,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Occupational Therapist)',
    id: 686,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Oncologist)',
    id: 687,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Ophthalmologist)',
    id: 688,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Optometrist)',
    id: 689,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Orthopedic Surgeon)',
    id: 690,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Osteopath)',
    id: 691,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Pediatrician)',
    id: 692,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Podiatrist)',
    id: 693,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Psychiatrist)',
    id: 694,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Psychologist)',
    id: 695,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Pulmonologist)',
    id: 696,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Speech Therapist)',
    id: 697,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office Urologist)',
    id: 698,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Office X Rays Imaging Laboratory)',
    id: 700,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Specialists)',
    id: 664,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Medical Transcription)',
    id: 663,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Mental Care Facilities)',
    id: 701,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Midwifery and Child Birth Preparation)',
    id: 659,
  },
  {
    displayName: 'Healthcare Providers and Services - Healthcare Services / Doctors (Nursing)',
    id: 660,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Organ and Tissue Bank / Donor)',
    id: 703,
  },
  {
    displayName:
      'Healthcare Providers and Services - Healthcare Services / Doctors (Rehabilitation Services)',
    id: 702,
  },
  {
    displayName: 'Healthcare Technology - Healthcare Technology / Healthcare Software',
    id: 709,
  },
  {
    displayName:
      'Healthcare Technology - Healthcare Technology / Healthcare Software (Medical Devices / Medical Equipment)',
    id: 710,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Hotels, Resorts and Cruise Lines / Hospitality',
    id: 259,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities',
    id: 260,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Amusement Parks)',
    id: 261,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Aquarium)',
    id: 275,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Art Studio)',
    id: 273,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Billiard Hall)',
    id: 269,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Bowling Lane)',
    id: 266,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Club Country Fishing or Yacht)',
    id: 267,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Coin Operated Amusements)',
    id: 262,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Dance Studio)',
    id: 265,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Membership Sport and Recreation Club)',
    id: 277,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Museums, Art Galleries, Botanical Gardens, and Arboreums)',
    id: 272,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Recreation Clubs and Services)',
    id: 264,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Roller Skating Rink Operation)',
    id: 268,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Shooting Gallery or Gunrange)',
    id: 270,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Sporting and Recreational Camp)',
    id: 278,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Sports, Gyms, and Related Services)',
    id: 276,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Swimming Pool)',
    id: 271,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Video Game Arcades)',
    id: 263,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Leisure Facilities (Zoo)',
    id: 274,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants',
    id: 279,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Bar, Tavern, Watering Hole Retail)',
    id: 1040,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Bistro)',
    id: 288,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Buffet)',
    id: 289,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Cafe)',
    id: 290,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Caterer)',
    id: 291,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Coffee and Tea Retail)',
    id: 282,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Delicatessen)',
    id: 280,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Donut and Bagel Retail)',
    id: 287,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Family Restaurant)',
    id: 292,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Fast Food Restaurant)',
    id: 293,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Fine Dining Restaurant)',
    id: 294,
  },
  {
    displayName:
      'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Food Concession and Refreshment Stand)',
    id: 283,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Ice Cream Retail)',
    id: 281,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Pizza Restaurant)',
    id: 295,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Sandwich Shop)',
    id: 284,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Smoothie Bar)',
    id: 285,
  },
  {
    displayName: 'Hotels, Restaurants, and Leisure / Hospitality - Restaurants (Snack Bar)',
    id: 286,
  },
  {
    displayName: 'Household and Personal Products - Household Products',
    id: 644,
  },
  {
    displayName: 'Household and Personal Products - Personal Products',
    id: 646,
  },
  {
    displayName: 'Household Durables - Consumer Electronics (Consumer Electronics)',
    id: 235,
  },
  {
    displayName: 'Household Durables - Consumer Electronics (Radar Detectors)',
    id: 237,
  },
  {
    displayName:
      'Household Durables - Consumer Electronics (Security Systems, Alarms, and Cameras)',
    id: 236,
  },
  {
    displayName: 'Household Durables - Homebuilding',
    id: 240,
  },
  {
    displayName: 'Household Durables - Home Furnishings',
    id: 238,
  },
  {
    displayName: 'Household Durables - Home Furnishings (Furniture and Cabinet Manufacturing)',
    id: 239,
  },
  {
    displayName: 'Household Durables - Household Appliances',
    id: 241,
  },
  {
    displayName: 'Household Durables - Housewares and Specialties',
    id: 242,
  },
  {
    displayName: 'Insurance - Insurance Brokers (Claim Adjusters)',
    id: 774,
  },
  {
    displayName: 'Insurance - Insurance Brokers (Claim Processors)',
    id: 775,
  },
  {
    displayName: 'Insurance - Insurance Brokers (Employee Benefits Insurance Brokerage)',
    id: 999,
  },
  {
    displayName:
      'Insurance - Insurance Brokers (Employee Benefits / Property and Casualty / Personal Lines Insurance Brokerage (Retail))',
    id: 1002,
  },
  {
    displayName: 'Insurance - Insurance Brokers (Personal Lines Insurance Brokerage)',
    id: 772,
  },
  {
    displayName: 'Insurance - Insurance Brokers (Property and Casualty Insurance Brokerage)',
    id: 773,
  },
  {
    displayName: 'Insurance - Insurance Brokers (Third Party Administrators)',
    id: 776,
  },
  {
    displayName: 'Insurance - Life and Health Insurance / Carrier',
    id: 777,
  },
  {
    displayName: 'Insurance - Multi-line Insurance / Carrier',
    id: 778,
  },
  {
    displayName: 'Insurance - Property and Casualty Insurance / Carrier',
    id: 779,
  },
  {
    displayName:
      'Insurance - Property and Casualty Insurance / Carrier (Property and Casualty - Commercial (Carrier))',
    id: 781,
  },
  {
    displayName:
      'Insurance - Property and Casualty Insurance / Carrier (Property and Casualty - Personal (Carrier))',
    id: 780,
  },
  {
    displayName: 'Insurance - Reinsurance',
    id: 782,
  },
  {
    displayName: 'International Non-Profit - International NGOs',
    id: 985,
  },
  {
    displayName: 'IT Services - Data Processing and Outsourced Services',
    id: 851,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services',
    id: 840,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services (Application Development)',
    id: 848,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services (Computer Consulting)',
    id: 849,
  },
  {
    displayName:
      'IT Services - IT Consulting and Other Services (Computer Facility Management Service)',
    id: 843,
  },
  {
    displayName:
      'IT Services - IT Consulting and Other Services (Computer Integrated System Design)',
    id: 844,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services (Computer Maintenance and Repair)',
    id: 845,
  },
  {
    displayName:
      'IT Services - IT Consulting and Other Services (Computer Programming Service - Custom)',
    id: 1051,
  },
  {
    displayName:
      'IT Services - IT Consulting and Other Services (Computer Programming Service - Prepackaged)',
    id: 846,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services (Computer Related Service)',
    id: 847,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services (Computer Software And Service)',
    id: 850,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services (Computer Systems Integrator)',
    id: 1050,
  },
  {
    displayName: 'IT Services - IT Consulting and Other Services (IT Project Management)',
    id: 841,
  },
  {
    displayName:
      'IT Services - IT Consulting and Other Services (IT Software and Hardware Training Service)',
    id: 842,
  },
  {
    displayName: 'Machinery - Agricultural and Farm Machinery',
    id: 82,
  },
  {
    displayName: 'Machinery - Construction Machinery and Heavy Trucks',
    id: 81,
  },
  {
    displayName: 'Machinery - Industrial Machinery',
    id: 83,
  },
  {
    displayName: 'Machinery - Industrial Machinery (Machinery Manufacturing)',
    id: 27,
  },
  {
    displayName:
      'Marine - Marine (Water and Marine Transportation) (Commercial Marine and Watercrafts)',
    id: 198,
  },
  {
    displayName:
      'Marine - Marine (Water and Marine Transportation) (Marine and Water Freight Transportation)',
    id: 199,
  },
  {
    displayName:
      'Marine - Marine (Water and Marine Transportation) (Marine and Water Passenger Transportation)',
    id: 200,
  },
  {
    displayName:
      'Marine - Marine (Water and Marine Transportation) (Safety and Rescue Marine and Watercraft)',
    id: 201,
  },
  {
    displayName: 'Materials - Construction Materials',
    id: 36,
  },
  {
    displayName: 'Materials - Containers and Packaging',
    id: 37,
  },
  {
    displayName: 'Materials - Paper and Forest Products',
    id: 56,
  },
  {
    displayName: 'Media - Advertising (Advertising)',
    id: 386,
  },
  {
    displayName: 'Media - Advertising (Direct Mail Advertising Service)',
    id: 385,
  },
  {
    displayName: 'Media - Advertising (Investor Relations Services)',
    id: 389,
  },
  {
    displayName: 'Media - Advertising (Marketing Services)',
    id: 387,
  },
  {
    displayName: 'Media - Advertising (Public Relations Services)',
    id: 388,
  },
  {
    displayName: 'Media - Advertising (Telemarketing And Telephone Soliciting Services)',
    id: 390,
  },
  {
    displayName: 'Media - Broadcasting',
    id: 391,
  },
  {
    displayName: 'Media - Broadcasting (Radio)',
    id: 392,
  },
  {
    displayName: 'Media - Broadcasting (Television)',
    id: 393,
  },
  {
    displayName: 'Media - Broadcasting (Web Broadcasts And Cybercasts)',
    id: 394,
  },
  {
    displayName: 'Media - Cable and Satellite',
    id: 395,
  },
  {
    displayName: 'Media - Cable and Satellite (Cable and Other Pay Television Service)',
    id: 396,
  },
  {
    displayName: 'Media - Movies and Entertainment',
    id: 397,
  },
  {
    displayName:
      'Media - Movies and Entertainment (Disc Jockey, Musician, Singer, Actor, or Entertainer)',
    id: 399,
  },
  {
    displayName: 'Media - Movies and Entertainment (Entertainment Equipment)',
    id: 401,
  },
  {
    displayName: 'Media - Movies and Entertainment (Entertainment Production Companies)',
    id: 402,
  },
  {
    displayName: 'Media - Movies and Entertainment (Entertainment Services)',
    id: 403,
  },
  {
    displayName: 'Media - Movies and Entertainment (Entertainment Venues)',
    id: 412,
  },
  {
    displayName: 'Media - Movies and Entertainment (Film Processing)',
    id: 404,
  },
  {
    displayName: 'Media - Movies and Entertainment (Media Buyer)',
    id: 407,
  },
  {
    displayName: 'Media - Movies and Entertainment (Motion Picture and Video Distribution)',
    id: 406,
  },
  {
    displayName: 'Media - Movies and Entertainment (Performing Arts Theater)',
    id: 1058,
  },
  {
    displayName: 'Media - Movies and Entertainment (Professional Sports Teams and Clubs)',
    id: 408,
  },
  {
    displayName: 'Media - Movies and Entertainment (Recording Studio)',
    id: 398,
  },
  {
    displayName: 'Media - Movies and Entertainment (Recreational Sports Teams and Services)',
    id: 409,
  },
  {
    displayName: 'Media - Movies and Entertainment (Stadium Operators)',
    id: 410,
  },
  {
    displayName: 'Media - Movies and Entertainment (Ticketing and Ticket Agencies)',
    id: 411,
  },
  {
    displayName: 'Media - Movies and Entertainment (Videotape Editing Service)',
    id: 405,
  },
  {
    displayName: 'Media - Publishing',
    id: 413,
  },
  {
    displayName: 'Media - Publishing (Bookbinding)',
    id: 415,
  },
  {
    displayName: 'Media - Publishing (Book Publishing)',
    id: 417,
  },
  {
    displayName: 'Media - Publishing (Editorial and Support Services)',
    id: 422,
  },
  {
    displayName: 'Media - Publishing (Electronic Publishing)',
    id: 420,
  },
  {
    displayName: 'Media - Publishing (Greeting Card Publisher)',
    id: 414,
  },
  {
    displayName: 'Media - Publishing (Music Publishing)',
    id: 416,
  },
  {
    displayName: 'Media - Publishing (News and Publicity Services)',
    id: 423,
  },
  {
    displayName: 'Media - Publishing (Newspaper and Magazine Publishing)',
    id: 419,
  },
  {
    displayName: 'Media - Publishing (Other Publishing)',
    id: 418,
  },
  {
    displayName: 'Media - Publishing (Technical and Creative Writing Services)',
    id: 424,
  },
  {
    displayName: 'Media - Publishing (Translation and Related Services)',
    id: 421,
  },
  {
    displayName: 'Metals and Mining - Aluminum',
    id: 42,
  },
  {
    displayName: 'Metals and Mining - Copper',
    id: 51,
  },
  {
    displayName: 'Metals and Mining - Diversified Metals and Mining',
    id: 43,
  },
  {
    displayName:
      'Metals and Mining - Diversified Metals and Mining (Fabricated Metal Manufacturing)',
    id: 49,
  },
  {
    displayName: 'Metals and Mining - Diversified Metals and Mining (General Manufacturing)',
    id: 50,
  },
  {
    displayName:
      'Metals and Mining - Diversified Metals and Mining (Plumbing Product Manufacturing)',
    id: 45,
  },
  {
    displayName:
      'Metals and Mining - Diversified Metals and Mining (Precision Machined Part Manufacturing)',
    id: 46,
  },
  {
    displayName: 'Metals and Mining - Diversified Metals and Mining (Primary Metal Manufacturing)',
    id: 44,
  },
  {
    displayName: 'Metals and Mining - Diversified Metals and Mining (Printing Manufacturing)',
    id: 47,
  },
  {
    displayName: 'Metals and Mining - Diversified Metals and Mining (Tool Manufacturing)',
    id: 48,
  },
  {
    displayName: 'Metals and Mining - Gold',
    id: 52,
  },
  {
    displayName: 'Metals and Mining - Precious Metals and Minerals',
    id: 53,
  },
  {
    displayName: 'Metals and Mining - Silver',
    id: 54,
  },
  {
    displayName: 'Metals and Mining - Steel',
    id: 55,
  },
  {
    displayName: 'Mortgage Real Estate Investment Trusts (REITs) - Mortgage REITs',
    id: 768,
  },
  {
    displayName: 'Multiline Retail - Department Stores',
    id: 508,
  },
  {
    displayName: 'Multiline Retail - General Merchandise Stores',
    id: 509,
  },
  {
    displayName: 'Oil, Gas and Consumable Fuels - Coal and Consumable Fuels',
    id: 21,
  },
  {
    displayName: 'Oil, Gas and Consumable Fuels - Coal and Consumable Fuels (Coal Mining)',
    id: 22,
  },
  {
    displayName: 'Oil, Gas and Consumable Fuels - Integrated Oil and Gas',
    id: 13,
  },
  {
    displayName:
      'Oil, Gas and Consumable Fuels - Integrated Oil and Gas (Liquefied Petroleum Gas Production and Distribution)',
    id: 15,
  },
  {
    displayName:
      'Oil, Gas and Consumable Fuels - Integrated Oil and Gas (Oil, Crude, Petroleum, and Natural Gas)',
    id: 14,
  },
  {
    displayName: 'Oil, Gas and Consumable Fuels - Oil and Gas Exploration and Production',
    id: 16,
  },
  {
    displayName: 'Oil, Gas and Consumable Fuels - Oil and Gas Refining and Marketing',
    id: 17,
  },
  {
    displayName:
      'Oil, Gas and Consumable Fuels - Oil and Gas Refining and Marketing (Refined Petroleum Pipeline)',
    id: 18,
  },
  {
    displayName: 'Oil, Gas and Consumable Fuels - Oil and Gas Storage and Transportation',
    id: 19,
  },
  {
    displayName:
      'Oil, Gas and Consumable Fuels - Oil and Gas Storage and Transportation (Crude, Petroleum, or Natural Gas Pipeline, Distribution, or Transmission)',
    id: 20,
  },
  {
    displayName: 'Paper and Forest Products - Forest Products',
    id: 57,
  },
  {
    displayName: 'Paper and Forest Products - Forest Products (Forestry)',
    id: 58,
  },
  {
    displayName: 'Paper and Forest Products - Paper Products',
    id: 59,
  },
  {
    displayName: 'Paper and Forest Products - Paper Products (Paper Manufacturing)',
    id: 60,
  },
  {
    displayName: 'Pharmaceuticals, Biotechnology and Life Sciences - Biotechnology',
    id: 713,
  },
  {
    displayName:
      'Pharmaceuticals, Biotechnology and Life Sciences - Biotechnology (Life Sciences and Bio Technologies (including Research))',
    id: 714,
  },
  {
    displayName:
      'Pharmaceuticals, Biotechnology and Life Sciences - Life Sciences Tools and Services',
    id: 718,
  },
  {
    displayName: 'Pharmaceuticals, Biotechnology and Life Sciences - Pharmaceuticals',
    id: 716,
  },
  {
    displayName:
      'Pharmaceuticals, Biotechnology and Life Sciences - Pharmaceuticals (Clinical Trials)',
    id: 1015,
  },
  {
    displayName: 'Professional Services - Human Resource and Employment Services',
    id: 135,
  },
  {
    displayName: 'Professional Services - Human Resource and Employment Services (Billing Service)',
    id: 139,
  },
  {
    displayName:
      'Professional Services - Human Resource and Employment Services (Employment Agency / Firm)',
    id: 137,
  },
  {
    displayName:
      'Professional Services - Human Resource and Employment Services (Personal Assistant Service)',
    id: 136,
  },
  {
    displayName:
      'Professional Services - Human Resource and Employment Services (Staffing Agency / Firm)',
    id: 138,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services',
    id: 141,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Accountant)',
    id: 149,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Accounting and Bookkeeping Firms and Services)',
    id: 150,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Actuarial Consulting)',
    id: 176,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Agricultural Consulting)',
    id: 171,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Animal Testing)',
    id: 1036,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Appraising Services)',
    id: 142,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Architect / Architecture)',
    id: 185,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Audio Visual Consulting)',
    id: 172,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Auditor)',
    id: 151,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Bail Bonds)',
    id: 179,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Business Management Consulting)',
    id: 163,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Cannabis / Marijuana Consulting)',
    id: 987,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Career Consulting)',
    id: 174,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Communication Media Public Relation Consulting)',
    id: 169,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Compensation Benefit Consulting)',
    id: 160,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Consulting Services)',
    id: 153,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Court Reporting Services)',
    id: 180,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Debt Consulting)',
    id: 175,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Educational Consulting)',
    id: 173,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Engineers / Engineering)',
    id: 186,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Event Planning Consulting)',
    id: 155,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Executive Coaching)',
    id: 158,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Financial Consulting)',
    id: 154,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Genealogical Investigator)',
    id: 148,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Graphic Art Design Consulting)',
    id: 156,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Healthcare Consulting)',
    id: 177,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Home, Building, and Real Estate Appraising)',
    id: 143,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Human Resource Consulting)',
    id: 161,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Image Consulting)',
    id: 168,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Interior Decorator)',
    id: 146,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Jewelry Appraiser)',
    id: 144,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Law Firms, Legal Counsel, and Lawyers (Real Estate / Title))',
    id: 1012,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Law Firms, Legal Counsel, Lawyers, and Prosecution)',
    id: 181,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Lobbying and Lobbyists)',
    id: 187,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Market Research Service)',
    id: 147,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Other Consulting)',
    id: 178,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Other Professional Services)',
    id: 188,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Patent Agents, Notaries Public, and Paralegal Services)',
    id: 182,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Procurement Consulting)',
    id: 164,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Project Management Consulting)',
    id: 165,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Research, Development, and Testing Services)',
    id: 183,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Residential Construction and Carpentry)',
    id: 145,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Sales and Marketing Consulting)',
    id: 166,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Strategic Planning Consulting)',
    id: 167,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Structured Settlement Consulting)',
    id: 162,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Tax Preparation and Services)',
    id: 152,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Testing Laboratories)',
    id: 184,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Training and Development Consulting)',
    id: 157,
  },
  {
    displayName: 'Professional Services - Research and Consulting Services (Vocational Consulting)',
    id: 159,
  },
  {
    displayName:
      'Professional Services - Research and Consulting Services (Wedding Planning Consulting)',
    id: 170,
  },
  {
    displayName:
      'Real Estate Management and Development - Commercial Real Estate (Commercial Condominium)',
    id: 933,
  },
  {
    displayName:
      'Real Estate Management and Development - Residential Real Estate (Homeowner Association)',
    id: 928,
  },
  {
    displayName: 'Real Estate Management and Development - Residential Real Estate (Realtor)',
    id: 929,
  },
  {
    displayName:
      'Real Estate Management and Development - Residential Real Estate (Residential Apartment, Condominium, and Townhouse)',
    id: 930,
  },
  {
    displayName:
      'Real Estate Management and Development - Residential Real Estate (Rooming and Boarding House)',
    id: 931,
  },
  {
    displayName: 'Real Estate - Real Estate Investment Trusts (REITs) excl. Mortgage',
    id: 924,
  },
  {
    displayName: 'Real Estate - Real Estate Management and Development',
    id: 926,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail',
    id: 494,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (Catalog Retail)',
    id: 498,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (E-commerce / Ecommerce)',
    id: 496,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (Internet Auctions)',
    id: 499,
  },
  {
    displayName:
      'Retailing - Internet and Direct Marketing Retail (Internet Beverage and Drinks Retail)',
    id: 500,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (Internet Broadline Retail)',
    id: 501,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (Internet Food and Drug Retail)',
    id: 503,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (Internet Hotel Booking)',
    id: 504,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (Internet Specialty Retail)',
    id: 505,
  },
  {
    displayName:
      'Retailing - Internet and Direct Marketing Retail (Internet Ticketing and Ticket Agencies)',
    id: 502,
  },
  {
    displayName:
      'Retailing - Internet and Direct Marketing Retail (Internet Transportation Booking and Travel Agencies (excl. Hotel))',
    id: 506,
  },
  {
    displayName:
      'Retailing - Internet and Direct Marketing Retail (Kiosk and Automatic Vending Machines)',
    id: 497,
  },
  {
    displayName: 'Retailing - Internet and Direct Marketing Retail (Travel Agency)',
    id: 495,
  },
  {
    displayName: 'Retailing - Retail Distribution',
    id: 427,
  },
  {
    displayName:
      'Retailing - Retail Distribution (Architect Drafting and Engineer Equipment Supply Wholesaler)',
    id: 440,
  },
  {
    displayName: 'Retailing - Retail Distribution (Artificial Flower Wholesaler)',
    id: 457,
  },
  {
    displayName: 'Retailing - Retail Distribution (Bag, Box, and Container Wholesaler)',
    id: 465,
  },
  {
    displayName:
      'Retailing - Retail Distribution (Bar, Hotel, and Restaurant Equipment, Fixture, and Supply Wholesaler)',
    id: 492,
  },
  {
    displayName: 'Retailing - Retail Distribution (Book Magazine Wholesaler)',
    id: 478,
  },
  {
    displayName: 'Retailing - Retail Distribution (Brass, Copper, and Specialty Item Wholesaler)',
    id: 480,
  },
  {
    displayName: 'Retailing - Retail Distribution (Burial Casket and Vault Box Wholesaler)',
    id: 451,
  },
  {
    displayName: 'Retailing - Retail Distribution (Cameras Photo Supply Wholesaler)',
    id: 436,
  },
  {
    displayName: 'Retailing - Retail Distribution (Candle Wholesaler)',
    id: 481,
  },
  {
    displayName:
      'Retailing - Retail Distribution (China, Glassware, Ceramics, and Pottery Wholesaler)',
    id: 429,
  },
  {
    displayName: 'Retailing - Retail Distribution (Clothing and Accessory Wholesaler)',
    id: 472,
  },
  {
    displayName: 'Retailing - Retail Distribution (Clothing Wholesaler)',
    id: 471,
  },
  {
    displayName: 'Retailing - Retail Distribution (Curtain and Drapery Wholesaler)',
    id: 467,
  },
  {
    displayName: 'Retailing - Retail Distribution (Cutlery Wholesaler)',
    id: 446,
  },
  {
    displayName:
      'Retailing - Retail Distribution (Dry Cleaning and Laundry Equipment Supply Wholesaler)',
    id: 453,
  },
  {
    displayName: 'Retailing - Retail Distribution (Education School Supply Wholesaler)',
    id: 464,
  },
  {
    displayName: 'Retailing - Retail Distribution (Electrical Equipment Wholesaler)',
    id: 442,
  },
  {
    displayName: 'Retailing - Retail Distribution (Exporter Importer)',
    id: 428,
  },
  {
    displayName: 'Retailing - Retail Distribution (Fabric Wholesaler)',
    id: 468,
  },
  {
    displayName: 'Retailing - Retail Distribution (Fastener Wholesaler)',
    id: 447,
  },
  {
    displayName: 'Retailing - Retail Distribution (Fence Supply Wholesaler)',
    id: 490,
  },
  {
    displayName: 'Retailing - Retail Distribution (Floor Covering Wholesaler)',
    id: 430,
  },
  {
    displayName: 'Retailing - Retail Distribution (Florist Wholesaler)',
    id: 479,
  },
  {
    displayName: 'Retailing - Retail Distribution (Fur, Garment, and Pelt Wholesaler)',
    id: 473,
  },
  {
    displayName:
      'Retailing - Retail Distribution (Furniture, Appliance Household, and Home Furnishing Wholesaler)',
    id: 444,
  },
  {
    displayName: 'Retailing - Retail Distribution (Gardening Supply Wholesaler)',
    id: 477,
  },
  {
    displayName: 'Retailing - Retail Distribution (Gift Wholesaler)',
    id: 482,
  },
  {
    displayName: 'Retailing - Retail Distribution (Glass, Window, and Mirror Wholesaler)',
    id: 435,
  },
  {
    displayName: 'Retailing - Retail Distribution (Grocery Wholesaler)',
    id: 475,
  },
  {
    displayName: 'Retailing - Retail Distribution (Hair Accessory Wholesaler)',
    id: 469,
  },
  {
    displayName: 'Retailing - Retail Distribution (Hardware Tool Wholesaler)',
    id: 445,
  },
  {
    displayName: 'Retailing - Retail Distribution (Hearing Aid Wholesaler)',
    id: 438,
  },
  {
    displayName:
      'Retailing - Retail Distribution (Heating, Air Conditioning, and Ventilation (HVAC) Equipment Wholesaler)',
    id: 448,
  },
  {
    displayName: 'Retailing - Retail Distribution (Hobby Craft and Artist Supply Wholesaler)',
    id: 456,
  },
  {
    displayName: 'Retailing - Retail Distribution (Humidifier and Dehumidifier Wholesaler)',
    id: 449,
  },
  {
    displayName: 'Retailing - Retail Distribution (Ice Dealer Wholesaler)',
    id: 483,
  },
  {
    displayName: 'Retailing - Retail Distribution (Industrial Paper Wholesaler)',
    id: 466,
  },
  {
    displayName: 'Retailing - Retail Distribution (Janitorial Supply Wholesaler)',
    id: 450,
  },
  {
    displayName: 'Retailing - Retail Distribution (Jewelry and Precious Metals Wholesaler)',
    id: 458,
  },
  {
    displayName: 'Retailing - Retail Distribution (Jewelry Box Wholesaler)',
    id: 484,
  },
  {
    displayName: 'Retailing - Retail Distribution (Kitchen Accessory Wholesaler)',
    id: 431,
  },
  {
    displayName: 'Retailing - Retail Distribution (Lamp Lighting and Fixture Wholesaler)',
    id: 443,
  },
  {
    displayName: 'Retailing - Retail Distribution (Leather Good and Luggage Wholesaler)',
    id: 460,
  },
  {
    displayName: 'Retailing - Retail Distribution (Linen Wholesaler)',
    id: 432,
  },
  {
    displayName: 'Retailing - Retail Distribution (Monument and Tombstone Wholesaler)',
    id: 461,
  },
  {
    displayName: 'Retailing - Retail Distribution (Music CD Wholesaler)',
    id: 485,
  },
  {
    displayName: 'Retailing - Retail Distribution (Office Equipment Wholesaler)',
    id: 437,
  },
  {
    displayName: 'Retailing - Retail Distribution (Optical Good Wholesaler)',
    id: 439,
  },
  {
    displayName: 'Retailing - Retail Distribution (Other Wholesaler)',
    id: 470,
  },
  {
    displayName: 'Retailing - Retail Distribution (Paint, Wallpaper, and Wall Covering Wholesaler)',
    id: 433,
  },
  {
    displayName: 'Retailing - Retail Distribution (Pet Supply Wholesaler)',
    id: 486,
  },
  {
    displayName: 'Retailing - Retail Distribution (Photometer Spectrograph Wholesaler)',
    id: 441,
  },
  {
    displayName: 'Retailing - Retail Distribution (Picture Frame Wholesaler)',
    id: 434,
  },
  {
    displayName: 'Retailing - Retail Distribution (Plastic and Rubber Supply Wholesaler)',
    id: 476,
  },
  {
    displayName: 'Retailing - Retail Distribution (Plumbing Wholesaler)',
    id: 1032,
  },
  {
    displayName: 'Retailing - Retail Distribution (Religious Article and Church Supply Wholesaler)',
    id: 487,
  },
  {
    displayName: 'Retailing - Retail Distribution (Shoe Repair and Materials Wholesaler)',
    id: 454,
  },
  {
    displayName: 'Retailing - Retail Distribution (Shoe Wholesaler)',
    id: 474,
  },
  {
    displayName: 'Retailing - Retail Distribution (Sign Wholesaler)',
    id: 462,
  },
  {
    displayName: 'Retailing - Retail Distribution (Sporting Good Wholesaler)',
    id: 455,
  },
  {
    displayName: 'Retailing - Retail Distribution (Stationery and Paper Product Wholesaler)',
    id: 463,
  },
  {
    displayName: 'Retailing - Retail Distribution (Sunglasses Wholesaler)',
    id: 488,
  },
  {
    displayName: 'Retailing - Retail Distribution (Toiletry Wholesaler)',
    id: 452,
  },
  {
    displayName: 'Retailing - Retail Distribution (Trophy Wholesaler)',
    id: 489,
  },
  {
    displayName: 'Retailing - Retail Distribution (Watch and Clock Wholesaler)',
    id: 459,
  },
  {
    displayName: 'Retailing - Retail Distribution (Wholesaler Other)',
    id: 491,
  },
  {
    displayName: 'Road and Rail - Railroads',
    id: 209,
  },
  {
    displayName: 'Road and Rail - Railroads (Railroad Passenger Transportation)',
    id: 210,
  },
  {
    displayName: 'Road and Rail - Trucking',
    id: 211,
  },
  {
    displayName: 'Road and Rail - Trucking (Ambulance Services)',
    id: 216,
  },
  {
    displayName: 'Road and Rail - Trucking (Car and Motor (Automotive) Vehicle Rental and Leasing)',
    id: 213,
  },
  {
    displayName: 'Road and Rail - Trucking (Freight Trucking, Trucker)',
    id: 214,
  },
  {
    displayName:
      'Road and Rail - Trucking (Local and Interurban Passenger Transit / Private Transit Authority)',
    id: 212,
  },
  {
    displayName:
      'Road and Rail - Trucking (Road Transportation of Passengers (Bus, Carpool, Taxi, etc.))',
    id: 215,
  },
  {
    displayName: 'Road and Rail - Trucking (Special Needs Transportation)',
    id: 217,
  },
  {
    displayName: 'Semiconductors and Semiconductor Equipment - Semiconductor Equipment',
    id: 909,
  },
  {
    displayName: 'Semiconductors and Semiconductor Equipment - Semiconductors',
    id: 910,
  },
  {
    displayName: 'Software and Services - Internet Software and Services',
    id: 786,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Agents And Spider Software)',
    id: 793,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Application Hosting Services)',
    id: 803,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Blockchain Technology / Software (Excl. Crypto Currency))',
    id: 1016,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Blog Forum)',
    id: 813,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Bookmarking Site)',
    id: 787,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Browser Software)',
    id: 794,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Business-to-Business (B2B) Internet Services)',
    id: 804,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Cannabis / Marijuana Industry Software)',
    id: 990,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Collaboration Tools)',
    id: 821,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Collaborative Economy)',
    id: 996,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Co-location/Data Centers - Excluding Managed Service)',
    id: 805,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Co-location/Data Centers - Managed Service)',
    id: 1049,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Consumer Awareness and Knowledge Centers)',
    id: 806,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Content Delivery)',
    id: 807,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Content Management Software)',
    id: 795,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Crypto Currency / Exchange / Software / Platform)',
    id: 838,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Customer Relationship Management (CRM) Software)',
    id: 823,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Data Aggregator)',
    id: 792,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Data Hosting and Cloud Service Provider)',
    id: 790,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Document Management Software)',
    id: 824,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Domain Services)',
    id: 808,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Drawing and Imaging Software)',
    id: 822,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Electronic Data Interchange (EDI))',
    id: 809,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Energy Industry Software)',
    id: 994,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Enterprise Data Management Software)',
    id: 825,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Enterprise Information Portals)',
    id: 826,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Enterprise Middleware)',
    id: 827,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Enterprise Resource Planning (ERP) Software)',
    id: 828,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Groupware)',
    id: 834,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Industry Specific Software)',
    id: 829,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Information and Knowledge Management Software)',
    id: 830,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Internet Education Courses)',
    id: 818,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Internet Navigation Portals)',
    id: 796,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Internet Presence Providers (IPP))',
    id: 814,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Internet Service Providers (ISP))',
    id: 815,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Logistics Software)',
    id: 831,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Math and Science Software)',
    id: 835,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Media Sharing)',
    id: 812,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Messaging and Messaging Services)',
    id: 816,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Mobile Applications)',
    id: 1042,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Multimedia Software)',
    id: 836,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Multimedia Streaming and Online Presentation)',
    id: 817,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Office and Home Productivity Software)',
    id: 837,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Online Dating)',
    id: 1011,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Online Exchange)',
    id: 791,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Online Financial Services)',
    id: 819,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Online Marketing)',
    id: 789,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Performance and Usage Tracking Software)',
    id: 797,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Plug-Ins Software)',
    id: 798,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Property Management Software)',
    id: 832,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Search Engine Software)',
    id: 799,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Social Network, Social Media, and Internet Communities)',
    id: 810,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Social News)',
    id: 811,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Supply Chain Management Software)',
    id: 833,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Telematics)',
    id: 1017,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (VOIP Software)',
    id: 800,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Web Hosting)',
    id: 820,
  },
  {
    displayName: 'Software and Services - Internet Software and Services (Website Design)',
    id: 788,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Website Infrastructure Software)',
    id: 802,
  },
  {
    displayName:
      'Software and Services - Internet Software and Services (Website Management Software)',
    id: 801,
  },
  {
    displayName: 'Software - Application Software',
    id: 853,
  },
  {
    displayName: 'Software - Application Software (Application Service Provider)',
    id: 854,
  },
  {
    displayName: 'Software - Home Entertainment Software',
    id: 888,
  },
  {
    displayName: 'Software - Home Entertainment Software (Educational and Training Software)',
    id: 889,
  },
  {
    displayName: 'Software - Home Entertainment Software (Online Gaming)',
    id: 891,
  },
  {
    displayName: 'Software - Home Entertainment Software (Videogames / Console and Computer Games)',
    id: 890,
  },
  {
    displayName: 'Software - Systems Software',
    id: 855,
  },
  {
    displayName: 'Software - Systems Software (Applications Toolkit)',
    id: 863,
  },
  {
    displayName: 'Software - Systems Software (Artificial Intelligence Software)',
    id: 886,
  },
  {
    displayName: 'Software - Systems Software (Automation Products and Services)',
    id: 859,
  },
  {
    displayName: 'Software - Systems Software (Autonomous Vehicle Software)',
    id: 1018,
  },
  {
    displayName: 'Software - Systems Software (Backup and Recovery Software)',
    id: 860,
  },
  {
    displayName: 'Software - Systems Software (Computer Aided Design (CAD))',
    id: 879,
  },
  {
    displayName: 'Software - Systems Software (Computer System Network Development)',
    id: 856,
  },
  {
    displayName: 'Software - Systems Software (Computer Telephone Integration (CTI) Software)',
    id: 861,
  },
  {
    displayName: 'Software - Systems Software (Configuration Management)',
    id: 866,
  },
  {
    displayName: 'Software - Systems Software (Database Design)',
    id: 858,
  },
  {
    displayName: 'Software - Systems Software (Design Automation)',
    id: 862,
  },
  {
    displayName: 'Software - Systems Software (Encryption Software)',
    id: 880,
  },
  {
    displayName: 'Software - Systems Software (File Management Software)',
    id: 864,
  },
  {
    displayName: 'Software - Systems Software (Graphical User Interface (GUI) Builders)',
    id: 867,
  },
  {
    displayName: 'Software - Systems Software (Handheld Applications)',
    id: 868,
  },
  {
    displayName: 'Software - Systems Software (License Distribution and Control Software)',
    id: 877,
  },
  {
    displayName: 'Software - Systems Software (Linux Development Software)',
    id: 865,
  },
  {
    displayName: 'Software - Systems Software (Machine Learning Technology)',
    id: 1024,
  },
  {
    displayName: 'Software - Systems Software (Maintenance Software)',
    id: 878,
  },
  {
    displayName: 'Software - Systems Software (Network Administration)',
    id: 881,
  },
  {
    displayName: 'Software - Systems Software (Operating System Software)',
    id: 882,
  },
  {
    displayName: 'Software - Systems Software (Performance Monitoring and Analysis Software)',
    id: 883,
  },
  {
    displayName: 'Software - Systems Software (Process and Project Management)',
    id: 869,
  },
  {
    displayName: 'Software - Systems Software (Security Software (Cybersecurity))',
    id: 884,
  },
  {
    displayName: 'Software - Systems Software (Software Configuration and Control)',
    id: 885,
  },
  {
    displayName: 'Software - Systems Software (Software Development - Custom)',
    id: 1052,
  },
  {
    displayName: 'Software - Systems Software (Software Development - Prepackaged)',
    id: 857,
  },
  {
    displayName: 'Software - Systems Software (Software Diagram and Design)',
    id: 870,
  },
  {
    displayName: 'Software - Systems Software (Software Documentation)',
    id: 871,
  },
  {
    displayName: 'Software - Systems Software (Software Program Editors)',
    id: 872,
  },
  {
    displayName: 'Software - Systems Software (Software Testing)',
    id: 873,
  },
  {
    displayName: 'Software - Systems Software (Unix Development Software)',
    id: 874,
  },
  {
    displayName: 'Software - Systems Software (Web Development Software)',
    id: 875,
  },
  {
    displayName: 'Software - Systems Software (Windows Development Software)',
    id: 876,
  },
  {
    displayName: 'Software - Systems Software (Wireless Access)',
    id: 887,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail',
    id: 511,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail (Clothing Retail)',
    id: 512,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail (Formal Wear or Clothing Rental)',
    id: 520,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail (Golf Apparel and Equipment Retail)',
    id: 517,
  },
  {
    displayName:
      'Specialty Retail - Apparel Retail (Running and Track Apparel and Equipment Retail)',
    id: 515,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail (Shoe and Footwear Retail)',
    id: 513,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail (Soccer Apparel and Equipment Retail)',
    id: 518,
  },
  {
    displayName:
      'Specialty Retail - Apparel Retail (Sporting Good or Athletic Equipment and Apparel Retail)',
    id: 516,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail (Tailoring or Dressmaking Retail)',
    id: 514,
  },
  {
    displayName: 'Specialty Retail - Apparel Retail (Tennis Apparel and Equipment Retail)',
    id: 519,
  },
  {
    displayName: 'Specialty Retail - Automotive Retail',
    id: 586,
  },
  {
    displayName: 'Specialty Retail - Automotive Retail (Auto Dealership / Car Dealership)',
    id: 588,
  },
  {
    displayName: 'Specialty Retail - Automotive Retail (Auto Part Retail)',
    id: 587,
  },
  {
    displayName: 'Specialty Retail - Automotive Retail (Gas Station)',
    id: 589,
  },
  {
    displayName: 'Specialty Retail - Computer and Electronics Retail',
    id: 521,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail',
    id: 590,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Bedding and Bath Retail)',
    id: 594,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Cabinet Retail)',
    id: 591,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Electrical Lighting Retail)',
    id: 593,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Furniture Retail)',
    id: 592,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Home Decoration Retail)',
    id: 597,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Household Appliance Retail)',
    id: 598,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Kitchen Accessory Retail)',
    id: 595,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Knife and Scissors Retail)',
    id: 596,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Sewing Machine Retail)',
    id: 599,
  },
  {
    displayName: 'Specialty Retail - Homefurnishing Retail (Vacuum Cleaner Retail)',
    id: 600,
  },
  {
    displayName: 'Specialty Retail - Home Improvement Retail',
    id: 522,
  },
  {
    displayName: 'Specialty Retail - Home Improvement Retail (Curtain Drapery Retail)',
    id: 527,
  },
  {
    displayName: 'Specialty Retail - Home Improvement Retail (Gardening and Plant Retail)',
    id: 525,
  },
  {
    displayName: 'Specialty Retail - Home Improvement Retail (Hardware Tool Retail)',
    id: 524,
  },
  {
    displayName:
      'Specialty Retail - Home Improvement Retail (Heating, Air Conditioning, and Ventilation (HVAC) Equipment Retail)',
    id: 528,
  },
  {
    displayName: 'Specialty Retail - Home Improvement Retail (Paint Wallpaper Retail)',
    id: 523,
  },
  {
    displayName: 'Specialty Retail - Home Improvement Retail (Tile Floor Covering Retail)',
    id: 526,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores',
    id: 529,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Antique Retail)',
    id: 539,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Aquarium Supply Retail)',
    id: 568,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Army Navy Retail)',
    id: 534,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Art, Picture, and Poster Retail)',
    id: 569,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Balloon and Party Good Retail)',
    id: 548,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Bicycle Retail)',
    id: 541,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Book and Magazine Retail)',
    id: 540,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Brass and Copper Specialty Retail)',
    id: 570,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Burial Casket and Coffin Retail)',
    id: 571,
  },
  {
    displayName:
      'Specialty Retail - Specialty Stores (Cake Decorating Equipment and Supply Retail)',
    id: 572,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Candle Retail)',
    id: 549,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Cannabis / Marijuana Retail)',
    id: 988,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Cannabis Non-Marijuana Retail)',
    id: 1038,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Ceramics and Pottery Retail)',
    id: 573,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Christmas and Holiday Retail)',
    id: 550,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Clock and Watch Retail and Repair)',
    id: 546,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Collectible and Memorabilia Retail)',
    id: 554,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Convenience Store Retail)',
    id: 1020,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Cosmetic and Beauty Retail)',
    id: 1054,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Education and School Supply Retail)',
    id: 543,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Fabric and Yarn Retail)',
    id: 558,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Florist or Flower Retail)',
    id: 561,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Gift Retail)',
    id: 551,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Glass, China, and Crystal Retail)',
    id: 531,
  },
  {
    displayName:
      'Specialty Retail - Specialty Stores (Greeting Card, Stationery Paper, and Paper Product Retail)',
    id: 542,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Hearing Aid Retail)',
    id: 564,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Herbal Remedy)',
    id: 1019,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Hobby Craft or Art Supply Retail)',
    id: 547,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Janitorial Supply Retail)',
    id: 533,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Jewelry Retail)',
    id: 545,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Leather Good Retail)',
    id: 557,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Linen Retail)',
    id: 559,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Luggage Retail)',
    id: 556,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Mail Box or Packaging Retail)',
    id: 583,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Miscellaneous and Other Retail)',
    id: 555,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Monument and Tombstone Retail)',
    id: 574,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Music and Video Retail)',
    id: 538,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Office Machine or Appliance Retail)',
    id: 530,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Painting, Picture, and Frame Retail)',
    id: 565,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Pawn Shop Retail)',
    id: 585,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Pet and Pet Supply Retail)',
    id: 566,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Photographic Equipment Supply Retail)',
    id: 553,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Plumbing Supply Retail)',
    id: 575,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Printing and Bookbinding Supply Retail)',
    id: 544,
  },
  {
    displayName:
      'Specialty Retail - Specialty Stores (Radio, Television, Audio, and Visual Equipment Retail)',
    id: 537,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Religious Article and Church Supply Retail)',
    id: 576,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Restaurant Fixture Supply Retail)',
    id: 536,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Rubber Stamp Retail)',
    id: 577,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Sewing and Needlework Retail)',
    id: 560,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Ship Chandler Retail)',
    id: 578,
  },
  {
    displayName:
      'Specialty Retail - Specialty Stores (Sign Painting and Lettering Retail and Services)',
    id: 532,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Sunglasses and Optical Good Retail)',
    id: 563,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Swimming Pool Supply Retail)',
    id: 579,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Thrift Retail)',
    id: 582,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Tobacco Retail)',
    id: 562,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Toy Retail)',
    id: 552,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Trophy Retail)',
    id: 567,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Video Rental)',
    id: 581,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Vitamin Retail)',
    id: 535,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Water Softening Equipment Retail)',
    id: 580,
  },
  {
    displayName: 'Specialty Retail - Specialty Stores (Wig Retail)',
    id: 584,
  },
  {
    displayName: 'State Level Government - Local Government',
    id: 949,
  },
  {
    displayName: 'State Level Government - Local Government (Airport)',
    id: 1026,
  },
  {
    displayName: 'State Level Government - Local Government (County Government)',
    id: 1029,
  },
  {
    displayName: 'State Level Government - Local Government (Fire Department)',
    id: 1021,
  },
  {
    displayName: 'State Level Government - Local Government (Police Department)',
    id: 1022,
  },
  {
    displayName: 'State Level Government - Local Government (Port Authority)',
    id: 1027,
  },
  {
    displayName: 'State Level Government - Local Government (State Government)',
    id: 1030,
  },
  {
    displayName: 'State Level Government - Local Government (Transit Authority)',
    id: 1028,
  },
  {
    displayName: 'State Level Government - Local Government (Utilities / Utility)',
    id: 1025,
  },
  {
    displayName: 'State Level Government - State and Provincial Government',
    id: 950,
  },
  {
    displayName:
      'State Level Government - State and Provincial Government (Administration of Educational Program - State and Provincial)',
    id: 953,
  },
  {
    displayName:
      'State Level Government - State and Provincial Government (Administration of General Economic Program - State and Provincial)',
    id: 954,
  },
  {
    displayName:
      'State Level Government - State and Provincial Government (Administration of Housing Program - State and Provincial)',
    id: 955,
  },
  {
    displayName:
      'State Level Government - State and Provincial Government (Administration of Public Health Program)',
    id: 951,
  },
  {
    displayName:
      'State Level Government - State and Provincial Government (Administration of Social Human Resource and Income Maintenance Program - State and Provincial)',
    id: 956,
  },
  {
    displayName:
      'State Level Government - State and Provincial Government (Administration of Urban Planning and Community and Rural Development - State and Provincial)',
    id: 957,
  },
  {
    displayName:
      'State Level Government - State and Provincial Government (Administration of Vet Affair Health)',
    id: 952,
  },
  {
    displayName: 'Technology Hardware and Equipment - Communications Equipment',
    id: 894,
  },
  {
    displayName:
      'Technology Hardware and Equipment - Communications Equipment (Telephone and Telegraph Apparatus)',
    id: 895,
  },
  {
    displayName: 'Technology Hardware and Equipment - Technology Hardware, Storage and Peripherals',
    id: 897,
  },
  {
    displayName:
      'Technology Hardware and Equipment - Technology Hardware, Storage and Peripherals (Battery and Energy Storage)',
    id: 1037,
  },
  {
    displayName:
      'Technology Hardware and Equipment - Technology Hardware, Storage and Peripherals (Computer Peripheral Equipment)',
    id: 898,
  },
  {
    displayName:
      'Technology Hardware and Equipment - Technology Hardware, Storage and Peripherals (Computer Rental and Leasing)',
    id: 899,
  },
  {
    displayName:
      'Technology Hardware and Equipment - Technology Hardware, Storage and Peripherals (Computer Storage Device)',
    id: 900,
  },
  {
    displayName:
      'Technology Hardware and Equipment - Technology Hardware, Storage and Peripherals (Computer Terminal)',
    id: 901,
  },
  {
    displayName:
      'Technology Hardware and Equipment - Technology Hardware, Storage and Peripherals (Wearables)',
    id: 1014,
  },
  {
    displayName: 'Telecommunication Services',
    id: 912,
  },
  {
    displayName: 'Telecommunication Services (Communications Service)',
    id: 913,
  },
  {
    displayName: 'Textiles, Apparel, and Luxury Goods - Apparel, Accessories, and Luxury Goods',
    id: 249,
  },
  {
    displayName:
      'Textiles, Apparel, and Luxury Goods - Apparel, Accessories, and Luxury Goods (Cosmetic Manufacturing)',
    id: 251,
  },
  {
    displayName:
      'Textiles, Apparel, and Luxury Goods - Apparel, Accessories, and Luxury Goods (Leather Manufacturing)',
    id: 250,
  },
  {
    displayName: 'Textiles, Apparel, and Luxury Goods - Footwear',
    id: 252,
  },
  {
    displayName: 'Textiles, Apparel, and Luxury Goods - Textiles',
    id: 253,
  },
  {
    displayName: 'Textiles, Apparel, and Luxury Goods - Textiles (Embroidery Manufacturing)',
    id: 254,
  },
  {
    displayName: 'Textiles, Apparel, and Luxury Goods - Textiles (Textile Manufacturing)',
    id: 255,
  },
  {
    displayName: 'Transportation - Air Freight and Logistics (Air Freight and Logistics)',
    id: 192,
  },
  {
    displayName: 'Transportation - Air Freight and Logistics (Commercial Airlines)',
    id: 194,
  },
  {
    displayName: 'Transportation - Air Freight and Logistics (Helicopter Transportation Services)',
    id: 195,
  },
  {
    displayName:
      'Transportation - Air Freight and Logistics (Passenger and Commuter Train and Rail Systems)',
    id: 202,
  },
  {
    displayName:
      'Transportation - Air Freight and Logistics (Private or Business Aircraft Services)',
    id: 196,
  },
  {
    displayName: 'Transportation - Air Freight and Logistics (Safety and Rescue Aircraft Services)',
    id: 197,
  },
  {
    displayName: 'Transportation - Airlines',
    id: 204,
  },
  {
    displayName: 'Transportation - Airlines (Air Passenger Transportation)',
    id: 205,
  },
  {
    displayName: 'Transportation Infrastructure - Airport Services / Private Airports',
    id: 219,
  },
  {
    displayName: 'Transportation Infrastructure - Highways and Railtracks',
    id: 220,
  },
  {
    displayName:
      'Transportation Infrastructure - Marine Ports and Services / Private Port Authority',
    id: 221,
  },
  {
    displayName: 'Transportation - Transportation Infrastructure',
    id: 218,
  },
  {
    displayName: 'Utilities - Utilities / Utility',
    id: 921,
  },
];
