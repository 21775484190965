import { assign, find, pick, reduce, sumBy, values, omit, isNaN } from 'lodash';
import * as moment from 'moment';

import { parseMoney } from '../../../shared/helpers/number-format-helpers';
import { Industry } from '../../../shared/services/naics.service';

import {
  CoalitionCyberFormDataFormatted,
  CoalitionCyberFormDataRaw,
  CoalitionCyberQuestion,
  CoalitionCyberSubmissionField,
  FirstPartyCoverageNestedQuestion,
  FormValue,
  MitigatingClauseNestedQuestion,
  NestedGroupValue,
  ThirdPartyCoverageNestedQuestion,
  INSURANCE_MARKET_CONTROL,
  InsuranceMarketControl,
  CyberQuestionEnablementFlags,
  CyberQuestionEnablementFlag,
  CoalitionNaicsCode,
  NAICS_CODE,
  EngagedIndustriesNestedQuestion,
} from './cyber-typings.model';
import { US_DATE_MASK, USD_MONEY_PATTERN } from '../../../constants';
import {
  COALITION_CYBER_ISO_DATE_FORMAT,
  LIMIT_OPTIONS,
  RETENTION_OPTIONS,
  COMPANY_EMPLOYEE_COUNT_OPTIONS,
  PRIOR_CLAIMS_AMOUNT_OPTIONS,
  CyberLimitOption,
  CyberRetentionOption,
} from './cyber-constants.model';
import { FormDSLFormData } from '../../digital-carrier/models/types';
import { COALITION_CYBER_INDUSTRIES } from './cyber-industries.model';

const parseFieldValue = (fieldValue: FormValue | null) => {
  switch (typeof fieldValue) {
    case 'string':
      if (USD_MONEY_PATTERN.test(fieldValue)) {
        return parseMoney(fieldValue);
      }
      if (!isNaN(Number(fieldValue))) {
        return Number(fieldValue);
      }
      return fieldValue || null;
    case 'boolean':
      return fieldValue || null;
    case 'number':
      return fieldValue;
    default:
      return null;
  }
};

export const sanitizeDomain = (domain: string): string => {
  domain = domain.toLowerCase();
  if (domain.startsWith('http://')) {
    domain = domain.slice('http://'.length);
  }
  if (domain.startsWith('https://')) {
    domain = domain.slice('https://'.length);
  }
  if (domain.includes('/')) {
    domain = domain.slice(0, domain.indexOf('/'));
  }
  const splitEmailDomain = domain.split('@');
  return splitEmailDomain[splitEmailDomain.length - 1];
};

export const formatFormFieldValues = (formDataWithoutSteps: CoalitionCyberFormDataRaw) => {
  const formDataFormatted = reduce(
    formDataWithoutSteps,
    (
      formatted: CoalitionCyberFormDataFormatted,
      fieldValue,
      fieldName: CoalitionCyberQuestion | InsuranceMarketControl | CoalitionNaicsCode
    ) => {
      switch (fieldName) {
        // These fields are used for form logic only and should not be sent with the form data.
        case INSURANCE_MARKET_CONTROL:
        case CoalitionCyberQuestion.AGGREGATE_LIMIT:
        case CoalitionCyberQuestion.DEFAULT_RETENTION:
          return formatted;
        case CoalitionCyberQuestion.ADDRESS:
          const { addressLine1, addressLine2, city, state, zip } = fieldValue as Address;
          formatted[CoalitionCyberSubmissionField.STREET_LINE_1] = addressLine1;
          if (addressLine2) {
            formatted[CoalitionCyberSubmissionField.STREET_LINE_2] = addressLine2;
          }
          formatted[CoalitionCyberSubmissionField.CITY] = city;
          formatted[CoalitionCyberSubmissionField.STATE] = state;
          formatted[CoalitionCyberSubmissionField.POSTCODE] = zip;
          return formatted;
        case CoalitionCyberQuestion.COMPANY_INDUSTRY_ID:
          const { id } = fieldValue as Industry;
          formatted[fieldName] = id;
          return formatted;
        case NAICS_CODE:
          formatted[fieldName] = fieldValue as string;
          return formatted;
        case CoalitionCyberQuestion.THIRD_PARTY_COVERAGES:
        case CoalitionCyberQuestion.FIRST_PARTY_COVERAGES:
        case CoalitionCyberQuestion.MITIGATING_CLAUSES:
        case CoalitionCyberQuestion.ENGAGED_INDUSTRIES:
          // Flatten nested groups into the form data
          return assign(formatted, fieldValue as NestedGroupValue);
        case CoalitionCyberQuestion.EFFECTIVE_DATE:
          const effectiveDateMoment = moment(fieldValue as string, US_DATE_MASK);
          const endDateMoment = effectiveDateMoment.clone().add(1, 'year');
          formatted[CoalitionCyberQuestion.EFFECTIVE_DATE] = effectiveDateMoment.format(
            COALITION_CYBER_ISO_DATE_FORMAT
          );
          formatted[CoalitionCyberSubmissionField.END_DATE] = endDateMoment.format(
            COALITION_CYBER_ISO_DATE_FORMAT
          );
          return formatted;
        case CoalitionCyberQuestion.DOMAIN_NAMES:
          if (fieldValue) {
            formatted[CoalitionCyberQuestion.DOMAIN_NAMES] = sanitizeDomain(fieldValue as string);
          }
          return formatted;
        case CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS:
          formatted[fieldName] = fieldValue as string;
          if (fieldValue === 'No') {
            formatted[CoalitionCyberQuestion.PRIOR_CLAIMS] = 'No';
          }
          return formatted;
        case CoalitionCyberQuestion.DUAL_CONTROL_5K:
          formatted[fieldName] = fieldValue as string;
          if (fieldValue === 'Yes') {
            // If dual control is in place for transfers over $5K, we can assume
            // that dual control is in place for transfers over $25K.
            formatted[CoalitionCyberQuestion.DUAL_CONTROL] = 'Yes';
          }

          return formatted;
        case CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT:
          // If > $500K, we will instead set this field using the value of
          // AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K below.
          if (fieldValue !== PRIOR_CLAIMS_AMOUNT_OPTIONS['> $500,000']) {
            formatted[fieldName] = fieldValue as string;
          }
          return formatted;
        case CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K:
          const onlyDigits = (fieldValue as string).replace(/\D+/g, '');
          formatted[CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT] = onlyDigits;
          return formatted;
        default:
          const parsedFieldValue = parseFieldValue(fieldValue as FormValue);
          if (parsedFieldValue !== null) {
            // Set numbers, true, or non-empty strings only; ignore other values
            formatted[fieldName] = parsedFieldValue;
          }
          return formatted;
      }
    },
    {} as CoalitionCyberFormDataFormatted
  );

  if (
    formDataWithoutSteps[CoalitionCyberQuestion.AGGREGATE_LIMIT] &&
    formDataWithoutSteps[CoalitionCyberQuestion.DEFAULT_RETENTION]
  ) {
    formDataFormatted[CoalitionCyberQuestion.AGGREGATE_LIMIT] =
      LIMIT_OPTIONS[formDataWithoutSteps[CoalitionCyberQuestion.AGGREGATE_LIMIT] as number];
    formDataFormatted[CoalitionCyberQuestion.DEFAULT_RETENTION] =
      RETENTION_OPTIONS[formDataWithoutSteps[CoalitionCyberQuestion.DEFAULT_RETENTION] as number];
  }

  return formDataFormatted;
};

export const formatFormFieldValuesForDisplay = (
  formValues: FormDSLFormData
): CoalitionCyberFormDataRaw => {
  const formDataFormatted = reduce(
    formValues,
    (
      formatted: CoalitionCyberFormDataRaw,
      fieldValue,
      fieldName: CoalitionCyberQuestion | CoalitionCyberSubmissionField
    ) => {
      // First, check for fields that are handled specially
      switch (fieldName) {
        case CoalitionCyberQuestion.AGGREGATE_LIMIT:
          formatted[fieldName] = LIMIT_OPTIONS.indexOf(fieldValue as CyberLimitOption);
          return formatted;
        case CoalitionCyberQuestion.DEFAULT_RETENTION:
          formatted[fieldName] = RETENTION_OPTIONS.indexOf(fieldValue as CyberRetentionOption);
          return formatted;
        case CoalitionCyberSubmissionField.STREET_LINE_1:
        case CoalitionCyberSubmissionField.STREET_LINE_2:
        case CoalitionCyberSubmissionField.CITY:
        case CoalitionCyberSubmissionField.STATE:
        case CoalitionCyberSubmissionField.POSTCODE:
        case CoalitionCyberSubmissionField.END_DATE:
          // These fields are handled below
          return formatted;

        case CoalitionCyberQuestion.COMPANY_INDUSTRY_ID:
          const industry = find(COALITION_CYBER_INDUSTRIES, (value) => value.id === fieldValue);
          if (industry) {
            formatted[fieldName] = industry as Industry;
          }

          return formatted;
        case CoalitionCyberQuestion.EFFECTIVE_DATE:
          const effectiveDateMoment = moment(fieldValue as string, COALITION_CYBER_ISO_DATE_FORMAT);
          formatted[CoalitionCyberQuestion.EFFECTIVE_DATE] =
            effectiveDateMoment.format(US_DATE_MASK);
          return formatted;
        case CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT:
          const isOver500K = /^\d+$/.test(fieldValue as string) && Number(fieldValue) > 500_000;
          if (isOver500K) {
            formatted[fieldName] = PRIOR_CLAIMS_AMOUNT_OPTIONS['> $500,000'];
            formatted[CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K] =
              fieldValue as string;
          } else if (values(PRIOR_CLAIMS_AMOUNT_OPTIONS).includes(fieldValue as any)) {
            formatted[fieldName] = fieldValue as string;
          }
          return formatted;
        default:
          break;
      }

      // Then, handle the rest
      if (fieldValue !== null) {
        formatted[fieldName] = fieldValue;
      }

      return formatted;
    },
    {} as CoalitionCyberFormDataRaw
  );

  formDataFormatted[CoalitionCyberQuestion.THIRD_PARTY_COVERAGES] = pick(
    formValues,
    values(ThirdPartyCoverageNestedQuestion)
  ) as Record<ThirdPartyCoverageNestedQuestion, boolean>;

  formDataFormatted[CoalitionCyberQuestion.FIRST_PARTY_COVERAGES] = pick(
    formValues,
    values(FirstPartyCoverageNestedQuestion)
  ) as Record<FirstPartyCoverageNestedQuestion, boolean>;

  formDataFormatted[CoalitionCyberQuestion.MITIGATING_CLAUSES] = pick(
    formValues,
    values(MitigatingClauseNestedQuestion)
  ) as Record<MitigatingClauseNestedQuestion, boolean>;

  formDataFormatted[CoalitionCyberQuestion.ENGAGED_INDUSTRIES] = pick(
    formValues,
    values(EngagedIndustriesNestedQuestion)
  ) as Record<EngagedIndustriesNestedQuestion, boolean>;

  // Handle address fields specially
  const addressLine1 = formValues[CoalitionCyberSubmissionField.STREET_LINE_1];
  const addressLine2 = formValues[CoalitionCyberSubmissionField.STREET_LINE_2];
  const city = formValues[CoalitionCyberSubmissionField.CITY];
  const state = formValues[CoalitionCyberSubmissionField.STATE];
  const zip = formValues[CoalitionCyberSubmissionField.POSTCODE];
  formDataFormatted[CoalitionCyberQuestion.ADDRESS] = {
    addressLine1,
    addressLine2: addressLine2 || '',
    city,
    state,
    zip,
  } as Address;

  return formDataFormatted;
};

export const employeeCountNumbertoString = (
  count: number
): keyof typeof COMPANY_EMPLOYEE_COUNT_OPTIONS => {
  if (count <= 25) {
    return '1-25';
  } else if (count <= 50) {
    return '26-50';
  } else if (count <= 250) {
    return '51-250';
  } else if (count <= 1000) {
    return '251-1000';
  } else {
    return '1001+';
  }
};

export const getEmployeeCountValue = (locations: BopLocation[]) => {
  const employeeCount = sumBy(locations, (location) => {
    if (!location.locationDetails) {
      return 0;
    }
    return (
      parseInt(location.locationDetails.employeeCount || '0', 10) +
      parseInt(location.locationDetails.partTimeEmployeeCount || '0', 10)
    );
  });

  return employeeCountNumbertoString(employeeCount);
};

export const removeIneligibleCyberCoverages = (
  formData: CoalitionCyberFormDataFormatted,
  flagsFromIndustryData: CyberQuestionEnablementFlags
) => {
  // Other questions with `flagDependency` in CYBER_DEPENDENCIES are already taken care of,
  // or only apply to surplus. This is a list for items for which we need to verify if they should be
  // removed from the formData, based on Coalition's `flagsFromIndustryData`.

  // Dictionary of Coalition industry flags (key) with corresponding formData version (value).
  // All these flags start with `reject_*` and indicate that if Coalition has sent a `true` value,
  // we can remove it from the formData request.
  const industryFlagsToVerify = {
    [CyberQuestionEnablementFlag.REJECT_BUSINESS_INTERRUPTION]:
      FirstPartyCoverageNestedQuestion.BUSINESS_INTERRUPTION,
    [CyberQuestionEnablementFlag.REJECT_FTF]: FirstPartyCoverageNestedQuestion.FUNDS_TRANSFER,
    [CyberQuestionEnablementFlag.REJECT_MEDIA_LIABILITY]:
      ThirdPartyCoverageNestedQuestion.MEDIA_LIABILITY,
  };

  const questionsToOmit = [];

  for (const [coalitionIndustryFlag, coverageFormDataName] of Object.entries(
    industryFlagsToVerify
  )) {
    // When Coalition's industry flag is true
    if (flagsFromIndustryData[coalitionIndustryFlag as CyberQuestionEnablementFlag]) {
      // Remove ineligible coverage
      questionsToOmit.push(coverageFormDataName);
    }
  }

  return omit(formData, questionsToOmit) as CoalitionCyberFormDataFormatted;
};
