import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import { BreachEstimate, CommonIncident, LossEstimate } from '../../models/cyber-typings.model';

@Component({
  selector: 'app-cyber-risk-profile',
  templateUrl: './coalition-cyber-risk-profile.component.html',
})
export class CoalitionCyberRiskProfileComponent implements OnInit {
  error: boolean = false;
  loading: boolean = true;
  accountName: string;
  breachData: BreachEstimate;
  overallLoss: LossEstimate;
  ransomwareIncident: string;
  ransomwareLoss: LossEstimate;
  ftfIncident: string;
  ftfLoss: LossEstimate;
  breachIncident: string;
  breachLoss: LossEstimate;
  otherIncident: string;

  constructor(
    private digitalCarrierQuoteService: DigitalCarrierQuoteService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.params;
    this.accountName = this.route.snapshot.queryParams.accountName;

    this.digitalCarrierQuoteService
      .getCoalitionBreachEstimates(params.quoteId)
      .subscribe((response) => {
        if (response) {
          this.breachData = response;
          const commonIncidents = response.overall.common_incidents;
          this.ransomwareIncident = this.extractCommonIncidentPercentage(
            commonIncidents,
            'Ransomware'
          );
          this.ftfIncident = this.extractCommonIncidentPercentage(
            commonIncidents,
            'Funds Transfer Fraud'
          );
          this.breachIncident = this.extractCommonIncidentPercentage(
            commonIncidents,
            'Data Breach'
          );
          this.otherIncident = this.extractCommonIncidentPercentage(commonIncidents, 'Other');
          this.overallLoss = this.extractLossValue(response.overall);
          this.ransomwareLoss = this.extractLossValue(response.ransomware);
          this.ftfLoss = this.extractLossValue(response.ftf);
          this.breachLoss = this.extractLossValue(response.breach);

          this.validateData();
          this.loading = false;
        }
      });
  }

  extractCommonIncidentPercentage(incidents: CommonIncident[], type: string): string {
    const value = parseFloat(incidents.filter((i) => i.name === type)[0].value);

    if (value < 0.0 || value > 1.0) {
      this.error = true;
      throw new Error(`Unable to extract valid ${type} percentage. Extracted value: ${value}`);
    }

    return Math.round(value * 100).toString(10);
  }

  extractLossValue(estimate: LossEstimate): LossEstimate {
    return {
      median_loss: Math.round(estimate.median_loss),
      '1_10_loss': Math.round(estimate['1_10_loss']),
      '1_100_loss': Math.round(estimate['1_100_loss']),
    };
  }

  validateData() {
    // Make sure all the incident values are percentages between 0 and 100
    [this.ransomwareIncident, this.ftfIncident, this.breachIncident, this.otherIncident].forEach(
      (incident) => {
        if (!incident || parseInt(incident, 10) < 0 || parseInt(incident, 10) > 100) {
          this.error = true;
        }
      }
    );

    // Make sure all the loss values are populated
    [this.overallLoss, this.ransomwareLoss, this.ftfLoss, this.breachLoss].forEach((loss) => {
      if (!loss.median_loss || !loss['1_10_loss'] || !loss['1_100_loss']) {
        this.error = true;
      }
    });
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
}
