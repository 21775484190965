<div>
  <h3 class="h3">Rewards Activity</h3>
  <p *ngIf="totalRedeemedThisYear" class="p">
    Congratulations! 🎉
    <br />
    You have redeemed {{ totalRedeemedThisYear }} points this year which is equal to ${{
      pointsToDollarValue(totalRedeemedThisYear)
    }}.
  </p>
  <div class="rewards-activity-table-container">
    <table class="rewards-activity-table">
      <thead>
        <tr>
          <th>TRANSACTION DETAIL</th>
          <th class="td__right">INSURECOIN</th>
          <th class="td__right">DATE</th>
        </tr>
      </thead>
      <tbody class="rewards-activity-table-body js-rewards-activity-table-body">
        <ng-container *ngFor="let transaction of transactions">
          <tr *ngIf="transaction.points !== 0">
            <td class="td__small" *ngIf="transaction.points < 0; else insuredAccount">
              <span
                class="gift-card"
                [ngClass]="{
                  'gift-card__5': transaction.points === -50,
                  'gift-card__10': transaction.points === -100,
                  'gift-card__25': transaction.points === -250,
                  'gift-card__50': transaction.points === -500,
                  'gift-card__max': ![-50, -100, -250, -500].includes(transaction.points)
                }"
              >
                Redeemed insurecoins
              </span>
            </td>
            <ng-template #insuredAccount
              ><td class="td__small">{{ transaction.insuredAccountName || '' }}</td></ng-template
            >
            <td class="td__right js-points">{{ transaction.points }}</td>
            <td class="td__right">{{ transaction.createdAt | date: 'mediumDate':'UTC' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
