<div
  class="toggle-switch-wrapper tool-tip tool-tip__right tool-tip__large"
  [attr.data-tooltip]="tooltipText"
>
  <input
    class="toggle-switch-input"
    type="checkbox"
    [id]="inputId"
    [name]="inputId"
    [formControl]="toggleForm"
  />
  <div class="toggle-switch"></div>
</div>
