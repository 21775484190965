import { API_V3_BASE } from 'app/constants';

export const API_URI = API_V3_BASE;
export const QUOTE_API_URI = `${API_V3_BASE}/quotes/hiscox`;

export const GL_QUESTION_API_URL = (productId: string, state: string, classOfBusiness: string) =>
  `${API_V3_BASE}/quotes/hiscox/dynamic-questions/${productId}/${state}/${classOfBusiness}`;

export const HISCOX_QUOTE_API_URL = (productId: string) =>
  `${API_V3_BASE}/quotes/hiscox/${productId}`;

export const HISCOX_GL_PL_GUIDE = `https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Hiscox+GL+and+PL+Product+Overview+%26+Appetite+Guide+7.27.22.pdf`;
