import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SentryService } from 'app/core/services/sentry.service';
import { GET_LOSS_RUNS_BY_POLICY } from 'app/constants';
import * as moment from 'moment';

export interface LossRunsAvailabilityInfo {
  lossRunAvailable: boolean;
  lossRunExpiry: moment.Moment | null;
  lossRunCreatedAt: moment.Moment | null;
}

interface LossRunsInfoBackendResponse {
  uuid: string;
  account_number: string;
  policy_number: string;
  s3_key: string;
  file_names: string[];
  request_type: string;
  uploaded_by_email: string;
  loss_run_expiry: string;
  created_at: string;
}

@Injectable()
export class InternalToolsService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getLossRunsListForPolicy(policyNumber: string): Observable<LossRunsAvailabilityInfo> {
    return this.http.get(`${GET_LOSS_RUNS_BY_POLICY}/${policyNumber}`).pipe(
      map((results: LossRunsInfoBackendResponse[]) => {
        if (results.length) {
          return {
            lossRunAvailable: true,
            lossRunExpiry: moment(results[0].loss_run_expiry),
            lossRunCreatedAt: moment(results[0].created_at),
          };
        }
        return {
          lossRunAvailable: false,
          lossRunExpiry: null,
          lossRunCreatedAt: null,
        };
      }),
      catchError((error: any) => {
        this.sentryService.notify('Error getting loss runs by policy number information', {
          severity: 'error',
          metaData: {
            policyNumber,
            underlyingError: error,
            underlyingErrorMessage: error && error.message,
          },
        });
        throw new Error('Error getting loss runs by policy number information');
      })
    );
  }
}
