import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, interval, of as observableOf } from 'rxjs';
import { map, timeout, catchError, filter, take, switchMap } from 'rxjs/operators';

import { FeatureFlagService, BOOLEAN_FLAG_NAMES } from '../services/feature-flag.service';
import { SentryService } from '../services/sentry.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagEnabledGuard {
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private sentryService: SentryService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const featureFlagToCheck: BOOLEAN_FLAG_NAMES = route.data?.flagName;
    this.featureFlagService.fetchUserInformation();
    if (!featureFlagToCheck) {
      this.sentryService.notify(
        'FeatureFlagEnabledGuard: No feature flag configured for this guard',
        {
          severity: 'error',
          metaData: {
            route: route.toString(),
          },
        }
      );

      this.router.navigateByUrl('/accounts');
      return observableOf(false);
    }

    const featureFlagServiceIsInitialized$ = interval(500).pipe(
      filter(() => {
        return this.featureFlagService.launchDarklyClientInitialized;
      }),
      take(1)
    );

    return featureFlagServiceIsInitialized$.pipe(
      filter((isInitialized) => isInitialized !== null),
      timeout(5000),
      switchMap(() =>
        this.featureFlagService.isEnabled(featureFlagToCheck, true).pipe(
          map((isEnabled) => {
            if (!isEnabled) {
              this.router.navigateByUrl('/accounts');
            }

            return !!isEnabled;
          })
        )
      ),
      catchError((_err) => {
        const path = route.routeConfig?.path || route.toString();
        this.sentryService.notify(
          'FeatureFlagEnabledGuard: LaunchDarkly client failed to initialize before timeout, cannot evaluate flag',
          {
            severity: 'error',
            metaData: {
              featureFlagToCheck,
              path: path,
            },
          }
        );

        this.router.navigateByUrl('/accounts');

        return observableOf(false);
      })
    );
  }
}
