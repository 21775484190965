<div class="detail-panel" [ngClass]="{ 'detail-panel__no-border': compact }">
  <!-- Start Billing section -->

  <div *ngIf="showBackButton" class="nav-button-group nav-button-group__left close">
    <a
      class="button button__nav button__nav-sidebar-quote button__no-margin"
      [routerLink]="'/accounts/' + accountId"
    >
      <span class="icon-arrow-left button-icon"></span>
      Close
    </a>
  </div>

  <h2 class="h2" *ngIf="model.companyName && !compact">New quote for {{ model.companyName }}</h2>

  <!-- Start Attune WC section -->
  <section
    class="js-product-card__attune-wc"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="
      showAttuneWcProduct() &&
      !insuredAccountHasAttuneWcInForce() &&
      (!this.excludeNonCrossSell ||
        (isProductCrossSell({ product: 'wc', pasSource: 'attune_gw' }) &&
          !showAttuneWcDeclineReasons())) &&
      !showAlternativeCarriersOnly
    "
  >
    <div *ngIf="loading">
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__loading-placeholder"
            [ngClass]="{ 'product-badge__small': compact }"
          ></div>
          <h3 class="account-policies-header-h3__loading-placeholder"></h3>
        </div>
        <div class="loading-placeholder-button"></div>
      </div>
    </div>
    <ng-container *ngIf="!loading">
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__wc"
            [ngClass]="{ 'product-badge__small': compact }"
          >
            <div
              class="product-badge-carrier-icon"
              [ngClass]="{ 'product-badge-carrier-icon__small': compact }"
              [ngClass]="
                isAdpWcUser
                  ? 'product-badge-carrier-icon__everpeak'
                  : 'product-badge-carrier-icon__attune'
              "
            ></div>
          </div>
          <div>
            <h3 class="account-policies-header-h3">Workers' Compensation</h3>
            <div
              *ngIf="
                isAttuneWcEligible &&
                !showAttuneWcDeclineReasons() &&
                ['1', '2'].includes(wcPreferenceLevel)
              "
              class="preference-tag-container"
            >
              <div class="pill-tag pill-tag__preferred">
                <ng-container *ngIf="showWcStronglyPreferred && wcPreferenceLevel === '1'">
                  <img
                    class="badge-list-img"
                    src="/assets/img/attune_strongly_preferred_heart.svg"
                    alt=""
                  />
                  <span class="ml-1 preferred-text js-wc-preferred-text">
                    <strong>strongly preferred</strong>
                  </span>
                </ng-container>
                <ng-container *ngIf="!showWcStronglyPreferred || wcPreferenceLevel === '2'">
                  <img class="badge-list-img" src="/assets/img/attune_preferred_green.svg" alt="" />
                  <span class="ml-1 preferred-text js-wc-preferred-text">
                    <strong>preferred</strong>
                  </span>
                </ng-container>
              </div>
            </div>
            <p class="p p__no-margin">
              {{ getAttuneWCBrandName(isAdpWcUser).brand }}&nbsp;
              <span class="gray-text">{{ getAttuneWCBrandName(isAdpWcUser).details }}</span
              >&nbsp;&nbsp;<a href="{{ attuneWcGuidelinesLink }}" target="_blank">Learn more</a>
            </p>
          </div>
        </div>
        <ng-container
          *ngIf="isAttuneWcEligible && !isSampleAccount && !showAttuneWcDeclineReasons()"
        >
          <a
            class="button button__small button__no-margin js-new-quote-button new-tag-container"
            [ngClass]="{
              button__primary: !recentNonDeclinedAttuneWcQuote,
              button__secondary: recentNonDeclinedAttuneWcQuote
            }"
            [routerLink]="
              recentNonDeclinedAttuneWcQuote
                ? [
                    '/accounts',
                    accountId,
                    'attune',
                    'workers-comp',
                    'quotes',
                    recentNonDeclinedAttuneWcQuote.id,
                    'edit'
                  ]
                : ['/accounts', accountId, 'attune', 'workers-comp', 'new']
            "
            data-amplitude-input-name="account-get-quote-attune-wc"
          >
            <span class="js-attune-wc-new-quote" *ngIf="recentNonDeclinedAttuneWcQuote"
              >New Quote</span
            >
            <span class="js-attune-wc-get-a-quote" *ngIf="!recentNonDeclinedAttuneWcQuote"
              >Get a Quote</span
            >
          </a>
        </ng-container>
        <span
          *ngIf="!isAttuneWcEligible || isSampleAccount || showAttuneWcDeclineReasons()"
          class="nav-quote-button-disabled js-attune-wc-not-in-appetite-warning"
          >Ineligible</span
        >
      </div>
      <!-- Only displays Attune WC eligibility decline reasons if there are no Attune WC quotes -->
      <div *ngIf="showAttuneWcDeclineReasons()" class="account-policies-content">
        <app-dialog-box
          header="Ineligible for EverPeak Workers' Compensation"
          type="warning"
          class="dialog-text__full-width dialog-text__no-margin"
        >
          <ul class="bulleted-list js-attune-wc-elgibility-decline-reasons">
            <li *ngFor="let reason of attuneWcEligibilityDeclineReasons">{{ reason }}</li>
          </ul>
        </app-dialog-box>
      </div>
    </ng-container>
  </section>
  <!-- End Attune WC section -->

  <!-- Start BOP Plus Section -->
  <section
    class="js-product-card__bop-plus"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="
      !insuredAccountHasBopPlusInForce() &&
      brokerIsEligibleForBOP() &&
      (!this.excludeNonCrossSell ||
        (this.eligibilities?.isBopEligible &&
          isProductAvailable({ product: 'bop', pasSource: 'attune_gw' }) &&
          isProductCrossSell({ product: 'bop', pasSource: 'attune_gw' }) &&
          !showBOPPrefillDeclineReasons())) &&
      !showAlternativeCarriersOnly
    "
  >
    <!-- Start BOP Plus Card -->
    <ng-container *ngIf="insuredAccountHasBopPlus() || insuredAccountHasMeToo()">
      <!-- Loading state -->
      <div *ngIf="loading || productAvailabilitiesLoading">
        <div
          class="account-policies-header"
          [ngClass]="{ 'account-policies-header__small': compact }"
        >
          <div class="product-badge-group">
            <div
              class="product-badge product-badge__loading-placeholder"
              [ngClass]="{ 'product-badge__small': compact }"
            ></div>
            <h3 class="account-policies-header-h3__loading-placeholder"></h3>
          </div>
          <div class="loading-placeholder-button"></div>
        </div>
        <div class="account-policies-content">
          <table>
            <thead>
              <tr class="quote-table-row">
                <th><div class="quote-th-loading-placeholder"></div></th>
                <th><div class="quote-th-loading-placeholder"></div></th>
                <th><div class="quote-th-loading-placeholder"></div></th>
                <th class="td__right"><div class="quote-th-loading-placeholder"></div></th>
              </tr>
            </thead>
            <tbody>
              <tr class="quote-table-row">
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td class="td__right"><div class="quote-td-loading-placeholder"></div></td>
              </tr>
              <tr class="quote-table-row">
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td class="td__right"><div class="quote-td-loading-placeholder"></div></td>
              </tr>
              <tr class="quote-table-row">
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td><div class="quote-td-loading-placeholder"></div></td>
                <td class="td__right"><div class="quote-td-loading-placeholder"></div></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- End of loading state -->
      <ng-container *ngIf="!loading">
        <div
          class="account-policies-header"
          [ngClass]="{ 'account-policies-header__small': compact }"
        >
          <div class="product-badge-group">
            <div
              class="product-badge product-badge__bop-plus"
              [ngClass]="{ 'product-badge__small': compact }"
            >
              <div
                class="product-badge-carrier-icon product-badge-carrier-icon__attune"
                [ngClass]="{ 'product-badge-carrier-icon__small': compact }"
              ></div>
            </div>
            <div>
              <h3 class="account-policies-header-h3">Businessowners' Policy</h3>
              <div
                *ngIf="isBopEligible && ['1', '2'].includes(bopPreferenceLevel)"
                class="preference-tag-container"
              >
                <div class="pill-tag pill-tag__preferred">
                  <img
                    class="badge-list-img"
                    src="{{
                      bopPreferenceLevel === '1'
                        ? '/assets/img/attune_strongly_preferred_heart.svg'
                        : '/assets/img/attune_preferred_green.svg'
                    }}"
                    alt=""
                  />
                  <span class="ml-1 preferred-text js-bop-preferred-text">
                    <strong>{{
                      bopPreferenceLevel === '1' ? 'strongly preferred' : 'preferred'
                    }}</strong>
                  </span>
                </div>
              </div>
              <p class="p p__no-margin">
                Attune <span class="gray-text">(Accredited)</span>&nbsp;&nbsp;<a
                  href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+Plus+UW+Guidelines.pdf"
                  target="_blank"
                  >Learn more</a
                >
              </p>
            </div>
          </div>
          <span
            *ngIf="showUWAlertWarning$ | async"
            class="tool-tip tool-tip__large tool-tip__left js-uw-alert-warning"
            data-tooltip="Quotes will be reviewed at bind"
            ><span class="account-uw-alert"></span
          ></span>
          <ng-container
            *ngIf="
              isBopEligible &&
              !showBOPPrefillDeclineReasons() &&
              !isBOPCatRestrictedForProducer &&
              !isSampleAccount &&
              !(showUWAlertWarning$ | async) &&
              !autobindDisabledForAllQuotes()
            "
          >
            <a
              class="button button__small button__no-margin js-new-quote-button"
              [ngClass]="{
                button__primary: !recentNonDeclinedBopPlusQuote,
                button__secondary: recentNonDeclinedBopPlusQuote
              }"
              [routerLink]="
                recentNonDeclinedBopPlusQuote
                  ? [
                      '/accounts',
                      accountId,
                      'bop',
                      'policies',
                      recentNonDeclinedBopPlusQuote.id,
                      'edit'
                    ]
                  : ['/accounts', accountId, 'bop', 'new']
              "
              [queryParams]="recentNonDeclinedBopPlusQuote ? { new: true } : false"
              data-amplitude-input-name="account-get-quote-bop"
            >
              <span *ngIf="recentNonDeclinedBopPlusQuote">New Quote</span>
              <span class="js-get-a-quote" *ngIf="!recentNonDeclinedBopPlusQuote">Get a Quote</span>
            </a>
          </ng-container>
          <span
            *ngIf="
              (!insuredAccountHasBopPlusQuotesOrDrafts() && !isBopEligible) ||
              isBOPCatRestrictedForProducer ||
              showBOPPrefillDeclineReasons()
            "
            class="nav-quote-button-disabled js-bop-not-in-appetite-warning"
            >Ineligible</span
          >
        </div>
        <!-- Only displays prefill decline reasons if there are no BOP quotes or drafts -->
        <div *ngIf="showBOPPrefillDeclineReasons()" class="account-policies-content">
          <app-dialog-box
            header="Ineligible for Accredited BOP"
            type="warning"
            class="dialog-text__full-width dialog-text__no-margin"
          >
            <ul class="bulleted-list">
              <li *ngFor="let reason of bopPrefillDeclineReasons">{{ reason }}</li>
            </ul>
          </app-dialog-box>
        </div>
      </ng-container>
    </ng-container>
  </section>
  <!-- End BOP plus section -->

  <!-- Start BOP section -->
  <section
    class="js-product-card__bop"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="
      !insuredAccountHasActivePolicies() &&
      !insuredAccountHasBopPlusInForce() &&
      insuredAccountHasBopV1() &&
      !insuredAccountHasBopPlus() &&
      !insuredAccountHasMeToo() &&
      !(this.excludeNonCrossSell && !this.eligibilities?.isBopEligible) &&
      !showAlternativeCarriersOnly
    "
  >
    <!-- Loading state -->
    <div *ngIf="loading">
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__loading-placeholder"
            [ngClass]="{ 'product-badge__small': compact }"
          ></div>
          <h3 class="account-policies-header-h3__loading-placeholder"></h3>
        </div>
        <div class="loading-placeholder-button"></div>
      </div>
      <div class="account-policies-content">
        <table>
          <thead>
            <tr class="quote-table-row">
              <th><div class="quote-th-loading-placeholder"></div></th>
              <th><div class="quote-th-loading-placeholder"></div></th>
              <th><div class="quote-th-loading-placeholder"></div></th>
              <th class="td__right"><div class="quote-th-loading-placeholder"></div></th>
            </tr>
          </thead>
          <tbody>
            <tr class="quote-table-row">
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td class="td__right"><div class="quote-td-loading-placeholder"></div></td>
            </tr>
            <tr class="quote-table-row">
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td class="td__right"><div class="quote-td-loading-placeholder"></div></td>
            </tr>
            <tr class="quote-table-row">
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td><div class="quote-td-loading-placeholder"></div></td>
              <td class="td__right"><div class="quote-td-loading-placeholder"></div></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- End of loading state -->

    <!-- Start BOP Card -->
    <ng-container *ngIf="!loading">
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__bop"
            [ngClass]="{ 'product-badge__small': compact }"
          >
            <div
              class="product-badge-carrier-icon product-badge-carrier-icon__attune"
              [ngClass]="{ 'product-badge-carrier-icon__small': compact }"
            ></div>
          </div>
          <div>
            <h3 class="account-policies-header-h3">Businessowners' Policy</h3>
            <p class="p p__no-margin">
              Attune <span class="gray-text">(Blackboard)</span>&nbsp;&nbsp;<a
                href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+UW+Guidelines.pdf"
                target="_blank"
                >Learn more</a
              >
            </p>
          </div>
        </div>
        <span
          *ngIf="showUWAlertWarning$ | async"
          class="tool-tip tool-tip__large tool-tip__left js-uw-alert-warning"
          data-tooltip="Quotes will be reviewed at bind"
          ><span class="account-uw-alert"></span
        ></span>
        <ng-container
          *ngIf="
            isBopEligible &&
            !showBOPPrefillDeclineReasons() &&
            !isSampleAccount &&
            !(showUWAlertWarning$ | async) &&
            !insuredAccountHasBopPlus()
          "
        >
          <a
            class="button button__small button__no-margin js-new-quote-button"
            [ngClass]="{
              button__primary: !recentNonDeclinedBopV1Quote,
              button__secondary: recentNonDeclinedBopV1Quote
            }"
            [routerLink]="
              recentNonDeclinedBopV1Quote
                ? [
                    '/accounts',
                    accountId,
                    'bop',
                    'policies',
                    recentNonDeclinedBopV1Quote.id,
                    'edit'
                  ]
                : ['/accounts', accountId, 'bop', 'new']
            "
            [queryParams]="recentNonDeclinedBopV1Quote ? { new: true } : false"
            data-amplitude-input-name="account-get-quote-bop"
          >
            <span *ngIf="recentNonDeclinedBopV1Quote">New Quote</span>
            <span class="js-get-a-quote" *ngIf="!recentNonDeclinedBopV1Quote">Get a Quote</span>
          </a>
        </ng-container>
        <span
          *ngIf="
            (!insuredAccountHasBopV1QuotesOrDrafts() && !isBopEligible) ||
            showBOPPrefillDeclineReasons()
          "
          class="nav-quote-button-disabled js-bop-not-in-appetite-warning"
          >Ineligible</span
        >
      </div>
      <!-- Only displays prefill decline reasons if there are no BOP quotes or drafts -->
      <div *ngIf="showBOPPrefillDeclineReasons()" class="account-policies-content">
        <app-dialog-box
          header="Ineligible for Blackboard BOP"
          type="warning"
          class="dialog-text__full-width dialog-text__no-margin"
        >
          <ul class="bulleted-list">
            <li *ngFor="let reason of bopPrefillDeclineReasons">{{ reason }}</li>
          </ul>
        </app-dialog-box>
      </div>
    </ng-container>
  </section>
  <!-- End BOP section -->

  <!-- Start Neptune section -->
  <section
    class="intro spinner-centered"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="loadingNeptune"
  >
    <div class="account-policies-header" [ngClass]="{ 'account-policies-header__small': compact }">
      <div class="product-badge-group">
        <div
          class="product-badge product-badge__flood"
          [ngClass]="{ 'product-badge__small': compact }"
        ></div>
        <div class="neptune-quote-details-js">
          <h3 class="account-policies-header-h3">Flood Insurance</h3>
          <div class="flood-risk-container__loading-placeholder"></div>
          <p class="p p__no-margin account-policies-header-p__loading-placeholder"></p>
        </div>
      </div>

      <div class="loading-placeholder-button"></div>
    </div>
  </section>
  <section
    class="js-product-card__neptune-flood"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="showNeptuneFloodQuote()"
  >
    <app-neptune-flood-details [neptuneFloodQuoteResponse]="neptuneFloodQuote" [compact]="compact">
    </app-neptune-flood-details>
  </section>
  <!-- End Neptune section -->

  <!-- Start cyber section -->
  <ng-container *ngFor="let productConfig of digitalProducts">
    <ng-container
      *ngIf="
        productConfig.pasSource === 'coalition' &&
        !(
          this.excludeNonCrossSell &&
          (!this.eligibilities?.isCyberEligible ||
            !isProductAvailable(productConfig) ||
            !isProductCrossSell(productConfig))
        ) &&
        !showAlternativeCarriersOnly
      "
    >
      <div
        *ngIf="accountSummaryView().status === 'loading' || loading || productAvailabilitiesLoading"
      >
        <div
          class="account-policies-header"
          [ngClass]="{ 'account-policies-header__small': compact }"
        >
          <div class="product-badge-group">
            <div
              class="product-badge product-badge__loading-placeholder"
              [ngClass]="{ 'product-badge__small': compact }"
            ></div>
            <h3 class="account-policies-header-h3__loading-placeholder"></h3>
          </div>
          <div class="loading-placeholder-button"></div>
        </div>
      </div>
      <ng-container
        *ngIf="
          accountSummaryView().status === 'success' && !loading && !productAvailabilitiesLoading
        "
      >
        <app-insured-account-product-card
          [accountId]="accountId"
          [eligibilities]="eligibilities"
          [productAvailabilities]="productAvailabilities"
          [naicsCode]="this.model.naicsCode"
          [productConfig]="productConfig"
          [accountSummary]="accountSummaryView()"
          [compact]="compact"
        >
        </app-insured-account-product-card>
      </ng-container>
    </ng-container>
  </ng-container>
  <!-- End cyber section -->

  <!-- Start Empoyers Workers Comp [Pre-DCP] Section -->
  <!-- TODO(NY): Can we just remove this app-product-card? It seems like it will never be shown because of the ngIf since excludeNonCrossSell is only true if hasWcPolicy is false -->
  <app-insured-account-product-card
    *ngIf="
      hasWcPolicy() &&
      !loading &&
      !productAvailabilitiesLoading &&
      !(
        this.excludeNonCrossSell &&
        (!this.eligibilities?.isEmployersWcEligible ||
          !isProductAvailable(employersWcProduct) ||
          this.hasWcQuote ||
          this.hasWcPolicy())
      )
    "
    [accountId]="accountId"
    [eligibilities]="eligibilities"
    [productAvailabilities]="productAvailabilities"
    [naicsCode]="this.model.naicsCode"
    [productConfig]="wcDigitalProduct"
    [accountSummary]="accountSummaryView()"
    [compact]="compact"
  >
  </app-insured-account-product-card>
  <section
    class="js-product-card__employers-wc"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="
      showEmployersProduct() &&
      !hasWcPolicy() &&
      !(
        this.excludeNonCrossSell &&
        (!this.eligibilities?.isEmployersWcEligible ||
          !isProductAvailable(employersWcProduct) ||
          this.hasWcQuote ||
          this.hasWcPolicy())
      )
    "
  >
    <ng-container *ngIf="isEmployersWcLoading()">
      <div class="account-policies-header">
        <div class="product-badge-group">
          <div class="product-badge product-badge__loading-placeholder"></div>
          <h3 class="account-policies-header-h3__loading-placeholder"></h3>
        </div>
        <div class="loading-placeholder-button"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isEmployersWcLoading()">
      <div
        class="account-policies-header"
        [ngClass]="{
          'account-policies-header__small': compact,
          'account-policies-header__transparent': !isStateWithEmployers(model.state)
        }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__wc"
            [ngClass]="{ 'product-badge__small': compact }"
          >
            <div
              class="product-badge-carrier-icon product-badge-carrier-icon__employers"
              [ngClass]="{ 'product-badge-carrier-icon__small': compact }"
            ></div>
          </div>
          <div>
            <h3 class="account-policies-header-h3">Workers' Compensation</h3>
            <p class="p p__no-margin">
              EMPLOYERS&nbsp;&nbsp;<a href="{{ employersWcGuidelinesLink }}" target="_blank"
                >Learn more</a
              >
            </p>
          </div>
        </div>
        <div class="nav-button-group nav-button-group__centered">
          <ng-container
            class="js-wc-buttons-for-demo"
            *ngIf="isEmployersWorkersCompEligible && isSampleAccount && isEmployersWcAvailable"
          >
            <button
              *ngIf="showWcGetQuote()"
              class="button button__small button__primary button__no-margin js-wc-get-a-quote"
              (click)="displayRestrictedWcNote()"
              (keypress)="displayRestrictedWcNote()"
            >
              Get a quote
            </button>
          </ng-container>
          <ng-container class="js-wc-buttons-not-for-demo" *ngIf="!isSampleAccount">
            <ng-container *ngIf="isEmployersWorkersCompEligible && isEmployersWcAvailable">
              <a
                *ngIf="showWcGetQuote()"
                class="button button__small button__primary button__no-margin js-wc-get-a-quote"
                [routerLink]="['/accounts', accountId, 'workers-comp', 'new']"
                [queryParams]="showAlternativeCarriersOnly ? { 'quote-feedback': 'true' } : {}"
                data-amplitude-input-name="account-get-quote-wc"
                >Get a quote</a
              >
            </ng-container>
            <a
              *ngIf="showWcEditQuote()"
              class="button button__small button__secondary button__no-margin js-wc-edit-quote"
              [ngClass]="{ button__disabled: wcQuoteReferring }"
              [routerLink]="['/accounts', accountId, 'workers-comp', 'edit']"
              >Edit Quote</a
            >
            <a
              *ngIf="showWcDraftQuote()"
              class="button button__small button__secondary button__no-margin js-wc-edit-quote"
              [ngClass]="{ button__disabled: wcQuoteReferring }"
              [routerLink]="['/accounts', accountId, 'workers-comp', 'new']"
              [queryParams]="showAlternativeCarriersOnly ? { 'quote-feedback': 'true' } : {}"
              >Edit Quote</a
            >
            <button
              *ngIf="showWcRequote()"
              class="button button__small button__secondary button__no-margin js-wc-requote-quote"
              [ngClass]="{ button__disabled: wcQuoteReferring }"
              (click)="requoteWcQuote()"
              (keypress)="requoteWcQuote()"
            >
              Requote
            </button>
            <span
              *ngIf="!isEmployersWorkersCompEligible && isEmployersWcAvailable"
              class="nav-quote-button-disabled js-wc-not-in-appetite-warning"
              >Ineligible</span
            >
            <span
              *ngIf="!isEmployersWcAvailable"
              class="nav-quote-button-disabled js-wc-not-in-appetite-warning"
              >Unavailable</span
            >
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="!isStateWithEmployers(model.state)">
        <div class="account-policies-content">
          <app-dialog-box header="Ineligible" type="warning" class="dialog-text__full-width">
            Our Employers Workers' Compensation is not available in {{ model.state }}.
          </app-dialog-box>
        </div>
      </ng-container>

      <ng-container *ngIf="this.hasWcQuote">
        <app-modal
          [open]="showWcPendingReferModal"
          (handleModalClose)="closeWcPendingReferModal()"
          modalClass="app-modal-content__wc-pending-refer"
        >
          <header>
            <div class="modal-image" role="presentation">
              <img src="../../../../../assets/img/airplane_icon.png" alt="" />
            </div>
            <h2>Referred</h2>
          </header>
          <section>
            <p class="wc-pending-refer-text">Estimate</p>
            <h2 class="wc-pending-refer-price">
              {{ wcQuote.premium | currency: 'USD':'symbol':'1.0-0' }}
            </h2>
            <p>
              This is an estimated premium, which is subject to change after review by our carrier's
              underwriting team. Would you like to continue with the referral?
            </p>
            <div class="nav-button-group nav-button-group__centered-horizontal">
              <button
                (click)="closeWcPendingReferModal()"
                (keypress)="closeWcPendingReferModal()"
                class="button button__secondary js-close-pending-refer-modal"
              >
                No thanks
              </button>
              <button
                (click)="referWcQuote()"
                (keypress)="referWcQuote()"
                class="button button__primary"
              >
                Yes, continue
              </button>
            </div>
          </section>
        </app-modal>
      </ng-container>
    </ng-container>
  </section>
  <!-- End Workers Comp [Pre-DCP] Section -->

  <!-- Starts GL V4 section -->
  <section
    class="js-product-card__hiscox-glv4"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="
      isGlV4Available &&
      !(
        this.excludeNonCrossSell &&
        (!this.eligibilities?.isGlEligible ||
          !isProductAvailable(glV4Product) ||
          !isProductCrossSell(glV4Product))
      ) &&
      !showAlternativeCarriersOnly
    "
  >
    <ng-container
      *ngIf="(isGlV4Loading() && !isHiscoxRolledOver()) || productAvailabilitiesLoading"
    >
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__loading-placeholder"
            [ngClass]="{ 'product-badge__small': compact }"
          ></div>
          <h3 class="account-policies-header-h3__loading-placeholder"></h3>
        </div>
        <div class="loading-placeholder-button"></div>
      </div>
    </ng-container>
    <!-- Starts GL V4 Card -->
    <ng-container
      *ngIf="!isGlV4Loading() && !isHiscoxRolledOver() && !productAvailabilitiesLoading"
    >
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__gl"
            [ngClass]="{ 'product-badge__small': compact }"
          >
            <div
              class="product-badge-carrier-icon product-badge-carrier-icon__hiscox"
              [ngClass]="{ 'product-badge-carrier-icon__small': compact }"
            ></div>
          </div>
          <div>
            <h3 class="account-policies-header-h3">General Liability</h3>
            <p class="p p__no-margin">
              Hiscox&nbsp;&nbsp;<a href="{{ hiscoxGlPlGuidelinesLink }}" target="_blank"
                >Learn more</a
              >
            </p>
          </div>
        </div>
        <div class="nav-button-group nav-button-group__centered">
          <ng-container
            class="js-gl-v4-buttons-for-demo"
            *ngIf="isGlV4Eligible && isSampleAccount && isGlV4Available"
          >
            <button
              *ngIf="showGlV4GetQuote()"
              class="button button__small button__primary button__no-margin js-gl-v4-get-a-quote"
              (click)="displayRestrictedNote()"
            >
              Get a quote
            </button>
          </ng-container>
          <ng-container class="js-gl-v4-buttons-not-for-demo" *ngIf="!isSampleAccount">
            <ng-container *ngIf="isGlV4Eligible && isGlV4Available">
              <div *ngIf="showGlV4GetQuote()">
                <a
                  class="
                    button button__small button__primary button__no-margin
                    js-gl-v4-get-a-quote
                  "
                  [routerLink]="['/accounts', accountId, 'gl', 'new']"
                  data-amplitude-input-name="account-get-quote-gl-v4"
                >
                  Get a quote
                </a>
              </div>
            </ng-container>
            <a
              *ngIf="showGlV4EditQuote()"
              class="button button__small button__secondary button__no-margin js-gl-v4-edit-quote"
              href="javascript:void(0)"
              [routerLink]="['/accounts', accountId, 'gl', 'edit']"
              >Edit Quote</a
            >
            <span
              *ngIf="!isGlV4Eligible && isGlV4Available"
              class="nav-quote-button-disabled js-gl-v4-not-in-appetite-warning"
              >Ineligible</span
            >
            <span
              *ngIf="!isGlV4Available"
              class="nav-quote-button-disabled js-gl-v4-not-in-appetite-warning"
              >Unavailable</span
            >
          </ng-container>
        </div>
      </div>
    </ng-container>
  </section>
  <!-- Ends GL V4 section -->

  <!-- Start PL V4 section -->
  <section
    class="js-product-card__hiscox-plv4"
    [ngClass]="{ 'product-eligibility-box': !compact }"
    *ngIf="
      isPlV4Available &&
      !(
        this.excludeNonCrossSell &&
        (!this.eligibilities?.isPlEligible ||
          !isProductAvailable(plV4Product) ||
          !isProductCrossSell(plV4Product))
      ) &&
      !showAlternativeCarriersOnly
    "
  >
    <ng-container
      *ngIf="(isPlV4Loading() && !isHiscoxRolledOver()) || productAvailabilitiesLoading"
    >
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__loading-placeholder"
            [ngClass]="{ 'product-badge__small': compact }"
          ></div>
          <h3 class="account-policies-header-h3__loading-placeholder"></h3>
        </div>
        <div class="loading-placeholder-button"></div>
      </div>
    </ng-container>
    <!-- Start PL Card -->
    <ng-container
      *ngIf="!isPlV4Loading() && !isHiscoxRolledOver() && !productAvailabilitiesLoading"
    >
      <div
        class="account-policies-header"
        [ngClass]="{ 'account-policies-header__small': compact }"
      >
        <div class="product-badge-group">
          <div
            class="product-badge product-badge__pl"
            [ngClass]="{ 'product-badge__small': compact }"
          >
            <div
              class="product-badge-carrier-icon product-badge-carrier-icon__hiscox"
              [ngClass]="{ 'product-badge-carrier-icon__small': compact }"
            ></div>
          </div>
          <div>
            <h3 class="account-policies-header-h3">Professional Liability</h3>
            <p class="p p__no-margin">
              Hiscox&nbsp;&nbsp;<a href="{{ hiscoxGlPlGuidelinesLink }}" target="_blank"
                >Learn more</a
              >
            </p>
          </div>
        </div>
        <div class="nav-button-group nav-button-group__centered">
          <ng-container
            class="js-pl-v4-buttons-for-demo"
            *ngIf="isPlV4Eligible && isSampleAccount && isPlV4Available"
          >
            <button
              *ngIf="showPlV4GetQuote()"
              class="button button__small button__primary button__no-margin js-pl-v4-get-a-quote"
              (click)="displayRestrictedNote()"
            >
              Get a quote
            </button>
          </ng-container>
          <ng-container class="js-pl-v4-buttons-not-for-demo" *ngIf="!isSampleAccount">
            <ng-container *ngIf="isPlV4Eligible && isPlV4Available">
              <div *ngIf="showPlV4GetQuote()">
                <a
                  class="
                    button button__small button__primary button__no-margin
                    js-pl-v4-get-a-quote
                  "
                  [routerLink]="['/accounts', accountId, 'pl', 'new']"
                  data-amplitude-input-name="account-get-quote-pl-v4"
                >
                  Get a quote
                </a>
              </div>
            </ng-container>
            <a
              *ngIf="showPlV4EditQuote()"
              class="button button__small button__secondary button__no-margin js-pl-v4-edit-quote"
              href="javascript:void(0)"
              [routerLink]="['/accounts', accountId, 'pl', 'edit']"
              >Edit Quote</a
            >
            <span
              *ngIf="!isPlV4Eligible && isPlV4Available"
              class="nav-quote-button-disabled js-pl-v4-not-in-appetite-warning"
              >Ineligible</span
            >
            <span
              *ngIf="!isPlV4Available"
              class="nav-quote-button-disabled js-pl-v4-not-in-appetite-warning"
              >Unavailable</span
            >
          </ng-container>
        </div>
      </div>
    </ng-container>
  </section>
  <!-- End PL V4 section -->
  <section class="js-digital-carrier-products">
    <ng-container *ngFor="let productConfig of digitalProducts">
      <ng-container
        *ngIf="
          productConfig.pasSource !== 'coalition' &&
          !(
            this.excludeNonCrossSell &&
            (
              !this.eligibilities?.[productConfig.eligibilityField] ||
              !isProductAvailable(productConfig) ||
              !isProductCrossSell(productConfig)
            )
          ) && !showAlternativeCarriersOnly"
      >
        <app-insured-account-product-eligibility-card
          [loading]="
            accountSummaryView().status === 'loading' || loading || productAvailabilitiesLoading
          "
          [small]="compact"
          [lob]="productConfig.product"
          [carrier]="productConfig.pasSource"
          [isAvailable]="isProductAvailable(productConfig)"
          [isEligible]="eligibilities?.[productConfig.eligibilityField]"
          [guidelinesLink]="productConfig.guidelinesLink"
          [hasQuotes]="productHasQuotes(productConfig)"
          [hasEditableQuotes]="productHasEditableQuotes(productConfig)"
          (navigate)="handleNavigate($event, productConfig)"
        >
        </app-insured-account-product-eligibility-card>
      </ng-container>
    </ng-container>
  </section>
</div>
