import { LibertyMutualClassCode } from './common-typings';

/**
 * NOTE: LM has different rules about which class codes can be set as primary and secondary class
 * codes (outlined here: https://docs.google.com/spreadsheets/d/1TPHQnas4sspTDfVBF3-sTL_n5g1x2cqP/edit#gid=162736424)
 * There are three categories:
 *   (a) classes that may be set as "primary" in all states
 *   (b) classes that may only be set as "primary" in certain states
 *   (c) classes that may never be set as "primary"

 * We are not currently handling secondary classes, so category (c) is not included in the below list.

 * Category (b) should be handled as COB exceptions, i.e. we will give an early decline if one of these
 * classes is selected for a state where that class cannot be the 'primary' class.
 */
export const LM_CPSP_GL_CLASS_CODES: LibertyMutualClassCode[] = [
  {
    name: 'Air Conditioning Equipment - Dealers or Distributors Only',
    code: '10010',
  },
  {
    name: 'Antique Stores',
    code: '10026',
  },
  {
    name: 'Appliance Distributors - Household Type',
    code: '10040',
  },
  {
    name: 'Appliance Stores - Household Type',
    code: '10042',
  },
  {
    name: 'Art Galleries - For Profit',
    code: '10065',
  },
  {
    name: 'Art Galleries - Not For Profit',
    code: '10066',
  },
  {
    name: 'Automobile Parts And Supplies Distributors',
    code: '10070',
  },
  {
    name: 'Automobile Parts And Supplies Stores',
    code: '10071',
  },
  {
    name: 'Automobile Quick Lubrication Services',
    code: '10072',
  },
  {
    name: 'Automobile Repair Or Service Shops NOC',
    code: '10073',
  },
  {
    name: 'Bakeries',
    code: '10100',
  },
  {
    name: 'Barber Or Beauty Shop Supplies Distributors',
    code: '10111',
  },
  {
    name: 'Barber Shops',
    code: '10113',
  },
  {
    name: 'Beauty Parlors And Hair Styling Salons',
    code: '10115',
  },
  {
    name: 'Beverage Distributors - Alcoholic Other Than Beer',
    code: '10140',
  },
  {
    name: 'Beverage Distributors - Nonalcoholic And Beer',
    code: '10141',
  },
  {
    name: 'Beverage Stores - Liquor And Wine',
    code: '10145',
  },
  {
    name: 'Beverage Stores - Soft Drinks And Beer',
    code: '10146',
  },
  {
    name: 'Bicycle Stores - Sales And Servicing',
    code: '10150',
  },
  {
    name: 'Books And Magazines Stores - For Profit',
    code: '10204',
  },
  {
    name: 'Building Material Dealers Other Than Secondhand Material',
    code: '10255',
  },
  {
    name: 'Building Material Distributors',
    code: '10257',
  },
  {
    name: 'Camera And Photographic Equipment Stores',
    code: '10309',
  },
  {
    name: 'Candy Or Confectionery Stores',
    code: '10352',
  },
  {
    name: 'Car Washes - Other Than Self Service',
    code: '10367',
  },
  {
    name: 'Car Washes - Self-Service',
    code: '10368',
  },
  {
    name: 'Carpet, Rug Or Upholstery Cleaning - Shop Only',
    code: '11007',
  },
  {
    name: 'Catalog Or Premium Coupon Redemption Stores',
    code: '11020',
  },
  {
    name: 'Caterers',
    code: '11039',
  },
  {
    name: 'Clothing Or Wearing Apparel Distributors',
    code: '11126',
  },
  {
    name: 'Clothing Or Wearing Apparel Stores - For Profit',
    code: '11127',
  },
  {
    name: 'Clothing Or Wearing Apparel Stores - Not For Profit',
    code: '11128',
  },
  {
    name: 'Computer Stores',
    code: '11160',
  },
  {
    name: 'Concessionaires - Checkroom, Shoeshine, Or Toilet Concessions In Hotels, Restaurants, Railroad Stations, Etc.',
    code: '11167',
  },
  {
    name: 'Concessionaires - NOC',
    code: '11168',
  },
  {
    name: 'Copying and Duplicating Services - Retail',
    code: '11222',
  },
  {
    name: 'Cosmetic, Hair or Skin Preparation Stores',
    code: '11234',
  },
  {
    name: 'Dairy Products or Butter and Egg Stores - For Profit',
    code: '11258',
  },
  {
    name: 'Dairy Products or Butter and Egg Stores - Not For Profit',
    code: '11259',
  },
  {
    name: 'Delicatessens',
    code: '11288',
  },
  {
    name: 'Dental Laboratories',
    code: '12014',
  },
  {
    name: 'Department or Discount Stores',
    code: '12356',
  },
  {
    name: 'Distributors - Food Or Drink NOC',
    code: '12361',
  },
  {
    name: 'Distributors - No Food Or Drink NOC',
    code: '12362',
  },
  {
    name: 'Drugstores - No Table Or Counter Service For Beverage Or Food',
    code: '12374',
  },
  {
    name: 'Drugstores NOC',
    code: '12375',
  },
  {
    name: 'Electrical Equipment Distributors',
    code: '12391',
  },
  {
    name: 'Electronics Store',
    code: '12393',
  },
  {
    name: 'Equipment, Fixtures Or Supplies - For Bars, Hotels, Offices, Restaurants Or Stores - Distributors',
    code: '12467',
  },
  {
    name: 'Fabric Stores',
    code: '12510',
  },
  {
    name: 'Fence Dealers',
    code: '12651',
  },
  {
    name: 'Floor Covering Distributors',
    code: '12797',
  },
  {
    name: 'Floor Covering Stores',
    code: '12805',
  },
  {
    name: 'Florists',
    code: '12841',
  },
  {
    name: 'Frozen Food - Distributors',
    code: '13049',
  },
  {
    name: 'Fruit, Nut or Vegetable Dealers',
    code: '13111',
  },
  {
    name: 'Fruit, Nut or Vegetable Distributors',
    code: '13112',
  },
  {
    name: 'Furniture Stores - For Profit',
    code: '13351',
  },
  {
    name: 'Gasoline Stations - Full Service',
    code: '13453',
  },
  {
    name: 'Gasoline Stations - Self-Service',
    code: '13454',
  },
  {
    name: 'Gasoline Stations - Self And Full Service Combined',
    code: '13455',
  },
  {
    name: 'Gift Shops - For Profit',
    code: '13506',
  },
  {
    name: 'Gift Shops - Not For Profit',
    code: '13507',
  },
  {
    name: 'Glass Dealers And Glaziers',
    code: '13590',
  },
  {
    name: 'Grocery Distributors',
    code: '13670',
  },
  {
    name: 'Grocery Stores - NOC',
    code: '13673',
  },
  {
    name: 'Hardware And Tool Distributors',
    code: '13715',
  },
  {
    name: 'Hardware Stores',
    code: '13716',
  },
  {
    name: 'Health Or Natural Food Stores',
    code: '13720',
  },
  {
    name: 'Hearing Aid Stores',
    code: '13759',
  },
  {
    name: 'Heating Or Combined Heating And Air Conditioning Equipment - Dealers Or Distributors Only',
    code: '13930',
  },
  {
    name: "Hobby, Craft Or Artists' Supply Stores",
    code: '14101',
  },
  {
    name: 'Home Improvement Stores',
    code: '14279',
  },
  {
    name: 'Ice Cream Stores',
    code: '14401',
  },
  {
    name: 'Ice Dealers And Distributors',
    code: '14405',
  },
  {
    name: 'Janitorial Supplies - Dealers Or Distributors',
    code: '14527',
  },
  {
    name: 'Jewelry Stores Or Distributors',
    code: '14655',
  },
  {
    name: 'Laundries And Dry Cleaners - Self-Service',
    code: '14731',
  },
  {
    name: 'Laundry And Dry Cleaning Or Dyeing Receiving Stations',
    code: '14732',
  },
  {
    name: 'Laundry And Dry Cleaning Stores',
    code: '14733',
  },
  {
    name: 'Laundry Rental Service',
    code: '14734',
  },
  {
    name: 'Locksmiths',
    code: '14913',
  },
  {
    name: 'Mail Box Or Packaging Stores',
    code: '15070',
  },
  {
    name: 'Meat, Fish, Poultry Or Seafood - Distributors',
    code: '15223',
  },
  {
    name: 'Meat, Fish, Poultry Or Seafood Stores',
    code: '15224',
  },
  {
    name: 'Metal Dealers Or Distributors - Nonstructural',
    code: '15404',
  },
  {
    name: 'Musical Instrument Stores',
    code: '15538',
  },
  {
    name: 'Nail Salons',
    code: '15600',
  },
  {
    name: 'Newspaper Or Magazine Distributors',
    code: '15607',
  },
  {
    name: 'Newsstands',
    code: '15608',
  },
  {
    name: 'Nursery - Garden',
    code: '15699',
  },
  {
    name: 'Optical Goods Stores',
    code: '15839',
  },
  {
    name: 'Paint, Wallpaper Or Wallcovering Stores',
    code: '15991',
  },
  {
    name: 'Painting, Picture Or Frame Stores',
    code: '15993',
  },
  {
    name: 'Paper Products Distributors',
    code: '16005',
  },
  {
    name: 'Pet Grooming',
    code: '16402',
  },
  {
    name: 'Pet Stores',
    code: '16403',
  },
  {
    name: 'Pet Training',
    code: '16404',
  },
  {
    name: 'Photographers',
    code: '16471',
  },
  {
    name: 'Plastic Or Rubber Supply Goods Distributors',
    code: '16501',
  },
  {
    name: 'Plumbing Supplies And Fixtures Dealers And Distributors',
    code: '16527',
  },
  {
    name: 'Printers Or Electrotypers Supplies - Distributors',
    code: '16588',
  },
  {
    name: 'Music Stores - Pre-Recorded',
    code: '16676',
  },
  {
    name: 'Refrigeration Equipment - Dealers And Distributors Only - Commercial',
    code: '16705',
  },
  {
    name: 'Restaurants - Operated By Concessionaires - For Profit',
    code: '16819',
  },
  {
    name: 'Restaurants - Operated By Concessionaires - Not For Profit',
    code: '16820',
  },
  {
    name: 'Restaurants - With No Sale of Alcoholic Beverages - With Table Service',
    code: '16900',
  },
  {
    name: 'Restaurants - With No Sale of Alcoholic Beverages - Without Table Service With Seating',
    code: '16901',
  },
  {
    name: 'Restaurants - With No Sale of Alcoholic Beverages - Without Seating',
    code: '16902',
  },
  {
    name: 'Restaurants - With Sale of Alcoholic Beverages That Are Less Than 30% of the Annual Receipts of the Restaurants With Table Service',
    code: '16910',
  },
  {
    name: 'Restaurants - With Sale of Alcoholic Beverages That Are Less Than 30% of the Annual Receipts of the Restaurants Without Table Service',
    code: '16911',
  },
  {
    name: 'Restaurants - With Sale of Alcoholic Beverages That Are 30% or More of but Less Than 75% of the Total Annual Receipts of the Restaurants - Without Dance Floor',
    code: '16916',
  },
  {
    name: 'Shoe Repair Shops',
    code: '18109',
  },
  {
    name: 'Shoe Stores',
    code: '18110',
  },
  {
    name: 'Spas Or Personal Enhancement Facilities',
    code: '18200',
  },
  {
    name: 'Sporting Goods Or Athletic Equipment Distributors',
    code: '18205',
  },
  {
    name: 'Sporting Goods Or Athletic Equipment Stores',
    code: '18206',
  },
  {
    name: 'Stationery Or Paper Products Stores',
    code: '18335',
  },
  {
    name: 'Stores - Food Or Drink NOC - For Profit',
    code: '18435',
  },
  {
    name: 'Stores - Food Or Drink NOC - Not For Profit',
    code: '18436',
  },
  {
    name: 'Stores - No Food Or Drink Noc - For Profit',
    code: '18437',
  },
  {
    name: 'Stores - No Food Or Drink Noc - Not For Profit',
    code: '18438',
  },
  {
    name: 'Supermarkets',
    code: '18501',
  },
  {
    name: 'Tailoring Or Dressmaking Establishments - Custom',
    code: '18507',
  },
  {
    name: 'Telecommunications Equipment Providers',
    code: '18575',
  },
  {
    name: 'Tire Dealers',
    code: '18616',
  },
  {
    name: 'Toy Distributors',
    code: '18833',
  },
  {
    name: 'Toy Stores',
    code: '18834',
  },
  {
    name: 'Variety Stores - Profit',
    code: '18911',
  },
  {
    name: 'Variety Stores - Non-Profit',
    code: '18912',
  },
  {
    name: 'Video Stores',
    code: '18920',
  },
  {
    name: 'Warehouses - Mini-Warehouses',
    code: '18991',
  },
  {
    name: 'Washing Machines, Dryers Or Ironers - Coin Meter Type',
    code: '19007',
  },
  {
    name: 'Water Softening Equipment - Rented To Others',
    code: '19051',
  },
  {
    name: 'Cemeteries - For Profit',
    code: '41603',
  },
  {
    name: 'Cemeteries - Not For Profit',
    code: '41604',
  },
  {
    name: 'Churches Or Other Houses Of Worship',
    code: '41650',
  },
  {
    name: 'Clubs - Racquet Sports And Handball',
    code: '41665',
  },
  {
    name: 'Clubs - Civic, Service Or Social - Having Buildings Or Premises Owned Or Leased - For Profit',
    code: '41667',
  },
  {
    name: 'Clubs - Civic, Service Or Social - Having Buildings Or Premises Owned Or Leased - Not For Profit',
    code: '41668',
  },
  {
    name: 'Clubs - Civic, Service Or Social - No Buildings Or Premises Owned Or Leased Except For Office Purposes - For Profit',
    code: '41669',
  },
  {
    name: 'Clubs - Civic, Service Or Social - No Buildings Or Premises Owned Or Leased Except For Office Purposes - Not For Profit',
    code: '41670',
  },
  {
    name: 'Computer Consulting Or Programming',
    code: '41675',
  },
  {
    name: 'Consultants - NOC',
    code: '41677',
  },
  {
    name: 'Convents or Monasteries',
    code: '41680',
  },
  {
    name: 'Crematories - Profit',
    code: '41696',
  },
  {
    name: "Entertainment Performed On Others' Premises",
    code: '43215',
  },
  {
    name: 'Funeral Homes Or Chapels',
    code: '43889',
  },
  {
    name: 'Golf Courses - Miniature',
    code: '44069',
  },
  {
    name: 'Event, Party, or Wedding Planners',
    code: '44280',
  },
  {
    name: 'Hotels And Motels - With Pools Or Beaches - Less Than Four Stories',
    code: '45190',
  },
  {
    name: 'Hotels And Motels - With Pools Or Beaches - Four Stories Or More',
    code: '45191',
  },
  {
    name: 'Hotels And Motels - Without Pools Or Beaches - Less Than Four Stories',
    code: '45192',
  },
  {
    name: 'Hotels And Motels - Without Pools Or Beaches - Four Stories Or More',
    code: '45193',
  },
  {
    name: 'Insurance Agents',
    code: '45334',
  },
  {
    name: 'Kennels - Breeding, Boarding Or Sales',
    code: '45450',
  },
  {
    name: 'Laundries And Dry Cleaning Plants',
    code: '45678',
  },
  {
    name: 'Mail Order Houses',
    code: '45901',
  },
  {
    name: 'Mailing Or Addressing Companies',
    code: '45937',
  },
  {
    name: 'Manufacturers Representatives',
    code: '45993',
  },
  {
    name: 'Mobile Home Parks Or Courts',
    code: '46202',
  },
  {
    name: 'Museums - For Profit',
    code: '46426',
  },
  {
    name: 'Museums - Not For Profit',
    code: '46427',
  },
  {
    name: 'Professional And Trade Associations - No Building Or Premises Owned Or Leased Except As Offices - For Profit',
    code: '46881',
  },
  {
    name: 'Professional And Trade Associations - No Building Or Premises Owned Or Leased Except As Offices - Not For Profit',
    code: '46882',
  },
  {
    name: 'Real Estate Agents',
    code: '47050',
  },
  {
    name: 'Recording Studios',
    code: '47103',
  },
  {
    name: 'Sales Or Service Organizations',
    code: '47367',
  },
  {
    name: 'Social Services - Consulting Services Only - Operated By The Private Sector',
    code: '48600',
  },
  {
    name: 'Taxidermists',
    code: '49005',
  },
  {
    name: 'Vending Machine Operations - Confection, Food, Beverage Or Ice',
    code: '49617',
  },
  {
    name: 'Vending Machine Operations NOC',
    code: '49619',
  },
  {
    name: 'Window Decorating',
    code: '49840',
  },
  {
    name: 'Air Conditioning Equipment Mfg.',
    code: '51116',
  },
  {
    name: 'Appliances And Accessories Mfg. - Commercial - Not Gas',
    code: '51221',
  },
  {
    name: 'Appliances And Accessories Mfg. - Household - Not Gas',
    code: '51224',
  },
  {
    name: 'Bakery Plants',
    code: '51315',
  },
  {
    name: 'Beverage Bottler - Soft Drinks - Carbonated - In Cans Or Plastic Bottles',
    code: '51355',
  },
  {
    name: 'Beverage Bottler - Soft Drinks - In Glass Bottles',
    code: '51356',
  },
  {
    name: 'Beverage Bottler - Soft Drinks - In Metal Cylinders',
    code: '51357',
  },
  {
    name: 'Beverage Bottler - Soft Drinks - In Paper Containers',
    code: '51358',
  },
  {
    name: 'Beverage Bottler - Soft Drinks - Not Carbonated - In Bottles Or Cans',
    code: '51359',
  },
  {
    name: 'Bolt, Nut, Rivet, Screw Or Washer Mfg.',
    code: '51500',
  },
  {
    name: 'Bookbinding - For Profit',
    code: '51516',
  },
  {
    name: 'Bottle And Jar Mfg. - Plastic - Nonreturnable',
    code: '51553',
  },
  {
    name: 'Bottle And Jar Mfg. - Plastic - Returnable',
    code: '51554',
  },
  {
    name: 'Candy Or Confectionery Products Mfg.',
    code: '51752',
  },
  {
    name: 'China, Porcelain Or Earthenware Mfg.',
    code: '51869',
  },
  {
    name: 'Clay Products Mfg.',
    code: '51877',
  },
  {
    name: 'Clothing Mfg.',
    code: '51896',
  },
  {
    name: 'Color Or Pigment Preparation',
    code: '51919',
  },
  {
    name: 'Communication Or Recording Systems Or Equipment Mfg. - Industrial Or Commercial',
    code: '51926',
  },
  {
    name: 'Communications Or Recording Systems Or Equipment Mfg. Other Than Industrial Or Commercial',
    code: '51927',
  },
  {
    name: 'Concrete or Plaster Products Mfg. - Not Structural',
    code: '51957',
  },
  {
    name: 'Dairy Products Mfg.',
    code: '52002',
  },
  {
    name: 'Door Or Window Mfg. - Other Than Wood',
    code: '52134',
  },
  {
    name: 'Electrical Equipment Mfg. - Other Than For Direct & Indirect Application To The Body',
    code: '52432',
  },
  {
    name: 'Electrical Parts, Components Or Accessories Mfg. NOC',
    code: '52438',
  },
  {
    name: 'Electrical Wire Or Cable Mfg.',
    code: '52467',
  },
  {
    name: 'Electronic Components Mfg.',
    code: '52469',
  },
  {
    name: 'Electronic Games Mfg.',
    code: '52505',
  },
  {
    name: 'Engraving',
    code: '52660',
  },
  {
    name: 'Eye Glass Lens Mfg.',
    code: '52967',
  },
  {
    name: 'Food Products Mfg. - Dry',
    code: '53374',
  },
  {
    name: 'Food Products Mfg. - Frozen',
    code: '53375',
  },
  {
    name: 'Food Products Mfg. - Not Dry - In Glass Containers',
    code: '53376',
  },
  {
    name: 'Food Products Mfg. - Not Dry - In Other Than Glass Containers',
    code: '53377',
  },
  {
    name: 'Fruit Or Vegetable Juice Mfg. - No Bottling Of Carbonated Beverages',
    code: '53565',
  },
  {
    name: 'Furniture Mfg. Or Assembling - Other Than Wood',
    code: '53732',
  },
  {
    name: 'Glass Or Glassware Manufacturing',
    code: '54077',
  },
  {
    name: 'Honey Extracting',
    code: '55371',
  },
  {
    name: 'Jewelry Mfg.',
    code: '55802',
  },
  {
    name: 'Lamps Or Lanterns Mfg. - Electric',
    code: '56041',
  },
  {
    name: 'Lamps Or Lanterns Mfg. - Other Than Electric',
    code: '56042',
  },
  {
    name: 'Leather Goods Mfg. NOC',
    code: '56202',
  },
  {
    name: 'Light Bulb Or Tubes Mfg.',
    code: '56390',
  },
  {
    name: 'Lighting Fixtures Mfg. NOC',
    code: '56391',
  },
  {
    name: 'Meat, Fish, Poultry Or Seafood - Curing',
    code: '56758',
  },
  {
    name: 'Meat, Fish, Poultry Or Seafood Processing - In Airtight Containers',
    code: '56759',
  },
  {
    name: 'Meat, Fish, Poultry Or Seafood Processing - Not In Airtight Containers',
    code: '56760',
  },
  {
    name: 'Musical Instrument Mfg.',
    code: '57257',
  },
  {
    name: 'Net Mfg - Other Than Safety Nets',
    code: '57410',
  },
  {
    name: 'Office Machines Mfg.',
    code: '57572',
  },
  {
    name: 'Optical Goods Manufacturing',
    code: '57600',
  },
  {
    name: 'Paper Coating Or Finishing',
    code: '57716',
  },
  {
    name: 'Pattern Mfg. - Metal',
    code: '57808',
  },
  {
    name: 'Pencil, Pen, Crayon Or Chalk Mfg.',
    code: '57871',
  },
  {
    name: 'Pet Food Mfg.',
    code: '57913',
  },
  {
    name: 'Photo Finishing Laboratories',
    code: '57997',
  },
  {
    name: 'Photographic Equipment Mfg.',
    code: '57998',
  },
  {
    name: 'Pipes Or Tubes Mfg. - Metal',
    code: '58009',
  },
  {
    name: 'Plastic Or Rubber Goods Mfg. - Household NOC',
    code: '58057',
  },
  {
    name: 'Plumbing Fixtures Mfg.',
    code: '58095',
  },
  {
    name: 'Plumbing Supplies Mfg. NOC',
    code: '58096',
  },
  {
    name: 'Printing - For Profit',
    code: '58408',
  },
  {
    name: 'Printing - Not For Profit',
    code: '58409',
  },
  {
    name: 'Publishers - Books Or Magazines - For Profit',
    code: '58456',
  },
  {
    name: 'Publishers - Books Or Magazines - Not For Profit',
    code: '58457',
  },
  {
    name: 'Publishers - Newspapers - For Profit',
    code: '58458',
  },
  {
    name: 'Publishers - Newspapers - Not For Profit',
    code: '58459',
  },
  {
    name: 'Media Mfg. - prerecorded',
    code: '58627',
  },
  {
    name: 'Refrigeration Equipment Mfg',
    code: '58663',
  },
  {
    name: 'Rubber Stamp Mfg. Or Assembling',
    code: '58759',
  },
  {
    name: 'Sewing Machines Mfg. - Commercial',
    code: '58903',
  },
  {
    name: 'Sheet Metal Work - Shop Only',
    code: '58922',
  },
  {
    name: 'Shoe, Boot Or Slipper Mfg.',
    code: '59005',
  },
  {
    name: 'Sign Mfg. - Electrical',
    code: '59057',
  },
  {
    name: 'Sign Mfg. - Other Than Electrical',
    code: '59058',
  },
  {
    name: 'Soap Mfg.',
    code: '59223',
  },
  {
    name: 'Stone Cutting Or Polishing',
    code: '59482',
  },
  {
    name: 'Tool Mfg. - Accessories NOC',
    code: '59781',
  },
  {
    name: 'Tool Mfg. - Hand Type - Not Powered',
    code: '59782',
  },
  {
    name: 'Umbrella Or Cane Mfg.',
    code: '59889',
  },
  {
    name: 'Vegetable Oil Mfg. NOC',
    code: '59905',
  },
  {
    name: 'Metal Works - Shop - Decorative Or Artistic',
    code: '59914',
  },
  {
    name: 'Vending Machines Mfg.',
    code: '59915',
  },
  {
    name: 'Venetian Blinds Mfg Or Assembling',
    code: '59917',
  },
  {
    name: 'Watch Or Watch Case Mfg.',
    code: '59923',
  },
  {
    name: 'Wine Mfg. - Sparkling',
    code: '59963',
  },
  {
    name: 'Wine Mfg. - Still',
    code: '59964',
  },
  {
    name: 'Wire Goods Mfg. NOC',
    code: '59975',
  },
  {
    name: 'Apartment Buildings',
    code: '60010',
  },
  {
    name: 'Apartment Buildings - Garden',
    code: '60011',
  },
  {
    name: 'Buildings Or Premises - Bank And Other Financial Institutions',
    code: '61223',
  },
  {
    name: 'Buildings Or Premises-Office-Premises Occupied by Employees of The Insured-Other Than-Not-For-Profit',
    code: '61224',
  },
  {
    name: 'Buildings Or Premises-Office-Premises Occupied by Employees of The Insured-Not-For-Profit Only',
    code: '61225',
  },
  {
    name: 'Buildings Or Premises - Office NOC - For Profit',
    code: '61226',
  },
  {
    name: 'Buildings Or Premises - Office NOC - Not For Profit',
    code: '61227',
  },
  {
    name: 'Condominiums - Commercial - Bank or Mercantile, Manufacturing or Office (Association Risk Only)',
    code: '62000',
  },
  {
    name: 'Condominiums - Commercial - Shopping Centers (Association Risk Only)',
    code: '62001',
  },
  {
    name: 'Condominiums - Commercial Warehouses - Manufacturing Or Private (Association Risk Only)',
    code: '62002',
  },
  {
    name: 'Condominiums - Residential - (Association Risk Only)',
    code: '62003',
  },
  {
    name: 'Lawyers Offices - For Profit',
    code: '66122',
  },
  {
    name: 'Lawyers Offices - Not For Profit',
    code: '66123',
  },
  {
    name: 'Libraries',
    code: '66309',
  },
  {
    name: 'Medical Offices',
    code: '66561',
  },
  {
    name: 'Ticket Agencies',
    code: '68439',
  },
  {
    name: 'Townhouse Associations including Homeowners Associations (association risk only)',
    code: '68500',
  },
  {
    name: 'Advertising Sign Companies - Outdoor',
    code: '90089',
  },
  {
    name: 'Air Conditioning Systems or Equipment - Dealers Or Distributors And Installation, Servicing or Repair',
    code: '91111',
  },
  {
    name: 'Appliances And Accessories - Installation, Servicing Or Repair - Commercial',
    code: '91150',
  },
  {
    name: 'Appliances And Accessories - Installation, Servicing Or Repair - Household',
    code: '91155',
  },
  {
    name: "Auctioneers - Sales Conducted Away From The Insured's Premises",
    code: '91177',
  },
  {
    name: 'Auctions - On Premises Owned Or Rented By The Insured',
    code: '91179',
  },
  {
    name: 'Cable Installation In Conduits Or Subways',
    code: '91302',
  },
  {
    name: 'Carpentry - Construction Of Residential Property Not Exceeding Three Stories In Height',
    code: '91340',
  },
  {
    name: 'Carpentry - Interior',
    code: '91341',
  },
  {
    name: 'Carpentry NOC',
    code: '91342',
  },
  {
    name: "Carpet, Rug, Furniture Or Upholstery Cleaning - On Customers' Premises",
    code: '91405',
  },
  {
    name: 'Ceiling Or Wall Installation - Metal',
    code: '91436',
  },
  {
    name: 'Communication Equipment Installation - Industrial Or Commercial',
    code: '91551',
  },
  {
    name: 'Computer Service Or Repair',
    code: '91555',
  },
  {
    name: 'Concrete Construction',
    code: '91560',
  },
  {
    name: 'Conduit Construction For Cables or Wires',
    code: '91577',
  },
  {
    name: 'Door, Window or Assembled Millwork - Installation, Service or Repair - Metal',
    code: '91746',
  },
  {
    name: 'Draftsmen',
    code: '91805',
  },
  {
    name: 'Drilling - Water',
    code: '92102',
  },
  {
    name: 'Driveway, Parking Area Or Sidewalk - Paving Or Repaving',
    code: '92215',
  },
  {
    name: 'Dry Wall Or Wallboard Installation',
    code: '92338',
  },
  {
    name: 'Electrical Apparatus - Installation, Servicing Or Repair NOC',
    code: '92451',
  },
  {
    name: 'Electrical Work - Within Buildings',
    code: '92478',
  },
  {
    name: 'Engineers Or Architects - Consulting - Not Engaged In Actual Construction',
    code: '92663',
  },
  {
    name: 'Excavation',
    code: '94007',
  },
  {
    name: 'Fence Erection Contractors',
    code: '94276',
  },
  {
    name: 'Floor Covering Installation - Not Ceramic Tile Or Stone',
    code: '94569',
  },
  {
    name: 'Furniture Or Fixtures - Installation In Offices Or Stores - Portable - Metal Or Wood',
    code: '95124',
  },
  {
    name: 'Grading Of Land',
    code: '95410',
  },
  {
    name: 'Greenhouse Erection',
    code: '95487',
  },
  {
    name: 'Handyperson',
    code: '95625',
  },
  {
    name: 'Heating Or Combined Heating And Air Conditioning Systems Or Equipment - Dealers Or Distributors And Installation, Servicing Or Repair - No Liquefied Petroleum Gas (LPG) Equipment Sales Or Work',
    code: '95647',
  },
  {
    name: 'Heating Or Combined Heating And Air Conditioning Systems Or Equipment - Dealers Or Distributors And Installation, Servicing Or Repair NOC',
    code: '95648',
  },
  {
    name: 'House Furnishings Installation NOC',
    code: '96053',
  },
  {
    name: 'Inspection And Appraisal Companies - Inspecting For Insurance Or Valuation Purposes',
    code: '96317',
  },
  {
    name: 'Interior Decorators',
    code: '96611',
  },
  {
    name: 'Janitorial Services',
    code: '96816',
  },
  {
    name: 'Landscape Gardening',
    code: '97047',
  },
  {
    name: 'Lawn Care Services',
    code: '97050',
  },
  {
    name: 'Machine Shops NOC',
    code: '97220',
  },
  {
    name: 'Masonry',
    code: '97447',
  },
  {
    name: 'Metal Erection - Decorative Or Artistic',
    code: '97650',
  },
  {
    name: 'Metal Erection - Nonstructural NOC',
    code: '97653',
  },
  {
    name: 'Motion Pictures - Development Of Negatives, Printing And All Subsequent Operations',
    code: '98090',
  },
  {
    name: 'Motion Pictures - Film Distribution Or Exchanges - Not Located At Motion Picture Studios',
    code: '98091',
  },
  {
    name: 'Office Machines Or Appliances - Installation, Inspection, Adjustment Or Repair',
    code: '98111',
  },
  {
    name: 'Painting - Exterior - Buildings Or Structures - Three Stories Or Less In Height NOC',
    code: '98304',
  },
  {
    name: 'Painting - Interior - Buildings Or Structures',
    code: '98305',
  },
  {
    name: 'Paperhanging',
    code: '98344',
  },
  {
    name: 'Piano Tuning',
    code: '98405',
  },
  {
    name: 'Plumbing - Commercial And Industrial',
    code: '98482',
  },
  {
    name: 'Plumbing - Residential Or Domestic',
    code: '98483',
  },
  {
    name: 'Prefabricated Building Erection',
    code: '98502',
  },
  {
    name: 'Radio Or TV Broadcasting Stations - For Profit',
    code: '98597',
  },
  {
    name: 'Radio Or TV Broadcasting Stations - Not For Profit',
    code: '98598',
  },
  {
    name: 'Refrigeration Systems Or Equipment - Dealers And Distributors And Installation, Servicing Or Repair - Commercial',
    code: '98636',
  },
  {
    name: 'Sand Or Gravel Digging',
    code: '98710',
  },
  {
    name: 'Septic Tank Systems - Cleaning',
    code: '98805',
  },
  {
    name: 'Septic Tank Systems - Installation, Servicing Or Repair',
    code: '98806',
  },
  {
    name: 'Sewer Cleaning',
    code: '98813',
  },
  {
    name: 'Sheet Metal Work - Outside',
    code: '98884',
  },
  {
    name: 'Siding Installation',
    code: '98967',
  },
  {
    name: 'Sign Erection, Installation Or Repair',
    code: '98993',
  },
  {
    name: 'Sign Painting Or Lettering - Inside Of Buildings',
    code: '99003',
  },
  {
    name: 'Sign Painting Or Lettering On Buildings Or Structures',
    code: '99004',
  },
  {
    name: 'Surveyors - Land - Not Engaged In Actual Construction',
    code: '99471',
  },
  {
    name: 'Swimming Pool Servicing',
    code: '99505',
  },
  {
    name: 'Telecommunications Service Providers',
    code: '99600',
  },
  {
    name: 'Teleproduction Studios',
    code: '99620',
  },
  {
    name: 'Television Or Radio Receiving Set Installation Or Repair',
    code: '99650',
  },
  {
    name: 'Tile, Stone, Marble, Mosaic Or Terrazzo Work - Interior Construction',
    code: '99746',
  },
  {
    name: 'Upholstering NOC',
    code: '99826',
  },
  {
    name: 'Upholstering - Shop Only',
    code: '99827',
  },
  {
    name: 'Veterinarian Or Veterinary Hospitals',
    code: '99851',
  },
  {
    name: 'Water Mains Or Connections Construction',
    code: '99946',
  },
  {
    name: 'Water Softening Equipment - Installation, Servicing Or Repair',
    code: '99948',
  },
];
