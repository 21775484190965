import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SentryService } from 'app/core/services/sentry.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { from as observableFrom, Observable } from 'rxjs';

import { GET_DOCUMENT_UPLOAD_LINK } from 'app/constants';

@Injectable()
export class FileUploadService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  public getUploadLink(
    fileType: string,
    fileEnding: string,
    metadata: object = {}
  ): Observable<{
    s3Link: string;
    s3Bucket: string;
    s3Key: string;
  }> {
    return this.http
      .post(
        GET_DOCUMENT_UPLOAD_LINK,
        {
          ...metadata,
          documentType: 'broker_upload',
          contentType: fileType, // Official MIME Type
          fileEnding: fileEnding,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .pipe(
        catchError((error) => {
          this.sentryService.notify(`Error creating upload link / uploading file.`, {
            severity: 'error',
            metaData: {
              ...metadata,
              underlyingError: error,
            },
          });
          throw error;
        }),
        map((response: any) => {
          return {
            s3Link: response.link,
            s3Key: response.s3Key,
            s3Bucket: response.s3Bucket,
          };
        })
      );
  }

  public uploadToS3(putUrl: string, file: File, mimeType: string): Observable<any> {
    const uploadFilePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as ArrayBuffer;
        if (result.byteLength === 0) {
          reject();
        }
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject();
      };

      reader.readAsArrayBuffer(file);
    });

    return observableFrom(uploadFilePromise).pipe(
      switchMap((buffer: ArrayBuffer) => {
        return this.http.put(putUrl, buffer, {
          headers: {
            'Content-Type': mimeType,
          },
        });
      })
    );
  }
}
