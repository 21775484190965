<header class="invoice-list-sidebar">
  <app-logo-with-text class="logo__invoice-list"></app-logo-with-text>
  <ng-container *ngIf="isBannerShowing()">
    <div class="invoice-list-mobile-banner">
      <div *ngIf="invoiceView().banners.overdueInvoice">
        <p
          class="
            dialog-text dialog-text__warning dialog-text__full-width dialog-text__no-margin
            js-overdue-dialog
          "
        >
          You have one or more overdue invoice(s), if payment is not received by
          <strong>
            {{ formatDateLong(invoiceView().banners.gracePeriodAfterEarliestDueDate) }}</strong
          >, a notice of cancellation will be mailed.
        </p>
      </div>
      <div *ngIf="invoiceView().banners.otherCancelReasons">
        <p
          class="
            dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
            js-uw-cancel-outstanding-invoice-dialog
          "
        >
          Your policy is pending cancellation effective
          <strong>{{ formatDateLong(invoiceView().cancellationDate) }}</strong
          >. Once this happens, you will no longer have coverage. Please pay outstanding charges. To
          learn more, please contact us
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a>.
        </p>
      </div>
      <div *ngIf="invoiceView().banners.nonpayCancelWithOutstanding">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-canceled-outstanding-invoice-dialog
          "
        >
          Your policy was canceled effective
          <strong> {{ formatDateLong(invoiceView().cancellationDate) }}</strong
          >. Please pay outstanding charges for coverage provided through the cancellation effective
          date. Making payment will not reinstate coverage. Click
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a> for information on
          how to request a review for reinstatement.
        </p>
      </div>
      <div *ngIf="invoiceView().banners.nonpayCancelWithoutOutstanding">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-nopay-no-outstanding-canceled-invoice
          "
        >
          Your policy was canceled effective
          <strong> {{ formatDateLong(invoiceView().cancellationDate) }}.</strong>
        </p>
      </div>
      <div *ngIf="invoiceView().banners.uwCancelWithOutstanding">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-uw-outstanding-canceled-dialog
          "
        >
          Your policy was canceled by the carrier effective
          <strong> {{ formatDateLong(invoiceView().cancellationDate) }}</strong
          >. Please pay outstanding charges for coverage provided through the cancellation effective
          date. Making payment will not reinstate coverage. To learn more, please contact your
          broker.
        </p>
      </div>
      <div *ngIf="invoiceView().banners.uwCancelWithoutOutstanding">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-uw-no-outstanding-canceled-dialog
          "
        >
          Your policy was canceled by the carrier effective
          <strong> {{ formatDateLong(invoiceView().cancellationDate) }}.</strong>
          To learn more, please contact your broker.
        </p>
      </div>

      <div *ngIf="invoiceView().banners.outstandingWithoutDelinquencies">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-outstanding-no-deliquent-dialog
          "
        >
          You are in good standing but have outstanding charges. We strongly recommend paying before
          becoming delinquent.
        </p>
      </div>
      <div *ngIf="invoiceView().banners.uwPendingCancel">
        <p
          class="
            dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
            js-uw-cancel-outstanding-invoice-dialog
          "
        >
          Your policy is pending cancellation by the carrier effective
          <strong>{{ formatDateLong(invoiceView().cancellationDate) }}</strong
          >. Once this happens, you will no longer have coverage. Please pay outstanding charges. To
          learn more, please contact us
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a>
        </p>
      </div>

      <div *ngIf="invoiceView().banners.nonpayPendingCancellation">
        <p
          class="
            dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
            js-uw-cancel-outstanding-invoice-dialog
          "
        >
          Your policy is pending cancellation. If you do not pay the minimum due before
          <strong>{{ formatDateLong(invoiceView().cancellationDate) }}, </strong>the policy will
          cancel. Once this happens, you will no longer have coverage.
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="invoiceView().status === 'error' || invoiceView().status === 'loading'">
    <div class="placeholder-h2"></div>
    <div class="placeholder-contact-item"></div>
    <div class="placeholder-contact-item"></div>
  </ng-container>
  <ng-container *ngIf="invoiceView().status === 'success'">
    <h1 class="h2 h2__invoice-list-header">{{ invoiceView().accountName }}</h1>
    <small class="gray-text">ACCT NO. {{ invoiceView().accountNumber }}</small>
    <br />
    <ng-container
      *ngIf="!invoiceView().isCancelled && invoiceView().remainingAnnualPremium !== null"
    >
      <small class="small-text">REMAINING ANNUAL AMOUNT</small>
      <h2 class="h3 h3__invoice-list-header js-remaining-amount">
        {{ invoiceView().remainingAnnualPremium | currency }}
      </h2>
    </ng-container>

    <ng-container [ngSwitch]="invoiceView().billingStatus">
      <ng-container *ngSwitchCase="'overdue'">
        <small class="small-text js-bill-header">OVERDUE BALANCE</small>
        <h2 class="h3 h3__invoice-list-header js-overdue-balance">
          {{ invoiceView().overdueAmount | currency }}
        </h2>
      </ng-container>

      <ng-container *ngSwitchCase="'current'">
        <small class="small-text js-bill-header">CURRENT BILL</small>
        <h2 class="h3 h3__invoice-list-header js-current-bill">
          {{ invoiceView().amountToPay | currency }}
        </h2>
      </ng-container>

      <ng-container *ngSwitchCase="'next'">
        <small class="small-text js-bill-header">NEXT BILL</small>
        <ng-container *ngIf="!invoiceView().hasAutopayEnabled && invoiceView().nextBillAmount > 0">
          <h2 class="h3 h3__invoice-list-header js-next-bill">
            {{ invoiceView().nextBillAmount | currency }} due on {{ invoiceView().nextDueDate }}
          </h2>
        </ng-container>
        <ng-container *ngIf="invoiceView().hasAutopayEnabled && invoiceView().nextBillAmount > 0">
          <h2 class="h3 h3__invoice-list-header js-next-bill">
            {{ invoiceView().nextBillAmount | currency }} drafting on
            {{ formatDate(getDraftDate(invoiceView().nextDueDate)) }}
          </h2>
        </ng-container>
        <ng-container *ngIf="invoiceView().amountToPay === 0 && invoiceView().payoffAmount === 0">
          <h2 class="h3 h3__invoice-list-header js-next-bill">Paid in full</h2>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="invoice-list-info-item" *ngIf="invoiceView().hasAutopayEnabled">
      <span class="invoice-list-info-value">
        <span class="js-autopay-method-text">{{ invoiceView().currentAutopayText }}</span>
        <span
          *ngIf="invoiceView().banners.autopayPastDueInvoices"
          class="more-info-tip payment-info-tip tool-tip tool-tip__large tool-tip__top"
          data-tooltip="Uh oh, there seems to be something wrong with your saved payment method."
        >
          <span class="icon icon-warning"></span>
        </span>
      </span>
    </div>
    <div class="invoice-list-payment-buttons">
      <button
        id="stripeButton"
        class="button button__primary button__full-width js-make-payment"
        type="submit"
        *ngIf="invoiceView().amountToPay > 0"
        (click)="openMakePaymentModal()"
      >
        Make payment
      </button>
      <button
        class="button button__secondary button__full-width js-set-up-autopay"
        *ngIf="!invoiceView().hasAutopayEnabled"
        (click)="openAutopayModal()"
      >
        Set up Autopay
      </button>
      <button
        class="button button__secondary button__full-width js-edit-autopay"
        *ngIf="invoiceView().hasAutopayEnabled"
        (click)="openUpdatePaymentModal()"
      >
        Edit Autopay Method
      </button>
    </div>
    <!-- TODO(olex): should be dl dt dd list when that's unstyled! -->
    <ng-container *ngIf="!hideAccountDetail">
      <small class="gray-text"
        >The information shown below is for the current policy period only.</small
      >

      <div class="invoice-list-info">
        <div class="invoice-list-info-item">
          <strong class="invoice-list-info-key">Policy Period:</strong>
          <span class="invoice-list-info-value">
            {{ invoiceView().relevantPolicy.periodStart.format(dateMask) }} –
            {{ invoiceView().relevantPolicy.periodEnd.format(dateMask) }}
          </span>
        </div>
        <div
          class="invoice-list-info-item"
          *ngIf="invoiceView().showPremium && !invoiceView().isCancelled"
        >
          <strong class="invoice-list-info-key">Annual Amount:</strong>
          <span class="invoice-list-info-value">
            <span
              *ngFor="let item of invoiceView().relevantPolicy.premiums | keyvalue"
              class="invoice-list-info-item"
            >
              {{ invoiceView().relevantPolicyHasMultiplePremiums ? item.key + ' – ' : '' }}
              {{ item.value | currency }}
            </span>
          </span>
        </div>
        <div class="invoice-list-info-item">
          <strong class="invoice-list-info-key">Payment Plan:</strong>
          <span class="invoice-list-info-value">
            <span
              *ngFor="let item of invoiceView().relevantPolicy.paymentPlans | keyvalue"
              class="invoice-list-info-item"
            >
              {{ invoiceView().relevantPolicyHasMultipleProducts ? item.key + ' – ' : '' }}
              {{ item.value }}
            </span>
          </span>
        </div>
        <div
          *ngIf="
            !invoiceView().includesCyberPolicy &&
            !invoiceView().includesAttuneWcPolicy &&
            invoiceView().status !== 'loading' &&
            !invoiceView().pendingReinstatementInvoice
          "
          class="invoice-list-info-item"
        >
          <a
            data-amplitude-input-name="change_plan_modal_open"
            (click)="openPaymentPlanModal()"
            (keyup.enter)="openPaymentPlanModal()"
            >Edit Payment Plan</a
          >
        </div>
      </div>

      <hr class="hr__small" />
      <!-- TODO agent info-->
      <strong>Downloadable documents</strong>
      <br />
      <ng-container *ngIf="isLoadingScheduleOfInvoices || isLoadingInsuredGuide">
        <span class="gray-text invoice-list-document-loading">Please wait ...</span>
        <app-loading-spinner class="invoice-list-loading-spinner"></app-loading-spinner>
        <br />
      </ng-container>
      <a
        (click)="downloadScheduleOfInvoices()"
        (keyup.enter)="downloadScheduleOfInvoices()"
        *ngIf="!invoiceView().pendingReinstatementInvoice"
        >Schedule of Invoices</a
      >
      <br />
      <a (click)="downloadInsuredBillingGuide()" (keyup.enter)="downloadInsuredBillingGuide()"
        >Insured Billing Guide</a
      >
      <br />
    </ng-container>
    <br />
    <div class="invoice-list-faq">
      <small class="gray-text"
        >Have a billing question? Check out our
        <a href="https://attunehelp.zendesk.com/hc/en-us">FAQs</a></small
      >
      <br />
    </div>

    <div class="account-info-toggle">
      <button
        class="button button__small button__no-margin button__secondary js-endorse-dropdown-button"
        aria-expanded="false"
        *ngIf="!hideAccountDetail"
        (click)="hideAccountDetail = true"
      >
        Hide Account Info
        <span class="icon-arrow-up dropdown-icon"></span>
      </button>

      <button
        class="button button__small button__no-margin button__secondary js-endorse-dropdown-button"
        aria-expanded="false"
        *ngIf="hideAccountDetail"
        (click)="hideAccountDetail = false"
      >
        Show Account Info
        <span class="icon-arrow-down dropdown-icon"></span>
      </button>
    </div>

    <app-modal
      [open]="isShowingPaymentPlanModal()"
      (handleModalClose)="closeStripePaymentModal()"
      modalClass="app-modal-content__payment"
    >
      <div class="invoice-list-payment-plan-modal">
        <app-invoices-payment-plan-form
          [isCancelled]="invoiceView().isCancelled"
          [billingStatus]="invoiceView().billingStatus"
          [remainingAnnualPremium]="invoiceView().remainingAnnualPremium"
          [relevantPolicy]="invoiceView().relevantPolicy"
          [futurePolicy]="invoiceView().futurePolicy ? invoiceView().futurePolicy : null"
          [hasPlanConflict]="invoiceView().relevantPolicyHasMultiplePaymentPlans"
          [invoicePid]="route.snapshot.queryParams.invoicePid"
          [invoiceToken]="route.snapshot.queryParams.invoiceToken"
          [billedInvoices]="billedInvoices"
          (closePaymentPlanModal)="closePaymentPlanModalAndReload()"
          (openPayoffModal)="openPayoffModal()"
        >
        </app-invoices-payment-plan-form>
      </div>
    </app-modal>

    <app-modal
      [open]="showStripePaymentModal()"
      (handleModalClose)="closeStripePaymentModal()"
      modalClass="app-modal-content__payment"
    >
      <div class="invoice-payment-modal">
        <app-invoices-payment-form
          (formSubmit)="handlePaymentFormSubmit()"
          (closePaymentModal)="closeStripePaymentModal()"
          [amountToPay]="getAmountToPay()"
          [onlyShowAchOption]="invoiceView().includesHabPolicy"
          [paymentUpdateSuccessful]="paymentUpdateSuccessful"
          [isProcessing]="isModalProcessing"
          [isUpdateOnly]="false"
          [paymentSuccessMessage]="paymentSuccessMessage"
          [isUpdatingPayment]="showUpdatePaymentModal"
          [serverError]="serverError"
          [isInitialAutopay]="showAutopayEnrollModal"
          [currentAutopayIsCard]="invoiceView().hasAutopayCreditCard"
          [currentAutopayText]="invoiceView().currentAutopayText"
          [hasPaymentDue]="invoiceView().amountToPay > 0"
          [hasPastDueAndOutstanding]="
            invoiceView().overdueAmount && invoiceView().amountToPay !== invoiceView().overdueAmount
          "
          [includeCreditCardFee]="invoiceView().isCardProcessingFeeEligible"
          [creditCardFee]="getCreditCardFee()"
          [creditCardFeePercentage]="getCreditCardFeePercentage()"
        >
        </app-invoices-payment-form>
      </div>
    </app-modal>
  </ng-container>
</header>
<main class="invoice-list-main">
  <ng-container *ngIf="invoiceView().status === 'error'">
    <h1 class="h2 h2__invoice-list-header js-invoice-list-error">Invoice Error</h1>
    <p>{{ invoiceView().errorMessage }}</p>
  </ng-container>

  <div class="invoice-list-main-flow">
    <ng-container *ngIf="invoiceView().status === 'success'">
      <div
        *ngIf="
          invoiceView().banners.showRenewalBilled && !invoiceView().banners.showPriorityTopBanner
        "
        class="invoices-early-payoff-section"
      >
        <div
          class="
            nav-button-group__centered
            nav-button-group
            stripe-section-wrapper stripe-section-wrapper__margin
          "
        >
          <div class="invoice-info-card-section">
            <img class="invoice-info-icon" src="/assets/img/timeout_error.png" alt="" />
            <div>
              <h3>Your renewal invoice is ready</h3>
              <p class="gray-text info-text" *ngIf="invoiceView().hasAutopayEnabled">
                To ensure you have coverage next year, payment for your renewal invoice will be
                drafted on
                <strong>{{ formatDateLong(invoiceView().banners.renewalDraftDate) }}</strong> .
              </p>
              <p class="gray-text info-text" *ngIf="!invoiceView().hasAutopayEnabled">
                To ensure you have coverage next year, please make payment for your renewal invoice
                by <strong>{{ formatDateLong(invoiceView().banners.renewalDueDate) }}</strong> .
              </p>
            </div>
          </div>
          <a
            data-amplitude-input-name="invoice_list_invoice_direct_link"
            class="button button__small button__transparent button__no-margin"
            target="_blank"
            [routerLink]="getInvoiceUrlSegments(invoiceView().mostRecentInvoice)"
            [queryParams]="{ token: invoiceView().mostRecentInvoice.transactionId }"
          >
            View invoice
          </a>
        </div>
      </div>

      <div
        *ngIf="
          isEarlyPayoffEligible() &&
          !invoiceView().banners.showPriorityTopBanner &&
          !invoiceView().banners.showRenewalBilled
        "
        class="invoices-early-payoff-section"
      >
        <div
          class="
            nav-button-group__centered
            nav-button-group
            stripe-section-wrapper stripe-section-wrapper__margin
          "
        >
          <div class="invoice-info-card-section">
            <img class="invoice-info-icon" src="/assets/img/early_payoff.png" alt="" />
            <div>
              <h3>Early payoff</h3>
              <p class="gray-text info-text">
                If you would like to payoff the remaining balance of your account early, you can
                make a payment of {{ invoiceView().payoffAmount | currency }} now
              </p>
            </div>
          </div>
          <button
            id="payoffButton"
            class="button button__secondary"
            type="submit"
            (click)="openPayoffModal()"
          >
            Pay in full
          </button>
        </div>
      </div>

      <div *ngIf="invoiceView().banners.showRenewalOverdue" class="invoices-early-payoff-section">
        <div
          class="
            nav-button-group__centered
            nav-button-group
            stripe-section-wrapper stripe-section-wrapper__margin
          "
        >
          <div class="invoice-info-card-section">
            <img class="invoice-info-icon" src="/assets/img/overdue.png" alt="" />
            <div>
              <h3>Your renewal invoice is overdue</h3>
              <p class="gray-text info-text">
                To ensure you have coverage next year, make sure you pay your renewal invoice, which
                was due on
                <strong>{{ formatDateLong(invoiceView().banners.renewalDueDate) }}</strong
                >. or your policy will be canceled.
              </p>
            </div>
          </div>
          <a
            data-amplitude-input-name="invoice_list_invoice_direct_link"
            class="button button__small button__transparent button__no-margin"
            target="_blank"
          >
            View invoice
          </a>
        </div>
      </div>
    </ng-container>

    <section
      class="invoice-list-dialog-section print-hidden"
      *ngIf="madeCardPayment || madeAchPayment || isBannerShowing()"
    >
      <p
        class="dialog-text dialog-text__success dialog-text__block dialog-text__margin-top"
        *ngIf="madeCardPayment"
      >
        <span class="success-check icon-check"></span> Payment successful.
      </p>
      <p
        class="dialog-text dialog-text__neutral dialog-text__block dialog-text__margin-top"
        *ngIf="madeAchPayment"
      >
        Payment pending.
      </p>
      <ng-container *ngIf="isBannerShowing()">
        <div class="invoice-list-nonmobile-banner">
          <div *ngIf="invoiceView().banners.overdueInvoice">
            <p
              class="
                dialog-text dialog-text__warning dialog-text__full-width dialog-text__no-margin
                js-overdue-dialog
              "
            >
              You have one or more overdue invoice(s), if payment is not received by
              <strong>
                {{ formatDateLong(invoiceView().banners.gracePeriodAfterEarliestDueDate) }}</strong
              >, a notice of cancellation will be mailed.
            </p>
          </div>
          <div *ngIf="invoiceView().banners.otherCancelReasons">
            <p
              class="
                dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
                js-uw-cancel-outstanding-invoice-dialog
              "
            >
              Your policy is pending cancellation effective
              <strong>{{ formatDateLong(invoiceView().cancellationDate) }}</strong
              >. Once this happens, you will no longer have coverage. Please pay outstanding
              charges. To learn more, please contact us
              <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a>
            </p>
          </div>
          <div *ngIf="invoiceView().banners.nonpayCancelWithOutstanding">
            <p
              class="
                dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
                js-canceled-outstanding-invoice-dialog
              "
            >
              Your policy was canceled effective
              <strong> {{ formatDateLong(invoiceView().cancellationDate) }}</strong
              >. Please pay outstanding charges for coverage provided through the cancellation
              effective date. Making payment will not reinstate coverage. Click
              <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a> for
              information on how to request a review for reinstatement.
            </p>
          </div>
          <div *ngIf="invoiceView().banners.nonpayCancelWithoutOutstanding">
            <p
              class="
                dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
                js-nopay-no-outstanding-canceled-invoice
              "
            >
              Your policy was canceled effective
              <strong> {{ formatDateLong(invoiceView().cancellationDate) }}.</strong>
            </p>
          </div>
          <div *ngIf="invoiceView().banners.uwCancelWithOutstanding">
            <p
              class="
                dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
                js-uw-outstanding-canceled-dialog
              "
            >
              Your policy was canceled by the carrier effective
              <strong> {{ formatDateLong(invoiceView().cancellationDate) }}</strong
              >. Please pay outstanding charges for coverage provided through the cancellation
              effective date. Making payment will not reinstate coverage. To learn more, please
              contact your broker.
            </p>
          </div>
          <div *ngIf="invoiceView().banners.uwCancelWithoutOutstanding">
            <p
              class="
                dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
                js-uw-no-outstanding-canceled-dialog
              "
            >
              Your policy was canceled by the carrier effective
              <strong> {{ formatDateLong(invoiceView().cancellationDate) }}.</strong>
              To learn more, please contact your broker.
            </p>
          </div>

          <div *ngIf="invoiceView().banners.outstandingWithoutDelinquencies">
            <p
              class="
                dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
                js-outstanding-no-deliquent-dialog
              "
            >
              You are in good standing but have outstanding charges. We strongly recommend paying
              before becoming delinquent.
            </p>
          </div>
          <div *ngIf="invoiceView().banners.uwPendingCancel">
            <p
              class="
                dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
                js-uw-cancel-outstanding-invoice-dialog
              "
            >
              Your policy is pending cancellation by the carrier effective
              <strong>{{ formatDateLong(invoiceView().cancellationDate) }}</strong
              >. Once this happens, you will no longer have coverage. Please pay outstanding
              charges. To learn more, please contact us
              <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a>
            </p>
          </div>

          <div *ngIf="invoiceView().banners.nonpayPendingCancellation">
            <p
              class="
                dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
                js-uw-cancel-outstanding-invoice-dialog
              "
            >
              Your policy is pending cancellation. If you do not pay the minimum due before
              <strong>{{ formatDateLong(invoiceView().cancellationDate) }}, </strong>the policy will
              cancel. Once this happens, you will no longer have coverage.
            </p>
          </div>
        </div>
      </ng-container>
    </section>

    <h3>Billed Invoices</h3>
    <ng-container *ngIf="invoiceView().status === 'success'">
      <p *ngIf="!invoiceView().pendingReinstatementInvoice">
        Below is your payment history and outstanding invoices. This may include invoices from
        previous (no longer active) policies. Click "View Invoice" to learn more about each billed
        invoice.
      </p>
      <p *ngIf="invoiceView().pendingReinstatementInvoice">
        Please make payment for the invoice below to continue to be reviewed for reinstatement.
      </p>
    </ng-container>
    <table class="invoice-list-table">
      <thead>
        <tr>
          <th>Invoice Number</th>
          <th>Due date</th>
          <th>Status</th>
          <th>Amount</th>
          <th class="td__small"></th>
        </tr>
      </thead>

      <tbody class="tbody__striped-v2">
        <ng-container *ngIf="invoiceView().status === 'loading'">
          <tr *ngFor="let i of placeholderRange()" class="js-invoice-loading-list">
            <td>
              <div class="table-loading-cell"></div>
            </td>
            <td>
              <div class="table-loading-cell"></div>
            </td>
            <td>
              <div class="table-loading-cell"></div>
            </td>
            <td>
              <div class="table-loading-cell"></div>
            </td>
            <td>
              <div class="table-loading-cell"></div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="invoiceView().status !== 'loading' && billedInvoices">
          <tr *ngFor="let invoice of getVisibleInvoices()" class="js-invoice-row">
            <td data-column-name="Invoice Number">
              {{ invoice.invoiceNumber }}
            </td>
            <td data-column-name="Due">
              {{ formatDate(invoice.dueDate) }}
            </td>
            <td data-column-name="Status">
              <span class="pill-tag pill-tag__{{ invoiceStatusClass(invoice) }}">{{
                invoiceStatus(invoice)
              }}</span>
            </td>
            <td data-column-name="Amount">
              {{ invoice.amountDue || invoice.amountOriginallyBilled | currency }}
            </td>
            <td>
              <a
                data-amplitude-input-name="invoice_list_invoice_direct_link"
                class="button button__small button__transparent button__no-margin"
                target="_blank"
                [routerLink]="getInvoiceUrlSegments(invoice)"
                [queryParams]="{ token: invoice.transactionId }"
              >
                View invoice
              </a>
              <ng-container *ngIf="invoice.status.toLowerCase() === 'paid'">
                <span class="receipt-divider"> | </span>
                <div
                  *ngIf="!displayLoadingState(invoice.id)"
                  data-amplitude-input-name="invoice_list_receipt_pdf"
                  class="receipt-button button button__small button__transparent button__no-margin"
                  target="_blank"
                  (click)="downloadReceipt(invoice.id, invoice.transactionId)"
                  (keyup.enter)="downloadReceipt(invoice.id, invoice.transactionId)"
                >
                  Receipt
                </div>
                <div
                  *ngIf="displayLoadingState(invoice.id)"
                  class="receipt-button receipt-button__loading"
                >
                  <app-loading-spinner
                    class="document-download-icon receipt-loading-spinner"
                  ></app-loading-spinner>
                </div>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div
      class="policies-overview-page-selector"
      *ngIf="!hasPendingReinstatement() && getVisibleInvoices() && getVisibleInvoices().length > 0"
    >
      <a
        *ngIf="billingInvoicePager.currentPage > 1"
        (click)="setBillingPage(billingInvoicePager.currentPage - 1)"
        (keyup.enter)="setBillingPage(billingInvoicePager.currentPage - 1)"
        class="js-policies-renewal-previous"
        ><span class="icon-arrow-left button-icon"></span
      ></a>
      <span class="gray-text" *ngIf="billingInvoicePager.currentPage === 1"
        ><span class="icon-arrow-left button-icon"></span
      ></span>

      <span>
        {{ getPaginatedFirstItemNumber(billingInvoicePager.startIndex) }} -
        {{
          getPaginatedLastItemNumber(
            billingInvoicePager.startIndex,
            billingInvoicePager.totalItems,
            pagedBilledItems.length
          )
        }}
      </span>
      <span class="gray-text"> of </span>
      <span> {{ billingInvoicePager.totalItems }} </span>

      <a
        *ngIf="billingInvoicePager.currentPage < billingInvoicePager.totalPages"
        (click)="setBillingPage(billingInvoicePager.currentPage + 1)"
        (keyup.enter)="setBillingPage(billingInvoicePager.currentPage + 1)"
        class="js-policies-renewal-next"
        ><span class="icon-arrow-right button-icon"></span
      ></a>
      <span
        class="gray-text"
        *ngIf="billingInvoicePager.currentPage === billingInvoicePager.totalPages"
        ><span class="icon-arrow-right button-icon"></span
      ></span>
    </div>

    <ng-container *ngIf="invoiceView().status === 'success'">
      <div
        *ngIf="
          invoiceView().banners.showRenewalBilled && invoiceView().banners.showPriorityTopBanner
        "
        class="invoices-early-payoff-section"
      >
        <div
          class="
            nav-button-group__centered
            nav-button-group
            stripe-section-wrapper stripe-section-wrapper__margin
          "
        >
          <div class="invoice-info-card-section">
            <img class="invoice-info-icon" src="/assets/img/timeout_error.png" alt="" />
            <div>
              <h3>Your renewal invoice is ready</h3>
              <p class="gray-text info-text" *ngIf="invoiceView().hasAutopayEnabled">
                To ensure you have coverage next year, payment for your renewal invoice will be
                drafted on
                <strong>{{ formatDateLong(invoiceView().banners.renewalDraftDate) }}</strong> .
              </p>
              <p class="gray-text info-text" *ngIf="!invoiceView().hasAutopayEnabled">
                To ensure you have coverage next year, please make payment for your renewal invoice
                by <strong>{{ formatDateLong(invoiceView().banners.renewalDueDate) }}</strong> .
              </p>
            </div>
          </div>
          <a
            data-amplitude-input-name="invoice_list_invoice_direct_link"
            class="button button__small button__transparent button__no-margin"
            target="_blank"
            [routerLink]="getInvoiceUrlSegments(invoiceView().mostRecentInvoice)"
            [queryParams]="{ token: invoiceView().mostRecentInvoice.transactionId }"
          >
            View invoice
          </a>
        </div>
      </div>

      <div
        *ngIf="
          isEarlyPayoffEligible() &&
          (invoiceView().banners.showPriorityTopBanner || invoiceView().banners.showRenewalBilled)
        "
        class="invoices-early-payoff-section"
      >
        <div
          class="
            nav-button-group__centered
            nav-button-group
            stripe-section-wrapper stripe-section-wrapper__margin
          "
        >
          <div class="invoice-info-card-section">
            <img class="invoice-info-icon" src="/assets/img/early_payoff.png" alt="" />
            <div>
              <h3>Early payoff</h3>
              <p class="gray-text info-text">
                If you would like to payoff the remaining balance of your account early, you can
                make a payment of {{ invoiceView().payoffAmount | currency }} now
              </p>
            </div>
          </div>
          <button
            id="payoffButton"
            class="button button__secondary"
            type="submit"
            (click)="openPayoffModal()"
          >
            Pay in full
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!hasPendingReinstatement()">
      <h3 id="future-invoices">Future Invoices</h3>
      <p *ngIf="plannedInvoices && plannedInvoices.length === 0" class="gray-text">
        You have no future invoices in your current policy period.
      </p>
      <ng-container *ngIf="plannedInvoices && plannedInvoices.length > 0">
        <ng-container *ngIf="!isLoadingScheduleOfInvoices">
          <p>
            <a
              data-amplitude-input-name="document-download-schedule-invoice-insured-page"
              href="javascript:void(0)"
              (click)="downloadScheduleOfInvoices()"
              >Download Schedule of Invoices
            </a>
            for a full look at all of your invoices.
          </p>
        </ng-container>
        <ng-container *ngIf="isLoadingScheduleOfInvoices">
          <p>Downloading Schedule of Invoices</p>
        </ng-container>
        <table class="invoice-list-table">
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th *ngIf="!invoiceView().hasAutopayEnabled">Due date</th>
              <th *ngIf="invoiceView().hasAutopayEnabled">Draft date</th>
              <th>Status</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>

          <tbody class="tbody__striped-v2">
            <ng-container *ngIf="invoiceView().status === 'loading'">
              <tr *ngFor="let i of placeholderRange()">
                <td>
                  <div class="table-loading-cell"></div>
                </td>
                <td>
                  <div class="table-loading-cell"></div>
                </td>
                <td>
                  <div class="table-loading-cell"></div>
                </td>
                <td>
                  <div class="table-loading-cell"></div>
                </td>
                <td>
                  <div class="table-loading-cell"></div>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="plannedInvoices">
              <tr *ngFor="let invoice of pagedPlannedItems" class="js-invoice-row">
                <td data-column-name="Invoice Number">
                  {{ invoice.invoiceNumber }}
                </td>
                <td data-column-name="Due" *ngIf="!invoiceView().hasAutopayEnabled">
                  {{ formatDate(invoice.dueDate) }}
                </td>
                <td data-column-name="Due" *ngIf="invoiceView().hasAutopayEnabled">
                  {{ formatDate(getDraftDate(invoice.dueDate)) }}
                </td>
                <td data-column-name="Status">
                  <span class="pill-tag pill-tag__{{ invoiceStatusClass(invoice) }}">{{
                    invoiceStatus(invoice)
                  }}</span>
                </td>
                <td data-column-name="Amount">
                  {{ invoice.amountDue | currency }}
                </td>
                <td></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
      <div
        class="policies-overview-page-selector js-invoices-selector"
        *ngIf="pagedPlannedItems && pagedPlannedItems.length > 0"
      >
        <a
          *ngIf="pager.currentPage > 1"
          (click)="setPlannedPage(pager.currentPage - 1)"
          (keyup.enter)="setPlannedPage(pager.currentPage - 1)"
          class="js-policies-renewal-previous"
          ><span class="icon-arrow-left button-icon"></span
        ></a>
        <span class="gray-text" *ngIf="pager.currentPage === 1"
          ><span class="icon-arrow-left button-icon"></span
        ></span>

        <span>
          {{ getPaginatedFirstItemNumber(pager.startIndex) }} -
          {{
            getPaginatedLastItemNumber(pager.startIndex, pager.totalItems, pagedPlannedItems.length)
          }}
        </span>
        <span class="gray-text"> of </span>
        <span> {{ pager.totalItems }} </span>

        <a
          *ngIf="pager.currentPage < pager.totalPages"
          (click)="setPlannedPage(pager.currentPage + 1)"
          (keyup.enter)="setPlannedPage(pager.currentPage + 1)"
          class="js-policies-renewal-next"
          ><span class="icon-arrow-right button-icon"></span
        ></a>
        <span class="gray-text" *ngIf="pager.currentPage === pager.totalPages"
          ><span class="icon-arrow-right button-icon"></span
        ></span>
      </div>
    </ng-container>
    <div class="footer-container invoice-list-footer">
      <span>© Attune Insurance {{ currentYear }}</span>
      <div class="footer-links">
        <a id="footer-content-contact" class="footer-link" href="/{{ helpCenterPath }}"
          >Get in touch with us</a
        >
        <p class="footer-link">|</p>
        <a id="footer-content-phone" href="tel:+18885304650" class="footer-link">1-888-530-4650</a>
      </div>
    </div>
  </div>
</main>
