<header class="bind-quote-header">
  <h1>Payment Details</h1>
</header>

<main *ngIf="paymentIframeLoading" class="placeholder-form-container">
  <div class="placeholder-form-input"></div>
</main>

<ng-container *ngIf="!paymentIframeLoading">
  <div class="form-split">
    <h5 class="payment-subtitle">Payment Details</h5>
  </div>

  <iframe
    *ngIf="!!hiscoxChaseUrl"
    id="contentFrame"
    [ngStyle]="{ width: '800px', height: '520px' }"
    [src]="hiscoxChaseUrl"
    class="e2e-iframe-trusted-src"
    frameborder="0"
    width="600"
    height="600"
    allowfullscreen
  >
  </iframe>

  <ng-container>
    <h4 class="h4">Fraud Warning</h4>
    <div [ngSwitch]="state">
      <small *ngSwitchCase="'AR'">
        Any person who knowingly presents a false or fraudulent claim for payment of a loss or
        benefit, or knowingly presents false information in an application for insurance is guilty
        of a crime and may be subject to fines and confinement in prison.
      </small>
      <small *ngSwitchCase="'CO'">
        It is unlawful to knowingly provide false, incomplete, or misleading facts or information to
        an insurance company for the purpose of defrauding or attempting to defraud the company.
        Penalties may include imprisonment, fines, denial of insurance, and civil damages. Any
        insurance company or agent of an insurance company who knowingly provides false, incomplete,
        or misleading facts or information to a policyholder or claimant for the purpose of
        defrauding or attempting to defraud the policyholder or claimant with regard to a settlement
        or award payable from insurance proceeds shall be reported to the Colorado Division of
        Insurance within the Department of Regulatory Agencies.</small
      >
      <small *ngSwitchCase="'DC'">
        WARNING: It is a crime to provide false or misleading information to an insurer for the
        purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or
        fines. In addition, an insurer may deny insurance benefits if false information materially
        related to a claim was provided by the applicant.
      </small>
      <small *ngSwitchCase="'FL'">
        Any person who knowingly and with intent to injure, defraud or deceive any insurer files a
        statement of claim or an application containing any false, incomplete, or misleading
        information is guilty of a felony of the third degree.
      </small>
      <small *ngSwitchCase="'KS'">
        Any person who knowingly and with intent to defraud any insurance company or other person
        files an application for insurance or statement of claim containing any materially false
        information or conceals, for the purpose of misleading, information concerning any fact
        material thereto, commits a fraudulent act, which is a crime and may subject such person to
        criminal and civil penalties.
      </small>
      <small *ngSwitchCase="'KY'">
        NOTICE TO KENTUCKY APPLICANTS: ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY
        INSURANCE COMPANY OR OTHER PERSON FILES AN APPLICATION FOR INSURANCE CONTAINING ANY
        MATERIALLY FALSE INFORMATION, OR CONCEALS FOR THE PURPOSE OF MISLEADING, INFORMATION
        CONCERNING ANY FACT MATERIAL THERETO, COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A
        CRIME.</small
      >
      <small *ngSwitchCase="'LA'">
        Any person who knowingly presents a false or fraudulent claim for payment of a loss or
        benefit or knowingly presents false information in an application for insurance is guilty of
        a crime and may be subject to fines and confinement in prison.
      </small>
      <small *ngSwitchCase="'ME'">
        It is a crime to knowingly provide false, incomplete or misleading information to an
        insurance company for the purpose of defrauding the insurance company. Penalties include
        imprisonment, fines or a denial of insurance benefits.
      </small>
      <small *ngSwitchCase="'MD'">
        Any person who knowingly or willfully presents a false or fraudulent claim for payment of a
        loss or benefit or who knowingly or willfully presents false information in an application
        for insurance is guilty of a crime and may be subject to fines and confinement in prison.
      </small>
      <small *ngSwitchCase="'NJ'">
        Any person who includes any false or misleading information on an application for an
        insurance policy is subject to criminal and civil penalties.
      </small>
      <small *ngSwitchCase="'NM'">
        Any person who knowingly presents a false or fraudulent claim for payment of a loss or
        benefit, or knowingly presents false information in an application for insurance is guilty
        of a crime and may be subject to fines and confinement in prison.
      </small>
      <small *ngSwitchCase="'NY'">
        Any person who knowingly and with intent to defraud any insurance company or other person
        files an application for insurance or statement of claim containing any materially false
        information, or conceals for the purpose of misleading, information concerning any fact
        material thereto, commits a fraudulent insurance act, which is a crime, and shall also be
        subject to a civil penalty not to exceed five thousand dollars and the stated value of the
        claim for each such violation.
      </small>
      <small *ngSwitchCase="'OH'">
        Any person who, with intent to defraud or knowing that he or she is facilitating a fraud
        against an insurer, submits an application or files a claim
      </small>
      <small *ngSwitchCase="'OK'">
        WARNING: Any person who knowingly, and with the intent to injure, defraud or deceive any
        insurer, makes any claim for the proceeds of an insurance policy containing any false,
        incomplete or misleading information is guilty of a felony (365:15-1-10, 36 §3613.1)
      </small>
      <small *ngSwitchCase="'OR'">
        Any person who knowingly presents a false or fraudulent claim for payment of a loss or
        benefit or knowingly presents materially false information in an application for insurance
        may be guilty of a crime and may be subject to fines and confinement in prison.
      </small>
      <small *ngSwitchCase="'PA'">
        Any person who knowingly and with intent to defraud any insurance company or other person
        files an application for insurance or statement of claim containing any materially false
        information or conceals for the purpose of misleading, information concerning any fact
        material thereto commits a fraudulent insurance act, which is a crime and subjects such
        person to criminal and civil penalties.
      </small>
      <small *ngSwitchCase="'TN'">
        It is a crime to knowingly provide false, incomplete or misleading information to an
        insurance company for the purpose of defrauding the company. Penalties include imprisonment,
        fines and denial of insurance benefits
      </small>
      <small *ngSwitchCase="'VT'">
        Any person who knowingly and with intent to defraud any insurance company or other person
        files an application for insurance or statement of claim containing any materially false
        information or, conceals, for the purpose of misleading, information concerning any material
        fact thereto, may be guilty of a criminal offense and subject to penalties under state law.
      </small>
      <small *ngSwitchCase="'VA'">
        It is a crime to knowingly provide false, incomplete or misleading information to an
        insurance company for the purpose of defrauding the company. Penalties include imprisonment,
        fines and denial of insurance benefits.
      </small>
      <small *ngSwitchCase="'WA'">
        It is a crime to knowingly provide false, incomplete, or misleading information to an
        insurance company for the purpose of defrauding the company. Penalties include imprisonment,
        fines, and denial of insurance benefits.
      </small>
      <small *ngSwitchCase="'WV'">
        Any person who knowingly presents a false or fraudulent claim for payment of a loss or
        benefit or knowingly presents false information in an application for insurance is guilty of
        a crime and may be subject to fines and confinement in prison.
      </small>
      <small *ngSwitchDefault>
        It is a crime to knowingly and intentionally attempt to defraud an insurance company by
        providing false or misleading information or concealing material information during the
        application process or when filing a claim. Such conduct could result in your policy being
        voided and subject you to criminal and civil penalties.
      </small>
    </div>
  </ng-container>

  <ng-container *ngIf="showSCDisclaimer">
    <h4 class="h4">Policy Cancellation</h4>
    <small>
      25% of the premium is due today and may be non-refundable if the policy is cancelled. Any
      mention of our 14-day money back guarantee is not applicable for this policy.
    </small>
  </ng-container>
</ng-container>
