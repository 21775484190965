import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

import { InvoicesService } from 'app/features/invoices/services/invoices.service';

@Component({
  selector: 'app-invoices-payment-receipt-page',
  templateUrl: './invoices-payment-receipt-page.component.html',
})
export class InvoicesPaymentReceiptPageComponent implements OnInit, OnDestroy {
  private sub = new Subscription();

  paymentInvoiceDetails: PaymentInvoiceDetailsResponse | null;
  invoiceDetails: Invoice | null;

  constructor(private route: ActivatedRoute, private invoiceService: InvoicesService) {}

  ngOnInit() {
    if (this.route.snapshot.params.id && this.route.snapshot.queryParams.token) {
      const invoiceId = this.route.snapshot.params.id;
      const token = this.route.snapshot.queryParams.token;

      this.sub.add(
        forkJoin(
          this.invoiceService.getInvoice(invoiceId, token),
          this.invoiceService.getPaymentInvoiceDetails(invoiceId, token)
        ).subscribe(([invoiceDetails, paymentInvoiceDetails]) => {
          this.invoiceDetails = invoiceDetails;
          this.paymentInvoiceDetails = paymentInvoiceDetails;
        })
      );
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getInvoiceTotal(charges: PaymentInvoiceCharge[]) {
    return charges.reduce((acc, curr) => {
      return acc + Number(curr.invoiceItemAmountPaid);
    }, 0);
  }

  getInvoiceNumbers(paymentInvoiceDetails: PaymentInvoiceDetailsResponse) {
    return paymentInvoiceDetails.invoiceDetails.map((invoice) => invoice.invoiceNumber).join(', ');
  }

  getPaymentDate(paymentDateIsoString: string) {
    return moment.utc(paymentDateIsoString).format('MM/DD/YYYY');
  }

  getPaymentTime(paymentDateIsoString: string) {
    return moment.utc(paymentDateIsoString).format('h:mm A [UTC]');
  }

  formatPaymentAmount(amount: string) {
    return Number(amount);
  }

  getAutopayStatus(): string {
    return this.invoiceDetails && this.invoiceDetails.isStripeRecurring
      ? 'Enrolled'
      : 'Not enrolled';
  }
}
