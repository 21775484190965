<ng-container *ngIf="servicingInfo">
  <h1 *ngIf="showHeading">Working with {{ servicingInfo.displayName }}</h1>

  <p class="p p__large-text">
    Everything you need to know about servicing your {{ carrierTitle }} policy:.
  </p>
  <ul class="badge-list">
    <li *ngFor="let servicingItem of servicingInfo.servicingItems">
      <img class="badge-list-img" src="/assets/img/check.svg" alt="" />
      <p class="badge-list-text">
        <strong>{{ servicingItem.name }}</strong
        >: <span [innerHTML]="servicingItem.detail"></span>
      </p>
    </li>
  </ul>
</ng-container>
