import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { InformToast, InformLevel } from 'app/core/services/inform.service';

@Component({
  selector: 'app-inform-toast',
  templateUrl: './inform-toast.component.html',
})
export class InformToastComponent implements OnDestroy {
  @Input() toast: InformToast;
  @Output() destroy = new EventEmitter<InformToast>();
  InformLevel = InformLevel;
  private closeClicked = false;

  constructor() {}

  ngOnDestroy() {
    if (this.toast && this.toast.callback) {
      this.toast.callback(this.closeClicked);
    }
  }

  close() {
    this.closeClicked = true;
    this.destroy.emit(this.toast);
  }
}
