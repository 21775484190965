import * as _ from 'lodash';
import { ValidatorFn, Validators } from '@angular/forms';

import { PlConfigNode } from './pl-types';
import {
  FormDslNode,
  SearchQueryMethod,
  SearchFormatter,
  ComplexValidatorDictionary,
} from 'app/shared/form-dsl/constants/form-dsl-typings';
import {
  plAgreeDisagreeGeneralModal,
  generatePlV4CheckboxGroupNode,
  validateCheckboxGroupNode,
  plAgreeDisagreeNyV4,
  plAgreeDisagreeMoV4,
} from 'app/features/hiscox/models/hiscox-customization';
import { validateEmailAddress } from 'app/features/attune-bop/models/form-validators';
import {
  maxDateExceededValidator,
  minDateExceededValidator,
  phoneValidator,
  zipCodeValidator,
  rangeValidator,
  validatePercentLessThanOrEqualToOneHundred,
} from 'app/shared/helpers/form-helpers';
import { UsStateService } from 'app/shared/services/us-state.service';
import { getToday, getTodayUs } from 'app/shared/helpers/date-time-helpers';
import {
  getLatestEffectiveDateMoment,
  getOneHundredYearsAgo,
} from 'app/features/hiscox/models/date-time';
import {
  classOfBusinessExceptionsByStateValidator,
  validateAtLeastOneYesInsurancePlacementSrvsAnswer,
  validateEstSalesLargestCustmr1NotGreaterThanAnnualRevenue,
  validateEstSalesLargestCustmrNotGreaterThanAnnualRevenue,
  validateExclusiveCheckboxOption,
  validateLargestCustomer1ValueIsSmallerOrEqualToTotalRevenue,
  validateLargestCustomerValueIsSmallerOrEqualToTotalCommissions,
  validateLargestProjectValueIsSmallerOrEqualToTotalRevenue,
  validateNumericQuestionAggregates,
  validateProfesssionalLiabilityRetroactiveDateAgainstEffectiveDate,
  validateProfesssionalLiabilityRetroactiveDateAgainstBusinessStartDate,
  validateRequiredCheckboxOption,
  validateSubcontractProfSrvcsAtLeastOneSubQuestionSelectedYes,
  zipMatchesStateValidator,
} from 'app/features/hiscox/models/form-validators';
import {
  PL_BUSINESS_CLASSES_V4,
  EOSTATEMENT1,
  EOSTATEMENT2_V4,
  EOSTATEMENT3_V4_NY,
  HiscoxPlFormDataFieldV4,
  PL_ORDERED_FIELDS_V4,
  PL_V4_FIELDS_WITH_NONE_OF_THE_ABOVE_OPTION,
} from 'app/features/hiscox/models/pl-constants';
import {
  HISCOX_API_VERSION,
  CyberUpsellFormLogicControlName,
  HiscoxFormStepPath,
  cyberUpsellValidators,
  HISCOX_PRODUCTS,
} from './hiscox-types';
import { sortDictionaryByValues } from 'app/shared/helpers/helper-functions';
import { CoalitionCyberQuestion } from '../../coalition/models/cyber-typings.model';
import {
  COMPANY_EMPLOYEE_COUNT_OPTIONS,
  ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
} from '../../coalition/models/cyber-constants.model';
import { ALL_STATES_ABBR } from '../../../shared/States';
import { REMOVE_FROM_PAYLOAD } from './hiscox-constants';
import { HiscoxGlFormDataFieldV4 } from './gl-constants';

const usStateService = new UsStateService();
const usStates = usStateService.getUsStateAbbreviations();

export const plAll: FormDslNode[] = [
  {
    primitive: 'DATE',
    inputType: 'text',
    inputId: 'ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_ProfessionalExperience',
    nameOfFormControl:
      'ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_ProfessionalExperience',
    labelText: 'Approximately when did the business begin?',
    errorText: 'Business start date cannot be in the future',
    required: true,
  },
  {
    primitive: 'TEXT',
    inputType: 'text',
    cssClass: 'hx-hide',
    inputId: 'ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_EOCoverQuoteRq_CoverId',
    nameOfFormControl: 'ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_EOCoverQuoteRq_CoverId',
    required: true,
    labelText: "Cover Id, don't edit",
    readonly: true,
    value: 'EO',
  },
  {
    inputId: 'ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_EstmtdAnnualGrossSales',
    nameOfFormControl:
      'ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_EstmtdAnnualGrossSales',
    primitive: 'MONEY_WITHOUT_DECIMAL',
    labelText: "What is the business's estimated gross sales during the next 12 months?",
    // TODO
    // subLabelText: "If the business is primarily compensated by commissions (like a real estate agent or travel agent), estimate the total value of the commissions, not the total value of the sales which the commissions are based on. Your best estimate is fine.",
    required: true,
  },
];

// Note: these are questions that should appear for all PL quotes
export const plAllV4: FormDslNode[] = [
  {
    primitive: 'DATE',
    inputType: 'text',
    inputId: 'ProductQuoteRqs_ApplicationRatingInfo_ProfessionalExperience',
    nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_ProfessionalExperience',
    labelText: 'Approximately when did the business begin?',
    errorText: 'Business start date cannot be in the future',
    required: true,
  },
];

export const plConfigRaw = (): PlConfigNode[] => {
  return [
    plAgreeDisagreeGeneralModal(),
    plAgreeDisagreeMoV4,
    plAgreeDisagreeNyV4,
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_AcupressureBusnsActivity',
        labelText: 'Does the business perform any of the following? Check all that apply',
        checkboxConfigs: [
          {
            nameOfFormControl: 'ChiropracticServices',
            labelText: 'Chiropractic services',
          },
          {
            nameOfFormControl: 'Cupping',
            labelText: 'Cupping',
          },
          {
            nameOfFormControl: 'Direct_moxibustion',
            labelText: 'Direct moxibustion',
          },
          {
            nameOfFormControl: 'Homeopathy',
            labelText: 'Homeopathy',
          },
          {
            nameOfFormControl: 'Injections',
            labelText: 'Injections',
          },
          {
            nameOfFormControl: 'PerformAcupunctureForAnesthesia',
            labelText: 'Perform acupuncture for purposes of anesthesia',
          },
          {
            nameOfFormControl: 'PrescribeDispenseHerbsOrHerbalDrugsOtherThanMugwort',
            labelText: 'Prescribe or dispense herbs or herbal drugs other than mugwort',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseOverTheCounterOrPrescriptionDrugs',
            labelText: 'Prescribe or dispense over the counter or prescription drugs',
          },
          {
            nameOfFormControl: 'TattooServices',
            labelText: 'Tattoo Services',
          },
          {
            nameOfFormControl: 'UseOfNonDisposableNeedles',
            labelText: 'Use of non-disposable needles',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'ACC_PUN_PRES'
    ),
    {
      inputId: 'AcupressureServices',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'ACUPUNCTURE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business provide any acupressure services? ',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'AcupunctureServices',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'ACUPRESSURE2',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business provide any acupuncture services? ',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    // v4 version
    {
      inputId: 'AgreeDisagreeStatements',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'ALL',
      conditional: 'None',
      options: {
        'I have read and agree with these statements': 'Agree',
        'I have read and disagree with one or more of these statements': 'Disagree',
      },
      labelText: 'I have read and agreed to the important statements',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_AnEConstructionSrvcs',
        labelText:
          "Does the business or any of the business' subcontractors directly perform any of the following physical services? Check all that apply",
        checkboxConfigs: [
          {
            nameOfFormControl: 'Carpentry',
            labelText: 'Carpentry',
          },
          {
            nameOfFormControl: 'ConstructionDemolitionOrRemodeling',
            labelText: 'Construction, demolition, or remodeling',
          },
          {
            nameOfFormControl: 'Delivery',
            labelText: 'Delivery',
          },
          {
            nameOfFormControl: 'Electrical',
            labelText: 'Electrical',
          },
          {
            nameOfFormControl: 'EquipmentInstallationOrRepair',
            labelText: 'Equipment installation or repair',
          },
          {
            nameOfFormControl: 'Flooring',
            labelText: 'Flooring ',
          },
          {
            nameOfFormControl: 'GeneralContracting',
            labelText: 'General contracting',
          },
          {
            nameOfFormControl: 'HomeBuilding',
            labelText: 'Home building',
          },
          {
            nameOfFormControl: 'LawnOrGardenMaintenance',
            labelText: 'Lawn or garden maintenance ',
          },
          {
            nameOfFormControl: 'Painting',
            labelText: 'Painting',
          },
          {
            nameOfFormControl: 'Plumbing',
            labelText: 'Plumbing ',
          },
          {
            nameOfFormControl: 'Roofing',
            labelText: 'Roofing',
          },
          {
            nameOfFormControl: 'Tiling',
            labelText: 'Tiling ',
          },
          {
            nameOfFormControl: 'Transportation',
            labelText: 'Transportation',
          },
          {
            nameOfFormControl: 'AnyOtherPhysicalMaintenanceRepairServices',
            labelText: 'Any other physical maintenance/repair services? ',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'ARCHITECT_AND_ENGINEERING2'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          "Does the business or any of the business' subcontractors provide any of the following services or work on any projects involving the following materials or infrastructure? Check all that apply.",
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3,
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerospaceConsultingOrOrAdvice',
            labelText: 'Aerospace consulting or advice',
          },
          {
            nameOfFormControl: 'AmusementRidesPoolsOrPlaygrounds',
            labelText: 'Amusement rides, pools or playgrounds',
          },
          {
            nameOfFormControl: 'ArchitectureOrEngineeringAdvice',
            labelText: 'Architecture or engineering advice',
          },
          {
            nameOfFormControl: 'AsbestosLeadOrMoldEvaluationOrAbatement',
            labelText: 'Asbestos, lead, or mold evaluation or abatement',
          },
          {
            nameOfFormControl: 'BridgesDamsHarborsMinesPiersOrTunnels',
            labelText: 'Bridges, dams, harbors, mines, piers, or tunnels',
          },
          {
            nameOfFormControl: 'ConstructionManagementOrAdvice',
            labelText: 'Construction management or advice',
          },
          {
            nameOfFormControl:
              'ConstructionMaintenanceRepairRenovationorPropertyPreservationServices',
            labelText:
              'Construction, maintenance, repair, renovation, or property preservation services',
          },
          {
            nameOfFormControl: 'DesignOrBuild',
            labelText: 'Design/build',
          },
          {
            nameOfFormControl: 'EmergencyResponseOrCleanupOfAnyHazardousWaste',
            labelText: 'Emergency response or clean-up of any hazardous waste',
          },
          {
            nameOfFormControl: 'EnvironmentalConsultingOrAdvice',
            labelText: 'Environmental consulting or advice',
          },
          {
            nameOfFormControl: 'FinancingOrFinancialAuditing',
            labelText: 'Financing or Financial auditing',
          },
          {
            nameOfFormControl: 'FoundationSheetingOrRetainingWallDesign',
            labelText: 'Foundation, sheeting or retaining wall design',
          },
          {
            nameOfFormControl: 'GeneralContracting',
            labelText: 'General contracting',
          },
          {
            nameOfFormControl: 'HydraulicFracturingHydrofracturingOrFracking',
            labelText: 'Hydraulic fracturing, hydrofracturing, or fracking',
          },
          {
            nameOfFormControl: 'RealEstateInspections',
            labelText: 'Inspections as part of a real estate transaction',
          },
          {
            nameOfFormControl: 'InsurancePlacementOrAdvice',
            labelText: 'Insurance placement or advice',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'LaboratoryTesting',
            labelText: 'Laboratory testing',
          },
          {
            nameOfFormControl: 'LandAcquisitionAdvice',
            labelText: 'Land acquisition advice',
          },
          {
            nameOfFormControl: 'LandSurveyingBoundarySurveyingOrConstructionStaking',
            labelText: 'Land surveying, boundary surveying, or construction staking',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'MiningConsultingOrAdvice',
            labelText: 'Mining consulting  or advice',
          },
          {
            nameOfFormControl: 'OilGasOrPetroleumConsultingOrAdvice',
            labelText: 'Oil, gas, or petroleum consulting or advice',
          },
          {
            nameOfFormControl: 'RealEstateAppraisals',
            labelText: 'Real estate appraisals',
          },
          {
            nameOfFormControl: 'UndergroundStorageTanksOrUtilitiesOrAnyOtherSubsurfaceCondition',
            labelText:
              'Underground storage tanks or utilities, and/or any other subsurface condition',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'FORBIDDEN_PROJECTS3'
    ),
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1,
        labelText:
          'Does the business or any of your subcontractors provide any of the following services or work on any projects involving the following materials or infrastructure? Check all that apply',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerospaceEngineering',
            labelText: 'Aerospace engineering',
          },
          {
            nameOfFormControl: 'AmusementRidesPoolsOrPlaygrounds',
            labelText: 'Amusement rides, pools or playgrounds',
          },
          {
            nameOfFormControl: 'AsbestosLeadMoldEvaluationOrAbatement',
            labelText: 'Asbestos, lead, or mold evaluation or abatement',
          },
          {
            nameOfFormControl: 'BridgesDamsHarborsMinesPiersOrTunnels',
            labelText: 'Bridges, dams, harbors, mines, piers or tunnels',
          },
          {
            nameOfFormControl: 'BuildingEnvelopeInspections',
            labelText: 'Building Envelope Inspections',
          },
          {
            nameOfFormControl: 'Condominiums',
            labelText: 'Condominiums',
          },
          {
            nameOfFormControl: 'DesignBuildFirms',
            labelText: 'Design/build firms',
          },
          {
            nameOfFormControl: 'EmergencyResponseOrHazardousWasteCleanup',
            labelText: 'Emergency response or clean-up of any hazardous waste',
          },
          {
            nameOfFormControl: 'FoundationSheetingOrRetainingWallDesign',
            labelText: 'Foundation, sheeting or retaining wall design',
          },
          {
            nameOfFormControl: 'GeotechnicalSoilsEngineer',
            labelText: 'Geotechnical / soils engineering',
          },
          {
            nameOfFormControl: 'HomeInspectionsForBuyersOrLendersOrInRealEstateTransaction',
            labelText:
              'Home inspections for prospective buyers or lenders, or to be used in a real estate transaction',
          },
          {
            nameOfFormControl: 'HydraulicFrackingHydrofracturingOrFracking',
            labelText: 'Hydraulic fracturing, hydrofracturing, or fracking',
          },
          {
            nameOfFormControl: 'LaboratoryTesting',
            labelText: 'Laboratory testing',
          },
          {
            nameOfFormControl: 'LandAcquisition',
            labelText: 'Land acquisition',
          },
          {
            nameOfFormControl: 'LandSurveyingBoundarySurveyingOrConstructionStaking',
            labelText: 'Land surveying, boundary surveying or construction staking',
          },
          {
            nameOfFormControl: 'MarineEngineering',
            labelText: 'Marine engineering',
          },
          {
            nameOfFormControl: 'MechanicalEngineering',
            labelText: 'Mechanical engineering',
          },
          {
            nameOfFormControl: 'MiningEngineering',
            labelText: 'Mining engineering',
          },
          {
            nameOfFormControl: 'NuclearEngineering',
            labelText: 'Nuclear engineering',
          },
          {
            nameOfFormControl: 'OilGasOrWellEngineering',
            labelText: 'Oil, gas or well engineering',
          },
          {
            nameOfFormControl: 'PetroChemicalEngineering',
            labelText: 'Petro / chemical engineering',
          },
          {
            nameOfFormControl: 'StrcturalEngineering',
            labelText: 'Structural engineering',
          },
          {
            nameOfFormControl: 'UndergroundStorageTanksOrUtilities',
            labelText: 'Underground storage tanks or utilities',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'ANE_FORBIDDENPROJECTS1'
    ),
    {
      inputId: 'AnnualSupplementRevenue1',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'DIET_EXEC',
      conditional: 'None',
      options: {
        'I understand and agree the above is excluded by this policy':
          'I understand and agree the above is excluded by this policy',
        'I disagree with the above being excluded by this policy':
          'I disagree with the above being excluded by this policy',
      },
      labelText: 'Selling dietary supplements is excluded and NOT covered by this policy.',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'ALL',
      conditionalChildren: [
        {
          inputId: 'ApplicantAuthorized',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'ALL',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            'As the individual completing this transaction, you are authorized to purchase and bind this insurance on behalf of the entity applying for coverage. ',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'ALL',
      conditionalChildren: [
        {
          inputId: 'ApplicantAuthorizedNonAcceptReason',
          primitive: 'TEXT',
          inputType: 'text',
          cobOnly: 'ALL',
          options: {},
          labelText: 'Please explain below why you are unable to agree to this statement',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business conduct any of the following activities? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_BusnActyLifeExeCoch',
        checkboxConfigs: [
          {
            nameOfFormControl: 'FamilyMarriageCounseling',
            labelText: 'Family/marriage counseling',
          },
          {
            nameOfFormControl: 'MedicalCounselingInPatientOrOutPatient',
            labelText: 'Medical counseling (in-patient or out-patient)',
          },
          {
            nameOfFormControl: 'MentalHealthCounseling',
            labelText: 'Mental health counseling',
          },
          {
            nameOfFormControl: 'SocialWork',
            labelText: 'Social work',
          },
          {
            nameOfFormControl: 'SubstanceAbuseCounseling',
            labelText: 'Substance abuse counseling',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'COACH'
    ),
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      cobOnly: 'PROCESS_SERVER',
      versions: [HISCOX_API_VERSION.v4],
      conditionalChildren: [
        {
          inputId: 'CertifiedInGeorgia',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'PROCESS_SERVER',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            'The business or owner is currently and properly certified by an order of a court of competent and appropriate jurisdiction of the State of Georgia.',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      cobOnly: 'PROCESS_SERVER',
      versions: [HISCOX_API_VERSION.v4],
      conditionalChildren: [
        {
          inputId: 'CertifiedInGeorgiaNonAcceptReason',
          primitive: 'TEXT',
          inputType: 'text',
          cobOnly: 'PROCESS_SERVER',
          options: {},
          labelText: 'Please explain below why you are unable to agree to this statement',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      cobOnly: 'PROCESS_SERVER',
      versions: [HISCOX_API_VERSION.v4],
      conditionalChildren: [
        {
          inputId: 'CertifiedInRhodeIsland',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'PROCESS_SERVER',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            'The business or owner is properly and currently certified by court an order of a court of competent and appropriate jurisdiction of the State of Rhode Island.',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      cobOnly: 'ALL',
      versions: [HISCOX_API_VERSION.v4],
      conditionalChildren: [
        {
          inputId: 'CertifiedInRhodeIslandNonAcceptReason',
          primitive: 'TEXT',
          inputType: 'text',
          cobOnly: 'ALL',
          options: {},
          labelText:
            'You or your business are properly and currently certified by court an order of a court of competent and appropriate jurisdiction of the State of Rhode Island.',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO__PRFORBIDDENPRODUCTS,
        labelText:
          "Do any of the business's clients produce, manufacture, or distribute any of the following?",
        checkboxConfigs: [
          {
            nameOfFormControl: 'AdultContent',
            labelText: 'Adult content',
          },
          {
            nameOfFormControl: 'Cannabis',
            labelText: 'Cannabis ',
          },
          {
            nameOfFormControl: 'Firearms',
            labelText: 'Firearms',
          },
          {
            nameOfFormControl: 'Gambling',
            labelText: 'Gambling',
          },
          {
            nameOfFormControl: 'PharmaceuticalsNutraceuticals',
            labelText: 'Pharmaceuticals/ nutraceuticals ',
          },
          {
            nameOfFormControl: 'PoliticalContent',
            labelText: 'Political content',
          },
          {
            nameOfFormControl: 'TobaccoECigarettes',
            labelText: 'Tobacco/ e-cigarettes',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PUBLIC_RELATIONS'
    ),
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_CLNTBUSNSPROMOTION,
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'CLNTBUSNSPROMOTION',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business promote media, artists or entertainers on behalf of or for any entertainment, publishing, music, internet or media company?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ComplyDoNotCallLaw',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'ANS_MARKETING',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business comply with applicable federal, state, and local laws and regulations relating to telemarketing and telesales including "Do Not Call" laws and regulations?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ConsultantEligibilityPL',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerospaceConsultingOrAdvice',
            labelText: 'Aerospace consulting or advice',
          },
          {
            nameOfFormControl: 'ActuarialAdvice',
            labelText: 'Actuarial advice',
          },
          {
            nameOfFormControl: 'ArchitectureOrEngineeringAdvice',
            labelText: 'Architecture or engineering advice',
          },
          {
            nameOfFormControl: 'ConstructionManagementOrAdvice',
            labelText: 'Construction management or advice',
          },
          {
            nameOfFormControl: 'CreditCounseling',
            labelText: 'Credit counseling',
          },
          {
            nameOfFormControl: 'EnvironmentalConsultingOrAdvice',
            labelText: 'Environmental consulting or advice',
          },
          {
            nameOfFormControl: 'FinancingOrFinancialAuditing',
            labelText: 'Financing or financial auditing',
          },
          {
            nameOfFormControl: 'GeneralContracting',
            labelText: 'General contracting',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'InsurancePlacementOrAdvice',
            labelText: 'Insurance placement or advice',
          },
          {
            nameOfFormControl: 'LandAcquisition',
            labelText: 'Land acquisition',
          },
          {
            nameOfFormControl: 'LawEnforcementTraining',
            labelText: 'Law enforcement training',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'LobbyingAndOrPoliticalAdvice',
            labelText: 'Lobbying and/or political advice',
          },
          {
            nameOfFormControl: 'MedicalAdvice',
            labelText: 'Medical advice',
          },
          {
            nameOfFormControl: 'MergersOrAcquisitionsOrBusinessValuations',
            labelText: 'Mergers or acquisitions or business valuations',
          },
          {
            nameOfFormControl: 'MiningConsultingOrAdvice',
            labelText: 'Mining consulting or advice',
          },
          {
            nameOfFormControl: 'OilGasOrPetroleumConsultingOrAdvice',
            labelText: 'Oil, gas, or petroleum consulting or advice',
          },
          {
            nameOfFormControl: 'SafetyConsultingOrAdvice',
            labelText: 'Safety consulting or advice',
          },
          {
            nameOfFormControl: 'SalesRepresentative',
            labelText: 'Sales representative',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'CONSULTING'
    ),
    {
      inputId: 'CPRServices',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'AID_CPR',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business perform any services as an EMT, Paramedic or Lifeguard?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business provide documents that support or provide any of the following services? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_DocPrepEligibility',
        checkboxConfigs: [
          {
            nameOfFormControl: 'Appraisals',
            labelText: 'Appraisals',
          },
          {
            nameOfFormControl: 'BackgroundChecks',
            labelText: 'Background checks',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'MedicalAdvice',
            labelText: 'Medical advice',
          },
          {
            nameOfFormControl: 'MergersAndAcquisitionsOrBusinessValuations',
            labelText: 'Mergers and acquisitions or business valuations',
          },
          {
            nameOfFormControl: 'PrivateInvestigations',
            labelText: 'Private investigations',
          },
          {
            nameOfFormControl: 'TitleAbstracting',
            labelText: 'Title abstracting',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'DOC_RESUME_SERVICES'
    ),
    {
      inputId: 'EducationalLicenseExcluded',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'EXEC',
      conditional: 'None',
      options: {
        'I understand and agree the above is excluded by this policy':
          'I understand and agree the above is excluded by this policy',
        'I disagree with the above being excluded by this policy':
          'I disagree with the above being excluded by this policy',
      },
      labelText:
        'Providing educational instruction to students seeking a license in this profession is excluded and NOT covered by this policy.',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR,
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputType: 'text',
      cobOnly: 'MANUFACTURER_SALES_REPRESENTATIVE',
      conditional: 'None',
      options: {},
      labelText:
        'During the next 12 months, what are the estimated gross sales the business will earn from its largest customer?  ',
      required: true,
      errorText: 'Enter value greater than $0 and less than $1,000,000,000.',
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        'Do the manufacturers the business works for have branches or operations located in the United States?',
      conditional: 'None',
      primitive: 'RADIO',
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_MSRBranchesOPS',
      cobOnly: 'MANUFACTURER_SALES_REPRESENTATIVE',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR1,
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputType: 'text',
      cobOnly: 'LARGEST_CUSTOMER',
      conditional: 'None',
      options: {},
      labelText:
        'During the next 12 months, what are the expected gross sales the business will earn from its largest customer?',
      required: true,
      errorText: 'Enter value greater than $0 and less than $1,000,000,000.',
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTPROJECT,
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputType: 'text',
      cobOnly: 'LARGEST_PROJECT',
      conditional: 'None',
      options: {},
      labelText:
        'During the next 12 months, what is the expected gross revenue the business will earn from their largest project?',
      required: true,
      errorText: 'Enter value greater than $0 and less than $1,000,000,000.',
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business provide any of the following services or activities? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS,
        checkboxConfigs: [
          {
            nameOfFormControl: 'EquipmentRentals',
            labelText: 'Equipment rentals',
          },
          {
            nameOfFormControl: 'OnlyHostingOrConductingEventsForYourOwnBusiness',
            labelText: 'Only host or conduct events for your own business',
          },
          {
            nameOfFormControl: 'StaffingOrCateringServices',
            labelText: 'Staffing or catering services',
          },
          {
            nameOfFormControl: 'TransportationServices',
            labelText: 'Transportation services',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'EVENT_PLANNING'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_BusMgrSrvs',
        checkboxConfigs: [
          {
            nameOfFormControl: 'ActuarialAdvice',
            labelText: 'Actuarial advice',
          },
          {
            nameOfFormControl: 'ConstructionManagementOrArchitecture',
            labelText: 'Construction management or architecture',
          },
          {
            nameOfFormControl: 'ConstructionOrRemodeling',
            labelText: 'Construction or remodeling',
          },
          {
            nameOfFormControl: 'CreditCounseling',
            labelText: 'Credit counseling',
          },
          {
            nameOfFormControl: 'Engineering',
            labelText: 'Engineering',
          },
          {
            nameOfFormControl: 'FinancingOrFinancialAuditing',
            labelText: 'Financing or financial auditing',
          },
          {
            nameOfFormControl: 'GeneralContracting',
            labelText: 'General contracting',
          },
          {
            nameOfFormControl: 'Healthcare',
            labelText: 'Healthcare',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'InsurancePlacementOrAdvice',
            labelText: 'Insurance placement or advice',
          },
          {
            nameOfFormControl: 'JanitorialOrCleaning',
            labelText: 'Janitorial or cleaning',
          },
          {
            nameOfFormControl: 'LandscapingOrLawnCare',
            labelText: 'Landscaping or lawn care',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'LobbyingAndOrPoliticalAdvice',
            labelText: 'Lobbying and/or political advice',
          },
          {
            nameOfFormControl: 'Manufacturing',
            labelText: 'Manufacturing',
          },
          {
            nameOfFormControl: 'MedicalAdvice',
            labelText: 'Medical Advice',
          },
          {
            nameOfFormControl: 'MergersAndAcquisitionsOrBusinessValuations',
            labelText: 'Mergers and acquisitions or business valuations',
          },
          {
            nameOfFormControl: 'PhysicalRepairOrInstallation',
            labelText: 'Physical repair or installation',
          },
          {
            nameOfFormControl: 'SecurityGuard',
            labelText: 'Security guard',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'BUSINESS_MGR_SERVICES'
    ),
    {
      inputId: 'AccountingChange',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'FINANCIAL_AUDITS',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business provide accounting related services such as certified public accounting, audits, or attestation?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_FinancialServices',
        labelText:
          'Does the business manage or participate in any of the following services? Check all that apply',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AuditingForFinancialInstitutionsOrPensions',
            labelText: 'Auditing for financial institutions or pensions',
          },
          {
            nameOfFormControl: 'FinancialPlanningAsARegisteredFinancialAdvisor',
            labelText: 'Financial planning as a registered financial advisor',
          },
          {
            nameOfFormControl: 'TaxShelters',
            labelText: 'Tax shelters',
          },
          {
            nameOfFormControl: 'InvestmentAdvice',
            labelText: 'Investment advice',
          },
          {
            nameOfFormControl: 'MergersAndAcquisitions',
            labelText: 'Mergers & acquisitions',
          },
          {
            nameOfFormControl: 'PublicAuditsSECAuditsHighNetWorthAuditing10MillionPlusClients',
            labelText: 'Public audits, SEC audits, high net worth auditing ($10M+ clients)',
          },
          {
            nameOfFormControl: 'TrusteeServices',
            labelText: 'Trustee services',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'ACCOUNTING'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business perform or assist in any of the following services or treatments? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_HomeHealthForbiddenSrvcs',
        checkboxConfigs: [
          {
            nameOfFormControl: 'Catheterization',
            labelText: 'Catheterization (other than urinary or umbilical)',
          },
          {
            nameOfFormControl: 'DiagnosingConditionsDisordersOrDiseases',
            labelText: 'Diagnosing conditions, disorders, or diseases',
          },
          {
            nameOfFormControl: 'HealthcareStaffingOrHomeHealthStaffingAgencyServices',
            labelText: 'Healthcare staffing or home health staffing agency services',
          },
          {
            nameOfFormControl: 'HolisticMedicine',
            labelText: 'Holistic medicine',
          },
          {
            nameOfFormControl: 'InfusionTherapy',
            labelText: 'Infusion therapy',
          },
          {
            nameOfFormControl: 'Injections',
            labelText: 'Injections of any kind',
          },
          {
            nameOfFormControl: 'LaserTreatments',
            labelText: 'Laser treatments',
          },
          {
            nameOfFormControl: 'ServicesRelatedCannabisNotLimitedToMedicalMarijuanaDispensing',
            labelText:
              'Services related to Cannabis, including but not limited to medical marijuana dispensing',
          },
          {
            nameOfFormControl: 'OrganSpermBloodServices',
            labelText: 'Organ/sperm/blood services',
          },
          {
            nameOfFormControl: 'PatientClientCareFacilityAnyTypeYouOwnOrRent',
            labelText:
              'Patient or client care at a hospital, nursing home, correctional institution, hospice facility, assisted living facility, independent living facility, or a facility or residence that you own or rent',
          },
          {
            nameOfFormControl: 'PatientTransportation',
            labelText:
              'Patient transportation, if these services represent more than 10% of your professional services',
          },
          {
            nameOfFormControl: 'PediatricOrDevelopmentlyDisabledClientCare',
            labelText: 'Pediatric or developmentally disabled client care',
          },
          {
            nameOfFormControl: 'PhysicalTherapy',
            labelText: 'Physical therapy',
          },
          {
            nameOfFormControl: 'ResearchOrExperimentalServices',
            labelText: 'Research or experimental services',
          },
          {
            nameOfFormControl: 'SelfDirectedCareFamilyCaregiverNursingWaiverProgram',
            labelText: 'Self-directed care; family caregiver, or nursing waiver programs',
          },
          {
            nameOfFormControl: 'StressTestServices',
            labelText: 'Stress test services',
          },
          {
            nameOfFormControl: 'WoundCare',
            labelText: 'Wound care',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'HOME_PERSONAL_AID'
    ),
    {
      primitive: 'DIV',
      cssClass: 'question-group',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'INS_AGENT',
      children: [
        {
          primitive: 'H2',
          cobOnly: 'INS_AGENT',
          text: 'What type of insurance placement services does the business provide?',
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId: 'InsurancePlacementSrvs_LifeAccidentorHealth',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'INS_AGENT',
          conditional: 'None',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText: 'Life, Accident, or Health',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId: 'InsurancePlacementSrvs_CommercialLines',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'INS_AGENT',
          conditional: 'None',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText: 'Commercial lines',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId: 'InsurancePlacementSrvs_PersonalLines',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'INS_AGENT',
          conditional: 'None',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText: 'Personal lines',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId: 'InsurancePlacementSrvs_Other',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'INS_AGENT',
          conditional: 'None',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText: 'Other',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'InsurancePlacementSrvs_LifeAccidentorHealth',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'INS_AGENT',
      conditionalChildren: [
        {
          inputId: 'InsurancePlacementSrvsLife',
          primitive: 'NUMBER',
          cobOnly: 'INS_AGENT',
          options: {},
          labelText:
            'Please enter percentage of annual premium for Life, Accident, or Health line of business',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'InsurancePlacementSrvs_CommercialLines',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'INS_AGENT',
      conditionalChildren: [
        {
          inputId: 'InsurancePlacementSrvsCL',
          primitive: 'NUMBER',
          cobOnly: 'INS_AGENT',
          options: {},
          labelText: 'Please enter percentage of annual premium for Commercial Lines of business',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'InsurancePlacementSrvs_PersonalLines',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'INS_AGENT',
      conditionalChildren: [
        {
          inputId: 'InsurancePlacementSrvsPL',
          primitive: 'NUMBER',
          cobOnly: 'INS_AGENT',
          options: {},
          labelText: 'Please enter percentage of annual premium for Personal Lines of business',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'InsurancePlacementSrvs_Other',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'INS_AGENT',
      conditionalChildren: [
        {
          inputId: 'InsurancePlacementSrvsOther',
          primitive: 'NUMBER',
          cobOnly: 'INS_AGENT',
          options: {},
          labelText: 'Please enter percentage of annual premium for Other lines of business',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business provide insurance placement or advice for any of the following insurance products or services?',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_InsuranceAgentAdvice',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AviationInsurance',
            labelText: 'Aviation insurance',
          },
          {
            nameOfFormControl: 'CropInsurance',
            labelText: 'Crop insurance',
          },
          {
            nameOfFormControl: 'LawyersLiabilityInsurance',
            labelText: 'Lawyers liability insurance',
          },
          {
            nameOfFormControl: 'HealthMaintenanceOrganizationPlans',
            labelText: 'Health Maintenance Organization (HMO) plans',
          },
          {
            nameOfFormControl: 'LongHaulTruckingInsurance',
            labelText: 'Long-haul  trucking insurance',
          },
          {
            nameOfFormControl: 'MiningInsurance',
            labelText: 'Mining insurance',
          },
          {
            nameOfFormControl: 'MultipleEmployerWelfareArrangements',
            labelText: 'Multiple Employer Welfare Arrangements (MEWA)',
          },
          {
            nameOfFormControl: 'OceanWetMarineInsurance',
            labelText: 'Ocean/wet marine insurance',
          },
          {
            nameOfFormControl: 'VariableAnnuitiesMutualFundsStocksorInvestmentBonds',
            labelText: 'Variable annuities, mutual funds, stocks, or investment bonds',
          },
          {
            nameOfFormControl: 'VariableLifeInsurance',
            labelText: 'Variable life insurance',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
        required: true,
      },
      [HISCOX_API_VERSION.v4],
      'INS_AGENT'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business provide services in the capacity of the following? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_InsuranceAgentCapacities',
        checkboxConfigs: [
          {
            nameOfFormControl: 'HealthMaintenanceOrganizationPlanCreatorManagerOrAdministrator',
            labelText:
              'Health Maintenance Organization (HMO) plan creator, manager and/or administrator',
          },
          {
            nameOfFormControl: 'ManagingGeneralAgent',
            labelText: 'Managing General Agent (MGA);',
          },
          {
            nameOfFormControl: 'ManagingGeneralUnderwriter',
            labelText: 'Managing General Underwriter (MGU);',
          },
          {
            nameOfFormControl: 'ProfessionalEmployerOrganization',
            labelText: 'Professional Employer Organization (PEO);',
          },
          {
            nameOfFormControl: 'ProgramAdministratorOrThirdPartyAdministrator',
            labelText: 'Program administrator or Third Party Administrator (TPA);',
          },
          {
            nameOfFormControl: 'ReinsuranceIntermediary',
            labelText: 'Reinsurance intermediary;',
          },
          {
            nameOfFormControl: 'RiskRetentionGroup',
            labelText: 'Risk Retention Group (RRG); or',
          },
          {
            nameOfFormControl: 'WholesaleBroker',
            labelText: 'Wholesale broker',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'INS_AGENT'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_InsuranceAgentServices',
        checkboxConfigs: [
          {
            nameOfFormControl: 'ActuarialAdvice',
            labelText: 'Actuarial advice',
          },
          {
            nameOfFormControl: 'FinancingOrFinancialAuditing',
            labelText: 'Financing or financial auditing',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'LobbyingOrPoliticalAdvice',
            labelText: 'Lobbying and/or political advice',
          },
          {
            nameOfFormControl: 'MedicalAdvice',
            labelText: 'Medical advice',
          },
          {
            nameOfFormControl: 'MergersAndAcquisitionsOrBusinessValuations',
            labelText: 'Mergers and acquisitions or business valuations',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'INS_AGENT'
    ),
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ITSPCLSTACTVTY,
        labelText: 'Does the business conduct any of the following? Check all that apply',
        checkboxConfigs: [
          {
            nameOfFormControl: 'ManageClientsNetworkInfastructureOnTheirBehalf',
            labelText: "Manage their client's network/ infrastructure on their behalf",
          },
          {
            nameOfFormControl: 'OngoingRemoteMonitorAccessClientSystems',
            labelText: 'Ongoing remote monitoring or access to client systems',
          },
          {
            nameOfFormControl:
              'CreateSupportOrWorkOnSOftwareForSecuritiesTransationsMakesMedicalDiagnosesOrManufacturingOrProcessControl',
            labelText:
              'Create, support, or work on software that executes securities transactions, makes medical diagnoses, or is involved in manufacturing or process control',
          },
          {
            nameOfFormControl: 'DomainNameRegistration',
            labelText: 'Domain Name Registration (DNS)',
          },
          {
            nameOfFormControl: 'HardwareDesignOrManufacturing',
            labelText:
              'Hardware design or manufacturing (Does not apply to Value Added Resellers of third-party hardware,)',
          },
          {
            nameOfFormControl: 'HardwareDesignOrManufacturing',
            labelText: 'Internet content aggregator or web portal',
          },
          {
            nameOfFormControl: 'InternetOnlinePublisherOfContent',
            labelText: 'Internet or on-line publisher of content',
          },
          {
            nameOfFormControl: 'OwnHostOrRunPornographicWebsite',
            labelText: 'Own, host, or run any website that contains pornographic material,',
          },
          {
            nameOfFormControl: 'OwnManageASocialNetworkingUserGeneratedContentOrAuctionWebsite',
            labelText:
              'Own or manage a social networking, user generated content, or auction website,  (Does not apply to hosting services for third-party sites)',
          },
          {
            nameOfFormControl: 'OperateAsAVOIPServiceProvider',
            labelText:
              'Operate as a Voice Over IP (VOIP) Service Provider (Does not apply to use of a third-party VOIP service for conducting your own business)',
          },
          {
            nameOfFormControl: 'PaymentProcessing',
            labelText: 'Payment processing',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'TECH_ALL'
    ),
    {
      inputId: 'LicensedDoctorNurse',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'LICENSED_DOCTOR_NURSE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        "Is the owner of the business an active or retired Medical Doctor, Registered Nurse, Nurse Practitioner, Licensed Practical Nurse, Physicia's Assistant or Naturopathic Doctor?",
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'LicensedDoctorNurse1',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'HOME_PERSONAL_AID',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Is the owner or any employees an active Medical Doctor, Midwife, Nurse Practitioner, Physician’s Assistant, Registered Nurse, Licensed Practical Nurse, or Naturopathic doctor?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROCESS_SERVER',
      conditionalChildren: [
        {
          inputId: 'Licensing',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'PROCESS_SERVER',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            "The business is properly and currently licensed as per its state's applicable rules and statutes.",
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYPL,
        checkboxConfigs: [
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'LobbyingAndOrPoliticalAdvice',
            labelText: 'Lobbying and/or political advice',
          },
          {
            nameOfFormControl: 'MedicalAdvice',
            labelText: 'Medical advice',
          },
          {
            nameOfFormControl: 'SalesRepresentativeOrRetail',
            labelText: 'Sales representative or retail',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'MARKET_PR'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business act as the nutritional director for any of the following? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_NutritionDirector',
        checkboxConfigs: [
          {
            nameOfFormControl: 'CorrectionalFacility',
            labelText: 'Correctional facility',
          },
          {
            nameOfFormControl: 'HealthDepartment',
            labelText: 'Health department',
          },
          {
            nameOfFormControl: 'HealthcareFacility',
            labelText: 'Healthcare facility',
          },
          {
            nameOfFormControl: 'Hospital',
            labelText: 'Hospital',
          },
          {
            nameOfFormControl: 'PhysiciansOffice',
            labelText: 'Physician’s office',
          },
          {
            nameOfFormControl: 'SchoolOrUniversity',
            labelText: 'School or University',
          },
          {
            nameOfFormControl: 'StateOrLocalGovernmentAgency',
            labelText: 'State or local government agency',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'DIET'
    ),
    {
      inputId: 'PartyIdentify',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'NOTARY',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business ensure that for all transactions, each party whose signature you notarize is identified and in your presence at the time of notarization?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_PCEligibilityPL',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AdultDaycare',
            labelText: 'Adult daycare',
          },
          {
            nameOfFormControl: 'ChildcareOrNanny',
            labelText: 'Childcare or nanny',
          },
          {
            nameOfFormControl: 'CleaningOrJanitorial',
            labelText: 'Cleaning or janitorial',
          },
          {
            nameOfFormControl: 'FinancialOrInvestmentAdvice',
            labelText: 'Financial or investment advice',
          },
          {
            nameOfFormControl: 'HealthcareOrRelatedServices',
            labelText: 'Healthcare or related services',
          },
          {
            nameOfFormControl: 'Moving',
            labelText: 'Moving',
          },
          {
            nameOfFormControl: 'PetSittingOrPetCare',
            labelText: 'Pet sitting or pet care',
          },
          {
            nameOfFormControl: 'SocialWork',
            labelText: 'Social work',
          },
          {
            nameOfFormControl: 'TransportationOfPeopleOrGoods',
            labelText: 'Transportation of people or goods',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PERSONAL_ASSISTANT'
    ),
    {
      inputId: HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CHIROPHYSICALTHERAPY,
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'OCC_SPEECH',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business provide any chiropractic or physical therapy services?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ProfessLicensesAllServices',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'PROF_LIC',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
        'Not Required': 'Not Required',
      },
      labelText:
        'If required by state law, does the owner of the business or the principal of the firm maintain current and valid professional training, certifications, licenses or designations for all services provided?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_ProfessLicensesHealthSrvcs',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'HOME_PERSONAL_AID',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
        'Not Applicable': 'Not Applicable',
      },
      labelText:
        'As required by state law, is the owner of the business and the employees properly trained or certified to administer, dispense, or assist in providing medications to patients?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'PrptyMgrCreditChecks',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'PROP_MGT',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business conduct credit checks on prospective tenants?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_PrptyMgrCreditChecks',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROP_MGT',
      conditionalChildren: [
        {
          inputId:
            'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_PrptyMgrCreditConsentForm',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'PROP_MGT',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            'Does the business usually obtain a consent form or advise prospective tenants that their credit will be checked?',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrHotelHealthcare',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'PROP_MGT',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business manage any hotel properties or health care facilities?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrRenovation',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'PROP_MGT',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business manage or implement any construction, renovation or reconstruction projects on the properties you manage? (This does not apply to oversight of routine maintenance.)',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_PMRealEstateServices',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'PROP_MGT',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business provide any real estate agent services?',
      clauses: [
        'Insurance agent/broker',
        'Home inspector',
        'Property developer builder or property preservation.  (This does not include the oversight of routine maintenance)',
      ],
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ApplicationRatingInfo_PMRealEstateServices',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROP_MGT',
      conditionalChildren: [
        generatePlV4CheckboxGroupNode(
          {
            labelText:
              'Does the business provide any of the following services? Check all that apply.',
            nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices1',
            checkboxConfigs: [
              {
                nameOfFormControl: 'ConstructionOrRemodeling',
                labelText: 'Construction or remodeling',
              },
              {
                nameOfFormControl: 'GeneralContracting',
                labelText: 'General contracting',
              },
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'JanitorialOrCleaning',
                labelText: 'Janitorial or cleaning',
              },
              {
                nameOfFormControl: 'LandscapingOrLawnCare',
                labelText: 'Landscaping or lawn care',
              },
              {
                nameOfFormControl: 'MortgageBankerOrBroker',
                labelText: 'Mortgage banker/broker',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder',
              },
              {
                nameOfFormControl: 'PropertyPreservation',
                labelText: 'Property preservation',
              },
              {
                nameOfFormControl: 'RealEstateAppraiser',
                labelText: 'Real estate appraiser',
              },
              {
                nameOfFormControl: 'TitleAgentOrAbstractor',
                labelText: 'Title agent/abstractor',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          },
          [HISCOX_API_VERSION.v4],
          'PROP_MGT'
        ),
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ApplicationRatingInfo_PMRealEstateServices',
      enableValue: 'No',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROP_MGT',
      conditionalChildren: [
        generatePlV4CheckboxGroupNode(
          {
            labelText:
              'Does the business provide any of the following services? Check all that apply.',
            nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_PrptyMgrServices2',
            checkboxConfigs: [
              {
                nameOfFormControl: 'ConstructionOrRemodeling',
                labelText: 'Construction or remodeling',
              },
              {
                nameOfFormControl: 'GeneralContracting',
                labelText: 'General contracting',
              },
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'JanitorialOrCleaning',
                labelText: 'Janitorial or cleaning',
              },
              {
                nameOfFormControl: 'LandscapingOrLawnCare',
                labelText: 'Landscaping or lawn care',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder',
              },
              {
                nameOfFormControl: 'PropertyPreservation',
                labelText: 'Property preservation',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          },
          [HISCOX_API_VERSION.v4],
          'PROP_MGT'
        ),
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ClaimsSrvs',
        checkboxConfigs: [
          {
            nameOfFormControl: 'ConstructionOrRemodeling',
            labelText: 'Construction or remodeling',
          },
          {
            nameOfFormControl: 'GeneralContracting',
            labelText: 'General contracting',
          },
          {
            nameOfFormControl: 'InstallationOrRepair',
            labelText: 'Installation or repair',
          },
          {
            nameOfFormControl: 'PublicAdjusting',
            labelText: 'Public Adjusting',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'CLAIMS_SERVICES'
    ),
    {
      // TODO number validator
      inputId: 'RealEstateDualAgencyCommissionPct',
      primitive: 'NUMBER',
      cobOnly: 'REALESTATE',
      conditional: 'None',
      options: {},
      labelText:
        'Approximately, what percentage of commissions are dual agency sales, where the business represents both the buyer and seller in the same transaction?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'RealEstateExclusiveSales',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'REALESTATE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business have any exclusive sales relationships with any real estate developer, builder or construction company?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'RealEstateLicenseCurrent',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'REALESTATE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        "Are the licenses for all agents working with the firm current, and in good standing with the state's licensing board?",
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'RealEstateOwnedSales',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'REALESTATE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business sell any properties owned by the owner or an affiliated entity?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business perform any of the following? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ExecutiveSrvs',
        checkboxConfigs: [
          {
            nameOfFormControl: 'ChildcareOrNannyRecruitmentOrPlacements',
            labelText: 'Childcare or nanny recruitment or placements',
          },
          {
            nameOfFormControl: 'ActAsAProfessionalEmployerOrganizationPEO',
            labelText: 'Act as a Professional Employer Organization (PEO)',
          },
          {
            nameOfFormControl: 'LeaseEmployeesOrProvideEmployeesToOtherOrganizations',
            labelText: 'Lease employees or provide employees to other organizations',
          },
          {
            nameOfFormControl: 'MedicalPersonnelRecruitmentOrPlacements',
            labelText: 'Medical personnel recruitment or placements',
          },
          {
            nameOfFormControl: 'StaffingServices',
            labelText: 'Staffing services',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the Above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PLACEMENT_RECRUIT'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business conduct any of the following activities? Check all that apply.',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ResearchConsultingEligibility',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerospaceConsultingOrAdvice',
            labelText: 'Aerospace consulting or advice',
          },
          {
            nameOfFormControl: 'ActuarialAdvice',
            labelText: 'Actuarial advice',
          },
          {
            nameOfFormControl: 'Appraisals',
            labelText: 'Appraisals',
          },
          {
            nameOfFormControl: 'ArchitectureOrEngineeringAdvice',
            labelText: 'Architecture or engineering advice',
          },
          {
            nameOfFormControl: 'BackgroundChecks',
            labelText: 'Background checks',
          },
          {
            nameOfFormControl: 'ConstructionManagementOrAdvice',
            labelText: 'Construction management or advice',
          },
          {
            nameOfFormControl: 'ClinicalOrMedicalTrials',
            labelText: 'Clinical or medical trials',
          },
          {
            nameOfFormControl: 'CreditCounseling',
            labelText: 'Credit counseling',
          },
          {
            nameOfFormControl: 'EnvironmentalConsultingOrAdvice',
            labelText: 'Environmental consulting or advice',
          },
          {
            nameOfFormControl: 'FinancingOrFinancialAuditing',
            labelText: 'Financing or financial auditing',
          },
          {
            nameOfFormControl: 'GeneralContracting',
            labelText: 'General contracting',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'InsurancePlacementOrAdvice',
            labelText: 'Insurance placement or advice',
          },
          {
            nameOfFormControl: 'LandAcquisition',
            labelText: 'Land acquisition',
          },
          {
            nameOfFormControl: 'LawEnforcementTraining',
            labelText: 'Law enforcement training',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'LobbyingOrPoliticalAdvice',
            labelText: 'Lobbying and/or political advice',
          },
          {
            nameOfFormControl: 'MedicalAdvice',
            labelText: 'Medical advice',
          },
          {
            nameOfFormControl: 'MergersAndAcquisitionsOrBusinessValuations',
            labelText: 'Mergers and acquisitions or business valuations',
          },
          {
            nameOfFormControl: 'MiningConsultingOrAdvice',
            labelText: 'Mining consulting or advice',
          },
          {
            nameOfFormControl: 'OilGasPetroleumConsultingOrAdvice',
            labelText: 'Oil, gas, or petroleum consulting or advice',
          },
          {
            nameOfFormControl: 'PrivateInvestigations',
            labelText: 'Private investigations',
          },
          {
            nameOfFormControl: 'SafetyConsultingOrAdvice',
            labelText: 'Safety consulting or advice',
          },
          {
            nameOfFormControl: 'SalesRepresentative',
            labelText: 'Sales representative',
          },
          {
            nameOfFormControl: 'TitleAbstracting',
            labelText: 'Title abstracting',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'RESEARCH'
    ),
    {
      inputId: 'ServiceProfAthletes1',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'EXEC',
      conditional: 'None',
      options: {
        'I understand and agree the above is excluded by this policy':
          'I understand and agree the above is excluded by this policy',
        'I disagree with the above being excluded by this policy':
          'I disagree with the above being excluded by this policy',
      },
      labelText:
        'Providing any services to a professional athlete is excluded and NOT covered by this policy.',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business treat patients using any of the following? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ShockTherapy',
        checkboxConfigs: [
          {
            nameOfFormControl: 'PhysicalRestraints',
            labelText: 'Physical restraints',
          },
          {
            nameOfFormControl: 'ShockTherapy',
            labelText: 'Shock Therapy',
          },
          {
            nameOfFormControl: 'SexualTherapy',
            labelText: 'Sexual Therapy',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PHYSICAL_TREATMENT'
    ),
    {
      inputId: 'SocialWorkSrvs',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'SOCIAL_WORK',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business work on cases involving child protective services?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SpaOwnership',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'MASSAGE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Is the business a franchisee of a salon or spa franchise?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractProfSrvcs',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'SUBCONTRACT_PROF_SERVICES',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business subcontract any professional services?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractProfSrvcs',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'SUBCONTRACT_PROF_SERVICES',
      conditionalChildren: [
        {
          inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractDesign',
          cssClass: 'sub-question',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'SUBCONTRACT_PROF_SERVICES',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            'Does the business subcontract design related architectural, drafting, or engineering services?',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractRepair',
          cssClass: 'sub-question',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'SUBCONTRACT_PROF_SERVICES',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText: 'Does the business subcontract physical construction or repair services?',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractOtherSrvcs',
          cssClass: 'sub-question',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'SUBCONTRACT_PROF_SERVICES',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText: 'Does the business subcontract any other services?',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractInsurance',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'SUBCONTRACT_PROF_SERVICES',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            'Does the business require subcontractors you hire to purchase their own insurance?',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractOtherSrvcs',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'SUBCONTRACT_PROF_SERVICES',
      conditionalChildren: [
        {
          inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SubcontractSrvcsDescribe',
          primitive: 'TEXT',
          inputType: 'text',
          cobOnly: 'SUBCONTRACT_PROF_SERVICES',
          cssClass: 'sub-question',
          options: {},
          labelText: 'Please describe',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'AgreeDisagreeStatements',
      enableValue: 'I have read and disagree with one or more of these statements',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROCESS_SERVER',
      conditionalChildren: [
        {
          inputId: 'TexasStatutoryRequirements',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'PROCESS_SERVER',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          labelText:
            'The business is properly and currently certified by order of the Supreme Court of the State of Texas and have successfully completed all required training (as required by Texas statute) prior to certification.',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does your business conduct any of the following training, instruction, or services? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility',
        checkboxConfigs: [
          {
            nameOfFormControl: 'Animal',
            labelText: 'Animal',
          },
          {
            nameOfFormControl: 'ArchitectureOrEngineering',
            labelText: 'Architecture or engineering',
          },
          {
            nameOfFormControl: 'Aviation',
            labelText: 'Aviation',
          },
          {
            nameOfFormControl: 'BehindTheWheelDriving',
            labelText: 'Behind the wheel driving',
          },
          {
            nameOfFormControl: 'CookingOrFoodPreparation',
            labelText: 'Cooking or food preparation',
          },
          {
            nameOfFormControl: 'ConstructionManagement',
            labelText: 'Construction management',
          },
          {
            nameOfFormControl: 'DaycareOrChildcare',
            labelText: 'Daycare or childcare',
          },
          {
            nameOfFormControl: 'Fitness',
            labelText: 'Fitness',
          },
          {
            nameOfFormControl: 'LawEnforcement',
            labelText: 'Law enforcement',
          },
          {
            nameOfFormControl: 'Medical',
            labelText: 'Medical',
          },
          {
            nameOfFormControl: 'Safety',
            labelText: 'Safety',
          },
          {
            nameOfFormControl: 'SecurityGuard',
            labelText: 'Security guard',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'EDUCATION_CONSULTING'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Is the business any of the following? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_TrainingEligibility1',
        checkboxConfigs: [
          {
            nameOfFormControl: 'PublicPrivateCharterSchool',
            labelText: 'Public/private/charter school',
          },
          {
            nameOfFormControl: 'CollegeOrUniversity',
            labelText: 'College or university',
          },
          {
            nameOfFormControl: 'DayCareOrEldercareFacility',
            labelText: 'Day care or eldercare facility',
          },
          {
            nameOfFormControl: 'TechnicalOrVocationalSchool',
            labelText: 'Technical or vocational school',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'EDUCATION_CONSULTING'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_TRAVELSERVICES,
        checkboxConfigs: [
          {
            nameOfFormControl: 'TourOperatorOrTransportation',
            labelText: 'Tour operator or transportation',
          },
          {
            nameOfFormControl: 'BookTravelArrangementsUsingRewardPointsNotObtainedByClient',
            labelText:
              'Book travel arrangements using travel reward points the client did not obtain (i.e. creating travel reward bookings from points obtained through a travel reward broker)',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'TRAVEL'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business provide any of the following IT consulting or technology services? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ITTechServices',
        checkboxConfigs: [
          {
            nameOfFormControl: 'GameDeveloper',
            labelText: 'Game developer',
          },
          {
            nameOfFormControl: 'InternetSearchEngine',
            labelText: 'Internet search engine',
          },
          {
            nameOfFormControl: 'SmartphoneMobilePhoneTabletApplicationDevelopment',
            labelText: 'Smartphone/mobile phone/tablet application development',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'TECH_ALL'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business own, host or run any website that: Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_TypeOfWebsite',
        checkboxConfigs: [
          {
            nameOfFormControl: 'PornographicMaterials',
            labelText: 'Contains any pornographic materials',
          },
          {
            nameOfFormControl: 'UserGeneratedContent',
            labelText: 'Contains user generated content',
          },
          {
            nameOfFormControl: 'SocialNetworkingWebsite',
            labelText: 'Is a social networking website?',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'GRAPHIC_DESIGN'
    ),
    {
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_SimilarInsurance',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'ALL',
      options: {
        Yes: 'Yes',
        No: 'No',
        'Prefer not to answer': 'PreferNotToAnswer',
      },
      labelText:
        'Does the business currently have an insurance policy in effect for the coverage requested?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ApplicationRatingInfo_SimilarInsurance',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROF_LIABILITY_INSURANCE',
      conditionalChildren: [
        {
          inputId:
            'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_HaveProfLiabilityInsurance',
          labelText: 'Does the business owner currently have a Professional Liability policy?',
          conditional: 'None',
          primitive: 'RADIO',
          cobOnly: 'PROF_LIABILITY_INSURANCE',
          cssClass: 'sub-question',
          options: {
            Yes: 'Yes',
            No: 'No',
          },
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SIMILARINSURANCE,
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROF_LIABILITY_INSURANCE',
      conditionalChildren: [
        {
          labelText:
            'Does the business owner have the retroactive date of your current Professional Liability policy?',
          conditional: 'None',
          primitive: 'RADIO',
          inputId:
            'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ProfessionalLiabilityRetroactiveDateAvailable',
          cobOnly: 'PROF_LIABILITY_INSURANCE',
          cssClass: 'sub-question',
          options: {
            Yes: 'Yes',
            "No or I don't know what that is": "No or I don't know what that is",
          },
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn:
        'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ProfessionalLiabilityRetroactiveDateAvailable',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'PROF_LIABILITY_INSURANCE',
      conditionalChildren: [
        {
          primitive: 'DATE',
          inputId:
            HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PROFESSIONALLIABILITYRETROACTIVEDATE,
          cobOnly: 'PROF_LIABILITY_INSURANCE',
          cssClass: 'sub-question',
          inputType: 'text',
          labelText: 'Professional Liability retroactive date',
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Which of the following services does your business provide? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_IsBookkeeping',
        checkboxConfigs: [
          {
            nameOfFormControl: 'BookkeepingPayrollServices',
            labelText: 'Bookkeeping/Payroll services',
          },
          {
            nameOfFormControl: 'PersonalTaxAndBusinessTaxReturns',
            labelText: 'Personal Tax & Business Tax Returns',
          },
          {
            nameOfFormControl: 'EnrolledAgents',
            labelText: 'Enrolled Agents',
          },
          {
            nameOfFormControl: 'BusinessConsulting',
            labelText: 'Business Consulting',
          },
          {
            nameOfFormControl: 'CertifiedPublicAccounting',
            labelText: 'Certified Public Accounting',
          },
          {
            nameOfFormControl: 'EstateTaxReturns',
            labelText: 'Estate Tax Returns',
          },
          {
            nameOfFormControl: 'ForensicAccounting',
            labelText: 'Forensic Accounting',
          },
          {
            nameOfFormControl: 'LitigationSupport',
            labelText: 'Litigation Support',
          },
          {
            nameOfFormControl: 'BusinessValuationsForecasts',
            labelText: 'Business Valuations/Forecasts',
          },
          {
            nameOfFormControl: 'AuditingAttestationServices',
            labelText: 'Auditing/Attestation services',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'ACCOUNTING'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Which of these services does your business provide?',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_IsBarber',
        checkboxConfigs: [
          {
            nameOfFormControl: 'BarberHairStylist',
            labelText: 'Barber/Hair Stylist',
          },
          {
            nameOfFormControl: 'BeauticianCosmetologist',
            labelText: 'Beautician/Cosmetologist',
          },
          {
            nameOfFormControl: 'Esthetician',
            labelText: 'Esthetician',
          },
          {
            nameOfFormControl: 'NailTechnician',
            labelText: 'Nail Technician',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'HAIR_BEAUTY_5'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Which of these services does the business provide?',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_IsBeautician',
        checkboxConfigs: [
          {
            nameOfFormControl: 'BarberHairStylist',
            labelText: 'Barber/Hair Stylist',
          },
          {
            nameOfFormControl: 'BeauticianCosmotologist',
            labelText: 'Beautician/Cosmetologist',
          },
          {
            nameOfFormControl: 'Esthetician',
            labelText: 'Esthetician',
          },
          {
            nameOfFormControl: 'NailTechnician',
            labelText: 'Nail Technician',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'HAIR_BEAUTY_4'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Which of these services does the business provide?',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_IsEsthetician',
        checkboxConfigs: [
          {
            nameOfFormControl: 'BarberHairStylist',
            labelText: 'Barber/Hair Stylist',
          },
          {
            nameOfFormControl: 'BeauticianCosmetologist',
            labelText: 'Beautician/Cosmetologist',
          },
          {
            nameOfFormControl: 'Esthetician',
            labelText: 'Esthetician',
          },
          {
            nameOfFormControl: 'NailTechnician',
            labelText: 'Nail Technician',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'HAIR_NAIL_1'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Which of these services does the business provide?',
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_IsNailTechnician',
        checkboxConfigs: [
          {
            nameOfFormControl: 'BarberHairStylist',
            labelText: 'Barber/Hair Stylist',
          },
          {
            nameOfFormControl: 'BeauticianCosmetologist',
            labelText: 'Beautician/Cosmetologist',
          },
          {
            nameOfFormControl: 'Esthetician',
            labelText: 'Esthetician',
          },
          {
            nameOfFormControl: 'NailTechnician',
            labelText: 'Nail Technician',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'NAIL_TECH'
    ),
    {
      inputId: 'MassageTherapyServices',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'HAIR_BEAUTY_6',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does the business provide any full body massage therapy services?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_BeautyServices2PL',
        labelText:
          'Does the business provide any of the following services or use any of the following equipment? Check all that apply',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AdministerInjections',
            labelText: 'Administer injections',
          },
          {
            nameOfFormControl: 'ChemicalPeelsWithSolutions31PercentOrGreater',
            labelText: 'Chemical peels with solutions 31% or greater?',
          },
          {
            nameOfFormControl: 'ColonHydrotherapyServices',
            labelText: 'Colon hydrotherapy services',
          },
          {
            nameOfFormControl: 'ElectrolysisServices',
            labelText: 'Electrolysis services',
          },
          {
            nameOfFormControl: 'LaserTreatmentServices',
            labelText: 'Laser treatment services',
          },
          {
            nameOfFormControl: 'LicensingAsPartOfASchool',
            labelText: 'Licensing as part of a school',
          },
          {
            nameOfFormControl: 'OperatingSaunasOrSteamRooms',
            labelText: 'Operate saunas or steam rooms',
          },
          {
            nameOfFormControl: 'OperatingTanningBedsOrBooths',
            labelText: 'Operate tanning beds or booths',
          },
          {
            nameOfFormControl: 'PermanentMakeupServices',
            labelText: 'Permanent make up services',
          },
          {
            nameOfFormControl: 'SkinTagRemovalServices',
            labelText: 'Skin tag removal services',
          },
          {
            nameOfFormControl: 'TattoosServices',
            labelText: 'Tattoos services',
          },
          {
            nameOfFormControl: 'TeethWhiteningOrOtherDentalServices',
            labelText: 'Teeth whitening or other dental services',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'HAIR_BEAUTY_2'
    ),
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_BusinessAuction',
        labelText: 'Does the business perform any of the following? Check all that apply',
        checkboxConfigs: [
          {
            nameOfFormControl:
              'AuctionPropertiesOrOtherItemsThatYourBusinessOwnsOrHasControllingInterest',
            labelText:
              'Auction properties or other items that the business owns or in which it has a controlling interest',
          },
          {
            nameOfFormControl: 'AuthenticateOwnershipOrPropertyOrItemsAuctioned',
            labelText: 'Authenticate the ownership of property(ies) or items auctioned',
          },
          {
            nameOfFormControl: 'ConductAuctionsOnlineOrOverInternet',
            labelText: 'Conduct auctions on-line or over the Internet',
          },
          {
            nameOfFormControl: 'ConductPrivateOrNonPublicAuctions',
            labelText: 'Conduct private or other auctions other than public auctions',
          },
          {
            nameOfFormControl: 'OwnOrOperateAnAuctionHouse',
            labelText: 'Own or operate an auction house',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'AUCTIONEERING'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business prescribe or dispense any of the following? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS1,
        checkboxConfigs: [
          {
            nameOfFormControl: 'HerbsOrHerbalDrugs',
            labelText: 'Herbs or herbal drugs',
          },
          {
            nameOfFormControl: 'OverTheCounterOrPrescriptionDrugs',
            labelText: 'Over the counter or prescription drugs',
          },
          {
            nameOfFormControl: 'HormoneTherapy',
            labelText: 'Hormone therapy',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'DIET'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business perform any of the following? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS2,
        checkboxConfigs: [
          {
            nameOfFormControl: 'PrescribeOrDispenseDietarySupplements',
            labelText: 'Prescribe or dispense dietary supplements',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseHerbsOrHerbalDrugs',
            labelText: 'Prescribe or dispense herbs or herbal drugs',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseOverTheCounterOrPrescriptionDrugs',
            labelText: 'Prescribe or dispense over the counter or prescription drugs',
          },
          {
            nameOfFormControl: 'HynosisAsAFormOfEntertainmentForAnAudience', // spelling is wrong in schmema file
            labelText: 'Hypnosis as a form of entertainment for an audience?',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'HYPNOSIS'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business perform any of the following? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS3,
        checkboxConfigs: [
          {
            nameOfFormControl: 'PrescribeOrDispenseHerbsOrHerbalDrugs',
            labelText: 'Prescribe or dispense herbs or herbal drugs',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseOverTheCounterOrPrescriptionDrugs',
            labelText: 'Prescribe or dispense over the counter or prescription drugs',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PRESCRIBE_HERBS3'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business perform any of the following? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS4,
        checkboxConfigs: [
          {
            nameOfFormControl: 'AdministerOrMonitorDetoxificationPrograms',
            labelText: 'Administer or monitor detoxification programs',
          },
          {
            nameOfFormControl: 'CounselPatientsOnAdoptionChildAbuseOrFosterCare',
            labelText: 'Counsel patients on adoption, child abuse, or foster care',
          },
          {
            nameOfFormControl: 'OperateAnyResidentialTreatmentFacilities',
            labelText: 'Operate any residential treatment facilities',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseHerbsOrHerbalDrugs',
            labelText: 'Prescribe or dispense herbs or herbal drugs',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseOverTheCounterOrPrescriptionDrugs',
            labelText: 'Prescribe or dispense over the counter or prescription drugs',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PRESCRIBE_HERBS4'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business perform any of the following? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS5,
        checkboxConfigs: [
          {
            nameOfFormControl: 'AdministerOrMonitorDetoxificationPrograms',
            labelText: 'Administer or monitor detoxification programs',
          },
          {
            nameOfFormControl: 'DrugTesting',
            labelText: 'Drug testing',
          },
          {
            nameOfFormControl: 'OperateAnyResidentialTreatmentFacilities',
            labelText: 'Operate any residential treatment facilities',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseHerbsOrHerbalDrugs',
            labelText: 'Prescribe or dispense herbs or herbal drugs',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseOverTheCounterOrPrescriptionDrugs',
            labelText: 'Prescribe or dispense over the counter or prescription drugs',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'SUBSTANCE_ABUSE_COUNSELING'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          'Does the business perform or operate any of the following? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS6,
        checkboxConfigs: [
          {
            nameOfFormControl: 'AdministerOrMonitorDetoxificationPrograms',
            labelText: 'Administer or monitor detoxification programs',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseHerbsOrHerbalDrugs',
            labelText: 'Prescribe or dispense herbs or herbal drugs',
          },
          {
            nameOfFormControl: 'PrescribeOrDispenseOverTheCounterOrPrescriptionDrugs',
            labelText: 'Prescribe or dispense over the counter or prescription drugs',
          },
          {
            nameOfFormControl: 'ResidentialFacility',
            labelText: 'Residential facility',
          },
          {
            nameOfFormControl: 'TreatmentFacility',
            labelText: 'Treatment facility',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'SOCIAL_WORK'
    ),
    {
      inputId: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_CoverQuoteRq_RatingInfo_Deductible',
      primitive: 'SELECT',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'ALL',
      labelText: 'Deductible amount',
      // All COBS, per Mustang UI Development Guidelines Quote V4 - v1.5
      options: {
        '0': '$0',
        '50': '$50',
        '100': '$100',
        '250': '$250',
        '500': '$500',
        '750': '$750',
        '1000': '$1,000',
        '1500': '$1,500',
        '2500': '$2,500',
        '5000': '$5,000',
        '7500': '$7,500',
        '10000': '$10,000',
      },
      cobOverrideSets: [
        [
          'CLAIMS_ADJ',
          {
            options: {
              '2500': '$2,500',
              '5000': '$5,000',
              '7500': '$7,500',
              '10000': '$10,000',
            },
          },
        ],
        [
          ['REALESTATE', 'INS_AGENT', 'PROP_MGT'],
          {
            options: {
              '1000': '$1,000',
              '1500': '$1,500',
              '2500': '$2,500',
              '5000': '$5,000',
              '7500': '$7,500',
              '10000': '$10,000',
            },
          },
        ],
      ],
    },
    {
      inputId: 'FormalChangeMgmt', // fixKeyV4 pre-pends with `ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_`
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'CHANGE_MGT',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText:
        'Does the business implement measures to manage risk, such as client contracts with indemnification clauses, sign-off on deliverables, or formal change management procedure?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        "Is the business owner or the business properly and currently licensed as per the state's applicable rules and statutes.",
      primitive: 'RADIO',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_CERTIFIEDINNYCA,
      cobOnly: 'PROCESS_SERVER',
      stateOnly: 'NY',
      conditional: 'None',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        "Is the business owner or the business properly and currently licensed as per the state's applicable rules and statutes.",
      primitive: 'RADIO',
      inputId: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_CertifiedInNYCA',
      cobOnly: 'PROCESS_SERVER',
      stateOnly: 'AZ',
      conditional: 'None',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        "Is the business owner or the business properly and currently licensed as per the state's applicable rules and statutes.",
      primitive: 'RADIO',
      inputId: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_CertifiedInNYCA',
      cobOnly: 'PROCESS_SERVER',
      stateOnly: 'IL',
      conditional: 'None',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        "Is the business owner or the business properly and currently licensed as per the state's applicable rules and statutes.",
      primitive: 'RADIO',
      inputId: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_CertifiedInNYCA',
      cobOnly: 'PROCESS_SERVER',
      stateOnly: 'CA',
      conditional: 'None',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        'Is the business owner or the business currently and properly certified by an order of a court of competent and appropriate jurisdiction of the State of Georgia.',
      primitive: 'RADIO',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_CERTIFIEDINGA,
      cobOnly: 'PROCESS_SERVER',
      stateOnly: 'GA',
      conditional: 'None',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        'Is the business owner or the business properly and currently certified by court an order of a court of competent and appropriate jurisdiction of the State of Rhode Island.',
      primitive: 'RADIO',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_CERTIFIEDINRHODEISLAND,
      cobOnly: 'PROCESS_SERVER',
      stateOnly: 'RI',
      conditional: 'None',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        'Is the business owner or the business properly and currently certified by order of the Supreme Court of the State of Texas and have successfully completed all required training (as required by Texas statute) prior to certification.',
      primitive: 'RADIO',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_TEXASSTATUTORYREQUIREMENTS,
      cobOnly: 'PROCESS_SERVER',
      stateOnly: 'TX',
      conditional: 'None',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText: 'The business owner agrees to receive policy documents by email.',
      conditional: 'None',
      primitive: 'RADIO',
      inputId: 'Acknowledgements_EmailConsent',
      cobOnly: 'ALL',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText: 'The business owner agrees to receive important notices by email.',
      conditional: 'None',
      primitive: 'RADIO',
      inputId: 'Acknowledgements_EmailConsent2',
      cobOnly: 'ALL',
      options: {
        Agree: 'Agree',
        Disagree: 'Disagree',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'TaxPrepSrvs',
      primitive: 'RADIO',
      inputType: 'text',
      cobOnly: 'BOOKKEEPING',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      labelText: 'Does your business provide any tax preparation services?',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      inputId: 'ProfessLicensesInsAgent',
      labelText:
        'If required by state law, does the owner or the principal of the firm maintain current and valid professional training, certifications, licenses or designations for all services the business provides?',
      primitive: 'RADIO',
      cobOnly: 'INS_AGENT',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    // all cobs, all states except NY
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'Acknowledgements_AgreeDisagreeStatements',
      enableValue: 'Disagree',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'ALL',
      statesExclude: ['NY'],
      conditionalChildren: [
        {
          inputId:
            'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_EOStatement1',
          nameOfFormControl: 'EOStatement1',
          primitive: 'RADIO',
          cssClass: 'sub-question',
          inputType: 'text',
          cobOnly: 'ALL',
          conditional: 'None',
          options: {
            Agree: 'Agree',
            Disagree: 'Disagree',
          },
          labelText: `${EOSTATEMENT1}`,
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId:
            'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_EOStatement2',
          nameOfFormControl: 'EOStatement2',
          primitive: 'RADIO',
          cssClass: 'sub-question',
          inputType: 'text',
          cobOnly: 'ALL',
          conditional: 'None',
          options: {
            Agree: 'Agree',
            Disagree: 'Disagree',
          },
          labelText: `${EOSTATEMENT2_V4}`,
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    // all cobs, NY state only
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'Acknowledgements_AgreeDisagreeStatements',
      enableValue: 'Disagree',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'ALL',
      stateOnly: 'NY',
      conditionalChildren: [
        {
          inputId:
            'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_EOStatement1',
          nameOfFormControl: 'EOStatement1',
          primitive: 'RADIO',
          cssClass: 'sub-question',
          inputType: 'text',
          cobOnly: 'ALL',
          conditional: 'None',
          options: {
            Agree: 'Agree',
            Disagree: 'Disagree',
          },
          labelText: `${EOSTATEMENT1}`,
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
        {
          inputId:
            'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_ProductAcknowledgements_EOStatement3',
          nameOfFormControl: 'EOStatement3',
          cssClass: 'sub-question',
          primitive: 'RADIO',
          inputType: 'text',
          cobOnly: 'ALL',
          stateOnly: 'NY',
          conditional: 'None',
          options: {
            Agree: 'Agree',
            Disagree: 'Disagree',
          },
          labelText: `${EOSTATEMENT3_V4_NY}`,
          required: true,
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5,
        labelText:
          "Does the business or any of the business' subcontractors provide any of the following services or work on any projects involving the following materials or infrastructure? Check all that apply.",
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerospaceConsultingOrAdvice',
            labelText: 'Aerospace consulting or advice',
          },
          {
            nameOfFormControl: 'AmusementRidesPoolsOrPlaygrounds',
            labelText: 'Amusement rides, pools or playgrounds',
          },
          {
            nameOfFormControl: 'ArchitectureOrEngineeringAdvice',
            labelText: 'Architecture or engineering advice',
          },
          {
            nameOfFormControl: 'AsbestosLeadOrMoldEvaluationOrAbatement',
            labelText: 'Asbestos, lead, or mold evaluation or abatement',
          },
          {
            nameOfFormControl: 'BridgesDamsHarborsMinesPiersSubdivisionsOrTunnels',
            labelText: 'Bridges, dams, harbors, highways, mines, piers, subdivisions, or tunnels',
          },
          {
            nameOfFormControl: 'ConstructionCostEstimates',
            labelText: 'Construction cost estimates',
          },
          {
            nameOfFormControl: 'ConstructionManagementOrAdvice',
            labelText: 'Construction management or advice',
          },
          {
            nameOfFormControl:
              'ConstructionMaintenanceRepairRenovationOrPropertyPreservationServices',
            labelText:
              'Construction, maintenance, repair, renovation, or property preservation services',
          },
          {
            nameOfFormControl: 'DesignBuild',
            labelText: 'Design/build',
          },
          {
            nameOfFormControl: 'EmergencyResponseOrCleanUpOfAnyHazardousWaste',
            labelText: 'Emergency response or clean-up of any hazardous waste',
          },
          {
            nameOfFormControl: 'EnvironmentalConsultingOrAdvice',
            labelText: 'Environmental consulting or advice',
          },
          {
            nameOfFormControl: 'FinancingOrFinancialAuditing',
            labelText: 'Financing or financial auditing',
          },
          {
            nameOfFormControl: 'FoundationSheetingOrRetainingWallDesign',
            labelText: 'Foundation, sheeting, or retaining wall design',
          },
          {
            nameOfFormControl: 'GeneralContracting',
            labelText: 'General contracting',
          },
          {
            nameOfFormControl: 'GroundTesting',
            labelText: 'Ground testing',
          },
          {
            nameOfFormControl: 'HydraulicFracturingHydrofracturingOrFracking',
            labelText: 'Hydraulic fracturing, hydrofracturing, or fracking',
          },
          {
            nameOfFormControl: 'InspectionsAsPartOfARealEstateTransaction',
            labelText: 'Inspections as part of a real estate transaction',
          },
          {
            nameOfFormControl: 'InsurancePlacementOrAdvice',
            labelText: 'Insurance placement or advice',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'LaboratoryTesting',
            labelText: 'Laboratory testing',
          },
          {
            nameOfFormControl: 'LandAcquisitionAdvice',
            labelText: 'Land acquisition advice',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'MiningConsultingOrAdvice',
            labelText: 'Mining consulting  or advice',
          },
          {
            nameOfFormControl: 'OilGasOrPetroleumConsultingOrAdvice',
            labelText: 'Oil, gas, or petroleum consulting or advice',
          },
          {
            nameOfFormControl: 'QuantitySurveysOrQuantityEstimates',
            labelText: 'Quantity surveys or quantity estimates',
          },
          {
            nameOfFormControl: 'RealEstateAppraisals',
            labelText: 'Real estate appraisals',
          },
          {
            nameOfFormControl:
              'PhysicalWorkOnUndergroundStorageTanksOrUtilitiesOrSubsurfaceCondition',
            labelText:
              'Physical work on any underground storage tanks, utilities, and/or any other subsurface condition',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'FORBIDDEN_PROJECTS5'
    ),
    {
      labelText:
        'Is the business hired as an independent contractor by the original manufacturer(s)?',
      primitive: 'RADIO',
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_MSRIndpntCtrctr',
      cobOnly: 'MANUFACTURER_SALES_REPRESENTATIVE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText: 'Does the business have storage or warehouse facilities for inventory?',
      primitive: 'RADIO',
      inputId: HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_MSRINVNTRY,
      cobOnly: 'MANUFACTURER_SALES_REPRESENTATIVE',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText: "What are the business's expected commissions during the next 12 months?",
      primitive: 'MONEY_WITHOUT_DECIMAL',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS,
      cobOnly: 'MANUFACTURER_SALES_REPRESENTATIVE',
      conditional: 'None',
      required: true,
      errorText: 'Enter value greater than $0 and less than $1,000,000,000.',
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_ForbiddenProjectsMSR',
        labelText:
          'Does the business sell or distribute any of the following items, products, or supplies? Check all that apply.',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AlcoholTobaccoElectronicCigaretteOrVaporProductsOrSupplies',
            labelText: 'Alcohol, tobacco, electronic cigarette, or vapor products or supplies',
          },
          {
            nameOfFormControl: 'AutoBoatOrUtilityVehiclesAndOrParts',
            labelText: 'Auto, boat, or utility vehicles and/or parts',
          },
          {
            nameOfFormControl: 'ChemicalsOrChemicalSupplies',
            labelText: 'Chemicals or chemical supplies',
          },
          {
            nameOfFormControl: 'FarmingOrGardeningSupplies',
            labelText: 'Farming or gardening supplies',
          },
          {
            nameOfFormControl: 'GunFirearmOrAmmunitionProductsOrSupplies',
            labelText: 'Gun, firearm, or ammunition products or supplies',
          },
          {
            nameOfFormControl: 'PharmaceuticalOrMedicalDevices',
            labelText: 'Pharmaceutical or medical devices',
          },
          {
            nameOfFormControl: 'HazardousMaterials',
            labelText: 'Hazardous materials',
          },
          {
            nameOfFormControl: 'HealthFoodHerbsVitaminsOrSupplements',
            labelText: 'Health food, herbs, vitamins, or supplements',
          },
          {
            nameOfFormControl: 'HerbicidesOrPesticides',
            labelText: 'Herbicides or pesticides',
          },
          {
            nameOfFormControl: 'PetOrPetSupplies',
            labelText: 'Pet or pet supplies',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'MANUFACTURER_SALES_REPRESENTATIVE'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business own or host any of the following? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_PrsnlTrnYogaInstrc',
        checkboxConfigs: [
          {
            nameOfFormControl: 'BoxingGym',
            labelText: 'Boxing gym',
          },
          {
            nameOfFormControl: 'GymnasticsStudio',
            labelText: 'Gymnastics studio',
          },
          {
            nameOfFormControl: 'MartialArtsStudio',
            labelText: 'Martial arts studio',
          },
          {
            nameOfFormControl: 'MudRacesOrChallengeCourses',
            labelText: 'Mud races or challenge courses',
          },
          {
            nameOfFormControl: 'SportOrRecreationalLeagues',
            labelText: 'Sport or recreational leagues',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PRESCRIBE_HERBS3'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_PhotSrvs',
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerialOrDronePhotography',
            labelText: 'Aerial or drone photography',
          },
          {
            nameOfFormControl: 'HomeOrPropertyInspections',
            labelText: 'Home or property inspections',
          },
          {
            nameOfFormControl: 'MedicalDeviceImaging',
            labelText: 'Medical device imaging',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'PHOTO_SERVICE'
    ),
    {
      labelText:
        "Are the business's consulting procedures and guidelines in compliance with OSHA and/or other government safety requirements?",
      primitive: 'RADIO',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_OSHACOMPLIANT,
      cobOnly: 'SAFETY_CONSULT',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    generatePlV4CheckboxGroupNode(
      {
        labelText:
          "Does the business or any of the business' clients work in any of the following areas or industries? Check all that apply.",
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS,
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerospaceOrAviation',
            labelText: 'Aerospace or aviation',
          },
          {
            nameOfFormControl: 'AmusementRidesPoolsOrPlaygrounds',
            labelText: 'Amusement rides, pools, or playgrounds',
          },
          {
            nameOfFormControl: 'BridgesDamsHarborsMinesPiersOrTunnels',
            labelText: 'Bridges, dams, harbors, mines, piers, or tunnels',
          },
          {
            nameOfFormControl: 'ConstructionSites',
            labelText: 'Construction sites',
          },
          {
            nameOfFormControl: 'Environmental',
            labelText: 'Environmental',
          },
          {
            nameOfFormControl: 'FoodOrDrugProcessing',
            labelText: 'Food or drug processing',
          },
          {
            nameOfFormControl: 'FoundationSheetingOrRetainingWalls',
            labelText: 'Foundation, sheeting, or retaining walls',
          },
          {
            nameOfFormControl: 'HazardousWaste',
            labelText: 'Hazardous waste',
          },
          {
            nameOfFormControl: 'Mining',
            labelText: 'Mining',
          },
          {
            nameOfFormControl: 'OilAndGas',
            labelText: 'Oil and gas',
          },
          {
            nameOfFormControl: 'Railroads',
            labelText: 'Railroads',
          },
          {
            nameOfFormControl: 'UndergroundStorageTanksOrUtilities',
            labelText: 'Underground storage tanks or utilities',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'SAFETY_CONSULT'
    ),
    generatePlV4CheckboxGroupNode(
      {
        labelText: 'Does the business provide any of the following services? Check all that apply.',
        nameOfFormControl:
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_CONSULTANTELIGIBILITYSAFETY,
        checkboxConfigs: [
          {
            nameOfFormControl: 'AerospaceConsultingOrAdvice',
            labelText: 'Aerospace consulting or advice',
          },
          {
            nameOfFormControl: 'ActuarialAdvice',
            labelText: 'Actuarial advice',
          },
          {
            nameOfFormControl: 'ArchitectureOrEngineeringAdvice',
            labelText: 'Architecture or engineering advice',
          },
          {
            nameOfFormControl: 'ConstructionManagementOrAdvice',
            labelText: 'Construction management or advice',
          },
          {
            nameOfFormControl: 'CreditCounseling',
            labelText: 'Credit counseling',
          },
          {
            nameOfFormControl: 'EnvironmentalConsultingOrAdvice',
            labelText: 'Environmental consulting or advice',
          },
          {
            nameOfFormControl: 'FinancingOrFinancialAuditing',
            labelText: 'Financing or financial auditing',
          },
          {
            nameOfFormControl: 'HomeOrBuildingInspections',
            labelText: 'Home or building inspections',
          },
          {
            nameOfFormControl: 'InvestmentOrTaxAdvice',
            labelText: 'Investment or tax advice',
          },
          {
            nameOfFormControl: 'InsurancePlacementOrAdvice',
            labelText: 'Insurance placement or advice',
          },
          {
            nameOfFormControl: 'LandAcquisitionAdvice',
            labelText: 'Land acquisition advice',
          },
          {
            nameOfFormControl: 'LawEnforcementTraining',
            labelText: 'Law enforcement training',
          },
          {
            nameOfFormControl: 'LegalAdvice',
            labelText: 'Legal advice',
          },
          {
            nameOfFormControl: 'LobbyingAndOrPoliticalAdvice',
            labelText: 'Lobbying and/or political advice',
          },
          {
            nameOfFormControl: 'MedicalAdvice',
            labelText: 'Medical advice',
          },
          {
            nameOfFormControl: 'MergersAndAcquisitionsOrBusinessValuations',
            labelText: 'Mergers and acquisitions or business valuations',
          },
          {
            nameOfFormControl: 'MiningConsultingOrAdvice',
            labelText: 'Mining consulting  or advice',
          },
          {
            nameOfFormControl: 'OilGasOrPetroleumConsultingOrAdvice',
            labelText: 'Oil, gas, or petroleum consulting or advice',
          },
          {
            nameOfFormControl: 'SalesRepresentative',
            labelText: 'Sales representative',
          },
          {
            nameOfFormControl: 'SecurityGuardServicesOrPersonnel',
            labelText: 'Security guard services or personnel',
          },
          {
            nameOfFormControl: 'NoneOfTheAbove',
            labelText: 'None of the above',
          },
        ],
      },
      [HISCOX_API_VERSION.v4],
      'SAFETY_CONSULT'
    ),
    {
      labelText: 'Does the business provide any bookkeeping services?',
      primitive: 'RADIO',
      inputId: 'BookkeepingSrvs',
      cobOnly: 'TAX_PREP',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText:
        'Does the business provide any medical or healthcare related translating or interpreting services?',
      primitive: 'RADIO',
      inputId: 'TranSrvs',
      cobOnly: 'TRANSLATION',
      conditional: 'None',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      labelText: 'Does the business provide replacement cost valuation services?',
      conditional: 'None',
      primitive: 'RADIO',
      inputId: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_ReplacementCostServices',
      cobOnly: 'COST_VALUATION',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_REPLACEMENTCOSTSERVICES,
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'COST_VALUATION',
      conditionalChildren: [
        {
          inputId:
            HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_REPLACEMENTCOSTMAX,
          primitive: 'MONEY_WITHOUT_DECIMAL',
          inputType: 'text',
          versions: [HISCOX_API_VERSION.v4],
          cobOnly: 'COST_VALUATION',
          conditional: 'None',
          options: {},
          labelText:
            'What is the maximum replacement cost value for any home or building inspected?',
          required: true,
          errorText: 'Enter value greater than $0 and less than $1,000,000,000.',
        },
      ],
    },
    {
      labelText: 'Does the business provide any property management services?',
      conditional: 'None',
      primitive: 'RADIO',
      inputId: 'ProductQuoteRqs_ApplicationRatingInfo_REPropManServices',
      cobOnly: 'REALESTATE_AGENT',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ApplicationRatingInfo_REPropManServices',
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'REALESTATE_AGENT',
      conditionalChildren: [
        generatePlV4CheckboxGroupNode(
          {
            labelText:
              'Does your business provide any of the following services? Check all that apply.',
            nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices1',
            checkboxConfigs: [
              {
                nameOfFormControl: 'ConstructionOrRemodeling',
                labelText: 'Construction or remodeling',
              },
              {
                nameOfFormControl: 'GeneralContracting',
                labelText: 'General contracting',
              },
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'JanitorialOrCleaning',
                labelText: 'Janitorial or cleaning',
              },
              {
                nameOfFormControl: 'LandscapingOrLawnCare',
                labelText: 'Landscaping or lawn care',
              },
              {
                nameOfFormControl: 'MortgageBankerOrBroker',
                labelText: 'Mortgage banker/broker',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder.',
              },
              {
                nameOfFormControl: 'PropertyPreservation',
                labelText: 'Property preservation',
              },
              {
                nameOfFormControl: 'RealEstateAppraiser',
                labelText:
                  'Real estate appraiser (for loan valuations, does not apply to brokerage service)',
              },
              {
                nameOfFormControl: 'TitleAgentOrAbstractor',
                labelText: 'Title agent/abstractor',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          },
          [HISCOX_API_VERSION.v4],
          'REALESTATE_AGENT'
        ),
      ],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: 'ProductQuoteRqs_ApplicationRatingInfo_REPropManServices',
      enableValue: 'No',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'REALESTATE_AGENT',
      conditionalChildren: [
        generatePlV4CheckboxGroupNode(
          {
            labelText:
              'Does the business provide any of the following services? Check all that apply.',
            nameOfFormControl: 'ProductQuoteRqs_ApplicationRatingInfo_RealEstateServices2',
            checkboxConfigs: [
              {
                nameOfFormControl: 'HomeInspector',
                labelText: 'Home inspector',
              },
              {
                nameOfFormControl: 'InsuranceAgentOrBroker',
                labelText: 'Insurance agent/broker',
              },
              {
                nameOfFormControl: 'MortgageBankerOrBroker',
                labelText: 'Mortgage banker/broker',
              },
              {
                nameOfFormControl: 'PropertyDeveloperOrBuilder',
                labelText: 'Property developer or builder.',
              },
              {
                nameOfFormControl: 'RealEstateAppraiser',
                labelText:
                  'Real estate appraiser (for loan valuations, does not apply to brokerage service)',
              },
              {
                nameOfFormControl: 'TitleAgentOrAbstractor',
                labelText: 'Title agent/abstractor',
              },
              {
                nameOfFormControl: 'NoneOfTheAbove',
                labelText: 'None of the above',
              },
            ],
          },
          [HISCOX_API_VERSION.v4],
          'REALESTATE_AGENT'
        ),
      ],
    },
    {
      primitive: 'RADIO',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID,
      cobOnly: 'ALL',
      options: {
        Include: 'WaiverOfSubrogation',
        'Do not include': REMOVE_FROM_PAYLOAD,
      },
      labelText: 'Waiver of Subrogation',
      required: false,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'RADIO',
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ,
      cobOnly: 'CONTINGENT_BODILY_INJURY_AND_PROPERTY_DAMAGE',
      options: {
        Include: 'Include',
        'Do not include': REMOVE_FROM_PAYLOAD,
      },
      labelText: 'Contingent Bodily Injury and Property Damage',
      required: true,
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ,
      enableValue: 'Include',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'CONTINGENT_BODILY_INJURY_AND_PROPERTY_DAMAGE',
      conditionalChildren: [
        {
          inputId:
            HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ_RATINGINFO_LOI,
          primitive: 'SELECT',
          inputType: 'text',
          cobOnly: 'CONTINGENT_BODILY_INJURY_AND_PROPERTY_DAMAGE',
          conditional: 'None',
          options: {
            '50000': '$50,000',
            '100000': '$100,000',
            '200000': '$200,000',
          },
          labelText: 'Coverage Limit',
          subLabelText:
            'This quote will include optional coverage for contingent bodily injury and property damage as described here for an annual amount of the selected value',
          required: true,
          cssClass: 'sub-question',
          versions: [HISCOX_API_VERSION.v4],
        },
      ],
    },
    {
      primitive: 'RADIO',
      inputId: CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY,
      nameOfFormControl: CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY,
      labelText: 'Include Coalition Cyber Standalone Policy?',
      options: {
        Yes: 'Yes',
        No: 'No',
      },
      cobOnly: 'ALL',
      versions: [HISCOX_API_VERSION.v4],
    },
    {
      primitive: 'VALUE-CONDITIONAL',
      dependsOn: CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY,
      enableValue: 'Yes',
      versions: [HISCOX_API_VERSION.v4],
      cobOnly: 'ALL',
      cssClass: 'form-subsection',
      conditionalChildren: [
        // NAICS_CODE_IS_INELIGIBLE is only used for managing display logic of the industry control,
        // and is not rendered in the flow.
        {
          primitive: 'TEXT',
          inputId: CyberUpsellFormLogicControlName.NAICS_CODE_IS_INELIGIBLE,
          labelText: '',
          versions: [HISCOX_API_VERSION.v4],
          cssClass: 'hx-hide',
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn: CyberUpsellFormLogicControlName.NAICS_CODE_IS_INELIGIBLE,
          enableValue: true,
          versions: [HISCOX_API_VERSION.v4],
          conditionalChildren: [
            {
              primitive: 'DROPDOWN_SEARCH',
              inputId: CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
              nameOfFormControl: CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
              labelText: 'Description of business',
              queryMethodName: SearchQueryMethod.COALITION_CYBER_INDUSTRY,
              formatterName: SearchFormatter.COALITION_CYBER_INDUSTRY,
              required: true,
              versions: [HISCOX_API_VERSION.v4],
              cobOnly: 'ALL',
            },
          ],
        },
        // INDUSTRY_REQUIRES_TECH_EO and INDUSTRY_REQUIRES_NET_PROFIT are only used for
        // managing display logic of other controls, and are not rendered in the flow.
        {
          primitive: 'TEXT',
          inputId: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_TECH_EO,
          labelText: '',
          versions: [HISCOX_API_VERSION.v4],
          cssClass: 'hx-hide',
        },
        {
          primitive: 'TEXT',
          inputId: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_NET_PROFIT,
          labelText: '',
          versions: [HISCOX_API_VERSION.v4],
          cssClass: 'hx-hide',
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_TECH_EO,
          enableValue: true,
          versions: [HISCOX_API_VERSION.v4],
          cobOnly: 'ALL',
          conditionalChildren: [
            {
              primitive: 'RADIO',
              labelText:
                'Will the company have an active technology errors and omissions policy concurrent with this insurance policy?',
              versions: [HISCOX_API_VERSION.v4],
              inputId: CoalitionCyberQuestion.HAS_TECH_EO,
              nameOfFormControl: CoalitionCyberQuestion.HAS_TECH_EO,
              options: { Yes: 'Yes', No: 'No' },
              required: true,
              cobOnly: 'ALL',
            },
          ],
        },
        {
          primitive: 'VALUE-CONDITIONAL',
          dependsOn: CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_NET_PROFIT,
          enableValue: true,
          versions: [HISCOX_API_VERSION.v4],
          cobOnly: 'ALL',
          conditionalChildren: [
            {
              primitive: 'MONEY_WITHOUT_DECIMAL',
              versions: [HISCOX_API_VERSION.v4],
              inputId: CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
              labelText: 'Projected gross profit/net revenue over the next 12 months',
              required: true,
              cobOnly: 'ALL',
            },
          ],
        },
        {
          primitive: 'RADIO',
          versions: [HISCOX_API_VERSION.v4],
          labelText: 'Number of employees',
          inputId: CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT,
          options: COMPANY_EMPLOYEE_COUNT_OPTIONS,
          required: true,
          cobOnly: 'ALL',
        },
        {
          primitive: 'TRADITIONAL_RADIO',
          versions: [HISCOX_API_VERSION.v4],
          labelText: 'Limit and retention',
          inputId: CoalitionCyberQuestion.AGGREGATE_LIMIT,
          nameOfFormControl: CoalitionCyberQuestion.AGGREGATE_LIMIT,
          options: {
            '250k limit, 5k retention': MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
            '100k limit, 1k retention': ESSENTIAL_AGGREGATE_LIMIT_INDEX,
          },
          required: true,
          cobOnly: 'ALL',
        },
      ],
    },
    // Per occurrence limit - AR and ND only
    // Based on `Hiscox NOW Underwriting Manual API v1.6 (Mustang)`
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      nameOfFormControl:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      labelText: 'Per occurrence limit',
      cobOnly: 'ALL',
      statesExclude: _.without(ALL_STATES_ABBR, 'AR', 'ND'),
      primitive: 'SELECT',
      required: true,
      // All other COBs
      // including A&E COBs, accounting, home health aide, insurance agent/inspector,
      // personal care aide, manufacturer sales rep, safety consultant
      // Since $3mil+ requires underwriting review, and is not being included in the options
      options: {
        '1000000': '$1,000,000',
        '2000000': '$2,000,000',
      },
      versions: [HISCOX_API_VERSION.v4],
      cobOverrideSets: [
        // IT Consulting/Technology Services, Insurance Agents, Land Surveyors, Prop Mgmt
        [
          'IT_CONSULTING_AND_OTHER_SERVICES',
          {
            options: {
              '1000000': '$1,000,000',
            },
          },
        ],
      ],
    },
    // Per occurrence limit - CA
    // Based on `Hiscox NOW Underwriting Manual API v1.6 (Mustang)`
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      nameOfFormControl:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      labelText: 'Per occurrence limit',
      cobOnly: 'ALL',
      stateOnly: 'CA',
      primitive: 'SELECT',
      required: true,
      // All other COBs
      // including A&E COBs, accounting, home health aides, personal case aides, insurance inspectors
      // property management, real estate agents/brokers
      // Since $3mil+ requires underwriting review, and is not being included in the options
      options: {
        '250000': '$250,000',
        '500000': '$500,000',
        '750000': '$750,000',
        '1000000': '$1,000,000',
        '2000000': '$2,000,000',
      },
      versions: [HISCOX_API_VERSION.v4],
      cobOverrideSets: [
        // IT Consulting/Technology Services, Insurance Agents, Land Surveyors, Prop Mgmt
        [
          'IT_CONSULTING_AND_OTHER_SERVICES',
          {
            options: {
              '250000': '$250,000',
              '500000': '$500,000',
              '1000000': '$1,000,000',
            },
          },
        ],
      ],
    },
    // Per occurrence limit - IL
    // Based on `Hiscox NOW Underwriting Manual API v1.6 (Mustang)`
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      nameOfFormControl:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      labelText: 'Per occurrence limit',
      cobOnly: 'ALL',
      stateOnly: 'IL',
      primitive: 'SELECT',
      required: true,
      // All other COBs
      // inclusing A&E COBs, accounting, home health aides, personal care aides, insurance inspectors
      // since $3mil+ requires underwriting review, and is not being included in the options
      options: {
        '250000': '$250,000',
        '500000': '$500,000',
        '750000': '$750,000',
        '1000000': '$1,000,000',
        '2000000': '$2,000,000',
      },
      versions: [HISCOX_API_VERSION.v4],
      cobOverrideSets: [
        // IT Consulting/Technology Services, Insurance Agent, Land Surveyors, Prop Mgmt
        [
          'IT_CONSULTING_AND_OTHER_SERVICES',
          {
            options: {
              '250000': '$250,000',
              '500000': '$500,000',
              '750000': '$750,000',
              '1000000': '$1,000,000',
            },
          },
        ],
      ],
    },
    // Per occurrence limit - NY
    // Based on `Hiscox NOW Underwriting Manual API v1.6 (Mustang)`
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      nameOfFormControl:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      labelText: 'Per occurrence limit',
      cobOnly: 'ALL',
      stateOnly: 'NY',
      primitive: 'SELECT',
      required: true,
      // All other COBs
      // including A&E COBs, accounting, home health aides, personal care aides, insurance inspector,
      // safety consultants, manufacturer sales reps.
      // Since $3mil+ requires underwriting review, and is not being included in the options
      options: {
        '500000': '$500,000',
        '750000': '$750,000',
        '1000000': '$1,000,000',
        '2000000': '$2,000,000',
      },
      versions: [HISCOX_API_VERSION.v4],
      cobOverrideSets: [
        // IT Consulting/Technology Services, Prop Mgmt, Real estate agents/brokers, Land Surveyor
        [
          'IT_CONSULTING_AND_OTHER_SERVICES_NY',
          {
            options: {
              '500000': '$500,000',
              '1000000': '$1,000,000',
            },
          },
        ],
      ],
    },
    // Per occurrence limit - all states, except NY, IL, CA, AR, ND
    // Based on `Hiscox NOW Underwriting Manual API v1.6 (Mustang)`
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      nameOfFormControl:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
      labelText: 'Per occurrence limit',
      cobOnly: 'ALL',
      statesExclude: ['NY', 'IL', 'CA', 'AR', 'ND'],
      primitive: 'SELECT',
      required: true,
      // All COBs
      // All options are shown here for all other states
      // Since $3mil+ requires underwriting review, and is not being included in the options
      options: {
        '250000': '$250,000',
        '500000': '$500,000',
        '750000': '$750,000',
        '1000000': '$1,000,000',
        '2000000': '$2,000,000',
      },
      versions: [HISCOX_API_VERSION.v4],
      cobOverrideSets: [
        // IT Consulting/Technology Services, Insurance Agents, Land Surveyors, Prop Mgmt
        [
          'IT_CONSULTING_AND_OTHER_SERVICES',
          {
            options: {
              '250000': '$250,000',
              '500000': '$500,000',
              '750000': '$750,000',
              '1000000': '$1,000,000',
            },
          },
        ],
      ],
    },
    {
      inputId:
        HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE,
      primitive: 'MONEY_WITHOUT_DECIMAL',
      labelText:
        'For the next 12 months, what is the business’s expected revenue? Total revenue includes all sales, commissions and other sources of revenue the business receives.',
      required: true,
      cobOnly: 'ESTMTD_ANNUAL_REVENUE',
      versions: [HISCOX_API_VERSION.v4],
    },
  ];
};

export const keyFunc = (node: any) => {
  const key = _.get(
    node,
    'inputId',
    _.get(
      node,
      'dependsOn',
      _.get(
        node,
        'children[0].inputId',
        _.get(node, 'children[1].dependsOn', _.get(node, 'children[1].inputId', 'noKey'))
      )
    )
  );
  return key;
};

const SHORT_V4_PL_ORDERED_FIELDS = _.map(PL_ORDERED_FIELDS_V4, (fname) => {
  return fname.replace('ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_', '');
});

const plV4ConfigRaw = _.filter(plConfigRaw(), (node: PlConfigNode) => {
  return node.versions.includes(HISCOX_API_VERSION.v4);
});

const plV4ConfigRawOrdered: PlConfigNode[] = _.sortBy(plV4ConfigRaw, (node: any) =>
  SHORT_V4_PL_ORDERED_FIELDS.indexOf(keyFunc(node))
);

// setStateOnly('InsuranceDecline', 'MO', 'conditionalChildren[0].inputId');
// TODO: setStateOnly("CertifiedInGeorgia", "GA", "conditionalChildren[0].inputId");

// the following two fields are called EODisciplinAction... in the UI
// spec, their field names should be as follows according to the XSD
// 'DisciplinaryAction',
// 'DisciplinaryActionNonAcceptReason',

// Questions showing at the end of quotes and shouldn’t
// ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_InsuranceDeclineNonAcceptReason
// ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_MergerAcquisitionsNonAcceptReasonboth

// check behavior of this
// fix state default, remove rest of defaults

// Adding a value to the ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_SimilarInsuranceCarrier causes an error (works when question shows retroactive date with COB = graphic design)

// Remove the prefill for zip code, insured email, street address, first name, last name (edited)

const removeKeys = [
  'AgreeDisagreeToStatements',
  'AgreeDisagreeStatements',
  'BusinessOwnershipStructure',

  //  'NumOfCargoVehicles', // BOP only, probably in XSD
  // 'CargoLimitOfLiability', // BOP only probably in XSD

  'CoverageStartDate',
  'EstmtdAnnualGrossSales',
  'StateRegion',
  'Licensing',
];

export const plV4BasicTree: FormDslNode[] = [
  {
    inputId: 'BusinessInfo_CommercialName',
    nameOfFormControl: 'BusinessInfo_CommercialName',
    primitive: 'TEXT',
    labelText: 'Business name',
    cssClass: 'dropdown-search hidden',
    value: 'Replaced in HiscoxPlQuotePageComponent',
    required: true,
  },
  {
    primitive: 'DATE',
    inputId: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_CoverageStartDate',
    nameOfFormControl: 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_CoverageStartDate',
    labelText: 'Policy effective date',
    errorText: 'Effective date must be between today and 60 days in the future',
    required: true,
  },
  {
    primitive: 'SELECT',
    inputId: 'BusinessInfo_ClassOfBusinessCd',
    nameOfFormControl: 'BusinessInfo_ClassOfBusinessCd',
    labelText: 'Class of business',
    options: sortDictionaryByValues(PL_BUSINESS_CLASSES_V4),
    required: true,
  },
  // V4 version
  {
    primitive: 'SELECT',
    inputId: 'BusinessInfo_BusinessOwnershipStructure',
    nameOfFormControl: 'BusinessInfo_BusinessOwnershipStructure',
    labelText: 'Business ownership structure',
    options: {
      Corporation: 'Corporation',
      Estate: 'Estate',
      'Joint Venture': 'Joint Venture',
      'Limited Liability Company': 'Limited Liability Company',
      'Not for profit Corporation': 'Not for profit Corporation',
      Partnership: 'Partnership',
      'Professional Association': 'Professional Association',
      'Sole Proprietor': 'Sole Proprietor',
      Trust: 'Trust',
      Other: 'Other',
    },
    required: true,
  },
  {
    primitive: 'ADDRESS_AUTO_COMPLETE',
    inputId: 'BusinessInfo_MailingAddress_AddrInfo_Addr1',
    nameOfFormControl: 'BusinessInfo_MailingAddress_AddrInfo_Addr1',
    labelText: 'Street address',
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: 'BusinessInfo_MailingAddress_AddrInfo_Addr2',
    nameOfFormControl: 'BusinessInfo_MailingAddress_AddrInfo_Addr2',
    labelText: 'Street address 2',
  },
  {
    primitive: 'TEXT',
    inputId: 'BusinessInfo_MailingAddress_AddrInfo_City',
    nameOfFormControl: 'BusinessInfo_MailingAddress_AddrInfo_City',
    labelText: 'City',
    required: true,
  },
  {
    primitive: 'SELECT',
    inputId: 'BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd',
    nameOfFormControl: 'BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd',
    labelText: 'State',
    options: usStates,
    readonly: true,
    required: true,
  },
  {
    primitive: 'NUMBER',
    inputId: 'BusinessInfo_MailingAddress_AddrInfo_PostalCode',
    nameOfFormControl: 'BusinessInfo_MailingAddress_AddrInfo_PostalCode',
    labelText: 'Zip code',
    tooltipText:
      'Hiscox validates addresses via USPS. This sometimes results in an error as Google may provide an incorrect zip code. To prevent this, try validating the address through the quick tools option on the USPS website.',
    maxLength: '5',
    minLength: '5',
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: 'BusinessInfo_MailingAddress_AddrInfo_County',
    nameOfFormControl: 'BusinessInfo_MailingAddress_AddrInfo_County',
    labelText: 'Business County:',
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: 'BusinessInfo_Person_Name_FirstName',
    nameOfFormControl: 'BusinessInfo_Person_Name_FirstName',
    labelText: "Business owner's first name",
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: 'BusinessInfo_Person_Name_LastName',
    nameOfFormControl: 'BusinessInfo_Person_Name_LastName',
    labelText: "Business owner's last name",
    required: true,
  },
  {
    primitive: 'TEXT',
    inputId: 'BusinessInfo_Person_CommunicationsInfo_EmailInfo_EmailAddr',
    nameOfFormControl: 'BusinessInfo_Person_CommunicationsInfo_EmailInfo_EmailAddr',
    labelText: "Insured's email address",
    required: true,
  },
  {
    primitive: 'PHONE',
    inputId: 'BusinessInfo_Person_CommunicationsInfo_PhoneInfo_PhoneNumber',
    nameOfFormControl: 'BusinessInfo_Person_CommunicationsInfo_PhoneInfo_PhoneNumber',
    labelText: "Insured's phone number",
    maxLength: '12',
    minLength: '12',
    required: true,
  },
  {
    inputId: 'BusinessInfo_HaveDBAorFKA',
    nameOfFormControl: 'BusinessInfo_HaveDBAorFKA',
    labelText: 'Do you have a DBA or FKA business name?',
    primitive: 'RADIO',
    inputType: 'text',
    options: {
      'DBA (Doing Business As)': 'DBA',
      'FKA (Formerly Known As)': 'FKA',
    },
    required: false,
  },
  {
    inputId: 'BusinessInfo_DBAorFKAName',
    nameOfFormControl: 'BusinessInfo_DBAorFKAName',
    primitive: 'TEXT',
    inputType: 'text',
    labelText: 'DBA/FKA name:',
    required: false,
  },
];

// default field values
const plFieldDefaults: { [inputId: string]: any } = {
  [CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY]: 'Yes',
};

/*
  This data structure can be used to set validators on each specific HiscoxPlFormDataFieldV4.

  example:

  [HiscoxPlFormDataFieldV4.FIELD_NAME]: [
    validateCheckboxGroupNode,
    Validators.required
  ]
*/
const fieldValidatorsCustomV4: { [inputId: string]: Function[] } = {
  [HiscoxFormStepPath.QUOTE_BASIC]: [
    classOfBusinessExceptionsByStateValidator(HISCOX_PRODUCTS.pl),
    zipMatchesStateValidator(),
  ],
  [HiscoxFormStepPath.QUOTE_RATING]: [
    validateLargestProjectValueIsSmallerOrEqualToTotalRevenue(),
    validateLargestCustomerValueIsSmallerOrEqualToTotalCommissions(),
    validateLargestCustomer1ValueIsSmallerOrEqualToTotalRevenue(),
    validateSubcontractProfSrvcsAtLeastOneSubQuestionSelectedYes(),
    validateProfesssionalLiabilityRetroactiveDateAgainstBusinessStartDate,
    validateAtLeastOneYesInsurancePlacementSrvsAnswer,
    validateNumericQuestionAggregates([
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSLIFE,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSCL,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSPL,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSOTHER,
    ]),
  ],
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSLIFE]:
    [validatePercentLessThanOrEqualToOneHundred],
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSCL]:
    [validatePercentLessThanOrEqualToOneHundred],
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSPL]:
    [validatePercentLessThanOrEqualToOneHundred],
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVSOTHER]:
    [validatePercentLessThanOrEqualToOneHundred],
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_REALESTATEDUALAGENCYCOMMISSIONPCT]:
    [validatePercentLessThanOrEqualToOneHundred],
};

const optionalPlFields: HiscoxPlFormDataFieldV4[] = [
  HiscoxPlFormDataFieldV4.BUSINESSINFO_HAVEDBAORFKA,
  HiscoxPlFormDataFieldV4.BUSINESSINFO_DBAORFKANAME,
  HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID,
];

// fields validators for v4 quote requests
const plV4FieldValidators: { [inputId: string]: any[] } = _.zipObject(
  PL_ORDERED_FIELDS_V4,
  _.map(PL_ORDERED_FIELDS_V4, (key: string) => {
    let vals: Function[] = [];
    if (key in fieldValidatorsCustomV4) {
      vals = fieldValidatorsCustomV4[key];
    } else {
      if (!_.includes(optionalPlFields, key)) {
        vals = [Validators.required];
      }
    }
    return vals;
  })
);

export const getPlFieldDefaults = () => {
  // today's date
  const todayUs = getTodayUs();

  return {
    ...plFieldDefaults,
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERAGESTARTDATE]:
      todayUs,
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_WAIVEROFSUBROGATIONCOVERQUOTERQ_COVERID]:
      REMOVE_FROM_PAYLOAD,
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_CONTINGENTBODILYINJURYCOVERQUOTERQ]:
      REMOVE_FROM_PAYLOAD,
  };
};

export const getPlFieldValidators = () => {
  // today's date
  const today = getToday();
  // latest allowable value for coverage start date
  const maxEffectiveDateMoment = getLatestEffectiveDateMoment();
  // earliest allowable date for professional experience and retroactive date
  const oneHundredYearsAgo = getOneHundredYearsAgo();

  const validatorDictionary: Partial<
    Record<HiscoxFormStepPath | HiscoxPlFormDataFieldV4, ValidatorFn[]>
  > = {
    ...plV4FieldValidators,
    ...cyberUpsellValidators,
    BusinessInfo_MailingAddress_AddrInfo_PostalCode: [zipCodeValidator, Validators.required],
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_EMAILINFO_EMAILADDR]: [
      validateEmailAddress,
      Validators.email,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.BUSINESSINFO_PERSON_COMMUNICATIONSINFO_PHONEINFO_PHONENUMBER]: [
      phoneValidator,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE]: [
      maxDateExceededValidator(today),
      minDateExceededValidator(oneHundredYearsAgo),
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANECONSTRUCTIONSRVCS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_EVENTPLANNINGSRVCS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_ANEFORBIDDENPROJECTS1]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS3]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTS5]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES1]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRPTYMGRSERVICES2]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES1]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_REALESTATESERVICES2]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_TRAININGELIGIBILITY1]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_BEAUTYSERVICES2PL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_BUSINESSAUCTION]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_FORBIDDENPROJECTSMSR]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PRSNLTRNYOGAINSTRC]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SAFETYFORBIDDENPROJECTS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_BUSNACTYLIFEEXECOCH]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ACUPRESSUREBUSNSACTIVITY]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_BUSMGRSRVS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_CLAIMSSRVS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_CONSULTANTELIGIBILITYPL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_DOCPREPELIGIBILITY]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_EXECUTIVESRVS]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_FINANCIALSERVICES]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_FORBIDDENPROJECTRETAIL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_HOMEHEALTHFORBIDDENSRVCS]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEAGENTADVICE]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEAGENTCAPACITIES]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEAGENTSERVICES]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ISBOOKKEEPING]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ISESTHETICIAN]:
      [
        validateCheckboxGroupNode,
        validateRequiredCheckboxOption('Esthetician', 'Esthetician'),
        Validators.required,
      ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ISNAILTECHNICIAN]:
      [
        validateCheckboxGroupNode,
        validateRequiredCheckboxOption('NailTechnician', 'Nail Technician'),
        Validators.required,
      ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ITSPCLSTACTVTY]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ITTECHSERVICES]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_MARKETINGELIGIBILITYPL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_NUTRITIONDIRECTOR]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PCELIGIBILITYPL]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PHOTSRVS]: [
      validateCheckboxGroupNode,
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS1]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS2]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS3]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS4]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS5]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PRESCRIBEHERBS6]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_RESEARCHCONSULTINGELIGIBILITY]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_SHOCKTHERAPY]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_TRAVELSERVICES]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_TYPEOFWEBSITE]:
      [validateCheckboxGroupNode, Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ISBARBER]: [
      validateCheckboxGroupNode,
      validateRequiredCheckboxOption('BarberHairStylist', 'Barber/Hair Stylist'),
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ISBEAUTICIAN]:
      [
        validateCheckboxGroupNode,
        validateRequiredCheckboxOption('BeauticianCosmotologist', 'Beautician/Cosmetologist'),
        Validators.required,
      ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERAGESTARTDATE]: [
      maxDateExceededValidator(maxEffectiveDateMoment),
      minDateExceededValidator(today, 'day'),
      Validators.required,
    ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR]:
      [
        rangeValidator(1.0, 1000000000),
        validateEstSalesLargestCustmrNotGreaterThanAnnualRevenue,
        Validators.required,
      ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR1]:
      [
        rangeValidator(1.0, 1000000000),
        validateEstSalesLargestCustmr1NotGreaterThanAnnualRevenue,
        Validators.required,
      ],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTPROJECT]:
      [rangeValidator(1.0, 1000000000), Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI]:
      [Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS]:
      [rangeValidator(1.0, 1000000000), Validators.required],
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE]:
      [rangeValidator(1.0, 1000000000), Validators.required],
  };
  PL_V4_FIELDS_WITH_NONE_OF_THE_ABOVE_OPTION.forEach((field: HiscoxPlFormDataFieldV4) => {
    const validators = validatorDictionary[field];
    if (validators) {
      validatorDictionary[field] = [
        validateExclusiveCheckboxOption('NoneOfTheAbove'),
        ...validators,
      ];
    } else {
      validatorDictionary[field] = [validateExclusiveCheckboxOption('NoneOfTheAbove')];
    }
  });
  return validatorDictionary;
};

/**
 * Used for validation between different form steps. This function also gets the control on initialization
 *
 * NOTE: These validators are only triggered when the control of the key field is updated. So if the
 * validator relies on values of other controls then those controls will be updated without triggering
 * the validator to revalidate. These validators should only use controls of previous steps to avoid this
 * error.
 * SEE CARP-3182 for more info on this limitation.
 */
export const plV4ComplexValidators: Partial<ComplexValidatorDictionary<HiscoxPlFormDataFieldV4>> = {
  [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PROFESSIONALLIABILITYRETROACTIVEDATE]:
    [validateProfesssionalLiabilityRetroactiveDateAgainstEffectiveDate],
};

export const plConfigV4: PlConfigNode[] = _.filter(plV4ConfigRawOrdered, (node: PlConfigNode) => {
  return removeKeys.indexOf(keyFunc(node)) === -1;
});
