<div *ngIf="eblcEnabled()">
  <app-form-field-radio
    inputId="quote-employeeBenefitsLiabilityCoverage"
    [form]="formSubject"
    [submitted]="submitted"
    questionText="Include Employee Benefits Liability coverage?"
    nameOfFormControl="optedIn"
    tooltipText="Employee Benefits Liability (EBL) insurance covers administrative errors
    and omissions such as failing to enroll an employee in a group health plan, or providing
    wrongful advice on the selection of an offered program."
  >
  </app-form-field-radio>

  <div class="form-subsection" *ngIf="form.value.employeeBenefitsLiabilityCoverage.optedIn">
    <app-form-field-text
      inputId="quote-employeeBenefitsLiabilityCoverage-retroactiveDate"
      [form]="formSubject"
      [submitted]="submitted"
      labelText="Retroactive Date"
      nameOfFormControl="retroactiveDate"
      appDateMask
    >
    </app-form-field-text>

    <app-form-field-select
      inputId="quote-employeeBenefitsLiabilityCoverage-eachEmployeeLimit"
      [form]="formSubject"
      [submitted]="submitted"
      labelText="Each Employee Limit"
      nameOfFormControl="eachEmployeeLimit"
      [availableOptions]="availableEachEmployeeLimitsForEmployeeLiability"
    >
    </app-form-field-select>

    <app-form-field-radio
      *ngIf="
        parseEmployeeLimitToInt(formSubject.get('eachEmployeeLimit').value) === baseEmployeeLimit
      "
      inputId="quote-employeeBenefitsLiabilityCoverage-aggregateLimit"
      [form]="formSubject"
      [submitted]="submitted"
      questionText="Aggregate Limit"
      nameOfFormControl="aggregateLimit"
      [options]="availableAggregateLimitsForEmployeeLiability"
    >
    </app-form-field-radio>

    <div
      class="form-field"
      *ngIf="
        parseEmployeeLimitToInt(formSubject.get('eachEmployeeLimit').value) !== baseEmployeeLimit
      "
    >
      <label for="quote-employeeBenefitsLiabilityCoverage-aggregateLimit">Aggregate Limit</label>
      <input
        id="quote-employeeBenefitsLiabilityCoverage-aggregateLimit"
        disabled="true"
        [value]="formSubject.get('eachEmployeeLimit').value | currency: 'USD':'symbol':'1.0-0'"
      />
    </div>

    <app-form-field-radio
      inputId="quote-employeeBenefitsLiabilityCoverage-deductible"
      [form]="formSubject"
      [submitted]="submitted"
      questionText="Deductible"
      nameOfFormControl="deductible"
      cssClass="form-field__no-margin"
      [options]="availableDeductiblesForEmployeeLiability"
    >
    </app-form-field-radio>
  </div>
</div>
