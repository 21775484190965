import { Subscription, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getControl } from 'app/shared/helpers/form-helpers';

import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import * as _ from 'lodash';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { scrollToTop } from 'app/shared/helpers/scroll-helpers';
import { InformService } from 'app/core/services/inform.service';

import { FormDslTestFormService } from 'app/shared/form-dsl-test/form-dsl-test-form.service';
import { FORM_CONFIG } from 'app/shared/form-dsl-test/form-dsl-test-data';

@Component({
  selector: 'app-form-dsl-test',
  templateUrl: './form-dsl-test.component.html',
  providers: [FormDslTestFormService],
})
export class FormDslTestComponent implements OnInit, OnDestroy {
  currentStep: RouteFormStep;
  isDevMode = isDevMode();
  quotedSuccess$ = new Subject();
  formTree: any[];
  quoteForm: any = {};
  quoteRequest: any = {};
  quoteFormString: string;

  private sub: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private informService: InformService,
    private router: Router,
    public formService: FormDslTestFormService
  ) {}

  isFinalStep() {
    return this.formService.isFinalStep();
  }

  isFirstStep() {
    return this.formService.isFirstStep();
  }

  // TODO? These methods are the same as in the ConsumerBopFormComponent
  clickBackward() {
    if (this.formService.stepBackward()) {
      this.navigateToCurrentStep();
    }
  }

  handleSubmit(submitEvent?: Event): boolean {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    return this.formService.stepForward();
  }

  handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep, step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  submitted() {
    return this.formService.submitted;
  }

  // TODO: Break out the sections into components and use routes with args like in ConsumerBopFormComponent
  protected navigateToCurrentStep(): void {
    this.currentStep = this.formService.getCurrentStep();
    scrollToTop();
  }

  isCurrentStep(slug: string) {
    if (!this.currentStep) {
      return false;
    }
    const currentSlug = this.currentStep.slug;
    const slugRegex = new RegExp('^' + slug + '$');
    const result = currentSlug ? currentSlug.match(slugRegex) : false;
    return result;
  }

  isCurrentStepValid() {
    return this.formService.isCurrentStepValid();
  }

  onChange(): void {
    const formDict: any = {};
    this.formService.form.valueChanges.subscribe((fields) => {
      _.keys(fields).forEach((field, index) => {
        const value = getControl(this.formService.form, field).value;
        formDict[field] = value;
      });
    });
  }

  getFieldsOfStep(step: string) {
    return this.formService.form.value[step];
  }

  selectFormTree() {}

  submitForm() {
    const formData = this.getFieldsOfStep('quoteBasic');
    console.log(formData);
  }

  ngOnDestroy() {
    removeZendeskLeftSnap();
    this.sub.unsubscribe();
  }

  showPageRetryGrowl(retryMethod: Function) {
    this.informService.minorErrorToast(
      `We encountered an error while loading information about this quote. Please try refreshing the page.`,
      null,
      'Failed to retrieve quote.',
      'Retry',
      retryMethod,
      0
    );
  }

  ngOnInit() {
    zendeskLeftSnap();

    const config: string = this.route.snapshot.params['config'] as string;
    const formTree = FORM_CONFIG[config];

    this.formTree = formTree;
    this.formService.setFormTree(formTree);
    this.formService.initializeForm();
    this.formService.syncAllSteps();

    this.navigateToCurrentStep();

    this.sub.add(
      this.formService.incrementedStep$.subscribe(() => {
        this.navigateToCurrentStep();
      })
    );
  }
}
