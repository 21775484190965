export const hasNcciValidationError = (quoteErrors: unknown[]) => {
  const validationErrorMessages = ['restricted fein entered'];

  return quoteErrors.some((error) =>
    validationErrorMessages.some(
      (validationError) =>
        typeof error === 'string' && error.toLowerCase().includes(validationError.toLowerCase())
    )
  );
};

export const hasNcciMultipleRiskIdsError = (quoteErrors: unknown[]) => {
  const multipleRiskIdErrorMessage =
    'multiple risk ids returned for federal employer identification number';
  return quoteErrors.some((error) => {
    return (
      typeof error === 'string' &&
      error.toLowerCase().includes(multipleRiskIdErrorMessage.toLowerCase())
    );
  });
};
