<h2>Edit Payment Plan</h2>
<ng-container *ngIf="!isCancelledOrOverdue()">
  <ng-container *ngIf="isSelfServiceAvailable()">
    <p *ngIf="!isTargetingRenewalOrRollover" class="gray-text small-text">
      Please note changes will affect all billed and future invoices.
    </p>
    <p *ngIf="isTargetingRenewalOrRollover" class="gray-text text__warning">
      Please note changes will affect your upcoming policy period.
    </p>
    <h3>Policy:</h3>
    <ng-container *ngFor="let policyDesc of policyRecord | keys">
      <p class="js-payment-plan-policy-description">{{ policyDesc }}</p>
    </ng-container>
    <form [formGroup]="paymentPlanForm" class="payment-plan-form">
      <app-form-field-radio-traditional
        class="payment-plan-radio"
        inputId="payment-plan-option"
        [form]="paymentPlanForm"
        nameOfFormControl="newPaymentPlan"
        [options]="planMap"
        [optionDescriptions]="generateLabelText()"
        [defaultDisabledKey]="currentPaymentPlan"
        [showLabelWithoutValue]="true"
      ></app-form-field-radio-traditional>
    </form>
    <p
      *ngIf="errorMessage"
      class="dialog-text dialog-text__full-width dialog-text__error js-error-message"
    >
      {{ errorMessage }}
    </p>
    <ng-container
      *ngIf="
        !paymentPlanForm.get('newPaymentPlan').dirty ||
        paymentPlanForm.get('newPaymentPlan').value !== 'FullPay'
      "
    >
      <button
        [disabled]="isProcessing"
        (click)="handlePaymentPlanFlow()"
        data-amplitude-input-name="payment-plan-self-service"
        type="submit"
        class="button button__primary button__full-width js-payment-plan-submit-button"
      >
        <app-loading-spinner
          *ngIf="isProcessing"
          class="payment-plan-saving-spinner"
        ></app-loading-spinner>
        {{ isProcessing ? 'Saving...' : 'Save changes' }}
      </button>
    </ng-container>
    <ng-container
      *ngIf="
        paymentPlanForm.get('newPaymentPlan').dirty &&
        paymentPlanForm.get('newPaymentPlan').value === 'FullPay'
      "
    >
      <button
        [disabled]="isProcessing"
        (click)="openStripePaymentModal()"
        data-amplitude-input-name="payment-plan-self-service"
        type="submit"
        class="button button__primary button__full-width js-make-payment-plan-button"
      >
        Make Payment
      </button>
    </ng-container>
    <button
      [disabled]="isProcessing"
      class="button button__secondary button__full-width"
      (click)="closePaymentPlanModal.emit()"
    >
      Cancel
    </button>
  </ng-container>

  <ng-container *ngIf="!isSelfServiceAvailable()">
    <p
      class="
        dialog-text dialog-text__full-width dialog-text__warning
        js-payment-plan-no-changes-ccc
      "
    >
      To change your payment plan, please contact our customer care team
      <a
        [routerLink]="generateHelpCenterUrl()"
        target="_blank"
        id="payment-plan-change-support-link"
        >here</a
      >.
    </p>
  </ng-container>
</ng-container>

<ng-container *ngIf="isCancelledOrOverdue()">
  <p class="dialog-text dialog-text__full-width dialog-text__error">
    Delinquent and canceled accounts do not have access to updating your payment plan.
  </p>
</ng-container>
