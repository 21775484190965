<app-liberty-mutual-quote-form-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__liberty-mutual"
  [product]="productType"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insuredAccount | async"
  sidebarTitle="Bind quote"
>
  >
</app-liberty-mutual-quote-form-sidebar>

<div class="app-page-form-scrollable-wrapper">
  <div class="app-page-form">
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>

    <router-outlet></router-outlet>

    <div class="nav-button-group nav-button-group__left">
      <form [formGroup]="form" (ngSubmit)="handleSubmit($event)" novalidate>
        <button
          *ngIf="!isFinalStep()"
          id="liberty-mutual-quote-form-next-button"
          [ngClass]="{
            button: true,
            'js-step-forward-button': true,
            button__discouraged: !this.isCurrentStepValid()
          }"
          type="submit"
          class="button button__primary js-step-forward-button"
          [disabled]="paymentIframeLoading"
        >
          <span *ngIf="!paymentIframeLoading">Next</span>
          <span *ngIf="paymentIframeLoading">Loading...</span>
        </button>
      </form>
    </div>
  </div>
</div>

<app-quote-error-modal
  [open]="openHydraErrorModal || openOnSubmitActionErrorModal"
  (closeQuoteErrorModal)="handleHydraResultModalClose($event)"
  [errorType]="LIBERTY_MUTUAL_HYDRA_ERROR"
>
</app-quote-error-modal>

<app-quote-error-modal
  [open]="openPaymentErrorModal"
  (closeQuoteErrorModal)="handleQuotePaymentResultModalClose($event)"
  [errorType]="getPaymentErrorType()"
>
</app-quote-error-modal>

<ng-container *ngIf="showProgressBar">
  <app-binding-animation [showProgressBar]="true" [finish$]="bindSuccess$"> </app-binding-animation>
</ng-container>

<app-quote-error-modal
  [open]="openBindErrorModal"
  (closeQuoteErrorModal)="handleQuoteBindResultModalClose($event)"
  [errors]="quoteBindErrors"
  [errorType]="getBindErrorType()"
>
  <ng-container *ngIf="!hasDisplayableQuoteBindErrors()">
    <p class="js-liberty-mutual-contact-team">
      We're having trouble issuing this policy. Please try again or contact the Customer Care team
      if you continue to have this issue.
    </p>
  </ng-container>
  <ng-container *ngIf="hasDisplayableQuoteBindErrors()">
    <p class="js-liberty-mutual-retry-quote-bind">
      We're having trouble issuing this policy. Please try again or contact the Customer Care team
      if you continue to have this issue.
    </p>
  </ng-container>
</app-quote-error-modal>

<app-quote-error-modal
  [open]="openCreditCardErrorModal"
  (closeQuoteErrorModal)="handleCreditCardResultModalClose($event)"
  [errorType]="getCreditCardErrorType()"
>
</app-quote-error-modal>
