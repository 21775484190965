import { Directive } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

export const sanitizePhoneNumber = (value: string) => {
  let digits = value.replace(/\D+/g, '').substr(0, 20);

  // Insert dashes after the first three and next three digits
  if (digits.length > 6) {
    digits = digits.slice(0, 6) + '-' + digits.slice(6);
  }

  if (digits.length > 3) {
    digits = digits.slice(0, 3) + '-' + digits.slice(3);
  }
  return digits;
};

@Directive({
  selector: '[appPhoneMask]',
})
export class PhoneMaskDirective extends BaseMaskDirective {
  getMask(value: string, event: KeyboardEvent) {
    if (value == null) {
      return '';
    }

    const digits = sanitizePhoneNumber(value);

    if (event && digits.length) {
      this.updateCursorPosition(<HTMLInputElement>event.target, digits);
    }

    return digits;
  }
}
