<div class="policy-pane-content">
  <div class="policy-pane-nav nav-button-group nav-button-group__space-between mb-4">
    <a class="button button__nav button__no-margin" [routerLink]="['../../..']">
      <span class="icon-arrow-left button-icon"></span>
      Back
    </a>
    <div *ngIf="quote && quote.status !== 'BOUND'">
      <a
        [routerLink]="'/accounts/' + accountId + '/workers-comp/edit'"
        class="button button__small button__secondary button__no-margin"
      >
        Edit
      </a>
      <button
        *ngIf="nonBindingRole"
        class="
          button button__small button__primary button__no-margin button__discouraged
          js-go-to-bind
        "
        (click)="handleErrorToast()"
        (keyup.enter)="handleErrorToast()"
      >
        Continue to bind
      </button>
      <a
        *ngIf="!nonBindingRole"
        [routerLink]="'/accounts/' + accountId + '/workers-comp/policies/' + quote.uuid + '/bind'"
        class="button button__small button__primary button__no-margin"
      >
        Bind
      </a>
    </div>
  </div>
  <ng-container *ngIf="!quote && !loading">
    <div class="placeholder-h1"></div>
    <ul>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
      <li class="placeholder-contact-item"></li>
    </ul>
    <div class="policy-pane-header">
      <h2>Error loading Workers' Comp policy...</h2>
      <small>Is this URL correct?</small>
    </div>
  </ng-container>

  <ng-container *ngIf="quote">
    <div class="policy-pane-header">
      <div class="policy-pane-header-left">
        <div class="product-badge product-badge__wc">
          <div class="product-badge-carrier-icon product-badge-carrier-icon__employers"></div>
        </div>
        <div class="policy-pane-quote-info">
          <h2 class="policy-pane-header-h2">Quote details</h2>
          <small class="gray-text">{{ quote.uuid }}</small>
        </div>
      </div>
      <div class="policy-pane-header-right" *ngIf="quote.premium">
        <h2 class="policy-pane-header-h2 js-premium-number">
          {{ quote.premium | currency }}
        </h2>
        <small class="gray-text">Premium</small>
      </div>
    </div>

    <div class="policy-detail-subsection">
      <ng-container>
        <dl>
          <dt>Status</dt>
          <dd><app-policy-status [policyStatus]="quote.status"></app-policy-status></dd>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{{ quote.effectiveDate ? quote.effectiveDate.format(US_DATE_MASK) : '—' }}</dd>
        </dl>
        <dl *ngIf="!!quote.policyNumber && quote.status === 'QUOTED'">
          <dt>Quote number</dt>
          <dd>{{ quote.policyNumber }}</dd>
        </dl>
      </ng-container>
    </div>
  </ng-container>

  <app-document-download
    *ngIf="quote && quote.isQuoted() && quote.hasQuoteLetter"
    displayName="Quote Letter"
    [documentDownload$]="quoteLetterDownload$"
    [documentReady$]="quoteLetterDownloadReady$"
  ></app-document-download>
  <div class="mt-4">
    <app-quote-servicing-information carrier="employers"></app-quote-servicing-information>
  </div>
</div>
