import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Components
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';

// Models
import {
  ExecutiveElectionFormGroup,
  ExecutiveElectionsFormGroup,
  LocationValue,
} from 'app/workers-comp/attune/models/quote-form.model';

// Services
import { AttuneWcQuoteFormService } from 'app/workers-comp/attune/services/attune-wc-quote-form.service';

// Constants
import { EXECUTIVE_ELECTION_ATTESTATION } from 'app/workers-comp/attune/constants';

@Component({
  selector: 'app-wc-executives-page',
  templateUrl: './attune-wc-executives-page.component.html',
})
export class AttuneWcExecutivesPageComponent
  extends SteppedFormPageComponent<AttuneWcQuoteFormService, ExecutiveElectionsFormGroup>
  implements OnInit, OnDestroy
{
  formPath: string = 'executiveElections';
  execElectionAttestation: string = EXECUTIVE_ELECTION_ATTESTATION;

  locationSelectOptions: {
    [locationNum: string]: string;
  } = {};

  sub: Subscription = new Subscription();

  constructor(
    protected formService: AttuneWcQuoteFormService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(formService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setLocationFieldOptions();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setLocationFieldOptions() {
    this.formService.locationsFormArray().controls.forEach((control) => {
      const locationValue = control.value as LocationValue;
      this.locationSelectOptions[locationValue.locationNum] =
        this.getPrettyLocAddress(locationValue);
    });
  }

  private getPrettyLocAddress(location: LocationValue) {
    const { addressLine1, city, state, zip } = location.address;
    return `${addressLine1}, ${city}, ${state} ${zip}`;
  }

  navigateToLocationForExec(execElectionFormGroup: ExecutiveElectionFormGroup) {
    const locationNum = execElectionFormGroup.value.locationNum;
    if (!locationNum) {
      throw new Error('Revise location payroll link unexpectedly shown before location selected');
    }
    const location = this.formService.getLocation(locationNum);
    if (!location) {
      throw new Error('Unable to find relevant location for revising payroll');
    }
    const routeStep = this.formService
      .getSteps()
      .find((step) => step.slug === `location/${locationNum}`);
    if (!routeStep) {
      throw new Error('Unable to find relevant location step');
    }
    this.formService.stepWithoutValidation(routeStep);
    this.router.navigate(['../location', locationNum], {
      relativeTo: this.route,
    });
  }

  /**
   *  Executive election methods
   */

  getTitleOptions(execElectionFormGroup: ExecutiveElectionFormGroup) {
    const state = this.getStateForExecutive(execElectionFormGroup);
    if (!state) {
      throw new Error(
        'WC executive title options cannot be determined before a locationNum/state is set.'
      );
    }
    return this.executiveTitleOptionsByState[state];
  }

  getExecutiveElections() {
    return this.formService.getExecutiveElectionsFormArray();
  }

  addExecutiveElection() {
    return this.formService.addExecutiveElection();
  }

  removeExecutiveElection(index: number) {
    return this.formService.removeExecutiveElection(index);
  }

  execTitleSelected(execElectionFormGroup: ExecutiveElectionFormGroup) {
    const title = execElectionFormGroup.get('title')?.value;
    return !!title;
  }

  locationSelected(execElectionFormGroup: ExecutiveElectionFormGroup) {
    const locationNum = execElectionFormGroup.get('locationNum')?.value;
    return !!locationNum;
  }

  get executiveTitleOptionsByState() {
    return this.formService.availableExecTitlesByState;
  }

  get showExecutiveElectionsWarning() {
    return this.formService.showExecutiveElectionsWarning;
  }

  private getStateForExecutive(execElectionFormGroup: ExecutiveElectionFormGroup): string | null {
    const locationNum = execElectionFormGroup.value.locationNum;
    if (!locationNum) {
      return null;
    }

    return this.formService.getLocationState(locationNum);
  }

  /**
   * End of executive election methods
   */
}
