export const zendeskLeftSnap = () => {
  (<any>document).querySelector('body').classList.add('body__zendesk-left-snap');
};

export const removeZendeskLeftSnap = () => {
  (<any>document).querySelector('body').classList.remove('body__zendesk-left-snap');
};

export const PROGRESS_BAR_FILLED_IN_COLOR = '#009191';
export const PROGRESS_BAR_EMPTY_COLOR = '#faf8f5';
export const TIER_INDICATOR_GREEN_COLOR = '#00baba';
export const TIER_INDICATOR_GRAY_COLOR = '#e8e2da';

export const bonusBarBackground = (percentage: number) => {
  return `linear-gradient(to right, ${PROGRESS_BAR_FILLED_IN_COLOR} 0%, ${PROGRESS_BAR_FILLED_IN_COLOR} ${
    percentage + 0.5
  }%, ${PROGRESS_BAR_EMPTY_COLOR} ${percentage + 0.501}%, ${PROGRESS_BAR_EMPTY_COLOR} 100%)`;
};
