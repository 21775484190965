import { BopGWPolicyPayment } from 'app/features/attune-bop/models/bop-gw-policy-payment';
import {
  CC_PROCESSING_FEE_EXCLUSIONS,
  isBopV2orV3,
} from 'app/features/attune-bop/models/constants';

export const MONTHLY_NUMBER_OF_PAYMENTS = 10;
export const ANNUAL_NUMBER_OF_PAYMENTS = 1;

export class BopGWPolicyPaymentPresenter {
  installments: number;
  downPaymentPremium: number;
  downPaymentTax: number;
  downPaymentSubtotal: number;
  installmentPremium: number;
  estimatedTotalIntegral: number;
  estimatedTotalFractional: number;
  downPaymentPercent: number;
  technologyFee = 0;
  processingFee = 0;
  hasCcProcessingFee = true;

  constructor(
    bopPolicyPayment: BopGWPolicyPayment,
    excessPolicyPayment: BopGWPolicyPayment | null,
    numberOfPayments: number
  ) {
    this.installments = numberOfPayments - 1;
    this.downPaymentPremium = bopPolicyPayment.getDownPaymentPremium(numberOfPayments);
    this.downPaymentTax = bopPolicyPayment.getDownPaymentTax();
    this.downPaymentSubtotal = this.downPaymentPremium + this.downPaymentTax;
    this.installmentPremium = bopPolicyPayment.getInstallment(numberOfPayments);
    const isBopVersion2Or3 = isBopV2orV3(bopPolicyPayment.uwCompanyCode);
    this.hasCcProcessingFee =
      isBopVersion2Or3 && !CC_PROCESSING_FEE_EXCLUSIONS.includes(bopPolicyPayment.policyBaseState);

    if (excessPolicyPayment !== null) {
      this.installmentPremium += excessPolicyPayment.getInstallment(numberOfPayments);

      this.downPaymentPremium += excessPolicyPayment.getDownPaymentPremium(numberOfPayments);
      this.downPaymentTax += excessPolicyPayment.getDownPaymentTax();
      this.downPaymentSubtotal = this.downPaymentPremium + this.downPaymentTax;
    }
    if (isBopVersion2Or3) {
      this.technologyFee = bopPolicyPayment.getTechnologyFee();
      this.processingFee = bopPolicyPayment.getProcessingFee();
    }
    const feesTotal = this.technologyFee + this.processingFee;
    this.downPaymentSubtotal += feesTotal;
    const estimatedTotal =
      this.downPaymentPremium +
      this.installments * this.installmentPremium +
      this.downPaymentTax +
      feesTotal;
    this.estimatedTotalIntegral = Math.trunc(estimatedTotal);
    this.estimatedTotalFractional = (estimatedTotal - this.estimatedTotalIntegral) * 100;

    this.downPaymentPercent =
      this.installments === 0
        ? 0
        : Math.round((100 * this.downPaymentPremium) / this.estimatedTotalIntegral);
  }
}
