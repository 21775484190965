import { CommissionPlanFrontendDetails } from './types';

// Included based on product availability
export const DCP_COMMISSION_PLANS: { [key: string]: CommissionPlanFrontendDetails } = {
  employers: {
    newBusinessPercentage: 12,
    renewalBusinessPercentage: 10,
    label: "Workers' Compensation",
  },
  hiscox: {
    newBusinessPercentage: 15,
    renewalBusinessPercentage: 12.5,
    label: 'General Liability/Professional Liability',
  },
  neptune: {
    newBusinessPercentage: 10,
    renewalBusinessPercentage: 8,
    label: 'Flood Insurance',
  },
};
