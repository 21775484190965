import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { of as observableOf, Observable, forkJoin } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { UserService } from 'app/core/services/user.service';
import { UsStateService } from 'app/shared/services/us-state.service';
import { RewardsService } from 'app/shared/services/rewards.service';
import { ActionName } from 'app/shared/rewards/rewards-types';

import {
  FormDslData,
  FormDslNode,
  TextNode,
  StrictPrimitive,
  ValueConditional,
  MultiInputNode,
  LinkModalNode,
  DivNode,
  HtmlElementNode,
  CheckBoxGroupNode,
  DropdownSearchNode,
  TraditionalRadioNode,
} from 'app/shared/form-dsl/constants/form-dsl-typings';

import {
  findNode,
  removeNullData,
  correctDateFormat,
  correctMoneyFormat,
  correctPhoneFormat,
} from 'app/shared/form-dsl/utils/form-dsl-helpers';

import {
  decodeArrayData,
  removeMalformedFields,
  correctStateFormat,
  coerceAggLoiValuePl,
  retrieveSecondaryCobFieldsSelected,
  setRequestDate,
  flattenObjectData,
  removeFieldsBasedOnValue,
  expandProfessionalLiabilityQuoteRqInputId,
  expandApplicationRatingInfoInputId,
  removeFieldsNotRequiredInRequest,
  expandV4RatingInfoInputId,
  removeCyberUpsellFields,
  defaultAnswersToPlQuestions,
} from 'app/features/hiscox/models/component-data';
import { convertDateToUsFormat } from 'app/shared/helpers/date-time-helpers';
import { HiscoxQuoteFormService } from 'app/features/hiscox/services/hiscox-quote-form.service';
import { HiscoxQuoteFormComponent } from 'app/features/hiscox/components/hiscox-quote-form/hiscox-quote-form.component';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';

import {
  PlConfigNode,
  PlConfigNodeBasic,
  PlConfigNodeMulti,
  PlValueConditional,
  PlDivNode,
  PlHtmlNode,
} from '../../models/pl-types';
import {
  keyFunc,
  plV4BasicTree,
  getPlFieldDefaults,
  getPlFieldValidators,
  plConfigV4,
  plAllV4,
  plV4ComplexValidators,
} from '../../models/pl-config';
import { COB_GROUPS, PL_COB_OVERRIDE_GROUPS } from '../../models/pl-cob-groups';
import {
  HiscoxPlFormDataFieldV4,
  PL_BUSINESS_CLASSES_V4,
  PL_ORDERED_FIELDS_V4,
} from 'app/features/hiscox/models/pl-constants';
import {
  HiscoxQuoteStatus,
  HISCOX_API_VERSION,
  HISCOX_PRODUCTS,
  HiscoxFormStepPath,
  CYBER_UPSELL_QUESTIONS,
  CyberUpsellQuestion,
} from '../../models/hiscox-types';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { HiscoxSharedDataField } from 'app/features/hiscox/models/hiscox-constants';
import { makeOverrideNodeByCob } from '../../models/hiscox-customization';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import {
  CoalitionCyberAdmittedProduct,
  QuoteResponse,
  isDraftQuoteResponse,
  isUnavailableResponse,
  isQuoteErrorResponse,
} from '../../../digital-carrier/models/types';
import { ISO_DATE_MASK } from '../../../../constants';
import { InsuredAccountSummaryService } from '../../../insured-account/services/insured-account-summary.service';
import { NaicsService } from '../../../../shared/services/naics.service';
import {
  CoalitionCyberQuestion,
  CyberQuestionEnablementFlag,
  CyberQuestionEnablementFlags,
  CyberIndustryData,
} from '../../../coalition/models/cyber-typings.model';
import {
  COALITION_BROKER_OF_RECORD_REFERRAL_REASON,
  DUPLICATE_BROKER_ERROR_MESSAGE,
  NAICS_CODES_TO_INDUSTRY_IDS,
} from '../../../coalition/models/cyber-constants.model';
import { SegmentService } from 'app/core/services/segment.service';

// There are some translation functions that are key to the
// functioning of the pl-quote-form component. The idea is that the
// core config lives in pl-config, and then the transformations
// necessary for display happen in this module

const cleanFormData = (formDataPrior: { [key: string]: any }, state: string, _cob: string): any => {
  let formDataResult = decodeArrayData(formDataPrior);
  // remove fields used only for Cyber quote. This must be done *before* the form is flattened.
  formDataResult = removeCyberUpsellFields(formDataResult);
  formDataResult = flattenObjectData(formDataResult);
  formDataResult = removeMalformedFields(formDataResult);
  formDataResult = removeNullData(formDataResult);
  formDataResult = correctDateFormat(formDataResult);
  formDataResult = correctMoneyFormat(formDataResult);
  formDataResult = correctPhoneFormat(formDataResult);
  formDataResult = correctStateFormat(formDataResult, state);
  formDataResult = coerceAggLoiValuePl(formDataResult);
  formDataResult = setRequestDate(formDataResult);
  formDataResult = removeFieldsBasedOnValue(formDataResult, HISCOX_PRODUCTS.pl);
  formDataResult = removeFieldsNotRequiredInRequest(formDataResult);

  return formDataResult;
};

/*
PlConfigRaw contains each of the question objects, and each question has an inputId,
this inputId is the value for xPath, so these fixKey function were intended to change
a short version to the exact path. It would be worth evaluation if this is needed, since
for v4 we are using the entire path for some of the questions.
*/
const fixKeyV4 = (oldInputId: string): string => {
  // Acknowlegemenst do not need to updated
  if (oldInputId.indexOf('Acknowledgements') > -1) {
    return oldInputId;
  }

  // CoverQuoteRq values have key set to exact path expected in request
  if (oldInputId.indexOf('CoverQuoteRq') > -1) {
    return oldInputId;
  }

  // ProductAcknowledgements values have keys set to the exact path expected in request
  if (oldInputId.indexOf('ProductAcknowledgements') > -1) {
    return oldInputId;
  }

  // ApplicationRatingInfo values already have the expected path
  if (oldInputId.indexOf('ApplicationRatingInfo') > -1) {
    return oldInputId;
  }

  if (CYBER_UPSELL_QUESTIONS.includes(oldInputId as CyberUpsellQuestion)) {
    return oldInputId;
  }

  let newDependsOn = oldInputId;

  // If inputId does not contain ProfessionalLiabilityQuoteRq, then we need to add it
  if (newDependsOn.indexOf('ProfessionalLiabilityQuoteRq') === -1) {
    newDependsOn = 'ProductQuoteRqs_ProfessionalLiabilityQuoteRq_RatingInfo_' + newDependsOn;
  }
  return newDependsOn;
};

const convertToMultiNodeV4 = (pNode: PlConfigNodeMulti): MultiInputNode => {
  const newInputId: string = fixKeyV4(pNode.inputId);

  return {
    inputId: newInputId,
    primitive: 'RADIO',
    nameOfFormControl: newInputId,
    formStep: pNode.formStep,
    cssClass: pNode.cssClass,
    options: pNode.options,
    labelText: _.get(pNode, 'labelText', ''),
    ul: { li: _.get(pNode, 'clauses', []) },
  };
};

const convertToSelectNode = (pNode: PlConfigNodeMulti): MultiInputNode => {
  const newInputId: string = fixKeyV4(pNode.inputId);

  return {
    inputId: newInputId,
    primitive: 'SELECT',
    nameOfFormControl: newInputId,
    formStep: pNode.formStep,
    cssClass: pNode.cssClass,
    options: pNode.options,
    labelText: _.get(pNode, 'labelText', ''),
    ul: { li: _.get(pNode, 'clauses', []) },
  };
};

const convertToStrictFormInputNode = (
  pNode: PlConfigNodeBasic,
  _primitive: StrictPrimitive
): TextNode => {
  const newInputId: string = fixKeyV4(pNode.inputId);

  const temp: TextNode = {
    inputId: newInputId,
    primitive: pNode.primitive,
    nameOfFormControl: newInputId,
    formStep: pNode.formStep,
    cssClass: pNode.cssClass,
    value: pNode.value,
    labelText: _.get(pNode, 'labelText', ''),
    inputType: 'text',
    errorText: pNode.errorText,
  };
  if (pNode.inputType === '') {
    return temp as TextNode;
  } else if (pNode.inputType === 'number') {
    temp.inputType = 'number';
    return temp as TextNode;
  }
  return temp;
};

/* eslint-disable  @typescript-eslint/no-use-before-define */
const convertToStrictVC = (pNode: PlValueConditional): ValueConditional => {
  const dependsOn: string = fixKeyV4(pNode.dependsOn);
  const conditionalChildren: FormDslNode[] = _.map(
    pNode.conditionalChildren,
    convertToFormDslV4
  ) as FormDslNode[];

  return {
    ...pNode,
    conditionalChildren,
    dependsOn,
  };
};

const convertToDivV4 = (pNode: PlDivNode): DivNode => {
  const temp: DivNode = {
    primitive: 'DIV',
    children: _.map(pNode.children, convertToFormDslV4) as FormDslNode[],
    cssClass: pNode.cssClass,
  };
  return temp;
};

const convertToHtmlNode = (pNode: PlHtmlNode): HtmlElementNode => {
  const temp: HtmlElementNode = {
    primitive: 'H2',
    text: pNode.text,
  };
  return temp;
};

const convertToFormDslV4 = (pNode: PlConfigNode): FormDslNode => {
  // PlConfig types are simple mirrors of FormDSLNodes with a little
  // additional filtering in the form of cobOnly, stateOnly, and statesExclude.
  // PlConfigs are emitted by George's tool which has a slightly
  // different format than true FormDSL.
  // These functions convert back to strict FormDSL.
  if (pNode.primitive === 'RADIO') {
    return convertToMultiNodeV4(pNode);
  }
  if (pNode.primitive === 'MULTI-CLAUSE-RADIO') {
    return convertToMultiNodeV4(pNode);
  }
  if (pNode.primitive === 'SELECT') {
    return convertToSelectNode(pNode);
  }
  if (pNode.primitive === 'TEXT') {
    return convertToStrictFormInputNode(
      pNode as PlConfigNodeBasic,
      pNode.primitive as StrictPrimitive
    );
  }
  if (pNode.primitive === 'NUMBER') {
    return convertToStrictFormInputNode(
      pNode as PlConfigNodeBasic,
      pNode.primitive as StrictPrimitive
    );
  }
  if (pNode.primitive === 'MONEY_WITHOUT_DECIMAL') {
    return convertToStrictFormInputNode(
      pNode as PlConfigNodeBasic,
      pNode.primitive as StrictPrimitive
    );
  }
  if (pNode.primitive === 'VALUE-CONDITIONAL') {
    return convertToStrictVC(pNode);
  }
  if (pNode.primitive === 'DATE') {
    return convertToStrictFormInputNode(
      pNode as PlConfigNodeBasic,
      pNode.primitive as StrictPrimitive
    );
  }
  if (pNode.primitive === 'LINK_MODAL') {
    return pNode as LinkModalNode;
  }
  if (pNode.primitive === 'DIV') {
    return convertToDivV4(pNode as PlDivNode);
  }
  if (pNode.primitive === 'H2') {
    return convertToHtmlNode(pNode as PlHtmlNode);
  }
  if (pNode.primitive === 'CHECKBOX_GROUP') {
    return pNode as CheckBoxGroupNode;
  }
  if (pNode.primitive === 'DROPDOWN_SEARCH') {
    return pNode as DropdownSearchNode;
  }
  if (pNode.primitive === 'TRADITIONAL_RADIO') {
    return pNode as TraditionalRadioNode;
  }
  return {
    primitive: 'TEXT',
    inputType: 'text',
    inputId: 'quote-question-free-text-1',
    nameOfFormControl: 'quote-question-free-text-1',
    labelText: `Error translating ${pNode.primitive}`,
    placeholder: _.get(pNode, 'inputId'),
  };
};

const plOverrideNodeV4 = makeOverrideNodeByCob(
  _.keys(PL_BUSINESS_CLASSES_V4),
  PL_COB_OVERRIDE_GROUPS
);

const filterNodes = (nodeList: PlConfigNode[], cob: string) => {
  const overriddenNodes = _.map(nodeList, (node: PlConfigNode) => {
    return plOverrideNodeV4(node, cob);
  });

  const rawNodes = _.filter(overriddenNodes, (node) => {
    const activeCobSetName = _.get(node, 'cobOnly', '');
    const overridingChildCOBSet = _.get(node, 'conditionalChildren[0].cobOnly', activeCobSetName);
    if (activeCobSetName === 'ALL') {
      return true;
    }
    const activeCobSet = _.get(COB_GROUPS, overridingChildCOBSet, []) as string[];
    const cobIndex = activeCobSet.indexOf(cob);

    return cobIndex > -1;
  }) as PlConfigNode[];

  return rawNodes;
};

const nodesForCob = (cob: string): PlConfigNode[] => {
  const filterRawNodesByCobVersion: PlConfigNode[] = filterNodes(plConfigV4, cob);

  const rawNodes: PlConfigNode[] = _.map(filterRawNodesByCobVersion, (node) => {
    if (node.primitive === 'DIV') {
      const childNodes = filterNodes(node.children, cob);
      const retNode: PlDivNode = {
        primitive: 'DIV',
        children: childNodes,
        cssClass: node.cssClass,
        cobOnly: node.cobOnly,
        stateOnly: node.stateOnly,
        statesExclude: node.statesExclude,
        versions: node.versions,
      };
      return retNode;
    }
    return node;
  });
  return rawNodes;
};

@Component({
  selector: 'app-hiscox-pl-quote-form.app-page.app-page__form',
  templateUrl: '../../components/hiscox-quote-form/hiscox-quote-form.component.html',
  providers: [HiscoxQuoteFormService],
})
export class HiscoxPlQuotePageComponent
  extends HiscoxQuoteFormComponent
  implements OnInit, OnDestroy
{
  productType: HISCOX_PRODUCTS.pl = HISCOX_PRODUCTS.pl;
  version: HISCOX_API_VERSION = HISCOX_API_VERSION.v4;
  plBasicTreeByVersion: FormDslNode[];
  plOrderedFields: string[];
  plQuestionsAll: FormDslNode[];

  cyberQuoteWasAttempted = false;
  flagsFromIndustryData: CyberQuestionEnablementFlags;

  constructor(
    protected route: ActivatedRoute,
    protected informService: InformService,
    protected insuredAccountService: InsuredAccountService,
    protected router: Router,
    protected amplitudeService: AmplitudeService,
    protected userService: UserService,
    protected usStateService: UsStateService,
    protected hiscoxQuoteService: HiscoxQuoteService,
    public formService: HiscoxQuoteFormService,
    protected sentryService: SentryService,
    protected rewardsService: RewardsService,
    private digitalCarrierService: DigitalCarrierQuoteService,
    private accountSummaryService: InsuredAccountSummaryService,
    private naicsService: NaicsService,
    protected segmentService: SegmentService
  ) {
    super(
      route,
      informService,
      insuredAccountService,
      router,
      amplitudeService,
      userService,
      usStateService,
      hiscoxQuoteService,
      formService,
      sentryService,
      segmentService
    );
    this.loadQuestions = this.loadQuestions.bind(this);
  }

  ngOnInit() {
    this.version = HISCOX_API_VERSION.v4;
    this.plBasicTreeByVersion = plV4BasicTree;
    this.plOrderedFields = PL_ORDERED_FIELDS_V4;
    this.plQuestionsAll = plAllV4;
    this.formService.apiVersionSubject.next(this.version);

    // setup hidden questions
    const basicTreeKeys = this.plBasicTreeByVersion.map((node) => {
      return node.nameOfFormControl as string;
    });
    const secondStepHiddenNodeIds = new Set<string>(basicTreeKeys);

    // setup form config
    this.formConfig = [
      {
        /* === BEGIN RouteFormStep PROPERTIES === */
        args: {},
        displayName: 'Basic Info',
        slug: 'quote-basic',
        parent: 'quote-basic',
        formPath: HiscoxFormStepPath.QUOTE_BASIC,
        /* === END RouteFormStep PROPERTIES === */
        getFormTree: (formData: FormDslData = {}): Observable<FormDslNode[]> => {
          return observableOf(this.plBasicTreeByVersion);
        },
      },
      {
        /* === BEGIN RouteFormStep PROPERTIES === */
        args: {},
        displayName: 'Rating Info',
        slug: 'quote-rating',
        parent: 'quote-rating',
        formPath: HiscoxFormStepPath.QUOTE_RATING,
        /* === END RouteFormStep PROPERTIES === */
        hiddenNodeIds: secondStepHiddenNodeIds,
        getFormTree: this.loadQuestions,
      },
    ];
    // set form defaults
    this.formDefaults = getPlFieldDefaults();

    // set form validators
    this.formValidators = getPlFieldValidators();
    this.complexFormValidators = plV4ComplexValidators;

    // filter cyber industries according to class code
    this.sub.add(
      this.insuredAccountSubject
        .pipe(
          tap((insuredAccount) => {
            const accountNaicsCode = insuredAccount.naicsCode?.code;
            if (accountNaicsCode && NAICS_CODES_TO_INDUSTRY_IDS[accountNaicsCode]) {
              this.formService.naicsCodeSubject.next(accountNaicsCode);
            }
          }),
          switchMap((insuredAccount) => {
            // It's not guaranteed that an insuredAccount will have a naicsCode on it
            if (insuredAccount && insuredAccount.naicsCode && insuredAccount.naicsCode.hash) {
              return this.naicsService.getCyberLiabilityNaicsMapping(insuredAccount.naicsCode.hash);
            }

            // return an empty array if the insuredAccount lacks a naicsCode
            return observableOf([]);
          })
        )
        .subscribe((cyberMappings) => {
          if (cyberMappings.length > 0) {
            this.formService.cyberDescriptionOfBusinessSubject.next(
              cyberMappings.map((industry) => industry.id.toString(10))
            );
          }
        })
    );

    // start hiscox component
    super.ngOnInit();

    // Set listener for Cyber industry ID, so we can fetch the industry when it changes
    this.sub.add(
      this.formService.industryId$.subscribe((industryId: number | null) => {
        if (industryId) {
          this.handleCyberIndustryIdControlUpdates(industryId);
        }
      })
    );

    this.sub.add(
      this.accountSummaryService
        .getSummary(this.route.snapshot.params.accountId)
        .subscribe((accountSummary) => {
          this.formService.accountSummarySubject.next(accountSummary);
        })
    );

    this.sub.add(
      this.naicsService.getProductAvailability().subscribe((availabilities) => {
        this.formService.productAvailabilitiesSubject.next(availabilities);
      })
    );
  }

  protected configureNewForm(accountDetails: InsuredAccount) {
    // retrieve business name field name
    const businessNameInputId = 'BusinessInfo_CommercialName';
    // cast business name node to TextNode in order to access 'value' property
    const businessNameNode = findNode(this.plBasicTreeByVersion, businessNameInputId) as TextNode;
    // set business name
    businessNameNode['value'] = accountDetails.companyName;

    this.formData = {
      [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1]:
        accountDetails.addressLine1,
      [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR2]:
        accountDetails.addressLine2,
      [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY]: accountDetails.city,
      [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD]:
        accountDetails.state,
      [HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE]: accountDetails.zip,
    };
  }

  private configureEditFormV4(
    oldFormData: FormDslData,
    classOfBusiness: string,
    state: string,
    insuredAccountDetails: InsuredAccount
  ) {
    // retrieve coverage start date field name for PL
    const coverageStartDateInputId: string = expandProfessionalLiabilityQuoteRqInputId(
      this.productType,
      'CoverageStartDate'
    );

    // retrieve coverage start date input value
    const coverageStartDate = convertDateToUsFormat(
      _.get(oldFormData, coverageStartDateInputId, '') as string
    );

    // retrieve professional experience field name for PL
    const professionalExperienceInputId: string = expandApplicationRatingInfoInputId(
      this.productType,
      'ProfessionalExperience'
    );

    // retrieve professional experience input value
    const professionalExperience = convertDateToUsFormat(
      _.get(oldFormData, professionalExperienceInputId, '') as string
    );

    // retrieve retroactive date field name for PL
    const retroactiveDateInputId = expandV4RatingInfoInputId(this.productType, 'RetroactiveDate');

    // retrieve retroactive date input value
    const retroactiveDate = convertDateToUsFormat(
      _.get(oldFormData, retroactiveDateInputId, '') as string
    );

    // retrieve ProfessionalLiabilityRetroactiveDate date input value
    const plRetroactiveDateInputId = expandV4RatingInfoInputId(
      this.productType,
      'ProfessionalLiabilityRetroactiveDate'
    );
    const plRetroactiveDate = convertDateToUsFormat(
      _.get(oldFormData, plRetroactiveDateInputId, '') as string
    );

    // retrieve secondary class of business field name for PL
    const secondaryClassesOfBusinessInputId = expandV4RatingInfoInputId(
      this.productType,
      'SecondaryCOBSmallContractors_ClassOfBusinessCd'
    );

    // retrieve secondary class of business input value
    const secondaryClassesOfBusiness = _.get(oldFormData, secondaryClassesOfBusinessInputId, '');
    if (secondaryClassesOfBusiness) {
      const secondaryCobFieldsSelected = retrieveSecondaryCobFieldsSelected(
        this.productType,
        secondaryClassesOfBusiness as string[]
      );
      oldFormData = {
        ...oldFormData,
        ...secondaryCobFieldsSelected,
      };
    }
    delete oldFormData[secondaryClassesOfBusinessInputId];

    // retrieve business name field name
    const businessNameInputId = HiscoxPlFormDataFieldV4.BUSINESSINFO_COMMERCIALNAME;
    // use business name from insured account
    const businessName = insuredAccountDetails.companyName;
    // cast business name node to TextNode in order to access 'value' property
    const businessNameNode = findNode(this.plBasicTreeByVersion, businessNameInputId) as TextNode;
    // set business name
    businessNameNode['value'] = businessName;

    // set default fields, if they do not already exist in the formData
    defaultAnswersToPlQuestions(oldFormData);

    // reset form config to reflect these updates
    const firstStepConfig = this.formConfig[0];
    firstStepConfig['getFormTree'] = (formData: FormDslData = {}): Observable<FormDslNode[]> => {
      return observableOf(this.plBasicTreeByVersion);
    };

    // update form data
    this.formData = {
      ...oldFormData,
      classOfBusinessCd: classOfBusiness,
      [coverageStartDateInputId]: coverageStartDate,
      [professionalExperienceInputId]: professionalExperience,
      [retroactiveDateInputId]: retroactiveDate,
      [plRetroactiveDateInputId]: plRetroactiveDate,
    };
  }

  protected configureEditForm(
    oldFormData: FormDslData,
    classOfBusiness: string,
    state: string,
    insuredAccountDetails: InsuredAccount
  ) {
    return this.configureEditFormV4(oldFormData, classOfBusiness, state, insuredAccountDetails);
  }

  handleRedirectError() {
    this.router.navigate(['accounts'], { replaceUrl: true });
    this.informService.errorToast(
      `We encountered an error loading the Hiscox quote flow.`,
      null,
      null,
      'Okay',
      null,
      0
    );
  }

  loadQuestions(): Observable<FormDslNode[]> {
    const formStepFields = this.getFieldsOfStep(this.formService.defaultFormStep);
    const state: string =
      formStepFields[HiscoxSharedDataField.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD];

    const classOfBusiness: string = formStepFields.BusinessInfo_ClassOfBusinessCd;

    const rawNodes = nodesForCob(classOfBusiness);

    const stateFilteredNodes = _.filter(rawNodes, (node) => {
      const stateOnly = _.get(node, 'stateOnly', 'noIncludeFilter');
      const statesExclude = _.get(node, 'statesExclude', []);
      const noStateOnly = stateOnly === 'noIncludeFilter';
      const noStatesExclude = _.isEmpty(statesExclude);

      if (noStateOnly && noStatesExclude) {
        return true;
      }
      return (
        (stateOnly === state || noStateOnly) &&
        (!_.includes(statesExclude, state) || noStatesExclude)
      );
    }) as PlConfigNode[];

    const applicableNodes: FormDslNode[] = _.map(stateFilteredNodes, convertToFormDslV4);

    const rawCombinedNodes: FormDslNode[] = _.concat(this.plQuestionsAll, applicableNodes);

    const combinedNodes: FormDslNode[] = _.sortBy(rawCombinedNodes, (node: any) =>
      this.plOrderedFields.indexOf(keyFunc(node))
    );

    return observableOf(combinedNodes);
  }

  submitForm(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    // attempt to step forward in order to
    // trigger validation error detection
    if (!this.isSubmittable()) {
      this.clickForward();
      return;
    }

    const requestUuid = 'FROM_HISCOX_DQ_API'; // TODO: this is subject to change
    const quoteId = ''; // TODO: this will change for existing draft quotes
    const formStepFields = this.getFieldsOfStep(this.formService.defaultFormStep);
    const state = formStepFields.BusinessInfo_Locations_Primary_AddrInfo_StateOrProvCd;
    const classOfBusiness = formStepFields.BusinessInfo_ClassOfBusinessCd;

    const formDataOrig = this.getFormData();
    const formData = cleanFormData(formDataOrig, state, classOfBusiness);
    this.isSubmittingForm = true;
    this.sendSegmentEvent('Quote Attempted');

    this.sub.add(
      forkJoin(
        this.hiscoxQuoteService.requestQuote(
          formData,
          this.productType,
          this.accountDetails,
          requestUuid,
          quoteId,
          classOfBusiness
        ),
        this.submitCoalitionCyberQuote()
      ).subscribe(([hiscoxResponse, cyberResponse]: [any, QuoteResponse]) => {
        const validQuoteStates = [HiscoxQuoteStatus.DECLINED, HiscoxQuoteStatus.REFERRED];
        this.isSubmittingForm = false;
        if (
          hiscoxResponse &&
          hiscoxResponse.error &&
          !validQuoteStates.includes(hiscoxResponse.status)
        ) {
          this.requestQuoteErrorResponse = hiscoxResponse;
          this.requestQuoteErrors = this.getQuoteErrors();
          this.quoteResultModalOpen = true;
          this.amplitudeService.track({
            eventName: 'pl_error',
            detail: JSON.stringify(hiscoxResponse.error),
            useLegacyEventName: true,
          });
          return;
        }
        if (hiscoxResponse.status !== HiscoxQuoteStatus.DECLINED) {
          this.rewardsService.submitRewardAction({
            actionName: ActionName.QUOTE_FOR_ACCOUNT,
            data: {
              insuredAccountId: this.accountId,
              accountName: formData.BusinessInfo_CommercialName,
              carrierName: 'hiscox',
              product: 'pl',
            },
          });
        }
        this.quotedSuccess$.next(true);

        const queryParams: Params = {};
        if (cyberResponse?.success && cyberResponse.status === 'quoted') {
          queryParams.secondaryQuoteId = cyberResponse.uuid;
        }

        if (
          cyberResponse &&
          isUnavailableResponse(cyberResponse) &&
          cyberResponse.unavailableReasons.includes(COALITION_BROKER_OF_RECORD_REFERRAL_REASON)
        ) {
          this.sentryService.notify(
            'Coalition Cyber: Submit quote response from pl up sell / cross sell contains broker of record error, redirecting user to Help Center form',
            {
              severity: 'info',
              metaData: { response: cyberResponse, product: 'cyber_admitted' },
            }
          );
          queryParams.secondaryQuoteBrokerOfRecordError = true;
        } else if (
          cyberResponse &&
          isQuoteErrorResponse(cyberResponse) &&
          cyberResponse.errors.includes(DUPLICATE_BROKER_ERROR_MESSAGE)
        ) {
          this.sentryService.notify(
            'Coalition Cyber: Submit quote response from pl up sell / cross sell did not have draft status due to Duplicate broker error',
            {
              severity: 'info',
              metaData: { response: cyberResponse, product: 'cyber_admitted' },
            }
          );
          queryParams.secondaryQuoteDuplicateBrokerError = true;
        }

        this.router.navigate(['/accounts', this.accountId, HISCOX_PRODUCTS.pl, 'details'], {
          queryParams,
        });
      })
    );
  }

  fillInHappyPath() {
    this.formService.setHappyPathData(this.productType);
    this.formService.fillInHappyPath();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // Methods for Cyber upsell
  updateQuestionEnablementFlags(industryData: CyberIndustryData) {
    const flagNames = _.values(CyberQuestionEnablementFlag);
    const flags = _.pick(industryData, flagNames);
    this.formService.cyberIndustryFlagsSubject.next(flags);
  }

  handleCyberIndustryIdControlUpdates(industryId: number) {
    this.sub.add(
      this.digitalCarrierService
        .getCoalitionCyberIndustry(industryId, 'cyber_admitted')
        .subscribe((industryData) => {
          if (!industryData) {
            this.sentryService.notify(
              'Coalition Cyber Upsell: Failed to retrieve industry data; cannot determine questions to enable',
              {
                severity: 'error',
                metaData: {
                  industryId,
                  product: 'cyber_admitted',
                  quoteFlowProduct: HISCOX_PRODUCTS.pl,
                },
              }
            );
            return;
          }
          this.updateQuestionEnablementFlags(industryData);
        })
    );
  }

  submitCoalitionCyberQuote() {
    if (!this.formService.isStandaloneCyberSelected() || this.cyberQuoteWasAttempted) {
      return observableOf(null);
    }

    // All upsell cyber quotes will be in the Admitted market.
    const digitalCarrierProduct: CoalitionCyberAdmittedProduct = {
      product: 'cyber_admitted',
      pasSource: 'coalition',
    };

    const formData = this.formService.getStandaloneCyberFields(
      HISCOX_PRODUCTS.pl
      // this.flagsFromIndustryData
    );

    const rawEffectiveDate = formData[CoalitionCyberQuestion.EFFECTIVE_DATE] as string;
    const effectiveDate = moment.utc(rawEffectiveDate).format(ISO_DATE_MASK);
    const account = this.digitalCarrierService.parseAccountForQuoteSubmit(this.accountDetails);

    const quoteResponse$ = this.digitalCarrierService.submitQuote(
      digitalCarrierProduct,
      formData,
      account,
      effectiveDate,
      false
    );

    // We set this flag so that we do not create a new Cyber quote if the Hiscox quote
    // is re-submitted for any reason.
    this.cyberQuoteWasAttempted = true;

    return quoteResponse$.pipe(
      switchMap((quoteResponse: QuoteResponse) => {
        if (isDraftQuoteResponse(quoteResponse) && quoteResponse.pasId) {
          const quoteUuid = quoteResponse.uuid;

          return this.digitalCarrierService.issueQuote(
            digitalCarrierProduct,
            formData,
            account,
            effectiveDate,
            quoteUuid
          );
        } else {
          return observableOf(quoteResponse);
        }
      })
    );
  }
  // END methods for cyber upsell
}
