<header class="policy-pane-header">
  <div class="policy-pane-header-left">
    <div
      class="product-badge"
      role="presentation"
      [ngClass]="{
        'product-badge__bop-plus': isBop(),
        'product-badge__xs': isExcess(),
        'product-badge__wc': isAttuneWc()
      }"
    ></div>
    <div class="policy-pane-quote-info">
      <h2 class="policy-pane-header-h2">Policy details</h2>
      <small class="gray-text">No. {{ policyTermTransaction.PolicyNumber }}</small>
      <div *ngIf="showPreference" class="quote-type-info">
        <div
          *ngIf="
            isStronglyPreferredPolicy &&
            (!isAttuneWc(policyTermTransaction) || showWcStronglyPreferred)
          "
          class="quote_preference quote_preference__strongly_preferred"
        ></div>
        <div *ngIf="isPreferredPolicy" class="quote_preference quote_preference__preferred"></div>
        <div
          *ngIf="isNonPreferredPolicy"
          class="quote_preference quote_preference__non_preferred"
        ></div>
        {{ policyType }}
        <span
          *ngIf="policyTypeTip"
          class="more-info-tip tool-tip tool-tip__large"
          attr.data-tooltip="{{ policyTypeTip }}"
        >
          <span class="icon icon-question"></span>
        </span>
      </div>
    </div>
  </div>
  <div class="policy-pane-header-right">
    <h2 class="policy-pane-header-h2 js-total-cost">
      {{ getTotalCost() | currency: '$':'symbol':'1.2-2' }}
    </h2>
    <small class="gray-text">Amount</small>
  </div>
</header>

<app-document-download
  [displayName]="displayProductName()"
  [documentDownload$]="policyDocumentDownload$"
  [documentReady$]="policyDocumentDownloadReady$"
></app-document-download>
<app-document-download
  class="js-application-input-download"
  *ngIf="isBop() || isAttuneWc()"
  displayName="Application Input"
  [documentDownload$]="quoteSubmissionDownload$"
></app-document-download>

<app-document-download
  *ngIf="isLossRunsDownloadEnabled && hasDownloadableLossRuns"
  displayName="Loss Runs Documents"
  [documentDownload$]="lossRunsDocumentDownload$"
  [documentReady$]="lossRunsDocumentDownloadReady$"
></app-document-download>

<div class="policy-pane-details">
  <div class="policy-detail-subsection">
    <dl *ngIf="quoteDetails?.governingClassDescription" class="js-governing-class">
      <dt>Governing Class</dt>
      <dd class="{{ governingClassCssClass }}">
        {{ quoteDetails.governingClassDescription }} ({{ quoteDetails.governingClassCode }})
      </dd>
    </dl>
    <dl *ngIf="quoteDetails?.hazardGroup" class="js-hazard-group">
      <dt>Hazard Group</dt>
      <dd>{{ quoteDetails.hazardGroup }}</dd>
    </dl>
    <dl>
      <dt>Effective date</dt>
      <dd>{{ policyTermTransaction.PeriodStart | date: 'mediumDate':'UTC' }}</dd>
    </dl>
    <dl>
      <dt>Expiration date</dt>
      <dd>{{ policyTermTransaction.PeriodEnd | date: 'mediumDate':'UTC' }}</dd>
    </dl>
  </div>
</div>

<app-dialog-box
  *ngIf="secondaryPolicyId"
  fullWidth="true"
  type="primary"
  header="Your bind includes Cyber coverage"
>
  <p>View details and download policy documents by clicking below.</p>
  <a class="dialog-text-link" href="/accounts/{{ accountId }}/quotes/{{ secondaryPolicyId }}">
    View Cyber policy
  </a>
</app-dialog-box>

<ng-container *ngIf="changeHistory?.length">
  <h3 class="policy-pane-subheader js-policy-change-history">Change History</h3>
  <ul *ngFor="let policyChange of changeHistory" class="link-list">
    <li>
      <app-document-download
        [displayName]="policyChange.displayName"
        [documentDownload$]="policyChange.download$"
        [documentReady$]="changeHistoryDownloadsReady$"
      ></app-document-download>
    </li>
  </ul>
</ng-container>

<app-additional-documents [isAttuneWc]="isAttuneWc()" paneType="policy"></app-additional-documents>

<app-post-bind-servicing-information
  carrier="{{ isAttuneWc() ? 'attune-gw-wc' : 'attune-gw-bop' }}"
>
</app-post-bind-servicing-information>
