import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InsuredAccountService } from '../../features/insured-account/services/insured-account.service';

import * as _ from 'lodash';
import { AccountPolicyTerm } from '../../features/insured-account/models/insured-account.model';
import { InformService } from '../../core/services/inform.service';
import { SentryService } from 'app/core/services/sentry.service';

/**
 * This component serves to handle redirects to policy details.
 * In certain situations, we may want to link to a policy despite not having
 * its term number available. In that case, we link to this page, which looks
 * up the term number and links to the newest term.
 */
@Component({
  selector: 'app-policy-details-redirect-page',
  template: '<ng-container></ng-container>',
})
export class PolicyDetailsRedirectComponent implements OnInit, OnDestroy {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private informService: InformService,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService
  ) {}

  private sub = new Subscription();

  handleRedirectError() {
    this.router.navigate(['accounts'], { replaceUrl: true });
    this.informService.errorToast(
      `We encountered an error loading your policy.`,
      null,
      'Policy not found.',
      'Okay',
      null,
      0
    );
  }

  ngOnInit() {
    const accountId = (this.route as ActivatedRoute).snapshot.params['accountId'];
    const policyId = (this.route as ActivatedRoute).snapshot.params['policyId'];

    this.sub.add(
      this.insuredAccountService.get(accountId).subscribe(
        (accountDetails) => {
          const policies = accountDetails.policiesWithTerms;

          const allTerms: AccountPolicyTerm[] =
            _.concat.apply(
              null,
              policies.map((policy) => policy.terms)
            ) || [];
          const matchingTerm = allTerms.find((term) => term.policyNumber === policyId);
          if (matchingTerm) {
            if (matchingTerm.termNumber) {
              this.router.navigate(
                ['accounts', accountId, 'terms', policyId, matchingTerm.termNumber],
                { replaceUrl: true }
              );
            } else {
              this.router.navigate(['accounts', accountId], { replaceUrl: true });
            }
            this.sentryService.notify('No matching term found for Policy Details Redirect', {
              severity: 'error',
              metaData: {
                accountId,
                policyId,
                policies,
                allTerms,
              },
            });
            return;
          }

          this.handleRedirectError();
        },
        (err) => {
          this.sentryService.notify(
            'Error while fetching Insured Account details during Policy Details Redirect',
            {
              severity: 'error',
              metaData: {
                accountId,
                policyId,
                underlyingErrorMessage: err && err.message,
                underlyingError: err,
              },
            }
          );
          this.handleRedirectError();
        }
      )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
