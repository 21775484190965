import { of as observableOf, Observable, EMPTY, Subscription } from 'rxjs';
import { catchError, filter, merge, switchMap, take } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationError,
  NavigationCancel,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { get, isEmpty } from 'lodash';
import * as moment from 'moment';
import * as $ from 'jquery';
import { AMPLITUDE_ANALOGUE_URL, CURRENT_GIT_SHA } from 'app/constants';
import {
  AmplitudeCssSelector,
  AmplitudeEventListener,
  AmplitudeHtmlEvent,
  AmplitudePayloadType,
  AmplitudeSubmitEventPayload,
  FRAGMENT_ID_REGEX,
  HELP_CENTER_FORM_ID_REGEX,
  INPUT_FALLBACK_VALUE,
  INPUT_NAV_CANCEL,
  INPUT_NAV_ERROR,
  INPUT_PAGE_ENTER,
  QUERY_PARAMS_REGEX,
  SYNTHETIC_EVENT_TYPES,
  TERMS_AND_POLICY_PAGE_REGEX,
  TOOLTIP_TRACK_DELAY_MS,
  UNIQUE_ID_REGEX,
  getLinkInput,
  getRadioButtonInput,
  getSliderValue,
  getTooltipInput,
  sanitizeInput,
  sanitizeValue,
  useValueOrRedact,
  AmplitudeTrackPayload,
  AmplitudeTrackPayloadOverride,
  HISCOX_POLICY_ID_REGEX,
} from 'app/core/constants/amplitude-helpers';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { UserService } from 'app/core/services/user.service';
import { SentryService } from 'app/core/services/sentry.service';
import { USER_CONTEXT } from 'app/shared/models/current-user';
import { User } from 'app/shared/models/user';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { environment } from 'environments/environment';
import * as amplitude from '@amplitude/analytics-browser';

interface AmplitudeServiceOptions {
  isAdp?: boolean;
  skipAskForUser?: boolean;
}

@Injectable()
export class AmplitudeService {
  private sub = new Subscription();
  private eventListeners: AmplitudeEventListener[] = [];

  private isStarted = false;
  private newQuoteTSID = '';
  private newWcUuid = '';

  // State ids
  private currentUserId: string;
  private currentAccountId: string;
  private savedState: any;
  private inTransition = false;

  // State details
  private currentAccountDetails: any;
  private currentUserDetails: any;
  private currentPolicyDetails: any;
  private currentWcPolicyDetails: any;
  private currentGlPolicyDetails: any;
  private currentMSExcessDetails: any;
  private intuitSessionId: string;
  private intuitQuotePremium: string;
  private isAdp: boolean;

  // Transition vars
  private previousValue: any;
  private previousPage: string;
  private timeLoad = moment().format();
  private keyCount: number;

  constructor(
    private sentryService: SentryService,
    private currentUserService: CurrentUserService,
    private http: HttpClient,
    private insuredAccountService: InsuredAccountService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  private logEventInAmplitude(eventName: string, payload: any) {
    if (!amplitude) {
      if (!['test', 'acceptance'].includes(environment.stage)) {
        console.warn('No global amplitude object. Skipping logging of event', eventName, payload);
      }
      return;
    }
    if (['production', 'staging'].includes(environment.stage)) {
      amplitude.track(eventName, payload);
    }
  }

  init(key: string, { isAdp = false, skipAskForUser = false }: AmplitudeServiceOptions = {}) {
    if (this.isStarted) {
      throw new Error('Amplitude cannot be started twice');
    }

    let amplitudeOptions: amplitude.Types.BrowserOptions = {
      defaultTracking: {
        attribution: true,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
    };
    if (!isAdp) {
      amplitudeOptions = { ...amplitudeOptions, serverUrl: '/tracking' };
    }
    // The below `init` method accepts [apikey, user_identifier(optional), config (optional)] as arguments.
    // Setting `attribution` to true tells Amplitude to collect UTM params from the referring website.
    // These params are set as user properties when the event is submitted.
    amplitude.init(key, amplitudeOptions);

    this.isAdp = isAdp;
    this.isStarted = true;
    this.ngZone.runOutsideAngular(() => {
      this.initOutsideZone(skipAskForUser);
    });
  }

  initOutsideZone(skipAskForUser: boolean = false) {
    /////////////////////////////////////
    // Details subscriptions           //
    /////////////////////////////////////

    // Track if we're on an account page. If this works ok, consider this same framework with WC, policies, etc.
    this.sub.add(
      this.router.events
        .pipe(
          // On Navigation end, if the route or children has an accountId param, grab it as the account_id
          // Then request the details associated with that account
          // If there's no account in the route, clear out the account info
          filter((event) => {
            return event instanceof NavigationEnd;
          }),
          switchMap((event) => {
            return this.setAccountInfo();
          })
        )
        .subscribe((details) => {
          if (this.currentAccountId === get(details, 'id', '')) {
            this.currentAccountDetails = details;
          }
        })
    );

    // In case of a refresh, initial pageload, etc.
    $(document).ready(() => {
      this.sub.add(
        this.setAccountInfo()
          .pipe(take(2))
          .subscribe((details) => {
            if (this.currentAccountId === get(details, 'id', '')) {
              this.currentAccountDetails = details;
            }
          })
      );
    });

    // Fetch current user details
    this.sub.add(
      this.router.events
        .pipe(
          merge(observableOf('init')),
          filter((event: any | 'init') => {
            if (event === 'init' || event instanceof NavigationEnd) {
              const currentUser = this.currentUserService.getCurrentUser();
              return (
                currentUser !== null &&
                String(this.currentUserId).toLowerCase() !== currentUser.username
              );
            }
            return false;
          }),
          switchMap((event) => {
            const currentUser = this.currentUserService.getCurrentUser();
            this.currentUserId = currentUser ? currentUser.username.toLowerCase() : '';
            return skipAskForUser
              ? observableOf({ userName: this.currentUserId })
              : this.userService.getUser();
          }),
          filter((details: User) => {
            if (!details.userName) {
              this.sentryService.notify('Received user without userName from service-proxy', {
                severity: 'error',
                metaData: {
                  currentUserID: this.currentUserId,
                  userFromServer: details,
                },
              });
            }
            return !!details.userName;
          })
        )
        .subscribe((details: User) => {
          this.currentUserDetails = details;
        })
    );

    /////////////////////////////////////
    // Set up transition listeners     //
    /////////////////////////////////////
    const amplitudeService = this;
    // The code below tracks keycounts and previous values for input-related UI events

    // Input (non-radio, non-checkbox) and select elements on focus
    const freeTextAndSelect = `${AmplitudeCssSelector.Freetext},${AmplitudeCssSelector.Dropdown}`;
    this.listenOnEvent(
      AmplitudeHtmlEvent.Focus,
      function (_evt: JQuery.Event) {
        amplitudeService.keyCount = 0;
        amplitudeService.previousValue = useValueOrRedact(this, (this as HTMLInputElement).value);

        // Any keypress event
        amplitudeService.listenOnEvent(AmplitudeHtmlEvent.Keypress, function () {
          amplitudeService.keyCount++;
        });
      },
      freeTextAndSelect as AmplitudeCssSelector
    );

    // Sliders on mousedown
    this.listenOnEvent(
      AmplitudeHtmlEvent.MouseDown,
      function (_evt: JQuery.Event) {
        amplitudeService.previousValue = useValueOrRedact(
          this,
          getSliderValue(this as HTMLInputElement)
        );
      },
      AmplitudeCssSelector.Slider
    );

    /////////////////////////////////////
    // Event listeners                 //
    /////////////////////////////////////

    // Routing events
    this.sub.add(
      this.router.events.subscribe((event) => {
        switch (event.constructor) {
          case NavigationStart:
            this.savedState = this.genStatePayload();
            this.inTransition = true;
            this.previousPage = this.router.url;
            break;

          case NavigationEnd:
            this.timeLoad = moment().format();
            this.sleepySubmitEvent({
              ms: 500,
              input: INPUT_PAGE_ENTER,
              type: AmplitudePayloadType.Page,
              value: '',
            });
            break;

          case NavigationError:
            this.submitEvent({
              input: INPUT_NAV_ERROR,
              type: AmplitudePayloadType.Page,
              value: '',
            });
            break;

          case NavigationCancel:
            this.submitEvent({
              input: INPUT_NAV_CANCEL,
              type: AmplitudePayloadType.Page,
              value: '',
            });
            break;
        }
      })
    );

    // The code below sets up global event listeners for organic events

    // Radio inputs on click
    this.listenOnEvent(
      AmplitudeHtmlEvent.Click,
      function (_evt: JQuery.Event) {
        amplitudeService.submitOrganicEvent(
          {
            input: getRadioButtonInput(this),
            type: AmplitudePayloadType.RadioButton,
            value: useValueOrRedact(this, $(this).text()),
          },
          this
        );
      },
      AmplitudeCssSelector.Radio
    );

    // Link elements on click
    this.listenOnEvent(
      AmplitudeHtmlEvent.Click,
      function (_evt: JQuery.Event) {
        amplitudeService.submitOrganicEvent(
          {
            input: getLinkInput(this as HTMLAnchorElement),
            type: AmplitudePayloadType.Link,
            value: useValueOrRedact(this, (this as HTMLAnchorElement).href),
          },
          this
        );
      },
      AmplitudeCssSelector.Link
    );

    // Breadcrumb elements on click
    this.listenOnEvent(
      AmplitudeHtmlEvent.Click,
      function (_evt: JQuery.Event) {
        amplitudeService.submitOrganicEvent(
          {
            type: AmplitudePayloadType.Breadcrumb,
            input: AmplitudePayloadType.Breadcrumb,
            value: useValueOrRedact(this, $(this).text()),
          },
          this
        );
      },
      AmplitudeCssSelector.Breadcrumb
    );

    // Checkbox input elements on click
    this.listenOnEvent(
      AmplitudeHtmlEvent.Click,
      function (_evt: JQuery.Event) {
        amplitudeService.submitOrganicEvent(
          {
            type: AmplitudePayloadType.Checkbox,
            input: $(this).attr('id') || INPUT_FALLBACK_VALUE,
            value: useValueOrRedact(this, (this as HTMLInputElement).checked),
          },
          this
        );
      },
      AmplitudeCssSelector.Checkbox
    );

    // Select input elements on change
    this.listenOnEvent(
      AmplitudeHtmlEvent.Change,
      function (_evt: JQuery.Event) {
        amplitudeService.submitOrganicEvent(
          {
            type: AmplitudePayloadType.Select,
            input: $(this).attr('id') || INPUT_FALLBACK_VALUE,
            value: useValueOrRedact(this, (this as HTMLInputElement).value),
          },
          this
        );
      },
      AmplitudeCssSelector.Dropdown
    );

    // Slider elements on mouseup
    this.listenOnEvent(
      AmplitudeHtmlEvent.MouseUp,
      function (_evt: JQuery.Event) {
        amplitudeService.submitOrganicEvent(
          {
            type: AmplitudePayloadType.Slider,
            input: $(this).attr('id') || INPUT_FALLBACK_VALUE,
            value: useValueOrRedact(this, getSliderValue(this as HTMLInputElement)),
          },
          this
        );
      },
      AmplitudeCssSelector.Slider
    );

    // Input (non-radio, non-checkbox) elements on blur
    this.listenOnEvent(
      AmplitudeHtmlEvent.Blur,
      function (_evt: JQuery.Event) {
        amplitudeService.submitOrganicEvent(
          {
            type: AmplitudePayloadType.Input,
            input: $(this).attr('id') || INPUT_FALLBACK_VALUE,
            value: useValueOrRedact(this, (this as HTMLInputElement).value),
            payloadOverride: { inputType: (this as HTMLInputElement).type },
          },
          this
        );
      },
      AmplitudeCssSelector.Freetext
    );

    // Tooltips on mouseover
    // Note: mouseover events on tooltips set a small timer to track how long a user looks at
    // a tooltip item and doesn't track the event if a user leaves the tooltip before the timer expires
    let tooltipInteractionTimer: NodeJS.Timeout;
    this.listenOnEvent(
      AmplitudeHtmlEvent.MouseEnter,
      function (evt: JQuery.Event) {
        tooltipInteractionTimer = setTimeout(() => {
          amplitudeService.submitOrganicEvent(
            {
              type: AmplitudePayloadType.Tooltip,
              input: getTooltipInput(evt.currentTarget as HTMLElement),
              value: useValueOrRedact(this, $(this).attr('data-tooltip')),
            },
            this
          );
        }, TOOLTIP_TRACK_DELAY_MS);
      },
      AmplitudeCssSelector.Tooltip
    );

    this.listenOnEvent(
      AmplitudeHtmlEvent.MouseLeave,
      function (_evt: JQuery.Event) {
        clearTimeout(tooltipInteractionTimer);
      },
      AmplitudeCssSelector.Tooltip
    );
  }

  private saveListener(eventListenerDetails: AmplitudeEventListener) {
    this.eventListeners.push(eventListenerDetails);
  }

  private listenOnEvent(
    event: AmplitudeHtmlEvent,
    handler: JQuery.EventHandler<HTMLElement, null>,
    selector?: AmplitudeCssSelector
  ) {
    if (selector) {
      $(document).on(event, selector, handler);
    } else {
      $(document).on(event, handler);
    }
    this.saveListener({ element: document, htmlEventName: event, selector });
  }

  public teardownListeners() {
    this.sub.unsubscribe();
    try {
      this.eventListeners.forEach(({ element, htmlEventName, selector }) =>
        $(element).off(htmlEventName, selector)
      );
    } catch (error) {
      this.sentryService.notify('Amplitude Service: event listener teardown logic failed.', {
        metaData: {
          underlyingError: error,
          underlyingErrorMessage: error && error.message,
        },
      });
    }
  }

  public getSessionId(): string | undefined {
    if (amplitude) {
      const sessionId: number | undefined = amplitude.getSessionId();
      if (sessionId) {
        return sessionId.toString();
      }
    }
  }

  public debugUserId() {
    const u = this.currentUserService.getCurrentUser();
    return u ? u.username.toLowerCase() : 'null';
  }

  public debugUser() {
    const userName = get(this.currentUserDetails, 'firstName', '')
      ? get(this.currentUserDetails, 'firstName', '') +
        ' ' +
        get(this.currentUserDetails, 'lastName', '')
      : '';
    const gw_username = get(this.currentUserDetails, 'husaUserName');
    const producer_code = get(this.currentUserDetails, 'producer');
    return { producer_code, gw_username, userName };
  }

  genStatePayload() {
    // User info
    const currentUser = this.currentUserService.getCurrentUser();
    const attune_portal_username = currentUser ? currentUser.username.toLowerCase() : '';

    const userName = this.getUserDetail('firstName')
      ? this.getUserDetail('firstName') + ' ' + this.getUserDetail('lastName')
      : '';
    const gw_username = this.getUserDetail('husaUserName');
    const producer_code = this.getUserDetail('producer');

    // Account info
    const accountId = this.currentAccountId;
    const accountName = this.getAccountDetail('companyName');
    const accountState = this.getAccountDetail('state');
    const accountOrgType = this.getAccountDetail('organizationType');
    const accountZip = this.getAccountDetail('zip');
    const accountEmail = this.getAccountDetail('emailAddress');
    const accountNaicsCode = this.getAccountDetail('naicsCode.code');
    const accountNaicsDescription = this.getAccountDetail('naicsCode.description');

    const policiesWithTerms = this.getAccountDetail('policiesWithTerms');
    const accountHasBOPPolicy = policiesWithTerms && policiesWithTerms.length > 0 ? true : false;

    const policies = this.getAccountDetail('policies');
    const accountLatestBOPPolicyNum =
      policies && (policies.length > 0 ? policies[policies.length - 1].policyNumber : '');

    // Quote info
    const bopTransactionId = this.getTransactionID();
    const bopBindable = this.getQuoteDetail('bindable');
    const bopAggLimit = this.getQuoteDetail('aggregateLimit', true);
    const bopMed = this.getQuoteDetail('medicalLimitPerPerson', true);
    const bopPerOcc = this.getQuoteDetail('perOccurenceLimit', true);
    const bopName = this.getQuoteDetail('quoteName');
    const bopPremium = this.getQuoteDetail('totalPremium');
    const bopTaxes = this.getQuoteDetail('totalTaxes');
    const bopLineBusiness = this.getQuoteDetail('lineBusinessType', true);
    const bopLinkedTransactionId = this.getQuoteDetail('linkedJobId');

    // Mainstreet Excess info
    const msExcessAggLimit = this.getMSExcessDetail('aggregateLimit', true);
    const msExcessPerOcc = this.getMSExcessDetail('perOccurenceLimit', true);
    const msExcessPremium = this.getMSExcessDetail('totalPremium');
    const msExcessTaxes = this.getMSExcessDetail('totalTaxes');
    const msExcessLinkedTransactionId = this.getMSExcessDetail('linkedJobId');
    const msExcessTransactionId = this.getMSExcessDetail('id');

    // WC quote info
    const wcQuoteId = this.getWcPolicyId();
    const wcPremium = this.getWcDetail('premium');
    const wcEffDate = this.getWcDetail('effectiveDate');

    // Within quote-flow ID
    const tsRequestId = this.getTsRequestId();
    const prevRequestId = this.getPreviousTsRequestId();
    const wcUuid = this.getWcUuid();

    // Intuit info
    const intuitSessionId = this.getIntuitSessionId();
    const intuitQuotePremium = this.getIntuitQuotePremium();

    // Site info - site version refers to the git sha of the release the user is running in their browser.
    const siteVersion = CURRENT_GIT_SHA;

    // Page info
    const pageVersion = 1.0;
    const page = this.isAdp ? this.getAdpPage() : this.router.url;
    const insProduct = this.determineInsProduct(page);

    // other
    const timeEvent = moment().format();
    const timeLoad = this.timeLoad;
    const keyCount = this.keyCount;

    const payload = {
      accountEmail,
      accountHasBOPPolicy,
      accountId,
      accountLatestBOPPolicyNum,
      accountNaicsCode,
      accountNaicsDescription,
      accountName,
      accountOrgType,
      accountState,
      accountZip,
      attune_portal_username,
      bopAggLimit,
      bopBindable,
      bopLineBusiness,
      bopLinkedTransactionId,
      bopMed,
      bopName,
      bopPerOcc,
      bopPremium,
      bopTaxes,
      bopTransactionId,
      gw_username,
      insProduct,
      intuitQuotePremium,
      intuitSessionId,
      isAdp: this.isAdp,
      keyCount,
      msExcessAggLimit,
      msExcessLinkedTransactionId,
      msExcessPerOcc,
      msExcessPremium,
      msExcessTaxes,
      msExcessTransactionId,
      page,
      pageVersion,
      prevRequestId,
      producer_code,
      siteVersion,
      timeEvent,
      timeLoad,
      tsRequestId,
      userName,
      wcEffDate,
      wcPremium,
      wcQuoteId,
      wcUuid,
    };

    return payload;
  }

  public setAdp() {
    this.isAdp = true;
  }

  private analogueCall(eventName: string, payload: any) {
    // we want to avoid making amplitude calls when we aren't logged in as an authorized user
    if (!this.currentUserService.isCurrentUserPresent(USER_CONTEXT.AGENT_PORTAL)) {
      return EMPTY;
    }

    const amplitudePayload = {
      source: 'amplitude.service',
      eventName,
      payload,
    };

    return this.http.post<any>(AMPLITUDE_ANALOGUE_URL, amplitudePayload).pipe(
      catchError((error) => {
        // Do the error handling here
        this.sentryService.notify('Error calling AmplitudeAnalogue.', {
          severity: 'error',
          metaData: {
            payload,
            eventName,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return EMPTY;
      })
    );
  }

  // All manually tracked events should use `track` and `trackWithOverride`
  // to submit events to Amplitude. `submitEvent` should NOT be called directly
  // outside of this service.
  public track({ eventName, detail, useLegacyEventName = false }: AmplitudeTrackPayload) {
    return this.submitEvent({
      input: eventName,
      type: AmplitudePayloadType.System,
      value: detail,
      useLegacyEventName,
    });
  }

  public trackWithOverride({
    eventName,
    detail,
    payloadOverride,
    useLegacyEventName = false,
  }: AmplitudeTrackPayloadOverride) {
    return this.submitEvent({
      input: eventName,
      type: AmplitudePayloadType.System,
      value: detail,
      payloadOverride,
      useLegacyEventName,
    });
  }

  submitOrganicEvent(payload: AmplitudeSubmitEventPayload, element: HTMLElement) {
    this.submitEvent({
      disabled: this.isDisabled(element),
      quoteSection: this.quoteSection(element),
      ...payload,
    });
  }

  /**
   * All events tracked in Amplitude and in the Event Processor will
   * go through this method.
   *
   * Unless you're manually logging an organic UI event (out of necessity)
   * and need to change the `type` passed into Amplitude, do NOT call
   * this method directly. Instead, use the `track` or `trackWithOverride`
   * methods to submit synthetic events to Amplitude.
   */
  submitEvent({
    disabled,
    input,
    payloadOverride,
    quoteSection,
    type,
    useLegacyEventName = false,
    value,
  }: AmplitudeSubmitEventPayload) {
    if (input === undefined || type === undefined) {
      this.sentryService.notify(
        'AmplitudeService: submitEvent was invoked with invalid parameters',
        {
          severity: 'error',
          metaData: { parameters: JSON.stringify(arguments[0]) } /* eslint-disable-line */,
        }
      );
      return;
    }

    // Sanitize the values for the event payload
    const cleanInput = sanitizeInput(input);
    const cleanValue = sanitizeValue(value);

    // Create the global event payload based on its type
    const payload_state =
      this.inTransition && input !== INPUT_PAGE_ENTER ? this.savedState : this.genStatePayload();

    // Determine the previous value of an event based on its type
    const previousValue =
      type === AmplitudePayloadType.Page ? this.previousPage : this.previousValue;

    // Calculate `shortUrl` from the full page url
    const originalUrl = payload_state['page'];
    const shortUrl = this.getShortUrl(originalUrl);

    // Create the primary event payload
    const payload_event = {
      disabled,
      input: cleanInput,
      previousValue,
      quoteSection,
      shortUrl,
      type,
      value: cleanValue,
    };

    // Add all the payload pieces together to get the full payload
    const payload = Object.assign({}, payload_event, payload_state, payloadOverride);

    // Add the bound policy number if it can be parsed from the url
    const boundPolicyNumber = this.getBoundPolicyNumberFromUrl(originalUrl);
    if (boundPolicyNumber !== null) {
      payload.boundPolicyNumber = boundPolicyNumber;
    }

    /**
     * The `eventName` is a unique string that identifies a group of events.
     * Its value is calculated based on an event's type.
     * TODO (LT or WC): Add a little more context here!
     */
    const eventName = this.generateEventNameByType({
      type,
      input: cleanInput,
      shortUrl,
      useLegacyEventName,
    });

    // Uncomment the line below to debug event properties
    // console.log(eventName, payload);

    // Uncomment the line below to debug most common event properties
    // console.log({ eventName, type, input: cleanInput, value: cleanValue, shortUrl });

    // Uncomment the line below to debug a URL cleanup
    // console.log({ originalUrl: payload_state['page'], shortUrl: shortUrl });

    // Uncomment the line below to debug the `boundPolicyNumber` property
    // console.log({ originalUrl: payload_state['page'], boundPolicyNumber: payload.boundPolicyNumber });

    // Send events to Amplitude
    this.logEventInAmplitude(eventName, payload);

    // Send events to the EventProcessor, an Attune-owned service
    // Note: unlike calls to the global Amplitude instance, these API calls are not batched
    this.sub.add(this.analogueCall(eventName, payload).subscribe());

    // Stop any key counting listeners and reset related state
    if (type === AmplitudePayloadType.Page) {
      this.previousPage = '';
    }
    $(document).off(AmplitudeHtmlEvent.Keypress);
    this.keyCount = 0;
    this.previousValue = void 0;
    this.inTransition = false;
  }

  // TODO: When migrating the synthetic events to new event naming system,
  // refactor this method so that including the shortUrl is opt-in, not opt-out
  generateEventNameByType({
    type,
    input,
    shortUrl,
    useLegacyEventName = false,
  }: {
    type: AmplitudePayloadType;
    input: string;
    shortUrl: string;
    useLegacyEventName?: boolean;
  }): string {
    const isSyntheticEvent = SYNTHETIC_EVENT_TYPES.includes(type);

    // Synthetic events: use the unique input string by default
    if (isSyntheticEvent && !useLegacyEventName) {
      return input;
    }

    // Synthetic events: use the url + input if legacy event name is needed
    if (isSyntheticEvent && useLegacyEventName) {
      return `${shortUrl} :: ${input}`;
    }

    // Page events: use the unique input string (`page_enter`, `navigation_error`, or `navigation_cancel`)
    if (type === AmplitudePayloadType.Page) {
      return input;
    }

    // Organic events (and all other types): use type
    return type;
  }

  getShortUrl(originalUrl: string): string {
    // NOTE: The cleanup style in this function is inconsistent in order reduce
    // risk as it was extracted out of `submitEvent`. It can definitely be
    // (carefully) refactored!
    let shortUrl = originalUrl
      .replace(UNIQUE_ID_REGEX, '')
      .replace(HISCOX_POLICY_ID_REGEX, '')
      .replace(QUERY_PARAMS_REGEX, '')
      .replace(FRAGMENT_ID_REGEX, '')
      .replace(HELP_CENTER_FORM_ID_REGEX, '$1');

    // Remove the invoice number from the url
    if (shortUrl.includes('invoice')) {
      shortUrl = shortUrl.replace(/(^\/.*?\/.*?\/).*/g, '$1');
    }

    // Remove the policy number from the url
    if (shortUrl.match(TERMS_AND_POLICY_PAGE_REGEX)) {
      shortUrl = shortUrl.replace(/(^\/.*?\/.*?\/).*/g, '$1');
    }

    return shortUrl;
  }

  getAdpPage(): string {
    return this.getShortUrl(window.location.href);
  }

  getBoundPolicyNumberFromUrl(originalUrl: string): string | null {
    const match = /(?:terms|policy)\/([^\/]+)/g.exec(originalUrl);
    if (match) {
      // Note: policy number should be matched by first capture group.
      return match[1];
    }

    return null;
  }

  /////////////////////////////////////
  // Getters and Setters             //
  /////////////////////////////////////

  // TODO: Consider making `quoteSection` and `isDisabled` static methods
  // since this logic is used in the core Amplitude `submitEvent`
  quoteSection(object: HTMLElement): string {
    const formHeading = $(object).closest('.app-page-form').find('h1').text();

    if (formHeading) {
      return formHeading;
    }
    return $(object).closest('.app-modal').find('h1').text();
  }

  // determines whether an event in on a disabled object
  isDisabled(obj: HTMLElement): boolean {
    return !!(
      $(obj).hasClass('disabled') ||
      obj.getAttribute('disabled') ||
      $(obj).hasClass('button__discouraged')
    );
  }

  sleepySubmitEvent({ input, ms, type, value }: AmplitudeSubmitEventPayload & { ms: number }) {
    setTimeout(() => {
      this.submitEvent({
        input,
        type,
        value,
      });
    }, ms);
  }

  //////////////////////////////////////
  // Insured account functions        //

  setAccountInfo(): Observable<InsuredAccount | null> {
    let maybeAccountId;
    let currentRoute: ActivatedRoute | null = this.route;
    while (currentRoute) {
      maybeAccountId = <any>get(currentRoute, 'params.value.accountId');
      if (maybeAccountId) {
        break;
      }
      currentRoute = currentRoute.firstChild;
    }

    if (maybeAccountId && this.currentUserId) {
      this.currentAccountId = maybeAccountId;
      this.currentAccountDetails = null;
      return this.insuredAccountService.get(maybeAccountId);
    } else {
      this.currentAccountId = '';
      this.currentAccountDetails = null;
      return observableOf(null);
    }
  }

  getAccountDetail(param: string) {
    return get(this.currentAccountDetails, param, '');
  }

  getUserDetail(param: string) {
    return get(this.currentUserDetails, param, '');
  }

  //////////////////////////////////////
  // Quote functions                  //

  getTransactionID() {
    return (
      get(this.route, 'firstChild.params._value.policyId') ||
      get(this.route, 'firstChild.firstChild.params._value.policyId')
    );
  }

  setPolicyDetails(details: any) {
    this.currentPolicyDetails = details;
  }

  getTsRequestId() {
    const currentPolicyTSID = this.getQuoteDetail('tsRequestId');
    if (!isEmpty(currentPolicyTSID)) {
      return currentPolicyTSID;
    } else if (this.newQuoteTSID) {
      return this.newQuoteTSID;
    }
  }

  getPreviousTsRequestId() {
    const transactionId = this.getTransactionID();
    if (
      transactionId &&
      this.currentPolicyDetails &&
      this.router.url.includes('/edit') &&
      transactionId === this.currentPolicyDetails.id
    ) {
      return this.currentPolicyDetails['tsRequestId'];
    }
    return '';
  }

  getQuoteDetail(param: string, patternCode?: Boolean) {
    const transactionId = this.getTransactionID();
    if (
      transactionId &&
      this.currentPolicyDetails &&
      !this.router.url.includes('/edit') &&
      transactionId === this.currentPolicyDetails.id
    ) {
      return patternCode
        ? this.currentPolicyDetails['patternCodes'][param]
        : this.currentPolicyDetails[param];
    }
    return '';
  }

  setNewQuoteTSID(tsRequestId: string) {
    this.newQuoteTSID = tsRequestId;
  }

  unsetQuoteTSIDs() {
    this.newQuoteTSID = '';
  }

  determineInsProduct(pageUrl: string) {
    const productMatch = /accounts\/[0-9]+\/(.*?)(\/| +|$)/g.exec(pageUrl);

    // Return hardcoded 'bop' when url returns `terms`
    if (productMatch && productMatch[1] === 'terms') {
      return 'bop';
    }

    // For most page urls, `productMatch` will resolve to 'bop' or 'workers-comp'
    if (productMatch) {
      return productMatch[1];
    }

    return '';
  }

  //////////////////////////////////////
  // Intuit quote functions           //
  public getIntuitSessionId(): string {
    return this.intuitSessionId || '';
  }

  public setIntuitSessionId(sessionId: string): void {
    this.intuitSessionId = sessionId;
  }

  public getIntuitQuotePremium(): string {
    return this.intuitQuotePremium;
  }

  public setIntuitQuotePremium(premium: string): void {
    this.intuitQuotePremium = premium;
  }

  //////////////////////////////////////
  //       Hiscox functions           //
  setGlPolicyDetails(details: any | null) {
    this.currentGlPolicyDetails = details;
  }

  //////////////////////////////////////
  // Workers Comp functions           //
  getWcUuid() {
    if (this.newWcUuid) {
      return this.newWcUuid;
    } else if (this.router.url.includes('workers-comp')) {
      return get(this.route, 'firstChild.params._value.quoteUuid');
    }
  }

  getWcPolicyId() {
    return (
      // This is not implemented yet
      // get(this.route, 'firstChild.params._value.wcPolicyId') ||
      this.getWcDetail('policyNumber')
    );
  }

  setWcPolicyDetails(details: any | null) {
    this.currentWcPolicyDetails = details;
  }

  getWcDetail(param: string) {
    const wcId = this.getWcUuid();
    if (wcId && this.currentWcPolicyDetails && wcId === this.currentWcPolicyDetails.uuid) {
      return this.currentWcPolicyDetails[param];
    }
    return '';
  }

  // TODO(tyler): switch URL parameter https://bitbucket.org/HamiltonPlatform/agentportal/branch/temp_branch_to_hold_amp_corrections

  setNewWcID(quoteWcUuid: string) {
    this.newWcUuid = quoteWcUuid;
  }

  // TODO(tyler): switch URL parameter https://bitbucket.org/HamiltonPlatform/agentportal/branch/temp_branch_to_hold_amp_corrections

  unsetWcIDs() {
    this.newWcUuid = '';
  }

  //////////////////////////////////////
  // Mainstreet Excess functions      //

  setMSExcessDetails(details: any) {
    this.currentMSExcessDetails = details;
  }

  getMSExcessDetail(param: string, patternCode?: Boolean) {
    const transactionId = this.getTransactionID();
    if (
      transactionId &&
      this.currentMSExcessDetails &&
      transactionId === this.currentMSExcessDetails.linkedJobId
    ) {
      return patternCode
        ? this.currentMSExcessDetails['patternCodes'][param]
        : this.currentMSExcessDetails[param];
    }
    return '';
  }
}
