import {
  BopAdditionalInsuredBusiness,
  AdditionalInsuredType,
  AdditionalInsuredPlacement,
  ADDITIONAL_INSURED_MAPPING,
} from 'app/features/attune-bop/models/bop-additional-insured-business';
import { AdditionalInsured, BuildingAdditionalInsured, GwContact } from 'app/bop/guidewire/typings';

const additionalInsuredTypeMap: { [key: string]: string } = {
  [AdditionalInsuredType.CONTRACT_AGREEMENT_PERMIT]: 'CONAGRPER',
  [AdditionalInsuredType.CONTROLLING_INTEREST]: 'CONTROL',
  [AdditionalInsuredType.DESIGNATED_PERSON]: 'DESIG',
  [AdditionalInsuredType.ENGINEERS]: 'ENG',
  [AdditionalInsuredType.ENGINEERS_NOT_ENGAGED]: 'ENGNOT',
  [AdditionalInsuredType.STATE_SUBDIVISIONS]: 'GOVPREM',
  [AdditionalInsuredType.GRANTOR_OF_FRANCHISE]: 'GRANTFRAN',
  [AdditionalInsuredType.OWNERS]: 'OLCADDLINSREQCONSCONTRACT_HUSA',
  [AdditionalInsuredType.STATE_SUBDIVISIONS_AUTHORIZATIONS]: 'STATEPOLPERMAUTH',
  [AdditionalInsuredType.VENDORS]: 'VENDOR',
  [AdditionalInsuredType.CO_OWNER]: 'COOWN',
  [AdditionalInsuredType.LESSOR_OF_EQUIPMENT]: 'LESSEQUIP',
  [AdditionalInsuredType.MANAGER_OF_PREMISES]: 'MGRPREM',
  [AdditionalInsuredType.OWNERS_OF_LAND]: 'OWNLAND',
  [AdditionalInsuredType.OWNERS_SCHEDULED_PERSON]: 'OLCSCHED',
  [AdditionalInsuredType.OWNERS_COMPLETED_OPS]: 'OLCCOMPLETE',
  [AdditionalInsuredType.LOSS_OF_RENTAL_VALUE]: 'LRVLANDDESIGPAY_HUSA',
  [AdditionalInsuredType.MORTGAGEE]: 'MORT',
  [AdditionalInsuredType.BUILDING_OWNER]: 'BLDGOWNADDLINS_HUSA',
  [AdditionalInsuredType.LOSS_PAYABLE]: 'LOSSPAY',
  [AdditionalInsuredType.MORTGAGE_HOLDER]: 'Mortgageholder',
};

export const additionalInsuredTypeMapFromGW: { [key: string]: AdditionalInsuredType } = {
  BLDGOWNADDLINS_HUSA: AdditionalInsuredType.BUILDING_OWNER,
  CONAGRPER: AdditionalInsuredType.CONTRACT_AGREEMENT_PERMIT,
  CONTROL: AdditionalInsuredType.CONTROLLING_INTEREST,
  COOWN: AdditionalInsuredType.CO_OWNER,
  DESIG: AdditionalInsuredType.DESIGNATED_PERSON,
  ENG: AdditionalInsuredType.ENGINEERS,
  ENGNOT: AdditionalInsuredType.ENGINEERS_NOT_ENGAGED,
  GOVPREM: AdditionalInsuredType.STATE_SUBDIVISIONS,
  GRANTFRAN: AdditionalInsuredType.GRANTOR_OF_FRANCHISE,
  LESSEQUIP: AdditionalInsuredType.LESSOR_OF_EQUIPMENT,
  LOSSPAY: AdditionalInsuredType.LOSS_PAYABLE,
  LRVLANDDESIGPAY_HUSA: AdditionalInsuredType.LOSS_OF_RENTAL_VALUE,
  MGRPREM: AdditionalInsuredType.MANAGER_OF_PREMISES,
  MORT: AdditionalInsuredType.MORTGAGEE,
  Mortgageholder: AdditionalInsuredType.MORTGAGE_HOLDER,
  OLCADDLINSREQCONSCONTRACT_HUSA: AdditionalInsuredType.OWNERS,
  OLCCOMPLETE: AdditionalInsuredType.OWNERS_COMPLETED_OPS,
  OLCSCHED: AdditionalInsuredType.OWNERS_SCHEDULED_PERSON,
  OWNLAND: AdditionalInsuredType.OWNERS_OF_LAND,
  STATEPOLPERMAUTH: AdditionalInsuredType.STATE_SUBDIVISIONS_AUTHORIZATIONS,
  VENDOR: AdditionalInsuredType.VENDORS,
};

// Expects 0-indexed array indexes
const locationIdFilter = (
  additionalInsured: BopAdditionalInsuredBusiness,
  locationIndex: number
): boolean => {
  const locationId = `location-${locationIndex + 1}-1`;
  return additionalInsured.relatedLocation === locationId;
};

const getContactDenorm = (additionalInsured: BopAdditionalInsuredBusiness): GwContact => ({
  AddressBookUID: null,
  EmailAddress1: null,
  Name: additionalInsured.businessName,
  PrimaryAddress: additionalInsured.address
    ? {
        AddressLine1: additionalInsured.address.addressLine1,
        AddressLine2:
          additionalInsured.address.addressLine2 === undefined
            ? null
            : additionalInsured.address.addressLine2,
        AddressType: additionalInsured.addressType,
        City: additionalInsured.address.city,
        County: null,
        PostalCode: additionalInsured.address.zip,
        State: additionalInsured.address.state,
      }
    : undefined,
  Subtype: 'Company',
  WorkPhone: null,
  'entity-Person': null,
});

const getAdditionalInsured = (
  additionalInsured: BopAdditionalInsuredBusiness
): AdditionalInsured => ({
  ContactDenorm: getContactDenorm(additionalInsured),
  PolicyAdditionalInsuredDetails: {
    Entry: [
      {
        AdditionalInfoValue_HUSA: additionalInsured.additionalInformation,
        AdditionalInsuredType: String(additionalInsuredTypeMap[additionalInsured.type]),
        LimitOfInsurance_HUSA: additionalInsured.limitOfInsurance,
      },
    ],
  },
  SeqNumber: null,
  Subtype: 'PolicyAddlInsured',
});

export function getAdditionalInsuredsForPolicy(
  additionalInsuredBusinesses: Array<BopAdditionalInsuredBusiness>
) {
  return additionalInsuredBusinesses
    .filter((additionalInsured) => {
      return ADDITIONAL_INSURED_MAPPING[additionalInsured.type] === undefined;
    })
    .map((additionalInsured) => getAdditionalInsured(additionalInsured));
}

export function getAdditionalInsuredsForLocation(
  additionalInsuredBusinesses: Array<BopAdditionalInsuredBusiness>,
  location: BopLocation,
  locationIndex: number
) {
  return additionalInsuredBusinesses
    .filter(
      (additionalInsured) =>
        AdditionalInsuredPlacement.LOCATION === ADDITIONAL_INSURED_MAPPING[additionalInsured.type]
    )
    .filter((additionalInsured) => locationIdFilter(additionalInsured, locationIndex))
    .map((additionalInsured) => getAdditionalInsured(additionalInsured));
}

export function getAdditionalInterestsForBuilding(
  additionalInsuredBusinesses: Array<BopAdditionalInsuredBusiness>,
  building: BopBuilding,
  locationIndex: number,
  buildingIndex: number
): BuildingAdditionalInsured[] {
  return additionalInsuredBusinesses
    .filter(
      (additionalInsured) =>
        AdditionalInsuredPlacement.BUILDING === ADDITIONAL_INSURED_MAPPING[additionalInsured.type]
    )
    .filter((additionalInsured) => locationIdFilter(additionalInsured, locationIndex))
    .map((additionalInsured) => {
      const buildingAdditionalInsured: BuildingAdditionalInsured = {
        AdditionalInterestType: additionalInsuredTypeMap[additionalInsured.type],
        ContractNumber: String(additionalInsured.contractNumber),
        PolicyAddlInterest: {
          ContactDenorm: getContactDenorm(additionalInsured),
        },
      };

      if (
        additionalInsured.type === AdditionalInsuredType.BUILDING_OWNER ||
        additionalInsured.type === AdditionalInsuredType.LOSS_PAYABLE
      ) {
        buildingAdditionalInsured.PropertyDescription = String(
          additionalInsured.buildingDescription
        );
      }

      if (additionalInsured.type === AdditionalInsuredType.LOSS_PAYABLE) {
        buildingAdditionalInsured.BuildingParagraph = String(additionalInsured.paragraph);
      }

      return buildingAdditionalInsured;
    });
}
