<section class="top-class-codes">
  <h2>Browse Our Top Classes</h2>
  <ng-container *ngIf="loading">
    <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div class="top-class-code-filters">
      <div class="top-class-code-filter-label">
        <span>Filter:</span>
      </div>
      <div>
        <app-form-field-select
          inputId="industry"
          labelText="Industry"
          [form]="form"
          nameOfFormControl="industry"
          placeholder="Industry"
          [availableOptions]="industries"
        >
        </app-form-field-select>
      </div>
      <div>
        <app-form-field-select
          inputId="state"
          labelText="State"
          [form]="form"
          nameOfFormControl="state"
          placeholder="State"
          [availableOptions]="getStates()"
        >
        </app-form-field-select>
      </div>
    </div>
    <table class="top-class-code-table">
      <thead>
        <tr>
          <th class="class-column">Class</th>
          <th>Where we win</th>
          <th class="select-column"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let topClassCode of topClassCodes">
          <td class="td-align-top">
            <p class="mb-0">{{ topClassCode.description }}</p>
            <p class="p__gray mb-0">{{ topClassCode.code }} | {{ topClassCode.category }}</p>
          </td>
          <td>
            <div class="eligible-products-td">
              <app-product-badge
                *ngFor="let product of topClassCode.products"
                [product]="product.productCombination.product"
                [pasSource]="product.productCombination.pasSource"
              ></app-product-badge>
            </div>
          </td>
          <td class="td-align-middle">
            <a
              (click)="
                selectClassCode(
                  topClassCode.naicsCode,
                  topClassCode.naicsDescription,
                  topClassCode.naicsHash
                )
              "
              (keyup.enter)="
                selectClassCode(
                  topClassCode.naicsCode,
                  topClassCode.naicsDescription,
                  topClassCode.naicsHash
                )
              "
            >
              Get a Quote
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</section>
