import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dropdown-subsection',
  templateUrl: './dropdown-subsection.component.html',
})
export class DropdownSubsectionComponent {
  @Input()
  header = 'subsection';

  isExpanded = false;
}
