import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { pick, entries, omit } from 'lodash';

import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';
import { Industry } from '../../../shared/services/naics.service';
import { HISCOX_PL_HAPPY_PATH_FORM_DATA_V4 } from '../models/hiscox-pl-happy-path-form-data';
import {
  HiscoxFormStepPath,
  HISCOX_PRODUCTS,
  HISCOX_API_VERSION,
  CyberUpsellFormLogicControlName,
} from '../models/hiscox-types';
import { getFormGroup, getControl, enableDisableControl } from 'app/shared/helpers/form-helpers';
import { BehaviorSubject, Observable, combineLatest, of as observableOf, merge } from 'rxjs';
import { AccountSummaryView } from '../../insured-account/services/insured-account-summary.service';
import { DropdownSearchNode } from '../../../shared/form-dsl/constants/form-dsl-typings';
import { filter, map, switchMap } from 'rxjs/operators';
import { HiscoxGlFormDataFieldV4 } from '../models/gl-constants';
import { HiscoxPlFormDataFieldV4 } from '../models/pl-constants';
import { ClassCodeSelection, ProductAvailability } from 'app/features/digital-carrier/models/types';
import {
  CoalitionCyberFormDataFormatted,
  CoalitionCyberFormDataRaw,
  CoalitionCyberQuestion,
  FirstPartyCoverageNestedQuestion,
  CyberQuestionEnablementFlags,
  NAICS_CODE,
} from '../../coalition/models/cyber-typings.model';
import {
  CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M,
  ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  ESSENTIAL_COVERAGES,
  MOST_POPULAR_COVERAGES,
  ESSENTIAL_DEFAULT_RETENTION_INDEX,
  MOST_POPULAR_DEFAULT_RETENTION_INDEX,
  ADMITTED_KNOCK_OUT_STATES,
  NAICS_CODES_TO_INDUSTRY_IDS,
} from '../../coalition/models/cyber-constants.model';
import {
  formatFormFieldValues,
  employeeCountNumbertoString,
  removeIneligibleCyberCoverages,
} from '../../coalition/models/cyber-form-helpers.model';

@Injectable()
export class HiscoxQuoteFormService extends FormDslSteppedFormService {
  submitted = false;

  defaultFormStep = 'quoteBasic';

  DEFAULT_NUMBER_OF_PAYMENTS = '1';

  isHappyPath = false;

  // Properties for Cyber upsell
  public cyberIndustryFlagsSubject: BehaviorSubject<CyberQuestionEnablementFlags | null> =
    new BehaviorSubject(null);

  public cyberDescriptionOfBusinessSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public accountSummarySubject: BehaviorSubject<AccountSummaryView | null> = new BehaviorSubject(
    null
  );

  public productAvailabilitiesSubject: BehaviorSubject<ProductAvailability[] | null> =
    new BehaviorSubject<ProductAvailability[] | null>(null);

  public naicsCodeSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);

  industryId$: Observable<number | null>;
  private industryIdSubject: BehaviorSubject<number | null> = new BehaviorSubject(null);

  private upsellDependenciesAreSet = false;
  // END Properties for Cyber upsell

  public apiVersionSubject: BehaviorSubject<HISCOX_API_VERSION | null> = new BehaviorSubject(null);

  constructor(public formBuilder: UntypedFormBuilder) {
    super(formBuilder);
    this.industryId$ = this.industryIdSubject.asObservable();
  }

  formDependenciesInit(formStep: HiscoxFormStepPath) {
    super.formDependenciesInit(formStep);

    if (!this.upsellDependenciesAreSet && formStep === HiscoxFormStepPath.QUOTE_RATING) {
      this.setCyberUpsellDependencies();
    }
  }

  setHappyPathData(product: HISCOX_PRODUCTS) {
    switch (product) {
      case HISCOX_PRODUCTS.pl:
        this.happyPathFormData = HISCOX_PL_HAPPY_PATH_FORM_DATA_V4;
        break;
      case HISCOX_PRODUCTS.gl:
      default:
        this.happyPathFormData = {};
        break;
    }
  }

  fillInHappyPath() {
    this.isHappyPath = true;
    const currentFormPath = this.currentStep.formPath;

    if (currentFormPath && !this.patchedFormSteps[currentFormPath]) {
      this.patchStepForHappyPath(currentFormPath as HiscoxFormStepPath);
    }
  }

  patchStepForHappyPath(currentFormPath: HiscoxFormStepPath) {
    this.patchedFormSteps[currentFormPath] = true;
    const formData = this.happyPathFormData[currentFormPath];
    if (formData) {
      const currentFormGroup = getFormGroup(this.form, currentFormPath);
      currentFormGroup.patchValue(formData);
    }
  }

  // Methods for Cyber Upsell
  isStandaloneCyberSelected() {
    const quoteRatingFormStep = getFormGroup(this.form, HiscoxFormStepPath.QUOTE_RATING);
    const upsellOptInControl = getControl(
      quoteRatingFormStep,
      CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY
    );

    return upsellOptInControl?.enabled && upsellOptInControl?.value === 'Yes';
  }

  setCyberUpsellDependencies() {
    const quoteRatingFormStep = getFormGroup(this.form, HiscoxFormStepPath.QUOTE_RATING);
    const upsellOptInControl = getControl(
      quoteRatingFormStep,
      CyberUpsellFormLogicControlName.INCLUDE_COALITION_CYBER_STANDALONE_POLICY
    );

    if (!upsellOptInControl) {
      // If the form group is not populated yet, we cannot set the dependencies.
      return;
    }

    // TEMP: Disable opt in question for Hiscox V4
    // during Hiscox UAT
    if (this.apiVersionSubject.getValue() === HISCOX_API_VERSION.v4) {
      upsellOptInControl.disable();
      return;
    }

    const companyIndustryIdControl = getControl(
      quoteRatingFormStep,
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    );

    const naicsCodeIsIneligibleControl = getControl(
      quoteRatingFormStep,
      CyberUpsellFormLogicControlName.NAICS_CODE_IS_INELIGIBLE
    );

    // By default, assume the naics code is not available.
    naicsCodeIsIneligibleControl.patchValue(true);

    this.naicsCodeSubject
      .pipe(filter((naicsCode) => !!naicsCode))
      .subscribe((naicsCode: string) => {
        this.industryIdSubject.next(NAICS_CODES_TO_INDUSTRY_IDS[naicsCode]);
        naicsCodeIsIneligibleControl.patchValue(false);
      });

    companyIndustryIdControl.valueChanges.subscribe((industry: Industry) => {
      if (companyIndustryIdControl.enabled && industry) {
        this.industryIdSubject.next(industry.id);
      }
    });

    const requiresTechEOControl = getControl(
      quoteRatingFormStep,
      CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_TECH_EO
    );
    const requiresNetProfitControl = getControl(
      quoteRatingFormStep,
      CyberUpsellFormLogicControlName.INDUSTRY_REQUIRES_NET_PROFIT
    );

    this.cyberIndustryFlagsSubject.subscribe((flags) => {
      if (flags) {
        requiresTechEOControl.patchValue(flags.require_tech_eo);
        requiresNetProfitControl.patchValue(flags.use_gross_profit_net_revenue);
      }
    });

    this.cyberDescriptionOfBusinessSubject.subscribe((filterClassCodes) => {
      if (filterClassCodes.length > 0) {
        const cyberDescriptionOfBusinessNode = this.formTree.find((node) => {
          return node.nameOfFormControl === CoalitionCyberQuestion.COMPANY_INDUSTRY_ID;
        }) as DropdownSearchNode;

        cyberDescriptionOfBusinessNode.queryableResults = filterClassCodes;
      }
    });

    const baseState$ = this.apiVersionSubject.pipe(
      filter((version) => !!version),
      switchMap((version) => {
        const baseStateControlName = 'BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd';
        const baseStateControl = getControl(
          this.form,
          `${HiscoxFormStepPath.QUOTE_BASIC}.${baseStateControlName}`
        );
        return merge(observableOf(baseStateControl.value), baseStateControl.valueChanges);
      })
    );

    const cyberIsAvailable$ = this.productAvailabilitiesSubject.pipe(
      map((availabilities) => {
        if (availabilities) {
          return availabilities.some(({ pasSource, product, classCodeSelection }) => {
            return (
              pasSource === 'coalition' &&
              product === 'cyber_admitted' &&
              classCodeSelection === ClassCodeSelection.ALL
            );
          });
        }

        return false;
      })
    );

    combineLatest(this.accountSummarySubject, baseState$, cyberIsAvailable$).subscribe(
      ([accountSummary, baseState, cyberIsAvailable]) => {
        let shouldEnableUpsell = false;
        if (baseState && accountSummary?.status === 'success') {
          const stateIsIneligibleForAdmitted = ADMITTED_KNOCK_OUT_STATES.includes(baseState);
          const accountHasCyberPolicy = accountSummary.policyTerms.some(
            ({ pasSource, product, status }) => {
              return (
                pasSource === 'coalition' &&
                ['cyber_admitted', 'cyber_surplus'].includes(product) &&
                ['in_force', 'scheduled'].includes(status)
              );
            }
          );

          shouldEnableUpsell =
            !stateIsIneligibleForAdmitted && !accountHasCyberPolicy && cyberIsAvailable;
        }
        enableDisableControl(upsellOptInControl, shouldEnableUpsell);
      }
    );

    this.upsellDependenciesAreSet = true;
  }

  getStandaloneCyberFields(product: HISCOX_PRODUCTS): CoalitionCyberFormDataFormatted {
    const quoteBasicFormGroupValue = getFormGroup(this.form, HiscoxFormStepPath.QUOTE_BASIC).value;
    const quoteRatingFormGroup = getFormGroup(this.form, HiscoxFormStepPath.QUOTE_RATING).value;

    const cyberPayload: CoalitionCyberFormDataRaw = pick(quoteRatingFormGroup, [
      CoalitionCyberQuestion.COMPANY_EMPLOYEE_COUNT,
      CoalitionCyberQuestion.COMPANY_REVENUE,
      CoalitionCyberQuestion.HAS_TECH_EO,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
      CoalitionCyberQuestion.AGGREGATE_LIMIT,
    ]);

    const naicsCode = this.naicsCodeSubject.getValue();
    if (naicsCode) {
      cyberPayload[NAICS_CODE] = naicsCode;
    } else {
      cyberPayload.company_industry_id =
        quoteRatingFormGroup[CoalitionCyberQuestion.COMPANY_INDUSTRY_ID];
    }

    cyberPayload.address = {
      addressLine1: quoteBasicFormGroupValue.BusinessInfo_Locations_Primary_AddrInfo_Addr1,
      addressLine2: quoteBasicFormGroupValue.BusinessInfo_Locations_Primary_AddrInfo_Addr2,
      city: quoteBasicFormGroupValue.BusinessInfo_Locations_Primary_AddrInfo_City,
      state: quoteBasicFormGroupValue.BusinessInfo_Locations_Primary_AddrInfo_StateOrProvCd,
      zip: quoteBasicFormGroupValue.BusinessInfo_Locations_Primary_AddrInfo_PostalCode,
    };

    if (product === HISCOX_PRODUCTS.pl) {
      cyberPayload.company_revenue =
        quoteRatingFormGroup.ProductQuoteRqs_ErrorsAndOmissionsQuoteRq_RatingInfo_EstmtdAnnualGrossSales;

      cyberPayload.effective_date =
        quoteBasicFormGroupValue[
          HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERAGESTARTDATE
        ];
    }

    if (product === HISCOX_PRODUCTS.gl) {
      const employeeCount: number =
        quoteRatingFormGroup.ProductQuoteRqs_GeneralLiabilityQuoteRq_RatingInfo_NumOfEmployees;
      cyberPayload.company_employee_count = employeeCountNumbertoString(employeeCount);
      cyberPayload.effective_date =
        quoteBasicFormGroupValue[
          HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERAGESTARTDATE
        ];
    }

    // For upsell, submit with no domain
    cyberPayload.domain_names = '';

    const essentialAggregateLimitSelected =
      cyberPayload.aggregate_limit === ESSENTIAL_AGGREGATE_LIMIT_INDEX;
    // Coverages are selected based on which aggregate limit the user selected:
    // * Essential bundle for 100K limit
    // * Most Popular bundle for 250K limit
    const { first_party_coverages, third_party_coverages } = essentialAggregateLimitSelected
      ? ESSENTIAL_COVERAGES
      : MOST_POPULAR_COVERAGES;

    cyberPayload.default_retention = essentialAggregateLimitSelected
      ? ESSENTIAL_DEFAULT_RETENTION_INDEX
      : MOST_POPULAR_DEFAULT_RETENTION_INDEX;

    // Since we are only supporting Admitted quotes, we must not include Surplus-specific coverages
    const NON_ADMITTED_COVERAGES = [
      FirstPartyCoverageNestedQuestion.BREACH_RESPONSE,
      FirstPartyCoverageNestedQuestion.TECH_EO,
    ];

    cyberPayload.first_party_coverages = omit(
      first_party_coverages,
      NON_ADMITTED_COVERAGES
    ) as Record<FirstPartyCoverageNestedQuestion, boolean>;
    cyberPayload.third_party_coverages = third_party_coverages;

    // Use default Underwriting question answers
    entries(CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M).forEach(([key, val]) => {
      cyberPayload[key as CoalitionCyberQuestion] = val;
    });
    // Format data to send to SQ
    const formData = formatFormFieldValues(cyberPayload);
    // Remove coverages that are invalid based on the industry flags
    const industryFlags = this.cyberIndustryFlagsSubject.getValue();
    if (industryFlags) {
      return removeIneligibleCyberCoverages(formData, industryFlags);
    }

    return formData;
  }
  // END Methods for Cyber Upsell
}
