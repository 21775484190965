import { v4 as uuidv4 } from 'uuid';
import { ToastDisplay, RewardToastType, RewardName, RedemptionType } from './rewards-types';
import { ToastTemplate } from '../toasts/toast';

// This delay corresponds to the toast animation style in `rewards.scss`
export const STANDARD_DELAY = 5000;
export const GIFT_CARD_TYPE_AND_POINT_VALUE: { [key in RedemptionType]: number } = {
  GIFT_CARD_250: 250,
  GIFT_CARD_50: 50,
};

export const TOAST_DISPLAYS: { [K in RewardName]: ToastDisplay } = {
  BIND_POLICY_FOR_ACCOUNT: {
    message: 'Bound a policy',
    type: RewardToastType.REWARD,
    delay: 0,
  },
  BOP_BIND_FOR_ACCOUNT: {
    message: 'Bound a policy',
    type: RewardToastType.REWARD,
    delay: 0,
  },
  BOP_QUOTE_FOR_ACCOUNT: {
    message: 'Submitted a new quote',
    type: RewardToastType.REWARD,
    delay: 0,
  },
  DCP_QUOTE_FOR_ACCOUNT: {
    message: 'Submitted a new quote',
    type: RewardToastType.REWARD,
    delay: 0,
  },
  QUOTE_FOR_ACCOUNT: {
    message: 'Submitted a new quote',
    type: RewardToastType.REWARD,
    delay: 0,
  },
  GIFT_CARD_250: {
    message: 'You redeemed $25!',
    type: RewardToastType.REDEMPTION,
    delay: 0,
  },
  GIFT_CARD_50: {
    message: 'You redeemed $5!',
    type: RewardToastType.REDEMPTION,
    delay: 0,
  },
  ONE_ATTUNE_BOP_QUOTES_ACHIEVEMENT: {
    message: 'Attune BOP quote!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  ONE_ATTUNE_WC_QUOTES_ACHIEVEMENT: {
    message: 'Attune WC quote!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT: {
    message: 'Employers WC quote!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  ONE_HISCOX_GL_QUOTES_ACHIEVEMENT: {
    message: 'Hiscox GL quote!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  ONE_HISCOX_PL_QUOTES_ACHIEVEMENT: {
    message: 'Hiscox PL quote!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT: {
    message: 'Coalition Cyber quote!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT: {
    message: 'Employers WC bundle!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT: {
    message: 'Hiscox PL bundle!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT: {
    message: 'Coalition Cyber bundle!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BOP_QUOTE_ARTISAN_ACHIEVEMENT: {
    message: 'Quoted an artisan!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BOP_QUOTE_RETAIL_ACHIEVEMENT: {
    message: 'Quoted a retail business!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BOP_QUOTE_SERVICE_ACHIEVEMENT: {
    message: 'Quoted a professional service!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BOP_QUOTE_OFFICE_ACHIEVEMENT: {
    message: 'Quoted an office!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
  BOP_QUOTE_WHOLESALER_ACHIEVEMENT: {
    message: 'Quoted a wholesale business!',
    type: RewardToastType.ACHIEVEMENT,
    delay: 6000,
  },
};

export class RewardToast {
  id: string;
  type = ToastTemplate.REWARD;
  display: ToastDisplay;
  removing: false;
  constructor(public name: RewardName, public value: number) {
    this.id = uuidv4();
    this.display = TOAST_DISPLAYS[name];
  }
}
