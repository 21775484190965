import { Component, Input, OnInit } from '@angular/core';
import {
  ServicingInfoCarrier,
  ServicingInfo,
  SERVICING_INFO_BY_CARRIER,
  ServicingInfoProduct,
} from './helpers';

@Component({
  selector: 'app-carrier-partner-servicing-info',
  templateUrl: './carrier-partner-servicing-info.component.html',
})
export class CarrierPartnerServicingInfoComponent implements OnInit {
  @Input() showHeading = false;
  @Input() carrier: ServicingInfoCarrier;
  @Input() product: ServicingInfoProduct;

  servicingInfo: ServicingInfo;

  constructor() {}

  ngOnInit(): void {
    this.servicingInfo = SERVICING_INFO_BY_CARRIER[this.carrier];
  }
}
