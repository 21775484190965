import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  AiCheckerResult,
  AiCheckerService,
} from 'app/features/ai-checker/services/ai-checker.service';
import { Observable, tap, of as observableOf } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { getControl } from '../../../../shared/helpers/form-helpers';
import { SentryService } from '../../../../core/services/sentry.service';
import { BOP_GUIDELINE_LINK_ACCREDITED } from '../../../support/models/support-constants';

@Component({
  selector: 'app-ai-checker-page.app-page.app-page__ai-checker',
  templateUrl: 'ai-checker-page.component.html',
})
export class AiCheckerPageComponent {
  constructor(
    private aiCheckerService: AiCheckerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sentryService: SentryService
  ) {}

  public BOP_GUIDELINE_LINK_ACCREDITED = BOP_GUIDELINE_LINK_ACCREDITED;

  public hasSubmittedAiSearch: boolean = false;
  public aiSearchText: string;
  public fetchingSearchResults: boolean = false;
  public streamingSearchResults: boolean = false;
  public hasSearchError: boolean = false;
  public searchResults$: Observable<AiCheckerResult | null>;

  public form = this.formBuilder.group({
    queryText: [null, Validators.required],
  });

  submitAiSearch() {
    this.aiSearchText = getControl(this.form, 'queryText').value;
    this.hasSubmittedAiSearch = true;
    this.fetchingSearchResults = true;
    this.hasSearchError = false;
    this.searchResults$ = this.aiCheckerService.fetchAiSearch({ queryStr: this.aiSearchText }).pipe(
      tap(() => {
        this.fetchingSearchResults = false;
        this.streamingSearchResults = true;
      }),
      catchError((error) => {
        this.fetchingSearchResults = false;
        this.hasSubmittedAiSearch = false;
        this.hasSearchError = true;
        this.sentryService.notify('AI Eligibility Bot: Error fetching eligibility response', {
          severity: 'error',
          underlyingError: error,
          underlyingErrorMessage: error?.message,
        });
        return observableOf(null);
      }),
      finalize(() => {
        this.streamingSearchResults = false;
      })
    );
  }

  editAiSearch() {
    this.hasSubmittedAiSearch = false;
  }

  handleGetQuote() {
    this.router.navigate(['/accounts/new']);
  }
}
