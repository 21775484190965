import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { map, debounceTime, share } from 'rxjs/operators';
import { AttuneBopQuoteFormService } from 'app/features/attune-bop/services/attune-bop-quote-form.service';
import { Observable, Subscription } from 'rxjs';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import {
  INFORMATIONAL_NAMES,
  NewInformationalService,
} from 'app/shared/services/new-informational.service';
import { MOBILE_WIDTH_THRESHOLD } from 'app/constants';
import { environment } from 'environments/environment';
import { enableDisableControl, getControl } from '../helpers/form-helpers';

@Component({
  selector: 'app-building-lessors-risk-form',
  templateUrl: './building-lessors-risk-form.component.html',
})
export class BuildingLessorsRiskFormComponent implements OnInit, OnDestroy {
  @Input() accountId: string;
  @Input() tsRequestId: string;
  @Input() locationIndex: number;
  @Input() buildingIndex: number;
  @Input() buildingLessorsRiskFormGroup: UntypedFormGroup;
  @Input() buildingExposureFormGroup: UntypedFormGroup;
  @Input() formService: AttuneBopQuoteFormService;
  @Input() submitted: boolean;
  @Output() openFirstTimeLROModal: EventEmitter<void> = new EventEmitter();

  tenantTotalSquareFootage$: Observable<number>;
  tenantTotalRent$: Observable<number>;
  tenantRentIsValid$: Observable<boolean>;

  sub: Subscription = new Subscription();

  constructor(private newInformationalService: NewInformationalService) {}

  ngOnInit(): void {
    // DO not show the modal on mobile.
    if (
      environment.lroFlowEnabled &&
      !this.newInformationalService.getValue(INFORMATIONAL_NAMES.LRO_STEP_VIDEO) &&
      window.innerWidth > MOBILE_WIDTH_THRESHOLD
    ) {
      // Toggle modal in parent component here (ensures the modal will be centered relative to the entire parent component)
      this.openFirstTimeLROModal.emit();
      this.newInformationalService.incrementValue(INFORMATIONAL_NAMES.LRO_STEP_VIDEO);
    }
    this.tenantTotalSquareFootage$ = this.createTotalSqFtObservable();
    this.tenantTotalRent$ = this.createTotalRentObservable();
    this.tenantRentIsValid$ = this.createTenantRentValidObservable();

    // Disable form fields for vacant tenants
    this.sub.add(
      this.tenantsFormArray.valueChanges
        .pipe(debounceTime(200))
        .subscribe((tenants: LROTenant[]) => {
          for (let i = 0; i < tenants.length; i++) {
            const tenantFormGroup = this.tenantsFormArray.at(i) as UntypedFormGroup;
            const isOccupied = tenants[i].occupancyStatus === 'Occupied';

            enableDisableControl(getControl(tenantFormGroup, 'tenantName'), isOccupied);
            enableDisableControl(getControl(tenantFormGroup, 'descriptionOfBusiness'), isOccupied);
            enableDisableControl(getControl(tenantFormGroup, 'rent'), isOccupied);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get tenantsFormArray() {
    return this.buildingLessorsRiskFormGroup.get('lessorsRiskTenants') as UntypedFormArray;
  }

  get landlordRequirementsFormGroup() {
    return this.buildingLessorsRiskFormGroup.get('landlordRequirements') as UntypedFormGroup;
  }

  get tenantResponsibilitiesFormGroup() {
    return this.buildingLessorsRiskFormGroup.get('tenantResponsibilities') as UntypedFormGroup;
  }

  get buildingSquareFootage() {
    return this.buildingExposureFormGroup.get('squareFootage')?.value;
  }

  createTotalSqFtObservable(): Observable<number> {
    return this.tenantsFormArray.valueChanges.pipe(
      debounceTime(200),
      map((tenants: LROTenant[]) => {
        return tenants.reduce((prev, curr) => {
          const currentSquareFootage = parseMaskedInt(curr.squareFootage);
          if (currentSquareFootage) {
            return prev + currentSquareFootage;
          }
          return prev;
        }, 0);
      }),
      share()
    );
  }

  createTotalRentObservable(): Observable<number> {
    return this.tenantsFormArray.valueChanges.pipe(
      debounceTime(200),
      map((tenants: LROTenant[]) => {
        return tenants.reduce((prev, curr) => {
          const currentRent = parseMaskedInt(curr.rent);
          if (currentRent) {
            return prev + currentRent;
          }
          return prev;
        }, 0);
      })
    );
  }

  createTenantRentValidObservable() {
    return this.tenantTotalSquareFootage$.pipe(
      map((total) => {
        return total === parseMaskedInt(this.buildingSquareFootage);
      })
    );
  }

  addTenant() {
    this.formService.addLessorsRiskTenant(this.tenantsFormArray);
  }

  removeTenant(index: number) {
    this.formService.removeLessorsRiskTenant(this.tenantsFormArray, index);
  }

  getFileUploadMetadata() {
    return {
      accountId: this.accountId,
      locationNumber: String(this.locationIndex),
      buildingNumber: String(this.buildingIndex),
      tsRequestId: this.tsRequestId,
    };
  }

  getCssForSquareFootageField(isLargestTenant: boolean) {
    // We do this because an individual tenant's sqft form control may be valid
    // but the form array itself may be invalid if the square footage !== building sqft.
    // We also need a different css class besides form-field__error
    // since that will be overridden in the text field component.
    if (this.submitted && this.tenantsFormArray.invalid && !isLargestTenant) {
      return 'form-field__no-margin lro-square-footage__error';
    }

    return 'form-field__no-margin';
  }
}
