import * as _ from 'lodash';
import * as moment from 'moment';
import {
  US_DATE_MASK,
  ISO_DATE_MASK,
  US_DATE_MASK_PATTERN,
  ISO_DATE_MASK_PATTERN,
  USD_MONEY_PATTERN,
  USD_PHONE_PATTERN,
} from '../../../constants';
import { FormDslData, FormDslNode } from '../constants/form-dsl-typings';

// TODO: mergeTwoFormSteps and mergeSeveralFormSteps can be replaced by
// _.merge({}, formDataPriorStep1, formDataPriorStep2) and _.merge({},
// ...formDataPrior).

export const mergeTwoFormSteps = (formDataPriorStep1: any, formDataPriorStep2: any): any => {
  return { ...formDataPriorStep1, ...formDataPriorStep2 };
};

export const mergeSeveralFormSteps = (formDataPrior: any[]): any => {
  let formDataResult: { [key: string]: any } = {};
  formDataPrior.forEach((s) => {
    formDataResult = mergeTwoFormSteps(formDataResult, s);
  });
  return formDataResult;
};

export const removeNullData = (formDataPrior: { [key: string]: any }): { [key: string]: any } => {
  const formDataEntriesFiltered = _.entries(formDataPrior).filter((pair: [string, any]) => {
    return pair[1];
  });
  return _.fromPairs(formDataEntriesFiltered);
};

export const formatDateForDisplay = (formDataPrior: FormDslData): FormDslData => {
  const formDataEntriesMapped = _.entries(formDataPrior).map((pair: [string, any]) => {
    const key = pair[0];
    let val = pair[1];
    if (ISO_DATE_MASK_PATTERN.test(val)) {
      val = moment(val, ISO_DATE_MASK).format(US_DATE_MASK);
    }
    return [key, val];
  });
  return _.fromPairs(formDataEntriesMapped);
};

export const formatPhoneForDisplay = (formDataPrior: FormDslData): FormDslData => {
  const formDataEntriesMapped = _.entries(formDataPrior).map((pair: [string, any]) => {
    const key = pair[0];
    let val = pair[1];
    if (USD_PHONE_PATTERN.test(val)) {
      val = val.replace(/-|\+/g, '');
      if (val.startsWith('1')) {
        val = val.slice(1);
      }
    }
    return [key, val];
  });
  return _.fromPairs(formDataEntriesMapped);
};

export const correctDateFormat = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  const formDataEntriesMapped = _.entries(formDataPrior).map((pair: [string, any]) => {
    const key = pair[0];
    let val = pair[1];
    if (US_DATE_MASK_PATTERN.test(val)) {
      val = moment(val, US_DATE_MASK).format(ISO_DATE_MASK);
    }
    return [key, val];
  });
  return _.fromPairs(formDataEntriesMapped);
};

export const correctMoneyFormat = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  const formDataEntriesMapped = _.entries(formDataPrior).map((pair: [string, any]) => {
    const key = pair[0];
    let val = pair[1];
    if (USD_MONEY_PATTERN.test(val)) {
      val = val.replace(/\$|,/g, '');
    }
    return [key, val];
  });
  return _.fromPairs(formDataEntriesMapped);
};

export const correctPhoneFormat = (formDataPrior: {
  [key: string]: any;
}): { [key: string]: any } => {
  const formDataEntriesMapped = _.entries(formDataPrior).map((pair: [string, any]) => {
    const key = pair[0];
    let val = pair[1];
    if (USD_PHONE_PATTERN.test(val)) {
      val = val.replace(/-/g, '');
    }
    return [key, val];
  });
  return _.fromPairs(formDataEntriesMapped);
};

// TODO GL-RELEASE this function and the related functions are
// involved with converting long state name to short state name from
// the first form step to the second.  Adding typing and comments here
// will help make the transforms cleaner.
export const correctStateFormat = (
  formDataPrior: { [key: string]: any },
  state: string
): { [key: string]: any } => {
  const formDataResult: { [key: string]: any } = {};
  const stateKey = 'BusinessInfo_Locations_Primary_AddrInfo_StateOrProvCd';
  _.assign(formDataResult, formDataPrior);
  formDataResult[stateKey] = state;
  return formDataResult;
};

// Find index of any node by nameOfFormControl
export const calculateNodeIndex = (tree: FormDslNode[], nameOfFormControl: string) => {
  return _.findIndex(tree, (node: FormDslNode) => {
    return node.nameOfFormControl === nameOfFormControl;
  });
};

// Find node by nameOfFormControl
export const findNode = (tree: FormDslNode[], nameOfFormControl: string): FormDslNode => {
  const nodeIndex = calculateNodeIndex(tree, nameOfFormControl);
  return tree[nodeIndex];
};

/**
 * Returns a dictionary where all controls from all steps are siblings, removing the "step" level
 * of nesting. More deeply nested values (i.e. FormGroup and FormArray values contained within a step)
 * will not be flattened.
 */
export const removeFormDataSteps = <T extends string>(
  formValueWithSteps: Record<string, { [key in T]?: any }>
): Record<T, any> => {
  return _.reduce(
    formValueWithSteps,
    (fields, stepFields, _stepName) => {
      return _.merge(fields, stepFields);
    },
    {} as Record<T, any>
  );
};
