import { of as observableOf, Observable, Subject } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { CurrentUser, USER_CONTEXT } from 'app/shared/models/current-user';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { DeviceTokenService } from 'app/core/services/device-token.service';
import { LOGIN_OKTA_API_URI, V3_ADP_API_URI } from 'app/constants';

import { SentryService } from 'app/core/services/sentry.service';
import { FullstoryService } from 'app/core/services/fullstory.service';

import { environment } from 'environments/environment';
import { datadogRum } from '@datadog/browser-rum';

@Injectable()
export class AuthenticationService {
  public token: string | null | undefined;
  fixedToken = environment.adpBearerToken;

  public successfulAuthentications$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private currentUserService: CurrentUserService,
    private sentryService: SentryService,
    private fullstoryService: FullstoryService,
    private deviceTokenService: DeviceTokenService
  ) {
    // set token if saved in local storage
    const currentUser = currentUserService.getCurrentUser();
    this.token = currentUser && currentUser.token;
  }

  loginOkta(username: string, token: string): Observable<boolean> {
    return this.http
      .post<any>(
        LOGIN_OKTA_API_URI,
        { token: token },
        {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response',
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (!error.status || error.status !== 401) {
            this.sentryService.notify('Unable to log in with Okta token.', {
              severity: 'error',
              metaData: {
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
                emailLogin: username,
              },
            });
          }
          datadogRum.addAction('login_failure', { email: username });
          return observableOf(false);
        }),
        map((response: HttpResponse<any>) => {
          const user = {
            context: USER_CONTEXT.AGENT_PORTAL,
            token: response.body.token,
            username: username,
          };
          const authenticationSuccessful =
            !!user && this.updateTokenAndCurrentUserFromPayload(user);
          if (authenticationSuccessful) {
            // Note: this triggers another of our services to set the current Sentry user
            this.successfulAuthentications$.next();
          }

          return authenticationSuccessful;
        })
      );
  }

  loginAdp(adpJWT: string, interactionID: string): Observable<void> {
    return this.http
      .post<any>(
        `${V3_ADP_API_URI}/auth`,
        { adpJWT, interactionID },
        {
          headers: {
            Authorization: `Bearer ${this.fixedToken}`,
            'Content-type': 'application/json',
          },
          observe: 'response',
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        catchError((error: any) => {
          this.sentryService.notify('Error authenticating ADP user', {
            severity: 'error',
            metaData: {
              adpJWT,
              interactionID,
              underlyingError: error,
              underlyingErrorMessage: error && error.message,
            },
          });
          throw new Error('Error authenticating ADP user');
        }),
        map((response: HttpResponse<any>) => {
          const user = this.parseCurrentUser(response, interactionID, USER_CONTEXT.ADP_FORM);
          if (user) {
            this.updateTokenAndCurrentUserFromPayload(user, USER_CONTEXT.ADP_FORM);
          }
        })
      );
  }

  parseCurrentUser(
    response: HttpResponse<any>,
    username: string,
    context = USER_CONTEXT.AGENT_PORTAL
  ): CurrentUser | null {
    const token = response.headers && response.headers.get('X-AUTH-TOKEN');
    if (!token) {
      return null;
    }
    return { token: token, username: username, context: context };
  }

  logout(context = USER_CONTEXT.AGENT_PORTAL): void {
    const isUserLoggedIn = this.currentUserService.isCurrentUserPresent(context);
    // clear token remove user from local storage to log user out
    this.token = null;
    this.currentUserService.destroyCurrentUser();
    // Reload the browser once on logout to ensure that users get the latest Javascript code.
    if (isUserLoggedIn) {
      window.location.reload();
    }
  }

  updateTokenAndCurrentUserFromPayload(
    currentUser: CurrentUser,
    context = USER_CONTEXT.AGENT_PORTAL
  ): boolean {
    try {
      // set token property
      this.token = currentUser.token;
      this.currentUserService.setCurrentUser(currentUser, context);
      // return true to indicate successful login
      return true;
    } catch (error) {
      // Note: Error logged to Sentry in the currentUserService;
      return false;
    }
  }
}
