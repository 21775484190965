import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import {
  GET_ACCOUNT_BILLING_STATUS_API_URI,
  GET_COMMISSIONS_API_URL,
  GET_PAYABLE_STATUS_API_URL,
  API_V4_BASE,
} from 'app/constants';
import { CommissionPayableDetails } from 'app/shared/settings/commissions-constants';
import { SentryService } from 'app/core/services/sentry.service';
import { CommissionPlansResponse } from '../commission-plan-information-modal/types';

@Injectable()
export class BillingApiService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  private payableStatusData$: Observable<CommissionPayableDetails | null>;

  get(insuredAccountId: string): Observable<BillingStatusEndpointResponse> {
    return this.http
      .get<BillingStatusEndpointResponse>(GET_ACCOUNT_BILLING_STATUS_API_URI, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { accountNumber: insuredAccountId },
      })
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to get billing status for insured account.', {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
              insuredAccountId,
            },
          });
          throw error;
        })
      );
  }

  getCommissionStatements(fromDate: moment.Moment): Observable<{ result: CommissionStatement[] }> {
    return this.http.get<any>(GET_COMMISSIONS_API_URL + encodeURIComponent(fromDate.format())).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve commmission statements.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getAttuneAndPartnerCommissionStatements(
    producerCode: string,
    fromDate: moment.Moment
  ): Observable<AttuneOrPartnerCommissionStatement[]> {
    const url = `${API_V4_BASE}/producer-code/${producerCode}/commissions/statements/`;
    return this.http.get<any>(url, { params: { fromDate: fromDate.format('YYYY-MM-DD') } }).pipe(
      map((response: any) => response.data),
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve Attune and partner commission statements.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  fetchAndReplayPayableStatus(): Observable<CommissionPayableDetails | null> {
    const ONE_HOUR_IN_MS = 60 * 60 * 1000;
    return this.http.get<CommissionPayableDetails>(GET_PAYABLE_STATUS_API_URL).pipe(
      shareReplay(1, ONE_HOUR_IN_MS),
      catchError((error) => {
        this.sentryService.notify('Unable to retrieve payable status.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  getPayableStatus() {
    if (!this.payableStatusData$) {
      this.payableStatusData$ = this.fetchAndReplayPayableStatus();
    }
    return this.payableStatusData$;
  }

  fetchDcpCommissionStatementJson(
    producerCode: string,
    statementDate: string
  ): Observable<DcpCommissionStatement> {
    const url =
      API_V4_BASE + `/producer-code/${producerCode}/commissions/statements/${statementDate}`;
    return this.http.get<DcpCommissionStatement>(url, { params: { format: 'json' } }).pipe(
      map((response: any) => response.data),
      catchError((error) => {
        this.sentryService.notify('Unable to fetch DCP commission statement data', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
            producerCode,
            statementDate,
          },
        });

        throw error;
      })
    );
  }

  getCommissionPlanRates(producerCode: string): Observable<CommissionPlansResponse> {
    const url = API_V4_BASE + `/${producerCode}/commission-plan-rates`;
    return this.http.get<CommissionPlansResponse[]>(url, { params: { format: 'json' } }).pipe(
      map((response: any) => response),
      catchError((error) => {
        this.sentryService.notify('Unable to fetch commission plan rates', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
            producerCode,
          },
        });

        throw error;
      })
    );
  }
}
