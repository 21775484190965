import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, NgZone, Inject, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of as observableOf, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

import { LibertyMutualBindFormService } from 'app/features/liberty-mutual/services/liberty-mutual-bind-form.service';
import { FormDslSteppedFormBaseComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form-base.component';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { LibertyMutualBindQuoteService } from 'app/features/liberty-mutual/services/liberty-mutual-bind-quote.service';
import {
  HydraPaymentIFrameRes,
  HydraPaymentValidationRes,
  LibertyMutualProductType,
} from 'app/features/liberty-mutual/models/common-typings';
import { SentryService } from 'app/core/services/sentry.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import {
  LibertyMutualFrontendBindRequest,
  ProductCombination,
  QuoteResponse,
  FrontendQuote,
} from 'app/features/digital-carrier/models/types';
import {
  LIBERTY_MUTUAL_UNKNOWN_PAYMENT_ERROR,
  LIBERTY_MUTUAL_UNKNOWN_BIND_ERROR,
  LIBERTY_MUTUAL_UNKNOWN_CC_ERROR,
  LIBERTY_MUTUAL_HYDRA_ERROR,
} from 'app/shared/quote-error-modal/errors';

/**
 * NOTE: in future implementations of a bind page, do not extend BaseSteppedFormComponent
 * as it was written for the Hab product.
 * For more details, check the GitLab comment at:
 * https://gitlab.com/attuneinsurance/portal-agent/-/merge_requests/3580#note_590419197
 * Or the Linear card at:
 * https://linear.app/attuneinsurance/issue/CARP-202/review-lm-bind-form-and-refactor-as-needed
 */
@Component({
  selector: 'app-liberty-mutual-bind-quote-form',
  templateUrl: './liberty-mutual-bind-quote-form.component.html',
  providers: [LibertyMutualBindFormService],
})
export class LibertyMutualBindQuoteFormComponent
  extends FormDslSteppedFormBaseComponent<LibertyMutualBindFormService>
  implements OnInit, AfterViewInit
{
  productType: LibertyMutualProductType;
  accountId: string;
  quoteIdentifier: string;
  insuredAccount: Observable<InsuredAccount | null>;
  insuredAccountSubject: BehaviorSubject<InsuredAccount | null> = new BehaviorSubject(null);
  quote: FrontendQuote;

  paymentIframeLoading: boolean;
  paymentInstrumentId: string;

  quoteBindErrors: Array<string> = [] as string[];

  openHydraErrorModal = false;
  openBindErrorModal = false;
  openPaymentErrorModal = false;
  openCreditCardErrorModal = false;
  openOnSubmitActionErrorModal = false;
  showProgressBar = false;

  LIBERTY_MUTUAL_HYDRA_ERROR = LIBERTY_MUTUAL_HYDRA_ERROR;

  private sub: Subscription = new Subscription();

  bindSuccess$ = new Subject();

  constructor(
    public formService: LibertyMutualBindFormService,
    private insuredAccountService: InsuredAccountService,
    private libertyMutualBindQuoteService: LibertyMutualBindQuoteService,
    private digitalCarrierQuoteService: DigitalCarrierQuoteService,
    protected route: ActivatedRoute,
    protected router: Router,
    private sentryService: SentryService,
    private amplitudeService: AmplitudeService,
    private zone: NgZone,
    private elementRef: ElementRef,
    @Inject('Window') private window: Window
  ) {
    super(formService, route, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.determineProductType();
    this.setupLibertyMutualFunctions();

    this.accountId = this.route.snapshot.params.accountId;
    this.quoteIdentifier = this.route.snapshot.params.quoteIdentifier;
    this.insuredAccount = this.insuredAccountSubject.asObservable();

    this.getQuoteDetails();
    this.loadAccountDetails();
  }

  ngAfterViewInit() {
    this.setComponentProductTypeAttributes();
  }

  private determineProductType() {
    const urlPath = this.route.snapshot.url[2].path;
    const [carrier, productType] = urlPath.split('-');
    this.productType = productType as LibertyMutualProductType;
  }

  private setComponentProductTypeAttributes() {
    const currentComponentElement = this.elementRef.nativeElement;
    currentComponentElement.setAttribute('product-type', this.productType);
    currentComponentElement.setAttribute(
      'class',
      `js-liberty-mutual-bind-quote-form-${this.productType}`
    );
  }

  private getQuoteDetails() {
    this.sub.add(
      this.digitalCarrierQuoteService
        .getQuoteDetails(this.quoteIdentifier)
        .subscribe((quote: FrontendQuote) => {
          this.quote = quote;
        })
    );
  }

  private loadAccountDetails() {
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => {
            return this.accountId === insuredAccount.id.toString();
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccountSubject.next(insuredAccount);
        })
    );
  }

  private setupLibertyMutualFunctions() {
    // instance of ngZone used to run methods called from liberty-mutual-callback.html, within the Angular zone
    (this.window as { [key: string]: any })['handleOnSubmitAction'] = () => {
      this.zone.run(() => {
        this.handleOnSubmitAction();
      });
    };

    (this.window as { [key: string]: any })['handleOnCancelAction'] = () => {
      this.zone.run(() => {
        this.handleOnCancelAction();
      });
    };

    (this.window as { [key: string]: any })['handleOnSubmitActionError'] = (err: Error) => {
      this.zone.run(() => {
        this.handleOnSubmitActionError(err);
      });
    };
  }

  public handleOnSubmitActionError(e: Error) {
    this.amplitudeService.track({
      eventName: `liberty_mutual_${this.productType}_bind_error`,
      detail: JSON.stringify(e),
      useLegacyEventName: true,
    });

    this.sentryService.notify(
      `Liberty Mutual ${this.productType.toUpperCase()}: Error while executing on submit function`,
      {
        severity: 'error',
        metaData: {
          error: e,
          accountId: this.accountId,
          productType: `liberty_mutual_${this.productType}`,
          quoteId: this.quoteIdentifier,
        },
      }
    );

    this.openOnSubmitActionErrorModal = true;
  }

  public handleOnSubmitAction() {
    this.validateHydraInstrument();
  }

  public handleOnCancelAction() {
    // TODO: not sure how canceling works
  }

  public validateHydraInstrument() {
    if (this.isCurrentStep('payment-details')) {
      this.sub.add(
        this.libertyMutualBindQuoteService
          .validatePaymentInstrument(
            this.accountId,
            this.quoteIdentifier,
            this.paymentInstrumentId,
            this.productType
          )
          .subscribe(
            (payload: HydraPaymentValidationRes) => {
              if (payload.status === 'Approved') {
                this.bindQuote();
              } else if (payload.status === 'Declined') {
                // Note: if declined, new iFrame must be requested.
                // CC error is usually 'Declined' and not being explicitly used in modal.
                this.openCreditCardErrorModal = true;
              } else {
                // Note: if error continues after re-try of valid data, then issue is with Hydra
                this.sentryService.notify(
                  `Liberty Mutual ${this.productType.toUpperCase()}: Error response while trying to validate Hydra payment details`,
                  {
                    severity: 'error',
                    metaData: {
                      accountId: this.accountId,
                      productType: `liberty_mutual_${this.productType}`,
                      quoteId: this.quoteIdentifier,
                      responsePayload: payload,
                    },
                  }
                );
                this.openPaymentErrorModal = true;
              }
            },
            (err) => {
              this.amplitudeService.track({
                eventName: `liberty_mutual_${this.productType}_bind_error`,
                detail: JSON.stringify(err),
                useLegacyEventName: true,
              });

              this.sentryService.notify(
                `Liberty Mutual ${this.productType.toUpperCase()}: Error while trying to validate Hydra payment details`,
                {
                  severity: 'error',
                  metaData: {
                    error: err,
                    accountId: this.accountId,
                    productType: `liberty_mutual_${this.productType}`,
                    quoteId: this.quoteIdentifier,
                  },
                }
              );

              this.openPaymentErrorModal = true;
            }
          )
      );
    }
  }

  handleCreditCardResultModalClose(result: { close: boolean; retry: boolean }) {
    if (result.close) {
      this.openCreditCardErrorModal = false;
    }

    if (result.retry) {
      this.clickBackward();
    }

    if (!result.close && !result.retry) {
      this.router.navigate(['accounts', this.accountId]);
    }
  }

  getCreditCardErrorType() {
    return LIBERTY_MUTUAL_UNKNOWN_CC_ERROR;
  }

  public handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep(), step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  public handleSubmit(submitEvent?: Event): boolean {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    this.paymentIframeLoading = true;
    if (this.isCurrentStep('payment-plan')) {
      const paymentFrequency = this.formService.getSelectedPayment();

      this.sub.add(
        this.libertyMutualBindQuoteService
          .requestPaymentIFrame(this.accountId, this.quoteIdentifier, this.productType)
          .subscribe(
            (iFrameRes: HydraPaymentIFrameRes) => {
              this.paymentInstrumentId = iFrameRes.instrumentId;
              this.paymentIframeLoading = false;
              return this.formService.stepForward();
            },
            (err) => {
              this.amplitudeService.track({
                eventName: `liberty_mutual_${this.productType}_bind_error`,
                detail: JSON.stringify(err),
                useLegacyEventName: true,
              });

              this.sentryService.notify(
                `Liberty Mutual ${this.productType.toUpperCase()}: Error while trying to get iFrame from Hydra`,
                {
                  severity: 'error',
                  metaData: {
                    error: err,
                    accountId: this.accountId,
                    productType: `liberty_mutual_${this.productType}`,
                    quoteId: this.quoteIdentifier,
                  },
                }
              );
              this.openHydraErrorModal = true;
              return false;
            }
          )
      );
    }

    return false;
  }

  public bindQuote() {
    const policyHolderEmail = this.formService.get<UntypedFormControl>(
      'paymentPlan.policyHolderEmail'
    ).value;

    const paymentPlanSelection = this.formService.get<UntypedFormControl>(
      'paymentPlan.numberOfPayments'
    ).value;

    const bindRequest: LibertyMutualFrontendBindRequest = {
      email: policyHolderEmail,
      hydraPolicyUuid: this.quote.pasId,
      instrumentId: this.paymentInstrumentId,
      paymentPlan: paymentPlanSelection,
    };

    let dcpProduct: ProductCombination;
    if (this.productType === 'bop') {
      dcpProduct = {
        pasSource: 'liberty_mutual',
        product: 'bop',
      };
    }
    if (this.productType === 'cpsp') {
      dcpProduct = {
        pasSource: 'liberty_mutual',
        product: 'cpsp',
      };
    }

    this.showProgressBar = true;

    this.sub.add(
      this.insuredAccountSubject.subscribe((insuredAccount: InsuredAccount) => {
        this.digitalCarrierQuoteService
          .bindQuote(bindRequest, dcpProduct, insuredAccount, this.quoteIdentifier)
          .subscribe(
            (response: HttpResponse<QuoteResponse>) => {
              const payload = response.body;
              if (payload?.success && payload?.status === 'bound') {
                this.amplitudeService.track({
                  eventName: `liberty_mutual_${this.productType}_bind_success`,
                  detail: JSON.stringify(payload),
                });

                this.bindSuccess$.next(true);
                this.goBackToAccount();
              } else {
                if (payload?.details?.errorResponseFromCarrier) {
                  this.quoteBindErrors = payload.details.errorResponseFromCarrier;
                }

                this.amplitudeService.track({
                  eventName: `liberty_mutual_${this.productType}_bind_error`,
                  detail: JSON.stringify(payload),
                });

                this.sentryService.notify(
                  `Liberty Mutual ${this.productType.toUpperCase()}: Error while trying to make bind request, quote not bound`,
                  {
                    severity: 'error',
                    metaData: {
                      error: payload?.status,
                      accountId: this.accountId,
                      productType: `liberty_mutual_${this.productType}`,
                      quoteId: this.quoteIdentifier,
                    },
                  }
                );

                this.showProgressBar = false;
                this.openBindErrorModal = true;
              }
            },
            (err) => {
              this.amplitudeService.track({
                eventName: `liberty_mutual_${this.productType}_bind_error`,
                detail: JSON.stringify(err),
              });

              this.sentryService.notify(
                `Liberty Mutual ${this.productType.toUpperCase()}: Error while trying to make bind request`,
                {
                  severity: 'error',
                  metaData: {
                    error: err,
                    accountId: this.accountId,
                    productType: `liberty_mutual_${this.productType}`,
                    quoteId: this.quoteIdentifier,
                  },
                }
              );

              this.showProgressBar = false;
              this.openBindErrorModal = true;
            }
          );
      })
    );
  }

  private goBackToAccount() {
    this.router.navigate(['accounts', this.accountId], { replaceUrl: true });
  }

  handleQuotePaymentResultModalClose(result: { close: boolean; retry: boolean }) {
    if (!result.close && !result.retry) {
      this.router.navigate(['accounts', this.accountId]);
    }
  }

  getPaymentErrorType() {
    return LIBERTY_MUTUAL_UNKNOWN_PAYMENT_ERROR;
  }

  handleHydraResultModalClose(result: { close: boolean; retry: boolean }) {
    if (!result.close && !result.retry) {
      this.router.navigate(['accounts', this.accountId]);
    }
  }

  handleQuoteBindResultModalClose(result: { close: boolean; retry: boolean }) {
    if (!result.close && !result.retry) {
      this.router.navigate(['accounts', this.accountId]);
    }
  }

  hasDisplayableQuoteBindErrors() {
    return false;
  }

  getBindErrorType() {
    return LIBERTY_MUTUAL_UNKNOWN_BIND_ERROR;
  }

  public checkQuotingSuccess(): Observable<boolean> {
    return observableOf(false);
  }

  public onIncrementedStep(nextStep: RouteFormStep): void {
    this.navigateToCurrentStep();
  }

  public sendForm(): Observable<any | null> {
    return observableOf(null);
  }

  public clickBackward() {
    if (this.formService.stepBackward()) {
      this.navigateToCurrentStep();
    }
  }

  public handleFailedQuote(): void {}

  public handleSuccessfulQuote(): void {}

  public loadInitialData(): void {}
}
