// Libraries
import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { getControl } from 'app/shared/helpers/form-helpers';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-form-field-slider',
  templateUrl: './form-field-slider.component.html',
})
export class FormFieldSliderComponent implements OnInit, AfterViewChecked {
  @Input() inputId: string;
  @Input() labelText: string;
  @Input() cssClass: string;
  @Input() form: UntypedFormGroup;
  @Input() nameOfFormControl: string;
  @Input() step: number;
  @Input() min: number;
  @Input() max: number;
  @Input() tooltipText: string;
  @Input() isCurrencyValue = false;
  @Input() submitted = false;
  @Input() readonly = false;
  @Input() displayType: 'DISPLAY_CALCULATED_VALUE' | 'DISPLAY_CONTROL_VALUE' =
    'DISPLAY_CONTROL_VALUE';
  @Input() displayValueCalculator: (value: number) => number;

  constructor(private sanitizer: DomSanitizer) {}

  public inlineStyle: SafeStyle;

  ngOnInit() {
    this.inlineStyle = this.gradient(this.percent(this.derivedValue()));
    const formControl = this.form.get(this.nameOfFormControl);
    if (formControl) {
      formControl.valueChanges.subscribe((value) => this.onValueChange(value));
    }
  }

  ngAfterViewChecked(): void {
    this.onValueChange(this.derivedValue());
  }

  derivedValue(): number {
    return getControl(this.form, this.nameOfFormControl).value;
  }

  displayValue(): number {
    return this.displayType === 'DISPLAY_CALCULATED_VALUE'
      ? this.displayValueCalculator(this.derivedValue())
      : this.derivedValue();
  }

  onValueChange(value: number) {
    this.inlineStyle = this.gradient(this.percent(value));
  }

  private percent(value: number): number {
    return ((value - this.min) / (this.max - this.min)) * 100;
  }

  public gradient(percent: number): SafeStyle {
    const blue = 'rgb(0, 186, 186)'; // #00BABA
    const lightGray = 'rgb(239, 244, 248)'; // #F2F4F5

    return `linear-gradient(90deg, ${blue} 0%, ${blue} ${percent}%, ${lightGray} ${percent}%, ${lightGray} 100%)`;
  }
}
