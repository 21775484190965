import { ProductEligibility } from '../../../shared/services/naics.service';
import { WcPolicyDocument } from 'app/workers-comp/employers/models/wc-priced-quote';
import {
  AttestationFormData,
  CoalitionCyberFormDataFormatted,
} from 'app/features/coalition/models/cyber-typings.model';
import { FrontendQuoteWithLinks } from '../../insured-account/services/insured-account-summary.service';
import {
  CyberLimitOption,
  CyberRetentionOption,
} from '../../coalition/models/cyber-constants.model';

export interface AttuneInsuredAccount {
  id: string;
  companyName: string;
  organizationType: string;
  phoneNumber: string;
  emailAddress: string;
  additionalEmailAddress?: string;

  // contact address:
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  state: string;
  zip: string;

  doingBusinessAs: string;
  website: string | null;
  naicsCode: {
    code: string;
    description: string;
    hash: string;
  } | null;
}

interface DigitalQuoteRequestBase {
  account: AttuneInsuredAccount;
  requestId: string;
  effectiveDate: string;
  requestDate?: string;
  quoteId?: string;
  renewalOfQuoteId?: string;
}

// Hiscox / LM FormDSL style
export interface FormDSLFormData {
  [key: string]: number | string | boolean | null;
}

export type FormDSLQuoteRequest = DigitalQuoteRequestBase & {
  formData: FormDSLFormData;
};

// Employers Example
export type EmployersFrontendQuoteRequest = DigitalQuoteRequestBase & {
  mainLimit: number;
};
export type DigitalQuoteRequest = FormDSLQuoteRequest | EmployersFrontendQuoteRequest;

export interface Surcharge {
  label: string;
  amount: number;
  type?: string;
}

export interface CoalitionEffectiveDateUpdatedResponse {
  bindable: boolean;
}

// Property is only available for quoted/referred Neptune quotes
export type NeptuneFloodRiskColorValue = 'Yellow' | 'Orange' | 'Red';

export interface BaseQuoteResponse {
  uuid: string;
  details?: {
    // TODO: Update this type to match properties sent by backend
    link?: string;
    errorResponseFromCarrier?: string[];
    limitRetentionCorrections?: {
      default_retention?: CyberRetentionOption;
      aggregate_limit?: CyberLimitOption;
    };
    riskColorCode?: NeptuneFloodRiskColorValue; // not available on historical quotes
  };
  surcharges?: Surcharge[];
}

export type BoundQuoteResponse = BaseQuoteResponse & {
  success: true;
  status: 'bound' | 'bound_with_subjectivity';
  premium: number;
  pasId: string;
};

export type DraftQuoteResponse = BaseQuoteResponse & {
  success: true;
  status: 'draft';
  premium?: number;
  pasId?: string;
};

export type QuotedQuoteResponse = BaseQuoteResponse & {
  success: true;
  status: 'quoted';
  premium: number;
  pasId: string;
};

export type NeptuneFloodQuotedQuoteResponse = QuotedQuoteResponse & {
  details: {
    link: string;
  };
};

export type ReferralResponse = BaseQuoteResponse & {
  success: true;
  status: 'referred';
  premium?: number;
  referralReasons: string[];
  pasId: string;
};

export type UnavailableResponse = BaseQuoteResponse & {
  success: true;
  status: 'unavailable';
  premium?: number;
  unavailableReasons: string[];
  pasId: string;
};

export type DeclinedQuoteResponse = BaseQuoteResponse & {
  success: true;
  status: 'declined';
  declineReasons: string[];
  pasId: string; // this could be available on a carrier by carrier basis
};

export type QuoteErrorResponse = BaseQuoteResponse & {
  success: false;
  status: 'error';
  errors: string[];
  errorMessage: string;
};

export type QuoteResponse =
  | BoundQuoteResponse
  | DraftQuoteResponse
  | QuotedQuoteResponse
  | ReferralResponse
  | DeclinedQuoteResponse
  | QuoteErrorResponse
  | UnavailableResponse;

export const isReferralResponse = (
  quoteResponse: QuoteResponse
): quoteResponse is ReferralResponse => {
  return quoteResponse.status === 'referred';
};

export const isUnavailableResponse = (
  quoteResponse: QuoteResponse
): quoteResponse is UnavailableResponse => {
  return quoteResponse.status === 'unavailable';
};

export const isDeclinedQuoteResponse = (
  quoteResponse: QuoteResponse
): quoteResponse is DeclinedQuoteResponse => {
  return quoteResponse.status === 'declined';
};

export const isQuoteErrorResponse = (
  quoteResponse: QuoteResponse
): quoteResponse is QuoteErrorResponse => {
  return quoteResponse.success === false;
};

export const isQuotedQuoteResponse = (
  quoteResponse: QuoteResponse
): quoteResponse is QuotedQuoteResponse => {
  return quoteResponse.status === 'quoted';
};

export const isNeptuneQuotedQuoteResponse = (
  quoteResponse: QuoteResponse
): quoteResponse is NeptuneFloodQuotedQuoteResponse => {
  return (
    quoteResponse.status === 'quoted' && !!quoteResponse.details && !!quoteResponse.details.link
  );
};

export const isDraftQuoteResponse = (
  quoteResponse: QuoteResponse
): quoteResponse is DraftQuoteResponse => {
  return quoteResponse.status === 'draft';
};

// Compute path for edit based on quote ID
// Note: This is necessary in order to maintain current edit routes
// for existing products that may not support multiple quotes
// for the same product on a single account.
type EditUrlFunction = (quoteId?: string) => string;
type BindRequestUrlFunction = (accountNumber: string, quoteNumber: string) => string;

export type InsuranceProductCode = ProductCombination['product'];
export type InsuranceProductBadge = InsuranceProductCode | 'bop-plus' | 'xs' | 'hab';
export type InsurancePolicyAdminSource = ProductCombination['pasSource'];
export type InsuranceProductIcon = InsurancePolicyAdminSource | 'attune' | 'attune_gw';

export interface CoalitionCyberAdmittedProduct {
  product: 'cyber_admitted';
  pasSource: 'coalition';
}

export interface CoalitionCyberSurplusProduct {
  product: 'cyber_surplus';
  pasSource: 'coalition';
}

export type CoalitionCyberProduct = CoalitionCyberAdmittedProduct | CoalitionCyberSurplusProduct;

export interface AttuneWCProduct {
  product: 'wc';
  pasSource: 'attune_gw';
}

export interface EmployerWCProduct {
  product: 'wc';
  pasSource: 'employers';
}

interface HiscoxGLV4Product {
  product: 'gl';
  pasSource: 'hiscox';
}

interface HiscoxPLV4Product {
  product: 'pl';
  pasSource: 'hiscox';
}

interface LibertyMutualBOPProduct {
  product: 'bop';
  pasSource: 'liberty_mutual';
}

interface LibertyMutualCPSPProduct {
  product: 'cpsp';
  pasSource: 'liberty_mutual';
  computePathForRequestBindUrl?: BindRequestUrlFunction;
}

interface NeptuneFloodProduct {
  product: 'flood';
  pasSource: 'neptune';
}

export type AttuneProduct = AttuneWCProduct | AttuneBOPProduct;

export interface AttuneWCProduct {
  product: 'wc';
  pasSource: 'attune_gw';
}

export interface AttuneBOPProduct {
  product: 'bop';
  pasSource: 'attune_gw';
}

export type ProductName =
  | "Businessowners' Policy"
  | 'General Liability'
  | 'Professional Liability'
  | 'Cyber Liability'
  | "Workers' Compensation"
  | 'Custom Protector'
  | 'Flood Insurance'
  | 'Commercial Excess Liability Policy';

export type PasSourceName =
  | 'Attune'
  | 'Coalition'
  | 'Employers'
  | 'Hiscox'
  | 'Liberty Mutual'
  | 'Neptune';

interface DigitalProductConfigBase {
  carrierDisplayName: PasSourceName;
  productName: ProductName;
  pathForUrl: string;
  eligibilityField: keyof ProductEligibility;
  computePathForEditUrl: EditUrlFunction;
  referralMessage?: string;
  underReviewMessage?: string;
  unavailableMessage?: string;
  notTakenMessage?: string;
  guidelinesLink?: string;
  showOnDifferentProductCard?: DigitalCarrierProduct;
}

// These types should stay in sync with models/Quote.ts in service-quote

export type ProductCombination =
  | CoalitionCyberAdmittedProduct
  | CoalitionCyberSurplusProduct
  | EmployerWCProduct
  | HiscoxGLV4Product
  | HiscoxPLV4Product
  | LibertyMutualBOPProduct
  | LibertyMutualCPSPProduct
  | NeptuneFloodProduct;

export type ProductCombinationForAvailability =
  | ProductCombination
  | AttuneBOPProduct
  | AttuneWCProduct;

export type DigitalCarrierProduct = DigitalProductConfigBase & ProductCombination;

export type DigitalQuoteStatus =
  | 'bound_with_subjectivity' // Currently only for coalition cyber
  | 'bound' // Once a quote is bound, it's policy will be created with appropriate status
  | 'declined'
  | 'draft'
  | 'expired'
  | 'not_taken'
  | 'quoted'
  | 'referred'
  | 'unavailable'
  | 'under_review';

export interface FrontendQuote {
  uuid: string;
  accountId: string;
  producerCode: string;
  createdAt: string;
  effectiveDate: string;
  updatedAt: string;
  premium: number | null;
  product: InsuranceProductCode;
  pasSource: InsurancePolicyAdminSource;
  pasId: string;
  status: DigitalQuoteStatus;
  displayId: string;
  underwritingCompany: string; // not currently used in UI
  surcharges?: Surcharge[];

  // "quoted", "bound" only
  details?: Record<string, string | string[] | Record<string, any>>;
  renewalDetails?: {
    previousQuoteUuid?: string;
    renewalQuoteUuids?: string[];
  };
  policyNumber?: string;
  // "declined" status only
  declineReasons?: string[];
}

export interface BackendQuoteBundle {
  uuid: string;
  brokerEmail: string;
  producerCode: string;
  accountId: string;
  bopQuoteUuid: string;
  cyberQuoteUuid: string;
}

export type AttuneBopFrontendQuoteWithLinks = Omit<FrontendQuote, 'product' | 'pasSource'> &
  AttuneBOPProduct & {
    routerLink: string;
  };

export interface FrontendQuoteBundle {
  uuid: string;
  brokerEmail: string;
  producerCode: string;
  accountId: string;
  quotes: (FrontendQuoteWithLinks | AttuneBopFrontendQuoteWithLinks)[];
}

export type PolicyStatus = 'scheduled' | 'in_force' | 'cancelled' | 'expired';

export type PolicyRenewalStatus = 'automatic' | 'manual' | 'lapsed' | 'non_renewal';

export interface DigitalCarrierPolicyDetails {
  uuid: string;
  policyNumber: string;
  product: InsuranceProductCode;
  status: PolicyStatus;
  netPremium: number;
  originalPremium?: number;
  effectiveDate: string;
  expirationDate: string;
  pasSource: InsurancePolicyAdminSource;
  pasQuoteId: string; // E.g. for WC, this is the Employers' quote uuid
  quoteId: string;
  accountId: string;
  producerCode: string;
  renewalPasSource?: InsurancePolicyAdminSource;
  previousPolicyUuid?: string;
  nextQuoteId?: string;
  nextPolicyUuid?: string;
  cancellationDate?: string;
  cancellationCode?: string;
  cancellationReason?: string;
  documents?: WcPolicyDocument[];
  docsStatus?: 'success' | 'error' | 'not-ready';
  updatedAt: string;
  deletedAt?: string;
  createdAt: string;
  term: string;
  policyAttributes?: {
    renewal_status?: PolicyRenewalStatus;
  };
}

export interface LibertyMutualFrontendBindRequest {
  instrumentId: string;
  email: string;
  hydraPolicyUuid: string; // hydra uuid from quote response
  paymentPlan: string;
}

export interface CoalitionCyberFrontendBindRequest {
  email: string;
  attestationFormData?: AttestationFormData;
  firstName?: string;
  lastName?: string;
  licenseNumber?: string;
}

export type DigitalBindRequest =
  | LibertyMutualFrontendBindRequest
  | CoalitionCyberFrontendBindRequest;

// Product Availability Types
export const enum ClassCodeSelection {
  PARTIAL = 'PARTIAL',
  ALL = 'ALL',
  NONE = 'NONE',
}
export type PartialProductAvailability = {
  classCodeSelection: ClassCodeSelection.PARTIAL;
  classCodes: {
    naics: string[];
    carrier: string[];
  };
} & ProductCombinationForAvailability;

export type NoProductAvailability = {
  classCodeSelection: ClassCodeSelection.NONE;
} & ProductCombinationForAvailability;

export type AllProductAvailability = {
  classCodeSelection: ClassCodeSelection.ALL;
} & ProductCombinationForAvailability;

export type ProductAvailability =
  | PartialProductAvailability
  | NoProductAvailability
  | AllProductAvailability;

// This type is only being used by Coalition Cyber Quote flow at the moment, hence it is not generalized at the moment
export interface EditRequestParams {
  dcp: ProductCombination;
  formData: CoalitionCyberFormDataFormatted;
  account: AttuneInsuredAccount;
  effectiveDate: string;
  isEditing: boolean;
  quoteId: string;
}

export type QuoteLetterStatus = 'loading' | 'success' | 'timeout' | 'not-available';

type ComplianceDocumentSigningParty =
  | 'broker'
  | 'insured'
  | 'broker_and_insured'
  | 'no_signing_party_required'
  | 'attune'
  | 'attune_and_broker'
  | 'attune_and_insured';

type SignatureType = 'electronic' | 'unsigned' | 'wet';

export interface ComplianceDocument {
  uuid: string;
  document_name: string;
  document_code: string;
  signing_party: ComplianceDocumentSigningParty;
  signature_type: SignatureType;
  state: string;
  created_at: string;
  updated_at: string;
  status: string;
  s3_url: string;
}

export interface ComplianceDocumentFetchResponse {
  success: boolean;
  documents: ComplianceDocument[];
  expectedDocumentCount: number;
}

// Bundle bind types
export interface BundleBindPolicyIdParams {
  bopPolicyId: string;
  cyberPolicyId?: string;
}

export interface BundleBindAdditionalLineItem {
  label: string;
  amount: number;
  type: 'premium' | 'tax' | 'other';
}
// END Bundle bind types
