import * as _ from 'lodash';
import * as moment from 'moment';
import { API_DATE_FORMAT, DAYS_TILL_QUOTE_EXPIRES } from '../constants';

export enum WcQuoteStatus {
  QUOTED = 'QUOTED',
  PENDING_REFER = 'PENDING_REFER',
  INDICATION = 'INDICATION', // Frontend's term for PENDING_REFER
  REFERRED = 'REFERRED',
  REPLIED = 'REPLIED',
  REQUESTED = 'REQUESTED',
  DECLINED = 'DECLINED',
  BOUND = 'BOUND',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
  EMPLOYERS_VALIDATION_ERROR = 'EMPLOYERS_VALIDATION_ERROR',
}

export interface WcDocument {
  docId: string;
  docTypeCode: string;
  fileName: string;
  fileSize: number;
  uploadDate: string;
}

// Note Employers has two sources / APIs for documents w/ different surface areas
export interface WcPolicyDocument {
  creationDate: string; // ISO YYYY-MM-DD
  documentId: string; // "D:1240914,J:20148"
  documentType: 'New Business' | 'Invoice' | 'Renewal';
  policyNumber: string; // "EIG257739502"
  insuredName?: string; // "MVNBC CORP"
}
export type WcPolicyDocumentsResponse =
  | { success: true; documents: WcPolicyDocument[] }
  | { success: false; errors: string[] };

export interface WcPricedResponse {
  quotes: WcBackendPricedQuote[];
  documents: WcDocument[];
}

export interface WcQuoteWithDocuments {
  quote: WcBackendPricedQuote | null;
  documents: WcDocument[];
}

export interface WcBackendPricedQuote {
  uuid: string;
  legacyInsuredAccountId: string;
  policyNumber: string;
  premium: string;
  createdAt: string;
  quoteLetterDownloadLink: string | null;
  status: WcQuoteStatus;
  effectiveDate: string;
  expirationDate: string;
  employersDeclineReasons: string[];
  employersApiErrors?: string[];
  hasQuoteLetter?: boolean;
  justReferred?: boolean;
  previouslyReferred?: boolean;
  hasExecutiveExclusion?: boolean;
}

export class WcPricedQuote {
  documents: WcDocument[] = [];
  uuid: string;
  legacyInsuredAccountId: string;
  policyNumber: string;
  premium: number;
  createdAt: moment.Moment;
  quoteLetterDownloadLink: string | null;
  status: WcQuoteStatus;
  effectiveDate: moment.Moment;
  declineReasons?: string[];
  justReferred = false;
  previouslyReferred = false;
  hasExecutiveExclusion = false;
  hasQuoteLetter = false;

  constructor(backendPricedQuote: WcBackendPricedQuote, documents: WcDocument[] = []) {
    Object.assign(
      this,
      _.pick(backendPricedQuote, [
        'uuid',
        'legacyInsuredAccountId',
        'policyNumber',
        'quoteLetterDownloadLink',
        'justReferred',
        'previouslyReferred',
        'hasExecutiveExclusion',
        'hasQuoteLetter',
      ])
    );

    this.documents = documents;

    this.status = backendPricedQuote.status;

    if (backendPricedQuote.status === 'PENDING_REFER') {
      this.status = WcQuoteStatus.INDICATION;
    }

    if (
      backendPricedQuote.status === 'EXPIRED' ||
      (backendPricedQuote.status === 'QUOTED' &&
        moment.utc().diff(moment.utc(backendPricedQuote.effectiveDate), 'days') >
          DAYS_TILL_QUOTE_EXPIRES)
    ) {
      this.status = WcQuoteStatus.EXPIRED;
    }

    this.createdAt = moment.utc(backendPricedQuote.createdAt);
    this.premium = parseFloat(backendPricedQuote.premium);
    if (!_.isEmpty(backendPricedQuote.employersDeclineReasons)) {
      this.declineReasons = backendPricedQuote.employersDeclineReasons;
    } else if (
      backendPricedQuote.employersApiErrors &&
      !_.isEmpty(backendPricedQuote.employersApiErrors) &&
      backendPricedQuote.employersApiErrors.some((e) => e === 'Prior Submission')
    ) {
      this.declineReasons = [
        `This quote was declined because the FEIN is already reserved in Employers' system. If you think this has been done in error, please contact our customer service team.`,
      ];
    }
    if (backendPricedQuote.effectiveDate) {
      this.effectiveDate = moment.utc(backendPricedQuote.effectiveDate, API_DATE_FORMAT);
    }
    if (backendPricedQuote.justReferred === true) {
      this.justReferred = true;
    }
  }

  isQuoted() {
    return this.status === WcQuoteStatus.QUOTED;
  }

  isReferred() {
    // Treat WcQuotes with statuses Referred/Replied/Requested as Referred when it comes to the UI.
    return [WcQuoteStatus.REFERRED, WcQuoteStatus.REPLIED, WcQuoteStatus.REQUESTED].includes(
      this.status
    );
  }

  isIndication() {
    return this.status === WcQuoteStatus.INDICATION;
  }

  isDeclined() {
    return this.status === WcQuoteStatus.DECLINED;
  }

  isBound() {
    return this.status === WcQuoteStatus.BOUND;
  }

  isExpired() {
    return this.status === WcQuoteStatus.EXPIRED;
  }
}
