import { Injectable } from '@angular/core';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { getControl } from 'app/shared/helpers/form-helpers';
import { Subject } from 'rxjs';
import {
  getServicingInfoDoNotShowAgainPreference,
  updateServicingDoNotShowAgainPreference,
} from '../../../shared/carrier-partner-servicing-info/helpers';

@Injectable()
export class HiscoxBindFormService extends FormDslSteppedFormBaseService {
  form: UntypedFormGroup;
  submitted = false;
  DEFAULT_PAYMENT_OPTION = 1;
  iFrameIsLoadingEmitter = new Subject<boolean>();

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.initializeForm();
  }

  fillInHappyPath() {}

  generateSteps(): RouteFormStep[] {
    const isStep = (step: RouteFormStep | null): step is RouteFormStep => {
      return step !== null;
    };

    const preliminarySteps: RouteFormStep[] = getServicingInfoDoNotShowAgainPreference('hiscox')
      ? []
      : [
          {
            args: {},
            displayName: `Working with Hiscox`,
            slug: 'working-with-hiscox',
            parent: 'working-with-hiscox',
            formPath: 'workingWithHiscox',
          },
        ];

    return [
      ...preliminarySteps,
      {
        args: {},
        displayName: `Payment Plan`,
        slug: 'payment-plan',
        parent: 'payment-plan',
        formPath: 'paymentPlan',
      },
      {
        args: {},
        displayName: `Payment Details`,
        slug: 'payment-details',
        parent: 'payment-details',
        formPath: 'paymentDetails',
      },
    ].filter(isStep);
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      workingWithHiscox: this.formBuilder.group({
        doNotShowAgain: [null],
      }),
      paymentPlan: this.formBuilder.group({
        numberOfPayments: [this.DEFAULT_PAYMENT_OPTION, Validators.required],
        'hx-bind-accepted': [Validators.required],
        'hx-electronic-info-accepted': [Validators.required],
      }),
      paymentDetails: this.formBuilder.group({}),
    });
  }

  getValue() {
    return this.form.value;
  }

  acceptedBindQuestion() {
    return getControl(this.form, 'paymentPlan.hx-bind-accepted').value === 'agree';
  }

  acceptedElectronicInfoQuestion() {
    return getControl(this.form, 'paymentPlan.hx-electronic-info-accepted').value === 'agree';
  }

  getSelectedPayment() {
    return getControl(this.form, 'paymentPlan.numberOfPayments').value;
  }

  setPreviouslySelectedPaymentOption(paymentPlan: number) {
    getControl(this.form, 'paymentPlan.numberOfPayments').patchValue(paymentPlan);
  }

  handleDoNotShowAgainSelection() {
    const doNotShowAgainControl = getControl(this.form, 'workingWithHiscox.doNotShowAgain');
    if (doNotShowAgainControl.dirty) {
      updateServicingDoNotShowAgainPreference('hiscox', doNotShowAgainControl.value);
    }
  }
}
