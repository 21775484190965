<div class="app-page-sidebar app-page-sidebar__bind">
  <a
    class="button button__nav button__nav-sidebar-bind button__no-margin button__nav-white-bg"
    [routerLink]="['..']"
  >
    <span class="icon-arrow-left button-icon"></span>
    Cancel
  </a>
  <header class="quote-sidebar">
    <!-- Include all product badges -->
    <div class="product-badge-group">
      <div
        *ngFor="let productToBind of productsToBind"
        [className]="
          'product-badge product-badge__sidebar  product-badge__' + productToBind.product
        "
      >
        <div
          [className]="
            'product-badge-carrier-icon product-badge-carrier-icon__' + productToBind.pasSource
          "
        ></div>
      </div>
    </div>
    <h3 class="h3">Bind quote</h3>
    <small *ngIf="!quoteDetailsLoading">{{ contact.Name }}</small>
    <ul class="sidebar-breadcrumbs">
      <ng-container *ngFor="let step of formSteps.activeSteps">
        <li
          [ngClass]="{
            'sidebar-breadcrumb': true,
            'sidebar-breadcrumb__active': this.isCurrentStep(step)
          }"
          (click)="handleStepClicked(step)"
          (keyup.enter)="handleStepClicked(step)"
        >
          {{ step }}
        </li>
      </ng-container>
    </ul>
  </header>
</div>
<div class="app-page-form-scrollable-wrapper app-page-form-scrollable-wrapper__bind">
  <div class="app-page-form">
    <a
      *ngIf="this.formSteps.currentStepIndex !== 0"
      class="emphasized-link form-previous-link"
      (click)="stepBackward()"
      (keyup.enter)="stepBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>
    <form [formGroup]="form" (ngSubmit)="handleSubmit()" novalidate>
      <main *ngIf="quoteDetailsLoading" class="placeholder-form-container">
        <div class="placeholder-h1"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-submit"></div>
      </main>
      <main *ngIf="!quoteDetailsLoading">
        <ng-container *ngIf="getCurrentFormStep() === 'Policy Details'">
          <header class="bind-quote-header">
            <h1>Policy details</h1>
          </header>

          <app-form-field-text
            inputId="bind-effectiveDate"
            [form]="form.get('policyDetails')"
            [submitted]="submitted"
            labelText="Effective date"
            nameOfFormControl="effectiveDate"
            (focusOut)="updateIsEffectiveDateInPast()"
            appDateMask
          >
            <span
              role="label"
              class="more-info-tip tool-tip tool-tip__large"
              data-tooltip="Please use the appropriate effective date, as changing it may impact the rating plan."
              (mouseover)="hideEffectiveDateBlink()"
              (focus)="hideEffectiveDateBlink()"
              (mouseout)="cancelEffectiveDateBlinkHide()"
              (blur)="cancelEffectiveDateBlinkHide()"
            >
              <span class="icon icon-question">
                <span
                  class="notice-dot"
                  *ngIf="!hasSeenEffectiveDateInfo() && !isHoveringOnDateTooltip"
                ></span>
              </span>
            </span>
          </app-form-field-text>
          <app-form-field-checkbox
            inputId="bind-confirmEffectiveDate"
            [form]="form.get('policyDetails')"
            [submitted]="submitted"
            labelText="I confirm that this is the correct effective date for both policies."
            nameOfFormControl="confirmEffectiveDate"
          ></app-form-field-checkbox>
          <app-dialog-box
            *ngIf="isEffectiveDateInPast"
            header="Requests to backdate coverage require review"
            type="warning"
            class="form-field js-backdate-warning-dialog"
          >
            Please submit a request via the link below to request a review for backdated coverage.
            All requests will be sent to our Underwriting Team for review.
            <div class="dialog-text-links">
              <a
                class="dialog-text-link"
                routerLink="/support/form/bind-quote"
                [queryParams]="getBindQuoteFormParams()"
                data-amplitude-input-name="support_bind_quote_backdated"
              >
                Submit request to backdate bind
              </a>
            </div>
          </app-dialog-box>
          <section *ngIf="!isEffectiveDateInPast" class="js-policy-details-content">
            <h3>Insured contact information</h3>
            <p>
              Attune is a direct bill company. Insured contact information will be used for billing
              purposes only.
            </p>

            <div class="bind-flow-subsection">
              <div class="form-split">
                <app-form-field-text
                  inputId="bind-emailAddress"
                  [form]="form.get('policyDetails')"
                  [submitted]="submitted"
                  (focusOut)="completeEmailTip()"
                  labelText="Insured email"
                  nameOfFormControl="emailAddress"
                  describedBy="{{ showEmailTip() ? 'bind-email-tip' : '' }}"
                  appRemoveWhitespaceMask
                >
                  <app-education-pane
                    position="below-center"
                    *ngIf="showEmailTip()"
                    elementId="bind-email-tip"
                  >
                    Since we're direct bill, we'll send digital invoices to your insured via this
                    email. We do not mail paper invoices.
                  </app-education-pane>
                </app-form-field-text>
                <app-form-field-text
                  inputId="bind-additionalEmailAddress"
                  [form]="form.get('policyDetails')"
                  [submitted]="submitted"
                  labelText="Secondary insured email"
                  specifierText="(optional)"
                  nameOfFormControl="additionalEmailAddress"
                  appRemoveWhitespaceMask
                >
                </app-form-field-text>
              </div>
              <div
                *ngIf="
                  form.get('policyDetails').controls.emailAddress.errors &&
                  form.get('policyDetails').controls.emailAddress.errors.invalidEmailAddress
                "
                class="validation"
              >
                <span class="validation-message">Email Address must be a valid email address.</span>
              </div>

              <app-form-field-text
                inputId="bind-phoneNumber"
                [form]="form.get('policyDetails')"
                [submitted]="submitted"
                labelText="Mobile number"
                nameOfFormControl="phoneNumber"
                appPhoneMask
              >
              </app-form-field-text>

              <app-broker-contacts
                describedBy="{{ showBrokerContactTip() ? 'bind-contact-tip' : '' }}"
                (delete)="removeContact($event)"
                (add)="addBrokerContact()"
                [contacts]="getAllPossibleContacts()"
                [form]="form.get('policyDetails.brokerContacts')"
                [submitted]="submitted"
              >
              </app-broker-contacts>
              <app-education-pane
                position="left-top"
                targetSelector=".broker-contact-panel"
                elementId="bind-contact-tip"
                *ngIf="showBrokerContactTip()"
              >
                Here you can opt in or out of emails you and/or other members of your agency will
                receive.
              </app-education-pane>

              <div class="validation" role="alert" *ngIf="effectiveDate.invalid">
                <p class="validation-message js-effective-date-validation-message">
                  {{ validationMessageDateAfter }}
                </p>
              </div>

              <app-form-field-radio
                inputId="bind-bindExcess"
                [form]="form.get('policyDetails')"
                nameOfFormControl="bindExcess"
                questionText="Would you like to bind with Excess?"
              >
              </app-form-field-radio>
            </div>
          </section>
        </ng-container>

        <ng-container *ngIf="getCurrentFormStep() === 'Excess Liability'">
          <header class="bind-quote-header">
            <h1 class="form-header-below-link">Excess</h1>
          </header>
          <div class="bind-flow-subsection" *ngIf="this.hasExcessEmployersLiability">
            <h4 class="question-subsection-header js-bind-employers-liability">
              Employers Liability
            </h4>

            <p>Carrier name</p>
            <div formGroupName="excessDetails">
              <div formGroupName="employersLiability" class="bind-excess-carrier">
                <div
                  [ngClass]="{
                    'form-field': true,
                    'form-field__error': submitted && excessULCarrierEL.invalid
                  }"
                >
                  <input
                    formControlName="carrier"
                    id="bind-excess-el-carrier-name-input"
                    placeholder="Search carriers"
                    (focus)="focusEL$.next($event.target.value)"
                    (click)="clickEL$.next($event.target.value)"
                    [ngbTypeahead]="searchCarrier(focusEL$, clickEL$)"
                    [resultFormatter]="formatSearchCarrierResults"
                    [inputFormatter]="formatSearchCarrierResults"
                  />
                </div>
              </div>
            </div>

            <app-form-field-text
              inputId="bind-excess-el-policy-number-input"
              [form]="form.get('excessDetails.employersLiability')"
              [submitted]="submitted"
              labelText="Policy number"
              specifierText="(optional)"
              nameOfFormControl="policyNumber"
            >
            </app-form-field-text>

            <div class="bind-excess-dates">
              <div class="helper-card-container bind-excess-dates-item">
                <app-form-field-text
                  inputId="bind-excess-el-effective-date-input"
                  [form]="form.get('excessDetails.employersLiability')"
                  [submitted]="submitted"
                  labelText="Effective date"
                  nameOfFormControl="effectiveDate"
                  appDateMask
                >
                </app-form-field-text>
                <div class="validation" role="alert" *ngIf="excessEffectiveDateEL.invalid">
                  <p class="validation-message">
                    {{ validationMessageDateAfter }}
                  </p>
                </div>
              </div>

              <div class="helper-card-container bind-excess-dates-item">
                <app-form-field-text
                  inputId="bind-excess-el-expiration-date-input"
                  [form]="form.get('excessDetails.employersLiability')"
                  [submitted]="submitted"
                  labelText="Expiration date"
                  nameOfFormControl="expirationDate"
                  appDateMask
                >
                </app-form-field-text>
                <div
                  class="validation"
                  role="alert"
                  *ngIf="
                    submitted &&
                    excessExpirationDateEL.value !== null &&
                    excessExpirationDateEL.invalid
                  "
                >
                  <p class="validation-message">
                    {{ validationMessageDateAfter }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="bind-flow-subsection" *ngIf="this.hasExcessCommercialAuto">
            <h4 class="question-subsection-header js-bind-commercial-auto">Commercial Auto</h4>

            <p>Carrier name</p>
            <div formGroupName="excessDetails">
              <div formGroupName="commercialAuto" class="bind-excess-carrier">
                <div
                  [ngClass]="{
                    'form-field': true,
                    'form-field__error': submitted && excessULCarrierCA.invalid
                  }"
                >
                  <input
                    formControlName="carrier"
                    id="bind-excess-ca-carrier-name-input"
                    placeholder="Search carriers"
                    (focus)="focusCA$.next($event.target.value)"
                    (click)="clickCA$.next($event.target.value)"
                    [ngbTypeahead]="searchCarrier(focusCA$, clickCA$)"
                    [resultFormatter]="formatSearchCarrierResults"
                    [inputFormatter]="formatSearchCarrierResults"
                  />
                </div>
              </div>
            </div>

            <app-form-field-text
              inputId="bind-excess-ca-policy-number-input"
              [form]="form.get('excessDetails.commercialAuto')"
              [submitted]="submitted"
              labelText="Policy number"
              subLabelText="(optional)"
              nameOfFormControl="policyNumber"
            >
            </app-form-field-text>

            <div class="bind-excess-dates">
              <div class="helper-card-container bind-excess-dates-item">
                <app-form-field-text
                  inputId="bind-excess-ca-effective-date-input"
                  [form]="form.get('excessDetails.commercialAuto')"
                  [submitted]="submitted"
                  labelText="Effective date"
                  nameOfFormControl="effectiveDate"
                  appDateMask
                >
                </app-form-field-text>
                <div class="validation" role="alert" *ngIf="excessEffectiveDateCA.invalid">
                  <p class="validation-message">
                    {{ validationMessageDateAfter }}
                  </p>
                </div>
              </div>

              <div class="helper-card-container bind-excess-dates-item">
                <app-form-field-text
                  inputId="bind-excess-ca-expiration-date-input"
                  [form]="form.get('excessDetails.commercialAuto')"
                  [submitted]="submitted"
                  labelText="Expiration date"
                  nameOfFormControl="expirationDate"
                  appDateMask
                >
                </app-form-field-text>
                <div
                  class="validation"
                  role="alert"
                  *ngIf="
                    submitted &&
                    excessExpirationDateCA.value !== null &&
                    excessExpirationDateCA.invalid
                  "
                >
                  <p class="validation-message">
                    {{ validationMessageDateAfter }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="bind-flow-subsection" *ngIf="this.hasUMUIM">
            <h4 class="question-subsection-header js-bind-umuim">
              Uninsured/Underinsured Motorist
            </h4>
            <div *ngFor="let state of umuimStates">
              <app-attune-bop-bind-excess-umuim
                stateCode="{{ state }}"
                (stateFormGroup)="addToUMUIMFormGroup($event, state)"
                [submitted]="submitted"
                [limit]="umuimLimit(state)"
                [excessCLLimit]="excessCLLimit"
              ></app-attune-bop-bind-excess-umuim>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="getCurrentFormStep() === 'Payment Details'">
          <header class="bind-quote-header">
            <h1 class="form-header-below-link">Payment details</h1>
          </header>
          <div class="helper-card">
            <p class="p__bold">Wondering how we bill?</p>
            <p>
              We've got answers. Check out our
              <a
                href="https://attunehelp.zendesk.com/hc/en-us/sections/360001532851-Billing"
                target="_blank"
                >billing FAQ page</a
              >
              to learn more.
            </p>
          </div>
          <div class="bind-flow-subsection">
            <ng-container *ngIf="policyPaymentPresenter as policyPayment">
              <app-form-field-radio
                inputId="numberOfPayments"
                [form]="form"
                [submitted]="submitted"
                questionText="Payment plan"
                [options]="paymentPlanOptions"
                cssClass="js-bind-payment-details"
                nameOfFormControl="numberOfPayments"
                [disabledOptions]="disabledPaymentPlans"
                [tooltipText]="
                  isBundleBindFlow
                    ? 'Payment plans are available only for BOP. If a payment plan is selected, all Cyber premium and fees will be included in the first installment.'
                    : ''
                "
                questionNote="{{
                  isBopPaymentRadioDisabled
                    ? 'Due to the small premium size, multiple payment options are disabled for this quote.'
                    : ''
                }}"
                optionsLabelCssClass="{{
                  isBopPaymentRadioDisabled ? 'radio-question-label__disabled' : ''
                }}"
              >
              </app-form-field-radio>
              <div *ngIf="policyPayment.installments > 0">
                <h5 class="payment-subtitle">Down Payment</h5>
                <dl class="dl__no-dotted-line">
                  <dt>{{ isBundleBindFlow ? 'BOP Premium' : 'Premium' }}</dt>
                  <dd class="js-payment-details-downPayment">
                    {{ policyPayment.downPaymentPremium | currency }}
                  </dd>
                </dl>
                <ng-container *ngFor="let lineItem of getAdditionalLineItems()">
                  <dl class="dl__no-dotted-line">
                    <dt>{{ lineItem.label }}</dt>
                    <dd>
                      {{ lineItem.amount | currency }}
                    </dd>
                  </dl>
                </ng-container>
                <dl *ngIf="policyPayment.technologyFee > 0" class="dl__no-dotted-line">
                  <dt>
                    Technology Fee
                    <span
                      class="more-info-tip tool-tip tool-tip__large"
                      [attr.data-tooltip]="techFeeText"
                    >
                      <span class="icon icon-question"></span>
                    </span>
                    <span class="sr-only js-technology-fee-tooltip">{{ techFeeText }}</span>
                  </dt>
                  <dd class="js-technology-fee">{{ policyPayment.technologyFee | currency }}</dd>
                </dl>
                <dl *ngIf="policyPayment.processingFee > 0" class="dl__no-dotted-line">
                  <dt>
                    Processing Fee
                    <span
                      class="more-info-tip tool-tip tool-tip__large"
                      data-tooltip="This is a flat fee of $70 to offset the operational costs of servicing the policy."
                    >
                      <span class="icon icon-question"></span>
                    </span>
                    <span class="sr-only"
                      >This is a flat fee of $70 to offset the operational costs of servicing the
                      policy.</span
                    >
                  </dt>
                  <dd class="js-processing-fee">{{ policyPayment.processingFee | currency }}</dd>
                </dl>
                <dl class="dl__no-dotted-line">
                  <dt>Installment fee</dt>
                  <dd class="js-installment-fee installment-fee-down-payment">
                    {{ installmentFeeDownPayment | currency }}
                  </dd>
                </dl>
                <dl class="dl__no-dotted-line">
                  <dt>Tax</dt>
                  <dd class="js-payment-details-downPayment-tax">
                    {{ totalTax() | currency }}
                  </dd>
                </dl>
                <dl class="dl__subtotal dl__no-dotted-line">
                  <dt>Subtotal</dt>
                  <dd>
                    <strong class="js-payment-details-downPayment-subtotal">{{
                      downPaymentSubtotal() | currency
                    }}</strong>
                  </dd>
                </dl>
                <h5 class="payment-subtitle">Installments x {{ policyPayment.installments }}</h5>
                <dl class="dl__no-dotted-line">
                  <dt>Installment premium</dt>
                  <dd class="js-payment-details-installment">
                    {{ policyPayment.installmentPremium | currency }}
                  </dd>
                </dl>
                <dl class="dl__no-dotted-line">
                  <dt>Installment fee</dt>
                  <dd class="js-installment-fee installment-fee-installment-payment">
                    {{ installmentFeeAfterDownPayment | currency }}
                  </dd>
                </dl>
                <dl class="dl__subtotal dl__no-dotted-line">
                  <dt>Subtotal</dt>
                  <dd>
                    <strong class="js-wc-payment-details-downPayment-subtotal">{{
                      (policyPayment.installmentPremium + installmentFeeAfterDownPayment) *
                        policyPayment.installments | currency
                    }}</strong>
                  </dd>
                </dl>
              </div>
              <div *ngIf="policyPayment.installments === 0">
                <dl class="dl__no-dotted-line">
                  <dt>{{ isBundleBindFlow ? 'BOP Premium' : 'Premium' }}</dt>
                  <dd class="js-payment-details-premium">
                    {{ policyPayment.downPaymentPremium | currency }}
                  </dd>
                </dl>
                <ng-container *ngFor="let lineItem of getAdditionalLineItems()">
                  <dl class="dl__no-dotted-line">
                    <dt>{{ lineItem.label }}</dt>
                    <dd>
                      {{ lineItem.amount | currency }}
                    </dd>
                  </dl>
                </ng-container>
                <dl *ngIf="policyPayment.technologyFee > 0" class="dl__no-dotted-line">
                  <dt>
                    Technology Fee
                    <span
                      class="more-info-tip tool-tip tool-tip__large"
                      [attr.data-tooltip]="techFeeText"
                    >
                      <span class="icon icon-question"></span>
                    </span>
                    <span class="sr-only js-technology-fee-tooltip">{{ techFeeText }}</span>
                  </dt>
                  <dd class="js-technology-fee">{{ policyPayment.technologyFee | currency }}</dd>
                </dl>
                <dl *ngIf="policyPayment.processingFee > 0" class="dl__no-dotted-line">
                  <dt>
                    Processing Fee
                    <span
                      class="more-info-tip tool-tip tool-tip__large"
                      data-tooltip="This is a flat fee of $70 to offset the operational costs of servicing the policy."
                    >
                      <span class="icon icon-question"></span>
                    </span>
                    <span class="sr-only"
                      >This is a flat fee of $70 to offset the operational costs of servicing the
                      policy.</span
                    >
                  </dt>
                  <dd class="js-processing-fee">{{ policyPayment.processingFee | currency }}</dd>
                </dl>
                <dl class="dl__no-dotted-line">
                  <dt>Tax</dt>
                  <dd class="js-payment-details-tax">
                    {{ totalTax() | currency }}
                  </dd>
                </dl>
              </div>
              <div class="bind-quote-total">
                <div class="bind-quote-total-lineitem">
                  <strong>Estimated total cost*</strong>
                  <div
                    class="big-total js-payment-details-total"
                    *ngIf="policyPayment.installments > 0; else other"
                  >
                    <span>$</span
                    >{{
                      totalDollars() +
                        installmentFeeDownPayment +
                        policyPayment.installments * installmentFeeAfterDownPayment
                        | number: '2.0-0'
                    }}.<span class="big-total-cents">{{ totalCents() | number: '2.0-0' }}</span>
                  </div>
                  <div
                    class="big-total js-payment-details-total"
                    *ngIf="policyPayment.installments === 0"
                  >
                    <span>$</span>{{ totalDollars() | number: '2.0-0' }}.<span
                      class="big-total-cents"
                      >{{ totalCents() | number: '2.0-0' }}</span
                    >
                  </div>
                </div>
                <app-dialog-box
                  type="warning"
                  class="js-dialog-text dialog-text__margin-top dialog-text__full-width"
                >
                  <ul
                    class="mt-0 mb-0"
                    [ngClass]="{ 'bulleted-list': policyPayment.hasCcProcessingFee }"
                  >
                    <li *ngIf="policyPayment.hasCcProcessingFee">
                      A <strong>3.2% processing fee</strong> applies to
                      <strong>card transactions</strong
                      >{{ isBundleBindFlow ? ' (for BOP premium and fees only)' : '' }}.
                    </li>
                    <li class="mb-0">
                      Please refer to the quote letter for calculated State taxes, surcharges and
                      fees and payment plan installment fees which may be applicable to this
                      quotation.
                    </li>
                  </ul>
                </app-dialog-box>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </main>

      <div class="form-footer" *ngIf="!isEffectiveDateInPast">
        <div class="validation" role="alert" *ngIf="submitted && !isCurrentStepValid()">
          <p class="validation-message">Please fill out all required fields</p>
        </div>
        <div *ngIf="isFinalStep()" class="email-confirm">
          <h2 class="email-confirm-header">Please confirm</h2>
          <p>
            <strong>The first invoice will be sent to the insured once the policy is bound.</strong>
            Please take a minute to review the insured email address is correct for all billing
            correspondence.
          </p>
          <app-form-field-text
            inputId="bind-emailAddress"
            [form]="form.get('policyDetails')"
            [submitted]="submitted"
            (focusOut)="completeEmailTip()"
            labelText="Primary insured email address"
            nameOfFormControl="emailAddress"
            describedBy="{{ showEmailTip() ? 'bind-email-tip' : '' }}"
            appRemoveWhitespaceMask
          >
          </app-form-field-text>
          <app-form-field-text
            inputId="bind-emailAddressConfirm"
            [form]="form.get('policyDetails')"
            [submitted]="submitted"
            (focusOut)="completeEmailTip()"
            labelText="Secondary insured email address"
            nameOfFormControl="additionalEmailAddress"
            describedBy="{{ showEmailTip() ? 'bind-email-tip' : '' }}"
            appRemoveWhitespaceMask
          >
          </app-form-field-text>
          <button
            *ngIf="this.isFinalStep()"
            class="button button__primary js-bind-bop-quote-button"
            [ngClass]="{ button__discouraged: !form.valid }"
            [disabled]="showProgressBar"
            (click)="submitBindForm()"
          >
            <span *ngIf="!showProgressBar">Bind quote</span>
            <span *ngIf="showProgressBar">Binding quote...</span>
          </button>
        </div>
        <div class="nav-button-group nav-button-group__left">
          <button
            *ngIf="!isFinalStep()"
            id="quote-form-next-button"
            [ngClass]="{
              button: true,
              'js-step-forward-button': true,
              button__discouraged: !this.isCurrentStepValid()
            }"
            type="submit"
            class="button__primary"
            [disabled]="(submitted && !isCurrentStepValid()) || updatingExcess"
          >
            <div class="button-loader" *ngIf="updatingExcess">
              <svg class="circle-spinner">
                <circle
                  cx="11"
                  cy="11"
                  r="9"
                  stroke-width="2"
                  stroke-dasharray="0,1000"
                  d="M5 40 l215 0"
                  fill="transparent"
                />
              </svg>
            </div>
            <span *ngIf="!updatingExcess">Next</span>
            <span *ngIf="updatingExcess">Updating quote...</span>
          </button>
        </div>
      </div>
      <small
        *ngIf="getCurrentFormStep() === 'Policy Details' && !isEffectiveDateInPast"
        class="gray-text"
      >
        <sup>1</sup>
        The insured consents to receive text message communications to the mobile phone number
        provided on this form. The insured understands that it may be charged message and data rates
        by its wireless carrier. Such messages may be generated by an automated messaging system and
        it may opt-out of this service by replying STOP to any message.
      </small>
    </form>
  </div>
  <div
    class="app-page-form-side-rail js-bind-guide"
    *ngIf="!quoteDetailsLoading && this.formSteps.currentStepIndex === 0"
  >
    <div class="bind-bop-rail-card">
      <a (click)="playBindVideo()" (keyup.enter)="playBindVideo()">
        <img src="/assets/img/2020_billing_video_thumbnail.jpg" alt="" />
        <p class="bind-bop-rail-card-text">Watch Attune's 60 second guide to binding and billing</p>
      </a>
      <app-modal
        [open]="bindGuideModalOpen"
        modalType="video"
        (handleModalClose)="toggleBindVideo()"
      >
        <iframe
          [ngStyle]="{ width: videoWidth, height: videoHeight }"
          src="https://www.youtube.com/embed/3Jop0KT50mo"
          frameborder="0"
          *ngIf="bindGuideModalOpen || !bindGuideModalFirstTimeClose"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </app-modal>
    </div>
    <section class="faq-container">
      <h5>Frequently Asked</h5>
      <app-faqs-list [faqs]="bindGuideFAQs"></app-faqs-list>
    </section>
  </div>
</div>

<ng-container *ngIf="bindFirstTimeModalOpen">
  <app-modal
    [open]="bindFirstTimeModalOpen"
    (handleModalClose)="hideFirstTimeModal()"
    modalClass="app-modal-content__left-align app-modal-content__first-bind-video"
  >
    <div class="pill-tag pill-tag__main-inverted">Getting started</div>
    <h2 class="modal-header-with-tag">Binding a policy?</h2>
    <p>
      Congratulations! We've put together a short helpful video to explain everything you need to
      know. You can also learn more about binding and billing
      <a href="https://attunehelp.zendesk.com/hc/en-us/" target="_blank">here</a>.
    </p>

    <section class="offset-border-group">
      <iframe
        [ngStyle]="{ width: '540px', height: '325px' }"
        src="https://www.youtube.com/embed/3Jop0KT50mo"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <h3>Watch Attune's 60 second guide to binding and billing</h3>
    </section>
  </app-modal>
</ng-container>

<app-quote-error-modal
  [open]="errorModalOpen"
  [errorType]="bindError"
  (closeQuoteErrorModal)="onCloseErrorModal($event)"
>
</app-quote-error-modal>

<app-price-difference-modal
  [displayPriceDiffModal]="displayPriceDiffModal"
  [originalTotalCost]="originalTotalCost"
  [newTotalCost]="newTotalCost"
  (triggerNavigationToAccount)="goBackToAccount()"
  (triggerBindServiceCall)="callBindService()"
></app-price-difference-modal>

<ng-container *ngIf="showProgressBar">
  <app-binding-animation
    [successComment]="isMoratorium ? 'Bind requested' : 'Preparing policy...'"
    [showProgressBar]="true"
    [finish$]="bindSuccess$"
  ></app-binding-animation>
</ng-container>
