import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ResetPasswordService } from 'app/core/services/reset-password.service';

@Component({
  selector: 'app-reset-password.app-page.app-page__login',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnDestroy, OnInit {
  loading = false;
  error = '';
  completedForEmail: string | null = null;
  email: UntypedFormControl;
  private sub: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private resetPasswordService: ResetPasswordService
  ) {}

  ngOnInit() {
    const { email } = this.route.snapshot.queryParams;
    this.email = this.formBuilder.control(email || '');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  handleSubmit(event: any) {
    this.loading = true;
    this.error = '';
    const submittedEmail = this.email.value.trim();

    this.sub.add(
      this.resetPasswordService.forgotPassword(submittedEmail).subscribe({
        next: (response) => {
          this.loading = false;
          this.completedForEmail = submittedEmail;
        },
        error: (err) => {
          this.error =
            'Error updating password. Please try again, or contact our Customer Care Team.';
          this.loading = false;
        },
      })
    );

    event.preventDefault();
  }
}
