export const handleQuoteError = (respErrorMsg: string, callback: Function) => {
  let err = '';
  if (respErrorMsg) {
    if (respErrorMsg.startsWith('com.guidewire')) {
      err = respErrorMsg.split(':')[1];
    } else {
      err = respErrorMsg;
    }
    return callback(err);
  }
};

export const notNull =
  <T>() =>
  (x: T | null): x is T =>
    x !== null;

// Per Lydia from DS:
// The BOP Industry Group is just the cleaner version of the bi.policies.class_code_property_type
export const determineBopIndustryGroup = (businessType: string) => {
  let group = businessType;
  if (businessType && businessType.toLowerCase().includes('restaurant')) {
    group = 'Restaurant';
  } else if (
    (businessType && businessType.toLowerCase().includes('condominium')) ||
    (businessType && businessType.toLowerCase().includes('mixed'))
  ) {
    group = 'Other';
  }
  return group;
};
