// Libraries
import { cloneDeep, get } from 'lodash';
import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of as observableOf, Subject, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// Components
import { FormDslSteppedFormBaseComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form-base.component';

// Services
import { AttuneBopQuoteService } from 'app/features/attune-bop/services/attune-bop-quote.service';
import { HabBindQuoteService } from 'app/hab/services/hab-bind-quote.service';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { SentryService } from 'app/core/services/sentry.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';

// helpers
import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import { UNKNOWN_ERROR_WITHOUT_RETRY } from 'app/shared/quote-error-modal/errors';
import { getControl } from 'app/shared/helpers/form-helpers';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';

@Component({
  templateUrl: './hab-bind-quote.component.html',
})
export class HabBindQuoteComponent
  extends FormDslSteppedFormBaseComponent<HabBindQuoteService>
  implements OnInit, OnDestroy
{
  constructor(
    public formService: HabBindQuoteService,
    public quoteService: AttuneBopQuoteService,
    protected route: ActivatedRoute,
    protected router: Router,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService
  ) {
    super(formService, route, router);
  }

  public isDevMode: boolean = isDevMode();

  public form: UntypedFormGroup;
  public quoteDetailsLoading = true;
  public accountId: string;
  public quoteId: string;
  public policyId: string;
  public policyTermNumber: string;
  quoteDetails: QuoteDetails | null = null;
  errorModalOpen = false;
  showProgressBar = false;
  displayPriceDiffModal = false;
  oldTotalCost: number;
  newTotalCost: number;

  errorType = UNKNOWN_ERROR_WITHOUT_RETRY;
  bindSuccess$ = new Subject();

  ngOnInit() {
    super.ngOnInit();
    this.accountId = this.route.snapshot.params.accountId;
    this.quoteId = this.route.snapshot.params.quoteId;
    this.formService.initializeForm();
    this.form = this.formService.form;
    this.formService.getDetailsFromPolicy(this.quoteId).subscribe((quoteDetails) => {
      this.formService.setDetailsFromPolicy(quoteDetails);
      this.formService.emitQuoteDetails(quoteDetails);
      this.quoteDetails = quoteDetails;
      this.oldTotalCost = quoteDetails.totalCost;
      this.quoteDetailsLoading = false;
    });

    zendeskLeftSnap();
  }

  public handleFailedQuote(): void {
    this.errorType = cloneDeep(UNKNOWN_ERROR_WITHOUT_RETRY);
    this.errorType.body =
      "We're having trouble issuing this policy.  Please try again or contact the customer care team if you continue to have this issue";
    this.errorModalOpen = true;
    this.showProgressBar = false;
  }

  public loadInitialData(): void {}

  public onIncrementedStep(nextStep: RouteFormStep): void {
    this.navigateToCurrentStep();
  }

  public sendForm(): Observable<QSQuoteSubmission[] | null> {
    this.showProgressBar = true;
    if (this.quoteDetails === null) {
      this.sentryService.notify('Trying to submit bind form before policy details were retrieved', {
        severity: 'error',
      });
      return observableOf(null);
    }

    // Ensure we have the latest account information after binding, whether it succeeds or fails.
    if (
      this.formService.originalEffectiveDate ===
        getControl(this.formService.form, 'policyDetails.effectiveDate').value ||
      this.displayPriceDiffModal
    ) {
      this.displayPriceDiffModal = false;
      return this.formService.sendForm(
        this.accountId,
        this.quoteId,
        this.quoteDetails as QuoteDetails
      );
    }
    return this.quoteService
      .updateHabEffectiveDateById(
        this.quoteId,
        getControl(this.formService.form, 'policyDetails.effectiveDate').value
      )
      .pipe(
        switchMap((resp: QSQuoteSubmissionResponse) => {
          const newTotalCost: string | null = get(resp, 'results[0].data.totalCost');
          if (!newTotalCost) {
            this.sentryService.notify(
              'Error while binding.  Could not get new cost of quote when checking if price changed',
              {
                severity: 'error',
                metaData: {
                  accountId: this.accountId,
                  quoteId: this.quoteId,
                  resp,
                },
              }
            );
            this.showProgressBar = false;
            this.errorModalOpen = true;
            return observableOf(null);
          } else {
            const formattedTotalCost = parseMoney(newTotalCost);
            // If dates are different and prices are diff, display modal
            if (Math.abs(formattedTotalCost - this.oldTotalCost) >= 1) {
              this.newTotalCost = formattedTotalCost;
              this.showProgressBar = false;
              this.displayPriceDiffModal = true;
              return observableOf([]);
            }
            // If dates are diff, but prices are same, continue to bind.
            return this.formService.sendForm(
              this.accountId,
              this.quoteId,
              this.quoteDetails as QuoteDetails
            );
          }
        }),
        catchError((error) => {
          this.sentryService.notify(
            'Error while updating effective date and getting new cost of quote while binding',
            {
              severity: 'error',
              metaData: {
                accountId: this.accountId,
                quoteId: this.quoteId,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            }
          );
          return observableOf(null);
        })
      );
  }

  public checkQuotingSuccess(response: QSQuoteSubmission[] | null): Observable<boolean> {
    if (response === null) {
      return observableOf(false);
    }
    this.policyId = get(response, '[0].data.policyId');
    this.policyTermNumber = get(response, '[0].data.policyTermNumber') || 1;
    return observableOf(true);
  }

  public handleSuccessfulQuote(): void {
    if (this.displayPriceDiffModal) {
      return;
    }
    this.bindSuccess$.next(true);
    timer(3000).subscribe(() => {
      this.showProgressBar = false;
      this.navigateToPolicyPage();
    });
  }

  public handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep(), step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    removeZendeskLeftSnap();
  }

  navigateToPolicyPage() {
    // Note: If policy Id is not passed in, then just navigate to the account page.
    if (!this.policyId) {
      this.goBackToAccount();
      return;
    }

    this.insuredAccountService.cachebust();
    this.router.navigate(
      [
        '/accounts',
        this.route.snapshot.params.accountId,
        'terms',
        this.policyId,
        this.policyTermNumber,
      ],
      {
        queryParams: {
          waitForInvoice: 'true',
        },
      }
    );
  }

  public closeQuoteErrorModal(result: { close: boolean; retry: boolean }): void {
    if (!result.close && !result.retry) {
      this.goBackToAccount();
      return;
    }
    if (result.close) {
      this.errorModalOpen = false;
    }
    if (result.retry) {
      this.formService.stepForward();
    }
  }

  goBackToAccount() {
    this.insuredAccountService.cachebust();
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }
}
