import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatestWith, of as observableOf, Subscription } from 'rxjs';

import * as moment from 'moment';
import { ProducerDetailsResponse } from '../../../bop/guidewire/typings';
import { CurrencyPipe } from '@angular/common';
import { SentryService } from '../../../core/services/sentry.service';
import { InformService } from '../../../core/services/inform.service';
import { BillingApiService } from '../../insured-account/billing-api.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../core/services/feature-flag.service';
import {
  COMMISSION_PAYOUT_DETAILS_HIDDEN_HEADER_TEXT,
  COMMISSION_PAYOUT_DETAILS_NON_FINANCE_USER_MESSAGE,
  COMMISSION_PAYOUT_DETAILS_NOT_AVAILABLE_HEADER_TEXT,
  COMMISSION_PAYOUT_DETAILS_NOT_AVAILABLE_MESSAGE,
  CommissionPayableDetails,
} from '../commissions-constants';
import { OktaGroup } from 'app/constants';
import { UserApiService } from 'app/core/services/user-api-service';
import { UserService } from 'app/core/services/user.service';
import { User } from '@sentry/browser';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { DocumentService } from '../../../features/documents/services/document.service';
import { catchError } from 'rxjs/operators';

const isFinanceAdmin = (groups: string[]) => groups && groups.includes(OktaGroup.FINANCE_ADMIN);

@Component({
  selector: 'app-commission-info',
  templateUrl: 'commission-info.component.html',
  providers: [CurrencyPipe],
})
export class CommissionInfoComponent implements OnInit, OnDestroy {
  constructor(
    private sentryService: SentryService,
    private billingApiService: BillingApiService,
    private documentService: DocumentService,
    private informService: InformService,
    private featureFlagService: FeatureFlagService,
    private userApiService: UserApiService,
    private userService: UserService,
    private insuredAccountService: InsuredAccountService
  ) {}
  private sub: Subscription = new Subscription();

  producerDetails: ProducerDetailsResponse;

  userGroups: string[] = [];
  payableStatus: CommissionPayableDetails | null;

  pageLoading = true;
  statementsLoading = false;
  statementsError = false;
  statementStartDate = moment.utc().subtract(1, 'year');

  showNegativeExplanationModal = false;
  showCommissionPlanInformationModal = false;

  dialogHeader: string = '';
  dialogBody: string = '';
  currentUser: User;

  commissionPlanLinkEnabled: boolean = false;
  attuneAndPartnerCommissionData: AttuneOrPartnerCommissionStatement[] = [];

  getProducerFinanceInformation() {
    const payableStatus$ = this.billingApiService.getPayableStatus().pipe(
      catchError(() => {
        return observableOf(null);
      })
    );
    const getCurrentGroups$ = this.userApiService.getCurrentGroups().pipe(
      catchError(() => {
        return observableOf(null);
      })
    );
    const getProducerDetails$ = this.insuredAccountService
      .getProducerDetails(this.currentUser.producer)
      .pipe(
        catchError(() => {
          return observableOf(null);
        })
      );

    payableStatus$
      .pipe(combineLatestWith(getCurrentGroups$, getProducerDetails$))
      .subscribe(([payableStatus, currentGroups, producerDetails]) => {
        this.payableStatus = payableStatus;

        if (currentGroups) {
          this.setUserGroups(currentGroups.result);
        }

        if (producerDetails) {
          this.producerDetails = producerDetails;
        }

        if (payableStatus?.hasDirectCommission && !this.canManageFinances()) {
          this.dialogHeader = COMMISSION_PAYOUT_DETAILS_HIDDEN_HEADER_TEXT;
          this.dialogBody = COMMISSION_PAYOUT_DETAILS_NON_FINANCE_USER_MESSAGE(
            payableStatus.financeUsers
          );
        }

        if (producerDetails && payableStatus && !payableStatus.hasDirectCommission) {
          this.dialogHeader = COMMISSION_PAYOUT_DETAILS_NOT_AVAILABLE_HEADER_TEXT;
          this.dialogBody = COMMISSION_PAYOUT_DETAILS_NOT_AVAILABLE_MESSAGE(
            producerDetails.Organization
          );
        }

        this.pageLoading = false;
      });
  }

  ngOnInit() {
    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.currentUser = user;
        this.getProducerFinanceInformation();
        this.loadAttuneAndPartnerStatements();
        this.sub.add(
          this.featureFlagService
            .guaranteeIsEnabled(BOOLEAN_FLAG_NAMES.COMMISSION_PLAN)
            .subscribe((isEnabled) => {
              this.commissionPlanLinkEnabled = isEnabled || false;
            })
        );
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setUserGroups(groups: string[]) {
    this.userGroups = groups;
  }

  loadAttuneAndPartnerStatements() {
    this.statementsLoading = true;
    this.statementsError = false;
    this.sub.add(
      this.billingApiService
        .getAttuneAndPartnerCommissionStatements(this.currentUser.producer, this.statementStartDate)
        .subscribe(
          (statements) => {
            this.attuneAndPartnerCommissionData = statements;
          },
          () => {
            this.statementsError = true;
          },
          () => {
            this.statementsLoading = false;
          }
        )
    );
  }

  openNegativeExplanationModal() {
    this.showNegativeExplanationModal = true;
  }

  closeNegativeExplanationModal() {
    this.showNegativeExplanationModal = false;
  }

  canManageFinances() {
    if (!this.userGroups) {
      return false;
    }

    return isFinanceAdmin(this.userGroups);
  }

  isDirectlyPayed() {
    if (!this.payableStatus) {
      return false;
    }

    return this.payableStatus.hasDirectCommission;
  }

  openCommissionPlanInformationModal() {
    this.showCommissionPlanInformationModal = true;
  }

  closeCommissionPlanInformationModal() {
    this.showCommissionPlanInformationModal = false;
  }
}
