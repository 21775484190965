import { Directive, Input, OnInit } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';

@Directive({
  selector: '[appNumberMask]',
})
export class NumberMaskDirective extends BaseMaskDirective implements OnInit {
  @Input() maxLength: number;

  ngOnInit() {
    if (this.maxLength) {
      this.MAX_DIGITS = this.maxLength;
    }
  }

  getMask(value: string) {
    const numericString: string | null = this.getNumericString(value);
    return numericString ? numericString : '';
  }
}
