export interface Carrier {
  publicId: number;
  name: string;
}

export const ALL_CARRIERS: Carrier[] = [
  { publicId: 424, name: 'Accident Fund National Insurance Co' },
  { publicId: 507, name: 'American Casualty Company of Reading, Pennsylvania' },
  { publicId: 704, name: 'Benchmark Insurance Company' },
  { publicId: 1431, name: 'Employers Preferred Insurance Company' },
  { publicId: 1641, name: 'FOREMOST COUNTY MUTUAL INSURANCE CO' },
  { publicId: 1642, name: 'Foremost Insurance Co Grand Rapids, MI' },
  { publicId: 2074, name: 'Hartford Insurance Company of the Southeast' },
  { publicId: 2168, name: 'Hamilton Insurance Company' },
  { publicId: 2193, name: 'Hartford Accident and Indemnity Company' },
  { publicId: 3390, name: 'Plymouth Rock Assurance Corporation' },
  { publicId: 3544, name: 'Plaza Insurance Company' },
  { publicId: 4120, name: 'Texas Mutual Insurance Company' },
  { publicId: 4554, name: 'WESCO INSURANCE COMPANY' },
  { publicId: 4717, name: 'Acadia Insurance Company' },
  { publicId: 4718, name: 'Addison Insurance Company' },
  { publicId: 4719, name: 'Admiral Insurance Company' },
  { publicId: 4720, name: 'Advantage Workers Compensation Ins Co' },
  { publicId: 4721, name: 'Affiliated FM Insurance Company' },
  { publicId: 4722, name: 'Agri General Insurance Company' },
  { publicId: 4723, name: 'AIG Hawaii Insurance Company Inc' },
  { publicId: 4724, name: 'AIG National Insurance Company Inc' },
  { publicId: 4725, name: 'AIU Insurance Company' },
  { publicId: 4726, name: 'Alamance Insurance Company' },
  { publicId: 4727, name: 'Alaska National Insurance Company' },
  { publicId: 4728, name: 'Alfa General Insurance Corporation' },
  { publicId: 4729, name: 'Alfa Insurance Corporation' },
  { publicId: 4730, name: 'Alfa Mutual General Insurance Company' },
  { publicId: 4731, name: 'Alfa Mutual Insurance Company' },
  { publicId: 4732, name: 'All America Insurance Company' },
  { publicId: 4733, name: 'Alliance Indemnity Company' },
  { publicId: 4734, name: 'Alliance Insurance Company, Inc.' },
  { publicId: 4735, name: 'Allianz Underwriters Insurance Company' },
  { publicId: 4736, name: 'ALLIED P & C Ins Co' },
  { publicId: 4737, name: 'Allmerica Financial Alliance Insurance Company' },
  { publicId: 4738, name: 'Allmerica Financial Benefit Insurance Company' },
  { publicId: 4739, name: 'Allstate County Mutual Insurance Company' },
  { publicId: 4740, name: 'Allstate Indemnity Company' },
  { publicId: 4741, name: 'Allstate Insurance Company' },
  { publicId: 4742, name: 'Allstate Property And Casualty Insurance Company' },
  { publicId: 4743, name: 'Allstate Texas Lloyds' },
  { publicId: 4744, name: 'Alpha Property & Casualty Insurance Co' },
  { publicId: 4745, name: 'AMCO Insurance Company' },
  { publicId: 4746, name: 'America First Insurance Company' },
  { publicId: 4747, name: 'American Agricultural Insurance Company' },
  { publicId: 4748, name: 'American Alternative Insurance Corporation' },
  { publicId: 4749, name: 'American Automobile Insurance Company' },
  { publicId: 4750, name: 'American Bankers Insurance Company of Florida' },
  { publicId: 4751, name: 'American Central Insurance Company' },
  { publicId: 4752, name: 'American Commerce Insurance Company' },
  { publicId: 4753, name: 'American Contractors Insurance Co RRG' },
  { publicId: 4754, name: 'American Contractors Insurance Group Ltd' },
  { publicId: 4755, name: 'American Economy Insurance Company' },
  { publicId: 4756, name: 'American Empire Insurance Company' },
  { publicId: 4757, name: 'American Empire Surplus Lines Insurance Company' },
  { publicId: 4758, name: 'American Employers  Insurance Company' },
  { publicId: 4759, name: 'American Equity Insurance Company' },
  { publicId: 4760, name: 'American Equity Specialty Insurance Company' },
  { publicId: 4761, name: 'American Family Home Insurance Company' },
  { publicId: 4762, name: 'American Family Insurance Company' },
  { publicId: 4763, name: 'American Family Mutual Insurance Company' },
  { publicId: 4764, name: 'American Fire and Casualty Company' },
  { publicId: 4765, name: 'American Guarantee and Liability Insurance Company' },
  { publicId: 4766, name: 'American Hardware Mutual Insurance Company' },
  { publicId: 4767, name: 'American Home Assurance Company' },
  { publicId: 4768, name: 'American Insurance Company' },
  { publicId: 4769, name: 'American International Ins Co of CA, Inc' },
  { publicId: 4770, name: 'American International Ins Co of NJ' },
  { publicId: 4771, name: 'American International Pacific Insurance Company' },
  { publicId: 4772, name: 'AIG Property Casualty Company' },
  { publicId: 4773, name: 'American Interstate Insurance Company' },
  { publicId: 4774, name: 'American Merchants Casualty Company' },
  { publicId: 4775, name: 'American Mercury Insurance Company' },
  { publicId: 4776, name: 'American Mercury Lloyds Insurance Company' },
  { publicId: 4777, name: 'American Modern Home Insurance Company' },
  { publicId: 4778, name: 'American Modern Lloyds Insurance Company' },
  { publicId: 4779, name: 'American National General Insurance Co' },
  { publicId: 4780, name: 'American National Lloyds Insurance Co' },
  { publicId: 4781, name: 'American National Property & Casualty Co' },
  { publicId: 4782, name: 'American Pacific Insurance Company Inc' },
  { publicId: 4783, name: 'American Road Insurance Company' },
  { publicId: 4784, name: 'American Safety RRG, Inc' },
  { publicId: 4785, name: 'American Security Insurance Company' },
  { publicId: 4786, name: 'American Select Insurance Company' },
  { publicId: 4787, name: 'American Southern Home Insurance Company' },
  { publicId: 4788, name: 'American Standard Insurance Company of OH' },
  { publicId: 4789, name: 'American Standard Insurance Company of WI' },
  { publicId: 4790, name: 'American Standard Lloyds Insurance Co' },
  { publicId: 4791, name: 'American States Insurance Company' },
  { publicId: 4792, name: 'American States Insurance Company of TX' },
  { publicId: 4793, name: 'American States Lloyds Insurance Company' },
  { publicId: 4794, name: 'American States Preferred Insurance Co' },
  { publicId: 4795, name: 'American Western Home Insurance Company' },
  { publicId: 4796, name: 'American Zurich Insurance Company' },
  { publicId: 4797, name: 'Amerisure Insurance Company' },
  { publicId: 4798, name: 'AMEX Assurance Company' },
  { publicId: 4799, name: 'AmGUARD Insurance Company' },
  { publicId: 4800, name: 'Amica Mutual Insurance Company' },
  { publicId: 4801, name: 'Appalachian Insurance Company' },
  { publicId: 4802, name: 'Argonaut Insurance Company' },
  { publicId: 4803, name: 'Argonaut-Midwest Insurance Company' },
  { publicId: 4804, name: 'Argonaut-Southwest Insurance Company' },
  { publicId: 4805, name: 'Associated Indemnity Corporation' },
  { publicId: 4806, name: 'Associated International Insurance Company' },
  { publicId: 4807, name: 'Assurance Company of America' },
  { publicId: 4808, name: 'Athena Assurance Company' },
  { publicId: 4809, name: 'Atlantic Employers Insurance Company' },
  { publicId: 4810, name: 'Atlantic States Insurance Company' },
  { publicId: 4811, name: 'Austin Mutual Insurance Company' },
  { publicId: 4812, name: 'Auto Club Family Insurance Company' },
  { publicId: 4813, name: 'Auto Club Group Insurance Company' },
  { publicId: 4814, name: 'Auto Club Insurance Association' },
  { publicId: 4815, name: 'Auto-Owners Insurance Company' },
  { publicId: 4816, name: 'Automobile Club Inter-Insurance Exchange' },
  { publicId: 4817, name: 'Automobile Ins Co of Hartford, CT' },
  { publicId: 4818, name: 'Avemco Insurance Company' },
  { publicId: 4819, name: 'Balboa Insurance Company' },
  { publicId: 4820, name: 'Baltimore Equitable Society' },
  { publicId: 4821, name: 'Bankers Standard Fire and Marine Company' },
  { publicId: 4822, name: 'Bankers Standard Insurance Company' },
  { publicId: 4823, name: 'Bay State Insurance Company' },
  { publicId: 4824, name: 'BCS Insurance Company' },
  { publicId: 4825, name: 'Berkley Insurance Company' },
  { publicId: 4826, name: 'Berkley Regional Insurance Company' },
  { publicId: 4827, name: 'Berkshire Hathaway Homestate Ins Co' },
  { publicId: 4828, name: 'Bituminous Casualty Corporation' },
  { publicId: 4829, name: 'Bituminous Fire & Marine Insurance Co' },
  { publicId: 4830, name: 'Blue Ridge Indemnity Company' },
  { publicId: 4831, name: 'Brethren Mutual Insurance Company' },
  { publicId: 4832, name: 'Bridgefield Casualty Insurance Company' },
  { publicId: 4833, name: 'Bridgefield Employers Insurance Company' },
  { publicId: 4834, name: 'Brierfield Insurance Company' },
  { publicId: 4835, name: 'Brotherhood Mutual Insurance Company' },
  { publicId: 4836, name: 'Builders Mutual Insurance Company' },
  { publicId: 4837, name: 'Burlington Insurance Company' },
  { publicId: 4838, name: 'California Automobile Insurance Company' },
  { publicId: 4839, name: 'California Capital Insurance Company' },
  { publicId: 4840, name: 'California Casualty & Fire Insurance Co' },
  { publicId: 4841, name: 'California Casualty General Ins Co of OR' },
  { publicId: 4842, name: 'California Casualty Indemnity Exch' },
  { publicId: 4843, name: 'California Casualty Insurance Company' },
  { publicId: 4844, name: 'California State Auto Assn Inter-Ins Bur' },
  { publicId: 4845, name: 'Cambridge Mutual Fire Insurance Company' },
  { publicId: 4846, name: 'Camden Fire Insurance Association' },
  { publicId: 4847, name: 'Canal Indemnity Company' },
  { publicId: 4848, name: 'Canal Insurance Company' },
  { publicId: 4849, name: 'Capitol Indemnity Corporation' },
  { publicId: 4850, name: 'Capitol Specialty Insurance Corporation' },
  { publicId: 4851, name: 'Carolina Casualty Insurance Company' },
  { publicId: 4852, name: 'Central Mutual Insurance Company' },
  { publicId: 4853, name: 'Central States Indemnity Co of Omaha' },
  { publicId: 4854, name: 'Centurion Casualty Company' },
  { publicId: 4855, name: 'Charter Indemnity Company' },
  { publicId: 4856, name: 'Charter Oak Fire Insurance Company' },
  { publicId: 4857, name: 'Chicago Insurance Company' },
  { publicId: 4858, name: 'Chrysler Insurance Company' },
  { publicId: 4859, name: 'Chubb Custom Insurance Company' },
  { publicId: 4860, name: 'Chubb Indemnity Insurance Company' },
  { publicId: 4861, name: 'Chubb Insurance Company of New Jersey' },
  { publicId: 4862, name: 'Chubb Lloyds Insurance Company of Texas' },
  { publicId: 4863, name: 'Chubb National Insurance Company' },
  { publicId: 4864, name: 'Church Mutual Insurance Company' },
  { publicId: 4865, name: 'Allied World Specialty Insurance Company' },
  { publicId: 4866, name: 'Cincinnati Insurance Companies' },
  { publicId: 4867, name: 'Citation Insurance Company (MA)' },
  { publicId: 4868, name: 'Citizens Mutual Insurance Company' },
  { publicId: 4869, name: 'Civic Property and Casualty Company' },
  { publicId: 4870, name: 'Civil Service Employees Insurance Co' },
  { publicId: 4871, name: 'CMI Lloyds' },
  { publicId: 4872, name: 'Colonial American Casualty & Surety Co' },
  { publicId: 4873, name: 'Colonial County Mutual Insurance Company' },
  { publicId: 4874, name: 'Colorado Casualty Insurance Company' },
  { publicId: 4875, name: 'Columbia Casualty Company' },
  { publicId: 4876, name: 'Columbia Insurance Company' },
  { publicId: 4877, name: 'Columbia Mutual Insurance Company' },
  { publicId: 4878, name: 'Columbia National Insurance Company' },
  { publicId: 4879, name: 'Commerce and Industry Insurance Company' },
  { publicId: 4880, name: 'Commerce Insurance Company' },
  { publicId: 4881, name: 'Commonwealth Insurance Co of America' },
  { publicId: 4882, name: 'Concord General Mutual Insurance Company' },
  { publicId: 4883, name: 'Consolidated Insurance Company' },
  { publicId: 4884, name: 'Continental Divide Insurance Company' },
  { publicId: 4885, name: 'Continental Insurance Company' },
  { publicId: 4886, name: 'Continental Insurance Company of NJ' },
  { publicId: 4887, name: 'Continental Western Insurance Company' },
  { publicId: 4888, name: 'Cooperativa de Seguros Multiples de PR' },
  { publicId: 4889, name: 'COPIC Insurance Company' },
  { publicId: 4890, name: 'Cornhusker Casualty Company' },
  { publicId: 4891, name: 'COUNTRY Casualty Insurance Company' },
  { publicId: 4892, name: 'COUNTRY Mutual Insurance Company' },
  { publicId: 4893, name: 'COUNTRY Preferred Insurance Company' },
  { publicId: 4894, name: 'Crum & Forster Indemnity Company' },
  { publicId: 4895, name: 'Crum and Forster Insurance Company' },
  { publicId: 4896, name: 'Cumberland Mutual Fire Insurance Co' },
  { publicId: 4897, name: 'CUMIS Insurance Society Inc' },
  { publicId: 4898, name: 'Cypress Insurance Company (CA)' },
  { publicId: 4899, name: 'Dairyland County Mutual Insurance Company of TX' },
  { publicId: 4900, name: 'Dairyland Insurance Company' },
  { publicId: 4901, name: 'Dakota Fire Insurance Company' },
  { publicId: 4902, name: 'Danbury Insurance Company' },
  { publicId: 4903, name: 'Deerbrook Insurance Company' },
  { publicId: 4904, name: 'Dentists Insurance Company' },
  { publicId: 4905, name: 'Depositors Insurance Company' },
  { publicId: 4906, name: 'Diamond State Insurance Company' },
  { publicId: 4907, name: 'Discover Property & Casualty Ins Co' },
  { publicId: 4908, name: 'Discover Specialty Insurance Company' },
  { publicId: 4909, name: 'Donegal Mutual Insurance Company' },
  { publicId: 4910, name: 'Dorchester Mutual Insurance Company' },
  { publicId: 4911, name: 'Dorinco Reinsurance Company' },
  { publicId: 4912, name: 'Eagle West Insurance Company' },
  { publicId: 4913, name: 'EastGUARD Insurance Company' },
  { publicId: 4914, name: 'Economy Preferred Insurance Company' },
  { publicId: 4915, name: 'Economy Premier Assurance Company' },
  { publicId: 4916, name: 'EMC Property and Casualty Company' },
  { publicId: 4917, name: 'EMCASCO Insurance Company' },
  { publicId: 4918, name: 'Empire Fire and Marine Insurance Company' },
  { publicId: 4919, name: 'Empire Indemnity Insurance Company' },
  { publicId: 4920, name: 'Employers  Fire Insurance Company' },
  { publicId: 4921, name: 'Employers Insurance Company of Wausau' },
  { publicId: 4922, name: 'Employers Mutual Casualty Company' },
  { publicId: 4923, name: 'Employers Reassurance Corporation' },
  { publicId: 4924, name: 'Erie Insurance Company' },
  { publicId: 4925, name: 'Chartis Specialty Insurance Company' },
  { publicId: 4926, name: 'Erie Insurance Exchange' },
  { publicId: 4927, name: 'Erie Insurance Property & Casualty Company' },
  { publicId: 4928, name: 'Essex Insurance Company' },
  { publicId: 4929, name: 'Evanston Insurance Company' },
  { publicId: 4930, name: 'Everest Indemnity Insurance Company' },
  { publicId: 4931, name: 'Everest National Insurance Company' },
  { publicId: 4932, name: 'Everest Reinsurance Company' },
  { publicId: 4933, name: 'Exact Property and Casualty Company' },
  { publicId: 4934, name: 'Excelsior Insurance Company' },
  { publicId: 4935, name: 'Executive Risk Indemnity Inc.' },
  { publicId: 4936, name: 'Executive Risk Specialty Insurance Company' },
  { publicId: 4937, name: 'Explorer Insurance Company' },
  { publicId: 4938, name: 'Factory Mutual Insurance Company' },
  { publicId: 4939, name: 'Farm Bureau County Mutual Ins Co of TX' },
  { publicId: 4940, name: 'Farm Bureau Mutual Insurance Co of ID' },
  { publicId: 4941, name: 'Farm Bureau Town & Country Ins Co of MO' },
  { publicId: 4942, name: 'Farm Family Casualty Insurance Company' },
  { publicId: 4943, name: 'Farmers Alliance Mutual Insurance Company' },
  { publicId: 4944, name: 'Farmers Insurance of Columbus, Inc.' },
  { publicId: 4945, name: 'Farmers Insurance Company, Inc.' },
  { publicId: 4946, name: 'Farmers Insurance Company of Arizona' },
  { publicId: 4947, name: 'Farmers Insurance Company of Idaho' },
  { publicId: 4948, name: 'Farmers Insurance Company of Oregon' },
  { publicId: 4949, name: 'Farmers Insurance Company of Washington' },
  { publicId: 4950, name: 'Farmers Insurance Exchange' },
  { publicId: 4951, name: 'Farmers Mutual Hail Insurance Company of IA' },
  { publicId: 4952, name: 'Farmers Mutual Insurance Company of NE' },
  { publicId: 4953, name: 'Farmers New Century Insurance Company' },
  { publicId: 4954, name: 'Farmers Texas County Mutual Insurance Company' },
  { publicId: 4955, name: 'Farmington Casualty Company' },
  { publicId: 4956, name: 'Farmland Mutual Insurance Company' },
  { publicId: 4957, name: 'FCCI Insurance Company' },
  { publicId: 4958, name: 'Federal Insurance Company' },
  { publicId: 4959, name: 'Federated Mutual Insurance Company' },
  { publicId: 4960, name: 'Federated Service Insurance Company' },
  { publicId: 4961, name: 'Fidelity and Deposit Company of Maryland' },
  { publicId: 4962, name: 'Fidelity and Guaranty Insurance Company' },
  { publicId: 4963, name: 'Fidelity and Guaranty Ins Undrwtr, Inc' },
  { publicId: 4964, name: 'Fidelity Mohawk Insurance Company' },
  { publicId: 4965, name: 'Financial Indemnity Company' },
  { publicId: 4966, name: 'Fire Insurance Exchange' },
  { publicId: 4967, name: 'Firemans Fund County Mutual Ins Co' },
  { publicId: 4968, name: 'Firemans Fund Indemnity Corporation' },
  { publicId: 4969, name: 'Firemans Fund Insurance Co of HI, Inc' },
  { publicId: 4970, name: 'Firemans Fund Insurance Company of Ohio' },
  { publicId: 4971, name: 'Firemans Fund Insurance Company' },
  { publicId: 4972, name: 'Firemens Insurance Company of WA, D.C.' },
  { publicId: 4973, name: 'First American Title Insurance Company' },
  { publicId: 4974, name: 'First American Title & Trust Company' },
  { publicId: 4975, name: 'First Financial Insurance Company' },
  { publicId: 4976, name: 'First F & C Insurance of HI, Inc' },
  { publicId: 4977, name: 'First Indemnity Insurance of Hawaii, Inc.' },
  { publicId: 4978, name: 'First Insurance Company of Hawaii, Ltd.' },
  { publicId: 4979, name: 'First Liberty Insurance Corporation' },
  { publicId: 4980, name: 'First National Insurance Co of America' },
  { publicId: 4981, name: 'First of Hawaii Group' },
  { publicId: 4982, name: 'First Security Insurance of Hawaii, Inc.' },
  { publicId: 4983, name: 'First Specialty Insurance Corporation' },
  { publicId: 4984, name: 'Firstline National Insurance Company' },
  { publicId: 4985, name: 'Flagship City Insurance Company' },
  { publicId: 4986, name: 'Florida FB Casualty Ins Co' },
  { publicId: 4987, name: 'Florida Farm Bureau General Insurance Co' },
  { publicId: 4988, name: 'FMH Insurance Group' },
  { publicId: 4989, name: 'Chartis Specialty Insurance Company' },
  { publicId: 4990, name: 'Foremost P & C Ins Co' },
  { publicId: 4991, name: 'Cincinnati Casualty Company' },
  { publicId: 4992, name: 'Frankenmuth Mutual Insurance Company' },
  { publicId: 4993, name: 'Franklin Mutual Insurance Company' },
  { publicId: 4994, name: 'GEICO Casualty Company' },
  { publicId: 4995, name: 'GEICO General Insurance Company' },
  { publicId: 4996, name: 'GEICO Indemnity Company' },
  { publicId: 4997, name: 'Cincinnati Casualty Company' },
  { publicId: 4998, name: 'General Casualty Insurance Company' },
  { publicId: 4999, name: 'General Casualty Company of Wisconsin' },
  { publicId: 5000, name: 'General Insurance Company of America' },
  { publicId: 5001, name: 'General Reinsurance Corporation' },
  { publicId: 5002, name: 'General Star Indemnity Company' },
  { publicId: 5003, name: 'General Star National Insurance Co' },
  { publicId: 5004, name: 'Generali - U.S. Branch' },
  { publicId: 5005, name: 'Genesis Insurance Company' },
  { publicId: 5006, name: 'Georgia Casualty & Surety Company' },
  { publicId: 5007, name: 'GeoVera Insurance Company' },
  { publicId: 5008, name: 'Gerling America Insurance Company' },
  { publicId: 5009, name: 'Germania Farm Mutual Insurance Assn' },
  { publicId: 5010, name: 'Germania Fire & Casualty Company' },
  { publicId: 5011, name: 'Germania Insurance Company' },
  { publicId: 5012, name: 'Golden Eagle Insurance Corporation' },
  { publicId: 5013, name: 'Gotham Insurance Company' },
  { publicId: 5014, name: 'Government Employees Insurance Company' },
  { publicId: 5015, name: 'Grange Mutual Casualty Company' },
  { publicId: 5016, name: 'Granite Mutual Insurance Company' },
  { publicId: 5017, name: 'Granite State Insurance Company' },
  { publicId: 5018, name: 'Graphic Arts Mutual Insurance Company' },
  { publicId: 5019, name: 'Cincinnati Indemnity Company' },
  { publicId: 5020, name: 'Cincinnati Indemnity Company' },
  { publicId: 5021, name: 'Great Divide Insurance Company' },
  { publicId: 5022, name: 'Great Lakes Casualty Insurance Company' },
  { publicId: 5023, name: 'Great Northern Insurance Company' },
  { publicId: 5024, name: 'Great West Casualty Company' },
  { publicId: 5025, name: 'Greater New York Mutual Insurance Co' },
  { publicId: 5026, name: 'Green Mountain Insurance Company, Inc.' },
  { publicId: 5027, name: 'Greenwich Insurance Company' },
  { publicId: 5028, name: 'Grinnell Mutual Reinsurance Company' },
  { publicId: 5029, name: 'GuideOne America Insurance Company' },
  { publicId: 5030, name: 'GuideOne Elite Insurance Company' },
  { publicId: 5031, name: 'GuideOne Lloyds Insurance Company' },
  { publicId: 5032, name: 'GuideOne Mutual Insurance Company' },
  { publicId: 5033, name: 'GuideOne Property & Casualty Ins Co' },
  { publicId: 5034, name: 'GuideOne Specialty Mutual Insurance Co' },
  { publicId: 5035, name: 'Guilderland Reinsurance Company' },
  { publicId: 5036, name: 'Guilford Insurance Company' },
  { publicId: 5037, name: 'Gulf Underwriters Insurance Company' },
  { publicId: 5038, name: 'Hallmark Insurance Company' },
  { publicId: 5039, name: 'Hamilton Mutual Insurance Company' },
  { publicId: 5040, name: 'Harco National Insurance Company' },
  { publicId: 5041, name: 'Harford Mutual Insurance Company' },
  { publicId: 5042, name: 'Harleysville Insurance Company of NJ' },
  { publicId: 5044, name: 'Hartford Casualty Insurance Company' },
  { publicId: 5045, name: 'Hartford Fire Insurance Company' },
  { publicId: 5046, name: 'Hartford Insurance Company of Illinois' },
  { publicId: 5047, name: 'Hartford Insurance Company of MidW' },
  { publicId: 5048, name: 'Hartford Lloyds Insurance Company' },
  { publicId: 5049, name: 'Hartford Steam Boiler Inspec & Ins' },
  { publicId: 5050, name: 'Hartford Steam Boiler Inspec & Ins Co CT' },
  { publicId: 5051, name: 'Hartford Underwriters Insurance Company' },
  { publicId: 5052, name: 'Hastings Mutual Insurance Company' },
  { publicId: 5053, name: 'Hawkeye-Security Insurance Company' },
  { publicId: 5054, name: 'Hingham Mutual Fire Insurance Company' },
  { publicId: 5055, name: 'Holyoke Mutual Insurance Co in Salem' },
  { publicId: 5056, name: 'Home-Owners Insurance Company' },
  { publicId: 5057, name: 'Hoosier Insurance Company' },
  { publicId: 5058, name: 'Horace Mann Insurance Company' },
  { publicId: 5059, name: 'Horace Mann Lloyds' },
  { publicId: 5060, name: 'Houston Casualty Company' },
  { publicId: 5061, name: 'Hudson Insurance Company' },
  { publicId: 5062, name: 'ICI Mutual Insurance Company' },
  { publicId: 5063, name: 'Illinois EMCASCO Insurance Company' },
  { publicId: 5064, name: 'Illinois Farmers Insurance Company' },
  { publicId: 5065, name: 'Cincinnati Insurance Company' },
  { publicId: 5066, name: 'Illinois Union Insurance Company' },
  { publicId: 5067, name: 'IMT Insurance Company' },
  { publicId: 5068, name: 'Indemnity Insurance Company of N A' },
  { publicId: 5069, name: 'Independence Casualty & Surety Company' },
  { publicId: 5070, name: 'Indian Harbor Insurance Company' },
  { publicId: 5071, name: 'Indiana Farmers Mutual Insurance Company' },
  { publicId: 5072, name: 'Indiana Insurance Company' },
  { publicId: 5073, name: 'Infinity Insurance Company' },
  { publicId: 5074, name: 'Infinity Select Insurance Company' },
  { publicId: 5075, name: 'Inland Insurance Company' },
  { publicId: 5076, name: 'Insurance Company of Greater New York' },
  { publicId: 5077, name: 'Insurance Company of North America' },
  { publicId: 5078, name: 'Insurance Company of State of PA' },
  { publicId: 5079, name: 'Insurance Company of the West' },
  { publicId: 5080, name: 'Integon Casualty Insurance Company' },
  { publicId: 5081, name: 'Integon General Insurance Corporation' },
  { publicId: 5082, name: 'Integon Indemnity Corporation' },
  { publicId: 5083, name: 'Integon National Insurance Company' },
  { publicId: 5084, name: 'Integon Preferred Insurance Company' },
  { publicId: 5085, name: 'Interinsurance Exchange of Auto Club' },
  { publicId: 5086, name: 'Interstate Fire & Casualty Company' },
  { publicId: 5087, name: 'Island Insurance Company, Ltd.' },
  { publicId: 5088, name: 'Jefferson Insurance Company' },
  { publicId: 5089, name: 'Jewelers Mutual Insurance Company' },
  { publicId: 5090, name: 'Kansas Bankers Surety Company' },
  { publicId: 5091, name: 'Kentucky Farm Bureau Mutual Insurance Co' },
  { publicId: 5092, name: 'Key Risk Insurance Company' },
  { publicId: 5093, name: 'Keystone Insurance Company' },
  { publicId: 5094, name: 'Landmark American Insurance Company' },
  { publicId: 5095, name: 'Lawyers Mutual Insurance Company' },
  { publicId: 5096, name: 'Cincinnati Insurance Company' },
  { publicId: 5097, name: 'Clear Blue Insurance Company, Inc.' },
  { publicId: 5098, name: 'Continental Casualty Company' },
  { publicId: 5099, name: 'Liberty Mutual Insurance Companies' },
  { publicId: 5100, name: 'Continental Casualty Company' },
  { publicId: 5101, name: 'CopperPoint Casualty Insurance Company' },
  { publicId: 5102, name: 'Lightning Rod Mutual Insurance Company' },
  { publicId: 5103, name: 'Lititz Mutual Insurance Company' },
  { publicId: 5104, name: 'LM Insurance Corporation' },
  { publicId: 5105, name: 'Louisiana Farm Bureau Casualty Ins Co' },
  { publicId: 5106, name: 'Louisiana Medical Mutual Insurance Co' },
  { publicId: 5107, name: 'Lyndon Property Insurance Company' },
  { publicId: 5108, name: 'MAG Mutual Insurance Company' },
  { publicId: 5109, name: 'Main Street America Assurance Company' },
  { publicId: 5110, name: 'Markel Insurance Company' },
  { publicId: 5111, name: 'Maryland Casualty Company' },
  { publicId: 5112, name: 'Medical Assurance Company, Inc.' },
  { publicId: 5113, name: 'Medical Assurance Co of Mississippi' },
  { publicId: 5114, name: 'Medical Insurance Exchange of California' },
  { publicId: 5115, name: 'Medical Mutual Liability Ins Soc of MD' },
  { publicId: 5116, name: 'Medical Professional Mutual Insurance Co' },
  { publicId: 5117, name: 'Medical Protective Company' },
  { publicId: 5118, name: 'Medmarc Casualty Insurance Company' },
  { publicId: 5119, name: 'Medmarc Mutual Insurance Company' },
  { publicId: 5120, name: 'MEEMIC Insurance Company' },
  { publicId: 5121, name: 'MemberSelect Insurance Company' },
  { publicId: 5122, name: 'Mercury Casualty Company' },
  { publicId: 5123, name: 'Mercury Indemnity Company of Georgia' },
  { publicId: 5124, name: 'Mercury Insurance Company' },
  { publicId: 5125, name: 'Mercury Insurance Company of Georgia' },
  { publicId: 5126, name: 'Mercury Insurance Company of Illinois' },
  { publicId: 5127, name: 'Meridian Citizens Mutual Insurance Co' },
  { publicId: 5128, name: 'Meridian Security Insurance Company' },
  { publicId: 5129, name: 'Meritplan Insurance Company' },
  { publicId: 5130, name: 'Merrimack Mutual Fire Insurance Company' },
  { publicId: 5131, name: 'Metropolitan Casualty Insurance Company' },
  { publicId: 5132, name: 'Metropolitan Direct P & C Ins Co' },
  { publicId: 5133, name: 'Metropolitan General Insurance Company' },
  { publicId: 5134, name: 'Metropolitan Group Prop & Cas Ins Co' },
  { publicId: 5135, name: 'Metropolitan Lloyds Insurance Co TX' },
  { publicId: 5136, name: 'Metropolitan P & C Ins Co' },
  { publicId: 5137, name: 'MIC General Insurance Corporation' },
  { publicId: 5138, name: 'Erie Insurance Company of New York' },
  { publicId: 5139, name: 'Michigan Millers Mutual Insurance Co' },
  { publicId: 5140, name: 'Mid-Century Insurance Company' },
  { publicId: 5141, name: 'Mid-Century Insurance Company of Texas' },
  { publicId: 5142, name: 'Mid-Continent Casualty Company' },
  { publicId: 5143, name: 'Middlesex Insurance Company' },
  { publicId: 5144, name: 'Middlesex Mutual Assurance Company' },
  { publicId: 5145, name: 'Midwest Employers Casualty Company' },
  { publicId: 5146, name: 'Midwest Medical Insurance Company' },
  { publicId: 5147, name: 'Midwestern Indemnity Company' },
  { publicId: 5148, name: 'Milbank Insurance Company' },
  { publicId: 5149, name: 'Milwaukee Casualty Insurance Company' },
  { publicId: 5150, name: 'Minnesota Lawyers Mutual Insurance Co' },
  { publicId: 5151, name: 'Missouri Hospital Plan' },
  { publicId: 5152, name: 'Monterey Insurance Company' },
  { publicId: 5153, name: 'Montgomery Mutual Insurance Company' },
  { publicId: 5154, name: 'Motorists Mutual Insurance Company' },
  { publicId: 5155, name: 'Mt. Hawley Insurance Company' },
  { publicId: 5156, name: 'Mount Vernon Fire Insurance Company' },
  { publicId: 5157, name: 'Mountain Laurel Assurance Company' },
  { publicId: 5158, name: 'Mountain West FB Mutual Ins Co' },
  { publicId: 5159, name: 'Mutual Assurance Society of Virginia' },
  { publicId: 5160, name: 'Mutual of Enumclaw Insurance Company' },
  { publicId: 5161, name: 'Mutual Insurance Company of Arizona' },
  { publicId: 5162, name: 'Mutual Protection Trust' },
  { publicId: 5163, name: 'National Casualty Company' },
  { publicId: 5164, name: 'National Farmers Un P & C Co' },
  { publicId: 5165, name: 'National Fire Insurance Company of Hartford' },
  { publicId: 5166, name: 'National Fire & Marine Insurance Company' },
  { publicId: 5167, name: 'National General Assurance Company' },
  { publicId: 5168, name: 'National General Insurance Company' },
  { publicId: 5169, name: 'National Indemnity Company' },
  { publicId: 5170, name: 'National Indemnity Company of Mid-America' },
  { publicId: 5171, name: 'National Indemnity Company of the South' },
  { publicId: 5172, name: 'National Liability & Fire Insurance Company' },
  { publicId: 5173, name: 'National Surety Corporation' },
  { publicId: 5174, name: 'National Trust Insurance Company' },
  { publicId: 5175, name: 'Nationwide Agribusiness Insurance Company' },
  { publicId: 5176, name: 'Nationwide Assurance Company' },
  { publicId: 5177, name: 'Nationwide General Insurance Company' },
  { publicId: 5178, name: 'Nationwide Insurance Company of America' },
  { publicId: 5179, name: 'Nationwide Lloyds' },
  { publicId: 5180, name: 'Nationwide Mutual Fire Insurance Company' },
  { publicId: 5181, name: 'Nationwide Mutual Insurance Company' },
  { publicId: 5182, name: 'Nationwide Property and Casualty Insurance Company' },
  { publicId: 5183, name: 'Nautilus Insurance Company' },
  { publicId: 5184, name: 'Navigators Insurance Company' },
  { publicId: 5185, name: 'NCMIC Insurance Company' },
  { publicId: 5186, name: 'Neighborhood Spirit P & C Co' },
  { publicId: 5187, name: 'Netherlands Insurance Company' },
  { publicId: 5188, name: 'New Hampshire Indemnity Company, Inc.' },
  { publicId: 5189, name: 'New Hampshire Insurance Company' },
  { publicId: 5190, name: 'New Jersey Manufacturers Insurance Company' },
  { publicId: 5191, name: 'New Jersey Re-Insurance Company' },
  { publicId: 5192, name: 'New South Insurance Company' },
  { publicId: 5193, name: 'New York Central Mutual Fire Insurance Company' },
  { publicId: 5194, name: 'New York Marine And General Insurance Company' },
  { publicId: 5195, name: 'Newport Insurance Company' },
  { publicId: 5196, name: 'Nonprofits  Insurance Alliance Of CA' },
  { publicId: 5197, name: 'NORCAL Mutual Insurance Company' },
  { publicId: 5198, name: 'Norfolk and Dedham Mutual Fire Insurance Company' },
  { publicId: 5199, name: 'NorGUARD Insurance Company' },
  { publicId: 5200, name: 'North American Capacity Insurance Co' },
  { publicId: 5201, name: 'North American Elite Insurance Company' },
  { publicId: 5202, name: 'North American Specialty Insurance Company' },
  { publicId: 5203, name: 'North Carolina FB Mutual Ins Co' },
  { publicId: 5204, name: 'North Pacific Insurance Company' },
  { publicId: 5205, name: 'North River Insurance Company' },
  { publicId: 5206, name: 'North Star Mutual Insurance Company' },
  { publicId: 5207, name: 'Northbrook Indemnity Company' },
  { publicId: 5208, name: 'Northern Assurance Company of America' },
  { publicId: 5209, name: 'Northern Insurance Company of New York' },
  { publicId: 5210, name: 'Northern Security Insurance Company, Inc.' },
  { publicId: 5211, name: 'Northfield Insurance Company' },
  { publicId: 5212, name: 'Northland Casualty Company' },
  { publicId: 5213, name: 'Northland Insurance Company' },
  { publicId: 5214, name: 'Northwestern Pacific Indemnity Company' },
  { publicId: 5215, name: 'Nuclear Electric Insurance Limited' },
  { publicId: 5216, name: 'Nutmeg Insurance Company' },
  { publicId: 5217, name: 'Oak Brook County Mutual Insurance Company' },
  { publicId: 5218, name: 'Oak River Insurance Company' },
  { publicId: 5219, name: 'Odyssey America Reinsurance Corporation' },
  { publicId: 5220, name: 'Ohio Casualty Insurance Company' },
  { publicId: 5221, name: 'Ohio Farmers Insurance Company' },
  { publicId: 5222, name: 'Ohio Mutual Insurance Company' },
  { publicId: 5223, name: 'Ohio Security Insurance Company' },
  { publicId: 5224, name: 'Oklahoma Surety Company' },
  { publicId: 5225, name: 'Old Dominion Insurance Company' },
  { publicId: 5226, name: 'Old Republic Insurance Company' },
  { publicId: 5227, name: 'Old Republic Lloyds of Texas' },
  { publicId: 5228, name: 'Old Republic National Title Ins Co' },
  { publicId: 5229, name: 'Oregon Automobile Insurance Company' },
  { publicId: 5230, name: 'Owners Insurance Company' },
  { publicId: 5231, name: 'Pacific Employers Insurance Company' },
  { publicId: 5232, name: 'Pacific Indemnity Company' },
  { publicId: 5233, name: 'Pacific Insurance Company' },
  { publicId: 5234, name: 'Pacific Insurance Company, Ltd.' },
  { publicId: 5235, name: 'Pan American Insurance Company' },
  { publicId: 5236, name: 'Partner Reinsurance Company of the U.S.' },
  { publicId: 5237, name: 'PartnerRe Insurance Company of New York' },
  { publicId: 5238, name: 'Patriot General Insurance Company' },
  { publicId: 5239, name: 'Patrons Mutual Insurance Company of CT' },
  { publicId: 5240, name: 'Patrons Oxford Insurance Company' },
  { publicId: 5241, name: 'Pawtucket Mutual Insurance Company' },
  { publicId: 5242, name: 'Peak Property and Casualty Insurance Corporation' },
  { publicId: 5243, name: 'Peerless Insurance Company' },
  { publicId: 5244, name: 'Pekin Insurance Company' },
  { publicId: 5245, name: 'PEMCO Mutual Insurance Company' },
  { publicId: 5246, name: 'Penn-America Insurance Company' },
  { publicId: 5247, name: 'Penn-Star Insurance Company' },
  { publicId: 5248, name: 'Pennland Insurance Company' },
  { publicId: 5249, name: 'Pennsylvania Lumbermens Mutual Insurance Company' },
  { publicId: 5250, name: 'Pennsylvania Medical Society Liability Insurance Company' },
  { publicId: 5251, name: 'Peoples Service Insurance Company' },
  { publicId: 5252, name: 'Philadelphia Contributionship Insurance Company' },
  {
    name: 'Philadelphia Contributionship for the Insurance of Houses from Loss by Fire',
    publicId: 5253,
  },
  { publicId: 5254, name: 'Philadelphia Indemnity Insurance Company' },
  { publicId: 5255, name: 'Philadelphia Insurance Companies' },
  { publicId: 5256, name: 'Philadelphia Insurance Company' },
  { publicId: 5257, name: 'Phoenix Insurance Company' },
  { publicId: 5258, name: 'Physicians Insurance Company of Wisconsin, Inc.' },
  { publicId: 5259, name: 'Physicians Insurance Exchange' },
  { publicId: 5260, name: 'Pioneer Insurance Company (NY)' },
  { publicId: 5261, name: 'Pioneer Insurance Company (OH)' },
  { publicId: 5262, name: 'Pioneer State Mutual Insurance Company' },
  { publicId: 5263, name: 'Plymouth Rock Assurance Corporation' },
  { publicId: 5264, name: 'Port Lawrence Title & Trust Company' },
  { publicId: 5265, name: 'Preferred Employers Insurance Company' },
  { publicId: 5266, name: 'Preferred Mutual Insurance Company' },
  { publicId: 5267, name: 'Preferred Risk Insurance Company' },
  { publicId: 5268, name: 'Princeton Excess & Surplus Lines Insurance Company' },
  { publicId: 5269, name: 'Professional Underwriters Liability Insurance Company' },
  { publicId: 5270, name: 'Progressive American Insurance Company' },
  { publicId: 5271, name: 'Progressive Bayside Insurance Company' },
  { publicId: 5272, name: 'Progressive Casualty Insurance Company' },
  { publicId: 5273, name: 'Progressive Classic Insurance Company' },
  { publicId: 5274, name: 'Progressive County Mutual Insurance Company' },
  { publicId: 5275, name: 'Progressive Express Insurance Company' },
  { publicId: 5276, name: 'Progressive Gulf Insurance Company' },
  { publicId: 5277, name: 'Progressive Hawaii Insurance Corp' },
  { publicId: 5278, name: 'Progressive Marathon Insurance Company' },
  { publicId: 5279, name: 'Progressive Michigan Insurance Company' },
  { publicId: 5280, name: 'Progressive Mountain Insurance Company' },
  { publicId: 5281, name: 'Progressive Northern Insurance Company' },
  { publicId: 5282, name: 'Progressive Northwestern Insurance Company' },
  { publicId: 5283, name: 'Progressive Paloverde Insurance Company' },
  { publicId: 5284, name: 'Progressive Preferred Insurance Company' },
  { publicId: 5285, name: 'Progressive Premier Insurance Company of Illinois' },
  { publicId: 5286, name: 'Progressive Security Insurance Company' },
  { publicId: 5287, name: 'Progressive Southeastern Insurance Company' },
  { publicId: 5288, name: 'Progressive Specialty Insurance Company' },
  { publicId: 5289, name: 'Progressive Universal Insurance Company' },
  { publicId: 5290, name: 'Progressive West Insurance Company' },
  { publicId: 5291, name: 'ProNational Casualty Company' },
  { publicId: 5292, name: 'ProNational Insurance Company' },
  { publicId: 5293, name: 'Property and Casualty Ins Co of Hartford' },
  { publicId: 5294, name: 'Property-Owners Insurance Company' },
  { publicId: 5295, name: 'ProSelect Insurance Company' },
  { publicId: 5296, name: 'Protective Insurance Company' },
  { publicId: 5297, name: 'Providence Mutual Fire Insurance Company' },
  { publicId: 5298, name: 'Providence Plantations Insurance Company' },
  { publicId: 5299, name: 'Prudential Commercial Insurance Company' },
  { publicId: 5300, name: 'Prudential General Insurance Company' },
  { publicId: 5301, name: 'Prudential Property and Casualty Insurance Company' },
  { publicId: 5302, name: 'Puerto Rican-American Insurance Company' },
  { publicId: 5303, name: 'Putnam Reinsurance Company' },
  { publicId: 5304, name: 'Quadrant Indemnity Company' },
  { publicId: 5305, name: 'Quincy Mutual Fire Insurance Company' },
  { publicId: 5306, name: 'Rampart Insurance Company' },
  { publicId: 5307, name: 'Redwood Fire and Casualty Insurance Co' },
  { publicId: 5308, name: 'Regal Insurance Company' },
  { publicId: 5309, name: 'Regent Insurance Company' },
  { publicId: 5310, name: 'Republic Fire & Casualty Insurance Company' },
  { publicId: 5311, name: 'Republic-Franklin Insurance Company' },
  { publicId: 5312, name: 'Republic Indemnity Company of America' },
  { publicId: 5313, name: 'Republic Indemnity Company of California' },
  { publicId: 5314, name: 'Republic Lloyds' },
  { publicId: 5315, name: 'Republic Underwriters Insurance Company' },
  { publicId: 5316, name: 'Republic-Vanguard Insurance Company' },
  { publicId: 5317, name: 'Risk Capital Reinsurance Company' },
  { publicId: 5318, name: 'RLI Insurance Company' },
  { publicId: 5319, name: 'RML Insurance Company' },
  { publicId: 5320, name: 'Rock River Insurance Company' },
  { publicId: 5321, name: 'SAFECO Insurance Company of America' },
  { publicId: 5322, name: 'SAFECO Insurance Company of Illinois' },
  { publicId: 5323, name: 'SAFECO Lloyds Insurance Company' },
  { publicId: 5324, name: 'SAFECO National Insurance Company' },
  { publicId: 5325, name: 'SAFECO Surplus Lines Insurance Company' },
  { publicId: 5326, name: 'Safeguard Insurance Company' },
  { publicId: 5327, name: 'Safety Indemnity Insurance Company' },
  { publicId: 5328, name: 'Safety Insurance Company' },
  { publicId: 5329, name: 'Safety National Casualty Corporation' },
  { publicId: 5330, name: 'Safeway Insurance Company' },
  { publicId: 5331, name: 'Sagamore Insurance Company' },
  { publicId: 5332, name: 'St. Paul Fire and Casualty Insurance Company' },
  { publicId: 5333, name: 'St. Paul Fire and Marine Insurance Company' },
  { publicId: 5334, name: 'St. Paul Guardian Insurance Company' },
  { publicId: 5335, name: 'St. Paul Indemnity Insurance Company' },
  { publicId: 5336, name: 'St. Paul Insurance Company of Illinois' },
  { publicId: 5337, name: 'St. Paul Insurance Company of North Dakota' },
  { publicId: 5338, name: 'St. Paul Medical Liability Insurance Company' },
  { publicId: 5339, name: 'St. Paul Mercury Insurance Company' },
  { publicId: 5340, name: 'St. Paul Property and Casualty Insurance Company' },
  { publicId: 5341, name: 'St. Paul Surplus Lines Insurance Company' },
  { publicId: 5342, name: 'Samsung Fire & Marine Insurance Company, Ltd.' },
  { publicId: 5343, name: 'FMI Insurance Company' },
  { publicId: 5344, name: 'Scottsdale Insurance Company' },
  { publicId: 5345, name: 'Scottsdale Surplus Lines Insurance Company' },
  { publicId: 5346, name: 'SECURA Insurance, A Mutual Company' },
  { publicId: 5347, name: 'SECURA Supreme Insurance Company' },
  { publicId: 5348, name: 'Security Insurance Company of Hartford' },
  { publicId: 5349, name: 'Security National Insurance Company (TX)' },
  { publicId: 5350, name: 'Select Insurance Company' },
  { publicId: 5351, name: 'Selective Insurance Company of America' },
  { publicId: 5352, name: 'Selective Insurance Company of South Carolina' },
  { publicId: 5353, name: 'Selective Insurance Company of the Southeast' },
  { publicId: 5354, name: 'Selective Insurance Company of New York' },
  { publicId: 5355, name: 'Selective Insurance Group, Inc' },
  { publicId: 5356, name: 'Selective Way Insurance Company' },
  { publicId: 5357, name: 'Sentinel Insurance Company, Ltd.' },
  { publicId: 5358, name: 'Sentry Casualty Company' },
  { publicId: 5359, name: 'Sentry Insurance a Mutual Company' },
  { publicId: 5360, name: 'Sentry Lloyds of Texas' },
  { publicId: 5361, name: 'Sentry Select Insurance Company' },
  { publicId: 5362, name: 'Service General Insurance Company' },
  { publicId: 5363, name: 'Seven Hills Insurance Company' },
  { publicId: 5364, name: 'Shelter General Insurance Company' },
  { publicId: 5365, name: 'Shelter Mutual Insurance Company' },
  { publicId: 5366, name: 'Shelter Reinsurance Company' },
  { publicId: 5367, name: 'Signet Star Reinsurance Company' },
  { publicId: 5368, name: 'Silver Oak Casualty, Inc.' },
  { publicId: 5369, name: 'Society Insurance, A Mutual Company' },
  { publicId: 5370, name: 'Sonnenberg Mutual Insurance Company' },
  { publicId: 5371, name: 'Sorema North America Reinsurance Company' },
  { publicId: 5372, name: 'Southern County Mutual Insurance Company' },
  { publicId: 5373, name: 'Southern Farm Bureau Casualty Insurance Company' },
  { publicId: 5374, name: 'Southern Guaranty Insurance Company' },
  { publicId: 5375, name: 'Southern Insurance Company' },
  { publicId: 5376, name: 'Southern Insurance Company of Virginia' },
  { publicId: 5377, name: 'Southern-Owners Insurance Company' },
  { publicId: 5378, name: 'Southern Underwriters Insurance Company' },
  { publicId: 5379, name: 'Southern Vanguard Insurance Company' },
  { publicId: 5380, name: 'Standard Fire Insurance Company' },
  { publicId: 5381, name: 'Standard Guaranty Insurance Company' },
  { publicId: 5382, name: 'StarNet Insurance Company' },
  { publicId: 5383, name: 'Starr Indemnity & Liability Company' },
  { publicId: 5384, name: 'State Auto Insurance Companies' },
  { publicId: 5385, name: 'State Auto Property and Casualty Insurance Company' },
  { publicId: 5386, name: 'State Automobile Mutual Insurance Company' },
  { publicId: 5387, name: 'State Farm County Mutual Insurance Company of Texas' },
  { publicId: 5388, name: 'State Farm Fire and Casualty Company' },
  { publicId: 5389, name: 'State Farm Mutual Automobile Insurance Company' },
  { publicId: 5390, name: 'State Mutual Insurance Company (ME)' },
  { publicId: 5391, name: 'State Volunteer Mutual Insurance Company' },
  { publicId: 5392, name: 'Steadfast Insurance Company' },
  { publicId: 5393, name: 'Stratford Insurance Company' },
  { publicId: 5394, name: 'Sunapee Mutual Fire Insurance Company' },
  { publicId: 5395, name: 'Surety Bonding Company of America' },
  { publicId: 5396, name: 'Swiss Reinsurance America Corporation' },
  { publicId: 5397, name: 'Sydney Reinsurance Corporation' },
  { publicId: 5398, name: 'Teachers Insurance Company' },
  { publicId: 5399, name: 'Tennessee Farmers Assurance Company' },
  { publicId: 5400, name: 'Tennessee Farmers Mutual Insurance Company' },
  { publicId: 5401, name: 'Texas Farm Bureau Mutual Insurance Company' },
  { publicId: 5402, name: 'Texas Farm Bureau Underwriters-A Reciprocal' },
  { publicId: 5403, name: 'Texas Farmers Insurance Company' },
  { publicId: 5404, name: 'Texas Medical Liability Trust' },
  { publicId: 5405, name: 'Texas Pacific Indemnity Company' },
  { publicId: 5406, name: 'Titan Indemnity Company' },
  { publicId: 5407, name: 'Titan Insurance Company' },
  { publicId: 5408, name: 'Toa Reinsurance Company of America' },
  { publicId: 5409, name: 'Tradewind Insurance Company, Ltd.' },
  { publicId: 5410, name: 'Trafalgar Insurance Company' },
  { publicId: 5411, name: 'Trans Pacific Insurance Company' },
  { publicId: 5412, name: 'Transatlantic Reinsurance Company' },
  { publicId: 5413, name: 'Transnational Insurance Company' },
  { publicId: 5414, name: 'Transportation Insurance Company' },
  { publicId: 5415, name: 'TravCo Insurance Company' },
  { publicId: 5416, name: 'Foremost Signature Insurance Company' },
  { publicId: 5417, name: 'Gemini Insurance Company' },
  { publicId: 5418, name: 'Germantown Mutual Insurance Company' },
  { publicId: 5419, name: 'Grange Insurance Company of Michigan' },
  { publicId: 5420, name: 'Great American Alliance Insurance Co' },
  { publicId: 5421, name: 'Tri-State Insurance Company' },
  { publicId: 5422, name: 'Tri-State Insurance Company of Minnesota' },
  { publicId: 5423, name: 'Trinity Lloyd s Insurance Company' },
  { publicId: 5424, name: 'Trinity Universal Insurance Company' },
  { publicId: 5425, name: 'Triton Insurance Company' },
  { publicId: 5426, name: 'Truck Insurance Exchange' },
  { publicId: 5427, name: 'Trumbull Insurance Company' },
  { publicId: 5428, name: 'Trustgard Insurance Company' },
  { publicId: 5429, name: 'Tudor Insurance Company' },
  { publicId: 5430, name: '21st Century Casualty Company' },
  { publicId: 5431, name: 'Twin City Fire Insurance Company' },
  { publicId: 5432, name: 'U.S. AEGIS Energy Insurance Company' },
  { publicId: 5433, name: 'U.S. Specialty Insurance Company' },
  { publicId: 5434, name: 'U.S. Underwriters Insurance Company' },
  { publicId: 5435, name: 'UFB Casualty Insurance Company' },
  { publicId: 5436, name: 'Underwriter for the Professions Insurance Company' },
  { publicId: 5437, name: 'Underwriters Insurance Company' },
  { publicId: 5438, name: 'Underwriters Reinsurance Company' },
  { publicId: 5439, name: 'Unigard Indemnity Company' },
  { publicId: 5440, name: 'Unigard Insurance Company' },
  { publicId: 5441, name: 'Union Automobile Indemnity Company' },
  { publicId: 5442, name: 'Union Insurance Company' },
  { publicId: 5443, name: 'Union Insurance Company of Providence' },
  { publicId: 5444, name: 'Union National Fire Insurance Company' },
  { publicId: 5445, name: 'Union Standard Insurance Company' },
  { publicId: 5446, name: 'Union Standard Lloyds' },
  { publicId: 5447, name: 'Unisun Insurance Company' },
  { publicId: 5448, name: 'United Educators Insurance Risk Retention Group, Inc.' },
  { publicId: 5449, name: 'United Farm Family Insurance Company' },
  { publicId: 5450, name: 'United Farm Family Mutual Insurance Co' },
  { publicId: 5451, name: 'United Financial Casualty Company' },
  { publicId: 5452, name: 'United Fire & Casualty Company' },
  { publicId: 5453, name: 'United National Insurance Company' },
  { publicId: 5454, name: 'United Ohio Insurance Company' },
  { publicId: 5455, name: 'United Services Automobile Association' },
  { publicId: 5456, name: 'United States Fidelity and Guaranty Company' },
  { publicId: 5457, name: 'United States Fire Insurance Company' },
  { publicId: 5458, name: 'Great American Alliance Insurance Co' },
  { publicId: 5459, name: 'Universal Insurance Company (PR)' },
  { publicId: 5460, name: 'Universal Surety Company' },
  { publicId: 5461, name: 'Universal Underwriters Insurance Company' },
  { publicId: 5462, name: 'Universal Underwriters of Texas Insurance Company' },
  { publicId: 5463, name: 'USAA Casualty Insurance Company' },
  { publicId: 5464, name: 'USAA County Mutual Insurance Company' },
  { publicId: 5465, name: 'USAA General Indemnity Company' },
  { publicId: 5466, name: 'USF&G Business Insurance Company' },
  { publicId: 5467, name: 'USF&G Family Insurance Company' },
  { publicId: 5468, name: 'USF&G Founder s Insurance Company' },
  { publicId: 5469, name: 'USF&G Insurance Company of Mississippi' },
  { publicId: 5470, name: 'USF&G Insurance Company of Wisconsin' },
  { publicId: 5471, name: 'USF&G Pacific Insurance Company' },
  { publicId: 5472, name: 'USF&G Small Business Insurance Company' },
  { publicId: 5473, name: 'USF&G Specialty Insurance Company' },
  { publicId: 5474, name: 'USF&G West Insurance Company' },
  { publicId: 5475, name: 'Utica Mutual Insurance Company' },
  { publicId: 5476, name: 'Utica National Assurance Company' },
  { publicId: 5477, name: 'Utica National Insurance Company of Texas' },
  { publicId: 5478, name: 'Utica Specialty Risk Insurance Company' },
  { publicId: 5479, name: 'Valiant Insurance Company' },
  { publicId: 5480, name: 'Valley Forge Insurance Company' },
  { publicId: 5481, name: 'Valley Insurance Company' },
  { publicId: 5482, name: 'Valley Property & Casualty Insurance Company' },
  { publicId: 5483, name: 'Venture Insurance Company' },
  { publicId: 5484, name: 'Vermont Accident Insurance Company, Inc.' },
  { publicId: 5485, name: 'Vermont Mutual Insurance Company' },
  { publicId: 5486, name: 'Vigilant Insurance Company' },
  { publicId: 5487, name: 'Viking Insurance Company of Wisconsin' },
  { publicId: 5488, name: 'Vintage Insurance Company' },
  { publicId: 5489, name: 'Virginia Insurance Reciprocal' },
  { publicId: 5490, name: 'Virginia Surety Company, Inc.' },
  { publicId: 5491, name: 'Walle Mutual Insurance Company' },
  { publicId: 5492, name: 'Warner Insurance Company' },
  { publicId: 5493, name: 'Washington International Insurance Company' },
  { publicId: 5494, name: 'Wausau Business Insurance Company' },
  { publicId: 5495, name: 'Wausau General Insurance Company' },
  { publicId: 5496, name: 'Wausau Underwriters Insurance Company' },
  { publicId: 5497, name: 'Wesco-Financial Insurance Company' },
  { publicId: 5498, name: 'West American Insurance Company' },
  { publicId: 5499, name: 'West Bend Mutual Insurance Company' },
  { publicId: 5500, name: 'West Newbury Mutual Fire Insurance Company' },
  { publicId: 5501, name: 'Westchester Fire Insurance Company' },
  { publicId: 5502, name: 'Westchester Surplus Lines Insurance Company' },
  { publicId: 5503, name: 'Western Agricultural Insurance Company' },
  { publicId: 5504, name: 'Western Community Insurance Company' },
  { publicId: 5505, name: 'Great American Assurance Company' },
  { publicId: 5506, name: 'Western National Assurance Company' },
  { publicId: 5507, name: 'Western National Mutual Insurance Company' },
  { publicId: 5508, name: 'Western Reserve Mutual Casualty Company' },
  { publicId: 5509, name: 'Western Surety Company' },
  { publicId: 5510, name: 'Western World Insurance Company' },
  { publicId: 5511, name: 'Westfield Insurance Company' },
  { publicId: 5512, name: 'Westfield National Insurance Company' },
  { publicId: 5513, name: 'Westport Insurance Corporation' },
  { publicId: 5514, name: 'Windsor Insurance Company' },
  { publicId: 5515, name: 'Workers Compensation Fund of Utah' },
  { publicId: 5516, name: 'Worldwide Casualty Insurance Company' },
  { publicId: 5517, name: 'Worldwide Direct Auto Insurance Company' },
  { publicId: 5518, name: 'Worldwide Insurance Company' },
  { publicId: 5519, name: 'XL Insurance Company of New York, Inc.' },
  { publicId: 5520, name: 'Yasuda Fire & Marine Insurance Company of America' },
  { publicId: 5521, name: 'Yosemite Insurance Company' },
  { publicId: 5522, name: 'Zenith Insurance Company' },
  { publicId: 5523, name: 'ZNAT Insurance Company' },
  { publicId: 5524, name: 'Zurich American Insurance Company of Illinois' },
  { publicId: 5525, name: 'Zurich American Insurance Company' },
  { publicId: 5526, name: 'Zurich Reinsurance (North America) Inc.' },
  { publicId: 5527, name: 'Zurich U.S.' },
  { publicId: 5528, name: 'Preferred National Insurance Company' },
  { publicId: 5529, name: 'Pacific Specialty Insurance Company' },
  { publicId: 5530, name: 'Colony Insurance Company' },
  { publicId: 5531, name: 'Century-National Insurance Company' },
  { publicId: 5532, name: 'Monroe Guaranty Insurance Company' },
  { publicId: 5533, name: 'New Mexico Mutual Casualty Company' },
  { publicId: 5534, name: 'Seneca Insurance Company Inc' },
  { publicId: 5535, name: 'QBE Insurance Corporation (Domestic)' },
  { publicId: 5536, name: 'Acuity A Mutual Insurance Company' },
  { publicId: 5537, name: 'Rockwood Casualty Insurance Company' },
  { publicId: 5539, name: 'XL Specialty Insurance Company' },
  { publicId: 5540, name: 'State National Insurance Company, Inc.' },
  { publicId: 5541, name: 'OneBeacon America Insurance Company' },
  { publicId: 5542, name: 'Sirius America Insurance Company' },
  { publicId: 5543, name: 'Arch Specialty Insurance Company' },
  { publicId: 5544, name: 'Colony National Insurance Co' },
  { publicId: 5545, name: 'Penn National Security Insurance Company' },
  { publicId: 5546, name: 'Century Surety Company' },
  { publicId: 5547, name: 'Massachusetts Employers Insurance Co' },
  { publicId: 5548, name: 'Service Lloyds Insurance Company' },
  { publicId: 5549, name: 'Maxum Indemnity Company' },
  { publicId: 5550, name: 'Markel International Insurance Company Ltd' },
  { publicId: 5551, name: 'Atlantic Charter Insurance Company' },
  { publicId: 5552, name: 'Stonington Insurance Company' },
  { publicId: 5553, name: 'Sompo Japan Insurance Company of America' },
  { publicId: 5554, name: 'Lafayette Insurance Company' },
  { publicId: 5555, name: 'Pennsylvania National Mutual Cas Ins Co' },
  { publicId: 5556, name: 'Employers Insurance Company of Nevada' },
  { publicId: 5557, name: 'Citizens Insurance Company of America' },
  { publicId: 5558, name: 'Citizens Insurance Company of Illinois' },
  { publicId: 5559, name: 'Citizens Insurance Company of Ohio' },
  { publicId: 5560, name: 'Citizens Insurance Company of MidW' },
  { publicId: 5561, name: 'Hanover American Insurance Company' },
  { publicId: 5562, name: 'Hanover Insurance Company' },
  { publicId: 5563, name: 'Hanover Lloyds Insurance Company' },
  { publicId: 5564, name: 'Massachusetts Bay Insurance Company' },
  { publicId: 5565, name: 'American Reliable Insurance Company' },
  { publicId: 5566, name: 'Oregon Mutual Insurance Company' },
  { publicId: 5567, name: 'Western Protectors Insurance Company' },
  { publicId: 5568, name: 'Nevada Capital Insurance Company' },
  { publicId: 5569, name: 'Atlantic Specialty Insurance Company' },
  { publicId: 5570, name: 'Integrity Mutual Insurance Company' },
  { publicId: 5571, name: 'Aspen Specialty Insurance Company' },
  { publicId: 5572, name: 'James River Insurance Company' },
  { publicId: 5573, name: 'First Mercury Insurance Company' },
  { publicId: 5574, name: 'Topa Insurance Company' },
  { publicId: 5575, name: 'FFVA Mutual Insurance Co' },
  { publicId: 5576, name: 'Virginia Mutual Insurance Company' },
  { publicId: 5577, name: 'Housing Authority RRG,Inc' },
  { publicId: 5578, name: 'Manufacturers Alliance Insurance Company' },
  { publicId: 5579, name: 'Pennsylvania Manufacturers Assoc Ins Co' },
  { publicId: 5580, name: 'Pennsylvania Manufacturers Indemnity Co' },
  { publicId: 5581, name: 'Lancer Insurance Company' },
  { publicId: 5582, name: 'National Interstate Insurance Company' },
  { publicId: 5583, name: 'Employers Compensation Insurance Company' },
  { publicId: 5584, name: 'New Jersey Casualty Insurance Company' },
  { publicId: 5585, name: 'Admiral Indemnity Company' },
  { publicId: 5586, name: 'Tower Insurance Company of New York' },
  { publicId: 5587, name: 'Franklin Insurance Company' },
  { publicId: 5588, name: 'Mercer Insurance Company' },
  { publicId: 5589, name: 'Mercer Insurance Co of NJ, Inc.' },
  { publicId: 5590, name: 'Great American Assurance Company' },
  { publicId: 5591, name: 'Vanliner Insurance Company' },
  { publicId: 5592, name: 'Highmark Casualty Insurance Company' },
  { publicId: 5593, name: 'National Specialty Insurance Company' },
  { publicId: 5594, name: 'Sequoia Insurance Company' },
  { publicId: 5595, name: 'Union Mutual Fire Insurance Company' },
  { publicId: 5596, name: 'Mitsui Sumitomo Insurance USA Inc' },
  { publicId: 5597, name: 'Mitsui Sumitomo Insurance Co Of America' },
  { publicId: 5598, name: 'Acceptance Casualty Insurance Company' },
  { publicId: 5599, name: 'Acceptance Indemnity Insurance Company' },
  { publicId: 5600, name: 'Occidental F & C Co of NC' },
  { publicId: 5601, name: 'Wilshire Insurance Company' },
  { publicId: 5602, name: 'Kentucky Associated Gen Cont Self Ins Fd' },
  { publicId: 5603, name: 'American Interstate Ins Company of Texas' },
  { publicId: 5604, name: 'Foundation Reserve Insurance Co, Inc' },
  { publicId: 5605, name: 'NOVA Casualty Company' },
  { publicId: 5606, name: 'American Safety Indemnity Company' },
  { publicId: 5607, name: 'Progressive Commercial Auto Group' },
  { publicId: 5608, name: 'Great American E&S Insurance Company' },
  { publicId: 5609, name: 'United Fire Lloyds' },
  { publicId: 5610, name: 'Quanta Indemnity Company' },
  { publicId: 5611, name: 'Quanta Specialty Lines Insurance Co' },
  { publicId: 5612, name: 'Hudson Specialty Insurance Company' },
  { publicId: 5613, name: 'Badger Mutual Insurance Company' },
  { publicId: 5614, name: 'Lackawanna American Insurance Company' },
  { publicId: 5615, name: 'Lackawanna Casualty Company' },
  { publicId: 5616, name: 'Lackawanna National Insurance Company' },
  { publicId: 5617, name: 'Drive New Jersey Insurance Company' },
  { publicId: 5618, name: 'Progressive Freedom Insurance Co' },
  { publicId: 5619, name: 'Progressive Garden State Insurance Co' },
  { publicId: 5620, name: 'Tower National Insurance Company' },
  { publicId: 5621, name: 'American Hallmark Insurance Co of TX' },
  { publicId: 5622, name: 'Great American E&S Insurance Company' },
  { publicId: 5623, name: 'Pharmacists Mutual Insurance Company' },
  { publicId: 5624, name: 'Financial Pacific Insurance Company' },
  { publicId: 5625, name: 'Secura Insurance' },
  { publicId: 5626, name: 'Darwin National Assurance Company' },
  { publicId: 5627, name: 'Darwin Select Insurance Company' },
  { publicId: 5628, name: 'Endurance Reinsurance Corp of America' },
  { publicId: 5629, name: 'Mercury Indemnity Company of America' },
  { publicId: 5630, name: 'Rockhill Insurance Company' },
  { publicId: 5631, name: 'Traders and Pacific Insurance Company' },
  { publicId: 5632, name: 'Brookwood Insurance Company' },
  { publicId: 5633, name: 'Arch Excess & Surplus Insurance Company' },
  { publicId: 5634, name: 'Arch Insurance Company' },
  { publicId: 5635, name: 'Arch Reinsurance Company' },
  { publicId: 5636, name: 'Gray Insurance Company' },
  { publicId: 5637, name: 'AXIS Reinsurance Company' },
  { publicId: 5638, name: 'AXIS Surplus Insurance Company' },
  { publicId: 5639, name: 'United National Casualty Ins Co' },
  { publicId: 5640, name: 'United National Specialty Insurance Co' },
  { publicId: 5641, name: 'Harleysville Insurance Company of NY' },
  { publicId: 5642, name: 'Harleysville Lake States Insurance Co' },
  { publicId: 5643, name: 'Harleysville Pennland Insurance Company' },
  { publicId: 5644, name: 'Harleysville Preferred Insurance Company' },
  { publicId: 5645, name: 'Harleysville Worcester Insurance Company' },
  { publicId: 5646, name: 'Founders Insurance Company (IL)' },
  { publicId: 5647, name: 'Mutual Benefit Insurance Company' },
  { publicId: 5648, name: 'Technology Insurance Company, Inc' },
  { publicId: 5649, name: 'AIG Premier Insurance Company' },
  { publicId: 5650, name: 'Contractors Bonding and Insurance Co' },
  { publicId: 5651, name: 'California Insurance Company' },
  { publicId: 5652, name: 'Royal & Sun Alliance Ins Co of Canada' },
  { publicId: 5653, name: 'Rochdale Insurance Company' },
  { publicId: 5654, name: 'New London County Mutual Insurance Co' },
  { publicId: 5655, name: 'Southwest Marine and General Ins Co' },
  { publicId: 5656, name: 'Praetorian Insurance Company' },
  { publicId: 5657, name: 'Utica First Insurance Company' },
  { publicId: 5658, name: 'QBE Specialty Insurance Company' },
  { publicId: 5659, name: 'Accident Fund General Insurance Co' },
  { publicId: 5660, name: 'Accident Fund Ins Co of America' },
  { publicId: 5661, name: 'United Wisconsin Insurance Company' },
  { publicId: 5662, name: 'Argonaut Great Central Insurance Company' },
  { publicId: 5663, name: 'Great American Insurance Company' },
  { publicId: 5664, name: 'Endurance American Specialty Ins Co' },
  { publicId: 5665, name: 'Victoria Select Insurance Company' },
  { publicId: 5666, name: 'Crum & Forster Specialty Insurance Co' },
  { publicId: 5667, name: 'Penn-Patriot Insurance Company' },
  { publicId: 5668, name: 'Great American Insurance Company' },
  { publicId: 5669, name: 'RSUI Indemnity Company' },
  { publicId: 5670, name: 'Iowa Mutual Insurance Company' },
  { publicId: 5671, name: 'TRANSGUARD INSURANCE COMPANY OF AMERICA' },
  { publicId: 5672, name: 'Riverport Insurance Company' },
  { publicId: 5673, name: 'Continental Indemnity Company' },
  { publicId: 5674, name: 'Ansur America Insurance Company' },
  { publicId: 5675, name: 'America First Lloyds Insurance Co' },
  { publicId: 5676, name: 'Torus Specialty Insurance Company' },
  { publicId: 5677, name: 'Great American Insurance Company of NY' },
  { publicId: 5678, name: 'Penn Millers Insurance Company' },
  { publicId: 5679, name: 'Allied Eastern Indemnity Company' },
  { publicId: 5680, name: 'Eastern Alliance Insurance Company' },
  { publicId: 5681, name: 'Seneca Specialty Insurance Company' },
  { publicId: 5682, name: 'Alterra Excess & Surplus Insurance Co' },
  { publicId: 5683, name: 'United Specialty Insurance Company' },
  { publicId: 5684, name: 'LIG Insurance Company,  Ltd USB' },
  { publicId: 5685, name: 'Atlantic Casualty Insurance Company' },
  { publicId: 5686, name: 'North Pointe Insurance Company' },
  { publicId: 5687, name: 'Underwriters at Lloyds London (Illinois)' },
  { publicId: 5688, name: 'CastlePoint Insurance Company' },
  { publicId: 5689, name: 'Sheboygan Falls Mutual Insurance Company' },
  { publicId: 5690, name: 'Le Mars Insurance Company' },
  { publicId: 5691, name: 'First American Specialty Insurance Co' },
  { publicId: 5692, name: 'NGM Insurance Company' },
  { publicId: 5693, name: 'Merchants Mutual Insurance Company' },
  { publicId: 5694, name: 'Merchants National Insurance Company' },
  { publicId: 5695, name: 'Merchants Preferred Insurance Company' },
  { publicId: 5696, name: 'Catlin Insurance Company, Inc.' },
  { publicId: 5697, name: 'Catlin Specialty Insurance Company' },
  { publicId: 5698, name: 'Hawaii Employers Mutual Ins Co, Inc' },
  { publicId: 5699, name: 'Professional Security Insurance Company' },
  { publicId: 5700, name: 'Navigators Specialty Insurance Company' },
  { publicId: 5701, name: 'Farmers and Mechanics Mutual Ins Co (PA)' },
  { publicId: 5702, name: 'Great American Insurance Company of NY' },
  { publicId: 5703, name: 'Berkley Regional Specialty Insurance Co' },
  { publicId: 5704, name: 'AMERICAN MODERN SURPLUS LINES INS CO' },
  { publicId: 5705, name: 'ECONOMY FIRE & CASUALTY COMPANY' },
  { publicId: 5706, name: 'FIRSTCOMP INSURANCE CO' },
  { publicId: 5707, name: 'Great American Lloyds Insurance Company' },
  { publicId: 5708, name: 'FOREMOST SIGNATURE INSURANCE CO' },
  { publicId: 5709, name: 'Great American Lloyds Insurance Company' },
  { publicId: 5710, name: 'MIDWEST FAMILY MUTUAL INSURANCE CO' },
  { publicId: 5711, name: 'PREFERRED PROFESSIONAL INSURANCE CO' },
  { publicId: 5712, name: 'PRESERVER INSURANCE COMPANY' },
  { publicId: 5713, name: 'SAVERS PROPERTY & CASUALTY INS CO' },
  { publicId: 5714, name: 'TOWER HILL PREFERRED INS CO' },
  { publicId: 5715, name: 'TOWER HILL PRIME INSURANCE COMPANY' },
  { publicId: 5716, name: 'TOWER HILL SELECT INSURANCE COMPANY' },
  { publicId: 5717, name: 'YORK INSURANCE COMPANY' },
  { publicId: 5718, name: 'Associated Employers Insurance Company' },
  { publicId: 5719, name: 'ACE American Insurance Company' },
  { publicId: 5720, name: 'ACE Fire Underwriters Insurance Company' },
  { publicId: 5721, name: 'ACE Insurance Company of the Midwest' },
  { publicId: 5722, name: 'ACE Property & Casualty Insurance Co' },
  { publicId: 5723, name: 'ACIG Insurance Company' },
  { publicId: 5724, name: 'WestGUARD Insurance Company' },
  { publicId: 5725, name: 'American Modern Select Insurance Company' },
  { publicId: 5726, name: 'Endurance American Insurance Company' },
  { publicId: 5727, name: 'Unitrin Advantage Insurance Company' },
  { publicId: 5728, name: 'Unitrin Auto and Home Insurance Company' },
  { publicId: 5729, name: 'Unitrin County Mutual Insurance Company' },
  { publicId: 5730, name: 'Unitrin Direct Insurance Company' },
  { publicId: 5731, name: 'Unitrin Direct Property & Casualty Co' },
  { publicId: 5732, name: 'Unitrin Preferred Insurance Company' },
  { publicId: 5733, name: 'Builders Insurance (A Mutual Captive Co)' },
  { publicId: 5734, name: 'Colony Specialty Insurance Co' },
  { publicId: 5735, name: 'GMAC Insurance Company Online Inc' },
  { publicId: 5736, name: 'T.H.E. Insurance Company' },
  { publicId: 5737, name: 'Associated Industries of MA Mut Ins Co' },
  { publicId: 5738, name: 'Harbor Point Reinsurance U.S., Inc.' },
  { publicId: 5739, name: 'AIX Specialty Insurance Company' },
  { publicId: 5740, name: 'Esurance Insurance Company of New Jersey' },
  { publicId: 5741, name: 'Esurance Property and Casualty Ins Co' },
  { publicId: 5742, name: 'Esurance Insurance Company' },
  { publicId: 5743, name: 'Louisiana Dealers Services Insurance Inc' },
  { publicId: 5744, name: 'National Guaranty Ins Co of Vermont' },
  { publicId: 5745, name: 'Aspen Insurance UK Limited' },
  { publicId: 5746, name: 'American Compensation Insurance Company' },
  { publicId: 5747, name: 'American Strategic Insurance Corp' },
  { publicId: 5748, name: 'ASI Lloyds' },
  { publicId: 5749, name: 'ASI Assurance Corporation' },
  { publicId: 5750, name: 'Bloomington Compensation Insurance Co' },
  { publicId: 5752, name: 'CompWest Insurance Company' },
  { publicId: 5753, name: 'Developers Surety and Indemnity Company' },
  { publicId: 5754, name: 'Mercury Insurance Company of FL' },
  { publicId: 5755, name: 'CUMIS Specialty Insurance Company Inc' },
  { publicId: 5756, name: 'Litchfield Mutual Fire Insurance Company' },
  { publicId: 5757, name: 'Housing Enterprise Insurance Co, Inc' },
  { publicId: 5758, name: 'Housing Authority Property, A Mutual Co' },
  { publicId: 5759, name: 'MMG Insurance Company' },
  { publicId: 5760, name: 'Sequoia Indemnity Company' },
  { publicId: 5761, name: 'USF Insurance Company' },
  { publicId: 5762, name: 'Cranbrook Insurance Company' },
  { publicId: 5763, name: 'EMC Reinsurance Company' },
  { publicId: 5764, name: 'National Lloyds Insurance Company' },
  { publicId: 5765, name: 'Guarantee Insurance Company' },
  { publicId: 5766, name: 'Dryden Mutual Insurance Company' },
  { publicId: 5767, name: 'Erie and Niagara Insurance Association' },
  { publicId: 5768, name: 'Ironshore Specialty Insurance Co' },
  { publicId: 5769, name: 'Ironshore Indemnity Inc' },
  { publicId: 5770, name: 'Vinings Insurance Company' },
  { publicId: 5771, name: 'Association Casualty Insurance Company' },
  { publicId: 5772, name: 'Old Republic General Insurance Corp' },
  { publicId: 5773, name: 'Allied World National Assurance Company' },
  { publicId: 5774, name: 'Allied World Reinsurance Company' },
  { publicId: 5775, name: 'Allied World Assurance Co (U.S.) Inc.' },
  { publicId: 5776, name: 'Cotton States Mutual Insurance Company' },
  { publicId: 5777, name: 'National Continental Insurance Company' },
  { publicId: 5778, name: 'Fitchburg Mutual Insurance Company' },
  { publicId: 5779, name: 'Cincinnati Specialty Underwriters Ins Co' },
  { publicId: 5780, name: 'Commerce West Insurance Company' },
  { publicId: 5781, name: 'State Farm General Insurance Company' },
  { publicId: 5782, name: 'Savers P & C Ins Co' },
  { publicId: 5783, name: 'American Underwriters Insurance Company' },
  { publicId: 5784, name: 'Wadena Insurance Company' },
  { publicId: 5785, name: 'Amerisure Mutual Insurance Company' },
  { publicId: 5786, name: '21st Century Insurance Company' },
  { publicId: 5787, name: '21st  Century Ins Co of the Southwest' },
  { publicId: 5788, name: 'Dongbu Insurance Co., Ltd USB' },
  { publicId: 5789, name: 'Progressive Direct Insurance Company' },
  { publicId: 5790, name: 'Peerless Indemnity Insurance Company' },
  { publicId: 5791, name: 'Strathmore Insurance Company' },
  { publicId: 5792, name: 'Eastern Advantage Assurance Company' },
  { publicId: 5793, name: 'Employers Security Insurance Company' },
  { publicId: 5794, name: 'ASI Preferred Insurance Corporation' },
  { publicId: 5795, name: 'North East Insurance Company' },
  { publicId: 5796, name: 'Continental Assurance Company' },
  { publicId: 5797, name: 'Founders Insurance Company (PA)' },
  { publicId: 5798, name: 'Grinnell Select Insurance Company' },
  { publicId: 5799, name: 'American Mining Insurance Company' },
  { publicId: 5800, name: 'Allstate New Jersey Insurance Company' },
  { publicId: 5801, name: 'Hallmark Specialty Insurance Company' },
  { publicId: 5802, name: 'OneBeacon Insurance Company' },
  { publicId: 5803, name: 'OneBeacon Midwest Ins Company' },
  { publicId: 5804, name: 'ACA Insurance Company' },
  { publicId: 5805, name: 'Goodville Mutual Casualty Company' },
  { publicId: 5806, name: 'Employers Assurance Company' },
  { publicId: 5807, name: 'Wawanesa General Insurance Company' },
  { publicId: 5808, name: 'Hiscox Insurance Company Inc.' },
  { publicId: 5809, name: 'Stonewood Insurance Company' },
  { publicId: 5810, name: 'Reliable Lloyds Insurance Company' },
  { publicId: 5811, name: 'Atradius Trade Credit Insurance, Inc.' },
  { publicId: 5812, name: 'Artisan & Truckers Casualty Company' },
  { publicId: 5813, name: 'Crusader Insurance Company' },
  { publicId: 5814, name: 'Guarantee Co of North America USA' },
  { publicId: 5815, name: 'Permanent General Assurance Corporation' },
  { publicId: 5816, name: 'Permanent General Assurance Corp of OH' },
  { publicId: 5817, name: 'AmFed National Insurance Co' },
  { publicId: 5818, name: 'AmFed Casualty Insurance Company' },
  { publicId: 5819, name: 'Nodak Mutual Insurance Company' },
  { publicId: 5820, name: 'Indemnity Reinsurance Corp of DC, Inc.' },
  { publicId: 5821, name: 'AXIS Insurance Company' },
  { publicId: 5822, name: 'LUBA Casualty Insurance Company' },
  { publicId: 5823, name: 'Rural Mutual Insurance Company' },
  { publicId: 5824, name: 'Maiden Reinsurance Company' },
  { publicId: 5825, name: 'Atradius Trade Credit Insurance Co NJ' },
  { publicId: 5826, name: 'Hermitage Insurance Company' },
  { publicId: 5827, name: 'MEMIC Indemnity Company' },
  { publicId: 5828, name: 'Lion Insurance Company (domiciled in FL)' },
  { publicId: 5829, name: 'CastlePoint Florida Insurance Company' },
  { publicId: 5830, name: 'Allstate Fire & Casualty Insurance Co' },
  { publicId: 5831, name: 'Allstate New Jersey Property & Casualty' },
  { publicId: 5832, name: 'Allstate North American Insurance Co' },
  { publicId: 5833, name: 'Encompass Home and Auto Ins Co' },
  { publicId: 5834, name: 'Encompass Indemnity Company' },
  { publicId: 5835, name: 'Encompass Independent Insurance Company' },
  { publicId: 5836, name: 'Encompass Insurance Company' },
  { publicId: 5837, name: 'Encompass Insurance Company of America' },
  { publicId: 5838, name: 'Encompass Insurance Company of MA' },
  { publicId: 5839, name: 'Encompass Insurance Company of NJ' },
  { publicId: 5840, name: 'Encompass Property & Casualty Company' },
  { publicId: 5841, name: 'Encompass Property & Casualty Ins Co NJ' },
  { publicId: 5842, name: 'First Colonial Insurance Company' },
  { publicId: 5843, name: 'AmTrust Insurance Company of Kansas' },
  { publicId: 5844, name: 'Association Insurance Company' },
  { publicId: 5845, name: 'American International Ins Co of DE' },
  { publicId: 5846, name: 'Hallmark County Mutual Insurance Company' },
  { publicId: 5847, name: 'Crestbrook Insurance Company' },
  { publicId: 5848, name: 'Mercury County Mutual Insurance Company' },
  { publicId: 5849, name: 'Mercury National Insurance Company' },
  { publicId: 5850, name: 'James River Casualty Company' },
  { publicId: 5851, name: 'Pacific Specialty Property and Casualty' },
  { publicId: 5852, name: 'Leading Insurance Group Ins Co Ltd USB' },
  { publicId: 5853, name: 'Victoria Automobile Insurance Company' },
  { publicId: 5854, name: 'Victoria Fire & Casualty Company' },
  { publicId: 5855, name: 'Victoria National Insurance Company' },
  { publicId: 5856, name: 'Victoria Specialty Insurance Company' },
  { publicId: 5857, name: 'Arbella Mutual Insurance Company' },
  { publicId: 5858, name: 'Arbella Indemnity Insurance Company' },
  { publicId: 5859, name: 'Arbella Protection Insurance Company' },
  { publicId: 5860, name: 'Commonwealth Reinsurance Company' },
  { publicId: 5861, name: 'Covenant Insurance Company' },
  { publicId: 5862, name: 'Hudson Excess Insurance' },
  { publicId: 5863, name: 'WRM America Indemnity Company' },
  { publicId: 5864, name: 'American International Insurance Company' },
  { publicId: 5865, name: 'ProAssurance Specialty Insurance Co Inc' },
  { publicId: 5866, name: 'Podiatry Insurance Company of America' },
  { publicId: 5867, name: 'ProAssurance Casualty Company' },
  { publicId: 5868, name: 'ProAssurance Indemnity Company, Inc' },
  { publicId: 5869, name: 'Chartis Casualty Company' },
  { publicId: 5870, name: 'Response Insurance Company' },
  { publicId: 5871, name: 'Response Indemnity Company of California' },
  { publicId: 5872, name: 'Response Worldwide Direct Auto Insurance' },
  { publicId: 5873, name: 'Response Worldwide Insurance Company' },
  { publicId: 5874, name: 'National Merit Insurance Company' },
  { publicId: 5875, name: 'GNY Custom Insurance Company' },
  { publicId: 5876, name: 'Phenix Mutual Fire Insurance Company' },
  { publicId: 5877, name: 'New Mexico Assurance Company' },
  { publicId: 5878, name: 'New Mexico Employers Assurance Company' },
  { publicId: 5879, name: 'New Mexico Foundation Insurance Co' },
  { publicId: 5880, name: 'New Mexico Premier Insurance Company' },
  { publicId: 5881, name: 'New Mexico Southwest Casualty Company' },
  { publicId: 5882, name: 'Great Midwest Insurance Company' },
  { publicId: 5883, name: 'United Fire & Indemnity Company' },
  { publicId: 5884, name: 'ASure Worldwide Insurance Company' },
  { publicId: 5885, name: 'Utica Lloyds of Texas' },
  { publicId: 5886, name: 'Valiant Specialty Insurance Company' },
  { publicId: 5887, name: 'Sterling Insurance Company' },
  { publicId: 5888, name: 'Infinity County Mutual Insurance Co' },
  { publicId: 5889, name: 'Kinsale Insurance Company' },
  { publicId: 5890, name: 'Voyager Indemnity Insurance Company' },
  { publicId: 5891, name: 'CastlePoint National Insurance Company' },
  { publicId: 5892, name: 'Producers Agriculture Insurance Company' },
  { publicId: 5893, name: 'CampMed C & I Company, Inc of MD' },
  { publicId: 5894, name: 'American Contractors Indemnity Company' },
  { publicId: 5895, name: 'HCC Specialty Insurance Company' },
  { publicId: 5896, name: 'American Republic Insurance Company' },
  { publicId: 5897, name: 'Chartis Insurance Company of Canada' },
  { publicId: 5898, name: 'Grain Dealers Mutual Insurance Company' },
  { publicId: 5899, name: 'Starr Surplus Lines Insurance Company' },
  { publicId: 5900, name: 'Electric Insurance Company' },
  { publicId: 5901, name: 'Rural Community Insurance Company' },
  { publicId: 5902, name: 'Intact Insurance Company' },
  { publicId: 5903, name: 'MSA Insurance Company' },
  { publicId: 5904, name: 'Wisconsin Mutual Insurance Company' },
  { publicId: 5905, name: 'ProAssurance National Capital Ins Co' },
  { publicId: 5906, name: 'Island Premier Insurance Company, Ltd.' },
  { publicId: 5907, name: 'Chubb Insurance Company of Canada' },
  { publicId: 5908, name: 'Clermont Insurance Company' },
  { publicId: 5909, name: 'Alterra Reinsurance USA Inc.' },
  { publicId: 5910, name: 'Alamo Title Insurance' },
  { publicId: 5911, name: 'NIPPONKOA Insurance Company Limited USB' },
  { publicId: 5912, name: 'Southern Fire & Casualty Company' },
  { publicId: 5913, name: 'Intermed Insurance Company' },
  { publicId: 5914, name: 'Anesthesiologists Professional Assur Co' },
  { publicId: 5915, name: 'First Professionals Insurance Company' },
  { publicId: 5916, name: 'Farm Bureau Property & Casualty Ins Co' },
  { publicId: 5917, name: 'International Insurance Company of Hannover SE' },
  { publicId: 5918, name: 'Florida Hospitality Mutual Insurance Co' },
  { publicId: 5919, name: 'Thames Insurance Company, Inc.' },
  { publicId: 5920, name: 'AmTrust Lloyds Insurance Company of TX' },
  { publicId: 5921, name: 'Security National Insurance Company (FL)' },
  { publicId: 5922, name: 'Patriot Insurance Company' },
  { publicId: 5923, name: 'Great Lakes Reinsurance (UK) PLC' },
  { publicId: 5924, name: 'Titan Prop & Casualty Insurance Co' },
  { publicId: 5925, name: 'Wisonsin American Mutual Insurance Co' },
  { publicId: 5926, name: 'MAPFRE Insurance Company of Florida' },
  { publicId: 5927, name: 'State-Wide Insurance Company' },
  { publicId: 5928, name: 'Mid-Continent Assurance Company' },
  { publicId: 5929, name: 'Great American Casualty Insurance Co' },
  { publicId: 5930, name: 'Great American Contemporary Insurance Co' },
  { publicId: 5931, name: 'Great American Fidelity Insurance Co' },
  { publicId: 5932, name: 'Great American Protection Insurance Co' },
  { publicId: 5933, name: 'Great American Security Insurance Co' },
  { publicId: 5934, name: 'Great American Spirit Insurance Company' },
  { publicId: 5935, name: 'National Interstate Insurance Company HI' },
  { publicId: 5936, name: 'Triumphe Casualty Company' },
  { publicId: 5937, name: 'Associated Industries Insurance Co, Inc' },
  { publicId: 5938, name: 'Wilson Mutual Insurance Company' },
  { publicId: 5939, name: 'Pilgrim Insurance Company' },
  { publicId: 5940, name: 'Alfa Alliance Insurance Corporation' },
  { publicId: 5941, name: 'Alfa Mutual Fire Insurance Company' },
  { publicId: 5942, name: 'Alfa Specialty Insurance Corporation' },
  { publicId: 5943, name: 'Alfa Vision Insurance Corp' },
  { publicId: 5944, name: 'Maiden Specialty Insurance Company' },
  { publicId: 5945, name: 'Aspen American Insurance Company' },
  { publicId: 5946, name: 'First Trenton Indemnity Company' },
  { publicId: 5947, name: 'NAU Country Insurance Company' },
  { publicId: 5948, name: 'Southern Pilot Insurance Company' },
  { publicId: 5949, name: 'Hamilton Insurance Corp.' },
  { publicId: 5950, name: 'Safety First Insurance Company' },
  { publicId: 5951, name: 'New Hampshire Employers Insurance Co' },
  { publicId: 5952, name: 'TM Casualty Insurance Group' },
  { publicId: 5953, name: 'TM Specialty Insurance Company' },
  { publicId: 5954, name: 'TNUS Insurance Co' },
  { publicId: 5955, name: 'Tokio Marine & Nichido Fire Ins ltd USB' },
  { publicId: 5956, name: 'Liberty Insurance Underwriters, Inc' },
  { publicId: 5957, name: 'Endeavour Insurance Company' },
  { publicId: 5958, name: 'Houston Specialty Insurance Company' },
  { publicId: 5959, name: 'Imperium Insurance Company' },
  { publicId: 5960, name: 'Auto Club Property-Casualty Insurance Co' },
  { publicId: 5961, name: 'MAPFRE PRAICO Insurance Company' },
  { publicId: 5962, name: 'Mid-Continent Excess & Surplus Ins Co' },
  { publicId: 5963, name: 'Harleysville Insurance Company' },
  { publicId: 5964, name: 'Harleysville Insurance Co of New York' },
  { publicId: 5965, name: 'XL Insurance America, Inc' },
  { publicId: 5966, name: 'XL Select Insurance Company' },
  { publicId: 5967, name: 'Ohio Indemnity Company' },
  { publicId: 5968, name: 'Amer Modern Surplus Lines Insurance Co' },
  { publicId: 5969, name: 'American Modern Ins Co of Florida, Inc' },
  { publicId: 5970, name: 'First Marine Insurance Company' },
  { publicId: 5971, name: 'Main Street American Protection Ins Co' },
  { publicId: 5972, name: 'First NonProfit Insurance Company' },
  { publicId: 5973, name: 'Arizona Automobile Insurance Company' },
  { publicId: 5974, name: 'Umialik Insurance Company' },
  { publicId: 5975, name: 'Freedom Specialty Insurance Company' },
  { publicId: 5976, name: 'Nationwide Affinity Insurance Co of America' },
  { publicId: 5977, name: 'Safety Property & Casualty Insurance Co' },
  { publicId: 5978, name: 'Battle Creek Mutual Insurance Company' },
  { publicId: 5979, name: 'Millers Capital Insurance Company' },
  { publicId: 5980, name: 'Alterra America Insurance Company' },
  { publicId: 5981, name: 'Horace Mann Property & Casualty Ins Co' },
  { publicId: 5982, name: 'Protective Specialty Insurance Company' },
  { publicId: 5983, name: 'Casco Indemnity Company' },
  { publicId: 5984, name: 'AAA Mid-Atlantic Insurance Company' },
  { publicId: 5985, name: 'AAA Mid-Atlantic Insurance Co of NJ' },
  { publicId: 5986, name: 'AAA Northern California, NV & UT Ins Exc' },
  { publicId: 5987, name: 'AAA Texas County Mutual Insurance Co.' },
  { publicId: 5988, name: 'Western United Insurance Company' },
  { publicId: 5989, name: 'Iowa American Insurance Company' },
  { publicId: 5990, name: 'MICO Insurance Company' },
  { publicId: 5991, name: 'Capitol County Mutual Fire Insurance Co' },
  { publicId: 5992, name: 'Kemper Independence Insurance Company' },
  { publicId: 5993, name: 'Merastar Insurance Company' },
  { publicId: 5994, name: 'Mutual Savings Fire Insurance Company' },
  { publicId: 5995, name: 'Old Reliable Casualty Company' },
  { publicId: 5996, name: 'Reserve National Insurance Company' },
  { publicId: 5997, name: 'United Casualty Insurance Co of America' },
  { publicId: 5998, name: 'Unitrin Safeguard Insurance Company' },
  { publicId: 5999, name: 'Hillstar Insurance Company' },
  { publicId: 6000, name: 'Infinity Assurance Insurance Group' },
  { publicId: 6001, name: 'Infinity Auto Insurance Company' },
  { publicId: 6002, name: 'Infinity Casualty Insurance Company' },
  { publicId: 6003, name: 'Infinity General Insurance Company' },
  { publicId: 6004, name: 'Infinity Indemnity Insurance Company' },
  { publicId: 6005, name: 'Infinity Preferred Insurance Company' },
  { publicId: 6006, name: 'Infinity Reserve Insurance Company' },
  { publicId: 6007, name: 'Infinity Safeguard Insurance Company' },
  { publicId: 6008, name: 'Infinity Security Insurance Company' },
  { publicId: 6009, name: 'Infinity Specialty Insurance Company' },
  { publicId: 6010, name: 'Infinity Standard Insurance Company' },
  { publicId: 6011, name: 'St Paul Fire & Casualty Insurance Co' },
  { publicId: 6012, name: 'St Paul Fire and Marine Insurance Co' },
  { publicId: 6013, name: 'St Paul Guardian Insurance Company' },
  { publicId: 6014, name: 'St Paul Surplus Lines Insurance Company' },
  { publicId: 6015, name: 'St Paul Protective Insurance Company' },
  { publicId: 6016, name: 'Travelers Commercial Casualty Company' },
  { publicId: 6017, name: 'Selective Auto Insurance Co New Jersey' },
  { publicId: 6018, name: 'Selective Insurance Company of NE' },
  { publicId: 6019, name: 'Maxum Casualty Insurance Company' },
  { publicId: 6020, name: 'State Auto Insurance Co of Wisconsin' },
  { publicId: 6021, name: 'State Auto Insurance Company of Ohio' },
  { publicId: 6022, name: 'Peninsula Insurance Company' },
  { publicId: 6023, name: 'Peninsula Indemnity Company' },
  { publicId: 6024, name: 'Covington Specialty Insurance Company' },
  { publicId: 6025, name: 'Platte River Insurance Company' },
  { publicId: 6026, name: 'Hanover New Jersey Insurance Company' },
  { publicId: 6027, name: 'Professionals Direct Insurance Company' },
  { publicId: 6028, name: 'Verlan Fire Insurance Company' },
  { publicId: 6029, name: 'AGCS Marine Insurance Company' },
  { publicId: 6030, name: 'Livingston Mutual Insurance Company' },
  { publicId: 6031, name: 'Penn Charter Mutual Insurance Company' },
  { publicId: 6032, name: 'GeoVera Security Insurance Company' },
  { publicId: 6033, name: 'GeoVera Specialty Insurance Company' },
  { publicId: 6034, name: 'Pacific Select Property Insurance Co' },
  { publicId: 6035, name: 'Stonebridge Casualty Insurance Company' },
  { publicId: 6036, name: 'CorePointe Insurance Company' },
  { publicId: 6037, name: 'AXA Insurance Company' },
  { publicId: 6038, name: 'Argonaut Limited Risk Insurance Co' },
  { publicId: 6039, name: 'Select Markets Insurance Company' },
  { publicId: 6040, name: 'Somerset Casualty Insurance Company' },
  { publicId: 6041, name: 'RLI Indemnity Company' },
  { publicId: 6042, name: 'Berkley Assurance Company' },
  { publicId: 6043, name: 'Deerfield Insurance Company' },
  { publicId: 6044, name: 'Everest Security Insurance Company' },
  { publicId: 6045, name: 'Amerisure Partners Insurance Company' },
  { publicId: 6046, name: 'FCCI Commercial Insurance Company' },
  { publicId: 6047, name: 'Lombard General Insurance Co of Canada' },
  { publicId: 6048, name: 'Essentia Insurance Company' },
  { publicId: 6049, name: 'Homeland Insurance Company of New York' },
  { publicId: 6050, name: 'Pennsylvania General Insurance Company' },
  { publicId: 6051, name: 'Traders & General Insurance Company' },
  { publicId: 6052, name: 'First Floridian Auto and Home Ins Co' },
  { publicId: 6053, name: 'Comp Options Insurance Company' },
  { publicId: 6054, name: 'Atain Specialty Insurance Company' },
  { publicId: 6055, name: 'Torus National Insurance Company' },
  { publicId: 6056, name: 'Brickstreet Mutual Insurance Company (West VA)' },
  { publicId: 6057, name: 'Pilot Insurance Company' },
  { publicId: 6058, name: 'AmTrust International Underwriters Ltd.' },
  { publicId: 6059, name: 'Germania Select Insurance Company' },
  { publicId: 6060, name: 'HDI-Gerling America Insurance Company' },
  { publicId: 6061, name: 'Partners Mutual Insurance Company' },
  { publicId: 6062, name: 'Penn National Insurance Company' },
  { publicId: 6063, name: 'Progressive Choice Insurance Co' },
  { publicId: 6064, name: 'Progressive Max Insurance Company' },
  { publicId: 6065, name: 'Progressive Select Insurance Company' },
  { publicId: 6066, name: 'Fremont Insurance Company' },
  { publicId: 6067, name: 'Fidelity National Insurance Company' },
  { publicId: 6068, name: 'Universal North America Insurance Co' },
  { publicId: 6069, name: 'Old United Casualty Company' },
  { publicId: 6070, name: 'Noetic Specialty Insurance Company' },
  { publicId: 6071, name: 'Infinity Premier Insurance Company' },
  { publicId: 6072, name: 'Mesa Underwriters Specialty Insurance Co' },
  { publicId: 6073, name: 'Premier Insurance Company of MA' },
  { publicId: 6074, name: 'Atain Insurance Company' },
  { publicId: 6075, name: 'Aioi Nissay Dowa Insurance Co of America' },
  { publicId: 6076, name: 'ACE European Group Limited' },
  { publicId: 6077, name: 'Allegany Co-op Insurance Company' },
  { publicId: 6078, name: 'State Farm Indemnity Company' },
  { publicId: 6079, name: 'Grange Property & Casualty Insurance Co' },
  { publicId: 6080, name: 'Integrity Property & Casualty Ins Co' },
  { publicId: 6081, name: 'MAPFRE Insurance Company of New York' },
  { publicId: 6082, name: 'MAPFRE Insurance Company' },
  { publicId: 6083, name: 'Laurier Indemnity Company' },
  { publicId: 6084, name: 'FCCI Advantage Insurance Company' },
  { publicId: 6085, name: 'GEICO Advantage Insurance Company' },
  { publicId: 6086, name: 'GEICO Choice Insurance Company' },
  { publicId: 6087, name: 'GEICO Secure Insurance Company' },
  { publicId: 6088, name: 'Tokio Marine America Insurance Company' },
  { publicId: 6089, name: 'Berkeley National Insurance Company' },
  { publicId: 6090, name: 'Oklahoma Specialty Insurance Company' },
  { publicId: 6091, name: 'Farmers Insurance Hawaii Inc' },
  { publicId: 6092, name: 'Insurance Company of Illinois' },
  { publicId: 6093, name: 'LM General Insurance Company' },
  { publicId: 6094, name: 'LM Property & Casualty Insurance Company' },
  { publicId: 6095, name: 'Liberty County Mutual Insurance Company' },
  { publicId: 6096, name: 'Mid-American Fire & Casualty Company' },
  { publicId: 6097, name: 'Massachusetts Homeland Insurance Co.' },
  { publicId: 6098, name: 'Cherokee Insurance Company' },
  { publicId: 6099, name: 'GuideOne National Insurance Company' },
  { publicId: 6100, name: 'Triple-S Propiedad' },
  { publicId: 6101, name: 'Selective Casualty Insurance Company' },
  { publicId: 6102, name: 'Selective Fire & Casualty Insurance Co' },
  { publicId: 6103, name: 'Utica National Insurance Co of Ohio' },
  { publicId: 6104, name: 'Beazley Furlonge Ltd' },
  { publicId: 6105, name: 'Grange Indemnity Insurance Company' },
  { publicId: 6106, name: 'DTRIC Insurance Company, Limited' },
  { publicId: 6107, name: 'Chartis Insurance Company - Puerto Rico' },
  { publicId: 6108, name: 'Canopius US Insurance, Inc.' },
  { publicId: 6109, name: 'Aviva Insurance Limited' },
  { publicId: 6110, name: 'BrickStreet Mutual Ins Co' },
  { publicId: 6111, name: 'Security National Insurance Company' },
  { publicId: 6112, name: 'Zurich Insurance Company' },
  { publicId: 6113, name: 'NorthStone Insurance Company' },
  { publicId: 6114, name: 'Tokio Marine Specialty Insurance Company' },
  { publicId: 6115, name: 'UPMC Health Benefits Inc' },
  { publicId: 6116, name: 'Aviva Insurance Company of Canada' },
  { publicId: 6117, name: 'Travelers Insurance Company of Canada' },
  { publicId: 6118, name: 'Builders Premier Insurance Company' },
  { publicId: 6119, name: 'Housing Enterprise Insurance Co Inc' },
  { publicId: 6120, name: 'Mississippi Farm Bureau Casualty Ins Co' },
  { publicId: 6121, name: 'Southern FB Casualty Ins Co' },
  { publicId: 6122, name: 'Hallmark National Insurance Company' },
  { publicId: 6123, name: 'New Jersey Indemnity Insurance Company' },
  { publicId: 6124, name: 'Privilege Underwriters Reciprocal Exchange' },
  { publicId: 6125, name: 'Seaworthy Insurance' },
  { publicId: 6126, name: 'ACE INA Insurance' },
  { publicId: 6127, name: 'Berkley Life and Health Insurance Co' },
  { publicId: 6128, name: 'Northbridge General Insurance' },
  { publicId: 6129, name: 'Berkley National Insurance Company' },
  { publicId: 6130, name: 'Old Republic Insurance Company of Canada' },
  { publicId: 6131, name: 'Allianz Global Risks US Insurance Company' },
  { publicId: 6132, name: 'AIG Seguros Mexico, S.A. de C.V.' },
  { publicId: 6133, name: 'Pacific Compensation Insurance Co' },
  { publicId: 6134, name: 'CSE Safeguard Ins Co' },
  { publicId: 6135, name: 'Illinois National Insurance Company' },
  { publicId: 6136, name: 'AIG Specialty Insurance Company' },
  { publicId: 6137, name: 'SCF Casualty Insurance Company' },
  { publicId: 6138, name: 'Illinois National Insurance Company' },
  { publicId: 6139, name: "Kentucky Employers' Mutual Insurance" },
  { publicId: 6140, name: 'Chesapeake Employers Insurance Company' },
  { publicId: 6141, name: 'American Modern Insurance Company of Florida Incorporated' },
  { publicId: 6142, name: 'Kookmin Best Insurance (United States Branch)' },
  { publicId: 6143, name: 'Stillwater Insurance Company' },
  { publicId: 6144, name: 'Catlin Indemnity Company' },
  { publicId: 6145, name: 'Tuscarora Wayne Insurance Company' },
  { publicId: 6146, name: 'AIG Assurance Company' },
  { publicId: 6147, name: 'Lexington Insurance Company' },
  { publicId: 6148, name: 'OBI National Insurance Company' },
  { publicId: 6149, name: 'Lexington Insurance Company' },
  { publicId: 6150, name: 'Liberty Insurance Corporation' },
  { publicId: 6151, name: 'Liberty Insurance Corporation' },
  { publicId: 6152, name: 'CopperPoint Mutual Insurance Company' },
  { publicId: 6153, name: 'Westminster American Insurance Company' },
  { publicId: 6154, name: 'Manitoba Public Insurance' },
  { publicId: 6155, name: 'The Dominion of Canada General Insurance' },
  { publicId: 6156, name: 'UPMC Health Benefits Inc.' },
  { publicId: 6157, name: 'KnightBrook Insurance Company' },
  { publicId: 6158, name: 'BITCO General Insurance Corporation' },
  { publicId: 6159, name: 'BITCO National Insurance Company' },
  { publicId: 6160, name: 'Pinnacle Point Insurance Company' },
  { publicId: 6161, name: 'Pioneer Specialty Insurance Company' },
  { publicId: 6162, name: 'Liberty Mutual Insurance Europe Ltd' },
  { publicId: 6163, name: 'Knight Specialty Insurance Company' },
  { publicId: 6164, name: 'Falls Lake National Insurance Company' },
  { publicId: 6165, name: 'CopperPoint Western Insurance Company' },
  { publicId: 6166, name: 'Rockingham Casualty Company' },
  { publicId: 6167, name: 'CopperPoint General Insurance Company' },
  { publicId: 6168, name: 'FirstComp Insurance Company' },
  { publicId: 6169, name: 'Hamilton Specialty Insurance Company' },
  { publicId: 6170, name: 'Farmers Union Mutual Insurance Company (ND)' },
  { publicId: 6171, name: 'HM Casualty Insurance Company' },
  { publicId: 6172, name: 'CopperPoint National INsurance Company' },
  { publicId: 6173, name: 'Allied Insurance Company of America' },
  { publicId: 6174, name: 'Liberty Insurance Underwriters Inc' },
  { publicId: 6175, name: 'Liberty Insurance Underwriters Inc' },
  { publicId: 6176, name: 'Liberty Mutual Fire Insurance Company' },
  { publicId: 6177, name: 'American Builders Insurance Company' },
  { publicId: 6178, name: 'CopperPoint Indemnity Insurance Company' },
  { publicId: 6179, name: 'National Builders Insurance Company' },
  { publicId: 6180, name: 'Liberty Mutual Fire Insurance Company' },
  { publicId: 6181, name: 'Liberty Mutual Insurance Company' },
  { publicId: 6182, name: 'HDI Global Insurance Company' },
  { publicId: 6183, name: 'Lloyds Syndicate 1991 (R&Q Managing Agency Limited)' },
  { publicId: 6184, name: 'Spring Valley Mutual Insurance Company' },
  { publicId: 6185, name: 'Starstone National Insurance Company' },
  { publicId: 6186, name: 'Liberty Mutual Insurance Company' },
  { publicId: 6187, name: 'Liberty Northwest Insurance Corporation' },
  { publicId: 6188, name: 'Florists Mutual Insurance Company' },
  { publicId: 6189, name: 'ARI Insurance Company' },
  { publicId: 6190, name: 'Watford Specialty Insurance Company' },
  { publicId: 6191, name: 'Illinois Insurance Company' },
  { publicId: 6192, name: 'Homeland insurance Company of Delaware' },
  { publicId: 6193, name: 'Liberty Northwest Insurance Corporation' },
  { publicId: 6194, name: 'Liberty Surplus Insurance Corporation' },
  { publicId: 6195, name: 'falls lake fire and casualty company' },
  { publicId: 6196, name: 'CM Vantage Specialty Insurance Company' },
  { publicId: 6197, name: 'Peleus Insurance Company' },
  { publicId: 6198, name: 'Allied World Surplus Lines Insurance Company' },
  { publicId: 6199, name: 'Clear Blue Specialty Insurance Company' },
  { publicId: 6200, name: 'New Mexico Security Insurance Company' },
  { publicId: 6201, name: 'STILLWATER INSURANCE CO' },
  { publicId: 6202, name: 'Golden Bear Insurance Company' },
  { publicId: 6203, name: 'Liberty Surplus Insurance Corporation' },
  { publicId: 6204, name: 'Old Republic Union Insurance Company' },
  { publicId: 6205, name: 'Ironshore Europe DAC' },
  { publicId: 6206, name: 'Markel American Insurance Company' },
  { publicId: 6207, name: 'LUBA Indemnity Insurance Company' },
  { publicId: 6208, name: 'Markel American Insurance Company' },
  { publicId: 6209, name: 'Michigan Insurance Company' },
  { publicId: 6210, name: 'Rockingham Insurance Compnay' },
  { publicId: 6211, name: 'Intrepid Insurance Company' },
  { publicId: 6212, name: 'Windsor-Mount Joy Mutual Insurance Company' },
  { publicId: 6213, name: 'Sompo America Insurance Company' },
  { publicId: 6214, name: 'Everett Cash Mutual Insurance Company' },
  { publicId: 6215, name: 'StarStone Specialty Insurance Company' },
  { publicId: 6216, name: 'Lancer Indemnity Company' },
  { publicId: 6217, name: 'National American Insurance Co' },
  { publicId: 6218, name: 'National Union Fire Ins Co Pittsburgh PA' },
  { publicId: 6219, name: 'National Union Fire Ins Co Pittsburgh PA' },
  { publicId: 6220, name: 'Scottsdale Indemnity Company' },
  { publicId: 6221, name: 'Scottsdale Indemnity Company' },
  { publicId: 6222, name: 'Travelers Auto Ins Co Of New Jersey' },
  { publicId: 6223, name: 'Travelers Auto Ins Co Of New Jersey' },
  { publicId: 6224, name: 'Travelers Casualty and Surety Company' },
  { publicId: 6225, name: 'Travelers Casualty and Surety Company' },
  { publicId: 6226, name: 'Travelers Casualty and Surety Company of America' },
  { publicId: 6227, name: 'Travelers Casualty and Surety Company of America' },
  { publicId: 6228, name: 'Travelers Casualty Company of Connecticut' },
  { publicId: 6229, name: 'Travelers Casualty Company of Connecticut' },
  { publicId: 6230, name: 'Travelers Casualty Insurance Co of America' },
  { publicId: 6231, name: 'Travelers Casualty Insurance Co of America' },
  { publicId: 6232, name: 'Travelers Commercial Insurance Company' },
  { publicId: 6233, name: 'Travelers Commercial Insurance Company' },
  { publicId: 6234, name: 'Travelers Excess and Surplus Lines Company' },
  { publicId: 6235, name: 'Travelers Excess and Surplus Lines Company' },
  { publicId: 6236, name: 'Travelers Home and Marine Insurance Company' },
  { publicId: 6237, name: 'Travelers Home and Marine Insurance Company' },
  { publicId: 6238, name: 'Travelers Indemnity Company' },
  { publicId: 6239, name: 'Travelers Indemnity Company' },
  { publicId: 6240, name: 'Travelers Indemnity Company of America' },
  { publicId: 6241, name: 'Travelers Indemnity Company of America' },
  { publicId: 6242, name: 'Travelers Indemnity Company of Connecticut' },
  { publicId: 6243, name: 'Travelers Indemnity Company of Connecticut' },
  { publicId: 6244, name: 'Travelers Lloyds Insurance Company' },
  { publicId: 6245, name: 'Travelers Lloyds Insurance Company' },
  { publicId: 6246, name: 'Travelers Lloyds of Texas Insurance Company' },
  { publicId: 6247, name: 'Travelers Lloyds of Texas Insurance Company' },
  { publicId: 6248, name: 'Travelers Property Casualty Co of Amer' },
  { publicId: 6249, name: 'Travelers Property Casualty Co of Amer' },
  { publicId: 6250, name: 'Travelers Property Casualty Insurance Company' },
  { publicId: 6251, name: 'Travelers Property Casualty Insurance Company' },
  { publicId: 6252, name: 'United States Liability Insurance Company' },
  { publicId: 6253, name: 'United States Liability Insurance Company' },
  { publicId: 6254, name: 'Beacon Mutual Insurance Company (RI State Fund)' },
  { publicId: 6255, name: 'Pinnacol Assurance Corporation(State Fund of Colorado)' },
  { publicId: 6256, name: 'State Insurance Fund of New York' },
  { publicId: 6257, name: 'State Insurance Fund of Oregon' },
  { publicId: 6258, name: 'State Insurance Fund of Utah' },
  { publicId: 6259, name: 'State Insurance Fund of California' },
  { publicId: 6260, name: 'State Compensation Fund of Arizona' },
  { publicId: 6261, name: 'Louisiana Workers Compensation Corporation' },
  { publicId: 6262, name: 'Missouri Employers Mutual Insurance Company' },
  { publicId: 6263, name: 'Alabama Self-Insured Workers Comp Fd' },
  { publicId: 6264, name: 'CompSource Mutual' },
  { publicId: 6265, name: 'CompSource Oklahoma' },
  { publicId: 6266, name: 'Maine Employers Mutual Insurance Co.' },
  { publicId: 6267, name: 'State Insurance Fund of Pennsylvania' },
  { publicId: 6268, name: 'Associated Industries Insurance Co. (FL)' },
  { publicId: 6269, name: 'Idaho State Insurance Fund' },
  { publicId: 6270, name: 'North Carolina Mutual Employers Fund' },
  { publicId: 6271, name: 'Injured Workers Insurance Fund(Maryland)' },
  { publicId: 6272, name: 'Montana State Fund' },
  { publicId: 6273, name: 'Florida Retail Federation Self Insurers Fund' },
  { publicId: 6274, name: 'Western Heritage Insurance Company' },
  { publicId: 6275, name: 'State Fund Mutual Ins Co of MN' },
  { publicId: 6276, name: 'State Compensation Fund of California' },
  { publicId: 6277, name: 'State Workers Insurance Fund' },
  { publicId: 6278, name: 'State Accident Fund of South Carolina' },
  { publicId: 6279, name: 'Worker Compensation Fund' },
  { publicId: 6280, name: 'SAIF (State Accident Injury Fund)' },
  { publicId: 6281, name: 'Ohio Bureau of Workers Compensation' },
  { publicId: 6282, name: 'North Dakota Workers Compensation Bureau' },
  { publicId: 6283, name: 'Carolina Mutual Insurance Company' },
  { publicId: 6284, name: 'Erie Insurnace Company' },
  { publicId: 6285, name: 'Western Heritage Insurance Company' },
  { publicId: 6286, name: 'SFM Mutual Insurance Company' },
];
