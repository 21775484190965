import { HostListener, Input, OnInit, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { getControl, calculateCursorPosition } from 'app/shared/helpers/form-helpers';

@Directive()
export abstract class BaseMaskDirective implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() nameOfFormControl: string;

  MAX_DIGITS = 10;
  count = 0;

  constructor() {}

  ngOnInit() {
    this.formCheck();
    const formattedValue = this.getMask(getControl(this.form, this.nameOfFormControl).value);
    this.form.patchValue({ [this.nameOfFormControl]: formattedValue });
  }

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const value = (<HTMLInputElement>event.target).value;
    const maskedValue = this.getMask(value, event);

    this.form.patchValue({ [this.nameOfFormControl]: maskedValue });
  }

  private formCheck() {
    if (!this.form) {
      console.warn(`Missing FormGroup.`);
      return;
    } else if (!this.nameOfFormControl || !getControl(this.form, this.nameOfFormControl)) {
      console.warn(`Form control ${this.nameOfFormControl} does not exist in FormGroup.`);
      return;
    }
  }

  abstract getMask(value: string, event?: KeyboardEvent): string;

  protected updateFormValue(updatedValue: string) {
    this.form.patchValue({ [this.nameOfFormControl]: updatedValue });
  }

  protected getNumberValueFormattedWithoutDecimals(value: string | number | null): string | null {
    let numericalVal;
    if (typeof value === 'string') {
      numericalVal = this.parseNumberForMask(value);
    } else {
      numericalVal = value;
    }

    if (numericalVal !== null) {
      return numericalVal.toLocaleString('en-US');
    }

    return null;
  }

  protected getNumberValueFormattedWithDecimals(
    value: string | number | null,
    formatToLocale: boolean = true
  ): string | null {
    if (value === null) {
      return null;
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    // Remove any characters besides the digits 0-9 and decimal points.
    let numericalVal = value.replace(/[^0-9.]/g, '');
    if (!numericalVal.length) {
      // After removing any non-digit characters and decimal points, there was
      // nothing left!
      return null;
    }

    // The number doesn't have any decimal points, so it can be formatted as is
    // and returned.
    if (!numericalVal.includes('.')) {
      return formatToLocale ? Number(numericalVal).toLocaleString('en-US') : numericalVal;
    }

    // At this point, the number has decimal points. If it has more than one,
    // we'll remove all but the first.
    let decimalFound = false;
    numericalVal = numericalVal.replace(/\./g, (match) => {
      if (!decimalFound) {
        decimalFound = true;
        return match;
      }

      return '';
    });

    // We need to split the whole part and fractional part of the number because
    // they will be formatted differently.
    let [wholePart, fractionalPart] = numericalVal.split('.');

    // The whole part is formatted to add commas where expected.
    wholePart = formatToLocale ? Number(wholePart).toLocaleString('en-US') : wholePart;

    // The fractional part is limited to only two characters.
    fractionalPart = fractionalPart.slice(0, 2);

    // The decimal point is explicitly included so users can continue to type
    // numbers after it.
    return wholePart + '.' + fractionalPart;
  }

  protected getNumericString(value: string | number): string | null {
    if (typeof value === 'number') {
      return String(value);
    }

    return this.parseNumberForStringMask(value);
  }

  private parseNumberForMask(value: string): number | null {
    let returnVal = null;
    let numericalVal = value.replace(/\D+/g, '');

    if (numericalVal.length > this.MAX_DIGITS) {
      numericalVal = numericalVal.slice(0, this.MAX_DIGITS);
    }

    if (numericalVal.length) {
      returnVal = parseInt(numericalVal, 10);
    }

    return returnVal;
  }

  private parseNumberForStringMask(value: string): string | null {
    const numericalVal = value.replace(/\D+/g, '');
    return numericalVal.length ? numericalVal.slice(0, this.MAX_DIGITS) : null;
  }

  // This function updates the cursor position after the form control is patched with
  // the masked value. Without updating the cursor position, the user's cursor will be
  // reset to the end of the textbox for each form patch.
  protected updateCursorPosition(target: HTMLInputElement, maskedValue: string) {
    if (typeof target.selectionStart === 'number' && target.setSelectionRange) {
      // Determine the new cursor position based on the input string and the masked string
      const newCursorPosition = calculateCursorPosition(
        target.selectionStart,
        target.value,
        maskedValue
      );
      // Wait a `tick` for the form value to update before setting cursor position
      setTimeout(() => {
        // This check prevents setting the cursor position when input field value changes
        // faster than cursor position can be set.
        if (target.value === maskedValue) {
          target.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      });
    }
  }
}
