<ng-container *ngIf="form.enabled">
  <div class="form-field {{ cssClass }}">
    <button
      [id]="inputId"
      class="button button__small button__no-margin button__secondary js-upload-file-button"
      [ngClass]="{ button__error: fieldInvalid(nameOfFormControl) }"
      type="button"
      (click)="fileInput.click()"
    >
      <div class="button-loader" *ngIf="fileUploading">
        <svg class="circle-spinner">
          <circle
            cx="11"
            cy="11"
            r="9"
            stroke-width="2"
            stroke-dasharray="0,1000"
            d="M5 40 l215 0"
            fill="transparent"
          />
        </svg>
      </div>
      Upload
      <input
        type="file"
        style="display: none"
        id="{{ inputId }}-selectFile"
        accept=".pdf"
        name="lossRunFiles"
        (change)="onFilesSelected($event)"
        #fileInput
      />
    </button>
    <span class="upload-file-name">{{
      (form.value && form.value.fileName) || 'No file uploaded'
    }}</span>
  </div>
</ng-container>
