<app-left-navigation></app-left-navigation>
<div class="subpage-sidebar subpage-sidebar__settings">
  <div class="subpage-sidebar-header">
    <h3>Settings</h3>
  </div>
  <div class="subpage-sidebar-main">
    <ul>
      <li class="js-team-settings">
        <a
          class="subpage-sidebar-link"
          routerLinkActive="subpage-sidebar-link__selected"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/settings"
        >
          Team Settings
        </a>
      </li>
      <li *ngIf="canManageFinances()" class="js-financial-info">
        <a
          class="subpage-sidebar-link"
          routerLinkActive="subpage-sidebar-link__selected"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/settings/finances"
        >
          Financial Info
        </a>
      </li>
      <li class="js-commission-info">
        <a
          class="subpage-sidebar-link"
          routerLinkActive="subpage-sidebar-link__selected"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/settings/commission"
        >
          Commission
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="subpage-container">
  <router-outlet></router-outlet>
</div>
