<div class="quoting-game-container">
  <ng-container *ngIf="showProgressBar">
    <app-quoting-progress
      title="Binding quote"
      [comments]="[
        'Dotting the i\'s...',
        'Crossing the t\'s...',
        'Spell checking...',
        'Shaking hands...',
        'Preparing confetti...'
      ]"
      [secondsToMax]="10"
      [updateIntervalMs]="2000"
      [finish$]="finish$"
      [successComment]="successComment"
      successImage="assets/img/bound.png"
    ></app-quoting-progress>
  </ng-container>
  <canvas #canvas id="binding-animation"></canvas>
</div>
