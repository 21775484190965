import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InformService } from '../../../../core/services/inform.service';
import { UserService } from '../../../../core/services/user.service';
import { InvoicesListService } from '../../services/invoices-list.service';
import { SentryService } from '../../../../core/services/sentry.service';
import { switchMap } from 'rxjs/operators';

/**
 * This component serves to handle redirects to invoice list for logged-in users.
 * Often, we want to surface a link to the invoices list page, which requires a
 * token, even when
 */
@Component({
  selector: 'app-invoice-redirect-page',
  template: '<ng-container></ng-container>',
})
export class InvoiceRedirectPageComponent implements OnInit, OnDestroy {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private informService: InformService,
    private userService: UserService,
    private invoiceListService: InvoicesListService,
    private sentryService: SentryService
  ) {}

  accountId: string;

  private sub = new Subscription();

  handleRedirectError() {
    this.router.navigate(['accounts'], { replaceUrl: true });
    this.informService.errorToast(
      `We encountered an error loading invoices for this account.`,
      null,
      'Invoices not found.',
      'Okay',
      null,
      0
    );
    this.sentryService.notify('Error loading invoices when redirecting on account', {
      metaData: {
        accountId: this.accountId,
      },
    });
  }

  ngOnInit() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.accountId = params['accountId'];
            return this.userService.getUser();
          }),
          switchMap((user) => {
            return this.invoiceListService.getInvoiceList(
              user.producer,
              0,
              25,
              undefined,
              this.accountId,
              undefined
            );
          })
        )
        .subscribe(
          (invoiceList: BackendInvoiceSummary) => {
            const invoices: BackendListInvoice[] = invoiceList
              ? invoiceList.invoices.filter((invoice) => invoice.id && invoice.transactionId)
              : [];

            if (invoices.length) {
              this.router.navigate(['insured', this.accountId], {
                queryParams: {
                  invoicePid: invoices[0].id,
                  invoiceToken: invoices[0].transactionId,
                },
                replaceUrl: true,
              });
            } else {
              this.handleRedirectError();
            }
          },
          (err) => {
            this.handleRedirectError();
          }
        )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
