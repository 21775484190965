<section class="submission-summary">
  <header>
    <figure class="submission-summary-heading-logo">
      <img src="/assets/img/attune_logo_moire.svg" alt="Attune logo" />
    </figure>
    <h1 class="submission-summary-download-title h2">Businessowners' Policy Application</h1>
  </header>
  <section class="submission-summary-general-details">
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">Quote number</div>
      <div class="submission-summary-field-value js-quote-number">
        {{ quoteNumber }}
      </div>
    </div>
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">Quote Date</div>
      <div class="submission-summary-field-value js-exported-time">
        {{ createdAt }}
      </div>
    </div>
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">Exported</div>
      <div class="submission-summary-field-value js-exported-time">
        {{ downloadedAt }}
      </div>
    </div>
    <div class="submission-summary-data-row">
      <div class="submission-summary-field-label">PRODUCERS PLEASE TAKE NOTE:</div>
      <div class="submission-summary-field-value">
        This document allows you to review the selections you made in Attune's portal for this
        quote. For a full list of coverages/forms associated with this quote, please see the quote
        letter.
      </div>
    </div>
  </section>

  <section *ngIf="accountDetails">
    <div class="submission-summary-data-row" *ngFor="let field of accountDisplayLabels">
      <ng-container *ngIf="!field.hide">
        <div class="submission-summary-field-label">
          {{ field.displayName }}
        </div>
        <!-- IMPORTANT: Do not remove the js-account-detail-value class: service-document uses it to ensure that the data has loaded before rendering the pdf -->
        <div class="submission-summary-field-value js-account-detail-value">
          {{ getFormField(this.accountDetails, this.field.keyName, field.formatter) }}
        </div>
      </ng-container>
    </div>
  </section>

  <section *ngIf="formValue">
    <ng-container *ngFor="let fieldGroup of policyDisplayLabels">
      <div *ngIf="!fieldGroupIsHidden(fieldGroup)" class="submission-summary-field-group">
        <div class="submission-summary-data-row">
          <div class="submission-summary-field-label">
            <h2 class="submission-summary-section-heading h3">{{ fieldGroup.heading }}</h2>
          </div>
        </div>
        <div class="submission-summary-data-row" *ngFor="let field of fieldGroup.children">
          <ng-container *ngIf="!isHidden(field)">
            <div class="submission-summary-field-label">
              {{ field.displayName }}
            </div>
            <!-- IMPORTANT: Do not remove the js-policy-detail-value class: service-document uses it to ensure that the data has loaded before rendering the pdf -->
            <div class="submission-summary-field-value js-policy-detail-value">
              {{ getFormField(this.formValue, field.keyName, field.formatter) }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </section>
  <section *ngIf="excessPolicyDetails">
    <ng-container *ngFor="let fieldGroup of excessPolicyDisplayLabels">
      <div *ngIf="!fieldGroup.hide" class="submission-summary-field-group">
        <div class="submission-summary-data-row">
          <div class="submission-summary-field-label">
            <h2 class="submission-summary-section-heading h3">{{ fieldGroup.heading }}</h2>
          </div>
        </div>
        <div class="submission-summary-data-row" *ngFor="let field of fieldGroup.children">
          <ng-container *ngIf="!field.hide">
            <div class="submission-summary-field-label">
              {{ field.displayName }}
            </div>
            <!-- IMPORTANT: Do not remove the js-policy-detail-value class: service-document uses it to ensure that the data has loaded before rendering the pdf -->
            <div class="submission-summary-field-value js-policy-detail-value">
              {{ getFormField(this.excessPolicyDetails, field.keyName, field.formatter) }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </section>
</section>
