<ng-container *ngIf="policyTermTransaction">
  <app-dialog-box
    *ngIf="isNotTaken() || isNonRenewing()"
    type="warning"
    header="Not renewing"
    class="dialog-text__full-width"
  >
    <ng-container *ngIf="isNotTaken()">
      The insured has elected to <strong>not renew</strong> coverage.
    </ng-container>
    <ng-container *ngIf="isNonRenewing()">
      This policy is scheduled for <strong>non-renewal</strong> by the carrier.
    </ng-container>
  </app-dialog-box>
</ng-container>

<app-dialog-box
  *ngIf="isQuoted()"
  type="primary"
  header="Need to make a change?"
  class="dialog-text__full-width"
>
  Submit a request and we'll take care of it for you.
  <div class="dialog-text-links">
    <a [routerLink]="['/support', 'form', 'edit-renewal']" class="dialog-text-link">Request edit</a>
  </div>
</app-dialog-box>

<header class="policy-pane-header">
  <div class="policy-pane-header-left">
    <div
      class="product-badge"
      role="presentation"
      [ngClass]="{
        'product-badge__bop-plus': isBop(),
        'product-badge__wc': isAttuneWc()
      }"
    ></div>
    <div class="policy-pane-quote-info">
      <h2 class="policy-pane-header-h2">Renewal details</h2>
      <small *ngIf="!isNonRenewing()" class="gray-text"
        >No. {{ policyTermTransaction.PolicyNumber }}</small
      >
      <small *ngIf="isNonRenewing()" class="gray-text">N/A</small>
    </div>
  </div>
  <div class="policy-pane-header-right">
    <h2 *ngIf="!isNonRenewing()" class="policy-pane-header-h2 js-premium-amount">
      {{ getTransactionCost() | currency: '$':'symbol':'1.0-0' }}
    </h2>
    <h2 *ngIf="isNonRenewing()" class="policy-pane-header-h2 js-premium-amount">N/A</h2>
    <small class="gray-text">Premium</small>
  </div>
</header>

<div class="document-download-container">
  <app-document-download
    *ngIf="!isNonRenewing() && !isScheduled()"
    displayName="Renewal Quote Letter"
    [documentDownload$]="quoteLetterDownload$"
  ></app-document-download>
  <app-document-download
    *ngIf="isScheduled()"
    [displayName]="displayProductName()"
    [documentDownload$]="policyDocumentDownload$"
  ></app-document-download>
  <app-document-download
    *ngIf="this.isNonRenewing() && this.hasValidNonRenewReasons()"
    [displayName]="getOdenNoticeDisplayName()"
    [documentDownload$]="nonRenewDocumentDownload$"
    [documentReady$]="nonRenewDocumentDownloadReady$"
  ></app-document-download>
</div>

<ng-container *ngIf="isQuoted()" class="policy-pane-details">
  <h3 class="policy-pane-subheader">Important Dates</h3>
  <div class="policy-detail-subsection">
    <dl>
      <dt>Date policy is auto-issued</dt>
      <dd class="js-auto-issue-date">{{ getAutoIssueDate() | date: 'mediumDate':'UTC' }}</dd>
    </dl>
  </div>
</ng-container>

<ng-container class="policy-pane-details">
  <h3 class="policy-pane-subheader">Renewal information</h3>
  <div class="policy-detail-subsection">
    <dl>
      <ng-container *ngIf="!isNotTaken() && !isNonRenewing()">
        <dt>Effective Date</dt>
        <dd>{{ policyTermTransaction.EditEffectiveDate | date: 'mediumDate':'UTC' }}</dd>
      </ng-container>
      <dt>Policy status</dt>
      <dd *ngIf="!isNotTaken()" class="pill-tag pill-tag__{{ transactionClass() }}">
        {{ policyTermTransaction.TermDisplayStatus_ATN }}
      </dd>
      <dd *ngIf="isNotTaken()" class="pill-tag pill-tag__{{ transactionClass() }}">nottaken</dd>
      <ng-container *ngIf="isScheduled()">
        <dt>Payment Status</dt>
        <dd>{{ paymentStatus }}</dd>
      </ng-container>
    </dl>
  </div>
</ng-container>

<app-product-cross-sell
  [open]="
    (isScheduled() || isQuoted()) &&
    isCrossSellWindowEnabled &&
    isBrokerEnabledForCyber &&
    isAccountEligibleForCyber &&
    !doesAccountHaveCyberQuotes &&
    !doesAccountHaveCyberPolicy &&
    !crossSellDismissed
  "
  [config]="crossSellConfiguration"
  (handleClose)="closeCrossSell($event)"
>
</app-product-cross-sell>
<ng-container *ngIf="changeHistory?.length">
  <h3 class="policy-pane-subheader js-policy-change-history">Change History</h3>
  <ul *ngFor="let policyChange of changeHistory" class="link-list">
    <li>
      <app-document-download
        [displayName]="policyChange.displayName"
        [documentDownload$]="policyChange.download$"
        [documentReady$]="changeHistoryDownloadsReady$"
      ></app-document-download>
    </li>
  </ul>
</ng-container>
