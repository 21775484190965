import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserApiService } from '../../core/services/user-api-service';
import { UserService } from '../../core/services/user.service';

import { User } from '../models/user';

import { UserRole, OktaGroup } from 'app/constants';
import * as _ from 'lodash';
import { UserGroupInfo, UserInfo } from '../consumer/typings';
import { InsuredAccountService } from '../../features/insured-account/services/insured-account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProducerDetailsResponse, GwProducerCodeDetails } from 'app/bop/guidewire/typings';
import { validateEmailAddress } from 'app/features/attune-bop/models/form-validators';

const DEACTIVATED_USER_STATUSES = ['DEPROVISIONED', 'DEACTIVATED'];

// Temporary fix. Due to Okta rate limits, this page cannot perform adequately for producers with huge numbers of users.
// A longer term fix (pagination) will require a redesign of this page.
const MAXIMUM_USERS_FOR_MANAGEMENT = 60;

const isUserAdmin = (userData: UserGroupInfo | UserData | undefined) =>
  userData && userData.groups.includes(OktaGroup.USER_ADMIN);

@Component({
  selector: 'app-team-settings',
  templateUrl: 'team-settings.component.html',
})
export class TeamSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private userApiService: UserApiService,
    private userService: UserService,
    private insuredAccountService: InsuredAccountService
  ) {}
  private sub: Subscription = new Subscription();

  canceledInvites: Record<string, boolean> = {};

  userGroups: Record<string, UserGroupInfo>;
  userList: Record<string, UserInfo>;

  producerDetails: ProducerDetailsResponse;
  producerCodeDetails: GwProducerCodeDetails;

  editUserForm: UntypedFormGroup;
  showEditUserModal = false;
  editUserSubmitted = false;
  updatingUser = false;

  showForbiddenChangeModal = false;
  showDeactivatedUsers = false;

  inviteUserForm: UntypedFormGroup;
  showInviteUserModal = false;
  inviteUserSubmitted = false;
  inviteUserSending = false;

  loading = true;
  hasInviteNameCollision = false;

  currentUser: User;

  editingUser: UserData | null = null;

  userData: UserGroupInfo[] = [];

  // Disable advanced user management features (groups) for certain users
  disableManagement = false;

  roleOptions = UserRole;
  roleLabels = {
    [UserRole.Standard]:
      'Provides limited access to the account. Standard can quote, bind, and service accounts.',
    [UserRole.Admin]:
      'Provides full access to the account. Admin can quote, bind, service acounts, invite new users, and modify user roles.',
  };

  ngOnInit() {
    this.editUserForm = this.formBuilder.group({
      role: [UserRole.Standard, Validators.required],
    });
    this.inviteUserForm = this.formBuilder.group({
      agencyConfirmation: [null, [Validators.required, Validators.requiredTrue]],
      emailAddress: [null, [Validators.required, validateEmailAddress]],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      role: [UserRole.Standard, Validators.required],
    });

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.currentUser = user;
        this.sub.add(
          this.insuredAccountService
            .getProducerDetails(user.producer)
            .subscribe((producerDetails) => {
              this.producerDetails = producerDetails;
            })
        );

        this.sub.add(
          this.insuredAccountService
            .getProducerCodeDetails(user.producer)
            .subscribe((producerCodeDetails) => {
              this.producerCodeDetails = producerCodeDetails;
            })
        );
      })
    );

    this.loadUsersData();
  }

  getAgencyName() {
    return _.get(this.producerCodeDetails, 'AgencyEntity_HUSA.Name', '');
  }

  getProducerCode() {
    return this.currentUser.producer;
  }

  getInviteConfirmationText() {
    if (this.producerCodeDetails) {
      return `I confirm that the user I am inviting is an employee of my agency, ${this.getAgencyName()}, and should be added to my agency's producer code, ${this.getProducerCode()}`;
    }
    return `I confirm that the user I am inviting is an employee of my agency, and should be added to my agency's producer code.`;
  }

  loadUsersData() {
    this.sub.add(
      this.userApiService.getUsers().subscribe((users) => {
        this.userList = users.users.reduce((acc, item) => {
          const login = _.get(item, 'profile.login', '') as string;
          if (login) {
            acc[login] = item;
          }
          return acc;
        }, {} as Record<string, UserInfo>);
        const activeUsers = _.filter(this.userList, (userInfo) => !!userInfo.lastLogin);
        if (activeUsers.length > MAXIMUM_USERS_FOR_MANAGEMENT) {
          this.loading = false;
          this.disableManagement = true;

          this.userData = Object.values(this.userList)
            .filter((user: UserInfo) => {
              return !!(user && user.lastLogin);
            })
            .map((user: UserInfo) => {
              return { user: user.profile, groups: [] };
            });
        } else {
          this.loadGroupsData();
        }
        this.calculateUsers();
      })
    );
  }

  loadGroupsData() {
    this.sub.add(
      this.userApiService.getUserGroups().subscribe(
        (groups) => {
          this.userGroups = groups.result.reduce((acc, item) => {
            const login = _.get(item, 'user.login', '') as string;
            if (login) {
              acc[login] = item;
            }
            return acc;
          }, {} as Record<string, UserGroupInfo>);

          this.calculateUsers();
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
    );
  }

  calculateUsers() {
    this.userData = Object.values(this.userList)
      .filter((user) => !!user.lastLogin)
      .map((userInfo) => ({
        user: userInfo.profile,
        groups: this.userGroups?.[userInfo.profile.email]?.groups || [],
      }));
  }

  getUsers() {
    const deactivatedUsers = this.getDeactivatedUsers();
    return this.userData.filter((user) => {
      return !deactivatedUsers.some((deactivatedUser) => {
        return deactivatedUser.profile.login === user.user.login;
      });
    });
  }

  getDeactivatedUsers() {
    if (this.userList) {
      return Object.values(this.userList).filter((user) => {
        return DEACTIVATED_USER_STATUSES.includes(user.status);
      });
    }
    return [];
  }

  getInvitations() {
    if (this.userList) {
      return Object.values(this.userList).filter((user) => {
        return !user.lastLogin && !DEACTIVATED_USER_STATUSES.includes(user.status);
      });
    }
    return [];
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  canEdit() {
    if (!this.currentUser || this.disableManagement || !this.userGroups) {
      return false;
    }

    return isUserAdmin(this.userGroups[this.currentUser.userName]);
  }

  editUser(user: UserData) {
    this.editingUser = user;

    if (this.isCurrentUser(user) && this.getAdminCount() < 2) {
      this.showForbiddenChangeModal = true;
    } else {
      const isEditingAdmin = isUserAdmin(user);
      this.editUserForm.patchValue({ role: isEditingAdmin ? UserRole.Admin : UserRole.Standard });
      this.showEditUserModal = true;
      this.editUserSubmitted = false;
      this.updatingUser = false;
    }
  }

  getAdminCount() {
    return this.getUsers().reduce((acc, userData) => {
      if (isUserAdmin(userData)) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }

  updateUser() {
    this.editUserSubmitted = true;

    if (!this.editUserForm.valid) {
      return;
    }
    this.updatingUser = true;
    const targetUser = this.editingUser as UserData;

    const isEditingAdmin = isUserAdmin(targetUser);
    if (this.editUserForm.value.role === UserRole.Admin && !isEditingAdmin) {
      this.sub.add(
        this.userApiService.addAdminStatus(targetUser.user.login).subscribe(() => {
          this.showEditUserModal = false;
          targetUser.groups.push(OktaGroup.USER_ADMIN);
          this.loadUsersData();
        })
      );
    } else if (this.editUserForm.value.role === UserRole.Standard && isEditingAdmin) {
      this.sub.add(
        this.userApiService.removeAdminStatus(targetUser.user.login).subscribe(() => {
          this.showEditUserModal = false;
          targetUser.groups.splice(
            targetUser.groups.findIndex((group) => group === OktaGroup.USER_ADMIN),
            1
          );
          this.loadUsersData();
        })
      );
    } else {
      this.showEditUserModal = false;
    }
  }

  isCurrentUser(item: UserData) {
    return this.currentUser && this.currentUser.userName === item.user.login;
  }

  isCurrentUserAdmin() {
    if (!this.currentUser || !this.userGroups) {
      return false;
    }

    return isUserAdmin(this.userGroups[this.currentUser.userName]);
  }

  getRole(item: any) {
    if (isUserAdmin(item)) {
      return 'Admin';
    } else if (item?.groups?.length) {
      return 'Standard';
    }
    return '';
  }

  canInvite() {
    if (!this.currentUser || (!this.userGroups && !this.disableManagement)) {
      return false;
    }
    if (this.disableManagement) {
      return true;
    }

    return isUserAdmin(this.userGroups[this.currentUser.userName]);
  }

  inviteUser() {
    this.inviteUserSubmitted = false;
    this.inviteUserSending = false;

    this.showInviteUserModal = true;
  }

  toggleDeactivatedUsers() {
    this.showDeactivatedUsers = !this.showDeactivatedUsers;
  }

  closeEditUserModal() {
    this.showEditUserModal = false;
  }

  closeInviteUserModal() {
    this.showInviteUserModal = false;
  }

  closeForbiddenChangeModal() {
    this.showForbiddenChangeModal = false;
  }

  cancelInvite(login: string) {
    this.sub.add(
      this.userService.cancelInvite(login).subscribe(() => {
        // We must store the cancellation in this list, because it will not immediately appear in the Okta API
        this.canceledInvites[login] = true;
      })
    );
  }

  submitInvite() {
    this.inviteUserSubmitted = true;

    if (!this.inviteUserForm.valid || !this.producerDetails) {
      return;
    }
    this.hasInviteNameCollision = false;
    this.inviteUserSending = true;

    const invitePayload = {
      email: this.inviteUserForm.value.emailAddress,
      firstName: this.inviteUserForm.value.firstName,
      lastName: this.inviteUserForm.value.lastName,
      role: this.inviteUserForm.value.role,
      agentGroup: this.producerDetails.AgentGroup,
      organization: this.producerDetails.Organization,
    };

    this.sub.add(
      this.userService.inviteUser(invitePayload).subscribe(
        () => {
          this.showInviteUserModal = false;
          this.inviteUserSending = false;

          this.inviteUserForm.patchValue({
            emailAddress: null,
            firstName: null,
            lastName: null,
          });
          this.loadUsersData();
        },
        (err: HttpErrorResponse) => {
          this.inviteUserSending = false;
          if (err.status === 409) {
            this.hasInviteNameCollision = true;
          }
        }
      )
    );
  }
}
