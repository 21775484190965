import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import { FileUploadService } from 'app/shared/services/file-upload.service';
import { catchError, switchMap } from 'rxjs/operators';
import * as URLParse from 'url-parse';

const BYTES_IN_MB = 1024 * 1024;
const FILE_SIZE_LIMIT = BYTES_IN_MB * 100;
const niceFileSize = (size: number): string => {
  if (size > BYTES_IN_MB) {
    return String(Math.ceil(size / BYTES_IN_MB)) + 'MB';
  }
  if (size > 1024) {
    return String(Math.ceil(size / 1024)) + 'KB';
  }
  return String(size) + 'B';
};

@Component({
  selector: 'app-form-field-file',
  templateUrl: './form-field-file.component.html',
})
export class FormFieldFileComponent {
  @Input() inputId: string;
  @Input() labelText: string;
  @Input() tooltipText: string | null;
  @Input() specifierText = '';
  @Input() cssClass: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() nameOfFormControl: string;
  @Input() accept = '.pdf,.doc,.docx,image/*';
  @Input() fileMetadata: { [k: string]: string } = {};

  getControl = getControl;
  droppingClass = false;

  constructor(
    private fileUploadService: FileUploadService,
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService
  ) {}

  fieldInvalid(): boolean | undefined {
    return shouldShowInvalid(this.nameOfFormControl, this.form, this.submitted);
  }

  getFiles(): FileUpload[] {
    return getControl(this.form, this.nameOfFormControl).value as FileUpload[];
  }

  onDragOver(event: Event) {
    // Prevent file from being opened in the current browser tab
    event.stopPropagation();
    event.preventDefault();
  }

  onDrop(event: Event) {
    this.droppingClass = false;
    event.preventDefault();
    event.stopPropagation();
    this.onFilesSelected(event);
  }

  onFilesSelected(event: Event) {
    const filesControl = this.form.get(this.nameOfFormControl) as UntypedFormArray;
    if (!event.target) {
      return;
    }

    // Event can either be a dropped file or files-selected Event on inputs
    const files = ((event as any).target.files || (event as any).dataTransfer.files) as File[];

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // TODO(Olex): test files for isDirectory
        if (file.size && file.size > FILE_SIZE_LIMIT) {
          filesControl.push(
            new UntypedFormControl({
              name: file.name,
              size: niceFileSize(file.size),
              status: 'error',
              message: 'File is too large. Max limit is 100 MB.',
            })
          );
          continue;
        }

        const fControl = new UntypedFormControl({
          name: file.name,
          size: niceFileSize(file.size),
          status: 'loading',
          s3url: null,
          // We are adding the s3 key + bucket to make it easier to save a reference to this file in the orch DB.
          s3Key: null,
          s3Bucket: null,
        });
        filesControl.push(fControl);
        this.amplitudeService.track({
          eventName: 'file_upload_attempt',
          detail: file.name,
          useLegacyEventName: true,
        });

        this.uploadFile(fControl, file);
      }
    }
  }

  onFileRemoved(index: number) {
    const filesControl = this.form.get(this.nameOfFormControl) as UntypedFormArray;
    filesControl.removeAt(index);
  }

  dragHandler(onOff: boolean) {
    if (this.droppingClass !== onOff) {
      this.droppingClass = onOff;
    }
    return false;
  }

  private uploadFile(fileControl: UntypedFormControl, file: File) {
    let s3url = '';
    let s3Key = '';
    let s3Bucket = '';
    const mimeType = file.type || 'application/octet-stream';

    if (!file.type) {
      this.sentryService.notify(`Browser / OS provided no mimeType for file`, {
        severity: 'info',
        metaData: {
          mimeTypeUsed: mimeType,
          fileName: file.name,
          fileType: file.type,
        },
      });
    }

    this.fileUploadService
      .getUploadLink(mimeType, this.getFileEnding(file.name), this.fileMetadata)
      .pipe(
        catchError((error) => {
          fileControl.patchValue({
            ...fileControl.value,
            status: 'error',
            s3url: null,
          });
          this.amplitudeService.track({
            eventName: 'file_upload_error',
            detail: 'link_not_generated',
            useLegacyEventName: true,
          });
          throw error;
        }),
        switchMap((uploadLinkData) => {
          const uploadLink = uploadLinkData.s3Link;
          const parsedUrl = new URLParse(uploadLink);

          s3url = parsedUrl.origin + parsedUrl.pathname;
          s3Key = uploadLinkData.s3Key;
          s3Bucket = uploadLinkData.s3Bucket;

          return this.fileUploadService.uploadToS3(uploadLink, file, mimeType);
        })
      )
      .subscribe(
        (_response: any) => {
          fileControl.patchValue({
            ...fileControl.value,
            s3url: s3url,
            s3Key: s3Key,
            s3Bucket: s3Bucket,
            status: 'done',
          });
          this.amplitudeService.track({
            eventName: 'file_upload_success',
            detail: file.name,
            useLegacyEventName: true,
          });
        },
        (error: any) => {
          fileControl.patchValue({
            ...fileControl.value,
            status: 'error',
          });
          this.amplitudeService.track({
            eventName: 'file_upload_error',
            detail: 's3_upload_failure',
            useLegacyEventName: true,
          });
          throw error;
        }
      );
  }

  private getFileEnding(fileName: string): string {
    return (fileName.split('.').length > 1 ? fileName.split('.').pop() : '') || '';
  }
}
