<ng-container *ngIf="isEligible">
  <h3 class="h3">
    Earn your agency an additional $100 for each policy bound (on up to three binds) before
    {{ incentiveEndDate }}.
    <span class="rewards-terms">
      <a href="https://www.attuneinsurance.com/legal/new-user-rewards-2023" target="_blank"
        >Terms & conditions</a
      >
    </span>
  </h3>
  <div class="rewards-progress">
    <div
      *ngFor="let x of [].constructor(MAX_BIND_REWARDS_FOR_NEW_USERS); let i = index"
      class="rewards-progress-card"
    >
      <ng-container *ngIf="i + 1 > totalBinds">
        <img class="rewards-chest" src="/assets/img/rewards-chest-with-star.svg" alt="" />
        <div class="rewards-progress-card-content">
          <p class="p p__bold p__no-margin">$100 for bind #{{ i + 1 }}</p>
          <div>
            <span
              class="pill-tag pill-tag__neutral"
              style="display: flex; align-items: center; text-transform: none"
            >
              <img class="rewards-progress-lock" src="/assets/img/rewards-lock-locked.svg" alt="" />
              Locked
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="i + 1 <= totalBinds">
        <img class="rewards-chest" src="/assets/img/rewards-chest-open-with-star.svg" alt="" />
        <div class="rewards-progress-card-content">
          <p class="p p__bold p__no-margin">$100 for bind #{{ i + 1 }}</p>
          <div>
            <span
              class="pill-tag pill-tag__neutral"
              style="display: flex; align-items: center; text-transform: none"
            >
              <img
                class="rewards-progress-lock"
                src="/assets/img/rewards-lock-unlocked.svg"
                alt=""
              />
              Earned
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
