import { of as observableOf } from 'rxjs';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { shouldShowInvalid } from 'app/shared/helpers/form-helpers';
import { WcFormEmployeeClassificationCode } from 'app/workers-comp/employers/models/wc-policy';
import { AmplitudeService } from 'app/core/services/amplitude.service';

const INITIAL_CODES_LIMIT = 250;
const SEARCH_RESULTS_LIMIT = 100;

@Component({
  selector: 'app-wc-classification',
  templateUrl: './wc-classification.component.html',
})
export class WcClassificationComponent {
  @Input() employeeClassification: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() prefix: string;
  @Input() classCodeOptions: WcFormEmployeeClassificationCode[] = [];
  @Input() fuse: any;
  @ViewChild('typeahead')
  typeahead: ElementRef;

  constructor(private amplitudeService: AmplitudeService) {}

  triggerAmplitudeOnClassCodeSelect(classCode: WcFormEmployeeClassificationCode) {
    this.amplitudeService.track({
      eventName: 'wc_classification_code',
      detail: JSON.stringify(classCode),
      useLegacyEventName: true,
    });
  }

  mapCodeToLabel(classCode: WcFormEmployeeClassificationCode | null): string {
    if (!classCode || (classCode && (!classCode.classCode || !classCode.description))) {
      return '';
    }
    // This is used for analytics, for rendering, see the resultTemplate
    return `${classCode.classCode}-${classCode.classSeq} - ${classCode.description}`;
  }

  searchCodes = (query: string) => {
    /* TODO(Olex) Maaaybe? switch to ReplaySubject
    return this.fuse$.pipe(
      map((fuse: any) => {
        const results = fuse.search(query);
    */
    if (!this.fuse) {
      return;
    }
    const results = this.fuse.search(query);

    if (!query) {
      return observableOf(this.classCodeOptions.slice(0, INITIAL_CODES_LIMIT));
    }

    if (results.length > 0) {
      return observableOf(this.sortFirstMatchingClass(results));
    } else {
      return observableOf([]);
    }
  };

  /**
   * When searching for something common like '8810'
   * this block sorts the first matching class (8810)
   * so quoteable, lowest-seq-number codes come first.
   */
  sortFirstMatchingClass(results: WcFormEmployeeClassificationCode[]) {
    const nextCodeIdx = _.findIndex(results, (code) => {
      return code.classCode !== results[0].classCode;
    });

    return _.orderBy(
      results.slice(0, nextCodeIdx),
      ['quoteable', 'classSeq'],
      ['desc', 'asc']
    ).concat(results.slice(nextCodeIdx, SEARCH_RESULTS_LIMIT));
  }

  classificationFieldInvalid() {
    const invalid = shouldShowInvalid(
      'codeDescription',
      this.employeeClassification,
      this.submitted
    );
    return invalid;
  }
}
