import { Injectable } from '@angular/core';
import { ALL_CARRIERS } from 'app/features/attune-bop/models/constants-carriers';
import { filter, sortBy, take } from 'lodash';

@Injectable()
export class CarrierService {
  lookup(queryStr: string, limit: number = 20) {
    queryStr = queryStr.toLowerCase();

    let found = filter(ALL_CARRIERS, (c) => c.name.toLowerCase().indexOf(queryStr) !== -1);
    found = sortBy(found, (c) => c.name);
    return take(found, limit);
  }

  isValidCarrier(publicId: number): boolean {
    const found = filter(ALL_CARRIERS, (c) => c.publicId === publicId);
    return found.length ? true : false;
  }
}
