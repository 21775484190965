<p
  #container
  role="alert"
  class="toast"
  [ngClass]="{
    toast__achievement: toast.display.type === 'ACHIEVEMENT',
    toast__redemption: toast.display.type === 'REDEMPTION'
  }"
>
  <span *ngIf="toast.display.type !== 'REDEMPTION'; then reward; else redemption"></span>

  <ng-template #reward>
    <span *ngIf="toast.display.type === 'ACHIEVEMENT'" class="toast__tag">{{
      toast.display.type
    }}</span>
    {{ toast.display.message }}
    <span class="toast__points coin-spin">
      {{ toast.value }}
      <span class="sr-only">points earned</span>
    </span>
    <app-shimmer-animation
      *ngIf="toast.display.type === 'ACHIEVEMENT'"
      [parentContainer$]="animationContainer"
    ></app-shimmer-animation>
  </ng-template>

  <ng-template #redemption>
    <img class="toast__image" src="{{ getRewardIcon(toast.name) }}" alt="" />
    <span class="toast__message">
      {{ toast.display.message }}
      <span class="toast__message--note"> Check your inbox to claim your reward for quoting! </span>
    </span>
  </ng-template>
</p>
