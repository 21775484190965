<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__bop"
  product="hab"
  pasSource="attune"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insAccount$.getValue()"
  sidebarTitle="New quote"
  [fillInIneligibleLocation]="populateIneligibleLocation.bind(this)"
  [fillInHappyPath]="populateKnownGoodHappyPathValues.bind(this)"
></app-quote-stepped-sidebar>

<div class="app-page-form-scrollable-wrapper app-page-form-with-sidebar-wrapper">
  <div class="app-page-form" content>
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>
    <main *ngIf="isLoadingAccount" class="placeholder-form-container">
      <div class="placeholder-h1"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-submit"></div>
    </main>
    <ng-container *ngIf="!isLoadingAccount">
      <form autocomplete="off" [formGroup]="form" (ngSubmit)="handleSubmit($event)" novalidate>
        <router-outlet></router-outlet>

        <div class="nav-button-group nav-button-group__left">
          <button
            *ngIf="!isFinalStep()"
            id="hab-quote-form-next-button"
            [ngClass]="{
              button: true,
              'js-step-forward-button': true,
              button__discouraged: !this.isCurrentStepValid()
            }"
            [disabled]="waitingForEligibilityCheck"
            type="submit"
            class="button__primary"
          >
            <span>Next</span>
          </button>
          <button
            *ngIf="isFinalStep()"
            id="hab-quote-form-submit-button"
            [ngClass]="{
              button: true,
              'js-step-forward-button': true,
              button__discouraged: !this.isCurrentStepValid()
            }"
            type="submit"
            class="button__primary"
            [disabled]="isQuoteInProgress"
          >
            <span *ngIf="!isQuoteInProgress">Get quote</span>
            <span *ngIf="isQuoteInProgress">Submitting...</span>
          </button>
        </div>

        <ng-container *ngIf="isDevMode">
          <div class="adminz-only">
            <pre>{{ form.value | json }}</pre>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </div>
  <div class="app-page-form-side-rail">
    <app-hab-sectioned-guidelines *ngIf="showEligibilityCriteriaRail()">
      <p>The following risks are <strong>ineligible:</strong></p>
    </app-hab-sectioned-guidelines>
  </div>
</div>

<app-quote-error-modal
  [open]="shouldDisplayQuoteErrorModal"
  (closeQuoteErrorModal)="closeQuoteErrorModal($event)"
  [errors]="errors"
  [errorType]="errorType"
></app-quote-error-modal>

<app-modal
  id="hab-quote-form-eligibility-loading-modal"
  [open]="waitingForEligibilityCheck"
  *ngIf="waitingForEligibilityCheck"
  [disableClose]="true"
>
  <h2>Just a moment</h2>
  <p>We need to check our data models.</p>
  <app-progress-bar
    [startPercent]="5"
    [secondsToMax]="10"
    [updateIntervalMs]="1000"
  ></app-progress-bar>
</app-modal>

<div class="quoting-game-container" *ngIf="quoting">
  <app-quoting-game [finish$]="quotingSuccess$"></app-quoting-game>
</div>
