<ng-container>
  <header class="bind-quote-header">
    <h1 class="form-header-below-link">Payment details</h1>
  </header>
  <div class="helper-card">
    <p class="p__bold">Wondering how we bill?</p>
    <p>
      We've got answers. Check out our
      <a
        href="https://attunehelp.zendesk.com/hc/en-us/sections/360001532851-Billing"
        target="_blank"
        >billing FAQ page</a
      >
      to learn more.
    </p>
  </div>
  <div class="bind-flow-subsection">
    <ng-container *ngIf="paymentDetails">
      <app-form-field-radio
        inputId="paymentPlan"
        [form]="form"
        [submitted]="submitted"
        questionText="Payment plan"
        [options]="paymentPlanOptions"
        cssClass="js-bind-payment-details"
        nameOfFormControl="paymentPlan"
        [disabledOptions]="disabledPaymentPlanOptions"
        [questionNote]="paymentPlanQuestionNote"
      >
      </app-form-field-radio>
      <div *ngIf="paymentDetails.installments > 0">
        <h5 class="payment-subtitle">Down Payment</h5>
        <dl class="dl__no-dotted-line">
          <dt>Premium</dt>
          <dd class="js-payment-details-downPayment">
            {{ paymentDetails.downPaymentPremium | currency }}
          </dd>
        </dl>

        <dl class="dl__no-dotted-line">
          <dt>Tax</dt>
          <dd class="js-payment-details-downPayment-tax">
            {{ paymentDetails.totalTaxes | currency }}
          </dd>
        </dl>
        <dl class="dl__subtotal dl__no-dotted-line">
          <dt>Subtotal</dt>
          <dd>
            <strong class="js-payment-details-downPayment-subtotal">{{
              paymentDetails.downPaymentSubtotal | currency
            }}</strong>
          </dd>
        </dl>
        <h5 class="payment-subtitle">Installments x {{ paymentDetails.installments }}</h5>
        <dl class="dl__no-dotted-line">
          <dt>Installment premium</dt>
          <dd class="js-payment-details-installment">
            {{ paymentDetails.installmentPremium | currency }}
          </dd>
        </dl>
        <dl class="dl__no-dotted-line">
          <dt>Installment fee</dt>
          <dd class="js-installment-fee installment-fee-installment-payment">
            {{ paymentDetails.installmentFeeAfterDownPayment | currency }}
          </dd>
        </dl>
        <dl class="dl__subtotal dl__no-dotted-line">
          <dt>Subtotal</dt>
          <dd>
            <strong class="js-wc-payment-details-downPayment-subtotal">{{
              paymentDetails.installmentTotal * paymentDetails.installments | currency
            }}</strong>
          </dd>
        </dl>
      </div>
      <div *ngIf="paymentDetails.installments === 0">
        <dl class="dl__no-dotted-line">
          <dt>Premium</dt>
          <dd class="js-payment-details-premium">
            {{ paymentDetails.downPaymentPremium | currency }}
          </dd>
        </dl>

        <dl class="dl__no-dotted-line">
          <dt>Tax</dt>
          <dd class="js-payment-details-tax">
            {{ paymentDetails.totalTaxes | currency }}
          </dd>
        </dl>
      </div>
      <div class="bind-quote-total">
        <div class="bind-quote-total-lineitem">
          <strong>Estimated total cost*</strong>
          <div class="big-total js-payment-details-total">
            <span>$</span>{{ paymentDetails.totalDollars | number: '2.0-0' }}.<span
              class="big-total-cents"
              >{{ paymentDetails.totalCents | number: '2.0-0' }}</span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
