<ng-container *ngIf="getControl(form, nameOfFormControl).enabled">
  <div
    class="{{ cssClass }} form-field"
    [ngClass]="{ 'form-field__error': fieldInvalid(nameOfFormControl) }"
  >
    <label [for]="inputId">
      {{ questionText }}
      <div
        *ngIf="tooltipText"
        class="more-info-tip tool-tip tool-tip__large"
        attr.data-tooltip="{{ tooltipText }}"
      >
        <span class="icon icon-question"></span>
      </div>
      <ng-container *ngIf="additionalQuestionText">
        <br />
        <span [innerHTML]="additionalQuestionText"></span>
      </ng-container>
    </label>
    <div class="radio-question {{ questionCssClass }}" [id]="inputId" role="radiogroup">
      <div
        *ngFor="let optionKey of getOptions() | keys"
        class="radio-question-option {{ optionsCssClass }}"
        [ngClass]="{ 'radio-question-option__disabled': shouldDisableOption(optionKey) }"
      >
        <input
          type="radio"
          [formControl]="form.get(nameOfFormControl)"
          [id]="getSanitizedOptionId(optionKey)"
          [name]="inputId"
          [value]="getOptions()[optionKey]"
          [attr.aria-checked]="isSelected(optionKey)"
          [attr.disabled]="shouldDisableOption(optionKey) ? true : null"
        />
        <label
          [for]="getSanitizedOptionId(optionKey)"
          class="radio-question-label {{
            shouldDisableOption(optionKey) ? optionsLabelCssClass : null
          }}"
          (click)="clickOption(getOptions()[optionKey])"
          (keyup.enter)="clickOption(getOptions()[optionKey])"
        >
          {{ optionKey }}
          <span
            class="pill-tag pill-tag__{{ getOptionLabels()[optionKey].type }}"
            *ngIf="getOptionLabels()[optionKey]"
          >
            {{ getOptionLabels()[optionKey].label }}
          </span>
        </label>
      </div>
    </div>
    <ng-content></ng-content>
    <p class="form-field-note" *ngIf="questionNote">{{ questionNote }}</p>
  </div>
</ng-container>
