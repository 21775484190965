// Libraries
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';
// Components

export interface ImageRadioQuestionOption {
  displayName: string;
  imageUrl: string;
}

@Component({
  selector: 'app-form-field-radio-image',
  templateUrl: './form-field-radio-image.component.html',
})
export class FormFieldRadioImageComponent {
  @Input() inputId: string;
  @Input() additionalQuestionText: string;
  @Input() questionText: string;
  @Input() nameOfFormControl: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() cssClass: string;
  @Input() optionsCssClass: string;
  @Input() questionCssClass: string;
  @Input() tooltipText: string;
  @Input() options: { [key: string]: ImageRadioQuestionOption };
  @Input() disabledOptions: string[];

  getControl = getControl;

  fieldInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.form, this.submitted);
  }

  shouldDisableOption(optionKey: string): boolean {
    if (!this.disabledOptions) {
      return false;
    }
    for (let i = 0; i < this.disabledOptions.length; i++) {
      if (this.disabledOptions[i] === optionKey) {
        return true;
      }
    }
    return false;
  }
}
