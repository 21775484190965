import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { HabBindQuoteService } from 'app/hab/services/hab-bind-quote.service';
import { User } from 'app/shared/models/user';
import { SentryService } from 'app/core/services/sentry.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { UserService } from 'app/core/services/user.service';

import { InsuredAccount } from '../../../features/insured-account/models/insured-account.model';

@Component({
  selector: 'app-hab-bind-loss-runs-page',
  templateUrl: './hab-bind-loss-runs-page.component.html',
})
export class HabBindLossRunsPageComponent implements OnDestroy {
  public form: UntypedFormGroup;
  public account: InsuredAccount;
  public user: User;
  public quoteId: string;
  private formPath = 'lossRuns';
  private sub = new Subscription();

  constructor(
    private formService: HabBindQuoteService,
    private insuredAccountService: InsuredAccountService,
    private route: ActivatedRoute,
    private userService: UserService,
    private sentryService: SentryService
  ) {
    if (this.route.snapshot.parent === null || this.route.snapshot.parent === undefined) {
      this.sentryService.notify(
        'Unable to initialize hab loss runs bind component. Expected form to have a parent',
        {
          severity: 'error',
        }
      );
      throw new Error('Expected hab loss runs bind form to have a parent');
    }

    this.quoteId = this.route.snapshot.parent.params.quoteId;

    this.sub.add(
      this.userService.getUser().subscribe((user: User) => {
        this.user = user;
      })
    );

    this.sub.add(
      this.insuredAccountService.insuredSubject.subscribe((account: InsuredAccount) => {
        this.account = account;
      })
    );

    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
    if (!this.form) {
      throw new Error(`formPath (${this.formPath}) not found`);
    }
  }

  public hasMultipleLossRuns(): boolean {
    return this.formService.getLossRunFormArray().length > 1;
  }

  public addLossRun(): void {
    this.formService.addLossRun();
  }

  public removeLossRun(index: number): void {
    this.formService.removeLossRun(index);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  hasSubmitted() {
    return this.formService.submitted;
  }
}
