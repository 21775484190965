// Libraries
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
// Constants
import {
  AVAILABLE_SPOILAGE_LIMITS,
  AVAILABLE_SPOILAGE_LIMITS_RESTAURANTS,
} from 'app/features/attune-bop/models/constants';
// Helpers
import { classificationIsRestaurant } from 'app/features/attune-bop/models/classification-helpers';

@Component({
  selector: 'app-spoilage-coverage',
  templateUrl: './spoilage-coverage.component.html',
})
export class SpoilageCoverageComponent implements OnInit {
  @Input() coverage: UntypedFormGroup;
  @Input() exposure: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() prefix: string;

  public spoilageEnabled = false;
  public availableSpoilageLimits: number[] = AVAILABLE_SPOILAGE_LIMITS;

  constructor() {}

  ngOnInit() {
    const classificationHolder: BuildingClassificationHolder = (<UntypedFormGroup>(
      this.exposure.get('classification')
    )).value;
    const spoilageControl = this.coverage.get('spoilage') as UntypedFormControl;
    this.spoilageEnabled = spoilageControl ? spoilageControl.enabled : false;

    if (this.spoilageEnabled && classificationHolder) {
      const isRestaurantClassification = classificationIsRestaurant(classificationHolder.code);
      this.availableSpoilageLimits = isRestaurantClassification
        ? AVAILABLE_SPOILAGE_LIMITS_RESTAURANTS
        : AVAILABLE_SPOILAGE_LIMITS;

      const spoilageLimit: number = Number(spoilageControl.value);
      if (!this.availableSpoilageLimits.includes(spoilageLimit)) {
        spoilageControl.patchValue(this.availableSpoilageLimits[0]);
      }
    }
  }
}
