import * as _ from 'lodash';
import {
  AVAILABLE_LIQUOR_CLASS_CODES,
  SpoilageClass,
  BYOB_LIQUOR_CLASS_CODES,
  PIZZA_SHOP_CLASSIFICATION_CODE,
  DEFAULT_LIQUOR_CLASS_CODES,
  SpoilageDescription,
} from 'app/features/attune-bop/models/constants';
import {
  AttuneBopBuildingClassificationService,
  getSpoilageClass,
  hasRestrictedCoverage,
} from 'app/features/attune-bop/services/attune-bop-building-classification.service';

const getBuildingClassificationsForLocation = (
  location: BopLocation
): BuildingClassificationHolder[] => {
  return location.buildings.reduce(
    (classifications: BuildingClassificationHolder[], building: BopBuilding) => {
      return classifications.concat(building.exposure.classification);
    },
    []
  );
};

export const getBuildingClassificationsForLocations = (
  locations: BopLocation[]
): BuildingClassificationHolder[] => {
  return locations.reduce(
    (classifications: BuildingClassificationHolder[], location: BopLocation) =>
      classifications.concat(getBuildingClassificationsForLocation(location)),
    []
  );
};

export const canClassificationHavePizza = (classification: BuildingClassification): boolean => {
  return (classification && classification.code === PIZZA_SHOP_CLASSIFICATION_CODE) === true;
};

export const canClassificationsHavePizza = (
  classifications: BuildingClassificationHolder[]
): boolean => {
  return _.some(classifications, (classification) => {
    return classification.code ? canClassificationHavePizza(classification.code) : false;
  });
};

export const classificationCanHaveAlcohol = (
  classification: BuildingClassification | null
): boolean => {
  const code = classification && classification.code;

  if (!code) {
    return false;
  }

  return _.includes(AVAILABLE_LIQUOR_CLASS_CODES, code);
};

export const classificationServesAlcohol = (
  classification: BuildingClassification | null
): boolean => {
  const code = classification && classification.code;

  if (!code) {
    return false;
  }

  return _.includes(DEFAULT_LIQUOR_CLASS_CODES, code);
};

export const classificationHasByobAlcohol = (
  classification: BuildingClassification | null
): boolean => {
  const code = classification && classification.code;

  if (!code) {
    return false;
  }

  return _.includes(BYOB_LIQUOR_CLASS_CODES, code);
};

export const classificationsHaveBeverageStore = (
  classification: BuildingClassification | null
): boolean => {
  const code = classification && classification.code;

  if (!code) {
    return false;
  }

  return '59215' === code;
};

export const classificationsHaveLiquor = (
  classifications: BuildingClassificationHolder[],
  defaultsToYes: boolean = false
): boolean => {
  return _.some(classifications, (classification) => {
    if (classification.code) {
      if (defaultsToYes) {
        return (
          classificationServesAlcohol(classification.code) ||
          classificationHasByobAlcohol(classification.code)
        );
      } else {
        return (
          classificationCanHaveAlcohol(classification.code) ||
          classificationServesAlcohol(classification.code) ||
          classificationHasByobAlcohol(classification.code)
        );
      }
    }
    return false;
  });
};
export const classificationHasSpoilageCoverage = (
  classification: BuildingClassification | null
): boolean => {
  return classification ? hasRestrictedCoverage(classification.descriptionCode, 'Spoilage') : false;
};

export const classificationIsRestaurant = (
  classification: BuildingClassification | null
): boolean => {
  return classification
    ? _.includes(AttuneBopBuildingClassificationService.restaurantCodes, classification.code)
    : false;
};

export const classificationCodeStringFrom = (
  classification: BuildingClassificationHolder
): string => {
  const validCodeObjects = _.filter(
    [classification],
    (classificationHolder: BuildingClassificationHolder) =>
      _.isObjectLike(classificationHolder.code)
  );
  const codeString = _.map(_.map(validCodeObjects, 'code'), 'code');
  return <string>codeString[0];
};

export const spoilageClassificationDescription = (
  classCode: BuildingClassification | null
): SpoilageDescription | null => {
  if (!classCode) {
    return null;
  }

  const spoilageClass: SpoilageClass | null = getSpoilageClass(classCode.descriptionCode);
  if (!spoilageClass) {
    return null;
  }

  let perishableDesc = '';
  switch (spoilageClass) {
    case 'BakeryGoods':
    case 'DairyProductsexcludingIceCream':
    case 'Delicatessens':
    case 'MeatandPoultryMarkets':
    case 'Other':
    case 'Restaurants':
    case 'Seafood':
      perishableDesc =
        "All ingredients and food requiring refrigeration as used in the course of the insured's business operations.";
      break;
    case 'Florists':
      perishableDesc =
        "All flowers requiring refrigeration as used in the course of the insured's business operations.";
      break;
    case 'FruitsandVegetables':
      perishableDesc =
        "All fruits and vegetables requiring refrigeration as used in the course of the insured's business operations.";
      break;
    case 'Pharmaceuticals':
      perishableDesc =
        "All medicines and pharmaceuticals requiring refrigeration as used in the course of the insured's business operations.";
      break;
  }

  return {
    spoilageClass,
    perishableDesc,
  };
};

export const locationsHaveLroBuildings = (
  bopLocations: QSLocation[] | BopLocation[] | undefined
) => {
  if (!bopLocations) {
    return false;
  }

  return bopLocations.some((location: BopLocation | QSLocation) => {
    return location.buildings.some((building: BopBuilding | QSBuilding) => {
      return building.exposure.lessorsRisk;
    });
  });
};
