import { UntypedFormGroup, UntypedFormControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { getControl, getFormGroup, zipCodeInState } from 'app/shared/helpers/form-helpers';
import {
  COB_EXCEPTIONS_BY_STATE_V4,
  SMALL_CONTRACTOR_WITH_1M_LOI_IN_ALL_STATES_NAMES,
} from './hiscox-constants';
import { HiscoxFormStepPath, HISCOX_PRODUCTS } from './hiscox-types';
import { parseMoney } from 'app/shared/helpers/number-format-helpers';
import { HiscoxGlFormDataFieldV4 } from './gl-constants';
import { HiscoxPlFormDataFieldV4 } from './pl-constants';

const SMALL_CONTRACTOR_LOI_LIMIT = 1_000_000;

export const classOfBusinessExceptionsByStateValidator = (product: HISCOX_PRODUCTS) => {
  return (group: UntypedFormGroup) => {
    const classOfBusinessControl: UntypedFormControl = group.get(
      'BusinessInfo_ClassOfBusinessCd'
    ) as UntypedFormControl;
    const cobCode: string = classOfBusinessControl.value;
    const stateOrProvCdControl: UntypedFormControl = group.get(
      'BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd'
    ) as UntypedFormControl;
    const state: string = stateOrProvCdControl.value;

    if (!cobCode || !state) {
      return null;
    }

    const cobExceptionsForStateByProduct: string[] = _.keys(
      COB_EXCEPTIONS_BY_STATE_V4[state][product]
    );
    if (cobExceptionsForStateByProduct.includes(cobCode)) {
      return {
        cobStateComboNotEligible: true,
      };
    }
    return null;
  };
};

export const zipMatchesStateValidator = () => {
  return (group: UntypedFormGroup) => {
    const stateOrProvCdControl = group.get(
      HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD
    ) as UntypedFormControl;
    const state = stateOrProvCdControl.value;

    const zipControl = group.get(
      HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE
    ) as UntypedFormControl;
    const zip = zipControl.value;

    if (!zip || !state) {
      return null;
    }

    if (zipCodeInState(zip, state)) {
      return null;
    }

    return {
      [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_STATEORPROVCD]: {},
      [HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE]: {
        validationMessage: `Zip code must be in ${state}.`,
      },
      zipCodeNotInState: {
        value: zip,
        validationMessage: `Zip code must be in ${state}.`,
      },
    };
  };
};

export const validateMaxSelectionsInCheckboxGroup = (
  max: number,
  validationMessage: string = `Please select a maximum of ${max} options.`
) => {
  return (group: UntypedFormGroup) => {
    const options: Record<string, boolean> = group.value;

    if (options) {
      const checkedCount = Object.values(options).filter((val) => !!val).length;
      if (checkedCount > max) {
        return {
          maxOptionsExceeded: {
            value: checkedCount,
            validationMessage,
          },
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
};

export const validateAggregateLimitGreateThanPerOccurrence = (
  { perOccurrenceLimitControlName }: { perOccurrenceLimitControlName: string } = {
    perOccurrenceLimitControlName:
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI,
  }
): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const perOccurrenceControl = getControl(form, perOccurrenceLimitControlName);

    const aggregateLimitControl = getControl(
      form,
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_AGGLOI
    );

    if (
      !perOccurrenceControl ||
      !aggregateLimitControl ||
      !perOccurrenceControl.value ||
      !aggregateLimitControl.value ||
      parseMoney(perOccurrenceControl.value) <= parseMoney(aggregateLimitControl.value)
    ) {
      return null;
    }

    return {
      aggregateLimitGreateThanPerOccurrence: {
        value: perOccurrenceControl.value,
        validationMessage: 'Per occurrence cannot be greater than aggregate limit.',
      },
    };
  };
};

export const validateProfesssionalLiabilityRetroactiveDateAgainstBusinessStartDate: ValidatorFn = (
  form: UntypedFormGroup
) => {
  const businessStartDateControl = getControl(
    form,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_PROFESSIONALEXPERIENCE
  );

  const plRetroactiveDateControl = getControl(
    form,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PROFESSIONALLIABILITYRETROACTIVEDATE
  );

  if (!businessStartDateControl?.value || !plRetroactiveDateControl?.value) {
    return null;
  }

  const retroDateIsAfterBusinessStartDate = moment(plRetroactiveDateControl.value).isSameOrAfter(
    moment(businessStartDateControl.value)
  );

  if (retroDateIsAfterBusinessStartDate) {
    return null;
  }

  return {
    [HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_PROFESSIONALLIABILITYRETROACTIVEDATE]:
      {
        value: plRetroactiveDateControl.value,
        validationMessage:
          'Professional Liability retroactive date cannot be before the business start date',
      },
  };
};

/**
 * Note: This function takes in the entire form (both form steps) because it requires
 * controls from a previous step to validate.
 *
 * @param form The entire form, including both form steps
 * @returns a validator function that validates an individual control (i.e. retroactive date)
 */
export const validateProfesssionalLiabilityRetroactiveDateAgainstEffectiveDate = (
  form: UntypedFormGroup
): ValidatorFn => {
  const effectiveDateControl = getControl(
    form,
    `${HiscoxFormStepPath.QUOTE_BASIC}.${HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_COVERAGESTARTDATE}`
  );

  return (plRetroactiveDateControl: UntypedFormControl) => {
    if (!effectiveDateControl?.value || !plRetroactiveDateControl?.value) {
      return null;
    }

    const retroDateIsBeforeEffectiveDate = moment(plRetroactiveDateControl.value).isSameOrBefore(
      moment(effectiveDateControl.value)
    );

    if (retroDateIsBeforeEffectiveDate) {
      return null;
    }

    return {
      plRetroactiveDateIsBeforeBusinessStartDate: {
        value: plRetroactiveDateControl.value,
        validationMessage:
          'Professional Liability retroactive date cannot be after the policy effective date',
      },
    };
  };
};

export const validateNumberOfEmployees = (employeeCountControl: UntypedFormControl) => {
  if (
    !employeeCountControl.enabled ||
    employeeCountControl.value === null ||
    Number(employeeCountControl.value) < 1000
  ) {
    return null;
  }

  return {
    maxEmployeeCountExceeded: {
      value: employeeCountControl.value,
      validationMessage: 'Please enter the number of employees (must be less than 1000)',
    },
  };
};

export const validateNumberOfOwners = (ownerCountControl: UntypedFormControl) => {
  if (
    !ownerCountControl.enabled ||
    ownerCountControl.value === null ||
    (Number(ownerCountControl.value) > 0 && Number(ownerCountControl.value) < 100)
  ) {
    return null;
  }

  return {
    maxOwnerCountExceeded: {
      value: ownerCountControl.value,
      validationMessage: 'Please enter the number of owners (must be between 1 and 100)',
    },
  };
};

export const validateLargestProjectValueIsSmallerOrEqualToTotalRevenue = (): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const totalRevenueControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE
    );

    const largestProjectControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTPROJECT
    );

    if (
      !totalRevenueControl ||
      !totalRevenueControl.value ||
      !largestProjectControl ||
      !largestProjectControl.value ||
      parseMoney(largestProjectControl.value) <= parseMoney(totalRevenueControl.value)
    ) {
      return null;
    }

    return {
      largestProjectGreaterThanTotalRevenue: {
        value: largestProjectControl.value,
        validationMessage: 'Gross revenue from largest project cannot exceed total revenue.',
      },
    };
  };
};

export const validateLargestCustomer1ValueIsSmallerOrEqualToTotalRevenue = (): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const totalRevenueControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE
    );

    const largestCustomer1Control = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR1
    );

    if (
      !totalRevenueControl ||
      !totalRevenueControl.value ||
      !largestCustomer1Control ||
      !largestCustomer1Control.value ||
      parseMoney(largestCustomer1Control.value) <= parseMoney(totalRevenueControl.value)
    ) {
      return null;
    }

    return {
      largestProjectGreaterThanTotalRevenue: {
        value: largestCustomer1Control.value,
        validationMessage: 'Gross revenue from largest customer cannot exceed total revenue.',
      },
    };
  };
};

export const validateLargestCustomerValueIsSmallerOrEqualToTotalCommissions = (): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const totalCommissionsControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTMTDANNUALCOMMISSIONS
    );

    const largestCustomerControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR
    );

    if (
      !largestCustomerControl ||
      !largestCustomerControl.value ||
      parseMoney(largestCustomerControl.value) <= parseMoney(totalCommissionsControl.value)
    ) {
      return null;
    }

    return {
      largestProjectGreaterThanTotalRevenue: {
        value: largestCustomerControl.value,
        validationMessage: 'Gross revenue from largest customer cannot exceed total commissions.',
      },
    };
  };
};

export const validateSubcontractProfSrvcsAtLeastOneSubQuestionSelectedYes = (): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const profServicesControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTPROFSRVCS
    );
    const subDesignControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTDESIGN
    );
    const subRepairControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTREPAIR
    );
    const subOtherControl = getControl(
      form,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_APPLICATIONRATINGINFO_SUBCONTRACTOTHERSRVCS
    );
    if (
      profServicesControl?.value !== 'Yes' ||
      _.some(
        [subDesignControl, subRepairControl, subOtherControl],
        (control) => control?.value === 'Yes'
      )
    ) {
      return null;
    }

    return {
      SubcontractProfSrvcsAtLeastOneSubQuestionSelectedYes: {
        validationMessage: 'Must select at least one subcontract professional service.',
      },
    };
  };
};

export const validateAtLeastOneYesInsurancePlacementSrvsAnswer = (
  quoteRatingGroup: UntypedFormGroup
) => {
  const lifeAccidentorHealthControl = getControl(
    quoteRatingGroup,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVS_LIFEACCIDENTORHEALTH
  );

  const commercialLinesControl = getControl(
    quoteRatingGroup,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVS_COMMERCIALLINES
  );

  const personalLinesControl = getControl(
    quoteRatingGroup,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVS_PERSONALLINES
  );

  const otherLinesControl = getControl(
    quoteRatingGroup,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_INSURANCEPLACEMENTSRVS_OTHER
  );

  if (
    !lifeAccidentorHealthControl?.enabled &&
    !commercialLinesControl?.enabled &&
    !personalLinesControl?.enabled &&
    !otherLinesControl?.enabled
  ) {
    return null;
  }

  if (
    lifeAccidentorHealthControl.value === 'No' &&
    commercialLinesControl.value === 'No' &&
    personalLinesControl.value === 'No' &&
    otherLinesControl.value === 'No'
  ) {
    return {
      InsurancePlacementSrvsYesAnswerError: {
        validationMessage:
          'You must answer yes to at least one insurance placement services option.',
      },
    };
  }
};

export const validateNumericQuestionAggregates = (
  fields: HiscoxPlFormDataFieldV4[]
): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const enabledControls: (UntypedFormControl | null)[] = fields
      .map((field) => {
        return getControl(form, field);
      })
      .filter((control) => !!control?.enabled);
    if (!enabledControls.length) {
      return null;
    }
    if (_.sumBy(enabledControls, (control) => parseInt(control?.value, 10) || 0) === 100) {
      return null;
    }

    return {
      InsurancePlacementServicesTotal100: {
        validationMessage: 'Total of insurance placement services percentages must equal 100.',
      },
    };
  };
};

export const validateExclusiveCheckboxOption = (exclusiveOption: string): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    const checkboxWithoutExclusive = _.omit(form.value, [exclusiveOption]);
    const otherBoxesValues = _.values(checkboxWithoutExclusive);
    const anyOtherBoxesChecked = _.some(otherBoxesValues);
    if (!form.value[exclusiveOption] || !anyOtherBoxesChecked) {
      return null;
    }
    return {
      valueNotSelectedExclusively: {
        value: form.value,
        validationMessage: '"None of the above" cannot be selected with other options.',
      },
    };
  };
};

export const validateRequiredCheckboxOption = (
  requiredOption: string,
  optionText: string
): ValidatorFn => {
  return (form: UntypedFormGroup) => {
    if (form.value[requiredOption]) {
      return null;
    }
    return {
      valueNotSelectedExclusively: {
        value: form.value,
        validationMessage: `"${optionText}" must be selected for this class code`,
      },
    };
  };
};

export const validateHnoaLimitNotGreaterThanGlLoi: ValidatorFn = (
  quoteRatingGroup: UntypedFormGroup
) => {
  const hnoaLimitControl = getControl(
    quoteRatingGroup,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_HIRENONOWNEDAUTOCOVERQUOTERQ_RATINGINFO_LOI
  );

  if (hnoaLimitControl?.enabled && hnoaLimitControl.value) {
    const glLoiControl = getControl(
      quoteRatingGroup,
      HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI
    );
    const hnoaLoiIsGreaterThanGlLoi = Number(hnoaLimitControl.value) > Number(glLoiControl.value);

    if (hnoaLoiIsGreaterThanGlLoi) {
      return {
        hnoaLoiIsGreaterThanGlLoi: {
          hnoaLoi: hnoaLimitControl.value,
          glLoi: glLoiControl.value,
          validationMessage:
            'HNOA occurrence limit cannot be greater than policy occurrence limit.',
        },
      };
    }
  }

  return null;
};

/**
 * Per "Hiscox NOW Underwriting Manual API v1.6 (Mustang).pdf", some Small contractos (SC), such as
 * handyman, have a per occurrence limit of up to 1 million for all states.
 * If the secondary COB is one of these COBs then the overall Occurrence LOI limit is reduced to
 * the $1M limit set by this secondary SC COB.
 *
 * @param quoteRatingGroup
 * @returns validation error | null
 */
export const validatePrimaryCobLoiLessThanEqualSecondaryCobLoiLimit = (
  quoteRatingGroup: UntypedFormGroup
) => {
  const perOccurrenceLimitControl = getControl(
    quoteRatingGroup,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_LOI
  );

  const secondaryCobControl = getFormGroup(
    quoteRatingGroup,
    HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_RATINGINFO_SECONDARYCOBSMALLCONTRACTORS_CLASSOFBUSINESSCD
  );

  if (
    secondaryCobControl &&
    perOccurrenceLimitControl &&
    secondaryCobControl?.enabled &&
    perOccurrenceLimitControl?.enabled &&
    secondaryCobControl.value &&
    perOccurrenceLimitControl.value
  ) {
    const haSomeLoiLimitingSecondaryCob = Object.entries(secondaryCobControl.controls).some(
      ([controlName, _control]) => {
        return SMALL_CONTRACTOR_WITH_1M_LOI_IN_ALL_STATES_NAMES.includes(controlName);
      }
    );

    const perOccurrenceLoiValue = parseMoney(perOccurrenceLimitControl.value);

    if (haSomeLoiLimitingSecondaryCob && perOccurrenceLoiValue > SMALL_CONTRACTOR_LOI_LIMIT) {
      return {
        secondaryCobRestrictsMaxLoi: {
          validationMessage:
            "Service provided by business requires a max 'Occurrence limit' of $1M",
        },
      };
    }
  }

  return null;
};

export const validateEstSalesLargestCustmr1NotGreaterThanAnnualRevenue: ValidatorFn = (
  quoteRatingGroup: UntypedFormGroup
) => {
  const estimatedAnnualRevenueControl = getControl(
    quoteRatingGroup,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE
  );

  if (estimatedAnnualRevenueControl?.enabled && estimatedAnnualRevenueControl.value) {
    const estSalesLargestCustmr1Control = getControl(
      quoteRatingGroup,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR1
    );
    const estSalesLargestCustmr1ThanGlAnnualRev =
      Number(estSalesLargestCustmr1Control.value) > Number(estimatedAnnualRevenueControl.value);

    if (estSalesLargestCustmr1ThanGlAnnualRev) {
      return {
        estSalesLargestCustmr1ThanGlAnnualRev: {
          estSalesLargestCustmr1: estSalesLargestCustmr1Control.value,
          estimatedAnnualRevenue: estimatedAnnualRevenueControl.value,
          validationMessage: 'Estimated largest customer sales cannot exceed annual revenue.',
        },
      };
    }
  }

  return null;
};

export const validateEstSalesLargestCustmrNotGreaterThanAnnualRevenue: ValidatorFn = (
  quoteRatingGroup: UntypedFormGroup
) => {
  const estimatedAnnualRevenueControl = getControl(
    quoteRatingGroup,
    HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_LOCATIONS_PRIMARY_ADDRINFO_RATINGINFO_ESTIMATEDANNUALREVENUE
  );

  if (estimatedAnnualRevenueControl?.enabled && estimatedAnnualRevenueControl.value) {
    const estSalesLargestCustmrControl = getControl(
      quoteRatingGroup,
      HiscoxPlFormDataFieldV4.PRODUCTQUOTERQS_PROFESSIONALLIABILITYQUOTERQ_RATINGINFO_ESTSALESLARGESTCUSTMR
    );
    const estSalesLargestCustmr1ThanGlAnnualRev =
      Number(estSalesLargestCustmrControl.value) > Number(estimatedAnnualRevenueControl.value);

    if (estSalesLargestCustmr1ThanGlAnnualRev) {
      return {
        estSalesLargestCustmr1ThanGlAnnualRev: {
          estSalesLargestCustmr1: estSalesLargestCustmrControl.value,
          estimatedAnnualRevenue: estimatedAnnualRevenueControl.value,
          validationMessage: 'Estimated largest customer sales cannot exceed annual revenue.',
        },
      };
    }
  }

  return null;
};
