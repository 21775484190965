import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';

@Component({
  selector: 'app-hab-guidelines-page',
  templateUrl: './hab-guidelines-page.component.html',
})
export class HabGuidelinesPageComponent {
  form: UntypedFormGroup;
  formPath = 'guidelines';

  constructor(protected formService: HabQuoteFormService) {
    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
    if (!this.form) {
      throw new Error(`formPath (${this.formPath}) not found`);
    }
  }

  public isFirstStep(): boolean {
    return this.formService.isFirstStep();
  }

  hasSubmitted() {
    return this.formService.submitted;
  }
}
