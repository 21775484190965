import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'app/shared/shared.module';

import { AdditionalInsuredsComponent } from 'app/shared/quote-form/additional-insureds.component';
import { FormFieldSelectApiComponent } from 'app/shared/form-dsl/components/form-field-select-api/form-field-select-api.component';
import { BuildingExposureFormComponent } from 'app/shared/quote-form/building-exposure-form.component';
import { BuildingLessorsRiskFormComponent } from 'app/shared/quote-form/building-lessors-risk-form.component';
import { CatastropheCoveragesComponent } from 'app/shared/quote-form/catastrophe-coverages.component';
import { AttuneBopCyberLiabilityCoverageComponent } from 'app/features/attune-bop/components/attune-bop-cyber-liability-coverage/attune-bop-cyber-liability-coverage.component';
import { DebrisRemovalCoverageComponent } from 'app/shared/quote-form/debris-removal-coverage.component';
import { EmployeeBenefitsLiabilityCoverageComponent } from 'app/shared/quote-form/employee-benefits-liability-coverage.component';
import { ExcessLiabilityComponent } from 'app/shared/quote-form/excess-liability.component';
import { LocationFormComponent } from 'app/shared/quote-form/location-form.component';
import { SpoilageCoverageComponent } from 'app/shared/quote-form/spoilage-coverage.component';
import { UWQuestionsComponent } from 'app/shared/quote-form/uw-questions.component';
import { UtilityServicesCoverageComponent } from 'app/shared/quote-form/utility-services-coverage.component';
import { OrdinanceLawCoverageComponent } from 'app/shared/quote-form/ordinance-law-coverage.component';
import { WaiverOfSubrogationComponent } from './waiver-of-subrogation.component';
import { AttuneBopQuestionsUwEligibilityComponent } from 'app/features/attune-bop/components/attune-bop-questions-uw-eligibility/attune-bop-questions-uw-eligibility.component';
import { AttuneBopQuestionsEmploymentPracticesLiabilityComponent } from 'app/features/attune-bop/components/attune-bop-questions-employment-practices-liability/attune-bop-questions-employment-practices-liability.component';

@NgModule({
  declarations: [
    AdditionalInsuredsComponent,
    FormFieldSelectApiComponent,
    BuildingExposureFormComponent,
    BuildingLessorsRiskFormComponent,
    CatastropheCoveragesComponent,
    AttuneBopCyberLiabilityCoverageComponent,
    DebrisRemovalCoverageComponent,
    AttuneBopQuestionsUwEligibilityComponent,
    EmployeeBenefitsLiabilityCoverageComponent,
    AttuneBopQuestionsEmploymentPracticesLiabilityComponent,
    ExcessLiabilityComponent,
    LocationFormComponent,
    SpoilageCoverageComponent,
    UWQuestionsComponent,
    UtilityServicesCoverageComponent,
    OrdinanceLawCoverageComponent,
    WaiverOfSubrogationComponent,
  ],
  exports: [
    AdditionalInsuredsComponent,
    FormFieldSelectApiComponent,
    BuildingExposureFormComponent,
    BuildingLessorsRiskFormComponent,
    CatastropheCoveragesComponent,
    AttuneBopCyberLiabilityCoverageComponent,
    DebrisRemovalCoverageComponent,
    AttuneBopQuestionsUwEligibilityComponent,
    EmployeeBenefitsLiabilityCoverageComponent,
    AttuneBopQuestionsEmploymentPracticesLiabilityComponent,
    ExcessLiabilityComponent,
    LocationFormComponent,
    SpoilageCoverageComponent,
    UWQuestionsComponent,
    UtilityServicesCoverageComponent,
    OrdinanceLawCoverageComponent,
    WaiverOfSubrogationComponent,
  ],
  imports: [CommonModule, FormsModule, NgbModule, ReactiveFormsModule, SharedModule],
})
export class QuoteFormModule {}
