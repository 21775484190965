import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { GWBindService } from 'app/shared/services/gw-bind.service';
import { HabBindQuoteService } from 'app/hab/services/hab-bind-quote.service';
import { getControl } from 'app/shared/helpers/form-helpers';
import { SentryService } from 'app/core/services/sentry.service';

@Component({
  selector: 'app-hab-bind-page',
  templateUrl: './hab-bind-policy-details.component.html',
})
export class HabBindPolicyDetailsComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  formPath = 'policyDetails';
  contact: BackendContact;
  isPastEffDateValid: boolean;
  originalEffectiveDate: moment.Moment;
  validationMessageDateAfter: string;
  sub: Subscription;

  constructor(
    public bindService: GWBindService,
    protected formService: HabBindQuoteService,
    private route: ActivatedRoute,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.form = <UntypedFormGroup>this.formService.form.get(this.formPath);
    if (!this.form) {
      this.sentryService.notify(
        'Strange error.  Unable to find policyDetails form component in hab bind quote form service',
        {
          severity: 'error',
        }
      );
      throw new Error(`formPath (${this.formPath}) not found`);
    }
    if (this.route.parent === null) {
      this.sentryService.notify(
        'Unable to initialize hab bind policy details component.  Expected form to have a parent',
        {
          severity: 'error',
        }
      );
      throw new Error('Expected hab bind policy details form to have a parent');
    }
    this.validationMessageDateAfter =
      'Please enter a date between today and three months from today.';

    this.sub = this.formService.policyDetails$.subscribe((policyDetails: QuoteDetails) => {
      this.contact = policyDetails.contact;
      this.originalEffectiveDate = moment.utc(policyDetails.policyStart);

      const today: moment.Moment = moment.utc().startOf('day');
      const fiveDaysAgo: moment.Moment = moment.utc().subtract(5, 'days').startOf('day');
      const isBeforeFiveDaysAgo: boolean = moment
        .utc(this.originalEffectiveDate)
        .isBefore(fiveDaysAgo);
      const isBeforeToday: boolean = moment.utc(this.originalEffectiveDate).isBefore(today);

      this.isPastEffDateValid = !isBeforeFiveDaysAgo && isBeforeToday;
    });
  }

  get effectiveDate() {
    return getControl(this.form, 'effectiveDate');
  }

  hasSubmitted() {
    return this.formService.submitted;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getEffectiveDateValidationMessage() {
    return this.formService.getEffectiveDateValidationMessage();
  }
}
