import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AttuneBlogItem, SupportNewsfeedService } from '../../services/support-newsfeed.service';

@Component({
  selector: 'app-support-newsfeed-page',
  templateUrl: './support-newsfeed-page.component.html',
})
export class SupportNewsfeedPageComponent implements OnInit {
  newsfeed: AttuneBlogItem[];
  constructor(private supportNewsfeedService: SupportNewsfeedService) {}

  ngOnInit() {
    this.supportNewsfeedService.getNewsfeed().subscribe((response) => {
      this.newsfeed = response.feed.items.slice(0, 12);
    });
  }

  formatDate(isoDateString: string) {
    return moment(isoDateString).format('MMMM d, YYYY');
  }
}
