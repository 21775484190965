import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { InformService } from 'app/core/services/inform.service';
import { BehaviorSubject, of as observableOf, Observable } from 'rxjs';
import { tap, catchError, switchMap, filter } from 'rxjs/operators';
import { SentryService } from 'app/core/services/sentry.service';
import { SEND_LOSS_RUNS_EMAIL_URI } from 'app/constants';
import { API_V3_HAB_QUOTES_URI } from 'app/hab/constants';

interface FileSenderConfig {
  successMessage: string;
  failureMessage: string;
  sentryMessage: string;
  data: FormData;
  fileName: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(
    private http: HttpClient,
    private informService: InformService,
    private sentryService: SentryService
  ) {}

  public uploadedFileNameStatus$Map: { [key: string]: BehaviorSubject<boolean> } = {};

  public setupFileStatusObserver(fileName: string): BehaviorSubject<boolean> {
    if (this.uploadedFileNameStatus$Map[fileName] !== undefined) {
      this.uploadedFileNameStatus$Map[fileName].unsubscribe();
    }

    this.uploadedFileNameStatus$Map[fileName] = new BehaviorSubject<boolean>(false);

    return this.uploadedFileNameStatus$Map[fileName];
  }

  public teardownFileStatusObserver(fileName: string): void {
    if (this.uploadedFileNameStatus$Map[fileName] !== undefined) {
      this.uploadedFileNameStatus$Map[fileName].unsubscribe();
      delete this.uploadedFileNameStatus$Map[fileName];
    }
  }

  sendEmail(data: FormData, fileName: string): Observable<boolean> {
    return this.baseFileSender(SEND_LOSS_RUNS_EMAIL_URI, {
      successMessage: 'Thank you for your submission!',
      failureMessage: 'Upload failed: please try again',
      sentryMessage: 'Unable to send loss run email with attachment.',
      fileName,
      data,
    });
  }

  sendHabLossRun(quoteId: string, data: FormData, fileName: string): Observable<boolean> {
    const url = `${API_V3_HAB_QUOTES_URI}/${quoteId}/loss-runs`;
    const sentryMessage = `Unable to send loss run email for hab quote ${quoteId}.`;

    return this.baseFileSender(url, {
      successMessage: 'Successfully received your uploaded loss run!',
      failureMessage:
        'Please try your upload again. If this continues, contact our customer care team at help@attuneinsurance.com for assistance.',
      sentryMessage: sentryMessage,
      fileName,
      data,
    });
  }

  private baseFileSender(url: string, config: FileSenderConfig): Observable<boolean> {
    const data = config.data;
    // Warning: Filename is passed in separately from the FormData
    // because IE 11 does not support the FormData.has method
    if (!config.fileName) {
      this.sentryService.notify('Attempted to upload without a file.', {
        severity: 'error',
        metaData: {
          fileName: config.fileName,
        },
      });

      this.informService.errorToast(config.failureMessage);
      return observableOf(false);
    }

    return this.http
      .post(url, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        tap((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.setFileUploading(config.fileName, true);
              break;
            case HttpEventType.Response:
              this.setFileUploading(config.fileName, false);
              this.informService.successIconToast(config.successMessage, 'success_knockout');
              break;
          }
        }),
        filter((event) => {
          return event.type === HttpEventType.Response;
        }),
        switchMap((event: HttpResponse<{ status: number }>) => {
          return observableOf(event.status === 200);
        }),
        catchError((errorEvent) => {
          this.sentryService.notify(config.sentryMessage, {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: `${errorEvent.error.message}: ${errorEvent.message}`,
              underlyingError: errorEvent,
            },
          });
          this.setFileUploading(config.fileName, false);
          this.informService.errorToast(`${errorEvent.error.message}. ${config.failureMessage}`);
          return observableOf(false);
        })
      );
  }

  setFileUploading(fileName: string, uploading: boolean) {
    if (this.uploadedFileNameStatus$Map[fileName] !== undefined) {
      this.uploadedFileNameStatus$Map[fileName].next(uploading);
    }
  }
}
