import { Component, OnInit, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { AttuneBopEndorseQuoteFormService } from '../../services/attune-bop-endorse-quote-form.service';
import { BOP_ENDORSEMENT_AI_FAQS, BOP_ENDORSEMENT_GENERAL_FAQS } from '../../models/constants';

@Component({
  selector: 'app-attune-bop-endorse-faqs-panel',
  templateUrl: './attune-bop-endorse-faqs-panel.component.html',
})
export class AttuneBopEndorseFaqsPanelComponent implements OnInit, OnDestroy {
  public faqs: (Faq & { isToggled?: boolean })[];
  private sub: Subscription = new Subscription();

  constructor(public formService: AttuneBopEndorseQuoteFormService) {}

  ngOnInit() {
    this.sub.add(
      this.formService.currentStep$.subscribe((step) => {
        if (step && step.slug) {
          this.setFaqs(step.slug);
        }
      })
    );
  }

  setFaqs(slug: string) {
    if (slug === 'endorsement-requests' || slug === 'additional-insureds') {
      this.faqs = cloneDeep(BOP_ENDORSEMENT_AI_FAQS);
    } else {
      this.faqs = cloneDeep(BOP_ENDORSEMENT_GENERAL_FAQS);
    }
  }

  // Call this method in the ngOnInit subscription to auto-toggle open an FAQ for a given form step.
  toggleOnFaq(faq: Faq) {
    const faqToToggle = this.faqs.find((f) => f.question === faq.question);
    if (faqToToggle) {
      faqToToggle.isToggled = true;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
