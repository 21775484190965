// Libraries
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Component } from '@angular/core';
// Services
import { BopQuotePayload, BopQuoteFormValue } from 'app/features/attune-bop/models/bop-policy';
import { getAddress } from 'app/shared/helpers/form-helpers';

// Parent Component
import { AttuneBopQuoteNewFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-new-form-page/attune-bop-quote-new-form-page.component';

// ONLY EXTEND, DO NOT USE DIRECTLY (Angular does not allow for an abstract to extend a concrete :( )
@Component({ template: '' })
export class AttuneBopQuoteEditFormComponent extends AttuneBopQuoteNewFormPageComponent {
  handleTranslationError(err: Error) {
    console.error(err);
  }

  patchForm(
    bopFormInputs: BopQuotePayload | DeepPartial<BopQuoteFormValue> | null,
    cachePrefill = true,
    excessFormInput?: BopQuotePayload['excessLiability'] | null
  ) {
    this.formService.patchForm(bopFormInputs, excessFormInput);

    this.updateIsEffectiveDateWarned();

    // Initialize Prefill
    if (cachePrefill) {
      this.locationsFormArray().controls.forEach((loc: AbstractControl, index) => {
        this.prefillAddressMap.set(
          index,
          getAddress((<UntypedFormGroup>loc.get('locationDetails')).value)
        );
      });
    }
    this.loading = false;
  }
}
