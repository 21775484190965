import {
  HttpClient,
  HttpDownloadProgressEvent,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';
import { API_V3_BASE } from '../../../constants';
import { SentryService } from '../../../core/services/sentry.service';

const isDownloadProgressEvent = (event: HttpEvent<any>): event is HttpDownloadProgressEvent => {
  return event.type === HttpEventType.DownloadProgress;
};

export interface AiCheckerResult {
  relevantFacts: string[];
  reasons?: string[];
  conditions: string[];
  answer: string[];
  mayBeEligible: boolean;
  hasStructuredResponse: boolean;
  rawResult: string;
}

@Injectable({
  providedIn: 'root',
})
export class AiCheckerService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  fetchAiSearch(payload: { queryStr: string }): Observable<AiCheckerResult> {
    return this.http
      .post(`${API_V3_BASE}/bop/ai-eligibility-stream`, payload, {
        responseType: 'text',
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        filter((chunk: HttpEvent<string>) => {
          return isDownloadProgressEvent(chunk) && !!chunk.partialText;
        }),
        map((chunk: HttpDownloadProgressEvent) => {
          return this.parseEligibilityString(chunk.partialText as string);
        }),
        catchError((error) => {
          const errorType = error instanceof HttpErrorResponse ? 'HTTP' : 'runtime';

          this.sentryService.notify(`AI eligibility checker ${errorType} error`, {
            severity: 'error',
            metaData: {
              queryStr: payload.queryStr,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  parseEligibilityString(partialText: string): AiCheckerResult {
    const headings = ['Relevant Facts', 'Reasoning', 'Conditions', 'Answer'] as const;

    const sections = partialText.split('\n\n');
    let hasStructuredResponse = false;
    const [relevantFacts, reasons, conditions, answer] = headings.map((heading) => {
      const textWithHeading = sections.find((section) => section.startsWith(heading));
      if (!textWithHeading) {
        return null;
      }
      hasStructuredResponse = true;
      const text: string = textWithHeading.slice(`${heading}:\n`.length);
      // Ignore '', '-', and '- ' strings
      if (/^(-|- )?$/.test(text)) {
        return [];
      }
      const listStartPattern = /^\n?- /;
      const listItemPattern = /\n?- /;
      return listStartPattern.test(text) ? text.split(listItemPattern).slice(1) : [text];
    });

    const mayBeEligible = answer?.length === 1 && !/^No\b/.test(answer[0]);

    return {
      relevantFacts,
      reasons,
      conditions,
      answer,
      mayBeEligible,
      hasStructuredResponse,
      rawResult: partialText,
    } as AiCheckerResult;
  }
}
