import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SteppedFormPageComponent } from 'app/shared/stepped-form/stepped-form-page.component';

import {
  enableFeedbackForm,
  createDependentFormSubscriptions,
  FEEDBACK_LABEL_MAP,
  QuoteFeedbackForm,
} from 'app/shared/helpers/quote-feedback-helpers';
import { Subscription } from 'rxjs';
import { FORM_SERVICE_TOKEN } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { WcQuoteFormService } from 'app/workers-comp/employers/services/workers-comp-form.service';

type FormServicesWithFeedbackForm = WcQuoteFormService;

@Component({
  selector: 'app-quote-feedback-form',
  templateUrl: './quote-feedback-form.component.html',
})
export class QuoteFeedbackFormComponent
  extends SteppedFormPageComponent<FormServicesWithFeedbackForm, QuoteFeedbackForm>
  implements OnInit, OnDestroy
{
  formPath = 'quoteFeedback';
  subscription = new Subscription();
  feedbackLabelMap = FEEDBACK_LABEL_MAP;

  // Allows us to inject different implementations of form service at runtime.
  constructor(@Inject(FORM_SERVICE_TOKEN) protected formService: FormServicesWithFeedbackForm) {
    super(formService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // If in the future we'd like to selectively enable questions, they can be passed from the formService to
    // the enableFeedbackForm() function as the second arg.
    enableFeedbackForm(this.form);
    createDependentFormSubscriptions(this.form, this.subscription);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // For use w/ Map and keyvalue pipe to preserve order of entries.
  keepMapOrder(_a: any, _b: any) {
    return 0;
  }
}
