<app-modal
  [open]="open"
  (handleModalClose)="closeModal()"
  modalClass="app-modal-content__left-align help-center-marketing-modal"
>
  <div class="help-center-modal-title">
    <span class="pill-tag pill-tag__main-inverted pill-tag__margin">News</span>
    <h2 class="help-center-modal-header">New way to get in touch (faster than email!)</h2>
  </div>
  <div>
    <p class="p p__gray app-modal-content__left-align help-center-modal-text">
      Our new
      <a
        (click)="closeModalAndNavigate()"
        (keyup.enter)="closeModalAndNavigate()"
        attr.data-amplitude-input-name="account_help_center_modal"
        >help center</a
      >
      makes it easier to get in touch with us, but more importantly it helps us provide you with
      faster service. Start here if you need to:
    </p>
    <ul class="bulleted-list bulleted-list__tight help-center-modal-list">
      <li>Process <strong>endorsements</strong> and policy changes</li>
      <li>Request <strong>cancellations</strong> and <strong>reinstatements</strong></li>
      <li>Request broker of record changes</li>
      <li>Much more</li>
    </ul>
    <img src="assets/img/help-center-marketing.png" alt="" />
  </div>
</app-modal>
