import { Observable, forkJoin } from 'rxjs';
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { InvoicesService } from 'app/features/invoices/services/invoices.service';
import { SentryService } from 'app/core/services/sentry.service';
import { prettyLineOfBusiness } from 'app/features/invoices/models/invoices-constants';

interface InvoiceScheduleItem {
  number: string;
  billDate: moment.Moment;
  dueDate: moment.Moment;
  status: string;
  amount: number | string;
}

@Component({
  selector: 'app-invoices-schedule-page',
  templateUrl: './invoices-schedule-page.component.html',
})
export class InvoicesSchedulePageComponent implements OnInit {
  invoiceObservable: Observable<BackendInvoiceDetails>;
  associatedPolicies: BackendInvoiceAssociatedPolicy[];
  plannedInvoices: Observable<BackendListInvoice>;

  accountName: string;
  accountNumber: string;
  agentName: string;

  policyStart: moment.Moment;
  policyEnd: moment.Moment;
  policyNo: string;
  linesOfBiz: string;
  invoicesArray: InvoiceScheduleItem[];
  autopayStatus: string;

  currentDate = moment.utc().format('M/D/YYYY');
  currentTime = moment.utc().format('h:mm A') + ' UTC';

  constructor(
    private invoiceService: InvoicesService,
    private route: ActivatedRoute,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.getInvoice();
  }

  getInvoice() {
    if (this.route.snapshot.params.id && this.route.snapshot.queryParams.token) {
      const invoiceId = this.route.snapshot.params.id;
      const token = this.route.snapshot.queryParams.token;

      forkJoin(
        this.invoiceService.getInvoice(invoiceId, token),
        this.invoiceService.getPlannedInvoices(invoiceId, token),
        this.invoiceService.getAssociatedPolicies(invoiceId, token)
      ).subscribe(([invoiceDetails, plannedInvoices, associatedPolicies]) => {
        this.accountDetails(invoiceDetails);
        this.bizLines(plannedInvoices.associatedInvoices);
        this.policyDates(
          invoiceDetails,
          associatedPolicies.associatedPolicies,
          this.route.snapshot.queryParams.inForce
        );
        this.invoiceLines(plannedInvoices.associatedInvoices);
      });
    }
  }

  bizLines(associatedInvoices: BackendListInvoice[]) {
    const bizLines: { [k: string]: string } = {};

    for (const invoiceLine of associatedInvoices) {
      invoiceLine.lineItems.forEach((el) => {
        bizLines[el.lineOfBusiness] = el.lineOfBusiness;
      });
      this.linesOfBiz = Object.keys(bizLines).map(prettyLineOfBusiness).join(', ');
    }
  }

  policyDates(
    invoice: Invoice | null,
    associatedPolicies: BackendInvoiceAssociatedPolicy[],
    inForce: string
  ) {
    const isInForce = inForce === 'true';
    if (invoice) {
      const policyNos: { [key: string]: string } = {};
      invoice.charges.forEach((el) => {
        if (!policyNos[el.policyNumber]) {
          policyNos[el.policyNumber] = el.policyNumber;
        }
      });
      if (isInForce) {
        for (const policy of associatedPolicies) {
          if (policy.TermDisplayStatus_ATN === 'In Force') {
            this.policyStart = moment.utc(policy.PeriodStart);
            this.policyEnd = moment.utc(policy.PeriodEnd);
            this.policyNo = policy.PolicyNumber;
          }
        }
      }
      if (!isInForce || (isInForce && !this.policyStart)) {
        // set the policy period based on the invoice policy numbers, make sure all relevant policies have the same period
        for (const policy of associatedPolicies) {
          if (policyNos[policy.PolicyNumber]) {
            if (!this.policyStart) {
              this.policyStart = moment.utc(policy.PeriodStart);
              this.policyEnd = moment.utc(policy.PeriodEnd);
              this.policyNo = policy.PolicyNumber;
            } else if (!moment.utc(policy.PeriodStart).isSame(this.policyStart)) {
              this.sentryService.notify('Policy periods do not match!', {
                severity: 'error',
                metaData: {
                  setPolicyPeriod: {
                    policyStart: this.policyStart,
                    policyEnd: this.policyEnd,
                  },
                  conflictingPeriod: {
                    policyStart: policy.PeriodStart,
                    policyEnd: policy.PeriodEnd,
                  },
                },
              });
            }
          }
        }
      }
    }
  }

  accountDetails(invoice: Invoice | null) {
    if (invoice) {
      this.accountName = invoice.accountName;
      this.accountNumber = invoice.accountNumber;
      this.agentName = invoice.agentName;
      if (invoice.isStripeRecurring || invoice.isChaseRecurring) {
        this.autopayStatus = 'Enrolled';
      } else {
        this.autopayStatus = 'Not enrolled';
      }
    }
  }

  invoiceLines(planned: BackendListInvoice[]) {
    this.invoicesArray = [];

    const policyStartMoment = moment(this.policyStart);
    const policyEndMoment = moment(this.policyEnd);

    for (const invoice of planned) {
      if (invoice.status === 'Planned') {
        invoice.status = 'Scheduled';
      } else if (invoice.status === 'Due') {
        invoice.status = 'Billed';
      }

      let isSameDayPolicyNoMatches = false;
      const isSameStartOrEndDay =
        moment(invoice.dueDate).isSame(policyStartMoment, 'day') ||
        moment(invoice.dueDate).isSame(policyEndMoment, 'day');
      const isBetweenStartOrEnd =
        moment(invoice.dueDate).isAfter(policyStartMoment) &&
        moment(invoice.dueDate).isBefore(policyEndMoment);
      if (isSameStartOrEndDay) {
        isSameDayPolicyNoMatches = invoice.lineItems.some(
          (el) => el.policyNumber === this.policyNo
        );
      }

      // invoice dueDates have time T00:00:00+00:00
      // policy start and end dates come in at T00:01:00+00:00
      // if the invoice due date matches the policy start or end date, make sure the policy number matches the invoice policy number
      // otherwise make sure the invoice due date doesn't happen on the same day as the policy start/end, but is between the start and end.
      if (
        (isSameStartOrEndDay && isSameDayPolicyNoMatches) ||
        (!isSameStartOrEndDay && isBetweenStartOrEnd)
      ) {
        const item: InvoiceScheduleItem = {
          amount: invoice.status === 'Paid' ? invoice.amountOriginallyBilled : invoice.amountDue,
          billDate: moment.utc(invoice.billDate),
          dueDate: moment.utc(invoice.dueDate),
          status: invoice.status,
          number: invoice.invoiceNumber,
        };
        this.invoicesArray.push(item);
      }
    }
    this.invoicesArray.sort((a, b) => a.dueDate.diff(b.dueDate));
  }
}
