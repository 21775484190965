import {
  ViewChild,
  ElementRef,
  Component,
  EventEmitter,
  Output,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { StripeService } from 'ngx-stripe';
import {
  StripeElements,
  StripeElementsOptions,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
  StripeCardElementOptions,
  StripeElementStyleVariant,
  StripeElementChangeEvent,
} from '@stripe/stripe-js';
import { Subscription } from 'rxjs';

interface StripeCardFields {
  isFocused: boolean;
  isComplete: boolean;
  errorCode: string | undefined;
  errorMessage: string | undefined;
}

@Component({
  selector: 'app-invoices-payment-input',
  templateUrl: './invoices-payment-input.component.html',
})
export class InvoicesPaymentInputComponent implements AfterViewInit, OnDestroy {
  @Output() cardComplete = new EventEmitter<boolean>();
  @Output() cardErrors = new EventEmitter<string>();

  @ViewChild('cardNumber')
  cardNumberElement: ElementRef;
  @ViewChild('cardExpiry')
  cardExpiryElement: ElementRef;
  @ViewChild('cardCvc')
  cardCvcElement: ElementRef;

  creditCardFields: { [key: string]: StripeCardFields } = {
    cardNumber: {
      isFocused: false,
      isComplete: false,
      errorCode: undefined,
      errorMessage: undefined,
    },
    cardExpiry: {
      isFocused: false,
      isComplete: false,
      errorCode: undefined,
      errorMessage: undefined,
    },
    cardCvc: {
      isFocused: false,
      isComplete: false,
      errorCode: undefined,
      errorMessage: undefined,
    },
  };

  elements: StripeElements;
  cardNumber: StripeCardNumberElement;
  cardExpiry: StripeCardExpiryElement;
  cardCvc: StripeCardCvcElement;

  // optional parameters
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  private sub = new Subscription();

  constructor(private stripeService: StripeService) {}

  ngAfterViewInit() {
    this.generateStripeElements();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  clearForm() {
    if (this.cardNumber) {
      this.cardNumber.clear();
    }
    if (this.cardExpiry) {
      this.cardExpiry.clear();
    }
    if (this.cardCvc) {
      this.cardCvc.clear();
    }
  }

  generateStripeElements() {
    this.sub.add(
      this.stripeService.elements(this.elementsOptions).subscribe((elements: StripeElements) => {
        // This styling has to be done like this. This is the Stripe-provided way to style Stripe-based Elements
        const baseStyle: StripeElementStyleVariant = {
          '::placeholder': {
            color: '#95a4b3',
          },
          color: '#303344',
          fontFamily:
            "GT America, -apple-system, BlinkMacSystemFont, Helvetica, 'Helvetica Neue', Arial, sans-serif",
          fontSize: '16px',
          lineHeight: '40px',
        };

        const invalidStyle: StripeElementStyleVariant = { color: '#f81c4d' };

        this.elements = elements;
        // Only mount the element the first time
        if (!this.cardNumber) {
          const elementOptions: StripeCardElementOptions = {
            style: { base: baseStyle, invalid: invalidStyle, empty: invalidStyle },
          };

          this.cardNumber = this.elements.create('cardNumber', elementOptions);
        }
        this.cardNumber.mount('#js-card-number-element');

        this.cardNumber.on('focus', () => {
          this.creditCardFields['cardNumber'].isFocused = true;
          this.cardNumberElement.nativeElement.classList.add('card-field__focus');
        });
        this.cardNumber.on('blur', () => {
          this.creditCardFields['cardNumber'].isFocused = false;
          this.cardNumberElement.nativeElement.classList.remove('card-field__focus');
        });

        this.cardNumber.on('change', (e) => {
          this.cardErrorSetter(e, 'cardNumber');
        });

        if (!this.cardExpiry) {
          this.cardExpiry = this.elements.create('cardExpiry', {
            style: {
              base: baseStyle,
            },
          });
        }

        this.cardExpiry.mount('#js-card-expiry-element');

        this.cardExpiry.on('focus', () => {
          this.creditCardFields['cardExpiry'].isFocused = true;
          this.cardExpiryElement.nativeElement.classList.add('card-field__focus');
        });
        this.cardExpiry.on('blur', () => {
          this.creditCardFields['cardExpiry'].isFocused = false;
          this.cardExpiryElement.nativeElement.classList.remove('card-field__focus');
        });

        this.cardExpiry.on('change', (e) => {
          this.cardErrorSetter(e, 'cardExpiry');
        });

        if (!this.cardCvc) {
          this.cardCvc = this.elements.create('cardCvc', {
            style: {
              base: baseStyle,
            },
          });
        }

        this.cardCvc.mount('#js-card-cvc-element');

        this.cardCvc.on('focus', () => {
          this.creditCardFields['cardCvc'].isFocused = true;
          this.cardCvcElement.nativeElement.classList.add('card-field__focus');
        });
        this.cardCvc.on('blur', () => {
          this.creditCardFields['cardCvc'].isFocused = false;
          this.cardCvcElement.nativeElement.classList.remove('card-field__focus');
        });

        this.cardCvc.on('change', (e) => {
          this.cardErrorSetter(e, 'cardCvc');
        });
      })
    );
  }

  cardErrorSetter(e: StripeElementChangeEvent, cardFieldName: string) {
    let errorMessage;
    if (e.error) {
      if (e.error.code !== this.creditCardFields[cardFieldName].errorCode) {
        this.creditCardFields[cardFieldName].errorCode = e.error.code;
        this.creditCardFields[cardFieldName].errorMessage = e.error.message;
        errorMessage = e.error.message;
      }
    } else {
      this.creditCardFields[cardFieldName].errorMessage = undefined;
      this.creditCardFields[cardFieldName].errorCode = undefined;
      if (this.hasCardErrors()) {
        const remainingError = Object.keys(this.creditCardFields).find(
          (key) => this.creditCardFields[key].errorMessage !== undefined
        );
        if (remainingError !== undefined) {
          errorMessage = this.creditCardFields[remainingError].errorMessage || '';
        }
      }
    }
    if (e.complete !== this.creditCardFields[cardFieldName].isComplete) {
      this.creditCardFields[cardFieldName].isComplete = e.complete;
    }

    this.cardComplete.emit(this.hasCardComplete());
    if (this.hasCardErrors()) {
      this.cardErrors.emit(errorMessage || '');
    } else {
      this.cardErrors.emit('');
    }
  }
  checkCreditCardFieldError(cardFieldName: string) {
    return (
      !this.creditCardFields[cardFieldName].isFocused &&
      this.creditCardFields[cardFieldName].errorCode
    );
  }

  hasCardErrors() {
    return Object.keys(this.creditCardFields).some(
      (i) => this.creditCardFields[i].errorCode !== undefined
    );
  }

  hasCardComplete() {
    return Object.keys(this.creditCardFields).every((i) => this.creditCardFields[i].isComplete);
  }
}
