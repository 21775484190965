import { mergeMap, distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';
import { merge as observableMerge, Observable, Subject, Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AmplitudePayloadType } from 'app/core/constants/amplitude-helpers';
import { scrollElementToTop } from 'app/shared/helpers/scroll-helpers';

@Component({
  selector: 'app-form-field-dropdown-search',
  templateUrl: './form-field-dropdown-search.component.html',
})
export class FormFieldDropdownSearchComponent implements OnInit, OnDestroy {
  @Input() inputId: string;
  @Input() labelText: string;
  @Input() questionNote: string | null = null;
  @Input() cssClass: string;
  @Input() tooltipText: string;
  @Input() submitted: boolean;
  @Input() searchExpanded = true;
  @Input() queryMethod: (searchTerm: string, queryableResults?: string[]) => Observable<any>;
  @Input() queryableResults?: string[];
  @Input() formatter: (obj: any) => string;
  @Input() resultTemplate: any = null;
  @Input() targetFormControl: AbstractControl;
  @Output() triggerAmplitudeOnClassCodeSelect = new EventEmitter<any>();
  @Input() placeholderText = 'Choose one';
  @Input() typeaheadPlaceholderText = 'Search';
  @Input() showToolTip: boolean;
  @Input() readonly = false;
  @ViewChild('typeahead', { static: true })
  typeahead: ElementRef;
  currentInputValue: string;
  sub: Subscription;
  typeaheadFormControl: AbstractControl;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  displayError: boolean;

  constructor(private amplitudeService: AmplitudeService) {}

  ngOnInit() {
    this.typeaheadFormControl = new UntypedFormControl({});
    this.setInitialCurrentInputValue();

    if (this.currentInputValue && this.currentInputValue !== this.placeholderText) {
      this.searchExpanded = false;
    }
    // If a change to the form control is triggered outside of this component,
    // the value will be updated in the component.

    this.sub = this.targetFormControl.valueChanges.subscribe((newValue) => {
      const formattedValue = this._runFormatter(newValue);
      if (this.currentInputValue !== formattedValue) {
        this.currentInputValue = formattedValue ? formattedValue : this.placeholderText;
        this.searchExpanded = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // If the form control has an existing value, update it in the component.
  // This is important for BOP edit.
  setInitialCurrentInputValue() {
    if (this.targetFormControl.value) {
      this.currentInputValue = this._runFormatter(this.targetFormControl.value);
    } else {
      this.currentInputValue = this.placeholderText;
    }
  }

  private _runFormatter(val: any): string {
    const formatted = this.formatter(val);
    return formatted && typeof formatted === 'string' ? formatted : this.placeholderText;
  }

  handleSelect(selection: any) {
    const formattedValue = this._runFormatter(selection);
    this.currentInputValue = formattedValue;
    this.targetFormControl.patchValue(selection);

    this.triggerAmplitudeOnClassCodeSelect.emit(selection);

    this.targetFormControl.markAsDirty();
    this.searchExpanded = false;

    // Clears the typeahead search with timeout to wait for DOM re-render
    setTimeout(() => this.typeaheadFormControl.setValue(''));

    this.amplitudeService.submitEvent({
      input: `${this.inputId}-search-typeahead`,
      quoteSection: this.inputId,
      type: AmplitudePayloadType.Typeahead,
      value: formattedValue,
    });
  }

  expandSearch($event: any) {
    $event.preventDefault();
    this.searchExpanded = !this.searchExpanded;

    // Focus on typeahead with timeout to wait for DOM re-render
    if (this.searchExpanded) {
      setTimeout(() => this.typeahead.nativeElement.focus());
    }
  }

  search = (query$: Observable<string>) => {
    const debouncedText$ = query$.pipe(debounceTime(200), distinctUntilChanged());

    return observableMerge(debouncedText$, this.focus$, this.click$).pipe(
      mergeMap((queryStr: string) => {
        return this.queryMethod(queryStr.trim(), this.queryableResults).pipe(
          tap((searchResults) => {
            const searchResultsElement = document.querySelector('.js-typeahead-popup-window');
            if (searchResultsElement) {
              scrollElementToTop(searchResultsElement);
            }
            this.displayError =
              searchResults.length < 1 && this.typeahead.nativeElement.value.length > 2;
            return searchResults;
          })
        );
      })
    );
  };
}
