<div class="form-fleld" *ngIf="coverageAvailable()">
  <app-form-field-radio
    inputId="{{ prefix }}-ordinanceLawCoverageOptedIn"
    [form]="coverage"
    [submitted]="submitted"
    cssClass="ordinance"
    questionText="Would you like Ordinance or Law coverage?"
    nameOfFormControl="ordinanceLawCoverageOptedIn"
  >
  </app-form-field-radio>

  <div class="form-subsection" *ngIf="coverage.value.ordinanceLawCoverageOptedIn">
    <app-form-field-slider
      [form]="coverage"
      inputId="{{ prefix }}-{{ ordinanceLawCoverageValue }}"
      [min]="5000"
      [max]="100000"
      [step]="5000"
      isCurrencyValue="true"
      labelText="Demolition Costs and Increased Cost of Construction  (Apply Coverage 2 and 3 Limit? Coverage 1 is included)"
      nameOfFormControl="ordinanceLawCoverageValue"
    >
    </app-form-field-slider>
    <p
      *ngIf="coverage.controls.ordinanceLawCoverageValue.enabled"
      class="p__field-description-text"
    >
      What are Coverages 1, 2, and 3?
      <a
        href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/BOP+Ordinance+or+Law+Coverage+(BP+04+46+07+13).pdf"
        target="_blank"
        >Learn More</a
      >
    </p>
  </div>
</div>
