import { of as observableOf, Subscription, combineLatest } from 'rxjs';
import * as moment from 'moment';
// Libraries
import { switchMap, share } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { every, get, flatMap } from 'lodash';

// Models
import {
  AVAILABLE_BUSINESS_TYPES,
  AVAILABLE_CONSTRUCTION_TYPES,
  ADDITIONAL_INSURED_TYPES,
  WIND_LOSS_MITIGATION_QUESTIONS,
} from 'app/features/attune-bop/models/constants';
import { BopQuotePayload } from 'app/features/attune-bop/models/bop-policy';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  DisplayConfiguration,
  DisplayConfigurationGroup,
} from 'app/shared/models/submission-summary';

// Services
import { AttuneBopBuildingClassificationService } from 'app/features/attune-bop/services/attune-bop-building-classification.service';
import { AttuneBopQuoteService } from 'app/features/attune-bop/services/attune-bop-quote.service';
import { AttuneBopExcessQuoteService } from 'app/features/attune-bop/services/attune-bop-excess-quote.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import { AttuneBopQuoteFormService } from 'app/features/attune-bop/services/attune-bop-quote-form.service';
import { formatMoneyNoCents } from 'app/shared/helpers/number-format-helpers';

@Component({
  selector: 'app-attune-bop-submission-document-page',
  templateUrl: './attune-bop-submission-document-page.component.html',
})
export class AttuneBopSubmissionDocumentPageComponent implements OnInit, OnDestroy {
  excessPolicyDetails: BopQuotePayload['excessLiability'];
  accountDetails: InsuredAccount;
  quoteNumber: string;
  createdAt: string;
  sub: Subscription = new Subscription();
  formValue: DeepPartial<BopQuotePayload>;

  accountDisplayLabels: DisplayConfiguration[] = [];
  policyDisplayLabels: DisplayConfigurationGroup[] = [];
  excessPolicyDisplayLabels: DisplayConfigurationGroup[] = [];
  downloadedAt = moment.utc().format('M/D/YYYY h:mm A') + ' UTC';

  constructor(
    private bopQuoteService: AttuneBopQuoteService,
    private excessQuoteService: AttuneBopExcessQuoteService,
    private bindService: GWBindService,
    private insuredAccountService: InsuredAccountService,
    private bopQuoteFormService: AttuneBopQuoteFormService,
    private route: ActivatedRoute,
    private buildingClassificationService: AttuneBopBuildingClassificationService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            const accountRequest = this.insuredAccountService.get(params['accountId']);
            const policyRequest = this.bopQuoteService.getTranslatedQuoteV2(params['policyId']);
            const bopQuoteDetailsRequest = this.bindService
              .getQuoteDetails(params['policyId'])
              .pipe(share());

            const excessSubmissionRequest = bopQuoteDetailsRequest.pipe(
              switchMap((policyDetails) => {
                if (!policyDetails.hasExcessPolicy || !policyDetails.linkedJobId) {
                  return observableOf(undefined);
                }
                return this.excessQuoteService.getTranslatedQuote(policyDetails.linkedJobId);
              })
            );

            const excessDetailsRequest = bopQuoteDetailsRequest.pipe(
              switchMap((policyDetails) => {
                if (!policyDetails.hasExcessPolicy || !policyDetails.linkedJobId) {
                  return observableOf(undefined);
                }
                return this.bindService.getQuoteDetails(policyDetails.linkedJobId);
              })
            );

            return combineLatest(
              accountRequest,
              policyRequest,
              bopQuoteDetailsRequest,
              excessSubmissionRequest,
              excessDetailsRequest
            );
          })
        )
        .subscribe(
          ([
            accountResponse,
            bopSubmissionResponse,
            bopQuoteDetailsResponse,
            excessSubmissionResponse,
            excessDetailsResponse,
          ]) => {
            if (bopSubmissionResponse) {
              this.bopQuoteFormService.patchForm(
                bopSubmissionResponse as BopQuotePayload,
                this.excessPolicyDetails
              );
              this.formValue = this.bopQuoteFormService.form.value;
              // By default policy info chooses the most recent date between the effective date and today
              // This is needed to show the actual chosen effective date
              if (this.formValue.policyInfo?.effectiveDate) {
                this.formValue.policyInfo.effectiveDate = bopQuoteDetailsResponse.policyStart
                  .utc()
                  .format('M/D/YYYY');
              }
              this.quoteNumber = get(bopSubmissionResponse, 'guidewireId') || '';
              this.generatePolicyLabels(this.formValue, bopQuoteDetailsResponse);
              this.createdAt =
                moment(bopQuoteDetailsResponse.createdAt).utc().format('M/D/YYYY h:mm A') + ' UTC';
            }

            if (
              excessSubmissionResponse &&
              excessSubmissionResponse.translatedQuote !== null &&
              excessDetailsResponse
            ) {
              this.excessPolicyDetails = excessSubmissionResponse.translatedQuote;
              this.excessPolicyDisplayLabels = this.generateExcessPolicyLabels(
                this.excessPolicyDetails,
                excessDetailsResponse
              );
            }

            // Note: Guard against the empty insured account that gets emitted first by the insuredAccountService
            //       observable before it emits the object with the actual values.
            if (accountResponse !== null && accountResponse.id) {
              this.accountDetails = accountResponse;
              this.generateAccountLabels(this.accountDetails);
            }
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  generateAccountLabels(insuredAccount: InsuredAccount) {
    this.accountDisplayLabels = [
      {
        keyName: 'companyName',
        displayName: 'Company name',
      },
      {
        keyName: 'doingBusinessAs',
        displayName: 'DBA or Operating Name',
        hide: !insuredAccount.doingBusinessAs,
      },
      {
        keyName: 'addressLine1',
        displayName: 'Address line 1',
      },
      {
        keyName: 'addressLine2',
        displayName: 'Address line 2',
        hide: !insuredAccount.addressLine2,
      },
      {
        keyName: 'city',
        displayName: 'City',
      },
      {
        keyName: 'state',
        displayName: 'State',
      },
      {
        keyName: 'zip',
        displayName: 'Zip',
      },
      {
        keyName: 'emailAddress',
        displayName: 'Email Address',
      },
      {
        keyName: 'additionalEmailAddress',
        displayName: 'Additional email address',
        hide: !insuredAccount.additionalEmailAddress,
      },
      {
        keyName: 'phoneNumber',
        displayName: 'Phone number',
        formatter: (rawValue: string) => {
          const digitGroups = /(\d{3})(\d{3})(\d+)/.exec(rawValue);
          if (!digitGroups) {
            return rawValue;
          }
          return `${digitGroups[1]}-${digitGroups[2]}-${digitGroups[3]}`;
        },
      },
      {
        keyName: 'website',
        displayName: 'Website',
        hide: !insuredAccount.website,
      },
      {
        keyName: 'ofacAlertPresent',
        hide: true,
      },
      {
        keyName: 'producerCodesStruct.Entry[0].ProducerCode.Code',
        hide: true,
      },
      {
        keyName: 'naicsCode.code',
        hide: true,
      },
      {
        keyName: 'naicsCode.description',
        hide: true,
      },
      {
        keyName: 'naicsCode.hash',
        hide: true,
      },
    ];
  }

  generateExcessPolicyLabels(
    excessLiability: BopQuotePayload['excessLiability'],
    excessDetailsResponse: QuoteDetails
  ) {
    return [
      {
        heading: 'Excess liability',
        children: [
          {
            keyName: '',
            displayName: 'Note',
            formatter: () => 'Does not include excess submission information',
          },
        ],
      },
    ];
  }

  generateExcessComericalAutoLabels(
    excessLiability: BopQuotePayload['excessLiability']
  ): DisplayConfigurationGroup {
    return {
      heading: 'Excess commercial auto coverage',
      hide: !excessLiability.excessCommercialAutoCoverageIsScheduled,
      children: [
        {
          keyName: 'excessCommercialAutoCombinedLimit',
          displayName: 'Combined Single Limit',
          formatter: formatMoneyNoCents,
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.PrivatePassengerVehicleCount',
          displayName: 'Private passenger vehicles',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.LightTrucksCount',
          displayName: 'Light trucks (0 to 10,000 lbs)',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.MediumTrucksCount',
          displayName: 'Medium trucks (10,001 to 20,000 lbs)',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.HeavyTrucksCount',
          displayName: 'Heavy trucks (20,001 to 45,000 lbs)',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.ExHvyTruckAndTractorSHCount',
          displayName: 'Short haul extra heavy trucks and tractors (over 45,000 lbs)',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.ExHvyTruckAndTractorLHCount',
          displayName: 'Long haul extra heavy trucks and tractors (over 45,000 lbs)',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.CementMixersCount',
          displayName: 'Cement mixers',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleCounts.BusesOver20PassengersCount',
          displayName: 'Buses over 20 passengers only',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleExposure.SchoolBusOrVan',
          displayName: 'School Buses or Vans',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleExposure.PoliceVehFireTrkAmbulance',
          displayName: 'Police Vehicles, Fire Trucks, or Ambulances',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleExposure.MoreThan10HtlMtlParkVan',
          displayName: 'Hotel/Motel/Parking Lot Courtesy Vans',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleExposure.MoreThan15PassengerCourtseyVan',
          displayName: 'Courtesy Vans More than 15 Passengers',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleExposure.RapidDevliveryOperations',
          displayName: 'Rapid Delivery Operations e.g. Pizza, Magazine, Newspaper',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleExposure.GasHaulHzdWasteRedLblMat',
          displayName: 'Gasoline Hauling or Hazardous Waste/Red Label Materials',
        },
        {
          keyName: 'excessCommercialAutoCoverageVehicleExposure.CommodityIIIORIVHauling',
          displayName: 'Commodity III or IV Hauling',
        },
        // Note: This row is a little different in that GW doesn't store the exact states, but only if any of them were checked
        {
          keyName: '',
          displayName:
            'Insured has vehicles registered in, garaged in, or traveling within any of FL, LA, NH, VT, WV',
          hide:
            !excessLiability.excessCommercialAutoCoverageStates ||
            !(
              excessLiability.excessCommercialAutoCoverageStates.hasCommercialAutoInFL ||
              excessLiability.excessCommercialAutoCoverageStates.hasCommercialAutoInLA ||
              excessLiability.excessCommercialAutoCoverageStates.hasCommercialAutoInNH ||
              excessLiability.excessCommercialAutoCoverageStates.hasCommercialAutoInVT ||
              excessLiability.excessCommercialAutoCoverageStates.hasCommercialAutoInWV
            ),
          formatter: () => 'Yes',
        },
        {
          keyName: 'excessCommercialAutoCoverageStates.hasCommercialAutoInFL',
          hide: true,
        },
        {
          keyName: 'excessCommercialAutoCoverageStates.hasCommercialAutoInLA',
          hide: true,
        },
        {
          keyName: 'excessCommercialAutoCoverageStates.hasCommercialAutoInNH',
          hide: true,
        },
        {
          keyName: 'excessCommercialAutoCoverageStates.hasCommercialAutoInVT',
          hide: true,
        },
        {
          keyName: 'excessCommercialAutoCoverageStates.hasCommercialAutoInWV',
          hide: true,
        },
        {
          keyName: 'excessCommercialAutoCoverageIsScheduled',
          hide: true,
        },
      ],
    };
  }

  generateExcessEmployersLiabilityLabels(
    excessLiability: BopQuotePayload['excessLiability']
  ): DisplayConfigurationGroup {
    const formatExcessEmployersLiabilityCoverage = (rawValue: string | number) => {
      // If not a parseable number (eg 'Unlimited'), then return string unchanged
      if (Number.isNaN(Number(rawValue))) {
        return String(rawValue);
      }
      return formatMoneyNoCents(String(rawValue));
    };

    return {
      heading: 'Excess employers liability coverage',
      hide: !excessLiability.excessLiabilityCoverageIsScheduled,
      children: [
        {
          keyName: 'excessLiabilityPerAccidentCoverage',
          displayName: 'Per accident limit',
          formatter: formatExcessEmployersLiabilityCoverage,
        },
        {
          keyName: 'excessLiabilityPerDiseaseCoverage',
          displayName: 'Per disease limit',
          formatter: formatExcessEmployersLiabilityCoverage,
        },
        {
          keyName: 'excessLiabilityPerPolicyCoverage',
          displayName: 'Per policy limit',
          formatter: formatExcessEmployersLiabilityCoverage,
        },
        {
          keyName: 'excessLiabilityCoverageIsScheduled',
          hide: true,
        },
      ],
    };
  }

  generatePolicyLabels(
    policyDetails: DeepPartial<BopQuotePayload>,
    bopQuoteDetailsResponse: QuoteDetails
  ) {
    this.policyDisplayLabels = [
      ...this.generatePolicyInfoLabels(policyDetails, bopQuoteDetailsResponse),
      ...flatMap(policyDetails.locations, (location: BopLocation, locationIndex: number) => {
        return this.generateLocationLabels(location, locationIndex);
      }),
      ...this.generateLiabilityCoverageLabels(policyDetails),
      ...this.generateAdditionalCoveragesLabels(policyDetails),
      ...this.generateAdditionalInsuredLabels(policyDetails),
    ];
  }

  generatePolicyInfoLabels(
    policyDetails: DeepPartial<BopQuotePayload>,
    bopQuoteDetailsResponse: QuoteDetails
  ): DisplayConfigurationGroup[] {
    const declinedLabel = [];
    if (bopQuoteDetailsResponse.status === 'Draft') {
      declinedLabel.push({
        keyName: '',
        displayName: 'Note',
        formatter: () => 'BOP quote was declined',
      });
    }

    return [
      {
        heading: 'Submission Info',
        children: [
          ...declinedLabel,
          {
            keyName: 'policyInfo.effectiveDate',
            displayName: 'Proposed Effective Date',
          },
          {
            keyName: 'policyInfo.baseState',
            displayName: 'Base State',
          },
          {
            keyName: 'policyInfo.yearsInBusiness',
            displayName: 'Years in Business',
          },
          {
            keyName: 'policyInfo.numberOfLocations',
            displayName: 'Number of locations',
          },
          {
            keyName: 'policyInfo.organizationType',
            displayName: 'Organization Type',
          },
          {
            keyName: 'guidelines',
            hide: true,
          },
          {
            keyName: 'guidewireId',
            hide: true,
          },
        ],
      },
    ];
  }

  generateAdditionalCoveragesLabels(
    policyDetails: DeepPartial<BopQuotePayload>
  ): DisplayConfigurationGroup[] {
    return [
      ...this.generateCyberCoverageLabels(policyDetails),
      ...this.generateEPLILabels(policyDetails),
      ...this.generateLiquorLiabilityLabels(policyDetails),
      ...this.generateEmployeeDishonestyLabels(),
      ...this.generateTerrorismCoverageLabels(),
      ...this.generateWaiverOfSubmissionLabels(policyDetails),
      {
        heading: 'hidden fields',
        hide: true,
        children: [
          {
            keyName: 'policyInfo.bopVersion',
            hide: true,
          },
          {
            keyName: 'additionalCoverages.hasAdditionalInsuredBusinesses',
            hide: true,
          },
          {
            keyName: 'additionalCoverages.hasWaiversOfSubrogation',
            hide: true,
          },
          {
            keyName: 'excessLiabilityOptIn',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessAnnualRevenue',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessCommercialAutoCoverageIsScheduled',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessCommercialAutoUnderlyingPremium',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessLiabilityPerPolicyCoverage',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessEmployersUnderlyingPremium',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessLiabilityLimit',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessLiabilityCoverageIsScheduled',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessLiabilityPerAccidentCoverage',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessLiabilityPerDiseaseCoverage',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessAnnualRevenue',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessEmployersUnderlyingPremium',
            hide: true,
          },
          {
            keyName: 'excessLiability.excessCommercialAutoUnderlyingPremium',
            hide: true,
          },
        ],
      },
    ];
  }

  generateTerrorismCoverageLabels(): DisplayConfigurationGroup[] {
    return [
      {
        heading: 'Certified Acts of Terror coverage',
        children: [
          {
            keyName: 'additionalCoverages.acceptCertifiedActsOfTerrorismCoverage',
            displayName: 'Included in quote?',
          },
        ],
      },
    ];
  }

  generateEmployeeDishonestyLabels(): DisplayConfigurationGroup[] {
    return [
      {
        heading: 'Employee Dishonesty',
        children: [
          {
            keyName: 'additionalCoverages.limitForEmployeeDishonesty',
            displayName: 'Limit',
            formatter: formatMoneyNoCents,
          },
        ],
      },
    ];
  }

  generateCyberCoverageLabels(
    policyDetails: DeepPartial<BopQuotePayload>
  ): DisplayConfigurationGroup[] {
    const selectedCoverage = get(
      policyDetails,
      'additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage',
      'none'
    );
    const notOptedIn = selectedCoverage !== 'endorsement';
    return [
      {
        heading: `Cyber liability coverage`,
        children: [
          {
            keyName: 'additionalCoverages.cyberLiabilityCoverage.optedIn',
            displayName: 'Included in quote?',
          },
          {
            keyName: 'additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage',
            displayName: 'What cyber liabilty coverage was selected during quote?',
          },
          {
            keyName: 'additionalCoverages.cyberLiabilityCoverage.aggregateLimit',
            displayName: 'Cyber liability coverage aggregate limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName: 'additionalCoverages.cyberLiabilityCoverage.firstPartyLimit',
            displayName: 'Cyber liability coverage first party limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName: 'additionalCoverages.cyberLiabilityCoverage.deductible',
            displayName: 'Cyber liability coverage deductible',
            hide: true,
          },
          {
            keyName: 'additionalCoverages.cyberLiabilityCoverage.retroactiveDate',
            displayName: 'Cyber liability coverage retroactive date',
            hide: notOptedIn,
          },
        ],
      },
    ];
  }

  generateEPLILabels(policyDetails: DeepPartial<BopQuotePayload>): DisplayConfigurationGroup[] {
    const notOptedIn = !get(
      policyDetails,
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.optedIn',
      false
    );
    return [
      {
        heading: `Employment related practices liability coverage`,
        children: [
          {
            keyName: 'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.optedIn',
            displayName: 'Included in quote?',
          },
          {
            keyName:
              'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimit',
            displayName: 'Coverage aggregate limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName: 'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.deductible',
            displayName: 'Coverage deductible',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName:
              'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.eachEmploymentWrongfulActLimit',
            displayName: 'Each employment wrongful act limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName:
              'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.retroactiveDate',
            displayName: 'Retroactive date',
            hide: notOptedIn,
          },
          {
            keyName:
              'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimitV2',
            displayName: 'Coverage aggregate limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName: 'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.deductibleV2',
            displayName: 'Coverage deductible',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName:
              'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.defenseLimitV2',
            displayName: 'Coverage aggregate limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            // TODO: Display this when per location part time employees field is added to agent portal
            keyName:
              'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.perLocationPartTimeEmployees',
            displayName: 'Per Location Part Time Employees',
            hide: true,
          },
        ],
      },
    ];
  }

  generateLiquorLiabilityLabels(
    policyDetails: DeepPartial<BopQuotePayload>
  ): DisplayConfigurationGroup[] {
    return [
      {
        heading: 'Liquor liability',
        children: [
          {
            keyName: 'liabilityCoverages.liquorLiability.optedIn',
            displayName: 'Included in quote?',
          },
          {
            keyName: 'liabilityCoverages.liquorLiability.eachCommonCauseLimit',
            displayName: 'Each common cause limit',
            formatter: formatMoneyNoCents,
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName: 'liabilityCoverages.liquorLiability.aggregateLimit',
            displayName: 'Aggregate limit',
            formatter: formatMoneyNoCents,
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName: 'liabilityCoverages.liquorLiability.liquorLicenseNumber',
            displayName: 'Liquor License Number',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName:
              'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions.liquorLicenseSuspendedInLast10Years',
            displayName: 'Liquor license suspended or fined in the last 10 years?',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName:
              'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions.patronAgeCheckUnder40',
            displayName: 'Patron age checked under 40?',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName:
              'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions.identificationCheckedElectronically',
            displayName: 'Identification checked electronically?',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName:
              'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions.yearsOfLiquorExperience',
            displayName: 'Years of liquor experience',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName:
              'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions.requireAlcoholTraining',
            displayName: 'Is alcohol training required?',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName:
              'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions.yearsLicenseIssuedFor',
            displayName: 'Years liquor license issued for',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
          {
            keyName:
              'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions.liquorSalesOrLowerPrice',
            displayName: 'Are sales or lower prices for liquor offered?',
            hide: !get(policyDetails, 'liabilityCoverages.liquorLiability.optedIn'),
          },
        ],
      },
    ];
  }

  generateAdditionalInsuredLabels(
    policyDetails: DeepPartial<BopQuotePayload>
  ): DisplayConfigurationGroup[] {
    const AI_RELATED_LOCATION_FORMATTER = (locationBuildingReferenceString: string) => {
      // Note: Match location index, and then building index in groups
      const indexExtractionRegexpResult = /location-(\d+)-(\d+)/.exec(
        locationBuildingReferenceString
      );
      if (!indexExtractionRegexpResult) {
        // Note: If we were unable to parse location reference string, then just return the reference string
        return locationBuildingReferenceString;
      }
      const locationIndex = parseInt(indexExtractionRegexpResult[1], 10) - 1;
      const buildingNumber = parseInt(indexExtractionRegexpResult[2], 10);
      const locationAddress = get(
        policyDetails,
        `locations[${locationIndex}].locationDetails`
      ) as BopLocationDetails;
      return `${locationAddress.addressLine1}${
        locationAddress.addressLine2 ? ' ' + locationAddress.addressLine2 : ''
      } ${locationAddress.city} ${locationAddress.state} ${
        locationAddress.zip
      }: Building ${buildingNumber}`;
    };

    const AI_TYPE_LABEL_FORMATTER = (type: string) => {
      const labels = Object.keys(ADDITIONAL_INSURED_TYPES);
      return labels.find((label) => ADDITIONAL_INSURED_TYPES[label] === type) || type;
    };

    const additionalInsureds = get(
      policyDetails,
      'additionalCoverages.additionalInsuredBusinesses',
      []
    );
    if (!additionalInsureds.length) {
      return [
        {
          heading: 'Additonal Insureds',
          children: [
            {
              displayName: 'None',
              keyName: '',
            },
          ],
        },
      ];
    }

    return flatMap(additionalInsureds, (additionalInsured, additionalInsuredIndex) => {
      return [
        {
          keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}`,
          heading: 'Additonal Insured ' + (additionalInsuredIndex + 1),
          children: [
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.businessName`,
              displayName: 'Legal Business Name',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.type`,
              displayName: 'Type',
              formatter: AI_TYPE_LABEL_FORMATTER,
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.relatedLocation`,
              displayName: 'Applicable location',
              formatter: AI_RELATED_LOCATION_FORMATTER,
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.contractNumber`,
              displayName: 'Contract number',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.buildingDescription`,
              displayName: 'Building description',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.paragraph`,
              displayName: 'Paragraph',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.additionalInformation`,
              displayName: 'Additional Information',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.limitOfInsurance`,
              displayName: 'Limit of insurance',
              formatter: formatMoneyNoCents,
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.address.addressLine1`,
              displayName: 'Address line 1',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.address.addressLine2`,
              displayName: 'Address line 2',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.address.city`,
              displayName: 'City',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.address.state`,
              displayName: 'State',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.address.zip`,
              displayName: 'Zip',
            },
            {
              keyName: `additionalCoverages.additionalInsuredBusinesses.${additionalInsuredIndex}.addressType`,
              displayName: 'Address Type',
            },
          ],
        },
      ];
    });
  }

  generateWaiverOfSubmissionLabels(
    policyDetails: DeepPartial<BopQuotePayload>
  ): DisplayConfigurationGroup[] {
    const wosFields = [
      {
        heading: 'Waivers of Subrogation',
        children: [
          {
            keyName: 'additionalCoverages.hasWaiversOfSubrogation',
            displayName: 'Included In Quote?',
          },
        ],
      },
    ];

    const bopV1ScheduledWaivers = get(
      policyDetails,
      'additionalCoverages.waiversOfSubrogation',
      []
    );
    if (bopV1ScheduledWaivers.length) {
      bopV1ScheduledWaivers.forEach((waivers: any, waiversIndex: number) => {
        wosFields[0].children.push({
          keyName: `additionalCoverages.waiversOfSubrogation.${waiversIndex}.orgOrPersonName`,
          displayName: 'Name of Person or Organization',
        });
      });
    }
    return wosFields;
  }

  generateLiabilityCoverageLabels(
    policyDetails: DeepPartial<BopQuotePayload>
  ): DisplayConfigurationGroup[] {
    return [
      {
        heading: `Liability Coverages`,
        children: [
          {
            keyName: 'liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses',
            displayName: 'Per Occurrence limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.limitPerPersonOfMedicalExpenses',
            displayName: 'Medical Expenses limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.damageToPremises',
            displayName: 'Damage to premises rented to you limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.funeralDirectorsProfessionalLiability',
            displayName: 'Funeral directors professional liability',
          },
          {
            keyName: 'liabilityCoverages.opticalAndHearingAidEstablishmentsProfessionalLiability',
            displayName: 'Optical and hearing aid establishments professional liability',
          },
          {
            keyName: 'liabilityCoverages.opticalAndHearingAidSales',
            displayName: 'Optical and hearing aid sales',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.printersErrorsAndOmissionsProfessionalLiability',
            displayName: 'Printers errors and omissions professional liability',
          },
          {
            keyName: 'liabilityCoverages.printersErrorsAndOmissionsSalesOrPayroll',
            displayName: 'Printers errors and omissions sales or payroll',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.propertyInTransitLimit',
            displayName: 'Property in transit limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.veterinariansProfessionalLiability',
            displayName: 'Veterinarians professional liability',
          },
          {
            keyName: 'liabilityCoverages.numberOfVeterinarians',
            displayName: 'Number of veterinarians',
          },
          {
            keyName: 'liabilityCoverages.veterinariansOnlyTreatHousePets',
            displayName: 'Veterinarians only treat house pets',
          },
          {
            keyName: 'liabilityCoverages.acceptSnowPlowCompletedOpsCoverage',
            displayName: 'Snow plow completed ops coverage',
          },
          {
            keyName: 'liabilityCoverages.barberShopsAndHairSalonsProfessionalLiability',
            displayName: 'Barber shops and hair salons professional liability',
          },
          {
            keyName: 'liabilityCoverages.barberShopsNumberOfOperators',
            displayName: 'Barber shops number of operators',
          },
          {
            keyName: 'liabilityCoverages.beautySalonsDescriptionOfAdditionalServices',
            hide: true,
          },
          {
            keyName: 'liabilityCoverages.beautySalonsProfessionalLiability',
            displayName: 'Beauty salons professional liability',
          },
          {
            keyName: 'liabilityCoverages.beautySalonsNumberOfOperators',
            displayName: 'Beauty salons number of operators',
          },
          {
            keyName: 'liabilityCoverages.acceptHiredNonOwnedAutoCoverage',
            displayName: 'Hired non-owned auto coverage (HNOA) included?',
          },
          {
            keyName: 'liabilityCoverages.hnoaNumberOfDrivers',
            displayName: 'HNOA Coverage - Number of drivers',
          },
          {
            keyName: 'liabilityCoverages.equipmentLeftInVehicle',
            displayName: 'Equipment left in vehicle',
          },
          {
            keyName: 'liabilityCoverages.stressTestsWaterLines',
            displayName: 'Stress tests water lines',
          },
          {
            keyName: 'liabilityCoverages.employeeHandbook',
            displayName: 'Employee handbook',
          },
          {
            keyName: 'liabilityCoverages.janitorialServices',
            displayName: 'Janitorial services',
          },
          {
            keyName: 'liabilityCoverages.stopGap',
            displayName: 'Stop Gap Employers Liability',
          },
        ],
      },
      {
        heading: 'Contractors coverage',
        children: [
          {
            keyName: 'liabilityCoverages.eachCoveredJobSiteLimit',
            displayName: 'Each Covered Job Site Limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.installationLimit',
            displayName: 'Installation Limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.toolsBlanketLimit',
            displayName: 'Tools Blanket Limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: 'liabilityCoverages.toolsPerItemLimit',
            displayName: 'Tools Per Item Limit',
            formatter: formatMoneyNoCents,
          },
        ],
      },
      ...this.generateEmployeeBenefitsLiabilityCoverageLabels(policyDetails),
    ];
  }

  generateEmployeeBenefitsLiabilityCoverageLabels(
    policyDetails: DeepPartial<BopQuotePayload>
  ): DisplayConfigurationGroup[] {
    const notOptedIn = !get(
      policyDetails,
      'liabilityCoverages.employeeBenefitsLiabilityCoverage.optedIn',
      false
    );
    return [
      {
        heading: 'Employee Benefits Liability coverage',
        children: [
          {
            keyName: 'liabilityCoverages.employeeBenefitsLiabilityCoverage.optedIn',
            displayName: 'Included in quote?',
          },
          {
            keyName: 'liabilityCoverages.employeeBenefitsLiabilityCoverage.aggregateLimit',
            displayName: 'Employee benefits liability coverage aggregate limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName: 'liabilityCoverages.employeeBenefitsLiabilityCoverage.deductible',
            displayName: 'Employee benefits liability coverage deductible',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
          {
            keyName: 'liabilityCoverages.employeeBenefitsLiabilityCoverage.retroactiveDate',
            displayName: 'Employee benefits liability coverage retroactive date',
            hide: notOptedIn,
          },
          {
            keyName: 'liabilityCoverages.employeeBenefitsLiabilityCoverage.eachEmployeeLimit',
            displayName: 'Employee benefits liability coverage each employee limit',
            formatter: formatMoneyNoCents,
            hide: notOptedIn,
          },
        ],
      },
    ];
  }

  generateLocationLabels(
    location: BopLocation,
    locationIndex: number
  ): DisplayConfigurationGroup[] {
    return [
      {
        heading: 'Location ' + (locationIndex + 1),
        children: [
          {
            keyName: `locations[${locationIndex}].locationDetails.addressLine1`,
            displayName: 'Address line 1',
          },
          {
            keyName: `locations[${locationIndex}].locationDetails.addressLine2`,
            displayName: 'Address line 2',
          },
          {
            keyName: `locations[${locationIndex}].locationDetails.city`,
            displayName: 'City',
          },
          {
            keyName: `locations[${locationIndex}].locationDetails.state`,
            displayName: 'State',
          },
          {
            keyName: `locations[${locationIndex}].locationDetails.zip`,
            displayName: 'Zip',
          },
          {
            keyName: `locations[${locationIndex}].locationDetails.employeeCount`,
            displayName: 'Number of employees',
          },
          {
            keyName: `locations[${locationIndex}].locationDetails.partTimeEmployeeCount`,
            displayName: 'Number of part-time employees',
          },
          {
            keyName: `locations[${locationIndex}].locationDetails.propertyDeductible`,
            displayName: 'All Other Perils (AOP) deductible for this location',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: `locations[${locationIndex}].locationPrefill`,
            hide: true,
          },
        ],
      },
      ...flatMap(location.buildings, (building, buildingIndex) => {
        return this.generateBuildingLabels(building, locationIndex, buildingIndex);
      }),
    ];
  }

  generateBuildingLabels(
    building: BopBuilding,
    locationIndex: number,
    buildingIndex: number
  ): DisplayConfigurationGroup[] {
    return [
      ...this.generateBuildingExposureLabels(building.exposure, locationIndex, buildingIndex),
      ...this.generateBuildingLROLabels(
        building.exposure,
        building.lessorsRisk,
        locationIndex,
        buildingIndex
      ),
      ...this.generateBuildingCoverageLabels(building.coverage, locationIndex, buildingIndex),
    ];
  }

  generateBuildingExposureLabels(
    exposure: BopBuildingExposure,
    locationIndex: number,
    buildingIndex: number
  ): DisplayConfigurationGroup[] {
    return [
      {
        heading: `Location ${locationIndex + 1} - Building ${buildingIndex + 1}`,
        children: [
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.lessorsRisk`,
            displayName: 'Is lessors risk only?',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.businessType`,
            displayName: 'Business category',
            formatter: (rawValue: string) =>
              AVAILABLE_BUSINESS_TYPES[rawValue as keyof typeof AVAILABLE_BUSINESS_TYPES],
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.classification.code.code`,
            hide: true,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.classification.code.descriptionCode`,
            displayName: 'Business classification',
            formatter: (rawValue: string) =>
              this.buildingClassificationService.codeDescription(exposure.businessType, rawValue) ||
              rawValue,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.buildingLimit`,
            displayName: 'Building limit',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.limitForBusinessPersonalProperty`,
            displayName: 'Business Personal Property limit',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.totalSales`,
            displayName: 'Total sales',
            formatter: (rawValue: string) => {
              if (rawValue === '$0') {
                return '$0 (or not required for a quote)';
              }
              return rawValue;
            },
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.classification.alcoholSales`,
            displayName: 'Alcohol sales',
            formatter: formatMoneyNoCents,
            hide:
              exposure.classification.alcoholSales === undefined ||
              exposure.classification.alcoholSales === null,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.payroll`,
            displayName: 'Payroll',
            formatter: (rawValue: string) => {
              if (rawValue === '$0') {
                return '$0 (or not required for a quote)';
              }
              return rawValue;
            },
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.constructionType`,
            displayName: 'Construction type of the building',
            formatter: (rawValue: keyof typeof AVAILABLE_CONSTRUCTION_TYPES) =>
              AVAILABLE_CONSTRUCTION_TYPES[rawValue],
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.storyCount`,
            displayName: 'Number of stories in the building',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.squareFootage`,
            displayName: 'Square footage occupied by insured',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.yearBuilt`,
            displayName: 'Year built',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.roofUpdated`,
            displayName: 'Has the roof been updated in the last 20 years?',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.electricPlumbingHVACUpdated`,
            displayName: 'Has electrical, plumbing, roof and HVAC been updated?',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].exposure.hasAutomaticSprinklerSystem`,
            displayName: 'Does this building have an automatic sprinkler system?',
          },
        ],
      },
    ];
  }

  generateBuildingLROLabels(
    exposure: BopBuildingExposure,
    lessorsRisk: BopBuildingLessorsRisk | undefined,
    locationIndex: number,
    buildingIndex: number
  ): DisplayConfigurationGroup[] {
    if (!lessorsRisk) {
      return [];
    }

    return [
      {
        heading: `Location ${locationIndex + 1} - Building ${buildingIndex + 1} - Lessors Risk`,
        children: [
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.propertyManagementCompany`,
            displayName: 'Does the applicant use a property management company?',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.commonOwnershipWithTenants`,
            displayName: 'Does the applicant share common ownership with any tenants?',
          },
        ],
        hide: !exposure.lessorsRisk,
      },
      {
        heading: `Location ${locationIndex + 1} - Building ${buildingIndex + 1} - Tenants`,
        children: flatMap(
          lessorsRisk?.lessorsRiskTenants.map((_tenant, index) => {
            const tenantIndex = index + 1;
            return [
              {
                keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.lessorsRiskTenants[${index}].tenantName`,
                displayName: `Tenant ${tenantIndex}: Name`,
              },
              {
                keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.lessorsRiskTenants[${index}].descriptionOfBusiness`,
                displayName: `Tenant ${tenantIndex}: Description of business`,
              },
              {
                keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.lessorsRiskTenants[${index}].squareFootage`,
                displayName: `Tenant ${tenantIndex}: Square footage`,
              },
              {
                keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.lessorsRiskTenants[${index}].rent`,
                displayName: `Tenant ${tenantIndex}: Rent`,
              },
            ];
          })
        ),
        hide: !exposure.lessorsRisk,
      },
      {
        heading: `Location ${locationIndex + 1} - Building ${
          buildingIndex + 1
        } - Landlord requirements`,
        children: [
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.landlordRequirements.writtenLease`,
            displayName: 'Written lease between landlord and tenant',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.landlordRequirements.tenantInsuranceValidated`,
            displayName:
              "Tenant's insurance policy is validated at time of lease signing and renewal",
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.landlordRequirements.landlordAdditionalInsured`,
            displayName: 'Landlord named as Additional Insured',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.landlordRequirements.landlordLimits`,
            displayName: 'Limits = Landlord limits',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.landlordRequirements.primaryNonContributory`,
            displayName: 'Primary non-contributory',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.landlordRequirements.waiverOfSubrogation`,
            displayName: 'Waiver of subrogation',
          },
        ],
        hide: !exposure.lessorsRisk,
      },
      {
        heading: `Location ${locationIndex + 1} - Building ${
          buildingIndex + 1
        } - Tenant responsibilities`,
        children: [
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.tenantResponsibilities.propertyTaxes`,
            displayName: 'Property taxes',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.tenantResponsibilities.propertyInsurance`,
            displayName: 'Property insurance',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].lessorsRisk.tenantResponsibilities.propertyMaintenance`,
            displayName: 'Property maintenance',
          },
        ],
        hide: !exposure.lessorsRisk,
      },
    ];
  }

  generateBuildingCoverageLabels(
    coverage: BopBuildingCoverage,
    locationIndex: number,
    buildingIndex: number
  ): DisplayConfigurationGroup[] {
    return [
      {
        heading: `Location ${locationIndex + 1} - Building ${buildingIndex + 1} - Coverages`,
        children: [
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.utilityServicesTimeElement`,
            displayName: 'Utility Services - Time Element',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.utilityServicesDirectDamage`,
            displayName: 'Utility Services - Direct Damage',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.businessIncomeAndExtraExpensesIndemnityInMonths`,
            displayName: 'BI/EE Period of Indemnity (Months)',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windCoverageOptedIn`,
            displayName: 'Would you like wind coverage?',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windDeductible`,
            hide: true,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windDeductiblePercent`,
            displayName: 'Wind Deductible',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.equipmentBreakdownCoverageOptedIn`,
            displayName: 'Would you like Equipment Breakdown Coverage?',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.spoilage`,
            displayName: 'Spoilage limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.debrisRemoval`,
            displayName: 'Additional Debris Removal limit',
            formatter: formatMoneyNoCents,
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.ordinanceLawCoverageOptedIn`,
            displayName: 'Ordinance and law coverage included?',
          },
          {
            keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.ordinanceLawCoverageValue`,
            displayName: 'Ordinance and law coverage limit',
            hide: !coverage.ordinanceLawCoverageOptedIn,
            formatter: formatMoneyNoCents,
          },
          ...this.generateWindLossMitigationLabels(coverage, locationIndex, buildingIndex),
        ],
      },
    ];
  }

  generateWindLossMitigationLabels(
    coverage: BopBuildingCoverage,
    locationIndex: number,
    buildingIndex: number
  ): DisplayConfiguration[] {
    return [
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigationPresent`,
        displayName:
          'Does the insured have evidence that building construction or the retrofitting measure has been implemented according to applicable standards for the South Carolina Windstorm Loss Mitigation Program?',
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.SCBCCompliant`,
        displayName: 'Wind loss mitigation - Is the Building Code SCBC Compliant?',
        hide: !coverage.windLossMitigationPresent,
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.level`,
        displayName: 'Wind loss mitigation - Level',
        formatter: (rawValue: keyof typeof WIND_LOSS_MITIGATION_QUESTIONS.level) =>
          WIND_LOSS_MITIGATION_QUESTIONS.level[rawValue] || rawValue,
        hide: !coverage.windLossMitigationPresent,
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.roofToWallConnection`,
        displayName: 'Wind loss mitigation - Roof To Wall Connection',
        formatter: (rawValue: keyof typeof WIND_LOSS_MITIGATION_QUESTIONS.roofToWallConnection) =>
          WIND_LOSS_MITIGATION_QUESTIONS.roofToWallConnection[rawValue] || rawValue,
        hide: !coverage.windLossMitigationPresent,
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.openingProtection`,
        displayName: 'Wind loss mitigation - Opening Protection',
        formatter: (rawValue: keyof typeof WIND_LOSS_MITIGATION_QUESTIONS.openingProtection) =>
          WIND_LOSS_MITIGATION_QUESTIONS.openingProtection[rawValue] || rawValue,
        hide: !coverage.windLossMitigationPresent,
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.doorStrength`,
        displayName: 'Wind loss mitigation - Door Strength',
        formatter: (rawValue: keyof typeof WIND_LOSS_MITIGATION_QUESTIONS.doorStrength) =>
          WIND_LOSS_MITIGATION_QUESTIONS.doorStrength[rawValue] || rawValue,
        hide: !coverage.windLossMitigationPresent,
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.secondaryWaterResistance`,
        displayName: 'Does the building include Secondary Water Resistance',
        hide: !coverage.windLossMitigationPresent,
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.roofShape`,
        displayName: 'Wind loss mitigation - Roof Shape',
        formatter: (rawValue: keyof typeof WIND_LOSS_MITIGATION_QUESTIONS.roofShape) =>
          WIND_LOSS_MITIGATION_QUESTIONS.roofShape[rawValue] || rawValue,
        hide: !coverage.windLossMitigationPresent,
      },
      {
        keyName: `locations[${locationIndex}].buildings[${buildingIndex}].coverage.windLossMitigation.roofAndDeckAttachment`,
        hide: true,
      },
    ];
  }

  getFormField(
    data: DeepPartial<BopQuotePayload> | InsuredAccount,
    path: string,
    formatter: (rawValue: number | string) => string
  ) {
    let value = get(data, path);

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (formatter) {
      value = formatter(value);
    }
    return value;
  }

  fieldGroupIsHidden(fieldGroup: DisplayConfigurationGroup) {
    return every(fieldGroup.children, this.isHidden.bind(this));
  }

  isHidden(fieldInfo: DisplayConfiguration) {
    if (!this.formValue) {
      return true;
    }
    if (fieldInfo.hide) {
      return true;
    }

    const fieldValue = get(this.formValue, fieldInfo.keyName, undefined);
    return fieldValue === undefined || fieldValue === null || fieldValue === 'undefined';
  }
}
