import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SentryService } from 'app/core/services/sentry.service';
import { API_V3_BASE } from 'app/constants';

export interface AttuneBlogItem {
  content: string;
  contentSnippet: string;
  guid: string;
  isoDate: string;
  link: string;
  pubDate: string;
  title: string;
}
export interface AttuneBlogResponse {
  feed: {
    items: AttuneBlogItem[];
  };
}

@Injectable()
export class SupportNewsfeedService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getNewsfeed(): Observable<AttuneBlogResponse> {
    return this.http.get<AttuneBlogResponse>(`${API_V3_BASE}/attune-blog`).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to get support news feed.', {
          severity: 'info',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }
}
