import { Component, isDevMode, Input, Output, EventEmitter } from '@angular/core';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import {
  RouteFormStep,
  FormDslSteppedFormBaseService,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

@Component({
  selector: 'app-hiscox-quote-form-sidebar',
  templateUrl: './hiscox-quote-form-sidebar.component.html',
})
export class HiscoxQuoteFormSidebarComponent {
  @Input() accountId: string;
  @Input() insAccount: InsuredAccount;
  @Input() steppedFormService: FormDslSteppedFormBaseService;
  @Input() product: string;
  @Input() currentStepSlug: string;
  @Input() sidebarTitle: string;
  @Input() disableNavigationToStep: boolean;
  @Input() showCancelButton = true;
  @Output() navigateToStep: EventEmitter<string> = new EventEmitter();

  isDevMode = isDevMode();

  constructor() {}

  isCurrentStep(step: RouteFormStep) {
    return step.slug === this.steppedFormService.currentStep.slug;
  }

  isNavigable(step: RouteFormStep) {
    return this.steppedFormService.stepDifference(this.steppedFormService.currentStep, step) <= 1;
  }

  handleStepClicked(step: RouteFormStep) {
    if (this.isNavigable(step)) {
      this.navigateToStep.emit(step.slug);
    }
  }

  getSteps() {
    return this.steppedFormService.getSteps();
  }
}
