import { of as observableOf, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NaicsService } from '../services/naics.service';

export const getNaicsCodes = (
  naicsService: NaicsService,
  searchTerm: string,
  numberTimesTried: number = 1
): Observable<NaicsCode[]> => {
  if (searchTerm.length > 2) {
    return naicsService.searchCodes(searchTerm).pipe(
      catchError((error) => {
        if (numberTimesTried === 1) {
          return getNaicsCodes(naicsService, searchTerm, numberTimesTried + 1);
        }
        return observableOf([]);
      })
    );
  }
  return observableOf([]);
};
