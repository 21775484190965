import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_V3_BASE } from '../../constants';
import { catchError } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { SentryService } from 'app/core/services/sentry.service';
import { InformService } from 'app/core/services/inform.service';
import * as FileSaver from 'file-saver';

export type RefundMethod = 'prorata' | 'flat' | 'shortrate';
export declare type cancellationReason =
  | 'CovsNotAvailable'
  | 'InfoNotProvided'
  | 'LossHistory'
  | 'OpsChars'
  | 'PaymentHistory'
  | 'ProdRequirements'
  | 'ProductsChars'
  | 'businessClosed'
  | 'businessSold'
  | 'canReWrittenToEffDate'
  | 'canReWrittenToPayPlan'
  | 'criminal'
  | 'eligibility'
  | 'failcoop'
  | 'failsafe'
  | 'failterm'
  | 'fincononpay'
  | 'fincononpay_HUSA'
  | 'flatrewrite'
  | 'fraud'
  | 'midtermrewrite'
  | 'noc'
  | 'nofincononpay_HUSA'
  | 'nondisclose'
  | 'nonpayment'
  | 'nonreport'
  | 'nottaken'
  | 'policybounderror_ATN'
  | 'reinsurance'
  | 'rescinded_HUSA'
  | 'riskchange'
  | 'suspension'
  | 'ulbopcancelled_HUSA'
  | 'ulcarrierrating_HUSA'
  | 'uwreasons'
  | 'vacant'
  | 'violation'
  | 'wrapup';

export interface EndorsementResponseInfo {
  accountNumber: string;
  policyNumber: string;
  effectiveDate: string;
  quotedCost: string;
  costChange: string;
  description: string;
}
export interface CancellationResponseInfo {
  accountNumber: string;
  policyNumber: string;
  effectiveDate: string;
  refundMethod: RefundMethod;
  cancellationReason: cancellationReason;
}

export type ResponseInfo = EndorsementResponseInfo | CancellationResponseInfo;

// These VerifyResponse and VerifyErrorResponse types are used to handle passing the error status within the verify method
export interface VerifyResponse {
  confirmed: boolean;
  rejected: boolean;
  requesterEmail: string;
  linkInfo: ResponseInfo;
}

export interface VerifyErrorResponse {
  status: number;
}

@Injectable()
export class PolicyAutomationLandingService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private informService: InformService
  ) {}

  verifyLink(linkId: string) {
    return this.http.get(`${API_V3_BASE}/help-center-link/verify/${linkId}`);
  }

  confirmLink(linkId: string) {
    return this.http.post(`${API_V3_BASE}/help-center-link/confirm`, { id: linkId }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Failed to confirm link', {
          severity: 'error',
          metaData: {
            linkId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }

  withdrawLink(linkId: string) {
    return this.http.post(`${API_V3_BASE}/help-center-link/withdraw`, { id: linkId }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Failed to withdraw link', {
          severity: 'error',
          metaData: {
            linkId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }

  getPolicyDocLink(linkId: string) {
    return this.http.get(`${API_V3_BASE}/help-center-link/document-link/${linkId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Failed to generate document download link', {
          severity: 'error',
          metaData: {
            linkId,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }

  getPolicyDocStatus(link: string) {
    return this.http
      .head(link, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.sentryService.notify('Failed to retrieve document status from link', {
            severity: 'error',
            metaData: {
              link,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf(null);
        })
      );
  }

  getPolicyDoc(link: string, fileName: string) {
    return this.http
      .get(link, {
        headers: { Accept: 'application/pdf' },
        responseType: 'blob',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.sentryService.notify('Failed to retrieve policy document pdf', {
            severity: 'error',
            metaData: {
              link,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf(null);
        })
      )
      .subscribe((data: Blob) => {
        if (data === null) {
          this.sentryService.notify('Policy document is null', {
            severity: 'error',
            metaData: { link },
          });

          return this.informService.infoToast(
            'There was an error downloading this file. Please contact our Customer Care Team.'
          );
        }
        FileSaver.saveAs(data, fileName);
      });
  }
}
