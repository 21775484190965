import { AbstractPolicyPayment } from 'app/shared/models/abstract-policy-payment';
import { WC_POLICY_PAYMENT_PLAN_OPTIONS } from 'app/workers-comp/employers/constants';

export class WcPolicyPayment extends AbstractPolicyPayment {
  constructor(premium: number) {
    super(premium);
  }

  getDownPaymentPremium(numberOfPayments: number) {
    const paymentOption = WC_POLICY_PAYMENT_PLAN_OPTIONS[numberOfPayments];
    return paymentOption.downPayment * this.totalPremium;
  }

  getInstallment(numberOfPayments: number) {
    let numberOfInstallments: number;
    const paymentOption = WC_POLICY_PAYMENT_PLAN_OPTIONS[numberOfPayments];

    if (numberOfPayments === 9 || !paymentOption.downPayment) {
      numberOfInstallments = numberOfPayments;
    } else {
      numberOfInstallments = numberOfPayments - 1;
    }

    if (numberOfInstallments > 0) {
      return (
        (this.totalPremium - this.getDownPaymentPremium(numberOfPayments)) / numberOfInstallments
      );
    }

    return 0;
  }
}
