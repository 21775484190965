import { Directive, HostListener } from '@angular/core';
import { BaseMaskDirective } from './base-mask.directive';
import * as moment from 'moment';
import { US_DATE_MASK } from 'app/constants';

@Directive({
  selector: '[appDateMask]',
})
export class DateMaskDirective extends BaseMaskDirective {
  getMask(value: string, event: KeyboardEvent): string {
    if (value == null) {
      return '';
    }

    let dateLikeValue = value.replace(/\D+/g, '');

    if (dateLikeValue.length > 4) {
      dateLikeValue = dateLikeValue.slice(0, 4) + '/' + dateLikeValue.slice(4, 8);
    }

    if (dateLikeValue.length > 2) {
      dateLikeValue = dateLikeValue.slice(0, 2) + '/' + dateLikeValue.slice(2);
    }

    if (dateLikeValue.length && event) {
      this.updateCursorPosition(<HTMLInputElement>event.target, dateLikeValue);
    }

    return dateLikeValue;
  }

  @HostListener('focusout', ['$event.target.value'])
  onFocusOut(value: string) {
    // If user inputs 0 or 2 digits for the year, Moment will parse and correct the date
    if (value && (value.length <= 6 || value.length === 8)) {
      const parsedDate: string = moment(value, 'MM/DD/YY').format(US_DATE_MASK);
      if (parsedDate !== 'Invalid date') {
        this.updateFormValue(parsedDate);
      }
    }
  }
}
