<div class="policy-pane-content">
  <ng-container *ngIf="quote$ | async as quote">
    <nav class="nav-button-group nav-button-group__space-between mb-4">
      <a class="button button__nav button__no-margin" [routerLink]="['../../']">
        <span class="button-icon icon-arrow-left"></span>
        Back
      </a>
      <div>
        <ng-container *ngIf="!quote.isBound() && quote.version === 'v4'">
          <a
            [routerLink]="'/accounts/' + accountId + '/' + product + '/edit'"
            class="button button__small button__secondary button__no-margin"
          >
            Edit
          </a>
        </ng-container>
        <ng-container *ngIf="!quote.isBound() && quote.version === 'v4'">
          <a
            *ngIf="quote.isQuoted()"
            (click)="continueToBind()"
            (keyup.enter)="continueToBind()"
            class="button button__small button__primary button__no-margin js-new-quote-button"
          >
            Bind
          </a>
        </ng-container>
      </div>
    </nav>

    <app-dialog-box
      *ngIf="quote.isReferred()"
      header="Quote referred"
      type="warning"
      class="js-{{ product }}-{{
        version
      }}-dialog-text dialog-text__margin-top dialog-text__full-width"
    >
      Please contact a Hiscox licensed agent at
      <a href="tel:8664251465">1-866-425-1465</a> (Mon-Fri, 8am-10pm EST) to complete your quote.
      Your information has been saved.
    </app-dialog-box>

    <app-dialog-box
      *ngIf="quote.isDeclined()"
      header="Quote declined"
      type="danger"
      class="dialog-text__margin-top js-{{ product.toUpperCase() }}-{{
        version
      }}-decline-reasons dialog-text__full-width"
    >
      <ul class="bulleted-list" *ngIf="quote.declineReasons && quote.declineReasons.length !== 0">
        {{
          quote.declineReasons.join(' ')
        }}
      </ul>
      Please contact a Hiscox licensed agent at
      <a href="tel:8664251465">1-866-425-1465</a> (Mon-Fri, 8am-10pm EST) for details.
    </app-dialog-box>

    <div class="policy-pane-header">
      <div class="policy-pane-header-left">
        <div class="product-badge product-badge__{{ product }}">
          <div class="product-badge-carrier-icon product-badge-carrier-icon__hiscox"></div>
        </div>
        <div class="policy-pane-quote-info">
          <h2 class="policy-pane-header-h2" *ngIf="quote.status === 'QUOTED'">Quote details</h2>
          <h2 class="policy-pane-header-h2" *ngIf="quote.status === 'BOUND'">Policy details</h2>
          <small class="gray-text">{{
            quote.status === 'BOUND' ? quote.policyNumber : quote.quoteId
          }}</small>
        </div>
      </div>
      <div *ngIf="quote.premium" class="policy-pane-header-right">
        <h2 class="policy-pane-header-h2 js-premium-number">
          {{ quote.premium | currency: 'USD':'symbol':'1.0-0' }}
        </h2>
        <small class="gray-text">Premium</small>
      </div>
    </div>
    <app-document-download
      *ngIf="!quote.isBound() && !quote.isDeclined() && !quote.isReferred() && !quote.isUnknown()"
      displayName="Quote Letter"
      [documentDownload$]="quoteLetterDownload$"
      [documentDownloadErrorMessage]="quoteLetterErrorMessage"
    >
    </app-document-download>
    <div class="policy-detail-subsection">
      <dl>
        <dt>Status</dt>
        <dd>
          <span
            class="pill-tag"
            [ngClass]="{
              'pill-tag__quoted': quote.isQuoted(),
              'pill-tag__warning': quote.isReferred(),
              'pill-tag__bad': quote.isDeclined(),
              'pill-tag__main': quote.isBound(),
            }"
          >
            {{ quote.status | lowercase }}
          </span>
        </dd>
      </dl>
      <dl>
        <dt>Effective date</dt>
        <dd>{{ quote.effectiveDate ? quote.effectiveDate.format(US_DATE_MASK) : '—' }}</dd>
      </dl>
      <dl *ngIf="!!quote.quoteId && quote.status === 'QUOTED'">
        <dt>Quote number</dt>
        <dd>{{ quote.quoteId }}</dd>
      </dl>
      <dl *ngIf="!!quote.quoteId && quote.status === 'BOUND'">
        <dt>Policy number</dt>
        <dd>{{ quote.policyNumber }}</dd>
      </dl>

      <div *ngIf="quote.status === 'BOUND'">
        <app-post-bind-servicing-information carrier="hiscox">
        </app-post-bind-servicing-information>
      </div>
    </div>
    <div class="policy-detail-subsection" *ngIf="quote.status !== 'BOUND'">
      <app-quote-servicing-information carrier="hiscox"></app-quote-servicing-information>
    </div>
  </ng-container>
</div>
<app-product-cross-sell
  [open]="
    (isCrossSellWindowEnabled &&
      !isCrossSellWindowDismissed &&
      isAccountEligibleForCyber &&
      isBrokerEnabledForCyber &&
      !doesAccountHaveCyberQuotes) ||
    showUpsellStatusCard
  "
  [config]="hiscoxGlPlCyberCrossSellConfiguration"
  (handleClose)="handleProductCrossSellWindowEvent($event)"
>
</app-product-cross-sell>
<ng-container *ngIf="quote?.isQuoted()">
  <app-bundle-quote-review-modal
    [open]="showBundleQuoteReview"
    [primaryProductName]="productForBundleQuoteReview"
    [primaryDocumentDownload$]="quoteLetterDownload$"
    [primaryDocumentDownloadReady$]="quoteLetterDownloadReady$"
    [primaryProductPremium]="quote.premium"
    [secondaryQuote]="secondaryQuote$ | async"
    [secondaryDocumentDownload$]="secondaryQuoteLetterDownload$"
    (closeModal)="handleBundleQuoteReviewModalClose()"
  ></app-bundle-quote-review-modal>
</ng-container>
