<div class="app-page app-page__form">
  <div
    class="
      app-page-form-scrollable-wrapper app-page-form-scrollable-wrapper__full-height
      app-page-form-with-sidebar-wrapper
    "
  >
    <div class="app-page-form" content>
      <a
        *ngIf="!isFirstStep()"
        class="emphasized-link form-previous-link"
        (click)="clickBackward()"
        (keyup.enter)="clickBackward()"
      >
        <span class="icon-full-arrow-left button-icon" role="presentation"></span>Previous step
      </a>
      <ng-container>
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="handleSubmit($event)" novalidate>
          <router-outlet></router-outlet>

          <div *ngIf="this.isFirstStep() && showExploreAppetite" class="mb-2">
            <a (click)="exploreAppetite()" (keyup.enter)="exploreAppetite()">
              <span class="explore-appetite">Explore Attune’s appetite first</span>
            </a>
          </div>

          <p *ngIf="stepIsInvalid()" class="validation validation__align-left" role="alert">
            <span class="validation-message">Please fill out all required fields</span>
          </p>

          <p *ngIf="showAccountsNote()">We'll take you to your accounts now</p>

          <button
            id="onboarding-form-submit-page-button"
            *ngIf="this.isCurrentStepValid()"
            type="submit"
            class="button button__primary button__form-margin js-step-forward-button"
            [disabled]="quoting"
            attr.aria-describedby="{{ showSampleQuoteTip() ? 'sample-quote-tip' : '' }}"
          >
            <span *ngIf="!loading">{{ currentStep().nextButtonText }}</span>
            <span *ngIf="loading">Getting ready...</span>
            <app-education-pane
              position="right-top"
              *ngIf="showSampleQuoteTip()"
              elementId="sample-quote-tip"
            >
              We’ll fill in the fields for you using a fake {{ selectedClassDisplayName }}.
            </app-education-pane>
          </button>
          <button
            *ngIf="showSkipButton()"
            class="button button__secondary button__form-margin button__onboarding-skip"
            [disabled]="skipLoading || loading"
            (click)="handleSkip($event)"
          >
            <span *ngIf="!skipLoading">Skip</span>
            <span *ngIf="skipLoading">Skipping...</span>
          </button>

          <p *ngIf="!this.isFirstStep() && showExploreAppetite">
            <a (click)="exploreAppetite()" (keyup.enter)="exploreAppetite()">
              <span class="explore-appetite">Explore Attune’s appetite first</span>
            </a>
          </p>

          <ng-container *ngIf="isDevMode">
            <div class="adminz-only">
              <pre>{{ form.value | json }}</pre>
            </div>
          </ng-container>
        </form>
      </ng-container>
    </div>

    <div class="form-flow-rail">
      <app-incentives-new-users></app-incentives-new-users>
    </div>
  </div>
</div>
