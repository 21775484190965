import { BopPolicy } from 'app/features/attune-bop/models/bop-policy';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  getBuildingClassificationsForLocations,
  canClassificationsHavePizza,
  classificationCanHaveAlcohol,
  classificationServesAlcohol,
  classificationHasByobAlcohol,
  spoilageClassificationDescription,
} from 'app/features/attune-bop/models/classification-helpers';
import {
  isContractor,
  isRestaurant,
} from 'app/features/attune-bop/services/attune-bop-building-classification.service';

import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import {
  DEBRIS_REMOVAL_LIMIT_DEFAULT,
  SpoilageDescription,
  NO_WINDSTORM_OR_HAIL_EXCLUSION_STATES,
  REQUIRED_BOPV1_WIND_COVERAGE_STATES,
  REQUIRED_BOPV2_WIND_COVERAGE_STATES,
  BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_25000,
  BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_10000,
  IL_LL_SOCIETAL_LIMIT_CODE,
  IL_LL_PROPERTY_LIMIT_CODE,
  IL_LL_BODILY_INJURY_LIMIT_CODE,
} from 'app/features/attune-bop/models/constants';
import { US_DATE_MASK } from 'app/constants';
import { hasContractorBusinessType, hasAbuseRiskClassifications } from './business-type';
import { getBuildingTypeId, getClassificationTypeId, getLocationTypeId } from './type-ids';
import { Clause } from 'app/bop/guidewire/typings';

const ALL_COVERED_JOB_SITE_LIMITS_MULTIPLE = 3;

export const determineLimitForEmployeeDishonesty = (policy: BopPolicy) => {
  const businessType = policy.locations[0].buildings[0].exposure.businessType;
  const lessorsRisk = policy.locations[0].buildings[0].exposure.lessorsRisk;
  const limitForEmployeeDishonesty = parseInt(
    policy.additionalCoverages.limitForEmployeeDishonesty,
    10
  );
  if (
    BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_10000.includes(businessType) &&
    !lessorsRisk &&
    limitForEmployeeDishonesty === 10000
  ) {
    return 0;
  }
  if (
    BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_25000.includes(businessType) &&
    !lessorsRisk &&
    limitForEmployeeDishonesty === 25000
  ) {
    return 0;
  }
  return limitForEmployeeDishonesty;
};

export function coverageTerms(policy: BopPolicy, insuredAccount: InsuredAccount): Clause[] {
  // We will use both of these in several places, so pull them into constants
  // here to make their reuse more clear.

  const generalLiabilityPerOccurrenceLimit = parseInt(
    policy.liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses,
    10
  );
  const generalLiabilityAggregateLimit = 2 * generalLiabilityPerOccurrenceLimit;
  const coverages: Clause[] = locationCoverageTerms(policy, insuredAccount)
    .concat(hiredNonOwnedAutoCoverageTerms(policy))
    .concat([enhancementCoverageTerm(policy)])
    .concat([
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7AccsDisclConfidPersonInfoDataRelatedLiabNoBIExcepIncl_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: '60',
              PatternCode: 'BP7BusnIncomeOrdinaryPayrollNumDays',
            },
            {
              CovTermValueForRating_HUSA: 'false',
              PatternCode: 'BP7Exempt',
            },
            {
              CovTermValueForRating_HUSA: '60',
              PatternCode: 'BP7BusnIncomeExtddPeriodNumDays',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7BusinessIncome',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: generalLiabilityPerOccurrenceLimit,
              PatternCode: 'BP7EachOccLimit',
            },
            {
              CovTermValueForRating_HUSA: parseInt(
                policy.liabilityCoverages.limitPerPersonOfMedicalExpenses,
                10
              ),
              PatternCode: 'BP7OptionalMedicalCovLimitPerPerson',
            },
            {
              CovTermValueForRating_HUSA: parseInt(policy.liabilityCoverages.damageToPremises, 10),
              PatternCode: 'BP7TenantsFireLiabLimit',
            },
            {
              CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
              PatternCode: 'BP7AggregateLimit',
            },
            {
              CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
              PatternCode: 'BP7ProdCompldOpsAggregateLimit',
            },
            {
              CovTermValueForRating_HUSA: 'NoDeductible',
              PatternCode: 'BP7PropDamageLiabDed',
            },
            {
              CovTermValueForRating_HUSA: 'NotApplicable',
              PatternCode: 'BP7PropDamageLiabDedType',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7BusinessLiability',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: 10000,
              PatternCode: 'BP7Limit5',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7ElectronicData',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: determineLimitForEmployeeDishonesty(policy),
              PatternCode: 'BP7Limit6',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7EmployeeDishty',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7EmploymentRelatedPracticesExcl',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: null,
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7ExclSilicaSilicaRelatedDust',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: '2500',
              PatternCode: 'BP7Limit7',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7ForgeryAlteration',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7FungiBacteriaExclLiability',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: 10000,
              PatternCode: 'BP7Limit8',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7InterruptionComputerOps',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: String(
                policy.additionalCoverages.acceptCertifiedActsOfTerrorismCoverage
              ),
              PatternCode: 'BP7AccptCertifiedActs_HUSA',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7Terrorism_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7_LineAdditionalCov_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7MinimumPremiumAdjustment_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7AsbestosExclusion_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: null,
              PatternCode: 'BP7AdditionalInfo',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7DisclosurePursuantToTRIA2002',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7TotPolltnExclHeatEquipAndHostileFireExcptn',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7ExclCertfdActsTerrsmCovFireLosses',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: null,
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7CapLossesFromCertfdActsTerrsm',
        },
        ScheduledItems_HUSA: null,
      },
    ]);

  if (
    policy.additionalCoverages.cyberLiabilityCoverage &&
    policy.additionalCoverages.cyberLiabilityCoverage.optedIn &&
    policy.policyInfo.baseState !== 'NY'
  ) {
    let extraCoverageTerms: Array<{
      CovTermValueForRating_HUSA: number | null;
      PatternCode: string;
    }>;

    if (policy.additionalCoverages.cyberLiabilityCoverage.aggregateLimit !== 50000) {
      if (insuredAccount.state === 'KS') {
        extraCoverageTerms = [
          {
            CovTermValueForRating_HUSA:
              policy.additionalCoverages.cyberLiabilityCoverage.aggregateLimit,
            PatternCode: 'Bp7PCIAmountsSublimit',
          },
        ];
      } else {
        extraCoverageTerms = [
          {
            CovTermValueForRating_HUSA:
              policy.additionalCoverages.cyberLiabilityCoverage.aggregateLimit,
            PatternCode: 'BP7PCIFines',
          },
        ];
      }
    } else {
      extraCoverageTerms = [];
    }

    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: policy.cyberLiabilityRetroactiveMoment().format('Y-MM-DD'),
            PatternCode: 'BP7RetroactiveDate1',
          },
          {
            CovTermValueForRating_HUSA:
              policy.additionalCoverages.cyberLiabilityCoverage.deductible,
            PatternCode: 'BP7DeductibleTerm',
          },
          {
            CovTermValueForRating_HUSA:
              policy.additionalCoverages.cyberLiabilityCoverage.aggregateLimit,
            PatternCode: 'BP7CoverageEndorsementAggregate',
          },
        ].concat(extraCoverageTerms),
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7DataResponseCyberLiability',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.policyInfo.bopVersion === 2 && policy.additionalCoverages.hasWaiversOfSubrogation) {
    coverages.push({
      CovTerms: null,
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7WaiverTransferRightsAgainstOthersToUs',
      },
    });
  } else if (policy.additionalCoverages.hasWaiversOfSubrogation) {
    const subrogationValue = policy.additionalCoverages.waiversOfSubrogation.map((value, index) => {
      return {
        FieldValue1: value.orgOrPersonName,
        ScheduleNumber: index + 1,
      };
    });
    coverages.push({
      CovTerms: null,
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7WaiverTransferRightsOfRecoveryAgainstOthersToUs',
      },
      ScheduledItems_HUSA: {
        Entry: subrogationValue,
      },
    });
  }

  if (policy.liabilityCoverages.employeeBenefitsLiabilityCoverage.optedIn) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: "Named insured's employee benefit program",
            PatternCode: 'BP7EmployeeBenefitsProgramDesc',
          },
          {
            // This is indeed the format, and it is indeed different from
            // all the other date formats in the payload. For details, see
            // bp7-data-response-cyber-liability.directive.spec.ts in BOPv1.
            CovTermValueForRating_HUSA: policy
              .employeeBenefitsLiabilityRetroactiveMoment()
              .format('Y-MM-DD'),
            PatternCode: 'BP7RetroDate',
          },
          {
            CovTermValueForRating_HUSA:
              policy.liabilityCoverages.employeeBenefitsLiabilityCoverage.eachEmployeeLimit,
            PatternCode: 'BP7EachEmployeeLimit_HUSA',
          },
          {
            CovTermValueForRating_HUSA:
              policy.liabilityCoverages.employeeBenefitsLiabilityCoverage.aggregateLimit,
            PatternCode: 'BP7AggregateLimit1_HUSA',
          },
          {
            CovTermValueForRating_HUSA:
              policy.liabilityCoverages.employeeBenefitsLiabilityCoverage.deductible,
            PatternCode: 'BP7Deductible1_HUSA',
          },
          {
            // According to BOPv1, this value translates to '4 or greater.'
            // See quote-submission-form-options.cyber-constants.model.ts in BOPv1.
            CovTermValueForRating_HUSA: 4,
            PatternCode: 'BP7ConsecYears_HUSA1',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7EmpBenefitsLiabCov',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage.optedIn) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 10000,
            PatternCode: 'BP7CovType',
          },
          {
            CovTermValueForRating_HUSA: parseInt(
              policy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimit,
              10
            ),
            PatternCode: 'BP7AggLimit1',
          },
          {
            CovTermValueForRating_HUSA: '500max5',
            PatternCode: 'BP7LossWagesSubLimit_HUSA',
          },
          {
            CovTermValueForRating_HUSA:
              policy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage.deductible,
            PatternCode: 'BP7Ded',
          },
          {
            CovTermValueForRating_HUSA: moment(
              policy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage
                .retroactiveDate,
              US_DATE_MASK
            ).format('Y-MM-DD'),
            PatternCode: 'BP7RetroDate1',
          },
          {
            CovTermValueForRating_HUSA: null,
            PatternCode: 'BP7PriorOrPendingLitigationDate',
          },
          {
            CovTermValueForRating_HUSA: 'true',
            PatternCode: 'BP7ContinuousCov_HUSA',
          },
          {
            CovTermValueForRating_HUSA: parseInt(
              policy.additionalCoverages.employmentRelatedPracticesLiabilityCoverage
                .eachEmploymentWrongfulActLimit,
              10
            ),
            PatternCode: 'BP7SuppllLimit',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7EmploymentRelatedPracticesLiabilityCov',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.acceptSnowPlowCompletedOpsCoverage) {
    coverages.push({
      CovTerms: null,
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7SnowPlowProdsCompldOpsHzdCov',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (hasContractorBusinessType(policy)) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: parseInt(
              policy.liabilityCoverages.eachCoveredJobSiteLimit,
              10
            ),
            PatternCode: 'BP7EachCoveredJobSiteLimit',
          },
          {
            CovTermValueForRating_HUSA:
              parseInt(policy.liabilityCoverages.eachCoveredJobSiteLimit, 10) *
              ALL_COVERED_JOB_SITE_LIMITS_MULTIPLE,
            PatternCode: 'BP7AllCoveredJobSitesLimit',
          },
          {
            CovTermValueForRating_HUSA: parseMaskedInt(
              policy.liabilityCoverages.propertyInTransitLimit
            ),
            PatternCode: 'BP7PropInTransit_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7ContrctrsInstalltnTypes',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (hasAbuseRiskClassifications(policy)) {
    coverages.push({
      CovTerms: {
        Entry: [],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7AbuseMolestationExcl',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.opticalAndHearingAidEstablishmentsProfessionalLiability) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            // (via Sandeep on 2018-03-22) The field is badly named in GW; the
            // user-facing label should indeed be "Optical and Hearing Sales."
            CovTermValueForRating_HUSA: parseMaskedInt(
              policy.liabilityCoverages.opticalAndHearingAidSales
            ),
            PatternCode: 'BP7OptPayroll_HUSA',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityPerOccurrenceLimit,
            PatternCode: 'BP7OptPerOccuLim_HUSA',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
            PatternCode: 'BP7OptAggLimit_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7OpticalHearingAid',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.printersErrorsAndOmissionsProfessionalLiability) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA:
              insuredAccount.state === 'NY'
                ? null
                : parseMaskedInt(
                    policy.liabilityCoverages.printersErrorsAndOmissionsSalesOrPayroll
                  ),
            PatternCode: 'BP7PriPayroll_HUSA',
          },
          {
            CovTermValueForRating_HUSA:
              insuredAccount.state === 'NY'
                ? parseMaskedInt(policy.liabilityCoverages.printersErrorsAndOmissionsSalesOrPayroll)
                : null,
            PatternCode: 'BP7PrinterSales_HUSA',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityPerOccurrenceLimit,
            PatternCode: 'BP7PriPerOccuLim_HUSA',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
            PatternCode: 'BP7PriAggLimit_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7PrintersErrorsOmissions',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.funeralDirectorsProfessionalLiability) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 113,
            PatternCode: 'BP7AnnualNumberofDeceasedHumanBodies',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
            PatternCode: 'BP7AggregateLimitFlatCharge1',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityPerOccurrenceLimit,
            PatternCode: 'BP7OccurrenceLimit1',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7FuneralDirectorsProflLiab',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.barberShopsAndHairSalonsProfessionalLiability) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: generalLiabilityPerOccurrenceLimit,
            PatternCode: 'BP7BarbPerOccuLim_HUSA',
          },
          {
            CovTermValueForRating_HUSA: parseMaskedInt(
              policy.liabilityCoverages.barberShopsNumberOfOperators
            ),
            PatternCode: 'BP7BarbNoOper_HUSA',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
            PatternCode: 'BP7BarbAggLimit_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7BarbersBeauticiansProflLiab',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.beautySalonsProfessionalLiability) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: generalLiabilityPerOccurrenceLimit,
            PatternCode: 'BP7BeauPerOccuLim_HUSA',
          },
          {
            CovTermValueForRating_HUSA:
              'Beauty Parlors and Hair Styling Salons including Nail Salons',
            PatternCode: 'BP7AddlServicesDesc',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
            PatternCode: 'BP7BeauAggLimit_HUSA',
          },
          {
            CovTermValueForRating_HUSA: parseMaskedInt(
              policy.liabilityCoverages.beautySalonsNumberOfOperators
            ),
            PatternCode: 'BP7BeauNoOper_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7BeautySalonsProflLiab',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.veterinariansProfessionalLiability) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: parseMaskedInt(
              policy.liabilityCoverages.numberOfVeterinarians
            ),
            PatternCode: 'BP7NumVeterinarians',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityAggregateLimit,
            PatternCode: 'BP7VetAggLimit_HUSA',
          },
          {
            CovTermValueForRating_HUSA: generalLiabilityPerOccurrenceLimit,
            PatternCode: 'BP7VetPerOccuLim_HUSA',
          },
          {
            CovTermValueForRating_HUSA: String(
              policy.liabilityCoverages.veterinariansOnlyTreatHousePets
            ),
            PatternCode: 'BP7VetTret_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7VeterinariansProflLiab',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (policy.liabilityCoverages.liquorLiability.optedIn) {
    const inIL = policy.policyInfo.baseState === 'IL';
    const stateBasedEntries = [
      {
        CovTermValueForRating_HUSA: inIL
          ? 'NotApplicable'
          : policy.liabilityCoverages.liquorLiability.eachCommonCauseLimit,
        PatternCode: 'BP7LiquorEachCommonCauseLimit1',
      },
      {
        CovTermValueForRating_HUSA: inIL ? IL_LL_BODILY_INJURY_LIMIT_CODE : null,
        PatternCode: 'BP7EachOccurrenceBILimit_IL_HUSA',
      },
      {
        CovTermValueForRating_HUSA: inIL ? IL_LL_PROPERTY_LIMIT_CODE : null,
        PatternCode: 'BP7EachOccurrencePDLimit_IL_HUSA',
      },
      {
        CovTermValueForRating_HUSA: inIL ? IL_LL_SOCIETAL_LIMIT_CODE : null,
        PatternCode: 'BP7SocietalLimit_IL_HUSA',
      },
    ];

    coverages.push({
      CovTerms: {
        Entry: [
          ...stateBasedEntries,
          {
            CovTermValueForRating_HUSA: policy.liabilityCoverages.liquorLiability.aggregateLimit,
            PatternCode: 'BP7LiquorAggregateLimit1',
          },
          {
            CovTermValueForRating_HUSA: _.flatMap(
              policy.locations,
              (location) => location.buildings
            )
              .some((building) =>
                classificationHasByobAlcohol(building.exposure.classification.code)
              )
              .toString(),
            PatternCode: 'BP7BYOAEstablishments_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7LiquorLiabCov',
      },
      ScheduledItems_HUSA: null,
    });

    // UTAH LiquorLiab requires and additional coverage term
    if (policy.policyInfo.baseState === 'UT') {
      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA:
                policy.liabilityCoverages.liquorLiability.eachCommonCauseLimit,
              PatternCode: 'BP7EachPersonLimit_HUSA',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: 'entity.BP7BusinessOwnersLine',
        },
        Pattern: {
          Code: 'BP7UTLiquorLiabCov_HUSA',
        },
        ScheduledItems_HUSA: null,
      });
    }
  }

  return coverages;
}

function locationCoverageTerms(policy: BopPolicy, insuredAccount: InsuredAccount): Clause[] {
  return _.flatMap(policy.locations, (location, locationIndex) => {
    const locationTypeId = getLocationTypeId(locationIndex);
    return buildingCoverageTerms(
      location.buildings,
      locationIndex,
      insuredAccount,
      location,
      policy.policyInfo.bopVersion
    ).concat([
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: '2',
              PatternCode: 'BP7AutomaticIncreasePct',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: locationTypeId,
        },
        Pattern: {
          Code: 'BP7LocationAutomaticIncreasePct',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: 'NotApplicable',
              PatternCode: 'BP7EmployeeDishtyApply',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: locationTypeId,
        },
        Pattern: {
          Code: 'BP7LocationEmployeeDishty',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: 2500,
              PatternCode: 'BP7Limit9',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: locationTypeId,
        },
        Pattern: {
          Code: 'BP7LocationFireDeptService',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: locationTypeId,
        },
        Pattern: {
          Code: 'BP7_LocAdditionalCov_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: 500,
              PatternCode: 'BP7BaseDeductible',
            },
            {
              CovTermValueForRating_HUSA: parseInt(location.locationDetails.propertyDeductible, 10),
              PatternCode: 'BP7OptionalDeductible',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: locationTypeId,
        },
        Pattern: {
          Code: 'BP7LocationPropertyDeductibles',
        },
        ScheduledItems_HUSA: null,
      },
    ]);
  });
}

function enhancementCoverageTerm(policy: BopPolicy): Clause {
  const firstBuilding = policy.locations[0].buildings[0];
  const firstBuildingCodeDescription: string = _.get(
    firstBuilding,
    'exposure.classification.code.descriptionCode',
    ''
  );

  const hasLessorsRisk: boolean = firstBuilding.exposure.lessorsRisk || false;

  let enhancementPatternCode = '';
  if (hasLessorsRisk) {
    enhancementPatternCode = 'BP7LessorRiskEnhacement';
  } else if (isContractor(firstBuildingCodeDescription)) {
    enhancementPatternCode = 'BP7ContractorsEnhacement';
  } else if (isRestaurant(firstBuildingCodeDescription)) {
    enhancementPatternCode = 'BP7RestaurantsEnhacement';
  } else {
    enhancementPatternCode = 'BP7BusinessOwnersEnhacement';
  }

  return {
    CovTerms: null,
    OwningCoverable: {
      TypeIDString: 'entity.BP7BusinessOwnersLine',
    },
    Pattern: {
      Code: enhancementPatternCode,
    },
    ScheduledItems_HUSA: null,
  };
}

function getEquipmentBreakdown(buildingTypeId: string): Clause {
  const equimentBreakDownCoverage: Clause = {
    CovTerms: {
      Entry: [
        { CovTermValueForRating_HUSA: 50000, PatternCode: 'BP7EBDataRestoration2_HUSA' },
        { CovTermValueForRating_HUSA: 50000, PatternCode: 'BP7EBExpeditingExpenses2_HUSA' },
        { CovTermValueForRating_HUSA: 50000, PatternCode: 'BP7EBHazSubstances2_HUSA' },
        { CovTermValueForRating_HUSA: 25000, PatternCode: 'BP7EBOffPremEquipBreakdowns2_HUSA' },
        { CovTermValueForRating_HUSA: 5000, PatternCode: 'BP7EBPublicRelations2_HUSA' },
        { CovTermValueForRating_HUSA: 50000, PatternCode: 'BP7EBSpoilage2_HUSA' },
        { CovTermValueForRating_HUSA: 1000, PatternCode: 'BP7EBDirectCoverages2_HUSA' },
        { CovTermValueForRating_HUSA: 72, PatternCode: 'BP7EBIndirectCoverages2_HUSA' },
      ],
    },
    OwningCoverable: { TypeIDString: buildingTypeId },
    Pattern: { Code: 'BP7EquipmentBreakdownCoverage2' },
    ScheduledItems_HUSA: null,
  };
  return equimentBreakDownCoverage;
}

function buildingCoverageTerms(
  buildings: Array<BopBuilding>,
  locationIndex: number,
  insuredAccount: InsuredAccount,
  location: BopLocation,
  bopVersion: BopVersion
): Clause[] {
  return _.flatMap(buildings, (building, buildingIndex) => {
    const buildingTypeId = getBuildingTypeId(locationIndex, buildingIndex);
    const locationTypeId = getLocationTypeId(locationIndex);
    const coverages: Array<Clause> = [
      {
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: '25.0000',
              PatternCode: 'BP7BPPSeasonalIncrease2_HUSA',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7BPPSeasonalIncrease_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
      {
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7_BuildAddCov_HUSA',
        },
        ScheduledItems_HUSA: null,
      },
    ];
    if (building.coverage.equipmentBreakdownCoverageOptedIn) {
      coverages.push(getEquipmentBreakdown(buildingTypeId));
    }
    if (building.coverage.windCoverageOptedIn) {
      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: building.coverage.windDeductiblePercent || null,
              PatternCode: 'BP7WindOrHailDed_HUSA',
            },
            {
              CovTermValueForRating_HUSA: building.coverage.windDeductible,
              PatternCode: 'BP7MinWinDedAmt_HUSA',
            },
            {
              CovTermValueForRating_HUSA: 'BP7_WindstormorHailPercDeductible',
              PatternCode: 'BP7WindHurCoverForm_HUSA',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7BuildWind_HUSA',
        },
        ScheduledItems_HUSA: null,
      });
    } else if (!_.includes(NO_WINDSTORM_OR_HAIL_EXCLUSION_STATES, insuredAccount.state)) {
      coverages.push({
        CovTerms: null,
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7WindstormOrHailExcl',
        },
        ScheduledItems_HUSA: null,
      });
    }
    let requiredWindCoverageStates = [];
    if (bopVersion === 2) {
      requiredWindCoverageStates = REQUIRED_BOPV2_WIND_COVERAGE_STATES;
    } else {
      requiredWindCoverageStates = REQUIRED_BOPV1_WIND_COVERAGE_STATES;
    }
    if (
      _.includes(requiredWindCoverageStates, location.locationDetails.state) ||
      building.coverage.windCoverageOptedIn
    ) {
      coverages.push({
        CovTerms: {
          Entry: [],
        },
        OwningCoverable: {
          TypeIDString: locationTypeId,
        },
        Pattern: {
          Code: 'BP7LocWind_HUSA',
        },
        ScheduledItems_HUSA: null,
      });
    }

    if (building.coverage.windLossMitigationPresent && building.coverage.windCoverageOptedIn) {
      const windLossBuildingType =
        parseInt(building.exposure.storyCount, 10) < 4 ? 'TypeI' : 'Type2';
      const wlm = building.coverage.windLossMitigation;
      const scbcString = wlm.SCBCCompliant ? 'SCBC' : 'Non-SCBC';
      const swrString = wlm.secondaryWaterResistance ? 'SWR' : 'NSWR';

      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: windLossBuildingType,
              PatternCode: 'BuildType_HUSA',
            },
            {
              CovTermValueForRating_HUSA: scbcString,
              PatternCode: 'BuildCode_HUSA',
            },
            {
              CovTermValueForRating_HUSA: wlm.level,
              PatternCode: 'Level_HUSA',
            },
            {
              CovTermValueForRating_HUSA: wlm.roofToWallConnection,
              PatternCode: 'RoofWallCon_HUSA',
            },
            {
              CovTermValueForRating_HUSA: wlm.openingProtection,
              PatternCode: 'OpenProt_HUSA',
            },
            {
              CovTermValueForRating_HUSA: wlm.doorStrength,
              PatternCode: 'DoorStren_HUSA',
            },
            {
              CovTermValueForRating_HUSA: swrString,
              PatternCode: 'SeconWatRes_HUSA',
            },
            {
              CovTermValueForRating_HUSA: wlm.roofShape,
              PatternCode: 'RoofShape_HUSA',
            },
            {
              CovTermValueForRating_HUSA: wlm.roofAndDeckAttachment,
              PatternCode: 'RoofDeck2_HUSA',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7WindLossMitig_HUSA',
        },
        ScheduledItems_HUSA: null,
      });
    }
    if (parseMaskedInt(building.exposure.buildingLimit) > 0) {
      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: parseMaskedInt(building.exposure.buildingLimit),
              PatternCode: 'BP7BuildingLimit',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7Structure',
        },
        ScheduledItems_HUSA: null,
      });
    }

    if (building.coverage.businessIncomeAndExtraExpensesIndemnityInMonths !== 12) {
      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA:
                building.coverage.businessIncomeAndExtraExpensesIndemnityInMonths,
              PatternCode: 'BP7NumOfMonths',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7BusnIncomeAndExtraExpenseCovRevisedPerOfIndemni',
        },
        ScheduledItems_HUSA: null,
      });
    }

    if (building.coverage.debrisRemoval > DEBRIS_REMOVAL_LIMIT_DEFAULT) {
      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: building.coverage.debrisRemoval,
              PatternCode: 'BP7Limit21',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7DebrisRmvlAddlIns',
        },
        ScheduledItems_HUSA: null,
      });
    }
    if (building.coverage.utilityServicesTimeElement > 0) {
      coverages.push({
        CovTerms: null,
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7UtilitySrvcsTimeElement1',
        },
        ScheduledItems_HUSA: {
          Entry: [
            {
              BoolCol1_HUSA: false,
              BoolCol2_HUSA: true,
              BoolCol3_HUSA: true,
              BP7NonNegative1_HUSA: 0,
              FieldValue1: null,
              FieldValue2: null,
              ReferenceDateInternal: null,
              ScheduleClauses_HUSA: {
                Entry: [
                  {
                    CovTerms: {
                      Entry: [
                        {
                          CovTermValueForRating_HUSA: building.coverage.utilityServicesTimeElement,
                          PatternCode: 'BP7UtilitySrvcsTimeElementSubLimit',
                        },
                      ],
                    },
                    OwningCoverable: {
                      TypeIDString: 'entity.BP7BuildingSchedCovItem:6303',
                    },
                    Pattern: {
                      Code: 'BP7UtilitySrvcsTimeElementScheduleItem',
                    },
                    ScheduledItems_HUSA: null,
                  },
                ],
              },
              ScheduleNumber: 1,
              StringCol3: null,
              StringCol4_HUSA: null,
              TypeKeyValue1: 'OTL_Included',
              TypeKeyValue2: 'public_utilities',
              TypeKeyValue3: 'OTL_Included',
              TypeKeyValue4: null,
            },
          ],
        },
      });
    }

    if (building.coverage.utilityServicesDirectDamage > 0) {
      coverages.push({
        CovTerms: null,
        OwningCoverable: {
          TypeIDString: buildingTypeId,
        },
        Pattern: {
          Code: 'BP7UtilitySrvcsDirectDamageForBuilding',
        },
        ScheduledItems_HUSA: {
          Entry: [
            {
              BP7NonNegative1_HUSA: 1000,
              BoolCol1_HUSA: false,
              BoolCol2_HUSA: true,
              BoolCol3_HUSA: true,
              FieldValue1: null,
              FieldValue2: null,
              ReferenceDateInternal: null,
              ScheduleClauses_HUSA: {
                Entry: [
                  {
                    CovTerms: {
                      Entry: [
                        {
                          CovTermValueForRating_HUSA: building.coverage.utilityServicesDirectDamage,
                          PatternCode: 'BP7UtilitySrvcsDirectDamageSubLimit',
                        },
                      ],
                    },
                    OwningCoverable: {
                      TypeIDString: 'entity.BP7BuildingSchedCovItem:6304',
                    },
                    Pattern: {
                      Code: 'BP7UtilitySrvcsDirectDamageScheduleItem',
                    },
                    ScheduledItems_HUSA: null,
                  },
                ],
              },
              ScheduleNumber: 1,
              StringCol3: null,
              StringCol4_HUSA: null,
              TypeKeyValue1: 'BusiPersonalProp',
              TypeKeyValue2: 'public_utilities',
              TypeKeyValue3: 'OTL_Included',
              TypeKeyValue4: 'OTL_Included',
            },
          ],
        },
      });
    }
    if (building.coverage.ordinanceLawCoverageOptedIn) {
      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: 'Coverage1withCoverages2and3Combined',
              PatternCode: 'BP7',
            },
            {
              CovTermValueForRating_HUSA: building.coverage.ordinanceLawCoverageValue,
              PatternCode: 'BP7Limit24',
            },
            {
              CovTermValueForRating_HUSA: 0,
              PatternCode: 'BP7HoursWaitingPeriod',
            },
            {
              CovTermValueForRating_HUSA: 'false',
              PatternCode: 'BP7BusnIncomeAndExtraExpenseOptional',
            },
          ],
        },
        OwningCoverable: { TypeIDString: buildingTypeId },
        Pattern: { Code: 'BP7OrdinanceOrLawCov' },
        ScheduledItems_HUSA: null,
      });
    }

    return classificationCoverageTerms(
      building.exposure.classification,
      parseMaskedInt(building.exposure.limitForBusinessPersonalProperty),
      locationIndex,
      buildingIndex,
      building
    ).concat(coverages);
  });
}

function classificationCoverageTerms(
  classification: BuildingClassificationHolder,
  limitForBusinessPersonalProperty: number,
  locationIndex: number,
  buildingIndex: number,
  building: BopBuilding
): Clause[] {
  const hasLessorsRisk: boolean = building.exposure.lessorsRisk || false;
  const classificationTypeId = getClassificationTypeId(locationIndex, buildingIndex);
  const coverages: Clause[] = [
    {
      CovTerms: {
        Entry: [],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7_ClassAdditionalCov_HUSA',
      },
      ScheduledItems_HUSA: null,
    },
    {
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 2500,
            PatternCode: 'BP7Limit39',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7ClassificationOutdoorProperty',
      },
      ScheduledItems_HUSA: null,
    },
    {
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 10000,
            PatternCode: 'BP7Limit1',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7ClassificationBPPTemporarilyInPortableStorageUn',
      },
      ScheduledItems_HUSA: null,
    },
    {
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 5000,
            PatternCode: 'BP7Limit38',
          },
          {
            CovTermValueForRating_HUSA: 'false',
            PatternCode: 'BP7BusinessIncomeFromSecondaryDependentProperties',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7ClassificationBusinessIncomeFromDependentProps',
      },
      ScheduledItems_HUSA: null,
    },
    {
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 10000,
            PatternCode: 'BP7Limit',
          },
          {
            CovTermValueForRating_HUSA: 5000,
            PatternCode: 'BP7AccountsReceivableOffLimit_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7ClassificationAccountsReceivable',
      },
      ScheduledItems_HUSA: null,
    },
    {
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 10000,
            PatternCode: 'BP7Limit3',
          },
          {
            CovTermValueForRating_HUSA: 5000,
            PatternCode: 'BP7ValuablePapersOffLimit_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7ClassificationValuablePapers',
      },
      ScheduledItems_HUSA: null,
    },
    {
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 2500,
            PatternCode: 'BP7Limit14',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7TheftLimitations',
      },
      ScheduledItems_HUSA: null,
    },
  ];

  if (
    !hasLessorsRisk &&
    classification.alcoholSales &&
    (classificationCanHaveAlcohol(classification.code) ||
      classificationServesAlcohol(classification.code))
  ) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: parseMaskedInt(classification.alcoholSales),
            PatternCode: 'BP7LiquorExposure_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7LiquorLiabCovExposure_HUSA',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (!hasLessorsRisk && classificationHasByobAlcohol(classification.code)) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: parseMaskedInt(building.exposure.totalSales),
            PatternCode: 'BP7LiquorExposure_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7LiquorLiabCovExposure_HUSA',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (limitForBusinessPersonalProperty > 0) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: limitForBusinessPersonalProperty,
            PatternCode: 'BP7BusnPrsnlPropLimit',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: classificationTypeId,
      },
      Pattern: {
        Code: 'BP7ClassificationBusinessPersonalProperty',
      },
      ScheduledItems_HUSA: null,
    });
  }

  if (building.coverage.spoilage > 0) {
    const spoilageDescription: SpoilageDescription | null = spoilageClassificationDescription(
      building.exposure.classification.code
    );

    if (spoilageDescription) {
      let covType = 'PowerOutage';
      let maintenanceAgreement = 'NotApplicable';

      if (!building.coverage.equipmentBreakdownCoverageOptedIn) {
        covType = 'BreakdownorContaminationandPowerOutage';
        maintenanceAgreement = 'No';
      }

      coverages.push({
        CovTerms: {
          Entry: [
            {
              CovTermValueForRating_HUSA: building.coverage.spoilage,
              PatternCode: 'BP7Limit32',
            },
            {
              CovTermValueForRating_HUSA: covType,
              PatternCode: 'BP7CovType2',
            },
            {
              CovTermValueForRating_HUSA: spoilageDescription.spoilageClass,
              PatternCode: 'BP7Class',
            },
            {
              CovTermValueForRating_HUSA: maintenanceAgreement,
              PatternCode: 'BP7MaintenanceAgreement1',
            },
            {
              CovTermValueForRating_HUSA: spoilageDescription.perishableDesc,
              PatternCode: 'BP7PerishableStockDesc',
            },
            {
              CovTermValueForRating_HUSA: 500,
              PatternCode: 'BP7SpoilageDeductible_HUSA',
            },
          ],
        },
        OwningCoverable: {
          TypeIDString: classificationTypeId,
        },
        Pattern: {
          Code: 'BP7SpoilgCov',
        },
        ScheduledItems_HUSA: null,
      });
    }
  }

  return coverages;
}

function hiredNonOwnedAutoCoverageTerms(policy: BopPolicy): Clause[] {
  const coverages = [];
  const pizzaIsOnTheTable = canClassificationsHavePizza(
    getBuildingClassificationsForLocations(policy.locations)
  );

  if (
    policy.liabilityCoverages.acceptHiredNonOwnedAutoCoverage === true &&
    !hasContractorBusinessType(policy) &&
    !pizzaIsOnTheTable
  ) {
    coverages.push({
      CovTerms: {
        Entry: [
          {
            CovTermValueForRating_HUSA: 'false',
            PatternCode: 'BP7Liability1',
          },
          {
            CovTermValueForRating_HUSA: parseInt(
              policy.liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses,
              10
            ),
            PatternCode: 'BP7HiredLimit_HUSA',
          },
          {
            CovTermValueForRating_HUSA: 'false',
            PatternCode: 'BP7HiredAutoQ2_HUSA',
          },
          {
            CovTermValueForRating_HUSA: 'false',
            PatternCode: 'BP7HiredAutoQ_HUSA',
          },
        ],
      },
      OwningCoverable: {
        TypeIDString: 'entity.BP7BusinessOwnersLine',
      },
      Pattern: {
        Code: 'BP7HiredNonOwnedAuto',
      },
      ScheduledItems_HUSA: null,
    });
  }

  return coverages;
}
