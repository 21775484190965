import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, of as observableOf, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { UntypedFormControl } from '@angular/forms';

import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { HiscoxBindFormService } from 'app/features/hiscox/services/hiscox-bind-form.service';
import {
  HISCOX_ERROR_CODES,
  HiscoxTransactionRes,
  HISCOX_POLICY_PAYMENT_PLAN_OPTIONS,
  HiscoxPaymentRes,
  HiscoxQuoteQuotedResponse,
} from 'app/features/hiscox/models/hiscox-priced-quote';
import { HISCOX_PRODUCTS } from '../../models/hiscox-types';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { FormDslSteppedFormBaseComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form-base.component';
import { SentryService } from 'app/core/services/sentry.service';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  HISCOX_UNKNOWN_BIND_ERROR,
  HISCOX_UNKNOWN_PAYMENT_ERROR,
  HISCOX_UNKNOWN_CC_ERROR,
} from 'app/shared/quote-error-modal/errors';
import { AttuneEventName, SegmentService } from 'app/core/services/segment.service';

@Component({
  selector: 'app-hiscox-bind-page',
  templateUrl: './hiscox-bind-page.component.html',
  providers: [HiscoxBindFormService],
})
export class HiscoxBindPageComponent
  extends FormDslSteppedFormBaseComponent<HiscoxBindFormService>
  implements OnInit
{
  accountId: string;
  productType: HISCOX_PRODUCTS;
  loadingAccountDetails: boolean;
  insuredAccount: Observable<InsuredAccount | null>;
  insuredAccountSubject: BehaviorSubject<InsuredAccount | null> = new BehaviorSubject(null);

  quoteBindErrors: Array<string> = [] as string[];
  quotePaymentErrors: Array<string> = [] as string[];
  quoteCreditCardErrors: Array<string> = [] as string[];

  _numberOfPayments: number;
  paymentReference: string;
  paymentIframeLoading: boolean;

  openBindErrorModal = false;
  openCreditCardErrorModal = false;
  openPaymentErrorModal = false;
  showProgressBar = false;

  private sub: Subscription = new Subscription();

  bindSuccess$ = new Subject();

  quote: HiscoxQuoteQuotedResponse;

  constructor(
    public formService: HiscoxBindFormService,
    protected route: ActivatedRoute,
    protected router: Router,
    private insuredAccountService: InsuredAccountService,
    private sentryService: SentryService,
    private amplitudeService: AmplitudeService,
    protected hiscoxQuoteService: HiscoxQuoteService,
    private segmentService: SegmentService,
    private zone: NgZone,
    @Inject('Window') private window: Window
  ) {
    super(formService, route, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupHiscoxFunctions();

    this.productType = this.route.snapshot.params.productType;
    this.accountId = this.route.snapshot.params.accountId;
    this.insuredAccount = this.insuredAccountSubject.asObservable();

    this.sub.add(
      this.hiscoxQuoteService
        .getQuote(this.productType, this.accountId)
        .subscribe((quoteResponse: HiscoxQuoteQuotedResponse) => {
          this.quote = quoteResponse;
        })
    );
    this.loadAccountDetails();
  }

  public loadInitialData(): void {}

  public onIncrementedStep(nextStep: RouteFormStep): void {
    this.navigateToCurrentStep();
  }

  private setupHiscoxFunctions() {
    // instance of ngZone used to run methods called from hiscox-callback.html, within the Angular zone
    (this.window as { [key: string]: any })['cancelCREPayment'] = () => {
      this.zone.run(() => {
        this.cancelCREPayment();
      });
    };
    (this.window as { [key: string]: any })['completeCREPayment'] = (res: HiscoxTransactionRes) => {
      this.zone.run(() => {
        this.completeCREPayment(res);
      });
    };
    (this.window as { [key: string]: any })['creHandleErrors'] = (error: string) => {
      this.zone.run(() => {
        this.creHandleErrors(error);
      });
    };
    (this.window as { [key: string]: any })['whatCVV2'] = () => {
      this.zone.run(() => {
        this.whatCVV2();
      });
    };
  }

  private errorCodeLookup(error: string): string {
    const errorsArray = error.split('|');
    const message = [] as string[];
    for (const err of errorsArray) {
      if (err !== '' && HISCOX_ERROR_CODES[err]) {
        message.push(HISCOX_ERROR_CODES[err]);
      }
    }
    return message.join(' | ');
  }

  public cancelCREPayment() {
    // TODO: need to elaborate on this method
    // TODO: unhide element in iFrame
    this.router.navigate(['accounts', this.accountId]);
  }

  public creHandleErrors(errorCodes: string) {
    const errorsString = this.errorCodeLookup(errorCodes);
    this.quoteCreditCardErrors.push(errorsString);
    this.openCreditCardErrorModal = true;

    this.sentryService.notify(
      'Error while trying to verify credit card details for Hiscox payment',
      {
        severity: 'error',
        metaData: {
          errorCodes: errorCodes,
          errorString: errorsString,
        },
      }
    );
  }

  public completeCREPayment(transaction: HiscoxTransactionRes) {
    this.bindQuote(transaction.uID);
  }

  public whatCVV2() {
    // TODO: might need to elaborate on this method - currently hidding link in UI
    // TODO: unhide element in iFrame
    console.log('here in whatCVV2');
  }

  private bindQuote(paymentReference: string) {
    const productType: HISCOX_PRODUCTS = this.route.snapshot.params.productType;
    this.showProgressBar = true;
    this.paymentReference = paymentReference;
    this._numberOfPayments = this.formService.get<UntypedFormControl>(
      'paymentPlan.numberOfPayments'
    ).value;

    let paymentFrequency;

    if (this._numberOfPayments === HISCOX_POLICY_PAYMENT_PLAN_OPTIONS['One-time payment']) {
      paymentFrequency = 'Annual';
    } else {
      paymentFrequency = 'Monthly';
    }

    this.sub.add(
      this.hiscoxQuoteService
        .bindQuote(this.accountId, productType, paymentFrequency, this.paymentReference)
        .subscribe(
          (hiscoxBindResponse: unknown) => {
            // TODO: define response type
            this.amplitudeService.track({
              eventName: 'bind',
              detail: `hixcox-${productType}`,
              useLegacyEventName: true,
            });
            this.openBindErrorModal = false;
            this.bindSuccess$.next(true);
            this.goBackToAccount();
          },
          (err) => {
            this.showProgressBar = false;
            // TODO: should log this error and redirect to accounts page
            this.amplitudeService.track({
              eventName: `${productType}_error`,
              detail: JSON.stringify(err),
              useLegacyEventName: true,
            });

            this.openBindErrorModal = true;
            this.sentryService.notify('Error while trying to bind Hiscox quote', {
              severity: 'error',
              metaData: {
                error: err,
                accountId: this.accountId,
                productType,
              },
            });
          }
        )
    );
  }

  private goBackToAccount() {
    this.router.navigate(['accounts', this.accountId], { replaceUrl: true });
  }

  private loadAccountDetails() {
    this.loadingAccountDetails = true;
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => {
            return this.accountId === insuredAccount.id.toString();
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccountSubject.next(insuredAccount);
          this.loadingAccountDetails = false;
        })
    );
  }

  public handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep(), step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  public checkQuotingSuccess(): Observable<boolean> {
    return observableOf(false);
  }

  public handleFailedQuote(): void {}

  public handleSuccessfulQuote(): void {}

  public sendForm(): Observable<any | null> {
    return observableOf(null);
  }

  public clickBackward() {
    if (this.formService.stepBackward()) {
      this.navigateToCurrentStep();
    }
  }

  acceptedAgreementQuestions(): boolean {
    if (
      this.formService.acceptedBindQuestion() &&
      this.formService.acceptedElectronicInfoQuestion()
    ) {
      return true;
    }
    return false;
  }

  sendSegmentEvent(eventName: AttuneEventName) {
    this.insuredAccount.subscribe((insuredAccount: InsuredAccount) => {
      const address = {
        addressLine1: insuredAccount?.addressLine1,
        addressLine2: insuredAccount?.addressLine2,
        city: insuredAccount?.city,
        state: insuredAccount?.state,
        zip: insuredAccount?.zip,
      };

      this.segmentService.track({
        event: eventName,
        properties: {
          product: this.productType,
          carrier: 'hiscox',
          class_code: {
            class_of_business: this.quote.origFrontendRequest?.classOfBusinessCd?.split('.')[0],
            naics_code: this.quote.origFrontendRequest?.classOfBusinessCd?.split('.')[1],
          },
          naics_code: insuredAccount.naicsCode,
          primary_state: insuredAccount.state,
          insured_address: address,
          insured_email: insuredAccount.emailAddress,
          business_name: insuredAccount.companyName,
        },
      });
    });
  }

  handleSubmit(submitEvent?: Event): boolean {
    if (submitEvent) {
      submitEvent.preventDefault();
    }
    if (this.formService.currentStep.slug === 'working-with-hiscox') {
      this.formService.handleDoNotShowAgainSelection();
      return this.formService.stepForward();
    }
    this.paymentIframeLoading = true;
    this.sendSegmentEvent('Bind Attempted');
    if (this.isCurrentStep('payment-plan')) {
      const paymentFrequency = this.formService.getSelectedPayment();
      this.hiscoxQuoteService
        .makePaymentSetup(this.accountId, this.productType, paymentFrequency)
        .subscribe(
          (paymentRes: HiscoxPaymentRes) => {
            this.paymentIframeLoading = false;
            return this.formService.stepForward();
          },
          (err) => {
            this.amplitudeService.track({
              eventName: `${this.productType}_error`,
              detail: JSON.stringify(err),
              useLegacyEventName: true,
            });
            this.sentryService.notify('Error while trying to make Hiscox payment setup', {
              severity: 'error',
              metaData: {
                error: err,
                accountId: this.accountId,
                productType: this.productType,
              },
            });
            this.showProgressBar = false;
            this.openPaymentErrorModal = true;
            return false;
          }
        );
    }
    return false;
  }

  handleQuotePaymentResultModalClose(result: { close: boolean; retry: boolean }) {
    this.quotePaymentErrors = [];
    if (result.close) {
      this.openPaymentErrorModal = false;
    }

    if (result.retry) {
      this.handleSubmit();
    }

    if (!result.close && !result.retry) {
      this.router.navigate(['accounts', this.accountId]);
    }
  }

  getPaymentErrorType() {
    return HISCOX_UNKNOWN_PAYMENT_ERROR;
  }

  hasDisplayableQuotePaymentErrors() {
    return this.quotePaymentErrors.length > 0;
  }

  handleCreditCardResultModalClose(result: { close: boolean; retry: boolean }) {
    this.quoteCreditCardErrors = [];

    if (result.close) {
      this.openCreditCardErrorModal = false;
    }
  }

  getCreditCardErrorType() {
    return HISCOX_UNKNOWN_CC_ERROR;
  }

  hasDisplayableCrediCardErrors() {
    return this.quoteCreditCardErrors.length > 0;
  }

  handleQuoteBindResultModalClose(result: { close: boolean; retry: boolean }) {
    this.quoteBindErrors = [];
    if (result.close) {
      this.openBindErrorModal = false;
    }

    if (result.retry) {
      if (this.hiscoxQuoteService.paymentSubject.value) {
        const paymentResponse = this.hiscoxQuoteService.paymentSubject.value;
        this.bindQuote(paymentResponse.PaymentReference);
      }
    }

    if (!result.close && !result.retry) {
      this.router.navigate(['accounts', this.accountId]);
    }
  }

  getBindErrorType() {
    return HISCOX_UNKNOWN_BIND_ERROR;
  }

  hasDisplayableQuoteBindErrors() {
    return this.quoteBindErrors.length > 0;
  }
}
