import {
  InsurancePolicyAdminSource,
  InsuranceProductCode,
} from 'app/features/digital-carrier/models/types';

export enum ActionName {
  QUOTE_FOR_ACCOUNT = 'QUOTE_FOR_ACCOUNT',
  BOP_QUOTE_FOR_ACCOUNT = 'BOP_QUOTE_FOR_ACCOUNT', // Deprecated, use QUOTE_FOR_ACCOUNT instead
  BOP_BIND_FOR_ACCOUNT = 'BOP_BIND_FOR_ACCOUNT', // Deprecated, use BIND_POLICY_FOR_ACCOUNT instead.
  DCP_QUOTE_FOR_ACCOUNT = 'DCP_QUOTE_FOR_ACCOUNT', // Deprecated, use QUOTE_FOR_ACCOUNT instead
  BIND_POLICY_FOR_ACCOUNT = 'BIND_POLICY_FOR_ACCOUNT',
  GIFT_CARD_REDEMPTION = 'GIFT_CARD_REDEMPTION',
  QUOTE_GAME_SCORE = 'QUOTE_GAME_SCORE',
}

export enum RewardName {
  QUOTE_FOR_ACCOUNT = 'QUOTE_FOR_ACCOUNT',
  BOP_QUOTE_FOR_ACCOUNT = 'BOP_QUOTE_FOR_ACCOUNT', // Deprecated, use QUOTE_FOR_ACCOUNT instead
  DCP_QUOTE_FOR_ACCOUNT = 'DCP_QUOTE_FOR_ACCOUNT', // Deprecated, use QUOTE_FOR_ACCOUNT instead
  BOP_BIND_FOR_ACCOUNT = 'BOP_BIND_FOR_ACCOUNT',
  BIND_POLICY_FOR_ACCOUNT = 'BIND_POLICY_FOR_ACCOUNT',
  GIFT_CARD_250 = 'GIFT_CARD_250',
  GIFT_CARD_50 = 'GIFT_CARD_50',
  ONE_ATTUNE_BOP_QUOTES_ACHIEVEMENT = 'ONE_ATTUNE_BOP_QUOTES_ACHIEVEMENT',
  ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT = 'ONE_EMPLOYERS_WC_QUOTES_ACHIEVEMENT',
  ONE_ATTUNE_WC_QUOTES_ACHIEVEMENT = 'ONE_ATTUNE_WC_QUOTES_ACHIEVEMENT',
  ONE_HISCOX_GL_QUOTES_ACHIEVEMENT = 'ONE_HISCOX_GL_QUOTES_ACHIEVEMENT',
  ONE_HISCOX_PL_QUOTES_ACHIEVEMENT = 'ONE_HISCOX_PL_QUOTES_ACHIEVEMENT',
  ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT = 'ONE_COALITION_CYBER_QUOTES_ACHIEVEMENT',
  BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT = 'BUNDLE_BIND_EMPLOYERS_WC_ACHIEVEMENT',
  BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT = 'BUNDLE_BIND_HISCOX_PL_ACHIEVEMENT',
  BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT = 'BUNDLE_BIND_COALITION_CYBER_ACHIEVEMENT',
  BOP_QUOTE_ARTISAN_ACHIEVEMENT = 'BOP_QUOTE_ARTISAN_ACHIEVEMENT',
  BOP_QUOTE_RETAIL_ACHIEVEMENT = 'BOP_QUOTE_RETAIL_ACHIEVEMENT',
  BOP_QUOTE_SERVICE_ACHIEVEMENT = 'BOP_QUOTE_SERVICE_ACHIEVEMENT',
  BOP_QUOTE_OFFICE_ACHIEVEMENT = 'BOP_QUOTE_OFFICE_ACHIEVEMENT',
  BOP_QUOTE_WHOLESALER_ACHIEVEMENT = 'BOP_QUOTE_WHOLESALER_ACHIEVEMENT',

  // Archived achievements
  // ONE_BOP_QUOTES_ACHIEVEMENT = 'ONE_BOP_QUOTES_ACHIEVEMENT',
  // FIVE_BOP_QUOTES_ACHIEVEMENT = 'FIVE_BOP_QUOTES_ACHIEVEMENT',
  // TEN_BOP_QUOTES_ACHIEVEMENT = 'TEN_BOP_QUOTES_ACHIEVEMENT',
  // TWENTY_BOP_QUOTES_ACHIEVEMENT = 'TWENTY_BOP_QUOTES_ACHIEVEMENT',
  // FIFTY_BOP_QUOTES_ACHIEVEMENT = 'FIFTY_BOP_QUOTES_ACHIEVEMENT',
  // ONE_HUNDRED_BOP_QUOTES_ACHIEVEMENT = 'ONE_HUNDRED_BOP_QUOTES_ACHIEVEMENT',
  // TWO_HUNDRED_FIFTY_BOP_QUOTES_ACHIEVEMENT = 'TWO_HUNDRED_FIFTY_BOP_QUOTES_ACHIEVEMENT',
  // FIVE_HUNDRED_FIFTY_BOP_QUOTES_ACHIEVEMENT = 'FIVE_HUNDRED_BOP_QUOTES_ACHIEVEMENT',
  // BOP_QUOTE_CONTRACTOR_ACHIEVEMENT = 'BOP_QUOTE_CONTRACTOR_ACHIEVEMENT',
  // BOP_QUOTE_RESTAURANT_ACHIEVEMENT = 'BOP_QUOTE_RESTAURANT_ACHIEVEMENT',
  // BOP_QUOTE_LRO_ACHIEVEMENT = 'BOP_QUOTE_LRO_ACHIEVEMENT',
  // BOP_QUOTE_TWO_LOCATIONS_ACHIEVEMENT = 'BOP_QUOTE_TWO_LOCATIONS_ACHIEVEMENT',
  // ONE_XS_QUOTES_ACHIEVEMENT = 'ONE_XS_QUOTES_ACHIEVEMENT',
  // BOP_QUOTE_PREMIUM_10K = 'BOP_QUOTE_PREMIUM_10K',
  // BOP_QUOTE_PREMIUM_20K = 'BOP_QUOTE_PREMIUM_20K',
  // QUOTE_GAME_SCORE_9K = 'QUOTE_GAME_SCORE_9K',
  // ONE_CYBER_QUOTES_ACHIEVEMENT = 'ONE_CYBER_QUOTES_ACHIEVEMENT',
  // FIVE_CYBER_QUOTES_ACHIEVEMENT = 'FIVE_CYBER_QUOTES_ACHIEVEMENT',
}

export interface RewardRequest {
  actionName: ActionName;
  username?: string;
  data?: RewardRequestData; // Any additional information to send QS endpoint
}

export interface RewardRequestData {
  insuredAccountId?: string;
  bopQuoteId?: string;
  redemptionType?: RedemptionType;
  quoteGameScore?: number;
  accountName?: string;
  carrierName?: InsurancePolicyAdminSource | 'attune_gw';
  product?: InsuranceProductCode;
}

export interface RewardsTransactions {
  transactions: RewardsTransaction[];
}

export interface RewardsTransaction {
  insuredAccountId: string;
  insuredAccountName: string;
  points: number;
  createdAt: string;
}

export interface BalanceResponse {
  balance: number;
  eligibleForRewards: boolean;
  optedIn: boolean;
  rewardsEmail: string | null;
  totalRedeemedThisYear: number;
}

export interface OptInRequest {
  name: string;
  rewardsEmail: string;
}

export interface OptInResponse {
  optedIn: boolean;
  rewardsEmail: string;
}

export interface EditEmailAddressRequest {
  newRewardsEmail: string;
  oldRewardsEmail: string;
  newName: string;
}

export interface RewardResponseList {
  transactions: RewardResponse[];
}

export interface RewardResponse {
  name: RewardName;
  points: number;
  uuid?: string;
}

export interface RewardListItems {
  name: RewardName;
}

export enum RedemptionType {
  GIFT_CARD_250 = 'GIFT_CARD_250',
  GIFT_CARD_50 = 'GIFT_CARD_50',
}

export enum RewardToastType {
  REWARD = 'REWARD',
  ACHIEVEMENT = 'ACHIEVEMENT',
  GOAL = 'GOAL',
  REDEMPTION = 'REDEMPTION',
}

export interface ToastDisplay {
  type: RewardToastType;
  message: string;
  delay: number;
}

export interface RewardsBalanceInfo {
  balance: number;
  totalRedeemedThisYear: number;
}
