<!--TODO: Consider adding loading state tracker to avoid displaying empty page-->
<ng-container *ngIf="isFormInitialized | async">
  <app-hiscox-quote-form-sidebar-inspection
    class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__hx"
    [product]="productType"
    [version]="version"
    pasSource="hiscox"
    [form]="
      formService.form.get(
        currentStep.formPath ? currentStep.formPath : formService.defaultFormStep
      )
    "
    [steppedFormService]="formService"
    [accountId]="accountId"
    [fillInHappyPath]="fillInHappyPath.bind(this)"
    [insAccount]="insuredAccount | async"
    sidebarTitle="New quote"
  >
  </app-hiscox-quote-form-sidebar-inspection>

  <div class="app-page-form-scrollable-wrapper">
    <div class="app-page-form" content>
      <a
        *ngIf="!isFirstStep()"
        class="emphasized-link form-previous-link"
        (click)="loadPreviousStep($event)"
        (keyup.enter)="loadPreviousStep($event)"
      >
        <span class="icon-full-arrow-left button-icon"></span>Previous step
      </a>

      <form [formGroup]="formService.form" (ngSubmit)="submitForm($event)" novalidate>
        <main *ngFor="let step of formService.formDslSteps">
          <section *ngIf="isCurrentStep(step.slug)">
            <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">{{ step.displayName }}</h1>
            <app-form-dsl-form
              [formTree]="stepTree"
              [form]="formService.form.get(step.formPath)"
              [submitted]="submitted()"
              (newAutoCompleteAddress)="handleAddressAutocomplete($event)"
            >
            </app-form-dsl-form>
          </section>
        </main>

        <div class="form-footer">
          <div class="validation" role="alert" *ngIf="submitted() && !isCurrentStepValid()">
            <span *ngIf="hasNonEligibleError()">
              <p class="validation-message">
                Sorry, this class of business is not available in this state.
              </p>
            </span>
            <span *ngIf="!hasNonEligibleError()">
              <p class="validation-message">
                {{ getValidationMessage() }}
              </p>
            </span>
          </div>
          <div *ngIf="isStreetNumberMissing" class="validation">
            <span class="validation-message validation-message__warning">
              No street number was found for the selected address. Please confirm the above address
              appears correct before proceeding.
            </span>
          </div>
          <div class="nav-button-group nav-button-group__left">
            <button
              *ngIf="!isFinalStep()"
              class="button button__primary js-load-next-step-button"
              (click)="loadNextStep($event)"
              [ngClass]="{ button__discouraged: !isCurrentStepValid() }"
              [disabled]="isLoadingNextStep"
            >
              <div class="button-loader" role="presentation" *ngIf="isLoadingNextStep">
                <svg class="circle-spinner">
                  <circle
                    cx="11"
                    cy="11"
                    r="9"
                    stroke-width="2"
                    stroke-dasharray="0,1000"
                    d="M5 40 l215 0"
                    fill="transparent"
                  />
                </svg>
              </div>
              <span *ngIf="isLoadingNextStep">Loading...</span>
              <span *ngIf="!isLoadingNextStep">Next</span>
            </button>
            <button
              *ngIf="isFinalStep()"
              type="submit"
              class="button button__primary js-submit-button"
              [ngClass]="{ button__discouraged: !this.isCurrentStepValid() }"
              [disabled]="isSubmittingForm"
            >
              <div class="button-loader" *ngIf="isSubmittingForm">
                <svg class="circle-spinner">
                  <circle
                    cx="11"
                    cy="11"
                    r="9"
                    stroke-width="2"
                    stroke-dasharray="0,1000"
                    d="M5 40 l215 0"
                    fill="transparent"
                  />
                </svg>
              </div>
              <span *ngIf="isSubmittingForm">Submitting...</span>
              <span *ngIf="!isSubmittingForm">Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="app-page-form-side-rail"></div>
  </div>

  <app-quote-error-modal
    [open]="quoteResultModalOpen"
    (closeQuoteErrorModal)="handleQuoteResultModalClose($event)"
    [errors]="requestQuoteErrors"
    [errorType]="getErrorType()"
  >
    <ng-container *ngIf="!hasDisplayableQuoteErrors()">
      <p class="js-hx-contact-team">
        We're sorry, there was an issue with this quote. Please contact our Customer Care team so
        that we can help finish your quote.
      </p>
    </ng-container>
    <ng-container *ngIf="hasDisplayableQuoteErrors()">
      <p class="js-hx-retry-quote">
        We're sorry, there was an issue processing your request. Please retry your quote or contact
        our Customer Care team.
      </p>
    </ng-container>
  </app-quote-error-modal>
</ng-container>
