/// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts" />

export function addressComponentIsCity(addressComponent: google.maps.GeocoderAddressComponent) {
  // City names can have a at least two different sets of types
  if (addressComponent.types.includes('locality') && addressComponent.types.includes('political')) {
    return true;
  }
  if (
    addressComponent.types.includes('sublocality_level_1') &&
    addressComponent.types.includes('sublocality') &&
    addressComponent.types.includes('political')
  ) {
    return true;
  }
  return false;
}

export function addressComponentIsCounty(addressComponent: google.maps.GeocoderAddressComponent) {
  return addressComponent.types.includes('administrative_area_level_2');
}

export function addressComponentIsState(addressComponent: google.maps.GeocoderAddressComponent) {
  return addressComponent.types.includes('administrative_area_level_1');
}

export function addressComponentIsZipCode(addressComponent: google.maps.GeocoderAddressComponent) {
  return addressComponent.types.includes('postal_code');
}
