import {
  DependencyConfig,
  DependencyOperator,
  DependencySource,
  EvaluatedValueName,
} from './common-typings';
import {
  LmBopQuestion,
  LmBopComplexEvaluator,
  LmBopLossHistoryQuestion,
  LmBopNestedControls,
} from './lm-bop-typings';

export const LM_BOP_NESTED_CONTROL_DEPENDENCIES: Record<
  LmBopNestedControls,
  DependencyConfig<LmBopQuestion, LmBopComplexEvaluator>
> = {
  [LmBopLossHistoryQuestion.ALLOCATED_ADJUSTER_EXPENSE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopLossHistoryQuestion.AMOUNT_PAID]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopLossHistoryQuestion.AMOUNT_RESERVED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopLossHistoryQuestion.CLAIM_STATUS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopLossHistoryQuestion.DATE_OF_LOSS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopLossHistoryQuestion.LOSS_DESCRIPTION]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopLossHistoryQuestion.LOSS_TYPE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
};

export const LM_BOP_DEPENDENCIES: Record<
  LmBopQuestion,
  DependencyConfig<LmBopQuestion, LmBopComplexEvaluator>
> = {
  [LmBopQuestion.NUMBER_STORIES]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.ALARM_TYPE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.ANNUAL_DRY_CLEANING_RECEIPTS_AT_THIS_LOCATION]: {
    classCode: {
      display: 'IN',
      values: ['88661', '88662'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.ANNUAL_SALES_RECEIPTS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.AREA_OCCUPIED_BY_INSURED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.AREA_OCCUPIED_BY_OTHER_BUILDING_TENANTS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.AREA_UNOCCUPIED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.BOP_CLASS_CODE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.BUILDING_LIMIT_OF_INSURANCE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.BUSINESS_ADDRESS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.CONSTRUCTION_TYPE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES]: {
    classCode: {
      display: 'NOT_IN',
      values: ['88617', '88609', '88583', '88651'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT]: {
    classCode: {
      display: 'NOT_IN',
      values: ['88617', '88609', '88583', '88651'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.CYBER_COVERAGE]: {
    classCode: {
      display: 'NOT_IN',
      values: ['88617', '88609', '88583', '88651'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.DATA_COMPROMISE_RESPONSE_EXPENSES,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.DESCRIPTION_OF_OPERATIONS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES]: {
    classCode: {
      display: 'NOT_IN',
      values: ['82021', '88581'],
    },
    state: {
      display: 'NOT_IN',
      values: ['NY'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.EPLI,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.EFFECTIVE_DATE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.EPLI]: {
    classCode: {
      display: 'NOT_IN',
      values: ['82021', '88581'],
    },
    state: {
      display: 'NOT_IN',
      values: ['NY'],
    },
  },
  [LmBopQuestion.FORM_OF_BUSINESS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.FULL_TIME_BARBERS_AT_THIS_LOCATION]: {
    classCode: {
      display: 'IN',
      values: ['81011', '88573'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.FULL_TIME_BEAUTICIANS_AT_THIS_LOCATION]: {
    classCode: {
      display: 'IN',
      values: ['88573', '81021'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.HEARING_AID_PROFESSIONAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['81351'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY]: {
    classCode: {
      display: 'NOT_IN',
      values: ['80441', '81341', '88574', '88629', '88631', '89138', '89148', '89152', '89153'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.UNDER_1M_SALES_AND_UNDER_10_EMP,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.HIRED_AUTO_PHYSICAL_DAMAGE]: {
    classCode: {
      display: 'NOT_IN',
      values: ['80441', '81341', '88574', '88629', '88631', '89138', '89148', '89152', '89153'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.INSURED_PHONE_NUMBER]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.INTEREST]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LIMIT_OF_INSURANCE_PRINTERS_WORK_CORRECTION]: {
    classCode: {
      display: 'IN',
      values: ['81231', '81081', '81221'],
    },
    state: {
      display: 'NOT_IN',
      values: ['AR', 'FL', 'LA', 'NY', 'RI'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.PRINTERS_WORK_CORRECTION_PRINTERS_E_O,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.LIQUOR_COVERAGE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LIQUOR_LIABILITY_TYPE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.LIQUOR_COVERAGE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.MANICURISTS_AT_THIS_LOCATION]: {
    classCode: {
      display: 'IN',
      values: ['88614', '88573'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.NUMBER_OF_EMPLOYEES_VETERINARIANS_ASSISTANTS_VETERINARIANS_STAFF]: {
    classCode: {
      display: 'IN',
      values: ['86831', '80501', '88624', '82281', '86011'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.VETERINARIAN_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_HEARING_AID_PROFESSIONAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['81351'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HEARING_AID_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTICAL_PROFESSIONAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['88619', '80141'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.OPTICAL_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.NUMBER_OF_PROFESSIONALS_OPTOMETRISTS_PROFESSIONAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['88619', '80141'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.NUMBER_OF_VETERINARIANS_OWNERS_PARTNERS_OFFICERS]: {
    classCode: {
      display: 'IN',
      values: ['86831', '80501', '88624', '82281', '86011'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.VETERINARIAN_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.OPTICAL_PROFESSIONAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['88619', '80141'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['88619', '80141'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.PART_TIME_BARBERS_AT_THIS_LOCATION]: {
    classCode: {
      display: 'IN',
      values: ['88573', '81011'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.PART_TIME_BEAUTICIANS_AT_THIS_LOCATION]: {
    classCode: {
      display: 'IN',
      values: ['88573', '81021'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.PRIMARY_RISK_STATE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.PRINTERS_ANNUAL_RECEIPTS_AT_THIS_LOCATION_PRINTERS_E_O]: {
    classCode: {
      display: 'IN',
      values: ['81231', '81081', '81221'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['81231', '81081', '81221'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.PRINTERS_WORK_CORRECTION_PRINTERS_E_O]: {
    classCode: {
      display: 'IN',
      values: ['81221', '81231', '81081'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.PRINTERS_ERRORS_AND_OMISSIONS_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.TOTAL_ANNUAL_LIQUOR_RECEIPTS_AT_THIS_LOCATION]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.LIQUOR_LIABILITY_TYPE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'liquor-liability',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.TOTAL_RECEIPTS_FOR_LOCATION]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.UM_UIM_LOSS_TYPE]: {
    classCode: {
      display: 'NOT_IN',
      values: ['88629', '88631', '88574', '80441', '81341'],
    },
    state: {
      display: 'IN',
      values: ['LA'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.UNINSURED_UNDERINSURED_MOTORIST_LIMIT]: {
    classCode: {
      display: 'NOT_IN',
      values: ['88629', '88631', '88574', '80441', '81341'],
    },
    state: {
      display: 'IN',
      values: ['IL', 'LA', 'WV'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.VETERINARIAN_PROFESSIONAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['80501', '88624', '82281', '86011', '86831'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.BUSINESS_PERSONAL_PROPERTY_LIMIT,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.YEAR_BUILT]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.HAS_DELIVERY_SERVICE]: {
    classCode: {
      display: 'IN',
      values: [
        '81331',
        '85911',
        '88551',
        '88552',
        '88574',
        '88607',
        '88629',
        '88631',
        '89138',
        '89148',
        '89152',
        '89153',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HIRED_AND_NON_OWNED_AUTO_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.YEAR_BUSINESS_STARTED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.RARE_ANIMAL_TREATMENT]: {
    classCode: {
      display: 'IN',
      values: ['82281'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.USE_OF_FLAMMABLES]: {
    classCode: {
      display: 'IN',
      values: ['81081', '81221', '85381', '86821'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.OPERATIONS_INVOLVE_FLAMMABLES,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.OWN_PRODUCTS]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80021',
        '80051',
        '80061',
        '80101',
        '80111',
        '80131',
        '80141',
        '80151',
        '80161',
        '80171',
        '80191',
        '80211',
        '80311',
        '80321',
        '80331',
        '80341',
        '80361',
        '80371',
        '80381',
        '80391',
        '80401',
        '80411',
        '80421',
        '80501',
        '80561',
        '80571',
        '80841',
        '80881',
        '81331',
        '81371',
        '81381',
        '82261',
        '83021',
        '83031',
        '83161',
        '83171',
        '83191',
        '83201',
        '83211',
        '83241',
        '83671',
        '83751',
        '83831',
        '83901',
        '85011',
        '85021',
        '85061',
        '85071',
        '85101',
        '85111',
        '85131',
        '85141',
        '85161',
        '85171',
        '85191',
        '85241',
        '85251',
        '85311',
        '85341',
        '85351',
        '85361',
        '85371',
        '85381',
        '85391',
        '85401',
        '85501',
        '85571',
        '85601',
        '85641',
        '85661',
        '85671',
        '85691',
        '85741',
        '85811',
        '85821',
        '85831',
        '85841',
        '85851',
        '85861',
        '85871',
        '85901',
        '85911',
        '86021',
        '86041',
        '86051',
        '86061',
        '86071',
        '86081',
        '86111',
        '86331',
        '86341',
        '86361',
        '86371',
        '86421',
        '86431',
        '86461',
        '86481',
        '86491',
        '86521',
        '86531',
        '86541',
        '86581',
        '86591',
        '86611',
        '86631',
        '86661',
        '86671',
        '86681',
        '86691',
        '86711',
        '86721',
        '86731',
        '86741',
        '86751',
        '86761',
        '86781',
        '86791',
        '86811',
        '86821',
        '86831',
        '86841',
        '86851',
        '86881',
        '86891',
        '86911',
        '86921',
        '86931',
        '86941',
        '86951',
        '88546',
        '88553',
        '88554',
        '88555',
        '88556',
        '88559',
        '88561',
        '88565',
        '88568',
        '88578',
        '88584',
        '88587',
        '88588',
        '88589',
        '88591',
        '88595',
        '88596',
        '88599',
        '88601',
        '88606',
        '88612',
        '88613',
        '88623',
        '88633',
        '88636',
        '88638',
        '88641',
        '88643',
        '88644',
        '88645',
        '88655',
        '88656',
        '88658',
        '88659',
        '88716',
        '89131',
        '89137',
        '89139',
        '89146',
        '89149',
      ],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.AREA_UNOCCUPIED_DETAILS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.AREA_UNOCCUPIED,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.HEATING_YEAR]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.PLUMBING_YEAR]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE_DETAILS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.YEARS_MANAGEMENT_EXPERIENCE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.YEAR_BUSINESS_STARTED,
      },
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.CODE,
        value: EvaluatedValueName.CURRENT_YEAR_MINUS_THREE_YEARS,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.OWNED_AUTO]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.HIRED_OR_NON_OWNED_AUTO_SELECTED,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.PERSONAL_AUTO_EMPLOYEE_USE]: {
    classCode: {
      display: 'IN',
      values: [
        '80021',
        '80441',
        '81081',
        '81221',
        '82021',
        '82051',
        '82081',
        '82211',
        '83231',
        '85021',
        '85521',
        '85621',
        '85911',
        '88551',
        '88552',
        '88574',
        '88577',
        '88593',
        '88638',
        '88701',
        '89131',
        '89132',
        '89133',
        '89134',
        '89135',
        '89136',
        '89137',
        '89138',
        '89139',
        '89142',
        '89146',
        '89147',
        '89148',
        '89149',
        '89151',
        '89152',
        '89153',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.HIRED_OR_NON_OWNED_AUTO_SELECTED,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.EMP_COV_MIN_REQUIRED]: {
    classCode: {
      display: 'IN',
      values: [
        '80021',
        '80441',
        '81081',
        '81221',
        '82021',
        '82051',
        '82081',
        '82211',
        '83231',
        '85021',
        '85521',
        '85621',
        '85911',
        '88551',
        '88552',
        '88574',
        '88577',
        '88593',
        '88638',
        '88701',
        '89131',
        '89132',
        '89133',
        '89134',
        '89135',
        '89136',
        '89137',
        '89138',
        '89139',
        '89142',
        '89146',
        '89147',
        '89148',
        '89149',
        '89151',
        '89152',
        '89153',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.PERSONAL_AUTO_EMPLOYEE_USE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.LEASED_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80091',
        '80841',
        '81331',
        '81361',
        '83041',
        '85011',
        '85091',
        '85601',
        '85841',
        '85871',
        '86041',
        '88623',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.LEASED_EQUIPMENT,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.LEASED_OPERATOR_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80091',
        '80841',
        '81331',
        '81361',
        '83041',
        '85011',
        '85091',
        '85601',
        '85841',
        '85871',
        '86041',
        '88623',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.LEASED_EQUIPMENT,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.UL_300_EXTINGUISHING_SYSTEM]: {
    classCode: {
      display: 'IN',
      values: ['82150', '89112'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.FRYING_ON_PREMISES,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.EXTINGUISHING_SYSTEM_MAINTENANCE]: {
    classCode: {
      display: 'IN',
      values: ['88551', '88577'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.DONUT_FRYER,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.BPP_INCLUDES_EQUIP_OVER_250]: {
    classCode: {
      display: 'IN',
      values: [
        '82011',
        '82281',
        '82391',
        '88544',
        '88566',
        '88575',
        '88618',
        '88619',
        '88621',
        '88627',
        '88628',
        '88632',
        '88648',
        '88657',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.AREA_OCC_BY_INS_AND_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.NAMED_INSURED_ON_GL_POLICY]: {
    classCode: {
      display: 'IN',
      values: [
        '57155',
        '80011',
        '80021',
        '80031',
        '80051',
        '80061',
        '80071',
        '80091',
        '80101',
        '80111',
        '80121',
        '80131',
        '80141',
        '80151',
        '80161',
        '80171',
        '80181',
        '80191',
        '80201',
        '80211',
        '80311',
        '80321',
        '80331',
        '80341',
        '80361',
        '80371',
        '80381',
        '80391',
        '80401',
        '80411',
        '80421',
        '80441',
        '80501',
        '80561',
        '80571',
        '80701',
        '80801',
        '80831',
        '80841',
        '80881',
        '80891',
        '81011',
        '81021',
        '81041',
        '81051',
        '81071',
        '81081',
        '81111',
        '81121',
        '81131',
        '81141',
        '81151',
        '81161',
        '81171',
        '81181',
        '81191',
        '81201',
        '81221',
        '81231',
        '81311',
        '81331',
        '81351',
        '81361',
        '81371',
        '81381',
        '82011',
        '82021',
        '82031',
        '82041',
        '82051',
        '82061',
        '82081',
        '82091',
        '82121',
        '82140',
        '82150',
        '82161',
        '82171',
        '82181',
        '82191',
        '82201',
        '82211',
        '82221',
        '82231',
        '82241',
        '82251',
        '82261',
        '82271',
        '82281',
        '82331',
        '82391',
        '83011',
        '83021',
        '83031',
        '83041',
        '83151',
        '83161',
        '83171',
        '83181',
        '83191',
        '83211',
        '83221',
        '83231',
        '83241',
        '83671',
        '83751',
        '83831',
        '83901',
        '85011',
        '85021',
        '85031',
        '85061',
        '85071',
        '85091',
        '85101',
        '85111',
        '85131',
        '85141',
        '85161',
        '85171',
        '85191',
        '85241',
        '85251',
        '85311',
        '85341',
        '85351',
        '85361',
        '85371',
        '85381',
        '85391',
        '85401',
        '85501',
        '85521',
        '85571',
        '85601',
        '85621',
        '85641',
        '85661',
        '85671',
        '85691',
        '85701',
        '85741',
        '85801',
        '85811',
        '85821',
        '85831',
        '85841',
        '85851',
        '85861',
        '85871',
        '85901',
        '85911',
        '86011',
        '86021',
        '86031',
        '86041',
        '86051',
        '86061',
        '86071',
        '86081',
        '86111',
        '86211',
        '86311',
        '86321',
        '86331',
        '86341',
        '86361',
        '86371',
        '86381',
        '86391',
        '86411',
        '86421',
        '86431',
        '86461',
        '86471',
        '86481',
        '86491',
        '86511',
        '86521',
        '86531',
        '86541',
        '86561',
        '86571',
        '86581',
        '86591',
        '86611',
        '86621',
        '86631',
        '86641',
        '86651',
        '86661',
        '86671',
        '86681',
        '86691',
        '86711',
        '86721',
        '86731',
        '86741',
        '86751',
        '86761',
        '86781',
        '86791',
        '86811',
        '86821',
        '86831',
        '86841',
        '86851',
        '86881',
        '86891',
        '86911',
        '86921',
        '86931',
        '86941',
        '86951',
        '88543',
        '88544',
        '88545',
        '88546',
        '88547',
        '88548',
        '88549',
        '88551',
        '88552',
        '88553',
        '88554',
        '88555',
        '88556',
        '88557',
        '88558',
        '88559',
        '88561',
        '88563',
        '88564',
        '88565',
        '88566',
        '88567',
        '88568',
        '88572',
        '88573',
        '88574',
        '88575',
        '88576',
        '88577',
        '88578',
        '88582',
        '88583',
        '88584',
        '88585',
        '88586',
        '88587',
        '88588',
        '88589',
        '88591',
        '88592',
        '88593',
        '88594',
        '88595',
        '88596',
        '88597',
        '88598',
        '88599',
        '88601',
        '88602',
        '88603',
        '88604',
        '88605',
        '88606',
        '88607',
        '88608',
        '88609',
        '88611',
        '88612',
        '88613',
        '88614',
        '88615',
        '88616',
        '88618',
        '88619',
        '88621',
        '88622',
        '88623',
        '88624',
        '88625',
        '88626',
        '88627',
        '88628',
        '88629',
        '88631',
        '88632',
        '88633',
        '88634',
        '88636',
        '88637',
        '88638',
        '88639',
        '88641',
        '88643',
        '88644',
        '88645',
        '88646',
        '88647',
        '88648',
        '88651',
        '88652',
        '88653',
        '88655',
        '88656',
        '88657',
        '88658',
        '88659',
        '88661',
        '88662',
        '88701',
        '88712',
        '88713',
        '88714',
        '88715',
        '88716',
        '89111',
        '89112',
        '89113',
        '89131',
        '89132',
        '89133',
        '89134',
        '89135',
        '89136',
        '89137',
        '89138',
        '89139',
        '89142',
        '89146',
        '89147',
        '89148',
        '89149',
        '89151',
        '89152',
        '89153',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.INS_OWNED_BLDG_CVG_AND_HAS_TENANTS,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.PROOF_READ]: {
    classCode: {
      display: 'IN',
      values: ['81081', '81221'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.SERVICE_WORK_SALES_1]: {
    classCode: {
      display: 'IN',
      values: [
        '57155',
        '80011',
        '80391',
        '83041',
        '83671',
        '85091',
        '85671',
        '85911',
        '86331',
        '86341',
        '86661',
        '88563',
        '88585',
        '88586',
        '88588',
        '89133',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.SERVICE_WORK_SALES_2]: {
    classCode: {
      display: 'IN',
      values: ['83901'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.SERVICE_WORK_SALES_3]: {
    classCode: {
      display: 'IN',
      values: ['80021', '80061', '81361', '86041', '88633'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.SERVICE_WORK_SALES_4]: {
    classCode: {
      display: 'IN',
      values: ['80131', '81371', '83011', '86551'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.SERVICE_WORK_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '57155',
        '80011',
        '80021',
        '80061',
        '80131',
        '80391',
        '81361',
        '81371',
        '83011',
        '83041',
        '83671',
        '83901',
        '85091',
        '85671',
        '85911',
        '86041',
        '86331',
        '86341',
        '86661',
        '88563',
        '88585',
        '88586',
        '88588',
        '88633',
        '89133',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.SERVICE_WORK_SALES_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.FURNITURE_INSTALLATION_PERCENT]: {
    classCode: {
      display: 'IN',
      values: ['80101', '83831', '85101', '85831', '88599', '88601'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.HOURS_OF_OPERATION]: {
    classCode: {
      display: 'IN',
      values: [
        '81331',
        '88567',
        '88574',
        '88577',
        '88589',
        '88602',
        '88604',
        '88607',
        '88656',
        '88631',
        '89138',
        '89148',
        '89152',
        '89153',
      ],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.COMPOUNDING_OR_MIXING]: {
    classCode: {
      display: 'IN',
      values: ['81331'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.SECONDHAND_MATERIALS]: {
    classCode: {
      display: 'IN',
      values: [
        '80211',
        '80401',
        '83191',
        '83201',
        '83211',
        '85251',
        '85641',
        '86071',
        '86081',
        '86111',
        '86591',
        '86611',
        '86731',
        '86921',
        '88584',
        '88599',
        '88601',
        '88713',
        '88715',
        '89135',
        '89136',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.COMPUTER_PROGRAMMING]: {
    classCode: {
      display: 'IN',
      values: ['89137'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.WEB_HOSTING]: {
    classCode: {
      display: 'IN',
      values: ['80571', '85571', '88712', '88713', '88714', '88715', '88716', '89137'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.WIRING_YEAR]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.REFURBISHED_EQUIPMENT]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80021',
        '80061',
        '80091',
        '80101',
        '80131',
        '80391',
        '80401',
        '80571',
        '80841',
        '81361',
        '81371',
        '83011',
        '83041',
        '83671',
        '83831',
        '85011',
        '85021',
        '85061',
        '85091',
        '85131',
        '85391',
        '85401',
        '85571',
        '85601',
        '85671',
        '85691',
        '85821',
        '85831',
        '85841',
        '85851',
        '85871',
        '85911',
        '86041',
        '86211',
        '86311',
        '86331',
        '86341',
        '86391',
        '86481',
        '86511',
        '86661',
        '86671',
        '88553',
        '88595',
        '88596',
        '88603',
        '88633',
        '88636',
        '88645',
        '89149',
      ],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.RESTAURANTS_OUTSIDE_CLEANING_SERVICE]: {
    classCode: {
      display: 'IN',
      values: ['89138', '89148', '89153'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.CPUL_300_EXTINGUISHING_SYSTEM]: {
    classCode: {
      display: 'IN',
      values: ['89138', '89148', '89153'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.DEEP_FAT_FRYER_NUMBER,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'ONE',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.PERCENT_HABITATIONAL_OCCUPANTS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.SEASONAL_OPERATION]: {
    classCode: {
      display: 'IN',
      values: ['88594', '88602', '89139'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.APPLICANT_SUBSIDIARIES]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },

  [LmBopQuestion.PRIOR_COVERAGE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.YEAR_BUSINESS_STARTED,
      },
      operator: DependencyOperator.LESS_THAN,
      right: {
        source: DependencySource.CODE,
        value: EvaluatedValueName.CURRENT_YEAR,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.LEASED_EQUIPMENT]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80091',
        '80841',
        '81331',
        '81361',
        '83041',
        '85011',
        '85091',
        '85601',
        '85841',
        '85871',
        '86041',
        '88623',
      ],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LRO_OFFICES]: {
    classCode: {
      display: 'IN',
      values: ['82140', '82150'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.BEAUTY_SHOP]: {
    classCode: {
      display: 'IN',
      values: ['81021'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.OTHER_BUSINESS_INSD_ELSEWHERE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.INCIDENTAL_COMMERCIAL_COOKING_OPERATIONS]: {
    classCode: {
      display: 'IN',
      values: ['89138', '89148', '89153'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LEARNING_CENTERS_EXTRA_CURRICULAR]: {
    classCode: {
      display: 'IN',
      values: ['89142'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LEARNING_CENTER_BACKGROUND_CHECK]: {
    classCode: {
      display: 'IN',
      values: ['89142'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.INSURED_OR_EMPLOYEE_SERVE_ALCOHOL]: {
    classCode: {
      display: 'IN',
      values: ['89147'],
    },
    state: {
      display: 'NOT_IN',
      values: ['AL'],
    },
  },
  [LmBopQuestion.INSURED_THIRD_PARTY_SERVE_ALCOHOL]: {
    classCode: {
      display: 'IN',
      values: ['89147'],
    },
    state: {
      display: 'NOT_IN',
      values: ['AL'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.INSURED_OR_EMPLOYEE_SERVE_ALCOHOL,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'NO',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.INSURED_ON_THIRD_PARTY_INSURANCE]: {
    classCode: {
      display: 'IN',
      values: ['89147'],
    },
    state: {
      display: 'NOT_IN',
      values: ['AL'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.INSURED_THIRD_PARTY_SERVE_ALCOHOL,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.THREE_D_PRINTING]: {
    classCode: {
      display: 'IN',
      values: ['81081', '81221'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.DEEP_FAT_FRYER_NUMBER]: {
    classCode: {
      display: 'IN',
      values: ['89138', '89148', '89153'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.INCIDENTAL_COMMERCIAL_COOKING_OPERATIONS,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.PET_RESCUE]: {
    classCode: {
      display: 'IN',
      values: ['80501', '82281', '85501', '86011', '86321', '86831', '88605', '88624'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.GREENHOUSE_PRESENT]: {
    classCode: {
      display: 'IN',
      values: ['80841', '85841', '86421'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.RESTAURANT_EQUIP_INSTALL]: {
    classCode: {
      display: 'IN',
      values: ['85061'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.EMPLOYEE_ON_DUTY]: {
    classCode: {
      display: 'IN',
      values: ['88661'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.APPLIANCE_DELIVERY_SERVICE]: {
    classCode: {
      display: 'IN',
      values: ['83041'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.HIRED_OR_NON_OWNED_AUTO_SELECTED,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.BIZ_OPS_CLOSEDOR_SUSPENDED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.PERCENTAGE_OF_IMPORTED_MERCHANDISE]: {
    classCode: {
      display: 'IN',
      values: ['88712', '88713', '88714', '88715', '88716'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.MOTORIZED_CONVEYANCE]: {
    classCode: {
      display: 'IN',
      values: ['80391', '85391', '88658'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.PERFORMING_REPAIRS]: {
    classCode: {
      display: 'IN',
      values: ['80091', '80841', '81361', '85091', '85871', '86041'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.OPERATIONS_INVOLVE_FLAMMABLES]: {
    classCode: {
      display: 'IN',
      values: ['81081', '81221', '85381', '86821', '89132'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.GLASS_ON_EXTERIOR_CONSTRUCTION]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'IN',
      values: [
        'AL',
        'GA',
        'MS',
        'NC',
        'SC',
        'LA',
        'TX',
        'FL',
        'NY',
        'NJ',
        'CT',
        'RI',
        'MA',
        'DE',
        'ME',
        'MD',
        'NH',
        'VA',
      ],
    },
  },
  [LmBopQuestion.HOME_CARE_SERVICES]: {
    classCode: {
      display: 'IN',
      values: ['81331'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.GROSS_RECEIPTS_FROM_ASSEMBLING_BICYCLES]: {
    classCode: {
      display: 'IN',
      values: ['80391'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.ATYPICAL_DAY_SPA_SERVICES_PROVIDED]: {
    classCode: {
      display: 'IN',
      values: ['88573'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.DONUT_FRYER_UL_PROTECTED]: {
    classCode: {
      display: 'IN',
      values: ['88551', '88577'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.DONUT_FRYER,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.LOCATION_ADULT_MATERIAL_SALES]: {
    classCode: {
      display: 'IN',
      values: ['80031', '80341', '80401', '80801', '85031', '88606', '88712'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.CLEAN_EQUIPMENT_ACCORDING_TO_MANUFACTURER]: {
    classCode: {
      display: 'IN',
      values: ['81021', '88573', '88614'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.INVOLVE_JOBSITE_MANAGEMENT]: {
    classCode: {
      display: 'IN',
      values: ['88547', '88582'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.REPACKAGING_PRODUCTS]: {
    classCode: {
      display: 'IN',
      values: [
        '80091',
        '80701',
        '81021',
        '81361',
        '83221',
        '85091',
        '85701',
        '86381',
        '86391',
        '88572',
        '88573',
        '88598',
        '88603',
        '88614',
        '89149',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.RECREATIONAL_EQUIPMENT]: {
    classCode: {
      display: 'IN',
      values: ['80391'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.HAS_WATERCRAFT_SALE_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['88558'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.DONUT_FRYER]: {
    classCode: {
      display: 'IN',
      values: ['88551', '88577'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.TOTAL_SALES_FROM_DIRECTLY_IMPORTED]: {
    classCode: {
      display: 'IN',
      values: [
        '80021',
        '80061',
        '80091',
        '80211',
        '80331',
        '80341',
        '80391',
        '80401',
        '80701',
        '80831',
        '81361',
        '81371',
        '83041',
        '83161',
        '83221',
        '85011',
        '85021',
        '85061',
        '85091',
        '85341',
        '85391',
        '85401',
        '85601',
        '85701',
        '85871',
        '85911',
        '86041',
        '86311',
        '86381',
        '86391',
        '86511',
        '86591',
        '86611',
        '88553',
        '88558',
        '88572',
        '88584',
        '88603',
        '88623',
        '88636',
        '88658',
        '89139',
        '89149',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.AREA_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.PROFESSIONAL_SERVICES_MEDICAL_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: ['88611'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.PROFESSIONAL_SERVICES_LIABILITY]: {
    classCode: {
      display: 'IN',
      values: [
        '82011',
        '82021',
        '82031',
        '82051',
        '82081',
        '82201',
        '82211',
        '82231',
        '82271',
        '82331',
        '82391',
        '88543',
        '88544',
        '88547',
        '88566',
        '88575',
        '88582',
        '88615',
        '88616',
        '88618',
        '88621',
        '88622',
        '88627',
        '88628',
        '88632',
        '88634',
        '88639',
        '88648',
        '88657',
        '89142',
      ],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.FRYING_ON_PREMISES_1]: {
    classCode: {
      display: 'IN',
      values: ['82150', '89112'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.FRYING_ON_PREMISES_2]: {
    classCode: {
      display: 'IN',
      values: [
        '80881',
        '85521',
        '85621',
        '88552',
        '88567',
        '88568',
        '88592',
        '88593',
        '88602',
        '88604',
        '88637',
        '88659',
        '88574',
        '88608',
        '88631',
        '88701',
        '89151',
        '89152',
        '89153',
      ],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LOCATION_FIREARME_SALES]: {
    classCode: {
      display: 'IN',
      values: ['80091', '80391', '85091', '85391', '88643', '88644'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.LOCATION_WEB_SALES]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80021',
        '80031',
        '80051',
        '80061',
        '80071',
        '80101',
        '80111',
        '80131',
        '80151',
        '80161',
        '80171',
        '80181',
        '80191',
        '80201',
        '80211',
        '80311',
        '80321',
        '80331',
        '80341',
        '80361',
        '80371',
        '80381',
        '80391',
        '80401',
        '80411',
        '80421',
        '80501',
        '80561',
        '80571',
        '80701',
        '80801',
        '80831',
        '80841',
        '81121',
        '81191',
        '81351',
        '81361',
        '81371',
        '81381',
        '82261',
        '83161',
        '83171',
        '83181',
        '83191',
        '83201',
        '83211',
        '83221',
        '83241',
        '83671',
        '83751',
        '83831',
        '83901',
        '85011',
        '85021',
        '85031',
        '85061',
        '85071',
        '85101',
        '85111',
        '85131',
        '85141',
        '85161',
        '85171',
        '85191',
        '85241',
        '85251',
        '85311',
        '85341',
        '85351',
        '85361',
        '85371',
        '85381',
        '85391',
        '85401',
        '85501',
        '85601',
        '85641',
        '85661',
        '85671',
        '85691',
        '85701',
        '85741',
        '85811',
        '85821',
        '85831',
        '85841',
        '85851',
        '85861',
        '85901',
        '85911',
        '86021',
        '86031',
        '86051',
        '86061',
        '86071',
        '86081',
        '86111',
        '86211',
        '86311',
        '86331',
        '86341',
        '86361',
        '86371',
        '86381',
        '86391',
        '86421',
        '86431',
        '86461',
        '86471',
        '86481',
        '86491',
        '86511',
        '86541',
        '86561',
        '86571',
        '86581',
        '86591',
        '86611',
        '86621',
        '86631',
        '86651',
        '86661',
        '86671',
        '86681',
        '86691',
        '86711',
        '86721',
        '86731',
        '86741',
        '86751',
        '86761',
        '86781',
        '86791',
        '86811',
        '86821',
        '86831',
        '86841',
        '86851',
        '86881',
        '86891',
        '86911',
        '86921',
        '86931',
        '86941',
        '86951',
        '88546',
        '88548',
        '88549',
        '88553',
        '88554',
        '88558',
        '88559',
        '88563',
        '88565',
        '88572',
        '88578',
        '88584',
        '88587',
        '88588',
        '88589',
        '88591',
        '88595',
        '88596',
        '88597',
        '88598',
        '88599',
        '88601',
        '88603',
        '88606',
        '88612',
        '88613',
        '88623',
        '88626',
        '88633',
        '88636',
        '88641',
        '88643',
        '88645',
        '88655',
        '89131',
        '89133',
        '89135',
        '89136',
        '89137',
        '89139',
        '89146',
        '89149',
        '89151',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.DRUGGIST_BACKGROUND_CHECKS]: {
    classCode: {
      display: 'IN',
      values: ['81331'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LIQUOR_WRITTEN_GUIDELINES]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'NOT_IN',
      values: ['AL'],
    },
    dependency: {
      functionName: LmBopComplexEvaluator.LIQ_LIABILITY_OR_BYOB,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.OPTOMETRISTS_SURGERY]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.OPTOMETRIST_LICENSED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.OPTOMETRIST_PRESCRIPTION]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.OPTOMETRISTS_PROFESSIONAL_LIABILITY,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: true,
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.LEASED_EQUIPMENT_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80091',
        '80841',
        '81331',
        '81361',
        '83041',
        '85011',
        '85091',
        '85601',
        '85841',
        '85871',
        '86041',
        '88623',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.LEASED_EQUIPMENT,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.APPLICANT_SUBSIDIARIES_DETAILS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.APPLICANT_SUBSIDIARIES,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.MAJORITY_INSURED_SALES]: {
    classCode: {
      display: 'IN',
      values: ['88712', '88713', '88714', '88715', '88716'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.AREA_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.MEDICINAL_MARIJUANA_OPERATIONS_TYPE]: {
    classCode: {
      display: 'IN',
      values: ['81331', '82011', '88598', '88615', '88628'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.CP_EXTING_SYS_SERV_SEMI_ANNUAL]: {
    classCode: {
      display: 'IN',
      values: ['89138', '89148', '89153'],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.CPUL_300_EXTINGUISHING_SYSTEM,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_1]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'NOT_IN',
      values: ['AL'],
    },
    dependency: {
      functionName: LmBopComplexEvaluator.LIQ_LIABILITY_OR_BYOB,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.LIQUOR_CONTROL_VIOLATIONS_2]: {
    classCode: {
      display: 'IN',
      values: ['89147'],
    },
    state: {
      display: 'NOT_IN',
      values: ['AL'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.INSURED_OR_EMPLOYEE_SERVE_ALCOHOL,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.HAS_LEAD_ABATEMENT]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'IN',
      values: ['NY'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.ARE_THERE_ANY_HABITATIONAL_EXPOSURES_IN_THIS_BUILDING,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.PRIOR_COVERAGE_REASON]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.PRIOR_COVERAGE,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'NO',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.PRIOR_COVERAGE_DETAILS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName:
        LmBopComplexEvaluator.PRIOR_COVERAGE_REASON_IS_NEW_ACQUISITION_FOR_THE_INSURED_OR_NONE_OF_THE_ABOVE,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES_DETAILS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.NAMED_INSD_OTHER_ACTIVITIES,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.REFURBISHED_EQUIPMENT_DETAILS]: {
    classCode: {
      display: 'IN',
      values: [
        '80011',
        '80021',
        '80061',
        '80091',
        '80101',
        '80131',
        '80391',
        '80401',
        '80571',
        '80841',
        '81361',
        '81371',
        '83011',
        '83041',
        '83671',
        '83831',
        '85011',
        '85021',
        '85061',
        '85091',
        '85131',
        '85391',
        '85401',
        '85571',
        '85601',
        '85671',
        '85691',
        '85821',
        '85831',
        '85841',
        '85851',
        '85871',
        '85911',
        '86041',
        '86211',
        '86311',
        '86331',
        '86341',
        '86391',
        '86481',
        '86511',
        '86661',
        '86671',
        '88553',
        '88595',
        '88596',
        '88603',
        '88633',
        '88636',
        '88645',
        '89149',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.REFURBISHED_EQUIPMENT,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.EPLI_CLAIMS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'NOT_IN',
      values: ['NY'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES,
      },
      //  WARNING: "This checks that this value was chosen, but the question itself is not required. We might need to revisit if coercing undefined to 0 isn't good behavior.",
      operator: DependencyOperator.GREATER_THAN,
      right: {
        source: DependencySource.VALUE,
        value: '0',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.TENANT_OPERATIONS_TYPE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.HAS_BLDG_CVG_AND_HAS_TENANTS,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.LPG_SALES_RECEIPTS]: {
    classCode: {
      display: 'IN',
      values: ['80091', '80841', '85091'],
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.ARSON_CONVICTION]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.RESTAURANT_BOH_ACTIVITIES]: {
    classCode: {
      display: 'IN',
      values: [
        '88551',
        '88552',
        '88567',
        '88574',
        '88577',
        '88592',
        '88602',
        '88631',
        '88637',
        '88701',
        '89138',
        '89148',
        '89151',
        '89152',
        '89153',
      ],
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      functionName: LmBopComplexEvaluator.BLDG_OCC_BY_INS_AND_BPP_OVER_0,
      type: 'COMPLEX',
    },
  },
  [LmBopQuestion.ROOF_CONSTRUCTION]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.ROOF_TYPE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.YEAR_ROOF_REPLACED]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
  },
  [LmBopQuestion.LOSSES]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'ALL',
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.HAS_SINKHOLE_CLAIMS]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'IN',
      values: ['FL'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
  [LmBopQuestion.HAS_SINKHOLE_DAMAGE]: {
    classCode: {
      display: 'ALL',
    },
    state: {
      display: 'IN',
      values: ['FL'],
    },
    dependency: {
      left: {
        source: DependencySource.ID,
        value: LmBopQuestion.HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED,
      },
      operator: DependencyOperator.EQUAL,
      right: {
        source: DependencySource.VALUE,
        value: 'YES',
      },
      type: 'SIMPLE',
    },
  },
};
