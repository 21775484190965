import { FormGroup, FormControl, Validators } from '@angular/forms';

import {
  enableDisableControl,
  requireCheckboxesToBeCheckedValidator,
} from 'app/shared/helpers/form-helpers';
import { Subscription } from 'rxjs';
import { RouteFormStep } from '../form-dsl/services/form-dsl-stepped-form-base.service';

export type FeedbackCategory = 'carrierPreference' | 'coverage' | 'other' | 'price' | 'service';

type FeedbackControlPair = FormGroup<{
  checkbox: FormControl<boolean>;
  comment: FormControl<string>;
}>;

export type QuoteFeedbackForm = FormGroup<{
  [key in FeedbackCategory]: FeedbackControlPair;
}>;

// We use a Map here for better type-safety when iterating through keys and to guarantee order for questions.
export const FEEDBACK_LABEL_MAP = new Map<
  FeedbackCategory,
  { checkboxLabel: string; commentLabel: string }
>([
  [
    'carrierPreference',
    {
      checkboxLabel: 'Carrier Preference',
      commentLabel: 'Tell me more',
    },
  ],
  [
    'coverage',
    {
      checkboxLabel: 'Coverage',
      commentLabel: 'What coverage do you need to add?',
    },
  ],
  [
    'price',
    {
      checkboxLabel: 'Price',
      commentLabel: 'What is your target price?',
    },
  ],
  [
    'service',
    {
      checkboxLabel: 'Service',
      commentLabel: 'Tell me more',
    },
  ],
  // Other should remain the last option
  [
    'other',
    {
      checkboxLabel: 'Other',
      commentLabel: 'Tell me more',
    },
  ],
]);

/**
 * Quote feedback route form step to be used by stepped forms.
 */
export const QUOTE_FEEDBACK_STEP: RouteFormStep = {
  displayName: 'Quote feedback',
  formPath: 'quoteFeedback',
  parent: 'quote-feedback',
  slug: 'quote-feedback',
  args: {},
};

export const createQuoteFeedbackFormGroup = (): QuoteFeedbackForm => {
  const formGroup = {} as { [K in FeedbackCategory]: FeedbackControlPair };
  FEEDBACK_LABEL_MAP.forEach((_value, category) => {
    const controlPairFormGroup = new FormGroup({
      checkbox: new FormControl(false, { nonNullable: true }),
      comment: new FormControl(
        { value: '', disabled: true },
        { nonNullable: true, validators: Validators.required }
      ),
    });
    formGroup[category] = controlPairFormGroup;
  });

  const feedbackFormGroup = new FormGroup(formGroup, {
    validators: requireCheckboxesToBeCheckedValidator(1),
  });

  // Disable initially.
  feedbackFormGroup.disable();
  return feedbackFormGroup;
};

export const enableFeedbackForm = (
  feedbackForm: QuoteFeedbackForm,
  categoriesToEnable?: FeedbackCategory[]
): void => {
  // If subset of categories is passed in, only enable them - otherwise enable all checkboxes.
  const feedbackCategoriesToEnable: FeedbackCategory[] = categoriesToEnable
    ? categoriesToEnable
    : [...FEEDBACK_LABEL_MAP.keys()];

  const feedbackFormControls = feedbackForm.controls;
  // Enable all checkbox controls without affecting the comment controls.
  feedbackCategoriesToEnable.forEach((feedbackCategory: FeedbackCategory) => {
    feedbackFormControls[feedbackCategory].controls.checkbox.enable();
  });
};

/**
 *
 * @param quoteFeedbackForm - The quote feedback form group.
 * @param teardownSubscription  - a Subscription to be passed from the consuming component to unsubscribe on ngOnDestroy
 */

export const createDependentFormSubscriptions = (
  quoteFeedbackForm: QuoteFeedbackForm,
  teardownSubscription: Subscription
): void => {
  const feedbackFormControls = quoteFeedbackForm.controls;
  FEEDBACK_LABEL_MAP.forEach((_value, category) => {
    const checkboxControl = feedbackFormControls[category].controls.checkbox;
    const commentControl = feedbackFormControls[category].controls.comment;

    teardownSubscription.add(
      checkboxControl.valueChanges.subscribe((shouldEnable: boolean) => {
        enableDisableControl(commentControl, shouldEnable);
      })
    );
  });
};
