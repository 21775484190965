<svg class="circle-spinner" *ngIf="!isIE">
  <circle
    cx="11"
    cy="11"
    r="9"
    stroke-width="2"
    stroke-dasharray="0,1000"
    d="M5 40 l215 0"
    fill="transparent"
    stroke="#4579ff"
  />
</svg>
<img src="assets/img/loading-spinner-white.gif" *ngIf="isIE" alt="loading" />
