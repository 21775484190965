<div
  [ngClass]="{
    'app-modal__closed': !open,
    'app-modal__flyout': isFlyout(),
    'app-modal__drawer': isDrawer(),
    'app-modal': true,
    'app-modal__video': isVideo()
  }"
  class="{{ modalContainerClass }}"
  attr.role="{{ ariaRole }}"
  aria-modal="true"
  attr.aria-describedby="{{ ariaDescribedby }}"
  attr.aria-labelledby="{{ ariaLabelledby }}"
>
  <div
    *ngIf="hasCurtain()"
    class="app-modal-curtain"
    (click)="handleClickClose()"
    (keyup.enter)="handleClickClose()"
  ></div>
  <div class="{{ modalClass }} app-modal-content" [ngClass]="{ 'animate-close': isAnimatingClose }">
    <div class="app-modal-inner-content">
      <button
        *ngIf="(!disableClose && isVideo()) || (!disableClose && isPlainModal())"
        type="button"
        class="app-modal-close-button"
        [attr.data-amplitude-input-name]="getModalCloseAmplitudeEventName()"
        (click)="handleClickClose()"
      >
        <span class="icon-close"></span><span class="sr-only">Close</span>
      </button>

      <button
        *ngIf="!disableClose && isFlyout()"
        class="button button__nav button__no-margin"
        (click)="handleClickClose()"
      >
        <span class="icon-arrow-left button-icon"></span>Cancel
      </button>

      <button
        *ngIf="!disableClose && isDrawer()"
        class="button button__modal-drawer button__small button__transparent"
        (click)="handleClickClose()"
      >
        Hide
      </button>
      <ng-content></ng-content>
    </div>
  </div>
</div>
