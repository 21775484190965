<main id="unsubscribe" class="unsubscribe-box">
  <app-logo-with-text></app-logo-with-text>

  <form (submit)="unsubscribe($event)" class="unsubscribe-box-form">
    <h1>Unsubscribe</h1>
    <p class="gray-text" *ngIf="!isUnsubscribedCompleted && !isUnsuccessful">
      By clicking unsubscribe you will no longer receive {{ emailDescription() }} emails.
    </p>
    <button
      type="submit"
      class="button button__primary button__full-width button__no-margin"
      [disabled]="loading"
      *ngIf="!isUnsubscribedCompleted && !isUnsuccessful"
    >
      <div class="button-loader" *ngIf="loading">
        <svg class="circle-spinner">
          <circle
            cx="11"
            cy="11"
            r="9"
            stroke-width="2"
            stroke-dasharray="0,1000"
            d="M5 40 l215 0"
            fill="transparent"
          />
        </svg>
      </div>
      Unsubscribe
    </button>
  </form>
  <app-dialog-box *ngIf="isUnsubscribedCompleted" type="success">
    You've successfully unsubscribed!
  </app-dialog-box>
  <app-dialog-box *ngIf="isUnsuccessful" type="danger">
    We encountered an error, please try again.
  </app-dialog-box>
</main>
