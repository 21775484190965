import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BindsNearYouData,
  RecentActivitySummaryService,
  ClassCodeModuleData,
} from 'app/shared/services/recent-activity-summary.service';
import { UserService } from 'app/core/services/user.service';
import { Subscription, switchMap, EMPTY, of as observableOf, forkJoin } from 'rxjs';
import { SMALL_LAPTOP_THRESHOLD } from 'app/constants';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { NaicsService } from 'app/shared/services/naics.service';

@Component({
  selector: 'app-activity-recent-binds-near-you',
  templateUrl: 'activity-recent-binds-near-you.component.html',
})
export class ActivityRecentBindsNearYouComponent implements OnInit, OnDestroy {
  constructor(
    private userService: UserService,
    private recentActivitySummaryService: RecentActivitySummaryService,
    private insuredAccountService: InsuredAccountService,
    private naicsService: NaicsService
  ) {}
  private sub: Subscription = new Subscription();

  classCodeModuleData: ClassCodeModuleData;
  showClassCodeModule = false;
  displayCount = 3;
  displayShowMoreButton = true;
  shouldShowNumericCode = false;
  bindsSinceEarliestDate: string;
  bindsSinceLatestDate: string;
  isSmallLaptopOrLess = false;

  ngOnInit() {
    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) =>
            forkJoin([this.naicsService.getProductAvailability(), observableOf(user)])
          ),
          switchMap(([productAvailability, user]) => {
            if (
              productAvailability.some(
                (eligibility) =>
                  eligibility.pasSource === 'attune_gw' &&
                  eligibility.product === 'bop' &&
                  eligibility.classCodeSelection !== 'NONE'
              )
            ) {
              return forkJoin([
                this.insuredAccountService.getProducerCodeDetails(user.producer),
                observableOf(user),
              ]);
            } else {
              return EMPTY;
            }
          }),
          switchMap(([producerDetails, user]) => {
            return forkJoin([
              this.insuredAccountService.isProducerCatRestricted(
                user.producer,
                producerDetails.Address.State
              ),
              observableOf(user),
            ]);
          }),
          switchMap(([isProducerCatRestricted, user]) => {
            if (!isProducerCatRestricted) {
              return this.recentActivitySummaryService.getBindsNearYou(user.producer);
            } else {
              return EMPTY;
            }
          })
        )
        .subscribe((classCodeModuleResponse) => {
          this.classCodeModuleData = classCodeModuleResponse;
          if (this.classCodeModuleData.bindsNearYou.length >= 5) {
            this.showClassCodeModule = true;
            this.setDateAndClasscode(this.classCodeModuleData.bindsNearYou);
          }
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setDateAndClasscode(bindsNearYou: BindsNearYouData[]) {
    this.setToolTipShape();
    this.shouldShowNumericCode = bindsNearYou.every((row) => row.classCode !== undefined);

    const sortedByDate = bindsNearYou.sort((a, b) => {
      return new Date(a.boundDate).getTime() - new Date(b.boundDate).getTime();
    });
    this.bindsSinceEarliestDate = sortedByDate[0].boundDate;
    this.bindsSinceLatestDate = sortedByDate[sortedByDate.length - 1].boundDate;
  }

  setToolTipShape() {
    this.isSmallLaptopOrLess = window.innerWidth < SMALL_LAPTOP_THRESHOLD;
  }

  setToolTipText(classcode: string, description: string): string {
    if (this.shouldShowNumericCode && classcode && !this.isSmallLaptopOrLess) {
      return `Classcode ${classcode}: ${description}`;
    } else {
      return description;
    }
  }

  getCarrierName(dataRow: BindsNearYouData) {
    switch (dataRow.productType) {
      case 'BOP':
        return 'attune';
      case 'WC':
        return 'employers';
      case 'GL':
      case 'PL':
        return 'hiscox';
    }
  }

  getBadgeName(productType: string): string {
    const badgeClass = 'product-badge__';
    if (productType.includes(' ')) {
      const underscores = productType.replace(' ', '_');
      return badgeClass + underscores;
    }

    return badgeClass + productType;
  }

  showMore() {
    this.displayShowMoreButton = false;
    this.displayCount = 10;
  }
}
