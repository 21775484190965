import { Component, ElementRef, ViewChild, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { randomInt } from '../helpers/number-format-helpers';
import { tap } from 'rxjs/operators';

export interface Star {
  x: number;
  y: number;
  points: number;
  outerRadius: number;
  innerRadius: number;
  ticks: number;
}

const EXTRA_HEIGHT_ABOVE_PARENT = 60; // Pixels added to parent container to get canvas size
const WHERE_STARS_START_ON_CANVAS = 0.4; // Percentage of canvas that stars will appear on

@Component({
  selector: 'app-shimmer-animation',
  templateUrl: './shimmer-animation.component.html',
})
export class ShimmerAnimationComponent implements OnDestroy, OnInit {
  @ViewChild('canvas', { static: true })
  canvasRef: ElementRef;
  @Input() parentContainer$: Observable<ElementRef>;
  running = false;
  private context2d: CanvasRenderingContext2D;
  private stars: Star[] = [];
  private sub = new Subscription();

  constructor(private ngZone: NgZone) {}

  static devicePixelRatio() {
    return window.devicePixelRatio;
  }

  static canvasDimensions(parentContainer: HTMLElement) {
    return {
      height:
        (parentContainer.offsetHeight + EXTRA_HEIGHT_ABOVE_PARENT) *
        ShimmerAnimationComponent.devicePixelRatio(),
      width: parentContainer.offsetWidth * ShimmerAnimationComponent.devicePixelRatio(),
    };
  }

  static createStars(numOfStars: number, canvasWidth: number, canvasHeight: number) {
    const stars: Star[] = [];
    const maxYPosition = canvasHeight * WHERE_STARS_START_ON_CANVAS;
    for (let i = 0; i < numOfStars; i++) {
      stars.push({
        x: randomInt(6, canvasWidth - 6),
        y: randomInt(-5, maxYPosition),
        points: randomInt(4, 6),
        outerRadius: 9 * ShimmerAnimationComponent.devicePixelRatio(),
        innerRadius: 3 * ShimmerAnimationComponent.devicePixelRatio(),
        ticks: 1,
      });
    }

    return stars;
  }

  ngOnInit() {
    const animation = this.parentContainer$
      .pipe(
        tap((element) => {
          // If no parent element is received, do not animate
          if (!element) {
            this.running = false;
          } else if (element && !this.running) {
            this.context2d = this.canvasRef.nativeElement.getContext('2d');
            this.scaleCanvas(element);

            // Generate stars
            const { height, width } = ShimmerAnimationComponent.canvasDimensions(
              element.nativeElement
            );
            const amountOfStars = Math.floor(width / 30);
            this.stars = ShimmerAnimationComponent.createStars(amountOfStars, width, height);

            // Start animating
            this.running = true;
            this.ngZone.runOutsideAngular(() => this.animate(width, height));
          }
        })
      )
      .subscribe();
    this.sub.add(animation);
  }

  ngOnDestroy() {
    this.running = false;
    this.sub.unsubscribe();
  }

  drawStar = ({ x, y, points, outerRadius, innerRadius }: Star) => {
    let rot = (Math.PI / 2) * 3;
    let newX = x;
    let newY = y;
    const step = Math.PI / points;

    this.context2d.beginPath();
    this.context2d.moveTo(x, y - outerRadius);
    for (let i = 0; i < points; i++) {
      newX = x + Math.cos(rot) * outerRadius;
      newY = y + Math.sin(rot) * outerRadius;
      this.context2d.lineTo(newX, newY);
      rot += step;

      newX = x + Math.cos(rot) * innerRadius;
      newY = y + Math.sin(rot) * innerRadius;
      this.context2d.lineTo(newX, newY);
      rot += step;
    }
    this.context2d.lineTo(x, y - outerRadius);
    this.context2d.closePath();
    this.context2d.fillStyle = '#F8D31C';
    this.context2d.fill();
  };

  tick(
    { x, y, ticks, outerRadius, innerRadius, points }: Star,
    canvasWidth: number,
    canvasHeight: number
  ): Star {
    const maxYPosition = canvasHeight * WHERE_STARS_START_ON_CANVAS;
    const almostMaxYPosition = maxYPosition - 2.4;
    const rateOfSizeIncrease = y / (maxYPosition - 5);

    // Descrease height position to move star up the canvas
    y -= 0.15;

    let drawOuterRadius: number;
    let drawInnerRadius: number;

    // If star appears at the bottom of canvas, rapidly
    // scale up the radiuses to make the star expand in size
    // Else decrease the radiuses / size as the star nears
    // the top of the canvas
    if (ticks < 16 && y > almostMaxYPosition) {
      drawOuterRadius = (outerRadius * ticks) / 16;
      drawInnerRadius = (innerRadius * ticks) / 16;
    } else {
      drawOuterRadius = outerRadius * rateOfSizeIncrease;
      drawInnerRadius = innerRadius * rateOfSizeIncrease;
    }

    // If outer & inner radius, draw star
    if (outerRadius > 0 && innerRadius > 0 && y > -5) {
      this.drawStar({
        x,
        y,
        points,
        outerRadius: drawOuterRadius,
        innerRadius: drawInnerRadius,
        ticks: ticks + 1,
      });
    }

    // If star has disappeared, return a new star at the bottom of the canvas
    if (y < -5) {
      return {
        x: randomInt(6, canvasWidth - 6),
        y: maxYPosition,
        points: randomInt(4, 6),
        outerRadius: 9 * ShimmerAnimationComponent.devicePixelRatio(),
        innerRadius: 3 * ShimmerAnimationComponent.devicePixelRatio(),
        ticks: 1,
      };
    }

    return { x, y, ticks: ticks + 1, outerRadius, innerRadius, points };
  }

  scaleCanvas(parentContainer: ElementRef) {
    const canvas = this.canvasRef.nativeElement;
    const { width, height } = ShimmerAnimationComponent.canvasDimensions(
      parentContainer.nativeElement
    );
    if (canvas.width !== width) {
      canvas.width = width;
      canvas.style.width = width / ShimmerAnimationComponent.devicePixelRatio() + 'px';
    }
    if (canvas.height !== height) {
      canvas.height = height;
      canvas.style.height = height / ShimmerAnimationComponent.devicePixelRatio() + 'px';
    }
  }

  private animate(canvasWidth: number, canvasHeight: number) {
    if (!this.running) {
      return;
    }
    // Clear the previous animation frame
    this.context2d.clearRect(0, 0, canvasWidth, canvasHeight);

    // Update and draw stars
    if (this.stars) {
      this.stars = this.stars.map((star) => this.tick(star, canvasWidth, canvasHeight));
    }

    requestAnimationFrame(() => this.animate(canvasWidth, canvasHeight));
  }
}
