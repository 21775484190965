import { of as observableOf } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { API_V3_BASE } from 'app/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmplitudeService } from 'app/core/services/amplitude.service';

import { SentryService } from 'app/core/services/sentry.service';
import { CurrentUserService } from 'app/core/services/current-user.service';

const BUILDING_VERIFICATION_URL = `${API_V3_BASE}/quotes/buildingEstimates`;

export interface BuildingVerificationResponse {
  bppEstimate?: number;
  replacementEstimate?: number;
  salesEstimate?: number;
}

@Injectable()
export class AttuneBopBuildingVerificationService {
  constructor(
    protected http: HttpClient,
    protected amplitudeService: AmplitudeService,
    private currentUserService: CurrentUserService,
    private sentryService: SentryService
  ) {}

  getBuildingVerification(buildingVerificationRequest: any) {
    return this.http
      .post<BuildingVerificationResponse>(BUILDING_VERIFICATION_URL, buildingVerificationRequest)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to get building verification data.', {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });

          return observableOf({});
        })
      );
  }
}
