import { first } from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfettiMixin } from 'app/shared/mixins/confetti.mixin';

class BindingAnimation {}
const ConfettiedBindingAnimation = ConfettiMixin(BindingAnimation);

/**
 * Component that renders the binding animation
 */
@Component({
  selector: 'app-binding-animation',
  templateUrl: './binding-animation.component.html',
})
export class BindingAnimationComponent
  extends ConfettiedBindingAnimation
  implements OnDestroy, OnInit
{
  @ViewChild('canvas', { static: true })
  canvasRef: ElementRef;
  @Input() finish$: Observable<boolean>;
  @Input() showProgressBar = false;
  @Input() successComment = 'Preparing policy...';

  ngOnInit() {
    this.init();

    if (this.finish$) {
      this.finish$.pipe(first()).subscribe((isFinished) => {
        this.running = isFinished;
        this.ngZone.runOutsideAngular(() => this.animate());
      });
    }
  }

  ngOnDestroy() {
    this.destroy();
  }
}
