import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of as observableOf, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SentryService } from 'app/core/services/sentry.service';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { DigitalCarrierPolicyDetails, ProductCombination } from '../models/types';
import { API_V4_BASE } from 'app/constants';

export const DCP_POLICY_API_URL = (dcpProduct: ProductCombination) =>
  `${API_V4_BASE}/policies/${dcpProduct.pasSource}/${dcpProduct.product}`;

@Injectable({
  providedIn: 'root',
})
export class DigitalCarrierPolicyService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private currentUserService: CurrentUserService
  ) {}

  getPolicyDetails(
    policyIdentifier: string,
    dcpProduct: ProductCombination
  ): Observable<DigitalCarrierPolicyDetails | null> {
    return this.http
      .get<DigitalCarrierPolicyDetails>(`${DCP_POLICY_API_URL(dcpProduct)}/${policyIdentifier}`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to get policy details', {
            severity: 'error',
            metaData: {
              policyIdentifier,
              currentUserName: this.currentUserName(),
              errorMessage: error.message,
              error: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  private currentUserName() {
    const user = this.currentUserService.getCurrentUser();
    if (user && user.username) {
      return user.username;
    }
    throw new Error('Login Expired');
  }
}
