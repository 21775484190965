<app-modal
  *ngIf="neptuneFloodQuote"
  id="neptune-flood-helper-modal"
  [open]="neptuneFloodQuote"
  (handleModalClose)="close()"
>
  <div class="neptune-modal">
    <img class="neptune-modal-flooded-town" src="/assets/img/neptune_flooded_town.png" alt="" />
    <div class="neptune-modal-product-logo-container">
      <img
        class="neptune-modal-product-logo"
        src="/assets/img/neptune_logo_horizontal_blue.svg"
        alt="Neptune Logo"
      />
    </div>
    <h2 class="h2">Neptune Flood + Attune</h2>
    <ul class="bulleted-list">
      <li>Available in every state but Alaska</li>
      <li>10% new / 8% renewal commission</li>
      <li>Quote & bind in minutes with no elevation certificate (EC) required</li>
      <li>Almost always priced lower than NFIP</li>
      <li>
        Better coverage - Up to $4m in building coverage and $25,000 in business interruption
        coverage
      </li>
      <li>
        <a href="{{ NEPTUNE_FLOOD_GUIDE }}" target="_blank" class="small-text"> Learn more </a>
      </li>
    </ul>
    <div class="modal-action-group modal-action-group__right">
      <div class="nav-button-group">
        <a class="button button__secondary" (click)="close()" (keyup.enter)="close()"> Close </a>
        <a
          href="{{ neptuneFloodQuote.details.link }}"
          target="_blank"
          (click)="continueToNeptuneAndClose()"
          class="button button__primary"
        >
          Continue to Neptune
        </a>
      </div>
      <app-form-field-checkbox
        *ngIf="showHideNeptuneFloodPrompt"
        inputId="dontShowAgain"
        labelText="Don't show me again"
        nameOfFormControl="dontShowAgain"
        [form]="neptuneFloodPrompt"
      >
      </app-form-field-checkbox>
    </div>
  </div>
</app-modal>

<app-modal
  *ngIf="!neptuneFloodQuote"
  id="neptune-flood-decline-helper-modal"
  [open]="!neptuneFloodQuote"
  (handleModalClose)="close()"
>
  <div class="neptune-modal">
    <h2 class="h2">Failed to get a Neptune Flood quote</h2>
    <ul class="bulleted-list">
      <li>The address may be ineligible for coverage</li>
      <li>The address may be entered incorrectly</li>
    </ul>
    <div class="modal-action-group modal-action-group__right">
      <div class="nav-button-group">
        <a (click)="close()" (keyup.enter)="close()" class="button button__secondary"> Close </a>
      </div>
    </div>
  </div>
</app-modal>
