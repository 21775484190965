import { get } from 'lodash';
import * as $ from 'jquery';

// Constants
export const AMPLITUDE_INPUT_ATTRIBUTE_NAME = 'data-amplitude-input-name';
export const AMPLITUDE_REDACT_ATTRIBUTE_NAME = 'data-amplitude-redact';
export const REDACTED_VALUE = 'redacted';
export const TOOLTIP_TRACK_DELAY_MS = 750;

// Standard `input` values
export const INPUT_FALLBACK_VALUE = 'err_no_input';
export const INPUT_PAGE_ENTER = 'page_enter';
export const INPUT_NAV_ERROR = 'navigation_error';
export const INPUT_NAV_CANCEL = 'navigation_cancel';

// Regular expressions for url and value cleanup
export const UUID_PATTERN = /\/[0-9A-Fa-f]{8}(?:\-[0-9A-Fa-f]{4}){3}\-[0-9A-Fa-f]{12}/;
export const NUMERAL_PATTERN = /\/[0-9][0-9a-zA-Z]*/;
export const UNIQUE_ID_REGEX = new RegExp(
  `(${UUID_PATTERN.source}|${NUMERAL_PATTERN.source})`,
  'g'
);
export const HISCOX_POLICY_ID_REGEX = /[A-Z][0-9]{3}.[0-9]{3}.[0-9]{3}/;
export const QUERY_PARAMS_REGEX = new RegExp(/\?.*/);
export const FRAGMENT_ID_REGEX = new RegExp(/#.*/);
export const HELP_CENTER_FORM_ID_REGEX = new RegExp(/(form)\/[a-z\-]+/);
export const TERMS_AND_POLICY_PAGE_REGEX = /accounts\/(terms|policy)/g;
export const WHITESPACE_REGEX = /( |\n)+/g;

// Fields which may be used in an Amplitude payload - typed so that we do not accidentally add more.
// Add new fields here as needed--please avoid duplication when possible.
export type AmplitudePayloadField =
  | 'error'
  | 'status'
  | 'description'
  | 'accountNaicsCode'
  | 'rewardsPoints'
  | 'accountId'
  | 'bopTransactionId'
  | 'accountNaicsDescription'
  | 'url'
  | 'instrumentId'
  | 'email'
  | 'hydraPolicyUuid'
  | 'paymentPlan'
  | 'product'
  | 'pasSource'
  | 'smsMessageId'
  | 'effectiveDate'
  | 'jobNumber'
  | 'policyNumber'
  | 'inputType'
  | 'termNumber'
  | 'lineOfBusiness'
  | 'getPriceReductionReq'
  | 'getPriceReductionRes'
  | 'requoteTsRequestId'
  | 'userEntered'
  | 'isAdp'
  | 'endorsementTransactionWaived'
  | 'eligibilitySummary'
  | 'cyberUpsellIsEnabled'
  | 'upsellProductAdded'
  | 'defaultCyberProductForUpsell';

// Helpers
export const shouldRedact = (target: Element): boolean => {
  const attr: Attr | null = target.attributes.getNamedItem(AMPLITUDE_REDACT_ATTRIBUTE_NAME);
  if (attr === null) {
    return false;
  }

  return attr.value === 'true';
};

export const useValueOrRedact = (
  target: Element,
  value: string | boolean | number | null | undefined
): string | boolean | number => {
  if (shouldRedact(target)) {
    return REDACTED_VALUE;
  }

  return value || INPUT_FALLBACK_VALUE;
};

export const getRadioButtonInput = (obj: HTMLElement): string => {
  const $obj = $(obj);

  const labelText = $obj.closest('[class*="form-field"]').find('label').first().text();
  if (labelText && labelText.trim()) {
    return labelText;
  }

  if ($obj.attr(AMPLITUDE_INPUT_ATTRIBUTE_NAME)) {
    return $obj.attr(AMPLITUDE_INPUT_ATTRIBUTE_NAME) || INPUT_FALLBACK_VALUE;
  }

  const closestWithInputNameAttribute = $obj.closest(`[${AMPLITUDE_INPUT_ATTRIBUTE_NAME}]`);
  if (closestWithInputNameAttribute.length > 0) {
    return (
      closestWithInputNameAttribute.attr(AMPLITUDE_INPUT_ATTRIBUTE_NAME) || INPUT_FALLBACK_VALUE
    );
  }

  return INPUT_FALLBACK_VALUE;
};

export const getLinkInput = (obj: HTMLAnchorElement): string => {
  return (
    $(obj).attr(AMPLITUDE_INPUT_ATTRIBUTE_NAME) ||
    obj.classList[0] ||
    obj.text ||
    'undetermined_link'
  );
};

export const getSliderValue = (obj: HTMLInputElement) => {
  return get($(obj).siblings('.slider-output'), '[0].textContent') || '';
};

export const getTooltipInput = (obj: HTMLElement) => {
  // TODO (LT): Based on recent events in Amplitude, I think this
  // calculation is often broken and should be revisited
  const par = $(obj).closest('label');
  return par.attr('for') || par.text();
};

export const sanitizeInput = (input: string) => {
  if (!input) {
    return INPUT_FALLBACK_VALUE;
  }

  return input
    .replace(WHITESPACE_REGEX, ' ')
    .replace(/^.*?\-[0-9]+\-([0-9]+\-)?/g, '')
    .trim()
    .toLowerCase();
};

export const sanitizeValue = (value: string | number | boolean | null | undefined) => {
  if (value === undefined || value === null) {
    return '';
  }

  return String(value).trim().replace(WHITESPACE_REGEX, ' ');
};

// Types and enums
export enum AmplitudeCssSelector {
  Breadcrumb = '.sidebar-breadcrumb',
  Button = '.button, button:not(.close):not([id*="typeahead"][class*="dropdown-item"]), input[type=button]:not(.close), details[data-amplitude-input-name]',
  Checkbox = ':checkbox',
  Dropdown = 'select',
  Freetext = 'input:not([type=password]):not([type="checkbox"]):not([type*="radio"]):not([type*="range"]), textarea',
  Link = 'a:not(:button, .button)',
  Radio = '.radio-question-label, .radio-question-alt-label, .traditional-radio-question-label, .radio-question-list-label',
  Slider = 'input.slider',
  Tooltip = '.tool-tip:not(.main-nav-link)',
}

// TODO: What is even happening here? Enum to string conversion doesn't work
export const AmplitudeCssSelectorButton =
  '.button, button:not(.close):not([id*="typeahead"][class*="dropdown-item"]), input[type=button]:not(.close), details[data-amplitude-input-name]';

export enum AmplitudeHtmlEvent {
  Blur = 'blur',
  Click = 'click',
  Change = 'change',
  Focus = 'focus',
  Keypress = 'keypress',
  MouseDown = 'mousedown',
  MouseEnter = 'mouseenter',
  MouseLeave = 'mouseleave',
  MouseUp = 'mouseup',
  Select = 'select',
}

export interface AmplitudeEventListener {
  element: HTMLElement | Document;
  htmlEventName: AmplitudeHtmlEvent;
  selector?: AmplitudeCssSelector;
}

export enum AmplitudePayloadType {
  Breadcrumb = 'sidebar_breadcrumb',
  Button = 'button',
  Checkbox = 'checkbox',
  Input = 'input',
  Link = 'link',
  None = 'err_no_type',
  Page = 'page_event',
  RadioButton = 'radio_button',
  Select = 'select_one',
  Slider = 'slider',
  System = 'system_event',
  Tooltip = 'tooltip',
  Typeahead = 'typeahead_selection',
  Modal = 'modal_event',
}

export const SYNTHETIC_EVENT_TYPES = [AmplitudePayloadType.System];

export interface AmplitudeTrackPayload {
  eventName: string;
  detail: string;
  useLegacyEventName?: boolean;
}

export type AmplitudePayloadOverride = { [key in AmplitudePayloadField]?: string };

export type AmplitudeTrackPayloadOverride = AmplitudeTrackPayload & {
  payloadOverride: AmplitudePayloadOverride;
};

export interface AmplitudeSubmitEventPayload {
  disabled?: boolean;
  input: string;
  quoteSection?: string;
  payloadOverride?: AmplitudePayloadOverride;
  type: AmplitudePayloadType;
  useLegacyEventName?: boolean;
  value: string | boolean | number | null | undefined;
}
