import { Component, OnInit, Input } from '@angular/core';
import { UNAUTHENTICATED_ROUTE } from 'app/features/support/models/support-help-center-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() showPhoneNumber: boolean;
  @Input() isWhiteBackground = false;
  currentYear: number;
  helpCenterPath = UNAUTHENTICATED_ROUTE;

  constructor() {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
