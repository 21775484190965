import { Component, OnChanges, OnDestroy, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { SentryService } from 'app/core/services/sentry.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { PolicyCountResponse, PolicyCancellation } from 'app/bop/guidewire/typings';
import { EXCESS_BUSINESS_TYPE } from 'app/features/activity/models/constants';
import { ActivityListBaseComponent } from 'app/features/activity/components/activity-list-base/activity-list-base.component';

@Component({
  selector: 'app-activity-list-pending-cancellation',
  templateUrl: 'activity-list-pending-cancellation.component.html',
  providers: [CurrencyPipe],
})
export class ActivityListPendingCancellationComponent
  extends ActivityListBaseComponent
  implements OnChanges, OnDestroy
{
  @Input() policyCount: PolicyCountResponse;
  @Input() policyPageLength = 5;

  constructor(
    protected sentryService: SentryService,
    private insuredAccountService: InsuredAccountService,
    protected currencyPipe: CurrencyPipe
  ) {
    super(sentryService, currencyPipe);
  }

  private sub: Subscription = new Subscription();

  // Number of fake rows to display while loading pending cancellations
  estimatedPendingCancellationLength = 0;

  pendingCancellationSelectedIndex = 0;
  pendingCancellationLoadedIndex = 0;

  pendingCancellations: PolicyCancellation[];
  pendingCancellationSubscriptions: Subscription[] = [];

  loadingPendingCancellations = true;
  hidePendingCancellations = false;

  ngOnChanges() {
    this.loadPendingCancellations(0);
  }

  getPendingCancellationCount() {
    return this.policyCount ? parseInt(this.policyCount.cancellingPolicyCount, 10) : null;
  }

  loadPendingCancellations(index: number) {
    const pendingCancellationCount = this.getPendingCancellationCount();

    if (!pendingCancellationCount) {
      return;
    }

    this.pendingCancellationSelectedIndex = index;
    this.clearSummarySubscriptions(this.pendingCancellationSubscriptions);

    this.loadingPendingCancellations = true;
    this.estimatedPendingCancellationLength = Math.min(
      this.policyPageLength,
      pendingCancellationCount - index
    );

    this.pendingCancellationSubscriptions.push(
      this.insuredAccountService
        .getPendingCancellationSummary(index, this.policyPageLength)
        .subscribe((pendingCancellations) => {
          this.loadingPendingCancellations = false;
          this.pendingCancellationLoadedIndex = index;
          this.pendingCancellations = pendingCancellations;
        })
    );
  }

  loadNextPendingCancellations() {
    this.loadPendingCancellations(this.pendingCancellationSelectedIndex + this.policyPageLength);
  }

  loadPreviousPendingCancellations() {
    this.loadPendingCancellations(this.pendingCancellationSelectedIndex - this.policyPageLength);
  }

  getPendingCancellationRouterLink(policy: PolicyCancellation) {
    const accountNumber = _.get(policy, 'Cancellation.AccountNumber');
    const policyNumber = _.get(policy, 'Cancellation.PolicyNumber');
    const policyType = _.get(policy, 'Cancellation.LineBusinessType');

    const cancellationReason = this.getCancellationReason(policy);

    if (policyType === EXCESS_BUSINESS_TYPE) {
      return [`/accounts/${accountNumber}`];
    } else if (cancellationReason === 'Non Payment') {
      return [`/billing/${accountNumber}`];
    } else {
      return [`/accounts/${accountNumber}/policy/${policyNumber}`];
    }
  }

  ngOnDestroy() {
    this.clearSummarySubscriptions(this.pendingCancellationSubscriptions);
    this.sub.unsubscribe();
  }
}
