import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { getUWAlertUrl } from 'app/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SentryService } from 'app/core/services/sentry.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { V3QuoteService } from 'app/shared/services/v3-quote-service';
import { BopPolicy } from 'app/features/attune-bop/models/bop-policy';
import { WcPolicy } from 'app/workers-comp/employers/models/wc-policy';
import { BackendCreateInsuredAccountRequestPayloadFactory } from 'app/shared/models/backend/backend-create-insured-account-payload-factory';

export interface RunUWAlertOptions {
  insuredAccount: InsuredAccount;
  newBopQuote?: BopPolicy;
  newWCQuote?: WcPolicy;
}

@Injectable()
export class UWAlertService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private v3QuoteService: V3QuoteService
  ) {}

  runUWAlert(
    accountNumber: string,
    { insuredAccount, newBopQuote, newWCQuote }: RunUWAlertOptions
  ) {
    try {
      const requestBody = {
        insuredAccount:
          BackendCreateInsuredAccountRequestPayloadFactory.buildV3Payload(insuredAccount),
        newBopQuote: undefined as BaseQuote | undefined,
        newWCQuote: undefined as WcPolicy | undefined,
      };
      if (newBopQuote) {
        requestBody.newBopQuote = this.v3QuoteService.translateToBopPayload(
          newBopQuote,
          insuredAccount
        );
      }
      if (newWCQuote) {
        requestBody.newWCQuote = newWCQuote;
      }

      return this.http.post(getUWAlertUrl(accountNumber), requestBody).pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to trigger uw alert.', {
            severity: 'error',
            metaData: {
              accountNumber,
              underlyingErrorMessage: error && error.message,
            },
          });
          throw error;
        })
      );
    } catch (e) {
      this.sentryService.notify('Unable to build request body for UW Alert', {
        severity: 'error',
        metaData: {
          accountNumber,
          underlyingErrorMessage: e && e.message,
        },
      });
      return throwError(e);
    }
  }

  fetchAlerts(accountNumber: string, quoteNumber?: string): Observable<UWAlert[]> {
    let params = new HttpParams();
    if (quoteNumber) {
      params = params.set('quoteNumber', quoteNumber);
    }
    const options = { params: params };
    return this.http.get<{ uwAlerts: UWAlert[] }>(getUWAlertUrl(accountNumber), options).pipe(
      map((alertResponse) => {
        return alertResponse.uwAlerts;
      }),
      catchError((error) => {
        this.sentryService.notify('Unable to fetch uw alerts.', {
          severity: 'error',
          metaData: {
            accountNumber,
            quoteNumber,
            underlyingErrorMessage: error && error.message,
          },
        });
        throw error;
      })
    );
  }
}
