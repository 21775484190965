<dl class="accordion">
  <div class="accordion-item" *ngFor="let faq of faqs; index as faqIndex">
    <dt class="faqs-question-wrapper">
      <button
        type="button"
        class="accordion-tab"
        [ngClass]="{ 'accordion-tab__open': isToggled(faqIndex) }"
        [attr.aria-expanded]="isToggled(faqIndex)"
        tabindex="0"
        (click)="toggleFaq(faqIndex)"
        (keydown.return)="toggleFaq(faqIndex)"
      >
        {{ faq.question }}
      </button>
    </dt>
    <dd class="accordion-contents">
      <p class="p p__no-margin" *ngIf="isToggled(faqIndex)" [innerHTML]="faq.answer"></p>
    </dd>
  </div>
</dl>
