import {
  ValidatorDictionary,
  ComplexValidatorDictionary,
  FormArrayValidators,
} from '../../../shared/form-dsl/constants/form-dsl-typings';

export enum LmCpspFormStepPath {
  POLICY_INFO = 'policyInfo',
  LOSS_HISTORY = 'lossHistory',
  LOCATION_DETAILS = 'locationDetails',
  ADDITIONAL_COVERAGES = 'additionalCoverages',
}

export enum LmCpspQuestion {
  _10_MONTHLY_RENTAL = '10-monthly-rental',
  _3_D_PRINTING = '3-d-printing',
  ADDITIONAL_INSURED_CLAUSE = 'additional-insured-clause',
  ADDRESS = 'address',
  ADULT_MATERIALS_SALES = 'adult-materials-sales',
  AIRPORT_CONSTRUCTION_DETAILS = 'airport-construction-details',
  ALARM_OR_SPRINKLER_INSTALLATION = 'alarm-or-sprinkler-installation',
  ANNUAL_SALES_RECEIPTS = 'annual-sales-receipts',
  APARTMENT_FORECLOSURE = 'apartment-foreclosure',
  APPLICANT_IS_ADDITIONAL_INSURED = 'applicant-is-additional-insured',
  APPLICANT_MFG_COMPENENTS_FOR_OTHERS = 'applicant-mfg-compenents-for-others',
  APPLICANT_MFG_COMPENENTS_FOR_OTHERS_DETAILS = 'applicant-mfg-compenents-for-others-details',
  APPLICANT_SUBSIDIARIES = 'applicant-subsidiaries',
  APPLICANT_SUBSIDIARIES_DETAILS = 'applicant-subsidiaries-details',
  APPLICANTS_MAIN_CUSTOMERS = 'applicants-main-customers',
  ARE_THERE_APARTMENT_OCCUPANCIES_THAT_IN_TOTAL_COMPRISE_LESS_THAN_25_OF_THE_BUILDING_FLOOR_AREA = 'are-there-apartment-occupancies-that-in-total-comprise-less-than-25-of-the-building-floor-area',
  AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_1 = 'area-occupied-10-000-square-feet-question-1',
  AREA_OCCUPIED_10_000_SQUARE_FEET_QUESTION_2 = 'area-occupied-10-000-square-feet-question-2',
  AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_1 = 'area-occupied-2-500-square-feet-question-1',
  AREA_OCCUPIED_2_500_SQUARE_FEET_QUESTION_2 = 'area-occupied-2-500-square-feet-question-2',
  AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_1 = 'area-occupied-5-000-square-feet-question-1',
  AREA_OCCUPIED_5_000_SQUARE_FEET_QUESTION_2 = 'area-occupied-5-000-square-feet-question-2',
  AREA_OCCUPIED_BY_INSURED_PROPERTY = 'area-occupied-by-insured-property',
  AREA_OCCUPIED_BY_TENANTS = 'area-occupied-by-tenants',
  AREA_UNOCCUPIED = 'area-unoccupied',
  AREA_UNOCCUPIED_DETAILS = 'area-unoccupied-details',
  ARMED_SECURITY_GUARDS = 'armed-security-guards',
  ARSON_CONVICTION = 'arson-conviction',
  ASBESTOS_FIBERS = 'asbestos-fibers',
  ATTACK_EXTORTION = 'attack-extortion',
  ATTACK_EXTORTION_LIMIT = 'attack-extortion-limit',
  ATTENDANT_ON_DUTY = 'attendant-on-duty',
  AUTO_BODY_1 = 'auto-body-1',
  AUTO_BODY_2 = 'auto-body-2',
  AUTO_BODY_DETAILS = 'auto-body-details',
  AUTO_LENDING = 'auto-lending',
  AUTO_RENTAL_OR_LEASING = 'auto-rental-or-leasing',
  AUTO_SALVAGE = 'auto-salvage',
  BACKGROUND_CHECKS = 'background-checks',
  BALCONIES = 'balconies',
  BANQUET_ADEQUATE_HARDWARE = 'banquet-adequate-hardware',
  BEAUTY_SHOP = 'beauty-shop',
  BICYCLE_RENTAL = 'bicycle-rental',
  BIZ_OPS_CLOSEDOR_SUSPENDED = 'biz-ops-closedor-suspended',
  BLANKET_ADDITIONAL_INSURED_CONTRACTORS_PRODUCTS_COMPLETED_OPERATIONS = 'blanket-additional-insured-contractors-products-completed-operations',
  BOILER_WORK = 'boiler-work',
  BOILER_WORK_DETAILS = 'boiler-work-details',
  BPP_INCLUDES_EQUIP_OVER_500 = 'bpp-includes-equip-over-500',
  BUILDING_LOCATIONS_DISTANCE = 'building-locations-distance',
  BUILDING_OCCUPANTS = 'building-occupants',
  BUILDING_SQUARE_FEET_EXCEED_100_K = 'building-square-feet-exceed-100-k',
  BUILDING_SQUARE_FEET_EXCEED_150_K = 'building-square-feet-exceed-150-k',
  BUILDING_SQUARE_FOOTAGE = 'building-square-footage',
  BURGLARY_ALARM = 'burglary-alarm',
  CASINO = 'casino',
  CERAMIC_FLOOR_PERCENT_REFER = 'ceramic-floor-percent-refer',
  CERTIFICATES_OF_INSURANCE = 'certificates-of-insurance',
  CERTIFICATES_PROVIDED = 'certificates-provided',
  CHILD_CARE_SERVICE = 'child-care-service',
  CHURCH_SCHOOL = 'church-school',
  CITATION_ALCOHOLIC_BEVERAGES = 'citation-alcoholic-beverages',
  CLASS_LIQUOR_LIABILITY = 'class-liquor-liability',
  CLEANING_KITCHEN_FLUES_HOODS_PERCENT_REFER = 'cleaning-kitchen-flues-hoods-percent-refer',
  CLOSED_BY_BOH = 'closed-by-boh',
  COINSURANCE_BI_AND_EE = 'coinsurance-bi-and-ee',
  COINSURANCE_BPP = 'coinsurance-bpp',
  COINSURANCE_BUILDING = 'coinsurance-building',
  COMBUSTIBLE_WASTE_CONTAINERS = 'combustible-waste-containers',
  COMMERCIAL_COOKING_QUESTION = 'commercial-cooking-question',
  COMPLEMENTARY_SERVICES = 'complementary-services',
  COMPUTER_PROGRAMMING = 'computer-programming',
  COMPUTER_PROGRAMMING_DETAILS = 'computer-programming-details',
  CONSTRUCTION_PROJECTS_GENERAL_AGGREGATE_LIMITS_PER_PROJECT = 'construction-projects-general-aggregate-limits-per-project',
  CONSTRUCTION_TYPE_PROPERTY = 'construction-type-property',
  CONSULTANT_OPERATIONS = 'consultant-operations',
  CONTACT_UTILITIES = 'contact-utilities',
  CONTRACT_LANGUAGE = 'contract-language',
  CONTRACTORS_LICENSES_LIMIT = 'contractors-licenses-limit',
  CONVERTED_HOME_DWELLING = 'converted-home-dwelling',
  CP_1045_SALE_OR_STORAGE = 'cp-1045-sale-or-storage',
  CP_EXTING_SYS_SERV_SEMI_ANNUAL = 'cp-exting-sys-serv-semi-annual',
  CPUL_300_EXTINGUISHING_SYSTEM = 'cpul-300-extinguishing-system',
  CPUL_300_EXTINGUISHING_SYSTEM_MGT_POLICY = 'cpul-300-extinguishing-system-mgt-policy',
  CRANE_OR_BUCKET_TRUCK = 'crane-or-bucket-truck',
  CRANE_OR_BUCKET_TRUCK_DETAILS = 'crane-or-bucket-truck-details',
  CUSTOMIZING_SERVICES = 'customizing-services',
  DAMS_OR_BRIDGES = 'dams-or-bridges',
  DATA_COMPROMISE_DEFENSE_AND_LIABILITY = 'data-compromise-defense-and-liability',
  DATA_COMPROMISE_RESPONSE_EXPENSES = 'data-compromise-response-expenses',
  DATA_COMPROMISE_RESPONSE_EXPENSES_LIMIT = 'data-compromise-response-expenses-limit',
  DATA_SECURITY_QUESTION_1 = 'data-security-question-1',
  DATA_SECURITY_QUESTION_10 = 'data-security-question-10',
  DATA_SECURITY_QUESTION_11 = 'data-security-question-11',
  DATA_SECURITY_QUESTION_12 = 'data-security-question-12',
  DATA_SECURITY_QUESTION_2 = 'data-security-question-2',
  DATA_SECURITY_QUESTION_3 = 'data-security-question-3',
  DATA_SECURITY_QUESTION_4 = 'data-security-question-4',
  DATA_SECURITY_QUESTION_5 = 'data-security-question-5',
  DATA_SECURITY_QUESTION_6 = 'data-security-question-6',
  DATA_SECURITY_QUESTION_7 = 'data-security-question-7',
  DATA_SECURITY_QUESTION_8 = 'data-security-question-8',
  DATA_SECURITY_QUESTION_9 = 'data-security-question-9',
  DAY_CARE = 'day-care',
  DEMOLITION_DETAILS = 'demolition-details',
  DEMOLITION_PERCENT = 'demolition-percent',
  DESCRIPTION_OF_OPERATIONS = 'description-of-operations',
  DESIGN_OR_ENGINEERING_SERVICES = 'design-or-engineering-services',
  DESIGN_OR_ENGINEERING_SERVICES_DETAILS = 'design-or-engineering-services-details',
  DESIGNS_OR_SPECS_FOR_OTHERS = 'designs-or-specs-for-others',
  DESIGNS_OR_SPECS_FOR_OTHERS_DETAILS = 'designs-or-specs-for-others-details',
  DISPOSAL_AUTO_RELATED = 'disposal-auto-related',
  DIVING_BOARD_HEIGHT = 'diving-board-height',
  DWELLING_OCCUPANCY = 'dwelling-occupancy',
  E_COMMERCE_DESIGN = 'e-commerce-design',
  EACH_CLAIM_AGGREGATE_LIMIT_EMPLOYMENT_PRACTICES = 'each-claim-aggregate-limit-employment-practices',
  EACH_OCCURRENCE_AGGREGATE_LIMIT = 'each-occurrence-aggregate-limit',
  EFFECTIVE_DATE = 'effective-date',
  EIFS = 'eifs',
  ELECTROPLATING_OPERATIONS = 'electroplating-operations',
  EMERGENCY_ESCAPE_ROUTES = 'emergency-escape-routes',
  EMP_CARRY_FIREARMS = 'emp-carry-firearms',
  EMPLOYEE_LEASING = 'employee-leasing',
  EMPLOYEE_PRACTICES_LIABILITY = 'employee-practices-liability',
  ENTER_RESIDENCES = 'enter-residences',
  ENTERTAINMENT_OR_DANCE_FLOOR = 'entertainment-or-dance-floor',
  ENTERTAINMENT_OR_DANCE_FLOOR_DETAILS = 'entertainment-or-dance-floor-details',
  ENVIRONMENTAL_HAZARD = 'environmental-hazard',
  ENVIRONMENTAL_HAZARD_DETAILS = 'environmental-hazard-details',
  EPLI_CLAIMS = 'epli-claims',
  EXCEED_10_MILLION = 'exceed-10-million',
  EXCEED_15_MILLION = 'exceed-15-million',
  EXCEED_15_MILLION_IP = 'exceed-15-million-ip',
  EXCEED_7_5_MILLION_IP = 'exceed-7-5-million-ip',
  EXECUTIVE_SEARCH_IQ = 'executive-search-iq',
  EXPERT_WITNESS_IQ = 'expert-witness-iq',
  EXPOSURE = 'exposure',
  EXPOSURE_LIQUOR_LIABILITY = 'exposure-liquor-liability',
  EXTERIOR_DOORS_1 = 'exterior-doors-1',
  EXTERNAL_INSURED_DETAILS = 'external-insured-details',
  EXTERNAL_INSURED_REVENUES = 'external-insured-revenues',
  FEDERAL_ID = 'federal-id',
  FILL_PROPANE_GAS_TANKS = 'fill-propane-gas-tanks',
  FINANCIAL_ACCOUNTING_IQ = 'financial-accounting-iq',
  FIRE_ALARM_TYPE = 'fire-alarm-type',
  FIRE_DAMAGE_REMEDIATION = 'fire-damage-remediation',
  FIRE_DAMAGE_REMEDIATION_DETAILS = 'fire-damage-remediation-details',
  FIREARMS_SALES = 'firearms-sales',
  FIREPLACES_OR_WOODSTOVES = 'fireplaces-or-woodstoves',
  FIREWALL_AND_SELF_CLOSING_FIRE_DOORS = 'firewall-and-self-closing-fire-doors',
  FLAMMABLE_FLUID_STORAGE = 'flammable-fluid-storage',
  FOOD_PREP_QUESTION = 'food-prep-question',
  FOOD_TRUCK = 'food-truck',
  FOOD_TRUCK_PERCENTAGE = 'food-truck-percentage',
  FORM_OF_BUSINESS = 'form-of-business',
  GAMBLING_OPERATIONS = 'gambling-operations',
  GASOLINE_SALES = 'gasoline-sales',
  GENERAL_CONTRACTING = 'general-contracting',
  GENERAL_CONTRACTING_DETAILS = 'general-contracting-details',
  GENERAL_MANAGER_IQ = 'general-manager-iq',
  GL_CLASSIFICATION_LEVEL_CLASS_CODE = 'gl-classification-level-class-code',
  GLASS_ON_EXTERIOR_CONSTRUCTION = 'glass-on-exterior-construction',
  GREENHOUSE_PRESENT = 'greenhouse-present',
  HABITATION_RISKS = 'habitation-risks',
  HABITATIONAL_EXPOSURES = 'habitational-exposures',
  HABITATIONAL_UNITS = 'habitational-units',
  HAS_FIREPLACE_OR_WOOD_STOVE_WORK = 'has-fireplace-or-wood-stove-work',
  HAVE_THERE_BEEN_LOSSES_FOR_THE_LINES_OF_BUSINESS_SUBMITTED = 'have-there-been-losses-for-the-lines-of-business-submitted',
  HAZARDOUS_MATERIALS_ABATEMENT = 'hazardous-materials-abatement',
  HAZARDOUS_PRODUCTS = 'hazardous-products',
  HEATING_YEAR = 'heating-year',
  HIGH_HAZ_OCCUPANCIES = 'high-haz-occupancies',
  HOLD_HARMLESS_CONTRACT = 'hold-harmless-contract',
  HOSPITAL_WORK_PERCENT = 'hospital-work-percent',
  HOT_WORK_OPERATIONS = 'hot-work-operations',
  HOT_WORK_PROGRAM = 'hot-work-program',
  HOTEL_NUMBER_OF_STORIES = 'hotel-number-of-stories',
  HOURS_OF_OPERATION = 'hours-of-operation',
  INLAND_MARINE = 'inland-marine',
  INS_ADDITIONAL_INS_ON_MFGR_INS_POLICY = 'ins-additional-ins-on-mfgr-ins-policy',
  INSURED_HAVE_BANQUET = 'insured-have-banquet',
  INSURED_PHONE_NUMBER = 'insured-phone-number',
  INSURED_SNOWPLOW_ON_HIGHWAY = 'insured-snowplow-on-highway',
  INSURED_STORE_PRODUCTS = 'insured-store-products',
  INSURED_WORK_OUTSIDE_STATE = 'insured-work-outside-state',
  INSURED_WORK_OUTSIDE_STATE_DETAIL = 'insured-work-outside-state-detail',
  INTEREST_PROPERTY = 'interest-property',
  INTERNAL_COMPLAINT_PROCEDURE = 'internal-complaint-procedure',
  JANITORIAL_DEBRIS_CLEAN_UP_PERCENT_REFER = 'janitorial-debris-clean-up-percent-refer',
  LARGE_SPECIAL_EVENT = 'large-special-event',
  LARGEST_NUMBER_OF_NEW_HOME_STARTS = 'largest-number-of-new-home-starts',
  LAWN_LANDSCAPE_UNDERGROUND = 'lawn-landscape-underground',
  LEASED_DETAILS = 'leased-details',
  LEASED_EQUIPMENT = 'leased-equipment',
  LEASED_OPERATOR_DETAILS = 'leased-operator-details',
  LIABILITY_SQUARE_FEET_EXCEED_100_K = 'liability-square-feet-exceed-100-k',
  LIABILITY_SQUARE_FEET_EXCEED_150_K = 'liability-square-feet-exceed-150-k',
  LICENSED_FOR_TRADE = 'licensed-for-trade',
  LIMIT_BI_AND_EE = 'limit-bi-and-ee',
  LIMIT_OF_INSURANCE_BUILDING = 'limit-of-insurance-building',
  LIMIT_OF_INSURANCE_BUSINESS_PERSONAL_PROPERTY = 'limit-of-insurance-business-personal-property',
  LIMITED_COOKING_QUESTION = 'limited-cooking-question',
  LIQUOR_LIABILITY_INCLUDE_IN_PACKAGE = 'liquor-liability-include-in-package',
  LIQUOR_PERCENT_OF_SALES = 'liquor-percent-of-sales',
  LIT_COMMON_AREAS = 'lit-common-areas',
  LITIGATION_INVOLVEMENT = 'litigation-involvement',
  LOCKS_CHANGED = 'locks-changed',
  LOSSES = 'losses',
  LP_GAS_WORK_PERCENT = 'lp-gas-work-percent',
  LP_GAS_WORK_PERCENT_DETAILS = 'lp-gas-work-percent-details',
  MA_TENANT_RELOCATION_EXPENSE_COVERAGE_NUMBER_OF_RENTED_LIVING_QUARTERS = 'ma-tenant-relocation-expense-coverage-number-of-rented-living-quarters',
  MANUFACTURING_ACTIVITY = 'manufacturing-activity',
  MANUFACTURING_ACTIVITY_DETAILS = 'manufacturing-activity-details',
  MARIJUANA_OPERATIONS = 'marijuana-operations',
  MARKETING_ADVERTISING_IQ = 'marketing-advertising-iq',
  MEDICAL_EQUIP_OVER_500_DESC = 'medical-equip-over-500-desc',
  MEDICAL_GASES_WORK = 'medical-gases-work',
  MEDICAL_MARIJUANA_SALES = 'medical-marijuana-sales',
  MEDICAL_OFFICE_IQ = 'medical-office-iq',
  MERCHANDISE_IMPORTED_DIRECTLY = 'merchandise-imported-directly',
  MINORS_LIQUOR_SALE = 'minors-liquor-sale',
  MIXING_OR_COMPOUNDING_DRUGS = 'mixing-or-compounding-drugs',
  MOLESTATION = 'molestation',
  MOLESTATION_DETAILS = 'molestation-details',
  MOTORCYCLE_REPAIR = 'motorcycle-repair',
  MOTORIZED_CONVEYANCE = 'motorized-conveyance',
  NAMED_INSD_OTHER_ACTIVITIES = 'named-insd-other-activities',
  NAMED_INSD_OTHER_ACTIVITIES_DETAILS = 'named-insd-other-activities-details',
  NETWORK_SECURITY = 'network-security',
  NETWORK_SECURITY_LIMIT = 'network-security-limit',
  NEW_PLANNED_OR_RESEARCHED_PRODUCTS = 'new-planned-or-researched-products',
  NEW_PLANNED_OR_RESEARCHED_PRODUCTS_DETAILS = 'new-planned-or-researched-products-details',
  NEW_RESIDENTAIL_CONSRUCTION = 'new-residentail-consruction',
  NEW_RESIDENTAIL_CONSRUCTION_DETAILS = 'new-residentail-consruction-details',
  NON_PAY_CANCELLATIONS_OR_NON_COMPLIANT_AUDITS = 'non-pay-cancellations-or-non-compliant-audits',
  NONGARAGE_OPERATIONS = 'nongarage-operations',
  NONGARAGE_OPERATIONS_DETAILS = 'nongarage-operations-details',
  NP_FUND_RAISING_IQ = 'np-fund-raising-iq',
  NUMBER_OF_GAMING_MACHINES = 'number-of-gaming-machines',
  NUMBER_OF_STORIES = 'number-of-stories',
  OCCUPANCY_QUESTION_MS = 'occupancy-question-ms',
  OCCUPANCY_QUESTION_WA = 'occupancy-question-wa',
  OCCUPANCY_RATE_1 = 'occupancy-rate-1',
  OCCUPANCY_TYPE_CATEGORY_PROPERTY = 'occupancy-type-category-property',
  OCCUPANCY_TYPE_PROPERTY = 'occupancy-type-property',
  OFF_PREMISES_PROPERTY_DAMAGE_INCLUDING_CARE_CUSTODY_OR_CONTROL = 'off-premises-property-damage-including-care-custody-or-control',
  OFFICE_ADMIN_IQ = 'office-admin-iq',
  OP_CHANGE = 'op-change',
  OPEN_AFTER_MIDNIGHT = 'open-after-midnight',
  OPT_AIRPORT_EMERGENCY_WARNING_EQUIPMENT = 'opt-airport-emergency-warning-equipment',
  OPTS_INCLUDE_BLASTING_OR_TUNNELING = 'opts-include-blasting-or-tunneling',
  OPTS_INCLUDE_BLASTING_OR_TUNNELING_DETAILS = 'opts-include-blasting-or-tunneling-details',
  ORGANIZATIONAL_DEVELOPMENT_IQ = 'organizational-development-iq',
  OSHA_APPROVAL = 'osha-approval',
  OTHER_BUSINESS_INSD_ELSEWHERE = 'other-business-insd-elsewhere',
  OTHER_OFFICE_TYPES = 'other-office-types',
  OTHER_OFFICE_TYPES_DETAILS = 'other-office-types-details',
  OTHER_PROD_SOLD_UNDER_APPLICANTS_LABEL = 'other-prod-sold-under-applicants-label',
  OTHER_THAN_MINOR_AUTO_REPAIR = 'other-than-minor-auto-repair',
  OWN_LEASE_DOCKS = 'own-lease-docks',
  PAINTING_OPERATIONS = 'painting-operations',
  PARTY_PLANNER_IQ = 'party-planner-iq',
  PERFORMING_REPAIRS = 'performing-repairs',
  PERFORMS_RADON_MEASUREMENTS = 'performs-radon-measurements',
  PERSONAL_SHOPPER_IQ = 'personal-shopper-iq',
  PEST_RODENT_CONTROL_PGM = 'pest-rodent-control-pgm',
  PESTICIDE_CERTIFICATE_OF_INSURANCE = 'pesticide-certificate-of-insurance',
  PESTICIDES_LICENSED_CONTRACTOR = 'pesticides-licensed-contractor',
  PESTICIDES_SUPERVISION = 'pesticides-supervision',
  PESTICIDES_USAGE = 'pesticides-usage',
  PET_RESCUE = 'pet-rescue',
  PHF_FERTILIZER = 'phf-fertilizer',
  PIPING_REPAIRS = 'piping-repairs',
  PIPING_REPAIRS_DETAILS = 'piping-repairs-details',
  PLUMBING_YEAR = 'plumbing-year',
  PNEUMATIC_PLUMBING_SYSTEM = 'pneumatic-plumbing-system',
  PRIMARY_RISK_STATE = 'primary-risk-state',
  PRIOR_COVERAGE = 'prior-coverage',
  PRIOR_COVERAGE_DETAILS = 'prior-coverage-details',
  PRIOR_COVERAGE_REASON = 'prior-coverage-reason',
  PRODUCT_DESCRIPTION = 'product-description',
  PRODUCT_DISCONTINUED_DETAILS = 'product-discontinued-details',
  PRODUCT_LAWSUIT = 'product-lawsuit',
  PRODUCTS_CUSTOMER_SPECIFICATIONS = 'products-customer-specifications',
  PRODUCTS_DISCONTINUED = 'products-discontinued',
  PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL = 'products-sold-under-applicants-label',
  PRODUCTS_SOLD_UNDER_APPLICANTS_LABEL_DETAILS = 'products-sold-under-applicants-label-details',
  PRODUCTS_STORED = 'products-stored',
  PROFESSIONAL_LIABILITY_COVERAGE = 'professional-liability-coverage',
  PROHIBITED_SMOKING = 'prohibited-smoking',
  PROPERTIES_LEASED_TO_OTHERS = 'properties-leased-to-others',
  PROPERTIES_UNDER_CONSTRUCTION = 'properties-under-construction',
  PROPERTIES_UNDER_CONSTRUCTION_DETAILS = 'properties-under-construction-details',
  PROPERTY_CLASS_CODE_INTERNAL_CLASSIFICATION_CODE = 'property-class-code-internal-classification-code',
  PROPERTY_DEDUCTIBLE = 'property-deductible',
  PROVIDING_TRAINING = 'providing-training',
  QUALITY_CONTROL_PROCEDURES = 'quality-control-procedures',
  RARE_ANIMAL_TREATMENT = 'rare-animal-treatment',
  RECORDS_MANAGEMENT_IQ = 'records-management-iq',
  REFURBISHED_EQUIPMENT = 'refurbished-equipment',
  REFURBISHED_EQUIPMENT_DETAILS = 'refurbished-equipment-details',
  RELIGIOUS_LIMITATIONS = 'religious-limitations',
  RENOVATION = 'renovation',
  RENOVATION_BY_EMP = 'renovation-by-emp',
  RENT_MEDICAL_EQUIPMENT = 'rent-medical-equipment',
  REPAIR_HEAVY_TRUCKS_TRACTORS_R_VS_MOBILE_HOMES = 'repair-heavy-trucks-tractors-r-vs-mobile-homes',
  REPAIR_INSTALL_PASSENGER_VEHICLES = 'repair-install-passenger-vehicles',
  REQUIRE_WRITTEN_CONTRACTS = 'require-written-contracts',
  REQUIRED_APPLICATORS_LICENSE = 'required-applicators-license',
  RESTAURANTS_OUTSIDE_CLEANING_SERVICE = 'restaurants-outside-cleaning-service',
  RESTORATION_WORK = 'restoration-work',
  RESTORATION_WORK_DETAILS = 'restoration-work-details',
  RISK_CURRENTLY_OR_PAST_10_YEARS = 'risk-currently-or-past-10-years',
  RISK_MANAGEMENT_IQ = 'risk-management-iq',
  ROAD_WORK = 'road-work',
  ROAD_WORK_DETAILS = 'road-work-details',
  ROOF_CONSTRUCTION_PROPERTY = 'roof-construction-property',
  ROOF_INSTALLATION = 'roof-installation',
  ROOFING_YEAR = 'roofing-year',
  ROOM_OFFERED_RATE_1 = 'room-offered-rate-1',
  ROOM_RATE_1 = 'room-rate-1',
  SAFETY_PRECAUTIONS = 'safety-precautions',
  SALES_OR_STORAGE_QUESTION = 'sales-or-storage-question',
  SALES_SERVICE_OPERATIONS = 'sales-service-operations',
  SAND_BLASTING = 'sand-blasting',
  SECURITY_PERSONNEL = 'security-personnel',
  SECURITY_SERVICE = 'security-service',
  SEMINAR_EDUCATOR_IQ = 'seminar-educator-iq',
  SERVE_ALCOHOL = 'serve-alcohol',
  SERVE_ALCOHOL_TOTAL_SALES = 'serve-alcohol-total-sales',
  SERVICE_STATION_CAPTURE_CONTROLS = 'service-station-capture-controls',
  SERVICE_STATIONS_MISC_OPTS = 'service-stations-misc-opts',
  SILK_SCREENING = 'silk-screening',
  SMOKE_ALARMS = 'smoke-alarms',
  SMOKING_IN_REPAIR_AREAS = 'smoking-in-repair-areas',
  SNOW_PLOW_OPERATIONS = 'snow-plow-operations',
  SPA_SALON_SERVICES = 'spa-salon-services',
  SPRAY_MAN_IND_OPS_DETAILS = 'spray-man-ind-ops-details',
  SPRAY_PAINT_PROCESSING_MFG_OCCUPANCIES_QUESTION = 'spray-paint-processing-mfg-occupancies-question',
  SPRAY_PAINTING_OR_DIP_TANK_DETAILS = 'spray-painting-or-dip-tank-details',
  SPRAY_PAINTING_QUESTION = 'spray-painting-question',
  SPRINKLER = 'sprinkler',
  SUB_CONTRACTORS_PERCENT = 'sub-contractors-percent',
  SUBCONTRACTOR_LIMITS = 'subcontractor-limits',
  SURVEYORS_PROFESSIONAL_LIABILITY = 'surveyors-professional-liability',
  SWIMMING_POOL = 'swimming-pool',
  SWIMMING_POOL_DIVING_BOARD = 'swimming-pool-diving-board',
  SWIMMING_POOL_LOCKED_AND_FENCED = 'swimming-pool-locked-and-fenced',
  TABLESIDE_COOKING = 'tableside-cooking',
  TENANT_CREDIT_QUESTION = 'tenant-credit-question',
  TENANT_SQUARE_FEET_EXCEED_100_K = 'tenant-square-feet-exceed-100-k',
  TENANT_SQUARE_FEET_EXCEED_150_K = 'tenant-square-feet-exceed-150-k',
  TIRE_RECAPPING = 'tire-recapping',
  TIRE_SALES = 'tire-sales',
  TIRE_STOCK = 'tire-stock',
  TOTAL_LIMIT_CUSTOM_PROTECTOR_PROPERTY_FLOATER = 'total-limit-custom-protector-property-floater',
  TOTAL_NUMBER_OF_EMPLOYEES_AT_ALL_LOCATIONS = 'total-number-of-employees-at-all-locations',
  TOTAL_SERVICE_OCCUPIED_QUESTION_MS = 'total-service-occupied-question-ms',
  TOTAL_SERVICE_OCCUPIED_QUESTION_WA = 'total-service-occupied-question-wa',
  TOWING = 'towing',
  TRAFFIC_SIGNAL_WORK = 'traffic-signal-work',
  TRAINED_EMPLOYEE_SERVING_ALCOHOL = 'trained-employee-serving-alcohol',
  TRAINING_LEADERSHIP_IQ = 'training-leadership-iq',
  TRAINING_SOFTWARE_IQ = 'training-software-iq',
  TRENCHING_EXCAVATING = 'trenching-excavating',
  TYPE_OF_LICENSES = 'type-of-licenses',
  UL_APPROVED_PAINT_BOOTH = 'ul-approved-paint-booth',
  UL_APPROVED_PAINT_CABINET = 'ul-approved-paint-cabinet',
  UNDERGROUND_OR_HEIGHTS = 'underground-or-heights',
  UNDERGROUND_OR_HEIGHTS_DETAILS = 'underground-or-heights-details',
  USED_MATERIAL_MERCHANDISE = 'used-material-merchandise',
  VEHICLES_SOLD = 'vehicles-sold',
  VOLUNTARY_PROPERTY_DAMAGE_EXTENSION = 'voluntary-property-damage-extension',
  WATER_CONNECTIONS = 'water-connections',
  WATER_HAZARDS = 'water-hazards',
  WATER_OR_SEWER_MAIN_INSTALLATION = 'water-or-sewer-main-installation',
  WATERPROOFING_SURFACES = 'waterproofing-surfaces',
  WEB_HOSTING = 'web-hosting',
  WEDDING_IQ = 'wedding-iq',
  WIRING_YEAR = 'wiring-year',
  WRITTEN_ANTI_SEXUAL_HARASSMENT = 'written-anti-sexual-harassment',
  WRITTEN_CONTRACT = 'written-contract',
  WRITTEN_CONTRACT_DETAILS = 'written-contract-details',
  YEAR_BUILT_PROPERTY = 'year-built-property',
  YEAR_BUSINESS_STARTED = 'year-business-started',
  YEAR_ROOF_REPLACED_PROPERTY = 'year-roof-replaced-property',
  YEARS_MANAGEMENT_EXPERIENCE = 'years-management-experience',
  YEARS_MANAGEMENT_EXPERIENCE_DETAILS = 'years-management-experience-details',
}

// Properties present on submission payload but not in form. If any additional
// IDs are added here or any are renamed, then the equivalent change also needs
// to be made in `service-quote`.
export enum LmCpspSubmissionField {
  ADDR_STREET_1 = 'street-address-1',
  ADDR_STREET_2 = 'street-address-2',
  ADDR_CITY = 'city',
  ADDR_STATE = 'state',
  ADDR_ZIP = 'zipcode',
  BUSINESS_CLASS_DESCRIPTION = 'business-class-description',
  OCCUPANCY_RATE_2 = 'occupancy-rate-2',
  EXTERIOR_DOORS_2 = 'exterior-doors-2',
  RETROACTIVE_DATE_EMPLOYMENT_PRACTICES = 'retroactive-date-employment-practices',
  ROOM_RATE_2 = 'room-rate-2',
  ROOM_OFFERED_RATE_2 = 'room-offered-rate-2',
  SELECT_BUILDING_CHECKBOX = 'select-building-checkbox',
  SELECT_BUSINESS_INCOME_WITH_EXTRA_EXPENSE = 'select-business-income-with-extra-expense',
  SELECT_BUSINESS_PERSONAL_PROPERTY = 'select-business-personal-property',
  PROPERTY_MOST_HAZARDOUS_CLASS_CODE_MOST_HAZARDOUS_INTERNAL_CLASSIFICATION_CODE = 'property-most-hazardous-class-code-most-hazardous-internal-classification-code',
}

export type LmCpspFormData = {
  [key in LmCpspQuestion | LmCpspSubmissionField]?: string | number | true | LmCpspLossGroupValue[];
};

export enum LmCpspLossHistoryQuestion {
  ALLOCATED_ADJUSTER_EXPENSE = 'allocated-adjuster-expense',
  AMOUNT_PAID = 'amount-paid',
  AMOUNT_RESERVED = 'amount-reserved',
  CLAIM_STATUS = 'claim-status',
  LINE_OF_BUSINESS = 'line-of-business',
  DATE_OF_LOSS = 'date-of-loss',
  LOSS_DESCRIPTION = 'loss-description',
  LOSS_TYPE_GENERAL_LIABILITY = 'loss-type-general-liability',
  LOSS_TYPE_PROPERTY = 'loss-type-property',
}

export type LmCpspFormValue = string | number | boolean | null;

export type LmCpspLossGroupValue = { [key in LmCpspLossHistoryQuestion]: LmCpspFormValue };

// FormArray controls are handled differently for dependencies and
// validators. If additional FormArrays are added, their enum should
// be included in this type.
export type LmCpspNestedControls = LmCpspLossHistoryQuestion;

export enum LmCpspComplexEvaluator {
  HAS_SECURITY_PERSONNEL_OR_HAS_SECURITY_SERVICE = 'HAS_SECURITY_PERSONNEL_OR_HAS_SECURITY_SERVICE',
  ARMED_SECURITY_GUARDS_IS_NO_OR_RENOVATION_BY_EMP_IS_NO = 'ARMED_SECURITY_GUARDS_IS_NO_OR_RENOVATION_BY_EMP_IS_NO',
  BACKGROUND_CHECKS_DEPENDENCY = 'BACKGROUND_CHECKS_DEPENDENCY',
  CITATION_ALCOHOLIC_BEVERAGES_DEPENDENCY = 'CITATION_ALCOHOLIC_BEVERAGES_DEPENDENCY',
  CP_EXTING_SYS_SERV_SEMI_ANNUAL_DEPENDENCY = 'CP_EXTING_SYS_SERV_SEMI_ANNUAL_DEPENDENCY',
  CSP_CLASS_IS_0542 = 'CSP_CLASS_IS_0542',
  CSP_CLASS_IS_0570 = 'CSP_CLASS_IS_0570',
  CSP_CLASS_IS_BUILDING_ONLY = 'CSP_CLASS_IS_BUILDING_ONLY',
  DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K = 'DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_250K',
  DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K = 'DC_OR_ATTACK_EXTORTION_OR_NETWORK_SECURITY_LIMIT_OVER_500K',
  EXCAVATION_OR_TRENCHING_EXCAVATING = 'EXCAVATION_OR_TRENCHING_EXCAVATING',
  GREATER_THAN_25_PERCENT_REVENUE_GENERATED_OFF_PREMISES = 'GREATER_THAN_25_PERCENT_REVENUE_GENERATED_OFF_PREMISES',
  HAS_APT_WITH_MERC_CSP_CLASS_AND_FRAME_NC_CONSTRUCTION = 'HAS_APT_WITH_MERC_CSP_CLASS_AND_FRAME_NC_CONSTRUCTION',
  HAS_APT_WITH_MERC_CSP_CLASS_AND_JM_CONSTRUCTION = 'HAS_APT_WITH_MERC_CSP_CLASS_AND_JM_CONSTRUCTION',
  HAS_APT_WITH_MERC_CSP_CLASS_AND_MNC_MFR_FR_CONSTRUCTION = 'HAS_APT_WITH_MERC_CSP_CLASS_AND_MNC_MFR_FR_CONSTRUCTION',
  HAS_APT_WITH_MERC_CSP_CLASS_MS = 'HAS_APT_WITH_MERC_CSP_CLASS_MS',
  HAS_APT_WITH_MERC_CSP_CLASS_WA = 'HAS_APT_WITH_MERC_CSP_CLASS_WA',
  HAS_BUILDING_CVG_AND_CONSTRUCTION_IS_FR_JM_NC_AND_INCIDENTAL_APT_EXISTS = 'HAS_BUILDING_CVG_AND_CONSTRUCTION_IS_FR_JM_NC_AND_INCIDENTAL_APT_EXISTS',
  HAS_COMMERCIAL_COOKING_AND_INTEREST_IS_NOT_TENANT = 'HAS_COMMERCIAL_COOKING_AND_INTEREST_IS_NOT_TENANT',
  HAS_LIMITED_COOKING = 'HAS_LIMITED_COOKING',
  HAS_LOSS_TYPE_GENERAL_LIABILITY = 'HAS_LOSS_TYPE_GENERAL_LIABILITY',
  HAS_LOSS_TYPE_PROPERTY = 'HAS_LOSS_TYPE_PROPERTY',
  HAS_RESIDENTIAL_CSP_CLASS_MA = 'HAS_RESIDENTIAL_CSP_CLASS_MA',
  HAS_RESIDENTIAL_CSP_CLASS_MS = 'HAS_RESIDENTIAL_CSP_CLASS_MS',
  HAS_RESIDENTIAL_CSP_CLASS_WA = 'HAS_RESIDENTIAL_CSP_CLASS_WA',
  IN_AL_OR_IS_HOTEL_OR_CMML_CONDO = 'IN_AL_OR_IS_HOTEL_OR_CMML_CONDO',
  IS_COMPUTER_CONSULTANT_DOING_E_COMMERCE_OR_IS_MEDICAL_OFFICE = 'IS_COMPUTER_CONSULTANT_DOING_E_COMMERCE_OR_IS_MEDICAL_OFFICE',
  LIMITED_COOKING_CSP_CLASS = 'LIMITED_COOKING_CSP_CLASS',
  LIQUOR_LIABILITY_IS_NOT_SELECTED = 'LIQUOR_LIABILITY_IS_NOT_SELECTED',
  OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K = 'OVER_24_AND_YRS_OLD_BL_OVER_0_OR_BPP_OVER_500K',
  PRIOR_COVERAGE_REASON_NEW_ACQUISITION_OR_NONE_OF_THE_ABOVE = 'PRIOR_COVERAGE_REASON_NEW_ACQUISITION_OR_NONE_OF_THE_ABOVE',
  SERVES_ALCOHOL_OR_HAS_LIQUOR_RELATED_CLASS_CODE = 'SERVES_ALCOHOL_OR_HAS_LIQUOR_RELATED_CLASS_CODE',
  UNOCC_AREA_GREATER_THAN_50_PERCENT_AND_EITHER_BUILDING_OR_BPP_SELECTED = 'UNOCC_AREA_GREATER_THAN_50_PERCENT_AND_EITHER_BUILDING_OR_BPP_SELECTED',
}

export enum LmCpspComplexValueEvaluator {
  EXPOSURE_LIQUOR_LIABILITY_LABEL_TEXT = 'EXPOSURE_LIQUOR_LIABILITY_LABEL_TEXT',
  EXPOSURE_PRIMITIVE_AND_LABEL_TEXT = 'EXPOSURE_PRIMITIVE_AND_LABEL_TEXT',
  PROPERTY_CLASS_CODE_OPTIONS = 'PROPERTY_CLASS_CODE_OPTIONS',
  COINSURANCE_BUILDING_OPTIONS = 'COINSURANCE_BUILDING_OPTIONS',
  OCCUPANCY_TYPE_OPTIONS = 'OCCUPANCY_TYPE_OPTIONS',
}

export enum ExposureType {
  ACRES = 'ACRES',
  DOLLARS_OF_GROSS_SALES = 'DOLLARS_OF_GROSS_SALES',
  DOLLARS_OF_PAYROLL = 'DOLLARS_OF_PAYROLL',
  GALLONS = 'GALLONS',
  KENNELS = 'KENNELS',
  MEMBERS = 'MEMBERS',
  SHOWS = 'SHOWS',
  SQUARE_FEET_OF_AREA = 'SQUARE_FEET_OF_AREA',
  UNITS = 'UNITS',
}

export interface CpsClassConfig {
  name: string;
  cspCode: string;
  internalCode: string;
}

export type LmCpspValidators = ValidatorDictionary<LmCpspQuestion | LmCpspFormStepPath>;
export type LmCpspComplexValidators = ComplexValidatorDictionary<
  LmCpspQuestion | LmCpspFormStepPath
>;
export type LmCpspArrayValidators = FormArrayValidators<LmCpspQuestion, LmCpspNestedControls>;
