import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ROLLOVER_FAQS } from '../../models/constants';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { AttuneBopQuoteService } from '../../../attune-bop/services/attune-bop-quote.service';
import * as moment from 'moment';
import { shouldGetBopV2, shouldGetMeToo } from '../../../../shared/helpers/account-helpers';

import { ChartData } from '../../components/activity-rollover-bar-chart/activity-rollover-bar-chart.component';
import { SentryService } from 'app/core/services/sentry.service';
import {
  INFORMATIONAL_NAMES,
  NewInformationalService,
} from 'app/shared/services/new-informational.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';

interface ChartKeyItem {
  label: string;
  color: string;
}

const PENDING_STATE_COLOR = '#00d2d0'; // robinsegg40, in CSS
const COMPLETE_STATE_COLOR = '#6d57ff'; // purpleheart40, in CSS
const NO_POLICIES_STATE_COLOR = '#d9dfe1'; // gray30, in CSS

// Number of policies to fetch in one call
const POLICY_API_PAGE_SIZE = 500;

// Number of days in advance that rollovers are expected to be generated
const UPCOMING_POLICY_DATE_RANGE = 95;

@Component({
  selector: 'app-activity-rollover-page.policies-rollover-page',
  templateUrl: 'activity-rollover-page.component.html',
})
export class ActivityRolloverPageComponent implements OnInit, OnDestroy {
  constructor(
    private amplitudeService: AmplitudeService,
    private bopQuoteService: AttuneBopQuoteService,
    private sentryService: SentryService,
    private newInformationalService: NewInformationalService
  ) {}
  @ViewChild('statesChart')
  statesChart: ElementRef<HTMLCanvasElement>;

  TOTAL_BAR_COLOR = '#00d2d0'; // robinsegg40, in CSS
  ROLLOVER_BAR_COLOR = '#6d57ff'; // purpleheart40, in CSS
  POLICY_TABLE_PAGE_SIZE = 5;

  hasRolloverListError = false;
  hasChartError = false;

  policyTableIndex = 0;
  scheduledPolicyTableIndex = 0;

  loadingRolloverList = true;

  loadedPolicies: UpcomingPolicy[] = [];
  scheduledRolloverPolicies: UpcomingPolicy[] = [];
  upcomingRolloverPolicies: UpcomingPolicy[] = [];

  stateRolloverInfo: RolloverStateInfo;
  businessRolloverInfo: RolloverCategoryInfo;

  showAutobindModal = false;
  hasAutobindDisableError = false;
  processingAutobindDisable = false;
  modalPolicy: UpcomingPolicy;

  private sub = new Subscription();

  public BAR_CHART_KEY: ChartKeyItem[] = [
    { label: 'Total', color: this.TOTAL_BAR_COLOR },
    { label: 'Transitioned', color: this.ROLLOVER_BAR_COLOR },
  ];

  public STATE_CHART_KEY: ChartKeyItem[] = [
    { label: 'No Policies', color: NO_POLICIES_STATE_COLOR },
    { label: 'Not Live', color: PENDING_STATE_COLOR },
    { label: 'Live', color: COMPLETE_STATE_COLOR },
  ];

  FILTER_OPTIONS: Record<UpcomingPoliciesFilter, string> = {
    '': 'All',
    Restaurant: 'Restaurants',
    Contractor: 'Contractors',
    Office: 'Offices',
    Mercantile: 'Retail',
    'Processing and Service': 'Prof. Services',
  };

  FILTER_SINGULAR_LABEL: Record<UpcomingPoliciesFilter, string> = {
    '': 'rollover',
    Restaurant: 'restaurant',
    Contractor: 'contractor',
    Office: 'offices',
    Mercantile: 'retail',
    'Processing and Service': 'professional service',
  };

  selectedFilter: UpcomingPoliciesFilter = '';

  rolloverFAQs: (Faq & { isToggled?: boolean })[] = ROLLOVER_FAQS;

  policiesRolledOver = 0;
  policiesTotal = 0;
  policyRollOverPercent = 0;
  statesLive = 0;
  statesTotal = 0;
  barChartData: ChartData = {
    items: [],
  };
  barChartAlternativeText = '';
  stateTotalCounts: Record<string, number> = {};
  stateRolloverCounts: Record<string, number> = {};
  stateStatusData: Record<string, string> = {};
  stateChartAlternativeText = '';

  loadingChartInformation() {
    return !this.hasChartError && (!this.businessRolloverInfo || !this.stateRolloverInfo);
  }

  getFilterOptions() {
    return Object.keys(this.FILTER_OPTIONS);
  }

  ngOnInit() {
    this.loadMoreUpcomingPolicies();
    this.loadStateRolloverInfo();
    this.loadBusinessTypeRolloverInfo();
  }

  reloadChartInfo() {
    this.hasChartError = false;
    this.loadStateRolloverInfo();
    this.loadBusinessTypeRolloverInfo();
  }

  setFilter(newFilter: UpcomingPoliciesFilter) {
    this.selectedFilter = newFilter;
    this.policyTableIndex = 0;
  }

  loadStateRolloverInfo() {
    this.sub.add(
      this.bopQuoteService.getStateRolloverInfo().subscribe(
        (rolloverInfo: RolloverStateInfo) => {
          this.stateRolloverInfo = rolloverInfo;
          this.calculateStateData();
        },
        (e) => this.showChartError(e)
      )
    );
  }

  loadBusinessTypeRolloverInfo() {
    this.sub.add(
      this.bopQuoteService.getBusinessRolloverInfo().subscribe(
        (rolloverInfo: RolloverCategoryInfo) => {
          this.businessRolloverInfo = rolloverInfo;
          this.calculateBusinessData();
        },
        (e) => this.showChartError(e)
      )
    );
  }

  showAutobindExplanation() {
    return (
      this.newInformationalService.getValue(INFORMATIONAL_NAMES.AUTOBIND_ROLLOVER_EXPLANATION) === 0
    );
  }

  dismissAutobindExplanation() {
    this.newInformationalService.incrementValue(INFORMATIONAL_NAMES.AUTOBIND_ROLLOVER_EXPLANATION);
  }

  calculateStateData() {
    const rawStateData: RolloverStateItem[] = this.stateRolloverInfo.types;
    this.policiesRolledOver = rawStateData.reduce(
      (acc: number, stateInfo) => acc + stateInfo.RolledOverPoliciesCount,
      0
    );
    this.policiesTotal = rawStateData.reduce(
      (acc: number, stateInfo) => acc + stateInfo.TotalPoliciesCount,
      0
    );

    this.policyRollOverPercent = +((this.policiesRolledOver / this.policiesTotal) * 100).toFixed(1);

    this.stateTotalCounts = rawStateData.reduce((acc: Record<string, number>, policy) => {
      acc[policy.State] = policy.TotalPoliciesCount;
      return acc;
    }, {});
    this.stateRolloverCounts = rawStateData.reduce((acc: Record<string, number>, policy) => {
      acc[policy.State] = policy.RolledOverPoliciesCount;
      return acc;
    }, {});
    const activeStatesMapping = _.pickBy(
      this.stateTotalCounts,
      (stateInfo, stateKey) => shouldGetBopV2(stateKey) || shouldGetMeToo(stateKey)
    );
    this.stateStatusData = rawStateData.reduce((acc: Record<string, string>, policy) => {
      acc[policy.State] = activeStatesMapping[policy.State]
        ? COMPLETE_STATE_COLOR
        : PENDING_STATE_COLOR;
      return acc;
    }, {});

    this.statesLive = Object.keys(activeStatesMapping).length;
    this.statesTotal = Object.keys(this.stateTotalCounts).length;

    this.stateChartAlternativeText = 'Status of rollover by state.\n';
    Object.keys(this.stateTotalCounts).forEach((state) => {
      const statusText = activeStatesMapping[state] ? 'Complete' : 'Pending';
      this.stateChartAlternativeText += `${state}: ${statusText}.\n`;
    });
  }

  calculateBusinessData() {
    const rawBusinessInfo = this.businessRolloverInfo.types;
    let barChartItems = rawBusinessInfo.map((businessInfo) => {
      return {
        label: businessInfo.PropertyType,
        values: [businessInfo.TotalPoliciesCount, businessInfo.RolledOverPoliciesCount],
      };
    });
    // Lessor's Risk will always be zero, since that category has been removed on the API side.
    // If for some reason the API returns it, filter it out.
    barChartItems = barChartItems.filter((item) => item.label !== "Lessor's Risk");
    // Processing And Services should be displayed as Prof. Services
    barChartItems = barChartItems.filter((item) => {
      if (item.label === 'Processing And Service') {
        item.label = 'Prof. Services';
      }
      return item;
    });
    this.barChartData = { items: barChartItems };
    this.barChartAlternativeText = 'Policies by type.\n';
    rawBusinessInfo.forEach((businessInfo) => {
      this.barChartAlternativeText += `${businessInfo.PropertyType}: ${businessInfo.RolledOverPoliciesCount} transitioned, ${businessInfo.TotalPoliciesCount} total.\n`;
    });
  }

  showPreviousScheduledPolicies() {
    if (this.scheduledPolicyTableIndex - this.POLICY_TABLE_PAGE_SIZE >= 0) {
      this.scheduledPolicyTableIndex -= this.POLICY_TABLE_PAGE_SIZE;
    }
  }

  showNextScheduledPolicies() {
    if (
      this.scheduledPolicyTableIndex + this.POLICY_TABLE_PAGE_SIZE <=
      this.scheduledRolloverPolicies.length
    ) {
      this.scheduledPolicyTableIndex += this.POLICY_TABLE_PAGE_SIZE;
    }
  }

  showPreviousRollovers() {
    if (this.policyTableIndex - this.POLICY_TABLE_PAGE_SIZE >= 0) {
      this.policyTableIndex -= this.POLICY_TABLE_PAGE_SIZE;
    }
  }

  showNextRollovers() {
    if (this.policyTableIndex + this.POLICY_TABLE_PAGE_SIZE <= this.totalPolicyCount()) {
      this.policyTableIndex += this.POLICY_TABLE_PAGE_SIZE;
    }
  }

  buildArray(length: number): number[] {
    // Utility method to create an array, for use with ngFor in template
    return [...Array(length).keys()];
  }

  filteredPolicies() {
    if (this.selectedFilter === 'Restaurant') {
      return this.upcomingRolloverPolicies.filter((policy) =>
        policy.ClassificationPropertyType.startsWith('Restaurant')
      );
    } else if (this.selectedFilter) {
      return this.upcomingRolloverPolicies.filter(
        (policy) => policy.ClassificationPropertyType === this.selectedFilter
      );
    } else {
      return this.upcomingRolloverPolicies;
    }
  }

  filteredPoliciesPage() {
    return this.filteredPolicies().slice(
      this.policyTableIndex,
      this.policyTableIndex + this.POLICY_TABLE_PAGE_SIZE
    );
  }

  scheduledPoliciesPage() {
    return this.scheduledRolloverPolicies.slice(
      this.scheduledPolicyTableIndex,
      this.scheduledPolicyTableIndex + this.POLICY_TABLE_PAGE_SIZE
    );
  }

  formatDate(timestamp: string) {
    return moment.utc(timestamp).format('MM/DD/YYYY');
  }

  totalPolicyCount() {
    return this.filteredPolicies().length;
  }

  normalizePolicies(policies: UpcomingPolicy[]) {
    const filteredPolicies = policies.filter((policy) => policy.Status !== 'Draft');
    const policiesByAccount: Record<string, UpcomingPolicy> = {};
    filteredPolicies.forEach((policy) => {
      const currentPolicy = policiesByAccount[policy.AccountNumber];
      if (!currentPolicy || policy.Status === 'Bound') {
        policiesByAccount[policy.AccountNumber] = policy;
      } else if (
        moment(policy.LastUpdateTime).isAfter(currentPolicy.LastUpdateTime) &&
        currentPolicy.Status !== 'Bound'
      ) {
        policiesByAccount[policy.AccountNumber] = policy;
      }
    });
    const dedupedPolicies = Object.values(policiesByAccount);
    dedupedPolicies.sort((a, b) => moment(a.PeriodStart).diff(moment(b.PeriodStart)));

    return dedupedPolicies;
  }

  onUpcomingPolicyFetch(policies: UpcomingPolicy[]) {
    this.loadedPolicies = this.loadedPolicies.concat(policies);

    if (policies.length >= POLICY_API_PAGE_SIZE) {
      this.loadMoreUpcomingPolicies();
    } else {
      const normalizedPolicies = this.normalizePolicies(this.loadedPolicies);
      [this.scheduledRolloverPolicies, this.upcomingRolloverPolicies] = _.partition(
        normalizedPolicies,
        (policy) => {
          return policy.Status === 'Bound';
        }
      );
      this.loadingRolloverList = false;
    }
  }

  loadMoreUpcomingPolicies() {
    this.sub.add(
      this.bopQuoteService
        .getUpcomingPolicies(
          this.loadedPolicies.length,
          POLICY_API_PAGE_SIZE,
          UPCOMING_POLICY_DATE_RANGE,
          true,
          ''
        )
        .subscribe(
          (policyResponse: UpcomingPoliciesResponse) =>
            this.onUpcomingPolicyFetch(policyResponse.policies),
          (err) => this.showRolloverError(err)
        )
    );
  }

  getTooltipText(policy: UpcomingPolicy) {
    if (policy.VerifyEmployeeCount || policy.VerifyFeetToHydrant) {
      return 'This policy has issues that must be resolved before it is eligible for autobind.';
    } else if (policy.AutoBind) {
      const autobindDay = moment.utc(policy.PeriodStart).subtract(32, 'day').format('M-D-YYYY');
      if (moment.utc().isAfter(autobindDay)) {
        // In this case, we can't know exactly when the bind will happen--whenever the next batch job runs.
        return 'This policy will bind automatically.';
      }
      return `This policy will bind automatically on ${autobindDay}`;
    } else {
      return 'You will have to bind this policy manually';
    }
  }

  updateAutobind(policy: UpcomingPolicy, newState: boolean) {
    if (policy.VerifyEmployeeCount || policy.VerifyFeetToHydrant || policy.Status === 'Draft') {
      return;
    }

    if (policy.AutoBind) {
      this.processingAutobindDisable = false;
      this.hasAutobindDisableError = false;
      this.modalPolicy = policy;
      this.showAutobindModal = true;
    } else {
      this.sub.add(
        this.bopQuoteService.updateAutobindStatus(policy.JobNumber, true).subscribe(
          () => {
            this.amplitudeService.trackWithOverride({
              eventName: 'enable_autobind',
              detail: 'rollover_summary',
              payloadOverride: {
                accountId: policy.AccountNumber,
                bopTransactionId: policy.JobNumber,
              },
            });
            policy.AutoBind = true;
          },
          (err) => {
            this.sentryService.notify('Was not able to enable autobind.', {
              severity: 'error',
              metaData: {
                accountNumber: policy.AccountNumber,
                accountName: policy.AccountName,
              },
            });
          }
        )
      );
    }
  }

  disableAutobind() {
    const policy = this.modalPolicy;
    this.processingAutobindDisable = true;
    this.sub.add(
      this.bopQuoteService.updateAutobindStatus(policy.JobNumber, false).subscribe(
        () => {
          this.amplitudeService.trackWithOverride({
            eventName: 'disable_autobind',
            detail: 'rollover_summary',
            payloadOverride: {
              accountId: policy.AccountNumber,
              bopTransactionId: policy.JobNumber,
            },
          });
          this.showAutobindModal = false;
          policy.AutoBind = false;
        },
        (err) => {
          this.hasAutobindDisableError = true;
          this.processingAutobindDisable = false;
        }
      )
    );
  }

  closeAutobindModal() {
    this.showAutobindModal = false;
  }

  getQuoteLink(policyInfo: UpcomingPolicy) {
    if (['Bound', 'Scheduled'].includes(policyInfo.Status)) {
      // For bound or scheduled policies, link the the policy view, not the quote view
      return `../../accounts/${policyInfo.AccountNumber}/terms/${policyInfo.PolicyNumber}/${policyInfo.TermNumber}`;
    }
    return `../../accounts/${policyInfo.AccountNumber}/bop/policies/${policyInfo.JobNumber}`;
  }

  getPageEnd(index: number, totalItemCount: number) {
    return Math.min(index + this.POLICY_TABLE_PAGE_SIZE, totalItemCount);
  }

  showRolloverError(err: Error) {
    this.loadingRolloverList = false;
    this.hasRolloverListError = true;
  }

  showChartError(err: Error) {
    this.sentryService.notify('Error in loading data for rollover charts');
    this.hasChartError = true;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
