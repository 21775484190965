import { API_V3_BASE } from 'app/constants';
import * as _ from 'lodash';

export const QUOTE_API_URI = `${API_V3_BASE}/quotes/workers-comp`;
export const QUOTE_SUBMISSION_API_URI = `${API_V3_BASE}/quotes/workers-comp/submission`;
export const POLICY_DOCS_API_URI = (uuid: string) => {
  return `${API_V3_BASE}/quotes/workers-comp/${uuid}/policy-documents`;
};

export const RESTRICTED_EMPLOYERS_STATES = ['AK', 'HI', 'ND', 'OH', 'WA', 'WY'];

export const EMP_LIABILITY_MAP = {
  '100k': {
    eachClaim: 100000,
    eachEmployee: 100000,
    policyLimit: 500000,
  },
  '500k': {
    eachClaim: 500000,
    eachEmployee: 500000,
    policyLimit: 500000,
  },
  '1m': {
    eachClaim: 1000000,
    eachEmployee: 1000000,
    policyLimit: 1000000,
  },
  '2m': {
    eachClaim: 2000000,
    eachEmployee: 2000000,
    policyLimit: 2000000,
  },
};

export const EMP_LIABILITY_DEFAULT_OPTIONS: { [key: string]: string } = {
  '100k': '$100k / $100k / $500k',
  '500k': '$500k / $500k / $500k',
  '1m': '$1M / $1M / $1M',
  '2m': '$2M / $2M / $2M',
};

export const EMP_LIABILITY_OPTIONS_BY_STATE: { [key: string]: { [key: string]: string } } = {
  CA: _.pick(EMP_LIABILITY_DEFAULT_OPTIONS, ['1m', '2m']),
  OR: _.pick(EMP_LIABILITY_DEFAULT_OPTIONS, ['500k', '1m', '2m']),
};

export const EMP_TAX_ID_KEY_BY_STATE: { [key: string]: { [key: string]: string } } = {
  ME: {
    accountKey: 'maineUnemploymentNumber',
    labelText: 'Maine Unemployment Number',
  },
  MI: {
    accountKey: 'michiganUnemploymentNumber',
    labelText: 'Michigan Unemployment Number',
  },
  MN: {
    accountKey: 'minnesotaUnemploymentNumber',
    labelText: 'Minnesota Unemployment Number',
  },
  NJ: {
    accountKey: 'newJerseyTaxIdNumber',
    labelText: 'New Jersey Tax ID Number',
  },
  RI: {
    accountKey: 'rhodeIslandUnemploymentNumber',
    labelText: 'Rhode Island Unemployment Number',
  },
  UT: {
    accountKey: 'utahUnemploymentNumber',
    labelText: 'Utah Unemployment Number',
  },
};

export const EMP_TAX_ID_KEY_LENGTH_BY_STATE: {
  [key: string]: {
    min: number;
    max: number;
  };
} = {
  MI: { min: 2, max: 15 },
  MN: { min: 5, max: 8 },
  NJ: { min: 12, max: 12 },
  UT: { min: 9, max: 9 },
  RI: { min: 10, max: 10 },
  ME: { min: 10, max: 10 },
};

export const EMP_CONSTRUCTION_TYPE_OPTIONS: { [key: string]: string } = {
  F: 'Frame',
  FR: 'Fire Resistive',
  JM: 'Joisted Masonry',
  MFR: 'Modified Fire-Resistive',
  MY: 'Masonry Non Combustible',
  NC: 'Noncombustible',
};

export interface WcPolicyPaymentPlanOption {
  premiumThreshold: number;
  downPayment: number;
  name: string;
}
export const WC_POLICY_PAYMENT_PLAN_OPTIONS: { [key: number]: WcPolicyPaymentPlanOption } = {
  1: {
    downPayment: 1.0,
    name: 'One payment',
    premiumThreshold: 0,
  },
  4: {
    downPayment: 0.3,
    name: 'Four payments',
    premiumThreshold: 1000,
  },
  10: {
    downPayment: 0.1,
    name: 'Ten payments: 10% down',
    premiumThreshold: 2500,
  },
  9: {
    downPayment: 0.2,
    name: 'Ten payments: 20% down',
    premiumThreshold: 1000,
  },
  11: {
    downPayment: 0.0,
    name: 'Precisepay',
    premiumThreshold: 1500,
  },
};

export const API_DATE_FORMAT = 'Y-MM-DD';

export const EXECUTIVE_EXCLUSION_QUESTION_CODE = 'EMPCO-2000';

export const EXECUTIVE_EXCLUSION_QUESTION: { code: string; question: string } = {
  code: EXECUTIVE_EXCLUSION_QUESTION_CODE,
  question: `I attest that the insured (my client) has complied with the applicable workers' compensation laws of the states shown in item 3.A. of the policy information page, and I will maintain and make available upon request all required documentation in the Agency file.`,
};

export type EXECUTIVE_QUESTION_SLUG =
  | 'OWN-2'
  | 'OWN-2-1'
  | 'OWN-2-1-1'
  | 'OWN-3'
  | 'OWN-4'
  | 'OWN-5'
  | 'OWN-6'
  | 'OWN-7';

export type EMPLOYERS_QUESTION_SLUG =
  | 'BOP40'
  | 'CGL04'
  | 'EMPCO-1007'
  | 'EMPCO-1104'
  | 'EMPCO-1105'
  // "EMPCO-119" |
  // "EMPCO-120" |
  | 'EMPCO-2000'
  | 'EMPCO-3001'
  | 'EMPCO-3003'
  | 'EMPCO-3011'
  | 'EMPCO-3012'
  | 'EMPCO-3013'
  | 'EMPCO-3014'
  | 'EMPCO-3015'
  // | 'EMPCO-3019'
  | 'EMPCO-163'
  | 'EMPCO-164'
  // "EMPCO-165" |
  | 'EMPCO-3007'
  | 'EMPCO-3008'
  // | 'EMPCO-30080'
  // "EMPCO-316" |
  | 'EMPCO-3009'
  // | 'EMPCO-3010'
  | 'EMPCO-3016'
  // | 'EMPCO-3018'
  | 'EMPCO-3020'
  | 'EMPCO-3021'
  | 'EMPCO-3022'
  | 'EMPCO-3023'
  | 'EMPCO-3024'
  | 'EMPCO-3025'
  | 'EMPCO-3029'
  | 'EMPCO-3030'
  | 'EMPCO-3031'
  | 'EMPCO-3109'
  | 'EMPCO-3129'
  // | 'EMPCO-3118'
  | 'EMPCO-3131'
  | 'EMPCO-3209'
  | 'EMPCO-3229'
  // | 'EMPCO-3218'
  // "EMPCO-325" |
  // "EMPCO-374" |
  | 'EMPCO-512'
  | 'WORK06'
  | 'WORK07'
  | 'WORK08'
  | 'WORK11'
  | 'WORK11FiveEmp'
  | 'WORK11RecReview';

interface WcUwQuestion {
  code: EMPLOYERS_QUESTION_SLUG;
  question?: string;
  hidden?: boolean;
  subpoints?: string[];
  payrollThreshold?: number;
}

interface WcExecutiveQuestion {
  code: EXECUTIVE_QUESTION_SLUG;
  orgTypes: string[];
  titles?: string[];
  question: string;
}

export const EXECUTIVE_QUESTIONS: {
  [index: string]: WcExecutiveQuestion[];
} = {
  NY: [
    {
      code: 'OWN-2',
      orgTypes: ['CP'],
      question:
        'Is the individual an executive officers of religious, charitable, educational, or municipal corporation, an officer of any post or chapter of organizations of veterans of any war of the United States?',
    },
    {
      code: 'OWN-2-1',
      orgTypes: ['CP'],
      question: 'Is the individual an unsalaried executive officer of a non-profit corporation?',
    },
    {
      code: 'OWN-2-1-1',
      orgTypes: ['CP'],
      question:
        'Is there a sole executive officer that owns 100% of the stock or 2 executive officers that own 100% of the stock between them?',
    },
  ],
  CA: [
    {
      code: 'OWN-3',
      orgTypes: ['GP', 'LL'],
      titles: ['TE', 'MT'],
      question:
        'Verify the following is true: Individual must be a working employee and Individual is the trustee of a revocable trust that is a General Partner, and has the power to revoke the trust’s interest.',
    },
    {
      code: 'OWN-4',
      orgTypes: ['CP'],
      titles: ['C2', 'F2', 'D2', 'P2', 'S2', 'T2', 'V2'],
      question:
        'Verify the following is true: Individual must be a working employee and an appointed officer or member of the Board of Directors, and Individual must own either: A) at least 10% of the  issued and outstanding stock, or B) at least 1% of the stock and has a parent, grandparent, sibling, spouse, or child who owns at least 10% of the issued and outstanding stock of the corporation and the individual is covered by a health insurance policy or a health service plan.',
    },
    {
      code: 'OWN-5',
      orgTypes: ['CP'],
      titles: ['BT'],
      question:
        'Verify the following is true: Individual must be a working employee and an appointed officer or member of the Board of Directors, and Individual owns no stock but is the trustee of a revocable trust that is a stockholder, and has the power to revoke the trust’s share.',
    },
    {
      code: 'OWN-6',
      orgTypes: ['CP'],
      titles: ['PP'],
      question:
        'Verify the following is true: Individual must be a working employee that owns stock and is a practitioner rendering professional services for which the corporation was organized, and is covered by a health care service plan or health insurance policy.',
    },
    {
      code: 'OWN-7',
      orgTypes: ['CP'],
      titles: ['C1', 'F1', 'D1', 'P1', 'S1', 'T1', 'V1'],
      question:
        'Verify the following is true: A) Individual must be a working employee and an appointed officer or member of the Board of Directors, and B) must be covered by a health care service plan or health insurance policy and a disability plan that is comparable in scope and coverage to a workers compensation policy.',
    },
  ],
};

export const UW_CLASS_SPECIFIC_QUESTIONS: {
  [index: string]: WcUwQuestion[];
} = {
  ARCHITECT: [
    {
      code: 'EMPCO-3001',
      question: 'Does the applicant have any of the following:',
      subpoints: [
        'Exposure to surveying, geophysical exploration, marine, mining, timber cruising, oil or gas geology',
        'Management of construction projects',
        'An active contractor’s license',
      ],
    },
  ],
  AUTOSERV: [
    {
      code: 'EMPCO-3011',
      question: 'Does the Applicant provide any of the following:',
      subpoints: [
        'Emergency roadside assistance, mobile auto repair, or city, county, or municipality towing-for-hire services',
        'Repair of motorcycles, buses, recreational vehicles, commercial trucks, industrial trucks, and trailers',
        'Performs testing on watercraft or jet skis',
        'Car wash with manual labor (including auto detailing operations)',
      ],
    },
  ],
  BAR: [
    { code: 'EMPCO-164', question: 'Two or more employees are on duty at all times.' },
    {
      code: 'EMPCO-163',
      question: 'Applicant has cover charges, bouncers, crowd control, or armed security guards.',
    },
  ],
  BEAUTY: [
    {
      code: 'EMPCO-3016',
      question: 'Does the Applicant have any of the following:',
      subpoints: [
        'Tattooing',
        'Body piercing (except for ear piercing)',
        'Sunless tanning',
        'Personal training',
      ],
    },
  ],
  CLERICAL: [
    {
      code: 'EMPCO-3012',
      question: 'The applicant is involved in:',
      subpoints: [
        'Engineer',
        'Bail bondsman',
        'Draftsman',
        'Licensed contractor',
        'Charitable/welfare organization',
        'Check cashing service',
        'Collection agency',
        'Pari-mutuel betting organization',
        'Logistics',
        'Freight forwarder',
        'Employee staffing operations',
        'Distributor',
        'Mailing/addressing company',
        'Management/dispatching of vehicles',
        'Social services',
      ],
    },
  ],
  DAYCARE: [
    {
      code: 'EMPCO-3003',
      question:
        'Applicant operates a day care from a home or private residence, or operates an adult day care.',
    },
  ],
  ELECTRICAL: [
    {
      code: 'EMPCO-3020',
      question: 'Does the Applicant subcontract 35% or more work?',
      payrollThreshold: 50000,
    },
    {
      code: 'EMPCO-3022',
      question: 'The applicant does one of the following:',
      subpoints: [
        'Perform any work or drilling below 5 feet in depth or within confined spaces',
        'Perform work above 30 feet',
        'Perform work on Public Utilities',
        'Perform any work on or related to high voltage/heavy electrical greater than 240V',
        'Use large equipment such as bucket truck, cherry picker, crane, excavator, platform hoist, rigging, etc.',
        'Install Solar panels, Satellite Dishes, or Turbine',
        'Perform work related to Joint ventures or wrap-ups',
      ],
      payrollThreshold: 50000,
    },
  ],
  FLOORING: [
    {
      code: 'EMPCO-3020',
      question: 'Does the Applicant subcontract 35% or more work?',
      payrollThreshold: 50000,
    },
    {
      code: 'EMPCO-3023',
      question: 'The applicant does one of the following:',
      subpoints: [
        'Remediate and/or remove asbestos, mold, or radon',
        'Perform Carpentry/Handyman Repairs',
        'Have silica dust exposure',
      ],
      payrollThreshold: 50000,
    },
  ],
  FOODSRV: [
    {
      code: 'EMPCO-3009',
      question: 'The applicant does one of the following:',
      subpoints: ['Delivery', 'Off-premises catering', 'Operate a mobile catering service'],
    },
    /*
     * Sub-questions are hardcoded in the template: 'EMPCO-3109' 'EMPCO-3209'
     */
    {
      code: 'EMPCO-3109',
      hidden: true,
    },
    {
      code: 'EMPCO-3209',
      hidden: true,
    },
  ],
  GASSTATION: [
    {
      code: 'EMPCO-3031',
      question: 'Do employees work after 10 PM?',
    },
    /*
     * Sub-questions are hardcoded in the template: 'EMPCO-3131'
     */
    {
      code: 'EMPCO-3131',
      hidden: true,
    },
  ],
  JANITORIAL: [
    {
      code: 'EMPCO-3029',
      question: 'The applicant does the following:',
      subpoints: [
        'Perform ANY construction, demolition, restoration services/catastrophe cleanup, or hazardous material removal/cleanup',
        'Clean industrial plants, escalators, nursing homes, or hospitals',
        'Perform ANY pressure washing, parking lot sweeping, or swimming pool services',
        'Perform work above 8 feet',
      ],
    },
    /**
     * Sub-questions are hardcoded in the template: 'EMPCO-3029
     */
    {
      code: 'EMPCO-3129',
      hidden: true,
    },
    {
      code: 'EMPCO-3229',
      hidden: true,
    },
  ],
  LANDSCAPE: [
    {
      code: 'EMPCO-3025',
      question: 'The applicant does one of the following:',
      subpoints: [
        'Perform work above 8 feet',
        'Transport, remove, or transplant mature trees',
        'Perform any excavation work',
        'Spend 50% or more of their time doing hardscaping work',
        'Spend 25% or more of their time doing snow removal',
        'Work on Highways and/or Interstates',
        'Have contracts with the state or federal Department of Transportation (DOT)',
      ],
    },
  ],
  MEDSRV: [
    {
      code: 'EMPCO-3013',
      question: 'Applicant performs any of the following services:',
      subpoints: [
        'Home healthcare',
        'Public health clinics',
        'Mental health facilities',
        'Hospital emergency rooms',
        'Drug rehabilitation center',
        'Abortion clinic',
        'Hospice',
      ],
    },
  ],
  PAINT: [
    {
      code: 'EMPCO-3020',
      question: 'Does the Applicant subcontract 35% or more work?',
      payrollThreshold: 50000,
    },
    {
      code: 'EMPCO-3024',
      question: 'The applicant does one of the following:',
      subpoints: [
        'Perform work above 30 feet',
        'Perform work on Public Utilities',
        'Perform any work at operations of industrial, manufacturing, refinery buildings or plants',
        'Own, rent or hire large equipment such as a bucket truck, cherry picker, crane, excavator, platform hoist, and/or rigging',
        'Remediate and/or remove asbestos, mold, and lead-based paint',
        'Perform carpentry/handyman repairs',
        'Use industrial coatings or sandblasting',
        'Perform work on or adjacent to roadways, bridges, overpasses, water towers, and ships',
        'Perform work related to joint ventures or wrap-ups',
      ],
      payrollThreshold: 50000,
    },
  ],
  PHOTO: [
    {
      code: 'EMPCO-512',
      question:
        'Applicant provides photography work for television, newspapers, magazines, or media outlets (including paparazzi); conducts photography for underwater, aerial, extreme sports, or adult entertainment; or works with animals or at heights over 8 feet.',
    },
  ],
  PLUMBING: [
    {
      code: 'EMPCO-3020',
      question: 'Does the Applicant subcontract 35% or more work?',
    },
    {
      code: 'EMPCO-3021',
      question: 'The applicant does one of the following:',
      subpoints: [
        'Perform any work or drilling below 5 feet in depth',
        'Perform work above 30 feet',
        'Perform maintenance on or install industrial boilers',
        'Install, remove or repair well pumps, septic or oil tanks (Sump pumps are acceptable)',
        'Perform work on Public Utilities such as gas, sewer or water mains',
        'Perform remediation of asbestos, mold, or Radon',
      ],
    },
  ],
  PROPMGMT: [
    {
      code: 'EMPCO-3030',
      question: 'The applicant does one of the following:',
      subpoints: [
        'Perform work above 8 feet',
        'Employ armed security guards',
        "Maintain a contractos's license or perform work that would require one",
        'Own or operate a flea market',
      ],
    },
  ],
  RETAIL: [
    {
      code: 'EMPCO-3014',
      question: 'The applicant does any of the following:',
      subpoints: [
        'Sell marijuana or CBD products with 0.3% or more of THC',
        'Sell live animals other than domestic pets',
        'Operate a pawn shop',
        'Operate a rental business',
        'Derive more than 20% of sales from firearms',
      ],
    },
  ],
  SCHOOLS: [
    {
      code: 'EMPCO-3015',
      question:
        'Applicant is a school for martial arts, driving, adult health care facility (daycares), or located in correctional facilities',
    },
  ],
  WINERY: [
    {
      code: 'EMPCO-3007',
      question: 'Applicant does farming, harvesting, or picking',
    },
  ],
  WOODMFG: [
    {
      code: 'EMPCO-3008',
      question: 'Applicant installs or delivers products.',
    },
  ],
};

export enum QUOTE_API_ERROR_STATUS_CODES {
  VALIDATION = 422,
}

export const DAYS_TILL_QUOTE_EXPIRES = 10;
