// Libraries
import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { kebabCase, toString } from 'lodash';

export interface OptionLabel {
  label: string;
  type: 'main' | 'success' | 'warning' | 'danger' | 'neutral';
}

@Component({
  selector: 'app-form-field-radio',
  templateUrl: './form-field-radio.component.html',
})
export class FormFieldRadioComponent implements OnInit, OnDestroy {
  @Input() inputId: string;
  @Input() additionalQuestionText: string;
  @Input() questionText: string;
  @Input() questionNote: string;
  @Input() nameOfFormControl: string;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() cssClass: string;
  @Input() optionsCssClass: string;
  @Input() optionsLabelCssClass: string;
  @Input() questionCssClass: string;
  @Input() tooltipText: string;
  @Input() options: { [key: string]: string | boolean | number };
  @Input() optionLabels: { [key: string]: OptionLabel };
  @Input() emitClicks: boolean;
  @Input() disabled: boolean;
  @Input() invalid = false;
  @Input() disabledOptions: string[];
  @Output() optionClicked = new EventEmitter<string | boolean | number>();

  private sub: Subscription = new Subscription();
  public inputValue: string | boolean | number | null;

  getControl = getControl;

  ngOnInit() {
    const formControl = getControl(this.form, this.nameOfFormControl);
    if (formControl) {
      this.sub.add(
        formControl.valueChanges
          .pipe(startWith(formControl.value))
          .subscribe((selectedValue) => (this.inputValue = selectedValue))
      );
    }
  }

  getOptions() {
    return this.options || { Yes: true, No: false };
  }

  getOptionLabels() {
    return this.optionLabels || {};
  }

  getSanitizedOptionId(optionKey: string) {
    return this.inputId + '-' + kebabCase(toString(this.getOptions()[optionKey]));
  }

  shouldDisableOption(optionKey: string): boolean {
    if (this.disabled) {
      return true;
    }
    if (!this.disabledOptions) {
      return false;
    }
    for (let i = 0; i < this.disabledOptions.length; i++) {
      if (this.disabledOptions[i] === optionKey) {
        return true;
      }
    }
    return false;
  }

  fieldInvalid(field: string): boolean | undefined {
    return (this.submitted && this.invalid) || shouldShowInvalid(field, this.form, this.submitted);
  }

  clickOption(option: string | boolean | number) {
    if (this.emitClicks) {
      this.optionClicked.emit(option);
    }
  }

  isSelected(optionKey: string): boolean {
    return this.getOptions()[optionKey] === this.inputValue;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
